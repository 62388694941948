import * as React from "react";
import { useRef } from "react";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";
import { makeStyles, createStyles } from "@material-ui/core";
import clsx from 'clsx';


const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: { width: "95%",},
    fullWidth: { width: "100%",}
  })
);

const config: ApiAutoCompleteConfigItem = {
  acId: "fundSources",
  cacheTime: 5,
  route: "/StudentAccounts/FundSource/GetFundSourcesByCampus",
  requestType: "GET",
  body: null,
  hasUrlParams: true,
  useCache: false,
  storeInCache: false,
  isCampusSpecific: true,
  isUserSpecific: false,
  loadOnRender: true,
  defaultSelectedFirstItem: false,
  optionsMappedFx: (data: Array<any>) => {
    return data;
  }
};

const FundSourcesAutoComplete = (props: FilterParams) => {
  const inputRef: any = useRef(null);
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const handleOnChange = (e: any) => {
    if (props.filterHandle) {
      props.filterHandle(e);
    }
  };

  const classes = useStyles({});

  return (
    <div className={props.params?.fullWidth ? clsx(classes.fullWidth) : clsx(classes.divWidth) }>
      <ApiAutoComplete
        config={config}
        loading={userSelectedCampus ? false : true}
        reqParams={{ campusId: (props.campusId ? props.campusId : userSelectedCampus), 
          nonTitleIvLoanOnly: props.params?.nonTitleIvLoanOnly ? props.params?.nonTitleIvLoanOnly : null,
          awardType: props.params?.awardType ? props.params?.awardType : null,
          afaFilter: props.params?.afaFilter ? props.params?.afaFilter : null }}
          
        classes={{
          option: "text-black"
        }}
        label={props.label ? props.label : "Fund Source"}
        onChange={(e: any) => {
          handleOnChange(e);
        }}
        multiple={props.multiple || false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        inputRef={props.inputRef ? props.inputRef : inputRef}
        error={props.error}
        helperText={props.helperText}
        inputName={props.name}
        optionsFilter={props.optionsFilter? props.optionsFilter : undefined}
      ></ApiAutoComplete>
    </div>
  );
};
export default FundSourcesAutoComplete;
