import { colors } from "@material-ui/core";
import { light } from "@material-ui/core/styles/createPalette";

const white = "#FFFFFF";
const black = "#000000";

//figma colors

const primarySite = "#000000";
const secondarySite = "#F4F0F0";
const accentSite = "#F3C057";
const fontAndIcons = "#37474F";
const paperBackground = "#FFFFFF";
const lighterFontsIcons = "rgba(55, 71, 79, 0.75)";
const cardBackground = "#FCFCFC";
const accent2 = "#96C950";
const loginBackground = "rgba(194,194,194,.8)";
const searchBackground = "rgba(255, 255, 255, 0.4)";
const selectedChartRow = "#f3c057";
const secondarySiteImportant = "#F4F0F0 !important";

//custom colors
const darkError = "#861917";

export default {
  white,
  black,

  //begin overrides
  primary: {
    contrastText: white,
    contrastNavTabColor: primarySite,
    headerText: black,
    dark: primarySite,
    main: primarySite,
    light: primarySite,
  },
  secondary: {
    contrastText: black,
    dark: secondarySite,
    main: secondarySite,
    light: secondarySite,
    mainImportant: secondarySiteImportant,
  },
  error: {
    contrastText: white,
    dark: darkError,
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: fontAndIcons,
    secondary: lighterFontsIcons,
    link: colors.blue[600],
  },
  //end overrides

  site: {
    primary: primarySite,
    secondary: secondarySite,
    accent: accentSite,
  },
  table: {
    row: {
      background: white,
      hover: secondarySite,
    },
  },
  paper: {
    background: paperBackground,
  },
  card: {
    background: cardBackground,
  },
  icon: {
    primary: fontAndIcons,
    secondary: lighterFontsIcons,
  },
  login: {
    background: loginBackground,
  },
  topBar:{
    background: primarySite,
    icons: white,
    inputFields:{
      background: "rgba(255, 255, 255, 0.4)",
      border: "#2a2a2a",
      color: white
    }
  },
  buttons: {
    primary: primarySite,
    secondary: secondarySite,
  },
  studentSearch: {
    background: searchBackground,
    border:"#2a2a2a"
  },
  charts: {
    selectedRow: selectedChartRow,
  },
  buttonProgress: {
    primary: colors.green[500],
    secondary: colors.green[700],
  },
  
  badge: "#ff7f27",
  paperSummarySchedulerTitle: "#00008b",
  tableHeaderColor: "#e1e1e1",
  divider: primarySite,
  border: primarySite,
  boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 3px 3px 3px 0 rgba(63,63,68,0.15)",
};
