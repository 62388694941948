import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
		Typography,
		Paper,
		TextField,
		CardContent,
		Dialog,
		DialogActions,
		DialogContent,
		DialogContentText,
		DialogTitle,
		Button
	} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import * as StatusHistoryApi from "../../../api/student/status/StatusHistoryApi";
import { useSelector } from "react-redux";

import LeaveOfAbsenceModal from "../../../pages/student/status/LeaveOfAbsenceModal";
import ProbationModal from "../../../pages/student/status/ProbationModal";
import SuspensionModal from "../../../pages/student/status/SuspensionModal";
import CurrentlyAttendingModal from "../../../pages/student/status/CurrentlyAttendingModal";
import GraduatedModal from "../../../pages/student/status/GraduatedModal";
import FutureStartModal from "../../../pages/student/status/FutureStartModal";
import { IStatusChangeOption } from "../../../interfaces/student/status/IStatusChangeOption";
import { IStatusChangeEnrollment } from "../../../interfaces/student/status/IStatusChangeEnrollment";
import { SystemEnrollmentStatus } from "../../../enums/SystemEnrollmentStatus"; 
import { checkPermissions } from "../../../utils/securityFunctions"

type StatusUpdateProps = {
	updateEvent: any;
	refreshFlag: boolean;
	newPageEvent: any;
};

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.background.paper,
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2),
			},
		},
		title: {
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1.5),
			paddingLeft: theme.spacing(.5),			
		},
		changeLabel: {
			display: "inline-block !important",	
			paddingLeft: theme.spacing(.5),
			verticalAlign: "bottom",
		},
		changeItem: {
			display: "inline-block !important",	
			paddingLeft: theme.spacing(2.5),
			verticalAlign: "bottom",
			width: "40%",
		},
		updateWrapper: {
			width: "100%",			
		},
		cardTitle: {
			fontSize: theme.spacing(2),
			color: theme.palette.black,
			textDecoration: "bold",
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1)
		},
		textField: {
			marginRight: theme.spacing(1),
		},
		cardContent: {
			padding: theme.spacing(1, 1)
		},
		button: {
			marginRight: theme.spacing(1),
			marginLeft: theme.spacing(1),
			width: "180px"

		},
		dialogHeader: {
			backgroundColor: theme.palette.site.secondary,
			"& .MuiTypography-root": {
				fontSize: theme.spacing(2),
			},
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
		},
	})
);

const StatusUpdate = (props: 
	StatusUpdateProps) => {
	const classes = useStyles({});
	const isSupportUser = useSelector(
        (state: any) => state.session.user.isSupportUser
    );

	const [changeOptionData, setChangeOptionData] = React.useState<Array<IStatusChangeOption>>([]);

	const [LeaveOfAbsenceModalOpen, setLeaveOfAbsenceModalOpen] = React.useState(false);
	const [ProbationModalOpen, setProbationModalOpen] = React.useState(false);
	const [SuspensionModalOpen, setSuspensionModalOpen] = React.useState(false);
	const [CurrentlyAttendingModalOpen, setCurrentlyAttendingModalOpen] = React.useState(false);
	const [GraduatedModalOpen, setGraduatedModalOpen] = React.useState(false);
	const [FutureStartModalOpen, setFutureStartModalOpen] = React.useState(false);
	const [addNewCode, setAddNewCode] = React.useState(null);
	const [addNewText, setAddNewText] = React.useState(null);

	const [selectedStatus, setSelectedStatus] = React.useState(null);
	const [selectedChange, setSelectedChange] = React.useState<IStatusChangeOption | null>();
	const [changeEnrollment, setChangeEnrollment] = React.useState<IStatusChangeEnrollment>();

	const selectedEnrollment = useSelector(
		(state: any) => state.student.selectedEnrollment
	);

	const [openUndoReEnrollPrompt, setOpenUndoReEnrollPrompt] = React.useState(false);
	
	const refreshOptions=()=>{
		if (selectedEnrollment && selectedEnrollment.stuEnrollId) {
			let studentEnrollmentId = selectedEnrollment.stuEnrollId;
			StatusHistoryApi.getStatusChangeOptions(studentEnrollmentId).then(
				(response: Array<IStatusChangeOption>) => {
					if (response && response.length > 0) {
						if(checkPermissions(["RestrictAddLOA"]) && !isSupportUser)
						{
							response.forEach(status => {
								if(status.sysStatusId == 10) //LOA
								{
									status.validOption = false;
								}
							});
							setChangeOptionData(response);
						}
						else
						{
							setChangeOptionData(response);
						}						
					}
				},
				(exception: any) => {}
			);
		}
	}
	const refreshChangeEnrollment=()=>{
		if (selectedEnrollment && selectedEnrollment.stuEnrollId) {
			let studentEnrollmentId = selectedEnrollment.stuEnrollId;
			StatusHistoryApi.getStatusChangeEnrollment(studentEnrollmentId).then(
				(response: IStatusChangeEnrollment) => {
					if (response) {
						setChangeEnrollment(response);
					}
				},
				(exception: any) => {}
			);
		}
	}
	React.useEffect(() => {
		refreshOptions();
		refreshChangeEnrollment();
	}, [selectedEnrollment, props.refreshFlag]);
	
	const functionsByStatusType : Array<any> = [
		{
			sysStatusId: SystemEnrollmentStatus.FutureStart,
			function: "handleFutureStart(value);"
		},
		{
			sysStatusId: SystemEnrollmentStatus.NoStart,
			function: "handleNoStart(value);"
		},		
		{
			sysStatusId: SystemEnrollmentStatus.CurrentlyAttending,
			function: "handleCurrentlyAttending(value);"
		},		
		{
			sysStatusId: SystemEnrollmentStatus.LeaveOfAbsence,
			function: "handleNewLOA(value);"
		},
		{
			sysStatusId: SystemEnrollmentStatus.Suspension,
			function: "handleNewSuspension(value);"
		},
		{
			sysStatusId: SystemEnrollmentStatus.Dropped,
			function: "handleDrop(value);"
		},	
		{
			sysStatusId: SystemEnrollmentStatus.Graduated,
			function: "handleGraduation(value);"
		},	
		// {
		// 	sysStatusId: SystemEnrollmentStatus.DisciplinaryProbation,
		// 	function: "handleLinkToProbation(value);"
		// },
		// {
		// 	sysStatusId: SystemEnrollmentStatus.WarningProbation,
		// 	function: "handleLinkToProbation(value);"
		// }

        ]

	const onOptionChange = (_event: any, value: any) => {
		if (value != null)
		{
			let found = functionsByStatusType.find((element) => {return element.sysStatusId === value.sysStatusId;});
			if (found != null)
				{eval(found.function)}
		}
		setAddNewCode((value != null)? value.statusCodeId : null)
		setAddNewText((value != null)? value.statusDescription : null)
		setSelectedChange(value);
	}

	const handleNewLOA = (option: IStatusChangeOption) => {
		setLeaveOfAbsenceModalOpen(true);
	}

	const handleNewSuspension = (option: IStatusChangeOption) => {
		setSuspensionModalOpen(true);
	}

	const handleNoStart = (option: IStatusChangeOption) => {
		props.newPageEvent("/student/termination");
	}

	const handleDrop = (option: IStatusChangeOption) => {
		props.newPageEvent("/student/termination");	
	}

	const handleUndoTermination = () => {
		setOpenUndoReEnrollPrompt(false);
		props.newPageEvent("/student/undotermination");
	};
	
	const handleReEnroll = () => {
		setOpenUndoReEnrollPrompt(false);		
		switch (selectedChange?.sysStatusId) {
			case SystemEnrollmentStatus.FutureStart:
				setFutureStartModalOpen(true);
				break;
			case SystemEnrollmentStatus.CurrentlyAttending:
				setCurrentlyAttendingModalOpen(true);
				break;
		};
	};	
	
	const handleCurrentlyAttending = (option: IStatusChangeOption) => {
		if (option != null)
		{
			switch (changeEnrollment?.systemStatus){
				case SystemEnrollmentStatus.Dropped:
				case SystemEnrollmentStatus.NoStart:{
					setOpenUndoReEnrollPrompt(true);
					break;					
				}
				case SystemEnrollmentStatus.LeaveOfAbsence: 
				case SystemEnrollmentStatus.Suspension:
				case SystemEnrollmentStatus.FutureStart:
				case SystemEnrollmentStatus.DisciplinaryProbation:
				case SystemEnrollmentStatus.WarningProbation: {
					setCurrentlyAttendingModalOpen(true);
					break;		
				}
				case SystemEnrollmentStatus.CurrentlyAttending:{
					setCurrentlyAttendingModalOpen(true);
					break;
				}
			}
		}
	};

	const handleGraduation = () => {
		setGraduatedModalOpen(true);
	};

	const handleFutureStart = (option: IStatusChangeOption) => {
		if (option != null)
		{
			switch (changeEnrollment?.systemStatus){
				case SystemEnrollmentStatus.Dropped:
				case SystemEnrollmentStatus.NoStart:{
					setOpenUndoReEnrollPrompt(true);
					break;					
				}
			}
		}
	};
	
	// const handleLinkToProbation = (option: IStatusChangeOption) => {
	// 	setProbationModalOpen(true);
	// }

	const handleStatusUpdateEvent = (newStatus: any) => {
		props.updateEvent(newStatus);
	}

	const handleUndoReEnrollPromptClose =() => {
		setOpenUndoReEnrollPrompt(false);
	}

	return (

	<Paper className={classes.updateWrapper}>
		<Typography className={classes.cardTitle} >
				Status Update
		</Typography>
		<CardContent className={classes.cardContent}>
			<Typography className={classes.changeLabel}>
				Change status to
			</Typography>
			<div className={classes.changeItem}>
				<Autocomplete
					id="change-status-options"
					options={changeOptionData}
					className={classes.textField}
					value={selectedStatus}
					getOptionLabel={(option) => (option != null)? option.statusDescription: ""}
					getOptionDisabled={(option) => option.validOption == false}
					onChange={onOptionChange}
					renderInput={(params) => (
						<TextField {...params} label="New Status" />
					)}
				/>
			</div>	
		</CardContent>
		
		<LeaveOfAbsenceModal
			open={LeaveOfAbsenceModalOpen}
			setOpen={setLeaveOfAbsenceModalOpen}
			updateEvent={handleStatusUpdateEvent}
			newStatusCode={addNewCode}
			newStatus={addNewText}
			statusChangeEnrollment={changeEnrollment}
			loaWidgetEnrollment={null}
		/>
		{/* <ProbationModal
			open={ProbationModalOpen}
			setOpen={setProbationModalOpen}
		/>			 */}
		<SuspensionModal
			open={SuspensionModalOpen}
			setOpen={setSuspensionModalOpen}
			updateEvent={handleStatusUpdateEvent}
			newStatusCode={addNewCode}
			newStatus={addNewText}
			statusChangeEnrollment={changeEnrollment}
		/>	

		<CurrentlyAttendingModal
			open={CurrentlyAttendingModalOpen}
			setOpen={setCurrentlyAttendingModalOpen}
			updateEvent={handleStatusUpdateEvent}
			newPageEvent={props.newPageEvent}
			statusChange={selectedChange}
			statusChangeEnrollment={changeEnrollment}
		/>

		<GraduatedModal
			open={GraduatedModalOpen}
			setOpen={setGraduatedModalOpen}
			updateEvent={handleStatusUpdateEvent}
			newPageEvent={props.newPageEvent}
			statusChange={selectedChange}
			statusChangeEnrollment={changeEnrollment}
		/>

		<FutureStartModal
			open={FutureStartModalOpen}
			setOpen={setFutureStartModalOpen}
			updateEvent={handleStatusUpdateEvent}
			newPageEvent={props.newPageEvent}
			statusChange={selectedChange}
			statusChangeEnrollment={changeEnrollment}
		/>
		
		<Dialog open={openUndoReEnrollPrompt} onClose={handleUndoReEnrollPromptClose} >
			<DialogTitle
				id="reEnrollPrompt-dialog-title"
				className={classes.dialogHeader}
			>
				<IconButton 
					aria-label="close" 
					className={classes.closeButton} 
					onClick={handleUndoReEnrollPromptClose}
					size="small"
				>
					<CloseIcon fontSize="small" />
				</IconButton>
			</DialogTitle>			
			<DialogContent>
				<DialogContentText id="Undo-or-ReEnroll">
					Do you want to undo student termination or re-enroll?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleUndoTermination}
					color="secondary"
					variant="contained"
					className={classes.button}
				>Undo Termination</Button>
					<Button
					onClick={handleReEnroll}
					color="secondary"
					variant="contained"
					className={classes.button}
				>Re-Enroll</Button>         
			</DialogActions>
		</Dialog>

	</Paper>

	);
};
	
export default StatusUpdate;
