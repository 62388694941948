import {
  Backdrop,
  Button,
  CircularProgress,
  FormControlLabel,
  Modal,
  Paper,
  Popover,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { useTheme } from "@material-ui/core/styles";
import Clear from "@material-ui/icons/Clear";
import MoreVert from "@material-ui/icons/MoreVert";
import Search from "@material-ui/icons/Search";
import { createStyles, makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import "date-fns";
import MaterialTable, { MTableToolbar } from "material-table";
import moment from "moment";
import React, { forwardRef, useMemo } from "react";
import { useSelector } from "react-redux";
import * as ledgerApi from "../../../../api/student/studentAccounts/ledgerApi";
import AccessManager from "../../../../components/security/AccessManager";
import ConfirmationModal from "../../../../components/_Layout/Modal/ConfirmationModal";
import { security } from "../../../../constants/Security/securityConstants";
import { TransactionCode } from "../../../../enums/TransactionCode";
import { LedgerItem } from "../../../../interfaces/student/studentAccounts/LedgerItem";
import { system } from "../../../../constants/system/systemConstants";
import ReverseTransaction from './ReverseTransaction';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      "& .MuiCardContent-root": {
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(2),
      },
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    actionButton: {
      transition: "none",
      "&:hover": {
        backgroundColor: theme.palette.table.row.background,
      },
      "&:click": {
        backgroundColor: theme.palette.table.row.background,
      },
    },
    popoverButton: {
      width: "100%",
      textTransform: "none",
      justifyContent: "left",
    },
    wrapper: {
      position: "relative",
    },
    tableWrapper: {
      height: "100%",
      "& div:nth-child(2)": {
        height: "calc(100% - 75px)",
        "& div": {
          height: "100%",
        },
      },
      "& tr.MuiTableRow-head": {
        "& th": {
          width: "calc((100% - 0px) / 10) !important",
        },
        "& th:nth-child(2)": {
          width: "calc((100% - 0px) / 3) !important",
        },
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      // color: "#fff"
    },
    bodyWrapper: {
      height: "calc(100% - 75px)",
    },
    buttonSuccess: {
      backgroundColor: green[500],
      "&:hover": {
        backgroundColor: green[700],
      },
    },
    fabProgress: {
      color: theme.palette.primary,
      position: "absolute",
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: theme.palette.primary,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    typography: {
      padding: theme.spacing(1),
    },
    modalButtons: {
      height: "40px",
      marginLeft: theme.spacing(1),
    },
    flexRow: {
      display: "flex",
      flexDirection: "row",
      padding: 0,
      margin: 0,
    },
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    options: {
      textAlign: "center",
    },
    body: {
      height: "100%",
    },
  })
);

const tableIcons = {
  ResetSearch: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <Clear {...props} ref={ref} />
  )),
  Search: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <Search {...props} ref={ref} />
  )),
  Popover: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <MoreVert {...props} ref={ref} />
  )),
};

type LedgerGridProps = {
  ledgerItems: LedgerItem[];
  setTotalBalance: any;
  refreshLedgerItems: any;
  setShowVoid: any;
  showVoid: boolean;
  setSnackBar: any;
};

type DeletableTransaction = {
  reason: string;
  transactionId: string | null;
};
const paymentTransactionCodes = [
  TransactionCode.StudentPaymentPlan,
  TransactionCode.MiscStudentPayment,
];
const disbursementAndRefundCodes = [
  TransactionCode.FinancialAidPayment,
  TransactionCode.FinancialAidRefund,
];

const formatMoney = (number: number) => {
  var absValue = Math.abs(number);
  let numberString = absValue.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  return number < 0 ? "(" + numberString + ")" : numberString;
};

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const LedgerGrid = (props: LedgerGridProps) => {
  const {
    ledgerItems,
    setTotalBalance,
    refreshLedgerItems,
    showVoid,
    setShowVoid,
  } = props;
  const classes = useStyles({});
  const theme = useTheme();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [transactionToDelete, setTransactionToDelete] = React.useState<
    DeletableTransaction
  >({ reason: "", transactionId: null });
  const [popoverState, setPopoverState] = React.useState<any>({
    openedPopoverId: null,
    anchorEl: null,
    x: 0,
    y: 0,
    height: 0,
    witdh: 0,
  });

  const [rowLoadingState, setRowLoadingState] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [reversalPopUpOpen, setReversalPopUpOpen] = React.useState<boolean>(false);
  const [reverseTransId, setReverseTransId] = React.useState<string>("");
  const [confirmationPopupState, setConfirmationPopupState] = React.useState<
    any
  >({
    id: "simple-popup",
    message: "",
  });

  const handleReasonInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.persist();
    setTransactionToDelete((state: any) => {
      return { ...state, reason: event.target.value };
    });
  };

  const isPaymentTransaction = React.useCallback(
    (transactionCodeId: number) => {
      return paymentTransactionCodes.includes(
        transactionCodeId as TransactionCode
      );
    },
    []
  );

  const isDisbursementOrRefund = React.useCallback(
    (transactionCodeId: number) => {
      return disbursementAndRefundCodes.includes(
        transactionCodeId as TransactionCode
      );
    },
    []
  );

  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const appSettings = useSelector(
    (state: any) => { return state.settings.settings }
  );

  const isSupportUser = useSelector(
    (state: any) => state.session.user.isSupportUser
);

  const getAfaIntegratedFlag = () => {
    const afaIntegrationSetting = appSettings[system.settings.enableAFAIntegration] as string
    let afaIntegrationEnabled = false;
    const allowAFAOverrideSetting = appSettings[system.settings.allowAFAOverride] as string
    let allowAFAOverrideEnabled = false;

    if (afaIntegrationSetting) {
        afaIntegrationEnabled = afaIntegrationSetting.toLowerCase() === 'yes'
         
    }


    return afaIntegrationEnabled;
  };


  const getOverrideEnabledDeleteFlag = () => {
    const afaIntegrationSetting = appSettings[system.settings.enableAFAIntegration] as string
    let afaIntegrationEnabled = false;

    const allowAFAOverrideSetting = appSettings[system.settings.allowAFAOverride] as string
    let allowAFAOverrideEnabled = false;

    if (afaIntegrationSetting) {
        afaIntegrationEnabled = afaIntegrationSetting.toLowerCase() === 'yes'
        if (afaIntegrationEnabled)
            if (allowAFAOverrideSetting) 
            {
                allowAFAOverrideEnabled = allowAFAOverrideSetting.toLowerCase() === 'true'
            }  
    }


    return allowAFAOverrideEnabled;
  };

  const isAfaIntegrationEnabled = getAfaIntegratedFlag();
  const allowAFAOverrideEnabledDelete = getOverrideEnabledDeleteFlag();

  const handlePrintReceipt = (transactionId: string) => {
    if (!rowLoadingState.includes(transactionId)) {
      setRowLoadingState((state: any[]) => [...state, transactionId]);
      ledgerApi.printReceipt(transactionId, userSelectedCampus).then(
        (response: any) => {
          setRowLoadingState((state: any[]) =>
            state.filter((tId) => tId !== transactionId)
          );
          if (response) {
          }
        },
        (exception: any) => { }
      );
    }
  };
  const handleVoidConfirmation = (transactionId: string) => {
    setRowLoadingState((state: any[]) => [...state, transactionId]);
    ledgerApi.voidTransaction(transactionId).then(
      (response: any) => {
        setRowLoadingState((state: any[]) =>
          state.filter((tId) => tId !== transactionId)
        );

        if (response) {
          refreshLedgerItems();
        }
      },
      (exception: any) => { }
    );
  };

  const handleDeleteConfirmation = (transactionId: string) => {
    setTransactionToDelete((state: any) => {
      return { ...state, transactionId: transactionId };
    });
    setModalOpen(true);
  };

  const handleModalConfirmation = () => {
    if (transactionToDelete && transactionToDelete.transactionId) {
      setRowLoadingState((state: any[]) => [
        ...state,
        transactionToDelete.transactionId,
      ]);
      ledgerApi
        .deleteTransaction(
          transactionToDelete.transactionId,
          transactionToDelete.reason
        )
        .then(
          (response: any) => {
            setRowLoadingState((state: any[]) =>
              state.filter((tId) => tId !== transactionToDelete.transactionId)
            );
            if (response) {
              refreshLedgerItems();
            }
          },
          (exception: any) => { }
        );
    }

    handleModalClose();
  };

  const handleClickVoid = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    setConfirmationPopupState((state: any) => {
      return {
        ...state,
        open: true,
        confirmationText: "Are you sure you want to void this transaction?",
        confirmationButton: "Yes",
        title: "Void Transaction",
        onConfirmEvent: () => {
          setConfirmationPopupState((updatedState: any) => {
            return { ...updatedState, open: false };
          });
          handleVoidConfirmation(id);
        },
        onCancelEvent: () => {
          setConfirmationPopupState((updatedState: any) => {
            return { ...updatedState, open: false };
          });
        },
      };
    });
  };

  const handleReverseTransaction = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    setReverseTransId(id);
    setReversalPopUpOpen(true);
  };

  const closeReversalPopUp = React.useCallback(() => {
    setReversalPopUpOpen(false);
    setReverseTransId("");
  }, []);

  const handleClickDelete = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    setConfirmationPopupState((state: any) => {
      return {
        ...state,
        open: true,
        confirmationText: "Are you sure you want to delete this transaction?",
        confirmationButton: "Yes",
        title: "Delete Transaction",
        onConfirmEvent: () => {
          setConfirmationPopupState((updatedState: any) => {
            return { ...updatedState, open: false };
          });
          handleDeleteConfirmation(id);
        },
        onCancelEvent: () => {
          setConfirmationPopupState((updatedState: any) => {
            return { ...updatedState, open: false };
          });
        },
      };
    });
  };

  const handleChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowVoid(event.target.checked);
  };

  const filterTransactions = (ledgerItems: Array<LedgerItem>) => {
    let result: Array<LedgerItem> = ledgerItems;

    if (!showVoid) {
      result = ledgerItems.filter(function (li: any) {
        return !li.voided;
      });
    }
    return result;
  };
  let rect: any = null;
  const handleModalClose = () => {
    setModalOpen(false);
    setTransactionToDelete({ reason: "", transactionId: null });
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    rect = event.currentTarget.getBoundingClientRect();
    setPopoverState({
      openedPopoverId: id,
      anchorEl: event.currentTarget,
      x: rect.left - 120,
      y: rect.top,
      height: rect.height,
      witdh: rect.width,
    });
  };

  const handleClose = () => {
    setPopoverState({
      openedPopoverId: null,
      anchorEl: null,
    });
  };
  let runningBalance: number = 0;

  const filteredLedgerItems = filterTransactions(ledgerItems).map(
    (li: LedgerItem) => {
      const amount: number = !li.voided ? (li.amount ? li.amount : 0) : 0;
      let voidPrefix = "";

      if (li.voided) {
        if (li.voidedDate) {
          const voidDate = new Date(li.voidedDate).toLocaleDateString();
          voidPrefix =
            "*** VOID " +
            voidDate +
            " - Amount: " +
            li.amountAsCurrency +
            " - ";
        } else {
          voidPrefix = "*** VOID - Amount: " + li.amountAsCurrency + " - ";
        }
      }

      runningBalance += amount;
      return {
        ...li,
        description: voidPrefix + li.description,
        balance: runningBalance,
      } as LedgerItem;
    }
  );

  const transactionRefArray: any[] = [];
  const [showPostDateAndUser, setShowPostDateAndUser] = React.useState<boolean>(false);
  const handleShowPostDateAndUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowPostDateAndUser(event.target.checked);
  }

  const components = useMemo(() => {
    return {
      Toolbar: (props: any) => (
        <div style={{ padding: "15px", height: "75px" }}>
          <MTableToolbar {...props} />
          <div style={{ marginTop: "-45px" }}>
            <FormControlLabel
              label="Show Voided Transactions"
              control={
                <Switch
                  checked={showVoid}
                  value="showVoidedTransactions"
                  onChange={handleChange("showVoidedTransactions")}
                  color="default"
                />
              }
            />

            <FormControlLabel
              label="Show Post Date and User"
              control={
                <Switch
                  checked={showPostDateAndUser}
                  onChange={handleShowPostDateAndUserChange}
                  color="default"
                />
              }
            />

          </div>
        </div>
      ),
      Action: (props: any) => (
        <Button
          onClick={(event: any) => {
            props.action.onClick(event, props.data);
          }}
          variant="contained"
        ></Button>
      ),
      Container: (props: any) => (
        <Paper className={classes.tableWrapper} {...props} />
      ),
    };
  }, [showVoid, showPostDateAndUser]);

  setTotalBalance(runningBalance);
  return (
    <div className={classes.body}>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={modalOpen}
        onClose={handleModalClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <h3 id="simple-modal-title">Transaction Deletion</h3>
          <Typography color="textSecondary" variant="body1">
            Enter a reason for deleting this transaction.
          </Typography>

          <div className={classes.flexRow}>
            <TextField
              onChange={handleReasonInputChange}
              id="deletionReason"
              name="deletionReason"
              required
              label="Reason"
            />

            <Button
              variant="contained"
              className={classes.modalButtons}
              size="small"
              onClick={() => {
                handleModalConfirmation();
              }}
              disabled={transactionToDelete.reason === ""}
              color="primary"
            >
              OK
            </Button>
            <Button
              variant="contained"
              size="small"
              className={classes.modalButtons}
              onClick={() => {
                handleModalClose();
              }}
              color="secondary"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      <MaterialTable
        title=""
        columns={[
          { title: "id", field: "transactionId", hidden: true },
          { title: "Date", field: "dateString", type: "date" },
          {
            title: "Description",
            field: "description",
            render: (rowData: any) => {
              let description = (
                <Tooltip title="Description" placement="top-start">
                  <span>{showPostDateAndUser ? `${rowData.description} - Posted By: ${rowData.postedBy ?? ''} on ${rowData.postedDate ? moment(rowData.postedDate)?.format('MM/DD/YYYY') : ''}` : rowData.description}</span>
                </Tooltip>
              );
              let checkNumber = rowData.checkNumber ? (
                <Tooltip title="Check Number" placement="top-start">
                  <span> - {rowData.checkNumber}</span>
                </Tooltip>
              ) : (
                ""
              );
              let transReference = rowData.transReference ? (
                <Tooltip title="Refund Document Id" placement="top-start">
                  <span> - {rowData.transReference}</span>
                </Tooltip>
              ) : (
                ""
              );
              let documentId = rowData.documentId ? (
                <Tooltip title="Disbursement Document Id" placement="top-start">
                  <span> - {rowData.documentId}</span>
                </Tooltip>
              ) : (
                ""
              );
              return (
                <div>
                  {description} {(checkNumber != null && checkNumber != "") ? checkNumber : transReference} {documentId}
                </div>
              );
            },
          },
          {
            title: "Amount",
            field: "amount",
            render: (rowData: any) => {
              return rowData.voided
                ? formatMoney(0)
                : formatMoney(rowData.amount);
            },
          },
          {
            title: "Balance",
            field: "balance",
            render: (rowData: any) => {
              return formatMoney(rowData.balance);
            },
          },
          { title: "Period", field: "period" },
          {
            title: "",
            field: "actions",
            render: function (rowData: any) {
              let enablePrint = isPaymentTransaction(rowData.sysTransCodeId);
              let disableVoid = (isDisbursementOrRefund(rowData.sysTransCodeId) && isAfaIntegrationEnabled) || rowData.voided;
              let disableReverse = (isDisbursementOrRefund(rowData.sysTransCodeId) && isAfaIntegrationEnabled);
              return (
                <div
                  className={classes.options}
                  ref={(div) => {
                    transactionRefArray[rowData.transactionId] = div;
                  }}
                >
                  {rowLoadingState.includes(rowData.transactionId) ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Button
                      className={clsx(classes.actionButton)}
                      onClick={(e) => {
                        handleClick(e, rowData.transactionId);
                      }}
                      disableRipple={true}
                    >
                      <MoreVert />
                    </Button>
                  )}
                  <Backdrop
                    open={
                      popoverState.openedPopoverId === rowData.transactionId
                    }
                    onClick={(e: any) => {
                      if (
                        (e.target.className as string).indexOf(
                          "MuiBackdrop-root"
                        ) >= 0
                      ) {
                        handleClose();
                      }
                    }}
                    className={classes.backdrop}
                  >
                    <Popover
                      id={rowData.transactionId}
                      key={rowData.transactionId}
                      open={
                        popoverState.openedPopoverId === rowData.transactionId
                      }
                      onClose={handleClose}
                      style={{
                        transform: `translate(${popoverState.x}px, ${popoverState.y}px)`,
                      }}
                      PaperProps={{
                        style: {
                          boxShadow: "2px 3px 3px 3px rgba(0,0,0,0.03)",
                        },
                      }}
                    >
                      <div className={classes.wrapper}>
                        <Button
                          variant="text"
                          className={classes.popoverButton}
                          disabled={!enablePrint}
                          onClick={() => {
                            handlePrintReceipt(rowData.transactionId);
                            handleClose();
                          }}
                        >
                          Print Receipt
                        </Button>
                      </div>
                      <AccessManager
                        allowedPermissions={[
                          security.permissions.student.updateLedger,
                        ]}
                        renderNoAccess={() => null}
                      >
                        <div>
                          <Button
                            className={classes.popoverButton}
                            onClick={(e) => {
                              handleClickVoid(e, rowData.transactionId);
                              handleClose();
                            }}
                            disabled={disableVoid}
                          >
                            Void Transaction
                          </Button>
                        </div>
                        {!rowData.isReversed && !rowData.isReversal && (
                          <div>
                            <Button
                              className={classes.popoverButton}
                              onClick={(e) => {
                                handleReverseTransaction(e, rowData.transactionId);
                                handleClose();
                              }}
                              disabled={disableReverse}
                            >
                              Reverse Transaction
                            </Button>
                          </div>
                        )}
                        {(isSupportUser || !isAfaIntegrationEnabled || allowAFAOverrideEnabledDelete || (!rowData.isTitleIV)) && (
                          <div>
                            <Button
                              className={classes.popoverButton}
                              onClick={(e) => {
                                handleClickDelete(e, rowData.transactionId);
                                handleClose();
                              }}
                            >
                              Delete Transaction
                            </Button>
                          </div>
                        )}
                      </AccessManager>
                    </Popover>
                  </Backdrop>
                </div>
              );
            },
          },
        ]}
        icons={tableIcons}
        options={{
          search: true,
          sorting: false,

          actionsColumnIndex: 5,
          paging: false,
          headerStyle: {
            backgroundColor: theme.palette.secondary.light,
            zIndex: 2,
          },

          maxBodyHeight: "100%",
          rowStyle: (rowData) => {
            if (rowData.voided) {
              return {
                color: theme.palette.text.secondary,
                fontStyle: "italic",
                fontFamily: "Roboto"
              };
            }
            return {
              fontFamily: "Roboto"
            };
          },
        }}
        data={filteredLedgerItems}
        components={components}
      />
      <ConfirmationModal {...confirmationPopupState} />
      <ReverseTransaction open={reversalPopUpOpen} refreshLedgerItems={refreshLedgerItems} setSnackBar={props.setSnackBar} handleClose={closeReversalPopUp} transactionId={reverseTransId} isLoading={loading} setLoading={setLoading} />
    </div>
  );
};

export default LedgerGrid;
