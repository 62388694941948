export const SavedReportParametersApiAutoComplete = {
  config: {
    acId: "SavedReportParameters",
    cacheTime: 5,
    route: "/Report/GetSavedReportParameters",
    requestType: "GET",
    body: null,
    hasUrlParams: true,
    useCache: false,
    storeInCache: false,
    isCampusSpecific: true,
    isUserSpecific: false,
    loadOnRender: false,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
      return data;
    },
  },
};
