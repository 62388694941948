export interface IProgramDetails{
    id: string;
    name: string;
    statusId: string;
    campusId?: string;
    academicCalendarId: number;
    cipCode: string;
    credentialLevelId: string;
    ipedsCredentialLevelId?: number;
    edSpecialProgramIndicatorId?: number;
    accrualMethodId?: number;
    measuredInId?: number;
    is1098tExtract: boolean;
    gainfulEmploymentEnabled: boolean;
}

export class ProgramDetails implements IProgramDetails{
    id: string = "";
    name: string = "";
    statusId: string = "";
    campusId?: string = "";
    academicCalendarId: number =0;
    cipCode: string = "";
    credentialLevelId: string = "";
    ipedsCredentialLevelId?: number = undefined;
    edSpecialProgramIndicatorId?: number = undefined;
    is1098tExtract: boolean = false;
    gainfulEmploymentEnabled: boolean = false;

}