import * as React from "react";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { config } from "../../constants/reports/AdHocReportCategoryAutoComplete";


const AdHocReportCategoryAutoComplete = (props: FilterParams) => {
 const filterHandle = props.filterHandle;
  const onChange = (e: any) => {
    if (filterHandle) {
      filterHandle(e);
    }
  };

  return (
    <div>
      <ApiAutoComplete
        config={config}
        classes={{
          option: "text-black"
        }}
        label={props.hideLabel ? undefined: (props.label || "Report Category")}
        placeholder={props.hidePlaceholder ? undefined :(props.placeholder || "Report Category")}
        onChange={(e: any) => {
            onChange(e);
        }}
        inputRef={props.inputRef}
        error={props.error}
        helperText={props.helperText}
        multiple={props.multiple ?? false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        inputName={props.name}
        chipSize={props.chipSize}
        maxTags={props.maxTags}
      ></ApiAutoComplete>
    </div>
  );
};
export default AdHocReportCategoryAutoComplete;
