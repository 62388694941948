import API from "../../apiWrapper";
import { ILeaveOfAbsence } from "../../../interfaces/student/status/ILeaveOfAbsence";
import { ILeaveOfAbsenceReason } from "../../../interfaces/student/status/ILeaveOfAbsenceReason";

export const getLOAHistory = (
    studentEnrollmentId: string
  ) => {

      return API()
          .get("/AcademicRecords/StatusChanges/LeaveOfAbsence/GetLeaveOfAbsencesByEnrollment", {
              params: {
                studentEnrollmentId
              }
          })
          .then(
              (res: any) => {
                  if (res && res.data) {
                    return res.data as  Array<ILeaveOfAbsence>;
                   }
              },
              (error: any) => {
                  return error;
              }
          );          
  };

  export const updateLOAHistory = (
    loa: ILeaveOfAbsence,
    runValidation: boolean, disbursementConfirmation?: boolean
  ) => {
      return API()
          .post("/AcademicRecords/StatusChanges/LeaveOfAbsence/UpdateLeaveOfAbsence", loa, {
            params: {
                runValidation : runValidation,
                disbursementConfirmation : disbursementConfirmation              
            }
        })
          .then(
            (res: any) => {
                if (res && res.data) {
                    return res;
                }
                },
                    (error: any) => {
                    return error;
                }
            );;          
  };
  
  export const createLOA = (
    loa: ILeaveOfAbsence,  disbursementConfirmation?: boolean
  ) => {

      return API()
          .post("/AcademicRecords/StatusChanges/LeaveOfAbsence/CreateLeaveOfAbsence", loa, {
            params: {
                disbursementConfirmation : disbursementConfirmation              
            }})
          .then(
            (res: any) => {
                if (res && res.data) {
                    return res;
                }
                },
                    (error: any) => {
                    return error;
                }
            );;          
  };

  export const deleteLOA = (
    loa: ILeaveOfAbsence
  ) => {

      return API()
          .post("/AcademicRecords/StatusChanges/LeaveOfAbsence/DeleteLeaveOfAbsence", loa)
          .then(
            (res: any) => {
                if (res && res.data) {
                    return res;
                }
                },
                    (error: any) => {
                    return error;
                }
            );;          
  };

  export const getLOAReasons = (
    studentEnrollmentId: string
  ) => {

      return API()
          .get("/AcademicRecords/StatusChanges/LeaveOfAbsence/GetLeaveOfAbsenceReasons", {
              params: {
                studentEnrollmentId
              }
          })
          .then(
              (res: any) => {
                  if (res && res.data) {
                    return res.data as Array<ILeaveOfAbsenceReason>;
                   }
              },
              (error: any) => {
                  return error;
              }
          );          
  };


