import * as React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
	Grid,
	FormControl,
	FormLabel,
	FormHelperText,
	FormControlLabel,
	Switch,
} from "@material-ui/core";
import { FormContext, useForm } from "react-hook-form";
import { IStudentListingReportParams } from "../../../interfaces/reports/studentAccounts/IStudentListingReportParams";
import * as studentListingReportApi from "../../../api/reports/studentAccounts/studentListingReportApi";
import { ReportOutput } from "../../../enums/ReportOutput";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import StartEndDateFilter from "../../attendance/StartEndDateFilter";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import { useSelector } from "react-redux";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import EnrollmentStatusAutoComplete from "../../../components/AutoComplete/EnrollmentStatusAutoComplete";
import StudentGroupsAutoComplete from "../../../components/AutoComplete/StudentGroupsAutoComplete";
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			minHeight: "100%",
			height: "100%",
			padding: theme.spacing(2),
		},
		"& .makeStyles-content": {
			backgroundColor: theme.palette.background.paper,
		},
		paperRoot: {
			padding: theme.spacing(3, 2),
			width: "100%",
			minHeight: "100%",
			height: "100%",
			overflowX: "hidden",
			overflowY: "auto",
			flexDirection: "column",
			backgroundColor: theme.palette.background.paper,
		},
		searchActions: {
			marginTop: theme.spacing(3),
			textAlign: "left",
		},
		startDateField: {
			width: "100%",
		},
		fieldSet: {
			marginTop: theme.spacing(2),
		},
	})
);



const StudentListingReport = (props: any) => {
	const { report } = props;
	const classes = useStyles({});
	const [preview, setPreview] = React.useState<any>();
	const [previewLoader, setPreviewLoader] = React.useState<boolean>();
	const [value, setValue] = React.useState<any>({

	});

	let reportOverview = { report: report } as IReportOverview;

	const data = useForm<any>({ mode: "onBlur" });

	const campusId = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const [model, setModel] = React.useState({
		campusId: campusId as string,
		exportType: ReportOutput.Pdf as any,
		enrollmentStatusIds: [],
		studentGroupId: [],
		studentGroupIdExl: [],
		showSSN: false,
		groupByStudentGroups: true
	});
	const setEnrollmentStatus = (enrollmentStatusIds: any) => {
		let newStatuses: string[] = [];
		if (enrollmentStatusIds !== null) {
			if (Array.isArray(enrollmentStatusIds)) {
				newStatuses = enrollmentStatusIds.map((gr: any) => gr.value);
			} else {
				newStatuses.push(enrollmentStatusIds.value);
			}
		}

		return newStatuses;
	};
	const setStudentGroup = (studentGroups: any) => {
		let newGroups: string[] = [];
		if (studentGroups !== null) {
			if (Array.isArray(studentGroups)) {
				newGroups = studentGroups.map((gr: any) => gr.value);
			} else {
				newGroups.push(studentGroups.value);
			}
		}
		return newGroups;
	};

	const exportTypes: Array<DropDownListItem> = [
		{ text: "PDF", id: "1" },
		{ text: "Excel", id: "2" },
		{ text: "Word", id: "3" },
	];

	const exportTypeAutoComplete = {
		options: exportTypes,
		getOptionLabel: (option: DropDownListItem) => option.text,
	};

	const handleFieldOnChange = (
		fieldId: string,
		value: any,
		mapFunction?: Function
	) => {
		let updatedModel = model;
		let newValue = mapFunction ? mapFunction(value) : value;
		(updatedModel as any)[fieldId] = newValue;
		setModel({ ...updatedModel });
	};

	const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
		const item = items.find((opt) => {
			if (opt.id == value) return opt;
		});
		return item || ({} as DropDownListItem);
	};

	const { handleSubmit } = data;

	const onSubmit = async (isPreview: any): Promise<void> => {
		let params = await getReportParameters();
		const validation = await data.triggerValidation();
		if (validation) {
			if (isPreview) {
				studentListingReportApi
					.generateReport(params, isPreview)
					.then((res: any) => {
						if (res) {
							res.arrayBuffer().then((buffer: any) => {
								setPreview(buffer);
								setPreviewLoader(false);
							});
						} else setPreviewLoader(false);
					});
			} else await studentListingReportApi.generateReport(params);
		}
	};

	const filterHandler = (data: any) => {
		if (data) {
			setValue({ startDate: data.startDate, endDate: data.endDate });
			setModel({ ...data });
		} else {
			setValue({ startDate: new Date(), endDate: new Date() });
			setModel({
				campusId: model.campusId,
				exportType: model.exportType,
				enrollmentStatusIds: model.enrollmentStatusIds,
				studentGroupId: model.studentGroupId,
				studentGroupIdExl: model.studentGroupIdExl,
				showSSN: model.showSSN,
				groupByStudentGroups: model.groupByStudentGroups,
			});
		}
	};

	const getReportParameters = async () => {
		const success = await data.triggerValidation();
		if (model && success) {
			let params: IStudentListingReportParams = {
				campusId: model.campusId,
				exportType: model.exportType,
				enrollmentStatusIds: model.enrollmentStatusIds,
				studentGroupId: model.studentGroupId,
				studentGroupIdExl: model.studentGroupIdExl,
				//studentgroupidexl: model.studentgroupidexl,
				showSSN: model.showSSN,
				groupByStudentGroups: model.groupByStudentGroups,
			};
			return params;
		}
		return {} as IStudentListingReportParams;
	};

	let parameters = (
		<div className={classes.root}>
			<FormContext {...data}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container direction="row" spacing={1}>
						<Grid item md={6} sm={6} xs={12}>
							<EnrollmentStatusAutoComplete
								filterHandle={(statuses: any) => {
									handleFieldOnChange(
										"enrollmentStatusIds",
										statuses,
										setEnrollmentStatus
									);
								}}
								id="enrollmentStatusIds"
								name="enrollmentStatusIds"
								label="Enrollment Status *"
								multiple={true}
								error={!!data.errors.enrollmentStatusIds}
								inputRef={data.register({
									validate: {
										atLeastOneRequired: (value) =>
											(model.enrollmentStatusIds && model.enrollmentStatusIds.length > 0) ||
											"Status(es) are required.",
									},
								})}
								helperText={
									data.errors.enrollmentStatusIds
										? "At least one status is required."
										: undefined
								}
								valueFilter={
									model?.enrollmentStatusIds
										? {
											key: "value",
											values: model?.enrollmentStatusIds,
										}
										: undefined
								}
							/>
						</Grid>
						<Grid item md={12} sm={12} xs={12}>
							<StudentGroupsAutoComplete
								id="studentGroupId"
								name="studentGroupId"
								campusId={campusId}
								disabled={false}
								filterHandle={(groups: any) => {
									handleFieldOnChange("studentGroupId", groups, setStudentGroup);
								}}
								label="Student Group "
								multiple={true}
								includeAllValue={true}
								showIncludeAllValue={true}
								valueFilter={
									model?.studentGroupId
										? {
											key: "value",
											values: model?.studentGroupId,
										}
										: undefined
								}
							/>
						</Grid>
						<Grid item md={12} sm={12} xs={12}>
							<StudentGroupsAutoComplete
								id="studentGroupIdExl"
								name="studentGroupIdExl"
								campusId={campusId}
								disabled={false}
								filterHandle={(groups: any) => {
									handleFieldOnChange("studentGroupIdExl", groups, setStudentGroup);
								}}
								label="Exclude Student Groups"
								multiple={true}
								includeAllValue={true}
								showIncludeAllValue={true}
								valueFilter={
									model?.studentGroupIdExl
										? {
											key: "value",
											values: model?.studentGroupIdExl,
										}
										: undefined
								}
							/>
						</Grid>
						<Grid item md={3} sm={3} xs={3}>
							<Autocomplete
								{...exportTypeAutoComplete}
								autoComplete
								includeInputInList
								onChange={(e: any, value: any) => {
									handleFieldOnChange(
										"exportType",
										value ? value : undefined,
										(v: any) => v?.id
									);
								}}
								value={getSelectedItem(exportTypes, model.exportType)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Export Type *"
										name="exportType"
										error={!!data.errors.exportType}
										inputRef={data.register({ required: true })}
										helperText={
											data.errors.exportType
												? "Export Type is required."
												: undefined
										}
										fullWidth
									/>
								)}
							/>
						</Grid>
						<Grid item md={8} sm={8} xs={8}>
							<FormControl>
								<FormControlLabel
									label="Show SSN"
									control={
										<Switch
											checked={model.showSSN}
											value="showSSN"
											onChange={(e: any) => {
												setModel({ ...model, showSSN: e.target.checked })
											}}
											color="primary"
										/>
									}
								/>
							</FormControl>
							<FormControl>
								<FormControlLabel
									label="Group by Student Groups"
									control={
										<Switch
											checked={model.groupByStudentGroups}
											value="groupByStudentGroups"
											onChange={(e: any) => {
												setModel({ ...model, groupByStudentGroups: e.target.checked })
											}}
											color="primary"
										/>
									}
								/>
							</FormControl>
						</Grid>
					</Grid>
				</form>
			</FormContext>
		</div>
	);

	reportOverview.parameters = parameters;

	return (
		<ReportOverview
			reportOverview={reportOverview}
			filterHandler={filterHandler}
			getReportParameters={getReportParameters}
			exportHandler={onSubmit}
			preview={preview}
			previewLoader={previewLoader}
		/>
	);
};

export default StudentListingReport;
