import { ApiAutoCompleteConfigItem } from '../../../interfaces/ApiAutoCompleteConfigItem';
import IProgramCourse from '../../../interfaces/setup/academics/ProgramDefinition/IProgramCourse';

const config: ApiAutoCompleteConfigItem = {
	acId: 'programVersioncourses',
	cacheTime: 5,
	route: '/AcademicRecords/ProgramVersions/GetProgramVersionDefinition',
	requestType: 'GET',
	body: null,
	hasUrlParams: true,
	useCache: false,
	storeInCache: false,
	isCampusSpecific: true,
	isUserSpecific: false,
	loadOnRender: true,
	defaultSelectedFirstItem: false,
	optionsMappedFx: (data: Array<IProgramCourse>) => {
		return data.map((item: IProgramCourse) => ({ value: item.courseId, text: item.courseTitle }));
	},
};

export default config;