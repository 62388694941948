import axios, { AxiosResponse } from "axios";
import API from "../apiWrapper";
import { PermissionTemplateDetail } from '../../interfaces/security/PermissionTemplateDetail';
import { UpdateUserPermissions } from '../../interfaces/security/UpdateUserPermissions';
import { stringify } from 'qs'
import { SaveNewPermissionTemplate } from "../../interfaces/security/SaveNewPermissionTemplate";


export const getPermissionsForUser = (userId:string) => {
    return API()
        .get("/Security/Permission/GetPermissionsForUser", {
            params: {
                userId
            },
            paramsSerializer: params => {
                return stringify(params)
            }
        })
        .then(
            (res: any) => {
                if (res && res.data) {
                    return res.data;
                }
                return null;
            },
            (error: any) => {
                return error;
            }
        );
};

export const getPermissionTemplateDetails = (templateIds: Array<number>) => {
    return API()
        .get("/Security/PermissionTemplate/GetPermissionTemplateDetails", {
            params: {
                templateIds
            },
            paramsSerializer: params => {
                return stringify(params)
            }
        })
        .then(
            (res: any) => {
                if (res && res.data) {
                    return res.data as Array<PermissionTemplateDetail>;
                }
                return null;
            },
            (error: any) => {
                return error;
            }
        );
};

export const updateUserPermissions = (userPermissions: UpdateUserPermissions) => {
    return API()
        .post("/Security/Permission/UpdateUserPermissions", userPermissions)
        .then(
            (res: any) => {
                if (res && res.data) {
                    return res.data as UpdateUserPermissions;
                }
                return null;
            },
            (error: any) => {
                return error;
            }
        );
};

export const savePermissionTemplate = (template: SaveNewPermissionTemplate) => {
    return API()
        .post("/Security/PermissionTemplate/SavePermissionTemplate", template)
        .then(
            (res: any) => {
                if (res && res.data) {
                    return res.data as SaveNewPermissionTemplate;
                }
                return null;
            },
            (error: any) => {
                return error;
            }
        );
};