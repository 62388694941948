import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  DialogActions,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import clsx from "clsx";
import React from "react";
import { FieldError, FormContext, useForm } from "react-hook-form";
import * as ledgerApi from "../../../../../api/student/studentAccounts/ledgerApi";
import PaymentTypesAutoComplete from "../../../../../components/AutoComplete/PaymentTypesAutoComplete";
import ProgressSaveButton from "../../../../../components/_Layout/Buttons/ProgressSaveButton";
import CurrencyInput from "../../../../../components/_Layout/Inputs/CurrencyInput";
import { AllowSpaceDigitsAndLettersOnlyRegex } from "../../../../../constants/uiConstants/inputConstants";
import { IStudentPayment } from "../../../../../interfaces/student/financials/IStudentPayment";
import { EmptyGuid } from "../../../../../utils/constants";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      width: "100%",
    },
    fullWidthField: {
      width: "100%",
    },
    buttonSection: {
      marginTop: theme.spacing(3),
    },
    saveButton: {
      width: 90,
    },
    switch: {
      marginLeft: "0px",
      paddingTop: theme.spacing(2),
      width: "140px",
    },
    printSaveButton: {
      width: 90,
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
  })
);

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& .MuiFormLabel-root": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "80%",
      },
    },
  })
)(TextField);

type StudentPaymentProps = {
  model: IStudentPayment;
  setPopupState: any;
  setTransactionTypeValue: any;
  transactionType: any;
  refreshLedgerItems: any;
  setSnackBar: any;
  enrollmentId: string;
  campusId: string;
  creditDebit: any;
};

const StudentPayment = (props: StudentPaymentProps) => {
  const classes = useStyles({});

  const data = useForm<any>({
    mode: "onBlur",
  });

  const [submitButtonText, setSubmitButtonText] = React.useState<string>(
    "Save"
  );
  const [print, setPrint] = React.useState<boolean | undefined>(true);
  const printButtonClass = clsx({
    [classes.printSaveButton]: print,
    [classes.saveButton]: !print,
  });
  const { handleSubmit } = data;
  const [loaderState, setLoaderState] = React.useState<boolean>(false);
  const [model, setModel] = React.useState<IStudentPayment>({
    transactionCodeId: props.transactionType.value,
    transactionDate: new Date().toLocaleDateString(),
    paymentTypeId: null,
    documentId: "",
    amount: undefined,
    transactionId: EmptyGuid,
    studentEnrollmentId: props.enrollmentId,
    creditDebit : props.creditDebit,
  });

  const handleFieldOnChange = (fieldId: string, value: any) => {
    let updatedModel = model;
    if (fieldId === 'transactionDate'){
      (updatedModel as any)[fieldId] = new Date(value).toLocaleDateString();
      setModel({ ...updatedModel });
    }else { 
      (updatedModel as any)[fieldId] = value;
      setModel({ ...updatedModel });}
  };

  const handleClose = () => {
    props.setPopupState(false);
    props.setTransactionTypeValue(null);
    props.refreshLedgerItems();
  };

  const handlePrintOnChange = (value: boolean) => {
    setPrint(value);
    setSubmitButtonText("Save");
  };

  const unFormattedNumber = (formattedNumber: any) => {
    const cleanedInput = (formattedNumber + "").replace(/[^\d.-]/g, "");
    const result = Number(cleanedInput);
		return result;
  };
  const onAmountChanged = (studentPaymentAmount: any) =>{
    return unFormattedNumber(studentPaymentAmount) > 0 || "Amount must be greater than 0";
 };
  const onSubmit = (d: any) => {
    if (model) {
      setLoaderState(true);
      ledgerApi.postStudentPayment(model).then(
        (response: any) => {
          if (response.resultStatus === 0) {
            setLoaderState(false);
            props.setSnackBar((props: any) => {
              return {
                variant: "success",
                showSnackBar: true,
                messageInfo: response.resultStatusMessage,
              };
            });
            handleClose();
            if (print && response.result.transactionId !== EmptyGuid) {
              ledgerApi
                .printReceipt(response.result.transactionId, props.campusId)
                .then(
                  (printResponse: any) => {
                    if (printResponse.status === 200) {
                      setLoaderState(false);
                      props.setSnackBar((props: any) => {
                        return {
                          variant: "success",
                          showSnackBar: true,
                          messageInfo: "Receipt created successfully",
                        };
                      });
                      handleClose();
                    } else {
                      setLoaderState(false);
                      props.setSnackBar((props: any) => {
                        return {
                          variant: "error",
                          showSnackBar: true,
                          messageInfo: "Error printing Receipt",
                        };
                      });
                    }
                  },
                  (exception: any) => {
                    setLoaderState(false);
                    props.setSnackBar((snackBarProps: any) => {
                      return {
                        variant: "error",
                        showSnackBar: true,
                        messageInfo: exception,
                      };
                    });
                  }
                );
            }
          } else {
            setLoaderState(false);
            props.setSnackBar((props: any) => {
              return {
                variant: "error",
                showSnackBar: true,
                messageInfo: response.data,
              };
            });
          }
        },
        (exception: any) => {
          setLoaderState(false);
          props.setSnackBar((snackBarProps: any) => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo: exception,
            };
          });
        }
      );
    }
  };

  return (
    <FormContext {...data}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  clearable
                  format={"MM/dd/yyyy"}
                  placeholder="mm/dd/yyyy"
                  id="transactionDate"
                  name="transactionDate"
                  label="Transaction Date *"
                  TextFieldComponent={ThemeTextField}
                  className={classes.fullWidthField}
                  value={model.transactionDate ? model.transactionDate : null}
                  onChange={(value: any) => {
                    handleFieldOnChange(
                      "transactionDate",
                      value ? value : null
                    );
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  autoOk={true}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <PaymentTypesAutoComplete
                id="paymentTypeId"
                name="paymentTypeId"
                label="Payment Type * "
                valueFilter={
                  model.paymentTypeId
                    ? {
                        key: "value",
                        values: [model.paymentTypeId],
                      }
                    : undefined
                }
                filterHandle={(v: any) => {
                  handleFieldOnChange("paymentTypeId", v ? v.value : null);
                }}
                error={!!data.errors.paymentTypeId}
                inputRef={data.register({ required: true })}
                helperText={
                  data.errors.paymentTypeId
                    ? "Payment Type required."
                    : undefined
                }
              />
            </Grid>
            <Grid item xs={12}>
              <ThemeTextField
                id="documentId"
                label="Document ID"
                name="documentId"
                className={classes.fullWidthField}
                value={model.documentId}
                onChange={(e) => {
                  handleFieldOnChange("documentId", e.target.value);
                }}
                error={!!data.errors.documentId}
                inputRef={data.register({
                  validate: (v: any) => {
                    return !v && (model.paymentTypeId as any) !== 1 /* Cash */
                      ? false
                      : true;
                  },
                })}
                helperText={
                  data.errors.documentId ? "Document id is required" : null
                }
                inputProps={{ pattern: AllowSpaceDigitsAndLettersOnlyRegex }}
              />
            </Grid>
            <Grid item xs={12}>
              <CurrencyInput
                control={data.control}
                className={classes.textField}
                label="Amount *"
                name="amount"
                key="amount"
                onChange={handleFieldOnChange}
                value={model.amount}
                error={!!data.errors.amount}
                creditDebit={props.creditDebit}
                inputRef={data.register({
                  required: "Amount is required.",
                  validate: {
                    valueGreaterThanZero:onAmountChanged,
                  },
                })}
                helperText={
                  data.errors.amount
                    ? (data.errors.amount as FieldError).message
                    : undefined
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <FormControlLabel
                id="doPrint"
                className={classes.switch}
                labelPlacement="start"
                label="Print Receipt"
                control={
                  <Switch
                    checked={print}
                    value={print}
                    onChange={(e: any) => {
                      handlePrintOnChange(e.target.checked);
                    }}
                    color="primary"
                  />
                }
              />
            </Grid>
          </Grid>
          <br />
        </div>
        <div>
          <Grid item xs={12}>
            <DialogActions className={classes.buttonSection}>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={2}></Grid>
                <Grid
                  item
                  container
                  xs={4}
                  alignContent="center"
                  alignItems="center"
                  justify="center"
                >
                  <ProgressSaveButton
                    text={submitButtonText}
                    onClick={() => {
                      handleSubmit(onSubmit);
                    }}
                    loading={loaderState}
                    buttonClassName={printButtonClass}
                  ></ProgressSaveButton>
                </Grid>
                <Grid
                  item
                  container
                  xs={4}
                  alignContent="center"
                  alignItems="center"
                  justify="center"
                >
                  <Button
                    onClick={handleClose}
                    color="secondary"
                    variant="contained"
                    type="button"
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
            </DialogActions>
          </Grid>
        </div>
      </form>
    </FormContext>
  );
};

export default StudentPayment;
