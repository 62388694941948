import API from "../apiWrapper";

import { IStudentAppIntegrationConfiguration } from "../../interfaces/integration/IStudentAppIntegrationConfiguration";


export const upsertConfiguration = (model: IStudentAppIntegrationConfiguration) => {
    return API()
        .post("/SystemCatalog/StudentAppIntegrationConfiguration/UpsertStudentAppIntegrationConfiguration", model
        )
        .then(
            (res: any) => {
                if (res) {
                    return res.data;
                }
                return false
            },
            (error: any) => {
                return false;
            }
        );
};

export const syncMobileAppData = (campusId: string, type: string | null) => {
    return API()
        .get("/SystemCatalog/StudentAppIntegrationConfiguration/SyncMobileAppData", {
            params: {
                campusId,
                type
            }
        })
        .then(
            (res: any) => {
                if (res) {
                    return res.data;
                }
                return false
            },
            (error: any) => {
                return false;
            }
        );
};

export const getStudentAppConfiguration = (campusId: string): Promise<IStudentAppIntegrationConfiguration> => {
    return API()
        .get("SystemCatalog/StudentAppIntegrationConfiguration/GetStudentAppIntegrationConfiguration", {
            params: {
                campusId
            }
        })
        .then(
            (res: any) => {

                return res && res.data
            },
            (error: any) => {
                if (error.response) {
                    throw new Error(error.response.data);
                }
                throw (new Error('Unknown error'));
            }
        );
}