import React, { useState } from "react";
import clsx from "clsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import {
	Typography,
	Card,
	CardContent,
	InputAdornment,
	Checkbox,
	Switch,
	FormControlLabel
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Add from "@material-ui/icons/Add";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.paper.background,
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2)
			}
		},
		"& .makeStyles-content": {
			backgroundColor: theme.palette.paper.background
		},
		paperRoot: {
			padding: theme.spacing(2),
			width: "100%",
			minHeight: "100%",
			height: "100%",
			overflowX: "hidden",
			overflowY: "auto",
			flexDirection: "column",
			backgroundColor: theme.palette.paper.background
		},
		buttonColorPrimary: {
			backgroundColor: theme.palette.buttons.primary,
			color: theme.palette.primary.contrastText,
			"&:hover": {
				backgroundColor: theme.palette.primary.main
			},
			textDecoration: "bold",
			padding: theme.spacing(1),
			marginTop: theme.spacing(3),
			whiteSpace: "nowrap"
		},
		cardSeparator: {
			marginBottom: theme.spacing(1)
		},
		cardTitle: {
			fontSize: 17,
			color: theme.palette.black,
			textDecoration: "bold",
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1)
		},
		textField: {
			marginRight: theme.spacing(1),
			width: "100%"
		},
		headerText: {
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(0)
		},
		cardContent: {
			padding: theme.spacing(1, 3)
		}
	})
);

interface OptionType {
	text: string;
	id: string;
}

const testData = [
	{ text: "Austin", id: "austin" },
	{ text: "Miami", id: "miami" },
	{ text: "New York", id: "newYork" },
	{ text: "Raleigh", id: "raleigh" },
	{ text: "Seattle", id: "seattle" },
	{ text: "San Diego", id: "sanDiego" },
	{ text: "Colorado Springs", id: "coloradoSprings" },
	{ text: "Lexington", id: "lexington" },
	{ text: "Jacksonville", id: "jacksonville" },
	{ text: "Columbus", id: "columbus" }
];

const defaultProps = {
	options: testData,
	getOptionLabel: (option: OptionType) => option.text
};

const ThemeTextField = withStyles((theme: any) => createStyles({}))(TextField);

const UpsertCustomField = (props: any) => {
	const classes = useStyles({});

	const [isCreatingNewField, setIsCreatingNewField] = useState<boolean>(false);

	const addButtonClicked = () => {
		setIsCreatingNewField(true);
	};

	return (
		<div>
			<Card square={true}>
				<Typography className={clsx(classes.cardTitle)}>
					<div className={classes.headerText}>Create Custom Field</div>
				</Typography>
				<CardContent className={classes.cardContent}>
					<Grid container direction="row" spacing={3}>
						<Grid item md={3} sm={5} xs={8}>
							<ThemeTextField
								id="Field Name"
								className={classes.textField}
								label="Field Name *"
							/>
						</Grid>

						<Grid item md={3} sm={5} xs={8}>
							<FormControlLabel
								control={
									<Switch
										checked={false}
										onChange={() => {}}
										value="isRequiredChecked"
										color="primary"
									/>
								}
								label="Is Required"
								labelPlacement="start"
							></FormControlLabel>
						</Grid>

						<Grid item md={6} sm={7} xs={4}></Grid>

						<Grid item md={3} sm={5} xs={8}>
							<Autocomplete
								{...defaultProps}
								id="Type"
								renderInput={params => (
									<ThemeTextField {...params} label="Type" fullWidth />
								)}
							/>{" "}
						</Grid>

						<Grid item md={3} sm={5} xs={8}>
							<Autocomplete
								{...defaultProps}
								id="Length(Max. Number of characters)"
								renderInput={params => (
									<ThemeTextField
										{...params}
										label="Length(Max. Number of characters)"
										fullWidth
									/>
								)}
							/>
						</Grid>

						<Grid item md={6} sm={7} xs={4}></Grid>

						<Grid item md={3} sm={5} xs={8}></Grid>
					</Grid>
				</CardContent>
				<Grid container direction="row" spacing={3}>
					<Grid item md={3} sm={5} xs={8}>
						<Typography className={clsx(classes.cardTitle)}>
							<div className={classes.headerText}>Create Custom Field</div>
						</Typography>

						<CardContent className={classes.cardContent}>
							<Grid container direction="row" spacing={0}>
								<Grid item xs={12}>
									<ThemeTextField
										id="List Item"
										className={classes.textField}
										label="List Item"
									/>
								</Grid>
							</Grid>
						</CardContent>
					</Grid>
				</Grid>
			</Card>
		</div>
	);
};

export default UpsertCustomField;
