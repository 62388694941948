import React from 'react';
import MaterialTable from 'material-table';


const Exception = () => {

	return (
		<div style={{ maxWidth: '100%' }}>
			<MaterialTable
				columns={[
					{ title: 'Type', field: 'name' },
					{ title: 'Student Name', field: 'surname' },
					{ title: 'Description', field: 'birthYear', type: 'numeric' },
					{ title: 'Exception Message', field: 'birthCity', lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' }},
					{ title: 'Key', field: 'name' },
					{ title: 'Date', field: 'surname' },
					{ title: 'Actions', field: 'birthYear', type: 'numeric' } 
				]}
				options={{
					rowStyle: () => {
						return {
							fontFamily: 'Roboto'
						};
					},
				}}
				data={[{ name: 'Mehmet', innerText:'this is inner text' ,surname: 'Baran', birthYear: 1987, birthCity: 63 },
					{ name: 'Khan Zada', innerText:'PM' ,surname: 'Baran', birthYear: 1987, birthCity: 63 }]}
				title="Demo Title"
				detailPanel={[
					{
						tooltip: 'Show Name',
						render: rowData => {
							return (
								<div
									style={{
										fontSize: 100,
										textAlign: 'center',
										color: 'white',
										backgroundColor: '#43A047',
									}}
								>
									{rowData.innerText}
								</div>
							);
						},
					}
				]}
			/>
		</div>
	);
};

export default Exception;
