import * as React from "react";
import { useEffect, useRef } from "react";
import { apiAutoCompleteConfig } from "../../constants/apiAutoComplete.config";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& label.Mui-focused": {
				color: theme.palette.primary.white
			},
			"& input": {
				color: theme.palette.black,
				opacity: 1,
				fontWeight: theme.typography.fontWeightMedium,
				fontSize: 14,
				padding: 10
			},
			"& input::placeholder": {
				fontWeight: theme.typography.fontWeightMedium,
				fontSize: 13
			},
			"& .MuiInputBase-root": {
				backgroundColor: theme.palette.site.secondary,
				borderRadius: 5
			}
		}
	})
)(TextField);

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {
			width: "100%"
		},
		searchIcon: {
			fontSize: 30,
			paddingLeft: 10,
			color: theme.palette.icon.primary,
			cursor: "pointer"
		}
	})
);

export interface CampusAutoSelectFilterParams extends FilterParams{
	notSelectCurrentCampus?:boolean,
  }

const MultiCampusAutoComplete = (props: CampusAutoSelectFilterParams) => {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const [valueFilter, setValueFilter] = React.useState<any>(null);

	const campusOnChange = (e: any) => {
		if (props.filterHandle) {
			props.filterHandle(e);
		}
	};
	const inputRef: any = useRef(null);
	useEffect(() => {
		if (!props.valueFilter && props.notSelectCurrentCampus != true) {
			setValueFilter({
				key: "value",
				values: [userSelectedCampus]
			});
		} else {
			setValueFilter(props.valueFilter);
		}
	}, [props.valueFilter, userSelectedCampus]);

	return (
		<div className={clsx(classes.divWidth)}>
			<ApiAutoComplete
				id={props.id}
				loading={userSelectedCampus ? false : true}
				config={apiAutoCompleteConfig.campus}
				classes={{
					option: "text-black"
				}}
				label={props.label ?? ""}
				onChange={(e: any) => {
					campusOnChange(e);
				}}
				disableClearable={true}
				multiple={props.multiple ?? true}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
				includeAllValue={props.includeAllValue}
				showIncludeAllValue={props.showIncludeAllValue}
				inputRef={props.inputRef ? props.inputRef : inputRef}
				error={props.error}
				helperText={props.helperText}
				inputName={props.name}
			></ApiAutoComplete>
		</div>
	);
};
export default MultiCampusAutoComplete;
