import React from 'react';

const ComponentImport = (reportName: string) => React.lazy(() => import(`./${reportName}/index`));

const conponentNames: { [key: string]: string } = {
	'Illinois Transcript Report': 'IllinoisTranscript'
};

export const Components = <P extends Object>(props: { reportName: string; componentsProps: React.PropsWithChildren<P | never> }) => {
	const [Component, setComponent] = React.useState<React.ComponentType<React.PropsWithChildren<P | never>>>();
	React.useEffect(() => {
		const loadComponent = async () => {
			try {
				const name = conponentNames[props.reportName];
				const cmp = await ComponentImport(name);
				setComponent(cmp);
			} catch (error) {
				setComponent(undefined);
			}
		};
		loadComponent();
	}, [props.reportName]);
	return Component ? <Component {...props.componentsProps} /> : null;
};


const ComponentImport2 = (reportName: string) => React.lazy(() => import(`./${reportName}/index`));

const conponentNames2: { [key: string]: string } = {
	'Transcript': 'Transcript'
};

export const Components2 = <P extends Object>(props: { reportName: string; componentsProps: React.PropsWithChildren<P | never> }) => {
	const [Component, setComponent] = React.useState<React.ComponentType<React.PropsWithChildren<P | never>>>();
	React.useEffect(() => {
		const loadComponent = async () => {
			try {
				const name = conponentNames2[props.reportName];
				const cmp = await ComponentImport2(name);
				setComponent(cmp);
			} catch (error) {
				setComponent(undefined);
			}
		};
		loadComponent();
	}, [props.reportName]);
	return Component ? <Component {...props.componentsProps} /> : null;
};


const ComponentImport3 = (reportName: string) => React.lazy(() => import(`./${reportName}/index`));
const componentNames3: { [key: string]: string } = {
	'Progress': 'Progress'
};
export const Components3 = <P extends Object>(props: { reportName: string; componentsProps: React.PropsWithChildren<P | never> }) => {
	const [Component, setComponent] = React.useState<React.ComponentType<React.PropsWithChildren<P | never>>>();
	React.useEffect(() => {
		const loadComponent = async () => {
			try {
				const name = componentNames3[props.reportName];
				const cmp = await ComponentImport3(name);
				setComponent(cmp);
			} catch (error) {
				setComponent(undefined);
			}
		};
		loadComponent();
	}, [props.reportName]);
	return Component ? <Component {...props.componentsProps} /> : null;
};

const ComponentImport4 = (reportName: string) => React.lazy(() => import(`./${reportName}/index`));
const componentNames4: { [key: string]: string } = {
	'Disbursement Notice': 'DisbursementNotice'
};
export const Components4 = <P extends Object>(props: { reportName: string; componentsProps: React.PropsWithChildren<P | never> }) => {
	const [Component, setComponent] = React.useState<React.ComponentType<React.PropsWithChildren<P | never>>>();
	React.useEffect(() => {
		const loadComponent = async () => {
			try {
				const name = componentNames4[props.reportName];
				const cmp = await ComponentImport4(name);
				setComponent(cmp);
			} catch (error) {
				setComponent(undefined);
			}
		};
		loadComponent();
	}, [props.reportName]);
	return Component ? <Component {...props.componentsProps} /> : null;
};


