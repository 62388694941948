import API from "../apiWrapper";
import { LeadSearchInputPaged } from "../../interfaces/admissions/LeadSearchInputPaged";
import { stringify } from "qs";
import { ILead } from "../../interfaces/admissions/ILead";
import { ILeadImage } from "../../interfaces/admissions/ILeadImage";


export const searchLeads = (campusId: string, searchParams: LeadSearchInputPaged | null, includeEnrolled: boolean = false) => {
    return API()
        .get("/Leads/Search", {
            params: { campusId, ...searchParams, includeEnrolled },
            paramsSerializer: params => {
                return stringify(params)
            }
        }
        )
        .then(
            (res: any) => {
                return res;
            },
            (error: any) => {
                return error;
            }
        );
};


export const importLead = (lead: ILead) => {
    return API()
        .post("/Leads/ImportLead", lead
        )
        .then(
            (res: any) => {
                return res;
            },
            (error: any) => {
                return error;
            }
        );
};

export const uploadStudentPhoto = (image: ILeadImage) => {
    return API()
        .post("/Leads/UploadStudentPhoto", image
        )
        .then(
            (res: any) => {
                return res;
            },
            (error: any) => {
                return error;
            }
        );
};


export const pullExternalCrmLead = (externalLeadId: string, campusId: string) => {
    return API()
        .get("/Leads/Crm/ImportExternalLead?externalLeadId=" + externalLeadId+"&campusId="+campusId)
        .then(
            (res: any) => {
                return res;
            },
            (error: any) => {
                return error;
            }
        );
};



