import API from "../../apiWrapper";
import {
  ITransferStudentToCampusDetail,
  ITransferStudentToProgramDetail,
  IChangeProgramVersionDetail,
} from "../../../interfaces/student/transfer/IStudentTransferInfo";

export const TransferStudentToCampus = (newCampus: ITransferStudentToCampusDetail) => {
  return API()
    .post("/AcademicRecords/TransferStudent/TransferStudentToCampus", newCampus)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const TransferStudentToProgram = (newProgram: ITransferStudentToProgramDetail) => {
    return API()
      .post("/AcademicRecords/TransferStudent/TransferStudentToProgram", newProgram)
      .then(
        (res: any) => {
          if (res.data) {
            return res.data;
          }
        },
        (error: any) => {
          return error;
        }
      );
  };

  export const UpdateStudentProgramVersion = (newProgramVersion: IChangeProgramVersionDetail) => {
    return API()
      .post("/AcademicRecords/TransferStudent/UpdateStudentProgramVersion", newProgramVersion)
      .then(
        (res: any) => {
          if (res.data) {
            return res.data;
          }
        },
        (error: any) => {
          return error;
        }
      );
  };

  export const GetDetailOnProgramVersionChanges = (newProgramVersion: IChangeProgramVersionDetail) => {
    return API()
      .get("/AcademicRecords/TransferStudent/GetDetailOnProgramVersionChanges", {
        params: {
          enrollmentId: newProgramVersion.EnrollmentId,
          newProgramVersionId: newProgramVersion.NewProgramVersionId,
          oldProgramVersionId: newProgramVersion.OldProgramVersionId,
        },
      })
      .then(
        (res: any) => {
          if (res.data) {
            return res.data;
          }
        },
        (error: any) => {
          return error;
        }
      );
  };

  export const TransferStudentToProgramSupport = (newProgram: ITransferStudentToProgramDetail) => {
    return API()
      .post("/AcademicRecords/TransferStudent/TransferStudentToProgramSupport", newProgram)
      .then(
        (res: any) => {
          if (res.data) {
            return res.data;
          }
        },
        (error: any) => {
          return error;
        }
      );
  };