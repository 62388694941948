import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Paper,
  Grid,
  Dialog,
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Card,
  CardContent,
} from "@material-ui/core";
import { SentimentVeryDissatisfied } from "@material-ui/icons";
import clsx from "clsx";
import { useSelector } from "react-redux";
import * as documentsApi from "../../../api/student/documents/documentsApi";
import * as storageApi from "../../../api/storage/storageApi";
import { IStudentDocumentModule } from "../../../interfaces/student/documents/IStudentDocumentModule";
import { DropzoneArea } from "material-ui-dropzone";
import { useTheme } from "@material-ui/core/styles";
import { IGetFileParams } from "../../../interfaces/storage/IGetFileParams";
import { FileConfigurationFeature } from "../../../enums/FileConfigurationFeature";
import StorageFileModel from "../../../models/storage/StorageFileModel";
import CustomSnackbar from "../../../components/notifications/CustomSnackbar";
import { CustomSnackBarProps } from "../../../interfaces/common/CustomSnackBarProps";
import DocumentsGrid from "./DocumentsGrid";
import DocumentUpload from "./DocumentUpload";
import AccessManager from "../../../components/security/AccessManager";
import { security } from "../../../constants/Security/securityConstants";
const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      width: "100%",
      minHeight: "100%",
      height: "95%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      marginTop: theme.spacing(3),
      backgroundColor: theme.palette.paper.background,
    },
    tableContainer: {
      margin: theme.spacing(2),
    },
    loader: {
      margin: "auto",
    },
    cardWrapper: {
      height: "100%",
    },
    dropZone: {
      backgroundColor: theme.palette.white,
      width: "100%",
      minHeight: "150px",
      height: 150,
      marginBottom: theme.spacing(2),
    },
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%"
    },
    sadFaceWrapper: {
      textAlign: "center",
      color: "#f1f1f1",
      fontSize: "10em",
    },
    noDocumentMessage: {
      color: "#f1f1f1",
      textAlign: "center",
      fontSize: "3em",
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
    },
    mainGrid: {
      height: "calc(100% - 20px)"
    }
  })
);

const Documents = (props: any) => {
  const classes = useStyles({});
  const studentId = useSelector((state: any) => state.student.studentId);
  const campusId = useSelector(
    (state: any) => state.student.enrollments[0].campusId
  );

  const [documents, setDocuments] = React.useState<Array<IStudentDocumentModule>>([]);
  const [files, setFiles] = React.useState<any>([]);
  const [currentFile, setCurrentFile] = React.useState<StorageFileModel>(
    new StorageFileModel()
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openDocumentUpload, setOpenDocumentUpload] = React.useState(false);
  const [workingFile, setWorkingFile] = React.useState<any>(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = () => {
    if (currentFile && currentFile.fileUri) {
      storageApi
        .getFile({
          fileName: currentFile.fileName,
          displayName: currentFile.displayName,
          campusId: campusId,
          studentId: studentId,
          featureType: FileConfigurationFeature.DOC,
        } as IGetFileParams)
        .then(
          (response: string) => { },
          (exception: any) => { }
        );
    }
  };

  const getDocumentsData = () => {
    setDocuments([]);
    if (studentId) {
      documentsApi.getDocumentsByStudentId(studentId).then(
        (response: Array<IStudentDocumentModule>) => {
          if (response && response.length > 0) {
            setDocuments(response);
          }
        },
        (exception: any) => { }
      );
    }
  }

  React.useEffect(() => {
    getDocumentsData();
  }, [studentId]);


  const handleFileChange = (files: any) => {
    setFiles(files);
  };

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );
  const handleDrop = (file: any) => {
    console.log(file);
    setWorkingFile(file[0]);
    setOpenDocumentUpload(true);
  };


  return (
    <React.Fragment>
      <DocumentUpload
        open={openDocumentUpload}
        setOpen={setOpenDocumentUpload}
        file={workingFile}
        refreshDocumentGrid={getDocumentsData}
        setSnackBar={setSnackBarProps}
        campusId={campusId}
        studentId={studentId}
      />



      <Paper className={classes.root} square={true}>
        <CustomSnackbar
          variant={snackBarProps.variant}
          message={snackBarProps.messageInfo}
          open={snackBarProps.showSnackBar}
          onClose={(event?: React.SyntheticEvent, reason?: string) => {
            setSnackBarProps((props: any) => {
              return { ...props, showSnackBar: false };
            });
          }}
        ></CustomSnackbar>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle>Document Preview</DialogTitle>
          <DialogContent className={classes.loader}>
            {loading ? (
              <CircularProgress color="inherit" />
            ) : (
                <div>Image thumbnail goes here</div>
              )}
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDownload} color="primary">
              Download
            </Button>
          </DialogActions>
        </Dialog>
        <Card square={true} className={classes.cardWrapper}>
          <Typography className={clsx(classes.cardTitle)}>
            <div className={classes.headerText}>Documents</div>
          </Typography>
          <CardContent className={classes.cardContent}>
            <Grid alignItems="flex-start" container justify="flex-start" className={classes.mainGrid}>
                          <Grid item xs={12}>
                              <AccessManager
                                  allowedPermissions={[security.permissions.student.updateDocuments]}
                                  renderNoAccess={() => <DropzoneArea
                                      dropzoneProps={{ disabled: true }}
                                      initialFiles={!!(workingFile) ? [workingFile] : []}
                                      filesLimit={1}
                                      maxFileSize={6000000}
                                      showPreviewsInDropzone={false}
                                      dropzoneClass={classes.dropZone}
                                      onDrop={handleDrop}
                                      dropzoneText={
                                          "Drag and drop a file here or click to add a new file"
                                      }
                                  />}
                              >
                                  <DropzoneArea
                                      initialFiles={!!(workingFile) ? [workingFile] : []}
                                      filesLimit={1}
                                      maxFileSize={6000000}
                                      showPreviewsInDropzone={false}
                                      dropzoneClass={classes.dropZone}
                                      onDrop={handleDrop}
                                      dropzoneText={
                                          "Drag and drop a file here or click to add a new file"
                                      }
                                  />
                              </AccessManager>
               
              </Grid>
              {documents.length === 0 && (
                <Grid container>
                  <Grid item xs={12} className={classes.sadFaceWrapper}>
                    <SentimentVeryDissatisfied fontSize={"inherit"} />
                  </Grid>
                  <Grid item xs={12} className={classes.noDocumentMessage}>
                    <span>
                      This place looks empty, make it a happy place by uploading
                      some documents
                    </span>
                  </Grid>
                </Grid>
              )}

              {documents.length > 0 && <DocumentsGrid data={documents} setSnackBarProps={setSnackBarProps} refreshDocumentGrid={getDocumentsData} />}
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </React.Fragment>
  );
};

export default Documents;
