import {
	Dialog,
	makeStyles,
	createStyles,
	DialogTitle,
	DialogContent,
	Grid,
	DialogActions,
	Button,
	IconButton,
	InputLabel,
	Checkbox,
	FormControlLabel
} from '@material-ui/core';
import ProgressSaveButton from '../../components/_Layout/Buttons/ProgressSaveButton';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import InputField from '../../components/_Layout/Inputs/InputField';
import { InputType } from '../../constants/uiConstants/inputConstants';
import ITerminationSettings from '../../interfaces/academicRecords/ITerminationSettings';
import { useForm, FormContext } from 'react-hook-form';
import { EmptyGuid } from '../../utils/constants';
import { terminationSettingsApi } from '../../api/student/academics/terminationSettingsApi';
import CustomSnackbar from '../../components/notifications/CustomSnackbar';
import AccessManager from '../../components/security/AccessManager';
import { security } from '../../constants/Security/securityConstants';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: any) =>
	createStyles({
		dialogHeader: {
			backgroundColor: theme.palette.site.secondary,
			'& .MuiTypography-root': {
				fontWeight: theme.typography.fontWeightBold,
				fontSize: '12pt',
			},
		},
		buttonSection: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(3),
		},
		saveButton: {
			width: 130,
		},
		fullWidthField: {
			width: '100%',
		},

		downloadIcon: {
			width: '100%',
			fontSize: '10em',
		}
		, Disabled: {
			color: 'rgba(0, 0, 0, 0.26)'
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(0),
			top: theme.spacing(0)
		},
		inputFeildGrid: {
			marginBottom:theme.spacing(1)
		}
	})
);

const TerminationSettingsPopup = (props: any) => {
	const classes = useStyles({});
	const campusId = useSelector(
		(state: any) => state.student.enrollments[0].campusId
	);

	const [loaderState, setLoaderState] = React.useState<boolean>(false);

	const handleClose = () => {
		props.setOpen(false);
	};

	const initialState: ITerminationSettings = {
		campusId: campusId,
		maxAllowedDaysConsecutiveAbsence: null,
		maxAllowedDaysIncompleteGrad: null,
		campus: null,
		terminationSettingId: EmptyGuid,
		showMtfFailStudents:true,
	};

	const [model, setModel] = useState<ITerminationSettings>({...initialState});

	const handleModelChange = (fieldId: keyof (ITerminationSettings), value: any) => {
		let modelToUpdate = model;
		(modelToUpdate as any)[fieldId] = value;
		setModel({ ...modelToUpdate });
	};

    interface SnackBarProps {
        open: boolean;
        message: string;
        variant: string;
    }

    const [snackProps, setSnackProps] = React.useState<SnackBarProps>({ open: false, message: '', variant: 'success' });

    const data = useForm<any>({ mode: 'onBlur' });
    const { handleSubmit } = data;

    const onSubmit = (_d: any) => {
    	if (model) {
    		let modelToSend = { ...model, campusId: campusId };

    		data.triggerValidation().then((validation: any) => {
    			if (validation) {
    				setLoaderState(true);
    				terminationSettingsApi.upsertTerminationSettings(modelToSend).then(
    					(response: any) => {
    						if (response.status === 200 && response.data !== EmptyGuid) {
    							setLoaderState(false);

    							if(props.refereshPendingTerminations){
    								props.refereshPendingTerminations();
    							}

    							setSnackProps({
    								open: true,
    								message: 'Saved Successfully',
    								variant: 'success'
    							});
    							handleClose();
    						} else {
    							setLoaderState(false);
    							setSnackProps({
    								open: true,
    								message: 'Something went wrong',
    								variant: 'error'
    							});
    						}
    					},
    					(exception: any) => {
    						setLoaderState(false);
    						setSnackProps({
    							open: true,
    							message: exception,
    							variant: 'error'
    						});
    					}
    				);
    			}
    		});
    	}
    };
    
    React.useEffect(() => {
    	if(props.open){
    		terminationSettingsApi.getTerminationSettings(campusId).then(response => {
    			if (response.status === 200 && response.data) {
    				setModel({ ...response.data, showMtfFailStudents: response.data.showMtfFailStudents ?? true });
    			}
    			else{
    				setModel({...initialState});
    			}
    		});
    	}
    }, [campusId, props.open]);

    const handleSnackbarClose = () => {
    	setSnackProps({
    		open: false,
    		message: '',
    		variant: 'success'
    	});
    };

    return (
    	<AccessManager
    		allowedPermissions={[security.permissions.terminationSettings.viewTerminationSettings]}
    		renderNoAccess={() => null}
    	>
    		<div>
    			<Dialog
    				open={props.open}
    				onClose={handleClose}
    				aria-labelledby="form-dialog-title"
    				maxWidth={'xs'}
    				fullWidth={true}
    				disableBackdropClick >
    				<DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
    					<Typography>Termination Settings</Typography>
    					<IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
    						<CloseIcon />
    					</IconButton>
    				</DialogTitle>
    				<DialogContent>
    					<FormContext {...data}>
    						<form onSubmit={handleSubmit(onSubmit)}>
    							<Grid container direction="row" >
    								<Grid item xs={12} md={12} sm={12}>
    									<InputLabel required={true} shrink={true} >
                                        Maximum Allowable Consecutive Absence
    									</InputLabel>
    								</Grid>
    							</Grid>

    							<Grid container direction="row" className={classes.inputFeildGrid}>
    								<Grid item xs={12} md={12} sm={12}>
    									<InputField
    										type={InputType.NUMBER}
    										key={'txtMaxAllowedDaysConsecutiveAbsence'}
    										id={'txtMaxAllowedDaysConsecutiveAbsence'}
    										label=""
    										name={'txtMaxAllowedDaysConsecutiveAbsence'}
    										onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
    											handleModelChange('maxAllowedDaysConsecutiveAbsence', e.target.value);
    										}}
    										defaultValue={model.maxAllowedDaysConsecutiveAbsence}
    										datatype={'Number'}
    										autoComplete={'off'}
    										error={!!data.errors.txtMaxAllowedDaysConsecutiveAbsence}
    										inputRef={data.register({
    											validate: {
    												greaterThan0: (value) =>
    													Number(value) > 0 ||
                                                        'required',
    											},
    											required: true
    										})}
    										required={true}
    										helperText={data.errors.txtMaxAllowedDaysConsecutiveAbsence ? 'required' : ''}
    									/>
    								</Grid>
    							</Grid>
    							<Grid container direction="row" >
    								<Grid item xs={12} md={12} sm={12}>
    									<InputLabel required={true} shrink={true} >
                                        Maximum Allowable Days To Be Incomplete Grad
    									</InputLabel>
    								</Grid>
    							</Grid>
    							<Grid container direction="row">
    								<Grid item xs={12} md={12} sm={12}>
    									<InputField
    										type={InputType.NUMBER}
    										key={'txtMaxAllowedDaysIncompleteGrad'}
    										id={'txtMaxAllowedDaysIncompleteGrad'}
    										label=""
    										name={'txtMaxAllowedDaysIncompleteGrad'}
    										defaultValue={model.maxAllowedDaysIncompleteGrad}
    										autoComplete={'off'}
    										onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
    											handleModelChange('maxAllowedDaysIncompleteGrad', e.target.value);
    										}}
    										datatype={'Number'}
    										error={!!data.errors.txtMaxAllowedDaysIncompleteGrad}
    										inputRef={data.register({
    											validate: {
    												greaterThan0: (value) =>
    													Number(value) > 0 ||
                                                        'required',
    											},
    											required: true
    										})}
    										required={true}
    										helperText={data.errors.txtMaxAllowedDaysIncompleteGrad ? 'required' : ''}
    									/>
    								</Grid>
    							</Grid>

								<Grid container direction="row">
    								<Grid item xs={12} md={12} sm={12}>
									<FormControlLabel control={<Checkbox
											color="primary"
											checked={model.showMtfFailStudents}
											onChange={(e: any) => {
												handleModelChange('showMtfFailStudents', e.target.checked);
											  }}
										/>} label="Show MTF Fail Students" />
    								</Grid>
    							</Grid>

    						</form>
    					</FormContext>
    				</DialogContent>
    				<DialogActions className={classes.buttonSection}>
    					<Grid container direction="row" spacing={1}
    						alignContent="center"
    						alignItems="center"
    						justify="center"
    					>
                            
    						<AccessManager
    							allowedPermissions={[security.permissions.terminationSettings.addOrUpdateTerminationSettings]}
    							renderNoAccess={() => null}
    						>
    							<Grid
    								item
    								container
    								xs={3}
    								alignContent="center"
    								alignItems="center"
    								justify="center"
    							>

    								<ProgressSaveButton
    									text="Save"
    									onClick={(e: any) => {
    										onSubmit(e);
    									}}
    									loading={loaderState}
    									disabled={loaderState}
    									buttonClassName={classes.saveButton}
    								></ProgressSaveButton>
    							</Grid>
    						</AccessManager>
    						<Grid
    							item
    							container
    							xs={3}
    							alignContent="center"
    							alignItems="center"
    							justify="center"
    						>
    							<Button
    								onClick={handleClose}
    								color="secondary"
    								variant="contained"
    								type="button"
    							>
                                    Cancel
    							</Button>
    						</Grid>
    					</Grid>
    				</DialogActions>
    			</Dialog>
    			<CustomSnackbar
    				variant={snackProps.variant === 'success' ? 'success' : 'error'}
    				message={snackProps.message}
    				open={snackProps.open}
    				onClose={handleSnackbarClose}
    			></CustomSnackbar>
    		</div>
    	</AccessManager>

    );
};

export default TerminationSettingsPopup;
