import * as actionTypes from "../../constants/actions.constants";
import { Action } from "redux";
import IToasterOptions from '../../interfaces/IToasterOptions';


const initialState: IToasterOptions = {message:'',variant:'info',showToaster:false};

const toasterReducer = (state:IToasterOptions = initialState, action: Action<any>) => {

  switch (action.type) {
    case actionTypes.toasterConstants.SHOW_TOASTER: {
        let actionData = (action as any).data as IToasterOptions;
      return {
        ...state, showToaster: actionData.showToaster, message: actionData.message, variant: actionData.variant
      };
    
    }

    case actionTypes.toasterConstants.HIDE_TOASTER: {
      return {
        ...state, showToaster: false, message: '', variant: 'info'
      };
    
    }

    default: {
      return {...state, showToaster:false};
    }
}
};

export default toasterReducer;
