import React, { useEffect, useRef } from "react";
import { apiAutoCompleteConfig } from "../../../constants/apiAutoComplete.config";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../../constants/actions.constants";
import { withRouter } from "react-router-dom";
import { Campus } from "../../../interfaces/systemCatalog/ICampus";
import { keys } from "../../../constants/urlparameters.contants";

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& label.Mui-focused": {
				color: theme.palette.primary.white
			},
			"& input": {
				color: theme.palette.topBar.inputFields.color,
				opacity: 1,
				fontWeight: theme.typography.fontWeightMedium,
				fontSize: 16,
				padding: "10px !important"
			},
			"& input::placeholder": {
				fontWeight: theme.typography.fontWeightMedium,
				fontSize: 14
			},
			"& .MuiInputBase-root": {
				backgroundColor: theme.palette.topBar.inputFields.background,
				border: "1px solid " + theme.palette.topBar.inputFields.border,
				borderRadius: 5,
			}
		}
	})
)(TextField);

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {
			width: "95%"
		},
		searchIcon: {
			fontSize: 30,
			paddingLeft: 10,
			color: theme.palette.icon.primary,
			cursor: "pointer"
		},
		campusAutoSelect: {
			color: theme.palette.topBar.inputFields.color,
		}
	})
);

export const CampusSearch = (props: any) => {
	const classes = useStyles({});
	const dispatch = useDispatch();
	const [campusSelectedGuid, setCampusSelectedGuid] = React.useState<any>();
	const user = useSelector((state: any) => state.session.user);
	const inputRef: any = useRef(null);
	let urlParams = new URLSearchParams(props.history.location.search);
	let campusIdQueryId = keys.campusId;
	let studentIdQueryId = keys.studentId;
	let campusIdQueryString = urlParams.get(campusIdQueryId);

	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const campusSelected = userSelectedCampus ? true : false;

	const magnifyOnClick = (e: any) => {
		if (inputRef.current !== undefined) {
			inputRef.current.children[1].focus();
		}
	};

	const campusOnChange = (e: any) => {
		let urlParams = new URLSearchParams(props.history.location.search);
		let campusIdQueryString = urlParams.get(campusIdQueryId);
		if (e && e.text && e.value && campusIdQueryString !== e.value) {
			e.campusTimeZoneEnvironment && e.campusTimeZoneEnvironment.uxUrl && localStorage.setItem('UXUrl', (e.campusTimeZoneEnvironment.uxUrl));
			e.campusTimeZoneEnvironment && e.campusTimeZoneEnvironment.hostUrl && localStorage.setItem('HostUrl', (e.campusTimeZoneEnvironment.hostUrl));
			e.campusTimeZoneEnvironment && e.campusTimeZoneEnvironment.siteUrl && localStorage.setItem('SiteUrl', (e.campusTimeZoneEnvironment.siteUrl));
			e.campusTimeZoneEnvironment && e.campusTimeZoneEnvironment.apiUrl && localStorage.setItem('ApiUrl', (e.campusTimeZoneEnvironment.apiUrl));
			dispatch({
				type: actionTypes.userStateConstants.USER_STATE_SET_SELECTED_CAMPUS,
				data: {
					userId: user.userId,
					selectedCampus: e.value
				}
			});
			urlParams.set(campusIdQueryId, e.value);
			urlParams.delete(studentIdQueryId);
			props.history.replace({
				...props.location,
				search: urlParams.toString()
			});
		}
	};

	return (
		<div className={clsx(classes.divWidth)}>
			<ApiAutoComplete
				config={apiAutoCompleteConfig.campus}
				classes={{
					option: "text-black"
				}}
				label=""
				onChange={(e: any) => {
					campusOnChange(e);
				}}
				multiple={false}
				valueFilter={
					userSelectedCampus
						? {
							key: "value",
							values: [userSelectedCampus]
						}
						: {
							key: "value",
							values: [campusIdQueryString]
						}
				}
				renderInput={(params: any) => {
					return (
						<ThemeTextField
							{...params}
							fullWidth
							className={classes.campusAutoSelect}
							InputProps={{
								...params.InputProps,
								startAdornment: (
									<React.Fragment>
										{!campusSelected ? (
											<SearchIcon
												className={classes.searchIcon}
												onClick={(e: any) => {
													magnifyOnClick(e);
												}}
											></SearchIcon>
										) : null}
									</React.Fragment>
								),
								placeholder: "Search by campus name",
								disableUnderline: true
							}}
						/>
					);
				}}
			></ApiAutoComplete>
		</div>
	);
};
export default withRouter(CampusSearch);
