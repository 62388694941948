
export const insert = (arr: any, index: number, newItem: any) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index)
]

export const groupBy = (arr: any, prop: any) => {
    const map = new Map(Array.from(arr, (obj: any) => [obj[prop], [] as Array<any>]));
    if (map) {
        arr.forEach((obj: any) => { const val = map.get(obj[prop]); if (val) val.push(obj) });
        return Array.from(map.values());
    }
    return [];
}


export const groupByArray = (xs: any, key: any) => {
    return xs.reduce(function (rv: any, x: any) {
        let v = key instanceof Function ? key(x) : x[key];
        let el = rv.find((r: any) => r && r.key === v);
        if (el) { el.values.push(x); }
        else {
            rv.push({ key: v, values: [x] });
        }
        return rv;
    }, []);
}

function group(xs: any, key: any) {
    return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};
function chunks(array: any, size: number) {
    var results = [];
    while (array.length) {

        results.push(array.splice(0, size));
    }
    return results;
};
export function chunksByKey(array: any, size: number, key: string) {
    var results = [];
    var groupedResults = group(array, key);
    var chunk: any[] = [];

    Object.keys(groupedResults).forEach(function (key: string, index) {
        let val = groupedResults[key];
        if ((val.length + chunk.length) > size) {
            results.push(chunk.slice())
            chunk = [];
        }
        chunk.push(val)
        chunk = chunk.reduce((acc, val) => acc.concat(val), []);;
    });
    //$.each(groupedResults, (i, val) => {

    //})
    if (chunk.length)
        results.push(chunk)
    return results;
};

export function flatten(array:any, result:any) : any {
    if (array.length === 0) {
        return result
    }
    var head = array[0]
    var rest = array.slice(1)
    if (Array.isArray(head)) {
        return flatten(head.concat(rest), result)
    }
    result.push(head)
    return flatten(rest, result)
}