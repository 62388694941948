import * as React from "react";
import { useState, useEffect } from "react";
import clsx from "clsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import EmailTypesAutoComplete from "../../../../components/AutoComplete/EmailTypesAutoComplete";
import TextField from "@material-ui/core/TextField";
import { Typography, Card, CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import { Email } from "../../../../interfaces/student/profile/contactInfo/Email";
import * as EmailApi from "../../../../api/student/profle/contactInfo/EmailApi";
import { useSelector } from "react-redux";
import { StudentProfile } from "../../../../interfaces/student/profile/StudentProfile";
import { useFormContext } from "react-hook-form";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    drawer: {
      width: 280,
    },
    root: {
      backgroundColor: theme.palette.paper.background,
      "& .MuiCardContent-root": {
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(2),
      },
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.paper.background,
    },
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
  })
);

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {},
  })
)(TextField);
type EmailProps = {
  setEmailModel: any;
  isNewEnrollment: boolean;
  leadId?: string;
  isTransferredStudent?: boolean;
  refreshData?: boolean;
  setRefreshData?: (value: boolean) => void;
  bestEmailModelPassthrough?: any;
};
const BestEmail = (props: EmailProps) => {
  const classes = useStyles({});
  const data = useFormContext();
  const { leadId, bestEmailModelPassthrough } = props;
  const [emailModel, setEmailModel] = useState<Email>(new Email()); //props; //useState<Email>(new Email());

  /*
  Getting the current selected student from the session
   */
  const selectedStudent: StudentProfile = useSelector(
    (state: any) => state.student
  ) as StudentProfile;
  const handleFieldOnChange = (fieldId: string, value: any) => {
    let updatedModel = emailModel;
    (updatedModel as any)[fieldId] = value;
    setEmailModel(updatedModel);
    props.setEmailModel(updatedModel);
  };

  const onValidation = (fieldId: string, value: any) => {
    if (data.errors && (emailModel as any)[fieldId] != value) {
      handleFieldOnChange(fieldId, value);
    }
  };

  React.useEffect(() => {
    if (bestEmailModelPassthrough) {
      setEmailModel({ ...bestEmailModelPassthrough });
    }
  }, [bestEmailModelPassthrough]);

  const loadFormData = async () => {
    /*
   Checks that the initial state is not undefined, this is two avoid having two calls to the API when the redux is still loading data
  */
    if (props.isNewEnrollment === false || !!leadId && ((selectedStudent !== undefined) || (props.refreshData && props.refreshData === true))) {
      if (
        selectedStudent.leadId !== undefined &&
        selectedStudent.leadId.length > 0 || !leadId
      ) {
        if (!props.isNewEnrollment || props.isTransferredStudent || !!leadId) {
          let studentId = leadId ? leadId : selectedStudent.leadId;
          if (studentId) {
            try {
              const response = await EmailApi.getBestEmail(studentId);
              let updatedModel = response;
              if (response) {
                (updatedModel as any)["emailId"] = (response as any).id;
                (updatedModel as any)["emailIsShowOnLeadPage"] = (response as any).isShowOnLeadPage;
                props.setEmailModel({ ...updatedModel });
                if (updatedModel) {
                  setEmailModel({ ...updatedModel });
                }
              }
            }
            catch (err) {

            }
          }
        }
      }
    } else {
      let clearEmail = new Email();
      props.setEmailModel({ ...clearEmail });
      setEmailModel({ ...clearEmail });
    }
    if (props.refreshData && props.refreshData === true) {
      if (props.setRefreshData) {
        props.setRefreshData(false);
      }
    }
  }
  /*
  When the Component is loaded
   */
  useEffect(
    () => {

      loadFormData();
    },
    [
      selectedStudent,
      leadId,
      props.isNewEnrollment,
      props.refreshData
    ] /* tell react to watch changes on this variable */
  );
  const EmailValidation = (errorName: any) => {
    if (errorName?.type === "validate") {
      return "Email is not in correct format.";
    } else return null;
  };
  const EmailTypeValidation = (errorName: any) => {
    if (errorName?.type === "validate") {
      return "Email Type is Required.";
    } else return undefined;
  };
  return (
    <div>
      <Card square={true}>
        <Typography className={clsx(classes.cardTitle)}>
          <div className={classes.headerText}>Best Email</div>
        </Typography>
        <CardContent className={classes.cardContent}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={12} sm={6}>
              <EmailTypesAutoComplete
                filterHandle={(v: any) => {
                  handleFieldOnChange("emailTypeId", v ? v.value : undefined);
                }}
                label={"Email Type *"}
                id="emailTypeId"
                name="emailTypeId"
                inputRef={data.register({
                  validate: (value: any) => {
                    if (emailModel.email && !value) return false;
                    else return true;
                  },
                })}
                helperText={EmailTypeValidation(data.errors.emailTypeId)}
                error={!!data.errors.emailTypeId}
                valueFilter={
                  emailModel && emailModel.emailTypeId
                    ? {
                      key: "value",
                      values: [emailModel.emailTypeId],
                    }
                    : undefined
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ThemeTextField
                id="email"
                name="email"
                className={classes.textField}
                label={"Email *"}
                margin="none"
                value={emailModel.email || ""}
                inputRef={data.register({
                  validate: (value: any) => {
                    onValidation("email", value);
                    if (emailModel.emailTypeId && !value) return false;
                    else
                      return /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}\s*$/.test(
                        value
                      );
                  },
                })}
                helperText={EmailValidation(data.errors.email)}
                error={!!data.errors.email}
                onChange={(v: any) => {
                  handleFieldOnChange("email", v ? v.target.value : undefined);
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default BestEmail;
