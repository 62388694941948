import {
  Card,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  Divider,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import * as classRegApi from "../../../../api/setup/academics/classRegistrationApi";
import { useSelector } from "react-redux";
import { CenterFocusStrong } from "@material-ui/icons";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardWrapper: {
      height: "100%",
    },
    headingTitle: {
      fontSize: 17,
      color: "Black",
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
    },
  })
);

type BannerProps = {
  stuEnrollId: string;
  reload: boolean;
  setReload: (values: boolean) => void;
};

interface ICourse {
  CourseId: string;
  CourseName: string;
}
interface IStudentBannerDetail {
  StuEnrollId: string;
  StudentNumber: string;
  FirstName: string;
  LastName: string;
  FullName: string;
  Program: string;
  RegisteredCourses: Array<ICourse>;
  RemainingCourses: Array<ICourse>;
}

const StudentBanner = (props: BannerProps) => {
  const classes = useStyles({});
  const [model, setModel] = useState<IStudentBannerDetail>();

  const loadStudentDetail = (enrollmentId: any) => {
    classRegApi.GetStudentRegistrationDetail(enrollmentId).then(
      (response: any) => {
        if (response) {
          let JsonResult: IStudentBannerDetail[] = JSON.parse(
            response.jsonResult
          );
          setModel(JsonResult[0]);
          props.setReload(false);
        }
      },
      (exception: any) => {}
    );
  };

  useEffect(() => {
    if (
      (props.stuEnrollId !== undefined && props.stuEnrollId !== "") ||
      props.reload == true
    ) {
      loadStudentDetail(props.stuEnrollId);
    }
  }, [props.stuEnrollId, props.reload]);

  return (
    <Card square={true} className={classes.cardWrapper}>
      <Fragment>
        <CardContent>
          <React.Fragment>
            <Grid container direction="row" spacing={1}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ textAlign: "center" }}
              >
                <Typography>
                  <b>
                    {model?.StudentNumber} - {model?.FullName}
                  </b>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ textAlign: "center" }}
              >
                <Typography>
                  <b>{model?.Program}</b>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Typography className={classes.headingTitle}>
                  Scheduled and Graded Courses
                </Typography>
                <Typography>
                  <ul>
                    {model?.RegisteredCourses?.map(
                      (row: ICourse, index: any) => (
                        <li style={{ listStyle: "none" }}>{row.CourseName}</li>
                      )
                    )}
                  </ul>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Typography className={classes.headingTitle}>
                  Remaining Courses
                </Typography>
                <Typography>
                  <ul>
                    {model?.RemainingCourses?.map(
                      (row: ICourse, index: any) => (
                        <li style={{ listStyle: "none" }}>{row.CourseName}</li>
                      )
                    )}
                  </ul>
                </Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        </CardContent>
      </Fragment>
    </Card>
  );
};

export default StudentBanner;
