import DateFnsUtils from "@date-io/date-fns";
import { Button, DialogActions, Grid, TextField } from "@material-ui/core";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useState } from "react";
import { FieldError, FormContext, useForm } from "react-hook-form";
import * as DisbursementApi from "../../../../../api/student/studentAccounts/disbursementDetailsApi";
import * as ledgerApi from "../../../../../api/student/studentAccounts/ledgerApi";
import DisbursementAutoComplete from "../../../../../components/AutoComplete/DisbursementAutoComplete";
import FundSourcesAutoComplete from "../../../../../components/AutoComplete/FundSourcesAutoComplete";
import StudentAwardAutoComplete from "../../../../../components/AutoComplete/StudentAwardAutoComplete";
import ProgressSaveButton from "../../../../../components/_Layout/Buttons/ProgressSaveButton";
import InputField from "../../../../../components/_Layout/Inputs/InputField";
import ConfirmationModal from "../../../../../components/_Layout/Modal/ConfirmationModal";
import { InputType } from "../../../../../constants/uiConstants/inputConstants";
import { IFinancialAidDisbursement } from "../../../../../interfaces/student/financials/IFinancialAidDisbursement";
import { EmptyGuid } from "../../../../../utils/constants";
import { AllowSpaceDigitsAndLettersOnlyRegex } from "../../../../../constants/uiConstants/inputConstants";
const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			width: "100%",
		},
		fullWidthField: {
			width: "100%",
		},
		buttonSection: {
			marginTop: theme.spacing(3),
		},
		saveButton: {
			width: 90,
		},
		textField: {
			marginRight: theme.spacing(1),
			width: "100%",
		},
	})
);

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& .MuiFormLabel-root": {
				textOverflow: "ellipsis",
				whiteSpace: "nowrap",
				overflow: "hidden",
				width: "80%",
			},
		},
	})
)(TextField);

type FinancialAidDisbursementProps = {
	model: IFinancialAidDisbursement;
	setPopupState: any;
	setTransactionTypeValue: any;
	isRefund: boolean;
	transactionType: any;
	refreshLedgerItems: any;
	setSnackBar: any;
	enrollmentId: string;
	creditDebit: any;
};
export const ConvertCurrencyValueToInput = (value: any) => {
	return Number((value + "").replace("$ ", "").replace(/,/g, ""));
};
const FinancialAidDisbursement = (props: FinancialAidDisbursementProps) => {
	const classes = useStyles({});
	const data = useForm<any>({ mode: "onBlur" });
	const [confirmationPopupState, setConfirmationPopupState] =
		React.useState<any>({
			id: "simple-popup",
			message: "",
		});

	const [disbursedAmount, setDisbursedAmount] = useState<number>(0);
	const [useDecimalFormat, setuseDecimalFormat] = React.useState<boolean>(true);
	const [model, setModel] = React.useState<IFinancialAidDisbursement>({
		transactionDate: new Date().toLocaleDateString(),
		amount: undefined,
		awardId: EmptyGuid,
		disbursementId: EmptyGuid,
		documentId: "",
		fundSourceId: EmptyGuid,
		transactionCodeId: props.transactionType.value,
		isRefund: props.isRefund,
		transactionId: EmptyGuid,
		studentEnrollmentId: props.enrollmentId,
		creditDebit: props.creditDebit,
	});

	const getDisbursementDetails = (newDisbursmentId:string) => {
		DisbursementApi.GetDisbursementDetail(newDisbursmentId).then(
			(response) => {
				if (response.data && response.data.resultStatus === 0) {
					let result = response.data.result;
					setDisbursedAmount(result.amount);
				}
			}
		);
	};

	const handleDisbursementOnChange = (fieldId: string, value: any) => {
		if (value) {
			if (value.position === 0) {
				let updatedModel = model;
				(updatedModel as any)[fieldId] = value.value;
				setModel({ ...updatedModel });
				getDisbursementDetails(value.value);
			} else {
				setConfirmationPopupState((state: any) => {
					return {
						...state,
						open: true,
						confirmationText: !props.isRefund
							? "The selected disbursement is not the earliest scheduled disbursement to pay. Are you sure you want to pay this disbursement?"
							: "The selected disbursement is not the last paid disbursement that can be refunded. Are you sure you want to refund this disbursement?",
						confirmationButton: "Yes",
						title: !props.isRefund
							? "Disbursement Confirmation"
							: "Refund Confirmation",
						onConfirmEvent: () => {
							let updatedModel = model;
							(updatedModel as any)[fieldId] = value.value;
							setModel({ ...updatedModel });
							setConfirmationPopupState((updatedState: any) => {
								return { ...updatedState, open: false };
							});

							getDisbursementDetails(value.value);
						},
						onCancelEvent: () => {
							setConfirmationPopupState((updatedState: any) => {
								return { ...updatedState, open: false };
							});
						},
					};
				});
			}
		}
	};

	const handleRefundeAmountLessThanDisbursementChange = () => {
		//value less than disbursement
		if (
			model.isRefund &&
			model.amount &&
			disbursedAmount > 0 &&
			model.amount < disbursedAmount
		) {
			setConfirmationPopupState((state: any) => {
				return {
					...state,
					open: true,
					confirmationText:
						"The amount you entered " +
						(model && model.amount
							? (model.amount as number).toLocaleString("en-US", {
									style: "currency",
									currency: "USD",
							  })
							: "$0.00") +
						"  is less than the amount of the last disbursement " +
						disbursedAmount.toLocaleString("en-US", {
							style: "currency",
							currency: "USD",
						}) +
						". Are you sure you want to proceed? Make sure to always post refunds against the last un-refunded disbursement. ",
					confirmationButton: "Yes",
					title: "Confirm Refund Amount",
					onConfirmEvent: () => {
						saveTransaction();
						setConfirmationPopupState((updatedState: any) => {
							return { ...updatedState, open: false };
						});
					},
					onCancelEvent: () => {
						let updatedModel = model;
						model.amount = 0;
						setModel({ ...updatedModel });
						setConfirmationPopupState((updatedState: any) => {
							return { ...updatedState, open: false };
						});
					},
				};
			});
		} else {
			handleRefundeAmountGreaterThanDisbursementChange()
		}
	};

	const handleRefundeAmountGreaterThanDisbursementChange = () => {
		if (
			model.isRefund &&
			model.amount &&
			disbursedAmount > 0 &&
			model.amount > disbursedAmount
		) {
			props.setSnackBar((props: any) => {
				return {
				  variant: "error",
				  showSnackBar: true,
				  messageInfo: "The amount you entered " +
				  (model && model.amount
					  ? (model.amount as number).toLocaleString("en-US", {
							  style: "currency",
							  currency: "USD",
						})
					  : "$0.00") +
				  "  is greater than the amount of the last disbursement " +
				  disbursedAmount.toLocaleString("en-US", {
					  style: "currency",
					  currency: "USD",
				  }) +
				  ".",
				};
			  });
		} else {
			saveTransaction();
		}
	};
	const handleFieldOnChange = (fieldId: string, value: any) => {
		let updatedModel = model;
		if (fieldId === 'transactionDate'){
			(updatedModel as any)[fieldId] = new Date(value).toLocaleDateString();
			setModel({ ...updatedModel });
		  }else { 
			(updatedModel as any)[fieldId] = value;
			if (fieldId === "awardId") {
				updatedModel.disbursementId = EmptyGuid;
			}
			setModel({ ...updatedModel });}
	};
	const getDecimalValue = (value: any) => {
		let valstring = (value + "").replace("$ ", "").replace(/,/g, "");
		let valstr = valstring.split(".", 2);
		let ret = 0;
		if (valstr.length > 1) {
			ret = Number(valstr[1]);
		}
		if (!useDecimalFormat) {
			if (ret > 0) {
				return 0;
			} else {
				return 1;
			}
		} else {
			return 1;
		}
	};
	const handleClose = () => {
		props.setPopupState(false);
		props.setTransactionTypeValue(null);
		props.refreshLedgerItems();
	};

	const { handleSubmit } = data;
	const [loaderState, setLoaderState] = React.useState<boolean>(false);
	const saveTransaction = () => {
		if (model) {
			setLoaderState(true);
			ledgerApi.processFinancialAidDisbursement(model).then(
				(response: any) => {
					if (response.resultStatus === 0) {
						setLoaderState(false);
						props.setSnackBar((props: any) => {
							return {
								variant: "success",
								showSnackBar: true,
								messageInfo: response.resultStatusMessage,
							};
						});
						handleClose();
					} else {
						setLoaderState(false);
						props.setSnackBar((props: any) => {
							return {
								variant: "error",
								showSnackBar: true,
								messageInfo: response.data,
							};
						});
					}
				},
				(exception: any) => {
					setLoaderState(false);
					props.setSnackBar((snackBarProps: any) => {
						return {
							variant: "error",
							showSnackBar: true,
							messageInfo: exception,
						};
					});
				}
			);
		}
	};
	const onSubmit = (d: any) => {
		if (document && document.activeElement) 
		{(document.activeElement as any).blur()}
		handleRefundeAmountLessThanDisbursementChange();
	};

	return (
		<FormContext {...data}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div>
					<Grid container direction="row" spacing={2}>
						<Grid item xs={12}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									clearable
									format={"MM/dd/yyyy"}
									placeholder="mm/dd/yyyy"
									id="transactionDate"
									name="transactionDate"
									label="Transaction Date"
									TextFieldComponent={ThemeTextField}
									className={classes.fullWidthField}
									value={model.transactionDate ? model.transactionDate : null}
									onChange={(value: any) => {
										handleFieldOnChange(
											"transactionDate",
											value ? value : null
										);
										data.setValue("transactionDate", value ? value : null);
									}}
									KeyboardButtonProps={{
										"aria-label": "change date",
									}}
									autoOk={true}
									required
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item xs={12}>
							<FundSourcesAutoComplete
								id="fundSourceId"
								label="Fund Source *"
								name="fundSourceId"
								filterHandle={(v: any) => {
									if (v) {
										if (
											v.advFundsourceId === 6 ||
											v.advFundsourceId === 7 ||
											v.advFundsourceId === 8
										) {
											setuseDecimalFormat(false);
										} else {
											setuseDecimalFormat(true);
										}
									} else {
										setuseDecimalFormat(true);
									}
									handleFieldOnChange("fundSourceId", v ? v.value : null);
								}}
								valueFilter={
									model.fundSourceId
										? {
												key: "value",
												values: [model.fundSourceId],
										  }
										: undefined
								}
								error={!!data.errors.fundSourceId}
								inputRef={data.register({ required: true })}
								helperText={
									data.errors.fundSourceId
										? "Fund Source is required."
										: undefined
								}
								multiple={false}
							/>
						</Grid>
						<Grid item xs={12}>
							<StudentAwardAutoComplete
								id="awardId"
								name="awardId"
								label="Student Award *"
								fundSourceId={model.fundSourceId}
								studentEnrollmentId={props.enrollmentId}
								filterHandle={(v: any) => {
									handleFieldOnChange("awardId", v ? v.value : null);
								}}
								valueFilter={
									model.awardId
										? {
												key: "value",
												values: [model.awardId],
										  }
										: undefined
								}
								error={!!data.errors.awardId}
								inputRef={data.register({ required: true })}
								helperText={
									data.errors.awardId ? "Award is required." : undefined
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<DisbursementAutoComplete
								id="disbursementId"
								name="disbursementId"
								label="Disbursement *"
								awardId={model.awardId}
								isRefund={model.isRefund}
								valueFilter={
									model.disbursementId === EmptyGuid
										? undefined
										: {
												key: "value",
												values: [model.disbursementId],
										  }
								}
								filterHandle={(v: any) => {
									handleDisbursementOnChange("disbursementId", v ? v : null);
								}}
								error={!!data.errors.disbursementId}
								inputRef={data.register({ required: true })}
								helperText={
									data.errors.disbursementId
										? "Disbursement required."
										: undefined
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<ThemeTextField
								id="documentId"
								label="Document ID"
								name="documentId"
								className={classes.fullWidthField}
								value={model.documentId}
								onChange={(e) => {
									handleFieldOnChange("documentId", e.target.value);
								}}
								inputProps={{ pattern: AllowSpaceDigitsAndLettersOnlyRegex }}
							/>
						</Grid>
						<Grid item xs={12}>
							<InputField
								type={InputType.CURRENCY}
								label="Amount *"
								name="amount"
								key="amount"
								decimal
								thousandSeparator
								defaultValue={model.amount}
								onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
									handleFieldOnChange("amount", v.target.value);
								}}
								error={!!data.errors.amount}
								inputRef={data.register({
									required: "Amount is required.",
									validate: {
										numberOnly: (value) =>
											getDecimalValue(value) !== 0 ||
											"Amount cannot have cents.",
										valueGreaterThanZero: (value) =>
											ConvertCurrencyValueToInput(value) > 0 ||
											"Amount must be greater than 0",
									},
								})}
								helperText={
									data.errors.amount
										? (data.errors.amount as FieldError).message
										: undefined
								}
							/>
						</Grid>
					</Grid>
				</div>
				<div>
					<Grid item xs={12}>
						<DialogActions className={classes.buttonSection}>
							<Grid container direction="row" spacing={1}>
								<Grid item xs={2}></Grid>
								<Grid
									item
									container
									xs={4}
									alignContent="center"
									alignItems="center"
									justify="center"
								>
									<ProgressSaveButton
										text="Save"
										onClick={() => {
											handleSubmit(onSubmit);
										}}
										loading={loaderState}
										buttonClassName={classes.saveButton}
									></ProgressSaveButton>
								</Grid>
								<Grid
									item
									container
									xs={4}
									alignContent="center"
									alignItems="center"
									justify="center"
								>
									<Button
										onClick={handleClose}
										color="secondary"
										variant="contained"
										type="button"
									>
										Cancel
									</Button>
								</Grid>
								<Grid item xs={2}></Grid>
							</Grid>
						</DialogActions>
					</Grid>
					<ConfirmationModal {...confirmationPopupState} />
				</div>
			</form>
		</FormContext>
	);
};

export default FinancialAidDisbursement;
