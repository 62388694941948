import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CampusForCurrentUserAutoComplete from "../../../components/AutoComplete/CampusForCurrentUserAutoComplete";
import { FormControlLabel, Switch, Grid, Typography, FormControl, FormLabel, RadioGroup, Radio } from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import * as gainfulEmploymentApi from "../../../api/reports/gainfulEmploymentReportApi";
import { ReportOutput } from "../../../enums/ReportOutput";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import ProgramVersionAutoComplete from "../../../components/AutoComplete/ProgramVersionAutoComplete";
import StudentGroupsAutoComplete from "../../../components/AutoComplete/StudentGroupsAutoComplete";
import StudentNameBadgeIDFilter from '../../../../src/pages/attendance/StudentNameBadgeIDFilter'
import { EnrollmentAutoComplete } from "../../../components/AutoComplete/EnrollmentAutoComplete";
import { IEnrollmentSearchResult } from "../../../interfaces/student/enrollment/EnrollmentSearchResult";
import { IAttendanceSummaryReportParam } from "../../../interfaces/reports/attendance/AttendanceSummaryReportParam";
import { useSelector } from "react-redux";
import MultiCampusAutoComplete from "../../../components/AutoComplete/MultiCampusAutoComplete";
import { IGEDataCheckParam } from "../../../interfaces/reports/IGEDataCheckReportParam";
import { cloneDeep } from "lodash";
import { IIPEDSDisabilityServiceReportParam } from "../../../interfaces/reports/IPEDSDisabilityServiceReportParam";
import { getIPEDSDisabilityServiceReport } from "../../../api/reports/ipeds/IPEDSDisabilityServiceReportApi";
import ProgramAutoComplete from "../../../components/AutoComplete/ProgramAutoComplete";


const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(3),
            minHeight: "100%",
            height: "100%",
            maxWidth: "1050px",
            padding: theme.spacing(2),
        },
        "& .makeStyles-content": {
            backgroundColor: theme.palette.background.paper,
        },
        paperRoot: {
            padding: theme.spacing(3, 2),
            width: "100%",
            minHeight: "100%",
            height: "100%",
            overflowX: "hidden",
            overflowY: "auto",
            flexDirection: "column",
            backgroundColor: theme.palette.background.paper,
        },
        searchActions: {
            marginTop: theme.spacing(3),
            textAlign: "left",
        },
        startDateField: {
            width: "100%",
        },
        cardTitle: {
            fontSize: 17,
            color: theme.palette.primary.headerText,
            textDecoration: "bold",
            backgroundColor: theme.palette.secondary.dark,
            padding: theme.spacing(1, 1),
            marginBottom: theme.spacing(1),
        },
        headerText: {
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(0),
        },
        cardContent: {
            padding: theme.spacing(1, 3),
        }, switch: {
            display: "flex",
            alignItems: "flex-end",
            padding: theme.spacing(0, 0.5),
            height: theme.spacing(8.6),
        },
        fieldSet: {
            marginTop: theme.spacing(2),
        },
    })
);

interface ICohortYearDataSource {
    startDate: string;
    endDate: string;
    cohortYear: number;
}

const IPEDSDisabilityService = (props: any) => {
    const { report } = props;
    let reportOverview = { report: report } as IReportOverview;
    const classes = useStyles({});
    const [preview, setPreview] = React.useState<any>();
    const [previewLoader, setPreviewLoader] = React.useState<boolean>();
    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );

    const currentYear = (new Date()).getFullYear() - 1;


    const cohortYearOnChange = (e: ICohortYearDataSource) => {
        if (!!e && !!e.cohortYear && e.cohortYear > 0) {
            let updatedModel = { ...model };
            updatedModel.startDate = model.schoolReportType == 'program' ? e.startDate : `${e.cohortYear}/10/15`;
            updatedModel.endDate = model.schoolReportType == 'program' ? e.endDate : `${e.cohortYear}/10/15`;
            updatedModel.cohortYear = e.cohortYear;
            updatedModel.selectedAcademicYearDate = new Date(e.cohortYear, 9, 15)
            setModel({ ...updatedModel });
        }
    };

    const data = useForm<any>({ mode: "onBlur" });
    const initialModel: IIPEDSDisabilityServiceReportParam = {
        campusId: userSelectedCampus,
        startDate: `${currentYear}/08/01`,
        endDate: `${currentYear}/10/31`,
        progIdList: [],
        cohortYear: currentYear,
        orderBy: 'StudentIdentifier',
        schoolReportType: 'program',
        showSSN: false,
        selectedAcademicYearDate: new Date(currentYear, 9, 15),
        showInactive: false,
    };
    const [model, setModel] = React.useState<IIPEDSDisabilityServiceReportParam>(initialModel);

    const cohortYearDataSource = React.useMemo(() => {
        let toReturn: ICohortYearDataSource[] = [];
        let startYear: number = currentYear - 10;
        let endYear: number = currentYear;

        for (let i = endYear; i > startYear; i--) {
            toReturn.push({
                endDate: `10/31/${i}`,
                cohortYear: i,
                startDate: `08/01/${i}`,
            });
        }

        return toReturn;
    }, [model.selectedAcademicYearDate]);

    const cohortYearAutoComplete = {
        options: cohortYearDataSource,
        getOptionLabel: (option: ICohortYearDataSource) => option.cohortYear.toString()
    };

    const handleFieldOnChange = (
        fieldId: string,
        value: any,
        mapFunction?: Function
    ) => {
        let updatedModel = model;
        let newValue = mapFunction ? mapFunction(value) : value;
        (updatedModel as any)[fieldId] = newValue;
        if (fieldId == "campusId") {
            (updatedModel as any)["progIdList"] = [];
        }

        if (fieldId == 'schoolReportType') {
            const selectedCohortYear = cohortYearDataSource.find(m => m.cohortYear == model.cohortYear);
            if ((value == 'program')) {

                if (!!selectedCohortYear && !!selectedCohortYear && !!model.cohortYear && model.cohortYear > 1) {
                    updatedModel.startDate = selectedCohortYear.startDate;
                    updatedModel.endDate = selectedCohortYear.endDate;
                } else {
                    updatedModel.startDate = `${model.cohortYear ?? currentYear}/08/01`;
                    updatedModel.endDate = `${model.cohortYear ?? currentYear}/10/31`;
                }
            } else {
                    updatedModel.startDate = `${model.cohortYear ?? currentYear}/10/15`;
                    updatedModel.endDate = `${model.cohortYear ?? currentYear}/10/15`;
                    (updatedModel['selectedAcademicYearDate'] as any) = `${model.cohortYear ?? currentYear}/10/15`;
            }
        }

        if(fieldId == 'selectedAcademicYearDate' && !!value){
            updatedModel.startDate = value;
            updatedModel.endDate = value;
        }

        setModel({ ...updatedModel });
    };

    const { handleSubmit } = data;

    const onSubmit = async (isPreview: any) => {
        let params = (await getReportParameters()) as IIPEDSDisabilityServiceReportParam;
        const validation = await data.triggerValidation();
        if (validation) {
            await getIPEDSDisabilityServiceReport(params, isPreview);
        }
    };

    const filterHandler = (data: any) => {
        if (data) {
            setModel(data);
        } else {
            setModel(initialModel);
        }
    };

    const handleSortByChange = (value: 'StudentName' | 'StudentIdentifier') => {
        let copy = cloneDeep(model);
        copy.orderBy = value;
        setModel(copy);
    }

    const setPrograms = (programs: any) => {
        let newProgram: string[] = [];
        if (programs !== null) {
            if (Array.isArray(programs)) {
                newProgram = programs.map((gr: any) => gr.value);
            } else {
                newProgram.push(programs.value);
            }
        }
        return newProgram;
    };

    const getReportParameters = async () => {
        const success = await data.triggerValidation();
        if (model && success) {
            let startDate = model?.startDate ? model.startDate : null;
            let endDate = model?.endDate ? model.endDate : null;
            let params: IIPEDSDisabilityServiceReportParam = {
                campusId: model?.campusId ?? userSelectedCampus,
                startDate: startDate,
                endDate: endDate,
                progIdList: model?.progIdList ?? [],
                cohortYear: model?.cohortYear,
                orderBy: model.orderBy,
                schoolReportType: model.schoolReportType,
                showSSN: model.showSSN,
                selectedAcademicYearDate: model.selectedAcademicYearDate,
            };
            return params;
        }
        return {} as IIPEDSDisabilityServiceReportParam;
    };

    let parameters = (
        <div className={classes.root}>
            <FormContext {...data}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container direction="row" spacing={1}>
                        <Grid item xs={12} sm={12} md={6}>
                            <MultiCampusAutoComplete label="Campus *"
                                multiple={false}
                                id="campusId"
                                name="campusId"
                                valueFilter={
                                    model.campusId
                                        ? {
                                            key: "value",
                                            values: [model.campusId],
                                        }
                                        : undefined
                                }
                                filterHandle={(v: any) => {
                                    handleFieldOnChange(
                                        "campusId",
                                        v ? v.value : null
                                    );
                                }}
                                error={!!data.errors.campusId}
                                inputRef={data.register({ required: true })}
                                helperText={
                                    data.errors.campusId
                                        ? "Campus is required."
                                        : undefined
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <ProgramAutoComplete
                                chipSize="small"
                                maxTags={1}
                                multiple
                                name='progId'
                                valueFilter={
                                    model.progIdList
                                        ? {
                                            key: 'value',
                                            values: model.progIdList,
                                        }
                                        : undefined
                                }
                                filterHandle={(v: any) => handleFieldOnChange('progIdList', v || [], setPrograms)}
                                activeOnly={model.showInactive ? false : true}
                                label="Programs"
                                campusIds={model.campusId ? [model.campusId] : []}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl component="fieldset" className={classes.fieldSet}>
                                <FormLabel component="legend">School Reporting Type</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="schoolReportType"
                                    name="schoolReportType"
                                    value={model.schoolReportType}
                                    onChange={(e: any) => {
                                        handleFieldOnChange('schoolReportType', e.target.value);
                                    }}
                                >
                                    <FormControlLabel
                                        labelPlacement="end"
                                        value="program"
                                        control={<Radio color="default" />}
                                        label="Program Reporter"
                                    />
                                    <FormControlLabel
                                        labelPlacement="end"
                                        value="academicYear"
                                        control={<Radio color="default" />}
                                        label="Academic Year Reporter"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        {model.schoolReportType == 'academicYear' && <Grid item md={12}>
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>IMPORTANT MESSAGE:</Typography>
                            <Typography variant="body1">
                                Use the school's "Official Report Date" or October 15th in the "Report Date" field below. The student counts on this report must tie into student counts on other IPEDS Surveys, otherwise the U.S. Department of Education will flag these reports as inconsistent and will require futher explanations.

                                In addition, the "Report End Date" chosen for this report must be used for all other IPEDS reports that have this warning message. Please double check your "Report Date" settings before submitting the data.
                            </Typography>
                        </Grid>}

                        {model.schoolReportType == 'academicYear' && <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    minDate={new Date(model.cohortYear, 0, 1)}
                                    maxDate={new Date(model.cohortYear, 11, 31)}
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    id="selectedAcademicYearDate"
                                    name="selectedAcademicYearDate"
                                    label="Report Date *"
                                    value={model.selectedAcademicYearDate ? model.selectedAcademicYearDate : null}
                                    onChange={(e: any, value: any) =>
                                        handleFieldOnChange("selectedAcademicYearDate", value ? value : undefined)
                                    }
                                    //className={classes.startDateField}
                                    KeyboardButtonProps={{
                                        "aria-label": "report date",
                                    }}
                                    error={!!data.errors["selectedAcademicYearDate"]}
                                    innerRef={(ref: any) => {
                                        data.register("selectedAcademicYearDate", {
                                            required: true,
                                            // validate: {
                                            //   beforeEndDate: (value) =>
                                            //     new Date(value) <
                                            //       new Date(data.getValues()["excludeStuBefThisDate"]) ||
                                            //     "Start Date must be before end date",
                                            // },
                                        });
                                        data.setValue("selectedAcademicYearDate", model.selectedAcademicYearDate);
                                    }}
                                    helperText={
                                        data.errors["selectedAcademicYearDate"]
                                            ? (data.errors["selectedAcademicYearDate"] as FieldError).message
                                            : undefined
                                    }
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>}

                        <Grid item xs={4}>
                            {model.schoolReportType == 'program' && <Typography>{`Report Date: ${cohortYearDataSource.findIndex(m => m.cohortYear === model.cohortYear) >= 0 ? `${cohortYearDataSource.find(m => m.cohortYear === model.cohortYear)?.startDate} thru ${cohortYearDataSource.find(m => m.cohortYear === model.cohortYear)?.endDate}` : ''}`}</Typography>}
                            <Autocomplete
                                {...cohortYearAutoComplete}
                                autoComplete
                                includeInputInList
                                value={!!model.cohortYear ? cohortYearDataSource.find(m => m.cohortYear == (model.cohortYear)) : null}
                                onChange={(e: any, v: any) => {
                                    cohortYearOnChange(v);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={`Cohort Year *`}
                                        name="cohortYear"
                                        error={!!data.errors.cohortYear}
                                        inputRef={data.register({ required: true })}
                                        helperText={
                                            data.errors.exportType
                                                ? "Cohort Year is required."
                                                : undefined
                                        }
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl component="fieldset" className={classes.fieldSet}>
                                <FormLabel component="legend">Sort By</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="orderBy"
                                    name="orderBy"
                                    value={model.orderBy}
                                    onChange={(e: any) => {
                                        handleSortByChange(e.target.value)
                                    }}
                                >
                                    <FormControlLabel
                                        labelPlacement="end"
                                        value="StudentIdentifier"
                                        control={<Radio color="default" />}
                                        label="Student number"
                                    />
                                    <FormControlLabel
                                        labelPlacement="end"
                                        value="StudentName"
                                        control={<Radio color="default" />}
                                        label="Last Name"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                    </Grid>
                </form>
            </FormContext>
        </div>
    );
    reportOverview.parameters = parameters;

    return (
        <ReportOverview
            reportOverview={reportOverview}
            filterHandler={filterHandler}
            getReportParameters={getReportParameters}
            exportHandler={onSubmit}
            preview={preview}
            previewLoader={previewLoader}
            hidePreview={true}
        />
    );
};
export default IPEDSDisabilityService;
