import axios from "axios";
import { string } from "prop-types";
import { AppConfig } from "../interfaces/AppConfig";
import { getStore } from "../index";
import IToasterOptions from "../interfaces/IToasterOptions";
import { toasterConstants } from "../constants/actions.constants";
export var ApiConfig = { token: "" };

export default () => {
  let axiosInstance = axios.create({
    baseURL: apiUrl(),
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error & error.response && error.response.status) {
        if (error.response.status === 401) {
        } else if (error.response.status === 403) {
          const store = getStore().store;

          if (store) {
            let data: IToasterOptions = {
              message:
                "The user does not have permissions to perform this action.",
              showToaster: true,
              variant: "error",
            };
            store.dispatch({ type: toasterConstants.SHOW_TOASTER, data });
          }
        }
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export function setToken(token: string) {
  ApiConfig.token = token;
}

export function authHeader() {
  try {
    let session: any = localStorage.getItem("persist:root");
    let user = JSON.parse(JSON.parse(session).session).user;
    if (user && user.token) {
      return "Bearer " + user.token;
    } else {
      return "Bearer " + ApiConfig.token;
    }
  } catch {
    return "Bearer " + ApiConfig.token;
  }

  //we may be able to detect expired token here and take appropriate action.
}
export function apiUrl() {
  try {
    let session: any = localStorage.getItem("persist:root");

    let appConfig: any = JSON.parse(JSON.parse(session).session).appConfig;
    if (localStorage.getItem('ApiUrl')) {
      return localStorage.getItem('ApiUrl');
    }
    if (appConfig) {
      return appConfig.appConfig.ApiUrl;
    } else {
      return "";
    }
  } catch {
    return "";
  }
}
