import React, { useRef, useEffect, useState } from "react";
import { Grid, Switch, FormControlLabel, Tooltip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputField from "../../../components/_Layout/Inputs/InputField";
import IneligibilityReasonAutoComplete from "../../../components/AutoComplete/IneligibilityReasonAutoComplete";
import IEligibilityDetails from "../../../interfaces/student/placement/Elidibility";
import { useFormContext, FieldError } from "react-hook-form";
import { EmptyGuid } from "../../../utils/constants";
import StudentPlacedACCETAutoComplete from "../../../components/AutoComplete/StudentPlacedACCETAutoComplete";
import CompletionWaiverACCETAutoComplete from "../../../components/AutoComplete/CompletionWaiverACCETAutoComplete";

type EligibilityProps = {
  eligibilityDetails: IEligibilityDetails;
  updateDetails(details: IEligibilityDetails): void;
};
const scrollToRef = (ref: any) => {
  ref.current.scrollIntoView({ behavior: "smooth" });
};

const Eligibility = (props: EligibilityProps) => {
  const { eligibilityDetails, updateDetails } = props;
  const data = useFormContext<IEligibilityDetails>();

  const handleFieldOnChange = (
    fieldId: keyof IEligibilityDetails,
    value: any
  ) => {
    let updatedModel = { ...props.eligibilityDetails };
    (updatedModel as any)[fieldId] = value;
    data.setValue(fieldId, value);
    if (fieldId === "eligible") {
      updatedModel.ineligibilityReasonId = undefined;
      data.setValue("ineligibilityReasonId", undefined);
      data.triggerValidation("ineligibilityReasonId");
    }

    if (fieldId == "licenseNumber") {
      if (!!value && (value as string).length > 200) {
        data.setError(
          "licenseNumber",
          "maxLengthError",
          "License number can't be longer than 200 characters"
        );
      } else {
        data.clearError("licenseNumber");
      }
    }

    data.triggerValidation(fieldId);
    updateDetails(updatedModel);
  };

  const handleNaccassAuthorizedCheckBoxChange = (isChecked: boolean) => {
    let updatedModel = { ...eligibilityDetails };
    updatedModel.notNaccasAuthorized = isChecked;
    updatedModel.ineligibilityReasonId =
      updatedModel.notNaccasAuthorized === true
        ? undefined
        : updatedModel.ineligibilityReasonId;
    updatedModel.eligible =
      updatedModel.notNaccasAuthorized === true ? "No" : updatedModel.eligible;

    data.setValue("notNaccasAuthorized", isChecked);
    data.setValue(
      "ineligibilityReasonId",
      updatedModel.notNaccasAuthorized === true
        ? undefined
        : updatedModel.ineligibilityReasonId
    );
    data.setValue(
      "eligible",
      updatedModel.notNaccasAuthorized === true ? "No" : updatedModel.eligible
    );

    data.triggerValidation("notNaccasAuthorized");
    data.triggerValidation("ineligibilityReasonId");
    data.triggerValidation("eligible");
    updateDetails(updatedModel);
  };

  const helpertxt = data.errors.ineligibilityReasonId
    ? (data.errors.ineligibilityReasonId as FieldError).message === undefined
      ? ""
      : (data.errors.ineligibilityReasonId as FieldError).message + ""
    : "";

  data.register("eligible", {
    validate: () => {
      // if state already contain same value useEffect won't work so make it false before setting it.
      setScrollToError(false);
      setScrollToError(!props.eligibilityDetails.eligible);
      return !props.eligibilityDetails.eligible
        ? "Eligibility is required."
        : true;
    },
  });
  data.register("ineligibilityReasonId", {
    validate: () =>
      !!(
        props.eligibilityDetails.eligible &&
        props.eligibilityDetails.eligible === "No"
      ) &&
      (!props.eligibilityDetails.ineligibilityReasonId ||
        props.eligibilityDetails.ineligibilityReasonId === EmptyGuid) &&
      props.eligibilityDetails.notNaccasAuthorized === false
        ? "Ineligibility Reasons is required."
        : true,
  });

  const eligibilityDivRef = useRef<any>(null);
  const [scrollToError, setScrollToError] = useState<boolean>(false);
  useEffect(() => {
    if (scrollToError) scrollToRef(eligibilityDivRef);
  }, [scrollToError]);

  return (
    <div ref={eligibilityDivRef}>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={4} sm={12} md={4} lg={4}>
          <Tooltip
            title="Graduated student is not legally authorized to practice (as per NACCAS Spec)"
            aria-label="ToolNotes"
          >
            <FormControlLabel
              labelPlacement="start"
              label="Is not Authorized (Naccas)"
              control={
                <Switch
                  checked={eligibilityDetails?.notNaccasAuthorized}
                  id="job"
                  name="notNaccasAuthorized"
                  value={eligibilityDetails?.notNaccasAuthorized}
                  onChange={(e: any, value: any) => {
                    handleNaccassAuthorizedCheckBoxChange(e.target.checked);
                  }}
                  color="primary"
                />
              }
            />
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            options={["Yes", "No"]}
            id="isStudentLicensed"
            value={eligibilityDetails.isStudentLicensed}
            onChange={(e: any, value: any) => {
              handleFieldOnChange("isStudentLicensed", value);
            }}
            renderInput={(params) => (
              <InputField
                {...params}
                label="Is Student Licensed?"
                name="isStudentLicensed"
                margin="none"
                fullWidth
                error={!!data.errors.isStudentLicensed}
                helperText={
                  data.errors.isStudentLicensed
                    ? (data.errors.isStudentLicensed as FieldError).message
                    : undefined
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <StudentPlacedACCETAutoComplete
            id="studentPlacedACCETId"
            name="studentPlacedACCETId"
            label={"Is Student Placed? (ACCET)"}
            multiple={false}
            valueFilter={
              eligibilityDetails.studentPlacedACCETId
                ? {
                    key: "value",
                    values: [eligibilityDetails.studentPlacedACCETId],
                  }
                : undefined
            }
            filterHandle={(v: any) => {
              handleFieldOnChange("studentPlacedACCETId", v ? v.value : null);
            }}
            error={!!data.errors.studentPlacedACCETId}
            helperText={
              data.errors.studentPlacedACCETId
                ? (data.errors.studentPlacedACCETId as FieldError).message
                : undefined
            }
          />
        </Grid>

        <Grid item xs={4}>
          <Autocomplete
            disabled={!(eligibilityDetails.notNaccasAuthorized === false)}
            options={["Yes", "No"]}
            id="job"
            value={eligibilityDetails.eligible}
            onChange={(e: any, value: any) => {
              handleFieldOnChange("eligible", value);
            }}
            renderInput={(params) => (
              <InputField
                {...params}
                label="Is the student eligible for placement?"
                name="eligible"
                margin="none"
                fullWidth
                error={!!data.errors.eligible}
                helperText={
                  data.errors.eligible
                    ? (data.errors.eligible as FieldError).message
                    : undefined
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <InputField
            value={eligibilityDetails.licenseNumber ?? ""}
            label="License Number"
            name="licenseNumber"
            id="licenseNumberId"
            key={"licenseNumberIdKey"}
            margin="none"
            fullWidth
            error={!!data.errors.licenseNumber}
            helperText={
              !!data.errors.licenseNumber
                ? (data.errors.licenseNumber as FieldError).message
                : undefined
            }
            onChange={(e: any) => {
              handleFieldOnChange(
                "licenseNumber",
                e ? e.target.value : undefined
              );
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <CompletionWaiverACCETAutoComplete
            id={"completionWaiverACCETId"}
            name={"completionWaiverACCETId"}
            label={"Completion Waiver (5C-ACCET)"}
            valueFilter={
              eligibilityDetails.completionWaiverACCETId
                ? {
                    key: "value",
                    values: [eligibilityDetails.completionWaiverACCETId],
                  }
                : undefined
            }
            filterHandle={(v: any) => {
              handleFieldOnChange(
                "completionWaiverACCETId",
                v ? v.value : null
              );
            }}
            error={!!data.errors.completionWaiverACCETId}
            helperText={
              data.errors.completionWaiverACCETId
                ? (data.errors.completionWaiverACCETId as FieldError).message
                : undefined
            }
          />
        </Grid>

        <Grid item xs={4}>
          {eligibilityDetails.eligible === "No" &&
            eligibilityDetails.notNaccasAuthorized === false && (
              <IneligibilityReasonAutoComplete
                name="ineligibilityReasonId"
                id="ineligibilityReasonId"
                label="Ineligibility Reasons"
                valueFilter={
                  props.eligibilityDetails.ineligibilityReasonId &&
                  props.eligibilityDetails?.ineligibilityReasonId !== EmptyGuid
                    ? {
                        key: "value",
                        values: [
                          props.eligibilityDetails?.ineligibilityReasonId,
                        ],
                      }
                    : undefined
                }
                filterHandle={(v: any) => {
                  handleFieldOnChange(
                    "ineligibilityReasonId",
                    v ? v.value : null
                  );
                }}
                error={!!data.errors.ineligibilityReasonId}
                helperText={
                  data.errors.ineligibilityReasonId
                    ? (data.errors.ineligibilityReasonId as FieldError).message
                    : undefined
                }
              />
            )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Eligibility;
