import * as React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Link, { LinkProps } from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {
	Link as RouterLink,
	useLocation,
	Route,
	MemoryRouter,
	matchPath,
} from "react-router-dom";
import { Omit } from "@material-ui/types";
import LocalPrintshopOutlinedIcon from "@material-ui/icons/LocalPrintshopOutlined";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import SearchIcon from "@material-ui/icons/Search";
import { width } from "@material-ui/system";
import {
	Grid,
	Paper,
	TextField,
	CircularProgress,
	LinearProgress,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import getBreadCrumbs from "../../../utils/getBreadCrumbs";
import breadCrumbActionConfig from "../BreadCrumbs/breadCrumbConfig";
import BreadCrumbActionItem from "../BreadCrumbs/BreadCrumbActionItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { checkPermissions } from "../../../utils/securityFunctions";
import StudentNavigator from "../StudentNavigator/StudentNavigator";

const HideableAutoComplete = React.forwardRef(
	(props: any, ref: React.Ref<HTMLDivElement>) => (
		<div ref={ref} style={{ display: "none" }}>
			<Autocomplete
				id="combo-box-demo"
				options={top100Films}
				getOptionLabel={(option: FilmOptionType) => option.title}
				style={{ width: 300 }}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Combo box"
						variant="outlined"
						fullWidth
					/>
				)}
			/>
		</div>
	)
);

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			display: "flex",
			flexDirection: "column",
			width: 360,
			height: "100%",
		},
		text: {
			fontWeight: "bold",
			float: "left",
			fontSize: 15,
			lineHeight: 2,
		},
		icon: {
			float: "right",
			width: "100%",
			textAlign: "right",
		},
		floatLeft: {
			float: "left",
			width: "50%",
		},
		gridcontainer: {
			height: "100%",
			width: "100%",
			lineHeight: 2,
		},
		spacing: {
			paddingLeft: theme.spacing(3),
		},
		color: {
			color: "#546e7a",
			marginRight: theme.spacing(3),
		},
		flexContainer: {
			display: "flex",
			flexDirection: "row",
			padding: 0,
		},

		searchBar: {
			"& .MuiInputLabel-outlined": {
				zIndex: 1,
				transform: "translate(18px, 15px) scale(1)",
			},
			"& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
				zIndex: 1,
				transform: "translate(14px, -6px) scale(0.75)",
			},
			"& .MuiAutocomplete-inputRoot": {
				padding: 0,
			},
		},

		hidden: {
			display: "none",
		},
	})
);

interface FilmOptionType {
	title: string;
	year: number;
}

type Breadcrumb = {
	route: Array<string>;
	navigation: Array<IBreadcrumbNavigation>;
};

type IBreadcrumbNavigation = {
	title: string;
	icon: string;
	url: string;
	callbackFunction: string;
};
// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
	{ title: "The Shawshank Redemption", year: 1994 },
	{ title: "The Godfather", year: 1972 },
	{ title: "The Godfather: Part II", year: 1974 },
	{ title: "The Dark Knight", year: 2008 },
	{ title: "12 Angry Men", year: 1957 },
	{ title: "Schindler's List", year: 1993 },
	{ title: "Pulp Fiction", year: 1994 },
];
export const buildBreadCrumbs = (props: any) => {
	const { breadCrumbs, crumb } = props;
	if (!crumb.current) {
		breadCrumbs.push(
			<Link
				color="inherit"
				id={crumb.title}
				key={crumb.title}
				{...(crumb.isParent ? {} : { href: crumb.href })}
				onClick={() => {}}
			>
				{crumb.title}
			</Link>
		);
	} else {
		breadCrumbs.push(
			<Typography color="textPrimary">{crumb.title}</Typography>
		);
	}

	return breadCrumbs;
};

export const buildBreadCrumbActions = (props: any) => {
	const { actionItems, actionItem } = props;
	actionItems.push(
		<BreadCrumbActionItem
			icon={actionItem.icon}
			action={actionItem.action}
			actionUrl={actionItem.actionUrl}
			secondary={actionItem.secondary}
			child={actionItem.component}
			renderComponentHandler={props.renderComponentHandler}
			setBreadCrumbBarLoader={props.setBreadCrumbBarLoader}
		></BreadCrumbActionItem>
	);

	return actionItems;
};

export default function SimpleBreadcrumbs(props: any) {
	const classes = useStyles({});
	const location = useLocation();
	const breadCrumbs = getBreadCrumbs(location.pathname);

	let actionConfig = breadCrumbActionConfig[0].pages.find(
		(pageActions) => pageActions.href === location.pathname
	);

	if (!actionConfig)
		actionConfig = breadCrumbActionConfig[0].pages.find(
			(pageActions) =>
				matchPath(location.pathname, {
					path: pageActions.route,
					exact: true,
				}) != null
		);

	const tab = useSelector((state: any) => state.tab);

	if (tab && tab.tabLabel) {
		if (breadCrumbs.length > 0) {
			if (
				breadCrumbs[breadCrumbs.length - 1].title.toLowerCase() !==
				tab.tabLabel.toLowerCase()
			) {
				breadCrumbs.push({ href: "", title: tab.tabLabel, current: true });
			}
		} else breadCrumbs.push({ href: "", title: tab.tabLabel, current: true });
	}

	const student = useSelector((state: any) =>
		state.userstate[state.session.user.userId]
			? state.userstate[state.session.user.userId].selectedStudent
			: null
	);
	const rootPath = breadCrumbs[0]?.href ? breadCrumbs[0].href : undefined;
	if (
		rootPath === "/student" &&
		student != null &&
		student.studentName != null
	) {
		breadCrumbs.push({ href: "", title: student.studentName, current: true });
	}

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const [searchBarElVisible, setSearchBarElVisibility] = React.useState(false);
	const [breadCrumbBarLoader, setBreadCrumbBarLoader] = React.useState(false);
	const [renderActionElement, setRenderActionElement] =
		React.useState<JSX.Element | null>(null);

	let breadCrumbActionTemplates: Array<any> = [];
	let breadCrumbActionItems: Array<JSX.Element> = [];
	let searchable: boolean | undefined = false;
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleChildAction = React.useCallback(
		(action: any) => {
			let element: JSX.Element = action();
			if (element) setRenderActionElement(element);
		},
		[setRenderActionElement]
	);

	const renderComponentHandler = React.useCallback(
		(componentToRender: any) => {
			if (componentToRender) setRenderActionElement(componentToRender);
		},
		[setRenderActionElement]
	);
	const handleClose = () => {
		setAnchorEl(null);
	};
	const buildSecondaryActions = (actions: Array<any>) => {
		let menu = (
			<Menu
				id="fade-menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				TransitionComponent={Fade}
			>
				{actions.map((actionItem: any) => {
					return (
						<MenuItem
							onClick={function () {
								handleClose();
								handleChildAction(actionItem.action);
							}}
						>
							{actionItem.text}
						</MenuItem>
					);
				})}
			</Menu>
		);
		return menu;
	};

	if (actionConfig) {
		breadCrumbActionTemplates = actionConfig.actions;
		searchable = actionConfig.searchable;
	}

	let primaryActions = breadCrumbActionTemplates.filter((item: any) => {
		return (
			item.isSecondary === false && checkPermissions(item.permissions || [])
		);
	});
	/* let secondaryActions = breadCrumbActionTemplates.filter((item: any) => {
		return item.isSecondary === true && checkPermissions(item.permissions || []);
	}); */

	if (searchable) {
		const searchBar = (
			<Autocomplete
				id="combo-box-demo"
				options={top100Films}
				getOptionLabel={(option: FilmOptionType) => option.title}
				style={{ width: 300, padding: 0 }}
				className={clsx(classes.searchBar, {
					[classes.hidden]: !searchBarElVisible,
				})}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Search by Keyword"
						style={{ padding: 0 }}
						className={classes.searchBar}
						variant="outlined"
						fullWidth
					/>
				)}
			/>
		);

		const toggleSearch = () => {
			setSearchBarElVisibility((visible: boolean) => !visible);
		};
		//ReactDOM.findDOMNode(this.refs.["rbc-toolbar-label"]).style.display = 'none'
		breadCrumbActionItems.push(
			<BreadCrumbActionItem
				icon={SearchIcon}
				action={toggleSearch}
				secondary={false}
				child={searchBar}
			></BreadCrumbActionItem>
		);
	}
	if (breadCrumbActionTemplates) {
		breadCrumbActionItems.push(
			primaryActions.reduce(
				(actionItems, actionItem) =>
					buildBreadCrumbActions({
						actionItems,
						actionItem,
						renderComponentHandler,
						setBreadCrumbBarLoader,
					}),
				[]
			)
		);
	}

	/* if (secondaryActions)
		breadCrumbActionItems.push(
			<BreadCrumbActionItem
				icon={MoreVertOutlinedIcon}
				action={handleClick}
				secondary={true}
				key={`secondary-list`}
				child={buildSecondaryActions(secondaryActions)}
			></BreadCrumbActionItem>
		); */

	let internalActionElement;

	if (renderActionElement) {
		internalActionElement = React.cloneElement(renderActionElement, {
			onClose: setRenderActionElement,
		});
	}

	return (
		<React.Fragment>
			<div className={clsx(classes.gridcontainer, classes.spacing)}>
				{internalActionElement && internalActionElement}
				<Grid
					container
					direction="row"
					className={classes.gridcontainer}
					justify="center"
					alignItems="center"
				>
					<Grid item xs={4}>
						<Grid
							container
							item
							xs={12}
							justify="flex-start"
							alignItems="center"
						>
							<Breadcrumbs
								data-testid="testId_BreadCrumbs"
								aria-label="breadcrumb"
								className={classes.text}
							>
								{breadCrumbs.reduce(
									(breadCrumbs, crumb) =>
										buildBreadCrumbs({ breadCrumbs, crumb }),
									[]
								)}
							</Breadcrumbs>
						</Grid>
					</Grid>
					<Grid item xs={4}>
						{rootPath === "/student" && <StudentNavigator />}
					</Grid>
					<Grid item xs={4}>
						<Grid
							container
							item
							xs={12}
							spacing={2}
							justify="flex-end"
							alignItems="flex-end"
						>
							<List className={classes.flexContainer}>
								{breadCrumbActionItems}
							</List>
						</Grid>
					</Grid>
				</Grid>
			</div>
			{breadCrumbBarLoader && (
				<Grid container direction="row" justify="center" alignItems="center">
					<Grid item xs={12}>
						<LinearProgress></LinearProgress>
					</Grid>
				</Grid>
			)}
		</React.Fragment>
	);
}
