import * as React from "react";
import { useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { Checkbox } from "@material-ui/core";

export const ClassesByCampusApiAutoComplete = {
  config: {
    acId: "classesByCampusId",
    cacheTime: 5,
    route: "/AcademicRecords/Class/GetClassesByCampus",
    requestType: "GET",
    body: null,
    hasUrlParams: true,
    useCache: false,
    storeInCache: false,
    isCampusSpecific: false,
    isUserSpecific: false,
    loadOnRender: true,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
      return data;
    },
  },
};

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& label.Mui-focused": {
        color: theme.palette.primary.white,
      },
      "& input": {
        color: theme.palette.black,
        opacity: 1,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: 13,
        paddingRight: "0px !important",
        paddingLeft: "1px !important",
        minWidth: '100px !important'
      },
      "& .MuiInputBase-root": {
        backgroundColor: theme.palette.site.secondary,
        borderRadius: 20,
        paddingLeft: "10px !important",
        fontSize: 13,
        minWidth: "225px",
        maxWidth: "750px",
        display: 'inline-flex',
        width: 'max-content',
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.black,
        paddingTop: "2.5px !important",
        paddingBottom: "2.5px !important",
      },

    },
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {
      width: "95%",
    }, inlineText: {
      display: "inline-block",
      paddingRight: "2px !important"
    },
  })
);

export interface ClassProps extends FilterParams {
}

const ClassesByCampusAutoComplete = (props: ClassProps) => {
  const classes = useStyles({});
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const inputRef: any = useRef(null);

  const filterHandle = props.filterHandle;

  const classTypesOnChange = (e: any) => {
    if (filterHandle) filterHandle(e);
  };

  return (
    <div className={clsx(classes.divWidth)}>
      <ApiAutoComplete
        config={ClassesByCampusApiAutoComplete.config}
        loading={userSelectedCampus ? false : true}
        reqParams={{ campusId: userSelectedCampus}}
        classes={{
          option: "text-black",
        }}
        label={props.label ? props.label : "Class"}
        onChange={(e: any) => {
          classTypesOnChange(e);
        }}
        multiple={false}
        renderOption={props.multiple ? (option: any, params: { selected: boolean }) => (
          <React.Fragment>
            <Checkbox color="primary" checked={params.selected} />
            {option.text}
          </React.Fragment>
        ) : undefined}
        renderTags={(value: any, _getTagProps) => {
          return value.map((option: any, index: number) => (option.text + (index === value.length - 1 ? '' : ', ')));
        }}
        renderInput={(params: any) => {
          return (
            <ThemeTextField
              {...params}
              fullWidth
              InputProps={{
                ...params.InputProps,
                startAdornment: <React.Fragment> <div className={classes.inlineText}>Class: {!!(props.multiple) && params.InputProps.startAdornment}</div></React.Fragment>,
                disableUnderline: true,
              }}
            />
          );
        }}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        inputRef={props.inputRef ? props.inputRef : inputRef}
        error={props.error}
        helperText={props.helperText}
        inputName={props.name}
      ></ApiAutoComplete>
    </div>
  );
};
export default ClassesByCampusAutoComplete;
