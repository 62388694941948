import React from "react";
import moment from "moment";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import {
	Typography,
	Paper,
	CardContent,
	Grid,
	Button,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
import ProgressSaveButton from "../../../components/_Layout/Buttons/ProgressSaveButton";
import * as CurrentlyAttendingApi from "../../../api/student/status/CurrentlyAttendingApi";
import { ApiResultStatus } from "../../../enums/ApiResultStatus";
import { SystemEnrollmentStatus } from "../../../enums/SystemEnrollmentStatus";
import { useFormContext, Controller } from "react-hook-form";

type ReturnToEnrollmentProps = {
	setSnackBarProps: any;
	updateEvent: any;
	closeEvent: any;
	newPageEvent: any;
	statusChange: any;
	statusChangeEnrollment: any;
};

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.background.paper,
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2),
			},
		},
		paperRoot: {
			width: "100%",
		},
		cardTitle: {
			fontSize: theme.spacing(2),
			color: theme.palette.black,
			textDecoration: "bold",
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1),
		},
		cardContent: {
			padding: theme.spacing(1, 1),
		},
		datePicker: {
			"& .MuiIconButton-root": {
				padding: theme.spacing(0.8),
			},
		},
		actionContainer: {
			marginTop: theme.spacing(3),
		},
		button: {
			marginRight: theme.spacing(1),
			marginLeft: theme.spacing(1),
		},
	})
);

const ReturnToEnrollment = (props: ReturnToEnrollmentProps) => {
	const classes = useStyles({});

	const data = useFormContext();

	const [changeEnrollmentData, setChangeEnrollmentData] = React.useState<any>({
		...props.statusChangeEnrollment,
	});
	const [loaderState, setLoaderState] = React.useState<boolean>(false);

	const getTitle = () => {
		let title = props.statusChange.statusDescription;

		switch (changeEnrollmentData?.systemStatus as SystemEnrollmentStatus) {
			case SystemEnrollmentStatus.LeaveOfAbsence:
				title = "Return From Leave of Absence";
				break;
			case SystemEnrollmentStatus.Suspension:
				title = "Return From Suspension";
				break;
			case SystemEnrollmentStatus.FutureStart:
				title = "Start Attending";
				break;
			case SystemEnrollmentStatus.DisciplinaryProbation:
			case SystemEnrollmentStatus.WarningProbation:
				title = "Return From Probation";
				break;
		}

		return title;
	};

	const getMessage = () => {
		let msg;
		msg = "Enter Effective Date";

		return msg;
	};

	const handleFieldOnChange = (fieldId: string, value: any) => {
		let updatedData = changeEnrollmentData;
		(updatedData as any)[fieldId] = value;
		setChangeEnrollmentData({ ...updatedData });
	};

	const handleReturnToEnrollment = () => {
		switch (changeEnrollmentData?.systemStatus as SystemEnrollmentStatus) {
			case SystemEnrollmentStatus.LeaveOfAbsence:
				ReturnFromLeaveOfAbsence();
				break;
			case SystemEnrollmentStatus.Suspension:
				ReturnFromSuspension();
				break;
			case SystemEnrollmentStatus.FutureStart:
			case SystemEnrollmentStatus.CurrentlyAttending:
				StartAttending();
				break;
			case SystemEnrollmentStatus.DisciplinaryProbation:
			case SystemEnrollmentStatus.WarningProbation:
				ReturnFromProbation();
				break;
		}
	};

	const handleClose = () => {
		props.closeEvent();
	};

	const ReturnFromLeaveOfAbsence = () => {
		data.triggerValidation().then((validation: any) => {
			if (validation) {
				setLoaderState(true);
				CurrentlyAttendingApi.ReturnFromLeaveOfAbsence(
					changeEnrollmentData,
					props.statusChange.statusCodeId
				).then(
					(response: any) => {
						setLoaderState(false);
						handleSubmitResponse(response, true);
					},
					(exception: any) => {
						setLoaderState(false);
						props.setSnackBarProps((snackBarProps: any) => {
							return {
								variant: "error",
								showSnackBar: true,
								messageInfo: exception,
							};
						});
					}
				);
			}
		});
	};

	const ReturnFromSuspension = () => {
		data.triggerValidation().then((validation: any) => {
			if (validation) {
				setLoaderState(true);
				CurrentlyAttendingApi.ReturnFromSuspension(
					changeEnrollmentData,
					props.statusChange.statusCodeId
				).then(
					(response: any) => {
						setLoaderState(false);
						handleSubmitResponse(response, true);
					},
					(exception: any) => {
						setLoaderState(false);
						props.setSnackBarProps((snackBarProps: any) => {
							return {
								variant: "error",
								showSnackBar: true,
								messageInfo: exception,
							};
						});
					}
				);
			}
		});
	};

	const ReturnFromProbation = () => {
		data.triggerValidation().then((validation: any) => {
			if (validation) {
				setLoaderState(true);
				CurrentlyAttendingApi.ReturnFromProbation(
					changeEnrollmentData,
					props.statusChange.statusCodeId
				).then(
					(response: any) => {
						setLoaderState(false);
						handleSubmitResponse(response, true);
					},
					(exception: any) => {
						setLoaderState(false);
						props.setSnackBarProps((snackBarProps: any) => {
							return {
								variant: "error",
								showSnackBar: true,
								messageInfo: exception,
							};
						});
					}
				);
			}
		});
	};

	const StartAttending = () => {
		data.triggerValidation().then((validation: any) => {
			if (validation) {
				setLoaderState(true);
				CurrentlyAttendingApi.ChangeToCurrentlyAttending(
					changeEnrollmentData,
					props.statusChange.statusCodeId
				).then(
					(response: any) => {
						setLoaderState(false);
						handleSubmitResponse(response, false);
					},
					(exception: any) => {
						setLoaderState(false);
						props.setSnackBarProps((snackBarProps: any) => {
							return {
								variant: "error",
								showSnackBar: true,
								messageInfo: exception,
							};
						});
					}
				);
			}
		});
	};

	const handleSubmitResponse = (response: any, redirectFlag: boolean) => {
		if (response.data != undefined && response.data.resultStatus != undefined) {
			switch (response.data.resultStatus) {
				case ApiResultStatus.Success:
					props.setSnackBarProps((props: any) => {
						return {
							variant: "success",
							showSnackBar: true,
							messageInfo: response.data.resultStatusMessage,
						};
					});
					props.updateEvent(props.statusChange.statusDescription);
					if (redirectFlag) {
						props.newPageEvent("/student/1/profile/enrollments");
					} else {
						handleClose();
					}
					break;
				case ApiResultStatus.Warning:
					props.setSnackBarProps((props: any) => {
						return {
							variant: "warning",
							showSnackBar: true,
							messageInfo: response.data.resultStatusMessage,
						};
					});
					break;
				case ApiResultStatus.Error:
					props.setSnackBarProps((props: any) => {
						return {
							variant: "error",
							showSnackBar: true,
							messageInfo: response.data.resultStatusMessage,
						};
					});
					break;
				default:
			}
		} else {
			props.setSnackBarProps((props: any) => {
				return {
					variant: "error",
					showSnackBar: true,
					messageInfo: "Error changing to currently attending status.",
				};
			});
		}
	};

	return (
		<Paper className={classes.paperRoot}>
			<Typography className={classes.cardTitle}>{getTitle()}</Typography>
			<CardContent className={classes.cardContent}>
				<Grid container direction="row" spacing={2}>
					<Grid item xs={12}>
						<Typography>{getMessage()}</Typography>
					</Grid>
					<Grid item xs={12}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDatePicker
								disableToolbar
								variant="inline"
								format="MM/dd/yyyy"
								id="effectiveDate"
								label="Effective Date"
								fullWidth
								autoOk={true}
								required
								KeyboardButtonProps={{
									"aria-label": "effective date",
								}}
								value={
									changeEnrollmentData.effectiveDate
										? new Date(
												changeEnrollmentData.effectiveDate
										  ).toLocaleDateString()
										: null
								}
								error={!!data.errors["effectiveDate"]}
								innerRef={(ref: any) => {
									data.register("effectiveDate", { required: true });
									data.setValue(
										"effectiveDate",
										changeEnrollmentData.effectiveDate
									);
								}}
								onChange={(val: any) => {
									handleFieldOnChange("effectiveDate", val);
								}}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
					<Grid item xs={12}>
						<div className={classes.actionContainer} id="returnActions">
							<ProgressSaveButton
								text="Save"
								onClick={() => {
									handleReturnToEnrollment();
								}}
								loading={loaderState}
							></ProgressSaveButton>
							<Button
								onClick={handleClose}
								color="secondary"
								variant="contained"
								className={classes.button}
							>
								Cancel
							</Button>
						</div>
					</Grid>
				</Grid>
			</CardContent>
		</Paper>
	);
};

export default ReturnToEnrollment;
