import * as React from 'react';
import {
	makeStyles,
	createStyles,
	Grid,
	IconButton,
	Dialog,
	DialogContent,
	DialogTitle,
	Typography,
	Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as EmergencyFundsApi from '../../api/student/financials/emergencyFunds/EmergencyFundsApi';
import { IEmergencyFundsDetail } from '../../interfaces/student/financials/IEmergencyFundsInfo';
import { FormContext, useForm, FieldError } from 'react-hook-form';
import SearchBarFilter from '../../components/_Layout/Search/SearchBarFilter';
import InputField from '../../components/_Layout/Inputs/InputField';
import { InputType } from '../../constants/uiConstants/inputConstants';
import EmergencyFundsTypesAutoComplete from '../../components/AutoComplete/EmergencyFundsTypesAutoComplete';
import AwardYearAutoComplete from '../../components/AutoComplete/AwardYearAutoComplete';
import StudentProgramVersionAutoComplete from '../../components/AutoComplete/StudentProgramVersionAutoComplete';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';


const useStyles = makeStyles((theme: any) =>
	createStyles({
		cardTitle: {
			fontSize: 17,
			color: theme.palette.black,
			fontWeight: 'bold',
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(0),
		},
		headerText: {
			float: 'left',
			padding: theme.spacing(1.5),
		},
		dialogCloseButton: {
			float: 'right',
		},
		textField: {
			marginRight: theme.spacing(1),
			width: '100%',
		},
		mainContent: {
			minHeight: 600,
		},
		enrollmentAutocomplete: {
			textAlign: 'center',
		},
		enrollmentSelector: {
			fontSize: theme.spacing(1.5),
		},
		hidden: {
			display: 'none',
		},
		width100: {
			width: '100%',
		},
		cancelBtn: {
			marginLeft: '20',
		},
		searchBarContainer: {
			backgroundColor: theme.palette.site.secondary,
			borderRadius: 20,
			paddingLeft: '10px !important',
			fontSize: 13,
			fontWeight: theme.typography.fontWeightBold,
			color: theme.palette.black,
			paddingTop: '2.5px !important',
			paddingBottom: '2.5px !important',
		},
	})
);
type AddFundsPopUpProps = {
    open?: boolean;
    editedFundId: number | null;
    isLoading: boolean;
    setLoading: (loading: boolean) => void;
    handleClose: () => void;
    setSnackBar: any;
};
export const ConvertCurrencyValueToInput = (value: any) => {
	return Number((value + '').replace('$ ', '').replace(/,/g, ''));
};

const AddEmergencyFunds = (
	props: Readonly<AddFundsPopUpProps>
) => {
	const { open, handleClose } = props;
	const classes = useStyles({});
	const data = useForm<any>({ mode: 'onBlur' });
	const { handleSubmit } = data;
	const useDecimalFormat = true;

    interface IModel {
        studentEnrollmentId?: string;
        studentId?: string;
        studentName?: string;
    }

    const [model, setModel] = React.useState<IEmergencyFundsDetail>({
    	emergencyFundId: '00000000-0000-0000-0000-000000000000',
    	studentName: 'student search',
    	studentEnrollmentId: '',
    	programVersionId: '',
    	programVersion: '',
    	emergencyFundTypeId: '',
    	emergencyFundType: '',
    	studentId: '',
    	disbursementDate: null,
    	awardYear: '',
    	academicYearId: '',
    	academicYearDescription: '',
    	amount: null,
    	modUser: '',
    	modDate: null,
       	}
    );

    const getDecimalValue = (value: any) => {
    	let valstring = (value + '').replace('$ ', '').replace(/,/g, '');
    	let valstr = valstring.split('.', 2);
    	let ret = 0;
    	if (valstr.length > 1) {
    		ret = Number(valstr[1]);
    	}
    	if (!useDecimalFormat) {
    		if (ret > 0) {
    			return 0;
    		} else {
    			return 1;
    		}
    	} else {
    		return 1;
    	}
    };


    const updateFunds = () => {
    	data.triggerValidation().then((validation: any) => {
    		if (validation) {
    			model.modDate = new Date();
    			if (model.programVersionId !== null && model.amount !== null) {
    				EmergencyFundsApi.CreateEmergencyFunds(model).then(
    					(response: any) => {
    						if (response) {
    							props.setSnackBar(() => {
    								return {
    									variant: 'success',
    									showSnackBar: true,
    									messageInfo: 'Saved successfully.',
    								};
    							});
    							resetModel();
    							handleClose();
    						}
    					},
    					() => { }
    				);
    			}
    		}

    	});
    };

    const resetModel = React.useCallback(() => {
    	let updatedModel = model;
    	(updatedModel as any)['emergencyFundId'] = null;
    	(updatedModel as any)['studentName'] = null;
    	(updatedModel as any)['studentEnrollmentId'] = null;
    	(updatedModel as any)['programVersionId'] = null;
    	(updatedModel as any)['programVersion'] = null;
    	(updatedModel as any)['emergencyFundTypeId'] = null;
    	(updatedModel as any)['emergencyFundType'] = null;
    	(updatedModel as any)['studentId'] = null;
    	(updatedModel as any)['disbursementDate'] = null;
    	(updatedModel as any)['awardYear'] = null;
    	(updatedModel as any)['academicYearId'] = null;
    	(updatedModel as any)['academicYearDescription'] = null;
    	(updatedModel as any)['amount'] = null;
    	setModel({ ...updatedModel });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
    	if (props.editedFundId !== null) {
    		EmergencyFundsApi.GetEmergencyFundsById(props.editedFundId).then(
    			(response: any) => {
    				if (response && response.data) {
    					setModel({ ...response.data });
    				}
    			},
    			() => { }
    		);
    	}
    	else {
    		resetModel();
    	}
    }, [props.editedFundId, resetModel]);

    const handleFieldOnChange = (
    	fieldId: string,
    	value: any,
    	mapFunction?: Function
    ) => {
    	let updatedModel = model;
    	let newValue = mapFunction ? mapFunction(value) : value;
    	(updatedModel as any)[fieldId] = newValue;
    	setModel({ ...updatedModel });
    };

    const setStudent = (
    	values: IModel
    ) => {
    	handleFieldOnChange('studentId', values.studentId);
    	handleFieldOnChange('studentEnrollmentId', values.studentEnrollmentId);
    	handleFieldOnChange('studentName', values.studentName);
    };

    
    const beforeClose = () => {
    	resetModel();
    	handleClose();
    };

    return (
    	<React.Fragment>
    		<FormContext {...data}>
    			<form onSubmit={handleSubmit(updateFunds)}>
    				<Dialog
    					PaperProps={{ square: true }}
    					fullWidth={true}
    					maxWidth="md"
    					open={open ?? false}
    					onClose={beforeClose}
    					aria-labelledby="notes-add-dialog-title"
    				>
    					<DialogTitle
    						className={classes.cardTitle}
    						disableTypography
    						id="notes-add-dialog-title"
    					>
    						<Typography className={classes.cardTitle}>
    							<div className={classes.headerText}>Add Emergency Funds</div>
    							<IconButton
    								aria-label="close"
    								onClick={beforeClose}
    								className={classes.dialogCloseButton}
    							>
    								<CloseIcon fontSize="small" />
    							</IconButton>
    						</Typography>
    					</DialogTitle>
    					<DialogContent
    						className={classes.mainContent}
    					>
    						{open &&
                                    <Grid container direction="row" spacing={1}>

                                    	<Grid item md={12} sm={12} xs={12}>
                                    		<div className={classes.searchBarContainer}>
                                    			{
                                    				props.editedFundId === null ?
                                    					<SearchBarFilter onSelect={(data?: any, studentName?: string) => data && data.stuEnrollId && studentName && setStudent({ studentEnrollmentId: data.stuEnrollId, studentName, studentId: data.studentId })} placeholder={model.studentName} ></SearchBarFilter>
                                    					: <div>{model.studentName}</div>
                                    			}

                                    		</div>

                                    	</Grid>
                                    	<Grid item md={6} sm={6} xs={12}>
                                    		<StudentProgramVersionAutoComplete
                                    			id="programVersionId"
                                    			name="programVersionId"
                                    			label="Program Version *"
                                    			studentId={model.studentId}
                                    			valueFilter={
                                    				model.programVersionId
                                    					? {
                                    						key: 'value',
                                    						values: [model.programVersionId]
                                    					}
                                    					: undefined
                                    			}
                                    			error={!!data.errors.programVersionId}
                                    			inputRef={data.register({ required: true })}
                                    			helperText={
                                    				data.errors.programVersionId ? 'Program version is required.' : undefined
                                    			}
                                    			filterHandle={(v: any) => {
                                    				handleFieldOnChange('programVersionId', v ? v.value : undefined);
                                    			}}
                                    		/>
                                    	</Grid>
                                    	<Grid item md={6} sm={6} xs={12}>
                                    		<EmergencyFundsTypesAutoComplete
                                    			id="emergencyFundTypeId"
                                    			name="emergencyFundTypeId"
                                    			label="Funds Type *"
                                    			valueFilter={
                                    				model.emergencyFundTypeId
                                    					? {
                                    						key: 'value',
                                    						values: [model.emergencyFundTypeId]
                                    					}
                                    					: undefined
                                    			}
                                    			error={!!data.errors.emergencyFundTypeId}
                                    			inputRef={data.register({ required: true })}
                                    			helperText={
                                    				data.errors.emergencyFundTypeId ? 'Funds type is required.' : undefined
                                    			}
                                    			filterHandle={(v: any) => {
                                    				handleFieldOnChange('emergencyFundTypeId', v ? v.value : undefined);
                                    			}}
                                    		/>
                                    	</Grid>
                                    	<Grid item md={6} sm={6} xs={12}>
                                    		<AwardYearAutoComplete
                                    			name="academicYearId"
                                    			id="academicYearId"
                                    			label="Award Year"
                                    			valueFilter={
                                    				model.academicYearId
                                    					? {
                                    						key: 'value',
                                    						values: [model.academicYearId]
                                    					}
                                    					: undefined
                                    			}
                                    			filterHandle={(v: any) => {
                                    				handleFieldOnChange('academicYearId', v ? v.value : null);
                                    			}}
                                    			error={!!data.errors.academicYearId}
                                    			inputRef={data.register({ required: true })}
                                    			helperText={
                                    				data.errors.academicYearId
                                    					? 'Award Year is required.'
                                    					: undefined
                                    			}
                                    		/>
                                    	</Grid>
                                    	<Grid item md={6} sm={6} xs={12}>
                                    		<InputField
                                    			type={InputType.CURRENCY}
                                    			label="Amount *"
                                    			name="amount"
                                    			key="amount"
                                    			decimal
                                    			thousandSeparator
                                    			defaultValue={model.amount}
                                    			onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                                    				handleFieldOnChange('amount', v.target.value);
                                    			}}
                                    			error={!!data.errors.amount}
                                    			inputRef={data.register({
                                    				required: 'Amount is required.',
                                    				validate: {
                                    					numberOnly: (value) =>
                                    						getDecimalValue(value) !== 0 ||
                                                            'Amount cannot have cents.',
                                    					valueGreaterThanZero: (value) =>
                                    						ConvertCurrencyValueToInput(value) > 0 ||
                                                            'Amount must be greater than 0',
                                    				},
                                    			})}
                                    			helperText={
                                    				data.errors.amount
                                    					? (data.errors.amount as FieldError).message
                                    					: undefined
                                    			}
                                    		/>
                                    	</Grid>
                                    	<Grid item md={6} sm={6} xs={12}>
                                    		<MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    			<KeyboardDatePicker
                                    				disableToolbar
                                    				variant="inline"
                                    				format="MM/dd/yyyy"
                                    				id="disbursementDate"
                                    				name="disbursementDate"
                                    				label="Disbursement Date"
                                    				className={classes.width100}
                                    				value={model.disbursementDate ? model.disbursementDate : null}
                                    				onChange={(e: any, value: any) =>
                                    					handleFieldOnChange('disbursementDate', value ? value : undefined)
                                    				}
                                    				KeyboardButtonProps={{
                                    					'aria-label': 'report date',
                                    				}}
                                    			/>
                                    		</MuiPickersUtilsProvider>
                                    	</Grid>
                                    	<Grid item md={12} sm={12} xs={12}>
                                    		<Button
                                    			size='small'
                                    			color='primary'
                                    			variant='contained'
                                    			type='button'
                                    			onClick={updateFunds}
                                    		>Save</Button>
                                    		<Button
                                    			className={classes.cancelBtn}
                                    			onClick={beforeClose}
                                    			size='small'
                                    			color="secondary"
                                    			variant="contained"
                                    			type="button"
                                    		>
                                                Cancel
                                    		</Button>

                                    	</Grid>

                                    </Grid>
    						}
    					</DialogContent>
    				</Dialog>
    			</form>
    		</FormContext>
    	</React.Fragment>
    );
};

export default AddEmergencyFunds;
