export class Address {
  constructor() {
    this.addressId = undefined;
    this.addressTypeId = undefined;
    this.address1 = "";
    this.address2 = "";
    this.countryId = undefined;
    this.countyId = undefined;
    this.city = "";
    this.stateId = undefined;
    this.zip = "";
  }
  addressId: string | undefined;
  addressTypeId: string | undefined;
  address1: string | undefined;
  address2: string | undefined;
  countryId: string | undefined;
  countyId: string | undefined;
  city: string | undefined;
  stateId: string | undefined;
  zip: string | undefined;
}
export class Country {
  constructor() {
    this.countryId = undefined;
  }
  countryId: string | undefined;
}
