import { colors } from '@material-ui/core';
const accentSite = "#F3C057";
const fontAndIcons = "#37474F";

export default {
  root: {
    backgroundColor: accentSite,
        color: fontAndIcons
  },
  deletable: {
    '&:focus': {
      backgroundColor: colors.blueGrey[100]
    }
  },
deleteIcon: {
      color: fontAndIcons
  }
};
