import { AdvanceSearchModel } from "../interfaces/student/search/AdvanceSearchModel";
export const apiVirtualizedTableConfig = {
    users: {
        acId: "users",
        cacheTime: 5,
        route: "/SystemCatalog/User/SearchUsers",
        requestType: "GET",
        body: null,
        params: null,
        useCache: false,
        storeInCache: false,
        isCampusSpecific: false,
        isUserSpecific: true,
        loadOnRender: true,
        defaultSelectedFirstItem: true,
        optionsMappedFx: (data: Array<any>) => {
            return data;
        },
    },
    usersPaged: {
        acId: "usersPage",
        cacheTime: 5,
        route: "/SystemCatalog/User/SearchUsersPaged",
        requestType: "GET",
        body: null,
        params: null,
        useCache: false,
        storeInCache: false,
        isCampusSpecific: false,
        isUserSpecific: true,
        loadOnRender: true,
        defaultSelectedFirstItem: true,
        optionsMappedFx: (data: Array<any>) => {
            return data;
        },
        lazyLoad: true,
        rowsInBatch: 15,
    },
    AdvanceSearchPaged: {
        acId: "advanceSearch",
        cacheTime: 5,
        route: "/AcademicRecords/Student/AdvanceStudentProfileSearch",
        requestType: "Post",
        body: null,
        params: null,
        useCache: false,
        storeInCache: false,
        isCampusSpecific: false,
        isUserSpecific: true,
        loadOnRender: true,
        defaultSelectedFirstItem: true,
        optionsMappedFx: (data: Array<any>) => {
            return data;
        },
        lazyLoad: true,
        rowsInBatch: 15,
    },
    leadSearchPaged: {
        acId: "leadSearchPaged",
        cacheTime: 5,
        route: "/Leads/SearchPaged",
        requestType: "GET",
        body: null,
        params: null,
        useCache: false,
        storeInCache: false,
        isCampusSpecific: false,
        isUserSpecific: true,
        loadOnRender: true,
        defaultSelectedFirstItem: true,
        optionsMappedFx: (data: Array<any>) => {
            return data;
        },
        lazyLoad: true,
        rowsInBatch: 15,
    },
    adhocPaged: {
        acId: "adhocPaged",
        cacheTime: 5,
        route: "/Reports/AdHoc/GetAdhocReports",
        requestType: "GET",
        body: null,
        params: null,
        useCache: false,
        storeInCache: false,
        isCampusSpecific: false,
        isUserSpecific: true,
        loadOnRender: true,
        defaultSelectedFirstItem: true,
        optionsMappedFx: (data: Array<any>) => {
            return data;
        },
        lazyLoad: true,
        rowsInBatch: 15,
    }
};
