import * as React from "react";
import { useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";

export const GradeBookComponentsApiAutoComplete = {
  config: {
    acId: "creditgradebookcomponentsbyclass",
    cacheTime: 5,
    route: "/AcademicRecords/Class/GetClassGradeBookComponents",
    requestType: "GET",
    body: null,
    hasUrlParams: true,
    useCache: false,
    storeInCache: false,
    isCampusSpecific: false,
    isUserSpecific: false,
    loadOnRender: true,
    defaultSelectedFirstItem: true,
    optionsMappedFx: (data: Array<any>) => {
      return [{ text: 'Final Grade' , value: "FinalGrade" }, ...data];
    },
  },
};

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& label.Mui-focused": {
        color: theme.palette.primary.white,
      },
    },
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {
      width: "100%",
    },
  })
);

export interface ClassProps extends FilterParams {
  classId:string;
}

const GradeBookComponentsAutoComplete = (props: ClassProps) => {
  const classes = useStyles({});

  const inputRef: any = useRef(null);

  const filterHandle = props.filterHandle;

  const classTypesOnChange = (e: any) => {
    if (filterHandle) filterHandle(e);
  };

  return (
    <div className={clsx(classes.divWidth)}>
      <ApiAutoComplete
        config={GradeBookComponentsApiAutoComplete.config}
        reqParams={{ classId: props.classId}}
        classes={{
          option: "text-black",
        }}
        label={props.label ? props.label : "Class"}
        onChange={(e: any) => {
          classTypesOnChange(e);
        }}
        multiple={false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        inputRef={props.inputRef ? props.inputRef : inputRef}
        error={props.error}
        helperText={props.helperText}
        inputName={props.name}
      ></ApiAutoComplete>
    </div>
  );
};
export default GradeBookComponentsAutoComplete;
