import React from "react";
import { StudentProfile } from "../../../interfaces/student/profile/StudentProfile";
import { useSelector } from "react-redux";
import ClockExam from "./ClockExam";
import CreditExam from "./CreditExam";

type IGradesProps = {
  setConfirmationModal: any;
};
const Exams = (props: IGradesProps) => {
  const selectedStudent: StudentProfile = useSelector(
    (state: any) => state.student
  ) as StudentProfile;
  return selectedStudent?.selectedEnrollment?.isCreditHour ? (
    <CreditExam setConfirmationModal={props.setConfirmationModal} stuEnrollId = {selectedStudent?.selectedEnrollment?.stuEnrollId} />
  ) : (
    <ClockExam setConfirmationModal={props.setConfirmationModal} />
  );
};
export default Exams;
