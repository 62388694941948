import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  createStyles,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  TextField,
  withStyles,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { FormContext, useForm } from "react-hook-form";
import * as CreditProgramsApi from "../../../../../api/setup/academics/creditProgramsApi";
import AcademicCalendarAutoComplete from "../../../../../components/AutoComplete/AcademicCalendarAutoComplete";
import EdSpecialProgramIndicatorAutoComplete from "../../../../../components/AutoComplete/EdSpecialProgramIndicatorAutoComplete";
import IPEDSCredentialLevelAutoComplete from "../../../../../components/AutoComplete/IPEDSCredentialLevelAutoComplete";
import MultiCampusAutoComplete from "../../../../../components/AutoComplete/MultiCampusAutoComplete";
import ProgramAccrualMethodAutoComplete from "../../../../../components/AutoComplete/ProgramAccrualMethodAutoComplete";
import ProgramAccrualMethodMeasurementAutoCompleteAutoComplete from "../../../../../components/AutoComplete/ProgramAccrualMethodMeasurementAutoComplete";
import ProgramCredentialLevelAutoComplete from "../../../../../components/AutoComplete/ProgramCredentialLevelAutoComplete";
import StatusAutoComplete from "../../../../../components/AutoComplete/StatusAutoComplete";
import AccessManager from "../../../../../components/security/AccessManager";
import ProgressSaveButton from "../../../../../components/_Layout/Buttons/ProgressSaveButton";
import { security } from "../../../../../constants/Security/securityConstants";
import CustomCardTitle from "../../../../../interfaces/common/card/CustomCardTitle";
import {
  ICreditProgramDetails,
  CreditProgramDetails,
} from "../../../../../interfaces/setup/academics/ProgramProgramVersion/ICreditProgramDetails";
import { EmptyGuid } from "../../../../../utils/constants";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%",
    },
    cardWrapper: {
      height: "100%",
    },
    fullWidthField: {
      width: "100%",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "80%",
    },
    saveButton: {
      width: 90,
    },
    switch: {
      marginLeft: "0px",
      paddingTop: theme.spacing(2),
    },
    preLoader: {
      textAlign: "center",
    },
  })
);

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& .MuiFormLabel-root": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "80%",
      },
    },
  })
)(TextField);

type ProgramDetailsProps = {
  programId: string;
  setSnackBar: any;
  newProgramName: string;
  clearNewProgramProgramVersion: any;
  setRefreshData: any;
  refreshDetailData: any;
};
const ProgramDetailsCardCreditHr = (props: ProgramDetailsProps) => {
  const classes = useStyles({});
  const [model, setModel] = useState<ICreditProgramDetails>(
    new CreditProgramDetails()
  );
  const [loading, setLoading] = useState<boolean>(false);
  interface ListItem {
    value: number;
    text: string;
  }
  const optionList = useSelector(
    (state: any) =>
      state.ac_cache?.data?.programAccrualMethodMeasurement?.options ?? []
  ) as ListItem[];

  const data = useForm<any>({ mode: "onBlur" });
  const { handleSubmit } = data;
  const onSubmit = (d: any) => {
    if (model) {
      let modelToSend = model;
      data.triggerValidation().then((validation: any) => {
        if (validation) {
          setLoading(true);

          if (modelToSend && modelToSend.campusIds) {
            var campusIds: string[] = modelToSend?.campusIds.filter(function (
              item: string
            ) {
              return item !== "All";
            });
            modelToSend.campusIds = campusIds;
          }

          CreditProgramsApi.upsertProgram(modelToSend).then(
            (response: any) => {
              if (response.resultStatus === 0) {
                props.setRefreshData(true);
                props.refreshDetailData(response.result);
                props.setSnackBar((props: any) => {
                  return {
                    variant: "success",
                    showSnackBar: true,
                    messageInfo: response.resultStatusMessage,
                  };
                });
              } else {
                props.setSnackBar((props: any) => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: response.data,
                  };
                });
              }
              setLoading(false);
            },
            (exception: any) => {
              props.setSnackBar((snackBarProps: any) => {
                return {
                  variant: "error",
                  showSnackBar: true,
                  messageInfo: exception,
                };
              });
              setLoading(false);
            }
          );
        }
      });
    }
  };

  const handleCancel = () => {
    if (
      props.programId !== undefined &&
      props.programId !== EmptyGuid &&
      props.programId !== ""
    ) {
      getCreditProgramDetails();
    } else {
      setModel(new CreditProgramDetails());
      props.clearNewProgramProgramVersion();
    }
  };
  const getCreditProgramDetails = () => {
    CreditProgramsApi.GetProgramDetails(props.programId).then(
      (response: any) => {
        if (response.resultStatus === 0) {
          setModel(response.result);
          props.setSnackBar((props: any) => {
            return {
              variant: "success",
              showSnackBar: false,
              messageInfo: response.resultStatusMessage,
            };
          });
        } else {
          props.setSnackBar((props: any) => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo: response.resultStatusMessage,
            };
          });
        }
      },
      (exception: any) => {
        props.setSnackBar((snackBarProps: any) => {
          return {
            variant: "error",
            showSnackBar: true,
            messageInfo: exception,
          };
        });
      }
    );
  };

  const handleFieldChange = (
    fieldId: string,
    value: any,
    text?: any,
    mapFunction?: Function
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;

    if (fieldId === "accrualMethodId") {
      if (text == "None-exclude from accruals") {
        (updatedModel as any)["measuredInId"] = optionList.find(
          (x) => x.text === "None"
        )?.value;
      } else {
        (updatedModel as any)["measuredInId"] = optionList.find(
          (x) => x.text === "Actual hours"
        )?.value;
      }
    }
    setModel({ ...updatedModel });
  };

  useEffect(() => {
    if (
      props.programId !== undefined &&
      props.programId !== EmptyGuid &&
      props.programId !== ""
    ) {
      getCreditProgramDetails();
    } else {
      setModel(new CreditProgramDetails());
      if (props.newProgramName !== undefined && props.newProgramName !== "") {
        handleFieldChange("name", props.newProgramName);
        handleFieldChange("id", EmptyGuid);
      }
    }
  }, [props.programId, props.newProgramName]);

  const setCampuses = (campusIds: any) => {
    let campIds: string[] = [];
    if (campusIds !== null) {
      if (Array.isArray(campusIds)) {
        campIds = campusIds.map((gr: any) => gr.value);
      } else {
        campIds.push(campusIds.value);
      }
    }
    return campIds;
  };

  return (
    <FormContext {...data}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card square={true} className={classes.cardWrapper}>
          <Fragment>
            <CustomCardTitle title={"Program Details - Credit Hours"} />
            <CardContent>
              {(loading && (
                <div className={classes.preLoader}>
                  <CircularProgress />
                </div>
              )) || (
                <React.Fragment>
                  <Grid container direction="row" spacing={4}>
                    <Grid item xs={12} sm={12} md={4}>
                      <ThemeTextField
                        id="name"
                        label="Program *"
                        name="name"
                        className={classes.fullWidthField}
                        value={model.name}
                        onChange={(e: any) => {
                          handleFieldChange("name", e.target.value);
                        }}
                        error={!!data.errors.name}
                        inputRef={data.register({ required: true })}
                        helperText={
                          data.errors.name ? "Program is required." : undefined
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <StatusAutoComplete
                        id="statusId"
                        name="statusId"
                        label="Status *"
                        valueFilter={
                          model.statusId
                            ? {
                                key: "value",
                                values: [model.statusId],
                              }
                            : undefined
                        }
                        filterHandle={(v: any) => {
                          handleFieldChange("statusId", v ? v.value : null);
                        }}
                        error={!!data.errors.statusId}
                        inputRef={data.register({ required: true })}
                        helperText={
                          data.errors.statusId
                            ? "Status is required."
                            : undefined
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <ThemeTextField
                        id="cipCode"
                        label="CIP Code"
                        name="cipCode"
                        className={classes.fullWidthField}
                        value={model.cipCode}
                        error={!!data.errors.cipCode}
                        inputRef={data.register({
                          maxLength: {
                            message: "CIP Code must not exceed 20 characters.",
                            value: 20,
                          },
                        })}
                        onChange={(e: any) => {
                          handleFieldChange("cipCode", e.target.value);
                        }}
                        helperText={
                          data.errors.cipCode
                            ? data.errors.cipCode.message
                            : undefined
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={4}>
                    <Grid item xs={12} sm={12} md={4}>
                      <AcademicCalendarAutoComplete
                        id="academicCalendarId"
                        name="academicCalendarId"
                        label="Academic Calendar *"
                        valueFilter={
                          model.academicCalendarId
                            ? {
                                key: "value",
                                values: [model.academicCalendarId],
                              }
                            : undefined
                        }
                        filterHandle={(v: any) => {
                          handleFieldChange(
                            "academicCalendarId",
                            v ? v.value : null
                          );
                        }}
                        error={!!data.errors.academicCalendarId}
                        inputRef={data.register({ required: true })}
                        helperText={
                          data.errors.academicCalendarId
                            ? "Academic Calendar is required."
                            : undefined
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <ProgramCredentialLevelAutoComplete
                        id="credentialLevelId"
                        name="credentialLevelId"
                        label="Credential Level"
                        valueFilter={
                          model.credentialLevelId
                            ? {
                                key: "value",
                                values: [model.credentialLevelId],
                              }
                            : undefined
                        }
                        filterHandle={(v: any) => {
                          handleFieldChange(
                            "credentialLevelId",
                            v ? v.value : null
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <IPEDSCredentialLevelAutoComplete
                        id="ipedsCredentialLevelId"
                        name="ipedsCredentialLevelId"
                        label="IPEDS Credential Level"
                        valueFilter={
                          model.credentialLevelId
                            ? {
                                key: "value",
                                values: [model.ipedsCredentialLevelId],
                              }
                            : undefined
                        }
                        filterHandle={(v: any) => {
                          handleFieldChange(
                            "ipedsCredentialLevelId",
                            v ? v.value : null
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={4}>
                    <Grid item xs={12} sm={12} md={4}>
                      <EdSpecialProgramIndicatorAutoComplete
                        id="edSpecialProgramIndicatorId"
                        name="edSpecialProgramIndicatorId"
                        label="ED Special Program Indicator"
                        valueFilter={
                          model.edSpecialProgramIndicatorId
                            ? {
                                key: "value",
                                values: [model.edSpecialProgramIndicatorId],
                              }
                            : undefined
                        }
                        filterHandle={(v: any) => {
                          handleFieldChange(
                            "edSpecialProgramIndicatorId",
                            v ? v.value : null
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <ProgramAccrualMethodAutoComplete
                        id="accrualMethodId"
                        name="accrualMethodId"
                        label="Accrual Method"
                        valueFilter={
                          model.accrualMethodId
                            ? {
                                key: "value",
                                values: [model.accrualMethodId],
                              }
                            : undefined
                        }
                        filterHandle={(v: any) => {
                          handleFieldChange(
                            "accrualMethodId",
                            v ? v.value : null,
                            v ? v.text : null
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <ProgramAccrualMethodMeasurementAutoCompleteAutoComplete
                        id="measuredInId"
                        name="measuredInId"
                        label="Measured In *"
                        valueFilter={
                          model.measuredInId
                            ? {
                                key: "value",
                                values: [model.measuredInId],
                              }
                            : undefined
                        }
                        filterHandle={(v: any) => {
                          handleFieldChange("measuredInId", v ? v.value : null);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={4}>
                    <Grid item xs={12} sm={12} md={12}>
                      <MultiCampusAutoComplete
                        label="Campus(es) *"
                        multiple={true}
                        id="campusIds"
                        name="campusIds"
                        includeAllValue={true}
                        showIncludeAllValue={true}
                        defaultOptionText={"All"}
                        notSelectCurrentCampus={true}
                        filterHandle={(v: any) => {
                          handleFieldChange("campusIds", v, null, setCampuses);
                        }}
                        error={!!data.errors.campusIds}
                        inputRef={data.register({
                          validate: {
                            atLeastOneRequired: (value) =>
                              (model.campusIds && model.campusIds.length > 0) ||
                              "Campus(es) are required.",
                          },
                        })}
                        helperText={
                          data.errors.campusIds
                            ? "At least one campus is required."
                            : undefined
                        }
                        valueFilter={
                          model?.campusIds && model?.campusIds[0] != null
                            ? {
                                key: "value",
                                values: model?.campusIds,
                              }
                            : undefined
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={4}>
                    <Grid item xs={12} sm={12} md={3}>
                      <FormControlLabel
                        id="excludeFrom1098"
                        className={classes.switch}
                        labelPlacement="start"
                        label="Gainful Employment"
                        control={
                          <Switch
                            checked={model.gainfulEmploymentEnabled}
                            name="gainfulEmploymentEnabled"
                            onChange={(e: any) => {
                              handleFieldChange(
                                "gainfulEmploymentEnabled",
                                e ? e.target.checked : false
                              );
                            }}
                            color="primary"
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <FormControlLabel
                        id="excludeFrom1098"
                        className={classes.switch}
                        labelPlacement="start"
                        label="Include in 1098-T Extract"
                        control={
                          <Switch
                            checked={model.is1098tExtract}
                            name="is1098tExtract"
                            onChange={(e: any) => {
                              handleFieldChange(
                                "is1098tExtract",
                                e ? e.target.checked : false
                              );
                            }}
                            color="primary"
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={4}>
                    <Grid
                      item
                      container
                      xs={1}
                      alignContent="flex-start"
                      alignItems="flex-start"
                      justify="flex-start"
                    >
                      <AccessManager
                        allowedPermissions={[
                          security.permissions.maintenance.updatePrograms,
                          security.permissions.maintenance.deletePrograms,
                        ]}
                        renderNoAccess={() => (
                          <ProgressSaveButton
                            text="Save"
                            onClick={(e: any) => {
                              // onSubmit(e);
                            }}
                            loading={false}
                            disabled={true}
                            buttonClassName={classes.saveButton}
                          ></ProgressSaveButton>
                        )}
                      >
                        <ProgressSaveButton
                          text="Save"
                          onClick={(e: any) => {
                            // onSubmit(e);
                          }}
                          loading={false}
                          disabled={false}
                          buttonClassName={classes.saveButton}
                        ></ProgressSaveButton>
                      </AccessManager>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={1}
                      alignContent="flex-start"
                      alignItems="flex-start"
                      justify="flex-start"
                    >
                      <Button
                        onClick={handleCancel}
                        color="secondary"
                        variant="contained"
                        type="button"
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </CardContent>
          </Fragment>
        </Card>
      </form>
    </FormContext>
  );
};

export default ProgramDetailsCardCreditHr;
