import React from "react";
import {
  Typography,
  CardContent,
  Card,
  IconButton,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import clsx from "clsx";
import AccessManager from "../../components/security/AccessManager";
import { security } from "../../constants/Security/securityConstants";
import RefreshIcon from "@material-ui/icons/Refresh";
import PrintIcon from "@material-ui/icons/Print";
import makeStyles from "./WidgetStyles";
import * as campusAttendanceApi from "../../api/attendance/campusAttendanceApi";
import { ICampusAttendanceResult } from "../../interfaces/attendance/ICampusAttendance";
import CampusAttendanceReportPopup from "./CampusAttendanceReportPopup";

const useStyles = makeStyles;

function CampusAttendanceWidget() {
  const classes = useStyles();
  const [settingsModelState, setSettingsModelState] = React.useState(false);
  const [forDay, setForDay] = React.useState<Date>(new Date());
  const [campusAttendance, setCampusAttendance] = React.useState<
    Array<ICampusAttendanceResult>
  >([]);
  const campusId = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  React.useEffect(() => {
    if (campusId) {
      loadCampusAttendanceData(campusId);
    }
  }, [campusId]);

  const handelReloadPage = () => {
    loadCampusAttendanceData(campusId);
  };
  const openSettingsModel = () => {
    setSettingsModelState(true);
  };

  const loadCampusAttendanceData = (campusId: any) => {
    campusAttendanceApi.GetCampusAttendanceData(campusId, null).then(
      (response: any) => {
        if (response && response?.data !== null) {
          setCampusAttendance(response?.data.result);
          if (response?.data.result) {
            setForDay(response?.data.result[0]?.forDay);
          }
        }
      },
      (_exception: any) => {}
    );
  };

  return (
    <AccessManager
      allowedPermissions={[
        security.permissions.dashboard.viewCampusAttendanceWidget,
      ]}
      renderNoAccess={() => null}
    >
      <Card className={classes.root}>
        <Typography className={clsx(classes.cardTitle)}>
          <Grid container direction="row">
            <Grid item xs={8}>
              <div className={classes.headerText}>
                Campus Attendance Summary{" "}
                {new Date(forDay).toLocaleDateString()}
              </div>
            </Grid>
            <Grid item xs={4} className={classes.widgetMenu}>
              <Typography className={classes.headerTextRight}></Typography>
              <IconButton
                className={classes.iconBtn}
                aria-label="settings"
                onClick={openSettingsModel}
              >
                <PrintIcon />
              </IconButton>
              <IconButton
                className={classes.iconBtn}
                aria-label="settings"
                onClick={handelReloadPage}
              >
                <RefreshIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Typography>
        <CardContent className={classes.cardContent}>
          <div className={classes.innerRoot}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left" className={classes.headCell}>
                      Campus Name
                    </TableCell>
                    <TableCell align="left" className={classes.headCell}>
                      In School Students
                    </TableCell>
                    <TableCell align="left" className={classes.headCell}>
                      Out of School Students
                    </TableCell>
                    <TableCell align="left" className={classes.headCell}>
                      Overall Attendance %
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {campusAttendance !== null && campusAttendance.length > 0 ? (
                    campusAttendance
                      .sort((a, b) =>
                        a.percentageInSchool > b.percentageInSchool ? -1 : 1
                      )
                      .map((row: ICampusAttendanceResult, index: any) => {
                        return (
                          <TableRow key={index}>
                            <TableCell align="left">
                              {row.campDescrip}
                            </TableCell>
                            <TableCell align="left" style={{ width: 100 }}>
                              {row.studentsInSchool}
                            </TableCell>
                            <TableCell align="left" style={{ width: 100 }}>
                              {row.studentsNotInSchool}
                            </TableCell>
                            <TableCell align="center" style={{ width: 100 }}>
                              {row.percentageInSchool}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={4}>
                        No Data
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </CardContent>
      </Card>
      <CampusAttendanceReportPopup
        open={settingsModelState}
        setOpen={setSettingsModelState}
        reload={handelReloadPage}
      />
    </AccessManager>
  );
}
export default CampusAttendanceWidget;
