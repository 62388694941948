import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CampusForCurrentUserAutoComplete from "../../../components/AutoComplete/CampusForCurrentUserAutoComplete";
import { FormControlLabel, Switch, Grid, Typography, FormControl, FormLabel, RadioGroup, Radio } from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import * as gainfulEmploymentApi from "../../../api/reports/gainfulEmploymentReportApi";
import { ReportOutput } from "../../../enums/ReportOutput";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import ProgramVersionAutoComplete from "../../../components/AutoComplete/ProgramVersionAutoComplete";
import StudentGroupsAutoComplete from "../../../components/AutoComplete/StudentGroupsAutoComplete";
import StudentNameBadgeIDFilter from '../../../../src/pages/attendance/StudentNameBadgeIDFilter'
import { EnrollmentAutoComplete } from "../../../components/AutoComplete/EnrollmentAutoComplete";
import { IEnrollmentSearchResult } from "../../../interfaces/student/enrollment/EnrollmentSearchResult";
import { IAttendanceSummaryReportParam } from "../../../interfaces/reports/attendance/AttendanceSummaryReportParam";
import { useSelector } from "react-redux";
import MultiCampusAutoComplete from "../../../components/AutoComplete/MultiCampusAutoComplete";
import { IGEDataCheckParam } from "../../../interfaces/reports/IGEDataCheckReportParam";
import { cloneDeep } from "lodash";


const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(3),
            minHeight: "100%",
            height: "100%",
            maxWidth: "1050px",
            padding: theme.spacing(2),
        },
        "& .makeStyles-content": {
            backgroundColor: theme.palette.background.paper,
        },
        paperRoot: {
            padding: theme.spacing(3, 2),
            width: "100%",
            minHeight: "100%",
            height: "100%",
            overflowX: "hidden",
            overflowY: "auto",
            flexDirection: "column",
            backgroundColor: theme.palette.background.paper,
        },
        searchActions: {
            marginTop: theme.spacing(3),
            textAlign: "left",
        },
        startDateField: {
            width: "100%",
        },
        cardTitle: {
            fontSize: 17,
            color: theme.palette.primary.headerText,
            textDecoration: "bold",
            backgroundColor: theme.palette.secondary.dark,
            padding: theme.spacing(1, 1),
            marginBottom: theme.spacing(1),
        },
        headerText: {
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(0),
        },
        cardContent: {
            padding: theme.spacing(1, 3),
        }, switch: {
            display: "flex",
            alignItems: "flex-end",
            padding: theme.spacing(0, 0.5),
            height: theme.spacing(8.6),
        },
        fieldSet: {
            marginTop: theme.spacing(2),
          },
    })
);

interface IGEReportType{
    reportType:'ProgramSubmittal' | 'EnrollmentSubmittal' | 'ProgramSubmittalDetail';
    reportTypeDescription:string;
}

interface IAwardYearDataSource {
    startDate: string;
    endDate: string;
    reportYear: number;
    reportYearDescription: string;
}

const GainfulEmployment = (props: any) => {
    const { report } = props;
    let reportOverview = { report: report } as IReportOverview;
    const classes = useStyles({});
    const [preview, setPreview] = React.useState<any>();
    const [previewLoader, setPreviewLoader] = React.useState<boolean>();
    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );

    const currentYear = (new Date()).getFullYear();
    const awardYearDataSource = React.useMemo(() => {
        let toReturn: IAwardYearDataSource[] = [];
        let startYear: number = 2015;
        let endYear: number = (new Date()).getFullYear() + 1;

        for (let i = endYear; i > startYear; i--) {
            toReturn.push({
                endDate: `06/30/${i}`,
                reportYear: i,
                reportYearDescription: `${i -1}-${i}`,
                startDate: `07/01/${i - 1}`,
            });
        }

        return toReturn;
    }, []);

    const reportTypeDataSource:IGEReportType[] = [{reportTypeDescription:'Program Submittal', reportType:'ProgramSubmittal'},{reportTypeDescription:'Enrollment Submittal', reportType:'EnrollmentSubmittal'}, {reportTypeDescription:'Program Student Details', reportType:'ProgramSubmittalDetail'}];


    const data = useForm<any>({ mode: "onBlur" });
    const initialModel: IGEDataCheckParam = {
        campusId: userSelectedCampus,
        startDate: awardYearDataSource.find(m => m.reportYear === currentYear)?.startDate,
        endDate: awardYearDataSource.find(m => m.reportYear === currentYear)?.endDate,
        prgVerIds: [],
        sortBy: 'StudentNumber',
        selectedReportYear: currentYear + 1,
        reportType:'ProgramSubmittal'
    };
    const [model, setModel] = React.useState<IGEDataCheckParam>(initialModel);

    const handleFieldOnChange = (
        fieldId: string,
        value: any,
        mapFunction?: Function
    ) => {
        let updatedModel = model;
        let newValue = mapFunction ? mapFunction(value) : value;
        (updatedModel as any)[fieldId] = newValue;
        if (fieldId == "campusId") {
            (updatedModel as any)["programId"] = [];
            (updatedModel as any)["leadGroupIds"] = [];
        }
        setModel({ ...updatedModel });
    };

    const { handleSubmit } = data;

    const onSubmit = async (isPreview: any) => {
        let params = (await getReportParameters()) as IGEDataCheckParam;
        const validation = await data.triggerValidation();
        if (validation) {
            if(params.reportType == 'ProgramSubmittal'){
                await gainfulEmploymentApi.generateGEProgramsReport(params);
            }else if(params.reportType == 'EnrollmentSubmittal'){
                await gainfulEmploymentApi.generateGEStudentsEnrolledReport(params);
            }else if(params.reportType == 'ProgramSubmittalDetail'){
                await gainfulEmploymentApi.generateGEProgramsDetailReport(params);
            }
            
            //await gainfulEmploymentApi.generateGECompletersReport(params);
            
        }
    };

    const awardYearAutoComplete = {
        options: awardYearDataSource,
        getOptionLabel: (option: IAwardYearDataSource) => option.reportYearDescription
    };

    const reportTypeAutoComplete = {
        options: reportTypeDataSource,
        getOptionLabel: (option: IGEReportType) => option.reportTypeDescription
    };

    const awardYearOnChange = (e: IAwardYearDataSource) => {
        if(!!e && !!e.reportYear && e.reportYear > 0){
            let updatedModel = { ...model };
            updatedModel.startDate = e.startDate;
            updatedModel.endDate = e.endDate;
            updatedModel.selectedReportYear = e.reportYear;
            setModel({ ...updatedModel });
        }
    };

    const reportTypeOnChange = (e: IGEReportType) => {
        let updatedModel = { ...model };
            updatedModel.reportType = e?.reportType ?? updatedModel.reportType;
            setModel({ ...updatedModel });
    };

    const filterHandler = (data: any) => {
        if (data) {
            setModel(data);
        } else {
            setModel(initialModel);
        }
    };

    const handleSortByChange = (value: 'StudentNumber' | 'LastName') => {
        let copy = cloneDeep(model);
        copy.sortBy = value;
        setModel(copy);
    }

    const setProgramVersion = (PrgVersions: any) => {
        console.log(PrgVersions)
        let newProgramVersion: string[] = [];
        if (PrgVersions !== null) {
            if (Array.isArray(PrgVersions)) {
                newProgramVersion = PrgVersions.map((gr: any) => gr.value);
            } else {
                newProgramVersion.push(PrgVersions.value);
            }
        }
        return newProgramVersion;
    };

    const getReportParameters = async () => {
        const success = await data.triggerValidation();
        if (model && success) {
            let startDate = model?.startDate ? model.startDate : undefined;
            let endDate = model?.endDate ? model.endDate : undefined;
            let params: IGEDataCheckParam = {
                campusId: model?.campusId ?? userSelectedCampus,
                startDate: startDate,
                endDate: endDate,
                prgVerIds: model?.prgVerIds ?? [],
                selectedReportYear: model.selectedReportYear,
                sortBy: model.sortBy,
                reportType:model.reportType
            };
            return params;
        }
        return {} as IGEDataCheckParam;
    };

    const getSelectedItem = (items: Array<IAwardYearDataSource>, value: number) => {
        const item = items.find((opt) => {
            if (opt.reportYear == value) return opt;
        });
        return item || ({} as IAwardYearDataSource);
    };

    let parameters = (
        <div className={classes.root}>
            <FormContext {...data}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container direction="row" spacing={1}>
                        <Grid item xs={12} sm={12} md={6}>
                            <MultiCampusAutoComplete label="Campus *"
                                multiple={false}
                                id="campusId"
                                name="campusId"
                                valueFilter={
                                    model.campusId
                                        ? {
                                            key: "value",
                                            values: [model.campusId],
                                        }
                                        : undefined
                                }
                                filterHandle={(v: any) => {
                                    handleFieldOnChange(
                                        "campusId",
                                        v ? v.value : null
                                    );
                                }}
                                error={!!data.errors.campusId}
                                inputRef={data.register({ required: true })}
                                helperText={
                                    data.errors.campusId
                                        ? "Campus is required."
                                        : undefined
                                }
                            />
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                            <Autocomplete
                                {...reportTypeAutoComplete}
                                autoComplete
                                includeInputInList
                                value={!!model.reportType ? reportTypeDataSource.find(m => m.reportType == (model.reportType)) : null}
                                onChange={(e: any, v: any) => {
                                    reportTypeOnChange(v);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={`Report Type *`}
                                        name="reportType"
                                        error={!!data.errors.reportType}
                                        inputRef={data.register({ required: true })}
                                        helperText={
                                            data.errors.reportType
                                                ? "Report Type is required."
                                                : undefined
                                        }
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>

                        
                        <Grid item md={6} sm={6} xs={12}>
                            <ProgramVersionAutoComplete
                                selectAll={true}
                                name="programVersion"
                                campusId={model.campusId}
                                onlyGEPrograms={true}
                                filterHandle={(value: any) =>
                                    handleFieldOnChange("prgVerIds", value, setProgramVersion)
                                }
                                valueFilter={
                                    model.prgVerIds
                                        ? {
                                            key: "value",
                                            values: model.prgVerIds,
                                        }
                                        : undefined
                                }
                                multiple={true}
                            />
                        </Grid>
                        
                        <Grid item md={6} sm={6} xs={12}>
                            <Typography>{`Report Date: ${awardYearDataSource.findIndex(m => m.reportYear === model.selectedReportYear) >= 0 ? `${awardYearDataSource.find(m => m.reportYear === model.selectedReportYear)?.startDate} thru ${awardYearDataSource.find(m => m.reportYear === model.selectedReportYear)?.endDate}` : ''}`}</Typography>
                            <Autocomplete
                                {...awardYearAutoComplete}
                                autoComplete
                                includeInputInList
                                value={!!model.selectedReportYear ? awardYearDataSource.find(m => m.reportYear == (model.selectedReportYear)) : null}
                                onChange={(e: any, v: any) => {
                                    awardYearOnChange(v);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={`Reporting Year *`}
                                        name="selectedReportYear"
                                        error={!!data.errors.selectedReportYear}
                                        inputRef={data.register({ required: true })}
                                        helperText={
                                            data.errors.exportType
                                                ? "Report Year is required."
                                                : undefined
                                        }
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={6} sm={6} xs={6}>
                            <FormControl component="fieldset" className={classes.fieldSet}>
                                <FormLabel component="legend">Sort By</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="sortBy"
                                    name="sortBy"
                                    value={model.sortBy}
                                    onChange={(e:any) => {
                                        handleSortByChange(e.target.value)
                                    }}
                                >
                                    <FormControlLabel
                                        labelPlacement="end"
                                        value="StudentNumber"
                                        control={<Radio color="default"  />}
                                        label="SSN"
                                    />
                                    <FormControlLabel
                                        labelPlacement="end"
                                        value="LastName"
                                        control={<Radio  color="default" />}
                                        label="Last Name"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>
            </FormContext>
        </div>
    );
    reportOverview.parameters = parameters;

    return (
        <ReportOverview
            reportOverview={reportOverview}
            filterHandler={filterHandler}
            getReportParameters={getReportParameters}
            exportHandler={onSubmit}
            preview={preview}
            previewLoader={previewLoader}
            hidePreview={true}
        />
    );
};
export default GainfulEmployment;
