import React, { forwardRef, useState } from "react";
import Edit from "@material-ui/core/SvgIcon";
import AddIcon from "@material-ui/icons/Add";
import { EmptyGuid } from "../../../../utils/constants";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import {
  Button,
  Popover,
  Switch,
  FormControlLabel,
  Typography,
  CircularProgress,
  Modal,
  TextField,
  Backdrop,
} from "@material-ui/core";
import "date-fns";
import MaterialTable, { MTableToolbar } from "material-table";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import MoreVert from "@material-ui/icons/MoreVert";
import { DisbursementDetailsItem } from "../../../../interfaces/student/studentAccounts/DisbursementDetailsItem";
import { green } from "@material-ui/core/colors";
import clsx from "clsx";
import * as disbursementDetailsApi from "../../../../api/student/studentAccounts/disbursementDetailsApi";
import { useSelector } from "react-redux";
import { Enrollment } from "../../../../interfaces/student/academics/Enrollment";
import { StudentAward } from "../../../../interfaces/student/studentAccounts/StudentAward";
import { useTheme } from "@material-ui/core/styles";
import DisbursementModal from "./DisbursementModal";
import AccessManager from "../../../../components/security/AccessManager";
import { security } from "../../../../constants/Security/securityConstants";
import { system } from "../../../../constants/system/systemConstants";
import { Guid } from "../../../../utils/guid";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      "& .MuiCardContent-root": {
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(2),
      },
    },
    drawer: {
      width: 280,
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    actionButton: {
      transition: "none",
      "&:hover": {
        backgroundColor: theme.palette.table.row.background,
      },
      "&:click": {
        backgroundColor: theme.palette.table.row.background,
      },
    },
    popoverButton: {
      width: "100%",
      textTransform: "none",
      justifyContent: "left",
    },
    wrapper: {
      position: "relative",
    },
    buttonSuccess: {
      backgroundColor: green[500],
      "&:hover": {
        backgroundColor: green[700],
      },
    },
    fabProgress: {
      color: theme.palette.primary,
      position: "absolute",
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: theme.palette.primary,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    typography: {
      padding: theme.spacing(1),
    },
    modalButtons: {
      height: "40px",
      marginLeft: theme.spacing(1),
    },
    flexRow: {
      display: "flex",
      flexDirection: "row",
      padding: 0,
      margin: 0,
    },
    options: {
      textAlign: "center",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      // color: '#fff',
    },
  })
);

const tableIcons = {
  ResetSearch: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <Clear {...props} ref={ref} />
  )),
  Search: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <Search {...props} ref={ref} />
  )),
  Popover: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <MoreVert {...props} ref={ref} />
  )),

  EditIcon: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <EditIcon {...props} ref={ref} />
  )),
};

type DisbursementDetailsGridProps = {
  studentAwardID?: string;
  disbursementDetailsItem: DisbursementDetailsItem[];
  refreshDisbursementDetailsItems: any;
  setSnackBar: any;
  setSelectedAward: any;
  selectedAward: StudentAward;
  refreshAwardGrid: any;
  isTitleIVAward: boolean | undefined;
};

type DeletableTransaction = {
  reason: string;
  transactionId: string | null;
};

const formatMoney = (number: number) => {
  var absValue = Math.abs(number);
  let numberString = absValue.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  return number < 0 ? "(" + numberString + ")" : numberString;
};

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

const DisbursementDetailsGrid = (props: DisbursementDetailsGridProps) => {
  const selectedEnrollment = useSelector(
    (state: any) => state.student.selectedEnrollment
  );
  const { disbursementDetailsItem, refreshDisbursementDetailsItems } = props;
  const classes = useStyles({});
  const theme = useTheme();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [transactionToDelete, setTransactionToDelete] =
    React.useState<DeletableTransaction>({ reason: "", transactionId: null });
  const [popoverState, setPopoverState] = React.useState<any>({
    openedPopoverId: null,
    anchorEl: null,
  });
  const isSupportUser = useSelector(
    (state: any) => state.session.user.isSupportUser
  );

  const appSettings = useSelector((state: any) => {
    return state.settings.settings;
  });

  const getAfaIntegratedFlag = () => {
    const afaIntegrationSetting = appSettings[
      system.settings.enableAFAIntegration
    ] as string;
    let afaIntegrationEnabled = false;

    const allowAFAOverrideSetting = appSettings[
      system.settings.allowAFAOverride
    ] as string;
    let allowAFAOverrideEnabled = false;

    if (afaIntegrationSetting) {
      afaIntegrationEnabled = afaIntegrationSetting.toLowerCase() === "yes";
      if (afaIntegrationEnabled)
        if (allowAFAOverrideSetting) {
          allowAFAOverrideEnabled =
            allowAFAOverrideSetting.toLowerCase() === "true";

          if (allowAFAOverrideEnabled) {
            afaIntegrationEnabled = false;
          }
        }
    }

    return afaIntegrationEnabled;
  };

  const isAfaIntegrationEnabled = getAfaIntegratedFlag();

  const [rowLoadingState, setRowLoadingState] = React.useState<any[]>([]);
  const [confirmationPopupState, setConfirmationPopupState] =
    React.useState<any>({
      id: "simple-popup",
      message: "",
    });

  const handleChange =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      //setShowVoid(event.target.checked);
    };

  const handleModalClose = () => {
    setModalOpen(false);
    setTransactionToDelete({ reason: "", transactionId: null });
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    setPopoverState({
      openedPopoverId: id,
      anchorEl: event.currentTarget,
    });
  };
  const [loaderState, setLoaderState] = React.useState<boolean>(false);
  const handleClickDeleteDisbursement = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    disbursementDetailsApi
      .deleteDisbursementDetails(
        id,
        (selectedEnrollment as Enrollment).campusId
      )
      .then(
        (response: any) => {
          if (response.resultStatus === 0) {
            setLoaderState(false);
            props.refreshAwardGrid();
            props.setSnackBar((props: any) => {
              return {
                variant: "success",
                showSnackBar: true,
                messageInfo: response.resultStatusMessage,
              };
            });
            handleClose();
          } else {
            setLoaderState(false);
            props.setSnackBar((props: any) => {
              return {
                variant: "error",
                showSnackBar: true,
                messageInfo: response.data,
              };
            });
          }
        },
        (exception: any) => {
          setLoaderState(false);
          props.setSnackBar((snackBarProps: any) => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo: exception,
            };
          });
        }
      );
  };

  const handleClose = () => {
    setPopoverState({
      openedPopoverId: null,
      anchorEl: null,
    });
    props.refreshDisbursementDetailsItems();
  };

  const { openedPopoverId, anchorEl } = popoverState;

  const [disbursementModalOpen, setDisbursementModalOpen] = useState(false);
  const [disbursementId, setDisbursementId] = useState("");

  const handleClickEditDisbursement = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    setDisbursementId(id);
    setDisbursementModalOpen(true);
  };
  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={disbursementModalOpen}
        onMouseDown={(e: any) => {
          if (
            e.target.className === "MuiDialog-container MuiDialog-scrollPaper"
          ) {
            setDisbursementModalOpen(false);
          }
        }}
      >
        <DisbursementModal
          studentAwardID={props.studentAwardID}
          open={disbursementModalOpen}
          setDisbursementId={setDisbursementId}
          setOpen={setDisbursementModalOpen}
          disbursementId={disbursementId}
          setSnackBar={props.setSnackBar}
          refreshGrid={props.refreshDisbursementDetailsItems}
          maxSequenceNumber={Math.max(...disbursementDetailsItem.map(obj => obj.sequenceNumber ?? 0))}
        />
      </Backdrop>
      <MaterialTable
        title="Disbursement Details"
        columns={[
          { title: "id", field: "studentAwardScheduleID", hidden: true },
          { title: "Payment Period", field: "paymentPeriod" },
          { title: "Disb Number", field: "sequenceNumber" },
          { title: "Begin Date", field: "beginDate", type: "date" },
          { title: "End Date", field: "endDate", type: "date" },
          {
            title: "Schd Disb Date",
            field: "scheduledDisbursementDate",
            type: "date",
          },
          {
            title: "Schd Disb Amt",
            field: "scheduledDisbursementAmount",
            render: (rowData: any) => {
              return formatMoney(rowData.scheduledDisbursementAmount);
            },
          },
          {
            title: "Act Disb Date",
            field: "actualDisbursementDate",
            type: "date",
          },
          {
            title: "Act Disb Amt",
            field: "actualDisbursementAmount",
            render: (rowData: any) => {
              return formatMoney(rowData.actualDisbursementAmount);
            },
          },
          {
            title: "Net Amt",
            field: "netAmount",
            render: (rowData: any) => {
              return formatMoney(rowData.netAmount);
            },
          },

          {
            title: (
              <AccessManager
                allowedPermissions={[
                  security.permissions.student.updateAwards,
                  security.permissions.student.deleteAwards,
                ]}
                accessCheck={(
                  extraAccessData: any,
                  user: any,
                  rowData: any
                ) => {
                  return isSupportUser || !isAfaIntegrationEnabled || (isAfaIntegrationEnabled && !props.isTitleIVAward)
                }}
                renderNoAccess={() => null}
              >
                <Button
                  className={clsx(classes.actionButton)}
                  onClick={(e) => {
                    setPopoverState({
                      openedPopoverId: null,
                      anchorEl: null,
                    });
                    handleClickEditDisbursement(e, EmptyGuid);
                  }}
                >
                  <AddIcon />
                </Button>
              </AccessManager>
            ),
            field: "canEdit",
            render: function (rowData: any) {
              return (
                <div className={classes.options}>
                  {
                    <AccessManager
                      allowedPermissions={[
                        security.permissions.student.updateAwards,
                        security.permissions.student.deleteAwards,
                      ]}
                      extraAccessData={{ rowData }}
                      accessCheck={(
                        extraAccessData: any
                      ) => {
                        return isSupportUser || extraAccessData?.rowData?.canEdit  || !isAfaIntegrationEnabled || (isAfaIntegrationEnabled && !props.isTitleIVAward)
                      }}
                        
                      renderNoAccess={() => null}
                    >
                      <Button
                        className={clsx(classes.actionButton)}
                        onClick={(e) => {
                          handleClick(e, rowData.studentAwardScheduleID);
                        }}
                        disableRipple={true}
                      >
                        <EditIcon />
                      </Button>
                    </AccessManager>
                  }

                  <Popover
                    id={rowData.studentAwardScheduleID}
                    key={rowData.studentAwardScheduleID}
                    open={openedPopoverId === rowData.studentAwardScheduleID}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    PaperProps={{
                      style: {
                        boxShadow: "2px 3px 3px 3px rgba(0,0,0,0.03)",
                      },
                    }}
                  >
                    <div className={classes.wrapper}>
                      <Button
                        className={classes.popoverButton}
                        onClick={(e) => {
                          setPopoverState({
                            openedPopoverId: null,
                            anchorEl: null,
                          });
                          handleClickEditDisbursement(
                            e,
                            rowData.studentAwardScheduleID
                          );
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                    <div className={classes.wrapper}>
                      <Button
                        className={classes.popoverButton}
                        onClick={(e) => {
                          handleClickDeleteDisbursement(
                            e,
                            rowData.studentAwardScheduleID
                          );
                          handleClose();
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </Popover>
                </div>
              );
            },
          },
        ]}
        icons={tableIcons}
        options={{
          search: true,
          sorting: false,
          actionsColumnIndex: 5,
          paging: false,
          headerStyle: {
            backgroundColor: theme.palette.secondary.light,
            zIndex: 0,
          },
          rowStyle: (rowData) => ({
            fontFamily: "Roboto",
          }),
        }}
        data={disbursementDetailsItem}
      />
    </div>
  );
};

export default DisbursementDetailsGrid;
