import React, { useEffect, useRef, SyntheticEvent } from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Input, IconButton, Card, CircularProgress, Tooltip } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import StudentItemResult from "./SearchResults/StudentItemResult/StudentItemResult";
import { StudentProfile } from "../../../interfaces/student/profile/StudentProfile";
import AdvanceSearch from "./AdvanceSearch/AdvanceSearch";
import { AdvanceSearchModel } from "../../../interfaces/student/search/AdvanceSearchModel";
import * as studentSearchApi from "../../../api/search/studentSearchApi";
import { StudentSearchModel } from "../../../interfaces/student/search/SearchModel";
import * as actionTypes from "../../../constants/actions.constants";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import useOutsideClick from "../../../utils/useOutsideClick";
import { withRouter } from "react-router-dom";
import { keys } from "../../../constants/urlparameters.contants";
import CustomSnackbar from "../../../components/notifications/CustomSnackbar";
import { UpdateMRU } from "../../../interfaces/systemCatalog/UpdateMRU";
import { Enrollment } from "../../../interfaces/student/academics/Enrollment";
import FilterListIcon from '@material-ui/icons/FilterList';
import { UserProfile } from "../../../interfaces/user/UserProfile";

const useStyles = makeStyles((theme: any) => ({
    searchBox: {
        alignItems: "center",
        borderRadius: "0.4em",
        width: "100%",
        marginRight: theme.spacing(2),
    },
    searchBoxActive: {
        display: "flex",
        alignItems: "center",
        marginRight: theme.spacing(2),
        width: "100%",
        "& input::placeholder": {},
        "& $searchIcon": {
            color: theme.palette.black,
        },
        position: "relative",
    },
    searchIcon: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        color: theme.palette.topBar.inputFields.color,
    },
    searchInput: {
        flexGrow: 1,
        width: "88%",
        color: theme.palette.topBar.inputFields.color,
        fontSize: 14,
        fontWeight: theme.typography.fontWeightMedium,
        padding: 4,
        "& input::placeholder": {
            color: theme.palette.topBar.inputFields.color,
            fontWeight: theme.typography.fontWeightLight,
        },
        "&$searchInputFocused": {
            color: theme.palette.text.primary,
            width: "88%",
            "& input::placeholder": {
                color: theme.palette.text.primary,
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
    },
    advanceSearchInput: {
        color: theme.palette.primary.main,
        "& input::placeholder": {
            color: theme.palette.primary.main,
        },
    },
    searchInputFocused: {},
    searchButton: {
        marginLeft: theme.spacing(3),
    },
    searchPaper: {
        backgroundColor: theme.palette.studentSearch.background,
        border: "1px solid " + theme.palette.studentSearch.border,
        boxShadow: "none",
        transition: "width 4s",
    },
    searchPaperShown: {
        width: "100%",
        position: "absolute",
        backgroundColor: theme.palette.white,
        border: "1px solid #ccc",
        minHeight: "300px",
        top: 0,
        zIndex: 10,
    },
    searchContentWrapper: {
        display: "none",
        color: theme.palette.primary.main,
    },
    searchContentWrapperShown: {
        top: "0px",
        width: "100%",
        display: "block",
    },
    searchResultsContent: {
        paddingLeft: "1em",
        paddingRight: "1em",
        paddingBottom: "1em",
        color: "rgba(0, 0, 0, 0.8)",
    },
    searchResultList: {
        padding: "0.4em",
        marginTop: "10pt",
        maxHeight: "500px",
        overflowY: "auto",
        overflowX: "hidden",
        display: "block",
        visibility: "visible",
    },
    searchResultListHidden: {
        display: "none",
        visibility: "hidden",
    },
    advanceSearchResult: {
        padding: "0.4em",
        display: "block",
        visibility: "visible",
    },
    advanceSearchResultHidden: {
        display: "none",
        visibility: "hidden",
    },
    recentStudents: {
        color: "rgba(0, 0, 0, 0.8)",
        fontSize: "10pt",
    },
    advanceSearchExpand: {
        float: "right",
        color: theme.palette.black,
    },
    advanceSearchExpanded: {
        float: "right",
        color: theme.palette.black,
    },
    loader: {
        marginTop: theme.spacing(10),
    },
}));

const SearchBar = (props: any) => {
    const { onSearch, className, staticContext, ...rest } = props;
    const dispatch = useDispatch();
    const containerRef = useRef();
    const classes = useStyles({});
    const [isSearchBarActive, setSearchBarState] = useState<boolean>(false);
    const [mruHasLoaded, setMruHasLoaded] = useState<boolean>(false);
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const refreshMru = useSelector((state: any) =>
        state.mru.refreshMru
    );
    const [isAdvanceSearchActive, setIsAdvanceSearchState] = useState<boolean>(
        false
    );
    const [isSearchBoxContentActive, setIsSearchBoxContentActiveState] = useState<
        boolean
    >(false);
    const [studentProfiles, setStudentProfiles] = React.useState<
        Array<StudentProfile>
    >();
    const [mruProfiles, setMruProfiles] = React.useState<Array<StudentProfile>>();
    const [mruIsShowing, setMruIsShowing] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [searchText, setSearchText] = React.useState<string>("");
    const defaultInputSearchPlaceHolder: string =
        "Choose a recent student or search by name, SSN, or ID";

    const loggedInUser = useSelector((state: any) => state.session.user) as UserProfile;
    const key = `${loggedInUser?.userId}_campuses`;
    const campusOptions = useSelector((state: any) => (state.ac_cache && state.ac_cache.data && state.ac_cache.data[key] && state.ac_cache.data[key].options) || []);
    const isAnyAdvanceSearchFilterApplied: boolean = useSelector((state: any) =>
        state.user_advance_search.isAnyFilterApplied(state.session.user.userId)
    );
    const [inputSearchPlaceHolder, setInputSearchPlaceHolderState] = useState<
        string
    >(defaultInputSearchPlaceHolder);

    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );

    const onChangeSearchText = (e: any) => {
        setSearchText(e);
    };

    const fetchSearchResults = () => {
        if (searchText && searchText.length > 2 && userSelectedCampus) {
            setLoading(true);
            studentSearchApi
                .studentProfileSearch({
                    Filter: searchText,
                    ShowAll: true,
                    CampusId: userSelectedCampus,
                    creditHoursOnly: false,
                } as StudentSearchModel)
                .then(
                    (rv) => {
                        setStudentProfiles(rv.result);
                        setMruIsShowing(false);
                        setLoading(false);
                    },
                    (e) => {
                        console.log(e);
                        setLoading(false);
                    }
                );
        } else if (!mruIsShowing) {
            loadMRU();
        }
    }



    const loadMRU = () => {
        setLoading(true);
        if (mruHasLoaded) {
            setStudentProfiles(mruProfiles);
            setMruIsShowing(true);
            setMruHasLoaded(true);
            setLoading(false);
        } else {
            studentSearchApi.getMRU().then(
                (rv: any) => {
                    setStudentProfiles(rv.result);
                    setMruProfiles(rv.result);
                    setMruIsShowing(true);
                    setMruHasLoaded(true);
                    setLoading(false);
                },
                (e: any) => {
                    setLoading(false);
                }
            );
        }
    };

    const onSearchFocus = (event: any) => {
        if (!isSearchBarActive) {
            setSearchBarState((isSearchBarActive) => !isSearchBarActive);
            setInputSearchPlaceHolderState(defaultInputSearchPlaceHolder);
            setIsSearchBoxContentActiveState(true);
            setIsAdvanceSearchState(false);
        }
    };

    const hideStudentSearch = () => {
        setInputSearchPlaceHolderState(defaultInputSearchPlaceHolder);
        if (isSearchBarActive) {
            setSearchBarState((isSearchBarActive) => !isSearchBarActive);
            setIsSearchBoxContentActiveState(false);
        }
    };

    const onSelectStudentProfile = (data: StudentProfile) => {
        if (data) {
            hideStudentSearch();

            //update MRU
            setMruProfiles((mruProfiles: any) => {
                if (!mruProfiles) {
                    mruProfiles = [];
                }
                mruProfiles = mruProfiles.filter((e: any) => {
                    return e.studentId !== data.studentId;
                });
                mruProfiles.unshift(data);
                if (mruProfiles.length > 5) {
                    mruProfiles.pop();
                }
                return mruProfiles;
            });

            studentSearchApi
                .updateMRU({
                    mruTypeId: 1,
                    entityId: data.studentId,
                    userId: loggedInUser.userId,
                    campusId: (data.enrollments as any)[0].campusId,
                } as UpdateMRU)
                .then(
                    (e: any) => { },
                    (err) => { }
                );

            let urlParams = new URLSearchParams(props.history.location.search);
            let studentIdQueryId = keys.studentId;
            let campusIdQueryId = keys.campusId;

            //if student selected is different than current selected campus, show snackbar
            if (
                urlParams.get(campusIdQueryId) != (data.enrollments as any)[0].campusId
            ) {
                setShowSnackBar(true);
            }

            //set url parameters for campusId and studentId
            urlParams.set(studentIdQueryId, data.studentId as any);
            urlParams.set(
                campusIdQueryId,
                (data.enrollments as any)[0].campusId as any
            );

            //change api url(s) based on timezone of the campus
            const e = (campusOptions as any[]).find((m) => m.value === (data.enrollments as any)[0].campusId);
            e && e.campusTimeZoneEnvironment && e.campusTimeZoneEnvironment.uxUrl && localStorage.setItem('UXUrl', (e.campusTimeZoneEnvironment.uxUrl));
            e && e.campusTimeZoneEnvironment && e.campusTimeZoneEnvironment.hostUrl && localStorage.setItem('HostUrl', (e.campusTimeZoneEnvironment.hostUrl));
            e && e.campusTimeZoneEnvironment && e.campusTimeZoneEnvironment.siteUrl && localStorage.setItem('SiteUrl', (e.campusTimeZoneEnvironment.siteUrl));
            e && e.campusTimeZoneEnvironment && e.campusTimeZoneEnvironment.apiUrl && localStorage.setItem('ApiUrl', (e.campusTimeZoneEnvironment.apiUrl));

            //change api url(s) based on timezone of the campus

            //route to student info page if not from student module
            if (props && props.location) {
                if (props.location.pathname.indexOf("student") > -1) {
                    props.history.push({
                        search: urlParams.toString(),
                    });
                } else {
                    props.history.push({
                        pathname: "/student/1/profile/info",
                        search: urlParams.toString(),
                    });
                }
            }

            setSearchText("");
        }
    };

    const onAdvanceSearchClick = (event: any) => {
        if (isSearchBarActive) {
            hideStudentSearch();
        }

        if (isAdvanceSearchActive) {
            setSearchBarState(false);
            setIsSearchBoxContentActiveState(false);
            setInputSearchPlaceHolderState(defaultInputSearchPlaceHolder);
        } else {
            setIsSearchBoxContentActiveState(true);
            setInputSearchPlaceHolderState("Advanced Search");
        }
        setIsAdvanceSearchState((isAdvanceSearchActive) => !isAdvanceSearchActive);
    };

    const onSearchClicked = (
        event: any,
        advanceSearchModel: AdvanceSearchModel
    ) => {
        setSearchBarState(false);
        setIsSearchBoxContentActiveState(false);
        setIsAdvanceSearchState(false);
        setInputSearchPlaceHolderState(defaultInputSearchPlaceHolder);
    };

    useOutsideClick(containerRef, () => {
        if (isSearchBarActive) {
            hideStudentSearch();
        }
    });

    useEffect(() => {
        const timeOutId = setTimeout(() => fetchSearchResults(), 500);
        return () => clearTimeout(timeOutId);

    }, [searchText]);

    useEffect(() => {
        setLoading(true);

        studentSearchApi.getMRU().then(
            (rv: any) => {
                setStudentProfiles(rv.result);
                setMruProfiles(rv.result);
                setMruHasLoaded(true);
                setLoading(false);
            },
            (e: any) => {
                setLoading(false);
            }
        );
    }, [refreshMru]);

    useEffect(() => {
        if (mruIsShowing && mruHasLoaded) {
            loadMRU();
        }
    }, [mruProfiles]);

    return (
        <div
            ref={containerRef}
            id="searchBox"
            name="searchBox"
            {...rest}
            className={clsx(
                { [classes.searchBox]: !isSearchBarActive },
                {
                    [classes.searchBoxActive]: isSearchBarActive || isAdvanceSearchActive,
                }
            )}
        >
            <Card
                className={clsx(
                    { [classes.searchPaper]: !isSearchBarActive },
                    {
                        [classes.searchPaperShown]:
                            isSearchBarActive || isAdvanceSearchActive,
                    }
                )}
                elevation={2}
            >
                <Input
                    startAdornment={<SearchIcon className={classes.searchIcon} />}
                    classes={{
                        root: clsx(
                            { [classes.searchInput]: !isAdvanceSearchActive },
                            { [classes.advanceSearchInput]: isAdvanceSearchActive }
                        ),
                        focused: classes.searchInputFocused,
                    }}
                    onChange={(e: any) => {
                        onChangeSearchText(e.target.value);
                    }}
                    value={searchText}
                    disableUnderline
                    placeholder={inputSearchPlaceHolder}
                    onFocus={onSearchFocus}
                />

                <IconButton
                    className={clsx(
                        { [classes.advanceSearchExpand]: !isSearchBarActive },
                        {
                            [classes.advanceSearchExpanded]:
                                isSearchBarActive || isAdvanceSearchActive,
                        }
                    )}
                    size="small"
                    onClick={onAdvanceSearchClick}
                >
                    {!isAdvanceSearchActive && !isAnyAdvanceSearchFilterApplied &&
                        <Tooltip
                            title="Advance Search"
                            aria-label="Advance Search"
                        >
                            <ArrowDropDownIcon />
                        </Tooltip>}
                    {isAdvanceSearchActive && !isAnyAdvanceSearchFilterApplied && <ArrowDropUpIcon />}
                    {isAnyAdvanceSearchFilterApplied &&
                        <Tooltip
                            title="Advance Search"
                            aria-label="Advance Search"
                        >
                            <FilterListIcon />
                        </Tooltip>
                    }
                </IconButton>

                <div
                    id="searchContentWrapper"
                    className={clsx(
                        { [classes.searchContentWrapper]: !isSearchBoxContentActive },
                        {
                            [classes.searchContentWrapperShown]: isSearchBoxContentActive,
                        }
                    )}
                >
                    <div className={classes.searchResultsContent}>
                        {loading && (
                            <Grid container justify="center" alignItems="center">
                                <CircularProgress className={classes.loader}></CircularProgress>
                            </Grid>
                        )}

                        {!isAdvanceSearchActive && studentProfiles && !loading && (
                            <div className={classes.searchResultList}>
                                {studentProfiles.map(function (item: any, index: any) {
                                    return (
                                        <StudentItemResult
                                            key={"student-item-result-" + index}
                                            studentProfile={item}
                                            onSelect={(e: any, data: any) => {
                                                onSelectStudentProfile(data);
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        )}

                        {isAdvanceSearchActive && (
                            <div className={classes.advanceSearchResult}>
                                <AdvanceSearch
                                    onSearchClicked={onSearchClicked}
                                ></AdvanceSearch>
                            </div>
                        )}
                    </div>
                </div>
            </Card>
            <CustomSnackbar
                variant="warning"
                message="Campus changed after selecting student from different campus."
                open={showSnackBar}
                onClose={(event?: SyntheticEvent, reason?: string) => {
                    setShowSnackBar(false);
                }}
            ></CustomSnackbar>
        </div>
    );
};

SearchBar.propTypes = {
    className: PropTypes.string,
    onSearch: PropTypes.func,
};

export default withRouter(SearchBar);
