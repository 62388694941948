import { autoCompleteConstants } from "../constants/actions.constants";
import * as autoCompleteApi from "../api/autoCompleteApi";

export const autoCompleteActions = {
  loadAutoCompleteData,
  updateAutoCompleteCache,
  refresh
};

export type loadAutoCompleteDataOptions = {
  route: any;
  data?: any;
  params?: any;
  method: any;
};

function loadAutoCompleteData(options: loadAutoCompleteDataOptions) {
  return autoCompleteApi
    .pullData(options.route, options.data, options.params, options.method)
    .then(
      (response: any) => {
        if (response.data) {
          return response.data;
        } else {
          throw response;
        }
      },
      (exception: any) => {
        return exception;
      }
    );
}

function updateAutoCompleteCache(data: any, key: any) {
  return (dispatch: any) => {
    const dta = { key, ...data };
    dispatch({
      type: autoCompleteConstants.SESSION_LOADING_AC_UPDATE_CACHE,
      data: dta
    });
  };
}

function refresh(error: any) {
  return { type: autoCompleteConstants.SESSION_LOADING_AC_LOADING, error };
}
