export enum InputType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  CURRENCY = 'CURRENCY'
}

export enum AdornmentPosition {
  PRE = 'PRE',
  POST = 'POST'
}

export const DEFAULT_ADORNMENT_POSITION = AdornmentPosition.PRE;
export const DEFAULT_TYPE = InputType.TEXT;
export const DEFAULT_CURRENCY = '$';
export const DEFAULT_DECIMAL_PLACES = 2;
export const INTEGER_DECIMAL_PLACES = 0;
export const DEFAULT_THOUSAND_SEPARATOR = ',';
export const AllowSpaceDigitsAndLettersOnlyRegex = '^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$';