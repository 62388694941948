import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { connect, useSelector } from "react-redux";
import { Grid, TextField, Box, Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import "../../index.css";
import { sessionActions as actions } from "../../actions/sessionActions";
import { accountActions as accActions } from "../../actions/accountActions";
import { LoginUser } from "../../interfaces/security/LoginUser";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import { UserTermsAndConditions } from "./UserTermsAndConditions";
import { TenantSelector } from "./TenantSelector";
import SchoolLogo from "./SchoolLogo";
import SelectedSchoolBackground from "./SchoolBackground";
import themeTenantPicker from "../Common/ThemeTenantPicker";

const Login = (props: any) => {
  const history = useHistory();
  const isTabletMode = useMediaQuery("(max-width:1280px)");
  const themeName = themeTenantPicker();
  const themeBackground = SelectedSchoolBackground(themeName);
  const appConfig = useSelector(
    (state: any) => state.session?.appConfig?.appConfig
  );
  const useStyles = makeStyles((theme: any) =>
    createStyles({
      root: {
        background:
          themeName === "default"
            ? theme.palette.login.background
            : "url(" + themeBackground + ") no-repeat center center fixed", // theme.palette.login.background, //"url(" + background + ") no-repeat center center fixed",
        backgroundSize: "cover",
        WebkitBackgroundSize: "cover",
        MozBackgroundSize: "cover",
        OBackgroundSize: "cover",
        height: "100% !important",
        minHeight: "100% !important",
        overflow: "hidden !important",
      },
      linkNew: {
        cursor: "pointer",
      },
      singleSignOnLink: {
        marginLeft: 10,
        cursor: "pointer",
      },
      gridContainer: {
        zIndex: 999999,
        height: "100%",
      },
      gridContainerTabletMode: {
        zIndex: 999999,
        height: "100%",
      },
      loginFormContainer: {
        minWidth: "600px !important",
        maxWidth: "600px !important",
      },
      loginContainer: {
        background: theme.palette.login.background,
        border: "1px solid",
        borderColor: theme.palette.border,
        boxShadow: theme.palette.boxShadow,
        borderRadius: "0.2em",
      },
      mainLogoContainer: {
        textAlign: "center",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
      forgetPasswordLinkContainer: {
        textAlign: "left",
        padding: theme.spacing(3),
        paddingLeft: theme.spacing(4),
        color: theme.palette.text.primary,
      },
      column: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        textAlign: "center",
        color: theme.palette.text.primary,
      },
      textField: {
        width: "100%",
        background: theme.palette.paper.background,
      },
      button: {
        backgroundColor: theme.palette.site.primary,
        width: "100%",
        color: theme.palette.white,
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
        },
      },
      ssoButton: {
        backgroundColor: "white",
        width: "50%",
        color: theme.palette.primary.main,
        "&:hover": {
          opacity: 0.8,
          backgroundColor: "white",
          color: theme.palette.primary.main,
        },
      },
      hrStyle: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        borderTop: "1px solid",
        borderColor: theme.palette.border,
      },
      errorText: {
        margin: 0,
        padding: 0,
        color: theme.palette.error.dark,
      },
    })
  );

  const classes = useStyles({});

  const [loginUser, setLoginUser] = useState<LoginUser>({
    username: "",
    password: "",
    tenantName: "",
  });

  const queryParams = new URLSearchParams(window.location.search);
  const authToken = queryParams.get("authToken");
  const session = useSelector((state: any) => state.session);
  const account = useSelector((state: any) => state.account);

  React.useEffect(() => {
    if (!account.isPendingUserTerms && account.moveToLogin) {
      if (authToken) {
        handleTokenLogin();
      } else {
        handleLogin();
      }
    }
  }, [account.isPendingUserTerms]);

  const [callMessage, setCallMessage] = useState("");
  const [hasLoginError, setHasLoginError] = useState(false);

  React.useEffect(() => {
    setCallMessage(session.errorMessage);
    setHasLoginError(true);
  }, [session.errorMessage]);

  const handleContinue = (tenantName: any) => {
    let updatedModel = loginUser;
    (updatedModel as any)["tenantName"] = tenantName;
    setLoginUser(updatedModel);

    if (authToken) {
      handleTokenLogin();
    } else {
      handleLogin();
    }
  };

  const handleLogin = () => {
    props.clearErrorMsg();
    setHasLoginError(false);
    if (loginUser && loginUser.password && loginUser.username) {
      props.login(loginUser);
    } else {
      setCallMessage("Incorrect credentials, please try again");
      setHasLoginError(true);
    }
  };

  const handleTokenLogin = () => {
    props.clearErrorMsg();
    setHasLoginError(false);
    if (authToken) {
      props.loginWithToken(authToken, loginUser.tenantName);
    } else {
      setCallMessage(
        "Cannot login with authentication provider if no token provided."
      );
      setHasLoginError(true);
    }
  };

  const onPasswordChanged = (e: any) => {
    if (e.keyCode === 13) {
      loginUser.password = e.target.value;
      setLoginUser(loginUser);
      handleLogin();
    }
  };

  useEffect(() => {
    props.clearErrorMsg();
  }, []);

  const isLoggingIn: boolean = session.loggingIn;

  useEffect(() => {
    if (authToken) props.loginWithToken(authToken, null);
  }, [authToken]);

  return (
    <div className={clsx(classes.root)}>
      <Grid
        container
        className={clsx(
          { [classes.gridContainer]: !isTabletMode },
          { [classes.gridContainerTabletMode]: isTabletMode }
        )}
        direction="row"
        alignItems="center"
        justify="center"
      >
        <Grid item xs></Grid>
        <Grid item xs={6} className={classes.loginFormContainer}>
          <div className={classes.loginContainer}>
            <div className={classes.mainLogoContainer}>
              <SchoolLogo schoolName={themeName} logoType="Normal"></SchoolLogo>
            </div>
            <div className={classes.column}>
              <TextField
                required
                id="username"
                className={classes.textField}
                label="Username"
                // margin="normal"
                variant="filled"
                value={loginUser.username}
                onChange={(e: any) =>
                  setLoginUser({ ...loginUser, username: e.target.value })
                }
                autoFocus={true}
              />
            </div>
            <div className={classes.column}>
              <TextField
                required
                id="password"
                className={classes.textField}
                label="Password"
                type="password"
                // margin="normal"
                variant="filled"
                value={loginUser.password}
                onKeyUp={onPasswordChanged}
                onChange={(e: any) =>
                  setLoginUser({ ...loginUser, password: e.target.value })
                }
              />
              {hasLoginError && (
                <h5 className={classes.errorText}>{callMessage}</h5>
              )}
            </div>
            <div className={classes.column}>
              {isLoggingIn && <CircularProgress></CircularProgress>}

              {!isLoggingIn && (
                <Button className={classes.button} onClick={handleLogin}>
                  Login
                </Button>
              )}
            </div>
            <div className={classes.forgetPasswordLinkContainer}>
              <Link
                title="Click to retrieve password"
                className={classes.linkNew}
                onClick={() => {
                  props.resetAccountStore();
                  history.push("/ForgotPassword/EnterEmail");
                }}
              >
                Forgot password?
              </Link>
            </div>

            {appConfig && appConfig.FameAuthEnabled && (
              <div className={classes.column}>
                {!isLoggingIn && (
                  <Button
                    className={classes.ssoButton}
                    onClick={() => {
                      window.location.href =
                        appConfig.FameAuthDomain +
                        "Identity/Account/Login?clientId=" +
                        appConfig.FameAuthClientId;
                    }}
                  >
                    Login with &nbsp;
                    <img
                      src="https://sso-development-fame.azurewebsites.net/images/fame_rgb_primary.png"
                      alt="Image"
                      width="100px"
                    ></img>
                  </Button>
                )}
              </div>
            )}
            <UserTermsAndConditions />
            <TenantSelector handleContinue={handleContinue} />
          </div>
        </Grid>
        <Grid item xs></Grid>
      </Grid>
    </div>
  );
};

function mapState(state: any) {
  return state;
}

const actionCreators = {
  login: actions.login,
  logout: actions.logout,
  loginWithToken: actions.loginWithToken,
  clearErrorMsg: actions.clearErrorMsg,
  resetAccountStore: accActions.resetAccountStore,
};

const connectedLoginPage = connect(mapState, actionCreators)(Login);
export { connectedLoginPage as Login };
