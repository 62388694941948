import API from "../../apiWrapper";
import { AxiosResponse, CancelToken } from "axios";
import { IMTFPreCheck } from "../../../interfaces/academicRecords/MTFPreCheck";

const getMTFPreCheckResult = (
    campusId: string
) => {
    return API()
        .get("/AcademicRecords/TitleIVSAP/MTFPreCheck", {
            params: {
                campusId
            }
        })
        .then(
            (res: AxiosResponse<IMTFPreCheck[]>) => {
                if (!!res && !!res.data) {
                    return res.data;
                }
            },
            (error: any) => {
                return error;
            }
        );
};

const getMTFWidgetSettingValue = (
    campusId: string
) => {
    return API()
        .get("/AcademicRecords/TitleIVSAP/MTFWidgetSetting", {
            params: {
                campusId
            }
        })
        .then(
            (res: AxiosResponse<number>) => {
                if (!!res) {
                    return res.data;
                }
            },
            (error: any) => {
                return error;
            }
        );
};

const updateMTFWidgetSettingValue = (
    campusId: string,
    minimumMtfPercentage:number
) => {
    return API()
        .put(`/AcademicRecords/TitleIVSAP/UpdateMTFWidgetSetting?campusId=${campusId}&minimumMtfPercentage=${minimumMtfPercentage}`)
        .then(
            (res: AxiosResponse<number>) => {
                if (!!res) {
                    return res.data;
                }
            },
            (error: any) => {
                return error;
            }
        );
};

export const printMtfWidgetReport = (campusId: any) => {
    return API()
      .get(`/AcademicRecords/TitleIVSAP/GenerateMTFWidgetReport?campusId=${campusId}`, {
        responseType: "blob",
      })
      .then(
        (res: any) => {
          if (res && res.data) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            let newDate = new Date();
            var toDay =
              newDate.getMonth() +
              1 +
              "-" +
              newDate.getDate() +
              "-" +
              newDate.getFullYear();
            let fileName =
              "MTFPreCheck" +
              toDay +
              ".pdf";
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          }
          return res;
        },
        (error: any) => {
          return error.response;
        }
      );
  };

export const mtfWidgetApi = { getMTFPreCheckResult, getMTFWidgetSettingValue, updateMTFWidgetSettingValue, printMtfWidgetReport };