import React from 'react';
import {
	Table,
	TableBody,
	TablePagination,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Checkbox,
	Link,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import AccessManager from '../../components/security/AccessManager';
import { security } from '../../constants/Security/securityConstants';
import makeStyles from './WidgetStyles';
import { withRouter } from 'react-router-dom';
import ProgressSaveButton from '../../components/_Layout/Buttons/ProgressSaveButton';
import * as PaymentPlanApi from '../../api/student/financials/paymentPlan/paymentPlanApi';
import CustomSnackbar from '../../components/notifications/CustomSnackbar';

const useStyles = makeStyles;

const formatMoney = (number: number) => {
	var absValue = Math.abs(number);
	let numberString = absValue.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
	return number < 0 ? '(' + numberString + ')' : numberString;
};

function PaymentPlanPastDue(props: any) {
	const classes = useStyles();
	const [PastDue, setPastDueData] = React.useState([] as any);
	const [loaderState, setLoaderState] = React.useState<boolean>(false);

	const [selected, setSelected] = React.useState([] as any);
	const isSelected = (paymentPlanId: any) =>
		selected.indexOf(paymentPlanId) !== -1;

	const rowClick = (event: any, resultId: string) => {
		const selectedIndex = selected.indexOf(resultId);
		let newSelected: string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, resultId);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};

	React.useEffect(() => {
		setSelected(props.data.filter((s: any) => s.contacted === true).map(function (item: any) { return item.paymentPlanId; }));
		setPastDueData(props.data);
	}, [props.data]);

	const campusId = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

    interface SnackBarProps {
        open: boolean;
        message: string;
        variant: string;
    }
    const [snackProps, setSnackProps] = React.useState<SnackBarProps>({ open: false, message: '', variant: 'success' });
    const handleSnackbarClose = () => {
    	setSnackProps({
    		open: false,
    		message: '',
    		variant: 'success'
    	});
    };

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event: any, newPage: any) => {
    	setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: any) => {
    	setRowsPerPage(parseInt(event.target.value, 10));
    	setPage(0);
    };

    const updateContacted = () => {
    	if (selected.length > 0) {
    		setLoaderState(true);
    		PaymentPlanApi.updateContactedFlagForPaymentPlan(selected).then(
    			(_response: any) => {
    				props.reload();
    				setLoaderState(false);
    				setSnackProps({
    					open: true,
    					message: 'Saved Successfully',
    					variant: 'success'
    				});
    			},
    			(_exception: any) => {
    				setLoaderState(false);
    				setSnackProps({
    					open: true,
    					message: 'Something went wrong',
    					variant: 'error'
    				});
    			}
    		);
    	}
    };

    return (
    	<AccessManager
    		allowedPermissions={[
    			security.permissions.dashboard.viewPaymentPlanWidget,
    		]}
    		renderNoAccess={() => null}
    	>
    		<TableContainer component={Paper}>
    			<Table
    				className={classes.table}
    				size="small"
    				aria-label="a dense table"
    			>
    				<TableHead>
    					<TableRow>
    						<TableCell align="left" className={classes.headCell}>
                                Enrollment
    						</TableCell>
    						<TableCell align="left" className={classes.headCell}>
                                Total Amount Past Due
    						</TableCell>
    						<TableCell align="center" className={classes.headCell}>
                                Days Past Due
    						</TableCell>
    						<TableCell align="center" className={classes.headCell}>
                                Contacted ?
    						</TableCell>
    					</TableRow>
    				</TableHead>
    				<TableBody>
    					{PastDue !== null && PastDue.length > 0 ? (
    						PastDue.sort((a: any, b: any) => b.daysDue - a.daysDue).slice(
    							page * rowsPerPage,
    							page * rowsPerPage + rowsPerPage
    						).map((row: any) => {
    							const isRowSelected = isSelected(row.paymentPlanId);
    							return (
    								<TableRow
    									key={row.paymentPlanId}
    									onClick={(event) =>
    										rowClick(event, row.paymentPlanId)
    									}
    									role="checkbox"
    									selected={isRowSelected}
    								>
    									<TableCell align="left" className={classes.bodyCell}>
    										<Link
    											className={classes.linkDesign}
    											component="button"
    											onClick={() => {
    												let urlParams = new URLSearchParams(
    													props.history.location.search
    												);
    												let studentIdQueryId = 'studentId';
    												let campusIdQueryId = 'campusId';

    												//set url parameters for campusId and studentId
    												urlParams.set(
    													studentIdQueryId,
                                                        row.studentId as any
    												);
    												urlParams.set(campusIdQueryId, campusId);
    												props.history.push({
    													pathname: '/student/1/financials/ledger',
    													search: urlParams.toString(),
    												});
    											}}
    										>
    											{row.studentNameDesc}
    										</Link>
    									</TableCell>
    									<TableCell align="right">{formatMoney(row.amountDue)}</TableCell>
    									<TableCell align="center">{row.daysDue}</TableCell>
    									<TableCell align="center">
    										<Checkbox
    											className={classes.checkBoxStyle}
    											value="uncontrolled"
    											color="primary"
    											inputProps={{
    												'aria-label': 'uncontrolled-checkbox',
    											}}
    											checked={isRowSelected}
    										/>
    									</TableCell>
    								</TableRow>
    							);
    						})
    					) : (
    						<TableRow>
    							<TableCell align="center" colSpan={4}>
                                    No Data
    							</TableCell>
    						</TableRow>
    					)}
    				</TableBody>
    			</Table>
    		</TableContainer>
    		<TablePagination
    			rowsPerPageOptions={[5, 10, 25]}
    			component="div"
    			count={PastDue.length}
    			rowsPerPage={rowsPerPage}
    			page={page}
    			onPageChange={handleChangePage}
    			onRowsPerPageChange={handleChangeRowsPerPage}
    		/>
    		<AccessManager
    			allowedPermissions={[
    				security.permissions.dashboard.updateContactedFlagForPaymentPlanSchedule,
    			]}
    			renderNoAccess={() => (
    				<ProgressSaveButton
    					disabled={true}
    					text={'Save'}
    					onClick={() => {
    					}}
    					loading={loaderState}
    				></ProgressSaveButton>
    			)}
    		>
    			<ProgressSaveButton
    				text={'Save'}
    				onClick={() => {
    					updateContacted();
    				}}
    				loading={loaderState}
    			></ProgressSaveButton>
    		</AccessManager>
    		<CustomSnackbar
    			variant={snackProps.variant === 'success' ? 'success' : 'error'}
    			message={snackProps.message}
    			open={snackProps.open}
    			onClose={handleSnackbarClose}
    		></CustomSnackbar>
    	</AccessManager>
    );
}
export default withRouter(PaymentPlanPastDue);
