import React from "react";
import moment from "moment";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import {
		Typography,
		Paper,
		CardContent,
		Grid,
		Button
	} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
		MuiPickersUtilsProvider,
		KeyboardDatePicker
	} from "@material-ui/pickers";
import ProgressSaveButton from "../../../components/_Layout/Buttons/ProgressSaveButton";
import * as CurrentlyAttendingApi from "../../../api/student/status/CurrentlyAttendingApi";
import * as FutureStartApi from "../../../api/student/status/FutureStartApi";
import { ApiResultStatus } from "../../../enums/ApiResultStatus"; 
import { SystemEnrollmentStatus } from "../../../enums/SystemEnrollmentStatus"; 
import { useFormContext, Controller } from "react-hook-form";

type ReEnrollmentProps = {
	setSnackBarProps: any;
	updateEvent: any;
	closeEvent: any;
	newPageEvent: any;
	statusChange: any;
	statusChangeEnrollment: any;
};

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.background.paper,
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2),
			},
		},
		paperRoot: {
			width: "100%",			
		},
		cardTitle: {
			fontSize: theme.spacing(2),
			color: theme.palette.black,
			textDecoration: "bold",
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1)
		},
		cardContent: {
			padding: theme.spacing(1, 1)
		},
		datePicker: {
			"& .MuiIconButton-root": {
				padding: theme.spacing(0.8)
			}
		},    
		actionContainer: {
			marginTop: theme.spacing(3)
		  },
		button: {
			marginRight: theme.spacing(1),
			marginLeft: theme.spacing(1),
        },
	})
);

const ReEnrollment = (props: 
		ReEnrollmentProps) => {

    const classes = useStyles({});

	const data = useFormContext();

	const check180Days = (): boolean => {		
		let startDate = moment(changeEnrollmentData.lastDateAttendance);
		let endDate = moment(changeEnrollmentData.reEnrollmentDate);		
		return endDate.diff(startDate, 'days') > 180;
	};

	const checkAwards = (): boolean => {	
		return (changeEnrollmentData?.systemStatus == SystemEnrollmentStatus.NoStart 
					&& changeEnrollmentData.awardsExist);
	};
	
	const [changeEnrollmentData, setChangeEnrollmentData] = React.useState<any>(props.statusChangeEnrollment);
	const [selectedChange, setSelectedChange] = React.useState<any>(props.statusChange);
	const [passed180Days, setPassed180Days] = React.useState<boolean>(check180Days());
	const [awardsBlock, setAwardsBlock] = React.useState<boolean>(checkAwards());
	const [loaderState, setLoaderState] = React.useState<boolean>(false);

	const getTitle = () => {
		let title = "Change Status to Currently Attending";

		switch (changeEnrollmentData?.systemStatus) {
			case SystemEnrollmentStatus.Dropped:
				title = "Re-Enroll From Dropped Status";
				break;
			case SystemEnrollmentStatus.NoStart:
				title = "Re-Enroll From No Start Status";
				break;
			}
			
		return title;
	};

	const getMessage = () => {
		let msg;

		msg = awardsBlock
			? "As the student has awards a new enrollment is required. Do you want to create the new enrollment now?"
			:!passed180Days 
				? "Enter Re-Enrollment Date" 
				: changeEnrollmentData?.systemStatus == SystemEnrollmentStatus.NoStart
					? "As its more than 180 days since student’s withdrawal a new enrollment is required. Do you want to create the new enrollment now?"
					: "As its more than 180 days since student’s LDA a new enrollment is required. Do you want to create the new enrollment now?"

		return msg;
	};

	const handleFieldOnChange = (fieldId: string, value: any) => {
		let updatedData = changeEnrollmentData;
		(updatedData as any)[fieldId] = value;
		setChangeEnrollmentData({ ...updatedData });
		if (fieldId == "reEnrollmentDate")
			setPassed180Days(check180Days()); 
	};

	const handleReEnroll = () => {	
		switch (selectedChange?.sysStatusId) {
			case SystemEnrollmentStatus.CurrentlyAttending:
				handleReEnrollCurrentlyAttending();
				break;
			case SystemEnrollmentStatus.FutureStart:
				handleReEnrollFutureStart();
				break;
		};
	};

	const handleReEnrollCurrentlyAttending = () => {	
		switch (changeEnrollmentData?.systemStatus) {
			case SystemEnrollmentStatus.NoStart:
				ReEnrollCurrentlyAttendingFromNoStart();
				break;
			case SystemEnrollmentStatus.Dropped:
				ReEnrollCurrentlyAttendingFromDrop();
				break;
		};
	};

	const handleReEnrollFutureStart = () => {	
		switch (changeEnrollmentData?.systemStatus) {
			case SystemEnrollmentStatus.NoStart:
				ReEnrollFutureStartFromNoStart();
				break;
			case SystemEnrollmentStatus.Dropped:
				ReEnrollFutureStartFromDrop();
				break;
		};
	};

	const handleNewEnroll = () => {
		props.newPageEvent("/Student/NewEnrollment?isNew=false");
	};

	const handleClose = () => {
		props.closeEvent();
	};

	const ReEnrollCurrentlyAttendingFromDrop = () => {	
		data.triggerValidation().then((validation: any) => {
			if (validation) {
				setLoaderState(true);
				CurrentlyAttendingApi.ReEnrollFromDrop(changeEnrollmentData, props.statusChange.statusCodeId).then(
				(response: any) => {
					setLoaderState(false);
					handleSubmitResponse(response);
				},
				(exception: any) => {
					setLoaderState(false);
					props.setSnackBarProps((snackBarProps: any) => {
					  return {
						variant: "error",
						showSnackBar: true,
						messageInfo: exception,
					  };
					});
				}
			)}
		});		
	};

	const ReEnrollCurrentlyAttendingFromNoStart = () => {		
		data.triggerValidation().then((validation: any) => {
			if (validation) {
				setLoaderState(true);
				CurrentlyAttendingApi.reEnrollFromNoStart(changeEnrollmentData, props.statusChange.statusCodeId).then(
				(response: any) => {
					setLoaderState(false);
					handleSubmitResponse(response);	
				},
				(exception: any) => {
					setLoaderState(false);	
					props.setSnackBarProps((snackBarProps: any) => {
					  return {
						variant: "error",
						showSnackBar: true,
						messageInfo: exception,
					  };
					});
				}
			)}
		});	
	};

	const ReEnrollFutureStartFromDrop = () => {		
		data.triggerValidation().then((validation: any) => {
			if (validation) {
				setLoaderState(true);
				FutureStartApi.ReEnrollFromDrop(changeEnrollmentData, props.statusChange.statusCodeId).then(
				(response: any) => {
					setLoaderState(false);
					handleSubmitResponse(response);
				},
				(exception: any) => {
					setLoaderState(false);
					props.setSnackBarProps((snackBarProps: any) => {
					  return {
						variant: "error",
						showSnackBar: true,
						messageInfo: exception,
					  };
					});
				}
			)}
		});		
	};

	const ReEnrollFutureStartFromNoStart = () => {	
		data.triggerValidation().then((validation: any) => {
			if (validation) {
				setLoaderState(true);	
				FutureStartApi.reEnrollFromNoStart(changeEnrollmentData, props.statusChange.statusCodeId).then(
				(response: any) => {
					setLoaderState(false);	
					handleSubmitResponse(response);
				},
				(exception: any) => {
					setLoaderState(false);	
					props.setSnackBarProps((snackBarProps: any) => {
					  return {
						variant: "error",
						showSnackBar: true,
						messageInfo: exception,
					  };
					});
				}
			)}
		});
	};

	const handleSubmitResponse = (response: any) => {
		if (response.data != undefined && response.data.resultStatus != undefined)
		{
			switch (response.data.resultStatus) {
				case ApiResultStatus.Success:
					props.setSnackBarProps((props: any) => {
						return {
						variant: "success",
						showSnackBar: true,
						messageInfo: response.data.resultStatusMessage
						};
					});	
					props.updateEvent(props.statusChange.statusDescription);
					props.newPageEvent("/student/1/profile/enrollments")				
					break;							
				case ApiResultStatus.Warning:
					props.setSnackBarProps((props: any) => {
						return {
						variant: "warning",
						showSnackBar: true,
						messageInfo: response.data.resultStatusMessage
						};
					});
					break;
				case ApiResultStatus.Error:
					props.setSnackBarProps((props: any) => {
						return {
						variant: "error",
						showSnackBar: true,
						messageInfo: response.data.resultStatusMessage
						};
					});
					break;
				default:
			}						
		}
		else {
			props.setSnackBarProps((props: any) => {
				return {
				variant: "error",
				showSnackBar: true,
				messageInfo: "Error creating re-enrollment."
				};
			});
		}
	}





	return (

	<Paper className={classes.paperRoot}>
		<Typography className={classes.cardTitle} >
			{getTitle()}
		</Typography>
		<CardContent className={classes.cardContent}>
			<Grid container direction="row" spacing={2}>
				<Grid item xs={12}>
					<Typography>
						{getMessage()}
					</Typography>					
				</Grid>
				<Grid item xs={12}>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="reEnrollmentDate"
                  label="ReEnroll Date"
                  fullWidth
				  autoOk={true}
				  required
                  KeyboardButtonProps={{
                    "aria-label": "renenrollment date"
                  }}
                  value={
                    changeEnrollmentData.reEnrollmentDate
                      ? new Date(changeEnrollmentData.reEnrollmentDate).toLocaleDateString()
                      : null
				  }
				  error={!!data.errors["reEnrollmentDate"]}
				  innerRef={(ref: any) => {
					data.register("reEnrollmentDate", { required: true });
					data.setValue("reEnrollmentDate", changeEnrollmentData.reEnrollmentDate);
				  }}			  
                  onChange={(val: any) => {
                    handleFieldOnChange("reEnrollmentDate", val);
                  }}
                />
              </MuiPickersUtilsProvider>
				</Grid>
				{ (!passed180Days && !awardsBlock)
					? <Grid item xs={12}>
						<div className={classes.actionContainer} id="reEnrollActions">
						<ProgressSaveButton
						text="Re-Enroll"
						onClick={() => {
							handleReEnroll();
						}}
						loading={loaderState}
						></ProgressSaveButton>
						<Button
							onClick={handleClose}
							color="secondary"
							variant="contained"
							className={classes.button}
						>Cancel</Button>
						</div>
						</Grid>
					: <Grid item xs={12}>
					<div className={classes.actionContainer} id="newEnrollActions">
						<ProgressSaveButton
						text="Yes"
						onClick={() => {
							handleNewEnroll();
						}}
						loading={loaderState}
						></ProgressSaveButton>
						<Button
							onClick={handleClose}
							color="secondary"
							variant="contained"
							className={classes.button}
						>No</Button>
						</div>
					</Grid>}
			</Grid>

		</CardContent>
	 </Paper>

	);
};
	
export default ReEnrollment;
