import React from 'react';
import {
  makeStyles,
  Card,
  createStyles,
  CardContent,
  Grid,
  CardActions,
  Paper,
  Table,
  withStyles,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  IconButton,
  CircularProgress,
  Popover,
  Typography,
  Button,
  TextField,
} from '@material-ui/core';
import CustomCardTitle from '../../../interfaces/common/card/CustomCardTitle';
import { useSelector } from 'react-redux';
import * as holidaysApi from "../../../api/setup/holiday/holidaysApi";
import CustomSnackbar from "../../../components/notifications/CustomSnackbar";
import { CustomSnackBarProps } from "../../../interfaces/common/CustomSnackBarProps";
import CampusGroupAutoComplete from "../../../components/AutoComplete/CampusGroupAutoComplete";
import TimeIntervalsAutoComplete from "../../../components/AutoComplete/TimeIntervalsAutoComplete";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormContext, useForm, FieldError } from "react-hook-form";
import StatusAutoComplete from "../../../components/AutoComplete/StatusAutoComplete";
import { FormControlLabel, Switch } from '@material-ui/core';
import ProgressSaveButton from "../../../components/_Layout/Buttons/ProgressSaveButton";
import { Holiday } from "../../../interfaces/setup/holiday/holiday";
import Search from "../../../../src/pages/student/grades/Search";
import AccessManager from "../../../components/security/AccessManager";
import { security } from "../../../constants/Security/securityConstants";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { string } from 'prop-types';


const StyledTableCell = withStyles((theme: any) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.site.secondary,
      fontSize: 17,
      color: theme.palette.black,
      fontWeight: 'bold',
      textAlign: 'center',
      boxShadow: '1px 1px 0px 0px rgba(0,0,0,0.2)',
      '&:first-child': {
        width: '42px',
        padding: theme.spacing(0, 1)
      }
    },
    body: {
      fontSize: 16,
      color: theme.palette.black,
      fontWeight: 500,
      cursor: 'pointer',
      textAlign: 'left',
      '&:first-child': {
        width: '42px',
        padding: theme.spacing(0, 1)
      }
    },
  }),
)(TableCell);


const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {},
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: 'bold',
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: '100%',
    },
    tableContainer: {
      height: '400px',
    },
    tableEmptyRow: {
      height: '350px',
      textAlign: 'center',
      fontSize: 20,
      opacity: 0.3,
    },
    table: {
      width: '100%',
    },
    iconColor: {
      color: theme.palette.black
    },
    searchField: {
      '&::placeholder': {
        color: '#000000'
      }
    },
    selectionCheckBox: {
      padding: theme.spacing(0)
    },
    preLoaderCell: {
      textAlign: 'center',
      padding: theme.spacing(1)
    },
    cardMain: {
      marginTop: theme.spacing(2)
    },
    popOverButtons: {
      display: "flex",
      justifyContent: "space-evenly",
      flexDirection: "row",
      padding: 5
    },
    typography: {
      padding: theme.spacing(2)
    },
    lineThru: {
      textDecoration: "line-through",
    },
    textField: {
      width: "100%",
    },
    switch: {
      display: 'flex',
      alignItems: 'flex-end',
      padding: theme.spacing(0, 0.5),
      paddingBottom: theme.spacing(0),
      height: theme.spacing(3),
    },
    dateField: {
      width: "100%",
    },
    paperRoot: {
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
    },
  })
);


const Holidays = (props: any) => {
  const classes = useStyles({});
  const data = useForm<any>({ mode: "onBlur" });
  const [holidays, setHolidays] = React.useState<Array<Holiday>>([]);
  const [loaderState, setLoaderState] = React.useState<boolean>(false);
  const [searchToolFilter, setSearchToolFilter] = React.useState<string | null>("");

  interface ListItem {
    value: number;
    text: string;
  }
  const optionList = useSelector((state: any) => state.ac_cache?.data?.statuses?.options ?? []) as ListItem[];
  const activeStatusId = optionList.find(x => x.text === 'Active')?.value.toString();
  const [statusFilter, setStatusFilter] = React.useState<string | null>(null);

  const [holidayIdDelete, setHolidayIdToDelete] = React.useState<string | null>(null);
  const [popOverAnchorEl, setPopOverAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const popOverOpen = Boolean(popOverAnchorEl);
  const popOverId = popOverOpen ? "simple-popover-holiday" : undefined;

  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );

  const defaultModel = {
    id: "",
    code: "",
    description: "",
    statusId: "",
    startDate: null,
    endDate: null,
    allDay: true,
    startTimeId: "",
    endTimeId: "",
    startTime: "",
    endTime: "",
    startTimeText: "",
    endTimeText: "",
    campusId: userSelectedCampus,
    campusGroupId: null,
    campusGroup: "",
  }
  const [model, setModel] = React.useState<Holiday>(defaultModel);

  const loadHolidays = () => {
    if (userSelectedCampus) {
      holidaysApi.getHolidayByCampus(userSelectedCampus).then(
        (response: any) => {
          if (response && response.data) {
            setHolidays(response.data);
          }
        },
        (exception: any) => { }
      );
    }
  }

  React.useEffect(() => {
    loadHolidays();
  }, [userSelectedCampus]);

  React.useEffect(() => {
    loadHolidays();
  }, [userSelectedCampus]);

  React.useEffect(() => {
    if (activeStatusId) {
      setStatusFilter(activeStatusId);
    }
  }, [activeStatusId]);

  const handelStatusFilter = (statusId : string) => {
    setStatusFilter(statusId);
  }

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    if (fieldId == "allDay" && value == true) {
      (updatedModel as any)["startTimeId"] = null;
      (updatedModel as any)["endTimeId"] = null;
    }
    setModel({ ...updatedModel });
  };

  const updateHoliday = () => {
    data.triggerValidation().then((validation: any) => {
      if (validation) {
        setLoaderState(true);
        model.campusId = userSelectedCampus;
        holidaysApi.UpdateHoliday(model).then(
          (response: any) => {
            if (response) {
              setHolidays(response?.result);
              setSnackBarProps((props: any) => {
                return {
                  variant: "success",
                  showSnackBar: true,
                  messageInfo: response.resultStatusMessage,
                };
              });
              resetDefault();
              setLoaderState(false);
            }
          },
          (exception: any) => {
            setSnackBarProps((props: any) => {
              return {
                variant: "error",
                showSnackBar: true,
                messageInfo: exception,
              };
            });
            setLoaderState(false);
          }
        );
      }
    });
  }

  const setRowToEdit = (holidayId: any) => {
    holidaysApi.GetHolidayById(holidayId).then(
      (response: any) => {
        if (response && response.data) {
          setModel(response.data)
        }
      },
      (exception: any) => {
        setSnackBarProps((props: any) => {
          return {
            variant: "error",
            showSnackBar: true,
            messageInfo: exception,
          };
        });
      }
    );
  }

  const handleClosePopOver = () => {
    setHolidayIdToDelete(null);
    setPopOverAnchorEl(null);
  };
  const handleClickPopOver = (event: any, holidayId: any) => {
    setHolidayIdToDelete(holidayId);
    setPopOverAnchorEl(event.currentTarget);
  };

  const handleCancel = () => {
    resetDefault();
  }

  const resetDefault = () => {
    setModel(defaultModel)
  }

  const handleDelete = () => {
    if (holidayIdDelete != null) {
      setLoaderState(true);
      holidaysApi.DeleteHoliday(holidayIdDelete, userSelectedCampus).then(
        (response: any) => {
          if (response) {
            setHolidays(response?.result);
            setSnackBarProps((props: any) => {
              return {
                variant: "success",
                showSnackBar: true,
                messageInfo: response.resultStatusMessage,
              };
            });
            resetDefault();
            setLoaderState(false);
          }
        },
        (exception: any) => {
          setSnackBarProps((props: any) => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo: exception,
            };
          });
          setLoaderState(false);
        }
      );
    }
    setHolidayIdToDelete(null);
    setPopOverAnchorEl(null);
  };

  return (
    <React.Fragment>
      <div className={classes.paperRoot}>
        <CustomSnackbar
          variant={snackBarProps.variant}
          message={snackBarProps.messageInfo}
          open={snackBarProps.showSnackBar}
          onClose={(event?: React.SyntheticEvent, reason?: string) => {
            setSnackBarProps((props: any) => {
              return { ...props, showSnackBar: false };
            });
          }}
        ></CustomSnackbar>
        <Card square={true} elevation={3} className={classes.cardMain}>
          <CustomCardTitle title='Set Up Holidays'></CustomCardTitle>
          <CardActions>
            <Grid container direction="row" spacing={4}>
              <Grid item xs={12} sm={12} md={3}>
                <CampusGroupAutoComplete
                  id="campusGroupId"
                  label="Campus Group *"
                  name="campusGroupId"
                  valueFilter={
                    model?.campusGroupId
                      ? {
                        key: "value",
                        values: [model?.campusGroupId],
                      }
                      : undefined
                  }
                  filterHandle={(v: any) => {
                    handleFieldOnChange("campusGroupId", v ? v.value : null);
                  }}
                  error={!!data.errors.campusGroupId}
                  inputRef={data.register({ required: true })}
                  helperText={
                    data.errors.campusGroupId ? "Campus group is required." : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <ThemeTextField
                  id="description"
                  name="description"
                  inputProps={{ maxLength: 50 }}
                  className={classes.textField}
                  label="Description *"
                  value={model.description ?? ""}
                  onChange={(v: any) => {
                    handleFieldOnChange(
                      "description",
                      v ? v.target.value : undefined
                    );
                  }}
                  error={!!data.errors.description}
                  inputRef={data.register({ required: true })}
                  helperText={
                    data.errors.description ? "Description is required." : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <ThemeTextField
                  id="code"
                  name="code"
                  className={classes.textField}
                  label="Code *"
                  inputProps={{ maxLength: 12 }}
                  value={model.code ?? ""}
                  onChange={(v: any) => {
                    handleFieldOnChange(
                      "code",
                      v ? v.target.value : undefined
                    );
                  }}
                  error={!!data.errors.code}
                  inputRef={data.register({ required: true })}
                  helperText={
                    data.errors.code ? "Code is required." : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <StatusAutoComplete
                  id="statusId"
                  name="statusId"
                  label="Status *"
                  valueFilter={
                    model.statusId
                      ? {
                        key: "value",
                        values: [model.statusId],
                      }
                      : undefined
                  }
                  filterHandle={(v: any) => {
                    handleFieldOnChange("statusId", v ? v.value : null);
                  }}
                  error={!!data.errors.statusId}
                  inputRef={data.register({ required: true })}
                  helperText={
                    data.errors.statusId ? "Status is required." : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    id="startDate"
                    name="startDate"
                    label="Start Date *"
                    value={model.startDate ? model.startDate : null}
                    onChange={(e: any, value: any) =>
                      handleFieldOnChange("startDate", value ? value : undefined)
                    }
                    className={classes.dateField}
                    KeyboardButtonProps={{
                      "aria-label": "start date",
                    }}
                    error={!!data.errors["startDate"]}
                    innerRef={(ref: any) => {
                      data.register("startDate", {
                        validate: {
                          required: (value: Date) =>
                            (value != null) ||
                            "Start date is required.",
                          beforeEndDate: (value) =>
                            new Date(value) <= new Date(data.getValues()["endDate"]) ||
                            "Start Date must be before end date",
                        },
                      });
                      data.setValue("startDate", model.startDate);
                    }}
                    helperText={
                      data.errors["startDate"]
                        ? (data.errors["startDate"] as FieldError).message
                        : undefined
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    id="endDate"
                    name="endDate"
                    label="End Date *"
                    value={model.endDate ? model.endDate : null}
                    onChange={(e: any, value: any) =>
                      handleFieldOnChange("endDate", value ? value : null)
                    }
                    className={classes.dateField}
                    KeyboardButtonProps={{
                      "aria-label": "end date",
                    }}
                    error={!!data.errors["endDate"]}
                    innerRef={(ref: any) => {
                      data.register("endDate", {
                        validate: {
                          required: (value: Date) =>
                            (value != null) ||
                            "End date is required.",
                          beforeEndDate: (value) =>
                            new Date(value) >= new Date(data.getValues()["startDate"]) ||
                            "End Date must be after Start date",
                        },
                      });
                      data.setValue("endDate", model.endDate);
                    }}
                    helperText={
                      data.errors["endDate"]
                        ? (data.errors["endDate"] as FieldError).message
                        : undefined
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TimeIntervalsAutoComplete
                  id="startTimeId"
                  label={model.allDay == false ? "Start Time *" : "Start Time"}
                  name="startTimeId"
                  disabled={model.allDay == false ? false : true}
                  valueFilter={
                    model.startTimeId
                      ? {
                        key: "value",
                        values: [model.startTimeId],
                      }
                      : undefined
                  }
                  filterHandle={(v: any) => {
                    handleFieldOnChange("startTimeId", v ? v.value : null);
                  }}
                  error={!!data.errors.startTimeId}
                  inputRef={data.register({ required: model.allDay == false ? true : false })}
                  helperText={
                    data.errors.startTimeId ? "Start time is required." : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TimeIntervalsAutoComplete
                  id="endTimeId"
                  label={model.allDay == false ? "End Time *" : "End Time"}
                  name="endTimeId"
                  disabled={model.allDay == false ? false : true}
                  valueFilter={
                    model.endTimeId
                      ? {
                        key: "value",
                        values: [model.endTimeId],
                      }
                      : undefined
                  }
                  filterHandle={(v: any) => {
                    handleFieldOnChange("endTimeId", v ? v.value : null);
                  }}
                  error={!!data.errors.endTimeId}
                  inputRef={data.register({ required: model.allDay == false ? true : false })}
                  helperText={
                    data.errors.endTimeId ? "End time is required." : undefined
                  }
                />
              </Grid>
              <Grid item md={3} sm={12} xs={12} justify="flex-start">
                <FormControlLabel
                  id="lblAllDay"
                  className={classes.switch}
                  labelPlacement="end"
                  label="All Day"
                  control={
                    <Switch
                      checked={model.allDay}
                      name="allDay"
                      onChange={(e: any) => {
                        handleFieldOnChange("allDay", e ? e.target.checked : false);
                      }}
                      color="primary"
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container direction="row" spacing={1}>
                  <Grid
                    item
                    alignContent="flex-start"
                    alignItems="flex-start"
                    justify="flex-start"
                  >
                    <ProgressSaveButton
                      text="Save"
                      onClick={updateHoliday}
                      loading={loaderState}
                      disabled={false}
                    ></ProgressSaveButton>

                  </Grid>
                  <Grid
                    item
                    alignContent="flex-start"
                    alignItems="flex-start"
                    justify="flex-start"
                  >
                    <Button
                      onClick={handleCancel}
                      color="secondary"
                      variant="contained"
                      type="button"
                    >
                      New
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </CardActions>
          <CardContent>
            <Grid container direction="column" spacing={2}>
              <Grid
                item
                alignContent="flex-start"
                alignItems="flex-start"
                justify="flex-start"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Card>
                    <CardContent>
                      <Grid container direction="row" spacing={4}>
                        <Grid item xs={12} sm={12} md={8}>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2}>
                          <StatusAutoComplete
                            id="statusId1"
                            name="statusId1"
                            includeAllValue={true}
                            valueFilter={
                              statusFilter
                                ? {
                                  key: "value",
                                  values: [statusFilter],
                                }
                                : undefined
                            }
                            filterHandle={(v: any) => {
                              handelStatusFilter(v ? v.value : null);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2}>
                          <Search
                            label={"Search Text"}
                            value={searchToolFilter}
                            onChange={(e: any) => {
                              setSearchToolFilter(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <TableContainer component={Paper} square className={classes.tableContainer}>
                  <Table stickyHeader size='small' className={classes.table} aria-label='customized table'>

                    <TableHead>
                      <TableRow>
                        <TableCell>Campus Group</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Code</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Times</TableCell>
                        <TableCell>Edit</TableCell>
                        <TableCell>Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loaderState ?
                        <TableRow>
                          <StyledTableCell colSpan={8} className={classes.preLoaderCell}>
                            <CircularProgress />
                          </StyledTableCell>
                        </TableRow>
                        : holidays?.filter(h => (h.description.toLowerCase().includes((searchToolFilter ?? "").toLowerCase())
                          || h.code.toLowerCase().includes((searchToolFilter ?? "").toLowerCase())
                          || (h.campusGroup ?? "").toLowerCase().includes((searchToolFilter ?? "").toLowerCase())) && ((statusFilter == null || statusFilter == "All") || statusFilter == h.statusId)
                        ).map((row: Holiday, index: any) => (
                          <TableRow>
                            <TableCell>{row.campusGroup}</TableCell>
                            <TableCell>{row.description}</TableCell>
                            <TableCell>{row.code}</TableCell>
                            <TableCell>{new Date(row.startDate ?? new Date()).toLocaleDateString()}</TableCell>
                            <TableCell>{new Date(row.endDate ?? new Date()).toLocaleDateString()}</TableCell>
                            <TableCell>
                              {
                                row.allDay ? "All Day" : row.startTimeText + " - " + row.endTimeText
                              }
                            </TableCell>
                            <TableCell>
                              <AccessManager
                                allowedPermissions={[security.permissions.setupHoliday.updateSetUpHolidays]}
                                renderNoAccess={() => <IconButton disabled={true} key={`editButton-${row.id}`}>
                                  <EditIcon key={`editIcon-${row.id}`} />
                                </IconButton>}
                              >
                                <IconButton key={`editButton-${row.id}`} onClick={() => { setRowToEdit(row.id) }}>
                                  <EditIcon key={`editIcon-${row.id}`} />
                                </IconButton>
                              </AccessManager>

                            </TableCell>
                            <TableCell>
                              <AccessManager
                                allowedPermissions={[security.permissions.setupHoliday.deleteSetUpHolidays]}
                                renderNoAccess={() => <IconButton disabled={true} key={`editButton-${row.id}`}>
                                  <DeleteIcon key={`archiveIcon-${row.id}`} />
                                </IconButton>}
                              >
                                <IconButton key={`archiveButton-${row.id}`} onClick={(e: any) => handleClickPopOver(e, row.id)}>
                                  <DeleteIcon key={`archiveIcon-${row.id}`} />
                                </IconButton>
                              </AccessManager>

                            </TableCell>
                          </TableRow>
                        ))}

                      {
                        holidays?.length == 0 ?
                          <TableRow>
                            <TableCell className={classes.tableEmptyRow} colSpan={8}>No Data</TableCell>
                          </TableRow>
                          : null
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </CardContent>
          <Popover
            id={popOverId}
            open={popOverOpen}
            anchorEl={popOverAnchorEl}
            onClose={handleClosePopOver}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <Typography
              color="textSecondary"
              className={classes.typography}
              variant="body1"
            >
              Are you sure you want to delete this holiday?
            </Typography>
            <div className={classes.popOverButtons}>
              <Button
                variant="contained"
                size="small"
                onClick={() => handleDelete()}
                color="primary"
              >
                Yes
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={handleClosePopOver}
                color="secondary"
              >
                Cancel
              </Button>
            </div>
          </Popover>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Holidays;
