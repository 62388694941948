import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CampusForCurrentUserAutoComplete from "../../../components/AutoComplete/CampusForCurrentUserAutoComplete";
import { FormControlLabel, Switch, Grid } from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import { IAttendaceHistoryMultiple } from "../../../interfaces/reports/attendance/IAttendaceHistoryMultiple";
import { generateReport } from "../../../api/reports/financialAids/LedgerBalanceReportAPI";
import { ReportOutput } from "../../../enums/ReportOutput";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import ProgramVersionAutoComplete from "../../../components/AutoComplete/ProgramVersionsMCAutoComplete";
import StudentGroupsAutoComplete from "../../../components/AutoComplete/StudentGroupsAutoComplete";
import StudentNameBadgeIDFilter from "../../../../src/pages/attendance/StudentNameBadgeIDFilter";
import ILedgerBalanceReport from "../../../interfaces/reports/ILedgerBalanceReport";
import FundSourcesAutoCompleteMultiCampus from "../../../components/AutoComplete/FundSourcesAutoCompleteMultiCampus";
import { mapValues } from "lodash";
import EnrollmentStatusAutoComplete from "../../../components/AutoComplete/EnrollmentStatusAutoComplete";
import PaymentPeriodAutoComplete from "../../../components/AutoComplete/PaymentPeriodAutoComplete";
import PaymentPeriodNumberAutoComplete from "../../../components/AutoComplete/PaymentPeriodNumberAutoComplete";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      minHeight: "100%",
      height: "100%",
      maxWidth: "1050px",
      padding: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    paperRoot: {
      padding: theme.spacing(3, 2),
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
    },
    searchActions: {
      marginTop: theme.spacing(3),
      textAlign: "left",
    },
    startDateField: {
      width: "100%",
    },
    cardTitle: {
      fontSize: 17,
      color: theme.palette.primary.headerText,
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
    switch: {
      display: "flex",
      alignItems: "flex-end",
      padding: theme.spacing(0, 0.5),
      height: theme.spacing(8.6),
    },
  })
);

const AttendanceHistoryMultiple = (props: any) => {
  const { report } = props;
  let reportOverview = { report: report } as IReportOverview;
  const classes = useStyles({});
  const [preview, setPreview] = React.useState<any>();
  const [previewLoader, setPreviewLoader] = React.useState<boolean>();

  const data = useForm<any>({ mode: "onBlur" });

  const [model, setModel] = React.useState({
    campusId: [] as Array<string>,
    //asOf: undefined as Date | undefined,
    asOf: new Date() as Date | undefined,
    exportType: 1 as ReportOutput,
    programVersions: [] as Array<string>,
    fundSources: [] as Array<string>,
    enrollmentStatuses: [] as Array<string>,
    showBalance: false as boolean,
    showTitleIV: false as boolean,
    leadGroupIds: [] as Array<string>,
    paymentPeriod: "",
  });

  const setStudentGroup = (studentGroups: any) => {
    let newGroups: string[] = [];
    if (studentGroups !== null) {
      if (Array.isArray(studentGroups)) {
        newGroups = studentGroups.map((gr: any) => gr.value);
      } else {
        newGroups.push(studentGroups.value);
      }
    }
    return newGroups;
  };

  const [inActiveGroup, setInActiveGroup] = React.useState<boolean>(false);

  const handleChange =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      switch (name) {
        case "inActiveGroup":
          setInActiveGroup(event.target.checked);
          break;
      }
    };

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: (values: any, key: string) => Array<string>
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value, "value") : value;
    (updatedModel as any)[fieldId] = newValue;
    if (fieldId == "campusId") {
      (updatedModel as any)["programVersions"] = [];
      (updatedModel as any)["fundSources"] = [];
    }
    setModel({ ...updatedModel });
  };

  const { handleSubmit } = data;

  const exportTypes: Array<DropDownListItem> = [
    { text: "PDF", id: "1" },
    { text: "Excel", id: "2" },
    { text: "Word", id: "3" },
  ];

  const onSubmit = async (isPreview: any) => {
    let params = (await getReportParameters()) as ILedgerBalanceReport;
    const validation = await data.triggerValidation();
    if (validation) {
      console.log(params); //
      console.log("TestHERE");
      if (isPreview) {
        setPreviewLoader(true);
        generateReport(params, isPreview).then((res: any) => {
          if (res) {
            res.arrayBuffer().then((buffer: any) => {
              setPreview(buffer);
              setPreviewLoader(false);
            });
          } else setPreviewLoader(false);
        });
      } else await generateReport(params);
    }
  };

  const exportTypeAutoComplete = {
    options: exportTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  // const exportTypeAutoComplete2 = {
  //   options: exportTypes2,
  //   getOptionLabel: (option: DropDownListItem) => option.text,
  // };

  const filterHandler = (data: any) => {
    if (data) {
      setModel(data);
    } else {
      setModel({
        campusId: [],
        asOf: new Date() as Date | undefined,
        exportType: 1 as ReportOutput,
        programVersions: [],
        enrollmentStatuses: [],
        fundSources: [],
        showBalance: model.showBalance,
        showTitleIV: model.showTitleIV,
        leadGroupIds: model.leadGroupIds,
        paymentPeriod: "",
      });
    }
  };
  const setArrayValues = (values: any, key: string) => {
    let newValues: string[] = [];
    if (values !== null) {
      if (Array.isArray(values)) {
        newValues = values.map((gr: any) => gr[key]);
      } else {
        newValues.push(values.value);
      }
    }
    return newValues;
  };
  const getReportParameters = async () => {
    const success = await data.triggerValidation();
    if (model && success) {
      let asOf = model.asOf ? model.asOf : undefined;
      let params: ILedgerBalanceReport = {
        campusId: model.campusId,
        asOf: asOf,
        exportType: model?.exportType,
        programVersions: model.programVersions,
        enrollmentStatuses: model.enrollmentStatuses,
        fundSources: model.fundSources,
        showBalance: model.showBalance,
        showTitleIV: model.showTitleIV,
        leadGroupIds: model.leadGroupIds,
        paymentPeriod: model.paymentPeriod,
      };
      return params;
    }
    return {} as ILedgerBalanceReport;
  };

  const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
    const item = items.find((opt) => {
      if (opt.id == value) return opt;
    });
    return item || ({} as DropDownListItem);
  };

  let parameters = (
    <div className={classes.root}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} sm={6} xs={12}>
              <CampusForCurrentUserAutoComplete
                id="campusId"
                name="campusId"
                label="Campus(es) *"
                multiple
                includeAllValue={true}
                showIncludeAllValue={true}
                filterHandle={(v: any) => {
                  handleFieldOnChange("campusId", v, setArrayValues);
                }}
                valueFilter={
                  model.campusId
                    ? {
                        key: "value",
                        values: model.campusId,
                      }
                    : undefined
                }
                error={!!data.errors.campusId}
                inputRef={data.register({
                  validate: (_value) =>
                    model.campusId && model.campusId.length > 0 ? true : false,
                })}
                helperText={
                  data.errors.campusId ? "Campus is required." : undefined
                }
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Autocomplete
                {...exportTypeAutoComplete}
                autoComplete
                disableClearable
                includeInputInList
                onChange={(e: any, value: any) => {
                  handleFieldOnChange(
                    "exportType",
                    value ? value : undefined,
                    (v: any) => v?.id
                  );
                }}
                value={getSelectedItem(
                  exportTypes,
                  model.exportType.toString()
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Export Type"
                    name="exportType"
                    error={!!data.errors.exportType}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.exportType
                        ? "Export Type is required."
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="asOf"
                  name="asOf"
                  label="As Of *"
                  value={model.asOf ? model.asOf : new Date()}
                  onChange={(e: any, value: any) =>
                    handleFieldOnChange("asOf", value ? value : undefined)
                  }
                  className={classes.startDateField}
                  KeyboardButtonProps={{
                    "aria-label": "report date",
                  }}
                  error={!!data.errors["asOf"]}
                  innerRef={(ref: any) => {
                    data.register("asOf", {
                      validate: (_val) =>
                        model.asOf ? true : "Date is required",
                    });
                  }}
                  helperText={
                    data.errors["asOf"]
                      ? (data.errors["asOf"] as FieldError).message
                      : undefined
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <ProgramVersionAutoComplete
                showIncludeAllValue={
                  model.campusId && model.campusId.length > 0
                }
                includeAllValue={model.campusId && model.campusId.length > 0}
                chipSize="small"
                maxTags={2}
                label="Program Version(s)"
                name="programVersions"
                campuses={model.campusId}
                filterHandle={(value: any) =>
                  handleFieldOnChange("programVersions", value, setArrayValues)
                }
                valueFilter={
                  model.programVersions
                    ? {
                        key: "value",
                        values: model.programVersions,
                      }
                    : undefined
                }
                multiple={true}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <EnrollmentStatusAutoComplete
                id="enrollmentStatuses"
                name="enrollmentStatuses"
                chipSize="small"
                maxTags={2}
                multiple={true}
                filterHandle={(statuses: any) => {
                  handleFieldOnChange(
                    "enrollmentStatuses",
                    statuses,
                    setArrayValues
                  );
                }}
                label="Enrollment Status(es)"
                valueFilter={
                  model?.enrollmentStatuses
                    ? {
                        key: "value",
                        values: model?.enrollmentStatuses,
                      }
                    : undefined
                }
              />
            </Grid>
            <Grid item sm={8} md={8}>
              <StudentGroupsAutoComplete
                id="leadGroupIds"
                name="leadGroupIds"
                filterHandle={(groups: any) => {
                  handleFieldOnChange("leadGroupIds", groups, setStudentGroup);
                }}
                label="Student Group"
                params={{
                  inActiveStudentGroup: inActiveGroup,
                }}
                multiple={true}
                valueFilter={
                  model?.leadGroupIds
                    ? { key: "value", values: model?.leadGroupIds }
                    : undefined
                }
              />
            </Grid>
            <Grid item md={3} sm={3} xs={12} className={classes.switch}>
              <FormControlLabel
                label="Show Only Credit Balance"
                control={
                  <Switch
                    checked={model.showBalance}
                    value="showBalance"
                    onChange={(e: any) => {
                      setModel({ ...model, showBalance: e.target.checked });
                    }}
                    color="primary"
                  />
                }
              />
            </Grid>
            <Grid item md={3} sm={3} xs={12} className={classes.switch}>
              <FormControlLabel
                label="Show Title IV Credit Balance Only"
                control={
                  <Switch
                    checked={model.showTitleIV}
                    value="showTitleIV"
                    onChange={(e: any) => {
                      setModel({ ...model, showTitleIV: e.target.checked });
                    }}
                    color="primary"
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <PaymentPeriodNumberAutoComplete
                id="paymentPeriod"
                name="paymentPeriod"
                label="Payment Period"
                valueFilter={
                  model.paymentPeriod
                    ? {
                        key: "value",
                        values: [model.paymentPeriod],
                      }
                    : undefined
                }
                error={!!data.errors.paymentPeriod}
                inputRef={data.register({ required: false })}
                filterHandle={(v: any) => {
                  handleFieldOnChange("paymentPeriod", v ? v.value : undefined);
                }}
              />
            </Grid>
          </Grid>
        </form>
      </FormContext>
    </div>
  );
  reportOverview.parameters = parameters;

  return (
    <ReportOverview
      reportOverview={reportOverview}
      filterHandler={filterHandler}
      getReportParameters={getReportParameters}
      exportHandler={onSubmit}
      preview={preview}
      previewLoader={previewLoader}
    />
  );
};
export default AttendanceHistoryMultiple;
