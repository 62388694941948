export interface IProgramVersionAcademicDetails {
  gradeSystemId: string;
  gradeScaleId?: string;
  minimumGradeRequired: string;
  sapPolicyId?: string;
  fasapPolicyId?: string;
  degreeId?: string;
  doesGradeClinicServices?: boolean;
  overallAverageBy?:string | null;
}

export class ProgramVersionAcademicDetails
  implements IProgramVersionAcademicDetails {
  gradeSystemId: string = "";
  gradeScaleId?: string = "";
  minimumGradeRequired: string = "";
  sapPolicyId?: string = "";
  fasapPolicyId?: string = "";
  degreeId?: string = "";
  doesGradeClinicServices?: boolean = false;
  overallAverageBy?: string | null | undefined = 'Subject';
}
