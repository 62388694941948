import API from "../../apiWrapper";
import { IStatusChangeEnrollment } from "../../../interfaces/student/status/IStatusChangeEnrollment";

export const Graduate = (
  changeEnrollment: IStatusChangeEnrollment,
  newStatusCode: string
) => {

  return API()
    .post("/AcademicRecords/StatusChanges/Graduation/Graduate", changeEnrollment, {
      params: {
        newStatusCode
      }
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );;
};
export const CheckStudentMeetingRequirements = (
  enrollmentId: IStatusChangeEnrollment
) => {

  return API()
    .get("/AcademicRecords/StudentSummary/CheckStudentMeetingRequirements", {
      params: {
        enrollmentId
      }
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );;
};
