import { StudentProfile } from "../../../interfaces/student/profile/StudentProfile";
import {
  Enrollment,
  TitleIVSapStatus,
} from "../../../interfaces/student/academics/Enrollment";
import { Action } from "redux";
import * as actionTypes from "../../../constants/actions.constants";

const initialState: StudentProfile = {
  studentName: "",
  studentNumber: "",
  studentId: "",
  leadId: "",
  currentStatus: "",
  currentProgramVersion: "",
  avatar: "",
  campus: "",
  startDate: "",
  lastDateOfAttendance: "",
  selectedEnrollment: undefined,
  firstName: "",
  lastName: "",
  afaStudentId: "",
  profilePhoto: undefined,
  enrollments: [
    {
      name: "",
      studentId: "",
      stuEnrollId: "",
      status: "",
      programVersionDescription: "",
      percentCompleted: 0,
      sapStatus: null,
      campusId: "",
      startDate: "",
      currentStatusStart: null,
      currentStatusEnd: null,
    } as Enrollment,
  ],
};

const studentProfileReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case actionTypes.studentProfileConstants.SESSION_SET_STUDENT_PROFILE: {
      let sP = (action as any).studentProfile as StudentProfile;
      if (sP.enrollments !== undefined) {
        if (sP.enrollments.length > 0) {
          sP.selectedEnrollment = sP.enrollments[0];
        }
      }
      return sP;
    }
    case actionTypes.studentProfileConstants.SESSION_SET_SELECTED_ENROLLMENT: {
      let sE = (action as any).selectedEnrollment as Enrollment;
      return { ...state, selectedEnrollment: sE };
    }
    default: {
      return state;
    }
  }
};

export default studentProfileReducer;
