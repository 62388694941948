import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import { ReportOutput } from "../../../enums/ReportOutput";
import { INetPriceCalculatorParams } from "../../../interfaces/reports/studentAccounts/INetPriceCalculatorParams";
import * as NetPriceCalculatorApi from "../../../api/reports/studentAccounts/netPriceCalculatorApi";
import { useSelector } from "react-redux";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import AwardYearAutoComplete from "../../../components/AutoComplete/AwardYearAutoComplete";
import { EmptyGuid } from "../../../../src/utils/constants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			paddingTop: theme.spacing(2),
			minHeight: "100%",
			height: "100%",
			maxWidth: theme.spacing(131.25),
			margin: theme.spacing(2),
		},
		"& .makeStyles-content": {
			backgroundColor: theme.palette.background.paper,
		},
		paperRoot: {
			padding: theme.spacing(3, 2),
			width: "100%",
			minHeight: "100%",
			height: "100%",
			overflowX: "hidden",
			overflowY: "auto",
			flexDirection: "column",
			backgroundColor: theme.palette.background.paper,
		},
		width100: {
			width: "100%",
		},
		cardTitle: {
			fontSize: theme.spacing(2.15),
			color: theme.palette.primary.headerText,
			textDecoration: "bold",
			backgroundColor: theme.palette.secondary.dark,
			padding: theme.spacing(1, 1),
			marginBottom: theme.spacing(1),
		},
		headerText: {
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(0),
		},
		cardContent: {
			padding: theme.spacing(1, 3),
		},
		switch: {
			display: "flex",
			alignItems: "flex-end",
			padding: theme.spacing(0, 0.5),
			height: theme.spacing(6.6),
		},
		fieldSet: {
			marginTop: theme.spacing(2),
		},
	})
);

const NetPriceCalculatorReport = (props: any) => {
	const classes = useStyles({});
	const { report } = props;
	let reportOverview = { report: report } as IReportOverview;

	const [preview, setPreview] = React.useState<any>();
	const [previewLoader, setPreviewLoader] = React.useState<boolean>();
	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const defaultFilters = {
		month: undefined as Date | undefined,
		reportDate: new Date() as Date | undefined,
		exportType: 1 as ReportOutput,
		sortOrder: "name" as string | undefined,
	};
	const [model, setModel] = React.useState<any>(defaultFilters);
	const exportTypes: Array<DropDownListItem> = [
		{ text: "PDF", id: "1" },
		{ text: "Excel", id: "2" },
		{ text: "Word", id: "3" },
	];

	const exportTypeAutoComplete = {
		options: exportTypes,
		getOptionLabel: (option: DropDownListItem) => option.text,
	};

	const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
		const item = items.find((opt) => {
			if (opt.id == value) return opt;
		});
		return item || ({} as DropDownListItem);
	};

	const handleFieldOnChange = (
		fieldId: string,
		value: any,
		mapFunction?: Function
	) => {
		let updatedModel = model;
		let newValue = mapFunction ? mapFunction(value) : value;
		(updatedModel as any)[fieldId] = newValue;
		setModel({ ...updatedModel });
	};
	const data = useForm<any>({ mode: "onBlur" });
	const { handleSubmit } = data;
	const onSubmit = async (isPreview: any): Promise<void> => {
		let params = (await getReportParameters()) as INetPriceCalculatorParams;
		const validation = await data.triggerValidation();

		if (validation) {
			if (isPreview) {
				setPreviewLoader(true);
				NetPriceCalculatorApi.getNetPriceCalculatorReport(
					params,
					isPreview
				).then((res: any) => {
					if (res) {
						res.arrayBuffer().then((buffer: any) => {
							setPreview(buffer);
							setPreviewLoader(false);
						});
					} else setPreviewLoader(false);
				});
			} else await NetPriceCalculatorApi.getNetPriceCalculatorReport(params);
		}
	};

	const filterHandler = (data: any) => {
		if (data) {
			setModel({ ...data });
		} else {
			setModel(defaultFilters);
		}
	};
	const getReportParameters = async () => {
		const success = await data.triggerValidation();
		if (model && success) {
			let params: INetPriceCalculatorParams = {
				academicYearId: model?.academicYearId,
				exportType: model?.exportType,
				campusId: userSelectedCampus,
			};
			return params;
		}
		return {} as INetPriceCalculatorParams;
	};

	let parameters = (
		<div className={[classes.root, classes.width100].join(" ")}>
			<FormContext {...data}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid
						container
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						spacing={1}
					>
						<React.Fragment>
							<Grid item xs={6} sm={6} md={3}>
								<AwardYearAutoComplete
									name="academicYearId"
									id="academicYearId"
									label="Award Year *"
									valueFilter={
										props.selectedAward &&
										props.selectedAward?.academicYearId !== EmptyGuid
											? {
													key: "value",
													values: [props.selectedAward?.academicYearId],
											  }
											: undefined
									}
									filterHandle={(v: any) => {
										handleFieldOnChange("academicYearId", v ? v.value : null);
									}}
									error={!!data.errors.academicYearId}
									inputRef={data.register({ required: true })}
									helperText={
										data.errors.academicYearId
											? "Award Year is required."
											: undefined
									}
								/>
							</Grid>
							<Grid item md={6} sm={6} xs={12}>
								<Autocomplete
									{...exportTypeAutoComplete}
									autoComplete
									includeInputInList
									onChange={(e: any, value: any) => {
										handleFieldOnChange(
											"exportType",
											value ? value : undefined,
											(v: any) => v?.id
										);
									}}
									value={getSelectedItem(exportTypes, model.exportType)}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Export Type *"
											name="exportType"
											error={!!data.errors.exportType}
											inputRef={data.register({ required: true })}
											helperText={
												data.errors.exportType
													? "Export Type is required."
													: undefined
											}
											fullWidth
										/>
									)}
								/>
							</Grid>
						</React.Fragment>
					</Grid>
				</form>
			</FormContext>
		</div>
	);
	reportOverview.parameters = parameters;

	return (
		<ReportOverview
			reportOverview={reportOverview}
			filterHandler={filterHandler}
			getReportParameters={getReportParameters}
			exportHandler={onSubmit}
			preview={preview}
			previewLoader={previewLoader}
		/>
	);
};

export default NetPriceCalculatorReport;
