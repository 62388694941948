import React from 'react';
import {
	Checkbox,
	Typography,
	CardContent,
	Card,
	IconButton,
	Grid,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import AccessManager from '../../components/security/AccessManager';
import { security } from '../../constants/Security/securityConstants';
import RefreshIcon from '@material-ui/icons/Refresh';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import makeStyles from './WidgetStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import PaymentPlanDue from './PaymentPlanDue';
import PaymentPlanPastDue from './PaymentPlanPastDue';
import * as PaymentPlanApi from '../../api/student/financials/paymentPlan/paymentPlanApi';
import PaymentPlanWidgetSettings from './PaymentPlanWidgetSettings';

const useStyles = makeStyles;

function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`ppw-tabpanel-${index}`}
			{...other}
		>
			{value === index && (
				<div>
					<Typography>{children}</Typography>
				</div>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function tabProps(index: any) {
	return {
		id: `ppw-tab-${index}`,
		'aria-controls': `ppw-tabpanel-${index}`,
	};
}

function PaymentPlanWidget() {
	const classes = useStyles();
	const [PastDueData, setPastDueData] = React.useState([] as any);
	const [DueData, setDueData] = React.useState([] as any);
	const [value, setValue] = React.useState(0);
	const [settingsModelState, setSettingsModelState] = React.useState(false);
	const [selectAll, setSelectAll] = React.useState(false);

	const handleChange = (event: any, newValue: any) => {
		setValue(newValue);
	};

	const campusId = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	React.useEffect(() => {}, [DueData, PastDueData, value]);

	const resetPage = () => {
		setPastDueData([]);
		setDueData([]);
	};

	React.useEffect(() => {
		if (campusId) {
			resetPage();
			LoadPaymentPlanData(campusId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [campusId, selectAll]);

	const handelReloadPage = () => {
		resetPage();
		LoadPaymentPlanData(campusId);
	};

	const selectAllClick = (event: any) => {
		setSelectAll(event.target.checked);
	};

	const LoadPaymentPlanData = (campusId: any) => {
		PaymentPlanApi.GetPaymentPlanWidgetData(campusId, null, selectAll).then(
			(response: any) => {
				if (response && response?.data !== null) {
					var pastDueData = response.data?.filter(
            (s: any) => s.isPastDue === true
          );
					var dueData = response.data?.filter(
            (s: any) => s.isPastDue === false
          );
					setPastDueData(pastDueData);
					setDueData(dueData);
				}
			},
			(_exception: any) => {}
		);
	};

	const openSettingsModel = () => {
		setSettingsModelState(true);
	};

	return (
		<AccessManager
			allowedPermissions={[
				security.permissions.dashboard.viewPaymentPlanWidget,
			]}
			renderNoAccess={() => null}
		>
			<Card className={classes.root}>
				<Typography className={clsx(classes.cardTitle)}>
					<Grid container direction="row" justify="center" alignItems="center">
						<Grid item xs={6}>
							<div className={classes.headerText}>Payment Plans</div>
						</Grid>
						<Grid item xs={6} className={classes.widgetMenu}>
							<Typography className={classes.headerTextRight}></Typography>
							<IconButton
								className={classes.iconBtn}
								aria-label="settings"
								onClick={handelReloadPage}
							>
								<RefreshIcon />
							</IconButton>
							<IconButton
								className={classes.iconBtn}
								aria-label="settings"
								onClick={openSettingsModel}
							>
								<MoreVertIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Typography>
				<CardContent className={classes.cardContent}>
					<div className={classes.innerRoot}>
						<Typography
							className={classes.headerTextRight}
							style={{ float: 'right' }}
						>
              Show All
							<Checkbox
								value="uncontrolled"
								color="primary"
								onClick={selectAllClick}
								inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
								checked={selectAll}
							/>
						</Typography>
						<CardContent className={classes.cardContent}>
							<Tabs
								value={value}
								onChange={handleChange}
								className={classes.tabStyle}
								indicatorColor="primary"
							>
								<Tab
									label={'PAST DUE (' + PastDueData?.length + ')'}
									{...tabProps(0)}
								/>
								<Tab label={'DUE (' + DueData?.length + ')'} {...tabProps(1)} />
							</Tabs>
							<TabPanel value={value} index={0}>
								<PaymentPlanPastDue
									data={PastDueData}
									reload={handelReloadPage}
								/>
							</TabPanel>
							<TabPanel value={value} index={1}>
								<PaymentPlanDue data={DueData} reload={handelReloadPage} />
							</TabPanel>
						</CardContent>
					</div>
				</CardContent>
			</Card>
			<PaymentPlanWidgetSettings
				open={settingsModelState}
				setOpen={setSettingsModelState}
				reload={handelReloadPage}
			/>
		</AccessManager>
	);
}
export default PaymentPlanWidget;
