import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CampusForCurrentUserAutoComplete from "../../../components/AutoComplete/CampusForCurrentUserAutoComplete";
import {
    FormControlLabel, Switch, Grid, Paper, Typography,
    CardContent,
    Card,
    Button
} from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import * as dataExport9010Api from "../../../api/reports/financialAids/ExportData9010API";
import { ReportOutput } from "../../../enums/ReportOutput";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import ProgramVersionAutoComplete from "../../../components/AutoComplete/ProgramVersionAutoComplete";
import StudentGroupsAutoComplete from "../../../components/AutoComplete/StudentGroupsAutoComplete";
import StudentNameBadgeIDFilter from '../../attendance/StudentNameBadgeIDFilter'
import { EnrollmentAutoComplete } from "../../../components/AutoComplete/EnrollmentAutoComplete";
import { IEnrollmentSearchResult } from "../../../interfaces/student/enrollment/EnrollmentSearchResult";
import { IAttendanceSummaryReportParam } from "../../../interfaces/reports/attendance/AttendanceSummaryReportParam";
import { useSelector } from "react-redux";
import clsx from "clsx";
import MultiCampusAutoComplete from "../../../components/AutoComplete/MultiCampusAutoComplete";
import { IDataExport9010 } from "../../../interfaces/academicRecords/IDataExport9010";
import ProgramAutoComplete from "../../../components/AutoComplete/ProgramAutoComplete";
import StudentGroupsMultiCampusAutoComplete from "../../../components/AutoComplete/StudentGroupsMultiCampus";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(3),
            minHeight: "100%",
            height: "100%",
            maxWidth: "1050px",
            margin: "auto"
        },
        "& .makeStyles-content": {
            backgroundColor: theme.palette.background.paper
        },
        paperRoot: {
            padding: theme.spacing(3, 2),
            width: "100%",
            minHeight: "100%",
            height: "100%",
            overflowX: "hidden",
            overflowY: "auto",
            flexDirection: "column",
            backgroundColor: theme.palette.background.paper
        },
        startDateField: {
            width: "100%",
        },
        cardTitle: {
            fontSize: 17,
            color: "Black",
            textDecoration: "bold",
            backgroundColor: theme.palette.site.secondary,
            padding: theme.spacing(1, 1)
        },
        buttonColorPrimary: {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
            "&:hover": {
                backgroundColor: theme.palette.primary.main
            },
            textDecoration: "bold",
            padding: theme.spacing(1),
            whiteSpace: "nowrap",
            margin: theme.spacing(1),
        },
        textField: {
            marginRight: theme.spacing(1),
            width: "100%",
            "& ::-webkit-inner-spin-button, ::-webkit-outer-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
                MozAppearance: "textfield"
            }
        },
    })
);

const Report9010 = (props: any) => {
    const { report } = props;
    const classes = useStyles({});
    const data = useForm<any>({ mode: "onBlur" });
    const initialModel: IDataExport9010 = {
        stuEnrollmentId: null,
        groupIds: [],
        programIds: [],
        campusIds: [],
        fiscalYearStart: null,
        fiscalYearEnd: null,
        activitiesConductedAmount: 0,
        isIncludePriorYearCashBalance: false,
        includeOtherFederalAssistance:false,
        includeReportDateRange:false,
    };
    const [model, setModel] = React.useState<IDataExport9010>(initialModel);
    const [enrollments, setEnrollments] = React.useState<IEnrollmentSearchResult[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const handleFieldOnChange = (
        fieldId: string,
        value: any,
        mapFunction?: Function
    ) => {
        let updatedModel = model;
        let newValue = mapFunction ? mapFunction(value) : value;
        (updatedModel as any)[fieldId] = newValue;
        if (fieldId == "campusIds") {
            updatedModel.groupIds = [];
            updatedModel.programIds = [];
        }

        setModel({ ...updatedModel });
    };

    const { handleSubmit } = data;

    const onSubmit = async () => {
        let params = { ...model };
        const validation = await data.triggerValidation();
        if (validation) {
            setIsLoading(true);
            await dataExport9010Api.exportData9010Report(params);
            setIsLoading(false);
        }
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paperRoot}>
                <div>
                    <Card>
                        <Typography className={clsx(classes.cardTitle)}>90/10 Data Export</Typography>
                        <CardContent>
                            <FormContext {...data}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container direction="row" spacing={1}>


                                        <Grid item md={6} sm={6} xs={12}>
                                            <CampusForCurrentUserAutoComplete
                                                multiple
                                                name="campusIds"
                                                id="campusIds"
                                                label="Campus *"
                                                params={{
                                                    fullWidth: true
                                                }}
                                                includeAllValue={true}
                                                showIncludeAllValue={true}
                                                filterHandle={(v: any) => handleFieldOnChange('campusIds', v && Array.isArray(v) && v.length > 0 ? v.map(m => m.value) : undefined)}
                                                valueFilter={
                                                    model.campusIds
                                                        ? {
                                                            key: "value",
                                                            values: model.campusIds,
                                                        }
                                                        :
                                                        undefined
                                                }
                                                chipSize="small"
                                                error={!!data.errors.campusIds}
                                                helperText={data.errors?.campusIds?.message ?? undefined}
                                                inputRef={data.register({ validate: () => model.campusIds && model.campusIds.filter(m => m.toLowerCase() != 'all').length > 0 ? true : 'Campus is required.' })}
                                                maxTags={3}
                                            />
                                        </Grid>

                                        <Grid item md={6} sm={6} xs={12}>
                                            <ProgramAutoComplete
                                                multiple
                                                name="programIds"
                                                id="programIds"
                                                label="Program *"
                                                params={{
                                                    fullWidth: true
                                                }}
                                                campusIds={model.campusIds ?? []}
                                                includeAllValue={true}
                                                showIncludeAllValue={false}
                                                filterHandle={(v: any) => handleFieldOnChange('programIds', v && Array.isArray(v) && v.length > 0 ? v.map(m => m.value) : undefined, (vals:any[]) =>{
                                                    if(!!vals && vals.length > 0){
                                                        let lastValue = vals[vals.length - 1];

                                                        if(lastValue === 'All'){
                                                            return ['All'];
                                                        }else{
                                                            return vals.filter(a => a !== 'All');
                                                        }
                                                    }else{
                                                        return vals;
                                                    }
                                                })}
                                                valueFilter={
                                                    model.programIds
                                                        ? {
                                                            key: "value",
                                                            values: model.programIds,
                                                        }
                                                        :
                                                        undefined
                                                }
                                                chipSize="small"
                                                error={!!data.errors.programIds}
                                                helperText={data.errors?.programIds?.message ?? undefined}
                                                inputRef={data.register({ validate: () => (model.programIds && model.programIds.length > 0) ? true : 'Program is required.' })}
                                                maxTags={3}
                                            />
                                        </Grid>

                                        <Grid item md={6} sm={6} xs={12}>
                                            <StudentGroupsMultiCampusAutoComplete
                                                multiple
                                                name="groupIds"
                                                id="groupIds"
                                                label="Group *"
                                                params={{
                                                    fullWidth: true
                                                }}
                                                campusIds={model.campusIds ?? []}
                                                includeAllValue={true}
                                                showIncludeAllValue={false}
                                                filterHandle={(v: any) => handleFieldOnChange('groupIds', v && Array.isArray(v) && v.length > 0 ? v.map(m => m.value) : undefined, (vals:any[]) =>{
                                                    if(!!vals && vals.length > 0){
                                                        let lastValue = vals[vals.length - 1];

                                                        if(lastValue === 'All'){
                                                            return ['All'];
                                                        }else{
                                                            return vals.filter(a => a !== 'All');
                                                        }
                                                    }else{
                                                        return vals;
                                                    }
                                                })}
                                                valueFilter={
                                                    model.groupIds
                                                        ? {
                                                            key: "value",
                                                            values: model.groupIds,
                                                        }
                                                        :
                                                        undefined
                                                }
                                                chipSize="small"
                                                error={!!data.errors.groupIds}
                                                helperText={data.errors?.groupIds?.message ?? undefined}
                                                inputRef={data.register({ validate: () => (model.groupIds && model.groupIds.length > 0) ? true : 'Group is required.' })}
                                                maxTags={3}
                                            />
                                        </Grid>

                                        <Grid item md={6} sm={6} xs={12} spacing={1}>
                                            <EnrollmentAutoComplete
                                                values={enrollments}
                                                setValues={(values: IEnrollmentSearchResult[]) => {
                                                    setEnrollments(!!values && values.length > 0 ? [{ ...values[values.length - 1] }] : []);
                                                    handleFieldOnChange('stuEnrollmentId', !!values && values.length > 0 ? values[0].stuEnrollId : null);
                                                }}
                                                label={'Student'}
                                                placeholder={'Search Name or Student Number'}
                                            />
                                        </Grid>

                                        <Grid item md={6} sm={6} xs={12}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    id="startDate"
                                                    name="startDate"
                                                    label="Fiscal Year Start *"
                                                    value={model.fiscalYearStart ? model.fiscalYearStart : null}
                                                    onChange={(e: any, value: any) =>
                                                        handleFieldOnChange("fiscalYearStart", value ? value : undefined)
                                                    }
                                                    className={classes.startDateField}
                                                    KeyboardButtonProps={{
                                                        "aria-label": "report date",
                                                    }}
                                                    error={!!data.errors["fiscalYearStart"]}
                                                    innerRef={(ref: any) => {
                                                        data.register("fiscalYearStart", {
                                                            required: true,
                                                            validate: {
                                                                beforeEndDate: (value) =>
                                                                    new Date(value) < new Date(data.getValues()["fiscalYearEnd"]) ||
                                                                    "Fiscal year start Date must be before fiscal year end date",
                                                            },
                                                        });
                                                        data.setValue("fiscalYearStart", model.fiscalYearStart);
                                                    }}
                                                    helperText={
                                                        data.errors["fiscalYearStart"]
                                                            ? (data.errors["fiscalYearStart"] as FieldError).message
                                                            : undefined
                                                    }
                                                    autoOk={true}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    id="endDate"
                                                    name="endDate"
                                                    label="Fiscal Year End *"
                                                    value={model.fiscalYearEnd ? model.fiscalYearEnd : null}
                                                    onChange={(e: any, value: any) =>
                                                        handleFieldOnChange("fiscalYearEnd", value ? value : null)
                                                    }
                                                    className={classes.startDateField}
                                                    KeyboardButtonProps={{
                                                        "aria-label": "end date",
                                                    }}
                                                    error={!!data.errors["fiscalYearEnd"]}
                                                    innerRef={(ref: any) => {
                                                        data.register("fiscalYearEnd", {
                                                            required: true,
                                                            validate: {
                                                                beforeEndDate: (value) =>
                                                                    new Date(value) > new Date(data.getValues()["fiscalYearStart"]) ||
                                                                    "Fiscal Year End Date must be after Fiscal Year Start date",
                                                            },
                                                        });
                                                        data.setValue("fiscalYearEnd", model.fiscalYearEnd);
                                                    }}
                                                    helperText={
                                                        data.errors["fiscalYearEnd"]
                                                            ? (data.errors["fiscalYearEnd"] as FieldError).message
                                                            : undefined
                                                    }
                                                    autoOk={true}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <InputField
                                                type={InputType.NUMBER}
                                                decimal
                                                className={classes.textField}
                                                label={"Activities conducted for education and training (US Dollars) "}
                                                id={"activitiesConductedAmount"}
                                                key={"activitiesConductedAmount"}
                                                name={"activitiesConductedAmount"}
                                                defaultValue={model.activitiesConductedAmount}
                                                onBlur={(v: any) => {
                                                    handleFieldOnChange("activitiesConductedAmount", v.target.value);
                                                }}
                                                required
                                                inputRef={data.register({
                                    				required: 'required.',
                                    			})}
                                                error={!!data.errors.activitiesConductedAmount}
                                                helperText={
                                                    data.errors["activitiesConductedAmount"]
                                                        ? (data.errors["activitiesConductedAmount"] as FieldError).message
                                                        : undefined
                                                }
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <FormControlLabel
                                                label="Include Prior Year Cash Balance"
                                                control={
                                                    <Switch
                                                        checked={model.isIncludePriorYearCashBalance ?? false}
                                                        value="isIncludePriorYearCashBalance"
                                                        onChange={(e: any) => {
                                                            handleFieldOnChange(
                                                                "isIncludePriorYearCashBalance",
                                                                e ? e.target.checked : undefined
                                                            );
                                                        }}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </Grid>

                                        <Grid item md={6} sm={6} xs={12}>
                                            <FormControlLabel
                                                label="Include Other Federal Assistance"
                                                control={
                                                    <Switch
                                                        checked={model.includeOtherFederalAssistance}
                                                        value="includeOtherFederalAssistance"
                                                        onChange={(e: any) => {
                                                            handleFieldOnChange(
                                                                "includeOtherFederalAssistance",
                                                                e ? e.target.checked : undefined
                                                            );
                                                        }}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </Grid>

                                        <Grid item md={6} sm={6} xs={12}>
                                            <FormControlLabel
                                                label="Include Report Date Range in Excel Report"
                                                control={
                                                    <Switch
                                                        checked={model.includeReportDateRange}
                                                        value="includeReportDateRange"
                                                        onChange={(e: any) => {
                                                            handleFieldOnChange(
                                                                "includeReportDateRange",
                                                                e ? e.target.checked : undefined
                                                            );
                                                        }}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </Grid>

                                    </Grid>
                                </form>
                            </FormContext>
                        </CardContent>
                        <Button
                            className={classes.buttonColorPrimary}
                            onClick={onSubmit}
                            size="small"
                            type="submit"
                            color="primary"
                            disabled={isLoading}
                        >Export</Button>
                    </Card>
                </div>
            </Paper>
        </div>
    );
};
export default Report9010;
