import * as React from "react";
import { apiAutoCompleteConfig } from "../../constants/apiAutoComplete.config";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";

const GeographicTypesAutoComplete = (props: FilterParams) => {
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const filterHandle = props.filterHandle;

  const geographicTypesOnChange = (e: any) => {
    if (filterHandle) {
      filterHandle(e);
    }
  };

  return (
    <div>
      <ApiAutoComplete
        config={apiAutoCompleteConfig.geographicTypes}
        loading={userSelectedCampus ? false : true}
        reqParams={{ campusId: userSelectedCampus }}
        classes={{
          option: "text-black"
        }}
        label="Geographic Area"
        onChange={(e: any) => {
          geographicTypesOnChange(e);
        }}
        multiple={props.multiple || false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        maxTags={props.maxTags}
				includeAllValue={props.includeAllValue}
				showIncludeAllValue={props.showIncludeAllValue}
				chipSize={props.chipSize}
      ></ApiAutoComplete>
    </div>
  );
};
export default GeographicTypesAutoComplete;
