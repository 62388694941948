import {pdfPreviewConstants} from '../constants/actions.constants';

   const showPreview = (url:string,title:string) => {
    return (dispatch:any) => {
        let data = {url,title};
      dispatch({ type: pdfPreviewConstants.SHOW_PREVIEW, data });
    };
  }

  const hidePreview = () => {
    return (dispatch:any) => {
      dispatch({ type: pdfPreviewConstants.HIDE_PREVIEW});
    };
  }

  export const pdfPreviewActions = {
      showPreview,
      hidePreview
  };