import * as React from "react";
import clsx from "clsx";
import PhoneTypesAutoComplete from "../../../../components/AutoComplete/PhoneTypesAutoComplete";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import { Typography, Card, CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import * as PhonesApi from "../../../../api/student/profle/contactInfo/PhonesApi";
import { Phone } from "../../../../interfaces/student/profile/contactInfo/Phone";
import { useSelector } from "react-redux";
import { StudentProfile } from "../../../../interfaces/student/profile/StudentProfile";
import { useFormContext } from "react-hook-form";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    drawer: {
      width: 280,
    },
    root: {
      backgroundColor: theme.palette.paper.background,
      "& .MuiCardContent-root": {
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(2),
      },
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.paper.background,
    },
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
  })
);

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {},
  })
)(TextField);
type PhoneProps = {
  setPhoneModel: any;
  isNewEnrollment: boolean;
  isTransferredStudent?: boolean;
  leadId?: string,
  refreshData?: boolean;
  setRefreshData?: (value: boolean) => void;
  bestPhoneModelPassthrough?: any;
};
const BestPhone = (props: PhoneProps) => {
  const appSettings = useSelector((state: any) => {
    return state.settings.settings;
    });
  const PhoneValue = appSettings.TakePhoneInputValid;
  const classes = useStyles({});
  const data = useFormContext();
  const { leadId, bestPhoneModelPassthrough } = props;
  const [phoneModel, setPhoneModel] = React.useState<Phone>(new Phone()); //props; //useState<Phone>(new Phone());
  /*
  Getting the current selected student from the session
   */
  const selectedStudent: StudentProfile = useSelector(
    (state: any) => state.student
  ) as StudentProfile;

  React.useEffect(() => {
    if (bestPhoneModelPassthrough) {
      setPhoneModel({ ...bestPhoneModelPassthrough });
    }
  }, [bestPhoneModelPassthrough]);


  const loadFormData = async () => {


    if ((props.isNewEnrollment === false || !!leadId) && ((selectedStudent !== undefined) || (props.refreshData && props.refreshData === true))) {
      if (
        (selectedStudent.leadId !== undefined &&
          selectedStudent.leadId.length > 0) || !!leadId
      ) {
        if (!props.isNewEnrollment || props.isTransferredStudent || !!leadId) {

          let studentId = leadId ? leadId : selectedStudent.leadId;
          if (studentId) {
            try {
              const response = await PhonesApi.getBestPhone(studentId);
              let updatedModel = response;
              if (response) {
                (updatedModel as any)["phoneId"] = (response as any).id;
                (updatedModel as any)["phoneIsShowOnLeadPage"] = (response as any).isShowOnLeadPage;
                props.setPhoneModel({ ...updatedModel });
                if (updatedModel) {
                  setPhoneModel({ ...updatedModel });
                }
              }
            }
            catch (err) {

            }
          }
        }
      }
    }
    else {
      let clearPhone = new Phone();
      props.setPhoneModel({ ...clearPhone });
      setPhoneModel({ ...clearPhone });
    }
    if (props.refreshData !== undefined && props.refreshData === true) {
      if (props.setRefreshData) {
        props.setRefreshData(false);
      }

    }
  }

  /*
  When the Component is loaded
   */
  React.useEffect(
    () => {
      /*
      Checks that the initial state is not undefined, this is two avoid having two calls to the API when the redux is still loading data
     */
      loadFormData();
    },

    [
      selectedStudent,
      leadId,
      props.isNewEnrollment,
      props.refreshData
    ] /* tell react to watch changes on this variable */
  );
  const handleFieldOnChange = (fieldId: string, value: any) => {
    let updatedModel = phoneModel;
    (updatedModel as any)[fieldId] = value;
    setPhoneModel(updatedModel);
    props.setPhoneModel(updatedModel);
  };

  const onValidation = (fieldId: string, value: any) => {
    if (data.errors && (phoneModel as any)[fieldId] != value) {
      handleFieldOnChange(fieldId, value);
    }
  };

  //Accepts 10 Digits Number, permitted delimiters are: spaces, dashes, or periods, it allows + and/or 1 leading values to form a number like +1800-000-0000.
  const PhoneValidation = (errorName: any) => {
    if (errorName?.type === "validate") {
      return "Phone is not in correct format.";
    } else return null;
  };
  const PhoneTypeValidation = (errorName: any) => {
    if (errorName?.type === "validate") {
      return "Phone Type is Required.";
    } else return undefined;
  };
  return (
    <div>
      <Card square={true}>
        <Typography className={clsx(classes.cardTitle)}>
          <div className={classes.headerText}>Best Phone</div>
        </Typography>
        <CardContent className={classes.cardContent}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={12} sm={4}>
              <PhoneTypesAutoComplete
                filterHandle={(v: any) => {
                  handleFieldOnChange("phoneTypeId", v ? v.value : undefined);
                }}
                label={"Phone Type *"}
                id="phoneTypeId"
                name="phoneTypeId"
                inputRef={data.register({
                  validate: (value: any) => {
                    if (phoneModel.phone && !value) return false;
                    else return true;
                  },
                })}
                helperText={PhoneTypeValidation(data.errors.phoneTypeId)}
                error={!!data.errors.phoneTypeId}
                valueFilter={
                  phoneModel && phoneModel.phoneTypeId
                    ? {
                      key: "value",
                      values: [phoneModel.phoneTypeId],
                    }
                    : undefined
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ThemeTextField
                id="phone"
                name="phone"
                className={classes.textField}
                label={"Number *"}
                margin="none"
                value={phoneModel.phone ?? ""}
                inputRef={data.register({
                  validate: (value: any) => {
                    onValidation("phone", value);
                    if (phoneModel.phoneTypeId && !value) 
                      return false;
                    else if (PhoneValue == 'True') 
                      return /^\d{10}$/.test(value);
                    else                    
                      return /^[+]?1?[-\. ]?(\(\d{3}\)?[-\. ]?|\d{3}?[-\. ]?)?\d{3}?[-\. ]?\d{4}$/.test(value);
                  },
                })}
                helperText={PhoneValidation(data.errors.phone)}
                error={!!data.errors.phone}
                onChange={(v: any) => {
                  handleFieldOnChange("phone", v ? v.target.value : undefined);
                }}
              />
            </Grid>      
            <Grid item xs={12} sm={4}>
              <ThemeTextField
                id="extension"
                className={classes.textField}
                label="Ext."
                margin="none"
                value={phoneModel.extension}
                onChange={(v: any) => {
                  handleFieldOnChange(
                    "extension",
                    v ? v.target.value : undefined
                  );
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default BestPhone;
