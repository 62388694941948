import * as React from "react";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { FilterParams } from "../../interfaces/common/FilterParams";


const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    divWidth: {
      width: "95%"
    }
  })
);

const MonthAutoComplete = (props: FilterParams) => {
  const classes = useStyles({});

  const handleOnChange = (e: any) => {
    if (props.filterHandle) {
      props.filterHandle(e);
    }
  };

  return (
    <div className={clsx(classes.divWidth)}>
      <ApiAutoComplete
        config={{
            acId: "Month",
            cacheTime: 20,
            route: "",
            requestType: "",
            body: null,
            hasUrlParams: false,
            useCache: true,
            storeInCache: true,
            isCampusSpecific: false,
            isUserSpecific: false,
            loadOnRender: true,
            defaultSelectedFirstItem: false,
            optionsMappedFx: (data: Array<any>) => {
                return data;
            }
            
        }}
        options={[
            {text:'January', value:'1'},
            {text:'February', value:'2'},
            {text:'March', value:'3'},
            {text:'April', value:'4'},
            {text:'May', value:'5'},
            {text:'June', value:'6'},
            {text:'July', value:'7'},
            {text:'August', value:'8'},
            {text:'September', value:'9'},
            {text:'October', value:'10'},
            {text:'November', value:'11'},
            {text:'December', value:'12'},
        ]}
        classes={{
          option: "text-black"
        }}
        label={props.hideLabel ? undefined : (props.label ?? 'Month')}
        onChange={(e: any) => {
          handleOnChange(e);
        }}
        disabled={props.disabled}
        inputRef={props.inputRef}
        error={props.error}
        helperText={props.helperText}
        inputName={props.name}
        multiple={props.multiple || false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        maxTags={props.maxTags}
        includeAllValue={props.includeAllValue}
        showIncludeAllValue={props.showIncludeAllValue}
        chipSize={props.chipSize}
        reqParams={props.params}
      ></ApiAutoComplete>
    </div>
  );
};
export default MonthAutoComplete;
