import React from 'react';
import {
	Dialog,
	makeStyles,
	createStyles,
	DialogTitle,
	DialogContent,
	Grid,
	DialogActions,
	Button,
	IconButton,
    InputLabel,
} from '@material-ui/core';
import ProgressSaveButton from '../../../components/_Layout/Buttons/ProgressSaveButton';
import { useSelector } from 'react-redux';
import CustomSnackbar from '../../../components/notifications/CustomSnackbar';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ProgramVersionAutoComplete from '../../../components/AutoComplete/ProgramVersionAutoComplete';
import Label from '../../../components/Label/Label';
import * as InstitutionalRefundApi from "../../../../src/api/student/studentAccounts/InstitutionalRefundApi";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		dialogHeader: {
			backgroundColor: theme.palette.site.secondary,
			'& .MuiTypography-root': {
				fontWeight: theme.typography.fontWeightBold,
				fontSize: '12pt',
			},
		},
		buttonSection: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(3),
		},
		saveButton: {
			width: 130,
		},
		fullWidthField: {
			width: '100%',
		},

		downloadIcon: {
			width: '100%',
			fontSize: '10em',
		}
		, Disabled: {
			color: 'rgba(0, 0, 0, 0.26)'
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(0),
			top: theme.spacing(0)
		},
		inputFeildGrid: {
			marginBottom:theme.spacing(1)
		}
	})
);

interface IProps{
    open:boolean;
    setOpen:(isOpen:boolean) => void;
    policyId:string;
    policyTitle:string;
}

export const ProgramVersionMappingPopup = (props: any) => {
	const classes = useStyles({});
	const campusId = useSelector(
		(state: any) => state.student.enrollments[0].campusId
	);

	const [loaderState, setLoaderState] = React.useState<boolean>(false);

	const handleClose = () => {
        setProgramVersionIds([]);
		props.setOpen(false);
	};

	const [programVersionIds, setProgramVersionIds] = React.useState<string[]>([]);


    interface SnackBarProps {
        open: boolean;
        message: string;
        variant: string;
    }

    const [snackProps, setSnackProps] = React.useState<SnackBarProps>({ open: false, message: '', variant: 'success' });

    const onSubmit = () =>{
        setLoaderState(true);
        InstitutionalRefundApi.MapProgramVersionToPolicy(props.policyId, programVersionIds)
        .then(res => {
            setSnackProps({message:'Updated Successfully.',open:true,variant:'success'});
            setLoaderState(false);
            setProgramVersionIds([]);
            props.setOpen(false);
        }, e => {
            setSnackProps({message:'Failed to Update.',open:true,variant:'error'});
            setLoaderState(false);
        });
    }
    
    React.useEffect(() => {
    	if(!!props.open && !!props.policyId){
            setLoaderState(true);
            InstitutionalRefundApi.GetProgramVersionIdsForPolicy(props.policyId)
            .then(res => {
                if(!!res && res.length > 0){
                    setProgramVersionIds(res);
                }else{
                    setProgramVersionIds([]);
                }

                setLoaderState(false);
            }, e => {
                setLoaderState(false);
                setProgramVersionIds([]);
                props.setOpen(false);
                setSnackProps({message:'Failed to get details.',open:true, variant:'error'});
            });
    	}
    }, [props.open, props.policyId]);

    const handleSnackbarClose = () => {
    	setSnackProps({
    		open: false,
    		message: '',
    		variant: 'success'
    	});
    };

    const setProgramVersion = (PrgVersions: any) => {
        let newGroups: string[] = [];
        if (PrgVersions !== null) {
          if (Array.isArray(PrgVersions)) {
            newGroups = PrgVersions.map((gr: any) => gr.value);
          } else {
            newGroups.push(PrgVersions.value);
          }
        }

        setProgramVersionIds(newGroups);
      };

    return (
    	<div>
    			<Dialog
    				open={props.open}
    				onClose={handleClose}
    				aria-labelledby="form-dialog-title"
    				maxWidth={'xs'}
    				fullWidth={true}
    				disableBackdropClick >
    				<DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
    					<Typography>Program Version Mapping</Typography>
    					<IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
    						<CloseIcon />
    					</IconButton>
    				</DialogTitle>
    				<DialogContent>
    							<Grid container direction="row" >
                                <Grid item xs={12} md={12} sm={12}>
                                    <Label style={{marginBottom:10}} id={'PolicyDescription'} >
                                        {`${props.policyTitle}`}
    								</Label>
                                </Grid>
    								<Grid item xs={12} md={12} sm={12}>
                                    <ProgramVersionAutoComplete
                                    disabled={loaderState}
                                        id="prgVersionForMappingPolicy"
                                        name="prgVersionForMappingPolicy"
                                        label="Program Version"
                                        multiple={true}
                                        valueFilter={
                                                programVersionIds
                                                ? {
                                                    key: "value",
                                                    values: programVersionIds,
                                                    }
                                                : undefined
                                                }
                                        filterHandle={(data: any) => {
                                            setProgramVersion(data);
                                        }}
                                        />
    								</Grid>
    							</Grid>
    				</DialogContent>
    				<DialogActions className={classes.buttonSection}>
    					<Grid container direction="row" spacing={1}
    						alignContent="center"
    						alignItems="center"
    						justify="center"
    					>
                            
    						<ProgressSaveButton
    									text="Save"
    									onClick={(e: any) => {
    										onSubmit();
    									}}
    									loading={loaderState}
    									disabled={loaderState}
    									buttonClassName={classes.saveButton}
    								></ProgressSaveButton>
    						<Grid
    							item
    							container
    							xs={3}
    							alignContent="center"
    							alignItems="center"
    							justify="center"
    						>
    							<Button
    								onClick={handleClose}
    								color="secondary"
    								variant="contained"
    								type="button"
    							>
                                    Cancel
    							</Button>
    						</Grid>
    					</Grid>
    				</DialogActions>
    			</Dialog>
    			<CustomSnackbar
    				variant={snackProps.variant === 'success' ? 'success' : 'error'}
    				message={snackProps.message}
    				open={snackProps.open}
    				onClose={handleSnackbarClose}
    			></CustomSnackbar>
    		</div>

    );
};
