export const config = {
    acId: "adHocReportCategory",
    cacheTime: 5,
    route: "/Reports/AdHoc/AdHocReport/GetAdhocReportCategories",
    requestType: "GET",
    body: null,
    hasUrlParams: false,
    useCache: true,
    storeInCache: true,
    isCampusSpecific: false,
    isUserSpecific: false,
    loadOnRender: true,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
        return data;
    },
};