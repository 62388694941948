import React from "react";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  Grid,
  Card,
  Typography,
  CardContent,
  Checkbox,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  TextField,
  DialogContentText,
} from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import { useSelector } from "react-redux";
import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";
import { CustomSnackBarProps } from "../../../../interfaces/common/CustomSnackBarProps";
import { IEnrollmentSearchResult } from "../../../../interfaces/student/enrollment/EnrollmentSearchResult";
import SearchBarFilter from "../../../../components/_Layout/Search/SearchBarFilter";
import StudentBanner from "./StudentBanner";
import * as classRegApi from "../../../../api/setup/academics/classRegistrationApi";
import SortIcon from "@material-ui/icons/Sort";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import {
  ITermSearchParam,
  ITermResult,
  ITermClassResult,
  ICourseRegisterModel,
} from "../../../../interfaces/setup/creditHour/ITermSearchModels";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CustomCardTitle from "../../../../interfaces/common/card/CustomCardTitle";

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      minHeight: "100%",
      height: "100%",
      maxWidth: "1050px",
      padding: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    startDateField: {
      width: "100%",
    },
    fullWidthElement: {
      width: "100%",
    },
    cardTitle: {
      fontSize: 17,
      color: "Black",
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardSeparator: {
      marginBottom: theme.spacing(1),
    },
    copyButton: {
      marginTop: theme.spacing(3),
      width: 240,
    },
    cardWrapper: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    searchBarContainer: {
      backgroundColor: theme.palette.site.secondary,
      borderRadius: 20,
      paddingLeft: "10px !important",
      fontSize: 13,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.black,
      paddingTop: "2.5px !important",
      paddingBottom: "2.5px !important",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    accordion: {
      position: "inherit",
    },
    sortDesc: {
      transform: "rotate(180deg)",
    },
    dialogHeader: {
      backgroundColor: theme.palette.site.secondary,
      "& .MuiTypography-root": {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: "12pt",
      },
    },
    buttonSection: {
      marginBottom: theme.spacing(2),
    },
    saveButton: {
      width: "auto",
      marginRight: theme.spacing(2),
    },
    typography: {
      whiteSpace: "pre-line",
    },
    accordionSummary: {
      backgroundColor: theme.palette.site.secondary,
    },
    right: {
      float: "right",
    },
  })
);

function ByStudent() {
  const classes = useStyles();
  const data = useForm<any>({ mode: "onBlur" });
  const { handleSubmit } = data;
  const [updating, setUpdating] = React.useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );

  interface IClassRegistrationByStudent {
    enrollments: IEnrollmentSearchResult[];
    studentId: string;
    studentEnrollmentId: string;
    studentName: string;
  }

  const initialState: IClassRegistrationByStudent = {
    enrollments: [] as IEnrollmentSearchResult[],
    studentId: "",
    studentEnrollmentId: "",
    studentName: "Search Student",
  };

  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const defaultFilter: ITermSearchParam = {
    campusId: null,
    stuEnrollId: null,
    sortOrder: "ASC",
    pageNo: 1,
    pageSize: 100,
  };

  const [filter, setFilter] = React.useState<ITermSearchParam>(defaultFilter);

  const [model, setModel] =
    React.useState<IClassRegistrationByStudent>(initialState);

  const [reload, setReload] = React.useState<boolean>(false);

  const [terms, setTerms] = React.useState<Array<ITermResult>>(
    new Array<ITermResult>()
  );
  const [termsCopy, setTermsCopy] = React.useState<Array<ITermResult>>(
    new Array<ITermResult>()
  );

  React.useEffect(() => {
    loadTerms();
  }, [filter, userSelectedCampus]);

  const loadTerms = () => {
    filter.campusId = userSelectedCampus;
    classRegApi.SearchTerms(filter).then(
      (response: any) => {
        if (
          response &&
          response.data &&
          response.data.result &&
          response.data.resultStatus === 0
        ) {
          var results: ITermResult[] = response.data.result?.jsonResult ?? [];
          setTerms(results ?? []);
          setTermsCopy(results ?? []);
        }else{
          setTerms([]);
          setTermsCopy([]);
        }
      },
      (exception: any) => {
        setSnackBarProps(() => {
          return {
            variant: "error",
            showSnackBar: true,
            messageInfo: exception,
          };
        });
      }
    );
  };

  const handleFieldOnChange = (fieldId: string, value: any) => {
    let updatedModel = model;
    if (fieldId == "fromCampusId") {
      (updatedModel as any)["programId"] = "";
      (updatedModel as any)["programVerId"] = "";
    }
    (updatedModel as any)[fieldId] = value;
    setModel({ ...updatedModel });
  };

  interface IModel {
    studentEnrollmentId?: string;
    studentId?: string;
    studentName?: string;
  }

  const setStudent = (values: IModel) => {
    handleFieldOnChange("studentId", values.studentId);
    handleFieldOnChange("studentEnrollmentId", values.studentEnrollmentId);
    handleFieldOnChange("studentName", values.studentName);
    setFilter({
      ...filter,
      stuEnrollId: values.studentEnrollmentId,
    });
  };

  const selectAllClick = (checked: boolean, termId: any) => {
    if (checked) {
      setTerms((prevTerms) => {
        const updatedTerms = prevTerms.map((term) => {
          if (term.TermCalendarId === termId) {
            const updatedClasses = term.Classes?.map((cls) => {
              let theClass = cls;
              if(theClass.CanEdit == true)
              {
                theClass.Registered = true;
              }
              //...cls,
              //Registered: true,
              return theClass;
            });
            term.Selected = term.Classes !== undefined && term.Classes != null && term.Classes.length > 0;
            return { ...term, Classes: updatedClasses };
          }
          return term;
        });
        return updatedTerms;
      });
    } else {
      setTerms((prevTerms) => {
        const updatedTerms = prevTerms.map((term) => {
          if (term.TermCalendarId === termId) {
            const updatedClasses = term.Classes?.map((cls) => {
              let theClass = cls;
              if(theClass.CanEdit == true)
              {
                theClass.Registered = false;
              }
              //...cls,
              //Registered: false,
              return theClass;
            });
            term.Selected = false;
            return { ...term, Classes: updatedClasses };
          }
          return term;
        });
        return updatedTerms;
      });
    }
  };
  const selectClass = (checked: boolean, termId: string, classId: string) => {
    if (checked) {
      setTerms((prevTerms) => {
        const updatedTerms = prevTerms.map((term) => {
          if (term.TermCalendarId === termId) {
            const updatedClasses = term.Classes.map((cls) => {
              let theClass = cls;
              if (cls.InstrClassTermId == classId) {
                theClass.Registered = true;
              }
              return theClass;
            });
            return { ...term, Classes: updatedClasses };
          }
          return term;
        });
        return updatedTerms;
      });
    } else {
      setTerms((prevTerms) => {
        const updatedTerms = prevTerms.map((term) => {
          if (term.TermCalendarId === termId) {
            const updatedClasses = term.Classes.map((cls) => {
              let theClass = cls;
              if (cls.InstrClassTermId == classId) {
                theClass.Registered = false;
              }
              return theClass;
            });
            return { ...term, Classes: updatedClasses };
          }
          return term;
        });
        return updatedTerms;
      });
    }
  };

  function getTotalRegisteredClassesLength() {
    let totalLength = 0;
    for (const term of terms) {
      if (term.Classes != null) {
        const registeredClassesInTerm = term?.Classes.filter(
          (f) =>
            (f.Registered == true && f.StudentScheduleId == null) ||
            (f.Registered == false && f.StudentScheduleId != null)
        ).length;
        totalLength += registeredClassesInTerm;
      }
    }
    return totalLength;
  }

  const onSubmit = async (isPreview: any) => {
    if (getTotalRegisteredClassesLength() > 0) {
      setDialogOpen(true);
    }
  };

  const isAttendanceExists = (enrollId:string, classIds:string[], onSuccess:(isExists:boolean) => void) =>{
    let cIds = '';

    if(!!classIds && classIds.length > 0){
      classIds.forEach(element => {
        cIds += `${cIds == '' ? '' : ','}${element}`;
      });

      classRegApi.IsAttendanceExistsForAnyClass(enrollId, cIds).then(res => {
        onSuccess(res);
      });
    }
  }

  const [showAttendanceWarningPopup, setShowAttendanceWarningPopup] = React.useState<boolean>(false);

  const handleConfirm = () => {
    let classIds:string[] = [];

    terms?.map(function (term: ITermResult, ix: any) {
      term.Classes?.filter(
        (f) =>
          (f.Registered == true && f.StudentScheduleId == null) ||
          (f.Registered == false && f.StudentScheduleId != null)
      )?.map(function (cls: ITermClassResult, ix: any) {
        classIds.push(cls.InstrClassTermId);
      });
    });
    if (classIds.length > 0) {
      setUpdating(true);
      isAttendanceExists(model.studentEnrollmentId, classIds, (isExists:boolean) => {
        setUpdating(false);
        if(isExists){
          setShowAttendanceWarningPopup(true);
        }else{
          updateRegistrations();
        }
      })
    }
  };

  const updateRegistrations = () => {
    let courseList: ICourseRegisterModel[] = [];
    terms?.map(function (term: ITermResult, ix: any) {
      term.Classes?.filter(
        (f) =>
          (f.Registered == true && f.StudentScheduleId == null) ||
          (f.Registered == false && f.StudentScheduleId != null)
      )?.map(function (cls: ITermClassResult, ix: any) {
        courseList.push({
          instrClassTermId: cls.InstrClassTermId,
          stuEnrollId: model.studentEnrollmentId,
          studentScheduleId: cls.StudentScheduleId,
        });
      });
    });

    if (courseList.length > 0){
      setUpdating(true);
      classRegApi.RegisterCourse(courseList).then(
        (response: any) => {
          if (response && response.data && response.data.resultStatus === 0) {
            if (response.data.result === true) {
              setSnackBarProps(() => {
                return {
                  variant: "success",
                  showSnackBar: true,
                  messageInfo: "Data saved successfully.",
                };
              });
              setDialogOpen(false);
              loadTerms();
              setReload(true);
            } else {
              setSnackBarProps(() => {
                return {
                  variant: "error",
                  showSnackBar: true,
                  messageInfo: response.data.resultStatusMessage,
                };
              });
            }
          } else {
            setSnackBarProps(() => {
              return {
                variant: "error",
                showSnackBar: true,
                messageInfo: response.data,
              };
            });
          }
          setUpdating(false);
        },
        (exception: any) => {
          setSnackBarProps(() => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo: exception,
            };
          });
          setUpdating(false);
        }
      );
    }
  }

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleTextFieldClick = (event: any) => {
    event.stopPropagation();
  };

  const handleSearch = (searchText: string, termCalendarId: string) => {
    const filteredTerms = termsCopy.map((term) => {
      if (term.TermCalendarId === termCalendarId) {
        return {
          ...term,
          SearchText: searchText,
          Classes: term.Classes.filter(
            (cls) =>
              cls.Course.toLowerCase().includes(searchText.toLowerCase()) ||
              cls.Class.toLowerCase().includes(searchText.toLowerCase())
          ),
        };
      }
      return term;
    });
    setTerms(filteredTerms);
  };

  return (
    <Card className={classes.cardSeparator}>
      <Dialog
			open={showAttendanceWarningPopup}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<CustomCardTitle title='Unregister From Class' />
			<DialogContent style={{width:500}}>
				<DialogContentText id="alert-dialog-description">
					{'The student has attendance in one or more classes. Unregistering will remove attendance from that class. Continue?'}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => {
          setShowAttendanceWarningPopup(false);
          updateRegistrations();
				}} color="primary">
					Yes
				</Button>
				<Button onClick={() => {
          setShowAttendanceWarningPopup(false);
        }} color="primary">
					No
				</Button>
			</DialogActions>
		</Dialog>
      <CardContent>
        <FormContext {...data}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="row" spacing={1}>
              <Grid item md={12} sm={12} xs={12}>
                <Grid container direction="row" spacing={2}>
                  <Grid item md={6} sm={6} xs={12}>
                    <div className={classes.searchBarContainer}>
                      <SearchBarFilter
                        onSelect={(data?: any, studentName?: string) =>
                          data &&
                          data.stuEnrollId &&
                          studentName &&
                          setStudent({
                            studentEnrollmentId: data.stuEnrollId,
                            studentName,
                            studentId: data.studentId,
                          })
                        }
                        placeholder={model.studentName}
                        creditHoursOnly={true}
                      ></SearchBarFilter>
                    </div>
                  </Grid>
                  {model.studentEnrollmentId ? (
                    <Grid container direction="row" spacing={1}>
                      <Grid item md={3} sm={3} xs={12}>
                        <StudentBanner
                          stuEnrollId={model.studentEnrollmentId}
                          reload={reload}
                          setReload={setReload}
                        />
                      </Grid>

                      <Grid item md={9} sm={9} xs={12}>
                        <IconButton
                          onClick={() => {
                            const newSort =
                              filter.sortOrder === "ASC" ? "DESC" : "ASC";
                            setFilter({ ...filter, sortOrder: newSort });
                          }}
                        >
                          <SortIcon
                            className={
                              (filter.sortOrder === "ASC" &&
                                classes.sortDesc) ||
                              ""
                            }
                          />
                        </IconButton>
                        {terms.map((row: ITermResult, index: any) => (
                          <Accordion
                            className={classes.accordion}
                            key={`${index}`}
                          >
                            <AccordionSummary
                              className={classes.accordionSummary}
                            >
                              <Grid container direction="row" spacing={2}>
                                <Grid item md={8} sm={8} xs={8}>
                                  {row.Name +
                                    " ( " +
                                    row.StartDate +
                                    " - " +
                                    row.EndDate +
                                    " )"}
                                </Grid>
                                <Grid item md={4} sm={4} xs={4}>
                                  <div className={classes.right}>
                                    <TextField
                                      placeholder={"Search classes/courses"}
                                      onClick={handleTextFieldClick}
                                      value={row.SearchText}
                                      onChange={(v: any) => {
                                        handleSearch(
                                          v ? v.target.value : undefined,
                                          row.TermCalendarId
                                        );
                                      }}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton>
                                              <SearchIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                              <TableContainer>
                                <Table size="small" aria-label="a dense table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Class</TableCell>
                                      <TableCell>Instructor</TableCell>
                                      <TableCell>Course</TableCell>
                                      <TableCell>Grade/Status</TableCell>
                                      <TableCell align="right">
                                        Select All
                                        <Checkbox
                                          value="uncontrolled"
                                          color="primary"
                                          checked={row.Selected}
                                          onClick={(e: any) => {
                                            selectAllClick(
                                              e.target.checked,
                                              row.TermCalendarId
                                            );
                                          }}
                                          inputProps={{
                                            "aria-label":
                                              "uncontrolled-checkbox",
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {row.Classes?.map(
                                      (cls: ITermClassResult, ix: any) => (
                                        <TableRow>
                                          <TableCell>{cls.Class}</TableCell>
                                          <TableCell>{cls.Instructor}</TableCell>
                                          <TableCell>{cls.Course}</TableCell>
                                          <TableCell>{cls.Grade}</TableCell>
                                          <TableCell align="right">
                                            {((cls.Grade == null || cls.Grade == 'IP') && cls.CanEdit == true) ? (
                                              <Checkbox
                                                color="primary"
                                                key={`selectionCheck-${ix}`}
                                                onClick={(e: any) => {

                                                  //Check if same course is already registered with passing/IP
                                                  const passOrIp = terms.find(term => {
                                                      const foundClass = term.Classes?.find(item => item.InstrClassTermId !== cls.InstrClassTermId && item.CourseId === cls.CourseId && (item.Grade == 'IP' || item.IsPass == true));
                                                      return foundClass !== undefined;
                                                  });

                                                  const fail = terms.find(term => {
                                                      const foundClass = term.Classes?.find(item => item.InstrClassTermId !== cls.InstrClassTermId && item.CourseId === cls.CourseId && (item.IsPass != null || item.IsPass == false));
                                                      return foundClass !== undefined;
                                                  });

                                                  if((passOrIp || fail) && e.target.checked == true)
                                                  {
                                                    if(passOrIp)
                                                    {
                                                      alert("Student is already registered in the same course, you cannot register again.")
                                                    }
                                                    else
                                                    {
                                                      const confirmed = window.confirm('Are you sure you want to register for this class?');
                                                      if (!confirmed) return;
                                                      selectClass(
                                                        e.target.checked,
                                                        row.TermCalendarId,
                                                        cls.InstrClassTermId
                                                      );
                                                    }
                                                  }
                                                  else
                                                  {
                                                    selectClass(
                                                      e.target.checked,
                                                      row.TermCalendarId,
                                                      cls.InstrClassTermId
                                                    );
                                                  }
                                                  
                                                }}
                                                checked={cls.Registered}
                                              />
                                            ) : (
                                              <Checkbox
                                                color="primary"
                                                disabled={true}
                                                checked={cls.Registered}
                                              />
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Grid
                item
                alignContent="flex-start"
                alignItems="flex-start"
                justify="flex-start"
              >
                <ProgressSaveButton
                  text="Save"
                  onClick={(e: any) => {
                    onSubmit(e);
                  }}
                  loading={updating}
                  buttonClassName={classes.copyButton}
                ></ProgressSaveButton>
              </Grid>
            </Grid>
          </form>
        </FormContext>
      </CardContent>
      <CustomSnackbar
        variant={snackBarProps.variant}
        message={snackBarProps.messageInfo}
        open={snackBarProps.showSnackBar}
        onClose={(event?: React.SyntheticEvent, reason?: string) => {
          setSnackBarProps((props: any) => {
            return { ...props, showSnackBar: false };
          });
        }}
      ></CustomSnackbar>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
          {"Registration Confirmation"}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              {}
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <Typography
                color="textSecondary"
                className={classes.typography}
                variant="body1"
              >
                {"Please review and confirm."}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}><b>Add Class</b></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell><b>Class</b></TableCell>
                      <TableCell><b>Instructor</b></TableCell>
                      <TableCell><b>Course</b></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {terms?.map((term: ITermResult, ix: any) =>
                      term.Classes?.filter(
                        (f) =>
                          f.Registered == true && f.StudentScheduleId == null
                      ).map((cls: ITermClassResult, ix: any) => (
                        <TableRow>
                          <TableCell>{cls.Class}</TableCell>
                          <TableCell>{cls.Instructor}</TableCell>
                          <TableCell>{cls.Course}</TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}><b>Remove Class</b></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell><b>Class</b></TableCell>
                      <TableCell><b>Instructor</b></TableCell>
                      <TableCell><b>Course</b></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {terms?.map((term: ITermResult, ix: any) =>
                      term.Classes?.filter(
                        (f) =>
                          f.Registered == false && f.StudentScheduleId != null
                      ).map((cls: ITermClassResult, ix: any) => (
                        <TableRow>
                          <TableCell>{cls.Class}</TableCell>
                          <TableCell>{cls.Instructor}</TableCell>
                          <TableCell>{cls.Course}</TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <Grid item xs={12}>
          <DialogActions className={classes.buttonSection}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={3}></Grid>
              <Grid
                item
                container
                xs={6}
                alignContent="center"
                alignItems="center"
                justify="center"
              >
                <ProgressSaveButton
                  text={"Yes"}
                  onClick={() => {
                    handleConfirm();
                  }}
                  loading={false}
                  buttonClassName={classes.saveButton}
                ></ProgressSaveButton>

                <Button
                  onClick={handleClose}
                  color="secondary"
                  variant="contained"
                  type="button"
                >
                  {"Cancel"}
                </Button>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </DialogActions>
        </Grid>
      </Dialog>
    </Card>
  );
}
export default ByStudent;
