import jwt from "jwt-decode"; // import dependency

const functions = {
	IsTokenExpired: (token: string) => {
		let decoded: any = jwt(token);
		const now = Date.now().valueOf() / 1000;
		if (typeof decoded.exp !== "undefined" && decoded.exp < now) {
			return true;
		}

		return false;
	}
};

export default functions;
