import API from "../apiWrapper";
import { UserMenuItem } from "../../interfaces/user/UserMenuItem";

export const getUserMenu = (campusId: string) => {
	return API()
		.get("/SystemCatalog/Menu/GetUserMenu", {
			params: {
				campusId
			}
		})
		.then(
			(res: any) => {
				if (res.data) {
					return res.data.result as Array<UserMenuItem>;
				}
			},
			(error: any) => {
				return error;
			}
		);
};
