import { Action } from "redux";
import * as actionTypes from "../../constants/actions.constants";

const initialState: any = {
    dragData: {
        destination: undefined,
        draggableId: undefined,
        mode: undefined,
        reason: undefined,
        source: undefined
    }
};

const dragReducer = (state = initialState, action: Action<any>) => {
    switch (action.type) {
        case actionTypes.dragConstants.DRAG_ENDED: {
            console.log((action as any).dragData)
            return {
                ...state,
                dragData: (action as any).dragData
            };
        }
        case actionTypes.dragConstants.DRAG_STARTED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default dragReducer;
