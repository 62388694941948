import * as React from "react";
import {
  Menu,
  MenuItem,
} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";

const SecondaryMenuModel = (props: any) => {
  const [open, setOpen] = React.useState<any>();
  const history = props.browserLocationHistory;
  React.useEffect(() => {
    setOpen(props.menuProps)
  }, [props.menuProps]);

  const handleClose = () => {
    setOpen({isOpen : false})
  };

  return (
    <Menu
      id="breadcrumb-menu"
      anchorEl={props.anchorEl}
      open={open && open.isOpen}
      onClose={handleClose}
      TransitionComponent={Fade}
    >
      <MenuItem
        onClick={function (e: any) {
          handleClose();
          history.push("/student/1/program")
        }}
      >
        Transfer to a different program
      </MenuItem>
      {/* <MenuItem
        onClick={function (e: any) {
          handleClose();
          history.push("/student/1/campus")
        }}
      >
        Transfer to a different campus
      </MenuItem> */}
    </Menu>
  );
};
export default SecondaryMenuModel;
