import * as React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import AdvantageViewer from '../../components/Common/AdvantageViewer';
import getAdvantageLink from '../../utils/getAdvantageLink';
import { useSelector } from 'react-redux';
import { Campus } from '../../interfaces/systemCatalog/ICampus';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			height: '100%',
			minHeight: '100%',
			paddingTop: theme.spacing(3),
			overflowX: 'hidden',
			overflowY: 'auto'
		}
	})
);

const FundSources = (props: any) => {
	const classes = useStyles({});
	const advantageLink = getAdvantageLink(props.location.pathname);
	const studentId = useSelector<any, any>(
		(state: any) => state.student.studentId
	);
	const campusId = useSelector<any, Campus>(
		(state: any) => state.student.enrollments[0].campusId
	);

	return (
		<div className={classes.root}>
			<AdvantageViewer
				url={advantageLink}
				params={{
					cmpId: campusId,
					studentId: studentId,
					desc: 'Fund Sources',
					mod: 'SA'
				}}
			/>
		</div>
	);
};

export default FundSources;
