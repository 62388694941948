import * as React from "react";
import {
  makeStyles,
  createStyles,
  Grid,
  withStyles,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  TextField,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  CircularProgress,
  Paper,
  Chip,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import * as quickInfoApi from "../../../api/student/quickInfo/quickInfoApi";
import { IDisbursementEmailStatus } from "../../../interfaces/student/quickInfo/IDisbursementEmailStatus";
import { StudentProfile } from "../../../interfaces/student/profile/StudentProfile";
import { FormContext, useForm } from "react-hook-form";
import ProgressSaveButton from "../Buttons/ProgressSaveButton";
import CustomSnackbar from "../../../components/notifications/CustomSnackbar";
import { CustomSnackBarProps } from "../../../interfaces/common/CustomSnackBarProps";
import { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";

const StyledTableCell = withStyles((theme: any) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.site.secondary,
      fontSize: 17,
      color: theme.palette.black,
      fontWeight: "bold",
      textAlign: "center",
      boxShadow: "1px 1px 0px 0px rgba(0,0,0,0.2)",
      "&:first-child": {
        width: "42px",
        padding: theme.spacing(0, 1),
      },
    },
    body: {
      fontSize: 16,
      color: theme.palette.black,
      fontWeight: 500,
      cursor: "pointer",
      textAlign: "left",
      "&:first-child": {
        width: "42px",
        padding: theme.spacing(0, 1),
      },
    },
  })
)(TableCell);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      fontWeight: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(0),
    },
    headerText: {
      float: "left",
      padding: theme.spacing(1.5),
    },
    dialogCloseButton: {
      float: "right",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    tableContainer: {
      height: "400px",
      width: "100%",
    },
    tableEmptyRow: {
      height: "350px",
      textAlign: "center",
      fontSize: 20,
      opacity: 0.3,
    },
    table: {
      width: "100%",
    },
    preLoaderCell: {
      textAlign: "center",
      padding: theme.spacing(1),
    },
    saveButton: {
      width: 130,
    },
  })
);
type NotificationsPopUpProps = {
  open?: boolean;
  enrollmentId: string;
  module: number | null;
  isLoading: boolean;
  setLoading: (loading: boolean) => void;
  handleClose: () => void;
};

const DisbursementNotificationsPopup = (
  props: Readonly<NotificationsPopUpProps>
) => {
  const { open, handleClose, isLoading } = props;
  const classes = useStyles({});
  const [notificationSummary, setNotificationSummary] = React.useState<
    Array<IDisbursementEmailStatus>
  >([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState([] as any);

  const selectAllClick = (event: any) => {
    if (event.target.checked) {
      const selected = notificationSummary.map(
        (n: IDisbursementEmailStatus) => n.disbEmailId
      );
      setSelected(selected);
      return;
    }
    setSelected([]);
  };
  const isSelected = (disbEmailId: any) => selected.indexOf(disbEmailId) !== -1;

  const rowClick = (event: any, resultId: string) => {
    const selectedIndex = selected.indexOf(resultId);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, resultId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const loadDisbursementNotification = (enrollmentId: string) => {
    quickInfoApi.GetDisbursementEmailStatusByEnrollment(enrollmentId).then(
      (response: any) => {
        if (response && response.data) {
          setNotificationSummary(response.data);
        }
      },
      (exception: any) => {}
    );
  };

  const sendEmail = () => {
    quickInfoApi.SendDisbursementEmailAsync().then(
      (response: any) => {
        setSnackBarProps(() => {
          return {
            variant: "success",
            showSnackBar: true,
            messageInfo: "Email sent successfully.",
          };
        });
      },
      (exception: any) => {
        setSnackBarProps(() => {
          return {
            variant: "error",
            showSnackBar: true,
            messageInfo: exception,
          };
        });
      }
    );
  };

  const reSendEmail = () => {
    if (selected.length > 0) {
      setLoading(true);

      quickInfoApi.ReSendDisbursementEmailAsync(selected).then(
        (response: any) => {
          if (response && response.data && response.data.result == true) {
            loadDisbursementNotification(props.enrollmentId);
            setSelected([]);
            setSnackBarProps(() => {
              return {
                variant: "success",
                showSnackBar: true,
                messageInfo: "Email sent successfully.",
              };
            });
          } else {
            setSnackBarProps(() => {
              return {
                variant: "error",
                showSnackBar: true,
                messageInfo: response.data.resultStatusMessage,
              };
            });
          }
          setLoading(false);
        },
        (exception: any) => {
          setSnackBarProps(() => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo: exception,
            };
          });
          setLoading(false);
        }
      );
    }
  };
  React.useEffect(() => {
    if (props.enrollmentId != null && props.open == true) {
      loadDisbursementNotification(props.enrollmentId);
    }
  }, [props.open]);

  const beforeClose = () => {
    setNotificationSummary([]);
    handleClose();
  };

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );

  return (
    <React.Fragment>
      <Dialog
        PaperProps={{ square: true }}
        fullWidth={true}
        maxWidth="sm"
        open={open ?? false}
        onClose={beforeClose}
        aria-labelledby="summary-add-dialog-title"
      >
        <DialogTitle
          className={classes.cardTitle}
          disableTypography
          id="summary-add-dialog-title"
        >
          <Typography className={classes.cardTitle}>
            <div className={classes.headerText}>Disbursement Notifications</div>
            <IconButton
              aria-label="close"
              onClick={beforeClose}
              className={classes.dialogCloseButton}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Typography>
        </DialogTitle>
        <DialogContent>
          {open && (
            <Grid container direction="column" spacing={2} wrap="nowrap">
              <Grid
                item
                alignContent="flex-start"
                alignItems="flex-start"
                justify="flex-start"
              >
                <Grid container direction="row" spacing={3}>
                  <Grid
                    item
                    xs={12}
                    alignContent="flex-start"
                    alignItems="flex-start"
                    justify="flex-start"
                  >
                    <TableContainer
                      component={Paper}
                      square
                      className={classes.tableContainer}
                    >
                      <Table
                        stickyHeader
                        size="small"
                        className={classes.table}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">
                              <Checkbox
                                value="uncontrolled"
                                color="primary"
                                onClick={selectAllClick}
                                inputProps={{
                                  "aria-label": "uncontrolled-checkbox",
                                }}
                              />
                            </TableCell>
                            <TableCell>Notification Time</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Detail</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {isLoading ? (
                            <TableRow>
                              <StyledTableCell
                                colSpan={2}
                                className={classes.preLoaderCell}
                              >
                                <CircularProgress />
                              </StyledTableCell>
                            </TableRow>
                          ) : (
                            notificationSummary?.map(
                              (row: IDisbursementEmailStatus, index: any) => {
                                const isRowSelected = isSelected(
                                  row.disbEmailId
                                );
                                return (
                                  <TableRow
                                    key={row.disbEmailId}
                                    onClick={(event) =>
                                      rowClick(event, row.disbEmailId)
                                    }
                                    role="checkbox"
                                    selected={isRowSelected}
                                  >
                                    <TableCell align="center">
                                      <Checkbox
                                        value="uncontrolled"
                                        color="primary"
                                        inputProps={{
                                          "aria-label": "uncontrolled-checkbox",
                                        }}
                                        checked={
                                          row.status == "Delivered"
                                            ? false
                                            : isRowSelected
                                        }
                                        disabled={
                                          row.status == "Delivered" ? true : false
                                        }
                                      />
                                    </TableCell>
                                    <TableCell>
                                      {new Date(
                                        row.sentDateTime
                                      ).toLocaleDateString()}
                                    </TableCell>
                                    <TableCell><Chip label={row.status} color={row.status == "Delivered" ? "primary" : "secondary"} /></TableCell>
                                    <TableCell>
                                      {row.statusDescription}
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )
                          )}

                          {notificationSummary?.length == 0 ? (
                            <TableRow>
                              <TableCell
                                className={classes.tableEmptyRow}
                                colSpan={4}
                              >
                                No Data
                              </TableCell>
                            </TableRow>
                          ) : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={2}>
                  <Grid
                    item
                    container
                    xs={2}
                    alignContent="flex-start"
                    alignItems="flex-start"
                    justify="flex-start"
                  >
                    <ProgressSaveButton
                      text="Send"
                      onClick={reSendEmail}
                      loading={loading}
                      buttonClassName={classes.saveButton}
                    ></ProgressSaveButton>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={2}
                    alignContent="flex-start"
                    alignItems="flex-start"
                    justify="flex-start"
                  >
                    <Button
                      onClick={beforeClose}
                      size="medium"
                      color="secondary"
                      variant="contained"
                      type="button"
                    >
                      Close
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                alignContent="flex-start"
                alignItems="flex-start"
                justify="flex-start"
              ></Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
      <CustomSnackbar
        variant={snackBarProps.variant}
        message={snackBarProps.messageInfo}
        open={snackBarProps.showSnackBar}
        onClose={(event?: React.SyntheticEvent, reason?: string) => {
          setSnackBarProps((props: any) => {
            return { ...props, showSnackBar: false };
          });
        }}
      ></CustomSnackbar>
    </React.Fragment>
  );
};

export default DisbursementNotificationsPopup;
