import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Switch,
} from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import ProgramVersionAutoComplete from "../../../components/AutoComplete/ProgramVersionAutoComplete";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ReportOutput } from "../../../enums/ReportOutput";
import { ReportSort } from "../../../enums/ReportSort";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";
import { IAbsentToday } from "../../../interfaces/reports/attendance/IAbsentToday";
import * as absentTodayReportApi from "../../../api/reports/attendance/absentTodayReportApi";
import { useSelector } from "react-redux";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import ProgramScheduleSelect from "../../../components/AutoComplete/ProgramScheduleAutoComplete";
import ScheduleProgramAutoComplete from "../../../components/AutoComplete/ScheduleProgramAutoComplete";


const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      minHeight: "100%",
      height: "100%",
      maxWidth: theme.spacing(131.25),
      margin: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    paperRoot: {
      padding: theme.spacing(3, 2),
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
    },
    width100: {
      width: "100%",
    },
    cardTitle: {
      fontSize: theme.spacing(2.15),
      color: theme.palette.primary.headerText,
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
    switch: {
      display: "flex",
      alignItems: "flex-end",
      padding: theme.spacing(0, 0.5),
      height: theme.spacing(6.6),
    },
    fieldSet: {
      marginTop: theme.spacing(2),
    },
  })
);

const AbsentTodayReport = (props: any) => {
  const classes = useStyles({});
  const { report } = props;
  let reportOverview = { report: report } as IReportOverview;
  //#endregion Global Const

  //#region State Objects
  const [preview, setPreview] = React.useState<any>();
  const [previewLoader, setPreviewLoader] = React.useState<boolean>();
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );
  const [inActiveSchedule, setInActiveSchedule] = React.useState<boolean>(
    false
  );
  const [inActiveProgramVersion, setInActiveProgramVersion] = React.useState<
    boolean
  >(false);
  const defaultFilters = {
    inActiveSchedule: false,
    inActiveProgramVersion: false,
    scheduleIds: [],
    programVersionIds: [],
    month: undefined as Date | undefined,
    reportDate: new Date() as Date | undefined,
    exportType: 1 as ReportOutput,
    sortOrder: "name" as string | undefined,
  };
  const [model, setModel] = React.useState<any>(defaultFilters);
  //#endregion State Objects End

  //#region Input Handlers
  const handleChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    switch (name) {
      case "inActiveSchedule":
        setInActiveSchedule(event.target.checked);
        break;
      case "inActiveProgramVersion":
        setInActiveProgramVersion(event.target.checked);
        break;
    }
  };

  const setSchedule = (schedules: any) => {
    let newSchedules: string[] = [];
    if (schedules !== null) {
      if (Array.isArray(schedules)) {
        newSchedules = schedules.map((gr: any) => gr.value);
      } else {
        newSchedules.push(schedules.value);
      }
    }
    return newSchedules;
  };
  const setProgramVersion = (PrgVersions: any) => {
    let newProgramVersion: string[] = [];
    if (PrgVersions !== null) {
      if (Array.isArray(PrgVersions)) {
        newProgramVersion = PrgVersions.map((gr: any) => gr.value);
      } else {
        newProgramVersion.push(PrgVersions.value);
      }
    }
    return newProgramVersion;
  };

  const exportTypes: Array<DropDownListItem> = [
    { text: "PDF", id: "1" },
    { text: "Excel", id: "2" },
    { text: "Word", id: "3" },
  ];

  const exportTypeAutoComplete = {
    options: exportTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
    const item = items.find((opt) => {
      if (opt.id == value) return opt;
    });
    return item || ({} as DropDownListItem);
  };
  //#endregion Input Handlers

  //#region Form Handler

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    setModel({ ...updatedModel });
  };
  const data = useForm<any>({ mode: "onBlur" });
  const { handleSubmit } = data;
  const onSubmit = async (isPreview: any): Promise<void> => {
    let params = (await getReportParameters()) as IAbsentToday;
    const validation = await data.triggerValidation();

    if (validation && params.reportDate) {
      params.reportDate = new Date(params.reportDate).toLocaleDateString();
      if (isPreview) {
        setPreviewLoader(true);
        absentTodayReportApi
          .generateReport(params, isPreview)
          .then((res: any) => {
            if (res) {
              res.arrayBuffer().then((buffer: any) => {
                setPreview(buffer);
                setPreviewLoader(false);
              });
            } else setPreviewLoader(false);
          });
      } else await absentTodayReportApi.generateReport(params);
    }
  };

  const filterHandler = (data: any) => {
    if (data) {
      setInActiveSchedule(data.inActiveSchedule);
      setInActiveProgramVersion(data.inActiveProgramVersion);
      setModel({ ...data });
    } else {
      setInActiveSchedule(false);
      setInActiveProgramVersion(false);
      setModel(defaultFilters);
    }
  };

  const handleSortOrderChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let updatedModel = model;
    (updatedModel as any)[
      "sortOrder"
    ] = (event.target as HTMLInputElement).value;
    setModel({ ...updatedModel });
  };

  const getReportParameters = async () => {
    const success = await data.triggerValidation();
    if (model && success) {
      let params: IAbsentToday = {
        scheduleIds: model?.scheduleIds,
        programVersionIds: model?.programVersionIds,
        reportDate: model?.reportDate,
        exportType: model?.exportType,
        campusId: userSelectedCampus,
        inActiveSchedule: inActiveSchedule,
        inActiveProgramVersion: inActiveProgramVersion,
        sortOrder: model.sortOrder,
      };
      return params;
    }
    return {} as IAbsentToday;
  };
  //#endRegion Form Handler

  let parameters = (
    <div className={[classes.root, classes.width100].join(" ")}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <React.Fragment>
              <Grid item md={9} sm={9} xs={12}>
                <ProgramVersionAutoComplete
                  id="programVersionIds"
                  name="programVersionIds"
                  label="Program Versions"
                  multiple={true}
                  params={{
                    inActiveProgramVersion: inActiveProgramVersion,
                  }}
                  selectAll={true}
                  valueFilter={
                    model?.programVersionIds
                      ? {
                          key: "value",
                          values: model?.programVersionIds,
                        }
                      : undefined
                  }
                  filterHandle={(data: any) => {
                    handleFieldOnChange(
                      "programVersionIds",
                      data,
                      setProgramVersion
                    );
                  }}
                />
              </Grid>
              <Grid item md={3} sm={3} xs={12} className={classes.switch}>
                <FormControlLabel
                  label="Include Inactive Values"
                  control={
                    <Switch
                      checked={inActiveProgramVersion}
                      value="inActiveProgramVersion"
                      onChange={handleChange("inActiveProgramVersion")}
                      color="primary"
                    />
                  }
                />
              </Grid>
            </React.Fragment>
            <React.Fragment>
              <Grid item sm={9} md={9}>
                <ScheduleProgramAutoComplete
                  label="Schedule"
                  programVersionList={model?.programVersionIds}
                  params={{
                    inActiveSchedule: inActiveSchedule,
                  }}
                  multiple={true}
                  valueFilter={
                    model?.scheduleIds
                      ? {
                          key: "value",
                          values: model?.scheduleIds,
                        }
                      : undefined
                  }
                  filterHandle={(data: any) => {
                    handleFieldOnChange("scheduleIds", data, setSchedule);
                  }}
                  name="newSchedule"
                  id="newSchedule"
                  key="newSchedule"
                />
              </Grid>
              <Grid item md={3} sm={3} xs={12} className={classes.switch}>
                <FormControlLabel
                  label="Include Inactive Values  "
                  control={
                    <Switch
                      checked={inActiveSchedule}
                      value="inActiveSchedule"
                      onChange={handleChange("inActiveSchedule")}
                      color="primary"
                    />
                  }
                />
              </Grid>
            </React.Fragment>            
            <React.Fragment>
              <Grid item md={6} sm={6} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    id="reportDate"
                    name="reportDate"
                    key="reportDateKey"
                    label="Report Date"
                    value={model.reportDate ? model.reportDate : new Date()}
                    required
                    onChange={(e: any, value: any) =>
                      handleFieldOnChange(
                        "reportDate",
                        value ? value : undefined
                      )
                    }
                    className={classes.width100}
                    KeyboardButtonProps={{
                      "aria-label": "report date",
                    }}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.reportDate ? "Date is required." : undefined
                    }
                    error={!!data.errors["reportDate"]}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Autocomplete
                  {...exportTypeAutoComplete}
                  autoComplete
                  includeInputInList
                  onChange={(e: any, value: any) => {
                    handleFieldOnChange(
                      "exportType",
                      value ? value : undefined,
                      (v: any) => v?.id
                    );
                  }}
                  value={getSelectedItem(exportTypes, model.exportType)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Export Type *"
                      name="exportType"
                      error={!!data.errors.exportType}
                      inputRef={data.register({ required: true })}
                      helperText={
                        data.errors.exportType
                          ? "Export Type is required."
                          : undefined
                      }
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </React.Fragment>
            <React.Fragment>
              <Grid item md={12} sm={12} xs={12}>
                <FormControl component="fieldset" className={classes.fieldSet}>
                  <FormLabel component="legend">Sort Order</FormLabel>
                  <RadioGroup
                    row
                    aria-label="sortOrder"
                    name="sortOrder"
                    value={model.sortOrder}
                    onChange={handleSortOrderChange}
                  >
                    <FormControlLabel
                      labelPlacement="start"
                      value="name"
                      control={<Radio />}
                      label="Name"
                    />
                    <FormControlLabel
                      labelPlacement="start"
                      value="enrollmentId"
                      control={<Radio />}
                      label="Enrollment ID"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </React.Fragment>
          </Grid>
        </form>
      </FormContext>
    </div>
  );
  reportOverview.parameters = parameters;

  return (
    <ReportOverview
      reportOverview={reportOverview}
      filterHandler={filterHandler}
      getReportParameters={getReportParameters}
      exportHandler={onSubmit}
      preview={preview}
      previewLoader={previewLoader}
    />
  );
};

export default AbsentTodayReport;
