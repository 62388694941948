import { useSelector } from "react-redux";
import { checkPermissions } from "../../utils/securityFunctions"

type AccessManagerProps = {
    user?: any;
    allowedPermissions: Array<string>;
    children?: any;
    renderNoAccess: () => React.ReactNode;
    accessCheck?: any;
    extraAccessData?: any;
}
const AccessManager = (props: AccessManagerProps) => {
    const { user,
        allowedPermissions,
        children,
        renderNoAccess,
        accessCheck,
        extraAccessData } = props;

    let permitted;
    // when an accessCheck function is provided, ensure that passes as well as the permissions
    if (accessCheck) {
        permitted =
            accessCheck(extraAccessData, user) &&
            checkPermissions(allowedPermissions);
    } else {
        // otherwise only check permissions
        permitted = checkPermissions(allowedPermissions);
    }
    if (permitted) {
        return children;
    }
    return renderNoAccess();
};

export default AccessManager;