import {reportsPageViewModeConstants} from '../constants/actions.constants';

   const changeMode = (mode:string) => {
    return (dispatch:any) => {
        let data:any = {mode};
      dispatch({ type: reportsPageViewModeConstants.REPORTS_PAGE_VIEW_MODE_CHANGED, data });
    };
  }

  export const reportsPageViewModeActions = {
      changeMode
  };