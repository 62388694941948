import React, { useRef } from "react";
import clsx from "clsx";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import SearchIcon from "@material-ui/icons/Search";
import { apiAutoCompleteConfig } from "../../constants/apiAutoComplete.config";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { useSelector } from "react-redux";

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& label.Mui-focused": {
				color: theme.palette.primary.white
			},
			"& input": {
				color: theme.palette.black,
				opacity: 1,
				fontWeight: theme.typography.fontWeightMedium,
				fontSize: 14,
				padding: 10
			},
			"& input::placeholder": {
				fontWeight: theme.typography.fontWeightMedium,
				fontSize: 13
			},
			"& .MuiInputBase-root": {
				backgroundColor: theme.palette.site.secondary,
				borderRadius: 5
			}
		}
	})
)(TextField);

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {
			width: "35%"
		},
		searchIcon: {
			fontSize: 30,
			paddingLeft: 10,
			color: theme.palette.icon.primary,
			cursor: "pointer"
		}
	})
);

const AdmissionsRepsAutoComplete = (props: FilterParams) => {
	const classes = useStyles({});
	const [admissionsRepSelected, setAdmissionsRepSelected] = React.useState(
		false
	);

	const userSelectedCampusId = useSelector((state: any) =>
		state.userstate[state.session.user.userId]
			? state.userstate[state.session.user.userId].selectedCampus
			: null
	);

	const filterHandle = props.filterHandle;

	const inputRef: any = useRef(null);

	const magnifyOnClick = (e: any) => {
		if (inputRef.current !== undefined) {
			inputRef.current.focus();
		}
	};

	const admissionsRepOnChange = (e: any) => {
		if (filterHandle) {
			filterHandle(e);
		}

		setAdmissionsRepSelected(e);
	};

	return (
		<div className={clsx(classes.divWidth)}>
			<ApiAutoComplete
				config={apiAutoCompleteConfig.admissionsReps}
				reqParams={{ campusId: userSelectedCampusId }}
				optionName={(option: any) => {
					return option.text;
				}}
				includeAllValue={true}
				multiple={false}
				loading={!admissionsRepSelected}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
				label=""
				classes={{
					option: "text-black"
				}}
				onChange={(e: any) => {
					admissionsRepOnChange(e);
				}}
				renderInput={(params: any) => (
					<ThemeTextField
						{...params}
						fullWidth
						InputProps={{
							...params.InputProps,
							startAdornment: (
								<React.Fragment>
									{!admissionsRepSelected ? (
										<SearchIcon
											className={classes.searchIcon}
											onClick={(e: any) => {
												magnifyOnClick(e);
											}}
										></SearchIcon>
									) : null}
								</React.Fragment>
							),
							inputRef: props.inputRef ? props.inputRef : inputRef,
							placeholder: "Admissions Rep.",
							disableUnderline: true
						}}
					/>
				)}
			/>
		</div>
	);
};

export default AdmissionsRepsAutoComplete;
