import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CampusForCurrentUserAutoComplete from "../../../components/AutoComplete/CampusForCurrentUserAutoComplete";
import { Grid } from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import { ICoeCplReport } from "../../../interfaces/reports/attendance/ICoeCplReport";
import * as CoeCplReportAPI from "../../../api/reports/attendance/CoeCplReportAPI";
import { ReportOutput } from "../../../enums/ReportOutput";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import ProgramVersionAutoComplete from "../../../components/AutoComplete/ProgramVersionAutoComplete";
import moment from "moment";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      minHeight: "100%",
      height: "100%",
      maxWidth: "1050px",
      padding: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    startDateField: {
      width: "100%",
    },
  })
);

const CoeCplReport = (props: any) => {
  const { report } = props;
  let reportOverview = { report: report } as IReportOverview;
  const classes = useStyles({});
  const [preview, setPreview] = React.useState<any>();
  const [previewLoader, setPreviewLoader] = React.useState<boolean>();

  const data = useForm<any>({ mode: "onBlur" });
  const [model, setModel] = React.useState({
    campusId: "",
    startDate: undefined as Date | undefined,
    endDate: undefined as Date | undefined,
    exportType: 1 as ReportOutput,
    programId: [],
    reportType: "",
  });

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    if (
      (fieldId == "reportType" || fieldId == "exportType") &&
      value == undefined
    )
      return;

    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    if (fieldId === "campusId") {
      (updatedModel as any)["programId"] = [];
      (updatedModel as any)["leadGroupIds"] = [];
    }
    // if (fieldId === 'programId') {
    // 	(updatedModel as any)['programId'] = [];
    // 	(updatedModel as any)['leadGroupIds'] = [];
    // }
    // if (fieldId === 'reportType') {
    // 	(updatedModel as any)['studentGroupIds'] = [];
    // }

    setModel({ ...updatedModel });
  };

  const { handleSubmit } = data;

  const exportTypes: Array<DropDownListItem> = [
    { text: "PDF", id: "1" },
    { text: "Excel", id: "2" },
    // { text: "Word", id: "3" },
  ];


  // const exportTypes: Array<DropDownListItem> = [{ text: "Excel", id: "2" }];
  // const exportTypes: Array<DropDownListItem> = [{ text: "PDF", id: "1" }];

  const reportTypes: Array<DropDownListItem> = [
    { text: "Detail", id: "Detail" },
    { text: "COE-CPL", id: "COECPL" },
  ];

  const onSubmit = async (isPreview: any) => {
    let params = (await getReportParameters()) as ICoeCplReport;
    const validation = await data.triggerValidation();
    if (validation) {
      if (isPreview) {
        setPreviewLoader(true);
        CoeCplReportAPI.generateReport(params, isPreview).then((res: any) => {
          if (res) {
            res.arrayBuffer().then((buffer: any) => {
              setPreview(buffer);
              setPreviewLoader(false);
            });
          } else setPreviewLoader(false);
        });
      } else await CoeCplReportAPI.generateReport(params);
    }
  };

  const exportTypeAutoComplete = {
    options: exportTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const reportTypeAutoComplete = {
    options: reportTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const filterHandler = (data: any) => {
    if (data) {
      setModel(data);
    } else {
      setModel({
        campusId: "",
        startDate: undefined as Date | undefined,
        endDate: undefined as Date | undefined,
        //exportType: 1 as ReportOutput,
        exportType: 2 as ReportOutput,

        programId: [],
        reportType: "COECPL",
      });
    }
  };
  const setProgramVersion = (PrgVersions: any) => {
    let newProgramVersion: string[] = [];
    if (PrgVersions !== null) {
      if (Array.isArray(PrgVersions)) {
        newProgramVersion = PrgVersions.map((gr: any) => gr.value);
      } else {
        newProgramVersion.push(PrgVersions.value);
      }
    }
    return newProgramVersion;
  };

  const getReportParameters = async () => {
    const success = await data.triggerValidation();
    if (model && success) {
      let startDate = model.startDate ? model.startDate : undefined;
      let EndDate = model.endDate ? model.endDate : undefined;
      let params: ICoeCplReport = {
        campusId: model.campusId,
        startDate: startDate,
        endDate: EndDate,
        exportType: model.exportType,
        programId: model.programId,
        clientDateTime: moment().format("MM/DD/yyyy hh:mm A"),
        reportType: model.reportType,
      };
      return params;
    }
    return {} as ICoeCplReport;
  };

  const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
    const item = items.find((opt) => {
      if (opt.id === value) return opt;
    });
    return item || ({} as DropDownListItem);
  };

  let parameters = (
    <div className={classes.root}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} sm={6} xs={12}>
              <CampusForCurrentUserAutoComplete
                id="toCampus"
                name="toCampus"
                label="Campus *"
                filterHandle={(v: any) => {
                  handleFieldOnChange("campusId", v ? v.value : undefined);
                }}
                valueFilter={
                  model.campusId
                    ? {
                        key: "value",
                        values: [model.campusId],
                      }
                    : undefined
                }
                error={!!data.errors.toCampus}
                inputRef={data.register({ required: true })}
                helperText={
                  data.errors.toCampus ? "Campus is required." : undefined
                }
              />
            </Grid>

            <Grid item md={3} sm={3} xs={12}>
              <Autocomplete
                {...reportTypeAutoComplete}
                autoComplete
                includeInputInList
                onChange={(e: any, value: any) => {
                  handleFieldOnChange(
                    "reportType",
                    value ? value : undefined,
                    (v: any) => v?.id
                  );
                }}
                value={getSelectedItem(
                  reportTypes,
                  model.reportType.toString()
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Report Type *"
                    name="reportType"
                    error={!!data.errors.reportType}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.reportType
                        ? "Report Type is required."
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item md={3} sm={3} xs={12}>
              <Autocomplete
                {...exportTypeAutoComplete}
                autoComplete
                includeInputInList
                onChange={(e: any, value: any) => {
                  handleFieldOnChange(
                    "exportType",
                    value ? value : undefined,
                    (v: any) => v?.id
                  );
                }}
                value={getSelectedItem(
                  exportTypes,
                  model.exportType.toString()
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Export Type"
                    name="exportType"
                    error={!!data.errors.exportType}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.exportType
                        ? "Export Type is required."
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="startDate"
                  name="startDate"
                  label="Date From *"
                  value={model.startDate ? model.startDate : null}
                  onChange={(e: any, value: any) =>
                    handleFieldOnChange("startDate", value ? value : undefined)
                  }
                  className={classes.startDateField}
                  KeyboardButtonProps={{
                    "aria-label": "report date",
                  }}
                  error={!!data.errors["startDate"]}
                  innerRef={(ref: any) => {
                    data.register("startDate", {
                      required: true,
                      validate: {
                        beforeEndDate: (value) =>
                          new Date(value) <
                            new Date(data.getValues()["endDate"]) ||
                          "Start Date must be before end date",
                      },
                    });
                    data.setValue("startDate", model.startDate);
                  }}
                  helperText={
                    data.errors["startDate"]
                      ? (data.errors["startDate"] as FieldError).message
                      : undefined
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="endDate"
                  name="endDate"
                  label="Date To *"
                  value={model.endDate ? model.endDate : null}
                  onChange={(e: any, value: any) =>
                    handleFieldOnChange("endDate", value ? value : null)
                  }
                  className={classes.startDateField}
                  KeyboardButtonProps={{
                    "aria-label": "end date",
                  }}
                  error={!!data.errors["endDate"]}
                  innerRef={(ref: any) => {
                    data.register("endDate", {
                      required: true,
                      validate: {
                        beforeEndDate: (value) =>
                          new Date(value) >
                            new Date(data.getValues()["startDate"]) ||
                          "End Date must be after Start date",
                      },
                    });
                    data.setValue("endDate", model.endDate);
                  }}
                  helperText={
                    data.errors["endDate"]
                      ? (data.errors["endDate"] as FieldError).message
                      : undefined
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <ProgramVersionAutoComplete
                campusId={model.campusId}
                id="programId"
                name="programId"
                label="Program Version *"
                multiple={true}
                error={!!data.errors.programId}
                helperText={
                  data.errors.programId
                    ? (data.errors.programId as FieldError).message
                    : undefined
                }
                inputRef={data.register({
                  validate: {
                    atLeastOneRequired: (value) =>
                      (model.programId && model.programId.length > 0) ||
                      "Program Version(s) are required.",
                  },
                })}
                // params={{
                //   inActiveProgramVersion: inActiveProgramVersion,
                // }}
                selectAll={true}
                valueFilter={
                  model?.programId
                    ? {
                        key: "value",
                        values: model?.programId,
                      }
                    : undefined
                }
                filterHandle={(data: any) => {
                  handleFieldOnChange("programId", data, setProgramVersion);
                }}
              />
            </Grid>
          </Grid>
        </form>
      </FormContext>
    </div>
  );
  reportOverview.parameters = parameters;

  return (
    <ReportOverview
      reportOverview={reportOverview}
      filterHandler={filterHandler}
      getReportParameters={getReportParameters}
      exportHandler={onSubmit}
      preview={preview}
      previewLoader={previewLoader}
    />
  );
};
export default CoeCplReport;
