import React from "react";
import {
  makeStyles,
  Card,
  createStyles,
  CardContent,
  Grid,
  Paper,
  Table,
  withStyles,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  IconButton,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { StudentProfile } from "../../../../interfaces/student/profile/StudentProfile";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useForm } from "react-hook-form";
import CloseIcon from "@material-ui/icons/Close";
import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";
import AddressTypesAutoComplete from "../../../../components/AutoComplete/AddressTypesAutoComplete";
import CountryAutoComplete from "../../../../components/AutoComplete/CountryAutoComplete";
import CountyAutoComplete from "../../../../components/AutoComplete/CountyAutoComplete";
import StatesAutoComplete from "../../../../components/AutoComplete/StatesAutoComplete";
import * as AddressApi from "../../../../api/student/profle/contactInfo/OtherAddressApi";
import { OtherAddress } from "../../../../interfaces/student/profile/contactInfo/OtherAddress";

const StyledTableCell = withStyles((theme: any) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.site.secondary,
      fontSize: 17,
      color: theme.palette.black,
      fontWeight: "bold",
      textAlign: "center",
      boxShadow: "1px 1px 0px 0px rgba(0,0,0,0.2)",
      "&:first-child": {
        width: "42px",
        padding: theme.spacing(0, 1),
      },
    },
    body: {
      fontSize: 16,
      color: theme.palette.black,
      fontWeight: 500,
      cursor: "pointer",
      textAlign: "left",
      "&:first-child": {
        width: "42px",
        padding: theme.spacing(0, 1),
      },
    },
  })
)(TableCell);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%",
    },
    saveButton: {
      width: 140,
    },
    tableContainer: {
      height: "400px",
    },
    tableEmptyRow: {
      height: "350px",
      textAlign: "center",
      fontSize: 20,
      opacity: 0.3,
    },
    table: {
      width: "100%",
    },
    iconColor: {
      color: theme.palette.black,
    },
    searchField: {
      "&::placeholder": {
        color: "#000000",
      },
    },
    selectionCheckBox: {
      padding: theme.spacing(0),
    },
    preLoaderCell: {
      textAlign: "center",
      padding: theme.spacing(1),
    },
    cardMain: {
      marginTop: theme.spacing(2),
    },
    popOverButtons: {
      display: "flex",
      justifyContent: "space-evenly",
      flexDirection: "row",
      padding: 5,
    },
    typography: {
      padding: theme.spacing(2),
    },
    lineThru: {
      textDecoration: "line-through",
    },
    cardWrapper: {
      marginBottom: theme.spacing(2),
    },
    root: {
      paddingTop: theme.spacing(2),
      minHeight: "100%",
      height: "100%",
      width: "100%",
      margin: "auto",
    },
    paperRoot: {
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
  })
);

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {},
  })
)(TextField);

type AddressPageProps = {
  setSnackBarProps?: any;
  otherContactId?: string;
  leadId?: string;
};
const OtherAddressPage = (props: any) => {
  const classes = useStyles({});

  const data = useForm<OtherAddress>({ mode: "onBlur" });

  const selectedStudent: StudentProfile = useSelector(
    (state: any) => state.student
  ) as StudentProfile;

  const [addresses, setAddresses] = React.useState<OtherAddress[]>([]);
  const [isCreatingAddress, setIsCreatingAddress] = React.useState(false);
  const [newAddress, setNewAddress] = React.useState<OtherAddress | null>(
    null
  );

  const [selectedCountry, setSelectedCountry] = React.useState<string>("");

  React.useEffect(() => {
    getAddress();
  }, [props.otherContactId]);

  const handleAddAddressClick = () => {
    setNewAddress({...new OtherAddress(), leadId:props.leadId ?? "", otherContactId: props.otherContactId ?? "" });
    setIsCreatingAddress(true);
  };

  // Handler to update address details in the form
  const handleFieldOnChange = (field: keyof OtherAddress, value: any) => {
    if (newAddress) {
      setNewAddress({ ...newAddress, [field]: value });
    }
  };

  const getAddress = () => {
    if (selectedStudent.selectedEnrollment !== undefined) {
      if (
        selectedStudent.leadId !== undefined &&
        selectedStudent.leadId.length > 0
      ) {
        AddressApi.getAll(selectedStudent.leadId, props.otherContactId).then(
          (response: any) => {
            setAddresses(response);
          },
          (exception: any) => {}
        );
      }
    }
  };

  const handleAddressCancel = () => {
    setIsCreatingAddress(false);
    setNewAddress(null);
  };

  const deleteAddress = (id: any) => {
    setLoading(true);
    AddressApi.deleteAddress(selectedStudent.leadId, id).then(
      (response: any) => {
        props.setSnackBarProps(() => {
          return {
            variant: "success",
            showSnackBar: true,
            messageInfo: "Address deleted successfully.",
          };
        });
        setDeletePop(undefined);
        getAddress();
        setIsCreatingAddress(false);
        setLoading(false);
      },
      (exception: any) => {
        props.setSnackBarProps(() => {
          return {
            variant: "error",
            showSnackBar: true,
            messageInfo: exception,
          };
        });
        setLoading(false);
      }
    );
  };

  const editAddress = (id: any) => {
    if (selectedStudent.selectedEnrollment !== undefined) {
      if (
        selectedStudent.leadId !== undefined &&
        selectedStudent.leadId.length > 0
      ) {
        AddressApi.getAddress(selectedStudent.leadId, id).then(
          (response: any) => {
            setNewAddress(response);
            setIsCreatingAddress(true);
          },
          (exception: any) => {}
        );
      }
    }
  };

  const handleSaveAddress = () => {
    if (newAddress) {
      data.triggerValidation().then((validation: any) => {
        if (validation) {
          setLoading(true);

          if (newAddress.id == "") {
            AddressApi.postAddress(selectedStudent.leadId, {
              ...newAddress,
              id: newAddress.id,
            } as any).then(
              (response: any | null) => {
                if (response) {
                  props.setSnackBarProps(() => {
                    return {
                      variant: "success",
                      showSnackBar: true,
                      messageInfo: "Address saved successfully.",
                    };
                  });
                  setIsCreatingAddress(false);
                  getAddress();
                } else {
                  props.setSnackBarProps(() => {
                    return {
                      variant: "error",
                      showSnackBar: true,
                      messageInfo:
                        "Address update could not be completed. Please try again.",
                    };
                  });
                }
                setLoading(false);
              },
              (exception: any) => {
                setLoading(false);
                props.setSnackBarProps(() => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: exception,
                  };
                });
              }
            );
          } else {
            AddressApi.updateAddress(selectedStudent.leadId, {
              ...newAddress,
              id: newAddress.id,
            } as any).then(
              (response: any | null) => {
                if (response) {
                  props.setSnackBarProps(() => {
                    return {
                      variant: "success",
                      showSnackBar: true,
                      messageInfo: "Address updated successfully.",
                    };
                  });
                  setIsCreatingAddress(false);
                  getAddress();
                } else {
                  props.setSnackBarProps(() => {
                    return {
                      variant: "error",
                      showSnackBar: true,
                      messageInfo:
                        "Address update could not be completed. Please try again.",
                    };
                  });
                }
                setLoading(false);
              },
              (exception: any) => {
                setLoading(false);
                props.setSnackBarProps(() => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: exception,
                  };
                });
              }
            );
          }
        }
      });
    }
  };

  const [deletePop, setDeletePop] = React.useState<(() => void) | undefined>(
    undefined
  );
  const [loading, setLoading] = React.useState<boolean>(false);

  const AddressValidation = (errorName: any) => {
    if (errorName?.type === "validate") {
      return "Address Line 1 is Required.";
    } else return null;
  };
  const AddressTypeValidation = (errorName: any) => {
    if (errorName?.type === "validate") {
      return "Address Type is Required.";
    } else return undefined;
  };
  const ZipValidation = (errorName: any) => {
    if (errorName?.type === "validate") {
      return !newAddress?.zip
        ? "Zip is Required."
        : "The correct format of Zip is 5 digits or 5digits-4digits.";
    } else return null;
  };
  const CityValidation = (errorName: any) => {
    if (errorName?.type === "validate") {
      return "City is Required.";
    } else return undefined;
  };
  const CountryValidation = (errorName: any) => {
    if (errorName?.type === "validate") {
      return "Country is Required.";
    } else return undefined;
  };
  const StateValidation = (errorName: any) => {
    if (errorName?.type === "validate") {
      return "State is Required.";
    } else return undefined;
  };

  return (
    <Card square={true} className={classes.cardWrapper}>
      {/* <CustomCardTitle title={"Addresses"}></CustomCardTitle> */}
      <CardContent>
        <Grid container direction="row" spacing={2}>
          {/* Table to Display Existing Addresses */}
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="address table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Type</TableCell>
                    <TableCell align="left">Address</TableCell>
                    <TableCell align="left">City, State, Zip</TableCell>
                    <TableCell align="left">County</TableCell>
                    <TableCell align="left">Country</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Is Mailing Address</TableCell>
                    <TableCell align="left">Edit</TableCell>
                    <TableCell align="left">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {addresses.map((address) => (
                    <TableRow key={address.id}>
                      <TableCell align="left">{address.addressType}</TableCell>
                      <TableCell align="left">{address.address1}</TableCell>
                      <TableCell align="left">
                        {address.city +
                          " " +
                          address.state +
                          " " +
                          address.zip}
                      </TableCell>
                      <TableCell align="left">{address.county}</TableCell>
                      <TableCell align="left">{address.country}</TableCell>
                      <TableCell align="left">{address.status}</TableCell>
                      <TableCell align="left">
                        {address.isMailingAddress ? "Yes" : "No"}
                      </TableCell>
                      <TableCell align="left" style={{ width: 20 }}>                      
                        <Tooltip title="Edit Address" style={{ padding: 0 }}>
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={(e: any) => {
                              editAddress(address.id);
                            }}
                          >
                            <EditIcon></EditIcon>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="left" style={{ width: 20 }}>
                        <Tooltip title="Delete Address" style={{ padding: 0 }}>
                          <IconButton
                            onClick={() => {
                              setDeletePop(
                                () => () => deleteAddress(address.id)
                              );
                            }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* "Add Address" Button */}
          <Grid item xs={12}>
            {!isCreatingAddress && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddAddressClick}
                size="small"
              >
                Add Address
              </Button>
            )}
          </Grid>

          {/* Form for New Address Entry */}
          {isCreatingAddress && newAddress && (
            <React.Fragment>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1">Add New Address</Typography>
                    <Grid container direction="row" spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <AddressTypesAutoComplete
                          filterHandle={(v: any) => {
                            handleFieldOnChange(
                              "addressTypeId",
                              v ? v.value : undefined
                            );
                          }}
                          id="addressTypeId"
                          name="addressTypeId"
                          inputRef={data.register({
                            validate: (value: any) => {
                              return value.trim().length > 0;
                            },
                          })}
                          helperText={AddressTypeValidation(
                            data.errors.addressTypeId
                          )}
                          error={!!data.errors.addressTypeId}
                          valueFilter={
                            newAddress && newAddress.addressTypeId
                              ? {
                                  key: "value",
                                  values: [newAddress.addressTypeId],
                                }
                              : undefined
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <CountryAutoComplete
                          filterHandle={(v: any) => {
                            handleFieldOnChange(
                              "countryId",
                              v ? v.value : undefined
                            );
                            setSelectedCountry(v ? v.text : undefined);
                            data.clearError("state");
                          }}
                          id="Country"
                          name="Country"
                          valueFilter={
                            newAddress && newAddress.countryId
                              ? {
                                  key: "value",
                                  values: [newAddress.countryId],
                                }
                              : undefined
                          }
                          inputRef={data.register({
                            validate: (value: any) => {
                              return value.trim().length > 0;
                            },
                          })}
                          helperText={CountryValidation(data.errors.country)}
                          error={!!data.errors.country}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <CountyAutoComplete
                          filterHandle={(v: any) => {
                            handleFieldOnChange(
                              "countyId",
                              v ? v.value : undefined
                            );
                          }}
                          id="County"
                          name="County"
                          valueFilter={
                            newAddress && newAddress.countyId
                              ? {
                                  key: "value",
                                  values: [newAddress.countyId],
                                }
                              : undefined
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ThemeTextField
                          id="address1"
                          name="address1"
                          InputLabelProps={{ shrink: !!newAddress.address1 }}
                          className={classes.textField}
                          label="Address Line 1 *"
                          margin="normal"
                          value={newAddress.address1 ?? ""}
                          inputRef={data.register({
                            validate: (value: any) => {
                              handleFieldOnChange("address1", value);
                              return value.trim().length > 0;
                            },
                          })}
                          helperText={AddressValidation(data.errors.address1)}
                          error={!!data.errors.address1}
                          onChange={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange(
                              "address1",
                              v ? v.target.value : undefined
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ThemeTextField
                          id="address2"
                          name="address2"
                          className={classes.textField}
                          label="Address Line 2"
                          margin="normal"
                          value={newAddress.address2 ?? ""}
                          onChange={(v: any) => {
                            handleFieldOnChange(
                              "address2",
                              v ? v.target.value : undefined
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <ThemeTextField
                          id="City"
                          name="City"
                          className={classes.textField}
                          label="City *"
                          margin="none"
                          value={newAddress.city ?? ""}
                          onChange={(v: any) => {
                            handleFieldOnChange(
                              "city",
                              v ? v.target.value : undefined
                            );
                          }}
                          inputRef={data.register({
                            validate: (value: any) => {
                              handleFieldOnChange("city", value);
                              return value.trim().length > 0;
                            },
                          })}
                          helperText={CityValidation(data.errors.city)}
                          error={!!data.errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <StatesAutoComplete
                          id="stateId"
                          name="States"
                          label={
                            selectedCountry === "USA" || !selectedCountry
                              ? "State *"
                              : "State"
                          }
                          countryId={newAddress.countryId}
                          valueFilter={
                            newAddress && newAddress.stateId
                              ? {
                                  key: "value",
                                  values: [newAddress.stateId],
                                }
                              : undefined
                          }
                          filterHandle={(v: any) => {
                            handleFieldOnChange(
                              "stateId",
                              v ? v.value : undefined
                            );
                          }}
                          inputRef={data.register({
                            validate: (value: any) => {
                              return selectedCountry === "USA" ||
                                !selectedCountry
                                ? value.trim().length > 0
                                : true;
                            },
                          })}
                          helperText={StateValidation(data.errors.state)}
                          error={!!data.errors.state}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <ThemeTextField
                          id="zipCode"
                          name="zipCode"
                          className={classes.textField}
                          label="Zip *"
                          margin="none"
                          value={newAddress.zip ?? ""}
                          inputRef={data.register({
                            validate: (value: any) => {
                              handleFieldOnChange("zip", value);
                              return (
                                value.trim().length > 0 &&
                                /^(\d{5}|\d{5}-\d{4})$/.test(value)
                              );
                            },
                          })}
                          helperText={ZipValidation(data.errors.zip)}
                          error={!!data.errors.zip}
                          onChange={(v: any) => {
                            handleFieldOnChange(
                              "zip",
                              v ? v.target.value : undefined
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={newAddress.isMailingAddress}
                              onChange={(e) =>
                                handleFieldOnChange(
                                  "isMailingAddress",
                                  e.target.checked
                                )
                              }
                              color="primary"
                            />
                          }
                          label="Is Mailing Address"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ProgressSaveButton
                          text="Save Address"
                          onClick={() => {
                            handleSaveAddress();
                          }}
                          size="small"
                          loading={loading}
                          buttonClassName={classes.saveButton}
                        ></ProgressSaveButton>
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          onClick={handleAddressCancel}
                          style={{ marginLeft: 5 }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </CardContent>
      <Dialog
        open={!!deletePop}
        onClose={() => !loading && setDeletePop(undefined)}
      >
        <DialogTitle id="customized-dialog-title">
          <Typography variant="h6">Delete Confirmation</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => !loading && setDeletePop(undefined)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this address?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ProgressSaveButton
            loading={loading}
            onClick={() => deletePop !== undefined && deletePop()}
            text="Delete"
            type="button"
          />{" "}
          <Button
            variant="contained"
            color="default"
            onClick={() => !loading && setDeletePop(undefined)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default OtherAddressPage;
