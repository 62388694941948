import React from 'react';
import {
    makeStyles,
    createStyles,
    Grid,
    IconButton,
    Button,
    Card,
    CardContent,
    CircularProgress,
} from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import INewComponentType from '../../../../interfaces/setup/academics/ProgramDefinition/INewComponentType'
import InputField from '../../../../components/_Layout/Inputs/InputField';
import { FieldError, useForm, FormContext } from 'react-hook-form';
import { InputType } from '../../../../constants/uiConstants/inputConstants';
import ComponentTypesAutoCompleteConfig from '../../../../constants/setup/academic/ProgramVersion/CreditGradeBookComponentTypesAutoComplete';
import { CustomSnackBarProps } from '../../../../interfaces/common/CustomSnackBarProps';
import ProgressSaveButton from '../../../../components/_Layout/Buttons/ProgressSaveButton';
import StatusAutoComplete from '../../../../components/AutoComplete/StatusAutoComplete';
import ResourceAutoComplete from '../../../../components/AutoComplete/ResourceAutoComplete';
import MultiCampusAutoComplete from '../../../../components/AutoComplete/MultiCampusAutoComplete';
import { createNewComponent } from '../../../../api/setup/academics/creditCoursesApi';
import { useDispatch } from 'react-redux';
import { autoCompleteConstants } from '../../../../constants/actions.constants';

const useStyles = makeStyles((theme: any) =>
    createStyles({
        cardTitle: {
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            backgroundColor: theme.palette.site.secondary,
            padding: theme.spacing(0)
        },
        headerText: {
            float: 'left',
            padding: theme.spacing(1.5),
        },
        cardContent: {
            padding: theme.spacing(1, 3),
            height: '100%',
        },
        saveButton: {
            width: 90,
        },
        table: {
            width: '100%',
        },
        iconColor: {
            color: theme.palette.black
        },
        searchField: {
            '&::placeholder': {
                color: theme.palette.black
            }
        },
        selectionCheckBox: {
            padding: theme.spacing(0)
        },
        preLoaderCell: {
            textAlign: 'center',
            padding: theme.spacing(1)
        },
        dialogCloseButton: {
            float: "right",
        },
        courseSelectionCell: {
            color: theme.palette.paperSummarySchedulerTitle,
            cursor: 'pointer',
            fontWeight: 500
        },
        alignCenter: {
            textAlign: "center",
        },
        alignLast: {
            textAlign: 'end'
        },
        dateInput: {
            marginRight: theme.spacing(1),
            width: '100%'
        }
    })
);
type AddNewComponentProps = {
    open: boolean;
    campusId?: string;
    showSnackBar: (param: CustomSnackBarProps) => void;
    close: () => void;
};

const AddNewCreditComponent = (
    props: Readonly<AddNewComponentProps>
) => {
    const classes = useStyles({});
    const [model, setModel] = React.useState<INewComponentType>({});
    const [isLoading, setIsloading] = React.useState<boolean>(false);
    const data = useForm<INewComponentType>({ mode: "onBlur" });
    const dispatch = useDispatch();
    const { handleSubmit } = data;


    const handleFieldChange = (fieldId: keyof INewComponentType, value: any) => {
        if (model) {
            let updatedModel = model;
            (updatedModel as any)[fieldId] = value;
            setModel({ ...updatedModel });
            data.setValue(fieldId, value);
            data.triggerValidation(fieldId);
        }
    };

    const saveDetails = async () => {
        if (!isLoading) {
            setIsloading(true);
            try {
                const newDetails = await createNewComponent(model);
                props.showSnackBar({
                    variant: "success",
                    showSnackBar: true,
                    messageInfo: newDetails.message,
                });
                const dta = { key: (props.campusId ?? newDetails.details.campusId) + '_' + ComponentTypesAutoCompleteConfig.acId };
                dispatch({
                    type: autoCompleteConstants.SESSION_LOADING_AC_REMOVE_CACHE,
                    data: dta,
                });
                props.close();
            }
            catch (err) {
                props.showSnackBar({
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: (err as Error).message,
                });
            }
            setIsloading(false);
        }
    }

    React.useEffect(() => {
        if(!props.open)
        {
            setModel({});
            data.reset({});
        }
    },
        [props.open]);

    return ((props.open &&
        <Card square={true} elevation={2} >
            <CardContent>
                <Grid container direction="column" spacing={2} wrap="nowrap">
                    {(!isLoading && (<Grid
                        item
                        alignContent='flex-start'
                        alignItems='flex-start'
                        justify='flex-start'
                    >
                        <FormContext {...data}>
                            <form onSubmit={handleSubmit(saveDetails)}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={4}>
                                        <InputField
                                            type={InputType.TEXT}
                                            key="description"
                                            id="description"
                                            label="Description *"
                                            name="description"
                                            defaultValue={model?.description ?? null}
                                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                handleFieldChange("description", e.target.value);
                                            }}
                                            error={!!data.errors.description}
                                            inputRef={data.register({
                                                required: 'Description is required.',
                                            })}
                                            helperText={
                                                data.errors.description
                                                    ? (data.errors.description as FieldError).message
                                                    : undefined
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ResourceAutoComplete
                                            id="systemComponentId"
                                            name="systemComponentId"
                                            label="System component type *"
                                            valueFilter={
                                                model.systemComponentId
                                                    ? {
                                                        key: "value",
                                                        values: [model.systemComponentId],
                                                    }
                                                    : undefined
                                            }
                                            filterHandle={(v: any) => {
                                                handleFieldChange("systemComponentId", v ? v.value : null);
                                            }}
                                            error={!!data.errors.systemComponentId}
                                            inputRef={data.register({ required: "Syste component type is required." })}
                                            helperText={
                                                data.errors.systemComponentId
                                                    ? (data.errors.systemComponentId as FieldError).message
                                                    : undefined
                                            }
                                            params={
                                                {
                                                    resourceTypeId: 10
                                                }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4}
                                        alignContent='flex-end'
                                        alignItems='flex-end'
                                        justify='flex-end'
                                        className={classes.alignLast}>
                                        <IconButton aria-label="settings" onClick={props.close}>
                                            <CloseOutlinedIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={4}>
                                        <StatusAutoComplete
                                            id="statusId"
                                            name="statusId"
                                            label="Status *"
                                            valueFilter={
                                                model.statusId
                                                    ? {
                                                        key: "value",
                                                        values: [model.statusId],
                                                    }
                                                    : undefined
                                            }
                                            filterHandle={(v: any) => {
                                                handleFieldChange("statusId", v ? v.value : null);
                                            }}
                                            error={!!data.errors.statusId}
                                            inputRef={data.register({ required: "Status is required." })}
                                            helperText={
                                                data.errors.statusId
                                                    ? (data.errors.statusId as FieldError).message
                                                    : undefined
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                    </Grid>
                                </Grid>
                            </form>
                        </FormContext>
                    </Grid>))
                        || <div className={classes.alignCenter}><CircularProgress /></div>}
                    <Grid
                        item
                        alignContent='flex-start'
                        alignItems='flex-start'
                        justify='flex-start'
                    >
                        <Grid container direction='row' spacing={1} wrap="nowrap">
                            <Grid
                                item
                                alignContent='flex-start'
                                alignItems='flex-start'
                                justify='flex-start'
                            >
                                <ProgressSaveButton
                                    text="Save"
                                    onClick={handleSubmit(saveDetails)}
                                    loading={isLoading}
                                    disabled={false}
                                    buttonClassName={classes.saveButton}
                                    size="small"
                                ></ProgressSaveButton>
                            </Grid>
                            <Grid
                                item
                                alignContent='flex-start'
                                alignItems='flex-start'
                                justify='flex-start'
                            >
                                <Button
                                    disabled={isLoading}
                                    size='small'
                                    color='primary'
                                    variant='contained'
                                    type='button'
                                    onClick={props.close}
                                >Cancel</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>)
        || <></>
    );
};

export default AddNewCreditComponent;
