import { Card, IconButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState } from "react";
import * as InstitutionalRefundApi from "../../../../src/api/student/studentAccounts/InstitutionalRefundApi";
import AccessManager from "../../../components/security/AccessManager";
import { EnrollmentNoticeGivenOptions, EnrollmentTypes, EqualitySymbolOptions, CalculationUsingTypesOptions, TimePeriodOptions, RightToCancelNoticeGivenToStudentOptions } from "../../../constants/RefundConstants/refunds.constants";
import { security } from "../../../constants/Security/securityConstants";
import { Order } from "../../../interfaces/common/Table/CustomHeadCell";
import { PolicyRule } from "../../../interfaces/student/refunds/PolicyRule";
import * as TableFunctions from "../../../utils/tableFunctions";


interface HeadCell {
	disablePadding: boolean;
	id: keyof PolicyRule;
	label: string;
	numeric: boolean;
	widthType: "L" | "M" | "S" | "XS";
}

const headCells: HeadCell[] = [
	{
		id: "rulePriority",
		numeric: false,
		disablePadding: false,
		label: "Rule Priority",
		widthType: "XS",
	},
	{
		id: "enrollmentType",
		numeric: false,
		disablePadding: false,
		label: "Enrollment type",
		widthType: "XS",
	},
	{
		id: "studentAcceptedBySchool",
		numeric: false,
		disablePadding: false,
		label: "Student accepted by school",
		widthType: "XS",
	},
	{
		id: "rightToCancelNoticeGivenToStudent",
		numeric: false,
		disablePadding: false,
		label: "Right to cancel notice given to student",
		widthType: "S",
	},
	{
		id: "whenIsEnrollmentNoticeGiven",
		numeric: false,
		disablePadding: false,
		label: "Withdrawal/cancellation of enrollment notice is given",
		widthType: "S",
	},
	{
		id: "beforeProgramStartOperator",
		numeric: false,
		disablePadding: false,
		label:
			"Days after signing enrollment agreement",
		widthType: "S",
	},
	{
		id: "hasStudentAttendedClass",
		numeric: false,
		disablePadding: false,
		label:
			"When notice is given after program start - Has student attended a class",
		widthType: "S",
	},
	{
		id: "afterStartProgramCompletionOperator",
		numeric: false,
		disablePadding: false,
		label:
			"When notice is given after program start - Program completion till day of notice since class start",
		widthType: "S",
	},
	{
		id: "programCompletionCalculatedUsingType",
		numeric: false,
		disablePadding: false,
		label: "Program completion calculated using",
		widthType: "S",
	},
	{
		id: "institutionalRefundPolicyRuleId",
		numeric: false,
		disablePadding: false,
		label: "Actions",
		widthType: "XS",
	},
];

interface EnhancedTableProps {
	classes: ReturnType<typeof useStyles>;
	numSelected: number;
	onRequestSort: (
		event: React.MouseEvent<unknown>,
		property: keyof PolicyRule
	) => void;
	onSelectAllClick: (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => void;
	order: Order;
	orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const {
		classes,
		onSelectAllClick,
		order,
		orderBy,
		numSelected,
		onRequestSort,
	} = props;
	const createSortHandler = (property: keyof PolicyRule) => (
		event: React.MouseEvent<unknown>
	) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className={classes.gridHeader}>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align="center"
						padding={headCell.disablePadding ? "none" : "default"}
						sortDirection={orderBy === headCell.id ? order : false}
						className={
							headCell.widthType == "XS"
								? classes.gridCellTypeXsmall
								: headCell.widthType == "S"
								? classes.gridCellTypeSmall
								: headCell.widthType == "M"
								? classes.gridCellTypeMedium
								: headCell.widthType == "L"
								? classes.gridCellTypeLarge
								: classes.gridCellTypeXLarge
						}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={order}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

const useToolbarStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(1),
			height: "50px",
		},
		searchDiv: {
			paddingRight: theme.spacing(2),
			float: "right",
			width: "60%",
		},
	})
);

// enhance table styling
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			height: "400px",
		},
		cardWrapper: {
			height: "100%",
		},
		paper: {
			width: "100%",
			marginBottom: theme.spacing(2),
		},
		table: {
			minWidth: 750,
			height: "100%",
		},
		tableWrapper: {
			height: "calc(100%)",
		},
		visuallyHidden: {
			border: 0,
			clip: "rect(0 0 0 0)",
			height: 1,
			margin: -1,
			overflow: "hidden",
			padding: 0,
			position: "absolute",
			top: 20,
			width: 1,
		},
		textField: {
			marginRight: theme.spacing(1),
			width: "100%",
			"& ::-webkit-inner-spin-button, ::-webkit-outer-spin-button": {
				WebkitAppearance: "none",
				margin: 0,
				MozAppearance: "textfield",
			},
		},
		tableRowWrapper: {
			display: "table",
			width: "100%",
			tableLayout: "fixed",
		},
		gridHeader: {
			backgroundColor: theme.palette.secondary.dark,
			height: "50px",
			display: "table",
			width: "100%",
			tableLayout: "fixed",
		},
		tableBodyWrapper: {
			height: "calc(100% - 110px)",
			overflowY: "auto",
			overflowX: "hidden",
			display: "block",
		},
		gridHeadTypeXsmall: {
			width: "10%",
			paddingLeft: theme.spacing(0),
			paddingRight: theme.spacing(0),
			fontSize: 11,
		},
		gridCellTypeXsmall: {
			width: "10%",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
			fontSize: 11,
		},
		gridHeadTypeSmall: {
			width: "15%",
			paddingLeft: theme.spacing(0),
			paddingRight: theme.spacing(0),
			fontSize: 11,
		},
		gridCellTypeSmall: {
			width: "15%",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
			fontSize: 11,
		},
		gridHeadTypeMedium: {
			width: "25%",
			paddingLeft: theme.spacing(0),
			paddingRight: theme.spacing(0),
		},
		gridCellTypeMedium: {
			width: "25%",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
		},
		gridHeadTypeLarge: {
			width: "35%",
			paddingLeft: theme.spacing(0),
			paddingRight: theme.spacing(0),
		},
		gridCellTypeLarge: {
			width: "35%",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
		},
		gridHeadTypeXLarge: {
			width: "45%",
			paddingLeft: theme.spacing(0),
			paddingRight: theme.spacing(0),
		},
		gridCellTypeXLarge: {
			width: "45%",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
		},
		datePicker: {
			"& .MuiIconButton-root": {
				padding: theme.spacing(0.8),
			},
		},
		actionCell: {
			whiteSpace: "nowrap",
		},
	})
);

type gradesTableProps = {
	policyId: string | null;
	refreshGrid?: any;
	onEditClick: Function;
	onDeleteClick?: Function;
	setPolicyRulesData: any;
	policyRulesData: any;
	setSnackBar: any;
	setConfirmationModal: any;
};
export default function EnhancedTable(props: gradesTableProps) {
	const classes = useStyles({});
	const [order, setOrder] = React.useState<Order>("asc");
	const [orderBy, setOrderBy] = React.useState<keyof PolicyRule>(
		"rulePriority"
	);

	const [selected, setSelected] = React.useState<string[]>([]);
	const [searchFilter, setsearchFilter] = useState<string | null>("");

	const filterDataBySearch = (data: PolicyRule[]): PolicyRule[] => {
		if (searchFilter === "" || searchFilter === undefined) return data;

		let searchVal: string = searchFilter
			? (searchFilter as string).toLowerCase()
			: "";
		return data.filter((row: PolicyRule) => {
			return true;
		});
	};
	const refreshGridData = () => {
		if (props.policyId) {
			InstitutionalRefundApi.GetPolicyRules(props.policyId).then(
				(response: any) => {
					if (response) {
						props.setPolicyRulesData(response.data.result);
					}
				},
				(exception: any) => {}
			);
		} else {
			props.setPolicyRulesData([]);
		}
	};

	React.useEffect(() => {
		refreshGridData();
	}, [props.policyId, props.refreshGrid]);

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof PolicyRule
	) => {
		const isDesc = orderBy === property && order === "desc";
		setOrder(isDesc ? "asc" : "desc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
		}
		setSelected([]);
	};

	return (
		<Paper className={classes.root}>
			<Card className={classes.cardWrapper}>
				<div className={classes.tableWrapper}>
					<Table
						className={classes.table}
						aria-labelledby="tableTitle"
						size="medium"
						aria-label="enhanced table"
					>
						<EnhancedTableHead
							classes={classes}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
						/>
						<TableBody className={classes.tableBodyWrapper}>
							{TableFunctions.stableSort(
								filterDataBySearch(props.policyRulesData as any) as any,
								TableFunctions.getSorting(order, orderBy)
							).map((row: any, index: any) => {
								const labelId = "enhanced-table-checkbox-${index}";
								let rowData = row as PolicyRule;
								return (
									<TableRow className={classes.tableRowWrapper} key={`row-${rowData.institutionalRefundPolicyRuleId}`}>
										<TableCell
											classes={{
												head: classes.gridHeadTypeXsmall,
												body: classes.gridCellTypeXsmall,
											}}
											align="center"
										>
											{rowData.rulePriority}
										</TableCell>
										<TableCell
											classes={{
												head: classes.gridHeadTypeXsmall,
												body: classes.gridCellTypeXsmall,
											}}
											align="center"
										>
											{
												EnrollmentTypes.filter((e: any) => {
													return e.value === rowData.enrollmentType;
												})[0]?.text
											}
										</TableCell>
										<TableCell
											classes={{
												head: classes.gridHeadTypeXsmall,
												body: classes.gridCellTypeXsmall,
											}}
											align="center"
										>
											{rowData.studentAcceptedBySchool ? "Yes" : "No"}
										</TableCell>
										<TableCell
											classes={{
												head: classes.gridHeadTypeSmall,
												body: classes.gridCellTypeSmall,
											}}
											align="center"
										>
											{
												RightToCancelNoticeGivenToStudentOptions.filter((e: any) => {
													return (
														e.value === rowData.rightToCancelNoticeGivenToStudent
													);
												})[0]?.text
											}
										</TableCell>
										<TableCell
											classes={{
												head: classes.gridHeadTypeSmall,
												body: classes.gridCellTypeSmall,
											}}
											align="center"
										>
											{
												EnrollmentNoticeGivenOptions.filter((e: any) => {
													return (
														e.value === rowData.whenIsEnrollmentNoticeGiven
													);
												})[0]?.text
											}
										</TableCell>
										<TableCell
											classes={{
												head: classes.gridHeadTypeSmall,
												body: classes.gridCellTypeSmall,
											}}
											align="center"
										>
											{!rowData.beforeProgramStartOperator ||
											!rowData.beforeProgramStartDays
												? "NA"
												: EqualitySymbolOptions.filter((e: any) => {
														return (
															e.value === rowData.beforeProgramStartOperator
														);
												  })[0]?.text +
												  " " +
												  rowData.beforeProgramStartDays}
										</TableCell>
										<TableCell
											classes={{
												head: classes.gridHeadTypeSmall,
												body: classes.gridCellTypeSmall,
											}}
											align="center"
										>
											{rowData.hasStudentAttendedClass != null ? (rowData.hasStudentAttendedClass === true ? "Yes" : "No") : "NA"}
										</TableCell>
										<TableCell
											classes={{
												head: classes.gridHeadTypeSmall,
												body: classes.gridCellTypeSmall,
											}}
											align="center"
										>
											{!rowData.afterStartProgramCompletionOperator ||
											!rowData.afterStartProgramCompletionAmount ||
											!rowData.afterStartProgramCompletionType
												? "NA"
												: EqualitySymbolOptions.filter((e: any) => {
														return (
															e.value ===
															rowData.afterStartProgramCompletionOperator
														);
												  })[0]?.text +
												  " " +
												  rowData.afterStartProgramCompletionAmount +
												  " " +
												  TimePeriodOptions.filter((e: any) => {
														return (
															e.value ===
															rowData.afterStartProgramCompletionType
														);
												  })[0]?.text}
										</TableCell>
										<TableCell
											classes={{
												head: classes.gridHeadTypeSmall,
												body: classes.gridCellTypeSmall,
											}}
											align="center"
										>
											{
												CalculationUsingTypesOptions.filter((e: any) => {
													return (
														e.value ===
														rowData.programCompletionCalculatedUsingType
													);
												})[0]?.text
											}
										</TableCell>
										<TableCell
											classes={{
												head: classes.gridHeadTypeXsmall,
												body: classes.gridCellTypeXsmall,
											}}
											className={classes.actionCell}
											align="center"
										>
											<AccessManager
												allowedPermissions={[security.permissions.studentAccounts.deleteRefundPolicies]}
												renderNoAccess={() => null}
											>
												<IconButton
													color="primary"
													aria-label="Edit"
													component="span"
													onClick={(e: any) => {
														props.onEditClick(rowData);
													}}
												>
													<EditIcon></EditIcon>
												</IconButton>
											</AccessManager>
											

											<AccessManager
												allowedPermissions={[security.permissions.studentAccounts.deleteRefundPolicies]}
												renderNoAccess={() => null}
											>
												<IconButton
													color="primary"
													aria-label="Delete"
													component="span"
													onClick={(e: any) => {
														if (props.onDeleteClick) {
															props.onDeleteClick(rowData);
														}
													}}
												>
													<DeleteIcon></DeleteIcon>
												</IconButton>
											</AccessManager>
											
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</div>
			</Card>
		</Paper>
	);
}
