import * as React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import AccrualsARExportReport from '../../pages/reports/AccrualsARReport/AccrualsARExportReport';
import * as reportsApi from '../../../src/api/reports/allReports/reportsApi';
import { Report } from '../../interfaces/reports/Report';
import { TagsColor } from '../../components/_Layout/TagManager/tagConfig';
import { Tag } from '../../interfaces/common/Tag';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			paddingTop: theme.spacing(3),
			minHeight: '100%',
			height: '100%',
			maxWidth: '100%',
			margin: 'auto'
		},
		paperRoot: {
			padding: theme.spacing(3, 2),
			width: '100%',
			minHeight: '100%',
			height: '100%',
			overflowX: 'hidden',
			overflowY: 'auto',
			flexDirection: 'column',
			backgroundColor: theme.palette.background.paper
		}
	})
);

const AccrualsARExport = () => {
	const classes = useStyles({});
	const [reportData, setReportData] = React.useState<Report>({
		creationMethod: '',
		description: 'Both the Monthly Accruals and Accounts Receivables reports can be printed here. The Monthly Accruals report calculates all the revenue that can be recognized for the selected month based on the calculation method for each program. The Accounts Receivables report calculates the receivables and deferred revenue for each student for the selected month.',
		favorited: false,
		id: 884,
		isUnLock: true,
		modules: [],
		name: 'Monthly Accruals and Accounts Receivables',
		recentlyUsed: true,
		reportClass: '',
		resourceURL: '',
		tags: [],
		typeId: 0,
	});

	const [_loading, setLoading] = React.useState<boolean>(true);
	const campusId = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const userId = useSelector((state: any) => state.session.user.userId);

	const reportName = 'Monthly Accruals and Accounts Receivables';
	React.useEffect(() => {
		if (campusId && userId) {
			setLoading(true);
			getReportListDetails(campusId, userId).then((r: Report[]) => {
				if (r.length > 0 && r[0].name === reportName) {
					setReportData(r[0]);
				}
				setLoading(false);
			});
		}
	}, [campusId, userId]);

	const getReportListDetails = (campusId: string, userId: string) => {
		return reportsApi.getReportList(campusId, userId, 0, reportName).then(
			(response: any) => {
				if (response) {
					if (response.result.length > 0) {
						let data = response.result;
						let reports = data.map((rep: any) => {
							return {
								name: rep.name,
								id: rep.id,
								description: rep.description,
								modules: [],
								tags: rep.reportTagList.map((tag: any) => {
									if (tag) {
										return {
											text: tag.text,
											color:
                                                TagsColor[
                                                tag?.text?.toLowerCase().replace(/ +/g, '') ?? ''
                                                ],
											id: tag.id,
										} as Tag;
									}
									return undefined;
								}),
								favorited: rep.favorited,
								typeId: rep.typeId,
								resourceURL: rep.resourceURL,
								recentlyUsed: rep.recentlyUsed,
								reportClass: rep.reportClass,
								creationMethod: rep.creationMethod,
								isUnLock: rep.isUnLock,
							} as Report;
						});
						return reports;
					}
				}
				return [];
			},
			(_exception: any) => []
		);
	};

	return (
		<div className={classes.root}>
			<Paper className={classes.paperRoot}>
				<AccrualsARExportReport
					report={reportData}
				></AccrualsARExportReport>
			</Paper>
		</div>
	);
};

export default AccrualsARExport;
