import * as React from "react";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Typography, Card, CardContent } from "@material-ui/core";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import { LedgerItem } from "../../../../interfaces/student/studentAccounts/LedgerItem";
import { useTheme } from "@material-ui/core/styles";

const tableIcons = {
	SortArrow: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
		<ArrowUpward {...props} ref={ref} />
	)),
};
const useStyles = makeStyles((theme: any) =>
	createStyles({
		drawer: {
			width: 280,
		},
		root: {
			width: "100%",
			backgroundColor: theme.palette.background.paper,
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2),
			},
		},
		"& .makeStyles-content": {
			backgroundColor: theme.palette.background.paper,
		},
		cardTitle: {
			fontSize: 17,
			color: theme.palette.black,
			textDecoration: "bold",
			backgroundColor: theme.palette.iconSecondaryHover,
			padding: theme.spacing(1, 1),
		},
		textField: {
			marginRight: theme.spacing(1),
			width: "100%",
		},
		headerText: {
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(0),
		},
	})
);
type AwardLedgerProps = {
	AwardLedger: LedgerItem[];
};
const LedgerActivity = (props: AwardLedgerProps) => {
	const { AwardLedger } = props;
	const classes = useStyles({});
	const theme = useTheme();
	return (
		<React.Fragment>
			<div className={classes.root}>
				<MaterialTable
					title="Ledger Activity"
					columns={[
						{ title: "Date", field: "date", type: "date" },
						{ title: "Description", field: "description" },
						{
							title: "Amount",
							field: "amountAsCurrency",
							headerStyle: { textAlign: "right" },
							cellStyle: {
								paddingRight: theme.spacing(6),
								textAlign: "right",
							},
						},
						{
							title: "Balance",
							field: "balanceAsCurrency",
							headerStyle: { textAlign: "right" },
							cellStyle: {
								paddingRight: theme.spacing(6),
								textAlign: "right",
							},
						},
					]}
					icons={tableIcons}
					data={AwardLedger}
					options={{
						search: false,
						paging: false,
						showTitle: true,
						toolbar: true,
						rowStyle: (rowData) => {
							return {
								fontFamily: "Roboto"
							}
						},
						headerStyle: {
							backgroundColor: theme.palette.secondary.main,
							zIndex: 0,
						},
					}}
				/>
			</div>
		</React.Fragment>
	);
};
export default LedgerActivity;
