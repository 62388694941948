import { createStyles, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme: any) =>
	createStyles({
		cardTitle: {
			fontSize: 17,
			color: theme.palette.black,
			textDecoration: 'bold',
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1),
			marginBottom: theme.spacing(1),
		},
		headerText: {
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(0),
		},
	})
);
type CustomCardTitleProps = {
	title: string;
};
const CustomCardTitle = (props: CustomCardTitleProps) => {
	const classes = useStyles({});
	return (
		<Typography className={clsx(classes.cardTitle)} >
			<div className={classes.headerText}>{props.title}</div>
		</Typography>
	);
};

export default CustomCardTitle;
