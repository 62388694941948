import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CampusForCurrentUserAutoComplete from "../../../components/AutoComplete/CampusForCurrentUserAutoComplete";
import { Grid } from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import { ISAPCheckListingByDateReportParams } from "../../../interfaces/reports/studentAccounts/ISAPCheckReportParams";
import * as SAPCheckReportApi from "../../../api/reports/studentAccounts/SAPCheckReportApi";
import { ReportOutput } from "../../../enums/ReportOutput";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import StudentGroupsAutoComplete from "../../../components/AutoComplete/StudentGroupsAutoComplete";
import { withStyles } from "@material-ui/core";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Switch,
} from "@material-ui/core";

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {},
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      minHeight: "100%",
      height: "100%",
      maxWidth: "1050px",
      padding: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    startDateField: {
      width: "100%",
    },
    fullWidthElement: {
      width: "100%",
    },
    fieldSet: {
      marginTop: theme.spacing(2),
    },
  })
);

const SAPCheckListingByDateReport = (props: any) => {
  const { report } = props;
  let reportOverview = { report: report } as IReportOverview;
  const classes = useStyles({});
  const [preview, setPreview] = React.useState<any>();
  const [previewLoader, setPreviewLoader] = React.useState<boolean>();

  const data = useForm<any>({ mode: "onBlur" });
  const [model, setModel] = React.useState<ISAPCheckListingByDateReportParams>({
    campusIds: [],
    startDate: undefined as Date | undefined,
    endDate: undefined as Date | undefined,
    sortOrder: "Name",
    checkType: "",
    exportType: 1 as ReportOutput,
  });

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: (values: any, key: string) => Array<string>
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value, "value") : value;
    (updatedModel as any)[fieldId] = newValue;
    setModel({ ...updatedModel });
  };

  const { handleSubmit } = data;

  const exportTypes: Array<DropDownListItem> = [
    { text: "PDF", id: "1" },
    { text: "Excel", id: "2" },
  ];

  const onSubmit = async (isPreview: any) => {
    let params =
      (await getReportParameters()) as ISAPCheckListingByDateReportParams;
    const validation = await data.triggerValidation();
    if (validation) {
      if (isPreview) {
        setPreviewLoader(true);
        SAPCheckReportApi.generateSAPCheckListingByDateReport(
          params,
          isPreview
        ).then((res: any) => {
          if (res) {
            res.arrayBuffer().then((buffer: any) => {
              setPreview(buffer);
              setPreviewLoader(false);
            });
          } else setPreviewLoader(false);
        });
      } else
        await SAPCheckReportApi.generateSAPCheckListingByDateReport(params);
    }
  };

  const exportTypeAutoComplete = {
    options: exportTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const filterHandler = (data: any) => {
    if (data) {
      setModel(data);
    } else {
      setModel({
        campusIds: [],
        startDate: undefined as Date | undefined,
        endDate: undefined as Date | undefined,
        sortOrder: "Name",
        checkType: "",
        exportType: 1 as ReportOutput,
      });
    }
  };
  const setCampuses = (values: any, key: string) => {
    let newValues: string[] = [];
    if (values !== null) {
      if (Array.isArray(values)) {
        newValues = values.map((gr: any) => gr[key]);
      } else {
        newValues.push(values.value);
      }
    }
    return newValues;
  };

  const handleSortOrderChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let updatedModel = model;
    (updatedModel as any)["sortOrder"] = (
      event.target as HTMLInputElement
    ).value;
    setModel({ ...updatedModel });
  };

  const getReportParameters = async () => {
    const success = await data.triggerValidation();
    if (model && success) {
      let startDate = model.startDate ? model.startDate : undefined;
      let endDate = model.endDate ? model.endDate : undefined;
      let params: ISAPCheckListingByDateReportParams = {
        campusIds: model.campusIds,
        startDate: startDate,
        endDate: endDate,
        sortOrder: model.sortOrder,
        checkType: model.checkType,
        exportType: model.exportType,
      };
      return params;
    }
    return {} as ISAPCheckListingByDateReportParams;
  };

  const reportTypes: Array<DropDownListItem> = [
    { text: "Title IV SAP Checks", id: "TitleIVSAPChecks" },
    { text: "SAP checks", id: "SAPChecks" },
  ];
  const reportTypeAutoComplete = {
    options: reportTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
    const item = items.find((opt) => {
      if (opt.id === value) return opt;
    });
    return item || ({} as DropDownListItem);
  };

  let parameters = (
    <div className={classes.root}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={1}>
            <Grid item md={9} sm={9} xs={12}>
              <CampusForCurrentUserAutoComplete
                id="campusIds"
                name="campusIds"
                label="Campus(es) *"
                multiple
                includeAllValue={true}
                showIncludeAllValue={true}
                filterHandle={(v: any) => {
                  handleFieldOnChange("campusIds", v, setCampuses);
                }}
                valueFilter={
                  model.campusIds
                    ? {
                        key: "value",
                        values: model.campusIds,
                      }
                    : undefined
                }
                error={!!data.errors.campusIds}
                inputRef={data.register({
                  validate: (_value) =>
                    model.campusIds && model.campusIds.length > 0
                      ? true
                      : false,
                })}
                helperText={
                  data.errors.campusIds ? "Campus(es) are required." : undefined
                }
              />
            </Grid>
            <Grid item md={3} sm={3} xs={12}>
              <Autocomplete
                {...exportTypeAutoComplete}
                autoComplete
                includeInputInList
                onChange={(e: any, value: any) => {
                  handleFieldOnChange(
                    "exportType",
                    value ? value : undefined,
                    (v: any) => v?.id
                  );
                }}
                value={getSelectedItem(
                  exportTypes,
                  model.exportType.toString()
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Export Type"
                    name="exportType"
                    error={!!data.errors.exportType}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.exportType
                        ? "Export Type is required."
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="startDate"
                  name="startDate"
                  label="Start Date *"
                  value={model.startDate ? model.startDate : null}
                  onChange={(e: any, value: any) =>
                    handleFieldOnChange("startDate", value ? value : undefined)
                  }
                  className={classes.startDateField}
                  KeyboardButtonProps={{
                    "aria-label": "report date",
                  }}
                  error={!!data.errors["startDate"]}
                    innerRef={(ref: any) => {
                      data.register("startDate", {
                        validate: {
                          required: (value: Date) =>
                            (value != null) ||
                            "Start date is required.",
                          beforeEndDate: (value) =>
                            new Date(value) <= new Date(data.getValues()["endDate"]) ||
                            "Start Date must be before end date",
                        },
                      });
                      data.setValue("startDate", model.startDate);
                    }}
                    helperText={
                      data.errors["startDate"]
                        ? (data.errors["startDate"] as FieldError).message
                        : undefined
                    }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="endDate"
                  name="endDate"
                  label="End Date *"
                  value={model.endDate ? model.endDate : null}
                  onChange={(e: any, value: any) =>
                    handleFieldOnChange("endDate", value ? value : null)
                  }
                  className={classes.startDateField}
                  KeyboardButtonProps={{
                    "aria-label": "end date",
                  }}
				  error={!!data.errors["endDate"]}
				  innerRef={(ref: any) => {
					data.register("endDate", {
					  validate: {
						required: (value: Date) =>
						  (value != null) ||
						  "End date is required.",
						beforeEndDate: (value) =>
						  new Date(value) >= new Date(data.getValues()["startDate"]) ||
						  "End Date must be after Start date",
					  },
					});
					data.setValue("endDate", model.endDate);
				  }}
				  helperText={
					data.errors["endDate"]
					  ? (data.errors["endDate"] as FieldError).message
					  : undefined
				  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
              <Autocomplete
                {...reportTypeAutoComplete}
                autoComplete
                includeInputInList
                onChange={(e: any, value: any) => {
                  handleFieldOnChange(
                    "checkType",
                    value ? value : undefined,
                    (v: any) => v?.id
                  );
                }}
                value={getSelectedItem(reportTypes, model.checkType ?? "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="SAP Check Types"
                    name="checkType"
                    error={!!data.errors.checkType}
                    inputRef={data.register({
                      validate: {
                        requiredCondition: (value: string) =>
                          value != "" || "SAP check type is required.",
                      },
                    })}
                    helperText={
                      data.errors.checkType
                        ? (data.errors.checkType as FieldError).message
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <FormControl component="fieldset" className={classes.fieldSet}>
                <FormLabel component="legend">Sort Order</FormLabel>
                <RadioGroup
                  row
                  aria-label="sortOrder"
                  name="sortOrder"
                  value={model.sortOrder}
                  onChange={handleSortOrderChange}
                >
                  <FormControlLabel
                    labelPlacement="start"
                    value="Name"
                    control={<Radio />}
                    label="Name"
                  />
                  <FormControlLabel
                    labelPlacement="start"
                    value="CheckPoint"
                    control={<Radio />}
                    label="Checkpoint Date"
                  />
				  <FormControlLabel
                    labelPlacement="start"
                    value="Status"
                    control={<Radio />}
                    label="SAP Status"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </FormContext>
    </div>
  );
  reportOverview.parameters = parameters;

  return (
    <ReportOverview
      reportOverview={reportOverview}
      filterHandler={filterHandler}
      getReportParameters={getReportParameters}
      exportHandler={onSubmit}
      preview={preview}
      previewLoader={previewLoader}
    />
  );
};
export default SAPCheckListingByDateReport;
