import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Typography, Card, CardContent, Divider, Button } from "@material-ui/core";
import DisplayField from "../DisplayField/DisplayField";
import * as StudentAcademicsCardApi from "../../../api/student/quickInfo/StudentAcademicsCardApi";
import { Academics } from "../../../interfaces/student/quickInfo/Academics";
import { StudentProfile } from "../../../interfaces/student/profile/StudentProfile";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    card: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(2),
      "& .MuiCardContent-root": {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
      }
    },
    cardTitle: {
      fontSize: 14,
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightMedium
    }
  })
);

const AcademicsCard = (props: any) => {
  const { className, ...rest } = props;
  const selectedStudent: StudentProfile = useSelector(
    (state: any) => state.student
  ) as StudentProfile;
  const [academicsModel, setAcademicsModel] = useState<Academics>(
    new Academics()
  );
  useEffect(() => {
    if (selectedStudent.selectedEnrollment !== undefined) {
      if (
        selectedStudent.leadId !== undefined &&
        selectedStudent.leadId.length > 0
      ) {
        StudentAcademicsCardApi.getStudentAcademicsCard(
          selectedStudent.selectedEnrollment.stuEnrollId
        ).then(
          (response: any) => {
            setAcademicsModel(response.data);
          },
          (exception: any) => {}
        );
      }
    }
  }, [selectedStudent]);

  const classes = useStyles({});
  useEffect(() => {
    let mounted = true;

    const fetchData = () => {};

    fetchData();

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Card className={classes.card} elevation={2}>
      <Typography className={clsx(classes.cardTitle)}><Link to={"/student/1/grades/exams"}>ACADEMICS</Link></Typography>
      <CardContent>
        {academicsModel.isCreditHour == false ? (
          <React.Fragment>
            <DisplayField
              labelText="Met Requirements"
              valueText={academicsModel.metReqs}
              fieldType="text"
            ></DisplayField>

            <DisplayField
              labelText="Overall GPA"
              valueText={academicsModel.overallGPA}
              fieldType="text"
            ></DisplayField>

            <DisplayField
              labelText="Written Exams Average"
              valueText={academicsModel.writtenExamAvg}
              fieldType="text"
            ></DisplayField>

            <DisplayField
              labelText="Practical Exams Average"
              valueText={academicsModel.practicalExamAvg}
              fieldType="text"
            ></DisplayField>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <DisplayField
              labelText="Overall GPA"
              valueText={(academicsModel.overallGPA ?? 0.00).toFixed(2)}
              fieldType="text"
            ></DisplayField>
            <DisplayField
              labelText="Credit Earned"
              valueText={(academicsModel.creditEarned ?? 0.00).toFixed(2)}
              fieldType="text"
            ></DisplayField>
            <DisplayField
              labelText="Credit Attempted"
              valueText={(academicsModel.creditAttempted ?? 0.00).toFixed(2)}
              fieldType="text"
            ></DisplayField>
            <DisplayField
              labelText="FA Credit Earned"
              valueText={(academicsModel.faCreditEarned ?? 0.00).toFixed(2)}
              fieldType="text"
            ></DisplayField>
            <DisplayField
              labelText="FA Credit Attempted"
              valueText={(academicsModel.faCreditAttempted ?? 0.00).toFixed(2)}
              fieldType="text"
            ></DisplayField>
          </React.Fragment>
        )}

        <DisplayField
          labelText="Clinic Services Remaining"
          valueText={academicsModel.servicesRemaining}
          fieldType="text"
        ></DisplayField>

        <DisplayField
          labelText="Clinic Hours Remaining"
          valueText={academicsModel.hoursRemaining}
          fieldType="text"
        ></DisplayField>
      </CardContent>
    </Card>
  );
};

export default AcademicsCard;
