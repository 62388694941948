import * as React from "react";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import {
  Typography,
  Card,
  CardContent,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import HistoryIcon from '@material-ui/icons/History';
import Grid from "@material-ui/core/Grid";
import GenderAutoComplete from "../../../../../src/components/AutoComplete/GenderAutoComplete";
import EthnicityAutoComplete from "../../../../../src/components/AutoComplete/EthnicityAutoComplete";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DependencyAutoComplete from "../../../../../src/components/AutoComplete/DependencyAutoComplete";
import HousingAutoComplete from "../../../../../src/components/AutoComplete/HousingAutoComplete";
import GeographicTypesAutoComplete from "../../../../../src/components/AutoComplete/GeographicTypesAutoComplete";
import MaritalStatusAutoComplete from "../../../../../src/components/AutoComplete/MaritalStatusAutoComplete";
import CitizenshipAutoComplete from "../../../../../src/components/AutoComplete/CitizenshipAutoComplete";
import { IStudentInfoDemographics } from "../../../../interfaces/student/profile/studentInfo/IStudentInfo";
import { useFormContext } from "react-hook-form";
import EFCHistoryField from './EFCHistoryField';
import InputField from "../../../../components/_Layout/Inputs/InputField";
import { AdornmentPosition, InputType } from "../../../../constants/uiConstants/inputConstants";
import { SnackBarStateProps } from "./StudentInfo";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    drawer: {
      width: 280
    },
    root: {
      backgroundColor: theme.palette.paper.background,
      "& .MuiCardContent-root": {
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(2)
      }
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.paper.background
    },
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1)
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%"
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0)
    },
    cardContent: {
      padding: theme.spacing(1, 3)
    },
    switch: {
      marginLeft: "0px",
      paddingTop: theme.spacing(2)
    }
  })
);

const ThemeTextField = withStyles((theme: any) => createStyles({}))(TextField);

type DemographicProps = {
  model: IStudentInfoDemographics;
  setModel: any;
  isNewEnrollment: boolean;
  showSnackBar?: (anckBar: SnackBarStateProps) => void;
};

const Demographics = (props: DemographicProps) => {
  const classes = useStyles({});
  const { model, setModel } = props;
  const data = useFormContext();
  const [efcHistoryOpen, toggleEfHistory] = React.useState<boolean>(false);

  const handleFieldOnChange = (fieldId: string, value: any) => {
    let updatedModel = model;
    
    if(fieldId == 'milesFromSchool'&& value!='')
    {
      (updatedModel as any)[fieldId] = Math.round(value);
      setModel({ ...updatedModel });
    }else{
      (updatedModel as any)[fieldId] = value;
      setModel({ ...updatedModel });
    }
 
  };

  const onValidation = (fieldId: string, value: any) => {
    if (data.errors && (model as any)[fieldId] != value) {
      handleFieldOnChange(fieldId, value);
    }
  };

  //Accepts whole numbers only.
  const NumberValidation = (errorName: any) => {
    if (errorName?.type === "validate") {
      return "Value is not in correct format.";
    } else return null;
  };

  return (
    <div>
      <Card square={true}>
        <Typography className={clsx(classes.cardTitle)}>
          <div className={classes.headerText}>Demographics</div>
        </Typography>
        <CardContent className={classes.cardContent}>
          <Grid container direction="row" spacing={2}>
            <Grid item md={4} sm={6} xs={12}>
              <GenderAutoComplete
                id="genderId"
                name="genderId"
                label="Gender *"
                valueFilter={
                  model.genderId
                    ? {
                      key: "value",
                      values: [model.genderId]
                    }
                    : undefined
                }
                error={!!data.errors.genderId}
                inputRef={data.register({ required: true })}
                helperText={
                  data.errors.genderId ? "Gender is required." : undefined
                }
                filterHandle={(v: any) => {
                  handleFieldOnChange("genderId", v ? v.value : undefined);
                }}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <EthnicityAutoComplete
                id="ethnicityId"
                name="ethnicityId"
                label="Race *"
                valueFilter={
                  model.ethnicityId
                    ? {
                      key: "value",
                      values: [model.ethnicityId]
                    }
                    : undefined
                }
                error={!!data.errors.ethnicityId}
                inputRef={data.register({ required: true })}
                helperText={
                  data.errors.ethnicityId ? "Ethnicity is required." : undefined
                }
                filterHandle={(v: any) => {
                  handleFieldOnChange("ethnicityId", v ? v.value : undefined);
                }}
              />
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <FormControlLabel
                id="isDisabled"
                className={classes.switch}
                labelPlacement="start"
                label="Disability"
                control={
                  <Switch
                    checked={model.isDisabled}
                    value={model.isDisabled}
                    onChange={(e: any) => {
                      handleFieldOnChange("isDisabled", e.target.checked);
                    }}
                    color="primary"
                  />
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <DependencyAutoComplete
                id="dependencyTypeId"
                name="dependencyTypeId"
                label="Dependency *"
                filterHandle={(v: any) => {
                  handleFieldOnChange("dependencyTypeId", v ? v.value : null);
                }}
                valueFilter={
                  model.dependencyTypeId
                    ? {
                      key: "value",
                      values: [model.dependencyTypeId]
                    }
                    : undefined
                }
                error={!!data.errors.dependencyTypeId}
                inputRef={data.register({ required: true })}
                helperText={
                  data.errors.dependencyTypeId ? "Dependency is required." : undefined
                }
              />
            </Grid>
            
            <Grid item md={4} sm={6} xs={12}>
              <ThemeTextField
                key="dependents"
                id="Dependents"
                name="dependents"
                className={classes.textField}
                label="Dependents"
                type="number"
                value={model.dependents ?? ""}
                inputRef={data.register({
                  validate: (value: any) => {
                    onValidation("dependents", value);
                      return /^\d+$/.test(value); 
                  },
                })}
                helperText={NumberValidation(data.errors.dependents)}
                error={!!data.errors.dependents}
                onChange={(v: any) => {
                  handleFieldOnChange("dependents", v ? v.target.value : undefined);
                }}
              />
            </Grid> 
            <Grid item md={4} sm={6} xs={12}>
              <FormControlLabel
                id="headOfHousehold"
                className={classes.switch}
                control={
                  <Switch
                    checked={model.headOfHousehold}
                    value={model.headOfHousehold}
                    onChange={(e: any) => {
                      handleFieldOnChange("headOfHousehold", e.target.checked);
                    }}
                    color="primary"
                  />
                }
                labelPlacement="start"
                label="Head of Household"
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <HousingAutoComplete
                id="housingId"
                name="housingId"
                label="Housing *"
                valueFilter={
                  model.housingId
                    ? {
                      key: "value",
                      values: [model.housingId]
                    }
                    : undefined
                }
                error={!!data.errors.housingId}
                inputRef={data.register({ required: true })}
                helperText={
                  data.errors.housingId ? "Housing is required." : undefined
                }
                filterHandle={(v: any) => {
                  handleFieldOnChange("housingId", v ? v.value : undefined);
                }}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <ThemeTextField
                key="milesFromSchool"
                id="milesFromSchool"
                name="milesFromSchool"
                className={classes.textField}
                label="Miles from School"
                type="number"
                value={model.milesFromSchool ?? ""}
                onChange={(e: any) => {
                  handleFieldOnChange("milesFromSchool", e.target.value);
                }}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <GeographicTypesAutoComplete
                filterHandle={(v: any) => {
                  handleFieldOnChange("geographicAreaId", v ? v.value : null);
                }}
                id="geographicAreaId"
                name="geographicAreaId"
                valueFilter={
                  model.geographicAreaId
                    ? {
                      key: "value",
                      values: [model.geographicAreaId]
                    }
                    : undefined
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <MaritalStatusAutoComplete
                id="maritalStatusId"
                name="maritalStatusId"
                filterHandle={(v: any) => {
                  handleFieldOnChange("maritalStatusId", v ? v.value : null);
                }}
                valueFilter={
                  model.maritalStatusId
                    ? {
                      key: "value",
                      values: [model.maritalStatusId]
                    }
                    : undefined
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <ThemeTextField
                id="familyIncomeAmount"
                key="familyIncomeAmount"
                name="familyIncomeAmount"
                className={classes.textField}
                label="Family Income *"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
                error={!!data.errors.familyIncomeAmount}
                inputRef={data.register({ required: true })}
                helperText={
                  data.errors.familyIncomeAmount ? "Family Income is required." : undefined
                }
                value={model.familyIncomeAmount ?? ""}
                onChange={(e: any) => {
                  handleFieldOnChange("familyIncomeAmount", e.target.value);
                }}
              />
            </Grid>
            {!props.isNewEnrollment && <Grid item md={4} sm={6} xs={12}>
              <InputField id="efc"
                key="efc"
                name="efc"
                label="EFC"
                disabled
                onBlur={(e: any) => {
                  handleFieldOnChange("efc", e.target.value);
                }} inputRef={data.register({ required: false })}
                defaultValue={model.efc}
                type={InputType.CURRENCY}
                adornmentPosition={AdornmentPosition.PRE}
                decimal
                InputLabelProps={{shrink: true}}
                InputProps={{ endAdornment: (<InputAdornment position="end"><IconButton color="primary" type="button" onClick={() => toggleEfHistory(!efcHistoryOpen)}><HistoryIcon /></IconButton></InputAdornment>)}} />
              <EFCHistoryField open={efcHistoryOpen} closeDialogue={() => toggleEfHistory(false)} showSnackBar={props.showSnackBar} setEfcAfterSave={(newEfc) => {
                setModel({...model, efc: newEfc.efcValue, efcId: newEfc.efcId });
                data.setValue("efc", newEfc.efcValue);
                data.setValue("efcId", newEfc.efcId)

              }} />
            </Grid>}
            <Grid item md={4} sm={6} xs={12}>
              <CitizenshipAutoComplete
                id="citizenshipId"
                name="citizenshipId"
                label="Citizenship *"
                valueFilter={
                  model.citizenshipId
                    ? {
                      key: "value",
                      values: [model.citizenshipId]
                    }
                    : undefined
                }
                error={!!data.errors.citizenshipId}
                inputRef={data.register({ required: true })}
                helperText={
                  data.errors.citizenshipId
                    ? "Citizenship is required."
                    : undefined
                }
                filterHandle={(v: any) => {
                  handleFieldOnChange("citizenshipId", v ? v.value : undefined);
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default Demographics;
