import {
    createStyles,
    
    withStyles,
    TextField,
  } from "@material-ui/core";
export const ThemeTextField = withStyles((theme: any) =>
createStyles({
  root: {
    "& .MuiFormLabel-root": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "100%",
    },
  },
})
)(TextField);