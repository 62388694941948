import * as React from "react";
import { useRef } from "react";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { EmptyGuid } from "../../utils/constants";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {
      width: "95%",
    },
  })
);
export interface ProgramVersionProps extends FilterParams {
  programId?:string;
  programVersionId?: string;
  selectDefaultFirst?: boolean;
}

const AutoCompleteConfig = {
  config: {
    acId: "HoursTypeAc",
    cacheTime: 5,
    route: "/SystemCatalog/HoursType/GetHoursTypesByProgramClockHour",
    requestType: "GET",
    body: null,
    hasUrlParams: true,
    useCache: false,
    storeInCache: false,
    isCampusSpecific: true,
    isUserSpecific: false,
    loadOnRender: true,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
      return data;
    },
  },
};

const HoursTypeAutoComplete = (props: ProgramVersionProps) => {
  const classes = useStyles({});
  const inputRef: any = useRef(null);
  const [programVersionId, setProgramVersionId] = React.useState<string | undefined>(
    undefined
  );

  const [programId, setProgramId] = React.useState<string | undefined | null>(
    null
  );

  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const PrgVerOnChange = (e: any) => {
    if (props.filterHandle) {
      props.filterHandle(e);
    }
  };

  React.useEffect(() => {
    setProgramVersionId(props.programVersionId);
    if(props.programVersionId && props.programVersionId != EmptyGuid){
      setProgramId(null);
    }

  }, [props.programVersionId]);

  React.useEffect(() => {
    setProgramId(props.programId);
  }, [props.programId]);

  return (
    <div className={clsx(classes.divWidth)}>
      <ApiAutoComplete
        config={{...AutoCompleteConfig.config, defaultSelectedFirstItem: !!props.selectDefaultFirst}}
        loading={userSelectedCampus ? false : true}
        reqParams={programVersionId !== undefined ? { programVersionId: programVersionId, programId: (programVersionId && programVersionId != EmptyGuid) ? null : programId } : null}
        classes={{
          option: "text-black",
        }}
        label={
          props.label
            ? props.label
            : "Graduate when program length is reached in"
        }
        onChange={(e: any) => {
          PrgVerOnChange(e);
        }}
        multiple={props.multiple ? props.multiple : false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        inputRef={props.inputRef ? props.inputRef : inputRef}
        error={props.error}
        helperText={props.helperText}
        inputName={props.name}
      ></ApiAutoComplete>
    </div>
  );
};
export default HoursTypeAutoComplete;
