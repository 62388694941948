import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AddPhotoIcon from '@material-ui/icons/AddPhotoAlternate';
import { Tooltip, IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        uploadInput: {
            display: 'none'
        }
    })
);

type PhotoUploadButtonProps = {
    text?: string;
    onClick: any;
};

const PhotoUploadButton = (props: PhotoUploadButtonProps) => {
    const classes = useStyles({});
    const { text, onClick, ...rest } = props;

    const handleButtonClick = (e:any) => {
        if (onClick) onClick(e);
    };

    return (
        <React.Fragment>
            <div style={{ display: 'inline-flex' }}><input
                accept="image/*"
                className={classes.uploadInput}
                style={{ display: 'none' }}
                id="profile-photo-file"
                multiple={false}
                type="file"
                onChange={handleButtonClick}
            />
                <label htmlFor="profile-photo-file">
                    <Tooltip title={text ? text : "Upload Profile Photo"}>
                        <IconButton aria-label="upload photo" component="span">
                            <AddPhotoIcon />
                        </IconButton>
                    </Tooltip>
                </label>
            </div>
        </React.Fragment>

    );
};

export default PhotoUploadButton;
