export interface IScheduleDetailsGridRow {
    key: string;
    type: any;
    optionName: string;
    monday: any;
    tuesday: any;
    wednesday:any;
    thrusday:any;
    friday:any;
    saturday:any;
    sunday:any;
    order:number;
    defaultValue: number;
  }
  
  export class ScheduleDetailsGridRow implements IScheduleDetailsGridRow{
      key: string = "";
      type: any;
      optionName: string = "";
      monday: any;
      tuesday: any;
      wednesday: any;
      thrusday: any;
      friday: any;
      saturday: any;
      sunday: any;
      order: number = 0;
      defaultValue: number=0;
  }