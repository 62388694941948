import { fork } from 'redux-saga/effects';
import adhocReportsSaga from './reports/adhocReports';
import illinoisTranscriptSaga from './setup/illinoisTranscript';
import licenseSaga from './setup/stateWiseLicense';
import illinoisTranscriptReportSaga from './reports/illlinoistranscript';
import transcriptReportSaga from './reports/transcriptReport';
export default function* rootSaga() {
	yield fork(adhocReportsSaga);
	yield fork(illinoisTranscriptSaga);
	yield fork(licenseSaga);
	yield fork(illinoisTranscriptReportSaga);
	yield fork(transcriptReportSaga);
}