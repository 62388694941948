import * as React from 'react';
import { makeStyles, TextField, StandardTextFieldProps, OutlinedTextFieldProps } from '@material-ui/core';
import { InputType, AdornmentPosition, DEFAULT_CURRENCY, DEFAULT_DECIMAL_PLACES, INTEGER_DECIMAL_PLACES, DEFAULT_THOUSAND_SEPARATOR } from '../../../constants/uiConstants/inputConstants';
import { isBoolean } from 'util';

export interface IInputFieldProps extends StandardTextFieldProps {
    type?: InputType;
    currencySymbol?: string;
    thousandSeparator?: string | boolean;
    decimal?: boolean;
    decimalPlaces?: number;
    adornmentPosition?: AdornmentPosition;
    inputRef?(el: any): void;
    readOnly?: boolean;
}

export interface IOutlinedInputFieldProps extends OutlinedTextFieldProps {
    type?: InputType;
    currencySymbol?: string;
    thousandSeparator?: string | boolean;
    decimal?: boolean;
    decimalPlaces?: number;
    adornmentPosition?: AdornmentPosition;
    inputRef?(el: any): void;
    readOnly?: boolean;
}

const useStyles = makeStyles((theme) => (
	{
		root: {
			marginRight: theme.spacing(1),
			width: '100%'
		}
	}));

const InputField = (props: Readonly<IInputFieldProps | IOutlinedInputFieldProps>): React.ReactElement => {
	const { onChange, onBlur, type, currencySymbol, decimal, decimalPlaces, thousandSeparator, adornmentPosition, defaultValue, inputRef, ...rest } = props;
	const classes = useStyles();

	const getRawValue = (formattedValue: Readonly<string>): any => {
		switch (type) {
		case InputType.NUMBER:
			if (decimal) {
				return Number.parseFloat((formattedValue || '0').replace(/,| /g, ''));
			}
			return Number.parseInt((formattedValue || '0').replace(/,| /g, ''));
		case InputType.CURRENCY:
			const str = (formattedValue || '0').replace(/,| /g, '').replace(currencySymbol ?? DEFAULT_CURRENCY, '');
			return Number.parseFloat(str || '0');
		default:
			return formattedValue;
		};
	};

	const formatValue = (currValue: any): string => {
		switch (type) {
		case InputType.NUMBER:
		case InputType.CURRENCY:
			let formatted = '';
			if (decimal) {
				formatted = Number(currValue ?? 0).toFixed(decimalPlaces ?? DEFAULT_DECIMAL_PLACES);
			} else {
				formatted = Number(currValue ?? 0).toFixed(INTEGER_DECIMAL_PLACES);
			}
			if (!!thousandSeparator) {
				const parts = formatted.split('.');
				parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, isBoolean(thousandSeparator) ? DEFAULT_THOUSAND_SEPARATOR : thousandSeparator);
				formatted = parts.join('.');
			}
			if (type === InputType.CURRENCY) {
				return `${(!adornmentPosition || adornmentPosition === AdornmentPosition.PRE)
					? `${currencySymbol ?? DEFAULT_CURRENCY} ` : ''}${formatted}${(adornmentPosition === AdornmentPosition.POST)
					? ` ${currencySymbol ?? DEFAULT_CURRENCY}` : ''}`;
			}
			return formatted;

		default:
			return currValue;
		}
	};

	const valueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const formattedValue = e.target.value;
		const rawValue: any = getRawValue(formattedValue);
		if (
			type === InputType.NUMBER ||
            (type === InputType.CURRENCY &&
                formattedValue &&
                formattedValue
                	.replace(currencySymbol ?? DEFAULT_CURRENCY, '')
                	.replace(' ', ''))
		) {
			if (isNaN(rawValue)) {
				document.execCommand('undo');
				return;
			}
			if (formattedValue && formattedValue.includes('.')) {
				const parts = formattedValue.split('.');
				if (!decimal) {
					e.target.value = parts[0];
					return;
				} else {
					if (
						parts[1] &&
                        parts[1].length > (decimalPlaces ?? DEFAULT_DECIMAL_PLACES)
					) {
						e.target.value = formattedValue.substring(
							0,
							formattedValue.length -
                            (parts[1].length - (decimalPlaces ?? DEFAULT_DECIMAL_PLACES))
						);
						return;
					}
				}
			}
		}
		if (onChange) {
			e.target.value = rawValue;
			onChange(e);
			e.target.value = rawValue || rawValue === 0 ? formatValue(rawValue) : '';
		}
	};

	const fieldBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		if (onBlur) {
			const formattedValue = e.target.value;
			const rawValue: any = formattedValue && getRawValue(formattedValue);
			e.target.value = !rawValue && isNaN(rawValue) ? undefined : rawValue;
			onBlur(e);
			e.target.value = (rawValue || rawValue === 0) ? formatValue(rawValue) : '';
		}
	}

	let input: HTMLInputElement;

	React.useEffect(() => {
		if (input) {
			const formattedValue = (defaultValue || defaultValue === 0) ? formatValue(defaultValue) : '';
			input.value = formattedValue;
		}
	}, [defaultValue])

	return (

		<TextField
			inputProps={{
				readOnly: props.readOnly ?? false
			}}
			onBlur={fieldBlur}
			onChange={valueChange}
			className={classes.root}
			classes={classes}
			inputRef={inputRef ? typeof inputRef === 'function' ? el => { input = el; inputRef && inputRef(el); } : inputRef : el => { input = el; }}
			{...rest}
		/>);
}

export default InputField;
