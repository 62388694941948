import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "@material-ui/core/TextField";
import * as postGradesApi from "../../../api/student/academics/postGradesApi";
import { useSelector } from "react-redux";

import { useFormContext, FieldError } from "react-hook-form";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import { security } from "../../../constants/Security/securityConstants";
import AccessManager from "../../../components/security/AccessManager";
import { string } from "prop-types";
import { StudentGrade } from "../../../interfaces/student/academics/StudentGrade";
import moment from "moment";
import { IStudentAttempts } from "../../../interfaces/student/academics/PostGradesByClassFilter";
import { CustomSnackBarProps } from "../../../interfaces/common/CustomSnackBarProps";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
    gridHeader: {
      backgroundColor: theme.palette.secondary.dark,
      height: "50px",
      display: "block",
      width: "100%",
      padding: "10px",
      tableLayout: "fixed",
    },
    table: {
      width: "100%",
    },
    buttonSty: {
      padding: theme.spacing(1),
      display: "inline-flex",
    },
    buttonStyDelete: {
      padding: theme.spacing(1),
      display: "inline-flex",
      backgroundColor: "#903333",
    },

    buttonDiv: {
      display: "inline-flex",
    },
    datePicker: {
      "& .MuiIconButton-root": {
        padding: theme.spacing(0.8),
      },
    },
    wrapper: {
      position: "relative",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
      "& ::-webkit-inner-spin-button, ::-webkit-outer-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
        MozAppearance: "textfield",
      },
    },
    gridCellTypeDate: {
      width: "45%",
      padding: "1px",
    },
    gridCellTypeInput: {
      width: "20%",
      padding: "1px",
    },
    gridCellTypeCheckBox: {
      width: "15%",
      padding: "1px",
    },
    header: {
      height: "40px",
      padding: "10px",
      fontWeight: 600,
      width: "100%",
      backgroundColor: theme.palette.secondary.dark,
    },
    validationMsg: {
      padding: "10px",
      color: theme.palette.error.main,
    },
    popoverStyle: {
      maxHeight: "800px",
      "& .MuiPopover-paper": {
        maxWidth: "400px !important",
        overflowY: "hidden",
      },
    },
    tableBodyWrapper: {
      maxHeight: "530px",
      overflowY: "auto",
      display: "block",
      width: "100%",
      padding: "10px",
    },
    tableRowWrapper: {
      display: "table",
      width: "100%",
      tableLayout: "fixed",
    },
    tableRowWrapperRed: {
      display: "table",
      width: "100%",
      tableLayout: "fixed",
      backgroundColor: "rgb(255, 201, 201)",
    },
    tableWrapper: {
      height: "calc(100% - 40px)",
      maxWidth: "400px",
      width: "100%",
    },
  })
);
type AttemptsHistoryBoxProps = {
  gradeBookWeightDetailId: any;
  showAdj: boolean;
  showScore: boolean;
  lblAdj: string;
  tabType: string;
  excludeLast: boolean;
  isClinicServiceScoreEnabled?: boolean;
  allowMoreClinicServicesOrHours?: boolean;
  setSnackBar: any;
  setConfirmationModal: any;
  /* 
	setConfirmationModal: any;
	refreshGridData:any;
	rowData:any; */
  refreshGridData: any;
  stuEnrollId: any;
  instrClassTermId: string;
  attempts: number;
  isOverridden: boolean;
  studentName: string | undefined;
};

const CreditAttemptHistoryBox = (props: AttemptsHistoryBoxProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [originalData, setOriginalData] = React.useState<any>();
  const [totalAdjustment, setTotalAdjustment] = React.useState<number>(
    props.attempts
  );
  const [openedPopoverId, setopenedPopoverId] = React.useState<any>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [checked, setChecked] = React.useState(true);
  let d = new Date();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const [studentAttemptsData, setStudentAttemptsData] = React.useState<
    Array<IStudentAttempts>
  >([]);

  React.useEffect(() => { 
    setOverrideGrade(false);
  }, [props.gradeBookWeightDetailId]);

  const [selected, setSelected] = React.useState([] as any);
  const isSelected = (grdBkResultId: any) =>
    selected.indexOf(grdBkResultId) !== -1;
  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = studentAttemptsData.map((n) => n.grdBkResultId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleChkClick = (event: any, grdBkResultId: string) => {
    const selectedIndex = selected.indexOf(grdBkResultId);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, grdBkResultId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleClose = () => {
    setSelected([]);
    setOverrideGrade(false);
    setAnchorEl(null);
  };

  const handleUpdate = () => {
    if (props.stuEnrollId) {
      var validComponents: IStudentAttempts[] = [];
      studentAttemptsData.forEach((comp) => {
        const isItemSelected = isSelected(comp.grdBkResultId);
        if (isItemSelected == true) {
          validComponents.push({
            score: comp.score,
            grdBkResultId: comp.grdBkResultId,
            postDate: comp.postDate,
            dateCompleted: comp.dateCompleted,
            instrClassTermId: props.instrClassTermId,
            stuEnrollId: props.stuEnrollId,
          });
        }
      });
      if (validComponents.length > 0) {
        postGradesApi.updateGrades(validComponents, overrideGrade).then(
          (response: any) => {
            if (response) {
              let data = response.data;
              let attemptData: any = {
                ...data,
              };
              setAnchorEl(attemptData.result ? null : anchorEl);
              setSelected([]);
              props.refreshGridData();
              props.setSnackBar((props: any) => {
                return {
                  variant: "success",
                  showSnackBar: true,
                  messageInfo: data.resultStatusMessage,
                };
              });
            }
          },
          (exception: any) => {
            props.setSnackBar((props: any) => {
              return {
                ...props,
                showSnackBar: true,
                messageInfo: exception.resultStatusMessage,
              };
            });
          }
        );
      }
    }
  };

  const handleDelete = () => {
    props.setConfirmationModal((state: any) => {
      return {
        ...state,
        open: true,
        confirmationText: "Are you sure you want to delete?",
        confirmationButton: "Yes",
        title: "Delete Attempt",
        onConfirmEvent: () => {
          DeleteAttempt();
          props.setConfirmationModal((updatedState: any) => {
            return { ...updatedState, open: false };
          });
        },
        onCancelEvent: () => {
          props.setConfirmationModal((updatedState: any) => {
            return { ...updatedState, open: false };
          });
        },
      };
    });
  };

  const DeleteAttempt = () => {
    if (props.stuEnrollId) {
      var validComponents: IStudentAttempts[] = [];
      studentAttemptsData.forEach((comp) => {
        const isItemSelected = isSelected(comp.grdBkResultId);
        if (isItemSelected == true) {
          validComponents.push({
            score: comp.score,
            grdBkResultId: comp.grdBkResultId,
            postDate: comp.postDate,
            dateCompleted: comp.dateCompleted,
            instrClassTermId: props.instrClassTermId,
            stuEnrollId: props.stuEnrollId
          });
        }
      });
      if (validComponents.length > 0) {
        postGradesApi.deleteGrades(validComponents, overrideGrade).then(
          (response: any) => {
            if (response) {
              let data = response.data;
              let attemptData: any = {
                ...data,
              };
              setAnchorEl(attemptData.result ? null : anchorEl);
              setSelected([]);
              props.refreshGridData();
              props.setSnackBar((props: any) => {
                return {
                  variant: "success",
                  showSnackBar: true,
                  messageInfo: data.resultStatusMessage,
                };
              });
            }
          },
          (exception: any) => {
            props.setSnackBar((props: any) => {
              return {
                ...props,
                showSnackBar: true,
                messageInfo: exception.resultStatusMessage,
              };
            });
          }
        );
      }
    }
  };

  React.useEffect(() => {
    if (anchorEl !== null && anchorEl !== undefined) {
      if (props.stuEnrollId) {
        let InstrGrdBkWgtDetailId = props.gradeBookWeightDetailId;
        postGradesApi
          .getGradesByComponent(
            props.gradeBookWeightDetailId,
            props.stuEnrollId
          )
          .then(
            (response: any) => {
              if (response) {
                setStudentAttemptsData(response.data);
              }
            },
            (exception: any) => {}
          );
      }
    }
  }, [anchorEl]);

  const handelScore = (Score: string, ix: number) => {
    let uComp = [...studentAttemptsData];
    uComp[ix].isDirty = true;
    if (isNumber(Score)) {
      uComp[ix].score = Score;
    } else {
      uComp[ix].score = null;
    }
    uComp[ix].rowError = uComp[ix].dateCompleted != null ? false : true;

    setStudentAttemptsData([...uComp]);
  };

  const changeDefaultDate = (dDate: Date, ix: number) => {
    let uComp = [...studentAttemptsData];
    var date = moment(dDate);
    uComp[ix].dateCompleted = date.isValid() ? dDate : null;
    uComp[ix].isDirty = true;
    uComp[ix].rowError = uComp[ix].score != null ? false : true;

    setStudentAttemptsData([...uComp]);
  };

  const isNumber = (value: string) => {
    return /^-?\d*\.?\d+$/.test(value);
  };

  const [overrideGrade, setOverrideGrade] = React.useState<boolean>(false);

  return (
    <div>
      <Button aria-describedby={id} onClick={handleClick}>
        <ListAltOutlinedIcon />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.popoverStyle}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography className={classes.header}>Previous Attempts</Typography>

        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <TableHead className={classes.gridHeader}>
              <TableRow className={classes.tableRowWrapper}>
                <TableCell
                  align="left"
                  classes={{
                    head: classes.gridCellTypeCheckBox,
                  }}
                >
                  <Checkbox
                    className={classes.datePicker}
                    value="uncontrolled"
                    color="primary"
                    onClick={handleSelectAllClick}
                    inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                  />
                </TableCell>
                <TableCell
                  classes={{
                    head: classes.gridCellTypeDate,
                  }}
                  align="left"
                >
                  Date
                </TableCell>
                {props.showScore ? (
                  <TableCell
                    classes={{
                      head: classes.gridCellTypeInput,
                    }}
                    align="left"
                  >
                    Score
                  </TableCell>
                ) : undefined}
                {props.showAdj ? (
                  <TableCell
                    classes={{
                      head: classes.gridCellTypeInput,
                    }}
                    align="left"
                  >
                    {props.lblAdj}
                  </TableCell>
                ) : undefined}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBodyWrapper}>
              {(studentAttemptsData as IStudentAttempts[]).map(
                (row: IStudentAttempts, index: any) => {
                  const isItemSelected = isSelected(row.grdBkResultId);
                  return (
                    <TableRow
                      onClick={(event) =>
                        handleChkClick(event, row.grdBkResultId ?? "")
                      }
                      role="checkbox"
                      tabIndex={-1}
                      key={row.grdBkResultId}
                      selected={isItemSelected}
                      className={
                        row.rowError == true
                          ? classes.tableRowWrapperRed
                          : classes.tableRowWrapper
                      }
                    >
                      <TableCell
                        align="left"
                        classes={{
                          body: classes.gridCellTypeCheckBox,
                        }}
                      >
                        <Checkbox
                          value="uncontrolled"
                          checked={isItemSelected}
                          id={"chk_" + row.grdBkResultId}
                          color="primary"
                          inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                        />
                      </TableCell>
                      <TableCell
                        classes={{
                          body: classes.gridCellTypeDate,
                        }}
                        align="left"
                      >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            clearable
                            format={"MM/dd/yyyy"}
                            placeholder="mm/dd/yyyy"
                            id="defaultDate"
                            key={row.grdBkResultId}
                            name="defaultDate"
                            label="Default Date"
                            className={classes.datePicker}
                            value={row.dateCompleted ? row.dateCompleted : null}
                            onChange={(value: any) => {
                              changeDefaultDate(value ? value : null, index);
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            autoOk={true}
                          />
                        </MuiPickersUtilsProvider>
                      </TableCell>
                      <TableCell
                        classes={{
                          body: classes.gridCellTypeInput,
                        }}
                        align="center"
                      >
                        <InputField
                          type={InputType.NUMBER}
                          label="Score"
                          name="Score"
                          key="Score"
                          decimal
                          thousandSeparator
                          defaultValue={row.score ?? ""}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handelScore(v.target.value, index);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
              {
                props.isOverridden == true ? <TableRow>
                <TableCell
                  align="left"
                  classes={{
                    body: classes.gridCellTypeCheckBox,
                  }}
                  colSpan={3}
                >
                  <Checkbox
                    value="uncontrolled"
                    color="primary"
                    checked={overrideGrade}
                    onClick={(e: any) => {
                      setOverrideGrade(e.target.checked);
                    }}
                    inputProps={{
                      "aria-label": "uncontrolled-checkbox",
                    }}
                  />
                  Recalculate Final?
                </TableCell>
              </TableRow> : ""
              }
              
            </TableBody>
          </Table>
        </div>
        <div className={classes.buttonDiv}>
          <div className={classes.buttonSty}>
            <Button
              className={classes.buttonSty}
              variant="contained"
              color="primary"
              size="small"
              onClick={(e: any) => {
                handleUpdate();
              }}
            >
              SAVE
            </Button>
          </div>
          <div className={classes.buttonSty}>
            <Button
              className={classes.buttonStyDelete}
              variant="contained"
              color="primary"
              size="small"
              onClick={(e: any) => {
                handleDelete();
              }}
            >
              DELETE
            </Button>
          </div>
          <div className={classes.buttonSty}>
            <Button
              className={classes.buttonSty}
              variant="contained"
              color="primary"
              size="small"
              onClick={(e: any) => {
                handleClose();
              }}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
};
export default CreditAttemptHistoryBox;
