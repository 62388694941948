import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { StudentProfile } from "../../../../../interfaces/student/profile/StudentProfile";
import { Paper, Button, Input, IconButton, Typography, Grid } from "@material-ui/core";
import StudentAvatar from "../../../StudentAvatar/StudentAvatar";
import { IEnrollmentSearchResult } from "../../../../../interfaces/student/enrollment/EnrollmentSearchResult";
const useStyles = makeStyles((theme: any) => ({
	studentSearchResultItem: {
		marginBottom: "7pt",
		width: '100%',
		justifyContent: 'center',
		alignSelf: 'center',
	},
	studentSearchResultItemGrid: {
		margin: "0px"
	},
	subTitle2: {
		color: theme.palette.black,
		fontSize: "11pt",
		marginBottom: "4pt",
		fontWeight: "bolder"
	},
	subTitle3: {
		color: theme.palette.black,
		fontWeight: "normal"
	},
	avatarContainer: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2)
	},
	studentAvatar: {
		// height: "100%",
		// width: "100%",
		// minwidth: 40,
		// minheight: 40,
		maxwidth: 50,
		maxheight: 50
	},
	studentNumber: {
		marginRight: "0.4em"
	},
	height: {
		height: "100%"
	}
}));

const EnrollmentSearchResultItem = (props: any) => {
	const classes = useStyles({});
	const profile = props.studentProfile as IEnrollmentSearchResult;
	const onStudentSelected = (e: any) => {
		if (props.onSelect) {
			props.onSelect(e, profile);
		}
	};

	return (
		<Paper
			elevation={1}
			className={classes.studentSearchResultItem}
			onClick={(e: any) => {
				onStudentSelected(e);
			}}>
			<Grid container className={classes.studentSearchResultItemGrid}>
				<Grid container spacing={3} className={classes.studentSearchResultItemGrid}>
					<Grid item className={classes.avatarContainer}>
						<Grid
							container
							direction='column'
							alignContent='center'
							justify='center'
							className={classes.height}>
							<Grid item xs={12}>
								<StudentAvatar
									className={classes.studentAvatar}
									firstName={profile != undefined ? profile.studentName!.split(" ")[0] : ""}
									lastName={
										profile != undefined
											? profile.studentName!.split(" ")[
											profile.studentName!.split(" ").length - 1
											]
											: ""
									}
									src={profile && profile.profilePhoto ? `data:${profile.profilePhoto.mediaType};base64,${profile.profilePhoto.image}` : ''}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item md={4} lg={5} container>
						<Grid item xs container direction='column' spacing={2}>
							<Grid item xs>
								<Typography gutterBottom variant='subtitle2' className={classes.subTitle2}>
									<span className={classes.studentNumber}>
										{profile != undefined ? profile.studentNumber : ""}
										{" -"}
									</span>
									<span>{profile != undefined ? profile.studentName : ""}</span>
								</Typography>
								<Typography variant='body2' color='textSecondary'>
									<span>{profile != undefined ? profile.campus : ""}</span>
								</Typography>
								<Typography variant='body2' color='textSecondary'>
									<span>{profile != undefined ? profile.programVersion : ""}</span>
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item md={5} lg={5} container>
						<Grid item xs container direction='column' spacing={2}>
							<Grid item xs>
								<Typography gutterBottom variant='subtitle2' className={classes.subTitle3}>
									<span>{profile != undefined ? profile.enrollmentStatus : ""}</span>
								</Typography>
								<Typography variant='body2' color='textSecondary'>
									<span>Start: {profile != undefined ? profile.startDate : ""}</span>
								</Typography>
								<Typography variant='body2' color='textSecondary'>
									<span>LDA: {profile != undefined ? profile.lastDateOfAttendance : ""}</span>
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};

EnrollmentSearchResultItem.propTypes = {
	className: PropTypes.string,
	studentProfile: PropTypes.any,
	onSelect: PropTypes.func
};

export default EnrollmentSearchResultItem;
