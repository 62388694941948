export interface ILeaveOfAbsence {
    studentEnrollmentId: string;
    studentStatusChangeId?: string;
    studentLeaveOfAbsenceId?: string;
    statusCodeId: string;
    startDate: Date | string;
    endDate: Date | string;
    requestDate: Date | null;
    returnDate: Date | null;
    reasonId: string;
    reason: string;
    modifiedDate: Date;
    modifiedUser?: string;
    isEditMode?: boolean;
    existingDisbursementReason?: string;
  }
  
  export function NewILeaveOfAbsenceDetail(studentEnrollmentId: string, statusCodeId: string) {
    return {
      studentEnrollmentId: studentEnrollmentId,
      studentStatusChangeId: "",
      studentLeaveOfAbsenceId: "00000000-0000-0000-0000-000000000000",
      statusCodeId: statusCodeId,
      startDate: "",
      endDate: "",
      requestDate: null,
      returnDate: null,
      reasonId: "",
      reason: "",
      modifiedDate: new Date(),
      modifiedUser: "",
      isEditMode: false,
    } as ILeaveOfAbsence;
  }