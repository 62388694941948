import { Action } from "redux";
import * as actionTypes from "../../constants/actions.constants";

const initialState: any = {
    getEnrollmentProgramAndGroups: function(userId: any) {
		return this[userId] ? this[userId].selectedEnrollmentProgramAndGroups : null;
	},
    getAdvanceSearchModel: function(userId: any) {
		return this[userId] && this[userId].advanceSearchModel ? this[userId].advanceSearchModel : {
            ProgramVersionId: "",
            StudentGrpId: "",
            EnrollmentStatusId: "",
            StartDate: null,
          };
	},
    isAnyFilterApplied: function(userId:any) {
      return (this[userId] && this[userId].advanceSearchModel && (
          (this[userId].advanceSearchModel.ProgramVersionId && this[userId].advanceSearchModel.ProgramVersionId != "") ||
          (this[userId].advanceSearchModel.StudentGrpId && this[userId].advanceSearchModel.StudentGrpId != "") ||
          (this[userId].advanceSearchModel.EnrollmentStatusId && this[userId].advanceSearchModel.EnrollmentStatusId != "") ||
          (this[userId].advanceSearchModel.StartDate)))
    },
};

const userAdvanceSearchReducer = (state = initialState, action: Action) => {
	switch (action.type) {
        case actionTypes.advanceSearchConstants.SET_ENROLLMENT_PROGRAM_AND_GROUPS_ADVANCE_SEARCH: {
			let newState = { ...state };
			newState[(action as any).data.userId] = {
				...newState[(action as any).data.userId],
				selectedEnrollmentProgramAndGroups: (action as any).data.selectedEnrollmentProgramAndGroups
			};
			return newState;
		}

        case actionTypes.advanceSearchConstants.SET_ADVANCE_SEARCH_FILTERS: {
			let newState = { ...state };
			newState[(action as any).data.userId] = {
				...newState[(action as any).data.userId],
				advanceSearchModel: (action as any).data.advanceSearchModel
			};
			return newState;
		}

		default: {
			return state;
		}
	}
};

export default userAdvanceSearchReducer;
