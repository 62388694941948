import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionsFunctions, ActionFunctionsObject, AsyncActionFunctionsObject, ActionBuilders, Types, ActionLifeCycle, ActionFunction, IActionBuilder, AsyncState, ReducerCases, IGenericAction, Selectors, GeneratedSelectors, ActionsBinding, BoundActionCreators } from "../interfaces/redux/utils";

const getType = (namespace: string, key: string, lifeCycle: ActionLifeCycle = ActionLifeCycle.None): string => `${namespace}-${key}-${lifeCycle}`;

const getActionBuilder = <F extends ActionFunction>(actionFunction: F, type: string, lifeCycle: ActionLifeCycle = ActionLifeCycle.None): IActionBuilder<F> => ((...params: Parameters<F>) => ({
	lifeCycle,
	type,
	data: actionFunction(...params)
}));

const generateActionBuildersAndTypes = <A extends AsyncActionFunctionsObject, S extends ActionFunctionsObject>(actions: ActionsFunctions<A, S>, namespace: string): { actions: ActionBuilders<A, S>, types: Types<A, S> } => {
	const { asyncs, sync } = actions;

	const types: Types<A, S> = ({
		...(Object.keys(sync).map(m => ({ key: m, val: getType(namespace, m, ActionLifeCycle.None) })).reduce((acc: Object, curr) => ({ ...acc, [curr.key]: curr.val }), {})),
		...((asyncs && Object.keys(asyncs).map(m => ({ key: m, val: getType(namespace, m, ActionLifeCycle.Request) })).reduce((acc: Object, curr) => ({ ...acc, [curr.key]: curr.val }), {})) || {}),
		saga: {
			...((asyncs && Object.keys(asyncs).map(m => ({ key: m, success: getType(namespace, m, ActionLifeCycle.Success), fail: getType(namespace, m, ActionLifeCycle.Failure) })).reduce((acc: Object, curr) => ({
				...acc, [curr.key]: {
					success: curr.success,
					failure: curr.fail
				}
			}), {})) || {})
		}
	}) as Types<A, S>;

	const actionBuilders: ActionBuilders<A, S> = ({
		...(Object.keys(sync).map((m: string) => ({ key: m, actionType: getType(namespace, m, ActionLifeCycle.None), func: sync[m] })).reduce((acc: Object, curr) => ({ ...acc, [curr.key]: getActionBuilder(curr.func, curr.actionType, ActionLifeCycle.None) }), {})),
		...((asyncs && Object.keys(asyncs).map(m => ({ key: m, actionType: getType(namespace, m, ActionLifeCycle.Request), func: asyncs[m].request })).reduce((acc: Object, curr) => ({ ...acc, [curr.key]: getActionBuilder(curr.func, curr.actionType, ActionLifeCycle.Request) }), {})) || {}),
		saga: {
			...((asyncs && Object.keys(asyncs).map(m => ({ key: m, successType: getType(namespace, m, ActionLifeCycle.Success), successFunction: asyncs[m].success, failType: getType(namespace, m, ActionLifeCycle.Failure), failFunction: asyncs[m].failure })).reduce((acc: Object, curr) => ({
				...acc, [curr.key]: {
					success: getActionBuilder(curr.successFunction, curr.successType, ActionLifeCycle.Success),
					failure: getActionBuilder(curr.failFunction, curr.failType, ActionLifeCycle.Failure)
				}
			}), {})) || {})
		}
	}) as ActionBuilders<A, S>;

	return { actions: actionBuilders, types: types };
};

const generateReducerCallBacks = <A extends AsyncActionFunctionsObject, S extends ActionFunctionsObject, D extends object>(reducerCallbacks: ReducerCases<A, S, D>, namespace: string): { [key: string]: (state: AsyncState<A, D>, action: IGenericAction) => AsyncState<A, D> } => {
	const { asyncs, sync } = reducerCallbacks;

	const callBacks: { [key: string]: (state: AsyncState<A, D>, action: IGenericAction) => AsyncState<A, D> } = {
		...(Object.keys(sync).map(m => ({ key: getType(namespace, m, ActionLifeCycle.None), callBack: sync[m] })).reduce((acc: { [key: string]: (state: AsyncState<A, D>, action: IGenericAction) => AsyncState<A, D> }, curr) => ({ ...acc, [curr.key]: curr.callBack }), {})),
		...((asyncs && Object.keys(asyncs).map(m => ({
			key: getType(namespace, m, ActionLifeCycle.Request),
			successKey: getType(namespace, m, ActionLifeCycle.Success),
			failKey: getType(namespace, m, ActionLifeCycle.Failure),
			callBack: asyncs[m].request,
			successCallBack: asyncs[m].success,
			failCallBack: asyncs[m].failure,
			typeKey: m
		})).reduce((acc: { [key: string]: (state: AsyncState<A, D>, action: IGenericAction) => AsyncState<A, D> }, curr) => ({
			...acc,
			[curr.key]: (state: AsyncState<A, D>, action: IGenericAction) => { return curr.callBack({ ...state, loading: { ...state.loading, [curr.typeKey]: true } }, action); },
			[curr.successKey]: (state: AsyncState<A, D>, action: IGenericAction) => { return curr.successCallBack({ ...state, loading: { ...state.loading, [curr.typeKey]: false } }, action); },
			[curr.failKey]: (state: AsyncState<A, D>, action: IGenericAction) => { return curr.failCallBack({ ...state, loading: { ...state.loading, [curr.typeKey]: false } }, action); }
		}), {})) || {})
	};

	return callBacks;
};

/**
 * Note : Inorder to use the selectors the namespace should be same as it is registered in root reducer.
 * @param actionFunctions action to be performed
 * @param initialData initial state
 * @param reducerCallbacks reducer cases for each action
 * @param namespace a unique string identifier
 * @param selectors selectors for data from redux(optional)
 */
export const createReducer = <A extends AsyncActionFunctionsObject, S extends ActionFunctionsObject, SR extends Selectors<A, D>, D extends object>(actionFunctions: ActionsFunctions<A, S>, initialData: D, reducerCallbacks: ReducerCases<A, S, D>, namespace: string, selectors: SR | undefined = undefined): { actions: ActionBuilders<A, S>, types: Types<A, S>, selectors: GeneratedSelectors<A, D, SR>, reducer: (state: AsyncState<A, D>, action: IGenericAction) => AsyncState<A, D> } => {
	const { actions, types } = generateActionBuildersAndTypes(actionFunctions, namespace);
	const reducerCallBackGenerated = generateReducerCallBacks(reducerCallbacks, namespace);
	const initialState: AsyncState<A, D> = {
		data: initialData,
		loading: {

		}
	};

	const reducer = (state: AsyncState<A, D> = initialState, action: IGenericAction): AsyncState<A, D> => {
		const foundCallBack = reducerCallBackGenerated[action.type];
		if (foundCallBack) {
			return foundCallBack(state, action);
		}
		return state;
	};

	const generatedSelectors = {
		...((selectors && Object.keys(selectors).map(m => ({ key: m, func: selectors[m] })).reduce((acc: Object, curr) => ({ ...acc, [curr.key]: (state: any) => { return ((state[namespace] && curr.func(state[namespace])) || undefined); } }), {})) || {})
	};

	return { actions, types, reducer, selectors: generatedSelectors as any };
};

export const useActions = <AC extends ActionsBinding> (actions: AC, deps: any): BoundActionCreators<AC> => {
	const dispatch = useDispatch();
	return useMemo(
		() => {
			return bindActionCreators(actions, dispatch);
		},
		deps ? [dispatch, ...deps] : [dispatch]
	);
};