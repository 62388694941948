import {
	CustomHeadCell,
	Order,
} from '../../../../../../interfaces/common/Table/CustomHeadCell';
import CustomTableHeader from '../../../../../../components/Tables/CustomTableHeader';
import React from 'react';
import {
	TableBody,
	makeStyles,
	createStyles,
	TableRow,
	TableCell,
	TableFooter,
} from '@material-ui/core';
import * as TableFunctions from '../../../../../../utils/tableFunctions';

import { IIncrementDetails } from '../../../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/CharginMethod/IIncrementDetails';
import IncrementDetailsGridRow from './IncrementDetailsGridRow';

type IncrementDetailsGridProps = {
	details: Array<IIncrementDetails>;
	isEditable: boolean;
	setModel: any;

	usesActualHours: boolean;
};
const useStyles = makeStyles((theme: any) =>
	createStyles({
		tableWrapper: {
			width: '100%',
			height: 'calc(100%)',
			overflowY: 'auto',
			paddingBottom: theme.spacing(2),
			marginTop: theme.spacing(2),
		},
		mainBody: {
			height: '100%',
		},
	})
);
const IncrementDetailsGrid = (props: IncrementDetailsGridProps) => {
	const classes = useStyles({});

	const headerCells: CustomHeadCell<IIncrementDetails>[] = [
		{
			id: 'actualHours',
			numeric: false,
			disablePadding: false,
			label: props.usesActualHours ? 'Actual Hours' : 'Scheduled Hours',
			widthType: 'M',
		},
		{
			id: 'chargeAmount',
			numeric: false,
			disablePadding: false,
			label: 'Charge Amount',
			widthType: 'M',
		},
		{
			id: 'transactionCode',
			numeric: false,
			disablePadding: false,
			label: 'Transaction Code',
			widthType: props.isEditable ? 'Remainder' : 'M',
		},

		{
			id: 'options',
			numeric: false,
			disablePadding: false,
			label: '',
			widthType: 'Option',
			isHidden: props.isEditable ? undefined : true,
		},
	];

	const [order, setOrder] = React.useState<Order>('asc');
	const [orderBy, setOrderBy] = React.useState<keyof IIncrementDetails>(
		'actualHours'
	);

	const updateRow = (property: string, rowId: string, newValue: any) => {
		let arrayToUpdate: IIncrementDetails[] = [...props.details];
		let modelToUpdate: any;
		modelToUpdate = arrayToUpdate.find((prop) => prop.id === rowId);
		if (modelToUpdate !== undefined) {
			modelToUpdate[property as keyof IIncrementDetails] = newValue;
		}
		props.setModel([...arrayToUpdate]);
	};

	const deleteRow = (rowId: string) => {
		let arrayToUpdate: IIncrementDetails[] = [...props.details];
		let arrayUpdated = arrayToUpdate.filter((d) => d.id !== rowId);
		props.setModel([...arrayUpdated]);
	};

	return (
		<div className={classes.tableWrapper}>
			<CustomTableHeader<IIncrementDetails>
				cells={headerCells}
				defaultOrder={order}
				defaultOrderBy={orderBy}
				setOrder={setOrder}
				setOrderBy={setOrderBy}
				key={'tex'}
			/>
			<TableBody className={classes.mainBody}>
				{TableFunctions.stableSort(
					props.details,
					TableFunctions.getSorting(order, orderBy) as any
				).map((row: IIncrementDetails) => {
					return (
						<IncrementDetailsGridRow
							row={row}
							updateRow={updateRow}
							usesActualHours={props.usesActualHours}
							removeRow={deleteRow}
							key={'increment_' + row.id}
						/>
					);
				})}
			</TableBody>
			<TableFooter>
				<TableRow key={'footer'}>
					<TableCell>{'Total'}</TableCell>
					<TableCell>
						{props.details.length > 0
							? props.details
								.map((d) => Number(d.chargeAmount))
								.reduce(function (a, b) {
									return a + b;
								})
								.toLocaleString('en-US', {
									style: 'currency',
									currency: 'USD',
								})
							: '$ 0.00'}
					</TableCell>
					<TableCell></TableCell>
					<TableCell></TableCell>
				</TableRow>
			</TableFooter>
		</div>
	);
};

export default IncrementDetailsGrid;
