import React from 'react';
import {
    makeStyles,
    createStyles,
    Grid,
    Paper,
    Table,
    withStyles,
    TableCell,
    TableRow,
    TableContainer,
    TableHead,
    TableBody,
    IconButton,
    CircularProgress,
    Button,
    Card,
    CardContent,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { format, addDays } from "date-fns";
import ICourseGradeBook from '../../../../../../../interfaces/setup/academics/ProgramDefinition/ICourseGradeBook';
import InputField from '../../../../../../../components/_Layout/Inputs/InputField';
import CustomCardTitle from '../../../../../../../interfaces/common/card/CustomCardTitle';
import { getCourseGradeBooks } from '../../../../../../../api/setup/academics/courses';
import GradeBookDetails from './GradeBookDetails';
import { EmptyGuid } from '../../../../../../../utils/constants';
import { CustomSnackBarProps } from '../../../../../../../interfaces/common/CustomSnackBarProps';

const StyledTableCell = withStyles((theme: any) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.site.secondary,
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            textAlign: 'left',
            boxShadow: '1px 1px 0px 0px rgba(0,0,0,0.2)',
            '&:first-child': {
                width: '42px',
                padding: theme.spacing(0, 1)
            }
        },
        body: {
            fontSize: 16,
            color: theme.palette.black,
            fontWeight: 400,
            cursor: 'pointer',
            textAlign: 'left',
            '&:first-child': {
                width: '42px',
                padding: theme.spacing(0, 1)
            }
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: any) =>
    createStyles({
        root: {
            '&:nth-of-type(even)': {
                backgroundColor: theme.palette.site.secondary,
            },
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.white,
            },
            '&:hover': {
                backgroundColor: theme.palette.site.secondary,
            }
        },
        body: {
            cursor: 'pointer'
        }
    }),
)(TableRow);

const useStyles = makeStyles((theme: any) =>
    createStyles({
        cardTitle: {
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            backgroundColor: theme.palette.site.secondary,
            padding: theme.spacing(0)
        },
        headerText: {
            float: 'left',
            padding: theme.spacing(1.5),
        },
        cardContent: {
            padding: theme.spacing(1, 3),
            height: '100%',
        },
        saveButton: {
            width: 90,
        },
        tableContainer: {
            maxHeight: '400px'
        },
        table: {
            width: '100%',
        },
        iconColor: {
            color: theme.palette.black
        },
        searchField: {
            '&::placeholder': {
                color: theme.palette.black
            }
        },
        selectionCheckBox: {
            padding: theme.spacing(0)
        },
        preLoaderCell: {
            textAlign: 'center',
            padding: theme.spacing(1)
        },
        dialogCloseButton: {
            float: "right",
        },
        courseSelectionCell: {
            color: theme.palette.paperSummarySchedulerTitle,
            cursor: 'pointer',
            fontWeight: 500
        }
    })
);
type CourseGradeBookProps = {
    courseId?: string;
    campusId?: string;
    repeatedExamPolicy?: string;
    showSnackBar: (param: CustomSnackBarProps) => void;
    setConfirmationDialog: (param: { onOk?: () => (void | Promise<void>); open: boolean; onCancel?: () => (void | Promise<void>); }) => void;
};

const CourseGradeBook = (
    props: Readonly<CourseGradeBookProps>
) => {
    const classes = useStyles({});
    const [searchText, setSearchText] = React.useState<string>('');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [grdBooks, setGrdBooks] = React.useState<ICourseGradeBook[]>([]);
    const [selectedGradeBook, selectGradeBook] = React.useState<ICourseGradeBook>({ gradeBookId: EmptyGuid });

    const toDisplay = searchText
        && searchText.length > 2
        ? grdBooks.filter((grdBk) => grdBk.gradeBookId === searchText
            || (grdBk?.gradeBookTitle ?? '').toLocaleLowerCase().includes(searchText.toLocaleLowerCase()))
        : grdBooks;

    const loadCourseGradeBook = async (resetSelected: boolean = false) => {
        if (!isLoading && props.courseId && props.courseId !== EmptyGuid) {
            setIsLoading(true);
            try {
                const gradeBooks = await getCourseGradeBooks(props.courseId ?? EmptyGuid);
                setGrdBooks(gradeBooks);
                if (resetSelected) {
                    selectGradeBook({ gradeBookId: EmptyGuid });
                }
            }
            catch (err) {
                props.showSnackBar({
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: (err as Error).message,
                });
            }
            setIsLoading(false);
        }
    }

    React.useEffect(() => {
        loadCourseGradeBook(true);
    }, [props.courseId]);

    return (
        <Card square={true} elevation={2} >
            <CustomCardTitle title='Grade Book'></CustomCardTitle>
            <CardContent>
                <Grid container direction="row" spacing={2} wrap="nowrap">
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container direction="column" spacing={2} wrap="nowrap">
                            <Grid
                                item
                                alignContent="flex-start"
                                alignItems="flex-start"
                                justify="flex-start"
                            >
                                <InputField
                                    value={searchText ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
                                    inputProps={{ className: classes.searchField }}
                                    InputProps={
                                        {
                                            startAdornment: <SearchIcon className={classes.iconColor} />,
                                            endAdornment: searchText && <IconButton onClick={() => setSearchText('')}><CloseIcon className={classes.iconColor} /></IconButton>
                                        }
                                    }
                                    placeholder={'Search Grade Book'} />
                            </Grid>
                            <Grid
                                item
                                alignContent="flex-start"
                                alignItems="flex-start"
                                justify="flex-start"
                            >
                                <TableContainer component={Paper} square className={classes.tableContainer}>
                                    <Table stickyHeader size='small' className={classes.table} aria-label='customized table'>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell colSpan={2} >Grade Book</StyledTableCell>
                                                <StyledTableCell>Effective Start Date</StyledTableCell>
                                                <StyledTableCell>Effective End Date</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {isLoading ?
                                                <StyledTableRow>
                                                    <StyledTableCell colSpan={4} className={classes.preLoaderCell}>
                                                        <CircularProgress />
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                                : toDisplay.map((grdBk, index) => (
                                                    <StyledTableRow key={`row-${index}`}>
                                                        <StyledTableCell colSpan={2} key={`gradeBookName-${index}`} className={classes.courseSelectionCell} onClick={() => selectGradeBook(grdBk)}>{grdBk.gradeBookTitle}</StyledTableCell>
                                                        <StyledTableCell key={`gradeBookEffectiveStartDate-${index}`}>{grdBk.effectiveStartDate && format(Date.parse(grdBk.effectiveStartDate.toString()), 'MM/dd/yyyy')}</StyledTableCell>
                                                        <StyledTableCell key={`gradeBookEffectiveEndDate-${index}`}>{index > 0 && toDisplay && toDisplay[index - 1] && toDisplay[index - 1].effectiveStartDate && format(addDays(Date.parse((toDisplay[index - 1])?.effectiveStartDate?.toString() ?? (new Date()).toString()), -1), 'MM/dd/yyyy')}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid
                                item
                                alignContent='flex-start'
                                alignItems='flex-start'
                                justify='flex-start'
                            >
                                <Button
                                    onClick={() => selectGradeBook({ gradeBookId: EmptyGuid })}
                                    size='small'
                                    color='primary'
                                    variant='contained'
                                    type='button'>ADD NEW</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="row" spacing={2} wrap="nowrap">
                    <Grid item xs={12} sm={12} md={12}>
                        <GradeBookDetails setConfirmationDialog={props.setConfirmationDialog}
                            refreshList={loadCourseGradeBook}
                            showSnackBar={props.showSnackBar}
                            model={selectedGradeBook}
                            campusId={props.campusId}
                            courseId={props.courseId}
                            repeatedExamPolicy={props.repeatedExamPolicy} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default CourseGradeBook;
