export enum FileConfigurationFeature {
    ///  Timeclock Archive ///
    TCA = 1,
    ///  Lead Import ///
    LI,
    ///  Student Docs ///
    DOC,
    ///  Photos ///
    PHOTO,
    ///  R2T4 ///
    R2T4,
    ///  Timeclock Import ///
    TCI
}