import React from "react";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import {
		Typography,
		Table,
		TableBody,
		TableCell,
		TableRow,
		TableHead,
		Paper,
		CardContent,
		TextField
	} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
		MuiPickersUtilsProvider,
		KeyboardDatePicker
	} from "@material-ui/pickers";
import * as SuspensionApi from "../../../api/student/status/SuspensionApi";
import { ISuspension } from "../../../interfaces/student/status/ISuspension";
import { Order } from "../../../interfaces/common/Table/CustomHeadCell";
import * as TableFunctions from "../../../utils/tableFunctions";
import { useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { ApiResultStatus } from "../../../enums/ApiResultStatus";
import { SystemEnrollmentStatus } from "../../../enums/SystemEnrollmentStatus"; 

type SuspensionTableProps = {
	setSnackBarProps: any;
	updateEvent: any;
	refreshFlag: boolean;
	statusChangeEnrollment: any;
};

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.background.paper,
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2),
			},
		},
		tableWrapper: {
			width: "100%",
		},
		table: {
			minWidth: "750",
		},
		gridHeadTypeXSmall: {
			width: "10%",
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
			backgroundColor: theme.palette.secondary.dark,
			minWidth: "60px",
		},
		gridCellTypeXSmall: {
			width: "10%",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0)
		},
		gridHeadTypeSmall: {
			width: "15%",
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
			backgroundColor: theme.palette.secondary.dark,
		},
		gridCellTypeSmall: {
			width: "15%",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0)
		},
		gridHeadTypeMedium: {
			width: "20%",
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
			backgroundColor: theme.palette.secondary.dark,
		},
		gridCellTypeMedium: {
			width: "20%",
			height: "46px", //match datepicker height on edit
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
		},
		gridHeadTypeLarge: {
			width: "25%",
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
			backgroundColor: theme.palette.secondary.dark,
		},
		gridCellTypearge: {
			width: "25%",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0)
		},
		gridHeadTypeRemainder: {
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
			backgroundColor: theme.palette.secondary.dark,
		},
		gridCellTypeRemainder: {
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
		  },
		icons: {
			fontSize: theme.spacing(2),
		},
		gridHeader: {
			backgroundColor: theme.palette.secondary.dark,
			width: "100%",
		},
		cardTitle: {
			fontSize: theme.spacing(2),
			color: theme.palette.black,
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1),
		  },
		  cardContent: {
			padding: theme.spacing(1, 1.5),
			maxHeight:"350px",
			overflowY: "auto",
		  },
		  datePicker: {
			"& .MuiIconButton-root": {
				padding: theme.spacing(0.8)
			}
		},
		textField: {
			marginRight: theme.spacing(1),
			width: "100%",
			fontSize: theme.spacing(1.5),
		  },

	})
);


const ThemeTextField = withStyles((theme: any) => createStyles({}))(TextField);

const SuspensionTable = (props:
	SuspensionTableProps) => {
    const classes = useStyles({});

	interface HeadCell {
		disablePadding: boolean;
		id: keyof ISuspension;
		label: string;
		numeric: boolean;
		widthType: "L" | "M" | "S" | "XS" | "R";
	}

	const headCells: HeadCell[] = [
		{
			id: "startDate",
			numeric: false,
			disablePadding: false,
			label: "Start Date",
			widthType: "M",
		},
		{
			id: "endDate",
			numeric: false,
			disablePadding: false,
			label: "End Date",
			widthType: "M",
		},
		{
			id: "reason",
			numeric: false,
			disablePadding: false,
			label: "Reason for Suspension",
			widthType: "R",
		},
		{
			id: "modifiedDate",
			numeric: true,
			disablePadding: false,
			label: "Modified Date",
			widthType: "S",
		},
		{
			id: "modifiedUser",
			numeric: false,
			disablePadding: false,
			label: "Modified By",
			widthType: "S",
		},
		{
			id: "studentSuspensionId",
			numeric: false,
			disablePadding: false,
			label: "",
			widthType: "XS",
		},
		];

	interface EnhancedTableProps {
		classes: ReturnType<typeof useStyles>;
	}

	function EnhancedTableHead(props: EnhancedTableProps) {
		const {
			classes,
		} = props;

		return (
			<TableHead >
				<TableRow className={classes.gridHeader}>
					{headCells.map(headCell => (
						<TableCell
							key={headCell.id}
							align="left"
							padding={headCell.disablePadding ? "none" : "default"}
							className={
								headCell.widthType == "S"
									? classes.gridHeadTypeSmall
									: headCell.widthType == "M"
									? classes.gridHeadTypeMedium
									: headCell.widthType == "L"
									? classes.gridHeadTypeLarge
									: headCell.widthType == "XS"
									? classes.gridHeadTypeXSmall
									: headCell.widthType == "R"
									? classes.gridHeadTypeRemainder
									: classes.gridHeadTypeRemainder
								}
							>
							{headCell.label}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
		);
	}

	const [suspensionData, setSuspensionData] = React.useState<Array<ISuspension>>([]);
	const [order, setOrder] = React.useState<Order>("desc");
	const [orderBy, setOrderBy] = React.useState<keyof ISuspension>("startDate");
	const [previousData, setPreviousData] = React.useState<Array<ISuspension>>([]);

	const selectedEnrollment = useSelector(
		(state: any) => state.student.selectedEnrollment
	);
	const [changeEnrollmentData, setChangeEnrollmentData] = React.useState<any>(props.statusChangeEnrollment);
	
	const checkStatusForBlock = (): boolean => {	
		return (changeEnrollmentData?.systemStatus == SystemEnrollmentStatus.Graduated 
					|| changeEnrollmentData?.systemStatus == SystemEnrollmentStatus.Dropped);
	};
	const [statusBlock, setStatusBlock] = React.useState<boolean>(checkStatusForBlock());

	const refreshGridData=()=>{
		if (selectedEnrollment && selectedEnrollment.stuEnrollId) {
			let studentEnrollmentId = selectedEnrollment.stuEnrollId;
			SuspensionApi.getSuspensionsByEnrollmentId(studentEnrollmentId).then(
				(response: Array<ISuspension>) => {
					if (response) {
						setSuspensionData(response);
					}
				},
				(exception: any) => {}
			);
		}
	}
	React.useEffect(() => {
		refreshGridData();
	}, [selectedEnrollment, props.refreshFlag]);

	const renderIcons = (row: ISuspension) => {
		let icons;

		{(row.isEditMode) ?
			icons = <span>
				<IconButton aria-label="submit" onClick = {() => {handleSubmitChanges(row);}} size="small">
					<CheckIcon className = {classes.icons} />
				</IconButton>
				<IconButton aria-label="cancel" onClick = {() => {handleCancelEditing(row);}} size="small">
					<ClearIcon className = {classes.icons} />
				</IconButton>
				</span>
		: (!statusBlock) ?
			icons =
				<IconButton aria-label="edit" onClick = {() => {handleStartEditing(row);}} size="small">
					<EditIcon className = {classes.icons} />
				</IconButton>
			: icons = "";
		};

		return icons;
	}

	const renderStartDate = (row: ISuspension) => {
		let startDate;

		{(row.isEditMode) ?
			startDate =
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<KeyboardDatePicker
					disableToolbar
					autoOk={true}
					variant="inline"
					format="MM/dd/yyyy"
					id={"startDate_" + row.studentSuspensionId}
					name={"startDate_" + row.studentSuspensionId}
					value={row.startDate ? new Date(row.startDate) : ""}
					onChange={(v: any) => {
					handleFieldOnChange(row, "startDate", v ? v : undefined);
					}}
					className={classes.datePicker}
					KeyboardButtonProps={{
					"aria-label": "start date",
					}}
				/>
		</MuiPickersUtilsProvider>
		: startDate = (row.startDate == undefined)? row.startDate :
			new Date(row.startDate).toLocaleDateString()
		};

		return startDate;
	}

	const renderEndDate = (row: ISuspension) => {
		let endDate;

		{(row.isEditMode) ?
			endDate =
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<KeyboardDatePicker
					disableToolbar
					autoOk={true}
					variant="inline"
					format="MM/dd/yyyy"
					id={"endDate_" + row.studentSuspensionId}
					name={"endDate_" + row.studentSuspensionId}
					value={row.endDate ? new Date(row.endDate) : ""}
					onChange={(v: any) => {
					handleFieldOnChange(row, "endDate", v ? v : undefined);
					}}
					className={classes.datePicker}
					KeyboardButtonProps={{
					"aria-label": "end date",
					}}
				/>
		</MuiPickersUtilsProvider>
		: endDate = (row.endDate == undefined)? row.endDate :
			new Date(row.endDate).toLocaleDateString()
		};

		return endDate;
	}

	const renderReason = (row: ISuspension) => {
		let reason;

		{(row.isEditMode) ?
			reason =
			<ThemeTextField
			id={"reason_" + row.studentSuspensionId}
			name={"reason_" + row.studentSuspensionId}
			className={classes.textField}
			value={row.reason}
			onChange={(e: any) => {
				handleFieldOnChange(row, "reason", e.target.value);				
			  }}
		  />
		: reason = row.reason
		};

		return reason;
	}

	const handleStartEditing = (selected: ISuspension) => {
		if (previousData != null && previousData != undefined && previousData.length > 0)
		{
			let found = previousData.filter((prev) => {return prev.studentSuspensionId === selected.studentSuspensionId;});
			if (found == null || found.length < 1)
			{
				previousData.push(selected);
			}
		}
		else{
			previousData.push(selected);
		}

		const rows = suspensionData.map(row => {
			if (row.studentSuspensionId === selected.studentSuspensionId) {
				return { ...row, isEditMode: true };
			}
			return row;
		})

		setSuspensionData(rows);
	};

	const handleFieldOnChange = (selected: ISuspension, fieldName: string, value: any) => {
		const rows =  TableFunctions.stableSort(
			suspensionData,
			TableFunctions.getSorting(order, orderBy) as any
			).map(row => {
				if (row.studentSuspensionId === selected.studentSuspensionId) {
					return { ...row, [fieldName]: value };
				}
				return row;
			})

		setSuspensionData(rows)
	}

	const handleCancelEditing = (selected: ISuspension) => {
		let rows: Array<ISuspension>;

		if (previousData != null && previousData != undefined && previousData.length > 0)
		{
			let found = previousData.find((prev) =>  prev.studentSuspensionId === selected.studentSuspensionId);
			if (found != null && found != undefined)
			{
				const foundRow = found;
				rows = suspensionData.map(row => {
					if (row.studentSuspensionId === selected.studentSuspensionId) {
						return { ...foundRow, isEditMode: false };
					}
					return row;
				})

				const newPrevious = previousData.filter((item) => item.studentSuspensionId !== selected.studentSuspensionId);
				setPreviousData(newPrevious);
			}
			else {
				rows = suspensionData.map(row => {
					if (row.studentSuspensionId === selected.studentSuspensionId) {
						return { ...row, isEditMode: false };
					}
					return row;
				})
			}
		}
		else{
			rows = suspensionData.map(row => {
				if (row.studentSuspensionId === selected.studentSuspensionId) {
					return { ...row, isEditMode: false };
				}
				return row;
			})
		}

		setSuspensionData(rows);
	};

	const handleSubmitChanges = (selected: ISuspension) => {
		if (validateRow(selected)) {

		SuspensionApi.updateSuspensionHistory(selected).then(
			(response: any) => {
				if (response.data != undefined && response.data.resultStatus != undefined)
				{
					switch (response.data.resultStatus) {
						case ApiResultStatus.Success:
							props.setSnackBarProps((props: any) => {
								return {
								variant: "success",
								showSnackBar: true,
								messageInfo: response.data.resultStatusMessage
								};
							});
							props.updateEvent();
							break;
						case ApiResultStatus.Warning:
							props.setSnackBarProps((props: any) => {
								return {
								variant: "warning",
								showSnackBar: true,
								messageInfo: response.data.resultStatusMessage
								};
							});
							break;
						case ApiResultStatus.Error:
							props.setSnackBarProps((props: any) => {
								return {
								variant: "error",
								showSnackBar: true,
								messageInfo: response.data.resultStatusMessage
								};
							});
							break;
						default:
					}
				}
				else {
					props.setSnackBarProps((props: any) => {
						return {
						variant: "error",
						showSnackBar: true,
						messageInfo: "Error updating suspension. "
						};
					});
				}
			},
			(exception: any) => {
				props.setSnackBarProps((snackBarProps: any) => {
				  return {
					variant: "error",
					showSnackBar: true,
					messageInfo: exception,
				  };
				});
			  }
		)}
	};

	const validateRow = (selected: ISuspension) => {
		return true;
	};


	return (

	<Paper className={classes.tableWrapper}>
		<Typography className={classes.cardTitle} >
			Suspension History
			</Typography>
			<CardContent className={classes.cardContent}>
		<Table stickyHeader
			className={classes.table}>
			<EnhancedTableHead classes={classes} />
			<TableBody >
			{TableFunctions.stableSort(
				suspensionData,
				TableFunctions.getSorting(order, orderBy) as any
				).map((row: ISuspension, index: any) => {
				return (
					<TableRow key={row.studentSuspensionId} >
						<TableCell  classes={{
								body: classes.gridCellTypeMedium}}>
							{renderStartDate(row)}
						</TableCell>
						<TableCell classes={{
								body: classes.gridCellTypeMedium}}>
							{renderEndDate(row)}
						</TableCell>
						<TableCell classes={{
								body: classes.gridCellTypeRemainder}}>
							{renderReason(row)}
						</TableCell>
						<TableCell classes={{
								 body: classes.gridCellTypeSmall}}>
							{new Date(row.modifiedDate).toLocaleDateString()}
						</TableCell>
						<TableCell classes={{
								body: classes.gridCellTypeSmall}}>
							{row.modifiedUser}
						</TableCell>
						<TableCell classes={{
								body: classes.gridCellTypeXSmall}}>
							{renderIcons(row)}
						</TableCell>
					</TableRow>
				);
			})}
			</TableBody>
		</Table>
		</CardContent>

	</Paper>

	);
};

export default SuspensionTable;
