import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { apiAutoCompleteConfig } from "../../constants/apiAutoComplete.config";
// import { ProgramsApiAutoComplete } from "../../constants/SystemCatalog/ProgramsAutoComplete";
import { TransferFromProgramApiAutoComplete } from "../../constants/SystemCatalog/TransferFromProgramAutoComplete";

import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../constants/actions.constants";
import { FilterParams } from "../../interfaces/common/FilterParams";

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& label.Mui-focused": {
        color: theme.palette.primary.white
      }
    }
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {}
  })
);

const TransferFromProgramAutoComplete = (props: FilterParams) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const [transferFromProgramId, setTransferFromProgramId] = React.useState(
    false
  );
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );
  const selectedEnrollment = useSelector(
    (state: any) => state.student.selectedEnrollment
  );
  const inputRef: any = useRef(null);

  const filterHandle = props.filterHandle;
  const programsOnChange = (e: any) => {
    if (filterHandle) filterHandle(e);
    setTransferFromProgramId(e);
  };
const [params, setParams] = useState<any>({});

useEffect(() => {
  if ( selectedEnrollment){
    let newParams = params;
    newParams.stuEnrollId = selectedEnrollment.stuEnrollId;
    setParams({...newParams})
  }
 
  
}, [selectedEnrollment])


  const [disabled, setIsDisabled] = React.useState<any | null>();
  React.useEffect(() => {
    setIsDisabled(props.disabled);
  }, [props.disabled]);
  return (
    <div>
      <ApiAutoComplete
        disabled={disabled}
        config={TransferFromProgramApiAutoComplete.config}
        loading={selectedEnrollment ? false : true}
        reqParams={params}
        classes={{
          option: "text-black"
        }}
        label="From Program"
        onChange={(e: any) => {
          programsOnChange(e);
        }}
        multiple={false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
      ></ApiAutoComplete>
    </div>
  );
};
export default TransferFromProgramAutoComplete;
