import DateFnsUtils from "@date-io/date-fns";
import {
	Button,
	createStyles,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Grid,
	IconButton,
	makeStyles,
	Paper,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useEffect, useRef, useState } from "react";
import { FieldError, FormContext, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as InstitutionalRefundApi from "../../../../src/api/student/studentAccounts/InstitutionalRefundApi";
import AcademicCalendarAutoComplete from "../../../components/AutoComplete/AcademicCalendarAutoComplete";
import EnrollmentTypeAutoComplete from "../../../components/AutoComplete/InstitutionalRefunds/EnrollmentTypeAutoComplete";
import EqualitySymbolAutoComplete from "../../../components/AutoComplete/InstitutionalRefunds/EqualitySymbolAutoComplete";
import HourTypesAutoComplete from "../../../components/AutoComplete/InstitutionalRefunds/HourTypesAutoComplete";
import RefundPeriodCalculationTypeAutoComplete from "../../../components/AutoComplete/InstitutionalRefunds/RefundPeriodCalculationTypeAutoComplete";
import RefundTypesAutoComplete from "../../../components/AutoComplete/InstitutionalRefunds/RefundTypesAutoComplete";
import TimePeriodsAutoComplete from "../../../components/AutoComplete/InstitutionalRefunds/TimePeriodsAutoComplete";
import WhenBothSpecifiedAutoComplete from "../../../components/AutoComplete/InstitutionalRefunds/WhenBothSpecifiedAutoComplete";
import WhenIsEnrollmentNoticeGivenAutoComplete from "../../../components/AutoComplete/InstitutionalRefunds/WhenIsEnrollmentNoticeGivenAutoComplete";
import RightToCancelNoticeGivenToStudentAutoComplete from "../../../components/AutoComplete/InstitutionalRefunds/RightToCancelNoticeGivenToStudentAutoComplete";
import PeriodTypeAutoComplete from "../../../components/AutoComplete/PeriodTypeAutoComplete";
import TransactionCodeAutoComplete from "../../../components/AutoComplete/TransactionCodeAutoComplete";
import CustomSnackbar from "../../../components/notifications/CustomSnackbar";
import AccessManager from "../../../components/security/AccessManager";
import ProgressSaveButton from "../../../components/_Layout/Buttons/ProgressSaveButton";
import InputField from "../../../components/_Layout/Inputs/InputField";
import {
	default as ConfirmationModal,
	default as GoBackConfirmationModal,
} from "../../../components/_Layout/Modal/ConfirmationModal";
import { security } from "../../../constants/Security/securityConstants";
import {
	AdornmentPosition,
	InputType,
} from "../../../constants/uiConstants/inputConstants";
import { TransactionCode } from "../../../enums/TransactionCode";
import { CustomSnackBarProps } from "../../../interfaces/common/CustomSnackBarProps";
import { PolicyRule } from "../../../interfaces/student/refunds/PolicyRule";
import { RefundPolicy } from "../../../interfaces/student/refunds/RefundPolicy";
import { RefundTransCodeCharges } from "../../../interfaces/student/refunds/RefundTransCodeCharges";
import { EmptyGuid } from "../../../utils/constants";
import { Guid } from "../../../utils/guid";
import PolicyRulesGrid from "./PolicyRulesGrid";
import RefundPolicyGrid from "./RefundPolicyGrid";
import { ProgramVersionMappingPopup } from "./ProgramVersionMappingPopup";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			padding: theme.spacing(2),
			width: "100%",
			minHeight: "100%",
			height: "95%",
			overflowX: "hidden",
			overflowY: "auto",
			flexDirection: "column",
			marginTop: theme.spacing(3),
			backgroundColor: theme.palette.paper.background,
		},
		heading: {
			fontSize: theme.typography.pxToRem(15),
			fontWeight: theme.typography.fontWeightRegular,
		},
		cardTitle: {
			fontSize: 24,
			color: theme.palette.black,
			textDecoration: "bold",
			backgroundColor: theme.palette.site.secondary,
		},
		backButton: {
			marginBottom: theme.spacing(2),
		},
		actionContainer: {
			marginBottom: theme.spacing(2),
			marginTop: theme.spacing(2),
		},
		titleMargin: {
			margin: theme.spacing(2),
		},
		fieldMargin: {
			marginTop: theme.spacing(1.5),
		},
		actionContainerButton: {
			marginRight: theme.spacing(2),
		},
		iconButton: {
			backgroundColor: theme.palette.site.primary,
			"&:hover": {
				backgroundColor: "gray",
			},
		},
		buttonIconColor: {
			color: theme.palette.white,
		},
		tableCell: {
			"&.MuiTableCell-root": {
				padding: theme.spacing(1),
			},
		},
		tableRoot: {
			border: "1px solid",
			margin: theme.spacing(2),
		},
	})
);

interface RefundTransCodeChargesModel extends RefundTransCodeCharges {
	clientId: string | null;
}

const RefundSetup = (props: any) => {
	const classes = useStyles({});
	const myRef = useRef<any>();
	const paperRef = useRef<any>();

	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const dateValidation = {
		minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 100)),
		maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 100)),
	};
	const [isDirty, setIsDirty] = useState<boolean>(false);

	const policyModelDefault: RefundPolicy = {
		campusId: userSelectedCampus,
		institutionalRefundPolicyId: null,
		description: null,
		periodTypeId: null,
		periodTypeDescription: null,
		effectiveFrom: new Date().toLocaleDateString(),
		effectiveTo: new Date(
			new Date().getTime() + 1000 * 60 * 60 * 24
		).toLocaleDateString(),
		academicCalendarId: null,
		academicCalendarDescription: null,
		unearnedChargesTransactionCodeId: null,
	};

	const policyRuleModelDefault: PolicyRule = {
		institutionalRefundPolicyRuleId: null,
		institutionalRefundPolicyId: null,
		rulePriority: null,
		enrollmentType: null,
		studentAcceptedBySchool: true,
		rightToCancelNoticeGivenToStudent: 1,
		whenIsEnrollmentNoticeGiven: null,
		beforeProgramStartOperator: null,
		beforeProgramStartDays: 0,
		hasStudentAttendedClass: true,
		afterStartProgramCompletionOperator: 0,
		afterStartProgramCompletionAmount: 0,
		afterStartProgramCompletionType: null,
		programCompletionCalculatedUsingType: null,
		institutionalRefundType: null,
		amountEarnedCalculationType: null,
		fixedCalculationAmount: 0,
		samePercentageForAllCharges: true,
		isProRatedForAllCharges: false,
		percentForAllCharges: null,
		conditionWhenBothSpecified: null,
		terminationFeeTransactionId: null,
		terminationFeeAmount: 0,
		separateAdjustmentsNoStart: false,
		institutionalRefundTransCodeCharges: [],
		nonRefundableTransCodeIds:null,
		doesSchoolRetainsAnyPercentOfUnearnedCharges:true,
	};

	const [policyModel, setPolicyModel] =
		useState<RefundPolicy>(policyModelDefault);
	const [policyRuleModel, setPolicyRuleModel] = useState<PolicyRule>(
		policyRuleModelDefault
	);

	const [openProgramVersionMappingPopUp, setOpenProgramVersionsPopup] = React.useState<boolean>(false);

	const refundTransCodeChargesDefault: RefundTransCodeChargesModel = {
		institutionalRefundTransCodeChargesId: EmptyGuid,
		percentage: null,
		institutionalRefundPolicyRuleId: EmptyGuid,
		transCodeId: null,
		clientId: null,
		isProRated: false,
		outOfPercentage:100
	};

	const [refundTransCodeChargesModel, setRefundTransCodeChargesModel] =
		useState<RefundTransCodeChargesModel>({
			...refundTransCodeChargesDefault,
		});

	const [refundTransCodeChargesArray, setRefundTransCodeChargesArray] =
		useState<RefundTransCodeChargesModel[]>([]);

	const [refundTransCodeChargesEditModel, setRefundCodeChargesEditModel] =
		useState<RefundTransCodeChargesModel>({
			...refundTransCodeChargesDefault,
		});

	const setRefundTransCodeChargesArrayOnRuleEdit = (
		array: RefundTransCodeChargesModel[]
	) => {
		let newArray: RefundTransCodeChargesModel[] = [];
		if (array !== null && array.length > 0) {
			newArray = array.map((item) => {
				item.clientId = Guid.create();
				return item;
			});
		}

		setRefundTransCodeChargesArray([...newArray]);
		setRefundTransCodeChargesModel({ ...refundTransCodeChargesDefault });
		setRefundCodeChargesEditModel({ ...refundTransCodeChargesDefault });
	};

	const [policyGridData, setPolicyGridData] = React.useState<RefundPolicy[]>(
		[]
	);

	const [policyRulesData, setPolicyRulesData] = React.useState<PolicyRule[]>(
		[]
	);

	const [loader, setLoader] = React.useState<boolean>(false);
	const [refreshPolicyGrid, setRefreshPolicyGrid] = React.useState<any>(null);
	const [refreshGrid, setRefreshGrid] = React.useState<any>(null);

	const [isEditingOrCreatingPolicy, setIsEditingOrCreatingPolicy] =
		React.useState<boolean>(false);

	const [isEditingOrCreatingPolicyRule, setIsEditingOrCreatingPolicyRule] =
		React.useState<boolean>(false);

	const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
		{
			showSnackBar: false,
			messageInfo: undefined,
			variant: "info",
		}
	);

	const [confirmationPopupState, setConfirmationPopupState] =
		React.useState<any>({
			id: "simple-popup",
			message: "",
		});

	const [goBackConfirmationPopupState, setGoBackConfirmationPopupState] =
		React.useState<any>({
			id: "simple-popup-goBack",
			message: "",
		});

	const methods = useForm<any>({
		mode: "onBlur",
	});

	const scrollToRef = (ref: any) => {
		if (paperRef && paperRef.current) {
			(paperRef.current as any).scrollTo({
				top: ref.current.offsetTop + 220,
				behavior: "smooth",
			});
		}
	};

	const handlePolicyChange = (fieldId: keyof RefundPolicy, value: any) => {
		let m = policyModel;
		(m as any)[fieldId] = value;
		methods.setValue(fieldId, value);
		setPolicyModel({ ...m });
	};

	const handlePolicyRuleChange = (fieldId: keyof PolicyRule, value: any) => {
		if (fieldId == "rulePriority") methods.clearError("rulePriority");
		let updatedModel = policyRuleModel;
		(updatedModel as any)[fieldId] = value;
		if (fieldId === "afterStartProgramCompletionType") {
			if (value !== 4) {
				updatedModel.isProRatedForAllCharges = false;
				setRefundTransCodeChargesArray(
					refundTransCodeChargesArray.map((m) => {
						methods.setValue(`isProRated-${m.clientId?.toString()}`, false);
						return { ...m, isProRated: false };
					})
				);
				setRefundCodeChargesEditModel({ ...refundTransCodeChargesDefault });
				methods.setValue("isProRatedForAllCharges", false);
			}

			updatedModel.programCompletionCalculatedUsingType = null; //reseting calculation using type on completion type change
			methods.setValue("programCompletionCalculatedUsingType", null);
		}
		setPolicyRuleModel({ ...updatedModel });
		methods.setValue(fieldId, value);
	};

	const handleNonRefundableTrancodeIdsChange = (selectedNonRefundableTransCodes:any[]) =>{
		let nonRefundableTransCodeIds:string|null = null;

		if(!!selectedNonRefundableTransCodes && selectedNonRefundableTransCodes.length > 0){
			selectedNonRefundableTransCodes.forEach(element => {
				if(!!nonRefundableTransCodeIds && nonRefundableTransCodeIds.length > 0){
					nonRefundableTransCodeIds = `${nonRefundableTransCodeIds},${element.value}`;
				}else{
					nonRefundableTransCodeIds = element.value;
				}
			});
		}

		const rule = {...policyRuleModel,nonRefundableTransCodeIds:nonRefundableTransCodeIds};
		setPolicyRuleModel(rule);
	}

	const handleTransCodeChargesChanges = (
		fieldId: keyof RefundTransCodeChargesModel,
		value: any
	) => {
		let updatedModel = refundTransCodeChargesModel;
		(updatedModel as any)[fieldId] = value;
		setRefundTransCodeChargesModel({ ...updatedModel });
		methods.setValue(fieldId, value);
	};

	const handleTransCodeChargesEditChanges = (
		modelKey: keyof RefundTransCodeChargesModel,
		value: any,
		fieldId: string,
		additionalField: string = ""
	) => {
		let updatedModel = refundTransCodeChargesEditModel;
		(updatedModel as any)[modelKey] = value;
		setRefundCodeChargesEditModel({ ...updatedModel });
		methods.setValue(fieldId, value);
	};

	const handleTransCodeChargesAdd = () => {
		let isValidated = true;
		methods.clearError(["transCodeId", "percentage"]);

		if (
			refundTransCodeChargesModel.transCodeId == null ||
			refundTransCodeChargesModel.transCodeId == EmptyGuid
		) {
			methods.setError("transCodeId", "required", "Required");
			setRefundTransCodeChargesModel({
				...refundTransCodeChargesModel,
				transCodeId: EmptyGuid,
			});
			isValidated = false;
		}

		if (
			!refundTransCodeChargesModel.isProRated &&
			refundTransCodeChargesModel.percentage === null
		) {
			methods.setError("percentage", "required", "Required");
			isValidated = false;
		}

		if (
			refundTransCodeChargesArray.some(
				(m) => m.transCodeId == refundTransCodeChargesModel.transCodeId
			)
		) {
			methods.setError("transCodeId", "required", "Already exists");
			isValidated = false;
		}

		if (isValidated) {
			let model = { ...refundTransCodeChargesModel, clientId: Guid.create() };
			setRefundTransCodeChargesArray([
				...refundTransCodeChargesArray,
				{ ...model },
			]);
			setRefundTransCodeChargesModel({ ...refundTransCodeChargesDefault });
			setRefundCodeChargesEditModel({ ...refundTransCodeChargesDefault });
		}
	};

	const handleTransCodeChargesUpdate = (index: any) => {
		let transCodeId: string = `transCode-${refundTransCodeChargesEditModel.clientId?.toString()}`;
		let percentageId: string = `percentage-${refundTransCodeChargesEditModel.clientId?.toString()}`;
		methods.clearError([transCodeId, percentageId]);
		let isValidated = true;

		if (
			refundTransCodeChargesEditModel.transCodeId == null ||
			refundTransCodeChargesEditModel.transCodeId == EmptyGuid
		) {
			methods.setError(transCodeId, "required", "Required");
			isValidated = false;
		}

		if (
			!refundTransCodeChargesEditModel.isProRated &&
			refundTransCodeChargesEditModel.percentage == null
		) {
			methods.setError(percentageId, "required", "Required");
			isValidated = false;
		}

		if (
			refundTransCodeChargesArray.some(
				(m) =>
					m.transCodeId == refundTransCodeChargesEditModel.transCodeId &&
					m.clientId !== refundTransCodeChargesEditModel.clientId
			)
		) {
			methods.setError(transCodeId, "duplicate", "Already exists");
			isValidated = false;
		}

		if (isValidated) {
			let updatedArray = [...refundTransCodeChargesArray];
			updatedArray[index] = { ...refundTransCodeChargesEditModel };
			setRefundTransCodeChargesArray([...updatedArray]);
			setRefundCodeChargesEditModel({ ...refundTransCodeChargesDefault });
		}
	};

	const handleTransCodeChargesEdit = (model: RefundTransCodeChargesModel) => {
		setRefundCodeChargesEditModel({ ...model });
	};

	const handleTransCodeChargesDelete = (guid: string | null) => {
		if (refundTransCodeChargesArray && guid) {
			let updatedArray = [...refundTransCodeChargesArray];
			let filteredArray = updatedArray.filter((m) => m.clientId !== guid);
			setRefundTransCodeChargesArray([...filteredArray]);
		}
	};

	const onSubmit = (data: any) => {
		let refundTransCodeChargesToPost: any[] = [];
		if (
			isPercentageSameForAllCharges === false &&
			!isFullRefund &&
			(isPartialByBoth || isPartialByPercentage)
		) {
			if (
				!refundTransCodeChargesArray ||
				refundTransCodeChargesArray.length <= 0
			) {
				methods.setError("transCodeId", "required", "Required");
				methods.setError("percentage", "required", "Required");

				if (refundTransCodeChargesModel.transCodeId == null) {
					setRefundTransCodeChargesModel({
						...refundTransCodeChargesModel,
						transCodeId: EmptyGuid,
						percentage: null,
					});
				}

				return;
			} else {
				refundTransCodeChargesToPost = refundTransCodeChargesArray;
			}
		} else {
			setRefundTransCodeChargesArray([]);
		}

		let validPolicyRule =
			policyRulesData.filter(
				(rule) =>
					rule.rulePriority == policyRuleModel.rulePriority &&
					rule.institutionalRefundPolicyRuleId !=
					policyRuleModel.institutionalRefundPolicyRuleId
			).length == 0;

		if (!validPolicyRule) {
			methods.setError(
				"rulePriority",
				"required",
				"Already exists. Try different. "
			);
			return;
		}
		methods.clearError("rulePriority");
		setLoader(true);

		if (policyModel.academicCalendarId == "All")
			policyModel.academicCalendarId = null;
		InstitutionalRefundApi.UpsertRefundPolicy(policyModel).then(
			(response: any) => {
				setIsDirty(false);
				if (response.resultStatus !== 0) {
					setLoader(false);
					setSnackBarProps({
						variant: "error",
						showSnackBar: true,
						messageInfo: response.result.resultStatusMessage,
					});
				}

				let policyRuleModelPostData: PolicyRule = {
					...policyRuleModel,
					institutionalRefundPolicyId:
						response.result.institutionalRefundPolicyId,
					institutionalRefundTransCodeCharges: [
						...refundTransCodeChargesToPost,
					],
				};

				//successfully saved policy record
				if (
					policyRuleModelPostData.institutionalRefundPolicyId &&
					isEditingOrCreatingPolicyRule
				) {
					InstitutionalRefundApi.UpsertRefundPolicyRule(
						policyRuleModelPostData
					).then(
						(policyResponse: any) => {
							if (policyResponse.resultStatus === 0) {
								handlePolicyChange(
									"institutionalRefundPolicyId",
									response.result.institutionalRefundPolicyId
								);

								handlePolicyRuleChange(
									"institutionalRefundPolicyRuleId",
									response.result.institutionalRefundPolicyRuleId
								);

								setSnackBarProps((props: any) => {
									return {
										variant: "success",
										showSnackBar: true,
										messageInfo: response.resultStatusMessage,
									};
								});

								setLoader(false);
							} else {
								setLoader(false);
								setSnackBarProps((props: any) => {
									return {
										variant: "error",
										showSnackBar: true,
										messageInfo: response.resultStatusMessage,
									};
								});
							}
							setRefreshGrid({ ...refreshGrid, refresh: true });
							setIsEditingOrCreatingPolicyRule(false);
						},
						(exception: any) => {
							setRefreshGrid({ ...refreshGrid, refresh: true });
							setIsEditingOrCreatingPolicyRule(false);
							setLoader(false);
							setSnackBarProps((snackBarProps: any) => {
								return {
									variant: "error",
									showSnackBar: true,
									messageInfo: exception,
								};
							});
						}
					);
				} else {
					handlePolicyChange(
						"institutionalRefundPolicyId",
						response.result.institutionalRefundPolicyId
					);
					setLoader(false);
					setSnackBarProps((props: any) => {
						return {
							variant: "success",
							showSnackBar: true,
							messageInfo: response.resultStatusMessage,
						};
					});
				}
			},
			(exception: any) => {
				setLoader(false);
				setSnackBarProps((snackBarProps: any) => {
					return {
						variant: "error",
						showSnackBar: true,
						messageInfo: exception,
					};
				});
			}
		);
	};

	React.useEffect(() => {
		if (isEditingOrCreatingPolicyRule) {
			scrollToRef(myRef);
		}
	}, [isEditingOrCreatingPolicyRule]);

	const isFullRefund: boolean | null =
		policyRuleModel.institutionalRefundType === 1;
	const isPartialRefund: boolean | null =
		policyRuleModel.institutionalRefundType === 2;
	const isPartialByPercentage: boolean | null =
		policyRuleModel.amountEarnedCalculationType == 1;
	const isPartialByFixed: boolean | null =
		policyRuleModel.amountEarnedCalculationType === 2;
	const isPartialByBoth: boolean | null =
		policyRuleModel.amountEarnedCalculationType == 3;
	const isPercentageSameForAllCharges: boolean | null =
		policyRuleModel.samePercentageForAllCharges;

	useEffect(() => {
		methods.triggerValidation(["effectiveFrom", "effectiveTo"]);
	}, [policyModel]);
	useEffect(() => {
		methods.setValue("effectiveFrom", policyModel.effectiveFrom);
		methods.setValue("effectiveTo", policyModel.effectiveTo);
		methods.triggerValidation(["effectiveFrom", "effectiveTo"]);
	}, [policyModel.effectiveFrom, policyModel.effectiveTo]);

	return (
		<Paper className={classes.root} square={true} ref={paperRef}>
			{!isEditingOrCreatingPolicy && (
				<React.Fragment>
					<RefundPolicyGrid
						onEditClick={(row: RefundPolicy) => {
							methods.clearError();
							setPolicyModel({ ...row });
							setIsEditingOrCreatingPolicy(true);
						}}
						refreshGrid={refreshPolicyGrid}
						onDeleteClick={(row: RefundPolicy) => {
							setConfirmationPopupState((state: any) => {
								return {
									...state,
									open: true,
									confirmationText:
										"Are you sure you want to delete this policy?",
									confirmationButton: "Yes",
									title: "Delete policy - " + row.description,
									onConfirmEvent: () => {
										InstitutionalRefundApi.DeleteRefundPolicy(
											row.institutionalRefundPolicyId as any
										).then(
											(response: any) => {
												setSnackBarProps({
													variant:
														response.resultStatus === 0 ? "success" : "error",
													showSnackBar: true,
													messageInfo: response.data
														? response.data.resultStatusMessage
														: response.resultStatusMessage,
												});
												setRefreshPolicyGrid({ ...refreshGrid, refresh: true });
											},
											(exception: any) => {
												setSnackBarProps((snackBarProps: any) => {
													return {
														variant: "error",
														showSnackBar: true,
														messageInfo: exception,
													};
												});
											}
										);
										setConfirmationPopupState((updatedState: any) => {
											return { ...updatedState, open: false };
										});
									},
									onCancelEvent: () => {
										setConfirmationPopupState((updatedState: any) => {
											return { ...updatedState, open: false };
										});
									},
								};
							});
						}}
						setPolicyData={setPolicyGridData}
						policyData={policyGridData}
						setSnackBar={setSnackBarProps}
						setConfirmationModal={props.setConfirmationModal}
					></RefundPolicyGrid>
					<Grid container direction="row" spacing={2}>
						<Grid item>
							<div className={classes.actionContainer}>
								<AccessManager
									allowedPermissions={[
										security.permissions.studentAccounts.updateRefundPolicies,
										security.permissions.studentAccounts.deleteRefundPolicies,
									]}
									renderNoAccess={() => (
										<ProgressSaveButton
											text="Add New +"
											onClick={() => {
												methods.clearError(["effectiveFrom", "effectiveTo"]);
												setPolicyModel(policyModelDefault);
												setPolicyRuleModel(policyRuleModelDefault);
												setIsEditingOrCreatingPolicy(true);
											}}
											disabled={true}
											loading={false}
										></ProgressSaveButton>
									)}
								>
									<ProgressSaveButton
										text="Add New +"
										onClick={() => {
											setPolicyModel(policyModelDefault);
											setPolicyRuleModel(policyRuleModelDefault);
											setIsEditingOrCreatingPolicy(true);
										}}
										loading={false}
									></ProgressSaveButton>
								</AccessManager>
							</div>
						</Grid>
					</Grid>
				</React.Fragment>
			)}
			{isEditingOrCreatingPolicy && (
				<React.Fragment>
					<ProgramVersionMappingPopup open={openProgramVersionMappingPopUp} setOpen={setOpenProgramVersionsPopup} policyId={policyModel.institutionalRefundPolicyId} policyTitle={policyModel.description} />
					<FormContext {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							<Grid item>
								<Button
									className={classes.backButton}
									color="secondary"
									variant="contained"
									onClick={() => {
										if (!isDirty) {
											setIsEditingOrCreatingPolicyRule(false);
											setIsEditingOrCreatingPolicy(false);
										} else {
											setGoBackConfirmationPopupState((state: any) => {
												return {
													...state,
													open: true,
													confirmationText:
														"The changes to the current policy have not been saved. Are you sure you want to navigate away from the Refund Set Up page?",
													confirmationButton: "Yes",
													cancelText: "No",
													title: "Discard changes",
													onConfirmEvent: () => {
														setIsDirty(false);
														setGoBackConfirmationPopupState(
															(updatedState: any) => {
																return { ...updatedState, open: false };
															}
														);
														setIsEditingOrCreatingPolicyRule(false);
														setIsEditingOrCreatingPolicy(false);
													},
													onCancelEvent: () => {
														setGoBackConfirmationPopupState(
															(updatedState: any) => {
																return { ...updatedState, open: false };
															}
														);
													},
												};
											});
										}
									}}
								>
									<ArrowBackIcon /> Back
								</Button>
							</Grid>

							<ExpansionPanel defaultExpanded={true}>
								<ExpansionPanelSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header"
									className={classes.cardTitle}
								>
									<Typography>Policy details</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<Grid container direction="row" spacing={2}>
										<Grid item md={3} sm={4} xs={12}>
											<InputField
												id="description"
												name="description"
												label="Description *"
												defaultValue={
													policyModel.description
														? policyModel.description
														: undefined
												}
												error={!!methods.errors.description}
												inputRef={methods.register({
													required: true,
												})}
												helperText={
													methods.errors.description
														? "Description is required"
														: null
												}
												onBlur={(e: any) => {
													setIsDirty(true);
													handlePolicyChange(
														"description",
														e ? e.target.value : undefined
													);
												}}
												type={InputType.TEXT}
											/>
										</Grid>
										<Grid item md={3} sm={4} xs={12}>
											<PeriodTypeAutoComplete
												id="periodTypeId"
												name="periodTypeId"
												label="Period Type *"
												useSelectedValue={false}
												valueFilter={
													policyModel.periodTypeId !== null
														? {
															key: "value",
															values: [policyModel.periodTypeId],
														}
														: undefined
												}
												error={!!methods.errors.periodTypeId}
												inputRef={methods.register({
													required: true,
												})}
												helperText={
													methods.errors.periodTypeId
														? "Period type is required"
														: null
												}
												filterHandle={(v: any) => {
													if (
														policyModel.periodTypeId != null &&
														policyModel.periodTypeId != v.value
													)
														setIsDirty(true);
													handlePolicyChange(
														"periodTypeId",
														v ? v.value : undefined
													);
												}}
											/>
										</Grid>
										<Grid item md={3} sm={4} xs={12}>
											<TransactionCodeAutoComplete
												id="unearnedChargesTransactionCodeId"
												name="unearnedChargesTransactionCodeId"
												hideLabel={false}
												label={"Transaction code *"}
												filterHandle={(e: any) => {
													if (
														policyModel.unearnedChargesTransactionCodeId !=
														null &&
														policyModel.unearnedChargesTransactionCodeId !=
														e.value
													)
														setIsDirty(true);

													handlePolicyChange(
														"unearnedChargesTransactionCodeId",
														e ? e.value : null
													);
												}}
												error={
													!!methods.errors.unearnedChargesTransactionCodeId
												}
												inputRef={methods.register({
													required: true,
												})}
												helperText={
													methods.errors.unearnedChargesTransactionCodeId
														? "Transaction code is required"
														: null
												}
												valueFilter={
													policyModel.unearnedChargesTransactionCodeId
														? {
															key: "value",
															values: [
																policyModel.unearnedChargesTransactionCodeId,
															],
														}
														: undefined
												}
											/>
										</Grid>
										<Grid item md={3} sm={4} xs={12}></Grid>
										<Grid item md={3} sm={4} xs={12}>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<KeyboardDatePicker
													disableToolbar
													variant="inline"
													format="MM/dd/yyyy"
													id="effectiveFrom"
													name="effectiveFrom"
													label="Effective from *"
													TextFieldComponent={TextField}
													fullWidth
													autoOk={true}
													autoFocus={true}
													KeyboardButtonProps={{
														"aria-label": "change date",
													}}
													value={
														policyModel.effectiveFrom
															? new Date(
																policyModel.effectiveFrom
															).toLocaleDateString()
															: null
													}
													error={!!methods.errors.effectiveFrom}
													inputRef={methods.register({
														required: true,
													})}
													// innerRef={effectiveFromInput}
													innerRef={(ref: any) => {
														methods.register("effectiveFrom", {
															required: true,
															validate: {
																beforeEndDate: (value) =>
																	!value ||
																	new Date(value) <
																	new Date(
																		methods.getValues()["effectiveTo"]
																	) ||
																	"Date must be before To date",
															},
														});
														methods.setValue(
															"effectiveFrom",
															policyModel.effectiveFrom
														);
													}}
													helperText={
														methods.errors.effectiveFrom
															? methods.errors.effectiveFrom.message
															: null
													}
													onChange={(val: any) => {
														if (
															val &&
															policyModel.effectiveFrom != null &&
															policyModel.effectiveFrom != val.toLocaleString()
														)
															setIsDirty(true);

														if (
															!val ||
															val.toLocaleString() == "Invalid Date" ||
															val < dateValidation.minDate ||
															val > dateValidation.maxDate
														)
															methods.setError(
																"effectiveFrom",
																"required",
																"Invalid Date"
															);
														else {
															methods.clearError("effectiveFrom");
															handlePolicyChange(
																"effectiveFrom",
																val?.toLocaleDateString()
															);
															methods.triggerValidation("effectiveFrom");
														}
													}}
												/>
											</MuiPickersUtilsProvider>
										</Grid>
										<Grid item md={3} sm={4} xs={12}>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<KeyboardDatePicker
													disableToolbar
													variant="inline"
													format="MM/dd/yyyy"
													id="effectiveTo"
													name="effectiveTo"
													label="Effective to *"
													TextFieldComponent={TextField}
													fullWidth
													// innerRef={effectiveToInput}
													innerRef={(ref: any) => {
														methods.register("effectiveTo", {
															required: true,
															validate: {
																beforeEndDate: (value) =>
																	!value ||
																	new Date(value) >
																	new Date(
																		methods.getValues()["effectiveFrom"]
																	) ||
																	"Date must be after From date",
															},
														});
														methods.setValue(
															"effectiveTo",
															policyModel.effectiveTo
														);
													}}
													autoOk={true}
													KeyboardButtonProps={{
														"aria-label": "change date",
													}}
													value={
														policyModel.effectiveTo
															? new Date(
																policyModel.effectiveTo
															).toLocaleDateString()
															: null
													}
													error={!!methods.errors.effectiveTo}
													inputRef={methods.register({
														required: true,
													})}
													helperText={
														methods.errors.effectiveTo
															? methods.errors.effectiveTo.message
															: null
													}
													onChange={(val: any) => {
														if (
															val &&
															policyModel.effectiveTo != null &&
															policyModel.effectiveTo != val.toLocaleString()
														)
															setIsDirty(true);

														if (
															!val ||
															val.toLocaleString() == "Invalid Date" ||
															val < dateValidation.minDate ||
															val > dateValidation.maxDate
														)
															methods.setError(
																"effectiveTo",
																"required",
																"Invalid Date"
															);
														else {
															methods.clearError("effectiveTo");
															handlePolicyChange(
																"effectiveTo",
																val?.toLocaleDateString()
															);
															methods.triggerValidation("effectiveTo");
														}
													}}
												/>
											</MuiPickersUtilsProvider>
										</Grid>
										<Grid item md={3} sm={4} xs={12}>
											<AcademicCalendarAutoComplete
												id="academicCalendarId"
												name="academicCalendarId"
												label="Academic Calendar *"
												includeAllValue={true}
												valueFilter={
													policyModel.academicCalendarId
														? {
															key: "value",
															values: [policyModel.academicCalendarId],
														}
														: undefined
												}
												error={!!methods.errors.academicCalendarId}
												inputRef={methods.register({
													required: true,
												})}
												helperText={
													methods.errors.academicCalendarId
														? "Academic calendar is required"
														: null
												}
												filterHandle={(v: any) => {
													if (
														policyModel.academicCalendarId != null &&
														policyModel.academicCalendarId != v.value
													)
														setIsDirty(true);

													handlePolicyChange(
														"academicCalendarId",
														v ? v.value : null
													);
												}}
											/>
										</Grid>
									</Grid>
								</ExpansionPanelDetails>
							</ExpansionPanel>

							<ExpansionPanel defaultExpanded={true}>
								<ExpansionPanelSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel2a-content"
									id="panel2a-header"
									className={classes.cardTitle}
								>
									<Typography className={classes.heading}>
										Refund rules for cancellation/withdrawal
									</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<Grid container direction="row" spacing={2}>
										{!isEditingOrCreatingPolicyRule && (
											<Grid item>
												<div className={classes.actionContainer}>
													<AccessManager
														allowedPermissions={[
															security.permissions.studentAccounts
																.updateRefundPolicies,
															security.permissions.studentAccounts
																.deleteRefundPolicies,
														]}
														renderNoAccess={() => (
															<ProgressSaveButton
																text="Add Rule +"
																onClick={() => { }}
																disabled={true}
																loading={false}
															></ProgressSaveButton>
														)}
													>
														<ProgressSaveButton
															text="Add Rule +"
															onClick={() => {
																setPolicyRuleModel(policyRuleModelDefault);
																setRefundTransCodeChargesArray([]);
																setRefundTransCodeChargesModel({
																	...refundTransCodeChargesDefault,
																});
																setRefundCodeChargesEditModel({
																	...refundTransCodeChargesDefault,
																});
																setIsEditingOrCreatingPolicyRule(true);
															}}
															loading={false}
														></ProgressSaveButton>
													</AccessManager>
												</div>
											</Grid>
										)}
										<Grid item xs={12}>
											<PolicyRulesGrid
												policyId={policyModel.institutionalRefundPolicyId}
												onEditClick={(row: any) => {
													methods.clearError();
													setRefundTransCodeChargesArrayOnRuleEdit(
														row.institutionalRefundTransCodeCharges
													);
													// if (isEditingOrCreatingPolicy) {
													// 	scrollToRef(myRef);
													// }
													setIsEditingOrCreatingPolicyRule(true);
													setPolicyRuleModel(row);
												}}
												onDeleteClick={(row: PolicyRule) => {
													setConfirmationPopupState((state: any) => {
														return {
															...state,
															open: true,
															confirmationText:
																"Are you sure you want to delete this rule?",
															confirmationButton: "Yes",
															title: "Delete policy rule",
															onConfirmEvent: () => {
																InstitutionalRefundApi.DeleteRefundPolicyRule(
																	row.institutionalRefundPolicyRuleId as any
																).then(
																	(response: any) => {
																		setSnackBarProps({
																			variant:
																				response.resultStatus === 0
																					? "success"
																					: "error",
																			showSnackBar: true,
																			messageInfo: response.resultStatusMessage,
																		});
																		setRefreshGrid({
																			...refreshGrid,
																			refresh: true,
																		});
																	},
																	(exception: any) => {
																		setSnackBarProps((snackBarProps: any) => {
																			return {
																				variant: "error",
																				showSnackBar: true,
																				messageInfo: exception,
																			};
																		});
																	}
																);
																setConfirmationPopupState(
																	(updatedState: any) => {
																		return { ...updatedState, open: false };
																	}
																);
															},
															onCancelEvent: () => {
																setConfirmationPopupState(
																	(updatedState: any) => {
																		return { ...updatedState, open: false };
																	}
																);
															},
														};
													});
												}}
												setPolicyRulesData={setPolicyRulesData}
												policyRulesData={policyRulesData}
												setSnackBar={setSnackBarProps}
												setConfirmationModal={props.setConfirmationModal}
												refreshGrid={refreshGrid}
											></PolicyRulesGrid>
										</Grid>
										{isEditingOrCreatingPolicyRule && (
											<Grid item xs={12} ref={myRef}>
												<ExpansionPanel defaultExpanded={true}>
													<ExpansionPanelSummary
														expandIcon={<ExpandMoreIcon />}
														aria-controls="panel3a-content"
														id="panel3a-header"
														className={classes.cardTitle}
													>
														<Typography className={classes.heading}>
															STEP 1: Specify the conditions on which refund
															calculation depends for this rule
														</Typography>
													</ExpansionPanelSummary>
													<ExpansionPanelDetails>
														<Grid container direction="row" spacing={2}>
															<Grid item md={1} sm={6} xs={12}>
																<Typography className={classes.titleMargin}>
																	Rule Priority
																</Typography>
															</Grid>
															<Grid item md={1} sm={6} xs={12}>
																<InputField
																	id="rulePriority"
																	name="rulePriority"
																	type={InputType.NUMBER}
																	decimal={false}
																	decimalPlaces={0}
																	className={classes.fieldMargin}
																	defaultValue={policyRuleModel.rulePriority}
																	error={!!methods.errors["rulePriority"]}
																	inputRef={methods.register({
																		required: "Rule priority is required",
																	})}
																	helperText={
																		methods.errors["rulePriority"]
																			? methods.errors["rulePriority"].message
																			: null
																	}
																	onBlur={(e: any) => {
																		handlePolicyRuleChange(
																			"rulePriority",
																			e.target.value
																		);
																	}}
																/>
															</Grid>

															{policyModel.periodTypeId == 0 && <><Grid item md={2} sm={6} xs={12}>
																<Typography className={classes.titleMargin}>
																Don't include transfer hours in calculation
																</Typography>
															</Grid>
															<Grid item md={1} sm={6} xs={12}>
																<Switch
																	id="doNotIncludeTransferHoursInCalculations"
																	name="doNotIncludeTransferHoursInCalculations"
																	className={classes.fieldMargin}
																	checked={
																		policyRuleModel.doNotIncludeTransferHoursInCalculations
																			? true
																			: false
																	}
																	inputRef={methods.register({})}
																	onChange={(e: any) => {
																		handlePolicyRuleChange(
																			"doNotIncludeTransferHoursInCalculations",
																			e ? e.target.checked : null
																		);
																	}}
																	color="primary"
																/>
															</Grid></>}

															<Grid item md={1} sm={6} xs={12}>
																<Typography className={classes.titleMargin}>
																	Enrollment type
																</Typography>
															</Grid>
															<Grid item md={2} sm={6} xs={12}>
																<EnrollmentTypeAutoComplete
																	id="enrollmentType"
																	name="enrollmentType"
																	label=""
																	className={classes.fieldMargin}
																	valueFilter={
																		policyRuleModel.enrollmentType
																			? {
																				key: "value",
																				values: [
																					policyRuleModel.enrollmentType,
																				],
																			}
																			: undefined
																	}
																	error={!!methods.errors.enrollmentType}
																	inputRef={methods.register({
																		required: true,
																	})}
																	helperText={
																		methods.errors.enrollmentType
																			? "Enrollment type is required"
																			: null
																	}
																	filterHandle={(v: any) => {
																		handlePolicyRuleChange(
																			"enrollmentType",
																			v ? v.value : null
																		);
																	}}
																/>
															</Grid>
															<Grid item md={2} sm={6} xs={12}>
																<Typography className={classes.titleMargin}>
																	Student accepted by school
																</Typography>
															</Grid>
															<Grid item md={1} sm={6} xs={12}>
																<Switch
																	id="studentAcceptedBySchool"
																	name="studentAcceptedBySchool"
																	className={classes.fieldMargin}
																	checked={
																		policyRuleModel.studentAcceptedBySchool
																			? true
																			: false
																	}
																	inputRef={methods.register({})}
																	onChange={(e: any) => {
																		handlePolicyRuleChange(
																			"studentAcceptedBySchool",
																			e ? e.target.checked : null
																		);
																	}}
																	color="primary"
																/>
															</Grid>
															<Grid item md={2} sm={6} xs={12}>
																<Typography className={classes.titleMargin}>
																	Right to cancel notice given to student *
																</Typography>
															</Grid>
															<Grid item md={2} sm={6} xs={12}>
																<RightToCancelNoticeGivenToStudentAutoComplete
																	id="rightToCancelNoticeGivenToStudent"
																	name="rightToCancelNoticeGivenToStudent"
																	label=""
																	className={classes.fieldMargin}
																	error={
																		!!methods.errors
																			.rightToCancelNoticeGivenToStudent
																	}
																	inputRef={methods.register({
																		required: true,
																	})}
																	helperText={
																		methods.errors
																			.rightToCancelNoticeGivenToStudent
																			? "Required"
																			: null
																	}
																	valueFilter={
																		policyRuleModel.rightToCancelNoticeGivenToStudent
																			? {
																				key: "value",
																				values: [
																					policyRuleModel.rightToCancelNoticeGivenToStudent,
																				],
																			}
																			: undefined
																	}
																	filterHandle={(v: any) => {
																		handlePolicyRuleChange(
																			"rightToCancelNoticeGivenToStudent",
																			v ? v.value : null
																		);
																	}}
																/>
															</Grid>
															<Grid item md={3} sm={6} xs={12}>
																<Typography className={classes.titleMargin}>
																	When is withdrawal/cancellation of enrollment
																	notice given
																</Typography>
															</Grid>
															<Grid item md={3} sm={6} xs={12}>
																<WhenIsEnrollmentNoticeGivenAutoComplete
																	id="whenIsEnrollmentNoticeGiven"
																	name="whenIsEnrollmentNoticeGiven"
																	label=""
																	className={classes.fieldMargin}
																	error={
																		!!methods.errors.whenIsEnrollmentNoticeGiven
																	}
																	inputRef={methods.register({
																		required: true,
																	})}
																	helperText={
																		methods.errors.whenIsEnrollmentNoticeGiven
																			? "Required"
																			: null
																	}
																	valueFilter={
																		policyRuleModel.whenIsEnrollmentNoticeGiven
																			? {
																				key: "value",
																				values: [
																					policyRuleModel.whenIsEnrollmentNoticeGiven,
																				],
																			}
																			: undefined
																	}
																	filterHandle={(v: any) => {
																		handlePolicyRuleChange(
																			"whenIsEnrollmentNoticeGiven",
																			v ? v.value : null
																		);
																	}}
																/>
															</Grid>

															{(policyRuleModel.whenIsEnrollmentNoticeGiven ===
																1 ||
																policyRuleModel.whenIsEnrollmentNoticeGiven ===
																2) && (
																	<React.Fragment>
																		<Grid item md={3} sm={6} xs={12}>
																			<Typography className={classes.titleMargin}>
																				Days after signing enrollment agreement
																			</Typography>
																		</Grid>
																		<Grid item md={1} sm={6} xs={12}>
																			<EqualitySymbolAutoComplete
																				id="beforeProgramStartOperator"
																				name="beforeProgramStartOperator"
																				label=""
																				className={classes.fieldMargin}
																				error={
																					!!methods.errors
																						.beforeProgramStartOperator
																				}
																				inputRef={methods.register({
																					validate: {
																						requiredIfBeforeStart: (
																							value: any
																						) => {
																							return (policyRuleModel.whenIsEnrollmentNoticeGiven ===
																								1 ||
																								policyRuleModel.whenIsEnrollmentNoticeGiven ===
																								2) &&
																								!value
																								? "Required."
																								: undefined;
																						},
																					},
																				})}
																				helperText={
																					methods.errors
																						.beforeProgramStartOperator
																						? (
																							methods.errors
																								.beforeProgramStartOperator as FieldError
																						).message
																						: undefined
																				}
																				valueFilter={
																					policyRuleModel.beforeProgramStartOperator
																						? {
																							key: "value",
																							values: [
																								policyRuleModel.beforeProgramStartOperator,
																							],
																						}
																						: undefined
																				}
																				filterHandle={(v: any) => {
																					handlePolicyRuleChange(
																						"beforeProgramStartOperator",
																						v ? v.value : null
																					);

																					if (!v || v.value == 5) {
																						//5 is not applicable
																						handlePolicyRuleChange(
																							"beforeProgramStartDays",
																							null
																						);
																					}
																				}}
																			/>
																		</Grid>
																		<Grid item md={2} sm={6} xs={12}>
																			<InputField
																				id="beforeProgramStartDays"
																				name="beforeProgramStartDays"
																				type={InputType.NUMBER}
																				decimal={true}
																				decimalPlaces={2}
																				className={classes.fieldMargin}
																				defaultValue={
																					policyRuleModel.beforeProgramStartDays
																				}
																				error={
																					!!methods.errors.beforeProgramStartDays
																				}
																				disabled={
																					policyRuleModel.beforeProgramStartOperator ==
																					null ||
																					policyRuleModel.beforeProgramStartOperator ==
																					"5"
																				} //5 is not applicable
																				inputRef={methods.register({
																					validate: {
																						requiredIfBeforeStart: (
																							value: any
																						) => {
																							return (policyRuleModel.whenIsEnrollmentNoticeGiven ===
																								1 ||
																								policyRuleModel.whenIsEnrollmentNoticeGiven ===
																								2) &&
																								policyRuleModel.beforeProgramStartOperator !=
																								"5" &&
																								!value
																								? "Required."
																								: undefined;
																						},
																					},
																				})}
																				helperText={
																					methods.errors.beforeProgramStartDays
																						? (
																							methods.errors
																								.beforeProgramStartDays as FieldError
																						).message
																						: undefined
																				}
																				onBlur={(e: any) => {
																					handlePolicyRuleChange(
																						"beforeProgramStartDays",
																						e.target.value
																					);
																				}}
																			/>
																		</Grid>
																	</React.Fragment>
																)}

															{policyRuleModel.whenIsEnrollmentNoticeGiven ===
																2 && (
																	<React.Fragment>
																		<Grid item md={2} sm={6} xs={12}>
																			<Typography className={classes.titleMargin}>
																				If notice given after program start, then
																			</Typography>
																		</Grid>
																		<Grid item md={2} sm={6} xs={12}>
																			<Typography className={classes.titleMargin}>
																				When notice is given after program start
																				has student attended a class?
																			</Typography>
																		</Grid>
																		<Grid item md={1} sm={6} xs={12}>
																			<Switch
																				id="hasStudentAttendedClass"
																				name="hasStudentAttendedClass"
																				className={classes.fieldMargin}
																				checked={
																					policyRuleModel.hasStudentAttendedClass
																						? true
																						: false
																				}
																				inputRef={methods.register({})}
																				onChange={(e: any) => {
																					handlePolicyRuleChange(
																						"hasStudentAttendedClass",
																						e ? e.target.checked : null
																					);
																				}}
																				color="primary"
																			/>
																		</Grid>
																		{policyRuleModel.hasStudentAttendedClass && (
																			<React.Fragment>
																				<Grid item md={3} sm={6} xs={12}>
																					<Typography
																						className={classes.titleMargin}
																					>
																						Period completion till day of cancellation/withdrawal notice since class start
																					</Typography>
																				</Grid>
																				<Grid item md={1} sm={6} xs={12}>
																					<EqualitySymbolAutoComplete
																						id="afterStartProgramCompletionOperator"
																						name="afterStartProgramCompletionOperator"
																						label=""
																						className={classes.fieldMargin}
																						error={
																							!!methods.errors
																								.afterStartProgramCompletionOperator
																						}
																						inputRef={methods.register({
																							validate: {
																								requiredIfAfterStart: (
																									value: any
																								) => {
																									return policyRuleModel.whenIsEnrollmentNoticeGiven ===
																										2 && !value
																										? "Required."
																										: undefined;
																								},
																							},
																						})}
																						helperText={
																							methods.errors
																								.afterStartProgramCompletionOperator
																								? (
																									methods.errors
																										.afterStartProgramCompletionOperator as FieldError
																								).message
																								: undefined
																						}
																						valueFilter={
																							policyRuleModel.afterStartProgramCompletionOperator
																								? {
																									key: "value",
																									values: [
																										policyRuleModel.afterStartProgramCompletionOperator,
																									],
																								}
																								: undefined
																						}
																						filterHandle={(v: any) => {
																							handlePolicyRuleChange(
																								"afterStartProgramCompletionOperator",
																								v ? v.value : null
																							);
																						}}
																					/>
																				</Grid>
																				<Grid item md={1} sm={6} xs={12}>
																					<InputField
																						id="afterStartProgramCompletionAmount"
																						name="afterStartProgramCompletionAmount"
																						type={InputType.NUMBER}
																						decimal={true}
																						className={classes.fieldMargin}
																						defaultValue={
																							(policyRuleModel.afterStartProgramCompletionAmount as any) >
																								0
																								? policyRuleModel.afterStartProgramCompletionAmount
																								: null
																						}
																						error={
																							!!methods.errors
																								.afterStartProgramCompletionAmount
																						}
																						inputRef={methods.register({
																							validate: {
																								requiredIfAfterStart: (
																									value: any
																								) => {
																									return policyRuleModel.whenIsEnrollmentNoticeGiven ===
																										2 &&
																										policyRuleModel.afterStartProgramCompletionOperator !==
																										5 &&
																										(!value || value <= 0)
																										? "Required."
																										: undefined;
																								},
																							},
																						})}
																						helperText={
																							methods.errors
																								.afterStartProgramCompletionAmount
																								? (
																									methods.errors
																										.afterStartProgramCompletionAmount as FieldError
																								).message
																								: undefined
																						}
																						decimalPlaces={2}
																						onBlur={(e: any) => {
																							handlePolicyRuleChange(
																								"afterStartProgramCompletionAmount",
																								e.target.value
																							);
																						}}
																					/>
																				</Grid>
																				<Grid item md={2} sm={6} xs={12}>
																					<TimePeriodsAutoComplete
																						id="afterStartProgramCompletionType"
																						name="afterStartProgramCompletionType"
																						label=""
																						className={classes.fieldMargin}
																						error={
																							!!methods.errors
																								.afterStartProgramCompletionType
																						}
																						inputRef={methods.register({
																							validate: {
																								requiredIfAfterStart: (
																									value: any
																								) => {
																									return policyRuleModel.whenIsEnrollmentNoticeGiven ===
																										2 && !value
																										? "Required."
																										: undefined;
																								},
																							},
																						})}
																						helperText={
																							methods.errors
																								.afterStartProgramCompletionType
																								? (
																									methods.errors
																										.afterStartProgramCompletionType as FieldError
																								).message
																								: undefined
																						}
																						valueFilter={
																							policyRuleModel.afterStartProgramCompletionType
																								? {
																									key: "value",
																									values: [
																										policyRuleModel.afterStartProgramCompletionType,
																									],
																								}
																								: undefined
																						}
																						filterHandle={(v: any) => {
																							handlePolicyRuleChange(
																								"afterStartProgramCompletionType",
																								v ? v.value : null
																							);
																						}}
																					/>
																				</Grid>
																				{policyRuleModel.afterStartProgramCompletionType !== 5 && (<><Grid item md={2} sm={6} xs={12}>
																					<Typography
																						className={classes.titleMargin}
																					>
																						When notice is given after program start, period completion is calculated using
																					</Typography>
																				</Grid>
																					<Grid item md={2} sm={6} xs={12}>
																						<HourTypesAutoComplete
																							id="programCompletionCalculatedUsingType"
																							name="programCompletionCalculatedUsingType"
																							afterStartProgramCompletionType={policyRuleModel.afterStartProgramCompletionType}
																							label=""
																							className={classes.fieldMargin}
																							error={
																								!!methods.errors
																									.programCompletionCalculatedUsingType
																							}
																							optionsFilter={(e: any) => {
																								return e.sysTransCodeId === 23;
																							}}
																							inputRef={methods.register({
																								validate: {
																									requiredIfAttended: (
																										value: any
																									) => {
																										return policyRuleModel.hasStudentAttendedClass &&
																											!value
																											? "Required."
																											: undefined;
																									},
																								},
																							})}
																							helperText={
																								methods.errors
																									.programCompletionCalculatedUsingType
																									? (
																										methods.errors
																											.programCompletionCalculatedUsingType as FieldError
																									).message
																									: undefined
																							}
																							valueFilter={
																								policyRuleModel.programCompletionCalculatedUsingType
																									? {
																										key: "value",
																										values: [
																											policyRuleModel.programCompletionCalculatedUsingType,
																										],
																									}
																									: undefined
																							}
																							filterHandle={(v: any) => {
																								handlePolicyRuleChange(
																									"programCompletionCalculatedUsingType",
																									v ? v.value : null
																								);
																							}}
																						/>
																					</Grid></>)}
																			</React.Fragment>
																		)}
																	</React.Fragment>
																)}
														</Grid>
													</ExpansionPanelDetails>
												</ExpansionPanel>
												<ExpansionPanel defaultExpanded={true}>
													<ExpansionPanelSummary
														expandIcon={<ExpandMoreIcon />}
														aria-controls="panel3a-content"
														id="panel3a-header"
														className={classes.cardTitle}
													>
														<Typography className={classes.heading}>
															STEP 2: Specify the refund amount for the values
															set for above conditions
														</Typography>
													</ExpansionPanelSummary>
													<ExpansionPanelDetails>
														<Grid container direction="row" spacing={2}>
															<Grid item md={2} sm={6} xs={12}>
																<Typography className={classes.titleMargin}>
																	Full refund or Partial refund
																</Typography>
															</Grid>
															<Grid item md={2} sm={6} xs={12}>
																<RefundTypesAutoComplete
																	id="institutionalRefundType"
																	name="institutionalRefundType"
																	label=""
																	className={classes.fieldMargin}
																	error={
																		!!methods.errors.institutionalRefundType
																	}
																	inputRef={methods.register({
																		required: "This is required",
																	})}
																	helperText={
																		methods.errors.institutionalRefundType
																			? "Required"
																			: null
																	}
																	valueFilter={
																		policyRuleModel.institutionalRefundType
																			? {
																				key: "value",
																				values: [
																					policyRuleModel.institutionalRefundType,
																				],
																			}
																			: undefined
																	}
																	filterHandle={(v: any) => {
																		handlePolicyRuleChange(
																			"institutionalRefundType",
																			v ? v.value : null
																		);
																	}}
																/>
															</Grid>
															{!isPartialRefund && (
																<React.Fragment>
																	<Grid item md={2} sm={6} xs={12}>
																		<Typography className={classes.titleMargin}>
																			Separate adjustment for no starts
																		</Typography>
																	</Grid>
																	<Grid item md={1} sm={6} xs={12}>
																		<Switch
																			id="separateAdjustmentsNoStart"
																			name="separateAdjustmentsNoStart"
																			className={classes.fieldMargin}
																			checked={
																				policyRuleModel.separateAdjustmentsNoStart
																					? true
																					: false
																			}
																			inputRef={methods.register({})}
																			onChange={(e: any) => {
																				handlePolicyRuleChange(
																					"separateAdjustmentsNoStart",
																					e ? e.target.checked : null
																				);
																			}}
																			color="primary"
																		/>
																	</Grid>
																</React.Fragment>
															)}
															{isPartialRefund && (
																<React.Fragment>
																	<Grid item md={2} sm={6} xs={12}>
																		<Typography className={classes.titleMargin}>
																			If Partial then calculation of amount
																			earned is by %, fixed amount or both
																		</Typography>
																	</Grid>
																	<Grid item md={2} sm={6} xs={12}>
																		<RefundPeriodCalculationTypeAutoComplete
																			id="amountEarnedCalculationType"
																			name="amountEarnedCalculationType"
																			label=""
																			className={classes.fieldMargin}
																			error={
																				!!methods.errors
																					.amountEarnedCalculationType
																			}
																			inputRef={methods.register({
																				validate: {
																					ifPartialRequired: (value: any) => {
																						return policyRuleModel.institutionalRefundType ==
																							2 && !value
																							? "Required."
																							: undefined;
																					},
																				},
																			})}
																			helperText={
																				methods.errors
																					.amountEarnedCalculationType
																					? (
																						methods.errors
																							.amountEarnedCalculationType as FieldError
																					).message
																					: undefined
																			}
																			valueFilter={
																				policyRuleModel.amountEarnedCalculationType
																					? {
																						key: "value",
																						values: [
																							policyRuleModel.amountEarnedCalculationType,
																						],
																					}
																					: undefined
																			}
																			filterHandle={(v: any) => {
																				handlePolicyRuleChange(
																					"amountEarnedCalculationType",
																					v ? v.value : null
																				);
																			}}
																		/>
																	</Grid>
																	{policyRuleModel.amountEarnedCalculationType && (
																		<React.Fragment>
																			{(isPartialByFixed ||
																				isPartialByBoth) && (
																					<React.Fragment>
																						<Grid item md={2} sm={6} xs={12}>
																							<Typography
																								className={classes.titleMargin}
																							>
																								Fixed amount if calculation of
																								amount earned is by fixed amount
																							</Typography>
																						</Grid>
																						<Grid item md={2} sm={6} xs={12}>
																							<InputField
																								id="fixedCalculationAmount"
																								name="fixedCalculationAmount"
																								type={InputType.CURRENCY}
																								className={classes.fieldMargin}
																								decimal={true}
																								decimalPlaces={2}
																								defaultValue={
																									policyRuleModel.fixedCalculationAmount
																								}
																								error={
																									!!methods.errors
																										.fixedCalculationAmount
																								}
																								inputRef={methods.register({
																									validate: {
																										requiredIfFixedOrBoth: (
																											value: any
																										) => {
																											return (policyRuleModel.amountEarnedCalculationType ==
																												2 ||
																												policyRuleModel.amountEarnedCalculationType ==
																												3) &&
																												!value
																												? "Required."
																												: undefined;
																										},
																									},
																								})}
																								helperText={
																									methods.errors
																										.fixedCalculationAmount
																										? (
																											methods.errors
																												.fixedCalculationAmount as FieldError
																										).message
																										: undefined
																								}
																								onBlur={(e: any) => {
																									handlePolicyRuleChange(
																										"fixedCalculationAmount",
																										e.target.value
																									);
																								}}
																							/>
																						</Grid>
																					</React.Fragment>
																				)}
																				
																			{(isPartialByPercentage ||
																				isPartialByBoth) && (
																					<React.Fragment>
																						<Grid item md={3} sm={6} xs={12}>
																							<Typography
																								className={classes.titleMargin}
																							>
																								If calculation of amount earned is
																								by % then is the % same for all
																								charges
																							</Typography>
																						</Grid>
																						<Grid item md={1} sm={6} xs={12}>
																							<Switch
																								id="samePercentageForAllCharges"
																								name="samePercentageForAllCharges"
																								className={classes.fieldMargin}
																								checked={
																									policyRuleModel.samePercentageForAllCharges
																										? true
																										: false
																								}
																								inputRef={methods.register({})}
																								onChange={(e: any) => {
																									handlePolicyRuleChange(
																										"samePercentageForAllCharges",
																										e ? e.target.checked : null
																									);
																								}}
																								color="primary"
																							/>
																						</Grid>



																						{isPartialRefund && (
																					<Grid container>
																						<Grid item md={3} sm={6} xs={12}>
																							<Typography
																								className={classes.titleMargin}
																							>
																								Does school retains any % of unearned charges ?
																							</Typography>
																						</Grid>
																						<Grid item md={1} sm={6} xs={12}>
																							<Switch
																								id="doesSchoolRetainsAnyPercentOfUnearnedCharges"
																								name="doesSchoolRetainsAnyPercentOfUnearnedCharges"
																								className={classes.fieldMargin}
																								checked={
																									policyRuleModel.doesSchoolRetainsAnyPercentOfUnearnedCharges
																										? true
																										: false
																								}
																								inputRef={methods.register({})}
																								onChange={(e: any) => {
																									handlePolicyRuleChange(
																										"doesSchoolRetainsAnyPercentOfUnearnedCharges",
																										e ? e.target.checked : null
																									);
																								}}
																								color="primary"
																							/>
																						</Grid>
																						<Grid
																												item
																												md={1}
																												sm={6}
																												xs={12}
																											>
																												<InputField
																													id="percentOfUnearnedCharges"
																													name="percentOfUnearnedCharges"
																													type={InputType.NUMBER}
																													className={
																														classes.fieldMargin
																													}
																													defaultValue={
																														policyRuleModel.percentOfUnearnedCharges
																													}
																													error={
																														!!methods.errors
																															.percentOfUnearnedCharges
																													}
																													inputRef={methods.register(
																														{
																															validate: {
																																requiredIfDoesSchoolRetainPercentageOfUnearnedChargesTrue: (
																																	value: any
																																) => {
																																	return policyRuleModel.doesSchoolRetainsAnyPercentOfUnearnedCharges &&
																																		!value
																																		? "Required."
																																		: undefined;
																																},
																																maxValueCheck:(value:any) =>{
																																	return !!value && !isNaN(value) && value > 100 ? "Value must be between 1 to 100" : undefined
																																},
																																minValueCheck:(value:any) =>{
																																	return !!value && !isNaN(value) && value < 1 ? "Value must be between 1 to 100" : undefined
																																}
																															},
																														}
																													)}
																													helperText={
																														methods.errors
																															.percentOfUnearnedCharges
																															? (
																																methods.errors
																																	.percentOfUnearnedCharges as FieldError
																															).message
																															: undefined
																													}
																													decimal={true}
																													decimalPlaces={2}
																													onBlur={(e: any) => {
																														handlePolicyRuleChange(
																															"percentOfUnearnedCharges",
																															e.target.value
																														);
																													}}
																												/>
																											</Grid>

																											<Grid item md={2} sm={6} xs={12}>
																							<Typography
																								className={classes.titleMargin}
																							>
																								Up to a Maximum $
																							</Typography>
																						</Grid>
																						<Grid
																												item
																												md={1}
																												sm={6}
																												xs={12}
																											>
																												<InputField
																													id="maximumRetainOnUnearnedCharges"
																													name="maximumRetainOnUnearnedCharges"
																													type={InputType.NUMBER}
																													className={
																														classes.fieldMargin
																													}
																													defaultValue={
																														policyRuleModel.maximumRetainOnUnearnedCharges
																													}
																													error={
																														!!methods.errors
																															.maximumRetainOnUnearnedCharges
																													}
																													inputRef={methods.register(
																														{
																															validate: {
																																requiredIfDoesSchoolRetainPercentageOfUnearnedChargesTrue: (
																																	value: any
																																) => {
																																	return policyRuleModel.doesSchoolRetainsAnyPercentOfUnearnedCharges &&
																																		!value
																																		? "Required."
																																		: undefined;
																																},
																															},
																															max:100,
																															min:1
																														}
																													)}
																													helperText={
																														methods.errors
																															.maximumRetainOnUnearnedCharges
																															? (
																																methods.errors
																																	.maximumRetainOnUnearnedCharges as FieldError
																															).message
																															: undefined
																													}
																													decimal={true}
																													decimalPlaces={2}
																													onBlur={(e: any) => {
																														handlePolicyRuleChange(
																															"maximumRetainOnUnearnedCharges",
																															e.target.value
																														);
																													}}
																												/>
																											</Grid>
																					</Grid>
																				)}





																						{isPercentageSameForAllCharges && (
																							<React.Fragment>
																								{policyRuleModel.afterStartProgramCompletionType ===
																									4 && (
																										<React.Fragment>
																											<Grid
																												item
																												md={2}
																												sm={6}
																												xs={12}
																											>
																												<Typography
																													className={
																														classes.titleMargin
																													}
																												>
																													Is the % earned for all
																													charges prorated as per
																													percentage of period
																													completed?
																												</Typography>
																											</Grid>
																											<Grid
																												item
																												md={1}
																												sm={6}
																												xs={12}
																											>
																												<Switch
																													id="isProRatedForAllCharges"
																													name="isProRatedForAllCharges"
																													className={
																														classes.fieldMargin
																													}
																													checked={
																														policyRuleModel.isProRatedForAllCharges
																															? true
																															: false
																													}
																													inputRef={methods.register(
																														{}
																													)}
																													onChange={(e: any) => {
																														handlePolicyRuleChange(
																															"isProRatedForAllCharges",
																															e
																																? e.target.checked
																																: null
																														);
																													}}
																													color="primary"
																												/>
																											</Grid>
																										</React.Fragment>
																									)}
																								{(!policyRuleModel ||
																									!policyRuleModel.isProRatedForAllCharges) && (
																										<React.Fragment>
																											<Grid
																												item
																												md={2}
																												sm={6}
																												xs={12}
																											>
																												<Typography
																													className={
																														classes.titleMargin
																													}
																												>
																													Specify the % if it is the
																													same for all charges
																												</Typography>
																											</Grid>
																											<Grid
																												item
																												md={2}
																												sm={6}
																												xs={12}
																											>
																												<InputField
																													id="percentForAllCharges"
																													name="percentForAllCharges"
																													type={InputType.NUMBER}
																													className={
																														classes.fieldMargin
																													}
																													defaultValue={
																														policyRuleModel.percentForAllCharges
																													}
																													error={
																														!!methods.errors
																															.percentForAllCharges
																													}
																													inputRef={methods.register(
																														{
																															validate: {
																																requiredIfSame: (
																																	value: any
																																) => {
																																	return policyRuleModel.samePercentageForAllCharges &&
																																		!value
																																		? "Required."
																																		: undefined;
																																},
																															},
																														}
																													)}
																													helperText={
																														methods.errors
																															.percentForAllCharges
																															? (
																																methods.errors
																																	.percentForAllCharges as FieldError
																															).message
																															: undefined
																													}
																													decimal={true}
																													decimalPlaces={2}
																													onBlur={(e: any) => {
																														handlePolicyRuleChange(
																															"percentForAllCharges",
																															e.target.value
																														);
																													}}
																												/>
																											</Grid>
																										</React.Fragment>
																									)}
																							</React.Fragment>
																						)}
																					</React.Fragment>
																				)}
																			{isPartialByBoth && (
																				<React.Fragment>
																					<Grid item md={2} sm={6} xs={12}>
																						<Typography
																							className={classes.titleMargin}
																						>
																							Condition when both % and amount
																							to be earned specified
																						</Typography>
																					</Grid>
																					<Grid item md={2} sm={6} xs={12}>
																						<WhenBothSpecifiedAutoComplete
																							id="conditionWhenBothSpecified"
																							name="conditionWhenBothSpecified"
																							label=""
																							className={classes.fieldMargin}
																							valueFilter={
																								policyRuleModel.conditionWhenBothSpecified
																									? {
																										key: "value",
																										values: [
																											policyRuleModel.conditionWhenBothSpecified,
																										],
																									}
																									: undefined
																							}
																							error={
																								!!methods.errors
																									.conditionWhenBothSpecified
																							}
																							inputRef={methods.register({
																								validate: {
																									requiredIfBoth: (
																										value: any
																									) => {
																										return policyRuleModel.amountEarnedCalculationType ==
																											3 && !value
																											? "Required."
																											: undefined;
																									},
																								},
																							})}
																							helperText={
																								methods.errors
																									.conditionWhenBothSpecified
																									? (
																										methods.errors
																											.conditionWhenBothSpecified as FieldError
																									).message
																									: undefined
																							}
																							filterHandle={(v: any) => {
																								handlePolicyRuleChange(
																									"conditionWhenBothSpecified",
																									v ? v.value : null
																								);
																							}}
																						/>
																					</Grid>
																					{!policyRuleModel.samePercentageForAllCharges && (
																						<Grid
																							item
																							md={4}
																							sm={6}
																							xs={12}
																						></Grid>
																					)}
																				</React.Fragment>
																			)}
																			{isPercentageSameForAllCharges ===
																				false &&
																				!isFullRefund &&
																				(isPartialByBoth ||
																					isPartialByPercentage) && (
																					<React.Fragment>
																						<Grid item md={2} sm={6} xs={12}>
																							<Typography
																								className={classes.titleMargin}
																							>
																								If % is different for the
																								charges that can be earned
																								specify the charges and
																								respective %
																							</Typography>
																						</Grid>
																						<Grid item md={1} sm={6} xs={12}>
																							<TransactionCodeAutoComplete
																								readOnly={false}
																								id="transCodeId"
																								name="transCodeId"
																								key={"transCodeId"}
																								hideLabel={true}
																								className={classes.fieldMargin}
																								disableClearable={false}
																								disabled={false}
																								filterHandle={(e: any) => {
																									handleTransCodeChargesChanges(
																										"transCodeId",
																										e ? e.value : null
																									);
																								}}
																								error={
																									!!methods.errors[
																									`transCodeId`
																									]
																								}
																								inputRef={methods.register({})}
																								helperText={
																									methods.errors["transCodeId"]
																										? (
																											methods.errors[
																											"transCodeId"
																											] as FieldError
																										).message
																										: null
																								}
																								valueFilter={
																									refundTransCodeChargesModel.transCodeId !==
																										undefined &&
																										refundTransCodeChargesModel.transCodeId !==
																										null
																										? {
																											key: "value",
																											values: [
																												refundTransCodeChargesModel.transCodeId,
																											],
																										}
																										: undefined
																								}
																							/>
																						</Grid>
																						{policyRuleModel.afterStartProgramCompletionType ===
																							4 && (
																								<React.Fragment>
																									<Grid
																										item
																										md={1}
																										sm={6}
																										xs={12}
																									>
																										<Typography
																											className={
																												classes.titleMargin
																											}
																										>
																											Prorate per period completed
																										</Typography>
																									</Grid>
																									<Grid
																										item
																										md={1}
																										sm={6}
																										xs={12}
																									>
																										<Switch
																											id="isProRated"
																											name="isProRated"
																											className={
																												classes.fieldMargin
																											}
																											checked={
																												refundTransCodeChargesModel.isProRated
																													? true
																													: false
																											}
																											inputRef={methods.register(
																												{}
																											)}
																											onChange={(e: any) => {
																												handleTransCodeChargesChanges(
																													"isProRated",
																													e
																														? e.target.checked
																														: null
																												);
																											}}
																											color="primary"
																										/>
																									</Grid>
																								</React.Fragment>
																							)}
																						{!refundTransCodeChargesModel.isProRated && (
																							<Grid item md={1} sm={6} xs={12}>
																								<InputField
																									id="percentage"
																									name="percentage"
																									type={InputType.NUMBER}
																									decimal={true}
																									decimalPlaces={2}
																									className={
																										classes.fieldMargin
																									}
																									defaultValue={
																										refundTransCodeChargesModel.percentage
																									}
																									error={
																										!!methods.errors.percentage
																									}
																									inputRef={methods.register(
																										{}
																									)}
																									helperText={
																										methods.errors.percentage
																											? "Required"
																											: null
																									}
																									onBlur={(e: any) => {
																										handleTransCodeChargesChanges(
																											"percentage",
																											e ? e.target.value : null
																										);
																									}}
																								/>
																							</Grid>
																						)}
																						<Grid item md={1} sm={6} xs={12}>
																							<IconButton
																								onClick={(e: any) => {
																									handleTransCodeChargesAdd();
																								}}
																								className={classes.iconButton}
																							>
																								<DoneIcon
																									className={
																										classes.buttonIconColor
																									}
																								/>
																							</IconButton>
																						</Grid>
																					</React.Fragment>
																				)}
																		</React.Fragment>
																	)}
																</React.Fragment>
															)}

															<Grid item md={2} sm={6} xs={12}>
																<Typography className={classes.titleMargin}>
																	Termination fee if applicable
																</Typography>
															</Grid>
															<Grid item md={2} sm={6} xs={12}>
																<TransactionCodeAutoComplete
																	id="terminationFeeTransactionId"
																	name="terminationFeeTransactionId"
																	hideLabel={true}
																	className={classes.fieldMargin}
																	filterHandle={(e: any) => {
																		handlePolicyRuleChange(
																			"terminationFeeTransactionId",
																			e ? e.value : null
																		);
																	}}
																	error={
																		!!methods.errors.terminationFeeTransactionId
																	}
																	inputRef={methods.register({})}
																	helperText={
																		methods.errors.terminationFeeTransactionId
																			? "Required"
																			: null
																	}
																	valueFilter={
																		policyRuleModel.terminationFeeTransactionId
																			? {
																				key: "value",
																				values: [
																					policyRuleModel.terminationFeeTransactionId,
																				],
																			}
																			: undefined
																	}
																	optionsFilter={(x: any) => {
																		return (
																			x.sysTransCodeId ==
																			TransactionCode.TerminationFee
																		);
																	}}
																	placeHolder={"Select"}
																/>
															</Grid>
															<Grid item md={1} sm={6} xs={12}>
																<InputField
																	id="terminationFeeAmount"
																	name="terminationFeeAmount"
																	type={InputType.CURRENCY}
																	className={classes.fieldMargin}
																	decimal={true}
																	decimalPlaces={2}
																	defaultValue={
																		policyRuleModel.terminationFeeAmount
																	}
																	adornmentPosition={AdornmentPosition.PRE}
																	onBlur={(e: any) => {
																		handlePolicyRuleChange(
																			"terminationFeeAmount",
																			e.target.value
																		);
																	}}
																/>
															</Grid>
															{isPercentageSameForAllCharges === false &&
																!isFullRefund &&
																(isPartialByBoth || isPartialByPercentage) &&
																refundTransCodeChargesArray &&
																refundTransCodeChargesArray.length > 0 && (
																	<Grid item md={6} sm={12} xs={12}>
																		<Table className={classes.tableRoot}>
																			<TableHead>
																				<TableRow>
																					<TableCell
																						className={classes.tableCell}
																						style={{
																							width:
																								policyRuleModel.afterStartProgramCompletionType ===
																									4
																									? "35%"
																									: "60%",
																						}}
																					>
																						<strong>Transaction code</strong>
																					</TableCell>
																					{policyRuleModel.afterStartProgramCompletionType ===
																						4 && (
																							<TableCell
																								className={classes.tableCell}
																								style={{ width: "25%" }}
																							>
																								<strong>
																									Prorate per period completed
																								</strong>
																							</TableCell>
																						)}
																					<TableCell
																						className={classes.tableCell}
																						style={{ width: "25%" }}
																					>
																						<strong>% earned</strong>
																					</TableCell>

																					<TableCell
																						className={classes.tableCell}
																						style={{ width: "25%" }}
																					>
																						<strong>Out of total %</strong>
																					</TableCell>

																					<TableCell
																						className={classes.tableCell}
																						style={{ width: "15%" }}
																					>
																						&nbsp;
																					</TableCell>
																				</TableRow>
																			</TableHead>
																			<TableBody>
																				{refundTransCodeChargesArray.map(
																					(
																						row: RefundTransCodeChargesModel,
																						index: any
																					) => {
																						return (
																							<TableRow
																								key={row.clientId?.toString()}
																							>
																								<TableCell
																									className={`${classes.tableCell}`}
																								>
																									<TransactionCodeAutoComplete
																										readOnly={
																											row.clientId !==
																											refundTransCodeChargesEditModel.clientId
																										}
																										id={`transCode-${row.clientId?.toString()}`}
																										name={`transCode-${row.clientId?.toString()}`}
																										key={row.clientId?.toString()}
																										hideLabel={true}
																										className={
																											classes.fieldMargin
																										}
																										disableClearable={true}
																										disabled={false}
																										filterHandle={(e: any) => {
																											handleTransCodeChargesEditChanges(
																												"transCodeId",
																												e ? e.value : null,
																												`transCode-${row.clientId?.toString()}`
																											);
																										}}
																										error={
																											!!methods.errors[
																											`transCode-${row.clientId?.toString()}`
																											]
																										}
																										inputRef={methods.register({
																											required: true,
																										})}
																										helperText={
																											methods.errors[
																												`transCode-${row.clientId?.toString()}`
																											]
																												? (
																													methods.errors[
																													`transCode-${row.clientId?.toString()}`
																													] as FieldError
																												).message
																												: null
																										}
																										valueFilter={
																											row.transCodeId
																												? {
																													key: "value",
																													values: [
																														row.clientId !==
																															refundTransCodeChargesEditModel.clientId
																															? row.transCodeId
																															: refundTransCodeChargesEditModel.transCodeId,
																													],
																												}
																												: undefined
																										}
																									/>
																								</TableCell>
																								{policyRuleModel.afterStartProgramCompletionType ==
																									4 && (
																										<TableCell
																											className={`${classes.tableCell}`}
																										>
																											<Switch
																												disabled={
																													row.clientId !==
																													refundTransCodeChargesEditModel.clientId
																												}
																												readOnly={
																													row.clientId !==
																													refundTransCodeChargesEditModel.clientId
																												}
																												id={`isProRated-${row.clientId?.toString()}`}
																												name={`isProRated-${row.clientId?.toString()}`}
																												className={
																													classes.fieldMargin
																												}
																												checked={
																													(
																														row.clientId !==
																															refundTransCodeChargesEditModel.clientId
																															? row.isProRated
																															: refundTransCodeChargesEditModel.isProRated
																													)
																														? true
																														: false
																												}
																												inputRef={methods.register(
																													{}
																												)}
																												onChange={(e: any) => {
																													handleTransCodeChargesEditChanges(
																														"isProRated",
																														e
																															? e.target.checked
																															: null,
																														`isProRated-${row.clientId?.toString()}`,
																														`percentage-${row.clientId?.toString()}`
																													);
																												}}
																												color="primary"
																											/>
																										</TableCell>
																									)}
																								<TableCell
																									className={`${classes.tableCell}`}
																								>
																									{(refundTransCodeChargesEditModel &&
																										row.clientId ===
																										refundTransCodeChargesEditModel.clientId
																										? !refundTransCodeChargesEditModel.isProRated
																										: !row.isProRated) && (
																											<InputField
																												id={`percentage-${row.clientId}`}
																												name={`percentage-${row.clientId}`}
																												type={InputType.NUMBER}
																												className={
																													classes.fieldMargin
																												}
																												readOnly={
																													row.clientId !==
																													refundTransCodeChargesEditModel.clientId
																												}
																												decimal={true}
																												decimalPlaces={2}
																												disabled={false}
																												defaultValue={
																													row.percentage
																												}
																												error={
																													!!methods.errors[
																													`percentage-${row.clientId}`
																													]
																												}
																												helperText={
																													methods.errors[
																														`percentage-${row.clientId}`
																													]
																														? "Required"
																														: null
																												}
																												inputRef={methods.register(
																													{
																														required:
																															!refundTransCodeChargesEditModel.isProRated,
																													}
																												)}
																												onBlur={(e: any) => {
																													handleTransCodeChargesEditChanges(
																														"percentage",
																														e
																															? e.target.value
																															: null,
																														`percentage-${row.clientId}`
																													);
																												}}
																											/>
																										)}
																								</TableCell>



																								<TableCell
																									className={`${classes.tableCell}`}
																								>
																									{(refundTransCodeChargesEditModel
																										) && (
																											<InputField
																												id={`outOfPercentage-${row.clientId}`}
																												name={`outOfPercentage-${row.clientId}`}
																												type={InputType.NUMBER}
																												className={
																													classes.fieldMargin
																												}
																												readOnly={
																													row.clientId !==
																													refundTransCodeChargesEditModel.clientId
																												}
																												decimal={true}
																												decimalPlaces={2}
																												disabled={false}
																												defaultValue={
																													row.outOfPercentage ?? 100
																												}
																												error={
																													!!methods.errors[
																													`outOfPercentage-${row.clientId}`
																													]
																												}
																												helperText={
																													methods.errors[
																														`outOfPercentage-${row.clientId}`
																													]
																														? "Required"
																														: null
																												}
																												inputRef={methods.register(
																													{
																														required:
																															!refundTransCodeChargesEditModel.isProRated,
																													}
																												)}
																												onBlur={(e: any) => {
																													handleTransCodeChargesEditChanges(
																														"outOfPercentage",
																														e
																															? e.target.value
																															: null,
																														`outOfPercentage-${row.clientId}`
																													);
																												}}
																											/>
																										)}
																								</TableCell>




																								<TableCell
																									className={`${classes.tableCell}`}
																									style={{
																										paddingBottom: "0px",
																									}}
																								>
																									{row.clientId !==
																										refundTransCodeChargesEditModel.clientId && (
																											<EditIcon
																												style={{
																													marginTop: "23px",
																												}}
																												onClick={(e: any) => {
																													handleTransCodeChargesEdit(
																														row
																													);
																												}}
																												cursor={"pointer"}
																												fontSize="small"
																											/>
																										)}

																									{row.clientId !==
																										refundTransCodeChargesEditModel.clientId && (
																											<DeleteIcon
																												onClick={(e: any) => {
																													if (row.clientId) {
																														handleTransCodeChargesDelete(
																															row.clientId
																														);
																													}
																												}}
																												cursor={"pointer"}
																												fontSize="small"
																											/>
																										)}
																									{(row.clientId ===
																										refundTransCodeChargesEditModel.clientId ??
																										false) && (
																											<IconButton
																												onClick={(e: any) => {
																													handleTransCodeChargesUpdate(
																														index
																													);
																												}}
																												className={
																													classes.iconButton
																												}
																											>
																												<DoneIcon
																													className={
																														classes.buttonIconColor
																													}
																												/>
																											</IconButton>
																										)}
																								</TableCell>
																							</TableRow>
																						);
																					}
																				)}
																			</TableBody>
																		</Table>
																	</Grid>
																)}
														<Grid container>
															<Grid item md={2} sm={6} xs={12}>
																<Typography
																	className={classes.titleMargin}
																>
																	Specify non-refundable charges
																</Typography>
															</Grid>
															<Grid item md={3} sm={6} xs={12}>
																<TransactionCodeAutoComplete
																	readOnly={false}
																	id="nonRefundableTransCodeIds"
																	name="nonRefundableTransCodeIds"
																	key={"nonRefundableTransCodeIds"}
																	hideLabel={true}
																	className={classes.fieldMargin}
																	disableClearable={true}
																	disabled={false}
																	filterHandle={(e: any) => {
																		handleNonRefundableTrancodeIdsChange(e);
																	}}
																	error={
																		!!methods.errors[
																		`nonRefundableTransCodeIds`
																		]
																	}
																	inputRef={methods.register({})}
																	helperText={
																		methods.errors["nonRefundableTransCodeIds"]
																			? (
																				methods.errors[
																				"nonRefundableTransCodeIds"
																				] as FieldError
																			).message
																			: null
																	}
																	valueFilter={
																		policyRuleModel.nonRefundableTransCodeIds !==
																			undefined &&
																			policyRuleModel.nonRefundableTransCodeIds !==
																			null
																			? {
																				key: "value",
																				values:[...policyRuleModel.nonRefundableTransCodeIds.split(',')],
																			}
																			: undefined
																	}

																	multiple={true}
																/>
															</Grid>
															</Grid>
														</Grid>
													</ExpansionPanelDetails>
												</ExpansionPanel>
												<Grid item>
													<div className={classes.actionContainer}>
														<ProgressSaveButton
															text="Save Rule"
															onClick={() => { }}
															loading={loader}
															buttonClassName={classes.actionContainerButton}
														></ProgressSaveButton>
														<ProgressSaveButton
															text="Cancel"
															onClick={() => {
																setIsEditingOrCreatingPolicyRule(false);
															}}
															loading={false}
														></ProgressSaveButton>
													</div>
												</Grid>
											</Grid>
										)}
									</Grid>
								</ExpansionPanelDetails>
							</ExpansionPanel>
							<div className={classes.actionContainer}>
								<AccessManager
									allowedPermissions={[
										security.permissions.studentAccounts.updateRefundPolicies,
										security.permissions.studentAccounts.deleteRefundPolicies,
									]}
									renderNoAccess={() => (
										<ProgressSaveButton
											text="Save"
											onClick={() => { }}
											disabled={true}
											loading={loader}
										></ProgressSaveButton>
									)}
								>
									<ProgressSaveButton
										text="Save"
										onClick={() => { }}
										loading={loader}
									></ProgressSaveButton>
								</AccessManager>
								{!!policyModel && !!policyModel.institutionalRefundPolicyId && <Button
										type="button"
										color="secondary"
										variant="contained"
										onClick={() => {
											setOpenProgramVersionsPopup(true);
										}}
										style={{marginLeft:10}}
									>Map to Program Versions</Button>}
							</div>
						</form>
					</FormContext>
				</React.Fragment>
			)}
			<CustomSnackbar
				variant={snackBarProps.variant}
				message={snackBarProps.messageInfo}
				open={snackBarProps.showSnackBar}
				onClose={(event?: React.SyntheticEvent, reason?: string) => {
					setSnackBarProps((props: any) => {
						return { ...props, showSnackBar: false };
					});
				}}
			></CustomSnackbar>
			<ConfirmationModal {...confirmationPopupState} />
			<GoBackConfirmationModal {...goBackConfirmationPopupState} />
		</Paper>
	);
};

export default RefundSetup;
