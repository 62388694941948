import {
  Backdrop,
  Card,
  CardContent,
  Color,
  colors,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import FavoriteIcon from "@material-ui/icons/Star";
import FavoriteBorderIcon from "@material-ui/icons/StarBorder";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import * as PropTypes from "prop-types";
import * as React from "react";
import { useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import * as reportsApi from "../../../src/api/reports/allReports/reportsApi";
import ColoredChip from "../../components/Chips/ColoredChip";
import * as actionTypes from "../../constants/actions.constants";
import { ModuleTags } from "../../interfaces/common/ModuleTags";
import { Tag } from "../../interfaces/common/Tag";
import { Report } from "../../interfaces/reports/Report";

const useStyles = makeStyles((theme: any) => ({
  root: { position: "relative" },
  content: {
    padding: theme.spacing(1),
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  lockIcon: {
    fontSize: theme.spacing(3.75),
    color: theme.palette.white,
    cursor: "pointer",
  },
  reportLink: {
    cursor: "pointer",
  },
  truncatedText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "inline-block",
  },
  truncatedTextDescription: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    minWidth: "30%",
    maxWidth: "45%",
    display: "block",
    marginLeft: theme.spacing(1),
  },
  tags: {
    minWidth: 5,
    minHeight: 5,
    padding: theme.spacing(0, 1, 1, 1),
    "& > * + *": {
      marginLeft: theme.spacing(1),
    },
  },
  favoritedButton: {
    color: colors.yellow[600],
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    margin: 0,
  },
  inlineFlex: {
    display: "inline-flex",
  },
  displayFlix: {
    display: "flex",
  },
  tagStyle: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

const LimitedBackdrop = withStyles({
  root: {
    position: "absolute",
    zIndex: 1,
  },
})(Backdrop);

type ReportCardProps = {
  className?: string;
  report: Report;
  setFavorites: (report: Report, favorite: boolean) => void;
  type?: string;
  oepnViewer: (report: Report) => void;
};

type colorShades = {
  color: Color;
  shades: string[];
};
const colorsToUse: colorShades[] = [
  { color: colors.blue, shades: ["300", "500", "700", "900"] },
  { color: colors.red, shades: ["300", "500", "700", "900"] },
  { color: colors.green, shades: ["300", "500", "700", "900"] },
  { color: colors.yellow, shades: ["300", "500", "700", "900"] },
  { color: colors.orange, shades: ["300", "500", "700", "900"] },
  { color: colors.pink, shades: ["300", "500", "700", "900"] },
  { color: colors.teal, shades: ["300", "500", "700", "900"] },
  { color: colors.blue, shades: ["300", "500", "700", "900"] },
];

const getTagColors = () => {
  let tagColors: string[] = [];
  colorsToUse.forEach((col: colorShades) => {
    let colorShades = col.shades.map((shade: string) => {
      return (col.color as any)[shade] as string;
    });
    tagColors.push(...colorShades);
  });
  return tagColors;
};

const ReportCard = (props: ReportCardProps) => {
  const { report, className, setFavorites, oepnViewer, ...rest } = props;
  const { favorited } = props.report;
  const dispatch = useDispatch();
  const inputRef = React.useRef<HTMLInputElement>(null);
  let type = props.type;
  const classes = useStyles({});
  const moduleTagState = useSelector(
    (state: any) => state.tag.moduleTags
  ) as any;
  const moduleTags = moduleTagState.find(
    (t: any) => t.moduleId === "9"
  ) as ModuleTags;
  const [editing, setEditing] = useState(false);
  const [tags, setTags] = useState(report.tags);
  const dragData = useSelector((state: any) => state.drag.dragData);
  const handleFavorited = (e: any) => {
    e.stopPropagation();
    setFavorites(props.report, true);
  };
  const [detailsHoverState, setdetailsHoverState] = useState<boolean>(false);
  const colors = getTagColors();
  React.useEffect(() => {
    setTags(report.tags);
  }, [report.tags]);

  const handleUnFavorited = (e: any) => {
    e.stopPropagation();
    setFavorites(props.report, false);
  };
  const handleStopEditing = () => {
    setEditing(false);
  };
  const handleEditing = () => {
    setEditing(true);
  };

  const [inputValue, setInputValue] = useState("");

  const onTagSelected = (tag: any) => {
    setTags((tags) => [...tags, tag]);
  };

  const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };
  const userId = useSelector((state: any) => state.session.user.userId);

  const updateReportTagByUser = async (
    report: Report,
    categoryTagIdList: Array<string>
  ): Promise<void> => {
    const response = await reportsApi.updateReportTagByUser(
      report.id,
      report.typeId,
      categoryTagIdList,
      userId
    );
  };

  const updateTag = (list: Array<any>, Rep: Report) => {
    let tagslist: [any] = [0];
    for (var i = 0; i < list.length; i++) {
      tagslist.push(list[i].id);
    }
    tagslist.splice(0, 1);
    updateReportTagByUser(Rep, tagslist);
  };

  const handleInputChange = (event: any) => {
    event.persist();
    setInputValue(event.target.value);
  };
  const handleInputKeyup = (event: any) => {
    event.persist();
    if (event.keyCode === 13 && inputValue) {
      if (tags.findIndex((t: Tag) => t.text === inputValue) === -1) {
        let color = getRandomColor();
        let newTag: Tag = {
          color: color,
          text: inputValue.toUpperCase(),
          index: tags.length + 1,
          Id: "9",
        };
        setTags((tags) => [...tags, newTag]);
        setInputValue("");

        dispatch({
          type: actionTypes.tagConstants.TAG_CREATED,
          data: { moduleId: "9", tag: newTag },
        });
      }
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<any | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleTagClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTagClose = () => {
    setAnchorEl(null);
  };

  const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const move = (
    key: string,
    destination: Array<any>,
    droppableDestination: any
  ) => {
    const destClone = Array.from(destination);
    let newTag = moduleTags.tags.find((t) => t.text.toLowerCase() === key);

    if (newTag) destClone.splice(droppableDestination.index, 0, newTag);

    return destClone;
  };
  if (!type) type = "normal";

  React.useEffect(() => {
    if (dragData) {
      if (dragData.destination) {
        if (dragData.destination.droppableId === `${type}-${report.name}`) {
          let key: string = dragData.draggableId.toLowerCase();
          let sourceDroppableKey = dragData.source.droppableId;
          let destinationDroppableKey = dragData.destination.droppableId;

          if (sourceDroppableKey == destinationDroppableKey) {
            setTags((tags: any) =>
              reorder(tags, dragData.source.index, dragData.destination.index)
            );
          } else if (!tags.find((t) => t.text.toLowerCase() === key)) {
            setTags((tags: any) => move(key, tags, dragData.destination));
          }
        }
      }
    }
  }, [dragData]);

  const session = useSelector((state: any) => state.session);
  const appConfig: any = session.appConfig.appConfig;
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <LimitedBackdrop open={!report.isUnLock}>
        <Tooltip
          title="User did not have permission for this Report."
          aria-label="User did not have permission for this Report."
        >
          <LockOutlinedIcon className={classes.lockIcon}></LockOutlinedIcon>
        </Tooltip>
      </LimitedBackdrop>
      <CardContent className={classes.content}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={8} className={classes.displayFlix}>
            <Typography
              className={clsx(classes.truncatedText, classes.inlineFlex)}
            >
              <Link
                color="textPrimary"
                onClick={(e: any) => {
                  e.stopPropagation();
                  oepnViewer(props.report);
                }}
                variant="h5"
                className={clsx(classes.reportLink)}
              >
                {report.name}
              </Link>
            </Typography>
            <Tooltip title={report.description ?? ""}>
              <Typography
                color="textSecondary"
                variant="subtitle2"
                className={clsx(classes.truncatedTextDescription)}
              >
                {report.description}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={4} className={classes.tagStyle}>
            <div
              className={clsx("tagContainer", classes.inlineFlex)}
              onPointerEnter={() => setdetailsHoverState(true)}
              onPointerLeave={() => setdetailsHoverState(false)}
              onDoubleClick={() => {
                setEditing(true);
                if (inputRef && inputRef.current) inputRef.current.focus();
              }}
              onBlur={() => setEditing(false)}
            >
              <Droppable
                droppableId={`${type}-${report.name}`}
                direction="horizontal"
              >
                {(provided, _snapshot) => (
                  <Grid ref={provided.innerRef} container spacing={1}>
                    {tags.map((tag, index) => (
                      <Draggable
                        key={`${type}-${report.name}-${tag.text}`}
                        draggableId={`${type}-${report.name}-${tag.text}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Grid
                            item
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={provided.draggableProps.style}
                          >
                            <ColoredChip
                              id={tag.text}
                              key={tag.text}
                              color={tag.color}
                              label={tag.text}
                            />
                          </Grid>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            </div>

            {report.favorited ? (
              <Tooltip title="Unfavorite">
                <IconButton
                  className={clsx(classes.favoritedButton)}
                  onClick={handleUnFavorited}
                  size="small"
                >
                  <FavoriteIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Favorite">
                <IconButton onClick={handleFavorited} size="small">
                  <FavoriteBorderIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ReportCard.propTypes = {
  className: PropTypes.string,
  report: PropTypes.object.isRequired,
};

export default ReportCard;
