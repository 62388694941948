import React from "react";
import { useSelector } from "react-redux";
import Paper from "@material-ui/core/Paper";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import AdmissionsRepsAutoComplete from "../../../../components/AutoComplete/AdmissionsRepsAutoComplete";
import { StudentProfile } from "../../../../interfaces/student/profile/StudentProfile"
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(3),
            minHeight: "100%",
            height: "100%",
            maxWidth: "1050px",
            margin: "auto"
        },
        "& .makeStyles-content": {
            backgroundColor: theme.palette.background.paper
        },
        paperRoot: {
            padding: theme.spacing(3, 2),
            width: "100%",
            minHeight: "100%",
            height: "100%",
            overflowX: "hidden",
            overflowY: "auto",
            flexDirection: "column",
            backgroundColor: theme.palette.background.paper
        },
        dropDown: {
            marginBottom: theme.spacing(3)
        }
    })
);

const LeadAppointments = (props: any) => {
    const classes = useStyles({});    
    const selectedStudent = useSelector((state: any) => state.student) as StudentProfile;  
    const selectedStudentLeadId = selectedStudent.leadId;
    const theme: any = useTheme();

    moment.locale("ko", {
        week: {
            dow: 1,
            doy: 1
        }
    });

    const localizer = momentLocalizer(moment);

    const events = [{}];

    const handleAdmissionsRepChange = (e: any) => {
        var admissionsRepValue = e == null ? null : e.value;
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paperRoot}>
            <div className={classes.dropDown}>
                <AdmissionsRepsAutoComplete
                    filterHandle={handleAdmissionsRepChange}
                />
            </div>
            <Calendar
                localizer={localizer}
                events={events}
                defaultView="week"
                min={new Date(0, 0, 0, 6, 0, 0)}
                eventPropGetter={event => ({
                    style: {
                        backgroundColor: theme.palette.primary.main
                    }
                })}
            />
            </Paper>
        </div>
    );
};

export default LeadAppointments;
