import { IT1098InputParams } from '../../interfaces/academicRecords/IT1098InputParams';
import API from '../apiWrapper';

export const T1098InputAPI = (
	params: IT1098InputParams
) => {
	return API()
		.post(
			'/FinancialAid/T1098/GetT1098OutputModel',
			params,
			
		)
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

export const T1098ExceptionsAPI = (
	params: IT1098InputParams
) => {
	return API()
		.post(
			'/FinancialAid/T1098/GetT1098Exceptions',
			params,
			{
				responseType: 'blob',
			}
		)
		.then(
			(res: any) => {
				if (res && res.data) {
					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					var fileName ='Exception1098.pdf';

					link.href = url;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
				}
			},
			(error: any) => {
				return null;
			}
		);
};