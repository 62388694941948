import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Portal,
  Grid,
  Button,
  Card,
} from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useForm, FormContext } from "react-hook-form";
import { DropzoneArea } from "material-ui-dropzone";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CustomSnackbar from "../../components/notifications/CustomSnackbar";
import { CustomSnackBarProps } from "../../interfaces/common/CustomSnackBarProps";
import ConfirmationModal from "../../components/_Layout/Modal/ConfirmationModal";
import { ConfirmationModalProps } from "../../components/_Layout/Modal/ConfirmationModal";
import * as attendanceApi from "../../api/student/attendance/attendanceApi";
import { useSelector, useDispatch } from "react-redux";
import TimeClockLogGrid from "./TimeClockLogGrid";
import CustomCardTitle from "../../interfaces/common/card/CustomCardTitle";
import InfoStatusGrid from "../student/status/InfoStatusGrid";
import ProgressSaveButton from "../../components/_Layout/Buttons/ProgressSaveButton";
import { IStudentInfo } from "../../interfaces/student/profile/studentInfo/IStudentInfo";
import { IStatusInfoRow } from "../../interfaces/student/profile/status/IStatusInfoRow";
import { ActionResult } from "../../interfaces/common/api/ActionResult";
import { userConstants } from "../../constants/actions.constants";

import * as notificationsApi from '../../api/Notifications/notificationsApi'
interface ImportTimeClockModalProps extends RouteComponentProps {
  modalProps: any;
  setBreadCrumbBarLoader: any;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .MuiPaper-rounded": {
        borderRadius: theme.spacing.borderradius,
      },
      "& .MuiDialogContent-root": {
        minHeight: 400,
      },
    },
    form: {
      display: "flex",
      flexDirection: "column",
      width: "fit-content",
      minWidth: 100,
    },
    dialogHeader: {
      backgroundColor: theme.palette.site.secondary,
      "& .MuiTypography-root": {
        fontSize: theme.spacing(2),
      },
    },
    cardWrapper:{marginBottom:theme.spacing(2)},
    dialogContent: {
      "& .MuiDropzoneArea-invalid": {
        borderColor: "#000000 !important",
        backgroundImage:
          "repeating-linear-gradient(-45deg, #000000, #000000 25px, #ffffff 25px, #ffffff 50px) !important",
      },
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    historyWrapper: {
      padding: theme.spacing(0.5),
    },
    updateWrapper: {
      padding: theme.spacing(0.5),
      height: "125px",
    },
    spacer: {
      height: theme.spacing(1),
    },
    dropZone: {
      backgroundColor: theme.palette.white,
      width: "100%",
      minHeight: "150px",
      height: 150,
      marginBottom: theme.spacing(2),
    },
    retuningLOAStudent: {},
    retuningLOAStudentContent:{
      padding:theme.spacing(2)
    },
    saveButton: {
      width: 150,
    },
  })
);

const ImportTimeClockModal = (props: ImportTimeClockModalProps) => {
  const classes = useStyles({});
  const history = useHistory();
  const dispatch = useDispatch();
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [workingFile, setWorkingFile] = React.useState<any>(null);
  const [refreshPendingPunchesData, setRefreshPendingPunchesData] = useState<boolean>(false)
  const [timeclockLogs, setTimeclockLogs] = React.useState<any[]>([]);

  const [refreshPolicyGrid, setRefreshPolicyGrid] = React.useState<any>(null);
  const [modalSettings, setModalSettings] = React.useState<any>({
    isOpen: false,
  });

  const [isConfirmed, setIsConfirmed] = React.useState<any>(false);

  const handleClose = () => {
    setModalSettings({ isOpen: false });
  };

  React.useEffect(() => {
    setModalSettings(props.modalProps);
  }, [props.modalProps]);

  React.useEffect(() => {
    setInterval(() => {
      setRefreshPolicyGrid({ refresh: true });
      
    }, 3000);
  }, []);


  useEffect(() => {
    if ( modalSettings.isOpen || refreshPendingPunchesData){
      getStudentWithPendingPunches();
      setRefreshPendingPunchesData(false);
    }
  }, [modalSettings.isOpen, refreshPendingPunchesData])

  const [studentsWithPendingPunches, setStudentsWithPendingPunches] = useState<Array<IStatusInfoRow>>([]);
  const getStudentWithPendingPunches = () => {
    attendanceApi.GetStudentWithPendingPunches(userSelectedCampus).then((response) => {
      if (response.data.resultStatus !== 0) {
        setLoading(false);
        setSnackBarProps((props: any) => {
          return {
            ...props,
            variant: "error",
            messageInfo: response.data.resultMessage,
            showSnackBar: true,
          };
        });
    }
    setStudentsWithPendingPunches(response.data.result)
    setLoading(false);
if ( response.data.result && response.data.result.length === 0)
{
  dispatch({ type: userConstants.SESSION_PERSISTENT_NOTIFICATION_REMOVED, notifications: [response.data.result.result] })

}
else{
    notificationsApi.GetPersistentNotications(userSelectedCampus).then((response:any) => {
        if ( response && response.data && response.data.result){
            dispatch({ type: userConstants.SESSION_PERSISTENT_NOTIFICATION_RECEIVED, notifications: response.data.result.result })
        }
    })

}

  })
  }

  React.useEffect(() => {
    if (isConfirmed) {
      attendanceApi
        .importTimeClockFile(userSelectedCampus, workingFile, false)
        .then(
          (e: any) => {
            if (e.data.resultStatus !== 0) {
              setSnackBarProps((props: any) => {
                return {
                  ...props,
                  variant: "error",
                  messageInfo: e.data.result,
                  showSnackBar: true,
                };
              });

            }
            setRefreshPendingPunchesData(true);


            if ( props.history.location.pathname === "/attendance/1/postAttendance"){

            props.history.replace({
              ...props.location,
              search: props.location.search + "&refresh=true",
            });}
          },
          () => {}
        );

      setIsConfirmed(false);
      setSnackBarProps((props: any) => {
        return {
          ...props,
          variant: "success",
          messageInfo: "Began processing file.",
          showSnackBar: true,
        };
      });
    }
  }, [isConfirmed]);



  const handleFileImportConfirmationConfirm = () => {
    setIsConfirmed(true);
    setConfirmationPopupState({ ...confirmationPopupState, open: false });
  };

  const handleFileImportConfirmationCancel = () => {
    setConfirmationPopupState({ ...confirmationPopupState, open: false });
  };

  const [confirmationPopupState, setConfirmationPopupState] = React.useState<
    ConfirmationModalProps
  >({
    id: "simple-popup",
    confirmationText: "",
    open: false,
    title: "Import file",
    confirmationButton: "Import",
    onCancelEvent: handleFileImportConfirmationCancel,
    onConfirmEvent: handleFileImportConfirmationConfirm,
  });

  const logDetailsConfirm = () => {
    setLogDetailsPopupState({ ...logDetailsPopupState, open: false });
  };

  const logDetailsCancel = () => {
    setLogDetailsPopupState({ ...logDetailsPopupState, open: false });
  };

  const [logDetailsPopupState, setLogDetailsPopupState] = React.useState<
    ConfirmationModalProps
  >({
    id: "log-popup",
    confirmationText: "",
    open: false,
    title: "Log details",
    confirmationButton: "OK",
    onCancelEvent: logDetailsCancel,
    onConfirmEvent: logDetailsConfirm,
  });

  const handleDrop = (file: any) => {
    let f = file[0];
    let fileName = f.name;
    setWorkingFile(f);
    setConfirmationPopupState({
      ...confirmationPopupState,
      open: true,
      confirmationText:
        "Are you sure you want to import file :" + fileName + "?",
    });
  };

  const handleUpdateStatus = (e: any) => {
    setLoading(true);
    let selectedStudents = studentsWithPendingPunches.filter(p => p.selected === true).map(s => s.studentEnrollmentId);
    attendanceApi.postPendingPunchesForStudents(userSelectedCampus,selectedStudents ).then((response:any) => {
      
      if (response && response.data && response.data.resultStatus === 0){
        getStudentWithPendingPunches();
      }
    })
  };
  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );

  return (
    <React.Fragment>
      <div>
        <Portal>
          <CustomSnackbar
            variant={snackBarProps.variant}
            message={snackBarProps.messageInfo}
            open={snackBarProps.showSnackBar}
            onClose={(event?: React.SyntheticEvent, reason?: string) => {
              setSnackBarProps((props: any) => {
                return { ...props, showSnackBar: false };
              });
            }}
          ></CustomSnackbar>
        </Portal>
        <Dialog
          open={modalSettings.isOpen}
          onClose={handleClose}
          className={classes.root}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="md"
          disableBackdropClick
        >
          <DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
            Import Time Clock
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <DropzoneArea
              initialFiles={!!workingFile ? [workingFile] : []}
              acceptedFiles={[
                ".dat",
                ".DAT",
                "text/plain",
                ".000",
                ".001",
                ".002",
                ".003",
                ".004",
                ".005",
                ".006",
                ".007",
                ".008",
                ".009",
                ".010",
                ".011",
                ".012",
                ".013",
                ".014",
                ".015",
              ]}
              filesLimit={1}
              maxFileSize={6000000}
              showPreviewsInDropzone={false}
              dropzoneClass={classes.dropZone}
              onDrop={handleDrop}
              dropzoneText={
                "Drag and drop a file here or click to add a new file"
              }
            />
            {studentsWithPendingPunches && studentsWithPendingPunches.length > 0 && (
              <div className={classes.retuningLOAStudent}>
                	<Card className={classes.cardWrapper}>
                <CustomCardTitle title="Returning LOA Students" />
                <div className={classes.retuningLOAStudentContent}>
                <InfoStatusGrid data={studentsWithPendingPunches} setData={setStudentsWithPendingPunches} />
                <Grid container direction="row" spacing={4}>
                  <Grid
                    item
                    container
                    xs={3}
                    alignContent="flex-start"
                    alignItems="flex-start"
                    justify="flex-start"
                  >
                    <ProgressSaveButton
                      text="Update Status"
                      type="button"
                      onClick={handleUpdateStatus}
                      loading={loading}
                      disabled={false}
                      buttonClassName={classes.saveButton}
                    ></ProgressSaveButton>
                  </Grid>
                </Grid>
                </div>
                </Card>
              </div>
            )}
            <TimeClockLogGrid
              onShowClick={(row: any) => {
                setLogDetailsPopupState({
                  ...logDetailsPopupState,
                  confirmationText: row.message,
                  open: true,
                });
              }}
              refreshGrid={refreshPolicyGrid}
              setLogsData={setTimeclockLogs}
              logsData={timeclockLogs}
              setSnackBar={setSnackBarProps}
            ></TimeClockLogGrid>
          </DialogContent>
        </Dialog>
      </div>
      <ConfirmationModal {...confirmationPopupState} />
      <ConfirmationModal {...logDetailsPopupState} />
    </React.Fragment>
  );
};

export default withRouter(ImportTimeClockModal);
