import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { keys } from "../../constants/urlparameters.contants";
import { StudentSearchModel } from "../../interfaces/student/search/SearchModel";
import { StudentProfile } from "../../interfaces/student/profile/StudentProfile";
import * as actionTypes from "../../constants/actions.constants";
import * as studentSearchApi from "../../api/search/studentSearchApi";
import * as userCampusApi from "../../api/user/userCampusApi";
import { Enrollment } from "../../interfaces/student/academics/Enrollment";

const UrlParameterManager = (props: any) => {
	const dispatch = useDispatch();
	const loggedInUser = useSelector((state: any) => state.session.user);
	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);
	const userSelectedStudent = useSelector((state: any) =>
		state.userstate.getSelectedStudent(state.session.user.userId)
	);

	let urlParams = new URLSearchParams(props.history.location.search);
	let campusIdQueryId = keys.campusId;
	let reloadEnrollmentSelectorId = keys.reloadEnrollmentSelector;
	let studentIdQueryId = keys.studentId;
	let studentIdQueryString = urlParams.get(studentIdQueryId);
	let campusIdQueryString = urlParams.get(campusIdQueryId);
	let reloadEnrollmentSelector = urlParams.get(reloadEnrollmentSelectorId);
	const handleEnrollmentSelectorLoad = () => {
		if (!campusIdQueryString) {
			studentSearchApi.getMRU().then(
				(rv: any) => {
					//if no student is selected, select MRU student
					if (rv.result && rv.result.length > 0) {
						dispatchStudentSelect(rv.result[0]);
					} else {
						// no mru, then select first campus user has access to
						userCampusApi.getUserCampuses().then(
							(data: any) => {
								if (data && data.length > 0) {
									dispatch({
										type: actionTypes.userStateConstants
											.USER_STATE_SET_SELECTED_CAMPUS,
										data: {
											userId: loggedInUser.userId,
											selectedCampus: data[0].id,
										},
									});
								}
							},
							(e) => {}
						);
					}
				},
				(e: any) => {}
			);
		} else if (studentIdQueryString && campusIdQueryString)
			studentSearchApi
				.studentProfileSearch({
					StudentId: studentIdQueryString,
					Filter: studentIdQueryString,
					ShowAll: true,
					CampusId: campusIdQueryString,
					creditHoursOnly: false,
				} as StudentSearchModel)
				.then(
					(rv: any) => {
						if (rv.result.length > 0) {
							dispatchStudentSelect(rv.result[0]);
						}
					},
					(e) => {}
				);
	};
	//handle browser page load
	useEffect(() => {
		handleEnrollmentSelectorLoad();
	}, []);

	useEffect(() => {
		if (reloadEnrollmentSelector && reloadEnrollmentSelector === "true") {
			handleEnrollmentSelectorLoad();
			urlParams.delete(reloadEnrollmentSelectorId);
			props.history.replace({
				...props.location,
				search: urlParams.toString(),
			});
		}
	}, [reloadEnrollmentSelector]);

	//campus change / no student
	useEffect(() => {
		if (campusIdQueryString && !studentIdQueryString) {
			studentSearchApi.getMruOrDefaultForCampusUser(campusIdQueryString).then(
				(rv: any) => {
					if (rv.result && rv.result.length > 0) {
						dispatchStudentSelect(rv.result[0]);
					}
				},
				(e) => {}
			);
		}
	}, [campusIdQueryString]);

	//track student changes, get student in url if different than current and set state
	useEffect(() => {
		if (
			campusIdQueryString &&
			studentIdQueryString &&
			studentIdQueryString !== userSelectedStudent?.studentId
		) {
			studentSearchApi
				.studentProfileSearch({
					StudentId: studentIdQueryString,
					Filter: studentIdQueryString,
					ShowAll: true,
					CampusId: campusIdQueryString,
					creditHoursOnly: false,
				} as StudentSearchModel)
				.then(
					(rv: any) => {
						dispatchStudentSelect(rv.result[0]);
					},
					(e) => {}
				);
		}
	}, [studentIdQueryString]);

	//runs on every route click, populates campusId and studentId url parameters if they do not exist
	useEffect(() => {
		if (
			!campusIdQueryString &&
			userSelectedCampus &&
			!studentIdQueryString &&
			userSelectedStudent
		) {
			urlParams.set(campusIdQueryId, userSelectedCampus);
			urlParams.set(studentIdQueryId, userSelectedStudent.studentId);
			props.history.replace({
				...props.location,
				search: urlParams.toString(),
			});
		}
	}, [props.location.key]);

	const dispatchStudentSelect = (data: StudentProfile) => {
		let urlParams = new URLSearchParams(props.history.location.search);
		let studentIdQueryId = keys.studentId;
		let campusIdQueryId = keys.campusId;
		urlParams.set(
			campusIdQueryId,
			(data.enrollments as any)[0]?.campusId ?? (campusIdQueryString as any)
		);
		urlParams.set(studentIdQueryId, data.studentId as any);
		dispatch({
			type: actionTypes.userStateConstants.USER_STATE_SET_SELECTED_STUDENT,
			data: {
				userId: loggedInUser.userId,
				studentProfile: data,
			},
		});

		dispatch({
			type: actionTypes.studentProfileConstants.SESSION_SET_STUDENT_PROFILE,
			studentProfile: data,
		});

		dispatch({
			type: actionTypes.userStateConstants.USER_STATE_SET_SELECTED_CAMPUS,
			data: {
				userId: loggedInUser.userId,
				selectedCampus:
					(data.enrollments as any)[0]?.campusId ?? campusIdQueryString,
			},
		});

		props.history.replace({
			...props.location,
			search: urlParams.toString(),
		});
	};

	return <React.Fragment></React.Fragment>;
};

export default withRouter(UrlParameterManager);
