import { Action } from "redux";
import * as actionTypes from "../../constants/actions.constants";
import { Tag } from "../../interfaces/common/Tag";
import { ModuleTags } from "../../interfaces/common/ModuleTags";


const initialState = { moduleTags: [{ moduleId: "9", tags: [] }] as Array<ModuleTags> }


const tagReducer = (state = initialState, action: Action<any>) => {
    switch (action.type) {
        case actionTypes.tagConstants.ADD_MODULE_TAGS: {
            const actionData = (action as any).data as ModuleTags;
            return {
                ...state,
                moduleTags: state.moduleTags.map(mt => {
                    if (mt.moduleId === actionData.moduleId) {
                        return ({ ...actionData });
                    }

                    return mt;
                }),
            };
        }
        case actionTypes.tagConstants.TAG_CREATED: {
            let actionData = (action as any).data;

            return {
                ...state,
                moduleTags: state.moduleTags.map(mt => {
                    if (mt.moduleId === actionData.moduleId) {
                        var index = mt.tags.findIndex(x => x.text.toLowerCase() == actionData.tag.text.toLowerCase())

                        if (index === -1) {
                            mt.tags = [...mt.tags, actionData.tag]
                        }

                    }

                    return mt;
                }),
            };
        }
        case actionTypes.tagConstants.TAG_DELETED: {
            let actionData = (action as any).data;

            return {
                ...state,
                moduleTags: state.moduleTags.map(mt => {
                    if (mt.moduleId === actionData.moduleId) {
                        mt.tags = mt.tags.filter(t => t.text !== (actionData.tag as Tag).text)
                    }

                    return mt;
                }),
            };
        }
        default: {
            return state;
        }
    }
};

export default tagReducer;
