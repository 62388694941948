export class OtherContact {
	constructor() {
		this.id = "";
		this.leadId = "";
		this.statusId = "";
		this.relationshipId = "";
		this.contactTypeId = "";
		this.prefixId = "";
		this.suffixId = "";
		this.firstName = "";
		this.lastName = "";
		this.middleName = "";
		this.comments = "";
		this.status = "";
		this.relationship = "";
		this.contactType = "";    
		this.prefix = "";
		this.suffix = "";
	}
	id?: string;
    leadId: string;
    statusId: string;
    relationshipId: string;
    contactTypeId: string;
    prefixId: string;
    suffixId: string;
    firstName: string;
    lastName: string;
    middleName: string;
    comments: string;
    status: string;
    relationship: string;
    contactType: string;    
    prefix: string;
    suffix: string;
}
