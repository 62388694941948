export enum SystemEnrollmentStatus {
    FutureStart = 7,
    NoStart = 8,
    CurrentlyAttending = 9,
    LeaveOfAbsence = 10,
    Suspension = 11,
    Dropped = 12,
    Graduated = 14,
    TransferOut = 19,
    AcademicProbation = 20,
    Externship = 22,
    DisciplinaryProbation = 23,
    WarningProbation = 24
}
