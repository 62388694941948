import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
    Dialog,
	DialogTitle,
	DialogContent,
	Portal
} from "@material-ui/core";
import { useForm, FormContext } from "react-hook-form";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SuspensionGrid from './SuspensionTable';
import SuspensionDetail from './SuspensionDetail';

import CustomSnackbar from "../../../components/notifications/CustomSnackbar";
import { CustomSnackBarProps } from "../../../interfaces/common/CustomSnackBarProps";

type SuspensionModalProps = {
	setOpen: any;
	open: boolean;
	updateEvent: any;
	newStatusCode: any;
	newStatus: any;
	statusChangeEnrollment: any;
};

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			"& .MuiPaper-rounded": {
				borderRadius: theme.spacing.borderradius,
			},
			"& .MuiDialogContent-root": {
				minHeight: 400,
			},
		},
		form: {
			display: 'flex',
			flexDirection: 'column',
			width: 'fit-content',
			minWidth: 100,
		  },
		dialogHeader: {
			backgroundColor: theme.palette.site.secondary,
			"& .MuiTypography-root": {
				fontSize: theme.spacing(2),
			},
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
		},
		historyWrapper: {
			padding: theme.spacing(.5),
		},
		detailWrapper: {
			padding: theme.spacing(.5),
		},
		spacer: {
			height: theme.spacing(1),
		},
	})
);

const SuspensionModal = (props: SuspensionModalProps) => {
	const classes = useStyles({});

	const [refreshFlag, setRefreshFlag] = React.useState<boolean>(false);
	
	const handleClose = () => {
		props.setOpen(false);
	};
	
	const handleStatusUpdateEvent = (newStatus: any) => {
		setRefreshFlag(!refreshFlag);
		props.updateEvent(newStatus);	
	};

	const data = useForm<any>({
		mode: "onBlur"
	  });

	  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
		{
			showSnackBar: false,
			messageInfo: undefined,
			variant: "info",
		}
	);

return (
    <FormContext {...data}>
        <form className={classes.form}>
            <div>
				<Portal><CustomSnackbar
					variant={snackBarProps.variant}
					message={snackBarProps.messageInfo}
					open={snackBarProps.showSnackBar}
					onClose={(event?: React.SyntheticEvent, reason?: string) => {
						setSnackBarProps((props: any) => {
						return { ...props, showSnackBar: false };
						});
					}}></CustomSnackbar></Portal>
                <Dialog
                    open={props.open}
                    onClose={handleClose}
                    className={classes.root}
                    aria-labelledby="form-dialog-title"
					fullWidth={true}
					maxWidth='lg'
					disableBackdropClick
                >
                    <DialogTitle
                        id="form-dialog-title"
                        className={classes.dialogHeader}
                    >
                        {"Suspension Details"}
						<IconButton 
							aria-label="close" 
							className={classes.closeButton} 
							onClick={handleClose}
						>
							<CloseIcon />
						</IconButton>
                    </DialogTitle>
                    <DialogContent>

					<div className={classes.detailWrapper}>
						{ (props.newStatusCode != null) ?
							<SuspensionDetail setSnackBarProps={setSnackBarProps} newStatusCode={props.newStatusCode} newStatus={props.newStatus} updateEvent={handleStatusUpdateEvent} /> 
							: null}
					</div>					
					<div className={classes.spacer}></div>
					<div className={classes.historyWrapper}>
						<SuspensionGrid setSnackBarProps={setSnackBarProps} updateEvent={handleStatusUpdateEvent} refreshFlag={refreshFlag} statusChangeEnrollment={props.statusChangeEnrollment} />
					</div>

                    </DialogContent>
                </Dialog>
            </div>
        </form>
    </FormContext>
)    
};

export default SuspensionModal;

