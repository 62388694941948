import * as React from 'react';
import { useEffect, useRef } from "react";
import { apiAutoCompleteConfig } from "../../constants/apiAutoComplete.config";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../constants/actions.constants";
import { FilterParams } from '../../interfaces/common/FilterParams';

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& label.Mui-focused": {
				color: theme.palette.primary.white
			},
			"& input": {
				color: theme.palette.black,
				opacity: 1,
				fontWeight: theme.typography.fontWeightMedium,
				fontSize: 14,
				padding: 10
			},
			"& input::placeholder": {
				fontWeight: theme.typography.fontWeightMedium,
				fontSize: 13
			},
			"& .MuiInputBase-root": {
				backgroundColor: theme.palette.site.secondary,
				borderRadius: 5
			}
		}
	})
)(TextField);

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {
			width: "95%"
		},
		searchIcon: {
			fontSize: 30,
			paddingLeft: 10,
			color: theme.palette.icon.primary,
			cursor: "pointer"
		}
	})
);

const UserTypeAutoComplete = (props: FilterParams) => {
	const classes = useStyles({});
	const dispatch = useDispatch();
    const [userTypeSelected, setUserTypeSelected] = React.useState(false);
	const userSelectedCampus = useSelector((state: any) =>
		state.userstate[state.session.user.userId]
			? state.userstate[state.session.user.userId].selectedCampus
			: null
	);
    const filterHandle = props.filterHandle;

    const inputRef: any = useRef(null);

	const magnifyOnClick = (e: any) => {
		if (inputRef.current !== undefined) {
			inputRef.current.children[1].focus();
		}
	};

	const userTypeOnChange = (e: any) => {

            if (filterHandle)
                filterHandle(e)

            setUserTypeSelected(e);

	};

	return (
		<div className={clsx(classes.divWidth)}>
			<ApiAutoComplete
				config={apiAutoCompleteConfig.usertype}
				inputName={props.name}
                classes={{
                    option: "text-black"
                }}
                label='User Type'
                onChange={(e: any) => {
                    userTypeOnChange(e);
                }}
                optionName={(option: any) => { if(option.value) return option.text }}
				multiple={false}
                valueFilter={
                    props.valueFilter
                        ? props.valueFilter
                        : undefined
                }
			></ApiAutoComplete>
		</div>
	);
};
export default UserTypeAutoComplete;
