import * as React from "react";
import AdvanceSearchVirtualizedTable from "./AdvanceSearchVirtualizedTable";

const AdvanceSearchResultsGrid = (props: any) => {
  return (
    <div>
      <AdvanceSearchVirtualizedTable
        advanceSearchModel={props.location.state}
        className={props.className}
        params={props.params}
      ></AdvanceSearchVirtualizedTable>
    </div>
  );
};
export default AdvanceSearchResultsGrid;
