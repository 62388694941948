import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Enrollment } from "../../../interfaces/student/academics/Enrollment";
import MenuOptionAutoComplete from "../../../components/AutoComplete/MenuOptionsAutoComplete";
import AdvantageViewer from "../../../components/Common/AdvantageViewer";
import { withRouter } from "react-router-dom";
import { Paper, Typography, CardContent } from "@material-ui/core";
import ComponentViewer from "./ComponentViewer";
import clsx from "clsx";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			width: "100%",
			height: "95%",
			// height: "calc(100% - 50px)",
			minHeight: "calc(100% - 70px)%",
			padding: theme.spacing(3),
			marginTop: theme.spacing(3),
			overflowX: "hidden",
			overflowY: "auto",
		},
		cardTitle: {
			fontSize: 17,
			color: theme.palette.black,
			textDecoration: "bold",
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1),
			marginBottom: theme.spacing(1),
		},
		headerText: {
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(0),
		},
		cardContent: {
			height: "calc(100% - 70px)",
			minHeight: "calc(100% - 70px)",
		},
		paperContent: {
			height: "100%",
		},
		contentWrapper: {
			marginTop: theme.spacing(1),
			height: "95%",
			background: theme.palette.white,
		},
	})
);

const SystemConfigurationViewer = (props: any) => {
	const { history } = props;
	const classes = useStyles({});
	const [menu, setMenu] = React.useState<any>();
	const handleMenuChange = (e: any) => {
		if (e) {
			setMenu(e);
			history.push(e.url);
		}
	};
	const selectedEnrollment: Enrollment = useSelector(
		(state: any) => state.student.selectedEnrollment
	);

	return (
		<Paper className={classes.root}>
			<Paper elevation={2} className={classes.paperContent}>
				<Typography className={clsx(classes.cardTitle)}>
					<div className={classes.headerText}>{props.title}</div>
				</Typography>
				<CardContent className={classes.cardContent}>
					<MenuOptionAutoComplete
						id="MenuOption"
						name="MenuOption"
						params={{ parentComponentName: props.parentComponentName }}
						filterHandle={(e: any) => {
							handleMenuChange(e);
						}}
						valueFilter={
							menu
								? {
										key: "value",
										values: [menu.value],
								  }
								: {
										key: "value",
										values: [props.location.pathname],
								  }
						}
					/>
					{
						<React.Fragment>
							{menu &&
								menu.legacyUrl != null &&
								menu.legacyUrl.indexOf(".aspx") > -1 && (
									<AdvantageViewer
										elevation={0}
										className={classes.contentWrapper}
										url={menu.legacyUrl + "?"}
										params={{
											resId: menu.resourceId,
											cmpId: selectedEnrollment?.campusId,
											mod: "",
										}}
									/>
								)}
							{menu && menu.legacyUrl == null && (
								<ComponentViewer
									component={menu.component}
									history={history}
								></ComponentViewer>
							)}
						</React.Fragment>
					}
				</CardContent>
			</Paper>
		</Paper>
	);
};

export default SystemConfigurationViewer;
