const placementEmployerJobs = {
    acId: "placementEmployerJobs",
    cacheTime: 5,
    route: "/Placement/EmployerJobs/GetJobsByCampus",
    requestType: "GET",
    body: null,
    hasUrlParams: true,
    useCache: false,
    storeInCache: false,
    isCampusSpecific: true,
    isUserSpecific: false,
    loadOnRender: true,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
      return data;
    }
  };

  export default placementEmployerJobs;
  