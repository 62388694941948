import API from "../apiWrapper";
import { IWidgetUpdate } from "../../interfaces/systemCatalog/IWidgetUpdate";

export const updateWidgetPositions = (data: IWidgetUpdate) => {
    return API()
        .post("/SystemCatalog/Widget/UpdateWidgetPositions", data)
        .then(
            (res: any) => {
                if (res.data) {
                    return res.data;
                }
            },
            (error: any) => {
                return error;
            }
        );
};
export const getUserWidgets = (campusId: string) => {
    return API()
        .get("/SystemCatalog/Widget/GetUserDashboardWidgets", {
            params: {
                campusId
            }
        })
        .then(
            (res: any) => {
                if (res.data) {
                    return res.data;
                }
            },
            (error: any) => {
                return error;
            }
        );
};
