import React from "react";
import moment from "moment";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import {
	Typography,
	Paper,
	CardContent,
	Grid,
	Button
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker
} from "@material-ui/pickers";
import ProgressSaveButton from "../../../components/_Layout/Buttons/ProgressSaveButton";
import * as GraduationApi from "../../../api/student/status/GraduationApi";
import { ApiResultStatus } from "../../../enums/ApiResultStatus";
import { SystemEnrollmentStatus } from "../../../enums/SystemEnrollmentStatus";
import { useFormContext, Controller } from "react-hook-form";

type GraduationProps = {
	setSnackBarProps: any;
	updateEvent: any;
	closeEvent: any;
	newPageEvent: any;
	statusChange: any;
	statusChangeEnrollment: any;
};

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.background.paper,
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2),
			},
		},
		paperRoot: {
			width: "100%",
		},
		cardTitle: {
			fontSize: theme.spacing(2),
			color: theme.palette.black,
			textDecoration: "bold",
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1)
		},
		cardContent: {
			padding: theme.spacing(1, 1)
		},
		datePicker: {
			"& .MuiIconButton-root": {
				padding: theme.spacing(0.8)
			}
		},
		actionContainer: {
			marginTop: theme.spacing(3)
		},
		button: {
			marginRight: theme.spacing(1),
			marginLeft: theme.spacing(1),
		},
	})
);

const Graduation = (props:
	GraduationProps) => {

	const classes = useStyles({});

	const data = useFormContext();

	const [changeEnrollmentData, setChangeEnrollmentData] = React.useState<any>(props.statusChangeEnrollment);
	const [loaderState, setLoaderState] = React.useState<boolean>(false);
	const [metRequirements, setMetRequirements] = React.useState<string | null>(null);

	const getTitle = () => {
		let title = "Graduate";

		return title;
	};

	const getMessage = () => {
		let msg;
		msg = "Enter Graduation Date";

		return msg;
	};

	const handleFieldOnChange = (fieldId: string, value: any) => {
		let updatedData = changeEnrollmentData;
		(updatedData as any)[fieldId] = value;
		setChangeEnrollmentData({ ...updatedData });
	};

	const handleClose = () => {
		props.closeEvent();
	};

	React.useEffect(() => {
		if (changeEnrollmentData && changeEnrollmentData.statusChangeStudentEnrollmentId) {
			GraduationApi.CheckStudentMeetingRequirements(changeEnrollmentData.statusChangeStudentEnrollmentId).then(
				(response: any) => {
					setMetRequirements(response?.data);
					setLoaderState(false);
				},
				(exception: any) => {
					setLoaderState(false);
					props.setSnackBarProps((snackBarProps: any) => {
						return {
							variant: "error",
							showSnackBar: true,
							messageInfo: exception,
						};
					});
				}
			)
		}
	}, [changeEnrollmentData])

	const handleGraduate = () => {
		data.triggerValidation().then((validation: any) => {
			if (validation) {
				setLoaderState(true);
				GraduationApi.Graduate(changeEnrollmentData, props.statusChange.statusCodeId).then(
					(response: any) => {
						handleSubmitResponse(response);
						setLoaderState(false);
					},
					(exception: any) => {
						setLoaderState(false);
						props.setSnackBarProps((snackBarProps: any) => {
							return {
								variant: "error",
								showSnackBar: true,
								messageInfo: exception,
							};
						});
					}
				)
			}
		});
	};

	const handleSubmitResponse = (response: any) => {
		if (response.data != undefined && response.data.resultStatus != undefined) {
			switch (response.data.resultStatus) {
				case ApiResultStatus.Success:
					props.setSnackBarProps((props: any) => {
						return {
							variant: "success",
							showSnackBar: true,
							messageInfo: response.data.resultStatusMessage
						};
					});
					props.updateEvent("Graduated");
					props.newPageEvent("/student/1/profile/enrollments")
					break;
				case ApiResultStatus.Warning:
					props.setSnackBarProps((props: any) => {
						return {
							variant: "warning",
							showSnackBar: true,
							messageInfo: response.data.resultStatusMessage
						};
					});
					break;
				case ApiResultStatus.Error:
					props.setSnackBarProps((props: any) => {
						return {
							variant: "error",
							showSnackBar: true,
							messageInfo: response.data.resultStatusMessage
						};
					});
					break;
				default:
			}
		}
		else {
			props.setSnackBarProps((props: any) => {
				return {
					variant: "error",
					showSnackBar: true,
					messageInfo: "Error changing status to graduated."
				};
			});
		}
	}

	return (

		<Paper className={classes.paperRoot}>
			{
				(metRequirements == null || metRequirements == "Yes") ?
					<div>
						<Typography className={classes.cardTitle} >
							{getTitle()}
						</Typography>
						<CardContent className={classes.cardContent}>
							<Grid container direction="row" spacing={2}>
								<Grid item xs={12}>
									<Typography>
										{getMessage()}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<KeyboardDatePicker
											disableToolbar
											variant="inline"
											format="MM/dd/yyyy"
											id="effectiveDate"
											label="Graduation Date"
											fullWidth
											autoOk={true}
											required
											KeyboardButtonProps={{
												"aria-label": "effective date"
											}}
											value={
												changeEnrollmentData.effectiveDate
													? new Date(changeEnrollmentData.effectiveDate).toLocaleDateString()
													: null
											}
											error={!!data.errors["effectiveDate"]}
											innerRef={(ref: any) => {
												data.register("effectiveDate", { required: true });
												data.setValue("effectiveDate", changeEnrollmentData.effectiveDate);
											}}
											onChange={(val: any) => {
												handleFieldOnChange("effectiveDate", val);
											}}
										/>
									</MuiPickersUtilsProvider>
								</Grid>
								<Grid item xs={12}>
									<div className={classes.actionContainer} id="graduationActions">
										<ProgressSaveButton
											text="Save"
											onClick={() => {
												handleGraduate();
											}}
											loading={loaderState}
										></ProgressSaveButton>
										<Button
											onClick={handleClose}
											color="secondary"
											variant="contained"
											className={classes.button}
										>Cancel</Button>
									</div>
								</Grid>
							</Grid>

						</CardContent>
					</div>
					:
					<div>
						<Typography className={classes.cardTitle} >
							Graduation Requirements
						</Typography>
						<CardContent className={classes.cardContent}>
							<div>
								This student does not meet the requirements to graduate. Are you sure you would like to override?
							</div>
							<Grid item xs={12}>
								<div className={classes.actionContainer} id="graduationActions">
									<ProgressSaveButton
										text="Yes"
										onClick={() => {
											setMetRequirements("Yes");
										}}
										loading={loaderState}
									></ProgressSaveButton>
									<Button
										onClick={handleClose}
										color="secondary"
										variant="contained"
										className={classes.button}
									>Cancel</Button>
								</div>
							</Grid>
						</CardContent>
					</div>
			}

		</Paper>

	);
};

export default Graduation;
