export const StatusInSchoolApiAutoComplete = {
  config: {
    acId: "inSchoolStatus",
    cacheTime: 5,
    route: "/SystemCatalog/StatusCodes/GetInSchoolStatusByCampus",
    requestType: "GET",
    body: null,
    hasUrlParams: true,
    useCache: false,
    storeInCache: false,
    isCampusSpecific: true,
    isUserSpecific: false,
    loadOnRender: true,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
      return data;
    },
  },
};
