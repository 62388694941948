import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { CardContent } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  root: {},
  content: {
    padding: theme.spacing(0),
  },
}));

interface ReportParametersProps {
  parameters: React.ReactNode;
  className?: string;
}

const ReportParameters = (props: ReportParametersProps) => {
  const { parameters, className, ...rest } = props;
  const classes = useStyles();
  return <CardContent className={classes.content}>{parameters}</CardContent>;
};
export default ReportParameters;
