import { EmptyGuid } from "../../../../../../utils/constants";

export interface IScheduleDetails {
  scheduleDetailId: string;
  timein?: Date | null;
  lunchout?: Date | null;
  lunchin?: Date | null;
  timeout?: Date | null;
  maximumHoursWithoutLunch?: number; //new
  total?: number;
  allowEarlyin: boolean;
  allowExtraHours: boolean;
  checkTardyin: boolean;
  maxBeforeTardy?: Date | null;
  tardyIntime?: Date | null;
  minimumHoursToBePresent?: number;
  dayOfWeek: number;
  allowLateout: boolean;
  lunchLength?: number;
  maximumNumberOfLunch?: number;
  scheduleId: string;
  maximumHoursPerDay?: number;
}

export class ScheduleDetails implements IScheduleDetails {
  scheduleDetailId: string = EmptyGuid;
  timein?: Date | null = new Date();
  lunchout?: Date | null = new Date();
  lunchin?: Date | null = new Date();
  timeout?: Date | null = new Date();
  maximumHoursWithoutLunch?: number = 0;
  maximumHoursPerDay?: number = 24;
  total?: number = 0;
  allowEarlyin: boolean = false;
  allowExtraHours: boolean = false;
  checkTardyin: boolean = false;
  maxBeforeTardy?: Date | null = new Date();
  tardyIntime?: Date | null = new Date();
  minimumHoursToBePresent?: number = 0;
  dayOfWeek: number = 0;
  allowLateout: boolean = false;
  lunchLength?: number = 0;
  maximumNumberOfLunch?: number = 0;
  scheduleId: string = EmptyGuid;

  constructor(dw: number, dateInit?: Date) {
    this.dayOfWeek = dw;
    this.timein = dateInit ? dateInit : null;
    this.lunchout = dateInit ? dateInit : null;
    this.lunchin = dateInit ? dateInit : null;
    this.timeout = dateInit ? dateInit : null;
    this.maxBeforeTardy = dateInit ? dateInit : null;
    this.tardyIntime = dateInit ? dateInit : null;
  }
}

export interface IClassScheduleDetails {
  scheduleDetailId: string;
  timein?: Date | null;
  timeout?: Date | null;
  total?: number;
  dayOfWeek: number;
  classScheduleId: string;
}

export class ClassScheduleDetails implements IClassScheduleDetails {
  scheduleDetailId: string = EmptyGuid;
  timein?: Date | null = new Date();
  timeout?: Date | null = new Date();  
  total?: number = 0;  
  dayOfWeek: number = 0;  
  classScheduleId: string = EmptyGuid;

  constructor(dw: number, dateInit?: Date) {
    this.dayOfWeek = dw;
    this.timein = dateInit ? dateInit : null;
    this.timeout = dateInit ? dateInit : null;
  }
}
