import API from "../apiWrapper";

export const getConfigSettingValue = (keyName: string, campusId: string) => {
	return API()
		.get(
			"/SystemCatalog/SystemConfigurationAppSetting/GetAppSettingValueByCampus",
			{
				params: {
					keyName,
					campusId
				}
			}
		)
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};
