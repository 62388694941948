import API from "../../apiWrapper";
export const GetMinimumGradeRequired = (gradeSystemId: string) => {
  return API()
    .get("/SystemCatalog/GradeSystem/GetMinimumGrade", {
      params: {
        gradeSystemId
      }
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};
