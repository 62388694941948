import * as React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Header from './Header';
import Results from './Results';
import { Paper, Button, CardActions } from '@material-ui/core';
import CustomSnackbar from '../../../components/notifications/CustomSnackbar';
import { NavLink, Redirect } from 'react-router-dom';
import { actions as adHocDetailsActions, selectors as adHocDetailsSelectors } from '../../../store/reports/adhocReportDetails';
import { useSelector } from 'react-redux';
import { useActions } from '../../../store/utils';
import { system } from '../../../constants/system/systemConstants';
const useStyles = makeStyles((theme: any) => ({
    root: {
        paddingTop: theme.spacing(2),
        minHeight: "100%",
        height: "100%",
        width: "100%",
        margin: "auto"
    },
    paperRoot: {
        padding: theme.spacing(2),
        width: "100%",
        minHeight: "100%",
        height: "100%",
        overflowX: "hidden",
        overflowY: "auto",
        flexDirection: "column",
    },
    results: {
        marginTop: theme.spacing(3),
        height: '100%',
        width: '100%'
    },
    backButton:
    {
        marginBottom: theme.spacing(1)
    },
    actions: {
        paddingLeft: theme.spacing(0),
        paddingTop: theme.spacing(1),
        justifyContent: 'flex-start'
    },
    newReportButton: {
        padding: theme.spacing(1),
        float: 'right'
    }
}));

type AdhocFilters = {
    categoryTagId?: Array<string>,

}

const CustomRouterLink = React.forwardRef((props: any, ref: React.Ref<HTMLDivElement>) => (
    <div ref={ref}>
        <NavLink to={props.href ? props.href : "/"} {...props} />
    </div>
));

const AdhocList = () => {
    const classes = useStyles({});
    const notification = useSelector(adHocDetailsSelectors.notification);
    const actions = useActions({
        hideNotification: adHocDetailsActions.hideNotification
    }, []);

    const appSettings = useSelector((state: any) => {
        return state.settings.settings;
    });

    const newManageAdhocSetting = appSettings[
        system.settings.enableNewManageAdhocFlow
    ] as string;
    const getNewManageAdhocSetting = () => {

        switch (newManageAdhocSetting) {
            case 'New':
                return 1;
            case 'Existing':
                return 2;
            case 'Both':
                return 3;
            default:
                return 0;
        }
    };
    const adHocSettings = getNewManageAdhocSetting();
    if (!!appSettings && !!newManageAdhocSetting && adHocSettings !== 1 && adHocSettings !== 3) {
        return <Redirect to={'/reports'} />
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paperRoot}>
                <div>
                    {notification && <CustomSnackbar
                        open={notification.showSnackBar}
                        message={notification.messageInfo}
                        variant={notification.variant}
                        onClose={actions.hideNotification} />}
                    <Header />
                    <Results
                        className={classes.results}

                    />
                    <CardActions className={classes.actions}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.newReportButton}
                            component={CustomRouterLink}
                            exact
                            to={"/reports/adhoc/newAdhocReport"}>
                            New Report
                        </Button>
                    </CardActions>
                    {notification && <CustomSnackbar open={notification.showSnackBar} message={notification.messageInfo} variant={notification.variant} onClose={actions.hideNotification} />}
                </div>
            </Paper>
        </div>

    );
};

export default AdhocList;
