import {
  CustomHeadCell,
  Order,
} from "../../interfaces/common/Table/CustomHeadCell";
import TableHead from "@material-ui/core/TableHead";
import {
  TableRow,
  TableCell,
  TableSortLabel,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import React, { Fragment } from "react";
import clsx from "clsx";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.site.secondary,
    },
    gridCellTypeXsmall: {
      width: "10%",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(0),
      whiteSpace: "nowrap",
    },
    gridCellTypeSmall: {
      width: "15%",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(0),
      whiteSpace: "nowrap",
    },
    gridCellTypeMedium: {
      width: "25%",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(0),
      whiteSpace: "nowrap",
    },
    gridCellTypeLarge: {
      width: "35%",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(0),
      whiteSpace: "nowrap",
    },
    gridCellTypeXLarge: {
      width: "45%",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(0),
      whiteSpace: "nowrap",
    },
    gridCellTypeRemainder: {
      width: "100%",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(0),
    },
    gridCellTypeOptions: {
      width: "50px",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(0),
      whiteSpace: "nowrap",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    headerHidden: {
      lineHeight: "0px",
      height: "0px",
      padding: 0,
    },
    alignCenter:{
      textAlign:"center",
    },
  })
);

type CustomTableHeaderProps<T extends object> = {
  cells: CustomHeadCell<T>[];
  defaultOrder: Order;
  defaultOrderBy: keyof T;
  headerVisible?: false;
  setOrder:(order: Order) => void,
  setOrderBy:(order: keyof T) => void,
};

const CustomTableHeader = <T extends object>(
  props: CustomTableHeaderProps<T>
) => {
  const classes = useStyles({});

  const {setOrder,setOrderBy, defaultOrder, defaultOrderBy } = {...props}

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T
  ) => {
    const isDesc = defaultOrderBy === property && defaultOrder === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  return (
    <TableHead className={classes.root}>
      <TableRow>
        {props.cells.map(
          (cell) =>
            !cell.isHidden && (
              <TableCell
                key={cell.id.toString()}
                padding={cell.disablePadding ? "none" : "default"}
                sortDirection={defaultOrderBy === cell.id ? defaultOrder : false}
                className={clsx(
                  cell.widthType == "XS"
                      ? classes.gridCellTypeXsmall
                      : cell.widthType == "S"
                      ? classes.gridCellTypeSmall
                      : cell.widthType == "M"
                      ? classes.gridCellTypeMedium
                      : cell.widthType == "L"
                      ? classes.gridCellTypeLarge
                      : cell.widthType == "XL"
                      ? classes.gridCellTypeXLarge
                      : cell.widthType == "Remainder"
                      ? classes.gridCellTypeRemainder
                      : classes.gridCellTypeOptions
                    , props.headerVisible === undefined ?   "" : classes.headerHidden
                    , cell.alignCenter === true ?   classes.alignCenter : "")
                }
              >
                {props.headerVisible === undefined && !cell.disableSort && (
                  <TableSortLabel
                    active={defaultOrderBy === cell.id.toString()}
                    direction={defaultOrder}
                    onClick={(e: any) => {
                      handleRequestSort(e, cell.id);
                    }}
                  >
                    <Fragment>
                      {cell.label}
                      {defaultOrderBy === cell.id.toString() ? (
                        <span className={classes.visuallyHidden}>
                          {defaultOrder === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </Fragment>
                  </TableSortLabel>
                )}

                {props.headerVisible === undefined && cell.disableSort && (
                  <Fragment>{cell.label}</Fragment>
                )}
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHeader;
