export const InstructorApiAutoComplete = {
	config: {
		acId: "instructor",
		cacheTime: 5,
		route: "/SystemCatalog/User/GetInstructorByCampus",
		requestType: "GET",
		body: null,
		hasUrlParams: true,
		useCache: false,
		storeInCache: false,
		isCampusSpecific: true,
		isUserSpecific: false,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
};
