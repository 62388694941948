import React from 'react';
import {
    makeStyles,
    createStyles,
    Grid,
    CardActions,
    Paper,
    Table,
    withStyles,
    TableCell,
    TableRow,
    TableContainer,
    TableHead,
    TableBody,
    IconButton,
    CircularProgress,
    Button,
    Card,
    CardContent,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { format, addDays } from "date-fns";
import  IGradeSystem  from "../../../../interfaces/setup/grades/ComponentTypes/IGradeSystem";
import * as componentTypeApi from "../../../../api/setup/grades/componentTypesApi";
import InputField from '../../../../components/_Layout/Inputs/InputField';
import CustomCardTitle from '../../../../interfaces/common/card/CustomCardTitle';
import GradeDetailDescriptionDetail from './GradeDetailDescriptionDetail';
import { EmptyGuid } from '../../../../utils/constants';
import { CustomSnackBarProps } from "../../../../interfaces/common/CustomSnackBarProps";
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";

const StyledTableCell = withStyles((theme: any) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.site.secondary,
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            textAlign: 'left',
            boxShadow: '1px 1px 0px 0px rgba(0,0,0,0.2)',
            '&:first-child': {
                width: '42px',
                padding: theme.spacing(0, 1)
            }
        },
        body: {
            fontSize: 16,
            color: theme.palette.black,
            fontWeight: 400,
            cursor: 'pointer',
            textAlign: 'left',
            '&:first-child': {
                width: '42px',
                padding: theme.spacing(0, 1)
            }
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: any) =>
    createStyles({
        root: {
            '&:nth-of-type(even)': {
                backgroundColor: theme.palette.site.secondary,
            },
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.white,
            },
            '&:hover': {
                backgroundColor: theme.palette.site.secondary,
            }
        },
        body: {
            cursor: 'pointer'
        }
    }),
)(TableRow);

const useStyles = makeStyles((theme: any) =>
    createStyles({
        cardTitle: {
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            backgroundColor: theme.palette.site.secondary,
            padding: theme.spacing(0)
        },
        headerText: {
            float: 'left',
            padding: theme.spacing(1.5),
        },
        cardContent: {
            padding: theme.spacing(1, 3),
            height: '100%',
        },
        saveButton: {
            width: 90,
        },
        tableContainer: {
            maxHeight: '400px'
        },
        table: {
            width: '100%',
        },
        iconColor: {
            color: theme.palette.black
        },
        searchField: {
            '&::placeholder': {
                color: theme.palette.black
            }
        },
        cardMain: {
            marginTop: theme.spacing(2)
          },
        selectionCheckBox: {
            padding: theme.spacing(0)
        },
        preLoaderCell: {
            textAlign: 'center',
            padding: theme.spacing(1)
        },
        dialogCloseButton: {
            float: "right",
        },
        selectionHeaderCell: {
            height: 40
        },
        paperRoot: {
            width: "100%",
            minHeight: "100%",
            height: "100%",
            overflowX: "hidden",
            overflowY: "auto",
            flexDirection: "column",
          },
        courseSelectionCell: {
            color: theme.palette.paperSummarySchedulerTitle,
            cursor: 'pointer',
            fontWeight: 500,
            height: 25
        }
    })
);
type GradeSystemProps = {
    grdSystemId?: string;
    campusId?: string;
    repeatedExamPolicy?: string;
    showSnackBar: (param: CustomSnackBarProps) => void;
    setConfirmationDialog: (param: { onOk?: () => (void | Promise<void>); open: boolean; onCancel?: () => (void | Promise<void>); }) => void;
};

const GradeDescriptionDetail = (
    props: Readonly<GradeSystemProps>
) => {
    const classes = useStyles({});
    const [searchText, setSearchText] = React.useState<string>('');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [grdDescription, setGrdDescription] = React.useState<IGradeSystem[]>([]);
    const [selectedDescriptionDetail, selectDescriptionDetail] = React.useState<IGradeSystem>({ grdSystemId: EmptyGuid });
    const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );
    const toDisplay = searchText
        && searchText.length > 2
        ? grdDescription.filter((grdBk) => grdBk.grdSystemId === searchText
            || (grdBk?.descrip ?? '').toLocaleLowerCase().includes(searchText.toLocaleLowerCase()))
        : grdDescription;
    
  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );


    const loadDescriptionDetail = async () => {
      setIsLoading(true);
      if(userSelectedCampus!=null && userSelectedCampus!= undefined)
      {
        componentTypeApi.getGradeSystem(userSelectedCampus).then(
          (response: any) => {
            if (response && response.data) {
              setGrdDescription(response.data);
            }
          },
          (exception: any) => { 
            props.showSnackBar({
              variant: "error",
              showSnackBar: true,
              messageInfo: (exception as Error).message,
          });
  
  
          }
          );

      }
      
        setIsLoading(false);
        
    }

    React.useEffect(() => {
        loadDescriptionDetail();
    }, [props.grdSystemId,userSelectedCampus]);

    return (
        <React.Fragment>
        <div className={classes.paperRoot}>
          <CustomSnackbar
            variant={snackBarProps.variant}
            message={snackBarProps.messageInfo}
            open={snackBarProps.showSnackBar}
            onClose={(event?: React.SyntheticEvent, reason?: string) => {
              setSnackBarProps((props: any) => {
                return { ...props, showSnackBar: false };
              });
            }}
          ></CustomSnackbar>
          <Card square={true} elevation={3} className={classes.cardMain}>
          <CustomCardTitle title='Description Detail'></CustomCardTitle>
          <CardActions>
          <Grid item xs={12} sm={12} md={12}>
                        <Grid container direction="column" spacing={2} wrap="nowrap">
                            <Grid
                                item
                                alignContent="flex-start"
                                alignItems="flex-start"
                                justify="flex-start"
                            >
                                <InputField
                                    value={searchText ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
                                    inputProps={{ className: classes.searchField }}
                                    InputProps={
                                        {
                                            startAdornment: <SearchIcon className={classes.iconColor} />,
                                            endAdornment: searchText && <IconButton onClick={() => setSearchText('')}><CloseIcon className={classes.iconColor} /></IconButton>
                                        }
                                    }
                                    placeholder={'Search Grade System'} />
                            </Grid>
                            <Grid
                                item
                                alignContent="flex-start"
                                alignItems="flex-start"
                                justify="flex-start"
                            >
                                <TableContainer component={Paper} square className={classes.tableContainer}>
                                    <Table stickyHeader size='small' className={classes.table} aria-label='customized table'>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell className={classes.selectionHeaderCell} colSpan={4} >Grade System</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {isLoading ?
                                                <StyledTableRow>
                                                    <StyledTableCell colSpan={4} className={classes.preLoaderCell}>
                                                        <CircularProgress />
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                                : toDisplay.map((grdBk, index) => (
                                                    <StyledTableRow key={`row-${index}`}>
                                                        <StyledTableCell colSpan={4} key={`description-${index}`} className={classes.courseSelectionCell} onClick={() => selectDescriptionDetail(grdBk)}>{grdBk.descrip}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid
                                item
                                alignContent='flex-start'
                                alignItems='flex-start'
                                justify='flex-start'
                            >
                            </Grid>
                        </Grid>
                    </Grid>

          </CardActions>
          <CardContent>
          <Grid container direction="column" spacing={2}>
          <GradeDetailDescriptionDetail setConfirmationDialog={props.setConfirmationDialog}
                            refreshList={loadDescriptionDetail}
                            showSnackBar={props.showSnackBar}
                            model={selectedDescriptionDetail}
                            campusId={props.campusId}
                            grdSystemId={props.grdSystemId}
                            repeatedExamPolicy={props.repeatedExamPolicy} />

          </Grid>
          </CardContent>
          </Card>
          </div>
    </React.Fragment>
      
    );
};

export default GradeDescriptionDetail;
