import {toasterConstants} from '../constants/actions.constants';
import IToasterOptions from '../interfaces/IToasterOptions';

   const showToaster = (message:string, variant:string = "info") => {
    return (dispatch:any) => {
        let data:IToasterOptions = {message, showToaster: true, variant};
      dispatch({ type: toasterConstants.SHOW_TOASTER, data });
    };
  }

  const hideToaster = () => {
    return (dispatch:any) => {
      dispatch({ type: toasterConstants.HIDE_TOASTER});
    };
  }

  export const toasterActions = {
      hideToaster,
      showToaster
  };