import * as React from "react";
import { useEffect, useRef } from "react";
import { apiAutoCompleteConfig } from "../../constants/apiAutoComplete.config";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../constants/actions.constants";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { createFilterOptions } from "@material-ui/lab";
import { Chip } from "@material-ui/core";

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& label.Mui-focused": {
        color: theme.palette.primary.white
      },
      "& input": {
        color: theme.palette.black,
        opacity: 1,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: 14,
        padding: 10
      },
      "& input::placeholder": {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: 13
      },
      "& .MuiInputBase-root": {
        backgroundColor: theme.palette.site.secondary,
        borderRadius: 5
      }
    }
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {
      width: "95%"
    },
    searchIcon: {
      fontSize: 30,
      paddingLeft: 10,
      color: theme.palette.icon.primary,
      cursor: "pointer"
    }
  })
);

const UserNameMultiCampusAutoComplete = (props: FilterParams) => {
  const classes = useStyles({});
  const [userNameSelected, setUserNameSelected] = React.useState(false);

  const filterHandle = props.filterHandle;

  const inputRef: any = useRef(null);

  const magnifyOnClick = (e: any) => {
    if (inputRef.current !== undefined) {
      inputRef.current.children[1].focus();
    }
  };

  const userNameOnChange = (e: any) => {
    if (filterHandle) filterHandle(e);

    setUserNameSelected(e);
  };
  const filterOptions = createFilterOptions({
    stringify: (option: any) => option.value.displayName
  });

  return (
    <div className={clsx(classes.divWidth)}>
      <ApiAutoComplete
        config={apiAutoCompleteConfig.usernameMultiCampus}
        classes={{
          option: "text-black"
        }}
        label={props.hideLabel ? '' : props.label ? props.label : "User Name"}
        onChange={(e: any) => {
          userNameOnChange(e);
        }}
        filterOptions={filterOptions}
        optionName={(option: any) => {
          if (option.value) return option.value.fullName + ' (' + option.value.displayName + ')';
        }}
        renderTags={(value: any, getTagProps) => {
          return value.map((option: any, index: number) => (
            <Chip
              size={props.chipSize ?? undefined}
              disabled={index === 0}
              label={(option.value && (option.value.fullName + ' (' + option.value.displayName + ')')) || ''}
              {...getTagProps({ index })}
            />
          ));
        }}
        inputName={props.name}
        multiple={props.multiple || false}
        reqParams={props.requestParameter}
        maxTags={props.maxTags}
        chipSize={props.chipSize}
        disabled={props.disabled}
        inputRef={props.inputRef}
        error={props.error}
        helperText={props.helperText}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
      ></ApiAutoComplete>
    </div>
  );
};
export default UserNameMultiCampusAutoComplete;
