import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Typography, Card, CardContent } from "@material-ui/core";
import DisplayField from "../DisplayField/DisplayField";
import * as quickInfoApi from "../../../api/student/quickInfo/quickInfoApi";
import { IEnrollmentCard } from "../../../interfaces/student/quickInfo/IEnrollmentCard";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		card: {
			margin: theme.spacing(1),
			marginTop: theme.spacing(2),
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingBottom: theme.spacing(1),
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2)
			}
		},
		cardTitle: {
			fontSize: 14,
			marginLeft: theme.spacing(1),
			marginTop: theme.spacing(1),
			color: theme.palette.text.secondary,
			fontWeight: theme.typography.fontWeightMedium
		}
	})
);

const EnrollmentsCard = (props: any) => {
	const { className } = props;
	const selectedEnrollment = useSelector(
		(state: any) => state.student.selectedEnrollment
	);
	const classes = useStyles({});
	const [enrollmentData, setEnrollmentData] = useState<IEnrollmentCard | null>(
		null
	);

	const GetEnrollmentInformation = () => {
		if (selectedEnrollment && selectedEnrollment.stuEnrollId)
			quickInfoApi
				.GetEnrollemntQuickInformation(selectedEnrollment.stuEnrollId)
				.then(response => {
					if (response) {
						setEnrollmentData(response.data.result);
					}
				});
	};

	useEffect(() => {
		GetEnrollmentInformation();
	}, [selectedEnrollment]);

	return (
		<Card className={classes.card} elevation={2}>
			<Typography className={clsx(classes.cardTitle)}><Link to={"/student/1/profile/enrollments"}>ENROLLMENT</Link></Typography>
			<CardContent>
				<DisplayField
					labelText="Start Date"
					valueText={
						enrollmentData && enrollmentData.startDate
							? new Date(enrollmentData.startDate).toLocaleDateString()
							: ""
					}
					fieldType="text"
				></DisplayField>

				<DisplayField
					labelText="Enrollment Date"
					valueText={
						enrollmentData && enrollmentData.enrollmentDate
							? new Date(enrollmentData.enrollmentDate).toLocaleDateString()
							: ""
					}
					fieldType="text"
				></DisplayField>

				<DisplayField
					labelText="Reenrollment Date"
					valueText={
						enrollmentData && enrollmentData.reEnrollmentDate
							? new Date(enrollmentData.reEnrollmentDate).toLocaleDateString()
							: ""
					}
					fieldType="text"
				></DisplayField>

				<DisplayField
					labelText="Graduation Date"
					valueText={
						enrollmentData && enrollmentData.graduationDate
							? new Date(enrollmentData.graduationDate).toLocaleDateString()
							: ""
					}
					fieldType="text"
				></DisplayField>

				<DisplayField
					labelText="Revised Graduation Date"
					valueText={
						enrollmentData && enrollmentData.revisedGraduationDate
							? new Date(
									enrollmentData.revisedGraduationDate
							  ).toLocaleDateString()
							: ""
					}
					fieldType="text"
				></DisplayField>

				<DisplayField
					labelText="Badge ID"
					valueText={enrollmentData?.badgeId}
					fieldType="text"
				></DisplayField>
			</CardContent>
		</Card>
	);
};

export default EnrollmentsCard;
