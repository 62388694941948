import * as React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";
import { useRef } from "react";
import clsx from "clsx";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {
      width: "95%",
    },
    fullWidth: { width: "100%",}
  })
);
const config: ApiAutoCompleteConfigItem = {
  acId: "programs",
  cacheTime: 5,
  route: "/SystemCatalog/Program/GetProgramByCampus",
  requestType: "GET",
  body: null,
  hasUrlParams: true,
  useCache: false,
  storeInCache: false,
  isCampusSpecific: true,
  isUserSpecific: false,
  loadOnRender: true,
  defaultSelectedFirstItem: false,
  optionsMappedFx: (data: Array<any>) => {
    return data;
  },
};

export interface ProgramsProps extends FilterParams {
  activePrograms?: boolean;
  includeCreditPrograms?: boolean;
}

const ProgramsAutoComplete = (props: ProgramsProps) => {
  const classes = useStyles({});
  const inputRef: any = useRef(null);
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const handleOnChange = (e: any) => {
    if (props.filterHandle) {
      props.filterHandle(e);
    }
  };
  const [disabled, setIsDisabled] = React.useState<any | null>();
  React.useEffect(() => {
    setIsDisabled(props.disabled);
  }, [props.disabled]);

  return (
    <div className={props.params?.fullWidth ? clsx(classes.fullWidth) : clsx(classes.divWidth)}>
      <ApiAutoComplete
        disabled={disabled}
        config={config}
        loading={userSelectedCampus ? false : true}
        reqParams={{
          campusId: props.campusId ? props.campusId : userSelectedCampus,
          activePrograms: props.activePrograms ? props.activePrograms : false,
          includeCreditPrograms: props.includeCreditPrograms ?? false,
        }}
        classes={{
          option: "text-black",
        }}
        label={props.label ? props.label : "Programs"}
        onChange={(e: any) => {
          handleOnChange(e);
        }}
        multiple={props.multiple || false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        inputRef={props.inputRef ? props.inputRef : inputRef}
        error={props.error}
        helperText={props.helperText}
        inputName={props.name}
        showIncludeAllValue={props.showIncludeAllValue}
				chipSize={props.chipSize}
        maxTags={props.maxTags}
        includeAllValue={props.includeAllValue ? props.includeAllValue: false}
      ></ApiAutoComplete>
    </div>
  );
};

export default ProgramsAutoComplete;
