import {
	Button,
	Card,
	colors,
	Divider,
	Fade,
	Input,
	Menu,
	MenuItem,
	TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import FavoriteIcon from "@material-ui/icons/Star";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import * as PropTypes from "prop-types";
import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as reportsApi from "../../../src/api/reports/allReports/reportsApi";
import { TagsColor } from "../../components/_Layout/TagManager/tagConfig";
import * as actionTypes from "../../constants/actions.constants";
import { ModuleTags } from "../../interfaces/common/ModuleTags";
import { Tag } from "../../interfaces/common/Tag";
import { Report } from "../../interfaces/reports/Report";
import ColoredChip from "../Chips/ColoredChip";
import { NavLink } from "react-router-dom";
import { system } from "../../constants/system/systemConstants";

const CustomRouterLink = React.forwardRef(
	(props: any, ref: React.Ref<HTMLDivElement>) => (
		<div ref={ref} style={{ flexGrow: 1 }}>
			<NavLink to={props.href ? props.href : "/"} {...props} />
		</div>
	)
);

const useStyles = makeStyles((theme: any) => ({
	root: {},
	keywords: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
	},
	searchIcon: {
		color: theme.palette.icon,
		marginRight: theme.spacing(2),
	},

	favoritesContainer: {
		marginLeft: "auto",
	},
	chips: {
		padding: theme.spacing(1),
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
	},
	chip: {
		margin: theme.spacing(1),
	},
	selects: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		backgroundColor: colors.grey[50],
		padding: theme.spacing(1),
	},
	favorites: {
		color: colors.blue[500],
	},
	selectAll: {
		marginLeft: "auto",
	},
	dividerColor: {
		backgroundColor: colors.grey[200],
	},
	noTransform: {
		"& ~ li": {
			transform: "none!important",
		},
		"& ~ [data-rbd-placeholder-context-id]": {
			display: "none!important",
		},
	},
	adhoc: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		backgroundColor: colors.grey[50],
		padding: theme.spacing(1),
	},
	deleteIcon: {
		color: theme.palette.white,
	},
	tagFilter: {
		minWidth: 150,
	},
	MAdhocRight: {
		marginLeft: "auto",
		display: 'flex'
	},
}));

type IFilterProps = {
	className: string;
	setChips: any;
	chips: any;
	favorites: Report[];
	setFilter: any;
	openReportViewer: (report: Report) => void;
};
const Filter = (props: IFilterProps) => {
	const {
		className,
		setChips,
		chips,
		favorites,
		setFilter,
		openReportViewer,
		...rest
	} = props;
	const classes = useStyles({});
	const moduleTags = useSelector((state: any) =>
		state.tag.moduleTags.find((t: any) => t.moduleId === "9")
	) as ModuleTags;
	const dispatch = useDispatch();

	const [inputValue, setInputValue] = useState("");
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const appSettings = useSelector((state: any) => {
		return state.settings.settings;
	});

	const getNewManageAdhocSetting = () => {
		const newManageAdhocSetting = appSettings[
			system.settings.enableNewManageAdhocFlow
		] as string;
		switch (newManageAdhocSetting) {
			case 'New':
				return 1;
			case 'Existing':
				return 2;
			case 'Both':
				return 3;
			default:
				return 0;
		}
	};

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const openReport = (report: Report) => {
		handleClose();
		openReportViewer(report);
	};
	const campusId = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);
	const manageAdhoc = {
		name: "adhoc",
		id: 0,
		description: "string",
		modules: [],
		tags: [],
		favorited: false,
		typeId: 0,
		resourceURL:
			"/Reports/SetupAdHocReports.aspx?resid=487&cmpid=" + campusId + "&mod=",
		recentlyUsed: false,
		reportClass: "",
		creationMethod: "",
	};
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.persist();
		setInputValue(event.target.value);
		setFilter(event.target.value);
	};

	const handleInputKeyup = (event: React.KeyboardEvent<HTMLInputElement>) => {
		event.persist();

		if (event.keyCode === 13) {
			setFilter(inputValue);
			//setInputValue('');
		}
	};

	const getReportTags = () => {
		return reportsApi.getReportTags().then(
			(response: any) => {
				if (response) {
					if (response.result.length > 0) {
						let data = response.result;
						let tags = data.map((rep: any) => {
							return {
								text: rep.text,
								id: rep.id,
								color: TagsColor[rep.text.toLowerCase().replace(/ +/g, "")],
							} as Tag;
						});

						return tags;
					}
				}
				return [];
			},
			(exception: any) => []
		);
	};

	React.useEffect(() => {
		getReportTags().then((r: Array<Tag>) => {
			setChips(r);
			dispatch({
				type: actionTypes.tagConstants.ADD_MODULE_TAGS,
				data: { moduleId: "9", tags: r },
			});
		});
	}, []);

	const handleMultiSelectChange = (value: any) => {
		setChips(value);
	};
	let menu = (
		<Menu
			id="fade-menu"
			anchorEl={anchorEl}
			keepMounted
			open={open}
			onClose={handleClose}
			TransitionComponent={Fade}
		>
			{favorites.map((report: Report) => {
				return (
					<MenuItem onClick={() => openReport(report)}>{report.name}</MenuItem>
				);
			})}
		</Menu>
	);

	const adHocSettings = getNewManageAdhocSetting();
	
	return (
		<Card {...rest} className={clsx(classes.root, className)}>
			<div className={classes.keywords}>
				<SearchIcon className={classes.searchIcon} />
				<Input
					disableUnderline
					onChange={handleInputChange}
					onKeyUp={handleInputKeyup}
					placeholder="Enter a keyword"
					value={inputValue}
				/>

				{menu}
				<Button
					color="primary"
					className={classes.favoritesContainer}
					onClick={handleClick}
				>
					<FavoriteIcon className={classes.favorites} />
					My Favorites
				</Button>
			</div>
			<Divider className={classes.dividerColor} />
			<div className={classes.selects}>
				<div className={classes.chips}>
					<Autocomplete
						className={classes.tagFilter}
						multiple={true}
						id="report-mainfilter-tags-input"
						options={moduleTags.tags?.sort((a, b) =>
							a.text.localeCompare(b.text)
						)}
						filterSelectedOptions={true}
						getOptionLabel={(option: Tag) => option.text}
						renderTags={(value: Tag[], getTagProps: any) => {
							return value.map((tag: Tag, index: number) => (
								<ColoredChip
									id={tag.text}
									className={clsx(classes.deleteIcon)}
									color={tag.color}
									label={tag.text}
									{...getTagProps({ index })}
								/>
							));
						}}
						onChange={(e: any, v: any) => {
							if (e.type !== "keydown") handleMultiSelectChange(v);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="standard"
								fullWidth
								label="Report Category"
							/>
						)}
					/>
				</div>
				<div className={classes.MAdhocRight}>
					
					{(adHocSettings === 2 || adHocSettings === 3) && (
						<Button
							color="primary"
							className={classes.adhoc}
							onClick={() => openReport(manageAdhoc)}
						>
							Manage AD HOC
						</Button>
					)}
					{(adHocSettings === 1 || adHocSettings === 3 ) && (
						<Button
							color="primary"
							className={classes.adhoc}
							component={CustomRouterLink}
							exact
							to={"/reports/adhoc"}
						>
							{(adHocSettings === 1 && 'Manage AD HOC') || 'New Ad Hoc'}
						</Button>
					)}
				</div>
			</div>
		</Card>
	);
};

Filter.propTypes = {
	className: PropTypes.string,
};

export default Filter;
