export class OtherAddress {
  constructor() {
    this.id = "";
    this.otherContactId = "";
    this.leadId = "";
    this.addressTypeId = "";
    this.addressType = ""; 
    this.address1 = ""; 
    this.address2 = ""; 
    this.city = ""; 
    this.stateId = "";
    this.zip = "";
    this.countryId = "";
    this.country = "";
    this.statusId = "";
    this.status = ""; 
    this.state = ""; 
    this.isInternational = false;
    this.countyId = ""; 
    this.county = "";
    this.isMailingAddress = false;
  }
  id: string; 
  otherContactId: string;
  leadId: string;
  addressTypeId: string;
  addressType: string; 
  address1: string; 
  address2?: string; 
  city?: string; 
  stateId?: string;
  zip?: string;
  countryId?: string;
  country?: string;
  statusId: string;
  status: string; 
  state?: string; 
  isInternational?: boolean;
  countyId?: string; 
  county?: string;
  isMailingAddress?: boolean;
}
