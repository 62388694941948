import API from '../../apiWrapper';
import { IBPPEReport } from '../../../interfaces/reports/bppe/IBPPEReport';
import { ISTRFReport } from '../../../interfaces/reports/bppe/IBPPEReport';
import { ReportOutput } from '../../../enums/ReportOutput';

export const generateReport = (
	params: IBPPEReport,
	isPreview: boolean = false
) => {
	return API()
		.post(
			'/Reports/BPPEandSTRF/BPPEReport',
			params,
			{
				responseType: 'blob',
			}
		)
		.then(
			(res: any) => {
				if (res && res.data) {
					if (isPreview && ReportOutput.Pdf === params.exportType) {
						return res.data;
					} else {
						const url = window.URL.createObjectURL(new Blob([res.data]));
						const link = document.createElement('a');
						let exportType: number = Number(params.exportType);
						let extension = '';
						switch (exportType) {
						case Number(ReportOutput.Pdf):
							extension = 'pdf';
							break;
						case Number(ReportOutput.Excel):
							extension = 'xls';
							break;
						case Number(ReportOutput.CSV):
							extension = 'csv';
							break;
						default:
							extension = 'pdf';
							break;
						}
						var fileName =
							params.reportType + 'Report' +
							'.' +
							extension;

						link.href = url;
						link.setAttribute('download', fileName);
						document.body.appendChild(link);
						link.click();
					}
				}
			},
			(error: any) => {
				return null;
			}
		);
};

export const generateSTRFReport = (
	params: ISTRFReport,
	isPreview: boolean = false
) => {
	return API()
		.post(
			'/Reports/BPPEandSTRF/STRFReport',
			params,
			{
				responseType: 'blob',
			}
		)
		.then(
			(res: any) => {
				if (res && res.data) {
					if (isPreview && ReportOutput.Pdf === params.exportType) {
						return res.data;
					} else {
						const url = window.URL.createObjectURL(new Blob([res.data]));
						const link = document.createElement('a');
						let exportType: number = Number(params.exportType);
						let extension = '';
						switch (exportType) {
						case Number(ReportOutput.Pdf):
							extension = 'pdf';
							break;
						case Number(ReportOutput.Excel):
							extension = 'xls';
							break;
						case Number(ReportOutput.CSV):
							extension = 'csv';
							break;
						default:
							extension = 'pdf';
							break;
						}
						var fileName =
							'STRFReport' +
							'.' +
							extension;

						link.href = url;
						link.setAttribute('download', fileName);
						document.body.appendChild(link);
						link.click();
					}
				}
			},
			(error: any) => {
				return null;
			}
		);
};