import * as React from "react";
import clsx from "clsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import {
  Typography,
  Card,
  CardContent,
  InputAdornment,
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useFormContext } from "react-hook-form";
import StatusAutoComplete from "../../../../../components/AutoComplete/StatusAutoComplete";
import RoleAutoComplete from "../../../../../components/AutoComplete/RoleAutoComplete";
import MultiCampusAutoComplete from "../../../../../components/AutoComplete/MultiCampusAutoComplete";
import UserTypeAutoComplete from "../../../../../components/AutoComplete/UserTypeAutoComplete";
import { User } from "../../../../../interfaces/user/User";
import { UserDetails } from "../../../../../interfaces/user/UserDetails";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    drawer: {
      width: 280,
    },
    root: {
      backgroundColor: theme.palette.paper.background,
      "& .MuiCardContent-root": {
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(2),
      },
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.paper.background,
    },
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
  })
);

const ThemeTextField = withStyles((theme: any) => createStyles({}))(TextField);

type IUserProfileProps = {
  user?: UserDetails;
  setUserDetails?: any;
};

const UserProfile = React.forwardRef((props: IUserProfileProps, ref) => {
  const classes = useStyles({});
  const user: UserDetails = props.user
    ? props.user
    : {
        fullName: "",
        displayName: "",
        email: "",
        accountActive: true,
        userStatusId: "",
        userTypeId: 0,
        isInstructor: false,
      };
  const setUserDetails = props.setUserDetails;
  const data = useFormContext();

  const watchFields = data.watch(["displayName", "email"]); // target specific fields by their names

  const appSettings = useSelector((state: any) => {
    return state.settings.settings;
  });

  const EnableClockHourCreditHour = appSettings.EnableClockHourCreditHour;

  React.useImperativeHandle(ref, () => ({
    getValues() {
      return watchFields;
    },
    validate() {
      data.triggerValidation();
    },
    errors() {
      return data.errors;
    },
  }));

  const handleStatusChange = (data: any) => {
    if (data) user.userStatusId = data.value;
    else user.userStatusId = "";

    setUserDetails(user);
  };
  const handleUserTypeChange = (data: any) => {
    if (data) user.userTypeId = data.value;
    else user.userTypeId = 0;
    setUserDetails(user);
  };

  const handleFullNameChange = (e: any) => {
    user.fullName = e.target.value;
    setUserDetails(user);
  };
  const handleDisplayNameChange = (e: any) => {
    user.displayName = e.target.value;
    setUserDetails(user);
  };
  const handleEmailChange = (e: any) => {
    user.email = e.target.value.trim();
    setUserDetails(user);
  };

  const handleInstructorCheck = (event: any) => {
    user.isInstructor = event.target.checked;
    setUserDetails(user);
  };

  React.useEffect(() => {
    if (user) {
      // you can do async server request and fill up form
      //data.reset({
      //    displayName: user.displayName,
      //    email: user.email,
      //    userStatusId: user.userStatusId,
      //    userTypeId: user.userTypeId
      //});
      data.setValue("fullName", user.fullName);
      data.setValue("displayName", user.displayName);
      data.setValue("email", user.email);
      data.setValue("userStatusId", user.userStatusId);
      data.setValue("userTypeId", user.userTypeId);
    }
    //triggerValidation();
  }, []);
  return (
    <div>
      <Card square={true}>
        <Typography className={clsx(classes.cardTitle)}>
          <div className={classes.headerText}>User Profile</div>
        </Typography>
        <CardContent className={classes.cardContent}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <ThemeTextField
                id="fullName"
                onBlur={handleFullNameChange}
                inputRef={data.register({ required: true })}
                name="fullName"
                className={classes.textField}
                label="Full Name *"
              />
              {data.errors.fullName && (
                <Typography color="error"> Full name is required </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <ThemeTextField
                id="displayName"
                onBlur={handleDisplayNameChange}
                inputRef={data.register({ required: true })}
                name="displayName"
                className={classes.textField}
                label="Display Name *"
              />
              {data.errors.displayName && (
                <Typography color="error">
                  {" "}
                  Display name is required{" "}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <ThemeTextField
                id="email"
                onBlur={handleEmailChange}
                inputRef={data.register({ required: true })}
                name="email"
                className={classes.textField}
                label="Email *"
              />
              {data.errors.email && (
                <Typography color="error"> Email is required </Typography>
              )}
            </Grid>

            <Grid item xs={4}>
              <StatusAutoComplete
                filterHandle={handleStatusChange}
                label="Status *"
                name="userStatusId"
                valueFilter={{
                  key: "value",
                  values: [user.userStatusId],
                }}
                inputRef={data.register({ required: true })}
              />
              {data.errors.userStatusId && (
                <Typography color="error"> Status is required </Typography>
              )}
            </Grid>

            <Grid item xs={4}>
              <UserTypeAutoComplete
                filterHandle={handleUserTypeChange}
                valueFilter={{
                  key: "value",
                  values: [user.userTypeId],
                }}
                name="userTypeId"
                inputRef={data.register({ required: true })}
              />
              {data.errors.userTypeId && (
                <Typography color="error"> User Type is required </Typography>
              )}
            </Grid>
            {EnableClockHourCreditHour == "True" || EnableClockHourCreditHour == "true" ? (
              <Grid item xs={4}>
                <FormControlLabel
                  key="isInstructor"
                  control={
                    <Checkbox
                      checked={user.isInstructor}
                      color="primary"
                      onClick={handleInstructorCheck}
                    />
                  }
                  label="Is Instructor"
                />
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
});

export default UserProfile;
