import API from "../../apiWrapper";
import { ReportOutput } from "../../../enums/ReportOutput";
import { IAttendanceSummaryReportParam } from "../../../interfaces/reports/attendance/AttendanceSummaryReportParam";
import { IClassAttendanceSummaryReportParam } from "../../../interfaces/reports/attendance/ClassAttendanceSummaryReportParam";
export const generateReport = (
    params: IAttendanceSummaryReportParam,
    isPreview: boolean = false
) => {
    return API()
        .post(
            "/AcademicRecords/Attendance/AttendanceSummary",
            params,
            {
                responseType: "blob",
            }
        )
        .then(
            (res: any) => {
                if (res && res.data) {
                    if (isPreview && ReportOutput.Pdf == params.exportType) {
                        return res.data;
                    } else {
                        const url = window.URL.createObjectURL(new Blob([res.data]));
                        const link = document.createElement("a");
                        let exportType: number = Number(params.exportType);
                        let extension = "";
                        switch (exportType) {
                            case Number(ReportOutput.Pdf):
                                extension = "pdf";
                                break;
                            case Number(ReportOutput.Excel):
                                extension = "xls";
                                break;
                            case Number(ReportOutput.Word):
                                extension = "doc";
                                break;
                            default:
                                extension = "pdf";
                                break;
                        }
                        var fileName =
                            "AttendanceSummaryReport" +
                            new Date()
                                .toISOString()
                                .replace("T", " ")
                                .replace(/\..*$/, "")
                                .split(" ")[0]
                                .split("-")
                                .join("") +
                            "." +
                            extension;

                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                    }
                }
            },
            (error: any) => {
                return null;
            }
        );
};

export const generateClassAttendanceSummaryReport = (
    reportParam:IClassAttendanceSummaryReportParam,
    isPreview: boolean = false
) => {
    return API()
        .post(
            `/AcademicRecords/Attendance/ClassAttendanceSummary`,
            reportParam,
            {
                responseType: "blob",
            }
        )
        .then(
            (res: any) => {
                if (res && res.data) {
                    if (isPreview && ReportOutput.Pdf == reportParam.exportType) {
                        return res.data;
                    } else {
                        const url = window.URL.createObjectURL(new Blob([res.data]));
                        const link = document.createElement("a");
                        let exportType = Number(reportParam.exportType);
                        let extension = "";
                        switch (exportType) {
                            case Number(ReportOutput.Pdf):
                                extension = "pdf";
                                break;
                            case Number(ReportOutput.Excel):
                                extension = "xls";
                                break;
                            case Number(ReportOutput.Word):
                                extension = "doc";
                                break;
                            default:
                                extension = "pdf";
                                break;
                        }
                        var fileName =
                            "ClassAttendanceSummary" +
                            new Date()
                                .toISOString()
                                .replace("T", " ")
                                .replace(/\..*$/, "")
                                .split(" ")[0]
                                .split("-")
                                .join("") +
                            "." +
                            extension;

                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                    }
                }
            },
            (error: any) => {
                return null;
            }
        );
};

export const generateStudentAttendanceReport = (
    stuEnrollId:string
) => {
    return API()
        .post(
            `/AcademicRecords/Attendance/StudentAttendanceReport?stuEnrollId=${stuEnrollId}`,
            null,
            {
                responseType: "blob",
            }
        )
        .then(
            (res: any) => {
                if (res && res.data) {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                        const link = document.createElement("a");
                        var fileName =
                            "StudentAttendanceReport" +
                            new Date()
                                .toISOString()
                                .replace("T", " ")
                                .replace(/\..*$/, "")
                                .split(" ")[0]
                                .split("-")
                                .join("") +
                            ".pdf";

                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                }
            },
            (error: any) => {
                return null;
            }
        );
};
