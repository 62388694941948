import { createReducer } from '../../utils';
import IIllinoisTranscriptReportSettings from '../../../interfaces/setup/reports/IIllinoisTranscriptReportSettings';
import { EmptyGuid } from '../../../utils/constants';
import IIllinoisTranscriptReportSettingsSubjectArea from '../../../interfaces/setup/reports/IIllinoisTranscriptReportSettingsSubjectArea';

const { reducer, actions, selectors, types } = createReducer(
	{
		sync: {
			selectConfiguration: (id?: string) => ({ id }),
			selectSubjectArea: (index?: number) => ({ index }),
			addSubjectArea: (subjectArea: IIllinoisTranscriptReportSettingsSubjectArea) => ({ subjectArea }),
			updateSubjectArea: (subjectArea: IIllinoisTranscriptReportSettingsSubjectArea, index: number) => ({ subjectArea, index }),
			deleteSubjectArea: (index: number) => ({ index }),
			removeFromDetails: (index: number) => ({ index }),
			closeSettings: () => (undefined),
			deleteComponents: () => (undefined),
			reset: () => (undefined)
		},
		asyncs: {
			fetchSettings: {
				request: (programVersionsId: string) => ({ programVersionsId }),
				success: (payload: Array<IIllinoisTranscriptReportSettings>) => ({ payload }),
				failure: (error: string) => ({ error })
			},
			fetchSettingsByCampus: {
				request: (campusId: string) => ({ campusId }),
				success: (payload: Array<IIllinoisTranscriptReportSettings>) => ({ payload }),
				failure: (error: string) => ({ error })
			},
			saveDetails: {
				request: (details: IIllinoisTranscriptReportSettings) => ({ details }),
				success: (message: string) => ({ message }),
				failure: (error: string) => ({ error })
			},
			deleteConfiguration: {
				request: (id: string) => ({ id }),
				success: (message: string) => ({ message }),
				failure: (error: string) => ({ error })
			}
		}
	},
	{
		programVersions: [] as Array<IIllinoisTranscriptReportSettings>,
		selectedConfiguration: undefined as string | undefined,
		selectedSubjectArea: undefined as number | undefined,
		subjectAreasToEdit: undefined as (Array<IIllinoisTranscriptReportSettingsSubjectArea> | undefined),
		subjectAreaDetails: undefined as (IIllinoisTranscriptReportSettingsSubjectArea | undefined),
		programVersionId: undefined as string | undefined,
		campusId: undefined as string | undefined,
		savingSuccess: false as boolean,
		savingError: undefined as string | undefined,
	},
	{
		sync: {
			deleteComponents: (state, _action) => ({
				...state,
				data: {
					...state.data,
					subjectAreasToEdit: !state.data.subjectAreasToEdit ? undefined : (state.data.subjectAreasToEdit).map(element => ({ ...element, subjectAreaComponents: [] }))
				}
			}),
			selectConfiguration: (state, { data: { id } }) => {
				const toSelect = id === EmptyGuid ? undefined : state.data.programVersions.find(pv => pv.reportConfigurationId === id);
				return ({ ...state, data: { ...state.data, programVersionId: toSelect?.prgVerId || state.data.programVersionId, selectedSubjectArea: undefined, selectedConfiguration: id, subjectAreasToEdit: (id === EmptyGuid ? [] : state.data.programVersions.find(pv => pv.reportConfigurationId === id)?.reportConfigurationSubjectAreas), subjectAreaDetails: id === state.data.selectedConfiguration ? state.data.subjectAreaDetails : undefined } });
			},
			selectSubjectArea: (state, { data: { index } }) => {
				return ({ ...state, data: { ...state.data, selectedSubjectArea: ((index || 0) < 0) || index === undefined ? index : (index || 0) + 1, subjectAreaDetails: index === -1 ? { subjectAreaId: EmptyGuid, subjectAreaComponents: [], reportConfigurationId: state.data.selectedConfiguration } : state.data.subjectAreasToEdit && index !== undefined ? state.data.subjectAreasToEdit[index || 0] : undefined } });
			},
			addSubjectArea: (state, { data: { subjectArea } }) => {
				return ({ ...state, data: { ...state.data, selectedSubjectArea: undefined, subjectAreaDetails: undefined, subjectAreasToEdit: [...state.data.subjectAreasToEdit || [], subjectArea] } });
			},
			updateSubjectArea: (state, { data: { subjectArea, index } }) => {
				return ({ ...state, data: { ...state.data, selectedSubjectArea: undefined, subjectAreaDetails: undefined, subjectAreasToEdit: [...state.data.subjectAreasToEdit!.slice(0, index - 1), subjectArea, ...state.data.subjectAreasToEdit!.slice(index)] } });
			},
			deleteSubjectArea: (state, { data: { index } }) => {
				return ({ ...state, data: { ...state.data, subjectAreaDetails: index === (state.data.selectedSubjectArea || 0) ? undefined : state.data.subjectAreaDetails, subjectAreasToEdit: [...state.data.subjectAreasToEdit!.slice(0, index - 1), ...state.data.subjectAreasToEdit!.slice(index)] } });
			},
			removeFromDetails: (state, { data: { index } }) => {
				return ({ ...state, data: { ...state.data, subjectAreaDetails: state.data.subjectAreaDetails ? { ...state.data.subjectAreaDetails, subjectAreaComponents: [...(state.data.subjectAreaDetails.subjectAreaComponents.slice(0, index)), ...(state.data.subjectAreaDetails.subjectAreaComponents.slice(index + 1))] } : undefined } });
			},
			reset: (state, _action) => ({
				...state, data: {
					programVersions: [] as Array<IIllinoisTranscriptReportSettings>,
					selectedConfiguration: undefined as string | undefined,
					selectedSubjectArea: undefined as number | undefined,
					subjectAreasToEdit: undefined as (Array<IIllinoisTranscriptReportSettingsSubjectArea> | undefined),
					subjectAreaDetails: undefined as (IIllinoisTranscriptReportSettingsSubjectArea | undefined),
					programVersionId: undefined as string | undefined,
					campusId: undefined as string | undefined,
					savingSuccess: false as boolean,
					savingError: undefined as string | undefined,
				}
			}),
			closeSettings: (state, _action) => (
				{
					...state, data: {
						...state.data,
						selectedConfiguration: undefined,
						selectedSubjectArea: undefined,
						subjectAreasToEdit: undefined,
						subjectAreaDetails: undefined,
						savingSuccess: false,
						savingError: undefined
					}
				}
			)
		},
		asyncs: {
			fetchSettings: {
				request: (state, action) => {
					return { ...state, data: { ...state.data, programVersionId: action.data.programVersionsId } };
				},
				success: (state, { data: { payload } }) => {
					return { ...state, data: { ...state.data, programVersions: payload } };
				},
				failure: (state, _action) => {
					return state;
				}
			},
			fetchSettingsByCampus: {
				request: (state, action) => {
					return { ...state, data: { ...state.data, campusId: action.data.campusId } };
				},
				success: (state, { data: { payload } }) => {
					return { ...state, data: { ...state.data, programVersions: payload } };
				},
				failure: (state, _action) => {
					return state;
				}
			},
			saveDetails: {
				request: (state, _action) => {
					return state;
				},
				success: (state, _action) => {
					return { ...state, data: { ...state.data, selectedConfiguration: undefined, selectedSubjectArea: undefined, subjectAreasToEdit: undefined, subjectAreaDetails: undefined } };
				},
				failure: (state, _action) => {
					return state;
				}
			},
			deleteConfiguration: {
				request: (state, _action) => state,
				success: (state, _action) => state,
				failure: (state, _action) => state
			},
		}
	},
	'illinoisTranscriptSettings',
	{
		selectProgramVersion: (state) => !state.loading.fetchSettings && state.data.selectedConfiguration,
		settings: (state) => !state.loading.fetchSettings && state.data.programVersions,
		loading: (state) => state.loading.fetchSettings || state.loading.fetchSettingsByCampus || state.loading.saveDetails || state.loading.deleteConfiguration,
		selectProgramVersionSubjectAreas: (state) => state.data.selectedConfiguration && (state.data.selectedConfiguration === EmptyGuid ? [] : state.data.programVersions.find(pv => pv.reportConfigurationId === state.data.selectedConfiguration)?.reportConfigurationSubjectAreas),
		programVersionDetails: (state) => state.data.selectedConfiguration && (state.data.selectedConfiguration === EmptyGuid ? undefined : state.data.programVersions.find(pv => pv.reportConfigurationId === state.data.selectedConfiguration)),
		selectSubjectArea: (state) => state.data.selectedSubjectArea,
		subjectAreasToEdit: (state) => state.data.subjectAreasToEdit,
		subjectAreaDetails: (state) => state.data.subjectAreaDetails,
		programVersionId: (state) => state.data.programVersionId,
		selectedCampus: (state) => state.data.campusId
	});

export { selectors, actions, types };

export default reducer;