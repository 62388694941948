import * as React from "react";
import { useEffect, useRef } from "react";
import { apiAutoCompleteConfig } from "../../constants/apiAutoComplete.config";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";
import { StudentGroupsApiAutoComplete } from "../../constants/SystemCatalog/StudentGroupsApiAutoComplete";

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& label.Mui-focused": {
        color: theme.palette.primary.white,
      },
      "& input": {
        color: theme.palette.black,
        opacity: 1,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: 14,
        padding: 10,
      },
      "& input::placeholder": {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: 13,
      },
      "& .MuiInputBase-root": {
        backgroundColor: theme.palette.site.secondary,
        borderRadius: 5,
      },
    },
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {
      width: "95%",
    },
    fullWidth: { width: "100%", },
    searchIcon: {
      fontSize: 30,
      paddingLeft: 10,
      color: theme.palette.icon.primary,
      cursor: "pointer",
    },
  })
);


const StudentGroupsAutoComplete = (props: FilterParams & { campusList?: string[] }) => {
  const classes = useStyles({});
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const studentGroupsOnChange = (e: any) => {
    if (props.filterHandle) {
      props.filterHandle(e);
    }
  };
  const [disabled, setIsDisabled] = React.useState<any | null>();
  const [campusId, setCampusId] = React.useState<string | undefined>(undefined);
  React.useEffect(() => {
    setIsDisabled(props.disabled);
    setCampusId(props.campusId);
  }, [props.disabled, props.campusId]);
  const inputRef: any = useRef(null);
  return (
    <div className={props.params?.fullWidth ? clsx(classes.fullWidth) : clsx(classes.divWidth)}>

      <ApiAutoComplete
        disabled={disabled}
        id={props.id}
        loading={userSelectedCampus ? false : true}
        config={props?.params?.disableCache ? { ...StudentGroupsApiAutoComplete.config, useCache: false, storeInCache: false, } : StudentGroupsApiAutoComplete.config}
        reqParams={{ 
          campusId: (campusId ? campusId : userSelectedCampus), isInactive: props?.params?.inActiveStudentGroup,
          campusList: props.campusList && !props.campusList.includes("All") ? props.campusList : undefined,
         }}
        classes={{
          option: "text-black",
        }}
        label={props.label ? props.label : "Groups"}
        placeholder={
          props.valueFilter &&
            props.valueFilter.values &&
            props.valueFilter.values.length > 0
            ? ""
            : "Select a group"
        }
        onChange={(e: any) => {
          studentGroupsOnChange(e);
        }}
        multiple={props.multiple !== undefined && props.multiple != null ? props.multiple : true}
        includeAllValue={props.includeAllValue ? props.includeAllValue : false}
        showIncludeAllValue={props.showIncludeAllValue ? props.showIncludeAllValue : false}
        defaultOptionText={props.defaultOptionText ? props.defaultOptionText : undefined}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        inputRef={props.inputRef ? props.inputRef : inputRef}
        error={props.error}
        helperText={props.helperText}
        inputName={props.name}
        chipSize={props.chipSize}
        maxTags={props.maxTags}
      ></ApiAutoComplete>
    </div>
  );
};
export default StudentGroupsAutoComplete;
