import { Button, Card, CardActions, CircularProgress, createStyles, Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme } from '@material-ui/core';
import React from 'react';
import IIllinoisReportConfigurationProps from '../../../../../../interfaces/setup/academics/ProgramProgramVersion/IIllinoisReportConfigurationProps';
import { useActions } from '../../../../../../store/utils';
import { actions as IllinoisTranscriptSettingsActions, selectors as IllinoisTranscriptSettingsSelectors } from '../../../../../../store/setup/reports/illinoisTranscriptSettings';
import { useSelector } from 'react-redux';
import IIllinoisTranscriptReportSettings from '../../../../../../interfaces/setup/reports/IIllinoisTranscriptReportSettings';
import { EmptyGuid } from '../../../../../../utils/constants';
import ProgramVersionSettingDetails from './ProgramVersionSettingsDetails';
import UnsaveConfirmPopUp from '../../../../Reports/Components/IllinoisTranscript/UnsaveConfirmPopUp';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		cardBody: {
			minHeight: theme.spacing(20),
			padding: theme.spacing(2)
		},
		selectorCell: {
			cursor: 'pointer',
		}
	})
);


const IllinoisTranscriptSettings: React.FC<IIllinoisReportConfigurationProps> = ({ hidden, programVersionId }) => {
	const classes = useStyles({});
	const actions = useActions({
		getIllinoisReportConfiguration: IllinoisTranscriptSettingsActions.fetchSettings,
		selectedConfiguration: IllinoisTranscriptSettingsActions.selectConfiguration,
		reset: IllinoisTranscriptSettingsActions.reset
	}, []);
	const isLoading = useSelector(IllinoisTranscriptSettingsSelectors.loading);
	const configurations: Array<IIllinoisTranscriptReportSettings> = useSelector(IllinoisTranscriptSettingsSelectors.settings);
	const selectedConfiguration = useSelector(IllinoisTranscriptSettingsSelectors.selectProgramVersion);
	React.useEffect(() => {
		if (!hidden && programVersionId && programVersionId !== EmptyGuid)
			actions.getIllinoisReportConfiguration(programVersionId);
		return () => {
			actions.reset();
		};
	}, [programVersionId, actions, hidden]);
	const [isDirty, setIsDirty] = React.useState(false);
	const [leaveConfirmOpen, setLeaveConfirmOpen] = React.useState<() => void>();
	return (
		<React.Fragment>
			<Card square={true} className={classes.cardBody} hidden={hidden}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6} lg={4}>
						<TableContainer>
							<Table >
								<TableHead>
									<TableRow>
										<TableCell variant='head'>Program Version</TableCell>
										<TableCell variant='head'>Effective</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{
										isLoading && <TableRow>
											<TableCell colSpan={2} align="center"><CircularProgress size={50} color="primary" /></TableCell>
										</TableRow>
									}
									{
										!isLoading && configurations.map((config, index) => {
											return <TableRow key={index}>
												<TableCell className={classes.selectorCell} onClick={() => {
													if (selectedConfiguration !== config.reportConfigurationId) {
														if (isDirty) {
															setLeaveConfirmOpen(() => () => {
																setIsDirty(false);
																actions.selectedConfiguration(config.reportConfigurationId);
															});
														} else {
															setIsDirty(false);
															actions.selectedConfiguration(config.reportConfigurationId);
														}
													}
												}}>{config.programVersionName}
												</TableCell>
												<TableCell>{new Date(config.effectiveDate).toLocaleDateString()}</TableCell>
											</TableRow>;
										})
									}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>
				<CardActions>
					<Button variant='contained' color='primary' onClick={() => {
						if (isDirty) {
							setLeaveConfirmOpen(() => () => {
								setIsDirty(false);
								actions.selectedConfiguration(EmptyGuid);
							});
						}
						else {
							actions.selectedConfiguration(EmptyGuid);
						}
					}}>Add New Setting</Button>
				</CardActions>
			</Card>
			{selectedConfiguration && <ProgramVersionSettingDetails isDirty={isDirty} setIsDirty={setIsDirty} leaveConfirmOpen={leaveConfirmOpen} setLeaveConfirmOpen={setLeaveConfirmOpen} programVersionId={programVersionId} />}
			<UnsaveConfirmPopUp
				open={isDirty && leaveConfirmOpen !== undefined}
				cancel={() => {
					setLeaveConfirmOpen(undefined);
				}}
				confirm={leaveConfirmOpen || (() => undefined)}
			/>
		</React.Fragment>
	);
};

export default IllinoisTranscriptSettings;