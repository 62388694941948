import React from "react";
import Paper from "@material-ui/core/Paper";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ProgramAndGroups from "./ProgramAndGroups";
import GeneralInfo from "./GeneralInfo";
import TransferDetails from "./TransferDetails";
import CostOfAttendDetails from "./CostOfAttendDetails";
import AutomaticCharging from "./AutomaticCharging";
import Schedule from "./Schedule";
import "date-fns";
import { useSelector } from "react-redux";
import * as enrollmentApi from "../../../../api/student/academics/enrollmentApi";
import { terminationApi } from "../../../../api/student/academics/terminationApi";
import {
  EnrollmentProgram,
  EnrollmentProgramAndGroups,
  EnrollmentGeneral,
  EnrollmentSchedule,
  EnrollmentTransferDetails,
  AutomaticChargingDetails,
  CostOfAttendance,
  ICostOfAttend,
} from "../../../../interfaces/student/academics/EnrollmentProgram";
import { useForm, FormContext } from "react-hook-form";
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";
import { any } from "prop-types";
import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";
import { security } from "../../../../constants/Security/securityConstants";
import AccessManager from "../../../../components/security/AccessManager";
import { CustomSnackBarProps } from "../../../../interfaces/common/CustomSnackBarProps";
import { SystemEnrollmentStatus } from "../../../../enums/SystemEnrollmentStatus";
import NotificationModal from "../../../../components/_Layout/Modal/NotificationModal";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      "& .MuiCardContent-root": {
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(2),
      },
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    paperRoot: {
      padding: theme.spacing(2),
      width: "100%",
      minHeight: "100%",
      height: "95%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
    },
    button: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      whiteSpace: "nowrap",
    },
    cardSeparator: {
      marginBottom: theme.spacing(1),
    },
    actionContainer: {
      marginTop: theme.spacing(3),
    },
  })
);

const Enrollments = (props: any) => {
  const classes = useStyles({});
  const [loaderState, setLoaderState] = React.useState<boolean>(false);
  const [originalBadgeId, setOriginalBadgeId] = React.useState<string | null>(
    null
  );



  const methods = useForm<any>({
    mode: "onBlur",
  });
  const { register, handleSubmit } = methods;
  const selectedEnrollment = useSelector(
    (state: any) => state.student.selectedEnrollment
  );

  const [enrollmentData, setEnrollmentData] = React.useState<EnrollmentProgram>(
    {} as EnrollmentProgram
  );

  const [enrollmentProgramAndGroups, setEnrollmentProgramAndGroups] =
    React.useState<EnrollmentProgramAndGroups>({
      programDescription: "",
      programVersionDescription: "",
      isCreditHour: false,
    } as EnrollmentProgramAndGroups);

  const [enrollmentGeneral, setEnrollmentGeneral] =
    React.useState<EnrollmentGeneral>({
      enrollmentId: "",
      thirdPartyPayer: "",
      statusCodeId: "",
      sysStatusId: 0,
      status: "",
      attendanceTypeId: "",
      startDate: null,
      enrollmentDate: null,
      reEnrollmentDate: null,
      graduationDate: null,
      revisedGraduationDate: null,
      entranceInterviewDate: null,
      isFirstTimeInSchool: null,
      thirdPartyContract: false,
      incarceratedWithinSixMonth: false,
      isFirstTimePostSecSchool: null,
      titleIvAuthorization: false,
      covid19DropWithAttestation: false,
    } as EnrollmentGeneral);

  const [enrollmentSchedule, setEnrollmentSchedule] =
    React.useState<EnrollmentSchedule>({
      badgeId: "",
      scheduleId: "",
    } as EnrollmentSchedule);

  const [enrollmentTransferDetails, setEnrollmentTransferDetails] =
    React.useState<EnrollmentTransferDetails>({
      transferDate: "",
      transferHoursOther: 0,
      transferHoursThis: 0,
      transferFromProgramId: "",
      transferFromStuEnrollId: "",
      moreThenOneEnrollment: false,
    } as EnrollmentTransferDetails);

    // const [costOfAttendance, setCostOfAttendance] =
    // React.useState<CostOfAttendance>({
    //   totalCostField1: 0,
    //   awardYearField1: "",
    //   totalCostField2: 0,
    //   awardYearField2: "",
    //   totalCostField3: 0,
    //   awardYearField3: "",
    //   totalCostField4: 0,
    //   awardYearField4: "",
    //   StuEnrollId: "",
    //   ProgramVersionId: "",
    // } as CostOfAttendance);

const [StateWiseLicenseDetails,setStateWiseLicenseDetails] = React.useState<ICostOfAttend>({
      stateId: "",
      campusId: "",
      licenseId: "",
      license: "",
      totalCOAAwardYear:"",
      academicYearId:"",
      studentCOAId:"",
      stuEnrollId:"",
  
  }as ICostOfAttend);
  

  const [automaticChargingDetails, setAutomaticChargingDetails] =
    React.useState<AutomaticChargingDetails>({
      billingMethodDescrip: "",
      incrementId: "",
    } as AutomaticChargingDetails);

  const [isDroppedDueToCovid19, setIsDroppedDueToCovid19] =
    React.useState<boolean>(false);
  React.useEffect(() => {
    if (selectedEnrollment && selectedEnrollment.stuEnrollId) {
      let enrollmentId = selectedEnrollment.stuEnrollId;
      enrollmentApi.getEnrollmentProgramInfo(enrollmentId).then(
        (response: any) => {
          if (response && response.data) {
            updateEnrollmentData(response.data);
            setOriginalBadgeId(response.data.badgeId);
          }
        },
        (exception: any) => {}
      );

      terminationApi
        .isDroppedDueToCovid19(enrollmentId)
        .then((result: boolean) => {
          setIsDroppedDueToCovid19(result);
        });
    }
  }, [selectedEnrollment]);

  const firstTimeInSchool = (fieldId: string, value: any) => {
    let modelToUpdate = enrollmentData;
    (enrollmentData as any)[fieldId] = value;
    setEnrollmentData({ ...modelToUpdate });
  };

  const [notificationPopupState, setNotificationPopupState] =
    React.useState<any>({
      id: "simple-popup",
      message: "",
    });
  const [firstSchoolError, setFirstSchoolError] =
    React.useState<boolean>(false);

  const handleFirstTimeInSchoolCheck = () => {
    let firstTimeInTHISSchool = enrollmentGeneral.isFirstTimeInSchool?.id;
    let firstTimePostSecSchool = enrollmentGeneral.isFirstTimePostSecSchool?.id;
    let transferHoursThis = enrollmentTransferDetails.transferHoursThis
      ? enrollmentTransferDetails.transferHoursThis
      : 0;
    let transferHoursOther = enrollmentTransferDetails.transferHoursOther
      ? enrollmentTransferDetails.transferHoursOther
      : 0;
    let text = "";
    if (firstTimeInTHISSchool == 1 && transferHoursThis > 0) {
    	setFirstSchoolError(true);
    	text = "A student cannot have transfer hours from this school and be first time."
    	handleFirstTimeCheck(text);
    }
    else if (firstTimePostSecSchool == 1 && transferHoursOther > 0) {
    	setFirstSchoolError(true);
    	text = "A student cannot have transfer hours from other schools and be first time at any post secondary school."
    	handleFirstTimeCheck(text);
    } else {
    	setFirstSchoolError(false);
    }
  };

  const handleFirstTimeCheck = (text: string) => {
    setNotificationPopupState((state: any) => {
      return {
        ...state,
        open: true,
        notificationText: text,
        notificationButton: "Ok",
        title: "Transfer hours issue",
        onConfirmEvent: () => {
          setNotificationPopupState((updatedState: any) => {
            return { ...updatedState, open: false };
          });
        },
        onCloseEvent: () => {
          setNotificationPopupState((updatedState: any) => {
            return { ...updatedState, open: false };
          });
        },
      };
    });
  };

  const [firstTimePostSec, setFirstTimePostSec] =
    React.useState<boolean>(false);

  const onSubmit = (data: any, e: any) => {
    if (!firstSchoolError) {
      if (enrollmentData) {
        enrollmentData.transferHoursThis =
          enrollmentTransferDetails.transferHoursThis;
        enrollmentData.transferHoursOther =
          enrollmentTransferDetails.transferHoursOther;
        enrollmentData.isFirstTimeInSchool =
          enrollmentGeneral.isFirstTimeInSchool;
        enrollmentData.isFirstTimePostSecSchool =
          enrollmentGeneral.isFirstTimePostSecSchool;
        setLoaderState(true);
        firstTimeInSchool(
          "isFirstTimeInSchool",
          enrollmentData?.isFirstTimeInSchool.id
        );
        firstTimeInSchool(
          "isFirstTimePostSecSchool",
          enrollmentData?.isFirstTimePostSecSchool.id
        );
        if (
          originalBadgeId != enrollmentData.badgeId &&
          enrollmentData.badgeId != ""
        ) {
          const params = new URLSearchParams(window.location.search);
          const campusId = params.get("campusId");
          enrollmentApi
            .checkStudentNumberAvailability(enrollmentData.badgeId, campusId)
            .then((response: any) => {
              if (response.data.result) callBackOnSubmit();
              else {
                setLoaderState(false);
                setSnackBarProps((props: any) => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: response.data.resultStatusMessage,
                  };
                });
              }
            });
        } else callBackOnSubmit();
      }
    }
  };

  const callBackOnSubmit = () => {
    enrollmentApi.updateEnrollment(enrollmentData).then(
      (response: any) => {
        if (response) {
          if (response.data) {
            updateEnrollmentData(response.data);
          }
          setSnackBarProps((props: any) => {
            return {
              variant: "success",
              showSnackBar: true,
              messageInfo: "Enrollment successfully updated.",
            };
          });
        } else {
          setSnackBarProps((props: any) => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo:
                "Enrollment update could not be completed. Please try again.",
            };
          });
        }
        setLoaderState(false);
      },
      (exception: any) => {
        setLoaderState(false);
      }
    );
  };
  const updateEnrollmentData = (data: any) => {
    let enrollmentProgramData: EnrollmentProgram = {
      ...data,
      startDate:
        data === undefined || data.startDateString === ""
          ? null
          : data.startDateString,
      enrollmentDate:
        data === undefined || data.enrollmentDateString === ""
          ? null
          : data.enrollmentDateString,
      reEnrollmentDate:
        data === undefined || data.reEnrollmentDateString === ""
          ? null
          : data.reEnrollmentDateString,
      graduationDate:
        data === undefined || data.graduationDateString === ""
          ? null
          : data.graduationDateString,
      revisedGraduationDate:
        data === undefined || data.revisedGraduationDateString === ""
          ? null
          : data.revisedGraduationDateString,
      isFirstTimeInSchool:
        data.isFirstTimeInSchool === false
          ? { text: "No", id: 0 }
          : { text: "Yes", id: 1 },
      isFirstTimePostSecSchool:
        data.isFirstTimePostSecSchool === false
          ? { text: "No", id: 0 }
          : { text: "Yes", id: 1 },
    };

    setEnrollmentData(enrollmentProgramData);
    setEnrollmentProgramAndGroups((state: EnrollmentProgramAndGroups) => {
      var result = getDataByModel(
        "EnrollmentProgramAndGroups",
        enrollmentProgramData
      );
      return { ...state, ...result };
    });
    setEnrollmentGeneral((state: EnrollmentGeneral) => {
      var result = getDataByModel("EnrollmentGeneral", enrollmentProgramData);
      return { ...state, ...result };
    });

    setEnrollmentSchedule((state: EnrollmentSchedule) => {
      var result = getDataByModel("EnrollmentSchedule", enrollmentProgramData);
      return { ...state, ...result };
    });
    setEnrollmentTransferDetails((state: EnrollmentTransferDetails) => {
      var result = getDataByModel(
        "EnrollmentTransferDetails",
        enrollmentProgramData
      );
      return { ...state, ...result };
    });
    setAutomaticChargingDetails((state: AutomaticChargingDetails) => {
      var result = getDataByModel(
        "AutomaticChargingDetails",
        enrollmentProgramData
      );
      return { ...state, ...result };
    });
  };

  const getDataByModel = (model: string, ed: EnrollmentProgram) => {
    var result = null;
    switch (model) {
      case "EnrollmentProgramAndGroups":
        result = {
          programDescription: ed.programDescription,
          programVersionDescription: ed.programVersionDescription,
          isCreditHour: ed.isCreditHour,
          programId: ed.programId,
          cipCode: ed.cipCode,
          programVersionId: ed.programVersionId,
          stuEnrollId: ed.stuEnrollId,
          studentGroupIds: ed.studentGroupIds,
          campusId: ed.campusId,
          programVersionSAPPolicyInfo: ed.programVersionSAPPolicyInfo,
        };
        break;
      case "EnrollmentGeneral":
        result = {
          enrollmentId: ed.enrollmentId,
          statusCodeId: ed.statusCodeId,
          sysStatusId: ed.sysStatusId,
          status: ed.status,
          startDate: ed.startDate,
          enrollmentDate: ed.enrollmentDate,
          reEnrollmentDate: ed.reEnrollmentDate,
          graduationDate: ed.graduationDate,
          updatedGraduationDate: ed.updatedGraduationDate,
          revisedGraduationDate: ed.revisedGraduationDate,
          entranceInterviewDate: ed.entranceInterviewDate,
          attendanceTypeId: ed.attendanceTypeId,
          distanceEducationStatusId: ed.distanceEducationStatusId,
          thirdPartyContract: ed.thirdPartyContract,
          incarceratedWithinSixMonth: ed.incarceratedWithinSixMonth,
          isFirstTimeInSchool: ed.isFirstTimeInSchool,
          isFirstTimePostSecSchool: ed.isFirstTimePostSecSchool,
          degcertseekingid: ed.degcertseekingid,
          adminCriteriaId: ed.adminCriteriaId,
          educationCompletedId: ed.educationCompletedId,
          lda: ed.lda,
          faadvisorId: ed.faadvisorId,
          thirdPartyPayer: ed.thirdPartyPayer,
          academicAdvisor: ed.academicAdvisor,
          sapId: ed.sapId,
          scheduleId: ed.scheduleId,
          titleIvAuthorization: ed.titleIvAuthorization,
          covid19DropWithAttestation: ed.covid19DropWithAttestation,
          instructorId: ed.instructorId,
        };
        break;
      case "EnrollmentSchedule":
        result = {
          scheduleId: ed.scheduleId,
          badgeId: ed.badgeId,
          programVersion: ed.programVersion,
        };
        break;
      case "EnrollmentTransferDetails":
        result = {
          transferDate: ed.transferDate,
          transferHoursOther: ed.transferHoursOther,
          transferHoursThis: ed.transferHoursThis,
          transferFromProgramId: ed.transferFromProgramId,
          transferToProgramId: ed.transferToProgramId,
          transferFromStuEnrollId: ed.transferFromStuEnrollId,
          moreThenOneEnrollment: ed.moreThenOneEnrollment,
          oldStuEnrollId: ed.oldStuEnrollId,
          oldProgramVersionId: ed.oldProgramVersionId,
          transferDateToNewProgram: ed.transferDateToNewProgram,
        };
        break;
      case "AutomaticChargingDetails":
        result = {
          billingMethodDescrip: ed.billingMethodDescrip,
          incrementId: ed.incrementId,
          programVersionId: ed.programVersionId,
          billingMethodId: ed.billingMethodId,
          usesActualHours: ed.usesActualHours,
        };
        break;
      default:
        result = {};
        break;
    }
    return result;
  };
  /*let enrollmentProgramAndGroups: EnrollmentProgramAndGroups ={
		programDescription: "", 
		programVersionDescription: ""
	};
	let enrollmentGeneral: EnrollmentGeneral = {
		enrollmentId: "",
		statusCodeId: "",
		sysStatusId: 0,
		status: "",
		attendanceTypeId: "",
		startDate: null,
		enrollmentDate: null,
		reEnrollmentDate: null,
		graduationDate: null,
		revisedGraduationDate: null,
		entranceInterviewDate: null,
		isFirstTimeInSchool: false,
		thirdPartyContract: false,
		isFirstTimePostSecSchool: false
	};
	let enrollmentSchedule: EnrollmentSchedule = {
		badgeId: "",
		scheduleId: ""
	};

	let enrollmentTransferDetails: EnrollmentTransferDetails = {
		transferDate: "",
		transferHoursOther: 0,
		transferHoursThis: 0,
		transferFromProgramId: "",
		transferFromStuEnrollId: "",
		moreThenOneEnrollment: false
	};
	let automaticChargingDetails: AutomaticChargingDetails = {
		billingMethodDescrip: "",
		incrementId: ""
	};*/

  /*if (enrollmentData) {
	let enrollmentProgramAndGroups: EnrollmentProgramAndGroups = {
	  programDescription: enrollmentData.programDescription,
	  programVersionDescription: enrollmentData.programVersionDescription,
	  studentGroupIds: enrollmentData.studentGroupIds,
	  programId: enrollmentData.programId,
	  programVersionId: enrollmentData.programVersionId,
	  stuEnrollId: enrollmentData.stuEnrollId,
	};
	let enrollmentGeneral: EnrollmentGeneral = {
	  ...enrollmentData,
	};
	let enrollmentSchedule = {
	  badgeId: enrollmentData.badgeId,
	  scheduleId: enrollmentData.scheduleId,
	};
	let enrollmentTransferDetails = {
	  transferDate: enrollmentData.transferDate,
	  transferHoursOther: enrollmentData.transferHoursOther,
	  transferHoursThis: enrollmentData.transferHoursThis,
	  transferFromProgramId: enrollmentData.transferFromProgramId,
	  transferFromStuEnrollId: enrollmentData.transferFromStuEnrollId,
	  moreThenOneEnrollment: enrollmentData.moreThenOneEnrollment,
	};
	let automaticChargingDetails = {
	  billingMethodDescrip: enrollmentData.billingMethodDescrip,
	  incrementId: enrollmentData.incrementId,
	};
  }

  const setEnrollmentGroups = (studentGroups: any) => {
	let newGroups: string[] = [];
	if (studentGroups !== null) {
	  if (Array.isArray(studentGroups)) {
		newGroups = studentGroups.map((gr: any) => gr.value);
	  } else {
		newGroups.push(studentGroups.value);
	  }
	}
	setEnrollmentData((state: EnrollmentProgram | null) => {
	  return { ...state, studentGroupIds: newGroups };
	});
  };*/

  const setGeneralInfo = (generalInfo: EnrollmentGeneral) => {
    setEnrollmentData((state: EnrollmentProgram | null) => {
      return { ...state, ...generalInfo };
    });
  };
  const setTransferInfo = (transferInfo: EnrollmentTransferDetails) => {
    setEnrollmentData((state: EnrollmentProgram | null) => {
      return { ...state, ...transferInfo };
    });
  };
  const setChargingMethodInfo = (
    chargingMethodInfo: AutomaticChargingDetails
  ) => {
    setEnrollmentData((state: EnrollmentProgram | null) => {
      return {
        ...state,
        billingMethodDescrip: chargingMethodInfo.billingMethodDescrip,
        usesActualHours: chargingMethodInfo.usesActualHours,
      };
    });
  };
  const setSchedule = (enrollmentSchedule: EnrollmentSchedule) => {
    setEnrollmentData((state: EnrollmentProgram | null) => {
      return {
        ...state,
        scheduleId: enrollmentSchedule.scheduleId,
        badgeId: enrollmentSchedule.badgeId,
      };
    });
  };

  const onEnrollmentProgramAndGroupsChanged = (
    enrollmentProgramAndGroups: EnrollmentProgramAndGroups
  ) => {
    setEnrollmentData((state: EnrollmentProgram | null) => {
      return {
        ...state,
        studentGroupIds: enrollmentProgramAndGroups.studentGroupIds,
      };
    });
    setEnrollmentData((state: EnrollmentProgram | null) => {
      return {
        ...state,
        programVersionId: enrollmentProgramAndGroups.programVersionId,
      };
    });
    setEnrollmentData((state: EnrollmentProgram | null) => {
      return {
        ...state,
        programId: enrollmentProgramAndGroups.programId,
      };
    });
  };
  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );


  const saveAllRef = React.useRef<(() => void) | null>(null);  // Step 1: Create a ref for the saveAll method


    // Function to store the saveAll method in the parent component
    const setSaveAllMethod = (saveAllMethod: () => void) => {
      saveAllRef.current = saveAllMethod;
    };
  
    // Handler for the SAVE button
    const handleSave = () => {
      if (saveAllRef.current) {
        saveAllRef.current();  // Call the saveAll method passed from the child component
      } else {
        console.warn("SaveAll method not available.");
      }
    };


  return (
    <Paper className={classes.paperRoot} square={true}>
      <CustomSnackbar
        variant={snackBarProps.variant}
        message={snackBarProps.messageInfo}
        open={snackBarProps.showSnackBar}
        onClose={(event?: React.SyntheticEvent, reason?: string) => {
          setSnackBarProps((props: any) => {
            return { ...props, showSnackBar: false };
          });
        }}
        // variant={snackBarVariant}
        // message={messageInfo}
        // open={showSnackBar}
        // onClose={(event?: React.SyntheticEvent, reason?: string) => {
        //   // if (reason === "clickaway") {
        //   // 	return;
        //   // }
        //   setShowSnackBar(false);
        // }}
      ></CustomSnackbar>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.cardSeparator}>
            <ProgramAndGroups
              enrollmentProgramAndGroups={enrollmentProgramAndGroups}
              setEnrollmentProgramAndGroups={
                onEnrollmentProgramAndGroupsChanged
              }
              isNewEnrollment={false}
              isNewEnrollmentWorkFlow={false}
              showInactivePrograms={true}
            />
          </div>
          <div className={classes.cardSeparator}>
            <GeneralInfo
              general={enrollmentGeneral}
              setGeneral={setGeneralInfo}
              isNewEnrollment={false}
              isDroppedDueToCovid19={isDroppedDueToCovid19}
            />
          </div>
          <div className={classes.cardSeparator}>
            <TransferDetails
              transferDetails={enrollmentTransferDetails}
              setTransfer={setTransferInfo}
              isNewEnrollmentTransfer={false}
            />
          </div>      
          <div className={classes.cardSeparator}>
            <CostOfAttendDetails
              stateWiseLicenseDetails={StateWiseLicenseDetails}
              setStateWise={setTransferInfo}
              //isNewEnrollmentTransfer={false}
              setSaveAllMethod={setSaveAllMethod} 
            />
          </div>

          <div className={classes.cardSeparator}>
						<AutomaticCharging
							automaticChargingDetails={automaticChargingDetails}
							setAutomaticCharging={setChargingMethodInfo}
							programVersionId={
								enrollmentProgramAndGroups.programVersionId ?? ""
							}
							setSnackBar={setSnackBarProps}
							isNewEnrollment={false}
						/>
					</div>
          <div>
            {!enrollmentProgramAndGroups.isCreditHour ? (
              <Schedule
                schedule={enrollmentSchedule}
                setSchedule={setSchedule}
                isNewEnrollment={false}
                isScheduleRequired={
                  selectedEnrollment &&
                  [
                    SystemEnrollmentStatus.FutureStart,
                    SystemEnrollmentStatus.CurrentlyAttending,
                    SystemEnrollmentStatus.LeaveOfAbsence,
                    SystemEnrollmentStatus.Suspension,
                  ].indexOf(selectedEnrollment.sysStatusId) !== -1
                }
              />
            ) : (
              <div></div>
            )}
          </div>
          <div className={classes.actionContainer}>
            <NotificationModal {...notificationPopupState} />
            <AccessManager
              allowedPermissions={[
                security.permissions.student.updateEnrollmentInfo,
              ]}
              renderNoAccess={() => (
                <ProgressSaveButton
                  text="Save"
                  onClick={() => {
                    handleFirstTimeInSchoolCheck();
                    handleSubmit(onSubmit);
                    handleSave();

                  }}
                  loading={loaderState}
                  disabled
                ></ProgressSaveButton>
              )}
            >
              <ProgressSaveButton
                text="Save"
                onClick={() => {
                  handleFirstTimeInSchoolCheck();
                  handleSubmit(onSubmit);
                  handleSave();

                }}
                loading={loaderState}
              ></ProgressSaveButton>
            </AccessManager>
          </div>
        </form>
      </FormContext>
    </Paper>
  );
};

export default Enrollments;
