import React from "react";
import { useEffect, useRef } from "react";
import { apiAutoCompleteConfig } from "../../constants/apiAutoComplete.config";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../constants/actions.constants";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";
import { Enrollment } from "../../interfaces/student/academics/Enrollment";

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& label.Mui-focused": {
				color: theme.palette.primary.white,
			},
		},
	})
)(TextField);

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {},
	})
);

const config: ApiAutoCompleteConfigItem = {
	acId: "studentAward",
	cacheTime: 5,
	route: "/FinancialAid/StudentAward/GetStudentAwards",
	requestType: "GET",
	body: null,
	hasUrlParams: true,
	useCache: false,
	storeInCache: false,
	isCampusSpecific: false,
	isUserSpecific: false,
	loadOnRender: true,
	defaultSelectedFirstItem: false,
	optionsMappedFx: (data: Array<any>) => {
		return data;
	},
};

export interface StudentAwardProps extends FilterParams {
	fundSourceId: string;
	studentEnrollmentId?: string;
}

const StudentAwardAutoComplete = (props: StudentAwardProps) => {
	const classes = useStyles({});
	const inputRef: any = useRef(null);

	const selectedEnrollment = useSelector(
		(state: any) => state.student.selectedEnrollment
	);

	const handleOnChange = (e: any) => {
		if (props.filterHandle) {
			props.filterHandle(e);
		}
	};

	return (
		<div>
			<ApiAutoComplete
				config={config}
				loading={selectedEnrollment ? false : true}
				reqParams={
					props.fundSourceId
						? {
								studentEnrollmentId: props.studentEnrollmentId
									? props.studentEnrollmentId
									: (selectedEnrollment as Enrollment).stuEnrollId,
								fundSourceId: props.fundSourceId,
						  }
						: {
								studentEnrollmentId: props.studentEnrollmentId
									? props.studentEnrollmentId
									: (selectedEnrollment as Enrollment).stuEnrollId,
						  }
				}
				classes={{
					option: "text-black",
				}}
				label={props.label ? props.label : "Award"}
				onChange={(e: any) => {
					handleOnChange(e);
				}}
				multiple={false}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
				inputRef={props.inputRef ? props.inputRef : inputRef}
				error={props.error}
				helperText={props.helperText}
				inputName={props.name}
			></ApiAutoComplete>
		</div>
	);
};

export default StudentAwardAutoComplete;
