import React from "react";
import {
  makeStyles,
  Card,
  createStyles,
  CardContent,
  Grid,
  withStyles,
  TextField,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { StudentProfile } from "../../../../interfaces/student/profile/StudentProfile";
import { useForm } from "react-hook-form";
import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";
import { INewNotes } from "../../../../interfaces/student/notes/INotesInfo";
import * as StudentNotesApi from "../../../../api/student/notes/StudentNotesApi";

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {},
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    saveButton: {
      width: 130,
    },
    cardWrapper: {
      marginBottom: theme.spacing(2),
    },
    root: {
      paddingTop: theme.spacing(2),
      minHeight: "100%",
      height: "100%",
      width: "100%",
      margin: "auto",
    },
    paperRoot: {
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
  })
);

type PhonePageProps = {
  setSnackBarProps?: any;
};

const PhonePage = (props: PhonePageProps) => {
  const classes = useStyles({});

  const data = useForm<INewNotes>({ mode: "onBlur" });
  const [leadId, setLeadId] = React.useState<string>("");
  const selectedStudent: StudentProfile = useSelector(
    (state: any) => state.student
  ) as StudentProfile;

  const [model, setModel] = React.useState<INewNotes>({
    notesId: null,
    leadId: null,
    moduleId: 2,
    noteText: "",
    noteType: "Comment",
    modDate: new Date(),
    modDateString: "",
  });

  React.useEffect(() => {
    if (selectedStudent.selectedEnrollment !== undefined) {
      if (
        selectedStudent.leadId !== undefined &&
        selectedStudent.leadId.length > 0
      ) {
        setLeadId(selectedStudent.leadId);
      }
    }
  }, [selectedStudent]);

  React.useEffect(() => {
    setModel({ ...model, leadId: leadId });
    getNotes();
  }, [leadId]);

  const getNotes = () => {
    if (leadId != "") {
      StudentNotesApi.GetLeadComments(leadId, "AD", "Comment").then(
        (response: any) => {
          if (response && response.data && response.data.notesId != null) {
            setModel({
              ...model,
              leadId: leadId,
              notesId: response.data.notesId,
              noteText: response.data.noteText,
            });
          } else {
            setModel({
              ...model,
              leadId: leadId,
              notesId: null,
              noteText: "",
            });
          }
        },
        (exception: any) => {}
      );
    }
  };

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    setModel({ ...updatedModel });
  };

  const handleSavePhone = () => {
    if (model) {
      data.triggerValidation().then((validation: any) => {
        if (validation) {
          setLoading(true);
          if (model.notesId == null) {
            StudentNotesApi.CreateNotes(model).then(
              (response: any | null) => {
                if (response && response.result) {
                  props.setSnackBarProps(() => {
                    return {
                      variant: "success",
                      showSnackBar: true,
                      messageInfo: "Comments saved successfully.",
                    };
                  });
                  getNotes();
                } else {
                  props.setSnackBarProps(() => {
                    return {
                      variant: "error",
                      showSnackBar: true,
                      messageInfo: response.data.resultStatusMessage,
                    };
                  });
                }
                setLoading(false);
              },
              (exception: any) => {
                props.setSnackBarProps(() => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: exception,
                  };
                });
              }
            );
          } else {
            StudentNotesApi.UpdateNotes(model).then(
              (response: any | null) => {
                if (response && response.result == true) {
                  props.setSnackBarProps(() => {
                    return {
                      variant: "success",
                      showSnackBar: true,
                      messageInfo: "Comments updated successfully.",
                    };
                  });
                  getNotes();
                } else {
                  props.setSnackBarProps(() => {
                    return {
                      variant: "error",
                      showSnackBar: true,
                      messageInfo: response.data.resultStatusMessage,
                    };
                  });
                }
                setLoading(false);
              },
              (exception: any) => {
                props.setSnackBarProps(() => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: exception,
                  };
                });
              }
            );
          }
        }
      });
    }
  };

  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <Card square={true} className={classes.cardWrapper}>
      <CardContent>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <ThemeTextField
                  id="noteText"
                  name="noteText"
                  className={classes.textField}
                  label="Comments"
                  multiline={true}
                  margin="normal"
                  value={model.noteText ?? ""}
                  onChange={(v: any) => {
                    handleFieldOnChange(
                      "noteText",
                      v ? v.target.value : undefined
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <ProgressSaveButton
                  text="Save"
                  onClick={handleSavePhone}
                  size="small"
                  loading={loading}
                  buttonClassName={classes.saveButton}
                ></ProgressSaveButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PhonePage;
