import React from 'react';
import {
    makeStyles,
    createStyles,
    Grid,
    Paper,
    Table,
    CardActions,
    withStyles,
    TableCell,
    TableRow,
    TableContainer,
    TableHead,
    TableBody,
    IconButton,
    CircularProgress,
    Button,
    Card,
    CardContent,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import  IGradeScale  from "../../../../interfaces/setup/grades/ComponentTypes/IGradeScale";
import * as componentTypeApi from "../../../../api/setup/grades/componentTypesApi";
import InputField from '../../../../components/_Layout/Inputs/InputField';
import CustomCardTitle from '../../../../interfaces/common/card/CustomCardTitle';
import GradeScaleDetail from './GradeScaleDetail';
import { EmptyGuid } from '../../../../utils/constants';
import { CustomSnackBarProps } from '../../../../interfaces/common/CustomSnackBarProps';
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";

const StyledTableCell = withStyles((theme: any) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.site.secondary,
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            textAlign: 'left',
            boxShadow: '1px 1px 0px 0px rgba(0,0,0,0.2)',
            '&:first-child': {
                width: '42px',
                padding: theme.spacing(0, 1)
            }
        },
        body: {
            fontSize: 16,
            color: theme.palette.black,
            fontWeight: 400,
            cursor: 'pointer',
            textAlign: 'left',
            '&:first-child': {
                width: '42px',
                padding: theme.spacing(0, 1)
            }
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: any) =>
    createStyles({
        root: {
            '&:nth-of-type(even)': {
                backgroundColor: theme.palette.site.secondary,
            },
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.white,
            },
            '&:hover': {
                backgroundColor: theme.palette.site.secondary,
            }
        },
        body: {
            cursor: 'pointer'
        }
    }),
)(TableRow);

const useStyles = makeStyles((theme: any) =>
    createStyles({
        cardTitle: {
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            backgroundColor: theme.palette.site.secondary,
            padding: theme.spacing(0)
        },
        headerText: {
            float: 'left',
            padding: theme.spacing(1.5),
        },
        cardContent: {
            padding: theme.spacing(1, 3),
            height: '100%',
        },
        saveButton: {
            width: 90,
        },
        tableContainer: {
            maxHeight: '400px'
        },
        paperRoot: {
            width: "100%",
            minHeight: "100%",
            height: "100%",
            overflowX: "hidden",
            overflowY: "auto",
            flexDirection: "column",
          },
        table: {
            width: '100%',
        },
        cardMain: {
            marginTop: theme.spacing(2)
          },
        iconColor: {
            color: theme.palette.black
        },
        searchField: {
            '&::placeholder': {
                color: theme.palette.black
            }
        },
        selectionCheckBox: {
            padding: theme.spacing(0)
        },
        preLoaderCell: {
            textAlign: 'center',
            padding: theme.spacing(1)
        },
        dialogCloseButton: {
            float: "right",
        },
        selectionHeaderCell: {
          height: 40
        },
        courseSelectionCell: {
            color: theme.palette.paperSummarySchedulerTitle,
            cursor: 'pointer',
            fontWeight: 500,
            height: 25
        }
    })
);
type GradeScaleProps = {
    grdScaleId?: string;
    campusId?: string;
    repeatedExamPolicy?: string;
    showSnackBar: (param: CustomSnackBarProps) => void;
    setConfirmationDialog: (param: { onOk?: () => (void | Promise<void>); open: boolean; onCancel?: () => (void | Promise<void>); }) => void;
};

const GradeScale = (
    props: Readonly<GradeScaleProps>
) => {
    const classes = useStyles({});
    const [searchText, setSearchText] = React.useState<string>('');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [grdScale, setGrdScale] = React.useState<IGradeScale[]>([]);
    const [selectedGradeScale, selectGradeScale] = React.useState<IGradeScale>({ grdScaleId: EmptyGuid });
    const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );
    const toDisplay = searchText
        && searchText.length > 2
        ? grdScale.filter((grdsc) => grdsc.grdScaleId === searchText
            || (grdsc?.descrip ?? '').toLocaleLowerCase().includes(searchText.toLocaleLowerCase()))
        : grdScale;
        const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
            {
              showSnackBar: false,
              messageInfo: undefined,
              variant: "info",
            }
          );

    const loadGradeScales = async () => {
      setIsLoading(true);
      if(userSelectedCampus!=null && userSelectedCampus!= undefined)
      {
        componentTypeApi.getGradeScales(userSelectedCampus).then(
          (response: any) => {
            if (response && response.data) {
                setGrdScale(response.data);
            }
          },
          (exception: any) => { 
            props.showSnackBar({
              variant: "error",
              showSnackBar: true,
              messageInfo: (exception as Error).message,
          });
  
  
          }
          );

      }
      
        setIsLoading(false);
        
    }

    React.useEffect(() => {
        loadGradeScales();
    }, [props.grdScaleId,userSelectedCampus]);

    return (
        <React.Fragment>
        <div className={classes.paperRoot}>
          <CustomSnackbar
            variant={snackBarProps.variant}
            message={snackBarProps.messageInfo}
            open={snackBarProps.showSnackBar}
            onClose={(event?: React.SyntheticEvent, reason?: string) => {
              setSnackBarProps((props: any) => {
                return { ...props, showSnackBar: false };
              });
            }}
          ></CustomSnackbar>
          <Card square={true} elevation={3} className={classes.cardMain}>
          <CustomCardTitle title='Grade Scales'></CustomCardTitle>
          <CardActions>
                <Grid container direction="row" spacing={2} wrap="nowrap">
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container direction="column" spacing={2} wrap="nowrap">
                            <Grid
                                item
                                alignContent="flex-start"
                                alignItems="flex-start"
                                justify="flex-start"
                            >
                                <InputField
                                    value={searchText ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
                                    inputProps={{ className: classes.searchField }}
                                    InputProps={
                                        {
                                            startAdornment: <SearchIcon className={classes.iconColor} />,
                                            endAdornment: searchText && <IconButton onClick={() => setSearchText('')}><CloseIcon className={classes.iconColor} /></IconButton>
                                        }
                                    }
                                    placeholder={'Search Grade Scale'} />
                            </Grid>
                            <Grid
                                item
                                alignContent="flex-start"
                                alignItems="flex-start"
                                justify="flex-start"
                            >
                                <TableContainer component={Paper} square className={classes.tableContainer}>
                                    <Table stickyHeader size='small' className={classes.table} aria-label='customized table'>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell className={classes.selectionHeaderCell} colSpan={4} >Grade Scale</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {isLoading ?
                                                <StyledTableRow>
                                                    <StyledTableCell colSpan={4} className={classes.preLoaderCell}>
                                                        <CircularProgress />
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                                : toDisplay.map((grdsc, index) => (
                                                    <StyledTableRow key={`row-${index}`}>
                                                        <StyledTableCell colSpan={4} key={`description-${index}`} className={classes.courseSelectionCell} onClick={() => selectGradeScale(grdsc)}>{grdsc.descrip}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid
                                item
                                alignContent='flex-start'
                                alignItems='flex-start'
                                justify='flex-start'
                            >
                                <Button
                                    onClick={() => selectGradeScale({ grdScaleId: EmptyGuid, campusId:userSelectedCampus })}
                                    size='small'
                                    color='primary'
                                    variant='contained'
                                    type='button'>ADD NEW</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardActions>
            <CardContent>
                <Grid container direction="row" spacing={2} wrap="nowrap">
                    <Grid item xs={12} sm={12} md={12}>
                        <GradeScaleDetail setConfirmationDialog={props.setConfirmationDialog}
                            refreshList={loadGradeScales}
                            showSnackBar={props.showSnackBar}
                            model={selectedGradeScale}
                            campusId={props.campusId}
                            grdScaleId={props.grdScaleId} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        </div>
        </React.Fragment>
    );
};

export default GradeScale;
