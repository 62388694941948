import React from "react";
import { createStyles,  Theme, makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CampusForCurrentUserAutoComplete from "../../../components/AutoComplete/CampusForCurrentUserAutoComplete";
import {  FormControlLabel,Switch,Grid } from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import { IAttendaceHistoryMultiple } from "../../../interfaces/reports/attendance/IAttendaceHistoryMultiple";
import * as attendanceHistoryApi from "../../../api/reports/attendance/attendanceHistoryApi";
import { ReportOutput } from "../../../enums/ReportOutput";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import ProgramVersionAutoComplete from "../../../components/AutoComplete/ProgramVersionAutoComplete";
import StudentGroupsAutoComplete from "../../../components/AutoComplete/StudentGroupsAutoComplete";
import StudentNameBadgeIDFilter from '../../../../src/pages/attendance/StudentNameBadgeIDFilter'


const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      minHeight: "100%",
      height: "100%",
      maxWidth: "1050px",
      padding: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    paperRoot: {
      padding: theme.spacing(3, 2),
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
    },
    searchActions: {
      marginTop: theme.spacing(3),
      textAlign: "left",
    },
    startDateField: {
      width: "100%",
    },
    cardTitle: {
      fontSize: 17,
      color: theme.palette.primary.headerText,
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
    switch: {
      display: "flex",
     alignItems: "flex",
      padding: theme.spacing(0, 0.5),
      height: theme.spacing(8.6),
    },
  })
);

const AttendanceHistoryMultiple = (props: any) => {
  const { report } = props;
  let reportOverview = { report: report } as IReportOverview;
  const classes = useStyles({});
  const [preview, setPreview] = React.useState<any>();
  const [previewLoader, setPreviewLoader] = React.useState<boolean>();

  const data = useForm<any>({ mode: "onBlur" });
  const [model, setModel] = React.useState({
    campusId: "",
    startDate: undefined as Date | undefined,
    endDate: undefined as Date | undefined,
    exportType: 1 as ReportOutput,
    programId: [],
    leadGroupIds: [],
    singleStudent : false,
    studentNameBadgeId: "",
    studentName:"",
    groupByStu: false,
  });

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    if(fieldId == "campusId")
    {
      (updatedModel as any)["programId"] = [];
      (updatedModel as any)["leadGroupIds"] = [];
    }
    setModel({ ...updatedModel });
  };

  const handleStudentNameBadgeIDFilterOnChange = (value: any)=>{
    let updatedModel = model;
    (updatedModel as any)["studentName"] = value.studentName;
    (updatedModel as any)["studentNameBadgeId"] = value.studentNameBadgeId;
    setModel({ ...updatedModel });
  }

  const { handleSubmit } = data;

  const exportTypes: Array<DropDownListItem> = [
    { text: "PDF", id: "1" },
    { text: "Excel", id: "2" },
    { text: "Word", id: "3" },
  ];

  const onSubmit = async (isPreview: any) => {
    let params = (await getReportParameters()) as IAttendaceHistoryMultiple;
    const validation = await data.triggerValidation();
    if (validation) {
      if (isPreview) {
        setPreviewLoader(true);
        attendanceHistoryApi
          .generateReport(params, isPreview)
          .then((res: any) => {
            if (res) {
              res.arrayBuffer().then((buffer: any) => {
                setPreview(buffer);
                setPreviewLoader(false);
              });
            } else setPreviewLoader(false);
          });
      } else await attendanceHistoryApi.generateReport(params);
    }
  };

  const exportTypeAutoComplete = {
    options: exportTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const filterHandler = (data: any) => {
    if (data) {
      setModel(data);
    } else {
      setModel({
        campusId: "",
        startDate: undefined as Date | undefined,
        endDate: undefined as Date | undefined,
        exportType: 1 as ReportOutput,
        programId: [],
        leadGroupIds: [],
        singleStudent : false,
        studentNameBadgeId: "",
        studentName:"",
        groupByStu: false,
      });
    }
  };
  const setProgramVersion = (PrgVersions: any) => {
    let newProgramVersion: string[] = [];
    if (PrgVersions !== null) {
      if (Array.isArray(PrgVersions)) {
        newProgramVersion = PrgVersions.map((gr: any) => gr.value);
      } else {
        newProgramVersion.push(PrgVersions.value);
      }
    }
    return newProgramVersion;
  };
  const setStudentGroup = (studentGroups: any) => {
    let newGroups: string[] = [];
    if (studentGroups !== null) {
      if (Array.isArray(studentGroups)) {
        newGroups = studentGroups.map((gr: any) => gr.value);
      } else {
        newGroups.push(studentGroups.value);
      }
    }
    return newGroups;
  };
  const getReportParameters = async () => {
    const success = await data.triggerValidation();
    if (model && success) {
      let startDate = model.startDate ? model.startDate : undefined;
      let EndDate = model.endDate ? model.endDate : undefined;
      let params: IAttendaceHistoryMultiple = {
        campusId: model.campusId,
        startDate: startDate,
        endDate: EndDate,
        exportType: model.exportType,
        programId: model.programId,
        leadGroupIds: model?.leadGroupIds,
        singleStudent : model.singleStudent,
        studentEnrollmentId: model.studentNameBadgeId,
        studentName:model.studentName,
        groupByStu: model.groupByStu,
      };
      return params;
    }
    return {} as IAttendaceHistoryMultiple;
  };

  const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
    const item = items.find((opt) => {
      if (opt.id == value) return opt;
    });
    return item || ({} as DropDownListItem);
  };

  let parameters = (
    <div className={classes.root}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={2}>
              <Grid item  md={6} sm={6} xs={12} >
                            <FormControlLabel
                                        id="lblSingleStudent"
                                        className={classes.switch}
                                        label="Single Student"
                                        control={
                                            <Switch
                                                checked={model.singleStudent}
                                                name="singleStudent"
                                                onChange={(e: any) => {
                                                  model.singleStudent= e ? e.target.checked : false;
                                                  setModel({ ...model });
                                                }}
                                                color="primary"
                                            />
                                        }
                                    />
                                     </Grid>
            <Grid item  md={6} sm={6} xs={12} >
          <FormControlLabel
                label="Order by Student"
                className={classes.switch}
                disabled={model.singleStudent}
                control={
                  <Switch
                    checked={model.groupByStu}
                    value="groupByStu"
                    onChange={(e: any) => {
                      model.groupByStu= e ? e.target.checked : false;
                      setModel({ ...model });
                    }}
                    color="primary"
                  />
                }
              />
            </Grid>

<Grid item md={12} sm={12} xs={12} spacing={1}>

  
    
      <StudentNameBadgeIDFilter   model={{ studentNameBadgeId: model.studentNameBadgeId, studentName: model.studentName }} show={model.singleStudent}  setModel={(values) =>   handleStudentNameBadgeIDFilterOnChange(  values )}/>

  
</Grid>
                
            <Grid item md={6} sm={6} xs={12}>
              <CampusForCurrentUserAutoComplete
                id="toCampus"
                name="toCampus"
                label="Campus *"
                disabled={model.singleStudent}
                filterHandle={(v: any) => {
                  handleFieldOnChange("campusId", v ? v.value : undefined);
                }}
                valueFilter={
                  model.campusId
                    ? {
                        key: "value",
                        values: [model.campusId],
                      }
                    : undefined
                }
                error={!!data.errors.toCampus}
                inputRef={data.register({ required: !model.singleStudent })}
                helperText={
                  data.errors.toCampus ? "Campus is required." : undefined
                }
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Autocomplete
                {...exportTypeAutoComplete}
                autoComplete
                includeInputInList
                onChange={(e: any, value: any) => {
                  handleFieldOnChange(
                    "exportType",
                    value ? value : undefined,
                    (v: any) => v?.id
                  );
                }}
                value={getSelectedItem(
                  exportTypes,
                  model.exportType.toString()
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Export Type"
                    name="exportType"
                    error={!!data.errors.exportType}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.exportType
                        ? "Export Type is required."
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="startDate"
                  name="startDate"
                  label="Start Date *"
                  value={model.startDate ? model.startDate : null}
                  onChange={(e: any, value: any) =>
                    handleFieldOnChange("startDate", value ? value : undefined)
                  }
                  className={classes.startDateField}
                  KeyboardButtonProps={{
                    "aria-label": "report date",
                  }}
                  error={!!data.errors["startDate"]}
                  innerRef={(ref: any) => {
                    data.register("startDate", {
                      required: true,
                      validate: {
                        beforeEndDate: (value) =>
                         new Date(value) < new Date(data.getValues()["endDate"]) ||
                          "Start Date must be before end date",
                      },
                    });
                    data.setValue("startDate", model.startDate);
                  }}
                  helperText={
                    data.errors["startDate"]
                      ? (data.errors["startDate"] as FieldError).message
                      : undefined
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="endDate"
                  name="endDate"
                  label="End Date *"
                  value={model.endDate ? model.endDate : null}
                  onChange={(e: any, value: any) =>
                    handleFieldOnChange("endDate", value ? value : null)
                  }
                  className={classes.startDateField}
                  KeyboardButtonProps={{
                    "aria-label": "end date",
                  }}
                  error={!!data.errors["endDate"]}
                  innerRef={(ref: any) => {
                    data.register("endDate", {
                      required: true,
                      validate: {
                        beforeEndDate: (value) =>
                         new Date(value) > new Date(data.getValues()["startDate"]) ||
                          "End Date must be after Start date",
                      },
                    });
                    data.setValue("endDate", model.endDate);
                  }}
                  helperText={
                    data.errors["endDate"]
                      ? (data.errors["endDate"] as FieldError).message
                      : undefined
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <ProgramVersionAutoComplete
                selectAll={true}
                name="programId"
                campusId = {model.campusId}
                filterHandle={(value: any) =>
                  handleFieldOnChange("programId", value, setProgramVersion)
                }
                valueFilter={
                  model.programId
                    ? {
                        key: "value",
                        values: model.programId,
                      }
                    : undefined
                }
                multiple={true}
                disabled={model.singleStudent}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
                  <StudentGroupsAutoComplete
                    id="leadGroupIds"
                    name="leadGroupIds"
                    campusId = {model.campusId}
                    disabled={model.singleStudent}
                    filterHandle={(groups: any) => {
                      handleFieldOnChange(
                        "leadGroupIds",
                        groups,
                        setStudentGroup
                      );
                    }}
                    label="Student Group"
                    multiple={true}
                    valueFilter={
                      model?.leadGroupIds
                        ? {
                            key: "value",
                            values: model?.leadGroupIds,
                          }
                        : undefined
                    }
                  />
                </Grid>
          </Grid>
        </form>
      </FormContext>
    </div>
  );
  reportOverview.parameters = parameters;

  return (
    <ReportOverview
      reportOverview={reportOverview}
      filterHandler={filterHandler}
      getReportParameters={getReportParameters}
      exportHandler={onSubmit}
      preview={preview}
      previewLoader={previewLoader}
    />
  );
};
export default AttendanceHistoryMultiple;
