import { Button, Card, CardActions, CardContent, CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { toastr } from 'react-redux-toastr';
import CampusForCurrentUserAutoComplete from '../../../../components/AutoComplete/CampusForCurrentUserAutoComplete';
import ProgramsAutoComplete from '../../../../components/AutoComplete/ProgramsAutoComplete';
import ProgramVersionAutoComplete from '../../../../components/AutoComplete/ProgramVersionAutoComplete';
import { EmptyGuid } from '../../../../utils/constants';
import ProgramVersionCourse from '../../../../components/AutoComplete/ProgramVersionCourse';
import CourseGradeBookAutoComplete from '../../../../components/AutoComplete/CourseGradeBookAutoCompleteLMS';
import APIHook from '../../../../api/hook';
import InputField from '../../../../components/_Layout/Inputs/InputField';
import IGradeComponentLMSMapping from '../../../../interfaces/setup/academics/ProgramDefinition/IGradeComponentLMSMapping';
import { getGradeBookComponentsLMSMappings, updateGradeBookComponentsLMSMappings } from '../../../../api/setup/academics/courses';
import ProgressSaveButton from '../../../../components/_Layout/Buttons/ProgressSaveButton';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		card: {
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
		},
		content: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			height: '80%',
		},
		tableContainer: {
			marginTop: theme.spacing(2),
		}
	}
	)
);

const GradeComponents: React.FC = () => {
	const classes = useStyles({});
	const [campusId, setCampusId] = React.useState<string>();
	const [programId, setProgramId] = React.useState<string>();
	const [programVersionId, setProgramVersionId] = React.useState<string>();
	const [course, setCourse] = React.useState<string>();
	const [gradeBook, setGradeBook] = React.useState<string>();
	const [gradeBookDT, , , loading, call, reset, updateData] = APIHook<Array<IGradeComponentLMSMapping>, typeof getGradeBookComponentsLMSMappings>(getGradeBookComponentsLMSMappings);
	const [, savingMessage, savingStatus, saving, save,] = APIHook<Array<IGradeComponentLMSMapping>, typeof updateGradeBookComponentsLMSMappings>(updateGradeBookComponentsLMSMappings);
	React.useEffect(() => {
		setProgramId(undefined);
	}, [campusId]);

	React.useEffect(() => {
		setProgramVersionId(undefined);
	}, [programId]);

	React.useEffect(() => {
		setCourse(undefined);
	}, [programVersionId]);

	React.useEffect(() => {
		setGradeBook(undefined);
	}, [course]);

	React.useEffect(() => {
		if (gradeBook) {
			call(gradeBook);
		}
		else {
			reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gradeBook]);

	React.useEffect(() => {
		if (!saving && savingStatus) {
			if (savingStatus === 200) {
				toastr.success('', 'Gradebook components saved successfully');
			}
			else if (savingStatus !== 200) {
				toastr.error('', savingMessage || 'Error saving gradebook components');
			}
		}
	}, [savingMessage, savingStatus, saving]);

	return (
		<Card className={classes.card}>
			<CardContent className={classes.content}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<CampusForCurrentUserAutoComplete
							filterHandle={(v: any) => setCampusId(v && v.value ? v.value : undefined)}
							valueFilter={
								campusId
									? {
										key: 'value',
										values: [campusId],
									}
									:
									undefined
							} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<ProgramsAutoComplete
							label='Program'
							disabled={!campusId}
							campusId={campusId || EmptyGuid}
							filterHandle={(v: any) => setProgramId(v && v.value ? v.value : undefined)}
							valueFilter={
								programId
									? {
										key: 'value',
										values: [programId],
									}
									:
									undefined
							}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<ProgramVersionAutoComplete
							disabled={!programId}
							programId={programId || EmptyGuid}
							campusId={campusId}
							valueFilter={
								programVersionId
									? {
										key: 'value',
										values: [programVersionId],
									}
									:
									undefined
							}
							filterHandle={(v: any) => setProgramVersionId(v && v.value ? v.value : undefined)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<ProgramVersionCourse
							disabled={!programVersionId}
							progamVersionIds={programVersionId || EmptyGuid}
							valueFilter={ 
								course
									? {
										key: 'value',
										values: [course],
									}
									:
									undefined
							}
							filterHandle={(v: any) => setCourse(v && v.value ? v.value : undefined)} 
						/>
					</Grid>
					<Grid item xs={false} sm={6}>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CourseGradeBookAutoComplete
							disabled={!course}
							courseId={course}
							filterHandle={(v: any) => setGradeBook(v && v.value ? v.value : undefined)}
							valueFilter={
								gradeBook
									? {
										key: 'value',
										values: [gradeBook],
									}
									:
									undefined
							}
						/>
					</Grid>
				</Grid>
				{gradeBook &&
					<TableContainer className={classes.tableContainer}>
						<Table stickyHeader size="small">
							<TableHead>
								<TableRow>
									<TableCell variant="head">Gradebook Component</TableCell>
									<TableCell variant="head">LMS Id</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									loading && <TableRow><TableCell align="center" colSpan={2}><CircularProgress color="primary" size={50} /></TableCell></TableRow>
								}
								{
									!loading && gradeBookDT && gradeBookDT.sort((a:any,b:any)=>a.componentName.localeCompare(b.componentName)).map((gradeBookComponent, idx) => (
										<TableRow key={idx}>
											<TableCell>{gradeBookComponent.componentName}</TableCell>
											<TableCell><InputField defaultValue={gradeBookComponent.lmsId || ''} onChange={(e) => {
												updateData(gradeBookDT.map((x, i) => i === idx ? { ...x, lmsId: e.target.value || undefined } : x));
											}} /></TableCell>
										</TableRow>
									))
								}
							</TableBody>
						</Table>
					</TableContainer>}
			</CardContent>
			<CardActions>
				<Button variant="contained" size="small" color="secondary" onClick={() => setCampusId(undefined)}>
					New
				</Button>
				{' '}
				<ProgressSaveButton loading={saving} text="Save" disabled={!gradeBook} size="small" onClick={() => gradeBookDT && save(gradeBookDT)} />

			</CardActions>
		</Card>
	);
};

export default GradeComponents;
