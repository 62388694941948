import * as React from "react";
import { useEffect, useRef } from "react";
import { apiAutoCompleteConfig } from "../../constants/apiAutoComplete.config";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../constants/actions.constants";
import { FilterParams } from "../../interfaces/common/FilterParams";

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& label.Mui-focused": {
				color: theme.palette.primary.white
			}
		}
	})
)(TextField);

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {}
	})
);

const HousingAutoComplete = (props: FilterParams) => {
	const classes = useStyles({});
	const inputRef: any = useRef(null);

	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const housingOnChange = (e: any) => {
		if (props.filterHandle) {
			props.filterHandle(e);
		}
	};

	return (
		<div>
			<ApiAutoComplete
				config={apiAutoCompleteConfig.housing}
				loading={userSelectedCampus ? false : true}
				reqParams={{ campusId: userSelectedCampus }}
				classes={{
					option: "text-black"
				}}
				label={props.label ? props.label : "Housing"}
				onChange={(e: any) => {
					housingOnChange(e);
				}}
				multiple={props.multiple || false}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
				inputRef={props.inputRef ? props.inputRef : inputRef}
				error={props.error}
				helperText={props.helperText}
				inputName={props.name}
				maxTags={props.maxTags}
				includeAllValue={props.includeAllValue}
				showIncludeAllValue={props.showIncludeAllValue}
				chipSize={props.chipSize}
			></ApiAutoComplete>
		</div>
	);
};
export default HousingAutoComplete;
