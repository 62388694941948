import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import * as reportsApi from '../../../src/api/reports/allReports/reportsApi';
import AdvantageViewer from '../../components/Common/AdvantageViewer';
import Filter from '../../components/Filter/filter';
import ComponentList from '../../components/routing/ComponentList';
import { TagsColor } from '../../components/_Layout/TagManager/tagConfig';
import * as actionTypes from '../../constants/actions.constants';
import { system } from '../../constants/system/systemConstants';
import { Tag } from '../../interfaces/common/Tag';
import { Report } from '../../interfaces/reports/Report';
import Results from '../../pages/reports/Results';

const useStyles = makeStyles((theme: any) => ({
  container: {
    height: '100%',
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginRight: -theme.spacing(3),
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3),
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  filter: {
    marginTop: theme.spacing(1),
  },
  results: {
    marginTop: theme.spacing(1),
  },

  dialogTitle: {
    padding: theme.spacing(0, 0.5),
  },
  dialogCloseButton: {
    padding: theme.spacing(1, 1),
    float: 'right',
  },
  dialogBody: {
    minHeight: theme.spacing(87.5),
    display: 'inline-grid',
    padding: theme.spacing(1, 3, 3, 3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
}));

const getReportListDetails = (campusId: string, userId: string) => {
  return reportsApi.getReportList(campusId, userId, 0, null).then(
    (response: any) => {
      if (response) {
        if (response.result.length > 0) {
          let data = response.result;
          let reports = data.map((rep: any) => {
            return {
              name: rep.name,
              id: rep.id,
              description: rep.description,
              modules: [],
              // tags: [],
              tags: rep.reportTagList.map((tag: any) => {
                if (tag) {
                  return {
                    text: tag.text,
                    color:
                      TagsColor[
                        tag?.text?.toLowerCase().replace(/ +/g, '') ?? ''
                      ],
                    id: tag.id,
                  } as Tag;
                }
                return undefined;
              }),
              favorited: rep.favorited,
              typeId: rep.typeId,
              resourceURL: rep.resourceURL,
              recentlyUsed: rep.recentlyUsed,
              reportClass: rep.reportClass,
              creationMethod: rep.creationMethod,
              isUnLock: rep.isUnLock,
            } as Report;
          });
          return reports;
        }
      }
      return [];
    },
    (_exception: any) => []
  );
};

const Reports = () => {
  const [reportsList, setReportsList] = React.useState<Report[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [selectedReport, setSelectedReport] = React.useState<Report>();
  const [tags, setTags] = React.useState<Array<Tag>>([]);
  const [filter, setFilter] = React.useState('');
  const classes = useStyles({});

  const campusId = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const userId = useSelector((state: any) => state.session.user.userId);

  const dispatch = useDispatch();

  const onDragEnd = React.useCallback((result) => {
    const { destination } = result;

    if (!destination) {
      return;
    }

    dispatch({
      type: actionTypes.dragConstants.DRAG_ENDED,
      dragData: result,
    });
  }, []);
  const onDragStart = React.useCallback((result) => {
    dispatch({
      type: actionTypes.dragConstants.DRAG_STARTED,
    });
  }, []);

  const closeViewer = () => {
    setSelectedReport(undefined);
  };

  const openReportViewer = (report: Report): void => {
    setSelectedReport(report);
    if (!report.recentlyUsed) {
      addToRecent(report);
    }
  };

  const addToRecent = async (report: Report): Promise<void> => {
    const response = await reportsApi.recentlyUsedPrefernce(
      report.id,
      report.typeId,
      userId
    );
    if (response) {
      if (campusId && userId) {
        getReportListDetails(campusId, userId).then((r: Report[]) => {
          setReportsList(r);
        });
      }
    }
  };

  const favoriteToggle = async (
    report: Report,
    favorite: boolean
  ): Promise<void> => {
    const response = await reportsApi.favoritePrefernce(
      report.id,
      report.typeId,
      userId,
      favorite
    );
    if (response) {
      report.favorited = favorite;
      setReportsList([...reportsList]);
    }
  };

  const SelectedReportComponent: any = selectedReport
    ? ComponentList.GetComponentByName(selectedReport.reportClass)
    : undefined;

  React.useEffect(() => {
    if (campusId && userId) {
      setLoading(true);
      getReportListDetails(campusId, userId).then((r: Report[]) => {
        setReportsList(r);
        setLoading(false);
      });
    }
  }, [campusId, userId]);

  return (
    <div className={classes.container}>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Filter
          className={classes.filter}
          chips={tags}
          setChips={setTags}
          setFilter={setFilter}
          favorites={reportsList.filter((r: Report) => r.favorited)}
          openReportViewer={openReportViewer}
        />
        {loading ? (
          <CircularProgress className={classes.backdrop} color="inherit" />
        ) : (
          <Results
            className={classes.results}
            closeViewer={closeViewer}
            tags={tags}
            filter={filter}
            reportsList={reportsList}
            openReportViewer={openReportViewer}
            favoriteToggle={favoriteToggle}
          />
        )}
      </DragDropContext>
      <Dialog
        open={!!selectedReport}
        onClose={closeViewer}
        PaperComponent={Paper}
        maxWidth="xl"
        fullWidth
        PaperProps={{ square: true }}
        aria-labelledby="report-dialog"
        aria-describedby="report-viewer"
        disableBackdropClick={true}
      >
        <DialogTitle
          className={classes.dialogTitle}
          disableTypography
          id="attendance-dialog-title"
        >
          <IconButton
            aria-label="close"
            onClick={closeViewer}
            className={classes.dialogCloseButton}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogBody}>
          {selectedReport &&
            (selectedReport.creationMethod ===
            system.reports.creationMethod.reactComponent ? (
              React.createElement(SelectedReportComponent, {
                report: selectedReport,
              })
            ) : (
              <AdvantageViewer url={selectedReport.resourceURL} />
            ))}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Reports;
