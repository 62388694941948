import * as React from 'react';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Header from './Header';
import Results from './Results';
import { Paper, Grid, Button, IconButton } from '@material-ui/core';
import UserMain from './userMain/UserMain';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { User } from '../../../../interfaces/user/User';
import { groupBy } from "../../../../utils/arrayFunctions";
import { UserRole } from "../../../../interfaces/systemCatalog/UserRole";
import { Campus } from "../../../../interfaces/systemCatalog/ICampus";
import CustomSnackbar from '../../../../components/notifications/CustomSnackbar';
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme: any) => ({
    root: {
        padding: theme.spacing(3),
        height: '100%',
        overflowY: "scroll",

    },
    results: {
        marginTop: theme.spacing(3),
        height: '100%',
        width: '100%'
    },
    backButton:
    {
        marginBottom: theme.spacing(1)
    }
}));

type UserFilters = {
    active?: boolean,
    userId?: string,
    roleId?: string,
    campusIds?: Array<string>,
    permissionIds?: Array<number>
}
const flattenUserRoles = (userRoles: UserRole[]) => {
    if (userRoles && userRoles.length > 0) {
        let groupedUserRoles = groupBy(userRoles, 'roleId');
        return groupedUserRoles.map((ur: any, index: number) => {
            const roleId = ur[0].roleId;
            const isAllCampuses: boolean = ur[0].isAllCampuses;
            let campuses: Array<Campus> = [];
            isAllCampuses ? campuses.push({ id: "All", name: 'All' }) : ur.forEach((cmp: any) => campuses.push({ id: cmp.campusId }));
            let mappedUserRole: UserRole = {
                id: index + 1, role: { id: roleId }, campuses: campuses
            };

            return mappedUserRole;
        })
    }
}
const UserList = () => {
    const classes = useStyles({});


    const [isAddingUser, setIsAddingUser] = useState<boolean>(false)
    const [editingUser, setEditingUser] = useState<User | null>(null)
    const [params, setParams] = useState<UserFilters | null>(null)
    const [showSnackBar, setShowSnackBar] = React.useState<boolean>(false);
    const [messageInfo, setMessageInfo] = React.useState<string | undefined>(undefined);
    const [snackBarVariant, setSnackBarVariant] = React.useState<'success' | 'error' | 'warning' | 'info'>('info');
    const renderUserList = (state?: 'success' | 'error' | 'warning' | 'info', message?: string) => {
        setIsAddingUser(false);
        setEditingUser(null);

        if (state && message) {
            setSnackBarVariant(state);
            setMessageInfo(message);
            setShowSnackBar(true);
        }
    }
    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );
    React.useEffect(() => {    
        setParams((p: any) => ({ ...p, campusIds: userSelectedCampus, userId: undefined }))
    }, [userSelectedCampus])

    const handleStatusChange = React.useCallback((e: any) => {
        if (e && e.text) {
            setParams((p: any) => ({ ...p, status: e.text.toLowerCase() === "active" }))
        }
        else
            setParams((p: any) => ({ ...p, status: undefined }))
    },[])
    const handleUserChange = (e: any) => {
        if (e && e.text) {
            setParams((p: any) => ({ ...p, userId: e.text }))
        }
        else
            setParams((p: any) => ({ ...p, userId: undefined }))
    }
    const handleCampusChange = (e: any) => {
        if (e && e.length > 0) {
            setParams((p: any) => ({ ...p, campusIds: e.map((c: any) => { return c.value }) }))

        }
        else
            setParams((p: any) => ({ ...p, campusIds: undefined }))
    }

    const handlePermissionChange = (e: any) => {
        if (e && e.length > 0) {
            setParams((p: any) => ({ ...p, permissionIds: e.map((p: any) => { return p.permissionId }) }))

        }
        else
            setParams((p: any) => ({ ...p, permissionIds: undefined }))
    }

    const handleEditingUser = React.useCallback((user: User) => {

        setEditingUser((prevState: any) => user);
    }, [])

    let contentHeader;
    let contentBody;

    if (isAddingUser || editingUser != null) {
        contentHeader = <Grid
            alignItems="flex-start"
            container
            justify="flex-start"
        >

            <Grid item>
                <Button
                    className={classes.backButton}
                    color="secondary"
                    variant="contained"
                    onClick={() => { renderUserList(); }}
                >
                    <ArrowBackIcon /> Back
                </Button>
            </Grid>
        </Grid>;

        contentBody = (<UserMain user={editingUser} isEditingUser={editingUser != null} onComplete={renderUserList} />)
    }
    else {
        contentHeader = (
            <Header setIsAddingUser={setIsAddingUser} userNameFilterHandle={handleUserChange} statusFilterHandle={handleStatusChange} campusFilterHandle={handleCampusChange} permissionFilterHandle={handlePermissionChange} />
        )

        contentBody = (<Results
            setSelectedUser={handleEditingUser}
            className={classes.results}
            params={params}
        />);
    }

    return (
        <Paper
            className={classes.root}
        >
            <CustomSnackbar
                variant={snackBarVariant}
                message={messageInfo}
                open={showSnackBar}
                onClose={(event?: React.SyntheticEvent, reason?: string) => {
                    setShowSnackBar(false);
                }}
            ></CustomSnackbar>
            {contentHeader}
            {contentBody}
        </Paper>
    );
};

export default UserList;
