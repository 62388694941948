/* eslint-disable react/no-multi-comp */
import * as React from "react";
import { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { Card } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import getParams from "../../utils/getParams";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			border: "none",
		},

		paper: {
			height: "100%",
		},

		invisible: {
			display: "none",
		},
		contentWrapper: {
			paddingTop: theme.spacing(2),
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			paddingBottom: theme.spacing(2),
			height: "100%",
			background: theme.palette.white,
		},
	})
);

type IAdvantageViewerProps = {
	url?: string;
	params?: any;
	children?: React.ReactNode;
	onCallBack?: any;
	elevation?: any;
	className?: any;
	onParentRefresh?: any;
};
const AdvantageViewer = (props: IAdvantageViewerProps) => {
	let { children, params, onCallBack, ...rest } = props;
	const session = useSelector((state: any) => state.session);
	const appConfig: any = session.appConfig.appConfig;
	const userState = useSelector((state: any) => state.session.user);

	const [authenticated, setIsAuthenticated] = useState(true);
	const [loaded, setLoaded] = useState(false);
	let advParams: string = "";
	if (params) {
		advParams = "&" + getParams(params);
	}
	if (userState?.tenantTheme) {
		advParams = advParams + "&theme=" + userState?.tenantTheme;
	}

	const appUrl = localStorage.getItem('SiteUrl') || appConfig.SiteUrl;

	const [url, setUrl] = useState(appUrl + props.url + advParams);

	React.useEffect(() => {
		setUrl(appUrl + props.url + advParams);
	}, [params]);

	const classes = useStyles({});

	const handleMessage = (event: any) => {
		if (event.origin === (localStorage.getItem('HostUrl') || appConfig.TrustedDomain)) {
			let task = event.data; // task received in postMessag
			switch (task) {
				case "RENEW_SESSION":
					setIsAuthenticated(false);
					break;
			}
		} else if (event.origin === appUrl) {
			let task = event.data;
			switch (task) {
				case "FORM_COMPLETE":
					onCallBack();
					break;
				case "REFRESH_PARENT":
					if (props.onParentRefresh) {
						props.onParentRefresh();
					}
					break;
			}
		}
	};

	React.useEffect(() => {
		window.addEventListener("message", handleMessage);

		return function cleanup() {
			window.removeEventListener("message", handleMessage);
		};
	}, []);

	React.useEffect(() => {
		setIsAuthenticated(false);
	}, [session]);

	React.useEffect(() => {
		if (!authenticated) {
			let token = session.user.tokenNoAuthorization ? session.user.tokenNoAuthorization : session.user.token;
			let tokenUrl = localStorage.getItem('HostUrl') || appConfig.HostUrl;
			setUrl(tokenUrl + "/default.aspx" + "?token=" + token);
		}
	}, [authenticated]);

	const handleLoad = (e: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
		setLoaded(true);
		if (!authenticated) {
			setIsAuthenticated(true);
			setUrl(appUrl + props.url + advParams);
			window.dispatchEvent(new Event("resize"));
		}
	};
	return (
		<React.Fragment>
			<Card
				className={
					props.className ?? clsx([classes.paper, classes.contentWrapper])
				}
				elevation={props.elevation ?? 2}
			>
				<LinearProgress className={clsx({ [classes.invisible]: loaded })} />
				<iframe
					id="advantage_viewer"
					className={classes.root}
					src={url}
					height="100%"
					width="100%"
					onLoadStart={(e) => setLoaded(false)}
					onLoad={(e) => handleLoad(e)}
				></iframe>
			</Card>
		</React.Fragment>
	);
};
AdvantageViewer.defaultProps = {
	url: "/dash.aspx?resid=264&mod=SY&desc=dashboard",
	component: "nav",
};

export default AdvantageViewer;
