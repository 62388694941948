import { IStudentDocument } from "../../../interfaces/student/documents/IStudentDocument";
import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  makeStyles,
  createStyles,
  Link,
  Button,
  TextField,
  withStyles,
} from "@material-ui/core";

import * as documentsApi from "../../../api/student/documents/documentsApi";
import { useSelector } from "react-redux";
import { Save, CheckBox } from "@material-ui/icons";
import CustomTableOptions from "../../../components/Tables/CustomTableOptions";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useForm, FieldError, FormContext } from "react-hook-form";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    tableWrapper: {
      width: "100%",
    },
    rowOpenBottom: {
      borderBottom: "2pt solid",
      borderBottomColor: theme.palette.site.secondary,
    },
    rowOpenRight: {
      borderRight: "2pt solid",
      borderRightColor: theme.palette.site.secondary,
    },
    rowOpenLeft: {
      borderLeft: "2pt solid",
      borderLeftColor: theme.palette.site.secondary,
    },
    lineThru: {
      textDecoration: "line-through",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "100%",
      background: "white",
      borderRadius: theme.spacing(0.5),
    },
    datePickerField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),

      background: "white",
      borderRadius: theme.spacing(0.5),
      "& .MuiInputBase-root": {
        "& input": {
          width: "100px",
        },
      },
    },
  })
);

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& .MuiFormLabel-root": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "80%",
      },
    },
  })
)(TextField);

type DocumentRowProps = {
  row: IStudentDocument;
  confimationModalProps: any;
  setConfirmationModalProps: any;
  setSnackBarProps: any;
  setFileUri: any;
  refreshDocumentGrid: any;
  setFileName: any;
  setFileExtension: any;
  setOpenPreview: any;
  isArchiveHidden: true | undefined;
};

const DocumentGridDocumentRow = (props: DocumentRowProps) => {
  const classes = useStyles({});
  const data = useForm<any>({ mode: "onBlur" });
  const [model, setModel] = useState<IStudentDocument>(props.row);
  const studentId = useSelector((state: any) => state.student.studentId);

  const deleteDocument = (fileName: string) => {
    documentsApi.softDelete({ studentId: studentId, fileName: fileName }).then(
      (response: any) => {
        if (response.resultStatus === 0) {
          props.refreshDocumentGrid();
          handleOnChange("isArchived", true)
          props.setSnackBarProps((props: any) => {
            return {
              variant: "success",
              showSnackBar: true,
              messageInfo: response.resultStatusMessage,
            };
          });
        } else {
          props.setSnackBarProps((props: any) => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo: response.data,
            };
          });
        }
      },
      (exception: any) => {
        props.setSnackBarProps((snackBarProps: any) => {
          return {
            variant: "error",
            showSnackBar: true,
            messageInfo: exception,
          };
        });
      }
    );
  };

  const handleDelete = () => {
    props.setConfirmationModalProps((state: any) => {
      return {
        ...state,
        open: true,
        confirmationText: "Are you sure you want to delete this document?",
        confirmationButton: "Yes",
        title: "Delete Document",
        onConfirmEvent: () => {
          deleteDocument(model.uriName);
          props.setConfirmationModalProps((updatedState: any) => {
            return { ...updatedState, open: false };
          });
        },
        onCancelEvent: () => {
          props.setConfirmationModalProps((updatedState: any) => {
            return { ...updatedState, open: false };
          });
        },
      };
    });
  };

  const updateDocument = () => {
    documentsApi
      .updateDocument({
        studentId: studentId,
        fileName: model.uriName,
        name: model.name,
        receivedDate: model.receivedDate,
        requestedDate: model.requestedDate,
      })
      .then(
        (response: any) => {
          if (response.resultStatus === 0) {
            props.refreshDocumentGrid();
            handleEdit();
            props.setSnackBarProps((props: any) => {
              return {
                variant: "success",
                showSnackBar: true,
                messageInfo: response.resultStatusMessage,
              };
            });
          } else {
            props.setSnackBarProps((props: any) => {
              return {
                variant: "error",
                showSnackBar: true,
                messageInfo: response.data,
              };
            });
          }
        },
        (exception: any) => {
          props.setSnackBarProps((snackBarProps: any) => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo: exception,
            };
          });
        }
      );
  };

  const handleSaveClick = () => {
    data.triggerValidation().then((validation: any) => {
      if (validation) {
        updateDocument();
      }
    });
  };

  const handleFileClick = () => {
    props.setFileUri(model.uriName);
    props.setFileName(model.name);
    props.setOpenPreview(true);
    props.setFileExtension(model.fileExtension)
  };

  const handleEdit = () => {
    let updatedModel = model;
    updatedModel.isEditModeOn = !updatedModel.isEditModeOn;
    setModel({ ...updatedModel });
  };

  const handleOnChange = (fieldId: string, value: any) => {
    let updatedModel = model;
    (updatedModel as any)[fieldId] = value;
    setModel({ ...updatedModel });
    data.setValue(fieldId, value);
    data.triggerValidation(fieldId);
  };

  //conditional rendering
  let nameComponent,
    optionsComponent,
    dateRequestedComponent,
    dateReceivedComponent;
  if (model.isEditModeOn) {
    nameComponent = (
      <ThemeTextField
        id="name"
        className={classes.textField}
        label="Document Name*"
        value={model.name}
        onChange={(e: any) => handleOnChange("name", e.target.value)}
        inputRef={(ref: any) => {
          data.register("name", { required: true });
          data.setValue("name", model.name);
        }}
        error={!!data.errors.name}
        disabled={true}
        helperText={
          data.errors.name
            ? (data.errors.name as FieldError).message
            : undefined
        }
      />
    );
    dateRequestedComponent = (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          clearable
          format={"MM/dd/yyyy"}
          placeholder="mm/dd/yyyy"
          id="requestedDate"
          name="requestedDate"
          label="Requested Date"
          TextFieldComponent={ThemeTextField}
          className={classes.datePickerField}
          value={model.requestedDate ? model.requestedDate : null}
          onChange={(value: any) => {
            handleOnChange("requestedDate", value ? value : null);
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          autoOk={true}
          required
          error={!!data.errors["requestedDate"]}
          innerRef={(ref: any) => {
            data.register("requestedDate", { required: true });
            data.setValue("requestedDate", model.receivedDate);
          }}
          helperText={
            data.errors["requestedDate"]
              ? (data.errors["requestedDate"] as FieldError).message
              : undefined
          }
        />
      </MuiPickersUtilsProvider>
    );
    dateReceivedComponent = (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          clearable
          format={"MM/dd/yyyy"}
          placeholder="mm/dd/yyyy"
          id="receivedDate"
          name="receivedDate"
          label="Received Date"
          TextFieldComponent={ThemeTextField}
          className={classes.datePickerField}
          value={model.receivedDate ? model.receivedDate : null}
          onChange={(value: any) => {
            handleOnChange("receivedDate", value ? value : null);
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          autoOk={true}
          required
          error={!!data.errors["receivedDate"]}
          innerRef={(ref: any) => {
            data.register("receivedDate", { required: true });
            data.setValue("receivedDate", model.receivedDate);
          }}
          helperText={
            data.errors["receivedDate"]
              ? (data.errors["receivedDate"] as FieldError).message
              : undefined
          }
        />
      </MuiPickersUtilsProvider>
    );
    optionsComponent = (
      <Button
        startIcon={<Save />}
        onClick={(e: any) => {
          handleSaveClick();
        }}
      ></Button>
    );
  } else {
    nameComponent = (
      <Link
        className={model.isArchived ? classes.lineThru : ""}
        href="#"
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          handleFileClick();
        }}
        variant="body2"
      >
        {model.name}
      </Link>
    );
    dateRequestedComponent = (
      <React.Fragment>
        {model.requestedDate
          ? new Date(model.requestedDate).toLocaleDateString()
          : ""}
      </React.Fragment>
    );
    dateReceivedComponent = (
      <React.Fragment>
        {model.receivedDate
          ? new Date(model.receivedDate).toLocaleDateString()
          : ""}
      </React.Fragment>
    );
    optionsComponent = (
      <CustomTableOptions
        onDelete={() => {
          handleDelete();
        }}
        onEdit={() => {
          handleEdit();
        }}

        disableDeleteButton={model.isArchived === true ? true : undefined}
        disableEditButton={model.isArchived === true ? true : undefined}
      />
    );
  }
  return (
    <FormContext {...data}>
      <React.Fragment>
        <TableRow key={"document_" + model}>
          <TableCell>{nameComponent}</TableCell>
          <TableCell>{model.requestedBy}</TableCell>
          <TableCell>{dateRequestedComponent}</TableCell>
          <TableCell>{dateReceivedComponent}</TableCell>
          <TableCell>{model.uploadedBy}</TableCell>
          <TableCell>
            {model.uploadedDate
              ? new Date(model.uploadedDate).toLocaleDateString()
              : ""}
          </TableCell>
          {!props.isArchiveHidden && <TableCell>{model.isArchived ? <CheckBox /> : ""}</TableCell>}
          <TableCell>{optionsComponent}</TableCell>
        </TableRow>
      </React.Fragment>
    </FormContext>
  );
};

export default DocumentGridDocumentRow;
