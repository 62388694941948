import API from "../apiWrapper";
import { StudentSearchModel } from "../../interfaces/student/search/SearchModel";
import { UpdateMRU } from "../../interfaces/systemCatalog/UpdateMRU";
import { IStudentInfo } from "../../interfaces/student/profile/studentInfo/IStudentInfo";
import { AdvanceSearchModel } from "../../interfaces/student/search/AdvanceSearchModel";
import { IStudentNavigation } from "../../interfaces/student/navigation/studentNavigation";
import { stringify } from "qs";
import { IEnrollmentSearchModel } from "../../interfaces/student/search/EnrollmentSearchModel";

export const getMRU = () => {
	return API()
		.get("/SystemCatalog/MostRecentlyUsed/GetMRU", {
			params: { mruTypes: 1 },
		})
		.then(
			(res: any) => {
				if (res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

export const updateMRU = (params: UpdateMRU) => {
	return API()
		.post("/SystemCatalog/MostRecentlyUsed/InsertMostRecentlyUsed", params)
		.then(
			(res: any) => {
				if (res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

export const getMruOrDefaultForCampusUser = (campusId: string) => {
	return API()
		.get("/SystemCatalog/MostRecentlyUsed/GetMruOrDefaultForCampusUser", {
			params: { mruTypes: 1, campusId: campusId },
		})
		.then(
			(res: any) => {
				if (res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

export const studentProfileSearch = (params: StudentSearchModel) => {
	return API()
		.get("/AcademicRecords/Student/StudentProfileSearch", {
			params: params,
		})
		.then(
			(res: any) => {
				if (res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error;
			}
		);
};


export const enrollmentSearch = (params: IEnrollmentSearchModel) => {
	return API()
		.get("/AcademicRecords/Enrollment/EnrollmentSearch", {
			params: params,
		})
		.then(
			(res: any) => {
				if (res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

export const GetStudentsProfileInfo = (studentIds: string[], isNew: boolean) => {
	return API()
		.post("/AcademicRecords/Student/GetStudentsProfileInfo", {
			studentIds,
			isNew,
		})
		.then(
			(res: any) => {
				if (res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

export const GetStudentProfileInfo = (leadId: string, isNew? : boolean ) => {
	return API()
		.get("/AcademicRecords/Student/GetStudentProfileInfo", {
			params: { leadId, isNew },
			paramsSerializer: (params) => {
				return stringify(params);
			},
		})
		.then(
			(res: any) => {
				if (res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

export const UpdateStudentsProfile = (studentInfo: IStudentInfo) => {
	return API()
		.post("/AcademicRecords/Student/UpdateStudentProfileInfo", studentInfo)
		.then(
			(res: any) => {
				if (res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

export const GetSsn = (leadId: string) => {
	return API()
		.get("/AcademicRecords/Student/GetSsn", {
			params: { leadId },
		})
		.then(
			(res: any) => {
				if (res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error;
			}
		);
};
export const GetSsnLast4 = (leadId: string, last4: string) => {
	return API()
		.get("/AcademicRecords/Student/GetSsnLast4", {
			params: { leadId, last4 },
		})
		.then(
			(res: any) => {
				if (res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

export const GetDateOfBirthByLead = (leadId: string) => {
	return API()
		.get("/AcademicRecords/Student/GetDateOfBirthByLead", {
			params: { leadId },
		})
		.then(
			(res: any) => {
				if (res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

export const SearchForExistingStudent = (campusId: string, ssn: string) => {
	return API()
		.get("/AcademicRecords/Student/SearchForExistingStudent", {
			params: { campusId, ssn },
		})
		.then(
			(res: any) => {
				if (res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

export const GetAdvanceStudentProfileSearch = (params: AdvanceSearchModel) => {
	return API()
		.post("/AcademicRecords/Student/AdvanceStudentProfileSearch", params)
		.then(
			(res: any) => {
				if (res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

export const GetStudentToNavigate = (params: IStudentNavigation) => {
	return API()
		.post("/AcademicRecords/Student/GetStudentToNavigate", params)
		.then(
			(res: any) => {
				if (res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error;
			}
		);
};
