import React from "react";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import {
	Typography,
	Paper,
	CardContent,
	Grid,
	Button,
	TextField
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker
} from "@material-ui/pickers";
import ProgressSaveButton from "../../../components/_Layout/Buttons/ProgressSaveButton";
import { ISuspension, NewISuspensionDetail } from "../../../interfaces/student/status/ISuspension";
import * as SuspensionApi from "../../../api/student/status/SuspensionApi";
import { ApiResultStatus } from "../../../enums/ApiResultStatus"; 
import { useSelector } from "react-redux";
import { useFormContext, Controller } from "react-hook-form";

type SuspensionDetailProps = {
	setSnackBarProps: any;
	updateEvent: any;
	newStatusCode: any;
	newStatus: any;
};

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& label.Mui-focused": {
				color: theme.palette.primary.white,
			},
		},
	})
)(TextField);

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.background.paper,
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2),
			},
		},
		paperRoot: {
			width: "100%",			
		},
		cardTitle: {
			fontSize: theme.spacing(2),
			color: theme.palette.black,
			textDecoration: "bold",
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1)
		},
		cardContent: {
			padding: theme.spacing(1, 1)
		},
		datePicker: {
			"& .MuiIconButton-root": {
				padding: theme.spacing(0.8)
			}
		},    
		textField: {
			marginRight: theme.spacing(1),
			width: "100%"
		  },
		actionContainer: {
			marginTop: theme.spacing(3)
		  },
		  button: {
			marginRight: theme.spacing(1),
			marginLeft: theme.spacing(1),
		},
	})
);

const SuspensionDetail = (props: 
		SuspensionDetailProps) => {

	const classes = useStyles({});

	const data = useFormContext();

	const selectedEnrollment = useSelector(
		(state: any) => state.student.selectedEnrollment
	);

	const [suspensionData, setSuspensionData] = React.useState<ISuspension>(
		NewISuspensionDetail((selectedEnrollment && selectedEnrollment.stuEnrollId)
			? selectedEnrollment.stuEnrollId
			: "", props.newStatusCode)
	);

	const [loaderState, setLoaderState] = React.useState<boolean>(false);

	const handleFieldOnChange = (fieldId: string, value: any) => {
		let updatedData = suspensionData;
		if(fieldId === 'startDate' || fieldId === 'endDate')
		{
			(updatedData as any)[fieldId] = new Date(value).toLocaleDateString();
		}else {
			(updatedData as any)[fieldId] = value;
		}
		setSuspensionData({ ...updatedData });
	  };

	const handleSubmit = () => {		
		data.triggerValidation().then((validation: any) => {
			if (validation) {
				setLoaderState(true);
				SuspensionApi.createSuspension(suspensionData).then(
					(response: any) => {
						setLoaderState(false);
						if (response.data != undefined && response.data.resultStatus != undefined)
						{
							switch (response.data.resultStatus) {
							case ApiResultStatus.Success:
								props.setSnackBarProps((props: any) => {
									return {
										variant: "success",
										showSnackBar: true,
										messageInfo: response.data.resultStatusMessage
									};
								});	
								props.updateEvent((suspensionData.startDate != null && suspensionData.startDate <= new Date(Date.now()))? props.newStatus : null);				
								break;							
							case ApiResultStatus.Warning:
								props.setSnackBarProps((props: any) => {
									return {
										variant: "warning",
										showSnackBar: true,
										messageInfo: response.data.resultStatusMessage
									};
								});
								break;
							case ApiResultStatus.Error:
								props.setSnackBarProps((props: any) => {
									return {
										variant: "error",
										showSnackBar: true,
										messageInfo: response.data.resultStatusMessage
									};
								});
								break;
							default:
							}						
						}
						else {
							props.setSnackBarProps((props: any) => {
								return {
									variant: "error",
									showSnackBar: true,
									messageInfo: "Error creating suspension."
								};
							});
						}
					},
					(exception: any) => {
						setLoaderState(false);
						props.setSnackBarProps((snackBarProps: any) => {
					  return {
								variant: "error",
								showSnackBar: true,
								messageInfo: exception,
					  };
						});
					})
			}
		});
	};

	const handleCancel = () => {
		setSuspensionData(NewISuspensionDetail((selectedEnrollment && selectedEnrollment.stuEnrollId)
			? selectedEnrollment.stuEnrollId
			: "", props.newStatusCode));		
	};


	return (

		<Paper className={classes.paperRoot}>
			<Typography className={classes.cardTitle} >
				Add Suspension Details
			</Typography>
			<CardContent className={classes.cardContent}>
				<Grid container direction="row" spacing={2}>
					<Grid item md={4} sm={6} xs={12}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDatePicker
								disableToolbar
								variant="inline"
								format="MM/dd/yyyy"
								id="startDate"
								label="Start Date"
								fullWidth
				  autoOk={true}
				  required
								KeyboardButtonProps={{
									"aria-label": "start date"
								}}
								value={
									suspensionData.startDate
										? new Date(suspensionData.startDate).toLocaleDateString()
										: null
				  }
				  error={!!data.errors["startDate"]}
				  innerRef={(ref: any) => {
									data.register("startDate", { required: true });
									data.setValue("startDate", suspensionData.startDate);
				  }}			  
								onChange={(val: any) => {
									handleFieldOnChange("startDate", val);
								}}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDatePicker
				  disableToolbar
				  autoOk={true}
								variant="inline"
								format="MM/dd/yyyy"
								id="endDate"
				  label="End Date"				  
				  className={classes.datePicker}
				  fullWidth
				  required
								KeyboardButtonProps={{
									"aria-label": "end date"
								}}
								value={
									suspensionData.endDate
										? new Date(suspensionData.endDate).toLocaleDateString()
										: null
				  }
				  error={!!data.errors["endDate"]}
				  innerRef={(ref: any) => {
									data.register("endDate", { required: true });
									data.setValue("endDate", suspensionData.endDate);
				  }}				  
								onChange={(val: any) => {
									handleFieldOnChange("endDate", val);
								}}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<ThemeTextField
							id="reason"
							className={classes.textField}
							label="Reason"
							value={suspensionData.reason}
							onChange={(e: any) => {
								handleFieldOnChange("reason", e.target.value);
							}}
						/>
					</Grid>
				</Grid>
			
				<div className={classes.actionContainer}>
					<ProgressSaveButton
						text="Save"
						onClick={() => {
							handleSubmit();
						}}
						loading={loaderState}
					></ProgressSaveButton>
					<Button
						onClick={handleCancel}
						color="secondary"
						variant="contained"
						className={classes.button}
					>Cancel</Button>
				</div>
			</CardContent>
		</Paper>

	);
};
	
export default SuspensionDetail;
