import * as React from 'react';
//import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography, colors, IconButton, Button } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => createStyles({
    root: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 0,
        flexShrink: 0,
        borderRadius: theme.shape.borderRadius,
        lineHeight: '10px',
        fontSize: '10px',
        height: 20,
        minWidth: 20,
        whiteSpace: 'nowrap',
        padding: theme.spacing(0.5, 1)
    },
    rounded: {
        borderRadius: 10,
        padding: theme.spacing(0.5)
    },
    deleteIcon: {
        color: theme.palette.white,
        padding: 0,
        margin: 0
    }
}));

type ILabelProps = {
    id: string,
    children?: React.ReactNode,
    className?: string,
    color?: string,
    shape?: 'square' | 'rounded',
    style?: any,
    variant?: 'contained' | 'outlined',
    deleteIcon?: JSX.Element,
    onDeleteClick?: any,
    draggable?: boolean
}

const Label = (props: ILabelProps) => {
    const { className, variant, color, shape, children, style, deleteIcon, onDeleteClick, id, draggable, ...rest } = props;
    const classes = useStyles({});
    const [isDragging, setIsDragging] = React.useState<boolean>(false);
    const rootClassName = clsx(
        {
            [classes.root]: true,
            [classes.rounded]: shape === 'rounded'
        },
        className
    );


    const draggingStarted = () => {
        setIsDragging(true);
    }
    const draggingEnded = () => {
        setIsDragging(false);
    }

    const finalStyle = { ...style };

    if (variant === 'contained') {
        finalStyle.backgroundColor = color;
        finalStyle.color = '#FFF';
    } else {
        finalStyle.border = `1px solid ${color}`;
        finalStyle.color = color;
    }

    return (
        <Typography
            {...rest}
            className={rootClassName}
            style={finalStyle}
            variant="overline"
        >
            {children}
            {deleteIcon && <IconButton disableRipple={true} disableFocusRipple={true} className={classes.deleteIcon} onClick={() => onDeleteClick(id)}> {deleteIcon} </IconButton>}

        </Typography>);
};


Label.defaultProps = {
    style: {},
    color: colors.grey[600],
    variant: 'contained',
    shape: 'square'
};

export default Label;
