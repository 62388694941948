import { Action } from "redux";
import * as actionTypes from "../../constants/actions.constants";
import { AutoCompleteSession } from "../../interfaces/AutoCompleteSession";

const initialState: AutoCompleteSession = {
	data: {},
};

const autoCompleteCacheReducer = (
	state = initialState,
	action: Action<any>
) => {
	switch (action.type) {
		case actionTypes.autoCompleteConstants.SESSION_LOADING_AC_LOADING: {
			return {
				...state,
			};
		}

		case actionTypes.autoCompleteConstants.SESSION_LOADING_AC_LOADING: {
			return state;
		}

		case actionTypes.autoCompleteConstants.SESSION_LOADING_AC_UPDATE_CACHE: {
			const updateData = action as any;
			let newItem: any = {};
			newItem[updateData.data.key] = {
				lastUpdated: new Date(),
				options: updateData.data.data,
			};
			const newState = {
				data: { ...state.data, ...newItem },
			};
			return newState;
		}

		case actionTypes.autoCompleteConstants.SESSION_LOADING_AC_REMOVE_CACHE: {
			const updateData = action as any;
			const newState = {
				data: { ...state.data, [updateData.data.key]: undefined },
			};
			return newState;
		}

		default: {
			return state;
		}
	}
};

export default autoCompleteCacheReducer;
