import React from 'react';
import {
	Table,
	TableBody,
	TablePagination,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Checkbox,
	CardContent,
	Card,
	Button,
} from '@material-ui/core';
import * as StudentSapApi from '../../../src/api/student/sap/StudentSapApi';
import makeStyles from './WidgetStyles';
import AccessManager from '../../components/security/AccessManager';
import ProgressSaveButton from '../../components/_Layout/Buttons/ProgressSaveButton';

const useStyles = makeStyles;
type SAPTypeProps = {
    title: string;
    sapType: string;
    formData: [];
    reLoad: any;
    printPermissions: string;
};

function SAPWidgetTab(props: SAPTypeProps) {
	const classes = useStyles();
	const {sapType, formData: TitleIVData} = props;
	const [selected, setSelected] = React.useState([] as any);
	const [loaderState, setLoaderState] = React.useState<boolean>(false);

	const isSelected = (checkId: any) =>
		selected.indexOf(checkId) !== -1;

	const selectAllClick = (event: any) => {
		if (event.target.checked) {
			const selected = TitleIVData.map((n: any) => n.checkId);
			setSelected(selected);
			return;
		}
		setSelected([]);
	};
	const rowClick = (event: any, resultId: string) => {
		const selectedIndex = selected.indexOf(resultId);
		let newSelected: string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, resultId);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};

	React.useEffect(() => {
		setSelected([]);
	}, [props.formData]);

	const doPrint = () => {
		if (selected.length > 0) {
			setLoaderState(true);
			if (sapType === 'IVSAP') {
				StudentSapApi.PrintTitleIVNoticeReport(selected).then(
					(_response: any) => {
						props.reLoad();
						setLoaderState(false);
					},
					(_exception: any) => {
						setLoaderState(false);
					}
				);
			}
			else {
				StudentSapApi.PrintSAPNoticeReport(selected).then(
					(_response: any) => {
						props.reLoad();
						setLoaderState(false);
					},
					(_exception: any) => {
						setLoaderState(false);
					}
				);
			}
		}
	};

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const handleChangePage = (event: any, newPage: any) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event: any) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<Card className={classes.root}>
			<CardContent className={classes.cardContent}>
				<div className={classes.innerRoot}>
					<TableContainer component={Paper}>
						<Table
							className={classes.table}
							size="small"
							aria-label="a dense table"
						>
							<TableHead>
								<TableRow>
									<TableCell align="left" className={classes.headCell}>
                                        Name
									</TableCell>
									<TableCell align="left" className={classes.headCell}>
                                        Result
									</TableCell>
									<TableCell align="center" className={classes.headCell}>
										<Checkbox
											className={classes.checkBoxStyle}
											value="uncontrolled"
											color="primary"
											onClick={selectAllClick}
											inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
										/>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{TitleIVData !== null && TitleIVData.length > 0 ? (
									TitleIVData.slice(
										page * rowsPerPage,
										page * rowsPerPage + rowsPerPage
									).map((row: any) => {
										const isRowSelected = isSelected(row.checkId);
										return (
											<TableRow
												key={row.checkId}
												onClick={(event) =>
													rowClick(event, row.checkId)
												}
												role="checkbox"
												selected={isRowSelected}
											>
												<TableCell align="left" className={classes.bodyCell}>
													{row.studentName}
												</TableCell>
												<TableCell align="left">{row.sapStatus}</TableCell>
												<TableCell align="center">
													<Checkbox
														className={classes.checkBoxStyle}
														value="uncontrolled"
														color="primary"
														inputProps={{
															'aria-label': 'uncontrolled-checkbox',
														}}
														checked={isRowSelected}
													/>
												</TableCell>
											</TableRow>
										);
									})
								) : (
									<TableRow>
										<TableCell align="center" colSpan={3}>
                                            No Data
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={TitleIVData.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
					<AccessManager
						allowedPermissions={[
							props.printPermissions,
						]}
						renderNoAccess={() => (
							<Button
								disabled={true}
								onClick={() => {
									doPrint();
								}}
								size="small"
								className={classes.button}
								type="button"
								color="primary"
							>
                                Print
							</Button>
						)}
					>
						<ProgressSaveButton
							text={'Print'}
							onClick={() => {
								doPrint();
							}}
							loading={loaderState}
						></ProgressSaveButton>
					</AccessManager>
				</div>
			</CardContent>
		</Card>
	);
}
export default SAPWidgetTab;
