import * as React from "react";
import { useRef } from "react";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";

const config: ApiAutoCompleteConfigItem = {
    acId: "weekDays",
    cacheTime: 5,
    route: "/SystemCatalog/WeekDays/GetWeekDays",
    requestType: "GET",
    body: null,
    hasUrlParams: false,
    useCache: false,
    storeInCache: false,
    isCampusSpecific: false,
    isUserSpecific: false,
    loadOnRender: true,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
        return data;
    },
};

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {},
        divWidth: {
            width: "95%",
        },
    })
);

const WeekDaysAutoComplete = (props: FilterParams) => {
    const classes = useStyles({});
    const selectedEnrollment = useSelector(
        (state: any) => state.student.selectedEnrollment
    );
    const inputRef: any = useRef(null);
    const { error, helperText } = props;

    const statusOnChange = (e: any) => {
        if (props.filterHandle) {
            props.filterHandle(e);
        }
    };

    return (
        <div className={clsx(classes.divWidth)}>
            <ApiAutoComplete
                config={config}
                classes={{
                    option: "text-black",
                }}
                label={props.label ? props.label : "Week Days"}
                placeholder={
                    props.valueFilter &&
                        props.valueFilter.values &&
                        props.valueFilter.values.length > 0
                        ? ""
                        : "Select Week Day (s)"
                }
                onChange={(e: any) => {
                    statusOnChange(e);
                }}
                multiple={props.multiple ? props.multiple : false}
                valueFilter={props.valueFilter ? props.valueFilter : undefined}
                error={error}
                helperText={helperText}
                inputRef={props.inputRef ? props.inputRef : inputRef}
                inputName={props.name}
            ></ApiAutoComplete>
        </div>
    );
};
export default WeekDaysAutoComplete;
