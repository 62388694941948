export const security = Object.freeze({
  permissions: {
    dashboard: {
      viewDashboard: "Dashboard",
      viewGraduationWidgetStatusSettings: "ViewGraduationWidgetStatusSettings",
      updateGraduationWidgetStatusSettings: "UpdateGraduationWidgetStatusSettings",
      viewGraduationWidget: "ViewGraduationWidget",
      updateGraduationWidget: "UpdateGraduationWidget",
      ViewLeaveOfAbsenceWidget: "ViewLeaveOfAbsenceWidget",
      UpdateLeaveOfAbsenceWidget: "UpdateLeaveOfAbsenceWidget",
      ViewTitleIVNoticesWidget: "ViewTitleIVNoticesWidget",
      ViewPendingRefundsWidget: "ViewPendingRefundsWidget",
      UpdatePendingRefundsWidget: "ViewPendingRefundsWidget",
      PrintPendingRefunds: "PrintPendingRefunds",
      viewReportsWidget: "ViewReportsWidget",
      viewPaymentPlanWidget: "ViewPaymentPlanWidget",
      viewPaymentPlanSettings: "ViewPaymentPlanSettings",
      addOrUpdatePaymentPlanSettings: "AddOrUpdatePaymentPlanSettings",
      updateContactedFlagForPaymentPlanSchedule: "UpdateContactedFlagForPaymentPlanSchedule",
      viewCampusAttendanceWidget: "ViewCampusAttendanceWidget",
    },
    student: {
      viewStudentInfo: "ViewStudentInfo",
      updateStudentInfo: "UpdateStudentInfo",
      viewContactInfo: "ViewContactInfo",
      updateContactInfo: "UpdateContactInfo",
      viewEnrollmentInfo: "ViewEnrollmentInfo",
      updateEnrollmentInfo: "UpdateEnrollmentInfo",
      viewPlacement: "ViewPlacement",
      updatePlacement: "UpdatePlacement",
      viewDocuments: "ViewDocuments",
      updateDocuments: "UpdateDocuments",
      viewExamResults: "ViewExamResults",
      updateExamResults: "UpdateExamResults",
      deleteExamResults: "DeleteExamResults",
      viewClinicServices: "ViewClinicServices",
      viewClinicHoursDetails: "ViewClinicHoursDetails",
      updateClinicServices: "UpdateClinicServices",
      updateClinicHoursDetails: "UpdateClinicHoursDetails",
      deleteClinicServices: "DeleteClinicServices",
      deleteClinicHoursAttempts: "DeleteClinicHoursAttempts",
      viewLedger: "ViewLedger",
      updateLedger: "UpdateLedger",
      postTransactions: "PostTransactions",
      viewAwards: "ViewAwards",
      viewAwardYears: "ViewAwardYears",
      updateAwards: "UpdateAwards",
      updateAwardYears: "UpdateAwardYears",
      deleteAwards: "DeleteAwards",
      deleteAwardYears: "DeleteAwardYears",
      viewPaymentPlans: "ViewPaymentPlans",
      viewPaymentDescription: "ViewPaymentDescription",
      updatePaymentPlans: "UpdatePaymentPlans",
      updatePaymentDescription: "UpdatePaymentDescription",
      deletePaymentPlans: "DeletePaymentPlans",
      deletePaymentDescription: "DeletePaymentDescription",
      viewEnrollmentStatus: "ViewEnrollmentStatus",
      updateEnrollmentStatus: "UpdateEnrollmentStatus",
      deleteEnrollmentStatus: "DeleteEnrollmentStatus",
      addNewEnrollment: "AddNewEnrollment",
      viewMonthlyAttendance: "ViewMonthlyAttendance",
      updateMonthlyAttendance: "UpdateMonthlyAttendance",
      viewTitleIVSAPResults: "ViewTitleIVSAPResults",
      viewSAPResults: "ViewSAPResults",
      viewSSN: "ViewSSN",
      updateSSN: "UpdateSSN",
      viewDateOfBirth: "ViewDateOfBirth",
      updateDateOfBirth: "UpdateDateOfBirth",
      postStudentOnLoa: "PostStudentOnLoa",
    },
    studentNotes: {
      viewNotes: "ViewNotes",
      updateNotes: "UpdateNotes",
      viewConfidentialNotes: "ViewConfidentialNotes",
      updateConfidentialNotes: "UpdateConfidentialNotes",
    },
    setupHoliday: {
      viewSetUpHolidays: "ViewSetUpHolidays",
      updateSetUpHolidays: "UpdateSetUpHolidays",
      deleteSetUpHolidays: "DeleteSetUpHolidays",
    },
    academics: {
      postGradesByGroup: "PostGradesByGroup",
      sap: "SAP",
      progressReport: "ProgressReport",
      transcript: "Transcript",
      recalcT4SAP: "RecalcT4SAP",
      IllinoisTranscriptReport:"IllinoisTranscriptReport",
    },
    attendance: {
      postAttendance: "PostAttendance",
      editPunches: "EditPunches",
      editScheduledHours: "EditScheduledHours",
      ViewAttendanceReport:'ViewAttendanceReport',
    },
    reports: {
      // Other Than Reports Page Reports Permissions
      viewprint9010report: "Viewprint9010report",
      printTitleIVSAPNotices: "PrintTitleIVSAPNotices",
      printSAPNotices: "PrintSAPNotices",
      generateExportAccrualsARReport: "GenerateExportAccrualsARReport",

      // IFrame Report Permissions
      viewStateBoardAccreditingAgencySettings:
        "ViewStateBoardAccreditingAgencySettings",
      updateStateBoardAccreditingAgencySettings:
        "UpdateStateBoardAccreditingAgencySettings",
      deleteStateBoardAccreditingAgencySettings:
        "DeleteStateBoardAccreditingAgencySettings",
      view9010Mappings: "View9010Mappings",
      update9010Mappings: "Update9010Mappings",
      delete9010Mappings: "Delete9010Mappings",
      campusAttendanceReport: "CampusAttendanceReport",
    },
    maintenance: {
      viewCampusGroup: "ViewCampusGroup",
      viewCampus: "ViewCampus",
      updateCampus: "UpdateCampus",
      deleteCampus: "DeleteCampus",
      viewHighSchoolsColleges: "ViewHighSchools&Colleges",
      viewCollegeDivisions: "ViewCollegeDivisions",
      updateHighSchoolsColleges: "UpdateHighSchools&Colleges",
      updateCollegeDivisions: "UpdateCollegeDivisions",
      deleteHighSchoolsColleges: "DeleteHighSchools&Colleges",
      deleteCollegeDivisions: "DeleteCollegeDivisions",
      viewHRDepartments: "ViewHRDepartments",
      viewDepartments: "ViewDepartments",
      updateHRDepartments: "UpdateHRDepartments",
      updateDepartments: "UpdateDepartments",
      deleteHRDepartments: "DeleteHRDepartments",
      deleteDepartments: "DeleteDepartments",
      viewSAPPolicies: "ViewSAPPolicies",
      updateSAPPolicies: "UpdateSAPPolicies",
      deleteSAPPolicies: "DeleteSAPPolicies",
      viewCourses: "ViewCourses",
      viewCourseGroups: "ViewCourseGroups",
      viewCourseEquivalent: "ViewCourseEquivalent",
      viewCourseCategories: "ViewCourseCategories",
      updateCourses: "UpdateCourses",
      updateCourseGroups: "UpdateCourseGroups",
      updateCourseEquivalent: "UpdateCourseEquivalent",
      updateCourseCategories: "UpdateCourseCategories",
      deleteCourses: "DeleteCourses",
      deleteCourseGroups: "DeleteCourseGroups",
      deleteCourseEquivalent: "DeleteCourseEquivalent",
      deleteCourseCategories: "DeleteCourseCategories",
      viewGradingSystem: "ViewGradingSystem",
      viewGradingScales: "ViewGradingScales",
      viewGradingComponentTypes: "ViewGradingComponentTypes",
      viewGradeDescriptionDetails: "ViewGradeDescriptionDetails",
      updateGradingSystem: "UpdateGradingSystem",
      updateGradingScales: "UpdateGradingScales",
      updateGradingComponentTypes: "UpdateGradingComponentTypes",
      updateGradeDescriptionDetails: "UpdateGradeDescriptionDetails",
      deleteGradingSystem: "DeleteGradingSystem",
      deleteGradingScales: "DeleteGradingScales",
      deleteGradingComponentTypes: "DeleteGradingComponentTypes",
      deleteGradeDescriptionDetails: "DeleteGradeDescriptionDetails",
      viewPrograms: "ViewPrograms",
      viewProgramTypes: "ViewProgramTypes",
      viewProgramGroups: "ViewProgramGroups",
      updatePrograms: "UpdatePrograms",
      updateProgramTypes: "UpdateProgramTypes",
      updateProgramGroups: "UpdateProgramGroups",
      deletePrograms: "DeletePrograms",
      deleteProgramTypes: "DeleteProgramTypes",
      deleteProgramGroups: "DeleteProgramGroups",
      copyProgramVersions: "CopyProgramVersions",
      ViewChargeDefinition: "ViewChargeDefinition",
      UpdateChargeDefinition: "UpdateChargeDefinition",
      EditInstructorField:"EditInstructorField",
    },
    studentAccounts: {
      viewRefundPolicies: "ViewRefundPolicies",
      updateRefundPolicies: "UpdateRefundPolicies",
      deleteRefundPolicies: "DeleteRefundPolicies",
    },
    terminationSettings: {
      viewTerminationSettings: "ViewTerminationSettings",
      addOrUpdateTerminationSettings: "AddOrUpdateTerminationSettings",
    },
    terminationWidget: {
      viewTerminationWidget: "ViewTerminationWidget",
    },
    MTFWidget:{
      ViewMTFWidget:"ViewMTFWidget"
    },
    statusHistory: {
      addNewStatusHistory : "AddNewStatusHistory",
      deleteStatusHistory : "DeleteStatusHistory"
    },
    creditHour: {
      reverseClassDrop : "ReverseClassDrop",
    }
  },
});
