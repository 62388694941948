export const CountryApiAutoComplete = {
    config: {
        acId: "country",
        cacheTime: 5,
        route: "/SystemCatalog/Countries/GetByCampus",
        requestType: "GET",
        body: null,
        hasUrlParams: true,
        useCache: true,
        storeInCache: true,
        isCampusSpecific: true,
        isUserSpecific: false,
        loadOnRender: true,
        defaultSelectedFirstItem: false,
        optionsMappedFx: (data: Array<any>) => {
          return data;
        }
      }
};