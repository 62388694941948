export const notificationTypes = Object.freeze({
	integration: {
		exception: "Fame.Integration.Exception",
	},
	timeclock: {
		fileImport: "Fame.TimeClock.FileImport",
	},
	reports: {
		generated: "Fame.Reports.Generated"
    }
});
