import React, { Fragment } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Button,
  Paper,
  Typography,
  CircularProgress,
  Grid,
} from "@material-ui/core";

import * as ledgerApi from "../../../../api/student/studentAccounts/ledgerApi";
import { useSelector } from "react-redux";
import { LedgerItem } from "../../../../interfaces/student/studentAccounts/LedgerItem";
import CenterLoader from "../../../../components/_Layout/Loaders/CenterLoader";
import { forwardRef } from "react";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";

import * as disbursementDetailsApi from "../../../../api/student/studentAccounts/disbursementDetailsApi";
import DisbursementDetailsGrid from "./DisbursementDetailsGrid";
import { DisbursementDetailsItem } from "../../../../interfaces/student/studentAccounts/DisbursementDetailsItem";
import { StudentAward } from "../../../../interfaces/student/studentAccounts/StudentAward";
import { EmptyGuid } from "../../../../utils/constants";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    drawer: {
      width: 280,
    },
    root: {
      width: "100%",
      height: "95%",
      minHeight: "100%",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
    button: {
      marginTop: -theme.spacing(8),
      marginBottom: theme.spacing(3),
      marginRight: theme.spacing(3),
      whiteSpace: "nowrap",
    },
    summaryFooter: {
      textAlign: "right",
      margin: "25px",
    },
  })
);
const formatMoney = (number: number) => {
  var absValue = Math.abs(number);
  let numberString = absValue.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  return number < 0 ? "(" + numberString + ")" : numberString;
};

type StudentAwardProps = {
  studentAwardId: any;
  setSnackBar: any;
  setSelectedAward: any;
  selectedAward: StudentAward;
  refreshAwardGrid: any;
  isTitleIVAward?: boolean;
};

const DisbursementDetails = (props: StudentAwardProps) => {
  const classes = useStyles({});

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showVoid, setShowVoid] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const [disbursementDetailsItem, setDisbursementDetailsItem] = React.useState<
    DisbursementDetailsItem[]
  >([]);
  const [totalBalance, setTotalBalance] = React.useState<number>(0);

  const refreshDisbursementDetailsItems = () => {
    if (
      props.studentAwardId &&
      props.studentAwardId.studentAwardId !== EmptyGuid
    ) {
      setLoading(true);
      let studentawardID = props.studentAwardId.studentAwardId;
      disbursementDetailsApi.getDisbursementDetails(studentawardID).then(
        (response: any) => {
          setLoading(false);
          if (response) {
            let data = response.data.result;
            let disbursementData: DisbursementDetailsItem[] = data.map(
              (di: any) => {
                return { ...di } as DisbursementDetailsItem;
              }
            );
            setDisbursementDetailsItem(disbursementData.sort((a, b) => (a.sequenceNumber ?? 0) - (b.sequenceNumber ?? 0)));
          }
        },
        (exception: any) => {
          setLoading(false);
        }
      );
    } else {
      setDisbursementDetailsItem([]);
    }
  };
  React.useEffect(() => {
    refreshDisbursementDetailsItems();
  }, [props.studentAwardId]);

  return (
    <Fragment>
      <div className={classes.root}>
        {loading ? (
          <CenterLoader />
        ) : (
          <DisbursementDetailsGrid
            studentAwardID={props.studentAwardId.studentAwardId}
            disbursementDetailsItem={disbursementDetailsItem}
            refreshDisbursementDetailsItems={refreshDisbursementDetailsItems}
            setSnackBar={props.setSnackBar}
            refreshAwardGrid={props.refreshAwardGrid}
            selectedAward={props.selectedAward}
            setSelectedAward={props.setSelectedAward}
            isTitleIVAward={props.isTitleIVAward}
          />
        )}
      </div>
    </Fragment>
  );
};

export default DisbursementDetails;
