import {
  Dialog,
  makeStyles,
  createStyles,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  withStyles,
  TextField,
  CardMedia,
} from "@material-ui/core";
import ProgressSaveButton from "../../../components/_Layout/Buttons/ProgressSaveButton";
import React, { useState } from "react";
import * as storageApi from "../../../api/storage/storageApi";
import { useSelector } from "react-redux";
import { FileConfigurationFeature } from "../../../enums/FileConfigurationFeature";
import { IGetFileParams } from "../../../interfaces/storage/IGetFileParams";
import { CloudDownload, ChevronRight, ChevronLeft } from "@material-ui/icons";
import { Page } from "react-pdf";
import { Document } from "react-pdf/dist/entry.webpack";
import clsx from "clsx";
import { FileUtil } from "../../../utils/file"
const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .MuiPaper-rounded": {
        borderRadius: 0,
      },
      "& .MuiDialogContent-root": {
        minHeight: 250,
        overflowY:"hidden"
      },
     
    },
    rootNoPdf: {
      "& .MuiPaper-rounded": {
        borderRadius: 0,
      },
      "& .MuiDialogContent-root": {
        minHeight: 250,
      },     
    },
    dialogHeader: {
      backgroundColor: theme.palette.site.secondary,
      "& .MuiTypography-root": {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: "12pt",
      },
    },
    buttonSection: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
    saveButton: {
      width: 130,
    },
    fullWidthField: {
      width: "100%",
    },
    preview: {
      height: 140,
    },
    noPreviewZone: {
      height: 500,
      textAlign: "center",
      padding: 100,
      fontSize: "1.5em",
    },
    buttonNoPreview: {
      height: "100%",
      width: "100%",
      fontSize: "2em",
    },
    downloadIcon: {
      width: "100%",
      fontSize: "10em",
    },
    pageManagement: {
      width: "50px",
      height: "inherit",
      float: "left",
      display: "inline",
      textAlign:"center",
      [theme.breakpoints.down('md')] :{
        paddingTop:theme.spacing(10)
      },
      [theme.breakpoints.between('md', 'lg')] :{
        paddingTop:theme.spacing(20)
      },
      [theme.breakpoints.up('lg')] :{
        paddingTop:theme.spacing(40)
      }
      
    },
    pdf: {
      width: "calc(100% - 100px)",
      float: "left",
      display: "inline",
      height: "inherit",
      overflowY: "auto",
      padding:" 0 2em 0 2em",
      boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)"
    },
    pageInfo: {
      width: "100%",
      textAlign:"center",
      padding:".5em 0 .5em 0",
      marginTop:"1em"
    },
    pdfWrapper:{
      width:"100%",
      [theme.breakpoints.down('md')] :{
        height:"400px",
      },
      [theme.breakpoints.between('md', 'lg')] :{
        height:"500px",
      },
      [theme.breakpoints.up('lg')] :{
        height:"600px",
      }
     
      
    },
    GridItemPdf:{
      height:"calc(100% - 100px)"
    }
    , Disabled:{
      color: "rgba(0, 0, 0, 0.26)"
    }
  })
);

type DocumentPreviewProps = {
  setOpen: any;
  open: boolean;
  setSnackBarProps: any;
  fileUri: string;
  fileName: string;
  fileExtension: string;
};

const DocumentPreview = (props: DocumentPreviewProps) => {
  const classes = useStyles({});

  const studentId = useSelector((state: any) => state.student.studentId);
  const campusId = useSelector(
    (state: any) => state.student.enrollments[0].campusId
  );

  const [imageUrl, setImageUrl] = React.useState<string>("");
  const [isPDF, setIsPDF] = React.useState<boolean>(false);
  const [loaderState, setLoaderState] = React.useState<boolean>(false);
  const [isPreviewAvailable, setIsPreviewAvailable] = React.useState<boolean>(
    false
  );
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const handleClose = () => {
    props.setOpen(false);
  };

  function onDocumentLoadSuccess(e: any) {
    setNumPages(e.numPages);
  }

  const errorLoadPDF = (e: any) => {
    console.log(e);
    setIsPreviewAvailable(false);
  };



  React.useEffect(() => {
    if (props.open) {
      storageApi
        .getFilePreview({
          fileName: props.fileUri,
          campusId: campusId,
          studentId: studentId,
          featureType: FileConfigurationFeature.DOC,
        } as IGetFileParams)
        .then(
          (response: string) => {
            if (response) {
              setImageUrl(response);
            }
          },
          (exception: any) => {}
        );
    }
  }, [props.open]);

  React.useEffect(() => {
    setIsPreviewAvailable(imageUrl !== "");
    setIsPDF(props.fileExtension.indexOf("pdf") > -1);
  }, [imageUrl]);

  const handleFileDownload = () => {
    const link = document.createElement("a");
     link.href = imageUrl;
     let fileName = FileUtil.GetFileExtension(props.fileName) === "" ? props.fileName + props.fileExtension : props.fileName;
     link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  };

  const handlePrevPage = () => {
    if ( pageNumber > 1 ){
      setPageNumber(pageNumber - 1)
    }
  };

  const handleNextPage = () => {
    if ( numPages && pageNumber < numPages ){
      setPageNumber(pageNumber + 1)
    }
  };
  const imgCSSProgs: React.CSSProperties = {
    width: "100%"
  }

  const handleImageError = (e:any) => {
    setIsPreviewAvailable(false);
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={isPDF ? "md" : "lg"}
        fullWidth={true}
        disableBackdropClick
        className={clsx(isPDF ? classes.root : classes.rootNoPdf)}
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
          {isPreviewAvailable ? "Document Preview" : "Download Document"}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} className={isPDF ? classes.GridItemPdf:""}>
              {!isPreviewAvailable && (
                <div
                  className={classes.noPreviewZone}
                  onClick={(e: any) => {
                    handleFileDownload();
                  }}
                >
                  <CloudDownload className={classes.downloadIcon} />
                  {"Click to Download ( " + props.fileName + " )"}
                </div>
              )}
              {!isPDF && isPreviewAvailable && (
                <CardMedia>
                  <img
                    src={imageUrl}
                    style={imgCSSProgs}
                    onError={handleImageError}
                  ></img>
                </CardMedia>
              )}
              {isPDF && isPreviewAvailable && (
                <React.Fragment>
                  <div className={classes.pdfWrapper}>
                    <div className={clsx(classes.pageManagement,( pageNumber === 1 ? classes.Disabled : ""))}
                    onClick={(e: any) => {
                      handlePrevPage();
                    }}>
                      <ChevronLeft />
                    </div>

                    <Document
                      file={imageUrl}
                      onLoadSuccess={onDocumentLoadSuccess}
                      onSourceError={errorLoadPDF}
                      className={classes.pdf}
                    >
                      <Page pageNumber={pageNumber} pageIndex={pageNumber} />
                    </Document>
                    <div className={clsx(classes.pageManagement,( pageNumber === numPages ? classes.Disabled : ""))}
                     onClick={(e: any) => {
                      handleNextPage();
                    }}>
                      <ChevronRight />
                    </div>
                  </div>
                  <div className={classes.pageInfo}>
                    <p>
                    Page {pageNumber} of {numPages}

                    </p>
                  </div>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.buttonSection}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={2}></Grid>
            <Grid
              item
              container
              xs={4}
              alignContent="center"
              alignItems="center"
              justify="center"
            >
              {isPreviewAvailable && (
                <ProgressSaveButton
                  text="Download"
                  onClick={(e: any) => {
                    handleFileDownload();
                  }}
                  loading={false}
                  buttonClassName={classes.saveButton}
                ></ProgressSaveButton>
              )}
            </Grid>
            <Grid
              item
              container
              xs={4}
              alignContent="center"
              alignItems="center"
              justify="center"
            >
              <Button
                onClick={handleClose}
                color="secondary"
                variant="contained"
                type="button"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DocumentPreview;
