import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DropDownListItem } from '../../../interfaces/DropDownListItem';
import TextField from '@material-ui/core/TextField';
import { Button, Card, CardContent, Checkbox, CircularProgress, FormControlLabel, Grid, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { FormContext, useForm } from 'react-hook-form';
import * as reportApi from '../../../api/reports/progressReport/reportApi';
import { ReportOutput } from '../../../enums/ReportOutput';
import ReportOverview from '../../../components/_Layout/Reports/ReportOverview';
import { IReportOverview } from '../../../interfaces/reports/IReportOverview';
import { useSelector } from 'react-redux';
import { IIllinoisTranscriptReportParams } from '../../../interfaces/reports/transcript/IIllinoisTranscriptReportParams';
import CampusForCurrentUserAutoComplete from '../../../components/AutoComplete/CampusForCurrentUserAutoComplete';
import StudentGroupsAutoComplete from '../../../components/AutoComplete/StudentGroupsAutoComplete';
import ProgramVersionAutoComplete from '../../../components/AutoComplete/ProgramVersionAutoComplete';
import EnrollmentStatusAutoComplete from '../../../components/AutoComplete/EnrollmentStatusAutoComplete';
import { actions as reportActions, selectors as reportSelectors } from '../../../store/reports/illinoisTranscript';
import { useActions } from '../../../store/utils';
import ProgressSaveButton from '../../../components/_Layout/Buttons/ProgressSaveButton';
import InputField from '../../../components/_Layout/Inputs/InputField';
import { InputType } from '../../../constants/uiConstants/inputConstants';
import { StudentProfile } from '../../../interfaces/student/profile/StudentProfile';
import CustomCardTitle from '../../../interfaces/common/card/CustomCardTitle';
import { Scrollbars, positionValues } from 'react-custom-scrollbars';


const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			paddingTop: theme.spacing(3),
			minHeight: '100%',
			height: '100%',
			padding: theme.spacing(2),
		},
		'& .makeStyles-content': {
			backgroundColor: theme.palette.background.paper,
		},
		paperRoot: {
			padding: theme.spacing(3, 2),
			width: '100%',
			minHeight: '100%',
			height: '100%',
			overflowX: 'hidden',
			overflowY: 'auto',
			flexDirection: 'column',
			backgroundColor: theme.palette.background.paper,
		},
		searchActions: {
			marginTop: theme.spacing(3),
			textAlign: 'left',
		},
		startDateField: {
			width: '100%',
		},
		cardTitle: {
			fontSize: 17,
			color: theme.palette.primary.headerText,
			textDecoration: 'bold',
			backgroundColor: theme.palette.secondary.dark,
			padding: theme.spacing(1, 1),
			marginBottom: theme.spacing(1),
		},
		headerText: {
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(0),
		},
		cardContent: {
			padding: theme.spacing(1, 3),
		}, switch: {
			display: 'flex',
			alignItems: 'center',
			padding: theme.spacing(0, 0.5),
			height: theme.spacing(8.6),
		},
		scroll: {
			'& div': {
				zIndex: 5
			}
		}
	})
);

const IIllinoisTranscriptReport = (props: any) => {
	const { report } = props;
	let reportOverview = { report: report } as IReportOverview;
	const classes = useStyles({});
	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const actions = useActions({
		getStudents: reportActions.loadStudents,
		loadMoreStudents: reportActions.loadMoreStudents,
		addToSelectedStudents: reportActions.addToSelected,
		removeFromSelectedStudents: reportActions.remove,
		reset: reportActions.reset,
	}, []);
	const loadingMoreStudents = useSelector(reportSelectors.loadingMore);
	const searchingStudents = useSelector(reportSelectors.loading);
	const studentsList: Array<StudentProfile> = useSelector(reportSelectors.data);
	const selectedStudents: Array<StudentProfile> = useSelector(reportSelectors.selected);
	const filters = useSelector(reportSelectors.filters);
	const hasMore = useSelector(reportSelectors.hadMore);
	const data = useForm<any>({ mode: 'onBlur' });
	const [model, setModel] = React.useState({
		exportType: 2 as ReportOutput,
		showSignature: false,
		campusId: userSelectedCampus,
		studentsGroup: [],
		inactiveStudentGroup: false,
		programVersionId: [],
		enrollmentStatusId: [],
		nameBadgeId: '',
		selectedStudents: [],
		allstudents: false,
	});

	const handleFieldOnChange = (
		fieldId: keyof IIllinoisTranscriptReportParams,
		value: any,
		mapFunction?: Function
	) => {
		let updatedModel = model;
		let newValue = mapFunction ? mapFunction(value) : value;
		(updatedModel as any)[fieldId] = newValue;
		setModel({ ...updatedModel });
	};

	const handleCampusChange = (value: any) => {
		let updatedModel = model;
		updatedModel.campusId = value;
		setModel({ ...updatedModel, studentsGroup: [], programVersionId: [] });
	};

	const { handleSubmit } = data;

	const exportTypes: Array<DropDownListItem> = [
		{ text: 'PDF', id: '1' },
		{ text: 'Excel', id: '2' }
	];

	const onSubmit = async () => {
		let params = (await getReportParameters()) as IIllinoisTranscriptReportParams;
		const validation = await data.triggerValidation();
		if (validation) {
			if (params.allstudents) {
				await reportApi.getIllinoisTranscriptReportAllStudents({ ...params }, 'IllinoisTranscriptReport');
			}
			else {
				await reportApi.getIllinoisTranscriptReport({ ...params }, 'IllinoisTranscriptReport');
			}
		}
	};

	const exportTypeAutoComplete = {
		options: exportTypes,
		getOptionLabel: (option: DropDownListItem) => option.text,
	};

	const filterHandler = (data: any) => {
		if (data) {
			setModel(data);
			actions.reset();
			actions.addToSelectedStudents(data.selectedStudents || []);
		} else {
			setModel({
				exportType: 2 as ReportOutput,
				showSignature: false,
				campusId: userSelectedCampus,
				studentsGroup: [],
				inactiveStudentGroup: false,
				programVersionId: [],
				enrollmentStatusId: [],
				nameBadgeId: '',
				selectedStudents: [],
				allstudents: false,
			});
		}
	};
	const getReportParameters = async () => {
		const success = await data.triggerValidation();
		if (model && success) {
			let params: IIllinoisTranscriptReportParams = {
				exportType: model.exportType,
				showSignature: model.showSignature,
				campusId: model.campusId,
				studentsGroup: model.studentsGroup,
				inactiveStudentGroup: model.inactiveStudentGroup,
				programVersionId: model.programVersionId,
				enrollmentStatusId: model.enrollmentStatusId,
				nameBadgeId: model.nameBadgeId,
				selectedStudents: selectedStudents,
				allstudents: model.allstudents,
			};
			return params;
		}
		return {} as IIllinoisTranscriptReportParams;
	};

	const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
		const item = items.find((opt) => {
			if (opt.id === value) return opt;
		});
		return item || ({} as DropDownListItem);
	};
	const [checked, setChecked] = React.useState<Array<boolean>>([]);
	const [toRemove, setToRemove] = React.useState<Array<boolean>>([]);
	const searchStudents = () => {
		const { campusId, studentsGroup, enrollmentStatusId, programVersionId, nameBadgeId } = model;
		actions.getStudents({
			CampusId: campusId || '',
			StudentGrpId: studentsGroup && studentsGroup.length ? studentsGroup : '',
			EnrollmentStatusId: enrollmentStatusId && enrollmentStatusId.length ? enrollmentStatusId : '',
			ProgramVersionId: programVersionId && programVersionId.length ? programVersionId : '',
			StartDate: null,
			NameBadgeId: nameBadgeId || '',
			skip: 0,
			take: 15
		});
		setChecked([]);
	};

	const loadMoreStudents = () => {
		actions.loadMoreStudents({
			...filters,
			skip: studentsList.length,
		});
	};

	const isSelected = (stuEnrollmentId: string) => selectedStudents.findIndex(m => stuEnrollmentId === m.currentEnrollmentId) !== -1;

	React.useEffect(() => {
		handleFieldOnChange('selectedStudents', selectedStudents, (v: StudentProfile) => v);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedStudents]);

	React.useEffect(() => {
		return () => {
			actions.reset();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	let parameters = (
		<div className={classes.root}>
			<FormContext {...data}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={1}>
						<Grid item md={4} sm={4} xs={12}>
							<CampusForCurrentUserAutoComplete
								name='campusId'
								valueFilter={
									model.campusId
										? {
											key: 'value',
											values: [model.campusId],
										}
										: undefined
								}
								filterHandle={(v: any) => handleCampusChange((v && v.value) || undefined)}
								error={!!data.errors.campusId} helperText={
									data.errors.campusId && data.errors.campusId.message
								}
								inputRef={data.register({ required: 'Campus is required.' })} />
						</Grid>

						<Grid item md={4} sm={4} xs={12}>
							<ProgramVersionAutoComplete
								multiple
								chipSize="small" maxTags={1}
								campusId={model.campusId}
								label={'Program Version(s)'}
								valueFilter={
									model.programVersionId
										&& model.programVersionId.length > 0
										? {
											key: 'value',
											values: model.programVersionId,
										}
										: undefined
								}
								filterHandle={(v: any) => handleFieldOnChange('programVersionId', v, (value: any) => value.map((item: any) => item.value))}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={1}>
						<Grid item md={4} sm={4} xs={12}>
							<EnrollmentStatusAutoComplete
								multiple
								campusId={model.campusId}
								chipSize="small"
								maxTags={1}
								label={'Enrollment Status(es)'}
								valueFilter={
									model.enrollmentStatusId
										&& model.enrollmentStatusId.length > 0
										? {
											key: 'value',
											values: model.enrollmentStatusId,
										}
										: undefined
								}
								filterHandle={(v: any) => handleFieldOnChange('enrollmentStatusId', v, (value: any) => value.map((item: any) => item.value))}
							/>
						</Grid>
						<Grid item md={4} sm={4} xs={12}>
							<StudentGroupsAutoComplete
								label={'Student Group(s)'}
								chipSize="small" maxTags={1}
								params={{ inActiveStudentGroup: model.inactiveStudentGroup }}
								campusId={model.campusId}
								valueFilter={
									model.studentsGroup
										&& model.studentsGroup.length > 0
										? {
											key: 'value',
											values: model.studentsGroup,
										}
										: undefined
								}
								filterHandle={(v: any) => handleFieldOnChange('studentsGroup', v, (value: any) => value.map((item: any) => item.value))}
							/>
						</Grid>
						<Grid item md={3} sm={3} xs={12} className={classes.switch}>
							<FormControlLabel
								label="InActive Student Groups"
								control={
									<Switch
										checked={model.inactiveStudentGroup}
										value="inActiveStudentGroup"
										onChange={() => setModel({ ...model, inactiveStudentGroup: !model.inactiveStudentGroup, studentsGroup: [] })}
										color="primary"
									/>
								}
							/>
						</Grid>


					</Grid>
					{!model.allstudents && <Grid container spacing={1}>
						<Grid item md={12} sm={12} xs={12}>
							<InputField type={InputType.TEXT} label="Student Name, Badge Id" name="nameBadgeId" value={model.nameBadgeId} onChange={(e) => handleFieldOnChange('nameBadgeId', e?.target?.value ?? '')} />
						</Grid>
					</Grid>}
					<Grid container spacing={1}>
						<Grid item>
							<FormControlLabel
								id="lblSingleStudent"
								className={classes.switch}
								labelPlacement="end"
								label="Print All"
								control={
									<Switch
										checked={model.allstudents}
										name="allStudetns"
										onChange={(e: any) => {
											model.allstudents = e ? e.target.checked : false;
											setModel({ ...model });
										}}
										color="primary"
									/>
								}
							/>
						</Grid>
					</Grid>
					{model.allstudents && <Grid container spacing={1}>
						<Grid item>
							<Typography variant="body2" color="error" component="p">
							Selecting Print All may require additional time to generate the report.
							</Typography>
						</Grid>
					</Grid>}
					{!model.allstudents &&
						<React.Fragment> <Grid container spacing={1}>
							<Grid item>
								<ProgressSaveButton onClick={handleSubmit(searchStudents)} type="button" text="Search" loading={searchingStudents} />
							</Grid>
						</Grid>
						<Grid container spacing={1}>
							<Grid item md={12} sm={12} xs={12}>
								<Card>
									<CustomCardTitle title='Search Result(s)' />
									<CardContent>
										<TableContainer>
											<Scrollbars
												className={classes.scroll}
												style={{ height: 250 }}
												renderView={(props) => (
													<div {...props} style={{ ...props.style, overflowX: 'hidden' }} />
												)}
												onScrollFrame={(val: positionValues) => {
													if (!searchingStudents && hasMore && val.top >= 1) {
														loadMoreStudents();
													}
												}}
											>
												<Table size="small" aria-label="simple table" stickyHeader>
													<TableHead>
														<TableRow>
															<TableCell variant="head"></TableCell>
															<TableCell variant="head" align="left">Badge ID</TableCell>
															<TableCell variant="head" align="left">Name</TableCell>
															<TableCell variant="head" align="left">Program Version</TableCell>
															<TableCell variant="head" align="left">Enrollment Status</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{
															searchingStudents && <TableRow><TableCell colSpan={5} align="center"><CircularProgress size={50} color="primary" /></TableCell></TableRow>
														}
														{
															!searchingStudents && studentsList.length === 0 && <TableRow><TableCell colSpan={5} align="center">No records found.</TableCell></TableRow>
														}
														{
															!searchingStudents && studentsList.length > 0 && studentsList.map((student, index: number) => (
																<TableRow key={index}>
																	<TableCell align="left">
																		<Checkbox disabled={isSelected(student.currentEnrollmentId || '')} color="primary" checked={!!checked[index]} onChange={() => {
																			const newChecked = [...checked];
																			newChecked[index] = !(newChecked[index] || false);
																			setChecked(newChecked);
																		}} />
																	</TableCell>
																	<TableCell align="left">{student.studentNumber}</TableCell>
																	<TableCell align="left">{student.studentName}</TableCell>
																	<TableCell align="left">{student.currentProgramVersion}</TableCell>
																	<TableCell align="left">{student.currentStatus}</TableCell>
																</TableRow>
															))
														}
														{
															loadingMoreStudents && <TableRow><TableCell colSpan={5} align="center"><CircularProgress size={50} color="primary" /></TableCell></TableRow>
														}
													</TableBody>
												</Table>
											</Scrollbars>

										</TableContainer>
									</CardContent>
								</Card>

							</Grid>
						</Grid>
						<Grid container justifyContent="center" spacing={1}>
							<Grid item>
								<Button variant="contained" color='primary' onClick={() => {
									const selectedStudents = studentsList.filter((_student, index: number) => checked[index]);
									actions.addToSelectedStudents(selectedStudents);
									setChecked([]);
								}}>Select Student(s)</Button>
							</Grid>
							<Grid item>
								<Button variant="contained" color='primary' onClick={() => {
									const selectedStudents = toRemove.map((m, i) => ({ checked: (!!m || false), index: i })).filter((m) => m.checked).map((m) => m.index);
									actions.removeFromSelectedStudents(selectedStudents);
									setToRemove([]);
								}}>Remove Student(s)</Button>
							</Grid>
						</Grid>
						<Grid container spacing={1}>
							<Grid item md={12} sm={12} xs={12}>
								<Card>
									<CustomCardTitle title='Selected Students' />
									<CardContent>
										<TableContainer>
											<Scrollbars
												className={classes.scroll}
												style={{ height: 250 }}
												renderView={(props) => (
													<div {...props} style={{ ...props.style, overflowX: 'hidden' }} />
												)}
											>
												<Table size="small" stickyHeader>
													<TableHead>
														<TableRow>
															<TableCell variant="head"></TableCell>
															<TableCell variant="head" align="left">Badge ID</TableCell>
															<TableCell variant="head" align="left">Name</TableCell>
															<TableCell variant="head" align="left">Program Version</TableCell>
															<TableCell variant="head" align="left">Enrollment Status</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{
															selectedStudents.length === 0 && <TableRow><TableCell colSpan={5} align="center">No Students Selected.</TableCell></TableRow>
														}
														{
															selectedStudents.length > 0 && selectedStudents.map((student, index: number) => (
																<TableRow key={index}>
																	<TableCell align="left">
																		<Checkbox color="primary" checked={!!toRemove[index]} onChange={() => {
																			const newChecked = [...toRemove];
																			newChecked[index] = !(newChecked[index] || false);
																			setToRemove(newChecked);
																		}} />
																	</TableCell>
																	<TableCell align="left">{student.studentNumber}</TableCell>
																	<TableCell align="left">{student.studentName}</TableCell>
																	<TableCell align="left">{student.currentProgramVersion}</TableCell>
																	<TableCell align="left">{student.currentStatus}</TableCell>
																</TableRow>
															))
														}
													</TableBody>
												</Table>
											</Scrollbars>
										</TableContainer>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
						</React.Fragment>
					}
					<Grid container direction="row" spacing={1}>
						<Grid item>
							<FormControlLabel
								id="lblSingleStudent"
								className={classes.switch}
								labelPlacement="end"
								label="Show Signature Line"
								control={
									<Switch
										checked={model.showSignature}
										name="singleStudent"
										onChange={(e: any) => {
											model.showSignature = e ? e.target.checked : false;
											setModel({ ...model });
										}}
										color="primary"
									/>
								}
							/>
						</Grid>
					</Grid>
					<Grid container direction="row" spacing={1}>
						<Grid item md={12} sm={12} xs={12}>
							<Autocomplete
								{...exportTypeAutoComplete}
								autoComplete
								includeInputInList
								onChange={(e: any, value: any) => {
									handleFieldOnChange(
										'exportType',
										value ? value : undefined,
										(v: any) => v?.id
									);
								}}
								value={getSelectedItem(
									exportTypes,
									model.exportType?.toString() ?? ''
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Export Type"
										name="exportType"
										error={!!data.errors.exportType}
										inputRef={data.register({ required: true })}
										helperText={
											data.errors.exportType
												? 'Export Type is required.'
												: undefined
										}
										fullWidth
									/>
								)}
							/>
						</Grid>
					</Grid>
				</form>
			</FormContext>
		</div>
	);
	reportOverview.parameters = parameters;

	return (
		<ReportOverview
			reportOverview={reportOverview}
			filterHandler={filterHandler}
			getReportParameters={getReportParameters}
			exportHandler={onSubmit}
			hidePreview
		/>
	);
};
export default IIllinoisTranscriptReport;
