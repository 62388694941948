import { cookiesConstants } from "../../constants/actions.constants";
import * as AvedaSchoolBackground from "../../resources/images/AVEDA/background.png";
import * as PMTSSchoolBackground from "../../resources/images/PMTS/background.png";
import * as TUBCSchoolBackground from "../../resources/images/TUBC/background.png";
import * as ArcLabsSchoolBackground from "../../resources/images/ARCLABS/background.png";
import * as MPISchoolBackground from "../../resources/images/MPI/background.png";
import * as ANGELESSchoolBackground from "../../resources/images/ANGELES/background.png";
import * as FABSchoolBackground from "../../resources/images/FABSCHOOL/background.jpg";
import * as PHAGANSchoolBackground from "../../resources/images/PHAGANS/background.png";
import * as TRAININGSchoolBackground from "../../resources/images/TRAINING/background.jpg";
import * as COLLEGESchoolBackground from "../../resources/images/COLLEGE/background.jpg";
import * as DefaultSchoolBackground from "../../resources/images/DEFAULT/background.png";
import * as CELEBRITYSchoolBackground from "../../resources/images/CELEBRITY/background.png";
import * as DPRESSLEYBackground from "../../resources/images/DPRESSLEY/background.png";
import * as RITZBackground from "../../resources/images/RITZ/background.png";
import * as ChromeInstituteBackground from '../../resources/images/CHROMEINSTITUTE/background.png';

const SelectedSchoolBackground = (props: any): any => {
	switch (props) {
		case cookiesConstants.PMTS_THEME:
			return PMTSSchoolBackground;
		case cookiesConstants.TUBC_THEME:
			return TUBCSchoolBackground;
		case cookiesConstants.AVEDA_THEME:
			return AvedaSchoolBackground;
		case cookiesConstants.ARCLABS_THEME:
			return ArcLabsSchoolBackground;
		case cookiesConstants.MPI_THEME:
			return MPISchoolBackground;
		case cookiesConstants.ANGELES_THEME:
			return ANGELESSchoolBackground;
		case cookiesConstants.PHAGANS_THEME:
			return PHAGANSchoolBackground;
		case cookiesConstants.CELEBRITY_THEME:
			return CELEBRITYSchoolBackground;
		case cookiesConstants.FABSCHOOL_THEME:
			return FABSchoolBackground;
		case cookiesConstants.TRAINING_THEME:
			return TRAININGSchoolBackground;
		case cookiesConstants.COLLEGE_THEME:
			return COLLEGESchoolBackground;
		case cookiesConstants.DPRESSLEY_THEME:
			return DPRESSLEYBackground;
		case cookiesConstants.RITZ_THEME:
			return RITZBackground;
		case cookiesConstants.CHROME_INSTITUTE_THEME:
				return ChromeInstituteBackground;
		default:
			return DefaultSchoolBackground;
	}
};

export default SelectedSchoolBackground;
