import { IProgramCatalogGeneralDetails, ProgramCatalogGeneralDetails } from "./ProgramVersion/IProgramCatalogGeneralDetails";
import { IProgramVersionAcademicDetails, ProgramVersionAcademicDetails } from "./ProgramVersion/IProgramVersionAcademicDetails";
import { IProgramVersionAttendanceDetails, ProgramVersionAttendanceDetails } from "./ProgramVersion/IProgramVersionAttendanceDetails";
import { IProgramVersionFinancialDetails, ProgramVersionFinancialDetails } from "./ProgramVersion/IProgramVersionFinancialDetails";
export interface IProgramCatalogDetails{
    general: IProgramCatalogGeneralDetails;
    academics: IProgramVersionAcademicDetails;
    attendance: IProgramVersionAttendanceDetails;
    financial: IProgramVersionFinancialDetails;
}

export class ProgramCatalogDetails implements IProgramCatalogDetails{
    attendance: IProgramVersionAttendanceDetails = new ProgramVersionAttendanceDetails();
    financial: IProgramVersionFinancialDetails = new ProgramVersionFinancialDetails();
    general: IProgramCatalogGeneralDetails = new ProgramCatalogGeneralDetails();
    academics: IProgramVersionAcademicDetails = new ProgramVersionAcademicDetails();
}