import API from "../../apiWrapper";
import {
  ITermSearchParam,
  ICourseRegisterModel,
  ICourseDrop,
} from "../../../interfaces/setup/creditHour/ITermSearchModels";
import { IClassRegistrationByClass } from "../../../../src/interfaces/setup/creditHour/IClassRegistration";
import { IClassRosterModel } from "../../../../src/interfaces/setup/creditHour/ITermSearchModels";

export const GetStudentRegistrationDetail = (enrollmentId: string) => {
  return API()
    .get("/AcademicRecords/ClassRegistration/GetStudentRegistrationDetail", {
      params: {
        enrollmentId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const GetEnrollmentsByClass = (classId: string) => {
  return API()
    .get("/AcademicRecords/ClassRegistration/GetEnrollmentsByClass", {
      params: {
        classId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const generateReport = (params : IClassRosterModel) => {
  
  return API()
    .post(
      "/Reports/InstructorReport/GetInstructorClassRosterReport",
      params,
      { responseType: "blob" }
    )
    .then(
      (res: any) => {
        if (res && res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            let extension =  "pdf" ;
            const fileName =
              "InstructorSummaryReport" +
              "." +
              extension;

            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        }
      },
      (error: any) => {
        return null;
      }
    );
};

export const SearchTerms = (params: ITermSearchParam) => {
  return API()
    .post("/AcademicRecords/ClassRegistration/SearchTerms", params)
    .then(
      (res: any) => {
        if (res && res.data && res.data) {
          return res;
        }
        throw new Error("Unknown error");
      },
      (error: any) => {
        if (error.response) {
          throw new Error(error.response.data);
        }
        throw new Error("Unknown error");
      }
    );
};

export const GetStudentClasses = (params: ITermSearchParam) => {
  return API()
    .post("/AcademicRecords/ClassRegistration/GetStudentClasses", params)
    .then(
      (res: any) => {
        if (res && res.data && res.data) {
          return res;
        }
        throw new Error("Unknown error");
      },
      (error: any) => {
        if (error.response) {
          throw new Error(error.response.data);
        }
        throw new Error("Unknown error");
      }
    );
};

export const DropClass = (params: ICourseDrop) => {
  return API()
    .post("/AcademicRecords/ClassRegistration/DropClass", params)
    .then(
      (res: any) => {
        if (res && res.data && res.data) {
          return res;
        }
        throw new Error("Unknown error");
      },
      (error: any) => {
        if (error.response) {
          throw new Error(error.response.data);
        }
        throw new Error("Unknown error");
      }
    );
};

export const ReverseDropClass = (params: ICourseDrop) => {
  return API()
    .post("/AcademicRecords/ClassRegistration/ReverseDropClass", params)
    .then(
      (res: any) => {
        if (res) {
          return res;
        }
        throw new Error("Unknown error");
      },
      (error: any) => {
        if (error.response) {
          throw new Error(error.response.data);
        }
        throw new Error("Unknown error");
      }
    );
};

export const RegisterCourse = (params: ICourseRegisterModel[]) => {
  return API()
    .post("/AcademicRecords/ClassRegistration/RegisterCourses", params)
    .then(
      (res: any) => {
        if (res && res.data && res.data) {
          return res;
        }
        throw new Error("Unknown error");
      },
      (error: any) => {
        if (error.response) {
          throw new Error(error.response.data);
        }
        throw new Error("Unknown error");
      }
    );
};

export const GetEnrollments = (params: IClassRegistrationByClass) => {
  return API()
    .post("/AcademicRecords/ClassRegistration/GetEnrollments", params)
    .then(
      (res: any) => {
        if (res && res.data && res.data) {
          return res;
        }
        throw new Error("Unknown error");
      },
      (error: any) => {
        if (error.response) {
          throw new Error(error.response.data);
        }
        throw new Error("Unknown error");
      }
    );
};

export const IsAttendanceExistsForAnyClass = (stuEnrollId:string, classIds:string) => {
  return API()
    .get(`/AcademicRecords/ClassRegistration/IsAttendanceExistsForAnyClassAsync?stuEnrollId=${stuEnrollId}&classIds=${classIds}`)
    .then(
      (res: any) => {
        return res.data;
      },
      (error: any) => {
        if (error.response) {
          throw new Error(error.response.data);
        }
        throw new Error("Unknown error");
      }
    );
};
