import { IIPEDSCostIIPartCDetailSummaryParam } from "../../../interfaces/reports/IPEDSCostIIPartCDetailSummary";
import { ToBlob } from "../../../utils/base64Helper";
import { Download } from "../../../utils/file";
import API from "../../apiWrapper";

export const getCostIIPartCDetailSummaryReport = (
    params: IIPEDSCostIIPartCDetailSummaryParam,
    isPreview: boolean = false
  ): Promise<any> => {
    console.clear();
    console.log(params)
    return API()
      .post("/Reports/IPEDS/CostIIPartCDetailSummary", params)
      .then(
        (response:any) => {
          if (isPreview) {
            return response.data.base64Data;
          }
          const extension = "pdf";
          var fileName =
            "MyReport" + "." + extension;
  
          Download(ToBlob(response.data.base64Data), fileName);
          return undefined;
        },
        (_) => {
          return null;
        }
      );
  };