import {
	Button, Card,
	CardContent, Dialog, DialogActions, DialogContent, DialogTitle
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";
import * as leadsApi from "../../../api/admissions/leadsApi";
import CustomSnackbar from "../../../components/notifications/CustomSnackbar";
import ProgressSaveButton from "../../../components/_Layout/Buttons/ProgressSaveButton";
import { ILead } from "../../../interfaces/admissions/ILead";
import { LeadSearchInputPaged } from "../../../interfaces/admissions/LeadSearchInputPaged";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import LeadImportGrid from "./LeadImportGrid";
import LeadSearchFilter from "./LeadSearchFilter";


const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			padding: theme.spacing(0),
			width: "100%", 
			minHeight: "100%",
			height: "95%",
			overflowX: "hidden",
			overflowY: "auto",
			flexDirection: "column",
			marginTop: theme.spacing(3),
			backgroundColor: theme.palette.paper.background,
		},
		dialogHeader: {
			backgroundColor: theme.palette.site.secondary,
			"& .MuiTypography-root": {
				fontWeight: theme.typography.fontWeightBold,
				fontSize: "12pt",
			},
		},
		dialogContent: {
			padding: theme.spacing(3),
		},
		inputField: {
			width: "100%",
		},
		cardContent: {
			padding: theme.spacing(1, 3),
			height: 450,
		},
		cardGrid: {
			marginTop: theme.spacing(2),
			height: 500,
		},
		fullWidthField: {
			width: "100%",
		},
		gridSpacing: {
			marginTop: theme.spacing(1),
		},
		marginBottom: {
			marginBottom: theme.spacing(1),
		},
	})
);

export interface ImportLeadDialogProps {
	visible: boolean;
	onImport: (val: any) => void;
	onCancel: () => void;
	postProcessLeadImport?: boolean;
}

const ImportLeadDialog = (props: ImportLeadDialogProps) => {
	const classes = useStyles({});
	const { onCancel } = props;
	//const [startDate, setStartDateState] = React.useState<Date>();
	const [model, setModel] = React.useState<LeadSearchInputPaged>(
		{} as LeadSearchInputPaged
	);
	const [showSnackBar, setShowSnackBar] = React.useState<boolean>(false);
	const [messageInfo, setMessageInfo] = React.useState<string | undefined>(
		undefined
	);
	const [snackBarVariant, setSnackBarVariant] = React.useState<
		"success" | "error" | "warning" | "info"
	>("info");

	const { onImport } = props;
	const [selectedLead, setSelectedLead] = React.useState<ILead | undefined>(
		undefined
	);
	const [readyToImport, setReadyToImport] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [isOpen, setIsOpen] = React.useState<boolean>(false);

	React.useEffect(() => {
		setIsOpen(props.visible);
	}, [props.visible]);

	const handleFieldChange = (fieldId: string, value: any) => {
		let updatedModel = model;
		(updatedModel as any)[fieldId] = value;
		setModel({ ...updatedModel });
	};

	React.useEffect(() => {
		setReadyToImport(!!selectedLead);
	}, [selectedLead]);

	const handleSelectLead = React.useCallback((lead: ILead | undefined) => {
		if (setSelectedLead) {
			setSelectedLead(lead);
		}
	}, []);

	const importLead = (lead: ILead) => {

		if(props.postProcessLeadImport)
		{
			onImport(lead);
			return;
		}

		setLoading(true);
		leadsApi.importLead(lead).then((response) => {
			if (response.data) {
				var lead = response.data as ILead;

				if (lead && lead.leadId) {
					onImport(lead);

					resetImportProcess();
				} else {
					setSnackBarVariant("error");
					setMessageInfo(
						"Lead import could not be completed. Please try again."
					);
					setShowSnackBar(true);
				}
			} else {
				let errorMessage = response.request.response.replaceAll('"', "");
				setSnackBarVariant("error");
				setMessageInfo(errorMessage);
				setShowSnackBar(true);
			}

			setLoading(false);
		});
	};

	const handleClose = () => {
		onCancel();
	};
	const resetImportProcess = () => {
		setModel({} as LeadSearchInputPaged);
		setReadyToImport(false);
		setSelectedLead(undefined);
	};

	const handleImport = () => {

		if(selectedLead && props.postProcessLeadImport)
		{
			importLead(selectedLead);
		}
		///both imported lead and existing lead selected, do merge and import process
		///imported lead selected only, do import
		else if (selectedLead) {
			importLead(selectedLead);
		}

		resetImportProcess();
	};
	return (
		<Dialog
			open={isOpen}
			aria-labelledby="form-dialog-title"
			maxWidth="lg"
			fullWidth={true}
			disableBackdropClick
		>
			<DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
				Import Lead
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<div className={classes.marginBottom}>
					<LeadSearchFilter handleFieldChange={handleFieldChange} model={model} />
				</div>
				<div>
					<Card square={true} className={classes.cardGrid}>
						<CustomCardTitle title={"Search Results"} />
						<CardContent className={classes.cardContent}>
							<LeadImportGrid
								params={model}
								setSelectedLead={handleSelectLead}
								meta={selectedLead}
							></LeadImportGrid>
						</CardContent>
					</Card>
				</div>
			</DialogContent>
			<DialogActions>
				<ProgressSaveButton
					text={"Import"}
					onClick={handleImport}
					loading={loading}
					disabled={!readyToImport}
				></ProgressSaveButton>

				<Button
					color="primary"
					onClick={() => {
						handleClose();
						resetImportProcess();
					}}
				>
					Cancel
				</Button>
			</DialogActions>

			<CustomSnackbar
				variant={snackBarVariant}
				message={messageInfo}
				open={showSnackBar}
				onClose={(event?: React.SyntheticEvent, reason?: string) => {
					setShowSnackBar(false);
				}}
			></CustomSnackbar>
		</Dialog>
	);
};
export default ImportLeadDialog;
