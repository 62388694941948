import * as actionTypes from "../../constants/actions.constants";
import { Action } from "redux";

const initialState = {mode:'grid'};

const reportsPageViewModeReducer = (state = initialState, action: Action<any>) => {

    switch (action.type) {
      case actionTypes.reportsPageViewModeConstants.REPORTS_PAGE_VIEW_MODE_CHANGED: {
          let actionData = (action as any).data;
        return {
          ...state, mode: actionData.mode
        };
      }
  
      default: {
        return state;
      }
  }
  };
  
  export default reportsPageViewModeReducer;