import * as React from 'react';
import { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, Button, colors } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import { IReportOverview } from '../../../interfaces/reports/IReportOverview';
import ColoredChip from '../../Chips/ColoredChip';
import { Tag } from '../../../interfaces/common/Tag';
import ReportExport from '../Reports/ReportExport';

const useStyles = makeStyles((theme: any) => ({
    root: {},
    header: {
        padding: theme.spacing(2)
    },
    label: {
        marginTop: theme.spacing(1)
    },
    shareButton: {
        marginRight: theme.spacing(2)
    },
    shareIcon: {
        marginRight: theme.spacing(1)
    },
    applyButton: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    }
}));

interface ReportOverviewHeaderProps {
    reportOverview: IReportOverview,
    className?: string
}
const ReportOverviewHeader = (props: ReportOverviewHeaderProps) => {
    const { reportOverview, className, ...rest } = props;

    const classes = useStyles();


    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Grid
                alignItems="flex-start"
                container
                justify="flex-start"
                spacing={3}
            >
                <Grid item xs={3}>
                    <Typography
                        component="h2"
                        gutterBottom
                        variant="overline"
                    >
                        Report Overview
                    </Typography>
                    <Typography
                        component="h1"
                        gutterBottom
                        variant="h3"
                    >
                        {reportOverview.report.name}
                    </Typography>
                    {
                        reportOverview.report.tags.map((tag: Tag, index: number) => (
                            <ColoredChip
                                id={tag.text}
                                color={tag.color}
                                label={tag.text}
                            />))

                    }
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h5">Description:</Typography>

                    <Typography
                        gutterBottom
                        variant="body1"
                    >
                        {reportOverview.report.description}
                    </Typography>

                </Grid>
                {/* <Grid item xs={3}>
                    <ReportExport />
                </Grid> */}

            </Grid>

        </div>
    );
};

ReportOverviewHeader.defaultProps = {};

export default ReportOverviewHeader;
