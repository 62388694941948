import {  colors} from '@material-ui/core';
import { Tag } from '../../../interfaces/common/Tag'


interface TagDefinitions {
    [key: string]: Tag;
}
interface TagsColorInterface {
    [key: string]: string;
}


export const Tags: TagDefinitions = {
    lead: { color: colors.yellow[700], text: "LEAD", index: 0, Id: "9" }, 
    student: { color: colors.teal[500], text: "STUDENT", index: 1, Id: "9" },
    attendance: { color: colors.blueGrey[500], text: "ATTENDANCE", index: 2, Id: "9" }, 
    financial: { color: colors.green[500], text: "FINANCIAL", index: 3, Id: "9" },
    ipeds: { color: colors.blue[500], text: "IPEDS", index: 4, Id: "9" }, 
    naccas: { color: colors.orange[500], text: "NACCAS", index: 5, Id: "9" }
   
}

export const TagsColor: TagsColorInterface = {
    accreditation: colors.yellow[700], 
    student:  colors.teal[500],
    attendance: colors.blueGrey[500], 
    financial: colors.green[500], 
    ipeds:  colors.blue[500], 
    stateboard: colors.orange[500], 
    adhoc: colors.pink[500], 
    springipeds: '#4682B4',
    winteripeds: '#73C2FB',
    fallipeds: '#57A0D3',
    gainfulemployment : '#adaf4c'
}



export default Tags