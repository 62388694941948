import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Typography, Grid } from "@material-ui/core";
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import { IPaymentPeriod } from "../../../interfaces/student/quickInfo/IProgramPaymentPeriodCard";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		fieldTextStyle: {
			fontSize: 12,
			color: theme.palette.text.primary
		},
		selectedRow: {
			backgroundColor: theme.palette.charts.selectedRow
		},
		selectedTextStyle: {
			fontWeight: theme.typography.fontWeightBold
		},
		arrowIcon: {
			color: theme.palette.primary,
			width: 15
		}
	})
);

type IPaymentPeriodChartProps = {
	ppData: Array<IPaymentPeriod> | undefined;
	className?: "";
	isCreditHour: boolean;
};

const PaymentPeriodChart = (props: IPaymentPeriodChartProps) => {
	const { className, ppData, ...rest } = props;

	const classes = useStyles({});
	const [open, setOpen] = useState(false);
	const [data, setData] = useState(null);

	useEffect(() => {
		let mounted = true;

		const fetchData = () => {};

		fetchData();

		return () => {
			mounted = false;
		};
	}, []);

	function RenderText(text: any, isActive?: any) {
		return (
			<Typography
				className={clsx(classes.fieldTextStyle, {
					[classes.selectedTextStyle]: isActive
				})}
			>
				{text}
			</Typography>
		);
	}

	return (
		<Grid container direction="column" justify="center" alignItems="center">
			{props.ppData?.map(function(rowItem: IPaymentPeriod, index: any) {
				return (
					<Grid
						container
						className={clsx(
							{ [classes.selectedRow]: rowItem.isCurrent },
							{ [classes.selectedTextStyle]: rowItem.isCurrent }
						)}
						key={index}
						direction="row"
						justify="center"
						alignItems="center"
					>
						<Grid item xs={1}>
							{rowItem.isCurrent && (
								<ArrowRightAlt className={classes.arrowIcon}></ArrowRightAlt>
							)}
						</Grid>
						<Grid item xs={1}>
							{RenderText(rowItem.paymentPeriodNumber, rowItem.isCurrent)}
						</Grid>
						<Grid item xs={6}>
							{RenderText(
								rowItem.startDateAsString + " - " + rowItem.endDateAsString,
								rowItem.isCurrent
							)}
						</Grid>
						<Grid item xs={4}>
							{
								rowItem.beginUnit ? RenderText(
									props.isCreditHour ? rowItem.beginUnit.toFixed(2) + " - " + rowItem.endUnit?.toFixed(2) : rowItem.beginUnit + " - " + rowItem.endUnit,
									rowItem.isCurrent
								) : ""
							}
						</Grid>
					</Grid>
				);
			})}
		</Grid>
	);
};

export default PaymentPeriodChart;
