import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
//import { Responsive as ResponsiveGridLayout} from 'react-grid-layout';
import { Responsive, WidthProvider, Layout, Layouts } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { useSelector } from 'react-redux';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import * as dashboardApi from '../../../src/api/system/dashboardApi';
import DashboardConfig from './dashboardConfig';
import { IWidget } from '../../interfaces/systemCatalog/IWidget';
import { IWidgetUpdate } from '../../interfaces/systemCatalog/IWidgetUpdate';

const ResponsiveGridLayout = WidthProvider(Responsive);

const useStyles = makeStyles((theme: any) => ({
	root: {
		padding: theme.spacing(3),
	},
	container: {
		height: '100%',
		minHeight: '100%',
		paddingTop: theme.spacing(3),
		paddingRight: theme.spacing(3),
		marginRight: -theme.spacing(3),
		overflowY: 'scroll',
		overflowX: 'hidden'
	},
	div: {
		backgroundColor: 'red',
	},
}));

const Dashboard = () => {
	const classes = useStyles();
	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);
	const userId = useSelector((state: any) => state.session.user.userId);
	const defaultLayouts = {
		lg: [
			{ i: 'a', x: 0, y: 0, w: 5, h: 2, minW: 5 },
			{ i: 'b', x: 6, y: 0, w: 6, h: 2, minW: 5 },
			{ i: 'c', x: 0, y: 2, w: 4, h: 3, minW: 4, minH: 3 },
		],
		md: [
			{ i: 'a', x: 0, y: 0, w: 5, h: 2, minW: 5 },
			{ i: 'b', x: 5, y: 0, w: 5, h: 2, minW: 5 },
			{ i: 'c', x: 0, y: 2, w: 4, h: 3, minW: 4, minH: 3 },
		],
		sm: [
			{ i: 'a', x: 0, y: 0, w: 5, h: 2, minW: 5 },
			{ i: 'b', x: 0, y: 2, w: 6, h: 2, minW: 5 },
			{ i: 'c', x: 0, y: 4, w: 4, h: 3, minW: 4, minH: 3 },
		],
	};

	const [widgets, setWidgets] = React.useState<Array<IWidget>>([]);
	const [layouts, setLayouts] = React.useState<Layouts>(defaultLayouts);
	const [currentBreakpoint, setCurrentBreakpoint] = React.useState('lg');
	const [updatedWidgets, setUpdatedWidgets] = React.useState<Array<IWidget>>(
		[]
	);
	const timerRef = React.useRef<NodeJS.Timeout | null>(null);
	const [modalOpen, setModalOpen] = React.useState(false);

	const buildLayouts = (widgets: IWidget[]) => {
		const layouts = { lg: [], md: [], sm: [] } as Layouts;

		widgets.map((w: IWidget) => {
			if (w.layout) {
				const widgetLayout = JSON.parse(w.layout);
				const id = w.widgetId.toString();
				if (widgetLayout.lg) {
					const lgLayout = { i: id, ...widgetLayout.lg } as Layout;
					layouts['lg'].push(lgLayout);
				}
				if (widgetLayout.md) {
					const mdLayout = { i: id, ...widgetLayout.md } as Layout;
					layouts['md'].push(mdLayout);
				}
				if (widgetLayout.sm) {
					const smLayout = { i: id, ...widgetLayout.sm } as Layout;
					layouts['sm'].push(smLayout);
				}
			}
		});
		return layouts;
	};

	const updateBreakpoint = (newBreakpoint: string) => {
		setCurrentBreakpoint(newBreakpoint);
	};

	const updatePosition = (e: Array<Layout> ) => {

		let widgetToUpdate = widgets.filter((w: IWidget) => e.findIndex(m => m.i === w.widgetId.toString()) > -1);
		if (widgetToUpdate && widgetToUpdate.length > 0) { 
			widgetToUpdate.forEach((widget: IWidget) => {
				const widgetLayout = JSON.parse(widget.layout);
				 let lo = widgetLayout[currentBreakpoint] as Layout | undefined;
				  if (!lo) {
					 lo = {} as Layout;
					 }
					  const updatedValue = e.find((m) => m.i === widget.widgetId.toString());
					   if (updatedValue) {
						 lo.h = updatedValue.h;
						 lo.w = updatedValue.w;
						 lo.x = updatedValue.x;
						 lo.y = updatedValue.y;
						 }
				widgetLayout[currentBreakpoint] = lo;
				 widget.layout = JSON.stringify(widgetLayout); });
				  setUpdatedWidgets(widgetToUpdate);
				 }
				 };
		
	React.useEffect(() => {
		if (timerRef.current !== null) clearTimeout(timerRef.current);

		timerRef.current = setTimeout(() => {
			dashboardApi
				.updateWidgetPositions({
					widgets: updatedWidgets,
					campusId: userSelectedCampus,
					userId: userId,
				} as IWidgetUpdate);
		}, 1500);

		return () => {
			if (timerRef.current) clearTimeout(timerRef.current);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updatedWidgets]);

	React.useEffect(() => {
		if (userSelectedCampus) {
			dashboardApi.getUserWidgets(userSelectedCampus).then(
				(response: any) => {
					if (response) {
						let data = response as Array<IWidget>;
						setWidgets(data);
						setLayouts(buildLayouts(data));
					}
				},
				() => { }
			);
		}
	}, [userSelectedCampus]);

	const onMouseDown = (ev: any) => {
		if (modalOpen) {
			ev.stopPropagation();
		}
	};


	return (
		<div className={classes.container} >
			<ResponsiveGridLayout
				className="layout"
				layouts={layouts}
				breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
				cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
				onDragStop={(layout: Layout[], _oldItem: Layout, newItem: Layout) => {
					updatePosition(layout);
				}}
				onResizeStop={(layout: Layout[], _oldItem: Layout, newItem: Layout) => {
					updatePosition(layout);
				}}
				onBreakpointChange={(newBreakpoint: string, _newCols: number) => {
					updateBreakpoint(newBreakpoint);
				}}
			>
				{widgets.map((w: IWidget, i: number) => {
					let widget = DashboardConfig.widgets[w.code];
					if (widget) {
						return < div key={w.widgetId.toString()} >
							<div style={{ height: '100%' }} onMouseDown={(ev: any) => onMouseDown(ev)}  >
								{React.cloneElement(React.createElement(widget), { setModalOpen: setModalOpen })}
							</div>
						</div>;
					}
					return <React.Fragment key={`${i}`} />;

				})
				}
			</ResponsiveGridLayout>
		</div>
	);
};

export default Dashboard;
