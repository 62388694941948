import IStateWiseLicense from '../../interfaces/setup/campus/IStateWiseLicense';
import { createReducer } from '../utils';

const { reducer, actions, selectors, types } = createReducer({
	sync: {
		clear: () => ({})
	},
	asyncs: {
		fetchRecord: {
			request: (campusId: string) => ({ campusId }),
			success: (payload: Array<IStateWiseLicense>) => ({ payload }),
			failure: (error: string) => ({ error })
		},
		createLicense:{
			request: (data: IStateWiseLicense) => (data),
			success: (payload: IStateWiseLicense) => ({ payload }),
			failure: (error: string) => ({ error })
		},
		deleteLicense: {
			request: (licenseId: string) => ({licenseId}),
			success: (payload: string) => ({ payload }),
			failure: (error: string) => ({ error })
		}
	}
}, {
	list: [] as Array<IStateWiseLicense>,
	campusId: undefined as string | undefined,
}, {
	sync: {
		clear: (state) => ({ ...state, data: { ...state.data, list: [], campusId: undefined }, loading: { ...state.loading, fetchRecord: false } })
	},
	asyncs: {
		fetchRecord: {
			request: (state, { data: { campusId } }) => {
				return ({ ...state, data: { ...state.data, campusId } });
			},
			success: (state, { data: { payload } }) => {
				return ({ ...state, data: { ...state.data, list: payload } });
			},
			failure: (state, _action) => state
		},
		createLicense: {
			request: (state, _action) => state,
			success: (state, _action) => state,
			failure: (state, _action) => state
		},
		deleteLicense: {
			request: (state, _action) => state,
			success: (state, _action) => state,
			failure: (state, _action) => state
		}
	}
}, 'stateWiseLicense',
{
	list: (state) => state.data.list,
	campusId: (state) => state.data.campusId,
	loadingRecords: (state) => state.loading.fetchRecord || state.loading.createLicense || state.loading.deleteLicense,
});

export { selectors, actions, types };

export default reducer;