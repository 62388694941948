import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
    Grid,
    Typography,
    Theme,
    FormControlLabel,
    Switch
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ICertificationDetails from '../../../interfaces/student/placement/Certifications';
import InputField from '../../../components/_Layout/Inputs/InputField';
import { useFormContext, FieldError } from "react-hook-form";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fullWidthField: {
            width: "100%"
        },
        certificateTitle: {
            fontSize: 15,
            color: "Black",
            margin: theme.spacing(1, 0, 0)
        },
        switch: {
            marginLeft: "0px",
            paddingTop: theme.spacing(2)
          },
    })
);

type CertificationsProps = {
    certificationDetails: ICertificationDetails;
    updateDetails(details: ICertificationDetails): void;
    exemptForLicensureFor2020:boolean;
    setExemptForLicensureFor2020(isExemptedForLicensureFor2020:boolean):void;
};

const Certifications = (props: CertificationsProps) => {
    const { certificationDetails, updateDetails } = props;
    const data = useFormContext();
    const classes = useStyles();
    const [enableSwitch, setEnableSwitch] = React.useState(false);
    data.register('certification1Date', {validate : () => (!certificationDetails.certification1Date || (certificationDetails.certification1Date).toString() !== 'Invalid Date') ? true : false });
    data.register('certification2Date', {validate : () => (!certificationDetails.certification2Date || (certificationDetails.certification2Date).toString() !== 'Invalid Date') ? true : false });
    data.register('certification3Date', {validate : () => (!certificationDetails.certification3Date || (certificationDetails.certification3Date).toString() !== 'Invalid Date') ? true : false })
   

    const handleFieldOnChange = (fieldId: string, value: any) => {
        data.setValue(fieldId, value);
        let updatedModel = certificationDetails;
        (updatedModel as any)[fieldId] = value;
        data.triggerValidation(fieldId);

        if((fieldId === 'certification1Date' || fieldId === 'certification1Status') && !value)
        {
            (updatedModel as any)['certification2Date'] = undefined;
            (updatedModel as any)['certification2Status'] = undefined;
            (updatedModel as any)['certification3Date'] = undefined;
            (updatedModel as any)['certification3Status'] = undefined;

            data.setValue('certification2Date', undefined);
            data.setValue('certification2Status', undefined);
            data.setValue('certification3Date', undefined);
            data.setValue('certification3Status', undefined);
        }else if((fieldId === 'certification2Date'  || fieldId === 'certification2Status') && !value)
        {
            (updatedModel as any)['certification3Date'] = undefined;
            (updatedModel as any)['certification3Status'] = undefined;
            data.setValue('certification3Date', undefined);
            data.setValue('certification3Status', undefined);
        }         
        
        if (fieldId === 'certification1Date') {
            data.triggerValidation('certification1Status');
        }
        if (fieldId === 'certification2Date') {
            data.triggerValidation('certification2Status');
        }
        if (fieldId === 'certification3Date') {
            data.triggerValidation('certification3Status');
        }
        
        if(updatedModel.certification1Date && updatedModel.certification2Date && updatedModel.certification3Date
            && updatedModel.certification1Status && updatedModel.certification2Status && updatedModel.certification3Status && fieldId != "satForAllParts")
        {
            data.setValue("satForAllParts", true);
            (updatedModel as any)["satForAllParts"] = true;
            data.triggerValidation(fieldId);
        } 
        updateDetails(updatedModel);  
    };

    React.useEffect(() => {
        if(certificationDetails.certification1Date &&certificationDetails.certification1Status )
        {
            setEnableSwitch(true);
        }
        else
        {
            setEnableSwitch(false);
            data.setValue("satForAllParts", false);
            let updatedModel = certificationDetails;
            (updatedModel as any)["satForAllParts"] = false;
        }        
    }, [certificationDetails.certification1Date, certificationDetails.certification1Status]);

    data.register('certification1Status' ,{ validate:() =>  !!(certificationDetails?.certification1Date) && !certificationDetails.certification1Status ? 'Status is required.' : true });
    data.register('certification2Status' ,{ validate:() =>  !!(certificationDetails?.certification2Date) && !certificationDetails.certification2Status ? 'Status is required.' : true });
    data.register('certification3Status' ,{ validate:() =>  !!(certificationDetails?.certification3Date) && !certificationDetails.certification3Status ? 'Status is required.' : true });
    return (
        <React.Fragment>
            <Grid container direction='row' spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
            <FormControlLabel
                id="satForAllParts"
                className={classes.switch}
                labelPlacement="start"
                label="Sat for all the parts of the exams"
                control={
                  <Switch
                    disabled = {!enableSwitch}
                    checked = {certificationDetails?.satForAllParts}
                    value={certificationDetails?.satForAllParts}
                    onChange={(e: any) => {
                      handleFieldOnChange("satForAllParts", e.target.checked);
                    }}
                    color="primary"
                  />
                }
              />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
              <FormControlLabel
                id="ExemptForLicensureFor2020"
                className={classes.switch}
                labelPlacement="start"
                label="Exempt from Licensure for 2020 with attestation"
                control={
                  <Switch
                    checked = {props.exemptForLicensureFor2020}
                    value={props.exemptForLicensureFor2020}
                    onChange={(e: any) => {
                        props.setExemptForLicensureFor2020(e.target.checked)
                    }}
                    color="primary"
                  />
                }
              />
              </Grid>
            </Grid>
            <Grid container direction='row' spacing={2}>
                <Grid item xs={7} sm={4}>
                    <Typography className={classes.certificateTitle}>First Exam</Typography>
                </Grid>
            </Grid>

            <Grid container direction='row' spacing={2}>
                <Grid item xs={7} sm={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="certification1Date"
                            label="Scheduled"
                            TextFieldComponent={InputField}
                            value={certificationDetails?.certification1Date ?? null}
                            autoOk={true}
                            onChange={(value: any) => {
                                handleFieldOnChange("certification1Date", value ? value : null);
                            }}
                            className={classes.fullWidthField}
                            KeyboardButtonProps={{
                                "aria-label": "change date"
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={5} sm={3}>
                    <Autocomplete
                        disabled={!(certificationDetails?.certification1Date)}
                        options={['Passed', 'Failed']}
                        id='job'
                        value={certificationDetails?.certification1Status ?? null}
                        onChange={(e: any, value: any) => {
                            handleFieldOnChange("certification1Status", value);
                        }}
                        renderInput={params => (
                            <InputField
                                {...params}
                                label='Status'
                                name="certification1Status"
                                margin='none'
                                fullWidth
                                error={!!data.errors.certification1Status}
                                helperText={
                                    data.errors.certification1Status
                                        ? (data.errors.certification1Status as FieldError).message
                                        : undefined
                                } />
                        )}
                    />
                </Grid>
            </Grid>

            <Grid container direction='row' spacing={2}>
                <Grid item xs={7} sm={4}>
                    <Typography className={classes.certificateTitle}>Second Exam</Typography>
                </Grid>
            </Grid>

            <Grid container direction='row' spacing={2}>
                <Grid item xs={7} sm={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disabled={!(certificationDetails?.certification1Date)}
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="certification2Date"
                            label="Scheduled"
                            TextFieldComponent={InputField}
                            value={certificationDetails?.certification2Date ?? null}
                            autoOk={true}
                            onChange={(value: any) => {
                                handleFieldOnChange("certification2Date", value ? value : null);
                            }}
                            className={classes.fullWidthField}
                            KeyboardButtonProps={{
                                "aria-label": "change date"
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={5} sm={3}>
                    <Autocomplete
                        disabled={!(certificationDetails?.certification2Date)}
                        options={['Passed', 'Failed']}
                        id='job'
                        value={certificationDetails?.certification2Status ?? null}
                        onChange={(e: any, value: any) => {
                            handleFieldOnChange("certification2Status", value);
                        }}
                        renderInput={params => (
                            <InputField
                                {...params}
                                label='Status'
                                name="certification2Status"
                                margin='none' fullWidth
                                error={!!data.errors.certification2Status}
                                helperText={
                                    data.errors.certification2Status
                                        ? (data.errors.certification2Status as FieldError).message
                                        : undefined
                                } />
                        )}
                    />
                </Grid>
            </Grid>

            <Grid container direction='row' spacing={2}>
                <Grid item xs={7} sm={4}>
                    <Typography className={classes.certificateTitle}>Third Exam</Typography>
                </Grid>
            </Grid>

            <Grid container direction='row' spacing={2}>
                <Grid item xs={7} sm={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disabled={!(certificationDetails?.certification1Date) || !(certificationDetails?.certification2Date)}
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="certification3Date"
                            label="Scheduled"
                            TextFieldComponent={InputField}
                            value={certificationDetails?.certification3Date ?? null}
                            autoOk={true}
                            onChange={(value: any) => {
                                handleFieldOnChange("certification3Date", value ? value : null);
                            }}
                            className={classes.fullWidthField}
                            KeyboardButtonProps={{
                                "aria-label": "change date"
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={5} sm={3}>
                    <Autocomplete
                        disabled={!(certificationDetails?.certification3Date)}
                        options={['Passed', 'Failed']}
                        id='job'
                        value={certificationDetails?.certification3Status ?? null}
                        onChange={(e: any, value: any) => {
                            handleFieldOnChange("certification3Status", value);
                        }}
                        renderInput={params => (
                            <InputField
                                {...params}
                                label='Status'
                                name="certification3Status"
                                margin='none' fullWidth
                                error={!!data.errors.certification3Status}
                                helperText={
                                    data.errors.certification3Status
                                        ? (data.errors.certification3Status as FieldError).message
                                        : undefined
                                } />
                        )}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default Certifications;
