import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Typography, Card, CardContent, Divider, IconButton } from "@material-ui/core";
import DisplayField from "../DisplayField/DisplayField";
import { useState } from "react";
import { useSelector } from "react-redux";
import { StudentProfile } from "../../../interfaces/student/profile/StudentProfile";
import { IGeneralInfo } from "../../../interfaces/student/quickInfo/GeneralInfo";
import * as GeneralInfoCardApi from "../../../api/student/quickInfo/GeneralInfoCardApi";
import * as studentApi from "../../../api/search/studentSearchApi";
import CustomSnackbar from "../../../components/notifications/CustomSnackbar";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import Grid from "@material-ui/core/Grid";
import AccessManager from "../../security/AccessManager";
import { security } from "../../../constants/Security/securityConstants";
import { checkPermissions } from "../../../utils/securityFunctions";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    card: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(2),
      "& .MuiCardContent-root": {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
      }
    },
    iconButton: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(-2),
    },
    cardTitle: {
      fontSize: 14,
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightMedium
    }
  })
);

const GeneralInformationCard = (props: any) => {
  const classes = useStyles({});
  const [generalInfoModel, setGeneralInfoModel] = useState<IGeneralInfo>();
  const selectedStudent: StudentProfile = useSelector(
    (state: any) => state.student
  ) as StudentProfile;

  type SnackBarStateProps = {
    showSnackBar: boolean;
    messageInfo: string | undefined;
    variant: "success" | "error" | "warning" | "info";
  };

  const [snackBarProps, setSnackBarProps] = React.useState<SnackBarStateProps>({
    showSnackBar: false,
    messageInfo: undefined,
    variant: "info",
  });

  const [showMaskedSSN, setShowMaskedSSN] = React.useState<boolean>(true);
  const [showMaskedDOB, setShowMaskedDOB] = React.useState<boolean>(true);
  const [viewDOBPermission, setViewDOBPermission] = React.useState(
    checkPermissions([security.permissions.student.viewDateOfBirth])
  );

  const handleViewMaskClick = (e: any) => {
    if (selectedStudent && selectedStudent.leadId && generalInfoModel && generalInfoModel.maskedSSN) {
      studentApi.GetSsnLast4(selectedStudent.leadId, generalInfoModel.maskedSSN).then((response: any) => {
        if (!response) {
          return;
        } else {
          if (
            response.resultStatusMessage === "Has permission and SSN found."
          ) {

            setShowMaskedSSN(false);
            let updatedModel = generalInfoModel;

            if ((response.result as string).length == 9) {
              let ssnWithoutDashes: string = response.result;
              let firstPart = ssnWithoutDashes.substr(0, 3);
              let secondPart = ssnWithoutDashes.substr(3, 2);
              let last4 = ssnWithoutDashes.substr(5, 4);
              updatedModel.ssn = firstPart + '-' + secondPart + '-' + last4;
            }

            updatedModel.maskedSSN = "***-**-****";
            setGeneralInfoModel({ ...updatedModel });
          } else if (
            response.resultStatusMessage === "Has permission and no SSN found."
          ) {
            setSnackBarProps((props: any) => {
              return {
                variant: "warning",
                showSnackBar: true,
                messageInfo: "No SSN found.",
              };
            });
          } else if (
            response.resultStatusMessage === "Does not have permission."
          ) {
            setSnackBarProps((props: any) => {
              return {
                variant: "warning",
                showSnackBar: true,
                messageInfo: "User does not have permission to view SSN.",
              };
            });
          }
        }
      });
    }
  };

  const handleViewPlainClick = (e: any) => {
    setShowMaskedSSN(true);
  }

  
  const handleViewPlainDOBClick = (e: any) => {
    if(viewDOBPermission)
    {
      studentApi.GetDateOfBirthByLead(selectedStudent.leadId ?? "")
        .then((response: any) => {
          if (!response) {
            return;
          } else {
            if(generalInfoModel)
            {
              let updatedModel = generalInfoModel;
              updatedModel.dateOfBirth = new Date(response.result.dateOfBirth); 
              updatedModel.age = response.result.age;
              setGeneralInfoModel({ ...updatedModel });
            }
            
            setShowMaskedDOB(false);
          }
        });
    }       
  }
  const handleViewMaskedDOBClick = (e: any) => {
    setShowMaskedDOB(true);
  }

  useEffect(() => {
    if (selectedStudent !== undefined) {
      if (
        selectedStudent.leadId !== undefined &&
        selectedStudent.leadId.length > 0
      ) {
        GeneralInfoCardApi.getGeneralInfoCard(selectedStudent.leadId, selectedStudent?.selectedEnrollment?.stuEnrollId ?? '').then(
          (response: any) => {
            if (response && response.data && response.data.result) {
              setGeneralInfoModel({ ...response.data.result });
            }
          },
          (exception: any) => { }
        );
      }
    }
  }, [selectedStudent]);

  return (
    <div>
      <Card className={classes.card} elevation={2}>
        <Typography className={clsx(classes.cardTitle)}><Link to={"/student/1/profile/info"}>GENERAL INFO</Link></Typography>
        <CardContent>
          <Grid container direction="row" spacing={1}>
            <Grid item md={5} sm={8} xs={10}>
              <DisplayField
                labelText="SSN"
                valueText={showMaskedSSN ? (generalInfoModel?.maskedSSN ? generalInfoModel.maskedSSN : '') : (generalInfoModel?.ssn ? generalInfoModel.ssn : '')}
                fieldType="text"
              >
              </DisplayField>
            </Grid>
            <Grid item md={1} sm={4} xs={2}>
              <AccessManager
                allowedPermissions={[security.permissions.student.viewSSN]}
                renderNoAccess={() => null}
              >
                <IconButton className={clsx(classes.iconButton)}>
                  {!showMaskedSSN ?
                    (<VisibilityOffOutlinedIcon
                      id="viewMask"
                      onClick={handleViewPlainClick}
                    />
                    ) : (
                      <VisibilityOutlinedIcon
                        id="viewPlain"
                        onClick={handleViewMaskClick}
                      ></VisibilityOutlinedIcon>
                    )}
                </IconButton>
              </AccessManager>
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={1}>
            <Grid item md={6} sm={6} xs={12}>
              <DisplayField
                labelText="DOB"
                valueText={showMaskedDOB ? "**_**_****" : (generalInfoModel && generalInfoModel.dateOfBirth ? new Date(generalInfoModel.dateOfBirth).toLocaleDateString() : '')}
                fieldType="text"
              ></DisplayField>
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
              <DisplayField
                labelText="Age"
                valueText={showMaskedDOB ? "**" : (generalInfoModel && generalInfoModel.age ? generalInfoModel.age : '')}
                fieldType="text"
              ></DisplayField>
            </Grid>
            <Grid item md={1} sm={4} xs={2}>
              <AccessManager
                allowedPermissions={[security.permissions.student.viewDateOfBirth]}
                renderNoAccess={() => null}
              >
                <IconButton className={clsx(classes.iconButton)}>
                  {!showMaskedDOB ?
                    (<VisibilityOffOutlinedIcon
                      id="viewMask"
                      onClick={handleViewMaskedDOBClick}
                    />
                    ) : (
                      <VisibilityOutlinedIcon
                        id="viewPlain"
                        onClick={handleViewPlainDOBClick}
                      ></VisibilityOutlinedIcon>
                    )}
                </IconButton>
              </AccessManager>
            </Grid>
          </Grid>

          <DisplayField
            labelText="Gender"
            valueText={generalInfoModel && generalInfoModel.gender ? generalInfoModel.gender : ''}
            fieldType="text"
          ></DisplayField>

          <DisplayField
            labelText="Student Groups"
            valueText={generalInfoModel && generalInfoModel.groups ? generalInfoModel.groups : ''}
            fieldType="text"
          ></DisplayField>
        </CardContent>
      </Card>
      <CustomSnackbar
        variant={snackBarProps.variant}
        message={snackBarProps.messageInfo}
        open={snackBarProps.showSnackBar}
        onClose={(event?: React.SyntheticEvent, reason?: string) => {
          setSnackBarProps((props: any) => {
            return { ...props, showSnackBar: false };
          });
        }}
      ></CustomSnackbar>
    </div>
  );
};

export default GeneralInformationCard;
