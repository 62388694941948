import React from "react";
import { useEffect, useRef } from "react";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../../constants/actions.constants";
import { FilterParams } from "../../../interfaces/common/FilterParams";
import { ApiAutoCompleteConfigItem } from "../../../interfaces/ApiAutoCompleteConfigItem";
import { WhenBothSpecifiedOptions } from "../../../constants/RefundConstants/refunds.constants";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {},
	})
);

const config: ApiAutoCompleteConfigItem = {
	acId: "whenBothSpecified",
	cacheTime: 5,
	route: null,
	requestType: "GET",
	body: null,
	hasUrlParams: false,
	useCache: false,
	storeInCache: false,
	isCampusSpecific: false,
	isUserSpecific: false,
	loadOnRender: true,
	defaultSelectedFirstItem: true,
	optionsMappedFx: (data: Array<any>) => {
		return data;
	},
};

export interface WhenBothSpecifiedProps extends FilterParams {
	className?: any;
}

const WhenBothSpecifiedAutoComplete = (props: WhenBothSpecifiedProps) => {
	const classes = useStyles({});
	const inputRef: any = useRef(null);

	const handleOnChange = (e: any) => {
		if (props.filterHandle) {
			props.filterHandle(e);
		}
	};

	return (
		<div>
			<ApiAutoComplete
				config={config}
				loading={false}
				reqParams={null}
				classes={{
					option: "text-black",
				}}
				className={props.className}
				label={props.label ? props.label : undefined}
				onChange={(e: any) => {
					handleOnChange(e);
				}}
				multiple={false}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
				inputRef={props.inputRef ? props.inputRef : inputRef}
				error={props.error}
				helperText={props.helperText}
				inputName={props.name}
				options={WhenBothSpecifiedOptions}
			></ApiAutoComplete>
		</div>
	);
};

export default WhenBothSpecifiedAutoComplete;
