import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,

    IconButton,
    ListItemSecondaryAction
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PaymentIcon from '@material-ui/icons/Payment';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import gradients from '../../../../../utils/gradients';
import { Notification } from "../../../../../interfaces/common/notifications/Notification";
import { useDispatch, useSelector } from 'react-redux';
import { userConstants } from "../../../../../constants/actions.constants";

const useStyles = makeStyles((theme: any) => ({
    root: {},
    listItem: {
        '&:hover': {
            backgroundColor: theme.palette.background.default
        }
    },
    avatarBlue: {
        backgroundImage: gradients.blue
    },
    avatarGreen: {
        backgroundImage: gradients.green
    },
    avatarOrange: {
        backgroundImage: gradients.orange
    },
    avatarIndigo: {
        backgroundImage: gradients.indigo
    },
    arrowForwardIcon: {
        color: theme.palette.icon
    }
}));


interface Notifications {
    [type: string]: any
}
const NotificationList = (props: any) => {
    const { notifications, className, persistentNotifications, ...rest } = props;
    const dispatch = useDispatch();
    const classes = useStyles();


    const avatars: Notifications = {
        lead: (
            <Avatar className={classes.avatarBlue}>
                <PeopleIcon />
            </Avatar>
        ),
        award: (
            <Avatar className={classes.avatarOrange}>
                <PaymentIcon />
            </Avatar>
        ),
        disbursement: (
            <Avatar className={classes.avatarGreen}>
                <PaymentIcon />
            </Avatar>
        ),
        document: (
            <Avatar className={classes.avatarIndigo}>
                <DescriptionIcon />
            </Avatar>
        )
    };

    const removeNotification = (notification: Notification) => {
        dispatch({ type: userConstants.SESSION_NOTIFICATION_REMOVED, notifications: [notification] })
    }
    return (
        <List
            {...rest}
            className={clsx(classes.root, className)}
            disablePadding
        >
            {notifications.map((notification: Notification, i: number) => (
                <ListItem
                    className={classes.listItem}
                    component={RouterLink}
                    divider={i < notifications.length - 1}
                    key={notification.id}
                    to={notification.href ?? "#"}
                    target="_blank"
                >
                    <ListItemAvatar>{avatars[notification.type as string] ?? avatars['document']}</ListItemAvatar>
                    <ListItemText
                        primary={notification.title}
                        primaryTypographyProps={{ variant: 'body1' }}
                        secondary={moment(notification.timestamp).fromNow()}
                    />
                    <ListItemSecondaryAction>
                        <IconButton onClick={() => { removeNotification(notification) }}>
                            <CloseIcon className={classes.arrowForwardIcon} />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}

            {persistentNotifications.map((notification: any, i: number) => (

                <ListItem
                    className={classes.listItem}
                    component={RouterLink}
                    divider={i < persistentNotifications.length - 1}
                    key={notification.id}
                    to="#"
                >
                    <ListItemAvatar>{avatars[notification.type as string]}</ListItemAvatar>
                    <ListItemText
                        primary={notification.title}
                        primaryTypographyProps={{ variant: 'body1' }}
                        secondary={moment(notification.timestamp).fromNow()}
                    />
                </ListItem>
            ))}
        </List>
    );
};

NotificationList.propTypes = {
    className: PropTypes.string,
    notifications: PropTypes.array.isRequired,
    persistentNotifications: PropTypes.array.isRequired
};

export default NotificationList;
