import React from "react";
import {
  makeStyles,
  createStyles,
  Grid,
  Paper,
  Table,
  withStyles,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  IconButton,
  CircularProgress,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { format, addDays } from "date-fns";
import ICreditCourseGradeBook from "../../../../interfaces/setup/academics/ProgramDefinition/ICourseGradeBook";
import InputField from "../../../../components/_Layout/Inputs/InputField";
import CustomCardTitle from "../../../../interfaces/common/card/CustomCardTitle";
import { getCourseGradeBooks } from "../../../../api/setup/academics/creditCoursesApi";
import * as classApi from "../../../../api/setup/creditHour/classApi";
import CreditGradeBookDetails from "./CreditGradeBookDetails";
import { EmptyGuid } from "../../../../utils/constants";
import { CustomSnackBarProps } from "../../../../interfaces/common/CustomSnackBarProps";
import ICreditCourseDetails from "../../../../interfaces/setup/academics/ProgramDefinition/ICreditCourseDetails";
import ICourseGradeBook from "../../../../interfaces/setup/academics/ProgramDefinition/ICourseGradeBook";
import IClassGradeBookParams from "../../../../interfaces/setup/creditHour/IClassGradeBookParams";
import { Tooltip } from "@material-ui/core";

const StyledTableCell = withStyles((theme: any) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.site.secondary,
      fontSize: 17,
      color: theme.palette.black,
      fontWeight: "bold",
      textAlign: "left",
      boxShadow: "1px 1px 0px 0px rgba(0,0,0,0.2)",
      "&:first-child": {
        width: "42px",
        padding: theme.spacing(0, 1),
      },
    },
    body: {
      fontSize: 16,
      color: theme.palette.black,
      fontWeight: 400,
      cursor: "pointer",
      textAlign: "left",
      "&:first-child": {
        width: "42px",
        padding: theme.spacing(0, 1),
      },
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: any) =>
  createStyles({
    root: {
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.site.secondary,
      },
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.white,
      },
      "&:hover": {
        backgroundColor: theme.palette.site.secondary,
      },
    },
    body: {
      cursor: "pointer",
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      fontWeight: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(0),
    },
    headerText: {
      float: "left",
      padding: theme.spacing(1.5),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%",
    },
    saveButton: {
      width: 90,
    },
    tableContainer: {
      maxHeight: "400px",
    },
    table: {
      width: "100%",
    },
    iconColor: {
      color: theme.palette.black,
    },
    searchField: {
      "&::placeholder": {
        color: theme.palette.black,
      },
    },
    selectionCheckBox: {
      padding: theme.spacing(0),
    },
    preLoaderCell: {
      textAlign: "center",
      padding: theme.spacing(1),
    },
    dialogCloseButton: {
      float: "right",
    },
    courseSelectionCell: {
      color: theme.palette.paperSummarySchedulerTitle,
      cursor: "pointer",
      fontWeight: 500,
    },
  })
);
type CreditCourseGradeBookProps = {
  courseId?: string;
  campusId?: string;
  repeatedExamPolicy?: string;
  course?: ICreditCourseDetails;
  userId?: string;
  isInstructorGradeBook: boolean;
  instrClassTermId?: string;
  showSnackBar: (param: CustomSnackBarProps) => void;
  setConfirmationDialog: (param: {
    onOk?: () => void | Promise<void>;
    message: string;
    open: boolean;
    onCancel?: () => void | Promise<void>;
  }) => void;
};

const CreditCourseGradeBook = (props: Readonly<CreditCourseGradeBookProps>) => {
  const classes = useStyles({});
  const [searchText, setSearchText] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [grdBooks, setGrdBooks] = React.useState<ICreditCourseGradeBook[]>([]);
  const [selectedGradeBook, selectGradeBook] =
    React.useState<ICreditCourseGradeBook>({ gradeBookId: EmptyGuid });
  const [anyGradePosted, setAnyGradePosted] = React.useState<boolean>(false);

  const toDisplay =
    searchText && searchText.length > 2
      ? grdBooks.filter(
          (grdBk) =>
            grdBk.gradeBookId === searchText ||
            (grdBk?.gradeBookTitle ?? "")
              .toLocaleLowerCase()
              .includes(searchText.toLocaleLowerCase())
        )
      : grdBooks;

  const loadCreditCourseGradeBook = async (resetSelected: boolean = false) => {
    if (!isLoading && props.courseId && props.courseId !== EmptyGuid) {
      setIsLoading(true);
      try {
        const gradeBooks = await classApi.GetGradeBookByCourseId(
          props.courseId ?? EmptyGuid,
          props.instrClassTermId ?? EmptyGuid          
        );

        const isAnyGradePostedFun = (
          gradeBooks: ICreditCourseGradeBook[]
        ): boolean =>
          gradeBooks.some((gradeBook) => gradeBook.isGradePosted === true) ||
          false;
        setAnyGradePosted(isAnyGradePostedFun(gradeBooks));

        if (props.isInstructorGradeBook == true) {
          setGrdBooks(filterCreditCourseGradeBookList(gradeBooks));
        } else {
          setGrdBooks(
            gradeBooks.filter(
              (g) =>
                props.isInstructorGradeBook == false && g.instructorId == null
            )
          );
        }

        if (resetSelected) {
          selectGradeBook({ gradeBookId: EmptyGuid });
        }
      } catch (err) {
        props.showSnackBar({
          variant: "error",
          showSnackBar: true,
          messageInfo: (err as Error).message,
        });
      }
      setIsLoading(false);
    }
  };

  const saveDetails = async (
    confirm?: boolean,
    updatedGrdBooks?: ICreditCourseGradeBook[]
  ) => {
    if (!isLoading) {
      setIsLoading(true);
      if (!confirm) {
        if (!confirm) {
          props.setConfirmationDialog({
            open: true,
            message: "Are you sure, do you want to save these changes? ",
            onOk: () => saveDetails(true, updatedGrdBooks),
            onCancel: () => setIsLoading(false),
          });
          return;
        }
      }
      try {
        setGrdBooks(updatedGrdBooks ?? []);
        let gradeBookId: string | null = null;
        const assignedGradeBook = updatedGrdBooks?.find(
          (book: ICreditCourseGradeBook) => book.assignedToClass === true
        );

        if (assignedGradeBook) {
          gradeBookId = assignedGradeBook.gradeBookId ?? null;
        }

        const gbParam: IClassGradeBookParams = {
          instrClassTermId: props.instrClassTermId,
          instrGrdBkWgtId: gradeBookId,
        };

        classApi.UpdateClassGradeBook(gbParam).then((response: any) => {
          if (response == true) {
            props.showSnackBar({
              variant: "success",
              showSnackBar: true,
              messageInfo: gradeBookId
                ? "Grade book assigned to the class."
                : "Grade book is removed from the class.",
            });
          }
        });
      } catch (err) {
        props.showSnackBar({
          variant: "error",
          showSnackBar: true,
          messageInfo: (err as Error).message,
        });
      }
      setIsLoading(false);
    }
  };

  //Take latest from course grade book + all instructor grade books
  function filterCreditCourseGradeBookList(list: ICreditCourseGradeBook[]) {
    const filteredList = list.filter((item) => item.instructorId !== null);
    const maxStartDateNullInstructor = list.reduce((maxItem, currentItem) => {
      if (currentItem.instructorId === null) {
        if (
          !maxItem ||
          (currentItem.effectiveStartDate ?? new Date()) >
            (maxItem.effectiveStartDate ?? new Date())
        ) {
          return currentItem;
        }
      }
      return maxItem;
    }, null as ICreditCourseGradeBook | null);

    if (maxStartDateNullInstructor) {
      filteredList.push(maxStartDateNullInstructor);
    }
    return filteredList;
  }

  const handleApplyGradeBook = (
    fieldId: keyof ICourseGradeBook,
    value: any,
    index: number = 0
  ) => {
    const updatedGrdBooks = grdBooks.map((item, i) => {
      if (i === index) {
        return { ...item, assignedToClass: value };
      } else {
        return { ...item, assignedToClass: false };
      }
    });

    saveDetails(false, updatedGrdBooks);
  };

  React.useEffect(() => {
    loadCreditCourseGradeBook(true);
  }, [props.instrClassTermId, props.courseId]);

  return (
    <Card square={true} elevation={2}>
      <CardContent>
        <Grid container direction="row" spacing={2} wrap="nowrap">
          <Grid item xs={12} sm={12} md={12}>
            <Grid container direction="column" spacing={2} wrap="nowrap">
              <Grid
                item
                alignContent="flex-start"
                alignItems="flex-start"
                justify="flex-start"
              >
                <h3>{props.course?.name ?? ""}</h3>
              </Grid>
              <Grid
                item
                alignContent="flex-start"
                alignItems="flex-start"
                justify="flex-start"
              >
                <TableContainer
                  component={Paper}
                  square
                  className={classes.tableContainer}
                >
                  <Table
                    stickyHeader
                    size="small"
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell colSpan={2}>
                          Grade Book
                        </StyledTableCell>
                        <StyledTableCell style={{ width: 190 }}>
                          Effective Start Date
                        </StyledTableCell>
                        <StyledTableCell style={{ width: 190 }}>
                          Effective End Date
                        </StyledTableCell>
                        {props.isInstructorGradeBook == true ? (
                          <StyledTableCell style={{ width: 190 }}>
                            Apply to the class?
                          </StyledTableCell>
                        ) : (
                          ""
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoading ? (
                        <StyledTableRow>
                          <StyledTableCell
                            colSpan={4}
                            className={classes.preLoaderCell}
                          >
                            <CircularProgress />
                          </StyledTableCell>
                        </StyledTableRow>
                      ) : (
                        grdBooks
                          // .filter(g => (props.isInstructorGradeBook == false && g.instructorId == null) || (props.isInstructorGradeBook == true))
                          //.sort((a, b) => (a.instructorId ?? "") < (b.instructorId ?? "") ? 1 : -1)
                          .map((grdBk, index) => (
                            <StyledTableRow key={`row-${index}`}>
                              <StyledTableCell
                                colSpan={2}
                                key={`gradeBookName-${index}`}
                                className={classes.courseSelectionCell}
                                onClick={() => selectGradeBook(grdBk)}
                              >
                                {grdBk.gradeBookTitle}{" "}
                                {grdBk.instructorId != null
                                  ? " (Instructor)"
                                  : " (Course)"}
                              </StyledTableCell>
                              <StyledTableCell
                                key={`gradeBookEffectiveStartDate-${index}`}
                              >
                                {grdBk.effectiveStartDate &&
                                  format(
                                    Date.parse(
                                      grdBk.effectiveStartDate.toString()
                                    ),
                                    "MM/dd/yyyy"
                                  )}
                              </StyledTableCell>
                              <StyledTableCell
                                key={`gradeBookEffectiveEndDate-${index}`}
                              >
                                {index > 0 &&
                                  grdBooks &&
                                  grdBooks[index - 1] &&
                                  grdBooks[index - 1].effectiveStartDate &&
                                  format(
                                    addDays(
                                      Date.parse(
                                        grdBooks[
                                          index - 1
                                        ]?.effectiveStartDate?.toString() ??
                                          new Date().toString()
                                      ),
                                      -1
                                    ),
                                    "MM/dd/yyyy"
                                  )}
                              </StyledTableCell>
                              {props.isInstructorGradeBook == true ? (
                                <StyledTableCell>
                                  <Tooltip
                                    title={
                                      anyGradePosted == true
                                        ? "Can not change grade book for this class, as grades were posted"
                                        : ""
                                    }
                                    placement="top-end"
                                  >
                                    <span>
                                      <Switch
                                        key={`isInGpa-${index + 1}`}
                                        checked={grdBk.assignedToClass ?? false}
                                        name={`isInGpa-${index + 1}`}
                                        id={`isInGpa-${index + 1}`}
                                        disabled={anyGradePosted == true}
                                        color="primary"
                                        onChange={(
                                          _event: React.ChangeEvent<HTMLInputElement>,
                                          checked: boolean
                                        ) =>
                                          handleApplyGradeBook(
                                            "assignedToClass",
                                            checked,
                                            index
                                          )
                                        }
                                      />
                                    </span>
                                  </Tooltip>
                                </StyledTableCell>
                              ) : (
                                ""
                              )}
                            </StyledTableRow>
                          ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid
                item
                alignContent="flex-start"
                alignItems="flex-start"
                justify="flex-start"
              >
                <Button
                  onClick={() => selectGradeBook({ gradeBookId: EmptyGuid })}
                  size="small"
                  color="primary"
                  variant="contained"
                  type="button"
                >
                  ADD NEW
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} wrap="nowrap">
          <Grid item xs={12} sm={12} md={12}>
            <CreditGradeBookDetails
              setConfirmationDialog={props.setConfirmationDialog}
              refreshList={loadCreditCourseGradeBook}
              showSnackBar={props.showSnackBar}
              model={selectedGradeBook}
              grdBooks={grdBooks}
              userId={props.userId}
              isInstructorGradeBook={props.isInstructorGradeBook}
              campusId={props.campusId}
              courseId={props.courseId}
              repeatedExamPolicy={props.repeatedExamPolicy}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CreditCourseGradeBook;
