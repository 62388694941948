import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import ExamsTable from "./ExamsTable";
import { StudentGrade } from "../../../interfaces/student/academics/StudentGrade";
import { useSelector } from "react-redux";
import { CustomSnackBarProps } from "../../../interfaces/common/CustomSnackBarProps";
import * as StudentGradesApi from "../../../api/student/academics/StudentGradesApi";
import { useForm, FormContext } from "react-hook-form";
import CustomSnackbar from "../../../components/notifications/CustomSnackbar";
import ProgressSaveButton from "../../../components/_Layout/Buttons/ProgressSaveButton";
import AccessManager from "../../../components/security/AccessManager";
import { security } from "../../../constants/Security/securityConstants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "95%",
      minHeight: "100%",
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      overflowX: "hidden",
      overflowY: "auto",
    },
    componentActions: {
      marginTop: theme.spacing(3),
    },
    gradesWrapper: {
      height: "100%",
      overflowY: "auto",
      padding: "3px",
    },
    form: {
      height: "100%",
    },
  })
);
type IGradesProps = {
  setConfirmationModal: any;
};
const ClockExam = (props: IGradesProps) => {
  const classes = useStyles({});
  const [gradesData, setGradesData] = React.useState<StudentGrade[]>([]);
  const [loaderState, setLoaderState] = React.useState<boolean>(false);
  const selectedEnrollment = useSelector(
    (state: any) => state.student.selectedEnrollment
  );
  const methods = useForm<any>({
    mode: "onBlur",
  });

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );

  const transferGradesForGrid = (gradeDate: any) => {
    return gradeDate.map((grade: any) => {
      if (grade.score == "-1") {
        grade.score = "T";
      }
      return grade;
    });
  };
  const transferGradesForSave = (gradeDate: any) => {
    return gradeDate.map((grade: any) => {
      if (grade.score == "T") {
        grade.score = "-1";
      }
      return grade;
    });
  };

  const { handleSubmit } = methods;
  const filterData = (data: StudentGrade[]): StudentGrade[] => {
    return data.filter(function (service: StudentGrade) {
      return service && service.isDirty;
    });
  };
  const onSave = () => {
    setLoaderState(true);

    if (selectedEnrollment && selectedEnrollment.stuEnrollId) {
      let enrollmentId = selectedEnrollment.stuEnrollId;
      if (gradesData) {
        let formValues = methods.getValues();
        let toSend = filterData(gradesData as StudentGrade[]) as StudentGrade[];
        toSend.forEach(function (grade) {
          grade.score = formValues["score_" + grade.gradeBookWeightDetailId];
          grade.attempts =
            formValues["attempts_" + grade.gradeBookWeightDetailId];
        });

        //const toSave  = [... toSend];
        let toSave = transferGradesForSave(JSON.parse(JSON.stringify(toSend)));

        StudentGradesApi.CreateGrades(enrollmentId, toSave).then(
          (response: any) => {
            if (response) {
              let data = response.data;
              if (data) {
                let allStudentGradesData: any = {
                  ...data,
                  dateCompleted:
                    data.dateCompleted === "" ? null : data.dateCompleted,
                };
                setGradesData([] as StudentGrade[]);
                setGradesData(allStudentGradesData.result);
                setGradesData(
                  transferGradesForGrid(allStudentGradesData.result)
                );
                setSnackBarProps(() => {
                  return {
                    variant: "success",
                    showSnackBar: true,
                    messageInfo: response.data.resultStatusMessage,
                  };
                });
              }
            }
            setLoaderState(false);
          },
          () => {
            setLoaderState(false);
          }
        );
      }
    }
  };
  return (
    <Paper className={classes.root}>
      <CustomSnackbar
        variant={snackBarProps.variant}
        message={snackBarProps.messageInfo}
        open={snackBarProps.showSnackBar}
        onClose={() => {
          setSnackBarProps((props: any) => {
            return { ...props, showSnackBar: false };
          });
        }}
      ></CustomSnackbar>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSave)} className={classes.form}>
          <div className={classes.gradesWrapper}>
            <ExamsTable
              setGradesData={setGradesData}
              gradesData={gradesData}
              setSnackBar={setSnackBarProps}
              setConfirmationModal={props.setConfirmationModal}
              transferGradesForGrid={transferGradesForGrid}
            />
            <div className={classes.componentActions}>
              <AccessManager
                allowedPermissions={[
                  security.permissions.student.updateExamResults,
                  security.permissions.student.deleteExamResults,
                ]}
                renderNoAccess={() => (
                  <ProgressSaveButton
                    disabled={true}
                    text="Save"
                    onClick={() => {
                      handleSubmit(onSave);
                    }}
                    loading={loaderState}
                  ></ProgressSaveButton>
                )}
              >
                <ProgressSaveButton
                  text="Save"
                  onClick={() => {
                    handleSubmit(onSave);
                  }}
                  loading={loaderState}
                ></ProgressSaveButton>
              </AccessManager>
            </div>
          </div>
        </form>
      </FormContext>
    </Paper>
  );
};
export default ClockExam;
