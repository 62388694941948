import { Fragment } from "react";
import {
	makeStyles,
	createStyles,
	Grid,
	Button
} from "@material-ui/core";

import { ThemeTextField } from "../../../../../../components/_Layout/Inputs/ThemeTextField";
import { IncrementDetails } from "../../../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/CharginMethod/IIncrementDetails";
import {
	IChargingMethodIncrement
} from "../../../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/CharginMethod/IChargingMethodIncrement";

import IncrementDetailsGrid from "./IncrementDetailsGrid";
import React from "react";
import { CreateNewGuid } from "../../../../../../utils/constants";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		cardTitle: {
			fontSize: 17,
			color: theme.palette.black,
			textDecoration: "bold",
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1),
			marginBottom: theme.spacing(1),
			marginTop: theme.spacing(2),
		},
		headerText: {
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(0),
		},
		cardContent: {
			padding: theme.spacing(1, 3),
		},
		cardWrapper: {
			height: "100%",
			width: "100%",
		},
		fullWidthField: {
			width: "100%",
		},
		switch: {
			marginLeft: "0px",
		},
		incrementRow: {
			marginBottom: theme.spacing(1),
		},
		label: {
			paddingTop: theme.spacing(2),
		},
		datePickerField: {
			width: "100%",
			background: "white",
			borderRadius: theme.spacing(0.5),
			"& .MuiInputBase-root": {
				"& input": {
					width: "100%",
				},
			},
		},
	})
);

type IncrementDetailsCardProps = {
	model: IChargingMethodIncrement;
	setModel: (model: IChargingMethodIncrement) => void;
	isEditable: boolean;

	usesActualHours: boolean;
};

const IncrementDetailsCard = (props: IncrementDetailsCardProps) => {
	const setDetails = (newValue: any) => {
		handleFieldChange("details", newValue);
	};
	const classes = useStyles({});
	const { model, setModel } = props;

	const handleAddNewperiod = () => {
		let modelToUpdate = { ...model };

		let newRow = new IncrementDetails();
		newRow.id = CreateNewGuid();
		newRow.usesActualHours = props.usesActualHours;
		let arrayUpdated = modelToUpdate.details;
		arrayUpdated.push({ ...newRow });

		modelToUpdate.details = [...arrayUpdated];
		setModel(modelToUpdate);
	};

	const handleFieldChange = (fieldId: string, value: any) => {
		let modelToUpdate = model;
		(modelToUpdate as any)[fieldId] = value;
		setModel({ ...modelToUpdate });
	};
	return (
		<Fragment>
			<Grid container direction="row" spacing={2}>
				<Grid item md={4} sm={6} xs={12}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							disableToolbar
							variant="inline"
							clearable
							format={"MM/dd/yyyy"}
							placeholder="mm/dd/yyyy"
							id="description"
							name="description"
							label="Payment Period Effective Date"
							TextFieldComponent={ThemeTextField}
							className={classes.datePickerField}
							value={
								model && model.effectiveDate
									? new Date(model.effectiveDate).toLocaleDateString()
									: ""
							}
							onChange={(value: any) => {
								handleFieldChange("effectiveDate", value ? value : undefined);
							}}
							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
							autoOk={true}
							disabled={!props.isEditable}
							// required
							// error={!!data.errors["effectiveStartDate"]}
							// innerRef={(ref: any) => {
							//   data.register("effectiveStartDate", {
							//     required: true,
							//     validate: {
							//       beforeEndDate: (value) =>
							//         new Date(value) <
							//           new Date(data.getValues()["effectiveEndDate"]) ||
							//         "Start Date must be before end date",
							//     },
							//   });
							//   data.setValue("effectiveStartDate", model.effectiveStartDate);
							// }}
							// helperText={
							//   data.errors["effectiveStartDate"]
							//     ? (data.errors["effectiveStartDate"] as FieldError).message
							//     : undefined
							// }
						/>
					</MuiPickersUtilsProvider>
				</Grid>
				<Grid item md={4} sm={6} xs={12}>
					<ThemeTextField
						id="description"
						label="Description"
						name="description"
						className={classes.fullWidthField}
						onChange={(e: any) => {
							handleFieldChange("description", e ? e.target.value : undefined);
						}}
						value={model ? model.description : ""}
						disabled={!props.isEditable}
					/>
				</Grid>
				<Grid item md={4} sm={6} xs={12}>
					<ThemeTextField
						id="percentageOfExcusedAbsencesToIncludeInCalculation"
						label="Percentage of Excused Absences to Include in Calculation"
						name="percentageOfExcusedAbsencesToIncludeInCalculation"
						className={classes.fullWidthField}
						value={
							model ? model.percentageOfExcuseAbsencesToIncludeInCalculation : 0
						}
						disabled={!props.isEditable}
						onChange={(e: any) => {
							handleFieldChange(
								"percentageOfExcuseAbsencesToIncludeInCalculation",
								e ? e.target.value : undefined
							);
						}}
					/>
				</Grid>

				{props.isEditable && (
					<Grid item container direction="row" spacing={4} xs={12}>
						<Grid item xs={4}>
							<Button
								color="secondary"
								variant="contained"
								type="button"
								onClick={handleAddNewperiod}
							>
								Add New Period
							</Button>
						</Grid>
					</Grid>
				)}
				<IncrementDetailsGrid
					details={model ? model.details : []}
					isEditable={props.isEditable}
					setModel={setDetails}
					usesActualHours={props.usesActualHours}
				/>
			</Grid>
		</Fragment>
	);
};

export default IncrementDetailsCard;
