import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Button, Paper, Typography, Backdrop } from "@material-ui/core";
import LedgerGrid from "./LedgerGrid";
import PostTransaction from "./PostTransaction";
import * as ledgerApi from "../../../../api/student/studentAccounts/ledgerApi";
import { useSelector } from "react-redux";
import { LedgerItem } from "../../../../interfaces/student/studentAccounts/LedgerItem";
import CenterLoader from "../../../../components/_Layout/Loaders/CenterLoader";
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";
import { CustomSnackBarProps } from "../../../../interfaces/common/CustomSnackBarProps";
import AccessManager from "../../../../components/security/AccessManager";
import { security } from "../../../../constants/Security/securityConstants";
import { Enrollment } from "../../../../interfaces/student/academics/Enrollment";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			width: "100%",
			height: "95%",
			minHeight: "100%",
			paddingTop: theme.spacing(2),
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			paddingBottom: theme.spacing(2),
			overflowX: "hidden",
			overflowY: "hidden",
		},
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			// color: '#fff',
		},
		button: {
			marginTop: -theme.spacing(8),
			marginBottom: theme.spacing(3),
			marginRight: theme.spacing(3),
			whiteSpace: "nowrap",
		},
		summaryFooter: {
			textAlign: "right",
			margin: "25px",
		},
		footerDiv: {
			height: "75px",
		},
		pageStyle: {
			height: "100%",
		},
		body: {
			height: "calc(100% - 75px)",
		},
	})
);
const formatMoney = (number: number) => {
	var absValue = Math.abs(number);
	let numberString = absValue.toLocaleString("en-US", {
		style: "currency",
		currency: "USD",
	});
	return number < 0 ? "(" + numberString + ")" : numberString;
};
// type SnackBarStateProps = {
//   showSnackBar: boolean;
//   messageInfo: string | undefined;
//   variant: "success" | "error" | "warning" | "info";
// };

const Ledger = (props: any) => {
	const classes = useStyles({});

	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [showVoid, setShowVoid] = React.useState(false);
	const handleClickPrint = () => {
		setLoading(true);
		let enrollmentId = selectedEnrollment.stuEnrollId;
		ledgerApi.printLedger(enrollmentId, showVoid).then(
			(response: any) => {
				setLoading(false);
			},
			(exception: any) => {}
		);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};
	const selectedEnrollment = useSelector(
		(state: any) => state.student.selectedEnrollment
	);
	const [ledgerItems, setLedgerItems] = React.useState<LedgerItem[]>([]);
	const [totalBalance, setTotalBalance] = React.useState<number>(0);
	const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
		{
			showSnackBar: false,
			messageInfo: undefined,
			variant: "info",
		}
	);
	const refreshLedgerItems = React.useCallback(() => {
		if (selectedEnrollment && selectedEnrollment.stuEnrollId) {
			setLoading(true);
			let enrollmentId = selectedEnrollment.stuEnrollId;
			ledgerApi.getStudentLedger(enrollmentId).then(
				(response: any) => {
					setLoading(false);
					if (response) {
						let data = response.data;
						let ledgerData: LedgerItem[] = data.map((li: any) => {
							return { ...li } as LedgerItem;
						});
						setLedgerItems(ledgerData);
					}
				},
				(exception: any) => {}
			);
		}
	}, [selectedEnrollment]);
	React.useEffect(() => {
		refreshLedgerItems();
	}, [selectedEnrollment, refreshLedgerItems]);

	return (
		<Paper className={classes.root}>
			<CustomSnackbar
				variant={snackBarProps.variant}
				message={snackBarProps.messageInfo}
				open={snackBarProps.showSnackBar}
				onClose={(event?: React.SyntheticEvent, reason?: string) => {
					setSnackBarProps((props: any) => {
						return { ...props, showSnackBar: false };
					});
				}}
			></CustomSnackbar>
			{loading ? (
				<CenterLoader />
			) : (
				<div className={classes.pageStyle}>
					<div className={classes.body}>
						<LedgerGrid
							ledgerItems={ledgerItems}
							setTotalBalance={setTotalBalance}
							refreshLedgerItems={refreshLedgerItems}
							setShowVoid={setShowVoid}
							showVoid={showVoid}
							setSnackBar={setSnackBarProps}
						/>
					</div>
					<div className={classes.footerDiv}>
						<div className={classes.summaryFooter}>
							<Typography variant="h4">
								Balance:{" "}
								{totalBalance !== 0 ? formatMoney(totalBalance) : "$0.00"}
							</Typography>
						</div>
						<AccessManager
							allowedPermissions={[
								security.permissions.student.postTransactions,
							]}
							renderNoAccess={() => (
								<Button
									disabled={true}
									variant="contained"
									color="primary"
									className={classes.button}
									onClick={handleClickOpen}
								>
									Post
								</Button>
							)}
						>
							<Button
								variant="contained"
								color="primary"
								className={classes.button}
								onClick={handleClickOpen}
							>
								Post
							</Button>
						</AccessManager>

						<Button
							variant="contained"
							color="secondary"
							className={classes.button}
							onClick={handleClickPrint}
						>
							Print
						</Button>
					</div>

					<Backdrop
						className={classes.backdrop}
						open={open}
						onMouseDown={(e: any) => {
							if (
								e.target.className ===
								"MuiDialog-container MuiDialog-scrollPaper"
							) {
								setOpen(false);
							}
						}}
					>
						<PostTransaction
							open={open}
							setOpen={setOpen}
							refreshLedgerGrid={refreshLedgerItems}
							setSnackBar={setSnackBarProps}
							enrollmentId={(selectedEnrollment as Enrollment).stuEnrollId}
							campusId={(selectedEnrollment as Enrollment).campusId}
						/>
					</Backdrop>
				</div>
			)}
		</Paper>
	);
};

export default Ledger;
