import * as React from "react";
import { useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";

export const ClassApiAutoComplete = {
  config: {
    acId: "classTypes",
    cacheTime: 5,
    route: "/AcademicRecords/Class/GetClasses",
    requestType: "GET",
    body: null,
    hasUrlParams: true,
    useCache: false,
    storeInCache: false,
    isCampusSpecific: false,
    isUserSpecific: false,
    loadOnRender: true,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
      return data;
    },
  },
};

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& label.Mui-focused": {
        color: theme.palette.primary.white,
      },
    },
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {
      width: "100%",
    },
  })
);

export interface ClassProps extends FilterParams {
  termId?: string;
  programId?: string;
  daysFilter?: number|undefined;
}

const ClassAutoComplete = (props: ClassProps) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const [ClassTypesSelected, setClassTypesSelected] =
    React.useState(false);
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const inputRef: any = useRef(null);

  const filterHandle = props.filterHandle;

  const classTypesOnChange = (e: any) => {
    if (filterHandle) filterHandle(e);
    setClassTypesSelected(e);
  };

  return (
    <div className={clsx(classes.divWidth)}>
      <ApiAutoComplete
        config={ClassApiAutoComplete.config}
        loading={userSelectedCampus ? false : true}
        reqParams={{ campusId: userSelectedCampus, termId: props.termId, programId: props.programId, daysFilter: props.daysFilter}}
        classes={{
          option: "text-black",
        }}
        label={props.label ? props.label : "Class"}
        onChange={(e: any) => {
          classTypesOnChange(e);
        }}
        multiple={false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        inputRef={props.inputRef ? props.inputRef : inputRef}
        error={props.error}
        helperText={props.helperText}
        inputName={props.name}
      ></ApiAutoComplete>
    </div>
  );
};
export default ClassAutoComplete;
