export interface Enrollment {
	name: string;
	stuEnrollId: string;
	studentId: string;
	status: string;
	programVersionId: string;
	programVersionDescription: string;
	isCreditHour: boolean;
	percentCompleted: number;
	sapStatus: TitleIVSapStatus | null;
	campusId: string;
	startDate?: string;
	currentStatusStart?: Date | null;
	currentStatusEnd?: Date | null;
	lastDateOfAttendance?: string;
	studentNumber?: string;
	sysStatusId?: number;
}

export enum TitleIVSapStatus {
	Passed = 1,
	Warning = 2,
	Ineligible = 3,
	Probation = 4,
}
