import API from "../../api/apiWrapper";

export const GetLeaveOfAbsenceListByCampusId = (params: any) => {
  return API()
    .get("/AcademicRecords/StatusChanges/LeaveOfAbsence/GetLeaveOfAbsenceListByCampusId", {
      params: params,
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const UpdateLOAStatus = (
  enrollmentList: any
) => {
  return API()
    .post(
      "/AcademicRecords/StatusChanges/LeaveOfAbsence/UpdateLeaveOfAbsenceStatus",
      enrollmentList
    )
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

