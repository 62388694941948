import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, TextField, withStyles } from "@material-ui/core";
import React from "react";
import { FormContext, useForm } from "react-hook-form";
import * as documentsApi from "../../../api/student/documents/documentsApi";
import DocumentTypeAutoComplete from "../../../components/AutoComplete/DocumentTypeAutoComplete";
import ModuleAutoComplete from "../../../components/AutoComplete/ModuleAutoComplete";
import ProgressSaveButton from "../../../components/_Layout/Buttons/ProgressSaveButton";
import { FileConfigurationFeature } from "../../../enums/FileConfigurationFeature";
import { IUploadDocument } from "../../../interfaces/student/documents/IUploadDocument";
import { EmptyGuid } from "../../../utils/constants";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .MuiPaper-rounded": {
        borderRadius: 0,
      },
      "& .MuiDialogContent-root": {
        minHeight: 250,
      },
    },
    dialogHeader: {
      backgroundColor: theme.palette.site.secondary,
      "& .MuiTypography-root": {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: "12pt",
      },
    },
    buttonSection: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    saveButton: {
      width: 90,
    },
    fullWidthField: {
      width: "100%",
    },
  })
);
const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& .MuiFormLabel-root": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "80%"
      },
    },
  })
)(TextField);

type DocumentUploadProps = {
  refreshDocumentGrid: any;
  setOpen: any;
  open: boolean;
  setSnackBar: any;
  file: any;
  campusId: string;
  studentId: string;
};

const DocumentUpload = (props: DocumentUploadProps) => {
  const classes = useStyles({});
  const data = useForm<any>({ mode: "onBlur" });
  
  const[model, setModel] = React.useState<IUploadDocument>({
      campusId : props.campusId,
    featureType: FileConfigurationFeature.DOC,
     fileToUpload : props.file ,
    studentId : props.studentId,
    documentTypeId: 0,
    documentTypeName: undefined,
    moduleId:0

  })

  const handleFieldOnChange = (fieldId: string, value: any) => {
		let updatedModel = model;
		(updatedModel as any)[fieldId] = value;
		setModel({ ...updatedModel });
	};
 
  const { handleSubmit } = data;
	const [loaderState, setLoaderState] = React.useState<boolean>(false);
  const onSubmit = (d: any) => {
		if (model) {
      let modelToSend = {...model};
      modelToSend.campusId=props.campusId;
      modelToSend.featureType= FileConfigurationFeature.DOC;
      modelToSend.fileToUpload = props.file ;
      modelToSend.studentId = props.studentId;
      modelToSend.moduleId = model.moduleId ;
      modelToSend.documentTypeName = model.documentTypeName;
      modelToSend.documentTypeId = model.documentTypeId.toString() === EmptyGuid ? 0 : model.documentTypeId;
      data.triggerValidation().then((validation: any)=> {
        if ( validation){
          setLoaderState(true);      
          documentsApi.uploadDocument(modelToSend).then(        
            (response: any) => {
              if (response.resultStatus === 0) {
                setLoaderState(false);
                props.setSnackBar((props: any) => {
                  return {
                    variant: "success",
                    showSnackBar: true,
                    messageInfo: response.resultStatusMessage
                  };
                });
                handleClose();
                props.refreshDocumentGrid();
              } else {
                setLoaderState(false);
                props.setSnackBar((props: any) => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: "An unhandled exception has occurred, please try again, if the error persist, contact the support team."
                  };
                });
              }
            },
            (exception: any) => {
              setLoaderState(false);
              props.setSnackBar((snackBarProps: any) => {
                return {
                  variant: "error",
                  showSnackBar: true,
                  messageInfo: exception
                };
              });
            }
          );
        }
        else {
          setLoaderState(false);
        }
      
    })
		}
	};
  const handleClose = () => {
    props.setOpen(false);
    setLoaderState(false);
  };

  return (
    <FormContext {...data}>
			<form onSubmit={handleSubmit(onSubmit)}>
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
        fullWidth={true}
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
          {"Document Upload"}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <ModuleAutoComplete 
                id="moduleId"
								label="Module *"
                name="moduleId"
                useOnlyDocumentsModule={true}
                filterHandle={(v: any) => {								
									handleFieldOnChange("moduleId", v ? v.value : null);
                }}
                
                valueFilter={
									model.moduleId
										? {
												key: "value",
												values: [model.moduleId]
										  }
										: undefined
								}
								error={!!data.errors.moduleId}
								inputRef={data.register({ required: true })}
								helperText={
									data.errors.awardId ? "Module is required." : undefined
								}
                />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
            <DocumentTypeAutoComplete 
             id="documentTypeId"
             label="Document Type *"
             name="documentTypeId"
             filterHandle={(v: any) => {			
               handleFieldOnChange("documentTypeName", v ? v.text : "");
               handleFieldOnChange("documentTypeId", v  ? v.value : 0);
               console.log(model);
             }}
             
             valueFilter={
               model.documentTypeId
                 ? {
                     key: "value",
                     values: [model.documentTypeId]
                   }
                 : undefined
             }
             error={!!data.errors.documentTypeId}
             inputRef={data.register({ required: true })}
             helperText={
               data.errors.documentTypeId ? "Document Type is required." : undefined
             }
            />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <ThemeTextField
                id="documentName"
                label="Document Name"
                name="documentName"
                disabled = {true}
                className={classes.fullWidthField}
                value={props.file ? props.file.name : ""}
                onChange={(e) => {}}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.buttonSection}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={2}></Grid>
            <Grid
              item
              container
              xs={4}
              alignContent="center"
              alignItems="center"
              justify="center"
            >
              <ProgressSaveButton
                text="Upload"
                onClick={(e: any) => {
                  onSubmit(e)
                }}
                loading={loaderState}
                disabled={false}
                buttonClassName={classes.saveButton}
              ></ProgressSaveButton>
            </Grid>
            <Grid
              item
              container
              xs={4}
              alignContent="center"
              alignItems="center"
              justify="center"
            >
              <Button
                onClick={handleClose}
                color="secondary"
                variant="contained"
                type="button"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
    </form>
    </FormContext>
  );
};

export default DocumentUpload;
