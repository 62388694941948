import API from "../../apiWrapper";
import IGradeSystemDescriptionDetail from "../../../interfaces/setup/grades/ComponentTypes/IGradeSystemDescriptionDetail";
import IGradeScaleDescriptionDetail from "../../../interfaces/setup/grades/ComponentTypes/IGradeScaleDescriptionDetail";
import IComponentType from "../../../interfaces/setup/grades/ComponentTypes/IComponentType";
import IComponentTypeDetail from "../../../interfaces/setup/grades/ComponentTypes/IComponentTypeDetail";
import { IComponentTypeSearch } from "../../../interfaces/setup/grades/ComponentTypes/IComponentTypeSearch";

export const getComponentsList = async (params: IComponentTypeSearch): Promise<{ data: Array<IComponentType>; hasMoreData: boolean; total: number; }> => {
	const response = await API().post('/AcademicRecords/GradeBookComponentTypes/GetComponentTypesByCampus', {
		search: params.search ?? '',
		take: params.take ?? 10,
		skip: params.skip ?? 0,
    campusId: params.campusId ?? "",
		showAll: params.showAll ?? false,
		sort: params.sort ?? 'desc'
	});
	return response.data;
};


export const getDetails = async (componentTypeId: string, campusId: string): Promise<IComponentTypeDetail> => {
	const response = await API().get<IComponentTypeDetail>('AcademicRecords/GradeBookComponentTypes/GetComponentTypeById', {
		params: { componentTypeId,campusId }
	});
	return response.data;
};

export const saveComponent = async (data: IComponentTypeDetail): Promise<IComponentTypeDetail> => {
  // if(data.campusId==null || data.campusId==EmptyGuid)
  // {
  //   data.campusId = userSelectedCampus;
  // }
	const response = await API().post<IComponentTypeDetail>('/AcademicRecords/GradeBookComponentTypes/UpdateComponent', data);
	return response.data;
};


export const deleteComponent = async (componentTypeId: string, campusId: string): Promise<string> => {
	const response  = await API().delete<string>('/AcademicRecords/GradeBookComponentTypes/DeleteComponentType', {
		params: { componentTypeId ,campusId}
	});
	return response.data;
};


export const getComponentTypesByCampus = (CampusId: string) => {
  return API()
      .get("/AcademicRecords/GradeBookComponentTypes/GetComponentTypesByCampus", {
      params: {
        campusId: CampusId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};


export const getGradeScaleDetail = (gradeScaleId: string | null) => {
  return API()
    .get("/SystemCatalog/GradeScale/GetGradeScalesDetail", {
      params: {
        gradeScaleId: gradeScaleId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};
//GetGradeSystemByCampGrp
export const getGradeScales = (CampusId: string) => {
  return API()
    .get("/SystemCatalog/GradeScale/GetGradeScalesCampGrp", {
      params: {
        campusId: CampusId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const getGradeSystem = (CampusId: string) => {
  return API()
    .get("/SystemCatalog/GradeSystem/GetGradeSystemByCampGrp", {
      params: {
        campusId: CampusId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetComponentTypeById = (componentTypeId: string,CampusId: string) => {
  return API()
    .get("/AcademicRecords/GradeBookComponentTypes/GetComponentTypeById", {
      params: {
        componentTypeId: componentTypeId,
        campusId: CampusId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};


// export const GetGradeSystemById = (gradeSystemId: string) => {
//   return API()
//     .get("/SystemCatalog/GradeSystem/GetGradeSystemById", {
//       params: {
//         gradeSystemId: gradeSystemId,
//       },
//     })
//     .then(
//       (res: any) => {
//         if (res && res.data) {
//           return res;
//         }
//       },
//       (error: any) => {
//         return error;
//       }
//     );
// };

export const GetGradeSystemById = (gradeSystemId: string) : Promise<IGradeSystemDescriptionDetail> => {
  return API()
    .get("/SystemCatalog/GradeSystem/GetGradeSystemById", {
      params: {
        gradeSystemId: gradeSystemId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetGradeScaleById = (gradeScaleId?: string) : Promise<IGradeScaleDescriptionDetail>  => {
  return API()
    .get("/SystemCatalog/GradeScale/GetGradeScaleById", {
      params: {
        gradeScaleId: gradeScaleId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};



export const UpsertGradeScale = (courseDetails: IGradeScaleDescriptionDetail): Promise<{ details: IGradeScaleDescriptionDetail }> => {
  return API()
      .post("/SystemCatalog/GradeScale/UpsertGradeScaleDetails", courseDetails, {
          params: {}
      })
      .then((res: any) => {
          if (res && res.data && res.data.result) {
              return {
                  details: res.data.result,
                  message: res.data.resultStatusMessage
              };
          }
          throw (new Error('Unknown error'));
      }, (error: any) => {
          if (error.response) {
              throw new Error(error.response.data); // => the response payload 
          }
          throw (new Error('Unknown error'));
      });
};

export const UpsertGradeSystem = (courseDetails: IGradeSystemDescriptionDetail): Promise<{ details: IGradeSystemDescriptionDetail }> => {
  return API()
      .post("/SystemCatalog/GradeSystem/UpsertGradeSystem", courseDetails, {
          params: {}
      })
      .then((res: any) => {
          if (res && res.data && res.data.result) {
              return {
                  details: res.data.result,
                  message: res.data.resultStatusMessage
              };
          }
          throw (new Error('Unknown error'));
      }, (error: any) => {
          if (error.response) {
              throw new Error(error.response.data); // => the response payload 
          }
          throw (new Error('Unknown error'));
      });
};

export const UpsertGradeSystemDetail = (courseDetails: IGradeSystemDescriptionDetail): Promise<{ details: IGradeSystemDescriptionDetail }> => {
  return API()
      .post("/SystemCatalog/GradeSystem/UpsertGradeSystemDetails", courseDetails, {
          params: {}
      })
      .then((res: any) => {
          if (res && res.data && res.data.result) {
              return {
                  details: res.data.result,
                  message: res.data.resultStatusMessage
              };
          }
          throw (new Error('Unknown error'));
      }, (error: any) => {
          if (error.response) {
              throw new Error(error.response.data); // => the response payload 
          }
          throw (new Error('Unknown error'));
      });
};