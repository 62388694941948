import React from "react";
import { useEffect, useRef } from "react";
import { apiAutoCompleteConfig } from "../../constants/apiAutoComplete.config";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../constants/actions.constants";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";
import { Enrollment } from "../../interfaces/student/academics/Enrollment";

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& label.Mui-focused": {
				color: theme.palette.primary.white
			}
		}
	})
)(TextField);

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {}
	})
);

const config: ApiAutoCompleteConfigItem = {
	acId: "studentAwardDisbursements",
	cacheTime: 5,
	route: "/FinancialAid/StudentAward/GetDisbursements",
	requestType: "GET",
	body: null,
	hasUrlParams: true,
	useCache: false,
	storeInCache: false,
	isCampusSpecific: false,
	isUserSpecific: false,
	loadOnRender: false,
	defaultSelectedFirstItem: true,
	optionsMappedFx: (data: Array<any>) => {
		return data;
	}
};

export interface DisbursementProps extends FilterParams {
	awardId: string;
	isRefund:boolean;
}

const DisbursementAutoComplete = (props: DisbursementProps) => {
	const classes = useStyles({});
	const inputRef: any = useRef(null);

	const handleOnChange = (e: any) => {
		if (props.filterHandle) {
			props.filterHandle(e);
		}
	};

	return (
		<div>
			<ApiAutoComplete
				config={config}
				loading={props.awardId ? false : true}
				reqParams={props.awardId ? { awardId: props.awardId,isRefund:props.isRefund } : null}
				classes={{
					option: "text-black"
				}}
				label={props.label ? props.label : "Disbursement"}
				onChange={(e: any) => {
					handleOnChange(e);
				}}
				multiple={false}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
				inputRef={props.inputRef ? props.inputRef : inputRef}
				error={props.error}
				helperText={props.helperText}
				inputName={props.name}
			></ApiAutoComplete>
		</div>
	);
};

export default DisbursementAutoComplete;
