import API from "../../../apiWrapper";
import IPaymentPlanSettings from '../../../../interfaces/dashboard/IPaymentPlanWidgetSettings';

export const GetPaymentPlanWidgetData = (campusId: string, asOfDate: string | null, showAll: boolean) => {
  return API()
    .get("/FinancialAid/PaymentPlanWidget/GetPaymenPlanDataForWidget", {
      params: {
        campusId,
        asOfDate,
		showAll,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const upsertPaymentPlanSettings = (
	terminationSettings: IPaymentPlanSettings
) => {
	return API()
		.post("/FinancialAid/PaymentPlanWidget/UpsertPaymentPlanSettings", terminationSettings)
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

export const updateContactedFlagForPaymentPlan = (
	checkList: []
) => {
	return API()
		.post("/FinancialAid/PaymentPlanWidget/UpdateContactedFlagForPaymentPlan", checkList)
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return error;
			}
		);
};


export const getPaymentPlanSettings = (
	campusId: string
) => {
	return API()
		.get("/FinancialAid/PaymentPlanWidget/GetPaymentPlanSettings", {
			params: {
				campusId
			}
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return error;
			}
		);
};