import * as React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
	Grid,
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Switch,
} from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import { IPaymentPlanTrackerReportParams } from "../../../interfaces/reports/studentAccounts/IPaymentPlanTrackerReportParams";
import * as paymentPlanTrackerApi from "../../../api/reports/studentAccounts/paymentPlanTrackerApi";
import { ReportOutput } from "../../../enums/ReportOutput";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import DateFnsUtils from "@date-io/date-fns";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import { useSelector } from "react-redux";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import StudentGroupsAutoComplete from "../../../components/AutoComplete/StudentGroupsAutoComplete";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
import EnrollmentStatusAutoComplete from "../../../components/AutoComplete/EnrollmentStatusAutoComplete";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import ProgramVersionAutoComplete from "../../../components/AutoComplete/ProgramVersionAutoComplete";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			minHeight: "100%",
			height: "100%",
			padding: theme.spacing(2),
		},
		"& .makeStyles-content": {
			backgroundColor: theme.palette.background.paper,
		},
		paperRoot: {
			padding: theme.spacing(3, 2),
			width: "100%",
			minHeight: "100%",
			height: "100%",
			overflowX: "hidden",
			overflowY: "auto",
			flexDirection: "column",
			backgroundColor: theme.palette.background.paper,
		},
		searchActions: {
			marginTop: theme.spacing(3),
			textAlign: "left",
		},
		startDateField: {
			width: "100%",
		},
		fieldSet: {
			marginTop: theme.spacing(2),
		},
		switch: {
			marginLeft: "0px",
			paddingTop: theme.spacing(2),
		},
	})
);

const PaymentPlanTrackerReport = (props: any) => {
	const { report } = props;
	const classes = useStyles({});
	const [preview, setPreview] = React.useState<any>();
	const [previewLoader, setPreviewLoader] = React.useState<boolean>();

	let reportOverview = { report: report } as IReportOverview;

	const data = useForm<any>({ mode: "onBlur" });

	const campusId = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const [model, setModel] = React.useState({
		campusId: campusId as string,
		endDate: undefined as Date | undefined,
		leadGroupIds: [],
		enrollmentStatusIds: [],
		programVersionIds: [],
		exportType: ReportOutput.Pdf as any,
		showHeader: false,
		gracePeriod: 15,
	});

	const exportTypes: Array<DropDownListItem> = [
		{ text: "PDF", id: "1" },
		{ text: "Excel", id: "2" },
		{ text: "Word", id: "3" },
	];

	const exportTypeAutoComplete = {
		options: exportTypes,
		getOptionLabel: (option: DropDownListItem) => option.text,
	};

	const setProgramVersion = (PrgVersions: any) => {
		let newProgramVersion: string[] = [];
		if (PrgVersions !== null) {
			if (Array.isArray(PrgVersions)) {
				newProgramVersion = PrgVersions.map((gr: any) => gr.value);
			} else {
				newProgramVersion.push(PrgVersions.value);
			}
		}
		return newProgramVersion;
	};

	const setStudentGroup = (studentGroups: any) => {
		let newGroups: string[] = [];
		if (studentGroups !== null) {
			if (Array.isArray(studentGroups)) {
				newGroups = studentGroups.map((gr: any) => gr.value);
			} else {
				newGroups.push(studentGroups.value);
			}
		}
		return newGroups;
	};

	const setEnrollmentStatus = (enrollmentStatus: any) => {
		let newStatuses: string[] = [];
		if (enrollmentStatus !== null) {
			if (Array.isArray(enrollmentStatus)) {
				newStatuses = enrollmentStatus.map((gr: any) => gr.value);
			} else {
				newStatuses.push(enrollmentStatus.value);
			}
		}

		return newStatuses;
	};

	const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
		const item = items.find((opt) => {
			if (opt.id == value) return opt;
		});
		return item || ({} as DropDownListItem);
	};

	const handleFieldOnChange = (
		fieldId: string,
		value: any,
		mapFunction?: Function
	) => {
		let updatedModel = model;
		let newValue = mapFunction ? mapFunction(value) : value;
		(updatedModel as any)[fieldId] = newValue;
		setModel({ ...updatedModel });
	};

	const { handleSubmit } = data;

	const onSubmit = async (isPreview: any): Promise<void> => {
		let params = await getReportParameters();
		const validation = await data.triggerValidation();
		if (validation) {
			if (isPreview) {
				paymentPlanTrackerApi
					.generateReport(params, isPreview)
					.then((res: any) => {
						if (res) {
							res.arrayBuffer().then((buffer: any) => {
								setPreview(buffer);
								setPreviewLoader(false);
							});
						} else setPreviewLoader(false);
					});
			} else await paymentPlanTrackerApi.generateReport(params);
		}
	};

	const filterHandler = (data: any) => {
		if (data) {
			setModel({ ...data });
		} else {
			setModel({
				campusId: model.campusId,
				endDate: model.endDate as Date,
				leadGroupIds: model.leadGroupIds,
				exportType: model.exportType,
				enrollmentStatusIds: model.enrollmentStatusIds,
				showHeader: model.showHeader,
				gracePeriod: model.gracePeriod,
				programVersionIds: model.programVersionIds,
			});
		}
	};

	const getReportParameters = async () => {
		const success = await data.triggerValidation();
		if (model && success) {
			let endDate = model.endDate ? model.endDate : undefined;
			let params: IPaymentPlanTrackerReportParams = {
				campusId: model.campusId,
				endDate: model.endDate,
				leadGroupIds: model.leadGroupIds,
				exportType: model.exportType,
				showHeader: model.showHeader,
				enrollmentStatusIds: model.enrollmentStatusIds,
				programVersionIds: model.programVersionIds,
			};
			return params;
		}
		return {} as IPaymentPlanTrackerReportParams;
	};

	let parameters = (
		<div className={classes.root}>
			<FormContext {...data}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container direction="row" spacing={1}>
						<Grid item md={3} sm={3} xs={12}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="MM/dd/yyyy"
									id="endDate"
									name="endDate"
									label="As of *"
									value={model.endDate ? model.endDate : null}
									onChange={(e: any, value: any) =>
										handleFieldOnChange("endDate", value ? value : null)
									}
									className={classes.startDateField}
									KeyboardButtonProps={{
										"aria-label": "end date",
									}}
									error={!!data.errors["endDate"]}
									innerRef={(ref: any) => {
										data.register("endDate", {
											required: true,
										});
										data.setValue("endDate", model.endDate);
									}}
									helperText={
										data.errors["endDate"]
											? (data.errors["endDate"] as FieldError).message
											: undefined
									}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item md={6} sm={6} xs={12}>
							<StudentGroupsAutoComplete
								id="leadGroupIds"
								name="leadGroupIds"
								campusId={model.campusId}
								disabled={false}
								filterHandle={(groups: any) => {
									handleFieldOnChange("leadGroupIds", groups, setStudentGroup);
								}}
								label="Student Group"
								multiple={true}
								valueFilter={
									model?.leadGroupIds
										? {
												key: "value",
												values: model?.leadGroupIds,
										  }
										: undefined
								}
							/>
						</Grid>
						<Grid item md={6} sm={6} xs={12}>
							<ProgramVersionAutoComplete
								selectAll={true}
								name="programVersionIds"
								campusId={model.campusId}
								filterHandle={(value: any) =>
									handleFieldOnChange(
										"programVersionIds",
										value,
										setProgramVersion
									)
								}
								valueFilter={
									model.programVersionIds
										? {
												key: "value",
												values: model.programVersionIds,
										  }
										: undefined
								}
								multiple={true}
								disabled={false}
							/>
						</Grid>
						<Grid item md={6} sm={6} xs={12}>
							<EnrollmentStatusAutoComplete
								filterHandle={(statuses: any) => {
									handleFieldOnChange(
										"enrollmentStatusIds",
										statuses,
										setEnrollmentStatus
									);
								}}
								id="enrollmentStatus"
								name="enrollmentStatus"
								label="Enrollment Status"
								multiple={true}
								valueFilter={
									model?.enrollmentStatusIds
										? {
												key: "value",
												values: model?.enrollmentStatusIds,
										  }
										: undefined
								}
							/>
						</Grid>
						<Grid item md={3} sm={3} xs={3}>
							<Autocomplete
								{...exportTypeAutoComplete}
								autoComplete
								includeInputInList
								onChange={(e: any, value: any) => {
									handleFieldOnChange(
										"exportType",
										value ? value : undefined,
										(v: any) => v?.id
									);
								}}
								value={getSelectedItem(exportTypes, model.exportType)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Export Type *"
										name="exportType"
										error={!!data.errors.exportType}
										inputRef={data.register({ required: true })}
										helperText={
											data.errors.exportType
												? "Export Type is required."
												: undefined
										}
										fullWidth
									/>
								)}
							/>
						</Grid>

						<Grid item md={3} sm={3} xs={3}>
							<FormControlLabel
								id="showHeader"
								className={classes.switch}
								labelPlacement="start"
								label="Show Header"
								control={
									<Switch
										checked={model.showHeader}
										name="showHeader"
										onChange={(e: any) => {
											handleFieldOnChange(
												"showHeader",
												e ? e.target.checked : false
											);
										}}
										color="primary"
									/>
								}
							/>
						</Grid>
						<Grid item xs={3} md={3} sm={6}>
							<InputField
								type={InputType.NUMBER}
								id="gracePeriod"
								label="Grace Period Days"
								name="gracePeriod"
								key="gracePeriod"
								defaultValue={model.gracePeriod}
								onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
									handleFieldOnChange("totalCost", e.target.value);
								}}
								decimal={false}
							/>
						</Grid>
					</Grid>
				</form>
			</FormContext>
		</div>
	);

	reportOverview.parameters = parameters;

	return (
		<ReportOverview
			reportOverview={reportOverview}
			filterHandler={filterHandler}
			getReportParameters={getReportParameters}
			exportHandler={onSubmit}
			preview={preview}
			previewLoader={previewLoader}
		/>
	);
};

export default PaymentPlanTrackerReport;
