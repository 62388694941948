import API from "../../apiWrapper";
import { IStatusChangeEnrollment } from "../../../interfaces/student/status/IStatusChangeEnrollment";		

  export const ReEnrollFromDrop = (
    changeEnrollment: IStatusChangeEnrollment,
    newStatusCode: string
  ) => {

      return API()
          .post("/AcademicRecords/StatusChanges/FutureStart/ReEnrollFromDrop", changeEnrollment,{
            params: {
              newStatusCode
            }
            })
          .then(
            (res: any) => {
                if (res && res.data) {
                    return res;
                }
                },
                    (error: any) => {
                    return error;
                }
            );;          
  };

  export const reEnrollFromNoStart = (
    changeEnrollment: IStatusChangeEnrollment,
    newStatusCode: string
  ) => {

      return API()
          .post("/AcademicRecords/StatusChanges/FutureStart/ReEnrollFromNoStart", changeEnrollment,{
            params: {
                newStatusCode
            }
            })
          .then(
            (res: any) => {
                if (res && res.data) {
                    return res;
                }
                },
                    (error: any) => {
                    return error;
                }
            );;          
  };

