import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Input,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Snackbar,
    Typography,
    colors,    ListItemSecondaryAction,
    ListItemIcon,
    Checkbox,
    CircularProgress,
    Grid,
    FormGroup,
    FormControlLabel,
    Tooltip,
    IconButton,
    Chip
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { v1 as uuid } from "uuid";
import { ILead } from '../../../interfaces/admissions/ILead';
import * as leadsApi from "../../../api/admissions/leadsApi";
import { useSelector } from 'react-redux';
import { LeadSearchInputPaged } from '../../../interfaces/admissions/LeadSearchInputPaged';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import _ from 'lodash';
import indigo from '@material-ui/core/colors/indigo';
import teal from '@material-ui/core/colors/teal';

const useStyles = makeStyles((theme: any) => ({
    root: {},
    content: {
        paddingTop: 0
    },
    search: {
        padding: theme.spacing(2, 3),
        display: 'flex',
        alignItems: 'center'
    },
    searchIcon: {
        color: theme.palette.text.secondary
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        color: theme.palette.text.secondary,
        fontSize: '14px'
    },
    avatar: {
        height: 60,
        width: 60,
        marginLeft: theme.spacing(3),

    },
    listItem: {
        flexWrap: 'wrap'
    },
    listItemText: {
        marginLeft: theme.spacing(2),
        flexGrow: 0,
        maxWidth: '25%',
        flexBasis: '25%'
    },
    connectButton: {
        marginLeft: 'auto'
    },
    pendingButton: {
        marginLeft: 'auto',
        color: theme.palette.white,
        backgroundColor: colors.red[600],
        '&:hover': {
            backgroundColor: colors.red[900]
        }
    },
    connectedButton: {
        marginLeft: 'auto',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    buttonIcon: {
        marginRight: theme.spacing(1)
    },
    body: {
        margin: 'auto',
        height: 300,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    bodyText: {
        padding: theme.spacing(3)
    },
    aboveCenter: {
        margin: 'auto',
        top: '50%',
        left: '50%',
    },
    center: {
        margin: 'auto',
        top: '60%',
        left: '50%',
    },
  
    block: {
        display: 'block'
    },
    filters: {
        justifyContent: 'space-between'
    },
    checkBoxes: {
        marginLeft: theme.spacing(2)
    },
    badge: {
        margin: theme.spacing(1)
    }

}));


export interface LeadListProps {
    lead?: ILead;
    headerContent?: string;
    includeEnrolled?: boolean;
    setSelectedLead?: (val: ILead | undefined) => any;
    params: LeadSearchInputPaged | null;
    className?: string;
}

const LeadsList = (props: LeadListProps) => {
    const { className, lead, headerContent, includeEnrolled, params, ...rest } = props;
    const parentSetSelectedLead = props.setSelectedLead;
    const classes = useStyles();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [leads, setLeads] = useState<Array<ILead>>([]);
    const [selectedLead, setSelectedLead] = useState<ILead | undefined>(undefined);
    const userSelectedCampus: string = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );
    const [inputValue, setInputValue] = useState('');

    const handleToggle = (lead: ILead) => () => {
        const isSelected = selectedLead?.leadId == lead.leadId

        if (isSelected) {
            setSelectedLead(undefined);

            if (parentSetSelectedLead)
                parentSetSelectedLead(undefined);

        }

        else {
            setSelectedLead(lead);

            if (parentSetSelectedLead)
                parentSetSelectedLead(lead);

        }
    };

    const handleFilterStateToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterState({ ...filterState, [event.target.name]: event.target.checked });

    }
    const theme: any = useTheme();
    const [loading, setLoading] = React.useState(false);
    const [filterState, setFilterState] = React.useState({
        afaIntegrationFilterChecked: false,
        includeEnrolledFilterChecked: includeEnrolled ?? true
    });
    const leadParams = lead ? {
        firstName: lead?.firstName ?? "", lastName: lead?.lastName ?? "", fullName: lead?.fullName, email: lead?.emails ? lead?.emails[0]?.email : "" ?? "", phoneNumber: lead?.phones ? lead?.phones[0]?.phone : "" ?? "",
        address: lead?.addresses ? lead?.addresses[0]?.address1 : "" ?? "", address2: lead?.addresses ? lead?.addresses[0]?.address2 : "" ?? "", city: lead?.addresses ? lead?.addresses[0]?.city : "" ?? "", zipCode: lead?.addresses ? lead?.addresses[0]?.zip : "" ?? "",
        state: lead?.addresses ? lead?.addresses[0]?.state : "" ?? "", program: lead?.program ?? "", status: lead?.status ?? ""
    } as LeadSearchInputPaged : params;

    useEffect(() => {
        let mounted = true;

        const fetchleads = () => {
            setLoading(true);
            leadsApi.searchLeads(userSelectedCampus, leadParams, filterState.includeEnrolledFilterChecked)
                .then(response => {
                    if (mounted && response.data) {
                        setLeads(response.data);
                    }
                    else {
                        setLeads([]);

                    }

                    setLoading(false);
                });
        };

        fetchleads();

        return () => {
            mounted = false;
        };
    }, [lead, params, filterState.includeEnrolledFilterChecked]);

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };


    const handleInputChange = (event: any) => {
        event.persist();
        setInputValue(event.target.value);
    };



    const searchLeads = (leads: ILead[]) => {
        if (inputValue) {
            var lower = inputValue.toLowerCase();
            return leads.filter(function (o) {
                return (o.firstName?.toLowerCase().includes(lower)
                    || o.lastName?.toLowerCase().includes(lower) || o.email?.toLowerCase().includes(lower) || o.phone?.toLowerCase().includes(lower));
            });
        }

        return leads;
    }
    const filterLeads = (leads: ILead[]) => {

        if (filterState.afaIntegrationFilterChecked) {
            return leads.filter((f: ILead) => !!f.afaStudentId)
        }

        return leads;
    }
    const filteredLeads = searchLeads(filterLeads(leads));


    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardHeader title="Existing Records in Advantage" />
            <Typography variant="body1" className={classes.bodyText} gutterBottom>
                {headerContent ? headerContent : "Select a lead if you would like to merge records. The fields on the existing record will take" +
                    " priority. Note: If a lead with an ISIR on file is selected, then the imported lead will also be linked to the ISIR."}
            </Typography>
            <FormGroup row className={classes.filters}>
                <div className={clsx(classes.search)}>
                    <SearchIcon
                        className={classes.searchIcon}
                        color="inherit"
                    />
                    <Input
                        className={classes.searchInput}
                        disableUnderline
                        onChange={handleInputChange}
                        placeholder="Search"
                    />
                </div>
                <div className={classes.checkBoxes}>
                    <FormControlLabel
                        control={<Checkbox color="primary" checked={filterState.includeEnrolledFilterChecked} onChange={handleFilterStateToggle} name="includeEnrolledFilterChecked" />}
                        label="Include Enrolled Students"
                    />
                    <FormControlLabel
                        control={<Checkbox color="primary" checked={filterState.afaIntegrationFilterChecked} onChange={handleFilterStateToggle} name="afaIntegrationFilterChecked" />}
                        label="ISIR On File Only"
                    />
                </div>
            </FormGroup>
            <Divider />
            <CardContent className={classes.content}>
                {loading && (<div className={classes.body}><Typography variant="h5" className={clsx(classes.aboveCenter)} gutterBottom>
                    Searching existing leads for possible matches...
                </Typography> <CircularProgress className={classes.center} size={theme.spacing(3)} /> </div>)}

                {!loading && (filteredLeads.length > 0 ? (<PerfectScrollbar>
                    <List disablePadding>
                        {filteredLeads.map((lead, i) => (
                            <ListItem
                                className={classes.listItem}
                                disableGutters
                                divider={i < leads.length - 1}
                                key={lead.leadId}
                                button onClick={handleToggle(lead)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={selectedLead?.leadId == lead.leadId}
                                        tabIndex={-1}
                                        color="primary"
                                        disableRipple
                                    />
                                </ListItemIcon>
                                <ListItemAvatar>
                                    <Avatar
                                        alt="Profile image"
                                        className={classes.avatar}
                                        src={lead.profilePhoto ? `data:${lead.profilePhoto.mediaType};base64,${lead.profilePhoto.image}` : ''}
                                    />
                                </ListItemAvatar>

                                <ListItemText
                                    className={classes.listItemText}
                                    primary={`${lead.firstName + " " + lead.lastName + (lead.studentNumber ? " - " + lead.studentNumber : "")} `}
                                    secondary={`SSN: ${lead.ssn ?? "N/A"}`}
                                />

                                <ListItemText
                                    className={classes.listItemText}
                                    primary={`Date of Birth`}
                                    secondary={`${moment(lead.birthDate).calendar()}`}

                                />


                                <ListItemText
                                    className={classes.listItemText}
                                    primary={`Contact Information`}
                                    secondary={<React.Fragment>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            color="textPrimary"
                                            className={classes.block}
                                        >
                                            Address: {lead.fullAddress ?? "N/A"}


                                        </Typography>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            color="textPrimary"
                                            className={classes.block}

                                        >
                                            Email: {lead.email ?? "N/A"}

                                        </Typography>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            color="textPrimary"
                                            className={classes.block}

                                        >
                                            Phone Number: {lead.phoneNumber ?? "N/A"}
                                        </Typography>
                                    </React.Fragment>
                                    }

                                />
                                <ListItemSecondaryAction>
                                    {lead.isEnrolled && <Chip label="Enrolled" className={classes.badge} size="small" style={{
                                        backgroundColor: indigo[500], color: 'white'
                                    }} />}
                                    {lead.afaStudentId && <Chip label="ISIR on file" size="small" className={classes.badge} style={{ backgroundColor: teal[500], color: 'white' }} />}
                                </ListItemSecondaryAction>


                            </ListItem>
                        ))}
                    </List>
                </PerfectScrollbar>) : (<div className={classes.body}><Typography variant="h5" className={clsx(classes.center)} gutterBottom>
                    No matching leads were found in Advantage for the selected lead.
                </Typography></div>))}
            </CardContent>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                autoHideDuration={6000}
                message={
                    <Typography
                        color="inherit"
                        variant="h6"
                    >
                        Sent lead request
          </Typography>
                }
                onClose={handleSnackbarClose}
                open={openSnackbar}
            />
        </Card>
    );
};


export default LeadsList;