/* eslint-disable react/display-name */
import * as React from "react";
import { useState, forwardRef, CSSProperties } from "react";
import { NavLink as RouterLink, withRouter, RouteComponentProps } from "react-router-dom";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { ListItem, Button, Icon, Collapse, colors } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { mapIcons } from "../../_Layout/NavBar/navigationConfig";
import BreadCrumbPrintMenu from "../../../pages/student/profile/breadCrumbActions/BreadCrumbPrintMenu";
import { useHistory } from "react-router-dom";

export interface IBreadCrumbActionItemProps  extends RouteComponentProps  {
  child?: any;
  secondary?: boolean;
  className?: string;
  action?: any;
  actionUrl?: string;
  icon?: any;
  label?: any;
  renderComponentHandler?: any;
  setBreadCrumbBarLoader?: any;
};

const useStyles = makeStyles((theme: any) =>
  createStyles({
    item: {
      display: "flex",
      paddingTop: 0,
      paddingBottom: 0,
    },
    button: {
      color: colors.blueGrey[800],
      justifyContent: "center",
      textTransform: "none",
      letterSpacing: 0,
      display: "flex",
    },
    icon: {
      color: theme.palette.icon.primary,
      display: "flex",
      alignItems: "center",
    },

    label: {
      display: "flex",
      alignItems: "center",
      marginLeft: "auto",
    },
    active: {
      color: theme.palette.site.primary,
      fontWeight: theme.typography.fontWeightMedium,
      "& $icon": {
        color: theme.palette.site.primary,
      },
    },
  })
);

const BreadCrumbActionItem = (props: IBreadCrumbActionItemProps) => {
  const {
    icon: Icon,
    className,
    action,
    actionUrl,
    secondary,
    child: Child,
    label: Label,
    ...rest
  } = props;

  const classes = useStyles({});
  const history = useHistory();

  return (
    <ListItem
      {...rest}
      className={clsx(classes.item, className)}
      disableGutters
    >
      <Button
        className={classes.button}
        onClick={(e) => {
          if (props.renderComponentHandler) {
            if (props.action) {
              props.renderComponentHandler(
                action(e, props.setBreadCrumbBarLoader, history)
              );
            } else if (props.actionUrl !== undefined) {
              history.push(props.actionUrl);
            }
          } else {
            if (props.action) {
              action(e, props.setBreadCrumbBarLoader, history);
            } else if (props.actionUrl !== undefined) {
              history.push(props.actionUrl);
            }
          }
        }}
      >
        {Icon && <Icon className={classes.icon} />}
      </Button>
      {Child && Child}
    </ListItem>
  );
};

BreadCrumbActionItem.defaultProps = {
  action: () => {},
};

export default withRouter(BreadCrumbActionItem);
