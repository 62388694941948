import * as React from "react";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";

const config: ApiAutoCompleteConfigItem = {
  acId: "graduationStatuses",
  cacheTime: 5,
  route: "/SystemCatalog/StatusCodes/GetGraduationStatusByCampus",
  requestType: "GET",
  body: null,
  hasUrlParams: false,
  useCache: true,
  storeInCache: true,
  isCampusSpecific: false,
  isUserSpecific: false,
  loadOnRender: true,
  defaultSelectedFirstItem: false,
  optionsMappedFx: (data: Array<any>) => {
    return data;
  },
};

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {
      width: "95%",
    },
    searchIcon: {
      fontSize: 30,
      paddingLeft: 10,
      color: theme.palette.icon.primary,
      cursor: "pointer",
    },
  })
);

const GraduationStatusAutoComplete = (props: FilterParams) => {
  const classes = useStyles({});

  const selectedEnrollment = useSelector(
    (state: any) => state.student.selectedEnrollment
  );

  const statusChange = (e: any) => {
    if (props.filterHandle) {
      props.filterHandle(e);
    }
  };

  return (
    <div className={clsx(classes.divWidth)}>
      <ApiAutoComplete
        config={config}
        reqParams={{
          campusId: selectedEnrollment ? selectedEnrollment.campusId : "",
        }}
        classes={{
          option: "text-black",
        }}
        label="Status"
        placeholder=""
        onChange={(e: any) => {
          statusChange(e);
        }}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        multiple={false}
      ></ApiAutoComplete>
    </div>
  );
};
export default GraduationStatusAutoComplete;
