import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import ProgramAndGroups from "../profile/enrollments/ProgramAndGroups";
import Schedule from "../profile/enrollments/Schedule";
import { EmptyGuid } from "../../../utils/constants";
import {
	EnrollmentProgramAndGroups,
	EnrollmentSchedule,
} from "../../../interfaces/student/academics/EnrollmentProgram";
import { useForm, FormContext } from "react-hook-form";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		cardSeparator: {
			marginBottom: theme.spacing(1),
		},

		button: {
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(3),
			whiteSpace: "nowrap",
			marginRight: theme.spacing(1),
		},
	})
);
type ProgramAndScheduleProps = {
	activeStep: number;
	steps: number;
	isTransferredStudent?: boolean;
	programId?: string | null;
	programVersionId?: string | null;
	isNewEnrollment: boolean;
	handleBack: () => void;
	handleNext: (
		enrollmentProgramAndGroups: EnrollmentProgramAndGroups | null,
		enrollmentSchedule: EnrollmentSchedule | null
	) => void;
	confimationModalProps?: any;
	setConfirmationModalProps?: any;
};

const ProgramAndSchedule = (props: ProgramAndScheduleProps) => {
	const classes = useStyles({});
	const methods = useForm<any>({
		mode: "onBlur",
	});
	const { handleSubmit, errors } = methods;

	const [
		enrollmentProgramAndGroupsDefault,
		setEnrollmentProgramAndGroupsDefault,
	] = React.useState<EnrollmentProgramAndGroups>({
		programDescription: "",
		programVersionDescription: "",
		programId: undefined,
		programVersionId: undefined,
		isCreditHour:false,
		studentGroupIds: [],
	});

	const [enrollmentSchedule, setEnrollmentSchedule] =
		React.useState<EnrollmentSchedule>({
			badgeId: EmptyGuid,
			scheduleId: EmptyGuid,
		});

	const setSchedule = (data: EnrollmentSchedule) => {
		setEnrollmentSchedule((state: EnrollmentSchedule | null) => {
			return { ...state, ...data };
		});
	};

	const onEnrollmentProgramAndGroupsChanged = (
		data: EnrollmentProgramAndGroups
	) => {
		setEnrollmentProgramAndGroupsDefault(
			(state: EnrollmentProgramAndGroups | null) => {
				return { ...state, ...data };
			}
		);
	};

	const onSubmit = (data: any) => {
		props.handleNext(enrollmentProgramAndGroupsDefault, enrollmentSchedule);
	};

	const loadFormData = () => {
		let defaultEnrollmentProgramAndGroups: EnrollmentProgramAndGroups = {
			programDescription: "",
			programVersionDescription: "",
			programId: undefined,
			programVersionId: undefined,
			studentGroupIds: [],
		};

		let defaultEnrollmentSchedule: EnrollmentSchedule = {
			badgeId: "",
			scheduleId: "",
		};
		setEnrollmentProgramAndGroupsDefault({
			...defaultEnrollmentProgramAndGroups,
		});

		setEnrollmentSchedule({ ...defaultEnrollmentSchedule });
	};

	const handleCancel = () => {
		props.setConfirmationModalProps((state: any) => {
			return {
				...state,
				open: true,
				confirmationText: "Are you sure you want to reset your changes?",
				confirmationButton: "Yes",
				title: "Confirmation",
				onConfirmEvent: () => {
					loadFormData();
					props.setConfirmationModalProps((updatedState: any) => {
						return { ...updatedState, open: false };
					});
				},
				onCancelEvent: () => {
					props.setConfirmationModalProps((updatedState: any) => {
						return { ...updatedState, open: false };
					});
				},
			};
		});
	};

	return (
		<FormContext {...methods}>
			<form key="programAndScheduleForm" onSubmit={handleSubmit(onSubmit)}>
				<div className={classes.cardSeparator}>
					<ProgramAndGroups
						enrollmentProgramAndGroups={enrollmentProgramAndGroupsDefault}
						setEnrollmentProgramAndGroups={onEnrollmentProgramAndGroupsChanged}
						isNewEnrollment={props.isNewEnrollment}
						isTransferredStudent={props.isTransferredStudent}
						isNewEnrollmentWorkFlow={true}
						showInactivePrograms={false}
					></ProgramAndGroups>
				</div>
				<div>
					{!enrollmentProgramAndGroupsDefault?.isCreditHour ? (
						<Schedule
						schedule={enrollmentSchedule}
						setSchedule={setSchedule}
						isNewEnrollment={true}
						isScheduleRequired={true}
						programVersion={
							enrollmentProgramAndGroupsDefault?.programVersionId
						}
						/>
					) : (
						<div></div>
					)}
				</div>
				<div>
					<Button
						disabled={props.activeStep === 0}
						onClick={props.handleBack}
						className={classes.button}
						color="secondary"
						variant="contained"
					>
						Back
					</Button>

					<Button
						variant="contained"
						color="primary"
						type="submit"
						onClick={() => {
							handleSubmit(onSubmit);
						}}
						className={classes.button}
					>
						Next
					</Button>
					<Button
						onClick={handleCancel}
						className={classes.button}
						color="secondary"
						variant="contained"
						type="button"
					>
						Cancel
					</Button>
				</div>
			</form>
		</FormContext>
	);
};
export default ProgramAndSchedule;
