import React from "react";
import { useRef } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { FilterParams } from "../../../interfaces/common/FilterParams";
import { ApiAutoCompleteConfigItem } from "../../../interfaces/ApiAutoCompleteConfigItem";
import { CalculationUsingTypesOptions,HoursTypeOptions,DaysTypeOptions,WeeksTypeOptions } from "../../../constants/RefundConstants/refunds.constants";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {},
	})
);

const config: ApiAutoCompleteConfigItem = {
	acId: "HourTypesAutoComplete",
	cacheTime: 5,
	route: null,
	requestType: "GET",
	body: null,
	hasUrlParams: false,
	useCache: false,
	storeInCache: false,
	isCampusSpecific: false,
	isUserSpecific: false,
	loadOnRender: true,
	defaultSelectedFirstItem: false,
	optionsMappedFx: (data: Array<any>) => {
		return data;
	},
};

export interface HourTypesProps extends FilterParams {
	afterStartProgramCompletionType:number | null;
}

const HourTypesAutoComplete = (props: HourTypesProps) => {
	const classes = useStyles({});
	const inputRef: any = useRef(null);

	const handleOnChange = (e: any) => {
		if (props.filterHandle) {
			props.filterHandle(e);
		}
	};

	const filter = createFilterOptions<any>();

	return (
		<div>
			<ApiAutoComplete
				config={config}
				loading={false}
				reqParams={null}
				classes={{
					option: "text-black",
				}}
				className={props.className}
				label={props.label ? props.label : undefined}
				onChange={(e: any) => {
					handleOnChange(e);
				}}
				multiple={false}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
				inputRef={props.inputRef ? props.inputRef : inputRef}
				error={props.error}
				helperText={props.helperText}
				inputName={props.name}
				options={CalculationUsingTypesOptions}
				filterOptions={(options: any, params: any) => {
					const filtered = (filter(options, params) as any[]).filter(
						(option: any) => {
							let isSatisfied = false;
							switch(props.afterStartProgramCompletionType){
								case 1:{//1 is hours
									isSatisfied = (HoursTypeOptions.findIndex(m => m.value === option.value) >= 0);
									break;
								}
								case 2:{//2 is days
									isSatisfied = (DaysTypeOptions.findIndex(m => m.value === option.value) >= 0);
									break;
								}
								case 3:{//3 is weeks
									isSatisfied = (WeeksTypeOptions.findIndex(m => m.value === option.value) >= 0);
									break;
								}
								case 4:{//4 is percent
									isSatisfied = (HoursTypeOptions.findIndex(m => m.value === option.value) >= 0);
									break;
								}
								default:{
									isSatisfied = false;
									break;
								}
							}

							return isSatisfied;
						}
					);

					return filtered;
				}}
			></ApiAutoComplete>
		</div>
	);
};

export default HourTypesAutoComplete;
