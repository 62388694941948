import API from '../../apiWrapper';
import { IAdhocReport } from '../../../interfaces/reports/adhoc/IAdhocReport';
import { AxiosResponse } from 'axios';
import { IAdHocReportOption } from '../../../interfaces/reports/adhoc/IAdHocReportOptions';
import { IAdhocReportDetails } from '../../../interfaces/reports/adhoc/IAdhocReportDetails';
import { ToBlob } from '../../../utils/base64Helper';
import { FileUtil } from '../../../utils/file';
import { stringify } from 'qs';

export const getReportsList = (query: { campusId: string, search: string, category: Array<string>, loadType: number, page: number, pageSize: number }): Promise<{ data: IAdhocReport[], hasMore: boolean, total: number, page: number, loadType: number }> => {
    return API().get('Reports/AdHoc/AdHocReport/GetAdhocReports', {
        params: {
            searchTerm: query.search || undefined,
            categoryId: query.category || [],
            pageNumber: query.page || 1,
            pageSize: query.pageSize || 10,
            campusId: query.campusId
        },
        paramsSerializer: params => {
            return stringify(params)
        }
    }).then((res: AxiosResponse<{
        total: Number,
        hasMore: boolean,
        results: Array<IAdhocReport>
    }>) => {
        if (!res || !res.data) {
            throw new Error('Unknown Error Occured.')
        }
        return {
            data: res.data.results || [],
            hasMore: res.data.hasMore,
            total: (res.data.results || []).length,
            page: query.page,
            loadType: query.loadType
        }
    }).catch((e) => { throw new Error((e && e.message) || 'Unknown Error Occured.') });
}

export const getAdHocReportDetails = (query: { reportId: string }): Promise<IAdhocReportDetails> => {
    return API().get('Reports/AdHoc/AdHocReport/GetAdhocReportDetails', {
        params: {
            ...query
        }
    }).then((res: AxiosResponse<IAdhocReportDetails>) => {
        if (!res || !res.data) {
            throw new Error('Unknown Error Occured.')
        }
        return res.data;
    }).catch((e) => { throw new Error((e && e.message) || 'Unknown Error Occured.') });
}

export const getAdHocReportData = (query: { pageNumber: number; pageSize: number; campusId:string; options?: IAdHocReportOption }): Promise<{
    totalPages: number;
    totalRecords: number;
    records: Array<any>;
    currentPage: number;
    pageSize: number;
}> => {
    return API().post('Reports/AdHoc/AdHocReport/BuildAndGetResults', query.options, {
        params: {
            pageNumber: query.pageNumber,
            pageSize: query.pageSize,
            campusId:query.campusId
        }
    }).then((res: AxiosResponse<{ currentPageNumber: number; currentPageSize: number; data: Array<any>; totalPages: number; totalRecords: number; }>) => {
        if (!res || !res.data) {
            throw new Error('Unknown Error Occured.');
        }
        return {
            totalPages: res.data.totalPages,
            totalRecords: res.data.totalRecords,
            records: res.data.data,
            currentPage: res.data.currentPageNumber,
            pageSize: res.data.currentPageSize
        };
    }).catch((e) => { throw new Error((e && e.message) || 'Unknown Error Occured.') });
}

export const saveAdHocReport = (details: IAdhocReportDetails): Promise<IAdhocReportDetails> => {
    return API().post('Reports/AdHoc/AdHocReport/SaveAdHocReport', details, {
        params: {

        }
    }).then((res: AxiosResponse<string>) => {
        if (!res || !res.data) {
            throw new Error('Unknown Error Occured.')
        }
        return { ...details, id: res.data };
    }).catch((e) => { throw new Error((e && e.message) || 'Unknown Error Occured.') });
}

export const getReportBlobById = (query: { reportId: string; campusId: string; fileName: string; exportType?: number }): Promise<Blob> => {
    return API().get('Reports/AdHoc/AdHocReport/ExportReportById', {
        params: {
            reportId: query.reportId,
            campusId: query.campusId,
            exportType: (query.exportType || 2)
        }
    }).then((res: AxiosResponse<{ base64Data: string }>) => {
        if (!res || !res.data) {
            throw new Error('Unknown Error Occured.')
        }
        //fileDownloadCode
        return ToBlob(res.data.base64Data, (query.exportType || 2) == 1 ? 'application/xlsx' : 'application/pdf');
    }).catch((e) => { throw new Error((e && e.message) || 'Unknown Error Occured.') });
}

export const exportReportById = (query: { reportId: string; campusId: string; fileName: string; exportType?: number }): Promise<string> => {
    return getReportBlobById(query).then((res: Blob) => {
        if (!res) {
            throw new Error('Unknown Error Occured.');
        }
        //fileDownloadCode
        FileUtil.Download(res, `${query.fileName}.${(query.exportType || 2) == 1 ? 'xlsx' : 'pdf'}`);
        return 'Report downloaded successfully.';
    }).catch((e) => { throw new Error((e && e.message) || 'Unknown Error Occured.') });
}

export const getReportBlob = (query: { reportName?: string; campusId: string; options: IAdHocReportOption, exportType?: number }): Promise<Blob> => {
    return API().post('Reports/AdHoc/AdHocReport/ExportReport', query.options, {
        params: {
            campusId: query.campusId,
            reportName: query.reportName,
            exportType: (query.exportType || 2)
        }
    }).then((res: AxiosResponse<{ base64Data: string }>) => {
        if (!res || !res.data) {
            throw new Error('Unknown Error Occured.')
        }
        //fileDownloadCode
        return ToBlob(res.data.base64Data, (query.exportType || 2) == 1 ? 'application/xlsx' : 'application/pdf');
    }).catch((e) => { throw new Error((e && e.message) || 'Unknown Error Occured.') });
}

export const exportReport = (query: { reportName?: string; campusId: string; options: IAdHocReportOption, exportType?: number }): Promise<string> => {
    return getReportBlob(query).then((res: Blob) => {
        if (!res) {
            throw new Error('Unknown Error Occured.');
        }
        //fileDownloadCode
        FileUtil.Download(res, `${query.reportName}.${(query.exportType || 2) == 1 ? 'xlsx' : 'pdf'}`);
        return 'Report downloaded successfully.';
    }).catch((e) => { throw new Error((e && e.message) || 'Unknown Error Occured.') });
}

export const deleteReportById = (reportId: string): Promise<string> => {
    return API().get('Reports/AdHoc/AdHocReport/DeleteReportById', {
        params: {
            reportId
        }
    }).then((res: AxiosResponse<any>) => {
        if (!res || !res.data) {
            throw new Error('Unknown Error Occured.')
        }
        return res.data.statusText || 'Report deleted Successfully.';
    }).catch((e) => { throw new Error((e && e.message) || 'Unknown Error Occured.') });
}

export const duplicateReportById = (reportId: string): Promise<string> => {
    return API().get('Reports/AdHoc/AdHocReport/CopyReportById', {
        params: {
            reportId
        }
    }).then((res: AxiosResponse<any>) => {
        if (!res || !res.data) {
            throw new Error('Unknown Error Occured.')
        }
        return res.data.statusText || 'Report copied Successfully.';
    }).catch((e) => { throw new Error((e && e.message) || 'Unknown Error Occured.') });
}