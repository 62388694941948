import { call, put, takeLatest, select, takeEvery } from '@redux-saga/core/effects';
import { Effect } from '@redux-saga/types';
import * as adHocAPI from '../../api/reports/adHoc/adhocReports';
import { actions, actions as adHocActions, maxPagesAllowed, types as adHocTypes } from '../../store/reports/adhocs';
import { actions as adHocDetailsActions, types as adHocDetailsTypes, selectors as adHocDetailsSelectors } from '../../store/reports/adhocReportDetails';
import { selectors as adHocSelectos } from '../../store/reports/adhocs';
import { IAdHocReportOption } from '../../interfaces/reports/adhoc/IAdHocReportOptions';
import { IAdhocReport } from '../../interfaces/reports/adhoc/IAdhocReport';

function* fecthAdHocList(action: ReturnType<typeof adHocActions.loadReports>): IterableIterator<Effect> {
    const { success, failure } = adHocActions.saga.loadReports;
    try {
        const response = yield call(adHocAPI.getReportsList, action.data);
        if (response) {
            yield put(success((response as any).data, (response as any).hasMore, (response as any).total, (response as any).page, (response as any).loadType));
        }
    }
    catch (err) {
        yield put(failure((err as Error)?.message ?? 'Unknown Error Occured'));
    }
}

function* fetchAdHocReportOptions(action: ReturnType<typeof adHocDetailsActions.getAdHocDetails>): IterableIterator<Effect> {
    const { success, failure } = adHocDetailsActions.saga.getAdHocDetails;
    try {
        const response = yield call(adHocAPI.getAdHocReportDetails, action.data);
        if (response) {
            yield put(success(response));
        }
    }
    catch (err) {
        yield put(failure((err as Error)?.message ?? 'Unknown Error Occured'));
    }
}

function* getAdHocReportData(action: ReturnType<typeof adHocDetailsActions.loadPreviewData>): IterableIterator<Effect> {
    const { success, failure } = adHocDetailsActions.saga.loadPreviewData;
    try {
        const response = yield call(adHocAPI.getAdHocReportData, action.data);
        if (response) {
            yield put(success(response));
        }
    }
    catch (err) {
        yield put(failure((err as Error)?.message ?? 'Unknown Error Occured'));
    }
}

function* autoApplySort(action: ReturnType<typeof adHocDetailsActions.changeSortDirection>): IterableIterator<Effect> {
    if( action.data.apply){
        yield put(adHocDetailsActions.applySort());
    }
}

function* triggerBuildReport(action: any): IterableIterator<Effect> {
    if (action.type !== adHocDetailsTypes.changeSortDirection || action.data.apply) {
        const options: IAdHocReportOption | undefined = yield select(adHocDetailsSelectors.options);
        const campusId:string | undefined = yield select((state: any) => state.userstate.getSelectedCampus(state.session.user.userId));
        const page = action.data && action.data.page;
        const pageSize = action.data && action.data.pageSize;
        if (options) {
            yield put(adHocDetailsActions.loadPreviewData(page || 1, pageSize || 10, campusId ?? '', options));
        }
    }
}

function* saveAdhocReport(action: ReturnType<typeof adHocDetailsActions.saveAdHocReport>): IterableIterator<Effect> {
    const { success, failure } = adHocDetailsActions.saga.saveAdHocReport;
    try {
        const response = yield call(adHocAPI.saveAdHocReport, action.data);
        if (response) {
            yield put(success(response));
        }
    }
    catch (err) {
        yield put(failure((err as Error)?.message ?? 'Unknown Error Occured'));
    }
}

function* exportReport(action: ReturnType<typeof adHocDetailsActions.exportAdHocReport>): IterableIterator<Effect> {
    const { success, failure } = adHocDetailsActions.saga.exportAdHocReport;
    try {
        const response = yield call(adHocAPI.exportReport, action.data);
        if (response) {
            yield put(success(response));
        }
    }
    catch (err) {
        yield put(failure((err as Error)?.message ?? 'Unknown Error Occured'));
    }
}

function* exportReportById(action: ReturnType<typeof adHocDetailsActions.exportAdHocReportById>): IterableIterator<Effect> {
    const { success, failure } = adHocDetailsActions.saga.exportAdHocReportById;
    try {
        const response = yield call(adHocAPI.exportReportById, action.data);
        if (response) {
            yield put(success(response));
        }
    }
    catch (err) {
        yield put(failure((err as Error)?.message ?? 'Unknown Error Occured'));
    }
}

function* deleteReport(action: ReturnType<typeof adHocDetailsActions.deleteAdHocReport>): IterableIterator<Effect> {
    const { success, failure } = adHocDetailsActions.saga.deleteAdHocReport;
    try {
        const response = yield call(adHocAPI.deleteReportById, action.data);
        if (response) {
            yield put(success({
                showSnackBar: true,
                variant: 'success' as 'success',
                messageInfo: response
            }));
            const minPage = ((yield select(adHocSelectos.firstPage)) || 1);
            const maxPage = ((yield select(adHocSelectos.lastPage)) || 1);
            const pageSize = ((yield select(adHocSelectos.pageSize)) || 2);
            const search = ((yield select(adHocSelectos.search)) || '');
            const category = ((yield select(adHocSelectos.category)) || []);
            const campusId = ((yield select((state: any) =>
                state.userstate.getSelectedCampus(state.session.user.userId)
            )) || '');
            const query = ({ campusId, category, search, pageSize: (maxPage * pageSize), page: 1, loadType: 0 });
            const resultReports = yield call<typeof adHocAPI.getReportsList>(adHocAPI.getReportsList, query);

            if (resultReports) {
                yield put(adHocActions.refreshData({
                    newTotal: minPage > 1 ? (resultReports as any).total - (minPage * pageSize) : (resultReports as any).total,
                    records: minPage > 1 ? (resultReports as any).data.slice(minPage * pageSize) : (resultReports as any).data,
                    removePrevious: minPage > 1 ? (resultReports as any).data.length - (maxPagesAllowed * pageSize) : 0,
                    minPageSize: minPage,
                    maxPageSize: (resultReports as any).total > ((maxPage - 1) * pageSize) ? maxPage : (maxPage - 1),
                    hasMore: (resultReports as any).hasMore
                }));
            }
        }
    }
    catch (err) {
        yield put(failure({
            showSnackBar: true,
            variant: 'error' as 'error',
            messageInfo: (err as Error)?.message ?? 'Unknown Error Occured'
        }));
    }
}

function* duplicateReport(action: ReturnType<typeof adHocDetailsActions.duplicateAdHocReport>): IterableIterator<Effect> {
    const { success, failure } = adHocDetailsActions.saga.duplicateAdHocReport;
    try {
        const response = yield call(adHocAPI.duplicateReportById, action.data);
        if (response) {
            yield put(success({
                showSnackBar: true,
                variant: 'success' as 'success',
                messageInfo: response
            }));
            const minPage = ((yield select(adHocSelectos.firstPage)) || 1);
            const maxPage = ((yield select(adHocSelectos.lastPage)) || 1);
            const pageSize = ((yield select(adHocSelectos.pageSize)) || 2);
            const search = ((yield select(adHocSelectos.search)) || '');
            const category = ((yield select(adHocSelectos.category)) || []);
            const campusId = ((yield select((state: any) =>
                state.userstate.getSelectedCampus(state.session.user.userId)
            )) || '');
            const query = ({ campusId, category, search, pageSize: (maxPage * pageSize), page: 1, loadType: 0 });
            const resultReports = yield call<typeof adHocAPI.getReportsList>(adHocAPI.getReportsList, query);

            if (resultReports) {
                yield put(adHocActions.refreshData({
                    newTotal: minPage > 1 ? (resultReports as any).total - (minPage * pageSize) : (resultReports as any).total,
                    records: minPage > 1 ? (resultReports as any).data.slice(minPage * pageSize) : (resultReports as any).data,
                    removePrevious: minPage > 1 ? (resultReports as any).data.length - (maxPagesAllowed * pageSize) : 0,
                    minPageSize: minPage,
                    maxPageSize: (resultReports as any).total > ((maxPage - 1) * pageSize) ? maxPage : (maxPage - 1),
                    hasMore: (resultReports as any).hasMore
                }));
            }
        }
    }
    catch (err) {
        yield put(failure({
            showSnackBar: true,
            variant: 'error' as 'error',
            messageInfo: (err as Error)?.message ?? 'Unknown Error Occured'
        }));
    }
}

function* getPreviewById(action: ReturnType<typeof adHocDetailsActions.getPreviewBlobAdHocReportById>): IterableIterator<Effect> {
    const { success, failure } = adHocDetailsActions.saga.getPreviewBlobAdHocReportById;
    try {
        const response = yield call(adHocAPI.getReportBlobById, action.data);
        if (response) {
            yield put(success(response));
        }
    }
    catch (err) {
        yield put(failure((err as Error)?.message ?? 'Unknown Error Occured'));
    }
}

export default function* rootAdHocSaga(): IterableIterator<Effect> {
    yield takeLatest(adHocTypes.loadReports, fecthAdHocList);
    yield takeLatest(adHocDetailsTypes.getAdHocDetails, fetchAdHocReportOptions);
    yield takeLatest(adHocDetailsTypes.loadPreviewData, getAdHocReportData);
    yield takeLatest(adHocDetailsTypes.toggleField, triggerBuildReport);
    yield takeLatest(adHocDetailsTypes.toggleAllFields, triggerBuildReport);
    yield takeLatest(adHocDetailsTypes.applyFilters, triggerBuildReport);
    yield takeLatest(adHocDetailsTypes.changeSortDirection, autoApplySort);
    //yield takeLatest(adHocDetailsTypes.deleteSortingOption, triggerBuildReport);
    //yield takeLatest(adHocDetailsTypes.addSortOption, triggerBuildReport);
    //yield takeLatest(adHocDetailsTypes.deleteAllSorts, triggerBuildReport);
    yield takeLatest(adHocDetailsTypes.applySort, triggerBuildReport);
    yield takeLatest(adHocDetailsTypes.saga.getAdHocDetails.success, triggerBuildReport);
    yield takeLatest(adHocDetailsTypes.changePreviewPage, triggerBuildReport);
    yield takeLatest(adHocDetailsTypes.saveAdHocReport, saveAdhocReport);
    yield takeLatest(adHocDetailsTypes.exportAdHocReport, exportReport);
    yield takeEvery(adHocDetailsTypes.exportAdHocReportById, exportReportById);
    yield takeLatest(adHocDetailsTypes.deleteAdHocReport, deleteReport);
    yield takeEvery(adHocDetailsTypes.duplicateAdHocReport, duplicateReport);
    yield takeLatest(adHocDetailsTypes.getPreviewBlobAdHocReportById, getPreviewById)
}