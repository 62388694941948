export const system = Object.freeze({
	settings: {
		enableAFAIntegration: 'EnableAFAIntegration',
		allowMoreClinicServices: 'AllowMoreClinicServices',
		allowMoreClinicHours: 'AllowMoreClinicHours',
		alwaysPromptReasonAttendance: 'AlwaysPromptReasonAttendance',
		enableNewManageAdhocFlow: 'AdhocReporting',
		enableImportLeadPopup: 'CRM Integration',
		balanceOwedIsGreaterThenTotalCost: 'BalanceOwedIsGreaterThenTotalCost',
		enableIllinoisTranscript: 'UseIllinoisTranscriptReport',
		enableClockHourCreditHour: 'EnableClockHourCreditHour',
		allowInstructorGradebook: 'AllowInstructorGradebook',
		isDistanceAttTracking: 'IsDistanceAttTracking',
		ExcusedAbsenses: 'ExcusedAbsences',
		allowAFAOverride: 'AllowAFAOverride',
	},
	reports: {
		creationMethod: {
			buildReport: 'BuildReport',
			reactComponent: 'ReactComponent'
		}
	}
});