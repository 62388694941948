/* eslint-disable react/no-multi-comp */
import * as React from "react";
import { matchPath } from "react-router-dom";
import clsx from "clsx";
import * as PropTypes from "prop-types";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { List, Typography } from "@material-ui/core";

import useRouter from "../../utils/useRouter";
import NavigationListItem from "../Navigation/components/NavigationListItem/NavigationListItem";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			marginBottom: theme.spacing(3),
		},
	})
);

type INavigationProps = {
	component: any;
	className?: string;
	pages: Array<any>;
	title: string;
	minimized?: boolean;
};

type INavigationState = {};

type INavigationListProps = {
	pages: Array<any>;
	depth: number;
	router?: any;
	minimized?: boolean;
};

const NavigationList = (props: INavigationListProps) => {
	const { pages, minimized, ...rest } = props;

	return (
		<List>
			{pages.reduce(
				(items, page) => reduceChildRoutes({ items, page, minimized, ...rest }),
				[]
			)}
		</List>
	);
};

const areChildrenSubItems = (children: []): boolean => {
	let count = children.filter((child: any) => {
		return child.menuTypeId === 7 || child.menuTypeId === 8;
	});
	if (count.length < children.length) {
		return true;
	}
	return false;
};

const reduceChildRoutes = (props: any) => {
	const { router, items, page, depth, minimized } = props;
	if (page.menuTypeId <= 6) {
		if (minimized && page.children) {
			items.push(
				<NavigationListItem
					depth={page.menuTypeId === 6 ? depth + 2 :depth}
					icon={page.icon}
					key={page.title}
					label={page.label}
					title={page.title}
					minimized={minimized}
					children={page.children}
				/>
			);
		} else if (
			page.children &&
			areChildrenSubItems(page.children) &&
			!minimized
		) {
			items.push(
				<NavigationListItem
					depth={page.menuTypeId === 6 ? depth + 2 :depth}
					icon={page.icon}
					key={page.title}
					label={page.label}
					open={Boolean(false)}
					title={page.title}
				>
					<NavigationList
						depth={depth + 1}
						pages={page.children}
						router={router}
					/>
				</NavigationListItem>
			);
		} else {
			items.push(
				<NavigationListItem
					depth={depth}
					href={minimized && page.children ? page.children[0].href : page.href}
					icon={page.icon}
					key={page.title}
					label={page.label}
					title={page.title}
					minimized={minimized}
				/>
			);
		}
	}

	return items;
};

const Navigation = (props: INavigationProps) => {
	const {
		title,
		pages,
		className,
		component: Component,
		minimized,
		...rest
	} = props;

	const classes = useStyles();
	const router = useRouter();
	return (
		<Component {...rest} className={clsx(classes.root, className)}>
			<NavigationList
				depth={0}
				pages={pages}
				router={router}
				minimized={minimized}
			/>
		</Component>
	);
};
Navigation.defaultProps = {
	component: "nav",
};

export default Navigation;
