import API from "../../apiWrapper";
import { IUploadDocument } from "../../../interfaces/student/documents/IUploadDocument";
import { IStudentDocumentModule } from "../../../interfaces/student/documents/IStudentDocumentModule";

export const getDocumentsByStudentId = (studentId: string) => {
  return API()
    .get("/AcademicRecords/StudentDocuments/GetDocumentsByStudentId", {
      params: {
        studentId,
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data.result as Array<IStudentDocumentModule>;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const softDelete = (model: any) => {
  return API()
    .delete("/AcademicRecords/StudentDocuments/SoftDeleteDocument", {
			data: {
				studentId: model.studentId,
				fileName: model.fileName,
			},
		})
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const updateDocument = (model: any) => {
  return API()
    .post("/AcademicRecords/StudentDocuments/UpdateDocument", model)
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const uploadDocument = (model: IUploadDocument) => {
  let formData = new FormData();
  formData.append("fileToUpload", model.fileToUpload);
  formData.append("campusId", model.campusId);
  formData.append("studentId", model.studentId);
  formData.append("featureType", model.featureType.toString());
  formData.append("documentTypeId", model.documentTypeId.toString());
  formData.append("documentTypeName", model.documentTypeName ?? "");
  formData.append("moduleId", model.moduleId.toString());
  return API()
    .post("/Maintenance/Storage/UploadFileWithType", formData, {
      responseType: "json",
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};
