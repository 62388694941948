import * as React from "react";
import {
  Grid,
  makeStyles,
  createStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  FormControlLabel,
  Tooltip,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { getEmployerDetailsDetails } from "../../../api/student/placement/placementDetails";
import PlacementEmployerAutoComplete from "../../../components/AutoComplete/PlacementEmployerAutoComplete";
import PlacementEmployerJobsAutoComplete from "../../../components/AutoComplete/PlacementEmployerJobsAutoComplete";
import AllStatesAutoComplete from "../../../components/AutoComplete/AllStatesAutoComplete";
import PlacementFieldOfStudyAutoComplete from "../../../components/AutoComplete/PlacementFieldOfStudyAutoComplete";
import EmploymentTypeAutoComplete from "../../../components/AutoComplete/EmploymentTypeAutoComplete";
import IPlacementHistory from "../../../interfaces/student/placement/PlacementHistory";
import PlacementHistorySelection from "./PlacementHistorySelection";
import { useFormContext, FieldError } from "react-hook-form";
import { EmptyGuid } from "../../../utils/constants";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import { useSelector } from "react-redux";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    fullWidthField: {
      width: "100%",
    },
    collapseSummary: {
      height: "21px",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
    },
    collapseTitle: {
      fontSize: 16,
      color: "Black",
      fontWeight: 500,
    },
    loadingWrapper: {
      padding: "20px",
    },
    cardTitle: {
      fontSize: 16,
      color: "Black",
      fontWeight: 500,
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
    cardSeparator: {
      margin: theme.spacing(2, 0),
    },
    switch: {
      marginLeft: "0px",
      paddingTop: theme.spacing(2),
    },
  })
);

type PlacementHistoryProps = {
  placementHistory: IPlacementHistory;
  placementList?: IPlacementHistory[];
  updateDetails(details: Omit<IPlacementHistory, "fieldOfStudyTitle">): void;
};

const PlacementHistory = (props: PlacementHistoryProps) => {
  const classes = useStyles();
  const { placementHistory, updateDetails, placementList } = props;

  const data = useFormContext<IPlacementHistory>();
  data.register("studentPlacementId");
  const [loading, setLoading] = React.useState<boolean>(false);

  const [isRefused, setIsRefused] = React.useState<boolean>(false);

  const bypassValidationTypes = [
    "Refused Employment",
    "Seeking",
    "Requested Not to be Placed",
  ];

  const loadEmployerDetails = async (): Promise<void> => {
    if (
      placementHistory.employerId &&
      placementHistory.employerId !== EmptyGuid
    ) {
      setLoading(true);
      const placementDetails = await getEmployerDetailsDetails(
        placementHistory.employerId
      );
      updateDetails(placementDetails);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } else {
      data.setValue("employerAddress1", undefined);
      data.setValue("employerAddress2", undefined);
      data.setValue("employerCity", undefined);
      data.setValue("employerState", undefined);
      data.setValue("employerZip", undefined);
      data.setValue("employerPhone", undefined);
      data.setValue("employerEmail", undefined);
      data.setValue("employerContactName", undefined);
      data.setValue("employerContactTitle", undefined);
      data.setValue("isFullTimeStatus", undefined);
      data.setValue("isPartTimeStatus", undefined);
      const newModel: Omit<IPlacementHistory, "fieldOfStudyTitle"> = {
        ...placementHistory,
        employerAddress1: undefined,
        employerAddress2: undefined,
        employerCity: undefined,
        employerState: undefined,
        employerEmail: undefined,
        employerPhone: undefined,
        employerZip: undefined,
        employerContactName: undefined,
        employerContactTitle: undefined,
        isFullTimeStatus: undefined,
        isPartTimeStatus: undefined,
      };
      updateDetails(newModel);
    }
  };

  const loadDetails = (): void => {
    if (placementHistory.studentPlacementId) {
      if (placementHistory.studentPlacementId === EmptyGuid) {
        data.setValue("employerId", undefined);
        data.setValue("employerJobId", undefined);
        data.setValue("employerTitle", undefined);
        data.setValue("employerJobTitle", undefined);
        data.setValue("employerAddress1", undefined);
        data.setValue("employerAddress2", undefined);
        data.setValue("employerCity", undefined);
        data.setValue("employerState", undefined);
        data.setValue("employerZip", undefined);
        data.setValue("employerPhone", undefined);
        data.setValue("employerEmail", undefined);
        data.setValue("dateHired", undefined);
        data.setValue("startDate", undefined);
        data.setValue("terminationDate", undefined);
        data.setValue("fieldOfStudy", undefined);
        data.setValue("employmentType", undefined);
        data.setValue("salary", undefined);
        data.setValue("employerTitle", undefined);
        data.setValue("employerJobTitle", undefined);
        data.setValue("isFullTimeStatus", undefined);
        data.setValue("isPartTimeStatus", undefined);
        const newModel: Omit<IPlacementHistory, "fieldOfStudyTitle"> = {
          studentPlacementId: placementHistory.studentPlacementId,
          studentEnrollmentId: placementHistory.studentEnrollmentId,
          employerId: undefined,
          employerTitle: undefined,
          employerJobId: undefined,
          employerJobTitle: undefined,
          employerAddress1: undefined,
          employerAddress2: undefined,
          employerCity: undefined,
          employerState: undefined,
          employerEmail: undefined,
          employerPhone: undefined,
          employerZip: undefined,
          employmentType: undefined,
          dateHired: undefined,
          startDate: undefined,
          terminationDate: undefined,
          salary: undefined,
          fieldOfStudy: undefined,
          employerContactName: undefined,
          employerContactTitle: undefined,
          isFullTimeStatus: undefined,
          isPartTimeStatus: undefined,
        };
        updateDetails(newModel);
      } else {
        data.triggerValidation();
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const handleFieldOnChange = (
    fieldId: keyof Omit<IPlacementHistory, "fieldOfStudyTitle">,
    value: any
  ) => {
    const updatedModel: Omit<IPlacementHistory, "fieldOfStudyTitle"> =
      props.placementHistory;
    if (fieldId === "employerId") {
      if (placementHistory.employerId !== value) {
        updatedModel.employerJobId = undefined;
        updatedModel.employerJobTitle = undefined;
        updatedModel.employerId = value;
      }
      updateDetails(updatedModel);
    }

    (updatedModel as any)[fieldId] = value;
    data.setValue(fieldId, value);
    data.triggerValidation(fieldId);

    if (
      [
        "dateHired",
        "startDate",
        "employerTitle",
        "employerJobTitle",
        "employerJobId",
        "terminationDate",
        "fieldOfStudy",
        "employmentType",
      ].includes(fieldId)
    ) {
      updateDetails(updatedModel);
    }
  };

  React.useEffect(() => {
    setLoading(true);
    loadDetails();
    if (
      placementHistory &&
      bypassValidationTypes.includes(placementHistory.employmentTypeDesc ?? "")
    ) {
      setIsRefused(true);
      setTimeout(() => {
        data.clearError();
      }, 500);
    } else {
      setIsRefused(false);
    }
  }, [placementHistory.studentPlacementId]);

  React.useEffect(() => {
    loadEmployerDetails();
  }, [placementHistory.employerId]);

  data.register("employerId", {
    validate: () =>
      !!placementHistory.studentPlacementId &&
      !placementHistory.employerId &&
      isRefused == false
        ? "Employer is required."
        : true,
  });
  data.register("employerJobId", {
    validate: () =>
      (!!placementHistory.studentPlacementId &&
      !placementHistory.employerJobId &&
      isRefused == false)
      || (isRefused == true && placementHistory.employerId != null && placementHistory.employerJobId == null)
        ? "Job is required."
        : true,
  });
  data.register("employerState", {
    validate: () =>
      !!placementHistory.studentPlacementId &&
      !placementHistory.employerState &&
      isRefused == false
        ? "State is required."
        : true,
  });
  data.register("dateHired", {
    validate: () =>
      !placementHistory.dateHired ||
      placementHistory.dateHired.toString() !== "Invalid Date"
        ? true
        : false,
  });
  data.register("startDate", {
    validate: () =>
      !placementHistory.dateHired ||
      placementHistory.dateHired.toString() !== "Invalid Date"
        ? true
        : false,
  });
  data.register("terminationDate", {
    validate: () =>
      !placementHistory.dateHired ||
      placementHistory.dateHired.toString() !== "Invalid Date"
        ? true
        : false,
  });
  data.register("fieldOfStudy");

  data.register("employmentType", {
    validate: () =>
      !!placementHistory.studentPlacementId &&
      !placementHistory.employmentType &&
      isRefused == false
        ? "Employment Type is required."
        : true,
  });

  const handleFullTimePartTimeChange = (
    fieldName: string,
    selectedValue: string
  ) => {
    let updatedModel = { ...placementHistory };

    updatedModel.isFullTimeStatus = selectedValue === "Full Time";
    updatedModel.isPartTimeStatus = selectedValue === "Part Time";

    data.triggerValidation("isFullTimeStatus");
    data.triggerValidation("isPartTimeStatus");
    updateDetails(updatedModel);
  };

  return (
    <React.Fragment>
      <Grid container direction="row" spacing={1} justify={"center"}>
        <Grid item xs={12}>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.collapseSummary}
            >
              <Typography className={classes.collapseTitle}>
                View Job Placement History
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <PlacementHistorySelection
                placementList={placementList ?? []}
                selectPlacementHistory={updateDetails}
                selectedPlacementHistoryId={
                  placementHistory?.studentPlacementId
                }
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      </Grid>
      {!!placementHistory?.studentPlacementId && !loading && (
        <Card className={classes.cardSeparator}>
          <Typography className={classes.cardTitle}>
            Placement Details
          </Typography>
          <CardContent>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={4}>
                <EmploymentTypeAutoComplete
                  name="employmentType"
                  id="employmentType"
                  label="Employment Type *"
                  valueFilter={
                    placementHistory?.employmentType &&
                    placementHistory?.employmentType !== EmptyGuid
                      ? {
                          key: "value",
                          values: [placementHistory?.employmentType],
                        }
                      : undefined
                  }
                  filterHandle={(v: any) => {
                    v && bypassValidationTypes.includes(v.text)
                      ? setIsRefused(true)
                      : setIsRefused(false);
                    handleFieldOnChange("employmentType", v ? v.value : null);
                  }}
                  error={!!data.errors.employmentType}
                  helperText={
                    data.errors.employmentType
                      ? (data.errors.employmentType as FieldError).message
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <PlacementFieldOfStudyAutoComplete
                  name="fieldOfStudy"
                  id="fieldOfStudy"
                  valueFilter={
                    placementHistory?.fieldOfStudy &&
                    placementHistory?.fieldOfStudy !== EmptyGuid
                      ? {
                          key: "value",
                          values: [placementHistory?.fieldOfStudy],
                        }
                      : undefined
                  }
                  filterHandle={(v: any) => {
                    handleFieldOnChange("fieldOfStudy", v ? v.value : null);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  type={InputType.CURRENCY}
                  id="salary"
                  label="Salary"
                  name="salary"
                  key="salary"
                  decimal
                  thousandSeparator
                  defaultValue={placementHistory?.salary}
                  onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                    handleFieldOnChange("salary", v.target.value);
                  }}
                  error={!!data.errors?.salary}
                  inputRef={data.register()}
                  helperText={
                    data.errors?.salary
                      ? (data.errors?.salary as FieldError).message
                      : undefined
                  }
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    id="dateHired"
                    label={isRefused ? "Date Hired" : "Date Hired *"}
                    name="dateHired"
                    TextFieldComponent={InputField}
                    value={placementHistory?.dateHired ?? null}
                    autoOk={true}
                    onChange={(value: any) => {
                      handleFieldOnChange("dateHired", value ? value : null);
                    }}
                    className={classes.fullWidthField}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    innerRef={(ref: any) => {
                      isRefused
                        ? data.register({ required: false })
                        : data.register("dateHired", {
                            required: "A date of hire is required.",
                          });
                      data.setValue("dateHired", placementHistory?.dateHired);
                    }}
                    error={!!data.errors.dateHired}
                    helperText={
                      data.errors.dateHired
                        ? (data.errors.dateHired as FieldError).message
                        : ""
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    id="startDate"
                    label="Start Date"
                    name="startDate"
                    TextFieldComponent={InputField}
                    value={placementHistory?.startDate ?? null}
                    autoOk={true}
                    onChange={(value: any) => {
                      handleFieldOnChange("startDate", value ? value : null);
                    }}
                    className={classes.fullWidthField}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    id="terminationDate"
                    label="Termination Date"
                    name="terminationDate"
                    TextFieldComponent={InputField}
                    value={placementHistory?.terminationDate ?? null}
                    autoOk={true}
                    onChange={(value: any) => {
                      handleFieldOnChange(
                        "terminationDate",
                        value ? value : null
                      );
                    }}
                    className={classes.fullWidthField}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                <PlacementEmployerAutoComplete
                  name="employerId"
                  id="employerId"
                  enrollmentId={placementHistory.studentEnrollmentId}
                  valueFilter={
                    placementHistory?.employerId
                      ? {
                          key: "value",
                          values: [placementHistory?.employerId],
                        }
                      : undefined
                  }
                  filterHandle={(v: any) => {
                    handleFieldOnChange("employerId", v ? v.value : null);
                    handleFieldOnChange("employerTitle", v ? v.text : null);
                  }}
                  error={!!data.errors.employerId}
                  helperText={
                    data.errors.employerId
                      ? (data.errors.employerId as FieldError).message
                      : undefined
                  }
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12}>
                <PlacementEmployerJobsAutoComplete
                  name="employerJobId"
                  id="employerJobId"
                  disabled={!placementHistory?.employerId}
                  valueFilter={
                    placementHistory?.employerJobId
                      ? {
                          key: "value",
                          values: [placementHistory?.employerJobId],
                        }
                      : undefined
                  }
                  filterHandle={(v: any) => {
                    handleFieldOnChange("employerJobId", v ? v.value : null);
                    handleFieldOnChange("employerJobTitle", v ? v.text : null);
                  }}
                  error={!!data.errors.employerJobId}
                  requestParameter={
                    placementHistory?.employerId &&
                    placementHistory.employerId !== EmptyGuid
                      ? { employerId: placementHistory?.employerId }
                      : null
                  }
                  helperText={
                    data.errors.employerJobId
                      ? (data.errors.employerJobId as FieldError).message
                      : undefined
                  }
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12}>
                <InputField
                  id="employerAddress1"
                  label="Address Line 1"
                  name="employerAddress1"
                  key="employerAddress1"
                  decimal
                  thousandSeparator
                  defaultValue={placementHistory?.employerAddress1}
                  onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                    handleFieldOnChange("employerAddress1", v.target.value);
                  }}
                  inputProps={{ maxLength: 80 }}
                  error={!!data.errors?.employerAddress1}
                  inputRef={
                    isRefused
                      ? data.register({ required: false })
                      : data.register({
                          required: !!placementHistory.studentPlacementId
                            ? "Address Line 1 is Required"
                            : false,
                        })
                  }
                  helperText={
                    data.errors?.employerAddress1
                      ? (data.errors?.employerAddress1 as FieldError).message
                      : undefined
                  }
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12}>
                <InputField
                  id="employerAddress2"
                  label="Address Line 2"
                  name="employerAddress2"
                  key="employerAddress2"
                  decimal
                  thousandSeparator
                  defaultValue={placementHistory?.employerAddress2}
                  onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                    handleFieldOnChange("employerAddress2", v.target.value);
                  }}
                  inputProps={{ maxLength: 100 }}
                  error={!!data.errors?.employerAddress2}
                  inputRef={data.register()}
                  helperText={
                    data.errors?.employerAddress2
                      ? (data.errors?.employerAddress2 as FieldError).message
                      : undefined
                  }
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={4}>
                <InputField
                  id="employerCity"
                  label="City"
                  name="employerCity"
                  key="employerCity"
                  decimal
                  thousandSeparator
                  defaultValue={placementHistory?.employerCity}
                  onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                    handleFieldOnChange("employerCity", v.target.value);
                  }}
                  error={!!data.errors?.employerCity}
                  inputRef={
                    isRefused
                      ? data.register({ required: false })
                      : data.register({
                          required: !!placementHistory.studentPlacementId
                            ? "City is Required"
                            : false,
                        })
                  }
                  helperText={
                    data.errors?.employerCity
                      ? (data.errors?.employerCity as FieldError).message
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <AllStatesAutoComplete
                  id="employerState"
                  label="State"
                  name="employerState"
                  key="employerState"
                  valueFilter={
                    placementHistory?.employerState &&
                    placementHistory?.employerState !== EmptyGuid
                      ? {
                          key: "value",
                          values: [placementHistory?.employerState],
                        }
                      : undefined
                  }
                  filterHandle={(v: any) => {
                    handleFieldOnChange("employerState", v ? v.value : null);
                  }}
                  error={!!data.errors.employerState}
                  helperText={
                    data.errors?.employerState
                      ? (data.errors?.employerState as FieldError).message
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  id="employerZip"
                  label="Zip"
                  name="employerZip"
                  key="employerZip"
                  defaultValue={placementHistory?.employerZip}
                  onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                    handleFieldOnChange("employerZip", v.target.value);
                  }}
                  error={!!data.errors?.employerZip}
                  inputRef={data.register({
                    validate: (value: any) => {
                      if (!value) return true;
                      else
                        return /^(\d{5}|\d{5}-\d{4})$/.test(value)
                          ? true
                          : "Zip must be in format 5 digits or 5 digits-4 digits.";
                    },
                  })}
                  helperText={
                    data.errors?.employerZip
                      ? (data.errors?.employerZip as FieldError).message
                      : undefined
                  }
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={4}>
                <InputField
                  id="employerPhone"
                  label="Phone"
                  name="employerPhone"
                  key="employerPhone"
                  decimal
                  thousandSeparator
                  defaultValue={placementHistory?.employerPhone}
                  onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                    handleFieldOnChange("employerPhone", v.target.value);
                  }}
                  error={!!data.errors?.employerPhone}
                  inputRef={data.register({
                    validate: (value: any) => {
                      if (!value) return true;
                      else
                        return /^[+]?1?[-\. ]?(\(\d{3}\)?[-\. ]?|\d{3}?[-\. ]?)?\d{3}?[-\. ]?\d{4}$/.test(
                          value
                        )
                          ? true
                          : "Phone is not in correct format.";
                    },
                  })}
                  helperText={
                    data.errors?.employerPhone
                      ? (data.errors?.employerPhone as FieldError).message
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={8}>
                <InputField
                  id="employerEmail"
                  label="Email"
                  name="employerEmail"
                  key="employerEmail"
                  defaultValue={placementHistory?.employerEmail}
                  onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                    handleFieldOnChange("employerEmail", v.target.value);
                  }}
                  error={!!data.errors?.employerEmail}
                  inputRef={data.register({
                    validate: (value: any) => {
                      if (!value) return true;
                      else
                        return /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}\s*$/.test(
                          value
                        )
                          ? true
                          : "Email is not in correct format.";
                    },
                  })}
                  helperText={
                    data.errors?.employerEmail
                      ? (data.errors?.employerEmail as FieldError).message
                      : undefined
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <InputField
                  id="employerContactName"
                  label="Employer Contact Name"
                  name="employerContactName"
                  key="employerContactName"
                  decimal
                  thousandSeparator
                  defaultValue={placementHistory?.employerContactName}
                  onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                    handleFieldOnChange("employerContactName", v.target.value);
                  }}
                  inputProps={{ maxLength: 100 }}
                  error={!!data.errors?.employerContactName}
                  inputRef={data.register()}
                  helperText={
                    data.errors?.employerContactName
                      ? (data.errors?.employerContactName as FieldError).message
                      : undefined
                  }
                />
              </Grid>

              <Grid item xs={3}>
                <InputField
                  id="employerContactTitle"
                  label="Employer Contact Title"
                  name="employerContactTitle"
                  key="employerContactTitle"
                  decimal
                  thousandSeparator
                  defaultValue={placementHistory?.employerContactTitle}
                  onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                    handleFieldOnChange("employerContactTitle", v.target.value);
                  }}
                  inputProps={{ maxLength: 100 }}
                  error={!!data.errors?.employerContactTitle}
                  inputRef={data.register()}
                  helperText={
                    data.errors?.employerContactTitle
                      ? (data.errors?.employerContactTitle as FieldError)
                          .message
                      : undefined
                  }
                />
              </Grid>

              <Grid item xs={3}>
                <Tooltip
                  title={
                    <>
                      Full Time - 30 hours or more per week.
                      <br />
                      Part Time - 29 hours or less per week.
                    </>
                  }
                  aria-label="ToolNotes"
                >
                  <Autocomplete
                    options={["Full Time", "Part Time"]}
                    id="job2"
                    value={
                      placementHistory?.isFullTimeStatus
                        ? "Full Time"
                        : placementHistory?.isPartTimeStatus
                        ? "Part Time"
                        : null
                    }
                    onChange={(e: any, value: any) => {
                      handleFullTimePartTimeChange(
                        "isFullTimeStatus",
                        value
                      );
                    }}
                    renderInput={(params) => (
                      <InputField
                        {...params}
                        label="Employment Status"
                        name="eligible"
                        margin="none"
                        fullWidth
                      />
                    )}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      {loading && (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.loadingWrapper}
        >
          <CircularProgress />
        </Grid>
      )}
    </React.Fragment>
  );
};

export default PlacementHistory;
