import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import Demographics from "../profile/studentInfo/Demographics";
import Admissions from "../profile/studentInfo/Admissions";
import { Button } from "@material-ui/core";
import { useForm, FormContext } from "react-hook-form";
import { IStudentInfo, IStudentInfoDemographics, IStudentInfoAdmissions, NewStudentInfoDemographics, NewStudentInfoAdmissions } from "../../../interfaces/student/profile/studentInfo/IStudentInfo";
import { useSelector } from "react-redux";
import * as studentApi from "../../../api/search/studentSearchApi";
import { EmptyGuid } from "../../../utils/constants";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardSeparator: {
      marginBottom: theme.spacing(1),
    },
    button: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      whiteSpace: "nowrap",
      marginRight: theme.spacing(1)
    },
  })
);

const ProfileInfo = (props: any) => {
  const classes = useStyles({});
  const selectedStudent = useSelector((state: any) => state.student);
  const methods = useForm<any>({
    mode: "onBlur",
  });
  const { handleSubmit } = methods;
  const { leadId } = props;
  const [studentInfoDemographics, setStudentInfoDemographics] = React.useState<IStudentInfoDemographics | IStudentInfo>(props.currentProfileData);
  const [studentInfoAdmissions, setStudentInfoAdmissions] = React.useState<IStudentInfoAdmissions | IStudentInfo>(props.currentProfileData);
  const [newStudentInfoDemographicsModel, setNewStudentInfoDemographicsModel] = React.useState<
    IStudentInfoDemographics | any
  >(NewStudentInfoDemographics() as IStudentInfoDemographics);

  const [newStudentInfoAdmissionsModel, setNewStudentInfoAdmissionsModel] = React.useState<
    IStudentInfoAdmissions | any
  >(NewStudentInfoAdmissions() as IStudentInfoAdmissions);

  const setDemographics = (data: IStudentInfoDemographics | null) => {

    setStudentInfoDemographics((state: IStudentInfoDemographics | IStudentInfo) => {
      return { ...state, ...data };
    });
  };

  const setAdmissions = (data: IStudentInfoAdmissions | null) => {

    setStudentInfoAdmissions((state: IStudentInfoAdmissions | IStudentInfo) => {
      return { ...state, ...data };
    });
  };

  const loadFormData = () => {

    if (!!leadId) {
      studentApi.GetStudentProfileInfo(leadId, false).then(
        (response: any) => {
          if (!response || !response.result) {
            return;
          }
          let data = response.result;
          let enrollmentProgramData: IStudentInfo = {
            ...data
          };
          setStudentInfoAdmissions((state: IStudentInfoAdmissions | IStudentInfo) => {
            return { ...state, ...enrollmentProgramData };
          });
          setStudentInfoDemographics((state: IStudentInfoDemographics | IStudentInfo) => {
            return { ...state, ...enrollmentProgramData };
          });
        },
        (exception: any) => { }
      );
    }
    else {
      if (!props.isNewEnrollment || (selectedStudent && selectedStudent.studentId && !!props.isTransferredStudent)) {
        studentApi.GetStudentsProfileInfo([selectedStudent.studentId], false).then(
          (response: any) => {
            if (!response || !response.result) {
              return;
            }
            let data = response.result[0];
            let enrollmentProgramData: IStudentInfo = {
              ...data
            };
            setStudentInfoAdmissions((state: IStudentInfoAdmissions | IStudentInfo) => {
              return { ...state, ...enrollmentProgramData };
            });
            setStudentInfoDemographics((state: IStudentInfoDemographics | IStudentInfo) => {
              return { ...state, ...enrollmentProgramData };
            });
          },
          (exception: any) => { }
        );
      }
      else {
        setStudentInfoDemographics({ ...props.currentProfileData });
        setStudentInfoAdmissions({ ...props.currentProfileData });
        setNewStudentInfoDemographicsModel({ ...props.currentProfileData });
        setNewStudentInfoAdmissionsModel({ ...props.currentProfileData });
      }
    }


  }

  React.useEffect(() => {
    loadFormData();
  }, [selectedStudent, leadId, props.currentProfileData]);

  const onSubmit = (data: any) => {
    props.handleNext(studentInfoAdmissions, studentInfoDemographics);
  };

  const handleCancel = () => {
    props.setConfirmationModalProps((state: any) => {
      return {
        ...state,
        open: true,
        confirmationText: "Are you sure you want to reset your changes?",
        confirmationButton: "Yes",
        title: "Confirmation",
        onConfirmEvent: () => {
          loadFormData();
          props.setConfirmationModalProps((updatedState: any) => {
            return { ...updatedState, open: false };
          });
        },
        onCancelEvent: () => {
          props.setConfirmationModalProps((updatedState: any) => {
            return { ...updatedState, open: false };
          });
        },
      };
    });

  }

  methods.register("highSchoolId", {
    validate: () =>
      props.isHighSchoolMandatory == true && (!studentInfoAdmissions.highSchoolId || studentInfoAdmissions.highSchoolId === EmptyGuid)
        ? "High School is required."
        : true,
  });

  methods.register('highSchoolGraduationDate', {
    validate: () =>
      props.isHighSchoolMandatory == true && (!studentInfoAdmissions.highSchoolGraduationDate)
        ? 'High School Graduation Date is required.'
        : props.isHighSchoolMandatory == false || (studentInfoAdmissions.highSchoolGraduationDate).toString() !== 'Invalid Date'
          ? true
          : 'Invalid Date'
  });

  return (
    <FormContext {...methods}>
      <form key="profileInfoForm" onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.cardSeparator}>
          <Demographics
            isNewEnrollment={props.isNewEnrollment}
            model={(props.isNewEnrollment) ? newStudentInfoDemographicsModel : studentInfoDemographics as IStudentInfoDemographics}
            setModel={setDemographics}
          />
        </div>

        <div>
          <Admissions
            isNewEnrollment={props.isNewEnrollment}
            model={(props.isNewEnrollment) ? newStudentInfoAdmissionsModel : studentInfoAdmissions as IStudentInfoAdmissions}
            setModel={setAdmissions}
            isHighSchoolMandatory={props.isHighSchoolMandatory}
          />
        </div>
        <div>
          <Button
            disabled={props.activeStep === 0}
            onClick={props.handleBack}
            color="secondary"
            className={classes.button}
            variant="contained"
          >
            Back
          </Button>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => {
              handleSubmit(onSubmit);
            }}
            className={classes.button}
          >
            Next
          </Button>
          <Button
            onClick={handleCancel}
            className={classes.button}
            color="secondary"
            variant="contained"
            type="button"
          >
            Cancel
          </Button>
        </div>
      </form>
    </FormContext>
  );
};
export default ProfileInfo;
