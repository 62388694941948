import * as actionTypes from "../../constants/actions.constants";
import { Action } from "redux";
import { UserProfile } from "../../interfaces/user/UserProfile";
import { AppConfig } from "../../interfaces/AppConfig";
import { UserMenuItem } from "../../interfaces/user/UserMenuItem";
import { Campus } from "../../interfaces/systemCatalog/ICampus";
import tokenFunctions from "../../utils/tokenFunctions";
import { Notification } from "../../interfaces/common/notifications/Notification";
const initialState: any = {
    loggingIn: false,
    isSupportUser: false,
    appConfig: undefined,
    loggedIn: function (): boolean {
        return (
            this.user &&
            this.user.token &&
            !tokenFunctions.IsTokenExpired(this.user.token)
        );
    },
    user: null,
    user_backup : null,
    errorMessage: null
};

const sessionReducer = (state = initialState, action: Action<any>) => {
    switch (action.type) {
        case actionTypes.authConstants.SESSION_LOGIN_REQUEST: {
            return {
                ...state,
                loggingIn: true
            };
        }
        case actionTypes.supportUserConstants.SUPPORT_USER_CANCEL: {
            return initialState;
        }
        case actionTypes.authConstants.SESSION_LOGIN_SUCCESS: {
            return {
                ...state,
                loggingIn: false,
                user: (action as any).user as UserProfile
            };
        }

        case actionTypes.authConstants.SESSION_LOGIN_ERROR: {
            return {
                ...state,
                loggingIn: false,
                errorMessage: (action as any).error
            };
        }

        case actionTypes.appConfig.APP_CONFIG_LOADER: {
            return { ...state, appConfig: (action as any) as AppConfig };
        }

        case actionTypes.authConstants.SESSION_LOGOUT: {
            return {
                ...state,
                user: {
                    ...state.user,
                    token : null
                }
            };        }

        case actionTypes.userConstants.SESSION_MENU_RETRIEVED: {
            return {
                ...state,
                user: {
                    ...state.user,
                    menu: (action as any).menu as Array<UserMenuItem>
                }
            };
        }
        case actionTypes.userConstants.SESSION_CAMPUSES_SET: {
            return {
                ...state,
                user: {
                    ...state.user,
                    selectedCampus: (action as any).campus as Campus
                }
            };
        }
        case actionTypes.userConstants.SESSION_NOTIFICATION_RECEIVED: {
            let notif = (action as any).notification as Notification;

            let userNotifications: Array<Notification> = [];
            if (state.user && state.user.notifications) {
                userNotifications = state.user.notifications.slice();
                let index = state.user.notifications.findIndex((x: Notification) => x.type.toLowerCase() === notif.type.toLowerCase());
                if (index !== -1) {
                    //remove from array if exists
                    userNotifications = userNotifications.filter((n: Notification) => n.type.toLowerCase() !== notif.type.toLowerCase())
                }
            }
            //add new notification to array
            userNotifications.push(notif);
            return {
                ...state,
                user: {
                    ...state.user,
                    notifications: userNotifications
                }
            }

        }
        case actionTypes.userConstants.SESSION_NOTIFICATION_REMOVED: {
            let notifs = (action as any).notifications as Array<Notification>;

            let newNotifications = null;
            if (state.user && state.user.notifications && notifs) {
                newNotifications = state.user.notifications.filter(function (n: Notification) {
                    return !notifs.includes(n);
                })
            }
            return {
                ...state,
                user: {
                    ...state.user,
                    notifications: newNotifications
                }
            }
        }
        case actionTypes.userConstants.SESSION_PERSISTENT_NOTIFICATION_RECEIVED: {
            let notif = (action as any).notifications as Array<Notification>;
             let userPersistentNotifications: Array<Notification> = notif;
            return {
                ...state,
                user: {
                    ...state.user,
                    persistentNotifications: userPersistentNotifications
                }
            }

        }
        case actionTypes.userConstants.SESSION_PERSISTENT_NOTIFICATION_REMOVED: {
        

            let newNotifications = null;
            return {
                ...state,
                user: {
                    ...state.user,
                    persistentNotifications: newNotifications
                }
            }
        }
        case actionTypes.userTermsConstants.NEED_APPROVAL: {
            return {
                ...state,
                loggingIn: false,
                user: null,
                user_backup : (action as any).payload as UserProfile
            };
        }
        case actionTypes.supportUserConstants.SUPPORT_USER_FOUND: {
            return {
                ...state,
                loggingIn: false
            };
        };
        default: {
            return state;
        }
    }
};

export default sessionReducer;
