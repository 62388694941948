import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useState, forwardRef, CSSProperties } from "react";
import { Enrollment, TitleIVSapStatus } from "../../../interfaces/student/academics/Enrollment";
import green from "@material-ui/core/colors/green";
import teal from "@material-ui/core/colors/teal";
import yellow from "@material-ui/core/colors/yellow";
import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";
import Fab from "@material-ui/core/Fab";
import ChevronLeftOutlinedIcon from "@material-ui/icons/ChevronLeftOutlined";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import { Paper, LinearProgress, Divider } from "@material-ui/core";
import useRouter from "../../../utils/useRouter";
import Navigation from "../../../components/Navigation/Navigation";
import { useMediaQuery } from "react-responsive";
import Fade from "@material-ui/core/Fade";
import EnrollmentSelector from "../EnrollmentSelector/EnrollmentSelector";
import MenuIcon from "@material-ui/icons/Menu";

type INavBarState = {};

type INavBarProps = {
	pages?: any;
	children?: React.ReactNode;
	className?: string;
	onMobileClose?: any;
	openMobile?: boolean;
};

const styles = createStyles({
	button: {},
	sapPassed: {
		root: {
			height: 10,
			backgroundColor: teal[700]
		},
		bar: {
			borderRadius: 20,
			backgroundColor: grey[500]
		}
	},
	sapWarning: {
		root: {
			height: 10,
			backgroundColor: yellow[400]
		},
		bar: {
			borderRadius: 20,
			backgroundColor: grey[500]
		}
	},
	sapIneligible: {
		root: {
			height: 10,
			backgroundColor: red[700]
		},
		bar: {
			borderRadius: 20,
			backgroundColor: grey[500]
		}
	}
});

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			height: "100%",
			overflowY: "hidden",
			position: "relative"
		},
		mainText: {
			color: theme.palette.text.primary
		},
		secondaryText: {
			color: theme.palette.text.secondary
		},
		content: {
			padding: theme.spacing(1),
			overflowY: "hidden",
			overflowX: "hidden",
			height: "100%"
		},
		divider: {
			marginTop: theme.spacing(2),
			backgroundColor: theme.palette.icon.secondary
		},
		navigation: {
			overflowX: "hidden",
			marginTop: theme.spacing(1),
			height: "100%",
			maxHeight: "78%"
		},
		fabDefault: {
			position: "absolute",
			top: theme.spacing(1.2),
			left: theme.spacing(28),
			zIndex: 10,
			width: 30,
			height: 30,
			minHeight: 0,
			minWidth: 0
			// boxShadow: "none"
		},
		fabMinimized: {
			position: "absolute",
			top: theme.spacing(1.2),
			left: theme.spacing(1.5),
			zIndex: 10,
			width: 30,
			height: 30,
			minHeight: 0,
			minWidth: 0
		},
		fabColor: {
			color: theme.palette.icon.primary,
			backgroundColor: theme.palette.white,
			"&:hover": {
				color: theme.palette.iconSecondary
			}
		},
		navBarMin: {
			zIndex: 3,
			width: 50,
			flex: "0 0 auto",
			overflowX: "hidden",
			paddingTop: theme.spacing(4)
		},
		hidden: {
			visibility: "hidden"
		},
		hiddenHeight: {
			height: 75
		}
	})
);

const NavBar = (props: INavBarProps) => {
	const { openMobile, onMobileClose, className, pages, ...rest } = props;
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1285px)" });
	const classes = useStyles({});
	const router = useRouter();

	const session = useSelector((state: any) => state.session);
	const student = useSelector((state: any) => state.student);

	const [enrollment, setEnrollment] = useState<Enrollment | null>(
		student ? student.enrollments[0] : null
	);

	const [isNavBarMinimized, setNavBarState] = useState<boolean>(false);
	const [minimizeButtonVisible, setMinimizeButtonState] = useState<boolean>(false);

	React.useEffect(
		() => {
			if (openMobile) {
				onMobileClose && onMobileClose();
			}

			setNavBarState && setNavBarState(isTabletOrMobile);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[isTabletOrMobile] /*, [router.location.pathname]*/
	);

	const Chevron = isNavBarMinimized ? MenuIcon : ChevronLeftOutlinedIcon;
	const handleClick = () => {
		setNavBarState(isNavBarMinimized => !isNavBarMinimized);
	};
	const navbarContent = (
		<Fade in={true} timeout={300}>
			<div className={clsx({ [classes.content]: !isNavBarMinimized })}>
				{!isNavBarMinimized && <EnrollmentSelector showProgressBar={true}></EnrollmentSelector>}
				{!isNavBarMinimized && <Divider className={classes.divider}></Divider>}
				<nav className={classes.navigation}>
					{pages && (
						<Navigation
							component='div'
							key={pages.title}
							pages={pages.pages}
							title={pages.title}
							minimized={isNavBarMinimized}
						/>
					)}
				</nav>
			</div>
		</Fade>
	);

	return (
		<React.Fragment>
			<Paper
				onPointerEnter={() => setMinimizeButtonState(true)}
				onPointerLeave={() => setMinimizeButtonState(false)}
				{...rest}
				className={clsx(
					classes.root,
					{ [className as string]: !isNavBarMinimized },
					{ [classes.navBarMin]: isNavBarMinimized }
				)}
				elevation={2}
				square>
				{navbarContent}
			</Paper>
			<Fade in={true}>
				<Fab
					size='small'
					onPointerEnter={() => setMinimizeButtonState(true)}
					onPointerLeave={() => setMinimizeButtonState(false)}
					onClick={handleClick}
					color='inherit'
					aria-label='add'
					className={clsx(
						{ [classes.fabDefault]: !isNavBarMinimized },
						{ [classes.fabMinimized]: isNavBarMinimized },
						{
							[classes.hidden]:
								!isTabletOrMobile && !minimizeButtonVisible && !isNavBarMinimized
						},
						classes.fabColor
					)}>
					<Chevron />
				</Fab>
			</Fade>
		</React.Fragment>
	);
};

export default NavBar;
