import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Button } from '@material-ui/core';
import * as authentication from '../../resources/images/authentication.png';
import * as page_not_found  from '../../resources/images/page_not_found.png';
import * as server_down from '../../resources/images/server_down.png';

const useStyles = makeStyles((theme: any) => ({
	root: {
		padding: theme.spacing(3),
		margin: theme.spacing(4),
		paddingTop: '10vh',
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center'
	},
	imageContainer: {
		marginTop: theme.spacing(6),
		display: 'flex',
		justifyContent: 'center'
	},
	image: {
		maxWidth: '100%',
		width: 560,
		maxHeight: 300,
		height: 'auto'
	},
	buttonContainer: {
		marginTop: theme.spacing(6),
		display: 'flex',
		justifyContent: 'center'
	}
}));

type IErrorProps = {
  errorCode: number;
  errorMessage: string;
};

const Error = (props: IErrorProps) => {
	const classes = useStyles({});

	return (
		<Paper className={classes.root}>
      
			<Typography align="center" variant="subtitle1">
        Ooops, something went terribly wrong!
			</Typography>
      
			<Typography align="center" variant="h1">
				{props.errorMessage}
			</Typography>
     
			<div className={classes.imageContainer}>
				{props.errorCode === 500 && (
					<img
						alt="Server Error"
						className={classes.image}
						src={String(server_down)}
					/>
				)}
				{props.errorCode === 401 && (
					<img
						alt="Unauthorized"
						className={classes.image}
						src={String(authentication)}
					/>
				)}
				{props.errorCode === 404 && (
					<img
						alt="Not Found"
						className={classes.image}
						src={String(page_not_found)}
					/>
				)}
			</div>
			<div className={classes.buttonContainer}>
				<Button
					color="primary"
					component={RouterLink}
					to="/"
					variant="outlined"
				>
          Back to home
				</Button>
			</div>
		</Paper>
	);
};

export default Error;
