import React, { useState, forwardRef } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import {
    autoCompleteActions as actions,
    loadAutoCompleteDataOptions,
} from "../../../actions/autoCompleteActions";
import { ApiTableConfigItem } from "../../../interfaces/ApiTableConfigItem";
import {
    createStyles,
    withStyles
} from "@material-ui/core/styles";
import { UserProfile } from "../../../interfaces/user/UserProfile";
import {
    AutoSizer,
    Column,
    Table,
    TableCellRenderer,
    TableHeaderProps,
    TableCellDataGetter,
    TableCellDataGetterParams,
    InfiniteLoader,
    IndexRange,
    TableRowRenderer,
    TableHeaderRenderer,
} from "react-virtualized";
import { TableCell, WithStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import CenterLoader from "../Loaders/CenterLoader";

declare module "@material-ui/core/styles/withStyles" {
    // Augment the BaseCSSProperties so that we can control jss-rtl
    interface BaseCSSProperties {
        /*
         * Used to control if the rule-set should be affected by rtl transformation
         */
        flip?: boolean;
    }
}

const styles = (theme: any) =>
    createStyles({
        flexContainer: {
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
        },
        table: {
            // temporary right-to-left patch, waiting for
            // https://github.com/bvaughn/react-virtualized/issues/454
            "& .ReactVirtualized__Table__headerRow": {
                flip: false,
                paddingRight: theme.direction === "rtl" ? "0px !important" : undefined,
                backgroundColor: theme.palette.site.secondary,
            },
        },
        tableRow: {
            cursor: "pointer",
        },
        tableRowHover: {
            "&:hover": {
                backgroundColor: theme.palette.table.row.hover,
            },
        },
        tableCell: {
            flex: 1,
        },
        noClick: {
            cursor: "initial",
        },
    });

interface ColumnData {
    dataKey: string;
    cellDataGetter?: TableCellDataGetter;
    cellRenderer?: TableCellRenderer;
    headerRenderer?: TableHeaderRenderer;
    label: string;
    numeric?: boolean;
    width: number;
}

interface Row {
    index: number;
}

interface MuiVirtualizedTableProps extends WithStyles<typeof styles> {
    config: ApiTableConfigItem;
    columns: ColumnData[];
    valueFilter?: any;
    headerHeight?: number;
    onRowClick?: () => void;
    rowCount?: number;
    rowGetter?: (row: Row) => any;
    cellDataGetter?: (params: TableCellDataGetterParams) => any;
    rowHeight?: number;
    rowRenderer?: TableRowRenderer;
}

const MuiVirtualizedTable = forwardRef(
    (props: MuiVirtualizedTableProps, ref) => {
        const {
            classes,
            columns,
            rowHeight,
            headerHeight,
            cellDataGetter,
            ...tableProps
        } = props;
        const config: ApiTableConfigItem = { ...props.config };
        const rowsToTake = config.rowsInBatch ? config.rowsInBatch : 30;
        const [records, setRecords] = React.useState<any[]>([]);
        //const [startIndex, setStartIndex] = useState<number>(0);
        //const [stopIndex, setStopIndex] = useState<number>(rowsToTake);
        let _visibleStartIndex = React.useRef(0);
        let _table: Table | null = null;
        const loggedInUser = useSelector(
            (state: any) => state.session.user
        ) as UserProfile;
        let uniqueKey = config.isCampusSpecific
            ? loggedInUser.selectedCampus.id + "_" + config.acId
            : config.acId;

        const [total, setTotal] = useState(0);
        const [hasMoreRows, setHasMoreRows] = useState(true);
        const [loading, setLoading] = useState(false);

        uniqueKey = config.isUserSpecific
            ? loggedInUser.userId + "_" + config.acId
            : config.acId;

        const handleChangeTotal = (event: any, total: any) => {
            setTotal(total);
        };

        const loadMoreRows = loading
            ? ({ startIndex, stopIndex }: IndexRange) => {
                return Promise.resolve({} as any);
            }
            : ({ startIndex, stopIndex }: IndexRange) => {

                const requestData: loadAutoCompleteDataOptions = {
                    route: config.route,
                    method: config.requestType,
                    params: { ...config.params, skip: startIndex, take: rowsToTake },
                    data: { ...config.body, skip: startIndex, take: rowsToTake },
                };
                return getPartialData(requestData);
            };

        const autoCompleteData = useSelector(
            (state: any) => state.ac_cache.data[uniqueKey]
        );
        const cacheTime = config.cacheTime ? config.cacheTime : 30;

        React.useImperativeHandle(ref, () => ({
            hardRefresh() {
                (() => {
                    setRecords([]);
                    setTotal(0);
                    setHasMoreRows(true);
                    if (_table) {
                        _table.forceUpdateGrid();
                    }
                    loadMoreRows({
                        startIndex: 0,
                        stopIndex: rowsToTake
                    })

                })();
            },
            refresh() {
                (() => {
              
                    if (_table) {
                        _table.forceUpdateGrid();
                    }
                    if (_visibleStartIndex.current >= 0) {
                        loadMoreRows({
                            startIndex: _visibleStartIndex.current,
                            stopIndex: rowsToTake
                        })
                    }
                })();
            },
        }));

        React.useEffect(() => {
            (() => {
                setRecords([]);
                setTotal(0);
                setHasMoreRows(true);
                const requestData: loadAutoCompleteDataOptions = {
                    route: config.route,
                    method: config.requestType,
                    params: { ...config.params, skip: 0, take: rowsToTake },
                    data: { ...config.body, skip: 0, take: rowsToTake },
                };

                return getPartialData(requestData);
            })();
        }, [config.params, props.config.body]);

        const getPartialData = (
            params: loadAutoCompleteDataOptions,
            refresh?: boolean
        ) => {
            
            setLoading(true);
            return actions.loadAutoCompleteData(params).then((data: any) => {
                const opts: any = config.optionsMappedFx(data);
                if ( opts.data) {
                    let skip: number = params.params.skip;
                    let dataLength: number = opts.data.length;
    
                    setRecords((records: any[]) => {
                        var deleted = Array.prototype.splice.apply(records, [
                            skip,
                            dataLength,
                            ...opts.data,
                        ]);
                        return records;
                    });
    
    
                    setTotal(opts.total);
                    setHasMoreRows(opts.hasMoreData);
                    setLoading(false);
                    return opts.data as any;
                }
                setLoading(false);
                return [] as any;
            });
        };
        const getRowClassName = ({ index }: Row) => {
            const { classes, onRowClick } = props;
            return clsx(classes.tableRow, classes.flexContainer, {
                [classes.tableRowHover]: index !== -1 && onRowClick != null,
            });
        };

        const mainCellRenderer: TableCellRenderer = ({ cellData, columnIndex }) => {
            const { columns, classes, rowHeight, onRowClick } = props;
            return (
                <TableCell
                    component="div"
                    className={clsx(classes.tableCell, classes.flexContainer, {
                        [classes.noClick]: onRowClick == null,
                    })}
                    variant="body"
                    style={{ height: rowHeight }}
                    align={
                        (columnIndex != null && columns[columnIndex].numeric) || false
                            ? "right"
                            : "left"
                    }
                >
                    {cellData}
                </TableCell>
            );
        };

        const mainHeaderRenderer = ({
            label,
            columnIndex,
        }: TableHeaderProps & { columnIndex: number }) => {
            const { headerHeight, columns, classes } = props;

            return (
                <TableCell
                    component="div"
                    className={clsx(
                        classes.tableCell,
                        classes.flexContainer,
                        classes.noClick
                    )}
                    variant="head"
                    style={{ height: headerHeight }}
                    align={columns[columnIndex].numeric || false ? "right" : "left"}
                >
                    <span>{label}</span>
                </TableCell>
            );
        };

        if (config.lazyLoad) {
            const rowCount = hasMoreRows ? records.length + 1 : records.length;
            // Only load 1 page of items at a time.
            // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.


            // Every row is loaded except for our loading indicator row.
            //const isRowLoaded = ({ index }: { index: number }) =>
            //      !hasMoreRows || index < records.length;

            const isRowLoaded = ({ index }: { index: any }) => {
                return !!records[index];
            }
            // Render a list item or a loading indicator.
            const rowRenderer: TableRowRenderer = ({
                className,
                columns,
                index,
                key,
                onRowClick,
                onRowDoubleClick,
                onRowMouseOut,
                onRowMouseOver,
                onRowRightClick,
                style,
                rowData,
            }) => {
                if (!isRowLoaded({ index })) {
                    return (
                        <div key={key} style={style}>
                            <Skeleton style={{ margin: 10 }} />
                        </div>
                    );
                } else {
                    const a11yProps: any = { "aria-rowindex": index + 1 };

                    if (
                        onRowClick ||
                        onRowDoubleClick ||
                        onRowMouseOut ||
                        onRowMouseOver ||
                        onRowRightClick
                    ) {
                        a11yProps["aria-label"] = "row";
                        a11yProps.tabIndex = 0;

                        if (onRowClick) {
                            a11yProps.onClick = (event: any) =>
                                onRowClick({ event, index, rowData });
                        }
                        if (onRowDoubleClick) {
                            a11yProps.onDoubleClick = (event: any) =>
                                onRowDoubleClick({ event, index, rowData });
                        }
                        if (onRowMouseOut) {
                            a11yProps.onMouseOut = (event: any) =>
                                onRowMouseOut({ event, index, rowData });
                        }
                        if (onRowMouseOver) {
                            a11yProps.onMouseOver = (event: any) =>
                                onRowMouseOver({ event, index, rowData });
                        }
                        if (onRowRightClick) {
                            a11yProps.onContextMenu = (event: any) =>
                                onRowRightClick({ event, index, rowData });
                        }
                    }

                    return (
                        <div
                            {...a11yProps}
                            className={className}
                            key={key}
                            role="row"
                            style={style}
                        >
                            {columns}
                        </div>
                    );
                }
            };
            const rr = props.rowRenderer ? props.rowRenderer : rowRenderer;
            const tableData = <InfiniteLoader
                isRowLoaded={isRowLoaded}
                loadMoreRows={loadMoreRows}
                rowCount={100000}
            >
                {({ onRowsRendered, registerChild }) => (
                    <AutoSizer>
                        {({ height, width }) => (
                            <Table
                                height={height}
                                width={width}
                                rowHeight={rowHeight!}
                                gridStyle={{
                                    direction: "inherit",
                                }}
                                headerHeight={headerHeight!}
                                className={classes.table}
                                rowCount={total}
                                rowGetter={({ index }) => records[index]}
                                rowClassName={getRowClassName}
                                ref={table => {
                                    _table = table;
                                    registerChild(table);
                                }}
                                onRowsRendered={({ startIndex, stopIndex }) => {
                                    _visibleStartIndex.current = startIndex;
                                    onRowsRendered({ startIndex, stopIndex })
                                }}
                                rowRenderer={rr}
                            >
                                {columns.map(
                                    (
                                        {
                                            dataKey,
                                            cellDataGetter,
                                            cellRenderer,
                                            headerRenderer,
                                            ...other
                                        },
                                        index
                                    ) => {
                                        
                                        let dataGetter = !cellDataGetter
                                            ? props.cellDataGetter
                                            : cellDataGetter;
                                        let dataCellRenderer = !cellRenderer
                                            ? mainCellRenderer
                                            : cellRenderer;
                                        let dataHeaderRenderer = !headerRenderer
                                            ? (headerProps: TableHeaderProps) =>
                                                mainHeaderRenderer({
                                                    ...headerProps,
                                                    columnIndex: index,
                                                })
                                            : headerRenderer;
                                        return (
                                            <Column
                                                key={dataKey}
                                                headerRenderer={dataHeaderRenderer}
                                                flexGrow={1}
                                                className={classes.flexContainer}
                                                cellRenderer={dataCellRenderer}
                                                cellDataGetter={dataGetter}
                                                dataKey={dataKey}
                                                {...other}
                                            />
                                        );
                                    }
                                )}
                            </Table>
                        )}
                    </AutoSizer>
                )}
            </InfiniteLoader>
            return (records.length == 0 && loading ? <CenterLoader /> : records.length > 0 ? tableData : <TableCell variant="body" component="div">No records to display</TableCell>);
        } else {
            return (
                
                <AutoSizer>
                    {({ height, width }) => (
                        <Table
                            height={height}
                            width={width}
                            rowHeight={rowHeight!}
                            gridStyle={{
                                direction: "inherit",
                            }}
                            headerHeight={headerHeight!}
                            className={classes.table}
                            rowCount={records.length}
                            rowGetter={({ index }) => records[index]}
                            rowClassName={getRowClassName}
                        >
                            
                            {columns.map(
                                (
                                    { dataKey, cellDataGetter, cellRenderer, ...other },
                                    index
                                ) => {
                                    let dataGetter = !cellDataGetter
                                        ? props.cellDataGetter
                                        : cellDataGetter;
                                    let dataCellRenderer = !cellRenderer
                                        ? mainCellRenderer
                                        : cellRenderer;
                                    let dataHeaderRenderer = (headerProps: TableHeaderProps) =>
                                        mainHeaderRenderer({
                                            ...headerProps,
                                            columnIndex: index,
                                        });
                                    return (
                                        <Column
                                            key={dataKey}
                                            headerRenderer={dataHeaderRenderer}
                                            flexGrow={1}
                                            className={classes.flexContainer}
                                            cellRenderer={dataCellRenderer}
                                            cellDataGetter={dataGetter}
                                            dataKey={dataKey}
                                            {...other}
                                        />
                                    );
                                }
                            )}
                        </Table>
                    )}
                </AutoSizer>
            );
        }
    }
);

const ApiVirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

ApiVirtualizedTable.defaultProps = {
    headerHeight: 48,
    rowHeight: 48,
};

export default ApiVirtualizedTable;
