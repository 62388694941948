import ITerm from "../../../interfaces/setup/terms/ITerm";
import ITermDetails from "../../../interfaces/setup/terms/ITermDetails";
import { ITermsSearch } from "../../../interfaces/setup/terms/ITermsSearch";
import API from "../../apiWrapper";

export const getTermsList = async (
	params: ITermsSearch
): Promise<{ data: Array<ITerm>; hasMoreData: boolean; total: number }> => {
	const response = await API().post("AcademicRecords/TermsList", {
		search: params.search ?? "",
		take: params.take ?? 10,
		skip: params.skip ?? 0,
		showAll: params.showAll ?? false,
		sort: params.sort ?? "desc",
	});
	return response.data;
};

export const saveTerm = async (data: ITermDetails): Promise<ITermDetails> => {
	const response = await API().post<ITermDetails>(
		"AcademicRecords/TermsList/Save",
		{
			...data,
			startDate:
				data.startDate &&
				`${data.startDate.getFullYear()}-${data.startDate.getMonth() + 1
				}-${data.startDate.getDate()}`,
			endDate:
				data.endDate &&
				`${data.endDate.getFullYear()}-${data.endDate.getMonth() + 1
				}-${data.endDate.getDate()}`,
		}
	);
	return response.data;
};

export const saveTermExist = async (data: ITermDetails): Promise<ITermDetails> => {

	const response = await API().post<ITermDetails>(
		"AcademicRecords/TermsList/Save",
		{
			...data,
		}
	);
	return response.data;
};

export const getDetails = async (id: string): Promise<ITermDetails> => {
	const response = await API().get<ITermDetails>(
		"AcademicRecords/TermsList/Details",
		{
			params: { id },
		}
	);
	return response.data;
};

export const deleteTerm = async (id: string): Promise<string> => {
	const response = await API().delete<string>(
		"AcademicRecords/TermsList/Delete",
		{
			params: { id },
		}
	);
	return response.data;
};
