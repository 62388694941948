export interface ISuspension {
    studentEnrollmentId: string;
    studentStatusChangeId?: string;
    studentSuspensionId?: string;
    statusCodeId: string;
    startDate: Date | null;
    endDate: Date | null;
    reason: string;
    modifiedDate: Date;
    modifiedUser?: string;
    isEditMode?: boolean;
  }
  
  export function NewISuspensionDetail(studentEnrollmentId: string, statusCodeId: string) {
    return {
      studentEnrollmentId: studentEnrollmentId,
      studentStatusChangeId: "",
      studentSuspensionId: "00000000-0000-0000-0000-000000000000",
      statusCodeId: statusCodeId,
      startDate: null,
      endDate: null,
      reason: "",
      modifiedDate: new Date(),
      modifiedUser: "",
      isEditMode: false,
    } as ISuspension;
  }