import API from "../../apiWrapper";
import { IDisbursement, ISearchTitleIVAwardsParams } from "../../../interfaces/student/financials/IDisbursement";
export const getDisbursementDetails = (studentAwardId: string) => {
	return API()
		.get("/FinancialAid/StudentAward/GetDisbursementDetailByStudentAwardId", {
			params: {
				studentAwardId,
			},
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return null;
			}
		);
};

export const deleteDisbursementDetails = (
	disbursementDetailId: string,
	campusId: string
) => {
	return API()
		.delete("/FinancialAid/StudentAward/DeleteDisbursementDetail", {
			data: {
				entityId: disbursementDetailId,
				campusId: campusId,
			},
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};

export const GetDisbursementDetail = (disbursementId: string) => {
	return API()
		.get("/FinancialAid/StudentAward/GetDisbursementDetailById", {
			params: {
				disbursementId,
			},
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};

export const UpdateDisbursement = (disbursement: IDisbursement) => {
	return API()
		.post("/FinancialAid/StudentAward/UpdateDisbursement", disbursement, {
			responseType: "json",
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};

export const GetNonTitleIVAwardsForPost = (param: ISearchTitleIVAwardsParams) => {
	return API()
		.post("/FinancialAid/StudentAward/GetNonTitleIVAwardsForPost", param)
		.then(
			(res: any) => {
				if (res && res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};
