import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import GeneralInfo from "../profile/enrollments/GeneralInfo";
import TransferDetails from "../profile/enrollments/TransferDetails";
import AutomaticCharging from "../profile/enrollments/AutomaticCharging";
import { Button } from "@material-ui/core";
import { useForm, FormContext } from "react-hook-form";
import {
  EnrollmentGeneral,
  EnrollmentTransferDetails,
  AutomaticChargingDetails,
  EnrollmentProgram,
} from "../../../interfaces/student/academics/EnrollmentProgram";
import IProgramVersionSAPPolicyInfo from "../../../interfaces/academicRecords/IProgramVersionSAPPolicyInfo";
import { EmptyGuid } from "../../../utils/constants";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardSeparator: {
      marginBottom: theme.spacing(1),
    },

    button: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      whiteSpace: "nowrap",
      marginRight: theme.spacing(1),
    },
  })
);
type EnrollmentInfoProps = {
  activeStep: number;
  steps: number;
  isTransferredStudent?: boolean;
  handleBack: () => void;
  handleNext: (
    enrollmentGeneral: EnrollmentGeneral,
    automaticChargingDetails: AutomaticChargingDetails,
    enrollmentTransferDetails: EnrollmentTransferDetails
  ) => void;
  isNewEnrollmentTransfer: boolean;
  programVersionId?: string;
  programVersionSAPPolicyInfo?: IProgramVersionSAPPolicyInfo;
  scheduleId?: string;
  isNewEnrollment: boolean;
  confimationModalProps?: any;
  setConfirmationModalProps?: any;
  notificationModal?: any;
  setNotificationModalProps?: any;
  setSnackBar:(snackBarProps:any) => void;
  setIsTransferredStudent? : any;
  isNewStudentWorkflow?: boolean;
  setHighSchoolMandatory?: any;
  enrollmentGeneralModelPassthrough?: any;
  enrollmentTransferDetailsModelPassthrough?: any;
};

const EnrollmentInfo = (props: EnrollmentInfoProps) => {
  const classes = useStyles({});
  const methods = useForm<any>({
    mode: "onBlur",
  });

  const { enrollmentGeneralModelPassthrough, enrollmentTransferDetailsModelPassthrough } = props;
 
  const [firstError, setFirstError] = React.useState<boolean>(false);
  const handleFirstTimeInSchoolCheck = () => {
    let firstTimeInTHISSchool = enrollmentGeneral.isFirstTimeInSchool?.id;
    let firstTimePostSecSchool = enrollmentGeneral.isFirstTimePostSecSchool?.id;
    let transferHoursThis = enrollmentTransferDetails.transferHoursThis? enrollmentTransferDetails.transferHoursThis: 0 ;
    let transferHoursOther = enrollmentTransferDetails.transferHoursOther? enrollmentTransferDetails.transferHoursOther: 0;
    let text = ''
    if(firstTimeInTHISSchool == 1 && transferHoursThis > 0)
    { setFirstError(true);
      text = "A student cannot have transfer hours from this school and be first time." 
      handleFirstTimeCheck(text); 
      setFirstError(true);
    }
    else if( firstTimePostSecSchool == 1 && transferHoursOther > 0 )
    {   setFirstError(true); 
      text = "A student cannot have transfer hours from other schools and be first time at any post secondary school." 
      handleFirstTimeCheck(text);
    }else{
      setFirstError(false); 
    }
};

const handleFirstTimeCheck = (text: string) => {
  props.setNotificationModalProps((state: any) => {
    
    return {
      ...state,
      open: true,
      notificationText: text,
      notificationButton: "Ok",
      title: "Transfer hours issue",
      onConfirmEvent: () => {
        props.setNotificationModalProps((updatedState: any) => {
          return { ...updatedState, open: false };
        });
      },
      onCloseEvent: () => {
        props.setNotificationModalProps((updatedState: any) => {
          return { ...updatedState, open: false };
        });
      },

    };
  });
  
}

  const { handleSubmit } = methods;
  const [enrollmentGeneral, setEnrollmentGeneral] = React.useState<
    EnrollmentGeneral
  >({
    enrollmentId: "",
    statusCodeId: "",
    sysStatusId: 0,
    status: "",
    attendanceTypeId: "",
    startDate: null,
    enrollmentDate: null,
    reEnrollmentDate: null,
    graduationDate: null,
    revisedGraduationDate: null,
    updatedGraduationDate: null,
    entranceInterviewDate: null,
    isFirstTimeInSchool: null,
    thirdPartyContract: false,
    isFirstTimePostSecSchool: null,
    lda: null,
    cohortStartDate: null,
    scheduleId: props.scheduleId,
    titleIvAuthorization: false,
    covid19DropWithAttestation:false,
  } as EnrollmentGeneral);

  const [
    automaticChargingDetails,
    setAutomaticChargingDetails,
  ] = React.useState<AutomaticChargingDetails>({
    billingMethodDescrip: "",
    incrementId: "",
    programVersionId: props.programVersionId,
    billingMethodId:""
  } as AutomaticChargingDetails);

  React.useEffect(() => {
    let modelUpdate = enrollmentGeneral;
    modelUpdate.scheduleId = props.scheduleId;

    setEnrollmentGeneral({ ...modelUpdate });
  }, [props.scheduleId]);
  const [
    enrollmentTransferDetails,
    setEnrollmentTransferDetails,
  ] = React.useState<EnrollmentTransferDetails>({
    transferDate: "",
    transferHoursOther: 0,
    transferHoursThis: 0,
    transferFromProgramId: "",
    transferFromStuEnrollId: "",
    moreThenOneEnrollment: false,
  } as EnrollmentTransferDetails);

  const onSubmit = (data: any) => {
    if(!firstError){
    props.handleNext(
      enrollmentGeneral,
      automaticChargingDetails,
      enrollmentTransferDetails
    );
  }
  };

  React.useEffect(() => {
    if (enrollmentGeneralModelPassthrough) {
      setEnrollmentGeneral(enrollmentGeneralModelPassthrough);
    }
  }, [enrollmentGeneralModelPassthrough]);

  React.useEffect(() => {
    if (enrollmentTransferDetailsModelPassthrough) {
      setEnrollmentTransferDetails(enrollmentTransferDetailsModelPassthrough);
    }
  }, [enrollmentTransferDetailsModelPassthrough]);

  const loadFormData = () => {
    let defaultEnrollmentGeneral: EnrollmentGeneral = {
      enrollmentId: "",
      statusCodeId: "",
      sysStatusId: 0,
      status: "",
      attendanceTypeId: "",
      startDate: null,
      enrollmentDate: null,
      reEnrollmentDate: null,
      graduationDate: null,
      revisedGraduationDate: null,
      updatedGraduationDate: null,
      entranceInterviewDate: null,
      isFirstTimeInSchool: null,
      thirdPartyContract: false,
      incarceratedWithinSixMonth: false,
      isFirstTimePostSecSchool: null,
      lda: null,
      educationCompletedId: null,
      scheduleId: props.scheduleId,
      degcertseekingid: "",
      distanceEducationStatusId: "",
      academicAdvisor: "",
      adminCriteriaId: "",
      faadvisorId: "" ,
      thirdPartyPayer: "",
      sapId: "",
      titleIvAuthorization: false,
      covid19DropWithAttestation:false,
    };

    let defaultTransferDetails: EnrollmentTransferDetails = {
      transferDate: "",
      transferHoursOther: 0,
      transferHoursThis: 0,
      transferFromProgramId: "",
      transferFromStuEnrollId: "",
      moreThenOneEnrollment: false,
    };

    let defaultAutomaticCharging: AutomaticChargingDetails = {
      billingMethodDescrip: "",
      incrementId: "",
      programVersionId: props.programVersionId,
      billingMethodId:"",
      usesActualHours:false
    };
    setEnrollmentGeneral({
      ...defaultEnrollmentGeneral,
    });   

    setEnrollmentTransferDetails({ ...defaultTransferDetails });

    setAutomaticChargingDetails({
      ...defaultAutomaticCharging,
    });
  };

  methods.register("educationCompletedId", {
    validate: () =>
      !enrollmentGeneral.educationCompletedId || enrollmentGeneral.educationCompletedId=="" || enrollmentGeneral.educationCompletedId === EmptyGuid
        ? "Education CompletedId is required."
        : true,
  });

  const handleCancel = () => {
    props.setConfirmationModalProps((state: any) => {
      return {
        ...state,
        open: true,
        confirmationText: "Are you sure you want to reset your changes?",
        confirmationButton: "Yes",
        title: "Confirmation",
        onConfirmEvent: () => {
          loadFormData();
          props.setConfirmationModalProps((updatedState: any) => {
            return { ...updatedState, open: false };
          });
        },
        onCancelEvent: () => {
          props.setConfirmationModalProps((updatedState: any) => {
            return { ...updatedState, open: false };
          });
        },
      };
    });
    
  }

  return (
    <FormContext {...methods}>
      <form key="enrollmentInfoForm" onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.cardSeparator}>
          <GeneralInfo
            general={enrollmentGeneral}
            setGeneral={setEnrollmentGeneral}
            isNewEnrollment={props.isNewEnrollment}
            programVersionSAPPolicyInfo={props.programVersionSAPPolicyInfo}
            loadOnlyFutureStartStatus={true}
            isNewStudentWorkflow={props.isNewStudentWorkflow}
            showMandatory = {true}
            setHighSchoolMandatory={props.setHighSchoolMandatory}
          />
        </div>
       <div className={classes.cardSeparator}>
            <TransferDetails
              transferDetails={enrollmentTransferDetails}
              setTransfer={setEnrollmentTransferDetails}
              isNewEnrollmentTransfer={props.isNewEnrollmentTransfer}
              isNewEnrollment={props.isNewEnrollment}
              programVersionId={props.programVersionId ?? ""}
              setIsTransferredStudent={props.setIsTransferredStudent}
              isNewStudentWorkflow={props.isNewStudentWorkflow}
            />
          </div>
      
        <div>
          <AutomaticCharging
            automaticChargingDetails={automaticChargingDetails}
            setAutomaticCharging={setAutomaticChargingDetails}
            programVersionId={props.programVersionId ?? ""}
            setSnackBar={props.setSnackBar}
            isNewEnrollment={true}
          />
        </div>
        <div>
          <Button
            disabled={props.activeStep === 0}
            onClick={props.handleBack}
            className={classes.button}
            color="secondary"
            variant="contained"
          >
            Back
          </Button>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => {
              handleFirstTimeInSchoolCheck();
              handleSubmit(onSubmit);
            }}
            className={classes.button}
          >
            Next
          </Button>
          <Button
            onClick={handleCancel}
            className={classes.button}
            color="secondary"
            variant="contained"
            type="button"
          >
            Cancel
          </Button>
        </div>
      </form>
    </FormContext>
  );
};
export default EnrollmentInfo;
