import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import {
	MuiPickersUtilsProvider,
	KeyboardTimePicker,
	KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "@material-ui/core/TextField";
import * as StudentGradesApi from "../../../../src/api/student/academics/StudentGradesApi";
import { useSelector } from "react-redux";
import { StudentAttempts } from "../../../../src/interfaces/student/academics/StudentAttempts";

import { useFormContext, FieldError } from "react-hook-form";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import { security } from "../../../constants/Security/securityConstants";
import AccessManager from "../../../components/security/AccessManager";
import { string } from "prop-types";
import { StudentGrade } from "../../../interfaces/student/academics/StudentGrade";


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		typography: {
			padding: theme.spacing(2)
		},
		gridHeader: {
			backgroundColor: theme.palette.secondary.dark,
			height: "50px",
			display: "block",
			width: "100%",
			padding: "10px",
			tableLayout: "fixed"
		},
		table: {
			width: "100%"
		},
		buttonSty: {
			padding: theme.spacing(1),
			display: "inline-flex"
		},
		buttonStyDelete: {
			padding: theme.spacing(1),
			display: "inline-flex",
			backgroundColor: '#903333'
		},

		buttonDiv: {
			display: "inline-flex"
		},
		datePicker: {
			"& .MuiIconButton-root": {
				padding: theme.spacing(0.8)
			}
		},
		// popoverButton: {
		// 	width: "100%",
		// 	textTransform: "none",
		// 	justifyContent: "left"
		// },
		wrapper: {
			position: "relative"
		},
		textField: {
			marginRight: theme.spacing(1),
			width: "100%",
			"& ::-webkit-inner-spin-button, ::-webkit-outer-spin-button": {
				WebkitAppearance: "none",
				margin: 0,
				MozAppearance: "textfield"
			}
		},
		gridCellTypeDate: {
			width: "45%",
			padding: "1px"
		},
		gridCellTypeInput: {
			width: "20%",
			padding: "1px"
		},
		gridCellTypeCheckBox: {
			width: "15%",
			padding: "1px"
		},
		header: {
			height: "40px",
			padding: "10px",
			fontWeight: 600,
			width: "100%",
			backgroundColor: theme.palette.secondary.dark
		},
		validationMsg: {
			padding: "10px",
			color: theme.palette.error.main
		},
		popoverStyle: {
			maxHeight: "800px",
			"& .MuiPopover-paper": {
				maxWidth: "400px !important",
				overflowY: "hidden"
			}
		},
		tableBodyWrapper: {
			maxHeight: "530px",
			overflowY: "auto",
			display: "block",
			width: "100%",
			padding: "10px"
		},
		tableRowWrapper: {
			display: "table",
			width: "100%",
			// maxWidth: "300px",
			tableLayout: "fixed"
		},
		tableWrapper: {
			height: "calc(100% - 40px)",
			maxWidth: "400px",
			width: "100%"
		}
	})
);
type AttemptsHistoryBoxProps = {
	gradeBookWeightDetailId: any;
	showAdj: boolean;
	showScore: boolean;
	lblAdj: string;
	tabType: string;
	excludeLast: boolean;
	isClinicServiceScoreEnabled?: boolean;
	allowMoreClinicServicesOrHours?: boolean;
	setSnackBar: any;
	setConfirmationModal: any;
	refreshGridData:any;
	rowData:any;
	stuEnrollId :  any;
};
export default function AttemptsHistoryBox(props: AttemptsHistoryBoxProps) {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);
	const [originalData, setOriginalData] = React.useState<any>();
	const [totalAdjustment, setTotalAdjustment] = React.useState<number>(props.rowData.attempts);
	const [openedPopoverId, setopenedPopoverId] = React.useState<any>(null);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const selectedEnrollment = useSelector(
		(state: any) => state.student.selectedEnrollment
	);
	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const [checked, setChecked] = React.useState(true);
	let d = new Date();
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};

	const data = useFormContext();
	const [startDate, setStartDateState] = React.useState<Date | null>(d);

	const handleDefaultDateChange = (value: any) => {
		setStartDateState(value);
	};

	function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
		const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
		stabilizedThis.sort((a, b) => {
			const order = cmp(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map(el => el[0]);
	}

	const [studentAttemptsData, setStudentAttemptsData] = React.useState<
		Array<StudentAttempts>
	>([]);

	type Order = "asc" | "desc";

	function getSorting<K extends keyof any>(
		order: Order,
		orderBy: K
	): (
		a: { [key in K]: number | string },
		b: { [key in K]: number | string }
	) => number {
		return order === "desc"
			? (a, b) => desc(a, b, orderBy)
			: (a, b) => -desc(a, b, orderBy);
	}

	function desc<T>(a: T, b: T, orderBy: keyof T) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}
	const [order, setOrder] = React.useState<Order>("asc");
	const [orderBy, setOrderBy] = React.useState<keyof StudentAttempts>(
		"resultNumber"
	);
	React.useEffect(() => {
		if (anchorEl !== null && anchorEl !== undefined) {
			if (selectedEnrollment.stuEnrollId) {
				let stuEnrollId = selectedEnrollment.stuEnrollId;
				let InstrGrdBkWgtDetailId = props.gradeBookWeightDetailId;
				StudentGradesApi.GetStudentAttempts(
					stuEnrollId,
					InstrGrdBkWgtDetailId,
					props.excludeLast
				).then(
					(response: any) => {
						if (response) {
							let data = response.data;
							let attemptData: any = {
								...data
							};
						   if(isServices)
						   {
							   if (attemptData.groupedResult) {
								   setStudentAttemptsData(attemptData.groupedResult);
								   setOriginalData(attemptData.groupedResult);
                               }

							}
							else
						   {
							   if (attemptData.result) {
								   setStudentAttemptsData(attemptData.result);
                               }
							}
						}
					},
					(exception: any) => {}
				);
			}
		}
	}, [anchorEl]);

	const formDataHasValue = (
		fieldName: string,
		id: string | number | undefined
	): boolean => {
		let arrayIndex: string = fieldName + "_" + id;
		return (
			data.getValues()[arrayIndex] !== undefined &&
			data.getValues()[arrayIndex] !== "" &&
			data.getValues()[arrayIndex] !== null
		);
	};
	let isServices: boolean = false;
	let isExams: boolean = false;
	let isHours: boolean = false;
	switch(props.tabType)
	{
		case 'Exams':
			isExams=true;
		break;
		case 'Services':
			isServices=true;
		break;
		case 'Hours':
			isHours=true;
		break;
	}

	const doNotValidateRow = (id: string | number) => {
		if (isHours) {
			return (
				!formDataHasValue("resultNumber", id) &&
				!formDataHasValue("dateCompleted", id)
			);
		}

		if (isServices) {
			return (
				(!formDataHasValue("score", id) ||
					!props.isClinicServiceScoreEnabled) &&
				!formDataHasValue("resultNumber", id) &&
				!formDataHasValue("dateCompleted", id)
			);
		}

		if (isExams) {
			return (
				!formDataHasValue("score", id) && !formDataHasValue("dateCompleted", id)
			);
		}
		return true;
	};
	const triggerRowValidation = (id: any) => {
		if (isHours) {
			data.triggerValidation(["resultNumber_" + id, "dateCompleted_" + id]);
		}

		if (isServices) {
			data.triggerValidation([
				"resultNumber_" + id,
				"score_" + id,
				"dateCompleted_" + id
			]);
		}

		if (isExams) {
			data.triggerValidation(["score_" + id, "dateCompleted_" + id]);
		}		
	};

	const triggerIsDirty = (rowId: string): boolean => {
		let dataValues = data.getValues();
		let isDirty = false;
		// clinical services
		if (isServices) {
			isDirty =
				(dataValues["score_" + rowId] !== undefined &&
					dataValues["score_" + rowId] !== "" &&
					props.isClinicServiceScoreEnabled &&
					dataValues["resultNumber_" + rowId] !== undefined &&
					dataValues["resultNumber_" + rowId] !== "" &&
					dataValues["dateCompleted_" + rowId] !== undefined &&
					dataValues["dateCompleted_" + rowId] !== "") ||
				(dataValues["resultNumber_" + rowId] !== undefined &&
					dataValues["resultNumber_" + rowId] !== "" &&
					dataValues["dateCompleted_" + rowId] !== undefined &&
					dataValues["dateCompleted_" + rowId] !== "");
		}

		//clinical Hours
		if (isHours) {
			isDirty =
				dataValues["resultNumber_" + rowId] !== undefined &&
				dataValues["resultNumber_" + rowId] !== "" &&
				dataValues["dateCompleted_" + rowId] !== undefined &&
				dataValues["dateCompleted_" + rowId] !== "";
		}

		//exams
		if (isExams) {
			isDirty =
				dataValues["score_" + rowId] !== undefined &&
				dataValues["score_" + rowId] !== "" &&
				dataValues["dateCompleted_" + rowId] !== undefined &&
				dataValues["dateCompleted_" + rowId] !== "";
		}

		return isDirty;
	};
	const handleFieldOnChange = (row: any, fieldName: string, value: any) => {
		let id = row.grdBkResultId;
		if (fieldName === "dateCompleted") {
			row[fieldName] = value;
			data.setValue("dateCompleted_" + id, value);
		}

		if (fieldName === "resultNumber") {
			row[fieldName] = value.target.value;
			data.setValue("resultNumber_" + id, value.target.value);
		}

		if (fieldName === "score") {
			row[fieldName] = value.target.value;
			data.setValue("score_" + id, value.target.value);

			if (
				!formDataHasValue("dateCompleted", row.grdBkResultId) &&
				formDataHasValue("score", row.grdBkResultId)
			) {
				(row as any)["dateCompleted"] = startDate;
				data.setValue("dateCompleted_" + id, startDate);
			}
		}

		if (triggerIsDirty(row.grdBkResultId)) {
			row.isDirty = true;
		} else {
			row.isDirty = false;
		}
		setStudentAttemptsData(studentAttemptsData);
		triggerRowValidation(row.grdBkResultId);
		sumAdjustments();
	};

	const sumAdjustments = () => {
		const total = studentAttemptsData
		.map(item => item.resultNumber ?? 0)
		.reduce((prev, curr) => Number(prev) + Number(curr), 0);
		setTotalAdjustment(total);
	}

	React.useEffect(() => {
		sumAdjustments();
	}, [studentAttemptsData]);

	const handleDeleteAttempt = (event: React.MouseEvent<HTMLButtonElement>) => {
		props.setConfirmationModal((state: any) => {
			return {
				...state,
				open: true,
				confirmationText: "Are you sure you want to delete?",
				confirmationButton: "Yes",
				title: "Delete Attempt",
				onConfirmEvent: () => {
					DeleteAttempt();
					props.setConfirmationModal((updatedState: any) => {
						return { ...updatedState, open: false };
					});
				},
				onCancelEvent: () => {
					props.setConfirmationModal((updatedState: any) => {
						return { ...updatedState, open: false };
					});
				}
			};
		});		
	};

const DeleteAttempt = ()=>{ // delo
	if (selectedEnrollment.stuEnrollId) {
		let selectedToDelete = selected;
		if(isServices && selectedToDelete){
			var container = [];
			for (let index = 0; index < selectedToDelete.length; index++) {
				const selectedItem = selectedToDelete[index];
				var group = originalData.filter((gp:any) =>{
                      return gp.grdBkResultId == selectedItem;
				})
				if(group && group[0] && group[0].groupAttemptIds)
				container.push(...group[0].groupAttemptIds);
			}
			selectedToDelete = container;
		}
		StudentGradesApi.deleteAttempts(selectedToDelete).then(
			(response: any) => {
				if (response) {
					let data = response.data;
					let attemptData: any = {
						...data
					};
					setAnchorEl(attemptData.result ? null : anchorEl);
					props.refreshGridData();
					props.setSnackBar((props: any) => {
						return {
							variant: "success",
							showSnackBar: true,
							messageInfo: data.resultStatusMessage
						};
					});
				}
			},
			(exception: any) => {
				props.setSnackBar((props: any) => {
					return {
						...props,
						showSnackBar: true,
						messageInfo: exception.resultStatusMessage
					};
				});
			}
		);		
	}

};
	const UpdateAttempts = () => { //upo
		if (selectedEnrollment.stuEnrollId) {
			let toSend: StudentAttempts[] = studentAttemptsData.filter(function(
				attempt: StudentAttempts
			) {
				return attempt && attempt.isDirty;
			});
        let toBeEdited = toSend;
		if(isServices && toBeEdited){
			var container = [];
			for (let index = 0; index < toBeEdited.length; index++) {
				const selectedItem = toBeEdited[index];
				var group = originalData.filter((group:any) =>{
                      return group.grdBkResultId == selectedItem.grdBkResultId;
				})

				if(group[0].resultNumber != group[0].groupAttempts.length)
				{
					// ADJUSTMENT HAS BEEN UPDATED
					if(group[0].resultNumber < group[0].groupAttempts.length)
					{
						// DELETION REQUIRED
						let diff = -Math.abs(group[0].groupAttempts.length - group[0].resultNumber);
						let unWantedItems = group[0].groupAttempts.slice(diff);
						let selectedToDelete = unWantedItems.map((value : any) => value.grdBkResultId);
						StudentGradesApi.deleteAttempts(selectedToDelete).then(
							(response: any) => {
								if (response) {
									let data = response.data;
									let attemptData: any = {
										...data
									};
									setAnchorEl(attemptData.result ? null : anchorEl);
									props.refreshGridData();
								}
							}
						);
					}  // DELETION ENDS HERE
					else
					{
					   // ADDITION REQUIRED
					   let diff = Math.abs(group[0].groupAttempts.length - group[0].resultNumber);
					   var newList = []
						   let newObj = {
							attempts: diff ,
							classSectionId: props.rowData.classSectionId ,
							componentDescription: props.rowData.componentDescription,
							componentOrder: props.rowData.componentOrder ,
							componentType: props.rowData.componentType,
							courseDescription: props.rowData.courseDescription,
							courseOrder: props.rowData.courseOrder,
						 	dateCompleted: group[0].dateCompleted ,
							dateCompletedAsString: "",
							gradeBookWeightDetailId: props.rowData.gradeBookWeightDetailId ,
							required: props.rowData.required ,
							score: group[0].score ,
							isDirty : true,
							sysComponentTypeId: props.rowData.sysComponentTypeId
						}
						newList.push(newObj);
						StudentGradesApi.CreateGrades(props.stuEnrollId, newList).then(
						 (response: any) => {
							if (response) {
								let data = response.data;
								let attemptData: any = {
									...data
								};
								//setAnchorEl(attemptData.result ? null : anchorEl);
								props.refreshGridData();
							}
						 });
					}
				}
				for (let index = 0; index < group[0].groupAttempts.length; index++) {
					const ele = group[0].groupAttempts[index];
					ele.Score = Number(group[0].score);
					ele.dateCompleted = group[0].dateCompleted;
					ele.IsDirty = true;
					ele.ResultNumber = group[0].resultNumber
					container.push(ele);
				}
			}
			toBeEdited = container;
			setTimeout(() => {
				UpdateAttempts_Util(toBeEdited)
			}, 500);
		}
		else{
			UpdateAttempts_Util(toBeEdited)
		}
	  }
	};

	const UpdateAttempts_Util = (toBeEdited : any) => {

			StudentGradesApi.updateComponentAttemps(toBeEdited, props.tabType).then(
				(response: any) => {
					if (response) {
						let data = response.data;
						let attemptData: any = {
							...data
						};
						setAnchorEl(attemptData.result ? null : anchorEl);
						props.refreshGridData();
						props.setSnackBar((props: any) => {
							return {
								variant: "success",
								showSnackBar: true,
								messageInfo: data.resultStatusMessage
							};
						});
					}
				},
				(exception: any) => {
					props.setSnackBar((props: any) => {
						return {
							...props,
							showSnackBar: true,
							messageInfo: exception.resultStatusMessage
						};
					});
				}
			);
	}
	const [selected, setSelected] = React.useState([] as any);
	const isSelected = (grdBkResultId:any) => selected.indexOf(grdBkResultId) !== -1;
	const handleSelectAllClick = (event:any) => {
		if (event.target.checked) {
		  const newSelecteds = studentAttemptsData.map(n => n.grdBkResultId);
		  setSelected(newSelecteds);
		  return;
		}
		setSelected([]);
	  };
	  const handleChkClick = (event:any, grdBkResultId:string) => {
		const selectedIndex = selected.indexOf(grdBkResultId);
		let newSelected:string[] = [];

		if (selectedIndex === -1) {
		  newSelected = newSelected.concat(selected, grdBkResultId);
		} else if (selectedIndex === 0) {
		  newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
		  newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
		  newSelected = newSelected.concat(
			selected.slice(0, selectedIndex),
			selected.slice(selectedIndex + 1),
		  );
		}

		setSelected(newSelected);
	  };

	return (
		<div>
			<Button aria-describedby={id} onClick={handleClick}>
				<ListAltOutlinedIcon />
			</Button>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				className={classes.popoverStyle}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
			>
				<Typography className={classes.header}>Previous Attempts</Typography>

				<div className={classes.tableWrapper}>
					<Table
						className={classes.table}
						aria-labelledby="tableTitle"
						size="medium"
						aria-label="enhanced table"
					>
						<TableHead className={classes.gridHeader}>
							<TableRow className={classes.tableRowWrapper}>
								<TableCell
									align="left"
									classes={{
										head: classes.gridCellTypeCheckBox
									}}
								>
									<Checkbox
										className={classes.datePicker}
										value="uncontrolled"
										color="primary"
										onClick={handleSelectAllClick}
										inputProps={{ "aria-label": "uncontrolled-checkbox" }}
									/>
								</TableCell>
								<TableCell
									classes={{
										head: classes.gridCellTypeDate
									}}
									align="left"
								>
									Date
								</TableCell>
								{props.showScore ? (
									<TableCell
										style={ (isServices && !props.isClinicServiceScoreEnabled) ? { display:'none'} : {}}
										classes={{
											head: classes.gridCellTypeInput
										}}
										align="left"
									>
										Score
									</TableCell>
								) : (
									undefined
								)}
								{props.showAdj ? (
									<TableCell
										classes={{
											head: classes.gridCellTypeInput
										}}
										align="left"
									>
										{props.lblAdj}
									</TableCell>
								) : (
									undefined
								)}
							</TableRow>
						</TableHead>
						<TableBody className={classes.tableBodyWrapper}>
							{(studentAttemptsData as StudentAttempts[]).map(
								(row: any, index: any) => {
									const isItemSelected = isSelected(row.grdBkResultId);									
									return (
										<TableRow className={classes.tableRowWrapper}
										//hover
                      onClick={event => handleChkClick(event, row.grdBkResultId)}
                      role="checkbox"
                      tabIndex={-1}
                      key={row.grdBkResultId}
                      selected={isItemSelected}
										>
											<TableCell
												align="left"
												classes={{
													body: classes.gridCellTypeCheckBox
												}}
											>
												<Checkbox
													value="uncontrolled"
													checked={isItemSelected}
													id = {"chk_"+row.grdBkResultId}
													color="primary"
													inputProps={{ "aria-label": "uncontrolled-checkbox" }}
												/>
											</TableCell>
											<TableCell
												classes={{
													body: classes.gridCellTypeDate
												}}
												align="left"
											>
												<MuiPickersUtilsProvider utils={DateFnsUtils}>
													<KeyboardDatePicker
														className={classes.datePicker}
														disableToolbar
														autoOk={true}
														variant="inline"
														format="MM/dd/yyyy"
														maxDate={d}
														id={"dateCompleted_" + row.grdBkResultId}
														name={"dateCompleted_" + row.grdBkResultId}
														value={
															row.dateCompleted
																? new Date(row.dateCompleted)
																: ""
														}
														onChange={(v: any) => {
															handleFieldOnChange(
																row,
																"dateCompleted",
																v ? v : undefined
															);
														}}
														KeyboardButtonProps={{
															"aria-label": "change date"
														}}
														onBlur={(v: any) => {
															triggerRowValidation(row.grdBkResultId);
														}}
														invalidDateMessage={""}
														error={
															!!data.errors[
																"dateCompleted_" + row.grdBkResultId
															]
														}
														innerRef={(ref: any) => {
															data.register(
																"dateCompleted_" + row.grdBkResultId,
																{
																	validate: {
																		requiredWhenOtherValues: value =>
																			(isExams &&
																				value !== "" &&
																				value !== undefined &&
																				formDataHasValue(
																					"score",
																					row.grdBkResultId
																				)) ||
																			(isServices &&
																				value !== "" &&
																				value !== undefined &&
																				(formDataHasValue(
																					"resultNumber",
																					row.grdBkResultId
																				) ||
																					(props.isClinicServiceScoreEnabled &&
																						formDataHasValue(
																							"score",
																							row.grdBkResultId
																						)))) ||
																			(isHours &&
																				value !== "" &&
																				value !== undefined &&
																				(formDataHasValue(
																					"resultNumber",
																					row.grdBkResultId
																				) ||
																					formDataHasValue(
																						"score",
																						row.grdBkResultId
																					))) ||
																			(value !== "" && value !== undefined) ||
																			doNotValidateRow(row.grdBkResultId) ||
																			"Required"
																	}
																}
															);
															data.setValue(
																"dateCompleted_" + row.grdBkResultId,
																row.dateCompleted
															);
														}}
														helperText={
															data.errors["dateCompleted_" + row.grdBkResultId]
																? (data.errors[
																		"dateCompleted_" + row.grdBkResultId
																  ] as FieldError).message
																: undefined
														}
													/>
												</MuiPickersUtilsProvider>
											</TableCell>
											{props.showScore ? (
												<TableCell
												style={ (isServices && !props.isClinicServiceScoreEnabled) ? { display:'none'} : {}}
													classes={{
														body: classes.gridCellTypeInput
													}}
													align="center"
												>
													<TextField
														type={InputType.NUMBER}
														className={classes.textField}
														id={"score_" + row.grdBkResultId}
														name={"score_" + row.grdBkResultId}
														value={row.score}
														disabled={
															isServices && !props.isClinicServiceScoreEnabled
														}
														error={!!data.errors["score_" + row.grdBkResultId]}
														onChange={(v: React.FocusEvent<HTMLInputElement>) => {
															handleFieldOnChange(
																row,
																"score",
																v ? v : undefined
															);
														}}
														inputRef={data.register({
															validate: {
																requiredWhenScoreEnabled: value =>
																	(isExams &&
																		value !== "" &&
																		formDataHasValue(
																			"dateCompleted",
																			row.grdBkResultId
																		)) ||
																	(isServices &&
																		value !== "" &&
																		props.isClinicServiceScoreEnabled &&
																		(formDataHasValue(
																			"resultNumber",
																			row.grdBkResultId
																		) ||
																			formDataHasValue(
																				"dateCompleted",
																				row.grdBkResultId
																			))) ||
																	isHours ||
																	value !== "" ||
																	doNotValidateRow(row.grdBkResultId) ||
																	"Required"
															}
														})}
														helperText={
															data.errors["score_" + row.grdBkResultId]
																? (data.errors[
																		"score_" + row.grdBkResultId
																  ] as FieldError).message
																: undefined
														}
													/>
												</TableCell>
											) : (
												undefined
											)}
											{props.showAdj ? (
												<TableCell
													classes={{
														body: classes.gridCellTypeInput
													}}
													align="center"
												>
													<TextField
														type="number"
														className={classes.textField}
														id={"resultNumber_" + row.grdBkResultId}
														name={"resultNumber_" + row.grdBkResultId}
														value={row.resultNumber}
														onChange={(v: any) => {
															handleFieldOnChange(
																row,
																"resultNumber",
																v ? v : undefined
															);
														}}
														error={
															!!data.errors["resultNumber_" + row.grdBkResultId]
														}
														onBlur={(v: any) => {
															triggerRowValidation(row.grdBkResultId);
														}}
														inputRef={data.register({
															validate: {
																requiredWhenScoreEnabled: value =>
																	isExams ||
																	(value !== "" &&
																		formDataHasValue(
																			"dateCompleted",
																			row.grdBkResultId
																		)) ||
																	(isServices &&
																		value !== "" &&
																		(formDataHasValue(
																			"dateCompleted",
																			row.grdBkResultId
																		) ||
																			(props.isClinicServiceScoreEnabled &&
																				formDataHasValue(
																					"score",
																					row.grdBkResultId
																				)))) ||
																	(isHours &&
																		value !== "" &&
																		formDataHasValue(
																			"dateCompleted",
																			row.grdBkResultId
																		)) ||
																	value !== "" ||
																	doNotValidateRow(row.grdBkResultId) ||
																	"Required"
															}
														})}
														helperText={
															data.errors["resultNumber_" + row.grdBkResultId]
																? (data.errors[
																		"resultNumber_" + row.grdBkResultId
																  ] as FieldError).message
																: undefined
														}
													/>
												</TableCell>
											) : (
												undefined
											)}
										</TableRow>
									);
								}
							)}
						</TableBody>
					</Table>
					<div className={classes.validationMsg}>{props.allowMoreClinicServicesOrHours == false && totalAdjustment > props.rowData.required ? "Total adjustment(s) cannot be greater then " + props.rowData.required : ""}</div>
				</div>
				<div className={classes.buttonDiv}>
				    <div className={classes.buttonSty}>
						<AccessManager
							allowedPermissions={[security.permissions.student.updateExamResults,
								security.permissions.student.updateClinicServices,
								security.permissions.student.updateClinicHoursDetails]}
							renderNoAccess={() => <Button
								disabled={true}
								className={classes.buttonSty}
								variant="contained"
								color="primary"
								size="small"
								onClick={(e: any) => {
									UpdateAttempts();
								}}
							>
								SAVE
						</Button>}
						>
							<Button
								className={classes.buttonSty}
								variant="contained"
								color="primary"
								size="small"
								disabled={props.allowMoreClinicServicesOrHours == false && totalAdjustment > props.rowData.required ? true : false}
								onClick={(e: any) => {
									UpdateAttempts();
								}}
							>
								SAVE
						</Button>
						</AccessManager>
					</div>
					<div className={classes.buttonSty}>
						<AccessManager
							allowedPermissions={[security.permissions.student.deleteExamResults,
								security.permissions.student.deleteClinicServices,
								security.permissions.student.deleteClinicHoursAttempts]}
							renderNoAccess={() => <Button
								disabled={true}
								className={classes.buttonStyDelete}
								variant="contained"
								color="primary"
								size="small"
								onClick={(e: any) => {
									handleDeleteAttempt(e);
									//DeleteAttempt()	;
								}}
							>
								DELETE
						</Button>}
						>
							<Button
								className={classes.buttonStyDelete}
								variant="contained"
								color="primary"
								size="small"
								onClick={(e: any) => {
									handleDeleteAttempt(e);
									//DeleteAttempt()	;
								}}
							>
								DELETE
						</Button>
						</AccessManager>

					</div>
					<div className={classes.buttonSty}>
						<Button
							className={classes.buttonSty}
							variant="contained"
							color="primary"
							size="small"
							onClick={(e: any) => {
								handleClose();
							}}
						>
							CANCEL
						</Button>
					</div>
				</div>

			</Popover>
		</div>
	);
}
