import * as React from "react";
import {
    Card,
    CardContent,
    Grid,
    CardActions,
    Typography,
    Button,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
} from '@material-ui/core';
import clsx from 'clsx';
import ProgressSaveButton from "../../components/_Layout/Buttons/ProgressSaveButton";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import EnrollmentStatusAutoComplete from "../../components/AutoComplete/EnrollmentStatusAutoComplete";
import ProgramVersionAutoComplete from "../../components/AutoComplete/ProgramVersionAutoComplete";
import StudentGroupsAutoComplete from "../../components/AutoComplete/StudentGroupsAutoComplete";
import CourseAutoComplete from "../../components/AutoComplete/CourseAutoComplete";
import GradingComponentsAutoComplete from "../../components/AutoComplete/GradingComponentsAutoComplete";
import { PostGradesFilter } from "../../interfaces/student/academics/PostGradesFilter";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from "@material-ui/pickers";
import { useForm } from "react-hook-form";
import { useFormContext } from "react-hook-form";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
            minHeight: "100%",
            paddingTop: theme.spacing(3),
            overflowX: "hidden",
            overflowY: "auto"
        }
        , cardTitle: {
            fontSize: 17,
            textDecoration: 'bold',
            backgroundColor: theme.palette.secondary.dark,
            padding: theme.spacing(1, 1),
        },
        filterMenu: {
            textAlign: 'right',
        },
        saveButton: {
            width: 90,
        },
        width100: {
            width: "100%",
        },
    })
);

const ITEM_HEIGHT = 90;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

type filterProps = {
    handleSearch: any;
    components: any;
};

const PostGradesSearchFilter = (props: filterProps) => {
    const classes = useStyles({});
    const [updating, setUpdating] = React.useState<boolean>(false);
    const studentId = useSelector<any, any>(
        (state: any) => state.student.studentId
    );

    const data = useForm<any>({ mode: "onBlur" });

    const campusId = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );

    const [model, setModel] = React.useState<PostGradesFilter>({
        startDate: null,
        programVersionIds: [],
        courseIds: [],
        componentIds: [],
        studentGroupIds: [],
        statusIds: [],
        campusId: campusId,
    });

    const handleFieldOnChange = (
        fieldId: string,
        value: any,
        mapFunction?: Function
    ) => {
        let updatedModel = model;
        let newValue = mapFunction ? mapFunction(value) : value;
        (updatedModel as any)[fieldId] = newValue;
        if (fieldId == "courseIds") {
            (updatedModel as any)["componentIds"] = [];
        } else if (fieldId == "programVersionIds") {
            (updatedModel as any)["courseIds"] = [];
            (updatedModel as any)["componentIds"] = [];
        }
        setModel({ ...updatedModel });
    };

    const handleSearch = async () => {
        const validation = await data.triggerValidation();
        if (validation) {
            setUpdating(true);
            props.handleSearch(model);
        }
    };

    React.useEffect(() => {
        setUpdating(false);
    }, [props.components]);

    const setEnrollmentStatus = (enrollmentStatus: any) => {
        let newStatuses: string[] = [];
        if (enrollmentStatus !== null) {
            if (Array.isArray(enrollmentStatus)) {
                newStatuses = enrollmentStatus.map((gr: any) => gr.value);
            } else {
                newStatuses.push(enrollmentStatus.value);
            }
        }
        return newStatuses;
    };
    const setProgramVersion = (PrgVersions: any) => {
        let newProgramVersion: string[] = [];
        if (PrgVersions !== null) {
            if (Array.isArray(PrgVersions)) {
                newProgramVersion = PrgVersions.map((gr: any) => gr.value);
            } else {
                newProgramVersion.push(PrgVersions.value);
            }
        }
        return newProgramVersion;
    };
    const setStudentGroup = (studentGroups: any) => {
        let newGroups: string[] = [];
        if (studentGroups !== null) {
            if (Array.isArray(studentGroups)) {
                newGroups = studentGroups.map((gr: any) => gr.value);
            } else {
                newGroups.push(studentGroups.value);
            }
        }
        return newGroups;
    };
    const setCourses = (courses: any) => {
        let newCourses: string[] = [];
        if (courses !== null) {
            if (Array.isArray(courses)) {
                newCourses = courses.map((gr: any) => gr.value);
            } else {
                newCourses.push(courses.value);
            }
        }
        return newCourses;
    };
    const setComponents = (components: any) => {
        let newComponents: string[] = [];
        if (components !== null) {
            if (Array.isArray(components)) {
                newComponents = components.map((gr: any) => gr.value);
            } else {
                newComponents.push(components.value);
            }
        }
        return newComponents;
    };

    const names: string[] = [
        'Enrollment Group',
        'Program Version',
        'Course',
        'Grading Component',
        'Start Date',
        'Status',
    ];
    const defaultFilter: string[] = [
        'Enrollment Group',
        'Status',
        'Course',
        'Grading Component',
    ];

    const [filters, setFilters] = React.useState<string[]>(defaultFilter);

    const handleReset = () => {
        let updatedModel = model;
        (updatedModel as any)["programVersionIds"] = [];
        (updatedModel as any)["studentGroupIds"] = [];
        (updatedModel as any)["startDate"] = null;

        setFilters(defaultFilter);
        setModel({ ...updatedModel });
    }

    const handleChange = (event: any) => {
        const {
            target: { value },
        } = event;
        if (value[value.length - 1] != "reset") {
            setFilters(
                typeof value === 'string' ? value.split(',') : value,
            );
        }

    };

    return (
        <React.Fragment>
            <Card square={true}>
                <Typography className={clsx(classes.cardTitle)}>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={6}>
                            <div>Post Grades by Group - Search</div>
                        </Grid>
                        <Grid item xs={6} className={classes.filterMenu}>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                color="primary"
                                variant="standard"
                                multiple
                                value={filters}
                                onChange={handleChange}
                                renderValue={(selected: any) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {names.map((name: string) => (
                                    <MenuItem color="primary" key={name} value={name}>
                                        <Checkbox color="primary" checked={filters.indexOf(name) > -1} />
                                        <ListItemText color="primary" primary={name} />
                                    </MenuItem>
                                ))}
                                <MenuItem key="reset" value="reset">
                                    <Button onClick={handleReset} size="small" variant='outlined' fullWidth={true}>Reset</Button>
                                </MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </Typography>
                <CardContent>
                    <Grid container direction="row" spacing={2}>
                        {
                            filters.indexOf("Enrollment Group") > -1 &&
                            <Grid item md={4} sm={4} xs={12}>
                                <StudentGroupsAutoComplete
                                    id="studentGroupIds"
                                    name="studentGroupIds"
                                    disabled={false}
                                    filterHandle={(groups: any) => {
                                        handleFieldOnChange("studentGroupIds", groups, setStudentGroup);
                                    }}
                                    label="Student Group"
                                    multiple={true}
                                    valueFilter={
                                        model?.studentGroupIds
                                            ? {
                                                key: "value",
                                                values: model?.studentGroupIds,
                                            }
                                            : undefined
                                    }
                                />
                            </Grid>
                        }
                        {
                            filters.indexOf("Course") > -1 &&
                            <Grid item md={4} sm={4} xs={12}>
                                <CourseAutoComplete
                                    id="courseIds"
                                    name="courseIds"
                                    campusId={model.campusId}
                                    progamVersionIds={model.programVersionIds}
                                    disabled={false}
                                    filterHandle={(groups: any) => {
                                        handleFieldOnChange("courseIds", groups, setCourses);
                                    }}
                                    label="Course *"
                                    multiple={true}
                                    valueFilter={
                                        model?.courseIds
                                            ? {
                                                key: "value",
                                                values: model?.courseIds,
                                            }
                                            : undefined
                                    }
                                    error={!!data.errors?.courseIds}
                                    inputRef={data.register({
                                        validate: {
                                            atLeastOneRequired: (value) =>
                                                (model.courseIds && model.courseIds.length > 0) ||
                                                "Course(s) are required.",
                                        },
                                    })}
                                    helperText={
                                        data.errors.courseIds
                                            ? "Course(s) are required."
                                            : undefined
                                    }
                                />
                            </Grid>
                        }
                        {
                            filters.indexOf("Grading Component") > -1 &&
                            <Grid item md={4} sm={4} xs={12}>
                                <GradingComponentsAutoComplete
                                    id="componentIds"
                                    name="componentIds"
                                    campusId={model.campusId}
                                    courseIds={model.courseIds}
                                    disabled={false}
                                    filterHandle={(groups: any) => {
                                        handleFieldOnChange("componentIds", groups, setComponents);
                                    }}
                                    label="Grading Component *"
                                    multiple={true}
                                    valueFilter={
                                        model?.componentIds
                                            ? {
                                                key: "value",
                                                values: model?.componentIds,
                                            }
                                            : undefined
                                    }
                                    error={!!data.errors?.componentIds}
                                    inputRef={data.register({
                                        validate: {
                                            atLeastOneRequired: (value) =>
                                                (model.componentIds && model.componentIds.length > 0) ||
                                                "Component(s) are required.",
                                        },
                                    })}
                                    helperText={
                                        data.errors.componentIds
                                            ? "Component(s) are required."
                                            : undefined
                                    }
                                />
                            </Grid>
                        }
                        {
                            filters.indexOf("Status") > -1 &&
                            <Grid item md={4} sm={4} xs={12}>
                                <EnrollmentStatusAutoComplete
                                    filterHandle={(statuses: any) => {
                                        handleFieldOnChange(
                                            "statusIds",
                                            statuses,
                                            setEnrollmentStatus
                                        );
                                    }}
                                    isInSchool={true}
                                    id="enrollmentStatus"
                                    name="enrollmentStatus"
                                    label="Enrollment Status"
                                    multiple={true}
                                    valueFilter={
                                        model?.statusIds
                                            ? {
                                                key: "value",
                                                values: model?.statusIds,
                                            }
                                            : undefined
                                    }
                                />
                            </Grid>
                        }
                        {
                            filters.indexOf("Program Version") > -1 &&
                            <Grid item md={4} sm={4} xs={12}>
                                <ProgramVersionAutoComplete
                                    selectAll={true}
                                    name="programVersionIds"
                                    filterHandle={(value: any) =>
                                        handleFieldOnChange(
                                            "programVersionIds",
                                            value,
                                            setProgramVersion
                                        )
                                    }
                                    valueFilter={
                                        model.programVersionIds
                                            ? {
                                                key: "value",
                                                values: model.programVersionIds,
                                            }
                                            : undefined
                                    }
                                    multiple={true}
                                    disabled={false}
                                />
                            </Grid>
                        }

                        {
                            filters.indexOf("Start Date") > -1 &&
                            <Grid item md={4} sm={4} xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="startDate"
                                        name="startDate"
                                        key="startDateKey"
                                        label="Start Date as of"
                                        value={model.startDate ? model.startDate : null}
                                        onChange={(e: any, value: any) =>
                                            handleFieldOnChange(
                                                "startDate",
                                                value ? value : undefined
                                            )
                                        }
                                        className={classes.width100}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        }
                    </Grid>
                </CardContent>
                <CardActions >
                    <Grid container direction="row" spacing={1}>
                        <Grid
                            item
                            alignContent="flex-start"
                            alignItems="flex-start"
                            justify="flex-start"
                        >
                            <ProgressSaveButton
                                text="Search"
                                onClick={handleSearch}
                                loading={updating}
                                buttonClassName={classes.saveButton}
                            ></ProgressSaveButton>
                        </Grid>
                        <Grid
                            item
                            alignContent="flex-start"
                            alignItems="flex-start"
                            justify="flex-start"
                        >

                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </React.Fragment>
    );
};

export default PostGradesSearchFilter;
