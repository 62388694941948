import API from "../../../apiWrapper";
import { Phone } from "../../../../interfaces/student/profile/contactInfo/Phone";

export const getAll = (leadId: string | undefined) => {
  return API()
    .get("/leads/" + leadId + "/phone/GetAll")
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};

export const getPhone = (leadId: string | undefined, id: string) => {
  return API()
    .get("/leads/" + leadId + "/phone/"+ id)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};

export const deletePhone = (leadId: string | undefined, id: string) => {
  return API()
    .delete("/leads/" + leadId + "/phone/Delete/"+ id)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};

export const postPhone = (leadId: string | undefined, phoneObj: Phone) => {
  return API()
    .post("/leads/" + leadId + "/phone/Post", {...phoneObj, leadId})
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};


export const getBestPhone = (studentId: string | undefined) => {
  return API()
    .get("/leads/" + studentId + "/phone/GetBest")
    .then(
      (res: any) => {
        if (res.data) {
          return res.data as Phone;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};
export const updatePhone = (leadId: string | undefined, phoneObj: Phone) => {
  return API()
    .put("/leads/" + leadId + "/phone/Put", {...phoneObj, leadId})
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};
