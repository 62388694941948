import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
		Typography,
		Table,
		TableBody,
		TableCell,
		TableRow,
		TableHead,
		Paper,
		CardContent,
		TextField
	} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
		MuiPickersUtilsProvider,
		KeyboardDatePicker
	} from "@material-ui/pickers";
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as LeaveOfAbsenceApi from "../../../api/student/status/LeaveOfAbsenceApi";
import * as StatusHistoryApi from "../../../api/student/status/StatusHistoryApi";
import { ILeaveOfAbsence } from "../../../interfaces/student/status/ILeaveOfAbsence";
import { ILeaveOfAbsenceReason } from "../../../interfaces/student/status/ILeaveOfAbsenceReason";
import { IEnrollmentStatus } from "../../../interfaces/student/status/IEnrollmentStatus";
import { Order } from "../../../interfaces/common/Table/CustomHeadCell";
import * as TableFunctions from "../../../utils/tableFunctions";
import { useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { ApiResultStatus } from "../../../enums/ApiResultStatus"; 
import ConfirmationModal from "../../../components/_Layout/Modal/ConfirmationModal";
import { SystemEnrollmentStatus } from "../../../enums/SystemEnrollmentStatus"; 
import { checkPermissions } from "../../../utils/securityFunctions"
import { format} from "date-fns";

type LeaveOfAbsenceTableProps = {
	setSnackBarProps: any;
	updateEvent: any;
	refreshFlag: boolean;
	statusChangeEnrollment: any;
	loaWidgetEnrollment: string|null;
	loaConfirmation: any
};

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.background.paper,
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2),
			},
		},
		tableWrapper: {
			width: "100%",
		},
		table: {
			minWidth: "750",			
		},
		gridHeadTypeXSmall: {
			width: "5%",
			paddingLeft: theme.spacing(.5),
			paddingRight: theme.spacing(0),
			backgroundColor: theme.palette.secondary.dark,
			minWidth: "60px",
		},	
		gridCellTypeXSmall: {
			width: "5%",
			paddingTop: theme.spacing(.5),
			paddingBottom: theme.spacing(.5),
			paddingLeft: theme.spacing(.5),
			paddingRight: theme.spacing(0),
		},		
		gridHeadTypeSmall: {
			width: "10%",
			paddingLeft: theme.spacing(.5),
			paddingRight: theme.spacing(0),
			backgroundColor: theme.palette.secondary.dark,
		},
		gridCellTypeSmall: {
			width: "10%",
			paddingTop: theme.spacing(.5),
			paddingBottom: theme.spacing(.5),
			paddingLeft: theme.spacing(.5),
			paddingRight: theme.spacing(0),
		},
		gridHeadTypeMedium: {
			width: "12%",
			paddingLeft: theme.spacing(.5),
			paddingRight: theme.spacing(0),
			backgroundColor: theme.palette.secondary.dark,
		},
		gridCellTypeMedium: {
			width: "12%",
			height: "65px", //match dropdown height on edit
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(.5),
			paddingRight: theme.spacing(0),
		},
		gridHeadTypeLarge: {
			width: "20%",
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
			backgroundColor: theme.palette.secondary.dark,
		},
		gridCellTypeLarge: {
			width: "20%",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
		},	
		gridHeadTypeRemainder: {
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
			backgroundColor: theme.palette.secondary.dark,
		},
		gridCellTypeRemainder: {
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
		  },			
		icons: {
			fontSize: theme.spacing(2),
		},
		gridHeader: {
			backgroundColor: theme.palette.secondary.dark,
			width: "100%",
		},
		cardTitle: {
			fontSize: theme.spacing(2),
			color: theme.palette.black,
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1),
		  },
		cardContent: {
			padding: theme.spacing(1, 1.5),
			maxHeight:"350px",
			overflowY: "auto",
		  },
		datePicker: {
			"& .MuiIconButton-root": {
				padding: theme.spacing(0.8),
			},
			fontSize: theme.spacing(.5),
		},
		textField: {
			marginRight: theme.spacing(1),
			width: "100%",
			fontSize: theme.spacing(1.5),
		  },

	})
);

const LeaveOfAbsenceTable = (props: 
	LeaveOfAbsenceTableProps) => {
    const classes = useStyles({});

	const isSupportUser = useSelector(
        (state: any) => state.session.user.isSupportUser
    );

	interface HeadCell {
		disablePadding: boolean;
		id: keyof ILeaveOfAbsence;
		label: string;
		numeric: boolean;
		widthType: "L" | "M" | "S" | "XS" | "R";
	}

	const headCells: HeadCell[] = [
		{
			id: "startDate",
			numeric: false,
			disablePadding: false,
			label: "Start Date",
			widthType: "M",
		},
		{
			id: "endDate",
			numeric: false,
			disablePadding: false,
			label: "End Date",
			widthType: "M",
		},
		{
			id: "requestDate",
			numeric: false,
			disablePadding: false,
			label: "Request Date",
			widthType: "M",
		},
		{
			id: "reasonId",
			numeric: false,
			disablePadding: false,
			label: "LOA Reason",
			widthType: "R",
		},
		{
			id: "returnDate",
			numeric: false,
			disablePadding: false,
			label: "Return from LOA",
			widthType: "M",
		},
		{
			id: "modifiedDate",
			numeric: true,
			disablePadding: false,
			label: "Modified Date",
			widthType: "S",
		},
		{
			id: "modifiedUser",
			numeric: false,
			disablePadding: false,
			label: "Modified By",
			widthType: "S",
		},	
		{
			id: "studentLeaveOfAbsenceId",
			numeric: false,
			disablePadding: false,
			label: "",
			widthType: "XS",
		},	  			  			
		];
	
	interface EnhancedTableProps {
		classes: ReturnType<typeof useStyles>;
	}

	function EnhancedTableHead(props: EnhancedTableProps) {
		const {
			classes,
		} = props;
	
		return (
			<TableHead >
				<TableRow className={classes.gridHeader}>
					{headCells.map(headCell => (
						<TableCell
							key={headCell.id}
							align="left"
							padding={headCell.disablePadding ? "none" : "default"}
							className={
								headCell.widthType == "S"
									? classes.gridHeadTypeSmall
									: headCell.widthType == "M"
									? classes.gridHeadTypeMedium
									: headCell.widthType == "L"
									? classes.gridHeadTypeLarge	
									: headCell.widthType == "XS"
									? classes.gridHeadTypeXSmall
									: headCell.widthType == "R"
									? classes.gridHeadTypeRemainder									
									: classes.gridHeadTypeRemainder
								}
							>
							{headCell.label}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
		);
	}

	const [leaveOfAbsenceData, setLeaveOfAbsenceData] = React.useState<Array<ILeaveOfAbsence>>([]);
	const [order, setOrder] = React.useState<Order>("desc");
	const [orderBy, setOrderBy] = React.useState<keyof ILeaveOfAbsence>("startDate");	
	const [previousData, setPreviousData] = React.useState<Array<ILeaveOfAbsence>>([]);
	const [reasonData, setReasonData] = React.useState<Array<ILeaveOfAbsenceReason>>([]);
	const [selectedReason, setSelectedReason] = React.useState<ILeaveOfAbsenceReason>();

	const [confirmationPopupState, setConfirmationPopupState] = React.useState<any>(
		{
			id: "simple-popup",
			message: ""
		});

	const selectedEnrollment = useSelector(
		(state: any) => state.student.selectedEnrollment
	);
	const [changeEnrollmentData, setChangeEnrollmentData] = React.useState<any>(props.statusChangeEnrollment);

	const checkStatusForBlock = (): boolean => {	
		return (changeEnrollmentData?.systemStatus == SystemEnrollmentStatus.Graduated 
					|| changeEnrollmentData?.systemStatus == SystemEnrollmentStatus.Dropped);
	};
	const [statusBlock, setStatusBlock] = React.useState<boolean>(checkStatusForBlock());

	const refreshGridData=()=>{
		if (selectedEnrollment && selectedEnrollment.stuEnrollId) {
			let studentEnrollmentId = selectedEnrollment.stuEnrollId;
			if(props.loaWidgetEnrollment)
			{
				studentEnrollmentId = props.loaWidgetEnrollment;
			}
			LeaveOfAbsenceApi.getLOAHistory(studentEnrollmentId).then(		
				(response: Array<ILeaveOfAbsence>) => {
					if (response) {
						setLeaveOfAbsenceData(response);
					}
				},
				(exception: any) => {}
			);
		}
	}
	const refreshReason=()=>{
		if (selectedEnrollment && selectedEnrollment.stuEnrollId) {
			let studentEnrollmentId = selectedEnrollment.stuEnrollId;
			LeaveOfAbsenceApi.getLOAReasons(studentEnrollmentId).then(
				(response: Array<ILeaveOfAbsenceReason>) => {
					if (response && response.length > 0) {
						setReasonData(response);
					}
				},
				(exception: any) => {}
			);
		}
	}
	React.useEffect(() => {
		refreshGridData();
		refreshReason();
	}, [selectedEnrollment, props.refreshFlag]);
		
	const renderIcons = (row: ILeaveOfAbsence, index: any) => {
		let icons;

		{(row.isEditMode) ?
			icons = <span>
				<IconButton aria-label="submit" onClick = {() => {handleSubmitChanges(row, index === 0);}} size="small">
					<CheckIcon className = {classes.icons} />
				</IconButton> 
				<IconButton aria-label="cancel" onClick = {() => {handleCancelEditing(row);}} size="small">
					<ClearIcon className = {classes.icons} />
				</IconButton> 
				</span>
			: 
				icons = <span>
					<IconButton disabled={(checkPermissions(["RestrictUpdateLOA"]) && !isSupportUser)? true : false} aria-label="edit" onClick = {() => {handleStartEditing(row);}} size="small">
						<EditIcon className = {classes.icons} />
					</IconButton>	
					{ (index == 0 && !statusBlock) && <IconButton disabled={(checkPermissions(["RestrictDeleteLOA"]) && !isSupportUser)? true : false} aria-label="delete" onClick = {() => {handleDeleteClick(row);}} size="small">
						<DeleteIcon className = {classes.icons} />
					</IconButton>}		
					</span>		
				
		};

		return icons;
	}

	const renderStartDate = (row: ILeaveOfAbsence, index: any) => {
		let startDate;

		{(row.isEditMode && index == 0) ?
			startDate = 
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<KeyboardDatePicker
					disableToolbar
					autoOk={true}
					variant="inline"
					format="MM/dd/yyyy"
					id={"startDate_" + row.studentLeaveOfAbsenceId}
					name={"startDate_" + row.studentLeaveOfAbsenceId}
					value={row.startDate ? new Date(row.startDate) : null}
					onChange={(v: any) => {
					handleFieldOnChange(row, "startDate", v ? v : undefined);
					}}
					className={classes.datePicker}
					KeyboardButtonProps={{
					"aria-label": "start date",
					}}
				/>
		</MuiPickersUtilsProvider>
		: startDate = (row.startDate == undefined)? row.startDate :
			new Date(row.startDate).toLocaleDateString()
		};

		return startDate;
	}

	const renderEndDate = (row: ILeaveOfAbsence, index: any) => {
		let endDate;

		{(row.isEditMode && index==0) ?
			endDate = 
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<KeyboardDatePicker
					key = {"endDate_key" + row.studentLeaveOfAbsenceId}
					disableToolbar
					autoOk={true}
					variant="inline"
					format="MM/dd/yyyy"
					id={"endDate_" + row.studentLeaveOfAbsenceId}
					name={"endDate_" + row.studentLeaveOfAbsenceId}
					value={row.endDate ? new Date(row.endDate) : null}
					onChange={(v: any) => {
					handleFieldOnChange(row, "endDate", v ? v : undefined);
					}}
					className={classes.datePicker}
					KeyboardButtonProps={{
					"aria-label": "end date",
					}}
				/>
		</MuiPickersUtilsProvider>
		: endDate = (row.endDate == undefined)? row.endDate :
			new Date(row.endDate).toLocaleDateString()
		};

		return endDate;
	}

	const renderRequestDate = (row: ILeaveOfAbsence, index: any) => {
		let requestDate;

		{(row.isEditMode && index==0) ?
			requestDate = 
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<KeyboardDatePicker
					disableToolbar
					autoOk={true}
					variant="inline"
					format="MM/dd/yyyy"
					id={"requestDate_" + row.studentLeaveOfAbsenceId}
					name={"requestDate_" + row.studentLeaveOfAbsenceId}
					value={row.requestDate ? new Date(row.requestDate) : null}
					onChange={(v: any) => {
					handleFieldOnChange(row, "requestDate", v ? v : undefined);
					}}
					className={classes.datePicker}
					KeyboardButtonProps={{
					"aria-label": "request date",
					}}
				/>
		</MuiPickersUtilsProvider>
		: requestDate = (row.requestDate == undefined)? row.requestDate :
			new Date(row.requestDate).toLocaleDateString()
		};

		return requestDate;
	}

	const renderReturnDate = (row: ILeaveOfAbsence, index: any) => {
		let returnDate;

		{(row.isEditMode && index==0) ?
			returnDate = 
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<KeyboardDatePicker
					disableToolbar
					autoOk={true}
					variant="inline"
					format="MM/dd/yyyy"
					id={"returnDate_" + row.studentLeaveOfAbsenceId}
					name={"returnDate_" + row.studentLeaveOfAbsenceId}
					value={row.returnDate ? new Date(row.returnDate) : null}
					onChange={(v: any) => {
					handleFieldOnChange(row, "returnDate", v ? v : undefined);
					}}
					className={classes.datePicker}
					KeyboardButtonProps={{
					"aria-label": "return date",
					}}
				/>
		</MuiPickersUtilsProvider>
		: returnDate = (row.returnDate == undefined)? row.returnDate :
			new Date(row.returnDate).toLocaleDateString()
		};

		return returnDate;
	}

	const renderReason = (row: ILeaveOfAbsence) => {
		let reason;

		{(row.isEditMode ) ?
			reason = 
			<Autocomplete
				id={"reason_" + row.studentLeaveOfAbsenceId}
				options={reasonData}
				value={selectedReason}
				className={classes.textField}
				getOptionLabel={(option) => (option != null)? option.description: ""}
				onChange={(e: any, v: any) => {
					handleReasonOnChange(row, v);
				}}
				renderInput={(params) => (
					<TextField {...params}  />
			)}
		/>
		: reason = row.reason
		};

		return reason;
	}


	const handleStartEditing = (selected: ILeaveOfAbsence) => {
		if (previousData != null && previousData != undefined && previousData.length > 0) 
		{
			let found = previousData.filter((prev) => {return prev.studentLeaveOfAbsenceId === selected.studentLeaveOfAbsenceId;});
			if (found == null || found.length < 1)
			{
				previousData.push(selected);
			}
		}
		else{
			previousData.push(selected);

		}

		const rows = leaveOfAbsenceData.map(row => {
			if (row.studentLeaveOfAbsenceId === selected.studentLeaveOfAbsenceId) {
				const reasons = 
					reasonData.map(reasonRow => {
						if (reasonRow.leaveOfAbsenceReasonId === selected.reasonId) {
							setSelectedReason(reasonRow);			
						}});			
				return { ...row, isEditMode: true };				
			}
			return row;
		})
		
		setLeaveOfAbsenceData(rows);
	};
	
	const handleFieldOnChange = (selected: ILeaveOfAbsence, fieldName: string, value: any) => {
		const rows =  TableFunctions.stableSort(
			leaveOfAbsenceData,
			TableFunctions.getSorting(order, orderBy) as any
			).map(row => {
				if (row.studentLeaveOfAbsenceId === selected.studentLeaveOfAbsenceId) {
					return { ...row, [fieldName]: value };		
				}
				return row;		
			})

		setLeaveOfAbsenceData(rows)
	}

	const handleReasonOnChange = (selected: ILeaveOfAbsence, value: any) => {
		const rows =  TableFunctions.stableSort(
			leaveOfAbsenceData,
			TableFunctions.getSorting(order, orderBy) as any
			).map(row => {
				if (row.studentLeaveOfAbsenceId === selected.studentLeaveOfAbsenceId) {
					return { ...row, reasonId:  (value != null)? value.leaveOfAbsenceReasonId : null, reason: (value != null)? value.description : null };
				}
				return row;
			})

		setSelectedReason(value);		
		setLeaveOfAbsenceData(rows)
	};

	const handleCancelEditing = (selected: ILeaveOfAbsence) => {
		let rows: Array<ILeaveOfAbsence>;

		if (previousData != null && previousData != undefined && previousData.length > 0) 
		{
			let found = previousData.find((prev) =>  prev.studentLeaveOfAbsenceId === selected.studentLeaveOfAbsenceId);
			if (found != null && found != undefined)
			{	
				const foundRow = found;
				rows = leaveOfAbsenceData.map(row => {
					if (row.studentLeaveOfAbsenceId === selected.studentLeaveOfAbsenceId) {
						return { ...foundRow, isEditMode: false };
					}
					return row;
				})
				
				const newPrevious = previousData.filter((item) => item.studentLeaveOfAbsenceId !== selected.studentLeaveOfAbsenceId);
				setPreviousData(newPrevious);
			}
			else {
				rows = leaveOfAbsenceData.map(row => {
					if (row.studentLeaveOfAbsenceId === selected.studentLeaveOfAbsenceId) {
						return { ...row, isEditMode: false };
					}
					return row;
				})	
			}
		}
		else{
			rows = leaveOfAbsenceData.map(row => {
				if (row.studentLeaveOfAbsenceId === selected.studentLeaveOfAbsenceId) {
					return { ...row, isEditMode: false };
				}
				return row;
			})	
		}
	
		setLeaveOfAbsenceData(rows);	
	};

	const handleDeleteClick = (selected: ILeaveOfAbsence) => {
		setConfirmationPopupState((state: any) => {
			return {
				...state,
				open: true,
				confirmationText: "Are you sure you want to delete this student's LOA record? The student absent hours will increase during this LOA time.",
				confirmationButton: "Yes",
				title: "Delete LOA",
				onConfirmEvent: () => {
					setConfirmationPopupState((updatedState: any) => {
						return { ...updatedState, open: false };
					});
					handleDeleteConfirmation(selected);
				},
				onCancelEvent: () => {
					setConfirmationPopupState((updatedState: any) => {
						return { ...updatedState, open: false };
					});
				}
			};
		});
	};	

	const handleDeleteConfirmation = (selected: ILeaveOfAbsence) => {
		LeaveOfAbsenceApi.deleteLOA(selected).then(
			(response: any) => {
				if (response.data != undefined && response.data.resultStatus != undefined)
				{
					switch (response.data.resultStatus) {
						case ApiResultStatus.Success:
							props.setSnackBarProps((props: any) => {
								return {
								variant: "success",
								showSnackBar: true,
								messageInfo: response.data.resultStatusMessage
								};
							});	
							refreshAfterDelete();
							break;							
						case ApiResultStatus.Warning:
							props.setSnackBarProps((props: any) => {
								return {
								variant: "warning",
								showSnackBar: true,
								messageInfo: response.data.resultStatusMessage
								};
							});
							break;
						case ApiResultStatus.Error:
							props.setSnackBarProps((props: any) => {
								return {
								variant: "error",
								showSnackBar: true,
								messageInfo: response.data.resultStatusMessage
								};
							});
							break;
						default:
					}						
				}
				else {
					props.setSnackBarProps((props: any) => {
						return {
						variant: "error",
						showSnackBar: true,
						messageInfo: "Error deleting leave of absence. "
						};
					});
				}
			},
			(exception: any) => {
				props.setSnackBarProps((snackBarProps: any) => {
				  return {
					variant: "error",
					showSnackBar: true,
					messageInfo: exception,
				  };
				});
			  }
		)
	};

	const refreshAfterDelete = () => {	
		let latestStatus;			
		if (selectedEnrollment && selectedEnrollment.stuEnrollId) {
			let studentEnrollmentId = selectedEnrollment.stuEnrollId;
			StatusHistoryApi.getStatusHistoryByEnrollmentId(studentEnrollmentId).then(
				(response: Array<IEnrollmentStatus>) => {
					if (response && response.length > 0) {
						const rows = TableFunctions.stableSort(
							response,
							TableFunctions.getSorting("desc", "dateOfChange") as any
							).map(row => {
								return row;
							})
							latestStatus = rows[0].newStatusDescription;
							props.updateEvent(latestStatus);
							refreshGridData();	
					}
					else {
						props.updateEvent();
						refreshGridData();	
					}
				}
			);
		}	
	};

	const handleSubmitChanges = (selected: ILeaveOfAbsence,  runValidation: boolean, disbursementConfirmation?: boolean, existingDisbursementReason?: string) => {

		if (validateRow(selected)) {
			selected.startDate = new Date(selected.startDate).toLocaleDateString()
			selected.endDate = new Date(selected.endDate).toLocaleDateString()
			selected.existingDisbursementReason = existingDisbursementReason;
		LeaveOfAbsenceApi.updateLOAHistory(selected, runValidation, disbursementConfirmation).then(
			(response: any) => {
				if (response.data != undefined && response.data.resultStatus != undefined)
				{
					switch (response.data.resultStatus) {
						case ApiResultStatus.Success:
							props.setSnackBarProps((props: any) => {
								return {
								variant: "success",
								showSnackBar: true,
								messageInfo: response.data.resultStatusMessage
								};
							});					
							props.updateEvent();
							const newPrevious = previousData.filter((item) => item.studentLeaveOfAbsenceId !== selected.studentLeaveOfAbsenceId);
							setPreviousData(newPrevious);
							break;							
						case ApiResultStatus.Warning:
							props.setSnackBarProps((props: any) => {
								return {
								variant: "warning",
								showSnackBar: true,
								messageInfo: response.data.resultStatusMessage
								};
							});
							break;
						case ApiResultStatus.Error:
							props.setSnackBarProps((props: any) => {
								return {
								variant: "error",
								showSnackBar: true,
								messageInfo: response.data.resultStatusMessage
								};
							});
							break;
						case ApiResultStatus.ConfirmationNeeded:
						{
							props.loaConfirmation({response, handleSubmit: handleSubmitChanges , meta: selected, runValidation })
							break;
						}
						default:
					}						
				}
				else {
					props.setSnackBarProps((props: any) => {
						return {
						variant: "error",
						showSnackBar: true,
						messageInfo: "Error updating leave of absence. "
						};
					});
				}
			},
			(exception: any) => {
				props.setSnackBarProps((snackBarProps: any) => {
				  return {
					variant: "error",
					showSnackBar: true,
					messageInfo: exception,
				  };
				});
			  }
		)}
	};


	
	const validateRow = (selected: ILeaveOfAbsence) => {
		return true;
	};
	

	return (
		
	<Paper className={classes.tableWrapper}>
		<Typography className={classes.cardTitle} >
			LOA History
			</Typography>
			<CardContent className={classes.cardContent}>
		<Table stickyHeader 
			className={classes.table}>
			<EnhancedTableHead classes={classes} />
			<TableBody >
			{TableFunctions.stableSort(
				leaveOfAbsenceData,
				TableFunctions.getSorting(order, orderBy) as any
				).map((row: ILeaveOfAbsence, index: any) => {
				return (
					<TableRow key={row.studentLeaveOfAbsenceId} >
						<TableCell  classes={{
								body: classes.gridCellTypeMedium}}>
							{renderStartDate(row, index)}						
						</TableCell>
						<TableCell classes={{
								body: classes.gridCellTypeMedium}}>
							{renderEndDate(row, index)}
						</TableCell>
						<TableCell classes={{
								body: classes.gridCellTypeMedium}}>
							{renderRequestDate(row, index)}
						</TableCell>						
						<TableCell classes={{
								body: classes.gridCellTypeRemainder}}>
							{renderReason(row)}
						</TableCell>	
						<TableCell classes={{
								body: classes.gridCellTypeMedium}}>
							{renderReturnDate(row, index)}
						</TableCell>					
						<TableCell classes={{
								 body: classes.gridCellTypeSmall}}>
							{new Date(row.modifiedDate).toLocaleDateString()}
						</TableCell>
						<TableCell classes={{
								body: classes.gridCellTypeSmall}}>
							{row.modifiedUser}
						</TableCell>
						<TableCell classes={{
								body: classes.gridCellTypeXSmall}}>
							{renderIcons(row, index)}
						</TableCell>
					</TableRow>
				);
			})} 
			</TableBody>
		</Table>
		</CardContent>
		<ConfirmationModal {...confirmationPopupState} />
	</Paper>


	);
};
	
export default LeaveOfAbsenceTable;
