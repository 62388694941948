
import API from "../../apiWrapper";
import ICreditProgramCourse from '../../../interfaces/setup/academics/ProgramDefinition/ICreditProgramCourse';

export const getProgramCatalogDefinition = (programCatalogId: string): Promise<ICreditProgramCourse[]> => {
    return API()
        .get("/AcademicRecords/ProgramCatalog/GetProgramRequirements", {
            params: {
                programCatalogId
            }
        })
        .then((res: any) => {
            if (res && res.data) {
                return res.data;
            }
            return [];
        }, (_error: any) => {
            return [];
        });
};

export const updateProgramRequirements = (programVersionId: string, definitions: ICreditProgramCourse[]): Promise<ICreditProgramCourse[]> => {
    return API()
        .post("/AcademicRecords/ProgramCatalog/UpdateProgramRequirements", definitions, {
            params: {
                programVersionId
            }
        })
        .then((res: any) => {
            if (res && res.data && res.data.result) {
                return res.data.result;
            }
            return [];
        }, (error: any) => {
            if (error.response) {
                throw new Error(error.response.data);
            }
            throw (new Error('Unknown error'));
        });
}