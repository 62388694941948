export class OtherPhone {
    constructor() {
        this.id = "";
        this.otherContactId = "";
        this.leadId = "";       
        this.phoneTypeId = "";
        this.phoneTypeDesc = "";
        this.phone = "";
        this.extension = "";
        this.isForeignPhone = false;
        this.statusId = "";
        this.status = "";
    }
    id: string;
    otherContactId: string;
    leadId: string;    
    phoneTypeId: string;
    phoneTypeDesc: string;
    phone: string;
    extension: string;
    isForeignPhone: boolean;
    statusId: string;
    status: string;
}