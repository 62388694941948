import API from "../../apiWrapper";
import { EFCHistory } from "../../../interfaces/student/studentAccounts/EFCHistory";

export const getEFCHistory = (studentId: string) => {
	return API()
		.get("/StudentAccount/EFCHistory/GETEFCHistory", {
			params: {
				studentId
			}
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res.data;
				}
			},
			(error: any) => {
				throw new Error(error?.message ?? "Unkown error occured while getting EFC.");
			}
		);
};

export const updateEfcHistory = (studentId: string, eFCs: EFCHistory[]) => {
	return API()
		.post("/StudentAccount/EFCHistory/UpsertEFCHistory",eFCs, {
			params: {
				studentId
			}
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res.data;
				}
			},
			(error: any) => {
				throw new Error(error?.message ?? "Unkown error occured while saving EFC.");
			}
		);
};