import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Typography, Card, CardContent, Divider } from "@material-ui/core";
import DisplayField from "../DisplayField/DisplayField";
import { useState } from "react";
import { useSelector } from "react-redux";
import { StudentProfile } from "../../../interfaces/student/profile/StudentProfile";
import * as ContactCardApi from "../../../api/student/quickInfo/ContactCardApi";
import { Contact } from "../../../interfaces/student/quickInfo/Contact";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    card: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(2),
      "& .MuiCardContent-root": {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
      }
    },
    cardTitle: {
      fontSize: 14,
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightMedium
    }
  })
);

const ContactCard = (props: any) => {
  const classes = useStyles({});
  const [contactModel, setContactModel] = useState<Contact>(new Contact());
  const selectedStudent: StudentProfile = useSelector(
    (state: any) => state.student
  ) as StudentProfile;

  useEffect(() => {
    if (selectedStudent !== undefined) {
      if (
        selectedStudent.leadId !== undefined &&
        selectedStudent.leadId.length > 0
      ) {
        ContactCardApi.getBestContactCard(selectedStudent.leadId).then(
          (response: any) => {
            setContactModel(response.data);
          },
          (exception: any) => {}
        );
      }
    }
  }, [selectedStudent]);

  useEffect(() => {
    let mounted = true;

    const fetchData = () => {};

    fetchData();

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Card className={classes.card} elevation={2}>
      <Typography className={clsx(classes.cardTitle)}><Link to={"/student/1/profile/contactInfo"}>CONTACT</Link></Typography>
      <CardContent>
        <DisplayField
          labelText="Phone"
          valueText={contactModel.phone}
          fieldType="phone"
        ></DisplayField>

        <DisplayField
          labelText="Email"
          valueText={contactModel.email}
          fieldType="email"
        ></DisplayField>

        <DisplayField
          labelText="Address"
          valueText={contactModel.fullAddress}
          fieldType="text"
        ></DisplayField>
      </CardContent>
    </Card>
  );
};

export default ContactCard;
