import API from "../../apiWrapper";
import {
  PostGradesFilter,
  PostGradesModel,
} from "../../../interfaces/student/academics/PostGradesFilter";
import {
  IPostCreditGradesModel,
  ISearchFilters,
  IGradeComponentModel,
	IStudentAttempts,

} from "../../../interfaces/student/academics/PostGradesByClassFilter";
import { ITermSearchParam } from "../../../interfaces/setup/creditHour/ITermSearchModels";

export const getStudentsForPostGrades = (filter: PostGradesFilter) => {
  return API()
    .post("/AcademicRecords/PostGrades/GetStudentsForPostGrades", filter)
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const getStudentsForCreditPostGrades = (filter: ISearchFilters) => {
  return API()
    .post("/AcademicRecords/PostGrades/GetStudentsForCreditPostGrades", filter)
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const getGradeSystemDetailAutoCompleteData = (grdSystemIds:string) => {
  return API()
    .get(`/SystemCatalog/GradeSystem/GetGradeSystemsDetailBySystemIds?grdSystemIds=${grdSystemIds}`)
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const postStudentGrades = (grades: Array<PostGradesModel>) => {
  return API()
    .post("/AcademicRecords/PostGrades/PostStudentGrades", grades)
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const postStudentCreditGrades = (grades: IPostCreditGradesModel, isOverride: boolean) => {
  return API()
    .post(`/AcademicRecords/PostGrades/PostCreditStudentGrades?isOverride=${isOverride}`, grades)
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const getLmsImportGradesLog = () => {
  return API()
    .get("/AcademicRecords/PostGrades/GetLmsImportGradeLogs")
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const importGradesLms = (file: any, campusId: string) => {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("campusId", campusId);

  return API()
    .post("/AcademicRecords/PostGrades/ImportGradesFromLMSFile", formData, {
      responseType: "json",
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetGradeComponentsByStudent = (params: ITermSearchParam) => {
  return API()
    .post("/AcademicRecords/PostGrades/GetGradeComponentsByStudent", params)
    .then(
      (res: any) => {
        if (res && res.data && res.data) {
          return res;
        }
        throw new Error("Unknown error");
      },
      (error: any) => {
        if (error.response) {
          throw new Error(error.response.data);
        }
        throw new Error("Unknown error");
      }
    );
};

export const GetStudentOverallGPA = (stuEnrollId: string) => {
  return API()
    .get("/AcademicRecords/PostGrades/GetStudentOverallGPA", {
      params: {
        stuEnrollId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const CalculateFinalGradesByClass = (stuEnrollId: string, classId: string, overrideFinalGrade: boolean) => {
  return API()
    .get("/AcademicRecords/PostGrades/CalculateFinalGradesByClass", {
      params: {
        stuEnrollId,
        classId,
        overrideFinalGrade,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const postCreditGradesByStudent = (grades: IGradeComponentModel[], isOverride: boolean) => {
  return API()
    .post(`/AcademicRecords/PostGrades/PostCreditGradesByStudent?isOverride=${isOverride}`, grades)
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const deleteFinalCreditGradesByStudent = (stuEnrollId:string, instrClassTermId:string) => {
  return API()
    .post(`/AcademicRecords/PostGrades/DeleteFinalGrade?stuEnrollId=${stuEnrollId}&instrClassTermId=${instrClassTermId}`, null)
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const getGradesByComponent = (componentId: string, stuEnrollId: string) => {
  return API()
    .get("/AcademicRecords/PostGrades/GetGradesByComponent", {
      params: {
        componentId,
        stuEnrollId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const updateGrades = (grades: IStudentAttempts[], isOverride: boolean) => {
	return API()
	  .post(`/AcademicRecords/PostGrades/UpdateGrades?isOverride=${isOverride}`, grades)
	  .then(
		(res: any) => {
		  if (res && res.data) {
			return res;
		  }
		},
		(error: any) => {
		  return error;
		}
	  );
  };
  export const deleteGrades = (grades: IStudentAttempts[], isOverride: boolean) => {
	return API()
	  .post(`/AcademicRecords/PostGrades/DeleteGrades?isOverride=${isOverride}`, grades)
	  .then(
		(res: any) => {
		  if (res && res.data) {
			return res;
		  }
		},
		(error: any) => {
		  return error;
		}
	  );
  };