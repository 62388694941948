import * as React from 'react';
import { Button, Card, CircularProgress, createStyles, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme } from '@material-ui/core';
import CustomCardTitle from '../../../interfaces/common/card/CustomCardTitle';
import CampusForCurrentUserAutoComplete from '../../../components/AutoComplete/CampusForCurrentUserAutoComplete';
import { useActions } from '../../../store/utils';
import { actions as licenseActions, selectors as licenseSelectors } from '../../../store/setup/stateWiseLicense';
import { useSelector } from 'react-redux';
import { CardBody } from 'reactstrap';
import IStateWiseLicense from '../../../interfaces/setup/campus/IStateWiseLicense';
import InputField from '../../../components/_Layout/Inputs/InputField';
import AllStatesAutoComplete from '../../../components/AutoComplete/AllStatesAutoComplete';
import { useForm } from 'react-hook-form';
import { EmptyGuid } from '../../../utils/constants';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			height: '100%',
			minHeight: '100%',
			padding: theme.spacing(3),
			overflowX: 'hidden',
			overflowY: 'auto'
		},
		container: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
			height: '100%'
		},
		details: {
			padding: theme.spacing(2, 0.5),
			flex: '1 1'
		}
	})
);

const StateWiseLicense: React.FC = () => {
	const classes = useStyles({});
	const [campusId, setCampusId] = React.useState<string>();
	const action = useActions({
		getStateWiseLicense: licenseActions.fetchRecord,
		clear: licenseActions.clear,
		createLicense: licenseActions.createLicense,
		deleteLicense: licenseActions.deleteLicense
	}, []);

	const loading = useSelector(licenseSelectors.loadingRecords);
	const list: Array<IStateWiseLicense> = useSelector(licenseSelectors.list);

	React.useEffect(() => {
		if (campusId) {
			action.getStateWiseLicense(campusId);
		}
		else {
			action.clear();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [campusId]);

	const { register, setValue, errors, handleSubmit } = useForm<{ license?: string; stateId?: string }>();
	const [newLicense, setNewLicense] = React.useState<{ license?: string; stateId?: string }>({});
	register({ name: 'stateId' }, { validate: () => !newLicense?.stateId ? 'State is required.' : true });



	const handleNewLicenseChange = (field: keyof { license?: string; stateId?: string }, value: string) => {
		setNewLicense({
			...newLicense,
			[field]: value
		});
		setValue(field, value, true);
	};

	const onSubmit = () => {
		if (campusId === undefined) return;
		action.createLicense({
			license: newLicense.license,
			campusId: campusId,
			stateId: newLicense.stateId,
			licenseId: EmptyGuid
		});
	};

	return (
		<Paper className={classes.root}>
			<div className={classes.container}>
				<Grid container spacing={2}>
					<Grid xs={3} item><CampusForCurrentUserAutoComplete valueFilter={campusId ? {
						key: 'value',
						values: [campusId]
					} : undefined} filterHandle={(v: any) => {
						if (v && v.value) {
							setCampusId(v.value);
						}
						else {
							setCampusId(undefined);
						}
					}} /></Grid>

				</Grid>
				<div className={classes.details}>
					<Card>
						<CustomCardTitle title="State Wise License" />
						<CardBody>
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell variant='head'>State</TableCell>
											<TableCell variant='head'>License</TableCell>
											<TableCell variant='head'></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell><AllStatesAutoComplete
												disabled={loading || !campusId}
												name='stateId'
												hideLabel
												error={!!errors.stateId}
												helperText={errors.stateId?.message}
												valueFilter={
													newLicense?.stateId
														? {
															key: 'value',
															values: [newLicense?.stateId]
														}
														: undefined
												}
												filterHandle={(v: any) => {
													handleNewLicenseChange('stateId', v ? v.value : undefined);
												}} /></TableCell>
											<TableCell><InputField
												disabled={loading || !campusId}
												onChange={(e) => handleNewLicenseChange('license', e.target.value ?? '')}
												inputRef={register({ required: 'License is required.' })}
												name='license'
												error={!!errors.license}
												helperText={errors.license?.message}
												fullWidth /></TableCell>
											<TableCell><Button disabled={loading || !campusId} variant='contained' color='primary' onClick={handleSubmit(onSubmit)}>Add</Button></TableCell>
										</TableRow>
										{
											loading && <TableRow>
												<TableCell colSpan={3} align="center"><CircularProgress size={50} color="primary" /></TableCell>
											</TableRow>
										}
										{
											!loading &&
											list.map((item: IStateWiseLicense) => {
												return (<TableRow key={item.licenseId}>
													<TableCell><AllStatesAutoComplete
														disabled
														name={`stateId${item.licenseId}`}
														hideLabel
														valueFilter={
															item?.stateId
																? {
																	key: 'value',
																	values: [item?.stateId]
																}
																: undefined
														}/></TableCell>
													<TableCell>{item.license}</TableCell>
													<TableCell><Button variant='contained' color='secondary' onClick={() => action.deleteLicense(item.licenseId ?? '')}>Delete</Button></TableCell>
												</TableRow>);
											})
										}
									</TableBody>
								</Table>
							</TableContainer>
						</CardBody>
					</Card>
				</div>
			</div>
		</Paper>);
};

export default StateWiseLicense;