import React from 'react';
import {
    makeStyles,
    createStyles,
    Grid,
    Paper,
    Table,
    withStyles,
    TableCell,
    TableRow,
    TableContainer,
    TableHead,
    TableBody,
    IconButton,
    CircularProgress,
    Button,
    Card,
    CardContent,
    TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { format, addDays } from "date-fns";
import ICourseGradeBook from '../../../../interfaces/setup/academics/ProgramDefinition/ICourseGradeBook';
import  IGradeSystem  from "../../../../interfaces/setup/grades/ComponentTypes/IGradeSystem";
import  IGradeSystemDescriptionDetail  from "../../../../interfaces/setup/grades/ComponentTypes/IGradeSystemDescriptionDetail";
import * as componentTypeApi from "../../../../api/setup/grades/componentTypesApi";
import InputField from '../../../../components/_Layout/Inputs/InputField';
import CustomCardTitle from '../../../../interfaces/common/card/CustomCardTitle';
import { getCourseGradeBooks } from '../../../../api/setup/academics/courses';
import { EmptyGuid } from '../../../../utils/constants';
import { FieldError, useForm, FormContext } from 'react-hook-form';
import { CustomSnackBarProps } from '../../../../interfaces/common/CustomSnackBarProps';
import IGradeSystemDetail from '../../../../interfaces/setup/grades/ComponentTypes/IGradeSystemDetail';
import ProgressSaveButton from '../../../../components/_Layout/Buttons/ProgressSaveButton';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import MultiCampusAutoComplete from '../../../../components/AutoComplete/MultiCampusAutoComplete';
import StatusAutoComplete from "../../../../components/AutoComplete/StatusAutoComplete";
import GradeSystemAutoComplete from "../../../../components/AutoComplete/GradeSystemAutoComplete";
import GradeDescriptionDetailGrid from './GradeDescriptionDetailGrid';
import AddNewComponent from '../../academics/ProgramProgramVersion/ProgramVersion/Definition/AddCoursePopUP/AddNewComponent';
const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {},
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
    createStyles({
        cardTitle: {
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            backgroundColor: theme.palette.site.secondary,
            padding: theme.spacing(0)
        },
        headerText: {
            float: 'left',
            padding: theme.spacing(1.5),
        },
        cardContent: {
            padding: theme.spacing(1, 3),
            height: '100%',
        },
        saveButton: {
            width: 90,
        },
        table: {
            width: '100%',
        },
        textField: {
          width: "100%",
        },
        iconColor: {
            color: theme.palette.black
        },
        searchField: {
            '&::placeholder': {
                color: theme.palette.black
            }
        },
        selectionCheckBox: {
            padding: theme.spacing(0)
        },
        preLoaderCell: {
            textAlign: 'center',
            padding: theme.spacing(1)
        },
        dialogCloseButton: {
            float: "right",
        },
        courseSelectionCell: {
            color: theme.palette.paperSummarySchedulerTitle,
            cursor: 'pointer',
            fontWeight: 500
        },
        alignCenter: {
            textAlign: "center",
        },
        dateInput: {
            marginRight: theme.spacing(1),
            width: '100%'
        }
    })
);
type GradeBookDetailsProps = {
    grdSystemId?: string;
    campusId?: string;
    repeatedExamPolicy?: string;
    model: IGradeSystem;
    showSnackBar: (param: CustomSnackBarProps) => void;
    refreshList: () => void;
    setConfirmationDialog: (param: { onOk?: () => (void | Promise<void>); open: boolean; onCancel?: () => (void | Promise<void>); }) => void;
};

const GradeDetailDescriptionDetailUpdated = (
    props: Readonly<GradeBookDetailsProps>
) => {
    const classes = useStyles({});
    const [model, setModel] = React.useState<IGradeSystemDescriptionDetail>({ gradeSystemDetails: [], grdSystemId: EmptyGuid });
    const [isLoading, setIsloading] = React.useState<boolean>(false);
    const data = useForm<IGradeSystem>({ mode: "onBlur" });
    const [addNewComponent, setAddNewComponentOpen] = React.useState<boolean>(false);

    const { handleSubmit } = data;

    //data.register('effectiveStartDate', { validate: () => !model. ? 'Effective start date is required.' : (((model?.effectiveStartDate).toString() !== 'Invalid Date') ? true : 'Invalid Date') });

    

    const addGradeComponent = (component: IGradeSystemDetail) => {
        model.gradeSystemDetails.push(component);
        model.gradeSystemDetails = [...model.gradeSystemDetails];
        handleFieldChange("gradeSystemDetails", model.gradeSystemDetails);
    }

    const updateGradeComponent = (component: IGradeSystemDetail, index: number) => {
        model.gradeSystemDetails[index] = component;
        model.gradeSystemDetails = [...model.gradeSystemDetails];
        handleFieldChange("gradeSystemDetails", model.gradeSystemDetails);
    }

    const deleteGradeComponent = (index: number) => {
        model.gradeSystemDetails.splice(index, 1);
        model.gradeSystemDetails = [...model.gradeSystemDetails];
        handleFieldChange("gradeSystemDetails", model.gradeSystemDetails);
    }

    const saveDetails = async (confirm?: boolean) => {
        if (!isLoading) {
            setIsloading(true);
            // if (!confirm) {
            //     if (!confirm && props.grdScaleId !== EmptyGuid) {
            //         props.setConfirmationDialog({ open: true, onOk: () => saveDetails(true), onCancel: () => setIsloading(false) })
            //         return;
            //     }
            // }
            try {
                const newDetails = await componentTypeApi.UpsertGradeSystem(model);
                if (newDetails && newDetails.details  ) {
                    data.reset(newDetails.details );
                    setModel(newDetails.details );
                    }
            }
            catch (err) {
                props.showSnackBar({
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: (err as Error).message,
                });
            }
            setIsloading(false);
        }
    }

    const handleFieldChange = (fieldId: keyof IGradeSystemDescriptionDetail, value: any) => {
      if (model) {
          let updatedModel = model;
          (updatedModel as any)[fieldId] = value;
          setModel({ ...updatedModel });
          if (fieldId !== 'gradeSystemDetails') {
              data.setValue(fieldId, value);
              data.triggerValidation(fieldId);
          }
      }
  };

   

    

    const loadDetails = async () => {
      if (props.model.grdSystemId !== EmptyGuid) {
      componentTypeApi.GetGradeSystemById(props.model.grdSystemId ??EmptyGuid).then(
        (response: any) => {
          if (response && response.data  && response.data.result) {
                      data.reset(response.data.result);
                      setModel(response.data.result);
          }
        },
        (exception: any) => { 
          props.showSnackBar({
            variant: "error",
            showSnackBar: true,
            messageInfo: (exception as Error).message,
        });


        }
        );
        setIsloading(false);
        
    }
    else {
        setModel({ ...props.model, gradeSystemDetails: [] });
        data.reset(model);
  }
  
  }


    React.useEffect(() => {
        loadDetails();
    }, [props.model]);

    return (
        <Card square={true} elevation={2} >
            <CustomCardTitle title='Grade Description Details'></CustomCardTitle>
            <CardContent>
                <Grid container direction="column" spacing={2} wrap="nowrap">
                    {(!isLoading && (<Grid
                        item
                        alignContent='flex-start'
                        alignItems='flex-start'
                        justify='flex-start'
                    >
                        
                        <Grid container direction="row" spacing={2} wrap="nowrap">
                            <Grid item xs={12}>
                                <GradeDescriptionDetailGrid
                                    addGradeComponent={addGradeComponent}
                                    updateGradeComponent={updateGradeComponent}
                                    deleteGradeComponent={deleteGradeComponent}
                                    campusId={props.campusId}
                                    grdSystemId={props.model.grdSystemId}
                                    systemDetail={model?.gradeSystemDetails ?? []} />
                                     
                            </Grid>
                        </Grid>
                    </Grid>))
                        || <div className={classes.alignCenter}><CircularProgress /></div>}
                    <Grid
                        item
                        alignContent='flex-start'
                        alignItems='flex-start'
                        justify='flex-start'
                    >
                        <Grid container direction='row' spacing={1} wrap="nowrap">
                            <Grid
                                item
                                alignContent='flex-start'
                                alignItems='flex-start'
                                justify='flex-start'
                            >
                                <ProgressSaveButton
                                    text="Save"
                                    onClick={handleSubmit(() => saveDetails(false))}
                                    loading={isLoading}
                                    disabled={( model.grdSystemId==EmptyGuid) ? true : false}
                                    buttonClassName={classes.saveButton}
                                    size="small"
                                ></ProgressSaveButton>
                            </Grid>
                            <Grid
                                item
                                alignContent='flex-start'
                                alignItems='flex-start'
                                justify='flex-start'
                            >
                                <Button
                                    disabled={isLoading}
                                    onClick={loadDetails}
                                    size='small'
                                    color='primary'
                                    variant='contained'
                                    type='button'
                                >Cancel</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        alignContent='flex-start'
                        alignItems='flex-start'
                        justify='flex-start'
                    >
                        <Grid container direction='row' spacing={1} wrap="nowrap">
                            <Grid
                                item
                                alignContent='flex-start'
                                alignItems='flex-start'
                                justify='flex-start'
                            >
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        alignContent='flex-start'
                        alignItems='flex-start'
                        justify='flex-start'
                    >
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default GradeDetailDescriptionDetailUpdated;
