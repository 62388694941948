export const StatesApiAutoComplete = {
  config: {
    acId: "state",
    cacheTime: 5,
    route: "/SystemCatalog/States/GetStatesByCountryId",
    requestType: "GET",
    body: null,
    hasUrlParams: true,
    useCache: false,
    storeInCache: false,
    isCampusSpecific: false,
    isUserSpecific: false,
    loadOnRender: true,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
      return data;
    }
  },
  allStatesConfig:{
    acId: "allStates",
    cacheTime: 5,
    route: "/SystemCatalog/States/GetAllStates",
    requestType: "GET",
    body: null,
    hasUrlParams: false,
    useCache: false,
    storeInCache: false,
    isCampusSpecific: false,
    isUserSpecific: false,
    loadOnRender: true,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
      return data;
    }
  },
  stateboardStatesConfig:{
    acId: "StateBoardStates",
    cacheTime: 5,
    route: "/SystemCatalog/States/GetStateBoardStates",
    requestType: "GET",
    body: null,
    hasUrlParams: false,
    useCache: false,
    storeInCache: false,
    isCampusSpecific: false,
    isUserSpecific: false,
    loadOnRender: true,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
      return data;
    }
  },
  stateboardReportsConfig:{
    acId: "StateBoardReports",
    cacheTime: 5,
    route: "/StateBoardSettings/GetStateBoardReports",
    requestType: "GET",
    body: null,
    hasUrlParams: true,
    useCache: false,
    storeInCache: false,
    isCampusSpecific: false,
    isUserSpecific: false,
    loadOnRender: false,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
      return data;
    }
  },
  mappedProgramVersionsConfig: {
    acId: "mappedProgramVersions",
    cacheTime: 5,
    route: "/StateBoardSettings/GetMichiganStateBoardProgramVersions",
    requestType: "GET",
    body: null,
    hasUrlParams: true,
    useCache: false,
    storeInCache: false,
    isCampusSpecific: true,
    isUserSpecific: false,
    loadOnRender: true,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
      return data;
    },
  },
};
