import React from "react";
import {
  makeStyles,
  createStyles,
  Card,
  Grid,
} from "@material-ui/core";

import * as ProgramVersionApi from "../../../../../../api/setup/academics/programVersionApi";
import { ProgramVersionTabProps } from "../../../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersionTabProps";
import SchedulesGrid from "./SchedulesGrid";
import ProgressSaveButton from "../../../../../../components/_Layout/Buttons/ProgressSaveButton";
import {
  IScheduleGridRow,
  ScheduleGridRow,
} from "../../../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/Schedule/IScheduleGridRow";
import ScheduleDetails from "./ScheduleDetails";
import { EmptyGuid } from "../../../../../../utils/constants";
const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%",
    },
    cardWrapper: {
      height: "100%",
      padding: theme.spacing(2),
    },
    saveButton: {
      width: 150,
    },
  })
);
const ProgramVersionScheduleTab = (props: ProgramVersionTabProps) => {
  const classes = useStyles({});
  const [tab, setTab] = React.useState(1);
  const [selectedScheduleId, setSelectedScheduleId] = React.useState<string>();
  const [selectedSchedule, setSelectedSchedule] = React.useState<
    IScheduleGridRow | undefined
  >(undefined);
  const [trackChangesModel, setTrackChangesModel] = React.useState<
    IScheduleGridRow
  >(new ScheduleGridRow());
  const [schedules, setSchedules] = React.useState<Array<IScheduleGridRow>>(
    new Array<IScheduleGridRow>()
  );
  const handleChange = (event: any, newValue: any) => {
    setTab(newValue);
  };

  const addNewRecord = () => {
    let newSchedule = new ScheduleGridRow();
    newSchedule.programVersionId = props.programVersionId;
    newSchedule.active = true;
    setSelectedSchedule({ ...newSchedule });
  };

  React.useEffect(() => {
    if (props.programVersionId && props.programVersionId !== EmptyGuid) {
      getSchedules();
    }
  }, [props.programVersionId]);

 const refreshSchedules = () => {
  getSchedules();
  addNewRecord();  
 }

  const getSchedules = () => {
    if (props.programVersionId) {
      ProgramVersionApi.GetSchedules(props.programVersionId).then(
        (response: Array<IScheduleGridRow>) => {
          if (response && response.length > 0) {
            let newData = [...response]
            setSchedules(newData);
          } else {
            setSchedules(new Array<IScheduleGridRow>());
          }
        },
        (exception: any) => { }
      );
    }
  };
  const resetChanges = () => {
    let resetModel: ScheduleGridRow = trackChangesModel;
    setSelectedSchedule({ ...resetModel });
  }
  return (
    <Card square={true} className={classes.cardWrapper} hidden={props.hidden}>
      <SchedulesGrid
        programVersionId={props.programVersionId}
        setCurrentScheduleDetails={setSelectedSchedule}
        schedules={schedules}
        setNoChangeModel={setTrackChangesModel}
      />
      <Grid container direction="row" spacing={1}>
        <Grid
          item
          container
          xs={1}
          alignContent="flex-start"
          alignItems="flex-start"
          justify="flex-start"
        >
          <ProgressSaveButton
            text="Add New"
            type="button"
            onClick={(e: any) => {
              addNewRecord();
            }}
            loading={false}
            disabled={false}
            buttonClassName={classes.saveButton}
          ></ProgressSaveButton>
        </Grid>
      </Grid>
      {selectedSchedule !== undefined && (
        <ScheduleDetails
          resetChanges={resetChanges}
          model={selectedSchedule}
          setModel={setSelectedSchedule}
          clearFields={addNewRecord}
          setSnackBar={props.setSnackBar}
          refreshSchedules={refreshSchedules}
          setSchedules={setSchedules}
        />
      )}
    </Card>
  );
};

export default ProgramVersionScheduleTab;
