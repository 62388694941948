
import API from "../../apiWrapper";
import IProgramCourse from '../../../interfaces/setup/academics/ProgramDefinition/IProgramCourse';

export const getProgramVersionDefinition = (programVersionId: string): Promise<IProgramCourse[]> => {
    return API()
        .get("/AcademicRecords/ProgramVersions/GetProgramVersionDefinition", {
            params: {
                programVersionId
            }
        })
        .then((res: any) => {
            if (res && res.data) {
                return res.data;
            }
            return [];
        }, (_error: any) => {
            return [];
        });
};

export const updateProgramVersionDefinition = (programVersionId: string, definitions: IProgramCourse[]): Promise<IProgramCourse[]> => {
    return API()
        .post("/AcademicRecords/ProgramVersions/UpdateProgramVersionDefinition", definitions, {
            params: {
                programVersionId
            }
        })
        .then((res: any) => {
            if (res && res.data && res.data.result) {
                return res.data.result;
            }
            return [];
        }, (error: any) => {
            if (error.response) {
                throw new Error(error.response.data);
            }
            throw (new Error('Unknown error'));
        });
}