import { IProgramVersionGeneralDetails, ProgramVersionGeneralDetails } from "./ProgramVersion/IProgramVersionGeneralDetails";
import { IProgramVersionAcademicDetails, ProgramVersionAcademicDetails } from "./ProgramVersion/IProgramVersionAcademicDetails";
import { IProgramVersionAttendanceDetails, ProgramVersionAttendanceDetails } from "./ProgramVersion/IProgramVersionAttendanceDetails";
import { IProgramVersionFinancialDetails, ProgramVersionFinancialDetails } from "./ProgramVersion/IProgramVersionFinancialDetails";
export interface IProgramVersionDetails{
    general: IProgramVersionGeneralDetails;
    academics: IProgramVersionAcademicDetails;
    attendance: IProgramVersionAttendanceDetails;
    financial: IProgramVersionFinancialDetails;
}

export class ProgramVersionDetails implements IProgramVersionDetails{
    attendance: IProgramVersionAttendanceDetails = new ProgramVersionAttendanceDetails();
    financial: IProgramVersionFinancialDetails = new ProgramVersionFinancialDetails();
    general: IProgramVersionGeneralDetails = new ProgramVersionGeneralDetails();
    academics: IProgramVersionAcademicDetails = new ProgramVersionAcademicDetails();
}