import API from "../apiWrapper";
import { IGetFileParams } from "../../interfaces/storage/IGetFileParams";
import {getReportFileName} from "../../utils/getReportFileName"
import { stringify } from "qs";
import { IReportFileInfo } from "../../interfaces/storage/IReportFileInfo";


export const getFileUri = (fileParams: IGetFileParams) => {
  return API()
    .post("/Maintenance/Storage/GetFileUri", fileParams)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data.result as string;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const getFilePreview = (fileParams: IGetFileParams) => {
  return API()
    .post("/Maintenance/Storage/GetFile", fileParams, { responseType: "blob" })
    .then(
      (res: any) => {
        if (res && res.data) {
          let img = new Blob([res.data]);
          const url = window.URL.createObjectURL(img);
          return url;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const getFile = (fileParams: IGetFileParams) => {
  return API()
    .post("/Maintenance/Storage/GetFile", fileParams, { responseType: "blob" })
    .then(
      (res: any) => {
        if (res && res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileParams.displayName);
          document.body.appendChild(link);
          link.click();
        }
      },
      (error: any) => {
        return error;
      }
    );
};


export const getReportFile = (fileParams: IReportFileInfo) => {
    return API()
        .post("/Maintenance/Storage/GetReportFile", fileParams, { responseType: "blob" })
        .then(
            (res: any) => {
                if (res && res.data) {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileParams.displayName);
                    document.body.appendChild(link);
                    link.click();
                }
            },
            (error: any) => {
                return error;
            }
        );
};
