import React, { useState, forwardRef } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Button, Grid } from "@material-ui/core";
import CustomFieldsGrid from "./CustomFieldsGrid";
import UpsertCustomField from "./UpsertCustomField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.paper.background,
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2)
			}
		},
		"& .makeStyles-content": {
			backgroundColor: theme.palette.paper.background
		},
		paperRoot: {
			padding: theme.spacing(2),
			width: "100%",
			minHeight: "100%",
			height: "100%",
			overflowX: "hidden",
			overflowY: "auto",
			flexDirection: "column",
			backgroundColor: theme.palette.paper.background
		},
		buttonColorPrimary: {
			backgroundColor: theme.palette.buttons.primary,
			color: theme.palette.primary.contrastText,
			"&:hover": {
				backgroundColor: theme.palette.primary.main
			},
			textDecoration: "bold",
			padding: theme.spacing(1),
			marginTop: theme.spacing(3),
			whiteSpace: "nowrap"
		},
		cardSeparator: {
			marginBottom: theme.spacing(1)
		},
		backButton: {
			marginBottom: theme.spacing(3)
		}
	})
);

const CustomFieldsSetup = (props: any) => {
	const classes = useStyles({});

	const [isCreatingNewField, setIsCreatingNewField] = useState<boolean>(false);

	const addButtonClicked = () => {
		setIsCreatingNewField(true);
	};

	return (
		<div>
			{!isCreatingNewField && (
				<Paper className={classes.paperRoot} square={true}>
					<div className={classes.cardSeparator}>
						<CustomFieldsGrid></CustomFieldsGrid>
					</div>

					<Button
						className={classes.buttonColorPrimary}
						onClick={() => addButtonClicked()}
						size="small"
					>
						ADD
					</Button>
				</Paper>
			)}

			{isCreatingNewField && (
				<Paper className={classes.paperRoot} square={true}>
					<div className={classes.cardSeparator}>
						<Grid item>
							<Button
								className={classes.backButton}
								color="secondary"
								variant="contained"
								onClick={() => {
									setIsCreatingNewField(false);
								}}
							>
								<ArrowBackIcon /> Back
							</Button>
						</Grid>
						<UpsertCustomField></UpsertCustomField>
						<Grid item>
							<Button
								className={classes.buttonColorPrimary}
								onClick={() => addButtonClicked()}
								variant="contained"
								size="medium"
							>
								SAVE
							</Button>
						</Grid>
					</div>
				</Paper>
			)}
		</div>
	);
};

export default CustomFieldsSetup;
