import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
	Typography,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TableHead,
	Paper,
	Link,
	CardContent
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker
} from '@material-ui/pickers';
import * as StatusHistoryApi from '../../../api/student/status/StatusHistoryApi';
import { IEnrollmentStatus } from '../../../interfaces/student/status/IEnrollmentStatus';
import { Order } from '../../../interfaces/common/Table/CustomHeadCell';
import * as TableFunctions from '../../../utils/tableFunctions';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import RemoveIcon from '@material-ui/icons/DeleteOutline';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';

import LeaveOfAbsenceModal from '../../../pages/student/status/LeaveOfAbsenceModal';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SuspensionModal from '../../../pages/student/status/SuspensionModal';
import { ApiResultStatus } from '../../../enums/ApiResultStatus'; 
import { SystemEnrollmentStatus } from '../../../enums/SystemEnrollmentStatus'; 
import { IStatusChangeEnrollment } from '../../../interfaces/student/status/IStatusChangeEnrollment';
import AddIcon from '@material-ui/icons/Add';
import AddNewStatusModel from './AddNewStatus';
import * as CurrentlyAttendingApi from '../../../api/student/status/CurrentlyAttendingApi';
import RemoveReasonModal from './RemoveReasonModal';
import AccessManager from '../../../components/security/AccessManager';
import { security } from '../../../constants/Security/securityConstants';
import { IDropReason } from '../../../interfaces/student/status/IDropReason';
import { checkPermissions } from "../../../utils/securityFunctions"

import * as api from '../../../api/reports/attendance/studentStatusHistoryApi';
import IReportConfig from '../../../interfaces/reports/IReportConfig';
import {ReportUrl} from '../../../constants/report.url';

type StatusHistoryTableProps = {
	setSnackBarProps: any;
	updateEvent: any;
	refreshFlag: boolean;
};

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.background.paper,
			'& .MuiCardContent-root': {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2),
			},
		},
		tableWrapper: {
			width: '100%',
		},
		table: {
			minWidth: '750',			
		},
		gridHeadTypeXSmall: {
			width: '5%',
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
			backgroundColor: theme.palette.secondary.dark,
			minWidth: '60px',
		},
		gridCellTypeXSmall: {
			width: '5%',
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0)
		},		
		gridHeadTypeSmall: {
			width: '10%',
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
			backgroundColor: theme.palette.secondary.dark,
		},
		gridCellTypeSmall: {
			width: '10%',
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0)
		},
		gridHeadTypeMedium: {
			width: '15%',
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
			backgroundColor: theme.palette.secondary.dark,
		},
		gridCellTypeMedium: {
			width: '15%',
			height: '46px', //match datepicker height on edit
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
		},
		gridHeadTypeLarge: {
			width: '20%',
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
			backgroundColor: theme.palette.secondary.dark,
		},
		gridCellTypeLarge: {
			width: '20%',
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0)
		},	
		gridHeadTypeRemainder: {
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
			backgroundColor: theme.palette.secondary.dark,
		},
		gridCellTypeRemainder: {
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
		  },			
		icons: {
			fontSize: theme.spacing(2),
		},
		addIcon : {
			float : 'right',
			marginTop :  theme.spacing(-1.56)
		},
		gridHeader: {
			backgroundColor: theme.palette.secondary.dark,
			width: '100%',
		},
		cardTitle: {
			fontSize: theme.spacing(2),
			color: theme.palette.black,
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1.5, 1),
			height : theme.spacing(6)
		  },
		  cardContent: {
			padding: theme.spacing(1, 1.5),
			maxHeight:'350px',
			overflowY: 'auto',
		  },
		  datePicker: {
			'& .MuiIconButton-root': {
				padding: theme.spacing(0.8)
			}
		}

	})
);



function generateReport(studentEnrollmentId: string) {
	let params = {
	
		stuEnrollId: studentEnrollmentId,
		exportType: 1,
		//campusId: ,
	};
	return api.generateReport(params);
}

const StatusHistoryTable = (props: 
	StatusHistoryTableProps) => {
	const classes = useStyles({});

	const isSupportUser = useSelector(
        (state: any) => state.session.user.isSupportUser
    );

	interface HeadCell {
		disablePadding: boolean;
		id: keyof IEnrollmentStatus;
		label: string;
		numeric: boolean;
		widthType: 'L' | 'M' | 'S' | 'XS' | 'R';
	}
	
	
	const headCells: HeadCell[] = [
		{
			id: 'dateOfChange',
			numeric: false,
			disablePadding: false,
			label: 'Effective Date',
			widthType: 'M',
		},
		{
			id: 'newStatusDescription',
			numeric: false,
			disablePadding: false,
			label: 'Status',
			widthType: 'L',
		},
		{
			id: 'changeReason',
			numeric: false,
			disablePadding: false,
			label: 'Reason for Change',
			widthType: 'R',
		},
		{
			id: 'modifiedDate',
			numeric: true,
			disablePadding: false,
			label: 'Modified Date',
			widthType: 'M',
		},
		{
			id: 'modifiedUser',
			numeric: false,
			disablePadding: false,
			label: 'Modified By',
			widthType: 'S',
		},	
		{
			id: 'studentStatusChangeId',
			numeric: false,
			disablePadding: false,
			label: '',
			widthType: 'XS',
		},	  			  			
	];
	
	interface EnhancedTableProps {
		classes: ReturnType<typeof useStyles>;
	}

	function EnhancedTableHead(props: EnhancedTableProps) {
		const {
			classes,
		} = props;
	
		return (
			<TableHead >
				<TableRow className={classes.gridHeader}>
					{headCells.map(headCell => (
						<TableCell
							key={headCell.id}
							align="left"
							padding={headCell.disablePadding ? 'none' : 'default'}
							className={
								headCell.widthType == 'S'
									? classes.gridHeadTypeSmall
									: headCell.widthType == 'M'
										? classes.gridHeadTypeMedium
										: headCell.widthType == 'L'
											? classes.gridHeadTypeLarge	
											: headCell.widthType == 'XS'
												? classes.gridHeadTypeXSmall
												: headCell.widthType == 'R'
													? classes.gridHeadTypeRemainder									
													: classes.gridHeadTypeRemainder
							}
						>
							{headCell.label}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
		);
	}


	const [historyData, setHistoryData] = React.useState<Array<IEnrollmentStatus>>([]);
	const [order, setOrder] = React.useState<Order>('desc');
	const [orderBy, setOrderBy] = React.useState<keyof IEnrollmentStatus>('dateOfChange');

	const [LeaveOfAbsenceModalOpen, setLeaveOfAbsenceModalOpen] = React.useState(false);
	//const [ProbationModalOpen, setProbationModalOpen] = React.useState(false);
	const [SuspensionModalOpen, setSuspensionModalOpen] = React.useState(false);
	const [NewStatusModelOpen, setNewStatusModelOpen] = React.useState(false);
	const [RemoveModelOpen, setRemoveModelOpen] = React.useState(false);
	const [RemovalConsent, setRemovalConsent] = React.useState(false);
	const [ConfirmationMessage, setConfirmationMessage] = React.useState('');
	const [deletionLoading, setDeletionLoading] = React.useState<boolean>(false);

	const [previousData, setPreviousData] = React.useState<Array<IEnrollmentStatus>>([]);
	const [changeEnrollment, setChangeEnrollment] = React.useState<IStatusChangeEnrollment>();
	const [selectedRow, setSelectedRow] = React.useState<IEnrollmentStatus>();
	const [selectedReason, setSelectedReason] = React.useState<IDropReason>();
	const [reasonData, setReasonData] = React.useState<Array<IDropReason>>([]);

	const selectedEnrollment = useSelector(
		(state: any) => state.student.selectedEnrollment
	);
	const [confirmationPopupState, setConfirmationPopupState] = React.useState<any>({
		id: 'simple-popup',
		message: ''
	});
	  
	  React.useEffect(() => {
		setRemovalConsent(false);
		setConfirmationMessage('');
	}, [RemoveModelOpen]);

	const refreshGridData=()=>{
		if (selectedEnrollment && selectedEnrollment.stuEnrollId) {
			let studentEnrollmentId = selectedEnrollment.stuEnrollId;
			StatusHistoryApi.getStatusHistoryByEnrollmentId(studentEnrollmentId).then(
				(response: Array<IEnrollmentStatus>) => {
					if (response && response.length > 0) {
						setHistoryData(response);
					}
				},
				(exception: any) => {}
			);
		}
	};
	const refreshChangeEnrollment=()=>{
		if (selectedEnrollment && selectedEnrollment.stuEnrollId) {
			let studentEnrollmentId = selectedEnrollment.stuEnrollId;
			StatusHistoryApi.getStatusChangeEnrollment(studentEnrollmentId).then(
				(response: IStatusChangeEnrollment) => {
					if (response) {
						setChangeEnrollment(response);
					}
				},
				(exception: any) => {}
			);
		}
	};
	React.useEffect(() => {
		refreshGridData();
		refreshChangeEnrollment();
		StatusHistoryApi.getDropReasons(selectedEnrollment.campusId).then(
			(response: Array<IDropReason>) => {
				if (response && response.length > 0) {
					setReasonData(response);
				}
			},
			(exception: any) => {}
		);
	}, [selectedEnrollment, props.refreshFlag]);
	
	const renderStatus = (row: IEnrollmentStatus) => {
		let status;

		{(row.sysStatusId == SystemEnrollmentStatus.LeaveOfAbsence) ?
			status=
			<Link
				component="button"
				variant="inherit"
				onClick={() => {handleLinkToLOA(row);}}
			>{row.newStatusDescription}
			</Link>
			:  (row.sysStatusId == SystemEnrollmentStatus.Suspension) ?
				status = 
				<Link
					component="button"
					variant="inherit"
					onClick={() => {handleLinkToSuspension(row);}}
				>{row.newStatusDescription}
				</Link>
			// : (row.sysStatusId == SystemEnrollmentStatus.DisciplinaryProbation || row.sysStatusId == SystemEnrollmentStatus.WarningProbation) ?
			// 	status = 
			// 		<Link
			// 			component="button"
			// 			variant="inherit"
			// 			onClick={() => {handleLinkToProbation(row);}}
			// 		>{row.newStatusDescription}
			// 		</Link>			
				:status = row.newStatusDescription; //no link
		}

		return status;
	};

	const renderReason = (row: IEnrollmentStatus) => {
		let reason;
		{(row.isEditMode && row.sysStatusId === SystemEnrollmentStatus.Dropped) ?
			reason = 
			<Autocomplete
				id={'reason_dropReason'}
				options={reasonData}
				value={selectedReason}
				getOptionLabel={(option) => (option != null)? option.text: ''}
				onChange={(e: any, v: any) => {
					handleReasonOnChange(row, v);
				}}
				renderInput={(params) => (
					<TextField {...params}  />
				)}
			/>
			: reason = row.changeReason;
		}

		return reason;
	};
	const renderIcons = (row: IEnrollmentStatus) => {
		let icons;

		{(row.isEditMode) ?
			icons = <span>
				<IconButton aria-label="submit" onClick = {() => {handleSubmitChanges(row);}} size="small">
					<CheckIcon className = {classes.icons} />
				</IconButton> 
				<IconButton aria-label="cancel" onClick = {() => {handleCancelEditing(row);}} size="small">
					<ClearIcon className = {classes.icons} />
				</IconButton> 
			</span>
			: icons =
				<span>
					<IconButton disabled={(row.sysStatusId == 10 || row.sysStatusId == 11)? true : false} aria-label="edit" onClick = {() => {handleStartEditing(row);}} size="small">
						<EditIcon className = {classes.icons} />
				   </IconButton>

				   <AccessManager
						allowedPermissions={[security.permissions.statusHistory.deleteStatusHistory]}
						renderNoAccess={() => null}
                	>
						<IconButton disabled={(row.sysStatusId == 10 && checkPermissions(["RestrictDeleteLOA"]) && !isSupportUser)? true : false} aria-label="edit" onClick = {() => {deleteStatusHistory(row);}} size="small">
							<RemoveIcon className = {classes.icons} />
						</IconButton>
					</AccessManager>				   
				</span>;	
		}

		return icons;
	};

	const renderEffectiveDate = (row: IEnrollmentStatus) => {
		let effDate;

		{(row.isEditMode) ?
			effDate = 
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<KeyboardDatePicker
					disableToolbar
					autoOk={true}
					variant="inline"
					format="MM/dd/yyyy"
					id={'dateOfChange_' + row.studentStatusChangeId}
					name={'dateOfChange_' + row.studentStatusChangeId}
					value={row.dateOfChange}
					onChange={(v: any) => {
						handleFieldOnChange(row, 'dateOfChange', v ? v : undefined);
					}}
					className={classes.datePicker}
					KeyboardButtonProps={{
						'aria-label': 'change date',
					}}
				/>
			</MuiPickersUtilsProvider>
			: effDate = 
			new Date(row.dateOfChange).toLocaleDateString();
		}

		return effDate;
	};


	const handleLinkToLOA = (row: IEnrollmentStatus) => {
		setLeaveOfAbsenceModalOpen(true);
	};

	const handleLinkToSuspension = (row: IEnrollmentStatus) => {
		setSuspensionModalOpen(true);
	};

	const deleteStatusHistory =  (selected: IEnrollmentStatus) => {
		if(historyData && historyData.length == 1 )
		{
		  props.setSnackBarProps((props: any) => {
			  return {
			  variant: 'error',
			  showSnackBar: true,
			  messageInfo: 'You can not remove this record because at least one history change is mandatory for the student. Try creating another history change record and you will be able to remove this one.'
			  };
		  });    
		  return;
		}

		setRemoveModelOpen(true);
		setSelectedRow(selected);
	};

	const deletionReasonCallback = (deleteReasonId : string | undefined) => {
		setDeletionLoading(true);
		CurrentlyAttendingApi.DeleteStatusHistory(selectedEnrollment.stuEnrollId, selectedRow?.studentStatusChangeId, deleteReasonId, RemovalConsent).then(
			(response:any) => {
				setDeletionLoading(false);
				if (response && response.data && response.data.resultStatusMessage) {
					if(response.data.confirmationNeeded)
					{
						setRemovalConsent(true);
					    setConfirmationMessage(response.data.resultStatusMessage);
					}
					else{
						refreshGridData();
						props.setSnackBarProps((props: any) => {
							return {
								variant: 'success',
								showSnackBar: true,
								messageInfo: response.data.resultStatusMessage
							};
						});									
						setRemoveModelOpen(false);
						if(response.data.newStatus && response.data.newStatus.length > 0)
						{
							handleStatusUpdateEvent(response.data.newStatus);
						}
					}
				}
			},
			(exception: any) => {}
		);	
	};

	const handleStartEditing = (selected: IEnrollmentStatus) => {
		if (previousData != null && previousData != undefined && previousData.length > 0) 
		{
			let found = previousData.filter((prev) => {return prev.studentStatusChangeId === selected.studentStatusChangeId;});
			if (found == null || found.length < 1)
			{
				previousData.push(selected);
			}
		}
		else{
			previousData.push(selected);
		}
			
		const rows = historyData.map(row => {			
			if (row.studentStatusChangeId === selected.studentStatusChangeId) {	
				reasonData.map(reasonRow => {
					if (reasonRow.value === selected.changeReasonId) {
						setSelectedReason(reasonRow);			
					}
				});

				return { ...row, isEditMode: true };
			}
			return row;
		});
		
		setHistoryData(rows);
	};
	
	const handleFieldOnChange = (selected: IEnrollmentStatus, fieldName: string, value: any) => {
		const rows =  TableFunctions.stableSort(
			historyData,
			TableFunctions.getSorting(order, orderBy) as any
		).map(row => {
			if (row.studentStatusChangeId === selected.studentStatusChangeId) {
				if(fieldName === 'dateOfChange')
				{
					value = new Date(value).toLocaleDateString();
				}
				return { ...row, [fieldName]: value };		
			}
			return row;		
		});

		setHistoryData(rows);
	};
	const handleReasonOnChange = (selected: IEnrollmentStatus, value: any) => {
		const rows =  TableFunctions.stableSort(
			historyData,
			TableFunctions.getSorting(order, orderBy) as any
		).map(row => {
			if (value!=null && row.studentStatusChangeId === selected.studentStatusChangeId) {
				return { ...row, changeReasonId:  value.value , dropReasonId:  value.value};
			}
			return row;
		});
		setSelectedReason(value);		
		setHistoryData(rows);
	};


	const handleCancelEditing = (selected: IEnrollmentStatus) => {
		let rows: Array<IEnrollmentStatus>;

		if (previousData != null && previousData != undefined && previousData.length > 0) 
		{
			let found = previousData.find((prev) =>  prev.studentStatusChangeId === selected.studentStatusChangeId);
			if (found != null && found != undefined)
			{	
				const foundRow = found;
				rows = historyData.map(row => {
					if (row.studentStatusChangeId === selected.studentStatusChangeId) {
						return { ...foundRow, isEditMode: false };
					}
					return row;
				});
				
				const newPrevious = previousData.filter((item) => item.studentStatusChangeId !== selected.studentStatusChangeId);
				setPreviousData(newPrevious);
			}
			else {
				rows = historyData.map(row => {
					if (row.studentStatusChangeId === selected.studentStatusChangeId) {
						return { ...row, isEditMode: false };
					}
					return row;
				});	
			}
		}
		else{
			rows = historyData.map(row => {
				if (row.studentStatusChangeId === selected.studentStatusChangeId) {
					return { ...row, isEditMode: false };
				}
				return row;
			});	
		}
	
		setHistoryData(rows);	
	};

	const handleSubmitChanges = (selected: IEnrollmentStatus) => {
		if (validateRow(selected)) {
		
			StatusHistoryApi.changeEffectiveDate(selected).then(
				(response: any) => {
					if (response.data != undefined && response.data.resultStatus != undefined)
					{
						switch (response.data.resultStatus) {
						case ApiResultStatus.Success:
							props.setSnackBarProps((props: any) => {
								return {
									variant: 'success',
									showSnackBar: true,
									messageInfo: 'Successfully updated status history. '
								};
							});							
							refreshGridData();
							break;							
						case ApiResultStatus.Warning:
							props.setSnackBarProps((props: any) => {
								return {
									variant: 'warning',
									showSnackBar: true,
									messageInfo: response.data.resultStatusMessage
								};
							});
							refreshGridData();
							break;
						case ApiResultStatus.Error:
							props.setSnackBarProps((props: any) => {
								return {
									variant: 'error',
									showSnackBar: true,
									messageInfo: response.data.resultStatusMessage
								};
							});
							break;
						default:
						}						
					}
					else {
						props.setSnackBarProps((props: any) => {
							return {
								variant: 'error',
								showSnackBar: true,
								messageInfo: 'Error updating status history. '
							};
						});
					}
				},
				(exception: any) => {
					props.setSnackBarProps((snackBarProps: any) => {
				  return {
							variant: 'error',
							showSnackBar: true,
							messageInfo: exception,
				  };
					});
			  }
			);
		}

	};
	
	const validateRow = (selected: IEnrollmentStatus) => {
		if (selected.dateOfChange.toString() =='Invalid Date')
		{
			props.setSnackBarProps((props: any) => {
				return {
					variant: 'error',
					showSnackBar: true,
					messageInfo: 'Invalid effective date. '
				};
			});
			return false;
		}
		return true;
	};
	
	const handleStatusUpdateEvent = (newStatus: any) => {
		props.updateEvent(newStatus);
	};

	const setOpenModelForNewStatus = (status : any) => {
		setNewStatusModelOpen(status);
	};
	return (
		
		<Paper className={classes.tableWrapper}>
			<Typography className={classes.cardTitle} >
			Status History 
				<AccessManager
					allowedPermissions={[security.permissions.statusHistory.addNewStatusHistory]}
					renderNoAccess={() => null}
				>
					
					<IconButton aria-label="add" onClick = {() => {setOpenModelForNewStatus(true);}} className = {classes.addIcon} >
						<AddIcon />
					</IconButton>

					<IconButton aria-label="Print" onClick = {() => {generateReport(selectedEnrollment.stuEnrollId);}} className = {classes.addIcon} >
						<PrintOutlinedIcon />
			 		</IconButton>
				</AccessManager>			    
			</Typography>
			<CardContent className={classes.cardContent}>
				<Table stickyHeader 
					className={classes.table}>
					<EnhancedTableHead classes={classes} />
					<TableBody >
						{TableFunctions.stableSort(
							historyData,
				TableFunctions.getSorting(order, orderBy) as any
						).map((row: IEnrollmentStatus, index: any) => {
							return (
								<TableRow key={row.studentStatusChangeId} >
									<TableCell  classes={{
										body: classes.gridCellTypeMedium}}>
										{renderEffectiveDate(row)}						
									</TableCell>
									<TableCell classes={{
										body: classes.gridCellTypeLarge}}>
										{renderStatus(row)}
									</TableCell>
									<TableCell classes={{
										body: classes.gridCellTypeRemainder}}>
										{renderReason(row)}
									</TableCell>
									<TableCell classes={{
								 body: classes.gridCellTypeSmall}}>
										{new Date(row.modifiedDate).toLocaleDateString()}
									</TableCell>
									<TableCell classes={{
										body: classes.gridCellTypeSmall}}>
										{row.modifiedUser}
									</TableCell>
									<TableCell classes={{
										body: classes.gridCellTypeXSmall}}>
										{renderIcons(row)}
									</TableCell>
								</TableRow>
							);
						})} 
					</TableBody>
				</Table>
			</CardContent>

			<LeaveOfAbsenceModal
				open={LeaveOfAbsenceModalOpen}
				setOpen={setLeaveOfAbsenceModalOpen}
				updateEvent={handleStatusUpdateEvent}
				newStatusCode={null}
				newStatus={null}
				statusChangeEnrollment={changeEnrollment}
				loaWidgetEnrollment={null}
			/>
			{/* <ProbationModal
			open={ProbationModalOpen}
			setOpen={setProbationModalOpen}
		/>			 */}
			<SuspensionModal
				open={SuspensionModalOpen}
				setOpen={setSuspensionModalOpen}
				updateEvent={handleStatusUpdateEvent}
				newStatusCode={null}
				newStatus={null}
				statusChangeEnrollment={changeEnrollment}
			/>		
			<AddNewStatusModel 
				open={NewStatusModelOpen} 
				setOpen={setOpenModelForNewStatus} 
				enrollmentId = {selectedEnrollment.stuEnrollId}
				setSnackBarProps = {props.setSnackBarProps}
				refreshHistoryGrid = {refreshGridData}
			/>

			<RemoveReasonModal 
				open={RemoveModelOpen} 
				setOpen={setRemoveModelOpen} 
				deletionReasonCallback = {deletionReasonCallback}
				confirmationMessage = {ConfirmationMessage}
				isLoading = {deletionLoading}
			/>
		</Paper>

	);
};
	
export default StatusHistoryTable;
