import * as React from 'react';
import { Button, Card, Checkbox, createStyles, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import CustomCardTitle from '../../../../../../interfaces/common/card/CustomCardTitle';
import { CardBody } from 'reactstrap';
import InputField from '../../../../../../components/_Layout/Inputs/InputField';
import { InputType } from '../../../../../../constants/uiConstants/inputConstants';
import { Search } from '@material-ui/icons';
import ProgramVersionCourse from '../../../../../../components/AutoComplete/ProgramVersionCourse';
import CourseGradeBookAutoComplete from '../../../../../../components/AutoComplete/CourseGradeBookAutoComplete';
import { getCourseGradeBookDetails } from '../../../../../../api/setup/academics/courses';
import IIllinoisTranscriptReportSettingsSubjectAreaComponents from '../../../../../../interfaces/setup/reports/IIllinoisTranscriptReportSettingsSubjectAreaComponents';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			mnargin: 'auto'
		},
		cardBody: {
			minHeight: theme.spacing(50),
			padding: theme.spacing(2)
		},
		subTitle: {
			fontWeight: 'bold'
		},
		button: {
			margin: theme.spacing(0.5, 0),
			width: 80
		},
		filters: {
			margin: theme.spacing(1, 0)
		}
	})
);

const useListStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			width: 300,
			height: 400,
			overflow: 'auto',
			padding: theme.spacing(0, 1)
		}
	}));

const TransferList = (props: { items: Array<{ value: string; text: string; checked?: boolean; disabled?: boolean }>, toggleComponent: (index: number) => void }) => {
	const classes = useListStyles({});
	const [search, setSearch] = React.useState<string>('');
	return (
		<Paper className={classes.paper}>
			<Grid container spacing={1} alignItems="flex-end">
				<Grid item>
					<Search />
				</Grid>
				<Grid item>
					<InputField value={search || ''} onChange={(e) => setSearch((e.target && e.target.value) || '')} size="small" fullWidth type={InputType.TEXT} label={'Search'} />
				</Grid>
			</Grid>

			<List dense component="div" role="list">
				{props.items.filter(M => (search ? M.text.toLowerCase().startsWith(search.toLowerCase()) : true)).map((m, index) => {
					const labelId = `transfer-list-item-${m.value}-label`;

					return (
						<ListItem key={`list-${index}`} role="listitem" button disabled={m.disabled ?? false} onClick={() => props.toggleComponent(index)}>
							<ListItemIcon>
								<Checkbox
									disabled={m.disabled ?? false}

									size='small'
									color="primary"
									checked={m.checked ?? false}
									tabIndex={-1}
									disableRipple
									inputProps={{ 'aria-labelledby': labelId }}
								/>
							</ListItemIcon>
							<ListItemText id={labelId} primary={m.text} />
						</ListItem>
					);
				})}
				<ListItem />
			</List>
		</Paper>
	);
};


const AddComponents: React.FC<{
	components: Array<{ value: string; text: string; checked?: boolean; disabled?: boolean }>,
	setMappedComponents: (components: Array<{ value: string; text: string; checked?: boolean; disabled?: boolean }>) => void,
	unmappedComponents: Array<{ value: string; text: string; checked?: boolean; disabled?: boolean }>,
	setUnmappedComponents: (components: Array<{ value: string; text: string; checked?: boolean; disabled?: boolean }>) => void,
	selectedSubjectAreaId?: string,
	selectedProgramVersionId?: string,
	mappedComponents?: Array<IIllinoisTranscriptReportSettingsSubjectAreaComponents>,
	setComponents?: (components: Array<IIllinoisTranscriptReportSettingsSubjectAreaComponents>) => void,
	setIsDirty: (isDirty: boolean) => void
}> = ({
	components,
	setMappedComponents: setComponents,
	unmappedComponents,
	setUnmappedComponents,
	selectedProgramVersionId,
	mappedComponents,
	setIsDirty
}) => {
	const classes = useStyles({});
	const [course, setCourse] = React.useState<string>();
	const [gbId, setGbId] = React.useState<string>();
	const toggleComponent = (index: number) => {
		components[index].checked = !components[index].checked;
		setComponents([...components]);
	};

	const toggleUnmappedComponent = (index: number) => {
		unmappedComponents[index].checked = !unmappedComponents[index].checked;
		setUnmappedComponents([...unmappedComponents]);
	};

	const loadComponents = async () => {
		setComponents([]);
		setUnmappedComponents([]);
		if (gbId) {
			try {
				var result = await getCourseGradeBookDetails(gbId);
				if (result && result.gradeBookDetails) {
					const unmapped: Array<{ value: string; text: string; checked?: boolean; disabled?: boolean }> = [];
					result.gradeBookDetails.forEach(m => {
						const mappedIndex = (mappedComponents ?? []).findIndex(r => r.componentId === m.gradeBookComponentId);
						unmapped.push({ value: m.gradeBookComponentId ?? '', text: m.gardeBookComponentType ?? '', checked: false, disabled: mappedIndex > -1 });

					});
					setUnmappedComponents(unmapped);
				}
			}
			catch (e) {
				setComponents([]);
				setUnmappedComponents([]);
			}
		}
	};
	React.useEffect(() => {
		setGbId(undefined);
	}, [course]);
	React.useEffect(() => {
		loadComponents();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gbId]);
	return (
		<Card>
			<CustomCardTitle title={'Add Components to Subject Area'} />
			<CardBody className={classes.cardBody}>
				<Typography className={classes.subTitle} variant="subtitle1">Select course and gradebook within it from below and click Map button to map them to the subject areas. Components already mapped to a Subject Area are grayed out.</Typography>
				<Grid container className={classes.filters}>
					<Grid xs={4} item>
						<ProgramVersionCourse
							filterHandle={(v: any) => {
								setCourse(v ? v.value : undefined);
							}}
							helperText=""
							valueFilter={
								course
									? {
										key: 'value',
										values: [course],
									}
									: undefined
							}
							progamVersionIds={selectedProgramVersionId} />
					</Grid>
					<Grid xs={3} item>
						<CourseGradeBookAutoComplete
							filterHandle={(v: any) => {
								setGbId(v ? v.value : undefined);
							}}
							helperText=""
							valueFilter={
								course
									? {
										key: 'value',
										values: [gbId],
									}
									: undefined
							}
							courseId={course} />
					</Grid>
				</Grid>
				<Grid
					container
					spacing={2}
					alignItems="center"
					className={classes.root}
				>
					<Grid item>
						<TransferList items={unmappedComponents} toggleComponent={toggleUnmappedComponent} />
					</Grid>
					<Grid item>
						<Grid container direction="column" alignItems="center" spacing={2}>
							<Button color='primary' className={classes.button} variant="contained" onClick={() => {
								setComponents([...components, ...unmappedComponents.filter(m => m.checked).map((m) => ({ value: m.value, text: m.text, checked: false }))]);
								setUnmappedComponents([...unmappedComponents.map(m => ({ ...m, checked: false, disabled: m.disabled || m.checked }))]);
								setIsDirty(true);
							}
							}>MAP</Button>
							<Button color='primary' className={classes.button} variant="contained" onClick={() => {
								setUnmappedComponents([...unmappedComponents.map(m => ({ ...m, disabled: components.findIndex(t => t.checked && t.value === m.value) > -1 ? false : m.disabled }))]);
								setComponents([...components.filter(m => !m.checked)]);
							}}>REMOVE</Button>
						</Grid>
					</Grid>
					<Grid item>
						<TransferList items={components} toggleComponent={toggleComponent} />
					</Grid>
				</Grid>
			</CardBody>
		</Card>);
};

export default AddComponents;