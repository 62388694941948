export class Academics {
  constructor() {
    this.stuEnrollId = "";
    this.metReqs = "";
    this.overallGPA = undefined;
    this.writtenExamAvg = "";
    this.practicalExamAvg = "";
    this.servicesRemaining = undefined;
    this.hoursRemaining = undefined;
  }

  stuEnrollId: string | undefined;
  metReqs: string | undefined;
  overallGPA: number | undefined;
  writtenExamAvg: string | undefined;
  practicalExamAvg: string | undefined;
  servicesRemaining: string | undefined;
  hoursRemaining: string | undefined;
  creditAttempted: number | undefined;
  creditEarned: number | undefined;
  faCreditAttempted: number | undefined;
  faCreditEarned: number | undefined;
  isCreditHour: boolean | undefined;
}
