export interface IProgramVersionFinancialDetails {
	refundPolicyIds: string[];
	totalCost: Number;
	costPerCredit: Number;
	chargeMethod: string;
	paymentPeriodId?: string;
	isFAMEApprovedTitleIV: boolean;
	isTitleIV: boolean;
	isFAMETitleIVService: boolean;
	r2t4ChargesCalculationByPaymentPeriod: boolean;
}

export class ProgramVersionFinancialDetails
	implements IProgramVersionFinancialDetails {
	refundPolicyIds: string[] = [];
	totalCost: Number = 0;
	costPerCredit: Number = 0;
	chargeMethod: string = "";
	paymentPeriodId: string = "";
	isFAMEApprovedTitleIV: boolean = false;
	isTitleIV: boolean = false;
	isFAMETitleIVService: boolean = false;
	r2t4ChargesCalculationByPaymentPeriod: boolean = false;
}
