import * as React from 'react';
import { useEffect, useRef } from "react";
import { apiAutoCompleteConfig } from "../../constants/apiAutoComplete.config";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../constants/actions.constants";
import { FilterParams } from '../../interfaces/common/FilterParams';
import { Permission } from '../../interfaces/security/Permission';
import { Chip } from '@material-ui/core';

const ThemeTextField = withStyles((theme: any) =>
    createStyles({
        root: {
            "& label.Mui-focused": {
                color: theme.palette.primary.white
            },
            "& input": {
                color: theme.palette.black,
                opacity: 1,
                fontWeight: theme.typography.fontWeightMedium,
                fontSize: 14,
                padding: 10
            },
            "& input::placeholder": {
                fontWeight: theme.typography.fontWeightMedium,
                fontSize: 13
            },
            "& .MuiInputBase-root": {
                backgroundColor: theme.palette.site.secondary,
                borderRadius: 5
            }
        }
    })
)(TextField);

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {},
        divWidth: {
            width: "95%"
        },
        searchIcon: {
            fontSize: 30,
            paddingLeft: 10,
            color: theme.palette.icon.primary,
            cursor: "pointer"
        }
    })
);

const PermissionAutoComplete = (props: FilterParams) => {
    const classes = useStyles({});
    const dispatch = useDispatch();
    const [roleSelected, setRoleSelected] = React.useState(false);

    const user = useSelector((state: any) => state.session.user);
    const { filterHandle, id, ...rest } = props;

    const inputRef: any = useRef(null);

    const magnifyOnClick = (e: any) => {
        if (inputRef.current !== undefined) {
            inputRef.current.children[1].focus();
        }
    };

    const roleOnChange = (e: any) => {

        if (filterHandle)
            filterHandle(id ? { data: { ...e }, id: id } : e)

        setRoleSelected(e);
    };

    return (
        <div className={clsx(classes.divWidth)}>
            <ApiAutoComplete
                config={apiAutoCompleteConfig.permissions}
                classes={{
                    option: "text-black"
                }}
                label='Permissions'
                onChange={(e: any) => {
                    roleOnChange(e);
                }}
                optionName={(option: Permission) => { return option.permissionName }}
                multiple={props.multiple || false}
                valueFilter={
                    props.valueFilter
                        ? props.valueFilter
                        : undefined
                }
                optionsFilter={
                    props.optionsFilter
                        ? props.optionsFilter
                        : undefined
                }
                getOptionSelected={(option: any, value: any) => {
                    return (option.permissionId === value.permissionId);

                }}
                renderTags={(value: any, getTagProps) => {
                    return value.map((option: any, index: number) => (
                        <Chip
                            label={option.permissionName}
                            {...getTagProps({ index })}
                        />
                    ));
                }}
                groupBy={(option: Permission) => option.permissionGroup.description}
                disableCloseOnSelect
            ></ApiAutoComplete>
        </div>
    );
};
export default PermissionAutoComplete;
