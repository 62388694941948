import React, { Fragment, useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Drawer, Fab, Badge } from "@material-ui/core";
import EnrollmentSelector from "../EnrollmentSelector/EnrollmentSelector";
import ContactCard from "./ContactCard";
import GeneralInformationCard from './GeneralInformationCard';
import EnrollmentsCard from "./EnrollmentCard";
import PaymentPeriodSapCard from "./PaymentPeriodSapCard";
import AcademicsCard from "./AcademicsCard";
import AttendanceCard from "./AttendanceCard";
import FinancialAidCard from "./FinancialAidCard";
import PaymentPlanCard from "./PaymentPlanCard";
import CostsAndBalancesCard from "./CostsAndBalancesCard";
import StudentAvatar from "../StudentAvatar/StudentAvatar";
import { useSelector } from "react-redux";


const useStyles = makeStyles((theme: any) =>
	createStyles({
		drawer: {
			width: 280
		},
		root: {
			backgroundColor: theme.palette.background.paper
		},
		fab: {
			position: "fixed",
			bottom: 34,
			right: 32,
			zIndex: theme.zIndex.drawer - 100
		},
		avatar_icon: {
			height: 54,
			width: 54
		},
		fab_icon: {
			height: 60,
			width: 60
		},
		sideBarContent: {},
		enrollmentSelect: {
			backgroundColor: theme.palette.site.secondary,
			paddingTop: theme.spacing(1)
		}
	})
);

const StudentQuickInfo = (props: any) => {
	const { className, ...rest } = props;
	const classes = useStyles({});
	const [open, setOpen] = useState(false);
	const [data, setData] = useState(null);
	const profile = useSelector((state: any) =>
		state.userstate[state.session.user.userId]
			? state.userstate[state.session.user.userId].selectedStudent
			: null
	);
	useEffect(() => {
		let mounted = true;

		const fetchData = () => {};

		fetchData();

		return () => {
			mounted = false;
		};
	}, []);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const sideBarContent = (
		<div className={classes.sideBarContent}>
			<EnrollmentSelector
				className={classes.enrollmentSelect}
				showStudentStatus={false}
				showEnrollmentSelect={true}
				showProfilePhotoUpload={true}></EnrollmentSelector>
			<GeneralInformationCard></GeneralInformationCard>
			<ContactCard></ContactCard>
			<EnrollmentsCard></EnrollmentsCard>
			<PaymentPeriodSapCard></PaymentPeriodSapCard>
			<AcademicsCard></AcademicsCard>
			<AttendanceCard></AttendanceCard>
			<FinancialAidCard></FinancialAidCard>
			<CostsAndBalancesCard></CostsAndBalancesCard>
			<PaymentPlanCard></PaymentPlanCard>
		</div>
	);

	return (
		<Fragment>
			<Drawer
				anchor='right'
				classes={{
					paper: classes.drawer
				}}
				elevation={1}
				onClose={handleClose}
				open={open}
				variant='temporary'
				transitionDuration={200}>
				<div {...rest} className={clsx(classes.root, className)}>
					{sideBarContent}
				</div>
			</Drawer>
			<span
				className={clsx(classes.fab, {
					[classes.fab]: open
				})}>
				<Badge color='error'>
					<Fab color='primary' onClick={handleOpen} className={classes.fab_icon}>
						{data ? (
							<p>Loading, please wait...</p>
						) : (
							<StudentAvatar
								className={classes.avatar_icon}
								firstName={profile != undefined ? profile.studentName!.split(" ")[0] : ""}
								lastName={
									profile != undefined
										? profile.studentName!.split(" ")[
												profile.studentName!.split(" ").length - 1
										  ]
										: ""
								}
								src={profile && profile.profilePhoto ? `data:${profile.profilePhoto.mediaType};base64,${profile.profilePhoto.image}` : ''}
							/>
						)}
					</Fab>
				</Badge>
			</span>
		</Fragment>
	);
};

StudentQuickInfo.propTypes = {
	className: PropTypes.string
};

export default StudentQuickInfo;
