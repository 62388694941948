import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
    Grid,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
    Switch,
} from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import ProgramVersionAutoComplete from "../../../components/AutoComplete/ProgramVersionAutoComplete";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ReportOutput } from "../../../enums/ReportOutput";
import { ReportSort } from "../../../enums/ReportSort";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";
import { IAbsentToday } from "../../../interfaces/reports/attendance/IAbsentToday";
import * as stateBoardReportApi from "../../../api/reports/stateBoard/stateBoardReportApi";
import { useSelector } from "react-redux";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import ProgramScheduleSelect from "../../../components/AutoComplete/ProgramScheduleAutoComplete";
import ScheduleProgramAutoComplete from "../../../components/AutoComplete/ScheduleProgramAutoComplete";
import MultiCampusAutoComplete from "../../../components/AutoComplete/MultiCampusAutoComplete";
import StateBoardStatesAutoComplete from "../../../components/AutoComplete/StateBoardStatesAutoComplete";
import StudentGroupsAutoComplete from "../../../components/AutoComplete/StudentGroupsAutoComplete";
import StateBoardReportsAutoComplete from "../../../components/AutoComplete/StateBoardReportsAutoComplete";
import MonthAutoComplete from "../../../components/AutoComplete/MonthAutoComplete";
import YearAutoComplete from "../../../components/AutoComplete/YearAutoComplete";
import { IStateBoardReportParam } from "../../../interfaces/reports/stateboardReportParam";
import StateBoardProgramVersionAutoComplete from "../../../components/AutoComplete/StateBoardProgramVersionAutoComplete";


const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(2),
            minHeight: "100%",
            height: "100%",
            maxWidth: theme.spacing(131.25),
            margin: theme.spacing(2),
        },
        "& .makeStyles-content": {
            backgroundColor: theme.palette.background.paper,
        },
        paperRoot: {
            padding: theme.spacing(3, 2),
            width: "100%",
            minHeight: "100%",
            height: "100%",
            overflowX: "hidden",
            overflowY: "auto",
            flexDirection: "column",
            backgroundColor: theme.palette.background.paper,
        },
        width100: {
            width: "100%",
        },
        cardTitle: {
            fontSize: theme.spacing(2.15),
            color: theme.palette.primary.headerText,
            textDecoration: "bold",
            backgroundColor: theme.palette.secondary.dark,
            padding: theme.spacing(1, 1),
            marginBottom: theme.spacing(1),
        },
        headerText: {
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(0),
        },
        cardContent: {
            padding: theme.spacing(1, 3),
        },
        switch: {
            display: "flex",
            alignItems: "flex-end",
            padding: theme.spacing(0, 0.5),
            height: theme.spacing(6.6),
        },
        fieldSet: {
            marginTop: theme.spacing(2),
        },
    })
);

const StateBoardReport = (props: any) => {
    const classes = useStyles({});
    const { report } = props;
    let reportOverview = { report: report } as IReportOverview;
    //#endregion Global Const
    const michiganStateId = '9F7D4E48-6E07-48CF-A0B3-FDB704A90BAA';
    //#region State Objects
    const [preview, setPreview] = React.useState<any>();
    const [previewLoader, setPreviewLoader] = React.useState<boolean>();
    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );

    const defaultFilters: IStateBoardReportParam = {
        campusId: userSelectedCampus,
        stateId: null,
        reportId: null,
        studentGroupId: null,
        month: null,
        year: null,
        programVersionIds: null,
        exportType: 1 as ReportOutput,
    };
    const [model, setModel] = React.useState<IStateBoardReportParam>(defaultFilters);
    //#endregion State Objects End

    const exportTypes: Array<DropDownListItem> = [
        { text: "PDF", id: "1" },
        { text: "Excel", id: "2" },
        { text: "Word", id: "3" },
    ];

    const exportTypeAutoComplete = {
        options: exportTypes,
        getOptionLabel: (option: DropDownListItem) => option.text,
    };

    const getSelectedItem = (items: Array<DropDownListItem>, value: any) => {
        const item = items.find(opt => opt.id == value);

        console.clear();
        console.log('Selected item:', value)
        return item || ({} as DropDownListItem);
    };
    //#endregion Input Handlers

    //#region Form Handler

    const handleFieldValueChange = (fieldId: string, value: any, mapFunction?: Function) => {
        let updatedModel = { ...model };
        let newValue = mapFunction ? mapFunction(value) : value;
        (updatedModel as any)[fieldId] = newValue;

        if (fieldId == 'stateId') {
            updatedModel.reportId = null;
            updatedModel.programVersionIds = null;
        }

        if (fieldId == 'campusId') {
            updatedModel.studentGroupId = null;
            updatedModel.programVersionIds = null;
        }

        if (fieldId == 'reportId') {
            updatedModel.programVersionIds = null;
        }

        setModel({ ...updatedModel });
    }

    const data = useForm<any>({ mode: "onBlur" });
    const { handleSubmit } = data;
    const onSubmit = async (isPreview: any): Promise<void> => {
        let params = (await getReportParameters()) as IStateBoardReportParam;
        const validation = await data.triggerValidation();

        if (validation) {


            if (params.studentGroupId == 'All') {
                params.studentGroupId = null;
            }

            if (!params.programVersionIds || params.programVersionIds.length == 0 || params.programVersionIds?.indexOf('All') >= 0) {
                params.programVersionIds = null;
            }

            if (isPreview) {
                setPreviewLoader(true);
                stateBoardReportApi.generateStateBoardReport(params, isPreview)
                    .then((res: any) => {
                        if (res) {
                            res.arrayBuffer().then((buffer: any) => {
                                setPreview(buffer);
                                setPreviewLoader(false);
                            });
                        } else setPreviewLoader(false);
                    });
            } else await stateBoardReportApi.generateStateBoardReport(params);
        }
    };

    const filterHandler = (data: any) => {
        if (data) {
            setModel({ ...data });
        } else {
            setModel(defaultFilters);
        }
    };

    const getReportParameters = async () => {
        const success = await data.triggerValidation();
        if (model && success) {
            let params: IStateBoardReportParam = { ...model };
            return params;
        }

        return {} as IStateBoardReportParam;
    };
    //#endRegion Form Handler

    let parameters = (
        <div className={[classes.root, classes.width100].join(" ")}>
            <FormContext {...data}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                    >

                        <Grid item xs={12} sm={6} md={6}>
                            <MultiCampusAutoComplete label="Campus *" multiple={false}
                                id="campusId"
                                name="campusId"
                                valueFilter={
                                    model.campusId
                                        ? {
                                            key: "value",
                                            values: [model.campusId],
                                        }
                                        : null
                                }
                                filterHandle={(v: any) => {
                                    handleFieldValueChange(
                                        "campusId",
                                        v ? v.value : null
                                    );
                                }}
                                error={!!data.errors.campusId}
                                inputRef={data.register({ required: "Campus is required." })}
                                helperText={
                                    data.errors.campusId
                                        ? (data.errors.campusId as FieldError).message
                                        : undefined
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <StateBoardStatesAutoComplete
                                disabled={false}
                                name='stateId'
                                label="State *"
                                error={!!data.errors.stateId}
                                inputRef={data.register({ required: "State is required." })}
                                helperText={
                                    data.errors.stateId
                                        ? (data.errors.stateId as FieldError).message
                                        : undefined
                                }
                                valueFilter={
                                    model?.stateId
                                        ? {
                                            key: 'value',
                                            values: [model?.stateId]
                                        }
                                        : undefined
                                }
                                filterHandle={(v: any) => {
                                    handleFieldValueChange('stateId', v ? v.value : null);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <StateBoardReportsAutoComplete
                                disabled={false}
                                label="Report *"
                                name='reportId'
                                params={{
                                    stateId: model.stateId
                                }}
                                error={!!data.errors.reportId}
                                inputRef={data.register({ required: "Report is required." })}
                                helperText={
                                    data.errors.reportId
                                        ? (data.errors.reportId as FieldError).message
                                        : undefined
                                }
                                valueFilter={
                                    model?.reportId
                                        ? {
                                            key: 'value',
                                            values: [model?.reportId]
                                        }
                                        : undefined
                                }
                                filterHandle={(v: any) => {
                                    handleFieldValueChange('reportId', v ? v.value : null);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <StudentGroupsAutoComplete
                                id="studentGroupId"
                                label="Student Group"
                                campusId={model?.campusId ?? undefined}
                                multiple={false}
                                disabled={false}
                                name='studentGroupId'
                                error={!!data.errors.studentGroupId}
                                helperText={
                                    data.errors.studentGroupId
                                        ? (data.errors.studentGroupId as FieldError).message
                                        : undefined
                                }
                                valueFilter={
                                    model?.studentGroupId
                                        ? {
                                            key: 'value',
                                            values: [model?.studentGroupId]
                                        }
                                        : undefined
                                }
                                filterHandle={(v: any) => {
                                    handleFieldValueChange('studentGroupId', v ? v.value : null);
                                }}
                                defaultOptionText="Include All"
                                includeAllValue={true}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <MonthAutoComplete
                                disabled={false}
                                label="Month *"
                                name='month'
                                error={!!data.errors.month}
                                inputRef={data.register({ required: "Month is required." })}
                                helperText={
                                    data.errors.month
                                        ? (data.errors.month as FieldError).message
                                        : undefined
                                }
                                valueFilter={
                                    model?.month
                                        ? {
                                            key: 'value',
                                            values: [model?.month]
                                        }
                                        : undefined
                                }
                                filterHandle={(v: any) => {
                                    handleFieldValueChange('month', v ? v.value : null);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <YearAutoComplete
                                disabled={false}
                                label="Year *"
                                name='year'
                                error={!!data.errors.year}
                                inputRef={data.register({ required: "Year is required." })}
                                helperText={
                                    data.errors.year
                                        ? (data.errors.year as FieldError).message
                                        : undefined
                                }
                                valueFilter={
                                    model?.year
                                        ? {
                                            key: 'value',
                                            values: [model?.year]
                                        }
                                        : undefined
                                }
                                filterHandle={(v: any) => {
                                    handleFieldValueChange('year', v ? v.value : null);
                                }}
                                params={{
                                    offset: 2
                                }}
                            />
                        </Grid>


                        <Grid item md={6} sm={6} xs={12}>
                            {!!model.stateId && model.stateId.toLowerCase() == michiganStateId.toLowerCase() ? <StateBoardProgramVersionAutoComplete
                                campusId={model.campusId ?? undefined}
                                stateId={model.stateId ?? undefined}
                                reportId={model.reportId ?? undefined}
                                id="programVersionIds"
                                name="programVersionIds"
                                label="Program Version"
                                multiple={true}
                                params={{
                                    inActiveProgramVersion: false,
                                }}
                                valueFilter={
                                    model?.programVersionIds
                                        ? {
                                            key: "value",
                                            values: model.programVersionIds,
                                        }
                                        : undefined
                                }
                                includeAllValue={true}
                                showIncludeAllValue={false}

                                filterHandle={(v: any) => handleFieldValueChange('programVersionIds', v && Array.isArray(v) && v.length > 0 ? v.map(m => m.value) : undefined, (vals:any[]) =>{
                                    if(!!vals && vals.length > 0){
                                        let lastValue = vals[vals.length - 1];

                                        if(lastValue === 'All'){
                                            return ['All'];
                                        }else{
                                            return vals.filter(a => a !== 'All');
                                        }
                                    }else{
                                        return vals;
                                    }
                                })}

                                defaultOptionText="Include All"
                            /> : <ProgramVersionAutoComplete
                                campusId={model.campusId ?? undefined}
                                id="programVersionIds"
                                name="programVersionIds"
                                label="Program Version"
                                multiple={true}
                                params={{
                                    inActiveProgramVersion: false,
                                }}
                                valueFilter={
                                    model?.programVersionIds
                                        ? {
                                            key: "value",
                                            values: model.programVersionIds,
                                        }
                                        : undefined
                                }
                                filterHandle={(v: any) => handleFieldValueChange('programVersionIds', v && Array.isArray(v) && v.length > 0 ? v.map(m => m.value) : undefined, (vals:any[]) =>{
                                    if(!!vals && vals.length > 0){
                                        let lastValue = vals[vals.length - 1];

                                        if(lastValue === 'All'){
                                            return ['All'];
                                        }else{
                                            return vals.filter(a => a !== 'All');
                                        }
                                    }else{
                                        return vals;
                                    }
                                })}

                                defaultOptionText="Include All"
                                includeAllValue={true}
                                showIncludeAllValue={false}
                            />}
                        </Grid>

                        <Grid item md={3} sm={3} xs={12}>
                            <Autocomplete
                                {...exportTypeAutoComplete}
                                autoComplete
                                includeInputInList
                                onChange={(e: any, value: any) => {
                                    handleFieldValueChange(
                                        "exportType",
                                        value ? value.id : null,
                                    );
                                }}
                                value={getSelectedItem(exportTypes, model.exportType)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Export Type *"
                                        name="exportType"
                                        error={!!data.errors.exportType}
                                        inputRef={data.register({ required: true })}
                                        helperText={
                                            data.errors.exportType
                                                ? "Export Type is required."
                                                : undefined
                                        }
                                    />
                                )}
                            />
                        </Grid>

                        {!!model.stateId && model.stateId.toLowerCase() == michiganStateId.toLowerCase() && <Grid item md={12} xs={12}>
                            <FormControlLabel
                                label="Include Students personal information"
                                control={
                                    <Switch
                                        checked={model.showPersonalInfo ?? false}
                                        value="showPersonalInfo"
                                        onChange={(e: any) => {
                                            handleFieldValueChange(
                                                "showPersonalInfo",
                                                e ? e.target.checked : null
                                            );
                                        }}
                                        color="primary"
                                    />
                                }
                            />
                        </Grid>}
                    </Grid>
                </form>
            </FormContext>
        </div>
    );
    reportOverview.parameters = parameters;

    return (
        <ReportOverview
            reportOverview={reportOverview}
            filterHandler={filterHandler}
            getReportParameters={getReportParameters}
            exportHandler={onSubmit}
            preview={preview}
            previewLoader={previewLoader}
        />
    );
};

export default StateBoardReport;
