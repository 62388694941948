import React, { useEffect } from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { Card } from "@material-ui/core";
import { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker
} from "@material-ui/pickers";
import Search from "./Search";
import TextField from "@material-ui/core/TextField";
import { useSelector } from "react-redux";
import * as StudentGradesApi from "../../../../src/api/student/academics/StudentGradesApi";

import { StudentGrade } from "../../../interfaces/student/academics/StudentGrade";
import AttemptHistoryBox from "../grades/AttemptHistoryBox";
import { useFormContext, FieldError } from "react-hook-form";

function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}

type Order = "asc" | "desc";

function getSorting<K extends keyof any>(
	order: Order,
	orderBy: K
): (
		a: { [key in K]: number | string },
		b: { [key in K]: number | string }
	) => number {
	return order === "desc"
		? (a, b) => desc(a, b, orderBy)
		: (a, b) => -desc(a, b, orderBy);
}

function desc<T>(a: T, b: T, orderBy: keyof T) {
	const aa = a[orderBy];
	const bb = b[orderBy];
	if (aa === bb) {
		return 0;
	} else if (aa === null) {
		return 1;
	} else if (bb === null) {
		return -1;
	} else {
		if (orderBy == "score") {
			let aas = Number(aa);
			let bbs = Number(bb);
			if (bbs < aas) {
				return -1;
			}
			if (bbs > aas) {
				return 1;
			}
			return 0;
		}
		else if (orderBy == "dateCompleted") {
			let aad = new Date(String(aa ?? new Date(0)));
			let bbd = new Date(String(bb ?? new Date(0)));
			if (bbd < aad) {
				return -1;
			}
			if (bbd > aad) {
				return 1;
			}
			return 0;
		}
		else
			return aa < bb ? 1 : -1;
	}
}

interface HeadCell {
	disablePadding: boolean;
	id: keyof StudentGrade;
	label: string;
	numeric: boolean;
	widthType: "L" | "M" | "S" | "XS";
}

const headCells: HeadCell[] = [
	{
		id: "componentType",
		numeric: false,
		disablePadding: false,
		label: "Type",
		widthType: "S"
	},
	{
		id: "courseDescription",
		numeric: false,
		disablePadding: false,
		label: "Course",
		widthType: "M"
	},
	{
		id: "componentDescription",
		numeric: false,
		disablePadding: false,
		label: "Name",
		widthType: "L"
	},
	{
		id: "score",
		numeric: true,
		disablePadding: false,
		label: "Score",
		widthType: "XS"
	},
	{
		id: "dateCompleted",
		numeric: true,
		disablePadding: false,
		label: "Date",
		widthType: "M"
	},
	{
		id: "attempts",
		numeric: true,
		disablePadding: false,
		label: "Attempts",
		widthType: "XS"
	},
	{
		id: "gradeBookWeightDetailId",
		numeric: true,
		disablePadding: false,
		label: "",
		widthType: "XS"
	}
];

interface EnhancedTableProps {
	classes: ReturnType<typeof useStyles>;
	numSelected: number;
	onRequestSort: (
		event: React.MouseEvent<unknown>,
		property: keyof StudentGrade
	) => void;
	onSelectAllClick: (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => void;
	order: Order;
	orderBy: string;
	//rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const {
		classes,
		onSelectAllClick,
		order,
		orderBy,
		numSelected,
		// rowCount,
		onRequestSort
	} = props;
	const createSortHandler = (property: keyof StudentGrade) => (
		event: React.MouseEvent<unknown>
	) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className={classes.gridHeader}>
			<TableRow>
				{headCells.map(headCell => (
					<TableCell
						key={headCell.id}
						align="left"
						padding={headCell.disablePadding ? "none" : "default"}
						sortDirection={orderBy === headCell.id ? order : false}
						className={
							headCell.widthType == "XS"
								? classes.gridCellTypeXsmall
								: headCell.widthType == "S"
									? classes.gridCellTypeSmall
									: headCell.widthType == "M"
										? classes.gridCellTypeMedium
										: headCell.widthType == "L"
											? classes.gridCellTypeLarge
											: classes.gridCellTypeXLarge
						}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={order}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

const useToolbarStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(1),
			height: "50px"
		},
		highlight:
			theme.palette.type === "light"
				? {
					color: theme.palette.secondary.main,
					backgroundColor: theme.palette.secondary.light
				}
				: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark
				},
		title: {
			flex: "1 1 100%"
		},
		header: {
			width: "100%",
			height: "90%",
			display: "inline-block",
			border: "5px",
			float: "left",
			color: "#a8a8a8"
		},
		date: {
			width: "100%"
		},
		dateDiv: {
			paddingRight: theme.spacing(2),
			width: "20%"
		},
		searchDiv: {
			paddingRight: theme.spacing(2),
			float: "right",
			width: "60%"
		}
	})
);

interface EnhancedTableToolbarProps {
	numSelected: number;
	onChangeDate?: Function;
	onChangeSearchFilter?: Function;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
	const classes = useToolbarStyles({});
	const { numSelected } = props;
	let d = new Date();
	d.setDate(d.getDate());
	const [defaultExameDate, setDefaultExameDate] = useState<Date | null>(d);
	const [searchToolFilter, setSearchToolFilter] = useState<string | null>("");
	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0
			})}
		>
			<div className={classes.dateDiv}>
				<Typography className={classes.title} variant="h5" id="tableTitle">
					Default Date:
				</Typography>
			</div>
			<div className={classes.dateDiv}>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardDatePicker
						disableToolbar
						autoOk={true}
						variant="inline"
						format="MM/dd/yyyy"
						id="date-picker-inline"
						value={defaultExameDate}
						disableFuture={true}
						maxDate={new Date()}
						maxDateMessage="Date should not be after the current date"
						onChange={value => {
							if (props.onChangeDate) {
								props.onChangeDate(value);
							}
							setDefaultExameDate(value);
						}}
						className={classes.date}
						KeyboardButtonProps={{
							"aria-label": "change date"
						}}
					/>
				</MuiPickersUtilsProvider>
			</div>
			<div className={classes.searchDiv}>
				<Search
					label={"Search Components"}
					value={searchToolFilter}
					onChange={(e: any) => {
						if (props.onChangeSearchFilter) {
							props.onChangeSearchFilter(e);
						}
						setSearchToolFilter(e.target.value);
					}}
				/>
			</div>
		</Toolbar>
	);
};

// enhance table styling
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			height: "calc(100% - 70px)"
		},
		cardWrapper: {
			height: "100%"
		},
		paper: {
			width: "100%",
			marginBottom: theme.spacing(2)
		},
		table: {
			minWidth: 750,
			height: "100%"
		},
		tableWrapper: {
			height: "calc(100% - 40px)"
		},
		visuallyHidden: {
			border: 0,
			clip: "rect(0 0 0 0)",
			height: 1,
			margin: -1,
			overflow: "hidden",
			padding: 0,
			position: "absolute",
			top: 20,
			width: 1
		},
		textField: {
			marginRight: theme.spacing(1),
			width: "100%",
			"& ::-webkit-inner-spin-button, ::-webkit-outer-spin-button": {
				WebkitAppearance: "none",
				margin: 0,
				MozAppearance: "textfield"
			}
		},
		tableRowWrapper: {
			display: "table",
			width: "100%",
			tableLayout: "fixed"
		},
		gridHeader: {
			backgroundColor: theme.palette.secondary.dark,
			height: "50px",
			display: "table",
			width: "100%",
			tableLayout: "fixed"
		},
		tableBodyWrapper: {
			height: "calc(100% - 75px)",
			overflowY: "auto",
			display: "block"
		},
		gridHeadTypeXsmall: {
			width: "10%",
			paddingLeft: theme.spacing(0),
			paddingRight: theme.spacing(0)
		},
		gridCellTypeXsmall: {
			width: "10%",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0)
		},
		gridHeadTypeSmall: {
			width: "15%",
			paddingLeft: theme.spacing(0),
			paddingRight: theme.spacing(0)
		},
		gridCellTypeSmall: {
			width: "15%",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0)
		},
		gridHeadTypeMedium: {
			width: "25%",
			paddingLeft: theme.spacing(0),
			paddingRight: theme.spacing(0)
		},
		gridCellTypeMedium: {
			width: "25%",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0)
		},
		gridHeadTypeLarge: {
			width: "35%",
			paddingLeft: theme.spacing(0),
			paddingRight: theme.spacing(0)
		},
		gridCellTypeLarge: {
			width: "35%",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0)
		},
		gridHeadTypeXLarge: {
			width: "45%",
			paddingLeft: theme.spacing(0),
			paddingRight: theme.spacing(0)
		},
		gridCellTypeXLarge: {
			width: "45%",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0)
		},
		datePicker: {
			"& .MuiIconButton-root": {
				padding: theme.spacing(0.8)
			}
		}
	})
);

type gradesTableProps = {
	setGradesData: any;
	gradesData: any;
	setSnackBar: any;
	setConfirmationModal: any;
	transferGradesForGrid: any;
};
export default function EnhancedTable(props: gradesTableProps) {
	const classes = useStyles({});
	const [order, setOrder] = React.useState<Order>("asc");
	const [orderBy, setOrderBy] = React.useState<keyof StudentGrade>(
		"courseDescription"
	);

	const data = useFormContext();
	const [selected, setSelected] = React.useState<string[]>([]);
	const [dense, setDense] = React.useState(false);
	let d = new Date();
	const [startDate, setStartDateState] = useState<Date | null>(d);
	const [searchFilter, setsearchFilter] = useState<string | null>("");
	const selectedEnrollment = useSelector(
		(state: any) => state.student.selectedEnrollment
	);

	const formDataHasValue = (
		fieldName: string,
		id: string | number
	): boolean => {
		let arrayIndex: string = fieldName + "_" + id;
		return (
			data.getValues()[arrayIndex] !== undefined &&
			data.getValues()[arrayIndex] !== "" &&
			data.getValues()[arrayIndex] !== null
		);
	};

	const doNotValidateRow = (id: string | number) => {
		return (
			!formDataHasValue("score", id) && !formDataHasValue("dateCompleted", id)
		);
	};
	const filterDataBySearch = (data: StudentGrade[]): StudentGrade[] => {
		if (searchFilter === "" || searchFilter === undefined) return data;

		let searchVal: string = searchFilter
			? (searchFilter as string).toLowerCase()
			: "";
		return data.filter(function (service: StudentGrade) {
			return (
				service &&
				((service.componentDescription &&
					service?.componentDescription?.toLowerCase().indexOf(searchVal) >
					-1) ||
					(service.courseDescription &&
						service?.courseDescription?.toLowerCase().indexOf(searchVal) >
						-1) ||
					(service.dateCompletedAsString &&
						service?.dateCompletedAsString?.indexOf(searchVal) > -1) ||
					(service.componentType &&
						service?.componentType?.toLowerCase().indexOf(searchVal) > -1))
			);
		});
	};
	const refreshGridData = () => {
		if (selectedEnrollment && selectedEnrollment.stuEnrollId) {
			let enrollmentId = selectedEnrollment.stuEnrollId;
			StudentGradesApi.GetStudentExams(enrollmentId).then(
				(response: any) => {
					if (response) {
						let data = response.data;
						let allStudentGradesData: any = {
							...data,
							DateCompleted:
								data.dateCompleted === "" ? null : data.dateCompleted
						};
						props.setGradesData([] as StudentGrade[]);
						props.setGradesData(props.transferGradesForGrid(allStudentGradesData.result));
					}
				},
				(exception: any) => { }
			);
		}
	}
	React.useEffect(() => {
		refreshGridData();
	}, [selectedEnrollment]);

	const handleSearchOnChange = (v: any) => {
		setsearchFilter(v.target.value);
	};
	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof StudentGrade
	) => {
		const isDesc = orderBy === property && order === "desc";
		setOrder(isDesc ? "asc" : "desc");
		setOrderBy(property);
	};
	const triggerRowValidation = (id: any) => {
		data.triggerValidation(["score_" + id, "dateCompleted_" + id]);
	};
	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
		}
		setSelected([]);
	};

	const handleDefaultDateChange = (value: any) => {
		setStartDateState(value);
	};

	const handleFieldOnChange = (row: any, fieldName: string, value: any) => {
		let dataValues = data.getValues();
		let id = row.gradeBookWeightDetailId;

		if (fieldName === "dateCompleted") {
			let dateValue = new Date(value);
			let newDate = dateValue.getFullYear() + "/" + (dateValue.getMonth() + 1) + "/" + dateValue.getDate();
			row[fieldName] = newDate;
			data.setValue("dateCompleted_" + id, newDate);
		}
		if (fieldName === "score") {
			let theScore = value.target.value;
			if (!isNaN(theScore) || theScore === "T" || theScore === "t") {
				if (theScore === "t" || theScore === "-1") theScore = "T";

				row[fieldName] = theScore;
				data.setValue("score_" + id, theScore);

				if (
					!formDataHasValue("dateCompleted", row.gradeBookWeightDetailId) &&
					formDataHasValue("score", row.gradeBookWeightDetailId)
				) {
					(row as any)["dateCompleted"] = startDate;
					data.setValue("dateCompleted_" + id, startDate);
				}
			} else {
				if (isNaN(theScore) && theScore !== '-') {
					data.setValue("score_" + id, '');
					row[fieldName] = '';
				}
			}
		}

		if (
			dataValues["score_" + id] !== undefined &&
			dataValues["score_" + id] !== "" &&
			dataValues["dateCompleted_" + id] !== undefined &&
			dataValues["dateCompleted_" + id] !== ""
		) {
			row.isDirty = true;
		} else {
			row.isDirty = false;
		}
		props.setGradesData(props.gradesData);
		triggerRowValidation(row.gradeBookWeightDetailId);
	};
	var sortedArrayData = stableSort(
		filterDataBySearch(props.gradesData as any) as any,
		getSorting(order, orderBy))
	return (
		<Paper className={classes.root}>
			<Card className={classes.cardWrapper}>
				<EnhancedTableToolbar
					numSelected={selected.length}
					onChangeDate={(value: any) => {
						handleDefaultDateChange(value);
					}}
					onChangeSearchFilter={handleSearchOnChange}
				/>
				<div className={classes.tableWrapper}>
					<Table
						className={classes.table}
						aria-labelledby="tableTitle"
						size="medium"
						aria-label="enhanced table"
					>
						<EnhancedTableHead
							classes={classes}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
						// rowCount={rows.length}
						// rowCount={3}
						/>
						<TableBody className={classes.tableBodyWrapper}>
							{sortedArrayData.map((row: any, index: any) => {
								const labelId = "enhanced-table-checkbox-${index}";

								return (
									<TableRow className={classes.tableRowWrapper}>
										<TableCell
											classes={{
												head: classes.gridHeadTypeSmall,
												body: classes.gridCellTypeSmall
											}}
											align="left"
										>
											{row.componentType}
										</TableCell>
										<TableCell
											classes={{
												head: classes.gridHeadTypeMedium,
												body: classes.gridCellTypeMedium
											}}
											align="left"
										>
											{row.courseDescription}
										</TableCell>
										<TableCell
											classes={{
												head: classes.gridHeadTypeLarge,
												body: classes.gridCellTypeLarge
											}}
											align="left"
										>
											{row.componentDescription}
										</TableCell>

										<TableCell
											classes={{
												head: classes.gridHeadTypeXsmall,
												body: classes.gridCellTypeXsmall
											}}
											align="left"
										>
											<TextField
												type="text"
												className={classes.textField}
												id={"score_" + row.gradeBookWeightDetailId}
												key={"key_score_" + row.gradeBookWeightDetailId}
												name={"score_" + row.gradeBookWeightDetailId}
												value={row.attempts == 0 && row.score == 0 && !row.isDirty ? "" : row.score}
												onKeyDown = {
													(e) => {
														if (e.key === 'ArrowDown' && index <= sortedArrayData.length) {
															document.getElementById(("score_" + sortedArrayData[index + 1].gradeBookWeightDetailId))?.focus()
														}
														if (e.key === 'ArrowUp' && index > 0) {
															document.getElementById(("score_" + sortedArrayData[index - 1].gradeBookWeightDetailId))?.focus()
														}
												}}
												onChange={(v: any) => {
													handleFieldOnChange(row, "score", v ? v : undefined);
												}}
												error={
													!!data.errors["score_" + row.gradeBookWeightDetailId]
												}
												onBlur={(v: any) => {
													triggerRowValidation(row.gradeBookWeightDetailId);
												}}
												inputRef={data.register({
													validate: {
														requiredWhenScoreEnabled: value => {
															if ((value !== "" &&
																formDataHasValue(
																	"dateCompleted",
																	row.gradeBookWeightDetailId
																))) {
																row.score = value;
															}
															return (value !== "" &&
																formDataHasValue(
																	"dateCompleted",
																	row.gradeBookWeightDetailId
																)) ||
																value !== "" ||
																doNotValidateRow(row.gradeBookWeightDetailId) ||
																"Required"
														}
													}
												})}
												helperText={
													data.errors["score_" + row.gradeBookWeightDetailId]
														? (data.errors[
															"score_" + row.gradeBookWeightDetailId
														] as FieldError).message
														: undefined
												}
											/>
										</TableCell>
										<TableCell
											classes={{
												head: classes.gridHeadTypeMedium,
												body: classes.gridCellTypeMedium
											}}
											align="left"
										>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<KeyboardDatePicker
													className={classes.datePicker}
													disableToolbar
													autoOk={true}
													variant="inline"
													format="MM/dd/yyyy"
													maxDate={d}
													id={"dateCompleted_" + row.gradeBookWeightDetailId}
													key={"key_dateCompleted_" + row.gradeBookWeightDetailId}
													name={"dateCompleted_" + row.gradeBookWeightDetailId}
													//   disableFuture={true}
													// maxDate={d}
													value={
														row.dateCompleted ? new Date(row.dateCompleted) : null
													}
													onChange={(v: any) => {
														handleFieldOnChange(
															row,
															"dateCompleted",
															v ? v : undefined
														);
													}}
													KeyboardButtonProps={{
														"aria-label": "change date"
													}}
													onBlur={(v: any) => {
														triggerRowValidation(row.gradeBookWeightDetailId);
													}}
													invalidDateMessage={""}
													error={
														!!data.errors[
														"dateCompleted_" + row.gradeBookWeightDetailId
														]
													}
													innerRef={(ref: any) => {
														data.register(
															"dateCompleted_" + row.gradeBookWeightDetailId,
															{
																validate: {
																	requiredWhenOtherValues: value =>
																		(value !== "" &&
																			value !== undefined &&
																			formDataHasValue(
																				"score",
																				row.gradeBookWeightDetailId
																			)) ||
																		(value !== "" && value !== undefined) ||
																		doNotValidateRow(
																			row.gradeBookWeightDetailId
																		) ||
																		"Required"
																}
															}
														);
														data.setValue(
															"dateCompleted_" + row.gradeBookWeightDetailId,
															row.dateCompleted
														);
													}}
													helperText={
														data.errors[
															"dateCompleted_" + row.gradeBookWeightDetailId
														]
															? (data.errors[
																"dateCompleted_" + row.gradeBookWeightDetailId
															] as FieldError).message
															: undefined
													}
												/>
											</MuiPickersUtilsProvider>
										</TableCell>
										<TableCell
											classes={{
												head: classes.gridHeadTypeXsmall,
												body: classes.gridCellTypeXsmall
											}}
											align="left"
										>
											{row.attempts}
										</TableCell>

										<TableCell
											classes={{
												head: classes.gridHeadTypeXsmall,
												body: classes.gridCellTypeXsmall
											}}
											align="left"
										>
											{row.attempts > 0 && (
												<AttemptHistoryBox
													gradeBookWeightDetailId={row.gradeBookWeightDetailId}
													showAdj={false}
													showScore={true}
													lblAdj={"Adj"}
													tabType={"Exams"}
													excludeLast={false}
													setSnackBar={props.setSnackBar}
													setConfirmationModal={props.setConfirmationModal}
													refreshGridData={refreshGridData}
													rowData={row}
													stuEnrollId={null}
												/>
											)}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</div>
			</Card>
		</Paper>
	);
}
