import * as React from 'react';
import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { Autocomplete } from '@material-ui/lab';
import { selectors as adHocDetailsSelectors, actions as adHocDetailsActions } from '../../../store/reports/adhocReportDetails';
import { useSelector } from 'react-redux';
import { IAdhocReportField } from '../../../interfaces/reports/adhoc/IAdhocReportField';
import InputField from '../../../components/_Layout/Inputs/InputField';
import AscIcon from '@material-ui/icons/ArrowUpward';
import DescIcon from '@material-ui/icons/ArrowDownward';
import CancelIcon from '@material-ui/icons/Cancel';
import { AhHocReportColumnSortOption } from '../../../enums/AhHocReportColumnSortOption';
import { useActions } from '../../../store/utils';
import { IAdhocRerportColumnSortOption } from '../../../interfaces/reports/adhoc/IAdHocReportOptions';


const useStyles = makeStyles((theme: any) =>
    createStyles({
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        sortColumn: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end'
        },
        actions:{
            borderTop: '1px solid',
            borderTopColor: theme.palette.grey[500]
        }
    }));

interface IAdHocReportSortConfiguration {
    open: boolean;
    close: () => void;
}

const AdHocReportSortConfiguration = (props: IAdHocReportSortConfiguration) => {
    const classes = useStyles({});
    const selectedColumns: Array<IAdhocReportField> = useSelector(adHocDetailsSelectors.selectedColumns);
    const addedSorts: Array<IAdhocRerportColumnSortOption> = useSelector(adHocDetailsSelectors.sortToEdit);
    const actions = useActions({
        addNewSortOptions: adHocDetailsActions.addSortOption,
        removeSort: adHocDetailsActions.deleteSortingOption,
        changeSortDirection: adHocDetailsActions.changeSortDirection,
        clearAllSort: adHocDetailsActions.deleteAllSorts,
        applySort: adHocDetailsActions.applySort,
        editSorts: adHocDetailsActions.setSortForEdit,
        updateSortField: adHocDetailsActions.changeSortField
    }, []);


    React.useEffect(() => {
        if (!addedSorts || addedSorts.length === 0) {
            actions.addNewSortOptions(undefined, AhHocReportColumnSortOption.Asc);
        }
    }, [addedSorts])

    React.useEffect(() => {
        if (props.open) {
            actions.editSorts();
        }
    }, [props.open]);

    return (<Dialog open={props.open} onClose={props.close} >
        <DialogTitle id="customized-dialog-title">
            <Typography variant="h6">Sort</Typography>

            <IconButton aria-label="close" className={classes.closeButton} onClick={props.close}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <Grid container justifyContent="flex-end">
                <Grid item>
                    <Button variant="text" color="primary" size="small" onClick={actions.clearAllSort}>
                        Clear All Sort
                    </Button>
                </Grid>
            </Grid>
            <DialogContentText>
                Select the columns by which data should be sorted
            </DialogContentText>
            <Grid container spacing={2}>
                {
                    addedSorts.map((m, index) => <Grid key={`sorting-item${index}`} item xs={12}>
                        <Grid container key={`sorting-item-container${index}`}>
                            <Grid item xs={5} key={`sorting-item-field${index}`}>
                                <Autocomplete
                                    key={`sorting-item-field-select${index}`}
                                    onChange={(_e: any, value: any) => actions.updateSortField(index ,value?.id ?? undefined)}
                                    options={selectedColumns}
                                    renderInput={(params) => <InputField {...params} label={`${index > 0 ? 'Then ' : ''}Sort By`} />}
                                    getOptionLabel={(opt) => opt.name}
                                    getOptionSelected={(option, value) => (value && (option.id === value.id))}
                                    value={selectedColumns.find(o => o.id === m.fieldId)} />
                            </Grid>
                            <Grid item xs={6} className={classes.sortColumn} key={`sorting-item-direction${index}`}>
                                <Button key={`sorting-item-direction-button${index}`} variant="text" color="primary" endIcon={m.sort === AhHocReportColumnSortOption.Asc ? <AscIcon /> : <DescIcon />} onClick={
                                    () => actions.changeSortDirection(index, (m.sort === AhHocReportColumnSortOption.Asc ? AhHocReportColumnSortOption.Desc : AhHocReportColumnSortOption.Asc))
                                }>
                                    {m.sort === AhHocReportColumnSortOption.Asc ? 'Ascending' : 'Descending'}
                                </Button>
                            </Grid>
                            {!(addedSorts.length === 1 && !m.fieldId) && <Grid item xs={1} className={classes.sortColumn} key={`sorting-item-actions${index}`}>
                                <IconButton key={`sorting-item-actions-delete${index}`} size="small" onClick={() => actions.removeSort(index)}>
                                    <CancelIcon key={`sorting-item-actions-delete-icon${index}`} />
                                </IconButton>
                            </Grid>}
                        </Grid>
                    </Grid>)
                }
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        {
                            addedSorts.length > 0 && <Grid item xs={12}>
                                <Button variant="text" color="primary" size="small" onClick={() => actions.addNewSortOptions(undefined, AhHocReportColumnSortOption.Asc)}>
                                    Add Sort
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Divider />
            </Grid>
        </DialogContent>
        <DialogActions className={classes.actions}>
            <Grid container>
                <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={actions.applySort} disabled={addedSorts && addedSorts.length !== 1 && addedSorts.findIndex(m => !m.fieldId) >= 0}>
                        Apply
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog >)
}

export default AdHocReportSortConfiguration;