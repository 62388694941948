import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { selectors as adHocDetailsSelectors } from '../../../store/reports/adhocReportDetails';
const ComponentImport = (lookUpName: string) => lazy(() => import(`../../../components/AutoComplete/${lookUpName}`));



const lookUpConfigs: { [key: string]: string } = {
    Gender: 'GenderAutoComplete',
    Citizenship: 'CitizenshipAutoComplete',
    'High School': 'InstitutionsAutoComplete',
    Dependancy: 'DependencyAutoComplete',
    'Geographic Area': 'GeographicTypesAutoComplete',
    'Address State (Best)': 'AllStatesAutoComplete',
    'Marital Status': 'MaritalStatusAutoComplete',
    Housing: 'HousingAutoComplete',
    Race: 'EthnicityAutoComplete',
    'Enrollment Status': 'EnrollmentStatusAutoComplete',
    Program: 'ProgramsAutoComplete',
    'Program Version': 'ProgramVersionAutoComplete',
    'Admissions Criteria': 'AdminCriteriaAutoComplete',
    Groups: 'StudentGroupsAutoComplete',
    'Deg/Certificate Seeking': 'DegCertSeekingAutoComplete',
    'Distance Ed. Status': 'DistanceEducationStatusAutoComplete',
    'Drop Reason': 'DropReasonAutoComplete',
    'Education completed': 'EducationCompletedAutoComplete',
    'FA SAP Status (latest)': 'TitleIVFASAPStatuses',
    'Attendance Status': 'AttendanceStatusAutoComplete',
    Campus: 'CampusForCurrentUserAutoComplete',
    'Employer Name': 'PlacementEmployerAutoComplete',
    'Employment Type': 'EmploymentTypeAutoComplete',
    'Job Field of Study': 'PlacementFieldOfStudyAutoComplete',
    'Financial Aid Types': 'FundSourcesAutoComplete',
    'Admissions Rep': 'UserNameMultiCampusAutoComplete',
    'Academic Advisor': 'UserNameMultiCampusAutoComplete'

};

const numericValueFields = ['FA SAP Status (latest)'];

const RenderFilterLookUp = (props: { fieldName: string; values?: string, onChnage: (value: string) => void }) => {
    const [Component, setComponent] = React.useState<any>();
    const reportDetails: {
        reportName?: string;
        categoryId?: string;
        reportDescription?: string;
        isRestricted?: boolean;
        accessibleByUserIds?: Array<string>;
        reportCampusIds?: Array<string>;

    } = useSelector(adHocDetailsSelectors.generatDetails);
    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );
    const loadComponent = async () => {
        if (props.fieldName) {
            try {
                const cmp = await ComponentImport(lookUpConfigs[props.fieldName]);
                setComponent(cmp);
            }
            catch (_err) {
                return;
            }
        }
    }

    React.useEffect(() => {
        loadComponent();
    }, [props.fieldName]);

    const inNumericValue = numericValueFields.includes(props.fieldName);

    return (<Suspense fallback={<span>Loading...</span>}>
        {Component && <Component
            label="Select Values"
            valueFilter={
                props.values
                    ? {
                        key: (['Admissions Rep', 'Academic Advisor'].includes(props.fieldName) && 'text') || "value",
                        values: inNumericValue ? props.values.split(',').map(m => Number(m)) : props.values.split(',')
                    }
                    : undefined
            }
            requestParameter={['Admissions Rep', 'Academic Advisor'].includes(props.fieldName) && {
                campusId: (reportDetails.reportCampusIds && reportDetails.reportCampusIds.length > 0 && reportDetails.reportCampusIds || [userSelectedCampus])
            }}
            multiple
            freeSolo={false}
            includeAllValue
            showIncludeAllValue
            chipSize='small'
            maxTags={1}
            filterHandle={(v: any) => {
                props.onChnage(v && Array.isArray(v) ? v.map(m => (['Admissions Rep', 'Academic Advisor'].includes(props.fieldName) && m.text) || m.value).join(',') : '');
            }}
        />}
    </Suspense>)
}

export default RenderFilterLookUp;