import React, { Fragment, useState, useEffect } from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import {
	Enrollment,
	TitleIVSapStatus,
} from "../../../interfaces/student/academics/Enrollment";
import {
	Typography,
	Theme,
	TextField,
	Grid,
	LinearProgress,
	Badge,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/teal";
import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";
import yellow from "@material-ui/core/colors/yellow";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import { teal } from "@material-ui/core/colors";
import * as actionTypes from "../../../constants/actions.constants";
import { Skeleton } from "@material-ui/lab";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import EnrollmentStatusModal from "../../../pages/student/status/EnrollmentStatusModal";
import AccessManager from "../../security/AccessManager";
import { security } from "../../../constants/Security/securityConstants";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import * as leadsApi from "../../../api/admissions/leadsApi";
import { ILeadImage } from "../../../interfaces/admissions/ILeadImage";
import { ImageType } from "../../../enums/ImageType";
import PhotoUploadButton from "../../../components/_Layout/Buttons/PhotoUploadButton";
import * as studentSearchApi from "../../../api/search/studentSearchApi";
import { StudentSearchModel } from "../../../interfaces/student/search/SearchModel";
import IToasterOptions from "../../../interfaces/IToasterOptions";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		container: {},
		profile: {
			paddingLeft: 5,
			paddingRight: 5,
			textAlign: "center",
		},
		studentName: {
			fontSize: theme.spacing(2) - 2,
			fontWeight: "bold",
		},
		statusDescription: {
			fontSize: theme.spacing(2) - 4,
			textAlign: "center",
		},
		enrollmentText: {
			textAlign: "center",
		},
		mainText: {},
		secondaryText: {},
		enrollmentAutocomplete: {
			textAlign: "center",
		},
		enrollmentSelector: {
			fontSize: theme.spacing(1.5),
		},
		sapProgress: {
			width: "100%",
			height: 10,
		},
		hidden: {
			display: "none",
		},
		sapProgressToolTip: {
			backgroundColor: theme.palette.paper.background,
			boxShadow: theme.palette.boxShadow,
			color: theme.palette.text.primary,
			maxWidth: 220,
			fontSize: theme.typography.pxToRem(12),
			border: theme.palette.border,
		},
		titleIVSapStatusPassed: {
			color: green[700],
		},
		titleIVSapStatusWarning: {
			color: amber[700],
		},
		titleIVSapStatusIneligible: {
			color: red[700],
		},

		programCompletion: {
			fontWeight: "bold",
		},
		textSkeleton: {
			marginTop: theme.spacing(0.1),
			marginLeft: "auto",
			marginRight: "auto",
			marginBottom: theme.spacing(0.1),
		},
		editButton: {
			fontSize: theme.spacing(2),
		},
		integratedBadge: {
			padding: theme.spacing(0.4),
			color: teal[500],
		},
		badgeInfo: {
			display: "inline",
			position: "relative",
			top: 10,
			left: -5,
			marginRight: 10
		}
	})
);

const SapPassedLinearProgress = withStyles({
	root: {
		height: 10,
		backgroundColor: grey[500],
		borderRadius: 0,
	},
	bar: {
		backgroundColor: teal[400],
	},
})(LinearProgress);

const SapWarningLinearProgress = withStyles({
	root: {
		height: 10,
		backgroundColor: grey[500],
		borderRadius: 0,
	},
	bar: {
		backgroundColor: amber[700],
	},
})(LinearProgress);

const SapIneligibleLinearProgress = withStyles({
	root: {
		height: 10,
		backgroundColor: grey[500],
		borderRadius: 0,
	},
	bar: {
		backgroundColor: red[700],
	},
})(LinearProgress);

const SapProbationLinearProgress = withStyles({
	root: {
		height: 10,
		backgroundColor: grey[500],
		borderRadius: 0,
	},
	bar: {
		backgroundColor: yellow[700],
	},
})(LinearProgress);

type IEnrollmentSelectorProps = {
	className?: string;
	showStudentStatus?: boolean;
	showProfilePhotoUpload?: boolean;
	onChange?: any;
	showProgressBar?: boolean;
	showEnrollmentSelect?: boolean;
	mainTextClassName?: string;
	secondaryTextClassName?: string;
};

const EnrollmentSelector = (props: IEnrollmentSelectorProps) => {
	const { className, onChange, showProfilePhotoUpload, ...rest } = props;
	const classes = useStyles({});
	const dispatch = useDispatch();

	const loggedInUser = useSelector((state: any) => state.session.user);

	const student = useSelector((state: any) =>
		state.userstate[state.session.user.userId]
			? state.userstate[state.session.user.userId].selectedStudent
			: null
	);
	const selectedEnrollment = useSelector((state: any) =>
		state.student ? state.student.selectedEnrollment : null
	);

	const isAfaIntegrated = student && !!student.afaStudentId;

	const [enrollment, setEnrollment] = useState<Enrollment>({} as Enrollment);

	useEffect(() => {
		if (student) {
			setEnrollment(student.enrollments[0]);
		}
	}, [student]);

	useEffect(() => {
		if (
			selectedEnrollment &&
			enrollment.stuEnrollId !== selectedEnrollment.stuEnrollId
		) {
			setEnrollment(selectedEnrollment);
		}
	}, [selectedEnrollment]);

	const enrollmentSelected = (e: Enrollment) => {
		dispatch({
			type: actionTypes.studentProfileConstants.SESSION_SET_SELECTED_ENROLLMENT,
			selectedEnrollment: e,
		});

		setEnrollment(e);
	};

	const getProgressBar = (sapStatus: TitleIVSapStatus | null) => {
		switch (sapStatus) {
			case TitleIVSapStatus.Passed:
				return SapPassedLinearProgress;
			case TitleIVSapStatus.Warning:
				return SapWarningLinearProgress;
			case TitleIVSapStatus.Ineligible:
				return SapIneligibleLinearProgress;
			case TitleIVSapStatus.Probation:
				return SapProbationLinearProgress;
			default:
				return SapPassedLinearProgress;
		}
	};
	const CustomProgressBar = getProgressBar(enrollment.sapStatus);

	const getTitleIVSAPStatus = (sapStatus: TitleIVSapStatus | null) => {
		switch (sapStatus) {
			case TitleIVSapStatus.Passed:
				return "Passed";
			case TitleIVSapStatus.Warning:
				return "Warning";
			case TitleIVSapStatus.Ineligible:
				return "Ineligible";
			case TitleIVSapStatus.Probation:
				return "Probation";
			default:
				return "";
		}
	};

	const getTitleIVSAPStatusClassName = () => {
		switch (enrollment.sapStatus) {
			case TitleIVSapStatus.Passed:
				return classes.titleIVSapStatusPassed;
			case TitleIVSapStatus.Warning:
				return classes.titleIVSapStatusWarning;
			case TitleIVSapStatus.Ineligible:
				return classes.titleIVSapStatusPassed;
			default:
				return classes.titleIVSapStatusPassed;
		}
	};

	const [enrollmentStatusModalOpen, setEnrollmentStatusModalOpen] =
		useState(false);

	if (!student) {
		return (
			<Fragment>
				<Grid
					container
					direction="row"
					alignItems="center"
					alignContent="center"
					justify="center"
				>
					<Grid item xs={12}>
						<div>
							<Skeleton
								variant="rect"
								animation="wave"
								width={200}
								height={20}
								className={classes.textSkeleton}
							/>
							<Skeleton
								variant="rect"
								// animation="wave"
								width={200}
								height={45}
								className={classes.textSkeleton}
							/>
						</div>
					</Grid>
				</Grid>
			</Fragment>
		);
	}

	const TitleIVSAPStatus = getTitleIVSAPStatus(enrollment.sapStatus);

	const handleOpenEnrollmentStatus = () => {
		setEnrollmentStatusModalOpen(true);
	};

	const handleStatusUpdateEvent = (newStatus: any) => {
		studentSearchApi
			.studentProfileSearch({
				Filter: student.studentId,
				ShowAll: true,
				CampusId: student.enrollments[0]?.campusId ?? "",
				creditHoursOnly: false,
			} as StudentSearchModel)
			.then(
				(rv: any) => {
					let data = rv.result[0];
					let updatedEnrollment = data?.enrollments[0];
					if (data && updatedEnrollment) {
						setEnrollment(updatedEnrollment);

						dispatch({
							type: actionTypes.studentProfileConstants
								.SESSION_SET_SELECTED_ENROLLMENT,
							selectedEnrollment: { ...updatedEnrollment },
						});

						dispatch({
							type: actionTypes.userStateConstants
								.USER_STATE_SET_SELECTED_STUDENT,
							data: {
								userId: loggedInUser.userId,
								studentProfile: data,
							},
						});
					}
				},
				(e: any) => { }
			);
	};

	function getByteArray(file: any): Promise<any[] | undefined> {
		return new Promise(function (resolve, reject) {
			let fileReader = new FileReader();

			fileReader.readAsArrayBuffer(file);
			fileReader.onload = function (ev) {
				if (ev.target && ev.target.result) {
					const array = new Uint8Array(ev.target.result as ArrayBuffer);
					const fileByteArray = [];
					for (let i = 0; i < array.length; i++) {
						fileByteArray.push(array[i]);
					}
					resolve(fileByteArray); // successful
				} else resolve(undefined);
			};
			fileReader.onerror = reject; // call reject if error
		});
	}

	const handlePhotoChange = (e: any) => {
		e.preventDefault();
		let file: File = e.target.files[0];

		if (file) {
			getByteArray(file).then((byteArray) => {
				if (byteArray && byteArray.length > 0) {
					leadsApi
						.uploadStudentPhoto({
							leadId: student.leadId,
							image: byteArray,
							imgSize: byteArray.length,
							imgFile: student.studentName + "_Profile_Photo",
							mediaType: file.type,
							imageTypeId: ImageType.ProfilePhoto,
						} as ILeadImage)
						.then((res: any) => {
							if (res && res.data) {
								studentSearchApi
									.studentProfileSearch({
										Filter: student.studentId,
										ShowAll: true,
										CampusId: student.enrollments[0]?.campusId ?? "",
										creditHoursOnly: false,
									} as StudentSearchModel)
									.then(
										(rv: any) => {
											let data = rv.result[0];
											dispatch({
												type: actionTypes.userStateConstants
													.USER_STATE_SET_SELECTED_STUDENT,
												data: {
													userId: loggedInUser.userId,
													studentProfile: data,
												},
											});

											dispatch({
												type: actionTypes.studentProfileConstants
													.SESSION_SET_STUDENT_PROFILE,
												studentProfile: data,
											});

											dispatch({
												type: actionTypes.mruConstants.REFRESH_MRU,
											});

											let messageData: IToasterOptions = {
												message:
													"The student profile picture has been updated.",
												showToaster: true,
												variant: "info",
											};
											dispatch({
												type: actionTypes.toasterConstants.SHOW_TOASTER,
												data: messageData,
											});
										},
										(e: any) => { }
									);
							}
						});
				}
			});
		}
	};

	return (
		<Fragment>
			<Grid
				container
				direction="row"
				alignItems="center"
				alignContent="center"
				justify="center"
				className={clsx(classes.container, props.className)}
			>
				<Grid item xs={12}>
					<div className={classes.profile}>
						<Typography
							className={clsx(
								classes.studentName,
								classes.mainText,
								props.mainTextClassName
							)}
							variant="h5"
						>
							{student.studentName} -{" "}
							{enrollment.studentNumber ?? student.studentNumber}
							{isAfaIntegrated && (
								<Tooltip title="ISIR on file">
									<IconButton
										aria-label="delete"
										className={classes.integratedBadge}
									>
										<CheckCircleIcon />
									</IconButton>
								</Tooltip>
							)}
							{showProfilePhotoUpload && (
								<AccessManager
									allowedPermissions={[
										security.permissions.student.updateStudentInfo,
									]}
									renderNoAccess={() => null}
								>
									<PhotoUploadButton onClick={handlePhotoChange} />
								</AccessManager>
							)}
						</Typography>
						{props.showStudentStatus && (
							<Typography
								className={clsx(
									classes.statusDescription,
									classes.secondaryText,
									props.secondaryTextClassName
								)}
							>
								{enrollment.status}
								<AccessManager
									allowedPermissions={[
										security.permissions.student.viewEnrollmentStatus,
										security.permissions.student.updateEnrollmentStatus,
										security.permissions.student.deleteEnrollmentStatus,
									]}
									renderNoAccess={() => null}
								>
									<IconButton
										aria-label="enrollment status"
										onClick={handleOpenEnrollmentStatus}
									>
										<EditIcon className={classes.editButton} />
									</IconButton>
								</AccessManager>
								<p>
									{
										enrollment.currentStatusStart != null && enrollment.currentStatusEnd != null ? (new Date(enrollment.currentStatusStart as any).toLocaleDateString() + " - " + new Date(enrollment.currentStatusEnd as any).toLocaleDateString()) : null
									}
								</p>
							</Typography>
						)}

						<Grid
							container
							direction="row"
							alignItems="center"
							alignContent="center"
						>
							<Grid item xs={1}></Grid>

							<Grid item xs={10}>
								{props.showEnrollmentSelect && (
									<React.Fragment>
										<Badge className={classes.badgeInfo} color="primary" badgeContent={student.enrollments?.length}></Badge>
										<Autocomplete
											id={"enrollment-selector"}
											style={{ display: "inline" }}
											className={classes.enrollmentAutocomplete}
											options={student.enrollments}
											getOptionLabel={(option: Enrollment) =>
												option.programVersionDescription
											}
											value={enrollment}
											classes={{
												clearIndicator: classes.hidden,
												input: classes.enrollmentSelector,
											}}
											onChange={(e: any, v: any) => {
												enrollmentSelected(v);
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													fullWidth
													InputProps={{
														...params.InputProps,
														disableUnderline: true,
														classes: {
															input: classes.enrollmentText,
														},
													}}
												/>
											)}
										/>
									</React.Fragment>
								)}
							</Grid>
						</Grid>

						{props.showProgressBar && (
							<Tooltip
								classes={{ tooltip: classes.sapProgressToolTip }}
								title={
									<React.Fragment>
										<Typography color="inherit">
											Title IV SAP Status:{" "}
											<span className={getTitleIVSAPStatusClassName()}>
												{TitleIVSAPStatus}
											</span>
										</Typography>
										<Typography color="inherit">
											Program Completion:{" "}
											<span className={classes.programCompletion}>
												{enrollment.percentCompleted}%
											</span>
										</Typography>
									</React.Fragment>
								}
							>
								<CustomProgressBar
									className={clsx(classes.sapProgress)}
									variant="determinate"
									value={
										enrollment.percentCompleted >= 100
											? 100
											: enrollment.percentCompleted
									}
								/>
							</Tooltip>
						)}
					</div>
				</Grid>
				<Grid item xs={1}></Grid>
			</Grid>
			<EnrollmentStatusModal
				open={enrollmentStatusModalOpen}
				setOpen={setEnrollmentStatusModalOpen}
				updateEvent={handleStatusUpdateEvent}
			/>
		</Fragment>
	);
};

EnrollmentSelector.defaultProps = {
	showEnrollmentSelect: true,
	showStudentStatus: true,
	showProgressBar: false,
};

export default EnrollmentSelector;
