import React from "react";
import {
  makeStyles,
  Card,
  createStyles,
  CardContent,
  Grid,
  Paper,
  Table,
  withStyles,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  IconButton,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { StudentProfile } from "../../../../interfaces/student/profile/StudentProfile";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useForm } from "react-hook-form";
import CloseIcon from "@material-ui/icons/Close";
import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";
import StatusAutoComplete from "../../../../components/AutoComplete/StatusAutoComplete";
import PhoneTypesAutoComplete from "../../../../components/AutoComplete/PhoneTypesAutoComplete";
import * as PhonesApi from "../../../../api/student/profle/contactInfo/PhonesApi";

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {},
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%",
    },
    saveButton: {
      width: 130,
    },
    tableContainer: {
      height: "400px",
    },
    typography: {
      padding: theme.spacing(2),
    },
    cardWrapper: {
      marginBottom: theme.spacing(2),
    },
    root: {
      paddingTop: theme.spacing(2),
      minHeight: "100%",
      height: "100%",
      width: "100%",
      margin: "auto",
    },
    paperRoot: {
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
  })
);

interface PhoneDetail {
  id: string;
  leadId: string;
  phoneTypeId: string;
  phoneTypeDesc: string;
  phone: string;
  position: number;
  extension: string;
  isForeignPhone: boolean;
  isBest: boolean;
  isShowOnLeadPage: boolean;
  statusId: string;
  status: string;
  checkForLeadStatus: boolean;
}

type PhonePageProps = {
  setSnackBarProps?: any;
};

const PhonePage = (props: PhonePageProps) => {
  const classes = useStyles({});

  const data = useForm<PhoneDetail>({ mode: "onBlur" });

  const appSettings = useSelector((state: any) => {
    return state.settings.settings;
  });
  const PhoneValue = appSettings.TakePhoneInputValid;

  const selectedStudent: StudentProfile = useSelector(
    (state: any) => state.student
  ) as StudentProfile;

  const [phones, setPhones] = React.useState<PhoneDetail[]>([]);
  const [isCreatingPhone, setIsCreatingPhone] = React.useState(false);
  const [newPhone, setNewPhone] = React.useState<PhoneDetail | null>(null);

  React.useEffect(() => {
    getPhones();
  }, [selectedStudent]);

  const getPhones = () => {
    if (selectedStudent.selectedEnrollment !== undefined) {
      if (
        selectedStudent.leadId !== undefined &&
        selectedStudent.leadId.length > 0
      ) {
        PhonesApi.getAll(selectedStudent.leadId).then(
          (response: any) => {
            setPhones(response);
          },
          (exception: any) => {}
        );
      }
    }
  };

  const handleAddPhoneClick = () => {
    setNewPhone({
      id: "",
      leadId: "",
      phoneTypeId: "",
      phoneTypeDesc: "",
      phone: "",
      position: 0,
      extension: "",
      isForeignPhone: false,
      isBest: false,
      isShowOnLeadPage: false,
      statusId: "",
      status: "",
      checkForLeadStatus: false,
    });
    setIsCreatingPhone(true);
  };

  const PhoneTypeValidation = (errorName: any) => {
    if (errorName?.type === "validate") {
      return "Phone Type is Required.";
    } else return undefined;
  };

  const PhoneValidation = (errorName: any) => {
    if (errorName?.type === "validate") {
      return "Phone is not in correct format.";
    } else return null;
  };

  const onValidation = (fieldId: string, value: any) => {
    if (data.errors && (newPhone as any)[fieldId] != value) {
      handlePhoneFormChange(fieldId, value);
    }
  };

  const handlePhoneFormChange = (field: any, value: any) => {
    if (newPhone) {
      setNewPhone({ ...newPhone, [field]: value });
    }
  };

  const handlePhoneCancel = () => {
    setIsCreatingPhone(false);
    setNewPhone(null);
  };

  const deletePhone = (id: any) => {
    setLoading(true);
    PhonesApi.deletePhone(selectedStudent.leadId, id).then(
      (response: any) => {
        props.setSnackBarProps(() => {
          return {
            variant: "success",
            showSnackBar: true,
            messageInfo: "Phone deleted successfully.",
          };
        });
        setDeletePop(undefined);
        getPhones();
        setIsCreatingPhone(false);
        setLoading(false);
      },
      (exception: any) => {
        props.setSnackBarProps(() => {
          return {
            variant: "error",
            showSnackBar: true,
            messageInfo: exception,
          };
        });
        setLoading(false);
      }
    );
  };

  const editPhone = (id: any) => {
    if (selectedStudent.selectedEnrollment !== undefined) {
      if (
        selectedStudent.leadId !== undefined &&
        selectedStudent.leadId.length > 0
      ) {
        PhonesApi.getPhone(selectedStudent.leadId, id).then(
          (response: any) => {
            setNewPhone(response);
            setIsCreatingPhone(true);
          },
          (exception: any) => {}
        );
      }
    }
  };

  const handleSavePhone = () => {
    if (newPhone) {
      data.triggerValidation().then((validation: any) => {
        if (validation) {
          setLoading(true);

          if (newPhone.id == "") {
            PhonesApi.postPhone(selectedStudent.leadId, {
              ...newPhone,
              id: newPhone.id,
            } as any).then(
              (response: any | null) => {
                if (response && response.data && response.data.result != null) {
                  props.setSnackBarProps(() => {
                    return {
                      variant: "success",
                      showSnackBar: true,
                      messageInfo: "Phone saved successfully.",
                    };
                  });
                  setIsCreatingPhone(false);
                  getPhones();
                } else {
                  props.setSnackBarProps(() => {
                    return {
                      variant: "error",
                      showSnackBar: true,
                      messageInfo: response.data.resultStatusMessage,
                    };
                  });
                }
                setLoading(false);
              },
              (exception: any) => {
                props.setSnackBarProps(() => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: exception,
                  };
                });
              }
            );
          } else {
            PhonesApi.updatePhone(selectedStudent.leadId, {
              ...newPhone,
              id: newPhone.id,
            } as any).then(
              (response: any | null) => {
                if (response && response.data && response.data.result != null) {
                  props.setSnackBarProps(() => {
                    return {
                      variant: "success",
                      showSnackBar: true,
                      messageInfo: "Phone updated successfully.",
                    };
                  });
                  setIsCreatingPhone(false);
                  getPhones();
                } else {
                  props.setSnackBarProps(() => {
                    return {
                      variant: "error",
                      showSnackBar: true,
                      messageInfo:response.data.resultStatusMessage,
                    };
                  });
                }
                setLoading(false);
              },
              (exception: any) => {
                setLoading(false);
                props.setSnackBarProps(() => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: exception,
                  };
                });
              }
            );
          }
        }
      });
    }
  };

  const [deletePop, setDeletePop] = React.useState<(() => void) | undefined>(
    undefined
  );
  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <Card square={true} className={classes.cardWrapper}>
      {/* <CustomCardTitle title={"Phones"}></CustomCardTitle> */}
      <CardContent>
        <Grid container direction="row" spacing={2}>
          {/* Phone Table */}
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="phone table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Type</TableCell>
                    <TableCell align="left">Phone</TableCell>
                    <TableCell align="left">Ext</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Best</TableCell>
                    <TableCell align="left">Edit</TableCell>
                    <TableCell align="left">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {phones.map((phone) => (
                    <TableRow key={phone.id}>
                      <TableCell align="left">{phone.phoneTypeDesc}</TableCell>
                      <TableCell align="left">{phone.phone}</TableCell>
                      <TableCell align="left">{phone.extension}</TableCell>
                      <TableCell align="left">{phone.status}</TableCell>
                      <TableCell align="left">
                        {phone.isBest ? "Yes" : "No"}
                      </TableCell>
                      <TableCell align="left" style={{ width: 20 }}>
                        <Tooltip title="Edit Phone" style={{ padding: 0 }}>
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={(e: any) => {
                              editPhone(phone.id);
                            }}
                          >
                            <EditIcon></EditIcon>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="left" style={{ width: 20 }}>
                        <Tooltip title="Delete Phone" style={{ padding: 0 }}>
                          <IconButton
                            onClick={() => {
                              setDeletePop(() => () => deletePhone(phone.id));
                            }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            {!isCreatingPhone && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddPhoneClick}
                size="small"
              >
                Add Phone
              </Button>
            )}
          </Grid>
          {isCreatingPhone && newPhone && (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="subtitle1">Add New Phone</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <PhoneTypesAutoComplete
                        filterHandle={(v: any) => {
                          handlePhoneFormChange(
                            "phoneTypeId",
                            v ? v.value : undefined
                          );
                        }}
                        label={"Phone Type *"}
                        id="phoneTypeId"
                        name="phoneTypeId"
                        inputRef={data.register({
                          validate: (value: any) => {
                            if (newPhone.phone && !value) return false;
                            else return true;
                          },
                        })}
                        helperText={PhoneTypeValidation(
                          data.errors.phoneTypeId
                        )}
                        error={!!data.errors.phoneTypeId}
                        valueFilter={
                          newPhone && newPhone.phoneTypeId
                            ? {
                                key: "value",
                                values: [newPhone.phoneTypeId],
                              }
                            : undefined
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ThemeTextField
                        id="phone"
                        name="phone"
                        className={classes.textField}
                        label={"Number *"}
                        margin="none"
                        value={newPhone.phone ?? ""}
                        inputRef={data.register({
                          validate: (value: any) => {
                            onValidation("phone", value);
                            if (newPhone.phoneTypeId && !value) return false;
                            else if (PhoneValue == "True")
                              return /^\d{10}$/.test(value);
                            else
                              return /^[+]?1?[-\. ]?(\(\d{3}\)?[-\. ]?|\d{3}?[-\. ]?)?\d{3}?[-\. ]?\d{4}$/.test(
                                value
                              );
                          },
                        })}
                        helperText={PhoneValidation(data.errors.phone)}
                        error={!!data.errors.phone}
                        onChange={(v: any) => {
                          handlePhoneFormChange(
                            "phone",
                            v ? v.target.value : undefined
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ThemeTextField
                        id="position"
                        className={classes.textField}
                        label="Position"
                        margin="none"
                        value={newPhone.position}
                        onChange={(v: any) => {
                          handlePhoneFormChange(
                            "position",
                            v ? v.target.value : undefined
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ThemeTextField
                        id="extension"
                        className={classes.textField}
                        label="Ext."
                        margin="none"
                        value={newPhone.extension}
                        onChange={(v: any) => {
                          handlePhoneFormChange(
                            "extension",
                            v ? v.target.value : undefined
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={newPhone.isForeignPhone}
                            onChange={(e) =>
                              handlePhoneFormChange(
                                "isForeignPhone",
                                e.target.checked
                              )
                            }
                            color="primary"
                          />
                        }
                        label="Is Foreign Phone"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={newPhone.isBest}
                            onChange={(e) =>
                              handlePhoneFormChange("isBest", e.target.checked)
                            }
                            color="primary"
                          />
                        }
                        label="Is Best"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StatusAutoComplete
                        id="statusId"
                        name="statusId"
                        label="Status *"
                        valueFilter={
                          newPhone.statusId
                            ? {
                                key: "value",
                                values: [newPhone.statusId],
                              }
                            : undefined
                        }
                        filterHandle={(v: any) => {
                          handlePhoneFormChange("statusId", v ? v.value : null);
                        }}
                        error={!!data.errors.statusId}
                        inputRef={data.register({ required: true })}
                        helperText={
                          data.errors.statusId
                            ? "Status is required."
                            : undefined
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProgressSaveButton
                        text="Save Phone"
                        onClick={() => {
                          handleSavePhone();
                        }}
                        size="small"
                        loading={loading}
                        buttonClassName={classes.saveButton}
                      ></ProgressSaveButton>
                      <Button
                        color="secondary"
                        variant="contained"
                        size="small"
                        onClick={handlePhoneCancel}
                        style={{ marginLeft: 5 }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <Dialog
        open={!!deletePop}
        onClose={() => !loading && setDeletePop(undefined)}
      >
        <DialogTitle id="customized-dialog-title">
          <Typography variant="h6">Delete Confirmation</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => !loading && setDeletePop(undefined)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this phone?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ProgressSaveButton
            loading={loading}
            onClick={() => deletePop !== undefined && deletePop()}
            text="Delete"
            type="button"
          />{" "}
          <Button
            variant="contained"
            color="default"
            onClick={() => !loading && setDeletePop(undefined)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default PhonePage;
