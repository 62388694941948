export const TransferFromProgramApiAutoComplete = {
  config: {
    acId: "transferFromProgram",
    cacheTime: 5,
    route: "/SystemCatalog/Program/GetTransferFromProgram",
    requestType: "GET",
    body: null,
    hasUrlParams: true,
    useCache: false,
    storeInCache: true,
    isCampusSpecific: false,
    isUserSpecific: false,
    loadOnRender: true,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
      return data;
    }
  }
};
