import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import InfoIcon from "@material-ui/icons/Info";
import EnrollmentSelector from "../EnrollmentSelector/EnrollmentSelector";
import { tsObjectKeyword } from "@babel/types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useSelector } from "react-redux";
import {
	makeStyles,
	createStyles,
	withStyles,
	Theme
} from "@material-ui/core/styles";
import React, { Fragment, useState, useEffect } from "react";

import {
	Avatar,
	Drawer,
	Typography,
	Fab,
	Badge,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Button,
	Link
} from "@material-ui/core";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		gridContainer: {
			height: "100%"
		},
		drawer: {
			width: 280
		},
		root: {
			backgroundColor: theme.palette.background.paper,
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2)
			}
		},
		fab: {
			position: "fixed",
			bottom: 32,
			right: 32,
			zIndex: theme.zIndex.drawer - 100
		},
		avatar_icon: {
			height: 50,
			width: 50
		},
		sideBarContent: {},
		card: {
			margin: theme.spacing(1)
		},
		bullet: {
			display: "inline-block",
			margin: "0 2px",
			transform: "scale(0.8)"
		},
		cardTitle: {
			fontSize: 14,
			marginLeft: theme.spacing(1),
			marginTop: theme.spacing(1),
			color: theme.palette.text.secondary
		},
		fieldHeaderStyle: {
			color: theme.palette.text.primary,
			fontWeight: "bold",
			marginTop: theme.spacing(1)
		},
		fieldTextStyle: {
			fontSize: 14,
			color: theme.palette.text.primary
		},
		avatar: {
			margin: 10
		}
	})
);

const colorStyle = makeStyles((theme: any) =>
	createStyles({
		red: {
			width: 50,
			height: 50,
			color: "#fff",
			backgroundColor: "#b71c1c"
		},
		pink: {
			width: 50,
			height: 50,
			color: "#fff",
			backgroundColor: "#880e4f"
		},
		purple: {
			width: 50,
			height: 50,
			color: "#fff",
			backgroundColor: "#4a148c"
		},
		deepPurple: {
			width: 50,
			height: 50,
			color: "#fff",
			backgroundColor: "#311b92"
		},
		indigo: {
			width: 50,
			height: 50,
			color: "#fff",
			backgroundColor: "#1a237e"
		},
		blue: {
			width: 50,
			height: 50,
			color: "#fff",
			backgroundColor: "#0d47a1"
		},
		lightBlue: {
			width: 50,
			height: 50,
			color: "#fff",
			backgroundColor: "#01579b"
		},
		cyan: {
			width: 50,
			height: 50,
			color: "#fff",
			backgroundColor: "#006064"
		},
		teal: {
			width: 50,
			height: 50,
			color: "#fff",
			backgroundColor: "#004d40"
		},
		green: {
			width: 50,
			height: 50,
			color: "#fff",
			backgroundColor: "#1b5e20"
		},
		lightGreen: {
			width: 50,
			height: 50,
			color: "#fff",
			backgroundColor: "#33691e"
		},
		lime: {
			width: 50,
			height: 50,
			color: "#fff",
			backgroundColor: "#827717"
		},
		yellow: {
			width: 50,
			height: 50,
			color: "#fff",
			backgroundColor: "#f57f17"
		},
		amber: {
			width: 50,
			height: 50,
			color: "#fff",
			backgroundColor: "#ff6f00"
		},
		orange: {
			width: 50,
			height: 50,
			color: "#fff",
			backgroundColor: "#e65100"
		},
		deepOrange: {
			width: 50,
			height: 50,
			color: "#fff",
			backgroundColor: "#bf360c"
		},
		brown: {
			width: 50,
			height: 50,
			color: "#fff",
			backgroundColor: "#3e2723"
		},
		blueGrey: {
			width: 50,
			height: 50,
			color: "#fff",
			backgroundColor: "#263238"
		}
	})
);

type IStudentAvatarProps = {
	firstName?: string;
	lastName?: string;
	src?: string;
	className?: any;
};
function getRandomInt(props: any) {
	return Math.floor(Math.random() * Math.floor(props));
}

const StudentAvatar = (props: IStudentAvatarProps) => {
	const classes = useStyles({});
	const colorclasses = colorStyle({});
	const [colorInt, setColorInt] = React.useState<number>();
	let initials = " ";

	useEffect(() => {
		setColorInt(getRandomInt(colorsArray.length));
	}, [props.firstName]);

	let colorsArray = [
		colorclasses.red,
		colorclasses.pink,
		colorclasses.purple,
		colorclasses.deepPurple,
		colorclasses.indigo,
		colorclasses.blue,
		colorclasses.lightBlue,
		colorclasses.cyan,
		colorclasses.teal,
		colorclasses.green,
		colorclasses.lightGreen,
		colorclasses.lime,
		colorclasses.yellow,
		colorclasses.amber,
		colorclasses.orange,
		colorclasses.deepOrange,
		colorclasses.brown,
		colorclasses.blueGrey
	];

	const str = props.firstName;
	const str1 = props.lastName;
	if (str !== undefined && str1 !== undefined) {
		initials =
			str.substring(0, 1).toUpperCase() + str1.substring(0, 1).toUpperCase();
	}

	if (props.src === undefined || props.src === " " || props.src === "") {
		return (
			<Grid
				container
				justify="center"
				alignItems="center"
				className={classes.gridContainer}
			>
				<Avatar
					className={clsx(colorsArray[colorInt as number], props.className)}
				>
					{initials}
				</Avatar>
			</Grid>
		);
	} else {
		return (
			<Avatar className={classes.avatar_icon} alt={initials} src={props.src} />
		);
	}
};
export default StudentAvatar;
