import {
	Dialog,
	makeStyles,
	createStyles,
	DialogTitle,
	DialogContent,
	Grid,
	DialogActions,
	Button,
	IconButton,
	InputLabel
} from '@material-ui/core';
import ProgressSaveButton from '../../components/_Layout/Buttons/ProgressSaveButton';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import InputField from '../../components/_Layout/Inputs/InputField';
import { InputType } from '../../constants/uiConstants/inputConstants';
import ITerminationSettings from '../../interfaces/academicRecords/ITerminationSettings';
import { useForm, FormContext } from 'react-hook-form';
import { EmptyGuid } from '../../utils/constants';
import { terminationSettingsApi } from '../../api/student/academics/terminationSettingsApi';
import CustomSnackbar from '../../components/notifications/CustomSnackbar';
import AccessManager from '../../components/security/AccessManager';
import { security } from '../../constants/Security/securityConstants';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { mtfWidgetApi } from '../../api/student/academics/mtfWidgetApi';

const useStyles = makeStyles((theme: any) =>
	createStyles({
		dialogHeader: {
			backgroundColor: theme.palette.site.secondary,
			'& .MuiTypography-root': {
				fontWeight: theme.typography.fontWeightBold,
				fontSize: '12pt',
			},
		},
		buttonSection: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(3),
		},
		saveButton: {
			width: 130,
		},
		fullWidthField: {
			width: '100%',
		},

		downloadIcon: {
			width: '100%',
			fontSize: '10em',
		}
		, Disabled: {
			color: 'rgba(0, 0, 0, 0.26)'
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(0),
			top: theme.spacing(0)
		},
		inputFeildGrid: {
			marginBottom:theme.spacing(1)
		}
	})
);

const MtfWidgetSettingsPopup = (props: any) => {
	const classes = useStyles({});
	const campusId = useSelector(
		(state: any) => state.student.enrollments[0].campusId
	);

	const [loaderState, setLoaderState] = React.useState<boolean>(false);

	const handleClose = () => {
		props.setOpen(false);
	};

	const [minimumPercentage, setMinimumPercentage] = React.useState<number>(0);

    interface SnackBarProps {
        open: boolean;
        message: string;
        variant: string;
    }

    const [snackProps, setSnackProps] = React.useState<SnackBarProps>({ open: false, message: '', variant: 'success' });

    const data = useForm<any>({ mode: 'onBlur' });
    const { handleSubmit } = data;

    const onSubmit = (_d: any) => {
    	data.triggerValidation().then((validation: any) => {
            if (validation) {
                setLoaderState(true);
                mtfWidgetApi.updateMTFWidgetSettingValue(campusId, minimumPercentage).then(
                    (response: any) => {
                        setLoaderState(false);

                            if(props.refreshData){
                                props.refreshData();
                            }

                            setSnackProps({
                                open: true,
                                message: 'Saved Successfully',
                                variant: 'success'
                            });
                            handleClose();
                    },
                    (exception: any) => {
                        setLoaderState(false);
                        setSnackProps({
                            open: true,
                            message: exception,
                            variant: 'error'
                        });
                    }
                );
            }
        });
    };
    
    React.useEffect(() => {
    	if(!!props.open){
    		mtfWidgetApi.getMTFWidgetSettingValue(campusId).then(response => {
    			setMinimumPercentage(response);
    		});
    	}
    }, [campusId, props.open]);

    const handleSnackbarClose = () => {
    	setSnackProps({
    		open: false,
    		message: '',
    		variant: 'success'
    	});
    };

    return (
    	<AccessManager
    		allowedPermissions={[security.permissions.MTFWidget.ViewMTFWidget]}
    		renderNoAccess={() => null}
    	>
    		<div>
    			<Dialog
    				open={props.open}
    				onClose={handleClose}
    				aria-labelledby="form-dialog-title"
    				maxWidth={'xs'}
    				fullWidth={true}
    				disableBackdropClick >
    				<DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
    					<Typography>MTF Widget Settings</Typography>
    					<IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
    						<CloseIcon />
    					</IconButton>
    				</DialogTitle>
    				<DialogContent>
    					<FormContext {...data}>
    						<form onSubmit={handleSubmit(onSubmit)}>
    							<Grid container direction="row" >
    								<Grid item xs={12} md={12} sm={12}>
    									<InputLabel required={true} shrink={true} >
                                            Show students who exceeded
    									</InputLabel>
    								</Grid>
    							</Grid>

    							<Grid container direction="row" className={classes.inputFeildGrid}>
    								<Grid item xs={12} md={12} sm={12}>
    									<InputField
    										type={InputType.NUMBER}
    										key={'txtMinimumMTFPercentage'}
    										id={'txtMinimumMTFPercentage'}
    										label=""
    										name={'txtMinimumMTFPercentage'}
    										onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
    											if(!!e.target.value && !isNaN(Number(e.target.value))){
                                                    setMinimumPercentage(Number(e.target.value));
                                                }
    										}}
    										defaultValue={minimumPercentage}
    										datatype={'Number'}
    										autoComplete={'off'}
    										error={!!data.errors.txtMinimumMTFPercentage}
    										inputRef={data.register({
    											validate: {
    												greaterThan0: (value) =>
    													Number(value) > 0 ||
                                                        'required',
    											},
    											required: true
    										})}
    										required={true}
    										helperText={data.errors.txtMinimumMTFPercentage ? 'required' : ''}
    									/>
    								</Grid>
    							</Grid>
    						</form>
    					</FormContext>
    				</DialogContent>
    				<DialogActions className={classes.buttonSection}>
    					<Grid container direction="row" spacing={1}
    						alignContent="center"
    						alignItems="center"
    						justify="center"
    					>
                            
    						<AccessManager
    							allowedPermissions={[security.permissions.MTFWidget.ViewMTFWidget]}
    							renderNoAccess={() => null}
    						>
    							<Grid
    								item
    								container
    								xs={3}
    								alignContent="center"
    								alignItems="center"
    								justify="center"
    							>

    								<ProgressSaveButton
    									text="Save"
    									onClick={(e: any) => {
    										onSubmit(e);
    									}}
    									loading={loaderState}
    									disabled={loaderState}
    									buttonClassName={classes.saveButton}
    								></ProgressSaveButton>
    							</Grid>
    						</AccessManager>
    						<Grid
    							item
    							container
    							xs={3}
    							alignContent="center"
    							alignItems="center"
    							justify="center"
    						>
    							<Button
    								onClick={handleClose}
    								color="secondary"
    								variant="contained"
    								type="button"
    							>
                                    Cancel
    							</Button>
    						</Grid>
    					</Grid>
    				</DialogActions>
    			</Dialog>
    			<CustomSnackbar
    				variant={snackProps.variant === 'success' ? 'success' : 'error'}
    				message={snackProps.message}
    				open={snackProps.open}
    				onClose={handleSnackbarClose}
    			></CustomSnackbar>
    		</div>
    	</AccessManager>

    );
};

export default MtfWidgetSettingsPopup;
