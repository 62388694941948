export class Email {
	constructor() {
		this.emailId = "";
		this.emailTypeId = undefined;
		this.email = "";
		this.isPreferred = true;
	}
	emailId: string | undefined;
	emailTypeId: string | undefined;
	email: string | undefined;
	isPreferred: boolean | undefined;
}
