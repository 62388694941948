import React from "react";
import { createStyles,  Theme, makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CampusForCurrentUserAutoComplete from "../../../components/AutoComplete/CampusForCurrentUserAutoComplete";
import {  FormControlLabel,Switch,Grid } from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import * as loanDebtReportApi from "../../../api/reports/loanDebtReportApi";
import { ReportOutput } from "../../../enums/ReportOutput";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import ProgramAutoComplete from "../../../components/AutoComplete/ProgramAutoComplete";
import { ILoanDebtReport } from "../../../interfaces/reports/ILoanDebtReport";


const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      minHeight: "100%",
      height: "100%",
      maxWidth: "1050px",
      padding: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    paperRoot: {
      padding: theme.spacing(3, 2),
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
    },
    searchActions: {
      marginTop: theme.spacing(3),
      textAlign: "left",
    },
    startDateField: {
      width: "100%",
    },
    cardTitle: {
      fontSize: 17,
      color: theme.palette.primary.headerText,
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
    switch: {
      display: "flex",
     alignItems: "flex",
      padding: theme.spacing(0, 0.5),
      height: theme.spacing(8.6),
    },
  })
);

const StudentLoanDebt = (props: any) => {
  const { report } = props;
  let reportOverview = { report: report } as IReportOverview;
  const classes = useStyles({});
  const [preview, setPreview] = React.useState<any>();
  const [previewLoader, setPreviewLoader] = React.useState<boolean>();

  const data = useForm<any>({ mode: "onBlur" });
  const [model, setModel] = React.useState({
    campusId: [] ,
    startDate: undefined as Date | undefined,
    endDate: undefined as Date | undefined,
    exportType: 1 as ReportOutput,
    reportType: 1,
    revGradDateType: 1,
    programId: [],
  });

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    if(fieldId == "campusId")
    {
      (updatedModel as any)["programId"] = [];
    }
    setModel({ ...updatedModel });
  };

  const { handleSubmit } = data;

  const exportTypes: Array<DropDownListItem> = [
    { text: "PDF", id: "1" },
    { text: "Excel", id: "2" },
  ];

  const studentExportTypes: Array<DropDownListItem> = [
    { text: "Excel", id: "2" },
  ];

  const reportTypes: Array<DropDownListItem> = [
    { text: "Median Loan Debt", id: "1" },
    { text: "Student Loan Debt", id: "2" },
  ];

  const onTimeGraduationByTypes: Array<DropDownListItem> = [
    { text: "Program Weeks", id: "1" },
    { text: "Graduation Date from Enrollment", id: "2" },
  ];

  const onSubmit = async (isPreview: any) => {
    let params = (await getReportParameters()) as ILoanDebtReport;
    const validation = await data.triggerValidation();
    if (validation) {
      if (isPreview) {
        setPreviewLoader(true);
        loanDebtReportApi
          .generateReport(params, isPreview)
          .then((res: any) => {
            if (res) {
              res.arrayBuffer().then((buffer: any) => {
                setPreview(buffer);
                setPreviewLoader(false);
              });
            } else setPreviewLoader(false);
          });
      } else await loanDebtReportApi.generateReport(params);
    }
  };

  const exportTypeAutoComplete = {
    options: exportTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const studentExportTypeAutoComplete = {
    options: studentExportTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };


  const reportTypeAutoComplete = {
    options: reportTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const onTimeGraduationByTypeAutoComplete = {
    options: onTimeGraduationByTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const filterHandler = (data: any) => {
    if (data) {
      setModel(data);
    } else {
      setModel({
        campusId: [],
        startDate: undefined as Date | undefined,
        endDate: undefined as Date | undefined,
        exportType: 1 as ReportOutput,
        reportType: 1,
        revGradDateType: 1 ,
        programId: [],
      });
    }
  };
  
  const getReportParameters = async () => {
    const success = await data.triggerValidation();
    if (model && success) {
      let startDate = model.startDate ? model.startDate : undefined;
      let EndDate = model.endDate ? model.endDate : undefined;
      let params: ILoanDebtReport = {
        campusId: model.campusId,
        startDate: startDate,
        endDate: EndDate,
        exportType: model.exportType,
        reportType: model.reportType,
        programId: model.programId,
        revGradDateType : model.revGradDateType,
      };
      return params;
    }
    return {} as ILoanDebtReport;
  };

  const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
    const item = items.find((opt) => {
      if (opt.id == value)
      {
        return opt;
      } 
    });
    return item || ({} as DropDownListItem);
  };

  let parameters = (
    <div className={classes.root}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={2}>
          <Grid item md={6} sm={6} xs={12}>
              <Autocomplete
                {...reportTypeAutoComplete}
                autoComplete
                includeInputInList
                onChange={(e: any, value: any) => {
                  handleFieldOnChange(
                    "reportType",
                    value ? value : undefined,
                    (v: any) => v?.id
                  );
                }}
                disableClearable={true}
                value={getSelectedItem(
                  reportTypes,
                  model.reportType.toString()
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Report Type"
                    name="reportType"
                    error={!!data.errors.reportType}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.reportType
                        ? "Report Type is required."
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item  md={6} sm={6} xs={12} >
            </Grid>

<Grid item md={12} sm={12} xs={12} spacing={1}>

                      </Grid>

            <Grid item md={6} sm={6} xs={12}>
            <CampusForCurrentUserAutoComplete
                    multiple
                    name="campusId"
                    id="campusId"
                    label="Campus *"
                    params={{
                        fullWidth: true
                    }}
                    includeAllValue={true}
                    showIncludeAllValue={true}
                    filterHandle={(v: any) => handleFieldOnChange('campusId', v && Array.isArray(v) && v.length > 0 ? v.map(m => m.value) : undefined)}
                    valueFilter={
                        model.campusId
                            ? {
                                key: "value",
                                values: model.campusId,
                            }
                            :
                            undefined
                    }
                    chipSize="small"
                    error={!!data.errors.campusId}
                    helperText={data.errors?.campusId?.message ?? undefined}
                    inputRef={data.register({ validate: () => model.campusId && model.campusId.filter(m => m!= 'all').length > 0 ? true : 'Campus is required.' })}
                    maxTags={3}
                />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Autocomplete
                {...(model.reportType ==1 ) ? exportTypeAutoComplete : studentExportTypeAutoComplete}
                autoComplete
                includeInputInList
                disableClearable={true}
                onChange={(e: any, value: any) => {
                  handleFieldOnChange(
                    "exportType",
                    value ? value : undefined,
                    (v: any) => v?.id
                  );
                }}
                value={getSelectedItem(
                  ((model.reportType ==1 ) ? exportTypes : studentExportTypes),
                  model.exportType.toString()
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Export Type"
                    name="exportType"
                    error={!!data.errors.exportType}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.exportType
                        ? "Export Type is required."
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="startDate"
                  name="startDate"
                  label="Start Date *"
                  value={model.startDate ? model.startDate : null}
                  onChange={(e: any, value: any) =>
                    handleFieldOnChange("startDate", value ? value : undefined)
                  }
                  className={classes.startDateField}
                  KeyboardButtonProps={{
                    "aria-label": "report date",
                  }}
                  error={!!data.errors["startDate"]}
                  innerRef={(ref: any) => {
                    data.register("startDate", {
                      required: true,
                      validate: {
                        beforeEndDate: (value) =>
                         new Date(value) < new Date(data.getValues()["endDate"]) ||
                          "Start Date must be before end date",
                      },
                    });
                    data.setValue("startDate", model.startDate);
                  }}
                  helperText={
                    data.errors["startDate"]
                      ? (data.errors["startDate"] as FieldError).message
                      : undefined
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="endDate"
                  name="endDate"
                  label="End Date *"
                  value={model.endDate ? model.endDate : null}
                  onChange={(e: any, value: any) =>
                    handleFieldOnChange("endDate", value ? value : null)
                  }
                  className={classes.startDateField}
                  KeyboardButtonProps={{
                    "aria-label": "end date",
                  }}
                  error={!!data.errors["endDate"]}
                  innerRef={(ref: any) => {
                    data.register("endDate", {
                      required: true,
                      validate: {
                        beforeEndDate: (value) =>
                         new Date(value) > new Date(data.getValues()["startDate"]) ||
                          "End Date must be after Start date",
                      },
                    });
                    data.setValue("endDate", model.endDate);
                  }}
                  helperText={
                    data.errors["endDate"]
                      ? (data.errors["endDate"] as FieldError).message
                      : undefined
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
                <ProgramAutoComplete
                    multiple
                    name="programId"
                    id="programId"
                    label="Program"
                    params={{
                        fullWidth: true
                    }}
                    campusIds={model.campusId ?? []}
                    includeAllValue={true}
                    showIncludeAllValue={false}
                    filterHandle={(v: any) => handleFieldOnChange('programId', v && Array.isArray(v) && v.length > 0 ? v.map(m => m.value) : undefined, (vals:any[]) =>{
                        if(!!vals && vals.length > 0){
                            let lastValue = vals[vals.length - 1];

                            if(lastValue === 'All'){
                                return ['All'];
                            }else{
                                return vals.filter(a => a !== 'All');
                            }
                        }else{
                            return vals;
                        }
                    })}
                    valueFilter={
                        model.programId
                            ? {
                                key: "value",
                                values: model.programId,
                            }
                            :
                            undefined
                    }
                    chipSize="small"
                    error={!!data.errors.programId}
                    helperText={data.errors?.programId?.message ?? undefined}
                    inputRef={data.register()}
                    maxTags={3}
                />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Autocomplete
                {...onTimeGraduationByTypeAutoComplete}
                autoComplete
                includeInputInList
                disableClearable={true}
                onChange={(e: any, value: any) => {
                  handleFieldOnChange(
                    "revGradDateType",
                    value ? value : undefined,
                    (v: any) => v?.id
                  );
                }}
                value={getSelectedItem(
                  onTimeGraduationByTypes,
                  model.revGradDateType.toString()
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="On Time Graduation Date By"
                    name="revGradDateType"
                    error={!!data.errors.revGradDateType}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.revGradDateType
                        ? "On Time Graduation Date By is required."
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
                </Grid>
          </Grid>
        </form>
      </FormContext>
    </div>
  );
  reportOverview.parameters = parameters;

  return (
    <ReportOverview
      reportOverview={reportOverview}
      filterHandler={filterHandler}
      getReportParameters={getReportParameters}
      exportHandler={onSubmit}
      preview={preview}
      previewLoader={previewLoader}
    />
  );
};
export default StudentLoanDebt;
