import * as React from "react";
import { useEffect, useRef } from "react";
import { apiAutoCompleteConfig } from "../../constants/apiAutoComplete.config";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../constants/actions.constants";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& label.Mui-focused": {
        color: theme.palette.primary.white
      }
    }
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {}
  })
);

const config: ApiAutoCompleteConfigItem = {
  acId: "CRMStudentProgramsAutoComplete",
  cacheTime: 5,
  route: "/Admissions/SchoolPrograms/GetByCampus",
  requestType: "GET",
  body: null,
  hasUrlParams: true,
  useCache: false,
  storeInCache: false,
  isCampusSpecific: true,
  isUserSpecific: false,
  loadOnRender: true,
  defaultSelectedFirstItem: false,
  optionsMappedFx: (data: Array<any>) => {
    return data;
  }
};

const CRMStudentProgramsAutoComplete = (props: FilterParams) => {
  const classes = useStyles({});
  const inputRef: any = useRef(null);
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const handleOnChange = (e: any) => {
    if (props.filterHandle) {
      props.filterHandle(e);
    }
  };

  return (
    <div>
      <ApiAutoComplete
        config={config}
        loading={userSelectedCampus ? false : true}
        reqParams={{ campusId: userSelectedCampus }}
        classes={{
          option: "text-black"
        }}
        label={props.label ? props.label : "Program"}
        onChange={(e: any) => {
          handleOnChange(e);
        }}
        multiple={false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        inputRef={props.inputRef ? props.inputRef : inputRef}
        error={props.error}
        helperText={props.helperText}
        inputName={props.name}
      ></ApiAutoComplete>
    </div>
  );
};
export default CRMStudentProgramsAutoComplete;
