import * as React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import General from "./General";
import Demographics from "./Demographics";
import Admissions from "./Admissions";
import { useSelector } from "react-redux";
import { useForm, FormContext, FieldError } from "react-hook-form";
import * as studentApi from "../../../../api/search/studentSearchApi";
import {
  IStudentInfo,
  IStudentInfoGeneral,
  IStudentInfoDemographics,
  IStudentInfoAdmissions,
  NewIStudentInfoModel,
} from "../../../../interfaces/student/profile/studentInfo/IStudentInfo";
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";
import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";
import AccessManager from "../../../../components/security/AccessManager";
import { security } from "../../../../constants/Security/securityConstants";
const useStyles = makeStyles((theme: any) =>
  createStyles({
    button: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      whiteSpace: "nowrap",
    },
    paperRoot: {
      padding: theme.spacing(2),
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      backgroundColor: theme.palette.paper.background,
    },
    cardSeparator: {
      marginBottom: theme.spacing(1),
    },
    actionContainer: {
      marginTop: theme.spacing(3),
    },
  })
);

export type SnackBarStateProps = {
  showSnackBar: boolean;
  messageInfo: string | undefined;
  variant: "success" | "error" | "warning" | "info";
};

const StudentInfo = (props: any) => {
  const classes = useStyles({});
  const selectedStudent = useSelector((state: any) => state.student);
  const methods = useForm<any>({
    mode: "onBlur",
  });
  const { handleSubmit } = methods;
  const params = new URLSearchParams(window.location.search);
let isNewQueryVal = params.get("isNew");
let isNewValue =
		isNewQueryVal && (isNewQueryVal === "true" || isNewQueryVal === "True")
			? true
			: false;
  const [studentInfoModel, setStudentInfoModel] = React.useState<IStudentInfo>(
    NewIStudentInfoModel() as IStudentInfo
  );
  const [showOffEyeIcon, setshowOffEyeIcon] = React.useState<boolean>(true);
  const [snackBarProps, setSnackBarProps] = React.useState<SnackBarStateProps>({
    showSnackBar: false,
    messageInfo: undefined,
    variant: "info",
  });
  const [loaderState, setLoaderState] = React.useState<boolean>(false);

  const setGeneral = (data: IStudentInfoGeneral | null) => {
    setStudentInfoModel((state: any) => {
      return { ...state, ...data };
    });
  };

  const setDemographics = (data: IStudentInfoDemographics | null) => {
    setStudentInfoModel((state: any) => {
      return { ...state, ...data };
    });
  };

  const setAdmissions = (data: IStudentInfoAdmissions | null) => {
    setStudentInfoModel((state: any) => {
      return { ...state, ...data };
    });
  };

  React.useEffect(() => {
    if (selectedStudent && selectedStudent.studentId && !isNewValue) {
      studentApi.GetStudentsProfileInfo([selectedStudent.studentId], false).then(
        (response: any) => {
          if (!response || !response.result) {
            return;
          }
          let data = response.result[0];
          let enrollmentProgramData: IStudentInfo = {
            ...data,
          };
          setStudentInfoModel(enrollmentProgramData);
        },
        (exception: any) => {}
      );
    }
  }, [selectedStudent]);

  const onSubmit = (data: any) => {
    methods.triggerValidation().then((valid) => {
      if (valid && methods.errors.ssn === undefined) {
        if (studentInfoModel) {
          setshowOffEyeIcon(true);
          setLoaderState(true);
          studentApi.UpdateStudentsProfile(studentInfoModel).then(
            (response: any) => {
              if (response.resultStatus === 0) {
                setLoaderState(false);
                setSnackBarProps((props: any) => {
                  return {
                    variant: "success",
                    showSnackBar: true,
                    messageInfo: "Student successfully updated.",
                  };
                });
              } else {
                setLoaderState(false);
                setSnackBarProps((props: any) => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo:
                      "Student update could not be completed. Please try again.",
                  };
                });
              }
            },
            (exception: any) => {
              setLoaderState(false);
              setSnackBarProps((snackBarProps: any) => {
                return {
                  variant: "error",
                  showSnackBar: true,
                  messageInfo: exception,
                };
              });
            }
          );
        }
      }
      else if ( methods.errors.ssn !== undefined){
        methods.setError("ssn", "ssn", (methods.errors.ssn as FieldError).message)
      }
    });
  };

  return (
    <Paper className={classes.paperRoot} square={true}>
      <CustomSnackbar
        variant={snackBarProps.variant}
        message={snackBarProps.messageInfo}
        open={snackBarProps.showSnackBar}
        onClose={(event?: React.SyntheticEvent, reason?: string) => {
          setSnackBarProps((props: any) => {
            return { ...props, showSnackBar: false };
          });
        }}
      ></CustomSnackbar>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.cardSeparator}>
            <General
              isNewEnrollment={isNewValue}
              model={ studentInfoModel as IStudentInfoGeneral}
              setModel={setGeneral}
              showOffEyeIcon={showOffEyeIcon as boolean}
              setshowOffEyeIcon={setshowOffEyeIcon}
            />
          </div>
          <div className={classes.cardSeparator}>
            <Demographics
              isNewEnrollment={isNewValue}
              model={studentInfoModel as IStudentInfoDemographics}
              setModel={setDemographics}
              showSnackBar={setSnackBarProps}
            />
          </div>
          <div>
            <Admissions
              isNewEnrollment={isNewValue}
              model={studentInfoModel as IStudentInfoAdmissions}
              setModel={setAdmissions}
            />
          </div>
          <div className={classes.actionContainer}>
            <AccessManager
              allowedPermissions={[
                security.permissions.student.updateStudentInfo,
              ]}
              renderNoAccess={() => (
                <ProgressSaveButton
                  text="Save"
                  onClick={() => {
                    handleSubmit(onSubmit);
                  }}
                  loading={loaderState}
                  disabled
                ></ProgressSaveButton>
              )}
            >
              <ProgressSaveButton
                text="Save"
                onClick={() => {
                  handleSubmit(onSubmit);
                }}
                loading={loaderState}
              ></ProgressSaveButton>
            </AccessManager>
          </div>
        </form>
      </FormContext>
    </Paper>
  );
};

export default StudentInfo;
