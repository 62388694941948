import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  Button,
  CircularProgress,
} from "@material-ui/core";
import Map9010Table from "../../student/grades/Map9010Table";
import { StudentGrade } from "../../../interfaces/student/academics/StudentGrade";
import { useSelector } from "react-redux";
import { CustomSnackBarProps } from "../../../interfaces/common/CustomSnackBarProps";
import * as StudentGradesApi from "../../../api/student/academics/StudentGradesApi";
import { useForm, FormContext } from "react-hook-form";
import CustomSnackbar from "../../../components/notifications/CustomSnackbar";
import ProgressSaveButton from "../../../components/_Layout/Buttons/ProgressSaveButton";
import AccessManager from "../../../components/security/AccessManager";
import { security } from "../../../constants/Security/securityConstants";
import { PrintCampus9010Mappings } from "../../../api/reports/financialAids/ExportData9010API";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      padding: theme.spacing(3),
      overflowX: "hidden",
      overflowY: "auto",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      height: "100%",
    },
    details: {
      padding: theme.spacing(2, 0.5),
      flex: "1 1",
    },
    componentActions: {
      marginTop: theme.spacing(3),
    },
    gradesWrapper: {
      height: "90%",
      padding: "3px",
    },
    form: {
      height: "100%",
    },
    titleFont: {
      background: "	rgb(220,220,220)",
      padding: "15px",
      textAlign: "left",
      fontFamily: "sans-serif",
    },
  })
);

type IGradesProps = {
  setConfirmationModal: any;
};

const ReportsSetup = (props: IGradesProps) => {
  const userSelectedCampus: string = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const [details, setDetails] = React.useState([]);
  const handleDetailsChange = (newDetails: any) => {
    setDetails(newDetails);
  };
  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
        showSnackBar: false,
        messageInfo: undefined,
        variant: "info",
    }
);

  const handleSave = async () => {
    setLoaderState(true);
    try {
      const response = await StudentGradesApi.UpdateCampusAwardMappings9010(
        userSelectedCampus,
        details
      );
      setSnackBarProps((props: any) => {
        return {
            variant: "success",
            showSnackBar: true,
            messageInfo: "All data saved successfully!",
        };
    });
    } catch (error) {
      setSnackBarProps((props: any) => {
        return {
            variant: "success",
            showSnackBar: true,
            messageInfo: "Error saving data. Please try again!",
        };
    });
    }
    setLoaderState(false);
  };

  const printReport = async () => {
    setLoaderStatePrint(true);
    try {
      const response = await PrintCampus9010Mappings(userSelectedCampus);
    } catch (error) {
    }
    setLoaderStatePrint(false);
  };

  const classes = useStyles({});

  const [gradesData, setGradesData] = React.useState<StudentGrade[]>([]);
  const [loaderState, setLoaderState] = React.useState<boolean>(false);
  const [loaderStatePrint, setLoaderStatePrint] =
    React.useState<boolean>(false);

  const selectedEnrollment = useSelector(
    (state: any) => state.student.selectedEnrollment
  );
  const methods = useForm<any>({
    mode: "onBlur",
  });

  const transferGradesForGrid = (gradeDate: any) => {
    return gradeDate.map((grade: any) => {
      if (grade.score == "-1") {
        grade.score = "T";
      }
      return grade;
    });
  };

  return (
    <Paper className={classes.root}>
                  <CustomSnackbar
                variant={snackBarProps.variant}
                message={snackBarProps.messageInfo}
                open={snackBarProps.showSnackBar}
                onClose={(event?: React.SyntheticEvent, reason?: string) => {
                    setSnackBarProps((props: any) => {
                        return { ...props, showSnackBar: false };
                    });
                }}
            ></CustomSnackbar>
      <FormContext {...methods}>
        <form className={classes.form}>
          <div className={classes.gradesWrapper}>
            {/* <h3 className={classes.titleFont}>90/10 Mappings</h3> */}
            <Map9010Table
              setGradesData={setGradesData}
              gradesData={gradesData}
              setSnackBar={setSnackBarProps}
              setConfirmationModal={props.setConfirmationModal}
              transferGradesForGrid={transferGradesForGrid}
              onDetailsChange={handleDetailsChange}
            />
          </div>
          <div className={classes.componentActions}>
            <AccessManager
              allowedPermissions={[
                security.permissions.student.updateExamResults,
                security.permissions.student.deleteExamResults,
              ]}
              renderNoAccess={() => (
                <ProgressSaveButton
                  disabled={true}
                  text="Save"
                  onClick={handleSave}
                  loading={loaderState}
                ></ProgressSaveButton>
              )}
            >
              <Button
                onClick={handleSave}
                color="primary"
                variant="contained"
                disabled={loaderState}
              >
                {" "}
                {loaderState ? (
                  <CircularProgress size={24} style={{ color: "black" }} />
                ) : (
                  "SAVE"
                )}{" "}
              </Button>

              <Button
                onClick={printReport}
                color="secondary"
                variant="contained"
                disabled={loaderStatePrint}
              >
                {" "}
                {loaderStatePrint ? (
                  <CircularProgress size={24} style={{ color: "white" }} />
                ) : (
                  "PRINT"
                )}{" "}
              </Button>
            </AccessManager>
          </div>
        </form>
      </FormContext>
    </Paper>
  );
};

export default ReportsSetup;
