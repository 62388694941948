import * as React from 'react';
import {
    makeStyles,
    createStyles,
    Grid,
    withStyles,
    TableCell,
    TableRow,
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
    DialogActions,
    TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { EmptyGuid } from '../../../../utils/constants';
import DateFnsUtils from "@date-io/date-fns";
import { FieldError, FormContext, useForm } from "react-hook-form";
import TransactionReversalReasonAutoComplete from "../../../../components/AutoComplete/TransactionReversalReasonAutoComplete";
import { IReverseTransaction } from "../../../../interfaces/student/financials/ITransactionInfo";
import * as ledgerApi from "../../../../api/student/studentAccounts/ledgerApi";

import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const StyledTableCell = withStyles((theme: any) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.site.secondary,
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            textAlign: 'left',
            boxShadow: '1px 1px 0px 0px rgba(0,0,0,0.2)',
            '&:first-child': {
                width: '42px',
                padding: theme.spacing(0, 1)
            }
        },
        body: {
            fontSize: 16,
            color: theme.palette.black,
            fontWeight: 400,
            cursor: 'pointer',
            textAlign: 'left',
            '&:first-child': {
                width: '42px',
                padding: theme.spacing(0, 1)
            }
        },
    }),
)(TableCell);

const formatMoney = (number: number) => {
    var absValue = Math.abs(number);
    let numberString = absValue.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
    });
    return number < 0 ? "(" + numberString + ")" : numberString;
};

const StyledTableRow = withStyles((theme: any) =>
    createStyles({
        root: {
            '&:nth-of-type(even)': {
                backgroundColor: theme.palette.site.secondary,
            },
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.white,
            },
            '&:hover': {
                backgroundColor: theme.palette.site.secondary,
            }
        },
        body: {
            cursor: 'pointer'
        }
    }),
)(TableRow);

const unFormattedNumber = (formattedNumber: any) => {
    const cleanedInput = (formattedNumber + "").replace(/[^\d.-]/g, "");
    const result = Number(cleanedInput);
    return result;
};

const useStyles = makeStyles((theme: any) =>
    createStyles({
        cardTitle: {
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            backgroundColor: theme.palette.site.secondary,
            padding: theme.spacing(0)
        },
        headerText: {
            float: 'left',
            padding: theme.spacing(1.5),
        },
        cardContent: {
            padding: theme.spacing(1, 3),
            height: '100%',
        },
        saveButton: {
            width: 90,
        },
        tableContainer: {
            maxHeight: '400px'
        },
        table: {
            width: '100%',
        },
        iconColor: {
            color: theme.palette.black
        },
        searchField: {
            '&::placeholder': {
                color: theme.palette.black
            }
        },
        selectionCheckBox: {
            padding: theme.spacing(0)
        },
        preLoaderCell: {
            textAlign: 'center',
            padding: theme.spacing(1)
        },
        dialogCloseButton: {
            float: "right",
        },
        fullWidthField: {
            width: "100%",
        },
        buttonSection: {
            marginTop: theme.spacing(3),
        },
        textField: {
            marginRight: theme.spacing(1),
            width: "100%",
        },
    })
);

const ThemeTextField = withStyles((theme: any) => createStyles({}))(TextField);

type ReverseTransactionPopUpProps = {
    open?: boolean;
    isLoading: boolean;
    transactionId: string;
    setLoading: (loading: boolean) => void;
    handleClose: () => void;
    refreshLedgerItems: any;
    setSnackBar: any;
};

const getFormattedDate = (date: Date) => {
    var year = date.getFullYear();
  
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;
  
    var day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;
  
    return month + "/" + day + "/" + year;
  };

const ReverseTransactionPopUp = (
    props: Readonly<ReverseTransactionPopUpProps>
) => {
    const { open, handleClose, isLoading } = props;
    const classes = useStyles({});
    const data = useForm<any>({
        mode: "onBlur",
    });
    const { handleSubmit } = data;

    const [model, setModel] = React.useState<IReverseTransaction>({
        transactionId: EmptyGuid,
        transactionDate: undefined,
        transactionDescription: "",
        amount: undefined,
        reversalReasonId: undefined,
    });
    const handleFieldOnChange = (fieldId: string, value: any) => {
        let updatedModel = model;
        if(fieldId == "transactionDate")
        {
            (updatedModel as any)[fieldId] = getFormattedDate(value);
        }
        else
        {
            (updatedModel as any)[fieldId] = value;
        }
        setModel({ ...updatedModel });
    };

    const reverseTransaction = () => {
        data.triggerValidation().then((validation: any) => {
            if (validation) {
                ledgerApi.reverseTransaction(model).then(
                    (response: any) => {
                        if (response && response.data && response.data.result == true) {
                            props.setSnackBar((props: any) => {
                                return {
                                    variant: "success",
                                    showSnackBar: true,
                                    messageInfo: response.data.resultStatusMessage,
                                };
                            });

                        }
                        else {
                            props.setSnackBar((props: any) => {
                                return {
                                    variant: "error",
                                    showSnackBar: true,
                                    messageInfo: response?.data?.resultStatusMessage,
                                };
                            });
                        }
                        props.refreshLedgerItems();
                    },
                    (exception: any) => { }
                );
            }
        });
    };

    React.useEffect(() => {
        if (props.transactionId != "") {
            ledgerApi.getTransactionById(props.transactionId).then(
                (response: any) => {
                    if (response) {
                        setModel({...response.data?.result, transactionDate : getFormattedDate(new Date())});
                    }
                },
                (exception: any) => { }
            );
        }
    }, [props.transactionId])

    return (
        <React.Fragment>
            <FormContext {...data}>
                <form onSubmit={handleSubmit(reverseTransaction)}>
                    <Dialog
                        PaperProps={{ square: true }}
                        fullWidth={true}
                        maxWidth="sm"
                        open={open ?? false}
                        onClose={handleClose}
                        aria-labelledby="reverse-transaction-add-dialog-title"
                    >
                        <DialogTitle
                            className={classes.cardTitle}
                            disableTypography
                            id="reverse-transaction-dialog-title"
                        >
                            <Typography className={classes.cardTitle}>
                                <div className={classes.headerText}>Reverse Transaction</div>
                                <IconButton
                                    aria-label="close"
                                    onClick={handleClose}
                                    className={classes.dialogCloseButton}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            {open &&
                                <Grid container direction="column" spacing={2} wrap="nowrap">
                                    <Grid
                                        item
                                        alignContent="flex-start"
                                        alignItems="flex-start"
                                        justify="flex-start"
                                    >
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        id="transactionDate"
                                                        name="transactionDate"
                                                        label="Reversal date *"
                                                        disableToolbar
                                                        variant="inline"
                                                        format="MM/dd/yyyy"
                                                        TextFieldComponent={ThemeTextField}
                                                        className={classes.fullWidthField}
                                                        value={model.transactionDate}
                                                        autoOk={true}
                                                        onChange={(e: any) => {
                                                            handleFieldOnChange("transactionDate", e);
                                                        }}
                                                        KeyboardButtonProps={{
                                                            "aria-label": "change date",
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ThemeTextField
                                                    id="transactionDescription"
                                                    label="Transaction Description"
                                                    name="transactionDescription"
                                                    disabled={true}
                                                    className={classes.fullWidthField}
                                                    value={model.transactionDescription}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ThemeTextField
                                                    id="Amount"
                                                    label="Amount"
                                                    name="Amount"
                                                    disabled={true}
                                                    className={classes.fullWidthField}
                                                    value={formatMoney(model.amount ? model.amount : 0)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TransactionReversalReasonAutoComplete
                                                    id="reversalReasonId"
                                                    label="Reversal Reasons *"
                                                    name="reversalReasonId"
                                                    filterHandle={(v: any) => {
                                                        handleFieldOnChange("reversalReasonId", v ? v.value : null);
                                                    }}
                                                    valueFilter={
                                                        model.reversalReasonId
                                                            ? {
                                                                key: "value",
                                                                values: [model.reversalReasonId],
                                                            }
                                                            : undefined
                                                    }
                                                    error={!!data.errors.reversalReasonId}
                                                    inputRef={data.register({ required: true })}
                                                    helperText={
                                                        data.errors.reversalReasonId
                                                            ? "Reversal Reason Id is required."
                                                            : undefined
                                                    }
                                                    multiple={false}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        alignContent="flex-start"
                                        alignItems="flex-start"
                                        justify="flex-start"
                                    >

                                    </Grid>
                                    <Grid
                                        item
                                        alignContent='flex-start'
                                        alignItems='flex-start'
                                        justify='flex-start'
                                    >
                                        <Button
                                            size='small'
                                            color='primary'
                                            variant='contained'
                                            type='button' onClick={reverseTransaction}>Reverse Transaction</Button>
                                    </Grid>
                                    <Grid
                                        item
                                        alignContent='flex-start'
                                        alignItems='flex-start'
                                        justify='flex-start'
                                    >

                                    </Grid>
                                </Grid>
                            }
                        </DialogContent>
                    </Dialog>
                </form>
            </FormContext>
        </React.Fragment >
    );
};

export default ReverseTransactionPopUp;
