import { Reducer } from 'react';
import { reducers } from './';
import {
	connectRouter,
	RouterState
} from 'connected-react-router';
import { History } from 'history';
import accountReducer from '../store/account/accountReducer';
import userAdvanceSearchReducer from '../store/user/userAdvanceSearchReducer';
import reportsPageViewModeReducer from './ui/reportsPageViewModeReducer';
import sessionReducer from '../store/user/sessionReducer';
import userStateReducer from '../store/user/userStateReducer';
import studentProfileReducer from '../store/student/profile/studentProfileReducer';
import autoCompleteCacheReducer from './autocomplete/autoCompleteCacheReducer';
import dragReducer from './system/dragReducer';
import tabReducer from './setup/tabReducer';
import tagReducer from './tags/tagReducer';
import notificationReducer from './system/notificationReducer';
import settingsReducer from './system/settingsReducer';
import toasterReducer from './ui/toasterReducer';
import pdfPreviewReducer from './ui/pdfPreviewReducer';
import mruReducer from './system/mruReducer';
import adhocReports from './reports/adhocs';
import adhocReportDetails from './reports/adhocReportDetails';
import illinoisTranscriptSettings from './setup/reports/illinoisTranscriptSettings';
import stateWiseLicense from './setup/stateWiseLicense';
import { Action } from 'redux';
import illinoisTranscript from './reports/illinoisTranscript';
import transcriptReport from './reports/transcriptReport';
import {reducer as toastrReducer} from 'react-redux-toastr';

const rootReducer = (history: History) => ({
	...reducers,
	router: connectRouter(history) as Reducer<
		RouterState | undefined,
		Action<any>
	>,
	session: sessionReducer,
	student: studentProfileReducer,
	ac_cache: autoCompleteCacheReducer,
	tab: tabReducer,
	userstate: userStateReducer,
	drag: dragReducer,
	tag: tagReducer,
	notification: notificationReducer,
	toaster: toasterReducer,
	pdf_preview: pdfPreviewReducer,
	settings: settingsReducer,
	account: accountReducer,
	mru: mruReducer,
	user_advance_search: userAdvanceSearchReducer,
	reports_page_view_mode: reportsPageViewModeReducer,
	adhocReports,
	adhocReportDetails,
	illinoisTranscriptSettings,
	stateWiseLicense,
	illinoisTranscript,
	transcriptReport,
	toastr: toastrReducer
});

export default rootReducer;