import React from 'react';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Portal,
	Typography,
	Checkbox,
	Grid,
} from '@material-ui/core';
import { useForm, FormContext } from 'react-hook-form';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CustomSnackbar from '../../components/notifications/CustomSnackbar';
import { CustomSnackBarProps } from '../../interfaces/common/CustomSnackBarProps';
import * as ReportsApi from '../../api/reports/allReports/reportsApi';
import MaterialTable from 'material-table';
import TableIcons from '../../components/Tables/TableIcons';
import { IReportFileInfo } from '../../interfaces/storage/IReportFileInfo';
import moment from 'moment';
import ProgressSaveButton from '../../components/_Layout/Buttons/ProgressSaveButton';
import * as StorageApi from '../../api/storage/storageApi';

type ReportGenerationHistoryProps = {
    setOpen: any;
    open: boolean;
    campusId: string;
    userId: string;
};

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			'& .MuiPaper-rounded': {
				borderRadius: theme.spacing.borderradius,
			},
			'& .MuiDialogContent-root': {
				minHeight: 400,
			},
		},
		form: {
			display: 'flex',
			flexDirection: 'column',
			width: 'fit-content',
			minWidth: 100,
		},
		dialogHeader: {
			backgroundColor: theme.palette.site.secondary,
			'& .MuiTypography-root': {
				fontSize: theme.spacing(2),
			},
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
		},
		historyWrapper: {
			padding: theme.spacing(0.5),
		},
		updateWrapper: {
			padding: theme.spacing(0.5),
			height: '125px',
		},
		spacer: {
			height: theme.spacing(1),
		},
		settingDescription: {
			fontSize: 15,
			margin: theme.spacing(2),
			fontWeight: theme.typography.fontWeightLarge,
		},
		table: {
			width: '100%',
		},
		button: {
			padding: theme.spacing(1),
			margin: theme.spacing(2, 0),
		},
		buttonColorPrimary: {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.primary.contrastText,
			'&:hover': {
				backgroundColor: theme.palette.primary.main,
			},
			textDecoration: 'bold',
			padding: theme.spacing(1),
			whiteSpace: 'nowrap',
			margin: theme.spacing(2, 0),
		},
		statusAutoSelect: {
			width: '250px',
		},
	})
);


const ReportGenerationHistoryModal = (
	props: ReportGenerationHistoryProps
) => {
	const classes = useStyles({});
	const [reportFiles, setReportFiles] = React.useState<Array<IReportFileInfo>>([]);
	const [selectedReportFiles, setSelectedReportFiles] = React.useState<Array<IReportFileInfo>>([]);
	const theme = useTheme();
	const { campusId, userId,open } = props;
	const [loading, setLoading] = React.useState(false);

	const handleClose = () => {
		props.setOpen(false);
	};


	const isSelected = (data: IReportFileInfo) => {
		return selectedReportFiles.findIndex(rf => rf.dateKey === data.dateKey && rf.resourceId === data.resourceId) !== -1;
	};

	const data = useForm<any>({
		mode: 'onBlur',
	});

	const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
		{
			showSnackBar: false,
			messageInfo: undefined,
			variant: 'info',
		}
	);

	const downloadReports = () => {
		if (selectedReportFiles.length > 0) {
			setLoading(true);
			for (var i = 0; i < selectedReportFiles.length; i++) {
				let rfi = selectedReportFiles[i];
				StorageApi.getReportFile(rfi).then(() => {

					if (i === (selectedReportFiles.length)) {
						setLoading(false);
					}
				});
			}
		}
	};
	React.useEffect(() => {
		if (userId && campusId && !!open) {
			ReportsApi.getReportGenerationHistory(userId, campusId).then(
				(response: any) => {
					if (response) {
						let data = response.map((x: any) => {
							return {
								resourceId: x.resourceId, filePath: x.filePath,
								fileExtension: x.fileExtension,
								fileName: x.fileName,
								displayName: x.displayName,
								modifiedDate:  x.modifiedDate,
								modifiedDateString : moment(x.modifiedDate).format('MM/DD/YYYY'),
								campusId: x.campusId,
								dateKey: x.dateKey,
								fromDate: x.fromDate,
								toDate: x.toDate
							} as IReportFileInfo;
						});
						setReportFiles(data);

					}
				},
				(_exception: any) => { }
			);
		}

	}, [campusId, userId, open]);

	const handleReportSelection = (item: IReportFileInfo) => {

		let newReportFileState = Array.from(selectedReportFiles);
		let ind = newReportFileState.findIndex((rf: IReportFileInfo) => rf.dateKey === item.dateKey && rf.resourceId === item.resourceId);
		if (ind === -1) {
			newReportFileState.push(item);
		}
		else {
			newReportFileState.splice(ind, 1);
		}

		setSelectedReportFiles(newReportFileState);

	};

	const columns = [
		{
			field: 'select',
			Title: 'Select',
			searchable: false,
			render: (rowData: any) => <Checkbox
				checked={isSelected(rowData)}
				onChange={(_event: any) => handleReportSelection(rowData)}
				value="primary"
				color="primary"
				inputProps={{ 'aria-label': 'primary checkbox' }}
			/>
		},
		{ title: 'File Name', field: 'displayName', searchable: true },
		{ title: 'Run Date', field: 'modifiedDateString', searchable: true },
		{ title: 'Date Range', field: 'dateKey',  searchable: true },
	];

	return (
		<FormContext {...data}>
			<form className={classes.form}>
				<div>
					<Portal>
						<CustomSnackbar
							variant={snackBarProps.variant}
							message={snackBarProps.messageInfo}
							open={snackBarProps.showSnackBar}
							onClose={(_event?: React.SyntheticEvent, _reason?: string) => {
								setSnackBarProps((props: any) => {
									return { ...props, showSnackBar: false };
								});
							}}
						></CustomSnackbar>
					</Portal>
					<Dialog
						open={open}
						onClose={handleClose}
						className={classes.root}
						aria-labelledby="form-dialog-title"
						fullWidth={true}
						maxWidth="md"
						disableBackdropClick
					>
						<DialogTitle
							id="form-dialog-title"
							className={classes.dialogHeader}
						>
							{'Report Generation History'}
							<IconButton
								aria-label="close"
								className={classes.closeButton}
								onClick={handleClose}
							>
								<CloseIcon />
							</IconButton>
						</DialogTitle>
						<DialogContent>
							<Grid container justify="center">
								<Grid container item justify="space-between">
									<Grid item>
										<Typography className={classes.settingDescription}>
                                            History of reports generated.
										</Typography>
									</Grid>

									<Grid item >
										<ProgressSaveButton
											text={'Download Reports'}
											onClick={() => {
												downloadReports();
											}}
											disabled={selectedReportFiles.length === 0}
											loading={loading}
										></ProgressSaveButton>
									</Grid>
									<Grid item xs={12}>
										<MaterialTable
											columns={columns}
											icons={TableIcons}
											data={reportFiles}
											options={{
												defaultExpanded: true,
												selection: false,
												search: true,
												paging: false,
												grouping: false,
												showTitle: false,
												toolbar: true,
												maxBodyHeight: 500,
												sorting: true,
												headerStyle: {
													backgroundColor: theme.palette.secondary.main,
													zIndex: 10
												},
												rowStyle: rowData => ({
													fontFamily: 'Roboto',
													backgroundColor:
                                                        rowData.period === '7/1/2018-6/30/2019'
                                                        	? theme.palette.secondary.main
                                                        	: '#FFF'
												})
											}}
										/>
									</Grid>

								</Grid>


							</Grid>


						</DialogContent>
					</Dialog>
				</div>
			</form>
		</FormContext>
	);
};

export default ReportGenerationHistoryModal;
