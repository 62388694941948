import React from 'react';
import { IReportOverview } from '../../../../interfaces/reports/IReportOverview';
import { useSelector } from 'react-redux';
import { IIPedsReportParamsFallPartCALL } from '../../../../interfaces/reports/IPedsReportParamsFallPartCALL';
import { FormContext, useForm } from 'react-hook-form';
import { makeStyles, createStyles } from '@material-ui/styles';
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Switch, Typography } from '@material-ui/core';
import ReportOverview from '../../../../components/_Layout/Reports/ReportOverview';
import CampusForCurrentUserAutoComplete from '../../../../components/AutoComplete/CampusForCurrentUserAutoComplete';
import ProgramAutoComplete from '../../../../components/AutoComplete/ProgramAutoComplete';
import { Autocomplete } from '@material-ui/lab';
import InputField from '../../../../components/_Layout/Inputs/InputField';
import { getIPEDSFallALLENRPartC } from '../../../../api/reports/ipeds/IPEDSFall';

const getReportingYearOptions = (yearMod: number = 1, yearRange: number = 1): Array<string> => {
	let years: Array<string> = [];
	const currentYear = new Date().getFullYear();
	for (let i = yearMod; i > -9; i--) {
		const start = (currentYear + i - yearRange).toString();
		const end = (currentYear + i).toString();
		years.push(start + '-' + end);
	}
	return years;
};

const getDatesText = (reportingYear: string): string => {
	const [start, end] = reportingYear.split('-');
	return new Date(Number(start) - 1, 6, 1).toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' }) + ' thru ' + new Date(Number(end) - 1, 5, 30).toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
};


const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			paddingTop: theme.spacing(3),
			minHeight: '100%',
			height: '100%',
			padding: theme.spacing(2),
		},
		'& .makeStyles-content': {
			backgroundColor: theme.palette.background.paper,
		},
		paperRoot: {
			padding: theme.spacing(3, 2),
			width: '100%',
			minHeight: '100%',
			height: '100%',
			overflowX: 'hidden',
			overflowY: 'auto',
			flexDirection: 'column',
			backgroundColor: theme.palette.background.paper,
		},
		switch: {
			display: 'flex',
			alignItems: 'center',
			padding: theme.spacing(0, 0.5),
			height: theme.spacing(8.6),
		},
	})
);

const FallAllEnrollmentsPartC: React.FC<{ report: any }> = ({ report }) => {
	const classes = useStyles({});
	const [loading, setLoading] = React.useState<boolean>(false);
	const [previewData, setPreviewData] = React.useState<any>(undefined);
	const reportOverview = { report: report } as IReportOverview;
	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);
	const data = useForm<any>({ mode: 'onBlur' });
	const [model, setModel] = React.useState<IIPedsReportParamsFallPartCALL>({
		campusId: userSelectedCampus,
		reportingYear: getReportingYearOptions()[0],
		orderBy: 'StudentNumber',
	});

	const handleFieldOnChange = (
		fieldId: keyof IIPedsReportParamsFallPartCALL,
		value: any,
		mapFunction?: Function
	) => {
		let updatedModel = model;
		let newValue = mapFunction ? mapFunction(value) : value;
		(updatedModel as any)[fieldId] = newValue;
		setModel({ ...updatedModel });
	};

	const handleCampusChange = (value: any) => {
		let updatedModel = model;
		updatedModel.campusId = value;
		setModel({ ...updatedModel, progId: undefined });
	};

	const { handleSubmit } = data;

	const filterHandler = (data: any) => {
		if (data) {
			setModel(data);
		} else {
			setModel({
				campusId: userSelectedCampus,
				reportingYear: getReportingYearOptions()[0],
				orderBy: 'StudentNumber',
			});
		}
	};

	const onSubmit = async (isPreview: boolean) => {
		let params = (await getReportParameters()) as IIPedsReportParamsFallPartCALL;
		const validation = await data.triggerValidation();
		if (validation) {
			if (isPreview) {
				setLoading(true);
			}
			const reportData = await getIPEDSFallALLENRPartC(params, isPreview);

			if (isPreview) {
				if(reportData)
				{
					setPreviewData('data:application/pdf;base64,' + reportData);
				}
				setLoading(false);
			}
		}
	};

	const getReportParameters = async () => {
		const success = await data.triggerValidation();
		if (model && success) {
			let params: IIPedsReportParamsFallPartCALL = {
				campusId: model.campusId,
				progId: model.progId,
				orderBy: model.orderBy,
				showInactive: model.showInactive,
				reportingYear: model.reportingYear,
			};
			return params;
		}
		return {} as IIPedsReportParamsFallPartCALL;
	};

	data.register({ name: 'campusId' }, {
		validate: () => {
			return model.campusId ? true : 'Campus is required.';
		}
	});

	data.register({ name: 'reportingYear' }, {
		validate: () => {
			return model.reportingYear ? true : 'Reporting Year is required.';
		}
	});

	data.register({ name: 'orderBy' }, {
		validate: () => {
			return model.orderBy ? true : 'Sort By is required.';
		}
	});

	const parameters = (
		<div className={classes.root}>
			<FormContext {...data}>
				<form onSubmit={handleSubmit(() => { })}>
					<Grid container spacing={1}>
						<Grid item md={4} sm={4} xs={12}>
							<CampusForCurrentUserAutoComplete
								name='campusId'
								valueFilter={
									model.campusId
										? {
											key: 'value',
											values: [model.campusId],
										}
										: undefined
								}
								filterHandle={(v: any) => handleCampusChange((v && v.value) || undefined)}
								error={!!data.errors.campusId} helperText={
									data.errors.campusId && data.errors.campusId.message
								}
								inputRef={data.register({ required: 'Campus is required.' })} />
						</Grid>
					</Grid>
					<Grid container spacing={1}>
						<Grid item md={8} sm={8} xs={12}>
							<ProgramAutoComplete
								chipSize="small"
								maxTags={1}
								multiple
								name='progId'
								valueFilter={
									model.progId
										? {
											key: 'value',
											values: model.progId.split(','),
										}
										: undefined
								}
								filterHandle={(v: any) => handleFieldOnChange('progId', v || [], (v: any[]) => v.reduce((a, b) => ((a && (a + ',')) || '') + b.value, ''))}
								activeOnly={model.showInactive ? false : true}
								label="Programs"
								campusIds={model.campusId ? [model.campusId] : []}
							/>
						</Grid>
						<Grid item md={3} sm={3} xs={12} className={classes.switch}>
							<FormControlLabel
								label="Show InActive Programs"
								control={
									<Switch
										checked={model.showInactive}
										value="inActiveStudentGroup"
										onChange={() => setModel({ ...model, showInactive: !(model.showInactive || false) })}
										color="primary"
									/>
								}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Typography variant="h6">
								Report Date: {getDatesText(model.reportingYear || '')}
							</Typography>
						</Grid>
						<Grid item xs={3} md={3}>
							<Autocomplete
								renderInput={(inputProps) => {
									return <InputField name="reportingYear" {...inputProps} label="Reporting Year"
										error={!!data.errors.reportingYear} helperText={
											data.errors.reportingYear && data.errors.reportingYear.message
										}
									/>;
								}}
								disableClearable
								options={getReportingYearOptions()}
								getOptionLabel={(option) => option}
								value={model.reportingYear || null}
								onChange={(_: any, v: string | null) => {
									handleFieldOnChange('reportingYear', v);
								}}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<FormControl component="fieldset">
								<FormLabel component="legend">Sort By</FormLabel>
								<RadioGroup name='orderBy' row value={model.orderBy}
									onChange={(_, v) => {
										handleFieldOnChange('orderBy', v);
									}
									}
								>
									<FormControlLabel
										value='StudentNumber'
										control={<Radio color="primary" />}
										label='Student Number'
										labelPlacement="end"

									/>
									<FormControlLabel
										value='LastName'
										control={<Radio color="primary" />}
										label='Last Name'
										labelPlacement="end"
									/>
								</RadioGroup>
							</FormControl>
						</Grid>
					</Grid>
				</form>
			</FormContext>
		</div>
	);

	reportOverview.parameters = parameters;

	return (
		<ReportOverview
			reportOverview={reportOverview}
			filterHandler={filterHandler}
			getReportParameters={getReportParameters}
			exportHandler={onSubmit}
			previewLoader={loading}
			preview={previewData}
			isUrlPreview
		/>
	);
};

export default FallAllEnrollmentsPartC;