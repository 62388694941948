import API from "../../../apiWrapper";
import { OtherEmail } from "../../../../interfaces/student/profile/contactInfo/OtherEmail";

export const getAll = (leadId: string | undefined, otherContactId: string | undefined) => {
  return API()
    .get("/leads/" + leadId + "/otherEmail/GetAll",{
      params: {
        otherContactId
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};

export const getEmail = (leadId: string | undefined, id: string) => {
  return API()
    .get("/leads/" + leadId + "/otherEmail/"+ id)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};

export const deleteEmail = (leadId: string | undefined, id: string) => {
  return API()
    .delete("/leads/" + leadId + "/otherEmail/Delete/"+ id)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};

export const postEmail = (leadId: string | undefined, emailObj: OtherEmail) => {
  return API()
    .post("/leads/" + leadId + "/otherEmail/Post", {...emailObj, leadId})
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const getBestEmail = (studentId: string | undefined) => {
  return API()
    .get("/leads/" + studentId + "/otherEmail/GetBest")
    .then(
      (res: any) => {
        if (res.data) {
          return res.data as OtherEmail;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};

export const updateEmail = (leadId: string | undefined, emailObj: OtherEmail) => {
  return API()
    .put("/leads/" + leadId + "/otherEmail/Put", {...emailObj, leadId})
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};
