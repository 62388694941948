import * as React from "react";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import * as PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
  TableCell,
} from "@material-ui/core";
import ApiVirtualizedTable from "../../../components/_Layout/ApiTables/ApiVirtualizedTable";
import { apiVirtualizedTableConfig } from "../../../constants/apiVirtualizedTable.config";
import Link from "@material-ui/core/Link";
import CustomSnackbar from "../../../components/notifications/CustomSnackbar";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme: any) => ({
  root: {},
  cardTitle: {
    fontWeight: "bold",
  },
  content: {
    padding: 0,
    height: "100%",
  },
  inner: {
    height: "100%",
    width: "100%",
    minWidth: 700,
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end",
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  bold: {
    fontWeight: "bold",
  },
  typography: {
    padding: theme.spacing(2),
  },
  tableCell: {
    flex: 1,
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
  },
}));

const AdvanceSearchResults = (props: any) => {
  const { className, params, advanceSearchModel, ...rest } = props;
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );
  const classes = useStyles({});
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const config = { ...apiVirtualizedTableConfig.AdvanceSearchPaged };
  config.body = advanceSearchModel as any;

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography
        color="textSecondary"
        gutterBottom
        variant="body2"
      ></Typography>
      <Card>
        <CardHeader title="Search Results" className={classes.cardTitle} />
        <CardContent className={classes.content}>
          <div className={classes.inner} style={{ height: 500 }}>
            <CustomSnackbar
              variant={'info'}
              message={undefined}
              open={showSnackBar}
              onClose={(event?: React.SyntheticEvent, reason?: string) => {
                setShowSnackBar(false);
              }}
            ></CustomSnackbar>
            <ApiVirtualizedTable
              //ref={advanceSearchResult.current}
              config={config}
              cellDataGetter={({ rowData, dataKey }) => {
                if (rowData) return rowData[dataKey];
              }}
              columns={[
                {
                  width: 130,
                  label: "ID",
                  dataKey: "studentNumber",
                  cellRenderer: ({ cellData, rowData, columnIndex }) => {
                    return (
                      <TableCell
                        className={clsx(
                          classes.tableCell,
                          classes.flexContainer
                        )}
                        component="div"
                        variant="body"
                      >
                        <Link
                          component="button"
                          //variant="body2"
                          className={classes.bold}
                          onClick={() => {
                            console.log(rowData.studentId);
                            let urlParams = new URLSearchParams(
                              props.history.location.search
                            );
                            let studentIdQueryId = "studentId";
                            let campusIdQueryId = "campusId";

                            //set url parameters for campusId and studentId
                            urlParams.set(
                              studentIdQueryId,
                              rowData.studentId as any
                            );
                            urlParams.set(campusIdQueryId, userSelectedCampus);
                            props.history.push({
                              pathname: "/student/1/profile/info",
                              search: urlParams.toString(),
                            });
                          }}
                        >
                          {cellData}
                        </Link>
                      </TableCell>
                    );
                  },
                },
                {
                  width: 130,
                  label: "Last Name",
                  dataKey: "lastName",
                },
                {
                  width: 80,
                  label: "First Name",
                  dataKey: "firstName",
                },
                {
                  width: 200,
                  label: "Program Version",
                  dataKey: "currentProgramVersion",
                },
                {
                  width: 60,
                  label: "Status",
                  dataKey: "currentStatus",
                },
              ]}
            />
          </div>
        </CardContent>
        <CardActions className={classes.actions}></CardActions>
      </Card>
    </div>
  );
};

AdvanceSearchResults.propTypes = {
  className: PropTypes.string,
  params: PropTypes.object,
};

export default withRouter(AdvanceSearchResults);
