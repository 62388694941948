import API from "../../apiWrapper";
import { ISuspension } from "../../../interfaces/student/status/ISuspension";
import { IStatusChangeOption } from "../../../interfaces/student/status/IStatusChangeOption";		

export const getSuspensionsByEnrollmentId = (
    studentEnrollmentId: string
  ) => {

      return API()
          .get("/AcademicRecords/StatusChanges/Suspension/GetSuspensionsByEnrollment", {
              params: {
                studentEnrollmentId
              }
          })
          .then(
              (res: any) => {
                  if (res && res.data) {
                    return res.data as  Array<ISuspension>;
                   }
              },
              (error: any) => {
                  return error;
              }
          );          
  };

  export const updateSuspensionHistory = (
    suspension: ISuspension
  ) => {

      return API()
          .post("/AcademicRecords/StatusChanges/Suspension/UpdateSuspensionHistory", suspension)
          .then(
            (res: any) => {
                if (res && res.data) {
                    return res;
                }
                },
                    (error: any) => {
                    return error;
                }
            );;          
  };

  
  export const createSuspension = (
    suspension: ISuspension
  ) => {

      return API()
          .post("/AcademicRecords/StatusChanges/Suspension/CreateSuspension", suspension)
          .then(
            (res: any) => {
                if (res && res.data) {
                    return res;
                }
                },
                    (error: any) => {
                    return error;
                }
            );;          
  };

  export const getStatusChangeOptions = (
    studentEnrollmentId: string
  ) => {

      return API()
          .get("/AcademicRecords/StatusChanges/StatusChange/GetStatusChangeOptions", {
              params: {
                studentEnrollmentId
              }
          })
          .then(
              (res: any) => {
                  if (res && res.data) {
                    return res.data as Array<IStatusChangeOption>;
                   }
              },
              (error: any) => {
                  return error;
              }
          );          
  };



