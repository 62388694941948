import API from "../../../apiWrapper";
import { OtherAddress } from "../../../../interfaces/student/profile/contactInfo/OtherAddress";
import { EmptyGuid } from "../../../../utils/constants";

export const getAll = (leadId: string | undefined, otherContactId: string | undefined) => {
  return API()
    .get("/leads/" + leadId + "/otherAddress/GetAll",{
      params: {
        otherContactId
      },
    })
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};

export const getAddress = (leadId: string | undefined, id: string) => {
  return API()
    .get("/leads/" + leadId + "/otherAddress/"+ id)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};

export const deleteAddress = (leadId: string | undefined, id: string) => {
  return API()
    .delete("/leads/" + leadId + "/otherAddress/Delete/"+ id)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};

export const postAddress = (leadId: string | undefined, addressObj: OtherAddress) => {
  return API()
    .post("/leads/" + leadId + "/otherAddress/Post", {...addressObj, leadId})
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const getCountryByName = (campusId: string, countryName: string) => {
  return API()
    .get("/SystemCatalog/Countries/GetByCampus", {
      params: {
        campusId,
        countryName,
      },
    })
    .then(
      (res: any) => {        
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};

export const getBestAddress = (studentId: string | undefined) => {
  return API()
    .get("/leads/" + studentId + "/otherAddress/GetBest")
    .then(
      (res: any) => {
        if (res.data) {
          return res.data as OtherAddress;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};
export const updateAddress = async (
  leadId: string | undefined,
  adressObj: OtherAddress
) => {
  return API()
    .put("/leads/" + leadId + "/otherAddress/Put", { ...adressObj, leadId, IsShowOnLeadPage: (adressObj as any).isShowOnLeadPage })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};

export const createAddress = (
  leadId: string | undefined,
  adressObj: OtherAddress
) => {
  return API()
    .post("/leads/" + leadId + "/otherAddress/Post", { ...adressObj, leadId, isMailingAddress: true, IsShowOnLeadPage: true })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};
