import React from "react";
import { Route,useLocation } from "react-router-dom";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Card, CardHeader, CardContent, Typography, Button, Grid } from "@material-ui/core";
import * as ReportsApi from "../../api/reports/allReports/reportsApi";
import * as StorageApi from "../../api/storage/storageApi";

import moment from "moment";
import { IReportFileInfo } from "../../interfaces/storage/IReportFileInfo"
import ProgressSaveButton from "../../components/_Layout/Buttons/ProgressSaveButton";

const useStyles = makeStyles((theme: any) => ({
    root: {
        margin: theme.spacing(3),
        height: '50%',
        overflowY: "auto",
    },
}));

const ReportDownloader = (props: any) => {
    const classes = useStyles({})
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const userId = params.get("UserId");
    const campusId = params.get("CampusId");
    const fromDate = params.get("FromDate");
    const toDate = params.get("ToDate");

    const [loading, setLoading] = React.useState(false);
    const [reportFileInfo, setReportFileInfo] = React.useState<Array<IReportFileInfo>>([]);

    React.useEffect(() => {
        if (userId && campusId && fromDate && toDate) {
            let from = moment(fromDate).toDate().toDateString();
            let to = moment(toDate).toDate().toDateString()
            ReportsApi.getBlobUris(userId, campusId, from, to).then(
                (response: any) => {
                    if (response) {
                        let data = response.map((x: any) => {
                            return {
                                resourceId: x.resourceId, filePath: x.filePath,
                                fileExtension: x.fileExtension,
                                fileName: x.fileName,
                                displayName: x.displayName,
                                modifiedDate: x.modifiedDate,
                                campusId: x.campusId,
                            } as IReportFileInfo
                        })
                        setReportFileInfo(data);
                    }
                },
                (exception: any) => { }
            );
        }

    }, [])

    const downloadReports = () => {
        if (reportFileInfo.length > 0) {
            setLoading(true);
            for (var i = 0; i < reportFileInfo.length; i++) {
                let rfi = reportFileInfo[i];
                StorageApi.getReportFile(rfi).then(() => {

                    if (i === (reportFileInfo.length)) {
                        setLoading(false);
                    }
                });
            }
        }
    }

    return (

        <Card className={classes.root} >
            <CardHeader
                title="Report Downloader"
            />
            <CardContent >
                <Grid container justify="center">
                    <Grid item >
                        <ProgressSaveButton
                            text={reportFileInfo.length == 0 ? "Getting Reports Ready..." : "Download Reports"}
                            onClick={() => {
                                downloadReports();
                            }}
                            disabled={reportFileInfo.length == 0}
                            loading={loading}
                        ></ProgressSaveButton>
                    </Grid>
                </Grid>
            </CardContent>

        </Card>

    );
};

export default ReportDownloader;
