import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
	Typography,
	Card,
	CardContent,
} from "@material-ui/core";
import DisplayField from "../DisplayField/DisplayField";
import * as quickInfoApi from "../../../api/student/quickInfo/quickInfoApi";
import { ICostAndBalancesCard } from "../../../interfaces/student/quickInfo/ICostAndBalancesCard";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		card: {
			margin: theme.spacing(1),
			marginTop: theme.spacing(2),
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingBottom: theme.spacing(1),
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2)
			}
		},
		cardTitle: {
			fontSize: 14,
			marginLeft: theme.spacing(1),
			marginTop: theme.spacing(1),
			color: theme.palette.text.secondary,
			fontWeight: theme.typography.fontWeightMedium
		}
	})
);

const CostsAndBalancesCard = (props: any) => {
	const { className, ...rest } = props;

	const classes = useStyles({});
	const [
		costAndBalancesData,
		setCostAndBalancesData
	] = useState<ICostAndBalancesCard | null>(null);

	const selectedEnrollment = useSelector(
		(state: any) => state.student.selectedEnrollment
	);

	const GetCostAndBalnacesInformation = () => {
		if (selectedEnrollment && selectedEnrollment.stuEnrollId)
			quickInfoApi
				.GetTransactionsQuickInformation(selectedEnrollment.stuEnrollId)
				.then(response => {
					if (response) {
						setCostAndBalancesData(response.data.result);
					}
				});
	};
	useEffect(() => {
		GetCostAndBalnacesInformation();
	}, [selectedEnrollment]);

	return (
		<Card className={classes.card} elevation={2}>
			<Typography className={clsx(classes.cardTitle)}><Link to={"/student/1/financials/ledger"}>COSTS & BALANCES</Link></Typography>
			<CardContent>
				<DisplayField
					labelText="Tuition"
					valueText={costAndBalancesData?.tuitionAmountAsString}
					fieldType="text"
				></DisplayField>

				<DisplayField
					labelText="Registration"
					valueText={costAndBalancesData?.registrationAmountAsString}
					fieldType="text"
				></DisplayField>

				<DisplayField
					labelText="Other Fees"
					valueText={costAndBalancesData?.otherFeesAmountAsString}
					fieldType="text"
				></DisplayField>

				<DisplayField
					labelText="Books & Supplies"
					valueText={costAndBalancesData?.booksAndSupliesAmountAsString}
					fieldType="text"
				></DisplayField>

				<DisplayField
					labelText="Room & Board"
					valueText={costAndBalancesData?.roomAndBoardAmountAsString}
					fieldType="text"
				></DisplayField>

				<DisplayField
					labelText="Total"
					valueText={costAndBalancesData?.totalAmountAsString}
					fieldType="text"
				></DisplayField>

				<DisplayField
					labelText="Payments & Disbursements"
					valueText={costAndBalancesData?.paymentAndDisbursementAmountAsString}
					fieldType="text"
				></DisplayField>

				<DisplayField
					labelText="Ledger Balance"
					valueText={costAndBalancesData?.balanceAmountAsString}
					fieldType="text"
				></DisplayField>
			</CardContent>
		</Card>
	);
};

export default CostsAndBalancesCard;
