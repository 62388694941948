import { ApiAutoCompleteConfigItem } from '../../../interfaces/ApiAutoCompleteConfigItem';
import ICourseGradeBook from '../../../interfaces/setup/academics/ProgramDefinition/ICourseGradeBook';
import { format, addDays } from "date-fns";


const config: ApiAutoCompleteConfigItem = {
	acId: 'programVersioncourses',
	cacheTime: 5,
	route: '/AcademicRecords/Courses/GetCourseGradeBooks',
	requestType: 'GET',
	body: null,
	hasUrlParams: true,
	useCache: false,
	storeInCache: false,
	isCampusSpecific: true,
	isUserSpecific: false,
	loadOnRender: true,
	defaultSelectedFirstItem: false,
	optionsMappedFx: (data: Array<ICourseGradeBook>) => {
		return data.map((item) => ({ 
			value: item.gradeBookId,
			text: `${item.gradeBookTitle}` + 
			` [${format(Date.parse(item.effectiveStartDate!.toString()), 'MM/dd/yyyy')}]`,
		}));
	},
};

export default config;