import React from "react";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  Grid,
  Card,
  Typography,
  CardContent,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  TextField,
} from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import { useSelector } from "react-redux";
import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";
import { CustomSnackBarProps } from "../../../../interfaces/common/CustomSnackBarProps";
import { IEnrollmentSearchResult } from "../../../../interfaces/student/enrollment/EnrollmentSearchResult";
import SearchBarFilter from "../../../../components/_Layout/Search/SearchBarFilter";
import StudentBanner from "../classRegistration/StudentBanner";
import * as classRegApi from "../../../../api/setup/academics/classRegistrationApi";
import SortIcon from "@material-ui/icons/Sort";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { DropDownListItem } from "../../../../interfaces/DropDownListItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import GradeSystemDetailAutoComplete from "../../../../components/AutoComplete/GradeSystemDetailAutoComplete";
import {
  ITermSearchParam,
  ITermResult,
  ITermClassResult,
  ICourseDrop,
} from "../../../../interfaces/setup/creditHour/ITermSearchModels";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { security } from "../../../../constants/Security/securityConstants";
import { checkPermissions } from "../../../../utils/securityFunctions";
import { Tooltip } from "@material-ui/core";

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      minHeight: "100%",
      height: "100%",
      width: "100%",
      margin: "auto",
    },
    paperRoot: {
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    startDateField: {
      width: "100%",
    },
    fullWidthElement: {
      width: "100%",
    },
    cardTitle: {
      fontSize: 17,
      color: "Black",
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardSeparator: {
      marginBottom: theme.spacing(1),
    },
    copyButton: {
      marginTop: theme.spacing(3),
      width: 240,
    },
    cardWrapper: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    searchBarContainer: {
      backgroundColor: theme.palette.site.secondary,
      borderRadius: 20,
      paddingLeft: "10px !important",
      fontSize: 13,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.black,
      paddingTop: "2.5px !important",
      paddingBottom: "2.5px !important",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    accordion: {
      position: "inherit",
    },
    sortDesc: {
      transform: "rotate(180deg)",
    },
    dialogHeader: {
      backgroundColor: theme.palette.site.secondary,
      "& .MuiTypography-root": {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: "12pt",
      },
    },
    buttonSection: {
      marginBottom: theme.spacing(2),
    },
    saveButton: {
      width: "auto",
      marginRight: theme.spacing(2),
    },
    typography: {
      whiteSpace: "pre-line",
    },
    accordionSummary: {
      backgroundColor: theme.palette.site.secondary,
    },
    right: {
      float: "right",
    },
  })
);

interface IDropModel {
  instrClassTermId: string;
  dropType: string;
  grdSystemDetailId: string;
  dropReason: string;
}

function ClassDrop() {
  const classes = useStyles();
  const data = useForm<any>({ mode: "onBlur" });
  const { handleSubmit } = data;
  const [updating, setUpdating] = React.useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [selectedClass, setSelectedClass] =
    React.useState<ITermClassResult | null>(null);

  const defModel: IDropModel = {
    instrClassTermId: "",
    dropType: "",
    grdSystemDetailId: "",
    dropReason: "",
  };

  let hasReverseClassDropPermission = checkPermissions([
    security.permissions.creditHour.reverseClassDrop,
  ]);

  const [dropModel, setDropModel] = React.useState<IDropModel>(defModel);

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );

  interface IClassRegistrationByStudent {
    enrollments: IEnrollmentSearchResult[];
    studentId: string;
    studentEnrollmentId: string;
    studentName: string;
  }

  const initialState: IClassRegistrationByStudent = {
    enrollments: [] as IEnrollmentSearchResult[],
    studentId: "",
    studentEnrollmentId: "",
    studentName: "Search Student",
  };

  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const defaultFilter: ITermSearchParam = {
    campusId: null,
    stuEnrollId: null,
    sortOrder: "ASC",
    pageNo: 1,
    pageSize: 100,
  };

  const [filter, setFilter] = React.useState<ITermSearchParam>(defaultFilter);

  const [model, setModel] =
    React.useState<IClassRegistrationByStudent>(initialState);

  const [reload, setReload] = React.useState<boolean>(false);

  const [isDrop, setIsDrop] = React.useState<boolean>(true);

  const [terms, setTerms] = React.useState<Array<ITermResult>>(
    new Array<ITermResult>()
  );
  const [termsCopy, setTermsCopy] = React.useState<Array<ITermResult>>(
    new Array<ITermResult>()
  );

  React.useEffect(() => {
    loadTerms();
  }, [filter]);

  const loadTerms = () => {
    filter.campusId = userSelectedCampus;
    classRegApi.GetStudentClasses(filter).then(
      (response: any) => {
        if (response && response.data) {
          let JsonResult: ITermResult[] = JSON.parse(response.data.jsonResult);
          setTerms(JsonResult ?? []);
          setTermsCopy(JsonResult ?? []);
        }
      },
      (exception: any) => {
        setSnackBarProps(() => {
          return {
            variant: "error",
            showSnackBar: true,
            messageInfo: exception,
          };
        });
      }
    );
  };

  const handleFieldOnChange = (fieldId: string, value: any) => {
    let updatedModel = model;
    if (fieldId == "fromCampusId") {
      (updatedModel as any)["programId"] = "";
      (updatedModel as any)["programVerId"] = "";
    }
    (updatedModel as any)[fieldId] = value;
    setModel({ ...updatedModel });
  };

  interface IModel {
    studentEnrollmentId?: string;
    studentId?: string;
    studentName?: string;
  }

  const setStudent = (values: IModel) => {
    handleFieldOnChange("studentId", values.studentId);
    handleFieldOnChange("studentEnrollmentId", values.studentEnrollmentId);
    handleFieldOnChange("studentName", values.studentName);
    setFilter({
      ...filter,
      stuEnrollId: values.studentEnrollmentId,
    });
  };

  const onSubmit = (theClass: ITermClassResult) => {
    setIsDrop(true);
    setDropModel(defModel);
    setSelectedClass(theClass);
    setDialogOpen(true);
  };

  const onReverseDrop = (theClass: ITermClassResult) => {
    setIsDrop(false);
    setDropModel(defModel);
    setSelectedClass(theClass);
    setDialogOpen(true);
  };
  const dropTypes: Array<DropDownListItem> = [
    { text: "Drop", id: "Drop" },
    { text: "Withdrawal", id: "Withdrawal" },
  ];

  const dropTypeAutoComplete = {
    options: dropTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const handleConfirm = (d: any) => {
    if (selectedClass != null) {
      if (isDrop == true) {
        data.triggerValidation().then((validation: any) => {
          if (validation) {
            let params: ICourseDrop = {
              instrClassTermId: selectedClass.InstrClassTermId,
              studentScheduleId: selectedClass.StudentScheduleId,
              dropType: dropModel.dropType,
              grdSystemDetailId: dropModel.grdSystemDetailId,
              dropReason: dropModel.dropReason,
              stuEnrollId: model.studentEnrollmentId,
            };
            classRegApi.DropClass(params).then(
              (response: any) => {
                if (response && response.data == true) {
                  setSnackBarProps(() => {
                    return {
                      variant: "success",
                      showSnackBar: true,
                      messageInfo: "Course dropped successfully.",
                    };
                  });
                  setDialogOpen(false);
                  loadTerms();
                  setReload(true);
                } else {
                  setSnackBarProps(() => {
                    return {
                      variant: "error",
                      showSnackBar: true,
                      messageInfo: response.data.resultStatusMessage,
                    };
                  });
                }
                setUpdating(false);
              },
              (exception: any) => {
                setSnackBarProps(() => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: exception,
                  };
                });
                setUpdating(false);
              }
            );
          }
        });
      } else {
        let params: ICourseDrop = {
          instrClassTermId: selectedClass.InstrClassTermId,
          studentScheduleId: selectedClass.StudentScheduleId,
          dropType: dropModel.dropType,
          grdSystemDetailId: dropModel.grdSystemDetailId,
          dropReason: dropModel.dropReason,
          stuEnrollId: model.studentEnrollmentId,
        };
        classRegApi.ReverseDropClass(params).then(
          (response: any) => {
            if (response && response.data && response.data.responseId == 1) {
              setSnackBarProps(() => {
                return {
                  variant: "success",
                  showSnackBar: true,
                  messageInfo: response.data.responseMessage,
                };
              });
              setDialogOpen(false);
              loadTerms();
              setReload(true);
            } else {
              setSnackBarProps(() => {
                return {
                  variant: "error",
                  showSnackBar: true,
                  messageInfo: "Class is full.",
                };
              });
            }
            setUpdating(false);
          },
          (exception: any) => {
            setSnackBarProps(() => {
              return {
                variant: "error",
                showSnackBar: true,
                messageInfo: exception,
              };
            });
            setUpdating(false);
          }
        );
      }
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleTextFieldClick = (event: any) => {
    event.stopPropagation();
  };

  const handleSearch = (searchText: string, termCalendarId: string) => {
    const filteredTerms = termsCopy.map((term) => {
      if (term.TermCalendarId === termCalendarId) {
        return {
          ...term,
          SearchText: searchText,
          Classes: term.Classes.filter(
            (cls) =>
              cls.Course.toLowerCase().includes(searchText.toLowerCase()) ||
              cls.Class.toLowerCase().includes(searchText.toLowerCase())
          ),
        };
      }
      return term;
    });
    setTerms(filteredTerms);
  };

  const handleDropChanges = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = dropModel;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    setDropModel({ ...updatedModel });
  };

  const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
    const item = items.find((opt) => {
      if (opt.id == value) return opt;
    });
    return item || ({} as DropDownListItem);
  };

  return (
    <div className={classes.root}>
      <div className={classes.paperRoot}>
        <Card className={classes.cardSeparator}>
          <CardContent>
            <FormContext {...data}>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} sm={12} xs={12}>
                  <Grid container direction="row" spacing={2}>
                    <Grid item md={6} sm={6} xs={12}>
                      <div className={classes.searchBarContainer}>
                        <SearchBarFilter
                          onSelect={(data?: any, studentName?: string) =>
                            data &&
                            data.stuEnrollId &&
                            studentName &&
                            setStudent({
                              studentEnrollmentId: data.stuEnrollId,
                              studentName,
                              studentId: data.studentId,
                            })
                          }
                          placeholder={model.studentName}
                          creditHoursOnly={true}
                        ></SearchBarFilter>
                      </div>
                    </Grid>
                    {model.studentEnrollmentId ? (
                      <Grid container direction="row" spacing={1}>
                        <Grid item md={3} sm={3} xs={12}>
                          <StudentBanner
                            stuEnrollId={model.studentEnrollmentId}
                            reload={reload}
                            setReload={setReload}
                          />
                        </Grid>

                        <Grid item md={9} sm={9} xs={12}>
                          <IconButton
                            onClick={() => {
                              const newSort =
                                filter.sortOrder === "ASC" ? "DESC" : "ASC";
                              setFilter({ ...filter, sortOrder: newSort });
                            }}
                          >
                            <SortIcon
                              className={
                                (filter.sortOrder === "ASC" &&
                                  classes.sortDesc) ||
                                ""
                              }
                            />
                          </IconButton>
                          {terms.map((row: ITermResult, index: any) => (
                            <Accordion className={classes.accordion}>
                              <AccordionSummary
                                className={classes.accordionSummary}
                              >
                                <Grid container direction="row" spacing={2}>
                                  <Grid item md={8} sm={8} xs={8}>
                                    <Typography>
                                      {row.Name +
                                        " ( " +
                                        row.StartDate +
                                        " - " +
                                        row.EndDate +
                                        " )"}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={4} sm={4} xs={4}>
                                    <div className={classes.right}>
                                      <TextField
                                        placeholder={"Search classes/courses"}
                                        onClick={handleTextFieldClick}
                                        value={row.SearchText}
                                        onChange={(v: any) => {
                                          handleSearch(
                                            v ? v.target.value : undefined,
                                            row.TermCalendarId
                                          );
                                        }}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconButton>
                                                <SearchIcon />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                              </AccordionSummary>
                              <AccordionDetails>
                                <TableContainer>
                                  <Table
                                    size="small"
                                    aria-label="a dense table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Class</TableCell>
                                        <TableCell>Instructor</TableCell>
                                        <TableCell>Course</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell align="center">
                                          Drop
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {row.Classes?.map(
                                        (cls: ITermClassResult, ix: any) => (
                                          <TableRow>
                                            <TableCell>{cls.Class}</TableCell>
                                            <TableCell>
                                              {cls.Instructor}
                                            </TableCell>
                                            <TableCell>{cls.Course}</TableCell>
                                            <TableCell>
                                              {cls.CourseStatus}
                                            </TableCell>
                                            <TableCell align="right">
                                              {cls.CourseStatusCode == "DP" ||
                                              cls.CourseStatusCode == "W" ? (
                                                <Tooltip
                                                  title={
                                                    hasReverseClassDropPermission ==
                                                    true
                                                      ? ""
                                                      : "You do not have sufficient permission to proceed."
                                                  }
                                                >
                                                  <div>
                                                    <ProgressSaveButton
                                                      text="Reverse"
                                                      key={cls.InstrClassTermId}
                                                      onClick={(e: any) => {
                                                        onReverseDrop(cls);
                                                      }}
                                                      disabled={
                                                        hasReverseClassDropPermission ==
                                                        true
                                                          ? false
                                                          : true
                                                      }
                                                      loading={updating}
                                                      buttonClassName={
                                                        classes.copyButton
                                                      }
                                                    ></ProgressSaveButton>
                                                  </div>
                                                </Tooltip>
                                              ) : (
                                                <ProgressSaveButton
                                                  text="Drop"
                                                  key={cls.InstrClassTermId}
                                                  onClick={(e: any) => {
                                                    onSubmit(cls);
                                                  }}
                                                  loading={updating}
                                                  buttonClassName={
                                                    classes.copyButton
                                                  }
                                                ></ProgressSaveButton>
                                              )}
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                        </Grid>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </FormContext>
          </CardContent>
          <CustomSnackbar
            variant={snackBarProps.variant}
            message={snackBarProps.messageInfo}
            open={snackBarProps.showSnackBar}
            onClose={(event?: React.SyntheticEvent, reason?: string) => {
              setSnackBarProps((props: any) => {
                return { ...props, showSnackBar: false };
              });
            }}
          ></CustomSnackbar>
          <Dialog
            open={dialogOpen}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth={true}
            disableBackdropClick
          >
            <DialogTitle
              id="form-dialog-title"
              className={classes.dialogHeader}
            >
              {isDrop ? "Drop Confirmation" : "Reverse Drop Confirmation"}
            </DialogTitle>
            <DialogContent>
              <FormContext {...data}>
                <form onSubmit={handleSubmit(handleConfirm)}>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12}>
                      {}
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        color="textSecondary"
                        className={classes.typography}
                        variant="body1"
                      >
                        {/* {"Please review and confirm."} */}
                        {"Attendance and any grades posted will be removed for the student. Do you wish to Proceed?"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TableContainer>
                        <Table size="small" aria-label="a dense table">
                          <TableBody>
                            <TableRow>
                              <TableCell>Class</TableCell>
                              <TableCell>{selectedClass?.Class}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Instructor</TableCell>
                              <TableCell>{selectedClass?.Instructor}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Course</TableCell>
                              <TableCell>{selectedClass?.Course}</TableCell>
                            </TableRow>
                            <TableRow>
                              {isDrop ? <TableCell>Drop Type *</TableCell> : ""}
                              {isDrop ? (
                                <TableCell>
                                  <Autocomplete
                                    {...dropTypeAutoComplete}
                                    autoComplete
                                    includeInputInList
                                    onChange={(e: any, value: any) => {
                                      handleDropChanges(
                                        "dropType",
                                        value ? value : undefined,
                                        (v: any) => v?.id
                                      );
                                    }}
                                    value={getSelectedItem(
                                      dropTypes,
                                      dropModel.dropType
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name="dropType"
                                        error={!!data.errors.dropType}
                                        inputRef={data.register({
                                          required: true,
                                        })}
                                        helperText={
                                          data.errors.dropType
                                            ? "Drop Type is required."
                                            : undefined
                                        }
                                        fullWidth
                                      />
                                    )}
                                  />
                                </TableCell>
                              ) : (
                                ""
                              )}
                            </TableRow>
                            {dropModel?.dropType == "Drop" ? (
                              <TableRow>
                                <TableCell>Drop Reason </TableCell>
                                <TableCell>
                                  <TextField
                                    placeholder={"Drop reason"}
                                    value={dropModel.dropReason}
                                    style={{ width: "100%" }}
                                    onChange={(v: any) => {
                                      setDropModel({
                                        ...dropModel,
                                        dropReason: v
                                          ? v.target.value
                                          : undefined,
                                      });
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            ) : dropModel?.dropType == "Withdrawal" ? (
                              <TableRow>
                                <TableCell>Grade System</TableCell>
                                <TableCell>
                                  <GradeSystemDetailAutoComplete
                                    id="grdSystemDetailId"
                                    name="grdSystemDetailId"
                                    label="Grade System *"
                                    dropOnly={true}
                                    grdSystemId={selectedClass?.GrdSystemId}
                                    valueFilter={
                                      dropModel.grdSystemDetailId
                                        ? {
                                            key: "value",
                                            values: [
                                              dropModel.grdSystemDetailId,
                                            ],
                                          }
                                        : undefined
                                    }
                                    filterHandle={(v: any) => {
                                      handleDropChanges(
                                        "grdSystemDetailId",
                                        v ? v.value : null
                                      );
                                    }}
                                    error={!!data.errors.grdSystemDetailId}
                                    inputRef={data.register({ required: true })}
                                    helperText={
                                      data.errors.grdSystemDetailId
                                        ? "Grade System is required."
                                        : undefined
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            ) : (
                              ""
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </form>
              </FormContext>
            </DialogContent>
            <Grid item xs={12}>
              <DialogActions className={classes.buttonSection}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={3}></Grid>
                  <Grid
                    item
                    container
                    xs={6}
                    alignContent="center"
                    alignItems="center"
                    justify="center"
                  >
                    <ProgressSaveButton
                      text={"Yes"}
                      onClick={(e: any) => {
                        handleConfirm(e);
                      }}
                      loading={false}
                      buttonClassName={classes.saveButton}
                    ></ProgressSaveButton>

                    <Button
                      onClick={handleClose}
                      color="secondary"
                      variant="contained"
                      type="button"
                    >
                      {"Cancel"}
                    </Button>
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
              </DialogActions>
            </Grid>
          </Dialog>
        </Card>
      </div>
    </div>
  );
}
export default ClassDrop;
