import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Portal,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	Button,
	Checkbox,
	TextField,
} from '@material-ui/core';
import { useForm, FormContext } from 'react-hook-form';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CustomSnackbar from '../../components/notifications/CustomSnackbar';
import { CustomSnackBarProps } from '../../interfaces/common/CustomSnackBarProps';
import GraduationStatusAutoComplete from '../../components/AutoComplete/GraduationStatusAutoComplete';
import * as StudentGradeWidgetApi from '../../api/dashboard/StudentGradeWidgetApi';
import { useSelector } from 'react-redux';
import AccessManager from '../../components/security/AccessManager';
import { security } from '../../constants/Security/securityConstants';
import { Autocomplete } from '@material-ui/lab';

const ledgerBalanceOptions = [
	{
		value: -1, label: 'N/A'
	},
	{
		value: 1, label: 'Yes'
	},
	{
		value: 2, label: 'No'
	}
];

const ledgeBalanceAutoCompleteConfigs = {
	options: ledgerBalanceOptions,
	getOptionLabel: (option: any) => option.label
};

type GraduationWidgetSettingsProps = {
  setOpen: any;
  open: boolean;
};

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			'& .MuiPaper-rounded': {
				borderRadius: theme.spacing.borderradius,
			},
			'& .MuiDialogContent-root': {
				minHeight: 400,
			},
		},
		form: {
			display: 'flex',
			flexDirection: 'column',
			width: 'fit-content',
			minWidth: 100,
		},
		dialogHeader: {
			backgroundColor: theme.palette.site.secondary,
			'& .MuiTypography-root': {
				fontSize: theme.spacing(2),
			},
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
		},
		historyWrapper: {
			padding: theme.spacing(0.5),
		},
		updateWrapper: {
			padding: theme.spacing(0.5),
			height: '125px',
		},
		spacer: {
			height: theme.spacing(1),
		},
		settingDescription: {
			fontSize: 15,
			margin: theme.spacing(2),
			fontWeight: theme.typography.fontWeightLarge,
		},
		table: {
			width: '100%',
		},
		button: {
			padding: theme.spacing(1),
			margin: theme.spacing(2, 0),
		},
		buttonColorPrimary: {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.primary.contrastText,
			'&:hover': {
				backgroundColor: theme.palette.primary.main,
			},
			textDecoration: 'bold',
			padding: theme.spacing(1),
			whiteSpace: 'nowrap',
			margin: theme.spacing(2, 0),
		},
		statusAutoSelect: {
			width: '250px',
		},
	})
);

const GraduationWidgetSettingsModel = (
	props: GraduationWidgetSettingsProps
) => {
	const classes = useStyles({});
	const [settingStatusData, setSettingStatusData] = React.useState([] as any);

	const campusId = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const GetGraduationWidgetSettings = () => {
		var params = {
			campusId: campusId,
		};
		StudentGradeWidgetApi.GetGraduationWidgetSettings(params).then(
			(response: any) => {
				if (response && response.data && response.data?.length > 0) {
					setSettingStatusData(response.data);
				} else {
					GetGraduationStatusDefaultSetting();
				}
			},
			(_exception: any) => { }
		);
	};

	const GetGraduationStatusDefaultSetting = () => {
		StudentGradeWidgetApi.GetGraduationStatusDefaultSetting().then(
			(response: any) => {
				if (response) {
					setSettingStatusData(response.data);
				}
			},
			(_exception: any) => { }
		);
	};

	React.useEffect(() => {
		if (campusId && props.open === true) {
			GetGraduationWidgetSettings();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.open]);

	const handleClose = () => {
		props.setOpen(false);
	};

	const data = useForm<any>({
		mode: 'onBlur',
	});

	const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
		{
			showSnackBar: false,
			messageInfo: undefined,
			variant: 'info',
		}
	);

	const handleOnChange = (event: any, fieldName: string, row: any) => {
		const selectedIndex = settingStatusData.findIndex(
			(r: any) => r.graduationStatusSettingId === row.graduationStatusSettingId
		);

		let newData = [...settingStatusData];
		if (fieldName === 'hoursCompleted') {
			newData[selectedIndex] = {
				...newData[selectedIndex],
				hoursCompleted: event.target.checked,
			};
		}
		if (fieldName === 'academicRequirementsCompleted') {
			newData[selectedIndex] = {
				...newData[selectedIndex],
				academicRequirementsCompleted: event.target.checked,
			};
		}
		if (fieldName === 'noLedgerBalance') {
			newData[selectedIndex] = {
				...newData[selectedIndex],
				noLedgerBalance: event,
			};
		}
		if (fieldName === 'statusCodeId') {
			newData[selectedIndex] = {
				...newData[selectedIndex],
				statusCodeId: event.value,
			};
		}
		setSettingStatusData(newData);
	};

	const updateStatus = () => {
		settingStatusData.forEach((status: any) => {
			status.campusId = campusId;
		});
		StudentGradeWidgetApi.SaveGraduationWidgetSettings(
			campusId,
			settingStatusData
		).then(
			(response: any) => {
				if (response.status === 200 && response.data === true) {
					setSnackBarProps((_props: any) => {
						return {
							variant: 'success',
							showSnackBar: true,
							messageInfo: 'Status settings successfully saved.',
						};
					});
				} else {
					setSnackBarProps((_props: any) => {
						return {
							variant: 'error',
							showSnackBar: true,
							messageInfo:
                'Status settings could not be saved. Please try again.',
						};
					});
				}
			},
			(_exception: any) => { }
		);
	};

	return (
		<FormContext {...data}>
			<form className={classes.form}>
				<div>
					<Portal>
						<CustomSnackbar
							variant={snackBarProps.variant}
							message={snackBarProps.messageInfo}
							open={snackBarProps.showSnackBar}
							onClose={(_event?: React.SyntheticEvent, _reason?: string) => {
								setSnackBarProps((props: any) => {
									return { ...props, showSnackBar: false };
								});
							}}
						></CustomSnackbar>
					</Portal>
					<Dialog
						open={props.open}
						onClose={handleClose}
						className={classes.root}
						aria-labelledby="form-dialog-title"
						fullWidth={true}
						maxWidth="md"
						disableBackdropClick
					>
						<DialogTitle
							id="form-dialog-title"
							className={classes.dialogHeader}
						>
							{'Rules: Graduate Student Status'}
							<IconButton
								aria-label="close"
								className={classes.closeButton}
								onClick={handleClose}
							>
								<CloseIcon />
							</IconButton>
						</DialogTitle>
						<DialogContent>
							<div className={classes.historyWrapper}>
								<Typography className={classes.settingDescription}>
                  Please specify the graduation status the student should be
                  placed for each of the listed cases
								</Typography>
								<TableContainer component={Paper}>
									<Table
										className={classes.table}
										size="small"
										aria-label="a dense table"
									>
										<TableHead>
											<TableRow>
												<TableCell align="left">Completed Hrs</TableCell>
												<TableCell align="left">
                          Completed Academic Requirements
												</TableCell>
												<TableCell align="left">No Ledger Balance</TableCell>
												<TableCell
													className={classes.statusAutoSelect}
													align="left"
												>
                          Graduation Status
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{settingStatusData !== null &&
                        settingStatusData.length > 0 ? (
													settingStatusData.map((row: any, index: number) => {
														return (
															<TableRow key={`row-${index}`}>
																<TableCell align="center">
																	<Checkbox
																		id={
																			'hoursCompleted' +
                                    row.graduationStatusSettingId
																		}
																		name={
																			'hoursCompleted' +
                                    row.graduationStatusSettingId
																		}
																		value="uncontrolled"
																		color="primary"
																		inputProps={{
																			'aria-label': 'uncontrolled-checkbox',
																		}}
																		checked={row.hoursCompleted}
																		onChange={(event: any) =>
																			handleOnChange(event, 'hoursCompleted', row)
																		}
																	/>
																</TableCell>
																<TableCell align="center">
																	<Checkbox
																		id={
																			'academicRequirementsCompleted' +
                                    row.graduationStatusSettingId
																		}
																		name={
																			'academicRequirementsCompleted' +
                                    row.graduationStatusSettingId
																		}
																		value="uncontrolled"
																		color="primary"
																		inputProps={{
																			'aria-label': 'uncontrolled-checkbox',
																		}}
																		checked={row.academicRequirementsCompleted}
																		onChange={(event: any) =>
																			handleOnChange(
																				event,
																				'academicRequirementsCompleted',
																				row
																			)
																		}
																	/>
																</TableCell>
																<TableCell align="center">
																	<Autocomplete
																		{...ledgeBalanceAutoCompleteConfigs}
																		disableClearable
																		id={
																			'noLedgerBalance' +
                                    row.graduationStatusSettingId
																		}
																		value={ledgerBalanceOptions.find(option => option.value === (row.noLedgerBalance === true ? 1 : row.noLedgerBalance === false ? 2 : -1))}
																		onChange={(e: any, v: any) => {
																			handleOnChange(((v?.value === 1 ? true : v?.value === 2 ? false : null)),
																				'noLedgerBalance', row);
																		}}
																		renderInput={(params: any) => (
																			<TextField {...params} />
																		)}
																	/>
																</TableCell>
																<TableCell align="left">
																	<GraduationStatusAutoComplete
																		id={
																			'statusCodeId' +
                                    row.graduationStatusSettingId
																		}
																		name={
																			'statusCodeId' +
                                    row.graduationStatusSettingId
																		}
																		label="Status"
																		valueFilter={
																			row.statusCodeId
																				? {
																					key: 'value',
																					values: [row.statusCodeId],
																				}
																				: undefined
																		}
																		filterHandle={(event: any) => {
																			handleOnChange(event, 'statusCodeId', row);
																		}}
																	/>
																</TableCell>
															</TableRow>
														);
													})
												) : (
													<TableRow>
														<TableCell align="center" colSpan={4}>
                            No Data
														</TableCell>
													</TableRow>
												)}
										</TableBody>
									</Table>
								</TableContainer>
								<AccessManager
									allowedPermissions={[
										security.permissions.dashboard
											.updateGraduationWidgetStatusSettings,
									]}
									renderNoAccess={() => (
										<Button
											disabled={true}
											onClick={() => {
												updateStatus();
											}}
											size="small"
											className={classes.button}
											type="button"
											color="primary"
										>
                      SAVE
										</Button>
									)}
								>
									<Button
										className={classes.buttonColorPrimary}
										onClick={() => {
											updateStatus();
										}}
										size="small"
										type="button"
									>
                    SAVE
									</Button>
								</AccessManager>
							</div>
						</DialogContent>
					</Dialog>
				</div>
			</form>
		</FormContext>
	);
};

export default GraduationWidgetSettingsModel;
