import * as React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
	Grid,
} from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import { ISAPCheckReportParams } from "../../../interfaces/reports/studentAccounts/ISAPCheckReportParams";
import * as SAPCheckReportApi from "../../../api/reports/studentAccounts/SAPCheckReportApi";
import { ReportOutput } from "../../../enums/ReportOutput";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import { useSelector } from "react-redux";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import StudentGroupsAutoComplete from "../../../components/AutoComplete/StudentGroupsAutoComplete";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import EnrollmentStatusAutoComplete from "../../../components/AutoComplete/EnrollmentStatusAutoComplete";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import ProgramVersionAutoComplete from "../../../components/AutoComplete/ProgramVersionAutoComplete";
import CampusForCurrentUserAutoComplete from "../../../components/AutoComplete/CampusForCurrentUserAutoComplete";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			minHeight: "100%",
			height: "100%",
			padding: theme.spacing(2),
		},
		"& .makeStyles-content": {
			backgroundColor: theme.palette.background.paper,
		},
		paperRoot: {
			padding: theme.spacing(3, 2),
			width: "100%",
			minHeight: "100%",
			height: "100%",
			overflowX: "hidden",
			overflowY: "auto",
			flexDirection: "column",
			backgroundColor: theme.palette.background.paper,
		},
		searchActions: {
			marginTop: theme.spacing(3),
			textAlign: "left",
		},
		startDateField: {
			width: "100%",
		},
		fieldSet: {
			marginTop: theme.spacing(2),
		},
		switch: {
			marginLeft: "0px",
			paddingTop: theme.spacing(2),
		},
		inputField: {
			width: "90%",
		},
	})
);

const SAPCheckReport = (props: any) => {
	const { report } = props;
	const classes = useStyles({});
	const [preview, setPreview] = React.useState<any>();
	const [previewLoader, setPreviewLoader] = React.useState<boolean>();

	let reportOverview = { report: report } as IReportOverview;

	const data = useForm<any>({ mode: "onBlur" });

	const currentCampusId = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const [value, setValue] = React.useState([0, 3000]);

	const [model, setModel] = React.useState({
		campusIds: [currentCampusId] as Array<string>,
		leadGroupIds: [] as Array<string>,
		programVersionIds: [] as Array<string>,
		enrollmentStatusIds: [] as Array<string>,
		actualHourStart: 1,
		actualHourEnd: 1500,
		exportType: ReportOutput.Excel as any,
	});

	const exportTypes: Array<DropDownListItem> = [
		{ text: "Excel", id: "2" },
	];

	const exportTypeAutoComplete = {
		options: exportTypes,
		getOptionLabel: (option: DropDownListItem) => option.text,
	};

	const setProgramVersion = (PrgVersions: any) => {
		let newProgramVersion: string[] = [];
		if (PrgVersions !== null) {
			if (Array.isArray(PrgVersions)) {
				newProgramVersion = PrgVersions.map((gr: any) => gr.value);
			} else {
				newProgramVersion.push(PrgVersions.value);
			}
		}
		return newProgramVersion;
	};

	const setStudentGroup = (studentGroups: any) => {
		let newGroups: string[] = [];
		if (studentGroups !== null) {
			if (Array.isArray(studentGroups)) {
				newGroups = studentGroups.map((gr: any) => gr.value);
			} else {
				newGroups.push(studentGroups.value);
			}
		}
		return newGroups;
	};

	const setEnrollmentStatus = (enrollmentStatus: any) => {
		let newStatuses: string[] = [];
		if (enrollmentStatus !== null) {
			if (Array.isArray(enrollmentStatus)) {
				newStatuses = enrollmentStatus.map((gr: any) => gr.value);
			} else {
				newStatuses.push(enrollmentStatus.value);
			}
		}

		return newStatuses;
	};

	const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
		const item = items.find((opt) => {
			if (opt.id == value) return opt;
		});
		return item || ({} as DropDownListItem);
	};

	const handleFieldOnChange = (
		fieldId: string,
		value: any,
		mapFunction?: Function
	) => {
		if ((fieldId == "actualHourStart" || fieldId == "actualHourEnd") && value < 0) {
			return;
		}
		else {
			let updatedModel = model;
			let newValue = mapFunction ? mapFunction(value) : value;
			(updatedModel as any)[fieldId] = newValue;
			setModel({ ...updatedModel });
		}
	};

	const { handleSubmit } = data;

	const onSubmit = async (isPreview: any): Promise<void> => {

		let params = await getReportParameters();
		const validation = await data.triggerValidation();
		if (validation) {
			if (isPreview) {
				SAPCheckReportApi
					.generateReport(params, isPreview)
					.then((res: any) => {
						if (res) {
							res.arrayBuffer().then((buffer: any) => {
								setPreview(buffer);
								setPreviewLoader(false);
							});
						} else setPreviewLoader(false);
					});
			} else await SAPCheckReportApi.generateReport(params);
		}
	};

	const filterHandler = (data: any) => {
		if (data) {
			setModel({ ...data });
		} else {
			setModel({
				campusIds: model.campusIds,
				leadGroupIds: model.leadGroupIds,
				programVersionIds: model.programVersionIds,
				enrollmentStatusIds: model.enrollmentStatusIds,
				actualHourStart: model.actualHourStart,
				actualHourEnd: model.actualHourEnd,
				exportType: model.exportType,
			});
		}
	};

	const getReportParameters = async () => {
		const success = await data.triggerValidation();
		if (model && success) {
			let params: ISAPCheckReportParams = {
				campusIds: model.campusIds,
				leadGroupIds: model.leadGroupIds,
				programVersionIds: model.programVersionIds,
				enrollmentStatusIds: model.enrollmentStatusIds,
				actualHourStart: model.actualHourStart,
				actualHourEnd: model.actualHourEnd,
				exportType: model.exportType,
			};
			return params;
		}
		return {} as ISAPCheckReportParams;
	};

	let parameters = (
		<div className={classes.root}>
			<FormContext {...data}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container direction="row" spacing={1}>
						<Grid item md={6} sm={6} xs={12}>
							<EnrollmentStatusAutoComplete
								filterHandle={(statuses: any) => {
									handleFieldOnChange(
										"enrollmentStatusIds",
										statuses,
										setEnrollmentStatus
									);
								}}
								id="enrollmentStatusIds"
								name="enrollmentStatusIds"
								label="Enrollment Status *"
								error={!!data.errors.enrollmentStatusIds}
								inputRef={data.register({
									validate: {
										atLeastOneRequired: (value) =>
											(model.enrollmentStatusIds && model.enrollmentStatusIds.length > 0) ||
											"Status(es) are required.",
									},
								})}
								helperText={
									data.errors.enrollmentStatusIds
										? "At least one status is required."
										: undefined
								}
								valueFilter={
									model?.enrollmentStatusIds
										? {
											key: "value",
											values: model?.enrollmentStatusIds,
										}
										: undefined
								}
								multiple={true}
							/>
						</Grid>
						<Grid item md={6} sm={6} xs={12}></Grid>
						<Grid item md={6} sm={6} xs={12}>
							<StudentGroupsAutoComplete
								id="leadGroupIds"
								name="leadGroupIds"
								campusId={currentCampusId}
								disabled={false}
								filterHandle={(groups: any) => {
									handleFieldOnChange("leadGroupIds", groups, setStudentGroup);
								}}
								label="Student Group"
								multiple={true}
								valueFilter={
									model?.leadGroupIds
										? {
											key: "value",
											values: model?.leadGroupIds,
										}
										: undefined
								}
							/>
						</Grid>
						<Grid item md={6} sm={6} xs={12}>
							<ProgramVersionAutoComplete
								selectAll={true}
								name="programVersionIds"
								campusId={currentCampusId}
								filterHandle={(value: any) =>
									handleFieldOnChange(
										"programVersionIds",
										value,
										setProgramVersion
									)
								}
								valueFilter={
									model.programVersionIds
										? {
											key: "value",
											values: model.programVersionIds,
										}
										: undefined
								}
								multiple={true}
								disabled={false}
							/>
						</Grid>
						<Grid md={3} sm={3} xs={6}>
							<TextField
								id="actualHourStart"
								name="actualHourStart"
								label="Actual Hour Start"
								className={classes.inputField}
								type={InputType.NUMBER}
								value={model.actualHourStart ? model.actualHourStart : ""}
								onChange={(v: any) => {
									handleFieldOnChange(
										"actualHourStart",
										v ? v.target.value : undefined
									);
								}}
							/>
						</Grid>
						<Grid md={3} sm={3} xs={6}>
							<TextField
								id="actualHourEnd"
								name="actualHourEnd"
								label="Actual Hour End"
								className={classes.inputField}
								type={InputType.NUMBER}
								value={model.actualHourEnd ? model.actualHourEnd : ""}
								onChange={(v: any) => {
									handleFieldOnChange(
										"actualHourEnd",
										v ? v.target.value : undefined
									);
								}}
							/>
						</Grid>
						
						<Grid item md={3} sm={3} xs={3}>
							<Autocomplete
								{...exportTypeAutoComplete}
								autoComplete
								includeInputInList
								onChange={(e: any, value: any) => {
									handleFieldOnChange(
										"exportType",
										value ? value : undefined,
										(v: any) => v?.id
									);
								}}
								value={getSelectedItem(exportTypes, model.exportType)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Export Type *"
										name="exportType"
										error={!!data.errors.exportType}
										inputRef={data.register({ required: true })}
										helperText={
											data.errors.exportType
												? "Export Type is required."
												: undefined
										}
										fullWidth
									/>
								)}
							/>
						</Grid>
					</Grid>
				</form>
			</FormContext>
		</div>
	);

	reportOverview.parameters = parameters;

	return (
		<ReportOverview
			reportOverview={reportOverview}
			filterHandler={filterHandler}
			getReportParameters={getReportParameters}
			exportHandler={onSubmit}
			preview={preview}
			previewLoader={previewLoader}
		/>
	);
};

export default SAPCheckReport;
