import * as React from "react";
import { useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { SAPPolicyApiAutoComplete } from "../../constants/SystemCatalog/SAPPolicyApiAutoComplete";

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& label.Mui-focused": {
        color: theme.palette.primary.white
      }
    }
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {}
  })
);

const SAPPolicyAutoComplete = (props: FilterParams) => {
  const classes = useStyles({});
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const inputRef: any = useRef(null);
  const filterHandle = props.filterHandle;

  const SAPPolicyOnChange = (e: any) => {
    if (filterHandle) {
      filterHandle(e);
    }
  };

  return (
    <div>
      <ApiAutoComplete
        config={SAPPolicyApiAutoComplete.config}
        loading={userSelectedCampus ? false : true}
        reqParams={{ campusId: userSelectedCampus }}
        classes={{
          option: "text-black"
        }}
        label={props.label ??  'SAP Policy'}
        onChange={(e: any) => {
          SAPPolicyOnChange(e);
        }}
        multiple={false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        inputRef={props.inputRef ? props.inputRef : inputRef}
        error={props.error}
        helperText={props.helperText}
        inputName={props.name}
      ></ApiAutoComplete>
    </div>
  );
};
export default SAPPolicyAutoComplete;
