import * as React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardContent,
    TableCell,
    IconButton,
    Tooltip,
    Table,
    TableHead,
    TableRow,
    CircularProgress,
    TableBody,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Typography,
    DialogActions,
    Button
} from '@material-ui/core';
import { Scrollbars, positionValues } from "react-custom-scrollbars";
import { useSelector } from 'react-redux';
import { selectors as adHocSelectors, actions as adhocActions } from '../../../store/reports/adhocs';
import { selectors as adHocDetailsSelectors, actions as adHocDetailsActions } from '../../../store/reports/adhocReportDetails';
import { IAdhocReport } from '../../../interfaces/reports/adhoc/IAdhocReport';
import { useActions } from '../../../store/utils';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import ProgressSaveButton from '../../../components/_Layout/Buttons/ProgressSaveButton';
import PreviewPopUp from './PreviewPopUp';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

const useStyles = makeStyles((theme: any) => ({
    root: {},
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    content: {
        padding: 0,
        height: '100%',
        "&:last-child": {
            paddingBottom: 0
        }
    },
    inner: {
        height: '100%',
        width: '100%',
        minWidth: 700
    },
    nameCell: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        height: 42,
        width: 42,
        marginRight: theme.spacing(1)
    },
    actions: {
        padding: theme.spacing(1),
        justifyContent: 'flex-start'
    },
    tableCell: {
        flex: 1,
        whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'
    },
    noClick: {
        cursor: 'initial',
    },
    marginRight: {
        marginRight: theme.spacing(1)
    },
    redText: {
        color: theme.palette.error.main
    },
    typography: {
        padding: theme.spacing(2),
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    popOverButtons: {
        justifyContent: 'space-evenly',
        padding: 5
    },
    newReportButton: {
        padding: theme.spacing(1),
        float: 'right'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    scroll: {
        '& div': {
            zIndex: 10
        }
    }
}));

type AdhocResultsProps = {
    className: string;
}





const Results = (props: AdhocResultsProps) => {
    const { className, ...rest } = props;
    const scrollbar = React.useRef<Scrollbars>(null);
    const isLoading = useSelector(adHocSelectors.isLoading);
    const loadingType = useSelector(adHocSelectors.loadType);
    const nextPage = useSelector(adHocSelectors.nextPage);
    const prevPage = useSelector(adHocSelectors.prevPage);
    const pageSize = useSelector(adHocSelectors.pageSize);
    const search = useSelector(adHocSelectors.search);
    const category = useSelector(adHocSelectors.category);
    const hasMore = useSelector(adHocSelectors.hasMore);
    const data = useSelector(adHocSelectors.data);
    const deleting: boolean = useSelector(adHocDetailsSelectors.deleting);
    const actions = useActions({
        fetchData: adhocActions.loadReports,
        exportReport: adHocDetailsActions.exportAdHocReportById,
        deleteReport: adHocDetailsActions.deleteAdHocReport,
        duplicateRport: adHocDetailsActions.duplicateAdHocReport
    }, []);

    const loading = isLoading && (!loadingType);
    const loadingNext = isLoading && (loadingType === 1);
    const loadingPrev = isLoading && (loadingType === -1);

    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );

    React.useEffect(() => {
        if (scrollbar && scrollbar.current && !isLoading) {
            if (loadingType == -1) {
                scrollbar.current.scrollTop(scrollbar.current.getScrollTop() + 1);
            }
            else if (loadingType == 1) {
                scrollbar.current.scrollTop(scrollbar.current.getScrollTop() - 1);
            }
            else {
                scrollbar.current.scrollToTop();
            }
        }
    }, [loadingType, isLoading]);

    const classes = useStyles({});
    const history = useHistory();

    const [deletePop, setDeletePop] = React.useState<(() => void) | undefined>(undefined);

    React.useEffect(
        () => {
            if (!deleting) {
                setDeletePop(undefined);
            }
        }, [deleting]);

    const [previewReport, setPreviewRerport] = React.useState<{ report: string; reportName: string }>();

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Card>
                <CardContent className={classes.content}>
                    <Paper className={classes.inner} style={{ height: 600 }}>
                        <Scrollbars
                            className={classes.scroll}
                            renderView={(props) => (
                                <div {...props} style={{ ...props.style, overflowX: "hidden" }} />
                            )}
                            ref={scrollbar}
                            onScrollFrame={(val: positionValues) => {
                                console.log(val);
                                if (!(loadingNext || loadingPrev || loading)) {
                                    if (val.top >= 0.99 && hasMore && (nextPage > 0)) {
                                        actions.fetchData(userSelectedCampus, search, category, 1, nextPage, pageSize)
                                    } else if (val.scrollTop === 0 && prevPage > 0) {
                                        actions.fetchData(userSelectedCampus, search, category, -1, prevPage, pageSize);
                                    }
                                }
                            }}
                        >
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            variant="head"
                                            component="th"
                                        >
                                            Report Title
                                        </TableCell>
                                        <TableCell
                                            variant="head"
                                            component="th"
                                        >
                                            Report Description
                                        </TableCell>
                                        <TableCell
                                            variant="head"
                                            component="th"
                                        >
                                            Report Category
                                        </TableCell>
                                        <TableCell
                                            variant="head"
                                            component="th"
                                        >

                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(loading || loadingPrev) && <TableRow>
                                        <TableCell
                                            variant="body"
                                            component="td"
                                            colSpan={4}
                                            align="center"
                                        >
                                            <CircularProgress color="primary" />
                                        </TableCell>
                                    </TableRow>}
                                    {
                                        !loading && data.map((m: IAdhocReport) => (<TableRow>
                                            <TableCell
                                                variant="body"
                                                component="td"
                                            >
                                                {m.reportName}
                                            </TableCell>
                                            <TableCell
                                                variant="body"
                                                component="td"
                                            >
                                                {m.reportDescription}
                                            </TableCell>
                                            <TableCell
                                                variant="body"
                                                component="td"
                                            >
                                                {m.categoryName}
                                            </TableCell>
                                            <TableCell
                                                variant="body"
                                                component="td"
                                            >
                                                <Tooltip title="View Report">
                                                    <IconButton onClick={() => setPreviewRerport({ report: m.id, reportName: m.reportName })}>
                                                        <PageviewOutlinedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Copy Report">
                                                    <IconButton onClick={() => actions.duplicateRport(m.id)}>
                                                        <FileCopyOutlinedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Edit Report">
                                                    <IconButton onClick={() => history.push(`/reports/adhoc/report/${m.id}`)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete Report">
                                                    <IconButton onClick={() => { setDeletePop(() => () => actions.deleteReport(m.id)); }}>
                                                        <DeleteOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Download Report">
                                                    <IconButton onClick={() => { actions.exportReport(m.id, userSelectedCampus, m.reportName, 2); actions.exportReport(m.id, userSelectedCampus, m.reportName, 1); }}>
                                                        <GetAppIcon />
                                                    </IconButton>
                                                </Tooltip>

                                                {/* <Tooltip title="Schedule Report/Edit Schedule">
                                                    <IconButton>
                                                        <HistoryIcon />
                                                    </IconButton>
                                                </Tooltip> */}
                                            </TableCell>
                                        </TableRow>))
                                    }
                                    {(loadingNext) && <TableRow>
                                        <TableCell
                                            variant="body"
                                            component="td"
                                            colSpan={4}
                                            align="center"
                                        >
                                            <CircularProgress color="primary" />
                                        </TableCell>
                                    </TableRow>}
                                </TableBody>
                            </Table>
                        </Scrollbars>
                        <PreviewPopUp open={!!previewReport} close={() => setPreviewRerport(undefined)} report={(previewReport && previewReport?.report) || ''} reportName={(previewReport && previewReport?.reportName) || ''} />
                        <Dialog open={!!deletePop} onClose={() => !deleting && setDeletePop(undefined)}>
                            <DialogTitle id="customized-dialog-title">
                                <Typography variant="h6">Delete Report</Typography>

                                <IconButton aria-label="close" className={classes.closeButton} onClick={() => !deleting && setDeletePop(undefined)}>
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to delete this report?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <ProgressSaveButton
                                    loading={deleting}
                                    onClick={() => deletePop !== undefined && deletePop()}
                                    text="Confirm Delete"
                                    type="button" />
                                {' '}
                                <Button variant="contained" color="default" onClick={() => !deleting && setDeletePop(undefined)}>
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Paper>
                </CardContent>

            </Card>
        </div >
    );
};

export default Results;
