import * as React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, colors, InputAdornment } from '@material-ui/core';
import InputField from '../../../components/_Layout/Inputs/InputField';
import Search from '@material-ui/icons/Search';
import { useSelector } from 'react-redux';
import { selectors as adHocSelectors, actions as adhocActions } from '../../../store/reports/adhocs';
import { useActions } from '../../../store/utils';
import AdHocReportCategoryAutoComplete from '../../../components/AutoComplete/AdHocReportCategoryAutoComplete';

const useStyles = makeStyles((theme: any) => ({
    root: {},
    adhoc: {

        alignSelf: "flex-end",

    },
    addAdhoc: {
        backgroundColor: colors.grey[50],
        padding: theme.spacing(1),
        float: 'right'
    }
}));

type IUserListFilterProps = {
    className?: string
}

const Header = (props: IUserListFilterProps) => {
    const { className, ...rest } = props;

    const classes = useStyles({});
    const search = useSelector(adHocSelectors.search);
    const category = useSelector(adHocSelectors.category);
    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );
    const actions = useActions({
        fetchData: adhocActions.loadReports
    }, []);

    React.useEffect(() => {
        actions.fetchData(userSelectedCampus, '', []);
    }, [userSelectedCampus]);

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Grid
                justify="space-between"
                alignItems="flex-start"
                container
                direction="row"
                spacing={1}
            >
                <Grid item xs={5}>
                    <InputField
                        defaultValue={search || undefined} onChange={(e) => {
                            if (e.target.value && e.target.value.length >= 3) {
                                actions.fetchData(userSelectedCampus, e.target.value, category);
                            }
                            else if (!e.target.value && !!search) {
                                actions.fetchData(userSelectedCampus, "", category);
                            }
                        }} placeholder="Search reports by title" InputProps={{ startAdornment: <InputAdornment position="start"><Search /></InputAdornment> }} />
                </Grid>
                <Grid item xs={5}>
                    <AdHocReportCategoryAutoComplete
                        hideLabel
                        multiple
                        maxTags={5}
                        chipSize="small"
                        placeholder="Search reports by category"
                        filterHandle={(v: any) => actions.fetchData(userSelectedCampus, search, ((v && Array.isArray(v) && v.map(k => k.value)) || []))}
                        valueFilter={
                            category
                                ? {
                                    key: "value",
                                    values: category,
                                }
                                : undefined
                        }
                    />
                </Grid>


            </Grid>
        </div>
    );
};

Header.propTypes = {
    className: PropTypes.string
};

export default Header;
