import React from 'react';
import {
  makeStyles,
  Card,
  createStyles,
  CardContent,
  Grid,
  Button,
  CardActions,
  Paper,
  Table,
  withStyles,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  Checkbox,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import CustomCardTitle from '../../../../../../interfaces/common/card/CustomCardTitle';
import ICreditCourseDetails from '../../../../../../interfaces/setup/academics/ProgramDefinition/ICreditCourseDetails';
import InputField from '../../../../../../components/_Layout/Inputs/InputField';
import { useSelector } from 'react-redux';
import { EmptyGuid } from '../../../../../../utils/constants';
import { getAllCourses, getAllCreditCourses } from '../../../../../../api/setup/academics/creditCoursesApi';
import ICreditProgramCourse from '../../../../../../interfaces/setup/academics/ProgramDefinition/ICreditProgramCourse';
import { CustomSnackBarProps } from '../../../../../../interfaces/common/CustomSnackBarProps';

const StyledTableCell = withStyles((theme: any) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.site.secondary,
      fontSize: 17,
      color: theme.palette.black,
      fontWeight: 'bold',
      textAlign: 'center',
      boxShadow: '1px 1px 0px 0px rgba(0,0,0,0.2)',
      '&:first-child': {
        width: '42px',
        padding: theme.spacing(0, 1)
      }
    },
    body: {
      fontSize: 16,
      color: theme.palette.black,
      fontWeight: 500,
      cursor: 'pointer',
      textAlign: 'left',
      '&:first-child': {
        width: '42px',
        padding: theme.spacing(0, 1)
      }
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: any) =>
  createStyles({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.site.secondary,
      },
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.white,
      },
      '&:hover': {
        backgroundColor: theme.palette.site.secondary,
      }
    },
    body: {
      cursor: 'pointer'
    }
  }),
)(TableRow);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: 'bold',
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: '100%',
    },
    saveButton: {
      width: 90,
    },
    tableContainer: {
      maxHeight: '400px'
    },
    table: {
      width: '100%',
    },
    iconColor: {
      color: theme.palette.black
    },
    searchField: {
      '&::placeholder': {
        color: '#000000'
      }
    },
    selectionCheckBox: {
      padding: theme.spacing(0)
    },
    preLoaderCell: {
      textAlign: 'center',
      padding: theme.spacing(1)
    }
  })
);
type AddCourseProps = {
  addCoursesToProgram: (coursesToAdd: ICreditProgramCourse[]) => void;
  showSnackBar: (param: CustomSnackBarProps) => void;
};

const AddCreditCourse = (
  props: Readonly<AddCourseProps>
) => {
  const classes = useStyles({});

  const [courses, setCourses] = React.useState<ICreditCourseDetails[]>([]);
  const [selected, setSelected] = React.useState<{ [key: number]: boolean }>({});
  const [searchText, setSearchText] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [coursePopUpOpen, setCoursePopUpOpen] = React.useState<boolean>(false);

  const toDisplay = searchText
    && searchText.length > 2
    ? courses.filter((course) => course.courseId === searchText
      || (course.name ?? "").toLocaleLowerCase().includes(searchText.toLocaleLowerCase()))
    : courses;

  const selectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const openCoursePopUp = React.useCallback(() => {
    setCoursePopUpOpen(true);
  }, []);

  const closeCoursePopUp = React.useCallback(() => {
    setCoursePopUpOpen(false);
  }, []);
  const selectRow = (index: number) => {
    setSelected({ ...selected, [index]: !(selected[index]) })
  }

  const selectAll = (): void => {
    if (toDisplay && toDisplay.length > 0) {
      const listSelected: { [key: number]: boolean } = {};
      const toggle = Object.values(selected).includes(false) || Object.values(selected).length < courses.length;
      for (let i = 0; i < toDisplay.length; i++) {
        listSelected[(i + 1)] = toggle
      }
      setSelected(listSelected);
    }
  }

  const loadCourses = async (): Promise<void> => {
    try {
      setLoading(true);
      const courses = await getAllCreditCourses(false);
      setCourses(courses);
    }
    catch (_e) {
      setCourses([]);
    }
    finally {
      setTimeout(() => setLoading(false), 250);
    }
  }

  const addCourseToProgram = (): void => {
    if (!loading) {
      setLoading(true);
      const toAdd: ICreditProgramCourse[] = Object.keys(selected).filter((index) => selected[(Number(index))]).map((index): ICreditProgramCourse => ({
        ...toDisplay[(Number(index) - 1)],
        required: true,
        programRequirementsId: EmptyGuid,
        //campusId: selectedCampus,
      }));
      if (toAdd.length > 0) {
        setSelected({});
        props.addCoursesToProgram(toAdd);
      }
      setLoading(false);
    }
  }

  React.useEffect(() => {
    setCourses([]);
    setSelected({});
    if (selectedCampus && (selectedCampus as string) !== EmptyGuid && !loading) {
      loadCourses();
    }
  }, [selectedCampus]);

  React.useEffect(() => {
    setSelected({});
  }, [searchText])

  return (
    <React.Fragment>
      <Card square={true} elevation={2} >
        <CustomCardTitle title='Add Courses to Program Version'></CustomCardTitle>
        <CardContent>
          <Grid container direction="column" spacing={2}>
            <Grid
              item
              alignContent="flex-start"
              alignItems="flex-start"
              justify="flex-start"
            >
              <InputField
                value={searchText ?? ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
                inputProps={{ className: classes.searchField }}
                InputProps={
                  {
                    startAdornment: <SearchIcon className={classes.iconColor} />,
                    endAdornment: searchText && <IconButton onClick={() => setSearchText('')}><CloseIcon className={classes.iconColor} /></IconButton>
                  }
                }
                placeholder={'Search Courses'} />
            </Grid>
            <Grid
              item
              alignContent="flex-start"
              alignItems="flex-start"
              justify="flex-start"
            >
              <TableContainer component={Paper} square className={classes.tableContainer}>
                <Table stickyHeader size='small' className={classes.table} aria-label='customized table'>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell >
                        <Checkbox
                          className={classes.selectionCheckBox}
                          color='primary'
                          indeterminate={Object.values(selected).includes(true) && (Object.values(selected).includes(false) || Object.values(selected).length < toDisplay.length)}
                          checked={Object.values(selected).includes(true) && Object.values(selected).length === toDisplay.length && !Object.values(selected).includes(false) ? true : false}
                          onChange={selectAll}
                        />
                      </StyledTableCell>
                      <StyledTableCell >Courses</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ?
                      <StyledTableRow>
                        <StyledTableCell colSpan={2} className={classes.preLoaderCell}>
                          <CircularProgress />
                        </StyledTableCell>
                      </StyledTableRow>
                      : toDisplay.map((program, index) => (
                        <StyledTableRow key={`row-${index}`} onClick={() => selectRow(index + 1)}>
                          <StyledTableCell key={`selection-${index}`}>
                            <Checkbox
                              className={classes.selectionCheckBox}
                              readOnly
                              color='primary'
                              key={`selectioncheck-${index}`}
                              checked={courses && courses.length > 0 && selected && selected[(index + 1)] ? true : false}
                            />
                          </StyledTableCell>
                          <StyledTableCell key={`val-${index}`}>{program.name}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container direction='row' spacing={1}>
            <Grid
              item
              alignContent='flex-start'
              alignItems='flex-start'
              justify='flex-start'
            >
              <Button
                size='small'
                color='primary'
                variant='contained'
                type='button'
                onClick={addCourseToProgram}
              >
                ADD TO PROGRAM
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>      
    </React.Fragment>
  );
};

export default AddCreditCourse;
