import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CampusForCurrentUserAutoComplete from "../../../components/AutoComplete/CampusForCurrentUserAutoComplete";
import { Grid } from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import { ICampusAttendanceReportParams } from "../../../interfaces/attendance/ICampusAttendance";
import * as campusAttendanceApi from "../../../api/attendance/campusAttendanceApi";
import { ReportOutput } from "../../../enums/ReportOutput";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import StudentGroupsAutoComplete from "../../../components/AutoComplete/StudentGroupsAutoComplete";
import { withStyles } from "@material-ui/core";

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {},
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      minHeight: "100%",
      height: "100%",
      maxWidth: "1050px",
      padding: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    startDateField: {
      width: "100%",
    },
    fullWidthElement: {
      width: "100%",
    },
  })
);

const CampusAttendanceReport = (props: any) => {
  const { report } = props;
  let reportOverview = { report: report } as IReportOverview;
  const classes = useStyles({});
  const [preview, setPreview] = React.useState<any>();
  const [previewLoader, setPreviewLoader] = React.useState<boolean>();

  const data = useForm<any>({ mode: "onBlur" });
  const [model, setModel] = React.useState<ICampusAttendanceReportParams>({
    campusIds: [],
    startDate: undefined as Date | undefined,
    endDate: undefined as Date | undefined,
    studentGroupIds: [],
    attendancePercent: null,
    conditionalOperator: null,
    exportType: 1 as ReportOutput,
  });

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: (values: any, key: string) => Array<string>
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value, "value") : value;
    (updatedModel as any)[fieldId] = newValue;
    if (fieldId === "campusIds") {
      (updatedModel as any)["studentGroupIds"] = [];
    }
    setModel({ ...updatedModel });
  };

  const { handleSubmit } = data;

  const exportTypes: Array<DropDownListItem> = [
    { text: "PDF", id: "1" },
    { text: "Excel", id: "2" },
    { text: "Word", id: "3" },
  ];

  const onSubmit = async (isPreview: any) => {
    let params = (await getReportParameters()) as ICampusAttendanceReportParams;
    const validation = await data.triggerValidation();
    if (validation) {
      if (isPreview) {
        setPreviewLoader(true);
        campusAttendanceApi
          .generateCampusAttendanceReport(params, isPreview)
          .then((res: any) => {
            if (res) {
              res.arrayBuffer().then((buffer: any) => {
                setPreview(buffer);
                setPreviewLoader(false);
              });
            } else setPreviewLoader(false);
          });
      } else await campusAttendanceApi.generateCampusAttendanceReport(params);
    }
  };

  const exportTypeAutoComplete = {
    options: exportTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const filterHandler = (data: any) => {
    if (data) {
      setModel(data);
    } else {
      setModel({
        campusIds: [],
        startDate: undefined as Date | undefined,
        endDate: undefined as Date | undefined,
        studentGroupIds: [],
        attendancePercent: null,
        conditionalOperator: null,
        exportType: 1 as ReportOutput,
      });
    }
  };
  const setCampuses = (values: any, key: string) => {
    let newValues: string[] = [];
    if (values !== null) {
      if (Array.isArray(values)) {
        newValues = values.map((gr: any) => gr[key]);
      } else {
        newValues.push(values.value);
      }
    }
    return newValues;
  };

  const setStudentGroup = (StudentGroup: any) => {
    let newStudentGroup: string[] = [];
    if (StudentGroup !== null) {
      if (Array.isArray(StudentGroup)) {
        newStudentGroup = StudentGroup.map((gr: any) => gr.value);
      } else {
        newStudentGroup.push(StudentGroup.value);
      }
    }
    return newStudentGroup;
  };

  const getReportParameters = async () => {
    const success = await data.triggerValidation();
    if (model && success) {
      let startDate = model.startDate ? model.startDate : undefined;
      let endDate = model.endDate ? model.endDate : undefined;
      let params: ICampusAttendanceReportParams = {
        campusIds: model.campusIds,
        startDate: startDate,
        endDate: endDate,
        studentGroupIds: model.studentGroupIds,
        attendancePercent: model.attendancePercent,
        conditionalOperator: model.conditionalOperator,
        exportType: model.exportType,
      };
      return params;
    }
    return {} as ICampusAttendanceReportParams;
  };

  const reportTypes: Array<DropDownListItem> = [
    { text: "Greater than", id: ">" },
    { text: "Less than", id: "<" },
  ];
  const reportTypeAutoComplete = {
    options: reportTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
    const item = items.find((opt) => {
      if (opt.id === value) return opt;
    });
    return item || ({} as DropDownListItem);
  };

  let parameters = (
    <div className={classes.root}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={1}>
            <Grid item md={9} sm={9} xs={12}>
              <CampusForCurrentUserAutoComplete
                id="campusIds"
                name="campusIds"
                label="Campus(es) *"
                multiple
                includeAllValue={true}
                showIncludeAllValue={true}
                filterHandle={(v: any) => {
                  handleFieldOnChange("campusIds", v, setCampuses);
                }}
                valueFilter={
                  model.campusIds
                    ? {
                        key: "value",
                        values: model.campusIds,
                      }
                    : undefined
                }
                error={!!data.errors.campusIds}
                inputRef={data.register({
                  validate: (_value) =>
                    model.campusIds && model.campusIds.length > 0
                      ? true
                      : false,
                })}
                helperText={
                  data.errors.campusIds ? "Campus(es) are required." : undefined
                }
              />
            </Grid>
            <Grid item md={3} sm={3} xs={12}>
              <Autocomplete
                {...exportTypeAutoComplete}
                autoComplete
                includeInputInList
                onChange={(e: any, value: any) => {
                  handleFieldOnChange(
                    "exportType",
                    value ? value : undefined,
                    (v: any) => v?.id
                  );
                }}
                value={getSelectedItem(
                  exportTypes,
                  model.exportType.toString()
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Export Type"
                    name="exportType"
                    error={!!data.errors.exportType}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.exportType
                        ? "Export Type is required."
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="startDate"
                  name="startDate"
                  label="Date from *"
                  value={model.startDate ? model.startDate : null}
                  onChange={(e: any, value: any) =>
                    handleFieldOnChange("startDate", value ? value : undefined)
                  }
                  className={classes.startDateField}
                  KeyboardButtonProps={{
                    "aria-label": "report date",
                  }}
                  error={!!data.errors["startDate"]}
                  innerRef={(ref: any) => {
                    data.register("startDate", {
                      required: true,
                      validate: {
                        beforeEndDate: (value) =>
                          new Date(value) <=
                            new Date(data.getValues()["endDate"]) ||
                          "Start Date must be before end date",
                      },
                    });
                    data.setValue("startDate", model.startDate);
                  }}
                  helperText={
                    data.errors["startDate"]
                      ? (data.errors["startDate"] as FieldError).message
                      : undefined
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="endDate"
                  name="endDate"
                  label="Date to *"
                  value={model.endDate ? model.endDate : null}
                  onChange={(e: any, value: any) =>
                    handleFieldOnChange("endDate", value ? value : null)
                  }
                  className={classes.startDateField}
                  KeyboardButtonProps={{
                    "aria-label": "end date",
                  }}
                  error={!!data.errors["endDate"]}
                  innerRef={(ref: any) => {
                    data.register("endDate", {
                      required: true,
                      validate: {
                        beforeEndDate: (value) =>
                          new Date(value) >=
                            new Date(data.getValues()["startDate"]) ||
                          "End Date must be after Start date",
                      },
                    });
                    data.setValue("endDate", model.endDate);
                  }}
                  helperText={
                    data.errors["endDate"]
                      ? (data.errors["endDate"] as FieldError).message
                      : undefined
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <StudentGroupsAutoComplete
                className={classes.fullWidthElement}
                params={{
                  fullWidth: true,
                }}
                name="studentGroupIds"
                campusList={model?.campusIds}
                label="Groups (If non selected report will show all Groups)"
                includeAllValue={true}
                showIncludeAllValue={true}
                defaultOptionText={"All Student groups"}
                filterHandle={(value: any) =>
                  handleFieldOnChange("studentGroupIds", value, setStudentGroup)
                }
                valueFilter={
                  model.studentGroupIds
                    ? {
                        key: "value",
                        values: model.studentGroupIds,
                      }
                    : undefined
                }
                multiple={true}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <ThemeTextField
                type="number"
                inputProps={{
                  step: 0.1,
                  min: 0.1,
                  max: 100,
                }}
                id="attendancePercent"
                name="attendancePercent"
                className={classes.fullWidthElement}
                label="Attendance %"
                value={model.attendancePercent ?? ""}
                onChange={(v: any) => {
                  handleFieldOnChange(
                    "attendancePercent",
                    v ? v.target.value : undefined
                  );
                }}
                error={!!data.errors.attendancePercent}
              />
            </Grid>
            <Grid item md={3} sm={3} xs={12}>
              <Autocomplete
                {...reportTypeAutoComplete}
                autoComplete
                includeInputInList
                onChange={(e: any, value: any) => {
                  handleFieldOnChange(
                    "conditionalOperator",
                    value ? value : undefined,
                    (v: any) => v?.id
                  );
                }}
                value={getSelectedItem(
                  reportTypes,
                  model.conditionalOperator ?? ""
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Condition"
                    name="conditionalOperator"
                    error={!!data.errors.conditionalOperator}
                    inputRef={data.register({
                      validate: {
                        requiredCondition: (value: string) =>
                          (model.attendancePercent == null && value == "") ||
                          (model.attendancePercent != null && value != "") ||
                          "Conditional operator is required.",
                      },
                    })}
                    helperText={
                      data.errors.conditionalOperator
                        ? (data.errors.conditionalOperator as FieldError)
                            .message
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </FormContext>
    </div>
  );
  reportOverview.parameters = parameters;

  return (
    <ReportOverview
      reportOverview={reportOverview}
      filterHandler={filterHandler}
      getReportParameters={getReportParameters}
      exportHandler={onSubmit}
      preview={preview}
      previewLoader={previewLoader}
    />
  );
};
export default CampusAttendanceReport;
