import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { Typography, CardContent, Card, Button } from "@material-ui/core";
import * as StudentSapApi from "../../../../src/api/student/sap/StudentSapApi";
import { useSelector } from "react-redux";
import clsx from "clsx";
import AccessManager from "../../../components/security/AccessManager";
import { security } from "../../../constants/Security/securityConstants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: "100%",
    },
    header: {
      height: "40px",
      padding: "10px",
      fontWeight: 600,
      width: "100%",
      backgroundColor: theme.palette.secondary.dark,
    },
    cardTitle: {
      fontSize: 17,
      color: "Black",
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardSeparator: {
      marginBottom: theme.spacing(1),
    },
    button: {
      padding: theme.spacing(1),
      margin: theme.spacing(2, 0),
    },
    buttonColorPrimary: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
      textDecoration: "bold",
      padding: theme.spacing(1),
      whiteSpace: "nowrap",
      margin: theme.spacing(2, 0),
    },
  })
);

function ResultSAP() {
  const classes = useStyles();
  const [TitleIVData, setTitleIVData] = React.useState([] as any);
  const [isCreditHour, setIsCreditHour] = React.useState(false);
  const [selected, setSelected] = React.useState([] as any);
  const isSelected = (sapCheckResultsId: any) =>
    selected.indexOf(sapCheckResultsId) !== -1;

  const selectAllClick = (event: any) => {
    if (event.target.checked) {
      const selected = TitleIVData.map((n: any) => n.sapCheckResultsId);
      setSelected(selected);
      return;
    }
    setSelected([]);
  };
  const rowClick = (event: any, resultId: string) => {
    const selectedIndex = selected.indexOf(resultId);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, resultId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const student = useSelector((state: any) => state.student);

  React.useEffect(() => {
    if (student.selectedEnrollment && student.selectedEnrollment.stuEnrollId) {
      let enrollmentId = student.selectedEnrollment.stuEnrollId;
      StudentSapApi.getSAPResultsForStudent(enrollmentId).then(
        (response: any) => {
          if (response) {
            setTitleIVData(response.data);
          }
        },
        (exception: any) => {}
      );
      setIsCreditHour(student?.selectedEnrollment?.isCreditHour);
    }
    setSelected([]);
  }, [student.selectedEnrollment]);

  const doPrint = () => {
    if (selected.length > 0) {
      let params = {
        StudentEnrollmentId: student.selectedEnrollment.stuEnrollId,
        CheckResultIdList: selected,
        StudentName: student.studentName,
      };
      StudentSapApi.PrintSAPResults(params).then(
        (response: any) => {},
        (exception: any) => {}
      );
    }
  };

  return (
    <Card className={classes.cardSeparator}>
      <Typography className={clsx(classes.cardTitle)}>
        <div className={classes.headerText}>Results</div>
      </Typography>
      <CardContent>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={1}>
                  Print
                </TableCell>
                <TableCell align="center" colSpan={5}>
                  Quantitative
                </TableCell>
                <TableCell align="center" colSpan={3}>
                  Qualitative
                </TableCell>
                <TableCell align="center" rowSpan={2}>
                  Result
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  <Checkbox
                    value="uncontrolled"
                    color="primary"
                    onClick={selectAllClick}
                    inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                  />
                </TableCell>
                <TableCell align="right">
                  {isCreditHour ? "Credits Attempted" : "Hours"}
                </TableCell>
                <TableCell align="right">
                  {isCreditHour ? "Credits Earned" : "Scheduled"}
                </TableCell>

                <TableCell align="right"> {isCreditHour ? "Actual Pace%" : "Actual"}</TableCell>
                
                <TableCell align="right">{isCreditHour ? "Required Pace%" : "Required"}</TableCell>

                <TableCell align="right">Satisfied</TableCell>
                <TableCell align="right">
                  {isCreditHour ? "GPA" : "Grade"}
                </TableCell>
                <TableCell align="right">Required</TableCell>
                <TableCell align="right">Satisfied</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {TitleIVData.map((row: any) => {
                const isRowSelected = isSelected(row.sapCheckResultsId);
                const QuantSatisfied =
                  row.percentCompleted >= row.quantitativeRequired
                    ? "Yes"
                    : "No";
                const QualSatisfied =
                  row.grade >= row.qualitativeRequired ? "Yes" : "No";
                return (
                  <TableRow
                    key={row.sapCheckResultsId}
                    onClick={(event) => rowClick(event, row.sapCheckResultsId)}
                    role="checkbox"
                    selected={isRowSelected}
                  >
                    <TableCell align="center">
                      <Checkbox
                        value="uncontrolled"
                        color="primary"
                        inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                        checked={isRowSelected}
                      />
                    </TableCell>
                    <TableCell align="center">
                      {isCreditHour ? row.creditAttempted : row.hoursEarned}
                    </TableCell>
                    <TableCell align="center">
                      {isCreditHour ? row.creditEarned : row.hoursScheduled}
                    </TableCell>
                    <TableCell align="center">{row.percentCompleted}</TableCell>
                    <TableCell align="center">
                      {row.quantitativeRequired}
                    </TableCell>
                    <TableCell align="center">{QuantSatisfied}</TableCell>
                    <TableCell align="center">
                      {parseFloat(row.grade).toFixed(2)}
                    </TableCell>
                    <TableCell align="center">
                      {row.qualitativeRequired}
                    </TableCell>
                    <TableCell align="center">{QualSatisfied}</TableCell>
                    <TableCell align="center">{row.sapStatus}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <AccessManager
          allowedPermissions={[security.permissions.reports.printSAPNotices]}
          renderNoAccess={() => (
            <Button
              disabled={true}
              onClick={() => {
                doPrint();
              }}
              size="small"
              className={classes.button}
              type="button"
              color="primary"
            >
              Print
            </Button>
          )}
        >
          <Button
            className={classes.buttonColorPrimary}
            onClick={() => {
              doPrint();
            }}
            size="small"
            type="button"
          >
            Print
          </Button>
        </AccessManager>
      </CardContent>
    </Card>
  );
}
export default ResultSAP;
