import React, { useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import AdvantageViewer from "../../../components/Common/AdvantageViewer";
import getAdvantageLink from "../../../utils/getAdvantageLink";
import { useDispatch, useSelector } from "react-redux";
import { Enrollment } from "../../../interfaces/student/academics/Enrollment";
import { Redirect } from "react-router-dom";
import * as StatusCodesApi from "../../../api/setup/catalog/statusCodesApi";
import { ActionResult } from "../../../interfaces/common/api/ActionResult";
import { keys } from "../../../constants/urlparameters.contants";
import { StudentProfile } from "../../../interfaces/student/profile/StudentProfile";
import * as actionTypes from "../../../constants/actions.constants";
import * as studentSearchApi from "../../../api/search/studentSearchApi";
import * as userCampusApi from "../../../api/user/userCampusApi";
import { StudentSearchModel } from "../../../interfaces/student/search/SearchModel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      paddingTop: theme.spacing(3),
      overflowX: "hidden",
      overflowY: "auto",
    },
  })
);

const Termination = (props: any) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state: any) => state.session.user);
  const [redirectState, setRedirectState] = React.useState<string | null>(null);
  const advantageLink = getAdvantageLink(props.location.pathname);
  const studentId = useSelector<any, any>(
    (state: any) => state.student.studentId
  );
  const [preSelectedStatusId, setPreSelectedStatusId] = useState<string>("");
  const [isTransferStudent, setIsTransferStudent] = React.useState<boolean>(
    false
  );
  const selectedEnrollment: Enrollment = useSelector(
    (state: any) => state.student.selectedEnrollment
  );

  const params = new URLSearchParams(window.location.search);
  const programId = params.get("toProgram");
  const programVersionId = params.get("toProgramVersion");
  const transferHours = Number(params.get("transferHours"));
  const transferDate = params.get("transferDate");
  const oldEnrollmentId = params.get("enrollmentId");
  const [paramsIFrame, setParamsIFrame] = useState<any>({
    cmpId: selectedEnrollment?.campusId,
    studentId: studentId,
    studentEnrollmentId: selectedEnrollment?.stuEnrollId,
    isTransferStudent: isTransferStudent,
    toProgram: programId,
    toProgramVersion: programVersionId,
    transferHours: transferHours,
    transferDate: transferDate,
    preSelectedStatusId: preSelectedStatusId,
    desc: "Termination",
    mod: "AR",
  })
  const GetPreSelectedStatusId = () => {
    StatusCodesApi.GetStatusBySystemStatusCode(19).then((response: ActionResult) => {
      if (response.resultStatus === 0) {
        setPreSelectedStatusId(response.result)


      }
    })
  }
  if (programId && programVersionId && transferDate && oldEnrollmentId) {
    if (!isTransferStudent) {
      setIsTransferStudent(true);
    }
    GetPreSelectedStatusId();
  }

  useEffect(() => {
    let updateModel = paramsIFrame;
    updateModel.isTransferStudent = isTransferStudent;
    updateModel.preSelectedStatusId = preSelectedStatusId;
    updateModel.cmpId = selectedEnrollment?.campusId;
    updateModel.studentId = studentId;
    updateModel.studentEnrollmentId = selectedEnrollment?.stuEnrollId;
    updateModel.toProgram = programId;
    updateModel.toProgramVersion = programVersionId;
    updateModel.transferHours = transferHours;
    updateModel.transferDate = transferDate;
    setParamsIFrame({ ...updateModel })
  }, [isTransferStudent, preSelectedStatusId, selectedEnrollment])


  let urlParams = new URLSearchParams(props.history.location.search);
  let campusIdQueryId = keys.campusId;
  let studentIdQueryId = keys.studentId;
  let studentIdQueryString = urlParams.get(studentIdQueryId);
  let campusIdQueryString = urlParams.get(campusIdQueryId);
  // let reloadEnrollmentSelector = urlParams.get(reloadEnrollmentSelectorId);

  const dispatchStudentSelect = (data: StudentProfile) => {
		let urlParams = new URLSearchParams(props.history.location.search);
		let studentIdQueryId = keys.studentId;
		let campusIdQueryId = keys.campusId;
		urlParams.set(
			campusIdQueryId,
		   (data.enrollments as any)[0]?.campusId ?? campusIdQueryString as any
		);
		urlParams.set(studentIdQueryId, data.studentId as any);
		dispatch({
			type: actionTypes.userStateConstants.USER_STATE_SET_SELECTED_STUDENT,
			data: {
				userId: loggedInUser.userId,
				studentProfile: data,
			},
		});

		dispatch({
			type: actionTypes.studentProfileConstants.SESSION_SET_STUDENT_PROFILE,
			studentProfile: data,
		});

		dispatch({
			type: actionTypes.userStateConstants.USER_STATE_SET_SELECTED_CAMPUS,
			data: {
				userId: loggedInUser.userId,
				selectedCampus: (data.enrollments as any)[0]?.campusId ?? campusIdQueryString,
			},
		});
	};

  const handleEnrollmentSelectorLoad = () => {
    if (!campusIdQueryString) {
      studentSearchApi.getMRU().then(
        (rv: any) => {
          //if no student is selected, select MRU student
          if (rv.result && rv.result.length > 0) {
            dispatchStudentSelect(rv.result[0]);
          } else {
            // no mru, then select first campus user has access to
            userCampusApi.getUserCampuses().then(
              (data: any) => {
                if (data && data.length > 0) {
                  dispatch({
                    type:
                      actionTypes.userStateConstants
                        .USER_STATE_SET_SELECTED_CAMPUS,
                    data: {
                      userId: loggedInUser.userId,
                      selectedCampus: data[0].id,
                    },
                  });
                }
              },
              (e) => { }
            );
          }
        },
        (e: any) => { }
      );
    } else if (studentIdQueryString && campusIdQueryString)
      studentSearchApi
        .studentProfileSearch({
          Filter: studentIdQueryString,
          ShowAll: true,
          CampusId: campusIdQueryString,
          creditHoursOnly: false,
        } as StudentSearchModel)
        .then(
          (rv: any) => {
            if (rv.result.length > 0) {
              dispatchStudentSelect(rv.result[0]);
            }
          },
          (e) => { }
        );
  }

  const handleOnParentRefresh = () => {
    handleEnrollmentSelectorLoad();
  }

  const handleCallBack = () => {
    handleEnrollmentSelectorLoad();
    const params = new URLSearchParams(window.location.search);
    const toProgram = params.get("toProgram");
    const toProgramVersion = params.get("toProgramVersion");
    const transferHours = params.get("transferHours");
    const transferDate = params.get("transferDate");
    const enrollmentId = params.get("enrollmentId");


    if (
      toProgram &&
      toProgramVersion &&
      transferHours &&
      transferDate &&
      enrollmentId
    ) {
      const newQueryString =
        "?toProgram=" +
        toProgram +
        "&toProgramVersion=" +
        toProgramVersion +
        "&transferHours=" +
        transferHours +
        "&transferDate=" +
        transferDate +
        "&enrollmentId=" +
        enrollmentId +
        "&isTransferred=true";
      if (toProgramVersion && transferHours && transferDate && enrollmentId) {
        setRedirectState("/student/newEnrollment" + newQueryString);
      }

      //create api to get value of transferred
    }
    else {
      setRedirectState('/dashboard?' + params.toString())
    }
  };
  if (redirectState) {
    return <Redirect to={redirectState} />;
  }
  return (
    <div className={classes.root}>
      <AdvantageViewer
        url={advantageLink}
        onCallBack={handleCallBack}
        onParentRefresh={handleOnParentRefresh}
        params={paramsIFrame}
      />
    </div>
  );
};

export default Termination;
