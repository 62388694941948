import * as React from 'react';
import { useSelector } from 'react-redux'
import LogRocket from "logrocket";
import { bool } from 'prop-types';


export default (props: any) => {
    const { children } = props;
    const [initialized, setInitialized] = React.useState<boolean>(false);
    const user = useSelector((state: any) => state.session?.user);
    const appConfig = useSelector((state: any) => state.session?.appConfig);

    const logRocketKey = appConfig && appConfig.appConfig ? appConfig.appConfig.LogRocketKey : undefined;

    React.useEffect(() => {
        
        if (logRocketKey) {
            LogRocket.init(logRocketKey);
            setInitialized(true);
        }
    }, [logRocketKey])

    React.useEffect(() => {

        if (user && user.userId && user.token) {

            LogRocket.identify(user.userId, {
                name: user.fullName,
                email: user.email,
            });
        }

    }, [user])


    return (
        <React.Fragment>{children}</React.Fragment>      
    )
}



