import React from "react";
import { makeStyles, createStyles, Card, Tabs, Tab } from "@material-ui/core";
import ProgramCatalogDetailsTab from "./Details/ProgramCatalogDetailsTab";
import ProgramCatalogChargingMethodTab from "./ChargingMethod/ProgramCatalogChargingMethodTab";
import ProgramCatalogDefinitionTab from "./Definition/ProgramCatalogDefinitionTab";
import CustomCardTitle from "../../../../../interfaces/common/card/CustomCardTitle";
import { EmptyGuid } from "../../../../../utils/constants";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%",
    },
    cardWrapper: {},
    tabController: {
      backgroundColor: "transparent",
      textTransform: "uppercase",
    },
    tabIndicator: {
      backgroundColor: theme.palette.site.primary,
    },
    tabSelected: { fontWeight: "bold" },
  })
);
type ProgramCatalogDetailsCardProps = {
  setSnackBar: any;
  programVersionId: string;
  newProgramVersionName: string;
  clearNewProgramProgramVersion: any;
  programId?: string;
  setRefreshData: any;
  refreshDetailData: any;
};
const ProgramCatalogDetailsCard = (props: ProgramCatalogDetailsCardProps) => {
  const classes = useStyles({});
  const [tab, setTab] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setTab(newValue);
  };
  return (
    <Card square={true} className={classes.cardWrapper}>
      <CustomCardTitle title={"Program Version Details - Credit"} />
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
        variant="fullWidth"
        classes={{
          indicator: classes.tabIndicator,
        }}
      >
        <Tab label="Details" classes={{ selected: classes.tabSelected }} />
        <Tab
          label="Definition"
          classes={{ selected: classes.tabSelected }}
          disabled={props.programVersionId === EmptyGuid}
        />
        <Tab
          label="Charging Method"
          classes={{ selected: classes.tabSelected }}
          disabled={props.programVersionId === EmptyGuid}
        />
      </Tabs>
      <ProgramCatalogDetailsTab
        hidden={tab !== 0}
        clearNewProgramProgramVersion={props.clearNewProgramProgramVersion}
        newProgramVersionName={props.newProgramVersionName}
        programVersionId={props.programVersionId}
        setSnackBar={props.setSnackBar}
        programId={props.programId}
        setRefreshData={props.setRefreshData}
        refreshDetailData={props.refreshDetailData}
      />
      <ProgramCatalogDefinitionTab
        hidden={tab !== 1}
        programVersionId={props.programVersionId}
        setSnackBar={props.setSnackBar}
        programId={props.programId}
      />
      <ProgramCatalogChargingMethodTab
        hidden={tab !== 3}
        programVersionId={props.programVersionId}
        setSnackBar={props.setSnackBar}
      />
    </Card>
  );
};

export default ProgramCatalogDetailsCard;
