import API from "../../apiWrapper";

const isDroppedDueToCovid19 = (
	stuEnrollId: string
) => {
	return API()
		.get("/AcademicRecords/StudentTermination/IsDroppedDueToCovid19", {
			params: {
				stuEnrollId
			}
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return false;
			}
		);
};

export const terminationApi = {isDroppedDueToCovid19};