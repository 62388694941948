
import API from "../../apiWrapper";
import {    IClassScheduleGridRow  } from "../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/Schedule/IScheduleGridRow";

export const upsertClassSchedule = (model: IClassScheduleGridRow) => {
    return API()
      .post("/SystemCatalog/ClassSchedule/UpsertClassSchedule", model, {
              responseType: "json",
          })
      .then(
        (res: any) => {
          if (res && res.data) {
            return res.data;
          }
        },
        (error: any) => {
          return error.response;
        }
      );
  };

  export const getScheduleDetailById = (scheduleId: string) => {
    return API()
      .get("/SystemCatalog/ClassSchedule/GetDetailById", {
        params: {
          scheduleId,
        }
      })
      .then(
        (res: any) => {
          if (res && res.data) {
            return res.data;
          }
        },
        (error: any) => {
          throw new Error(error.message);
        }
      );
  };