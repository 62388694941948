import API from "../apiWrapper";

export const GetEnrollmentsForWidget = (params: any) => {
  return API()
    .get("/AcademicRecords/GraduationStatus/GetEnrollmentsForWidget", {
      params: params,
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const UpdateEnrollmentStatus = (
  enrollmentList: any
) => {
  return API()
    .post(
      "/AcademicRecords/GraduationStatus/UpdateEnrollmentGraduationStatus",
      enrollmentList
    )
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetGraduationWidgetSettings = (params: any) => {
  return API()
    .get(
      "/SystemCatalog/GraduationStatusSettings/GetGraduationStatusSettingByCampusId",
      {
        params: params,
      }
    )
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetGraduationStatusDefaultSetting = () => {
  return API()
    .get(
      "/SystemCatalog/GraduationStatusSettings/GetGraduationStatusDefaultSetting",
      {}
    )
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const SaveGraduationWidgetSettings = (
  CampusId: string,
  modelList: any
) => {
  return API()
    .post(
      "/SystemCatalog/GraduationStatusSettings/SaveGraduationStatusSetting",
      modelList
    )
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};
