import { Action } from "redux";

export enum ActionLifeCycle {
    Success = 'Success',
    Failure = 'Failure',
    None = 'None',
    Request = 'Request'
}

export interface IGenericAction extends Action {
    data: any;
    lifeCycle: ActionLifeCycle;
}

export interface IAction<T> {
    type: string;
    data: T;
    lifeCycle: ActionLifeCycle;
}

export type ActionFunction = (...args: any[]) => any;
export type AsyncActionFunction = {
    request: ActionFunction;
    success: ActionFunction;
    failure: ActionFunction;
}

export type IActionBuilder<F extends ActionFunction> = (...params: Parameters<F>) => IAction<ReturnType<F>>;

export interface ActionFunctionsObject {
    [key: string]: ActionFunction;
}

export interface AsyncActionFunctionsObject {
    [key: string]: AsyncActionFunction;
}

export type ActionsFunctions<A extends AsyncActionFunctionsObject, S extends ActionFunctionsObject> = {
    asyncs?: A;
} & {
    sync: S
};

export type ActionBuilders<A extends AsyncActionFunctionsObject, S extends ActionFunctionsObject> = {
    [T in keyof S]: IActionBuilder<S[T]>;
} & {
        [T in keyof A]: IActionBuilder<A[T]['request']>;
    } & {
        saga: { [T in keyof A]: {
            success: IActionBuilder<A[T]['success']>;
            failure: IActionBuilder<A[T]['failure']>;
        }; }
    }

export type Types<A extends AsyncActionFunctionsObject, S extends ActionFunctionsObject> = {
    [T in keyof S]: string;
} & {
        [T in keyof A]: string;
    } & {
        saga: { [T in keyof A]: {
            success: string;
            failure: string;
        }; }
    };

export type AsyncState<A extends AsyncActionFunctionsObject, D extends object> = {
    data: D;
    loading: {
        [T in keyof A]?: boolean;
    }
};

export type ReducerCases<A extends AsyncActionFunctionsObject, S extends ActionFunctionsObject, D extends object> = {
    asyncs?:
    { [T in keyof A]: {
        request: (state: AsyncState<A, D>, action: IAction<ReturnType<A[T]['request']>>) => AsyncState<A, D>;
        success: (state: AsyncState<A, D>, action: IAction<ReturnType<A[T]['success']>>) => AsyncState<A, D>;
        failure: (state: AsyncState<A, D>, action: IAction<ReturnType<A[T]['failure']>>) => AsyncState<A, D>;
    };
    }
} & {
    sync: { [T in keyof S]: (state: AsyncState<A, D>, action: IAction<ReturnType<S[T]>>) => AsyncState<A, D>; }
};

export type Selector<A extends AsyncActionFunctionsObject ,D extends object> = (state: AsyncState<A, D>) => any;
export type Selectors<A extends AsyncActionFunctionsObject ,D extends object> = {
    [key: string]: Selector<A,D>;
}

export type GeneratedSelectors<A extends AsyncActionFunctionsObject ,D extends object, S extends Selectors<A,D>> = {
    [T in keyof S]: Selector<A,D>;
}

export type GenericActionBuilder = (...params: any[]) => IAction<any>;

export type BoundAction<AB extends  GenericActionBuilder> = (...params: Parameters<AB>) => any

export type ActionsBinding = {
    [key: string]: GenericActionBuilder;
}

export type BoundActionCreators<AC extends ActionsBinding> = {
    [T in keyof AC]: BoundAction<AC[T]>;
}
