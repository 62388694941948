import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import { Typography, Card, CardActions, CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { AutomaticChargingDetails } from "../../../../interfaces/student/academics/EnrollmentProgram";
import { useFormContext } from "react-hook-form";
import Button from "@material-ui/core/Button";
import IncrementsAutoComplete from "../../../../components/AutoComplete/IncrementsAutoComplete";

import * as programVersionApi from "../../../../api/setup/academics/programVersionApi";
import {
  IChargingMethodIncrement,
  CharginMethodIncrement,
} from "../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/CharginMethod/IChargingMethodIncrement";
import CustomIncrementModal from "./CustomIncrementModal";
import { EmptyGuid } from "../../../../utils/constants";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.paper.background,
      "& .MuiCardContent-root": {
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(2),
      },
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.paper.background,
    },
    cardTitle: {
      fontSize: 17,
      color: "Black",
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    fullWidthField: {
      width: "100%",
    },
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
      whiteSpace: "nowrap",
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
  })
);

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& .MuiFormLabel-root": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "80%",
      },
    },
  })
)(TextField);

type automaticChargingDetails = {
  automaticChargingDetails: AutomaticChargingDetails;
  setAutomaticCharging: any;
  programVersionId?: string;
  setSnackBar: (snackBarProps: any) => void;
  isNewEnrollment: boolean;
};

const AutomaticCharging = (props: automaticChargingDetails) => {
  const classes = useStyles({});
  const data = useFormContext();
  const { automaticChargingDetails, setAutomaticCharging } = props;
  const [billingMethodId, setBillingMethodId] = useState<string>("");
  const [doRefreshData, setDoRefreshData] = useState<boolean>(false);
  interface FilmOptionType {
    text: string;
    id: string;
  }
  const [model, setModel] = useState<IChargingMethodIncrement>(
    new CharginMethodIncrement()
  );
  const selectedEnrollment = useSelector(
    (state: any) => state.student.selectedEnrollment?.stuEnrollId
  );
  const [open, setOpen] = useState<boolean>(false);
  const handleFieldOnChange = (fieldId: string, value: any) => {
    let updatedModel = automaticChargingDetails;
    (updatedModel as any)[fieldId] = value;
    setAutomaticCharging(updatedModel);
  };
  const setIncrementId = (value: any) => {
    if (data) {
      let NewIncrementId = value;
      automaticChargingDetails.incrementId = NewIncrementId;
      setAutomaticCharging({ ...automaticChargingDetails });
    }
  };

  const getChargingMethodDetail = () => {
    if (props.programVersionId) {
      programVersionApi
        .GetProgramVersionChargingMethodInformation(props.programVersionId)
        .then((response: any) => {
          if (response) {
            if (response.resultStatus === 0) {
              let modelToUpdate = automaticChargingDetails;
              modelToUpdate.billingMethodDescrip = response.result.description;
              modelToUpdate.usesActualHours = response.result.usesActualHours;
              setBillingMethodId(response.result.id);
              setAutomaticCharging({ ...modelToUpdate });
            }
          }
        });
    }
  };

  useEffect(() => {
    getChargingMethodDetail();
  }, [props.programVersionId]);

  const onCustommizeIncrementClick = () => {
    setModel({ ...new CharginMethodIncrement() });
    setOpen(true);
  };

  return (
    <Card square={true}>
      <CustomIncrementModal
        model={model}
        setModel={setModel}
        open={open}
        setOpen={setOpen}
        setSnackBar={props.setSnackBar}
        isSaveNewCustomIncrement={props.isNewEnrollment}
        incrementId={automaticChargingDetails.incrementId ?? EmptyGuid}
        setIncrement={setIncrementId}
        setRefreshData={setDoRefreshData}
        usesActualHours={automaticChargingDetails.usesActualHours ?? false}
        billingMethodId={billingMethodId}
      />
      <Typography className={clsx(classes.cardTitle)}>
        &nbsp; Automatic Charging
      </Typography>
      <CardContent className={classes.cardContent}>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={4}>
            <ThemeTextField
              id="chargingMethodId"
              name="chargingMethodId"
              label="Charge Definition"
              className={classes.textField}
              value={automaticChargingDetails.billingMethodDescrip}
              onChange={(e: any) => {
                handleFieldOnChange("chargingMethodId", e.target.value);
              }}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <IncrementsAutoComplete
              filterHandle={(filterData: any) => {
                setIncrementId(filterData.value);
              }}
              refreshData={doRefreshData}
              billingMethodId={billingMethodId}
              studentEnrolmentId={selectedEnrollment}
              id="incrementId"
              name="incrementId"
              label="Charge Definition Version"
              valueFilter={
                automaticChargingDetails.incrementId
                  ? {
                      key: "value",
                      values: [automaticChargingDetails.incrementId],
                    }
                  : undefined
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="contained"
              color="primary"
              // type="submit"
              className={classes.button}
              type={"button"}
              onClick={onCustommizeIncrementClick}
            >
              CUSTOMIZE DEFINITION
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
};

export default AutomaticCharging;
