import { Campus } from "../../../__mocks__/mock_interfaces";
import IPolicies from "../../../interfaces/setup/policies/IPolicies";
import {IPoliciesDetails,IPoliciesDetails2} from "../../../interfaces/setup/policies/IPoliciesDetails";
import { IPoliciesSearch } from "../../../interfaces/setup/policies/IPoliciesSearch";
import API from "../../apiWrapper";
import { useSelector } from "react-redux";


//-------------------- Tab1

export const getPolicyList = async (
	params: IPoliciesSearch
): Promise<{ data: Array<IPolicies>; hasMoreData: boolean; total: number }> => {
	
	const response = await API().post("SystemCatalog/TitleIVSAP/GetListTab1", {
		search: params.search ?? "",
		take: params.take ?? 10,
		skip: params.skip ?? 0,
		showAll: params.showAll ?? false,
		sort: params.sort ?? "desc",
		campus: params.campus,
		id:params.id,
	});

	return response.data;

};

export const savePolicyExist = async (data: IPoliciesDetails): Promise<IPoliciesDetails> => {

	const response = await API().post<IPoliciesDetails>(
		"SystemCatalog/TitleIVSAP/Save",
		{
			...data,
		}
	);
	return response.data;
};

export const savePolicy = async (data: IPoliciesDetails): Promise<IPoliciesDetails> => {
	const response = await API().post<IPoliciesDetails>(
		"SystemCatalog/TitleIVSAP/Save",
		{
			...data,
			startDate:
				data.startDate &&
				`${data.startDate.getFullYear()}-${data.startDate.getMonth() + 1
				}-${data.startDate.getDate()}`,
			endDate:
				data.endDate &&
				`${data.endDate.getFullYear()}-${data.endDate.getMonth() + 1
				}-${data.endDate.getDate()}`,
		}
	);
	return response.data;
};

export const getDetails = async (id: string): Promise<IPoliciesDetails> => {
	const response = await API().get<IPoliciesDetails>(
		"SystemCatalog/TitleIVSAP/Details",
		{
			params: { id },
		}
	);
	return response.data;
};

export const deletePolicy = async (id: string): Promise<string> => {
	const response = await API().delete<string>(
		"SystemCatalog/TitleIVSAP/Delete",
		{
			params: { id },
		}
	);
	return response.data;
};

//-------------------- Tab2

export const getPolicyList2 = async (
	params: IPoliciesSearch
): Promise<{ data: Array<IPolicies>; hasMoreData: boolean; total: number }> => {

	const response = await API().post("SystemCatalog/TitleIVSAP/GetListTab2", {
		search: params.search ?? "",
		take: params.take ?? 10,
		skip: params.skip ?? 0,
		showAll: params.showAll ?? false,
		sort: params.sort ?? "desc",
		campus: params.campus,
		sapIdValue: params.sapIdValue,
	});
	return response.data;
};

export const savePolicyExist2 = async (data: IPoliciesDetails2): Promise<IPoliciesDetails2> => {

	const response = await API().post<IPoliciesDetails2>(
		"SystemCatalog/TitleIVSAP/Save2",
		{
			...data,
		}
	);
	return response.data;
};

export const savePolicy2 = async (data: IPoliciesDetails2): Promise<IPoliciesDetails2> => {
	const response = await API().post<IPoliciesDetails2>(
		"SystemCatalog/TitleIVSAP/Save2",
		{
			...data,
			startDate:
				data.startDate &&
				`${data.startDate.getFullYear()}-${data.startDate.getMonth() + 1
				}-${data.startDate.getDate()}`,
			endDate:
				data.endDate &&
				`${data.endDate.getFullYear()}-${data.endDate.getMonth() + 1
				}-${data.endDate.getDate()}`,
		}
	);
	return response.data;
};

export const getDetails2 = async (id: string): Promise<IPoliciesDetails2> => {
	const response = await API().get<IPoliciesDetails2>(
		"SystemCatalog/TitleIVSAP/Details2",

		{
			params: { id },
		}
	);
	return response.data;
};

export const deletePolicy2 = async (id: string): Promise<string> => {
	const response = await API().delete<string>(
		"SystemCatalog/TitleIVSAP/Delete2",
		{
			params: { id },
		}
	);
	return response.data;
};