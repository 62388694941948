import API from "../../apiWrapper";
import { ReportOutput } from "../../../enums/ReportOutput";
import { ISAPCheckListingByDateReportParams, ISAPCheckReportParams } from "../../../interfaces/reports/studentAccounts/ISAPCheckReportParams";

export const generateReport = (params: ISAPCheckReportParams,
	isPreview: boolean = false) => {

	return API()
		.post("/Reports/SAPCheckReport/GetSAPCheckReport", params, { responseType: "blob" }
		)
		.then(
			(res: any) => {
				if (res && res.data) {
					if (isPreview && ReportOutput.Pdf == params.exportType) {
						return res.data;
					} else {
						const url = window.URL.createObjectURL(new Blob([res.data]));
						const link = document.createElement("a");
						let exportType: number = Number(params.exportType);
						let extension = exportType === ReportOutput.Pdf ? "pdf" : "xls";
						const fileName = "SAPCheckReport" + new Date().toISOString().replace('T', ' ').replace(/\..*$/, '').split(' ')[0].split('-').join('') + "." + extension;

						link.href = url;
						link.setAttribute("download", fileName);
						document.body.appendChild(link);
						link.click();
					}
				}
			},
			(error: any) => {
				return null;
			}
		);
};

export const generateSAPCheckListingByDateReport = (params: ISAPCheckListingByDateReportParams,
	isPreview: boolean = false) => {

	return API()
		.post("/Reports/SAPCheckReport/GetSAPCheckListingByDateReport", params, { responseType: "blob" }
		)
		.then(
			(res: any) => {
				if (res && res.data) {
					if (isPreview && ReportOutput.Pdf == params.exportType) {
						return res.data;
					} else {
						const url = window.URL.createObjectURL(new Blob([res.data]));
						const link = document.createElement("a");
						let exportType: number = Number(params.exportType);
						let extension = exportType === ReportOutput.Pdf ? "pdf" : "xls";
						const fileName = "SAPCheckListingByDateReport" + new Date().toISOString().replace('T', ' ').replace(/\..*$/, '').split(' ')[0].split('-').join('') + "." + extension;

						link.href = url;
						link.setAttribute("download", fileName);
						document.body.appendChild(link);
						link.click();
					}
				}
			},
			(error: any) => {
				return null;
			}
		);
};