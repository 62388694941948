import API from "../../apiWrapper";
import ITerminationSettings from "../../../interfaces/academicRecords/ITerminationSettings";

const getTerminationSettings = (
	campusId: string
) => {
	return API()
		.get("/AcademicRecords/StudentTermination/GetTerminationSettings", {
			params: {
				campusId
			}
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

const upsertTerminationSettings = (
	terminationSettings: ITerminationSettings
) => {
	return API()
		.post("/AcademicRecords/StudentTermination/UpsertTerminationSettings", terminationSettings)
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

export const terminationSettingsApi = {getTerminationSettings,upsertTerminationSettings};