import API from "../../apiWrapper";

export const getTitleIVResultsForStudent = (
  StudentId: string,
  EnrollmentId: string
) => {
  return API()
    .post("/AcademicRecords/TitleIVSAP/GetTitleIVResultsForStudent", {
      StudentId,
      EnrollmentId,
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const PlaceStudentOnTitleIVProbation = (FASAPCheckResultsId: string) => {
  return API()
    .get("/AcademicRecords/TitleIVSAP/PlaceStudentOnTitleIVPrbation", {
      params: {
        FASAPCheckResultsId: FASAPCheckResultsId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const getSAPResultsForStudent = (studentEnrollmentId: string) => {
  return API()
    .get("/AcademicRecords/SAP/GetSAPResultsForStudent", {
      params: {
        studentEnrollmentId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const printTitleIVResults = (params: any) => {
  return API()
    .post("/Reports/ResultSAPReport/GetResultSAPTitleIVReport", params, {
      responseType: "blob",
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          let newDate = new Date();
          var toDay =
            newDate.getMonth() +
            1 +
            "-" +
            newDate.getDate() +
            "-" +
            newDate.getFullYear();
          let fileName =
            "SAP_Result_Title_IV-" +
            params.StudentName.split(" ").join("_") +
            "-" +
            toDay +
            ".pdf";
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        }
        return res;
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const PrintSAPResults = (params: any) => {
  return API()
    .post("/Reports/ResultSAPReport/GetResultSAPReport", params, {
      responseType: "blob",
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          let newDate = new Date();
          var toDay =
            newDate.getMonth() +
            1 +
            "-" +
            newDate.getDate() +
            "-" +
            newDate.getFullYear();
          let fileName =
            "SAP_Result_" +
            params.StudentName.split(" ").join("_") +
            "-" +
            toDay +
            ".pdf";
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        }
        return res;
      },
      (error: any) => {
        return error.response;
      }
    );
};


export const GetTitleIVNoticesForPrinting = (campusId: string) => {
  return API()
    .get("/AcademicRecords/TitleIVSAP/GetTitleIVNoticesForPrinting", {
      params: {
        campusId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetSAPWidgetData = (campusId: string, sapType: string | null) => {
  return API()
    .get("/AcademicRecords/SAPWidget/GetSAPResultsForWidget", {
      params: {
        campusId,
        sapType,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const PrintTitleIVNoticeReport = (params: any) => {
  return API()
    .post("/Reports/TitleIVNoticeReport/PrintTitleIVNoticeReport", params, {
      responseType: "blob",
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          let newDate = new Date();
          var toDay =
            newDate.getMonth() +
            1 +
            "-" +
            newDate.getDate() +
            "-" +
            newDate.getFullYear();
          let fileName =
            "TitleIV_SAP" +
            "-" +
            toDay +
            ".pdf";
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        }
        return res;
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const PrintSAPNoticeReport = (params: any) => {
  return API()
    .post("/Reports/NonTitleIVNoticeReport/PrintNonTitleIVNoticeReport", params, {
      responseType: "blob",
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          let newDate = new Date();
          var toDay =
            newDate.getMonth() +
            1 +
            "-" +
            newDate.getDate() +
            "-" +
            newDate.getFullYear();
          let fileName =
            "NonTitleIV_SAP" +
            "-" +
            toDay +
            ".pdf";
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        }
        return res;
      },
      (error: any) => {
        return error.response;
      }
    );
};