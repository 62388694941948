import React from 'react';
import {
  makeStyles,
  Card,
  createStyles,
  CardContent,
  Grid,
  Paper,
  Table,
  withStyles,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  Switch,
  IconButton,
  CircularProgress,
  Typography
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import clsx from "clsx";
import CustomCardTitle from '../../../../../../interfaces/common/card/CustomCardTitle';
import ICreditProgramCourse, { IProgramCourseComponent } from '../../../../../../interfaces/setup/academics/ProgramDefinition/ICreditProgramCourse';
import InputField from '../../../../../../components/_Layout/Inputs/InputField';
import { InputType } from '../../../../../../constants/uiConstants/inputConstants';
import { getCourseComponents } from '../../../../../../api/setup/academics/courses';
import { useFormContext, FieldError } from 'react-hook-form';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

const StyledTableCell = withStyles((theme: any) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.site.secondary,
      fontSize: 17,
      color: theme.palette.black,
      fontWeight: 'bold',
      boxShadow: '1px 1px 0px 0px rgba(0,0,0,0.2)',
      border: 'solid #e1e1e1',
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderTopWidth: 0,
      borderBottomWidth: 0,
      '&:last-child': { borderRightWidth: 0 },
      '&:first-child': { borderLeftWidth: 0 },
    },
    body: {
      fontSize: 16,
      color: theme.palette.black,
      fontWeight: 500,
      textAlign: 'left'
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: any) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.white,
      '&:hover': {
        backgroundColor: theme.palette.white,
      }
    }
  }),
)(TableRow);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: 'bold',
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: '100%',
    },
    saveButton: {
      width: 90,
    },
    tableContainer: {
      maxHeight: '400px'
    },
    table: {
      width: '100%',
    },
    inputField: {
      height: '46px'
    },
    inputFieldCell: {
      width: '200px'
    },
    textCenter: {
      textAlign: 'center'
    },
    courseSelectionCell: {
      color: theme.palette.primary.light,
      cursor: 'pointer',
      fontWeight: 500,
      "&:hover": {
        textDecoration: 'underline'
      }
    },
    subHeading: {
      fontSize: 17,
      color: theme.palette.black,
      fontWeight: 500
    }
  })
);
type ProgramVersionCoursesSequenceProps = {
  loadingDefinition?: boolean;
  courses: ICreditProgramCourse[];
  editMode: { [key: string]: boolean };
  selectedCourse?: ICreditProgramCourse;
  setSelectedCourse: (course?: ICreditProgramCourse) => void;
  setEditMode: (editable: { [key: string]: boolean }) => void;
  setCourses: (courses: ICreditProgramCourse[]) => void;
  deleteCourse: (index: number) => void;
};

const ProgramCatalogCoursesSequence = (
  props: Readonly<ProgramVersionCoursesSequenceProps>
) => {
  const classes = useStyles({});

  const { courses, setCourses, editMode, setEditMode, selectedCourse, setSelectedCourse } = props;
  const data = useFormContext();
  const [coursesBeforeEdit, setCoursesBeforeEdit] = React.useState<ICreditProgramCourse[]>([]);

  const [components, setComponents] = React.useState<IProgramCourseComponent[]>([]);
  const [loadingComponents, setLoadingComponents] = React.useState<boolean>(false);



  const handleFiledChange = (index: number, fieldId: 'sequence' | 'credit' | 'required', value?: boolean | number): void => {
    const program = courses && courses[index];
    if (program) {
      /* if ((fieldId === 'sequence' || fieldId === 'weight') && typeof value === 'number') {
        program[fieldId] = value;
      } else  */
      
      if (fieldId === 'required' && typeof value === 'boolean') {
        program[fieldId] = value;
      }
      setCourses([...courses]);
    }
  }

  const handleCancel = (courseId: string, toggle: boolean, index: number) => {
    const program = courses && courses[index];

    if (program) {
      let cancelledCourse = coursesBeforeEdit && coursesBeforeEdit.find(m => m.courseId == courseId);

      if (cancelledCourse && (
        program.required != cancelledCourse.required //||
        //program.sequence != cancelledCourse.sequence ||
        //program.weight != cancelledCourse.weight
      )) {
        program.required = cancelledCourse.required;
       // program.sequence = cancelledCourse.sequence;
        //program.weight = cancelledCourse.weight;
        setCourses([...courses]);
      }

      setCourses([...courses]);
    }

    setEditMode({ ...toggleEdit, [courseId]: toggle });
  }

  const toggleEdit = async (index: string, toggle: boolean, rowIndex: number): Promise<void> => {
    if (toggle) {
      let course = courses && courses[rowIndex];
      let courseBeforeEdit = coursesBeforeEdit && coursesBeforeEdit.find(m => m.courseId == index);
      if (!courseBeforeEdit) {
        setCoursesBeforeEdit([...coursesBeforeEdit, { ...course }]);
      }
    }

    const valid = !toggle ? await data.triggerValidation() : true;
    if (valid) {
      setEditMode({ ...toggleEdit, [index]: toggle });
    }
  }

  const loadCourseComponents = async (): Promise<void> => {
    if (selectedCourse?.courseId) {
      try {
        setLoadingComponents(true);
        const components = await getCourseComponents(selectedCourse?.courseId);
        setComponents(components);
      } catch (_error) {
        setComponents([]);
      } finally {
        setTimeout(() => setLoadingComponents(false), 250);
      }
    }
  }

  React.useEffect(() => {
    if (!!selectedCourse) {
      loadCourseComponents();
    } else {
      setComponents([]);
    }
  }, [selectedCourse]);

  React.useEffect(() => {
    if (props.loadingDefinition) {
      setCoursesBeforeEdit([]);
    }
  }, [props.loadingDefinition]);

  return (
    <Card square={true} elevation={2} >
      <CustomCardTitle title='Program Version Courses'></CustomCardTitle>
      <CardContent>
        <Grid container direction="column" spacing={2}>
          <Grid
            item
            alignContent="flex-start"
            alignItems="flex-start"
            justify="flex-start"
          >
            <TableContainer component={Paper} square className={classes.tableContainer}>
              <Table stickyHeader size='small' className={classes.table}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Course</StyledTableCell>
                    <StyledTableCell>Required</StyledTableCell>
                   {/*  <StyledTableCell>Sequence</StyledTableCell> */}
                    <StyledTableCell>Credit</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.loadingDefinition ?
                    <StyledTableRow>
                      <StyledTableCell colSpan={6} className={classes.textCenter}>
                        <CircularProgress />
                      </StyledTableCell>
                    </StyledTableRow>
                    : courses.map((program, index) => (
                      <StyledTableRow key={`row-${index}`}>
                        <StyledTableCell className={classes.courseSelectionCell} key={`title-${index}`} onClick={() => setSelectedCourse(program.courseId !== selectedCourse?.courseId ? { ...program } : undefined)}>
                          {program.name}
                        </StyledTableCell>
                        <StyledTableCell
                          className={classes.textCenter}
                          key={`required-${index}`}>
                          <Switch
                            disabled={editMode[program.courseId ?? 0] ? false : true}
                            key={`required-check-${index}`}
                            checked={program?.required ?? false}
                            name="checkedB"
                            color="primary"
                            onChange={(_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleFiledChange(index, 'required', checked)}
                          />
                        </StyledTableCell>
                        {/* <StyledTableCell className={classes.inputFieldCell} key={`sequence-${index}`}>
                          <InputField
                            name={`sequence-field-${index}`}
                            key={`sequence-field-${index}`}
                            className={classes.inputField}
                            disabled={editMode[program.courseId] ? false : true}
                            type={InputType.NUMBER}
                            defaultValue={program.sequence}
                            inputRef={data.register({
                              required: "Sequence is Required."
                            })}
                            error={!!(data.errors && data.errors[`sequence-field-${index}`])}
                            helperText={
                              (!!(data.errors && data.errors[`sequence-field-${index}`]))
                                ? (data.errors[`sequence-field-${index}`] as FieldError).message
                                : undefined
                            }
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => handleFiledChange(index, 'sequence', e.target.value ? Number(e.target.value) : undefined)}
                          />
                        </StyledTableCell> */}
                        <StyledTableCell className={classes.inputFieldCell} key={`credit-${index}`}>
                          {program.credit}
                          {/* <InputField
                            name={`credit-field-${index}`}
                            key={`credit-field-${index}`}
                            className={classes.inputField}
                            disabled={editMode[program.courseId] ? false : true}
                            type={InputType.NUMBER}
                            decimal
                            defaultValue={program.credit}
                            error={!!(data.errors && data.errors[`credit-field-${index}`])}
                            helperText={
                              (!!(data.errors && data.errors[`credit-field-${index}`]))
                                ? (data.errors[`credit-field-${index}`] as FieldError).message
                                : undefined
                            }
                            inputRef={data.register({
                              required: "credit is Required."
                            })}
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => handleFiledChange(index, 'credit', e.target.value ? Number(e.target.value) : undefined)}
                          /> */}
                        </StyledTableCell>
                        <StyledTableCell className={clsx(classes.inputFieldCell, classes.textCenter)} key={`edit-${index}`}>
                          <IconButton onClick={() => toggleEdit(program.courseId, !editMode[program.courseId], index)} key={`edit-button-${index}`}>
                            {
                              editMode[program.courseId]
                                ?
                                <DoneIcon key={`save-icon-${index}`} />
                                :
                                <EditIcon key={`edit-icon-${index}`} />
                            }
                          </IconButton>
                          <IconButton onClick={() => handleCancel(program.courseId, !editMode[program.courseId], index)} key={`cancel-button-${index}`}>
                            {
                              editMode[program.courseId]
                                ?
                                <ClearOutlinedIcon />
                                :
                                null
                            }
                          </IconButton>
                        </StyledTableCell>
                        <StyledTableCell className={clsx(classes.inputFieldCell, classes.textCenter)} key={`delete-${index}`}>
                          <IconButton onClick={() => props.deleteCourse(index)} key={`delete-button-${index}`}>
                            <DeleteIcon key={`delete-icon-${index}`} />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {selectedCourse && !props.loadingDefinition && <React.Fragment><Grid item xs={12} lg={7} xl={7}>
            <TableContainer>
              <Table size="small" className={classes.table}>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell colSpan={2}>
                      <Typography className={classes.subHeading}>Components for the selected course</Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell className={classes.inputFieldCell}>
                      <Typography className={classes.subHeading}>Course:</Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography className={classes.subHeading}>{selectedCourse?.name}</Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
            <Grid item xs={12} lg={9} xl={9}>
              <TableContainer component={Paper} square className={classes.tableContainer}>
                <Table stickyHeader size='small' className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell >Component</StyledTableCell>
                      <StyledTableCell >Sequence</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loadingComponents ?
                      <StyledTableRow>
                        <StyledTableCell colSpan={2} className={classes.textCenter}>
                          <CircularProgress />
                        </StyledTableCell>
                      </StyledTableRow> :
                      components.map((component, index) => (
                        <StyledTableRow key={`component-${index}`}>
                          <StyledTableCell key={`component-title-${index}`}>{component.componentTitle}</StyledTableCell>
                          <StyledTableCell className={classes.inputFieldCell} key={`component-sequence-${index}`}>{component.sequence}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </React.Fragment>}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProgramCatalogCoursesSequence;