import * as React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import { DropDownListItem } from "../../interfaces/DropDownListItem";
import Autocomplete from "@material-ui/lab/Autocomplete";

const ThemeTextField = withStyles((theme: any) =>
    createStyles({
        root: {
            "& label.Mui-focused": {
                color: theme.palette.primary.white
            }
        }
    })
)(TextField);

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {},
        divWidth: {}
    })
);

const trackAttendanceByOptions: Array<DropDownListItem> = [
    { text: "By Program", id: "ByProgram" },
    { text: "By Class", id: "ByClass" },
];


interface IProps{
    onChange:(value:string) => void;
    value:string | null | undefined;
    error:boolean;
    inputRef:any;

}

const TrackAttendanceByAutoComplete = (props: IProps) => {
    const classes = useStyles({});
    const trackAttendanceByAutoComplete = {
        options: trackAttendanceByOptions,
        getOptionLabel: (option: DropDownListItem) => option.text
    };

    const getSelectedItem = (value: string | null | undefined) => {
        const item = trackAttendanceByOptions.find((opt) => {
          if (opt.id == value) return opt;
        });
        return item || ({} as DropDownListItem);
      };

    return (
        <div>
            <Autocomplete
                {...trackAttendanceByAutoComplete}
                autoComplete
                includeInputInList
                onChange={(e: any, value: any) => {
                    props.onChange(value ? value.id : undefined);
                }}
                value={getSelectedItem(props.value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Attendance Tracking *"
                        name="trackAttendanceBy"
                        error={props.error}
                        inputRef={props.inputRef}
                        helperText={!!props.error && props.error == true && "Attendance Tracking is required."}
                        fullWidth
                    />
                )}
            />
        </div>
    );
};
export default TrackAttendanceByAutoComplete;
