import React from 'react';
import {
    makeStyles,
    createStyles,
    Grid,
    Paper,
    Table,
    withStyles,
    TableCell,
    TableRow,
    TableContainer,
    TableHead,
    TableBody,
    IconButton,
    CircularProgress,
    Switch,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import IGradeBookComponent from '../../../../../../../interfaces/setup/academics/ProgramDefinition/IGradeBookComponent';
import InputField from '../../../../../../../components/_Layout/Inputs/InputField';
import GradeBookComponentTypesAutoComplete from '../../../../../../../components/AutoComplete/GradeBookComponentTypesAutoComplete';
import { FieldError, FormContext, useForm } from 'react-hook-form';
import { InputType } from '../../../../../../../constants/uiConstants/inputConstants';
import { EmptyGuid } from '../../../../../../../utils/constants';
import { IGradeComponentTypeOptionItem } from '../../../../../../../interfaces/setup/academics/IGradeComponentTypeOptionItem';
import { GetGradeBookComponentTypes } from '../../../../../../../api/setup/academics/gradeBookApi';


const StyledTableCell = withStyles((theme: any) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.site.secondary,
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            textAlign: 'left',
            boxShadow: '1px 1px 0px 0px rgba(0,0,0,0.2)',
            '&:first-child': {
                minWidth: theme.spacing(30),
                padding: theme.spacing(0, 1)
            }
        },
        body: {
            fontSize: 16,
            color: theme.palette.black,
            fontWeight: 400,
            cursor: 'pointer',
            textAlign: 'left',
            padding: theme.spacing(1),
            '&:first-child': {
                minWidth: theme.spacing(30),
                padding: theme.spacing(0, 1)
            }
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: any) =>
    createStyles({
        root: {
            '&:nth-of-type(even)': {
                backgroundColor: theme.palette.site.secondary,
            },
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.white,
            },
            '&:hover': {
                backgroundColor: theme.palette.site.secondary,
            }
        },
        body: {
            cursor: 'pointer'
        }
    }),
)(TableRow);

const useStyles = makeStyles((theme: any) =>
    createStyles({
        cardTitle: {
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            backgroundColor: theme.palette.site.secondary,
            padding: theme.spacing(0)
        },
        headerText: {
            float: 'left',
            padding: theme.spacing(1.5),
        },
        cardContent: {
            padding: theme.spacing(1, 3),
            height: '100%',
        },
        saveButton: {
            width: 90,
        },
        tableContainer: {
            maxHeight: '400px'
        },
        table: {
            width: '100%',
        },
        iconColor: {
            color: theme.palette.black
        },
        searchField: {
            '&::placeholder': {
                color: theme.palette.black
            }
        },
        selectionCheckBox: {
            padding: theme.spacing(0)
        },
        preLoaderCell: {
            textAlign: 'center',
            padding: theme.spacing(1)
        },
        dialogCloseButton: {
            float: "right",
        },
        courseSelectionCell: {
            color: theme.palette.paperSummarySchedulerTitle,
            cursor: 'pointer',
            fontWeight: 500
        }
    })
);
type GradeBookComponentsProps = {
    components: IGradeBookComponent[];
    campusId?: string;
    repeatedExamPolicy?: string;
    addGradeComponent: (component: IGradeBookComponent) => void;
    updateGradeComponent: (component: IGradeBookComponent, index: number) => void;
    deleteGradeComponent: (index: number) => void;
};

const GradeBookComponents = (
    props: Readonly<GradeBookComponentsProps>
) => {
    const classes = useStyles({});
    const [isLoading] = React.useState<boolean>(false);
    const [grdBookComponents, setgrdBookComponents] = React.useState<IGradeBookComponent[]>(props.components);
    const [newGrdBookComponents, setNewGrdBookComponents] = React.useState<IGradeBookComponent>({ required: true, mustPass: true, gradeBookComponentId: EmptyGuid });
    const [gradeBookComponentTypes, setGradeBookComponentTypes] = React.useState<IGradeComponentTypeOptionItem[]>([]);
    const [rowToEdit, setEditIndex] = React.useState<number>();
    const data = useForm<any>({ mode: "onBlur" });
    const [tempData, setTempData] = React.useState<IGradeBookComponent>();

    const setRowToEdit = (index?: number) => {
        setEditIndex(index);
        if (index && (index - 1) < grdBookComponents.length) {
            setTempData({ ...(grdBookComponents[index - 1]) });
        }
    }

    const handleNewComponentFieldChange = (fieldId: keyof IGradeBookComponent, value: any, index: number = 0) => {
        if (index === 0) {
            let updatedModel = newGrdBookComponents;
            (updatedModel as any)[fieldId] = value;
            setNewGrdBookComponents({ ...updatedModel });
        }
        else if (grdBookComponents.length >= index) {
            grdBookComponents[index - 1][fieldId] = value;
            setgrdBookComponents([...grdBookComponents]);
        }
        data.setValue(`${fieldId}[${index}]`, value);
        data.triggerValidation(`${fieldId}[${index}]`);
    };

    const onComponentSave = async (index: number) => {
        const valid = await data.triggerValidation([`gradeBookComponentTypeId[${index}]`, `weight[${index}]`, `minimumScore[${index}]`, `sequence[${index}]`]);
        if (valid) {
            if (index === 0) {
                props.addGradeComponent(newGrdBookComponents);
                setNewGrdBookComponents({ required: true, mustPass: true, gradeBookComponentId: EmptyGuid });
            }
            else {
                props.updateGradeComponent(grdBookComponents[index - 1], index - 1);
            }
            setRowToEdit(undefined);
            setTempData(undefined);

        }
    }

    const onComponentDelete = (index: number) => {
        if (index < grdBookComponents.length) {
            props.deleteGradeComponent(index);
        }
    }

    const onComponentCancel = () => {
        if (rowToEdit && tempData) {
            grdBookComponents[rowToEdit - 1] = { ...tempData };
            setgrdBookComponents([...grdBookComponents]);
            setRowToEdit(undefined);
            setTempData(undefined);

        }
    }

    const getGradeBookComponentTypes = (cId:string) =>{
        GetGradeBookComponentTypes(cId)
        .then(res => {
            setGradeBookComponentTypes(res);
        })
    }

    React.useEffect(() =>{
        if(!!props.campusId){
            getGradeBookComponentTypes(props.campusId);
        }else{
            setGradeBookComponentTypes([]);
        }
    },[props.campusId]);

    React.useEffect(() => {
        setgrdBookComponents(props.components);
    }, [props.components])

    return (
        <FormContext {...data}>
            <form>
                <Grid container direction="column" spacing={2} wrap="nowrap">
                    <Grid
                        item
                        alignContent="flex-start"
                        alignItems="flex-start"
                        justify="flex-start"
                    >
                        <TableContainer component={Paper} square className={classes.tableContainer}>
                            <Table stickyHeader size='small' className={classes.table} aria-label='customized table'>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell colSpan={3}>Grade Book Component</StyledTableCell>
                                        <StyledTableCell colSpan={3}>Type</StyledTableCell>
                                        <StyledTableCell colSpan={1}>Number</StyledTableCell>
                                        <StyledTableCell colSpan={1}>Weight</StyledTableCell>
                                        <StyledTableCell colSpan={1}>Minimum Score</StyledTableCell>
                                        <StyledTableCell colSpan={1}>Sequence</StyledTableCell>
                                        <StyledTableCell colSpan={3}>Repeated Exam</StyledTableCell>
                                        <StyledTableCell colSpan={1}>Required</StyledTableCell>
                                        <StyledTableCell colSpan={1}>Must Pass</StyledTableCell>
                                        <StyledTableCell colSpan={1}></StyledTableCell>
                                        <StyledTableCell colSpan={1}></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={3}>
                                            <GradeBookComponentTypesAutoComplete
                                                disabled={!!(rowToEdit && rowToEdit > 0)}
                                                options={gradeBookComponentTypes}
                                                id="gradeBookComponentTypeId[0]"
                                                name="gradeBookComponentTypeId[0]"
                                                requestParameter={{ campusId: props.campusId }}
                                                valueFilter={
                                                    newGrdBookComponents.gradeBookComponentTypeId
                                                        ? {
                                                            key: "value",
                                                            values: [newGrdBookComponents.gradeBookComponentTypeId],
                                                        }
                                                        : undefined
                                                }
                                                filterHandle={
                                                    (v: any) => {
                                                        handleNewComponentFieldChange("gradeBookComponentTypeId", v ? v.value : null);
                                                        handleNewComponentFieldChange("gardeBookComponentType", v ? v.text : null);
                                                        handleNewComponentFieldChange("sysTemComponentType", v ? v.systemComponentType : null)
                                                    }
                                                }
                                                error={!!(data.errors.gradeBookComponentTypeId && data.errors.gradeBookComponentTypeId[0])}
                                                inputRef={data.register({ required: true })}
                                                helperText={
                                                    (data.errors.gradeBookComponentTypeId && data.errors.gradeBookComponentTypeId[0])
                                                        ? (data.errors.gradeBookComponentTypeId[0] as FieldError).message
                                                        : undefined
                                                }
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell colSpan={3}>{newGrdBookComponents.sysTemComponentType}</StyledTableCell>
                                        <StyledTableCell colSpan={1}>
                                            <InputField
                                                disabled={!!(rowToEdit && rowToEdit > 0)}
                                                type={InputType.NUMBER}
                                                decimal
                                                id="number[0]"
                                                name="number[0]"
                                                defaultValue={newGrdBookComponents.number}
                                                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                    handleNewComponentFieldChange("number", e.target.value);
                                                }}
                                                error={!!(data.errors.number && data.errors.number[0])}
                                                inputRef={data.register({ })}
                                                helperText={
                                                    (data.errors.number && data.errors.number[0])
                                                        ? (data.errors.number[0] as FieldError).message
                                                        : undefined
                                                }
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell colSpan={1}>

                                            <InputField
                                                disabled={!!(rowToEdit && rowToEdit > 0)}
                                                type={InputType.NUMBER}
                                                decimal
                                                id="weight[0]"
                                                name="weight[0]"
                                                defaultValue={newGrdBookComponents.weight}
                                                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                    handleNewComponentFieldChange("weight", e.target.value);
                                                }}
                                                error={!!(data.errors.weight && data.errors.weight[0])}
                                                inputRef={data.register({  })}
                                                helperText={
                                                    (data.errors.weight && data.errors.weight[0])

                                                        ? (data.errors.weight[0] as FieldError).message
                                                            : undefined
                                                }
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell colSpan={1}>
                                            <InputField
                                                disabled={!!(rowToEdit && rowToEdit > 0)}
                                                type={InputType.NUMBER}
                                                decimal
                                                id="minimumScore[0]"
                                                name="minimumScore[0]"
                                                defaultValue={newGrdBookComponents.minimumScore}
                                                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                    handleNewComponentFieldChange("minimumScore", e.target.value);
                                                }}
                                                error={!!(data.errors.minimumScore && data.errors.minimumScore[0])}
                                                inputRef={data.register({  })}
                                                helperText={
                                                    (data.errors.minimumScore && data.errors.minimumScore[0])
                                                        ? (data.errors.minimumScore[0] as FieldError).message
                                                        : undefined
                                                }
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell colSpan={1}>
                                            <InputField
                                                disabled={!!(rowToEdit && rowToEdit > 0)}
                                                type={InputType.NUMBER}
                                                id="sequence[0]"
                                                name="sequence[0]"
                                                defaultValue={newGrdBookComponents.sequence}
                                                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                    handleNewComponentFieldChange("sequence", e.target.value);
                                                }}
                                                error={!!(data.errors.sequence && data.errors.sequence[0])}
                                                inputRef={data.register({ required: true })}
                                                helperText={
                                                    (data.errors.sequence && data.errors.sequence[0])
                                                        ? (data.errors.sequence[0] as FieldError).message
                                                        : undefined
                                                }
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell colSpan={3}>{props.repeatedExamPolicy}</StyledTableCell>
                                        <StyledTableCell colSpan={1}>
                                            <Switch
                                                disabled={!!(rowToEdit && rowToEdit > 0)}
                                                checked={newGrdBookComponents.required}
                                                name="required"
                                                color="primary"
                                                onChange={(_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleNewComponentFieldChange('required', checked)}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell colSpan={1}>
                                            <Switch
                                                disabled={!!(rowToEdit && rowToEdit > 0)}
                                                checked={newGrdBookComponents.mustPass}
                                                name="mustPass"
                                                color="primary"
                                                onChange={(_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleNewComponentFieldChange('mustPass', checked)}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell colSpan={1}>
                                            <IconButton disabled={!!(rowToEdit && rowToEdit > 0)} onClick={() => onComponentSave(0)}>
                                                <SaveIcon />
                                            </IconButton>
                                        </StyledTableCell>
                                        <StyledTableCell colSpan={1}>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    {isLoading ?
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={18} className={classes.preLoaderCell}>
                                                <CircularProgress />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        : grdBookComponents.map((grdBKComp, index) => (
                                            <StyledTableRow key={`row-${index}`}>
                                                <StyledTableCell colSpan={3} key={`compTypeId-${index + 1}`}>
                                                    <GradeBookComponentTypesAutoComplete
                                                        disabled={!rowToEdit || rowToEdit !== (index + 1)}
                                                        options={gradeBookComponentTypes}
                                                        key={`gradeBookComponentTypeId-${index + 1}`}
                                                        id={`gradeBookComponentTypeId[${index + 1}]`}
                                                        name={`gradeBookComponentTypeId[${index + 1}]`}
                                                        requestParameter={{ campusId: props.campusId }}
                                                        valueFilter={
                                                            grdBKComp.gradeBookComponentTypeId
                                                                ? {
                                                                    key: "value",
                                                                    values: [grdBKComp.gradeBookComponentTypeId],
                                                                }
                                                                : undefined
                                                        }
                                                        filterHandle={
                                                            (v: any) => {
                                                                handleNewComponentFieldChange("gradeBookComponentTypeId", v ? v.value : null, index + 1);
                                                                handleNewComponentFieldChange("gardeBookComponentType", v ? v.text : null, index + 1);
                                                                handleNewComponentFieldChange("sysTemComponentType", v ? v.systemComponentType : null, index + 1)
                                                            }
                                                        }
                                                        error={!!(data.errors.gradeBookComponentTypeId && data.errors.gradeBookComponentTypeId[index + 1])}
                                                        inputRef={data.register({ required: true })}
                                                        helperText={
                                                            (data.errors.gradeBookComponentTypeId && data.errors.gradeBookComponentTypeId[index + 1])
                                                                ? (data.errors.gradeBookComponentTypeId[index + 1] as FieldError).message
                                                                : undefined
                                                        }
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell colSpan={3} key={`compType-${index + 1}`}>{grdBKComp.sysTemComponentType}</StyledTableCell>
                                                <StyledTableCell colSpan={1} key={`number-${index + 1}`}>
                                                    <InputField
                                                        type={InputType.NUMBER}
                                                        decimal
                                                        disabled={!rowToEdit || rowToEdit !== (index + 1)}
                                                        id={`number[${index + 1}]`}
                                                        name={`number[${index + 1}]`}
                                                        key={`numberValue-${index + 1}`}
                                                        defaultValue={grdBKComp.number}
                                                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                            handleNewComponentFieldChange("number", e.target.value, index + 1);
                                                        }}
                                                        error={!!(data.errors.number && data.errors.number[index + 1])}
                                                        inputRef={data.register({})}
                                                        helperText={
                                                            (data.errors.number && data.errors.number[index + 1])
                                                                ? (data.errors.number[index + 1] as FieldError).message
                                                                : undefined
                                                        }
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell colSpan={1} key={`weightColumn-${index + 1}`}>
                                                    <InputField
                                                        disabled={!rowToEdit || rowToEdit !== (index + 1)}
                                                        key={`weight-${index + 1}`}
                                                        type={InputType.NUMBER}
                                                        decimal
                                                        id={`weight[${index + 1}]`}
                                                        name={`weight[${index + 1}]`}
                                                        defaultValue={grdBKComp.weight}
                                                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                            handleNewComponentFieldChange("weight", e.target.value, index + 1);
                                                        }}
                                                        error={!!(data.errors.weight && data.errors.weight[index + 1])}
                                                        inputRef={data.register({ required: true })}
                                                        helperText={
                                                            (data.errors.weight && data.errors.weight[index + 1])
                                                                ? (data.errors.weight[index + 1] as FieldError).message
                                                                : undefined
                                                        }
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell colSpan={1} key={`minimumScoreColumn-${index + 1}`}>
                                                    <InputField
                                                        disabled={!rowToEdit || rowToEdit !== (index + 1)}
                                                        type={InputType.NUMBER}
                                                        decimal
                                                        id={`minimumScore[${index + 1}]`}
                                                        name={`minimumScore[${index + 1}]`}
                                                        defaultValue={grdBKComp.minimumScore}
                                                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                            handleNewComponentFieldChange("minimumScore", e.target.value, index + 1);
                                                        }}
                                                        error={!!(data.errors.minimumScore && data.errors.minimumScore[index + 1])}
                                                        inputRef={data.register({ required: true })}
                                                        helperText={
                                                            (data.errors.minimumScore && data.errors.minimumScore[index + 1])
                                                                ? (data.errors.minimumScore[index + 1] as FieldError).message
                                                                : undefined
                                                        }
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell colSpan={1} key={`sequenceColumn-${index + 1}`}>
                                                    <InputField
                                                        disabled={!rowToEdit || rowToEdit !== (index + 1)}
                                                        type={InputType.NUMBER}
                                                        id={`sequence[${index + 1}]`}
                                                        name={`sequence[${index + 1}]`}
                                                        defaultValue={grdBKComp.sequence}
                                                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                            handleNewComponentFieldChange("sequence", e.target.value, index + 1);
                                                        }}
                                                        error={!!(data.errors.sequence && data.errors.sequence[index + 1])}
                                                        inputRef={data.register({ required: true })}
                                                        helperText={
                                                            (data.errors.sequence && data.errors.sequence[index + 1])
                                                                ? (data.errors.sequence[index + 1] as FieldError).message
                                                                : undefined
                                                        }
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell colSpan={3} key={`policy-${index + 1}`}>{props.repeatedExamPolicy}</StyledTableCell>
                                                <StyledTableCell colSpan={1} key={`requiredColumn-${index + 1}`}>
                                                    <Switch
                                                        disabled={!rowToEdit || rowToEdit !== (index + 1)}
                                                        key={`required-${index + 1}`}
                                                        checked={grdBKComp.required}
                                                        name={`required-${index + 1}`}
                                                        id={`required-${index + 1}`}
                                                        color="primary"
                                                        onChange={(_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleNewComponentFieldChange('required', checked, index + 1)}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell colSpan={1} key={`mustPassColumn-${index + 1}`}>
                                                    <Switch
                                                        disabled={!rowToEdit || rowToEdit !== (index + 1)}
                                                        key={`mustPass-${index + 1}`}
                                                        checked={grdBKComp.mustPass}
                                                        name={`mustPass-${index + 1}`}
                                                        id={`mustPass-${index + 1}`}
                                                        color="primary"
                                                        onChange={(_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleNewComponentFieldChange('mustPass', checked, index + 1)}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell colSpan={1} key={`saveColumn-${index + 1}`}>
                                                    <IconButton disabled={!!(rowToEdit && rowToEdit !== (index + 1))} key={`saveButton-${index + 1}`} onClick={() => (rowToEdit && rowToEdit === (index + 1)) ? onComponentSave(index + 1) : setRowToEdit(index + 1)}>
                                                        {(rowToEdit && rowToEdit === (index + 1) &&
                                                            <SaveIcon key={`saveIcon-${index + 1}`} />)
                                                            ||
                                                            <EditIcon key={`editIcon-${index + 1}`} />
                                                        }
                                                    </IconButton>
                                                </StyledTableCell>
                                                <StyledTableCell colSpan={1} key={`deleteColumn-${index + 1}`}>
                                                    <IconButton disabled={!!(rowToEdit && rowToEdit !== (index + 1))} key={`deleteButton-${index + 1}`} onClick={() => !!(rowToEdit && rowToEdit === (index + 1)) ? onComponentCancel() : onComponentDelete(index)}>
                                                        {(rowToEdit && rowToEdit === (index + 1) &&
                                                            <ClearIcon key={`clearIcon-${index + 1}`} />)
                                                            ||
                                                            <DeleteIcon key={`deleteIcon-${index + 1}`} />
                                                        }
                                                    </IconButton>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </form>
        </FormContext>
    );
};

export default GradeBookComponents;
