import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import { Paper, Card, IconButton } from "@material-ui/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as InstitutionalRefundApi from "../../../../src/api/student/studentAccounts/InstitutionalRefundApi";
import { RefundPolicy } from "../../../interfaces/student/refunds/RefundPolicy";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Search from "../../student/grades/Search";
import CustomTableHeader from "../../../components/Tables/CustomTableHeader";
import {
	CustomHeadCell,
	Order,
} from "../../../interfaces/common/Table/CustomHeadCell";
import * as TableFunctions from "../../../utils/tableFunctions";
import AccessManager from "../../../components/security/AccessManager";
import { security } from "../../../constants/Security/securityConstants";

const headCells: CustomHeadCell<RefundPolicy>[] = [
	{
		id: "description",
		numeric: false,
		disablePadding: false,
		label: "Description",
		widthType: "M",
	},
	{
		id: "periodTypeDescription",
		numeric: false,
		disablePadding: false,
		label: "Refund period type",
		widthType: "S",
	},
	{
		id: "effectiveFrom",
		numeric: true,
		disablePadding: false,
		label: "Effective from",
		widthType: "S",
	},
	{
		id: "effectiveTo",
		numeric: true,
		disablePadding: false,
		label: "Effective to",
		widthType: "S",
	},
	{
		id: "academicCalendarDescription",
		numeric: true,
		disablePadding: false,
		label: "Academic calendar",
		widthType: "XS",
	},
	{
		id: "institutionalRefundPolicyId",
		numeric: false,
		disablePadding: false,
		label: "Actions",
		widthType: "XS",
		disableSort: true,
		alignCenter: true,
	},
];

const useToolbarStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(1),
			height: "50px",
		},
		highlight:
			theme.palette.type === "light"
				? {
					color: theme.palette.secondary.main,
					backgroundColor: theme.palette.secondary.light,
				}
				: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark,
				},
		title: {
			flex: "1 1 100%",
		},
		header: {
			width: "100%",
			height: "90%",
			display: "inline-block",
			border: "5px",
			float: "left",
			color: "#a8a8a8",
		},
		date: {
			width: "100%",
		},
		dateDiv: {
			paddingRight: theme.spacing(2),
			width: "20%",
		},
		searchDiv: {
			paddingRight: theme.spacing(2),
			float: "right",
			width: "100%",
		},
	})
);

interface EnhancedTableToolbarProps {
	numSelected: number;
	onChangeDate?: Function;
	onChangeSearchFilter?: Function;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
	const classes = useToolbarStyles({});
	const { numSelected } = props;
	let d = new Date();
	d.setDate(d.getDate());
	const [defaultExameDate, setDefaultExameDate] = useState<Date | null>(d);
	const [searchToolFilter, setSearchToolFilter] = useState<string | null>("");
	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0,
			})}
		>
			<div className={classes.searchDiv}>
				<Search
					label={"Search Policies"}
					value={searchToolFilter}
					onChange={(e: any) => {
						if (props.onChangeSearchFilter) {
							props.onChangeSearchFilter(e);
						}
						setSearchToolFilter(e.target.value);
					}}
				/>
			</div>
		</Toolbar>
	);
};

// enhance table styling
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			height: "calc(100% - 70px)",
			width: "100%",
		},
		cardWrapper: {
			height: "100%",
			width: "100%",
		},
		paper: {
			width: "100%",
			marginBottom: theme.spacing(2),
		},
		tableWrapper: {
			width: "100%",
			maxHeight: "83%",
			overflowY: "auto",
		},
		actionCell: {
			whiteSpace: "nowrap",
		},
	})
);

type PolicyTableProps = {
	onEditClick: Function;
	onDeleteClick?: Function;
	setPolicyData: any;
	policyData: any;
	setSnackBar: any;
	setConfirmationModal: any;
	refreshGrid?: any;
};

export default function EnhancedTable(props: PolicyTableProps) {
	const classes = useStyles({});
	const [order, setOrder] = React.useState<Order>("asc");
	const [orderBy, setOrderBy] = React.useState<keyof RefundPolicy>(
		"description"
	);

	const [selected, setSelected] = React.useState<string[]>([]);
	const [searchFilter, setsearchFilter] = useState<string | null>("");
	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const filterDataBySearch = (data: RefundPolicy[]): RefundPolicy[] => {
		if (searchFilter === "" || searchFilter === undefined) return data;

		let searchVal: string = searchFilter
			? (searchFilter as string).toLowerCase()
			: "";
		return data.filter((row: RefundPolicy) => {
			return (
				row &&
				row.description &&
				row?.description?.toLowerCase().indexOf(searchVal) > -1
			);
		});
	};
	const refreshGridData = () => {
		if (userSelectedCampus) {
			InstitutionalRefundApi.GetPolicies(userSelectedCampus).then(
				(response: any) => {
					if (response) {
						let data = response.data;

						if (data && data.result) {
							data.result.forEach((element: RefundPolicy) => {
								if (!element.academicCalendarId) {
									element.academicCalendarDescription = 'All';
								}
							});
						}

						props.setPolicyData(data.result);
					}
				},
				(exception: any) => { }
			);
		}
	};
	React.useEffect(() => {
		refreshGridData();
	}, [userSelectedCampus, props.refreshGrid]);

	const handleSearchOnChange = (v: any) => {
		setsearchFilter(v.target.value);
	};

	return (
		<Paper className={classes.root}>
			<Card className={classes.cardWrapper}>
				<EnhancedTableToolbar
					numSelected={selected.length}
					onChangeSearchFilter={handleSearchOnChange}
				/>
				<div className={classes.tableWrapper}>

					<Table stickyHeader>
						<CustomTableHeader<RefundPolicy>
							cells={headCells}
							defaultOrder={order}
							defaultOrderBy={orderBy}
							setOrder={setOrder}
							setOrderBy={setOrderBy}
							key={"tex"}
						/>
						<TableBody>
							{TableFunctions.stableSort(
								filterDataBySearch(props.policyData as any) as any,
								TableFunctions.getSorting(order, orderBy)
							).map((row: any, index: any) => {
								let rowData = row as RefundPolicy;

								return (
									<TableRow key={`row-${rowData.institutionalRefundPolicyId}`}>
										<TableCell>{rowData.description}</TableCell>
										<TableCell>{rowData.periodTypeDescription}</TableCell>
										<TableCell>
											{new Date(
												rowData.effectiveFrom as any
											).toLocaleDateString()}
										</TableCell>
										<TableCell>
											{new Date(
												rowData.effectiveTo as any
											).toLocaleDateString()}
										</TableCell>
										<TableCell>{rowData.academicCalendarDescription || 'All'}</TableCell>
										<TableCell className={classes.actionCell}>
											<IconButton
												color="primary"
												aria-label="upload picture"
												component="span"
												onClick={(e: any) => {
													props.onEditClick(rowData);
												}}
											>
												<EditIcon></EditIcon>
											</IconButton>

											<AccessManager
												allowedPermissions={[security.permissions.studentAccounts.updateRefundPolicies, security.permissions.studentAccounts.deleteRefundPolicies]}
												renderNoAccess={() => null}
											>
												<IconButton
													color="primary"
													aria-label="Delete"
													component="span"
													onClick={(e: any) => {
														if (props.onDeleteClick) {
															props.onDeleteClick(rowData);
														}
													}}
												>
													<DeleteIcon></DeleteIcon>
												</IconButton>
											</AccessManager>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</div>
			</Card>
		</Paper>
	);
}
