import React from "react";
import { Redirect } from "react-router-dom";
import * as PropTypes from "prop-types";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Divider } from "@material-ui/core";
import TabItem from "../../../components/Tabs/TabItem";
import getAdvantageLink from "../../../utils/getAdvantageLink";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Campus } from "../../../interfaces/systemCatalog/ICampus";
import ComponentList from "../../routing/ComponentList";
import * as actionTypes from "../../../constants/actions.constants";
import ConfirmationModal from "../../../components/_Layout/Modal/ConfirmationModal";
import { checkPermissions } from "../../../utils/securityFunctions";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		inner: {
			height: "100%",
			width: "100%",
			margin: "0 auto",
			minHeight: "100%",
			maxWidth: 1150,
		},
		divider: {
			backgroundColor: theme.palette.site.primary,
		},
		content: {
			height: "90%",
			minHeight: "90%",
		},
		contentOpaque: {
			height: "100%",
			minHeight: "100%",
			width: "100%",
		},
		contentWrapper: {
			height: "100%",
			minHeight: "100%",
			width: "100%",
			backgroundColor: "transparent",
			overflowX: "hidden",
		},
		fixed: {
			position: "sticky",
			top: "0",
			width: "100%",
			marginBottom: "0.5em",
		},
		tabController: {
			backgroundColor: "transparent",
			textTransform: "uppercase",
		},
		tabIndicator: {
			backgroundColor: theme.palette.primary.contrastNavTabColor,
		},
		tabSelected: { fontWeight: "bold" },
	})
);

type INavTabProps = {
	history?: any;
	match?: any;
	tabs: Array<TabItem>;
	pKey: any;
	location?: any;
	maxWidth?:number;
};

const NavTabs = (props: INavTabProps) => {
	const { match, history, tabs, pKey } = props;
	const classes: any = useStyles({});
	let { tab } = match.params;
	const studentId = useSelector<any, any>(
		(state: any) => state.student.studentId
	);
	const params = new URLSearchParams(window.location.search);
	const campusId = params.get("campusId");

	const dispatch = useDispatch();
	const [confirmationPopupState, setConfirmationPopupState] = React.useState<
		any
	>({
		id: "confirmation-popup",
		message: "",
	});
	const advantageLink = getAdvantageLink(props.location.pathname);

	const handleTabsChange = (event: any, value: any) => {
		history.push(value);
	};
	if (!tab) {
		return <Redirect to={"/dashboard"} />;
	}
	if (!tabs.find((t: TabItem) => t.value === tab)) {
		return <Redirect to="/errors/error-404" />;
	} else {
		let tabLabel = (tabs.find((t: TabItem) => t.value === tab) as TabItem)
			.label;
		dispatch({
			type: actionTypes.tabConstants.SESSION_TAB_CHANGED,
			tabLabel: tabLabel,
		});
	}
	const tabMetaData = ComponentList.GetComponentTabByName(pKey, tab);
	let tabComponent: any = undefined;
	if (tabMetaData) {
		if (tabMetaData.isLegacy) {
			let params = { cmpId: campusId, studentId: studentId };
			tabComponent = (
				<tabMetaData.component
					url={advantageLink}
					params={{ ...params, ...tabMetaData.params }}
				/>
			);
		} else {
			tabComponent = (
				<tabMetaData.component
					setConfirmationModal={setConfirmationPopupState}
				/>
			);
		}
	}
	return (
		<div id="navTabsContainer" className={classes.inner} style={!!props.maxWidth && props.maxWidth > 0 ? {maxWidth:props.maxWidth} : {}}>
			<ConfirmationModal {...confirmationPopupState} />
			<div className={classes.fixed}>
				<Tabs
					onChange={handleTabsChange}
					scrollButtons="auto"
					value={tab}
					variant="fullWidth"
					classes={{
						indicator: classes.tabIndicator,
					}}
					className={classes.tabController}
				>
					{tabs.map((tab: TabItem) => (
						<Tab
							disabled={!checkPermissions(tab.permissions || [])}
							key={tab.value}
							label={tab.label}
							value={tab.value}
							classes={{ selected: classes.tabSelected }}
						/>
					))}
				</Tabs>
				<Divider className={classes.divider} />
			</div>
			<div className={classes.content}>
				<div className={classes.contentOpaque}>
					<div className={classes.contentWrapper}>
						{tabComponent && tabComponent}
					</div>
				</div>
			</div>
		</div>
	);
};

NavTabs.propTypes = {
	tabs: PropTypes.object.isRequired,
};

export default NavTabs;
