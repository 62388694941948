export class OtherEmail {
	constructor() {
		this.id = "";
		this.otherContactId = "";
		this.leadId = "";
		this.emailTypeId = "";
		this.emailType = "";
		this.email = "";
		this.statusId = "";
		this.status = "";
	}
	id: string;
	otherContactId: string;
	leadId: string;
	emailTypeId: string;
	emailType: string;
	email: string;
	statusId: string;
	status: string;
}
