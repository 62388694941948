import * as React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import CustomFieldPagesGrid from "./CustomFieldsGrid";
// import General from "./General";
// import Demographics from "./Demographics";
// import Admissions from "./Admissions";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.paper.background,
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2)
			}
		},
		"& .makeStyles-content": {
			backgroundColor: theme.palette.paper.background
		},
		paperRoot: {
			padding: theme.spacing(2),
			width: "100%",
			minHeight: "100%",
			height: "100%",
			overflowX: "hidden",
			overflowY: "auto",
			flexDirection: "column",
			backgroundColor: theme.palette.paper.background
		},
		buttonColorPrimary: {
			backgroundColor: theme.palette.buttons.primary,
			color: theme.palette.primary.contrastText,
			"&:hover": {
				backgroundColor: theme.palette.primary.main
			},
			textDecoration: "bold",
			padding: theme.spacing(1),
			marginTop: theme.spacing(3),
			whiteSpace: "nowrap"
		},
		cardSeparator: {
			marginBottom: theme.spacing(1)
		}
	})
);

const CustomFieldsPagesSetup = (props: any) => {
	const classes = useStyles({});

	return (
		<Paper className={classes.paperRoot} square={true}>
			<form>
				<div className={classes.cardSeparator}>
					{/* <CustomFieldPagesGrid></CustomFieldPagesGrid> */}
				</div>
			</form>
		</Paper>
	);
};

export default CustomFieldsPagesSetup;
