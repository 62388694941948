import { authConstants,forgotPasswordConstants , userPilotConstants} from "../constants/actions.constants";
import * as authApi from "../api/security/authApi";
import { LoginUser } from "../interfaces/security/LoginUser";
import * as apiWrapper from "../api/apiWrapper";

export const accountActions = {
    runVerifyEmail,
    runVerifyAnswer,
    resetAccountStore,
    runUpdatePassword,
    setPasswordAndSecurityQuestion,
    reloadUserPilot
};


function runVerifyEmail(userEmail:string ) {
    return (dispatch: any) => {
      dispatch({ type: forgotPasswordConstants.ACCOUNT_FORGOT_PASSWORD_REQUEST, userEmail });
      authApi.verifyEmail(userEmail).then(
        (response: any) => {
          let serverResponse = response.data;
           if (serverResponse && serverResponse.isSuccess) 
              return dispatch({ type: forgotPasswordConstants.ACCOUNT_VERIFY_EMAIL_SUCCESS, serverResponse });

           return dispatch({ type: forgotPasswordConstants.ACCOUNT_VERIFY_EMAIL_ERROR, serverResponse });
        },
        (exception: any) => {
          return dispatch({ type: forgotPasswordConstants.ACCOUNT_VERIFY_EMAIL_ERROR });
        }
      );
    };
  }
  

  function runVerifyAnswer(userEmail:any, answer:string ) {
    return (dispatch: any) => {
      dispatch({ type: forgotPasswordConstants.ACCOUNT_FORGOT_PASSWORD_REQUEST_QUESTION, userEmail });
      authApi.verifyAnswer(userEmail, answer).then(
        (response: any) => {
          let serverResponse = response.data;
           if (serverResponse && serverResponse.isSuccess) 
              return dispatch({ type: forgotPasswordConstants.ACCOUNT_VERIFY_QUESTION_SUCCESS, serverResponse });

           return dispatch({ type: forgotPasswordConstants.ACCOUNT_VERIFY_QUESTION_ERROR, serverResponse });
        },
        (exception: any) => {
          return dispatch({ type: forgotPasswordConstants.ACCOUNT_VERIFY_QUESTION_ERROR });
        }
      );
    };
  }

  function runUpdatePassword(password:string, token:string, identity:string ) {
    return (dispatch: any) => {
      dispatch({ type: forgotPasswordConstants.ACCOUNT_NEW_PASSWORD_REQUEST });
      authApi.newPassword(password, token, identity).then(
        (response: any) => {
          let serverResponse = response.data;
           if (serverResponse && serverResponse.isSuccess) 
              return dispatch({ type: forgotPasswordConstants.ACCOUNT_NEW_PASSWORD_SUCCESS, serverResponse });
           else{
            return dispatch({ type: forgotPasswordConstants.ACCOUNT_NEW_PASSWORD_ERROR, serverResponse });
           }
        },
        (exception: any) => {
          return dispatch({ type: forgotPasswordConstants.ACCOUNT_NEW_PASSWORD_ERROR });
        }
      );
    };
  }

  function setPasswordAndSecurityQuestion(password:string, token:string, identity:string, securityQuestion:string, securityAnswer:string ) {
    return (dispatch: any) => {
      dispatch({ type: forgotPasswordConstants.ACCOUNT_NEW_PASSWORD_REQUEST });
      authApi.setPasswordAndQuestion(password, token, identity, securityQuestion, securityAnswer).then(
        (response: any) => {
          let serverResponse = response.data;
           if (serverResponse && serverResponse.isSuccess) 
              return dispatch({ type: forgotPasswordConstants.ACCOUNT_NEW_PASSWORD_SUCCESS, serverResponse });
           else{
            return dispatch({ type: forgotPasswordConstants.ACCOUNT_NEW_PASSWORD_ERROR, serverResponse });
           }
        },
        (exception: any) => {
          return dispatch({ type: forgotPasswordConstants.ACCOUNT_NEW_PASSWORD_ERROR });
        }
      );
    };
  }

  function resetAccountStore(){
    return (dispatch: any) => {
      dispatch({ type: forgotPasswordConstants.ACCOUNT_SESSION_RESET });
    };  
  }

  function reloadUserPilot(){
    return (dispatch: any) => {
      dispatch({ type: userPilotConstants.USER_PILOT_REFRESH_REQUIRED });
    };  
  }