import * as React from "react";

export const appConfig = {
	APP_CONFIG_LOADER: "APP_CONFIG_LOADER",
};

export const authConstants = {
	SESSION_LOGIN_REQUEST: "SESSION_LOGIN_REQUEST",
	SESSION_LOGIN_SUCCESS: "SESSION_LOGIN_SUCCESS",
	SESSION_ESTABLISHED: "SESSION_ESTABLISHED",
	SESSION_LOGIN_ERROR: "SESSION_LOGIN_ERROR",
	SESSION_LOGOUT: "SESSION_LOGOUT",
	SESSION_LOGIN_ERROR_MESSAGE:
		"An un-handle exception has occurred, please try again, if the error persist, please contact your administrator.",
};

export const userConstants = {
	SESSION_MENU_RETRIEVED: "SESSION_MENU_RETRIEVED",
	SESSION_CAMPUSES_SET: "SESSION_CAMPUSES_SET",
	SESSION_NOTIFICATION_RECEIVED: "SESSION_NOTIFICATION_RECEIVED",
	SESSION_NOTIFICATION_REMOVED: "SESSION_NOTIFICATION_REMOVED",
	SESSION_PERSISTENT_NOTIFICATION_RECEIVED:
		"SESSION_PERSISTENT_NOTIFICATION_RECEIVED",
	SESSION_PERSISTENT_NOTIFICATION_REMOVED:
		"SESSION_PERSISTENT_NOTIFICATION_REMOVED",
};

export const tabConstants = {
	SESSION_TAB_CHANGED: "SESSION_TAB_CHANGED",
	SESSION_TAB_CLEARED: "SESSION_TAB_CLEARED",
};

export const autoCompleteConstants = {
	SESSION_LOADING_AC_LOADING: "SESSION_AC_LOADING",
	SESSION_LOADING_AC_ERROR: "SESSION_AC_ERROR",
	SESSION_LOADING_AC_RETRIEVE_CACHE: "SESSION_LOADING_AC_RETRIEVE_CACHE",
	SESSION_LOADING_AC_UPDATE_CACHE: "SESSION_LOADING_AC_UPDATE_CACHE",
	SESSION_LOADING_AC_REMOVE_CACHE: "SESSION_LOADING_AC_REMOVE_CACHE",
};

export const studentProfileConstants = {
	SESSION_SET_STUDENT_PROFILE: "SESSION_SET_STUDENT_PROFILE",
	SESSION_SET_SELECTED_ENROLLMENT: "SESSION_SET_SELECTED_ENROLLMENT",
};

export const userStateConstants = {
	USER_STATE_SET_SELECTED_CAMPUS: "USER_STATE_SET_SELECTED_CAMPUS",
	USER_STATE_SET_SELECTED_STUDENT: "USER_STATE_SET_SELECTED_STUDENT",
};

export const dragConstants = {
	DRAG_CONTEXT_REQUESTED: "DRAG_CONTEXT_REQUESTED",
	DRAG_CONTEXT_CLEARED: "DRAG_CONTEXT_CLEARED",
	DRAG_ENDED: "DRAG_ENDED",
	DRAG_STARTED: "DRAG_STARTED",
};

export const tagConstants = {
	TAG_CREATED: "TAG_CREATED",
	TAG_DELETED: "TAG_DELETED",
	ADD_MODULE_TAGS: "ADD_MODULE_TAGS",
};

export const notificationConstants = {
	NEW_NOTIFICATION: "NEW_NOTIFICATION",
	NOTIFICATION_VIEWED: "NOTIFICATION_VIEWED",
};

export const mruConstants = {
	REFRESH_MRU: "REFRESH_MRU",
};

export const toasterConstants = {
	SHOW_TOASTER: "SHOW_TOASTER",
	HIDE_TOASTER: "HIDE_TOASTER",
};

export const pdfPreviewConstants = {
	SHOW_PREVIEW: "SHOW_PREVIEW",
	HIDE_PREVIEW: "HIDE_PREVIEW",
};

export const reportsPageViewModeConstants = {
	REPORTS_PAGE_VIEW_MODE_CHANGED: "REPORTS_PAGE_VIEW_MODE_CHANGED",
};

export const forgotPasswordConstants = {
	ACCOUNT_FORGOT_PASSWORD_REQUEST: "ACCOUNT_FORGOT_PASSWORD_REQUEST",
	ACCOUNT_VERIFY_EMAIL_SUCCESS: "ACCOUNT_VERIFY_EMAIL_SUCCESS",
	ACCOUNT_VERIFY_EMAIL_ERROR: "ACCOUNT_VERIFY_EMAIL_ERROR",

	ACCOUNT_FORGOT_PASSWORD_REQUEST_QUESTION:
		"ACCOUNT_FORGOT_PASSWORD_REQUEST_QUESTION",
	ACCOUNT_VERIFY_QUESTION_SUCCESS: "ACCOUNT_VERIFY_QUESTION_SUCCESS",
	ACCOUNT_VERIFY_QUESTION_ERROR: "ACCOUNT_VERIFY_QUESTION_ERROR",

	ACCOUNT_NEW_PASSWORD_REQUEST: "ACCOUNT_NEW_PASSWORD_REQUEST",
	ACCOUNT_NEW_PASSWORD_SUCCESS: "ACCOUNT_NEW_PASSWORD_SUCCESS",
	ACCOUNT_NEW_PASSWORD_ERROR: "ACCOUNT_NEW_PASSWORD_ERROR",

	ACCOUNT_SESSION_RESET: "ACCOUNT_SESSION_RESET",
};

export const settingConstants = {
	SET_SETTINGS: "SET_SETTINGS",
	GET_SETTINGS: "GET_SETTINGS",
};

export const userTermsConstants = {
	NEED_APPROVAL: "NEED_APPROVAL",
	APPROVAL_REQUEST: "APPROVAL_REQUEST",
	APPROVAL_DONE: "APPROVAL_DONE",
	APPROVAL_CANCEL: "APPROVAL_CANCEL",
};

export const cookiesConstants = {
	THEME_TENANT_KEY: "FAMETheme",
	FAME_THEME_MODE_KEY: "FAMEThemeMode",
	FAME_THEME_MODE: "Support",
	DEFAULT_THEME: "DEFAULT",
	AVEDA_THEME: "AVEDA",
	PMTS_THEME: "PMTS",
	TUBC_THEME: "TUBC",
	ARCLABS_THEME: "ARCLABS",
	MPI_THEME: "MPI",
	ANGELES_THEME: "ANGELES",
	PHAGANS_THEME: "PHAGANS",
	CELEBRITY_THEME: "CELEBRITY",
	FABSCHOOL_THEME: "FABSCHOOL",
	TRAINING_THEME: "TRAINING",
	COLLEGE_THEME: "COLLEGE",
	DPRESSLEY_THEME: "DPRESSLEY",
	RITZ_THEME: "RITZ",
	CHROME_INSTITUTE_THEME:"CHROMEINSTITUTE",
	QUERY_STRING_KEY: "theme",
};

export const supportUserConstants = {
	SUPPORT_USER_FOUND: "SUPPORT_USER_FOUND",
	SUPPORT_USER_CANCEL: "SUPPORT_USER_CANCEL",
};

export const multitenantUserConstants = {
	MULTI_TENANT_USER_FOUND: "MULTI_TENANT_USER_FOUND",
	MULTI_TENANT_USER_CANCEL: "MULTI_TENANT_USER_CANCEL",
}

export const advanceSearchConstants = {
	SET_ENROLLMENT_PROGRAM_AND_GROUPS_ADVANCE_SEARCH:
		"SET_ENROLLMENT_PROGRAM_AND_GROUPS_ADVANCE_SEARCH",
	SET_ADVANCE_SEARCH_FILTERS: "SET_ADVANCE_SEARCH_FILTERS",
};

export const userPilotConstants = {
	USER_PILOT_REFRESH_REQUIRED: "USER_PILOT_REFRESH_REQUIRED",
};
