import * as React from "react";
import { apiAutoCompleteConfig } from "../../constants/apiAutoComplete.config";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";

const AdmissionsRepsAutoCompleteNoSearchIcon = (props: FilterParams) => {
	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const filterHandle = props.filterHandle;
	const admissionsRepsOnChange = (e: any) => {
		if (filterHandle) {
			filterHandle(e);
		}
	};

	return (
		<div>
			<ApiAutoComplete
				config={apiAutoCompleteConfig.admissionsReps}
				loading={userSelectedCampus ? false : true}
				reqParams={{ campusId: userSelectedCampus }}
				classes={{
					option: "text-black"
				}}
				label="Admissions Rep"
				onChange={(e: any) => {
					admissionsRepsOnChange(e);
				}}
				multiple={false}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
			></ApiAutoComplete>
		</div>
	);
};
export default AdmissionsRepsAutoCompleteNoSearchIcon;
