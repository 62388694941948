import API from "../../apiWrapper";
import { IAttendaceHistoryMultiple } from "../../../interfaces/reports/attendance/IAttendaceHistoryMultiple";
import { ReportOutput } from "../../../enums/ReportOutput";
export const generateReport = (
  params: IAttendaceHistoryMultiple,
  isPreview: boolean = false
) => {
  return API()
    .post(
      "/AcademicRecords/Attendance/GetAttendanceHistoryMultipleStudentReport",
      params,
      {
        responseType: "blob",
      }
    )
    .then(
      (res: any) => {
        if (res && res.data) {
          if (isPreview && ReportOutput.Pdf == params.exportType) {
            return res.data;
          } else {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            let exportType: number = Number(params.exportType);
            let extension = "";
            switch (exportType) {
              case Number(ReportOutput.Pdf):
                extension = "pdf";
                break;
              case Number(ReportOutput.Excel):
                extension = "xls";
                break;
              case Number(ReportOutput.Word):
                extension = "doc";
                break;
              default:
                extension = "pdf";
                break;
            }
            var fileName =
              "AttendanceHistoryMultipleStudentReport" +
              new Date()
                .toISOString()
                .replace("T", " ")
                .replace(/\..*$/, "")
                .split(" ")[0]
                .split("-")
                .join("") +
              "." +
              extension;
              if(params.singleStudent)
              {
                fileName = fileName.replace("Multiple","")
              }
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          }
        }
      },
      (error: any) => {
        return null;
      }
    );
};
