import API from "./apiWrapper";
import { stringify } from "qs";

export const pullData = (route: any, data: any, params: any, type: any) => {
	if (type === "GET") {
		return API()
			.get(route, {
				params: params,
				paramsSerializer: params => {
					return stringify(params)
				}
			})
			.then(
				(res: any) => {
					return res;
				},
				(error: any) => {
					return error;
				}
			);
	} else {
		return API()
			.post(route, data)
			.then(
				(res: any) => {
					return res;
				},
				(error: any) => {
					return error;
				}
			);
	}
};

export const handleResponse = (response: any) => {
	return response.text().then((text: any) => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				// auto logout if 401 response returned from api
				window.location.reload(true);
			}

			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}

		return data;
	});
};
