import * as React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
	Grid,
	TextField,
	FormControlLabel,
	Switch,
	Card,
	Typography,
} from "@material-ui/core";
import ReportPreview from "./ReportPreview";
import ReportParameters from "./ReportParameters";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import ReportOverviewHeader from "./ReportOverviewHeader";
import ApiAutoComplete from "../ApiAutoComplete/ApiAutoComplete";
import { useSelector } from "react-redux";
import { SavedReportParametersApiAutoComplete } from "../../../constants/SystemCatalog/SavedReportParametersApiAutoComplete";
import * as savedReportParametersApi from "../../../api/reports/savedReportParameters/savedReportParametersApi";
import ProgressSaveButton from "../../../components/_Layout/Buttons/ProgressSaveButton";
import CustomSnackbar from "../../../components/notifications/CustomSnackbar";
import { CustomSnackBarProps } from "../../../interfaces/common/CustomSnackBarProps";
import { ISavedReportParameters } from "../../../interfaces/reports/ISavedReportParameters";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import NotesIcon from "@material-ui/icons/Notes";
import GetAppIcon from "@material-ui/icons/GetApp";
import SaveIcon from "@material-ui/icons/Save";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PrintIcon from "@material-ui/icons/Print";
import { Fullscreen } from "@material-ui/icons";

const useStyles = makeStyles((theme: any) => ({
	root: {},
	deliverables: {
		marginTop: theme.spacing(3),
	},
	members: {
		marginTop: theme.spacing(3),
	},
	innerContent: {
		padding: theme.spacing(4, 0, 4, 2),
	},
	inputField: {
		width: "100%",
	},
	switch: {
		display: "flex",
		alignItems: "flex-end",
		padding: theme.spacing(0, 0.5),
		height: theme.spacing(8.6),
	},
	header: {
		padding: theme.spacing(2, 2, 0, 2),
	},
	headerText: {
		color: theme.palette.text.secondary,
		fontSize: theme.spacing(1.5),
	},
	valignTop: { verticalAlign: "top" },
	spaceTop: {
		marginTop: theme.spacing(2),
	},
	mainActions: {
		marginTop: theme.spacing(3),
	},
	button: {
		paddingRight: theme.spacing(1),
	},
}));

interface ReportOverviewProps {
  reportOverview: IReportOverview;
  className?: string;
  filterHandler: any;
  getReportParameters: any;
  exportHandler: any;
  preview?: any;
  previewLoader?: boolean;
  hidePreview?: boolean;
  isUrlPreview?: boolean;
}
let defaultModel = {
	parameterName: "",
	reportParameterId: undefined as number | undefined,
	shareable: false,
};
const ReportOverview = (props: ReportOverviewProps) => {
	const {
		reportOverview,
		className,
		filterHandler,
		getReportParameters,
		...rest
	} = props;
	const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
		{
			showSnackBar: false,
			messageInfo: undefined,
			variant: "info",
		}
	);
	const classes = useStyles();
	const selectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);
	const loggedInUser = useSelector((state: any) => state.session.user);
	const [loaderState, setLoaderState] = React.useState<boolean>(false);
	const [showFull, setShowFull] = React.useState<boolean>(false);
	const [printLoaderState, setPrintLoaderState] = React.useState<boolean>(
		false
	);
	const [exportState, setExportState] = React.useState<boolean>(false);
	const [model, setModel] = React.useState<any>(defaultModel);
	React.useEffect(() => {
		if (model.reportParameterId) {
			savedReportParametersApi
				.getSavedReportParameterDetail(
					props.reportOverview.report.id,
					selectedCampus,
					model.reportParameterId
				)
				.then(
					(response: any) => {
						if (response) {
							handleFieldChange("shareable", response.result.shareable);
							handleFieldChange("parameterName", response.result.parameterName);
							const jsonData = JSON.parse(
								response.result.resourceJsonParameters
							);
							filterHandler(jsonData);
						}
					},
					(_exception: any) => []
				);
		}
	}, [model.reportParameterId]);

	//#region ReportParameters

	const handleFieldChange = (fieldId: string, value: any) => {
		let updatedModel = model;
		(updatedModel as any)[fieldId] = value;
		setModel({ ...updatedModel });
	};
	const onSubmit = async () => {
		if (!model.parameterName) {
			setSnackBarProps({
				variant: "error",
				showSnackBar: true,
				messageInfo: "Parameter Name is required.",
			});
			return;
		}
		try {
			setLoaderState(true);
			let reportParams = await getReportParameters();
			if (Object.keys(reportParams).length > 0) {
				let params: ISavedReportParameters = {
					parameterName: model.parameterName,
					reportParameterId: model.reportParameterId,
					resourceId: props.reportOverview.report.id,
					resourceJsonParameters: JSON.stringify(reportParams),
					campusId: selectedCampus,
					shareable: model.shareable,
				};
				savedReportParametersApi.saveReportParameter(params).then(
					(result: any) => {
						clearFilterAndState();
						setSnackBarProps(result);
						setLoaderState(false);
					},
					() => {
						setLoaderState(false);
					}
				);
			} else {
				setLoaderState(false);
				setSnackBarProps({
					variant: "error",
					showSnackBar: true,
					messageInfo: "Report parameters are not correct.",
				});
			}
		} catch (error) {
			setLoaderState(false);
			setSnackBarProps({
				variant: "error",
				showSnackBar: true,
				messageInfo: error.message,
			});
		}
	};
	const clearFilterAndState = () => {
		setModel({
			parameterName: "",
			reportParameterId: undefined as number | undefined,
			shareable: false,
		});
		filterHandler(null);
		setLoaderState(false);
		setExportState(false);
	};

	const exportEventHandler = (isPreview: boolean, isPrint: boolean = false) => {
		setExportState(!isPrint);
		setPrintLoaderState(isPrint);
		props.exportHandler(isPreview).then(() => {
			setExportState(false);
			setPrintLoaderState(false);
		});
	};
	const showFullScreen = () => {
		if (showFull) {
			setShowFull(false);
		}
		else {
			setShowFull(true);
		}
	}

	React.useEffect(() => {
		clearFilterAndState();
	}, []);
	//#endregion ReportParameters

	return (
		<div>
			{!showFull == true &&
        <ReportOverviewHeader reportOverview={reportOverview} />
			}
			<Grid
				{...rest}
				className={clsx(classes.root, className)}
				container
				spacing={2}
				justify="flex-start"
				alignItems="flex-start"
			>
				{
					!showFull == true &&

          <Grid item lg={(!props.hidePreview && 8) || 12} xl={(!props.hidePreview && 9) || 12} xs={12}>
          	<Card {...rest} className={clsx(classes.root, className)}>
          		<Grid container lg={12} xl={12} xs={12} direction="row" spacing={1}>
          			<Grid item md={6} sm={6} xs={12} justify="flex-start">
          				<Typography className={classes.header}>
          					<NotesIcon className={classes.valignTop} />
          					{"  "}
          					<span className={classes.headerText}>Report Parameters</span>
          				</Typography>
          			</Grid>
          			<Grid item md={6} sm={6} xs={12} justify="flex-end">
          				<ApiAutoComplete
          					config={SavedReportParametersApiAutoComplete.config}
          					loading={selectedCampus ? false : true}
          					id="reportParameterId"
          					reqParams={{
          						resourceId: props.reportOverview.report.id,
          						campusId: selectedCampus,
          						userId: loggedInUser.userId,
          					}}
          					classes={{
          						option: "text-black",
          					}}
          					label="Saved Parameters"
          					onChange={(e: any) => {
          						handleFieldChange(
          							"reportParameterId",
          							e ? e.value : undefined
          						);
          					}}
          					multiple={false}
          					valueFilter={
          						model.reportParameterId
          							? {
          								key: "value",
          								values: [model.reportParameterId],
          							}
          							: ""
          					}
          				></ApiAutoComplete>
          			</Grid>
          		</Grid>
          		<ReportParameters parameters={reportOverview.parameters} />
          		<div className={classes.innerContent}>
          			<Grid
          				container
          				lg={12}
          				xl={12}
          				xs={12}
          				direction="row"
          				spacing={2}
          				alignItems="center"
          			>
          				<ProgressSaveButton
          					text="Export"
          					onClick={exportEventHandler}
          					loading={exportState}
          					startIcon={<GetAppIcon />}
          				></ProgressSaveButton>
          				<Grid item md={4} sm={4} xs={12}>
          					<TextField
          						id="parameterName"
          						name="parameterName"
          						label="Parameter Name *"
          						className={classes.inputField}
          						type={InputType.TEXT}
          						value={model.parameterName ? model.parameterName : ""}
          						onChange={(v: any) => {
          							handleFieldChange(
          								"parameterName",
          								v ? v.target.value : undefined
          							);
          						}}
          					/>
          				</Grid>
          				<FormControlLabel
          					label="Shareable"
          					control={
          						<Switch
          							checked={model.shareable}
          							value="showSingleStudent"
          							onChange={(e: any) => {
          								handleFieldChange(
          									"shareable",
          									e ? e.target.checked : undefined
          								);
          							}}
          							color="primary"
          						/>
          					}
          				/>
          				<ProgressSaveButton
          					text="Save Parameters"
          					onClick={async () => {
          						await onSubmit();
          					}}
          					loading={loaderState}
          					startIcon={<SaveIcon />}
          				></ProgressSaveButton>
          			</Grid>
          		</div>
          	</Card>
          </Grid>
				}
				{!props.hidePreview && <Grid item lg={showFull ? 12 : 4} xl={showFull ? 12 : 3} xs={12}>
					<ReportPreview reportData={props.preview} isUrlPreview={props.isUrlPreview} />
					<div className={classes.mainActions}>
						<span className={classes.button}>
							<ProgressSaveButton
								text="Preview"
								onClick={() => {
									exportEventHandler(true);
								}}
								loading={props.previewLoader ?? false}
								startIcon={<VisibilityIcon />}
							></ProgressSaveButton>
						</span>
						<span className={classes.button}>
							<ProgressSaveButton
								text="Print"
								onClick={() => {
									exportEventHandler(false, true);
								}}
								loading={printLoaderState}
								startIcon={<PrintIcon />}
							></ProgressSaveButton>
						</span>
						<span className={classes.button}>
							{
								props.preview != null && <ProgressSaveButton
									text={!showFull ? "Full Screen" : "Exit Full Screen"}
									onClick={() => {
										showFullScreen();
									}}
									loading={printLoaderState}
									startIcon={<Fullscreen />}
								></ProgressSaveButton>
							}
						</span>
					</div>
				</Grid>
				}
			</Grid>
			<CustomSnackbar
				variant={snackBarProps.variant}
				message={snackBarProps.messageInfo}
				open={snackBarProps.showSnackBar}
				onClose={(event?: React.SyntheticEvent, reason?: string) => {
					setSnackBarProps((props: any) => {
						return { ...props, showSnackBar: false };
					});
				}}
			></CustomSnackbar>
		</div>
	);
};
export default ReportOverview;
