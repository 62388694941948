import API from "../../apiWrapper";
import { IEnrollmentStatus } from "../../../interfaces/student/status/IEnrollmentStatus";
import { IStatusChangeOption } from "../../../interfaces/student/status/IStatusChangeOption";		
import { IStatusChangeEnrollment } from "../../../interfaces/student/status/IStatusChangeEnrollment";
    
export const getStatusHistoryByEnrollmentId = (
    studentEnrollmentId: string
  ) => {

      return API()
          .get("/AcademicRecords/StatusChanges/StatusHistory/GetStatusHistoryByEnrollment", {
              params: {
                studentEnrollmentId
              }
          })
          .then(
              (res: any) => {
                  if (res && res.data) {
                      return res.data as Array<IEnrollmentStatus>;
                   }
              },
              (error: any) => {
                  return error;
              }
          );          
  };

  export const changeEffectiveDate = (
    enrollmentStatus: IEnrollmentStatus
  ) => {

      return API()
          .post("/AcademicRecords/StatusChanges/StatusHistory/ChangeEffectiveDate", enrollmentStatus)
          .then(
            (res: any) => {
                if (res && res.data) {
                    return res;
                }
                },
                    (error: any) => {
                    return error;
                }
            );;          
  };

  export const getStatusChangeOptions = (
    studentEnrollmentId: string
  ) => {

      return API()
          .get("/AcademicRecords/StatusChanges/StatusChange/GetStatusChangeOptions", {
              params: {
                studentEnrollmentId
              }
          })
          .then(
              (res: any) => {
                  if (res && res.data) {
                    return res.data as Array<IStatusChangeOption>;
                   }
              },
              (error: any) => {
                  return error;
              }
          );          
  };

  export const getStatusChangeOptionsForNewStatus = (
    studentEnrollmentId: string
  ) => {

      return API()
          .get("/AcademicRecords/StatusChanges/StatusChange/GetStatusChangeOptionsForAddStatus", {
              params: {
                studentEnrollmentId
              }
          })
          .then(
              (res: any) => {
                  if (res && res.data) {
                    return res.data as Array<IStatusChangeOption>;
                   }
              },
              (error: any) => {
                  return error;
              }
          );          
  };


  export const getStatusChangeEnrollment = (
    studentEnrollmentId: string
  ) => {

      return API()
          .get("/AcademicRecords/StatusChanges/StatusChange/GetStatusChangeEnrollment", {
              params: {
                studentEnrollmentId
              }
          })
          .then(
              (res: any) => {
                  if (res && res.data) {
                    return res.data as IStatusChangeEnrollment;
                   }
              },
              (error: any) => {
                  return error;
              }
          );          
  };

  export const getDropReasons = (
    campusId: string
  ) => {

      return API()
          .get("/SystemCatalog/DropReason/GetByCampus", {
              params: {
                campusId
              }
          })
          .then(
              (res: any) => {
                  if (res && res.data) {
                    return res.data as any;
                   }
              },
              (error: any) => {
                  return error;
              }
          );          
  };




