import axios, { AxiosResponse } from "axios";
import API from "../apiWrapper";
import { Campus } from "../../interfaces/systemCatalog/ICampus";

export const getUserCampuses = () => {
	return API()
		.get("/SystemCatalog/Campus/GetAllCampusesByUserId", {})
		.then(
			(res: any) => {
				if (res.status === 200 && res.data) {
					let campuses = res.data.map(
						(campus: any) => ({ id: campus.value, name: campus.text } as Campus)
					) as Array<Campus>;
					return campuses;
				}
			},
			(error: any) => {
				return error;
				// if (error.request.status === 401) {
				// 	logout();
				// 	// window.location.reload(true);
				// }
			}
		);
};
