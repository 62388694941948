import React from "react";
import {
  makeStyles,
  Card,
  createStyles,
  CardContent,
  Grid,
} from "@material-ui/core";
import CustomCardTitle from "../../../../interfaces/common/card/CustomCardTitle";
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";
import { CustomSnackBarProps } from "../../../../interfaces/common/CustomSnackBarProps";

import PhonePage from "./PhonePage";
import EmailPage from "./EmailPage";
import AddressPage from "./AddressPage";
import NotesPage from "./NotesPage";
import OtherContactsPage from "./OtherContactsPage";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      minHeight: "100%",
      height: "100%",
      width: "100%",
      margin: "auto",
    },
    paperRoot: {
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
    },
  })
);

const ViewAllContacts = (props: any) => {
  const classes = useStyles({});
  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );
  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classes.paperRoot}>
          <CustomSnackbar
            variant={snackBarProps.variant}
            message={snackBarProps.messageInfo}
            open={snackBarProps.showSnackBar}
            onClose={(event?: React.SyntheticEvent, reason?: string) => {
              setSnackBarProps((props: any) => {
                return { ...props, showSnackBar: false };
              });
            }}
          ></CustomSnackbar>
          <Card square={true}>
            <CustomCardTitle title="Student Information"></CustomCardTitle>
            <CardContent>
              <Grid container direction="column" spacing={2}>
                <Grid
                  item
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <PhonePage setSnackBarProps={setSnackBarProps} />
                  <EmailPage setSnackBarProps={setSnackBarProps} />
                  <AddressPage setSnackBarProps={setSnackBarProps} />
                  <NotesPage setSnackBarProps={setSnackBarProps} />
                  <OtherContactsPage setSnackBarProps={setSnackBarProps} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewAllContacts;
