import React, { useEffect } from 'react';
import {
	makeStyles,
	Card,
	createStyles,
	CardContent,
	Grid,
	CircularProgress,
	Button,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from '@material-ui/core';
import InputField from '../../components/_Layout/Inputs/InputField';
import { InputType } from '../../constants/uiConstants/inputConstants';
import CustomCardTitle from '../../interfaces/common/card/CustomCardTitle';
import { useSelector } from 'react-redux';
import * as StudentAppConfigApi from '../../api/integration/studentAppIntegration';
import CustomSnackbar from '../../components/notifications/CustomSnackbar';
import { CustomSnackBarProps } from '../../interfaces/common/CustomSnackBarProps';
import WeekDaysAutoComplete from '../../components/AutoComplete/WeekDaysAutoComplete';
import { FieldError, useForm, FormContext } from 'react-hook-form';
import ProgressSaveButton from '../../components/_Layout/Buttons/ProgressSaveButton';
import { MuiPickersUtilsProvider, KeyboardTimePicker, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { parse } from 'date-fns';
import { IStudentAppIntegrationConfiguration } from '../../interfaces/integration/IStudentAppIntegrationConfiguration';

const useStyles = makeStyles((theme: any) =>
	createStyles({
		cardTitle: {
			fontSize: 17,
			color: theme.palette.black,
			textDecoration: 'bold',
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1),
			marginBottom: theme.spacing(1),
		},
		cardContent: {
			padding: theme.spacing(1, 3),
			height: '100%',
		},
		saveButton: {
			width: 130,
		},
		cardMain: {
			maxWidth: "1050px",
			margin: 'auto',
			marginTop: theme.spacing(2),
			minHeight: "100%",
			height: "100%",
			overflowX: "hidden",
			overflowY: "auto",
			flexDirection: "column",
		},
		alignCenter: {
			textAlign: 'center',
		},
		cardWrapper: {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
		},
		timeField: {
			width: '120px',
			height: '46px',
		},
	})
);

const StudentAppConfiguration = () => {
	const classes = useStyles({});
	const [loading, setLoading] = React.useState<boolean>(false);
	const [syncType, setSyncType] = React.useState<string | null>(null);

	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const [model, setModel] = React.useState<IStudentAppIntegrationConfiguration>({
		configurationId: null,
		campusId: userSelectedCampus,
		apiKey: '',
		sendDataByLDAWithIn: null,
		sendDataHh: null,
		sendDataMm: null,
		sendDocumentsOnWeekDays: [],
		sendDocumentOnTime: '',
	});

	useEffect(() => {
		if (userSelectedCampus) {
			handleFieldChange('campusId', userSelectedCampus);
			getCampusConfiguration(userSelectedCampus);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userSelectedCampus]);

	const data = useForm<IStudentAppIntegrationConfiguration>({ mode: 'onBlur' });

	const { handleSubmit } = data;

	const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
		{
			showSnackBar: false,
			messageInfo: undefined,
			variant: 'info',
		}
	);

	const getCampusConfiguration = (campusId: string) => {
		setLoading(true);
		StudentAppConfigApi.getStudentAppConfiguration(campusId).then(
			(response: any) => {
				setLoading(false);
				if (response && response.result !== null) {
					setModel({ ...response.result });
				}
				else {
					setModel({
						configurationId: null,
						campusId: userSelectedCampus,
						apiKey: '',
						sendDataByLDAWithIn: null,
						sendDataHh: null,
						sendDataMm: null,
						sendDocumentsOnWeekDays: [],
						sendDocumentOnTime: '',
					});
				}
			},
			(exception: any) => {
				setLoading(false);
				setSnackBarProps(() => {
					return {
						variant: 'error',
						showSnackBar: true,
						messageInfo: exception,
					};
				});
			}
		);
	};

	const syncMobileAppData = (type: string | null) => {
		if (!loading) {
			setLoading(true);
			setSyncType(type);
			StudentAppConfigApi.syncMobileAppData(userSelectedCampus, type).then(
				(response: any) => {
					setLoading(false);
					if (response && response.result) {
						setSnackBarProps(() => {
							return {
								variant: 'success',
								showSnackBar: true,
								messageInfo: response.resultStatusMessage,
							};
						});
					}
				},
				(exception: any) => {
					setLoading(false);
					setSnackBarProps(() => {
						return {
							variant: 'error',
							showSnackBar: true,
							messageInfo: exception,
						};
					});
				}
			);
		}
	};

	const saveConfiguration = async () => {
		if (!loading) {
			setLoading(true);
			StudentAppConfigApi.upsertConfiguration(model).then(
				(response: any) => {
					setLoading(false);
					if (response && response.result) {
						setModel(model);
						setSnackBarProps(() => {
							return {
								variant: 'success',
								showSnackBar: true,
								messageInfo: response.resultStatusMessage,
							};
						});
					}
				},
				(exception: any) => {
					setLoading(false);
					setSnackBarProps(() => {
						return {
							variant: 'error',
							showSnackBar: true,
							messageInfo: exception,
						};
					});
				}
			);
			setLoading(false);
		}
	};

	const handleFieldChange = (
		fieldId: string,
		value: any,
		mapFunction?: Function
	) => {
		let updatedModel = model;
		let newValue = mapFunction ? mapFunction(value) : value;
		(updatedModel as any)[fieldId] = newValue;
		setModel({ ...updatedModel });
	};

	const setWeekDays = (studentGroups: any) => {
		let newGroups: string[] = [];
		if (studentGroups !== null) {
			if (Array.isArray(studentGroups)) {
				newGroups = studentGroups.map((gr: any) => gr.value);
			} else {
				newGroups.push(studentGroups.value);
			}
		}
		return newGroups;
	};

	const cancel = () => {
		setModel({
			configurationId: null,
			campusId: userSelectedCampus,
			apiKey: '',
			sendDataByLDAWithIn: null,
			sendDataHh: null,
			sendDataMm: null,
			sendDocumentsOnWeekDays: [],
			sendDocumentOnTime: '',
		});
	};

	return (
		<React.Fragment>
			<CustomSnackbar
				variant={snackBarProps.variant}
				message={snackBarProps.messageInfo}
				open={snackBarProps.showSnackBar}
				onClose={() => {
					setSnackBarProps((props: any) => {
						return { ...props, showSnackBar: false };
					});
				}}
			></CustomSnackbar>
			<Card square={true} elevation={12} className={classes.cardMain}>
				<CustomCardTitle title='FAME Student App Integration Configuration'></CustomCardTitle>
				<CardContent>
					<Grid container direction="column" spacing={4} wrap="nowrap">
						<Grid
							item
							alignContent='flex-start'
							alignItems='flex-start'
							justify='flex-start'
						>
							<FormContext {...data}>
								<form onSubmit={handleSubmit(() => saveConfiguration())}>
									<Grid container direction="row" spacing={1}>
										<Grid item xs={12} sm={12} md={12}>
											<InputField
												type={InputType.TEXT}
												key="apiKey"
												id="apiKey"
												label="API Key *"
												name="apiKey"
												defaultValue={model?.apiKey}
												onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
													handleFieldChange('apiKey', e.target.value);
												}}
												error={!!data.errors.apiKey}
												inputRef={data.register({
													validate: {
														dataRequired: () =>
															(model.apiKey !== null) ||
															'API key is required.',
														stringLength: () =>
															(model.apiKey && model.apiKey !== null && model.apiKey.length < 250) ||
															'API key length must be less then 250.',
													},
												})}
												helperText={
													data.errors.apiKey
														? (data.errors.apiKey as FieldError).message
														: undefined
												}
											/>
										</Grid>
									</Grid>
									<Grid container direction="row" spacing={2}>
										<Grid item xs={12} sm={12} md={12}>
											<InputField
												type={InputType.NUMBER}
												key="sendDataByLDAWithIn"
												id="sendDataByLDAWithIn"
												label="Send Data for Students With LDA Within *"
												name="sendDataByLDAWithIn"
												defaultValue={model?.sendDataByLDAWithIn}
												onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
													handleFieldChange('sendDataByLDAWithIn', e.target.value);
												}}
												error={!!data.errors.sendDataByLDAWithIn}
												inputRef={data.register({
													validate: {
														dataRequired: () =>
															(model.sendDataByLDAWithIn !== null) ||
															'Field is required.',
														daysRange: () =>
															(model.sendDataByLDAWithIn && model.sendDataByLDAWithIn !== null && model.sendDataByLDAWithIn >= 0 && model.sendDataByLDAWithIn <= 365) ||
															'Enter day(s) between 0 and 365.',
													},
												})}
												helperText={
													data.errors.sendDataByLDAWithIn
														? (data.errors.sendDataByLDAWithIn as FieldError).message
														: undefined
												}
											/>
										</Grid>
									</Grid>
									<Grid container direction="row" spacing={2}>
										<Grid item xs={12} sm={12} md={12}>
											<Card square={true} className={classes.cardWrapper}>
												<CustomCardTitle title={'Export Data Every'}></CustomCardTitle>
												<CardContent>
													<Grid container direction="row" spacing={2}>
														<Grid item xs={6} sm={6} md={6}>
															<InputField
																type={InputType.NUMBER}
																key="sendDataHh"
																id="sendDataHh"
																label="Hours *"
																name="sendDataHh"
																defaultValue={model?.sendDataHh}
																onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
																	handleFieldChange('sendDataHh', e.target.value);
																}}
																error={!!data.errors.sendDataHh}
																inputRef={data.register({
																	validate: {
																		dataRequired: () =>
																			(model.sendDataHh !== null) ||
																			'Hour(s) are required.',
																		hoursRange: () =>
																			(model.sendDataHh && model.sendDataHh !== null && model.sendDataHh >= 0 && model.sendDataHh <= 8766) ||
																			'Enter hourse between 0 and 8766.',
																	},
																})}
																helperText={
																	data.errors.sendDataHh
																		? (data.errors.sendDataHh as FieldError).message
																		: undefined
																}
															/>
														</Grid>
														<Grid item xs={6} sm={6} md={6}>
															<InputField
																type={InputType.NUMBER}
																key="sendDataMm"
																id="sendDataMm"
																label="Minuts *"
																name="sendDataMm"
																defaultValue={model?.sendDataMm}
																onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
																	handleFieldChange('sendDataMm', e.target.value);
																}}
																error={!!data.errors.sendDataMm}
																inputRef={data.register({
																	validate: {
																		dataRequired: () =>
																			(model.sendDataMm !== null) ||
																			'Minut(s) are required.',
																		hoursRange: () =>
																			(model.sendDataMm && model.sendDataMm !== null && model.sendDataMm >= 0 && model.sendDataMm <= 60) ||
																			'Enter minuts between 0 and 60.',
																	},
																})}
																helperText={
																	data.errors.sendDataMm
																		? (data.errors.sendDataMm as FieldError).message
																		: undefined
																}
															/>
														</Grid>
													</Grid>
												</CardContent>
											</Card>
										</Grid>
										<Grid item xs={12} sm={12} md={12}>
											<Card square={true} className={classes.cardWrapper}>
												<CustomCardTitle title={'Send Document'}></CustomCardTitle>
												<CardContent>
													<Grid container direction="row" spacing={2}>
														<Grid item xs={9} sm={6} md={9}>
															<WeekDaysAutoComplete
																filterHandle={(statuses: any) => {
																	handleFieldChange(
																		'sendDocumentsOnWeekDays',
																		statuses,
																		setWeekDays
																	);
																}}
																id="sendDocumentsOnWeekDays"
																name="sendDocumentsOnWeekDays"
																label="Week Day(s) *"
																error={!!data.errors.sendDocumentsOnWeekDays}
																inputRef={data.register({
																	validate: {
																		atLeastOneRequired: () =>
																			(model.sendDocumentsOnWeekDays && model.sendDocumentsOnWeekDays.length > 0) ||
																			'Week Day(s) are required.',
																	},
																})}
																helperText={
																	data.errors.sendDocumentsOnWeekDays
																		? 'At least one day is required.'
																		: undefined
																}
																valueFilter={
																	model?.sendDocumentsOnWeekDays
																		? {
																			key: 'value',
																			values: model?.sendDocumentsOnWeekDays,
																		}
																		: undefined
																}
																multiple={true}
															/>
														</Grid>
														<Grid item xs={3} sm={6} md={3}>
															<div>at</div>
															<MuiPickersUtilsProvider utils={DateFnsUtils}>
																<KeyboardTimePicker
																	className={classes.timeField}
																	mask="__:__ _M"
																	name="sendDocumentOnTime"
																	value={
																		model?.sendDocumentOnTime
																			? parse(
																				model?.sendDocumentOnTime,
																				'hh:mm a',
																				new Date()
																			)
																			: null
																	}
																	onChange={(_date, value) =>
																		handleFieldChange(
																			'sendDocumentOnTime',
																			value || ''
																		)
																	}
																	error={!!data.errors.sendDocumentOnTime}
																	inputRef={data.register({ required: 'Time is required.' })}
																	helperText={
																		data.errors.sendDocumentOnTime
																			? (data.errors.sendDocumentOnTime as FieldError).message
																			: undefined
																	}
																/>
															</MuiPickersUtilsProvider>
														</Grid>
													</Grid>
												</CardContent>
											</Card>
										</Grid>
										<Grid item xs={12} sm={12} md={12}>
											<TableContainer component={Paper}>
												<Table>
													<TableHead>
														<TableRow>
															<TableCell>Sr. No</TableCell>
															<TableCell>Type</TableCell>
															<TableCell>Description</TableCell>
															<TableCell>SYNC</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														<TableRow>
															<TableCell>1</TableCell>
															<TableCell>Enrollments</TableCell>
															<TableCell>Sync enrollments data for selected campus.</TableCell>
															<TableCell>
																<ProgressSaveButton
																	key="btnEnrollment"
																	text="Sync"
																	onClick={handleSubmit(() => syncMobileAppData("enrollments"))}
																	loading={syncType == 'enrollments' && loading}
																	disabled={false}
																	buttonClassName={classes.saveButton}
																	size="small"
																></ProgressSaveButton></TableCell>
														</TableRow>
														<TableRow>
															<TableCell>2</TableCell>
															<TableCell>Grades</TableCell>
															<TableCell>Sync grades data for selected campus.</TableCell>
															<TableCell>
																<ProgressSaveButton
																	key="btnGrades"
																	text="Sync"
																	onClick={handleSubmit(() => syncMobileAppData("grades"))}
																	loading={syncType == 'grades' && loading}
																	disabled={false}
																	buttonClassName={classes.saveButton}
																	size="small"
																></ProgressSaveButton></TableCell>
														</TableRow>
														<TableRow>
															<TableCell>3</TableCell>
															<TableCell>Punches</TableCell>
															<TableCell>Sync punches data for selected campus.</TableCell>
															<TableCell>
																<ProgressSaveButton
																	key="btnPunches"
																	text="Sync"
																	onClick={handleSubmit(() => syncMobileAppData("punches"))}
																	loading={syncType == 'punches' && loading}
																	disabled={false}
																	buttonClassName={classes.saveButton}
																	size="small"
																></ProgressSaveButton></TableCell>
														</TableRow>
														<TableRow>
															<TableCell>4</TableCell>
															<TableCell>Holidays</TableCell>
															<TableCell>Sync holidays data for selected campus.</TableCell>
															<TableCell>
																<ProgressSaveButton
																	key="btnHolidays"
																	text="Sync"
																	onClick={handleSubmit(() => syncMobileAppData("holidays"))}
																	loading={syncType == 'holidays' && loading}
																	disabled={false}
																	buttonClassName={classes.saveButton}
																	size="small"
																></ProgressSaveButton></TableCell>
														</TableRow>
														<TableRow>
															<TableCell>5</TableCell>
															<TableCell>Payment Plans</TableCell>
															<TableCell>Sync payment plan data for selected campus.</TableCell>
															<TableCell>
																<ProgressSaveButton
																	key="btnPaymentPlan"
																	text="Sync"
																	onClick={handleSubmit(() => syncMobileAppData("paymentPlan"))}
																	loading={syncType == 'paymentPlan' && loading}
																	disabled={false}
																	buttonClassName={classes.saveButton}
																	size="small"
																></ProgressSaveButton></TableCell>
														</TableRow>
														<TableRow>
															<TableCell>6</TableCell>
															<TableCell>Schedules</TableCell>
															<TableCell>Sync schedule data for selected campus.</TableCell>
															<TableCell>
																<ProgressSaveButton
																	key="btnSchedules"
																	text="Sync"
																	onClick={handleSubmit(() => syncMobileAppData("schedule"))}
																	loading={syncType == 'schedule' && loading}
																	disabled={false}
																	buttonClassName={classes.saveButton}
																	size="small"
																></ProgressSaveButton></TableCell>
														</TableRow>
														<TableRow>
															<TableCell>7</TableCell>
															<TableCell>Student Group</TableCell>
															<TableCell>Sync students group data for selected campus.</TableCell>
															<TableCell>
																<ProgressSaveButton
																	key="btnStudentGroup"
																	text="Sync"
																	onClick={handleSubmit(() => syncMobileAppData("studentGroup"))}
																	loading={syncType == 'studentGroup' && loading}
																	disabled={false}
																	buttonClassName={classes.saveButton}
																	size="small"
																></ProgressSaveButton></TableCell>
														</TableRow>
														<TableRow>
															<TableCell>8</TableCell>
															<TableCell>Title IV SAP</TableCell>
															<TableCell>Sync title IV data for selected campus.</TableCell>
															<TableCell>
																<ProgressSaveButton
																	key="btnTitleIV"
																	text="Sync"
																	onClick={handleSubmit(() => syncMobileAppData("titleIv"))}
																	loading={syncType == 'titleIv' && loading}
																	disabled={false}
																	buttonClassName={classes.saveButton}
																	size="small"
																></ProgressSaveButton></TableCell>
														</TableRow>
														<TableRow>
															<TableCell>9</TableCell>
															<TableCell>Student Awards</TableCell>
															<TableCell>Sync student awards data for selected campus.</TableCell>
															<TableCell>
																<ProgressSaveButton
																	key="btnStudentAwards"
																	text="Sync"
																	onClick={handleSubmit(() => syncMobileAppData("studentAwards"))}
																	loading={syncType == 'studentAwards' && loading}
																	disabled={false}
																	buttonClassName={classes.saveButton}
																	size="small"
																></ProgressSaveButton></TableCell>
														</TableRow>
														<TableRow>
															<TableCell>10</TableCell>
															<TableCell>Ledger</TableCell>
															<TableCell>Sync ledger data for selected campus.</TableCell>
															<TableCell>
																<ProgressSaveButton
																	key="btnLedger"
																	text="Sync"
																	onClick={handleSubmit(() => syncMobileAppData("ledger"))}
																	loading={syncType == 'ledger' && loading}
																	disabled={false}
																	buttonClassName={classes.saveButton}
																	size="small"
																></ProgressSaveButton></TableCell>
														</TableRow>
														<TableRow>
															<TableCell>11</TableCell>
															<TableCell>Document</TableCell>
															<TableCell>Sync all documents for selected campus.</TableCell>
															<TableCell>
																<ProgressSaveButton
																	key="btnDocument"
																	text="Sync"
																	onClick={handleSubmit(() => syncMobileAppData("document"))}
																	loading={syncType == 'document' && loading}
																	disabled={false}
																	buttonClassName={classes.saveButton}
																	size="small"
																></ProgressSaveButton></TableCell>
														</TableRow>
													</TableBody>
												</Table>
											</TableContainer>
										</Grid>
									</Grid>
								</form>
							</FormContext>
						</Grid>
						<Grid
							item
							alignContent='flex-start'
							alignItems='flex-start'
							justify='flex-start'
						>
							<Grid container direction='row' spacing={1} wrap="nowrap">
								<Grid
									item
									alignContent='flex-start'
									alignItems='flex-start'
									justify='flex-start'
								>
									<ProgressSaveButton
										key="btAll"
										text="Save"
										onClick={handleSubmit(() => saveConfiguration())}
										loading={syncType == null && loading}
										disabled={false}
										buttonClassName={classes.saveButton}
										size="small"
									></ProgressSaveButton>
								</Grid>
								<Grid
									item
									alignContent='flex-start'
									alignItems='flex-start'
									justify='flex-start'
								>
									<ProgressSaveButton
										text="Sync All Data"
										onClick={handleSubmit(() => syncMobileAppData(null))}
										loading={syncType == null && loading}
										disabled={false}
										buttonClassName={classes.saveButton}
										size="small"
									></ProgressSaveButton>
								</Grid>
								<Grid
									item
									alignContent='flex-start'
									alignItems='flex-start'
									justify='flex-start'
								>
									<Button
										disabled={syncType == null && loading}
										className={classes.saveButton}
										onClick={cancel}
										size='small'
										color='secondary'
										variant='contained'
										type='button'
									>Cancel</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</React.Fragment >
	);
};

export default StudentAppConfiguration;