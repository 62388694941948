import React from 'react';
//import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Checkbox,
	Typography,
	CardContent,
	Card,
	Button,
	Grid,
	Link,
	IconButton,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import CustomSnackbar from '../../components/notifications/CustomSnackbar';
import { CustomSnackBarProps } from '../../interfaces/common/CustomSnackBarProps';
import * as LeaveOfAbsenceWidgetApi from '../../../src/api/dashboard/LeaveOfAbsenceWidgetApi';
import AccessManager from '../../components/security/AccessManager';
import { security } from '../../constants/Security/securityConstants';
import RefreshIcon from '@material-ui/icons/Refresh';
import { withRouter } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import makeStyles from './WidgetStyles';
import LeaveOfAbsenceModal from '../../pages/student/status/LeaveOfAbsenceModal';
import * as StatusHistoryApi from '../../api/student/status/StatusHistoryApi';
import { IStatusChangeEnrollment } from '../../interfaces/student/status/IStatusChangeEnrollment';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles;

function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3} style={{ padding: '0px' }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

function LeaveOfAbsenceWidget() {
	const classes = useStyles();
	const [LOAExpiredData, setLOAExpiredData] = React.useState([] as any);
	const [LOAData, setLOAData] = React.useState([] as any);
	const [CurrentlyOnLOA, setCurrentlyOnLOA] = React.useState([] as any);
	const [selected, setSelected] = React.useState([] as any);
	const [selectAll, setSelectAll] = React.useState(false);

	const selectAllClick = (event: any) => {
		setSelectAll(event.target.checked);
	};
	const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
		{
			showSnackBar: false,
			messageInfo: undefined,
			variant: 'info',
		}
	);
	const rowClick = (event: any, fieldName: string, row: any) => {
		const selectedIndex = LOAData.findIndex(
			(r: any) => r.studentLeaveOfAbsenceId === row.studentLeaveOfAbsenceId
		);

		let newData = [...LOAData];
		if (fieldName === 'isContacted') {
			newData[selectedIndex] = {
				...newData[selectedIndex],
				isContacted: event.target.checked,
			};
		}
		setLOAData(newData);

		const sIndex = selected.findIndex(
			(r: any) => r.studentLeaveOfAbsenceId === row.studentLeaveOfAbsenceId
		);

		let newSelected = [...selected];
		if (sIndex === -1) {
			newSelected = newSelected.concat({
				StudentLeaveOfAbsenceId: row.studentLeaveOfAbsenceId,
				IsContacted: event.target.checked,
			});
		} else {
			newSelected[sIndex] = {
				...newSelected[sIndex],
				isContacted: event.target.checked,
			};
		}
		setSelected(newSelected);
	};

	const LoadLOAWidgetData = (campusId: any) => {
		const params = {
			campusId: campusId,
			showAll: selectAll,
		};

		setLOAData([] as any);
		setLOAExpiredData([] as any);
		LeaveOfAbsenceWidgetApi.GetLeaveOfAbsenceListByCampusId(params).then(
			(response: any) => {
				if (response) {
					if (response.data !== undefined) {
						let loaExpired = response.data.filter(
							(x: any) => x.daysExpired !== 0 && x.daysExpired !== null
						);
						let loaNotExpired = response.data.filter(
							(x: any) => x.daysExpired === 0
						);
						let allLOA = response.data;
						setLOAData(loaNotExpired);
						setLOAExpiredData(loaExpired);
						setCurrentlyOnLOA(allLOA);
					}
				}
			},
			(_exception: any) => {}
		);
	};

	const campusId = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const resetPage = () => {
		setLOAData([]);
		setLOAExpiredData([]);
		setCurrentlyOnLOA([]);
		setSelected([]);
	};

	const handelReloadPage = () => {
		resetPage();
		setSelectAll(false);
		LoadLOAWidgetData(campusId);
	};

	React.useEffect(() => {
		if (campusId) {
			resetPage();
			LoadLOAWidgetData(campusId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [campusId, selectAll]);

	const saveLoaStatus = () => {
		if (selected.length > 0) {
			LeaveOfAbsenceWidgetApi.UpdateLOAStatus(selected).then(
				(response: any) => {
					if (response.status === 200) {
						setSnackBarProps((_props: any) => {
							return {
								variant: 'success',
								showSnackBar: true,
								messageInfo: 'Leave of absence successfully updated.',
							};
						});
						setSelected([]);
						LoadLOAWidgetData(campusId);
					} else {
						setSnackBarProps((_props: any) => {
							return {
								variant: 'error',
								showSnackBar: true,
								messageInfo:
                  'Leave of absence could not be updated. Please try again.',
							};
						});
					}
				},
				(_exception: any) => {}
			);
		}
	};
	const [changeEnrollment, setChangeEnrollment] =
    React.useState<IStatusChangeEnrollment>();
	const [LeaveOfAbsenceModalOpen, setLeaveOfAbsenceModalOpen] =
    React.useState(false);
	const [enrollmentId, setEnrollmentId] = React.useState<string | null>(null);
	const refreshChangeEnrollment = () => {
		if (enrollmentId) {
			StatusHistoryApi.getStatusChangeEnrollment(enrollmentId).then(
				(response: IStatusChangeEnrollment) => {
					if (response) {
						setChangeEnrollment(response);
						setLeaveOfAbsenceModalOpen(true);
					}
				},
				(_exception: any) => {}
			);
		}
	};
	React.useEffect(() => {
		refreshChangeEnrollment();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enrollmentId]);

	const handleSelectedEnrollment = (enrollmentId: any) => {
		setEnrollmentId(enrollmentId);
	};

	const handleStatusUpdateEvent = (_newStatus: any) => {};

	const [value, setValue] = React.useState(0);

	const handleChange = (_index: any, newValue: any) => {
		setValue(newValue);
	};
	React.useEffect(() => {}, [value]);

	return (
		<AccessManager
			allowedPermissions={[
				security.permissions.dashboard.ViewLeaveOfAbsenceWidget,
			]}
			renderNoAccess={() => null}
		>
			<Card className={classes.root}>
				<Typography className={clsx(classes.cardTitle)}>
					<Grid container direction="row" justify="center" alignItems="center">
						<Grid item xs={6}>
							<div className={classes.headerText}>STUDENTS ON LOA</div>
						</Grid>
						<Grid item xs={6} className={classes.widgetMenu}>
							<Typography className={classes.headerTextRight}></Typography>
							<IconButton
								className={classes.iconBtn}
								aria-label="settings"
								onClick={handelReloadPage}
							>
								<RefreshIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Typography>
				<CardContent className={classes.cardContent}>
					<div className={classes.innerRoot}>
						<Tabs
							value={value}
							onChange={handleChange}
							className={classes.tabStyle}
							indicatorColor="primary"
						>
							<Tab
								label={'Expired (' + LOAExpiredData.length + ')'}
								{...a11yProps(0)}
							/>
							<Tab
								label={'Expiring (' + LOAData.length + ')'}
								{...a11yProps(1)}
							/>
							<Tab
								label={'Currently On LOA (' + CurrentlyOnLOA.length + ')'}
								{...a11yProps(2)}
							/>
						</Tabs>
						<TableContainer component={Paper}>
							<TabPanel value={value} index={0}>
								<Table
									className={classes.table}
									size="small"
									aria-label="a dense table"
								>
									<TableHead>
										<TableRow>
											<TableCell align="left" className={classes.headCell}>
                        Enrollment
											</TableCell>
											<TableCell align="left" className={classes.headCell}>
                        LOA Start
											</TableCell>
											<TableCell align="left" className={classes.headCell}>
                        LOA End
											</TableCell>
											<TableCell align="center" className={classes.headCell}>
                        Days Expired
											</TableCell>
											<TableCell align="center" className={classes.headCell}>
                        LOA Days in 12 Mos
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{LOAExpiredData !== null && LOAExpiredData.length > 0 ? (
											LOAExpiredData.map((row: any, index: number) => {
												return (
													<TableRow role="checkbox" key={`row-${index}`}>
														<TableCell
															align="left"
															className={classes.bodyCell}
														>
															<Link
																className={classes.linkDesign}
																component="button"
																onClick={() => {
																	handleSelectedEnrollment(
																		row.studentEnrollmentId
																	);
																}}
															>
																{row.firstName +
                                  ' ' +
                                  row.lastName +
                                  ' ( ' +
                                  row.programVersionDescription +
                                  ' )'}
															</Link>
														</TableCell>
														<TableCell align="left">
															{new Date(row.startDate).toLocaleDateString()}
														</TableCell>
														<TableCell align="left">
															{new Date(row.endDate).toLocaleDateString()}
														</TableCell>
														<TableCell align="center">
															{row.daysExpired}
														</TableCell>
														<TableCell align="center">
															{row.loA12Months}
														</TableCell>
													</TableRow>
												);
											})
										) : (
											<TableRow>
												<TableCell align="center" colSpan={5}>
                          No Data
												</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</TabPanel>
							<TabPanel value={value} index={1}>
								<Grid
									container
									direction="row"
									justify="center"
									alignItems="center"
								>
									<Grid item xs={12}>
										<Typography
											className={classes.headerTextRight}
											style={{ float: 'right' }}
										>
                      Show All
											<Checkbox
												value="uncontrolled"
												color="primary"
												onClick={selectAllClick}
												inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
												checked={selectAll}
											/>
										</Typography>
									</Grid>
								</Grid>
								<Table
									className={classes.table}
									size="small"
									aria-label="a dense table"
								>
									<TableHead>
										<TableRow>
											<TableCell align="left" className={classes.headCell}>
                        Enrollment
											</TableCell>
											<TableCell align="left" className={classes.headCell}>
                        LOA Start
											</TableCell>
											<TableCell align="left" className={classes.headCell}>
                        LOA End
											</TableCell>
											<TableCell align="center" className={classes.headCell}>
                        LOA Days in 12 Mos
											</TableCell>
											<TableCell align="center" className={classes.headCell}>
                        Contacted ?
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{LOAData !== null && LOAData.length > 0 ? (
											LOAData.map((row: any, index: number) => {
												return (
													<TableRow role="checkbox" key={`row-${index}`}>
														<TableCell
															align="left"
															className={classes.bodyCell}
														>
															<Link
																className={classes.linkDesign}
																component="button"
																onClick={() => {
																	handleSelectedEnrollment(
																		row.studentEnrollmentId
																	);
																}}
															>
																{row.firstName +
                                  ' ' +
                                  row.lastName +
                                  ' ( ' +
                                  row.programVersionDescription +
                                  ' )'}
															</Link>
														</TableCell>
														<TableCell align="left">
															{new Date(row.startDate).toLocaleDateString()}
														</TableCell>
														<TableCell align="left">
															{new Date(row.endDate).toLocaleDateString()}
														</TableCell>
														<TableCell align="center">
															{row.loA12Months}
														</TableCell>
														<TableCell align="center">
															<Checkbox
																className={classes.checkBoxStyle}
																value="uncontrolled"
																color="primary"
																onClick={(event) =>
																	rowClick(event, 'isContacted', row)
																}
																inputProps={{
																	'aria-label': 'uncontrolled-checkbox',
																}}
																checked={row.isContacted}
															/>
														</TableCell>
													</TableRow>
												);
											})
										) : (
											<TableRow>
												<TableCell align="center" colSpan={5}>
                          No Data
												</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</TabPanel>
							<TabPanel value={value} index={2}>
								<Table
									className={classes.table}
									size="small"
									aria-label="a dense table"
								>
									<TableHead>
										<TableRow>
											<TableCell align="left" className={classes.headCell}>
                        Enrollment
											</TableCell>
											<TableCell align="left" className={classes.headCell}>
                        LOA Start
											</TableCell>
											<TableCell align="left" className={classes.headCell}>
                        LOA End
											</TableCell>
											<TableCell align="center" className={classes.headCell}>
                        LOA Days in 12 Mos
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{CurrentlyOnLOA !== null && CurrentlyOnLOA.length > 0 ? (
											CurrentlyOnLOA.map((row: any, index: number) => {
												return (
													<TableRow role="checkbox" key={`row-${index}`}>
														<TableCell
															align="left"
															className={classes.bodyCell}
														>
															<Link
																className={classes.linkDesign}
																component="button"
																onClick={() => {
																	handleSelectedEnrollment(
																		row.studentEnrollmentId
																	);
																}}
															>
																{row.firstName +
                                  ' ' +
                                  row.lastName +
                                  ' ( ' +
                                  row.programVersionDescription +
                                  ' )'}
															</Link>
														</TableCell>
														<TableCell align="left">
															{new Date(row.startDate).toLocaleDateString()}
														</TableCell>
														<TableCell align="left">
															{new Date(row.endDate).toLocaleDateString()}
														</TableCell>
														<TableCell align="center">
															{row.loA12Months}
														</TableCell>
													</TableRow>
												);
											})
										) : (
											<TableRow>
												<TableCell align="center" colSpan={5}>
                          No Data
												</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</TabPanel>
						</TableContainer>
						<Box bottom={7} left={7}>
							<AccessManager
								allowedPermissions={[
									security.permissions.dashboard.UpdateLeaveOfAbsenceWidget,
								]}
								renderNoAccess={() => (
									<Button
										disabled={true}
										onClick={() => {
											saveLoaStatus();
										}}
										size="small"
										className={classes.button}
										type="button"
										color="primary"
									>
                    SAVE
									</Button>
								)}
							>
								<Button
									className={classes.buttonColorPrimary}
									onClick={() => {
										saveLoaStatus();
									}}
									size="small"
									type="button"
								>
                  SAVE
								</Button>
							</AccessManager>
						</Box>
					</div>
				</CardContent>
				<CustomSnackbar
					variant={snackBarProps.variant}
					message={snackBarProps.messageInfo}
					open={snackBarProps.showSnackBar}
					onClose={(_event?: React.SyntheticEvent, _reason?: string) => {
						setSnackBarProps((props: any) => {
							return { ...props, showSnackBar: false };
						});
					}}
				></CustomSnackbar>
			</Card>
			<LeaveOfAbsenceModal
				open={LeaveOfAbsenceModalOpen}
				setOpen={setLeaveOfAbsenceModalOpen}
				updateEvent={handleStatusUpdateEvent}
				newStatusCode={null}
				newStatus={null}
				statusChangeEnrollment={changeEnrollment}
				loaWidgetEnrollment={enrollmentId}
			/>
		</AccessManager>
	);
}

export default withRouter(LeaveOfAbsenceWidget);
