import { StudentProfile } from '../../interfaces/student/profile/StudentProfile';
import { AdvanceSearchModel } from '../../interfaces/student/search/AdvanceSearchModel';
import { createReducer } from '../utils';

const { reducer, actions, selectors, types } = createReducer(
	{
		sync: {
			addToSelected: (profile: Array<StudentProfile>) => (profile),
			remove: (indices: Array<Number>) => (indices),
			reset: () => undefined,
		},
		asyncs: {
			loadStudents: {
				request: (search: AdvanceSearchModel) => (search),
				success: (payload: { data: Array<StudentProfile>, hasMoreData: boolean, total: number }) => (payload),
				failure: (message: string) => ({ message })
			},
			loadMoreStudents: {
				request: (search: AdvanceSearchModel) => (search),
				success: (payload: { data: Array<StudentProfile>, hasMoreData: boolean, total: number }) => (payload),
				failure: (message: string) => ({ message })
			}
		}
	},
	{
		data: [] as Array<StudentProfile>,
		selected: [] as Array<StudentProfile>,
		filters: {} as AdvanceSearchModel,
		total: 0,
		hasMore: false
	},
	{
		sync: {
			addToSelected: (state, action) => {
				const selected = state.data.selected;
				//find the ones that are already not selected
				const toAdd: Array<StudentProfile> = action.data.filter(m => !selected.find((n) => n.currentEnrollmentId === m.currentEnrollmentId));
				return { ...state, data: { ...state.data, selected: [...state.data.selected, ...(toAdd)] } };
			},
			remove: (state, action) => {
				const selected = state.data.selected;
				const updated: Array<StudentProfile> = selected.filter((_m, i) => !action.data.includes(i));
				return { ...state, data: { ...state.data, selected: updated } };
			},
			reset: (state) => ({
				...state, data: {
					data: [] as Array<StudentProfile>,
					selected: [] as Array<StudentProfile>,
					filters: {} as AdvanceSearchModel,
					total: 0,
					hasMore: false
				}
			})
		},
		asyncs: {
			loadStudents: {
				request: (state, action) => ({ ...state, data: { ...state.data, data: [], filters: action.data } }),
				success: (state, action) => ({ ...state, data: { ...state.data, data: action.data.data, hasMore: action.data.hasMoreData, total: action.data.total } }),
				failure: (state, _action) => (state)
			},
			loadMoreStudents: {
				request: (state, action) => ({ ...state, data: { ...state.data, filters: action.data } }),
				success: (state, action) => ({ ...state, data: { ...state.data, data: [...state.data.data, ...action.data.data], hasMore: action.data.hasMoreData, total: action.data.total } }),
				failure: (state, _action) => (state)
			}
		}
	}, 'transcriptReport',
	{
		data: (state) => state.data.data,
		selected: (state) => state.data.selected,
		loading: (state) => state.loading.loadStudents,
		hadMore: (state) => state.data.hasMore,
		total: (state) => state.data.total,
		filters: (state) => state.data.filters,
		loadingMore: (state) => state.loading.loadMoreStudents
	}
);

export default reducer;
export { actions, selectors, types };