import React from "react";
import {
  makeStyles,
  createStyles,
  Card,
  Grid,
  Button,
  CardActions,
  CardContent,
} from "@material-ui/core";
import { ProgramVersionTabProps } from "../../../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersionTabProps";
import ProgressSaveButton from "../../../../../../components/_Layout/Buttons/ProgressSaveButton";
import AddCreditCourse from "./AddCreditCourse";
import ProgramCatalogCoursesSequence from "./ProgramCatalogCoursesSequence";
import ICreditProgramCourse from "../../../../../../interfaces/setup/academics/ProgramDefinition/ICreditProgramCourse";
import { EmptyGuid } from "../../../../../../utils/constants";
import {
  getProgramCatalogDefinition,
  updateProgramRequirements,
} from "../../../../../../api/setup/academics/programCatalogDefinition";
import { useForm, FormContext } from "react-hook-form";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%",
    },
    cardWrapper: {
      padding: theme.spacing(2),
    },
    cardFooter: {
      marginTop: theme.spacing(2),
    },
    saveButton: {
      width: 90,
    },
  })
);
const ProgramCatalogDefinitionTab = (props: ProgramVersionTabProps) => {
  const classes = useStyles({});

  const [courses, setCourses] = React.useState<ICreditProgramCourse[]>([]);
  const [selectedCourse, setSelectedCourse] = React.useState<ICreditProgramCourse>();
  const [loadingDefinition, setLoadingDefinition] =
    React.useState<boolean>(false);
  const [editMode, setEditMode] = React.useState<{ [key: string]: boolean }>(
    {}
  );
  const [dirty, setDirty] = React.useState<boolean>(false);
  const [updating, setUpdating] = React.useState<boolean>(false);
  const methods = useForm({ mode: "onBlur" });
  const onSave = async (): Promise<void> => {
    if (!updating) {
      try {
        setUpdating(true);
        setLoadingDefinition(true);
        const definition = await updateProgramRequirements(
          props.programVersionId,
          courses
        );
        setEditMode({});
        setCourses(definition);
        props.setSnackBar({
          variant: "success",
          showSnackBar: true,
          messageInfo: "Data updated successfully.",
        });
      } catch (error) {
        props.setSnackBar({
          variant: "error",
          showSnackBar: true,
          messageInfo: (error as Error)?.message,
        });
      } finally {
        setDirty(false);
        setSelectedCourse(undefined);
        setTimeout(() => setUpdating(false), 250);
        setTimeout(() => setLoadingDefinition(false), 250);
      }
    }
  };

  const addCoursesToProgram = (coursesToAdd: ICreditProgramCourse[]): void => {
    if (!loadingDefinition) {
      const nonExisting: ICreditProgramCourse[] = [];
      coursesToAdd.map((course) => {
        const existing = !!courses.find(
          (existingCourse) => course.courseId === existingCourse.courseId
        );
        if (!existing) {
          nonExisting.push(course);
        }
        return nonExisting;
      });
      setCourses([...courses, ...nonExisting]);
    }
  };

  const deleteCourse = (index: number): void => {
    if (index >= 0 && !loadingDefinition) {
      try {
        setLoadingDefinition(true);
        if (selectedCourse?.courseId === courses[index].courseId) {
          setSelectedCourse(undefined);
        }
        courses.splice(index, 1);
        setCourses([...courses]);
      } catch (_e) {
        setCourses([...courses]);
      } finally {
        setTimeout(() => setLoadingDefinition(false), 250);
      }
    }
  };

  const loadDefinition = async (): Promise<void> => {
    try {
      setLoadingDefinition(true);
      const definition = await getProgramCatalogDefinition(
        props.programVersionId
      );
      setEditMode({});
      setCourses(definition);
    } catch (error) {
      setCourses([]);
      props.setSnackBar({
        variant: "error",
        showSnackBar: true,
        messageInfo: (error as Error)?.message,
      });
    } finally {
      setSelectedCourse(undefined);
      setDirty(false);
      setTimeout(() => setLoadingDefinition(false), 250);
    }
  };

  React.useEffect(() => {
    setCourses([]);
    if (props.programVersionId && props.programVersionId !== EmptyGuid) {
      loadDefinition();
    }
  }, [props.programVersionId]);

  React.useEffect(() => {
    if (!dirty) {
      setDirty(true);
    }
  }, [courses]);

  return (
    <FormContext {...methods}>
      <form onSubmit={methods.handleSubmit(onSave)}>
        <Card square={true} hidden={props.hidden}>
          <CardContent>
            <Grid container direction="column" spacing={2}>
              <Grid
                item
                alignContent="flex-start"
                alignItems="flex-start"
                justify="flex-start"
              >
                <ProgramCatalogCoursesSequence
                  selectedCourse={selectedCourse}
                  setSelectedCourse={setSelectedCourse}
                  loadingDefinition={loadingDefinition}
                  courses={courses}
                  setCourses={setCourses}
                  deleteCourse={deleteCourse}
                  editMode={editMode}
                  setEditMode={setEditMode}
                />
              </Grid>
              <Grid
                item
                alignContent="flex-start"
                alignItems="flex-start"
                justify="flex-start"
              >
                <AddCreditCourse
                  addCoursesToProgram={addCoursesToProgram}
                  showSnackBar={props.setSnackBar}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid container direction="row" spacing={1}>
              <Grid
                item
                alignContent="flex-start"
                alignItems="flex-start"
                justify="flex-start"
              >
                <ProgressSaveButton
                  text="Save"
                  onClick={(_e: any) => methods.handleSubmit(onSave)}
                  loading={updating}
                  disabled={
                    !dirty ||
                    loadingDefinition ||
                    Object.values(editMode).includes(true)
                  }
                  buttonClassName={classes.saveButton}
                ></ProgressSaveButton>
              </Grid>
              <Grid
                item
                alignContent="flex-start"
                alignItems="flex-start"
                justify="flex-start"
              >
                <Button
                  disabled={
                    !dirty ||
                    updating ||
                    loadingDefinition ||
                    Object.values(editMode).includes(true)
                  }
                  onClick={loadDefinition}
                  color="secondary"
                  variant="contained"
                  type="button"
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </form>
    </FormContext>
  );
};

export default ProgramCatalogDefinitionTab;
