import { colors, Divider, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import * as React from "react";
import { useState } from "react";
import { Tag } from "../../interfaces/common/Tag";
import { Report } from "../../interfaces/reports/Report";
import ReportCard from "../reports/ReportCard";
import ReportCardList from "../reports/ReportCardList";
import { useSelector, useDispatch } from 'react-redux'
import {reportsPageViewModeActions} from '../../actions/reportsPageViewModeActions';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      position: "relative",
      overflow: "auto",
      minHeight: 300,
    },
    cardTab: {
      height: 500,
      overflowY: "scroll",
    },

    listSection: {
      backgroundColor: "inherit",
    },
    ul: {
      backgroundColor: "inherit",
      padding: 0,
    },
    header: {
      color: theme.palette.icon.primary,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: "14px",
      "& $icon": {
        color: theme.palette.primary.main,
      },
      backgroundColor: colors.grey[50],
    },
    headerBackground: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      backgroundColor: colors.grey[50],
      padding: theme.spacing(1),
    },
    actions: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    actionSpacing: {
      marginBottom: theme.spacing(2),
    },
    reportLink: {
      fontSize: 12,
      justifyContent: "flex-start",
      textAlign: "start",
    },

    gridSection: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    dividerColor: {
      backgroundColor: theme.palette.text.secondary,
    },
  })
);

type IResultsProps = {
  className: string;
  tags: Tag[];
  filter?: string;
  reportsList: Report[];
  openReportViewer: (report: Report) => void;
  favoriteToggle: (report: Report, favorite: boolean) => void;
  closeViewer: () => void;
};

function RecentReportItemBuilder(
  report: Report,
  oepnViewer: (report: Report) => void,
  setFavorites?: any
) {
  return (
    <Grid item xs={3}>
      <ReportCard
        type="recent"
        report={report}
        setFavorites={setFavorites}
        oepnViewer={oepnViewer}
      />
    </Grid>
  );
}

function ReportItemBuilder(
  report: Report,
  mode: string,
  oepnViewer: (report: Report) => void,
  setFavorites?: any
) {
  if (mode == "grid") {
    return (
      <Grid item md={4} sm={6} xs={12}>
        <ReportCard
          report={report}
          setFavorites={setFavorites}
          oepnViewer={oepnViewer}
        />
      </Grid>
    );
  } else {
    return (
      <Grid item md={12} sm={12} xs={12}>
        <ReportCardList
          report={report}
          setFavorites={setFavorites}
          oepnViewer={oepnViewer}
        />
      </Grid>
    );
  }
}

function eachRecursive(root: any, filter: string, properties?: any): boolean {
  var type = typeof root;
  const isObj = (!!root && type === "function") || type === "object";
  if (isObj) {
    const props = properties ? properties : Object.keys(root);
    return props.some(function (k: any) {
      let val = root[k];
      return eachRecursive(val, filter);
    });
  } else if (Array.isArray(root)) {
    return root.some((it) => {
      return eachRecursive(it, filter);
    });
  } else {
    return (
      !!root && root.toString().toLowerCase().includes(filter.toLowerCase())
    );
  }
}

const Results = (props: IResultsProps) => {
  const {
    className,
    tags,
    filter,
    reportsList,
    openReportViewer,
    favoriteToggle,
    ...rest
  } = props;

  const initialViewMode = useSelector((state: any) => state.reports_page_view_mode.mode);
  const [mode, setMode] = useState(initialViewMode);
  const classes = useStyles({});
  const dispatch = useDispatch();
  const handleModeChange = (
    _event: any,
    value: React.SetStateAction<string>
  ) => {
    setMode(value);
    let changeModeAction = reportsPageViewModeActions.changeMode(value.toString());
    changeModeAction(dispatch);
  };

  const recentlyUsedReports: Report[] = reportsList.filter(
    (r: Report) => r.recentlyUsed
  );

  const tagFilter = (reports: Report[]) => {
    if (tags) {
      if (tags.length < 1) {
        return reports.filter((rep) => {
          if (!rep.tags || rep.tags.length === 0)
            console.log("no report tags" + rep.tags);
          return rep.tags.some((t) => {
            if (t) return t;
            return false;
          });
        });
      }
      return reports.filter((rep) => {
        if (!rep.tags || rep.tags.length === 0)
          console.log("no report tags" + rep.tags);
        return rep.tags.some((t) =>
          tags.find((selTags: Tag) => {
            if (t) return selTags.text === t.text;
            return false;
          })
        );
      });
    }

    return reports;
  };
  const filterReports = (reports: Report[], properties?: Array<string>) => {
    let result: Report[] = reports;

    if (filter && filter.length > 2) {
      result = reports.filter(function (rep: any) {
        return eachRecursive(rep, filter, properties);
      });
    }
    return result;
  };

  return (
    <div className={className} {...rest}>
      <div className={classes.actionSpacing}>
        <div className={classes.actions}>
          <ToggleButtonGroup
            exclusive
            onChange={handleModeChange}
            size="small"
            value={mode}
          >
            <ToggleButton value="grid" disabled={mode === "grid"}>
              <ViewModuleIcon />
            </ToggleButton>
            <ToggleButton value="list" disabled={mode !== "grid"}>
              <ViewListIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>

      <div>
        <Typography color="textPrimary" variant="h5">
          Recently Used Reports
        </Typography>
        <Divider className={classes.dividerColor} />

        <Grid className={classes.gridSection} container spacing={3}>
          {recentlyUsedReports.map((report: Report) =>
            RecentReportItemBuilder(report, openReportViewer, favoriteToggle)
          )}
        </Grid>
      </div>

      <div>
        <Typography color="textPrimary" variant="h5">
          Reports
        </Typography>
        <Divider className={classes.dividerColor} />

        <Grid className={classes.gridSection} container spacing={1}>
          {filterReports(tagFilter(reportsList), [
            "name",
            "tags",
          ]).map((report: Report) =>
            ReportItemBuilder(report, mode, openReportViewer, favoriteToggle)
          )}
        </Grid>
      </div>
    </div>
  );
};

export default Results;
