
import API from "../../apiWrapper";
import {    IScheduleGridRow  } from "../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/Schedule/IScheduleGridRow";

export const upsertProgramVersionSchedule = (model: IScheduleGridRow) => {
    return API()
      .post("/SystemCatalog/ProgramSchedule/UpsertProgramSchedule", model, {
              responseType: "json",
          })
      .then(
        (res: any) => {
          if (res && res.data) {
            return res.data;
          }
        },
        (error: any) => {
          return error.response;
        }
      );
  };