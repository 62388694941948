import React, { useEffect, useMemo } from "react";
import {
    createStyles,
    lighten,
    makeStyles,
    Theme
} from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import { Grid, Card, Button, Tooltip, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions } from "@material-ui/core";
import UserPermissions from "../userMain/UserPermissions";
import UserAutoComplete from '../../../../../components/AutoComplete/UserNameAutoComplete';
import PermissionTemplateAutoComplete from '../../../../../components/AutoComplete/PermissionTemplateAutoComplete';
import MultiCampusAutoComplete from '../../../../../components/AutoComplete/MultiCampusAutoComplete';
import * as permissionsApi from '../../../../../api/security/permissionsApi'
import { Permission } from "../../../../../interfaces/security/Permission";
import { PermissionTemplateDetail } from "../../../../../interfaces/security/PermissionTemplateDetail";
import { PermissionTemplate } from "../../../../../interfaces/security/PermissionTemplate";
import { UpdateUserPermissions } from "../../../../../interfaces/security/UpdateUserPermissions";
import CustomSnackbar from "../../../../../components/notifications/CustomSnackbar";
import TemplateModal from "./TemplateModal"
import InfoIcon from '@material-ui/icons/Info';
import { SaveNewPermissionTemplate } from "../../../../../interfaces/security/SaveNewPermissionTemplate";
import ProgressSaveButton from "../../../../../components/_Layout/Buttons/ProgressSaveButton";
import { groupBy } from "../../../../../utils/arrayFunctions";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperRoot: {
            padding: theme.spacing(2),
            width: "100%",
            minHeight: "100%",
            height: "95%",
            overflowX: "hidden",
            overflowY: "auto",
            flexDirection: "column",
            backgroundColor: theme.palette.background.paper
        },
        checkbox: {
            color: "#37474F !important"
        },
        Card: {
            padding: theme.spacing(3)
        },
        buttonPadding: {
            paddingTop: theme.spacing(3)
        },
        secondaryButton: {
            backgroundColor: theme.palette.secondary.main
        }
    })
);

export const PermissionPage = () => {
    const classes = useStyles();

    const [templateDetails, setTemplateDetails] = React.useState<Array<PermissionTemplateDetail>>([]);
    const [template, setTemplate] = React.useState({ permissionTemplateId: 0, name: "", isCustom: false } as PermissionTemplate)
    const [loaderState, setLoaderState] = React.useState<boolean>(false);
    const [showSnackBar, setShowSnackBar] = React.useState<boolean>(false);
    const [messageInfo, setMessageInfo] = React.useState<string | undefined>(undefined);
    const [snackBarVariant, setSnackBarVariant] = React.useState<'success' | 'error' | 'warning' | 'info'>('info');
    const modalRef = React.useRef<any>();
    const [open, setOpen] = React.useState(false);

    const handleConfirmApply = () => {
        //mergeTemplates();
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleTemplateDetailsChange = (templateDetails: Array<PermissionTemplateDetail>) => {
        setTemplateDetails(templateDetails);
    }


    const saveNewTemplate = () => {
        if (templateDetails.length == 0) {
            setSnackBarVariant('info')
            setMessageInfo('Please add at least one permission to the template.')
            setShowSnackBar(true)
        }
        else
            modalRef.current.handleClickOpen();
    }
    const finalizeNewTemplate = () => {
        setLoaderState(true);
        const templateData = { template: template, permissionTemplateDetails: templateDetails } as SaveNewPermissionTemplate
        permissionsApi.savePermissionTemplate(templateData).then((response: any) => {
            setLoaderState(false);

            if (response.resultStatus === 0) {

                setSnackBarVariant('success')
                setMessageInfo('User template has been saved.')
                setShowSnackBar(true)

            }


        })
    }
    return (
        <Paper className={classes.paperRoot} square={true}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Apply Template?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apply template over existing user permissions?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmApply} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <CustomSnackbar
                variant={snackBarVariant}
                message={messageInfo}
                open={showSnackBar}
                onClose={(event?: React.SyntheticEvent, reason?: string) => {
                    // if (reason === "clickaway") {
                    // 	return;
                    // }
                    setShowSnackBar(false);
                }}
            ></CustomSnackbar>
            <Card className={classes.Card}>

                <Grid
                    container
                    direction="row"
                    style={{ marginTop: 10 }}
                >
                    <Grid item xs={12}>
                        <UserPermissions setTemplateDetails={handleTemplateDetailsChange} />
                        <TemplateModal ref={modalRef} template={template} setTemplate={setTemplate} onSubmit={finalizeNewTemplate} />
                    </Grid>
                </Grid>
                <div className={classes.buttonPadding}>
                    <Grid container >
                      
                        <Grid item xs={3}>
                            <ProgressSaveButton
                                text="Save As a new template"
                                onClick={saveNewTemplate}
                                loading={loaderState}
                            ></ProgressSaveButton>

                        </Grid>
                    </Grid>
                </div>
            </Card>
        </Paper>
    );
}

export default PermissionPage;