import * as React from "react";
import { useRef } from "react";
import placementEmployerJobs from "../../constants/Placement/employerJobsAutoComplete";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { useSelector } from "react-redux";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { EmptyGuid } from "../../utils/constants";

const filter = createFilterOptions<any>();

const PlacementEmployerJobsAutoComplete = (props: FilterParams) => {
  const inputRef: any = useRef(null);

  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const OnChange = (e: any) => {
    if (props.filterHandle) {
      props.filterHandle(e);
    }
  };

  return (
    <div>
      <ApiAutoComplete
        config={placementEmployerJobs}
        classes={{
          option: "text-black",
        }}
        label={props.label ? props.label : "Job"}
        onChange={(e: any) => {
          OnChange(e);
        }}
        reqParams={props.requestParameter ? { campusId: userSelectedCampus, ...props.requestParameter } : null}
        multiple={false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        inputRef={props.inputRef ? props.inputRef : inputRef}
        error={props.error}
        helperText={props.helperText}
        inputName={props.name}
        filterOptions={(options: any, params: any) => {
          const filtered = (filter(options, params) as any[]).filter(
            (option: any) => option.value !== EmptyGuid
          );
          if (!!params.inputValue) {
            let newOption = options.filter(
              (val: any) => val.value === EmptyGuid
            );
            if (newOption.length === 0) {
              (newOption as any[]).push({ text: `Add "${params.inputValue}"`, value: EmptyGuid });
              (options as any[]).push(newOption[0]);
            }
            else {
              newOption[0].text = `Add "${params.inputValue}"`;
            }
            filtered.push(newOption[0]);
          }
          return filtered;
        }}
        freeSolo
      ></ApiAutoComplete>
    </div>
  );
};
export default PlacementEmployerJobsAutoComplete;
