import * as React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
	Typography,
	Card,
	CardContent,
	TableContainer,
	TableRow,
	TableCell,
	Table,
	TableBody,
	TableHead,
	IconButton,
	CircularProgress,
} from "@material-ui/core";
import {
	KeyboardArrowLeft,
	KeyboardArrowRight,
	WarningOutlined,
} from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import clsx from "clsx";
import DateFnsUtils from "@date-io/date-fns";
import {
	startOfWeek,
	endOfWeek,
	isWithinInterval,
	isSameDay,
	format,
	getWeek,
	addDays,
	subDays,
	isEqual,
} from "date-fns";
import { Scrollbars, positionValues } from "react-custom-scrollbars";
import IWeeklyAttendance, {
	ISlotDetails,
} from "../../interfaces/attendance/IWeeklyAttendance";
import {
	saveDetails,
	createPunchRecord,
	postAbsentForDay,
	updatePunchRecord,
	validatePunches,
	getStudentAttendanceDetailsByDate,
	getWeeklyCreditHourAttendanceData,
	markOrUnmarkPresent,
	markOrUnmarkAbsent,
	markOrUnmarkTardy,
	markAllCreditHourPresent,
	markOrUnmarkExcusedAbsent,
	markOrUnmarkUnExcusedAbsent,
} from "../../api/attendance/postWeeklyAttendance";
import { useSelector } from "react-redux";
import { CustomSnackBarProps } from "../../interfaces/common/CustomSnackBarProps";
import IPostAttendacePunchRecords from "../../interfaces/attendance/IPostAttendacePunchRecords";
import { ICreditHourScheduledAttendance } from "../../interfaces/attendance/ICreditHourScheduledAttendance";
import IPostCreditHourAttendanceFilters from "../../interfaces/attendance/IPostCreditHourAttendanceFilters";
import { cloneDeep, sortBy } from "lodash";
import SortIcon from "@material-ui/icons/Sort";

import { Button, ButtonGroup } from '@material-ui/core';

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.background.paper,
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2),
			},
		},
		"& .makeStyles-content": {
			backgroundColor: theme.palette.background.paper,
		},
		cardRoot: {
			display: "flex",
			flexDirection: "column",
			height: "100%",
		},
		button: {
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(3),
			whiteSpace: "nowrap",
		},
		cardSeparator: {
			marginBottom: theme.spacing(1),
		},
		drawer: {
			width: 280,
		},
		cardTitle: {
			fontSize: 17,
			color: theme.palette.black,
			fontWeight: "bold",
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(3, 1),
			marginBottom: theme.spacing(1),
		},
		calendarTitle: {
			fontSize: 17,
			color: theme.palette.black,
			fontWeight: "bold",
			textAlign: "center",
			width: "fit-content",
		},
		calendarDateTitle: {
			padding: theme.spacing(1, 0, 0.5, 0),
			cursor: "pointer",
		},
		calendarWeekTitle: {
			padding: theme.spacing(0.5, 0, 1, 0),
		},
		textField: {
			marginRight: theme.spacing(1),
			width: "100%",
		},
		headerText: {
			paddingLeft: theme.spacing(1),
		},
		calenderHeaderText: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		cardContent: {
			padding: theme.spacing(0, 3),
			flexGrow: 1,
			display: "flex",
			flexDirection: "column",
		},
		switch: {
			marginLeft: "0px",
			paddingTop: theme.spacing(2),
		},
		tableContainer: {
			flexGrow: 1,
			maxHeight: '57vh'
		},
		tableRow: {
			"&:hover": {
				backgroundColor: "transparent",
			},
		},
		tableDetailsRow: {
			backgroundColor: "#fafafa",
			"&:hover": {
				backgroundColor: "#fafafa",
			},
		},
		tableHeadDate: {

		},
		allPresent: {
			backgroundColor: "#fafafa",
			"&:hover": {
				backgroundColor: "white",
			},
			cursor: 'pointer'
		},
		warningCell: {
			backgroundColor: "#ea9999",
		},
		tableHead: {
			fontWeight: "bold",
			fontSize: 14,
			color: theme.palette.common.black,
			backgroundColor: theme.palette.site.secondary,
			border: "solid #e1e1e1",
			borderLeftWidth: 1,
			borderRightWidth: 1,
			borderTopWidth: 0,
			borderBottomWidth: 0,
			padding: theme.spacing(0, 2),
			"&:last-child": { borderRightWidth: 0 },
			"&:first-child": { borderLeftWidth: 0 },
		},
		tableHeader: {
			boxShadow:
				"0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
		},
		dateHeader: {
			margin: theme.spacing(1, 0),
		},
		alignLeft: {
			textAlign: "left",
		},
		alignRight: {
			textAlign: "right",
		},
		alignCenter: {
			textAlign: "center",
		},
		dayWrapper: {
			position: "relative",
		},
		day: {
			width: 36,
			height: 36,
			fontSize: theme.typography.caption.fontSize,
			margin: "0 2px",
			color: "inherit",
		},
		customDayHighlight: {
			position: "absolute",
			top: 0,
			bottom: 0,
			left: "2px",
			right: "2px",
			border: `1px solid ${theme.palette.secondary.main}`,
			borderRadius: "50%",
		},
		nonCurrentMonthDay: {
			color: theme.palette.text.disabled,
		},
		highlightNonCurrentMonthDay: {
			color: "#676767",
		},
		highlight: {
			background: theme.palette.primary.main,
			color: theme.palette.common.white,
		},
		firstHighlight: {
			extend: "highlight",
			borderTopLeftRadius: "50%",
			borderBottomLeftRadius: "50%",
		},
		endHighlight: {
			extend: "highlight",
			borderTopRightRadius: "50%",
			borderBottomRightRadius: "50%",
		},
		slotCell: {
			cursor: "pointer",
			"&:hover": {
				backgroundColor: "#F4F0F0",
			},
			"&:focus": {
				backgroundColor: "#F4F0F0",
			},
		},

		attendanceButtonSelected: {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white,
		},
		slotCellDisabled: {
			backgroundColor: "#f2f2f2",
			cursor: "not-allowed",
		},
		superText: {
			verticalAlign: "super",
			fontSize: theme.spacing(1.25),
		},
		superIcon: {
			fontSize: theme.spacing(3.125),
		},
		scroll: {
			'& div': {
				zIndex: 5
			}
		},
		sortDesc: {
			transform: "rotate(180deg)",
		  },
		  disableColor:{
			color:'gray',
			transform: "rotate(180deg)",
		  },
		  disabled:{
			color:'gray',
			cursor:'default',
			backgroundColor: "#fafafa",
		  }
	})
);

interface IPostWeeklyAttendanceTableProps extends RouteComponentProps {
	filters: IPostCreditHourAttendanceFilters;
	resetFilter: () => void;
	selectedSlots: { [key: number]: ISlotDetails | undefined };
	confirmationDialog: {
		open?: boolean;
		message?: string;
		onCancel?: () => void;
		onOk?: () => void;
	};
	slotClick: (index: number, params?: ISlotDetails) => void;
	applyFilters: (filters: IPostCreditHourAttendanceFilters) => void;
	showSnackBar: (params: CustomSnackBarProps) => void;
	closeAllModals: () => void;
	setBulkDetailsOpen: (values: {
		[key: number]: ISlotDetails | undefined;
	}) => void;
	setconfirmationDialogProps: (dialogProps: {
		open?: boolean;
		message?: string;
		onCancel?: () => void;
		onOk?: () => void;
	}) => void;
}

const MAX_PAGES = 5;
const PAGE_SIZE = 100;

const PostWeeklyCreditHourAttendanceTable = (props: IPostWeeklyAttendanceTableProps) => {
	const classes = useStyles({});
	const scrollbar = React.useRef<Scrollbars>(null);
	const [datePickerOpen, setDatePickerOpen] = React.useState<boolean>(false);
	const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
	const [gridData, setGridData] = React.useState<ICreditHourScheduledAttendance[]>([]);
	const [loadingNext, setLoadingNext] = React.useState<boolean>(false);
	const [loadingPrev, setLoadingPrev] = React.useState<boolean>(false);
	const [removePrevItems, setRemovePrevItems] = React.useState<number>(0);
	const [firstPage, setFirstPage] = React.useState<number>(0);
	const [lastPage, setLastPage] = React.useState<number>(0);
	const [endOfData, setEndOfData] = React.useState<boolean>(false);
	const [resize, setResize] = React.useState<number>(0);
	const [expandAllDate, setExpandAllDate] = React.useState<Date>();
	const [sortOrder, setSortOrder] = React.useState<'ASC' | 'DESC'>('ASC');
	const [sortOn, setSortOn] = React.useState<'BadgeId' | 'Name'>('BadgeId');

	const closeModal = (index?: number) => {
		if (index || index === 0) {
			props.slotClick(index, undefined);
		} else {
			props.closeAllModals();
		}
		setExpandAllDate(undefined);
	};

	const closeConfirmationDialog = () => {
		props.setconfirmationDialogProps({ open: false });
	};

	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);
	React.useEffect(() => {
		if (userSelectedCampus) {
			props.resetFilter();
		}
	}, [userSelectedCampus]);

	const renderWrappedWeekDay = (
		date: any,
		selectedDate: any,
		dayInCurrentMonth: any
	) => {
		const dateClone = new Date(date);
		let selectedDateClone = new Date(selectedDate);

		const start = startOfWeek(selectedDateClone);
		const end = endOfWeek(selectedDateClone);

		const dayIsBetween = isWithinInterval(dateClone, { start, end });
		const isFirstDay = isSameDay(dateClone, start);
		const isLastDay = isSameDay(dateClone, end);

		const wrapperClassName = clsx({
			[classes.highlight]: dayIsBetween,
			[classes.firstHighlight]: isFirstDay,
			[classes.endHighlight]: isLastDay,
		});

		const dayClassName = clsx(classes.day, {
			[classes.nonCurrentMonthDay]: !dayInCurrentMonth,
			[classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
		});

		return (
			<div className={wrapperClassName}>
				<IconButton className={dayClassName}>
					<span> {format(dateClone, "d")} </span>
				</IconButton>
			</div>
		);
	};

	const renderWeekGridHeader = (): React.ReactNodeArray => {
		const rows: React.ReactNodeArray = [];
		const days = [
			"Sunday",
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
		];
		for (
			let start = startOfWeek(selectedDate);
			start < endOfWeek(selectedDate);
			start = addDays(start, 1)
		) {
			rows.push(
				<TableCell
					variant="head"
					component="th"
					key={`head-${start.getDay()}`}
					className={clsx(
						classes.tableHead,
						classes.tableHeadDate,
						classes.alignCenter
					)}
					onClick={() =>
						expandAllDate && isEqual(start, expandAllDate)
							? setExpandAllDate(undefined)
							: setExpandAllDate(start)
					}
				>
					<div className={classes.dateHeader}>{days[start.getDay()]}</div>
					<div className={classes.dateHeader}>{start.getDate()}</div>
					{!!gridData && gridData.length > 0 && gridData.findIndex(m => !!m.attendanceData && m.attendanceData.findIndex(aa => new Date(aa.attendedDate).getDay() === start.getDay() && aa.hasSchedule === true) >= 0) >= 0 && <div className={gridData.findIndex(m => !!m.attendanceData && m.attendanceData.findIndex(aa => new Date(aa.attendedDate).getDay() === start.getDay() && aa.hasSchedule === true && aa.isValidSchedule === true) >= 0) >= 0 ? classes.allPresent : classes.disabled} onClick={() => {
						if(!!gridData && gridData.length > 0 && gridData.findIndex(m => !!m.attendanceData && m.attendanceData.findIndex(aa => new Date(aa.attendedDate).getDay() === start.getDay() && aa.hasSchedule === true && aa.isValidSchedule === true) >= 0) >= 0){
							markAllPresent(start);
						}
					}}>{'All Present'}</div>}
				</TableCell>
			);
		}
		return rows;
	};

	const renderWeekGridValues = (
		slotItem: ICreditHourScheduledAttendance,
		index: number
	): React.ReactNodeArray => {
		const rows: React.ReactNodeArray = [];
		let i = 0;
		for (
			let start = startOfWeek(selectedDate);
			start < endOfWeek(selectedDate);
			start = addDays(start, 1)
		) {
			const useExcuseAbsence = slotItem?.useExcusedAbsence ?? false;
			const record = slotItem?.attendanceData?.find((m: any) => m.dayOfWeek % 7 === i);
			const recordIndex = slotItem?.attendanceData?.findIndex((m: any) => m.dayOfWeek % 7 === i);
			i++;
			const openDate =
				props.selectedSlots &&
				props.selectedSlots[index] &&
				props.selectedSlots[index]?.date;
			const open = openDate && isEqual(openDate, start);
			rows.push(
				<TableCell
					variant="body"
					className={clsx(
						classes.alignCenter,
					)}
					padding="none"
					key={`slot-${slotItem.studentBadgeId}-${start.getDay()}`}
					onClick={() => { }}
				>
					{record &&
						// (record.showInvalidPunchesWarning ? (<WarningOutlined />):
						(record.statusCode === "LOA" ? (
							"LOA"
						) : record.statusCode === "SUSP" ? (
							"SUSP"
						) : record.hasSchedule == true ? (
							record.isMarking === true ? <CircularProgress /> : <ButtonGroup style={{ margin: 5 }} disabled={!record.isValidSchedule} size="small" aria-label="outlined primary button group">
								<Button size="small" onClick={() => { markPresent(index, recordIndex ?? -1); }} className={record.isPresent === true ? classes.attendanceButtonSelected : ''}>P</Button>
								<Button size="small" onClick={() => { markTardy(index, recordIndex ?? -1); }} className={record.isTardy === true ? classes.attendanceButtonSelected : ''}>T</Button>
								{useExcuseAbsence === false && <Button size="small" onClick={() => { markAbsent(index, recordIndex ?? -1); }} className={record.isPresent === false ? classes.attendanceButtonSelected : ''}>A</Button>}
								{useExcuseAbsence === true && <Button size="small" onClick={() => { markExcusedAbsent(index, recordIndex ?? -1); }} className={record.isPresent === false && record.isExcused === true ? classes.attendanceButtonSelected : ''}>EA</Button>}
								{useExcuseAbsence === true && <Button size="small" onClick={() => { markUnExcusedAbsent(index, recordIndex ?? -1); }} className={record.isPresent === false && (!record.isExcused) ? classes.attendanceButtonSelected : ''}>UA</Button>}
							</ButtonGroup>

						) : (<span></span>))}
				</TableCell>
			);
		}
		return rows;
	};

	const refreshGridData = async () => {
		try {
			if (!!props.filters && !!props.filters.classId) {
				let newData: ICreditHourScheduledAttendance[] = [];
				for (let i = firstPage; i <= lastPage; i++) {
					var loaded = await getWeeklyCreditHourAttendanceData(
						props.filters,
						userSelectedCampus,
						startOfWeek(selectedDate),
						i,
						PAGE_SIZE
					);
					newData = newData.concat(loaded.data);
				}

				setSortOrder('ASC');
				setSortOn('BadgeId');
				setGridData(newData);
			}
		} catch (error) {
			props.showSnackBar({
				variant: "error",
				showSnackBar: true,
				messageInfo: error.message,
			});
		}
	};

	let urlParams = new URLSearchParams(props.history.location.search);
	let refresh = urlParams.get("refresh");

	React.useEffect(() => {
		if (refresh) {
			refreshGridData();
			urlParams.delete("refresh");
			props.history.replace({
				...props.location,
				search: urlParams.toString(),
			});
		}
	}, [refresh]);

	const loadGridData = async (startDate: Date) => {
		let eod = false;
		try {
			if (!!props.filters && !!props.filters.classId) {
				setLoadingNext(true);
				var loaded = await getWeeklyCreditHourAttendanceData(
					props.filters,
					userSelectedCampus,
					startDate,
					1,
					PAGE_SIZE
				);
				setGridData(loaded.data);
				setSortOrder('ASC');
				setSortOn('BadgeId');
				setRemovePrevItems(0);
				setFirstPage(1);
				setLastPage(1);
				setEndOfData(loaded.endofData ?? false);
				eod = loaded.endofData ?? false;
				setLoadingNext(false)
			}
		} catch (error) {
			props.showSnackBar({
				variant: "error",
				showSnackBar: true,
				messageInfo: error.message,
			});
		}

		setLoadingNext(false);
	};

	const loadNextPage = async () => {
		if (!endOfData && !!props.filters && !!props.filters.classId) {
			try {
				setLoadingNext(true);
				var loaded = await getWeeklyCreditHourAttendanceData(
					props.filters,
					userSelectedCampus,
					startOfWeek(selectedDate),
					lastPage + 1,
					PAGE_SIZE
				);
				let newArray = gridData.slice(
					loaded.page > MAX_PAGES ? PAGE_SIZE : 0,
					gridData.length
				);
				setSortOrder('ASC');
				setSortOn('BadgeId');
				setGridData(newArray.concat(loaded.data));
				setRemovePrevItems(
					loaded.page > MAX_PAGES ? loaded.itemsOnCurrentPages : 0
				);
				setFirstPage(loaded.page > MAX_PAGES ? firstPage + 1 : 1);
				setLastPage(loaded.page);
				setEndOfData(loaded.endofData ?? false);
				setLoadingNext(false)
			} catch (error) {
				props.showSnackBar({
					variant: "error",
					showSnackBar: true,
					messageInfo: error.message,
				});
			}
			setLoadingNext(false);
			if (!endOfData && scrollbar.current) {
				scrollbar.current.scrollTop(scrollbar.current.getScrollTop() - 10);
			}
		}
	};

	const checkForNext = () => {
		if (!loadingNext) {
			const vls = scrollbar.current && scrollbar.current.getValues();
			if (
				lastPage > 0 &&
				lastPage - firstPage < MAX_PAGES &&
				!endOfData &&
				(!vls ||
					vls?.scrollHeight - vls?.scrollTop === vls?.clientHeight ||
					vls?.scrollHeight === vls?.clientHeight)
			) {
				loadNextPage();
			}
		}
	};

	React.useEffect(() => {
		checkForNext();
	}, [`${lastPage}-${loadingNext}-${resize}`]);

	const loadPrevPage = async () => {
		if (firstPage > 1 && !!props.filters && !!props.filters.classId) {
			try {
				setLoadingPrev(true);
				var loaded = await getWeeklyCreditHourAttendanceData(
					props.filters,
					userSelectedCampus,
					startOfWeek(selectedDate),
					firstPage - 1,
					PAGE_SIZE
				);
				let newArray = gridData.slice(0, gridData.length - removePrevItems);
				setSortOrder('ASC');
				setSortOn('BadgeId');
				setGridData(loaded.data.concat(newArray));
				setRemovePrevItems(PAGE_SIZE);
				setFirstPage(loaded.page);
				setLastPage(lastPage - 1);
				setEndOfData(loaded.endofData ?? false);
			} catch (error) {
				props.showSnackBar({
					variant: "error",
					showSnackBar: true,
					messageInfo: error.message,
				});
			}
			setLoadingPrev(false);
			if (firstPage !== 1 && scrollbar.current) {
				scrollbar.current.scrollTop(scrollbar.current.getScrollTop() + 2);
			}
		}
	};

	const showLoading = (mainIndex: number, subIndex: number) => {
		let gridClone = cloneDeep(sortedGridData);
		let enrollmentRecord = gridClone[mainIndex];

		if (!!enrollmentRecord && !!enrollmentRecord.attendanceData && enrollmentRecord.attendanceData.length > 0) {
			var attendanceRecord = enrollmentRecord.attendanceData[subIndex];

			if (!!attendanceRecord) {
				attendanceRecord.isMarking = true;

				setGridData(gridClone);
			}
		}
	}

	const hideLoading = (mainIndex: number, subIndex: number) => {
		let gridClone = cloneDeep(sortedGridData);
		let enrollmentRecord = gridClone[mainIndex];

		if (!!enrollmentRecord && !!enrollmentRecord.attendanceData && enrollmentRecord.attendanceData.length > 0) {
			var attendanceRecord = enrollmentRecord.attendanceData[subIndex];

			if (!!attendanceRecord) {
				attendanceRecord.isMarking = false;

				setGridData(gridClone);
			}
		}
	}

	const markPresent = (mainIndex: number, subIndex: number) => {
		if (!!props.filters.classId && !!gridData && gridData.length > 0) {
			var gridClone = cloneDeep(sortedGridData);
			var enrollmentRecord = gridClone[mainIndex];
			if (!!enrollmentRecord && !!enrollmentRecord.attendanceData && enrollmentRecord.attendanceData.length > 0) {
				var attendanceRecord = enrollmentRecord.attendanceData[subIndex];

				if (!!attendanceRecord && !!attendanceRecord.studentScheduleId) {
					showLoading(mainIndex, subIndex);
					markOrUnmarkPresent(attendanceRecord.studentEnrollmentId, attendanceRecord.studentScheduleId, props.filters.classId, attendanceRecord.attendedDate).then(result => {
						if (!!result && !!enrollmentRecord && !!enrollmentRecord.attendanceData) {
							enrollmentRecord.attendanceData[subIndex] = cloneDeep(result);
							setGridData(gridClone);
						}
					})
						.catch(ex => {
							hideLoading(mainIndex, subIndex);
						})
				}
			}
		}
	}

	const markAbsent = (mainIndex: number, subIndex: number) => {
		if (!!props.filters.classId && !!gridData && gridData.length > 0) {
			var gridClone = cloneDeep(sortedGridData);
			var enrollmentRecord = gridClone[mainIndex];
			if (!!enrollmentRecord && !!enrollmentRecord.attendanceData && enrollmentRecord.attendanceData.length > 0) {
				var attendanceRecord = enrollmentRecord.attendanceData[subIndex];

				if (!!attendanceRecord && !!attendanceRecord.studentScheduleId) {
					showLoading(mainIndex, subIndex);
					markOrUnmarkAbsent(attendanceRecord.studentEnrollmentId, attendanceRecord.studentScheduleId, props.filters.classId, attendanceRecord.attendedDate).then(result => {
						if (!!result && !!enrollmentRecord && !!enrollmentRecord.attendanceData) {
							enrollmentRecord.attendanceData[subIndex] = cloneDeep(result);
							setGridData(gridClone);
						}
					})
						.catch(ex => {
							hideLoading(mainIndex, subIndex);
						})
				}
			}
		}
	}

	const markExcusedAbsent = (mainIndex: number, subIndex: number) => {
		if (!!props.filters.classId && !!gridData && gridData.length > 0) {
			var gridClone = cloneDeep(sortedGridData);
			var enrollmentRecord = gridClone[mainIndex];
			if (!!enrollmentRecord && !!enrollmentRecord.attendanceData && enrollmentRecord.attendanceData.length > 0) {
				var attendanceRecord = enrollmentRecord.attendanceData[subIndex];

				if (!!attendanceRecord && !!attendanceRecord.studentScheduleId) {
					showLoading(mainIndex, subIndex);
					markOrUnmarkExcusedAbsent(attendanceRecord.studentEnrollmentId, attendanceRecord.studentScheduleId, props.filters.classId, attendanceRecord.attendedDate).then(result => {
						if (!!result && !!enrollmentRecord && !!enrollmentRecord.attendanceData) {
							enrollmentRecord.attendanceData[subIndex] = cloneDeep(result);
							setGridData(gridClone);
						}
					})
						.catch(ex => {
							hideLoading(mainIndex, subIndex);
						})
				}
			}
		}
	}

	const markUnExcusedAbsent = (mainIndex: number, subIndex: number) => {
		if (!!props.filters.classId && !!gridData && gridData.length > 0) {
			var gridClone = cloneDeep(sortedGridData);
			var enrollmentRecord = gridClone[mainIndex];
			if (!!enrollmentRecord && !!enrollmentRecord.attendanceData && enrollmentRecord.attendanceData.length > 0) {
				var attendanceRecord = enrollmentRecord.attendanceData[subIndex];

				if (!!attendanceRecord && !!attendanceRecord.studentScheduleId) {
					showLoading(mainIndex, subIndex);
					markOrUnmarkUnExcusedAbsent(attendanceRecord.studentEnrollmentId, attendanceRecord.studentScheduleId, props.filters.classId, attendanceRecord.attendedDate).then(result => {
						if (!!result && !!enrollmentRecord && !!enrollmentRecord.attendanceData) {
							enrollmentRecord.attendanceData[subIndex] = cloneDeep(result);
							setGridData(gridClone);
						}
					})
						.catch(ex => {
							hideLoading(mainIndex, subIndex);
						})
				}
			}
		}
	}

	const markTardy = (mainIndex: number, subIndex: number) => {
		if (!!props.filters.classId && !!gridData && gridData.length > 0) {
			var gridClone = cloneDeep(sortedGridData);
			var enrollmentRecord = gridClone[mainIndex];
			if (!!enrollmentRecord && !!enrollmentRecord.attendanceData && enrollmentRecord.attendanceData.length > 0) {
				var attendanceRecord = enrollmentRecord.attendanceData[subIndex];

				if (!!attendanceRecord && !!attendanceRecord.studentScheduleId) {
					showLoading(mainIndex, subIndex);
					markOrUnmarkTardy(attendanceRecord.studentEnrollmentId, attendanceRecord.studentScheduleId, props.filters.classId, attendanceRecord.attendedDate).then(result => {
						if (!!result && !!enrollmentRecord && !!enrollmentRecord.attendanceData) {
							enrollmentRecord.attendanceData[subIndex] = cloneDeep(result);
							setGridData(gridClone);
						}
					})
						.catch(ex => {
							hideLoading(mainIndex, subIndex);
						})
				}
			}
		}
	}

	const markAllPresent = (dated: Date) => {
		if (!!props.filters.classId && !!sortedGridData && sortedGridData.length > 0) {
			props.setconfirmationDialogProps({
				message: 'Present will be posted to all students in the class. Continue?', open: true, onCancel: closeConfirmationDialog, onOk: () => {
					closeConfirmationDialog();
					markAllCreditHourPresent(props.filters, dated, userSelectedCampus).then(result => {
						if (result === true) {
							refreshGridData();
						}
					})
						.catch(ex => {

						})
				}
			});
		}
	}

	const saveScheduleDetails = async (index: number, record: any) => {
		try {
			const result = await saveDetails(record);
			props.slotClick(index, {
				...props.selectedSlots[index],
				tardy: record.tardy,
				isUpdated: record?.isUpdated,
			});
			props.showSnackBar(result);
		} catch (error) {
			props.showSnackBar({
				variant: "error",
				showSnackBar: true,
				messageInfo: error.message,
			});
		}
		await refreshGridData();
	};

	const createAttendacePunchRecord = async (
		campusId: string,
		studEnrollId: string,
		badgeNumber: string,
		punchIn?: Date,
		punchOut?: Date,
		isDistEdPunch?: boolean
	) => {
		try {
			const result = await createPunchRecord(
				campusId,
				badgeNumber,
				studEnrollId,
				punchIn,
				punchOut,
				isDistEdPunch
			);
			props.showSnackBar(result);
		} catch (error) {
			props.showSnackBar({
				variant: "error",
				showSnackBar: true,
				messageInfo: error.message,
			});
		}
		await refreshGridData();
	};

	const postAbsent = async (
		campusId: string,
		badgeNumber: string,
		date: Date
	): Promise<void> => {
		try {
			const result = await postAbsentForDay(campusId, badgeNumber, date);
			props.showSnackBar(result);
		} catch (error) {
			props.showSnackBar({
				variant: "error",
				showSnackBar: true,
				messageInfo: error.message,
			});
		}
		await refreshGridData();
	};
	const validatePunchesRow = async (
		punchId: number[],
		campusId: string,
		studEnrollId: string,
		badgeNumber: string,
		punchIn: Date,
		punchOut?: Date,
		isDistEdPunch?: boolean
	) => {
		try {
			let result = await validatePunches(
				punchId,
				campusId,
				studEnrollId,
				badgeNumber,
				punchIn,
				punchOut,
				isDistEdPunch
			);
			if (punchId.length === 1 && punchOut) {
				await createAttendacePunchRecord(
					campusId,
					studEnrollId,
					badgeNumber,
					undefined,
					punchOut,
					isDistEdPunch
				);
			} else {
				props.showSnackBar(result);
			}
		} catch (error) {
			props.showSnackBar({
				variant: "error",
				showSnackBar: true,
				messageInfo: error.message,
			});
		}
		await refreshGridData();
	};
	const updateAttendancePunchRecord = async (
		punchId: number[],
		campusId: string,
		studEnrollId: string,
		badgeNumber: string,
		punchIn: Date,
		punchOut?: Date
	) => {
		try {
			let result = await updatePunchRecord(
				punchId,
				campusId,
				studEnrollId,
				badgeNumber,
				punchIn,
				punchOut
			);
			if (punchId.length === 1 && punchOut) {
				await createAttendacePunchRecord(
					campusId,
					studEnrollId,
					badgeNumber,
					undefined,
					punchOut
				);
			} else {
				props.showSnackBar(result);
			}
		} catch (error) {
			props.showSnackBar({
				variant: "error",
				showSnackBar: true,
				messageInfo: error.message,
			});
		}
		await refreshGridData();
	};


	const getStudentPunchDetails = async (
		studetEnrollmentId: string,
		date: Date
	): Promise<IPostAttendacePunchRecords | undefined> => {
		try {
			const data = await getStudentAttendanceDetailsByDate(
				studetEnrollmentId,
				date
			);
			return data;
		} catch (error) {
			props.showSnackBar({
				variant: "error",
				showSnackBar: true,
				messageInfo: error.message,
			});
		}
		return undefined;
	};

	const changeDate = (check: number = 0) => {
		if (!check) {
			check =
				props.selectedSlots &&
					Object.values(props.selectedSlots).findIndex(
						(val: any) => !!val?.dirty
					) > -1
					? 1
					: 2;
		}
		if (check === 1) {
			props.setconfirmationDialogProps({
				open: true,
				message: "You are about to change the week. Any unsaved data will be lost. Are you sure you want to continue?",
				onOk: () => changeDate(2),
				onCancel: closeConfirmationDialog,
			});
			return;
		}
		props.applyFilters({
			...props.filters,
			startDate: startOfWeek(selectedDate),
			endDate: endOfWeek(selectedDate),
		});
	};

	React.useEffect(() => {
		if (
			selectedDate &&
			props.filters.startDate.toString() !== "Invalid Date" &&
			!isEqual(startOfWeek(selectedDate), props.filters.startDate)
		) {
			changeDate();
		}
	}, [selectedDate]);


	React.useEffect(() => {
		setGridData([]);
		setExpandAllDate(undefined);
		closeModal();
		let startDate = startOfWeek(selectedDate);

		if (
			props.filters.startDate &&
			props.filters.startDate.toString() !== "Invalid Date" &&
			!isEqual(props.filters.startDate, startOfWeek(selectedDate))
		) {
			setSelectedDate(startOfWeek(props.filters.startDate));
			startDate = startOfWeek(props.filters.startDate);
		}
		if (
			props.filters &&
			props.filters.startDate &&
			props.filters.startDate.toString() !== "Invalid Date" &&
			props.filters.status &&
			props.filters.status.length > 0
		) {
			loadGridData(startDate);
		}
		closeConfirmationDialog();
	}, [props.filters]);

	const handleResize = () => {
		if (!loadingNext) {
			setResize(resize + 1);
		}
	};

	React.useEffect(() => {
		window.addEventListener("resize", handleResize);

		// returned function will be called on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	const sortedGridData = React.useMemo(() => {
		if(sortOn == 'Name'){
			let copy = cloneDeep(gridData);
			copy.sort(function(a, b){
				let x = (a?.studentName ?? '').toLowerCase();
				let y = (b?.studentName ?? '').toLowerCase();
				if (x < y) {return sortOrder == 'ASC' ?  -1 : 1;}
				if (x > y) {return sortOrder == 'ASC' ? 1 : -1;}
				return 0;
			  });

			  return copy;
		}else{
			let copy = cloneDeep(gridData);
			copy.sort(function(a, b){
				let x = (a?.studentBadgeId ?? '').toLowerCase();
				let y = (b?.studentBadgeId ?? '').toLowerCase();
				if (x < y) {return sortOrder == 'ASC' ?  -1 : 1;}
				if (x > y) {return sortOrder == 'ASC' ? 1 : -1;}
				return 0;
			  });

			  return copy;
		}
	},[gridData, sortOn, sortOrder]);

	return (
		<Card className={classes.cardRoot} square={true}>
			{/* <Typography className={clsx(classes.cardTitle, classes.headerText)}>
        Post Weekly Attendance
        </Typography> */}
			<CardContent className={classes.cardContent}>
				<div>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<DatePicker
							format={"MMMM yyyy"}
							renderDay={renderWrappedWeekDay}
							onClose={() => setDatePickerOpen(false)}
							value={selectedDate}
							open={datePickerOpen}
							onChange={(date: any) => setSelectedDate(date)}
							TextFieldComponent={(propsParams: any) => (
								<div className={clsx(classes.calenderHeaderText)}>
									<Typography className={clsx(classes.calendarTitle)}>
										<span
											className={classes.calendarDateTitle}
											onClick={() => {
												setDatePickerOpen(true);
											}}
										>
											{propsParams.value}
										</span>
										<IconButton
											onClick={() =>
												setSelectedDate(endOfWeek(subDays(selectedDate, 7)))
											}
										>
											<KeyboardArrowLeft />
										</IconButton>{" "}
										Week {getWeek(selectedDate)}
										<IconButton
											onClick={() =>
												setSelectedDate(startOfWeek(addDays(selectedDate, 7)))
											}
										>
											<KeyboardArrowRight />
										</IconButton>
									</Typography>
								</div>
							)}
						></DatePicker>
					</MuiPickersUtilsProvider>
				</div>
				<TableContainer className={classes.tableContainer}>

					<Table stickyHeader size="small">
						<TableHead className={classes.tableHeader}>
							<TableRow>
								<TableCell
									variant="head"
									component="th"
									className={clsx(classes.tableHead, classes.alignCenter)}
								>
									Badge ID

									<IconButton
										onClick={() => {
											setSortOn('BadgeId');
											setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
										}}
									>
										<SortIcon
											className={ sortOn != 'BadgeId' ? classes.disableColor : (sortOrder === "ASC" ? classes.sortDesc : "")
											}
										/>
									</IconButton>
								</TableCell>
								<TableCell
									variant="head"
									component="th"
									className={clsx(classes.tableHead, classes.alignLeft)}
								>
									Student Name
									<IconButton
										onClick={() => {
											setSortOn('Name');
											setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
										}}
									>
										<SortIcon
											className={ sortOn != 'Name' ? classes.disableColor : (sortOrder === "ASC" ? classes.sortDesc : "")
											}
										/>
									</IconButton>
								</TableCell>
								{renderWeekGridHeader()}

							</TableRow>
						</TableHead>
						<TableBody>
							{loadingPrev && (
								<TableRow
									className={classes.tableRow}
									hover={false}
									selected={false}
								>
									<TableCell
										colSpan={10}
										variant="body"
										className={classes.alignCenter}
									>
										<CircularProgress />
									</TableCell>
								</TableRow>
							)}
							{sortedGridData.map((item, index) => (
								<React.Fragment key={`wrapper-${index}`}>
									<TableRow
										className={classes.tableRow}
										hover={false}
										selected={false}
										key={`row-${index}`}
									>
										<TableCell variant="body" className={classes.alignCenter}>
											{item.studentBadgeId}
										</TableCell>
										<TableCell variant="body">{item.studentName}</TableCell>
										{renderWeekGridValues(item, index)}

									</TableRow>

								</React.Fragment>
							))}
							{loadingNext && (
								<TableRow
									className={classes.tableRow}
									hover={false}
									selected={false}
								>
									<TableCell
										rowSpan={gridData.length === 0 ? PAGE_SIZE : 1}
										colSpan={9}
										variant="body"
										className={classes.alignCenter}
									>
										<CircularProgress />
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</CardContent>
		</Card>
	);
};

export default withRouter(PostWeeklyCreditHourAttendanceTable);
