import { Action } from "redux";
import * as actionTypes from "../../constants/actions.constants";
import { StudentProfile } from "../../interfaces/student/profile/StudentProfile";

const initialState: any = {
	getSelectedStudent: function(userId: any) {
		return this[userId] ? this[userId].selectedStudent : null;
	},
	getSelectedCampus: function(userId: any) {
		return this[userId] ? this[userId].selectedCampus : null;
	}
};

const userStateReducer = (state = initialState, action: Action) => {
	switch (action.type) {
		case actionTypes.userStateConstants.USER_STATE_SET_SELECTED_CAMPUS: {
			let newState = { ...state };
			newState[(action as any).data.userId] = {
				...newState[(action as any).data.userId],
				selectedCampus: (action as any).data.selectedCampus
			};
			return newState;
		}
		case actionTypes.userStateConstants.USER_STATE_SET_SELECTED_STUDENT: {
			let newState = { ...state };
			newState[(action as any).data.userId] = {
				...newState[(action as any).data.userId],
				selectedStudent: (action as any).data.studentProfile
			};
			return newState;
		}

		default: {
			return state;
		}
	}
};

export default userStateReducer;
