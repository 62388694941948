import * as React from 'react';
import { Userpilot as uPilot } from 'userpilot'
import { useSelector } from 'react-redux'
import packageJson from "../package.json";


export default function UserPilotProvider (props: any) {
    const { path } = props;

    const user = useSelector((state: any) => state.session?.user);
    const refreshUserPilot = useSelector((state: any) => state.account.refreshUserPilot);
    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );

    const appConfig = useSelector((state: any) => state.session?.appConfig);
    const userPilotKey = appConfig && appConfig.appConfig ? appConfig.appConfig.UserpilotKey : undefined;


    // INITIALIZATION OF USER PILOT
    React.useEffect(() => {    
        if (userPilotKey)
            uPilot.initialize(userPilotKey);
    }, [userPilotKey])

    // SETTING UP USER INFO
    React.useEffect(() => {    
        if (userPilotKey) 
            identifyUser(user)
    }, [userPilotKey, userSelectedCampus])

    // FORCING USER PILOT TO TAKE THE LATEST USER INFO
    React.useEffect(() => {  
        if (refreshUserPilot)
            identifyUser(user);           
    }, [refreshUserPilot])
        
    // RELOADING AFTER SCREEN/URL CHANGE 
	React.useEffect(() => {
        uPilot.reload(path);
	}, [path]);

    // IDENTIFY USER 
    const identifyUser = (userObj : any) => { 
       if(userObj && userSelectedCampus)
       {
            uPilot.identify(userObj.userId, { 
                name: userObj.fullName, 
                email: userObj.email,   
                version: packageJson.version,
                creation: userObj.creation,
                lastLogin: userObj.lastLogin,
                campusId: userSelectedCampus          
          });  
       }      
    }

    return (<span/>)
}