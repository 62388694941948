import React from 'react';
import {
	makeStyles,
	Card,
	createStyles,
	CardContent,
	Grid,
	CardActions,
	Paper,
	Table,
	withStyles,
	TableCell,
	TableRow,
	TableContainer,
	TableHead,
	TableBody,
	IconButton,
	CircularProgress,
	Popover,
	Typography,
	Button,
} from '@material-ui/core';
import CustomCardTitle from '../../../interfaces/common/card/CustomCardTitle';
import { useSelector } from 'react-redux';
import * as StudentNotesApi from "../../../api/student/notes/StudentNotesApi";
import { INotesDetail, INewNotes } from "../../../interfaces/student/notes/INotesInfo";
import { StudentProfile } from "../../../interfaces/student/profile/StudentProfile";
import AddNotesPopUp from './AddNotes';
import CustomSnackbar from "../../../components/notifications/CustomSnackbar";
import { CustomSnackBarProps } from "../../../interfaces/common/CustomSnackBarProps";
import ModuleAutoComplete from "../../../components/AutoComplete/ModuleAutoComplete";
import PrintIcon from "@material-ui/icons/Print";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AccessManager from "../../../components/security/AccessManager";
import { security } from "../../../constants/Security/securityConstants";

const StyledTableCell = withStyles((theme: any) =>
	createStyles({
		head: {
			backgroundColor: theme.palette.site.secondary,
			fontSize: 17,
			color: theme.palette.black,
			fontWeight: 'bold',
			textAlign: 'center',
			boxShadow: '1px 1px 0px 0px rgba(0,0,0,0.2)',
			'&:first-child': {
				width: '42px',
				padding: theme.spacing(0, 1)
			}
		},
		body: {
			fontSize: 16,
			color: theme.palette.black,
			fontWeight: 500,
			cursor: 'pointer',
			textAlign: 'left',
			'&:first-child': {
				width: '42px',
				padding: theme.spacing(0, 1)
			}
		},
	}),
)(TableCell);

const useStyles = makeStyles((theme: any) =>
	createStyles({
		cardTitle: {
			fontSize: 17,
			color: theme.palette.black,
			textDecoration: 'bold',
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1),
			marginBottom: theme.spacing(1),
		},
		headerText: {
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(0),
		},
		cardContent: {
			padding: theme.spacing(1, 3),
			height: '100%',
		},
		saveButton: {
			width: 90,
		},
		tableContainer: {
			height: '400px',
		},
		tableEmptyRow: {
			height: '350px',
			textAlign: 'center',
			fontSize: 20,
			opacity: 0.3,
		},
		table: {
			width: '100%',
		},
		iconColor: {
			color: theme.palette.black
		},
		searchField: {
			'&::placeholder': {
				color: '#000000'
			}
		},
		selectionCheckBox: {
			padding: theme.spacing(0)
		},
		preLoaderCell: {
			textAlign: 'center',
			padding: theme.spacing(1)
		},
		cardMain: {
			marginTop: theme.spacing(2)
		},
		popOverButtons: {
			display: "flex",
			justifyContent: "space-evenly",
			flexDirection: "row",
			padding: 5
		},
		typography: {
			padding: theme.spacing(2)
		},
		lineThru: {
			textDecoration: "line-through",
		},
	})
);

const Notes = () => {
	const classes = useStyles({});
	const [notes, setNotes] = React.useState<Array<INotesDetail>>([]);
	const [module, setModule] = React.useState<number | null>(null);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [editedNotesId, setEditedNotesId] = React.useState<number | null>(null);
	const [notesPopUpOpen, setNotesPopUpOpen] = React.useState<boolean>(false);

	const user = useSelector((state: any) => state.session.user);

	const selectedStudent: StudentProfile = useSelector(
		(state: any) => state.student
	) as StudentProfile;

	const printLeadNotes = () => {
		StudentNotesApi.PrintLeadNotes(selectedStudent.leadId, module).then(
			(response: any) => { },
			(exception: any) => { }
		);
	};

	const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
		{
			showSnackBar: false,
			messageInfo: undefined,
			variant: "info",
		}
	);

	const openNotesPopUp = React.useCallback(() => {
		setEditedNotesId(0);
		setNotesPopUpOpen(true);
	}, []);

	const closeNotesPopUp = React.useCallback(() => {
		setNotesPopUpOpen(false);
		setEditedNotesId(null);
	}, []);

	const loadNotes = () => {
		if ((selectedStudent.selectedEnrollment && selectedStudent.selectedEnrollment.stuEnrollId) || notesPopUpOpen == false) {
			StudentNotesApi.getStudentNotes(selectedStudent.leadId, module).then(
				(response: any) => {
					if (response && response.data) {
						setNotes(response.data);
					}
				},
				(exception: any) => { }
			);
		}
	}

	React.useEffect(() => {
		loadNotes();
	}, [selectedStudent.selectedEnrollment, module, notesPopUpOpen]);

	const setRowToEdit = (notesId: any) => {
		setEditedNotesId(notesId);
		setNotesPopUpOpen(true);
	}
	const handleFieldOnChange = (
		fieldId: string,
		value: any,
		mapFunction?: Function
	) => {
		setModule(value);
	};

	const [notesIdDelete, setNotesIdToDelete] = React.useState<number | null>(null);
	const [popOverAnchorEl, setPopOverAnchorEl] = React.useState<HTMLButtonElement | null>(null);
	const popOverOpen = Boolean(popOverAnchorEl);
	const popOverId = popOverOpen ? "simple-popover-notes" : undefined;
	const handleClosePopOver = () => {
		setNotesIdToDelete(null);
		setPopOverAnchorEl(null);
	};
	const handleClickPopOver = (event: any, notesId: any) => {
		setNotesIdToDelete(notesId);
		setPopOverAnchorEl(event.currentTarget);
	};
	const handleArchive = () => {
		if (notesIdDelete != null) {
			StudentNotesApi.ArchiveNotesById(notesIdDelete).then(
				(response: any) => {
					if (response == true) {
						setSnackBarProps((props: any) => {
							return {
								variant: "success",
								showSnackBar: true,
								messageInfo: "Note(s) deleted successfully.",
							};
						});
						loadNotes();
					}
				},
				(exception: any) => { }
			);
		}
		setNotesIdToDelete(null);
		setPopOverAnchorEl(null);
	};

	return (
		<React.Fragment>
			<CustomSnackbar
				variant={snackBarProps.variant}
				message={snackBarProps.messageInfo}
				open={snackBarProps.showSnackBar}
				onClose={(event?: React.SyntheticEvent, reason?: string) => {
					setSnackBarProps((props: any) => {
						return { ...props, showSnackBar: false };
					});
				}}
			></CustomSnackbar>
			<Card square={true} elevation={2} className={classes.cardMain}>
				<CustomCardTitle title='Student Notes'></CustomCardTitle>
				<CardActions>
					<Grid container direction="row" spacing={2}>
						<Grid item xs={12}>
							<ModuleAutoComplete
								id="moduleId"
								label="Module *"
								name="moduleId"
								useOnlyDocumentsModule={true}
								filterHandle={(v: any) => {
									handleFieldOnChange("moduleId", v ? v.value : null);
								}}
							/>
						</Grid>
					</Grid>
					<Grid container direction='row' spacing={1} dir="rtl">
						<Grid
							item
							alignContent='flex-start'
							alignItems='flex-start'
							justify='flex-start'
						>

							<AccessManager
								allowedPermissions={[security.permissions.studentNotes.updateNotes]}
								renderNoAccess={() =>
									<IconButton
										aria-label="add notes"
										disabled={true}
										onClick={openNotesPopUp}
									>
										<AddIcon />
									</IconButton>
								}
							>
								<IconButton
									aria-label="add notes"
									onClick={openNotesPopUp}
								>
									<AddIcon />
								</IconButton>
							</AccessManager>
						</Grid>
						<Grid
							item
							alignContent='flex-start'
							alignItems='flex-start'
							justify='flex-start'
						>
							<IconButton
								aria-label="print notes"
								onClick={printLeadNotes}
							>
								<PrintIcon />
							</IconButton>
						</Grid>
					</Grid>
				</CardActions>
				<CardContent>
					<Grid container direction="column" spacing={2}>
						<Grid
							item
							alignContent="flex-start"
							alignItems="flex-start"
							justify="flex-start"
						>
							<TableContainer component={Paper} square className={classes.tableContainer}>
								<Table stickyHeader size='small' className={classes.table} aria-label='customized table'>
									<TableHead>
										<TableRow>
											<TableCell>Date</TableCell>
											<TableCell>Module</TableCell>
											<TableCell>Source</TableCell>
											<TableCell>Type</TableCell>
											<TableCell>Field</TableCell>
											<TableCell>Note</TableCell>
											<TableCell>User</TableCell>
											<TableCell>Edit</TableCell>
											<TableCell>Archive</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{
											<AccessManager
												allowedPermissions={[security.permissions.studentNotes.viewConfidentialNotes]}
												renderNoAccess={() =>
													<React.Fragment>
														{loading ?
															<TableRow>
																<StyledTableCell colSpan={2} className={classes.preLoaderCell}>
																	<CircularProgress />
																</StyledTableCell>
															</TableRow>
															: notes?.filter(s => s.noteType !== "Confidential").map((row: INotesDetail, index: any) => (
																<TableRow>
																	<TableCell>{new Date(
																		row.modDate
																	).toLocaleString()}</TableCell>
																	<TableCell>{row.moduleCode}</TableCell>
																	<TableCell>{row.pageField}</TableCell>
																	<TableCell>{row.noteType}</TableCell>
																	<TableCell>{row.pageField}</TableCell>
																	<TableCell className={row.isArchived ? classes.lineThru : ""}>{row.noteText}</TableCell>
																	<TableCell>{row.userName}</TableCell>
																	<TableCell>
																		<AccessManager
																			allowedPermissions={[security.permissions.studentNotes.updateNotes]}
																			renderNoAccess={() => <IconButton disabled={true} key={`editButton-${row.notesId}`}>
																				<EditIcon key={`editIcon-${row.notesId}`} />
																			</IconButton>}
																		>
																			<IconButton disabled={row.userId != user.userId || row.isArchived} key={`editButton-${row.notesId}`} onClick={() => (row.userId = user.userId) ? setRowToEdit(row.notesId) : setRowToEdit(row.notesId)}>
																				<EditIcon key={`editIcon-${row.notesId}`} />
																			</IconButton>
																		</AccessManager>

																	</TableCell>
																	<TableCell>
																		<IconButton disabled={row.isArchived || !row.canArchive} key={`archiveButton-${row.notesId}`} onClick={(e: any) => handleClickPopOver(e, row.notesId)}>
																			<DeleteIcon key={`archiveIcon-${row.notesId}`} />
																		</IconButton>

																	</TableCell>
																</TableRow>
															))}
													</React.Fragment>
												}
											>
												{loading ?
													<TableRow>
														<StyledTableCell colSpan={2} className={classes.preLoaderCell}>
															<CircularProgress />
														</StyledTableCell>
													</TableRow>
													: notes?.map((row: INotesDetail, index: any) => (
														<TableRow>
															<TableCell>{new Date(
																row.modDate
															).toLocaleString()}</TableCell>
															<TableCell>{row.moduleCode}</TableCell>
															<TableCell>{row.pageField}</TableCell>
															<TableCell>{row.noteType}</TableCell>
															<TableCell>{row.pageField}</TableCell>
															<TableCell className={row.isArchived ? classes.lineThru : ""}>{row.noteText}</TableCell>
															<TableCell>{row.userName}</TableCell>
															<TableCell>
																{
																	row.noteType === "Confidential" ?
																		<AccessManager
																			allowedPermissions={[security.permissions.studentNotes.updateNotes]}
																			renderNoAccess={() => <IconButton disabled={true} key={`saveButton-${row.notesId}`}>
																				<EditIcon key={`editIcon-${row.notesId}`} />
																			</IconButton>}
																		>
																			<AccessManager
																				allowedPermissions={[security.permissions.studentNotes.updateConfidentialNotes]}
																				renderNoAccess={() => <IconButton disabled={true} key={`saveButton-${row.notesId}`}>
																					<EditIcon key={`editIcon-${row.notesId}`} />
																				</IconButton>}
																			>
																				<IconButton disabled={row.userId != user.userId || row.isArchived} key={`saveButton-${row.notesId}`} onClick={() => (row.userId = user.userId) ? setRowToEdit(row.notesId) : setRowToEdit(row.notesId)}>
																					<EditIcon key={`editIcon-${row.notesId}`} />
																				</IconButton>
																			</AccessManager>
																		</AccessManager>


																		:
																		<AccessManager
																			allowedPermissions={[security.permissions.studentNotes.updateNotes]}
																			renderNoAccess={() => <IconButton disabled={true} key={`saveButton-${row.notesId}`}>
																				<EditIcon key={`editIcon-${row.notesId}`} />
																			</IconButton>}
																		>
																			<IconButton disabled={row.userId != user.userId || row.isArchived} key={`saveButton-${row.notesId}`} onClick={() => (row.userId = user.userId) ? setRowToEdit(row.notesId) : setRowToEdit(row.notesId)}>
																				<EditIcon key={`editIcon-${row.notesId}`} />
																			</IconButton>
																		</AccessManager>
																}
															</TableCell>
															<TableCell>
																<IconButton disabled={row.isArchived || !row.canArchive} key={`archiveButton-${row.notesId}`} onClick={(e: any) => handleClickPopOver(e, row.notesId)}>
																	<DeleteIcon key={`archiveIcon-${row.notesId}`} />
																</IconButton>

															</TableCell>
														</TableRow>
													))}
											</AccessManager>
										}
										{
											notes?.length == 0 ?
												<TableRow>
													<TableCell className={classes.tableEmptyRow} colSpan={8}>No Data</TableCell>
												</TableRow>
												: null
										}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</Grid>
				</CardContent>
				<Popover
					id={popOverId}
					open={popOverOpen}
					anchorEl={popOverAnchorEl}
					onClose={handleClosePopOver}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center"
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "center"
					}}
				>
					<Typography
						color="textSecondary"
						className={classes.typography}
						variant="body1"
					>
						Are you sure you want to archive this Notes?
					</Typography>
					<div className={classes.popOverButtons}>
						<Button
							variant="contained"
							size="small"
							onClick={() => handleArchive()}
							color="primary"
						>
							Yes
						</Button>
						<Button
							variant="contained"
							size="small"
							onClick={handleClosePopOver}
							color="secondary"
						>
							Cancel
						</Button>
					</div>
				</Popover>
			</Card>
			<AddNotesPopUp editedNotesId={editedNotesId} module={module} open={notesPopUpOpen} handleClose={closeNotesPopUp} isLoading={loading} setLoading={setLoading} setSnackBar={setSnackBarProps} />
		</React.Fragment>
	);
};

export default Notes;
