import API from "../../apiWrapper";
import IPlacementInfo from "../../../interfaces/student/placement/PlacementInfo";
import IPlacementHistoryDetails from "../../../interfaces/student/placement/PlacementHistory";

export const getStudentPlacementDetails = async (EnrollmentId: string): Promise<IPlacementInfo> => {
	return API()
		.get("Placement/GetByStudentEnrollment", {
			params: {
				EnrollmentId
			}
		})
		.then(
			(res: any) => {
				if (res && res.data && res.data.result) {
					return res.data.result;
				}
			},
			(_error: any) => {
				return null;
			}
		);
};

export const getStudentPlacementHistory = async (EnrollmentId: string): Promise<IPlacementHistoryDetails[]> => {
	return API()
		.get("Placement/GetPlacementHistoryByStudent", {
			params: {
				EnrollmentId
			}
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res.data;
				}
				return [];
			},
			(_error: any) => {
				return [];
			}
		);
};

export const savePlacementDetails = async (placementInfo: IPlacementInfo, campusId: string): Promise<IPlacementInfo> => {
	return API()
		.post("Placement/SaveStudentPlacementInformation", placementInfo
		, {
			params: {
				campusId
			}
		})
		.then(
			(res: any) => {
				if (res && res.data && res.data.result && res.data.resultStatus === 0) {
					return res.data.result;
				}
				else
				{
					throw(res?.data?.resultStatusMessage);
				}
			},
			(error) => {
				throw(error?.response?.data);
			}
		);
};

export const deleteJobPlacement = async (studentPlacementId: string): Promise<string> => {

	return API()
		.delete("Placement/DeleteJobPlacement", {
			params: {
				studentPlacementId
			}
		})
		.then(
			(res: any) => {
				if (res && res.data && res.data.result) {
					return res.data.resultStatusMessage;
				}
				throw(res?.data?.resultStatusMessage);
			},
			(error: any) => {
				throw(error?.response?.data);
			}
		);
};

export const getEmployerDetailsDetails = async (employerId: string): Promise<IPlacementHistoryDetails> => {

	return API()
		.get("Placement/Employer/GetEmployerDetails", {
			params: {
				employerId
			}
		})
		.then(
			(res: any) => {
				if (res && res.data && res.data.result) {
					return res.data.result;
				}
			},
			(_error: any) => {
				return null;
			}
		);
};
