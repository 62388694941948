import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CampusForCurrentUserAutoComplete from "../../../components/AutoComplete/CampusForCurrentUserAutoComplete";
import { FormControlLabel, Switch, Grid, FormControl, FormLabel, RadioGroup, Radio } from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import * as attendanceSummaryApi from "../../../api/reports/attendance/attendanceSummaryApi";
import { ReportOutput } from "../../../enums/ReportOutput";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import ProgramVersionAutoComplete from "../../../components/AutoComplete/ProgramVersionAutoComplete";
import StudentGroupsAutoComplete from "../../../components/AutoComplete/StudentGroupsAutoComplete";
import StudentNameBadgeIDFilter from '../../../../src/pages/attendance/StudentNameBadgeIDFilter'
import { EnrollmentAutoComplete } from "../../../components/AutoComplete/EnrollmentAutoComplete";
import { IEnrollmentSearchResult } from "../../../interfaces/student/enrollment/EnrollmentSearchResult";
import { IAttendanceSummaryReportParam } from "../../../interfaces/reports/attendance/AttendanceSummaryReportParam";
import { useSelector } from "react-redux";
import TermCalenderAutoComplete from "../../../components/AutoComplete/TermCalenderAutoComplete";
import { IClassAttendanceSummaryReportParam } from "../../../interfaces/reports/attendance/ClassAttendanceSummaryReportParam";
import { cloneDeep, initial } from "lodash";
import ClassAutoComplete from "../../../components/AutoComplete/ClassAutoComplete";
import { GetClassById } from "../../../api/setup/creditHour/classApi";
import moment from "moment";

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(3),
            minHeight: "100%",
            height: "100%",
            maxWidth: "1050px",
            padding: theme.spacing(2),
        },
        "& .makeStyles-content": {
            backgroundColor: theme.palette.background.paper,
        },
        paperRoot: {
            padding: theme.spacing(3, 2),
            width: "100%",
            minHeight: "100%",
            height: "100%",
            overflowX: "hidden",
            overflowY: "auto",
            flexDirection: "column",
            backgroundColor: theme.palette.background.paper,
        },
        searchActions: {
            marginTop: theme.spacing(3),
            textAlign: "left",
        },
        startDateField: {
            width: "100%",
        },
        cardTitle: {
            fontSize: 17,
            color: theme.palette.primary.headerText,
            textDecoration: "bold",
            backgroundColor: theme.palette.secondary.dark,
            padding: theme.spacing(1, 1),
            marginBottom: theme.spacing(1),
        },
        headerText: {
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(0),
        },
        cardContent: {
            padding: theme.spacing(1, 3),
        }, switch: {
            display: "flex",
            alignItems: "flex-end",
            padding: theme.spacing(0, 0.5),
            height: theme.spacing(8.6),
        },
        fieldSet: {
            marginTop: theme.spacing(2),
          },
    })
);

const ClassAttendanceSummary = (props: any) => {
    const { report } = props;
    let reportOverview = { report: report } as IReportOverview;
    const classes = useStyles({});
    const [preview, setPreview] = React.useState<any>();
    const [previewLoader, setPreviewLoader] = React.useState<boolean>();
    const initialModel: IClassAttendanceSummaryReportParam = {
        classId: null,
        exportType: ReportOutput.Pdf,
        sortBy: 'StudentNumber',
        termId: null,
        asOfDate: new Date(),
    };
    const [model, setModel] = React.useState<IClassAttendanceSummaryReportParam>(initialModel);
    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );

    const [minAsOfDate, setMinAsOfDate] = React.useState<Date | undefined>(undefined);

    const data = useForm<any>({ mode: "onBlur" });

    const getAndSetClassStartDate = (classId:string, modelCopy:IClassAttendanceSummaryReportParam) => {
        if(!!classId && classId.trim() != ''){
            GetClassById(classId).then(res => {
                setMinAsOfDate(res.startDate);
    
                if(res.startDate > (modelCopy.asOfDate ?? new Date())){
                    let copy = cloneDeep(modelCopy);
                    copy.asOfDate = res.startDate;
                    setModel(copy);
                }
            });
        }else{
            setMinAsOfDate(undefined);
        }

    }

    const handleTermChange = (value: string) => {
        let copy = cloneDeep(model);
        copy.classId = null;
        copy.termId = value;
        copy.asOfDate = new Date();
        setMinAsOfDate(undefined);
        setModel(copy);
    }

    const handleClassChange = (value: string) => {
        let copy = cloneDeep(model);
        copy.classId = value;
        setModel(copy);
        getAndSetClassStartDate(value, copy);
    }

    const handleAsOfhange = (value: Date) => {
        let copy = cloneDeep(model);
        copy.asOfDate = value;
        setModel(copy);
        data.setValue('asOf',value);
        data.triggerValidation();
    }

    const handleSortByChange = (value: 'StudentNumber' | 'LastName') => {
        let copy = cloneDeep(model);
        copy.sortBy = value;
        setModel(copy);
    }

    const handleExportType = (value: number) => {
        let copy = cloneDeep(model);
        copy.exportType = value;
        setModel(copy);
    }

    const { handleSubmit } = data;

    const exportTypes: Array<DropDownListItem> = [
        { text: "PDF", id: "1" },
        { text: "Excel", id: "2" },
    ];

    const onSubmit = async (isPreview: any) => {
        
        let params = (await getReportParameters()) as IClassAttendanceSummaryReportParam;
        const validation = await data.triggerValidation();
        if (validation) {
            if (isPreview) {
                setPreviewLoader(true);
                attendanceSummaryApi
                    .generateClassAttendanceSummaryReport(params, isPreview)
                    .then((res: any) => {
                        if (res) {
                            res.arrayBuffer().then((buffer: any) => {
                                setPreview(buffer);
                                setPreviewLoader(false);
                            });
                        } else setPreviewLoader(false);
                    });
            } else await attendanceSummaryApi.generateClassAttendanceSummaryReport(params);
        }
    };

    const exportTypeAutoComplete = {
        options: exportTypes,
        getOptionLabel: (option: DropDownListItem) => option.text
    };

    const exportTypeOnChange = (e: any) => {
        let updatedModel = model;
        updatedModel.exportType = !!e ? parseInt(e.id) : null;
        setModel({ ...updatedModel });
    };


    const getReportParameters = async () => {
        const success = await data.triggerValidation();
        if (model && success) {
            let params: IClassAttendanceSummaryReportParam = {
                classId: model?.classId,
                exportType: model?.exportType,
                sortBy: model?.sortBy,
                termId: model?.termId,
                asOfDate:  moment(model?.asOfDate).format('MM/DD/yyyy'),
            };
            return params;
        }
        return {} as IClassAttendanceSummaryReportParam;
    };

    const filterHandler = (data: any) => {
        if (data) {
            setModel(data);
        } else {
            setModel(initialModel);
        }
    };

    const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
        const item = items.find((opt) => {
            if (opt.id == value) return opt;
        });
        return item || ({} as DropDownListItem);
    };

    let parameters = (
        <div className={classes.root}>
            <FormContext {...data}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container direction="row" spacing={1}>
                        <Grid item md={6} sm={6} xs={12}>
                            <TermCalenderAutoComplete
                                id="termId"
                                name="termId"
                                label="Term *"
                                key="termId"
                                required={true}
                                shouldCheckForEffectiveEndDate={true}
                                valueFilter={
                                    model?.termId
                                        ? {
                                            key: "value",
                                            values: [model?.termId],
                                        }
                                        : undefined
                                }
                                filterHandle={(v: any) => {
                                    handleTermChange(v ? v.value : null);
                                }}

                                error={!!data.errors.termId}
                                inputRef={data.register({ required: true })}
                                helperText={
                                    data.errors.termId
                                        ? "Term required."
                                        : undefined
                                }
                            />
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                            <ClassAutoComplete
                                id="classId"
                                name="classId"
                                label="Class *"
                                key="classId"
                                termId={model?.termId ?? undefined}
                                programId={undefined}
                                required={true}
                                valueFilter={
                                    model?.classId
                                        ? {
                                            key: "value",
                                            values: [model?.classId],
                                        }
                                        : undefined
                                }
                                filterHandle={(v: any) => {
                                    handleClassChange(
                                        v ? v.value : null
                                    );
                                }}

                                error={!!data.errors.classId}
                                inputRef={data.register({ required: true })}
                                helperText={
                                    data.errors.classId
                                        ? "Class required."
                                        : undefined
                                }
                            />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="asOf"
                  name="asOf"
                  label="As Of"
                  maxDate={new Date()}
                  minDate={minAsOfDate}
                  disabled={minAsOfDate === undefined || minAsOfDate == null}
                  value={model.asOfDate ? model.asOfDate : new Date()}
                  onChange={(e: any, value: any) =>
                    handleAsOfhange( value ? value : new Date())
                }
                  className={classes.startDateField}
                  KeyboardButtonProps={{
                    "aria-label": "report date",
                  }}
                  innerRef={(ref: any) => {
                    data.register('asOf', {
                        required: true,
                        validate: {
                            afterClassStartDateDate: (value) => {
                                return (!!minAsOfDate && new Date(value) >= new Date(moment(minAsOfDate).format('MM/DD/yyyy'))) || 'As Of date must be equal or after class start date';
                            },
                        },
                    });
                    data.setValue('asOf', model.asOfDate);
                }}
                  error={!!data.errors["asOf"]}
                  helperText={
                    data.errors["asOf"]
                      ? (data.errors["asOf"] as FieldError).message
                      : undefined
                  }
                />
              </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                            <Autocomplete
                                {...exportTypeAutoComplete}
                                autoComplete
                                includeInputInList
                                value={!!model.exportType ? exportTypes.find(m => m.id == (model.exportType ?? '').toString()) : null}
                                onChange={(e: any, v: any) => {
                                    exportTypeOnChange(v);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Export Type"
                                        name="exportType"
                                        error={!!data.errors.exportType}
                                        inputRef={data.register({ required: true })}
                                        helperText={
                                            data.errors.exportType
                                                ? "Export Type is required."
                                                : undefined
                                        }
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>

           
                        <Grid item md={6} sm={6} xs={6}>
                            <FormControl component="fieldset" className={classes.fieldSet}>
                                <FormLabel component="legend">Sort By</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="sortBy"
                                    name="sortBy"
                                    value={model.sortBy}
                                    onChange={(e:any) => {
                                        handleSortByChange(e.target.value)
                                    }}
                                >
                                    <FormControlLabel
                                        labelPlacement="end"
                                        value="StudentNumber"
                                        control={<Radio color="default"  />}
                                        label="Student Number"
                                    />
                                    <FormControlLabel
                                        labelPlacement="end"
                                        value="LastName"
                                        control={<Radio  color="default" />}
                                        label="Last Name"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                    </Grid>
                </form>
            </FormContext>
        </div>
    );
    reportOverview.parameters = parameters;

    return (
        <ReportOverview
            reportOverview={reportOverview}
            filterHandler={filterHandler}
            getReportParameters={getReportParameters}
            exportHandler={onSubmit}
            preview={preview}
            previewLoader={previewLoader}
        />
    );
};
export default ClassAttendanceSummary;
