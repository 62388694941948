import { createStyles, makeStyles, Paper } from "@material-ui/core";
import React, { useState,Fragment } from "react";
import InputField from '../../../../components/_Layout/Inputs/InputField';
import {
    Card,
    Switch,
    TableFooter,
    FormControlLabel,
    CardContent,
    Grid,
    CardActions,
    Table,
    withStyles,
    TableCell,
    TableRow,
    TableContainer,
    TableHead,
    TableBody,
    IconButton,
    CircularProgress,
    Popover,
    Typography,
    Button,
    TextField,
  } from '@material-ui/core';
  import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Pagination } from '@material-ui/lab';
import { toastr } from 'react-redux-toastr';
import SortIcon from '@material-ui/icons/Sort';
  import { useSelector } from 'react-redux';
  import { FormContext, useForm, FieldError } from "react-hook-form";
  import StatusAutoComplete from "../../../../components/AutoComplete/StatusAutoComplete";
  import ResourceAutoComplete from "../../../../components/AutoComplete/ResourceAutoComplete";
  import IComponentType from "../../../../interfaces/setup/grades/ComponentTypes/IComponentType";
  import IComponentTypeDetail from "../../../../interfaces/setup/grades/ComponentTypes/IComponentTypeDetail";
  import MultiCampusAutoComplete from '../../../../components/AutoComplete/MultiCampusAutoComplete';
  import {  getComponentsList,getDetails,saveComponent,deleteComponent } from "../../../../api/setup/grades/componentTypesApi";
  import APIHook from '../../../../api/hook';
import { EmptyGuid } from "../../../../utils/constants";

  
const useStyles = makeStyles((theme: any) =>
	createStyles({
		cardMain: {
			margin: theme.spacing(2, 0),
			minHeight: '100%',
			height: '100%',
			overflowX: 'hidden',
			overflowY: 'auto',
			flexDirection: 'column',
			padding: theme.spacing(2),
		},
		cardMainContent: {
			height: '100%',
		},
		selectAbleRow: {
			cursor: 'pointer',
		},
		pagination: {
			display: 'inline-flex',
		},
		loaderWrapper: {
			textAlign: 'center',
		},
		sortDesc: {
			transform: 'rotate(180deg)',
		}
	})
);

const ComponentTypeSetup: React.FC = () => {
	const classes = useStyles({});
	const [page, setPage] = React.useState<number>(1);
	const [showAll, setShowAll] = React.useState<boolean>(false);
	const [sort, setSort] = React.useState<'asc' | 'desc'>('desc');
	const pageSize = 20;
	const [search, setSearch] = React.useState<string>('');
	const [dirty, setDirty] = React.useState<boolean>(false);
	const [popAnchor, setPopAnchor] = React.useState<null | HTMLElement>(null);
	const [popMessage, setPopMessage] = React.useState<string>('');
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );
	const [popConfirmAction, setPopConfirmAction] = React.useState<() => void>();
	 const [, message, code, saving, callSave, resetState] = APIHook<IComponentTypeDetail, typeof saveComponent>(saveComponent);
	 const [, messageDelete, codeDelete, deleting, callDelete, resetStateDelete] = APIHook< string,  typeof deleteComponent>(deleteComponent);
	 const [fetchedDetails, , detailsCode, loadingDetails, getDescriptionDetail, resetDetails] = APIHook<IComponentTypeDetail, typeof getDetails>(getDetails);
	const [data, , , loading, getList] = APIHook<{
		data: Array<IComponentType>;
		hasMoreData: boolean;
		total: number;
	}, typeof getComponentsList>(getComponentsList);
	React.useEffect(() => {
		getList({ take: pageSize, sort, showAll, skip: (page - 1) * pageSize, search: search || undefined ,campusId:userSelectedCampus});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	 const [details, setDetails] = React.useState<IComponentTypeDetail>({systemComponentId: EmptyGuid,id: EmptyGuid,campusId:EmptyGuid,code:"",description:"",campusGroupId:"",campusGroup:"",statusId:EmptyGuid  }); // eslint-disable-line
	const { register, reset, errors, handleSubmit, triggerValidation } = useForm<IComponentTypeDetail>({
		mode: 'onBlur'
	});

	const onSave = () => {
		callSave(details);
	};

	React.useEffect(() => {
		if (detailsCode === 200) {
			reset({
				...fetchedDetails,
			});
			setDetails({ ...fetchedDetails });
			setDirty(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailsCode, loadingDetails]);

	React.useEffect(() => {
		if (code === 200 && !saving) {
			toastr.success('', 'Component saved successfully.');
			resetDetails();
			setDetails({
				id: EmptyGuid,campusId:EmptyGuid
			});
			reset({});
			setDirty(false);
			getList({ take: pageSize, skip: (page - 1) * pageSize, search: search || undefined, showAll, sort });
		}
		else if (code) {
			toastr.error('', message || 'Something went wrong.');
		}
		resetState();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [code, saving]);

	React.useEffect(() => {
		if (codeDelete === 200 && !deleting) {
			toastr.success('', 'Component deleted successfully.');
			resetDetails();
			setDetails({
				id: EmptyGuid, campusId:EmptyGuid
			});
			reset({});
			setDirty(false);
			getList({ take: pageSize, skip: (page - 1) * pageSize, search: search || undefined, showAll, sort });
		}
		else if (codeDelete) {
			toastr.error('', messageDelete || 'Something went wrong.');
		}
		resetStateDelete();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [codeDelete, deleting]);

	return (
		<React.Fragment>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<Card elevation={12} className={classes.cardMain}>
					<CardContent className={classes.cardMainContent}>
						<Grid container spacing={1} className={classes.cardMainContent}>
							<Grid item xs={6} className={classes.cardMainContent}>
								<Card elevation={2} className={classes.cardMainContent}>
									<CardContent className={classes.cardMainContent}>
										<TableContainer className={classes.cardMainContent}>
											<Table stickyHeader>
												<TableHead>
													<TableRow>
														<TableCell variant="head">
															<InputField
																value={search}
																onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
																	setSearch(e.target.value);
																	if (!e.target.value || e.target.value.length > 2) {
																		setPage(1);
																		getList({ take: pageSize, skip: 0, search: e.target.value, showAll, sort, campusId: userSelectedCampus});
																	}
																}}
																label="Component Type Search" />
															<FormControlLabel
																control={
																	<Switch
																		checked={showAll}
																		onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
																			setShowAll(e.target.checked);
																			setPage(1);
																			getList({ take: pageSize, skip: 0, search, showAll: e.target.checked, sort, campusId: userSelectedCampus });
																		}}
																		name="checkedB"
																		color="primary"
																	/>
																}
																label="Show All"
															/>
															<IconButton onClick={() => {
																const newSort = sort === 'asc' ? 'desc' : 'asc';
																setSort(newSort);
																setPage(1);
																getList({ take: pageSize, skip: 0, search, showAll, sort: newSort, campusId: userSelectedCampus });
															}}>
																<SortIcon className={sort === 'asc' && classes.sortDesc || ''} />
															</IconButton>
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{loading && <TableRow>
														<TableCell align="center">
															<CircularProgress size={50} color="primary" />
														</TableCell>
													</TableRow>}
													{
														!loading && data?.data?.map((item: IComponentType) => {
															return (
																<TableRow className={classes.selectAbleRow} key={item.id}
																	onClick={(e) => {
																		if (!dirty) {
																			getDescriptionDetail(item.id??"",item.id== EmptyGuid?EmptyGuid :userSelectedCampus);
																		}
																		else {
																			setPopMessage('You have unsaved changes. Are you sure you want to proceed?');
																			setPopConfirmAction(() => () => {
																				getDescriptionDetail(item.id??"",item.id== EmptyGuid?EmptyGuid :userSelectedCampus);
																			});
																			setPopAnchor(e.currentTarget);
																		}
																	}
																	}
																>
																	<TableCell>
																		{item.code}-{item.description}
																	</TableCell>
																</TableRow>
															);
														}
														)
													}
													{
														(data?.data?.length || 0) < pageSize && !loading &&
														[...Array(pageSize - (data?.data?.length || 0))].map((_, index) => {
															return (
																<TableRow key={index}>
																	<TableCell>
																		&nbsp;
																	</TableCell>
																</TableRow>
															);
														})
													}
												</TableBody>
												<TableFooter>
													<TableRow>
														<TableCell align="center">
															<Pagination
																className={classes.pagination}
																disabled={(!(data?.hasMoreData) ?? true) && (page || 1) === 1}
																onChange={(_e, page) => {
																	setPage(page);
																	getList({ take: pageSize, skip: (page - 1) * pageSize, search: search || undefined, showAll, sort,  campusId: userSelectedCampus });
																}}
																count={
																	Math.ceil((data?.total || 0) / pageSize) || 0
																} />
														</TableCell>
													</TableRow>
												</TableFooter>
											</Table>
										</TableContainer>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={4}>
								<Card elevation={2}>
									<CardContent>
										<form >
											{((loadingDetails) &&
												<div className={classes.loaderWrapper}>
													<CircularProgress size={50} color="primary" />
												</div>)
												||
												<Fragment>
													<InputField
														required
														name="code"
														label="Code"
														inputRef={register({ required: 'Code is required.' })}
														error={errors.code ? true : false}
														helperText={errors.code ? errors.code.message : ''}
														onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
															if ((details.code || '') !== (e.target.value || '')) {
																setDetails({
																	...details,
																	code: e.target.value
																});
																setDirty(true);
															}
														}
														}
													/>
													<InputField
														required
														name="description"
														label="Description"
														inputRef={register({ required: 'Description is required.' })}
														error={errors.description ? true : false}
														helperText={errors.description ? errors.description.message : ''}
														onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
															if (details.description !== e.target.value) {
																setDetails({
																	...details,
																	description: e.target.value
																});

																setDirty(true);
															}
														}
														}
													/>
													<StatusAutoComplete
														error={errors.statusId ? true : false}
														helperText={errors.statusId ? errors.statusId.message : ''}
														label='Status *'
														name="status"
														inputRef={register({ required: 'Status is required.' })}
														valueFilter={
															details?.statusId ? {
																key: 'value',
																values: [details?.statusId]
															} : undefined}
														filterHandle={(value: any) => {
															setDetails({
																...details,
																statusId: (value.value || undefined)
															});
															if (details.statusId !== value?.value) { setDirty(true); }
														}}

													/>

                          <MultiCampusAutoComplete
                            multiple={false}
														error={errors.campusId ? true : false}
														helperText={errors.campusId ? errors.campusId.message : ''}
														label='Campus *'
														name="campusId"
														inputRef={register({ required: 'campus is required.' })}
														valueFilter={
															details?.campusId ? {
																key: 'value',
																values: [details?.campusId]
															} : undefined}
														filterHandle={(value: any) => {
															setDetails({
																...details,
																campusId: (value.value || undefined)
															});
															if (details.campusId !== value?.value) { setDirty(true); }
														}}

													/>

                          <ResourceAutoComplete
														error={errors.systemComponentId ? true : false}
														helperText={errors.systemComponentId ? errors.systemComponentId.message : ''}
														label='System Component *'
														name="systemComponentId"
														inputRef={register({ required: 'system Component is required.' })}
														valueFilter={
															details?.systemComponentId ? {
																key: 'value',
																values: [details?.systemComponentId]
															} : undefined}
														filterHandle={(value: any) => {
															setDetails({
																...details,
																systemComponentId: (value.value || undefined)
															});
															if (details.systemComponentId !== value?.value) { setDirty(true); }
														}}
                            params={
                              {
                                  resourceTypeId: 10
                              }
                          }

													/>
													
												</Fragment>
											}
										</form>
									</CardContent>
									<CardActions>
										<Button color="primary" variant="contained" onClick={handleSubmit(onSave)} disabled={saving || deleting || loadingDetails}>SAVE</Button>
										{' '}
										<Button disabled={saving || deleting || loadingDetails} color="secondary" variant="contained" onClick={(e) => {
											if (!dirty) {
												resetDetails();
												setDetails({
													id: EmptyGuid
												});
												reset({});
												setDirty(false);
											}
											else {
												setPopMessage('You have unsaved changes. Are you sure you want to proceed?');
												setPopAnchor(e.currentTarget);
												setPopConfirmAction(() => () => {
													resetDetails();
													setDetails({});
													reset({});
													setDirty(false);
												});
											}
										}
										}>NEW</Button>
										{
											details?.id && details?.id !== EmptyGuid &&
											<Button disabled={saving || deleting || loadingDetails} color="secondary" variant="contained" onClick={
												(e) => {
													setPopMessage('Are you sure you want to delete this Component?');
													setPopAnchor(e.currentTarget);
													setPopConfirmAction(() => () => {
														callDelete(details?.id || EmptyGuid,userSelectedCampus);
													});
												}
											}>DELETE</Button>
										}
									</CardActions>
								</Card>
							</Grid>
						</Grid>

					</CardContent>
				</Card>
			</MuiPickersUtilsProvider>
			<Popover
				anchorEl={popAnchor}
				open={Boolean(popAnchor)}
				onClose={() => {
					setPopAnchor(null);
					setPopMessage('');
					setPopConfirmAction(undefined);
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Card>
					<CardContent>
						<Typography variant='h6'>{popMessage}</Typography>
					</CardContent>
					<CardActions>
						<Button size="small" color="primary" variant="contained" onClick={() => {
							if (popConfirmAction) { popConfirmAction(); }
							setPopAnchor(null);
							setPopMessage('');
							setPopConfirmAction(undefined);
						}
						}>Confirm</Button>
						{' '}
						<Button size="small" color="secondary" variant="contained" onClick={() => {
							setPopAnchor(null);
							setPopMessage('');
							setPopConfirmAction(undefined);
						}
						}>CANCEL</Button>
					</CardActions>
				</Card>
			</Popover>

		</React.Fragment>
	);
};

export default ComponentTypeSetup;
