import React from 'react';
import {
    makeStyles,
    createStyles,
    Grid,
    Paper,
    Table,
    withStyles,
    TableCell,
    TableRow,
    TableContainer,
    TableHead,
    TableBody,
    IconButton,
    CircularProgress,
    Button,
    Card,
    CardContent,
    TextField,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import InputField from '../../../../components/_Layout/Inputs/InputField';
import { InputType } from '../../../../constants/uiConstants/inputConstants';
import { EmptyGuid } from '../../../../utils/constants';
import { FieldError, useForm, FormContext } from 'react-hook-form';
import IGradeScaleDetails from '../../../../interfaces/setup/grades/ComponentTypes/IGradeScaleDetails';
import GradeSystemDetailAutoComplete from '../../../../components/AutoComplete/GradeSystemDetailAutoComplete';
const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {},
  })
)(TextField);


const StyledTableCell = withStyles((theme: any) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.site.secondary,
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            textAlign: 'left',
            boxShadow: '1px 1px 0px 0px rgba(0,0,0,0.2)',
            '&:first-child': {
                minWidth: theme.spacing(30),
                padding: theme.spacing(0, 1)
            }
        },
        body: {
            fontSize: 16,
            color: theme.palette.black,
            fontWeight: 400,
            cursor: 'pointer',
            textAlign: 'left',
            padding: theme.spacing(1),
            '&:first-child': {
                minWidth: theme.spacing(30),
                padding: theme.spacing(0, 1)
            }
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: any) =>
    createStyles({
        root: {
            '&:nth-of-type(even)': {
                backgroundColor: theme.palette.site.secondary,
            },
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.white,
            },
            '&:hover': {
                backgroundColor: theme.palette.site.secondary,
            }
        },
        body: {
            cursor: 'pointer'
        }
    }),
)(TableRow);

const useStyles = makeStyles((theme: any) =>
    createStyles({
        cardTitle: {
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            backgroundColor: theme.palette.site.secondary,
            padding: theme.spacing(0)
        },
        headerText: {
            float: 'left',
            padding: theme.spacing(1.5),
        },
        cardContent: {
            padding: theme.spacing(1, 3),
            height: '100%',
        },
        saveButton: {
            width: 90,
        },
        tableContainer: {
            maxHeight: '400px'
        },
        table: {
            width: '100%',
        },
        iconColor: {
            color: theme.palette.black
        },
        searchField: {
            '&::placeholder': {
                color: theme.palette.black
            }
        },
        selectionCheckBox: {
            padding: theme.spacing(0)
        },
        preLoaderCell: {
            textAlign: 'center',
            padding: theme.spacing(1)
        },
        dialogCloseButton: {
            float: "right",
        },
        courseSelectionCell: {
            color: theme.palette.paperSummarySchedulerTitle,
            cursor: 'pointer',
            fontWeight: 500
        }
    })
);
type GradeScaleDetailProps = {
    scales: IGradeScaleDetails[];
    campusId?: string;
    grdScaleId?: string;
    grdSystemId?: string;
    addGradeScale: (scale: IGradeScaleDetails) => void;
    updateGradeScale: (scale: IGradeScaleDetails, index: number) => void;
    deleteGradeScale: (index: number) => void;
};

const GradeScaleDetailGrid = (
    props: Readonly<GradeScaleDetailProps>
) => {
    const classes = useStyles({});
    const [isLoading] = React.useState<boolean>(false);
    const [grdScaleDetail, setgrdScaleDetail] = React.useState<IGradeScaleDetails[]>(props.scales);
    const [newgrdScaleDetail, setNewGrdScaleDetail] = React.useState<IGradeScaleDetails>({grdScaleDetailId:EmptyGuid,grdSysDetailId:EmptyGuid,minVal:0,maxVal:0,grdScaleId:EmptyGuid });
    const [rowToEdit, setEditIndex] = React.useState<number>();
    const data = useForm<any>({ mode: "onBlur" });
    const [tempData, setTempData] = React.useState<IGradeScaleDetails>();

    const setRowToEdit = (index?: number) => {
        setEditIndex(index);
        if (index && (index - 1) < grdScaleDetail.length) {
            setTempData({ ...(grdScaleDetail[index - 1]) });
        }
    }

    const handleNewComponentFieldChange = (fieldId: keyof IGradeScaleDetails, value: any, index: number = 0) => {
        if (index === 0) {
            let updatedModel = setNewGrdScaleDetail;
            (updatedModel as any)[fieldId] = value;
            setNewGrdScaleDetail({ ...updatedModel });
        }
        else if (grdScaleDetail.length >= index) {
            grdScaleDetail[index - 1][fieldId] = value;
            setgrdScaleDetail([...grdScaleDetail]);
        }
        data.setValue(`${fieldId}[${index}]`, value);
        data.triggerValidation(`${fieldId}[${index}]`);
    };

    const onComponentSave = async (index: number) => {
      
        //const valid = await data.triggerValidation([`gradeBookComponentTypeId[${index}]`, `weight[${index}]`, `minimumScore[${index}]`, `sequence[${index}]`]);
        const valid = await data.triggerValidation([`minVal[${index}]`,`maxVal[${index}]`, `grdSysDetailId[${index}]`]);
        if (valid) {
            if (index === 0) {
                props.addGradeScale(newgrdScaleDetail);
                setNewGrdScaleDetail({ minVal:0,maxVal:0 });
            }
            else {
                props.updateGradeScale(grdScaleDetail[index - 1], index - 1);
            }
            setRowToEdit(undefined);
            setTempData(undefined);

        }
    }

    const onComponentDelete = (index: number) => {
        if (index < grdScaleDetail.length) {
            props.deleteGradeScale(index);
        }
    }

    const onComponentCancel = () => {
        if (rowToEdit && tempData) {
            grdScaleDetail[rowToEdit - 1] = { ...tempData };
            setgrdScaleDetail([...grdScaleDetail]);
            setRowToEdit(undefined);
            setTempData(undefined);

        }
    }

    React.useEffect(() => {
        setgrdScaleDetail(props.scales);
        setNewGrdScaleDetail({});
    }, [props.scales])

    return (
      <Card>
        <CardContent>
        <FormContext {...data}>
            <form>
                <Grid container direction="column" spacing={2} wrap="wrap">
                    <Grid
                        item
                        alignContent="flex-start"
                        alignItems="flex-start"
                        justify="flex-start"
                    >
                        <TableContainer component={Paper} square className={classes.tableContainer}>
                            <Table stickyHeader size='small' className={classes.table} aria-label='customized table'>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell colSpan={1}>Mininum Value</StyledTableCell>
                                        <StyledTableCell colSpan={1}>Maximum Value</StyledTableCell>
                                        <StyledTableCell colSpan={1}>Grade System Detail</StyledTableCell>
                                       
                                        <StyledTableCell colSpan={1}></StyledTableCell>
                                        <StyledTableCell colSpan={1}></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow>
                                        
                                        <StyledTableCell colSpan={1}>
                                            <InputField
                                                disabled={!!(rowToEdit && rowToEdit > 0)}
                                                type={InputType.NUMBER}
                                                decimal
                                                id="minVal[0]"
                                                name="minVal[0]"
                                                defaultValue={newgrdScaleDetail.minVal}
                                                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                    handleNewComponentFieldChange("minVal", e.target.value);
                                                }}
                                                error={!!(data.errors.minVal && data.errors.minVal[0])}
                                                inputRef={data.register({  required: true })}
                                                helperText={
                                                    (data.errors.minVal && data.errors.minVal[0])
                                                        ? "MinValue Is required."
                                                        : undefined
                                                }
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell colSpan={1}>

                                            <InputField
                                                disabled={!!(rowToEdit && rowToEdit > 0)}
                                                type={InputType.NUMBER}
                                                decimal
                                                id="maxVal[0]"
                                                name="maxVal[0]"
                                                defaultValue={newgrdScaleDetail.maxVal}
                                                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                    handleNewComponentFieldChange("maxVal", e.target.value);
                                                }}
                                                error={!!(data.errors.maxVal && data.errors.maxVal[0])}
                                                inputRef={data.register({ required: true })}
                                                helperText={
                                                    (data.errors.maxVal && data.errors.maxVal[0])

                                                        ? "MaxValue Is required."
                                                            : undefined
                                                }
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                        <GradeSystemDetailAutoComplete
                                              disabled={!!(rowToEdit && rowToEdit > 0)}
                                              id="grdSysDetailId[0]"
                                              name="grdSysDetailId[0]"
                                              grdSystemId={props.grdSystemId}
                                              label="Grade System *"
                                              valueFilter={
                                                newgrdScaleDetail.grdSysDetailId
                                                  ? {
                                                    key: "value",
                                                    values: [newgrdScaleDetail.grdSysDetailId],
                                                  }
                                                  : undefined
                                              }
                                              filterHandle={(v: any) => {
                                                handleNewComponentFieldChange("grdSysDetailId", v ? v.value : null);
                                                handleNewComponentFieldChange("grdSysDetail", v ? v.text : null);
                                                
                                              }}
                                              error={!!data.errors.grdSysDetailId}
                                              inputRef={data.register({ required: true })}
                                              helperText={
                                                data.errors.grdSysDetailId ? "Grade System is required." : undefined
                                              }
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell colSpan={1}>
                                            <IconButton disabled={!!(rowToEdit && rowToEdit > 0)} onClick={() => onComponentSave(0)}>
                                                <SaveIcon />
                                            </IconButton>
                                        </StyledTableCell>
                                        <StyledTableCell colSpan={1}>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    {isLoading ?
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={18} className={classes.preLoaderCell}>
                                                <CircularProgress />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        : grdScaleDetail.map((grdScale, index) => (
                                            <StyledTableRow key={`row-${index}`}>
                                                
                                                <StyledTableCell colSpan={1} key={`minVal-${index + 1}`}>
                                                    <InputField
                                                        type={InputType.NUMBER}
                                                        decimal
                                                        disabled={!rowToEdit || rowToEdit !== (index + 1)}
                                                        id={`minVal[${index + 1}]`}
                                                        name={`minVal[${index + 1}]`}
                                                        key={`minValValue-${index + 1}`}
                                                        defaultValue={grdScale.minVal}
                                                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                            handleNewComponentFieldChange("minVal", e.target.value, index + 1);
                                                        }}
                                                        error={!!(data.errors.minVal && data.errors.minVal[index + 1])}
                                                        inputRef={data.register({})}
                                                        helperText={
                                                            (data.errors.minVal && data.errors.minVal[index + 1])
                                                                ? (data.errors.minVal[index + 1] as FieldError).message
                                                                : undefined
                                                        }
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell colSpan={1} key={`maxVal-${index + 1}`}>
                                                    <InputField
                                                        disabled={!rowToEdit || rowToEdit !== (index + 1)}
                                                        decimal
                                                        key={`maxVal-${index + 1}`}
                                                        type={InputType.NUMBER}
                                                        id={`maxVal[${index + 1}]`}
                                                        name={`maxVal[${index + 1}]`}
                                                        defaultValue={grdScale.maxVal}
                                                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                            handleNewComponentFieldChange("maxVal", e.target.value, index + 1);
                                                        }}
                                                        error={!!(data.errors.maxVal && data.errors.maxVal[index + 1])}
                                                        inputRef={data.register({ required: true })}
                                                        helperText={
                                                            (data.errors.maxVal && data.errors.maxVal[index + 1])
                                                                ? (data.errors.maxVal[index + 1] as FieldError).message
                                                                : undefined
                                                        }
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell colSpan={1} key={`gradeSys-${index + 1}`}>
                                                <GradeSystemDetailAutoComplete 
                                                      disabled={!rowToEdit || rowToEdit !== (index + 1)}
                                                      id={`grdSysDetailId[${index + 1}]`}
                                                      name={`grdSysDetailId[${index + 1}]`}
                                                      key={`grdSysDetailId-${index + 1}`}
                                                      grdSystemId={ props.grdSystemId }
                                                      label="Grade System *"
                                                      valueFilter={
                                                        grdScale.grdSysDetailId
                                                          ? {
                                                            key: "value",
                                                            values: [grdScale.grdSysDetailId],
                                                          }
                                                          : undefined
                                                      }
                                                      filterHandle={(v: any) => {
                                                        handleNewComponentFieldChange("grdSysDetailId", v ? v.value : null, index + 1);
                                                        handleNewComponentFieldChange("grdSysDetail", v ? v.text : null, index + 1);
                                                        // handleNewComponentFieldChange("sysTemDetail", v ? v.sysTemDetail : null, index + 1)
                                                      }}
                                                      error={!!(data.errors.grdSysDetailId && data.errors.grdSysDetailId[index + 1])}
                                                      inputRef={data.register({ required: true })}
                                                      helperText={
                                                        data.errors.grdSysDetailId ? "Grade System is required." : undefined
                                                      }
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell colSpan={1} key={`saveColumn-${index + 1}`}>
                                                    <IconButton disabled={!!(rowToEdit && rowToEdit !== (index + 1))} key={`saveButton-${index + 1}`} onClick={() => (rowToEdit && rowToEdit === (index + 1)) ? onComponentSave(index + 1) : setRowToEdit(index + 1)}>
                                                        {(rowToEdit && rowToEdit === (index + 1) &&
                                                            <SaveIcon key={`saveIcon-${index + 1}`} />)
                                                            ||
                                                            <EditIcon key={`editIcon-${index + 1}`} />
                                                        }
                                                    </IconButton>
                                                </StyledTableCell>
                                                <StyledTableCell colSpan={1} key={`deleteColumn-${index + 1}`}>
                                                    <IconButton disabled={!!(rowToEdit && rowToEdit !== (index + 1))} key={`deleteButton-${index + 1}`} onClick={() => !!(rowToEdit && rowToEdit === (index + 1)) ? onComponentCancel() : onComponentDelete(index)}>
                                                        {(rowToEdit && rowToEdit === (index + 1) &&
                                                            <ClearIcon key={`clearIcon-${index + 1}`} />)
                                                            ||
                                                            <DeleteIcon key={`deleteIcon-${index + 1}`} />
                                                        }
                                                    </IconButton>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </form>
        </FormContext>
        </CardContent>
        </Card>
    );
};

export default GradeScaleDetailGrid;
