import * as React from "react";
import { useRef } from "react";
import placementFieldOfStudy from "../../constants/Placement/fieldOfStudyAutoComplete";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { useSelector } from "react-redux";


const PlacementFieldOfStudyAutoComplete = (props: FilterParams) => {
    const inputRef: any = useRef(null);

    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );

    const OnChange = (e: any) => {
        if (props.filterHandle) {
            props.filterHandle(e);
        }
    };

    return (
        <div>
            <ApiAutoComplete
                config={placementFieldOfStudy}
                classes={{
                    option: "text-black"
                }}
                label={props.label ? props.label : "Field of Study"}
                onChange={(e: any) => {
                    OnChange(e);
                }}
                reqParams={{ campusId: userSelectedCampus }}
                multiple={props.multiple || false}
                valueFilter={props.valueFilter ? props.valueFilter : undefined}
                inputRef={props.inputRef ? props.inputRef : inputRef}
                error={props.error}
                helperText={props.helperText}
                inputName={props.name}
                maxTags={props.maxTags}
				includeAllValue={props.includeAllValue}
				showIncludeAllValue={props.showIncludeAllValue}
				chipSize={props.chipSize}
            ></ApiAutoComplete>
        </div >
    );
};
export default PlacementFieldOfStudyAutoComplete;
