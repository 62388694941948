export enum AdHocReportFilterOperation {
    equal = 0,
    greaterEqual = 1,
    lessEqual = 2,
    notEqual = 3,
    between = 4,
    any = 5,
    lessThan = 6,
    greaterThan = 7,
    notBetween = 8,
    notAny = 9,
    contains = 10,
    notContains = 11,
    startsWith = 12,
    endsWith = 13,
    notStartsWith = 14,
    notEndsWith = 15,    
}

export enum CombineFilter {
    and = 1,
    or = 2
}