import API from '../../apiWrapper';
import ICourse from '../../../interfaces/setup/academics/ProgramDefinition/ICourse';
import { IProgramCourseComponent } from '../../../interfaces/setup/academics/ProgramDefinition/IProgramCourse';
import ICourseDetails from '../../../interfaces/setup/academics/ProgramDefinition/ICourseDetails';
import ICourseGradeBook from '../../../interfaces/setup/academics/ProgramDefinition/ICourseGradeBook';
import ICourseGradeBookDetails from '../../../interfaces/setup/academics/ProgramDefinition/ICourseGradeBookDetails';
import INewComponentType from '../../../interfaces/setup/academics/ProgramDefinition/INewComponentType';
import IGradeComponentLMSMapping from '../../../interfaces/setup/academics/ProgramDefinition/IGradeComponentLMSMapping';

export const getAllCourses = (campusId: string): Promise<ICourse[]> => {
	return API()
		.get('/AcademicRecords/Courses/GetCoursesListByCampus', {
			params: {
				campusId
			}
		})
		.then((res: any) => {
			if (res && res.data) {
				return res.data;
			}
			return [];
		}, (_error: any) => {
			return [];
		});
};

export const UpsertCourse = (courseDetails: ICourseDetails): Promise<{ courseDetails: ICourseDetails, message: string }> => {
	return API()
		.post('/AcademicRecords/Courses/UpsertCourse', courseDetails, {
		})
		.then((res: any) => {
			if (res && res.data && res.data.result) {
				return {
					courseDetails: res.data.result,
					message: res.data.resultStatusMessage
				};
			}
			throw (new Error('Unknown error'));
		}, (error: any) => {
			if (error.response) {
				throw new Error(error.response.data); // => the response payload 
			}
			throw (new Error('Unknown error'));
		});
};

export const GetCourseDetails = (courseId: string): Promise<ICourseDetails> => {
	return API()
		.get('/AcademicRecords/Courses/GetCourseDetails', {
			params: {
				courseId
			}
		})
		.then((res: any) => {
			if (res && res.data && res.data.result) {
				return res.data.result;
			}
			throw (new Error('Unknown error'));
		}, (error: any) => {
			if (error.response) {
				throw new Error(error.response.data); // => the response payload 
			}
			throw (new Error('Unknown error'));
		});
};

export const CopyCourse = (courseId: string): Promise<{ details: ICourseDetails, message: string }> => {
	return API()
		.get('/AcademicRecords/Courses/CopyCourse', {
			params: {
				courseId
			}
		})
		.then((res: any) => {
			if (res && res.data && res.data.result) {
				return {
					details: res.data.result,
					message: res.data.resultStatusMessage
				};
			}
			throw (new Error('Unknown error'));
		}, (error: any) => {
			if (error.response) {
				throw new Error(error.response.data); // => the response payload 
			}
			throw (new Error('Unknown error'));
		});
};

export const getCourseComponents = (courseId: string): Promise<IProgramCourseComponent[]> => {
	return API()
		.get('/AcademicRecords/ProgramVersions/GetCourseComponents', {
			params: {
				courseId
			}
		})
		.then((res: any) => {
			if (res && res.data) {
				return res.data;
			}
			return [];
		}, (_error: any) => {
			return [];
		});
};

export const getCourseGradeBooks = (courseId: string): Promise<ICourseGradeBook[]> => {
	return API()
		.get('/AcademicRecords/Courses/GetCourseGradeBooks', {
			params: {
				courseId
			}
		})
		.then((res: any) => {
			if (res && res.data) {
				return res.data;
			}
			return [];
		}, (error: any) => {
			if (error.response) {
				throw new Error(error.response.data); // => the response payload 
			}
			throw (new Error('Unknown error'));
		});
};

export const getCourseGradeBookDetails = (gradeBookId: string): Promise<ICourseGradeBookDetails> => {
	return API()
		.get('/AcademicRecords/Courses/GetCourseGradeBookDetails', {
			params: {
				gradeBookId
			}
		})
		.then((res: any) => {
			if (res && res.data && res.data.result) {
				return res.data.result;
			}
			throw (new Error('Unknown error'));
		}, (error: any) => {
			if (error.response) {
				throw new Error(error.response.data); // => the response payload 
			}
			throw (new Error('Unknown error'));
		});
};

export const getGradeBookComponentsLMSMappings = async (gradeBookId: string): Promise<Array<IGradeComponentLMSMapping>> => {
	const result = await API()
		.get<Array<IGradeComponentLMSMapping>>('/AcademicRecords/Courses/GetComponentsLMSMappings', {
			params: {
				gradeBookId
			}
		});
	return result.data;
};

export const updateGradeBookComponentsLMSMappings = async (mappings: Array<IGradeComponentLMSMapping>): Promise<Array<IGradeComponentLMSMapping>> => {
	const result = await API()
		.post<Array<IGradeComponentLMSMapping>>('/AcademicRecords/Courses/SaveLMSMappings', mappings);
	return result.data;
};

export const CopyCourseGradeBook = (gradeBookId: string, courseId: string): Promise<{ details: ICourseGradeBookDetails, message: string }> => {
	return API()
		.get('/AcademicRecords/Courses/CopyCourseGradeBooks', {
			params: {
				gradeBookId,
				courseId
			}
		})
		.then((res: any) => {
			if (res && res.data && res.data.result) {
				return {
					details: res.data.result,
					message: res.data.resultStatusMessage
				};
			}
			throw (new Error('Unknown error'));
		}, (error: any) => {
			if (error.response) {
				throw new Error(error.response.data); // => the response payload 
			}
			throw (new Error('Unknown error'));
		});
};

export const upsertCourseGradeBook = (courseDetails: ICourseGradeBookDetails, courseId: string): Promise<{ details: ICourseGradeBookDetails, message: string }> => {
	return API()
		.post('/AcademicRecords/Courses/UpsertCourseGradeBook', courseDetails, {
			params: {
				courseId
			}
		})
		.then((res: any) => {
			if (res && res.data && res.data.result) {
				return {
					details: res.data.result,
					message: res.data.resultStatusMessage
				};
			}
			throw (new Error('Unknown error'));
		}, (error: any) => {
			if (error.response) {
				throw new Error(error.response.data); // => the response payload 
			}
			throw (new Error('Unknown error'));
		});
};

export const createNewComponent = (componentType: INewComponentType): Promise<{ details: INewComponentType, message: string }> => {
	return API()
		.post('/AcademicRecords/GradeBookComponentTypes/CreateComponent', componentType, {
		})
		.then((res: any) => {
			if (res && res.data && res.data.result) {
				return {
					details: res.data.result,
					message: res.data.resultStatusMessage
				};
			}
			throw (new Error('Unknown error'));
		}, (error: any) => {
			if (error.response) {
				throw new Error(error.response.data); // => the response payload 
			}
			throw (new Error('Unknown error'));
		});
};

const getByteArray = (file: any): Promise<any[] | undefined> => {
	return new Promise(function (resolve, reject) {
		let fileReader = new FileReader();

		fileReader.readAsArrayBuffer(file);
		fileReader.onload = function (ev) {
			if (ev.target && ev.target.result) {
				const array = new Uint8Array(ev.target.result as ArrayBuffer);
				const fileByteArray = [];
				for (let i = 0; i < array.length; i++) {
					fileByteArray.push(array[i]);
				}
				resolve(fileByteArray); // successful
			} else resolve(undefined);
		};
		fileReader.onerror = reject; // call reject if error
	});
};

export const ImportComponents = async (campusId: string, data: File | null) : Promise<{ errorList: Array<{values: string; error: string}>, message: string }> => {
	const buffer = await getByteArray(data);
	return API()
		.post('/AcademicRecords/GradeBookComponentTypes/ImportComponents', {
			data: buffer,
			fileType: data?.type,
			fileSize: data?.size
		}, {
			params: {
				campusId
			}
		})
		.then((res: any) => {
			if (res && res.data && res.data) {
				return {
					errorList: res.data.result,
					message: res.data.resultStatusMessage
				};
			}
			throw (new Error('Unknown error'));
		}, (error: any) => {
			if (error.response) {
				throw new Error(error.response); // => the response payload 
			}
			throw (new Error('Unknown error'));
		});
};