import * as React from "react";
import { useEffect, useRef } from "react";
import { TenantSelectorApiAutoComplete } from "../../constants/Security/TenantSelectorApiAutoComplete";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../constants/actions.constants";
import { FilterParams } from "../../interfaces/common/FilterParams";

interface TenantSelectorAutoCompleteProps extends FilterParams {
  options: any;
}

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& label.Mui-focused": {
        color: theme.palette.primary.white,
      },
    },
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {
      width: "95%",
    },
  })
);

const TenantSelectorAutoComplete = (props: TenantSelectorAutoCompleteProps) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const [TenantSelected, setTenantSelected] = React.useState(false);

  const inputRef: any = useRef(null);

  const filterHandle = props.filterHandle;

  const magnifyOnClick = (e: any) => {
    if (inputRef.current !== undefined) {
      inputRef.current.children[1].focus();
    }
  };

  const tenantOnChange = (e: any) => {
    if (filterHandle) filterHandle(e);

    setTenantSelected(e);
  };

  return (
    <div className={clsx(classes.divWidth)}>
      <ApiAutoComplete
        config={TenantSelectorApiAutoComplete.config}
        loading={false}
        reqParams={{}}
        classes={{
          option: "text-black",
        }}
        label="Tenant"
        onChange={(e: any) => {
          tenantOnChange(e);
        }}
        multiple={false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        inputRef={props.inputRef ? props.inputRef : inputRef}
        error={props.error}
        helperText={props.helperText}
        inputName={props.name}
        options={props.options}
      ></ApiAutoComplete>
    </div>
  );
};
export default TenantSelectorAutoComplete;
