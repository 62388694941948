import * as React from "react";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import MaskedInput from "react-text-mask";
import * as studentApi from "../../../../api/search/studentSearchApi";
import { useSelector } from "react-redux";
import {
  Typography,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  IStudentInfo,
  IStudentInfoGeneral,
} from "../../../../interfaces/student/profile/studentInfo/IStudentInfo";
import SuffixAutoComplete from "../../../../../src/components/AutoComplete/SuffixAutoComplete";
import PrefixAutoComplete from "../../../../../src/components/AutoComplete/PrefixAutoComplete";
import { useFormContext, Controller, FieldError } from "react-hook-form";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes, { any } from "prop-types";
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";
import register from "../../../../registerServiceWorker";
import SsnMaskInput from "../../../../../src/components/_Layout/Inputs/SsnMaskInput";
import CustomCardTitle from "../../../../interfaces/common/card/CustomCardTitle";
import Zoom from "@material-ui/core/Zoom";
import { TransitionProps } from "@material-ui/core/transitions";
import { useHistory } from "react-router-dom";
import LeadsList from "../../newEnrollment/LeadsList";
import { LeadSearchInputPaged } from "../../../../interfaces/admissions/LeadSearchInputPaged";
import { ILeadMatch } from "../../../../interfaces/admissions/ILeadMatch";
import { ILead } from "../../../../interfaces/admissions/ILead";
import red from "@material-ui/core/colors/red";
import { security } from "../../../../constants/Security/securityConstants";
import { checkPermissions } from "../../../../utils/securityFunctions";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityIcon from "@material-ui/icons/VisibilityOutlined";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    drawer: {
      width: 280,
    },
    root: {
      backgroundColor: theme.palette.paper.background,
      "& .MuiCardContent-root": {
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(2),
      },
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.paper.background,
    },
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
    switch: {
      marginLeft: "0px",
      paddingTop: theme.spacing(2),
    },
    visibilityIcon: {
      position: "absolute",
    },
    maskParent:{
      display: "flex"
    },
    dobMaskWidth: {
      flexGrow: 1,
    },
    dobMaskedTextField: {
     flexGrow: 1,
    },
    iconWidth: {
      width: "50px",
      float: "right",
      padding: theme.spacing(0),
      bottom: theme.spacing(-1),
    },
  })
);
type SnackBarStateProps = {
  showSnackBar: boolean;
  messageInfo: string | undefined;
  variant: "success" | "error" | "warning" | "info";
};

const ThemeTextField = withStyles((theme: any) => createStyles({}))(TextField);

type GeneralProps = {
  model: IStudentInfoGeneral;
  setModel: any;
  showOffEyeIcon: boolean;
  setshowOffEyeIcon: any;
  isNewEnrollment: boolean;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});
const General = (props: GeneralProps) => {
  const classes = useStyles({});
  const { model, setModel } = props;
  const data = useFormContext();
  const { showOffEyeIcon, setshowOffEyeIcon } = props;
  const [newSsn, setnewSsn] = React.useState<boolean>(false);
  const [isErr, setisErr] = React.useState<boolean>(false);
  const [updatedSsn, setupdatedSsn] = React.useState<string>("");
  const [selectedLeadMatch, setSelectedLeadMatch] = React.useState<
    ILeadMatch | undefined
  >(undefined);
  const [selectedLead, setSelectedLead] = React.useState<ILead | undefined>(
    undefined
  );
  const [open, setOpen] = React.useState(false);
  const [openDatePicker, setOpenDatePicker] = React.useState(false);
  const [multipleLeadMatches, setMultipleLeadMatches] = React.useState(false);
  const [viewDOBPermission, setViewDOBPermission] = React.useState(
    checkPermissions([security.permissions.student.viewDateOfBirth])
  );
  const [updateDOBPermission, setUpdateDOBPermission] = React.useState(
    checkPermissions([security.permissions.student.updateDateOfBirth])
  );
  const [showDOB, setShowDOB] = React.useState(false);
  const history = useHistory();
  const userSelectedCampus: string = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );
  const selectedEnrollment = useSelector((state: any) =>
    state.student ? state.student.selectedEnrollment : null
  );

  React.useEffect(() => {
    if(props.model.leadId != null)
    {
      studentApi
        .GetDateOfBirthByLead(props.model.leadId)
        .then((response: any) => {
          if (response && response.resultStatus === 0)
          {
            let updatedModel = model;
            updatedModel.dateOfBirth = response.result.dateOfBirth;
            setModel({ ...updatedModel });
            data.setValue("dateOfBirth", response.result.dateOfBirth);
            data.triggerValidation("dateOfBirth");
          }
        });
    }    
  }, [props.model.leadId]);

  React.useEffect(() => {
    setShowDOB(false);
  }, [selectedEnrollment]);

  React.useEffect(() => {
    if (props.isNewEnrollment && viewDOBPermission) {
      setShowDOB(true);
    } else {
      setShowDOB(false);
    }
  }, [props.isNewEnrollment]);

  const handelIconClick = (e: any) => {
    if (viewDOBPermission && !props.isNewEnrollment) {
      let activeLeadId = "";
      if (props.model.leadId === null) {
        activeLeadId = selectedStudent.leadId;
      } else {
        activeLeadId = props.model.leadId + "";
      }
      studentApi
        .GetDateOfBirthByLead(activeLeadId)
        .then((response: any) => {
          if (response && response.resultStatus === 0)
          {
            let updatedModel = model;
            updatedModel.dateOfBirth = response.result.dateOfBirth;
            setModel({ ...updatedModel });
            setShowDOB(!showDOB);
          }
          else
          {
            setShowDOB(!showDOB);
          }
        });
    }
    if (props.isNewEnrollment) {
      setShowDOB(!showDOB);
    }
    e.stopPropagation();
  };

  const handleNo = () => {
    setSelectedLeadMatch(undefined);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectedLeadFromMultiple = (lead: ILead | undefined) => {
    setSelectedLead(lead);
  };

  const isSupportUser = useSelector(
    (state: any) => state.session.user.isSupportUser
  );

  const handleYes = () => {
    if (selectedLeadMatch) {
      history.push(
        "/Student/NewEnrollment?isNew=false&campusId=" +
          userSelectedCampus +
          "&leadId=" +
          selectedLeadMatch.leadId
      );
    } else {
      if (selectedLead) {
        history.push(
          "/Student/NewEnrollment?isNew=false&campusId=" +
            userSelectedCampus +
            "&leadId=" +
            selectedLead.leadId
        );
      }
    }
    setOpen(false);
    setSelectedLead(undefined);
    setSelectedLeadMatch(undefined);
  };
  const [snackBarProps, setSnackBarProps] = React.useState<SnackBarStateProps>({
    showSnackBar: false,
    messageInfo: undefined,
    variant: "info",
  });
  const handleFieldOnChange = (fieldId: string, value: any) => {
    let updatedModel = model;
    (updatedModel as any)[fieldId] = value;
    setModel({ ...updatedModel });
    if (fieldId === "isInternationalStudent") {
      if (model.maskedSSN || model.ssn) {
        handleIsInternationalStudent(
          showOffEyeIcon ? model.maskedSSN : model.ssn
        );
      }
    }
  };

  const handleIsInternationalStudent = (value: string) => {
    let checkSsn = value ? value.replace(/-/g, "") : "";
    if (
      (checkSsn.trim().length === 0 || checkSsn.trim() === "*****") &&
      (model.isInternationalStudent === false ||
        model.isInternationalStudent === undefined ||
        model.isInternationalStudent === null)
    ) {
      data.setError(
        "ssn",
        "ssn",
        "SSN Required for non-International Students"
      );
      setisErr(true);
    } else if (checkSsn.trim().length !== 9 && checkSsn.trim().length > 0) {
      data.setError("ssn", "ssn", "SSN is not in correct format");
      setisErr(true);
    } else {
      data.clearError("ssn");
      setisErr(false);
    }
  };

  const onValidation = (fieldId: string, value: any) => {
    if (data.errors && (model as any)[fieldId] != value) {
      handleFieldOnChange(fieldId, value);
    }
  };

  const handleSsnOnChange = (value: any) => {
    if (!checkPermissions([security.permissions.student.updateSSN])) {
      return false;
    }

    setnewSsn(true);
    setupdatedSsn(value.target.value);
    let updatedModel = model;
    updatedModel.ssn = value.target.value;
    if (value.target.value) {
      let checkSsn = value.target.value.replace("-", "").replace("-", "");
      if (checkSsn.trim().length > 0) {
        updatedModel.maskedSSN = "***-**-****";
      } else {
        updatedModel.maskedSSN = "";
      }
    }
    setModel({ ...updatedModel });

    if (value.target.value) {
      setisErr(false);
      let checkSsn = value.target.value.replace("-", "").replace("-", "");
      if (checkSsn.trim().length === 9 || checkSsn.trim().length === 0) {
        data.clearError("ssn");
        setisErr(false);

        if (checkSsn.trim().length === 9) {
          if (props.isNewEnrollment) {
            studentApi
              .SearchForExistingStudent(userSelectedCampus, checkSsn)
              .then((response: any) => {
                if (response.result && response.result.length > 0) {
                  var leadMatches = response.result as Array<ILeadMatch>;
                  if (leadMatches.length > 0) {
                    setOpen(true);
                  }
                }
              });
          }
        }
        handleIsInternationalStudent(checkSsn);
      } else {
        data.setError("ssn", "ssn", "SSN is not in correct format");
        setisErr(true);
      }
      data.setValue("ssn", value.target.value);
    }
  };
  React.useEffect(() => {
    if (model.maskedSSN || model.ssn) {
      handleIsInternationalStudent(
        showOffEyeIcon ? model.maskedSSN : model.ssn
      );
    }
  }, [model]);

  React.useEffect(() => {
    setupdatedSsn("");
  }, [model.leadId]);

  const handleViewPlainClick = (e: any) => {
    setshowOffEyeIcon(!showOffEyeIcon);
    let updatedModel = model;
    if (updatedModel.ssn && updatedModel.ssn.length == 11) {
      updatedModel.maskedSSN = "***-**-****";
      setModel(updatedModel);
      props.setModel({ ...updatedModel });
    }
  };
  const selectedStudent = useSelector((state: any) => state.student);

  React.useEffect(() => {
    if (selectedStudent && selectedStudent.studentId) {
      setshowOffEyeIcon(props.isNewEnrollment ? false : true);
      setnewSsn(props.isNewEnrollment ? true : false);
      setupdatedSsn("");
      if (model.maskedSSN || model.ssn) {
        handleIsInternationalStudent(
          showOffEyeIcon ? model.maskedSSN : model.ssn
        );
      }
    }
  }, [selectedStudent]);

  const handleViewMaskClick = (e: any) => {
    let noSSN = true;
    if (props.model.maskedSSN != null) {
      if (props.model.maskedSSN.length > 9) {
        setnewSsn(false);
        noSSN = false;
      }
    }
    if (noSSN) {
      setshowOffEyeIcon(!showOffEyeIcon);
    }
    if (selectedStudent && selectedStudent.leadId && !noSSN) {
      let activeLeadId = "";
      if (props.model.leadId === null) {
        activeLeadId = selectedStudent.leadId;
      } else {
        activeLeadId = props.model.leadId + "";
      }

      studentApi
        .GetSsnLast4(activeLeadId, model.maskedSSN)
        .then((response: any) => {
          if (!response) {
            return;
          } else {
            if (
              response.resultStatusMessage === "Has permission and SSN found."
            ) {
              setshowOffEyeIcon(!showOffEyeIcon);
              let updatedModel = model;
              updatedModel.ssn = response.result;
              updatedModel.maskedSSN = "***-**-****";
              setModel(updatedModel);
              props.setModel({ ...updatedModel });
            } else if (
              response.resultStatusMessage ===
              "Has permission and no SSN found."
            ) {
              setshowOffEyeIcon(!showOffEyeIcon);
            } else if (
              response.resultStatusMessage === "Does not have permission."
            ) {
              setSnackBarProps((props: any) => {
                return {
                  variant: "warning",
                  showSnackBar: true,
                  messageInfo: "User does not have permission to view SSN.",
                };
              });
            }
            if (model.maskedSSN || model.ssn) {
              handleIsInternationalStudent(
                showOffEyeIcon ? model.maskedSSN : model.ssn
              );
            }
          }
        });
    } else {
      if (updatedSsn != "") {
        let updatedModel = model;
        updatedModel.ssn = updatedSsn;
        updatedModel.maskedSSN = "***-**-****";
        setModel(updatedModel);
        props.setModel(updatedModel);
        if (model.maskedSSN || model.ssn) {
          handleIsInternationalStudent(
            showOffEyeIcon ? model.maskedSSN : model.ssn
          );
        }
      }
    }
  };
  return (
    <div>
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Use Existing Lead/Student?"}
          </DialogTitle>
          <DialogContent>
            <LeadsList
              headerContent="Lead(s) and/or student(s) found for the specified SSN. Select one if you would like to use one of these students instead."
              setSelectedLead={handleSelectedLeadFromMultiple}
              params={
                {
                  ssn: updatedSsn.replace("-", "").trim(),
                } as LeadSearchInputPaged
              }
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleNo}
              variant="contained"
              style={{ backgroundColor: red[500], color: "white" }}
            >
              Continue as duplicate
            </Button>
            <Button
              onClick={handleYes}
              disabled={!!!selectedLeadMatch && !!!selectedLead}
              variant="contained"
              color="primary"
            >
              Use Student
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <CustomSnackbar
        variant={snackBarProps.variant}
        message={snackBarProps.messageInfo}
        open={snackBarProps.showSnackBar}
        onClose={(event?: React.SyntheticEvent, reason?: string) => {
          setSnackBarProps((props: any) => {
            return { ...props, showSnackBar: false };
          });
        }}
      ></CustomSnackbar>
      <Card square={true}>
        <CustomCardTitle title={"General"} />
        <CardContent className={classes.cardContent}>
          <Grid container direction="row" spacing={2}>
            <Grid item md={4} sm={6} xs={12}>
              <ThemeTextField
                id="firstName"
                name="firstName"
                className={classes.textField}
                label="First Name *"
                value={model.firstName}
                error={!!data.errors.firstName}
                inputRef={data.register({
                  validate: {
                    valid: (value) => {
                      onValidation("firstName", value);
                      return value !== "" || "Required";
                    },
                  },
                })}
                helperText={
                  data.errors.firstName ? "First name is required" : null
                }
                onChange={(e: any) => {
                  handleFieldOnChange("firstName", e.target.value);
                }}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <ThemeTextField
                id="MiddleName"
                name="middleName"
                className={classes.textField}
                label="Middle Name"
                value={model.middleName || ""}
                inputRef={data.register()}
                onChange={(e: any) => {
                  handleFieldOnChange("middleName", e.target.value);
                }}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <ThemeTextField
                id="lastName"
                name="lastName"
                className={classes.textField}
                inputRef={data.register({
                  validate: {
                    valid: (value) => {
                      onValidation("lastName", value);
                      return value !== "" || "Required";
                    },
                  },
                })}
                label="Last Name *"
                error={!!data.errors.lastName}
                value={model.lastName}
                helperText={
                  data.errors.lastName ? "Last name is required." : undefined
                }
                onChange={(e: any) => {
                  handleFieldOnChange("lastName", e.target.value);
                }}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <SsnMaskInput
                name="ssn"
                id="ssn"
                className={classes.textField}
                label={model.isInternationalStudent ? "SSN" : "SSN *"}
                error={!!data.errors.ssn}
                helperText={
                  data.errors.ssn
                    ? (data.errors.ssn as FieldError).message
                    : undefined
                }
                value={showOffEyeIcon ? model.maskedSSN : model.ssn}
                key="ssn"
                inputRef={data.register({
                  required: true,
                })}
                // inputRef={data.register({
                //     validate: {
                //         requiredForNonInternational: (value) => {
                //             return (model.isInternationalStudent === false && ((model.maskedSSN !== "" &&
                //             model.maskedSSN !== undefined) ||(model.ssn !== "" &&
                //             model.ssn !== undefined) )) || "SSN Required for Non-International Students"
                //         },
                //     },
                // })}
                disabled={
                  !checkPermissions([security.permissions.student.viewSSN])
                }
                showOffEyeIcon={showOffEyeIcon}
                control={data.control}
                handleViewPlainClick={handleViewPlainClick}
                handleViewMaskClick={handleViewMaskClick}
                onChange={handleSsnOnChange}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <PrefixAutoComplete
                id="prefixId"
                filterHandle={(v: any) => {
                  handleFieldOnChange("prefixId", v ? v.value : null);
                }}
                name="prefixId"
                valueFilter={
                  model.prefixId
                    ? {
                        key: "value",
                        values: [model.prefixId],
                      }
                    : undefined
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <SuffixAutoComplete
                id="suffixId"
                filterHandle={(v: any) => {
                  handleFieldOnChange("suffixId", v ? v.value : null);
                }}
                name="SuffixId"
                valueFilter={
                  model.suffixId
                    ? {
                        key: "value",
                        values: [model.suffixId],
                      }
                    : undefined
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              {viewDOBPermission == true && showDOB ? (
                <div className={classes.maskParent}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disabled={updateDOBPermission == true ? false : true}
                      autoOk={true}
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      id="dateOfBirth"
                      label="DOB *"
                      className={classes.dobMaskWidth}
                      InputProps={{
                        readOnly: updateDOBPermission == true ? false : true,
                      }}
                      TextFieldComponent={ThemeTextField}
                      innerRef={(ref: any) => {
                        data.register("dateOfBirth", {
                          required: "Date of birth is required",
                        });
                        data.setValue("dateOfBirth", model.dateOfBirth);
                      }}
                      error={!!data.errors.dateOfBirth}
                      helperText={
                        data.errors.dateOfBirth
                          ? (data.errors.dateOfBirth as FieldError).message
                          : ""
                      }
                      fullWidth
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      value={
                        model.dateOfBirth
                          ? new Date(model.dateOfBirth).toLocaleDateString()
                          : null
                      }
                      onChange={(val: any) => {
                        data.setValue(
                          "dateOfBirth",
                          val ? new Date(val) : null
                        );
                        handleFieldOnChange("dateOfBirth", val);
                        data.triggerValidation("dateOfBirth");
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <IconButton
                    color="primary"
                    className={classes.iconWidth}
                    type="button"
                    onClick={(e: any) =>
                      viewDOBPermission && handelIconClick(e)
                    }
                  >
                    {showDOB ? (
                      <VisibilityOffIcon
                        onClick={(e: any) => handelIconClick(e)}
                      />
                    ) : (
                      <VisibilityIcon
                        onClick={(e: any) => handelIconClick(e)}
                      />
                    )}
                  </IconButton>
                </div>
              ) : (
                <div className={classes.maskParent}>
                  <ThemeTextField
                    id="dobMasked"
                    width="100%"
                    className={classes.dobMaskedTextField}
                    label="DOB *"
                    value={"**_**_****"}
                    disable={true}
                    error={!!data.errors.dateOfBirth}
                    helperText={
                      data.errors.dateOfBirth
                        ? (data.errors.dateOfBirth as FieldError).message
                        : ""
                    }
                  />
                  <IconButton
                    color="primary"
                    type="button"
                    className={classes.iconWidth}
                    onClick={(e: any) =>
                      viewDOBPermission && handelIconClick(e)
                    }
                  >
                    {showDOB ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </div>
              )}
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <ThemeTextField
                id="stateBoardId"
                className={classes.textField}
                label="State Board ID"
                value={model.stateBoardId ?? ""}
                onChange={(e: any) => {
                  handleFieldOnChange("stateBoardId", e.target.value);
                }}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="stateBoardRegDate"
                  label="State Board Reg Date"
                  TextFieldComponent={ThemeTextField}
                  fullWidth
                  autoOk={true}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  value={
                    model.stateBoardRegDate ? model.stateBoardRegDate : null
                  }
                  onChange={(val) => {
                    handleFieldOnChange("stateBoardRegDate", val);
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            {isSupportUser && (
              <Grid item md={4} sm={6} xs={12}>
                <ThemeTextField
                  id="afaStudentId"
                  className={classes.textField}
                  label="AFA ID"
                  type="number"
                  value={model.afaStudentId ?? ""}
                  onChange={(e: any) => {
                    handleFieldOnChange("afaStudentId", e.target.value);
                  }}
                />
              </Grid>
            )}
            <Grid item md={2} sm={3} xs={12}>
              <FormControlLabel
                id="excludeFrom1098"
                className={classes.switch}
                labelPlacement="start"
                label="Exclude from 1098-T"
                control={
                  <Switch
                    checked={model.excludeFrom1098}
                    value={model.excludeFrom1098}
                    onChange={(e: any) => {
                      handleFieldOnChange("excludeFrom1098", e.target.checked);
                    }}
                    color="primary"
                  />
                }
              />
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              <FormControlLabel
                id="isInternationalStudent"
                className={classes.switch}
                labelPlacement="start"
                label="International Student Without SSN"
                control={
                  <Switch
                    checked={model.isInternationalStudent}
                    value={model.isInternationalStudent}
                    onChange={(e: any) => {
                      handleFieldOnChange(
                        "isInternationalStudent",
                        e.target.checked
                      );
                    }}
                    color="primary"
                  />
                }
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default General;
