import * as React from 'react';
import {
    makeStyles,
    createStyles,
    Grid,
    withStyles,
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
    TextField,
    Table,
    TableCell,
    TableRow,
    TableContainer,
    TableHead,
    TableBody,
    CircularProgress,
    Paper,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import * as quickInfoApi from "../../../api/student/quickInfo/quickInfoApi";
import { IAttendanceSummary } from "../../../interfaces/student/quickInfo/IAttendanceSummary";
import { StudentProfile } from "../../../interfaces/student/profile/StudentProfile";
import { FormContext, useForm } from "react-hook-form";
import ProgressSaveButton from "../Buttons/ProgressSaveButton";
import { useState } from "react";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const ThemeTextField = withStyles((theme: any) =>
    createStyles({
        root: {},
    })
)(TextField);
const StyledTableCell = withStyles((theme: any) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.site.secondary,
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            textAlign: 'center',
            boxShadow: '1px 1px 0px 0px rgba(0,0,0,0.2)',
            '&:first-child': {
                width: '42px',
                padding: theme.spacing(0, 1)
            }
        },
        body: {
            fontSize: 16,
            color: theme.palette.black,
            fontWeight: 500,
            cursor: 'pointer',
            textAlign: 'left',
            '&:first-child': {
                width: '42px',
                padding: theme.spacing(0, 1)
            }
        },
    }),
)(TableCell);

const useStyles = makeStyles((theme: any) =>
    createStyles({
        cardTitle: {
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            backgroundColor: theme.palette.site.secondary,
            padding: theme.spacing(0)
        },
        headerText: {
            float: 'left',
            padding: theme.spacing(1.5),
        },
        dialogCloseButton: {
            float: "right",
        },
        textField: {
            marginRight: theme.spacing(1),
            width: "100%",
        },
        tableContainer: {
            height: '400px',
            width: "100%",
        },
        tableEmptyRow: {
            height: '350px',
            textAlign: 'center',
            fontSize: 20,
            opacity: 0.3,
        },
        table: {
            width: '100%',
        },
        preLoaderCell: {
            textAlign: 'center',
            padding: theme.spacing(1)
        },
        saveButton: {
            width: 130,
        }
    })
);
type AttendanceSummaryPopUpProps = {
    open?: boolean;
    enrollmentId: string;
    module: number | null;
    isLoading: boolean;
    setLoading: (loading: boolean) => void;
    handleClose: () => void;
    setSnackBar: any;
};

const AttendanceSummaryPopup = (
    props: Readonly<AttendanceSummaryPopUpProps>
) => {
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const { open, handleClose, isLoading } = props;
    const classes = useStyles({});
    const data = useForm<any>({ mode: "onBlur" });
    const { handleSubmit } = data;
    var startD = new Date();
    startD.setMonth(startD.getMonth() - 5);

    const [startDate, setStartDateState] = useState<Date>(startD);
    const [endDate, setEndDateState] = useState<Date>(new Date());
    const [attendanceSummary, setAttendanceSummary] = React.useState<Array<IAttendanceSummary>>([]);
    const [downloading, setDownloading] = React.useState<boolean>(false);
    const selectedStudent: StudentProfile = useSelector(
        (state: any) => state.student
    ) as StudentProfile;
    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );

    const loadAttendenceSummaryData = (enrollmentId: string, start: Date, end: Date) => {
        quickInfoApi.GetAttendanceSummaryByEnrollment(enrollmentId, start, end).then(
            (response: any) => {
                if (response && response.data) {
                    setAttendanceSummary(response.data?.result);
                }
            },
            (exception: any) => { }
        );
    }

    React.useEffect(() => {
        if (props.enrollmentId != null && props.open == true) {
            loadAttendenceSummaryData(props.enrollmentId, startDate, endDate);
        }
    }, [props.open]);

    const setStartDateChange = (value: Date | null) => {
        if (value != null && value <= endDate) {
            setStartDateState(value);
            loadAttendenceSummaryData(props.enrollmentId, value, endDate);
        }
    }
    const setEndDateChange = (value: Date | null) => {
        if (value != null && value >= startDate) {
            setEndDateState(value);
            loadAttendenceSummaryData(props.enrollmentId, value, endDate);
        }
    }

    const downloadSummary = () => {
        setDownloading(true);
        quickInfoApi.PrintAttendanceSummary(props.enrollmentId, startDate, endDate, userSelectedCampus).then(
            (response: any) => {
                setDownloading(false);
            },
            (exception: any) => {
                setDownloading(false);
            }
        );
    }

    const beforeClose = () => {
        setAttendanceSummary([]);
        handleClose();
    }

    return (
        <React.Fragment>
            <FormContext {...data}>
                <form>
                    <Dialog
                        PaperProps={{ square: true }}
                        fullWidth={true}
                        maxWidth="sm"
                        open={open ?? false}
                        onClose={beforeClose}
                        aria-labelledby="summary-add-dialog-title"
                    >
                        <DialogTitle
                            className={classes.cardTitle}
                            disableTypography
                            id="summary-add-dialog-title"
                        >
                            <Typography className={classes.cardTitle}>
                                <div className={classes.headerText}>Attendance Summary</div>
                                <IconButton
                                    aria-label="close"
                                    onClick={beforeClose}
                                    className={classes.dialogCloseButton}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            {open &&
                                <Grid container direction="column" spacing={2} wrap="nowrap">
                                    <Grid
                                        item
                                        alignContent="flex-start"
                                        alignItems="flex-start"
                                        justify="flex-start"
                                    >
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={6}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        disableToolbar
                                                        variant="inline"
                                                        format="MM/dd/yyyy"
                                                        id="date-picker-inline"
                                                        label="Start Date"
                                                        autoOk={true}
                                                        value={startDate}
                                                        onChange={(value) => setStartDateChange(value)}
                                                        KeyboardButtonProps={{
                                                            "aria-label": "change date",
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        disableToolbar
                                                        variant="inline"
                                                        format="MM/dd/yyyy"
                                                        id="date-picker-inline"
                                                        label="End Date"
                                                        autoOk={true}
                                                        value={endDate}
                                                        onChange={(value) => setEndDateChange(value)}
                                                        KeyboardButtonProps={{
                                                            "aria-label": "change date",
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" spacing={3}>
                                            <Grid
                                                item xs={12}
                                                alignContent="flex-start"
                                                alignItems="flex-start"
                                                justify="flex-start"
                                            >
                                                <TableContainer component={Paper} square className={classes.tableContainer}>
                                                    <Table stickyHeader size='small' className={classes.table} aria-label='customized table'>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Actual Hours</TableCell>
                                                                <TableCell>Day</TableCell>
                                                                <TableCell>Date</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>


                                                            {isLoading ?
                                                                <TableRow>
                                                                    <StyledTableCell colSpan={2} className={classes.preLoaderCell}>
                                                                        <CircularProgress />
                                                                    </StyledTableCell>
                                                                </TableRow>
                                                                : attendanceSummary?.map((row: IAttendanceSummary, index: any) => (
                                                                    <TableRow>
                                                                        <TableCell>{row.actualHours}</TableCell>
                                                                        <TableCell>{weekday[new Date(row.recordDate).getDay()]}</TableCell>
                                                                        <TableCell>{new Date(
                                                                            row.recordDate
                                                                        ).toLocaleDateString()}</TableCell>

                                                                    </TableRow>
                                                                ))}

                                                            {
                                                                attendanceSummary?.length == 0 ?
                                                                    <TableRow>
                                                                        <TableCell className={classes.tableEmptyRow} colSpan={8}>No Data</TableCell>
                                                                    </TableRow>
                                                                    : null
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" spacing={2}>
                                            <Grid
                                                item
                                                container
                                                xs={2}
                                                alignContent="flex-start"
                                                alignItems="flex-start"
                                                justify="flex-start"
                                            >
                                                <ProgressSaveButton
                                                    text="Print"
                                                    onClick={() => {
                                                        downloadSummary();
                                                    }}
                                                    loading={downloading}
                                                    buttonClassName={classes.saveButton}
                                                ></ProgressSaveButton>

                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                xs={2}
                                                alignContent="flex-start"
                                                alignItems="flex-start"
                                                justify="flex-start"
                                            >
                                                <Button
                                                    onClick={beforeClose}
                                                    size='medium'
                                                    color="secondary"
                                                    variant="contained"
                                                    type="button"
                                                >
                                                    Close
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        alignContent='flex-start'
                                        alignItems='flex-start'
                                        justify='flex-start'
                                    >
                                    </Grid>

                                </Grid>
                            }
                        </DialogContent>
                    </Dialog>
                </form>
            </FormContext>
        </React.Fragment>
    );
};

export default AttendanceSummaryPopup;
