import * as React from "react";
import { useEffect, useRef } from "react";
import { apiAutoCompleteConfig } from "../../constants/apiAutoComplete.config";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../constants/actions.constants";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& label.Mui-focused": {
				color: theme.palette.primary.white,
			},
		},
	})
)(TextField);

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {},
	})
);

const config: ApiAutoCompleteConfigItem = {
	acId: "educationLevels",
	cacheTime: 5,
	route: "/SystemCatalog/EducationLevel/GetEducationLevelsByCampus",
	requestType: "GET",
	body: null,
	hasUrlParams: true,
	useCache: true,
	storeInCache: true,
	isCampusSpecific: true,
	isUserSpecific: false,
	loadOnRender: true,
	defaultSelectedFirstItem: false,
	optionsMappedFx: (data: Array<any>) => {
		return data;
	},
};

const EducationCompletedAutoComplete = (props: FilterParams) => {
	const classes = useStyles({});
	const dispatch = useDispatch();
	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const inputRef: any = useRef(null);
	const filterHandle = props.filterHandle;

	const educationCompletedOnChange = (e: any) => {
		if (filterHandle) {
			filterHandle(e);
		}
	};

	return (
		<div>
			<ApiAutoComplete
				config={config}
				loading={userSelectedCampus ? false : true}
				reqParams={{ campusId: userSelectedCampus }}
				classes={{
					option: "text-black",
				}}
				label={props.label ?? "Education Completed"}
				onChange={(e: any) => {
					educationCompletedOnChange(e);
				}}
				multiple={props.multiple || false}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
				helperText={props.helperText}
				error={props.error}
				inputRef={props.inputRef ? props.inputRef : inputRef}
				inputName={props.name}
				showIncludeAllValue={props.showIncludeAllValue}
				chipSize={props.chipSize}
				maxTags={props.maxTags}
				includeAllValue={props.includeAllValue ? props.includeAllValue : false}
			></ApiAutoComplete>
		</div>
	);
};
export default EducationCompletedAutoComplete;
