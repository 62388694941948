import React from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  TableBody,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { IStudentDocumentModule } from "../../../interfaces/student/documents/IStudentDocumentModule";
import { IStudentDocumentType } from "../../../interfaces/student/documents/IStudentDocumentType";
import {
  Order,
  CustomHeadCell,
} from "../../../interfaces/common/Table/CustomHeadCell";
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
} from "@material-ui/icons";
import CustomTableHeader from "../../../components/Tables/CustomTableHeader";
import * as TableFunctions from "../../../utils/tableFunctions";
import clsx from "clsx";
import DocumentGridTypeRow from "./DocumentGridTypeRow";
type DocumentRowProps = {
  row: IStudentDocumentModule;
  confimationModalProps: any;
  setConfirmationModalProps: any;
  setSnackBarProps: any;
  refreshDocumentGrid: any;
};

const useStyles = makeStyles((theme: any) =>
  createStyles({
    tableWrapper: {
      width: "100%",
    },
    rowOpenBottom: {
      borderBottom: "2pt solid",
      borderBottomColor: theme.palette.site.secondary,
    },
    rowOpenRight: {
      borderRight: "2pt solid",
      borderRightColor: theme.palette.site.secondary,
    },
    rowOpenLeft: {
      borderLeft: "2pt solid",
      borderLeftColor: theme.palette.site.secondary,
    },
  })
);
const DocumentGridModuleRow = (props: DocumentRowProps) => {
  const classes = useStyles({});
  const { row } = props;
  const [childrenOrder, setChildrenOrder] = React.useState<Order>("asc");
  const [childrenOrderBy, setChildrenOrderBy] = React.useState<
    keyof IStudentDocumentType
  >("name");
  const [open, setOpen] = React.useState(false);

  const childrenHeaderCells: CustomHeadCell<IStudentDocumentType>[] = [
    {
      id: "options",
      numeric: false,
      disablePadding: false,
      label: "",
      widthType: "Option",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Document Type",
      widthType: "Remainder",
    },
  ];

  return (
    <React.Fragment>
      {" "}
      <TableRow>
        <TableCell className={open ? clsx(classes.rowOpenLeft) : ""}>
          {" "}
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {row.documentTypes.length > 0 ? (
              open ? (
                <KeyboardArrowUp />
              ) : (
                <KeyboardArrowDown />
              )
            ) : (
              ""
            )}
          </IconButton>
        </TableCell>
        <TableCell className={open ? clsx(classes.rowOpenRight) : ""}>
          {row.name}
        </TableCell>
      </TableRow>
      {row.documentTypes.length > 0 && open && (
        <TableRow>
          <TableCell
            className={
              open ? clsx(classes.rowOpenBottom, classes.rowOpenLeft) : ""
            }
          ></TableCell>
          <TableCell
            colSpan={5}
            className={
              open ? clsx(classes.rowOpenBottom, classes.rowOpenRight) : ""
            }
          >
            <Collapse in={open} timeout="auto" unmountOnExit></Collapse>
            <Box margin={1}>
              <div className={classes.tableWrapper}>
                <CustomTableHeader<IStudentDocumentType>
                  cells={childrenHeaderCells}
                  defaultOrder={childrenOrder}
                  defaultOrderBy={childrenOrderBy}
                  setOrder={setChildrenOrder}
                  setOrderBy={setChildrenOrderBy}
                  key={"ch"}
                />
                <TableBody>
                  {TableFunctions.stableSort(
                    row.documentTypes,
                    TableFunctions.getSorting(
                      childrenOrder,
                      childrenOrderBy
                    ) as any
                  ).map((docRow: IStudentDocumentType, docIndex: any) => {
                    return (
                      <DocumentGridTypeRow
                        row={docRow}
                        confimationModalProps={props.confimationModalProps}
                        setConfirmationModalProps={
                          props.setConfirmationModalProps
                        }
                        setSnackBarProps={props.setSnackBarProps}
                        refreshDocumentGrid={props.refreshDocumentGrid}
                      />
                    );
                  })}
                </TableBody>
              </div>
            </Box>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

export default DocumentGridModuleRow;
