import React from "react";
import IInstructorClassDetails from "../../../../interfaces/setup/creditHour/IInstructorClassDetails";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as ClassScheduleApi from "../../../../api/setup/academics/classScheduleApi";
import CustomCardTitle from "../../../../interfaces/common/card/CustomCardTitle";
import ScheduleDetailsGrid from "../../creditHour/schedule/SchedulesDetailsGrid";
import * as classRegApi from "../../../../api/setup/academics/classRegistrationApi";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { useSelector } from "react-redux";
import { IClassRosterModel } from "../../../../interfaces/setup/creditHour/ITermSearchModels";
import {
  IClassScheduleDetails,
} from "../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/Schedule/IScheduleDetails";
import {
  Grid,
  Card,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  Button,
  Popover,
  Typography,
  FormControlLabel,
} from "@material-ui/core";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      minHeight: "100%",
      height: "100%",
      width: "100%",
      margin: "auto",
    },
    paperRoot: {
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
    },
    inner: {
      height: "100%",
      width: "100%",
      margin: "0 auto",
      minHeight: "100%",
      maxWidth: 1150,
    },
    fixed: {
      position: "sticky",
      top: "0",
      width: "100%",
      marginBottom: "0.5em",
    },
    popOverButtons: {
      display: "flex",
      justifyContent: "space-evenly",
      flexDirection: "row",
      padding: 5
    },
    typography: {
      padding: theme.spacing(2)
    },
    divider: {
      backgroundColor: theme.palette.site.primary,
      marginBottom: theme.spacing(1),
    },
    tabController: {
      backgroundColor: "transparent",
      textTransform: "uppercase",
    },
    tabIndicator: {
      backgroundColor: theme.palette.primary.contrastNavTabColor,
    },
    tabSelected: { fontWeight: "bold" },
    right: {
      float: "right",
    },
    sortIcon: {
      fontSize: 15,
      marginLeft: 10,
    },
    hiddenElement: {
      visibility: "hidden",
    },
    classLabel: {
      fontWeight: "bold",
    },
    classLabelLink: {
      fontWeight: "bold",
      textDecoration:"underline",
      cursor:"pointer",
    },
    cardMain: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
  })
);

interface IScheduleDetail {
  classScheduleId: string;
  code: string;
  name: string;
  classScheduleDetails: IClassScheduleDetails[];
}
interface IStudent {
  stuEnrollId: string;
  studentNumber: string;
  studentName: string;
}

type SelectedClassProps = {
  selectedClass: IInstructorClassDetails | undefined;
};
function ClassSummary(props: SelectedClassProps) {
  const classes = useStyles();

  const [selectedClass, setSelectedClasses] = React.useState<
    IInstructorClassDetails | undefined
  >(props.selectedClass);

  const [classesSchedule, setClassesSchedule] =
    React.useState<IScheduleDetail>();

  const [classEnrollments, setClassEnrollments] = React.useState<
    IStudent[] | undefined
  >(undefined);

  const [sortOn, setSortOn] = React.useState<string>("studentNumber");
  const [sortOrder, setSortOrder] = React.useState<string>("ASC");

  const [popOverAnchorEl, setPopOverAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const popOverOpen = Boolean(popOverAnchorEl);
  const popOverId = popOverOpen ? "simple-popover-instructorSummary" : undefined;


  const handleClosePopOver = () => {
    setPopOverAnchorEl(null);
  };

  
  const handleClickPopOver = (event: any) => {
    setPopOverAnchorEl(event.currentTarget);
  };

  const handlePrint = (IsStudentId: number) => {
    printRoster(IsStudentId);
    setPopOverAnchorEl(null);
  };
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );
  const loadSchedule = () => {
    if (selectedClass?.classScheduleId) {
      ClassScheduleApi.getScheduleDetailById(
        selectedClass?.classScheduleId
      ).then((response: any) => {
        if (response) {
          var result: IScheduleDetail = response;
          setClassesSchedule(result);
        }
      });
    }
  };

  const loadRoster = () => {
    if (selectedClass?.instrClassTermId) {
      classRegApi
        .GetEnrollmentsByClass(selectedClass?.instrClassTermId)
        .then((response: any) => {
          if (response) {
            var result: IStudent[] = response;
            setClassEnrollments(result);
          }
        });
    }
  };

  React.useEffect(() => {
    if (props.selectedClass) {
      setSelectedClasses(props.selectedClass);
      setClassesSchedule(undefined);
      setClassEnrollments(undefined);
    }
  }, [props.selectedClass]);

  const setScheduleDetails = (newValue: any) => {};

  const printRoster = (isStudentId: number) => {
    if (selectedClass?.instrClassTermId) {
      let params: IClassRosterModel = {
        campusId: userSelectedCampus,
        classId : selectedClass?.instrClassTermId,
        isShowStudentNumber: isStudentId
      };
     classRegApi.generateReport(params);
     
    }

  };

  const handleSort = (field: string) => {
    setSortOn(field);
    sortOrder == "ASC" ? setSortOrder("DESC") : setSortOrder("ASC");
  };

  return (
    <Grid container direction="row" spacing={1}>
      <Card square={true} elevation={2} className={classes.cardMain}>
        <CustomCardTitle title="Class Detail"></CustomCardTitle>
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableBody>
            <TableRow>
            <TableCell>
                  <label className={classes.classLabel}>Instructor Name:</label>{" "}
                  {selectedClass?.instructorName}
                </TableCell>
            </TableRow>
              <TableRow>
                <TableCell>
                  <label className={classes.classLabel}>Class Name:</label>{" "}
                  {selectedClass?.className}
                </TableCell>
                <TableCell>
                  <label className={classes.classLabel}>Code:</label>{" "}
                  {selectedClass?.code}
                </TableCell>
                <TableCell>
                  <label className={classes.classLabel}>Seats:</label>{" "}
                  {selectedClass?.occupiedSeats}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <label className={classes.classLabel}>Course:</label>{" "}
                  {selectedClass?.course}
                </TableCell>
                <TableCell>
                  <label className={classes.classLabel}>Start Date:</label>{" "}
                  {selectedClass?.startDate &&
                    new Date(selectedClass?.startDate).toLocaleDateString()}
                </TableCell>
                <TableCell>
                  <label className={classes.classLabel}>End Date:</label>{" "}
                  {selectedClass?.endDate &&
                    new Date(selectedClass?.endDate).toLocaleDateString()}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <label className={classes.classLabel}>Location:</label>{" "}
                  {selectedClass?.classLocation}
                </TableCell>
                <TableCell
                  onClick={(e: any) => {
                    loadSchedule();
                  }}
                >
                  <label className={classes.classLabelLink}>Class Schedule</label>
                </TableCell>
                <TableCell
                  onClick={(e: any) => {
                    loadRoster();
                  }}
                >
                  <label className={classes.classLabelLink}>Roster</label>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      {classesSchedule && (
        <Card square={true} elevation={2} className={classes.cardMain}>
          <CustomCardTitle title="Class Schedule"></CustomCardTitle>
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow>
                  <TableCell>
                    <label className={classes.classLabel}>Code: </label>{" "}
                    {classesSchedule?.code}
                  </TableCell>
                  <TableCell colSpan={1}>
                    <label className={classes.classLabel}>Schedule:</label>{" "}
                    {classesSchedule?.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    <ScheduleDetailsGrid
                      model={classesSchedule?.classScheduleDetails}
                      setModel={setScheduleDetails}
                      readonly={true}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      )}
      {classEnrollments != undefined && (
        <Card square={true} elevation={2} className={classes.cardMain}>
          <CustomCardTitle title="Class Students"></CustomCardTitle>
          <Button
            onClick={(e: any) => handleClickPopOver(e)}
            color="secondary"
            variant="contained"
            type="button"
          >
            {"PRINT"}
          </Button>
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormControlLabel
                      label="Student Number"
                      onClick={() => {
                        handleSort("studentNumber");
                      }}
                      control={
                        sortOn == "studentNumber" ? (
                          sortOrder == "ASC" ? (
                            <ArrowUpward className={classes.sortIcon} />
                          ) : (
                            <ArrowDownward className={classes.sortIcon} />
                          )
                        ) : (
                          <ArrowDownward className={classes.hiddenElement} />
                        )
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      label="Student Name"
                      onClick={() => {
                        handleSort("studentName");
                      }}
                      control={
                        sortOn == "studentName" ? (
                          sortOrder == "ASC" ? (
                            <ArrowUpward className={classes.sortIcon} />
                          ) : (
                            <ArrowDownward className={classes.sortIcon} />
                          )
                        ) : (
                          <ArrowDownward className={classes.hiddenElement} />
                        )
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {classEnrollments
                  .slice()
                  .sort((a, b) => {
                    const aValue = a[sortOn as keyof IStudent];
                    const bValue = b[sortOn as keyof IStudent];

                    if (
                      typeof aValue === "string" &&
                      typeof bValue === "string"
                    ) {
                      if (sortOrder === "ASC") {
                        return aValue.localeCompare(bValue);
                      } else {
                        return bValue.localeCompare(aValue);
                      }
                    } else if (
                      typeof aValue === "boolean" &&
                      typeof bValue === "boolean"
                    ) {
                      return aValue === bValue ? 0 : aValue ? -1 : 1;
                    }

                    return 0;
                  })
                  .map((cls: IStudent, ix: any) => (
                    <TableRow>
                      <TableCell>{cls.studentNumber}</TableCell>
                      <TableCell>{cls.studentName}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Popover
            id={popOverId}
            open={popOverOpen}
            anchorEl={popOverAnchorEl}
            onClose={handleClosePopOver}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <Typography
              color="textSecondary"
              className={classes.typography}
              variant="body1"
            >
              Print with student Id ?
            </Typography>
            <div className={classes.popOverButtons}>
              <Button
                variant="contained"
                size="small"
                onClick={() => handlePrint(1)}
                color="primary"
              >
                Yes
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={() => handlePrint(0)}
                color="secondary"
              >
                No
              </Button>
            </div>
          </Popover>
        </Card>
      )}
    </Grid>
  );
}

export default ClassSummary;
