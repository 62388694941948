export interface IStudentInfo
  extends IStudentInfoGeneral,
    IStudentInfoDemographics,
    IStudentInfoAdmissions {}

export interface IStudentInfoGeneral {
  firstName: string;
  middleName: string;
  lastName: string;
  leadId?: string;
  prefixId: string;
  suffixId: string;
  dateOfBirth: string;
  stateBoardId: string;
  afaStudentId?: number;
  stateBoardRegDate: string;
  ssn: string;
  maskedSSN: string;
  excludeFrom1098: boolean;
  isInternationalStudent:boolean;
}

export interface IStudentInfoDemographics {
  genderId: string;
  ethnicityId: string;
  isDisabled?: boolean;
  dependencyTypeId: string;
  dependents: string;
  headOfHousehold?: boolean;
  housingId: string;
  milesFromSchool: string;
  geographicAreaId: string;
  maritalStatusId: string;
  familyIncomeAmount?: number;
  citizenshipId: string;
  efc?: number;
  efcId?: string;
}

export interface IStudentInfoAdmissions {
  highSchoolId: string;
  highSchoolGraduationDate: string;
  currentlyAttendingHighSchool?: boolean;
  admissionsRepId?: string;
  admissionsRepAssigned: string;
}

export function NewStudentInfoDemographics(){

  return {
    genderId: "",
    ethnicityId: "",
    dependencyTypeId: "",
    dependents: "",
    headOfHousehold: false,
    housingId: "",
    milesFromSchool: "",
    geographicAreaId: "",
    maritalStatusId: "",
    familyIncomeAmount: 0,
    citizenshipId: "",
    efc: 1,
    efcId: ""
  }
}

export function NewStudentInfoAdmissions(){

  return {
    highSchoolId: "",
    highSchoolGraduationDate: "",
    currentlyAttendingHighSchool: false,
    admissionsRepId: "",
    admissionsRepAssigned: "",



  }
}


export function NewIStudentInfoModel() {
  
  return {
    firstName: "",
    middleName: "",
    lastName: "",
    leadId: undefined,
    prefixId: "",
    suffixId: "",
    dateOfBirth: "",
    stateBoardId: "",
    afaStudentId: undefined,
    stateBoardRegDate: "",
    ssn: "",
    genderId: "",
    ethnicityId: "",
    isDisabled: false,
    dependencyTypeId: "",
    dependents: "",
    headOfHousehold: false,
    housingId: "",
    milesFromSchool: "",
    geographicAreaId: "",
    maritalStatusId: "",
    familyIncomeAmount: undefined,
    citizenshipId: "",
    highSchoolId: "",
    highSchoolGraduationDate: "",
    currentlyAttendingHighSchool: false,
    admissionsRepId: "",
    admissionsRepAssigned: "",
    excludeFrom1098: false,
    isInternationalStudent:false
  } as IStudentInfo;
}
