import API from "../../apiWrapper";
import Report from "../../../interfaces/reports/Report";
import { stringify } from "qs";
import IRunReport from "../../../interfaces/reports/IRunReport";

export const getReportList = (
  campusId: string | undefined,
  userId: string | undefined,
  reportType: number | undefined,
  reportName: string | null
): Promise<Report> => {
  let route: string = "";
  if (reportType == undefined) {
    route = "Report/GetReportList?campusId=" + campusId + "&userId=" + userId;
  } else {
    route =
      "Report/GetReportList?campusId=" +
      campusId +
      "&userId=" +
      userId +
      "&reportType=" +
      reportType;      
  }

  if(reportName) route = route + "&reportName=" + reportName;

  return API()
    .get(route)
    .then(
      (res: any) => {
        return res.data;
      },
      (error: any) => {
        if (error.response) {
          throw new Error(error.response.data); // => the response payload
        }
        throw new Error("Unknown error");
      }
    );
};

export const getReportTags = (): Promise<Report> => {
  return API()
    .get("Report/GetReportTag")
    .then(
      (res: any) => {
        return res.data;
      },
      (error: any) => {
        if (error.response) {
          throw new Error(error.response.data); // => the response payload
        }
        throw new Error("Unknown error");
      }
    );
};

export const recentlyUsedPrefernce = (
  resourceId: number,
  resourceTypeId: number,
  userId: string
): Promise<boolean> => {
  return API()
    .post(
      "Report/UpdateReportUserPreferenceRecentlyUsed",
      {},
      {
        params: {
          resourceId,
          resourceTypeId,
          userId,
        },
      }
    )
    .then(
      (res: any) => {
        if (res?.data) {
          return res?.data.result;
        }
        return false;
      },
      (_error: any) => {
        return false;
      }
    );
};

export const favoritePrefernce = (
  resourceId: number,
  resourceTypeId: number,
  userId: string,
  favorite: boolean
): Promise<boolean> => {
  return API()
    .post(
      "Report/UpdateReportUserPreferenceFavorite",
      {},
      {
        params: {
          resourceId,
          resourceTypeId,
          userId,
          favorite,
        },
      }
    )
    .then(
      (res: any) => {
        if (res?.data) {
          return res?.data.result;
        }
        return false;
      },
      (_error: any) => {
        return false;
      }
    );
}


 export const updateAdHocDescription = (resourceId: number, resourceTypeId: number, description: string): Promise<boolean> => {
 
  return API()
  .post("Report/UpdateAdHocDescription",{resourceId, resourceTypeId,description})
    .then(
      (res: any) => {
        if (res?.data) {
          return res?.data.result;
        }
        return false;
      },
      (_error: any) => {
        return false;
      }
    );
}

 export const updateReportTagByUser = (resourceId: number, resourceTypeId: number, categoryTagId: Array<string>, userId: string): Promise<boolean> => {
 
   return API()
   .post("Report/UpdateReportTagByUser",{resourceId, resourceTypeId, categoryTagId, userId})
     .then(
       (res: any) => {
         if (res?.data) {
           return res?.data.result;
         }
         return false;
       },
       (_error: any) => {
         return false;
       }
     );
}

export const getReportsForWidget = (userId: string, campusId: string): Promise<boolean> => {

    return API()
        .get("Report/getReportsForWidget", {
            params: { userId, campusId },
            paramsSerializer: params => {
                return stringify(params)
            }
        })
        .then(
            (res: any) => {
                if (res?.data) {
                    console.log(res.data)

                    return res?.data.result;
                }
                return false;
            },
            (_error: any) => {
                return false;
            }
        );
}

export const runReports = (params: IRunReport): Promise<boolean> => {

    return API()
        .post("Report/RunReports", params)
        .then(
            (res: any) => {
                if (res?.data) {
                    return res?.data;
                }
                return false;
            },
            (_error: any) => {
                return false;
            }
        );
}

export const getBlobUris = (userId: string, campusId: string, fromDate: Date | string, toDate: Date | string, reportIds: Array<number> | undefined = undefined): Promise<boolean> => {

    return API()
        .get("Report/GetBlobUris", {
            params: { userId, campusId, fromDate, toDate, reportIds },
            paramsSerializer: params => {
                return stringify(params)
            }
        })
        .then(
            (res: any) => {
                if (res?.data) {
                    return res?.data;
                }
                return false;
            },
            (_error: any) => {
                return false;
            }
        );
}


export const getReportGenerationHistory = (userId: string, campusId: string): Promise<boolean> => {

    return API()
        .get("Report/GetReportGenerationHistory", {
            params: { userId, campusId },
            paramsSerializer: params => {
                return stringify(params)
            }
        })
        .then(
            (res: any) => {
                if (res?.data) {
                    return res?.data;
                }
                return false;
            },
            (_error: any) => {
                return false;
            }
        );
}