import * as React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Switch,
} from "@material-ui/core";
import { FormContext, useForm } from "react-hook-form";
import { IAidReceivedAllStudentsByDateParams } from "../../../interfaces/reports/studentAccounts/IAidReceivedAllStudentsByDateParams";
import * as aidReceivedApi from "../../../api/reports/studentAccounts/aidReceivedApi";
import { ReportOutput } from "../../../enums/ReportOutput";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import StartEndDateFilter from "../../attendance/StartEndDateFilter";
import AwardYearAutoComplete from "../../../components/AutoComplete/AwardYearAutoComplete";
import FundSourcesAutoComplete from "../../../components/AutoComplete/FundSourcesAutoComplete";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import { useSelector } from "react-redux";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "100%",
      height: "100%",
      padding: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    paperRoot: {
      padding: theme.spacing(3, 2),
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
    },
    searchActions: {
      marginTop: theme.spacing(3),
      textAlign: "left",
    },
    startDateField: {
      width: "100%",
    },
    fieldSet: {
      marginTop: theme.spacing(2),
    },
  })
);

const getFormattedDate = (date: Date) => {
  var stringDate = date.toString()
  if(stringDate.includes("/")){
    return date
  }
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return month + "/" + day + "/" + year;
};

const AidReceivedAllStudentsByDate = (props: any) => {
  const { report } = props;
  const classes = useStyles({});
  const [preview, setPreview] = React.useState<any>();
  const [previewLoader, setPreviewLoader] = React.useState<boolean>();
  const [value, setValue] = React.useState<any>({
    startDate: new Date(),
    endDate: new Date(),
  });

  let reportOverview = { report: report } as IReportOverview;

  const data = useForm<any>({ mode: "onBlur" });

    const campusId = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

    const defaultFilters = {
        campusId: campusId as string,
        startDate: value.startDate as Date,
        endDate: value.endDate as Date,
        awardYearId: "" as string | undefined,
        fundSourceIds: [] as Array<string>,
        sortOrder: "name" as string | undefined,
        excludeRefunds:false,
        exportType: 1 as ReportOutput,
    };
    const [model, setModel] = React.useState<any>(defaultFilters);


    const exportTypes: Array<DropDownListItem> = [
      { text: "PDF", id: "1" },
      { text: "Excel", id: "2" },
      { text: "Word", id: "3" },
    ];
  
    const exportTypeAutoComplete = {
      options: exportTypes,
      getOptionLabel: (option: DropDownListItem) => option.text,
    };
    const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
      const item = items.find((opt) => {
        if (opt.id == value) return opt;
      });
      return item || ({} as DropDownListItem);
    };
  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    setModel({ ...updatedModel });
  };

  const handleAwardYearChange = (value: any) => {
    let startYear: number | undefined = undefined;
    let endYear: number | undefined = undefined;

    if (value && value.text) {
      let awardYear: string = value.text;
      let ind = awardYear.indexOf("-");
      if (ind != -1) {
        let newStartYear = awardYear.substring(0, ind);
        let newEndYear = awardYear.substring(ind + 1, awardYear.length);

        if (newStartYear) {
          let yearPrefix = newStartYear.substring(0, 2);

          startYear = Number(newStartYear);

          if (newEndYear) {
            endYear = Number(yearPrefix + newEndYear);
          }
        }
      } else {
        ind = awardYear.indexOf("/");
        if (ind != -1) {
          let newStartYear = awardYear.substring(0, ind);
          let newEndYear = awardYear.substring(ind + 1, awardYear.length);

          if (newStartYear) {
            startYear = Number(newStartYear);
          }

          if (newEndYear) {
            endYear = Number(newEndYear);
          }
        }
      }

      if (startYear && endYear) {
        let updatedModel = model;
        updatedModel.startDate = new Date(startYear, 6, 1);
        updatedModel.endDate = new Date(endYear, 5, 30);
        updatedModel.awardYearId = value.value;
        setModel({ ...updatedModel });
        setValue({
          startDate: updatedModel.startDate,
          endDate: updatedModel.endDate,
        });
      }
    }
  };

  const { handleSubmit } = data;

  const handleSortOrderChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let updatedModel = model;
    (updatedModel as any)[
      "sortOrder"
    ] = (event.target as HTMLInputElement).value;
    setModel({ ...updatedModel });
  };

  const onSubmit = async (isPreview: any): Promise<void> => {
    let params = await getReportParameters();
    const validation = await data.triggerValidation();
    if (validation) {
      if (isPreview) {
        aidReceivedApi.generateReport(params, isPreview).then((res: any) => {
          if (res) {
            res.arrayBuffer().then((buffer: any) => {
              setPreview(buffer);
              setPreviewLoader(false);
            });
          } else setPreviewLoader(false);
        });
      } else await aidReceivedApi.generateReport(params);
    }
  };

  const filterHandler = (data: any) => {
    if (data) {
      setValue({ startDate: data.startDate, endDate: data.endDate });
      setModel({ ...data });
    } else {
      setValue({ startDate: new Date(), endDate: new Date() });
      setModel({
        campusId: model.campusId,
        startDate: value.startDate as Date,
        endDate: value.endDate as Date,
        awardYearId: "" as string | undefined,
        fundSourceIds: [] as Array<string>,
        sortOrder: "name" as string | undefined,
        excludeRefunds:false,
        exportType: model?.exportType,
      });
    }
  };

  const getReportParameters = async () => {
    const success = await data.triggerValidation();
    if (model && success) {
      let startDate = model.startDate
        ? getFormattedDate(model.startDate)
        : getFormattedDate(new Date());
      let endDate = model.endDate ? getFormattedDate(model.endDate): undefined;
      let params: IAidReceivedAllStudentsByDateParams = {
        campusId: model.campusId,
        endDate: endDate,
        startDate: startDate,
        awardYearId: model.awardYearId,
        fundSourceIds: model.fundSourceIds,
        sortOrder: model.sortOrder,
        //exportType: ReportOutput.Pdf,
        exportType: model.exportType,
        excludeRefunds:model.excludeRefunds,
      };
      return params;
    }
    return {} as IAidReceivedAllStudentsByDateParams;
  };

  let parameters = (
    <div className={classes.root}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} sm={12} xs={12}>
              <FormControl>
                <FormLabel component="label">Date Range*</FormLabel>
                <StartEndDateFilter
                  model={value}
                  setModel={(values: any) => {
                    handleFieldOnChange(
                      "startDate",
                      values ? values.startDate : undefined
                    );
                    handleFieldOnChange(
                      "endDate",
                      values ? values.endDate : undefined
                    );
                  }}
                  allowCustomDates={true}
                />
                <FormHelperText id="my-helper-text">
                  The date range for the report.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <AwardYearAutoComplete
                name="awardYearId"
                filterHandle={(value: any) => handleAwardYearChange(value)}
                valueFilter={
                  model.awardYearId
                    ? {
                        key: "value",
                        values: [model.awardYearId],
                      }
                    : ""
                }
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <FundSourcesAutoComplete
                name="fundSourceIds"
                error={!!data.errors.fundSourceIds}
                inputRef={data.register({
                  validate: {
                    atLeastOneRequired: (value) =>
                      (model.fundSourceIds && model.fundSourceIds.length > 0) ||
                      "Group(s) are required.",
                  },
                })}
                helperText={
                  data.errors.fundSourceIds
                    ? "At least one fund source is required."
                    : undefined
                }
                filterHandle={(value: any) =>
                  handleFieldOnChange(
                    "fundSourceIds",
                    value ? value.map((v: any) => v.value) : undefined
                  )
                }
                valueFilter={
                  model.fundSourceIds
                    ? {
                        key: "value",
                        values: model.fundSourceIds,
                      }
                    : undefined
                }
                label="Fund Source*"
                multiple={true}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
                <Autocomplete
                  {...exportTypeAutoComplete}
                  autoComplete
                  includeInputInList
                  onChange={(e: any, value: any) => {
                    handleFieldOnChange(
                      "exportType",
                      value ? value : undefined,
                      (v: any) => v?.id
                    );
                  }}
                  value={getSelectedItem(exportTypes, model.exportType)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Export Type *"
                      name="exportType"
                      error={!!data.errors.exportType}
                      inputRef={data.register({ required: true })}
                      helperText={
                        data.errors.exportType
                          ? "Export Type is required."
                          : undefined
                      }
                      fullWidth
                    />
                  )}
                />
              </Grid>
            <Grid item md={4} sm={6} xs={8}>
              <FormControl component="fieldset" className={classes.fieldSet}>
                <FormLabel component="legend">Sort Order</FormLabel>
                <RadioGroup
                  row
                  aria-label="sortOrder"
                  name="sortOrder"
                  value={model.sortOrder}
                  onChange={handleSortOrderChange}
                >
                  <FormControlLabel
                    labelPlacement="start"
                    value="name"
                    control={<Radio />}
                    label="Name"
                  />
                  <FormControlLabel
                    labelPlacement="start"
                    value="ssn"
                    control={<Radio />}
                    label="SSN"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item md={4} sm={4} xs={4}>
              <FormControl>
            <FormControlLabel
                  label="Exclude Refunds"
                  control={
                    <Switch
                      checked={model.excludeRefunds}
                      value="exclueRefunds"
                      onChange={(e: any) => {
                        setModel({...model,excludeRefunds:e.target.checked})
                      }}
                      color="primary"
                    />
                  }
                />
                </FormControl>
            </Grid>
          </Grid>
        </form>
      </FormContext>
    </div>
  );

  reportOverview.parameters = parameters;

  return (
    <ReportOverview
      reportOverview={reportOverview}
      filterHandler={filterHandler}
      getReportParameters={getReportParameters}
      exportHandler={onSubmit}
      preview={preview}
      previewLoader={previewLoader}
    />
  );
};

export default AidReceivedAllStudentsByDate;
