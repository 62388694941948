import DateFnsUtils from "@date-io/date-fns";
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Switch } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import { FormContext, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as WeeklyAttendanceReportAPI from "../../../api/reports/attendance/weeklyAttendanceAPI";
import ProgramVersionAutoComplete from "../../../components/AutoComplete/ProgramVersionAutoComplete";
import StudentGroupsAutoComplete from "../../../components/AutoComplete/StudentGroupsAutoComplete";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import { ReportOutput } from "../../../enums/ReportOutput";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import { IWeeklyAttendance } from "../../../interfaces/reports/attendance/IWeeklyAttendance";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import { endOfWeek, isValid } from 'date-fns';

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(2),
            minHeight: "100%",
            height: "100%",
            maxWidth: theme.spacing(131.25),
            margin: theme.spacing(2),
        },
        "& .makeStyles-content": {
            backgroundColor: theme.palette.background.paper,
        },
        paperRoot: {
            padding: theme.spacing(3, 2),
            width: "100%",
            minHeight: "100%",
            height: "100%",
            overflowX: "hidden",
            overflowY: "auto",
            flexDirection: "column",
            backgroundColor: theme.palette.background.paper,
        },
        width100: {
            width: "100%",
        },
        divWidthAutoComp: {
            width: "90%",
        },
        cardTitle: {
            fontSize: theme.spacing(2.15),
            color: theme.palette.primary.headerText,
            textDecoration: "bold",
            backgroundColor: theme.palette.secondary.dark,
            padding: theme.spacing(1, 1),
            marginBottom: theme.spacing(1),
        },
        headerText: {
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(0),
        },
        cardContent: {
            padding: theme.spacing(1, 3),
        },
        switch: {
            display: "flex",
            alignItems: "flex-end",
            padding: theme.spacing(0, 0.5),
            height: theme.spacing(6.6),
        },
        fieldSet: {
            marginTop: theme.spacing(2),
        },
    })
);



const WeeklyAttendanceReport = (props: any) => {
    const classes = useStyles({});
    const { report } = props;
    let reportOverview = { report: report } as IReportOverview;

    const [preview, setPreview] = React.useState<any>();
    const [previewLoader, setPreviewLoader] = React.useState<boolean>();
    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );
    const [inActiveGroup, setInActiveGroup] = React.useState<
        boolean
    >(false);
    const [inActiveProgramVersion, setInActiveProgramVersion] = React.useState<
        boolean
    >(false);


    const defaultFilters: IWeeklyAttendance = {
        leadGroupIds: [],
        programVersionIds: [],
        weekEndDate: endOfWeek(new Date()),
        exportType: 1 as ReportOutput,
        campusId: userSelectedCampus
    };
    const [model, setModel] = React.useState<IWeeklyAttendance>(defaultFilters);

    const handleChange = (name: string) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        switch (name) {
            case "inActiveGroup":
                setInActiveGroup(event.target.checked);
                break;
            case "inActiveProgramVersion":
                setInActiveProgramVersion(event.target.checked);
                break;
        }
    };


    const setProgramVersion = (PrgVersions: any) => {
        let newProgramVersion: string[] = [];
        if (PrgVersions !== null) {
            if (Array.isArray(PrgVersions)) {
                newProgramVersion = PrgVersions.map((gr: any) => gr.value);
            } else {
                newProgramVersion.push(PrgVersions.value);
            }
        }
        return newProgramVersion;
    };
    const setStudentGroup = (studentGroups: any) => {
        let newGroups: string[] = [];
        if (studentGroups !== null) {
            if (Array.isArray(studentGroups)) {
                newGroups = studentGroups.map((gr: any) => gr.value);
            } else {
                newGroups.push(studentGroups.value);
            }
        }
        return newGroups;
    };
    const exportTypes: Array<DropDownListItem> = [
        { text: "PDF", id: "1" },
        { text: "Excel", id: "2" },
        { text: "Word", id: "3" },
    ];

    const exportTypeAutoComplete = {
        options: exportTypes,
        getOptionLabel: (option: DropDownListItem) => option.text,
    };

    const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
        const item = items.find((opt) => {
            if (opt.id == value) return opt;
        });
        return item || ({} as DropDownListItem);
    };
    const data = useForm<IWeeklyAttendance>({ mode: "onBlur", defaultValues: defaultFilters });
    const handleFieldOnChange = (
        fieldId: string,
        value: any,
        mapFunction?: Function
    ) => {
        let updatedModel = model;
        let newValue = mapFunction ? mapFunction(value) : value;
        (updatedModel as any)[fieldId] = newValue;
        data.setValue(fieldId, newValue);
        data.triggerValidation(fieldId);
        setModel({ ...updatedModel });
    };
    const { handleSubmit } = data;
    const onSubmit = async (isPreview: any): Promise<void> => {
        let params = (await getReportParameters()) as IWeeklyAttendance;
        const validation = await data.triggerValidation();
        params.weekEndDate = new Date(params.weekEndDate).toLocaleDateString();
        if (validation && params.weekEndDate) {
            if (isPreview) {
                setPreviewLoader(true);
                WeeklyAttendanceReportAPI
                    .generateReport(params, isPreview)
                    .then((res: any) => {
                        if (res) {
                            res.arrayBuffer().then((buffer: any) => {
                                setPreview(buffer);
                                setPreviewLoader(false);
                            });
                        } else setPreviewLoader(false);
                    });
            } else await WeeklyAttendanceReportAPI.generateReport(params);
        }
    };

    const filterHandler = (data: any) => {
        if (data) {
            setInActiveGroup(data.inActiveGroup);
            setInActiveProgramVersion(data.inActiveProgramVersion);
            setModel({ ...data });
        } else {
            setInActiveGroup(false);
            setInActiveProgramVersion(false);
            setModel(defaultFilters);
        }
    };

    const getReportParameters = async () => {
        const success = await data.triggerValidation();
        if (model && success) {
            let params: IWeeklyAttendance = {
                leadGroupIds: model?.leadGroupIds,
                programVersionIds: model?.programVersionIds,
                weekEndDate: model?.weekEndDate,
                exportType: model?.exportType,
                campusId: userSelectedCampus,
            };
            return params;
        }
        return {} as IWeeklyAttendance;
    };

    data.register("weekEndDate", {
        validate: () =>( model.weekEndDate && isValid(model.weekEndDate) ? true : 'Week is required')
    });
    let parameters = (
        <div className={[classes.root, classes.width100].join(" ")}>
            <FormContext {...data}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                    >
                        <React.Fragment>
                            <Grid item md={8} sm={8} xs={12}>
                                <ProgramVersionAutoComplete
                                    id="programVersionIds"
                                    name="programVersionIds"
                                    label="Program Versions"
                                    multiple={true}
                                    params={{
                                        inActiveProgramVersion: inActiveProgramVersion,
                                    }}
                                    selectAll={true}
                                    valueFilter={
                                        model?.programVersionIds
                                            ? {
                                                key: "value",
                                                values: model?.programVersionIds,
                                            }
                                            : undefined
                                    }
                                    filterHandle={(data: any) => {
                                        handleFieldOnChange(
                                            "programVersionIds",
                                            data,
                                            setProgramVersion
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item md={3} sm={3} xs={12} className={classes.switch}>
                                <FormControlLabel
                                    label="Include Inactive Values"
                                    control={
                                        <Switch
                                            checked={inActiveProgramVersion}
                                            value="inActiveProgramVersion"
                                            onChange={handleChange("inActiveProgramVersion")}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>
                        </React.Fragment>
                        <React.Fragment>
                            <Grid item sm={8} md={8}>
                                <StudentGroupsAutoComplete
                                    id="leadGroupIds"
                                    name="leadGroupIds"
                                    filterHandle={(groups: any) => {
                                        handleFieldOnChange(
                                            "leadGroupIds",
                                            groups,
                                            setStudentGroup
                                        );
                                    }}
                                    label="Student Group"
                                    params={{
                                        inActiveStudentGroup: inActiveGroup,
                                    }}
                                    multiple={true}
                                    valueFilter={
                                        model?.leadGroupIds
                                            ? {
                                                key: "value",
                                                values: model?.leadGroupIds,
                                            }
                                            : undefined
                                    }

                                />
                            </Grid>
                            <Grid item md={3} sm={3} xs={12} className={classes.switch}>
                                <FormControlLabel
                                    label="Include Inactive Values"
                                    control={
                                        <Switch
                                            checked={inActiveGroup}
                                            value="inActiveGroup"
                                            onChange={handleChange("inActiveGroup")}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>
                        </React.Fragment>
                        <React.Fragment>
                            <Grid item md={4} sm={4} xs={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        views={["year", "month", "date"]}
                                        variant="inline"
                                        id="weekEndDate"
                                        name="weekEndDate"
                                        error={!!data.errors.weekEndDate}
                                        shouldDisableDate={(date) => date?.getDay() !== 6}
                                        helperText={
                                            data.errors["weekEndDate"]
                                                ? "The week is required"
                                                : undefined
                                        }
                                        label="Week of *"
                                        minDate={new Date().setFullYear(
                                            new Date().getFullYear() - 10
                                        )}
                                        value={model.weekEndDate ? model.weekEndDate : null}
                                        onChange={(value: any) =>
                                            handleFieldOnChange("weekEndDate", value ? value : undefined)

                                        }
                                        className={classes.width100}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item md={4} sm={4} >
                                <Autocomplete className={classes.divWidthAutoComp}
                                    {...exportTypeAutoComplete}
                                    autoComplete
                                    disableClearable
                                    includeInputInList
                                    onChange={(e: any, value: any) => {
                                        handleFieldOnChange(
                                            "exportType",
                                            value ? value : undefined,
                                            (v: any) => v?.id
                                        );
                                    }}
                                    value={getSelectedItem(exportTypes, model.exportType.toString())}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Export Type *"
                                            name="exportType"
                                            error={!!data.errors.exportType}
                                            inputRef={data.register({ required: true })}
                                            helperText={
                                                data.errors.exportType
                                                    ? "Export Type is required."
                                                    : undefined
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                        </React.Fragment>
                    </Grid>
                </form>
            </FormContext>
        </div>
    );
    reportOverview.parameters = parameters;

    return (
        <ReportOverview
            reportOverview={reportOverview}
            filterHandler={filterHandler}
            getReportParameters={getReportParameters}
            exportHandler={onSubmit}
            preview={preview}
            previewLoader={previewLoader}
        />
    );
};

export default WeeklyAttendanceReport;
