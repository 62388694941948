export interface IProgramVersionAttendanceDetails{
    attendanceUnitTypeId: string;
    doesTrackTardies: boolean;
    usesTimeClock: boolean;
    graduateWhenHoursLength: string;
    excusedAbsencePercentageAllowedPerPaymentPeriod: Number;
    trackAttendanceBy:string | undefined | null;
    attendanceUnitTypeDescription: string | undefined | null;
}

export class ProgramVersionAttendanceDetails implements IProgramVersionAttendanceDetails{
    attendanceUnitTypeId: string = "";
    doesTrackTardies: boolean =  false;
    graduateWhenHoursLength: string = "";
    usesTimeClock: boolean = false;
    excusedAbsencePercentageAllowedPerPaymentPeriod: Number = 0;
    trackAttendanceBy:string | undefined | null = undefined;
    attendanceUnitTypeDescription:string | undefined | null = undefined;
    
}