import {
  authConstants,
  userTermsConstants,
  supportUserConstants,
  cookiesConstants,
  multitenantUserConstants,
} from "../constants/actions.constants";
import * as authApi from "../api/security/authApi";
import { LoginUser } from "../interfaces/security/LoginUser";
import * as apiWrapper from "../api/apiWrapper";

export const sessionActions = {
  login,
  loginWithToken,
  logout,
  request,
  success,
  failure,
  clearErrorMsg,
  changeUserTerms,
  cancelUserTerms,
  cancelTenantSelector,
};

function login(loginUser: LoginUser, override: boolean = false) {
  return (dispatch: any) => {
    dispatch({ type: authConstants.SESSION_LOGIN_REQUEST });
    authApi
      .login(loginUser)
      .then(
        (response: any) => {
          if (
            !response.data ||
            !response.data.email ||
            !response.data.fullName
          ) {
            dispatch(failure(response.data.message));
            return;
          }

          if (response.data.isSupportUser) {
            localStorage.setItem(
              cookiesConstants.FAME_THEME_MODE_KEY,
              cookiesConstants.FAME_THEME_MODE
            );
          }

          if (
            response.data.userTermsOfUse &&
            !response.data.userTermsOfUse.hasAcceptedLatestTermsOfUse
          ) {
            // THERE'S UPDATE IN USER TERMS
            dispatch({
              type: userTermsConstants.NEED_APPROVAL,
              payload: response.data,
            });
            return;
          }

          let isMultiTenantUser = response.data!.tenants!.length > 1;
          let selectedTenant = !!loginUser.tenantName;

          if (!isMultiTenantUser) {
            dispatch(success(response.data));
            dispatch({ type: authConstants.SESSION_ESTABLISHED });
          }

          if (isMultiTenantUser && selectedTenant) {
            dispatch(success(response.data));
            dispatch({ type: authConstants.SESSION_ESTABLISHED });
            dispatch({
              type: multitenantUserConstants.MULTI_TENANT_USER_CANCEL,
            });
            return;
          }

          if (isMultiTenantUser && !selectedTenant) {
            let eventBody = response.data!.tenants;
            dispatch({
              type: multitenantUserConstants.MULTI_TENANT_USER_FOUND,
              eventBody,
            });
          }
        },
        (exception: any) => {
          if (exception) dispatch(failure(exception.toString()));
          else dispatch(failure(authConstants.SESSION_LOGIN_ERROR_MESSAGE));
        }
      )
      .catch((err) => {
        dispatch(failure(authConstants.SESSION_LOGIN_ERROR_MESSAGE));
      });
  };
}

function loginWithToken(token: string, tenantName: string) {
  return (dispatch: any) => {
    dispatch({ type: authConstants.SESSION_LOGIN_REQUEST });
    authApi
      .loginWithToken(token, tenantName)
      .then(
        (response: any) => {
          if (
            !response.data ||
            !response.data.email ||
            !response.data.fullName
          ) {
            dispatch(failure(response.data.message));
            return;
          }

          if (response.data.isSupportUser) {
            localStorage.setItem(
              cookiesConstants.FAME_THEME_MODE_KEY,
              cookiesConstants.FAME_THEME_MODE
            );
          }

          if (
            response.data.userTermsOfUse &&
            !response.data.userTermsOfUse.hasAcceptedLatestTermsOfUse
          ) {
            // THERE'S UPDATE IN USER TERMS
            dispatch({
              type: userTermsConstants.NEED_APPROVAL,
              payload: response.data,
            });
            return;
          }

          let isMultiTenantUser = response.data!.tenants!.length > 1;
          let selectedTenant = !!tenantName;

          if (!isMultiTenantUser) {
            dispatch(success(response.data));
            dispatch({ type: authConstants.SESSION_ESTABLISHED });
          }

          if (isMultiTenantUser && selectedTenant) {
            dispatch(success(response.data));
            dispatch({ type: authConstants.SESSION_ESTABLISHED });
            dispatch({
              type: multitenantUserConstants.MULTI_TENANT_USER_CANCEL,
            });
            return;
          }

          if (isMultiTenantUser && !selectedTenant) {
            let eventBody = response.data!.tenants;
            dispatch({
              type: multitenantUserConstants.MULTI_TENANT_USER_FOUND,
              eventBody,
            });
          }
        },
        (exception: any) => {
          if (exception) dispatch(failure(exception.toString()));
          else dispatch(failure(authConstants.SESSION_LOGIN_ERROR_MESSAGE));
        }
      )
      .catch((err) => {
        dispatch(failure(authConstants.SESSION_LOGIN_ERROR_MESSAGE));
      });
  };
}

function clearErrorMsg(loginUser: LoginUser, override: boolean = false) {
  return (dispatch: any) => {
    dispatch({ type: authConstants.SESSION_LOGIN_ERROR, error: null });
  };
}
function request() {
  return { type: authConstants.SESSION_LOGIN_REQUEST };
}

function success(user: any) {
  apiWrapper.setToken(user.token);
  return { type: authConstants.SESSION_LOGIN_SUCCESS, user };
}

function failure(error: any) {
  return { type: authConstants.SESSION_LOGIN_ERROR, error };
}

function logout() {
  return { type: authConstants.SESSION_LOGOUT };
}

function changeUserTerms(email: string, version: any) {
  return (dispatch: any) => {
    dispatch({ type: userTermsConstants.APPROVAL_REQUEST });
    authApi.changeUserTerms(email, version).then(
      (response: any) => {
        dispatch({ type: userTermsConstants.APPROVAL_DONE });
      },
      (exception: any) => {
        dispatch({ type: userTermsConstants.APPROVAL_DONE });
      }
    );
  };
}

function cancelUserTerms() {
  return (dispatch: any) => {
    dispatch({ type: userTermsConstants.APPROVAL_CANCEL });
  };
}

function cancelTenantSelector() {
  return (dispatch: any) => {
    dispatch({ type: supportUserConstants.SUPPORT_USER_CANCEL });
  };
}
