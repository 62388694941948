import * as React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import UserNameFilter from '../../../../components/AutoComplete/UserNameAutoComplete';
import StatusFilter from '../../../../components/AutoComplete/StatusAutoComplete';
import MultiCampusFilter from '../../../../components/AutoComplete/MultiCampusAutoComplete';
import PermissionAutoComplete from '../../../../components/AutoComplete/PermissionAutoComplete';
const useStyles = makeStyles(() => ({
    root: {},
    addButton: {
        display: 'flex',
        marginLeft: 'auto'
    }
}));

type IUserListFilterProps = {
    setIsAddingUser?: any,
    userNameFilterHandle: any,
    roleFilterHandle?: any,
    statusFilterHandle: any,
    campusFilterHandle: any,
    permissionFilterHandle:any,
    className?: string
}

const Header = (props: IUserListFilterProps) => {
    const { userNameFilterHandle, roleFilterHandle, statusFilterHandle, campusFilterHandle, permissionFilterHandle, setIsAddingUser, className, ...rest } = props;

    const classes = useStyles({});

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Grid
                alignItems="flex-end"
                container
                spacing={3}
            >
                <Grid item xs={8}>

                    <Typography
                        component="h1"
                        variant="h5"
                    >
                        Filters
                    </Typography>

                </Grid>
                <Grid item xs={4}>
                    <Button className={classes.addButton}
                        color="primary"
                        variant="contained"
                        onClick={() => setIsAddingUser(true)}
                    >
                        Add User
                        </Button>
                </Grid>
                <Grid item xs={6}>

                    <UserNameFilter filterHandle={userNameFilterHandle} />
                </Grid>

                <Grid item xs={6}>

                    <StatusFilter filterHandle={statusFilterHandle} />

                </Grid>

                <Grid item xs={6}>

                    <MultiCampusFilter filterHandle={campusFilterHandle} />

                </Grid>

                <Grid item xs={6}>
                    <PermissionAutoComplete
                        multiple={true}
                        filterHandle={permissionFilterHandle}
                    />
                </Grid>


            </Grid>
        </div>
    );
};

Header.propTypes = {
    className: PropTypes.string
};

export default Header;
