import * as React from "react";
import { useRef } from "react";
import autoCompleteConfig from "../../constants/setup/academic/ProgramVersion/CreditGradeBookComponentTypesAutoComplete";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { useSelector } from "react-redux";


const CreditGradeBookComponentTypesAutoComplete = (props: FilterParams) => {
    const inputRef: any = useRef(null);

    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );
    const onChange = (e: any) => {
        if (props.filterHandle) {
            props.filterHandle(e);
        }
    };

    return (
        <div>
            <ApiAutoComplete
                reqParams={{ campusId: props.requestParameter?.campusId ?? userSelectedCampus }}
                config={{...autoCompleteConfig, acId: (props.requestParameter?.campusId ?? userSelectedCampus) + "_" + autoCompleteConfig.acId}}
                classes={{
                    option: "text-black"
                }}
                label={props.label}
                onChange={(e: any) => {
                    onChange(e);
                }}
                disabled={props.disabled}
                multiple={false}
                valueFilter={props.valueFilter ? props.valueFilter : undefined}
                inputRef={props.inputRef ? props.inputRef : inputRef}
                error={props.error}
                helperText={props.helperText}
                inputName={props.name}
            ></ApiAutoComplete>
        </div>
    );
};
export default CreditGradeBookComponentTypesAutoComplete;
