import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Typography, Card, CardContent, IconButton, Tooltip, Badge } from "@material-ui/core";
import DisplayField from "../DisplayField/DisplayField";
import { useSelector } from "react-redux";
import * as quickInfoApi from "../../../api/student/quickInfo/quickInfoApi";
import { IFinancialAidCard } from "../../../interfaces/student/quickInfo/IFinancialAidCard";
import { Link } from "react-router-dom";
import MailIcon from "@material-ui/icons/MailOutline";
import MailSent from "@material-ui/icons/MailOutline";
import MailBounced from "@material-ui/icons/MailOutline";
import DisbursementNotificationsPopup from "./DisbursementNotificationsPopup";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    card: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(2),
      "& .MuiCardContent-root": {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    cardTitle: {
      fontSize: 14,
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(2),
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightMedium,
    },
  })
);

const FinancialAidCard = (props: any) => {
  const { className } = props;
  const [loading, setLoading] = React.useState<boolean>(false);
  const classes = useStyles({});

  const selectedEnrollment = useSelector(
    (state: any) => state.student.selectedEnrollment
  );

  const [financialAidData, setFinancialAidData] =
    useState<IFinancialAidCard | null>(null);

  const GetFinancialAidInformation = () => {
    if (selectedEnrollment && selectedEnrollment.stuEnrollId)
      quickInfoApi
        .GetFinancialAidQuickInformation(selectedEnrollment.stuEnrollId)
        .then((response) => {
          if (response) {
            setFinancialAidData(response.data.result);
          }
        });
  };
  useEffect(() => {
    GetFinancialAidInformation();
  }, [selectedEnrollment]);

  const [emailHistoryPopUpOpen, setEmailHistoryPopUpOpen] =
    React.useState<boolean>(false);
  const openEmailHistoryPopUp = React.useCallback(() => {
    setEmailHistoryPopUpOpen(true);
  }, []);
  const closeEmailHistoryPopUp = React.useCallback(() => {
    setEmailHistoryPopUpOpen(false);
  }, []);

  return (
    <Card className={classes.card} elevation={2}>
      <div style={{ display: "inline-flex" }}>
        <Typography className={clsx(classes.cardTitle)}>
          <Link to={"/student/1/financials/awards"}>FINANCIAL AID</Link>
        </Typography>
        <Tooltip title="Disbursement Notifications">
          <IconButton
            aria-label="Disbursement notifications"
            onClick={openEmailHistoryPopUp}
          >
            <div style={{ position: "relative", display: "inline-block" }}>
              <MailIcon />
              {financialAidData?.disbursementEmailStatus === "Red" && (
                <ErrorIcon
                  style={{
                    color: "red",
                    fontSize: 14,
                    position: "absolute",
                    top: -5,
                    right: -5,
                  }}
                />
              )}
              {financialAidData?.disbursementEmailStatus === "Green" && (
                <CheckCircleIcon
                  style={{
                    color: "green",
                    fontSize: 14,
                    position: "absolute",
                    top: -5,
                    right: -5,
                  }}
                />
              )}
            </div>
          </IconButton>
        </Tooltip>
      </div>
      <CardContent>
        <DisplayField
          labelText="Expected"
          valueText={financialAidData?.expectedAmountAsString}
          fieldType="text"
        ></DisplayField>

        <DisplayField
          labelText="Received"
          valueText={financialAidData?.receivedAmountAsString}
          fieldType="text"
        ></DisplayField>

        <DisplayField
          labelText="Refunded"
          valueText={financialAidData?.refundedAmountAsString}
          fieldType="text"
        ></DisplayField>
        <DisplayField
          labelText="Due"
          valueText={financialAidData?.dueAmountAsString}
          fieldType="text"
        ></DisplayField>
      </CardContent>
      <DisbursementNotificationsPopup
        enrollmentId={selectedEnrollment.stuEnrollId}
        module={1}
        open={emailHistoryPopUpOpen}
        handleClose={closeEmailHistoryPopUp}
        isLoading={loading}
        setLoading={setLoading}
      />
    </Card>
  );
};

export default FinancialAidCard;
