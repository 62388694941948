import * as React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
	Typography,
	Card,
	CardContent,
	TableContainer,
	TableRow,
	TableCell,
	Table,
	TableBody,
	TableHead,
	IconButton,
	CircularProgress,
} from "@material-ui/core";
import {
	KeyboardArrowLeft,
	KeyboardArrowRight,
	WarningOutlined,
} from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import clsx from "clsx";
import DateFnsUtils from "@date-io/date-fns";
import {
	startOfWeek,
	endOfWeek,
	isWithinInterval,
	isSameDay,
	format,
	getWeek,
	addDays,
	subDays,
	isEqual,
} from "date-fns";
import { Scrollbars, positionValues } from "react-custom-scrollbars";
import IPostAttendanceFilters from "../../interfaces/attendance/IPostAttendanceFilters";
import IWeeklyAttendance, {
	ISlotDetails,
} from "../../interfaces/attendance/IWeeklyAttendance";
import {
	getWeeklyAttendacneData,
	saveDetails,
	createPunchRecord,
	postAbsentForDay,
	deletePunchRecord,
	updatePunchRecord,
	validatePunches,
	getStudentAttendanceDetailsByDate,
} from "../../api/attendance/postWeeklyAttendance";
import { useSelector } from "react-redux";
import PostAttendanceModal from "./PostAttendanceModal";
import { CustomSnackBarProps } from "../../interfaces/common/CustomSnackBarProps";
import IPostAttendacePunchRecords from "../../interfaces/attendance/IPostAttendacePunchRecords";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.background.paper,
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2),
			},
		},
		"& .makeStyles-content": {
			backgroundColor: theme.palette.background.paper,
		},
		cardRoot: {
			display: "flex",
			flexDirection: "column",
			height: "100%",
		},
		button: {
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(3),
			whiteSpace: "nowrap",
		},
		cardSeparator: {
			marginBottom: theme.spacing(1),
		},
		drawer: {
			width: 280,
		},
		cardTitle: {
			fontSize: 17,
			color: theme.palette.black,
			fontWeight: "bold",
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(3, 1),
			marginBottom: theme.spacing(1),
		},
		calendarTitle: {
			fontSize: 17,
			color: theme.palette.black,
			fontWeight: "bold",
			textAlign: "center",
			width: "fit-content",
		},
		calendarDateTitle: {
			padding: theme.spacing(1, 0, 0.5, 0),
			cursor: "pointer",
		},
		calendarWeekTitle: {
			padding: theme.spacing(0.5, 0, 1, 0),
		},
		textField: {
			marginRight: theme.spacing(1),
			width: "100%",
		},
		headerText: {
			paddingLeft: theme.spacing(1),
		},
		calenderHeaderText: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		cardContent: {
			padding: theme.spacing(0, 3),
			flexGrow: 1,
			display: "flex",
			flexDirection: "column",
		},
		switch: {
			marginLeft: "0px",
			paddingTop: theme.spacing(2),
		},
		tableContainer: {
			flexGrow: 1,
		},
		tableRow: {
			"&:hover": {
				backgroundColor: "transparent",
			},
		},
		tableDetailsRow: {
			backgroundColor: "#fafafa",
			"&:hover": {
				backgroundColor: "#fafafa",
			},
		},
		tableHeadDate: {
			cursor: "pointer",
		},
		warningCell: {
			backgroundColor: "#ea9999",
		},
		tableHead: {
			fontWeight: "bold",
			fontSize: 14,
			color: theme.palette.common.black,
			backgroundColor: theme.palette.site.secondary,
			border: "solid #e1e1e1",
			borderLeftWidth: 1,
			borderRightWidth: 1,
			borderTopWidth: 0,
			borderBottomWidth: 0,
			padding: theme.spacing(0, 2),
			"&:last-child": { borderRightWidth: 0 },
			"&:first-child": { borderLeftWidth: 0 },
		},
		tableHeader: {
			boxShadow:
				"0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
		},
		dateHeader: {
			margin: theme.spacing(1, 0),
		},
		alignLeft: {
			textAlign: "left",
		},
		alignRight: {
			textAlign: "right",
		},
		alignCenter: {
			textAlign: "center",
		},
		dayWrapper: {
			position: "relative",
		},
		day: {
			width: 36,
			height: 36,
			fontSize: theme.typography.caption.fontSize,
			margin: "0 2px",
			color: "inherit",
		},
		customDayHighlight: {
			position: "absolute",
			top: 0,
			bottom: 0,
			left: "2px",
			right: "2px",
			border: `1px solid ${theme.palette.secondary.main}`,
			borderRadius: "50%",
		},
		nonCurrentMonthDay: {
			color: theme.palette.text.disabled,
		},
		highlightNonCurrentMonthDay: {
			color: "#676767",
		},
		highlight: {
			background: theme.palette.primary.main,
			color: theme.palette.common.white,
		},
		firstHighlight: {
			extend: "highlight",
			borderTopLeftRadius: "50%",
			borderBottomLeftRadius: "50%",
		},
		endHighlight: {
			extend: "highlight",
			borderTopRightRadius: "50%",
			borderBottomRightRadius: "50%",
		},
		slotCell: {
			cursor: "pointer",
			"&:hover": {
				backgroundColor: "#F4F0F0",
			},
			"&:focus": {
				backgroundColor: "#F4F0F0",
			},
		},
		slotCellDisabled: {
			backgroundColor: "#f2f2f2",
			cursor: "not-allowed",
		},
		superText: {
			verticalAlign: "super",
			fontSize: theme.spacing(1.25),
		},
		superIcon: {
			fontSize: theme.spacing(3.125),
		},
		scroll:{
			'& div':{
				zIndex: 5
			}
		}
	})
);

interface IPostWeeklyAttendanceTableProps extends RouteComponentProps {
	filters: IPostAttendanceFilters;
	resetFilter: () => void;
	selectedSlots: { [key: number]: ISlotDetails | undefined };
	confirmationDialog: {
		open?: boolean;
		message?: string;
		onCancel?: () => void;
		onOk?: () => void;
	};
	slotClick: (index: number, params?: ISlotDetails) => void;
	applyFilters: (filters: IPostAttendanceFilters) => void;
	showSnackBar: (params: CustomSnackBarProps) => void;
	closeAllModals: () => void;
	setBulkDetailsOpen: (values: {
		[key: number]: ISlotDetails | undefined;
	}) => void;
	setconfirmationDialogProps: (dialogProps: {
		open?: boolean;
		message?: string;
		onCancel?: () => void;
		onOk?: () => void;
	}) => void;
}

const MAX_PAGES = 5;
const PAGE_SIZE = 10;

const PostWeeklyAttendanceTable = (props: IPostWeeklyAttendanceTableProps) => {
	const classes = useStyles({});
	const scrollbar = React.useRef<Scrollbars>(null);
	const [datePickerOpen, setDatePickerOpen] = React.useState<boolean>(false);
	const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
	const [gridData, setGridData] = React.useState<IWeeklyAttendance[]>([]);
	const [loadingNext, setLoadingNext] = React.useState<boolean>(false);
	const [loadingPrev, setLoadingPrev] = React.useState<boolean>(false);
	const [removePrevItems, setRemovePrevItems] = React.useState<number>(0);
	const [firstPage, setFirstPage] = React.useState<number>(0);
	const [lastPage, setLastPage] = React.useState<number>(0);
	const [endOfData, setEndOfData] = React.useState<boolean>(false);
	const [resize, setResize] = React.useState<number>(0);
	const [expandAllDate, setExpandAllDate] = React.useState<Date>();

	const closeModal = (index?: number) => {
		if (index || index === 0) {
			props.slotClick(index, undefined);
		} else {
			props.closeAllModals();
		}
		setExpandAllDate(undefined);
	};

	const closeConfirmationDialog = () => {
		props.setconfirmationDialogProps({ open: false });
	};

	const setSlotDirty = (index?: number, dirtyValue: boolean = true) => {
		if ((index || index === 0) && props.selectedSlots[index]) {
			props.slotClick(index, {
				...props.selectedSlots[index],
				dirty: dirtyValue,
			});
		}
	};

	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);
	React.useEffect(() => {
		if (userSelectedCampus) {
			props.resetFilter();
		}
	}, [userSelectedCampus]);

	const renderWrappedWeekDay = (
		date: any,
		selectedDate: any,
		dayInCurrentMonth: any
	) => {
		const dateClone = new Date(date);
		let selectedDateClone = new Date(selectedDate);

		const start = startOfWeek(selectedDateClone);
		const end = endOfWeek(selectedDateClone);

		const dayIsBetween = isWithinInterval(dateClone, { start, end });
		const isFirstDay = isSameDay(dateClone, start);
		const isLastDay = isSameDay(dateClone, end);

		const wrapperClassName = clsx({
			[classes.highlight]: dayIsBetween,
			[classes.firstHighlight]: isFirstDay,
			[classes.endHighlight]: isLastDay,
		});

		const dayClassName = clsx(classes.day, {
			[classes.nonCurrentMonthDay]: !dayInCurrentMonth,
			[classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
		});

		return (
			<div className={wrapperClassName}>
				<IconButton className={dayClassName}>
					<span> {format(dateClone, "d")} </span>
				</IconButton>
			</div>
		);
	};

	const renderWeekGridHeader = (): React.ReactNodeArray => {
		const rows: React.ReactNodeArray = [];
		const days = [
			"Sunday",
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
		];
		for (
			let start = startOfWeek(selectedDate);
			start < endOfWeek(selectedDate);
			start = addDays(start, 1)
		) {
			rows.push(
				<TableCell
					variant="head"
					component="th"
					key={`head-${start.getDay()}`}
					className={clsx(
						classes.tableHead,
						classes.tableHeadDate,
						classes.alignCenter
					)}
					onClick={() =>
						expandAllDate && isEqual(start, expandAllDate)
							? setExpandAllDate(undefined)
							: setExpandAllDate(start)
					}
				>
					<div className={classes.dateHeader}>{days[start.getDay()]}</div>
					<div className={classes.dateHeader}>{start.getDate()}</div>
				</TableCell>
			);
		}
		return rows;
	};

	const renderWeekGridValues = (
		slotItem: IWeeklyAttendance,
		index: number
	): React.ReactNodeArray => {
		const rows: React.ReactNodeArray = [];
		let i = 0;
		for (
			let start = startOfWeek(selectedDate);
			start < endOfWeek(selectedDate);
			start = addDays(start, 1)
		) {
			const record = slotItem.attendanceData.find((m) => m.dayOfWeek % 7 === i);
			i++;
			const openDate =
				props.selectedSlots &&
				props.selectedSlots[index] &&
				props.selectedSlots[index]?.date;
			const open = openDate && isEqual(openDate, start);
			rows.push(
				<TableCell
					variant="body"
					className={clsx(
						classes.alignCenter,
						record?.disabled || open
							? classes.slotCellDisabled
							: classes.slotCell,
						record?.showInvalidPunchesWarning ? classes.warningCell : ""
					)}
					key={`slot-${slotItem.studentBadgeId}-${start.getDay()}`}
					onClick={
						record?.disabled || open
							? undefined
							: () =>
									props.slotClick(index, {
										studentBadgeId: slotItem.studentBadgeId,
										studentEnrollmentId: slotItem.studentEnrollmentId,
										date: start,
										studentName: slotItem.studentName,
										tardy: record?.tardy,
										isUpdated: record?.isUpdated,
										statusCode: record?.statusCode,
										scheduleHours: record?.scheduled,
									})
					}
				>
					{record &&
						// (record.showInvalidPunchesWarning ? (<WarningOutlined />):
						(record.statusCode === "LOA" ? (
							"LOA"
						) : record.statusCode === "SUSP" ? (
							"SUSP"
						) : record.absent ? (
							<span>
								0.00
								<span className={classes.superText}>A</span>
							</span>
						) : (
							<span>
								{record.actual || record.actual == 0
									? record.actual > 24
										? null
										: record.actual.toFixed(2)
									: null}
								{record.tardy && <span className={classes.superText}>T</span>}
								{record.isUpdated && (
									<span className={classes.superIcon}>*</span>
								)}
							</span>
						))}
				</TableCell>
			);
		}
		return rows;
	};

	const refreshGridData = async () => {
		try {
			let newData: IWeeklyAttendance[] = [];
			for (let i = firstPage; i <= lastPage; i++) {
				var loaded = await getWeeklyAttendacneData(
					props.filters,
					userSelectedCampus,
					startOfWeek(selectedDate),
					i,
					PAGE_SIZE
				);
				newData = newData.concat(loaded.data);
			}
			setGridData(newData);
		} catch (error) {
			props.showSnackBar({
				variant: "error",
				showSnackBar: true,
				messageInfo: error.message,
			});
		}
	};

	let urlParams = new URLSearchParams(props.history.location.search);
	let refresh = urlParams.get("refresh");

	React.useEffect(() => {
		if (refresh) {
			refreshGridData();
			urlParams.delete("refresh");
			props.history.replace({
				...props.location,
				search: urlParams.toString(),
			});
		}
	}, [refresh]);

	const loadGridData = async (startDate: Date) => {
		let eod = false;
		try {
			setLoadingNext(true);
			var loaded = await getWeeklyAttendacneData(
				props.filters,
				userSelectedCampus,
				startDate,
				1,
				PAGE_SIZE
			);
			setGridData(loaded.data);
			setRemovePrevItems(0);
			setFirstPage(1);
			setLastPage(1);
			setEndOfData(loaded.endofData ?? false);
			eod = loaded.endofData ?? false;
		} catch (error) {
			props.showSnackBar({
				variant: "error",
				showSnackBar: true,
				messageInfo: error.message,
			});
		}

		setLoadingNext(false);
	};

	const loadNextPage = async () => {
		if (!endOfData) {
			try {
				setLoadingNext(true);
				var loaded = await getWeeklyAttendacneData(
					props.filters,
					userSelectedCampus,
					startOfWeek(selectedDate),
					lastPage + 1,
					PAGE_SIZE
				);
				let newArray = gridData.slice(
					loaded.page > MAX_PAGES ? PAGE_SIZE : 0,
					gridData.length
				);
				setGridData(newArray.concat(loaded.data));
				setRemovePrevItems(
					loaded.page > MAX_PAGES ? loaded.itemsOnCurrentPages : 0
				);
				setFirstPage(loaded.page > MAX_PAGES ? firstPage + 1 : 1);
				setLastPage(loaded.page);
				setEndOfData(loaded.endofData ?? false);
			} catch (error) {
				props.showSnackBar({
					variant: "error",
					showSnackBar: true,
					messageInfo: error.message,
				});
			}
			setLoadingNext(false);
			if (!endOfData && scrollbar.current) {
				scrollbar.current.scrollTop(scrollbar.current.getScrollTop() - 10);
			}
		}
	};

	const checkForNext = () => {
		if (!loadingNext) {
			const vls = scrollbar.current && scrollbar.current.getValues();
			if (
				lastPage > 0 &&
				lastPage - firstPage < MAX_PAGES &&
				!endOfData &&
				(!vls ||
					vls?.scrollHeight - vls?.scrollTop === vls?.clientHeight ||
					vls?.scrollHeight === vls?.clientHeight)
			) {
				loadNextPage();
			}
		}
	};

	React.useEffect(() => {
		checkForNext();
	}, [`${lastPage}-${loadingNext}-${resize}`]);

	const loadPrevPage = async () => {
		if (firstPage > 1) {
			try {
				setLoadingPrev(true);
				var loaded = await getWeeklyAttendacneData(
					props.filters,
					userSelectedCampus,
					startOfWeek(selectedDate),
					firstPage - 1,
					PAGE_SIZE
				);
				let newArray = gridData.slice(0, gridData.length - removePrevItems);
				setGridData(loaded.data.concat(newArray));
				setRemovePrevItems(PAGE_SIZE);
				setFirstPage(loaded.page);
				setLastPage(lastPage - 1);
				setEndOfData(loaded.endofData ?? false);
			} catch (error) {
				props.showSnackBar({
					variant: "error",
					showSnackBar: true,
					messageInfo: error.message,
				});
			}
			setLoadingPrev(false);
			if (firstPage !== 1 && scrollbar.current) {
				scrollbar.current.scrollTop(scrollbar.current.getScrollTop() + 2);
			}
		}
	};

	const saveScheduleDetails = async (index: number, record: any) => {
		try {
			const result = await saveDetails(record);
			props.slotClick(index, {
				...props.selectedSlots[index],
				tardy: record.tardy,
				isUpdated: record?.isUpdated,
			});
			props.showSnackBar(result);
		} catch (error) {
			props.showSnackBar({
				variant: "error",
				showSnackBar: true,
				messageInfo: error.message,
			});
		}
		await refreshGridData();
	};

	const createAttendacePunchRecord = async (
		campusId: string,
		studEnrollId: string,
		badgeNumber: string,
		punchIn?: Date,
		punchOut?: Date,
		isDistEdPunch?: boolean
	) => {
		try {
			const result = await createPunchRecord(
				campusId,
				badgeNumber,
				studEnrollId,
				punchIn,
				punchOut,
				isDistEdPunch
			);
			props.showSnackBar(result);
		} catch (error) {
			props.showSnackBar({
				variant: "error",
				showSnackBar: true,
				messageInfo: error.message,
			});
		}
		await refreshGridData();
	};

	const postAbsent = async (
		campusId: string,
		badgeNumber: string,
		date: Date
	): Promise<void> => {
		try {
			const result = await postAbsentForDay(campusId, badgeNumber, date);
			props.showSnackBar(result);
		} catch (error) {
			props.showSnackBar({
				variant: "error",
				showSnackBar: true,
				messageInfo: error.message,
			});
		}
		await refreshGridData();
	};
	const validatePunchesRow = async (
		punchId: number[],
		campusId: string,
		studEnrollId: string,
		badgeNumber: string,
		punchIn: Date,
		punchOut?: Date,
		isDistEdPunch?: boolean
	) => {
		try {
			let result = await validatePunches(
				punchId,
				campusId,
				studEnrollId,
				badgeNumber,
				punchIn,
				punchOut,
				isDistEdPunch
			);
			if (punchId.length === 1 && punchOut) {
				await createAttendacePunchRecord(
					campusId,
					studEnrollId,
					badgeNumber,
					undefined,
					punchOut,
					isDistEdPunch
				);
			} else {
				props.showSnackBar(result);
			}
		} catch (error) {
			props.showSnackBar({
				variant: "error",
				showSnackBar: true,
				messageInfo: error.message,
			});
		}
		await refreshGridData();
	};
	const updateAttendancePunchRecord = async (
		punchId: number[],
		campusId: string,
		studEnrollId: string,
		badgeNumber: string,
		punchIn: Date,
		punchOut?: Date
	) => {
		try {
			let result = await updatePunchRecord(
				punchId,
				campusId,
				studEnrollId,
				badgeNumber,
				punchIn,
				punchOut
			);
			if (punchId.length === 1 && punchOut) {
				await createAttendacePunchRecord(
					campusId,
					studEnrollId,
					badgeNumber,
					undefined,
					punchOut
				);
			} else {
				props.showSnackBar(result);
			}
		} catch (error) {
			props.showSnackBar({
				variant: "error",
				showSnackBar: true,
				messageInfo: error.message,
			});
		}
		await refreshGridData();
	};

	const deleteAttendacePunchRecord = async (
		punchId: number[],
		campusId: string,
		studentEnrollmentId: string,
		badgeNumber: string
	) => {
		try {
			const result = await deletePunchRecord(
				punchId,
				campusId,
				studentEnrollmentId,
				badgeNumber
			);
			props.showSnackBar(result);
		} catch (error) {
			props.showSnackBar({
				variant: "error",
				showSnackBar: true,
				messageInfo: error.message,
			});
		}
		await refreshGridData();
	};

	const getStudentPunchDetails = async (
		studetEnrollmentId: string,
		date: Date
	): Promise<IPostAttendacePunchRecords | undefined> => {
		try {
			const data = await getStudentAttendanceDetailsByDate(
				studetEnrollmentId,
				date
			);
			return data;
		} catch (error) {
			props.showSnackBar({
				variant: "error",
				showSnackBar: true,
				messageInfo: error.message,
			});
		}
		return undefined;
	};

	const openModals = () => {
		if (expandAllDate && expandAllDate.toString() !== "Invalid Date") {
			const values: { [key: number]: ISlotDetails | undefined } = {};
			gridData.map((gridRow, index) => {
				const dayColumn =
					gridRow.attendanceData &&
					gridRow.attendanceData.find(
						(c) => !c.disabled && c.dayOfWeek % 7 === expandAllDate.getDay() % 7
					);
				values[index] = dayColumn && {
					studentBadgeId: gridRow.studentBadgeId,
					studentName: gridRow.studentName,
					studentEnrollmentId: gridRow.studentEnrollmentId,
					date: expandAllDate,
					tardy: dayColumn.tardy,
					isUpdated: dayColumn.isUpdated,
				};
			});
			props.setBulkDetailsOpen(values);
		}
	};

	const changeDate = (check: number = 0) => {
		if (!check) {
			check =
				props.selectedSlots &&
				Object.values(props.selectedSlots).findIndex(
					(val: any) => !!val?.dirty
				) > -1
					? 1
					: 2;
		}
		if (check === 1) {
			props.setconfirmationDialogProps({
				open: true,
				message:
					"You are about to change the week. Any unsaved data will be lost. Are you sure you want to continue?",
				onOk: () => changeDate(2),
				onCancel: closeConfirmationDialog,
			});
			return;
		}
		props.applyFilters({
			...props.filters,
			startDate: startOfWeek(selectedDate),
			endDate: endOfWeek(selectedDate),
		});
	};

	React.useEffect(() => {
		if (
			selectedDate &&
			props.filters.startDate.toString() !== "Invalid Date" &&
			!isEqual(startOfWeek(selectedDate), props.filters.startDate)
		) {
			changeDate();
		}
	}, [selectedDate]);

	React.useEffect(() => {
		if (!expandAllDate || expandAllDate.toString() === "Invalid Date") {
			closeModal();
			return;
		}
		openModals();
	}, [expandAllDate]);

	React.useEffect(() => {
		openModals();
	}, [gridData]);

	React.useEffect(() => {
		setGridData([]);
		setExpandAllDate(undefined);
		closeModal();
		let startDate = startOfWeek(selectedDate);

		if (
			props.filters.startDate &&
			props.filters.startDate.toString() !== "Invalid Date" &&
			!isEqual(props.filters.startDate, startOfWeek(selectedDate))
		) {
			setSelectedDate(startOfWeek(props.filters.startDate));
			startDate = startOfWeek(props.filters.startDate);
		}
		if (
			props.filters &&
			props.filters.startDate &&
			props.filters.startDate.toString() !== "Invalid Date" &&
			props.filters.status &&
			props.filters.status.length > 0
		) {
			loadGridData(startDate);
		}
		closeConfirmationDialog();
	}, [props.filters]);

	const handleResize = () => {
		if (!loadingNext) {
			setResize(resize + 1);
		}
	};

	React.useEffect(() => {
		window.addEventListener("resize", handleResize);

		// returned function will be called on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	return (
		<Card className={classes.cardRoot} square={true}>
			{/* <Typography className={clsx(classes.cardTitle, classes.headerText)}>
        Post Weekly Attendance
        </Typography> */}
			<CardContent className={classes.cardContent}>
				<div>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<DatePicker
							format={"MMMM yyyy"}
							renderDay={renderWrappedWeekDay}
							onClose={() => setDatePickerOpen(false)}
							value={selectedDate}
							open={datePickerOpen}
							onChange={(date: any) => setSelectedDate(date)}
							TextFieldComponent={(propsParams: any) => (
								<div className={clsx(classes.calenderHeaderText)}>
									<Typography className={clsx(classes.calendarTitle)}>
										<span
											className={classes.calendarDateTitle}
											onClick={() => {
												setDatePickerOpen(true);
											}}
										>
											{propsParams.value}
										</span>
										<IconButton
											onClick={() =>
												setSelectedDate(endOfWeek(subDays(selectedDate, 7)))
											}
										>
											<KeyboardArrowLeft />
										</IconButton>{" "}
										Week {getWeek(selectedDate)}
										<IconButton
											onClick={() =>
												setSelectedDate(startOfWeek(addDays(selectedDate, 7)))
											}
										>
											<KeyboardArrowRight />
										</IconButton>
									</Typography>
								</div>
							)}
						></DatePicker>
					</MuiPickersUtilsProvider>
				</div>
				<TableContainer className={classes.tableContainer}>
					<Scrollbars
						className={classes.scroll}
						renderView={(props) => (
							<div {...props} style={{ ...props.style, overflowX: "hidden" }} />
						)}
						ref={scrollbar}
						onScrollFrame={(val: positionValues) => {
							if (!(loadingNext || loadingPrev)) {
								if (val.top >= 0.99) {
									loadNextPage();
								} else if (val.scrollTop === 0 && firstPage > 1) {
									loadPrevPage();
								}
							}
						}}
					>
						<Table stickyHeader>
							<TableHead className={classes.tableHeader}>
								<TableRow>
									<TableCell
										variant="head"
										component="th"
										className={clsx(classes.tableHead, classes.alignCenter)}
									>
										Badge ID
									</TableCell>
									<TableCell
										variant="head"
										component="th"
										className={clsx(classes.tableHead, classes.alignLeft)}
									>
										Student Name
									</TableCell>
									{renderWeekGridHeader()}
									<TableCell
										variant="head"
										component="th"
										className={clsx(classes.tableHead, classes.alignCenter)}
									>
										Weekly Total
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{loadingPrev && (
									<TableRow
										className={classes.tableRow}
										hover={false}
										selected={false}
									>
										<TableCell
											colSpan={10}
											variant="body"
											className={classes.alignCenter}
										>
											<CircularProgress />
										</TableCell>
									</TableRow>
								)}
								{gridData.map((item, index) => (
									<React.Fragment key={`wrapper-${index}`}>
										<TableRow
											className={classes.tableRow}
											hover={false}
											selected={false}
											key={`row-${index}`}
										>
											<TableCell variant="body" className={classes.alignCenter}>
												{item.studentBadgeId}
											</TableCell>
											<TableCell variant="body">{item.studentName}</TableCell>
											{renderWeekGridValues(item, index)}
											<TableCell variant="body" className={classes.alignCenter}>
												{item.weeklyTotal.toFixed(2)}
											</TableCell>
										</TableRow>
										{item.studentBadgeId ===
											props.selectedSlots[index]?.studentBadgeId &&
											item.studentEnrollmentId ===
												props.selectedSlots[index]?.studentEnrollmentId && (
												<TableRow
													className={clsx(
														classes.tableDetailsRow,
														classes.tableHeader
													)}
													hover={false}
													selected={false}
													key={`row-details-${index}`}
												>
													<TableCell
														variant="body"
														className={classes.alignCenter}
													></TableCell>
													<TableCell
														variant="body"
														className={classes.alignCenter}
													></TableCell>
													<TableCell
														colSpan={7}
														variant="body"
														className={classes.alignCenter}
													>
														<PostAttendanceModal
															setSlotDirty={(value: boolean = true) =>
																setSlotDirty(index, value)
															}
															postAbsent={postAbsent}
															saveScheduleDetails={async (record: any) => {
																await saveScheduleDetails(index, record);
															}}
															getPunchDetails={getStudentPunchDetails}
															saveNewRecord={createAttendacePunchRecord}
															deleteRecord={deleteAttendacePunchRecord}
															updateRecord={updateAttendancePunchRecord}
															validatePunches={validatePunchesRow}
															tardy={props.selectedSlots[index]?.tardy}
															isUpdated={props.selectedSlots[index]?.isUpdated}
															scheduleHours={
																props.selectedSlots[index]?.scheduleHours
															}
															studentBadgeId={
																props.selectedSlots[index]?.studentBadgeId
															}
															studentName={
																props.selectedSlots[index]?.studentName
															}
															date={props.selectedSlots[index]?.date}
															studetEnrollmentId={
																props.selectedSlots[index]?.studentEnrollmentId
															}
															statusCode={
																props.selectedSlots[index]?.statusCode
															}
															dirty={props.selectedSlots[index]?.dirty}
															onClose={() => closeModal(index)}
															showSnackBar={props.showSnackBar}
														/>
													</TableCell>
													<TableCell
														variant="body"
														className={classes.alignCenter}
													></TableCell>
												</TableRow>
											)}
									</React.Fragment>
								))}
								{loadingNext && (
									<TableRow
										className={classes.tableRow}
										hover={false}
										selected={false}
									>
										<TableCell
											rowSpan={gridData.length === 0 ? PAGE_SIZE : 1}
											colSpan={10}
											variant="body"
											className={classes.alignCenter}
										>
											<CircularProgress />
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</Scrollbars>
				</TableContainer>
			</CardContent>
		</Card>
	);
};

export default withRouter(PostWeeklyAttendanceTable);
