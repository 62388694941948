import { useSelector } from "react-redux";
export default (pathname: string) => {
	const userMenu = useSelector((state: any) => state.session.user.menu);
	const nthIndex = (str: string, pat: string, n: number) => {
		let L = str.length,
			i = -1;
		while (n-- && i++ < L) {
			i = str.indexOf(pat, i);
			if (i < 0) break;
		}
		return i;
	};
	let advantageUrl = undefined;
	const index = nthIndex(pathname, "/", 2);
	let location = pathname.substr(0, index);
	const dataParent = userMenu.pages.filter((navItem: any) => {
		return navItem.href === location;
	})[0];

	if (dataParent && dataParent.children) {
		let dataNode = (dataParent.children as any).filter((child: any) => {
			return child.href === pathname;
		})[0];

		if (dataNode === undefined) {
			if (dataParent.children !== undefined) {
				for (
					let childIndex = 0;
					childIndex <= dataParent.children.length - 1;
					childIndex++
				) {
					if (dataParent.children[childIndex].children !== undefined) {
						for (
							let grandChildIndex = 0;
							grandChildIndex <=
							dataParent.children[childIndex].children.length;
							grandChildIndex++
						) {
							let dataGrandChild: any =
								dataParent.children[childIndex].children[
									grandChildIndex
								];
							if (dataGrandChild !== undefined) {
								if (dataGrandChild.href === pathname) {
									dataNode =
										dataParent.children[childIndex].children[
											grandChildIndex
										];
									break;
								}
							}
						}
						if (dataNode !== undefined) {
							break;
						}
					}
					else  if (dataParent.children[childIndex].tabs !== undefined){
						for (
							let tabChildId = 0;
							tabChildId <= dataParent.children[childIndex].tabs.tabs.length - 1;
							tabChildId++
						) {
							if (dataParent.children[childIndex].tabs.tabs[tabChildId] !== undefined) {
								if (dataParent.children[childIndex].tabs.tabs[tabChildId].href === pathname){
									dataNode = dataParent.children[childIndex].tabs.tabs[tabChildId];
								}
							}
						}
					}
				}
			}
			
		}

		if (dataNode) {
			advantageUrl = dataNode.advantageLink + "?resid=" + dataNode.resId;
		}
	}

	return advantageUrl;
};
