import * as React from "react";
import { useEffect, useRef } from "react";
import { apiAutoCompleteConfig } from "../../constants/apiAutoComplete.config";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../constants/actions.constants";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";

const config: ApiAutoCompleteConfigItem = {
	acId: "programSchedules",
	cacheTime: 5,
	route: "/SystemCatalog/ProgramSchedule/GetByProgramVersion",
	requestType: "GET",
	body: null,
	hasUrlParams: true,
	useCache: false,
	storeInCache: true,
	isCampusSpecific: true,
	isUserSpecific: false,
	loadOnRender: true,
	defaultSelectedFirstItem: false,
	optionsMappedFx: (data: Array<any>) => {
		return data;
	},
};

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& label.Mui-focused": {
				color: theme.palette.primary.white,
			},
		},
	})
)(TextField);

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {
			width: "95%",
		},
	})
);

const ProgramScheduleAutoComplete = (
	props: FilterParams & { programVersion?: string } & {
		programVersionList?: string[];
	}
) => {
	const classes = useStyles({});
	const selectedEnrollment = useSelector(
		(state: any) => state.student.selectedEnrollment
	);
	const inputRef: any = useRef(null);

	const filterHandle = props.filterHandle;

	const scheduleOnChange = (e: any) => {
		if (filterHandle) {
			filterHandle(e);
		}
	};

	//if (selectedEnrollment)

	return (
		<div className={clsx(classes.divWidth)}>
			<ApiAutoComplete
				disabled={props.disabled}
				config={config}
				loading={
					selectedEnrollment && selectedEnrollment.programVersionId
						? false
						: true
				}
				reqParams={{
					programVersionId: props.programVersion
						? props.programVersion
						: selectedEnrollment
						? selectedEnrollment.programVersionId
						: "",
					programVersionList: props.programVersionList
						? props.programVersionList
						: undefined,
					InActive: props?.params?.inActiveSchedule
						? props?.params?.inActiveSchedule
						: false,
				}}
				classes={{
					option: "text-black",
				}}
				label={props.label ? props.label : "Time Clock Schedule"}
				onChange={(e: any) => {
					scheduleOnChange(e);
				}}
				multiple={props.multiple ? props.multiple : false}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
				inputRef={props.inputRef ? props.inputRef : inputRef}
				error={props.error}
				helperText={props.helperText}
				inputName={props.name}
			></ApiAutoComplete>
		</div>
	);
};
export default ProgramScheduleAutoComplete;
