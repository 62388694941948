import API from "../apiWrapper";
export const GetPersistentNotications = (campusId: string) => {
	return API()
		.get("/Notification/GetPersistentNotifications", {
			params: {
				campusId,
			},
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return error;
			}
		);
};
