import API from "../../apiWrapper";
import { User } from "../../../interfaces/user/User";
import { Email } from "../../../interfaces/common/Email";
import { UserRole } from "../../../interfaces/systemCatalog/UserRole";
import { Campus } from "../../../interfaces/systemCatalog/ICampus";
import { EmptyGuid } from "../../../utils/constants";
import { EnrollmentProgram } from "../../../interfaces/student/academics/EnrollmentProgram";

export const importTimeClockFile = (
	campusId: string,
	file: any,
	isAutoImport: any
) => {
	let formData = new FormData();
	formData.append("campusId", campusId);
	formData.append("file", file);
	formData.append("isAutoImport", isAutoImport);

	return API()
		.post("/AcademicRecords/Attendance/ImportTimeClockFile", formData, {
			responseType: "json",
			headers: { "Content-Type": "multipart/form-data" },
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

export const postPendingPunchesForStudents = (
	campusId: string,
	studentEnrollmentIds: Array<string>
) => {
	

	return API()
		.post("/AcademicRecords/Attendance/PostPendingPunchesAndChangeStatus", {campusId: campusId, studentEnrollmentIds: studentEnrollmentIds}, {
			responseType: "json",
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};


export const getTimeClockLogsByCampus = (campusId: string) => {
	return API()
		.get("/AcademicRecords/Attendance/GetTimeClockImportLogsByCampus", {
			params: {
				campusId,
			},
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return error;
			}
		);
};

export const GetStudentWithPendingPunches = (campusId: string) => {
	return API()
		.get("/AcademicRecords/Attendance/GetStudentWithPendingPunches", {
			params: {
				campusId,
			},
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return error;
			}
		);
};
