import * as React from "react";
import { useRef } from "react";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";
import { Enrollment } from "../../interfaces/student/academics/Enrollment";

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& label.Mui-focused": {
				color: theme.palette.primary.white,
			},
		},
	})
)(TextField);

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {},
	})
);

const config: ApiAutoCompleteConfigItem = {
	acId: "paymentPeriodTypeId",
	cacheTime: 5,
	route:
		"/AcademicRecords/ProgramVersions/GetPaymentsPeriodOptionsByPeriodType",
	requestType: "GET",
	body: null,
	hasUrlParams: true,
	useCache: false,
	storeInCache: false,
	isCampusSpecific: true,
	isUserSpecific: false,
	loadOnRender: true,
	defaultSelectedFirstItem: false,
	optionsMappedFx: (data: Array<any>) => {
		return data;
	},
};

export interface PaymentPeriodProps extends FilterParams {
	periodType: any;
}

const PaymentPeriodAutoComplete = (props: PaymentPeriodProps) => {
	const classes = useStyles({});
	const inputRef: any = useRef(null);
	const filterHandle = props.filterHandle;
	const selectedEnrollment = useSelector(
		(state: any) => state.student.selectedEnrollment
	);

	const [params, setParams] = React.useState<any | null>();

	const handleOnChange = (e: any, v: any) => {
		if (filterHandle) {
			filterHandle(e);
		}
	};

	React.useEffect(() => {
		setParams({
			...setParams,
			periodType: props.periodType,
			stuEnrollId: selectedEnrollment.stuEnrollId,
		});
	}, [props.periodType, selectedEnrollment]);

	return (
		<div className={clsx(classes.divWidth)}>
			<ApiAutoComplete
				config={config}
				loading={selectedEnrollment && props.periodType ? false : true}
				reqParams={params}
				classes={{
					option: "text-black",
				}}
				label={props.label ? props.label : "Period"}
				onChange={(e: any, v: any) => {
					handleOnChange(e, v);
				}}
				multiple={false}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
				inputRef={props.inputRef ? props.inputRef : inputRef}
				error={props.error}
				helperText={props.helperText}
				inputName={props.name}
			></ApiAutoComplete>
		</div>
	);
};
export default PaymentPeriodAutoComplete;
