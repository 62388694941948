import API from "../../apiWrapper";
import { User } from "../../../interfaces/user/User";
import { Email } from "../../../interfaces/common/Email";
import { UserRole } from "../../../interfaces/systemCatalog/UserRole";
import { Campus } from "../../../interfaces/systemCatalog/ICampus";
import { EmptyGuid } from "../../../utils/constants";
import { StudentGrades } from "../../../interfaces/student/academics/StudentGrades";
import { StudentGrade } from "../../../interfaces/student/academics/StudentGrade";
import { StudentAttempts } from "../../../interfaces/student/academics/StudentAttempts";
import IRowDetails from "../../../interfaces/setup/grades/ComponentTypes/IRowDetails";

export const UpdateCampusAwardMappings9010 = (campusId: string, IRowDetails: any[]) => {
  return API()
    .post(
      "/Reports/Operations9010/UpdateCampusAwardMappings9010?campusId=" + campusId,
      IRowDetails
    )
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const getProgramScheduleDetails = (
  scheduleId?: string,
  enrollmentId?: string
) => {
  return API()
    .get("/AcademicRecords/ProgramScheduleDetails/GetProgramScheduleDetails", {
      params: {
        scheduleId,
        enrollmentId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const geGetStudentComponentGradesInfo = (stuEnrollId: string) => {
  return API()
    .get("/AcademicRecords/Grades/GetStudentComponentGrades", {
      params: {
        stuEnrollId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};
export const GetStudentGrades = (stuEnrollId: string) => {
  return API()
    .get("/AcademicRecords/Grades/GetStudentGrades", {
      params: {
        stuEnrollId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};
export const GetStudentExams = (stuEnrollId: string) => {
  return API()
    .get("/AcademicRecords/Grades/GetStudentExams", {
      params: {
        stuEnrollId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

//Get9010MappingsByCampusId

//export const Get9010Mapping = (stuEnrollId: string) => {
export const Get9010Mapping = (campusId: string) => {
  return (
    API()
      //.get("/AcademicRecords/Grades/Get9010Mapping", {
      .get("/Reports/Operations9010/Get9010MappingsByCampusId", {
        params: {
          campusId,
        },
      })
      .then(
        (res: any) => {
          if (res && res.data) {
            return res;
          }
        },
        (error: any) => {
          return error;
        }
      )
  );
};


export const Get9010AwardTypes = () => {
  return (
    API()
      //.get("/AcademicRecords/Grades/Get9010Mapping", {
      .get("/Reports/Operations9010/Get9010AwardTypes")
      .then(
        (res: any) => {
          if (res && res.data) {
            return res;
          }
        },
        (error: any) => {
          return error;
        }
      )
  );
};





export const GetStudentAttempts = (
  stuEnrollId: string,
  InstrGrdBkWgtDetailId: string,
  excludeLast: boolean
) => {
  return API()
    .get("/AcademicRecords/Grades/GetStudentAttempts", {
      params: {
        stuEnrollId,
        InstrGrdBkWgtDetailId,
        excludeLast,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetCreditStudentAttempts = (
  stuEnrollId: string,
  instrGrdBkWgtDetailId: string,
  excludeLast: boolean
) => {
  return API()
    .get("/AcademicRecords/Grades/GetCreditStudentAttempts", {
      params: {
        stuEnrollId,
        instrGrdBkWgtDetailId,
        excludeLast,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const updateComponentAttemps = (
  attempts: StudentAttempts[],
  type: string
) => {
  return API()
    .post("/AcademicRecords/Grades/UpdateComponentAttempt", {
      ComponentAttempts: attempts,
      Type: type,
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const updateStudentGrades = (enrollment: StudentGrades) => {
  return API()
    .post("/AcademicRecords/Grades/UpdateStudentGrades", enrollment)
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const GetStudentClinicHours = (stuEnrollId: string) => {
  return API()
    .get("/AcademicRecords/Grades/GetStudentClinicHours", {
      params: {
        stuEnrollId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetStudentClinicServices = (stuEnrollId: string) => {
  return API()
    .get("/AcademicRecords/Grades/GetStudentClinicServices", {
      params: {
        stuEnrollId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};
export const CreateGrades = (stuEnrollId: string, enrollment: any[]) => {
  return API()
    .post(
      "/AcademicRecords/Grades/CreateGrades?stuEnrollId=" + stuEnrollId,
      enrollment
    )
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const deleteAttempts = (GrdBkResultIdList: any) => {
  return API()
    .delete("/AcademicRecords/Grades/DeleteComponentAttempt", {
      data: GrdBkResultIdList,
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};
