import * as React from "react";
import { useRef } from "react";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";

const config: ApiAutoCompleteConfigItem = {
	acId: "ContactType",
	cacheTime: 5,
	route: "/SystemCatalog/ContactType/GetContactTypes",
	requestType: "GET",
	body: null,
	hasUrlParams: false,
	useCache: true,
	storeInCache: false,
	isCampusSpecific: false,
	isUserSpecific: false,
	loadOnRender: true,
	defaultSelectedFirstItem: false,
	optionsMappedFx: (data: Array<any>) => {
		return data;
	},
};

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& label.Mui-focused": {
        color: theme.palette.primary.white
      }
    }
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {
      width: "95%"
    }
  })
);
export interface StateProps extends FilterParams {
  label?: string  | undefined;
}
const ContactTypeAutoComplete = (props: StateProps) => {
  const classes = useStyles({});
  const inputRef: any = useRef(null);
  const filterHandle = props.filterHandle;

  const handleOnChange = (e: any) => {
    if (props.filterHandle) {
      props.filterHandle(e);
    }
  };

  return (
    <div className={clsx(classes.divWidth)}>
      <ApiAutoComplete
        config={config}
        loading={false}
        reqParams={null}
        classes={{
          option: "text-black"
        }}
        label={props.label || "State"}
        onChange={(e: any) => {
          handleOnChange(e);
        }}
        multiple={props.multiple || false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        inputRef={props.inputRef ? props.inputRef : inputRef}
        error={props.error}
        helperText={props.helperText}
        inputName={props.name}
        maxTags={props.maxTags}
				includeAllValue={props.includeAllValue}
				showIncludeAllValue={props.showIncludeAllValue}
				chipSize={props.chipSize}
      ></ApiAutoComplete>
    </div>
  );
};
export default ContactTypeAutoComplete;
