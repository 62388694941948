import React from "react";
import { IReportOverview } from "../../../../interfaces/reports/IReportOverview";
import { useSelector } from "react-redux";
import { IIPedsMissingDataReportParams } from "../../../../interfaces/reports/IPedsMissingDataReportParams";
import { FormContext, useForm, FieldError} from "react-hook-form";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@material-ui/core";
import ReportOverview from "../../../../components/_Layout/Reports/ReportOverview";
import CampusForCurrentUserAutoComplete from "../../../../components/AutoComplete/CampusForCurrentUserAutoComplete";
import ProgramAutoComplete from "../../../../components/AutoComplete/ProgramAutoComplete";
import { Autocomplete } from "@material-ui/lab";
import InputField from "../../../../components/_Layout/Inputs/InputField";
import { getIPEDSMissingData } from "../../../../api/reports/ipeds/IPEDSFall";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";


const getReportingYearOptions = (
  yearMod: number = 1,
  yearRange: number = 1
): Array<string> => {
  let years: Array<string> = [];
  const currentYear = new Date().getFullYear();
  for (let i = yearMod; i > -9; i--) {
    const start = (currentYear + i - yearRange).toString();
    const end = (currentYear + i).toString();
    years.push(start + "-" + end);
  }
  return years;
};

const getDatesText = (reportingYear: string): string => {
  const [start, end] = reportingYear.split("-");
  return (
    new Date(Number(start) - 1, 6, 1).toLocaleDateString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    }) +
    " thru " +
    new Date(Number(end) - 1, 5, 30).toLocaleDateString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    })
  );
};

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      minHeight: "100%",
      height: "100%",
      padding: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    paperRoot: {
      padding: theme.spacing(3, 2),
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
    },
    switch: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 0.5),
      height: theme.spacing(8.6),
    },
    errorText: {
      color: "red",
    },
    datePicker:{
      width: '290px',
    }
  })
);

interface DualListBoxProps {
  availableItems: string[];
  selectedItems: string[];
}

const DualListBox: React.FC<DualListBoxProps> = ({
  availableItems,
  selectedItems,
}) => {
  const [available, setAvailable] = React.useState<string[]>(availableItems);
  const [selected, setSelected] = React.useState<string[]>(selectedItems);
  const [selectedAvailableItem, setSelectedAvailableItem] = React.useState<
    string | null
  >(null);
  const [selectedSelectedItem, setSelectedSelectedItem] = React.useState<
    string | null
  >(null);

  const moveSelectedItem = (item: string, from: string) => {
    if (from === "available") {
      setAvailable(available.filter((i) => i !== item));
      setSelected([...selected, item]);
    } else {
      setSelected(selected.filter((i) => i !== item));
      setAvailable([...available, item]);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "20px",
      }}
    >
      <div>
        <h2>Available Items</h2>
        <ul>
          {available.map((item: any) => (
            <li
              key={item}
              onClick={() => setSelectedAvailableItem(item)}
              style={{
                cursor: "pointer",
                backgroundColor:
                  selectedAvailableItem === item ? "lightblue" : "transparent",
                padding: "5px",
                border: "1px solid #ccc",
              }}
            >
              {item}
            </li>
          ))}
        </ul>
        <button
          onClick={() =>
            selectedAvailableItem &&
            moveSelectedItem(selectedAvailableItem, "available")
          }
        >
          Add &gt;
        </button>
      </div>

      <div>
        <h2>Selected Items</h2>
        <ul>
          {selected.map((item) => (
            <li
              key={item}
              onClick={() => setSelectedSelectedItem(item)}
              style={{
                cursor: "pointer",
                backgroundColor:
                  selectedSelectedItem === item ? "lightblue" : "transparent",
                padding: "5px",
                border: "1px solid #ccc",
              }}
            >
              {item}
            </li>
          ))}
        </ul>
        <button
          onClick={() =>
            selectedSelectedItem &&
            moveSelectedItem(selectedSelectedItem, "selected")
          }
        >
          &lt; Remove
        </button>
      </div>
    </div>
  );
};

const IPEDSMissingDataReport: React.FC<{ report: any }> = ({ report }) => {
  const classes = useStyles({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [previewData, setPreviewData] = React.useState<any>(undefined);
  const reportOverview = { report: report } as IReportOverview;
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );
  const data = useForm<any>({ mode: "onBlur" });

  const [model, setModel] = React.useState<IIPedsMissingDataReportParams>({
    campusId: userSelectedCampus,
    reportingYear: getReportingYearOptions()[0],
    orderBy: "StudentNumber",
    offRepDate: undefined as Date | undefined,
    excludeStuBefThisDate: undefined as Date | undefined,
    // showInactive:true,
  });

  // const handleFieldOnChange = (
  //   fieldId: keyof IIPedsReportParamsFallCIPData2,
  //   value: any,
  //   mapFunction?: Function
  // ) => {
  //   let updatedModel = model;
  //   let newValue = mapFunction ? mapFunction(value) : value;
  //   (updatedModel as any)[fieldId] = newValue;
  //   setModel({ ...updatedModel });
  // };

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    if (fieldId === "cohortYear") {
      let displayValue = +newValue + 1;
      let newSartD = "01/01/" + newValue;
      let newEndD = "12/31/" + newValue;
      //(updatedModel as any)["offRepDate"] = newSartD;
      //(updatedModel as any)["excludeStuBefThisDate"] = newEndD;
      (updatedModel as any)["cohortYear"] = displayValue.toString();
    }
    setModel({ ...updatedModel });
  };

  React.useEffect(() => {
    const currentDate = new Date();
    const defaultBeginDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const defaultEndDate = new Date(
      currentDate.getFullYear()-4,
      8,
      1
      // currentDate.getMonth(),
      // currentDate.getDate()
    );
    handleFieldOnChange("offRepDate", defaultBeginDate);
    handleFieldOnChange("excludeStuBefThisDate", defaultEndDate);
  }, []);


  const handleCampusChange = (value: any) => {
    let updatedModel = model;
    updatedModel.campusId = value;
    setModel({
      ...updatedModel,
      progId: undefined, //, progId2: undefined
    });
  };

  const { handleSubmit } = data;

  const filterHandler = (data: any) => {
    if (data) {
      setModel(data);
    } else {
      setModel({
        campusId: userSelectedCampus,
        reportingYear: getReportingYearOptions()[0],
        orderBy: "StudentNumber",
        offRepDate: undefined as Date | undefined,
        excludeStuBefThisDate: undefined as Date | undefined,
        // showInactive:true,

      });
    }
  };

  //   let reportData2:any;

  const [reportData2, setReportData2] = React.useState(null);

  // const onSubmit = async (isPreview: boolean) => {
  //   let params =
  //     (await getReportParameters()) as IIPedsReportParamsFallCIPData2;
  //   const validation = await data.triggerValidation();
  //   if (validation) {
  //     if (isPreview) {
  //       setLoading(true);
  //     }
  //     const reportData = await getFallAllCompletions_CIPData(params, isPreview);
  //     setReportData2(await getFallAllCompletions_CIPData(params, isPreview));

  //     console.log("reportDataA");
  //     console.log(reportData);

  //     if (isPreview) {
  //       if (reportData) {
  //         setPreviewData("data:application/pdf;base64," + reportData);
  //         console.log("reportDataB");
  //         console.log(reportData);
  //       }
  //       setLoading(false);
  //     }
  //   }
  // };

  const onSubmit = async (isPreview: boolean) => {
    let params = (await getReportParameters()) as IIPedsMissingDataReportParams;
    const validation = await data.triggerValidation();

    if (validation) {
      if (isPreview) {
        setLoading(true);
      }

      try {
        const reportData = await getIPEDSMissingData(params, isPreview);
        setReportData2(reportData);

        if (isPreview) {
          if (reportData) {
            setPreviewData("data:application/pdf;base64," + reportData);
          }
        }
      } catch (error) {
        console.error("Error fetching report data:", error);

        setReportData2(null);
      } finally {
        if (isPreview) {
          setLoading(false);
        }
      }
    }
  };

  React.useEffect(() => {
    if (reportData2 !== null || reportData2 !== undefined) {
      console.log("UpdatedBlah", reportData2);
    }
  }, [reportData2]);

  const getReportParameters = async () => {
    const success = await data.triggerValidation();
    if (model && success) {
      let offRepDate = model.offRepDate ? model.offRepDate : undefined;
      let EndDate = model.excludeStuBefThisDate ? model.excludeStuBefThisDate : undefined;
      let params: IIPedsMissingDataReportParams = {
        campusId: model.campusId,
        progId: model.progId,
        orderBy: model.orderBy,
        showInactive: model.showInactive,
        reportingYear: model.reportingYear,
        offRepDate: offRepDate,
        excludeStuBefThisDate: EndDate,
      };
      return params;
    }
    return {} as IIPedsMissingDataReportParams;
  };

  data.register(
    { name: "campusId" },
    {
      validate: () => {
        return model.campusId ? true : "Campus is required.";
      },
    }
  );

  data.register(
    { name: "reportingYear" },
    {
      validate: () => {
        return model.reportingYear ? true : "Reporting Year is required.";
      },
    }
  );

  data.register(
    { name: "offRepDate" },
    {
      validate: () => {
        return model.offRepDate ? true : "OffRepDate is required.";
      },
    }
  );

  data.register(
    { name: "excludeStuBefThisDate" },
    {
      validate: () => {
        return model.excludeStuBefThisDate ? true : "ExcludeStuBefThisDate is required.";
      },
    }
  );

  data.register(
    { name: "orderBy" },
    {
      validate: () => {
        return model.orderBy ? true : "Sort By is required.";
      },
    }
  );

  const items = ["Apple", "Banana", "Cherry", "Date", "Elderberry"];

  const parameters = (
    <div className={classes.root}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(() => {})}>
          <Grid container spacing={1}>
            <Grid item md={4} sm={4} xs={12}>
              <CampusForCurrentUserAutoComplete
                name="campusId"
                valueFilter={
                  model.campusId
                    ? {
                        key: "value",
                        values: [model.campusId],
                      }
                    : undefined
                }
                filterHandle={(v: any) =>
                  handleCampusChange((v && v.value) || undefined)
                }
                error={!!data.errors.campusId}
                helperText={
                  data.errors.campusId && data.errors.campusId.message
                }
                inputRef={data.register({ required: "Campus is required." })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item md={8} sm={8} xs={12}>
              <ProgramAutoComplete
                chipSize="small"
                maxTags={1}
                multiple
                name="progId"
                valueFilter={
                  model.progId
                    ? {
                        key: "value",
                        values: model.progId.split(","),
                      }
                    : undefined
                }
                filterHandle={(v: any) =>
                  handleFieldOnChange("progId", v || [], (v: any[]) =>
                    v.reduce((a, b) => ((a && a + ",") || "") + b.value, "")
                  )
                }
                activeOnly={model.showInactive ? false : true}
                //activeOnly={false}
                label="Programs"
                campusIds={model.campusId ? [model.campusId] : []}
              />
            </Grid>
            <Grid item md={3} sm={3} xs={12} className={classes.switch}>
              <FormControlLabel
                label="Show InActive Programs"
                control={
                  <Switch
                    checked={model.showInactive}
                    value="inActiveStudentGroup"
                    onChange={() =>
                      setModel({
                        ...model,
                        showInactive: !(model.showInactive || false),
                      })
                    }
                    color="primary"
                  />
                }
              />
            </Grid>
          </Grid>
          {/* <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6">
                Report Date: {getDatesText(model.reportingYear || "")}
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}>
              <Autocomplete
                renderInput={(inputProps) => {
                  return (
                    <InputField
                      name="reportingYear"
                      {...inputProps}
                      label="Reporting Year"
                      error={!!data.errors.reportingYear}
                      helperText={
                        data.errors.reportingYear &&
                        data.errors.reportingYear.message
                      }
                    />
                  );
                }}
                disableClearable
                options={getReportingYearOptions()}
                getOptionLabel={(option) => option}
                value={model.reportingYear || null}
                onChange={(_: any, v: string | null) => {
                  handleFieldOnChange("reportingYear", v);
                }}
              />
            </Grid>
          </Grid> */}

          <Grid item md={6} sm={6} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.datePicker}
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                id="offRepDate"
                name="offRepDate"
                label="Report Date"
                //value={model.offRepDate ? model.offRepDate : null}
                value={
                  model.offRepDate
                    ? model.offRepDate
                    : new Date(
                        new Date().getFullYear(),
                        new Date().getMonth(),
                        1
                      )
                }
                onChange={(e: any, value: any) =>
                  handleFieldOnChange("offRepDate", value ? value : undefined)
                }
                //className={classes.startDateField}
                KeyboardButtonProps={{
                  "aria-label": "report date",
                }}
                error={!!data.errors["offRepDate"]}
                innerRef={(ref: any) => {
                  data.register("offRepDate", {
                    required: true,
                    // validate: {
                    //   beforeEndDate: (value) =>
                    //     new Date(value) <
                    //       new Date(data.getValues()["excludeStuBefThisDate"]) ||
                    //     "Start Date must be before end date",
                    // },
                  });
                  data.setValue("offRepDate", model.offRepDate);
                }}
                helperText={
                  data.errors["offRepDate"]
                    ? (data.errors["offRepDate"] as FieldError).message
                    : undefined
                }
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item md={7} sm={6} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.datePicker}
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                defaultValue=""
                id="excludeStuBefThisDate"
                name="excludeStuBefThisDate"
                label="Exclude students who left school prior to"
                //value={model.excludeStuBefThisDate ? model.excludeStuBefThisDate : null}
                value={
                  model.excludeStuBefThisDate
                    ? model.excludeStuBefThisDate
                    : new Date(
                        new Date().getFullYear(),
                        new Date().getMonth(),
                        1
                      )
                }
                onChange={(e: any, value: any) =>
                  handleFieldOnChange("excludeStuBefThisDate", value ? value : null)
                }
                // className={classes.startDateField}
                KeyboardButtonProps={{
                  "aria-label": "end date",
                }}
                error={!!data.errors["excludeStuBefThisDate"]}
                innerRef={(ref: any) => {
                  data.register("excludeStuBefThisDate", {
                    required: true,
                    // validate: {
                    //   beforeEndDate: (value) =>
                    //     new Date(value) >
                    //       new Date(data.getValues()["offRepDate"]) ||
                    //     "End Date must be after Start date",
                    // },
                  });
                  data.setValue("excludeStuBefThisDate", model.excludeStuBefThisDate);
                }}
                helperText={
                  data.errors["excludeStuBefThisDate"]
                    ? (data.errors["excludeStuBefThisDate"] as FieldError).message
                    : undefined
                }
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Sort By</FormLabel>
                <RadioGroup
                  name="orderBy"
                  row
                  value={model.orderBy}
                  onChange={(_, v) => {
                    handleFieldOnChange("orderBy", v);
                  }}
                >
                  <FormControlLabel
                    value="StudentNumber"
                    control={<Radio color="primary" />}
                    label="Student Number"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="LastName"
                    control={<Radio color="primary" />}
                    label="Last Name"
                    labelPlacement="end"
                  />
                </RadioGroup>
                {/* <div className={classes.errorText}>
                  {reportData2 ? reportData2 : ""}
                </div> */}
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </FormContext>
    </div>
  );

  reportOverview.parameters = parameters;

  return (
    <ReportOverview
      reportOverview={reportOverview}
      filterHandler={filterHandler}
      getReportParameters={getReportParameters}
      exportHandler={onSubmit}
      previewLoader={loading}
      preview={previewData}
      isUrlPreview
    />
  );
};

export default IPEDSMissingDataReport;
