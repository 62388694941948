import API from "../apiWrapper";

export const getContractedGradDate = (
  scheduleId: string,
  startDate: string,
  campusId: string
): Promise<string> => {
  let route: string = "";
  route =
    "Common/GraduationCalculator/GetCalculatedContractedGraduationDate?scheduleId=" +
    scheduleId +
    "&startDate=" +
    startDate +
    "&campusId=" +
    campusId;
  return API()
    .get(route)
    .then(
      (res: any) => {
        return res.data;
      },
      (error: any) => {
        if (error.response) {
          throw new Error(error.response.data); // => the response payload
        }
        throw new Error("Unknown error");
      }
    );
};
