export const Download = (fileData:Blob,fileName:string = "Report.pdf") => {
    const url = window.URL.createObjectURL(fileData);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
}

const GetObjectUrl = (fileData:Blob) => {
    const url = window.URL.createObjectURL(fileData);
    return url;
}

const DownloadFromObjectUrl = (url:string,fileName:string = "Report.pdf") => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
}

const Print = (url:string,fileName:string = "Report.pdf",onFailure:any) => {
    const iframe = document.createElement("iframe");
    iframe.onload = function(){

    if (iframe.contentWindow){
        iframe.contentWindow.print();
    }
    else{
            onFailure();
        }
    };

    iframe.src = url;
    iframe.setAttribute("title", fileName);
    document.body.appendChild(iframe);
}

const GetFileExtension = (fname: string) => {
    return fname.slice((Math.max(0, fname.lastIndexOf(".")) || Infinity) + 1);
}

export const FileUtil = {
    Download,
    GetObjectUrl,
    DownloadFromObjectUrl,
    Print,
    GetFileExtension
}