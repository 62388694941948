import MaskedInput from "react-text-mask";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import { IconButton } from "@material-ui/core";
import { security } from "../../../constants/Security/securityConstants";
import { checkPermissions } from "../../../utils/securityFunctions";

type SsnMaskInputProps = {
    id:any;
    name: any;
    onChange?: any;
    className: string;
    label: string;
    error?: any;
    inputRef?: any;
    helperText?: any;
    value: number | any;
    key: any;
    showOffEyeIcon: boolean;
    control?: any;
    handleViewPlainClick: any;
    handleViewMaskClick: any;
    disabled?: boolean;
};
const useStyles = makeStyles((theme: any) =>
    createStyles({
        textField: {
            marginRight: theme.spacing(1),
            width: "100%"
        }
    })
);

const ThemeTextField = withStyles((theme: any) => createStyles({}))(TextField);
const SsnMaskInput = (props: SsnMaskInputProps) => {
    let viewPermission = checkPermissions([security.permissions.student.viewSSN]);
    const { inputRef, name, value, onChange, ...other } = props;
    const data = useFormContext();
    React.useEffect(() => {
        if (data)
            data.setValue(props.name, value);
    });
    return (
        props.control ? (
            <Controller
                control={props.control}
                key={props.key}
                name={props.name}
                as={
                    <ThemeTextField
                        className={props.className}
                        label={props.label}
                        InputLabelProps={{ shrink: true }}
                        disabled={props.showOffEyeIcon || props.disabled}
                        name={props.name + "wrapper"}
                        getInputRef={inputRef}
                         inputRef={inputRef}
                        InputProps={{
                            inputComponent: (propsInput: any) => (
                                <MaskedInput
                                    {...propsInput}
                                    id={props.name}
                                    name={props.name}
                                    key={props.key}
                                    placeholderChar={"\u2000"}
                                    showMask={true}
                                    onBlur={(e: any) => {
                                        data.setValue(props.name, e.target.value);
                                        onChange(e);
                                    }}
                                    mask={
                                        props.showOffEyeIcon
                                            ? props.value
                                                ? [
                                                    "*",
                                                    "*",
                                                    "*",
                                                    "-",
                                                    "*",
                                                    "*",
                                                    "-",
                                                    "*",
                                                    "*",
                                                    "*",
                                                    "*"
                                                ]
                                                : [""]
                                            : [
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                "-",
                                                /\d/,
                                                /\d/,
                                                "-",
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/
                                            ]
                                    }
                                />
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        {!props.showOffEyeIcon ? (
                                            <VisibilityOffOutlinedIcon
                                                id="viewMask"
                                                onClick={viewPermission ? props.handleViewPlainClick : undefined}
                                            />
                                        ) : (
                                                <VisibilityOutlinedIcon
                                                    id="viewPlain"
                                                    onClick={viewPermission ? props.handleViewMaskClick: undefined}
                                                ></VisibilityOutlinedIcon>
                                            )}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        error={props.error ? props.error : undefined}
                        helperText={props.helperText ? props.helperText : undefined}
                        value={props.value}
                    />
                }
            />
        ) : <ThemeTextField
                className={props.className}
                label={props.label}
                InputLabelProps={{ shrink: true }}
                disabled={props.showOffEyeIcon}
                name={props.name + "wrapper"}
                getInputRef={inputRef}
                  inputRef={inputRef}
                InputProps={{
                    inputComponent: (propsInput: any) => (
                        <MaskedInput
                            {...propsInput}
                            id={props.name}
                            name={props.name}
                            key={props.key}
                            placeholderChar={"\u2000"}
                            showMask={true}
                            onBlur={(e: any) => {
                                onChange(e);
                            }}
                            mask={
                                props.showOffEyeIcon
                                    ? props.value
                                        ? [
                                            "*",
                                            "*",
                                            "*",
                                            "-",
                                            "*",
                                            "*",
                                            "-",
                                            "*",
                                            "*",
                                            "*",
                                            "*"
                                        ]
                                        : [""]
                                    : [
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        "-",
                                        /\d/,
                                        /\d/,
                                        "-",
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/
                                    ]
                            }
                        />
                    )
                }}
                error={props.error ? props.error : undefined}
                helperText={props.helperText ? props.helperText : undefined}
                value={props.value}
            />);
};

export default SsnMaskInput;
