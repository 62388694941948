import * as React from 'react';
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { debounce } from '@material-ui/core/utils';
import { enrollmentSearch } from '../../api/search/studentSearchApi';
import { useSelector } from 'react-redux';
import EnrollmentSearchResultItem from '../_Layout/Search/SearchResults/StudentItemResult/EnrollmentSearchResultItem';
import { IEnrollmentSearchResult } from '../../interfaces/student/enrollment/EnrollmentSearchResult';

interface IProps {
    values: IEnrollmentSearchResult[];
    setValues: (values: IEnrollmentSearchResult[]) => void;
    label?:string;
    placeholder?:string;
}

export const EnrollmentAutoComplete = (props: IProps) => {
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<IEnrollmentSearchResult[]>([]);
    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );
    const fetch = React.useMemo(
        () =>
            debounce(
                (
                    request: { searchText: string, campusId: string, numberOfRecords: number },
                    callback: (results?: readonly IEnrollmentSearchResult[]) => void,
                ) => {
                    enrollmentSearch(request).then(res => { callback(res.result) });
                },
                400,
            ),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            //setOptions(value ? value : []);
            return undefined;
        }

        fetch({ searchText: inputValue, campusId: userSelectedCampus, numberOfRecords: 20 }, (results?: readonly IEnrollmentSearchResult[]) => {
            if (active) {
                let newOptions: IEnrollmentSearchResult[] = [];

                if (results) {
                    newOptions = [...newOptions, ...results.filter(n => (!props.values || props.values.findIndex(m => m.stuEnrollId == n.stuEnrollId) < 0))];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [props.values, inputValue, fetch]);

    return (
        <Autocomplete
            id="EnrollmentSearchAutoComplete"
            disableCloseOnSelect={true}
            getOptionLabel={(option: IEnrollmentSearchResult) =>
                typeof option === 'string' ? option : `${option.studentNumber} - ${option.studentName} (${option.programVersion})`
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={props.values}
            noOptionsText=""
            onChange={(event: any, newValue: IEnrollmentSearchResult[]) => {
                props.setValues(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} label={!!props.label ? props.label : "Enrollments"} placeholder={!!props.placeholder ? props.placeholder : 'Search Enrollments'} />
            )}
            multiple={true}
            renderOption={(props, option) => {

                return (
                    <EnrollmentSearchResultItem
                        key={"enrollment-item-result-" + props.stuEnrollId}
                        studentProfile={props}
                        onSelect={(e: any, data: any) => { }}
                    />
                );
            }}
        />
    );
}
