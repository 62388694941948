import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
	Table,
	TableBody,
	TableFooter,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Checkbox,
	Typography,
	CardContent,
	Card,
	Button,
	Grid,
	IconButton,
	Link,
	TextField,
	MenuItem,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import * as StudentGradeWidgetApi from '../../api/dashboard/StudentGradeWidgetApi';
import AccessManager from '../../components/security/AccessManager';
import { security } from '../../constants/Security/securityConstants';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RefreshIcon from '@material-ui/icons/Refresh';
import GraduationWidgetSettingsModel from './GraduationWidgetSettingsModel';
import CustomSnackbar from '../../components/notifications/CustomSnackbar';
import { CustomSnackBarProps } from '../../interfaces/common/CustomSnackBarProps';
import Select from '@material-ui/core/Select';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { withRouter } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import makeStyles from './WidgetStyles';

const useStyles = makeStyles;

function GraduationWidget(props: any) {
	const classes = useStyles();
	const [enrollmentData, setEnrollmentData] = React.useState([] as any);
	const [filteredEnrollmentData, setFilteredEnrollmentData] = React.useState(
    [] as any
	);
	const [selected, setSelected] = React.useState([] as any);
	const [settingsModelState, setSettingsModelState] = React.useState(false);

	const [newStatusListFilter, setNewStatusListFilter] = React.useState(
    [] as any
	);
	const [statusListFilter, setStatusListFilter] = React.useState([] as any);
	const [enrollmentListFilter, setEnrollmentListFilter] = React.useState(
    [] as any
	);

	const ThemeTextField = withStyles((_theme: any) =>
		createStyles({
			root: {
				'& .MuiFormLabel-root': {
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					width: '80%',
				},
			},
		})
	)(TextField);

	const isSelected = (enrollmentId: any) =>
		selected.indexOf(enrollmentId) !== -1;

	const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
		{
			showSnackBar: false,
			messageInfo: undefined,
			variant: 'info',
		}
	);
	const selectAllClick = (event: any) => {
		if (event.target.checked) {
			const selected = enrollmentData.map((n: any) => n.enrollmentId);
			setSelected(selected);
			return;
		}
		setSelected([]);
	};
	const rowClick = (_event: any, resultId: string) => {
		const selectedIndex = selected.indexOf(resultId);
		let newSelected: string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, resultId);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};

	const campusId = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	React.useEffect(() => {
		if (campusId) {
			handelReloadPage();
			GetStudentsForStatusChange();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [campusId]);

	React.useEffect(() => {
		setNewStatusListFilter([
			...new Set(enrollmentData.map((item: any) => item.enrollmentNewStatus)),
		]);
		setStatusListFilter([
			...new Set(enrollmentData.map((item: any) => item.statusCodeDescription)),
		]);
		setEnrollmentListFilter([
			...new Set(
				enrollmentData.map(
					(item: any) =>
						item.firstName +
            ' ' +
            item.lastName +
            ' (' +
            item.studentNumber +
            ')'
				)
			),
		]);
	}, [enrollmentData]);

	const GetStudentsForStatusChange = () => {
		var params = {
			campusId: campusId,
		};
		StudentGradeWidgetApi.GetEnrollmentsForWidget(params).then(
			(response: any) => {
				console.log(response);
				if (response && response.data !== null) {
					setEnrollmentData(response.data);
					setFilteredEnrollmentData(response.data);
				}
			},
			(_exception: any) => {}
		);
	};

	const handleEffectiveDateChange = (
		_fieldId: string,
		enrollmentId: string,
		value: any
	) => {
		const updatedModel = filteredEnrollmentData.map((item: any) => {
			if (item.enrollmentId === enrollmentId) {
				const updatedItem = {
					...item,
					dateOfChange: new Date(value).toLocaleDateString(),
				};
				return updatedItem;
			}
			return item;
		});
		setFilteredEnrollmentData(updatedModel);
	};

	const handelReloadPage = () => {
		resetPage();
	};

	const resetPage = () => {
		setLedger(null);
		setAcademic(null);
		setHours(null);
		setNewStatus(null);
		setCurrentStatus(null);
		setLDA(null);
		setEnrollment(null);

		setSelected([]);
		GetStudentsForStatusChange();
	};

	const openSettingsModel = () => {
		setSettingsModelState(true);
	};

	const [ledger, setLedger] = React.useState(null);
	const [academic, setAcademic] = React.useState(null);
	const [hours, setHours] = React.useState(null);
	const [newStatus, setNewStatus] = React.useState(null);
	const [currentStatus, setCurrentStatus] = React.useState(null);
	const [LDA, setLDA] = React.useState(null);
	const [enrollment, setEnrollment] = React.useState(null);

	const handleChangeSearch = (value: any, filterName: any) => {
		switch (filterName) {
		case 'Ledger':
			setLedger(value);
			break;
		case 'Academic':
			setAcademic(value);
			break;
		case 'Hours':
			setHours(value);
			break;
		case 'NewStatus':
			setNewStatus(value);
			break;
		case 'CurrentStatus':
			setCurrentStatus(value);
			break;
		case 'LDA':
			setLDA(value);
			break;
		case 'Enrollment':
			setEnrollment(value);
			break;
		}
	};

	React.useEffect(() => {
		const results = enrollmentData.filter((item: any) => {
			return (
				(ledger !== null
					? String(item.isNoLedgerBalance) === String(ledger)
					: true) &&
        (academic !== null
        	? String(item.isAcademicReqCompleted) === String(academic)
        	: true) &&
        (hours !== null
        	? String(item.isHoursCompleted) === String(hours)
        	: true) &&
        (newStatus !== null
        	? String(item.enrollmentNewStatus) === String(newStatus)
        	: true) &&
        (currentStatus !== null
        	? String(item.statusCodeDescription) === String(currentStatus)
        	: true) &&
        (LDA !== null
        	? String(new Date(item.lastDateAttended).toLocaleDateString()) ===
            String(new Date(LDA || 0).toLocaleDateString())
        	: true) &&
        (enrollment !== null
        	? String(
        		item.firstName +
                ' ' +
                item.lastName +
                ' (' +
                item.studentNumber +
                ')'
        	) === String(enrollment)
        	: true)
			);
		});
		setFilteredEnrollmentData(results);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ledger, academic, hours, newStatus, currentStatus, LDA, enrollment]);

	const updateStatus = () => {
		if (selected && selected.length > 0) {
			var newStatusList: any = [];
			selected.map((enrollmentId: any) => {
				var item = filteredEnrollmentData.find(
					(x: any) => x.enrollmentId === enrollmentId
				);
				if (item.enrollmentNewStatusCodeId !== null) {
					newStatusList.push({
						enrollmentId: enrollmentId,
						enrollmentNewStatusCodeId: item.enrollmentNewStatusCodeId,
						dateOfChange: item.dateOfChange,
					});
				}
			});
			if (newStatusList.length > 0) {
				StudentGradeWidgetApi.UpdateEnrollmentStatus(newStatusList).then(
					(response: any) => {
						if (response.status === 200 && response.data?.result === true) {
							resetPage();

							setSnackBarProps((_props: any) => {
								return {
									variant: 'success',
									showSnackBar: true,
									messageInfo: 'Status successfully updated.',
								};
							});
						} else {
							setSnackBarProps((_props: any) => {
								return {
									variant: 'error',
									showSnackBar: true,
									messageInfo: 'Status could not be updated. Please try again.',
								};
							});
						}
					},
					(_exception: any) => {}
				);
			}
		}
	};
	return (
		<AccessManager
			allowedPermissions={[security.permissions.dashboard.viewGraduationWidget]}
			renderNoAccess={() => null}
		>
			<Card className={classes.root}>
				<Typography className={clsx(classes.cardTitle)}>
					<Grid container direction="row" justify="center" alignItems="center">
						<Grid item xs={6}>
							<div className={classes.headerText}>GRADUATE STUDENTS</div>
						</Grid>

						<Grid item xs={6} className={classes.widgetMenu}>
							<Typography className={classes.headerTextRight}>
                Students: {enrollmentData.length}
							</Typography>
							<IconButton
								className={classes.iconBtn}
								aria-label="settings"
								onClick={handelReloadPage}
							>
								<RefreshIcon />
							</IconButton>

							<AccessManager
								allowedPermissions={[
									security.permissions.dashboard
										.viewGraduationWidgetStatusSettings,
								]}
								renderNoAccess={() => (
									<IconButton
										className={classes.iconBtn}
										disabled={true}
										aria-label="settings"
									>
										<MoreVertIcon />
									</IconButton>
								)}
							>
								<IconButton
									className={classes.iconBtn}
									aria-label="settings"
									onClick={openSettingsModel}
								>
									<MoreVertIcon />
								</IconButton>
							</AccessManager>
						</Grid>
					</Grid>
				</Typography>
				<CardContent className={classes.cardContent}>
					<div className={classes.innerRoot}>
						<TableContainer component={Paper}>
							<Table
								className={classes.table}
								size="small"
								aria-label="a dense table"
							>
								<TableHead>
									<TableRow>
										<TableCell
											align="center"
											padding="checkbox"
											className={classes.headCell}
										>
											<Checkbox
												value="uncontrolled"
												color="primary"
												onClick={selectAllClick}
												inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
											/>
										</TableCell>
										<TableCell align="left" className={classes.headCell}>
                      Enrollment
										</TableCell>
										<TableCell align="left" className={classes.headCell}>
                      LDA
										</TableCell>
										<TableCell align="left" className={classes.headCell}>
                      Current Status
										</TableCell>
										<TableCell align="left" className={classes.headCell}>
                      New Status
										</TableCell>
										<TableCell align="left" className={classes.headCell}>
                      Effective Date
										</TableCell>
										<TableCell align="left" className={classes.headCell}>
                      Hrs
										</TableCell>
										<TableCell align="left" className={classes.headCell}>
                      Acad Req.
										</TableCell>
										<TableCell align="left" className={classes.headCell}>
                      No Ledger Balance
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell align="left"></TableCell>
										<TableCell align="left">
											<Select
												className={classes.headerFilter}
												value={enrollment}
												onChange={(event) =>
													handleChangeSearch(event.target.value, 'Enrollment')
												}
											>
												{enrollmentListFilter.map(
													(item: any, index: number) => {
														return (
															<MenuItem key={`item-${index}`} value={item}>
																{item}
															</MenuItem>
														);
													}
												)}
											</Select>
										</TableCell>
										<TableCell align="left">
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<KeyboardDatePicker
													disableToolbar
													variant="inline"
													clearable
													format="MM/dd/yyyy"
													placeholder="mm/dd/yyyy"
													TextFieldComponent={ThemeTextField}
													fullWidth
													autoOk={true}
													KeyboardButtonProps={{
														'aria-label': 'change date',
													}}
													value={LDA}
													onChange={(val) => {
														handleChangeSearch(val ? val : null, 'LDA');
													}}
												/>
											</MuiPickersUtilsProvider>
										</TableCell>
										<TableCell align="left">
											<Select
												className={classes.headerFilter}
												value={currentStatus}
												onChange={(event) =>
													handleChangeSearch(
														event.target.value,
														'CurrentStatus'
													)
												}
											>
												{statusListFilter.map((item: any, index: number) => {
													return (
														<MenuItem key={`item-${index}`} value={item}>
															{item}
														</MenuItem>
													);
												})}
											</Select>
										</TableCell>
										<TableCell align="left">
											<Select
												className={classes.headerFilter}
												value={newStatus}
												onChange={(event) =>
													handleChangeSearch(event.target.value, 'NewStatus')
												}
											>
												{newStatusListFilter.map((item: any, index: number) => {
													return (
														<MenuItem key={`item-${index}`} value={item}>
															{item}
														</MenuItem>
													);
												})}
											</Select>
										</TableCell>
										<TableCell align="left"> </TableCell>
										<TableCell align="left">
											<Select
												className={classes.headerFilter}
												value={hours}
												onChange={(event) =>
													handleChangeSearch(event.target.value, 'Hours')
												}
											>
												<MenuItem value="true">Yes</MenuItem>
												<MenuItem value="false">No</MenuItem>
											</Select>
										</TableCell>
										<TableCell align="left">
											<Select
												className={classes.headerFilter}
												value={academic}
												onChange={(event) =>
													handleChangeSearch(event.target.value, 'Academic')
												}
											>
												<MenuItem value="true">Yes</MenuItem>
												<MenuItem value="false">No</MenuItem>
											</Select>
										</TableCell>
										<TableCell align="left">
											<Select
												className={classes.headerFilter}
												value={ledger}
												onChange={(event) =>
													handleChangeSearch(event.target.value, 'Ledger')
												}
											>
												<MenuItem value="true">Yes</MenuItem>
												<MenuItem value="false">No</MenuItem>
											</Select>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{filteredEnrollmentData !== null &&
                  filteredEnrollmentData.length > 0 ? (
											filteredEnrollmentData.map((row: any) => {
												const isRowSelected = isSelected(row.enrollmentId);
												return (
													<TableRow
														key={row.enrollmentId}
														role="checkbox"
														selected={isRowSelected}
													>
														<TableCell align="center" padding="checkbox">
															<Checkbox
																className={classes.checkBoxStyle}
																value="uncontrolled"
																color="primary"
																onClick={(event) =>
																	rowClick(event, row.enrollmentId)
																}
																inputProps={{
																	'aria-label': 'uncontrolled-checkbox',
																}}
																checked={isRowSelected}
															/>
														</TableCell>
														<TableCell align="left">
															<Link
																className={classes.linkDesign}
																component="button"
																onClick={() => {
																	let urlParams = new URLSearchParams(
																		props.history.location.search
																	);
																	let studentIdQueryId = 'studentId';
																	let campusIdQueryId = 'campusId';

																	//set url parameters for campusId and studentId
																	urlParams.set(
																		studentIdQueryId,
                                  row.studentId as any
																	);
																	urlParams.set(campusIdQueryId, campusId);
																	props.history.push({
																		pathname: '/student/1/profile/info',
																		search: urlParams.toString(),
																	});
																}}
															>
																{row.firstName +
                                ' ' +
                                row.lastName +
                                ' (' +
                                row.studentNumber +
                                ')'}
															</Link>
														</TableCell>
														<TableCell align="left">
															{new Date(
																row.lastDateAttended
															).toLocaleDateString()}
														</TableCell>
														<TableCell align="left">
															{row.statusCodeDescription}
														</TableCell>
														<TableCell align="left">
															{row.enrollmentNewStatus}
														</TableCell>
														<TableCell align="left">
															<MuiPickersUtilsProvider utils={DateFnsUtils}>
																<KeyboardDatePicker
																	disableToolbar
																	variant="inline"
																	id={'dateOfChangeId' + row.enrollmentId}
																	key={'dateOfChangeKey' + row.enrollmentId}
																	format="MM/dd/yyyy"
																	placeholder="mm/dd/yyyy"
																	TextFieldComponent={ThemeTextField}
																	fullWidth
																	autoOk={true}
																	KeyboardButtonProps={{
																		'aria-label': 'change date',
																	}}
																	value={row.dateOfChange}
																	onChange={(val) => {
																		handleEffectiveDateChange(
																			'dateOfChange',
																			row.enrollmentId,
																			val ? val : null
																		);
																	}}
																/>
															</MuiPickersUtilsProvider>
														</TableCell>
														<TableCell align="left">
															<Checkbox
																className={classes.checkBoxStyle}
																disabled={true}
																value="uncontrolled"
																color="primary"
																inputProps={{
																	'aria-label': 'uncontrolled-checkbox',
																}}
																checked={row.isHoursCompleted}
															/>
														</TableCell>
														<TableCell align="left">
															<Checkbox
																className={classes.checkBoxStyle}
																disabled={true}
																value="uncontrolled"
																color="primary"
																inputProps={{
																	'aria-label': 'uncontrolled-checkbox',
																}}
																checked={row.isAcademicReqCompleted}
															/>
														</TableCell>
														<TableCell align="left">
															<Checkbox
																className={classes.checkBoxStyle}
																disabled={true}
																value="uncontrolled"
																color="primary"
																inputProps={{
																	'aria-label': 'uncontrolled-checkbox',
																}}
																checked={row.isNoLedgerBalance}
															/>
														</TableCell>
													</TableRow>
												);
											})
										) : (
											<TableRow>
												<TableCell align="center" colSpan={9}>
                        No Data
												</TableCell>
											</TableRow>
										)}
								</TableBody>
								<TableFooter></TableFooter>
							</Table>
						</TableContainer>
						<AccessManager
							allowedPermissions={[
								security.permissions.dashboard.updateGraduationWidget,
							]}
							renderNoAccess={() => (
								<Button
									disabled={true}
									onClick={() => {
										updateStatus();
									}}
									size="small"
									className={classes.button}
									type="button"
									color="primary"
								>
                  CHANGE STATUS
								</Button>
							)}
						>
							<Button
								className={classes.buttonColorPrimary}
								onClick={() => {
									updateStatus();
								}}
								size="small"
								type="button"
							>
                CHANGE STATUS
							</Button>
						</AccessManager>
					</div>
				</CardContent>
				<GraduationWidgetSettingsModel
					open={settingsModelState}
					setOpen={setSettingsModelState}
				/>
				<CustomSnackbar
					variant={snackBarProps.variant}
					message={snackBarProps.messageInfo}
					open={snackBarProps.showSnackBar}
					onClose={(_event?: React.SyntheticEvent, _reason?: string) => {
						setSnackBarProps((props: any) => {
							return { ...props, showSnackBar: false };
						});
					}}
				></CustomSnackbar>
			</Card>
		</AccessManager>
	);
}
GraduationWidget.propTypes = {
	className: PropTypes.string,
	params: PropTypes.object,
};
export default withRouter(GraduationWidget);
