import React from "react";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import {
	Grid,
	TextField,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	Button,
} from "@material-ui/core";
import Charge from "./transactionTypes/Charge";
import FinancialAidDisbursement from "./transactionTypes/FinancialAidDisbursement";
import StudentPayment from "./transactionTypes/StudentPayment";
import TitleIVStudentStipend from "./transactionTypes/TitleIVStudentStipend";
import RefundToStudent from "./transactionTypes/RefundToStudent";
import TransactionCodeAutoComplete from "../../../../components/AutoComplete/TransactionCodeAutoComplete";
import { IFinancialAidDisbursement } from "../../../../interfaces/student/financials/IFinancialAidDisbursement";
import { IStudentPayment } from "../../../../interfaces/student/financials/IStudentPayment";
import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			"& .MuiPaper-rounded": {
				borderRadius: 0,
			},
			"& .MuiDialogContent-root": {
				minHeight: 250,
			},
		},
		dialogHeader: {
			backgroundColor: theme.palette.site.secondary,
			"& .MuiTypography-root": {
				fontWeight: theme.typography.fontWeightBold,
				fontSize: "12pt",
			},
		},
		buttonSection: {
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(3),
		},
		saveButton: {
			width: 90,
		},
	})
);

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& .MuiFormLabel-root": {
				textOverflow: "ellipsis",
				whiteSpace: "nowrap",
				overflow: "hidden",
				width: "80%",
			},
		},
	})
)(TextField);

type PostTransactionProps = {
	refreshLedgerGrid: any;
	setOpen: any;
	open: boolean;
	setSnackBar: any;
	enrollmentId: string;
	campusId: string;
	prefixDialogComponent?: () => React.ReactNode;
};

const PostTransaction = (props: PostTransactionProps) => {
	const classes = useStyles({});

	const handleClose = () => {
		if (transactionType == null) {
			props.setOpen(false);
		} else {
			setTitle("Post Transaction");
			setTransactionType(null);
		}
	};

	const [title, setTitle] = React.useState("Post Transaction");
	const [cancelButtonVisible, setCancelButtonVisible] = React.useState(true);
	const [transactionType, setTransactionType] = React.useState<any | null>(
		null
	);

	const handleTransactionTypeChange = (newValue: any) => {
		setTransactionType(newValue);
		if (!newValue) {
			setTitle("Post Transaction");
			setCancelButtonVisible(true);
			return;
		} else {
			setCancelButtonVisible(false);
		}
		 let CreditDebitTitle = " - Debit"
		 if(newValue.creditDebit === 2)
		 {
			CreditDebitTitle = " - Credit"
		 }
		switch (newValue.ledgerPostingType) {
			case 1: {
				setTitle("Post Refund" + CreditDebitTitle);
				break;
			}
			case 2: {
				setTitle("Post Student Stipend"+ CreditDebitTitle);
				break;
			}
			case 3: {
				setTitle("Disbursement Refund"+ CreditDebitTitle);
				break;
			}
			case 4: {
				setTitle("Post Disbursement"+ CreditDebitTitle);
				break;
			}
			case 5: {
				setTitle("Post Charge"+ CreditDebitTitle);
				break;
			}
			case 6: {
				setTitle("Post Payment"+ CreditDebitTitle);
				break;
			}
			default: {
				setTitle("Post Transaction"+ CreditDebitTitle);
			}
		}
	};

	const getLedgerPostingSection = (item: any) => {
		if (!item) return null;
		switch (item.ledgerPostingType) {
			case 1: {
				return (
					<RefundToStudent
						setPopupState={props.setOpen}
						setTransactionTypeValue={setTransactionType}
						transactionType={transactionType}
						setSnackBar={props.setSnackBar}
						refreshLedgerItems={props.refreshLedgerGrid}
						enrollmentId={props.enrollmentId}
						creditDebit={item.creditDebit}
						
					/>
				);
			}
			case 2: {
				return (
					<TitleIVStudentStipend
						setPopupState={props.setOpen}
						setTransactionTypeValue={setTransactionType}
						transactionType={transactionType}
						setSnackBar={props.setSnackBar}
						refreshLedgerItems={props.refreshLedgerGrid}
						enrollmentId={props.enrollmentId}
						creditDebit={item.creditDebit}
					/>
				);
			}
			case 3: {
				return (
					<FinancialAidDisbursement
						model={{} as IFinancialAidDisbursement}
						setPopupState={props.setOpen}
						setTransactionTypeValue={setTransactionType}
						isRefund={true}
						transactionType={transactionType}
						refreshLedgerItems={props.refreshLedgerGrid}
						setSnackBar={props.setSnackBar}
						enrollmentId={props.enrollmentId}
						creditDebit={item.creditDebit}
					/>
				);
			}
			case 4: {
				return (
					<FinancialAidDisbursement
						model={{} as IFinancialAidDisbursement}
						setPopupState={props.setOpen}
						setTransactionTypeValue={setTransactionType}
						transactionType={transactionType}
						isRefund={false}
						refreshLedgerItems={props.refreshLedgerGrid}
						setSnackBar={props.setSnackBar}
						enrollmentId={props.enrollmentId}
						creditDebit={item.creditDebit}
					/>
				);
			}
			case 5: {
				return (
					<Charge
						setPopupState={props.setOpen}
						setTransactionTypeValue={setTransactionType}
						transactionType={transactionType}
						setSnackBar={props.setSnackBar}
						refreshLedgerItems={props.refreshLedgerGrid}
						enrollmentId={props.enrollmentId}
						creditDebit={item.creditDebit}
					/>
				);
			}
			case 6: {
				return (
					<StudentPayment
						model={{} as IStudentPayment}
						setPopupState={props.setOpen}
						setTransactionTypeValue={setTransactionType}
						transactionType={transactionType}
						refreshLedgerItems={props.refreshLedgerGrid}
						setSnackBar={props.setSnackBar}
						enrollmentId={props.enrollmentId}
						campusId={props.campusId}
						creditDebit={item.creditDebit}
					/>
				);
			}
			default: {
			}
		}
	};

	return (
		<div>
			<Dialog
				open={props.open}
				onClose={handleClose}
				className={classes.root}
				aria-labelledby="form-dialog-title"
				maxWidth="xs"
				fullWidth={true}
				disableBackdropClick
			>
				<DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
					{title}
				</DialogTitle>
				<DialogContent>
					{props.prefixDialogComponent && props.prefixDialogComponent()}
					<Grid container direction="row" spacing={2}>
						<Grid item xs={12}>
							<TransactionCodeAutoComplete
								id="transactionType"
								name="transactionType"
								filterHandle={(e: any) => {
									handleTransactionTypeChange(e);
								}}
								valueFilter={
									transactionType
										? {
												key: "value",
												values: [transactionType.value],
										  }
										: undefined
								}
							/>
						</Grid>
					</Grid>
					<Grid container direction="row" spacing={2}>
						<Grid item xs={12}>
							<div>{getLedgerPostingSection(transactionType)}</div>
						</Grid>
					</Grid>
				</DialogContent>
				{cancelButtonVisible && (
					<Grid item xs={12}>
						<DialogActions className={classes.buttonSection}>
							<Grid container direction="row" spacing={1}>
								<Grid item xs={2}></Grid>
								<Grid
									item
									container
									xs={4}
									alignContent="center"
									alignItems="center"
									justify="center"
								>
									<ProgressSaveButton
										text="Save"
										onClick={() => {}}
										loading={false}
										disabled={false}
										buttonClassName={classes.saveButton}
									></ProgressSaveButton>
								</Grid>
								<Grid
									item
									container
									xs={4}
									alignContent="center"
									alignItems="center"
									justify="center"
								>
									<Button
										onClick={handleClose}
										color="secondary"
										variant="contained"
										type="button"
									>
										Cancel
									</Button>
								</Grid>
								<Grid item xs={2}></Grid>
							</Grid>
						</DialogActions>
					</Grid>
				)}
			</Dialog>
		</div>
	);
};

export default PostTransaction;
