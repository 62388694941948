import * as React from "react";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { forIn } from "lodash";


const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    divWidth: {
      width: "95%"
    }
  })
);

const YearAutoComplete = (props: FilterParams) => {
  const classes = useStyles({});

  const handleOnChange = (e: any) => {
    if (props.filterHandle) {
      props.filterHandle(e);
    }
  };

  const currentYear = (new Date()).getFullYear();
  const [options, setOptions] = React.useState<any>({text:currentYear.toString(), value:currentYear});

  React.useEffect(() => {
    let ops = [];
    if(!!props.params.offset){
        for(let year = currentYear + props.params.offset; year >= (currentYear - props.params.offset); year--){
            ops.push({text:year.toString(), value:year});
        }
    }
    if(!!props.params.t1098){
      for(let year = currentYear - 6; year <= (currentYear ); year++)
      {  
          ops.push({text:year.toString(), value:year});
      }
      ops.sort((a, b) =>  b.value - a.value);
  }
    setOptions([...ops]);
  }, [props.params])

  return (
    <div className={clsx(classes.divWidth)}>
      <ApiAutoComplete
        config={{
            acId: "Year",
            cacheTime: 20,
            route: "",
            requestType: "",
            body: null,
            hasUrlParams: false,
            useCache: true,
            storeInCache: true,
            isCampusSpecific: false,
            isUserSpecific: false,
            loadOnRender: true,
            defaultSelectedFirstItem: false,
            optionsMappedFx: (data: Array<any>) => {
                return data;
            }
            
        }}
        options={options}
        classes={{
          option: "text-black"
        }}
        label={props.hideLabel ? undefined : (props.label ?? 'Year')}
        onChange={(e: any) => {
          handleOnChange(e);
        }}
        disabled={props.disabled}
        inputRef={props.inputRef}
        error={props.error}
        helperText={props.helperText}
        inputName={props.name}
        multiple={props.multiple || false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        maxTags={props.maxTags}
        includeAllValue={props.includeAllValue}
        showIncludeAllValue={props.showIncludeAllValue}
        chipSize={props.chipSize}
        reqParams={props.params}
      ></ApiAutoComplete>
    </div>
  );
};
export default YearAutoComplete;
