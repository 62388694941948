import * as React from "react";
import { useRef } from "react";
import { StatesApiAutoComplete } from "../../constants/SystemCatalog/StatesApiAutoComplete";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { FilterParams } from "../../interfaces/common/FilterParams";

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& label.Mui-focused": {
        color: theme.palette.primary.white
      }
    }
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {
      width: "95%"
    }
  })
);
export interface StateProps extends FilterParams {
  countryId: string | null | undefined;
  label?: string  | undefined;
}
const StatesAutoComplete = (props: StateProps) => {
  const classes = useStyles({});
  const inputRef: any = useRef(null);
  //const [countryId, setCountryId] = React.useState();
  //const [StateSelected, setStateSelected] = React.useState();
  const filterHandle = props.filterHandle;

  const handleOnChange = (e: any) => {
    if (props.filterHandle) {
      props.filterHandle(e);
    }
  };

  return (
    <div className={clsx(classes.divWidth)}>
      <ApiAutoComplete
        config={StatesApiAutoComplete.config}
        loading={props.countryId ? false : true}
        // requiredParameterMissingMessage={
        //   props.countryId ? undefined : "Select Country"
        // }
        reqParams={props.countryId ? { countryId: props.countryId } : null}
        classes={{
          option: "text-black"
        }}
        label={props.label || "State"}
        onChange={(e: any) => {
          handleOnChange(e);
        }}
        multiple={props.multiple || false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        inputRef={props.inputRef ? props.inputRef : inputRef}
        error={props.error}
        helperText={props.helperText}
        inputName={props.name}
        maxTags={props.maxTags}
				includeAllValue={props.includeAllValue}
				showIncludeAllValue={props.showIncludeAllValue}
				chipSize={props.chipSize}
      ></ApiAutoComplete>
    </div>
  );
};
export default StatesAutoComplete;
