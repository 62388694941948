import { AxiosError } from 'axios';
import { useState } from 'react';

type IAPIFunction<ReturnTpe> = (...args: any[]) => Promise<ReturnTpe>;

const APIHook = <RT, F extends IAPIFunction<RT>>(
	f: F,
): [RT | undefined, string | undefined, number | undefined, boolean, (...args: Parameters<F>) => Promise<void>, () => void, (data: React.SetStateAction<RT | undefined>) => void] => {
	const [data, setData] = useState<RT>();
	const [error, setError] = useState<string>();
	const [loading, setLoading] = useState<boolean>(false);
	const [statusCode, setStatusCode] = useState<number>();

	const reset = () => {
		setData(undefined);
		setError(undefined);
		setLoading(false);
		setStatusCode(undefined);
	};

	const call = async (...args: Parameters<F>) => {
		setLoading(true);
		try {
			setStatusCode(undefined);
			setError(undefined);
			const result = await f(...args);
			setData(result);
			setStatusCode(200);
		} catch (e) {
			setError((e as AxiosError<{ message: string }>).response?.data?.message || 'Something went wrong');
			setStatusCode((e as AxiosError<{ message: string }>).response?.status || 500);
		} finally {
			setLoading(false);
		}
	};

	const updateData = (data: React.SetStateAction<RT | undefined>) => {
		setData(data);
	};
	return [data, error, statusCode, loading, call, reset, updateData];
};

export default APIHook;