import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
	Typography,
	Card,
	CardContent
} from "@material-ui/core";
import DisplayField from "../DisplayField/DisplayField";
import * as quickInfoApi from "../../../api/student/quickInfo/quickInfoApi";
import { IPaymentPlanCard } from "../../../interfaces/student/quickInfo/IPaymentPlanCard";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		card: {
			margin: theme.spacing(1),
			marginTop: theme.spacing(2),
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingBottom: theme.spacing(1),
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2)
			}
		},
		cardTitle: {
			fontSize: 14,
			marginLeft: theme.spacing(1),
			marginTop: theme.spacing(1),
			color: theme.palette.text.secondary,
			fontWeight: theme.typography.fontWeightMedium
		}
	})
);

type IPaymentPlanCardData = {
	monthlyPayment: any;
	firstPaymentDueDate: any;
	lastPaymentAmount: any;
	lastPaymentDate: any;
	totalPlanAmount: any;
	currentPlanBalanceOwed: any;
};

const PaymentPlanCard = (props: any) => {
	const { className } = props;

	const classes = useStyles({});

	const selectedEnrollment = useSelector(
		(state: any) => state.student.selectedEnrollment
	);

	const [
		paymentPlanData,
		setPaymentPlanData
	] = useState<IPaymentPlanCard | null>(null);

	const GetPaymentPlanInformation = () => {
		if (selectedEnrollment && selectedEnrollment.stuEnrollId)
			quickInfoApi
				.GetPaymentPlanQuickInformation(selectedEnrollment.stuEnrollId)
				.then(response => {
					if (response) {
						setPaymentPlanData(response.data.result);
					}
				});
	};

	useEffect(() => {
		GetPaymentPlanInformation();
	}, [selectedEnrollment]);

	return (
		<Card className={classes.card} elevation={2}>
			<Typography className={clsx(classes.cardTitle)}><Link to={"/student/1/financials/paymentPlans"}>PAYMENT PLAN</Link></Typography>
			<CardContent>
				<DisplayField
					labelText="Monthly Payment"
					valueText={paymentPlanData?.monthlyPaymentAmountAsString}
					fieldType="text"
				></DisplayField>
				<DisplayField
					labelText="First Payment Due Date"
					valueText={paymentPlanData?.firstDueDateAsString}
					fieldType="text"
				></DisplayField>
				<DisplayField
					labelText="Last Payment Amount"
					valueText={paymentPlanData?.lastPaymentAmountAsString}
					fieldType="text"
				></DisplayField>
				<DisplayField
					labelText="Last Payment Date"
					valueText={paymentPlanData?.lastPaymentDateAsString}
					fieldType="text"
				></DisplayField>
				<DisplayField
					labelText="Total Plan Amount"
					valueText={paymentPlanData?.planTotalAmountAsString}
					fieldType="text"
				></DisplayField>
				<DisplayField
					labelText="Current Plan Balance Owed"
					valueText={paymentPlanData?.balanceOweAmountAsString}
					fieldType="text"
				></DisplayField>
			</CardContent>
		</Card>
	);
};

export default PaymentPlanCard;
