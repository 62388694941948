import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Typography, Paper } from "@material-ui/core";
import ClinicServicesTable from "./ClinicServicesTable";
import Button from "@material-ui/core/Button";
import { StudentGrade } from "../../../interfaces/student/academics/StudentGrade";
import * as StudentGradesApi from "../../../../src/api/student/academics/StudentGradesApi";
import { useSelector } from "react-redux";
import { useForm, FormContext } from "react-hook-form";
import CustomSnackbar from "../../../components/notifications/CustomSnackbar";
import { CustomSnackBarProps } from "../../../interfaces/common/CustomSnackBarProps";
import ProgressSaveButton from "../../../components/_Layout/Buttons/ProgressSaveButton";
import AccessManager from "../../../components/security/AccessManager";
import { security } from "../../../constants/Security/securityConstants";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			height: "95%",
			minHeight: "100%",
			paddingTop: theme.spacing(2),
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			paddingBottom: theme.spacing(2),
			overflowX: "hidden",
			overflowY: "auto"
		},
		componentActions: {
			marginTop: theme.spacing(3)
		},
		gradesWrapper: {
			height: "100%",
			overflowY: "auto",
			padding: "3px"
		},
		form: {
			height: "100%"
		}
	})
);
type ICliniServiceProps = {
	setConfirmationModal: any;
};
const ClinicServicesPage = (props: ICliniServiceProps) => {
	const classes = useStyles({});

	const selectedEnrollment = useSelector(
		(state: any) => state.student.selectedEnrollment
	);
	const methods = useForm<any>({
		mode: "onBlur"
	});
	const { register, handleSubmit } = methods;
	const filterData = (data: StudentGrade[]): StudentGrade[] => {
		return data.filter(function(service: StudentGrade) {
			return service && service.isDirty;
		});
	};

	const [loaderState, setLoaderState] = React.useState<boolean>(false);
	const [validationError, setValidationError] = React.useState<boolean>(false);
	const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
		{
			showSnackBar: false,
			messageInfo: undefined,
			variant: "info"
		}
	);

	const [gradesData, setGradesData] = React.useState<Array<StudentGrade>>([]);
	const onSave = () => {
		setLoaderState(true);
		if (selectedEnrollment && selectedEnrollment.stuEnrollId) {
			let enrollmentId = selectedEnrollment.stuEnrollId;
			if (gradesData) {
				let formValues = methods.getValues();
				let toSend = filterData(gradesData as StudentGrade[]) as StudentGrade[];
				toSend.forEach(function(grade) {
					grade.score = formValues["score_" + grade.gradeBookWeightDetailId];
					grade.attempts =
						formValues["attempts_" + grade.gradeBookWeightDetailId];
				});
				if(toSend.length > 0)
				{
					StudentGradesApi.CreateGrades(enrollmentId, toSend).then(
						(response: any) => {
							if (response) {
								let data = response.data;
								if (data) {
									let allStudentGradesData: any = {
										...data,
										dateCompleted:
											data.dateCompleted === "" ? null : data.dateCompleted
									};
									setGradesData([] as StudentGrade[]);
									setGradesData(allStudentGradesData.result);
									setSnackBarProps((props: any) => {
										return {
											variant: "success",
											showSnackBar: true,
											messageInfo: response.data.resultStatusMessage
										};
									});
								}
								setLoaderState(false);
							}
						},
						(exception: any) => {
							setLoaderState(false);
						}
					);
				}
				else
				{
					setLoaderState(false);
				}				
			}
		} else {
			setLoaderState(false);
		}
	};

	return (
		<Paper className={classes.root}>
			<CustomSnackbar
				variant={snackBarProps.variant}
				message={snackBarProps.messageInfo}
				open={snackBarProps.showSnackBar}
				onClose={(event?: React.SyntheticEvent, reason?: string) => {
					setSnackBarProps((props: any) => {
						return { ...props, showSnackBar: false };
					});
				}}
			></CustomSnackbar>
			<FormContext {...methods}>
				<form onSubmit={handleSubmit(onSave)} className={classes.form}>
					<div className={classes.gradesWrapper}>
						<ClinicServicesTable
							setClinicServicData={setGradesData}
							setValidationError={setValidationError}
							gradesData={gradesData}
							setSnackBar={setSnackBarProps}
							setConfirmationModal={props.setConfirmationModal}
							stuEnrollId = {selectedEnrollment ? selectedEnrollment.stuEnrollId : ""}
						/>
						<div className={classes.componentActions}>
							<AccessManager
								allowedPermissions={[security.permissions.student.updateClinicServices,
									security.permissions.student.deleteClinicServices]}
								renderNoAccess={() => <ProgressSaveButton
									disabled={true}
									text="Save"
									onClick={(e: any) => {
										onSave();
									}}
									loading={loaderState}
								></ProgressSaveButton>}
							>
								<ProgressSaveButton
									text="Save"
									disabled={validationError}
									onClick={(e: any) => {
										onSave();
									}}
									loading={loaderState}
								></ProgressSaveButton>
							</AccessManager>
							
						</div>
					</div>
				</form>
			</FormContext>
		</Paper>
	);
};

export default ClinicServicesPage;
