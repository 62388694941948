import * as React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Campus } from "../../interfaces/systemCatalog/ICampus";
import StatusInSchoolAutoComplete from "../../components/AutoComplete/StatusInSchoolAutoComplete";
const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      "& .MuiCardContent-root": {
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(2),
      },
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },

    filter: {
      // width: "15%",
      padding: theme.spacing(1),
      display: "inline-block",
    },
  })
);

interface IModel {
  status?: string[];
}

interface IStatusFilterProps {
  model: IModel;
  show?: boolean;
  setModel: (values: IModel) => void;
}

const StatusFilter = (props: IStatusFilterProps) => {
  const classes = useStyles({});
  const { model, setModel } = props;

  const setValues = (values: string[]): void => {
    if (JSON.stringify(values) !== JSON.stringify(model.status)) {
      setModel({ status: values });
    }
  }

  if (props.show) {
    return (
      <div className={classes.filter}>
        <StatusInSchoolAutoComplete
          multiple
          filterHandle={
            (values: any[]) =>
              setValues(values.map((v: { value: string }) => v.value))
          }
          valueFilter={
            model && model.status
              ? {
                key: "value",
                values: model.status,
              }
              : undefined
          } />
      </div>
    )
  } else {
    return <div className={classes.filter}></div>;
  }
};

export default StatusFilter;
