import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  Grid,
  Card,
  Typography,
  CardContent,
  Checkbox,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  TextField,
} from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import { useSelector } from "react-redux";
import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";
import { CustomSnackBarProps } from "../../../../interfaces/common/CustomSnackBarProps";
import { IEnrollmentSearchResult } from "../../../../interfaces/student/enrollment/EnrollmentSearchResult";
import SearchBarFilter from "../../../../components/_Layout/Search/SearchBarFilter";
import StudentBanner from "./StudentBanner";
import TermCalenderAutoComplete from "../../../../components/AutoComplete/TermCalenderAutoComplete";
import CreditProgramVersionAutoComplete from "../../../../components/AutoComplete/CreditProgramVersionAutoComplete";
import ClassAutoComplete from "../../../../components/AutoComplete/ClassAutoComplete";
import * as classRegApi from "../../../../api/setup/academics/classRegistrationApi";
import {
  ITermSearchParam,
  ITermResult,
  ITermClassResult,
  ICourseRegisterModel,
} from "../../../../interfaces/setup/creditHour/ITermSearchModels";

import {
  IClassRegistrationByClass,
  IEnrollmentsForClassRegistration,
  IRegistrationByClassResults,
} from "../../../../../src/interfaces/setup/creditHour/IClassRegistration";
import SortIcon from "@material-ui/icons/Sort";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      minHeight: "100%",
      height: "100%",
      maxWidth: "1050px",
      padding: theme.spacing(2),
      overflow: "auto",
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    startDateField: {
      width: "100%",
    },
    fullWidthElement: {
      width: "100%",
    },
    cardTitle: {
      fontSize: 17,
      color: "Black",
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardSeparator: {
      marginBottom: theme.spacing(1),
    },
    copyButton: {
      marginTop: theme.spacing(3),
      width: 240,
    },
    cardWrapper: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    searchBarContainer: {
      backgroundColor: theme.palette.site.secondary,
      borderRadius: 20,
      paddingLeft: "10px !important",
      fontSize: 13,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.black,
      paddingTop: "2.5px !important",
      paddingBottom: "2.5px !important",
    },
    right: {
      float: "right",
    },
    sortIcon: {
      fontSize: 15,
      marginLeft: 10,
    },
    hiddenElement: {
      visibility: "hidden",
    },
    dialogHeader: {
      backgroundColor: theme.palette.site.secondary,
      "& .MuiTypography-root": {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: "12pt",
      },
    },
    typography: {
      whiteSpace: "pre-line",
    },
    buttonSection: {
      marginBottom: theme.spacing(2),
    },
    saveButton: {
      width: "auto",
      marginRight: theme.spacing(2),
    },
    tableHeader: {
      backgroundColor: theme.palette.site.secondary,
    },
  })
);

function ByClass() {
  const classes = useStyles();
  const data = useForm<any>({ mode: "onBlur" });
  const { handleSubmit } = data;
  const [updating, setUpdating] = React.useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );

  const campusId = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

  const initialState: IClassRegistrationByClass = {
    termCalendarId: "",
    progCatYearId: "",
    instrClassTermId: "",
  };

  const [model, setModel] =
    React.useState<IClassRegistrationByClass>(initialState);
  const [classData, setClassData] =
    React.useState<IRegistrationByClassResults>();
  const [searchText, setSearchText] = React.useState<string>("");
  const [sortOn, setSortOn] = React.useState<string>("studentNumber");
  const [sortOrder, setSortOrder] = React.useState<string>("ASC");
  const [selectedCount, setSelectedCount] = React.useState<number>(0);
  const [selectAll, setSelectAll] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (model.termCalendarId && model.progCatYearId && model.instrClassTermId) {
      loadEnrollments();
    }
  }, [model.instrClassTermId]);

  const loadEnrollments = () => {
    classRegApi.GetEnrollments(model).then(
      (response: any) => {
        if (response && response.data && response.data.resultStatus === 0) {
          var result: IRegistrationByClassResults = response.data.result;
          setClassData(result);
          resetData();
        }
      },
      (exception: any) => {
        setSnackBarProps(() => {
          return {
            variant: "error",
            showSnackBar: true,
            messageInfo: exception,
          };
        });
      }
    );
  };
  const resetData = () => {    
    setSortOn("studentNumber");
    setSortOrder("ASC");
    setSelectAll(false);
    setSelectedCount(0);
    setSearchText("");
  }
  const handleFieldChange = (
    fieldId: keyof IClassRegistrationByClass,
    value: any
  ) => {
    let updatedModel = model;
    if (fieldId == "termCalendarId") {
      (updatedModel as any)["progCatYearId"] = "";
      (updatedModel as any)["instrClassTermId"] = "";
      setClassData(undefined);
    }
    if(fieldId == "progCatYearId")
    {
      (updatedModel as any)["instrClassTermId"] = "";
      setClassData(undefined);
    }
    (updatedModel as any)[fieldId] = value;
    setModel({ ...updatedModel });
  };

  const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

  const onSubmit = async (isPreview: any) => {
    if(classData?.enrollments?.filter((f) => f.registered == true).length ?? 0 > 0)
    {
      setDialogOpen(true);
    }    
  };

  const handleConfirm = () => {
    let courseList: ICourseRegisterModel[] = [];
    classData?.enrollments
      ?.filter((f) => f.registered == true)
      .map(function (cls: IEnrollmentsForClassRegistration, ix: any) {
        courseList.push({
          instrClassTermId: model.instrClassTermId,
          stuEnrollId: cls.stuEnrollId,
          studentScheduleId: null,
        });
      });
    if (courseList.length > 0) {
      setUpdating(true);
      classRegApi.RegisterCourse(courseList).then(
        (response: any) => {
          if (response && response.data && response.data.resultStatus === 0) {
            if (response.data.result === true) {
              setSnackBarProps(() => {
                return {
                  variant: "success",
                  showSnackBar: true,
                  messageInfo: "The selected student(s) have been registered for the class.",
                };
              });
              setDialogOpen(false);
              loadEnrollments();
            } else {
              setSnackBarProps(() => {
                return {
                  variant: "error",
                  showSnackBar: true,
                  messageInfo: response.data.resultStatusMessage,
                };
              });
            }
          } else {
            setSnackBarProps(() => {
              return {
                variant: "error",
                showSnackBar: true,
                messageInfo: response.data,
              };
            });
          }
          setUpdating(false);
        },
        (exception: any) => {
          setSnackBarProps(() => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo: exception,
            };
          });
          setUpdating(false);
        }
      );
    }
  };

  const selectAllClick = (checked: boolean) => {
    if (classData) {
      let count = selectedCount;
      let updatedEnrollments = sortList(classData?.enrollments).map((en) => {   
        if (count == classData.availableSeats && checked == true) {
          setSnackBarProps(() => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo: "Seat limit has been reached.",
            };
          });
          return en;    
        }
        if (en.visibility == true) {
          if(checked)
          {
            if(!en.registered) count = count + 1;
          }
          else
          {
            if(en.registered) count = count - 1;
          }
          return { ...en, registered: checked };
        }
        else return en;        
      });
      setSelectedCount(count);
      setClassData({ ...classData, enrollments: updatedEnrollments });
      
    }
  };

  const selectClass = (checked: boolean, enrollmentId: string) => {
    if (classData) {
      let updatedEnrollments = classData?.enrollments.map((en) => {
        if (en.stuEnrollId == enrollmentId) {
          return { ...en, registered: checked };
        }
        return en;
      });
      setClassData({ ...classData, enrollments: updatedEnrollments });
    }
    checked == true ? setSelectedCount(selectedCount + 1) : setSelectedCount(selectedCount - 1);
  };

  const handleSearch = (searchText: string) => {
    setSearchText(searchText);
    if (classData) {
      let updatedEnrollments = classData?.enrollments.map((en) => {
        if (en.studentName.toLowerCase().includes(searchText.toLowerCase())) {
          return { ...en, visibility: true };
        } else return { ...en, visibility: false };
      });
      setClassData({ ...classData, enrollments: updatedEnrollments });
    }
  };

  const handleSort = (field: string) => {
    setSortOn(field);
    sortOrder == "ASC" ? setSortOrder("DESC") : setSortOrder("ASC");
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const sortList = (list : Array<IEnrollmentsForClassRegistration>) => 
  {
    return list.sort((a, b) => {
      const aValue =
        a[
          sortOn as keyof IEnrollmentsForClassRegistration
        ];
      const bValue =
        b[
          sortOn as keyof IEnrollmentsForClassRegistration
        ];

      if (
        typeof aValue === "string" &&
        typeof bValue === "string"
      ) {
        if (sortOrder === "ASC") {
          return aValue.localeCompare(bValue);
        } else {
          return bValue.localeCompare(aValue);
        }
      } else if (
        typeof aValue === "boolean" &&
        typeof bValue === "boolean"
      ) {
        return aValue === bValue ? 0 : aValue ? -1 : 1;
      }

      return 0;
    })
  }

  React.useEffect(() => {
    let updatedModel = model;
    (updatedModel as any)["instrClassTermId"] = undefined;
    (updatedModel as any)["campusId"] = campusId;
    setModel({ ...updatedModel });
    setClassData(undefined);
	}, [campusId]);

  return (
    <Card className={classes.cardSeparator}>
      <CardContent>
        <FormContext {...data}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="row" spacing={1}>
              <Grid item md={12} sm={12} xs={12}>
                <Grid container direction="row" spacing={2}>
                  <Grid item md={4} sm={4} xs={12}>
                    <TermCalenderAutoComplete
                      id="termCalendarId"
                      name="termCalendarId"
                      label="Term"
                      key="termCalendarId"
                      shouldCheckForEffectiveEndDate={true}
                      valueFilter={
                        model?.termCalendarId
                          ? {
                              key: "value",
                              values: [model?.termCalendarId],
                            }
                          : undefined
                      }
                      filterHandle={(v: any) => {
                        handleFieldChange("termCalendarId", v ? v.value : null);
                      }}
                    />
                  </Grid>
                  <Grid item md={4} sm={4} xs={12}>
                    <CreditProgramVersionAutoComplete
                      id="searchProgCatYearId"
                      name="searchProgCatYearId"
                      label="Program"
                      key="searchProgCatYearId"
                      campusId={userSelectedCampus}
                      termId={model?.termCalendarId}
                      valueFilter={
                        model?.progCatYearId
                          ? {
                              key: "value",
                              values: [model?.progCatYearId],
                            }
                          : undefined
                      }
                      filterHandle={(v: any) => {
                        handleFieldChange("progCatYearId", v ? v.value : null);
                      }}
                    />
                  </Grid>
                  <Grid item md={4} sm={4} xs={12}>
                    <ClassAutoComplete
                      id="instrClassTermId"
                      name="instrClassTermId"
                      label="Class"
                      key="instrClassTermId"
                      termId={model.termCalendarId}
                      programId={model.progCatYearId}
                      valueFilter={
                        model?.instrClassTermId
                          ? {
                              key: "value",
                              values: [model?.instrClassTermId],
                            }
                          : undefined
                      }
                      filterHandle={(v: any) => {
                        handleFieldChange(
                          "instrClassTermId",
                          v ? v.value : null
                        );
                      }}
                    />
                  </Grid>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Card square={true} className={classes.cardWrapper}>
                        <CardContent>
                          <React.Fragment>
                            <Grid container direction="row" spacing={4}>
                              <Grid item xs={12} sm={4} md={4}>
                                <label>Instructor: </label>
                                {classData?.instructor}
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                <label>Course: </label>
                                {classData?.courseName}
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                <label>Number of Seats Available: </label>
                                {classData?.maxSeats != null
                                  ? Number(classData?.maxSeats) -
                                    Number(classData?.seatsOccupied)
                                  : ""}
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer style={{ maxHeight: 400 }}>
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>Eligible Students</TableCell>
                            <TableCell>
                              <div className={classes.right}>
                                <TextField
                                  placeholder={"Search students"}
                                  value={searchText}
                                  onChange={(v: any) => {
                                    handleSearch(
                                      v ? v.target.value : undefined
                                    );
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton>
                                          <SearchIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              onClick={() => {
                                handleSort("studentNumber");
                              }}
                            >
                              Student Number
                              {sortOn == "studentNumber" ? (
                                sortOrder == "ASC" ? (
                                  <ArrowUpward className={classes.sortIcon} />
                                ) : (
                                  <ArrowDownward className={classes.sortIcon} />
                                )
                              ) : (
                                <ArrowDownward
                                  className={classes.hiddenElement}
                                />
                              )}
                            </TableCell>
                            <TableCell
                              onClick={() => {
                                handleSort("studentName");
                              }}
                            >
                              Student Name
                              {sortOn == "studentName" ? (
                                sortOrder == "ASC" ? (
                                  <ArrowUpward className={classes.sortIcon} />
                                ) : (
                                  <ArrowDownward className={classes.sortIcon} />
                                )
                              ) : (
                                <ArrowDownward
                                  className={classes.hiddenElement}
                                />
                              )}
                            </TableCell>
                            <TableCell align="right">
                              Select All
                              <Checkbox
                                value="uncontrolled"
                                color="primary"
                                checked={selectAll}
                                onClick={(e: any) => {
                                  selectAllClick(e.target.checked);
                                  setSelectAll(e.target.checked);
                                }}
                                inputProps={{
                                  "aria-label": "uncontrolled-checkbox",
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {classData?.enrollments
                            ?.filter((item) => item.visibility === true)
                            .slice()
                            .sort((a, b) => {
                              const aValue =
                                a[
                                  sortOn as keyof IEnrollmentsForClassRegistration
                                ];
                              const bValue =
                                b[
                                  sortOn as keyof IEnrollmentsForClassRegistration
                                ];

                              if (
                                typeof aValue === "string" &&
                                typeof bValue === "string"
                              ) {
                                if (sortOrder === "ASC") {
                                  return aValue.localeCompare(bValue);
                                } else {
                                  return bValue.localeCompare(aValue);
                                }
                              } else if (
                                typeof aValue === "boolean" &&
                                typeof bValue === "boolean"
                              ) {
                                return aValue === bValue ? 0 : aValue ? -1 : 1;
                              }

                              return 0;
                            })
                            .map(
                              (
                                student: IEnrollmentsForClassRegistration,
                                ix: any
                              ) => (
                                <TableRow>
                                  <TableCell>{student.studentNumber}</TableCell>
                                  <TableCell>{student.studentName}</TableCell>
                                  <TableCell align="right">
                                    <Checkbox
                                      color="primary"
                                      key={`selectionCheck-${ix}`}
                                      onClick={(e: any) => {
                                        selectClass(
                                          e.target.checked,
                                          student.stuEnrollId
                                        );
                                      }}
                                      checked={student.registered}
                                      disabled={student.registered == false && selectedCount == classData?.availableSeats}
                                    />
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Grid
                item
                alignContent="flex-start"
                alignItems="flex-start"
                justify="flex-start"
              >
                <ProgressSaveButton
                  text="Complete Registration"
                  onClick={(e: any) => {
                    onSubmit(e);
                  }}
                  loading={updating}
                  buttonClassName={classes.copyButton}
                ></ProgressSaveButton>
              </Grid>
            </Grid>
          </form>
        </FormContext>
      </CardContent>
      <CustomSnackbar
        variant={snackBarProps.variant}
        message={snackBarProps.messageInfo}
        open={snackBarProps.showSnackBar}
        onClose={(event?: React.SyntheticEvent, reason?: string) => {
          setSnackBarProps((props: any) => {
            return { ...props, showSnackBar: false };
          });
        }}
      ></CustomSnackbar>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
          {"Registration Confirmation"}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              {}
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <Typography
                color="textSecondary"
                className={classes.typography}
                variant="body1"
              >
                {"Please review and confirm."}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Student Number</TableCell>
                      <TableCell>Student Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {classData?.enrollments
                      .filter((f) => f.registered == true)
                      .map((cls: IEnrollmentsForClassRegistration, ix: any) => (
                        <TableRow>
                          <TableCell>{cls.studentNumber}</TableCell>
                          <TableCell>{cls.studentName}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <Grid item xs={12}>
          <DialogActions className={classes.buttonSection}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={3}></Grid>
              <Grid
                item
                container
                xs={6}
                alignContent="center"
                alignItems="center"
                justify="center"
              >
                <ProgressSaveButton
                  text={"Yes"}
                  onClick={() => {
                    handleConfirm();
                  }}
                  loading={false}
                  buttonClassName={classes.saveButton}
                ></ProgressSaveButton>

                <Button
                  onClick={handleClose}
                  color="secondary"
                  variant="contained"
                  type="button"
                >
                  {"Cancel"}
                </Button>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </DialogActions>
        </Grid>
      </Dialog>
    </Card>
  );
}
export default ByClass;
