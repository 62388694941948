import {
	applyMiddleware,
	compose,
	createStore
} from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import {
	routerMiddleware
} from 'connected-react-router';
import { History } from 'history';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootSaga from '../saga';
import rootReducer from './rootReducer';

const sagaMiddleware = createSagaMiddleware();
export default function configureStore(history: History, initialState?: any) {
	const middleware = [thunk, routerMiddleware(history)];

	const persistConfig = {
		key: 'root',
		storage,
		blacklist: ['router', 'tab', 'student', 'userstate', 'drag', 'tag', 'notification', 'toaster', 'pdf_preview', 'settings', 'account', 'mru',
			'adhocReports', 'adhocReportDetails', 'illinoisTranscriptSettings', 'illinoisTranscriptSettingsSubjectAreas', 'illinoisTranscriptSettingsSubjectAreasComponents', 'stateWiseLicense', 'illinoisTranscript', 'transcriptReport']
	};

	const persistCombinedReducers = persistCombineReducers(
		persistConfig,
        rootReducer(history) as any
	);

	const enhancers = [];
	const windowIfDefined =
        typeof window === 'undefined' ? null : (window as any);
	if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
		enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
	}

	const store = createStore(
		persistCombinedReducers,
		initialState,
		compose(applyMiddleware(...middleware), applyMiddleware(sagaMiddleware), ...enhancers)
	);
	sagaMiddleware.run(rootSaga);

	const persistor = persistStore(store);

	return { store, persistor };
}
