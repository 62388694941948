// WebSocket.js
import * as React from 'react';
import { createContext } from 'react';
import {
    JsonHubProtocol,
    HttpTransportType,
    HubConnectionBuilder,
    HubConnection,
    HubConnectionState,
} from '@microsoft/signalr'; // version 1.0.4
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux'
// action for user authentication and receiving the access_token
import { userConstants, notificationConstants } from "../constants/actions.constants";
import { NotificationEvent } from "../interfaces/common/notifications/NotificationEvent";
import { Notification } from "../interfaces/common/notifications/Notification";
import getNotifications from '../utils/getNotifications';


const WebSocketContext = createContext(null)

export { WebSocketContext }

export default (props: any) => {
    const { children } = props;
    let connection: HubConnection | null = null;
    let ws = null;

    const dispatch = useDispatch();

    const userId = useSelector((state: any) => state.session?.user?.userId);
    const token = useSelector((state: any) => state.session?.user?.token);
    const appConfig = useSelector((state: any) => state.session?.appConfig);

    const protocol = new JsonHubProtocol();
    const apiBaseUrl = appConfig && appConfig.appConfig ? appConfig.appConfig.webSocketServiceUrl : undefined;

    const notificationReceived = (notification: Notification) => (dispatch: any) => {
        dispatch({ type: userConstants.SESSION_NOTIFICATION_RECEIVED, notification: notification });
        return Promise.resolve();
    };

    const startSignalRConnection = (connection: any, userId: string) => connection.start()
        .then(() => {
            console.info('SignalR Connected');
            //addToGroup("mytest", userId);
        })
        .catch((err: any) => console.error('SignalR Connection Error: ', err));

    const getConnectionInfo = (userId: string) => {
        return axios.post(`${apiBaseUrl}/api/negotiate`, null, getAxiosConfig(userId))
            .then(function (resp) { return resp.data })
            .catch(function () { return undefined })
    }

    const getAxiosConfig = (userId: string) => {
        const config = {
            headers: { 'x-ms-signalr-userid': userId }
        };
        return config;
    };

    const addToGroup = (groupName: string, userId: string) => {
        return axios.post(`${apiBaseUrl}/api/addToGroup/${groupName}`, null, getAxiosConfig(userId))
            .then(function (resp) { return resp.data })
            .catch(function () { return {} })
    }
    const handlerNotification = (dispatch: any) => (notification: any) => {

        //console.log('****** NOTIFICATION ******', notification);
        if (notification) {
            const notificationEvent: NotificationEvent = { eventType: notification.EventType, data: notification.Data }
            let notif = getNotifications(notificationEvent);
            if (notif) {
                notificationReceived(notif)(dispatch).then(() => {
                    return dispatch({ type: notificationConstants.NEW_NOTIFICATION })
                })
            }
        }

    }


    if (!connection && userId && token && apiBaseUrl) {

        // let transport to fall back to to LongPolling if it needs to
        const transport = HttpTransportType.WebSockets | HttpTransportType.LongPolling;

        getConnectionInfo(userId).then(function (info) {

            if (info) {
                let accessToken = info.accessToken
                const options = {
                    transport,
                    accessTokenFactory: function () {
                        if (accessToken) {
                            const _accessToken = accessToken
                            accessToken = null
                            return _accessToken
                        } else {
                            return getConnectionInfo(userId).then(function (info) {
                                return info.accessToken
                            })
                        }
                    }
                }

                connection = new HubConnectionBuilder()
                    .withUrl(info.url, options)
                    .withHubProtocol(protocol)
                    .withAutomaticReconnect()
                    .build()

                ws = {
                    connection: connection
                }
                // event handlers, you can use these to dispatch actions to update your Redux store
                connection.on('Notify', handlerNotification(dispatch));

                // re-establish the connection if connection dropped
                //connection.onclose(() => { console.log("connetion closed"); setTimeout(startSignalRConnection(connection, userId), 5000) });

                startSignalRConnection(connection, userId);
            }
        }).catch((err: any) => console.error('SignalR Connection Error: ', err))


    }

    return (
        <WebSocketContext.Provider value={ws}>
            {children}
        </WebSocketContext.Provider>
    )
}



