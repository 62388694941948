import * as React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import PostAttendanceFilterCard from "../attendance/PostAttendanceFilterCard";
import PostWeeklyAttendanceTable from "../attendance/PostWeeklyAttendanceTable";
import Paper from "@material-ui/core/Paper";
import {
	Typography,
	Card,
	CardContent,
	Dialog,
	DialogTitle,
	IconButton,
	DialogContent,
	DialogActions,
	Button,
} from "@material-ui/core";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import IPostAttendanceFilters from "../../interfaces/attendance/IPostAttendanceFilters";
import { ISlotDetails } from "../../interfaces/attendance/IWeeklyAttendance";
import { startOfWeek, endOfWeek, isEqual } from "date-fns";
import { CustomSnackBarProps } from "../../interfaces/common/CustomSnackBarProps";
import CustomSnackbar from "../../components/notifications/CustomSnackbar";
import { useEffect } from "react";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.background.paper,
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2),
			},
		},
		"& .makeStyles-content": {
			backgroundColor: theme.palette.background.paper,
		},
		wrapper: {
			paddingTop: theme.spacing(3),
			display: "flex",
			flexDirection: "column",
			height: "100%",
		},
		paperRoot: {
			padding: theme.spacing(2),
			width: "100%",
			minHeight: "100%",
			height: "95%",
			overflowX: "hidden",
			overflowY: "auto",
			flexDirection: "column",
			backgroundColor: theme.palette.background.paper,
		},
		cardRoot: {
			display: "flex",
			flexDirection: "column",
			height: "100%",
		},
		button: {
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(3),
			whiteSpace: "nowrap",
		},
		contentPane: {
			flexGrow: 1,
			height: "90%",
		},
		cardTitle: {
			fontSize: 17,
			color: theme.palette.black,
			fontWeight: "bold",
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1),
			marginBottom: theme.spacing(1),
		},
		textField: {
			marginRight: theme.spacing(1),
			width: "100%",
		},
		headerText: {
			paddingLeft: theme.spacing(1),
		},
		cardContent: {
			padding: theme.spacing(3, 3),
			flexGrow: 1,
			display: "flex",
			flexDirection: "column",
		},
		switch: {
			marginLeft: "0px",
			paddingTop: theme.spacing(2),
		},
		dialogTitle: {
			padding: theme.spacing(1, 0.5),
		},
		dialogTitleText: {
			fontSize: 17,
			fontWeight: 500,
			maxWidth: "calc(100% - 44px)",
			float: "left",
		},
		dialogCloseButton: {
			padding: theme.spacing(1, 1),
			float: "right",
		},
		dialogAction: {
			justifyContent: "flex-start",
		},
	})
);

const defaultFilters: IPostAttendanceFilters = {
	startDate: startOfWeek(new Date()),
	endDate: endOfWeek(new Date()),
	status: [],
	enrollmentGroup: [],
	programVersion: [],
};

const PostAttendance = (_props: any) => {
	const classes = useStyles({});
	const [filters, setFilters] =
		React.useState<IPostAttendanceFilters>(defaultFilters);
	const [detailsId, setDetailsId] = React.useState<{
		[key: number]: ISlotDetails | undefined;
	}>({});
	const [resetBit, setResetBit] = React.useState<boolean>(false);
	const [confirmationDialog, setconfirmationDialogProps] = React.useState<{
		open?: boolean;
		message?: string;
		onCancel?: () => void;
		onOk?: () => void;
	}>({ open: false });

	const setDetails = (
		index: number,
		inserting?: ISlotDetails,
		check: number = 0
	): void => {
		if (index || index === 0) {
			const details = detailsId && detailsId[index];
			const currDate = details?.date ?? new Date();
			if (!check) {
				check =
					details &&
					details.dirty &&
					(!inserting ||
						!inserting.date ||
						!isEqual(currDate, inserting.date)) &&
					detailsId[index]?.studentEnrollmentId ===
						inserting?.studentEnrollmentId &&
					detailsId[index]?.studentBadgeId === inserting?.studentBadgeId
						? 1
						: 2;
			}
			if (check === 1) {
				setconfirmationDialogProps({
					open: true,
					message:
						"You are about to change the slot. Any unsaved data will be lost. Are you sure you want to continue?",
					onOk: () => setDetails(index, inserting, 2),
					onCancel: () => setconfirmationDialogProps({ open: false }),
				});
				return;
			}
			setDetailsId({ ...detailsId, [index]: inserting });

			setconfirmationDialogProps({ open: false });
		}
	};

	const seDetailsBulk = (
		values: { [key: number]: ISlotDetails | undefined },
		check: number = 0
	) => {
		if (!check) {
			check =
				detailsId &&
				Object.values(detailsId).findIndex((val) => !!val?.dirty) > -1
					? 1
					: 2;
		}
		if (check === 1) {
			setconfirmationDialogProps({
				open: true,
				message:
					"You are about to change the date. Any unsaved data will be lost. Are you sure you want to continue?",
				onOk: () => seDetailsBulk(values, 2),
				onCancel: () => setconfirmationDialogProps({ open: false }),
			});
			return;
		}
		setDetailsId({ ...detailsId, ...values });
		setconfirmationDialogProps({ open: false });
	};

	const closeAllModals = (check: number = 0) => {
		if (!check) {
			check =
				(!confirmationDialog || !confirmationDialog.open) &&
				detailsId &&
				Object.values(detailsId).findIndex((val) => !!val?.dirty) > -1
					? 1
					: 2;
		}
		if (check === 1) {
			setconfirmationDialogProps({
				open: true,
				message:
					"You are about to close the punches window. Any unsaved data will be lost. Are you sure you want to continue?",
				onOk: () => closeAllModals(2),
				onCancel: () => setconfirmationDialogProps({ open: false }),
			});
			return;
		}
		setconfirmationDialogProps({ open: false });
		setDetailsId({});
	};

	const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
		{
			showSnackBar: false,
			messageInfo: undefined,
			variant: "info",
		}
	);

	const applyFilters = (filters: IPostAttendanceFilters, check: number = 0) => {
		if (!check) {
			check =
				detailsId &&
				Object.values(detailsId).findIndex((val) => !!val?.dirty) > -1
					? 1
					: 2;
		}
		if (check === 1) {
			setconfirmationDialogProps({
				open: true,
				message:
					"You are about to change the filter(s). Any unsaved data will be lost. Are you sure you want to continue?",
				onOk: () => applyFilters(filters, 2),
				onCancel: () => setconfirmationDialogProps({ open: false }),
			});
			return;
		}
		setFilters({ ...filters });
		setDetailsId({});
		setconfirmationDialogProps({ open: false });
	};

	const resetFilter = () => {
		setResetBit(!resetBit);
	};

	useEffect(() => {
		applyFilters(filters, 2);
	}, []);

	return (
		<div className={classes.wrapper}>
			<CustomSnackbar
				variant={snackBarProps.variant}
				message={snackBarProps.messageInfo}
				open={snackBarProps.showSnackBar}
				onClose={(event?: React.SyntheticEvent, reason?: string) => {
					setSnackBarProps((props: any) => {
						return { ...props, showSnackBar: false };
					});
				}}
			></CustomSnackbar>
			<Paper className={classes.paperRoot} square={true}>
				<div>
					<PostAttendanceFilterCard
						resetBit={resetBit}
						filters={filters}
						applyFilters={applyFilters}
						defaultFilters={defaultFilters}
					/>
				</div>
				<div className={clsx(classes.contentPane)}>
					<PostWeeklyAttendanceTable
						filters={filters}
						resetFilter={resetFilter}
						slotClick={setDetails}
						closeAllModals={closeAllModals}
						setBulkDetailsOpen={seDetailsBulk}
						applyFilters={setFilters}
						selectedSlots={detailsId}
						showSnackBar={setSnackBarProps}
						confirmationDialog={confirmationDialog}
						setconfirmationDialogProps={setconfirmationDialogProps}
					/>
				</div>
			</Paper>
			<Dialog
				open={confirmationDialog.open ? true : false}
				onClose={confirmationDialog.onCancel}
				PaperComponent={Paper}
				PaperProps={{ square: true }}
				aria-labelledby="attendance-dialog-title"
				aria-describedby="attendance-dialog-description"
			>
				<DialogTitle
					className={classes.dialogTitle}
					disableTypography
					id="attendance-dialog-title"
				>
					<Typography className={classes.dialogTitleText}>Warning</Typography>
					<IconButton
						aria-label="close"
						onClick={confirmationDialog.onCancel}
						className={classes.dialogCloseButton}
					>
						<CloseIcon fontSize="small" />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<Typography>{confirmationDialog.message}</Typography>
				</DialogContent>
				<DialogActions className={classes.dialogAction}>
					<Button
						onClick={confirmationDialog.onOk}
						size="small"
						type="submit"
						variant="contained"
						color="primary"
					>
						Confirm
					</Button>
					<Button
						onClick={confirmationDialog.onCancel}
						size="small"
						variant="contained"
						color="primary"
					>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default PostAttendance;
