import React from "react";
import {
	fade,
	makeStyles,
	Theme,
	createStyles
} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import { Grid, TextField, Box, Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		grow: {
			flexGrow: 1
		},
		searchIcon: {
			width: theme.spacing(7),
			height: "100%",
			position: "absolute",
			pointerEvents: "none",
			display: "flex",
			alignItems: "center",
			justifyContent: "center"
		},
		right: {
			float: "right"
		}
	})
);

export default function SearchWithIcon(props: any) {
	const classes = useStyles({});
	return (
		<div className={classes.right}>
			<TextField
				placeholder={props.label}
				value={props.value}
				onChange={props.onChange}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton>
								<SearchIcon />
							</IconButton>
						</InputAdornment>
					)
				}}
			/>
		</div>
	);
}
