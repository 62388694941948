import React from "react";
import "date-fns";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { AdvanceSearchModel } from "../../../../interfaces/student/search/AdvanceSearchModel";
import StudentGroupsAutoComplete from "../../../../components/AutoComplete/StudentGroupsAutoComplete";
import EnrollmentStatusAutoComplete from "../../../../components/AutoComplete/EnrollmentStatusAutoComplete";
import ProgramsAutoComplete from "../../../../components/AutoComplete/ProgramsAutoComplete";
import ProgramVersionAutoComplete from "../../../../components/AutoComplete/ProgramVersionAutoComplete";
import { EnrollmentProgramAndGroups } from "../../../../interfaces/student/academics/EnrollmentProgram";
import * as actionTypes from "../../../../constants/actions.constants";

const useStyles = makeStyles((theme: any) => ({
  searchActions: {
    marginTop: theme.spacing(3),
    textAlign: "right",
  },
  startDateField: {
    width: "95%",
  },
  resetFilterButton:{
    marginRight:theme.spacing(1),
  }
}));

const AdvanceSearch = (props: any) => {
  const classes = useStyles({});
  const dispatch = useDispatch();

  const loggedInUser = useSelector((state: any) => state.session.user);
  const selectedEnrollmentProgramAndGroups = useSelector((state: any) =>
  state.user_advance_search.getEnrollmentProgramAndGroups(state.session.user.userId)
  );

  const advanceSearchModelState = useSelector((state: any) =>
  state.user_advance_search.getAdvanceSearchModel(state.session.user.userId)
  );

  const [startDate, setStartDateState] = useState<Date | null>(advanceSearchModelState.StartDate);
  const [StudentGroupState, setStudentGroupState] = useState<string[] | "">(advanceSearchModelState.StudentGrpId);
  const [EnrollmentStatusSelected, setEnrollmentStatusSelected] = useState<
    string[] | ""
  >(advanceSearchModelState.EnrollmentStatusId);
  const [enrollmentProgramAndGroups,setEnrollmentProgramAndGroups,] = React.useState<EnrollmentProgramAndGroups>(props.programAndGroups ? props.programAndGroups : selectedEnrollmentProgramAndGroups);
  const [PrgVersionsSelected, setPrgVersionsSelected] = useState<string[] | "">(
    advanceSearchModelState.ProgramVersionId
  );
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );
  
  const setEnrollmentProgramAndGroupsState = (value:any) => {
    setEnrollmentProgramAndGroups(
      (state: EnrollmentProgramAndGroups) => {
        return { ...state, programVersionId: undefined };
      }
    );
    setEnrollmentProgramAndGroups(
      (state: EnrollmentProgramAndGroups) => {
        return { ...state, programId: value ? value.value : undefined };
      }
    );
  }

  const onSearchClicked = (event: any) => {
    let advanceSearchModel: AdvanceSearchModel = {
      CampusId: userSelectedCampus,
      ProgramVersionId: PrgVersionsSelected,
      StudentGrpId: StudentGroupState,
      EnrollmentStatusId: EnrollmentStatusSelected,
      StartDate: startDate,
    };

    setAdvanceSearchValuesToStore(advanceSearchModel);

    props.onSearchClicked(event, advanceSearchModel);
    props.history.push({
      pathname: "/AdvanceSearchResultsGrid",
      state: advanceSearchModel,
    });
  };

  const setAdvanceSearchValuesToStore = (advanceSearchModel:AdvanceSearchModel) => {
    dispatch({
      type:
        actionTypes.advanceSearchConstants.SET_ADVANCE_SEARCH_FILTERS,
      data: {
        userId: loggedInUser.userId,
        advanceSearchModel: advanceSearchModel,
      },
    });

    dispatch({
      type:
        actionTypes.advanceSearchConstants.SET_ENROLLMENT_PROGRAM_AND_GROUPS_ADVANCE_SEARCH,
      data: {
        userId: loggedInUser.userId,
        selectedEnrollmentProgramAndGroups: enrollmentProgramAndGroups,
      },
    });
  }

  const resetFilters = () => {
    setStudentGroupState("");
    setEnrollmentStatusSelected("");
    setEnrollmentProgramAndGroups(
      (state: EnrollmentProgramAndGroups) => {
        return { ...state,  };
      }
    );
    setEnrollmentProgramAndGroups(
      (state: EnrollmentProgramAndGroups) => {
        return { ...state, programId: undefined };
      }
    );
    setPrgVersionsSelected("");
    setStartDateState(null);

    dispatch({
      type:
        actionTypes.advanceSearchConstants.SET_ENROLLMENT_PROGRAM_AND_GROUPS_ADVANCE_SEARCH,
      data: {
        userId: loggedInUser.userId,
        selectedGroups: undefined,
      },
    });

    dispatch({
      type:
        actionTypes.advanceSearchConstants.SET_ADVANCE_SEARCH_FILTERS,
      data: {
        userId: loggedInUser.userId,
        advanceSearchModel: {
          CampusId:userSelectedCampus,
          ProgramVersionId: "",
          StudentGrpId: "",
          EnrollmentStatusId: "",
          StartDate: null,
        },
      },
    });
  }

  const setStudentGroup = (studentGroups: any) => {
    let newGroups: string[] = [];
    if (studentGroups !== null) {
      if (Array.isArray(studentGroups)) {
        newGroups = studentGroups.map((gr: any) => gr.value);
      } else {
        newGroups.push(studentGroups.value);
      }
    }
    setStudentGroupState(newGroups);

  };
  const setEnrollmentStatus = (enrollmentStatus: any) => {
    let newGroups: string[] = [];
    if (enrollmentStatus !== null) {
      if (Array.isArray(enrollmentStatus)) {
        newGroups = enrollmentStatus.map((gr: any) => gr.value);
      } else {
        newGroups.push(enrollmentStatus.value);
      }
    }
    setEnrollmentStatusSelected(newGroups);

  };
  const setProgramVersion = (PrgVersions: any) => {
    let newGroups: string[] = [];
    if (PrgVersions !== null) {
      if (Array.isArray(PrgVersions)) {
        newGroups = PrgVersions.map((gr: any) => gr.value);
      } else {
        newGroups.push(PrgVersions.value);
      }
    }
    setPrgVersionsSelected(newGroups);

  };
  return (
    <div>
      <div>
        <StudentGroupsAutoComplete
          id="studentGrp"
          filterHandle={(groups: any) => {
            setStudentGroup(groups);
          }}
          name="studentGrp"
          label="Student Group"
          multiple={true}
          valueFilter={
            StudentGroupState
              ? {
                  key: "value",
                  values: StudentGroupState,
                }
              : undefined
          }
        />
      </div>

      <div>
        <EnrollmentStatusAutoComplete
          filterHandle={(data: any) => {
            setEnrollmentStatus(data);
          }}
          id="enrollmentStatus"
          name="enrollmentStatus"
          label="Enrollment Status"
          multiple={true}
          valueFilter={
            EnrollmentStatusSelected
              ? {
                  key: "value",
                  values: EnrollmentStatusSelected,
                }
              : undefined
          }
        />
      </div>
      <div>
        <ProgramsAutoComplete
          id="programId"
          name="programId"
          label="Program"
          includeCreditPrograms={true}
          filterHandle={(value: any) => {
            setEnrollmentProgramAndGroupsState(value);
          }}

          valueFilter={
            enrollmentProgramAndGroups && enrollmentProgramAndGroups.programId ? {key:"value",values:[enrollmentProgramAndGroups.programId]} : undefined
          }
        ></ProgramsAutoComplete>
      </div>
      <div>
        <ProgramVersionAutoComplete
          id="prgVersion"
          name="prgVersion"
          label="Program Version"
          includeCreditProgramVersions={true}
          multiple={true}
          valueFilter={
            PrgVersionsSelected
              ? {
                  key: "value",
                  values: PrgVersionsSelected,
                }
              : undefined
          }
          programId={enrollmentProgramAndGroups?.programId}
          filterHandle={(data: any) => {
            setProgramVersion(data);
          }}
        />
      </div>
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            id="date-picker-inline"
            label="Start Date"
            value={startDate}
            onChange={(value) => setStartDateState(value)}
            className={classes.startDateField}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div className={classes.searchActions}>
        <Button
              className={classes.resetFilterButton}
							variant="contained"
							color="secondary"
							onClick={resetFilters}
						>
							Reset Filter
						</Button>

        <Button variant="contained" color="primary" onClick={onSearchClicked}>
          Search
        </Button>
      </div>
    </div>
  );
};
AdvanceSearch.propTypes = {
  onSearchClicked: PropTypes.func,
};
export default withRouter(AdvanceSearch);
