export default {
    acId: "resource",
    cacheTime: 5,
    route: "/SystemCatalog/Resource/GetByTypeId",
    requestType: "GET",
    body: null,
    hasUrlParams: true,
    useCache: true,
    storeInCache: true,
    isCampusSpecific: false,
    isUserSpecific: false,
    loadOnRender: true,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
      return data;
    },
  };
  