import React, { useEffect, useState, Fragment } from "react";
import {
  makeStyles,
  createStyles,
  Popover,
  Card,
  Grid,
  Button,
  CardContent,
  FormControlLabel,
  Switch,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import ScheduleDetails from "./ScheduleDetailsModal";
import { CustomSnackBarProps } from "../../../../interfaces/common/CustomSnackBarProps";
import DateFnsUtils from "@date-io/date-fns";
import AddIcon from "@material-ui/icons/Add";
import {
  IClassScheduleGridRow,
  ClassScheduleGridRow,
} from "../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/Schedule/IScheduleGridRow";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardMainContent: {
      height: "100%",
      maxWidth: "100%",
      width: "100%",
    },
    button1: {
      marginTop: "5px",
    },
    PopoverButton: {
      marginRight: "100px",
    },
    iconButton: {
      fontSize: "xx-large",
      overflow: "hidden",
      maxWidth: "100%",
      maxHeight: "100%",
      relative: "center",
    },
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%",
    },
    cardWrapper: {
      height: "100%",
      padding: theme.spacing(2),

      marginTop: theme.spacing(2),
    },
    saveButton: {
      width: 90,
    },
    gridMargin: {
      marginTop: theme.spacing(3),
    },
    switch: {
      marginLeft: "0px",
      paddingTop: theme.spacing(2),
    },
    fullWidthField: {
      width: "100%",
    },
    preLoader: {
      textAlign: "center",
    },
  })
);

const AddScheduleModal:  React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const classes = useStyles({});
  const [isDirty, setDirty] = React.useState<boolean>(false);
  
    const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
      {
        showSnackBar: false,
        messageInfo: undefined,
        variant: "info",
      }
    );
  
  const [selectedSchedule, setSelectedSchedule] = React.useState<
    IClassScheduleGridRow | undefined
  >(undefined);
  const [trackChangesModel, setTrackChangesModel] =
    React.useState<IClassScheduleGridRow>(new ClassScheduleGridRow());
  const [schedules, setSchedules] = React.useState<
    Array<IClassScheduleGridRow>
  >(new Array<IClassScheduleGridRow>());

  const addNewRecord = () => {
    let newSchedule = new ClassScheduleGridRow();
    setSelectedSchedule({ ...newSchedule });
  };

  React.useEffect(() => {
    addNewRecord();
  }, []);

  const refreshSchedules = () => {
    addNewRecord();
  };

  
  const resetChanges = () => {
    let resetModel: ClassScheduleGridRow = trackChangesModel;
    setSelectedSchedule({ ...resetModel });
  };


  

  return (
    <div>
    <Button
        aria-describedby={id}
        className={classes.button1}
        onClick={handleClick}
      >
        <AddIcon className={classes.iconButton} />

        {/* Open Popover 2 */}
      </Button>
      <Popover
        className={classes.PopoverButton}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
    <div>
          <React.Fragment>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={0} className={classes.cardMainContent}>
                <Grid item xs={12} sm={12} md={12}>
                 
                        <CardContent>
                          {selectedSchedule !== undefined && (
                            <ScheduleDetails
                              resetChanges={resetChanges}
                              model={selectedSchedule}
                              setScheduleModel={setSelectedSchedule}
                              isDirty={isDirty}
                              setDirty={setDirty}
                              clearFields={addNewRecord}
                              setSnackBar={setSnackBarProps}
                              refreshSchedules={refreshSchedules}
                              setSchedules={setSchedules}
                            />
                          )}
                        </CardContent>
                    </Grid>
                    </Grid>
                    </MuiPickersUtilsProvider>
                    </React.Fragment>
        </div>
        </Popover>
    </div>
  );
};

export default AddScheduleModal;
