import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Button, Card, CardContent, Fade } from "@material-ui/core";
import AwardSelection from "./AwardSelection";
import AwardDetail from "./AwardDetail";
import DisbursementDetail from "./DisbursementDetail";
import LedgerActivity from "./LedgerActivity";
import * as StudentAwardApi from "../../../../api/student/studentAccounts/StudentAwardApi";
import { StudentAward } from "../../../../interfaces/student/studentAccounts/StudentAward";
import { useSelector } from "react-redux";
import { LedgerItem } from "../../../../interfaces/student/studentAccounts/LedgerItem";
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";
import { EmptyGuid } from "../../../../utils/constants";
import { Enrollment } from "../../../../interfaces/student/academics/Enrollment";
import ConfirmationModal from "../../../../components/_Layout/Modal/ConfirmationModal";
import { CustomSnackBarProps } from "../../../../interfaces/common/CustomSnackBarProps";
import { FormContext, useForm } from "react-hook-form";
import AccessManager from "../../../../components/security/AccessManager";
import { security } from "../../../../constants/Security/securityConstants";
import { system } from "../../../../constants/system/systemConstants";

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.paper,
            "& .MuiCardContent-root": {
                paddingTop: theme.spacing(0),
                paddingLeft: theme.spacing(2),
            },
        },
        "& .makeStyles-content": {
            backgroundColor: theme.palette.background.paper,
        },
        paperRoot: {
            padding: theme.spacing(3, 2),
            width: "100%",
            minHeight: "100%",
            height: "95%",
            overflowX: "hidden",
            overflowY: "auto",
            flexDirection: "column",
            backgroundColor: theme.palette.background.paper,
        },
        buttonColorPrimary: {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
            "&:hover": {
                backgroundColor: theme.palette.primary.main,
            },
            textDecoration: "bold",
            padding: theme.spacing(1),
            whiteSpace: "nowrap",
            margin: theme.spacing(1.5),
        },
        buttonColorSecondary: {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.secondary.contrastText,
            "&:hover": {
                backgroundColor: theme.palette.secondary.main,
            },
            textDecoration: "bold",
            padding: theme.spacing(1),
            whiteSpace: "nowrap",
            margin: theme.spacing(1.5),
        },
        buttonColorTertiary: {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.error.contrastText,
            "&:hover": {
                backgroundColor: theme.palette.error.main,
            },
            textDecoration: "bold",
            padding: theme.spacing(1),
            whiteSpace: "nowrap",
            margin: theme.spacing(1.5),
        },
        cardSeparator: {
            marginBottom: theme.spacing(1),
        },
        awardCard: {
            marginTop: theme.spacing(2),
        },
    })
);

const Awards = (props: any) => {
    const classes = useStyles({});
    const selectedEnrollment = useSelector(
        (state: any) => state.student.selectedEnrollment
    );
    const [isSaveButtonDisabled, setSaveButtonDisabled] = React.useState(false);
    const [enabledNewAward, setEnableNewAward] = React.useState(false);
    const [fadeAwardDetails, setfadeAwardDetails] = React.useState(true);
    //const [fadeAwardDetails, setfadeAwardDetails] = React.useState(false);
    const appSettings = useSelector(
        (state: any) => {  return state.settings.settings }
    );
    const isSupportUser = useSelector(
        (state: any) => state.session.user.isSupportUser
    );
    const [awardId, setAwardId] = React.useState(EmptyGuid);
    const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
        {
            showSnackBar: false,
            messageInfo: undefined,
            variant: "info",
        }
    );

    const getAfaIntegratedFlag = () => {

        const afaIntegrationSetting = appSettings[system.settings.enableAFAIntegration] as string
        let afaIntegrationEnabled = false;
        const allowAFAOverrideSetting = appSettings[system.settings.allowAFAOverride] as string
        let allowAFAOverrideEnabled = false;

        if (afaIntegrationSetting) {
            afaIntegrationEnabled = afaIntegrationSetting.toLowerCase() === 'yes'
            if (afaIntegrationEnabled)
                if (allowAFAOverrideSetting) 
                {
                    allowAFAOverrideEnabled = allowAFAOverrideSetting.toLowerCase() === 'true'
                    
                    if (allowAFAOverrideEnabled){
                        afaIntegrationEnabled = false;
                    }
                }  
        }
        return afaIntegrationEnabled;

    };

    const isAfaIntegrationEnabled = getAfaIntegratedFlag();
    // const allowAFAOverrideEnabled = getAllowAFAOverrideFlag();
    // const allowAFAOverride = false;

    const [Award, setAward] = React.useState<StudentAward[]>([]);
    const [AwardLedger, setAwardLedger] = React.useState<LedgerItem[]>([]);
    const [loaderState, setLoaderState] = React.useState<boolean>(false);

    const deleteAward = () => {
        setLoaderState(true);
        StudentAwardApi.deleteAward(
            awardId,
            (selectedEnrollment as Enrollment).campusId
        ).then(
            (response: any) => {
                if (response.resultStatus === 0) {
                    setLoaderState(false);
                    refreshAwardGrid();

                    setSnackBarProps((props: any) => {
                        return {
                            variant: "success",
                            showSnackBar: true,
                            messageInfo: response.resultStatusMessage,
                        };
                    });
                } else {
                    setLoaderState(false);
                    setSnackBarProps((props: any) => {
                        return {
                            variant: "error",
                            showSnackBar: true,
                            messageInfo: response.data,
                        };
                    });
                }
            },
            (exception: any) => {
                setLoaderState(false);
                setSnackBarProps((snackBarProps: any) => {
                    return {
                        variant: "error",
                        showSnackBar: true,
                        messageInfo: exception,
                    };
                });
            }
        );
    };


    const refreshAwardGrid = () => {
        if (selectedEnrollment && selectedEnrollment.stuEnrollId) {
            StudentAwardApi.getStudentAward(selectedEnrollment.stuEnrollId).then(
                (response: any) => {
                    if (response != null) {
                        let data = response.data;
                        let awardData: StudentAward[] = data.map((li: any) => {
                            return { ...li } as StudentAward;
                        });
                        setAward(awardData);
                        setEnableNewAward(false);
                        if (awardData.length < 1) {
                            setAwardId(EmptyGuid);
                        }
                    } else {
                        // display error
                    }
                },
                (exception: any) => { }
            );
        }
    };
    const getAwardLedgerDetails = () => {
        if (selectedAward && selectedAward.studentAwardId) {
            StudentAwardApi.getLedgerForAward(selectedAward.studentAwardId).then(
                (response: any) => {
                    if (response != null) {
                        let data = response.data;
                        let ledgerData: LedgerItem[] = data.map((li: any) => {
                            return { ...li } as LedgerItem;
                        });
                        setAwardLedger(ledgerData);
                       
                    } else {
                        // display error
                    }
                },
                (exception: any) => { }
            );
        } else {
            //set empty so that it does not display anything
            let ledgerData: LedgerItem[] = [];
            setAwardLedger(ledgerData);
        }
    };
   
    React.useEffect(() => {
        refreshAwardGrid();
        setEnableNewAward(false);
        //methods.reset();
    }, [selectedEnrollment]);

    const [defValueEndUnit, setDefValueEndUnit] = React.useState<number>(10);
    const getEndUnitDefault = () => {
        if (selectedEnrollment && selectedEnrollment.stuEnrollId) {
            StudentAwardApi.getEndUnitDefault(selectedEnrollment.stuEnrollId).then(
                (response: any) => {
                    if (response != null) {
                         let data = response.data;
                        let endUnitVal = data.result as number;
                        setDefValueEndUnit(endUnitVal);
                       
                    } 
                },
                (exception: any) => { }
            );
        } else {
           setDefValueEndUnit(10);
        }
    };
    const [selectedAward, setSelectedAward] = React.useState<StudentAward>({
        studentAwardId: "",
        studentEnrollmentId: "",
        awardTypeId: "",
        academicYearId: "",
        grossAmount: 0,
        modUser: "",
        awardStartDate: new Date(),
        awardEndDate: new Date(),
        disbursements: 0,
        financialAidId: "",
        maxAwardAmount: 0,
        beginUnit: 1,
        endUnit: defValueEndUnit,
        netAmt: 0,
        loanFees: 0,
        recievedAmt: 0,
        refunded: 0,
        selected: false,
        canEdit: false,
        isTitleIVAward: false
    });
    const methods = useForm<StudentAward>({
        mode: "onBlur",
        defaultValues: selectedAward
       
    });
   
    const { handleSubmit } = methods;

    React.useEffect(() => {

        getAwardLedgerDetails();
        getEndUnitDefault();
        methods.setValue("endUnit", selectedAward.endUnit);
        //console.log(methods.getValues());
        //methods.reset(selectedAward);
        if (awardId !== EmptyGuid) {
            methods.triggerValidation();
        }

        if(selectedAward.studentAwardId != EmptyGuid){
            setEnableNewAward(false);
           
        }

    }, [selectedAward]);

    


    const [confirmationPopupState, setConfirmationPopupState] = React.useState<
        any
    >({
        id: "confirmation-popup",
        message: "",
    });

    const handleNewAwardClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (methods.formState.dirty) {
            setConfirmationPopupState((state: any) => {
                return {
                    ...state,
                    open: true,
                    confirmationText: "Are you sure you want to clear all fields?",
                    confirmationButton: "Yes",
                    title: "New Award",
                    onConfirmEvent: () => {
                        setConfirmationPopupState((updatedState: any) => {
                            return { ...updatedState, open: false };
                        });
                        clearSelectedAward();
                        setEnableNewAward(true);
                        methods.reset();
                    },
                    onCancelEvent: () => {
                        setConfirmationPopupState((updatedState: any) => {
                            return { ...updatedState, open: false };
                        });
                    },
                };
            });
        } else {
            clearSelectedAward();
            methods.reset();
            setEnableNewAward(true);
        }
    };

    const endUnitSelectedAward = () => {
        let startAward = new Date();
        let endDate = new Date();
        endDate.setFullYear(startAward.getFullYear() + 1);
        getEndUnitDefault();
        let endUnitAward: StudentAward = {
            studentAwardId: EmptyGuid,
            studentEnrollmentId: (selectedEnrollment as Enrollment).stuEnrollId,
            awardTypeId: EmptyGuid,
            academicYearId: EmptyGuid,
            grossAmount: 0.0,
            modUser: "",
            awardStartDate: startAward,
            awardEndDate: endDate,
            disbursements: 0,
            financialAidId: "",
            maxAwardAmount: 0.0,
            beginUnit: 1,
            endUnit:defValueEndUnit,
            netAmt: 0.0,
            loanFees: 0.0,
            recievedAmt: 0.0,
            refunded: 0.0,
            selected: false,
            canEdit: false,
            numberOfDisbursements:1,
            createDisbursementDetails:false,
            isTitleIVAward:false
        };
        setSelectedAward({ ...endUnitAward });
    };


    const clearSelectedAward = () => {
        let startAward = new Date();
        let endDate = new Date();
        endDate.setFullYear(startAward.getFullYear() + 1);
        let clearAward: StudentAward = {
            studentAwardId: EmptyGuid,
            studentEnrollmentId: (selectedEnrollment as Enrollment).stuEnrollId,
            awardTypeId: EmptyGuid,
            academicYearId: EmptyGuid,
            grossAmount: 0.0,
            modUser: "",
            awardStartDate: startAward,
            awardEndDate: endDate,
            disbursements: 0,
            financialAidId: "",
            maxAwardAmount: 0.0,
            beginUnit: 1,
            endUnit: defValueEndUnit,
            netAmt: 0.0,
            loanFees: 0.0,
            recievedAmt: 0.0,
            refunded: 0.0,
            selected: false,
            canEdit: false,
            numberOfDisbursements:1,
            createDisbursementDetails:false,
            isTitleIVAward:false
        };
        setSelectedAward({ ...clearAward });
    };

    const handleSaveAwardClick = (d: any) => {
        setSaveButtonDisabled(true);
        if (selectedAward) {
            let award = selectedAward.studentAwardId == EmptyGuid ? {...selectedAward, createDisbursementDetails:true} : {...selectedAward};
            StudentAwardApi.upsertAward(award).then(
                (response: any) => {
                    if (response.resultStatus === 0) {
                        setLoaderState(false);
                        setSnackBarProps((props: any) => {
                            return {
                                variant: "success",
                                showSnackBar: true,
                                messageInfo: response.resultStatusMessage,
                            };
                        });
                        refreshAwardGrid();
                        //methods.reset();
                        setEnableNewAward(false);

                    } else {
                        setLoaderState(false);
                        setSnackBarProps((props: any) => {
                            return {
                                variant: "error",
                                showSnackBar: true,
                                messageInfo: response.data,
                            };
                        });
                    }
                },
                (exception: any) => {
                    setLoaderState(false);
                    setSnackBarProps((snackBarProps: any) => {
                        return {
                            variant: "error",
                            showSnackBar: true,
                            messageInfo: exception,
                        };
                    });
                }
            ).finally(() => {
                // Enable the save button when the operation completes
                setLoaderState(false);
                setSaveButtonDisabled(false);
            });
        }
    };

    const HandleCancelChanges = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (methods.formState.dirty) {
            setConfirmationPopupState((state: any) => {
                return {
                    ...state,
                    open: true,
                    confirmationText: "Are you sure you want to cancel your changes?",
                    confirmationButton: "Yes",
                    title: "Cancel Changes",
                    onConfirmEvent: () => {
                        //methods.reset();
                        setConfirmationPopupState((updatedState: any) => {
                            return { ...updatedState, open: false };
                        });
                        clearSelectedAward();
                        setEnableNewAward(false);
                    },
                    onCancelEvent: () => {
                        setConfirmationPopupState((updatedState: any) => {
                            return { ...updatedState, open: false };
                        });
                    },
                };
            });
        } else {
            //methods.reset();
            setEnableNewAward(false);
        }
    };
    const handleDeleteAward = (event: React.MouseEvent<HTMLButtonElement>) => {
        setConfirmationPopupState((state: any) => {
            return {
                ...state,
                open: true,
                confirmationText: "Are you sure you want to delete this award?",
                confirmationButton: "Yes",
                title: "Delete Award",
                onConfirmEvent: () => {
                    deleteAward();
                    setConfirmationPopupState((updatedState: any) => {
                        return { ...updatedState, open: false };
                    });
                },
                onCancelEvent: () => {
                    setConfirmationPopupState((updatedState: any) => {
                        return { ...updatedState, open: false };
                    });
                },
            };
        });
    };

    return (
        <Paper className={classes.paperRoot}>
            <CustomSnackbar
                variant={snackBarProps.variant}
                message={snackBarProps.messageInfo}
                open={snackBarProps.showSnackBar}
                onClose={(event?: React.SyntheticEvent, reason?: string) => {
                    setSnackBarProps((props: any) => {
                        return { ...props, showSnackBar: false };
                    });
                }}
            ></CustomSnackbar>
            <FormContext {...methods}>
                <form onSubmit={handleSubmit(handleSaveAwardClick)}>
                    <AwardSelection
                        awardData={Award}
                        refreshAwardGrid={refreshAwardGrid}
                        setSelectedAward={setSelectedAward}
                        awardId={awardId}
                        setAwardId={setAwardId}
                        setFade={setfadeAwardDetails}
                    />
                    {(awardId !== EmptyGuid || enabledNewAward) && (
                        <Fade in={fadeAwardDetails} timeout={{ enter: 1500, exit: 1500 }}>
                            <Card className={classes.awardCard}>
                                <CardContent>
                                    <AwardDetail
                                        selectedAward={selectedAward}
                                        isAfaIntegrationEnabled={isAfaIntegrationEnabled}
                                        setSelectedAward={setSelectedAward}
                                        saveAward={handleSaveAwardClick}
                                    />

                                    <DisbursementDetail
                                        studentAwardId={selectedAward}
                                        setSnackBar={setSnackBarProps}
                                        refreshAwardGrid={refreshAwardGrid}
                                        selectedAward={selectedAward}
                                        setSelectedAward={setSelectedAward}
                                        isTitleIVAward={selectedAward.isTitleIVAward}
                                    />

                                    <LedgerActivity AwardLedger={AwardLedger} />
                                </CardContent>
                            </Card>
                        </Fade>
                    )}


                    <AccessManager
                        allowedPermissions={[security.permissions.student.updateAwards,security.permissions.student.deleteAwards]}
                        extraAccessData={{ selectedAward, awardId, Awards, enabledNewAward}}
                        accessCheck={(extraAccessData: any, user: any) => {
                            return (isSupportUser || extraAccessData.enabledNewAward || extraAccessData.selectedAward.canEdit || (extraAccessData.awardId !== EmptyGuid && extraAccessData.Awards.length > 0))
                        }
                    }
                        renderNoAccess={() => null}
                    >
                        {<Button
                            className={classes.buttonColorPrimary}
                            onClick={(e) => {
                                handleSubmit(handleSaveAwardClick);
                            }}
                            disabled={isSaveButtonDisabled}
                            size="small"
                            type="submit"
                        >
                            Save
						</Button>}
                    </AccessManager>


                    <AccessManager
                        allowedPermissions={[security.permissions.student.updateAwards, security.permissions.student.deleteAwards]}
                        renderNoAccess={() => null}
                    >
                        {!enabledNewAward && (<Button
                            className={classes.buttonColorSecondary}
                            onClick={(e) => {
                                handleNewAwardClick(e);
                            }}
                            size="small"
                            type="button"
                        >
                            New
					</Button>)}
                    </AccessManager>


                    {enabledNewAward && (
                        <Button
                            className={classes.buttonColorPrimary}
                            size="small"
                            onClick={(e) => {
                                HandleCancelChanges(e);
                            }}
                            type="button"
                        >
                            Cancel
                        </Button>
                    )}
                    <AccessManager
                        allowedPermissions={[security.permissions.student.deleteAwards]}
                        extraAccessData={{ selectedAward, awardId, Awards }}
                        accessCheck={(extraAccessData: any, user: any) =>
                            isSupportUser || extraAccessData.selectedAward.canEdit || (extraAccessData.awardId !== EmptyGuid && extraAccessData.Awards.length > 0)
                        }
                        renderNoAccess={() => null}
                    >
                        <Button
                            className={classes.buttonColorTertiary}
                            size="small"
                            onClick={(e) => {
                                handleDeleteAward(e);
                            }}
                            type="button"
                        >
                            Delete
						</Button>
                    </AccessManager>

                </form>
            </FormContext>
            <ConfirmationModal {...confirmationPopupState} />
        </Paper>
    );
};

export default Awards;
