import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Grid } from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import * as exitCounselingreportApi from "../../../api/reports/student/exitCounselingExportApi";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import { IExitCounselingReport } from "../../../interfaces/reports/IExitCounselingReport";
import { useSelector } from "react-redux";
import EnrollmentStatusAutoComplete from "../../../components/AutoComplete/EnrollmentStatusAutoComplete";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      minHeight: "100%",
      height: "100%",
      maxWidth: "1050px",
      padding: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    beginDateField: {
      width: "100%",
    },
    fullWidthElement: {
      width: "100%",
    },
  })
);

const ExitCounselingExport = (props: any) => {
  const { report } = props;
  let reportOverview = { report: report } as IReportOverview;
  const classes = useStyles({});

  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const data = useForm<any>({ mode: "onBlur" });
  const [model, setModel] = React.useState<IExitCounselingReport>({
    campusId: "",
    beginDate: undefined as Date | undefined,
    endDate: undefined as Date | undefined,
  });

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    setModel({ ...updatedModel });
  };

  const { handleSubmit } = data;

  const onSubmit = async (isPreview: any) => {
    let params = (await getReportParameters()) as IExitCounselingReport;
    const validation = await data.triggerValidation();
    if (validation) {
      await exitCounselingreportApi.generateCsvExtract(params);
    }
  };

  const filterHandler = (data: any) => {
    if (data) {
      setModel(data);
    } else {
      setModel({
        campusId: "",
        beginDate: undefined as Date | undefined,
        endDate: undefined as Date | undefined,
        statusIds: "",
      });
    }
  };

  const setEnrollmentStatus = (enrollmentStatus: any) => {
    let newStatuses: string[] = [];
    if (enrollmentStatus !== null) {
      if (Array.isArray(enrollmentStatus)) {
        if (enrollmentStatus.length > 1) {
          enrollmentStatus = enrollmentStatus.filter(
            (item: any) => item.value !== "All"
          );
        }
        newStatuses = enrollmentStatus.map((gr: any) => gr.value);
      } else {
        newStatuses.push(enrollmentStatus.value);
      }
    }
    return newStatuses;
  };

  const getReportParameters = async () => {
    const success = await data.triggerValidation();
    if (model && success) {
      let params: IExitCounselingReport = {
        campusId: userSelectedCampus,
        beginDate: model.beginDate,
        endDate: model.endDate,
        statusIds: model.statusIds,
      };
      return params;
    }
    return {} as IExitCounselingReport;
  };

  React.useEffect(() => {
    const currentDate = new Date();
    const defaultBeginDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const defaultEndDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );
    handleFieldOnChange("beginDate", defaultBeginDate);
    handleFieldOnChange("endDate", defaultEndDate);
  }, []);

  let parameters = (
    <div className={classes.root}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} sm={6} xs={6}>
              <EnrollmentStatusAutoComplete
                filterHandle={(statuses: any) => {
                  handleFieldOnChange(
                    "statusIds",
                    statuses,
                    setEnrollmentStatus
                  );
                }}
                id="statusIds"
                name="statusIds"
                label="Enrollment Statuses"
                valueFilter={
                  model?.statusIds
                    ? {
                        key: "value",
                        values: model?.statusIds,
                      }
                    : undefined
                }
                systemStatusId={14}
                multiple={true}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}></Grid>
            <Grid item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="beginDate"
                  name="beginDate"
                  label="Date from *"
                  value={
                    model.beginDate
                      ? model.beginDate
                      : new Date(
                          new Date().getFullYear(),
                          new Date().getMonth(),
                          1
                        )
                  }
                  onChange={(e: any, value: any) =>
                    handleFieldOnChange("beginDate", value ? value : undefined)
                  }
                  className={classes.beginDateField}
                  KeyboardButtonProps={{
                    "aria-label": "report date",
                  }}
                  error={!!data.errors["beginDate"]}
                  innerRef={(ref: any) => {
                    data.register("beginDate", {
                      required: true,
                      validate: {
                        beforeEndDate: (value) =>
                          new Date(value) <
                            new Date(data.getValues()["endDate"]) ||
                          "Begin Date must be before end date",
                      },
                    });
                    data.setValue("beginDate", model.beginDate);
                  }}
                  helperText={
                    data.errors["beginDate"]
                      ? (data.errors["beginDate"] as FieldError).message
                      : undefined
                  }
                  autoOk
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="endDate"
                  name="endDate"
                  label="Date to *"
                  value={model.endDate ? model.endDate : new Date()}
                  onChange={(e: any, value: any) =>
                    handleFieldOnChange("endDate", value ? value : null)
                  }
                  className={classes.beginDateField}
                  KeyboardButtonProps={{
                    "aria-label": "end date",
                  }}
                  error={!!data.errors["endDate"]}
                  innerRef={(ref: any) => {
                    data.register("endDate", {
                      required: true,
                      validate: {
                        beforeEndDate: (value) =>
                          new Date(value) >
                            new Date(data.getValues()["beginDate"]) ||
                          "End Date must be after Begin date",
                      },
                    });
                    data.setValue("endDate", model.endDate);
                  }}
                  helperText={
                    data.errors["endDate"]
                      ? (data.errors["endDate"] as FieldError).message
                      : undefined
                  }
                  autoOk
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </form>
      </FormContext>
    </div>
  );

  reportOverview.parameters = parameters;

  return (
    <ReportOverview
      reportOverview={reportOverview}
      filterHandler={filterHandler}
      getReportParameters={getReportParameters}
      exportHandler={onSubmit}
      preview={null}
      previewLoader={false}
      hidePreview={true}
    />
  );
};
export default ExitCounselingExport;
