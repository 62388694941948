import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { IStatusInfoRow } from "../../../interfaces/student/profile/status/IStatusInfoRow";
import {
  CustomHeadCell,
  Order,
} from "../../../interfaces/common/Table/CustomHeadCell";
import React from "react";
import { TableBody } from "@material-ui/core";
import * as TableFunctions from "../../../utils/tableFunctions";

import CustomTableHeader from "../../../components/Tables/CustomTableHeader";
import InfoStatusGridRow from "./InfoStatusGridRow";
// enhance table styling
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableWrapper: {
      width: "100%",
      height: "calc(100% - 200px)",
      overflowY: "auto",
      marginTop: theme.spacing(2),
      border: "2px solid #F4F0F0",
      marginBottom: theme.spacing(2),
    },
    mainBody: {
      height: "100%",
    },
    bodyWrapper: {
      height: 190,
      overflowY: "auto",
    },

  })
);

type InfoStatusGridProps = {
  data: Array<IStatusInfoRow>;
  setData:any;
};
const InfoStatusGrid = (props: InfoStatusGridProps) => {
  const classes = useStyles({});
  const headCells: CustomHeadCell<IStatusInfoRow>[] = [
    {
      id: "selected",
      numeric: false,
      disablePadding: false,
      label: "",
      widthType: "Option",
    },
    {
      id: "badgeNumber",
      numeric: false,
      disablePadding: false,
      label: "Badge ID",
      widthType: "S",
    },
    {
      id: "lastName",
      numeric: false,
      disablePadding: false,
      label: "Last Name",
      widthType: "M",
    },
    {
      id: "firstName",
      numeric: true,
      disablePadding: false,
      label: "First Name",
      widthType: "M",
    },
    {
      id: "currentStatus",
      numeric: true,
      disablePadding: false,
      label: "Current Status",
      widthType: "Remainder",
    },
  ];

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof IStatusInfoRow>(
    "lastName"
  );
  return (
    <div className={classes.tableWrapper}>
      <CustomTableHeader<IStatusInfoRow>
        cells={headCells}
        defaultOrder={order}
        defaultOrderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        key={"tex"}
      />
      <div className={classes.bodyWrapper}>
        <TableBody className={classes.mainBody}>
          {TableFunctions.stableSort(
            props.data,
            TableFunctions.getSorting(order, orderBy) as any
          ).map((row: IStatusInfoRow, index: any) => {
            return (
              <InfoStatusGridRow
                row={row}
                key={"status_" + row.studentEnrollmentId}
                gridData={props.data}
                setGridData={props.setData}
              />
            );
          })}
        </TableBody>
      </div>
    </div>
  );
};
export default InfoStatusGrid;
