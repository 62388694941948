import React from "react";
import {
  makeStyles,
  Card,
  createStyles,
  CardContent,
  Grid,
  Paper,
  Table,
  withStyles,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  IconButton,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { StudentProfile } from "../../../../interfaces/student/profile/StudentProfile";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useForm } from "react-hook-form";
import CloseIcon from "@material-ui/icons/Close";
import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";
import StatusAutoComplete from "../../../../components/AutoComplete/StatusAutoComplete";
import EmailTypesAutoComplete from "../../../../components/AutoComplete/EmailTypesAutoComplete";
import * as EmailApi from "../../../../api/student/profle/contactInfo/EmailApi";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%",
    },
    saveButton: {
      width: 120,
    },    
    typography: {
      padding: theme.spacing(2),
    },
    lineThru: {
      textDecoration: "line-through",
    },
    cardWrapper: {
      marginBottom: theme.spacing(2),
    },
    root: {
      paddingTop: theme.spacing(2),
      minHeight: "100%",
      height: "100%",
      width: "100%",
      margin: "auto",
    },
    paperRoot: {
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
  })
);

interface EmailDetail {
  id: string;
  leadId: string;
  emailTypeId: string;
  emailType: string;
  email: string;
  isPreferred: boolean; 
  isPortalUserName: boolean;
  statusId: string;
  status: string;
  isShowOnLeadPage: boolean;
}

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {},
  })
)(TextField);

type EmailPageProps = {
  setSnackBarProps?: any;
};

const EmailPage = (props: EmailPageProps) => {
  const classes = useStyles({});

  const data = useForm<EmailDetail>({ mode: "onBlur" });

  const selectedStudent: StudentProfile = useSelector(
    (state: any) => state.student
  ) as StudentProfile;

  const [emails, setEmails] = React.useState<EmailDetail[]>([]);
  const [isCreatingEmail, setIsCreatingEmail] = React.useState(false);
  const [newEmail, setNewEmail] = React.useState<EmailDetail | null>(null);

  React.useEffect(() => {
    getEmails();
  }, [selectedStudent]);

  // Handler to toggle form for adding a new email entry
  const handleAddEmailClick = () => {
    setNewEmail({
      id: "",
      leadId: "",
      emailTypeId: "",
      emailType: "",
      email: "",
      isPreferred: false,
      isPortalUserName: false,
      statusId: "",
      status: "",
      isShowOnLeadPage: false,
    });
    setIsCreatingEmail(true);
  };
  // Handler to update email details in the form
  const handleEmailFormChange = (field: any, value: any) => {
    if (newEmail) {
      setNewEmail({ ...newEmail, [field]: value });
    }
  };

  const handleEmailCancel = () => {
    setIsCreatingEmail(false);
    setNewEmail(null);
  };

  const deleteEmail = (id: any) => {
    setLoading(true);
    EmailApi.deleteEmail(selectedStudent.leadId, id).then(
      (response: any) => {
        props.setSnackBarProps(() => {
          return {
            variant: "success",
            showSnackBar: true,
            messageInfo: "Email deleted successfully.",
          };
        });
        setDeletePop(undefined);
        getEmails();
        setIsCreatingEmail(false);
        setLoading(false);
      },
      (exception: any) => {
        props.setSnackBarProps(() => {
          return {
            variant: "error",
            showSnackBar: true,
            messageInfo: exception,
          };
        });
        setLoading(false);
      }
    );
  };

  const getEmails = () => {
    if (selectedStudent.selectedEnrollment !== undefined) {
      if (
        selectedStudent.leadId !== undefined &&
        selectedStudent.leadId.length > 0
      ) {
        EmailApi.getAll(selectedStudent.leadId).then(
          (response: any) => {
            setEmails(response);
          },
          (exception: any) => {}
        );
      }
    }
  };

  const editEmail = (id: any) => {
    if (selectedStudent.selectedEnrollment !== undefined) {
      if (
        selectedStudent.leadId !== undefined &&
        selectedStudent.leadId.length > 0
      ) {
        EmailApi.getEmail(selectedStudent.leadId, id).then(
          (response: any) => {
            setNewEmail(response);
            setIsCreatingEmail(true);
          },
          (exception: any) => {}
        );
      }
    }
  };

  const handleSaveEmail = () => {
    if (newEmail) {
      data.triggerValidation().then((validation: any) => {
        if (validation) {
          setLoading(true);

          if (newEmail.id == "") {
            EmailApi.postEmail(selectedStudent.leadId, {
              ...newEmail,
              id: newEmail.id,
            } as any).then(
              (response: any | null) => {
                if (response && response.data && response.data.result != null) {
                  props.setSnackBarProps(() => {
                    return {
                      variant: "success",
                      showSnackBar: true,
                      messageInfo: "Email saved successfully.",
                    };
                  });
                  setIsCreatingEmail(false);
                  getEmails();
                } else {
                  props.setSnackBarProps(() => {
                    return {
                      variant: "error",
                      showSnackBar: true,
                      messageInfo:response.data.resultStatusMessage,
                    };
                  });
                }
                setLoading(false);
              },
              (exception: any) => {
                setLoading(false);
                props.setSnackBarProps(() => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: exception,
                  };
                });
              }
            );
          } else {
            EmailApi.updateEmail(selectedStudent.leadId, {
              ...newEmail,
              id: newEmail.id,
            } as any).then(
              (response: any | null) => {
                if (response && response.data && response.data.result != null) {
                  props.setSnackBarProps(() => {
                    return {
                      variant: "success",
                      showSnackBar: true,
                      messageInfo: "Email updated successfully.",
                    };
                  });
                  setIsCreatingEmail(false);
                  getEmails();
                } else {
                  props.setSnackBarProps(() => {
                    return {
                      variant: "error",
                      showSnackBar: true,
                      messageInfo:response.data.resultStatusMessage,
                    };
                  });
                }
                setLoading(false);
              },
              (exception: any) => {
                setLoading(false);
                props.setSnackBarProps(() => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: exception,
                  };
                });
              }
            );
          }
        }
      });
    }
  };

  const EmailValidation = (errorName: any) => {
    if (errorName?.type === "validate") {
      return "Email is not in correct format.";
    } else return null;
  };
  const EmailTypeValidation = (errorName: any) => {
    if (errorName?.type === "validate") {
      return "Email Type is Required.";
    } else return undefined;
  };
  const onValidation = (fieldId: string, value: any) => {
    if (data.errors && (newEmail as any)[fieldId] != value) {
      handleEmailFormChange(fieldId, value);
    }
  };

  const [deletePop, setDeletePop] = React.useState<(() => void) | undefined>(
    undefined
  );
  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <Card square={true} className={classes.cardWrapper}>
      {/* <CustomCardTitle title={"Emails"}></CustomCardTitle> */}
      <CardContent>
        <Grid container direction="row" spacing={2}>
          {/* Table to Display Existing Emails */}
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="email table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Type</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Best</TableCell>
                    <TableCell align="left">Edit</TableCell>
                    <TableCell align="left">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {emails.map((email) => (
                    <TableRow key={email.id}>
                      <TableCell align="left">{email.emailType}</TableCell>
                      <TableCell align="left">{email.email}</TableCell>
                      <TableCell align="left">{email.status}</TableCell>
                      <TableCell align="left">
                        {email.isPreferred ? "Yes" : "No"}
                      </TableCell>
                      <TableCell align="left" style={{ width: 20 }}>
                        <Tooltip title="Edit Email" style={{ padding: 0 }}>
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={(e: any) => {
                              editEmail(email.id);
                            }}
                          >
                            <EditIcon></EditIcon>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="left" style={{ width: 20 }}>
                        <Tooltip title="Delete Email" style={{ padding: 0 }}>
                          <IconButton
                            onClick={() => {
                              setDeletePop(() => () => deleteEmail(email.id));
                            }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            {!isCreatingEmail && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddEmailClick}
                size="small"
              >
                Add Email
              </Button>
            )}
          </Grid>
          {isCreatingEmail && newEmail && (
            <React.Fragment>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1">Add New Email</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <EmailTypesAutoComplete
                          filterHandle={(v: any) => {
                            handleEmailFormChange(
                              "emailTypeId",
                              v ? v.value : undefined
                            );
                          }}
                          label={"Email Type *"}
                          id="emailTypeId"
                          name="emailTypeId"
                          inputRef={data.register({
                            validate: (value: any) => {
                              if (newEmail.email && !value) return false;
                              else return true;
                            },
                          })}
                          helperText={EmailTypeValidation(
                            data.errors.emailTypeId
                          )}
                          error={!!data.errors.emailTypeId}
                          valueFilter={
                            newEmail && newEmail.emailTypeId
                              ? {
                                  key: "value",
                                  values: [newEmail.emailTypeId],
                                }
                              : undefined
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ThemeTextField
                          id="email"
                          name="email"
                          className={classes.textField}
                          label={"Email *"}
                          margin="none"
                          value={newEmail.email || ""}
                          inputRef={data.register({
                            validate: (value: any) => {
                              onValidation("email", value);
                              if (newEmail.emailTypeId && !value) return false;
                              else
                                return /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}\s*$/.test(
                                  value
                                );
                            },
                          })}
                          helperText={EmailValidation(data.errors.email)}
                          error={!!data.errors.email}
                          onChange={(v: any) => {
                            handleEmailFormChange(
                              "email",
                              v ? v.target.value : undefined
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={newEmail.isPreferred}
                              onChange={(e) =>
                                handleEmailFormChange(
                                  "isPreferred",
                                  e.target.checked
                                )
                              }
                              color="primary"
                            />
                          }
                          label="Is Preferred"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={newEmail.isPortalUserName}
                              onChange={(e) =>
                                handleEmailFormChange(
                                  "isPortalUserName",
                                  e.target.checked
                                )
                              }
                              color="primary"
                            />
                          }
                          label="Is Portal User Name"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <StatusAutoComplete
                          id="statusId"
                          name="statusId"
                          label="Status *"
                          valueFilter={
                            newEmail.statusId
                              ? {
                                  key: "value",
                                  values: [newEmail.statusId],
                                }
                              : undefined
                          }
                          filterHandle={(v: any) => {
                            handleEmailFormChange(
                              "statusId",
                              v ? v.value : null
                            );
                          }}
                          error={!!data.errors.statusId}
                          inputRef={data.register({ required: true })}
                          helperText={
                            data.errors.statusId
                              ? "Status is required."
                              : undefined
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ProgressSaveButton
                          text="Save Email"
                          onClick={() => {
                            handleSaveEmail();
                          }}
                          size="small"
                          loading={loading}
                          buttonClassName={classes.saveButton}
                        ></ProgressSaveButton>
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          onClick={handleEmailCancel}
                          style={{ marginLeft: 5 }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </CardContent>
      <Dialog
        open={!!deletePop}
        onClose={() => !loading && setDeletePop(undefined)}
      >
        <DialogTitle id="customized-dialog-title">
          <Typography variant="h6">Delete Confirmation</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => !loading && setDeletePop(undefined)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this email?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ProgressSaveButton
            loading={loading}
            onClick={() => deletePop !== undefined && deletePop()}
            text="Delete"
            type="button"
          />{" "}
          <Button
            variant="contained"
            color="default"
            onClick={() => !loading && setDeletePop(undefined)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default EmailPage;
