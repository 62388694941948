import API from "../../apiWrapper";
import { AxiosResponse, CancelToken } from "axios";
import { IPendingTerminationDetails } from '../../../interfaces/academicRecords/IPendingTerminationDetails';
import { IPendingRefund } from '../../../interfaces/student/financials/IPendingRefund';

const getPendingTerminations = (
    campusId: string
) => {
    return API()
        .get("/AcademicRecords/StudentTermination/GetPendingTerminations", {
            params: {
                campusId
            }
        })
        .then(
            (res: AxiosResponse<IPendingTerminationDetails>) => {
                if (res && res.data) {
                    return res;
                }
            },
            (error: any) => {
                return error;
            }
        );
};

const getPendingRefunds = (
    campusId: string,
    cancelToken:CancelToken
) => {
    return API()
        .get("/AcademicRecords/StudentTermination/GetTerminationRefundsDue", {
            params: {
                campusId
            },
            cancelToken
        })
        .then(
            (res: AxiosResponse<IPendingRefund>) => {
                if (res && res.data) {
                    return res;
                }
            },
            (error: any) => {
                return error;
            }
        );
};

export const printRefundsDueReport = (campusId: any) => {
    return API()
      .post("/AcademicRecords/StudentTermination/PrintRefundsDueReport", campusId, {
        responseType: "blob",
      })
      .then(
        (res: any) => {
          if (res && res.data) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            let newDate = new Date();
            var toDay =
              newDate.getMonth() +
              1 +
              "-" +
              newDate.getDate() +
              "-" +
              newDate.getFullYear();
            let fileName =
              "REFUNDS_DUE_" +
              toDay +
              ".pdf";
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          }
          return res;
        },
        (error: any) => {
          return error.response;
        }
      );
  };

export const terminationWidgetApi = { getPendingTerminations, getPendingRefunds, printRefundsDueReport };