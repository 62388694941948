import { useEffect } from "react";

const useOutsideClick = (ref: any, callback: any) => {
	const handleClick = (e: any) => {
		if (e.type === "blur" || (ref.current && !ref.current.contains(e.target))) {
			callback();
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClick);
		window.addEventListener("blur", handleClick);

		return () => {
			document.removeEventListener("mousedown", handleClick);
			window.removeEventListener("blur", handleClick);
		};
	});
};

export default useOutsideClick;
