import * as React from 'react';
import { makeStyles, createStyles, useTheme } from '@material-ui/styles';
import { Paper } from '@material-ui/core';
import MaterialTable from 'material-table'
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import IPlacementHistory from '../../../interfaces/student/placement/PlacementHistory';
import { fontFamily } from '@material-ui/system';

const useStyles = makeStyles((theme: any) =>
    createStyles({
        drawer: {
            width: 280
        },
        root: {
            backgroundColor: theme.palette.background.paper,
            '& .MuiCardContent-root': {
                paddingTop: theme.spacing(0),
                paddingLeft: theme.spacing(2)
            }
        },
        '& .makeStyles-content': {
            backgroundColor: theme.palette.background.paper
        },
        cardTitle: {
            fontSize: 17,
            color: theme.palette.black,
            textDecoration: 'bold',
            backgroundColor: theme.palette.iconSecondaryHover,
            padding: theme.spacing(1, 1)
        },
        textField: {
            marginRight: theme.spacing(1),
            width: '100%'
        },
        headerText: {
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(0)
        },
        tableWrapper: {
            width: '100%'
        }
    })
);

const tableIcons = {
    SortArrow: React.forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
        <ArrowUpward {...props} ref={ref} />
    ))
};
type PlacementHistoryGridProps = {
    placementList: IPlacementHistory[];
    selectedPlacementHistoryId?: string;
    selectPlacementHistory(details :IPlacementHistory): void;
};
const PlacementHistorySelection = (props: PlacementHistoryGridProps) => {
    const { placementList, selectPlacementHistory, selectedPlacementHistoryId } = props;
    const classes = useStyles({});
    const theme = useTheme<any>();
    return (

        <MaterialTable
            columns={[
                { title: 'Employment Type', field: 'employmentTypeDesc' },
                { title: 'Employer Name', field: 'employerTitle' },
                { title: 'Job', field: 'employerJobTitle' },
                { title: 'Field of Study', field: 'fieldOfStudyTitle' },
                {
                    title: 'Date Hired',
                    field: 'dateHired',
                    type: 'date',
                }
            ]}
            onRowClick={(_event: any, rowData?: IPlacementHistory) => {
                selectPlacementHistory(rowData ?? {studentPlacementId: undefined});
            }}
            icons={tableIcons}
            data={placementList}
            options={{
                search: false,
                paging: false,
                showTitle: false,
                toolbar: false,
                sorting: false,
                headerStyle: {
                    backgroundColor: theme.palette.secondary.main,
                    zIndex: 0
                },
                rowStyle: (rowData: IPlacementHistory) => {
                    if (rowData.studentPlacementId === selectedPlacementHistoryId) {
                        return {
                            backgroundColor: theme.palette.secondary.main,
                            fontFamily: "Roboto"
                        };
                    } else {
                        return {
                            backgroundColor: 'white',
                            fontFamily: "Roboto"
                        };
                    }
                }
            }}
            components={{
                Container: props => (
                    <Paper className={classes.tableWrapper} {...props} />
                )
            }}
        />
    );
};
export default PlacementHistorySelection;
