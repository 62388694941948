import API from "../../apiWrapper";
import ICreditCourse from '../../../interfaces/setup/academics/ProgramDefinition/ICreditCourse';
import { IProgramCourseComponent } from "../../../interfaces/setup/academics/ProgramDefinition/IProgramCourse";
import ICreditCourseDetails from "../../../interfaces/setup/academics/ProgramDefinition/ICreditCourseDetails";
import ICreditCourseGradeBook from "../../../interfaces/setup/academics/ProgramDefinition/ICourseGradeBook";
import ICreditCourseGradeBookDetails from "../../../interfaces/setup/academics/ProgramDefinition/ICourseGradeBookDetails";
import INewComponentType from "../../../interfaces/setup/academics/ProgramDefinition/INewComponentType";

export const getAllCourses = (campusId: string): Promise<ICreditCourse[]> => {
    return API()
        .get("/AcademicRecords/CreditCourses/GetCoursesListByCampus", {
            params: {
                campusId
            }
        })
        .then((res: any) => {
            if (res && res.data) {
                return res.data;
            }
            return [];
        }, (_error: any) => {
            return [];
        });
};

export const getAllCreditCourses = (showAll: boolean): Promise<ICreditCourseDetails[]> => {
    return API()
        .get("/AcademicRecords/CreditCourses/GetCoursesList",{
            params: {
                showAll
            }
        })
        .then((res: any) => {
            if (res && res.data) {
                return res.data;
            }
            return [];
        }, (_error: any) => {
            return [];
        });
};

export const UpsertCourse = (courseDetails: ICreditCourseDetails, courseDetailId: string | null) => {
    return API()
        .post("/AcademicRecords/CreditCourses/UpsertCourse", courseDetails, {
            params: {
                courseDetailId
            }
        })
        .then((res: any) => {
            if (res && res.data && res.data.result) {
                return {
                    courseDetails: res.data.result,
                    message: res.data.resultStatusMessage
                };
            }
            throw (new Error('Unknown error'));
        }, (error: any) => {
            if (error.response) {
                throw new Error(error.response.data); // => the response payload 
            }
            throw (new Error('Unknown error'));
        });
};

export const GetCourseDetails = (courseId: string): Promise<ICreditCourseDetails> => {
    return API()
        .get("/AcademicRecords/CreditCourses/GetCourseDetails", {
            params: {
                courseId
            }
        })
        .then((res: any) => {
            if (res && res.data && res.data.result) {
                return res.data.result
            }
            throw (new Error('Unknown error'));
        }, (error: any) => {
            if (error.response) {
                throw new Error(error.response.data); // => the response payload 
            }
            throw (new Error('Unknown error'));
        });
};

export const GetCourseDetailByCourseId = (courseId: string): Promise<ICreditCourseDetails[]> => {
    return API()
        .get("/AcademicRecords/CreditCourses/GetCourseDetailByCourseId", {
            params: {
                courseId
            }
        })
        .then((res: any) => {
            if (res && res.data) {
                return res.data;
            }
            throw (new Error('Unknown error'));
        }, (error: any) => {
            if (error.response) {
                throw new Error(error.response.data); // => the response payload 
            }
            throw (new Error('Unknown error'));
        });
};

export const GetCourseDetailByDetailId = (detailId: string): Promise<ICreditCourseDetails> => {
    return API()
        .get("/AcademicRecords/CreditCourses/GetCourseDetailByDetailId", {
            params: {
                detailId
            }
        })
        .then((res: any) => {
            if (res && res.data) {
                return res.data;
            }
            throw (new Error('Unknown error'));
        }, (error: any) => {
            if (error.response) {
                throw new Error(error.response.data); // => the response payload 
            }
            throw (new Error('Unknown error'));
        });
};

export const CopyCourse = (courseId: string): Promise<{ details: ICreditCourseDetails, message: string }> => {
    return API()
        .get("/AcademicRecords/CreditCourses/CopyCourse", {
            params: {
                courseId
            }
        })
        .then((res: any) => {
            if (res && res.data && res.data.result) {
                return {
                    details: res.data.result,
                    message: res.data.resultStatusMessage
                };
            }
            throw (new Error('Unknown error'));
        }, (error: any) => {
            if (error.response) {
                throw new Error(error.response.data); // => the response payload 
            }
            throw (new Error('Unknown error'));
        });
};

export const getCourseComponents = (courseId: string): Promise<IProgramCourseComponent[]> => {
    return API()
        .get("/AcademicRecords/ProgramCatalog/GetCourseComponents", {
            params: {
                courseId
            }
        })
        .then((res: any) => {
            if (res && res.data) {
                return res.data;
            }
            return [];
        }, (_error: any) => {
            return [];
        });
}

export const getCourseGradeBooks = (courseId: string): Promise<ICreditCourseGradeBook[]> => {
    return API()
        .get("/AcademicRecords/CreditCourses/GetCourseGradeBooks", {
            params: {
                courseId
            }
        })
        .then((res: any) => {
            if (res && res.data) {
                return res.data
            }
            return [];
        }, (error: any) => {
            if (error.response) {
                throw new Error(error.response.data); // => the response payload 
            }
            throw (new Error('Unknown error'));
        });
}

export const getCourseGradeBookDetails = (gradeBookId: string): Promise<ICreditCourseGradeBookDetails> => {
    return API()
        .get("/AcademicRecords/CreditCourses/GetCourseGradeBookDetails", {
            params: {
                gradeBookId
            }
        })
        .then((res: any) => {
            if (res && res.data && res.data.result) {
                return res.data.result
            }
            throw (new Error('Unknown error'));
        }, (error: any) => {
            if (error.response) {
                throw new Error(error.response.data); // => the response payload 
            }
            throw (new Error('Unknown error'));
        });
};

export const CopyCourseGradeBook = (gradeBookId: string, courseId: string): Promise<{ details: ICreditCourseGradeBookDetails, message: string }> => {
    return API()
        .get("/AcademicRecords/CreditCourses/CopyCourseGradeBooks", {
            params: {
                gradeBookId,
                courseId
            }
        })
        .then((res: any) => {
            if (res && res.data && res.data.result) {
                return {
                    details: res.data.result,
                    message: res.data.resultStatusMessage
                };
            }
            throw (new Error('Unknown error'));
        }, (error: any) => {
            if (error.response) {
                throw new Error(error.response.data); // => the response payload 
            }
            throw (new Error('Unknown error'));
        });
};

export const upsertCourseGradeBook = (courseDetails: ICreditCourseGradeBookDetails, courseId: string): Promise<{ details: ICreditCourseGradeBookDetails, message: string }> => {
    return API()
        .post("/AcademicRecords/CreditCourses/UpsertCourseGradeBook", courseDetails, {
            params: {
                courseId
            }
        })
        .then((res: any) => {
            if (res && res.data && res.data.result) {
                return {
                    details: res.data.result,
                    message: res.data.resultStatusMessage
                };
            }
            throw (new Error('Unknown error'));
        }, (error: any) => {
            if (error.response) {
                throw new Error(error.response.data); // => the response payload 
            }
            throw (new Error('Unknown error'));
        });
};

export const createNewComponent = (componentType: INewComponentType): Promise<{ details: INewComponentType, message: string }> => {
    return API()
        .post("/AcademicRecords/CreditGradeBookComponentTypes/CreateComponent", componentType, {
        })
        .then((res: any) => {
            if (res && res.data && res.data.result) {
                return {
                    details: res.data.result,
                    message: res.data.resultStatusMessage
                };
            }
            throw (new Error('Unknown error'));
        }, (error: any) => {
            if (error.response) {
                throw new Error(error.response.data); // => the response payload 
            }
            throw (new Error('Unknown error'));
        });
};

const getByteArray = (file: any): Promise<any[] | undefined> => {
    return new Promise(function (resolve, reject) {
        let fileReader = new FileReader();

        fileReader.readAsArrayBuffer(file);
        fileReader.onload = function (ev) {
            if (ev.target && ev.target.result) {
                const array = new Uint8Array(ev.target.result as ArrayBuffer);
                const fileByteArray = [];
                for (let i = 0; i < array.length; i++) {
                    fileByteArray.push(array[i]);
                }
                resolve(fileByteArray); // successful
            } else resolve(undefined);
        };
        fileReader.onerror = reject; // call reject if error
    });
}

export const ImportComponents = async (campusId: string, data: File | null) : Promise<{ errorList: Array<{values: string; error: string}>, message: string }> => {
    const buffer = await getByteArray(data);
    return API()
    .post("/AcademicRecords/CreditGradeBookComponentTypes/ImportComponents", {
        data: buffer,
        fileType: data?.type,
        fileSize: data?.size
    }, {
        params: {
            campusId
        }
    })
    .then((res: any) => {
        if (res && res.data && res.data) {
            return {
                errorList: res.data.result,
                message: res.data.resultStatusMessage
            };
        }
        throw (new Error('Unknown error'));
    }, (error: any) => {
        if (error.response) {
            throw new Error(error.response); // => the response payload 
        }
        throw (new Error('Unknown error'));
    });
}