import * as React from "react";
import clsx from "clsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import { Typography, Card, CardContent, InputAdornment, Checkbox, FormControlLabel, IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import MaterialTable from "material-table";
import PermissionAutoComplete from "../../../../../components/AutoComplete/PermissionAutoComplete";
import { forwardRef } from "react";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import { Permission } from "../../../../../interfaces/security/Permission";
import ClearIcon from '@material-ui/icons/Clear';
import { useTheme } from '@material-ui/core/styles';
import TableIcons from "../../../../../components/Tables/TableIcons"
import { PermissionTemplateDetail } from "../../../../../interfaces/security/PermissionTemplateDetail";
import PermissionTemplateAutoComplete from "../../../../../components/AutoComplete/PermissionTemplateAutoComplete";
import { PermissionTemplate } from "../../../../../interfaces/security/PermissionTemplate";
import * as permissionsApi from '../../../../../api/security/permissionsApi'
import { groupBy } from "../../../../../utils/arrayFunctions";

const useStyles = makeStyles((theme: any) =>
    createStyles({
        drawer: {
            width: 280
        },
        root: {
            backgroundColor: theme.palette.paper.background,
            "& .MuiCardContent-root": {
                paddingTop: theme.spacing(0),
                paddingLeft: theme.spacing(2)
            }
        },
        "& .makeStyles-content": {
            backgroundColor: theme.palette.paper.background
        },
        cardTitle: {
            fontSize: 17,
            color: theme.palette.black,
            textDecoration: "bold",
            backgroundColor: theme.palette.site.secondary,
            padding: theme.spacing(1, 1)
        },
        textField: {
            marginRight: theme.spacing(1),
            width: "100%"
        },
        headerText: {
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(0)
        },
        cardContent: {
            padding: theme.spacing(1, 3)
        }
    })
);

const ThemeTextField = withStyles((theme: any) => createStyles({}))(TextField);

type IUserPermissionsProps = {
    userPermissions?: Array<PermissionTemplateDetail>,
    setTemplateDetails?: any,

}

const convertToTemplateDetails = (permissions: Array<Permission> | undefined) => {

    if (permissions)
        return permissions.map((p: Permission) => { return { permissionId: p.permissionId, isEnabled: true, permission: p } as PermissionTemplateDetail })

    return [];
}

const UserPermissions = React.memo((props: IUserPermissionsProps) => {
    const classes = useStyles({});
    const userPermissions = props.userPermissions || [];
    const setParentTemplateDetails = props.setTemplateDetails;
    const [selectedTemplateIds, setSelectedTemplateIds] = React.useState<Array<number>>([]);
    const [templateDetails, setTemplateDetails] = React.useState<Array<PermissionTemplateDetail>>(userPermissions);
    const [workingTemplateDetails, setWorkingTemplateDetails] = React.useState<Array<PermissionTemplateDetail>>([]);
    const [selectedOnly, setSelectedOnly] = React.useState(false);
    const theme = useTheme();
    const selectedPermissions = templateDetails?.filter(p => p.isEnabled) || [];


    React.useEffect(() => {
        if (setParentTemplateDetails) {
            setParentTemplateDetails(templateDetails);
        }
    }, [templateDetails])

    const onPermissionTemplateChange = (data: Array<PermissionTemplate>) => {
        setSelectedTemplateIds(data.map(t => t.permissionTemplateId));
    }

    const mergeTemplates = (templates: Array<Array<PermissionTemplateDetail>>) => {
        let mergedTemplates = ([] as Array<PermissionTemplateDetail>).concat(...templates);
        let newTemplate = groupBy(mergedTemplates, "permissionId")
        return newTemplate.map((g: Array<PermissionTemplateDetail>) => {

            return g.sort(function (a: PermissionTemplateDetail, b: PermissionTemplateDetail) { return +b.isEnabled - +a.isEnabled })[0];
        });
    }

    React.useEffect(() => {
        if (selectedTemplateIds.length > 0) {
            permissionsApi.getPermissionTemplateDetails(selectedTemplateIds).then(
                (response: any) => {
                    if (response) {
                        const newTemplateDetails = response as Array<PermissionTemplateDetail>;

                        if (userPermissions.length > 0 || workingTemplateDetails.length > 0) {
                            const merged = mergeTemplates([userPermissions, workingTemplateDetails, newTemplateDetails]);
                            setTemplateDetails(merged);
                        }
                        else {
                            setTemplateDetails(newTemplateDetails);
                        }
                    }
                },
                (exception: any) => { }
            );
        }
        else {
            if (userPermissions.length > 0 || workingTemplateDetails.length > 0) {
                const details = mergeTemplates([userPermissions, workingTemplateDetails]);
                setTemplateDetails(details);

            }
            else {
                setTemplateDetails([]);

            }
        }

    }, [selectedTemplateIds])


    const columns = [
        {
            field: 'select',
            Title: 'Select',
            searchable: false,
            render: (rowData: any) => <Checkbox
                checked={rowData.isEnabled}
                onChange={(event: any) => handlePermissionCheck(rowData)}
                value="primary"
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        },
        { title: "Permission", field: "permission.permissionName", searchable: true },
        { title: "Description", field: "permission.permissionDescription", searchable: true },
        {
            field: 'delete',
            Title: 'Delete',
            searchable: false,
            render: (rowData: any) => <IconButton onClick={(event: any) => { handlePermissionDeletion(rowData); }}
                size="small"
            >
                <ClearIcon />
            </IconButton>
        },
        { title: "Permission Group", field: "permission.permissionGroup.description", defaultGroupOrder: 0, searchable: true },
    ]


    const handleViewSelectedOnly = (event: React.ChangeEvent<HTMLInputElement>) => {

        setSelectedOnly(event.target.checked);
    }

    const handleTemplateDetailChange = (templateDetails: Array<PermissionTemplateDetail>) => {

        setTemplateDetails(templateDetails);

        //if (setParentTemplateDetails)
        //    setParentTemplateDetails(templateDetails);
    }

    const handlePermissionSelection = (item: Permission) => {
        if (item) {
            let newTemplateDetail: PermissionTemplateDetail = { permissionId: item.permissionId, isEnabled: false, permission: item }
            handleTemplateDetailChange([...templateDetails, newTemplateDetail]);
            setWorkingTemplateDetails([...workingTemplateDetails, newTemplateDetail]);

        }
    }
    const handlePermissionDeletion = (item: PermissionTemplateDetail) => {
        handleTemplateDetailChange(templateDetails.filter((p: any) => p.permissionId !== item.permissionId));
        setWorkingTemplateDetails(workingTemplateDetails.filter((p: any) => p.permissionId !== item.permissionId));

    }

    const handlePermissionCheck = (item: PermissionTemplateDetail) => {

        handleTemplateDetailChange(templateDetails.map((val: any, index: number) => {
            if (val.permissionId == item.permissionId) {
                val.isEnabled = !item.isEnabled;
            }
            return val;
        }));

     
    }

    return (
        <div>
            <Card square={true}>
                <Typography className={clsx(classes.cardTitle)}>
                    <div className={classes.headerText}>Permissions</div>
                </Typography>
                <CardContent className={classes.cardContent}>
                    <Grid container direction='row' spacing={2}>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                        >

                            <Grid item xs={8}>

                                <PermissionTemplateAutoComplete filterHandle={onPermissionTemplateChange} />

                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <PermissionAutoComplete
                                filterHandle={handlePermissionSelection}
                                valueFilter={{
                                    key: "",
                                    values: [""]
                                }}
                                optionsFilter={(opt: any) => { let f = !templateDetails.map(td => td.permissionId).includes(opt.permissionId); return f; }}
                                renderInput={(params: any) => (
                                    <ThemeTextField {...params} label='Search Permissions' InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                <SearchOutlinedIcon />
                                            </React.Fragment>
                                        )
                                    }} fullWidth />
                                )}
                            />{" "}
                        </Grid>
                        <Grid item xs={8}>
                            <FormControlLabel
                                control={
                                    <Checkbox color="primary" checked={selectedOnly} onChange={handleViewSelectedOnly} />
                                }
                                label="View Selected Only"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <MaterialTable
                                columns={columns}
                                icons={TableIcons}
                                data={selectedOnly ? selectedPermissions : templateDetails}
                                options={{
                                    defaultExpanded: true,
                                    selection: false,
                                    search: true,
                                    paging: false,
                                    grouping: true,
                                    showTitle: false,
                                    toolbar: true,
                                    maxBodyHeight: 500,
                                    
                                    headerStyle: {
                                        backgroundColor: theme.palette.secondary.main,
                                        zIndex: 10
                                    },
                                    rowStyle: rowData => ({
                                        fontFamily: "Roboto",
                                        backgroundColor:
                                            rowData.period === "7/1/2018-6/30/2019"
                                                ? theme.palette.secondary.main
                                                : "#FFF"
                                    })
                                }}
                            />
                        </Grid>



                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
})

export default UserPermissions;
