import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import AdvantageViewer from "../../../../components/Common/AdvantageViewer";
import { useSelector } from "react-redux";
import { Enrollment } from "../../../../interfaces/student/academics/Enrollment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      paddingTop: theme.spacing(3),
      overflowX: "hidden",
      overflowY: "auto",
    },
  })
);

const PaymentPlans = (props: any) => {
  const classes = useStyles({});
  const studentId = useSelector<any, any>(
    (state: any) => state.student.studentId
  );

  const selectedEnrollment: Enrollment = useSelector(
    (state: any) => state.student.selectedEnrollment
  );

  const handleCallBack = () => {
  };
  
  return (
    <div className={classes.root}>
      <AdvantageViewer
        url="/FA/StudentPaymentPlan.aspx?"
        onCallBack={handleCallBack}
        params={{
          cmpId: selectedEnrollment?.campusId,
          studentId: studentId,
          enrollmentId: selectedEnrollment?.stuEnrollId,
          resId: 303,
          desc: "Payment Plans",
          mod: "FA",
        }}
      />
    </div>
  );
};

export default PaymentPlans;
