import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useEffect } from "react";
import { FieldError, FormContext, useForm } from "react-hook-form";
import * as disbursementDetailsApi from "../../../../api/student/studentAccounts/disbursementDetailsApi";
import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";
import InputField from "../../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../../constants/uiConstants/inputConstants";
import { IDisbursement } from "../../../../interfaces/student/financials/IDisbursement";

type DisbursementModalProps = {
  studentAwardID?:string
  refreshGrid: any;
  setOpen: any;
  open: boolean;
  setSnackBar: any;
  disbursementId: string;
  setDisbursementId: any;
  maxSequenceNumber: any;
};

export const ConvertCurrencyValueToInput = (value: any) => {
  return Number((value + "").replace("$ ", "").replace(/,/g, ""));
};
const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .MuiPaper-rounded": {
        borderRadius: 0,
      },
      "& .MuiDialogContent-root": {
        minHeight: 250,
      },
    },
    dialogHeader: {
      backgroundColor: theme.palette.site.secondary,
      "& .MuiTypography-root": {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: "12pt",
      },
    },
    saveButton: {
      width: 90,
    },
    buttonSection: {
      marginTop: theme.spacing(3),
    },
  })
);

const DisbursementModal = (props: DisbursementModalProps) => {
  const classes = useStyles({});
  const [model, setModel] = React.useState<IDisbursement>();
  const handleClose = () => {
    props.setOpen(false);
  };
  const data = useForm<any>({ mode: "onBlur" });
  const { handleSubmit } = data;
  const [loaderState, setLoaderState] = React.useState<boolean>(false);

  const handleFieldOnChange = (fieldId: string, value: any) => {
    let updatedModel = model;

    if (isDateField(value) && isDateInvalid(value)) 
      return;
    
    (updatedModel as any)[fieldId] = value;

    if (updatedModel) {
      checkAllDates(updatedModel);
      setModel({ ...updatedModel });
    }

    data.setValue(fieldId, value);
    
  };

  const isDateField = (value: any) => {
    return Object.prototype.toString.call(value) === '[object Date]'
  }

  const isDateInvalid = (value: any) => {
    return value.toString() === "Invalid Date"
  }

  const checkAllDates = (model: any) => {
    if(model.beginDate == undefined){
      model.beginDate = new Date();
    }

    if(model.endDate == undefined){
      model.endDate = new Date();
    }

    if(model.scheduledDisbursementDate == undefined){
      model.scheduledDisbursementDate = new Date();
    }
  }

  const GetDisbursementDetail = React.useCallback(() => {
    
    if (props.disbursementId)
      disbursementDetailsApi
        .GetDisbursementDetail(props.disbursementId)
        .then((response) => {
          if (response) {
            let newModel = response.data.result;
            setModel({ ...newModel});
          }
        });
  }, [props.disbursementId]);

  useEffect(() => {
    GetDisbursementDetail();
  }, [props.open, setModel, GetDisbursementDetail]);

  const onSave = (d: any) => {
    if (model) {
      model.studentAwardId = props.studentAwardID;
      model.sequenceNumber = props.maxSequenceNumber + 1;
      data.triggerValidation().then((validation: any) => {
        if (validation) {
          // alert(JSON.stringify(model));
          setLoaderState(true);
          disbursementDetailsApi.UpdateDisbursement(model).then(
            (response: any) => {
              if (response.resultStatus === 0) {
                setLoaderState(false);
                props.refreshGrid();
                props.setSnackBar((props: any) => {
                  return {
                    variant: "success",
                    showSnackBar: true,
                    messageInfo: response.resultStatusMessage,
                  };
                });
                handleClose();
              } else {
                setLoaderState(false);
                props.setSnackBar((props: any) => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: response.data,
                  };
                });
              }
            },
            (exception: any) => {
              setLoaderState(false);
              props.setSnackBar((snackBarProps: any) => {
                return {
                  variant: "error",
                  showSnackBar: true,
                  messageInfo: exception,
                };
              });
            }
          );
        }
      });
    }
  };
  const ThemeTextField = withStyles((theme: any) =>
    createStyles({
      root: {},
    })
  )(TextField);

  return (
    <FormContext {...data}>
      <form onSubmit={handleSubmit(onSave)}>
        {model && (
          <div>
            <Dialog
              open={props.open}
              onClose={handleClose}
              className={classes.root}
              aria-labelledby="form-dialog-title"
              maxWidth="xs"
              fullWidth={true}
              disableBackdropClick
            >
              <DialogTitle
                id="form-dialog-title"
                className={classes.dialogHeader}
              >
                {"Edit Disbursement"}
              </DialogTitle>
              <DialogContent>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} sm={12} md={4}>
                    <InputField
                      type={InputType.NUMBER}
                      id="beginUnit"
                      label="Begin"
                      name="beginUnit"
                      key="beginUnit"
                      decimal
                      defaultValue={model?.beginUnit ?? 0}
                      onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                        handleFieldOnChange("beginUnit", v.target.value);
                      }}
                      error={!!data.errors.beginUnit}
                      inputRef={data.register({
                        required: "Begin is Required.",
                        validate: {
                          beginUnitGreaterThanZero: (value) =>
                            ConvertCurrencyValueToInput(value) > 0 ||
                            "Begin must be greater than 0",
                          lessThanEndUnit: (value) =>
                            Number(value) < data.getValues()["endUnit"] ||
                            "Begin Unit must be less than End Unit",
                        },
                      })}
                      helperText={
                        data.errors.beginUnit
                          ? (data.errors.beginUnit as FieldError).message
                          : undefined
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        id="beginDate"
                        label=" "
                        TextFieldComponent={ThemeTextField}
                        value={model?.beginDate}
                        autoOk={true}
                         onChange={(value: any) => {
                          handleFieldOnChange(
                            "beginDate",
                            value ? value : null
                          );
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        error={!!data.errors.beginDate}
                        innerRef={(ref: any) => {
                          data.register("beginDate", {
                            validate: {
                              beforeEndDate: (value) =>
                                new Date(value) < data.getValues()["endDate"] ||
                                "Begin Date must be before end date",
                              requiredStartDate: (value) =>
                                value !== null || "Begin Date is required",
                            },
                          });
                          data.setValue(
                            "beginDate",
                            model?.beginDate
                              ? new Date(model?.beginDate.toString())
                              : null
                          );
                        }}
                        helperText={
                          data.errors.beginDate
                            ? (data.errors.beginDate as FieldError).message
                            : undefined
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <InputField
                      type={InputType.NUMBER}
                      id="endUnit"
                      label="End"
                      name="endUnit"
                      key="endUnit"
                      decimal
                      defaultValue={model?.endUnit ?? 0}
                      onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                        handleFieldOnChange("endUnit", v.target.value);
                      }}
                      error={!!data.errors.endUnit}
                      inputRef={data.register({
                        required: "End is Required.",
                        validate: {
                          endUnitGreaterThanZero: (value) =>
                            ConvertCurrencyValueToInput(value) > 0 ||
                            "End must be greater than 0",
                          greaterThanBeginUnit: (value) => {
                            data.errors.beginUnit = "";
                            return Number(value) > data.getValues()["beginUnit"] ||
                            "End must be greater than Begin Unit"
                          }
                        },
                      })}
                      helperText={
                        data.errors.endUnit
                          ? (data.errors.endUnit as FieldError).message
                          : undefined
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        name="endDate"
                        id="endDate"
                        label=" "
                        TextFieldComponent={ThemeTextField}
                        value={model?.endDate}
                        autoOk={true}
                        onChange={(value: any) => {
                          handleFieldOnChange("endDate", value ? value : null);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        error={!!data.errors.endDate}
                        innerRef={(ref: any) => {
                          data.register("endDate", {
                            validate: {
                              afterBeginDate: (value) =>
                                new Date(value) >
                                  data.getValues()["beginDate"] ||
                                "End Date must be after begin date",
                              requiredEndDate: (value) =>
                                value !== null || "End Date is required",
                            },
                          });
                          data.setValue(
                            "endDate",
                            model?.endDate
                              ? new Date(model?.endDate.toString())
                              : null
                          );
                        }}
                        helperText={
                          data.errors.endDate
                            ? (data.errors.endDate as FieldError).message
                            : undefined
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <InputField
                      type={InputType.CURRENCY}
                      id="scheduledDisbursementAmount"
                      label="Scheduled amount"
                      name="scheduledDisbursementAmount"
                      key="scheduledDisbursementAmount"
                      decimal
                      thousandSeparator
                      defaultValue={model?.scheduledDisbursementAmount ?? 0}
                      onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                        handleFieldOnChange(
                          "scheduledDisbursementAmount",
                          v.target.value
                        );
                      }}
                      error={!!data.errors.scheduledDisbursementAmount}
                      inputRef={data.register({
                        required: "Scheduled amount is required",
                        validate: {
                          greaterOrEqualThanActual: (value) =>
                            ConvertCurrencyValueToInput(value) >=
                              (model?.netAmount ?? 0) ||
                            "Scheduled cannot be less than Net amount",
                        },
                      })}
                      helperText={
                        data.errors.scheduledDisbursementAmount
                          ? (
                              data.errors
                                .scheduledDisbursementAmount as FieldError
                            ).message
                          : undefined
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        name="scheduledDisbursementDate"
                        id="scheduledDisbursementDate"
                        label=" "
                        TextFieldComponent={ThemeTextField}
                        value={model?.scheduledDisbursementDate}
                        autoOk={true}
                        onChange={(value: any) => {
                          handleFieldOnChange(
                            "scheduledDisbursementDate",
                            value ? value : null
                          );
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        error={!!data.errors.scheduledDisbursementDate}
                        innerRef={(ref: any) => {
                          data.register("scheduledDisbursementDate", {
                            required: "Scheduled Disbursment Date is required",
                          });
                          data.setValue(
                            "scheduledDisbursementDate",
                            model.scheduledDisbursementDate
                          );
                        }}
                        helperText={
                          data.errors.scheduledDisbursementDate
                            ? (
                                data.errors
                                  .scheduledDisbursementDate as FieldError
                              ).message
                            : undefined
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <InputField
                      type={InputType.CURRENCY}
                      id="actualDisbursementAmount"
                      label="Actual"
                      name="actualDisbursementAmount"
                      key="actualDisbursementAmount"
                      decimal
                      thousandSeparator
                      defaultValue={model?.actualDisbursementAmount}
                      onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                        handleFieldOnChange(
                          "actualDisbursementAmount",
                          v.target.value
                        );
                      }}
                      disabled
                      error={!!data.errors.actualDisbursementAmount}
                      helperText={
                        data.errors.actualDisbursementAmount
                          ? (data.errors.actualDisbursementAmount as FieldError)
                              .message
                          : undefined
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        name="actualDisbursementDate"
                        id="actualDisbursementDate"
                        label=" "
                        TextFieldComponent={ThemeTextField}
                        value={model?.actualDisbursementDate}
                        autoOk={true}
                        onChange={(value: any) => {
                          handleFieldOnChange(
                            "actualDisbursementDate",
                            value ? value : null
                          );
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disabled
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <DialogActions className={classes.buttonSection}>
                      <Grid container direction="row" spacing={1}>
                        <Grid item xs={2}></Grid>
                        <Grid
                          item
                          container
                          xs={4}
                          alignContent="center"
                          alignItems="center"
                          justify="center"
                        >
                          <ProgressSaveButton
                            text="Save"
                            onClick={(e: any) => {
                              onSave(e);
                            }}
                            loading={loaderState}
                            buttonClassName={classes.saveButton}
                          ></ProgressSaveButton>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={4}
                          alignContent="center"
                          alignItems="center"
                          justify="center"
                        >
                          <Button
                            onClick={handleClose}
                            color="secondary"
                            variant="contained"
                            type="button"
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item xs={2}></Grid>
                      </Grid>
                    </DialogActions>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
          </div>
        )}
      </form>
    </FormContext>
  );
};

export default DisbursementModal;
