export class Contact {
  constructor() {
    this.phone = "";
    this.extension = "";
    this.email = "";
    this.address1 = "";
    this.address2 = "";
    this.countryId = undefined;
    this.countyId = undefined;
    this.city = "";
    this.stateId = undefined;
    this.zip = "";
    this.fullAddress = "";
  }

  phone: string | undefined;
  extension: string | undefined;
  email: string | undefined;
  address1: string | undefined;
  address2: string | undefined;
  countryId: string | undefined;
  countyId: string | undefined;
  city: string | undefined;
  stateId: string | undefined;
  zip: string | undefined;
  fullAddress: string | undefined;
}
