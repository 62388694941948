import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import configureStore from '../src/store/configureStore';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { PersistGate } from 'redux-persist/integration/react';
import ConfigLoader from './utils/ConfigLoader';
import WebSocketProvider from './contexts/webSocketContext';
import AppTheme from '../src/AppTheme';
import LogRocket from '../src/LogRocketProvider';
import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

// Create browser history to use in the Redux store
const baseUrl = document
	.getElementsByTagName('base')[0]
	.getAttribute('href') as string;

const history = createBrowserHistory({ basename: baseUrl });

// Get the application-wide store instance, prepopulating with state from the
// server where available.
const store = configureStore(history);

export function getStore() {
	return store;
}

const setLocalStorageEnvironment = (environmentName: string) => {
	localStorage.setItem('environment', environmentName);
};

setLocalStorageEnvironment(process.env.NODE_ENV);

if (process.env.NODE_ENV === 'production') {
	// Initialize Google Analytics
	const googleAnalyticsTrackingId = 'UA-120839474-11';
	ReactGA.initialize(googleAnalyticsTrackingId);

	// Add pageview tracking to Google Analytics
	history.listen(loc => {
		ReactGA.set({ page: loc.pathname }); // set user's current page
		ReactGA.pageview(loc.pathname); // add pageview for user's current page
	});
}


ReactDOM.render(
	<Provider store={store.store}>
		<PersistGate loading={null} persistor={store.persistor}>
			<Router history={history}>
				<ConfigLoader>
					<AppTheme>
						<WebSocketProvider>
							{process.env.NODE_ENV === 'production' && <LogRocket />}
							<App />
						</WebSocketProvider>
					</AppTheme>
				</ConfigLoader>
			</Router>
		</PersistGate>
		<ReduxToastr
			timeOut={5000000}
			newestOnTop={false}
			preventDuplicates
			position="bottom-center"
			transitionIn="fadeIn"
			transitionOut="fadeOut"
			closeOnToastrClick
			className="toastr"
		/>
	</Provider>,

	document.getElementById('root')
);

registerServiceWorker();
