import NumberFormat from 'react-number-format';
import React, { useCallback } from 'react';
import { withStyles, createStyles, TextField } from '@material-ui/core';
import { useFormContext, Controller } from 'react-hook-form';

type CurrencyInputProps = {
  name: any;
  disabled?: boolean;
  onChange?: any;
  className: string;
  label: string;
  error: any;
  inputRef: any;
  helperText: any;
  value: number | any;
  key: any;
  control: any;
  creditDebit?: any;
};
export const ConvertCurrencyValueToInput = (value: any) => {
	const result = Number((value + '').replace(/[,\s\$]/g, ''));
	return result;
};
const CurrencyInput = (props: CurrencyInputProps) => {
	const { inputRef, onChange, value, ...other } = props;
	const ThemeTextField = withStyles((theme: any) =>
		createStyles({
			root: {},
		})
	)(TextField);

	const [displayNumberPrefix, setDisplayNumberPrefix] = React.useState<string>(
		'$'
	);
	const [displayNumberSuffix, setDisplayNumberSuffix] = React.useState<string>(
		''
	);

	const data = useFormContext();

	const onValueChange = useCallback(
		(values) => {
			if (data) {
				data.setValue(props.name, values.value);
			}
		},
		[onChange]
	);

	React.useEffect(() => {
		if (data) {
			data.setValue(props.name, value);
		}
	});

	React.useEffect(() => {
		if (props.creditDebit === 2) {
			setDisplayNumberPrefix('($ ');
			setDisplayNumberSuffix(' )');
		} else {
			setDisplayNumberPrefix('$ ');
			setDisplayNumberSuffix('');
		}
	}, [props.creditDebit]);

	return (
		<Controller
			control={props.control}
			name={props.name}
			as={
				<ThemeTextField
					name={props.name + 'Wrapper'}
					className={props.className}
					label={props.label}
					InputLabelProps={{ shrink: true }}
					disabled={props.disabled ? props.disabled : false}
					InputProps={{
						inputComponent: (propsInput: any) => (
							<NumberFormat
								{...propsInput}
								id={props.name}
								getInputRef={inputRef}
								name={props.name}
								key={props.key}
								onBlur={(e: any) => {
									let val = e.target.value
										.replace('($ ', '')
										.replace(' )', '')
										.replace('$ ', '')
										.replace(/,/g, '');
									data.setValue(props.name, ConvertCurrencyValueToInput(val));
									onChange(props.name, ConvertCurrencyValueToInput(val));
								}}
								onValueChange={onValueChange}
								prefix={displayNumberPrefix}
								suffix={displayNumberSuffix}
								thousandSeparator
								decimalScale={2}
								fixedDecimalScale={true}
							/>
						),
					}}
					error={props.error}
					helperText={props.helperText}
				/>
			}
		/>
	);
};

export default CurrencyInput;
