import * as React from "react";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import { Card, CardContent, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Campus } from "../../../../../interfaces/systemCatalog/ICampus";
import { useFormContext } from "react-hook-form";
import MultiCampusAutoComplete from "../../../../../components/AutoComplete/MultiCampusAutoComplete";

const useStyles = makeStyles((theme: any) =>
    createStyles({
        drawer: {
            width: 280
        },
        root: {
            backgroundColor: theme.palette.paper.background,
            "& .MuiCardContent-root": {
                paddingTop: theme.spacing(0),
                paddingLeft: theme.spacing(2)
            }
        },
        "& .makeStyles-content": {
            backgroundColor: theme.palette.paper.background
        },
        cardTitle: {
            fontSize: 17,
            color: theme.palette.black,
            textDecoration: "bold",
            backgroundColor: theme.palette.site.secondary,
            padding: theme.spacing(1, 1)
        },
        textField: {
            marginRight: theme.spacing(1),
            width: "100%"
        },
        headerText: {
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(0)
        },
        cardContent: {
            padding: theme.spacing(1, 3)
        }
    })
);

const ThemeTextField = withStyles((theme: any) => createStyles({}))(TextField);


const addCampusIfNotExists = (data: Array<Campus>, value: Campus) => {
    var index = data.findIndex(x => x.id == value.id)
    // here you can check specific property for an object whether it exist in your array or not

    if (index === -1) {
        data.push(value);
    }

    return data;
}

type IUserCampusesProps = {
    campuses?: Array<Campus>,
    setUserCampuses?: any
}

const UserCampuses = (props: IUserCampusesProps) => {
    const classes = useStyles({});
    const formData = useFormContext();

    const campuses = props.campuses || [];
    const setUserCampuses = props.setUserCampuses;


    return (
        <div>
            <Card square={true}>
                <Typography className={clsx(classes.cardTitle)}>
                    <div className={classes.headerText}>User Campus Assignment</div>
                </Typography>
                <CardContent className={classes.cardContent}>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                    >
                        <Grid item xs={6}>

                            <MultiCampusAutoComplete valueFilter={{
                                key: "value",
                                values: campuses.map(c => c.id),
                            }} filterHandle={setUserCampuses} />

                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
};

export default UserCampuses;
