import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useForm, FormContext } from 'react-hook-form';
import {
	createStyles,
	makeStyles,
	withStyles,
} from '@material-ui/core/styles';
import {
	Typography,
	Paper,
	Grid,
	Card,
	CardContent,
} from '@material-ui/core';
import ProgressSaveButton from '../../../components/_Layout/Buttons/ProgressSaveButton';
import TextField from '@material-ui/core/TextField';
import NotificationModal from '../../../components/_Layout/Modal/NotificationModal';
import CampusForCurrentUserAutoComplete from '../../../components/AutoComplete/CampusForCurrentUserAutoComplete';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as studentTransferApi from '../../../api/student/transfer/studentTransferApi';
import { ITransferStudentToCampusDetail } from '../../../interfaces/student/transfer/IStudentTransferInfo';

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			padding: theme.spacing(0),
			width: '100%',
			minHeight: '100%',
			height: '95%',
			overflowX: 'hidden',
			overflowY: 'auto',
			flexDirection: 'column',
			marginTop: theme.spacing(3),
			backgroundColor: theme.palette.paper.background,
		},
		loader: {
			margin: 'auto',
		},
		cardWrapper: {
			height: '100%',
		},
		textField: {
			marginRight: theme.spacing(1),
			width: '100%',
		},
		cardTitle: {
			fontSize: 17,
			color: theme.palette.black,
			textDecoration: 'bold',
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1),
			marginBottom: theme.spacing(1),
		},
		headerText: {
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(0),
		},
		cardContent: {
			padding: theme.spacing(1, 3),
			height: '100%',
		},
	})
);
const ThemeTextField = withStyles((_theme: any) =>
	createStyles({
		root: {
			'& .MuiFormLabel-root': {
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				width: '80%',
			},
		},
	})
)(TextField);

type SnackBarStateProps = {
  showSnackBar: boolean;
  messageInfo: string | undefined;
  variant: 'success' | 'error' | 'warning' | 'info';
};

const ToCampus = () => {
	const classes = useStyles({});
	const data = useForm<any>({ mode: 'onBlur' });

	const [model, setModel] = React.useState({
		toCampus: '',
		transferDate: null,
	});
	const handleFieldOnChange = (fieldId: string, value: any) => {
		let updatedModel = model;
		(updatedModel as any)[fieldId] = value;
		setModel({ ...updatedModel });
	};
	const student = useSelector((state: any) => state.student);
	const { handleSubmit } = data;
	const [loaderState, setLoaderState] = React.useState<boolean>(false);

	const [notificationPopupState, setNotificationPopupState] = React.useState<
    any
  >({
  	id: 'notification-popup',
  	message: '',
  });

	const onSubmit = (_d: any) => {
		if (model) {
			let enrollmentId = '';
			let campusId = '';
			let currentStatus='';
			let errorMessage = '';
			if (
				student.selectedEnrollment &&
        student.selectedEnrollment.stuEnrollId
			) {
				enrollmentId = student.selectedEnrollment.stuEnrollId;
				campusId = student.selectedEnrollment.campusId;
				currentStatus = student.selectedEnrollment.status;
			}

			let modelTransfer: ITransferStudentToCampusDetail = {
				EnrollmentId: enrollmentId,
				NewCampusId: model.toCampus,
				TransferDate: model.transferDate,
			};

			if(campusId !== model.toCampus)
			{
				if(currentStatus !== 'Currently Attending')
				{
					errorMessage = 'Transfer the student to different campus. The student enrollment status should be \'Currently Attending\'.';
				}
			}
			else
			{
				errorMessage = 'Student is already enrolled in the selected campus, Please select another campus.';
			}
			data.triggerValidation().then((validation: any) => {
				if (validation && modelTransfer.TransferDate !== null) {
					setLoaderState(true);
					if (errorMessage === '') {
						studentTransferApi.TransferStudentToCampus(modelTransfer).then(
							(response: any) => {
								if (response.resultStatus === 0) {
									setLoaderState(false);
									setNotificationPopupState((state: any) => {
										return {
											...state,
											open: true,
											notificationText: response.result,
											notificationButton: 'Ok',
											showCancelButton: false,
											title: 'Transfer to Campus',
											onConfirmEvent: () => {
												setNotificationPopupState((updatedState: any) => {
													return { ...updatedState, open: false };
												});
												window.location.href = '/student/1/campus';
											},
										};
									});
								} else {
									setLoaderState(false);
								}
							},
							(_exception: any) => {
								setLoaderState(false);
							}
						);
					} else {
						setLoaderState(false);
						setNotificationPopupState((state: any) => {
							return {
								...state,
								open: true,
								notificationText:errorMessage,
								notificationButton: 'Ok',
								showCancelButton: false,
								title: 'Transfer to Campus',
								onConfirmEvent: () => {
									setNotificationPopupState((updatedState: any) => {
										return { ...updatedState, open: false };
									});
								},
							};
						});
					}
				}
			});
		}
	};

	return (
		<FormContext {...data}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Paper className={classes.root} square={true}>
					<Card square={true} className={classes.cardWrapper}>
						<Typography className={clsx(classes.cardTitle)}>
							<div className={classes.headerText}>
                Transfer student enrollment to a different campus
							</div>
						</Typography>
						<CardContent className={classes.cardContent}>
							<Typography color="textPrimary" variant="h5">
                New Enrollment Detail
							</Typography>
							<Typography/>
							<Grid container direction="row" spacing={2}>
								<Grid item md={4} sm={6} xs={12}>
									<CampusForCurrentUserAutoComplete
										id="toCampus"
										name="toCampus"
										label="To Campus *"
										filterHandle={(v: any) => {
											handleFieldOnChange('toCampus', v ? v.value : undefined);
										}}
										valueFilter={
											model.toCampus
												? {
													key: 'value',
													values: [model.toCampus],
												}
												: undefined
										}
										error={!!data.errors.toCampus}
										inputRef={data.register({ required: true })}
										helperText={
											data.errors.toCampus ? 'Campus is required.' : undefined
										}
									/>
								</Grid>
								<Grid item md={4} sm={6} xs={12}>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<KeyboardDatePicker
											disableToolbar
											variant="inline"
											clearable
											format="MM/dd/yyyy"
											placeholder="mm/dd/yyyy"
											id="transferDate"
											name="transferDate"
											label="Transfer Date"
											TextFieldComponent={ThemeTextField}
											fullWidth
											autoOk={true}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											value={model?.transferDate ?? null}
											onChange={(val) => {
												handleFieldOnChange('transferDate', val ? val : null);
											}}
											required
											error={!!data.errors.transferDate}
											inputRef={data.register({
												required: true,
											})}
											helperText={
												data.errors.transferDate
													? 'Transfer date required'
													: null
											}
										/>
									</MuiPickersUtilsProvider>
								</Grid>
								<Grid item md={4} sm={6} xs={12}></Grid>
								<Grid item md={4} sm={6} xs={12}>
									<ProgressSaveButton
										text="Transfer"
										onClick={(e: any) => {
											onSubmit(e);
										}}
										loading={loaderState}
									></ProgressSaveButton>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
					<NotificationModal {...notificationPopupState} />
				</Paper>
			</form>
		</FormContext>
	);
};

export default ToCampus;
