import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DropDownListItem } from '../../../interfaces/DropDownListItem';
import TextField from '@material-ui/core/TextField';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CampusForCurrentUserAutoComplete from '../../../components/AutoComplete/CampusForCurrentUserAutoComplete';
import { Grid } from '@material-ui/core';
import { FormContext, useForm, FieldError } from 'react-hook-form';
import { IBPPEReport } from '../../../interfaces/reports/bppe/IBPPEReport';
import * as bppeReportApi from '../../../api/reports/bppe/bppeReportApi';
import { ReportOutput } from '../../../enums/ReportOutput';
import ReportOverview from '../../../components/_Layout/Reports/ReportOverview';
import { IReportOverview } from '../../../interfaces/reports/IReportOverview';
import ProgramVersionAutoComplete from '../../../components/AutoComplete/ProgramVersionAutoComplete';
import StudentGroupsAutoComplete from '../../../components/AutoComplete/StudentGroupsAutoComplete';
import FundSourcesAutoComplete from '../../../components/AutoComplete/FundSourcesAutoComplete';

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			paddingTop: theme.spacing(3),
			minHeight: '100%',
			height: '100%',
			maxWidth: '1050px',
			padding: theme.spacing(2),
		},
		'& .makeStyles-content': {
			backgroundColor: theme.palette.background.paper,
		},
		startDateField: {
			width: '100%',
		},
		fullWidthElement: {
			width: '100%',
		},
	})
);

const BPPEReport = (props: any) => {
	const { report } = props;
	let reportOverview = { report: report } as IReportOverview;
	const classes = useStyles({});
	const [preview, setPreview] = React.useState<any>();
	const [previewLoader, setPreviewLoader] = React.useState<boolean>();

	const data = useForm<any>({ mode: 'onBlur' });
	const [model, setModel] = React.useState<IBPPEReport>({
		campusId: '',
		gradStartDate: undefined as Date | undefined,
		gradEndDate: undefined as Date | undefined,
		programVerIds: [],
		studentGroupIds: [],
		nonTitleIVIds: [],
		exportType: 1 as ReportOutput,
		reportType: '',
	});

	const handleFieldOnChange = (
		fieldId: string,
		value: any,
		mapFunction?: Function
	) => {
		if ((fieldId == "reportType" || fieldId == "exportType") && value == undefined)
			return;

		let updatedModel = model;
		let newValue = mapFunction ? mapFunction(value) : value;
		(updatedModel as any)[fieldId] = newValue;
		if (fieldId === 'campusId') {
			(updatedModel as any)['programVerIds'] = [];
			(updatedModel as any)['studentGroupIds'] = [];
			(updatedModel as any)['nonTitleIVIds'] = [];
		}
		if (fieldId === 'reportType') {
			(updatedModel as any)['studentGroupIds'] = [];
		}
		setModel({ ...updatedModel });
	};

	const { handleSubmit } = data;

	const exportTypes: Array<DropDownListItem> = [
		{ text: 'CSV', id: '4' },
		{ text: 'Excel', id: '2' },
	];

	const reportTypes: Array<DropDownListItem> = [
		{ text: 'Annual Report', id: 'BPPE' },
		{ text: 'Labor Market', id: 'BPPELaborMarket' },
	];

	const onSubmit = async (isPreview: any) => {
		let params = (await getReportParameters()) as IBPPEReport;
		const validation = await data.triggerValidation();
		if (validation) {
			if (isPreview) {
				setPreviewLoader(true);
				bppeReportApi
					.generateReport(params, isPreview)
					.then((res: any) => {
						if (res) {
							res.arrayBuffer().then((buffer: any) => {
								setPreview(buffer);
								setPreviewLoader(false);
							});
						} else setPreviewLoader(false);
					});
			} else await bppeReportApi.generateReport(params);
		}
	};

	const exportTypeAutoComplete = {
		options: exportTypes,
		getOptionLabel: (option: DropDownListItem) => option.text,
	};

	const reportTypeAutoComplete = {
		options: reportTypes,
		getOptionLabel: (option: DropDownListItem) => option.text,
	};

	const filterHandler = (data: any) => {
		if (data) {
			setModel(data);
		} else {
			setModel({
				campusId: '',
				gradStartDate: undefined as Date | undefined,
				gradEndDate: undefined as Date | undefined,
				programVerIds: [],
				studentGroupIds: [],
				nonTitleIVIds: [],
				exportType: 4 as ReportOutput,
				reportType: 'BPPE',
			});
		}
	};
	const setProgramVersion = (PrgVersions: any) => {
		let newProgramVersion: string[] = [];
		if (PrgVersions !== null) {
			if (Array.isArray(PrgVersions)) {
				newProgramVersion = PrgVersions.map((gr: any) => gr.value);
			} else {
				newProgramVersion.push(PrgVersions.value);
			}
		}
		return newProgramVersion;
	};
	const setStudentGroup = (StudentGroup: any) => {
		let newStudentGroup: string[] = [];
		if (StudentGroup !== null) {
			if (Array.isArray(StudentGroup)) {
				newStudentGroup = StudentGroup.map((gr: any) => gr.value);
			} else {
				newStudentGroup.push(StudentGroup.value);
			}
		}
		return newStudentGroup;
	};
	const setNonTitleIV = (Funds: any) => {
		let newFunds: string[] = [];
		if (Funds !== null) {
			if (Array.isArray(Funds)) {
				newFunds = Funds.map((gr: any) => gr.value);
			} else {
				newFunds.push(Funds.value);
			}
		}
		return newFunds;
	};

	const getReportParameters = async () => {
		const success = await data.triggerValidation();
		if (model && success) {
			let gradStartDate = model.gradStartDate ? model.gradStartDate : undefined;
			let gradEndDate = model.gradEndDate ? model.gradEndDate : undefined;
			let params: IBPPEReport = {
				campusId: model.campusId,
				gradStartDate: gradStartDate,
				gradEndDate: gradEndDate,
				programVerIds: model.programVerIds,
				studentGroupIds: model.studentGroupIds,
				nonTitleIVIds: model.nonTitleIVIds,
				exportType: model.exportType,
				reportType: model.reportType,
			};
			return params;
		}
		return {} as IBPPEReport;
	};

	const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
		const item = items.find((opt) => {
			if (opt.id === value) return opt;
		});
		return item || ({} as DropDownListItem);
	};

	let parameters = (
		<div className={classes.root}>
			<FormContext {...data}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container direction="row" spacing={1}>
						<Grid item md={6} sm={6} xs={12}>
							<CampusForCurrentUserAutoComplete
								params={{
									fullWidth: true
								}}
								id="toCampus"
								name="toCampus"
								label="Campus *"
								filterHandle={(v: any) => {
									handleFieldOnChange('campusId', v ? v.value : undefined);
								}}
								valueFilter={
									model.campusId
										? {
											key: 'value',
											values: [model.campusId],
										}
										: undefined
								}
								error={!!data.errors.toCampus}
								inputRef={data.register({ required: true })}
								helperText={
									data.errors.toCampus ? 'Campus is required.' : undefined
								}
							/>
						</Grid>
						<Grid item md={3} sm={3} xs={12}>
							<Autocomplete
								{...reportTypeAutoComplete}
								autoComplete
								includeInputInList
								onChange={(e: any, value: any) => {
									handleFieldOnChange(
										'reportType',
										value ? value : undefined,
										(v: any) => v?.id
									);
								}}
								value={getSelectedItem(
									reportTypes,
									model.reportType.toString()
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Report Type *"
										name="reportType"
										error={!!data.errors.reportType}
										inputRef={data.register({ required: true })}
										helperText={
											data.errors.reportType
												? 'Report Type is required.'
												: undefined
										}
										fullWidth
									/>
								)}
							/>
						</Grid>
						<Grid item md={3} sm={3} xs={12}>
							<Autocomplete
								{...exportTypeAutoComplete}
								autoComplete
								includeInputInList
								onChange={(e: any, value: any) => {
									handleFieldOnChange(
										'exportType',
										value ? value : undefined,
										(v: any) => v?.id
									);
								}}
								value={getSelectedItem(
									exportTypes,
									model.exportType.toString()
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Export Type"
										name="exportType"
										error={!!data.errors.exportType}
										inputRef={data.register({ required: true })}
										helperText={
											data.errors.exportType
												? 'Export Type is required.'
												: undefined
										}
										fullWidth
									/>
								)}
							/>
						</Grid>
						<Grid item md={6} sm={6} xs={12}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="MM/dd/yyyy"
									id="gradStartDate"
									name="gradStartDate"
									label="Grad Date from *"
									value={model.gradStartDate ? model.gradStartDate : null}
									onChange={(e: any, value: any) =>
										handleFieldOnChange('gradStartDate', value ? value : undefined)
									}
									className={classes.startDateField}
									KeyboardButtonProps={{
										'aria-label': 'report date',
									}}
									error={!!data.errors['gradStartDate']}
									innerRef={(ref: any) => {
										data.register('gradStartDate', {
											required: true,
											validate: {
												beforeEndDate: (value) =>
													new Date(value) <
													new Date(data.getValues()['gradEndDate']) ||
													'Start Date must be before end date',
											},
										});
										data.setValue('gradStartDate', model.gradStartDate);
									}}
									helperText={
										data.errors['gradStartDate']
											? (data.errors['gradStartDate'] as FieldError).message
											: undefined
									}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item md={6} sm={6} xs={12}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="MM/dd/yyyy"
									id="gradEndDate"
									name="gradEndDate"
									label="Grad Date to *"
									value={model.gradEndDate ? model.gradEndDate : null}
									onChange={(e: any, value: any) =>
										handleFieldOnChange('gradEndDate', value ? value : null)
									}
									className={classes.startDateField}
									KeyboardButtonProps={{
										'aria-label': 'end date',
									}}
									error={!!data.errors['gradEndDate']}
									innerRef={(ref: any) => {
										data.register('gradEndDate', {
											required: true,
											validate: {
												beforeEndDate: (value) =>
													new Date(value) >
													new Date(data.getValues()['gradStartDate']) ||
													'End Date must be after Start date',
											},
										});
										data.setValue('gradEndDate', model.gradEndDate);
									}}
									helperText={
										data.errors['gradEndDate']
											? (data.errors['gradEndDate'] as FieldError).message
											: undefined
									}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item md={12} sm={12} xs={12}>
							<ProgramVersionAutoComplete
								params={{
									fullWidth: true
								}}
								name="programVerIds"
								campusId={model.campusId}
								label="Program Version (If non selected report will show all Program Versions)"
								includeAllValue={true}
								showIncludeAllValue={true}
								defaultOptionText={"All Programs"}
								filterHandle={(value: any) =>
									handleFieldOnChange('programVerIds', value, setProgramVersion)
								}
								valueFilter={
									model.programVerIds
										? {
											key: 'value',
											values: model.programVerIds,
										}
										: undefined
								}
								multiple={true}
							/>
						</Grid>
						{
							model.reportType == "BPPE" &&

							<Grid item md={12} sm={12} xs={12}>
								<StudentGroupsAutoComplete
									className={classes.fullWidthElement}
									params={{
										fullWidth: true
									}}
									name="studentGroupIds"
									campusId={model.campusId}
									label="Groups (If non selected report will show all Groups)"
									includeAllValue={true}
									showIncludeAllValue={true}
									defaultOptionText={"All Student groups"}
									filterHandle={(value: any) =>
										handleFieldOnChange('studentGroupIds', value, setStudentGroup)
									}
									valueFilter={
										model.studentGroupIds
											? {
												key: 'value',
												values: model.studentGroupIds,
											}
											: undefined
									}
									multiple={true}
								/>
							</Grid>
						}
						<Grid item md={12} sm={12} xs={12}>
							<FundSourcesAutoComplete
								name="nonTitleIVIds"
								campusId={model.campusId}
								params={{
									nonTitleIvLoanOnly: true,
									fullWidth: true,
									awardType: "Loan"
								}}
								error={!!data.errors.fundSourceIds}
								filterHandle={(value: any) =>
									handleFieldOnChange("nonTitleIVIds", value, setNonTitleIV)
								}
								valueFilter={
									model.nonTitleIVIds
										? {
											key: "value",
											values: model.nonTitleIVIds,
										}
										: undefined
								}
								label="Non T4 loans"
								multiple={true}
							/>
						</Grid>
					</Grid>
				</form>
			</FormContext>
		</div>
	);
	reportOverview.parameters = parameters;

	return (
		<ReportOverview
			reportOverview={reportOverview}
			filterHandler={filterHandler}
			getReportParameters={getReportParameters}
			exportHandler={onSubmit}
			preview={preview}
			previewLoader={previewLoader}
		/>
	);
};
export default BPPEReport;
