import API from "../apiWrapper";

export const getAppSettings = (campusId: string) => {
    return API()
        .get("/SystemCatalog/SystemConfigurationAppSetting/GetAppSettings", {
            params: {
                campusId
            }
        })
        .then(
            (res: any) => {
                if (res.data && res.data.result) {
                    return res.data.result;
                }
            },
            (error: any) => {
                return error;
            }
        );
};
