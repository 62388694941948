import * as React from "react";
import { useState, useEffect } from "react";
import clsx from "clsx";
import AddressTypesAutoComplete from "../../../../components/AutoComplete/AddressTypesAutoComplete";
import CountryAutoComplete from "../../../../components/AutoComplete/CountryAutoComplete";
import CountyAutoComplete from "../../../../components/AutoComplete/CountyAutoComplete";
import StatesAutoComplete from "../../../../components/AutoComplete/StatesAutoComplete";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import { Typography, Card, CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import {
  Address,
  Country,
} from "../../../../interfaces/student/profile/contactInfo/Address";
import * as AddressApi from "../../../../api/student/profle/contactInfo/AddressApi";
import { useSelector } from "react-redux";
import { StudentProfile } from "../../../../interfaces/student/profile/StudentProfile";
import { useFormContext } from "react-hook-form";
import InputField from "../../../../components/_Layout/Inputs/InputField";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    drawer: {
      width: 280,
    },
    root: {
      backgroundColor: theme.palette.paper.background,
      "& .MuiCardContent-root": {
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(2),
      },
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.paper.background,
    },
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
  })
);

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {},
  })
)(TextField);
type BestAddressProps = {
  setAddressModel: any;
  isNewEnrollment: boolean;
  leadId?: string;
  isTransferredStudent?: boolean;
  refreshData?: boolean;
  setRefreshData?: (value: boolean) => void;
  bestAddressModelPassthrough?: any;
};
const BestAddress = (props: BestAddressProps) => {
  const classes = useStyles({});
  const data = useFormContext();
  const { leadId, bestAddressModelPassthrough } = props;
  const [addressModel, setAddressModel] = useState<Address>(new Address()); //props; //useState<Address>(new Address());
  const handleFieldOnChange = (fieldId: string, value: any) => {
    let updatedModel = addressModel;
    (updatedModel as any)[fieldId] = value;
    setAddressModel(updatedModel);
    props.setAddressModel(updatedModel);
  };
  const [selectedCountry, setSelectedCountry] = useState<string>("");

  /*
  Getting the current selected student from the session
   */
  const selectedStudent: StudentProfile = useSelector(
    (state: any) => state.student
  ) as StudentProfile;

  const currentCampusId = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  React.useEffect(() => {
    if (bestAddressModelPassthrough) {
      setAddressModel({ ...bestAddressModelPassthrough });
    }
  }, [bestAddressModelPassthrough]);

  const loadFormData = async () => {
    /*
     Checks that the initial state is not undefined, this is two avoid having two calls to the API when the redux is still loading data
    */
    if (props.isNewEnrollment === false || !!leadId && ((selectedStudent !== undefined) || (props.refreshData && props.refreshData === true))) {
      if (
        selectedStudent.leadId !== undefined &&
        selectedStudent.leadId.length > 0 || !!leadId
      ) {
        if (!props.isNewEnrollment || props.isTransferredStudent || !!leadId) {
          let studentId = leadId ? leadId : selectedStudent.leadId;
          if (studentId) {
            try {
              const response = await AddressApi.getBestAddress(studentId);
              let updatedModel = response;
              if (response) {
                (updatedModel as any)["addressId"] = (response as any).id;
                (updatedModel as any)["addressIsShowOnLeadPage"] = (response as any).isShowOnLeadPage;
                props.setAddressModel({ ...updatedModel });
                if (updatedModel) {
                  setAddressModel({ ...updatedModel });
                }
              }
            }
            catch (err) {

            }
          }
        }
      }
    } else {
      let clearAddress = new Address();
      props.setAddressModel({ ...clearAddress });
      setAddressModel({ ...clearAddress });
      if (currentCampusId) {
        AddressApi.getCountryByName(currentCampusId, "USA").then((res: any) => {
          if (res) {
            (clearAddress as any)["countryId"] = res[0]?.value;
            props.setAddressModel({ ...clearAddress });
            setAddressModel({ ...clearAddress });
          }
        });

      }

    }

    if (props.refreshData && props.refreshData === true) {
      if (props.setRefreshData) {
        props.setRefreshData(false);
      }
    }
  }

  /*
  When the Component is loaded
   */

  useEffect(
    () => {
      loadFormData();
    },
    [
      selectedStudent,
      leadId,
      props.isNewEnrollment,
      props.refreshData
    ] /* tell react to watch changes on this variable */
  );
  const AddressValidation = (errorName: any) => {
    if (errorName?.type === "validate") {
      return "Address Line 1 is Required.";
    } else return null;
  };
  const AddressTypeValidation = (errorName: any) => {
    if (errorName?.type === "validate") {
      return "Address Type is Required.";
    } else return undefined;
  };
  const ZipValidation = (errorName: any) => {
    if (errorName?.type === "validate") {
      return !addressModel.zip
        ? 'Zip is Required.' : "The correct format of Zip is 5 digits or 5digits-4digits.";
    } else return null;
  };
  const CityValidation = (errorName: any) => {
    if (errorName?.type === "validate") {
      return "City is Required.";
    } else return undefined;
  };
  const CountryValidation = (errorName: any) => {
    if (errorName?.type === "validate") {
      return "Country is Required.";
    } else return undefined;
  };
  const StateValidation = (errorName: any) => {
    if (errorName?.type === "validate") {
      return "State is Required.";
    } else return undefined;
  };
  return (
    <div>
      <Card square={true}>
        <Typography className={clsx(classes.cardTitle)}>
          <div className={classes.headerText}>Best Address</div>
        </Typography>
        <CardContent className={classes.cardContent}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={12} sm={4}>
              <AddressTypesAutoComplete
                filterHandle={(v: any) => {
                  handleFieldOnChange("addressTypeId", v ? v.value : undefined);
                }}
                id="addressTypeId"
                name="addressTypeId"
                inputRef={data.register({
                  validate: (value: any) => {
                    return value.trim().length > 0;
                  },
                })}
                helperText={AddressTypeValidation(data.errors.addressTypeId)}
                error={!!data.errors.addressTypeId}
                valueFilter={
                  addressModel && addressModel.addressTypeId
                    ? {
                      key: "value",
                      values: [addressModel.addressTypeId],
                    }
                    : undefined
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CountryAutoComplete
                filterHandle={(v: any) => {
                  handleFieldOnChange("countryId", v ? v.value : undefined);
                  setSelectedCountry(v ? v.text : undefined);
                  data.clearError('States')
                }}
                id="Country"
                name="Country"
                valueFilter={
                  addressModel && addressModel.countryId
                    ? {
                      key: "value",
                      values: [addressModel.countryId],
                    }
                    : undefined
                }
                inputRef={data.register({
                  validate: (value: any) => {
                    return value.trim().length > 0;
                  },
                })}
                helperText={CountryValidation(data.errors.Country)}
                error={!!data.errors.Country}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CountyAutoComplete
                filterHandle={(v: any) => {
                  handleFieldOnChange("countyId", v ? v.value : undefined);
                }}
                id="County"
                name="County"
                valueFilter={
                  addressModel && addressModel.countyId
                    ? {
                      key: "value",
                      values: [addressModel.countyId],
                    }
                    : undefined
                }
              />
            </Grid>
            <Grid item xs={12}>
              <ThemeTextField
                id="address1"
                name="address1"
                InputLabelProps={{ shrink: !!addressModel.address1 }}
                className={classes.textField}
                label="Address Line 1 *"
                margin="normal"
                value={addressModel.address1 ?? ""}
                inputRef={data.register({
                  validate: (value: any) => {
                    handleFieldOnChange("address1", value);
                    return value.trim().length > 0;
                  },
                })}
                helperText={AddressValidation(data.errors.address1)}
                error={!!data.errors.address1}
                onChange={(v: React.FocusEvent<HTMLInputElement>) => {
                  handleFieldOnChange(
                    "address1",
                    v ? v.target.value : undefined
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ThemeTextField
                id="address2"
                name="address2"
                className={classes.textField}
                label="Address Line 2"
                margin="normal"
                value={addressModel.address2 ?? ""}
                onChange={(v: any) => {
                  handleFieldOnChange(
                    "address2",
                    v ? v.target.value : undefined
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ThemeTextField
                id="City"
                name="City"
                className={classes.textField}
                label="City *"
                margin="none"
                value={addressModel.city ?? ""}
                onChange={(v: any) => {
                  handleFieldOnChange("city", v ? v.target.value : undefined);
                }}
                inputRef={data.register({
                  validate: (value: any) => {
                    handleFieldOnChange("city", value);
                    return value.trim().length > 0;
                  },
                })}
                helperText={CityValidation(data.errors.City)}
                error={!!data.errors.City}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <StatesAutoComplete
                id="stateId"
                name="States"
                label={selectedCountry === 'USA' || !selectedCountry ? 'State *' : 'State'}
                countryId={addressModel.countryId}
                valueFilter={
                  addressModel && addressModel.stateId
                    ? {
                      key: "value",
                      values: [addressModel.stateId],
                    }
                    : undefined
                }
                filterHandle={(v: any) => {
                  handleFieldOnChange("stateId", v ? v.value : undefined);
                }}
                inputRef={data.register({
                  validate: (value: any) => {
                    return (selectedCountry === 'USA' || !selectedCountry ? value.trim().length > 0 : true);
                  },
                })}
                helperText={StateValidation(data.errors.States)}
                error={!!data.errors.States}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ThemeTextField
                id="zip"
                name="zip"
                className={classes.textField}
                label="Zip *"
                margin="none"
                value={addressModel.zip ?? ""}
                inputRef={data.register({
                  validate: (value: any) => {
                    handleFieldOnChange("zip", value);
                    return value.trim().length > 0 && /^(\d{5}|\d{5}-\d{4})$/.test(value);
                  },
                })}
                helperText={ZipValidation(data.errors.zip)}
                error={!!data.errors.zip}
                onChange={(v: any) => {
                  handleFieldOnChange("zip", v ? v.target.value : undefined);
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default BestAddress;
