export interface IProgramVersionGeneralDetails{
    id: string;
    name: string;
    statusId: string;
    effectiveStartDate: Date;
    effectiveEndDate: Date;
    hoursInProgram? : Number;
    creditsInProgram?: Number;
    creditsInAcademicYear? : Number;
    weeksInAcademicYear? : number;
    weeksInProgram? : Number;
    fullTimeDefinition: Number;
    addDropPeriod?: Number;
    hoursInAcademicYear? : Number;
    maximumTimeFrameOfProgramVersion?: Number;
    maxTimeToCompleteProgramPct?: Number;
    maxTimeToCompleteProgramWeeks?: Number;
    minimumAttendanceAllowed?: Number;
    paymentPeriodsPerAcademicYear?: Number;
    distanceEducationStatusId: number;
    registrationBy?: number;
    isContinuingEducation? : boolean;
    isClockHour : boolean;
    programId?: string;
    accreditingAgencyId: number;
    meetsLicensureRequirements?:string | null;
}

export class ProgramVersionGeneralDetails implements IProgramVersionGeneralDetails{
    id: string = "";
    name: string= "";
    statusId: string= "";
    effectiveStartDate: Date = new Date();
    effectiveEndDate: Date= new Date();
    creditsInProgram?: Number = 0;
    creditsInAcademicYear? : Number = 0;
    hoursInProgram?: Number = 0;
    fullTimeDefinition: Number = 0;
    addDropPeriod?: Number = 0;
    weeksInProgram?: Number = 0;
    hoursInAcademicYear?: Number = 0;
    weeksInAcademicYear?: number = 0;
    maximumTimeFrameOfProgramVersion?: Number = 0;
    maxTimeToCompleteProgramPct?: Number = 0;
    maxTimeToCompleteProgramWeeks?: Number = 0;
    minimumAttendanceAllowed?: Number = 0;
    paymentPeriodsPerAcademicYear?: Number = 0;
    distanceEducationStatusId: number= 0;
    registrationBy?: number;
    isContinuingEducation?: boolean;
    isClockHour : boolean =  false;
    programId?: string = "";
    accreditingAgencyId: number= 0;
    meetsLicensureRequirements?: string | undefined | null = null;
}