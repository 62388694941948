export const FinAidAdvisorApiAutoComplete = {
	config: {
		acId: "finAidAdvisor",
		cacheTime: 5,
		route: "/SystemCatalog/User/GetFinAidAdvisorByCampus",
		requestType: "GET",
		body: null,
		hasUrlParams: true,
		useCache: true,
		storeInCache: true,
		isCampusSpecific: true,
		isUserSpecific: false,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
};
