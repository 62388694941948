import API from "../../apiWrapper";

export const getStudentAcademicsCard = (enrollmentId: string) => {
  return API()
    .get("/AcademicRecords/StudentSummary/GetStudentAcademicsCard", {
      params: {
        enrollmentId
      }
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};
