import { IIncrementDetails } from "./IIncrementDetails";
export interface IChargingMethodIncrement {
	effectiveDate: Date;
	description: string;
	percentageOfExcuseAbsencesToIncludeInCalculation: Number;
	details: Array<IIncrementDetails>;
	incrementType: Number;
	id: string;
	incrementName: string;
	billingMethodId: string;
	isCustom: boolean;
	usesActualHours: boolean;
	studentEnrollmentId?: string;
	// saveAsNewIncrement: boolean;
}

export class CharginMethodIncrement implements IChargingMethodIncrement {
	effectiveDate: Date = new Date();
	description: string = "";
	usesActualHours: boolean = false;
	percentageOfExcuseAbsencesToIncludeInCalculation: Number = 0;
	details: IIncrementDetails[] = [];
	incrementType: Number = 0;
	incrementName: string = "";
	id: string = "";
	billingMethodId: string = "";
	isCustom: boolean = false;
	studentEnrollmentId?: string = undefined;
	// saveAsNewIncrement: boolean = true;
}
