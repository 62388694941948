import * as React from "react";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import * as PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import * as userApi from "../../../api/user/userApi";
import {
	Avatar,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Checkbox,
	Divider,
	Button,
	Link,
	TableCell,
	Typography,
	IconButton,
	Tooltip,
	Menu,
	Fade,
	MenuItem,
	Popover
} from "@material-ui/core";
import ApiVirtualizedTable from "../../../components/_Layout/ApiTables/ApiVirtualizedTable";
import { apiVirtualizedTableConfig } from "../../../constants/apiVirtualizedTable.config";
import {
	TableCellDataGetterParams,
	TableCellRenderer,
	TableRowRenderer,
	TableHeaderRenderer
} from "react-virtualized";
import EditIcon from "@material-ui/icons/Edit";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { User } from "../../../interfaces/user/User";
import CustomSnackbar from "../../../components/notifications/CustomSnackbar";

const useStyles = makeStyles((theme: any) => ({
	root: {},
	flexContainer: {
		display: "flex",
		alignItems: "center",
		boxSizing: "border-box"
	},
	content: {
		padding: 0,
		height: "100%"
	},
	inner: {
		height: "100%",
		width: "100%",
		minWidth: 700
	},
	nameCell: {
		display: "flex",
		alignItems: "center"
	},
	avatar: {
		height: 42,
		width: 42,
		marginRight: theme.spacing(1)
	},
	actions: {
		padding: theme.spacing(1),
		justifyContent: "flex-end"
	},
	tableCell: {
		flex: 1,
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden"
	},
	noClick: {
		cursor: "initial"
	},
	marginRight: {
		marginRight: theme.spacing(1)
	},
	redText: {
		color: theme.palette.error.main
	},
	typography: {
		padding: theme.spacing(2)
	},
	popOverButtons: {
		display: "flex",
		justifyContent: "space-evenly",
		flexDirection: "row",
		padding: 5
	}
}));

type CustomFieldsGridProps = {
	className?: string;
	params?: any;
	setSelectedUser?: any;
};

const CustomFieldsGrid = (props: CustomFieldsGridProps) => {
	const { className, params, setSelectedUser, ...rest } = props;

	const classes = useStyles({});

	const [selectedUsers, setSelectedUsers] = useState<any>([]);
	const [selectAllUsers, setSelectAllUsers] = useState<boolean>(false);
	const [userToDelete, setUserToDelete] = useState<any | null>(null);
	const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
	const [messageInfo, setMessageInfo] = React.useState<string | undefined>(
		undefined
	);
	const [snackBarVariant, setSnackBarVariant] = React.useState<
		"success" | "error" | "warning" | "info"
	>("info");

	const [refreshData, setRefreshData] = useState<boolean>(false);
	const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
		null
	);
	console.log(refreshData);
	const menuOpen = Boolean(menuAnchorEl);

	const handleSelectAllClick = (event: any) => {
		setSelectAllUsers((prevState: boolean) => !prevState);
	};

	const handleClickMenu = (event: any) => {
		setMenuAnchorEl(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setMenuAnchorEl(null);
	};

	const [
		popOverAnchorEl,
		setPopOverAnchorEl
	] = React.useState<HTMLButtonElement | null>(null);

	const handleClickPopOver = (event: any, id: any) => {
		setUserToDelete(id);
		setPopOverAnchorEl(event.currentTarget);
	};

	const handleClosePopOver = () => {
		setUserToDelete(null);
		setPopOverAnchorEl(null);
	};

	const handleBulkDelete = () => {
		if (selectedUsers.length === 0) {
			setSnackBarVariant("info");
			setMessageInfo(
				"Please select at least one user before attempting to delete."
			);
			setShowSnackBar(true);
		}
	};
	const handleResetPassword = () => {
		if (selectedUsers.length === 0) {
			setSnackBarVariant("info");
			setMessageInfo(
				"Please select at least one user before attempting to delete."
			);
			setShowSnackBar(true);
		}
	};
	const handleReactivateAccount = () => {
		setRefreshData(true);
	};
	const options = [
		{ label: "Delete", action: handleBulkDelete },
		{ label: "Reset Password", action: handleResetPassword },
		{ label: "Reactivate Account", action: handleReactivateAccount }
	];

	const popOverOpen = Boolean(popOverAnchorEl);
	const popOverId = popOverOpen ? "simple-popover" : undefined;

	//const handleSelectAll = (event: any) => {
	//    const selectedCustomers = event.target.checked
	//        ? customers.map((customer: any) => customer.id)
	//        : [];

	//    setSelectedCustomers(selectedCustomers);
	//};

	const handleSelectOne = (event: any, id: any) => {
		const selectedIndex = selectedUsers.indexOf(id);
		let newSelectedUsers:
			| any[]
			| never[]
			| ((prevState: never[]) => never[]) = [];

		if (selectedIndex === -1) {
			newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
		} else if (selectedIndex === 0) {
			newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
		} else if (selectedIndex === selectedUsers.length - 1) {
			newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelectedUsers = newSelectedUsers.concat(
				selectedUsers.slice(0, selectedIndex),
				selectedUsers.slice(selectedIndex + 1)
			);
		}

		setSelectedUsers(newSelectedUsers);
	};
	const config = { ...apiVirtualizedTableConfig.usersPaged };
	config.params = params;

	const onEditClicked = (id: string | undefined) => {};

	const onDeleteConfirmed = () => {
		if (userToDelete) {
			userApi.inactivateAccount(userToDelete).then(
				(response: boolean) => {
					if (response) {
						setSnackBarVariant("success");
						setMessageInfo("User(s) successfully inactivated.");
						setShowSnackBar(true);
					}
				},
				(exception: any) => {}
			);
		}
	};

	const rowRenderer: TableRowRenderer = ({
		columns,
		index,
		key,
		style,
		rowData
	}) => {
		let content;
		const isActive = rowData
			? rowData.value.status.toLowerCase() === "active"
			: false;
		return (
			<div
				className={clsx({ [classes.redText]: !isActive })}
				key={key}
				style={style}
			>
				{columns}
			</div>
		);
	};

	const cellRenderer: TableCellRenderer = ({
		cellData,
		rowData,
		columnIndex
	}) => {
		if (rowData) {
			const isSelected =
				selectedUsers.findIndex((userId: string) => userId === rowData.text) !==
				-1;
			return (
				<TableCell component="div" variant="body">
					<Tooltip title="Edit">
						<IconButton
							onClick={() => onEditClicked(rowData ? rowData.text : undefined)}
						>
							<EditIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title="Delete">
						<IconButton
							onClick={(e: any) => handleClickPopOver(e, rowData.text)}
						>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
					<Checkbox
						checked={selectAllUsers || isSelected}
						onChange={(event: any) => handleSelectOne(event, rowData.text)}
						value="primary"
						color="primary"
						inputProps={{ "aria-label": "primary checkbox" }}
					/>
				</TableCell>
			);
		}
		return <TableCell component="div" variant="body"></TableCell>;
	};

	return (
		<div {...rest} className={clsx(classes.root, className)}>
			<Typography
				color="textSecondary"
				gutterBottom
				variant="body2"
			></Typography>
			<Card>
				<CardContent className={classes.content}>
					<div className={classes.inner} style={{ height: 500 }}>
						<CustomSnackbar
							variant={snackBarVariant}
							message={messageInfo}
							open={showSnackBar}
							onClose={(event?: React.SyntheticEvent, reason?: string) => {
								setShowSnackBar(false);
							}}
						></CustomSnackbar>
						<ApiVirtualizedTable
							config={config}
							cellDataGetter={({ rowData, dataKey }) => {
								if (rowData) return rowData.value[dataKey];
							}}
							onRowClick={() => {
								console.log("row clicked");
							}}
							columns={[
								{
									width: 130,
									label: "Field Name",
									dataKey: "fullName"
								},
								{
									width: 180,
									label: "Type",
									dataKey: "email",
									cellRenderer: ({ cellData, rowData, columnIndex }) => {
										return (
											<Tooltip title={cellData} placement="top">
												<TableCell
													component="div"
													className={clsx(
														classes.tableCell,
														classes.flexContainer,
														classes.noClick
													)}
													variant="body"
												>
													{cellData}
												</TableCell>
											</Tooltip>
										);
									}
								},
								{
									width: 120,
									label: "Length",
									dataKey: "displayName",
									cellRenderer: ({ cellData, rowData, columnIndex }) => {
										return (
											<Tooltip title={cellData} placement="top">
												<TableCell
													component="div"
													className={clsx(
														classes.tableCell,
														classes.flexContainer,
														classes.noClick
													)}
													variant="body"
												>
													{cellData}
												</TableCell>
											</Tooltip>
										);
									}
								},
								{
									width: 120,
									label: "Is Required",
									dataKey: "userType"
								},
								{
									width: 120,
									label: "Options",
									dataKey: "options",
									headerRenderer: ({ columnData, label }) => {
										return (
											<TableCell
												component="div"
												className={clsx(
													classes.tableCell,
													classes.flexContainer
												)}
												variant="head"
												align="right"
											>
												<Tooltip title="select all">
													<Checkbox
														checked={selectAllUsers}
														onChange={handleSelectAllClick}
														value="primary"
														color="primary"
														inputProps={{
															"aria-label": "primary checkbox"
														}}
													/>
												</Tooltip>
												<Tooltip title="bulk actions">
													<IconButton>
														<MoreVertIcon onClick={handleClickMenu} />
													</IconButton>
												</Tooltip>
												<Menu
													id="fade-menu"
													anchorEl={menuAnchorEl}
													keepMounted
													open={menuOpen}
													onClose={handleCloseMenu}
													TransitionComponent={Fade}
												>
													{options.map((actionItem: any) => {
														return (
															<MenuItem
																onClick={function() {
																	handleCloseMenu();
																	actionItem.action();
																}}
															>
																{actionItem.label}
															</MenuItem>
														);
													})}
												</Menu>
											</TableCell>
										);
									},
									cellRenderer: cellRenderer
								}
							]}
						/>
					</div>
				</CardContent>
				<CardActions className={classes.actions}></CardActions>
			</Card>
			<Popover
				id={popOverId}
				open={popOverOpen}
				anchorEl={popOverAnchorEl}
				onClose={handleClosePopOver}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
			>
				<Typography
					color="textSecondary"
					className={classes.typography}
					variant="body1"
				>
					Are you sure you want to delete this custom field?
				</Typography>
				<div className={classes.popOverButtons}>
					<Button
						variant="contained"
						size="small"
						onClick={() => handleClosePopOver()}
						color="primary"
					>
						Yes
					</Button>
					<Button
						variant="contained"
						size="small"
						onClick={handleClosePopOver}
						color="secondary"
					>
						Cancel
					</Button>
				</div>
			</Popover>
		</div>
	);
};

CustomFieldsGrid.propTypes = {
	className: PropTypes.string,
	params: PropTypes.object
};

CustomFieldsGrid.defaultProps = {
	params: {}
};

export default CustomFieldsGrid;
