import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Dialog,TextField,	DialogTitle,DialogContent,Portal} from "@material-ui/core";
import { useForm, FormContext } from "react-hook-form";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {Typography,Paper,CardContent,Grid,Button} from "@material-ui/core";import CustomSnackbar from "../../../components/notifications/CustomSnackbar";
import { CustomSnackBarProps } from "../../../interfaces/common/CustomSnackBarProps";
import { MuiPickersUtilsProvider, KeyboardDatePicker} from "@material-ui/pickers";
import ProgressSaveButton from "../../../components/_Layout/Buttons/ProgressSaveButton";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IStatusChangeOption } from "../../../interfaces/student/status/IStatusChangeOption";
import * as StatusHistoryApi from "../../../api/student/status/StatusHistoryApi";
import { useSelector } from "react-redux";
import * as CurrentlyAttendingApi from "../../../api/student/status/CurrentlyAttendingApi";
import { format} from "date-fns";

type AddNewStatusProps = {
    open: boolean;
    setOpen : any;
    enrollmentId: string;
    setSnackBarProps: any;
    refreshHistoryGrid : any;
};

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			"& .MuiPaper-rounded": {
				borderRadius: theme.spacing.borderradius,
			},
			"& .MuiDialogContent-root": {
				minHeight: 270,
			},
		},
		form: {
			display: 'flex',
			flexDirection: 'column',
			width: 'fit-content',
			minWidth: 100,
		  },
		dialogHeader: {
			backgroundColor: theme.palette.site.secondary,
			"& .MuiTypography-root": {
				fontSize: theme.spacing(2),
			},
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
		},
		detailWrapper: {
			padding: theme.spacing(.5),
        },
        reasonTb : {
            width : '100%'
        },
        cancelBtn : {
            marginLeft : theme.spacing(2)
        }
	})
);

const AddNewStatus = (props: AddNewStatusProps) => {
	const classes = useStyles({});
    const selectedEnrollment = useSelector(
		(state: any) => state.student.selectedEnrollment
	);
    const [changeOptionData, setChangeOptionData] = React.useState<Array<IStatusChangeOption>>([]);
    const [optionValue, setOptionValue] = React.useState<string>( "");
    const [dateValue, setDateValue] = React.useState<string | number> (new Date().toString());
    const [reasonValue, setReasonValue] = React.useState<string>("");
    const validation = useForm<any>({ mode: "onBlur" });
    const [loading, setLoading] = React.useState<boolean>(false);
    const [confirmationMessage, setConfirmationMessage] = React.useState(null);
	const [changeStatusConsent, setChangeStatusConsent] = React.useState(false);


	const handleClose = () => {
        props.setOpen(false);
        setReasonValue("")
        setDateValue(new Date().toString())
        setOptionValue("")
	};

    const refreshOptions=()=>{
		if (selectedEnrollment && selectedEnrollment.stuEnrollId) {
			let studentEnrollmentId = selectedEnrollment.stuEnrollId;
			StatusHistoryApi.getStatusChangeOptionsForNewStatus(studentEnrollmentId).then(
				(response: Array<IStatusChangeOption>) => {
					if (response && response.length > 0) {
						setChangeOptionData(response);
					}
				},
				(exception: any) => {}
			);
		}
    }

    React.useEffect(() => {
		refreshOptions();
    }, [selectedEnrollment]);

    const onOptionChange = (_event: any, value: any) => {
		setOptionValue((value != null)? value.statusCodeId : null)
    }
    
    const submitNewStatus = () => {
        setLoading(true);
        CurrentlyAttendingApi.
        AddNewStatus({effectiveDate : format(new Date (dateValue), "yyyy-MM-dd" ) , changeReason : reasonValue,
         statusChangeStudentEnrollmentId : props.enrollmentId }, 
         optionValue, changeStatusConsent).then(
            (response: any) => { 
               setLoading(false); 
               if (response && response.data && response.data.resultStatusMessage) {
                    if(response.data.confirmationNeeded)
                    {
                        setChangeStatusConsent(true);
                        setConfirmationMessage(response.data.resultStatusMessage)
                    }
                    else{
                        props.setSnackBarProps((snackBarProps: any) => {
                            return {
                            variant: "success",
                            showSnackBar: true,
                            messageInfo: response.data.resultStatusMessage,
                            };
                        });
                        props.refreshHistoryGrid();
                        handleClose();
                    }
              }
            },
            (exception: any) => {
                setLoading(false);
                props.setSnackBarProps((snackBarProps: any) => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: exception,
                  };
                });
            }
        )}
    
    
return (
    <FormContext {...validation}>
        <form className={classes.form}>
            <div>
							
                <Dialog
                    open={props.open}
                    onClose={handleClose}
                    className={classes.root}
                    aria-labelledby="form-dialog-title"
					fullWidth={true}
					maxWidth='xs'
					disableBackdropClick
                >
                    <DialogTitle
                        id="form-dialog-title"
						className={classes.dialogHeader}
                    >
						Add New Status
						<IconButton 
							aria-label="close" 
							className={classes.closeButton} 
							onClick={handleClose}
						>
							<CloseIcon />
						</IconButton>
                    </DialogTitle>
                    <DialogContent>						
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="change-status-options"
                                options={changeOptionData}
                                getOptionLabel={(option) => (option != null)? option.statusDescription: ""}
                                getOptionDisabled={(option) => option.validOption == false}
                                onChange={onOptionChange}
                                renderInput={(params) => (
                                    <TextField {...params} 
                                        label="New Status *" 
                                        name="addNewStatus"
                                        error={!!validation.errors.addNewStatus}
                                        inputRef={validation.register({ required: true })}
                                        helperText={
                                            validation.errors.addNewStatus
                                            ? "Status is required."
                                            : undefined
                                        }
                                    />
                                )}
                            />
                        </Grid>
				        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                               
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    id="effectiveDate"
                                    fullWidth
                                    name="effectiveDate"
                                    key="effectiveDate"
                                    label="Effective Date"
                                    value={dateValue ? dateValue : new Date()}
                                    required
                                    autoOk = {true}
                                    onChange={(e: any, val: any) =>
                                        setDateValue(val)
                                    }
                                    KeyboardButtonProps={{
                                    "aria-label": "effective Date",
                                    }}
                                    inputRef={validation.register({ required: true })}
                                    helperText={
                                        validation.errors.reportDate ? "Date is required." : undefined
                                    }
                                    error={!!validation.errors["effectiveDate"]}
                             />
                            </MuiPickersUtilsProvider>
				        </Grid>
                        <Grid item xs={12}>
                            <TextField  label="Reason for change *" className={classes.reasonTb} value={reasonValue} 
                            onChange = {(e)=> {setReasonValue(e.target.value)}}
                            name="reason"
                            error={!!validation.errors.reason}
                            inputRef={validation.register({ required: true })}
                            helperText={
                                validation.errors.reason
                                ? "Reason is required."
                                : undefined
                            }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                            {confirmationMessage}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <ProgressSaveButton
                                text= { confirmationMessage ? "Add & Update" :  'Add'}
                                loading = {loading}
                                onClick={async() => {
                                    const response = await validation.triggerValidation();
                                   if(response)
                                   submitNewStatus()
                                }}
                                ></ProgressSaveButton>
                            <Button
                               className={classes.cancelBtn}
                                onClick={handleClose}
                                color="secondary"
                                variant="contained"
                            >Cancel</Button>
                        </Grid>
			        </Grid>

                    </DialogContent>
                </Dialog>
            </div>
        </form>
    </FormContext>
)    
};

export default AddNewStatus;

