import React, { useEffect, useRef, SyntheticEvent } from "react";
import { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { AdvanceSearchModel } from "../../../interfaces/student/search/AdvanceSearchModel";
import * as studentSearchApi from "../../../api/search/studentSearchApi";
import { StudentSearchModel } from "../../../interfaces/student/search/SearchModel";
import * as actionTypes from "../../../constants/actions.constants";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import CustomSnackbar from "../../../components/notifications/CustomSnackbar";
import { keys } from "../../../constants/urlparameters.contants";

import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import { IconButton, TextField, Tooltip, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { IStudentNavigationResult } from "../../../interfaces/student/navigation/studentNavigation";
import { StudentNavigationDirection } from "../../../enums/studentNavigationDirection";

const useStyles = makeStyles((theme: any) => ({
	mainContainer: {
		textAlign: "center",
	},
	studentNumberDisplay: {
		display: "inline-block",
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		verticalAlign: "middle",
		fontWeight: "bold",
	},
	iconButton: {
		padding: "0px",
	},
}));

let StudentNavigator = (props: any) => {
	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const advanceSearchModel = useSelector((state: any) =>
		state.user_advance_search.getAdvanceSearchModel(state.session.user.userId)
	);

	const classes = useStyles({});

	const student = useSelector((state: any) =>
		state.userstate[state.session.user.userId]
			? state.userstate[state.session.user.userId].selectedStudent
			: null
	);

	const navigateToNextStudent = () => {
		studentSearchApi
			.GetStudentToNavigate({
				advanceStudentSearch: {
					...advanceSearchModel,
					CampusId: userSelectedCampus,
				},
				currentStudentNumber: student.studentNumber,
				studentNavigationDirection: StudentNavigationDirection.Next,
			})
			.then((data) => {
				if (data && data.result) {
					setSelectedStudent(data.result);
				}
			});
	};

	const navigateToPreviousStudent = () => {
		studentSearchApi
			.GetStudentToNavigate({
				advanceStudentSearch: {
					...advanceSearchModel,
					CampusId: userSelectedCampus,
				},
				currentStudentNumber: student.studentNumber,
				studentNavigationDirection: StudentNavigationDirection.Previous,
			})
			.then((data) => {
				if (data && data.result) {
					setSelectedStudent(data.result);
				}
			});
	};

	const navigateToFirstStudent = () => {
		studentSearchApi
			.GetStudentToNavigate({
				advanceStudentSearch: {
					...advanceSearchModel,
					CampusId: userSelectedCampus,
				},
				currentStudentNumber: student.studentNumber,
				studentNavigationDirection: StudentNavigationDirection.First,
			})
			.then((data) => {
				if (data && data.result) {
					setSelectedStudent(data.result);
				}
			});
	};

	const navigateToLastStudent = () => {
		studentSearchApi
			.GetStudentToNavigate({
				advanceStudentSearch: {
					...advanceSearchModel,
					CampusId: userSelectedCampus,
				},
				currentStudentNumber: student.studentNumber,
				studentNavigationDirection: StudentNavigationDirection.Last,
			})
			.then((data) => {
				if (data && data.result) {
					setSelectedStudent(data.result);
				}
			});
	};

	const setSelectedStudent = (data: IStudentNavigationResult) => {
		let urlParams = new URLSearchParams(props.history.location.search);
		let studentIdQueryId = keys.studentId;
		let campusIdQueryId = keys.campusId;

		//if student selected is different than current selected campus, show snackbar
		if (urlParams.get(campusIdQueryId) != data.campusId) {
			//campus changed message here
		}

		//set url parameters for campusId and studentId
		urlParams.set(studentIdQueryId, data.studentId as any);
		urlParams.set(campusIdQueryId, data.campusId);

		//route to student info page if not from student module
		if (props && props.location) {
			if (props.location.pathname.indexOf("student") > -1) {
				props.history.push({
					search: urlParams.toString(),
				});
			} else {
				props.history.push({
					pathname: "/student/1/profile/info",
					search: urlParams.toString(),
				});
			}
		}
	};

	return (
		<div>
			<div className={classes.mainContainer}>
				<IconButton
					className={classes.iconButton}
					onClick={navigateToFirstStudent}
				>
					<Tooltip title="First" aria-label="First">
						<FirstPageIcon />
					</Tooltip>
				</IconButton>
				<IconButton
					className={classes.iconButton}
					onClick={navigateToPreviousStudent}
				>
					<Tooltip title="Previous" aria-label="Previous">
						<NavigateBeforeIcon />
					</Tooltip>
				</IconButton>
				<Typography className={classes.studentNumberDisplay}>
					{student?.studentNumber}
				</Typography>
				<IconButton
					className={classes.iconButton}
					onClick={navigateToNextStudent}
				>
					<Tooltip title="Next" aria-label="Next">
						<NavigateNextIcon />
					</Tooltip>
				</IconButton>
				<IconButton
					className={classes.iconButton}
					onClick={navigateToLastStudent}
				>
					<Tooltip title="Last" aria-label="Last">
						<LastPageIcon />
					</Tooltip>
				</IconButton>
			</div>
		</div>
	);
};

export default withRouter(StudentNavigator);
