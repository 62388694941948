import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
	Typography,
	CardContent,
	Card,
	Button
} from "@material-ui/core";

import Eligibility from './Eligibility';
import ExitInterview from './ExitInterview';
import Certifications from './Certification';
import PlacementHistory from './PlacementHistory';

import { FormContext, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { getStudentPlacementHistory, getStudentPlacementDetails, savePlacementDetails, deleteJobPlacement } from "../../../api/student/placement/placementDetails";
import { isExemptedForLicensureFor2020,updateExemptForLicensureFor2020 } from "../../../api/student/academics/enrollmentApi";
import IPlacementDetails from '../../../interfaces/student/placement/PlacementInfo';
import { Enrollment } from "../../../interfaces/student/academics/Enrollment";
import CustomSnackbar from "../../../components/notifications/CustomSnackbar";
import { CustomSnackBarProps } from "../../../interfaces/common/CustomSnackBarProps";
import { EmptyGuid } from "../../../utils/constants";
import IPlacementHistory from "../../../interfaces/student/placement/PlacementHistory";
import { security } from "../../../constants/Security/securityConstants";
import AccessManager from "../../../components/security/AccessManager";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		drawer: {
			width: 280
		},
		root: {
			backgroundColor: theme.palette.background.paper,
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2)
			}
		},
		"& .makeStyles-content": {
			backgroundColor: theme.palette.background.paper
		},
		cardTitle: {
			fontSize: 17,
			color: "Black",
			textDecoration: "bold",
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1)
		},

		textField: {
			marginRight: theme.spacing(1),
			width: "100%"
		},
		paperRoot: {
			padding: theme.spacing(3, 2),
			height: "100%",
			width: "80%",
			display: "flex",
			minHeight: "100px",
			flexDirection: "column",
			justifyContent: "center",
			backgroundColor: theme.palette.background.paper
		},
		fullWidthField: {
			width: "100%"
		},
		headerText: {
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(0)
		},
		cardSeparator: {
			marginBottom: theme.spacing(1)
		},
		buttonColorPrimary: {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.primary.contrastText,
			"&:hover": {
				backgroundColor: theme.palette.primary.main
			},
			textDecoration: "bold",
			padding: theme.spacing(1),
			whiteSpace: "nowrap",
			margin: theme.spacing(1),
		},
		buttonColorSecondary: {
			backgroundColor: theme.palette.secondary.dark,
			color: theme.palette.secondary.contrastText,
			"&:hover": {
				backgroundColor: theme.palette.secondary.main,
			},
			textDecoration: "bold",
			padding: theme.spacing(1),
			whiteSpace: "nowrap",
			margin: theme.spacing(1),
		},
		buttonColorTertiary: {
			backgroundColor: theme.palette.error.dark,
			color: theme.palette.error.contrastText,
			"&:hover": {
				backgroundColor: theme.palette.error.main,
			},
			textDecoration: "bold",
			padding: theme.spacing(1),
			whiteSpace: "nowrap",
			margin: theme.spacing(1),
		}
	})
);

const PlacementDetails = (_props: any) => {
	const classes = useStyles({});

	const selectedEnrollment = useSelector(
		(state: any) => state.student.selectedEnrollment
	);

	const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
		{
			showSnackBar: false,
			messageInfo: undefined,
			variant: "info",
		}
	);

	const [placementHistoryList, setPlacementHistoryList] = React.useState<IPlacementHistory[]>([]);

	const selectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const [ViewModel, updateViewModel] = useState<IPlacementDetails>({
		eligible: '',
		ineligibilityReasonId: '',
		notNaccasAuthorized: false,
		studentEnrollmentId: selectedEnrollment,
		exitInterViewDate: undefined,
		certification1Date: undefined,
		certification1Status: undefined,
		certification2Date: undefined,
		certification2Status: undefined,
		certification3Date: undefined,
		certification3Status: undefined,
		studentPlacementId: undefined,
		satForAllParts: false,
		isStudentLicensed:'No',
		licenseNumber:'',
	});

	const [exemptForLicensureFor2020,setExemptForLicensureFor2020] = React.useState<boolean>(false);

	const methods = useForm<IPlacementDetails>({
		mode: "onBlur",
		defaultValues: ViewModel
	});

	const newAwardClick = () => {
		updateViewModel({ ...ViewModel, studentPlacementId: EmptyGuid });
		methods.setValue('studentPlacementId', EmptyGuid);
	}

	const getPlacementHistory = async (): Promise<void> => {
		if (selectedEnrollment) {
			const studEnrollmentId = (selectedEnrollment as Enrollment).stuEnrollId;
			const placementList = await getStudentPlacementHistory(studEnrollmentId);
			setPlacementHistoryList(placementList);
		}
	}

	const getPlacementDetails = async (): Promise<void> => {
		if (selectedEnrollment) {
			const studEnrollmentId = (selectedEnrollment as Enrollment).stuEnrollId;
			const placementDetails = await getStudentPlacementDetails(studEnrollmentId);
			updateViewModel({ ...placementDetails });
			methods.reset(placementDetails);
		}
	}

	const deletePlacementDetails = async (): Promise<void> => {
		if (ViewModel.studentPlacementId && ViewModel.studentPlacementId != EmptyGuid) {
			
			try {
				const message = await deleteJobPlacement(ViewModel.studentPlacementId);
				setSnackBarProps(() => {
					return {
						variant: "success",
						showSnackBar: true,
						messageInfo: message,
					};
				});
				newAwardClick();
			} catch (error) {
				setSnackBarProps((props:any) => {
					return {
						...props,
						variant: "error",
						showSnackBar: true,
						messageInfo: error,
					};
				});
			}
			finally {
				getPlacementHistory();
			}

		}
	}

	

	const handleSave = async (_d: any) => {
		if (selectedEnrollment) {
			try {
				if(!!ViewModel.licenseNumber && ViewModel.licenseNumber.length > 200){
					methods.setError("licenseNumber",'maxLengthError', "License number can't be longer than 200 characters")
				}else{
					const placementDetails = await savePlacementDetails(ViewModel, (selectedCampus as string));
					updateViewModel({ ...placementDetails });
					methods.reset(placementDetails);
					
					updateExemptForLicensureFor2020(selectedEnrollment.stuEnrollId,exemptForLicensureFor2020)
					.then(() => {
						setSnackBarProps(() => {
							return {
								variant: "success",
								showSnackBar: true,
								messageInfo: 'Placement Details Saved Successfully',
							};
						});
					});
				}
			}
			catch (error) {
				setSnackBarProps((props:any) => {
					return {
						...props,
						variant: "error",
						showSnackBar: true,
						messageInfo: error,
					};
				});
			}
			finally {
				getPlacementHistory();
			}
		}
	};

	React.useEffect(() => {
		getPlacementDetails();
		getPlacementHistory();

		if(selectedEnrollment && selectedEnrollment.stuEnrollId){
			isExemptedForLicensureFor2020(selectedEnrollment.stuEnrollId)
			.then(isExempted => {
				let exempted:boolean = false;

				if(isExempted && isExempted === true){
					exempted = true;
				}

				setExemptForLicensureFor2020(exempted);
			});
		}
	}, [selectedEnrollment]);

	return (
		<div>
			<CustomSnackbar
				variant={snackBarProps.variant}
				message={snackBarProps.messageInfo}
				open={snackBarProps.showSnackBar}
				onClose={(event?: React.SyntheticEvent, reason?: string) => {
					setSnackBarProps((props: any) => {
						return { ...props, showSnackBar: false };
					});
				}}
			></CustomSnackbar>
			<FormContext {...methods}>
				<form onSubmit={methods.handleSubmit(handleSave)}>
					<div>
						<Card className={classes.cardSeparator}>
							<Typography className={clsx(classes.cardTitle)}> Exit Interview
				</Typography>
							<CardContent>
								<ExitInterview
									exitInterviewDetails={ViewModel}
									updateDetails={(details) => updateViewModel({ ...ViewModel, ...details })}
								/>
							</CardContent>
						</Card>
						<Card className={classes.cardSeparator}>
							<Typography className={clsx(classes.cardTitle)}> Eligibility </Typography>

							<CardContent>
								<Eligibility eligibilityDetails={ViewModel}
									updateDetails={(details) => updateViewModel({ ...ViewModel, ...details })} />
							</CardContent>
						</Card>
						<Card className={classes.cardSeparator}>
							<Typography className={clsx(classes.cardTitle)}>Certification Exam 	</Typography>

							<CardContent>
								<Certifications certificationDetails={ViewModel}
									updateDetails={(details) => updateViewModel({ ...ViewModel, ...details })}
									exemptForLicensureFor2020={exemptForLicensureFor2020}
									setExemptForLicensureFor2020={setExemptForLicensureFor2020} />
							</CardContent>
						</Card>

						<Card className={classes.cardSeparator}>
							<Typography className={clsx(classes.cardTitle)}>
								<div className={classes.headerText}> Job Placement </div>
							</Typography>

							<CardContent>
								<PlacementHistory
									placementHistory={ViewModel}
									placementList={placementHistoryList ?? []}
									updateDetails={(details) => updateViewModel({ ...ViewModel, ...details })} />
							</CardContent>
						</Card>
					</div>
					<div>
						{selectedEnrollment && (selectedEnrollment as Enrollment).stuEnrollId &&
							<React.Fragment>
							<AccessManager
								allowedPermissions={[security.permissions.student.updatePlacement]}
								renderNoAccess={() => <Button
									disabled={true}
									className={classes.buttonColorPrimary}
									onClick={_e => {
										methods.handleSubmit(handleSave);
									}}
									size="small"
									type="submit"
									color="primary"
								>Save</Button>}
							>
								<Button
									className={classes.buttonColorPrimary}
									onClick={_e => {
										methods.handleSubmit(handleSave);
									}}
									size="small"
									type="submit"
								>Save</Button>
							</AccessManager>


							<AccessManager
								allowedPermissions={[security.permissions.student.updatePlacement]}
								renderNoAccess={() => <Button
									className={classes.buttonColorSecondary}
									onClick={newAwardClick}
									disabled={true}
									size="small"
									type="button"
									title="New Job Placement"
								>New</Button>}
							>
								<Button
									className={classes.buttonColorSecondary}
									onClick={newAwardClick}
									size="small"
									type="button"
									title="New Job Placement"
								>New</Button>
							</AccessManager>
								
							</React.Fragment>}
						{methods.formState.dirty && (
							<Button
								className={classes.buttonColorTertiary}
								size="small"
								onClick={getPlacementDetails}
								type="button"
							>Cancel</Button>
						)}
						{ViewModel.studentPlacementId && ViewModel.studentPlacementId !== EmptyGuid && (
							<Button
								className={classes.buttonColorTertiary}
								size="small"
								onClick={deletePlacementDetails}
								type="button"
								title="Delete Selected Job Placement"
							>Delete</Button>
						)}
					</div>
				</form>
			</FormContext>
		</div>
	);
};

export default PlacementDetails;
