import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DropDownListItem } from '../../../interfaces/DropDownListItem';
import TextField from '@material-ui/core/TextField';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CampusForCurrentUserAutoComplete from '../../../components/AutoComplete/CampusForCurrentUserAutoComplete';
import { Grid } from '@material-ui/core';
import { FormContext, useForm, FieldError } from 'react-hook-form';
import { IInstructorReport } from '../../../interfaces/reports/attendance/IInstructorReport';
import * as instructorReportApi from '../../../api/reports/attendance/instructorReportApi';
import { ReportOutput } from '../../../enums/ReportOutput';
import ReportOverview from '../../../components/_Layout/Reports/ReportOverview';
import { IReportOverview } from '../../../interfaces/reports/IReportOverview';
import ProgramVersionAutoComplete from '../../../components/AutoComplete/ProgramVersionAutoComplete';
import EnrollmentStatusAutoComplete from "../../../components/AutoComplete/EnrollmentStatusAutoComplete";
import moment from 'moment';
import InstructorsAutoComplete from '../../../components/AutoComplete/InstructorAutoComplete';
const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			paddingTop: theme.spacing(3),
			minHeight: '100%',
			height: '100%',
			maxWidth: '1050px',
			padding: theme.spacing(2),
		},
		'& .makeStyles-content': {
			backgroundColor: theme.palette.background.paper,
		},
		startDateField: {
			width: '100%',
		},
	})
);

const InstructorReport = (props: any) => {
	const { report } = props;
	let reportOverview = { report: report } as IReportOverview;
	const classes = useStyles({});
	const [preview, setPreview] = React.useState<any>();
	const [previewLoader, setPreviewLoader] = React.useState<boolean>();

	const data = useForm<any>({ mode: 'onBlur' });
	const [model, setModel] = React.useState({
		campusId: '',
		startDate: undefined as Date | undefined,
		endDate: undefined as Date | undefined,
		exportType: 1 as ReportOutput,
		instructorId: '',
		enrollmentStatusIds: ['All'],
	});

	const handleFieldOnChange = (
		fieldId: string,
		value: any,
		mapFunction?: Function
	) => {
		let updatedModel = model;
		let newValue = mapFunction ? mapFunction(value) : value;
		(updatedModel as any)[fieldId] = newValue;
		if (fieldId === 'campusId') {
			(updatedModel as any)['instructorId'] =  '';
			
		}
		setModel({ ...updatedModel });
	};

	const { handleSubmit } = data;

	const exportTypes: Array<DropDownListItem> = [
		{ text: 'PDF', id: '1' },
		{ text: 'Excel', id: '2' },
		
	];

	const onSubmit = async (isPreview: any) => {
		let params = (await getReportParameters()) as IInstructorReport;
		const validation = await data.triggerValidation();
		if (validation) {
			if (isPreview) {
				setPreviewLoader(true);
				instructorReportApi
					.generateReport(params, isPreview)
					.then((res: any) => {
						if (res) {
							res.arrayBuffer().then((buffer: any) => {
								setPreview(buffer);
								setPreviewLoader(false);
							});
						} else setPreviewLoader(false);
					});
			} else await instructorReportApi.generateReport(params);
		}
	};

	const exportTypeAutoComplete = {
		options: exportTypes,
		getOptionLabel: (option: DropDownListItem) => option.text,
	};

	const filterHandler = (data: any) => {
		if (data) {
			setModel(data);
		} else {
			setModel({
				campusId: '',
				startDate: undefined as Date | undefined,
				endDate: undefined as Date | undefined,
				enrollmentStatusIds: ['All'],
				exportType: 1 as ReportOutput,
				instructorId: '',
			});
		}
	};


	const getReportParameters = async () => {
		const success = await data.triggerValidation();
		if (model && success) {
			let startDate = model.startDate ? model.startDate : undefined;
			let EndDate = model.endDate ? model.endDate : undefined;
			let enrollmentStatus = model.enrollmentStatusIds.filter((item: any) => item !== 'All');
			let params: IInstructorReport = {
				campusId: model.campusId,
				startDate: startDate,
				endDate: EndDate,
				exportType: model.exportType,
				instructorId: model.instructorId,
				enrollmentStatusIds: enrollmentStatus,
				//clientDateTime:moment().format('MM/DD/yyyy hh:mm A'),
			};
			return params;
		}
		return {} as IInstructorReport;
	};

	const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
		const item = items.find((opt) => {
			if (opt.id === value) return opt;
		});
		return item || ({} as DropDownListItem);
	};

	const setEnrollmentStatus = (enrollmentStatus: any) => {
		let newStatuses: string[] = [];
		if (enrollmentStatus !== null) {
		  if (Array.isArray(enrollmentStatus)) {
			if (enrollmentStatus.length > 1) {
			  enrollmentStatus = enrollmentStatus.filter((item: any) => item.value !== 'All');
			}
			newStatuses = enrollmentStatus.map((gr: any) => gr.value);
		  } else {
			newStatuses.push(enrollmentStatus.value);
		  }
		}
		return newStatuses;
	  };
	

	let parameters = (
    <div className={classes.root}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} sm={6} xs={12}>
              <CampusForCurrentUserAutoComplete
                id="toCampus"
                name="toCampus"
                label="Campus *"
                filterHandle={(v: any) => {
                  handleFieldOnChange("campusId", v ? v.value : undefined);
                }}
                valueFilter={
                  model.campusId
                    ? {
                        key: "value",
                        values: [model.campusId],
                      }
                    : undefined
                }
                error={!!data.errors.toCampus}
                inputRef={data.register({ required: true })}
                helperText={
                  data.errors.toCampus ? "Campus is required." : undefined
                }
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Autocomplete
                {...exportTypeAutoComplete}
                autoComplete
                includeInputInList
                onChange={(e: any, value: any) => {
                  handleFieldOnChange(
                    "exportType",
                    value ? value : undefined,
                    (v: any) => v?.id
                  );
                }}
                value={getSelectedItem(
                  exportTypes,
                  model.exportType?.toString()
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Export Type"
                    name="exportType"
                    error={!!data.errors.exportType}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.exportType
                        ? "Export Type is required."
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="startDate"
                  name="startDate"
                  label="Start Date *"
                  value={model.startDate ? model.startDate : null}
                  onChange={(e: any, value: any) =>
                    handleFieldOnChange("startDate", value ? value : undefined)
                  }
                  className={classes.startDateField}
                  KeyboardButtonProps={{
                    "aria-label": "report date",
                  }}
                  error={!!data.errors["startDate"]}
                  innerRef={(ref: any) => {
                    data.register("startDate", {
                      required: true,
                      validate: {
                        beforeEndDate: (value) =>
                          new Date(value) <
                            new Date(data.getValues()["endDate"]) ||
                          "Start Date must be before end date",
                      },
                    });
                    data.setValue("startDate", model.startDate);
                  }}
                  helperText={
                    data.errors["startDate"]
                      ? (data.errors["startDate"] as FieldError).message
                      : undefined
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="endDate"
                  name="endDate"
                  label="End Date *"
                  value={model.endDate ? model.endDate : null}
                  onChange={(e: any, value: any) =>
                    handleFieldOnChange("endDate", value ? value : null)
                  }
                  className={classes.startDateField}
                  KeyboardButtonProps={{
                    "aria-label": "end date",
                  }}
                  error={!!data.errors["endDate"]}
                  innerRef={(ref: any) => {
                    data.register("endDate", {
                      required: true,
                      validate: {
                        beforeEndDate: (value) =>
                          new Date(value) >
                            new Date(data.getValues()["startDate"]) ||
                          "End Date must be after Start date",
                      },
                    });
                    data.setValue("endDate", model.endDate);
                  }}
                  helperText={
                    data.errors["endDate"]
                      ? (data.errors["endDate"] as FieldError).message
                      : undefined
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <InstructorsAutoComplete
                id="instructorId"
                name="instructorId"
                label="Instructor *"
                //labelName = "Instructor *"
                campusId={model.campusId}
                valueFilter={
                  model.instructorId
                    ? {
                        key: "value",
                        values: [model.instructorId],
                      }
                    : undefined
                }
                filterHandle={(v: any) => {
                  handleFieldOnChange("instructorId", v ? v.value : null);
                }}
                error={!!data.errors.instructorId}
                inputRef={data.register({ required: true })}
                required={true}
                helperText={
                  data.errors.instructorId
                    ? "Instructor is required."
                    : undefined
                }
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <EnrollmentStatusAutoComplete
                filterHandle={(statuses: any) => {
                  handleFieldOnChange(
                    "enrollmentStatusIds",
                    statuses,
                    setEnrollmentStatus
                  );
                }}
                id="enrollmentStatusIds"
                name="enrollmentStatusIds"
                label="Enrollment Status (If non selected report will show all)"
                valueFilter={
                  model?.enrollmentStatusIds
                    ? {
                        key: "value",
                        values: model?.enrollmentStatusIds,
                      }
                    : undefined
                }
                multiple={true}
              />
            </Grid>
          </Grid>
        </form>
      </FormContext>
    </div>
  );
	reportOverview.parameters = parameters;

	return (
		<ReportOverview
			reportOverview={reportOverview}
			filterHandler={filterHandler}
			getReportParameters={getReportParameters}
			exportHandler={onSubmit}
			preview={preview}
			previewLoader={previewLoader}
		/>
	);
};
export default InstructorReport;
