import React from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	CardContent,
	Card,
	IconButton,
	Grid,
	Link,
	createStyles,
	makeStyles,
} from '@material-ui/core';

import { MoreVert, Refresh } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { terminationWidgetApi } from '../../../src/api/student/academics/terminationWidgetApi';
import AccessManager from '../../components/security/AccessManager';
import { security } from '../../constants/Security/securityConstants';
import { IPendingTermination } from '../../interfaces/academicRecords/IPendingTermination';
import { IPendingTerminationDetails } from '../../interfaces/academicRecords/IPendingTerminationDetails';
import { AxiosResponse } from 'axios';
import Moment from 'moment';
import TerminationSettingsPopup from './terminationSettings';
import * as widgetStyles from './WidgetStyles';
import { withRouter } from 'react-router-dom';
import { IExceededMTFDetail } from '../../interfaces/academicRecords/IExceededMTFDetail';

const useStyles = widgetStyles.default;
const customStyles = makeStyles((_theme: any) =>
	createStyles({
		enrollmentCell: {
			width: '46%',
		},
		dateCell: {
			width: '22%',
		},
		missedDaysCell: {
			width: '10%',
		},
	})
);

function TerminationWidget(props: any) {
	const classes = useStyles();
	const customClasses = customStyles();

	const [
		AllowedConsectiveAbsenceExceeded,
		setAllowedConsectiveAbsenceExceeded,
	] = React.useState<IPendingTermination[]>([]);
	const [
		AllowedConsecutiveAbsenceWillExceedInAWeek,
		setAllowedConsecutiveAbsenceWillExceedInAWeek,
	] = React.useState<IPendingTermination[]>([]);
	const [
		AllowedIncompleteGradDaysExceeded,
		setAllowedIncompleteGradDaysExceeded,
	] = React.useState<IPendingTermination[]>([]);
	const [LoaExceeded180Days, setLoaExceeded180Days] = React.useState<
    IPendingTermination[]
  >([]);

	const [MTFWeeksExceeded, setMTFWeeksExceeded] = React.useState<
    IExceededMTFDetail[]
  >([]);

	const resetPage = () => {
		setAllowedConsectiveAbsenceExceeded([]);
		setAllowedConsecutiveAbsenceWillExceedInAWeek([]);
		setAllowedIncompleteGradDaysExceeded([]);
		setLoaExceeded180Days([]);
		setMTFWeeksExceeded([]);
	};
	const LoadPendingTerminationDetails = (campusId: any) => {
		terminationWidgetApi.getPendingTerminations(campusId).then(
			(response: AxiosResponse<IPendingTerminationDetails>) => {
				if (response && response.data) {
					setAllowedConsectiveAbsenceExceeded([
						...response.data.allowedConsectiveAbsenceExceeded,
					]);
					setAllowedConsecutiveAbsenceWillExceedInAWeek([
						...response.data.allowedConsecutiveAbsenceWillExceedInAWeek,
					]);
					setAllowedIncompleteGradDaysExceeded([
						...response.data.allowedIncompleteGradDaysExceeded,
					]);
					setLoaExceeded180Days([...response.data.loaExceeded180Days]);

					setMTFWeeksExceeded([...response.data.exceededMTFWeeksForProgram]);
				} else {
					setAllowedConsectiveAbsenceExceeded([]);
					setAllowedConsecutiveAbsenceWillExceedInAWeek([]);
					setAllowedIncompleteGradDaysExceeded([]);
					setLoaExceeded180Days([]);
					setMTFWeeksExceeded([]);
				}
			},
			(_exception: any) => {
				setAllowedConsectiveAbsenceExceeded([]);
				setAllowedConsecutiveAbsenceWillExceedInAWeek([]);
				setAllowedIncompleteGradDaysExceeded([]);
				setLoaExceeded180Days([]);
				setMTFWeeksExceeded([]);
			}
		);
	};

	const campusId = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	React.useEffect(() => {
		if (campusId) {
			resetPage();
			LoadPendingTerminationDetails(campusId);
		}
	}, [campusId]);

	const [isTerminationSettingsPopupOpen, setIsTerminationSettingsPopupOpen] =
    React.useState<boolean>(false);

	const handleSettingsPopup = (isOpen: boolean) => {
		setIsTerminationSettingsPopupOpen(isOpen);
	};

	const handleRefreshOnSettingsChange = () => {
		LoadPendingTerminationDetails(campusId);
	};

	return (
		<AccessManager
			allowedPermissions={[
				security.permissions.terminationWidget.viewTerminationWidget,
			]}
			renderNoAccess={() => null}
		>
			<Card className={classes.root}>
				<Typography className={clsx(classes.cardTitle)}>
					<Grid container direction="row" justify="center" alignItems="center">
						<Grid item xs={6}>
							<div className={classes.headerText}>
                STUDENTS PENDING TERMINATION
							</div>
						</Grid>

						<Grid item xs={6} className={classes.widgetMenu}>
							<IconButton
								className={classes.iconBtn}
								aria-label="settings"
								onClick={(_e: any) => {
									LoadPendingTerminationDetails(campusId);
								}}
							>
								<Refresh />
							</IconButton>

							<AccessManager
								allowedPermissions={[
									security.permissions.terminationSettings
										.viewTerminationSettings,
								]}
								renderNoAccess={() => (
									<IconButton
										className={classes.iconBtn}
										disabled={true}
										aria-label="settings"
									>
										<MoreVert />
									</IconButton>
								)}
							>
								<IconButton
									className={classes.iconBtn}
									aria-label="settings"
									onClick={(_e: any) => {
										handleSettingsPopup(true);
									}}
								>
									<MoreVert />
								</IconButton>
							</AccessManager>
						</Grid>
					</Grid>
				</Typography>
				{(AllowedConsectiveAbsenceExceeded !== null &&
          AllowedConsectiveAbsenceExceeded.length > 0) ||
        (AllowedConsecutiveAbsenceWillExceedInAWeek !== null &&
          AllowedConsecutiveAbsenceWillExceedInAWeek.length > 0) ||
        (AllowedIncompleteGradDaysExceeded !== null &&
          AllowedIncompleteGradDaysExceeded.length > 0) ||
        (LoaExceeded180Days !== null && LoaExceeded180Days.length > 0) ||
        (MTFWeeksExceeded !== null && MTFWeeksExceeded.length > 0) ? (
						<CardContent className={`${classes.cardContent}`}>
							<div className={classes.innerRoot}>
								{AllowedConsectiveAbsenceExceeded !== null &&
                AllowedConsectiveAbsenceExceeded.length > 0 && (
									<Typography>
										<Grid container direction="row">
											<Grid item xs={7} md={8} sm={8} lg={8}>
												<div className={classes.headerText}>
													<strong>
                            Exceeded more than allowable consecutive days of
                            absence
													</strong>
												</div>
											</Grid>
											<Grid item xs={5} sm={4} md={4} lg={4}>
												<div
													className={`${classes.headerText} ${classes.textAlignRight} ${classes.headerPaddingRight}`}
												>
													<strong>
                            Students:
														{AllowedConsectiveAbsenceExceeded !== null
															? AllowedConsectiveAbsenceExceeded.length
															: 0}
													</strong>
												</div>
											</Grid>
										</Grid>
									</Typography>
								)}
								{AllowedConsectiveAbsenceExceeded !== null &&
                AllowedConsectiveAbsenceExceeded.length > 0 && (
									<TableContainer
										component={Paper}
										className={classes.tableBottomMargin}
									>
										<Table
											className={classes.table}
											size="small"
											aria-label="a dense table"
										>
											<TableHead>
												<TableRow>
													<TableCell className={classes.headCell} align="left">
                            Enrollment
													</TableCell>
													<TableCell className={classes.headCell} align="left">
                            Start Date
													</TableCell>
													<TableCell className={classes.headCell} align="left">
                            LDA
													</TableCell>
													<TableCell className={classes.headCell} align="left">
                            Days Missed
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{AllowedConsectiveAbsenceExceeded !== null &&
                        AllowedConsectiveAbsenceExceeded.length > 0 ? (
														AllowedConsectiveAbsenceExceeded.map(
															(row: IPendingTermination) => {
																return (
																	<TableRow key={`${row.studentNumber}`}>
																		<TableCell
																			className={`${classes.bodyCell} ${customClasses.enrollmentCell}`}
																			align="left"
																		>
																			<Link
																				className={classes.textAlignLeft}
																				component="button"
																				onClick={() => {
																					let urlParams = new URLSearchParams(
																						props.history.location.search
																					);
																					let studentIdQueryId = 'studentId';
																					let campusIdQueryId = 'campusId';

																					//set url parameters for campusId and studentId
																					urlParams.set(
																						studentIdQueryId,
                                          row.studentId as any
																					);
																					urlParams.set(
																						campusIdQueryId,
																						campusId
																					);
																					props.history.push({
																						pathname: '/student/termination',
																						search: urlParams.toString(),
																					});
																				}}
																			>
																				{`${row.lastName} ${row.firstName} ${row.studentNumber} (${row.enrollmentTitle})`}
																			</Link>
																		</TableCell>
																		<TableCell
																			className={`${classes.bodyCell} ${customClasses.dateCell}`}
																			align="left"
																		>
																			{row.startDate
																				? Moment(row.startDate).format(
																					'MM/DD/YYYY'
																				)
																				: ''}
																		</TableCell>
																		<TableCell
																			className={`${classes.bodyCell} ${customClasses.dateCell}`}
																			align="left"
																		>
																			{row.lastDateAttended
																				? Moment(row.lastDateAttended).format(
																					'MM/DD/YYYY'
																				)
																				: ''}
																		</TableCell>
																		<TableCell
																			className={`${classes.bodyCell} ${customClasses.missedDaysCell}`}
																			align="left"
																		>
																			{row.daysMissed}
																		</TableCell>
																	</TableRow>
																);
															}
														)
													) : (
														<TableRow>
															<TableCell colSpan={4} align="center">
                              No Data
															</TableCell>
														</TableRow>
													)}
											</TableBody>
										</Table>
									</TableContainer>
								)}
								{AllowedConsecutiveAbsenceWillExceedInAWeek !== null &&
                AllowedConsecutiveAbsenceWillExceedInAWeek.length > 0 && (
									<Typography>
										<Grid container direction="row">
											<Grid item xs={7} md={8} sm={8} lg={8}>
												<div className={classes.headerText}>
													<strong>
                            May exceed more than allowable consecutive days of
                            absence in a week
													</strong>
												</div>
											</Grid>
											<Grid item xs={5} sm={4} md={4} lg={4}>
												<div
													className={`${classes.headerText} ${classes.textAlignRight} ${classes.headerPaddingRight}`}
												>
													<strong>
                            Students:
														{AllowedConsecutiveAbsenceWillExceedInAWeek !== null
															? AllowedConsecutiveAbsenceWillExceedInAWeek.length
															: 0}
													</strong>
												</div>
											</Grid>
										</Grid>
									</Typography>
								)}
								{AllowedConsecutiveAbsenceWillExceedInAWeek !== null &&
                AllowedConsecutiveAbsenceWillExceedInAWeek.length > 0 && (
									<TableContainer
										component={Paper}
										className={classes.tableBottomMargin}
									>
										<Table
											className={classes.table}
											size="small"
											aria-label="a dense table"
										>
											<TableHead>
												<TableRow>
													<TableCell className={classes.headCell} align="left">
                            Enrollment
													</TableCell>
													<TableCell className={classes.headCell} align="left">
                            Start Date
													</TableCell>
													<TableCell className={classes.headCell} align="left">
                            LDA
													</TableCell>
													<TableCell className={classes.headCell} align="left">
                            Days Missed
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{AllowedConsecutiveAbsenceWillExceedInAWeek !== null &&
                        AllowedConsecutiveAbsenceWillExceedInAWeek.length >
                          0 ? (
														AllowedConsecutiveAbsenceWillExceedInAWeek.map(
															(row: IPendingTermination) => {
																return (
																	<TableRow key={`${row.studentNumber}`}>
																		<TableCell
																			className={`${classes.bodyCell} ${customClasses.enrollmentCell}`}
																			align="left"
																		>
																			<Link
																				className={classes.textAlignLeft}
																				component="button"
																				onClick={() => {
																					let urlParams = new URLSearchParams(
																						props.history.location.search
																					);
																					let studentIdQueryId = 'studentId';
																					let campusIdQueryId = 'campusId';

																					//set url parameters for campusId and studentId
																					urlParams.set(
																						studentIdQueryId,
                                          row.studentId as any
																					);
																					urlParams.set(
																						campusIdQueryId,
																						campusId
																					);
																					props.history.push({
																						pathname: '/student/termination',
																						search: urlParams.toString(),
																					});
																				}}
																			>{`${row.lastName} ${row.firstName} ${row.studentNumber} (${row.enrollmentTitle})`}</Link>
																		</TableCell>
																		<TableCell
																			className={`${classes.bodyCell} ${customClasses.dateCell}`}
																			align="left"
																		>
																			{row.startDate
																				? Moment(row.startDate).format(
																					'MM/DD/YYYY'
																				)
																				: ''}
																		</TableCell>
																		<TableCell
																			className={`${classes.bodyCell} ${customClasses.dateCell}`}
																			align="left"
																		>
																			{row.lastDateAttended
																				? Moment(row.lastDateAttended).format(
																					'MM/DD/YYYY'
																				)
																				: ''}
																		</TableCell>
																		<TableCell
																			className={`${classes.bodyCell} ${customClasses.missedDaysCell}`}
																			align="left"
																		>
																			{row.daysMissed}
																		</TableCell>
																	</TableRow>
																);
															}
														)
													) : (
														<TableRow>
															<TableCell colSpan={4} align="center">
                              No Data
															</TableCell>
														</TableRow>
													)}
											</TableBody>
										</Table>
									</TableContainer>
								)}
								{AllowedIncompleteGradDaysExceeded !== null &&
                AllowedIncompleteGradDaysExceeded.length > 0 && (
									<Typography>
										<Grid container direction="row">
											<Grid item xs={7} md={8} sm={8} lg={8}>
												<div className={classes.headerText}>
													<strong>
                            Incomplete grad for more than allowable number of
                            days
													</strong>
												</div>
											</Grid>
											<Grid item xs={5} sm={4} md={4} lg={4}>
												<div
													className={`${classes.headerText} ${classes.textAlignRight} ${classes.headerPaddingRight}`}
												>
													<strong>
                            Students:
														{AllowedIncompleteGradDaysExceeded !== null
															? AllowedIncompleteGradDaysExceeded.length
															: 0}
													</strong>
												</div>
											</Grid>
										</Grid>
									</Typography>
								)}
								{AllowedIncompleteGradDaysExceeded !== null &&
                AllowedIncompleteGradDaysExceeded.length > 0 && (
									<TableContainer
										component={Paper}
										className={classes.tableBottomMargin}
									>
										<Table
											className={classes.table}
											size="small"
											aria-label="a dense table"
										>
											<TableHead>
												<TableRow>
													<TableCell className={classes.headCell} align="left">
                            Enrollment
													</TableCell>
													<TableCell className={classes.headCell} align="left">
                            Start Date
													</TableCell>
													<TableCell className={classes.headCell} align="left">
                            LDA
													</TableCell>
													<TableCell className={classes.headCell} align="left">
                            Days
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{AllowedIncompleteGradDaysExceeded !== null &&
                        AllowedIncompleteGradDaysExceeded.length > 0 ? (
														AllowedIncompleteGradDaysExceeded.map(
															(row: IPendingTermination) => {
																return (
																	<TableRow key={`${row.studentNumber}`}>
																		<TableCell
																			className={`${classes.bodyCell} ${customClasses.enrollmentCell}`}
																			align="left"
																		>
																			<Link
																				className={classes.textAlignLeft}
																				component="button"
																				onClick={() => {
																					let urlParams = new URLSearchParams(
																						props.history.location.search
																					);
																					let studentIdQueryId = 'studentId';
																					let campusIdQueryId = 'campusId';

																					//set url parameters for campusId and studentId
																					urlParams.set(
																						studentIdQueryId,
                                          row.studentId as any
																					);
																					urlParams.set(
																						campusIdQueryId,
																						campusId
																					);
																					props.history.push({
																						pathname: '/student/termination',
																						search: urlParams.toString(),
																					});
																				}}
																			>{`${row.lastName} ${row.firstName} ${row.studentNumber} (${row.enrollmentTitle})`}</Link>
																		</TableCell>
																		<TableCell
																			className={`${classes.bodyCell} ${customClasses.dateCell}`}
																			align="left"
																		>
																			{row.startDate
																				? Moment(row.startDate).format(
																					'MM/DD/YYYY'
																				)
																				: ''}
																		</TableCell>
																		<TableCell
																			className={`${classes.bodyCell} ${customClasses.dateCell}`}
																			align="left"
																		>
																			{row.lastDateAttended
																				? Moment(row.lastDateAttended).format(
																					'MM/DD/YYYY'
																				)
																				: ''}
																		</TableCell>
																		<TableCell
																			className={`${classes.bodyCell} ${customClasses.missedDaysCell}`}
																			align="left"
																		>
																			{row.daysMissed}
																		</TableCell>
																	</TableRow>
																);
															}
														)
													) : (
														<TableRow>
															<TableCell colSpan={4} align="center">
                              No Data
															</TableCell>
														</TableRow>
													)}
											</TableBody>
										</Table>
									</TableContainer>
								)}
								{LoaExceeded180Days !== null && LoaExceeded180Days.length > 0 && (
									<Typography>
										<Grid container direction="row">
											<Grid item xs={7} md={8} sm={8} lg={8}>
												<div className={classes.headerText}>
													<strong>
                          Exceeded 180 days of LOA in 12 month period
													</strong>
												</div>
											</Grid>
											<Grid item xs={5} sm={4} md={4} lg={4}>
												<div
													className={`${classes.headerText} ${classes.textAlignRight} ${classes.headerPaddingRight}`}
												>
													<strong>
                          Students:
														{LoaExceeded180Days !== null
															? LoaExceeded180Days.length
															: 0}
													</strong>
												</div>
											</Grid>
										</Grid>
									</Typography>
								)}
								{LoaExceeded180Days !== null && LoaExceeded180Days.length > 0 && (
									<TableContainer
										component={Paper}
										className={classes.tableBottomMargin}
									>
										<Table
											className={classes.table}
											size="small"
											aria-label="a dense table"
										>
											<TableHead>
												<TableRow>
													<TableCell className={classes.headCell} align="left">
                          Enrollment
													</TableCell>
													<TableCell className={classes.headCell} align="left">
                          Start Date
													</TableCell>
													<TableCell className={classes.headCell} align="left">
                          LDA
													</TableCell>
													<TableCell className={classes.headCell} align="left">
                          LOA Days
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{LoaExceeded180Days !== null &&
                      LoaExceeded180Days.length > 0 ? (
														LoaExceeded180Days.map((row: IPendingTermination) => {
															return (
																<TableRow
																	key={`${row.studentNumber}`}
																	role="checkbox"
																>
																	<TableCell
																		className={`${classes.bodyCell} ${customClasses.enrollmentCell}`}
																		align="left"
																	>
																		<Link
																			className={classes.textAlignLeft}
																			component="button"
																			onClick={() => {
																				let urlParams = new URLSearchParams(
																					props.history.location.search
																				);
																				let studentIdQueryId = 'studentId';
																				let campusIdQueryId = 'campusId';

																				//set url parameters for campusId and studentId
																				urlParams.set(
																					studentIdQueryId,
                                      row.studentId as any
																				);
																				urlParams.set(campusIdQueryId, campusId);
																				props.history.push({
																					pathname: '/student/termination',
																					search: urlParams.toString(),
																				});
																			}}
																		>{`${row.lastName} ${row.firstName} ${row.studentNumber} (${row.enrollmentTitle})`}</Link>
																	</TableCell>
																	<TableCell
																		className={`${classes.bodyCell} ${customClasses.dateCell}`}
																		align="left"
																	>
																		{row.startDate
																			? Moment(row.startDate).format('MM/DD/YYYY')
																			: ''}
																	</TableCell>
																	<TableCell
																		className={`${classes.bodyCell} ${customClasses.dateCell}`}
																		align="left"
																	>
																		{row.lastDateAttended
																			? Moment(row.lastDateAttended).format(
																				'MM/DD/YYYY'
																			)
																			: ''}
																	</TableCell>
																	<TableCell
																		className={`${classes.bodyCell} ${customClasses.missedDaysCell}`}
																		align="left"
																	>
																		{row.daysMissed}
																	</TableCell>
																</TableRow>
															);
														})
													) : (
														<TableRow>
															<TableCell colSpan={4} align="center">
                            No Data
															</TableCell>
														</TableRow>
													)}
											</TableBody>
										</Table>
									</TableContainer>
								)}

								{MTFWeeksExceeded !== null && MTFWeeksExceeded.length > 0 && (
									<Typography>
										<Grid container direction="row">
											<Grid item xs={7} md={8} sm={8} lg={8}>
												<div className={classes.headerText}>
													<strong>Exceeded MTF Weeks for Program</strong>
												</div>
											</Grid>
											<Grid item xs={5} sm={4} md={4} lg={4}>
												<div
													className={`${classes.headerText} ${classes.textAlignRight} ${classes.headerPaddingRight}`}
												>
													<strong>
                          Students:
														{MTFWeeksExceeded !== null
															? MTFWeeksExceeded.length
															: 0}
													</strong>
												</div>
											</Grid>
										</Grid>
									</Typography>
								)}
								{MTFWeeksExceeded !== null && MTFWeeksExceeded.length > 0 && (
									<TableContainer
										component={Paper}
										className={classes.tableBottomMargin}
									>
										<Table
											className={classes.table}
											size="small"
											aria-label="a dense table"
										>
											<TableHead>
												<TableRow>
													<TableCell
														className={classes.headCell}
														style={{ width: '40%' }}
														align="left"
													>
                          Enrollment
													</TableCell>
													<TableCell
														className={classes.headCell}
														style={{ width: '15%' }}
														align="center"
													>
                          Start Date
													</TableCell>
													<TableCell
														className={classes.headCell}
														style={{ width: '15%' }}
														align="center"
													>
                          LDA
													</TableCell>
													<TableCell
														className={classes.headCell}
														style={{ width: '15%' }}
														align="center"
													>
                          Program MTF
													</TableCell>
													<TableCell
														className={classes.headCell}
														style={{ width: '15%' }}
														align="center"
													>
                          Student MTF
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{MTFWeeksExceeded !== null &&
                      MTFWeeksExceeded.length > 0 ? (
														MTFWeeksExceeded.map((row: IExceededMTFDetail) => {
															return (
																<TableRow
																	key={`${row.studentNumber}`}
																	role="checkbox"
																>
																	<TableCell
																		className={`${classes.bodyCell}`}
																		align="left"
																	>
																		<Link
																			className={classes.textAlignLeft}
																			component="button"
																			onClick={() => {
																				let urlParams = new URLSearchParams(
																					props.history.location.search
																				);
																				let studentIdQueryId = 'studentId';
																				let campusIdQueryId = 'campusId';

																				//set url parameters for campusId and studentId
																				urlParams.set(
																					studentIdQueryId,
                                      row.studentId as any
																				);
																				urlParams.set(campusIdQueryId, campusId);
																				props.history.push({
																					pathname: '/student/termination',
																					search: urlParams.toString(),
																				});
																			}}
																		>{`${row.lastName} ${row.firstName} ${row.studentNumber} (${row.enrollmentTitle})`}</Link>
																	</TableCell>
																	<TableCell
																		className={`${classes.bodyCell}`}
																		align="center"
																	>
																		{row.startDate
																			? Moment(row.startDate).format('MM/DD/YYYY')
																			: ''}
																	</TableCell>
																	<TableCell
																		className={`${classes.bodyCell}`}
																		align="center"
																	>
																		{row.lastDateAttended
																			? Moment(row.lastDateAttended).format(
																				'MM/DD/YYYY'
																			)
																			: ''}
																	</TableCell>
																	<TableCell
																		className={`${classes.bodyCell}`}
																		align="center"
																	>
																		{row.totalMTFWeeks}
																	</TableCell>
																	<TableCell
																		className={`${classes.bodyCell}`}
																		align="center"
																	>
																		{row.weeksRequiredToCompleteProgram}
																	</TableCell>
																</TableRow>
															);
														})
													) : (
														<TableRow>
															<TableCell colSpan={4} align="center">
                            No Data
															</TableCell>
														</TableRow>
													)}
											</TableBody>
										</Table>
									</TableContainer>
								)}
							</div>
						</CardContent>
					) : (
						<CardContent className={`${classes.cardContent}`}>
							<div className={classes.innerRoot}>
								<Typography>
									<Grid container direction="row">
										<Grid item xs={2} md={2} sm={2} lg={2}>
											<div className={classes.headerText}>
												<strong>No Pending Terminations</strong>
											</div>
										</Grid>
									</Grid>
								</Typography>
							</div>
						</CardContent>
					)}

				<TerminationSettingsPopup
					open={isTerminationSettingsPopupOpen}
					setOpen={handleSettingsPopup}
					refereshPendingTerminations={handleRefreshOnSettingsChange}
				></TerminationSettingsPopup>
			</Card>
		</AccessManager>
	);
}
export default withRouter(TerminationWidget);
