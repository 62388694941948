import API from "../../../apiWrapper";
import { INewNotes } from "../../../../interfaces/student/notes/INotesInfo";
import { IEmergencyFundsDetail } from "../../../../interfaces/student/financials/IEmergencyFundsInfo";

export const getEmergencyFundsByCampus = (campusId: string) => {
  return API()
    .get("/FinancialAid/EmergencyFund/GetEmergencyFundsAllocation", {
      params: {
        campusId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const removeEmergencyFunds = (emergencyFundId: string) => {
  return API()
    .get("/FinancialAid/EmergencyFund/RemoveEmergencyFundsAllocation", {
      params: {
        emergencyFundId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const GetEmergencyFundsById = (emergencyFundId: number) => {
  return API()
    .get("/FinancialAid/EmergencyFund/GetEmergencyFundsById", {
      params: {
        emergencyFundId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const CreateEmergencyFunds = (model: IEmergencyFundsDetail) => {
  return API()
    .post("/FinancialAid/EmergencyFund/SaveEmergencyFundsAllocation", model)
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};

export const UpdateNotes = (model: INewNotes) => {
  return API()
    .put("/LeadNotes/UpdateNotes", model)
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};

export const PrintEmergencyFunds = (campusId: string, academicYearId: string, emergencyFundTypeId: string) => {
  let params = {
    campusId: campusId,
    academicYearId: academicYearId,
    emergencyFundTypeId: emergencyFundTypeId,
  };
  return API()
    .post("/FinancialAid/EmergencyFund/GetEmergencyFundsReport", params, {
      responseType: "blob",
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          let fileName ="EmergencyFunds.xls";
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        }
        return res;
      },
      (error: any) => {
        return error.response;
      }
    );
};