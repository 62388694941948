import React, { useState, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Switch,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import CampusForCurrentUserAutoComplete from "../../../components/AutoComplete/CampusForCurrentUserAutoComplete";
import { FormContext, useForm, FieldError } from "react-hook-form";
import { ITitleIVAuditReport } from "../../../interfaces/reports/ITitleIVAuditReport";
import * as titleIVAuditReportApi from "../../../api/reports/financialAids/TitleIVAuditReportApi";
import { ReportOutput } from "../../../enums/ReportOutput";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import { getDate } from "date-fns";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      minHeight: "100%",
      height: "100%",
      maxWidth: "1050px",
      padding: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    startDateField: {
      width: "100%",
    },
    fieldSet: {
      marginTop: theme.spacing(2),
    },
    switch: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 0.5),
      height: theme.spacing(8.6),
    },
  })
);

const TitleIVAuditReport = (props: any) => {
  const { report } = props;
  let reportOverview = { report: report } as IReportOverview;
  const classes = useStyles({});
  const [preview, setPreview] = React.useState<any>();
  const [previewLoader, setPreviewLoader] = React.useState<boolean>();
  const [disableForDetailRep, setDisableForDetailRep] = React.useState<boolean>();
  const data = useForm<any>({ mode: "onBlur" });
  const [model, setModel] = React.useState({
    campusId: "",
    startDate: undefined as Date | undefined,
    endDate: undefined as Date | undefined,
    showSSN: "ssn" as string | undefined,
    // showFullSSN: "showfullssn" as string | undefined,
	showFullSSN: false,
	showEmail: false,
  detailReport: false,
    cohortYear: "",
    exportType: 1 as ReportOutput,
  });

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    if (fieldId === "cohortYear") {
      let displayValue = +newValue + 1;
      let newSartD = "01/01/" + newValue;
      let newEndD = "12/31/" + newValue;
      (updatedModel as any)["startDate"] = newSartD;
      (updatedModel as any)["endDate"] = newEndD;
      (updatedModel as any)["cohortYear"] = displayValue.toString();
    }
    setModel({ ...updatedModel });
  };


  const handleDetailReport = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    if (fieldId === "detailReport") {
      
      if (newValue == true)
      {

        (updatedModel as any)["exportType"]  = 2;
        (updatedModel as any)["showFullSSN"]  = false;
        (updatedModel as any)["showEmail"]  = false;
        (updatedModel as any)["showSSN"]  = "ssn";
        setDisableForDetailRep(true)
     
      }
      else 
      {
        (updatedModel as any)["exportType"]  = 1;
        setDisableForDetailRep(false)
      }
      
    }
    setModel({ ...updatedModel });
  };


  const { handleSubmit } = data;

  const exportTypes: Array<DropDownListItem> = [
    { text: "PDF", id: "1" },
    { text: "Excel", id: "2" },
    { text: "Word", id: "3" },
  ];

  const onSubmit = async (isPreview: any) => {
    let params = (await getReportParameters()) as ITitleIVAuditReport;
    const validation = await data.triggerValidation();
    if (validation) {
      if (isPreview) {
        setPreviewLoader(true);
        titleIVAuditReportApi
          .generateReport(params, isPreview)
          .then((res: any) => {
            if (res) {
              res.arrayBuffer().then((buffer: any) => {
                setPreview(buffer);
                setPreviewLoader(false);
              });
            } else setPreviewLoader(false);
          });
      } else await titleIVAuditReportApi.generateReport(params);
    }
  };

  const exportTypeAutoComplete = {
    options: exportTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };
  const handleSSNShowChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let updatedModel = model;
    (updatedModel as any)["showSSN"] = (event.target as HTMLInputElement).value;
    setModel({ ...updatedModel });
  };

  const handleFullSSNShowChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let updatedModel = model;
    (updatedModel as any)["showFullSSN"] = (
      event.target as HTMLInputElement
    ).value;
    setModel({ ...updatedModel });
  };

  const filterHandler = (data: any) => {
    if (data) {
      setModel(data);
    } else {
      setModel({
        campusId: "",
        startDate: undefined as Date | undefined,
        endDate: undefined as Date | undefined,
        showSSN: "ssn",
		showFullSSN: false,
		showEmail: false,
    detailReport: false,
        cohortYear: "",
        exportType: 1 as ReportOutput,
      });
    }
  };

  const getReportParameters = async () => {
    const success = await data.triggerValidation();
    if (model && success) {
      let startDate = model.startDate ? model.startDate : undefined;
      let EndDate = model.endDate ? model.endDate : undefined;
      let params: ITitleIVAuditReport = {
        campusId: model.campusId,
        startDate: startDate,
        endDate: EndDate,
        showSSN: model.showSSN,
        showFullSSN: model.showFullSSN,
		showEmail: model.showEmail,
    detailReport: model.detailReport,
        cohortYear: model.cohortYear,
        exportType: model?.exportType,
      };
      return params;
    }
    return {} as ITitleIVAuditReport;
  };

  const getSelectedItem = (items: Array<DropDownListItem>, value: any) => {
    const item = items.find((opt) => {
      if (opt.id == value) return opt;
    });
    return item || ({} as DropDownListItem);
  };


  useEffect(() => {
    if (model.showSSN !== 'ssn' && model.showFullSSN) {
        setModel(prevModel => ({ ...prevModel, showFullSSN: false }));
    }
}, [model.showSSN]);

  let parameters = (
    <div className={classes.root}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <FormControlLabel
    label="Detail Report"
    disabled={model.showSSN !== 'ssn'}
    control={
<Switch
            checked={model.detailReport}
            value="detailReport"
            onChange={(e: any) => {
              handleDetailReport("detailReport",  e.target.checked ?  e.target.checked : false )
            }}
            color="primary"
        />
    }
/>
          </FormControl>

          <Grid container direction="row" spacing={1}>



            <Grid item md={6} sm={6} xs={12}>
              <CampusForCurrentUserAutoComplete
                id="toCampus"
                name="toCampus"
                label="Campus *"
                filterHandle={(v: any) => {
                  handleFieldOnChange("campusId", v ? v.value : undefined);
                }}
                valueFilter={
                  model.campusId
                    ? {
                        key: "value",
                        values: [model.campusId],
                      }
                    : undefined
                }
                error={!!data.errors.toCampus}
                inputRef={data.register({ required: true })}
                helperText={
                  data.errors.toCampus ? "Campus is required." : undefined
                }
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Autocomplete
                {...exportTypeAutoComplete}
                autoComplete
                includeInputInList
                disabled={disableForDetailRep}
                onChange={(e: any, value: any) => {
                  handleFieldOnChange(
                    "exportType",
                    value ? value : undefined,
                    (v: any) => v?.id
                  );
                }}
                value={getSelectedItem(exportTypes, model.exportType)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Export Type"
                    name="exportType"
                    error={!!data.errors.exportType}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.exportType
                        ? "Export Type is required."
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="startDate"
                  name="startDate"
                  label="Start Date *"
                  value={model.startDate ? model.startDate : null}
                  onChange={(e: any, value: any) =>
                    handleFieldOnChange("startDate", value ? value : undefined)
                  }
                  className={classes.startDateField}
                  KeyboardButtonProps={{
                    "aria-label": "report date",
                  }}
                  error={!!data.errors["startDate"]}
                  innerRef={(ref: any) => {
                    data.register("startDate", {
                      required: true,
                      validate: {
                        beforeEndDate: (value) =>
                          new Date(value) <
                            new Date(data.getValues()["endDate"]) ||
                          "Start Date must be before end date",
                      },
                    });
                    data.setValue("startDate", model.startDate);
                  }}
                  helperText={
                    data.errors["startDate"]
                      ? (data.errors["startDate"] as FieldError).message
                      : undefined
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="endDate"
                  name="endDate"
                  label="End Date *"
                  value={model.endDate ? model.endDate : null}
                  onChange={(e: any, value: any) =>
                    handleFieldOnChange("endDate", value ? value : null)
                  }
                  className={classes.startDateField}
                  KeyboardButtonProps={{
                    "aria-label": "end date",
                  }}
                  error={!!data.errors["endDate"]}
                  innerRef={(ref: any) => {
                    data.register("endDate", {
                      required: true,
                      validate: {
                        beforeEndDate: (value) =>
                          new Date(value) >
                            new Date(data.getValues()["startDate"]) ||
                          "End Date must be after Start date",
                      },
                    });
                    data.setValue("endDate", model.endDate);
                  }}
                  helperText={
                    data.errors["endDate"]
                      ? (data.errors["endDate"] as FieldError).message
                      : undefined
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item md={3} sm={3} xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  views={["year"]}
                  openTo={"year"}
                  disableToolbar
                  variant="inline"
                  format="yyyy"
                  id="cohortYear"
                  name="cohortYear"
                  label="Cohort Year"
                  maxDate={new Date()}
                  value={model.cohortYear ? model.cohortYear : null}
                  onChange={(e: any, value: any) =>
                    handleFieldOnChange("cohortYear", value ? value : null)
                  }
                  KeyboardButtonProps={{
                    "aria-label": "cohort year",
                  }}
                  error={!!data.errors["cohortYear"]}
                  helperText={
                    data.errors["cohortYear"]
                      ? (data.errors["cohortYear"] as FieldError).message
                      : undefined
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <FormControl component="fieldset" className={classes.fieldSet} disabled={disableForDetailRep}>
                <FormLabel component="legend">Print with</FormLabel>
                <RadioGroup
                //disabled ={disableForDetailRep}
                  row
                  aria-label="showSSN"
                  name="showSSN"
                  value={model.showSSN}
                  onChange={handleSSNShowChange}
				  hidden={true}
                >
                  <FormControlLabel
                    labelPlacement="start"
                    value="ssn"
                    control={<Radio />}
                    label="SSN"
                  />
                  <FormControlLabel
                    labelPlacement="start"
                    value="studentNumber"
                    control={<Radio />}
                    label="Student Number"
                  />
                  {/* <FormControlLabel
                    labelPlacement="start"
                    value="showFullSSN"
                    control={<Radio />}
                    label="Show Full SSN"
                  /> */}
                </RadioGroup>				
              </FormControl>
            </Grid>
            
			{/* <FormLabel component="legend">Print with</FormLabel>
                <RadioGroup
                  row
                  aria-label="showSSN"
                  name="showFullSSN"
                  value={model.showFullSSN}
                  onChange={handleFullSSNShowChange}
				  hidden={true}
                >
                  <FormControlLabel
                    labelPlacement="start"
                    value="showFullSSN"
                    control={<Radio />}
                    label="Show Full SSN"
                  />
                </RadioGroup> */}

			{/* <Grid item xs={4}>
              <FormControlLabel
                className={classes.switch}
				//hidden={true}
				//disabled={true}
				disabled={model.showSSN!=='ssn'}
                labelPlacement="end"
                label="Show Full SSN"
                control={
                  <Switch
                    //checked={model.showFullSSN}
                    name="showTermDescription"
                    onChange={(e: any) => {
                      model.showFullSSN = e ? e.target.checked : false;
                      setModel({ ...model });
                    }}
                    color="primary"
                  />
                }
              />
            </Grid> */}

<FormControl>
								{/* <FormControlLabel
									label="Show Full SSN"
									disabled={model.showSSN!=='ssn'}
									control={
										<Switch
											checked={model.showFullSSN}
											value="showFullSSN"
											onChange={(e: any) => {
												setModel({ ...model, showFullSSN: e.target.checked })												
											}}
											color="primary"
										/>
									}
								/>
							</FormControl> */}

 

<FormControlLabel
    label="Show Full SSN"
    disabled={model.showSSN !== 'ssn'}
    control={
<Switch
            checked={model.showFullSSN}
            value="showFullSSN"
            onChange={(e: any) => {
                setModel({ ...model, showFullSSN: e.target.checked })
            }}
            color="primary"
        />
    }
/>

<FormControlLabel
    label="Show Email"
    disabled={disableForDetailRep}
    control={
<Switch
            checked={model.showEmail}
            value="showEmail"
            onChange={(e: any) => {
                setModel({ ...model, showEmail: e.target.checked })
            }}
            color="primary"
        />
    }
/>
</FormControl>

          </Grid>
        </form>
      </FormContext>
    </div>
  );
  reportOverview.parameters = parameters;

  return (
    <ReportOverview
      reportOverview={reportOverview}
      filterHandler={filterHandler}
      getReportParameters={getReportParameters}
      exportHandler={onSubmit}
      preview={preview}
      previewLoader={previewLoader}
    />
  );
};
export default TitleIVAuditReport;
