import * as React from "react";
import { useRef } from "react";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";

const config: ApiAutoCompleteConfigItem = {
    acId: "fundSourcesMultiCampuses",
    cacheTime: 5,
    route: "/StudentAccounts/FundSource/GetFundSourcesByCampuses",
    requestType: "GET",
    body: null,
    hasUrlParams: true,
    useCache: false,
    storeInCache: true,
    isCampusSpecific: false,
    isUserSpecific: false,
    loadOnRender: true,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
        return data;
    }
};

interface IFundSourcesAutoCompletePropsMultiCampus extends FilterParams {
    campuses: Array<string>;
}

const FundSourcesAutoCompleteMultiCampus = (props: IFundSourcesAutoCompletePropsMultiCampus) => {
    const inputRef: any = useRef(null);

    const handleOnChange = (e: any) => {
        if (props.filterHandle) {
            props.filterHandle(e);
        }
    };

    return (
        <div>
            <ApiAutoComplete
                config={config}
                loading={props.campuses ? false : true}
                reqParams={{ campusId: props.campuses }}
                classes={{
                    option: "text-black"
                }}
                label={props.label ? props.label : "Fund Source"}
                onChange={(e: any) => {
                    handleOnChange(e);
                }}
                showIncludeAllValue={props.showIncludeAllValue}
                includeAllValue={props.includeAllValue}
                multiple={props.multiple || false}
                valueFilter={props.valueFilter ? props.valueFilter : undefined}
                inputRef={props.inputRef ? props.inputRef : inputRef}
                error={props.error}
                helperText={props.helperText}
                inputName={props.name}
                optionsFilter={props.optionsFilter ? props.optionsFilter : undefined}
                maxTags={props.maxTags}
            ></ApiAutoComplete>
        </div>
    );
};
export default FundSourcesAutoCompleteMultiCampus;