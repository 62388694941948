import React, { useState, useEffect } from "react";
import {
  makeStyles,
  createStyles,
  Card,
  Grid,
  Button,
} from "@material-ui/core";
import ProgramCatalogGeneralDetails from "./ProgramCatalogGeneralDetails";
import ProgramCatalogAcademicDetails from "./ProgramCatalogAcademicDetails";
import ProgramCatalogAttendanceDetails from "./ProgramCatalogAttendanceDetails";
import ProgramCatalogFinancialDetails from "./ProgramCatalogFinancialDetails";

import { ProgramVersionTabProps } from "../../../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersionTabProps";
import ProgressSaveButton from "../../../../../../components/_Layout/Buttons/ProgressSaveButton";
import { FormContext, useForm } from "react-hook-form";
import {
  IProgramCatalogDetails,
  ProgramCatalogDetails,
} from "../../../../../../interfaces/setup/academics/ProgramProgramVersion/IProgramCatalogDetails";
import * as ProgramCatalogApi from "../../../../../../api/setup/academics/programCatalogApi";
import { IProgramCatalogGeneralDetails } from "../../../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/IProgramCatalogGeneralDetails";
import { EmptyGuid } from "../../../../../../utils/constants";

import * as ProgramsApi from "../../../../../../api/setup/academics/creditProgramsApi";
import { IProgramCatalogAcademicDetails } from "../../../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/IProgramCatalogAcademicDetails";
import { IProgramCatalogAttendanceDetails } from "../../../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/IProgramCatalogAttendanceDetails";
import { IProgramCatalogFinancialDetails } from "../../../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/IProgramCatalogFinancialDetails";
import { security } from "../../../../../../constants/Security/securityConstants";
import AccessManager from "../../../../../../components/security/AccessManager";
const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
    cardWrapper: {
      padding: theme.spacing(2),
    },
    saveButton: {
      width: 90,
    },
  })
);

const ProgramCatalogDetailsTab = (props: ProgramVersionTabProps) => {
  const classes = useStyles({});
  const [model, setModel] = useState<IProgramCatalogDetails>(
    new ProgramCatalogDetails()
  );
  const handleCancel = () => {
    if (!!props.programVersionId && props.programVersionId !== EmptyGuid) {
      getProgramDetails();
    } else {
      setModel(new ProgramCatalogDetails());
    }
  };
  const data = useForm<any>({ mode: "onBlur" });
  const { handleSubmit } = data;
  const getProgramDetails = () => {
    ProgramCatalogApi.GetProgramCatalogDetails(props.programVersionId).then(
      (response: any) => {
        if (response.resultStatus === 0) {
          setModel(response.result);
          props.setSnackBar((props: any) => {
            return {
              variant: "success",
              showSnackBar: false,
              messageInfo: response.resultStatusMessage,
            };
          });
        } else {
          props.setSnackBar((props: any) => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo: response.resultStatusMessage,
            };
          });
        }
      },
      (exception: any) => {
        props.setSnackBar((snackBarProps: any) => {
          return {
            variant: "error",
            showSnackBar: true,
            messageInfo: exception,
          };
        });
      }
    );
  };

  const isForCLockHourProgram = () => {
    if (!!props.programId) {
      ProgramsApi.IsClockHour(props.programId).then(
        (response: any) => {
          if (response.resultStatus === 0) {
            let generalDetailsUpdated = model.general;
            generalDetailsUpdated.isClockHour = response.result;
            let newModel = model;
            newModel.general = generalDetailsUpdated;
            setModel({ ...newModel });
            setGeneralDetails({ ...generalDetailsUpdated });
          } else {
          }
        },
        (exception: any) => {}
      );
    }
  };
  useEffect(() => {
    if (
      props.programVersionId !== undefined &&
      props.programVersionId !== EmptyGuid &&
      props.programVersionId !== ""
    ) {
      getProgramDetails();
    } else {
      setModel(new ProgramCatalogDetails());
      if (
        props.newProgramVersionName !== undefined &&
        props.newProgramVersionName !== ""
      ) {
        let generalDetailsUpdated = model.general;
        generalDetailsUpdated.name = props.newProgramVersionName;
        generalDetailsUpdated.programId = props.programId;
        generalDetailsUpdated.id = EmptyGuid;
        let newModel = model;
        newModel.general = generalDetailsUpdated;
        setModel({ ...newModel });
        setGeneralDetails({ ...generalDetailsUpdated });
        isForCLockHourProgram();
      }
    }
  }, [props.programVersionId, props.programVersionId, props.programId]);
  const [loaderState, setLoaderState] = React.useState<boolean>(false);
  const onSubmit = (d: any) => {
    if (model) {
      let modelToSend = model;
      data.triggerValidation().then((validation: any) => {
        if (validation) {
          setLoaderState(true);
          ProgramCatalogApi.upsertProgramCatalog(modelToSend).then(
            (response: any) => {
              if (response.resultStatus === 0) {
                setLoaderState(false);
                props.setRefreshData(true);
                props.refreshDetailData(response.result);
                props.setSnackBar((props: any) => {
                  return {
                    variant: "success",
                    showSnackBar: true,
                    messageInfo: response.resultStatusMessage,
                  };
                });
              } else {
                setLoaderState(false);
                props.setSnackBar((props: any) => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: response.data,
                  };
                });
              }
            },
            (exception: any) => {
              setLoaderState(false);
              props.setSnackBar((snackBarProps: any) => {
                return {
                  variant: "error",
                  showSnackBar: true,
                  messageInfo: exception,
                };
              });
            }
          );
        }
      });
    }
  };
  const setGeneralDetails = (data: IProgramCatalogGeneralDetails) => {
    setModel((state: any) => {
      return { ...state, general: { ...data } };
    });
  };
  const setAcademicDetails = (data: IProgramCatalogAcademicDetails) => {
    setModel((state: any) => {
      return { ...state, academics: { ...data } };
    });
  };
  const setAttendanceDetails = (data: IProgramCatalogAttendanceDetails) => {
    setModel((state: any) => {
      return { ...state, attendance: { ...data } };
    });
  };
  const setFinancialDetails = (data: IProgramCatalogFinancialDetails) => {
    setModel((state: any) => {
      return { ...state, financial: { ...data } };
    });
  };

  return (
    <FormContext {...data}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card
          square={true}
          className={classes.cardWrapper}
          hidden={props.hidden}
        >
          <ProgramCatalogGeneralDetails
            financial={model.financial}
            model={model.general}
            loading={loaderState}
            setModel={setGeneralDetails}
          />
          <ProgramCatalogAcademicDetails
            model={model.academics}
            loading={loaderState}
            setModel={setAcademicDetails}
          />
          <ProgramCatalogAttendanceDetails
            model={model.attendance}
            programId={props.programId}
            loading={loaderState}
            programVersionId={props.programVersionId}
            setModel={setAttendanceDetails}
          />
          <ProgramCatalogFinancialDetails
            model={model.financial}
            loading={loaderState}
            setModel={setFinancialDetails}
          />
          <Grid container direction="row" spacing={1}>
            <Grid
              item
              alignContent="flex-start"
              alignItems="flex-start"
              justify="flex-start"
            >
              <AccessManager
                allowedPermissions={[
                  security.permissions.maintenance.updatePrograms,
                  security.permissions.maintenance.deletePrograms,
                ]}
                renderNoAccess={() => (
                  <ProgressSaveButton
                    text="Save"
                    onClick={(e: any) => {}}
                    loading={loaderState}
                    disabled={true}
                    buttonClassName={classes.saveButton}
                  ></ProgressSaveButton>
                )}
              >
                <ProgressSaveButton
                  text="Save"
                  onClick={(e: any) => {}}
                  loading={loaderState}
                  disabled={false}
                  buttonClassName={classes.saveButton}
                ></ProgressSaveButton>
              </AccessManager>
            </Grid>
            <Grid
              item
              alignContent="flex-start"
              alignItems="flex-start"
              justify="flex-start"
            >
              <Button
                onClick={handleCancel}
                color="secondary"
                variant="contained"
                type="button"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Card>
      </form>
    </FormContext>
  );
};

export default ProgramCatalogDetailsTab;
