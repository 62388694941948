import * as React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import AdvantageViewer from "../../components/Common/AdvantageViewer";
import getAdvantageLink from "../../utils/getAdvantageLink";
import { useSelector } from "react-redux";
import { Campus } from "../../interfaces/systemCatalog/ICampus";
import TranscriptReport from "../reports/Transcript";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			height: "100%",
			minHeight: "100%",
			paddingTop: theme.spacing(3),
			overflowX: "hidden",
			overflowY: "auto"
		}
	})
);

const Transcripts = (props: any) => {
	const classes = useStyles({});
	const advantageLink = getAdvantageLink(props.location.pathname);
	const studentId = useSelector<any, any>(
		(state: any) => state.student.studentId
	);
	const campusId = useSelector<any, Campus>(
		(state: any) => state.student.enrollments[0].campusId
	);

	return (
		<div className={classes.root}>
			<TranscriptReport report={{
					name: "Student Transcript",
					id: 238,
					description: "Official and Unofficial Transcripts for multiple students can be printed from here using student groups, programs, Status, etc.",
					modules: [],
					tags: [
						{
							text: "STUDENT",
							color: "#009688",
							id: "6897a1b7-dadd-4368-9a25-2efad092b393"
						}
					],
					favorited: false,
					typeId: 5,
					resourceURL: "StudentTranscriptReport?resid=238&cmpid=9916379d-4aa0-4c98-80f6-5cb2e1a626a4&desc=Student Transcript",
					recentlyUsed: true,
					reportClass: "StudentTranscriptReport",
					creationMethod: "ReactComponent",
					isUnLock: true
				}} />
		</div>
	);
};

export default Transcripts;
