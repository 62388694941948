import * as React from 'react';
import { createStyles, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, makeStyles, Switch, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InputField from '../../../components/_Layout/Inputs/InputField';
import AdHocReportCategoryAutoComplete from '../../../components/AutoComplete/AdHocReportCategoryAutoComplete';
import MultiCampusAutoComplete from '../../../components/AutoComplete/MultiCampusAutoComplete';
import CampusForCurrentUserAutoComplete from '../../../components/AutoComplete/CampusForCurrentUserAutoComplete';
import UserNameMultiCampusAutoComplete from '../../../components/AutoComplete/UserNameMultiCampusAutoComplete';
import { useForm } from 'react-hook-form';
import ProgressSaveButton from '../../../components/_Layout/Buttons/ProgressSaveButton';
import { useActions } from '../../../store/utils';
import { actions as adhocDetailsActions, selectors as adHocDetailsSelectors } from '../../../store/reports/adhocReportDetails';
import { useSelector } from 'react-redux';
import { saveAdHocReport } from '../../../api/reports/adHoc/adhocReports';

const useStyles = makeStyles((theme: any) =>
    createStyles({
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        dialogue: {
            minWidth: 600
        }
    }));

interface ISaveAdHocReportPopUp {
    open: boolean;
    close: () => void;
}

const SaveAdHocReportPopUp = (props: ISaveAdHocReportPopUp) => {
    const classes = useStyles({});
    const data = useForm<{
        reportName?: string;
        categoryId?: string;
        reportDescription?: string;
        isRestricted?: boolean;
        accessibleByUserIds?: Array<string>;
        reportCampusIds?: Array<string>;

    }>({
        mode: 'onBlur'
    });



    const actions = useActions({
        mofidyDetails: adhocDetailsActions.modifyReportDetails,
        saveReport: adhocDetailsActions.saveAdHocReport
    }, []);

    const reportDetails: {
        reportName?: string;
        categoryId?: string;
        reportDescription?: string;
        isRestricted?: boolean;
        accessibleByUserIds?: Array<string>;
        reportCampusIds?: Array<string>;

    } = useSelector(adHocDetailsSelectors.generatDetails);

    const detailsObject = useSelector(adHocDetailsSelectors.detailsObject);
    const saving = useSelector(adHocDetailsSelectors.saving);
    const updateField = (field: keyof {
        reportName?: string;
        categoryId?: string;
        reportDescription?: string;
        isRestricted?: boolean;
        accessibleByUserIds?: Array<string>;
        reportCampusIds?: Array<string>;

    }, value: any) => {
        data.setValue(field, value);
        actions.mofidyDetails(field, value);
        if ((field === 'isRestricted' && !value)) {
            data.setValue('accessibleByUserIds', undefined);
            actions.mofidyDetails('accessibleByUserIds', undefined);
            data.triggerValidation('undefined');
        }
        data.triggerValidation(field);
    }

    return (<Dialog open={props.open} onClose={props.close} maxWidth="md" classes={{ paper: classes.dialogue }}>
        <DialogTitle id="customized-dialog-title">
            <Typography variant="h6">Save and Schedule Report</Typography>

            <IconButton aria-label="close" className={classes.closeButton} onClick={props.close}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <form onSubmit={data.handleSubmit(() => undefined)}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}>
                        <InputField label="Report Title"
                            error={!!data.errors.reportName}
                            helperText={data.errors?.reportName?.message ?? undefined}
                            name="reportName"
                            id="reportName"
                            inputRef={data.register({ required: "Report Title is required." })}
                            onBlur={(e) => updateField('reportName', e.target.value)}
                            defaultValue={reportDetails.reportName} />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <InputField
                            label="Report Description"
                            name="reportDescription"
                            id="reportDescription"
                            error={!!data.errors.reportDescription}
                            helperText={data.errors?.reportDescription?.message ?? undefined}
                            inputRef={data.register({})}
                            onBlur={(e) => updateField('reportDescription', e.target.value)}
                            defaultValue={reportDetails.reportDescription} />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <AdHocReportCategoryAutoComplete
                            name="categoryId"
                            id="categoryId"
                            label="Report Category"
                            inputRef={data.register({ required: 'Report Category is required.' })}
                            filterHandle={(v: any) => updateField('categoryId', v && v.value || undefined)}
                            error={!!data.errors.categoryId}
                            helperText={data.errors?.categoryId?.message ?? undefined}
                            valueFilter={
                                reportDetails.categoryId
                                    ? {
                                        key: "value",
                                        values: [reportDetails.categoryId],
                                    }
                                    :
                                    undefined
                            }
                        />

                    </Grid>
                    <Grid item xs={12} >
                        <CampusForCurrentUserAutoComplete
                            multiple
                            name="reportCampusIds"
                            id="reportCampusIds"
                            label="Campuses"
                            params={{
                                fullWidth: true
                            }}
                            includeAllValue={true}
						    showIncludeAllValue={true}
                            filterHandle={(v: any) => updateField('reportCampusIds', v && Array.isArray(v) && v.length > 0 ? v.map(m => m.value) : undefined)}
                            valueFilter={
                                reportDetails.reportCampusIds
                                    ? {
                                        key: "value",
                                        values: reportDetails.reportCampusIds,
                                    }
                                    :
                                    undefined
                            }
                            hidePlaceholder
                            chipSize="small"
                            error={!!data.errors.reportCampusIds}
                            helperText={data.errors?.reportCampusIds?.message ?? undefined}
                            inputRef={data.register({ validate: () => reportDetails.reportCampusIds && reportDetails.reportCampusIds.length > 0 ? true : 'You must specify campuses for the report.' })}
                        />
                    </Grid>
                    <Grid item xs={6} >
                        <FormControlLabel
                            control={
                                <Switch
                                    name="checkedB"
                                    color="primary"
                                    checked={reportDetails.isRestricted || false}
                                    onChange={(e) => updateField('isRestricted', e.target.checked)}
                                />
                            }
                            label="Restricted"
                        />
                    </Grid>
                    <Grid item xs={6} >
                        <UserNameMultiCampusAutoComplete
                            disabled={!reportDetails.isRestricted}
                            multiple
                            name="accessibleByUserIds"
                            id="accessibleByUserIds"
                            label="User(s) Allowed"
                            filterHandle={(v: any) => updateField('accessibleByUserIds', (v && Array.isArray(v) && v.length > 0) ? v.map(m => m.text) : undefined)}
                            valueFilter={
                                reportDetails.accessibleByUserIds
                                    ? {
                                        key: "text",
                                        values: reportDetails.accessibleByUserIds,
                                    }
                                    :
                                    undefined
                            }
                            maxTags={1}
                            hidePlaceholder
                            chipSize="small"
                            requestParameter={{
                                campusId: (reportDetails.reportCampusIds && reportDetails.reportCampusIds.length > 0 && reportDetails.reportCampusIds || [])
                            }}
                            error={!!data.errors.accessibleByUserIds}
                            helperText={data.errors?.accessibleByUserIds?.message ?? undefined}
                            inputRef={data.register({ validate: () => !reportDetails.isRestricted || (reportDetails.accessibleByUserIds && reportDetails.accessibleByUserIds.length > 0) ? true : 'You must specify users for which report is accessible.' })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ProgressSaveButton
                            text="Save"
                            onClick={data.handleSubmit(() => actions.saveReport(detailsObject))}
                            loading={!!(saving)}
                        ></ProgressSaveButton>
                    </Grid>
                </Grid>
            </form>
        </DialogContent>
    </Dialog>);
}

export default SaveAdHocReportPopUp;