import * as React from 'react';
import { useEffect, useRef } from 'react';
import { apiAutoCompleteConfig } from '../../constants/apiAutoComplete.config';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles';
import ApiAutoComplete from '../../components/_Layout/ApiAutoComplete/ApiAutoComplete';
import { useSelector, useDispatch } from 'react-redux';
import * as actionTypes from '../../constants/actions.constants';
import { FilterParams } from '../../interfaces/common/FilterParams';
import { ApiAutoCompleteConfigItem } from '../../interfaces/ApiAutoCompleteConfigItem';


const config: ApiAutoCompleteConfigItem = {
	acId: 'programsAutoComplete',
	cacheTime: 5,
	route: '/SystemCatalog/Program/GetProgramsAutoCompleteAsync',
	requestType: 'GET',
	body: null,
	hasUrlParams: true,
	useCache: false,
	storeInCache: true,
	isCampusSpecific: true,
	isUserSpecific: false,
	loadOnRender: true,
	defaultSelectedFirstItem: false,
	optionsMappedFx: (data: Array<any>) => {
		return data;
	},
};

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			'& label.Mui-focused': {
				color: theme.palette.primary.white,
			},
		},
	})
)(TextField);

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {
			width: '100%',
		},
	})
);

const ProgramAutoComplete = (
	props: FilterParams & { campusIds: string[] } & {
		activeOnly?: boolean
	}
) => {
	const classes = useStyles({});
	const inputRef: any = useRef(null);

	return (
		<div className={clsx(classes.divWidth)}>
			<ApiAutoComplete
				disabled={props.disabled}
				config={config}
				reqParams={{
					campusIds:props.campusIds,
					activeOnly: (props.activeOnly == false || props.activeOnly == null) ? null : props.activeOnly,
 
				}}
				includeAllValue={props.includeAllValue ? props.includeAllValue : false}
				showIncludeAllValue={props.showIncludeAllValue ? props.showIncludeAllValue : false}
				classes={{
					option: 'text-black',
				}}
				label={props.label ? props.label : 'Program'}
				onChange={(e: any) => {
					if (props.filterHandle) {
						props.filterHandle(e);
					}
				}}
				multiple={props.multiple ? props.multiple : false}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
				inputRef={props.inputRef ? props.inputRef : inputRef}
				error={props.error}
				helperText={props.helperText}
				inputName={props.name}
				maxTags={props.maxTags}
			></ApiAutoComplete>
		</div>
	);
};
export default ProgramAutoComplete;
