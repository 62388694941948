import * as React from "react";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import SearchBarFilter from "../../components/_Layout/Search/SearchBarFilter";
import useRouter from "../../utils/useRouter";
import { Enrollment } from "../../interfaces/student/academics/Enrollment";


const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.background.paper,

			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2),
			},
		},
		"& .makeStyles-content": {
			backgroundColor: theme.palette.background.paper,

		},

		filter: {
			width: "10%",
			padding: theme.spacing(1),
			display: "inline-block",
		},
		searchBarContainer: {
			backgroundColor: theme.palette.site.secondary,
			borderRadius: 20,
			paddingLeft: "10px !important",
			fontSize: 13,
			width: "250px",
			fontWeight: theme.typography.fontWeightBold,
			color: theme.palette.black,
			paddingTop: "2.5px !important",
			paddingBottom: "2.5px !important",
			marginLeft: theme.spacing(3),
		},
		studentSearchContainer: {
			borderBottom: "1px solid #000",
			color: theme.palette.black,
			paddingTop: "2.5px !important",
			paddingBottom: "2.5px !important",
		}
	})
);

interface IModel {
  studentNameBadgeId?: string;
  studentName?: string;
}

interface IStudentNameBadgeIDFilterProps {
  model: IModel;
  show?: boolean;
  setModel: (values: IModel) => void;
  report?: boolean;
  placeHolder?: string;
}

const StudentNameBadgeIDFilter = (props: IStudentNameBadgeIDFilterProps) => {
	const classes = useStyles({});
	const { model, setModel } = props;

	if (props.show) {
		return (
			<div className={props.report ? classes.studentSearchContainer : classes.searchBarContainer}>
				<SearchBarFilter report={props.report} onSelect={(data?: any, studentName?: string) => data && data.stuEnrollId && data.stuEnrollId !== model.studentNameBadgeId && studentName && setModel({ studentNameBadgeId: data.stuEnrollId, studentName })} placeholder={(model && model.studentName) || props.placeHolder} ></SearchBarFilter>
			</div>

		)

	} else {
		return <div className={classes.filter}></div>;
	}
};

export default StudentNameBadgeIDFilter;
