import TitleIVNotices from '../widgets/titleIVNotices';
import GraduationWidget from '../widgets/GraduationWidget';
import LOAWidget from '../widgets/LeaveOfAbsenceWidget';
import TerminationWidget from '../widgets/terminationWidget';
import PendingRefundsWidget from '../widgets/PendingRefundsWidget';
import ReportsWidget from '../widgets/ReportsWidget';
import PaymentPlanWidget from '../widgets/PaymentPlanWidget';
import CampusAttendanceWidget from '../widgets/CampusAttendanceWidget';
import MTFWidget from '../widgets/MTFWidget';

export default class DashboardConfig {
  static widgets: any = {
  	T4SAPNotice: TitleIVNotices,
  	GraduationWidget: GraduationWidget,
  	PendingRefundsWidget: PendingRefundsWidget,
  	LOAWidget: LOAWidget,
  	TerminationWidget: TerminationWidget,
  	ReportsWidget: ReportsWidget,
  	PaymentPlanWidget: PaymentPlanWidget,
  	CampusAttendanceWidget: CampusAttendanceWidget,
	MTFWidget:MTFWidget,
  };
}
