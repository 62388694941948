import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
  FormControlLabel,
  Switch,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import * as ipedsApi from "../../../api/reports/ipeds/IPEDSFall";
import { ReportOutput } from "../../../enums/ReportOutput";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import ProgramAutoComplete from "../../../components/AutoComplete/ProgramAutoComplete";
import { useSelector } from "react-redux";
import MultiCampusAutoComplete from "../../../components/AutoComplete/MultiCampusAutoComplete";
import { IPEDSFall12MonthPartADetailReportParam } from "../../../interfaces/reports/ipeds/IPEDSFall12MonthPartADetailReportParam";
import { cloneDeep } from "lodash";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      minHeight: "100%",
      height: "100%",
      maxWidth: "1050px",
      padding: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    paperRoot: {
      padding: theme.spacing(3, 2),
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
    },
    searchActions: {
      marginTop: theme.spacing(3),
      textAlign: "left",
    },
    startDateField: {
      width: "100%",
    },
    cardTitle: {
      fontSize: 17,
      color: theme.palette.primary.headerText,
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
    switch: {
      display: "flex",
      alignItems: "flex-end",
      padding: theme.spacing(0, 0.5),
      height: theme.spacing(8.6),
    },
    fieldSet: {
      marginTop: theme.spacing(2),
    },
  })
);

interface IAwardYearDataSource {
  startDate: string;
  endDate: string;
  reportYear: number;
  reportYearDescription: string;
}

const IPEDSFall12MonthPartADetail = (props: any) => {
  const { report } = props;
  let reportOverview = { report: report } as IReportOverview;
  const classes = useStyles({});
  const [preview, setPreview] = React.useState<any>();
  const [previewLoader, setPreviewLoader] = React.useState<boolean>();
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const currentYear = new Date().getFullYear();
  const awardYearDataSource = React.useMemo(() => {
    let toReturn: IAwardYearDataSource[] = [];
    let startYear: number = 2015;
    let endYear: number = new Date().getFullYear() + 1;

    for (let i = endYear; i > startYear; i--) {
      toReturn.push({
        endDate: `06/30/${i}`,
        reportYear: i,
        reportYearDescription: `${i - 1}-${i}`,
        startDate: `07/01/${i - 1}`,
      });
    }

    return toReturn;
  }, []);

  const data = useForm<any>({ mode: "onBlur" });
  const initialModel: IPEDSFall12MonthPartADetailReportParam = {
    campusId: userSelectedCampus,
    startDate: awardYearDataSource.find((m) => m.reportYear === currentYear)
      ?.startDate,
    endDate: awardYearDataSource.find((m) => m.reportYear === currentYear)
      ?.endDate,
    prgVerIds: [],
    sortBy: "StudentNumber",
    selectedReportYear: currentYear + 1,
  };
  const [model, setModel] = React.useState<IPEDSFall12MonthPartADetailReportParam>(initialModel);

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    if (fieldId == "campusId") {
      (updatedModel as any)["programId"] = [];
      (updatedModel as any)["leadGroupIds"] = [];
    }
    setModel({ ...updatedModel });
  };

  const { handleSubmit } = data;

  const onSubmit = async (isPreview: any) => {
    let params = (await getReportParameters()) as IPEDSFall12MonthPartADetailReportParam;
    const validation = await data.triggerValidation();
    if (validation) {
      await ipedsApi.generateIPEDSFall12MonthPartADetailReport(params);
    }
  };

  const awardYearAutoComplete = {
    options: awardYearDataSource,
    getOptionLabel: (option: IAwardYearDataSource) =>
      option.reportYearDescription,
  };

  const awardYearOnChange = (e: IAwardYearDataSource) => {
    if (!!e && !!e.reportYear && e.reportYear > 0) {
      let updatedModel = { ...model };
      updatedModel.startDate = awardYearDataSource.find((m) => m.reportYear === e.reportYear - 1)?.startDate;
      updatedModel.endDate = awardYearDataSource.find((m) => m.reportYear === e.reportYear - 1)?.endDate;
      updatedModel.selectedReportYear = e.reportYear;
      setModel({ ...updatedModel });
    }
  };

  const filterHandler = (data: any) => {
    if (data) {
      setModel(data);
    } else {
      setModel(initialModel);
    }
  };

  const handleSortByChange = (value: "StudentNumber" | "LastName") => {
    let copy = cloneDeep(model);
    copy.sortBy = value;
    setModel(copy);
  };

  const setProgramVersion = (PrgVersions: any) => {
    console.log(PrgVersions);
    let newProgramVersion: string[] = [];
    if (PrgVersions !== null) {
      if (Array.isArray(PrgVersions)) {
        newProgramVersion = PrgVersions.map((gr: any) => gr.value);
      } else {
        newProgramVersion.push(PrgVersions.value);
      }
    }
    return newProgramVersion;
  };

  const getReportParameters = async () => {
    const success = await data.triggerValidation();
    if (model && success) {
      let startDate = model?.startDate ? model.startDate : undefined;
      let endDate = model?.endDate ? model.endDate : undefined;
      let params: IPEDSFall12MonthPartADetailReportParam = {
        campusId: model?.campusId ?? userSelectedCampus,
        startDate: startDate,
        endDate: endDate,
        prgVerIds: model?.prgVerIds ?? [],
        selectedReportYear: model.selectedReportYear,
        sortBy: model.sortBy,
      };
      return params;
    }
    return {} as IPEDSFall12MonthPartADetailReportParam;
  };

  const getSelectedItem = (
    items: Array<IAwardYearDataSource>,
    value: number
  ) => {
    const item = items.find((opt) => {
      if (opt.reportYear == value) return opt;
    });
    return item || ({} as IAwardYearDataSource);
  };

  let parameters = (
    <div className={classes.root}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              <MultiCampusAutoComplete
                label="Campus *"
                multiple={false}
                id="campusId"
                name="campusId"
                valueFilter={
                  model.campusId
                    ? {
                        key: "value",
                        values: [model.campusId],
                      }
                    : undefined
                }
                filterHandle={(v: any) => {
                  handleFieldOnChange("campusId", v ? v.value : null);
                }}
                error={!!data.errors.campusId}
                inputRef={data.register({ required: true })}
                helperText={
                  data.errors.campusId ? "Campus is required." : undefined
                }
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
                <ProgramAutoComplete
                    multiple
                    name="prgVerIds"
                    id="prgVerIds"
                    label="Program"
                    params={{
                        fullWidth: true
                    }}
                    campusIds={model.campusId ? [model.campusId] : []}
                    includeAllValue={true}
                    showIncludeAllValue={true}
                    filterHandle={(v: any) => handleFieldOnChange('prgVerIds', v && Array.isArray(v) && v.length > 0 ? v.map(m => m.value) : undefined, (vals:any[]) =>{
                        if(!!vals && vals.length > 0){
                            let lastValue = vals[vals.length - 1];

                            if(lastValue === 'All'){
                                return ['All'];
                            }else{
                                return vals.filter(a => a !== 'All');
                            }
                        }else{
                            return vals;
                        }
                    })}
                    valueFilter={
                        model.prgVerIds
                            ? {
                                key: "value",
                                values: model.prgVerIds,
                            }
                            :
                            undefined
                    }
                    chipSize="small"
                    error={!!data.errors.programId}
                    helperText={data.errors?.programId?.message ?? undefined}
                    inputRef={data.register()}
                    maxTags={3}
                />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <label> </label>
              <Typography>{`Report Date: ${
                awardYearDataSource.findIndex(
                  (m) => m.reportYear === model.selectedReportYear-1
                ) >= 0
                  ? `${
                      awardYearDataSource.find(
                        (m) => m.reportYear === model.selectedReportYear-1
                      )?.startDate
                    } thru ${
                      awardYearDataSource.find(
                        (m) => m.reportYear === model.selectedReportYear-1
                      )?.endDate
                    }`
                  : ""
              }`}</Typography>
            </Grid>

            

            <Grid item md={6} sm={6} xs={12}>
              <Autocomplete
                {...awardYearAutoComplete}
                autoComplete
                includeInputInList
                value={
                  !!model.selectedReportYear
                    ? awardYearDataSource.find(
                        (m) => m.reportYear == model.selectedReportYear
                      )
                    : null
                }
                onChange={(e: any, v: any) => {
                  awardYearOnChange(v);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`Reporting Year *`}
                    name="selectedReportYear"
                    error={!!data.errors.selectedReportYear}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.exportType
                        ? "Report Year is required."
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <FormControl component="fieldset" className={classes.fieldSet}>
                <FormLabel component="legend">Sort By</FormLabel>
                <RadioGroup
                  row
                  aria-label="sortBy"
                  name="sortBy"
                  value={model.sortBy}
                  onChange={(e: any) => {
                    handleSortByChange(e.target.value);
                  }}
                >
                  <FormControlLabel
                    labelPlacement="end"
                    value="StudentNumber"
                    control={<Radio color="default" />}
                    label="Student Number"
                  />
                  <FormControlLabel
                    labelPlacement="end"
                    value="LastName"
                    control={<Radio color="default" />}
                    label="Last Name"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </FormContext>
    </div>
  );
  reportOverview.parameters = parameters;

  return (
    <ReportOverview
      reportOverview={reportOverview}
      filterHandler={filterHandler}
      getReportParameters={getReportParameters}
      exportHandler={onSubmit}
      preview={preview}
      previewLoader={previewLoader}
      hidePreview={true}
    />
  );
};
export default IPEDSFall12MonthPartADetail;
