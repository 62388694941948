import React from 'react';
import {
    makeStyles,
    createStyles,
    Grid,
    IconButton,
    Button,
    Card,
    CardContent,
    CircularProgress
} from '@material-ui/core';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import ICourseGradeBook from '../../../../../../../interfaces/setup/academics/ProgramDefinition/ICourseGradeBook';
import ICourseGradeBookDetails from '../../../../../../../interfaces/setup/academics/ProgramDefinition/ICourseGradeBookDetails'
import InputField from '../../../../../../../components/_Layout/Inputs/InputField';
import CustomCardTitle from '../../../../../../../interfaces/common/card/CustomCardTitle';
import { FieldError, useForm, FormContext } from 'react-hook-form';
import { InputType } from '../../../../../../../constants/uiConstants/inputConstants';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import GradeBookComponents from './GradeBookComponents';
import { EmptyGuid } from '../../../../../../../utils/constants';
import IGradeBookComponent from '../../../../../../../interfaces/setup/academics/ProgramDefinition/IGradeBookComponent';
import { getCourseGradeBookDetails, upsertCourseGradeBook, CopyCourseGradeBook } from '../../../../../../../api/setup/academics/courses';
import { CustomSnackBarProps } from '../../../../../../../interfaces/common/CustomSnackBarProps';
import ProgressSaveButton from '../../../../../../../components/_Layout/Buttons/ProgressSaveButton';
import AddNewComponent from './AddNewComponent';
import { Guid } from '../../../../../../../utils/guid';

const useStyles = makeStyles((theme: any) =>
    createStyles({
        cardTitle: {
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            backgroundColor: theme.palette.site.secondary,
            padding: theme.spacing(0)
        },
        headerText: {
            float: 'left',
            padding: theme.spacing(1.5),
        },
        cardContent: {
            padding: theme.spacing(1, 3),
            height: '100%',
        },
        saveButton: {
            width: 90,
        },
        table: {
            width: '100%',
        },
        iconColor: {
            color: theme.palette.black
        },
        searchField: {
            '&::placeholder': {
                color: theme.palette.black
            }
        },
        selectionCheckBox: {
            padding: theme.spacing(0)
        },
        preLoaderCell: {
            textAlign: 'center',
            padding: theme.spacing(1)
        },
        dialogCloseButton: {
            float: "right",
        },
        courseSelectionCell: {
            color: theme.palette.paperSummarySchedulerTitle,
            cursor: 'pointer',
            fontWeight: 500
        },
        alignCenter: {
            textAlign: "center",
        },
        dateInput: {
            marginRight: theme.spacing(1),
            width: '100%'
        }
    })
);
type GradeBookDetailsProps = {
    courseId?: string;
    campusId?: string;
    repeatedExamPolicy?: string;
    model: ICourseGradeBook;
    showSnackBar: (param: CustomSnackBarProps) => void;
    refreshList: () => void;
    setConfirmationDialog: (param: { onOk?: () => (void | Promise<void>); open: boolean; onCancel?: () => (void | Promise<void>); }) => void;
};

const GradeBookDetails = (
    props: Readonly<GradeBookDetailsProps>
) => {
    const classes = useStyles({});
    const [model, setModel] = React.useState<ICourseGradeBookDetails>({ gradeBookDetails: [], gradeBookId: EmptyGuid });
    const [isLoading, setIsloading] = React.useState<boolean>(false);
    const data = useForm<ICourseGradeBook>({ mode: "onBlur" });
    const [addNewComponent, setAddNewComponentOpen] = React.useState<boolean>(false);

    const { handleSubmit } = data;

    data.register('effectiveStartDate', { validate: () => !model.effectiveStartDate ? 'Effective start date is required.' : (((model?.effectiveStartDate).toString() !== 'Invalid Date') ? true : 'Invalid Date') });

    const handleFieldChange = (fieldId: keyof ICourseGradeBookDetails, value: any) => {
        if (model) {
            let updatedModel = model;
            (updatedModel as any)[fieldId] = value;
            setModel({ ...updatedModel });
            if (fieldId !== 'gradeBookDetails') {
                data.setValue(fieldId, value);
                data.triggerValidation(fieldId);
            }
        }
    };

    const addGradeComponent = (component: IGradeBookComponent) => {
        model.gradeBookDetails.push(component);
        model.gradeBookDetails = [...model.gradeBookDetails];
        handleFieldChange("gradeBookDetails", model.gradeBookDetails);
    }

    const updateGradeComponent = (component: IGradeBookComponent, index: number) => {
        model.gradeBookDetails[index] = component;
        model.gradeBookDetails = [...model.gradeBookDetails];
        handleFieldChange("gradeBookDetails", model.gradeBookDetails);
    }

    const deleteGradeComponent = (index: number) => {
        model.gradeBookDetails.splice(index, 1);
        model.gradeBookDetails = [...model.gradeBookDetails];
        handleFieldChange("gradeBookDetails", model.gradeBookDetails);
    }

    const saveDetails = async (confirm?: boolean) => {
        if (!isLoading) {
            setIsloading(true);
            if (!confirm) {
                if (!confirm && props.courseId !== EmptyGuid) {
                    props.setConfirmationDialog({ open: true, onOk: () => saveDetails(true), onCancel: () => setIsloading(false) })
                    return;
                }
            }
            try {
                const newDetails = await upsertCourseGradeBook(model, props.courseId ?? EmptyGuid);;
                props.showSnackBar({
                    variant: "success",
                    showSnackBar: true,
                    messageInfo: newDetails.message,
                });
                setModel(newDetails.details);
                data.reset(newDetails.details);
                props.refreshList();
            }
            catch (err) {
                props.showSnackBar({
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: (err as Error).message,
                });
            }
            setIsloading(false);
        }
    }

    const copyGradeBook = async () => {
        if (!isLoading) {
            setIsloading(true);
            try {
                const newDetails = await CopyCourseGradeBook(model.gradeBookId ?? EmptyGuid, props.courseId ?? EmptyGuid);
                setModel(newDetails.details);
                data.reset(newDetails.details);
                props.showSnackBar({
                    variant: "success",
                    showSnackBar: true,
                    messageInfo: newDetails.message,
                });
                props.refreshList();
            }
            catch (err) {
                props.showSnackBar({
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: (err as Error).message,
                });
            }
            setIsloading(false);
        }
    }

    const loadDetails = async () => {
        if (!isLoading && props.model && props.model.gradeBookId) {
            setIsloading(true);
            if (props.model.gradeBookId !== EmptyGuid) {
                try {
                    const newDetails = await getCourseGradeBookDetails(props.model.gradeBookId);
                    data.reset(newDetails);
                    setModel(newDetails);
                }
                catch (err) {
                    props.showSnackBar({
                        variant: "error",
                        showSnackBar: true,
                        messageInfo: (err as Error).message,
                    });
                }
            }
            else {
                setModel({ ...props.model, gradeBookDetails: [] });
                data.reset(model);
            }
            setIsloading(false);
        }
    }

    React.useEffect(() => {
        loadDetails();
    }, [props.model]);

    return (
        <Card square={true} elevation={2} >
            <CustomCardTitle title='Grade Book Details'></CustomCardTitle>
            <CardContent>
                <Grid container direction="column" spacing={2} wrap="nowrap">
                    {(!isLoading && (<Grid
                        item
                        alignContent='flex-start'
                        alignItems='flex-start'
                        justify='flex-start'
                    >
                        <FormContext {...data}>
                            <form onSubmit={handleSubmit(() => saveDetails(false))}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={10} sm={10} md={8}>
                                        <InputField
                                            type={InputType.TEXT}
                                            key="gradeBookTitle"
                                            id="gradeBookTitle"
                                            label="Grade book *"
                                            name="gradeBookTitle"
                                            defaultValue={model?.gradeBookTitle ?? null}
                                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                handleFieldChange("gradeBookTitle", e.target.value);
                                            }}
                                            error={!!data.errors.gradeBookTitle}
                                            inputRef={data.register({
                                                required: 'Grade book is required.',
                                            })}
                                            helperText={
                                                data.errors.gradeBookTitle
                                                    ? (data.errors.gradeBookTitle as FieldError).message
                                                    : undefined
                                            }
                                        />
                                    </Grid>
                                    {model.gradeBookId && model.gradeBookId != EmptyGuid && <Grid item xs={2}>
                                        <IconButton onClick={copyGradeBook}>
                                            <CopyIcon className={classes.iconColor} />
                                        </IconButton>
                                    </Grid>}
                                </Grid>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={10} sm={10} md={8}>
                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                        >
                                            <KeyboardDatePicker
                                                className={classes.dateInput}
                                                format="MM/dd/yyyy"
                                                label="Effective start date *"
                                                value={model?.effectiveStartDate ?? null}
                                                onChange={(date, _value) =>
                                                    handleFieldChange(
                                                        "effectiveStartDate",
                                                        date
                                                    )
                                                }
                                                error={!!data.errors.effectiveStartDate}
                                                helperText={
                                                    data.errors.effectiveStartDate
                                                        ? (data.errors.effectiveStartDate as FieldError).message
                                                        : undefined
                                                }
                                                InputProps={{
                                                    classes: {
                                                        root: classes.dateInput
                                                    }
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                            </form>
                        </FormContext>
                        <Grid container direction="row" spacing={2} wrap="nowrap">
                            <Grid item xs={12}>
                                <GradeBookComponents
                                    addGradeComponent={addGradeComponent}
                                    updateGradeComponent={updateGradeComponent}
                                    deleteGradeComponent={deleteGradeComponent}
                                    campusId={props.campusId}
                                    components={model?.gradeBookDetails ?? []}
                                    repeatedExamPolicy={props.repeatedExamPolicy} />
                            </Grid>
                        </Grid>
                    </Grid>))
                        || <div className={classes.alignCenter}><CircularProgress /></div>}
                    <Grid
                        item
                        alignContent='flex-start'
                        alignItems='flex-start'
                        justify='flex-start'
                    >
                        <Grid container direction='row' spacing={1} wrap="nowrap">
                            <Grid
                                item
                                alignContent='flex-start'
                                alignItems='flex-start'
                                justify='flex-start'
                            >
                                <ProgressSaveButton
                                    text="Save"
                                    onClick={handleSubmit(() => saveDetails(false))}
                                    loading={isLoading}
                                    disabled={false}
                                    buttonClassName={classes.saveButton}
                                    size="small"
                                ></ProgressSaveButton>
                            </Grid>
                            <Grid
                                item
                                alignContent='flex-start'
                                alignItems='flex-start'
                                justify='flex-start'
                            >
                                <Button
                                    disabled={isLoading}
                                    onClick={loadDetails}
                                    size='small'
                                    color='primary'
                                    variant='contained'
                                    type='button'
                                >Cancel</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        alignContent='flex-start'
                        alignItems='flex-start'
                        justify='flex-start'
                    >
                        <Grid container direction='row' spacing={1} wrap="nowrap">
                            <Grid
                                item
                                alignContent='flex-start'
                                alignItems='flex-start'
                                justify='flex-start'
                            >
                                <Button variant="text"
                                    color='primary'
                                    type="button"
                                    onClick={() => setAddNewComponentOpen(true)}>
                                    <LibraryAddOutlinedIcon />{' '}New Component
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        alignContent='flex-start'
                        alignItems='flex-start'
                        justify='flex-start'
                    >
                        <AddNewComponent open={addNewComponent} close={() => setAddNewComponentOpen(false)} showSnackBar={props.showSnackBar} campusId={props.campusId} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default GradeBookDetails;
