import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Typography, Tab, Tabs, Box, Divider } from "@material-ui/core";
import * as PropTypes from "prop-types";
import ByStudent from "./ByStudent";
import ByClass from "./ByClass";
import { Block } from "@material-ui/icons";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: "0px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      minHeight: "100%",
      height: "100%",
      width: "100%",
      margin: "auto",
    },
    paperRoot: {
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
    },
    inner: {
      height: "100%",
      width: "100%",
      margin: "0 auto",
      minHeight: "100%",
      maxWidth: 1150,
    },
    fixed: {
      position: "sticky",
      top: "0",
      width: "100%",
      marginBottom: "0.5em",
    },
    divider: {
      backgroundColor: theme.palette.site.primary,
      marginBottom: theme.spacing(1),
    },
    tabController: {
      backgroundColor: "transparent",
      textTransform: "uppercase",      
    },
    tabIndicator: {
      backgroundColor: theme.palette.primary.contrastNavTabColor,
    },
    tabSelected: { fontWeight: "bold" },
  })
);

function ClassRegistration() {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);
  const handleChange = (_index: any, newValue: any) => {
    setValue(newValue);
  };
  React.useEffect(() => {}, [value]);

  return (
    <div className={classes.root}>
      <div className={classes.paperRoot}>
        <div style={{ width: "auto", margin: "auto" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            scrollButtons="auto"
            variant="fullWidth"
            classes={{
              indicator: classes.tabIndicator,
            }}
            className={classes.tabController}
          >
            <Tab
              classes={{ selected: classes.tabSelected }}
              label={"BY SINGLE STUDENT"}
              {...a11yProps(0)}
            />
            <Tab
              classes={{ selected: classes.tabSelected }}
              label={"BY CLASS"}
              {...a11yProps(1)}
            />
          </Tabs>
          <Divider className={classes.divider} />
          <TabPanel value={value} index={0}>
            <ByStudent />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ByClass />
          </TabPanel>
        </div>
      </div>
    </div>
  );
}
export default ClassRegistration;
