import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { FormContext, useForm } from "react-hook-form";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ReportOutput } from "../../../enums/ReportOutput";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";
import { ITimeClockPunches } from "../../../interfaces/reports/attendance/ITimeClockPunches";
import * as timeClockPunchesApi from "../../../api/reports/attendance/timeClockPunchesApi";
import { useSelector } from "react-redux";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import InstructorsAutoComplete from '../../../components/AutoComplete/InstructorAutoComplete';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      minHeight: "100%",
      height: "100%",
      padding: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    paperRoot: {
      padding: theme.spacing(3, 2),
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
    },
    searchActions: {
      marginTop: theme.spacing(3),
      textAlign: "left",
    },
    width100: {
      width: "100%",
    },
    cardTitle: {
      fontSize: 17,
      color: theme.palette.primary.headerText,
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
    switch: {
      display: "flex",
      alignItems: "flex-end",
      padding: "0px 4px !important",
    },
  })
);

const TimeClockPunches = (props: any) => {
  const { report } = props;
  const [preview, setPreview] = React.useState<any>();
  const [previewLoader, setPreviewLoader] = React.useState<boolean>();
  let reportOverview = { report: report } as IReportOverview;

  const classes = useStyles({});
  const defaultFilters = {
    campusId: "",
    startDate: undefined as Date | undefined,
    exportType: 1 as ReportOutput,
  };
  const [model, setModel] = React.useState<any>(defaultFilters);
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );
  const exportTypes: Array<DropDownListItem> = [
    { text: "PDF", id: "1" },
    { text: "Excel", id: "2" },
    { text: "Word", id: "3" },
  ];

  const exportTypeAutoComplete = {
    options: exportTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    setModel({ ...updatedModel });
  };
  const data = useForm<any>({ mode: "onBlur" });
  const { handleSubmit } = data;
  const onSubmit = async (isPreview: any) => {
    let params = (await getReportParameters()) as ITimeClockPunches;
    const validation = await data.triggerValidation();
    if (validation) {
      if (isPreview) {
        setPreviewLoader(true);
        timeClockPunchesApi
          .generateReport(params, isPreview)
          .then((res: any) => {
            if (res) {
              res.arrayBuffer().then((buffer: any) => {
                setPreview(buffer);
                setPreviewLoader(false);
              });
            } else setPreviewLoader(false);
          });
      } else await timeClockPunchesApi.generateReport(params);
    }
  };

  const filterHandler = (data: any) => {
    if (data) {
      setModel(data);
    } else {
      setModel(defaultFilters);
    }
  };
  const getReportParameters = async () => {
    const success = await data.triggerValidation();
    if (model && success) {
      let params: ITimeClockPunches = {
        campusId: userSelectedCampus,
        startDate: model?.startDate,
        instructorId: model?.instructorId,
        exportType: model.exportType,
      };
      return params;
    }
    return {} as ITimeClockPunches;
  };

  let parameters = (
    <div className={[classes.root, classes.width100].join(" ")}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  views={["year", "month", "date"]}
                  variant="inline"
                  id="date"
                  name="date"
                  error={!!data.errors.date}
                  innerRef={(ref: any) => {
                    data.register("startDate", {
                      required: true,
                    });
                    data.setValue("startDate", model.startDate);
                  }}
                  helperText={
                    data.errors.date ? "Week is required." : undefined
                  }
                  label="Week *"
                  minDate={new Date().setFullYear(
                    new Date().getFullYear() - 10
                  )}
                  maxDate={new Date()}
                  value={model.startDate ? model.startDate : null}
                  onChange={(value: any) =>
                    handleFieldOnChange("startDate", value ? value : undefined)
                  }
                  className={classes.width100}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Autocomplete
                {...exportTypeAutoComplete}
                autoComplete
                includeInputInList
                onChange={(e: any, value: any) => {
                  handleFieldOnChange(
                    "exportType",
                    value ? value : undefined,
                    (v: any) => v?.id
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Export Type *"
                    name="exportType"
                    error={!!data.errors.exportType}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.exportType
                        ? "Export Type is required."
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <InstructorsAutoComplete
                id="instructorId"
                name="instructorId"
                label="Instructor"
                campusId={model.campusId}
                valueFilter={
                  model.instructorId
                    ? {
                        key: "value",
                        values: [model.instructorId],
                      }
                    : undefined
                }
                filterHandle={(v: any) => {
                  handleFieldOnChange("instructorId", v ? v.value : null);
                }}
                error={!!data.errors.instructorId}
              />
            </Grid>
          </Grid>
        </form>
      </FormContext>
    </div>
  );
  reportOverview.parameters = parameters;
  return (
    <ReportOverview
      reportOverview={reportOverview}
      filterHandler={filterHandler}
      getReportParameters={getReportParameters}
      exportHandler={onSubmit}
      preview={preview}
      previewLoader={previewLoader}
    />
  );
};
export default TimeClockPunches;
