import React, { useEffect } from "react";
import clsx from "clsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import { Typography, Card, CardActions, CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
import AttendanceStatusAutoComplete from "../../../../components/AutoComplete/AttendanceStatusAutoComplete";
import EnrollmentStatusAutoComplete from "../../../../components/AutoComplete/EnrollmentStatusAutoComplete";
import DistanceEducationStatusAutoComplete from "../../../../components/AutoComplete/DistanceEducationStatusAutoComplete";
import { EnrollmentGeneral } from "../../../../interfaces/student/academics/EnrollmentProgram";
import { EnrollmentTransferDetails } from "../../../../interfaces/student/academics/EnrollmentProgram";
import { useFormContext, Controller } from "react-hook-form";
import TransferFromProgramAutoComplete from "../../../../../src/components/AutoComplete/TransferFromProgramAutoComplete";
import ProgramsAutoComplete from "../../../../components/AutoComplete/ProgramsAutoComplete";
import ProgramVersionAutoComplete from "../../../../components/AutoComplete/ProgramVersionAutoComplete";

import * as EnrollmentApi from "../../../../api/student/academics/enrollmentApi";
import { useSelector } from "react-redux";
import { ActionResult } from "../../../../interfaces/common/api/ActionResult";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.paper.background,
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2),
			},
		},
		"& .makeStyles-content": {
			backgroundColor: theme.palette.paper.background,
		},
		cardTitle: {
			fontSize: 17,
			color: "Black",
			textDecoration: "bold",
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1),
		},
		textField: {
			marginRight: theme.spacing(1),
			width: "100%",
		},
		fullWidthField: {
			width: "100%",
		},
		cardContent: {
			padding: theme.spacing(1, 3),
		},
	})
);

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& .MuiFormLabel-root": {
				textOverflow: "ellipsis",
				whiteSpace: "nowrap",
				overflow: "hidden",
				width: "80%",
			},
		},
	})
)(TextField);

type TransferDetailsProps = {
	transferDetails: EnrollmentTransferDetails;
	setTransfer: any;
	isNewEnrollmentTransfer: boolean;
	programVersionId?: string;
	isNewEnrollment?: boolean;
	setIsTransferredStudent?: any;
	isNewStudentWorkflow?: boolean;
};

const getFormattedDate = (date: Date) => {
	var year = date.getFullYear();

	var month = (1 + date.getMonth()).toString();
	month = month.length > 1 ? month : "0" + month;

	var day = date.getDate().toString();
	day = day.length > 1 ? day : "0" + day;

	return month + "/" + day + "/" + year;
};

let disTransThis = true;
const TransferDetails = (props: TransferDetailsProps) => {
	const classes = useStyles({});
	const data = useFormContext();
	const { transferDetails, setTransfer } = props;
	const studentId = useSelector<any, any>(
		(state: any) => state.student.studentId
	);

	interface FilmOptionType {
		text: string;
		id: string;
	}
	const defaultProps = {
		options: [],
		getOptionLabel: (option: FilmOptionType) => option.text,
	};

	const GetTransferDetails = () => {
		if (
			props.isNewEnrollment !== undefined &&
			props.isNewEnrollment === false &&
			props.programVersionId
		) {
			EnrollmentApi.GetTransferDetails(studentId, props.programVersionId).then(
				(response: ActionResult) => {
					if (response.resultStatus === 0) {
						if (response.result !== undefined && response.result !== null) {
							let updateTransferDetails = transferDetails;
							let data: any = response.result;
							updateTransferDetails.moreThenOneEnrollment =
								data.moreThenOneEnrollment;
							updateTransferDetails.transferDate = data.transferDate;
							updateTransferDetails.transferFromProgramId =
								data.transferFromProgramId;
							updateTransferDetails.transferFromStuEnrollId =
								data.transferFromStuEnrollId;
							updateTransferDetails.oldProgramVersionId =
								data.oldProgramVersionId;
							updateTransferDetails.oldStuEnrollId =
								data.transferFromStuEnrollId;
							updateTransferDetails.transferHoursOther =
								data.transferHoursOther;
							updateTransferDetails.transferHoursThis = data.transferHoursThis;
							updateTransferDetails.transferDateToNewProgram =
								data.transferDateToNewProgram;

							setTransfer({ ...updateTransferDetails });
							if (props.setIsTransferredStudent) {
								props.setIsTransferredStudent(true);
							}
						}
					}
				}
			);
		}
	};
	useEffect(() => {
		//existing student being transfer from program
		GetTransferDetails();
	}, []);

	useEffect(() => {
		//existing student being transfer from program
		GetTransferDetails();
	}, [props.programVersionId]);

	const handleFieldOnChange = (fieldId: string, value: any) => {
		let updatedModel = transferDetails;
		if (fieldId == "transferHoursThis") {
			if (value > 0) {
				disTransThis = false;
			} else if (value < 1) {
				updatedModel.transferFromProgramId = "";
				updatedModel.transferFromStuEnrollId = "";
			} else {
				disTransThis = true;
			}
		}

		(updatedModel as any)[fieldId] = value;

		if (fieldId == "transferHoursOther" && !value) {
			updatedModel.transferHoursOther = 0;
		}
		setTransfer({ ...updatedModel });
	};
	const setProgramId = (data: any) => {
		if (data) {
			let ProgramId = data.value;
			let StuEnrollId = data.valueTwo;
			transferDetails.transferFromProgramId = ProgramId;
			transferDetails.transferFromStuEnrollId = StuEnrollId;
			setTransfer(transferDetails);
		}
	};
	const handleTransferDate = (e: any) => {
		if (e) {
			let date = getFormattedDate(e as Date);
			transferDetails.transferDate = date;
			setTransfer(transferDetails);
		}
	};

	const handleTransferHoursThisOnChange = (value: any) => {
		let updatedModel = transferDetails;
		updatedModel.transferHoursThis = value;
		if (value > 0) {
			disTransThis = false;
		}
		if (value < 1) {
			updatedModel.transferFromProgramId = "";
			updatedModel.transferFromStuEnrollId = "";
		}
		setTransfer({ ...updatedModel });
	};

	if (transferDetails.transferHoursThis! > 0) {
		disTransThis = false;
	} else {
		disTransThis = true;
	}
	if (!transferDetails.moreThenOneEnrollment!) {
		disTransThis = true;
	}

	return (
		<Card square={true}>
			<Typography className={clsx(classes.cardTitle)}>
				&nbsp; Transfer Details
			</Typography>
			<CardContent className={classes.cardContent}>
				<Grid container direction="row" spacing={2}>
					<Grid item xs={12} sm={4}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDatePicker
								disableToolbar
								variant="inline"
								format="MM/dd/yyyy"
								id="TransferDate"
								label="Transfer Date"
								TextFieldComponent={ThemeTextField}
								fullWidth
								autoOk={true}
								KeyboardButtonProps={{
									"aria-label": "change date",
								}}
								value={
									transferDetails.transferDate
										? transferDetails.transferDate
										: null
								}
								onChange={(value) => handleTransferDate(value)}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
					<Grid item xs={12} sm={4}>
						<ProgramVersionAutoComplete
							id="transferToProgram"
							name="transferToProgram"
							label="To Program"
							selectAll={true}
							valueFilter={
								transferDetails.transferToProgramId
									? {
											key: "value",
											values: [transferDetails.transferToProgramId],
									  }
									: undefined
							}
							filterHandle={(v: any) => {
								handleFieldOnChange(
									"toProgramVersion",
									v ? v.value : undefined
								);
							}}
						/>
					</Grid>
				</Grid>

				<Grid container direction="row" spacing={2}>
					<Grid item xs={12}>
						<h5></h5>
					</Grid>
				</Grid>

				<Grid container direction="row" spacing={2}>
					<Grid item xs={12} sm={4}>
						<ThemeTextField
							id="TransferHoursThis"
							className={classes.textField}
							label="Transfer hours from this school"
							type="text"
							value={transferDetails.transferHoursThis}
							onChange={(e: any) => {
								handleFieldOnChange("transferHoursThis", e.target.value);
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						{!transferDetails.moreThenOneEnrollment &&
							(transferDetails?.transferHoursThis ?? 0) <= 0}

						<TransferFromProgramAutoComplete
							filterHandle={(data: any) => {
								setProgramId(data);
							}}
							id="transferFromProgram"
							name="transferFromProgram"
							valueFilter={
								transferDetails.transferFromProgramId
									? {
											key: "value",
											values: [transferDetails.transferFromProgramId],
									  }
									: undefined
							}
						/>
					</Grid>
				</Grid>
				<Grid container direction="row" spacing={2}>
					<Grid item xs={12} sm={4}>
						<ThemeTextField
							id="TransferHoursOther"
							className={classes.textField}
							label="Transfer hours from other school"
							type="text"
							value={transferDetails.transferHoursOther ?? 0}
							onChange={(e: any) => {
								handleFieldOnChange("transferHoursOther", e.target.value);
							}}
						/>
					</Grid>
				</Grid>
			</CardContent>
			<CardActions></CardActions>
		</Card>
	);
};

export default TransferDetails;
