import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.buttonProgress.primary,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: theme.spacing(-1.5),
    marginLeft: theme.spacing(-1.5),
  },
  buttonSuccess: {
    backgroundColor: theme.palette.buttonProgress.primary,
    "&:hover": {
      backgroundColor: theme.palette.buttonProgress.secondary,
    },
  },
  buttonError: {},
}));

type ProgressSaveButtonProps = {
  text: string;
  onClick: any;
  loading: boolean;
  disabled?: boolean;
  buttonClassName?: any;
  status?: "success" | "error";
  type?: "button";
  startIcon?: any;
  size?: "small" | "medium" | "large";
  component?: React.ElementType;
};

const ProgressSaveButton = (props: ProgressSaveButtonProps) => {
  const classes = useStyles({});
  const buttonClassname = clsx(
    {
      [classes.buttonSuccess]: props.status === "success",
      [classes.buttonError]: props.status === "error",
    },
    props.buttonClassName
  );

  const handleButtonClick = () => {
    if (props.onClick) props.onClick();
  };

  return (
    // <div className={classes.root}>
    // <div className={classes.wrapper}>
    <React.Fragment>
      {!props.loading && (
        <React.Fragment>
          {(!!props.component && <Button
            variant="contained"
            color="primary"
            disabled={props.disabled}
            onClick={handleButtonClick}
            className={clsx(buttonClassname)}
            startIcon={props.startIcon ? props.startIcon : undefined}
            size={props.size}
            component={props.component}
          >
            {props.text}
          </Button>) ||
            (<Button
              variant="contained"
              color="primary"
              disabled={props.disabled}
              type={props.type ? props.type : "submit"}
              onClick={handleButtonClick}
              className={clsx(buttonClassname)}
              startIcon={props.startIcon ? props.startIcon : undefined}
              size={props.size}
            >
              {props.text}
            </Button>)
          }
        </React.Fragment>
      )}
      {props.loading && (
        <React.Fragment>
          <Button
            size={props.size}
            variant="contained"
            color="primary"
            disabled={true}
            onClick={handleButtonClick}
            className={clsx(buttonClassname)}
            startIcon={props.startIcon ? props.startIcon : undefined}
          >
            {props.text}
            <CircularProgress size={24} className={classes.buttonProgress} />
          </Button>
        </React.Fragment>
      )}
    </React.Fragment>
    // </div>
    // </div>
  );
};

export default ProgressSaveButton;
