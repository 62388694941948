import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Typography, Card, CardContent, IconButton } from "@material-ui/core";
import DisplayField from "../DisplayField/DisplayField";
import { IAttendanceCard } from "../../../interfaces/student/quickInfo/IAttendanceCard";
import * as quickInfoApi from "../../../api/student/quickInfo/quickInfoApi";
import { useSelector } from "react-redux";
import AttendanceSummaryPopup from "./AttendanceSummaryPopup";
import { CustomSnackBarProps } from "../../../interfaces/common/CustomSnackBarProps";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import { system } from "../../../constants/system/systemConstants";
import { Link } from "react-router-dom";
import ProgressSaveButton from "../Buttons/ProgressSaveButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import CircularProgress from "@material-ui/core/CircularProgress";


const useStyles = makeStyles((theme: any) =>
  createStyles({
    card: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(2),
      "& .MuiCardContent-root": {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    cardTitle: {
      fontSize: 14,
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(2),
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightMedium,
    },
    saveButton: {
			width: 10
		},
  })
);

type IAttendanceCardData = {
  LDA: any;
  actualHours: any;
  scheduledHours: any;
  absentHours: any;
  makeupHours: any;
  scheduledHoursLDA: any;
  scheduledHoursPerWeek: any;
  attendancePercentage: any;
  attendancePercentageLDA: any;
};

const AttendanceCard = () => {
  const classes = useStyles({});
  const [attendancePopUpOpen, setAttendancePopUpOpen] =
    React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isCreditHour, setIsCreditHour] = React.useState(false);
  const student = useSelector((state: any) => state.student);

  React.useEffect(() => {
    if (student.selectedEnrollment && student.selectedEnrollment.stuEnrollId) {
      let enrollmentId = student.selectedEnrollment.stuEnrollId;
      setIsCreditHour(student?.selectedEnrollment?.isCreditHour);
    }
  }, [student.selectedEnrollment]);


  const openAttendancePopUp = React.useCallback(() => {
    setAttendancePopUpOpen(true);
  }, []);

  const closeAttendancePopUp = React.useCallback(() => {
    setAttendancePopUpOpen(false);
  }, []);

  const appSettings = useSelector((state: any) => {
    return state.settings.settings;
  });
  const isDistanceAttTrackingEnabled =
    (
      (appSettings[system.settings.isDistanceAttTracking] as string) || "false"
    ).toLowerCase() === "true";

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );

  const [attendanceData, setattendanceData] = useState<IAttendanceCard | null>(
    null
  );
  const selectedEnrollment = useSelector(
    (state: any) => state.student.selectedEnrollment
  );
  useEffect(() => {
    GetAttendanceData();
  }, [selectedEnrollment]);
  
  const GetAttendanceData = () => {
    if (selectedEnrollment && selectedEnrollment.stuEnrollId)
      quickInfoApi
        .GetAttendanceQuickInformation(selectedEnrollment.stuEnrollId)
        .then((response) => {
          if (response) {
            setattendanceData(response.data);
          }
        });
  };

  const handleConfirm = () => {
    if (selectedEnrollment && selectedEnrollment.stuEnrollId)
      console.log(selectedEnrollment.stuEnrollId)
      quickInfoApi
        .GetResetExcusedHours(selectedEnrollment.stuEnrollId)
        .then((response) => {
          
        });
  };
  useEffect(() => {
    let mounted = true;

    const fetchData = () => {};

    fetchData();

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Card className={classes.card} elevation={2}>
      <div style={{ display: "inline-flex" }}>
        <Typography className={clsx(classes.cardTitle)}>{isCreditHour == true ? (<Link to={"/attendance/1/postCreditHourAttendance"}>ATTENDANCE SUMMARY</Link>) : (<Link to={"/attendance/1/postAttendance"}>ATTENDANCE SUMMARY</Link>)}</Typography>
        {!isCreditHour && (
          <IconButton
          aria-label="show attendance summary"
          onClick={openAttendancePopUp}
        >
          <ListAltOutlinedIcon />
        </IconButton> 
      )}
        {attendanceData?.isSchoolUsingExcusedHours == true && (
        <IconButton
          aria-label="show attendance summary"
          onClick={() => {
            handleConfirm();
          }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : <RefreshIcon />}
        </IconButton>       
        )}
      </div>
      {!isCreditHour &&(
            <CardContent>
              <DisplayField
                labelText="LDA"
                valueText={attendanceData?.lastDateAttendedString}
                fieldType="text"
              ></DisplayField>

              <DisplayField
                labelText="Total Hours"
                valueText={attendanceData?.totalHours}
                fieldType="text"
              ></DisplayField>

              <DisplayField
                labelText="Actual Hours"
                valueText={attendanceData?.actualHours}
                fieldType="text"
              ></DisplayField>

              <DisplayField
                labelText="Scheduled Hours"
                valueText={attendanceData?.scheduledHours}
                fieldType="text"
              ></DisplayField>

              <DisplayField
                labelText="Absent Hours"
                valueText={attendanceData?.absentHours}
                fieldType="text"
              ></DisplayField>

              {attendanceData?.isSchoolUsingExcusedHours == true && (
                <React.Fragment>
                  <DisplayField
                    labelText="Excused Hours"
                    valueText={attendanceData?.excusedHours}
                    fieldType="text"
                  ></DisplayField>
                  <DisplayField
                    labelText="Total Absent Hours"
                    valueText={attendanceData?.totalAbsentHours}
                    fieldType="text"
                  ></DisplayField>
                </React.Fragment>
              )}

              <DisplayField
                labelText="Makeup Hours"
                valueText={attendanceData?.makeupHours}
                fieldType="text"
              ></DisplayField>

              <DisplayField
                labelText="Transfer Hours This school"
                valueText={attendanceData?.totalTransferHoursFromThisSchool}
                fieldType="text"
              ></DisplayField>

              <DisplayField
                labelText="Transfer Hours Other School"
                valueText={
                  attendanceData?.transferHours -
                  attendanceData?.totalTransferHoursFromThisSchool
                }
                fieldType="text"
              ></DisplayField>

              <DisplayField
                labelText="Scheduled Hours (LDA)"
                valueText={attendanceData?.scheduledHoursLastDate}
                fieldType="text"
              ></DisplayField>
              {isDistanceAttTrackingEnabled ? (
                <DisplayField
                  labelText="Distance Education Hours"
                  valueText={attendanceData?.distEdHours}
                  fieldType="text"
                ></DisplayField>
              ) : (
                ""
              )}
              <DisplayField
                labelText="Scheduled Hours / Week"
                valueText={attendanceData?.scheduledHoursPerWeek}
                fieldType="text"
              ></DisplayField>
              <DisplayField
                labelText="Attendance %"
                valueText={attendanceData?.attendancePercentageFormatted}
                fieldType="text"
              ></DisplayField>
              <DisplayField
                labelText="Attendance % w/Transfer"
                valueText={attendanceData?.attendancePercentageWithTransferFormatted}
                fieldType="text"
              ></DisplayField>
              <DisplayField
                labelText="Attendance % (LDA)"
                valueText={attendanceData?.attendancePercentageLDAFormatted}
                fieldType="text"
              ></DisplayField>
            </CardContent>
      )}
      <AttendanceSummaryPopup
        enrollmentId={selectedEnrollment.stuEnrollId}
        module={1}
        open={attendancePopUpOpen}
        handleClose={closeAttendancePopUp}
        isLoading={loading}
        setLoading={setLoading}
        setSnackBar={setSnackBarProps}
      />
    </Card>
  );
};

export default AttendanceCard;
