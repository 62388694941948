import  { useEffect } from "react";
import { useSelector } from "react-redux";
import { cookiesConstants } from "../../constants/actions.constants";
import { useLocation } from "react-router-dom";

const ThemeTenantPicker = () => {
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const themeQueryString = urlParams.get("theme");
	const userState = useSelector((state: any) => state.session.user);

	useEffect(() => {
		if (themeQueryString !== null) {
			localStorage.setItem(
				cookiesConstants.THEME_TENANT_KEY,
				themeQueryString.toUpperCase()
			);
		}
	}, [themeQueryString]);

	const school =
		themeQueryString?.toUpperCase() ??
		userState?.tenantTheme ??
		localStorage.getItem(cookiesConstants.THEME_TENANT_KEY);
	localStorage.setItem(cookiesConstants.THEME_TENANT_KEY, school);

	return school;
};

export default ThemeTenantPicker;
