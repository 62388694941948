import { Action } from "redux";
import * as actionTypes from "../../constants/actions.constants";

const initialState: any = {
	tabLabel: undefined,
};

const tabReducer = (state = initialState, action: Action<any>) => {
	switch (action.type) {
        case actionTypes.tabConstants.SESSION_TAB_CHANGED: {

			return {
				...state,
                tabLabel: (action as any).tabLabel
			};
		}
        case actionTypes.tabConstants.SESSION_TAB_CLEARED: {

            return {
                ...state,
                tabLabel: undefined
            };
        }
		default: {
			return state;
		}
	}
};

export default tabReducer;
