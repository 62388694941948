import API from "../../apiWrapper";
import { ICreditProgramDetails } from "../../../interfaces/setup/academics/ProgramProgramVersion/ICreditProgramDetails";
export const GetProgramDetails = (programId: string) => {
  return API()
    .get("/SystemCatalog/CreditProgram/GetProgramDetails", {
      params: {
        programId
      }
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const IsClockHour = (programId: string) => {
  return API()
    .get("/SystemCatalog/CreditProgram/IsCreditHourProgram", {
      params: {
        programId
      }
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const upsertProgram = (model: ICreditProgramDetails) => {
  return API()
    .post("/SystemCatalog/CreditProgram/UpsertCreditProgram", model, {
			responseType: "json",
		})
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};