import * as React from "react";
import { useRef } from "react";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {
			width: "95%",
		},
	})
);

export const ProgramVersionApiAutoComplete = {
	config: {
		acId: "StudentPrgVersion",
		cacheTime: 5,
		route: "/AcademicRecords/Enrollment/GetEnrollmentsByStudentId",
		requestType: "GET",
		body: null,
		hasUrlParams: true,
		useCache: false,
		storeInCache: false,
		isCampusSpecific: false,
		isUserSpecific: false,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
};

export interface ProgramVersionProps extends FilterParams {
	studentId?: string;
	campusId?: string;
}

const StudentProgramVersionAutoComplete = (props: ProgramVersionProps) => {
	const classes = useStyles({});
	const inputRef: any = useRef(null);
	const [studentId, setStudentId] = React.useState<string | undefined>(undefined);
	const [campusId, setCampusId] = React.useState<string | undefined>(undefined);

	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const PrgVerOnChange = (e: any) => {
		if (props.filterHandle) {
			props.filterHandle(e);
		}
	};

	React.useEffect(() => {
		setStudentId(props.studentId);
		setCampusId(props.campusId);
	}, [props.studentId, props.campusId]);

	return (
		<div className={clsx(classes.divWidth)}>
			<ApiAutoComplete
				config={ProgramVersionApiAutoComplete.config}
				loading={userSelectedCampus ? false : true}
				reqParams={
					{
						campusId: campusId ? campusId : userSelectedCampus,
						studentId: studentId,
					}
				}
				classes={{
					option: "text-black",
				}}
				label={props.label ? props.label : "Program Version"}
				placeholder={"Select a program version"}
				onChange={(e: any) => {
					PrgVerOnChange(e);
				}}
				multiple={false}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
				inputRef={props.inputRef ? props.inputRef : inputRef}
				error={props.error}
				helperText={props.helperText}
				inputName={props.name}
			></ApiAutoComplete>
		</div>
	);
};
export default StudentProgramVersionAutoComplete;
