import API from "../apiWrapper";
import IWeeklyAttendance, { IDayRecord } from "../../interfaces/attendance/IWeeklyAttendance";
import IPostAttendanceFilters from '../../interfaces/attendance/IPostAttendanceFilters';
import { format, formatISO } from 'date-fns';
import IPostAttendacePunchRecords from "../../interfaces/attendance/IPostAttendacePunchRecords";
import { CustomSnackBarProps } from "../../interfaces/common/CustomSnackBarProps";
import IPostCreditHourAttendanceFilters from "../../interfaces/attendance/IPostCreditHourAttendanceFilters";
import { ICreditHourScheduledAttendance } from "../../interfaces/attendance/ICreditHourScheduledAttendance";
import { ICreditHourWeeklyAttendanceData } from "../../interfaces/attendance/ICreditHourWeeklyAttendanceData";

export const getWeeklyAttendacneData = async (filters: IPostAttendanceFilters, campusId: string, startDate: Date, page: number = 1, pageSize: number = 10): Promise<{ data: IWeeklyAttendance[], page: number; endofData?: boolean; itemsOnCurrentPages: number }> => {
    return API()
        .get("AcademicRecords/Attendance/GetStudentsAttendanceByWeek", {
            paramsSerializer: () => {
                const urlparams = new URLSearchParams();
                urlparams.append('filter.PageSize', `${pageSize}`);
                urlparams.append('filter.CurrentPage', `${page}`);
                urlparams.append('filter.Filter.StartDate', `${format(startDate, 'yyyy-MM-dd')}`);
                urlparams.append('campusId', `${campusId}`);
                filters.status && filters.status.map((value) => urlparams.append('filter.Filter.StatusIdList', value));
                filters.programVersion && filters.programVersion.map((value) => urlparams.append('filter.Filter.ProgramVersionList', value));
                filters.enrollmentGroup && filters.enrollmentGroup.map((value) => urlparams.append('filter.Filter.EnrollmentGroupList', value));
                filters.studentNameBadgeId && urlparams.append('filter.Filter.EnrollmentId', filters.studentNameBadgeId)
                return urlparams.toString();
            },
            params: {

            }
        })
        .then(
            (res: any) => {

                return {
                    page: (res.data && res.data.currentPage) ?? 1,
                    endofData: (res.data && !res.data.hasMoreData) ?? false,
                    data: (res.data && res.data.queryable) ?? [],
                    itemsOnCurrentPages: (res.data && res.data.queryable && res.data.queryable.length) ?? 0
                };
            },
            (error: any) => {
                if (error.response) {
                    throw new Error(error.response.data); // => the response payload 
                }
                throw (new Error('Unknown error'));
            }
        );
}

export const getWeeklyCreditHourAttendanceData = async (filters: IPostCreditHourAttendanceFilters, campusId: string, startDate: Date, page: number = 1, pageSize: number = 10): Promise<{ data: ICreditHourScheduledAttendance[], page: number; endofData?: boolean; itemsOnCurrentPages: number }> => {
    return API()
        .get("AcademicRecords/Attendance/GetCreditHourStudentsAttendanceByWeek", {
            paramsSerializer: () => {
                const urlparams = new URLSearchParams();
                urlparams.append('filters.PageSize', `${pageSize}`);
                urlparams.append('filters.CurrentPage', `${page}`);
                urlparams.append('filters.Filter.StartDate', `${format(startDate, 'yyyy-MM-dd')}`);
                urlparams.append('campusId', `${campusId}`);
                filters.status && filters.status.map((value) => urlparams.append('filters.Filter.StatusIdList', value));
                filters.programVersion && filters.programVersion.map((value) => urlparams.append('filters.Filter.ProgramVersionList', value));
                filters.enrollmentGroup && filters.enrollmentGroup.map((value) => urlparams.append('filters.Filter.EnrollmentGroupList', value));
                filters.studentNameBadgeId && urlparams.append('filters.Filter.EnrollmentId', filters.studentNameBadgeId)
                filters.classId && urlparams.append('filters.Filter.ClassId', filters.classId)
                return urlparams.toString();
            },
            params: {

            }
        })
        .then(
            (res: any) => {

                return {
                    page: (res.data && res.data.currentPage) ?? 1,
                    endofData: (res.data && !res.data.hasMoreData) ?? false,
                    data: (res.data && res.data.queryable) ?? [],
                    itemsOnCurrentPages: (res.data && res.data.queryable && res.data.queryable.length) ?? 0
                };
            },
            (error: any) => {
                if (error.response) {
                    throw new Error(error.response.data); // => the response payload 
                }
                throw (new Error('Unknown error'));
            }
        );
}

const i = {startDate:'',statusIdList:[],enrollmentGroupList:[],programVersionList:[],enrollmentId:'',classId:''}

export const markAllCreditHourPresent = async (filters: IPostCreditHourAttendanceFilters, dated:Date, campusId: string): Promise<boolean> => {
    return API()
        .post(`AcademicRecords/Attendance/MarkAllPresent?dated=${format(dated, 'yyyy-MM-dd')}&campusId=${campusId}`, 
        {
            startDate:filters.startDate,
            statusIdList:!!filters.status && filters.status.length > 0 ? filters.status : null,
            enrollmentGroupList:!!filters.enrollmentGroup && filters.enrollmentGroup.length > 0 ? filters.enrollmentGroup : null,
            programVersionList:!!filters.programVersion && filters.programVersion.length > 0 ? filters.programVersion : null,
            enrollmentId:!!filters.studentNameBadgeId ? filters.studentNameBadgeId : null,
            classId:filters.classId
        })
        .then(
            (res: any) => {

                return res.data;
            },
            (error: any) => {
                if (error.response) {
                    throw new Error(error.response.data); // => the response payload 
                }
                throw (new Error('Unknown error'));
            }
        );
}

export const markOrUnmarkPresent = async (stuEnrollId:string, studentScheduleId:string, classId:string, date:Date): Promise<ICreditHourWeeklyAttendanceData> => {
    return API()
        .post(`AcademicRecords/Attendance/MarkOrUnmarkPresent?stuEnrollId=${stuEnrollId}&studentScheduleId=${studentScheduleId}&classId=${classId}&dated=${date}`, null)
        .then(
            (res: any) => {

                return res.data;
            },
            (error: any) => {
                if (error.response) {
                    throw new Error(error.response.data); // => the response payload 
                }
                throw (new Error('Unknown error'));
            }
        );
}

export const markOrUnmarkAbsent = async (stuEnrollId:string, studentScheduleId:string, classId:string, date:Date): Promise<ICreditHourWeeklyAttendanceData> => {
    return API()
        .post(`AcademicRecords/Attendance/MarkOrUnmarkAbsent?stuEnrollId=${stuEnrollId}&studentScheduleId=${studentScheduleId}&classId=${classId}&dated=${date}`, null)
        .then(
            (res: any) => {

                return res.data;
            },
            (error: any) => {
                if (error.response) {
                    throw new Error(error.response.data); // => the response payload 
                }
                throw (new Error('Unknown error'));
            }
        );
}

export const markOrUnmarkExcusedAbsent = async (stuEnrollId:string, studentScheduleId:string, classId:string, date:Date): Promise<ICreditHourWeeklyAttendanceData> => {
    return API()
        .post(`AcademicRecords/Attendance/MarkOrUnmarkExcusedAbsent?stuEnrollId=${stuEnrollId}&studentScheduleId=${studentScheduleId}&classId=${classId}&dated=${date}`, null)
        .then(
            (res: any) => {

                return res.data;
            },
            (error: any) => {
                if (error.response) {
                    throw new Error(error.response.data); // => the response payload 
                }
                throw (new Error('Unknown error'));
            }
        );
}

export const markOrUnmarkUnExcusedAbsent = async (stuEnrollId:string, studentScheduleId:string, classId:string, date:Date): Promise<ICreditHourWeeklyAttendanceData> => {
    return API()
        .post(`AcademicRecords/Attendance/MarkOrUnmarkUnExcusedAbsent?stuEnrollId=${stuEnrollId}&studentScheduleId=${studentScheduleId}&classId=${classId}&dated=${date}`, null)
        .then(
            (res: any) => {

                return res.data;
            },
            (error: any) => {
                if (error.response) {
                    throw new Error(error.response.data); // => the response payload 
                }
                throw (new Error('Unknown error'));
            }
        );
}

export const markOrUnmarkTardy = async (stuEnrollId:string, studentScheduleId:string, classId:string, date:Date): Promise<ICreditHourWeeklyAttendanceData> => {
    return API()
        .post(`AcademicRecords/Attendance/MarkOrUnmarkTardy?stuEnrollId=${stuEnrollId}&studentScheduleId=${studentScheduleId}&classId=${classId}&dated=${date}`, null)
        .then(
            (res: any) => {

                return res.data;
            },
            (error: any) => {
                if (error.response) {
                    throw new Error(error.response.data); // => the response payload 
                }
                throw (new Error('Unknown error'));
            }
        );
}

export const getStudentAttendanceDetailsByDate = (studEnrollId: string, date: Date): Promise<IPostAttendacePunchRecords> => {
    return API()
        .get("AcademicRecords/Attendance/GetStudentsAttendanceDetails", {
            params: {
                studEnrollId,
                date: `${format(date, 'yyyy-MM-dd')}`
            }
        })
        .then(
            (res: any) => {

                return res && res.data && res.data.result
            },
            (error: any) => {
                if (error.response) {
                    throw new Error(error.response.data); // => the response payload 
                }
                throw (new Error('Unknown error'));
            }
        );
}

export const saveDetails = (record:IPostAttendacePunchRecords): Promise<CustomSnackBarProps> => {
    return API()
        .post("AcademicRecords/Attendance/PostAttendancePunches", record)
        .then(
            (res: any) => {
                if (res && res.data) {
                    return {
                        variant: res.data.result ? 'success' : 'error',
                        showSnackBar: true,
                        messageInfo: res.data.resultStatusMessage
                    }
                }
                throw (new Error('Unknown error'));
            },
            (error: any) => {
                if (error.response) {
                    throw new Error(error.response.data); // => the response payload 
                }
                throw (new Error('Unknown error'));
            }
        );
}

export const createPunchRecord = (campusId: string, badgeNumber: string, studEnrollId: string, punchIn?: Date, punchOut?: Date, isDistEdPunch?: boolean): Promise<CustomSnackBarProps> => {
    let pin  = format(punchIn ? punchIn : 0, 'yyyy-MM-dd h:mm:ss a');
    let pout = format(punchOut ? punchOut : 0, 'yyyy-MM-dd h:mm:ss a');
    return API()
        .post("AcademicRecords/Attendance/AddPunch", null, {
            params: {
                campusId,
                badgeNumber,
                studEnrollId, 
                punchIn: pin.includes('1970') ? "" : pin,
                punchOut: pout.includes('1970') ? "" : pout,
                isDistEdPunch
            }
        })
        .then(
            (res: any) => {
                if (res && res.data) {
                    return {
                        variant: res.data.result ? 'success' : 'error',
                        showSnackBar: true,
                        messageInfo: res.data.resultStatusMessage
                    }
                }
                throw (new Error('Unknown error'));
            },
            (error: any) => {
                if (error.response) {
                    throw new Error(error.response.data); // => the response payload 
                }
                throw (new Error('Unknown error'));
            }
        );
}

export const updatePunchRecord = (punchId: number[], campusId: string,studentEnrollmentId:string, badgeNumber: string, punchIn: Date, punchOut?: Date): Promise<CustomSnackBarProps> => {
    let pin  = format(punchIn ? punchIn : 0, 'yyyy-MM-dd h:mm:ss a');
    let pout = format(punchOut ? punchOut : 0, 'yyyy-MM-dd h:mm:ss a');

    return API()
        .post("AcademicRecords/Attendance/EditPunch", null, {
            params: {
            },
            paramsSerializer: () => {
                const urlparams = new URLSearchParams();
                urlparams.append('campusId', `${campusId}`);
                urlparams.append('badgeNumber', `${badgeNumber}`);
                urlparams.append('punchIn', `${pin}`);
                punchId.length > 1 && !pout.includes('1970') && urlparams.append('punchOut', `${pout}`);
                punchId && punchId.map((value) => urlparams.append('punchId', `${value}`));
                return urlparams.toString();
            },
        })
        .then(
            (res: any) => {
                if (res && res.data) {
                    return {
                        variant: res.data.result ? 'success' : 'error',
                        showSnackBar: true,
                        messageInfo: res.data.resultStatusMessage
                    }
                }
                throw (new Error('Unknown error'));
            },
            (error: any) => {
                if (error.response) {
                    throw new Error(error.response.data); // => the response payload 
                }
                throw (new Error('Unknown error'));
            }
        );
}

export const validatePunches = (punchId: number[], campusId: string,studentEnrollmentId:string, badgeNumber: string, punchIn: Date, punchOut?: Date, isDistEdPunch?: boolean): Promise<CustomSnackBarProps> => {
    let pin  = format(punchIn ? punchIn : 0, 'yyyy-MM-dd h:mm:ss a');
    let pout = format(punchOut ? punchOut : 0, 'yyyy-MM-dd h:mm:ss a');

    return API()
        .post("AcademicRecords/Attendance/validatePunches", null, {
            params: {
            },
            paramsSerializer: () => {
                const urlparams = new URLSearchParams();
                urlparams.append('campusId', `${campusId}`);
                urlparams.append('badgeNumber', `${badgeNumber}`);
                urlparams.append('punchIn', `${pin}`);
                urlparams.append('isDistEdPunch', `${isDistEdPunch}`);
                punchId.length > 1 && !pout.includes('1970') && urlparams.append('punchOut', `${pout}`);
                punchId && punchId.map((value) => urlparams.append('punchId', `${value}`));
                return urlparams.toString();
            },
        })
        .then(
            (res: any) => {
                if (res && res.data) {
                    return {
                        variant: res.data.result ? 'success' : 'error',
                        showSnackBar: true,
                        messageInfo: res.data.resultStatusMessage
                    }
                }
                throw (new Error('Unknown error'));
            },
            (error: any) => {
                if (error.response) {
                    throw new Error(error.response.data); // => the response payload 
                }
                throw (new Error('Unknown error'));
            }
        );
}

export const deletePunchRecord = (punchId: number[], campusId: string, studentEnrollmentId:string, badgeNumber: string): Promise<CustomSnackBarProps> => {
    return API()
        .post("AcademicRecords/Attendance/DeletePunch", null, {
            params: {
            },
            paramsSerializer: () => {
                const urlparams = new URLSearchParams();
                urlparams.append('campusId', `${campusId}`);
                urlparams.append('badgeNumber', `${badgeNumber}`);
                punchId && punchId.map((value) => urlparams.append('punchId', `${value}`));
                return urlparams.toString();
            },
        })
        .then(
            (res: any) => {
                if (res && res.data) {
                    return {
                        variant: res.data.result ? 'success' : 'error',
                        showSnackBar: true,
                        messageInfo: res.data.resultStatusMessage
                    }
                }
                throw (new Error('Unknown error'));
            },
            (error: any) => {
                if (error.response) {
                    throw new Error(error.response.data); // => the response payload 
                }
                throw (new Error('Unknown error'));
            }
        );
}

export const postAbsentForDay = (campusId: string, badgeNumber: string, date: Date): Promise<CustomSnackBarProps> => {
    return API()
        .post("AcademicRecords/Attendance/PostAbsent", null, {
            params: {
                campusId,
                badgeNumber,
                date: format(date, "yyyy-MM-dd" )
            },
        })
        .then(
            (res: any) => {
                if (res && res.data) {
                    return {
                        variant: res.data.result ? 'success' : 'error',
                        showSnackBar: true,
                        messageInfo: res.data.resultStatusMessage
                    }
                }
                throw (new Error('Unknown error'));
            },
            (error: any) => {
                if (error.response) {
                    throw new Error(error.response.data); // => the response payload 
                }
                throw (new Error('Unknown error'));
            }
        );
}
