import React, { useRef } from "react";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector } from "react-redux";
import { StatusInSchoolApiAutoComplete } from "../../constants/SystemCatalog/StatusInSchoolApiAutoComplete";
import { TextField, Checkbox } from "@material-ui/core";


const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& label.Mui-focused": {
        color: theme.palette.primary.white,
      },
      "& input": {
        color: theme.palette.black,
        opacity: 1,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: 13,
        paddingRight: "0px !important",
        paddingLeft: "1px !important",
        minWidth: '100px !important'
      },
      "& .MuiInputBase-root": {
        backgroundColor: theme.palette.site.secondary,
        borderRadius: 20,
        paddingLeft: "10px !important",
        fontSize: 13,
        minWidth: "225px",
        maxWidth: "750px",
        display: 'inline-flex',
        width: 'max-content',
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.black,
        paddingTop: "2.5px !important",
        paddingBottom: "2.5px !important",
      },

    },
  })
)(TextField);


const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {
      width: "95%",
    }, inlineText: {
      display: "inline-block",
      paddingRight: "2px !important"
    },
  })
);

export const StatusInSchoolAutoComplete = (props: any) => {
  const classes = useStyles({});
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );
  const statusOnChange = (e: any) => {
    if (props.filterHandle) {
      props.filterHandle(e);
    }
  };
  return (
    <div className={clsx(classes.divWidth)}>
      <ApiAutoComplete
        config={StatusInSchoolApiAutoComplete.config}
        classes={{
          option: "text-black",
        }}
        loading={userSelectedCampus ? false : true}
        reqParams={{ campusId: userSelectedCampus }}
        label=""
        onChange={(e: any) => {
          statusOnChange(e);
        }}
        multiple={props.multiple || false}
        renderOption={props.multiple ? (option: any, params: { selected: boolean }) => (
          <React.Fragment>
            <Checkbox color="primary" checked={params.selected} />
            {option.text}
          </React.Fragment>
        ) : undefined}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        renderTags={props.renderTags ? props.renderTags : (value: any, _getTagProps) => {
          return value.map((option: any, index: number) => (option.text + (index === value.length - 1 ? '' : ', ')));
        }}
        renderInput={(params: any) => {
          return (
            <ThemeTextField
              {...params}
              fullWidth
              InputProps={{
                ...params.InputProps,
                startAdornment: <React.Fragment> <div className={classes.inlineText}>Status: {!!(props.multiple) && params.InputProps.startAdornment}</div></React.Fragment>,
                disableUnderline: true,
              }}
            />
          );
        }}
      ></ApiAutoComplete>
    </div>
  );
};
export default StatusInSchoolAutoComplete;
