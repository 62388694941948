import React, { useState, useEffect } from 'react';
import {
	makeStyles,
	createStyles,
	Card,
	Grid,
} from '@material-ui/core';
import ChargingMethodIncrementCard from './ChargingMethodIncrementCard';
import { ProgramVersionTabProps } from '../../../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersionTabProps';
import { ThemeTextField } from '../../../../../../components/_Layout/Inputs/ThemeTextField';
import * as ProgramVersionApi from '../../../../../../api/setup/academics/programVersionApi';
import { EmptyGuid } from '../../../../../../utils/constants';

const useStyles = makeStyles((theme: any) =>
	createStyles({
		cardTitle: {
			fontSize: 17,
			color: theme.palette.black,
			textDecoration: 'bold',
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1),
			marginBottom: theme.spacing(1),
		},
		headerText: {
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(0),
		},
		cardContent: {
			padding: theme.spacing(1, 3),
			height: '100%',
		},
		cardWrapper: {
			height: '100%',
			padding: theme.spacing(2),
		},
		fullWidthField: {
			width: '100%',
		},
	})
);
const ProgramCatalogChargingMethodTab = (props: ProgramVersionTabProps) => {
	const classes = useStyles({});
	const [usesActualHours, setUsesActualHours] = useState<boolean>(false);
	const [model, setModel] = useState<any>();

	const getProgramVersionChargingMethodDetails = () => {
		ProgramVersionApi.GetProgramVersionChargingMethodInformation(
			props.programVersionId
		).then(
			(response: any) => {
				if (response.resultStatus === 0) {
					setModel(response.result);
					setUsesActualHours(response.result.usesActualHours);
					props.setSnackBar((_props: any) => {
						return {
							variant: 'success',
							showSnackBar: false,
							messageInfo: response.resultStatusMessage,
						};
					});
				} else {
					props.setSnackBar((_props: any) => {
						return {
							variant: 'error',
							showSnackBar: true,
							messageInfo: response.resultStatusMessage,
						};
					});
				}
			},
			(exception: any) => {
				props.setSnackBar((_snackBarProps: any) => {
					return {
						variant: 'error',
						showSnackBar: true,
						messageInfo: exception,
					};
				});
			}
		);
	};

	useEffect(() => {
		if (props.programVersionId && props.programVersionId !== EmptyGuid) {
			//getProgramVersionChargingMethodDetails();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.programVersionId]);
	return (
		<Card square={true} className={classes.cardWrapper} hidden={props.hidden}>
			<Grid container direction="row" spacing={1}>
				<Grid
					item
					container
					xs={12}
					alignContent="flex-start"
					alignItems="flex-start"
					justify="flex-start"
				>
					<ThemeTextField
						id="charginMethod"
						label="Charging Method *"
						name="charginMethod"
						className={classes.fullWidthField}
						value={model ? model.description : ''}
						disabled
					/>
				</Grid>
				<Grid
					item
					container
					xs={12}
					alignContent="flex-start"
					alignItems="flex-start"
					justify="flex-start"
				>
					<ChargingMethodIncrementCard
						programVersionId={props.programVersionId}
						billingMethodId={model ? model.id : EmptyGuid}
						setSnackBar={props.setSnackBar}
						usesActualHours={usesActualHours}
					/>
				</Grid>
			</Grid>
		</Card>
	);
};

export default ProgramCatalogChargingMethodTab;
