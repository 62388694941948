import * as React from "react";
import {
  Card,
  CardContent,
  Grid,
  CardActions,
  Paper,
  Table,
  withStyles,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  CircularProgress,
  Popover,
  Typography,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import * as postGradesApi from "../../../../api/student/academics/postGradesApi";
import {
  PostGradesFilter,
  PostGradeStudentsResult,
  PostGradesModel,
} from "../../../../interfaces/student/academics/PostGradesFilter";
import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";
import { CustomSnackBarProps } from "../../../../interfaces/common/CustomSnackBarProps";
import PostGradesByClassSearchFilter from "./PostGradesByClassSearchFilter";
import InputField from "../../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../../constants/uiConstants/inputConstants";
import * as StudentGradesApi from "../../../../api/student/academics/StudentGradesApi";
import { StudentAttempts } from "../../../../interfaces/student/academics/StudentAttempts";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  ICreditGradesModel,
  IPostCreditGradesModel,
  IPostGradeEnrollment,
  ISearchFilters,
} from "../../../../interfaces/student/academics/PostGradesByClassFilter";
import GradeSystemDetailAutoComplete from "../../../../components/AutoComplete/GradeSystemDetailAutoComplete";
import CreditAttemptHistoryBox from "../../../../../src/pages/student/grades/CreditAttemptHistoryBox";
import ConfirmationModal from "../../../../components/_Layout/Modal/ConfirmationModal";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import CustomCardTitle from "../../../../interfaces/common/card/CustomCardTitle";

const StyledTableCell = withStyles((theme: any) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.site.secondary,
      fontSize: 17,
      color: theme.palette.black,
      fontWeight: "bold",
      textAlign: "center",
      boxShadow: "1px 1px 0px 0px rgba(0,0,0,0.2)",
      "&:first-child": {
        width: "42px",
        padding: theme.spacing(0, 1),
      },
    },
    body: {
      fontSize: 16,
      color: theme.palette.black,
      fontWeight: 500,
      cursor: "pointer",
      textAlign: "left",
      "&:first-child": {
        width: "42px",
        padding: theme.spacing(0, 1),
      },
    },
  })
)(TableCell);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      minHeight: "100%",
      height: "100%",
      width: "100%",
      margin: "auto",
    },
    paperRoot: {
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
    },
    cardMain: {
      marginTop: theme.spacing(2),
    },
    tableContainer: {
      height: "400px",
    },
    tableEmptyRow: {
      height: "350px",
      textAlign: "center",
      fontSize: 20,
      opacity: 0.3,
    },
    table: {
      width: "100%",
    },
    searchField: {
      "&::placeholder": {
        color: "#000000",
      },
    },
    selectionCheckBox: {
      padding: theme.spacing(0),
    },
    preLoaderCell: {
      textAlign: "center",
      padding: theme.spacing(1),
    },
    popOverButtons: {
      display: "flex",
      justifyContent: "space-evenly",
      flexDirection: "row",
      padding: 5,
    },
    typography: {
      padding: theme.spacing(2),
    },
    lineThru: {
      textDecoration: "line-through",
    },
    saveButton: {
      width: 90,
    },
    gridHeadTypeXsmall: {
      width: 30,
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
    gridCellTypeXsmall: {
      width: 30,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(0),
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
      "& ::-webkit-inner-spin-button, ::-webkit-outer-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
        MozAppearance: "textfield",
      },
    },
    gridHeadTypeMedium: {
      width: 160,
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
    gridCellTypeMedium: {
      width: 160,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(0),
    },
    datePicker: {
      "& .MuiIconButton-root": {
        padding: theme.spacing(0.8),
      },
    },
    tableRowSelected: {
      backgroundColor: "rgb(255, 201, 201)",
    },
    cardContentTop: {
      paddingTop: 0,
      marginTop: 0,
    },
    tableWrapper: {
      height: "calc(100% - 40px)",
      maxWidth: "400px",
      width: "100%",
    },
    gridHeader: {
      backgroundColor: theme.palette.secondary.dark,
      height: "50px",
      display: "block",
      width: "100%",
      padding: "10px",
      tableLayout: "fixed",
    },
    tableRowWrapper: {
      display: "table",
      width: "100%",
      tableLayout: "fixed",
    },
    tableBodyWrapper: {
      maxHeight: "530px",
      overflowY: "auto",
      display: "block",
      width: "100%",
      padding: "10px",
    },
    header: {
      height: "40px",
      padding: "10px",
      fontWeight: 600,
      width: "100%",
      backgroundColor: theme.palette.secondary.dark,
    },
    popoverStyle: {
      maxHeight: "800px",
      "& .MuiPopover-paper": {
        maxWidth: "400px !important",
        overflowY: "hidden",
      },
    },
    right: {
      float: "right",
    },
    buttonSection: {
      paddingTop: theme.spacing(2),
    },
    saveButtonDialog: {
      marginLeft: theme.spacing(2),
    },
    dialogHeader: {
      backgroundColor: theme.palette.secondary.light,
      "& .MuiTypography-root": {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: "12pt",
      },
    },
  })
);

const PostGradesByClass = (props: any) => {
  const classes = useStyles({});
  
  let currentDate = new Date();
  const initialModel = {
    grades: [],
    isFinalGrade: true,
    instrGrdBkWgtDetailId: null,
  };
  const [searchText, setSearchText] = React.useState<string>("");
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [loaderState, setLoaderState] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [allDate, setAllDate] = React.useState<Date | null>(null);
  const [updating, setUpdating] = React.useState<boolean>(false);
  const [model, setModel] = React.useState<IPostCreditGradesModel>({
    ...initialModel,
  });
  const [validComponents, setValidComponents] =
    React.useState<IPostCreditGradesModel>({
      ...initialModel,
    });
  const [isFinalGrade, setIsFinalGrade] = React.useState<boolean>(true);
  const [gradeSystemDetailAutoCompleteDataSource, setGradeSystemDetailAutoCompleteDataSource] = React.useState<any[]>([]);
  const [isLoadingGradeSystemDetail, setIsLoadingGradeSystemDetail] = React.useState<boolean>(false);
  
  const [gradingData, setGradingData] = React.useState<IPostGradeEnrollment[]>(
    []
  );
  const [gradingDataCopy, setGradingDataCopy] = React.useState<
    IPostGradeEnrollment[]
  >([]);

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );

  const [isDirty, setIsDirty] = React.useState<boolean>(false);

  const [showUnsavedChangesPopup, _setShowUnSavedChangesPopup] = React.useState<boolean>(false);
  const showUnsavedChangesPopupRef = React.useRef(showUnsavedChangesPopup);

  const setShowUnSavedChangesPopup = (v:boolean) =>{
    showUnsavedChangesPopupRef.current = v;
    _setShowUnSavedChangesPopup(v);
  }

  const [confirmationPopupState, setConfirmationPopupState] =
    React.useState<any>({
      id: "confirmation-popup",
      message: "",
    });

  const campusId = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const [filters, setFilters] = React.useState<ISearchFilters>({
    programVersionIds: [],
    instrCreditGrdBkWgtDetailId: null,
    statusIds: [],
    campusId: campusId,
    classId: null,
    termId: null,
  });

  const finalGradesKey = "FinalGrade";

  const handleClose = () => {
    setDialogOpen(false);
    setLoaderState(false);
  };

  const handleSearch = (filter: ISearchFilters) => {
    setFilters({ ...filter });
    setIsFinalGrade(filter.instrCreditGrdBkWgtDetailId === finalGradesKey);
    setGradingData([]);
    setGradingDataCopy([]);
    setSearchText("");
    getStudentsForPostGrades({ ...filter });
  };

  const refreshGridData = () => {
    setIsDirty(false);
    getStudentsForPostGrades(filters);
  };

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const getStudentsForPostGrades = (filter: ISearchFilters) => {
    setIsDirty(false);
    setIsLoading(true);

    if (filter.instrCreditGrdBkWgtDetailId == finalGradesKey) {
      filter.instrCreditGrdBkWgtDetailId = null;
    }

    filter.campusId = campusId;
    postGradesApi.getStudentsForCreditPostGrades(filter).then(
      (response: any) => {
        setIsLoading(false);
        if (response && response.data) {
          setGradingData(response.data);
          setGradingDataCopy(response.data);
          loadGradeSystemDetailAutoComplete(response.data);
        }
      },
      (exception: any) => {
        setIsLoading(false);
      }
    );
  };

  const loadGradeSystemDetailAutoComplete = (gData:IPostGradeEnrollment[]) => {

    if(!!gData && gData.length > 0){
      let grdSystemIdList:string[] = [];
      gData.forEach(element => {
        if(!!element.grdSystemId && element.grdSystemId.trim() !== '' && grdSystemIdList.findIndex(f => f == element.grdSystemId) < 0){
          grdSystemIdList.push(element.grdSystemId.trim());
        }
      });

      let grdSystemIds = '';
      if(!!grdSystemIdList && grdSystemIdList.length > 0){
        grdSystemIdList.forEach(element => {
          if(!!element && element.trim() !== ''){
            grdSystemIds += `${!!grdSystemIds && grdSystemIds.trim() != '' ? ',' : ''}`+ element;
          }
        });
      }
      
      if(!!grdSystemIds && grdSystemIds.trim() != ''){
        setIsLoadingGradeSystemDetail(true);
    
        postGradesApi.getGradeSystemDetailAutoCompleteData(grdSystemIds).then(
          (response: any) => {
            setIsLoadingGradeSystemDetail(false);
            if (response && response.data) {
              setGradeSystemDetailAutoCompleteDataSource(response.data);
            }
          },
          (exception: any) => {
            setGradeSystemDetailAutoCompleteDataSource([]);
            setIsLoadingGradeSystemDetail(false);
          }
        );
      }
    }
  };


  const handleAllDateChange = (value: any) => {
    var selectedDate = value ? value?.toLocaleDateString() : undefined;
    setIsDirty(true);
    setAllDate(selectedDate);
  };

  const handleFieldOnChange = (
    row: any,
    fieldName: string,
    value: any,
    index: number
  ) => {
    let makeDirty = true;
    let updatedModel = [...gradingData];
    if (fieldName === "score") {
      if (
        value.target.value == "" ||
        (!isNaN(value.target.value) == true &&
          Number(value.target.value) >= 0 &&
          Number(value.target.value) <= 100)
      ) {
        updatedModel[index].score =
          value.target.value == "" ? null : Number(value.target.value);
      } else {
        if (Number(value.target.value) >= 100) {
          updatedModel[index].score = 100;
        } else if (Number(value.target.value) < 0) {
          updatedModel[index].score = 0;
        }
      }
      if (
        updatedModel[index].dateCompleted == null &&
        updatedModel[index].score != null
      ) {
        let newDate = new Date();
        updatedModel[index].dateCompleted = allDate ? allDate : newDate;
      }
    } else if (fieldName == "grdSysDetailId") {
      updatedModel[index].grdSysDetailId = value;

      if (updatedModel[index].dateCompleted == null) {
        let newDate = new Date();
        updatedModel[index].dateCompleted = allDate ? allDate : newDate;
      }
    } else {
      updatedModel[index].dateCompleted = value?.toLocaleDateString();
    }
    updatedModel[index].isDirty = makeDirty;
    setGradingData([...updatedModel]);
    setGradingDataCopy([...updatedModel]);
    setIsDirty(true);
  };

  const handleCancel = () => {
    setAllDate(null);
    refreshGridData();
  };

  const handleSave = (pathToNavigate?:string, params?:any) => {
    try {
      setUpdating(true);
      let postModel: IPostCreditGradesModel = {
        grades: [],
        instrGrdBkWgtDetailId: isFinalGrade
          ? null
          : filters.instrCreditGrdBkWgtDetailId,
        isFinalGrade: isFinalGrade,
      };

      let grades =
        !!gradingData && gradingData.length > 0 ? [...gradingData] : [];
      var invalidCount = 0;

      if (isFinalGrade) {
        grades.forEach((element, index) => {
          if (
            (element.grdSysDetailId != null && element.dateCompleted == null) ||
            (element.grdSysDetailId == null && element.dateCompleted != null)
          ) {
            invalidCount++;
            return;
          }
          if (element.grdSysDetailId != null && element.dateCompleted != null) {
            if (element.isDirty == true) {
              postModel.grades.push({
                stuEnrollId: element.stuEnrollId,
                score: element.score,
                dateCompleted: element.dateCompleted,
                grdSysDetailId: element.grdSysDetailId,
                instrClassTermId: filters.classId,
                isOverridden: element.isOverridden,
                studentName: element.student,
              });
            }
          }
        });
      } else {
        grades.forEach((element, index) => {
          if (
            (element.score != null && element.dateCompleted == null) ||
            (element.score == null && element.dateCompleted != null)
          ) {
            invalidCount++;
            return;
          }
          if (element.score != null && element.dateCompleted != null) {
            if (element.isDirty == true) {
              postModel.grades.push({
                stuEnrollId: element.stuEnrollId,
                score: element.score,
                dateCompleted: element.dateCompleted,
                grdSysDetailId: element.grdSysDetailId,
                instrClassTermId: filters.classId,
                isOverridden: element.isOverridden,
                studentName: element.student,
              });
            }
          }
        });
      }

      if (postModel.grades.length > 0 && invalidCount == 0) {
        var OverrideGrades = postModel.grades.filter(
          (f) => f.isOverridden == true
        );
        if (isFinalGrade || OverrideGrades.length == 0) {
          handleConfirm(postModel, false, pathToNavigate, params);
        } else {
          setValidComponents(postModel);
          setDialogOpen(true);
          /* setConfirmationPopupState((state: any) => {
            return {
              ...state,
              open: true,
              confirmationText: "Final Grade(s) are overridden. Would you like to recalculate it for all students?",
              confirmationButton: "Yes",
              title: "Recalculate Grades Confirmation",
              cancelText: "No",
              onConfirmEvent: () => {
                handleConfirm(postModel, true);
                setConfirmationPopupState((updatedState: any) => {
                  return { ...updatedState, open: false };
                });
              },
              onCancelEvent: () => {
                handleConfirm(postModel, false);
                setConfirmationPopupState((updatedState: any) => {
                  return { ...updatedState, open: false };
                });
              },
            };
          }); */
        }
      } else {
        setUpdating(false);
        setSnackBarProps({
          variant: "error",
          showSnackBar: true,
          messageInfo: "Please enter valid score and date.",
        });
      }
    } catch (error) {
      setSnackBarProps({
        variant: "error",
        showSnackBar: true,
        messageInfo: "Error",
      });
    }
  };

  const handleConfirm = (comp: IPostCreditGradesModel, override: boolean, pathToNavigate?:string, params?:any) => {
    postGradesApi.postStudentCreditGrades(comp, override).then(
      (response: any) => {
        if (response && response.data) {
          setModel({ ...initialModel });
          getStudentsForPostGrades(filters);
          setSearchText("");
          setDialogOpen(false);
          setSnackBarProps({
            variant: "success",
            showSnackBar: true,
            messageInfo: "Data saved successfully.",
          });
        }
        setUpdating(false);

        setTimeout(() => {
          if(!!pathToNavigate){
            props.history.push({
              pathname: pathToNavigate,
              search: params,
            });
          }
        },300);
      },
      (exception: any) => {
        setUpdating(false);
        setSnackBarProps({
          variant: "error",
          showSnackBar: true,
          messageInfo: "Error on posting grades.",
        });
      }
    );
  };

  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const open = Boolean(anchorEl);
  const popOverId = open ? "attempts-popover" : undefined;
  const [studentAttempts, setStudentAttempts] = React.useState<
    Array<StudentAttempts>
  >([]);
  const openPopover = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: IPostGradeEnrollment
  ) => {
    if (!!filters.instrCreditGrdBkWgtDetailId) {
      StudentGradesApi.GetCreditStudentAttempts(
        row.stuEnrollId,
        filters.instrCreditGrdBkWgtDetailId,
        false
      ).then(
        (response: any) => {
          if (response && response.data) {
            setStudentAttempts(response.data.result);
            setAnchorEl(event);
          }
        },
        (exception: any) => {}
      );
    }
  };
  const closePopover = () => {
    setAnchorEl(null);
  };

  const handleTextFieldClick = (event: any) => {
    event.stopPropagation();
  };

  const handleFilter = (searchText: string) => {
    const filteredComponents = gradingDataCopy.filter(
      (result) =>
        result.student?.toLowerCase().includes(searchText.toLowerCase()) ||
        result.programVersion
          ?.toLowerCase()
          .includes(searchText.toLowerCase()) ||
        result.studentNumber
          ?.toLowerCase()
          .includes(searchText.toLowerCase()) ||
        (result.dateCompleted &&
          new Date(result.dateCompleted)
            .toLocaleDateString()
            .toLowerCase()
            .includes(searchText.toLowerCase()))
    );
    setGradingData(filteredComponents);
    setSearchText(searchText);
  };

  const [nextUrl, setNextUrl] = React.useState<string>();
  const [nextParam, setNextParam] = React.useState<string>();

  React.useEffect(() => {
    const unblock = props.history.block((location:any, action:any) => {
      if (isDirty) {
        setNextUrl(location.pathname);
        setNextParam(location.search);
        setShowUnSavedChangesPopup(true);
        return false;
      }
      return true;
    });
  
    return () => {
      unblock();
    };
  }, [[gradingData, isFinalGrade, filters, isDirty]]);

  return (
    <div className={classes.root}>
      <div className={classes.paperRoot}>

      <React.Fragment>
      <Dialog
			open={showUnsavedChangesPopup}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<CustomCardTitle title='Unsaved Changes' />
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{'There are unsaved changes. Would you like to save?'}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => {
					handleSave(nextUrl, nextParam);
          setShowUnSavedChangesPopup(false);
				}} color="primary">
					Yes
				</Button>
				<Button onClick={() => {
          setIsDirty(false);
          setShowUnSavedChangesPopup(false);
          setTimeout(() => {
            props.history.push({
              pathname: nextUrl,
              search: nextParam,
            });
          },1000);
        }} color="primary">
					No
				</Button>
			</DialogActions>
		</Dialog>
      </React.Fragment>
        <div>
          <CustomSnackbar
            variant={snackBarProps.variant}
            message={snackBarProps.messageInfo}
            open={snackBarProps.showSnackBar}
            onClose={(event?: React.SyntheticEvent, reason?: string) => {
              setSnackBarProps((props: any) => {
                return { ...props, showSnackBar: false };
              });
            }}
          ></CustomSnackbar>
          <Card square={true} elevation={2} className={classes.cardMain}>
            <CardContent>
              <PostGradesByClassSearchFilter
                isLoading={isLoading}
                handleSearch={handleSearch}
              ></PostGradesByClassSearchFilter>
            </CardContent>
            <CardContent className={classes.cardContentTop}>
              <Grid container direction="column" spacing={2}>
                <Grid
                  item
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <TableContainer
                    component={Paper}
                    square
                    className={classes.tableContainer}
                  >
                    <Table
                      stickyHeader
                      size="small"
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={7}>
                            <div className={classes.right}>
                              <TextField
                                placeholder={"Search..."}
                                onClick={handleTextFieldClick}
                                value={searchText}
                                onChange={(v: any) => {
                                  handleFilter(v ? v.target.value : undefined);
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton>
                                        <SearchIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ width: "15%" }}>
                            Student
                          </TableCell>
                          <TableCell style={{ width: "15%" }}>
                            Program Version
                          </TableCell>
                          <TableCell style={{ width: "1%" }}></TableCell>
                          <TableCell style={{ width: "9%" }}>Grade</TableCell>
                          <TableCell style={{ width: "10%" }}>
                            {gradingData.length == 0 ? (
                              "Date"
                            ) : (
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  className={classes.datePicker}
                                  disableToolbar
                                  autoOk={true}
                                  variant="inline"
                                  format="MM/dd/yyyy"
                                  placeholder="Default Date"
                                  maxDate={currentDate}
                                  id={"selectAllDate"}
                                  key={"key_selectAllDate"}
                                  name={"selectAllDate"}
                                  value={allDate ? new Date(allDate) : null}
                                  onChange={(v: any) => {
                                    handleAllDateChange(v ? v : undefined);
                                  }}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            )}
                          </TableCell>
                          <TableCell style={{ width: "5%" }}></TableCell>
                          <TableCell style={{ width: "5%" }}>
                            {isFinalGrade == false ? "Attempts" : ""}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loading ? (
                          <TableRow>
                            <StyledTableCell
                              colSpan={2}
                              className={classes.preLoaderCell}
                            >
                              <CircularProgress />
                            </StyledTableCell>
                          </TableRow>
                        ) : (
                          gradingData?.map(
                            (row: IPostGradeEnrollment, index: any) => (
                              <TableRow
                                key={`TableRow_${row.stuEnrollId}`}
                                className={
                                  (isFinalGrade == false &&
                                    ((row.score != null &&
                                      row.dateCompleted == null) ||
                                      (row.score == null &&
                                        row.dateCompleted != null))) ||
                                  (isFinalGrade == true &&
                                    ((row.grdSysDetailId != null &&
                                      row.dateCompleted == null) ||
                                      (row.grdSysDetailId == null &&
                                        row.dateCompleted != null)))
                                    ? classes.tableRowSelected
                                    : ""
                                }
                              >
                                <TableCell>{row.student}</TableCell>
                                <TableCell>{row.programVersion}</TableCell>
                                <TableCell>
                                  <div style={{ textAlign: "right" }}>
                                    {row?.isOverridden && isFinalGrade == true
                                      ? " *"
                                      : ""}
                                  </div>
                                </TableCell>
                                <TableCell
                                  classes={{
                                    head: classes.gridHeadTypeXsmall,
                                    body: classes.gridCellTypeXsmall,
                                  }}
                                  align="left"
                                >
                                  {isFinalGrade == false && (
                                    <InputField
                                      type={InputType.NUMBER}
                                      decimal
                                      className={classes.textField}
                                      id={
                                        "score_" +
                                        row.stuEnrollId +
                                        row.studentNumber
                                      }
                                      key={
                                        "key_score_" +
                                        row.stuEnrollId +
                                        row.studentNumber
                                      }
                                      name={
                                        "score_" +
                                        row.stuEnrollId +
                                        row.studentNumber
                                      }
                                      defaultValue={
                                        row.score != null ? row.score : null
                                      }
                                      onBlur={(v: any) => {
                                        handleFieldOnChange(
                                          row,
                                          "score",
                                          v ? v : undefined,
                                          index
                                        );
                                      }}
                                    />
                                  )}

                                  {isFinalGrade == true && (
                                    <Autocomplete
                                    options={gradeSystemDetailAutoCompleteDataSource.filter(m => m.grdSystemId == row.grdSystemId)}
                                    getOptionLabel= {(option: any) => option.text}
                                    loading={isLoadingGradeSystemDetail}
                                    autoComplete
                                    includeInputInList
                                    value={!!gradeSystemDetailAutoCompleteDataSource?.filter(m => m.grdSystemId == row.grdSystemId && m.value === row.grdSysDetailId) && gradeSystemDetailAutoCompleteDataSource?.filter(m => m.grdSystemId == row.grdSystemId && m.value === row.grdSysDetailId).length > 0 ? gradeSystemDetailAutoCompleteDataSource?.filter(m => m.grdSystemId == row.grdSystemId && m.value === row.grdSysDetailId)[0] : null}
                                    onChange={(e: any, v: any) => {
                                      if(row.grdSysDetailId != (v ? v.value : undefined))
                                        {
                                          handleFieldOnChange(
                                            row,
                                            "grdSysDetailId",
                                            v ? v.value : undefined,
                                            index
                                          );
                                        }
                                        
                                      }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label=""
                                            name={`grdSystemDetailId_${row.stuEnrollId}`}
                                            key={`grdSystemDetailIdkey_${row.stuEnrollId}`}
                                            fullWidth
                                        />
                                    )}
                                />
                                  )}
                                </TableCell>
                                <TableCell
                                  classes={{
                                    head: classes.gridHeadTypeMedium,
                                    body: classes.gridCellTypeMedium,
                                  }}
                                  align="left"
                                >
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      className={classes.datePicker}
                                      disableToolbar
                                      autoOk={true}
                                      variant="inline"
                                      format="MM/dd/yyyy"
                                      maxDate={currentDate}
                                      id={
                                        "dateCompleted_" +
                                        row.stuEnrollId +
                                        row.studentNumber
                                      }
                                      key={
                                        "key_dateCompleted_" +
                                        row.stuEnrollId +
                                        row.studentNumber
                                      }
                                      name={
                                        "dateCompleted_" +
                                        row.stuEnrollId +
                                        row.studentNumber
                                      }
                                      value={
                                        row.dateCompleted
                                          ? new Date(row.dateCompleted)
                                          : null
                                      }
                                      onChange={(v: any) => {
                                        handleFieldOnChange(
                                          row,
                                          "dateCompleted",
                                          v ? v : undefined,
                                          index
                                        );
                                      }}
                                      KeyboardButtonProps={{
                                        "aria-label": "change date",
                                      }}
                                    />
                                  </MuiPickersUtilsProvider>
                                </TableCell>
                                <TableCell>
                                  {isFinalGrade == false
                                    ? Number(row.attempts)
                                    : ""}
                                </TableCell>
                                <TableCell>
                                  {isFinalGrade == false &&
                                    row.attempts > 0 && (
                                      <CreditAttemptHistoryBox
                                        gradeBookWeightDetailId={
                                          filters.instrCreditGrdBkWgtDetailId
                                        }
                                        showAdj={false}
                                        showScore={true}
                                        lblAdj={"Adj"}
                                        tabType={"Exams"}
                                        excludeLast={false}
                                        setSnackBar={setSnackBarProps}
                                        refreshGridData={refreshGridData}
                                        setConfirmationModal={
                                          setConfirmationPopupState
                                        }
                                        instrClassTermId={filters.classId ?? ""}
                                        stuEnrollId={row.stuEnrollId}
                                        attempts={row.attempts}
                                        isOverridden={row.isOverridden}
                                        studentName={row.student}
                                      />
                                    )}
                                </TableCell>
                              </TableRow>
                            )
                          )
                        )}

                        {gradingData?.length == 0 ? (
                          <TableRow>
                            <TableCell
                              className={classes.tableEmptyRow}
                              colSpan={6}
                            >
                              No Data
                            </TableCell>
                          </TableRow>
                        ) : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </CardContent>
            <ConfirmationModal {...confirmationPopupState} />
            <CardActions className={classes.cardContentTop}>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={9} container direction="row" spacing={1}>
                  <Grid
                    item
                    alignContent="flex-start"
                    alignItems="flex-start"
                    justify="flex-start"
                  >
                    <ProgressSaveButton
                      text="Save"
                      onClick={handleSave}
                      loading={updating}
                      buttonClassName={classes.saveButton}
                    ></ProgressSaveButton>
                  </Grid>
                  <Grid
                    item
                    alignContent="flex-start"
                    alignItems="flex-start"
                    justify="flex-start"
                  >
                    <Button
                      onClick={handleCancel}
                      color="secondary"
                      variant="contained"
                      type="button"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={3} style={{ textAlign: "right" }}>
                  <Typography>* Overridden Final Grade</Typography>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </div>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
          {"Recalculate Grades Confirmation"}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              {}
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <Typography
                color="textSecondary"
                className={classes.typography}
                variant="body1"
              >
                {
                  "Final Grade(s) are overridden. Would you like to recalculate it for following students?"
                }
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableBody>
                    {validComponents?.grades.filter(f => f.isOverridden == true).map(
                      (cls: ICreditGradesModel, ix: any) => (
                        <TableRow>
                          <TableCell>{cls.studentName}</TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <Grid item xs={12}>
          <DialogActions className={classes.buttonSection}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={3}></Grid>
              <Grid
                item
                container
                xs={6}
                alignContent="center"
                alignItems="center"
                justify="center"
              >
                <ProgressSaveButton
                  text={"Yes"}
                  onClick={() => {
                    handleConfirm(validComponents, true);
                  }}
                  loading={false}
                  buttonClassName={classes.saveButtonDialog}
                ></ProgressSaveButton>
                <ProgressSaveButton
                  text={"No"}
                  onClick={() => {
                    handleConfirm(validComponents, false);
                  }}
                  loading={false}
                  buttonClassName={classes.saveButtonDialog}
                ></ProgressSaveButton>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </DialogActions>
        </Grid>
      </Dialog>
    </div>
  );
};

export default PostGradesByClass;
