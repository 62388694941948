import React from 'react';
import {
	Typography,
	CardContent,
	Card,
	IconButton,
	Grid,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import AccessManager from '../../components/security/AccessManager';
import { security } from '../../constants/Security/securityConstants';
import RefreshIcon from '@material-ui/icons/Refresh';
import makeStyles from './WidgetStyles';
import SAPWidgetTab from './sapWidgetTab';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import * as StudentSapApi from '../../../src/api/student/sap/StudentSapApi';

const useStyles = makeStyles;
function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			{...other}
		>
			{value === index && (
				<div>
					<Typography>{children}</Typography>
				</div>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function tabProps(index: any) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

function TitleIVNotices() {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);
	const [SAPData, setSAPData] = React.useState([] as any);
	const [SAPIVData, setSAPIVData] = React.useState([] as any);
	const [SAPTotal, setSAPTotal] = React.useState(0);
	const [SAPIVTotal, setSAPIVTotal] = React.useState(0);

	const resetPage = () => {
		setValue(0);
		setSAPData([]);
		setSAPIVData([]);
		setSAPTotal(0);
		setSAPIVTotal(0);
	};

	const handleChange = (event: any, newValue: any) => {
		setValue(newValue);
	};

	const campusId = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	React.useEffect(() => {}, [SAPData, SAPIVData, value]);

	React.useEffect(() => {
		if (campusId) {
			resetPage();
			LoadSAPNotices(campusId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [campusId]);

	const handelReloadPage = () => {
		resetPage();
		LoadSAPNotices(campusId);
	};

	const LoadSAPNotices = (campusId: any) => {
		StudentSapApi.GetSAPWidgetData(campusId, null).then(
			(response: any) => {
				if (response) {
					var sapData = response.data?.filter((s: any) => s.sapType === 'SAP');
					var sapIVData = response.data?.filter(
            (s: any) => s.sapType === 'IVSAP'
          );
					setSAPData(sapData);
					setSAPIVData(sapIVData);
					setSAPTotal(sapData?.length);
					setSAPIVTotal(sapIVData?.length);
					let currentVal = value;
					setValue(2);
					setValue(currentVal);
				}
			},
			() => {}
		);
	};

	return (
		<AccessManager
			allowedPermissions={[
				security.permissions.dashboard.ViewTitleIVNoticesWidget,
			]}
			renderNoAccess={() => null}
		>
			<Card className={classes.root}>
				<Typography className={clsx(classes.cardTitle)}>
					<Grid container direction="row" justify="center" alignItems="center">
						<Grid item xs={6}>
							<div className={classes.headerText}>SAP NOTICES</div>
						</Grid>
						<Grid item xs={6} className={classes.widgetMenu}>
							<Typography className={classes.headerTextRight}></Typography>
							<IconButton
								className={classes.iconBtn}
								aria-label="settings"
								onClick={handelReloadPage}
							>
								<RefreshIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Typography>
				<CardContent className={classes.cardContent}>
					<div className={classes.innerRoot}>
						<Tabs
							value={value}
							onChange={handleChange}
							className={classes.tabStyle}
							indicatorColor="primary"
						>
							<Tab
								label={'Title IV SAP (' + SAPIVTotal + ')'}
								{...tabProps(0)}
							/>
							<Tab label={'SAP (' + SAPTotal + ')'} {...tabProps(1)} />
						</Tabs>
						<TabPanel value={value} index={0}>
							<SAPWidgetTab
								title="Title IV SAP"
								sapType="IVSAP"
								formData={SAPIVData}
								reLoad={handelReloadPage}
								printPermissions={
									security.permissions.reports.printTitleIVSAPNotices
								}
							></SAPWidgetTab>
						</TabPanel>
						<TabPanel value={value} index={1}>
							<SAPWidgetTab
								title="SAP"
								sapType="SAP"
								formData={SAPData}
								reLoad={handelReloadPage}
								printPermissions={security.permissions.reports.printSAPNotices}
							></SAPWidgetTab>
						</TabPanel>
					</div>
				</CardContent>
			</Card>
		</AccessManager>
	);
}
export default TitleIVNotices;
