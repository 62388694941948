import { colors } from "@material-ui/core";

const white = "#FFFFFF";
const black = "#000000";

//figma colors

const primarySite = "#1B405F";
const headerColor = "#619CE7";
const secondarySite = "#FBFBFB"; //"#3d38a5";
const accentSite = "#3d38a5";
const fontAndIcons = "#263238";
const paperBackground = "#FBFBFB";
const lighterFontsIcons = "rgba(55, 71, 79, 0.75)";
const cardBackground = "#FCFCFC";
const accent2 = "#96C950";
const loginBackground = "rgba(194,194,194,.8)";
const searchBackground = "#666666";
const selectedChartRow = "#f3c057";
const secondarySiteImportant = "#FAFBFE !important";
const paperBorder = "0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)"

const headerBackground = "#FFF";
const menuTextColor = "#546e7a";
const bodyBackground = "#f4f6f8";
const bodyText = "#263238"

//custom colors
const darkError = "#861917";

export default {
  white,
  black,

  //begin overrides
  primary: {
    contrastText: white,
    contrastNavTabColor: primarySite,
    headerText: black,
    dark: primarySite,
    main: primarySite,
    light: primarySite
  },
  secondary: {
    contrastText: black,
    dark: secondarySite,
    main: secondarySite,
    light: secondarySite,
    mainImportant: secondarySiteImportant
  },
  error: {
    contrastText: white,
    dark: darkError,
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: fontAndIcons,
    secondary: lighterFontsIcons,
    link: colors.blue[600]
  },
  //end overrides

  site: {
    primary: primarySite,
    secondary: secondarySite,
    accent: accentSite
  },
  table: {
    row: {
      background: white,
      hover: secondarySite
    }
  },
  paper: {
    background: paperBackground
  },
  card: {
    background: cardBackground
  },
  icon: {
    primary: fontAndIcons,
    secondary: lighterFontsIcons
  },
  login: {
    background: loginBackground
  },
  topBar:{
    background: headerColor,
    icons: white,
    inputFields:{
      background: "#FAFBFE",
      border: "#DBE5EE",
      color: primarySite
    }
  },
  buttons: {
    primary: primarySite,
    secondary: secondarySite
  },
  studentSearch: {
    background: "#FAFBFE",
    border: "#DBE5EE"
  },
  charts: {
    selectedRow: selectedChartRow
  },
  buttonProgress: {
    primary: colors.green[500],
    secondary: colors.green[700],
  },
  badge: "#ff7f27",
  paperSummarySchedulerTitle: "#00008b",
  tableHeaderColor: "#e1e1e1",
  divider: primarySite,
  border: primarySite,
  textColor: bodyText,
  menuSubTitleColor: menuTextColor,
  boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 3px 3px 3px 0 rgba(63,63,68,0.15)"
};
