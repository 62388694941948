import { createReducer } from '../utils';
import { IAdhocReport } from '../../interfaces/reports/adhoc/IAdhocReport';

export const maxPagesAllowed = 5;

const { reducer, actions, selectors, types } = createReducer({
	sync: {
		refreshData: (newData: {hasMore: boolean, newTotal: number; removePrevious: number; records: Array<IAdhocReport>; minPageSize: number; maxPageSize: number }) => newData
	},
	asyncs: {
		loadReports: {
			request: (campusId: string, search: string, category: Array<string>, loadType: number = 0, page: number = 1, pageSize: number = 10) => ({ campusId, search, category, page, pageSize, loadType }),
			failure: (message: string) => ({ message }),
			success: (data: IAdhocReport[], hasMore: boolean, total: number, page: number, loadType: number = 0) => ({ data, hasMore, total, page, loadType })
		}
	}
}, {
	search: "",
	category: [] as Array<string>,
	message: '',
	reports: [] as IAdhocReport[],
	minPage: 1,
	maxPage: 1,
	pageSize: 10,
	total: 0,
	removeWhenPrev: 0,
	hasMore: false,
	hasPrevious: false,
	loadType: 0
}, {
	sync: {
		refreshData: (state, action) => ({
			...state, data: {
				...state.data,
				reports: action.data.records,
				minPage: action.data.minPageSize,
				maxPage: action.data.maxPageSize,
				total: action.data.newTotal,
				removeWhenPrev: action.data.removePrevious,
				hasMore: action.data.hasMore
			}
		})
	},
	asyncs: {
		loadReports: {
			request: (state, action) => ({ ...state, data: { ...state.data, pageSize: action.data.pageSize, loadType: action.data.loadType, search: action.data.search, category: action.data.category } }),
			failure: (state, action) => ({ ...state, data: { ...state.data, message: action.data.message } }),
			success: (state, action) => {
				let newData = [];
				let newCount = 0;
				let removeWhenPrev = 0;
				let hasPrevious = false;
				let minPage = 1;
				let maxPage = 1;
				let newTotal = 0;
				switch (action.data.loadType) {
				case -1:
					newData = action.data.data.concat(state.data.reports.slice(0, state.data.total - state.data.removeWhenPrev));
					newCount = state.data.total - state.data.removeWhenPrev + action.data.total;
					removeWhenPrev = action.data.page === 1 ? 0 : state.data.pageSize;
					hasPrevious = action.data.page !== 1;
					minPage = action.data.page;
					maxPage = state.data.maxPage - 1;
					break;
				case 1:
					newTotal = state.data.total + action.data.total
					newCount = action.data.page <= maxPagesAllowed ? newTotal : newTotal - state.data.pageSize;
					newData = state.data.reports.slice(action.data.page <= maxPagesAllowed ? 0 : state.data.pageSize, state.data.reports.length).concat([...action.data.data]);
					hasPrevious = action.data.page > maxPagesAllowed;
					removeWhenPrev = hasPrevious ? action.data.total : 0;
					minPage = action.data.page > maxPagesAllowed ? action.data.page + 1 - maxPagesAllowed : 1;
					maxPage = action.data.page;
					break;
				default:
					newData = action.data.data;
					newCount = action.data.total;
					hasPrevious = action.data.page > maxPagesAllowed;
					break;

				}
				return {
					...state,
					data: {
						...state.data,
						total: newCount,
						reports: newData,
						hasMore: action.data.hasMore,
						removeWhenPrev: removeWhenPrev,
						minPage,
						maxPage
					}
				}
			}
		}
	}
}, 'adhocReports',
{
	isLoading: (state) => state.loading.loadReports,
	loadType: (state) => state.data.loadType,
	hasMore: (state) => state.data.hasMore,
	hasPrevious: (state) => state.data.hasPrevious,
	data: (state) => state.data.reports,
	pageSize: (state) => state.data.pageSize,
	search: (state) => state.data.search,
	category: (state) => state.data.category,
	prevPage: (state) => state.data.minPage - 1,
	nextPage: (state) => state.data.hasMore ? state.data.maxPage + 1 : -1,
	currTotal: (state) => state.data.total,
	firstPage: (state) => state.data.minPage,
	lastPage: (state) => state.data.maxPage
});

export { selectors, actions, types };

export default reducer;