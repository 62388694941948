import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Grid, FormControlLabel, Switch } from "@material-ui/core";
import StudentNameBadgeIDFilter from "../../attendance/StudentNameBadgeIDFilter";
import { FormContext, useForm, FieldError } from "react-hook-form";
import StudentGroupsAutoComplete from "../../../components/AutoComplete/StudentGroupsAutoComplete";
import ProgramVersionAutoComplete from "../../../components/AutoComplete/ProgramVersionAutoComplete";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ReportOutput } from "../../../enums/ReportOutput";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";
import { IMonthlyTimeClockAttendance } from "../../../interfaces/reports/attendance/IMonthlyTimeClockAttendance";
import { IStudentFilters } from "../../../interfaces/student/enrollment/IStudentFilters";
import * as timeClockAttendanceApi from "../../../api/reports/attendance/timeClockAttendanceApi";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      minHeight: "100%",
      height: "100%",
      maxWidth: "1050px",
      padding: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    paperRoot: {
      padding: theme.spacing(3, 2),
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
    },
    searchActions: {
      marginTop: theme.spacing(3),
      textAlign: "left",
    },
    width100: {
      width: "100%",
    },
    cardTitle: {
      fontSize: 17,
      color: theme.palette.primary.headerText,
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
    switch: {
      display: "flex",
      alignItems: "flex-end",
      padding: "0px 4px !important",
    },
  })
);

const MonthlyTimeClockAttendance = (props: any) => {
  //#region Global Const
  const { report } = props;
  let reportOverview = { report: report } as IReportOverview;
  const classes = useStyles({});
  //#endregion Global Const

  //#region State Objects
  const [preview, setPreview] = React.useState<any>();
  const [previewLoader, setPreviewLoader] = React.useState<boolean>();
  const [singleStudent, setSingleStudent] = React.useState<boolean>(true);
  const [inActiveStudentGroup, setInActiveStudentGroup] = React.useState<
    boolean
  >(false);
  const [inActiveProgramVersion, setInActiveProgramVersion] = React.useState<
    boolean
  >(false);
  const defaultFilters = {
    singleStudent: true,
    inActiveStudentGroup: false,
    inActiveProgramVersion: false,
    student: undefined as IStudentFilters | undefined,
    studentGroupIds: [],
    studentProgramVersions: [],
    month: undefined as Date | undefined,
    exportType: 1 as ReportOutput,
  };

  const [model, setModel] = React.useState<any>(defaultFilters);
  //#endregion State Objects End

  //#region Input Handlers
  const handleChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    switch (name) {
      case "showSingleStudent":
        setSingleStudent(event.target.checked);
        handleFieldOnChange("singleStudent", event.target.checked);
        break;
      case "inActiveStudentGroup":
        setInActiveStudentGroup(event.target.checked);
        break;
      case "inActiveProgramVersion":
        setInActiveProgramVersion(event.target.checked);
        break;
    }
  };

  const setStudentGroup = (studentGroups: any) => {
    let newGroups: string[] = [];
    if (studentGroups !== null) {
      if (Array.isArray(studentGroups)) {
        newGroups = studentGroups.map((gr: any) => gr.value);
      } else {
        newGroups.push(studentGroups.value);
      }
    }
    return newGroups;
  };
  const setProgramVersion = (PrgVersions: any) => {
    let newProgramVersion: string[] = [];
    if (PrgVersions !== null) {
      if (Array.isArray(PrgVersions)) {
        newProgramVersion = PrgVersions.map((gr: any) => gr.value);
      } else {
        newProgramVersion.push(PrgVersions.value);
      }
    }
    return newProgramVersion;
  };

  const exportTypes: Array<DropDownListItem> = [
    { text: "PDF", id: "1" },
    { text: "Excel", id: "2" },
    { text: "Word", id: "3" },
  ];

  const exportTypeAutoComplete = {
    options: exportTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  //#endregion

  //#region Form Handler

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    setModel({ ...updatedModel });
  };
  const data = useForm<any>({ mode: "onBlur" });
  const { handleSubmit } = data;
  const onSubmit = async (isPreview: any) => {
    let params = (await getReportParameters()) as IMonthlyTimeClockAttendance;
    const validation = await data.triggerValidation();
    if (validation) {
      if (isPreview) {
        setPreviewLoader(true);
        timeClockAttendanceApi
          .generateReport(params, isPreview)
          .then((res: any) => {
            if (res) {
              res.arrayBuffer().then((buffer: any) => {
                setPreview(buffer);
                setPreviewLoader(false);
              });
            } else setPreviewLoader(false);
          });
      } else await timeClockAttendanceApi.generateReport(params);
    }
  };
  const filterHandler = (data: any) => {
    if (data) {
      setInActiveStudentGroup(data.inActiveStudentGroup);
      setInActiveProgramVersion(data.inActiveProgramVersion);
      setSingleStudent(data.singleStudent);
      setModel({ ...data });
    } else {
      setSingleStudent(true);
      setInActiveStudentGroup(false);
      setInActiveProgramVersion(false);
      setModel(defaultFilters);
    }
  };
  const getReportParameters = async () => {
    const success = await data.triggerValidation();
    if (model && success) {
      let params: IMonthlyTimeClockAttendance = {
        singleStudent: model.singleStudent,
        studentEnrollId: model?.student?.studentNameBadgeId,
        studentGroupIds: model?.studentGroupIds,
        studentProgramVersions: model?.studentProgramVersions,
        month: model?.month,
        exportType: model.exportType,
        inActiveStudentGroup: inActiveStudentGroup,
        inActiveProgramVersion: inActiveProgramVersion,
        student: model.student,
      };
      return params;
    }
    return {} as IMonthlyTimeClockAttendance;
  };
  const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
    const item = items.find((opt) => {
      if (opt.id == value) return opt;
    });
    return item || ({} as DropDownListItem);
  };
  //#endregion Form Handler

  let parameters = (
    <div className={[classes.root, classes.width100].join(" ")}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} sm={12} xs={12}>
              <FormControlLabel
                label="Single Student"
                control={
                  <Switch
                    checked={singleStudent}
                    value="showSingleStudent"
                    onChange={handleChange("showSingleStudent")}
                    color="primary"
                  />
                }
              />
            </Grid>
            {singleStudent ? (
              <Grid item md={11} sm={11} xs={12}>
                <StudentNameBadgeIDFilter
                  report={true}
                  show={true}
                  model={{
                    studentNameBadgeId: model?.student?.studentNameBadgeId,
                    studentName: model?.student?.studentName,
                  }}
                  setModel={(values) => {
                    handleFieldOnChange("student", values);
                  }}
                />
              </Grid>
            ) : (
              <React.Fragment>
                <Grid item md={9} sm={9} xs={12}>
                  <StudentGroupsAutoComplete
                    id="studentGroupIds"
                    name="studentGroupIds"
                    error={!!data.errors.studentGroupIds}
                    helperText={
                      data.errors.studentGroupIds
                        ? (data.errors.studentGroupIds as FieldError).message
                        : undefined
                    }
                    inputRef={data.register({
                      validate: {
                        atLeastOneRequired: (value) =>
                          (model.studentGroupIds &&
                            model.studentGroupIds.length > 0) ||
                          "Group(s) are required.",
                      },
                    })}
                    filterHandle={(groups: any) => {
                      handleFieldOnChange(
                        "studentGroupIds",
                        groups,
                        setStudentGroup
                      );
                    }}
                    params={{
                      inActiveStudentGroup: inActiveStudentGroup,
                      disableCache: true,
                    }}
                    label="Student Group *"
                    multiple={true}
                    valueFilter={
                      model?.studentGroupIds
                        ? {
                            key: "value",
                            values: model?.studentGroupIds,
                          }
                        : undefined
                    }
                  />
                </Grid>
                <Grid item md={3} sm={3} xs={12} className={classes.switch}>
                  <FormControlLabel
                    label="Inactive Student Groups"
                    control={
                      <Switch
                        checked={inActiveStudentGroup}
                        value="inActiveStudentGroup"
                        onChange={handleChange("inActiveStudentGroup")}
                        color="primary"
                      />
                    }
                  />
                </Grid>
                <Grid item md={9} sm={9} xs={12}>
                  <ProgramVersionAutoComplete
                    id="studentProgramVersions"
                    name="studentProgramVersions"
                    label="Program Version *"
                    multiple={true}
                    error={!!data.errors.studentProgramVersions}
                    helperText={
                      data.errors.studentProgramVersions
                        ? (data.errors.studentProgramVersions as FieldError)
                            .message
                        : undefined
                    }
                    inputRef={data.register({
                      validate: {
                        atLeastOneRequired: (value) =>
                          (model.studentProgramVersions &&
                            model.studentProgramVersions.length > 0) ||
                          "Program Version(s) are required.",
                      },
                    })}
                    params={{
                      inActiveProgramVersion: inActiveProgramVersion,
                    }}
                    selectAll={true}
                    valueFilter={
                      model?.studentProgramVersions
                        ? {
                            key: "value",
                            values: model?.studentProgramVersions,
                          }
                        : undefined
                    }
                    filterHandle={(data: any) => {
                      handleFieldOnChange(
                        "studentProgramVersions",
                        data,
                        setProgramVersion
                      );
                    }}
                  />
                </Grid>
                <Grid item md={3} sm={3} xs={12} className={classes.switch}>
                  <FormControlLabel
                    label="Inactive Program Versions"
                    control={
                      <Switch
                        checked={inActiveProgramVersion}
                        value="inActiveProgramVersion"
                        onChange={handleChange("inActiveProgramVersion")}
                        color="primary"
                      />
                    }
                  />
                </Grid>
              </React.Fragment>
            )}
            <Grid item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  views={["year", "month"]}
                  // disableToolbar
                  variant="inline"
                  id="month"
                  name="month"
                  error={!!data.errors.month}
                  innerRef={(ref: any) => {
                    data.register("month", {
                      required: true,
                    });
                    data.setValue("month", model.month);
                  }}
                  helperText={
                    data.errors.month ? "Month is required." : undefined
                  }
                  label="Month *"
                  minDate={new Date().setFullYear(
                    new Date().getFullYear() - 10
                  )}
                  maxDate={new Date()}
                  value={model.month ? model.month : null}
                  onChange={(value: any) =>
                    handleFieldOnChange("month", value ? value : undefined)
                  }
                  className={classes.width100}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Autocomplete
                {...exportTypeAutoComplete}
                autoComplete
                includeInputInList
                value={getSelectedItem(exportTypes, model.exportType)}
                onChange={(e: any, value: any) => {
                  handleFieldOnChange(
                    "exportType",
                    value ? value : undefined,
                    (v: any) => v?.id
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Export Type *"
                    name="exportType"
                    error={!!data.errors.exportType}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.exportType
                        ? "Export Type is required."
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </FormContext>
    </div>
  );
  reportOverview.parameters = parameters;
  return (
    <ReportOverview
      reportOverview={reportOverview}
      filterHandler={filterHandler}
      getReportParameters={getReportParameters}
      exportHandler={onSubmit}
      preview={preview}
      previewLoader={previewLoader}
    />
  );
};
export default MonthlyTimeClockAttendance;
