import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Card, CardContent, TableRow, Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import { useForm, useFormContext, FieldError } from "react-hook-form";
import { forwardRef } from "react";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import { StudentAward } from "../../../../interfaces/student/studentAccounts/StudentAward";
import { EmptyGuid } from "../../../../utils/constants";
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: any) =>
	createStyles({
		drawer: {
			width: 280
		},
		root: {
			backgroundColor: theme.palette.background.paper,
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2)
			}
		},
		"& .makeStyles-content": {
			backgroundColor: theme.palette.background.paper
		},
		cardTitle: {
			fontSize: 17,
			color: theme.palette.black,
			textDecoration: "bold",
			backgroundColor: theme.palette.iconSecondaryHover,
			padding: theme.spacing(1, 1)
		},
		textField: {
			marginRight: theme.spacing(1),
			width: "100%"
		},
		headerText: {
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(0)
		},
		tableWrapper: {
			"& tr.MuiTableRow-root": {
				"& :nth-child(1)": {
					width: "calc((100% - 0px) / 4) !important"
				},
				"& :nth-child(2)": {
					width: "calc((100% - 0px) / 2) !important"
				},
				"& :nth-child(3)": {
					width: "calc( (100% - 0px) / 8) !important",
					"& div": {
						textAlign: "right",
						width: "100% !important"
					}
				},
				"& :nth-child(4), ": {
					width: "calc( (100% - 0px) / 8) !important",
					"& div": {
						textAlign: "right",
						width: "100% !important"
					}
				}
			}
		}
	})
);

const tableIcons = {
	SortArrow: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
		<ArrowUpward {...props} ref={ref} />
	))
};
type AwardGridProps = {
	awardData: StudentAward[];
	refreshAwardGrid: any;
	setSelectedAward: any;
	setAwardId: any;
	awardId: any;
	setFade: any;
};
const AwardSelections = (props: AwardGridProps) => {
	const { awardData, refreshAwardGrid } = props;
	const classes = useStyles({});
    const data = useFormContext();
    const theme = useTheme()
	React.useEffect(() => {
		if (awardData.length > 0) {
			let awardExist = awardData.find(a => a.studentAwardId === props.awardId);
			if (awardExist) {
				var i = 0;
				for (i = 0; i < awardData.length; i++) {
					awardData[i].selected = false;
				}
				awardExist.selected = true;
				props.setSelectedAward(awardExist);
				props.setAwardId(awardExist.studentAwardId);
			} else {
				props.setSelectedAward(awardData[0]);
				props.setAwardId(awardData[0].studentAwardId);
			}
		} else {
			var emptyAward: StudentAward = {
				studentAwardId: "",
				studentEnrollmentId: "",
				awardTypeId: "",
				academicYearId: "",
				grossAmount: 0,
				modUser: "",
				awardStartDate: new Date(),
				awardEndDate: new Date(),
				disbursements: 0,
				financialAidId: "",
				maxAwardAmount: 0,
				beginUnit: 0,
				endUnit: 0,
				netAmt: 0,
				loanFees: 0,
				recievedAmt: 0,
				refunded: 0,
				selected: false,
				canEdit: false
			};
			props.setSelectedAward(emptyAward);
			props.setAwardId(EmptyGuid);
		}
	}, [awardData]);
	return (
		<div>
			<Card>
				<CardContent>
					<MaterialTable
						columns={[
							{ title: "Period", field: "period" },
							{ title: "Award Description", field: "awardDescription" },
							{
								title: "Expected",
								field: "netAmt",
								type: "currency"
							},
							{
								title: "Received",
								field: "recievedAmt",
								type: "currency"
							},
							{
								title: "Refunded",
								field: "refunded",
								type: "currency"
							}
						]}
						onRowClick={(event: any, rowData: any) => {
							props.setFade((prev: React.SetStateAction<boolean>) => !prev);
							setTimeout(() => {
								let i = 0;
								for (i = 0; i < awardData.length; i++) {
									awardData[i].selected = false;
								}
								props.setSelectedAward(rowData);
								props.setAwardId((rowData as StudentAward).studentAwardId);
								rowData.selected = true;

								props.setFade((prev: React.SetStateAction<boolean>) => !prev);
								// data.reset();
							}, 500);
						}}
						icons={tableIcons}
						data={awardData}
						options={{
							search: false,
							paging: false,
							showTitle: false,
							toolbar: false,
							sorting: false,
							headerStyle: {
								backgroundColor: theme.palette.secondary.main,
								zIndex: 0
							},
							rowStyle: rowData => {
								if (rowData.selected) {
									return {
										backgroundColor: theme.palette.secondary.main,
										fontFamily: "Roboto"
										
									};
								} else {
									return {
										backgroundColor: "white",
										fontFamily: "Roboto"
									};
								}
							}
						}}
						components={{
							Container: props => (
								<Paper className={classes.tableWrapper} {...props} />
							)
						}}
					/>
				</CardContent>
			</Card>
		</div>
	);
};
export default AwardSelections;
