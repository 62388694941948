import API from "../../apiWrapper";
import { IChargingMethodIncrement } from "../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/CharginMethod/IChargingMethodIncrement";
export const GetIncrementDetails = (incrementId: string) => {
    return API()
      .get("/AcademicRecords/Increment/GetIncrementInformation", {
        params: {
            incrementId
        }
      })
      .then(
        (res: any) => {
          if (res && res.data) {
            return res.data;
          }
        },
        (error: any) => {
          return error.response;
        }
      );
  };

  export const UpsertIncrement = (model: IChargingMethodIncrement) => {
    return API()
      .post("/AcademicRecords/Increment/UpsertIncrement", model, {
        responseType: "json",
      })
      .then(
        (res: any) => {
          if (res && res.data) {
            return res.data;
          }
        },
        (error: any) => {
          return error.response;
        }
      );
  };

  
