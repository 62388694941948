import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
   
const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(3),
            minHeight: "100%",
            height: "100%",
            maxWidth: "1050px",
            margin: "auto"
        },
        "& .makeStyles-content": {
            backgroundColor: theme.palette.background.paper
        },
        paperRoot: {
            padding: theme.spacing(3, 2),
            width: "100%",
            minHeight: "100%",
            height: "100%",
            overflowX: "hidden",
            overflowY: "auto",
            flexDirection: "column",
            backgroundColor: theme.palette.background.paper
        }
    })
);

const AppointmentsTab = (props: any) => {
    const classes = useStyles({});

    return (
        <div className={classes.root}>
            <Paper className={classes.paperRoot}>     
                <Typography>Appointments</Typography>
            </Paper>
        </div>
    );
};

export default AppointmentsTab;

