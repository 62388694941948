export const EnrollmentTypes: Array<any> = [
	{
		text: "All",
		value: 1,
	},
	{
		text: "On Campus",
		value: 2,
	},
	{
		text: "Online",
		value: 3,
	},
];

export const RefundPeriodTypes: Array<any> = [
	{
		text: "%",
		value: 1,
	},
	{
		text: "Fixed",
		value: 2,
	},
	{
		text: "Both",
		value: 3,
	},
];

export const EnrollmentNoticeGivenOptions: Array<any> = [
	{
		text: "Before Program Start",
		value: 1,
	},
	{
		text: "After Program Start",
		value: 2,
	},
];

export const RightToCancelNoticeGivenToStudentOptions: Array<any> = [
	{
		text: "Yes",
		value: 1,
	},
	{
		text: "No",
		value: 2,
	},
	{
		text: "N/A",
		value: 3,
	},
];

export const EqualitySymbolOptions: Array<any> = [
	{
		text: "=",
		value: 1,
	},
	{
		text: "<",
		value: 2,
	},
	{
		text: ">",
		value: 3,
	},
	{
		text: "<=",
		value: 4,
	},
	{
		text: ">=",
		value: 6,
	},
	{
		text: "NA",
		value: 5,
	},
];

export const TimePeriodOptions: Array<any> = [
	{
		text: "Hours",
		value: 1,
	},
	{
		text: "Days",
		value: 2,
	},
	{
		text: "Weeks",
		value: 3,
	},
	{
		text: "Percent",
		value: 4,
	},
	{
		text: "N/A",
		value: 5,
	},
];

export const CalculationUsingTypesOptions: Array<any> = [
	{
		text: "Scheduled hours",
		value: 1,
	},
	{
		text: "Actual hours",
		value: 2,
	},
	{
		text: "Scheduled Days",
		value: 3,
	},
	{
		text: "Attended Days",
		value: 4,
	},
	{
		text: "Scheduled Weeks",
		value: 5,
	},
	{
		text: "Attended Weeks",
		value: 6,
	},
	{
		text: "N/A",
		value: 7,
	},
];

export const HoursTypeOptions = [
	{
		text: "Scheduled hours",
		value: 1,
	},
	{
		text: "Actual hours",
		value: 2,
	},
	{
		text: "N/A",
		value: 7,
	},
];

export const DaysTypeOptions = [
	{
		text: "Scheduled Days",
		value: 3,
	},
	{
		text: "Attended Days",
		value: 4,
	},
	{
		text: "N/A",
		value: 7,
	},
];

export const WeeksTypeOptions = [
	{
		text: "Scheduled Weeks",
		value: 5,
	},
	{
		text: "Attended Weeks",
		value: 6,
	},
	{
		text: "N/A",
		value: 7,
	},
];

export const RefundTypes: Array<any> = [
	{
		text: "Full refund",
		value: 1,
	},
	{
		text: "Partial Refund",
		value: 2,
	},
];

export const WhenBothSpecifiedOptions: Array<any> = [
	{
		text: "Lesser of the two",
		value: 1,
	},
	{
		text: "Greater of the two",
		value: 2,
	},
];
