import API from "../apiWrapper";
import IProgramVersionSAPPolicyInfo from "../../interfaces/academicRecords/IProgramVersionSAPPolicyInfo";

export const getProgramVersionSAPDetailsPolicies = (
  programVersionId: string|undefined,
  studentEnrollmentId: string|undefined
): Promise<IProgramVersionSAPPolicyInfo> => {
    let route:string = "";
    if (programVersionId !== undefined){
        route = "AcademicRecords/ProgramVersions/GetProgramVersionSAPDetailsPolicies?programVersionId=" +
        programVersionId;
    }
    else if (studentEnrollmentId !== undefined){
        route = "AcademicRecords/ProgramVersions/GetProgramVersionSAPDetailsPoliciesByEnrollmentId?studentEnrollmentId=" +
        studentEnrollmentId;
    }
  return API()
    .get(route)
    .then(
      (res: any) => {
        return res.data;
      },
      (error: any) => {
        if (error.response) {
          throw new Error(error.response.data); // => the response payload
        }
        throw new Error("Unknown error");
      }
    );
};
