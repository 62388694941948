import * as React from "react";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme =>
	createStyles({
		root: {
			color: theme.palette.text.primary,
			fontWeight: "bold",
			marginTop: theme.spacing(1)
		},
		rounded: {
			borderRadius: 10,
			padding: theme.spacing(0.5)
		}
	})
);

type ILabelProps = {
	children?: React.ReactNode;
	className?: string;
	color?: string;
	shape?: "square" | "rounded";
	style?: any;
	variant?: "contained" | "outlined";
};

const DisplayLabel = (props: ILabelProps) => {
	const { className, variant, color, shape, children, style, ...rest } = props;

	const classes = useStyles({});

	const rootClassName = clsx(
		{
			[classes.root]: true,
			[classes.rounded]: shape === "rounded"
		},
		className
	);

	const finalStyle = {
		...style,
		color: color
	};

	return (
		<Typography {...rest} className={rootClassName} style={finalStyle}>
			{children}
		</Typography>
	);
};

DisplayLabel.defaultProps = {
	style: {},
	variant: "contained",
	shape: "square"
};

export default DisplayLabel;
