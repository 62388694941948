const placementEmployers = {
    acId: "placementEmployers",
    cacheTime: 5,
    route: "/Placement/Employer/GetAllByCampus",
    requestType: "GET",
    body: null,
    hasUrlParams: false,
    useCache: false,
    storeInCache: false,
    isCampusSpecific: true,
    isUserSpecific: false,
    loadOnRender: true,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
      return data;
    }
  };

  export default placementEmployers;
  