import React, { useState, useEffect, Fragment } from "react";
import {
  makeStyles,
  createStyles,
  Card,
  Typography,
  Tabs,
  Tab,
  Grid,
  CardContent,
} from "@material-ui/core";

import CustomCardTitle from "../../../../../../interfaces/common/card/CustomCardTitle";
import {
  IChargingMethodIncrement,
  CharginMethodIncrement,
} from "../../../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/CharginMethod/IChargingMethodIncrement";
import IncrementsAutoComplete from "../../../../../../components/AutoComplete/IncrementsAutoComplete";

import * as IncrementApi from "../../../../../../api/setup/academics/incrementApi";
import IncrementDetailsCard from "./IncrementDetailsCard";
import { EmptyGuid } from "../../../../../../utils/constants";
const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
    cardWrapper: {
      height: "100%",
      width: "100%",
    },
    fullWidthField: {
      width: "100%",
    },
    incrementRow: {
      marginBottom: theme.spacing(1),
    },
  })
);
type CharginMethodIncrementTabProps = {
  programVersionId: string;
  billingMethodId: string;
  setSnackBar: any;
  usesActualHours:boolean;
};
const ChargingMethodIncrementCard = (props: CharginMethodIncrementTabProps) => {
  const classes = useStyles({});
  const [tab, setTab] = React.useState(1);
  const [model, setModel] = useState<IChargingMethodIncrement>(
    new CharginMethodIncrement()
  );
  const [incrementId, setIncrementId] = useState<string>("");

  const handleChange = (event: any, newValue: any) => {
    setTab(newValue);
  };

  const getIncrementDetails = () => {
    if (incrementId !== EmptyGuid && incrementId !== "") {
      IncrementApi.GetIncrementDetails(incrementId).then(
        (response: any) => {
          if (response.resultStatus === 0) {
            setModel(response.result);
            props.setSnackBar((props: any) => {
              return {
                variant: "success",
                showSnackBar: false,
                messageInfo: response.resultStatusMessage,
              };
            });
          } else {
            props.setSnackBar((props: any) => {
              return {
                variant: "error",
                showSnackBar: true,
                messageInfo: response.resultStatusMessage,
              };
            });
          }
        },
        (exception: any) => {
          props.setSnackBar((snackBarProps: any) => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo: exception,
            };
          });
        }
      );
    }
  };

  useEffect(() => {
    getIncrementDetails();
  }, [incrementId]);
  return (
    <Card square={true} className={classes.cardWrapper}>
      <CustomCardTitle title={"Charging Method Increments"} />
      <CardContent className={classes.cardContent}>
        <Grid spacing={4} className={classes.incrementRow}>
          <IncrementsAutoComplete
          refreshData={false}
            id="increment"
            name="increment"
            label="Increment"
            valueFilter={
              incrementId
                ? {
                    key: "value",
                    values: [incrementId],
                  }
                : undefined
            }
            filterHandle={(v: any) => {
              setIncrementId(v ? v.value : null);
            }}
            billingMethodId={props.billingMethodId}
          />
        </Grid>
        {model && <IncrementDetailsCard model={model} setModel={setModel} isEditable={false} usesActualHours={props.usesActualHours}/>}
      </CardContent>
    </Card>
  );
};

export default ChargingMethodIncrementCard;
