import { IScheduleDetails, ScheduleDetails, IClassScheduleDetails, ClassScheduleDetails } from "./IScheduleDetails";
import { EmptyGuid } from "../../../../../../utils/constants";
export interface IScheduleGridRow {
  description: string;
  active: boolean;
  useFlexTime: boolean;
  totalHours: number;
  schPrgWeeks: number | null;
  scheduleId: string;
  programScheduleDetails: Array<IScheduleDetails>;
  programVersionId?: string;
}

export class ScheduleGridRow implements IScheduleGridRow {
  description: string = "";
  active: boolean = false;
  useFlexTime: boolean = false;
  totalHours: number = 0.0;
  schPrgWeeks: number | null = null;
  scheduleId: string = EmptyGuid;
  programScheduleDetails: IScheduleDetails[] = [
    new ScheduleDetails(0),
    new ScheduleDetails(1),
    new ScheduleDetails(2),
    new ScheduleDetails(3),
    new ScheduleDetails(4),
    new ScheduleDetails(5),
    new ScheduleDetails(6),
  ];;
  programVersionId?: string = EmptyGuid;
}

export interface IClassScheduleGridRow {
  name: string;
  code: string;
  statusId: string;
  totalHours: number;
  classScheduleId: string;
  classScheduleDetails: Array<IClassScheduleDetails>;
}

export class ClassScheduleGridRow implements IClassScheduleGridRow {
  name: string = "";
  code: string = "";
  statusId: string = "";
  totalHours: number = 0.0;
  classScheduleId: string = EmptyGuid;
  classScheduleDetails: IClassScheduleDetails[] = [
    new ClassScheduleDetails(0),
    new ClassScheduleDetails(1),
    new ClassScheduleDetails(2),
    new ClassScheduleDetails(3),
    new ClassScheduleDetails(4),
    new ClassScheduleDetails(5),
    new ClassScheduleDetails(6),
  ];
}
