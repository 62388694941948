import * as React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Grid,
  Menu,
  Fade,
  MenuItem,
  Checkbox
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import StatusFilter from './StatusFilter';
import EnrollmentGroupFilter from './EnrollmentGroupFilter';
import StartEndDateFilter from './StartEndDateFilter';
import ProgramVersionFilter from './ProgramVersionFilter';
import StudentNameBadgeIDFilter from './StudentNameBadgeIDFilter';
import IPostAttendanceFilters, { IShowPostAttendanceFilters } from '../../interfaces/attendance/IPostAttendanceFilters';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardSeparator: {
      marginBottom: theme.spacing(1),
    },
    cardContent: {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0
      }
    },
    card: {
      marginLeft: '0px',
      display: 'inline-block',
    },
    addAndClear: {
      textAlign: 'right'
    },
    button: {
      margin: theme.spacing(0, 1),
      display: 'inline-block',
    },
    resetItem: {
      justifyContent: 'center',
      fontSize: theme.spacing(1.5),
      forntWeight: 500
    }
  })
);

interface IPostAttendanceFilterCardProps {
  filters: IPostAttendanceFilters;
  defaultFilters: IPostAttendanceFilters;
  applyFilters: (filters: IPostAttendanceFilters) => void
  resetBit: boolean
}

const PostAttendanceFilterCard = (props: IPostAttendanceFilterCardProps) => {
  const classes = useStyles({});
  const { filters, defaultFilters, applyFilters } = props;

  const [showOptions, setShowOptions] = React.useState<IShowPostAttendanceFilters>({ status: true });

  const reset = () => {
    applyFilters(defaultFilters);
    setShowOptions({ status: false });
    handleFiltersMenuClose();
  }

  React.useEffect(() => {
    reset();
  }, [props.resetBit]);

  const [anchorFilterMenuEl, setanchorFilterMenuEl] = React.useState(null);

  const handleFiltersMenuClick = (event: any) => {
    setanchorFilterMenuEl(event.currentTarget);
  };

  const handleFiltersMenuClose = () => {
    setanchorFilterMenuEl(null);
  };

  const toggleMenuItem = (key: keyof IShowPostAttendanceFilters) => {
    if (showOptions[key]) {
      applyFilters({ ...filters, [key]: defaultFilters[key], studentName: key === 'studentNameBadgeId' ? defaultFilters.studentName : filters.studentName });
    }
    setShowOptions({ ...showOptions, [key]: !showOptions[key] });
  }
  React.useEffect(() => {
    if (!showOptions.status) {
      setShowOptions({ status: true });
    }
  }, [showOptions.status]);

  return (
    <div className={classes.cardSeparator}>
      <Card square={true}  >
        <CardContent className={classes.cardContent}>
          <Grid container spacing={0} direction='row' alignContent='center' alignItems='center'>
            <Grid item xs={10}>
              <Grid container spacing={0} direction='row' alignContent='center' alignItems='center'>
                <Grid item >
                  <StartEndDateFilter model={{ startDate: filters.startDate, endDate: filters.endDate }} setModel={(values) => applyFilters({ ...filters, ...values })} />
                </Grid>
                <Grid item >
                  <StatusFilter show={showOptions.status} model={{ status: filters.status }} setModel={(values) => applyFilters({ ...filters, ...values })} />
                </Grid>
                {showOptions.enrollmentGroup && <Grid item >
                  <EnrollmentGroupFilter show={showOptions.enrollmentGroup} model={{ enrollmentGroup: filters.enrollmentGroup }} setModel={(values) => applyFilters({ ...filters, ...values })} />
                </Grid>}
                {showOptions.studentNameBadgeId &&
                  <Grid item >
                    <StudentNameBadgeIDFilter show={showOptions.studentNameBadgeId} model={{ studentNameBadgeId: filters.studentNameBadgeId, studentName: filters.studentName }} setModel={(values) => applyFilters({ ...filters, ...values })} />
                  </Grid>
                }
                {showOptions.programVersion &&
                  <Grid item >
                    <ProgramVersionFilter show={showOptions.programVersion} model={{ programVersion: filters.programVersion }} setModel={(values) => applyFilters({ ...filters, ...values })} />
                  </Grid>
                }
              </Grid>
            </Grid>
            <Grid item xs={2} className={classes.addAndClear}>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                size='small'
                className={classes.button}
                onClick={handleFiltersMenuClick}
              >
                FILTERS
            </Button>
              <Menu
                id="fade-menu"
                anchorEl={anchorFilterMenuEl}
                getContentAnchorEl={null}
                open={!!anchorFilterMenuEl}
                onClose={handleFiltersMenuClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                TransitionComponent={Fade}
              >
                <MenuItem onClick={() => toggleMenuItem('enrollmentGroup')}><Checkbox color="primary" checked={showOptions.enrollmentGroup ?? false} /> Enrollment Group</MenuItem>
                <MenuItem onClick={() => toggleMenuItem('studentNameBadgeId')}><Checkbox color="primary" checked={showOptions.studentNameBadgeId ?? false} /> Student Name/Badge ID</MenuItem>
                <MenuItem onClick={() => toggleMenuItem('programVersion')}><Checkbox color="primary" checked={showOptions.programVersion ?? false} /> Program Version</MenuItem>
                <MenuItem onClick={reset} className={classes.resetItem} >Reset</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div >
  );
};

export default PostAttendanceFilterCard;
