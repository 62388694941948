export default {
    acId: "completionWaiverACCET",
    cacheTime: 5,
    route: "/Placement/GetCompletionWaiverDropdownList",
    requestType: "GET",
    body: null,
    hasUrlParams: false,
    useCache: false,
    storeInCache: false,
    isCampusSpecific: false,
    isUserSpecific: false,
    loadOnRender: true,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
      return data;
    },
  };
  