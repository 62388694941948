import { useState } from "react";
import {
  makeStyles,
  Card,
  createStyles,
  CardContent,
  Grid,
  FormControlLabel,
  Switch,
  withStyles,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import React from "react";
import CustomCardTitle from "../../../../../../interfaces/common/card/CustomCardTitle";
import { IProgramVersionAttendanceDetails } from "../../../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/IProgramVersionAttendanceDetails";
import { useFormContext, FieldError } from "react-hook-form";
import InputField from "../../../../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../../../../constants/uiConstants/inputConstants";
import AttendanceUnitTypeAutoComplete from "../../../../../../components/AutoComplete/AttendanceUnitTypeAutoComplete";
import HoursTypeAutoComplete from "../../../../../../components/AutoComplete/HoursTypeAutoComplete";
import { EmptyGuid } from "../../../../../../utils/constants";
import { useSelector } from "react-redux";
import { system } from "../../../../../../constants/system/systemConstants";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%",
    },
    cardWrapper: {
      marginBottom: theme.spacing(2),
    },
    switch: {
      marginLeft: "0px",
      paddingTop: theme.spacing(2),
    },
    fullWidthField: {
      width: "100%",
    },
    preLoader: {
      textAlign: "center",
    },
  })
);
type ProgramVersionAttendanceDetailsProps = {
  model: IProgramVersionAttendanceDetails;
  programId?: any;
  programVersionId?: any;
  setModel: any;
  loading?: boolean;
};

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& .MuiFormLabel-root": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "100%",
      },
    },
  })
)(TextField);

const ProgramVersionAttendanceDetails = (
  props: ProgramVersionAttendanceDetailsProps
) => {
  const classes = useStyles({});
  const [tab, setTab] = useState(1);
  const { model, setModel } = props;
  const data = useFormContext();
  const handleChange = (event: any, newValue: any) => {
    setTab(newValue);
  };

  const handleFieldChange = (fieldId: string, value: any) => {
    let updatedModel = model;
    (updatedModel as any)[fieldId] = value;
    setModel({ ...updatedModel });
  };

  const appSettings = useSelector((state: any) => {
    return state.settings.settings;
  });

  const getExcusedAbsenses = () => {
    const setting = appSettings[
      // system.settings.enableClockHourCreditHour
      system.settings.ExcusedAbsenses
    ] as string;
    let allow = false;
    if (setting) {
      allow = setting.toLowerCase() === "true";
    }
    return allow;
  };
  const ExcusedAbsenses = getExcusedAbsenses();

  return (
    <Card square={true} className={classes.cardWrapper}>
      <CustomCardTitle title={"Attendance"}></CustomCardTitle>
      <CardContent>
        {(props.loading && (
          <div className={classes.preLoader}>
            <CircularProgress />
          </div>
        )) || (
          <React.Fragment>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <AttendanceUnitTypeAutoComplete
                  id="attendanceUnitTypeId"
                  name="attendanceUnitTypeId"
                  label="Attendance Unit Type *"
                  valueFilter={
                    model.attendanceUnitTypeId
                      ? {
                          key: "value",
                          values: [model.attendanceUnitTypeId],
                        }
                      : undefined
                  }
                  filterHandle={(v: any) => {
                    handleFieldChange(
                      "attendanceUnitTypeId",
                      v ? v.value : null
                    );
                  }}
                  error={!!data.errors.attendanceUnitTypeId}
                  inputRef={data.register({
                    required: true,
                  })}
                  helperText={
                    data.errors.attendanceUnitTypeId
                      ? (data.errors.attendanceUnitTypeId as FieldError).message
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <InputField
                  type={InputType.NUMBER}
                  id="excusedAbsencePercentageAllowedPerPaymentPeriod"
                  label="Excused Absence % allowed per payment period"
                  name="excusedAbsencePercentageAllowedPerPaymentPeriod"
                  key="excusedAbsencePercentageAllowedPerPaymentPeriod"
                  defaultValue={
                    model.excusedAbsencePercentageAllowedPerPaymentPeriod
                  }
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleFieldChange(
                      "excusedAbsencePercentageAllowedPerPaymentPeriod",
                      e.target.value
                    );
                  }}
                  decimal
                  decimalPlaces={2}
                  error={
                    !!data.errors
                      .excusedAbsencePercentageAllowedPerPaymentPeriod
                  }
                  inputRef={
                    ExcusedAbsenses
                      ? data.register({
                          required: "Excused Absence % required",
                          validate: {
                            cannotBeGreaterthan10: (value) =>
                              Number(value) <= 10 ||
                              "Excused Absence % cannot be greater than 10.00",
                            cannotBeless0: (value) =>
                              Number(value) >= 0 ||
                              "Excused Absence % cannot less than 0.00",
                          },
                        })
                      : undefined
                  }
                  helperText={
                    data.errors.excusedAbsencePercentageAllowedPerPaymentPeriod
                      ? (
                          data.errors
                            .excusedAbsencePercentageAllowedPerPaymentPeriod as FieldError
                        ).message
                      : undefined
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <HoursTypeAutoComplete
                  id="graduateWhenHoursLength"
                  name="graduateWhenHoursLength"
                  label="Graduate when program length is reached in *"
                  valueFilter={
                    model.graduateWhenHoursLength
                      ? {
                          key: "value",
                          values: [model.graduateWhenHoursLength],
                        }
                      : undefined
                  }
                  filterHandle={(v: any) => {
                    handleFieldChange(
                      "graduateWhenHoursLength",
                      v ? v.value : null
                    );
                  }}
                  selectDefaultFirst={
                    !props.programVersionId ||
                    props.programVersionId === EmptyGuid
                  }
                  programVersionId={
                    props.programVersionId != ""
                      ? props.programVersionId
                      : undefined
                  }
                  programId={props.programId}
                  error={!!data.errors.graduateWhenHoursLength}
                  inputRef={data.register({
                    required: true,
                  })}
                  helperText={
                    data.errors.graduateWhenHoursLength ? "Required" : undefined
                  }
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <FormControlLabel
                  id="doesTrackTardies"
                  className={classes.switch}
                  labelPlacement="start"
                  label="Track Tardies"
                  control={
                    <Switch
                      checked={model.doesTrackTardies}
                      name="doesTrackTardies"
                      onChange={(e: any) => {
                        handleFieldChange(
                          "doesTrackTardies",
                          e ? e.target.checked : false
                        );
                      }}
                      color="primary"
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormControlLabel
                  id="usesTimeClock"
                  className={classes.switch}
                  labelPlacement="start"
                  label="Use Time Clock"
                  control={
                    <Switch
                      checked={model.usesTimeClock}
                      name="usesTimeClock"
                      onChange={(e: any) => {
                        handleFieldChange(
                          "usesTimeClock",
                          e ? e.target.checked : false
                        );
                      }}
                      color="primary"
                    />
                  }
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </CardContent>
    </Card>
  );
};

export default ProgramVersionAttendanceDetails;
