import { call, Effect, put, select, takeLatest } from 'redux-saga/effects';
import { actions as licenseActions, types as licenseTypes, selectors as licenseSelectors } from '../../store/setup/stateWiseLicense';
import * as licenseAPI from '../../api/setup/campus/stateWiseLicense';

function* fetchConfigurations(action: ReturnType<typeof licenseActions.fetchRecord>): IterableIterator<Effect> {
	const { success, failure } = licenseActions.saga.fetchRecord;
	try {
		const response = yield call(licenseAPI.getCampusStateWiseLicense, action.data);
		if (response) {
			yield put(success((response as any)));
		}
	}
	catch (err) {
		yield put(failure((err as Error)?.message ?? 'Unknown Error Occured'));
	}
}

function* saveConfigurations(action: ReturnType<typeof licenseActions.createLicense>): IterableIterator<Effect> {
	const { success, failure } = licenseActions.saga.createLicense;
	try {
		const response = yield call(licenseAPI.addCampusStateWiseLicense, action.data);
		if (response) {
			yield put(success((response as any)));
		}
	}
	catch (err) {
		yield put(failure((err as Error)?.message ?? 'Unknown Error Occured'));
	}
}

function* deleteLicense(action: ReturnType<typeof licenseActions.deleteLicense>): IterableIterator<Effect> {
	const { success, failure } = licenseActions.saga.deleteLicense;
	try {
		const response = yield call(licenseAPI.deleteLicense, action.data);
		if (response) {
			yield put(success((response as any)));
		}
	}
	catch (err) {
		yield put(failure((err as Error)?.message ?? 'Unknown Error Occured'));
	}
}

function* realoadConfigurations(_action: any): IterableIterator<Effect> {
	const campusId = yield select(licenseSelectors.campusId);
	yield put(licenseActions.fetchRecord(campusId ?? ''));
}

export default function* rootAdHocSaga(): IterableIterator<Effect> {
	yield takeLatest(licenseTypes.fetchRecord, fetchConfigurations);
	yield takeLatest(licenseTypes.createLicense, saveConfigurations);
	yield takeLatest(licenseTypes.saga.createLicense.success, realoadConfigurations);
	yield takeLatest(licenseTypes.deleteLicense, deleteLicense);
	yield takeLatest(licenseTypes.saga.deleteLicense.success, realoadConfigurations);
}