import * as React from "react";
import { useRef } from "react";
import ineligibilityReason from "../../constants/Placement/inEligibilityAutoComplete";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { FilterParams } from "../../interfaces/common/FilterParams";


const IneligibilityReasonAutoComplete = (props: FilterParams) => {
	const inputRef: any = useRef(null);

	const ineligibilityReasonOnChange = (e: any) => {
		if (props.filterHandle) {
			props.filterHandle(e);
		}
	};

	return (
		<div>
			<ApiAutoComplete
				config={ineligibilityReason}
				classes={{
					option: "text-black"
				}}
				label={props.label ? props.label : "IneligibilityReason"}
				onChange={(e: any) => {
					ineligibilityReasonOnChange(e);
				}}
				multiple={false}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
				inputRef={props.inputRef ? props.inputRef : inputRef}
				error={props.error}
				helperText={props.helperText}
				inputName={props.name}
			></ApiAutoComplete>
		</div>
	);
};
export default IneligibilityReasonAutoComplete;
