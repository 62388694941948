import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Switch,
} from "@material-ui/core";
import React, { useState } from "react";
import { FieldError, FormContext, useForm } from "react-hook-form";
import ProgressSaveButton from "../../_Layout/Buttons/ProgressSaveButton";
import ProgramsAutoComplete from "../ProgramsAutoComplete";
import { useSelector } from "react-redux";
import { system } from '../../../constants/system/systemConstants';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {
      width: "95%",
    },
    text: {
      padding: theme.spacing(1),
    },
    programVersionText: {
      paddingLeft: theme.spacing(3),
    },
    dialogHeader: {
      backgroundColor: theme.palette.site.secondary,
      "& .MuiTypography-root": {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: "12pt",
      },
    },
    saveButton: {
      width: 90,
    },
    switchLabel: {
      paddingTop: theme.spacing(2) + "px !important",
    },
    content: {
      marginBottom: theme.spacing(1),
    },
  })
);

type ProgramAndProgramVersionAutoCompleteModalProps = {
  open: any;
  setOpen: any;
  addNew: any;
  setNewProgramVersionProgramId?: any;
  setIsProgramVersion?: (val: boolean) => void;
  setIsCreditHour?: (val: boolean) => void;
  // isProgramVersion: boolean;
};

const ProgramAndProgramVersionAutoCompleteModal = (
  props: ProgramAndProgramVersionAutoCompleteModalProps
) => {
  const classes = useStyles({});
  const data = useForm<any>({ mode: "onBlur" });
  const { handleSubmit } = data;
  const [programVersionChecked, setProgramVersionChecked] = useState<boolean>(false);
  const [creditHourChecked, setCreditHourChecked] = useState<boolean>(false);
  const handleClose = () => {
    props.setOpen(false);
  };
  const [programId, setProgramId] = useState<string | undefined>(undefined);

  const appSettings = useSelector(
		(state: any) => { return state.settings.settings }
	);
	const getEnableClockHourCreditHour = () => {
		const setting = appSettings[system.settings.enableClockHourCreditHour] as string;		
		let allow = false;
		if (setting) {
			allow = (setting.toLowerCase() === 'true');
		}		
		return allow;
	};
	const enableClockHourCreditHour = getEnableClockHourCreditHour();
  
  const onSubmit = (d: any) => {
    data.triggerValidation().then((validation: any) => {
      if (validation) {
        props.addNew();
        if (props.setIsProgramVersion) {
          props.setIsProgramVersion(programVersionChecked);
        }
        props.setOpen(false);
      }
    });
  };
  return (
    <FormContext {...data}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
          >
            <DialogTitle
              id="form-dialog-title"
              className={classes.dialogHeader}
            >
              Add Program/Program Version
            </DialogTitle>
            <DialogContent className={classes.content}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={3} className={classes.switchLabel}>
                  Program
                </Grid>
                <Grid item xs={3}>
                  <Switch
                    checked={programVersionChecked}
                    onChange={(e: any) => {
                      let isProgramVersionChecked = !programVersionChecked;
                      setProgramVersionChecked(isProgramVersionChecked);
                      setCreditHourChecked(false);
                      if (props.setIsCreditHour) {
                        props.setIsCreditHour(false);
                      }

                      if (props.setIsProgramVersion) {
                        props.setIsProgramVersion(isProgramVersionChecked);
                      }
                      if (isProgramVersionChecked === false) {
                        setProgramId(undefined);
                        props.setNewProgramVersionProgramId(undefined);
                        data.reset();
                      }
                    }}
                    name="programProgramVersionSwitch"
                    color="primary"
                  />
                </Grid>
                <Grid item xs={6} className={classes.switchLabel}>
                  Program Version
                </Grid>
              </Grid>
              {!programVersionChecked && enableClockHourCreditHour && (
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={6} className={classes.switchLabel}>
                    Is Credit Hour ?
                  </Grid>
                  <Grid item xs={3}>
                    <Switch
                      checked={creditHourChecked}
                      name="isCreditHour"
                      onChange={(e: any) => {
                        let isCreditHourChecked = !creditHourChecked;
                        setCreditHourChecked(isCreditHourChecked);
  
                         if (props.setIsCreditHour) {
                          props.setIsCreditHour(isCreditHourChecked);
                        }
                      }}
                      color="primary"
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                  {programVersionChecked && (
                    <ProgramsAutoComplete
                      id="newProgramVersionProgramId"
                      name="newProgramVersionProgramId"
                      valueFilter={
                        programId
                          ? {
                              key: "value",
                              values: [programId],
                            }
                          : undefined
                      }
                      includeCreditPrograms={true}
                      label={"Program for Program Version*"}
                      filterHandle={(v: any) => {
                        if (props.setIsCreditHour) {
                          props.setIsCreditHour(v ? v.isCredit : null);
                        }
                        setProgramId(v ? v.value : null);
                        props.setNewProgramVersionProgramId(v);
                        data.setValue(
                          "newProgramVersionProgramId",
                          v ? v.value : null
                        );
                      }}
                      error={!!data.errors.newProgramVersionProgramId}
                      inputRef={data.register("newProgramVersionProgramId", {
                        validate: () => {
                          return !programVersionChecked ||
                            (programId !== "" && programId !== undefined)
                            ? true
                            : "Required for Program Version";
                        },
                      })}
                      helperText={
                        data.errors["newProgramVersionProgramId"]
                          ? (
                              data.errors[
                                "newProgramVersionProgramId"
                              ] as FieldError
                            ).message
                          : undefined
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={1}></Grid>
                <Grid
                  item
                  container
                  xs={4}
                  alignContent="center"
                  alignItems="center"
                  justify="center"
                >
                  <ProgressSaveButton
                    text="New"
                    type="button"
                    onClick={(e: any) => {
                      onSubmit(e);
                    }}
                    loading={false}
                    disabled={false}
                    buttonClassName={classes.saveButton}
                  ></ProgressSaveButton>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid
                  item
                  container
                  xs={4}
                  alignContent="center"
                  alignItems="center"
                  justify="center"
                >
                  <Button
                    onClick={handleClose}
                    color="secondary"
                    variant="contained"
                    type="button"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>{" "}
        </div>
      </form>
    </FormContext>
  );
};

export default ProgramAndProgramVersionAutoCompleteModal;
