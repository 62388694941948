import React from 'react';
import {
    makeStyles,
    createStyles,
    Grid,
    IconButton,
    Button,
    Card,
    CardContent,
    CardActions,
    CircularProgress
} from '@material-ui/core';
import CopyIcon from '@material-ui/icons/FileCopyOutlined'
import ICourseDetails from '../../../../../../../interfaces/setup/academics/ProgramDefinition/ICourseDetails';
import InputField from '../../../../../../../components/_Layout/Inputs/InputField';
import CustomCardTitle from '../../../../../../../interfaces/common/card/CustomCardTitle';
import { FieldError, useForm, FormContext } from 'react-hook-form';
import { InputType } from '../../../../../../../constants/uiConstants/inputConstants';
import StatusAutoComplete from '../../../../../../../components/AutoComplete/StatusAutoComplete';
import MultiCampusAutoComplete from '../../../../../../../components/AutoComplete/MultiCampusAutoComplete';
import GradePolicyAutocomplete from '../../../../../../../components/AutoComplete/GradePolicyAutoComplete';
import CourseGradeBook from './CourseGradeBook';
import ICourse from '../../../../../../../interfaces/setup/academics/ProgramDefinition/ICourse';
import { CustomSnackBarProps } from '../../../../../../../interfaces/common/CustomSnackBarProps';
import ProgressSaveButton from '../../../../../../../components/_Layout/Buttons/ProgressSaveButton';
import { UpsertCourse, GetCourseDetails, CopyCourse } from '../../../../../../../api/setup/academics/courses';
import { EmptyGuid } from '../../../../../../../utils/constants';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: any) =>
    createStyles({
        cardTitle: {
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            backgroundColor: theme.palette.site.secondary,
            padding: theme.spacing(0)
        },
        headerText: {
            float: 'left',
            padding: theme.spacing(1.5),
        },
        cardContent: {
            padding: theme.spacing(1, 3),
            height: '100%',
        },
        saveButton: {
            width: 90,
        },
        table: {
            width: '100%',
        },
        iconColor: {
            color: theme.palette.black
        },
        searchField: {
            '&::placeholder': {
                color: theme.palette.black
            }
        },
        selectionCheckBox: {
            padding: theme.spacing(0)
        },
        preLoaderCell: {
            textAlign: 'center',
            padding: theme.spacing(1)
        },
        dialogCloseButton: {
            float: "right",
        },
        courseSelectionCell: {
            color: theme.palette.paperSummarySchedulerTitle,
            cursor: 'pointer',
            fontWeight: 500
        },
        alignCenter: {
            textAlign: "center",
        }
    })
);

type CourseDetailsProps = {
    selectedCourse?: ICourse;
    newCourse: () => void;
    showSnackBar: (param: CustomSnackBarProps) => void;
    refreshList: () => void;
    setConfirmationDialog: (param: { onOk?: () => (void | Promise<void>); open: boolean; onCancel?: () => (void | Promise<void>); }) => void;
};

const CourseDetails = (
    props: Readonly<CourseDetailsProps>
) => {
    const classes = useStyles({});
    const [model, setModel] = React.useState<ICourseDetails>({ courseId: props.selectedCourse?.courseId });
    const data = useForm<ICourseDetails>({ mode: "onBlur", defaultValues: model });
    const [repeatedExamPolicySelected, selectRepeatedExamPolicy] = React.useState<string>();
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleFieldChange = (fieldId: keyof ICourseDetails, value: any) => {
        let updatedModel = model;
        (updatedModel as any)[fieldId] = value;
        setModel({ ...updatedModel });
        data.setValue(fieldId, value);
        data.triggerValidation(fieldId);
    };

    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );

    const { handleSubmit } = data;

    const saveCourseDetails = async (confirm?: boolean) => {
        if (!loading) {
            setLoading(true);
            if (!confirm && model.courseId !== EmptyGuid) {
                props.setConfirmationDialog({ open: true, onOk: () => saveCourseDetails(true), onCancel: () => setLoading(false) })
                return;
            }
            try {
                const newDetails = await UpsertCourse(model);
                props.showSnackBar({
                    variant: "success",
                    showSnackBar: true,
                    messageInfo: newDetails.message,
                });
                data.reset(newDetails.courseDetails);
                setModel(newDetails.courseDetails);
                props.refreshList();
            }
            catch (err) {
                props.showSnackBar({
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: (err as Error).message,
                });
            }
            setLoading(false);
        }
    }

    const copyCourse = async () => {
        if (!loading) {
            setLoading(true);
            try {
                const newDetails = await CopyCourse(model.courseId ?? EmptyGuid);
                data.reset(newDetails.details);
                setModel(newDetails.details);
                props.showSnackBar({
                    variant: "success",
                    showSnackBar: true,
                    messageInfo: newDetails.message,
                });
                props.refreshList();
            }
            catch (err) {
                props.showSnackBar({
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: (err as Error).message,
                });
            }
            setLoading(false);
        }
    }

    const getCourseDetails = async () => {
        if (!loading && props.selectedCourse && props.selectedCourse.courseId) {
            setLoading(true);
            if (props.selectedCourse.courseId !== EmptyGuid) {
                try {
                    const newDetails = await GetCourseDetails(props.selectedCourse.courseId);
                    data.reset(newDetails);
                    setModel(newDetails);
                }
                catch (err) {
                    props.showSnackBar({
                        variant: "error",
                        showSnackBar: true,
                        messageInfo: (err as Error).message,
                    });
                }
            }
            else {
                setModel({ courseId: props.selectedCourse.courseId, campusId: userSelectedCampus });
                data.reset({ courseId: props.selectedCourse.courseId, campusId: userSelectedCampus });
            }
            setLoading(false);
        }
    }

    const cancel = () => {
        if (data.formState.dirty) {
            if (model.courseId === EmptyGuid) {
                props.newCourse();
            } else {
                getCourseDetails();
            }
        }
    }

    React.useEffect(() => {
        getCourseDetails();
    }, [props.selectedCourse]);

    return (
        <Card square={true} elevation={2} >
            <CustomCardTitle title='Course Details'></CustomCardTitle>
            <CardContent>
                <Grid container direction="column" spacing={8} wrap="nowrap">
                    <Grid
                        item
                        alignContent='flex-start'
                        alignItems='flex-start'
                        justify='flex-start'
                    >
                        <FormContext {...data}>
                            <form onSubmit={handleSubmit(() => saveCourseDetails(false))}>
                                {(!loading && <React.Fragment>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item xs={10} sm={10} md={8}>
                                            <InputField
                                                type={InputType.TEXT}
                                                key="courseTitle"
                                                id="courseTitle"
                                                label="Course *"
                                                name="courseTitle"
                                                defaultValue={model.courseTitle}
                                                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                    handleFieldChange("courseTitle", e.target.value);
                                                }}
                                                error={!!data.errors.courseTitle}
                                                inputRef={data.register({
                                                    required: 'Course is required.',
                                                })}
                                                helperText={
                                                    data.errors.courseTitle
                                                        ? (data.errors.courseTitle as FieldError).message
                                                        : undefined
                                                }
                                            />
                                        </Grid>
                                        {model.courseId && model.courseId != EmptyGuid &&
                                            <Grid item xs={2}>
                                                <IconButton onClick={copyCourse}>
                                                    <CopyIcon className={classes.iconColor} />
                                                </IconButton>
                                            </Grid>}
                                    </Grid>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item xs={10} sm={10} md={8}>
                                            <StatusAutoComplete
                                                id="statusId"
                                                name="statusId"
                                                label="Status *"
                                                valueFilter={
                                                    model.statusId
                                                        ? {
                                                            key: "value",
                                                            values: [model.statusId],
                                                        }
                                                        : undefined
                                                }
                                                filterHandle={(v: any) => {
                                                    handleFieldChange("statusId", v ? v.value : null);
                                                }}
                                                error={!!data.errors.statusId}
                                                inputRef={data.register({ required: "Status is required." })}
                                                helperText={
                                                    data.errors.statusId
                                                        ? (data.errors.statusId as FieldError).message
                                                        : undefined
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item xs={12} sm={12} md={8}>
                                            <MultiCampusAutoComplete label="Campus *" multiple={false}
                                                id="campusId"
                                                name="campusId"
                                                valueFilter={
                                                    model.campusId
                                                        ? {
                                                            key: "value",
                                                            values: [model.campusId],
                                                        }
                                                        : undefined
                                                }
                                                filterHandle={(v: any) => {
                                                    handleFieldChange(
                                                        "campusId",
                                                        v ? v.value : null
                                                    );
                                                }}
                                                error={!!data.errors.campusId}
                                                inputRef={data.register({ required: "Campus is required." })}
                                                helperText={
                                                    data.errors.campusId
                                                        ? (data.errors.campusId as FieldError).message
                                                        : undefined
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item xs={12} sm={12} md={8}>
                                            <InputField
                                                type={InputType.NUMBER}
                                                decimal
                                                key="minimumScore"
                                                id="minimumScore"
                                                label="Minimum Score"
                                                name="minimumScore"
                                                defaultValue={model.minimumScore}
                                                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                    handleFieldChange("minimumScore", e.target.value);
                                                }}
                                                error={!!data.errors.minimumScore}
                                                inputRef={data.register()}
                                                helperText={
                                                    data.errors.minimumScore
                                                        ? (data.errors.minimumScore as FieldError).message
                                                        : undefined
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8}>
                                            <GradePolicyAutocomplete
                                                id="repeatedExamPolicyId"
                                                name="repeatedExamPolicyId"
                                                label="Repeated Exams Policy"
                                                valueFilter={
                                                    model.repeatedExamPolicyId
                                                        ? {
                                                            key: "value",
                                                            values: [model.repeatedExamPolicyId],
                                                        }
                                                        : undefined
                                                }
                                                filterHandle={(v: any) => {
                                                    handleFieldChange("repeatedExamPolicyId", v ? v.value : null);
                                                    selectRepeatedExamPolicy((v ? v.text : undefined));
                                                }}
                                                error={!!data.errors.repeatedExamPolicyId}
                                                inputRef={data.register({  })}
                                                helperText={
                                                    data.errors.repeatedExamPolicyId
                                                        ? (data.errors.repeatedExamPolicyId as FieldError).message
                                                        : undefined
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </React.Fragment>)
                                    ||
                                    <div className={classes.alignCenter}><CircularProgress /></div>}
                            </form>
                        </FormContext>
                    </Grid>
                    <Grid
                        item
                        alignContent='flex-start'
                        alignItems='flex-start'
                        justify='flex-start'
                    >
                        <Grid container direction='row' spacing={1} wrap="nowrap">
                            <Grid
                                item
                                alignContent='flex-start'
                                alignItems='flex-start'
                                justify='flex-start'
                            >
                                <ProgressSaveButton
                                    text="Save"
                                    onClick={handleSubmit(() => saveCourseDetails(false))}
                                    loading={loading}
                                    disabled={false}
                                    buttonClassName={classes.saveButton}
                                    size="small"
                                ></ProgressSaveButton>
                            </Grid>
                            <Grid
                                item
                                alignContent='flex-start'
                                alignItems='flex-start'
                                justify='flex-start'
                            >
                                <Button
                                    disabled={loading}
                                    onClick={cancel}
                                    size='small'
                                    color='primary'
                                    variant='contained'
                                    type='button'
                                >Cancel</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {model.courseId && model.courseId != EmptyGuid && <Grid
                        item
                        alignContent='flex-start'
                        alignItems='flex-start'
                        justify='flex-start'
                    >
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12}>
                                <CourseGradeBook setConfirmationDialog={props.setConfirmationDialog} showSnackBar={props.showSnackBar} campusId={model.campusId} courseId={model.courseId} repeatedExamPolicy={repeatedExamPolicySelected} />
                            </Grid>
                        </Grid>
                    </Grid>}
                </Grid>
            </CardContent>
            <CardActions>

            </CardActions>
        </Card>
    );
};

export default CourseDetails;
