import * as React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";

const config: ApiAutoCompleteConfigItem = {
	acId: "distanceEducationStatus",
	cacheTime: 5,
	route: "/SystemCatalog/DistanceEducationStatus/GetDistanceEdByCampus",
	requestType: "GET",
	body: null,
	hasUrlParams: true,
	useCache: true,
	storeInCache: true,
	isCampusSpecific: true,
	isUserSpecific: false,
	loadOnRender: true,
	defaultSelectedFirstItem: false,
	optionsMappedFx: (data: Array<any>) => {
		return data;
	}
};

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& label.Mui-focused": {
				color: theme.palette.primary.white
			}
		}
	})
)(TextField);

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {}
	})
);

const DistanceEducationStatusAutoComplete = (props: FilterParams) => {
	const classes = useStyles({});
	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const onChange = (e: any) => {
		if (props.filterHandle) {
			props.filterHandle(e);
		}
	};

	return (
		<div>
			<ApiAutoComplete
				config={config}
				loading={userSelectedCampus ? false : true}
				reqParams={{ campusId: userSelectedCampus }}
				classes={{
					option: "text-black"
				}}
				label="Distance Ed. Status"
				onChange={(e: any) => {
					onChange(e);
				}}
				multiple={props.multiple || false}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
				showIncludeAllValue={props.showIncludeAllValue}
				chipSize={props.chipSize}
				maxTags={props.maxTags}
				includeAllValue={props.includeAllValue ? props.includeAllValue : false}
			></ApiAutoComplete>
		</div>
	);
};
export default DistanceEducationStatusAutoComplete;
