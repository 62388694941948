import React, { useEffect } from "react";
import {
  createStyles,
  makeStyles,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  Theme,
  CircularProgress,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { Card } from "@material-ui/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as StudentGradesApi from "../../../../src/api/student/academics/StudentGradesApi";
import { StudentGrade } from "../../../interfaces/student/academics/StudentGrade";
import { useFormContext, FieldError } from "react-hook-form";
import AwardsTypeAutoComplete from "../../../components/AutoComplete/AwardsTypeAutoComplete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "calc(100% - 10px)",
      width: "100%",
      overflow: "auto",
    },
    cardWrapper: {
      maxHeight: "50%",
      overflow: "auto",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
      height: "100%",
    },
    tableWrapper: {
      flexGrow: 1,
      overFlowY: "auto",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
      "& ::-webkit-inner-spin-button, ::-webkit-outer-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
        MozAppearance: "textfield",
      },
    },
    tableRowWrapper: {
      display: "table",
      width: "100%",
      tableLayout: "fixed",
    },
    gridHeader: {
      backgroundColor: theme.palette.secondary.dark,
      height: "50px",
      display: "table",
      width: "100%",
      tableLayout: "fixed",
    },
    tableBodyWrapper: {
      height: "calc(100% - 75px)",
      overflowY: "auto",
      display: "block",
    },
    datePicker: {
      "& .MuiIconButton-root": {
        padding: theme.spacing(0.8),
      },
    },
    paddedRight: { paddingRight: theme.spacing(10) },
    headerFont: {
      background: "	rgb(243,240,239)",
    },
    progressFormat: {
      position: "fixed",
      top: "35%",
      left: "60%",
      //transform: "translate(-50%,-35%)",
    },
  })
);

type Order = "asc" | "desc";

function desc<T>(a: T, b: T, orderBy: keyof T) {
  const aa = a[orderBy];
  const bb = b[orderBy];
  if (aa === bb) {
    return 0;
  } else if (aa === null) {
    return 1;
  } else if (bb === null) {
    return -1;
  } else {
    if (orderBy == "score") {
      let aas = Number(aa);
      let bbs = Number(bb);
      if (bbs < aas) {
        return -1;
      }
      if (bbs > aas) {
        return 1;
      }
      return 0;
    } else if (orderBy == "dateCompleted") {
      let aad = new Date(String(aa ?? new Date(0)));
      let bbd = new Date(String(bb ?? new Date(0)));
      if (bbd < aad) {
        return -1;
      }
      if (bbd > aad) {
        return 1;
      }
      return 0;
    } else return aa < bb ? 1 : -1;
  }
}

interface AwardTypeMapping {
  AwardTypeDescription?: string;
  EntityCode?: string;
  EntityDescription?: string;
  EntityHas9010Mapping?: string;
  EntityId?: string;
  EntityType?: string;
  EntityTypeEnum?: string;
  IsActive?: boolean;
  IsTitleIV?: string;
  MappingType9010Description?: string;
  MappingType9010Id?: string;
}

interface IRowDetails {
  awardTypeDescription?: string;
  entityCode?: string;
  entityDescription?: string;
  entityHas9010Mapping?: string;
  entityId?: string;
  entityType?: string;
  entityTypeEnum?: string;
  isActive?: boolean;
  isTitleIV?: string;
  mappingType9010Description?: string;
  mappingType9010Id?: string;
  isStatusLoading?: boolean;
}

interface IAwardTypes {
  awardType9010Id?: any;
  // Name?: any; //API call does not include
  description?: any;
  displayOrder?: any;
  // StatusId?: any; //API call does not return
}

type gradesTableProps = {
  setGradesData: any;
  gradesData: any;
  setSnackBar: any;
  setConfirmationModal: any;
  transferGradesForGrid: any;
  onDetailsChange: (newDetails: any[]) => void;
};

export default function EnhancedTable(props: gradesTableProps) {
  const { onDetailsChange } = props;

  const addDetail = (detail: any) => {
    const newDetails = [...rows];
    onDetailsChange(newDetails);
  };

  const userSelectedCampus: string = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const classes = useStyles({});
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] =
    React.useState<keyof StudentGrade>("courseDescription");

  const data = useFormContext();
  const [selected, setSelected] = React.useState<string[]>([]);
  const [dense, setDense] = React.useState(false);
  let d = new Date();
  const [startDate, setStartDateState] = useState<Date | null>(d);
  const [searchFilter, setsearchFilter] = useState<string | null>("");
  const selectedEnrollment = useSelector(
    (state: any) => state.student.selectedEnrollment
  );

  const formDataHasValue = (
    fieldName: string,
    id: string | number
  ): boolean => {
    let arrayIndex: string = fieldName + "_" + id;
    return (
      data.getValues()[arrayIndex] !== undefined &&
      data.getValues()[arrayIndex] !== "" &&
      data.getValues()[arrayIndex] !== null
    );
  };

  const filterDataBySearch = (data: StudentGrade[]): StudentGrade[] => {
    if (searchFilter === "" || searchFilter === undefined) return data;

    let searchVal: string = searchFilter
      ? (searchFilter as string).toLowerCase()
      : "";
    return data.filter(function (service: StudentGrade) {
      return (
        service &&
        ((service.componentDescription &&
          service?.componentDescription?.toLowerCase().indexOf(searchVal) >
            -1) ||
          (service.courseDescription &&
            service?.courseDescription?.toLowerCase().indexOf(searchVal) >
              -1) ||
          (service.dateCompletedAsString &&
            service?.dateCompletedAsString?.indexOf(searchVal) > -1) ||
          (service.componentType &&
            service?.componentType?.toLowerCase().indexOf(searchVal) > -1))
      );
    });
  };

  const [rows, setRows] = useState<AwardTypeMapping[]>([]);
  const [details, setDetails] = useState<IRowDetails[]>([]);
  const [awardTypes, setAwardTypes] = useState<IAwardTypes[]>([]);

  const [campusId, setCampusId] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const refreshGridData9010 = (
    setRows: React.Dispatch<React.SetStateAction<AwardTypeMapping[]>>,
    setDetails: React.Dispatch<React.SetStateAction<IRowDetails[]>>
  ) => {
    setIsLoading(true);

    StudentGradesApi.Get9010Mapping(userSelectedCampus).then(
      (response: any) => {
        if (response) {
          let data = response.data.map((item: any) => ({
            AwardTypeDescription: item.awardTypeDescription,
            EntityCode: item.entityCode,
            EntityDescription: item.entityDescription,
            EntityHas9010Mapping: item.entityHas9010Mapping,
            EntityId: item.entityId,
            EntityType: item.entityType,
            EntityTypeEnum: item.entityTypeEnum,
            IsActive: item.isActive,
            IsTitleIV: item.isTitleIV,
            MappingType9010Description: item.mappingType9010Description,
            MappingType9010Id: item.mappingType9010Id,
          }));

          setRows(data);
          setIsLoading(false);

          const initialDetails = data.map((item: any) => ({
            mappingType9010Description: item.MappingType9010Description,
            awardTypeDescription: item.AwardTypeDescription,
            entityCode: item.EntityCode,
            entityDescription: item.EntityDescription,
            entityHas9010Mapping: item.EntityHas9010Mapping,
            entityId: item.EntityId,
            entityType: item.EntityType,
            entityTypeEnum: item.EntityTypeEnum,
            isActive: item.IsActive,
            isTitleIV: item.IsTitleIV,
            mappingType9010Id: item.MappingType9010Id,
            isStatusLoading: true,
          }));

          setDetails(initialDetails);
        }
      },
      (exception: any) => {
        console.error(exception);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    refreshGridData9010(setRows, setDetails);
  }, []);

  React.useEffect(() => {
    refreshGridData9010(setRows, setDetails);
  }, [userSelectedCampus]);

  const [selectedAwardTypeId, setSelectedAwardTypeId] = useState("");
  const [selectedAwardType, setSelectedAwardType] = useState(null);
  const [isLoadingAwards, setIsLoadingAwards] = useState<boolean>(false);

  const getAwardTypes = (
    setAwardTypes: React.Dispatch<React.SetStateAction<IAwardTypes[]>>
  ) => {
    setIsLoading(true);

    StudentGradesApi.Get9010AwardTypes().then(
      (response: any) => {
        if (response) {
          let data = response.data.map((item: any) => ({
            awardType9010Id: item.id,
            description: item.description,
            displayOrder: item.displayOrder,
          }));

          setAwardTypes(data);
          setIsLoadingAwards(false);
        }
      },
      (exception: any) => {
        console.error(exception);
        setIsLoadingAwards(false);
      }
    );
  };

  useEffect(() => {
    getAwardTypes(setAwardTypes);
  }, []);

  React.useEffect(() => {
    getAwardTypes(setAwardTypes);
  }, [userSelectedCampus]);

  const triggerRowValidation = (id: any) => {
    data.triggerValidation(["score_" + id, "dateCompleted_" + id]);
  };

  const handleFieldChange = (
    index: number,
    fieldId: keyof IRowDetails,
    value: any
  ) => {
    const updatedDetails = [...details];
    if (!updatedDetails[index]) {
      updatedDetails[index] = {} as IRowDetails;
    }

    const tempAwardId = awardTypes.find(
      (option) => option.description === value
    )?.awardType9010Id;

    updatedDetails[index][fieldId] = value;
    updatedDetails[index]["mappingType9010Id"] = tempAwardId;

    setDetails(updatedDetails);
    addDetail(details);

    const updatedRows = [...rows];
    if (!updatedRows[index]) {
      updatedRows[index] = {} as AwardTypeMapping;
    }

    updatedRows[index]["MappingType9010Description"] = value;
    updatedRows[index]["MappingType9010Id"] = tempAwardId;

    setRows(updatedRows);
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Paper className={classes.root}>
      <Table
        //aria-labelledby="tableTitle"
        //size="medium"
        aria-label="sticky table"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerFont}>Status</TableCell>
            <TableCell className={classes.headerFont}>Entity Type</TableCell>
            <TableCell className={classes.headerFont}>Code</TableCell>
            <TableCell className={classes.headerFont}>
              Fund Source Description
            </TableCell>
            <TableCell className={classes.headerFont}>Map 9010</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            //{details.map((row, index) => (
            <TableRow key={row.EntityId}>
              <TableCell className={classes.paddedRight}>
                {row.IsActive == true ? "Active" : "Inactive"}
              </TableCell>
              <TableCell className={classes.paddedRight}>
                {row.EntityType}
              </TableCell>
              <TableCell>{row.EntityCode}</TableCell>
              <TableCell>{row.EntityDescription}</TableCell>

              <TableCell style={{ minWidth: 300 }}>
                {
                  //If
                  // row.isStatusLoading
                  //(row.mappingType9010Description||row.mappingType9010Description=="")
                  // ?
                  //Then
                  <AwardsTypeAutoComplete
                    id="Name"
                    name="Name"
                    label="Select"
                    valueFilter={
                      row.MappingType9010Description
                        ? {
                            key: "value",
                            values: [
                              awardTypes.find(
                                (option) =>
                                  option.description ===
                                  row.MappingType9010Description
                              )?.awardType9010Id,
                            ],
                          }
                        : undefined
                    }
                    filterHandle={(v: any) => {
                      const newValue = v ? v.value : null;
                      handleFieldChange(
                        index,
                        "mappingType9010Description",
                        awardTypes.find(
                          (option) => option.awardType9010Id === newValue
                        )?.description
                      );
                    }}
                  />
                  // :
                  //Else
                  //<CircularProgress size={24} color="primary" />
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        {/* </div> */}
      </Table>
    </Paper>
  );
}
