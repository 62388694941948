import * as React from "react";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { makeStyles, createStyles } from "@material-ui/styles";
import BestPhone from "./BestPhone";
import BestEmail from "./BestEmail";
import BestAddress from "./BestAddress";
import { useSelector } from "react-redux";
import { StudentProfile } from "../../../../interfaces/student/profile/StudentProfile";
import { BestContactInfo } from "../../../../interfaces/student/profile/contactInfo/BestContactInfo";
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";
import { useForm, FormContext } from "react-hook-form";
import { Email } from "../../../../interfaces/student/profile/contactInfo/Email";
import { Address } from "../../../../interfaces/student/profile/contactInfo/Address";
import { Phone } from "../../../../interfaces/student/profile/contactInfo/Phone";
import * as EmailApi from "../../../../api/student/profle/contactInfo/EmailApi";
import * as PhonesApi from "../../../../api/student/profle/contactInfo/PhonesApi";
import * as AddressApi from "../../../../api/student/profle/contactInfo/AddressApi";
import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";
import { security } from "../../../../constants/Security/securityConstants";
import AccessManager from "../../../../components/security/AccessManager";
import { EmptyGuid } from "../../../../utils/constants";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.paper.background,
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2),
			},
		},
		"& .makeStyles-content": {
			backgroundColor: theme.palette.paper.background,
		},
		paperRoot: {
			padding: theme.spacing(2),
			width: "100%",
			minHeight: "100%",
			height: "95%",
			overflowX: "hidden",
			overflowY: "auto",
			flexDirection: "column",
			backgroundColor: theme.palette.paper.background,
		},
		form: {
			marginBottom: theme.spacing(2),
		},
		buttonColorPrimary: {
			backgroundColor: theme.palette.buttons.primary,
			color: theme.palette.primary.contrastText,
			"&:hover": {
				backgroundColor: theme.palette.primary.main,
			},
			textDecoration: "bold",
			padding: theme.spacing(1),
			whiteSpace: "nowrap",
		},
		buttonColorSecondary: {
			backgroundColor: theme.palette.site.secondary,
			"&:hover": {
				backgroundColor: theme.palette.secondary.main,
			},
			textDecoration: "bold",
			padding: theme.spacing(1),
			margin: theme.spacing(3),
			whiteSpace: "nowrap",
		},
		cardSeparator: {
			marginBottom: theme.spacing(1),
		},
		actionContainer: {
			marginTop: theme.spacing(3),
		},
	})
);

const ContactInfo = (props: any) => {
	const classes = useStyles({});
	const [showSnackBar, setShowSnackBar] = React.useState<boolean>(false);
	const [loaderState, setLoaderState] = React.useState<boolean>(false);
	const [messageInfo, setMessageInfo] = React.useState<string | undefined>(
		undefined
	);
	const [snackBarVariant, setSnackBarVariant] = React.useState<
		"success" | "error" | "warning" | "info"
	>("info");
	const methods = useForm<any>({
		mode: "onBlur",
	});
	const { handleSubmit } = methods;
	const selectedStudent: StudentProfile = useSelector(
		(state: any) => state.student
	);

	const [contactInfoData, setcontactInfoData] =
		React.useState<BestContactInfo | null>(null);
	const onSubmit = (data: any, e: any) => {
		if (contactInfoData) {
			contactInfoData.checkForLeadStatus = false;
			//email
			let emailObj = contactInfoData as Email;
			(emailObj as any)["IsShowOnLeadPage"] = (
				contactInfoData as any
			).emailIsShowOnLeadPage;
			setLoaderState(true);
			EmailApi.updateEmail(selectedStudent.leadId, {
				...emailObj,
				id: emailObj.emailId,
				isPreferred: true,
			} as any).then(
				(response: any | null) => {
					if (response) {
						setSnackBarVariant("success");
						setMessageInfo("Contact successfully updated.");
						setShowSnackBar(true);
					} else {
						setSnackBarVariant("error");
						setMessageInfo(
							"Email update could not be completed. Please try again."
						);
						setShowSnackBar(true);
					}
					setLoaderState(false);
				},
				(exception: any) => {
					setSnackBarVariant("error");
					setMessageInfo(
						"Email update could not be completed. Please try again."
					);
					setShowSnackBar(true);
					setLoaderState(false);
				}
			);
			//phone
			let phoneObj = contactInfoData as Phone;
			(phoneObj as any)["IsShowOnLeadPage"] = (
				contactInfoData as any
			).phoneIsShowOnLeadPage;
			phoneObj.phoneId = contactInfoData.phoneId;
			PhonesApi.updatePhone(selectedStudent.leadId, {
				...phoneObj,
				id: phoneObj.phoneId,
			} as any).then(
				(response: any | null) => {
					if (response) {
						setSnackBarVariant("success");
						setMessageInfo("Contact successfully updated.");
						setShowSnackBar(true);
					} else {
						setSnackBarVariant("error");
						setMessageInfo(
							"Phone update could not be completed. Please try again."
						);
						setShowSnackBar(true);
					}
				},
				(exception: any) => {
					setSnackBarVariant("error");
					setMessageInfo(
						"Phone update could not be completed. Please try again."
					);
					setShowSnackBar(true);
				}
			);
			//address

			let addressObj = contactInfoData as Address;
			(addressObj as any)["IsShowOnLeadPage"] = (
				contactInfoData as any
			).addressIsShowOnLeadPage;
			if (addressObj.address1 && addressObj.addressTypeId) {
				if (
					(addressObj as any).addressId &&
					(addressObj as any).addressId !== EmptyGuid
				) {
					AddressApi.updateAddress(selectedStudent.leadId, {
						...addressObj,
						id: addressObj.addressId,
					} as any).then(
						(response: any | null) => {
							if (response) {
								setSnackBarVariant("success");
								setMessageInfo("Contact successfully updated.");
								setShowSnackBar(true);
							} else {
								setSnackBarVariant("error");
								setMessageInfo(
									"Address update could not be completed. Please try again."
								);
								setShowSnackBar(true);
							}
						},
						(exception: any) => {
							setSnackBarVariant("error");
							setMessageInfo(
								"Address update could not be completed. Please try again."
							);
							setShowSnackBar(true);
						}
					);
				} else {
					AddressApi.createAddress(selectedStudent.leadId, {
						...addressObj,
						id: addressObj.addressId,
					} as any).then(
						(response: any | null) => {
							if (response) {
								setSnackBarVariant("success");
								setMessageInfo("Contact successfully updated.");
								setShowSnackBar(true);
								setBestAddress({
									...(response as any),
									addressId: response.id,
									addressIsShowOnLeadPage: (response as any).isShowOnLeadPage,
								});
							} else {
								setSnackBarVariant("error");
								setMessageInfo(
									"Address update could not be completed. Please try again."
								);
								setShowSnackBar(true);
							}
						},
						(exception: any) => {
							setSnackBarVariant("error");
							setMessageInfo(
								"Address update could not be completed. Please try again."
							);
							setShowSnackBar(true);
						}
					);
				}
			}
		}
	};
	const setBestEmail = (BestEmail: BestContactInfo) => {
		setcontactInfoData((prevState: BestContactInfo | null) => {
			return { ...prevState, ...BestEmail };
		});
	};
	const setBestPhone = (BestPhone: BestContactInfo) => {
		setcontactInfoData((prevState: BestContactInfo | null) => {
			return { ...prevState, ...BestPhone };
		});
	};

	const setBestAddress = (BestAddress: BestContactInfo) => {
		setcontactInfoData((prevState: BestContactInfo | null) => {
			return { ...prevState, ...BestAddress };
		});
	};
	return (
		<Paper className={classes.paperRoot} square={true}>
			<CustomSnackbar
				variant={snackBarVariant}
				message={messageInfo}
				open={showSnackBar}
				onClose={(event?: React.SyntheticEvent, reason?: string) => {
					setShowSnackBar(false);
				}}
			></CustomSnackbar>
			<FormContext {...methods}>
				<form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
					<div className={classes.cardSeparator}>
						<BestPhone isNewEnrollment={false} setPhoneModel={setBestPhone} />
					</div>
					<div className={classes.cardSeparator}>
						<BestEmail isNewEnrollment={false} setEmailModel={setBestEmail} />
					</div>
					<div>
						<BestAddress
							isNewEnrollment={false}
							setAddressModel={setBestAddress}
						/>
					</div>
					<div className={classes.actionContainer}>
						<AccessManager
							allowedPermissions={[
								security.permissions.student.updateContactInfo,
							]}
							renderNoAccess={() => (
								<ProgressSaveButton
									text="Save"
									onClick={() => {
										handleSubmit(onSubmit);
									}}
									loading={loaderState}
									disabled
								></ProgressSaveButton>
							)}
						>
							<ProgressSaveButton
								text="Save"
								onClick={() => {
									handleSubmit(onSubmit);
								}}
								loading={loaderState}
							></ProgressSaveButton>
						</AccessManager>
						<Link
							to={"/student/1/profile/contacts/all"}
							className={
								classes.buttonColorSecondary +
								" MuiButtonBase-root MuiButton-root MuiButton-text makeStyles-buttonColorPrimary-595 MuiButton-textSizeSmall MuiButton-sizeSmall"
							}
						>
							View All
						</Link>
					</div>
				</form>
			</FormContext>
		</Paper>
	);
};

export default ContactInfo;
