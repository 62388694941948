import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
	Typography,
	Card,
	CardContent
} from "@material-ui/core";
import DisplayField from "../DisplayField/DisplayField";
import DisplayLabel from "../../Label/DisplayLabel";
import PaymentPeriodChart from "./PaymentPeriodChart";
import TitleIVSAPChart from "./TitleIVSAPChart";
import AcademicSAPChart from "./AcademicSAPChart";
import * as quickInfoApi from "../../../api/student/quickInfo/quickInfoApi";
import { IProgramPaymentPeriodCard } from "../../../interfaces/student/quickInfo/IProgramPaymentPeriodCard";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		card: {
			margin: theme.spacing(1),
			marginTop: theme.spacing(2),
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingBottom: theme.spacing(1),
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2)
			}
		},
		cardTitle: {
			fontSize: 14,
			marginLeft: theme.spacing(1),
			marginTop: theme.spacing(1),
			color: theme.palette.text.secondary,
			fontWeight: theme.typography.fontWeightMedium
		}
	})
);

const PaymentPeriodSapCard = (props: any) => {
	const { className } = props;
	const selectedEnrollment = useSelector(
		(state: any) => state.student.selectedEnrollment
	);
	const classes = useStyles({});
	const [
		paymentPeriodAndSapData,
		setPaymentPeriodAndSapData
	] = useState<IProgramPaymentPeriodCard | null>(null);
	
	const GetProgramPaymentPeriodInformation = () => {
		if (selectedEnrollment && selectedEnrollment.stuEnrollId)
			quickInfoApi
				.GetProgramPaymentPeriodQuickInformation(selectedEnrollment.stuEnrollId)
				.then(response => {
					if (response) {
						setPaymentPeriodAndSapData(response.data.result);
					}
				});
	};
	useEffect(() => {
		GetProgramPaymentPeriodInformation();
	}, [selectedEnrollment]);

	return (
    <Card className={classes.card} elevation={2}>
      <Typography className={clsx(classes.cardTitle)}><Link to={"/student/1/sap/titleIVSAP"}>PAYMENT PERIODS & SAP</Link></Typography>
      <CardContent>
        {paymentPeriodAndSapData?.isCreditHour == false ? (
          <React.Fragment>
            <DisplayField
              labelText="Program Length"
              valueText={paymentPeriodAndSapData?.length + " Hours"}
              fieldType="text"
            ></DisplayField>
            <DisplayField
              labelText="Contract Hours"
              valueText={paymentPeriodAndSapData?.contractHours + " Hours"}
              fieldType="text"
            ></DisplayField>
            <DisplayField
              labelText="Academic Year Length"
              valueText={paymentPeriodAndSapData?.academicYearLength + " Hours"}
              fieldType="text"
            ></DisplayField>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <DisplayField
              labelText="Program Credits"
              valueText={paymentPeriodAndSapData?.programCredits + " Credits"}
              fieldType="text"
            ></DisplayField>
            <DisplayField
              labelText="Credits Academic Length"
              valueText={paymentPeriodAndSapData?.creditsInAcademicYear + " Credits"}
              fieldType="text"
            ></DisplayField>
          </React.Fragment>
        )}

        <DisplayLabel>Payment Periods</DisplayLabel>
        <PaymentPeriodChart
          ppData={paymentPeriodAndSapData?.paymentPeriods}
          isCreditHour={paymentPeriodAndSapData?.isCreditHour ?? false}
        ></PaymentPeriodChart>
        <DisplayLabel>
          {"Title IV SAP - " + paymentPeriodAndSapData?.titleIVSAPStatus}
        </DisplayLabel>
        <TitleIVSAPChart
          checkpointsData={paymentPeriodAndSapData?.titleIVSAPs}
          isCreditHour={paymentPeriodAndSapData?.isCreditHour ?? false}
        ></TitleIVSAPChart>
        <DisplayLabel>Academic SAP</DisplayLabel>
        <AcademicSAPChart
          checkpointsData={paymentPeriodAndSapData?.academicSAPs}
          isCreditHour={paymentPeriodAndSapData?.isCreditHour ?? false}
        ></AcademicSAPChart>
      </CardContent>
    </Card>
  );
};

export default PaymentPeriodSapCard;
