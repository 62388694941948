import {
  CustomHeadCell,
  Order,
} from "../../../../../../interfaces/common/Table/CustomHeadCell";
import CustomTableHeader from "../../../../../../components/Tables/CustomTableHeader";
import React from "react";
import {
  TableBody,
  makeStyles,
  createStyles,
  TableRow,
  TableCell,
  Switch,
  Link,
} from "@material-ui/core";
import * as TableFunctions from "../../../../../../utils/tableFunctions";

import { IScheduleGridRow } from "../../../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/Schedule/IScheduleGridRow";

type SchedulesGridProps = {
  programVersionId: string;
  setCurrentScheduleDetails: any;
  schedules: Array<IScheduleGridRow>;
  setNoChangeModel: any;
};
const useStyles = makeStyles((theme: any) =>
  createStyles({
    tableWrapper: {
      width: "100%",
      height: "calc(100% - 200px)",
      overflowY: "auto",
      paddingBottom: theme.spacing(2),
    },
    mainBody: {
      height: "100%",
    },
  })
);
const SchedulesGrid = (props: SchedulesGridProps) => {
  const classes = useStyles({});

  const headerCells: CustomHeadCell<IScheduleGridRow>[] = [
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Schedule",
      widthType: "Remainder",
    },
    {
      id: "active",
      numeric: false,
      disablePadding: false,
      label: "Active",
      widthType: "M",
    },
    {
      id: "useFlexTime",
      numeric: false,
      disablePadding: false,
      label: "Flexible Schedule",
      widthType: "M",
    },
    {
      id: "totalHours",
      numeric: false,
      disablePadding: false,
      label: "Total Hours",
      widthType: "M",
    },
  ];

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof IScheduleGridRow>(
    "description"
  );

  return (
    <div className={classes.tableWrapper}>
      <CustomTableHeader<IScheduleGridRow>
        cells={headerCells}
        defaultOrder={order}
        defaultOrderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        key={"tex"}
      />
      <TableBody className={classes.mainBody}>
        {TableFunctions.stableSort(
          props.schedules,
          TableFunctions.getSorting(order, orderBy) as any
        ).map((row: IScheduleGridRow, index: any) => {
          return (
            <React.Fragment>
              <TableRow key={"schedule_" + row.scheduleId}>
                <TableCell>
                  <Link
                    href="#"
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                      e.preventDefault();
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                      props.setCurrentScheduleDetails({ ...row });
                      let trackingModel = row;
                      props.setNoChangeModel({ ...trackingModel, programScheduleDetails: trackingModel.programScheduleDetails.map(x => Object.assign({}, x)) })
                    }}
                    variant="body2"
                  >
                    {row.description}
                  </Link>
                </TableCell>
                <TableCell>
                  <Switch
                    disabled
                    checked={row.active}
                    name={"active_" + row.scheduleId}
                    color="primary"
                  />
                </TableCell>
                <TableCell>
                  <Switch
                    disabled
                    checked={row.useFlexTime}
                    name={"flexibleSchedule_" + row.scheduleId}
                    color="primary"
                  />
                </TableCell>
                <TableCell>{row.totalHours}</TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>


    </div>
  );
};

export default SchedulesGrid;
