import API from "../../apiWrapper";
import { ICopyProgramVersion } from "../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/ICopyProgramVersionParams";

export const copyProgramVersion = (model: ICopyProgramVersion) => {
  return API()
    .post("AcademicRecords/CopyProgramVersion/CopyProgramVersion", model)
    .then(
      (res: any) => {
        return res;
      },
      (error: any) => {
        return error;
      }
    );
};
