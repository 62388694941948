import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CampusForCurrentUserAutoComplete from "../../../components/AutoComplete/CampusForCurrentUserAutoComplete";
import { FormControlLabel, Switch, Grid } from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import * as attendanceSummaryApi from "../../../api/reports/attendance/attendanceSummaryApi";
import { ReportOutput } from "../../../enums/ReportOutput";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import ProgramVersionAutoComplete from "../../../components/AutoComplete/ProgramVersionAutoComplete";
import StudentGroupsAutoComplete from "../../../components/AutoComplete/StudentGroupsAutoComplete";
import StudentNameBadgeIDFilter from '../../../../src/pages/attendance/StudentNameBadgeIDFilter'
import { EnrollmentAutoComplete } from "../../../components/AutoComplete/EnrollmentAutoComplete";
import { IEnrollmentSearchResult } from "../../../interfaces/student/enrollment/EnrollmentSearchResult";
import { IAttendanceSummaryReportParam } from "../../../interfaces/reports/attendance/AttendanceSummaryReportParam";
import { useSelector } from "react-redux";


const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(3),
            minHeight: "100%",
            height: "100%",
            maxWidth: "1050px",
            padding: theme.spacing(2),
        },
        "& .makeStyles-content": {
            backgroundColor: theme.palette.background.paper,
        },
        paperRoot: {
            padding: theme.spacing(3, 2),
            width: "100%",
            minHeight: "100%",
            height: "100%",
            overflowX: "hidden",
            overflowY: "auto",
            flexDirection: "column",
            backgroundColor: theme.palette.background.paper,
        },
        searchActions: {
            marginTop: theme.spacing(3),
            textAlign: "left",
        },
        startDateField: {
            width: "100%",
        },
        cardTitle: {
            fontSize: 17,
            color: theme.palette.primary.headerText,
            textDecoration: "bold",
            backgroundColor: theme.palette.secondary.dark,
            padding: theme.spacing(1, 1),
            marginBottom: theme.spacing(1),
        },
        headerText: {
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(0),
        },
        cardContent: {
            padding: theme.spacing(1, 3),
        }, switch: {
            display: "flex",
            alignItems: "flex-end",
            padding: theme.spacing(0, 0.5),
            height: theme.spacing(8.6),
        },
    })
);

const AttendanceSummary = (props: any) => {
    const { report } = props;
    let reportOverview = { report: report } as IReportOverview;
    const classes = useStyles({});
    const [preview, setPreview] = React.useState<any>();
    const [previewLoader, setPreviewLoader] = React.useState<boolean>();
    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );

    let weekEndDate = new Date();
    weekEndDate.setDate(weekEndDate.getDate() - 1);
    let weekStartDate = new Date();
    weekStartDate.setDate(weekStartDate.getDate() - 8);
    const data = useForm<any>({ mode: "onBlur" });
    const initialModel: IAttendanceSummaryReportParam = {
        campusId: userSelectedCampus,
        startDate: new Date(weekStartDate.getFullYear(), weekStartDate.getMonth(), weekStartDate.getDate()),
        endDate: new Date(weekEndDate.getFullYear(), weekEndDate.getMonth(), weekEndDate.getDate()),
        exportType: 1 as ReportOutput,
        studentGroup: [],
        programVersion: [],
        groupBy: "programversion",
        enrollments: [] as IEnrollmentSearchResult[],
    };
    const [model, setModel] = React.useState<IAttendanceSummaryReportParam>(initialModel);

    const handleFieldOnChange = (
        fieldId: string,
        value: any,
        mapFunction?: Function
    ) => {
        let updatedModel = model;
        let newValue = mapFunction ? mapFunction(value) : value;
        (updatedModel as any)[fieldId] = newValue;
        if (fieldId == "campusId") {
            (updatedModel as any)["programId"] = [];
            (updatedModel as any)["leadGroupIds"] = [];
        }
        setModel({ ...updatedModel });
    };

    const { handleSubmit } = data;

    const exportTypes: Array<DropDownListItem> = [
        { text: "PDF", id: "1" },
        { text: "Excel", id: "2" },
        { text: "Word", id: "3" },
    ];

    const groupOptions: Array<DropDownListItem> = [
        { text: "Program Version", id: "programversion" },
        { text: "Student Group", id: "studentgroup" },
        { text: "None", id: "none" },
    ];

    const onSubmit = async (isPreview: any) => {
        let params = (await getReportParameters()) as IAttendanceSummaryReportParam;
        const validation = await data.triggerValidation();
        if (validation) {
            if (isPreview) {
                setPreviewLoader(true);
                attendanceSummaryApi
                    .generateReport(params, isPreview)
                    .then((res: any) => {
                        if (res) {
                            res.arrayBuffer().then((buffer: any) => {
                                setPreview(buffer);
                                setPreviewLoader(false);
                            });
                        } else setPreviewLoader(false);
                    });
            } else await attendanceSummaryApi.generateReport(params);
        }
    };

    const exportTypeAutoComplete = {
        options: exportTypes,
        getOptionLabel: (option: DropDownListItem) => option.text
    };

    const groupOptionsAutoComplete = {
        options: groupOptions,
        getOptionLabel: (option: DropDownListItem) => option.text
    };

    const exportTypeOnChange = (e: any) => {
        let updatedModel = model;
        updatedModel.exportType = !!e ? parseInt(e.id) : null;
        setModel({ ...updatedModel });
    };

    const groupByOnChange = (e: any) => {
        let updatedModel = model;
        updatedModel.groupBy = !!e ? e.id : null;
        setModel({ ...updatedModel });
    };

    const filterHandler = (data: any) => {
        if (data) {
            setModel(data);
        } else {
            setModel(initialModel);
        }
    };
    const setProgramVersion = (PrgVersions: any) => {
        let newProgramVersion: string[] = [];
        if (PrgVersions !== null) {
            if (Array.isArray(PrgVersions)) {
                newProgramVersion = PrgVersions.map((gr: any) => gr.value);
            } else {
                newProgramVersion.push(PrgVersions.value);
            }
        }
        return newProgramVersion;
    };

    const setStudentGroup = (studentGroups: any) => {
        let newGroups: string[] = [];
        if (studentGroups !== null) {
            if (Array.isArray(studentGroups)) {
                newGroups = studentGroups.map((gr: any) => gr.value);
            } else {
                newGroups.push(studentGroups.value);
            }
        }
        return newGroups;
    };
    const getReportParameters = async () => {
        const success = await data.triggerValidation();
        if (model && success) {
            let startDate = model?.startDate ? model.startDate : undefined;
            let endDate = model?.endDate ? model.endDate : undefined;
            let params: IAttendanceSummaryReportParam = {
                campusId: model?.campusId ?? userSelectedCampus,
                startDate: startDate,
                endDate: endDate,
                exportType: model.exportType,
                studentGroup: model?.studentGroup ?? [],
                programVersion: model?.programVersion ?? [],
                enrollments: model?.enrollments ?? [],
                groupBy: model?.groupBy
            };
            return params;
        }
        return {} as IAttendanceSummaryReportParam;
    };

    const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
        const item = items.find((opt) => {
            if (opt.id == value) return opt;
        });
        return item || ({} as DropDownListItem);
    };

    let parameters = (
        <div className={classes.root}>
            <FormContext {...data}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container direction="row" spacing={1}>
                        <Grid item md={12} sm={12} xs={12} spacing={1}>
                            <EnrollmentAutoComplete
                                values={model.enrollments}
                                setValues={(values: IEnrollmentSearchResult[]) => {
                                    setModel({ ...model, enrollments: !!values && values.length > 0 ? [...values.map(a => a)] : [] })
                                }}
                            />
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    id="startDate"
                                    name="startDate"
                                    label="Start Date *"
                                    value={model.startDate ? model.startDate : null}
                                    onChange={(e: any, value: any) =>
                                        handleFieldOnChange("startDate", value ? value : undefined)
                                    }
                                    className={classes.startDateField}
                                    KeyboardButtonProps={{
                                        "aria-label": "report date",
                                    }}
                                    error={!!data.errors["startDate"]}
                                    innerRef={(ref: any) => {
                                        data.register("startDate", {
                                            required: true,
                                            validate: {
                                                beforeEndDate: (value) =>
                                                    new Date(value) < new Date(data.getValues()["endDate"]) ||
                                                    "Start Date must be before end date",
                                            },
                                        });
                                        data.setValue("startDate", model.startDate);
                                    }}
                                    helperText={
                                        data.errors["startDate"]
                                            ? (data.errors["startDate"] as FieldError).message
                                            : undefined
                                    }
                                    autoOk={true}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    id="endDate"
                                    name="endDate"
                                    label="End Date *"
                                    value={model.endDate ? model.endDate : null}
                                    onChange={(e: any, value: any) =>
                                        handleFieldOnChange("endDate", value ? value : null)
                                    }
                                    className={classes.startDateField}
                                    KeyboardButtonProps={{
                                        "aria-label": "end date",
                                    }}
                                    error={!!data.errors["endDate"]}
                                    innerRef={(ref: any) => {
                                        data.register("endDate", {
                                            required: true,
                                            validate: {
                                                beforeEndDate: (value) =>
                                                    new Date(value) > new Date(data.getValues()["startDate"]) ||
                                                    "End Date must be after Start date",
                                            },
                                        });
                                        data.setValue("endDate", model.endDate);
                                    }}
                                    helperText={
                                        data.errors["endDate"]
                                            ? (data.errors["endDate"] as FieldError).message
                                            : undefined
                                    }
                                    autoOk={true}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <ProgramVersionAutoComplete
                                selectAll={true}
                                name="programVersion"
                                campusId={model.campusId}
                                filterHandle={(value: any) =>
                                    handleFieldOnChange("programVersion", value, setProgramVersion)
                                }
                                valueFilter={
                                    model.programVersion
                                        ? {
                                            key: "value",
                                            values: model.programVersion,
                                        }
                                        : undefined
                                }
                                multiple={true}
                            />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <StudentGroupsAutoComplete
                                id="studentGroup"
                                name="studentGroup"
                                campusId={model.campusId}
                                filterHandle={(groups: any) => {
                                    handleFieldOnChange(
                                        "studentGroup",
                                        groups,
                                        setStudentGroup
                                    );
                                }}
                                label="Student Group"
                                multiple={true}
                                valueFilter={
                                    model?.studentGroup
                                        ? {
                                            key: "value",
                                            values: model?.studentGroup,
                                        }
                                        : undefined
                                }
                            />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Autocomplete
                                {...groupOptionsAutoComplete}
                                autoComplete
                                includeInputInList
                                value={!!model.groupBy ? groupOptions.find(m => m.id == (model.groupBy ?? '')) : null}
                                onChange={(e: any, v: any) => {
                                    groupByOnChange(v);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Group Option"
                                        name="groupBy"
                                        error={!!data.errors.groupBy}
                                        inputRef={data.register({ required: true })}
                                        helperText={
                                            data.errors.groupBy
                                                ? "Group Option is required."
                                                : undefined
                                        }
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Autocomplete
                                {...exportTypeAutoComplete}
                                autoComplete
                                includeInputInList
                                value={!!model.exportType ? exportTypes.find(m => m.id == (model.exportType ?? '').toString()) : null}
                                onChange={(e: any, v: any) => {
                                    exportTypeOnChange(v);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Export Type"
                                        name="exportType"
                                        error={!!data.errors.exportType}
                                        inputRef={data.register({ required: true })}
                                        helperText={
                                            data.errors.exportType
                                                ? "Export Type is required."
                                                : undefined
                                        }
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </form>
            </FormContext>
        </div>
    );
    reportOverview.parameters = parameters;

    return (
        <ReportOverview
            reportOverview={reportOverview}
            filterHandler={filterHandler}
            getReportParameters={getReportParameters}
            exportHandler={onSubmit}
            preview={preview}
            previewLoader={previewLoader}
        />
    );
};
export default AttendanceSummary;
