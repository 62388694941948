import * as React from 'react';
import {
    makeStyles,
    createStyles,
    Grid,
    Paper,
    Table,
    withStyles,
    TableCell,
    TableRow,
    TableContainer,
    TableHead,
    TableBody,
    IconButton,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
    DialogActions,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ICourse from '../../../../../../../interfaces/setup/academics/ProgramDefinition/ICourse';
import InputField from '../../../../../../../components/_Layout/Inputs/InputField';
import CourseDetails from './CourseDetails';
import { EmptyGuid } from '../../../../../../../utils/constants';
import { CustomSnackBarProps } from '../../../../../../../interfaces/common/CustomSnackBarProps';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from '../../../../../../../constants/actions.constants';
import ProgressSaveButton from '../../../../../../../components/_Layout/Buttons/ProgressSaveButton';
import { ImportComponents } from '../../../../../../../api/setup/academics/courses';
import ComponentTypesAutoCompleteConfig from '../../../../../../../constants/setup/academic/ProgramVersion/GradeBookComponentTypesAutoComplete';
import { autoCompleteConstants } from '../../../../../../../constants/actions.constants';

const StyledTableCell = withStyles((theme: any) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.site.secondary,
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            textAlign: 'left',
            boxShadow: '1px 1px 0px 0px rgba(0,0,0,0.2)',
            '&:first-child': {
                width: '42px',
                padding: theme.spacing(0, 1)
            }
        },
        body: {
            fontSize: 16,
            color: theme.palette.black,
            fontWeight: 400,
            cursor: 'pointer',
            textAlign: 'left',
            '&:first-child': {
                width: '42px',
                padding: theme.spacing(0, 1)
            }
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: any) =>
    createStyles({
        root: {
            '&:nth-of-type(even)': {
                backgroundColor: theme.palette.site.secondary,
            },
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.white,
            },
            '&:hover': {
                backgroundColor: theme.palette.site.secondary,
            }
        },
        body: {
            cursor: 'pointer'
        }
    }),
)(TableRow);

const useStyles = makeStyles((theme: any) =>
    createStyles({
        cardTitle: {
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            backgroundColor: theme.palette.site.secondary,
            padding: theme.spacing(0)
        },
        headerText: {
            float: 'left',
            padding: theme.spacing(1.5),
        },
        cardContent: {
            padding: theme.spacing(1, 3),
            height: '100%',
        },
        saveButton: {
            width: 90,
        },
        tableContainer: {
            maxHeight: '400px'
        },
        table: {
            width: '100%',
        },
        iconColor: {
            color: theme.palette.black
        },
        searchField: {
            '&::placeholder': {
                color: theme.palette.black
            }
        },
        selectionCheckBox: {
            padding: theme.spacing(0)
        },
        preLoaderCell: {
            textAlign: 'center',
            padding: theme.spacing(1)
        },
        dialogCloseButton: {
            float: "right",
        },
        courseSelectionCell: {
            color: theme.palette.paperSummarySchedulerTitle,
            cursor: 'pointer',
            fontWeight: 500
        },
        fileInput: {
            display: 'none'
        }
    })
);
type AddCoursePopUpProps = {
    open?: boolean;
    courses: ICourse[];
    isLoading: boolean;
    setLoading: (loading: boolean) => void;
    setCourses: () => void;
    handleClose: () => void;
    showSnackBar: (param: CustomSnackBarProps) => void
};

const AddCoursePopUp = (
    props: Readonly<AddCoursePopUpProps>
) => {
    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );
    const dispatch = useDispatch();
    const { open, handleClose, isLoading, courses } = props;
    const [importLogs, setImportLogs] = React.useState<Array<{ values: string; error: string }>>([]);
    const [importingComponents, setimportingComponents] = React.useState(false);
    const classes = useStyles({});
    const [searchText, setSearchText] = React.useState<string>('');
    const [selectedCourse, selectCourse] = React.useState<ICourse>({ courseId: EmptyGuid, courseTitle: '' });
    const [confirmationDialogue, setConfirmationDialogue] = React.useState<{ onOk?: () => void; open: boolean; onCancel?: () => void; }>({ open: false });
    const toDisplay = searchText
        && searchText.length > 2
        ? courses.filter((course) => course.courseId === searchText
            || course.courseTitle.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()))
        : courses;

    const newCourse = React.useCallback(() => {
        selectCourse({ courseId: EmptyGuid, courseTitle: '' });
    }, [selectCourse]);

    const download = (filename: string) => {
        var element = document.createElement('a');
        const text = ("Error, Description, Type, Status\n").concat(importLogs.map(m => `${m.error}, ${m.values}`).join(''));
        element.setAttribute('href', 'data:application/vnd.ms-excel;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setImportLogs([]);
        if (e && e.target && e.target.files && e.target.files.length === 1) {
            setimportingComponents(true);
            if (e.target.files.item(0)?.type !== 'application/vnd.ms-excel') {
                dispatch({
                    type: actionTypes.toasterConstants.SHOW_TOASTER,
                    data: {
                        message:
                            "Only csv file is allowed.",
                        showToaster: true,
                        variant: "error",
                    },
                });
            }
            else if ((e.target.files.item(0)?.size ?? 0) > 5242880) {
                dispatch({
                    type: actionTypes.toasterConstants.SHOW_TOASTER,
                    data: {
                        message:
                            "Max file size is 5MB.",
                        showToaster: true,
                        variant: "error",
                    },
                });
            }
            else {
                if (!!e.target.files.item(0) && e.target.files.item(0) !== null) {
                    try {
                        const result = await ImportComponents(userSelectedCampus, e.target.files.item(0));
                        setImportLogs(result.errorList);
                        const dta = { key: userSelectedCampus + '_' + ComponentTypesAutoCompleteConfig.acId };
                        dispatch({
                            type: autoCompleteConstants.SESSION_LOADING_AC_REMOVE_CACHE,
                            data: dta,
                        });
                        dispatch({
                            type: actionTypes.toasterConstants.SHOW_TOASTER,
                            data: {
                                message:
                                    result.message,
                                showToaster: true,
                                variant: result.errorList.length === 0 ? 'success' : 'warning',
                            },
                        });
                    }
                    catch (err) {
                        dispatch({
                            type: actionTypes.toasterConstants.SHOW_TOASTER,
                            data: {
                                message:
                                    err && (err as Error).message,
                                showToaster: true,
                                variant: "error",
                            },
                        });
                    }
                }
            }
            setimportingComponents(false);
        }

    }

    return (
        <React.Fragment>
            <Dialog
                PaperProps={{ square: true }}
                fullWidth={true}
                maxWidth="xl"
                open={open ?? false}
                onClose={handleClose}
                aria-labelledby="course-add-dialog-title"
            >
                <DialogTitle
                    className={classes.cardTitle}
                    disableTypography
                    id="course-add-dialog-title"
                >
                    <Typography className={classes.cardTitle}>
                        <div className={classes.headerText}>Courses</div>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            className={classes.dialogCloseButton}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {open &&
                        <Grid container direction="column" spacing={2} wrap="nowrap">
                            <Grid
                                item
                                alignContent="flex-start"
                                alignItems="flex-start"
                                justify="flex-start"
                            >
                                <InputField
                                    value={searchText ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
                                    inputProps={{ className: classes.searchField }}
                                    InputProps={
                                        {
                                            startAdornment: <SearchIcon className={classes.iconColor} />,
                                            endAdornment: searchText && <IconButton onClick={() => setSearchText('')}><CloseIcon className={classes.iconColor} /></IconButton>
                                        }
                                    }
                                    placeholder={'Search Courses'} />
                            </Grid>
                            <Grid
                                item
                                alignContent="flex-start"
                                alignItems="flex-start"
                                justify="flex-start"
                            >
                                <TableContainer component={Paper} square className={classes.tableContainer}>
                                    <Table stickyHeader size='small' className={classes.table} aria-label='customized table'>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Course</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {isLoading ?
                                                <StyledTableRow>
                                                    <StyledTableCell className={classes.preLoaderCell}>
                                                        <CircularProgress />
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                                : toDisplay.map((program, index) => (
                                                    <StyledTableRow key={`row-${index}`}>
                                                        <StyledTableCell key={`courseName-${index}`} className={classes.courseSelectionCell} onClick={() => selectCourse(program)}>{program.courseTitle}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid
                                item
                                alignContent='flex-start'
                                alignItems='flex-start'
                                justify='flex-start'
                            >
                                <Button
                                    size='small'
                                    color='primary'
                                    variant='contained'
                                    type='button' onClick={newCourse}>ADD NEW</Button>
                                {' '}
                                {!importingComponents && <input
                                    accept="text/csv"
                                    id="data"
                                    name="data"
                                    type="file"
                                    onChange={handleFileUpload}
                                    className={classes.fileInput}
                                />}
                                <label htmlFor="data">
                                    <ProgressSaveButton
                                        text="Import Components"
                                        loading={importingComponents}
                                        onClick={() => undefined}
                                        disabled={false}
                                        size="small"
                                        component="span"
                                    ></ProgressSaveButton>
                                </label>
                                {importLogs && importLogs.length > 0 && <Button size="small" color='primary' variant="text" onClick={() => download('logs.csv')}>Download Logs</Button>}
                            </Grid>
                            <Grid
                                item
                                alignContent='flex-start'
                                alignItems='flex-start'
                                justify='flex-start'
                            >
                                <CourseDetails selectedCourse={selectedCourse} newCourse={newCourse} showSnackBar={props.showSnackBar} refreshList={props.setCourses} setConfirmationDialog={setConfirmationDialogue} />
                            </Grid>

                        </Grid>
                    }
                </DialogContent>
            </Dialog>
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                aria-labelledby="confirmation-dialog"
                open={confirmationDialogue.open}
                onClose={() => { setConfirmationDialogue({ open: false }); confirmationDialogue.onCancel && confirmationDialogue.onCancel() }}
            ><DialogContent dividers>
                    <Typography>
                        This course may be used by other programs and program versions. Are you sure you want to make changes to the course? A copy of the course can also be created
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { confirmationDialogue.onOk && confirmationDialogue.onOk(); setConfirmationDialogue({ open: false }); }} color="primary">Confirm</Button>
                    <Button autoFocus onClick={() => { setConfirmationDialogue({ open: false }); confirmationDialogue.onCancel && confirmationDialogue.onCancel() }} color="primary">Cancel</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default AddCoursePopUp;
