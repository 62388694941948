import React, { useState, Fragment } from "react";
import {
  TableBody,
  makeStyles,
  createStyles,
  TableRow,
  TableCell,
  Switch,
  Link,
  TableFooter,
  Button,
} from "@material-ui/core";

import { IIncrementDetails } from "../../../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/CharginMethod/IIncrementDetails";
import { Save, Cancel, Edit, Delete } from "@material-ui/icons";
import TransactionCodeAutoComplete from "../../../../../../components/AutoComplete/TransactionCodeAutoComplete";
import InputField from "../../../../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../../../../constants/uiConstants/inputConstants";
import { FieldError, useForm, FormContext } from "react-hook-form";

type IncrementDetailsGridRowProps = {
  row: IIncrementDetails;
  updateRow:(property: string,
    rowId: string,
    newValue: any) => void;
    usesActualHours:boolean;
    removeRow:(rowId:string) => void;
};

const IncrementDetailsGridRow = (props: IncrementDetailsGridRowProps) => {
  const [model, setModel] = useState<IIncrementDetails>(props.row);
  const data = useForm<any>({ mode: "onBlur" });
  let hoursComponent,
    chargeAmountComponent,
    transactionCodeComponent,
    optionsComponent;

  const handleSaveClick = () => {
    data.triggerValidation().then((success)=> {
      if ( success){
        let modelToUpdate = model;
        model.isEditModeOn = false;
        setModel({ ...modelToUpdate });
        props.updateRow("isEditModeOn",modelToUpdate.id, false);
      }
    })
   
  };

  const handleEditClick = () => {
    let modelToUpdate = model;
    modelToUpdate.isEditModeOn = true;
    setModel({ ...modelToUpdate });
    props.updateRow("isEditModeOn",modelToUpdate.id, true);
  };

  const handleDeleteClick =(rowId: string) => {
    props.removeRow(rowId)
  }

  const handleModelChange = (fieldId: string, value: any) => {
    let modelToUpdate = model;
    (modelToUpdate as any)[fieldId] = value;
    setModel({ ...modelToUpdate });
    props.updateRow(fieldId, modelToUpdate.id,value );
  };

  if (model.isEditModeOn) {
    transactionCodeComponent = (
      <React.Fragment>
        <TransactionCodeAutoComplete
          readOnly={false}
          id="transactionCodeId"
          name="transactionCodeId"
          key={"transactionCodeId"}
          hideLabel={true}
          disableClearable={false}
          disabled={false}
          filterHandle={(e: any) => {
            handleModelChange("transactionCodeId", e ? e.value : null);
            handleModelChange("transactionCode", e ? e.text : null);
          }}
          error={!!data.errors.transactionCodeId}
          inputRef={data.register({required: "Transaction Code Required"})}
          helperText={
            data.errors.transactionCodeId
                  ? (data.errors.transactionCodeId as FieldError).message
                  : null
          }
          valueFilter={
            model.transactionCodeId !== undefined &&
            model.transactionCodeId !== null
              ? {
                  key: "value",
                  values: [model.transactionCodeId],
                }
              : undefined
          }
        />
      </React.Fragment>
    );
    chargeAmountComponent = (
      <React.Fragment>
        <InputField
          type={InputType.NUMBER}
          key="chargeAmount"
          id="chargeAmount"
          label=""
          name="chargeAmount"
          defaultValue={model.chargeAmount}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            handleModelChange("chargeAmount", e.target.value);
          }}
          decimal
          decimalPlaces={2}
          error={!!data.errors.chargeAmount}
          inputRef={data.register({
            validate: {
                greaterThan0: (value) =>
                Number(value) > 0 ||
                "Charge Amount greater than 0",
            },
          })}
          helperText={
            data.errors.chargeAmount
              ? (data.errors.chargeAmount as FieldError).message
              : undefined
          }
        />
      </React.Fragment>
    );
    hoursComponent = (
      <React.Fragment>
        <InputField
          type={InputType.NUMBER}
          key={props.usesActualHours ? "actualHours" : "scheduledHours"}
          id={props.usesActualHours ? "actualHours" : "scheduledHours"}
          label=""
          name={props.usesActualHours ? "actualHours" : "scheduledHours"}
          defaultValue={props.usesActualHours ?model.actualHours : model.scheduledHours}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            if ( props.usesActualHours){

              handleModelChange("actualHours", e.target.value);
            }

            else{
              handleModelChange("scheduledHours", e.target.value);
            }
          }}
          decimal
          decimalPlaces={2}
          error={props.usesActualHours ? !!data.errors.actualHours : !!data.errors.scheduledHours}
          inputRef={data.register({
            validate: {
              greaterThan0: (value) =>
                Number(value) > 0 ||
                "Hours greater than 0",
            },
          })}
          helperText={props.usesActualHours ? 
           ( data.errors.actualHours
              ? (data.errors.actualHours as FieldError).message
              : undefined) :  ( data.errors.scheduledHours
                ? (data.errors.scheduledHours as FieldError).message
                : undefined)
          }
        />
      </React.Fragment>
    );
    optionsComponent = (
      <Fragment>
        <Button
          startIcon={<Save />}
          onClick={(e: any) => {
            handleSaveClick();
          }}
        ></Button>
        <Button
          startIcon={<Cancel />}
          onClick={(e: any) => {
            handleSaveClick();
          }}
        ></Button>
      </Fragment>
    );
  } else {
    transactionCodeComponent = (
      <React.Fragment>{model.transactionCode}</React.Fragment>
    );
    chargeAmountComponent = (
      <React.Fragment>
        {Number(model.chargeAmount).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}
      </React.Fragment>
    );
    hoursComponent = <React.Fragment>{props.usesActualHours ? model.actualHours : model.scheduledHours}</React.Fragment>;
    optionsComponent = (
      <Fragment>
        <Button
          startIcon={<Edit />}
          onClick={(e: any) => {
            handleEditClick();
          }}
        ></Button>
        <Button
          startIcon={<Delete />}
          onClick={(e: any) => {
            handleDeleteClick(model.id);
          }}
        ></Button>
      </Fragment>
    );
  }
  return (
    <FormContext {...data}>
    <React.Fragment>
      <TableRow
        key={"increment_" + model.id }
      >
        <TableCell>{hoursComponent}</TableCell>
        <TableCell>{chargeAmountComponent}</TableCell>
        <TableCell>{transactionCodeComponent}</TableCell>
        <TableCell>{optionsComponent}</TableCell>
      </TableRow>
    </React.Fragment>
    </FormContext>
  );
};

export default IncrementDetailsGridRow;
