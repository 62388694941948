import React, { useState, useEffect } from "react";
import { cookiesConstants } from "../../constants/actions.constants";
import * as ArcLabsLogo from "../../resources/images/ARCLABS/schoolLogo.png";
import * as ArcLabsInvertedLogo from "../../resources/images/ARCLABS/schoolLogoInverted.png";
import * as AvedaLogo from "../../resources/images/AVEDA/schoolLogo.png";
import * as AvedaInvertedLogo from "../../resources/images/AVEDA/schoolLogoInverted.png";
import * as PMTSLogo from "../../resources/images/PMTS/schoolLogo.png";
import * as PMTSInvertedLogo from "../../resources/images/PMTS/schoolLogoInverted.png";
import * as TUBCLogo from "../../resources/images/TUBC/schoolLogo.png";
import * as TUBCInvertedLogo from "../../resources/images/TUBC/schoolLogoInverted.png";
import * as MPILogo from "../../resources/images/MPI/schoolLogo.png";
import * as MPIInvertedLogo from "../../resources/images/MPI/schoolLogoInverted.png";
import * as ANGELESLogo from "../../resources/images/ANGELES/schoolLogoInverted.png";
import * as ANGELESInvertedLogo from "../../resources/images/ANGELES/schoolLogoInverted.png";
import * as CollegeLogo from "../../resources/images/COLLEGE/schoolLogo.png";
import * as CollegeLogoInverted from "../../resources/images/COLLEGE/schoolLogoInverted.png";
import * as FAMELogo from "../../resources/images/DEFAULT/schoolLogo.png";
import * as FAMELogoInverted from "../../resources/images/DEFAULT/schoolLogoInverted.png";

import * as TRAININGLogo from "../../resources/images/TRAINING/schoolLogo.png";
import * as TRAININGLogoInverted from "../../resources/images/TRAINING/schoolLogoInverted.png";

import * as FABSCHOOLLogo from "../../resources/images/FABSCHOOL/schoolLogo.png";
import * as FABSCHOOLLogoInverted from "../../resources/images/FABSCHOOL/schoolLogoInverted.png";

import * as PHAGANSLogo from "../../resources/images/PHAGANS/schoolLogo.png";
import * as PHAGANSLogoInverted from "../../resources/images/PHAGANS/schoolLogoInverted.png";

import * as CELEBRITYLogo from "../../resources/images/CELEBRITY/schoolLogo.png";
import * as CELEBRITYLogoInverted from "../../resources/images/CELEBRITY/schoolLogoInverted.png";

import * as DPRESSLEYLogo from "../../resources/images/DPRESSLEY/schoolLogo.png";
import * as DPRESSLEYLogoInverted from "../../resources/images/DPRESSLEY/schoolLogoInverted.png";

import * as RITZLogo from "../../resources/images/RITZ/schoolLogo.png";
import * as ChromeInstituteLogo from '../../resources/images/CHROMEINSTITUTE/schoolLogo.png';
import * as ChromeInstituteLogoInverted from '../../resources/images/CHROMEINSTITUTE/schoolLogoInverted.png';
import * as RITZLogoInverted from "../../resources/images/RITZ/schoolLogoInverted.png";


import { makeStyles, createStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		loginHeader: {
			background: theme.palette.paper.background,
			textAlign: "left",
			paddingLeft: theme.spacing(4),
			paddingRight: theme.spacing(4),
			color: theme.palette.text.primary,
		},
		mainTitle: {
			fontWeight: 500,
			lineHeight: 1.2,
			marginBottom: 10,
			color: theme.palette.textColor,
		},
		subTitle: {
			fontWeight: 400,
			fontSize: 14,
			lineHeight: 1.43,
			marginBottom: 1,
			color: theme.palette.menuSubTitleColor,
		},
		logoContainer: {
			textAlign: "center",
			// padding: theme.spacing(4),
			color: theme.palette.text.primary,
			maxWidth: "100%",
		},
	})
);

const logoTypes = {
	Inverted: "Inverted",
	Normal: "Normal",
};
const schoolNames = {
	PMTS: "PMTS",
	AVEDA: "AVEDA",
	ARCLABS: "ARCLABS",
	MPI: "MPI",
	DEFAULT: "DEFAULT",
	ANGELES: "ANGELES",
	PHAGANS: "PHAGANS",
	CELEBRITY: "CELEBRITY",
	DPRESSLEY: "DPRESSLEY",
	TRAINING: "TRAINING",
	FABSCHOOL: "FABSCHOOL",
	TUBC: "TUBC",
};
const SchoolLogo = (props: any) => {
	const classes = useStyles({});
	switch (props.schoolName.toUpperCase()) {
		case cookiesConstants.PMTS_THEME.toUpperCase():
			if (props.logoType === "Normal") {
				return (
					<div className={classes.logoContainer}>
						<img alt="logo" src={String(PMTSLogo)}></img>
					</div>
				);
			} else if (props.logoType === "Inverted") {
				return (
					<div className={classes.logoContainer}>
						<img alt="logo" src={String(PMTSInvertedLogo)}></img>
					</div>
				);
			}
		case cookiesConstants.TUBC_THEME.toUpperCase():
			if (props.logoType === "Normal") {
				return (
					<div className={classes.logoContainer}>
						<img alt="logo" src={String(TUBCLogo)}></img>
					</div>
				);
			} else if (props.logoType === "Inverted") {
				return <img alt="logo" src={String(TUBCInvertedLogo)}></img>;
			}
		case cookiesConstants.AVEDA_THEME.toUpperCase():
			if (props.logoType === "Normal") {
				return (
					<div className={classes.logoContainer}>
						<img alt="logo" src={String(AvedaLogo)}></img>
					</div>
				);
			} else if (props.logoType === "Inverted") {
				return <img alt="logo" src={String(AvedaInvertedLogo)}></img>;
			}
		case cookiesConstants.ARCLABS_THEME.toUpperCase():
			if (props.logoType === "Normal") {
				return (
					<div className={classes.logoContainer}>
						<img alt="logo" src={String(ArcLabsLogo)}></img>
					</div>
				);
			} else if (props.logoType === "Inverted") {
				return <img alt="logo" src={String(ArcLabsInvertedLogo)}></img>;
			}
		case cookiesConstants.MPI_THEME.toUpperCase():
			if (props.logoType === "Normal") {
				return (
					<div className={classes.logoContainer}>
						<img alt="logo" src={String(MPILogo)}></img>
					</div>
				);
			} else if (props.logoType === "Inverted") {
				return <img alt="logo" src={String(MPIInvertedLogo)}></img>;
			}
		case cookiesConstants.ANGELES_THEME.toUpperCase():
			if (props.logoType === "Normal") {
				return (
					<div className={classes.logoContainer}>
						<img alt="logo" src={String(ANGELESLogo)}></img>
					</div>
				);
			} else if (props.logoType === "Inverted") {
				return <img alt="logo" src={String(ANGELESInvertedLogo)}></img>;
			}
		case cookiesConstants.COLLEGE_THEME.toUpperCase():
			if (props.logoType === "Normal") {
				return (
					<div className={classes.logoContainer}>
						<img alt="logo" src={String(CollegeLogo)}></img>
					</div>
				);
			} else if (props.logoType === "Inverted") {
				return <img alt="logo" src={String(CollegeLogoInverted)}></img>;
			}
		case cookiesConstants.FABSCHOOL_THEME.toUpperCase():
			if (props.logoType === "Normal") {
				return (
					<div className={classes.logoContainer}>
						<img alt="logo" src={String(FABSCHOOLLogo)}></img>
					</div>
				);
			} else if (props.logoType === "Inverted") {
				return <img alt="logo" src={String(FABSCHOOLLogoInverted)}></img>;
			}
		case cookiesConstants.TRAINING_THEME.toUpperCase():
			if (props.logoType === "Normal") {
				return (
					<div className={classes.logoContainer}>
						<img alt="logo" src={String(TRAININGLogo)}></img>
					</div>
				);
			} else if (props.logoType === "Inverted") {
				return <img alt="logo" src={String(TRAININGLogoInverted)}></img>;
			}
		case cookiesConstants.PHAGANS_THEME.toUpperCase():
			if (props.logoType === "Normal") {
				return (
					<div className={classes.logoContainer}>
						<img alt="logo" src={String(PHAGANSLogo)}></img>
					</div>
				);
			} else if (props.logoType === "Inverted") {
				return <img alt="logo" src={String(PHAGANSLogoInverted)}></img>;
			}
		case cookiesConstants.CELEBRITY_THEME.toUpperCase():
			if (props.logoType === "Normal") {
				return (
					<div className={classes.logoContainer}>
						<img alt="logo" src={String(CELEBRITYLogo)}></img>
					</div>
				);
			} else if (props.logoType === "Inverted") {
				return <img alt="logo" src={String(CELEBRITYLogoInverted)}></img>;
			}
		case cookiesConstants.DPRESSLEY_THEME.toUpperCase():
			if (props.logoType === "Normal") {
				return (
					<div className={classes.logoContainer}>
						<img alt="logo" src={String(DPRESSLEYLogo)}></img>
					</div>
				);
			} else if (props.logoType === "Inverted") {
				return <img alt="logo" style={{ width: 50 }} src={String(DPRESSLEYLogoInverted)}></img>;
			}
		case cookiesConstants.RITZ_THEME.toUpperCase():
			if (props.logoType === "Normal") {
				return (
					<div className={classes.logoContainer}>
						<img alt="logo" style={{ width: 300 }} src={String(RITZLogo)}></img>
					</div>
				);
			} else if (props.logoType === "Inverted") {
				return <img alt="logo" style={{ width: 150 }} src={String(RITZLogo)}></img>;
			}
			case cookiesConstants.CHROME_INSTITUTE_THEME.toUpperCase():
				if (props.logoType === "Normal") {
					return (
						<div className={classes.logoContainer}>
							<img alt="logo" style={{ width: 300 }} src={String(ChromeInstituteLogo)}></img>
						</div>
					);
				} else if (props.logoType === "Inverted") {
					return <img alt="logo" style={{ width: 150 }} src={String(ChromeInstituteLogo)}></img>;
				}
		case cookiesConstants.DEFAULT_THEME.toUpperCase():
			if (props.logoType === "Normal") {
				return <img alt="logo" src={String(FAMELogo)} style={{width:220, marginTop:3}}></img>;
			} else if (props.logoType === "Inverted") {
				return <img alt="logo" src={String(FAMELogo)} style={{width:220, marginTop:3}}></img>;
			}
		default:
			return <img alt="logo" src={String(FAMELogo)} style={{width:220, marginTop:3}}></img>;
	}
};

SchoolLogo.propTypes = {
	logoType: PropTypes.oneOf(Object.keys(logoTypes)),
	schoolName: PropTypes.oneOf(Object.keys(schoolNames)),
};

export default SchoolLogo;
