import * as React from "react";
import { useRef } from "react";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";

export const ProgramVersionApiAutoComplete = {
  config: {
    acId: "CreditPrgVersion",
    cacheTime: 5,
    route: "/AcademicRecords/ProgramCatalog/GetProgramCatalogYear",
    requestType: "GET",
    body: null,
    hasUrlParams: true,
    useCache: false,
    storeInCache: false,
    isCampusSpecific: false,
    isUserSpecific: false,
    loadOnRender: true,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
      return data;
    },
  },
};

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {
      width: "100%",
    },
    fullWidth: { width: "100%" },
  })
);
export interface ProgramVersionProps extends FilterParams {
  programId?: string;
  campusId?: string;
  selectAll?: boolean;
  shouldCheckForEffectiveEndDate?: boolean;
  showInactivePrograms?: boolean;
  termId?: string;
}

const CreditProgramVersionAutoComplete = (props: ProgramVersionProps) => {
  const classes = useStyles({});
  const inputRef: any = useRef(null);
  const [PrgVersionsSelected, setPrgVersionsSelected] = React.useState(false);
  const [programId, setProgramId] = React.useState<string | undefined>(
    undefined
  );
  const [selectAll, setSelectAll] = React.useState<boolean | undefined>(
    undefined
  );
  const [shouldCheckForEffectiveEndDate, setShouldCheckForEffectiveEndDate] =
    React.useState<boolean>(false);

  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const PrgVerOnChange = (e: any) => {
    if (props.filterHandle) {
      props.filterHandle(e);
    }
  };

  React.useEffect(() => {
    console.log(props.programId);
    setProgramId(props.programId);
    setSelectAll(props.selectAll);
  }, [props.programId]);

  const [disabled, setIsDisabled] = React.useState<any | null>();
  React.useEffect(() => {
    setIsDisabled(props.disabled);
  }, [props.disabled]);

  React.useEffect(() => {
    if (props?.shouldCheckForEffectiveEndDate === true) {
      setShouldCheckForEffectiveEndDate(true);
    }
  }, [props.shouldCheckForEffectiveEndDate]);

  return (
    <div
      className={
        props.params?.fullWidth
          ? clsx(classes.fullWidth)
          : clsx(classes.divWidth)
      }
    >
      <ApiAutoComplete
        disabled={disabled}
        config={ProgramVersionApiAutoComplete.config}
        loading={userSelectedCampus ? false : true}
        reqParams={{
          campusId: props.campusId ? props.campusId : null,
          programId:
            programId !== undefined || selectAll === true
              ? programId
              : undefined,
          termId:props?.termId,
        }}
        classes={{
          option: "text-black",
        }}
        label={props.label ? props.label : "Program Version"}
        placeholder={
          props.valueFilter &&
          props.valueFilter.values &&
          props.valueFilter.values.length > 0
            ? ""
            : "Select a program version"
        }
        onChange={(e: any) => {
          PrgVerOnChange(e);
        }}
        multiple={props.multiple ? props.multiple : false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        inputRef={props.inputRef ? props.inputRef : inputRef}
        error={props.error}
        helperText={props.helperText}
        inputName={props.name}
        showIncludeAllValue={props.showIncludeAllValue}
        chipSize={props.chipSize}
        maxTags={props.maxTags}
        includeAllValue={props.includeAllValue ? props.includeAllValue : false}
        defaultOptionText={
          props.defaultOptionText ? props.defaultOptionText : undefined
        }
      ></ApiAutoComplete>
    </div>
  );
};
export default CreditProgramVersionAutoComplete;
