import API from "../../apiWrapper";
import { IExitCounselingReport } from "../../../interfaces/reports/IExitCounselingReport";

export const generateCsvExtract = (params: IExitCounselingReport) => {
  return API()
    .post("/Reports/ExitCounselingExport/GenerateCSVExtract", params, {
      responseType: "blob",
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");

		  const currentDate = new Date();
		  const year = currentDate.getFullYear();
		  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); 
		  const day = currentDate.getDate().toString().padStart(2, '0'); 
		  
		  const formattedDate = `${year}${month}${day}`;
		  const fileName = `AGEC${formattedDate}.csv`;

          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        }
      },
      (error: any) => {
        return null;
      }
    );
};
