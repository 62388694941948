import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
    Grid
} from "@material-ui/core";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import IExitInterviewDetails from '../../../interfaces/student/placement/ExitInterview';
import InputField from '../../../components/_Layout/Inputs/InputField';
import { useFormContext } from "react-hook-form";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		fullWidthField: {
			width: "100%"
		}
	})
);

type ExitInterviewProps = {
    exitInterviewDetails: IExitInterviewDetails;
    updateDetails(details: IExitInterviewDetails): void
};

const ExitInterview = (props: ExitInterviewProps) => {
    const { exitInterviewDetails, updateDetails } = props;
    const data = useFormContext();
    const classes= useStyles();
    data.register('exitInterViewDate', {validate : () => (!exitInterviewDetails.exitInterViewDate || (exitInterviewDetails.exitInterViewDate).toString() !== 'Invalid Date') ? true : false });
    const handleFieldOnChange = (fieldId: string, value: any) => {
        let updatedModel = exitInterviewDetails;
        (updatedModel as any)[fieldId] = value;
        data.setValue(fieldId, value, true);
        data.triggerValidation(fieldId);
        updateDetails(updatedModel);
    };

    return (

        <Grid container direction='row' spacing={1}>
            <Grid item xs={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							disableToolbar
							variant="inline"
							format="MM/dd/yyyy"
							id="exitInterViewDate"
                            label="Exit Interview"
                            name="exitInterViewDate"
							TextFieldComponent={InputField}
							value={props.exitInterviewDetails?.exitInterViewDate ?? null}
							autoOk={true}
							onChange={(value: any) => {
                                handleFieldOnChange("exitInterViewDate", value ? value : null);
                            }}
                            className={classes.fullWidthField}
							KeyboardButtonProps={{
								"aria-label": "change date"
                            }}
						/>
					</MuiPickersUtilsProvider>
            </Grid>
        </Grid>
    );
};

export default ExitInterview;
