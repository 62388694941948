import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppConfig } from "../interfaces/AppConfig";
import CircularProgress from "@material-ui/core/CircularProgress";
import Error from "../pages/error/Error";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import * as actionTypes from "../constants/actions.constants";

const useStyles = makeStyles({
  root: {
    padding: 24,
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    textAlign: "center"
  },
  loading: {
    position: "absolute",
    top: "48%",
    left: "45%"
  }
});

const ConfigLoader = (props: any) => {
  const classes = useStyles({});
  const session = useSelector((state: any) => state.session);
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<any>();

  useEffect(() => {
    let loadConfig: boolean = false;
    if (session === undefined) {
      loadConfig = true;
    } else if (session.appConfig === undefined) {
      loadConfig = true;
    }
    if (loadConfig) {
      fetch("/config/config.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }).then(response => {
        if (response.ok) {
          response.json().then(json => {
            dispatch({
              type: actionTypes.appConfig.APP_CONFIG_LOADER,
              appConfig:json
            });

            setLoading(false);
          });
        } else {
          setError(response);
        }
      });
    } else {
      setLoading(false);
    }
  }, [loading,session.appConfig]);
  if (!loading) {
    return <React.Fragment>{props.children}</React.Fragment>;
  } else if (error) {
    return (
      <Error
        errorCode={error.status}
        errorMessage={"Application Configuration " + error.statusText}
      ></Error>
    );
  } else {
    return (
      <React.Fragment>
        <div className={classes.root}>
          <CircularProgress className={classes.loading}></CircularProgress>
        </div>
      </React.Fragment>
    );
  }
};

export default ConfigLoader;
