import * as React from "react";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";
import { useRef } from "react";

const config: ApiAutoCompleteConfigItem = {
	acId: "attendanceStatusAutoComplete",
	cacheTime: 30,
	route: "/AcademicRecords/Attendance/GetAttendanceStatusesByCampus",
	requestType: "GET",
	body: null,
	hasUrlParams: true,
	useCache: true,
	storeInCache: true,
	isCampusSpecific: true,
	isUserSpecific: false,
	loadOnRender: true,
	defaultSelectedFirstItem: false,
	optionsMappedFx: (data: Array<any>) => {
		return data;
	}
};

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {}
	})
);

const AttendanceStatusAutoComplete = (props: FilterParams) => {
	const classes = useStyles({});
	const dispatch = useDispatch();
	const [addressTypesSelected, setAddressTypesSelected] = React.useState(false);
	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

    const { filterHandle, error, helperText } = props;

	const attendanceStatusOnChange = (e: any) => {
		if (filterHandle) {
			filterHandle(e);
		}
		setAddressTypesSelected(e);
	};
	const inputRef: any = useRef(null);
	return (
		<div className={clsx(classes.divWidth)}>
			<ApiAutoComplete
			 id={props.id}
				config={config}
				loading={userSelectedCampus ? false : true}
				reqParams={{ campusId: userSelectedCampus }}
				classes={{
					option: "text-black"
				}}
				label={props.label ?? "Attendance Status"}
				onChange={(e: any) => {
					attendanceStatusOnChange(e);
				}}
				inputRef={props.inputRef ? props.inputRef : inputRef}
				multiple={props.multiple || false}
                valueFilter={props.valueFilter ? props.valueFilter : undefined}
				error={error}
				inputName={props.name}
                helperText={helperText}
				showIncludeAllValue={props.showIncludeAllValue}
                chipSize={props.chipSize}
                maxTags={props.maxTags}
                includeAllValue={props.includeAllValue ? props.includeAllValue : false}
			></ApiAutoComplete>
		</div>
	);
};
export default AttendanceStatusAutoComplete;
