import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
    Dialog,
	DialogTitle,
	DialogContent,
	Portal
} from "@material-ui/core";
import { useForm, FormContext } from "react-hook-form";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LeaveOfAbsenceGrid from './LeaveOfAbsenceTable';
import LeaveOfAbsenceDetail from './LeaveOfAbsenceDetail';
import ConfirmationModal from "../../../components/_Layout/Modal/ConfirmationModal";
import CustomSnackbar from "../../../components/notifications/CustomSnackbar";
import { CustomSnackBarProps } from "../../../interfaces/common/CustomSnackBarProps";
import LOADisbursementConfirmation from './LOADisbursementConfirmation';

type LeaveOfAbsenceModalProps = {
	setOpen: any;
	open: boolean;
	updateEvent: any;
	newStatusCode: any;
	newStatus: any;
	statusChangeEnrollment: any;
	loaWidgetEnrollment: string|null;
};

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			"& .MuiPaper-rounded": {
				borderRadius: theme.spacing.borderradius,
			},
			"& .MuiDialogContent-root": {
				minHeight: 400,
			},
		},
		form: {
			display: 'flex',
			flexDirection: 'column',
			width: 'fit-content',
			minWidth: 100,
		  },
		dialogHeader: {
			backgroundColor: theme.palette.site.secondary,
			"& .MuiTypography-root": {
				fontSize: theme.spacing(2),
			},
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
		},
		historyWrapper: {
			padding: theme.spacing(.5),
		},
		detailWrapper: {
			padding: theme.spacing(.5),
		},
		spacer: {
			height: theme.spacing(1),
		},
	})
);

const LeaveOfAbsenceModal = (props: LeaveOfAbsenceModalProps) => {
	const classes = useStyles({});
	const [refreshFlag, setRefreshFlag] = React.useState<boolean>(false);
	const [confirmationPopupState, setConfirmationPopupState] = React.useState<any>(
		{
			id: "simple-popup-loa-confirmation",
			message: ""
		});
	const [loaConfirmationModelOpen, setLoaConfirmationModelOpen] = React.useState<boolean>(false);
	const setLoadDisbursementModal = (status : any) => {
		setLoaConfirmationModelOpen(status)
	}
	const [loaConfig, setLoaConfig] = React.useState<any>(null);
	const handleClose = () => {
		props.setOpen(false);
	};

	const handleStatusUpdateEvent = (newStatus: any) => {
		setRefreshFlag(!refreshFlag);
		props.updateEvent(newStatus);	
	};
	const data = useForm<any>({
		mode: "onBlur"
	  });

	const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
		{
			showSnackBar: false,
			messageInfo: undefined,
			variant: "info",
		}
	);

	const handleLoaOverlapConfirmation = (config: any) => {
		setLoadDisbursementModal(true);
		setLoaConfig(config);		
	};

return (
	<>
	<FormContext {...data}>
        <form className={classes.form}>
            <div>
				<Portal><CustomSnackbar
					variant={snackBarProps.variant}
					message={snackBarProps.messageInfo}
					open={snackBarProps.showSnackBar}
					onClose={(event?: React.SyntheticEvent, reason?: string) => {
						setSnackBarProps((props: any) => {
						return { ...props, showSnackBar: false };
						});
					}}></CustomSnackbar></Portal>				
                <Dialog
                    open={props.open}
                    onClose={handleClose}
                    className={classes.root}
                    aria-labelledby="form-dialog-title"
					fullWidth={true}
					maxWidth='lg'
					disableBackdropClick
                >
                    <DialogTitle
                        id="form-dialog-title"
                        className={classes.dialogHeader}
                    >
                        {"LOA Details"}
						<IconButton 
							aria-label="close" 
							className={classes.closeButton} 
							onClick={handleClose}
						>
							<CloseIcon />
						</IconButton>
                    </DialogTitle>
                    <DialogContent>

						{/* BEING USED TO ADD NEW LOA */}
						<div className={classes.detailWrapper}>
						{ (props.newStatusCode != null) ?
							<LeaveOfAbsenceDetail 
								setSnackBarProps={setSnackBarProps} 
								newStatusCode={props.newStatusCode} 
								newStatus={props.newStatus} 
								updateEvent={handleStatusUpdateEvent}
								loaConfirmation = {handleLoaOverlapConfirmation}
						    /> 
							: null}
					</div>					
					<div className={classes.spacer}></div>

					{/* BEING USED TO VIEW/EDIT EXISTING LOA */}
					<div className={classes.historyWrapper}>
						<LeaveOfAbsenceGrid 
							setSnackBarProps={setSnackBarProps} 
							updateEvent={handleStatusUpdateEvent} 
							refreshFlag={refreshFlag} 
							statusChangeEnrollment={props.statusChangeEnrollment} 
							loaWidgetEnrollment={props.loaWidgetEnrollment} 
							loaConfirmation = {handleLoaOverlapConfirmation}
							/>
					</div>

                    </DialogContent>
                </Dialog>
				<LOADisbursementConfirmation 
					open={loaConfirmationModelOpen} 
					setOpen={setLoadDisbursementModal} 
					meta = {loaConfig}		
				/>
            </div>
        </form>
    </FormContext>
	<ConfirmationModal {...confirmationPopupState} />
	</>
)    
};

export default LeaveOfAbsenceModal;

