import * as React from 'react';
import {
    makeStyles,
    createStyles,
    Grid,
    withStyles,
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
    TextField,
    Table,
    TableCell,
    TableRow,
    TableContainer,
    TableHead,
    TableBody,
    CircularProgress,
    Paper,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import * as quickInfoApi from "../../../api/student/quickInfo/quickInfoApi";
import { IAttendanceSummary } from "../../../interfaces/student/quickInfo/IAttendanceSummary";
import { StudentProfile } from "../../../interfaces/student/profile/StudentProfile";
import { FormContext, useForm } from "react-hook-form";
import { useState } from "react";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { IMTFDetail } from '../../../interfaces/academicRecords/IMTFDetail';
import moment from 'moment';

const ThemeTextField = withStyles((theme: any) =>
    createStyles({
        root: {},
    })
)(TextField);
const StyledTableCell = withStyles((theme: any) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.site.secondary,
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            textAlign: 'center',
            boxShadow: '1px 1px 0px 0px rgba(0,0,0,0.2)',
            '&:first-child': {
                width: '42px',
                padding: theme.spacing(0, 1)
            }
        },
        body: {
            fontSize: 16,
            color: theme.palette.black,
            fontWeight: 500,
            cursor: 'pointer',
            textAlign: 'left',
            '&:first-child': {
                width: '42px',
                padding: theme.spacing(0, 1)
            }
        },
    }),
)(TableCell);

const useStyles = makeStyles((theme: any) =>
    createStyles({
        cardTitle: {
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            backgroundColor: theme.palette.site.secondary,
            padding: theme.spacing(0)
        },
        headerText: {
            float: 'left',
            padding: theme.spacing(1.5),
        },
        dialogCloseButton: {
            float: "right",
        },
        textField: {
            marginRight: theme.spacing(1),
            width: "100%",
        },
        tableContainer: {
            height: '400px',
            width: "100%",
        },
        tableEmptyRow: {
            height: '350px',
            textAlign: 'center',
            fontSize: 20,
            opacity: 0.3,
        },
        table: {
            width: '100%',
        },
        preLoaderCell: {
            textAlign: 'center',
            padding: theme.spacing(1)
        },
        saveButton: {
            width: 130,
        }
    })
);
type MTFDetailPopupProps = {
    open?: boolean;
    mtfDetail?:IMTFDetail;
    handleClose: () => void;
};

export const MTFDetailPopup = (
    props: Readonly<MTFDetailPopupProps>
) => {
    const { open, handleClose } = props;
    const classes = useStyles({});

    return (
        <Dialog
            PaperProps={{ square: true }}
            fullWidth={true}
            maxWidth="md"
            open={open ?? false}
            onClose={props.handleClose}
            aria-labelledby="mtf-detail-dialog-title"
        >
            <DialogTitle
                className={classes.cardTitle}
                disableTypography
                id="mtf-detail-dialog-title"
            >
                <Typography className={classes.cardTitle}>
                    <div className={classes.headerText} style={{alignSelf:'center'}}>MTF Detail</div>
                    <IconButton
                        aria-label="close"
                        onClick={props.handleClose}
                        className={classes.dialogCloseButton}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Typography>
            </DialogTitle>
            <DialogContent>
                {open &&
                    <Grid container direction="column" spacing={2} wrap="nowrap">
                        <TableContainer>
                        <Table stickyHeader className={classes.table} aria-label='customized table'>
                                            <TableBody>
                                            <TableRow>
                                                <TableCell><strong>Payment Period:</strong> {props.mtfDetail?.period}</TableCell>
                                                <TableCell><strong>Performed Date:</strong> {moment(props.mtfDetail?.triggerMetDate).toDate().toDateString()}</TableCell>
                                                <TableCell><strong>Calculated By:</strong> {props.mtfDetail?.isByActual ? 'Actual Hours' :'Scheduled Hours'}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell><strong>Transfer Weeks:</strong> {Math.ceil(props.mtfDetail?.transferWeeks ?? 0)}</TableCell>
                                                <TableCell><strong>Scheduled Weeks:</strong> {Math.ceil(props.mtfDetail?.scheduleWeeksUntilFaSapCheckPoint ?? 0)}</TableCell>
                                                <TableCell><strong>Scheduled With Transfer Weeks:</strong> {Math.ceil(props.mtfDetail?.scheduleWeekUntilFASAPCheckPointPlusTransferWeeks ?? 0)}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell><strong>Actual Weeks:</strong> {Math.ceil(props.mtfDetail?.actualWeeksUntilFaSapCheckPoint ?? 0)}</TableCell>
                                                <TableCell><strong>Actual With Transfer Weeks:</strong> {Math.ceil(props.mtfDetail?.actualWeekUntilFASAPCheckPointPlusTransferWeeks ?? 0)}</TableCell>
                                                <TableCell><strong>Remaining Weeks To Complete Program:</strong> {Math.ceil(props.mtfDetail?.actualWeeksStillNeedToComplete ?? 0)}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell><strong>Program MTF:</strong> {Math.ceil(props.mtfDetail?.mtfWeeksForTheProgram ?? 0)}</TableCell>
                                                <TableCell><strong>Student MTF:</strong> {Math.ceil(props.mtfDetail?.mtfTotal ?? 0)}</TableCell>
                                                <TableCell><strong>Is Satisfied:</strong> {props.mtfDetail?.isSatisfied == true ? 'Yes' : 'No'}</TableCell>
                                            </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                    </Grid>
                }
            </DialogContent>
        </Dialog>
    );
};
