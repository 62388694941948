import * as React from "react";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
import AwardYearAutoComplete from "../../../../../src/components/AutoComplete/AwardYearAutoComplete";
import { StudentAward } from "../../../../interfaces/student/studentAccounts/StudentAward";
import FundSourcesAutoComplete from "../../../../components/AutoComplete/FundSourcesAutoComplete";
import CurrencyInput, {
	ConvertCurrencyValueToInput,
} from "../../../../components/_Layout/Inputs/CurrencyInput";
import InputField from "../../../../components/_Layout/Inputs/InputField";
import { FieldError, useForm } from "react-hook-form";
import { EmptyGuid } from "../../../../utils/constants";
import { InputType } from "../../../../constants/uiConstants/inputConstants";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		drawer: {
			width: 280,
		},
		root: {
			backgroundColor: theme.palette.paper.background,
			paddingBottom: theme.spacing(1),
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2),
			},
		},
		"& .makeStyles-content": {
			backgroundColor: theme.palette.paper.background,
		},
		cardTitle: {
			fontSize: 17,
			color: theme.palette.black,
			textDecoration: "bold",
			backgroundColor: theme.palette.iconSecondaryHover,
			padding: theme.spacing(1, 1),
		},
		textField: {
			marginRight: theme.spacing(1),
			width: "100%",
		},
		headerText: {},
		cardContent: {
			padding: theme.spacing(1, 3),
		},
	})
);

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {},
	})
)(TextField);
type AwardDetailProps = {
	selectedAward: StudentAward;
	isAfaIntegrationEnabled: boolean;
	setSelectedAward: any;
	saveAward: any;
};

const AwardDetail = (props: AwardDetailProps) => {
	const classes = useStyles({});
	const data = useForm<StudentAward>({
		mode:'onBlur'
	});

	const unFormattedNumber = (formattedNumber: any) => {
		const cleanedInput = (formattedNumber + "").replace(/[^\d.-]/g, "");
    const result = Number(cleanedInput);
		return result;
	};
	const onAmountChangedBeginUnitGreaterThanZero = (beginUnitGraterZero: any) =>{
		return unFormattedNumber(beginUnitGraterZero)  > 0 || "Begin must be greater than 0";
	 };
	 const onAmountChangedLessThanEndUnit = (lessEndUnit: any) =>{
		return unFormattedNumber(lessEndUnit) < props.selectedAward.endUnit || "Begin must be less than End";
	 };

	 const onAmountChangedEndUnitGreaterThanZero = (endUnitGraterZero: any) =>{
		return unFormattedNumber(endUnitGraterZero)  > 0 || "End must be greater than 0";
	 };
	 const onAmountChangedGreaterThanBeginUnit = (greaterBeginUnit: any) =>{
		return unFormattedNumber(greaterBeginUnit)  > props.selectedAward.beginUnit || "End must be greater than Begin Unit";
	 };

	const handleFieldOnChange = (fieldId: keyof(StudentAward), value: any) => {
		let updatedModel = props.selectedAward;
		(updatedModel as any)[fieldId] = value;
		data.setValue(fieldId, value);
		if (fieldId === "grossAmount" || fieldId === "loanFees") {
			updatedModel.netAmt = updatedModel.grossAmount - updatedModel.loanFees;
			data.triggerValidation('maxAwardAmount');
		}
		if (fieldId === "beginUnit") {
			data.triggerValidation("endUnit");
		}

		if (fieldId === "endUnit") {
			data.triggerValidation("beginUnit");
		}

		props.setSelectedAward({ ...updatedModel })
		data.triggerValidation(fieldId);
	};
	return (
		<div className={classes.root}>
			<Typography className={clsx(classes.cardTitle)}>
				<h3 className={classes.headerText}>Award Detail</h3>
			</Typography>
			<Grid container direction="row" spacing={1}>
				<Grid item xs={12} sm={12} md={3}>
					<FundSourcesAutoComplete
						id="awardTypeId"
						name="awardTypeId"
						multiple={false}
						label="Fund Source"
						valueFilter={
							props.selectedAward &&
								props.selectedAward.awardTypeId !== EmptyGuid
								? {
									key: "value",
									values: [props.selectedAward?.awardTypeId],
								}
								: undefined
						}
						filterHandle={(v: any) => {
							handleFieldOnChange("awardTypeId", v ? v.value : null);
						}}
						error={!!data.errors.awardTypeId}
						inputRef={data.register({ required: true })}
						helperText={
							data.errors.awardTypeId ? "Fund Source is required." : undefined
						}
						optionsFilter={(data:any) => {
							return (props.selectedAward.studentAwardId == EmptyGuid && props.isAfaIntegrationEnabled == true ? (data.isTitleIV !== true) : data);
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={1}></Grid>
				<Grid item xs={12} sm={12} md={3}>
					<AwardYearAutoComplete
						name="academicYearId"
						id="academicYearId"
						label="Award Year"
						valueFilter={
							props.selectedAward &&
								props.selectedAward?.academicYearId !== EmptyGuid
								? {
									key: "value",
									values: [props.selectedAward?.academicYearId],
								}
								: undefined
						}
						filterHandle={(v: any) => {
							handleFieldOnChange("academicYearId", v ? v.value : null);
						}}
						error={!!data.errors.academicYearId}
						inputRef={data.register({ required: true })}
						helperText={
							data.errors.academicYearId ? "Award Year is required." : undefined
						}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={1}></Grid>
				<Grid item xs={12} sm={12} md={4}>
					{props.isAfaIntegrationEnabled ? ( // we have to replace the true with isAfaIntegrationEnabled variable
						// <ThemeTextField
						// 	id="financialAidId"
						// 	name="financialAidId"
						// 	className={classes.textField}
						// 	label="FA Identifier"
						// 	value={props.selectedAward?.financialAidId}
						// 	onChange={(v: any) => {
						// 		handleFieldOnChange(
						// 			"financialAidId",
						// 			v ? v.target.value : undefined
						// 		);
						// 	}}
						// 	error={!!data.errors.financialAidId}
						// 	inputRef={data.register({
						// 		validate: {
						// 			requiredWhenAfaIntegrated: value =>
						// 				(value !== "" && props.isAfaIntegrationEnabled) ||
						// 				!props.isAfaIntegrationEnabled ||
						// 				"Financial Aid Id is required"
						// 		}
						// 	})}
						// 	helperText={
						// 		data.errors.financialAidId
						// 			? (data.errors.financialAidId as FieldError).message
						// 			: undefined
						// 	}
						// />
						<InputField
							type={InputType.TEXT}
							id="financialAidId"
							name="financialAidId"
							label="FA Identifier"
							key={"financialAidId" + props.selectedAward?.studentAwardId}
							defaultValue={props.selectedAward?.financialAidId}
							onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
								handleFieldOnChange("financialAidId", v.target.value);
							}}
							error={!!data.errors.financialAidId}
							inputRef={data.register({
								required:false
							})}
							helperText={
								data.errors.financialAidId
									? (data.errors.financialAidId as FieldError).message
									: undefined
							}
						/>
					) : null}
				</Grid>
				<Grid item xs={12} sm={12} md={3}>
					{/* <CurrencyInput
						control={data.control}
						className={classes.textField}
						label="Gross Amount"
						name="grossAmount"
						key="grossAmount"
						onChange={handleFieldOnChange}
						value={props.selectedAward?.grossAmount}
						error={!!data.errors.grossAmount}
						inputRef={data.register({
							required: "Gross amount is required.",
						})}
						helperText={
							data.errors.grossAmount
								? (data.errors.grossAmount as FieldError).message
								: undefined
						}
					/> */}
					<InputField
						type={InputType.CURRENCY}
						id="grossAmount"
						label="Gross Amount"
						name="grossAmount"
						key="grossAmount"
						decimal
						thousandSeparator
						defaultValue={props.selectedAward?.grossAmount}
						onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
							handleFieldOnChange("grossAmount", v.target.value);
						}}
						error={!!data.errors.grossAmount}
						inputRef={data.register({
							required: "Gross amount is required."
						})}
						helperText={
							data.errors.grossAmount
								? (data.errors.grossAmount as FieldError).message
								: undefined
						} />

				</Grid>
				<Grid item xs={12} sm={12} md={1}></Grid>
				<Grid item xs={12} sm={12} md={1}>
					{props.selectedAward && (
						<InputField
							type={InputType.NUMBER}
							id="beginUnit"
							label="Begin"
							name="beginUnit"
							key="beginUnit"
							decimal
							thousandSeparator
							defaultValue={props.selectedAward?.beginUnit}
							onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
								handleFieldOnChange("beginUnit", v.target.value);
							}}
							error={!!data.errors.beginUnit}
							inputRef={
								data.register({
									required: "Begin is required",
									validate: {
										beginUnitGreaterThanZero:onAmountChangedBeginUnitGreaterThanZero,
										lessThanEndUnit: onAmountChangedLessThanEndUnit,
									},
								})
							}
							helperText={
								data.errors.beginUnit
									? (data.errors.beginUnit as FieldError).message
									: undefined
							}
						/>
					)}

				</Grid>
				<Grid item xs={12} sm={12} md={2}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							disableToolbar
							variant="inline"
							format="MM/dd/yyyy"
							id="awardStartDate"
							label=" "
							TextFieldComponent={ThemeTextField}
							value={props.selectedAward?.awardStartDate}
							autoOk={true}
							onChange={(value: any) => {
								handleFieldOnChange("awardStartDate", value ? value : null);
							}}
							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
							required
						/>
					</MuiPickersUtilsProvider>
				</Grid>
				<Grid item xs={12} sm={12} md={5}></Grid>
				<Grid item xs={12} sm={12} md={3}>
					<InputField
						type={InputType.CURRENCY}
						id="loanFees"
						label="Loan Fees"
						name="loanFees"
						key="loanFees"
						decimal
						thousandSeparator
						defaultValue={props.selectedAward?.loanFees}
						onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
							handleFieldOnChange("loanFees", v.target.value);
						}}
						error={!!data.errors.loanFees}
						inputRef={data.register({
							required: "Loan amount is required."
						})}
						helperText={
							data.errors.loanFees
								? (data.errors.loanFees as FieldError).message
								: undefined
						} />
				</Grid>
				<Grid item xs={12} sm={12} md={1}></Grid>
				<Grid item xs={12} sm={12} md={1}>
					<InputField
						type={InputType.NUMBER}
						id="endUnit"
						label="End"
						name="endUnit"
						key="endUnit"
						decimal
						thousandSeparator
						defaultValue={props.selectedAward?.endUnit}
						onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
							handleFieldOnChange("endUnit", v.target.value);
						}}
						error={!!data.errors.endUnit}
						inputRef={data.register({
							required: "End is Required.",
							validate: {
								endUnitGreaterThanZero: onAmountChangedEndUnitGreaterThanZero,
								greaterThanBeginUnit: onAmountChangedGreaterThanBeginUnit,
							},
						})}
						helperText={
							data.errors.endUnit
								? (data.errors.endUnit as FieldError).message
								: undefined
						}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={2}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							disableToolbar
							variant="inline"
							format="MM/dd/yyyy"
							name="awardEndDate"
							id="awardEndDate"
							label=" "
							TextFieldComponent={ThemeTextField}
							value={props.selectedAward?.awardEndDate}
							autoOk={true}
							onChange={(value: any) => {
								handleFieldOnChange("awardEndDate", value ? value : null);
							}}
							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
						/>
					</MuiPickersUtilsProvider>
				</Grid>
				<Grid item xs={12} sm={12} md={5}></Grid>
				<Grid item xs={12} sm={12} md={3}>
					<InputField
						type={InputType.CURRENCY}
						id="netAmt"
						label="Net Amount"
						name="netAmt"
						key="netAmt-netAmt"
						decimal
						thousandSeparator
						defaultValue={props.selectedAward?.netAmt}
						error={!!data.errors.netAmt}
						inputRef={data.register({
							required: "New Amount is Required."
						})}
						disabled={true}
						helperText={
							data.errors.netAmt
								? (data.errors.netAmt as FieldError).message
								: undefined
						} />
				</Grid>
				<Grid item xs={12} sm={12} md={1}></Grid>
				<Grid item xs={12} sm={12} md={3}>
				{(props.selectedAward.studentAwardId == EmptyGuid && <InputField
						type={InputType.NUMBER}
						id="numberOfDisbursements"
						label="No. Of Disbursements"
						name="numberOfDisbursements"
						key="numberOfDisbursements"
						defaultValue={props.selectedAward?.numberOfDisbursements}
						onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
							handleFieldOnChange("numberOfDisbursements", v.target.value);
						}}
						error={!!data.errors.numberOfDisbursements}
						inputRef={data.register({
							required:true,
						})}
						helperText={
							data.errors.numberOfDisbursements
								? "No. of disbursements is required."
								: undefined
						}
					/>)}
				</Grid>
				<Grid item xs={12} sm={12} md={1}></Grid>
				<Grid item xs={12} sm={12} md={4}>
					<InputField
						type={InputType.CURRENCY}
						id="maxAwardAmount"
						label="Max Award Amount"
						name="maxAwardAmount"
						key="maxAwardAmount"
						decimal
						thousandSeparator
						defaultValue={props.selectedAward?.maxAwardAmount}
						onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
							handleFieldOnChange("maxAwardAmount", v.target.value);
						}}
						error={!!data.errors.maxAwardAmount}
						inputRef={data.register({
							required: "Max amount is required",
							validate: {
								greaterOrEqualThanNet: (value) =>
									ConvertCurrencyValueToInput(value) >=
									props.selectedAward.netAmt ||
									"Max amount must be greater or equal to Net amount",
							},
						})}
						helperText={
							data.errors.maxAwardAmount
								? (data.errors.maxAwardAmount as FieldError).message
								: undefined
						}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export default AwardDetail;
