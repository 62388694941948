import { useState, useEffect } from "react";
import {
  makeStyles,
  Card,
  createStyles,
  CardContent,
  Grid,
  withStyles,
  TextField,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import CustomCardTitle from "../../../../../../interfaces/common/card/CustomCardTitle";
import { IProgramVersionAcademicDetails } from "../../../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/IProgramVersionAcademicDetails";
import { useFormContext } from "react-hook-form";
import SAPPolicyAutoComplete from "../../../../../../components/AutoComplete/SapPolicyAutoComplete";
import FASAPPolicyAutoComplete from "../../../../../../components/AutoComplete/FASAPPolicyAutoComplete";
import GradeSystemAutoComplete from "../../../../../../components/AutoComplete/GradeSystemAutoComplete";
import GradeScaleAutoComplete from "../../../../../../components/AutoComplete/GradeScaleAutoComplete";
import DegreeAutoComplete from "../../../../../../components/AutoComplete/DegreeAutoComplete";

import * as GradeSystemApi from "../../../../../../api/setup/academics/gradeSystemApi";
import { DropDownListItem } from "../../../../../../interfaces/DropDownListItem";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    fullWidthField: {
      width: "100%",
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%",
    },
    cardWrapper: {
      marginBottom: theme.spacing(2),
    },
    switch: {
      marginLeft: "0px",
      paddingTop: theme.spacing(2),
    },
    preLoader: {
      textAlign: 'center'
    }
  })
);
const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& .MuiFormLabel-root": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "100%",
      },
    },
  })
)(TextField);

type ProgramVersionAcademicDetailsProps = {
  model: IProgramVersionAcademicDetails;
  setModel: any;
  loading?: boolean;
};

const ProgramVersionAcademicDetails = (
  props: ProgramVersionAcademicDetailsProps
) => {
  const classes = useStyles({});
  const [tab, setTab] = useState(1);
  const { model, setModel } = props;
  const data = useFormContext();

  const handleFieldChange = (fieldId: string, value: any) => {
    let updatedModel = model;
    (updatedModel as any)[fieldId] = value;
    setModel({ ...updatedModel });


  };

  const overallAverageOptions: Array<DropDownListItem> = [
    { text: "By Subject", id: "Subject" },
    { text: "By Grade Components", id: "GradeComponents" }
  ];

  const overallAverageAutoComplete = {
    options: overallAverageOptions,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
    const item = items.find((opt) => {
      if (opt.id == value) return opt;
    });
    return item || ({} as DropDownListItem);
  };

  useEffect(() => {
    refreshGradeData();

  }, [model.gradeSystemId])

  const refreshGradeData = () => {
    let academicDetailsUpdated = model;
    academicDetailsUpdated.gradeScaleId = model.gradeScaleId;

    setModel({ ...academicDetailsUpdated })
    getMinimumGrade();
  }

  const getMinimumGrade = () => {
    GradeSystemApi.GetMinimumGradeRequired(model.gradeSystemId).then(
      (response: any) => {
        if (response.resultStatus === 0) {
          let academicDetailsUpdated = model;
          academicDetailsUpdated.minimumGradeRequired = response.result;
          setModel({ ...academicDetailsUpdated })
        }
        else {

        }
      },
      (exception: any) => {

      }
    );
  }
  return (
    <Card square={true} className={classes.cardWrapper}>
      <CustomCardTitle title={"Academic"}></CustomCardTitle>
      <CardContent>
        {(props.loading && <div className={classes.preLoader}>
          <CircularProgress />
        </div>)
          ||
          <React.Fragment >
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <GradeSystemAutoComplete
                  id="gradeSystemId"
                  name="gradeSystemId"
                  label="Grade System *"
                  valueFilter={
                    model.gradeSystemId
                      ? {
                        key: "value",
                        values: [model.gradeSystemId],
                      }
                      : undefined
                  }
                  filterHandle={(v: any) => {
                    handleFieldChange("gradeSystemId", v ? v.value : null);
                  }}
                  error={!!data.errors.gradeSystemId}
                  inputRef={data.register({ required: true })}
                  helperText={
                    data.errors.gradeSystemId ? "Grade System is required." : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <GradeScaleAutoComplete
                  id="gradeScaleId"
                  name="gradeScaleId"
                  label="Grade Scale *"
                  gradeSystemId={model.gradeSystemId}
                  valueFilter={
                    model.gradeScaleId
                      ? {
                        key: "value",
                        values: [model.gradeScaleId],
                      }
                      : undefined
                  }
                  filterHandle={(v: any) => {
                    handleFieldChange("gradeScaleId", v ? v.value : null);
                  }}
                  error={!!data.errors.gradeScaleId}
                  inputRef={data.register({ required: true })}
                  helperText={
                    data.errors.gradeScaleId ? "Grade Scale is required." : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <ThemeTextField
                  id="minimumGradeRequired"
                  label="Minimum grade required"
                  name="minimumGradeRequired"
                  key="minimumGradeRequired"
                  value={model.minimumGradeRequired}
                  onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleFieldChange("minimumGradeRequired", e.target.value);
                  }}
                  disabled

                  className={classes.fullWidthField}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <SAPPolicyAutoComplete
                  id="sapPolicyId"
                  name="sapPolicyId"
                  label="SAP Policy *"
                  valueFilter={
                    model.sapPolicyId
                      ? {
                        key: "value",
                        values: [model.sapPolicyId],
                      }
                      : undefined
                  }
                  filterHandle={(v: any) => {
                    handleFieldChange("sapPolicyId", v ? v.value : null);
                  }}
                  error={!!data.errors.sapPolicyId}
                  inputRef={data.register({ required: true })}
                  helperText={
                    data.errors.sapPolicyId ? "SAP Policy is required." : undefined
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}> <FASAPPolicyAutoComplete
                id="fasapPolicyId"
                name="fasapPolicyId"
                label="FASAP Policy *"
                valueFilter={
                  model.fasapPolicyId
                    ? {
                      key: "value",
                      values: [model.fasapPolicyId],
                    }
                    : undefined
                }
                filterHandle={(v: any) => {
                  handleFieldChange("fasapPolicyId", v ? v.value : null);
                }}
                error={!!data.errors.fasapPolicyId}
                inputRef={data.register({ required: true })}
                helperText={
                  data.errors.fasapPolicyId ? "FASAP Policy is required." : undefined
                }
              /></Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DegreeAutoComplete id="degreeId"
                  name="degreeId"
                  label="Degree *"
                  valueFilter={
                    model.degreeId
                      ? {
                        key: "value",
                        values: [model.degreeId],
                      }
                      : undefined
                  }
                  filterHandle={(v: any) => {
                    handleFieldChange("degreeId", v ? v.value : null);
                  }}
                  error={!!data.errors.degreeId}
                  inputRef={data.register({ required: true })}
                  helperText={
                    data.errors.degreeId ? "Degree is required." : undefined
                  } />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
              <Autocomplete
                  {...overallAverageAutoComplete}
                  autoComplete
                  includeInputInList
                  onChange={(e: any, value: any) => {
                    handleFieldChange(
                      "overallAverageBy",
                      value ? value.id : undefined
                    );
                  }}
                  value={getSelectedItem(overallAverageOptions, model.overallAverageBy ?? '')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Overall Average *"
                      name="overallAverageBy"
                      error={!!data.errors.overallAverageBy}
                      inputRef={data.register({ required: true })}
                      helperText={
                        data.errors.overallAverageBy
                          ? "Overall Average is required."
                          : undefined
                      }
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormControlLabel
                  id="doesGradeClinicServices"
                  className={classes.switch}
                  labelPlacement="start"
                  label="Grade Clinic Services"
                  control={
                    <Switch
                      checked={model.doesGradeClinicServices}
                      name="doesGradeClinicServices"
                      onChange={(e: any) => {
                        handleFieldChange(
                          "doesGradeClinicServices",
                          e ? e.target.checked : false
                        );
                      }}
                      color="primary"
                    />
                  }
                />
              </Grid>
            </Grid>
          </React.Fragment>
        }
      </CardContent>
    </Card >
  );
};

export default ProgramVersionAcademicDetails;
