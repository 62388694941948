import { useState, useEffect } from "react";
import {
	makeStyles,
	Card,
	createStyles,
	CardContent,
	Grid,
	FormControlLabel,
	Switch,
	CircularProgress,
} from "@material-ui/core";
import React from "react";
import CustomCardTitle from "../../../../../../interfaces/common/card/CustomCardTitle";
import { useFormContext, FieldError } from "react-hook-form";
import { IProgramVersionFinancialDetails } from "../../../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/IProgramVersionFinancialDetails";
import InputField from "../../../../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../../../../constants/uiConstants/inputConstants";
import { ThemeTextField } from "../../../../../../components/_Layout/Inputs/ThemeTextField";
import InstitutionalRefundAutoComplete from "../../../../../../components/AutoComplete/InstitutionalRefundAutoComplete";
import CalculationPeriodTypesAutoComplete from "../../../../../../components/AutoComplete/CalculationPeriodTypesAutoComplete";

import * as settingsApi from "../../../../../../api/Common/configSettings";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme: any) =>
	createStyles({
		cardTitle: {
			fontSize: 17,
			color: theme.palette.black,
			textDecoration: "bold",
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1),
			marginBottom: theme.spacing(1),
		},
		headerText: {
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(0),
		},
		cardContent: {
			padding: theme.spacing(1, 3),
			height: "100%",
		},
		cardWrapper: {
			marginBottom: theme.spacing(2),
		},
		switch: {
			marginLeft: "0px",
			paddingTop: theme.spacing(2),
		},
		fullWidthField: {
			width: "100%",
		},
		preLoader: {
			textAlign: 'center'
		}
	})
);
type ProgramVersionFinancialDetailsProps = {
	model: IProgramVersionFinancialDetails;
	setModel: any;
	loading?: boolean;
};

const ProgramVersionFinancialDetails = (
	props: ProgramVersionFinancialDetailsProps
) => {
	const classes = useStyles({});
	const data = useFormContext();
	const { model, setModel } = props;
	const userSelectedCampus = useSelector(
		(state: any) => state.student.enrollments[0].campusId
	);
	const handleFieldChange = (fieldId: string, value: any) => {
		let updatedModel = model;
		(updatedModel as any)[fieldId] = value;
		setModel({ ...updatedModel });
	};

	const [initialLoad, setInitialLoad] = useState(true);
	const handleRefundPolicyChange = (policyIds: any[]) => {
		if (!initialLoad) {
			let newArray: string[] = [];

			if (policyIds !== null && policyIds.length > 0) {
				policyIds.forEach((p) => {
					newArray.push(p.value);
				});
			}
			let updatedModel = model;
			updatedModel.refundPolicyIds = newArray;
			setModel({ ...updatedModel });
		}

		setInitialLoad(false);
	};

	useEffect(() => {
		settingsApi
			.getConfigSettingValue(
				"FAMETitleIVService",
				userSelectedCampus ? userSelectedCampus : "00000000"
			)
			.then(
				(response: any) => {
					if (response != null) {
						let settingValue = response.data;
						if (
							settingValue == "1" ||
							settingValue == "true" ||
							settingValue == "True"
						) {
							handleFieldChange("isFAMETitleIVService", true);
						} else {
							handleFieldChange("isFAMETitleIVService", false);
						}
					} else {
						handleFieldChange("isFAMETitleIVService", false);
					}
				},
				(exception: any) => {
					handleFieldChange("isFAMETitleIVService", false);
				}
			);
	}, []);

	useEffect(() => {
		if ((!model.refundPolicyIds || model.refundPolicyIds.length <= 0) && initialLoad == true) {
			setInitialLoad(false);
		}
	}, [model.refundPolicyIds]);

	let titleIvComponent;
	if (model.isFAMETitleIVService) {
		titleIvComponent = (
			<FormControlLabel
				id="isFAMEApprovedTitleIV"
				className={classes.switch}
				labelPlacement="start"
				label="Is FAME Approved Title IV"
				control={
					<Switch
						checked={model.isFAMEApprovedTitleIV}
						name="isFAMEApprovedTitleIV"
						onChange={(e: any) => {
							handleFieldChange(
								"isFAMEApprovedTitleIV",
								e ? e.target.checked : false
							);
						}}
						color="primary"
					/>
				}
			/>
		);
	} else {
		titleIvComponent = (
			<FormControlLabel
				id="isTitleIV"
				className={classes.switch}
				labelPlacement="start"
				label="Is Title IV"
				control={
					<Switch
						checked={model.isTitleIV}
						name="isTitleIV"
						onChange={(e: any) => {
							handleFieldChange("isTitleIV", e ? e.target.checked : false);
						}}
						color="primary"
					/>
				}
			/>
		);
	}

	return (
		<Card square={true} className={classes.cardWrapper}>
			<CustomCardTitle title={"Financial"}></CustomCardTitle>
			<CardContent>
				{(props.loading && <div className={classes.preLoader}>
					<CircularProgress />
				</div>)
					||
					<Grid container direction="row" spacing={2}>
						{/* <Grid item xs={12} sm={12} md={4}>
          <TuitionEarningAutoComplete
              id="tuitionEarningId"
              name="tuitionEarningId"
              label="Tuition Earning *"
              valueFilter={
                model.tuitionEarningId
                  ? {
                      key: "value",
                      values: [model.tuitionEarningId],
                    }
                  : undefined
              }
              filterHandle={(v: any) => {
                handleFieldChange("tuitionEarningId", v ? v.value : null);
              }}
               error={!!data.errors.tuitionEarningId}
              inputRef={data.register({
                required: true
               
              })}
              helperText={
                data.errors.tuitionEarningId
                  ? (data.errors.tuitionEarningId as FieldError).message
                  : undefined
              }
            />
          </Grid> */}
						<Grid item xs={12} sm={12} md={4}>
							<InputField
								type={InputType.NUMBER}
								id="totalCost"
								label="Total Cost"
								name="totalCost"
								key="totalCost"
								defaultValue={model.totalCost}
								onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
									handleFieldChange("totalCost", e.target.value);
								}}
								decimal
								decimalPlaces={2}
							// error={!!data.errors.totalCost}
							// inputRef={data.register({
							//   required: "Excused Absence % required",
							//   validate: {
							//     cannotBeGreaterthan10:value => Number(value) <= 10 || "Excused Absence % cannot be greater than 10.00",
							//     cannotBeless0:value => Number(value) >= 0 || "Excused Absence % cannot less than 0.00"
							//   },
							// })}
							// helperText={
							//   data.errors.totalCost
							//     ? (data.errors.totalCost as FieldError).message
							//     : undefined
							// }
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<ThemeTextField
								id="chargeMethod"
								label="Charge Definition"
								name="chargeMethod"
								key="chargeMethod"
								value={model.chargeMethod}
								onChange={(e: React.FocusEvent<HTMLInputElement>) => {
									handleFieldChange("chargeMethod", e.target.value);
								}}
								disabled
								className={classes.fullWidthField}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<CalculationPeriodTypesAutoComplete
								id="paymentPeriodId"
								name="paymentPeriodId"
								label={`Period used for R2T4 calculation${((!model.isFAMETitleIVService && model.isTitleIV) || (model.isFAMETitleIVService && model.isFAMEApprovedTitleIV)) ? ' *' : ''}`}
								valueFilter={
									model.paymentPeriodId
										? {
											key: "value",
											values: [model.paymentPeriodId],
										}
										: undefined
								}
								filterHandle={(v: any) => {
									handleFieldChange("paymentPeriodId", v ? v.value : null);
								}}
								error={!!data.errors.paymentPeriodId}
								inputRef={data.register({
									required: ((!model.isFAMETitleIVService && model.isTitleIV) || (model.isFAMETitleIVService && model.isFAMEApprovedTitleIV)) ? true : false,
								})}
								helperText={
									data.errors.paymentPeriodId
										? (data.errors.paymentPeriodId as FieldError).message
										: undefined
								}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<InstitutionalRefundAutoComplete
								id="refundPolicyId"
								name="refundPolicyId"
								label="Refund Policy"
								multiple={true}
								valueFilter={
									model.refundPolicyIds
										? {
											key: "value",
											values: [...model.refundPolicyIds],
										}
										: undefined
								}
								filterHandle={(v: any) => {
									handleRefundPolicyChange(v);
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							{titleIvComponent}
						</Grid>
						{((!model.isFAMETitleIVService && model.isTitleIV) || (model.isFAMETitleIVService && model.isFAMEApprovedTitleIV)) && (<Grid item xs={12} sm={12} md={4}>
							<FormControlLabel
								id="r2t4ChargesCalculationByPaymentPeriod"
								className={classes.switch}
								labelPlacement="start"
								label="R2T4 charges calculation by Pay Period"
								control={
									<Switch
										checked={model.r2t4ChargesCalculationByPaymentPeriod}
										name="r2t4ChargesCalculationByPaymentPeriod"
										onChange={(e: any) => {
											handleFieldChange(
												"r2t4ChargesCalculationByPaymentPeriod",
												e ? e.target.checked : false
											);
										}}
										color="primary"
									/>
								}
							/>
						</Grid>)}
					</Grid>
				}
			</CardContent>
		</Card >
	);
};

export default ProgramVersionFinancialDetails;
