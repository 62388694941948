export const apiAutoCompleteConfig = {
	campus: {
		acId: "campuses",
		cacheTime: 5,
		route: "/SystemCatalog/Campus/GetAllCampusesByUserId",
		requestType: "GET",
		body: null,
		hasUrlParams: null,
		useCache: false,
		storeInCache: true,
		isCampusSpecific: false,
		isUserSpecific: true,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},

	campusGroup: {
		acId: "campusGroups",
		cacheTime: 5,
		route: "/SystemCatalog/CampusGroup/Get",
		requestType: "GET",
		body: null,
		hasUrlParams: null,
		useCache: true,
		storeInCache: true,
		isCampusSpecific: false,
		isUserSpecific: true,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
	campusGroupAll: {
		acId: "campusGroupsAll",
		cacheTime: 5,
		route: "/SystemCatalog/CampusGroup/GetCampusGroups",
		requestType: "GET",
		body: null,
		hasUrlParams: null,
		useCache: true,
		storeInCache: true,
		isCampusSpecific: false,
		isUserSpecific: true,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},

	timeIntervals: {
		acId: "timeIntervals",
		cacheTime: 5,
		route: "/SystemCatalog/TimeInterval/Get",
		requestType: "GET",
		body: null,
		hasUrlParams: null,
		useCache: true,
		storeInCache: true,
		isCampusSpecific: false,
		isUserSpecific: true,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},

	username: {
		acId: "usernames",
		cacheTime: 5,
		route: "/SystemCatalog/User/SearchUsers",
		requestType: "GET",
		body: null,
		hasUrlParams: null,
		useCache: false,
		storeInCache: false,
		isCampusSpecific: false,
		isUserSpecific: true,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
	usernameMultiCampus: {
		acId: "usernamesMultiCampus",
		cacheTime: 5,
		route: "/SystemCatalog/User/SearchUsersByMultipleCampus",
		requestType: "GET",
		body: null,
		hasUrlParams: true,
		useCache: false,
		storeInCache: false,
		isCampusSpecific: false,
		isUserSpecific: false,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
	role: {
		acId: "roles",
		cacheTime: 5,
		route: "/SystemCatalog/Roles/getRolesByUserType",
		requestType: "GET",
		body: null,
		hasUrlParams: null,
		useCache: false,
		storeInCache: false,
		isCampusSpecific: false,
		isUserSpecific: true,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
	status: {
		acId: "statuses",
		cacheTime: 5,
		route: "/SystemCatalog/Statuses/Get",
		requestType: "GET",
		body: null,
		hasUrlParams: null,
		useCache: false,
		storeInCache: true,
		isCampusSpecific: false,
		isUserSpecific: false,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
	awardTypes: {
		acId: "awardTypes",
		cacheTime: 5,
		route: "/SystemCatalog/Statuses/GetAwardTypes",
		requestType: "GET",
		body: null,
		hasUrlParams: null,
		useCache: true,
		storeInCache: true,
		isCampusSpecific: true,
		isUserSpecific: false,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
	TriggerUnitType: {
		acId: "TriggerUnitType",
		cacheTime: 5,
		route: "/SystemCatalog/Statuses/TriggerUnitType",
		requestType: "GET",
		body: null,
		hasUrlParams: null,
		useCache: false,
		storeInCache: true,
		isCampusSpecific: false,
		isUserSpecific: false,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
	TriggerOffsetType: {
		acId: "TriggerOffsetType",
		cacheTime: 5,
		route: "/SystemCatalog/Statuses/TriggerOffsetType",
		requestType: "GET",
		body: null,
		hasUrlParams: null,
		useCache: false,
		storeInCache: false,
		isCampusSpecific: false,
		isUserSpecific: false,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
	QualitativeMinimumType: {
		acId: "QualitativeMinimumType",
		cacheTime: 5,
		route: "/SystemCatalog/Statuses/QualitativeMinimumType",
		requestType: "GET",
		body: null,
		hasUrlParams: null,
		useCache: false,
		storeInCache: true,
		isCampusSpecific: false,
		isUserSpecific: false,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
	QuantitativeMinimumUnitType: {
		acId: "QuantitativeMinimumUnitType",
		cacheTime: 5,
		route: "/SystemCatalog/Statuses/QuantitativeMinimumUnitType",
		requestType: "GET",
		body: null,
		hasUrlParams: null,
		useCache: false,
		storeInCache: true,
		isCampusSpecific: false,
		isUserSpecific: false,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
	QuantitativeMinimumUnitTypeCredAtt: {
		acId: "QuantitativeMinimumUnitTypeCredAtt",
		cacheTime: 5,
		route: "/SystemCatalog/Statuses/QuantitativeMinimumUnitTypeCredAtt",
		requestType: "GET",
		body: null,
		hasUrlParams: null,
		useCache: false,
		storeInCache: true,
		isCampusSpecific: false,
		isUserSpecific: false,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
	ConseqTyp: {
		acId: "ConseqTyp",
		cacheTime: 5,
		route: "/SystemCatalog/Statuses/ConseqTyp",
		requestType: "GET",
		body: null,
		hasUrlParams: null,
		useCache: false,
		storeInCache: true,
		isCampusSpecific: false,
		isUserSpecific: false,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
	usertype: {
		acId: "usertypes",
		cacheTime: 5,
		route: "/SystemCatalog/UserType/GetUserTypes",
		requestType: "GET",
		body: null,
		hasUrlParams: null,
		useCache: true,
		storeInCache: true,
		isCampusSpecific: false,
		isUserSpecific: false,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
	permissions: {
		acId: "permissions",
		cacheTime: 5,
		route: "/Security/Permission/GetPermissions",
		requestType: "GET",
		body: null,
		hasUrlParams: null,
		useCache: false,
		storeInCache: false,
		isCampusSpecific: false,
		isUserSpecific: true,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
	gender: {
		acId: "genderId",
		cacheTime: 5,
		route: "/SystemCatalog/Gender/GetByCampus",
		requestType: "GET",
		body: null,
		hasUrlParams: true,
		useCache: true,
		storeInCache: true,
		isCampusSpecific: true,
		isUserSpecific: false,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
	Ethnicity: {
		acId: "ethnicity",
		cacheTime: 5,
		route: "/SystemCatalog/Ethnicity/GetEthnicityByCampus",
		requestType: "GET",
		body: null,
		hasUrlParams: true,
		useCache: true,
		storeInCache: true,
		isCampusSpecific: true,
		isUserSpecific: false,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
	dependency: {
		acId: "dependency",
		cacheTime: 5,
		route: "/SystemCatalog/Dependency/GetDependencyByCampus",
		requestType: "GET",
		body: null,
		hasUrlParams: true,
		useCache: true,
		storeInCache: true,
		isCampusSpecific: true,
		isUserSpecific: false,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
	housing: {
		acId: "housing",
		cacheTime: 5,
		route: "/SystemCatalog/Housing/GetHousingByCampus",
		requestType: "GET",
		body: null,
		hasUrlParams: true,
		useCache: true,
		storeInCache: true,
		isCampusSpecific: true,
		isUserSpecific: false,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
	geographicTypes: {
		acId: "geographicTypes",
		cacheTime: 5,
		route: "/SystemCatalog/GeographicTypes/GetGeographicTypesByCampus",
		requestType: "GET",
		body: null,
		hasUrlParams: true,
		useCache: true,
		storeInCache: true,
		isCampusSpecific: true,
		isUserSpecific: false,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
	maritalStatus: {
		acId: "maritalStatus",
		cacheTime: 5,
		route: "/SystemCatalog/maritalStatus/GetmaritalStatusByCampus",
		requestType: "GET",
		body: null,
		hasUrlParams: true,
		useCache: true,
		storeInCache: true,
		isCampusSpecific: true,
		isUserSpecific: false,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
	citizenship: {
		acId: "citizenshipId",
		cacheTime: 5,
		route: "/SystemCatalog/Citizenship/GetCitizenshipByCampus",
		requestType: "GET",
		body: null,
		hasUrlParams: true,
		useCache: true,
		storeInCache: true,
		isCampusSpecific: true,
		isUserSpecific: false,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
	admissionsReps: {
		acId: "admissionsReps",
		cacheTime: 5,
		route: "/SystemCatalog/Campus/GetAdmissionsRepsForCampusId",
		requestType: "GET",
		body: null,
		hasUrlParams: true,
		useCache: true,
		storeInCache: true,
		isCampusSpecific: true,
		isUserSpecific: false,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
	billingMethod: {
		acId: "billingMethod",
		cacheTime: 5,
		route: "",
		requestType: "GET",
		body: null,
		hasUrlParams: true,
		useCache: false,
		storeInCache: false,
		isCampusSpecific: true,
		isUserSpecific: false,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	},
	securityQuestions: {
		acId: "securityQuestion",
		cacheTime: 5,
		route: "/SystemCatalog/User/GetSecurityQuestions",
		requestType: "GET",
		body: null,
		hasUrlParams: null,
		useCache: false,
		storeInCache: false,
		isCampusSpecific: false,
		isUserSpecific: true,
		loadOnRender: true,
		defaultSelectedFirstItem: false,
		optionsMappedFx: (data: Array<any>) => {
			return data;
		},
	}
};
