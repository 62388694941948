import * as React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles, createStyles, Button, DialogActions } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { actions as adHocDetailsActions, selectors as adHocDetailsSelectors } from '../../../store/reports/adhocReportDetails';
import { useActions } from '../../../store/utils';
import { useSelector } from 'react-redux';
import { Document, Page } from 'react-pdf';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import clsx from "clsx";
import ExportButton from './ExportButton';

export interface IPreviewPopUpProps {
    open?: boolean;
    close: () => void;
    report: string;
    reportName: string;
}

const useStyles = makeStyles((theme: any) =>
    createStyles({
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        },
        pdf: {
            width: "calc(100% - 100px)",
            float: "left",
            display: "inline",
            height: "inherit",
            overflowY: "auto",
            padding: " 0 2em 0 2em",
            boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
        },
        pageInfo: {
            width: "100%",
            textAlign: "center",
            padding: ".5em 0 .5em 0",
            marginTop: "1em",
        },
        pdfWrapper: {
            width: "100%",
            [theme.breakpoints.down("md")]: {
                height: "500px",
            },
            [theme.breakpoints.between("md", "lg")]: {
                height: "500px",
            },
            [theme.breakpoints.up("lg")]: {
                height: "550px",
            },
        },
        Disabled: {
            color: "rgba(0, 0, 0, 0.26)",
        },
        pageManagement: {
            width: "50px",
            height: "inherit",
            float: "left",
            display: "inline",
            textAlign: "center",
            [theme.breakpoints.down("md")]: {
                paddingTop: theme.spacing(18.125),
            },
            [theme.breakpoints.between("md", "lg")]: {
                paddingTop: theme.spacing(20),
            },
            [theme.breakpoints.up("lg")]: {
                paddingTop: theme.spacing(22),
            },
        },
        dialogContent: {
            minWidth: 600
        }
    }));

const PreviewPopUp = (props: IPreviewPopUpProps) => {
    const classes = useStyles({});
    const { report, reportName, open, close } = props;
    const actions = useActions({
        exportReport: adHocDetailsActions.exportAdHocReportById,
        getPreview: adHocDetailsActions.getPreviewBlobAdHocReportById,
        clearFile: adHocDetailsActions.clearBlob
    }, []);

    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );
    const exporting = useSelector(adHocDetailsSelectors.epxortingById);
    const loadingPreview = useSelector(adHocDetailsSelectors.loadingPreviewById);
    const previewBlob = useSelector(adHocDetailsSelectors.previewBlob);

    const [numPages, setNumPages] = React.useState<any>(null);
    const [pageNumber, setPageNumber] = React.useState<any>(1);

    const onDocumentLoadSuccess = (e: any) => {
        setNumPages(e.numPages);
    }
    const handlePrevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    const handleNextPage = () => {
        if (numPages && pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    };

    React.useEffect(() => {
        if (!!report) {
            actions.getPreview(report, userSelectedCampus, reportName, 2);
        }
        return () => actions.clearFile();
    }, [report, userSelectedCampus, reportName]);
    return (<Dialog open={open || false} onClose={close}
        PaperProps={{ square: true }}
        fullWidth={true}
        maxWidth="xl">
        <DialogTitle id="customized-dialog-title">
            <IconButton aria-label="close" className={classes.closeButton} onClick={close}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <div className={classes.pdfWrapper}>
                <div
                    className={clsx(
                        classes.pageManagement,
                        pageNumber === 1 ? classes.Disabled : ""
                    )}
                    onClick={(e: any) => {
                        handlePrevPage();
                    }}
                >
                    <ChevronLeft />
                </div>
                <Document className={classes.pdf} onLoadSuccess={onDocumentLoadSuccess} file={{ url: (open && report && !loadingPreview && previewBlob && URL.createObjectURL(previewBlob)) || '' }}>
                    <Page pageNumber={pageNumber} pageIndex={pageNumber}/>
                </Document>
                <div
                    className={clsx(
                        classes.pageManagement,
                        pageNumber === numPages ? classes.Disabled : ""
                    )}
                    onClick={(e: any) => {
                        handleNextPage();
                    }}
                >
                    <ChevronRight />
                </div>
            </div>
            <div className={classes.pageInfo}>
                <p>
                    Page {pageNumber} of {numPages}
                </p>
            </div>
        </DialogContent>
        <DialogActions>
            <ExportButton progress={exporting} exportFunction={(exportType) => actions.exportReport(report, userSelectedCampus, reportName, exportType)} />
            <Button autoFocus onClick={close} color="secondary" variant="contained">
                Close
            </Button>
        </DialogActions>
    </Dialog >);
}

export default PreviewPopUp;
