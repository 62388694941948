import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import * as PropTypes from "prop-types";
import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import {
	Avatar,
	Card,
	CardContent,
	CardHeader,
	List,
	ListItem,
	Typography,
	CardMedia,
} from "@material-ui/core";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import ImageIcon from "@material-ui/icons/Image";
import { Document } from "react-pdf/dist/entry.webpack";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";
import { Page } from "react-pdf";

const useStyles = makeStyles((theme: any) => ({
	root: {},
	header: {
		padding: 0,
	},
	content: {
		padding: 0,
	},
	listItem: {
		padding: theme.spacing(2, 0),
		justifyContent: "space-between",
	},
	media: {
		height: 0,
		paddingTop: "56.25%", // 16:9
	},
	image: {
		height: 240,
		backgroundPositionX: "right",
		backgroundPositionY: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
	},
	pageManagement: {
		width: "50px",
		height: "inherit",
		float: "left",
		display: "inline",
		textAlign: "center",
		[theme.breakpoints.down("md")]: {
			paddingTop: theme.spacing(18.125),
		},
		[theme.breakpoints.between("md", "lg")]: {
			paddingTop: theme.spacing(20),
		},
		[theme.breakpoints.up("lg")]: {
			paddingTop: theme.spacing(22),
		},
	},
	pdfWrapper: {
		width: "100%",
		[theme.breakpoints.down("md")]: {
			height: "400px",
		},
		[theme.breakpoints.between("md", "lg")]: {
			height: "400px",
		},
		[theme.breakpoints.up("lg")]: {
			height: "450px",
		},
	},
	Disabled: {
		color: "rgba(0, 0, 0, 0.26)",
	},
	pdf: {
		width: "calc(100% - 100px)",
		float: "left",
		display: "inline",
		height: "inherit",
		overflowY: "auto",
		padding: " 0 2em 0 2em",
		boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
	},
	pageInfo: {
		width: "100%",
		textAlign: "center",
		padding: ".5em 0 .5em 0",
		marginTop: "1em",
	},
}));

interface IReportPreviewProps {
  className?: string;
  reportData?: any;
  isUrlPreview?: boolean;
}
const ReportPreview = React.memo((props: IReportPreviewProps) => {
	const { className, reportData, ...rest } = props;
	const [numPages, setNumPages] = React.useState<any>(null);
	const [pageNumber, setPageNumber] = React.useState<any>(1);

	function onDocumentLoadSuccess(e: any) {
		setNumPages(e.numPages);
	}
	const handlePrevPage = () => {
		if (pageNumber > 1) {
			setPageNumber(pageNumber - 1);
		}
	};

	const handleNextPage = () => {
		if (numPages && pageNumber < numPages) {
			setPageNumber(pageNumber + 1);
		}
	};
	const classes = useStyles();

	return (
		<Card {...rest} className={clsx(classes.root, className)}>
			<CardHeader avatar={<ImageIcon />} title="Report Preview" />
			<CardContent className={classes.content}>
				<div>
					<div className={classes.pdfWrapper}>
						<div
							className={clsx(
								classes.pageManagement,
								pageNumber === 1 ? classes.Disabled : ""
							)}
							onClick={(e: any) => {
								handlePrevPage();
							}}
						>
							<ChevronLeft />
						</div>

						<Document
							file={reportData ? props.isUrlPreview ? reportData : { data: reportData } : ""}
							onLoadSuccess={onDocumentLoadSuccess}
							className={classes.pdf}
						>
							<Page pageNumber={pageNumber} pageIndex={pageNumber} width={1400}/>
						</Document>
						<div
							className={clsx(
								classes.pageManagement,
								pageNumber === numPages ? classes.Disabled : ""
							)}
							onClick={(e: any) => {
								handleNextPage();
							}}
						>
							<ChevronRight />
						</div>
					</div>
					<div className={classes.pageInfo}>
						<p>
              Page {pageNumber} of {numPages}
						</p>
					</div>
				</div>
			</CardContent>
		</Card>
	);
});

export default ReportPreview;
