import { call, put, takeLatest } from '@redux-saga/core/effects';
import { Effect } from 'redux-saga/effects';
import { actions as transcriptActions, types as transcriptTypes } from '../../store/reports/transcriptReport';
import { GetAdvanceStudentProfileSearch } from '../../api/search/studentSearchApi';


function* fetchStudents(action: ReturnType<typeof transcriptActions.loadStudents>): IterableIterator<Effect> {
	const { success, failure } = transcriptActions.saga.loadStudents;
	try {
		const response = yield call(GetAdvanceStudentProfileSearch, action.data);
		if (response) {
			yield put(success((response as any)));
		}
	}
	catch (err) {
		yield put(failure((err as Error)?.message ?? 'Unknown Error Occured'));
	}
}

function* fetchMoreStudents(action: ReturnType<typeof transcriptActions.loadMoreStudents>): IterableIterator<Effect> {
	const { success, failure } = transcriptActions.saga.loadMoreStudents;
	try {
		const response = yield call(GetAdvanceStudentProfileSearch, action.data);
		if (response) {
			yield put(success((response as any)));
		}
	}
	catch (err) {
		yield put(failure((err as Error)?.message ?? 'Unknown Error Occured'));
	}
}

export default function* illinoisTranscriptSaga() {
	yield takeLatest(transcriptTypes.loadStudents, fetchStudents);
	yield takeLatest(transcriptTypes.loadMoreStudents, fetchMoreStudents);
}