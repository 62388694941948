export class Phone {
    constructor() {
        this.phoneId = undefined;
        this.phoneTypeId = undefined;
        this.phone = "";
        this.extension = "";
    }
    phoneId: string | undefined;
    phoneTypeId: string | undefined;
    phone: string | undefined;
    extension: string | undefined;
}