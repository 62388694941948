import React from "react";
import {
  makeStyles,
  Card,
  createStyles,
  CardContent,
  Grid,
  Paper,
  Table,
  withStyles,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  IconButton,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { StudentProfile } from "../../../../interfaces/student/profile/StudentProfile";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useForm } from "react-hook-form";
import CloseIcon from "@material-ui/icons/Close";
import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";
import StatusAutoComplete from "../../../../components/AutoComplete/StatusAutoComplete";
import RelationAutoComplete from "../../../../components/AutoComplete/RelationAutoComplete";
import SuffixAutoComplete from "../../../../../src/components/AutoComplete/SuffixAutoComplete";
import PrefixAutoComplete from "../../../../../src/components/AutoComplete/PrefixAutoComplete";
import ContactTypeAutoComplete from "../../../../components/AutoComplete/ContactTypeAutoComplete";
import * as OtherContactApi from "../../../../api/student/profle/contactInfo/OtherContactApi";
import { OtherContact } from "../../../../interfaces/student/profile/contactInfo/OtherContact";
import OtherPhonePage from "./OtherPhonePage";
import OtherEmailPage from "./OtherEmailPage";
import OtherAddressPage from "./OtherAddressPage";

const StyledTableCell = withStyles((theme: any) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.site.secondary,
      fontSize: 17,
      color: theme.palette.black,
      fontWeight: "bold",
      textAlign: "center",
      boxShadow: "1px 1px 0px 0px rgba(0,0,0,0.2)",
      "&:first-child": {
        width: "42px",
        padding: theme.spacing(0, 1),
      },
    },
    body: {
      fontSize: 16,
      color: theme.palette.black,
      fontWeight: 500,
      cursor: "pointer",
      textAlign: "left",
      "&:first-child": {
        width: "42px",
        padding: theme.spacing(0, 1),
      },
    },
  })
)(TableCell);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%",
    },
    saveButton: {
      width: 60,
    },
    tableContainer: {
      height: "400px",
    },
    tableEmptyRow: {
      height: "350px",
      textAlign: "center",
      fontSize: 20,
      opacity: 0.3,
    },
    table: {
      width: "100%",
    },
    iconColor: {
      color: theme.palette.black,
    },
    searchField: {
      "&::placeholder": {
        color: "#000000",
      },
    },
    selectionCheckBox: {
      padding: theme.spacing(0),
    },
    preLoaderCell: {
      textAlign: "center",
      padding: theme.spacing(1),
    },
    cardMain: {
      marginTop: theme.spacing(2),
    },
    popOverButtons: {
      display: "flex",
      justifyContent: "space-evenly",
      flexDirection: "row",
      padding: 5,
    },
    typography: {
      padding: theme.spacing(2),
    },
    lineThru: {
      textDecoration: "line-through",
    },
    cardWrapper: {
      marginBottom: theme.spacing(2),
    },
    root: {
      paddingTop: theme.spacing(2),
      minHeight: "100%",
      height: "100%",
      width: "100%",
      margin: "auto",
    },
    paperRoot: {
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
  })
);

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {},
  })
)(TextField);

type OtherContactPageProps = {
  setSnackBarProps?: any;
};
const OtherContactsPage = (props: any) => {
  const classes = useStyles({});

  const data = useForm<OtherContact>({ mode: "onBlur" });

  const selectedStudent: StudentProfile = useSelector(
    (state: any) => state.student
  ) as StudentProfile;

  const [leadId, setLeadId] = React.useState<string>("");
  const [otherContacts, setOtherContacts] = React.useState<OtherContact[]>([]);
  const [isCreatingOtherContact, setIsCreatingOtherContact] =
    React.useState(false);
  const [newOtherContact, setNewOtherContact] =
    React.useState<OtherContact | null>(null);

  React.useEffect(() => {
    if (selectedStudent.selectedEnrollment !== undefined) {
      if (
        selectedStudent.leadId !== undefined &&
        selectedStudent.leadId.length > 0
      ) {
       setLeadId(selectedStudent.leadId);
      }
    }
  }, [selectedStudent]);

  React.useEffect(() => {
    if (leadId !== "") {
      getOtherContact();
    }
  }, [leadId]);

  const handleAddOtherContactClick = () => {
    setNewOtherContact({...new OtherContact(), leadId: leadId });
    setIsCreatingOtherContact(true);
  };

  const handleFieldOnChange = (field: any, value: any) => {
    if (newOtherContact) {
      setNewOtherContact({ ...newOtherContact, [field]: value });
    }
  };

  const getOtherContact = () => {
    if (selectedStudent.selectedEnrollment !== undefined) {
      if (
        selectedStudent.leadId !== undefined &&
        selectedStudent.leadId.length > 0
      ) {
        OtherContactApi.getAll(selectedStudent.leadId).then(
          (response: any) => {
            setOtherContacts(response);
          },
          (exception: any) => {}
        );
      }
    }
  };

  const handleOtherContactCancel = () => {
    setIsCreatingOtherContact(false);
    setNewOtherContact(null);
  };

  const deleteOtherContact = (id: any) => {
    setLoading(true);
    OtherContactApi.deleteOtherContact(selectedStudent.leadId, id).then(
      (response: any) => {
        props.setSnackBarProps(() => {
          return {
            variant: "success",
            showSnackBar: true,
            messageInfo: "Other contact deleted successfully.",
          };
        });
        setDeletePop(undefined);
        getOtherContact();
        setIsCreatingOtherContact(false);
        setLoading(false);
      },
      (exception: any) => {
        props.setSnackBarProps(() => {
          return {
            variant: "error",
            showSnackBar: true,
            messageInfo: exception,
          };
        });
        setLoading(false);
      }
    );
  };

  const editOtherContact = (id: any) => {
    if (selectedStudent.selectedEnrollment !== undefined) {
      if (
        selectedStudent.leadId !== undefined &&
        selectedStudent.leadId.length > 0
      ) {
        OtherContactApi.getOtherContact(selectedStudent.leadId, id).then(
          (response: any) => {
            setNewOtherContact(response);
            setIsCreatingOtherContact(true);
          },
          (exception: any) => {}
        );
      }
    }
  };

  const handleSaveOtherContact = () => {
    if (newOtherContact) {
      data.triggerValidation().then((validation: any) => {
        if (validation) {
          setLoading(true);

          if (newOtherContact.id == "") {
            OtherContactApi.postOtherContact(selectedStudent.leadId, {
              ...newOtherContact,
              id: newOtherContact.id,
            } as any).then(
              (response: any | null) => {
                if (response) {
                  props.setSnackBarProps(() => {
                    return {
                      variant: "success",
                      showSnackBar: true,
                      messageInfo: "OtherContact saved successfully.",
                    };
                  });
                  setNewOtherContact({...newOtherContact, id: response?.data?.id});
                  getOtherContact();
                } else {
                  props.setSnackBarProps(() => {
                    return {
                      variant: "error",
                      showSnackBar: true,
                      messageInfo:
                        "OtherContact update could not be completed. Please try again.",
                    };
                  });
                }
                setLoading(false);
              },
              (exception: any) => {
                setLoading(false);
                props.setSnackBarProps(() => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: exception,
                  };
                });
              }
            );
          } else {
            OtherContactApi.updateOtherContact(selectedStudent.leadId, {
              ...newOtherContact,
              id: newOtherContact.id,
            } as any).then(
              (response: any | null) => {
                if (response) {
                  props.setSnackBarProps(() => {
                    return {
                      variant: "success",
                      showSnackBar: true,
                      messageInfo: "OtherContact updated successfully.",
                    };
                  });
                  getOtherContact();
                } else {
                  props.setSnackBarProps(() => {
                    return {
                      variant: "error",
                      showSnackBar: true,
                      messageInfo:
                        "OtherContact update could not be completed. Please try again.",
                    };
                  });
                }
                setLoading(false);
              },
              (exception: any) => {
                setLoading(false);
                props.setSnackBarProps(() => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: exception,
                  };
                });
              }
            );
          }
        }
      });
    }
  };

  const [deletePop, setDeletePop] = React.useState<(() => void) | undefined>(
    undefined
  );
  const [loading, setLoading] = React.useState<boolean>(false);

  const onValidation = (fieldId: string, value: any) => {
    if (data.errors && (newOtherContact as any)[fieldId] != value) {
      handleFieldOnChange(fieldId, value);
    }
  };

  return (
    <Card square={true} className={classes.cardWrapper}>
      {/* <CustomCardTitle title={"OtherContactes"}></CustomCardTitle> */}
      <CardContent>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="otherContact table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Type</TableCell>
                    <TableCell align="left">Relationship</TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Comments</TableCell>
                    <TableCell align="left">Edit</TableCell>
                    <TableCell align="left">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {otherContacts.map((otherContact) => (
                    <TableRow key={otherContact.id}>
                      <TableCell align="left">
                        {otherContact.contactType}
                      </TableCell>
                      <TableCell align="left">
                        {otherContact.relationship}
                      </TableCell>
                      <TableCell align="left">
                        {otherContact.firstName + " " + otherContact.lastName}
                      </TableCell>
                      <TableCell align="left">{otherContact.status}</TableCell>
                      <TableCell align="left">
                        {otherContact.comments}
                      </TableCell>
                      <TableCell align="left" style={{ width: 20 }}>
                        <Tooltip
                          title="Edit OtherContact"
                          style={{ padding: 0 }}
                        >
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={(e: any) => {
                              editOtherContact(otherContact.id);
                            }}
                          >
                            <EditIcon></EditIcon>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="left" style={{ width: 20 }}>
                        <Tooltip
                          title="Delete OtherContact"
                          style={{ padding: 0 }}
                        >
                          <IconButton
                            onClick={() => {
                              setDeletePop(
                                () => () => deleteOtherContact(otherContact.id)
                              );
                            }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12}>
            {!isCreatingOtherContact && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddOtherContactClick}
                size="small"
              >
                Other Contacts
              </Button>
            )}
          </Grid>

          {isCreatingOtherContact && newOtherContact && (
            <React.Fragment>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1">Add Contact</Typography>
                    <Grid container direction="row" spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <ContactTypeAutoComplete
                          id="contactTypeId"
                          name="contactTypeId"
                          label="Contact Type *"
                          valueFilter={
                            newOtherContact.contactTypeId
                              ? {
                                  key: "value",
                                  values: [newOtherContact.contactTypeId],
                                }
                              : undefined
                          }
                          filterHandle={(v: any) => {
                            handleFieldOnChange(
                              "contactTypeId",
                              v ? v.value : null
                            );
                          }}
                          error={!!data.errors.contactTypeId}
                          inputRef={data.register({ required: true })}
                          helperText={
                            data.errors.contactTypeId
                              ? "Contact type is required."
                              : undefined
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <StatusAutoComplete
                          id="statusId"
                          name="statusId"
                          label="Status *"
                          valueFilter={
                            newOtherContact.statusId
                              ? {
                                  key: "value",
                                  values: [newOtherContact.statusId],
                                }
                              : undefined
                          }
                          filterHandle={(v: any) => {
                            handleFieldOnChange("statusId", v ? v.value : null);
                          }}
                          error={!!data.errors.statusId}
                          inputRef={data.register({ required: true })}
                          helperText={
                            data.errors.statusId
                              ? "Status is required."
                              : undefined
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <RelationAutoComplete
                          id="relationshipId"
                          name="relationshipId"
                          label="Relationship *"
                          valueFilter={
                            newOtherContact.relationshipId
                              ? {
                                  key: "value",
                                  values: [newOtherContact.relationshipId],
                                }
                              : undefined
                          }
                          filterHandle={(v: any) => {
                            handleFieldOnChange(
                              "relationshipId",
                              v ? v.value : null
                            );
                          }}
                          error={!!data.errors.relationshipId}
                          inputRef={data.register({ required: true })}
                          helperText={
                            data.errors.relationshipId
                              ? "Relationship is required."
                              : undefined
                          }
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <ThemeTextField
                          id="firstName"
                          name="firstName"
                          className={classes.textField}
                          label="First Name *"
                          value={newOtherContact.firstName}
                          error={!!data.errors.firstName}
                          inputRef={data.register({
                            validate: {
                              valid: (value) => {
                                onValidation("firstName", value);
                                return value !== "" || "Required";
                              },
                            },
                          })}
                          helperText={
                            data.errors.firstName
                              ? "First name is required"
                              : null
                          }
                          onChange={(e: any) => {
                            handleFieldOnChange("firstName", e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <ThemeTextField
                          id="MiddleName"
                          name="middleName"
                          className={classes.textField}
                          label="Middle Name"
                          value={newOtherContact.middleName || ""}
                          inputRef={data.register()}
                          onChange={(e: any) => {
                            handleFieldOnChange("middleName", e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <ThemeTextField
                          id="lastName"
                          name="lastName"
                          className={classes.textField}
                          inputRef={data.register({
                            validate: {
                              valid: (value) => {
                                onValidation("lastName", value);
                                return value !== "" || "Required";
                              },
                            },
                          })}
                          label="Last Name *"
                          error={!!data.errors.lastName}
                          value={newOtherContact.lastName}
                          helperText={
                            data.errors.lastName
                              ? "Last name is required."
                              : undefined
                          }
                          onChange={(e: any) => {
                            handleFieldOnChange("lastName", e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <PrefixAutoComplete
                          id="prefixId"
                          filterHandle={(v: any) => {
                            handleFieldOnChange("prefixId", v ? v.value : null);
                          }}
                          name="prefixId"
                          valueFilter={
                            newOtherContact.prefixId
                              ? {
                                  key: "value",
                                  values: [newOtherContact.prefixId],
                                }
                              : undefined
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <SuffixAutoComplete
                          id="suffixId"
                          filterHandle={(v: any) => {
                            handleFieldOnChange("suffixId", v ? v.value : null);
                          }}
                          name="SuffixId"
                          valueFilter={
                            newOtherContact.suffixId
                              ? {
                                  key: "value",
                                  values: [newOtherContact.suffixId],
                                }
                              : undefined
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <ThemeTextField
                          id="comments"
                          name="comments"
                          className={classes.textField}
                          inputProps={{ maxLength: 500 }}
                          inputRef={data.register({ required: false })}
                          label="Comments"
                          error={!!data.errors.comments}
                          value={newOtherContact.comments}
                          onChange={(e: any) => {
                            handleFieldOnChange("comments", e.target.value);
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <ProgressSaveButton
                          text="Save"
                          onClick={() => {
                            handleSaveOtherContact();
                          }}
                          size="small"
                          loading={loading}
                          buttonClassName={classes.saveButton}
                        ></ProgressSaveButton>
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          onClick={handleOtherContactCancel}
                          style={{ marginLeft: 5 }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              {
                newOtherContact.id == "" ? "" : (<React.Fragment>
                  <Grid item xs={12}>
                    <OtherPhonePage setSnackBarProps={props.setSnackBarProps} otherContactId={newOtherContact.id} leadId = {leadId}/>
                  </Grid>
                  <Grid item xs={12}>
                    <OtherEmailPage setSnackBarProps={props.setSnackBarProps} otherContactId={newOtherContact.id} leadId = {leadId}/>
                  </Grid>
                  <Grid item xs={12}>
                    <OtherAddressPage setSnackBarProps={props.setSnackBarProps} otherContactId={newOtherContact.id} leadId = {leadId}/>
                  </Grid>
                  </React.Fragment>)
              }
              
            </React.Fragment>
          )}
        </Grid>
      </CardContent>
      <Dialog
        open={!!deletePop}
        onClose={() => !loading && setDeletePop(undefined)}
      >
        <DialogTitle id="customized-dialog-title">
          <Typography variant="h6">Delete Confirmation</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => !loading && setDeletePop(undefined)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this other contact?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ProgressSaveButton
            loading={loading}
            onClick={() => deletePop !== undefined && deletePop()}
            text="Delete"
            type="button"
          />{" "}
          <Button
            variant="contained"
            color="default"
            onClick={() => !loading && setDeletePop(undefined)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default OtherContactsPage;
