import { call, Effect, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import { actions as illinoisTranscriptSettingsActions, types as illinoisTranscriptSettingsTypes, selectors as illinoisTranscriptSettingsSelectors } from '../../store/setup/reports/illinoisTranscriptSettings';
import * as illinoisTranscriptAPI from '../../api/setup/reports/illinoisTranscript';

function* fetchConfigurations(action: ReturnType<typeof illinoisTranscriptSettingsActions.fetchSettings>): IterableIterator<Effect> {
	const { success, failure } = illinoisTranscriptSettingsActions.saga.fetchSettings;
	try {
		const response = yield call(illinoisTranscriptAPI.getReportConfigurations, action.data);
		if (response) {
			yield put(success((response as any)));
		}
	}
	catch (err) {
		yield put(failure((err as Error)?.message ?? 'Unknown Error Occured'));
	}
}

function* saveConfigurations(action: ReturnType<typeof illinoisTranscriptSettingsActions.saveDetails>): IterableIterator<Effect> {
	const { success, failure } = illinoisTranscriptSettingsActions.saga.saveDetails;
	try {
		const response = yield call(illinoisTranscriptAPI.saveConfigurations, action.data.details);
		if (response) {
			yield put(success((response as any)));
		}
	}
	catch (err) {
		yield put(failure((err as Error)?.message ?? 'Unknown Error Occured'));
	}
}

function* reloadSaga(action: any): IterableIterator<Effect> {
	const campusId = yield select(illinoisTranscriptSettingsSelectors.selectedCampus);
	const selectedConfig = yield select(illinoisTranscriptSettingsSelectors.selectProgramVersion);
	if (campusId) {
		yield put(illinoisTranscriptSettingsActions.fetchSettingsByCampus(campusId));
	}
	else {
		const programVersionId = yield select(illinoisTranscriptSettingsSelectors.programVersionId);
		if (programVersionId) {
			yield put(illinoisTranscriptSettingsActions.fetchSettings(programVersionId));
		}
	}
	if(action.data.message === selectedConfig){
		yield put(illinoisTranscriptSettingsActions.closeSettings());
	}
}

function* getReportConfigurationsByCampusSaga(action: ReturnType<typeof illinoisTranscriptSettingsActions.fetchSettingsByCampus>): IterableIterator<Effect> {
	const { success, failure } = illinoisTranscriptSettingsActions.saga.fetchSettingsByCampus;
	try {
		const response = yield call(illinoisTranscriptAPI.getReportConfigurationsByCampus, action.data);
		if (response) {
			yield put(success((response as any)));
		}
	}
	catch (err) {
		yield put(failure((err as Error)?.message ?? 'Unknown Error Occured'));
	}
}

function* deleteConfiguration(action: ReturnType<typeof illinoisTranscriptSettingsActions.deleteConfiguration>): IterableIterator<Effect> {
	const { success, failure } = illinoisTranscriptSettingsActions.saga.deleteConfiguration;
	try {
		const response = yield call(illinoisTranscriptAPI.deleteConfiguration, action.data);
		if (response) {
			yield put(success(action.data.id));
		}
	}
	catch (err) {
		yield put(failure((err as Error)?.message ?? 'Unknown Error Occured'));
	}
}

export default function* rootAdHocSaga(): IterableIterator<Effect> {
	yield takeLatest(illinoisTranscriptSettingsTypes.fetchSettings, fetchConfigurations);
	yield takeLatest(illinoisTranscriptSettingsTypes.saveDetails, saveConfigurations);
	yield takeLatest(illinoisTranscriptSettingsTypes.saga.saveDetails.success, reloadSaga);
	yield takeLatest(illinoisTranscriptSettingsTypes.fetchSettingsByCampus, getReportConfigurationsByCampusSaga);
	yield takeLatest(illinoisTranscriptSettingsTypes.deleteConfiguration, deleteConfiguration);
	yield takeEvery(illinoisTranscriptSettingsTypes.saga.deleteConfiguration.success, reloadSaga);

}