import * as React from "react";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Campus } from "../../interfaces/systemCatalog/ICampus";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { startOfWeek, endOfWeek } from "date-fns";

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.paper,
            "& .MuiCardContent-root": {
                paddingTop: theme.spacing(0),
                paddingLeft: theme.spacing(2),
            },
        },
        "& .makeStyles-content": {
            backgroundColor: theme.palette.background.paper,
        },

        filter: {
            padding: theme.spacing(1),
            display: "inline-block",
        },
        inline: {
            display: "inline-block",
            '&:last-child': {
                paddingLeft: theme.spacing(2)
            }
        },
        inlineText: {
            display: "inline-block",
            width: "150px",
            paddingRight: "2px !important"
        },
    })
);

const ThemeTextField = withStyles((theme: any) =>
    createStyles({
        root: {
            "& label.Mui-focused": {
                color: theme.palette.primary.white,
            },
            "& input": {
                color: theme.palette.black,
                opacity: 1,
                fontWeight: theme.typography.fontWeightMedium,
                fontSize: 13,
                paddingRight: "0px !important",
                paddingLeft: "1px !important",

            },
            "& .MuiInputBase-root": {
                backgroundColor: theme.palette.site.secondary,
                borderRadius: 20,
                paddingLeft: "10px !important",
                fontSize: 13,
                width: "195px",
                fontWeight: theme.typography.fontWeightBold,
                color: theme.palette.black,
            },
            "& .MuiIconButton-root": {
                padding: theme.spacing(0),
                paddingRight: "3px !important"

            }
        },
    })
)(TextField);

interface IValues {
    startDate?: Date;
    endDate?: Date;
}

interface IDateFilterProps {
    model: IValues;
    setModel: (values: IValues) => void;
    allowCustomDates?: boolean;
}

const StartEndDateFilter = (props: IDateFilterProps) => {
    const classes = useStyles({});
    const { model, setModel, allowCustomDates } = props;

    const handleFieldOnChange = (fieldId: keyof IValues, value: any) => {
        const updatedModel = model;
        if (fieldId === 'startDate') {
            updatedModel.startDate = value;

            if (!allowCustomDates) {
                if (!updatedModel.startDate) {
                    if (!updatedModel.endDate) {
                        updatedModel.startDate = startOfWeek(new Date());

                    } else {
                        updatedModel.startDate = startOfWeek(updatedModel.endDate);
                    }
                }
                updatedModel.startDate = startOfWeek(updatedModel.startDate);
                updatedModel.endDate = endOfWeek(updatedModel.startDate);
            }

        }
        if (fieldId === 'endDate') {
            updatedModel.endDate = value;
            if (!allowCustomDates) {
                if (!updatedModel.endDate) {
                    if (!updatedModel.startDate) {
                        updatedModel.endDate = endOfWeek(new Date());

                    } else {
                        updatedModel.endDate = endOfWeek(updatedModel.startDate);
                    }
                }
                updatedModel.endDate = endOfWeek(updatedModel.endDate);
                updatedModel.startDate = startOfWeek(updatedModel.endDate);
            }

        }
        setModel({ ...updatedModel });
    };

    const { startDate, endDate } = model;

    return (
        <div className={classes.filter}>

            <div className={classes.inline}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        id="startDate"
                        label=""
                        TextFieldComponent={ThemeTextField}
                        fullWidth
                        autoOk={true}
                        KeyboardButtonProps={{
                            "aria-label": "change date"
                        }}
                        value={
                            startDate
                        }
                        onChange={val => {
                            handleFieldOnChange('startDate', val);
                        }}
                        InputProps={{
                            startAdornment: <React.Fragment > <p className={classes.inlineText}>Start Date:</p></React.Fragment>,
                            disableUnderline: true,
                        }}
                    />
                </MuiPickersUtilsProvider>
            </div>

            <div className={classes.inline}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        id="endDate"
                        label=""
                        TextFieldComponent={ThemeTextField}
                        fullWidth
                        autoOk={true}
                        KeyboardButtonProps={{
                            "aria-label": "change date"
                        }}
                        value={
                            endDate
                        }
                        onChange={val => {
                            handleFieldOnChange('endDate', val);
                        }}
                        InputProps={{
                            startAdornment: <React.Fragment > <p className={classes.inlineText}>End Date:</p></React.Fragment>,
                            disableUnderline: true,
                        }}
                    />
                </MuiPickersUtilsProvider>
            </div>
        </div>
    );
};

export default StartEndDateFilter;
