import { createStyles, makeStyles } from "@material-ui/core";
import { createFilterOptions } from "@material-ui/lab";
import clsx from "clsx";
import * as React from "react";
import { useSelector } from "react-redux";
import { ApiAutoCompleteConfigItem } from "../../../interfaces/ApiAutoCompleteConfigItem";
import { FilterParams } from "../../../interfaces/common/FilterParams";
import { EmptyGuid } from "../../../utils/constants";
import ApiAutoComplete from "../../_Layout/ApiAutoComplete/ApiAutoComplete";
import ProgramAndProgramVersionAutoCompleteModal from "./ProgramAndProgramVersionAutoCompleteModal";

const filter = createFilterOptions<any>();
const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {
      width: "95%",
    },
    text: {
      padding: theme.spacing(1),
    },
    programVersionText: {
      paddingLeft: theme.spacing(3),
    },
    dialogHeader: {
      backgroundColor: theme.palette.site.secondary,
      "& .MuiTypography-root": {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: "12pt",
      },
    },
    saveButton: {
      width: 90,
    },
  })
);

const config: ApiAutoCompleteConfigItem = {
  acId: "programAndProgramVersions",
  cacheTime: 5,
  route: "/SystemCatalog/Program/GetProgramAndProgramVersionForCampus",
  requestType: "GET",
  body: null,
  hasUrlParams: true,
  useCache: false,
  storeInCache: true,
  isCampusSpecific: true,
  isUserSpecific: false,
  loadOnRender: true,
  defaultSelectedFirstItem: false,
  optionsMappedFx: (data: Array<any>) => {
    return data;
  },
};

const ProgramAndProgramVersionAutoComplete = (
  props: FilterParams & {
    addNew: any;
    setIsProgramVersion: (val?: boolean) => void;
    setIsCreditHour: (val?: boolean) => void;
    // isProgramVersion: boolean;
    setNewProgramVersionProgramId: any;
    setNewProgramProgramVersionName: any;
    setSelectedProgramProgramVersionId: any;
    refreshData: boolean;
  }
) => {
  const classes = useStyles({});
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );
  const [addModalOpen, setAddModalOpen] = React.useState(false);
  const onChange = (e: any) => {
    if (props.filterHandle) {
      props.filterHandle(e);
    }
  };

  let params = { campusId: userSelectedCampus };

  return (
    <React.Fragment>
      <div className={clsx(classes.divWidth)}>
        <ApiAutoComplete
          config={config}
          loading={userSelectedCampus ? false : true}
          reqParams={
            props.refreshData ? { ...params } : { campusId: userSelectedCampus }
          }
          classes={{
            option: "text-black",
          }}
          label={
            props.label
              ? props.label
              : "Search and Add Program and Program Version"
          }
          placeholder={
            props.valueFilter &&
            props.valueFilter.values &&
            props.valueFilter.values.length > 0
              ? ""
              : "Search and Add Program and Program Version"
          }
          onChange={(e: any) => {
            if (e === null || e === undefined) {
              props.setIsProgramVersion(undefined);
            } else {
              if (e && e.value && e.value === EmptyGuid) {
                props.setNewProgramProgramVersionName(e);
                props.setSelectedProgramProgramVersionId(e);
                props.setIsProgramVersion(false);
                setAddModalOpen(true);
              } else {
                props.setSelectedProgramProgramVersionId(e);
                props.setNewProgramProgramVersionName(e);
                props.setIsProgramVersion(e ? e.isProgramVersion : undefined);
              }
            }
            onChange(e);
          }}
          multiple={props.multiple ? props.multiple : false}
          valueFilter={props.valueFilter ? props.valueFilter : undefined}
          renderOption={(option) => {
            let optionName = option.text;
            return (
              <React.Fragment>
                <div
                  className={clsx(
                    classes.text,
                    option.isProgramVersion ? classes.programVersionText : ""
                  )}
                >
                  {optionName}
                </div>
              </React.Fragment>
            );
          }}
          filterOptions={(options: any, params: any) => {
            const filtered = (filter(options, params) as any[]).filter(
              (option: any) => option.value !== EmptyGuid
            );
            if (!!params.inputValue) {
              let newOption = options.filter(
                (val: any) => val.value === EmptyGuid
              );
              if (newOption.length === 0) {
                (newOption as any[]).push({
                  text: `Add "${params.inputValue}"`,
                  value: EmptyGuid,
                });
                (options as any[]).push(newOption[0]);
              } else {
                newOption[0].text = `Add "${params.inputValue}"`;
              }

              filtered.push(newOption[0]);
            }
            return filtered;
          }}
          freeSolo
        ></ApiAutoComplete>
      </div>
      <ProgramAndProgramVersionAutoCompleteModal
        open={addModalOpen}
        setOpen={setAddModalOpen}
        addNew={props.addNew}
        setIsProgramVersion={props.setIsProgramVersion}
        setIsCreditHour={props.setIsCreditHour}
        setNewProgramVersionProgramId={props.setNewProgramVersionProgramId}
      />
    </React.Fragment>
  );
};

export default ProgramAndProgramVersionAutoComplete;
