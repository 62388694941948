import React from 'react';
import { withRouter } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import {
	Table,
	TableBody,
	TableFooter,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	CardContent,
	Card,
	IconButton,
	Grid,
	Link,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { terminationWidgetApi } from '../../../src/api/student/academics/terminationWidgetApi';
import AccessManager from '../../components/security/AccessManager';
import { security } from '../../constants/Security/securityConstants';
import RefreshIcon from '@material-ui/icons/Refresh';
import PrintIcon from '@material-ui/icons/Print';
import LaunchIcon from '@material-ui/icons/Launch';
import makeStyles from './WidgetStyles';
import PostTransaction from '../student/financials/ledger/PostTransaction';
import { CustomSnackBarProps } from '../../interfaces/common/CustomSnackBarProps';
import axios, { CancelToken, CancelTokenSource } from 'axios';

const useStyles = makeStyles;

function PendingRefundsWidget(props: any) {
	const classes = useStyles();
	const [data, setData] = React.useState([] as any);
	const [selectedRowToPost, setSelectedRowToPost] = React.useState(null);
	const [selected, setSelected] = React.useState([] as any);
	const isSelected = () => false;
	const [open, setOpen] = React.useState(false);
	const [_snackBarProps, setSnackBarProps] =
    React.useState<CustomSnackBarProps>({
    	showSnackBar: false,
    	messageInfo: undefined,
    	variant: 'info',
    });

	const generatePrefixMessage = (row: any) => {
		if (row) {
			return (
				row.studentName +
        ' has $' +
        row.amountPending.toFixed(2) +
        ' of ' +
        row.fundType +
        ' pending to return.'
			);
		}
		return null;
	};

	const getRowClassName = (daysElapsed: any) => {
		if (daysElapsed >= 40) {
			return classes.seriousWarningRow;
		} else if (daysElapsed >= 35) {
			return classes.warningRow;
		} else {
			return classes.baseRow;
		}
	};

	const rowClick = (event: any, resultId: string) => {
		const selectedIndex = selected.indexOf(resultId);
		let newSelected: string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, resultId);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};

	const [pendingRefundsCancelToken, _setPendingRefundsCancelToken] =
    React.useState<CancelTokenSource>();
	const pendingRefundsCancelTokenRef = React.useRef(pendingRefundsCancelToken);

	const setPendingRefundsCancelToken = (tokenSource: CancelTokenSource) => {
		pendingRefundsCancelTokenRef.current = tokenSource;
		_setPendingRefundsCancelToken(tokenSource);
	};

	const loadPendingRefunds = (campusId: any) => {
		const cancelTokenSource = axios.CancelToken.source();
		setPendingRefundsCancelToken(cancelTokenSource);
		terminationWidgetApi
			.getPendingRefunds(campusId, cancelTokenSource.token)
			.then(
				(response: any) => {
					if (response) {
						if (
							response.data !== undefined &&
              response.data.result !== undefined
						)
							setData(response.data.result);
					}
				},
				(_exception: any) => {}
			);
	};

	const printRefundsDueReport = (campusId: any) => {
		terminationWidgetApi.printRefundsDueReport(campusId).then(
			(_response: any) => {},
			(_exception: any) => {}
		);
	};

	const campusId = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const resetPage = () => {
		setData([]);
		setSelectedRowToPost(null);
		setSelected([]);
		isSelected();
		setOpen(false);
	};
	React.useEffect(() => {
		if (campusId) {
			if (!!pendingRefundsCancelTokenRef.current) {
				console.log('going to cancel the request');
				pendingRefundsCancelTokenRef.current.cancel();
			}

			resetPage();
			loadPendingRefunds(campusId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [campusId]);

	const handleReloadButton = () => {
		loadPendingRefunds(campusId);
	};
	const handlePrintButton = () => {
		printRefundsDueReport(campusId);
	};

	return (
		<AccessManager
			allowedPermissions={[
				security.permissions.dashboard.ViewPendingRefundsWidget,
			]}
			renderNoAccess={() => null}
		>
			<Card className={classes.root}>
				<Typography className={clsx(classes.cardTitle)}>
					<Grid container direction="row" justify="center" alignItems="center">
						<Grid item xs={6}>
							<div className={classes.headerText}>REFUNDS DUE</div>
						</Grid>
						<Grid item xs={6} className={classes.widgetMenu}>
							<Typography className={classes.headerTextRight}>
                Pending: {data?.length}
							</Typography>
							<AccessManager
								allowedPermissions={[
									security.permissions.dashboard.PrintPendingRefunds,
								]}
								renderNoAccess={() => (
									<IconButton
										className={classes.iconBtn}
										aria-label="print"
										disabled={true}
										onClick={handlePrintButton}
									>
										<PrintIcon />
									</IconButton>
								)}
							>
								<IconButton
									className={classes.iconBtn}
									aria-label="print"
									onClick={handlePrintButton}
								>
									<PrintIcon />
								</IconButton>
							</AccessManager>
							<IconButton
								className={classes.iconBtn}
								aria-label="settings"
								onClick={handleReloadButton}
							>
								<RefreshIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Typography>
				<CardContent className={classes.cardContent}>
					<div className={classes.innerRoot}>
						<TableContainer component={Paper}>
							<Table
								className={classes.table}
								size="small"
								aria-label="a dense table"
							>
								<TableHead>
									<TableRow>
										<TableCell align="left" className={classes.headCell}>
                      Name
										</TableCell>
										<TableCell align="left" className={classes.headCell}>
                      Fund
										</TableCell>
										<TableCell align="left" className={classes.headCell}>
                      Days Elapsed
										</TableCell>
										<TableCell align="left" className={classes.headCell}>
                      Amount Pending
										</TableCell>
										<TableCell align="left" className={classes.headCell}>
                      Actions
										</TableCell>
										{/* <TableCell align="center" className={classes.headCell}>
											<Checkbox
												className={classes.checkBoxStyle}
												value="uncontrolled"
												color="primary"
												onClick={selectAllClick}
												inputProps={{ "aria-label": "uncontrolled-checkbox" }}
											/>
										</TableCell> */}
									</TableRow>
								</TableHead>
								<TableBody>
									{/* <CenterLoader></CenterLoader> */}
									{data !== null && data.length > 0 ? (
										data.map((row: any) => {
											const isRowSelected = isSelected();
											return (
												<TableRow
													key={row.studentEnrollmentId}
													onClick={(event) =>
														rowClick(event, row.studentEnrollmentId)
													}
													role="checkbox"
													selected={isRowSelected}
													className={getRowClassName(row.daysSinceWithdrawal)}
												>
													<TableCell align="left" className={classes.bodyCell}>
														<Link
															className={classes.linkDesign}
															component="button"
															onClick={() => {
																let urlParams = new URLSearchParams(
																	props.history.location.search
																);
																let studentIdQueryId = 'studentId';
																let campusIdQueryId = 'campusId';

																//set url parameters for campusId and studentId
																urlParams.set(
																	studentIdQueryId,
                                  row.studentId as any
																);
																urlParams.set(campusIdQueryId, campusId);
																props.history.push({
																	pathname: '/student/1/financials/ledger',
																	search: urlParams.toString(),
																});
															}}
														>
															{row.studentName}
														</Link>
													</TableCell>
													<TableCell align="left" className={classes.bodyCell}>
														{row.fundType}
													</TableCell>
													<TableCell align="left" className={classes.bodyCell}>
														{row.daysSinceWithdrawal}
													</TableCell>
													<TableCell align="left" className={classes.headCell}>
                            ${row.amountPending.toFixed(2)}
													</TableCell>
													<TableCell align="left" className={classes.headCell}>
														<AccessManager
															allowedPermissions={[
																security.permissions.dashboard
																	.UpdatePendingRefundsWidget,
															]}
															renderNoAccess={() => null}
														>
															<IconButton
																className={classes.iconBtn}
																aria-label="settings"
																onClick={(e: any) => {
																	setSelectedRowToPost(row);
																	setOpen(true);
																	e.stopPropagation();
																}}
															>
																<LaunchIcon />
															</IconButton>
														</AccessManager>
													</TableCell>
													{/* <TableCell align="center">
														<Checkbox
															className={classes.checkBoxStyle}
															value="uncontrolled"
															color="primary"
															inputProps={{
																"aria-label": "uncontrolled-checkbox",
															}}
															checked={isRowSelected}
														/>
													</TableCell> */}
												</TableRow>
											);
										})
									) : (
										<TableRow>
											<TableCell align="center" colSpan={6}>
                        No Data
											</TableCell>
										</TableRow>
									)}
								</TableBody>
								<TableFooter className={classes.footerOutline}>
									<TableRow key={'footer'}>
										<TableCell className={classes.footerCell}>Total</TableCell>
										<TableCell className={classes.footerCell}></TableCell>
										<TableCell className={classes.footerCell}></TableCell>
										<TableCell className={classes.footerCell}>
                      $
											{data.length > 0
												? data
													.map((d: any) => Number(d.amountPending))
													.reduce(function (a: any, b: any) {
														return a + b;
													})
													.toFixed(2)
												: '0.00'}
										</TableCell>
										<TableCell className={classes.footerCell}></TableCell>
									</TableRow>
								</TableFooter>
							</Table>
						</TableContainer>
					</div>
					{selectedRowToPost && (
						<PostTransaction
							open={open}
							setOpen={setOpen}
							refreshLedgerGrid={handleReloadButton}
							setSnackBar={setSnackBarProps}
							enrollmentId={(selectedRowToPost as any).enrollmentId}
							campusId={(selectedRowToPost as any).campusId}
							prefixDialogComponent={() => {
								return (
									<Typography className={classes.typographyMessage}>
										{generatePrefixMessage(selectedRowToPost)}
									</Typography>
								);
							}}
						/>
					)}
				</CardContent>
			</Card>
		</AccessManager>
	);
}

PendingRefundsWidget.propTypes = {
	className: PropTypes.string,
	params: PropTypes.object,
};
export default withRouter(PendingRefundsWidget);
