import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import * as React from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";
import { FilterParams } from "../../interfaces/common/FilterParams";

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& label.Mui-focused": {
				color: theme.palette.primary.white,
			},
		},
	})
)(TextField);

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {},
	})
);
const config: ApiAutoCompleteConfigItem = {
	acId: "moduleId",
	cacheTime: 5,
	route: "/SystemCatalog/Module/Get",
	requestType: "GET",
	body: null,
	hasUrlParams: true,
	useCache: true,
	storeInCache: true,
	isCampusSpecific: false,
	isUserSpecific: false,
	loadOnRender: true,
	defaultSelectedFirstItem: false,
	optionsMappedFx: (data: Array<any>) => {
		return data;
	},
};

const ModuleAutoComplete = (props: FilterParams & { useOnlyDocumentsModule?: boolean }) => {
	const classes = useStyles({});
	const inputRef: any = useRef(null);
	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const OnChange = (e: any) => {
		if (props.filterHandle) {
			props.filterHandle(e);
		}
	};

	if (props.useOnlyDocumentsModule && props.useOnlyDocumentsModule === true) {
		config.route = "/SystemCatalog/Module/GetDocumentModules"
	}

	const [disabled, setIsDisabled] = React.useState<any | null>();
	React.useEffect(() => {
		setIsDisabled(props.disabled);
	}, [props.disabled]);

	return (
		<div>
			<ApiAutoComplete
				config={config}
				loading={userSelectedCampus ? false : true}
				disabled={disabled}
				reqParams={{}}
				classes={{
					option: "text-black",
				}}
				label={props.label ? props.label : "Module"}
				onChange={(e: any) => {
					OnChange(e);
				}}
				multiple={false}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
				inputRef={props.inputRef ? props.inputRef : inputRef}
				error={props.error}
				helperText={props.helperText}
				inputName={props.name}
			></ApiAutoComplete>
		</div>
	);
};
export default ModuleAutoComplete;
