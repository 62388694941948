import * as React from "react";
import { useRef } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";

import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";
import { EmptyGuid } from "../../utils/constants";

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& label.Mui-focused": {
				color: theme.palette.primary.white,
			},
		},
	})
)(TextField);

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {},
	})
);

const filter = createFilterOptions<any>();
const config: ApiAutoCompleteConfigItem = {
	acId: "documentTypeId",
	cacheTime: 0,
	route: "/SystemCatalog/DocumentType/Get",
	requestType: "GET",
	body: null,
	hasUrlParams: false,
	useCache: false,
	storeInCache: false,
	isCampusSpecific: true,
	isUserSpecific: false,
	loadOnRender: true,
	defaultSelectedFirstItem: false,
	optionsMappedFx: (data: Array<any>) => {
		return data;
	},
};

const DocumentTypeAutoComplete = (props: FilterParams) => {
	const classes = useStyles({});
	const inputRef: any = useRef(null);
	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const OnChange = (e: any) => {
		if (props.filterHandle) {
			props.filterHandle(e);
		}
	};

	return (
		<div>
			<ApiAutoComplete
				config={config}
				loading={userSelectedCampus ? false : true}
				classes={{
					option: "text-black",
				}}
				label={props.label ? props.label : "Document Type"}
				onChange={(e: any) => {
					OnChange(e);
				}}

				multiple={false}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
				inputRef={props.inputRef ? props.inputRef : inputRef}
				error={props.error}
				helperText={props.helperText}
				inputName={props.name}
				filterOptions={(options: any, params: any) => {
					const filtered = (filter(options, params) as any[]).filter(
						(option: any) => option.value !== EmptyGuid
					);
					if (!!params.inputValue) {
						filtered.push({
							text: `Add "${params.inputValue}"`,
							value: EmptyGuid,
						});
					}
					return filtered;
				}}
				freeSolo
			></ApiAutoComplete>
		</div>
	);
};
export default DocumentTypeAutoComplete;
