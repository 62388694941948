import API from "../../apiWrapper";
import { StudentAward } from "../../../interfaces/student/studentAccounts/StudentAward";
export const getStudentAward = (stuEnrollId: string) => {
	return API()
		.get("/FinancialAid/StudentAward/GetStudentAwardsGivenEnrollment", {
			params: {
				stuEnrollId,
			},
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return null;
			}
		);
};
export const IsAfaIntegrationEnabled = (keyName: string, campusId: string) => {
	return API()
		.get(
			"/SystemCatalog/SystemConfigurationAppSetting/GetAppSettingValueByCampus",
			{
				params: {
					keyName,
					campusId,
				},
			}
		)
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return null;
			}
		);
};
export const getLedgerForAward = (studentAwardId: string) => {
	return API()
		.get("/FinancialAid/StudentAward/GetAwardLedgerGivenSudentAwardId", {
			params: {
				studentAwardId,
			},
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return null;
			}
		);
};

export const deleteAward = (awardId: string, campusId: string) => {
	return API()
		.delete("/FinancialAid/StudentAward/DeleteAward", {
			data: {
				entityId: awardId,
				campusId: campusId,
			},
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};

export const upsertAward = (award: StudentAward) => {
	return API()
		.post("/FinancialAid/StudentAward/CreateUpdateAward", award, {
			responseType: "json",
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};

export const getEndUnitDefault = (stuEnrollId: string) => {
	return API()
		.get("/FinancialAid/StudentAward/GetEndUnitDefault", {
			params: {
				stuEnrollId,
			},
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return null;
			}
		);
};
