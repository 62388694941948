import * as React from "react";
import { useRef } from "react";
import { ProgramVersionApiAutoComplete } from "../../constants/SystemCatalog/ProgramVersionApiAutoComplete";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { StatesApiAutoComplete } from "../../constants/SystemCatalog/StatesApiAutoComplete";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {
			width: "95%",
		},
		fullWidth: { width: "100%", },
	})
);
export interface ProgramVersionProps extends FilterParams {
	campusId?: string;
	stateId?:string;
    reportId?:string;
}

const StateBoardProgramVersionAutoComplete = (props: ProgramVersionProps) => {
	const classes = useStyles({});
	const inputRef: any = useRef(null);
	
	const [campusId, setCampusId] = React.useState<string | undefined | null>(props.campusId ?? null);
	const [stateId, setStateId] = React.useState<string | undefined | null>(props.stateId ?? null);
    const [reportId, setReportId] = React.useState<string | undefined | null>(props.reportId ?? null);

	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const PrgVerOnChange = (e: any) => {
		if (props.filterHandle) {
			props.filterHandle(e);
		}
	};

	React.useEffect(() => {
		setCampusId(props.campusId ?? null);
        setStateId(props.stateId ?? null);
        setReportId(props.reportId ?? null);
	}, [props.campusId, props.stateId, props.reportId]);

	const [disabled, setIsDisabled] = React.useState<any | null>();
	React.useEffect(() => {
		setIsDisabled(props.disabled);
	}, [props.disabled]);

	return (
		<div className={props.params?.fullWidth ? clsx(classes.fullWidth) : clsx(classes.divWidth)}>
			<ApiAutoComplete
				disabled={disabled}
				config={StatesApiAutoComplete.mappedProgramVersionsConfig}
				loading={userSelectedCampus ? false : true}
				reqParams={
					{
						campusId: campusId ? campusId : userSelectedCampus,
						stateId: stateId,
                        reportId: reportId
					}
				}
				classes={{
					option: "text-black",
				}}
				label={props.label ? props.label : "Program Version"}
				placeholder={
					props.valueFilter &&
						props.valueFilter.values &&
						props.valueFilter.values.length > 0
						? ""
						: "Select a program version"
				}
				onChange={(e: any) => {
					PrgVerOnChange(e);
				}}
				multiple={props.multiple ? props.multiple : false}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
				inputRef={props.inputRef ? props.inputRef : inputRef}
				error={props.error}
				helperText={props.helperText}
				inputName={props.name}
				showIncludeAllValue={props.showIncludeAllValue}
				chipSize={props.chipSize}
				maxTags={props.maxTags}
				includeAllValue={props.includeAllValue ? props.includeAllValue : false}
				defaultOptionText={props.defaultOptionText ? props.defaultOptionText: undefined}
			></ApiAutoComplete>
		</div>
	);
};
export default StateBoardProgramVersionAutoComplete;
