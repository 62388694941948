import * as React from "react";
import { useEffect, useRef } from "react";
import { apiAutoCompleteConfig } from "../../constants/apiAutoComplete.config";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";
import { createFilterOptions } from "@material-ui/lab";
import { Chip } from "@material-ui/core";

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& label.Mui-focused": {
				color: theme.palette.primary.white
			}
		}
	})
)(TextField);


const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {},
        divWidth: {
            width: "95%"
        },
        searchIcon: {
            fontSize: 30,
            paddingLeft: 10,
            color: theme.palette.icon.primary,
            cursor: "pointer"
        }
    })
);

const config: ApiAutoCompleteConfigItem = {
	acId: "permissionTemplates",
	cacheTime: 5,
	route: "/Security/PermissionTemplate/GetPermissionTemplates",
	requestType: "GET",
	body: null,
	hasUrlParams: null,
	useCache: false,
	storeInCache: false,
	isCampusSpecific: false,
	isUserSpecific: true,
	loadOnRender: true,
	defaultSelectedFirstItem: false,
	optionsMappedFx: (data: Array<any>) => {
		return data;
	},
};

export interface PermissionTemplateProps extends FilterParams {}


const PermissionTemplateAutoComplete = (props: FilterParams) => {
	const classes = useStyles({});

	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const [valueFilter, setValueFilter] = React.useState<any>(null);

	const templateOnChange = (e: any) => {
		if (props.filterHandle) {
			props.filterHandle(e);
		}
	};
	const filterOptions = createFilterOptions({
		stringify: (option: any) => option.name
	});


	return (
		<div className={clsx(classes.divWidth)}>
			<ApiAutoComplete
				id={props.id}
				loading={userSelectedCampus ? false : true}
				config={config}
				classes={{
					option: "text-black"
				}}
				label="Choose a template"
				filterOptions={filterOptions}
				renderTags={(value: any, getTagProps) => {
					return value.map((option: any, index: number) => (
						<Chip
							disabled={index === 0}
							label={option.name}
							{...getTagProps({ index })}
						/>
					));
				}}
				optionName={(option: any) => {
					if (option && option.name) return option.name;
				}}
				onChange={(e: any) => {
					templateOnChange(e);
				}}
				disableClearable={true}
				multiple={true}
				valueFilter={valueFilter}
				includeAllValue={props.includeAllValue}
			></ApiAutoComplete>
		</div>
	);
};
export default PermissionTemplateAutoComplete;