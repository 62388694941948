export const StudentGroupsApiAutoComplete = {
  config: {
    acId: "studentGroups",
    cacheTime: 5,
    route: "/AcademicRecords/StudentGroups/GetStudentGroupsByCampus",
    requestType: "GET",
    body: null,
    hasUrlParams: true,
    useCache: false,
    storeInCache: false,
    isCampusSpecific: true,
    isUserSpecific: false,
    loadOnRender: true,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
      return data;
    },
  },
};

export const StudentGroupsMultiCampusApiAutoComplete = {
  config: {
    acId: "studentGroupsMultiCampus",
    cacheTime: 5,
    route: "/AcademicRecords/StudentGroups/GetStudentGroupsAsync",
    requestType: "GET",
    body: null,
    hasUrlParams: true,
    useCache: false,
    storeInCache: false,
    isCampusSpecific: true,
    isUserSpecific: false,
    loadOnRender: true,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
      return data;
    },
  },
};
