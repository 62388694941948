import React, { useEffect } from "react";
import {pdfPreviewActions} from '../../../actions/pdfPreviewActions';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { FileUtil } from '../../../utils/file';
import { Page } from "react-pdf";
import { Document } from "react-pdf/dist/entry.webpack";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";
import clsx from "clsx";

import {
    Dialog,
    Button,
    DialogActions,
    DialogTitle,
    DialogContent,
    makeStyles,
    createStyles
  } from "@material-ui/core";

  const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .MuiPaper-rounded": {
        borderRadius: 0,
      },
      "& .MuiDialogContent-root": {
        minHeight: 250,
        overflowY:"hidden"
      },
     
    },
    rootNoPdf: {
      "& .MuiPaper-rounded": {
        borderRadius: 0,
      },
      "& .MuiDialogContent-root": {
        minHeight: 250,
      },     
    },
    dialogHeader: {
      backgroundColor: theme.palette.site.secondary,
      "& .MuiTypography-root": {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: "12pt",
      },
    },
    buttonSection: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
    saveButton: {
      width: 130,
    },
    fullWidthField: {
      width: "100%",
    },
    preview: {
      height: 140,
    },
    noPreviewZone: {
      height: 500,
      textAlign: "center",
      padding: 100,
      fontSize: "1.5em",
    },
    buttonNoPreview: {
      height: "100%",
      width: "100%",
      fontSize: "2em",
    },
    downloadIcon: {
      width: "100%",
      fontSize: "10em",
    },
    pageManagement: {
      width: "50px",
      height: "inherit",
      float: "left",
      display: "inline",
      textAlign:"center",
      [theme.breakpoints.down('md')] :{
        paddingTop:theme.spacing(10)
      },
      [theme.breakpoints.between('md', 'lg')] :{
        paddingTop:theme.spacing(20)
      },
      [theme.breakpoints.up('lg')] :{
        paddingTop:theme.spacing(40)
      }
      
    },
    pdf: {
      width: "calc(100% - 100px)",
      float: "left",
      display: "inline",
      height: "inherit",
      overflowY: "auto",
      padding:" 0 2em 0 2em",
      boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)"
    },
    pageInfo: {
      width: "100%",
      textAlign:"center",
      padding:".5em 0 .5em 0",
      marginTop:"1em"
    },
    pdfWrapper:{
      width:"100%",
      [theme.breakpoints.down('md')] :{
        height:"400px",
      },
      [theme.breakpoints.between('md', 'lg')] :{
        height:"500px",
      },
      [theme.breakpoints.up('lg')] :{
        height:"600px",
      }
     
      
    },
    GridItemPdf:{
      height:"calc(100% - 100px)"
    }
    , Disabled:{
      color: "rgba(0, 0, 0, 0.26)"
    }
  })
);

const PdfPreview = (props: any) => {
    
  const classes = useStyles({});
  const [numPages, setNumPages] = React.useState<any>(null);
  const [pageNumber, setPageNumber] = React.useState<any>(1);

  function onDocumentLoadSuccess(e: any) {
    setNumPages(e.numPages);
  }

  const handleClose = () => {
      props.hidePreview();
  };

  const handleDownload = () => {
    FileUtil.DownloadFromObjectUrl(props.url,props.title);
  };

  useEffect(() => {
    window.onunload = () => {
      handleClose();
    }
  });

  const handlePrevPage = () => {
    if ( pageNumber > 1 ){
        setPageNumber(pageNumber - 1)
      }
  };
    
  const handleNextPage = () => {
    if ( numPages && pageNumber < numPages ){
        setPageNumber(pageNumber + 1)
      }
  };

  return ReactDOM.createPortal (
    <Dialog
    maxWidth={'lg'}
    open={props.open}
    onClose={handleClose}
    aria-labelledby="draggable-dialog-title"
    >
    <DialogTitle>Preview</DialogTitle>
    <DialogContent >
    <div className = { classes.pdfWrapper }>
      <div className = { clsx(classes.pageManagement,( pageNumber === 1 ? classes.Disabled : "")) }
        onClick={(e: any) => {
          handlePrevPage();
        }}>
        <ChevronLeft />
      </div>

      <Document
        file={props.url}
        onLoadSuccess={onDocumentLoadSuccess}
        className={classes.pdf}
        >
      <Page pageNumber={pageNumber} pageIndex={pageNumber} />
      </Document>
      <div className={clsx(classes.pageManagement,( pageNumber === numPages ? classes.Disabled : ""))}
        onClick={(e: any) => {
          handleNextPage();
        }}>
        <ChevronRight />
      </div>
    </div>
    <div className={classes.pageInfo}>
      <p>
        Page {pageNumber} of {numPages}
      </p>
    </div>
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={handleClose} color="secondary"
                variant="contained"
                type="button">
        Cancel
      </Button>
      <Button color="primary" variant="contained"
                type="button" onClick={handleDownload}>
        Download
      </Button>
    </DialogActions>
  </Dialog>
  ,document.body);
};

const mapStateToProps = (state:any) => {
  return {
    open : state.pdf_preview.open,
    url : state.pdf_preview.url,
    title : state.pdf_preview.title
  }
 }
 
 
 let actionCreators = {
  showPreview:pdfPreviewActions.showPreview,
  hidePreview:pdfPreviewActions.hidePreview
 };
 
 export default connect(mapStateToProps,actionCreators)(PdfPreview);
