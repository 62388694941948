import * as React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import AdvantageViewer from '../../components/Common/AdvantageViewer';
import getAdvantageLink from '../../utils/getAdvantageLink';
import { useSelector } from 'react-redux';
import { Campus } from '../../interfaces/systemCatalog/ICampus';
import { IT1098InputParams } from '../../interfaces/academicRecords/IT1098InputParams';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import {
	FormControlLabel, Switch, Grid, Paper, Typography,
	CardContent,
	Card,
	Button,
	Checkbox 
} from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import clsx from "clsx";
import * as T1098API from '../../../src/api/Common/T1098API';
import CustomSnackbar from "../../components/notifications/CustomSnackbar";
import { CustomSnackBarProps } from "../../interfaces/common/CustomSnackBarProps";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Portal
} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import YearAutoComplete from "../../components/AutoComplete/YearAutoComplete";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			paddingTop: theme.spacing(3),
			minHeight: "100%",
			height: "100%",
			maxWidth: "1050px",
			margin: "auto"
		},
		"& .makeStyles-content": {
			backgroundColor: theme.palette.background.paper
		},
		paperRoot: {
			padding: theme.spacing(3, 2),
			width: "100%",
			minHeight: "100%",
			height: "100%",
			overflowX: "hidden",
			overflowY: "auto",
			flexDirection: "column",
			backgroundColor: theme.palette.background.paper
		},
		startDateField: {
			width: "100%",
		},
		cardTitle: {
			fontSize: 17,
			color: "Black",
			textDecoration: "bold",
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1)
		},
		buttonColorPrimary: {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.primary.contrastText,
			"&:hover": {
				backgroundColor: theme.palette.primary.main
			},
			textDecoration: "bold",
			padding: theme.spacing(1),
			whiteSpace: "nowrap",
			margin: theme.spacing(1),
		},
		textField: {
			marginRight: theme.spacing(1),
			width: "100%",
			"& ::-webkit-inner-spin-button, ::-webkit-outer-spin-button": {
				WebkitAppearance: "none",
				margin: 0,
				MozAppearance: "textfield"
			}
		},
		textTitle: {
			fontSize: 17,
			color: "Black",
			textDecoration: "bold",
			fontWeight: 900,
			padding: theme.spacing(1, 1)
		},		
		textTitleL: {
			fontSize: 17,
			color: "Black",
			textDecoration: "underline",
			cursor: "pointer",
			fontWeight: 900,
			padding: theme.spacing(1, 1)
		},textBody: {
			fontSize: 17,
			color: "Black",
			textDecoration: "bold",
			fontFamily: 'Roboto',
			padding: theme.spacing(1, 1)
		},
		list: {
			fontSize: 17,
			color: "Black",
			textDecoration: "bold",
			marginLeft: "100px",
			fontFamily: 'Roboto',
		},
		dialogHeader: {
			backgroundColor: theme.palette.site.secondary,
			"& .MuiTypography-root": {
				fontSize: theme.spacing(2),
			},
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
		},
		historyWrapper: {
			padding: theme.spacing(.5),
		},
		dialogContent: {
			"& .MuiDropzoneArea-invalid": {
			  borderColor: "#000000 !important",
			  backgroundImage:
				"repeating-linear-gradient(-45deg, #000000, #000000 25px, #ffffff 25px, #ffffff 50px) !important",
			},
		  },   
		  
		  rootDialog: {
			"& .MuiPaper-rounded": {
			  borderRadius: theme.spacing.borderradius,
			},
			"& .MuiDialogContent-root": {
			  minHeight: 200,
			},
			fontFamily: 'Roboto',
			fontSize: 17,
			color: "Black",
			textDecoration: "bold",
		  },
	})
);

const C_1098T = (props: any) => {
	const thisCYear = (new Date()).getFullYear();
	const classes = useStyles({});
	const studentId = useSelector<any, any>(
		(state: any) => state.student.studentId
	);
	const campusId = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);
	const user = useSelector((state: any) => state.session.user);
	const [thisYear, setthisYear] = React.useState<number>(thisCYear);	

	const initialModel: IT1098InputParams = {
		UserId: user.userId,
		CampusId: campusId,
		Year: thisYear,
        
	};

	const [checkZero, setcheckZero] = React.useState<boolean>(false);
	const [checkOne, setcheckOne] = React.useState<boolean>(false);
	const [hasYear, sethasYear] = React.useState<boolean>(true);
	const [model, setModel] = React.useState<IT1098InputParams>(initialModel);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	

	const handleFieldOnChange = (
		fieldId: string,
		value: any,
		mapFunction?: Function
	) => {
		let updatedModel = model;
		let newValue = mapFunction ? mapFunction(value) : value;
		(updatedModel as any)[fieldId] = newValue;
		if (fieldId == "Year" && newValue !== undefined && newValue !== null ) 
		{
			sethasYear(true);
			setthisYear(newValue);
		} 
		else 
		{	
			sethasYear(false);
		} 
		setModel({ ...updatedModel });
	};

	const handleFieldOnChangecheck = (
		fieldId: string,
		value: any,
		mapFunction?: Function
	) => {
		if (fieldId == "checkZero") {
			setcheckZero(value);
		}else {setcheckOne(value);}
      
	};


	const handleClose = () => {
		setMessagePropsProps(() => {
			return {
					 
					  showMessage: false,
					  messageInfo: '',
			};
			  });
	};
	const [messageProps, setMessagePropsProps] = React.useState<any>(
		{
		  showMessage: false,
		  messageInfo: undefined,
		  
		}
	  );
	const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
		{
		  showSnackBar: false,
		  messageInfo: undefined,
		  variant: "info",
		}
	  );
	  const onSubmit = async () => {
		setIsLoading(true);
		await T1098API.T1098InputAPI(initialModel).then(
			  (response: any) => {
				 
				if (response && response.data && response.data.resultStatus === 0) {
				  if (response.status === 200) {
						setSnackBarProps(() => {
					  return {
								variant: "success",
								showSnackBar: true,
								messageInfo: response.data.resultStatusMessage,
					  };
						});

						setMessagePropsProps(() => {
							return {
									 
								     showMessage: true,
									  messageInfo: response.data.resultStatusMessage,
							};
							  });
					
				  } else {
						setSnackBarProps(() => {
					  return {
								variant: "error",
								showSnackBar: true,
								messageInfo: response.data.resultStatusMessage,
					  };
						});
						setMessagePropsProps(() => {
							return {
									 
								     showMessage: true,
									  messageInfo: response.data.resultStatusMessage,
							};
							  });
				  }
				} else {
				  setSnackBarProps(() => {
						return {
					  variant: "error",
					  showSnackBar: true,
					  messageInfo: response.data,
						};
				  });

				  setMessagePropsProps(() => {
						return {
							 
							 showMessage: true,
							  messageInfo: response.data.resultStatusMessage,
						};
					  });
				}
			  },
			  (exception: any) => {
				setSnackBarProps(() => {
				  return {
						variant: "error",
						showSnackBar: true,
						messageInfo: exception,
				  };
				});
				setMessagePropsProps(() => {
					return {
							 
							 showMessage: true,
							  messageInfo: exception,
					};
					  });
			  }
		);
		setIsLoading(false);
	};


	const onSubmitReport = async () => {
		setIsLoading(true);
		await T1098API.T1098ExceptionsAPI(initialModel);
		setIsLoading(false);
	
	};

	const ruleList = [
		  {description: 'Verify the Transaction Types that have been selected for the 1098T process are accurate. '
		  +'To access this information navigate to Maintenance\\Student Accounts\\Transaction Types and select '
		  +'the Transaction Type on the left to review. Once selected, confirm the 1098T check box is populated. '
		  +'If the box is not checked, the Transaction Type will not be included in the upload.', key: 0},
		  {description: 'Verify the Programs selected for the 1098T process are accurate. To access this information,'
		  +' navigate to Maintenance\\Academics\\Programs and select the "Include in 1098T extract" check box.'
		  +' If the box is not checked, the Program will not be included in the upload.', key: 1},
		  {description: 'Select "View Exceptions" to review data that will not be included in 1098T processing.', key: 2},
		  {description: 'Select check boxes to confirm Programs and Transaction Types have been verified.', key: 3},
		  {description: 'Select the calendar year for 1098T processing.', key: 4},
		  {description: 'Select "Upload" to send data to FAME.', key: 5}
	];
	return (
		<div className={classes.root}>
			  <Paper className={classes.paperRoot}>
				<div>
					<Card>
						<Typography className={clsx(classes.cardTitle)}>1098T Service</Typography>
						<CardContent>
							<Typography className={clsx(classes.textTitle)}>
							This tool should only be run from January 1st to March 31st. Data sent 
							during any other time will NOT be processed by FAME.<br />
							To request a duplicate or make corrections to a 1098T, click<a className={clsx(classes.textTitleL)}onClick={()=> window.open("https://support.fameinc.com/hc/en-us/articles/115002597811", "_blank")}>here</a></Typography>
						
							<Typography className={clsx(classes.textBody)}>
								<br />All student data, including demographic information, 
							attendance and ledger activity must be entered in Advantage for the tax year selected</Typography>
							<Typography className={clsx(classes.textBody)}>
								<br />The 1098T processing software will gather all data
							 required and transmit the data to FAME. Then FAME will:</Typography>
							<Typography className={clsx(classes.list)}>
								<ul>
   							 <li>review your data, notify you of any corrections needed, and process those corrections,</li>
   							 <li> generate the 1098T forms, and mail them to your students,</li>
   							 <li>electronically submit all 1098T's to the IRS,</li>
							 <li>provide the school a 1098T Summary Status Report and a copy of each 1098T reported.</li>
 							 </ul>
							 
							</Typography>
							<Typography className={clsx(classes.textTitle)}>
							  Instructions:</Typography>
					
							 <ol  className={clsx(classes.list)}>
								{ruleList.map(rule => {
									return (
										<li key={rule.key}>{rule.description}</li>
									);
								})}
							</ol>
							<Grid >
								{/* <CustomSnackbar className={clsx(classes.textTitle)}
									variant={snackBarProps.variant}
									message={snackBarProps.messageInfo}
									open={snackBarProps.showSnackBar}
									onClose={(event?: React.SyntheticEvent, reason?: string) => {
										setSnackBarProps((props: any) => {
											return { ...props, showSnackBar: false };
										});
									}}
								></CustomSnackbar>
 */}

								<Dialog
									open={messageProps.showMessage}
									onClose={handleClose}
									className={classes.rootDialog}
									aria-labelledby="form-dialog-title"
									fullWidth={true}
									maxWidth='md'
									disableBackdropClick
								>
									<DialogTitle
										id="form-dialog-title"
										className={classes.dialogHeader}
									>
										{"1098T Data"}
										<IconButton 
											aria-label="close" 
											className={classes.closeButton} 
											onClick={handleClose}
										>
											<CloseIcon />
										</IconButton>
									</DialogTitle>
									<DialogContent className={classes.dialogContent}>
										{messageProps.messageInfo}
									</DialogContent>
								</Dialog>


								<FormControlLabel 
									label=	{<Typography className={clsx(classes.textTitle)}>I have verified that all Program Versions, to be included in the FAME 1098T extract, are selected.</Typography>}
									control={
										<Checkbox
											checked={checkZero}
											value="checkZero"
											onChange={(e: any) => {
												handleFieldOnChangecheck(
													"checkZero",
													e ? e.target.checked : undefined
												);
											}}
											color="primary"
										/>
									}
								/>
							</Grid>

							<Grid >
								<FormControlLabel 
								     
									 label=	{<Typography className={clsx(classes.textTitle)}>I have verified that all Transaction Types, to be included in the FAME 1098T extract, are selected.</Typography>}
									control={
										<Checkbox 
											checked={checkOne}
											value="checkOne"
											onChange={(e: any) => {
												handleFieldOnChangecheck(
													"checkOne",
													e ? e.target.checked : undefined
												);
											}}
											color="primary"
										/>
									}
								/>
							</Grid>
							<Grid  container wrap='nowrap'>
								<Button
									className={classes.buttonColorPrimary}
									onClick={onSubmitReport}
									size="small"
									type="submit"
									color="primary"
									disabled={isLoading}
								>View Exceptions</Button>
		
								<Grid item xs={6} sm={3} md={3}>
									<YearAutoComplete
										disabled={false}
										label="Year"
										name='Year'
									
										valueFilter={
                                    model?.Year
                                    	? {
                                    		key: 'value',
                                    		values: [model?.Year]
                                    	}
                                    	: undefined
										}
										filterHandle={(v: any) => {
											handleFieldOnChange('Year', v ? v.value : null);
										}}
										params={{
											//offset: 3,
											t1098: 'true'
										}}
									/>
								
								</Grid>
								<Button
									className={classes.buttonColorPrimary}
									onClick={onSubmit}
									size="small"
									type="submit"
									color="primary"
									disabled={!checkOne || !checkZero || isLoading || !hasYear}
								>Upload</Button>
							</Grid>
						</CardContent>
						
					</Card>
				</div>
			</Paper>
		</div>
	);
};

export default C_1098T;
