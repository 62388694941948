import React from "react";
import {
	TableRow,
	TableCell,
	IconButton,
	Collapse,
	Box,
	TableBody,
	makeStyles,
	createStyles,
	Button,
} from "@material-ui/core";
import { IStudentDocumentType } from "../../../interfaces/student/documents/IStudentDocumentType";
import {
	Order,
	CustomHeadCell,
} from "../../../interfaces/common/Table/CustomHeadCell";
import { IStudentDocument } from "../../../interfaces/student/documents/IStudentDocument";

import { KeyboardArrowUp, KeyboardArrowDown, Save } from "@material-ui/icons";
import CustomTableHeader from "../../../components/Tables/CustomTableHeader";
import CustomTableOptions from "../../../components/Tables/CustomTableOptions";
import * as TableFunctions from "../../../utils/tableFunctions";
import clsx from "clsx";
import DocumentPreview from "./DocumentPreview";

import { useSelector } from "react-redux";
import DocumentGridDocumentRow from "./DocumentGridDocumentRow";

type DocumentTypeRowProps = {
	row: IStudentDocumentType;
	confimationModalProps: any;
	setConfirmationModalProps: any;
	setSnackBarProps: any;

	refreshDocumentGrid: any;
};

const useStyles = makeStyles((theme: any) =>
	createStyles({
		tableWrapper: {
			width: "100%",
		},
		rowOpenBottom: {
			borderBottom: "2pt solid",
			borderBottomColor: theme.palette.site.secondary,
		},
		rowOpenRight: {
			borderRight: "2pt solid",
			borderRightColor: theme.palette.site.secondary,
		},
		rowOpenLeft: {
			borderLeft: "2pt solid",
			borderLeftColor: theme.palette.site.secondary,
		},
		lineThru: {
			textDecoration: "line-through",
		},
	})
);
const DocumentGridTypeRow = (props: DocumentTypeRowProps) => {
	const classes = useStyles({});
	const { row } = props;
	const [childrenOrder, setChildrenOrder] = React.useState<Order>("asc");
	const [childrenOrderBy, setChildrenOrderBy] = React.useState<
		keyof IStudentDocument
	>("name");
	const [open, setOpen] = React.useState(false);

	const [fileUri, setFileUri] = React.useState<string>("");
	const [fileExtension, setFileExtension] = React.useState<string>("");
	const [fileName, setFileName] = React.useState<string>("");
	const [openPreview, setOpenPreview] = React.useState(false);

	const isSupportUser = useSelector(
		(state: any) => state.session.user.isSupportUser
	);

	const [isArchiveHidden, setIsArchiveHidden] = React.useState<
		true | undefined
	>(isSupportUser === false ? true : undefined);
	const childrenHeaderCells: CustomHeadCell<IStudentDocument>[] = [
		{
			id: "name",
			numeric: false,
			disablePadding: false,
			label: "Document Name",
			widthType: "Remainder",
		},
		{
			id: "requestedBy",
			numeric: false,
			disablePadding: false,
			label: "Requested By",
			widthType: "M",
		},
		{
			id: "requestedDate",
			numeric: false,
			disablePadding: false,
			label: "Date Requested",
			widthType: "L",
		},
		{
			id: "receivedDate",
			numeric: false,
			disablePadding: false,
			label: "Date Received",
			widthType: "L",
		},
		{
			id: "uploadedBy",
			numeric: false,
			disablePadding: false,
			label: "Uploaded By",
			widthType: "M",
		},
		{
			id: "uploadedDate",
			numeric: false,
			disablePadding: false,
			label: "Date Uploaded",
			widthType: "M",
		},
		{
			id: "isArchived",
			numeric: false,
			disablePadding: false,
			label: "Is Archived",
			widthType: "S",
			isHidden: isArchiveHidden,
		},
		{
			id: "id",
			numeric: false,
			disablePadding: false,
			label: "",
			widthType: "Option",
		},
	];

	return (
		<React.Fragment>
			{" "}
			<TableRow>
				<TableCell className={open ? clsx(classes.rowOpenLeft) : ""}>
					{" "}
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{row.documents.length > 0 ? (
							open ? (
								<KeyboardArrowUp />
							) : (
								<KeyboardArrowDown />
							)
						) : (
							""
						)}
					</IconButton>
				</TableCell>
				<TableCell className={open ? clsx(classes.rowOpenRight) : ""}>
					{row.name}
				</TableCell>
			</TableRow>
			{row.documents.length > 0 && open && (
				<TableRow>
					<TableCell
						className={
							open ? clsx(classes.rowOpenBottom, classes.rowOpenLeft) : ""
						}
					></TableCell>
					<TableCell
						colSpan={4}
						className={
							open ? clsx(classes.rowOpenBottom, classes.rowOpenRight) : ""
						}
					>
						<Collapse in={open} timeout="auto" unmountOnExit></Collapse>
						<Box margin={1}>
							<div className={classes.tableWrapper}>
								<CustomTableHeader<IStudentDocument>
									cells={childrenHeaderCells}
									defaultOrder={childrenOrder}
									defaultOrderBy={childrenOrderBy}
									setOrder={setChildrenOrder}
                  setOrderBy={setChildrenOrderBy}
									key={"ch"}
								/>
								<TableBody>
									{TableFunctions.stableSort(
										row.documents,
										TableFunctions.getSorting(
											childrenOrder,
											childrenOrderBy
										) as any
									).map((docRow: IStudentDocument, docIndex: any) => {
										return (
											<DocumentGridDocumentRow
												setFileName={setFileName}
												setFileUri={setFileUri}
												setFileExtension={setFileExtension}
												row={docRow}
												confimationModalProps={props.confimationModalProps}
												refreshDocumentGrid={props.refreshDocumentGrid}
												setConfirmationModalProps={
													props.setConfirmationModalProps
												}
												setOpenPreview={setOpenPreview}
												setSnackBarProps={props.setSnackBarProps}
												isArchiveHidden={isArchiveHidden}
											/>
										);
									})}
								</TableBody>
							</div>
						</Box>
					</TableCell>
				</TableRow>
			)}
			<DocumentPreview
				open={openPreview}
				setOpen={setOpenPreview}
				setSnackBarProps={props.setSnackBarProps}
				fileUri={fileUri}
				fileName={fileName}
				fileExtension={fileExtension}
			/>
		</React.Fragment>
	);
};

export default DocumentGridTypeRow;
