import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useForm, FormContext, FieldError } from "react-hook-form";
import { CardContent, Card, Button, Grid, Link } from "@material-ui/core";
import clsx from "clsx";
import { security } from "../../../constants/Security/securityConstants";
import AccessManager from "../../../components/security/AccessManager";
import {
  ChargeDefinitionVersion,
  ChargeDefinitionVersionDef,
} from "../../../interfaces/setup/chargeDefinition/ChargeDefinitionVersion";
import {
  IChargingMethodIncrement,
  CharginMethodIncrement,
} from "../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/CharginMethod/IChargingMethodIncrement";
import ChargeDefinitionVersionDetail from "./ChargeDefinitionVersionDetail";
import { ChargePeriod } from "../../../interfaces/setup/chargeDefinition/ChargePeriod";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import { ChargeDefinition } from "../../../interfaces/setup/chargeDefinition/ChargeDefinition";
import * as chargeDefinitionApi from "../../../api/setup/chargeDefinition/chargeDefinitionApi";
import { EmptyGuid } from "../../../utils/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: "100%",
    },
    header: {
      height: "40px",
      padding: "10px",
      fontWeight: 600,
      width: "100%",
      backgroundColor: theme.palette.secondary.dark,
    },
    cardTitle: {
      fontSize: 17,
      color: "Black",
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardSeparator: {
      marginBottom: theme.spacing(1),
    },
    button: {
      padding: theme.spacing(1),
      margin: theme.spacing(2, 0),
    },

    buttonColorPrimary: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
      textDecoration: "bold",
    },
    buttonColorTertiary: {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.error.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.error.main,
      },
      textDecoration: "bold",
      padding: theme.spacing(1),
      whiteSpace: "nowrap",
      margin: theme.spacing(1),
    },
    tableWrapper: {
      width: "60%",
    },
  })
);

type IChargeDefinitionVersionProps = {
  isCreatingNew: boolean;
  model: ChargeDefinition;
  setModel: any;
  setSnackBarProps: any;
};

function ChargeDefinitionVersions(props: IChargeDefinitionVersionProps) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const classes = useStyles();

  const methods = useForm<any>({
    mode: "onBlur",
  });

  const [
    workingChargeDefinitionVersionModel,
    setWorkingChargeDefinitionVersionModel,
  ] = React.useState<ChargeDefinitionVersion | null>(null);

  const [
    isCreatingChargeDefinitionVersion,
    setIsCreatingChargeDefinitionVersion,
  ] = React.useState<boolean>(false);

  const [definitionVersions, setDefinitionVersions] = React.useState<
    ChargeDefinitionVersion[]
  >([]);

  const [selected, setSelected] = React.useState([] as any);
  const isSelected = (definitionVersionId: any) =>
    selected.indexOf(definitionVersionId) !== -1;

  const selectAllClick = (event: any) => {
    if (event.target.checked) {
      const selected = definitionVersions.map(
        (n: any) => n.definitionVersionId
      );
      setSelected(selected);
      return;
    }
    setSelected([]);
  };
  const rowClick = (definitionId: string) => {
    chargeDefinitionApi.GetChargeDefinitionVersionById(definitionId).then(
      (response: any) => {
        if (response && response.data) {
          setWorkingChargeDefinitionVersionModel({
            ...response.data,
          });
          setIsCreatingChargeDefinitionVersion(true);
        }
      },
      (exception: any) => {}
    );
  };

  const handelAddNew = () => {
    setIsCreatingChargeDefinitionVersion(true);
    var newVersion = new ChargeDefinitionVersionDef();
    newVersion.chargeDefinitionVersionId = null;
    newVersion.chargeDefinitionId = props.model.chargeDefinitionId ?? EmptyGuid;
    setWorkingChargeDefinitionVersionModel(newVersion);
  };

  const handelSaveDefinition = () => {
    methods.triggerValidation().then((validation: any) => {
      if (validation == true) {
        setLoading(true);
        if (workingChargeDefinitionVersionModel != null)
          chargeDefinitionApi
            .UpsertChargeDefinitionVersion(workingChargeDefinitionVersionModel)
            .then(
              (response: any) => {
                if (response.resultStatus === 0) {
                  props.setModel({
                    ...props.model,
                    chargeDefinitionVersions: response.result,
                  });
                  setIsCreatingChargeDefinitionVersion(false);
                  props.setSnackBarProps((props: any) => {
                    return {
                      variant: "success",
                      showSnackBar: true,
                      messageInfo: response.resultStatusMessage,
                    };
                  });
                } else {
                  props.setSnackBarProps((props: any) => {
                    return {
                      variant: "error",
                      showSnackBar: true,
                      messageInfo: response.data,
                    };
                  });
                }
                setLoading(false);
              },
              (exception: any) => {
                props.setSnackBarProps((snackBarProps: any) => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: exception,
                  };
                });
                setLoading(false);
              }
            );
      }
    });
  };

  const closeChargingDefinition = () => {
    setIsCreatingChargeDefinitionVersion(false);
    setWorkingChargeDefinitionVersionModel(null);
  };

  React.useEffect(() => {
    if (props?.model?.chargeDefinitionVersions) {
      setDefinitionVersions(props?.model?.chargeDefinitionVersions);
    }
  }, [props]);

  return (
    <Card>
      <CustomCardTitle title={"Charge Definition Versions"} />
      <CardContent>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <TableContainer component={Paper} className={classes.tableWrapper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="left">Effective Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {definitionVersions.map((row: ChargeDefinitionVersion) => {
                    const isRowSelected = isSelected(
                      row.chargeDefinitionVersionId
                    );
                    return (
                      <TableRow
                        key={row.chargeDefinitionVersionId as any}
                      >
                        <TableCell align="left">
                          <Link
                            component="button"
                            color="primary"
                            onClick={(event: any) => {
                              rowClick(row.chargeDefinitionVersionId as any);
                            }}
                          >
                            {row.description}
                          </Link>
                        </TableCell>
                        <TableCell align="left">
                          {row.effectiveDate
                            ? new Date(row.effectiveDate).toLocaleDateString()
                            : null}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12}>
            {!isCreatingChargeDefinitionVersion &&
              props.model.chargeDefinitionId != null && (
                <AccessManager
                  allowedPermissions={[
                    security.permissions.maintenance.UpdateChargeDefinition,
                  ]}
                  renderNoAccess={() => (
                    <Button
                      className={classes.buttonColorPrimary}
                      onClick={() => {}}
                      size="small"
                      type="button"
                      disabled={true}
                    >
                      Add New
                    </Button>
                  )}
                >
                  <Button
                    style={{ marginLeft: 5 }}
                    className={classes.buttonColorPrimary}
                    onClick={() => {
                      handelAddNew();
                    }}
                    size="small"
                    type="button"
                  >
                    Add New
                  </Button>
                </AccessManager>
              )}
          </Grid>

          <Grid item xs={12}>
            {isCreatingChargeDefinitionVersion &&
              workingChargeDefinitionVersionModel != null && (
                <React.Fragment>
                  <ChargeDefinitionVersionDetail
                    isCreatingNew={isCreatingChargeDefinitionVersion}
                    model={workingChargeDefinitionVersionModel}
                    setModel={setWorkingChargeDefinitionVersionModel}
                    closeChargingDefinition={() => {
                      closeChargingDefinition();
                    }}
                    methods={methods}
                  ></ChargeDefinitionVersionDetail>
                </React.Fragment>
              )}
          </Grid>

          {isCreatingChargeDefinitionVersion &&
            workingChargeDefinitionVersionModel != null && (
              <React.Fragment>
                <Grid container direction="row">
                  <Grid item xs={12}>
                    <Button
                      className={classes.buttonColorPrimary}
                      onClick={() => {
                        handelSaveDefinition();
                      }}
                      size="small"
                      type="button"
                    >
                      Save Definition Version
                    </Button>
                    <Button
                      style={{ marginLeft: 5 }}
                      color="secondary"
                      variant="contained"
                      size="small"
                      onClick={() => {
                        closeChargingDefinition();
                      }}
                      type="button"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
        </Grid>
      </CardContent>
    </Card>
  );
}
export default ChargeDefinitionVersions;
