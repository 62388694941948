import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { Card } from "@material-ui/core";
import { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker
} from "@material-ui/pickers";
import Search from "./Search";
import TextField from "@material-ui/core/TextField";
import { useSelector } from "react-redux";
import * as StudentGradesApi from "../../../../src/api/student/academics/StudentGradesApi";
import { StudentGrade } from "../../../../src/interfaces/student/academics/StudentGrade";
import TableContainer from "@material-ui/core/TableContainer";
import PropTypes from "prop-types";
import { useFormContext, FieldError } from "react-hook-form";
import AttemptHistoryBox from "../grades/AttemptHistoryBox";
import { system } from '../../../constants/system/systemConstants';

function stableSort(array: any, comparator: any) {
	const stabilizedThis = array.map((el: any, index: any) => [el, index]);
	stabilizedThis.sort((a: any, b: any) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el: any) => el[0]);
}
type Order = "asc" | "desc";

function descendingComparator(a: any, b: any, orderBy: any) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order: any, orderBy: any) {
	return order === "desc"
		? (a: any, b: any) => descendingComparator(a, b, orderBy)
		: (a: any, b: any) => -descendingComparator(a, b, orderBy);
}
interface HeadCell {
	disablePadding: boolean;
	id: keyof StudentGrade;
	label: string;
	numeric: boolean;
	widthType: "L" | "M" | "S" | "XS";
}

const headCells: HeadCell[] = [
	{
		id: "courseDescription",
		numeric: false,
		disablePadding: false,
		label: "Course",
		widthType: "M"
	},
	{
		id: "componentDescription",
		numeric: false,
		disablePadding: false,
		label: "Name",
		widthType: "M"
	},
	{
		id: "attempts",
		numeric: true,
		disablePadding: false,
		label: "Adjustment",
		widthType: "XS"
	},

	{
		id: "dateCompleted",
		numeric: false,
		disablePadding: false,
		label: "Date",
		widthType: "S"
	},
	{
		id: "componentOrder",
		numeric: false,
		disablePadding: false,
		label: "Completed",
		widthType: "XS"
	},
	{
		id: "componentType",
		numeric: false,
		disablePadding: false,
		label: "",
		widthType: "XS"
	}
];

interface EnhancedTableProps {
	classes: ReturnType<typeof useStyles>;
	numSelected: number;
	onRequestSort: (
		event: React.MouseEvent<unknown>,
		property: keyof StudentGrade
	) => void;
	onSelectAllClick: (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const { classes, order, orderBy, onRequestSort } = props;
	const createSortHandler = (property: keyof StudentGrade) => (event: any) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className={classes.gridHeader}>
			<TableRow>
				{headCells.map(headCell => (
					<TableCell
						key={headCell.id}
						align="left"
						padding={headCell.disablePadding ? "none" : "default"}
						sortDirection={orderBy === headCell.id ? order : false}
						className={
							headCell.widthType == "XS"
								? classes.gridCellTypeXsmall
								: headCell.widthType == "S"
								? classes.gridCellTypeSmall
								: headCell.widthType == "M"
								? classes.gridCellTypeMedium
								: headCell.widthType == "L"
								? classes.gridCellTypeLarge
								: classes.gridCellTypeXLarge
						}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}
EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired
};
const useToolbarStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(1)
		},
		header: {
			width: "100%",
			height: "90%",
			display: "inline-block",
			border: "5px",
			float: "left",
			color: "#a8a8a8"
		},
		date: {
			width: "100%"
		},
		dateDiv: {
			paddingRight: theme.spacing(2),
			width: "20%"
		},
		searchDiv: {
			paddingRight: theme.spacing(2),
			float: "right",
			width: "60%"
		}
	})
);

interface EnhancedTableToolbarProps {
	numSelected: number;
	onChange?: Function;
	onChangeSearchFilter?: Function;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
	const classes = useToolbarStyles({});
	let d = new Date();
	d.setDate(d.getDate());
	const [defaultDate, setdefaultDate] = useState<Date | null>(d);
	const [searchToolFilter, setSearchToolFilter] = useState<string | null>("");
	return (
		<Toolbar>
			<div className={classes.dateDiv}>
				<Typography variant="h5" id="tableTitle">
					Default Date:
				</Typography>
			</div>
			<div className={classes.dateDiv}>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardDatePicker
						disableToolbar
						autoOk={true}
						variant="inline"
						format="MM/dd/yyyy"
						id="date-picker-inline"
						value={defaultDate}
						disableFuture={true}
						maxDate={new Date()}
						maxDateMessage="Date should not be after the current date"
						onChange={value => {
							if (props.onChange) {
								props.onChange(value);
							}
							setdefaultDate(value);
						}}
						className={classes.date}
						KeyboardButtonProps={{
							"aria-label": "change date"
						}}
					/>
				</MuiPickersUtilsProvider>
			</div>
			<div className={classes.searchDiv}>
				<Search
					label={"Search Components"}
					value={searchToolFilter}
					onChange={(e: any) => {
						if (props.onChangeSearchFilter) {
							props.onChangeSearchFilter(e);
						}
						setSearchToolFilter(e.target.value);
					}}
				/>
			</div>
		</Toolbar>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			height: "calc(100% - 70px)"
		},
		cardWrapper: {
			height: "100%"
		},
		paper: {
			width: "100%",
			marginBottom: theme.spacing(2)
		},
		table: {
			minWidth: 750,
			height: "100%"
		},

		container: {
			maxHeight: 700
		},
		textField: {
			marginRight: theme.spacing(1),
			width: "100%",
			"& ::-webkit-inner-spin-button, ::-webkit-outer-spin-button": {
				WebkitAppearance: "none",
				margin: 0,
				MozAppearance: "textfield"
			}
		},
		visuallyHidden: {
			border: 0,
			clip: "rect(0 0 0 0)",
			height: 1,
			margin: -1,
			overflow: "hidden",
			padding: 0,
			position: "absolute",
			top: 20,
			width: 1
		},
		score: {
			textAlign: "left",
			width: "40%"
		},

		dateField: {
			width: "60%"
		},
		dateFieldlight: {
			width: "60%",
			color: "red",
			backgroundColor: "#263238"
		},

		gridHeader: {
			backgroundColor: theme.palette.secondary.dark,
			height: "50px",
			display: "table",
			width: "100%",
			tableLayout: "fixed"
		},
		tableWrapper: {
			height: "calc(100% - 40px)"
		},
		tableRowWrapper: {
			display: "table",
			width: "100%",
			tableLayout: "fixed"
		},
		tableBodyWrapper: {
			height: "calc(100% - 75px)",
			overflowY: "auto",
			display: "block"
		},
		gridHeadTypeXsmall: {
			width: "10%",
			paddingLeft: theme.spacing(0),
			paddingRight: theme.spacing(0)
		},
		gridCellTypeXsmall: {
			width: "10%",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0)
		},
		gridHeadTypeSmall: {
			width: "15%",
			paddingLeft: theme.spacing(0),
			paddingRight: theme.spacing(0)
		},
		gridCellTypeSmall: {
			width: "15%",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0)
		},
		gridHeadTypeMedium: {
			width: "25%",
			paddingLeft: theme.spacing(0),
			paddingRight: theme.spacing(0)
		},
		gridCellTypeMedium: {
			width: "25%",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0)
		},
		gridHeadTypeLarge: {
			width: "35%",
			paddingLeft: theme.spacing(0),
			paddingRight: theme.spacing(0)
		},
		gridCellTypeLarge: {
			width: "35%",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0)
		},
		gridHeadTypeXLarge: {
			width: "45%",
			paddingLeft: theme.spacing(0),
			paddingRight: theme.spacing(0)
		},
		gridCellTypeXLarge: {
			width: "45%",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0)
		}
	})
);
type ClinicHoursTableProps = {
	setValidationError: any;
	setclinicHoursData: any;
	clinicHoursData: any;
	setSnackBar: any;
	setConfirmationModal: any;
};
export default function EnhancedTable(props: ClinicHoursTableProps) {
	const classes = useStyles({});
	const [order, setOrder] = React.useState<Order>("asc");
	const data = useFormContext();
	const [orderBy, setOrderBy] = React.useState<keyof StudentGrade>(
		"componentOrder"
	);
	const [searchFilter, setsearchFilter] = useState<string | null>("");
	const [selected, setSelected] = React.useState<string[]>([]);
	let d = new Date();
	d.setDate(d.getDate());
	const [startDate, setStartDateState] = useState<Date | null>(d);
	const selectedEnrollment = useSelector(
		(state: any) => state.student.selectedEnrollment
	);
	const formDataHasValue = (
		fieldName: string,
		id: string | number
	): boolean => {
		let arrayIndex: string = fieldName + "_" + id;
		return (
			data.getValues()[arrayIndex] !== undefined &&
			data.getValues()[arrayIndex] !== "" &&
			data.getValues()[arrayIndex] !== null
		);
	};
	const doNotValidateRow = (id: string | number) => {
		return (
			!formDataHasValue("attempts", id) &&
			!formDataHasValue("dateCompleted", id)
		);
	};
	const filterData = (data: StudentGrade[]): StudentGrade[] => {
		if (searchFilter === "" || searchFilter === undefined) return data;

		let searchVal: string = searchFilter
			? (searchFilter as string).toLowerCase()
			: "";
		return data.filter(function(service: StudentGrade) {
			return (
				service &&
				((service.componentDescription &&
					service?.componentDescription?.toLowerCase().indexOf(searchVal) >
						-1) ||
					(service.courseDescription &&
						service?.courseDescription?.toLowerCase().indexOf(searchVal) >
							-1) ||
					(service.dateCompletedAsString &&
						service?.dateCompletedAsString?.indexOf(searchVal) > -1))
			);
		});
	};
	const refreshGridData=()=>{
		if (selectedEnrollment && selectedEnrollment.stuEnrollId) {
			let enrollmentId = selectedEnrollment.stuEnrollId;
			StudentGradesApi.GetStudentClinicHours(enrollmentId).then(
				(response: any) => {
					if (response) {
						let data = response.data;
						let allStudentGradesData: any = {
							...data,
							dateCompleted:
								data.dateCompleted === "" ? null : data.dateCompleted
						};
						allStudentGradesData.result?.forEach((e:any, i:any) => {
							e.score = null;
						});
						props.setclinicHoursData(allStudentGradesData.result);
					}
				},
				(exception: any) => {}
			);
		}
	};
	React.useEffect(() => {
		refreshGridData();
	}, [selectedEnrollment]);
	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof StudentGrade
	) => {
		const isDesc = orderBy === property && order === "desc";
		setOrder(isDesc ? "asc" : "desc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
		}
		setSelected([]);
	};

	const handleDefaultDateChange = (value: any) => {
		setStartDateState(value);
	};
	const handleSearchOnChange = (v: any) => {
		setsearchFilter(v.target.value);
	};
	const triggerRowValidation = (id: any) => {
		data.triggerValidation(["attempts_" + id, "dateCompleted_" + id]);
		if (Object.keys(data.errors).length == 0)
		{
			props.setValidationError(false);
		}
		else
		{
			props.setValidationError(true);
		}
	};

	const appSettings = useSelector(
		(state: any) => { return state.settings.settings }
	);
	const getAllowMoreClinicHours = () => {
		const setting = appSettings[system.settings.allowMoreClinicHours] as string;		
		let allow = true;
		if (setting) {
			allow = (setting.toLowerCase() === 'true');
		}		
		return allow;
	};
	const allowMoreClinicHours = getAllowMoreClinicHours();

	const handleFieldOnChange = (row: any, fieldName: string, value: any) => {
		let dataValues = data.getValues();
		let id = row.gradeBookWeightDetailId;

		if (fieldName === "dateCompleted") {
			let selectedDate = value;
			if(new Date() < selectedDate)
			{
				selectedDate=new Date();
			}
			(row as any)[fieldName] = selectedDate;
			data.setValue("dateCompleted_" + id, selectedDate);
			dataValues["dateCompleted_" + id] = selectedDate;
		}
		if (fieldName === "attempts") {
			(row as any)["dateCompleted"] = startDate;
			data.setValue("dateCompleted_" + id, startDate);
			dataValues["dateCompleted_" + id] = startDate;
		}
		if (
			(dataValues["attempts_" + id] !== undefined &&
				dataValues["attempts_" + id] !== "" &&
				dataValues["dateCompleted_" + id] !== undefined &&
				dataValues["dateCompleted_" + id] !== "") ||
			(dataValues["attempts_" + id] !== undefined &&
				dataValues["attempts_" + id] !== "" &&
				dataValues["dateCompleted_" + id] !== undefined &&
				dataValues["dateCompleted_" + id] !== "")
		) {
			row.isDirty = true;
		} else {
			row.isDirty = false;
		}

		if(allowMoreClinicHours == false && Number(row.required) < Number(dataValues["attempts_" + id]))
		{
			row.isDirty = false;
		}

		props.setclinicHoursData(props.clinicHoursData);
		triggerRowValidation(row.gradeBookWeightDetailId);
	};

	return (
		<Paper className={classes.root}>
			<Card className={classes.cardWrapper}>
				<EnhancedTableToolbar
					numSelected={selected.length}
					onChange={(value: any) => {
						handleDefaultDateChange(value);
					}}
					onChangeSearchFilter={handleSearchOnChange}
				/>
				<div className={classes.tableWrapper}>
					{/* <TableContainer className={classes.container}> */}
					<Table
						className={classes.table}
						aria-labelledby="tableTitle"
						size="medium"
						aria-label="enhanced table"
						stickyHeader
					>
						<EnhancedTableHead
							classes={classes}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={3}
						/>
						<TableBody className={classes.tableBodyWrapper}>
							{stableSort(
								filterData(props.clinicHoursData as any) as any,
								getComparator(order, orderBy)
							).map((row: any, index: any) => {
								const labelId = `attempts-${index}`;
								const dtLableId = `dtCompleted-${index}`;
								let remainingAdjustments = (Number(row.required)  - Number(row.attempts));
								let disableRow = allowMoreClinicHours == false && remainingAdjustments <= 0 ? true : false;
								return (
									<TableRow key={row.name} className={classes.tableRowWrapper}>
										<TableCell
											classes={{
												head: classes.gridCellTypeMedium,
												body: classes.gridCellTypeMedium
											}}
											align="left"
										>
											{row.courseDescription}
										</TableCell>
										<TableCell
											scope="row"
											component="th"
											classes={{
												head: classes.gridCellTypeMedium,
												body: classes.gridCellTypeMedium
											}}
											align="left"
										>
											{row.componentDescription}
										</TableCell>
										<TableCell
											classes={{
												head: classes.gridCellTypeXsmall,
												body: classes.gridCellTypeXsmall
											}}
											align="left"
										>
											<TextField
												type="number"
												className={classes.textField}
												id={"attempts_" + row.gradeBookWeightDetailId}
												name={"attempts_" + row.gradeBookWeightDetailId}
												disabled={disableRow}
												onBlur={(v: any) => {
													triggerRowValidation(row.gradeBookWeightDetailId);
												}}inputProps={{

													step: 0.01,
											 
											 }}
												error={
													!!data.errors[
														"attempts_" + row.gradeBookWeightDetailId
													]
												}
												onChange={(v: any) => {
													handleFieldOnChange(
														row,
														"attempts",
														v ? v : undefined
													);
												}}
												inputRef={data.register({
													validate: {
														requiredWhenDateEntered: value =>
															(value !== "" &&
																formDataHasValue(
																	"dateCompleted",
																	row.gradeBookWeightDetailId
																)) ||
															value !== "" ||
															doNotValidateRow(row.gradeBookWeightDetailId) ||
															"Required",
															remainingAdjustments: (value) =>
															allowMoreClinicHours == true || (remainingAdjustments < 0 || Number(value) <= remainingAdjustments) ||
															'Adjustment cannot be greater then ' + remainingAdjustments,
													}
												})}
												helperText={
													data.errors["attempts_" + row.gradeBookWeightDetailId]
														? (data.errors[
																"attempts_" + row.gradeBookWeightDetailId
														  ] as FieldError).message
														: undefined
												}
											/>
										</TableCell>
										<TableCell
											classes={{
												head: classes.gridCellTypeSmall,
												body: classes.gridCellTypeSmall
											}}
											align="left"
										>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<KeyboardDatePicker
													disableToolbar
													autoOk={true}
													variant="inline"
													format="MM/dd/yyyy"
													id={"dateCompleted_" + row.gradeBookWeightDetailId}
													name={"dateCompleted_" + row.gradeBookWeightDetailId}
													value={
														row.dateCompleted ? new Date(row.dateCompleted) : ""
													}
													onChange={(v: any) => {
														handleFieldOnChange(
															row,
															"dateCompleted",
															v ? v : undefined
														);
													}}
													KeyboardButtonProps={{
														"aria-label": "change date"
													}}
													disabled={disableRow}
													onBlur={(v: any) => {
														triggerRowValidation(row.gradeBookWeightDetailId);
													}}
													invalidDateMessage={""}
													disableFuture={true}
													maxDate={new Date()}
													error={
														!!data.errors[
															"dateCompleted_" + row.gradeBookWeightDetailId
														]
													}
													innerRef={(ref: any) => {
														data.register(
															"dateCompleted_" + row.gradeBookWeightDetailId,
															{
																validate: {
																	requiredWhenOtherValues: value =>
																		(value !== "" &&
																			value !== undefined &&
																			(formDataHasValue(
																				"attempts",
																				row.gradeBookWeightDetailId
																			) ||
																				formDataHasValue(
																					"score",
																					row.gradeBookWeightDetailId
																				))) ||
																		(value !== "" && value !== undefined) ||
																		doNotValidateRow(
																			row.gradeBookWeightDetailId
																		) ||
																		"Required"
																}
															}
														);
														data.setValue(
															"dateCompleted_" + row.gradeBookWeightDetailId,
															row.dateCompleted
														);
													}}
													helperText={
														data.errors[
															"dateCompleted_" + row.gradeBookWeightDetailId
														]
															? (data.errors[
																	"dateCompleted_" + row.gradeBookWeightDetailId
															  ] as FieldError).message
															: undefined
													}
												/>
											</MuiPickersUtilsProvider>
										</TableCell>
										<TableCell
											classes={{
												head: classes.gridHeadTypeXsmall,
												body: classes.gridCellTypeXsmall
											}}
											align="left"
										>
											{row.attempts === null ? 0 : row.attempts} /{" "}
											{row.required}
										</TableCell>
										<TableCell
											classes={{
												head: classes.gridHeadTypeXsmall,
												body: classes.gridCellTypeXsmall
											}}
											align="left"
										>
											{row.attempts > 0 && (
											<AttemptHistoryBox
												gradeBookWeightDetailId={row.gradeBookWeightDetailId}
												showAdj={true}
												showScore={false}
												tabType={"Hours"}
												excludeLast={false}
												lblAdj={"Hours"}
												allowMoreClinicServicesOrHours={allowMoreClinicHours}
												setSnackBar={props.setSnackBar}
												setConfirmationModal = {props.setConfirmationModal}
												refreshGridData = {refreshGridData}
												rowData = {row}
												stuEnrollId = {null}
											/>
											)}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
					{/* </TableContainer> */}
				</div>
			</Card>
		</Paper>
	);
}
