import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";
import CampusForCurrentUserAutoComplete from "../../../components/AutoComplete/CampusForCurrentUserAutoComplete";
import { FormControlLabel, Switch, Grid } from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import { generateReport, generateAutomatedDisbursementReport } from "../../../api/reports/financialAids/PaidDisbursementsReportAPI";
import { ReportOutput } from "../../../enums/ReportOutput";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import ProgramVersionAutoComplete from "../../../components/AutoComplete/ProgramVersionsMCAutoComplete";
import IAutomatedDisbursementsReport from "../../../interfaces/reports/IAutomatedDisbursementsReport";
import { FormControl, FormLabel, Radio, RadioGroup, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      minHeight: "100%",
      height: "100%",
      maxWidth: "1050px",
      padding: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    paperRoot: {
      padding: theme.spacing(3, 2),
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
    },
    searchActions: {
      marginTop: theme.spacing(3),
      textAlign: "left",
    },
    startDateField: {
      width: "100%",
    },
    cardTitle: {
      fontSize: 17,
      color: theme.palette.primary.headerText,
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
    switch: {
      display: "flex",
      alignItems: "flex-end",
      padding: theme.spacing(0, 0.5),
      height: theme.spacing(8.6),
    },

    fieldSet: {
      marginTop: theme.spacing(2),
    },
  })
);

const AutomatedDisbursementReport = (props: any) => {
  const { report } = props;
  let reportOverview = { report: report } as IReportOverview;
  const classes = useStyles({});
  const [preview, setPreview] = React.useState<any>();
  const [previewLoader, setPreviewLoader] = React.useState<boolean>();

  const data = useForm<any>({ mode: "onBlur" });

  const [model, setModel] = React.useState({
    campusIds: [] as Array<string>,
    exportType: 1 as ReportOutput,
    programVersions: [] as Array<string>,
    sortTypeName: "lastname" as string | undefined,
  });

  const handleSortOrderChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let updatedModel = model;
    (updatedModel as any)["sortTypeName"] = (
      event.target as HTMLInputElement
    ).value;
    setModel({ ...updatedModel });
  };

  const setStudentGroup = (studentGroups: any) => {
    let newGroups: string[] = [];
    if (studentGroups !== null) {
      if (Array.isArray(studentGroups)) {
        newGroups = studentGroups.map((gr: any) => gr.value);
      } else {
        newGroups.push(studentGroups.value);
      }
    }
    return newGroups;
  };

  const [inActiveGroup, setInActiveGroup] = React.useState<boolean>(false);

  const handleChange =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      switch (name) {
        case "inActiveGroup":
          setInActiveGroup(event.target.checked);
          break;
      }
    };

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: (values: any, key: string) => Array<string>
  ) => {
    if (
      fieldId == "exportType" &&
      value == undefined
    )
      return;

    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value, "value") : value;
    (updatedModel as any)[fieldId] = newValue;
    if (fieldId == "campusIds") {
      (updatedModel as any)["programVersions"] = [];
    }
    setModel({ ...updatedModel });
  };

  const { handleSubmit } = data;

  const exportTypes: Array<DropDownListItem> = [
    { text: "PDF", id: "1" },
    { text: "Excel", id: "2" },
    { text: "Word", id: "3" },
  ];

  const onSubmit = async (isPreview: any) => {
    let params = (await getReportParameters()) as IAutomatedDisbursementsReport;
    const validation = await data.triggerValidation();
    if (validation) {
      if (isPreview) {
        setPreviewLoader(true);
        generateAutomatedDisbursementReport(params, isPreview).then((res: any) => {
          if (res) {
            res.arrayBuffer().then((buffer: any) => {
              setPreview(buffer);
              setPreviewLoader(false);
            });
          } else setPreviewLoader(false);
        });
      } else await generateAutomatedDisbursementReport(params);
    }
  };

  const exportTypeAutoComplete = {
    options: exportTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const filterHandler = (data: any) => {
    if (data) {
      setModel(data);
    } else {
      setModel({
        campusIds: [],
        exportType: 1 as ReportOutput,
        programVersions: [],
        sortTypeName: "lastname" as string | undefined,
      });
    }
  };
  const setArrayValues = (values: any, key: string) => {
    let newValues: string[] = [];
    if (values !== null) {
      if (Array.isArray(values)) {
        newValues = values.map((gr: any) => gr[key]);
      } else {
        newValues.push(values.value);
      }
    }
    return newValues;
  };
  const getReportParameters = async () => {
    const success = await data.triggerValidation();
    if (model && success) {
      let params: IAutomatedDisbursementsReport = {
        campusIds: model.campusIds,
        exportType: model?.exportType,
        programVersions: model.programVersions,
        sortTypeName: model.sortTypeName,
      };
      return params;
    }
    return {} as IAutomatedDisbursementsReport;
  };

  const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
    const item = items.find((opt) => {
      if (opt.id == value) return opt;
      else return undefined;
    });
    return item || ({} as DropDownListItem);
  };

  let parameters = (
    <div className={classes.root}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} sm={12} xs={12} className={classes.headerText}><Typography>The report shows students with failed Disbursement notice delivery.</Typography></Grid>
            <Grid item md={6} sm={6} xs={12}>
              <CampusForCurrentUserAutoComplete
                id="campusIds"
                name="campusIds"
                label="Campus(es) *"
                multiple
                includeAllValue={true}
                showIncludeAllValue={true}
                params={{
                  fullWidth: true,
                }}
                filterHandle={(v: any) => {
                  handleFieldOnChange("campusIds", v, setArrayValues);
                }}
                valueFilter={
                  model.campusIds
                    ? {
                        key: "value",
                        values: model.campusIds,
                      }
                    : undefined
                }
                error={!!data.errors.campusIds}
                inputRef={data.register({
                  validate: (_value) =>
                    model.campusIds && model.campusIds.length > 0
                      ? true
                      : false,
                })}
                helperText={
                  data.errors.campusIds?.length > 0
                    ? "Campus is required."
                    : undefined
                }
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Autocomplete
                {...exportTypeAutoComplete}
                autoComplete
                disableClearable
                includeInputInList
                onChange={(e: any, value: any) => {
                  handleFieldOnChange(
                    "exportType",
                    value ? value : undefined,
                    (v: any) => v?.id
                  );
                }}
                value={getSelectedItem(
                  exportTypes,
                  model.exportType.toString()
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Export Type"
                    name="exportType"
                    error={!!data.errors.exportType}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.exportType
                        ? "Export Type is required."
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item md={6} sm={6} xs={12}>
              <ProgramVersionAutoComplete
                showIncludeAllValue={
                  model.campusIds && model.campusIds.length > 0
                }
                params={{
                  fullWidth: true,
                }}
                includeAllValue={model.campusIds && model.campusIds.length > 0}
                chipSize="small"
                maxTags={2}
                label="Program Version(s)"
                name="programVersions"
                campuses={model.campusIds}
                filterHandle={(value: any) =>
                  handleFieldOnChange("programVersions", value, setArrayValues)
                }
                valueFilter={
                  model.programVersions
                    ? {
                        key: "value",
                        values: model.programVersions,
                      }
                    : undefined
                }
                multiple={true}
              />
            </Grid>
           
            <Grid item md={12} sm={12} xs={12}>
              <FormControl component="fieldset" className={classes.fieldSet}>
                <FormLabel component="legend">Sort Order</FormLabel>
                <RadioGroup
                  row
                  aria-label="sortTypeName"
                  name="sortTypeName"
                  value={model.sortTypeName}
                  onChange={handleSortOrderChange}
                >
                  <FormControlLabel
                    labelPlacement="start"
                    value="lastname"
                    control={<Radio color="default" />}
                    label="Last Name"
                  />
                  <FormControlLabel
                    labelPlacement="start"
                    value="studentId"
                    control={<Radio color="default" />}
                    label="Student ID"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </FormContext>
    </div>
  );
  reportOverview.parameters = parameters;

  return (
    <ReportOverview
      reportOverview={reportOverview}
      filterHandler={filterHandler}
      getReportParameters={getReportParameters}
      exportHandler={onSubmit}
      preview={preview}
      previewLoader={previewLoader}
    />
  );
};
export default AutomatedDisbursementReport;
