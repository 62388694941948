import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import { FormContext, useForm } from 'react-hook-form';
import { SaveNewPermissionTemplate } from '../../../../../interfaces/security/SaveNewPermissionTemplate'
import { FormControlLabel, FormGroup, Checkbox, FormHelperText, FormLabel, FormControl } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PermissionTemplate } from '../../../../../interfaces/security/PermissionTemplate';

const useStyles = makeStyles((theme: any) =>
    createStyles({

        textField: {
            marginRight: theme.spacing(1),
            width: "100%"
        },
        formControl: {
            margin: theme.spacing(3),
        },
    })
);

const ThemeTextField = withStyles((theme: any) => createStyles({}))(TextField);

type ITemplateModalProps = {
    template: PermissionTemplate;
    setTemplate: any;
    onSubmit?: any;
}

export const TemplateModal = React.memo(React.forwardRef((props: ITemplateModalProps, ref) => {
    const classes = useStyles({});
    const [open, setOpen] = React.useState(false);
    const [userSpecific, setUserSpecific] = React.useState(false);
    const [campusSpecific, setCampusSpecific] = React.useState(false);
    const userId = useSelector((state: any) =>
        state.session.user.userId
    );
    const campusId = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );

    const methods = useForm<any>({
        mode: 'onBlur',
    });
    const { handleSubmit, register, errors, } = methods;
    const { template, setTemplate } = props;
    React.useImperativeHandle(ref, () => ({
        handleClickOpen() {
            handleClickOpen();
        },
        handleClose() {
            handleClose();
        }
    }));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const toggleUserSpecific = () => {
        setUserSpecific((state) => !state)
        setCampusSpecific(false)
        setTemplate({ userId: userId, campusId: undefined, ...template })
    }

    const toggleCampusSpecific = () => {
        setCampusSpecific((state) => !state)
        setUserSpecific(false)
        setTemplate({ ...template, userId: undefined, campusId: campusId })

    }
    const onSubmit = ((data: any, e: any) => {
        if (props.onSubmit)
            props.onSubmit();
        handleClose();
    });

    return (
        <div>
            <FormContext {...methods}>
                <form >
                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Save New Template</DialogTitle>


                        <DialogContent>
                            <DialogContentText>
                                Enter a template name to create a new permissions template
                            </DialogContentText>

                            <ThemeTextField
                                id="templateName"
                                name="templateName"
                                className={classes.textField}
                                label="Template Name"
                                error={!!errors.templateName}
                                inputRef={register({
                                    required: true
                                })}
                                onChange={(e:any) => {
                                    setTemplate({ ...template, name: e.target.value });
                                }}
                                helperText={
                                    errors.templateName ? "Template name is required" : null
                                }

                            />
                            <FormControl component="fieldset" className={classes.formControl}>

                                <FormLabel component="legend">Who can use this template?</FormLabel>

                                <FormGroup >
                                    <FormControlLabel
                                        control={<Checkbox checked={campusSpecific} onChange={toggleCampusSpecific} name="campusSpecificCheck" color="primary" />}
                                        label="Only this campus"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={userSpecific}
                                                onChange={toggleUserSpecific}
                                                name="userSpecificCheck"
                                                color="primary"
                                            />
                                        }
                                        label="Only this user"
                                    />
                                    <FormHelperText>If neither of these options are selected, then this template will be available across all campuses</FormHelperText>

                                </FormGroup>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={handleSubmit(onSubmit)} color="primary">
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            </FormContext>
        </div>
    );
}))

export default TemplateModal;
