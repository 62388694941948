import {
  CustomHeadCell,
  Order,
} from "../../../interfaces/common/Table/CustomHeadCell";
import {  IStudentDocumentModule } from "../../../interfaces/student/documents/IStudentDocumentModule";
import CustomTableHeader from "../../../components/Tables/CustomTableHeader";
import React from "react";
import {
  TableBody,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import * as TableFunctions from "../../../utils/tableFunctions";
import DocumentGridRow from './DocumentGridModuleRow'
import ConfirmationModal from "../../../components/_Layout/Modal/ConfirmationModal";


type DocumentGridProps = {
  data: IStudentDocumentModule[];
  setSnackBarProps: any;
  refreshDocumentGrid:any;
};
const useStyles = makeStyles((theme: any) =>
  createStyles({
    tableWrapper: {
      width: "100%",
      height:"calc(100% - 200px)",
      overflowY: "auto",
      borderTop: "2px solid #F4F0F0",
    borderBottom: "2px solid #F4F0F0"
    },
    mainBody:{
      height:"100%"
    }
  })
);
const DocumentGrid = (props: DocumentGridProps) => {
  const classes = useStyles({});
 

  const headerCells: CustomHeadCell<IStudentDocumentModule>[] = [
    {
      id: "options",
      numeric: false,
      disablePadding: false,
      label: "",
      widthType: "Option",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Module",
      widthType: "Remainder",
    },
    
  ];


  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof IStudentDocumentModule>(
    "name"
  );
  const [confirmationPopupState, setConfirmationPopupState] = React.useState<
  any
>({
  id: "simple-popup",
  message: ""
});

  return (
    <div className={classes.tableWrapper}>
      <CustomTableHeader<IStudentDocumentModule>
        cells={headerCells}
        defaultOrder={order}
        defaultOrderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        key={"tex"}
      />
      <TableBody className={classes.mainBody}>
        {TableFunctions.stableSort(
          props.data,
          TableFunctions.getSorting(order, orderBy) as any
        ).map((row: IStudentDocumentModule, index: any) => {
          return (
            <DocumentGridRow row={row} confimationModalProps={confirmationPopupState} setConfirmationModalProps={setConfirmationPopupState} setSnackBarProps={props.setSnackBarProps} refreshDocumentGrid={props.refreshDocumentGrid}/>
           );
        })}
      </TableBody>
      
			<ConfirmationModal {...confirmationPopupState} />
    </div>
  );
};

export default DocumentGrid;
