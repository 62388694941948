import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import {
	Theme,
	Typography,
	Card,
	CardActions,
	CardContent,
	Grid,
	TextField,
	Table,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
	Snackbar,
} from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

import ProgramScheduleSelect from "../../../../components/AutoComplete/ProgramScheduleAutoComplete";
import * as enrollmentApi from "../../../../api/student/academics/enrollmentApi";
import { ProgramSchedule } from "../../../../interfaces/student/academics/ProgramSchedule";
import { ProgramScheduleDetail } from "../../../../interfaces/student/academics/ProgramScheduleDetail";
import { DayOfWeek } from "../../../../enums/DayOfWeek";
import { EnrollmentSchedule } from "../../../../interfaces/student/academics/EnrollmentProgram";
import { useFormContext } from "react-hook-form";
import { EmptyGuid } from "../../../../utils/constants";
function Alert(props: AlertProps) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.paper.background,
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2),
			},
		},
		"& .makeStyles-content": {
			backgroundColor: theme.palette.paper.background,
		},
		cardTitle: {
			fontSize: 17,
			color: "Black",
			textDecoration: "bold",
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1),
		},

		textField: {
			marginRight: theme.spacing(1),
			width: "100%",
		},
		fullWidthField: {
			width: "100%",
		},

		badgeInput: {
			width: "80%",
		},
		hide: {
			display: "none",
			visibility: "hidden",
		},
		scrollableGrid: {
			display: "inline",
			overflowX: "auto",
		},
		tableHead: {
			backgroundColor: theme.palette.site.secondary,
		},
		cardContent: {
			padding: theme.spacing(1, 3),
		},
	})
);

const StyledTableCell = withStyles((theme: Theme) =>
	createStyles({
		root: {
			border: "none",
			fontSize: "small",
		},
		body: {
			fontSize: "small",
		},
	})
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
		},
	})
)(TableRow);

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& .MuiFormLabel-root": {
				textOverflow: "ellipsis",
				whiteSpace: "nowrap",
				width: "80%",
			},
		},
		table: {
			minWidth: 700,
		},
	})
)(TextField);

function createData(
	label: string,
	sunday: any,
	monday: string,
	tuesday: string,
	wednesday: string,
	thursday: string,
	friday: string,
	saturday: string
) {
	return {
		label,
		sunday,
		monday,
		tuesday,
		wednesday,
		thursday,
		friday,
		saturday,
	};
}

const schema = [
	{ key: "timein", label: "Time In", type: "datetime" },
	{ key: "lunchout", label: "Lunch Out", type: "datetime" },
	{ key: "lunchin", label: "Lunch In", type: "datetime" },
	{ key: "timeout", label: "Time Out", type: "datetime" },
	{ key: "lunchLength", label: "Lunch Length" },
	{ key: "maximumNumberOfLunch", label: "Max Hrs w/o Lunch" },
	{ key: "total", label: "Sched Daily Hrs" },
	{ key: "allowEarlyin", label: "Allow Early In", type: "bool" },
	{ key: "allowLateout", label: "Allow Late Out", type: "bool" },
	{ key: "allowExtraHours", label: "Allow Extra Hrs", type: "bool" },
	{ key: "checkTardyin", label: "Limit In Time", type: "bool" },
	{ key: "maxBeforeTardy", label: "Max In Time", type: "datetime" },
	{ key: "tardyIntime", label: "Assn Tardy Time", type: "datetime" },
];
type ScheduleProps = {
	schedule: EnrollmentSchedule;
	setSchedule: any;
	isNewEnrollment: boolean;
	disableSelection?: boolean;
	isScheduleRequired?: boolean;
	programVersion?: string;
};
const Schedule = (props: ScheduleProps) => {
	const classes = useStyles({});
	const { schedule, setSchedule } = props;
	const data = useFormContext();

	const params = new URLSearchParams(window.location.search);
	let programId: string | null = null;
	let programVersionId: string | null = null;
	let scheduleId: string | null = null;
	useEffect(() => {
		programId = params.get("programId");
		programVersionId = params.get("programVersionId");
		scheduleId = params.get("scheduleId");
		if (programId && programVersionId && scheduleId) {
			schedule.scheduleId = scheduleId;
			setSchedule({ ...schedule });
		}
	}, [props.programVersion]);
	const [enrollmentSchedule, setEnrollmentSchedule] = React.useState<
		ProgramSchedule | null | undefined
	>(undefined);

	const [hasErrorLoadingScheduleDetails, setHasErrorLoadingScheduleDetails] =
		React.useState<boolean>(false);

	React.useEffect(() => {
		if (schedule && schedule.scheduleId) {
			getScheduleDetails(schedule.scheduleId);
		} else {
			setEnrollmentSchedule(undefined);
		}
	}, [schedule.scheduleId]);

	const getScheduleDetails = (id: string) => {
		enrollmentApi.getProgramScheduleDetails(id).then(
			(response: any) => {
				if (response) {
					if (
						response.data !== undefined &&
						response.data !== null &&
						response.data.length > 0
					) {
						let data = response.data[0];
						let schedule = {
							...data,
							programScheduleDetails: data.programScheduleDetails.map(
								(psd: any) => {
									return {
										...psd,
									} as ProgramScheduleDetail;
								}
							),
						} as ProgramSchedule;
						setEnrollmentSchedule(schedule);
					} else {
						setEnrollmentSchedule(undefined);
						setHasErrorLoadingScheduleDetails(true);
					}
				} else {
					setEnrollmentSchedule(undefined);
				}
			},
			(exception: any) => {
				setEnrollmentSchedule(undefined);
			}
		);
	};
	const handleBadgeIdChange = (e: any) => {
		if (!isNaN(e.target.value)) {
			schedule.badgeId = e.target.value;
			setSchedule(schedule);
		}
	};

	const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}

		setHasErrorLoadingScheduleDetails(false);
	};

	const handleTimeClockScheduleChange = (data: any) => {
		if (data) {
			let scheduleId = data.value;
			schedule.scheduleId = scheduleId;
			setSchedule({ ...schedule });
			getScheduleDetails(scheduleId);
		} else {
			schedule.scheduleId = EmptyGuid;
			setSchedule({ ...schedule });
			getScheduleDetails(EmptyGuid);
		}
	};
	const tableData: any =
		enrollmentSchedule && enrollmentSchedule.programScheduleDetails
			? schema.map((scheme: any) => {
					if (enrollmentSchedule && enrollmentSchedule.programScheduleDetails) {
						enrollmentSchedule.programScheduleDetails.forEach(
							(psd: ProgramScheduleDetail) => {
								let dayOfWeek: DayOfWeek | undefined = psd.dayOfWeek;
								if (dayOfWeek !== undefined) {
									let value = (psd as any)[scheme.key];
									if (value !== undefined && value !== null && scheme.type) {
										if (scheme.type === "datetime") {
											value = new Date(value).toLocaleTimeString();
										} else if (scheme.type === "bool") {
											value = value ? "Yes" : "No";
										}
									}
									scheme[dayOfWeek] = value;
								}
							}
						);
						return scheme;
					}
			  })
			: [];

	return (
		<Card square={true}>
			<Typography className={clsx(classes.cardTitle)}>
				&nbsp; Schedule
			</Typography>
			<CardContent className={classes.cardContent}>
				<Grid container direction="row" spacing={2}>
					<Grid item xs={12} sm={4}>
						<ProgramScheduleSelect
							programVersion={
								props.programVersion ? props.programVersion : undefined
							}
							disabled={props.disableSelection}
							valueFilter={
								schedule.scheduleId
									? {
											key: "value",
											values: [schedule.scheduleId],
									  }
									: undefined
							}
							filterHandle={(value: any) => {
								handleTimeClockScheduleChange(
									value !== null ? value : undefined
								);
							}}
							name="scheduleSelect"
							id="scheduleSelect"
							key="scheduleSelect"
							error={data.errors.scheduleSelect ? true : false}
							inputRef={data.register({
								required: {
									value: props.isScheduleRequired ? true : false,
									message: "Time clock schedule is required.",
								},
							})}
							helperText={
								data.errors.scheduleSelect
									? (data.errors.scheduleSelect as any).message
									: ""
							}
							label={
								props.isScheduleRequired ? "Time Clock Schedule *" : undefined
							}
						/>
					</Grid>

					<Grid item xs={12} sm={4}>
						<ThemeTextField
							id="totalHours"
							label="Total Hours"
							name="totalHours"
							InputLabelProps={{ shrink: true }}
							className={classes.fullWidthField}
							value={enrollmentSchedule?.totalHours}
							disabled
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<ThemeTextField
							id="badgeId"
							label="Badge ID"
							name="badgeId"
							className={
								!props.isNewEnrollment ? classes.badgeInput : classes.hide
							}
							onChange={handleBadgeIdChange}
							value={schedule.badgeId ?? ""}
							error={data.errors.badgeId ? true : false}
							helperText={
								data.errors.badgeId ? (data.errors.badgeId as any).message : ""
							}
						/>
					</Grid>
				</Grid>
				{schedule !== undefined && (
					<Grid container direction="row" spacing={2}>
						<Grid item xs={12} className={classes.scrollableGrid}>
							<Table aria-label="customized table" size="small">
								<TableHead className={classes.tableHead}>
									<TableRow>
										<StyledTableCell></StyledTableCell>
										<StyledTableCell align="center">Sunday</StyledTableCell>
										<StyledTableCell align="center">Monday</StyledTableCell>
										<StyledTableCell align="center">Tuesday</StyledTableCell>
										<StyledTableCell align="center">Wednesday</StyledTableCell>
										<StyledTableCell align="center">Thursday</StyledTableCell>
										<StyledTableCell align="center">Friday</StyledTableCell>
										<StyledTableCell align="center">Saturday</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{tableData &&
										tableData.map((row: any) => (
											<StyledTableRow key={row.label}>
												<StyledTableCell component="th" scope="row">
													{row.label}
												</StyledTableCell>
												<StyledTableCell align="center">
													{row[DayOfWeek.Sunday]}
												</StyledTableCell>
												<StyledTableCell align="center">
													{row[DayOfWeek.Monday]}
												</StyledTableCell>
												<StyledTableCell align="center">
													{row[DayOfWeek.Tuesday]}
												</StyledTableCell>
												<StyledTableCell align="center">
													{row[DayOfWeek.Wednesday]}
												</StyledTableCell>
												<StyledTableCell align="center">
													{row[DayOfWeek.Thursday]}
												</StyledTableCell>
												<StyledTableCell align="center">
													{row[DayOfWeek.Friday]}
												</StyledTableCell>
												<StyledTableCell align="center">
													{row[DayOfWeek.Saturday]}
												</StyledTableCell>
											</StyledTableRow>
										))}
								</TableBody>
							</Table>
						</Grid>

						<Grid container direction="row" spacing={2}>
							<Grid item xs={10}>
								<Snackbar
									open={hasErrorLoadingScheduleDetails}
									autoHideDuration={6000}
									onClose={handleCloseAlert}
								>
									<Alert onClose={handleCloseAlert} severity="error">
										An unexpected error has ocurred!
									</Alert>
								</Snackbar>
							</Grid>
						</Grid>
					</Grid>
				)}
			</CardContent>
			<CardActions></CardActions>
		</Card>
	);
};

export default Schedule;
