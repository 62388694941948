import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DropDownListItem } from '../../../interfaces/DropDownListItem';
import TextField from '@material-ui/core/TextField';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CampusForCurrentUserAutoComplete from '../../../components/AutoComplete/CampusForCurrentUserAutoComplete';
import { Grid } from '@material-ui/core';
import { FormContext, useForm, FieldError } from 'react-hook-form';
import { IAttendanceExceptions } from '../../../interfaces/reports/attendance/IAttendanceExceptions';
import * as attendanceExceptionsApi from '../../../api/attendance/attendanceExceptionsApi';
import { ReportOutput } from '../../../enums/ReportOutput';
import ReportOverview from '../../../components/_Layout/Reports/ReportOverview';
import { IReportOverview } from '../../../interfaces/reports/IReportOverview';
import StudentGroupsAutoComplete from '../../../components/AutoComplete/StudentGroupsAutoComplete';
import { withStyles } from '@material-ui/core';

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {},
	})
)(TextField);

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			paddingTop: theme.spacing(3),
			minHeight: '100%',
			height: '100%',
			maxWidth: '1050px',
			padding: theme.spacing(2),
		},
		'& .makeStyles-content': {
			backgroundColor: theme.palette.background.paper,
		},
		startDateField: {
			width: '100%',
		},
		fullWidthElement: {
			width: '100%',
		},
	})
);

const AttendanceExceptionsReport = (props: any) => {
	const { report } = props;
	let reportOverview = { report: report } as IReportOverview;
	const classes = useStyles({});
	const [preview, setPreview] = React.useState<any>();
	const [previewLoader, setPreviewLoader] = React.useState<boolean>();

	const data = useForm<any>({ mode: 'onBlur' });
	const [model, setModel] = React.useState<IAttendanceExceptions>({
		campusIds: [],
		exportType: 1 as ReportOutput,
	});

	const handleFieldOnChange = (
		fieldId: string,
		value: any,
		mapFunction?: (values: any, key: string) => Array<string>
	) => {
		let updatedModel = model;
		let newValue = mapFunction ? mapFunction(value, 'value') : value;
		(updatedModel as any)[fieldId] = newValue;
		if (fieldId === 'campusIds') {
			(updatedModel as any)['studentGroupIds'] = [];
		}
		setModel({ ...updatedModel });
	};

	const { handleSubmit } = data;

	const exportTypes: Array<DropDownListItem> = [
		{ text: 'PDF', id: '1' },
	];

	const onSubmit = async (isPreview: any) => {
		let params = (await getReportParameters()) as IAttendanceExceptions;
		const validation = await data.triggerValidation();
		if (validation) {
			if (isPreview) {
				setPreviewLoader(true);
				attendanceExceptionsApi
					.getAttendanceExceptionsReport(params, isPreview)
					.then((res: any) => {
						if (res) {
							res.arrayBuffer().then((buffer: any) => {
								setPreview(buffer);
								setPreviewLoader(false);
							});
						} else setPreviewLoader(false);
					});
			} else await attendanceExceptionsApi.getAttendanceExceptionsReport(params);
		}
	};

	const exportTypeAutoComplete = {
		options: exportTypes,
		getOptionLabel: (option: DropDownListItem) => option.text,
	};

	const filterHandler = (data: any) => {
		if (data) {
			setModel(data);
		} else {
			setModel({
				campusIds: [],
				exportType: 1 as ReportOutput,
			});
		}
	};
	const setCampuses = (values: any, key: string) => {
		let newValues: string[] = [];
		if (values !== null) {
			if (Array.isArray(values)) {
				newValues = values.map((gr: any) => gr[key]);
			} else {
				newValues.push(values.value);
			}
		}
		return newValues;
	};



	const getReportParameters = async () => {
		const success = await data.triggerValidation();
		if (model && success) {

			let params: IAttendanceExceptions = {
				campusIds: model.campusIds,
				exportType: model.exportType,
			};
			return params;
		}
		return {} as IAttendanceExceptions;
	};

	const reportTypes: Array<DropDownListItem> = [
		{ text: 'Greater than', id: '>' },
		{ text: 'Less than', id: '<' },
	];


	const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
		const item = items.find((opt) => {
			if (opt.id === value) return opt;
		});
		return item || ({} as DropDownListItem);
	};

	let parameters = (
		<div className={classes.root}>
			<FormContext {...data}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container direction="row" spacing={1}>
						<Grid item md={9} sm={9} xs={12}>
							<CampusForCurrentUserAutoComplete
								id="campusIds"
								name="campusIds"
								label="Campus(es) *"
								multiple
								includeAllValue={true}
								showIncludeAllValue={true}
								filterHandle={(v: any) => {
									handleFieldOnChange('campusIds', v, setCampuses);
								}}
								valueFilter={
									model.campusIds
										? {
											key: 'value',
											values: model.campusIds,
										}
										: undefined
								}
								error={!!data.errors.campusIds}
								inputRef={data.register({
									validate: (_value) =>
										model.campusIds && model.campusIds.length > 0
											? true
											: false,
								})}
								helperText={
									data.errors.campusIds ? 'Campus(es) are required.' : undefined
								}
							/>
						</Grid>
						<Grid item md={3} sm={3} xs={12}>
							<Autocomplete
								{...exportTypeAutoComplete}
								autoComplete
								includeInputInList
								onChange={(e: any, value: any) => {
									handleFieldOnChange(
										'exportType',
										value ? value : undefined,
										(v: any) => v?.id
									);
								}}
								value={getSelectedItem(
									exportTypes,
									model.exportType?.toString()
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Export Type"
										name="exportType"
										error={!!data.errors.exportType}
										inputRef={data.register({ required: true })}
										helperText={
											data.errors.exportType
												? 'Export Type is required.'
												: undefined
										}
										fullWidth
									/>
								)}
							/>
						</Grid>
					</Grid>
				</form>
			</FormContext>
		</div>
	);
	reportOverview.parameters = parameters;

	return (
		<ReportOverview
			reportOverview={reportOverview}
			filterHandler={filterHandler}
			getReportParameters={getReportParameters}
			exportHandler={onSubmit}
			preview={preview}
			previewLoader={previewLoader}
		/>
	);
};
export default AttendanceExceptionsReport;
