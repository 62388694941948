import * as React from "react";
import { useEffect, useRef } from "react";
import { AddressTypesApiAutoComplete } from "../../constants/SystemCatalog/AddressTypesApiAutoComplete";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../constants/actions.constants";
import { FilterParams } from "../../interfaces/common/FilterParams";

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& label.Mui-focused": {
        color: theme.palette.primary.white
      }
    }
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {}
  })
);

const AddressTypesAutoComplete = (props: FilterParams) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const [AddressTypesSelected, setAddressTypesSelected] = React.useState(false);
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const inputRef: any = useRef(null);

  const filterHandle = props.filterHandle;

  const magnifyOnClick = (e: any) => {
    if (inputRef.current !== undefined) {
      inputRef.current.children[1].focus();
    }
  };

  const addressTypesOnChange = (e: any) => {
    if (filterHandle) filterHandle(e);

    setAddressTypesSelected(e);
  };

  return (
    <div className={clsx(classes.divWidth)}>
      <ApiAutoComplete
        config={AddressTypesApiAutoComplete.config}
        loading={userSelectedCampus ? false : true}
        reqParams={{ campusId: userSelectedCampus }}
        classes={{
          option: "text-black"
        }}
        label="Address Type *"
        onChange={(e: any) => {
          addressTypesOnChange(e);
        }}
        multiple={false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        inputRef={props.inputRef ? props.inputRef : inputRef}
        error={props.error}
        helperText={props.helperText}
        inputName={props.name}
      ></ApiAutoComplete>
    </div>
  );
};
export default AddressTypesAutoComplete;
