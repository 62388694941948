import DateFnsUtils from '@date-io/date-fns';
import { Button, Card, CardActions, createStyles, Grid, IconButton, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { CardBody } from 'reactstrap';
import CustomCardTitle from '../../../../../../interfaces/common/card/CustomCardTitle';
import IIllinoisTranscriptReportSettingsSubjectArea from '../../../../../../interfaces/setup/reports/IIllinoisTranscriptReportSettingsSubjectArea';
import SubjectAreaDetails from './SubjectAreaDetails';
import { selectors as IllinoisTranscriptSettingsSelectors, actions as IllinoisTranscriptSettingsActions } from '../../../../../../store/setup/reports/illinoisTranscriptSettings';
import { useActions } from '../../../../../../store/utils';
import { FieldError, useForm } from 'react-hook-form';
import { EmptyGuid } from '../../../../../../utils/constants';
import IIllinoisTranscriptReportSettings from '../../../../../../interfaces/setup/reports/IIllinoisTranscriptReportSettings';
import { isValid } from 'date-fns';
import DeleteIcon from '@material-ui/icons/Delete';
import Duplicate from '../../../../Reports/Components/IllinoisTranscript/Duplicate';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		cardBody: {
			minHeight: theme.spacing(30),
			padding: theme.spacing(2)
		},
		selectorCell: {
			cursor: 'pointer',
		}
	})
);

const ProgramVersionSettingDetails: React.FC<{ programVersionId: string; isDirty: boolean, setIsDirty: (value: boolean) => void, leaveConfirmOpen?: () => void, setLeaveConfirmOpen: React.Dispatch<React.SetStateAction<(() => void) | undefined>> }> = ({ programVersionId, isDirty, setIsDirty, setLeaveConfirmOpen }) => {
	const classes = useStyles({});
	const subJectAreas: Array<IIllinoisTranscriptReportSettingsSubjectArea> = useSelector(IllinoisTranscriptSettingsSelectors.subjectAreasToEdit);
	const details: IIllinoisTranscriptReportSettings | undefined = useSelector(IllinoisTranscriptSettingsSelectors.programVersionDetails);
	const selectedSubjectArea: number | undefined = useSelector(IllinoisTranscriptSettingsSelectors.selectSubjectArea);
	const sDetails = useSelector(IllinoisTranscriptSettingsSelectors.subjectAreaDetails);
	const actions = useActions({
		selectSubjectArea: IllinoisTranscriptSettingsActions.selectSubjectArea,
		saveDetails: IllinoisTranscriptSettingsActions.saveDetails,
		deleteSubjectArea: IllinoisTranscriptSettingsActions.deleteSubjectArea,
		closeSettings: IllinoisTranscriptSettingsActions.closeSettings,
		refreshSettings: IllinoisTranscriptSettingsActions.fetchSettings,
	}, []);
	const [values, setValues] = React.useState<{ effectiveDate?: Date }>({ effectiveDate: details?.effectiveDate });
	const { handleSubmit, register, errors, setValue } = useForm<{ effectiveDate?: Date }>({
		mode: 'onBlur', defaultValues: {
			effectiveDate: details?.effectiveDate,
		}
	});
	register({ name: 'effectiveDate' }, { validate: () => values.effectiveDate ? (!isValid(values.effectiveDate) ? 'Effective date is not valid.' : true) : 'Effective date is required.' });
	React.useEffect(() => {
		setValues({ effectiveDate: details?.effectiveDate });
		setValue('effectiveDate', details?.effectiveDate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [details]);

	const [duplicateOpen, setDuplicateOpen] = React.useState(false);
	
	return (
		<React.Fragment>
			<Card>
				<CustomCardTitle title="Program Version Setting Details" />
				<CardBody className={classes.cardBody}>
					<form>
						<Grid container spacing={2}>
							<Grid xs={3} item>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										disableToolbar
										variant="inline"
										format="MM/dd/yyyy"
										id="date-picker-inline"
										label="Effective"
										name="effectiveDate"
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}

										helperText={
											errors.effectiveDate
												? (errors.effectiveDate as FieldError).message
												: undefined
										}
										error={!!errors['effectiveDate']}
										onChange={
											(d) => {
												setValues({ ...values, effectiveDate: d ?? undefined });
												setIsDirty(true);
											}
										}
										value={values?.effectiveDate ?? null} />
								</MuiPickersUtilsProvider>
							</Grid>
						</Grid>
					</form>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<TableContainer>
								<Table >
									<TableHead>
										<TableRow>
											<TableCell variant='head'>Subject Areas</TableCell>
											<TableCell variant='head'>Sort Order</TableCell>
											<TableCell variant='head'></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{subJectAreas && subJectAreas.sort((a, b) => {
											const sa = a.sortOrder || 1; const sb = b.sortOrder || 1;
											if (sa - sb) {
												return sa-sb;
											}
											const na = (a.subjectAreaTitle || '').toLowerCase(); const nb = (b.subjectAreaTitle || '').toLowerCase();
											if (na > nb)
												return 1;
											if (nb > na)
												return -1;
											return 0;
										}).map((subjectArea, index) =>
											<TableRow key={index}>
												<TableCell className={classes.selectorCell} onClick={() => actions.selectSubjectArea(index)}>{subjectArea.subjectAreaTitle}</TableCell>
												<TableCell>{subjectArea.sortOrder}</TableCell>
												<TableCell>
													<IconButton onClick={() => { setIsDirty(true); actions.deleteSubjectArea(index + 1); }}>
														<DeleteIcon />
													</IconButton>
												</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</Grid>
					{sDetails && <SubjectAreaDetails isDirty={isDirty} setIsDirty={setIsDirty} details={sDetails} selectedProgramVersion={programVersionId} selctedIndex={selectedSubjectArea} />}
				</CardBody>
				<CardActions>

					<Button variant='contained' color='primary' onClick={handleSubmit(() => actions.selectSubjectArea(-1))}>Add Subject Area</Button>
					<Button variant='contained' color='primary' onClick={handleSubmit(() => {
						if (values.effectiveDate && programVersionId) {
							actions.saveDetails({ prgVerId: programVersionId, effectiveDate: values.effectiveDate, reportConfigurationSubjectAreas: subJectAreas, reportConfigurationId: details?.reportConfigurationId ?? EmptyGuid });
							setIsDirty(false);
						}
					})}>Save Settings</Button>
					{details?.reportConfigurationId && details?.reportConfigurationId !== EmptyGuid  && <Button variant='contained' color='primary' onClick={() => setDuplicateOpen(true)}>Duplicate</Button>}
					<Button variant='contained' color='secondary' onClick={() => isDirty ? setLeaveConfirmOpen(()=> () => {actions.closeSettings(); setIsDirty(false);}) : actions.closeSettings()}>Cancel</Button>
				</CardActions>
			</Card>
			<Duplicate refresh={() => actions.refreshSettings(programVersionId)} open={duplicateOpen} onClose={() => setDuplicateOpen(false)} fromConfig={details?.reportConfigurationId} />
		</React.Fragment>
	);
};

export default ProgramVersionSettingDetails;