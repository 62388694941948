const placementEmploymentTypes = {
	acId: "placementEmploymentTypes",
	cacheTime: 5,
	route: "/Placement/GetEmploymentTypesByCampus",
	requestType: "GET",
	body: null,
	hasUrlParams: false,
	useCache: true,
	storeInCache: true,
	isCampusSpecific: true,
	isUserSpecific: false,
	loadOnRender: true,
	defaultSelectedFirstItem: false,
	optionsMappedFx: (data: Array<any>) => {
		return data;
	},
};

export default placementEmploymentTypes;
