import * as React from "react";
import { useEffect, useRef } from "react";
import { apiAutoCompleteConfig } from "../../constants/apiAutoComplete.config";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../constants/actions.constants";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { AcademicYearApi } from "../../api/AwardDetail/AcademicYearApi";

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& label.Mui-focused": {
        color: theme.palette.primary.white
      }
    }
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {}
  })
);

const AwardYearAutoComplete = (props: FilterParams) => {
  const classes = useStyles({});
  const inputRef: any = useRef(null);

  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const awardYearOnChange = (e: any) => {
    if (props.filterHandle) {
      props.filterHandle(e);
    }
  };

  return (
    <div>
      <ApiAutoComplete
        config={AcademicYearApi.academicYear}
        loading={userSelectedCampus ? false : true}
        reqParams={{ campusId: userSelectedCampus }}
        classes={{
          option: "text-black"
        }}
        label={props.label ? props.label : props.noLabel ? undefined : "Award Year"}
        onChange={(e: any) => {
          awardYearOnChange(e);
        }}
        multiple={false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        inputRef={props.inputRef ? props.inputRef : inputRef}
        error={props.error}
        helperText={props.helperText}
        inputName={props.name}
        disabled={props.disabled}
      ></ApiAutoComplete>
    </div>
  );
};
export default AwardYearAutoComplete;
