import { Fragment } from "react";
import { makeStyles, createStyles, Grid, Button } from "@material-ui/core";
import { useForm, FormContext, FieldError } from "react-hook-form";
import { ThemeTextField } from "../../../components/_Layout/Inputs/ThemeTextField";
import { IncrementDetails } from "../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/CharginMethod/IIncrementDetails";
import { IChargingMethodIncrement } from "../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/CharginMethod/IChargingMethodIncrement";
import {
  ChargeDefinitionVersion,
  ChargeDefinitionPeriods,
  ChargeDefinitionPeriodsDef,
} from "../../../interfaces/setup/chargeDefinition/ChargeDefinitionVersion";
import IncrementDetailsGrid from "./IncrementDetailsGrid";
import React from "react";
import { CreateNewGuid } from "../../../utils/constants";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
    cardWrapper: {
      height: "100%",
      width: "100%",
    },
    fullWidthField: {
      width: "100%",
    },
    switch: {
      marginLeft: "0px",
    },
    incrementRow: {
      marginBottom: theme.spacing(1),
    },
    label: {
      paddingTop: theme.spacing(2),
    },
    datePickerField: {
      width: "100%",
      background: "white",
      borderRadius: theme.spacing(0.5),
      "& .MuiInputBase-root": {
        "& input": {
          width: "100%",
        },
      },
    },
  })
);

type IncrementDetailsCardProps = {
  model: ChargeDefinitionVersion;
  setModel: (model: ChargeDefinitionVersion) => void;
  isEditable: boolean;
  usesActualHours: boolean;
  methods: any;
};

const IncrementDetailsCard = (props: IncrementDetailsCardProps) => {
  const setDetails = (newValue: any) => {
    handleFieldChange("details", newValue);
  };
  const classes = useStyles({});
  const { model, setModel } = props;

  const handleAddNewPeriod = () => {
    let modelToUpdate = { ...model };

    let newRow = new ChargeDefinitionPeriodsDef();
    newRow.incrementId = CreateNewGuid();
    newRow.chargeDefinitionVersionId =
      props.model.chargeDefinitionVersionId ?? CreateNewGuid();
    newRow.hours = null ?? 0;
    newRow.options = null;
    newRow.isEditModeOn = false;
    newRow.scheduledHours = 0;
    newRow.actualHours = 0;
    newRow.transCodeId = "";
    let arrayUpdated = modelToUpdate.periods ?? [];
    arrayUpdated?.push(newRow);

    modelToUpdate.periods = [...arrayUpdated];
    setModel(modelToUpdate);
  };

  const handleFieldChange = (fieldId: string, value: any) => {
    let modelToUpdate = model;
    (modelToUpdate as any)[fieldId] = value;
    setModel({ ...modelToUpdate });
    props.methods.triggerValidation();
  };
  return (
    <Fragment>
      <FormContext {...props.methods}>
        <Grid container direction="row" spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                clearable
                format={"MM/dd/yyyy"}
                placeholder="mm/dd/yyyy"
                id="description"
                name="description"
                key="description"
                label="Payment Period Effective Date *"
                TextFieldComponent={ThemeTextField}
                className={classes.datePickerField}
                value={
                  model && model.effectiveDate
                    ? new Date(model.effectiveDate).toLocaleDateString()
                    : null
                }
                onChange={(value: any) => {
                  handleFieldChange("effectiveDate", value ? value : undefined);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                autoOk={true}
                disabled={!props.isEditable}
                //required
                error={!!props.methods.errors["effectiveDate"]}
                innerRef={(ref: any) => {
                  props.methods.register("effectiveDate", {
                    required: true,
                  });
                  props.methods.setValue("effectiveDate", model.effectiveDate);
                }}
                helperText={
                  props.methods.errors.effectiveDate
                    ? "Effective Date is required."
                    : undefined
                }
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <ThemeTextField
              id="description"
              label="Description *"
              name="description"
              className={classes.fullWidthField}
              onChange={(e: any) => {
                handleFieldChange(
                  "description",
                  e ? e.target.value : undefined
                );
              }}
              value={model ? model.description : ""}
              disabled={!props.isEditable}
              error={!!props.methods.errors["description"]}
              innerRef={(ref: any) => {
                props.methods.register("description", {
                  required: true,
                });
                props.methods.setValue("description", model.effectiveDate);
              }}
              helperText={
                props.methods.errors.description
                  ? "Description is required."
                  : undefined
              }
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <ThemeTextField
              id="excAbsencesPercent"
              label="Percentage of Excused Absences to Include in Calculation"
              name="excAbsencesPercent"
              className={classes.fullWidthField}
              value={model ? model.excAbsencesPercent : 0}
              disabled={!props.isEditable}
              onChange={(e: any) => {
                handleFieldChange(
                  "excAbsencesPercent",
                  e ? e.target.value : undefined
                );
              }}
            />
          </Grid>

          {props.isEditable && (
            <Grid item container direction="row" spacing={4} xs={12}>
              <Grid item xs={4}>
                <Button
                  color="secondary"
                  variant="contained"
                  type="button"
                  onClick={handleAddNewPeriod}
                >
                  Add New Period
                </Button>
              </Grid>
            </Grid>
          )}
          <IncrementDetailsGrid
            details={model.periods ? model.periods : []}
            isEditable={props.isEditable}
            setModel={setDetails}
            usesActualHours={props.usesActualHours}
          />
        </Grid>
      </FormContext>
    </Fragment>
  );
};

export default IncrementDetailsCard;
