import API from "../../apiWrapper";
import { Holiday } from "../../../interfaces/setup/holiday/holiday";

export const getHolidayByCampus = (CampusId: string) => {
  return API()
    .get("/SystemCatalog/Holiday/GetHolidayByCampus", {
      params: {
        campusId: CampusId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const UpdateHoliday = (model: Holiday) => {
  return API()
    .post("/SystemCatalog/Holiday/UpdateHoliday", model)
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};

export const GetHolidayById = (holidayId: string) => {
  return API()
    .get("/SystemCatalog/Holiday/GetHolidayById", {
      params: {
        holidayId: holidayId,
      },
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res;
        }
      },
      (error: any) => {
        return error;
      }
    );
};

export const DeleteHoliday = (holidayId: string, campusId: string) => {
  return API()
    .delete("/SystemCatalog/Holiday/DeleteHoliday", {
      params: {
        holidayId,
        campusId,
      }
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        throw new Error(error.message);
      }
    );
};