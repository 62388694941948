import * as React from "react";
import { useRef } from "react";
import { ProgramVersionApiAutoComplete } from "../../constants/SystemCatalog/ProgramVersionApiAutoCompleteMultiCampus";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {
			width: "95%",
		},
		fullWidth: { width: "100%", }
	})
);
export interface IProgramVersionMCProps extends FilterParams {
	programId?: string;
	campuses?: Array<string>;
	shouldCheckForEffectiveEndDate?: boolean;
	showInactivePrograms?: boolean;
}

const ProgramVersionsMCAutoComplete = (props: IProgramVersionMCProps) => {
	const classes = useStyles({});
	const inputRef: any = useRef(null);
	const [shouldCheckForEffectiveEndDate, setShouldCheckForEffectiveEndDate] =
		React.useState<boolean>(false);

	
	const PrgVerOnChange = (e: any) => {
		if (props.filterHandle) {
			props.filterHandle(e);
		}
	};

	const [disabled, setIsDisabled] = React.useState<any | null>();
	React.useEffect(() => {
		setIsDisabled(props.disabled);
	}, [props.disabled]);

	React.useEffect(() => {
		if (props?.shouldCheckForEffectiveEndDate === true) {
			setShouldCheckForEffectiveEndDate(true);
		}
	}, [props.shouldCheckForEffectiveEndDate]);

	return (
		<div className={props.params?.fullWidth ? clsx(classes.fullWidth) : clsx(classes.divWidth)}>
			<ApiAutoComplete
				disabled={disabled}
				config={ProgramVersionApiAutoComplete.config}
				loading={props.campuses ? false : true}
				reqParams={
					{
						campusId: props.campuses,
						isInactive: props?.showInactivePrograms,
						shouldCheckForEffectiveEndDate: shouldCheckForEffectiveEndDate,
					}
				}
				classes={{
					option: "text-black",
				}}
				label={props.label ? props.label : "Program Version"}
				placeholder={
					props.valueFilter &&
						props.valueFilter.values &&
						props.valueFilter.values.length > 0
						? ""
						: "Select a program version"
				}
				onChange={(e: any) => {
					PrgVerOnChange(e);
				}}
				multiple={props.multiple ? props.multiple : false}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
				inputRef={props.inputRef ? props.inputRef : inputRef}
				error={props.error}
				helperText={props.helperText}
				inputName={props.name}
				showIncludeAllValue={props.showIncludeAllValue}
				chipSize={props.chipSize}
				maxTags={props.maxTags}
				includeAllValue={props.includeAllValue ? props.includeAllValue : false}
			></ApiAutoComplete>
		</div>
	);
};
export default ProgramVersionsMCAutoComplete;
