import { Action } from "redux";
import { mruConstants } from "../../constants/actions.constants";

const initialState: any = {
    refreshMru: false
};

const mruReducer = (state = initialState, action: Action<any>) => {
    switch (action.type) {
        case mruConstants.REFRESH_MRU: {
            return {
                ...state,
                refreshMru: !state.refreshMru
            };
        }
        default: {
            return state;
        }
    }
};

export default mruReducer;
