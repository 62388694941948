import React, { useState, useEffect, useRef } from "react";
import DisplayLabel from "../../Label/DisplayLabel";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Typography, Link, Fade } from "@material-ui/core";
import clsx from "clsx";
import FileCopy from "@material-ui/icons/FileCopy";
import Check from "@material-ui/icons/Check";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		displayFieldContainer: {
			// border: "1px solid blue",
			position: "relative"
		},
		rounded: {},
		fieldTextStyle: {
			fontSize: 14,
			color: theme.palette.text.primary
		},
		fieldLinkStyle: {
			color: theme.palette.text.link
		},
		copyIcon: {
			color: theme.palette.text.secondary,
			display: "block !important",
			height: 15,
			width: 15,
			top: 15,
			right: 0,
			position: "absolute",
			cursor: "pointer"
		},
		checkIcon: {
			color: theme.palette.text.primary,
			display: "block !important",
			height: 15,
			width: 15,
			top: 15,
			right: 18,
			position: "absolute",
			cursor: "pointer"
		},
		showIcon: {
			paddingLeft: theme.spacing(1)
		}
	})
);

type IDisplayFieldProps = {
	children?: React.ReactNode;
	className?: string;
	labelText: any;
	valueText: any;
	fieldType: "text" | "email" | "phone";
	copyEnabled?: true;
};

const DisplayField = (props: IDisplayFieldProps) => {
	const {
		className,
		labelText,
		valueText,
		fieldType
	} = props;
	const classes = useStyles({});
	const [mouseHovered, setMouseHovered] = useState(false);
	const [wasClicked, setWasClicked] = useState(false);
	const textToCopyRef: any = useRef(null);

	useEffect(() => {});

	const itemClasses = clsx(
		{
			[classes.displayFieldContainer]: true
		},
		className
	);

	const hideCheckAfterTime = () => {
		setTimeout(() => {
			setWasClicked(false);
		}, 1000);
	};

	function selectElementContents(el: any) {
		let range = document.createRange();
		range.selectNodeContents(el);
		let sel: any = window.getSelection();
		sel.removeAllRanges();
		sel.addRange(range);
	}

	function unSelectSelectedText() {
		let doc: any = document;
		if (window.getSelection) {
			if (window.getSelection()!.empty) {
				// Chrome
				window.getSelection()!.empty();
			} else if (window.getSelection()!.removeAllRanges) {
				// Firefox
				window.getSelection()!.removeAllRanges();
			}
		} else if (doc.selection) {
			// IE?
			doc.selection.empty();
		}
	}

	function copyTextToClipboard(e: any) {
		selectElementContents(textToCopyRef.current);
		document.execCommand("copy");
		unSelectSelectedText();
	}

	return (
		<div
			className={itemClasses}
			onMouseEnter={() => setMouseHovered(true)}
			onMouseLeave={() => setMouseHovered(false)}
		>
			<DisplayLabel>{labelText}</DisplayLabel>
			{fieldType === "text" && (
				<Typography ref={textToCopyRef} className={classes.fieldTextStyle}>
					{valueText}
				</Typography>
			)}
			{(fieldType === "email" || fieldType === "phone") && (
				<Link
					ref={textToCopyRef}
					href={(fieldType === "email" ? "mailto:" : "tel:") + valueText}
					onClick={(e: any) => {
						e.preventDefault();
					}}
					className={clsx(classes.fieldTextStyle, classes.fieldLinkStyle)}
				>
					{valueText}
				</Link>
			)}

			{mouseHovered &&
				!wasClicked &&
				document.queryCommandSupported("copy") && (
					<Fade in={true} timeout={500}>
						<FileCopy
							className={clsx(classes.copyIcon)}
							onClick={e => {
								setWasClicked(true);
								hideCheckAfterTime();
								copyTextToClipboard(e);
							}}
						></FileCopy>
					</Fade>
				)}
			{wasClicked && document.queryCommandSupported("copy") && (
				<Fade in={true} timeout={500}>
					<Check
						className={clsx(classes.copyIcon)}
						onClick={() => {}}
					></Check>
				</Fade>
			)}
		</div>
	);
};

DisplayField.defaultProps = {
	fieldType: "text"
};

export default DisplayField;
