import { Dialog, DialogContent, Table, TableHead, TableBody, TableRow, TableCell, CircularProgress, IconButton, DialogActions, Grid, Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import * as React from 'react';
import { useSelector } from 'react-redux';
import * as EFCAPI from '../../../../api/student/studentAccounts/EFCHistory';
import SaveIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AwardYearAutoComplete from '../../../../components/AutoComplete/AwardYearAutoComplete';
import { EFCHistory } from "../../../../interfaces/student/studentAccounts/EFCHistory";
import { useFormContext, FieldError } from "react-hook-form";
import { EmptyGuid } from '../../../../utils/constants';
import InputField from '../../../../components/_Layout/Inputs/InputField';
import { AdornmentPosition, InputType } from '../../../../constants/uiConstants/inputConstants';
import ProgressSaveButton from '../../../../components/_Layout/Buttons/ProgressSaveButton';
import { SnackBarStateProps } from './StudentInfo';

const useStyles = makeStyles((theme: any) =>
    createStyles({
        loadingCell: {
            textAlign: 'center'
        },
        tableCell: {
            minWidth: 200
        }
    }));

interface IEFCHistoryFieldProps {
    open?: boolean;
    closeDialogue: () => void;
    showSnackBar?: (anckBar: SnackBarStateProps) => void;
    setEfcAfterSave: (efc: { efcValue?:  Number, efcId?: string }) => void;
}
const EFCHistoryField = (props: IEFCHistoryFieldProps) => {
    const classes = useStyles();
    const data = useFormContext();
    const [newModel, setNewModel] = React.useState<EFCHistory>({ efchistoryId: EmptyGuid });
    const [loading, setLoading] = React.useState<boolean>(false);
    const [updating, setUpdating] = React.useState<boolean>(false);
    const [efcRecords, setEfcRecords] = React.useState<EFCHistory[]>([]);
    const selectedStudent = useSelector((state: any) => state.student);
    const [toEdit, setToEdit] = React.useState<number>(-1);
    const getDataFormAPI = async (): Promise<void> => {
        setLoading(true);
        try {
            if (selectedStudent && selectedStudent.studentId && props.open) {
                const response = await EFCAPI.getEFCHistory(selectedStudent.studentId);
                setEfcRecords(response);
            }
        } catch (err) {
            props.showSnackBar && props.showSnackBar({
                variant: "error",
                messageInfo: err.message,
                showSnackBar: true
            });
        }
        setLoading(false);
    }

    const handleFieldOnChange = (index: number, fieldId: keyof EFCHistory, value: any) => {
        if (index === -1) {
            newModel[fieldId] = value;
            setNewModel({ ...newModel });
        }
        else {
            efcRecords[index] = { ...efcRecords[index], [fieldId]: value };
            setEfcRecords([...efcRecords]);
        }
    }

    const saveNewEFC = async () => {
        var valid = await data.triggerValidation(["amount-new", "academicYearId-new"]);
        if (valid) {
            setEfcRecords([...efcRecords, { ...newModel, leadId: selectedStudent.leadId }])
            data.reset(["amount-new", "academicYearId-new"]);
            setNewModel({ efchistoryId: EmptyGuid });
        }
    }

    const deleteEfc = (index: number) => {
        efcRecords.splice(index, 1);
        setEfcRecords([...efcRecords]);
    }

    const saveEFC = async (index: number) => {
        var valid = await data.triggerValidation([`amount-${index}`, `academicYearId-${index}`]);
        if (valid) {
            setToEdit(-1);
        }
    }

    const saveAll = async () => {
        setUpdating(true);
        try {
            var response = await EFCAPI.updateEfcHistory(selectedStudent.studentId, efcRecords);
            setEfcRecords(response);
            props.showSnackBar && props.showSnackBar({
                variant: "success",
                messageInfo: "EFC Saved Successfully.",
                showSnackBar: true
            });
            props.setEfcAfterSave({ efcValue: (efcRecords.length && efcRecords[efcRecords.length - 1].amount) || undefined, efcId: (efcRecords.length && efcRecords[efcRecords.length - 1].efchistoryId) || undefined })
        } catch (err) {
            props.showSnackBar && props.showSnackBar({
                variant: "error",
                messageInfo: err.message,
                showSnackBar: true
            });
        }
        setUpdating(false);
    }

    React.useEffect(() => {
        getDataFormAPI();
    }, [props.open, selectedStudent]);

    return <Dialog maxWidth='md' open={props.open ?? false} onClose={props.closeDialogue}>
        <DialogContent>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableCell}>Award Year</TableCell>
                        <TableCell className={classes.tableCell}>EFC</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        !loading && (<TableRow>
                            <TableCell>
                                <AwardYearAutoComplete
                                    disabled={toEdit !== -1}
                                    name="academicYearId-new"
                                    id="academicYearId-new"
                                    noLabel
                                    valueFilter={
                                        newModel.academicYearId &&
                                            newModel.academicYearId !== EmptyGuid
                                            ? {
                                                key: "value",
                                                values: [newModel.academicYearId],
                                            }
                                            : undefined
                                    }
                                    filterHandle={(v: any) => {
                                        handleFieldOnChange(-1, "academicYearId", v ? v.value : null);
                                    }}
                                    error={!!data.errors["academicYearId-new"]}
                                    inputRef={data.register({
                                        required: true, validate: () => {
                                            const found = efcRecords.findIndex(m => m.academicYearId === newModel.academicYearId);
                                            return (found < 0) ? true : "EFC Already added for the Academic Year."
                                        }
                                    })}
                                    helperText={
                                        data.errors["academicYearId-new"] ? (data.errors["academicYearId-new"] as FieldError).message : undefined
                                    } />
                            </TableCell>
                            <TableCell>
                                <InputField
                                    disabled={toEdit !== -1}
                                    id="amount-new"
                                    key="amount-new"
                                    name="amount-new"
                                    onBlur={(e: any) => {
                                        handleFieldOnChange(-1, "amount", e.target.value);
                                    }} inputRef={data.register({ required: true })}
                                    defaultValue={newModel.amount}
                                    type={InputType.CURRENCY}
                                    adornmentPosition={AdornmentPosition.PRE}
                                    error={!!data.errors["amount-new"]}
                                    decimal
                                    helperText={
                                        data.errors["amount-new"] ? "Efc amount is required." : undefined
                                    } />
                            </TableCell>
                            <TableCell>
                                <IconButton disabled={toEdit !== -1}
                                    color="primary" type="button" onClick={saveNewEFC}>
                                    <SaveIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>)
                    }
                    {
                        loading ?
                            <TableRow>
                                <TableCell colSpan={3} className={classes.loadingCell}>
                                    <CircularProgress color="primary" />
                                </TableCell>
                            </TableRow>
                            :
                            efcRecords.map((m, index) => (
                                <TableRow>
                                    <TableCell>
                                        <AwardYearAutoComplete
                                            disabled={toEdit !== index}
                                            name={`academicYearId-${index}`}
                                            id={`academicYearId-${index}`}
                                            noLabel
                                            valueFilter={
                                                m.academicYearId &&
                                                    m.academicYearId !== EmptyGuid
                                                    ? {
                                                        key: "value",
                                                        values: [m.academicYearId],
                                                    }
                                                    : undefined
                                            }
                                            filterHandle={(v: any) => {
                                                handleFieldOnChange(index, "academicYearId", v ? v.value : null);
                                            }}
                                            error={!!data.errors[`academicYearId-${index}`]}
                                            inputRef={data.register({
                                                required: true, validate: () => {
                                                    const found = efcRecords.findIndex((r, sIndex) => r.academicYearId === m.academicYearId && sIndex !== index);
                                                    return (found < 0) ? true : "EFC Already added for the Academic Year."
                                                }
                                            })}
                                            helperText={
                                                data.errors[`academicYearId-${index}`] ? (data.errors[`academicYearId-${index}`] as FieldError).message : undefined
                                            } />
                                    </TableCell>
                                    <TableCell>
                                        <InputField
                                            disabled={toEdit !== index}
                                            name={`amount-${index}`}
                                            id={`amount-${index}`}
                                            onBlur={(e: any) => {
                                                handleFieldOnChange(index, "amount", e.target.value);
                                            }} inputRef={data.register({ required: true })}
                                            defaultValue={m.amount}
                                            type={InputType.CURRENCY}
                                            adornmentPosition={AdornmentPosition.PRE}
                                            error={!!data.errors[`amount-${index}`]}
                                            decimal
                                            helperText={
                                                data.errors[`amount-${index}`] ? "Efc amount is required." : undefined
                                            } />
                                    </TableCell>
                                    <TableCell>
                                        {
                                            toEdit === -1 && (
                                                <React.Fragment>
                                                    <IconButton onClick={() => setToEdit(index)}
                                                        color="primary" type="button">
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton onClick={() => deleteEfc(index)}
                                                        color="primary" type="button">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </React.Fragment>
                                            )
                                        }
                                        {
                                            toEdit === index && (
                                                <IconButton
                                                    color="primary" type="button" onClick={() => saveEFC(index)}>
                                                    <SaveIcon />
                                                </IconButton>
                                            )
                                        }
                                    </TableCell>
                                </TableRow>
                            ))
                    }
                </TableBody>
            </Table>
        </DialogContent>
        <DialogActions>
            <Grid container direction="row" spacing={1}>
                <Grid item xs={3}></Grid>
                <Grid
                    item
                    container
                    xs={6}
                    alignContent="center"
                    alignItems="center"
                    justify="center"
                    spacing={1}
                >
                    <Grid item>
                        <ProgressSaveButton text="Save"
                            loading={updating}
                            onClick={saveAll}
                            disabled={loading || toEdit !== -1}
                        />
                    </Grid>
                    <Grid item>
                        <Button disabled={loading} color="primary" variant="contained" onClick={props.closeDialogue}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog>;
}

export default EFCHistoryField;