import API from "../../apiWrapper";
import IClassDetails from "../../../interfaces/setup/creditHour/IClassDetails";
import IClassSearchParam from "../../../interfaces/setup/creditHour/IClassSearchParam";
import IClassGradeBookParams from "../../../interfaces/setup/creditHour/IClassGradeBookParams";
import ICreditCourseGradeBook from "../../../interfaces/setup/academics/ProgramDefinition/ICourseGradeBook";

export const getAllCourses = (): Promise<IClassDetails[]> => {
    return API()
        .get("/AcademicRecords/Class/GetAllClasses")
        .then((res: any) => {
            if (res && res.data) {
                return res.data;
            }
            return [];
        }, (_error: any) => {
            return [];
        });
};

export const GetClassById = (classId: string): Promise<IClassDetails> => {
    return API()
        .get("/AcademicRecords/Class/GetClassById", {
            params: {
                classId
            }
        })
        .then((res: any) => {
            if (res && res.data) {
                return res.data
            }
            throw (new Error('Unknown error'));
        }, (error: any) => {
            if (error.response) {
                throw new Error(error.response.data); 
            }
            throw (new Error('Unknown error'));
        });
};

export const UpsertClass = (classDetails: IClassDetails) => {
    return API()
        .post("/AcademicRecords/Class/UpsertClass", classDetails)
        .then((res: any) => {
            if (res && res.data && res.data.result) {
                return {
                    classDetails: res.data.result,
                    message: res.data.resultStatusMessage
                };
            }
            throw (new Error('Unknown error'));
        }, (error: any) => {
            if (error.response) {
                throw new Error(error.response.data);
            }
            throw (new Error('Unknown error'));
        });
};

export const GetGradeBookByClassId = (classId: string): Promise<string> => {
    return API()
        .get("/AcademicRecords/Class/GetGradeBookByClassId", {
            params: {
                classId
            }
        })
        .then((res: any) => {
            return res;
        }, (error: any) => {
            if (error.response) {
                throw new Error(error.response.data); 
            }
            throw (new Error('Unknown error'));
        });
};

export const GetGradeBookByCourseId = (courseId: string, classId?: string): Promise<ICreditCourseGradeBook[]> => {
    return API()
        .get("/AcademicRecords/CreditCourses/GetGradeBookByCourseId", {
            params: {
                courseId,
                classId
            }
        })
        .then((res: any) => {
            return res?.data;
        }, (error: any) => {
            if (error.response) {
                throw new Error(error.response.data); 
            }
            throw (new Error('Unknown error'));
        });
};

export const UpdateClassGradeBook = (classGradeBook: IClassGradeBookParams) => {
    return API()
        .post("/AcademicRecords/Class/UpdateClassGradeBook", classGradeBook)
        .then((res: any) => {
            if (res && res.data) {
                return res.data;
              }
        }, (error: any) => {
            if (error.response) {
                throw new Error(error.response.data);
            }
            throw (new Error('Unknown error'));
        });
};

export const deleteClassById = (classId: string) => {
    return API()
      .delete("/AcademicRecords/Class/DeleteClass", {
        params: {
            classId
        }
      })
      .then(
        (res: any) => {
          if (res && res.data) {
            return res.data;
          }
        },
        (error: any) => {
          throw new Error(error.message);
        }
      );
  };

  export const getClassesByInstructor = (campusId: string, instructorId: string, activeOnly: boolean) => {
    return API()
      .get("/AcademicRecords/Class/GetClassesByInstructor", {
        params: {
            campusId,
            instructorId,
            activeOnly
        }
      })
      .then(
        (res: any) => {
          if (res && res.data) {
            return res.data;
          }
        },
        (error: any) => {
          throw new Error(error.message);
        }
      );
  };

export const SearchClasses = (params: IClassSearchParam) => {
    return API()
        .post("/AcademicRecords/Class/SearchClasses", params)
        .then((res: any) => {
            if (res && res.data && res.data) {
                return res;
            }
            throw (new Error('Unknown error'));
        }, (error: any) => {
            if (error.response) {
                throw new Error(error.response.data);
            }
            throw (new Error('Unknown error'));
        });
};

