import IIllinoisTranscriptReportSettings from '../../../interfaces/setup/reports/IIllinoisTranscriptReportSettings';
import API from '../../apiWrapper';

export const getReportConfigurations = (params: { programVersionsId: string }): Promise<Array<IIllinoisTranscriptReportSettings>> => {
	return API().get('/AcademicRecords/ProgramVersions/GetIllinoisTranscriptReportConfigurations', {
		params: {
			programVersionId: params.programVersionsId
		}
	}).then(response => response.data);
};

export const getReportConfigurationsByCampus = (params: { campusId: string }): Promise<Array<IIllinoisTranscriptReportSettings>> => {
	return API().get('/Configuration/ReportConfiguration/GetReportConfigurations', {
		params: {
			campusId: params.campusId
		}
	}).then(response => response.data);
};

export const saveConfigurations = async (data: IIllinoisTranscriptReportSettings): Promise<string> => {
	try {
		await API().post('/AcademicRecords/ProgramVersions/SaveIllinoisTranscriptReportConfigurations', data);
		return 'Configuration saved successfully.';
	}
	catch (error) {
		throw new Error('Error saving report configurations');
	}
};

export const deleteConfiguration = async (params: { id: string }): Promise<string> => {
	try {
		await API().delete('/Configuration/ReportConfiguration/DeleteReportConfiguration', {
			params: {
				reportConfigurationId: params.id
			}
		});
		return 'Success';
	}
	catch (error) {
		throw new Error('Error deleting report configuration');
	}
};

export const duplicateConfiguration = async (params: { fromConfiguration: string, programVersion: string }): Promise<IIllinoisTranscriptReportSettings> => {
	try {
		const response = await API().get('/Configuration/ReportConfiguration/DuplicateConfigration', {
			params: {
				fromConfiguration: params.fromConfiguration,
				programVersion: params.programVersion
			}
		});

		return response.data;
	}
	catch (error) {
		throw new Error('Error duplicating report configuration');
	}
};
