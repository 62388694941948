import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect, useSelector } from "react-redux";
import { sessionActions as actions } from "../../actions/sessionActions";
import TenantAutoComplete from "../AutoComplete/TenantSelectorAutoComplete"
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
	btnMargin : {
		marginRight : theme.spacing(3.5)
	  }
  }));

const TenantSelector = (props: any) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [tenantName, setTenantName] = React.useState("");
  const account = useSelector((state: any) => state.account);

  React.useEffect(() => {
    setOpen(account.isMultiTenantUser)
  }, [account.isMultiTenantUser]);

  const handleCancel = () => {
    handleClose();
    props.cancelTenantSelector();
  }

  const handleContinue = () => {
    if (tenantName) {
      handleClose();
      props.handleContinue(tenantName);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFieldOnChange = (fieldId: string, value: any) => {
    setTenantName(value);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='xs'
        fullWidth = {true}
      >
        <DialogTitle id="alert-dialog-title"><span >Please select tenant</span></DialogTitle>
        <DialogContent>
          <TenantAutoComplete
            filterHandle={(v: any) => {
              handleFieldOnChange("tenantName", v ? v.value : undefined);
            }}
            label={"Tenant *"}
            id="tenant"
            name="tenant"
            
            valueFilter={
              tenantName
                ? {
                  key: "value",
                  values: [tenantName],
                }
                : undefined
            }
            options={account.tenantPickerOptions}
          />
        </DialogContent>
        <DialogActions>
          <div className={classes.btnMargin}>
            <Button onClick={handleCancel} color="default" >
              Cancel
          </Button>
            <Button onClick={handleContinue} color="primary" autoFocus>
              Continue
          </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function mapState(state: any) {
  return state;
}

const actionCreators = {
  cancelTenantSelector: actions.cancelTenantSelector
};

interface ITenantSelectorProp {
  cancelTenantSelector: any,
  handleContinue: any

};
const connectedLoginPage = connect<ITenantSelectorProp>(mapState, actionCreators)(TenantSelector);
export { connectedLoginPage as TenantSelector };