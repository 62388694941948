/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */

import HomeIcon from '@material-ui/icons/HomeOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import SchoolIcon from '@material-ui/icons/SchoolOutlined';
import WatchIcon from '@material-ui/icons/WatchLaterOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import SyncAltOutlinedIcon from '@material-ui/icons/SyncAltOutlined';
import { UserMenuItem } from '../../../interfaces/user/UserMenuItem';
import ComponentList from '../../routing/ComponentList';
import LocalLibraryOutlinedIcon from '@material-ui/icons/LocalLibraryOutlined';

  const filterMenu = (array: UserMenuItem[]): UserMenuItem[] => {
    if (!array || array.length === 0) return [];
    const filteredItems = array.filter((item) => item.isCreditHour == false);
    return filteredItems.map((item) => ({
      ...item,
      childUserMenuItems: filterMenu(item.childUserMenuItems),
    }));
  };

export const buildNavigationConfig = (userMenu: Array<UserMenuItem>,EnableClockHourCreditHour:Boolean = false) => {
	let tabsList = ComponentList.tabs;

	if(EnableClockHourCreditHour){
		  userMenu = filterMenu(userMenu);
	} 

	let result = { title: 'Pages', pages: [] as Array<any> };
	result.pages = userMenu
  .sort((a, b) => a.displayOrder - b.displayOrder)
  .map((menuItem: UserMenuItem) => {
        let userMenuItem = {
            title: menuItem.menuName,
            href: menuItem.url,
            route: menuItem.route,
            component: menuItem.component,
            advantageLink: menuItem.legacyUrl,
            icon: menuItem.icon,
            resId: menuItem.resourceId,
            menuTypeId: menuItem.menuItemTypeId,
            children:
                menuItem.childUserMenuItems.length > 0
                    ? (menuItem.childUserMenuItems.sort((a, b) => a.displayOrder - b.displayOrder).map((childMenuItem) => {
                            return {
                                title: childMenuItem.menuName,
                                href: childMenuItem.url,
                                route: childMenuItem.route,
                                component: childMenuItem.component,
                                advantageLink: childMenuItem.legacyUrl,
                                icon: childMenuItem.icon,
                                resId: childMenuItem.resourceId,
                                menuTypeId: childMenuItem.menuItemTypeId,
                                tabs: tabsList.filter((tabItem: any) => {
                                    return tabItem.parent === childMenuItem.component;
                                })[0],
                                children:
                                    childMenuItem.childUserMenuItems.length > 0
                                        ? (childMenuItem.childUserMenuItems.sort((a,b)=>a.menuName.localeCompare(b.menuName)).map((grandChild) => {
                                                return {
                                                    title: grandChild.menuName,
                                                    href: grandChild.url,
                                                    route: grandChild.route,
                                                    component: grandChild.component,
                                                    advantageLink: grandChild.legacyUrl,
                                                    icon: grandChild.icon,
                                                    resId: grandChild.resourceId,
                                                    menuTypeId: grandChild.menuItemTypeId,
                                                    children:
                                                    grandChild.childUserMenuItems.length > 0
                                                        ? (grandChild.childUserMenuItems.sort((a,b)=>a.menuName.localeCompare(b.menuName)).map((grandChildItem) => {
                                                                return {
                                                                    title: grandChildItem.menuName,
                                                                    href: grandChildItem.url,
                                                                    route: grandChildItem.route,
                                                                    component: grandChildItem.component,
                                                                    advantageLink: grandChildItem.legacyUrl,
                                                                    icon: grandChildItem.icon,
                                                                    resId: grandChildItem.resourceId,
                                                                    menuTypeId: grandChildItem.menuItemTypeId,
                                                                };
                                                          }) as Array<any>)
                                                        : undefined,
                                                };
                                          }) as Array<any>)
                                        : undefined,
                            };
                      }) as Array<any>)
                    : undefined,
        } as any;
        return userMenuItem;
    });
    return result;
};


export const mapIcons = (iconName: string) => {
	switch (iconName) {
		case 'HomeIcon':
			return HomeIcon;
		case 'PeopleIcon':
			return PeopleIcon;
		case 'PersonIcon':
			return PersonIcon;
		case 'SchoolIcon':
			return SchoolIcon;
		case 'WatchIcon':
			return WatchIcon;
		case 'MonetizationOnOutlinedIcon':
			return MonetizationOnOutlinedIcon;
		case 'AccountBalanceOutlinedIcon':
			return AccountBalanceOutlinedIcon;
		case 'BuildOutlinedIcon':
			return BuildOutlinedIcon;
		case 'PrintOutlinedIcon':
			return PrintOutlinedIcon;
		case 'SettingsOutlinedIcon':
			return SettingsOutlinedIcon;
		case 'SyncAltOutlinedIcon':
			return SyncAltOutlinedIcon;
      case 'LocalLibraryOutlinedIcon':
        return LocalLibraryOutlinedIcon;
		default:
			return undefined;
	}
};

//export default [
//    {
//        title: 'Pages',
//        pages: [
//            {
//                title: 'Dashboard',
//                href: '/dashboard',
//                route: '/dashboard',
//                isNewPage: true,
//                componentName: 'Dashboard',
//                advantageLink: "insert_advantage_link_if_old_page",
//                icon: HomeIcon
//            }, {
//                title: 'Admissions',
//                href: '/admissions',
//                route: '/admissions',
//                isNewPage: true,
//                componentName: 'Admissions',
//                advantageLink: "insert_advantage_link_if_old_page",
//                icon: PeopleIcon
//            }, {
//                title: 'Student',
//                href: '/student',
//                icon: PersonIcon,
//                children: [
//                    {
//                        title: 'Summary',
//                        href: '/student/1/summary',
//                        route: '/student/:id/:tab',
//                        isNewPage: true,
//                        componentName: 'StudentTabs',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }, {
//                        title: 'Profile',
//                        href: '/student/1/profile',
//                        route: '/student/:id/:tab',
//                        isNewPage: true,
//                        componentName: 'StudentTabs',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }, {
//                        title: 'Grades',
//                        href: '/student/1/Grades',
//                        route: '/student/:id/:tab',
//                        isNewPage: true,
//                        componentName: 'StudentTabs',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }, {
//                        title: 'Financials',
//                        href: '/student/1/financials',
//                        route: '/student/:id/:tab',
//                        isNewPage: true,
//                        componentName: 'StudentTabs',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }, {
//                        title: 'Placement',
//                        href: '/student/1/placement',
//                        route: '/student/:id/:tab',
//                        isNewPage: true,
//                        isStudentCentric: true,
//                        componentName: 'StudentTabs',
//                        advantageLink: "/PL/PriorWorkPlacement.aspx",
//                        resId: 92
//                    }, {
//                        title: 'Notes',
//                        href: '/student/1/notes',
//                        route: '/student/:id/:tab',
//                        isNewPage: true,
//                        componentName: 'StudentTabs',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }

//                ]
//            }, {
//                title: 'Academics',
//                href: '/academics',
//                icon: SchoolIcon,
//                children: [
//                    {
//                        title: 'Post Grades by Group',
//                        href: '/academics/1/postGradesByGroup',
//                        route: '/academics/:id/:tab',
//                        isNewPage: true,
//                        componentName: 'AcademicsTabs',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }, {
//                        title: 'SAP',
//                        href: '/academics/1/SAP',
//                        route: '/academics/:id/:tab',
//                        isNewPage: true,
//                        componentName: 'AcademicsTabs',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }, {
//                        title: 'Progress Reports',
//                        href: '/academics/1/progressReports',
//                        route: '/academics/:id/:tab',
//                        isNewPage: true,
//                        componentName: 'AcademicsTabs',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }, {
//                        title: 'Transcripts',
//                        href: '/academics/1/transcripts',
//                        route: '/academics/:id/:tab',
//                        isNewPage: true,
//                        componentName: 'AcademicsTabs',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }
//                ]
//            }, {
//                title: 'Attendance',
//                href: '/attendance',
//                icon: WatchIcon,
//                children: [
//                    {
//                        title: 'Post Attendance',
//                        href: '/attendance/1/postAttendance',
//                        route: '/attendance/:id/:tab',
//                        isNewPage: true,
//                        componentName: 'AttendanceTabs',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }, {
//                        title: 'Edit Punches',
//                        href: '/attendance/1/editPunches',
//                        route: '/attendance/:id/:tab',
//                        isNewPage: true,
//                        componentName: 'AttendanceTabs',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }, {
//                        title: 'Adjust Scheduled Hours',
//                        href: '/attendance/1/adjustScheduledHours',
//                        route: '/attendance/:id/:tab',
//                        isNewPage: true,
//                        componentName: 'AttendanceTabs',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }, {
//                        title: 'Setup Schedules',
//                        href: '/attendance/1/setupSchedules',
//                        route: '/attendance/:id/:tab',
//                        isNewPage: true,
//                        componentName: 'AttendanceTabs',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }, {
//                        title: 'Reports',
//                        href: '/attendance/reports',
//                        route: '/attendance/reports',
//                        isNewPage: true,
//                        componentName: 'AttendanceReports',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }
//                ]
//            }, {
//                title: 'Student Accounts',
//                href: '/studentAccounts',
//                icon: MonetizationOnOutlinedIcon,
//                children: [
//                    {
//                        title: 'Ledger',
//                        href: '/studentAccounts/ledger',
//                        route: '/studentAccounts/:tab',
//                        isNewPage: true,
//                        componentName: 'StudentAccountsTabs',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }, {
//                        title: 'Accruals/AR Export',
//                        href: '/studentAccounts/accrualsExport',
//                        route: '/studentAccounts/:tab',
//                        isNewPage: true,
//                        componentName: 'StudentAccountsTabs',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }, {
//                        title: 'Fund Sources',
//                        href: '/studentAccounts/fundSources',
//                        route: '/studentAccounts/:tab',
//                        isNewPage: true,
//                        componentName: 'StudentAccountsTabs',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }, {
//                        title: 'Transaction Codes',
//                        href: '/studentAccounts/transactionCodes',
//                        route: '/studentAccounts/:tab',
//                        isNewPage: true,
//                        componentName: 'StudentAccountsTabs',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }, {
//                        title: 'Reports',
//                        href: '/studentAccounts/reports',
//                        route: '/studentAccounts/:tab',
//                        isNewPage: true,
//                        componentName: 'StudentAccountsTabs',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }
//                ]
//            }, {
//                title: 'Financial Aid',
//                href: '/financialAid',
//                icon: AccountBalanceOutlinedIcon,
//                children: [
//                    {
//                        title: 'Awards',
//                        href: '/financialAid/awards',
//                        route: '/financialAid/:tab',
//                        isNewPage: true,
//                        componentName: 'FinancialAidTabs',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }, {
//                        title: '1098T',
//                        href: '/financialAid/1098t',
//                        route: '/financialAid/:tab',
//                        isNewPage: true,
//                        componentName: 'FinancialAidTabs',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }, {
//                        title: '90/10 Export',
//                        href: '/financialAid/9010Export',
//                        route: '/financialAid/:tab',
//                        isNewPage: true,
//                        componentName: 'FinancialAidTabs',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }, {
//                        title: 'Reports',
//                        href: '/studentAccounts/transactionCodes',
//                        route: '/financialAid/:tab',
//                        isNewPage: true,
//                        componentName: 'FinancialAidTabs',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }
//                ]
//            }, {
//                title: 'Tools',
//                href: '/tools',
//                icon: BuildOutlinedIcon,
//                children: [
//                    {
//                        title: 'Grad Date Calculator',
//                        href: '/gradDateCalculator',
//                        route: '/gradDateCalculator',
//                        isNewPage: true,
//                        componentName: 'GradeDateCalculator',
//                        advantageLink: "insert_advantage_link_if_old_page_if_needed"
//                    }
//                ]
//            }, {
//                title: 'Reports',
//                href: '/reports',
//                icon: PrintOutlinedIcon,
//                children: [
//                    {
//                        title: 'Admissions',
//                        href: '/reports/admissions'
//                    }, {
//                        title: 'Academics',
//                        href: '/reports/Academics'
//                    }, {
//                        title: 'IPEDS Reports',
//                        href: '/reports/ipeds'
//                    }, {
//                        title: 'NACCAS Reports',
//                        href: '/reports/naccas'
//                    }, {
//                        title: 'Student Accounts',
//                        href: '/reports/studentAccounts'
//                    }, {
//                        title: 'Financial Aid',
//                        href: '/reports/financialAid'
//                    }, {
//                        title: 'Faculty',
//                        href: '/reports/faculty'
//                    }, {
//                        title: 'Placement',
//                        href: '/reports/placement'
//                    }, {
//                        title: 'System',
//                        href: '/reports/system'
//                    }
//                ]
//            }, {
//                title: 'Setup',
//                href: '/setup',
//                icon: SettingsOutlinedIcon,
//                children: [
//                    {
//                        title: 'Dashboard',
//                        href: '/setup/dashboard'
//                    }, {
//                        title: 'Admissions',
//                        href: '/setup/admissions'
//                    }, {
//                        title: 'Student',
//                        href: '/setup/student'
//                    }, {
//                        title: 'Academics',
//                        href: '/setup/academics'
//                    }, {
//                        title: 'Student Accounts',
//                        href: '/setup/studentAccounts'
//                    }, {
//                        title: 'Financial Aid',
//                        href: '/setup/financialAid'
//                    }, {
//                        title: 'Tools',
//                        href: '/setup/tools'
//                    }
//                ]

//            }, {
//                title: 'Integrations',
//                href: '/integrations',
//                icon: SyncAltOutlinedIcon,
//                children: [
//                    {
//                        title: 'Time Clock',
//                        href: '/integrations/timeClock'
//                    }, {
//                        title: 'LMS',
//                        href: '/integrations/lms'
//                    }, {
//                        title: 'Klass App',
//                        href: '/integrations/klassApp'
//                    }
//                ]
//            }
//        ]
//    }
//];
