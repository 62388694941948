import palette from "../mainPalette";

export default {
	root: {
		color: palette.icon.primary,
		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.03)"
		}
	}
};
