import * as React from "react";
import { useEffect, useRef } from "react";
import { apiAutoCompleteConfig } from "../../constants/apiAutoComplete.config";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../constants/actions.constants";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { Address } from "../../interfaces/student/profile/contactInfo/Address";
import BestAddress from "../../pages/student/profile/contactInfo/BestAddress";
import { TransactionType } from "../../enums/TransactionType";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& label.Mui-focused": {
				color: theme.palette.primary.white,
			},
		},
	})
)(TextField);

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {},
	})
);

const config: ApiAutoCompleteConfigItem = {
	acId: "transactionType",
	cacheTime: 5,
	route: "/FinancialAid/TransactionCode/GetTransactionCodesByCampusId",
	requestType: "GET",
	body: null,
	hasUrlParams: true,
	useCache: false,
	storeInCache: false,
	isCampusSpecific: true,
	isUserSpecific: false,
	loadOnRender: true,
	defaultSelectedFirstItem: false,
	optionsMappedFx: (data: Array<any>) => {
		return data;
	},
};

export interface TransactionTypeProps extends FilterParams {
	hideLabel?: boolean;
	className?: any;
	disabled?: boolean;
	disableClearable?: boolean;
	readOnly?: boolean;
	optionsFilter?: any;
	placeHolder?: string;
	multiple?:boolean;
}

const TransactionCodeAutoComplete = (props: TransactionTypeProps) => {
	const classes = useStyles({});
	const inputRef: any = useRef(null);
	const filterHandle = props.filterHandle;
	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);
	const handleOnChange = (e: any) => {
		if (filterHandle) {
			filterHandle(e);
		}
	};

	return (
		<div className={clsx(classes.divWidth)}>
			<ApiAutoComplete
				id={props.id}
				config={config}
				disableClearable={props.disableClearable}
				disabled={props.disabled}
				loading={userSelectedCampus ? false : true}
				reqParams={{ campusId: (props.campusId ? props.campusId : userSelectedCampus) 
					, withCode: props.params?.withCode ? props.params?.withCode : null
					, sysTransCodesDesc: props.params?.sysTransCodesDesc ? props.params?.sysTransCodesDesc : null
					, creditDebitId: props.params?.creditDebitId ? props.params?.creditDebitId : null
				}}
				className={props.className}
				readOnly={props.readOnly}
				classes={{
					option: "text-black",
				}}
				label={
					props.hideLabel
						? undefined
						: props.label
						? props.label
						: "Transaction Code"
				}
				onChange={(e: any) => {
					handleOnChange(e);
				}}
				multiple={props.multiple ?? false}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
				inputRef={props.inputRef ? props.inputRef : inputRef}
				optionsFilter={props.optionsFilter}
				error={props.error}
				helperText={props.helperText}
				inputName={props.name}
				placeholder={props.placeHolder ? props.placeHolder : ""}
			></ApiAutoComplete>
		</div>
	);
};

export default React.memo(
	TransactionCodeAutoComplete,
	(prev: TransactionTypeProps, next: TransactionTypeProps) => {
		return false;
	}
);
