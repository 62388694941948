import { Order } from "../interfaces/common/Table/CustomHeadCell";

export function getSorting<K extends keyof any>(
	order: Order,
	orderBy: K
): (
	a: { [key in K]: number | string },
	b: { [key in K]: number | string }
) => number {
	return order === "desc"
		? (a, b) => desc(a, b, orderBy)
		: (a, b) => -desc(a, b, orderBy);
}

export function desc<T>(a: T, b: T, orderBy: keyof T) {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    if (aValue == null && bValue == null) return 0;
    if (aValue == null) return 1;
    if (bValue == null) return -1;

    const aNum = typeof aValue === "string" && !isNaN(Number(aValue)) ? Number(aValue) : aValue;
    const bNum = typeof bValue === "string" && !isNaN(Number(bValue)) ? Number(bValue) : bValue;

    if (bNum < aNum) return -1;
    if (bNum > aNum) return 1;

    return 0;
}

export function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}
