import {
  IconButton,
  Backdrop,
  makeStyles,
  Theme,
  createStyles,
  Popover,
  Button,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import React from "react";

type CustomTableOptionProps = {
  onDelete?: any;
  onEdit?: any;
  disableDeleteButton?:true;
  disableEditButton?:true;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      // color: "#fff"
    },
    wrapper: {
      position: "relative",
    },
    popoverButton: {
      width: "100%",
      textTransform: "none",
      justifyContent: "left",
    },
  })
);

const CustomTableOptions = (props: CustomTableOptionProps) => {
  const classes = useStyles({});
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        onClick={(e) => {
          handleClick(e);
        }}
      >
        <MoreVert />
      </IconButton>
      <Backdrop
        open={open}
        onClick={(e: any) => {
          if ((e.target.className as string).indexOf("MuiBackdrop-root") >= 0) {
            handleClose();
          }
        }}
        className={classes.backdrop}
      >
        <Popover open={open} onClose={handleClose} anchorEl={anchorEl}>
          {props.onEdit && (
            <div className={classes.wrapper}>
              <Button
                variant="text"
                className={classes.popoverButton}
                onClick={() => {
                  props.onEdit();
                  handleClose();
                }}
                disabled={props.disableEditButton !== undefined && props.disableEditButton === true ? true : false}
              >
                Edit
              </Button>
            </div>
          )}
          {props.onDelete && (
            <div className={classes.wrapper}>
              <Button
                variant="text"
                className={classes.popoverButton}
                onClick={() => {
                  props.onDelete();
                  handleClose();
                }}
                disabled={props.disableDeleteButton !== undefined && props.disableDeleteButton === true ? true : false}
              >
                Delete
              </Button>
            </div>
          )}
        </Popover>
      </Backdrop>
    </div>
  );
};

export default CustomTableOptions;