import * as actionTypes from "../../constants/actions.constants";
import { Action } from "redux";


const initialState = {url:'',open:false,title:''};

const pdfPreviewReducer = (state = initialState, action: Action<any>) => {

  switch (action.type) {
    case actionTypes.pdfPreviewConstants.SHOW_PREVIEW: {
        let actionData = (action as any).data;
      return {
        ...state, open: true, url: actionData.url, title: actionData.title
      };
    
    }

    case actionTypes.pdfPreviewConstants.HIDE_PREVIEW: {
      return {
        ...state, open: false, url: '', title:''
      };
    
    }

    default: {
      return state;
    }
}
};

export default pdfPreviewReducer;
