import API from "../../apiWrapper";
import { PolicyRule } from "../../../interfaces/student/refunds/PolicyRule";
import { RefundPolicy } from "../../../interfaces/student/refunds/RefundPolicy";

export const GetPolicies = (campusId: string) => {
	return API()
		.get("/StudentAccounts/InstitutionalRefund/GetPolicies", {
			params: {
				campusId,
			},
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return null;
			}
		);
};

export const GetPolicyRules = (policyId: string) => {
	return API()
		.get("/StudentAccounts/InstitutionalRefund/GetPolicyRules", {
			params: {
				policyId,
			},
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return null;
			}
		);
};

export const UpsertRefundPolicy = (award: RefundPolicy) => {
	return API()
		.post("/StudentAccounts/InstitutionalRefund/UpsertRefundPolicy", award)
		.then(
			(res: any) => {
				if (res && res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};

export const UpsertRefundPolicyRule = (rule: PolicyRule) => {
	return API()
		.post("/StudentAccounts/InstitutionalRefund/UpsertRefundPolicyRule", rule)
		.then(
			(res: any) => {
				if (res && res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};

export const DeleteRefundPolicy = (id: string) => {
	return API()
		.post(
			"/StudentAccounts/InstitutionalRefund/DeleteRefundPolicy", id)
		.then(
			(res: any) => {
				if (res && res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};

export const DeleteRefundPolicyRule = (id: string) => {
	return API()
		.post(
			"/StudentAccounts/InstitutionalRefund/DeleteRefundPolicyRule",id)
		.then(
			(res: any) => {
				if (res && res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};

export const MapProgramVersionToPolicy = (policyId:string, programVersionIds:string[]) => {
	return API()
		.post(`/StudentAccounts/InstitutionalRefund/MapProgramVersionIdsToPolicy?policyId=${policyId}`, programVersionIds)
		.then(
			(res: any) => {
				if (res && res.data) {
					return res.data;
				}else{
					return [];
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};

export const GetProgramVersionIdsForPolicy = (policyId:string) => {
	return API()
		.get(`/StudentAccounts/InstitutionalRefund/ProgramVersionIdsForPolicy?policyId=${policyId}`)
		.then(
			(res: any) => {
				if (res && res.data) {
					return res.data;
				}else{
					return [];
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};
