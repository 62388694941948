import IStateWiseLicense from '../../../interfaces/setup/campus/IStateWiseLicense';
import API from '../../apiWrapper';

export const getCampusStateWiseLicense = (params: { campusId: string }) => {
	return API().get('/SystemCatalog/StateWiseLicense/GetLicensesByCampus', {
		params
	}).then((res) => res.data);
};

export const addCampusStateWiseLicense = (params: IStateWiseLicense) => {
	return API().post('/SystemCatalog/StateWiseLicense/CreateLicense', params).then((res) => res.data);
};

export const deleteLicense = (params: { licenseId: string }) => {
	return API().delete('/SystemCatalog/StateWiseLicense/DeleteLicense', {
		params
	}).then((res) => res.data);
};