import { getStore } from '../index'


export const checkPermissions = (allowedPermissions: Array<string>) => {

    const store = getStore().store;
    let isSupportUser = false;
    let userPermissions: Array<string> = [];
    if (store) {
        const user = (store.getState() as any).session.user;
        isSupportUser = user.isSupportUser;
        userPermissions = user.permissions;
    }
    if (allowedPermissions.length === 0 || isSupportUser)
        return true;
    if (userPermissions && userPermissions.length > 0)
        return userPermissions.some(r => allowedPermissions.includes(r))

    return false;
}
