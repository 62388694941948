import React from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { RenderGroupParams } from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import amber from "@material-ui/core/colors/amber";
import {
	autoCompleteActions as actions,
	loadAutoCompleteDataOptions,
} from "../../../actions/autoCompleteActions";
import { useDispatch } from "react-redux";
import { autoCompleteConstants } from "../../../constants/actions.constants";
import { ApiAutoCompleteConfigItem } from "../../../interfaces/ApiAutoCompleteConfigItem";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { UserProfile } from "../../../interfaces/user/UserProfile";
import Chip from "@material-ui/core/Chip";
import { insert } from "../../../utils/arrayFunctions";
import WarningIcon from "@material-ui/icons/Warning";
import { Tooltip } from "@material-ui/core";
import { EmptyGuid } from "../../../utils/constants";
import zhCN from "date-fns/esm/locale/zh-CN/index.js";

type IApiAutoCompleteProps = {
	id?: string;
	config: ApiAutoCompleteConfigItem;
	overrides?: ApiAutoCompleteConfigItem;
	className?: any;
	label?: string;
	placeholder?: string;
	onChange?: Function;
	onClose?: Function;
	valueFilter?: any;
	multiple?: any;
	optionName?: (option: any) => any;
	renderInput?: any;
	disableClearable?: boolean;
	disableCloseOnSelect?: boolean;
	includeAllValue?: boolean;
	showIncludeAllValue?: boolean;
	defaultOptionText?: string;
	variant?: any;
	classes?: any;
	options?: any;
	reqParams?: any;
	loading?: any;
	optionsFilter?: (option: any) => boolean | any;
	filterOptions?: any;
	disabled?: boolean;
	error?: boolean;
	helperText?: string;
	requiredParameterMissingMessage?: string;
	inputRef?: any;
	inputName?: string;
	groupBy?: (option: any) => string;
	renderGroup?: (params: RenderGroupParams) => React.ReactNode;
	freeSolo?: boolean;
	renderTags?: (value: any, getTagProps: any) => any;
	renderOption?: (option: any, params: { selected: boolean }) => any;
	onKeyUp?: Function;
	getOptionSelected?: any;
	readOnly?: boolean;
	maxTags?: number;
	chipSize?: 'medium' | 'small'
};

const useStyles = makeStyles((theme: any) =>
	createStyles({
		iconColor: {
			color: amber[700],
		},
	})
);

const ApiAutoComplete = (props: IApiAutoCompleteProps) => {
	const config: ApiAutoCompleteConfigItem = {
		...props.config,
		...props.overrides,
	};
	const classes = useStyles({});
	const dispatch = useDispatch();
	const [open, setOpen] = React.useState(false);
	const [initialLoad, setInitialLoad] = React.useState(true);
	const [value, setValue] = React.useState<any>({});
	const [values, setValues] = React.useState<any>([]);
	const [options, setOptions] = React.useState<any>(
		props.reqParams ? null : []
	);
	const [error, setError] = React.useState<any>();

	const [isControlDisabled, setIsControlDisabled] = React.useState<
		boolean | undefined
	>(props.disabled);
	const [isParameterRequired, setParameterRequired] = React.useState<boolean>(
		false
	);
	const loading: any =
		!options &&
		(open || config.loadOnRender) &&
		props.reqParams !== undefined &&
		props.reqParams != null;

	const loggedInUser = useSelector(
		(state: any) => state.session.user
	) as UserProfile;

	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user?.userId)
	);

	let uniqueKey = config.isCampusSpecific
		? userSelectedCampus + "_" + config.acId
		: config.acId;

	uniqueKey = config.isUserSpecific
		? loggedInUser?.userId + "_" + config.acId
		: uniqueKey;

	let optionLabel = (option: any) => {
		return props.optionName
			? props.optionName(option)
			: option.text
				? option.text
				: "";
	};
	const autoCompleteData = useSelector(
		(state: any) => state.ac_cache.data[uniqueKey]
	);
	const cacheTime = config.cacheTime ? config.cacheTime : 30;

	React.useEffect(() => {
		let selectedValueBasedOnFilter: any;
		if (
			props.valueFilter &&
			props.valueFilter.values.filter((i: any) => i === 'All').length > 0 &&
			props.includeAllValue &&
			props.showIncludeAllValue &&
			options &&
			options.length > 0
		) {
			selectedValueBasedOnFilter = options.filter((i: any) => i.value !== 'All');
			if (selectedValueBasedOnFilter.length > 0) {
				props.multiple
					? setValues(selectedValueBasedOnFilter)
					: setValue(selectedValueBasedOnFilter[0]);

				if (props.onChange) {
					props.multiple
						? props.onChange(selectedValueBasedOnFilter)
						: props.onChange(selectedValueBasedOnFilter[0]);
				}
				setOpen(false);
				return;
			}
		}

		if (
			props.valueFilter &&
			props.valueFilter.values.length > 0 &&
			options &&
			options.length > 0
		) {
			selectedValueBasedOnFilter = options.filter((e: any) => {
				return (
					props.valueFilter.values.find((x: any) => {
						return x === e[props.valueFilter.key];
					}) !== undefined
				);
			});

			if (selectedValueBasedOnFilter.length > 0) {
				props.multiple
					? setValues(selectedValueBasedOnFilter)
					: setValue(selectedValueBasedOnFilter[0]);

				if (initialLoad) {
					if (props.onChange) {
						props.multiple
							? props.onChange(selectedValueBasedOnFilter)
							: props.onChange(selectedValueBasedOnFilter[0]);
					}
					setInitialLoad(false);
				}
				return;
			}
		}

		if (
			options &&
			options.length > 0 &&
			config.defaultSelectedFirstItem &&
			!props.valueFilter
		) {
			props.multiple ? setValues([options[0]]) : setValue(options[0]);
			if (initialLoad) {
				if (props.onChange) {
					props.multiple
						? props.onChange([options[0]])
						: props.onChange(options[0]);
				}
				setInitialLoad(false);
			}
		}

		if (!selectedValueBasedOnFilter && !config.defaultSelectedFirstItem) {
			props.multiple ? setValues([]) : setValue(null);
		}
	}, [options, props.valueFilter]);

	React.useEffect(() => {
		if (
			props.reqParams !== undefined &&
			props.requiredParameterMissingMessage !== undefined
		) {
			let totalProps: number = 0;
			let totalNull: number = 0;
			for (var key in props.reqParams) {
				totalProps += 1;
				if (
					props.reqParams[key] === undefined ||
					props.reqParams[key] === null ||
					props.reqParams[key] === ""
				) {
					totalNull += 1;
				}
			}
			if (totalNull === totalProps) {
				setIsControlDisabled(true);
				setParameterRequired(true);
			} else {
				setParameterRequired(false);
			}
		}
	}, [props.reqParams, props.requiredParameterMissingMessage]);

	React.useEffect(() => {
		//options were passed in manually via props
		if (props.options) {
			setOptions(props.options);
			return;
		}

		if (config.isCampusSpecific && !userSelectedCampus) {
			return;
		}

		if (props.config.hasUrlParams && !props.reqParams) {
			return;
		}

		(async () => {
			if (config.useCache && autoCompleteData) {
				const diff = Math.abs(
					new Date(autoCompleteData.lastUpdated).getTime() -
					new Date().getTime()
				);
				const minutes = Math.floor(diff / 1000 / 60);
				const isFreshData = minutes <= cacheTime;

				if (isFreshData) {
					setOptions(autoCompleteData.options);
					let opts: any = config.optionsMappedFx(autoCompleteData.options);
					const defaultSelected = opts.filter((m: any) => m.defaultSelected);
					if (initialLoad && defaultSelected && defaultSelected.length > 0) {
						if (props.multiple) {
							if (props.onChange) {
								setValues(defaultSelected);
								props.onChange(defaultSelected);
							}
						} else {
							if (props.onChange) {
								setValues(defaultSelected && defaultSelected[0]);
								props.onChange(defaultSelected && defaultSelected[0]);
							}
						}
						setInitialLoad(false);
					}
					return;
				}
			}

			const optionsParams: loadAutoCompleteDataOptions = {
				route: config.route,
				method: config.requestType,
				params: { ...props.reqParams, campusId: (props.reqParams?.campusId ? props.reqParams?.campusId : userSelectedCampus) },
			};

			actions.loadAutoCompleteData(optionsParams).then(
				(data: any) => {
					if (data.statusCode === 400) {
						setOptions([]);
						return;
					}
					let opts: any = config.optionsMappedFx(data);
					if (props.includeAllValue) {
						opts = insert(opts, 0, { text: props.defaultOptionText ? props.defaultOptionText : "All", value: "All" });
					}
					setOptions(opts);

					const defaultSelected = opts.filter((m: any) => m.defaultSelected);
					if (config.storeInCache) {
						const dta = { key: uniqueKey, data: opts };
						dispatch({
							type: autoCompleteConstants.SESSION_LOADING_AC_UPDATE_CACHE,
							data: dta,
						});

					}
					if (initialLoad && defaultSelected && defaultSelected.length > 0) {
						if (props.multiple) {
							if (props.onChange) {
								setValues(defaultSelected);
								props.onChange(defaultSelected);
							}
						} else {
							if (props.onChange) {
								setValues(defaultSelected && defaultSelected[0]);
								props.onChange(defaultSelected && defaultSelected[0]);
							}
						}
						setInitialLoad(false);
					}

				},
				(e: any) => {
					setError(e);
				}
			);
		})();
	}, [loading, userSelectedCampus, props.reqParams]);

	React.useEffect(() => {
		setIsControlDisabled(props.disabled);
	}, [props.disabled]);

	return (
		<Autocomplete
			id={props.id ? props.id : props.config.acId}
			classes={props.classes}
			filterOptions={props.filterOptions}
			freeSolo={props.freeSolo}
			disableClearable={props.disableClearable}
			disableCloseOnSelect={props.multiple || props.disableCloseOnSelect}
			open={open && !(props.readOnly ?? false)}
			groupBy={props.groupBy}
			renderGroup={props.renderGroup}
			disabled={isControlDisabled ? true : false}
			renderOption={props.renderOption}
			limitTags={props.maxTags}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
				if (props.onClose) props.onClose();
			}}
			onKeyUp={(e: any) => {
				if (props.onKeyUp) props.onKeyUp(e);
			}}
			onChange={(e: any, v: any) => {
				if (
					props.freeSolo &&
					options &&
					!options.some((m: any) => m.value === EmptyGuid)
				) {
					options.push({ value: EmptyGuid, text: "" });
				}
				if (
					props.freeSolo &&
					options[options.length - 1] &&
					v &&
					(v?.text ?? ("" as string)).includes('Add "') &&
					v.value === EmptyGuid
				) {
					const updatedText = (v?.text ?? ("" as string))
						.replace('Add "', "")
						.slice(0, -1);
					options[options.length - 1].text = updatedText;
					options[options.length - 1].value = EmptyGuid;
					v.text = updatedText;
					v.value = EmptyGuid;
				}
				if (props.freeSolo && (typeof v === "string" || v instanceof String)) {
					options[options.length - 1].text = v;
					options[options.length - 1].value = EmptyGuid;
					v = { text: options[options.length - 1].text, value: EmptyGuid };
				}
				if (props.onChange) {
					props.onChange(v);
				}
				props.multiple ? setValues(v) : setValue(v);
			}}
			className={clsx(props.className)}
			getOptionLabel={optionLabel}
			getOptionSelected={props.getOptionSelected}
			options={
				props.optionsFilter && options
					? options.filter(props.optionsFilter)
					: options
						? options
						: []
			}
			loading={loading}
			multiple={props.multiple ? props.multiple : false}
			value={props.multiple === false ? value : values}
			renderTags={
				props.renderTags
					? props.renderTags
					: (value: any, getTagProps) => {
						return value.map((option: any, index: number) => (
							<Chip
								size={props.chipSize ?? undefined}
								disabled={index === 0}
								label={option.text}
								{...getTagProps({ index })}
							/>
						));
					}
			}
			renderInput={(params: any) => {
				if (!props.renderInput) {
					return (
						<TextField
							{...params}
							label={props.label}
							placeholder={props.placeholder}
							name={props.inputName ? props.inputName : props.config.acId}
							fullWidth
							inputRef={props.inputRef}
							error={props.error}
							helperText={
								props.error && props.helperText ? props.helperText : ""
							}
							onBlur={
								props.freeSolo
									? (e: React.FocusEvent<HTMLInputElement>) => {
										if (!!e.target.value && value?.text !== e.target.value) {
											if (
												!options[options.length - 1] ||
												options[options.length - 1].value !== EmptyGuid
											) {
												options.push({
													text: e.target.value,
													value: EmptyGuid,
												});
											} else {
												options[options.length - 1].text = e.target.value;
											}
											if (props.onChange) {
												props.onChange(options[options.length - 1]);
											}
											props.multiple
												? setValues([options[options.length - 1]])
												: setValue(options[options.length - 1]);
										}
									}
									: params.onBlur
							}
							InputProps={{
								...params.InputProps,
								readOnly: props.readOnly ?? false,
								endAdornment: !isParameterRequired ? (
									<React.Fragment>
										{loading && !error ? (
											<CircularProgress color="inherit" size={20} />
										) : null}
										{error && (
											<Tooltip title={error.message}>
												<WarningIcon className={classes.iconColor} />
											</Tooltip>
										)}
										{params.InputProps.endAdornment}
									</React.Fragment>
								) : (
									<React.Fragment>
										<Tooltip
											title={
												props.requiredParameterMissingMessage === undefined
													? "Required field"
													: props.requiredParameterMissingMessage
											}
										>
											<WarningIcon className={classes.iconColor} />
										</Tooltip>
									</React.Fragment>
								),
							}}
						/>
					);
				}

				return props.renderInput && props.renderInput(params);
			}}
		/>
	);
};

ApiAutoComplete.defaultProps = {};
export default React.memo(
	ApiAutoComplete,
	(prev: IApiAutoCompleteProps, next: IApiAutoCompleteProps) => {
		let reuseComponent = false;

		// if (!!prev.valueFilter && !!next.valueFilter) {
		// 	if (next.valueFilter.values.length > 0 && prev.valueFilter.values.length > 0)
		// 	{
		// 		if ((!prev.multiple || !next.multiple) && prev.valueFilter.values[0] === next.valueFilter.values[0])
		// 		{
		// 			reuseComponent = true;
		// 		}
		// 	}
		// }

		// //request parameters
		// if (!!prev.reqParams && !!next.reqParams)
		// {
		// 	const keys1 = Object.keys(prev.reqParams);
  		// 	const keys2 = Object.keys(next.reqParams);
		// 	if (keys1.length !== keys2.length) {
		// 		reuseComponent = false;
		// 	}

		// 	for (let key of keys1) {
		// 		if (prev.reqParams[key] !== next.reqParams[key]) {
		// 			reuseComponent = false;
		// 		}
		// 	}
		// }
		
		// if (prev.loading !== next.loading) {
		// 	reuseComponent = false;
		// }

		
		// if (prev.error !== next.error) {
		// 	reuseComponent = false;
		// }

		// if (!prev.reqParams && !!next.reqParams) {
		// 	reuseComponent = false;
		// }
		
		// console.log(prev);
		// console.log(next);
		// console.log(reuseComponent);
		return reuseComponent;
	}
);;
