import * as React from "react";
import { StatesApiAutoComplete } from "../../constants/SystemCatalog/StatesApiAutoComplete";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { FilterParams } from "../../interfaces/common/FilterParams";


const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    divWidth: {
      width: "95%"
    }
  })
);

const StateBoardStatesAutoComplete = (props: FilterParams) => {
  const classes = useStyles({});

  const handleOnChange = (e: any) => {
    if (props.filterHandle) {
      props.filterHandle(e);
    }
  };

  return (
    <div className={clsx(classes.divWidth)}>
      <ApiAutoComplete
        config={StatesApiAutoComplete.stateboardStatesConfig}
        classes={{
          option: "text-black"
        }}
        label={props.hideLabel ? undefined : (props.label ?? 'State')}
        onChange={(e: any) => {
          handleOnChange(e);
        }}
        disabled={props.disabled}
        inputRef={props.inputRef}
        error={props.error}
        helperText={props.helperText}
        inputName={props.name}
        multiple={props.multiple || false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        maxTags={props.maxTags}
        includeAllValue={props.includeAllValue}
        showIncludeAllValue={props.showIncludeAllValue}
        chipSize={props.chipSize}
      ></ApiAutoComplete>
    </div>
  );
};
export default StateBoardStatesAutoComplete;
