import * as React from "react";
import { useRef } from "react";
import autoCompleteConfig from "../../constants/SystemCatalog/ResourceAutoCompleteConfig";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { FilterParams } from "../../interfaces/common/FilterParams";


const ResourceAutoComplete = (props: FilterParams) => {
	const inputRef: any = useRef(null);

	const onChange = (e: any) => {
		if (props.filterHandle) {
			props.filterHandle(e);
		}
	};

	return (
		<div>
			<ApiAutoComplete
				config={autoCompleteConfig}
				classes={{
					option: "text-black"
				}}
				label={props.label ? props.label : "Resource"}
				onChange={(e: any) => {
					onChange(e);
				}}
				multiple={false}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
				inputRef={props.inputRef ? props.inputRef : inputRef}
				error={props.error}
				helperText={props.helperText}
				inputName={props.name}
                reqParams={props.params}
			></ApiAutoComplete>
		</div>
	);
};
export default ResourceAutoComplete;
