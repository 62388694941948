import * as React from "react";
import {
    Card,
    CardContent,
    Grid,
    CardActions,
    Typography,
    Button,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
} from '@material-ui/core';
import clsx from 'clsx';
import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import EnrollmentStatusAutoComplete from "../../../../components/AutoComplete/EnrollmentStatusAutoComplete";
import ProgramVersionAutoComplete from "../../../../components/AutoComplete/ProgramVersionAutoComplete";
import StudentGroupsAutoComplete from "../../../../components/AutoComplete/StudentGroupsAutoComplete";
import CourseAutoComplete from "../../../../components/AutoComplete/CourseAutoComplete";
import GradingComponentsAutoComplete from "../../../../components/AutoComplete/GradingComponentsAutoComplete";
import { ISearchFilters } from "../../../../interfaces/student/academics/PostGradesByClassFilter";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from "@material-ui/pickers";
import { useForm } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import TermCalenderAutoComplete from "../../../../components/AutoComplete/TermCalenderAutoComplete";
import ClassAutoComplete from "../../../../components/AutoComplete/ClassAutoComplete";
import CreditProgramVersionAutoComplete from "../../../../components/AutoComplete/CreditProgramVersionAutoComplete";
import GradeBookComponentsAutoComplete from "../../../../components/AutoComplete/GradeBookComponentsAutoComplete";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
            minHeight: "100%",
            paddingTop: theme.spacing(3),
            overflowX: "hidden",
            overflowY: "auto"
        }
        , cardTitle: {
            fontSize: 17,
            textDecoration: 'bold',
            backgroundColor: theme.palette.secondary.dark,
            padding: theme.spacing(1, 1),
        },
        filterMenu: {
            textAlign: 'right',
        },
        saveButton: {
            width: 90,
        },
        width100: {
            width: "100%",
        },
    })
);

const ITEM_HEIGHT = 90;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

type filterProps = {
    handleSearch: (filters:ISearchFilters) => void;
    isLoading:boolean;
};

const PostGradesByClassSearchFilter = (props: filterProps) => {
    const classes = useStyles({});
    const studentId = useSelector<any, any>(
        (state: any) => state.student.studentId
    );

    const data = useForm<any>({ mode: "onBlur" });

    const campusId = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );

    const initialState: ISearchFilters = {
        programVersionIds: [],
        instrCreditGrdBkWgtDetailId: 'FinalGrade',
        statusIds: [],
        campusId: campusId,
        classId: null,
        termId: null,
    };

    const [model, setModel] = React.useState<ISearchFilters>({ ...initialState });

    const handleFieldOnChange = (
        fieldId: string,
        value: any,
        mapFunction?: Function
    ) => {
        let updatedModel = model;
        let newValue = mapFunction ? mapFunction(value) : value;
        (updatedModel as any)[fieldId] = newValue;

        if(fieldId == 'classId'){
            (updatedModel as any)["termId"] = null;
        }
        setModel({ ...updatedModel });
    };

    const handleSearch = async () => {
        const validation = await data.triggerValidation();
        if (validation) {
            props.handleSearch(model);
        }
    };

    const setEnrollmentStatus = (enrollmentStatus: any) => {
        let newStatuses: string[] = [];
        if (enrollmentStatus !== null) {
            if (Array.isArray(enrollmentStatus)) {
                newStatuses = enrollmentStatus.map((gr: any) => gr.value);
            } else {
                newStatuses.push(enrollmentStatus.value);
            }
        }
        return newStatuses;
    };
    const setProgramVersion = (PrgVersions: any) => {
        let newProgramVersion: string[] = [];
        if (PrgVersions !== null) {
            if (Array.isArray(PrgVersions)) {
                newProgramVersion = PrgVersions.map((gr: any) => gr.value);
            } else {
                newProgramVersion.push(PrgVersions.value);
            }
        }
        return newProgramVersion;
    };

    const names: string[] = [
        'Program Version',
        'Status',
    ];
    const defaultFilter: string[] = [];

    const [filters, setFilters] = React.useState<string[]>(defaultFilter);

    const handleReset = () => {
        setFilters(defaultFilter);
        setModel({ ...initialState });
    }

    const handleChange = (event: any) => {
        const {
            target: { value },
        } = event;
        if (value[value.length - 1] != "reset") {
            setFilters(
                typeof value === 'string' ? value.split(',') : value,
            );
        }

    };

    return (
        <React.Fragment>
            <Card square={true}>
                <Typography className={clsx(classes.cardTitle)}>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={6}>
                            <div>Post Grades by Class - Search</div>
                        </Grid>
                        <Grid item xs={6} className={classes.filterMenu}>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                color="primary"
                                variant="standard"
                                multiple
                                value={filters}
                                onChange={handleChange}
                                displayEmpty
                                renderValue={(selected: any) => {
                                    if(!!selected && selected != '' && selected.length > 0){
                                        return selected.join(', ')
                                    }else{
                                        return 'Filters';
                                    }
                                    }
                                }
                                MenuProps={MenuProps}
                            >
                                {names.map((name: string) => (
                                    <MenuItem color="primary" key={name} value={name}>
                                        <Checkbox color="primary" checked={filters.indexOf(name) > -1} />
                                        <ListItemText color="primary" primary={name} />
                                    </MenuItem>
                                ))}
                                <MenuItem key="reset" value="reset">
                                    <Button onClick={handleReset} size="small" variant='outlined' fullWidth={true}>Reset</Button>
                                </MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </Typography>
                <CardContent>
                    <Grid container direction="row" spacing={2}>
                        <Grid item md={4} sm={4} xs={12}>
                            <TermCalenderAutoComplete
                                id="termId"
                                name="termId"
                                label="Term"
                                key="termId"
                                required={true}
                                daysFilter={8}
                                classId={model?.classId}
                                defaultSelectedFirstItem={model?.classId ? true : false}
                                valueFilter={
                                    model?.termId
                                        ? {
                                            key: "value",
                                            values: [model?.termId],
                                        }
                                        : undefined
                                }
                                filterHandle={(v: any) => {
                                    handleFieldOnChange("termId", v ? v.value : null);
                                }}

                                error={!!data.errors.termId && !model?.classId}
                                    inputRef={data.register({ required: true })}
                                    helperText={
                                      data.errors.termId && !model?.classId
                                        ? "Term required."
                                        : undefined
                                    }
                            />
                        </Grid>
                        <Grid item md={4} sm={4} xs={12}>
                            <ClassAutoComplete
                                id="classId"
                                name="classId"
                                label="Class"
                                key="classId"
                                daysFilter={8}
                                required={true}
                                valueFilter={
                                    model?.classId
                                        ? {
                                            key: "value",
                                            values: [model?.classId],
                                        }
                                        : undefined
                                }
                                filterHandle={(v: any) => {
                                    handleFieldOnChange(
                                        "classId",
                                        v ? v.value : null
                                    );
                                }}

                                error={!!data.errors.classId}
                                    inputRef={data.register({ required: true })}
                                    helperText={
                                      data.errors.classId
                                        ? "Class required."
                                        : undefined
                                    }
                            />
                        </Grid>
                        <Grid item md={4} sm={4} xs={12}>
                            <GradeBookComponentsAutoComplete
                                id="instrCreditGrdBkWgtDetailIdcredit"
                                name="instrCreditGrdBkWgtDetailId"
                                label="Grading Component"
                                key="instrCreditGrdBkWgtDetailIdcredit"
                                required={true}
                                classId={model.classId ?? ''}
                                valueFilter={
                                    model?.instrCreditGrdBkWgtDetailId
                                        ? {
                                            key: "value",
                                            values: [model?.instrCreditGrdBkWgtDetailId],
                                        }
                                        : undefined
                                }
                                filterHandle={(v: any) => {
                                    handleFieldOnChange(
                                        "instrCreditGrdBkWgtDetailId",
                                        v ? v.value : null
                                    );
                                }}

                                error={!!data.errors.instrCreditGrdBkWgtDetailId}
                                    inputRef={data.register({ required: true })}
                                    helperText={
                                      data.errors.instrCreditGrdBkWgtDetailId
                                        ? "Grading component required."
                                        : undefined
                                    }
                            />
                        </Grid>
                        {
                            filters.indexOf("Status") > -1 &&
                            <Grid item md={4} sm={4} xs={12}>
                                <EnrollmentStatusAutoComplete
                                    filterHandle={(statuses: any) => {
                                        handleFieldOnChange(
                                            "statusIds",
                                            statuses,
                                            setEnrollmentStatus
                                        );
                                    }}
                                    isInSchool={true}
                                    id="enrollmentStatus"
                                    name="enrollmentStatus"
                                    label="Enrollment Status"
                                    multiple={true}
                                    valueFilter={
                                        model?.statusIds
                                            ? {
                                                key: "value",
                                                values: model?.statusIds,
                                            }
                                            : undefined
                                    }
                                />
                            </Grid>
                        }
                        {
                            filters.indexOf("Program Version") > -1 &&
                            <Grid item md={4} sm={4} xs={12}>
                                <CreditProgramVersionAutoComplete
                                    id="programVersionIds"
                                    name="programVersionIds"
                                    label="Program"
                                    key="programVersionIds"
                                    campusId={campusId}
                                    termId={undefined}
                                    valueFilter={
                                        model?.programVersionIds
                                            ? {
                                                key: "value",
                                                values: [model?.programVersionIds],
                                            }
                                            : undefined
                                    }
                                    filterHandle={(v: any) => {
                                        handleFieldOnChange("programVersionIds", v ? v.value : setProgramVersion);
                                    }}
                                />
                            </Grid>
                        }
                    </Grid>
                </CardContent>
                <CardActions >
                    <Grid container direction="row" spacing={1}>
                        <Grid
                            item
                            alignContent="flex-start"
                            alignItems="flex-start"
                            justify="flex-start"
                        >
                            <ProgressSaveButton
                                text="Search"
                                onClick={handleSearch}
                                loading={props.isLoading}
                                buttonClassName={classes.saveButton}
                            ></ProgressSaveButton>
                        </Grid>
                        <Grid
                            item
                            alignContent="flex-start"
                            alignItems="flex-start"
                            justify="flex-start"
                        >

                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </React.Fragment>
    );
};

export default PostGradesByClassSearchFilter;
