import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    CardContent,
    Card,
    IconButton,
    Grid,
    Link,
    createStyles,
    makeStyles,
    CircularProgress,
} from '@material-ui/core';

import { MoreVert, Refresh, Print } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { terminationWidgetApi } from '../../../src/api/student/academics/terminationWidgetApi';
import AccessManager from '../../components/security/AccessManager';
import { security } from '../../constants/Security/securityConstants';
import { IPendingTermination } from '../../interfaces/academicRecords/IPendingTermination';
import { IPendingTerminationDetails } from '../../interfaces/academicRecords/IPendingTerminationDetails';
import { AxiosResponse } from 'axios';
import Moment from 'moment';
import TerminationSettingsPopup from './terminationSettings';
import * as widgetStyles from './WidgetStyles';
import { withRouter } from 'react-router-dom';
import { IExceededMTFDetail } from '../../interfaces/academicRecords/IExceededMTFDetail';
import { IMTFPreCheck } from '../../interfaces/academicRecords/MTFPreCheck';
import { mtfWidgetApi } from '../../api/student/academics/mtfWidgetApi';
import MtfWidgetSettingsPopup from './mtfWidgetSettingsPopup';

const useStyles = widgetStyles.default;
const customStyles = makeStyles((_theme: any) =>
    createStyles({
        enrollmentCell: {
            width: '46%',
        },
        dateCell: {
            width: '22%',
        },
        missedDaysCell: {
            width: '10%',
        },
    })
);

function MTFWidget(props: any) {
    const classes = useStyles();
    const customClasses = customStyles();

    const [
        mtfPreCheckData,
        setMtfPreCheckData,
    ] = React.useState<IMTFPreCheck[]>([]);

    const resetPage = () => {
        setMtfPreCheckData([]);
    };

    const LoadMtfPreCheckData = (campusId: any) => {
        setIsLoading(true);
        mtfWidgetApi.getMTFPreCheckResult(campusId).then(
            (response: IMTFPreCheck[]) => {
                setIsLoading(false);
                setMtfPreCheckData(response ?? []);
            },
            (_exception: any) => {
                setIsLoading(false);
                setMtfPreCheckData([]);
            }
        );
    };

    const campusId = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!!campusId) {
            resetPage();
            LoadMtfPreCheckData(campusId);
        }
    }, [campusId]);

    const [isSettingsPopupOpen, setIsSettingsPopupOpen] =
        React.useState<boolean>(false);

    const handleSettingsPopup = (isOpen: boolean) => {
        setIsSettingsPopupOpen(isOpen);
    };

    const handleRefreshOnSettingsChange = () => {
        LoadMtfPreCheckData(campusId);
    };

    const handlePrint = () => {
        mtfWidgetApi.printMtfWidgetReport(campusId).then();
    }

    return (
        <AccessManager
            allowedPermissions={[
                security.permissions.MTFWidget.ViewMTFWidget,
            ]}
            renderNoAccess={() => null}
        >
            <Card className={classes.root}>
                <Typography className={clsx(classes.cardTitle)}>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={6}>
                            <div className={classes.headerText}>
                                MTF Widget
                            </div>
                        </Grid>

                        <Grid item xs={6} className={classes.widgetMenu}>
                            <IconButton
                                className={classes.iconBtn}
                                aria-label="settings"
                                onClick={handleRefreshOnSettingsChange}
                            >
                                <Refresh />
                            </IconButton>

                            <IconButton
                                className={classes.iconBtn}
                                aria-label="Print"
                                onClick={handlePrint}
                            >
                                <Print />
                            </IconButton>

                            <IconButton
                                className={classes.iconBtn}
                                aria-label="settings"
                                onClick={(_e: any) => {
                                    handleSettingsPopup(true);
                                }}
                            >
                                <MoreVert />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Typography>
                {<CardContent className={`${classes.cardContent}`}>
                    <div className={classes.innerRoot}>
                        <TableContainer
                            component={Paper}
                            className={classes.tableBottomMargin}
                        >
                            <Table
                                className={classes.table}
                                size="small"
                                aria-label="a dense table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.headCell} align="left">
                                            Enrollment
                                        </TableCell>
                                        <TableCell className={classes.headCell} align="left">
                                            Student scheduled hours
                                        </TableCell>
                                        <TableCell className={classes.headCell} align="left">
                                            Student actual hours
                                        </TableCell>
                                        <TableCell className={classes.headCell} align="left">
                                            Program MTF
                                        </TableCell>
                                        <TableCell className={classes.headCell} align="left">
                                            Student MTF
                                        </TableCell>
                                        <TableCell className={classes.headCell} align="left">
                                            % MTF
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isLoading === true && <TableRow>
                                            <TableCell colSpan={6} align="center">
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>}
                                    {isLoading === false && !!mtfPreCheckData && mtfPreCheckData.length > 0 ? (
                                        mtfPreCheckData.map(
                                            (row: IMTFPreCheck) => {
                                                return (
                                                    <TableRow key={`${row.studentNumber}`}>
                                                        <TableCell
                                                            className={`${classes.bodyCell} ${customClasses.enrollmentCell}`}
                                                            align="left"
                                                        >
                                                            <Link
                                                                className={classes.textAlignLeft}
                                                                component="button"
                                                                onClick={() => {
                                                                    let urlParams = new URLSearchParams(
                                                                        props.history.location.search
                                                                    );
                                                                    let studentIdQueryId = 'studentId';
                                                                    let campusIdQueryId = 'campusId';

                                                                    //set url parameters for campusId and studentId
                                                                    urlParams.set(
                                                                        studentIdQueryId,
                                                                        row.studentId as any
                                                                    );
                                                                    urlParams.set(
                                                                        campusIdQueryId,
                                                                        campusId
                                                                    );
                                                                    props.history.push({
                                                                        pathname: '/student/1/profile/info',
                                                                        search: urlParams.toString(),
                                                                    });
                                                                }}
                                                            >
                                                                {`${row.lastName} ${row.firstName} ${row.studentNumber} (${row.programVersionName})`}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${classes.bodyCell} ${customClasses.dateCell}`}
                                                            align="left"
                                                        >
                                                            {row.sheduleHours ?? 0}
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${classes.bodyCell} ${customClasses.dateCell}`}
                                                            align="left"
                                                        >
                                                            {row.actualHours ?? 0}
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${classes.bodyCell} ${customClasses.missedDaysCell}`}
                                                            align="left"
                                                        >
                                                            {row.programMtf ?? 0}
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${classes.bodyCell} ${customClasses.missedDaysCell}`}
                                                            align="left"
                                                        >
                                                            {row.studentMtf ?? 0}
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${classes.bodyCell} ${customClasses.missedDaysCell}`}
                                                            align="left"
                                                        >
                                                            {(row.mtfPercentage ?? 0).toFixed(2)}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                        )
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={6} align="center">
                                                No Data
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </CardContent>}

                <MtfWidgetSettingsPopup
                    open={isSettingsPopupOpen}
                    setOpen={handleSettingsPopup}
                    refreshData={handleRefreshOnSettingsChange}
                />
            </Card>
        </AccessManager>
    );
}
export default withRouter(MTFWidget);
