import { Card, CardContent, createStyles, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import ProgramAndProgramVersionAutoComplete from "../../../../components/AutoComplete/ProgramAndProgramVersionAutoComplete/ProgramAndProgramVersionAutoComplete";
import CustomCardTitle from "../../../../interfaces/common/card/CustomCardTitle";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%",
    },
    cardWrapper: {
      marginBottom: theme.spacing(2),
    },
  })
);

type SearchAndAddProgramsCardProps = {
  addNew: any;
  setProgramProgramVersionId: any;
  setNewProgramProgramVersionName: any;
  setNewProgramVersionProgramId?: any;
  setIsProgramVersion: (val?: boolean) => void;
  setIsCreditHour: (val?: boolean) => void;
  refreshData:boolean;
};
const SearchAndAddProgramsCard = (props: SearchAndAddProgramsCardProps) => {
  const [value, setValue] = useState<any>(undefined);
  const classes = useStyles({});
  return (
    <Card square={true} className={classes.cardWrapper}>
      <CustomCardTitle title={"Search and Add Programs"}/>
      <CardContent>
        <ProgramAndProgramVersionAutoComplete
        refreshData={props.refreshData}
          valueFilter={
            value
              ? {
                  key: "value",
                  values: [value],
                }
              : undefined
          }
          filterHandle={(v: any) => {
            setValue(v ? v.value : null);
            props.setProgramProgramVersionId(v);
            props.setIsProgramVersion(v ? v.isProgramVersion : false);
            props.setIsCreditHour(v ? v.isCreditHour : false)
            props.setNewProgramProgramVersionName(v);
          }}
          addNew={() => {
            props.addNew();
          }}
          setIsProgramVersion={props.setIsProgramVersion}
          setIsCreditHour={props.setIsCreditHour}
          setNewProgramVersionProgramId={props.setNewProgramVersionProgramId}
          setNewProgramProgramVersionName={props.setNewProgramProgramVersionName}
          setSelectedProgramProgramVersionId={props.setProgramProgramVersionId}
        />
      </CardContent>
    </Card>
  );
};

export default SearchAndAddProgramsCard;
