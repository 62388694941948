import * as React from "react";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { DegCertSeekingApiAutoComplete } from "../../constants/SystemCatalog/DegCertSeekingApiAutoComplete";
import { useRef } from "react";

const DegCertSeekingAutoComplete = (props: FilterParams) => {
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const handleOnChange = (e: any) => {
    if (props.filterHandle) {
      props.filterHandle(e);
    }
  };

  const inputRef: any = useRef(null);
  return (
    <div>
      <ApiAutoComplete
        config={DegCertSeekingApiAutoComplete.config}
        loading={userSelectedCampus ? false : true}
        reqParams={{ campusId: userSelectedCampus }}
        classes={{
          option: "text-black"
        }}
        label={props.label ?? "Degree Certificate Seeking"}
        onChange={(e: any) => {
          handleOnChange(e);
        }}
        multiple={props.multiple || false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        inputRef={props.inputRef ? props.inputRef : inputRef}
        error={props.error}
        id={props.id}
        inputName={props.name}
        helperText={props.helperText}
        showIncludeAllValue={props.showIncludeAllValue}
        chipSize={props.chipSize}
        maxTags={props.maxTags}
        includeAllValue={props.includeAllValue ? props.includeAllValue : false}
      ></ApiAutoComplete>
    </div>
  );
};
export default DegCertSeekingAutoComplete;
