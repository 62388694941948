import React, { useEffect, useState, Fragment } from "react";
import {
  makeStyles,
  createStyles,
  Card,
  Grid,
  Button,
  CardContent,
  FormControlLabel,
  Switch,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";
import { IClassScheduleGridRow } from "../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/Schedule/IScheduleGridRow";
import CustomCardTitle from "../../../../interfaces/common/card/CustomCardTitle";
import { ThemeTextField } from "../../../../components/_Layout/Inputs/ThemeTextField";
import { CustomSnackBarProps } from "../../../../interfaces/common/CustomSnackBarProps";
import InputField from "../../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../../constants/uiConstants/inputConstants";
import { FormContext, useForm, FieldError } from "react-hook-form";
import { toastr } from "react-redux-toastr";
import ScheduleDetailsGrid from "../schedule/SchedulesDetailsGrid";
import * as ClassScheduleApi from "../../../../api/setup/academics/classScheduleApi";
import { EmptyGuid } from "../../../../utils/constants";
import ConfirmationModal from "../../../../components/_Layout/Modal/ConfirmationModal";
import StatusAutoComplete from "../../../../components/AutoComplete/StatusAutoComplete";
const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%",
    },
    cardWrapper: {
      height: "100%",
      padding: theme.spacing(2),

      marginTop: theme.spacing(2),
    },
    saveButton: {
      width: 90,
    },
    gridMargin: {
      marginTop: theme.spacing(3),
    },
    switch: {
      marginLeft: "0px",
      paddingTop: theme.spacing(2),
    },
    fullWidthField: {
      width: "100%",
    },
    preLoader: {
      textAlign: "center",
    },
  })
);

type ScheduleDetailsProps = {
  model: IClassScheduleGridRow;
  setScheduleModel: any;
  isDirty: boolean;
  setDirty: any;
  setSnackBar: any;
  refreshSchedules: any;
  setSchedules: any;
  clearFields: any;
  resetChanges: any;
};
const ScheduleDetailsModal = (props: ScheduleDetailsProps) => {
  const classes = useStyles({});
  const { model, setScheduleModel } = props;
  const [loading, setLoading] = React.useState<boolean>(false);
  const { isDirty, setDirty } = props;

  
  const scheduleData = useForm<any>({ mode: "onBlur" });
  useEffect(() => {
    handleScheduleFieldChange("totalHours", model.totalHours);
  }, []);

  const handleScheduleFieldChange = (fieldId: string, value: any) => {
    if (fieldId != "totalHours" && (model as any)[fieldId] != value) {
      setDirty(true);
    }
    let updatedModel = model;
    (updatedModel as any)[fieldId] = value;
    setScheduleModel({ ...updatedModel });
    scheduleData.setValue(fieldId, value);
  };

  const setScheduleDetails = (newValue: any) => {
    handleScheduleFieldChange("classScheduleDetails", newValue);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (_index: any, newValue: any) => {
    setValue(newValue);
  };
  React.useEffect(() => {}, [value]);
  const validateMaximumTimeAllowed = (model: IClassScheduleGridRow) => {
    let isValid = true;
    return isValid;
  };
  const saveScheduleDetails = async () => {
    scheduleData.triggerValidation().then((validation: any) => {
      let isValid = validateMaximumTimeAllowed(model);
      if (validation && isValid) {
        setLoading(true);
        setDirty(false);
        let modelToSend = model;
        ClassScheduleApi.upsertClassSchedule(modelToSend).then(
          (newDetails: any) => {
            toastr.success("", "Schedule saved successfully.");
            setTimeout(() => {
              toastr.clean();
            }, 3000);
            setLoading(false);
            props.clearFields();
          },
          (exception: any) => {
            toastr.error("", "Something went wrong.");
            setLoading(false);
          }
        );
      }
      else{setLoading(false);}

    })
  };

  return (
    <Fragment>
          {(loading && (
            <div className={classes.preLoader}>
              <CircularProgress />
            </div>
          )) || (
            <React.Fragment>
              <Grid container direction="row" spacing={1}>
                <Grid
                  item
                  container
                  xs={4}
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <ThemeTextField
                    id="name"
                    label="Schedule *"
                    name="name"
                    className={classes.fullWidthField}
                    value={model.name}
                    onChange={(e: any) => {
                      handleScheduleFieldChange("name", e.target.value);
                    }}
                    error={!!scheduleData.errors.name}
                    inputRef={scheduleData.register({ required: true })}
                    helperText={
                      scheduleData.errors.name
                        ? "Schedule name is required."
                        : undefined
                    }
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={4}
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <ThemeTextField
                    id="code"
                    label="Code *"
                    name="code"
                    className={classes.fullWidthField}
                    value={model.code ? model.code : ""}
                    onChange={(e: any) => {
                      handleScheduleFieldChange("code", e.target.value);
                    }}
                    error={!!scheduleData.errors.code}
                    inputRef={scheduleData.register({ required: true })}
                    helperText={
                      scheduleData.errors.code ? "Code is required." : undefined
                    }
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={2}
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <StatusAutoComplete
                    id="statusId"
                    name="statusId"
                    label="Status *"
                    valueFilter={
                      model.statusId
                        ? {
                            key: "value",
                            values: [model.statusId],
                          }
                        : undefined
                    }
                    filterHandle={(v: any) => {
                      handleScheduleFieldChange("statusId", v ? v.value : null);
                    }}
                    error={!!scheduleData.errors.statusId}
                    inputRef={scheduleData.register({
                      required: "Status is required.",
                    })}
                    helperText={
                      scheduleData.errors.statusId
                        ? (scheduleData.errors.statusId as FieldError).message
                        : undefined
                    }
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={2}
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <InputField
                    type={InputType.NUMBER}
                    key="totalHours"
                    id="totalHours"
                    label="Total Hours *"
                    name="totalHours"
                    value={model.totalHours ? model.totalHours : 0}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                className={classes.gridMargin}
                spacing={1}
              ></Grid>
              <ScheduleDetailsGrid
                model={props.model.classScheduleDetails}
                setModel={setScheduleDetails}
                readonly={false}
              />
              <Grid container direction="row" spacing={4}>
                <Grid
                  item
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <Grid container direction="row" spacing={1} wrap="nowrap">
                    <Grid
                      item
                      alignContent="flex-start"
                      alignItems="flex-start"
                      justify="flex-start"
                    >
                      <ProgressSaveButton
                        text="Save"
                        onClick={() => {
                          saveScheduleDetails();
                        }}
                        loading={loading}
                        disabled={false}
                        buttonClassName={classes.saveButton}
                        size="small"
                      ></ProgressSaveButton>
                    </Grid>
                    <Grid
                      item
                      alignContent="flex-start"
                      alignItems="flex-start"
                      justify="flex-start"
                    >
                      
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
    </Fragment>
  );
};

export default ScheduleDetailsModal;
