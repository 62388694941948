import React from 'react';
//import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Checkbox,
	Typography,
	CardContent,
	Card,
	Button,
	Grid,
	IconButton,
	FormControlLabel,
	useTheme,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import CustomSnackbar from '../../components/notifications/CustomSnackbar';
import { CustomSnackBarProps } from '../../interfaces/common/CustomSnackBarProps';
import AccessManager from '../../components/security/AccessManager';
import { security } from '../../constants/Security/securityConstants';
import * as PropTypes from 'prop-types';
import makeStyles from './WidgetStyles';
import * as ReportsApi from '../../api/reports/allReports/reportsApi';
import { ReportType } from '../../enums/ReportType';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Report from '../../interfaces/reports/report';
import IRunReport from '../../interfaces/reports/IRunReport';
import HistoryIcon from '@material-ui/icons/History';
import moment from 'moment';
import ProgressSaveButton from '../../components/_Layout/Buttons/ProgressSaveButton';
import ReportGenerationHistoryModal from '../widgets/ReportGenerationHistoryModal';
import { format } from 'date-fns';
const useStyles = makeStyles;

function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`report-widget-tabpanel-${index}`}
			aria-labelledby={`report-widget-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3} style={{ padding: '0px' }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

type DateRange = {
    from?: Date,
    to?: Date
}

function a11yProps(index: any) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

function ReportsWidget(props: any) {
	const classes = useStyles();
	const [weeklyReports, setWeeklyReports] = React.useState<Report[]>([]);
	const [monthlyReports, setMonthlyReports] = React.useState<Report[]>([]);
	const [selected, setSelected] = React.useState<number[]>([]);
	const [selectAll, setSelectAll] = React.useState(false);
	const [dateRange, setDateRange] = React.useState<DateRange>({ from: undefined, to: undefined });
	const [printing, setPrinting] = React.useState(false);
	const [sendEmail, setSendEmail] = React.useState(false);
	const [modalState, setModalState] = React.useState(false);
	const theme = useTheme();
	const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
		{
			showSnackBar: false,
			messageInfo: undefined,
			variant: 'info',
		}
	);

	const isSelected = (row: any) =>
		selected.indexOf(row.id) !== -1;


	const selectAllClick = (event: any) => {
		setSelectAll(event.target.checked);

		if (event.target.checked) {
			var data = [];
			if (value === 0) {
				data = weeklyReports;
			}
			else {
				data = monthlyReports;
			}
			const selected = data.map((r: Report) => r.id);
			setSelected(selected);
			return;
		}
		setSelected([]);
	};

	const toggleModal = (val: boolean) => {
		setModalState(val);
		props.setModalOpen(val);
	};

	const rowClick = (event: any, row: any) => {
		const sIndex = selected.findIndex(
			(r: number) => r === row.id
		);

		let newSelected = [...selected];
		if (sIndex === -1) {
			newSelected.push(row.id);
		} else {
			Array.prototype.splice.call(newSelected, sIndex, 1);
		}
		setSelected(newSelected);
	};

	const LoadWidgetData = (userId: string, campusId: string) => {

		ReportsApi.getReportsForWidget(userId, campusId).then(
			(response: any) => {
				if (response) {
					let data = response.map((x: any) => {
						return {
							id: x.id, reportType: x.reportType,
							name: x.name,
						} as Report;
					});
					let weeklyReports = data.filter((x: any) => x.reportType === ReportType.Weekly);
					let monthlyReports = data.filter((x: any) => x.reportType === ReportType.Monthly);
					setWeeklyReports(weeklyReports);
					setMonthlyReports(monthlyReports);
				}
			},
			(_exception: any) => { }
		);
	};

	const campusId = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const { userId, email } = useSelector((state: any) =>
		state.session.user
	);
	const resetPage = () => {
		setWeeklyReports([]);
		setMonthlyReports([]);
		setSelected ([]);
		setSelectAll(false);
		setPrinting(false);
		setSendEmail(false);
		setModalState(false);
	  };

	React.useEffect(() => {
		if (campusId && userId) {
			resetPage();
			LoadWidgetData(userId, campusId);
		}
	}, [campusId, userId]);

	const printReports = () => {
		if (selected.length > 0 && dateRange.from && dateRange.to) {
			setPrinting(true);
			let params: IRunReport = {
				campusId: campusId,
				userId: userId,
				reports: selected,
				fromDate: format(dateRange.from, 'yyyy-MM-dd'),
				toDate: format(dateRange.to, 'yyyy-MM-dd'),
				sendEmail: sendEmail,
				email: email,
				contentUrl: (localStorage.getItem('UXUrl') || (process.env.REACT_APP_DOMAIN + (process.env.REACT_APP_PORT ? ':' + process.env.REACT_APP_PORT : '') + '/')) + 'images/undraw_growth_analytics_8btt.png'
			};

			let qs = new URLSearchParams({
				CampusId: params.campusId,
				UserId: params.userId,
				FromDate: params.fromDate,
				ToDate: params.toDate
			} as any).toString();

			params.returnUrl = (localStorage.getItem('UXUrl') || (process.env.REACT_APP_DOMAIN + (process.env.REACT_APP_PORT ? ':' + process.env.REACT_APP_PORT : '') + '/')) + 'reports/downloadReports' + '?' + qs;

			ReportsApi.runReports(params).then(
				(response: any) => {
					if (response) {
						setSnackBarProps((_props: any) => {
							return {
								variant: 'info',
								showSnackBar: true,
								messageInfo: 'Report generation in progress. A notification will be sent once the process is complete.',
							};
						});
						setSelected([]);
						setSelectAll(false);
					} else {
						setSnackBarProps((_props: any) => {
							return {
								variant: 'error',
								showSnackBar: true,
								messageInfo:
                                    'An error occured when generating reports. Please try again.',
							};
						});
					}
					setPrinting(false);
				},
				(_exception: any) => {
					setPrinting(false);
				}
			);
		}
	};


	const setDateFilters = (date: any, direction: number) => {
		let currDateRange = { ...dateRange };
		if (value === 0) {
			let days = 6 * direction;
			let dateOffset = moment(date, 'MM/DD/YYYY').add(days, 'days').toDate();
			if (direction < 0) {
				currDateRange.from = dateOffset;
				currDateRange.to = moment(date).clone().toDate();
			}

			else {
				currDateRange.from = moment(date).clone().toDate();
				currDateRange.to = dateOffset;
			}
		}
		else if (value === 1) {
			const startOfMonth = moment(date, 'MM/DD/YYYY').clone().startOf('month').toDate();
			const endOfMonth = moment(date, 'MM/DD/YYYY').clone().endOf('month').toDate();
			currDateRange.from = startOfMonth;
			currDateRange.to = endOfMonth;
		}
		setDateRange(currDateRange);

	};

	const [value, setValue] = React.useState(0);

	const handleChange = (index: any, newValue: any) => {
		setValue(newValue);
	};
	React.useEffect(() => {
		setSelected([]);
		setSelectAll(false);

		setDateFilters(moment().clone().toDate(), -1);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);


	const CustomCheckBox = React.forwardRef((_props: any, _ref: React.Ref<HTMLDivElement>) => (
		<Grid container justify="flex-end">
			<Grid item>
				<FormControlLabel
					control={<Checkbox color="primary" checked={sendEmail} onChange={(e, val) => setSendEmail(val)} name="checkedSendEmail" />}
					label="Send Email Notification"
				/>
			</Grid>

		</Grid>
	));

	return (
		<AccessManager
			allowedPermissions={[
				security.permissions.dashboard.viewReportsWidget,
			]}
			renderNoAccess={() => null}
		>
			<Card className={classes.root}>
				<Typography className={clsx(classes.cardTitle)}>
					<Grid container direction="row" justify="space-between" alignItems="center">
						<Grid item xs={4}>
							<div className={classes.headerText}>REPORTS</div>
						</Grid>
						<Grid item xs={12}>
							<Grid item container justify="space-between" style={{ padding: theme.spacing(1, 1) }}>
								<Grid item>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<KeyboardDatePicker
											disableToolbar
											variant="inline"
											format="MM/dd/yyyy"
											id="repWidgetStartDate"
											name="repWidgetStartDate"
											label="Period From"
											value={dateRange.from}
											maxDate={value === 0 ? moment(new Date(), 'MM/DD/YYYY').add(-6, 'days').toDate() : moment(new Date(), 'MM/DD/YYYY').clone().endOf('month').toDate()}
											onChange={(e: any, value: any) => setDateFilters(value, 1)}
											KeyboardButtonProps={{
												'aria-label': 'period from date',
											}}

										/>
									</MuiPickersUtilsProvider>
								</Grid>

								<Grid item>

									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<KeyboardDatePicker
											disableToolbar
											variant="inline"
											format="MM/dd/yyyy"
											id="repWidgetEndDate"
											label="Period To"
											value={dateRange.to}
											maxDate={value === 0 ? new Date() : moment(new Date(), 'MM/DD/YYYY').clone().endOf('month').toDate()}
											onChange={(e: any, value: any) => setDateFilters(value, -1)}
											KeyboardButtonProps={{
												'aria-label': 'custom end date',
											}}
										/>
									</MuiPickersUtilsProvider>
								</Grid>

								<Grid item>

									<IconButton
										className={classes.iconBtn}
										aria-label="settings"
										onClick={(_e) => toggleModal(true)}
									>
										<HistoryIcon />
									</IconButton>
								</Grid>

							</Grid>
						</Grid>
					</Grid>
				</Typography>
				<CardContent style={{ padding: theme.spacing(1) }}>
					<div>
						<Tabs
							value={value}
							onChange={handleChange}
							className={classes.tabStyle}
							indicatorColor="primary"
						>
							<Tab label="Weekly" {...a11yProps(0)} />
							<Tab label="Monthly" {...a11yProps(1)} />
							<Tab disabled={true} component={CustomCheckBox} {...a11yProps(2)} />


						</Tabs>

					</div>
					<div>
						<TableContainer component={Paper} style={{ height: 250 }}>
							<TabPanel value={value} index={0}>
								<Table
									className={classes.table}
									size="small"
									aria-label="a dense table"
								>
									<TableHead>
										<TableRow>
											<TableCell align="left" className={classes.headCell}>
                                                Report
											</TableCell>
											<TableCell align="right">
												<Checkbox
													value="uncontrolled"
													color="primary"
													onClick={selectAllClick}
													inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
													checked={selectAll}
												/>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{weeklyReports !== null && weeklyReports.length > 0 ? (
											weeklyReports.map((row: any, index: number) => {
												const isRowSelected = isSelected(row);

												return (
													<TableRow role="checkbox" key={`row-${index}`}>
														<TableCell
															align="left"
															className={classes.bodyCell}
														>
															<Typography
																className={classes.linkDesign}

															>
																{row.name}
															</Typography>
														</TableCell>
														<TableCell align="right">

															<Checkbox
																value="uncontrolled"
																color="primary"
																onClick={(event) =>
																	rowClick(event, row)
																}
																inputProps={{
																	'aria-label': 'uncontrolled-checkbox',
																}}
																checked={isRowSelected}
															/>
														</TableCell>
													</TableRow>
												);
											})
										) : (
											<TableRow>
												<TableCell align="center" colSpan={4}>
                                                    No Data
												</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</TabPanel>
							<TabPanel value={value} index={1}>
								<Table
									className={classes.table}
									size="small"
									aria-label="a dense table"
								>
									<TableHead>
										<TableRow>
											<TableCell align="left" className={classes.headCell}>
                                                Report
											</TableCell>
											<TableCell align="right">
												<Checkbox
													value="uncontrolled"
													color="primary"
													onClick={selectAllClick}
													inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
													checked={selectAll}
												/>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{monthlyReports !== null && monthlyReports.length > 0 ? (
											monthlyReports.map((row: any, index: number) => {
												const isRowSelected = isSelected(row);

												return (
													<TableRow role="checkbox" key={`row-${index}`}>
														<TableCell
															align="left"
															className={classes.bodyCell}
														>
															<Typography
																className={classes.linkDesign}

															>
																{row.name}
															</Typography>
														</TableCell>
														<TableCell align="right">

															<Checkbox
																value="uncontrolled"
																color="primary"
																onClick={(event) =>
																	rowClick(event, row)
																}
																inputProps={{
																	'aria-label': 'uncontrolled-checkbox',
																}}
																checked={isRowSelected}
															/>
														</TableCell>

													</TableRow>
												);
											})
										) : (
											<TableRow>
												<TableCell align="center" colSpan={4}>
                                                    No Data
												</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</TabPanel>
						</TableContainer>
					</div>
				</CardContent>
				<div style={{ padding: theme.spacing(1) }}>
					<AccessManager
						allowedPermissions={[
							security.permissions.dashboard.viewReportsWidget,
						]}
						renderNoAccess={() => (
							<Button
								disabled={true}
								onClick={() => {
									printReports();
								}}
								size="small"
								className={classes.button}
								type="button"
								color="primary"
							>
                                PRINT
							</Button>
						)}
					>
						<ProgressSaveButton
							text={'PRINT'}
							onClick={() => {
								printReports();
							}}
							loading={printing}
						></ProgressSaveButton>
					</AccessManager>
				</div >
				<ReportGenerationHistoryModal
					open={modalState}
					setOpen={toggleModal}
					campusId={campusId}
					userId={userId}
				/>

				<CustomSnackbar
					variant={snackBarProps.variant}
					message={snackBarProps.messageInfo}
					open={snackBarProps.showSnackBar}
					onClose={(_event?: React.SyntheticEvent, _reason?: string) => {
						setSnackBarProps((props: any) => {
							return { ...props, showSnackBar: false };
						});
					}}
				></CustomSnackbar>
			</Card>

		</AccessManager>
	);
}
ReportsWidget.propTypes = {
	className: PropTypes.string,
	params: PropTypes.object,
};
export default ReportsWidget;
