import { IGEDataCheckParam } from "../../interfaces/reports/IGEDataCheckReportParam";
import API from "../apiWrapper";

export const generateGEStudentsEnrolledReport = (
    params: IGEDataCheckParam,
    isPreview: boolean = false
) => {
    return API()
        .post(
            "/Reports/GainfulEmployment/GenerateGEStudentsEnrolledReport",
            params,
            {
                responseType: "blob",
            }
        )
        .then(
            (res: any) => {
                if (res && res.data) {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                        const link = document.createElement("a");
                        let extension = "xls";
                       
                        var fileName =
                            "GEStudentEnrolled" +
                            new Date()
                                .toISOString()
                                .replace("T", " ")
                                .replace(/\..*$/, "")
                                .split(" ")[0]
                                .split("-")
                                .join("") +
                            "." +
                            extension;

                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                }
            },
            (error: any) => {
                return null;
            }
        );
};

export const generateGECompletersReport = (
    params: IGEDataCheckParam,
    isPreview: boolean = false
) => {
    return API()
        .post(
            "/Reports/GainfulEmployment/GenerateGECompletersReport",
            params,
            {
                responseType: "blob",
            }
        )
        .then(
            (res: any) => {
                if (res && res.data) {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                        const link = document.createElement("a");
                        let extension = "xls";
                       
                        var fileName =
                            "GECompletersReport" +
                            new Date()
                                .toISOString()
                                .replace("T", " ")
                                .replace(/\..*$/, "")
                                .split(" ")[0]
                                .split("-")
                                .join("") +
                            "." +
                            extension;

                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                }
            },
            (error: any) => {
                return null;
            }
        );
};

export const generateGEProgramsReport = (
    params: IGEDataCheckParam,
    isPreview: boolean = false
) => {
    return API()
        .post(
            "/Reports/GainfulEmployment/GenerateGEProgramsReport",
            params,
            {
                responseType: "blob",
            }
        )
        .then(
            (res: any) => {
                if (res && res.data) {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                        const link = document.createElement("a");
                        let extension = "xls";
                       
                        var fileName =
                            "GEProgramsReport" +
                            new Date()
                                .toISOString()
                                .replace("T", " ")
                                .replace(/\..*$/, "")
                                .split(" ")[0]
                                .split("-")
                                .join("") +
                            "." +
                            extension;

                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                }
            },
            (error: any) => {
                return null;
            }
        );
};

export const generateGEProgramsDetailReport = (
    params: IGEDataCheckParam,
    isPreview: boolean = false
) => {
    return API()
        .post(
            "/Reports/GainfulEmployment/GenerateGEProgramsDetailReport",
            params,
            {
                responseType: "blob",
            }
        )
        .then(
            (res: any) => {
                if (res && res.data) {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                        const link = document.createElement("a");
                        let extension = "xls";
                       
                        var fileName =
                            "GEProgramDetailReport" +
                            new Date()
                                .toISOString()
                                .replace("T", " ")
                                .replace(/\..*$/, "")
                                .split(" ")[0]
                                .split("-")
                                .join("") +
                            "." +
                            extension;

                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                }
            },
            (error: any) => {
                return null;
            }
        );
};