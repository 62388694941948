import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import CampusForCurrentUserAutoComplete from "../../../../components/AutoComplete/CampusForCurrentUserAutoComplete";
import { Grid, Card, Typography, CardContent } from "@material-ui/core";
import CustomCardTitle from "../../../../interfaces/common/card/CustomCardTitle";
import { FormContext, useForm, FieldError } from "react-hook-form";
import ProgramVersionAutoComplete from "../../../../components/AutoComplete/ProgramVersionAutoComplete";
import ProgramsAutoComplete from "../../../../components/AutoComplete/ProgramsAutoComplete";
import { useSelector } from "react-redux";
import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";
import { ICopyProgramVersion } from "../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/ICopyProgramVersionParams";
import * as copyProgramVersionApi from "../../../../api/setup/academics/copyProgramVersionApi";
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";
import { CustomSnackBarProps } from "../../../../interfaces/common/CustomSnackBarProps";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      minHeight: "100%",
      height: "100%",
      maxWidth: "1050px",
      padding: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    startDateField: {
      width: "100%",
    },
    fullWidthElement: {
      width: "100%",
    },
    cardTitle: {
      fontSize: 17,
      color: "Black",
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardSeparator: {
      marginBottom: theme.spacing(1),
    },
    copyButton: {
      marginTop: theme.spacing(3),
      width: 90,
    },
    cardWrapper: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);

function CopyProgramVersionToCampus() {
  const classes = useStyles();
  const data = useForm<any>({ mode: "onBlur" });
  const { handleSubmit } = data;
  const [updating, setUpdating] = React.useState<boolean>(false);

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );

  const initialState: ICopyProgramVersion = {
    fromCampusId: "",
    programId: "",
    programVerId: "",
    toCampusId: "",
  };

  const [model, setModel] = React.useState<ICopyProgramVersion>(initialState);

  const handleFieldOnChange = (fieldId: string, value: any) => {
    let updatedModel = model;
    if (fieldId == "fromCampusId")
    {
      (updatedModel as any)["programId"] = "";
      (updatedModel as any)["programVerId"] = "";
    }
    (updatedModel as any)[fieldId] = value;
    setModel({ ...updatedModel });
  };

  const onSubmit = async (isPreview: any) => {
    const validation = await data.triggerValidation();
    if (validation) {
      setUpdating(true);
      copyProgramVersionApi.copyProgramVersion(model).then(
        (response: any) => {
          if (response && response.data && response.data.resultStatus === 0) {
            if (response.data.result === true) {
              setSnackBarProps(() => {
                return {
                  variant: "success",
                  showSnackBar: true,
                  messageInfo: response.data.resultStatusMessage,
                };
              });
              setModel(initialState);
            } else {
              setSnackBarProps(() => {
                return {
                  variant: "error",
                  showSnackBar: true,
                  messageInfo: response.data.resultStatusMessage,
                };
              });
            }
          } else {
            setSnackBarProps(() => {
              return {
                variant: "error",
                showSnackBar: true,
                messageInfo: response.data,
              };
            });
          }
          setUpdating(false);
        },
        (exception: any) => {
          setSnackBarProps(() => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo: exception,
            };
          });
          setUpdating(false);
        }
      );
    }
  };

  return (
    <Card className={classes.cardSeparator}>
      <Typography className={clsx(classes.cardTitle)}>
        <div className={classes.headerText}>Copy Program Version to Campus</div>
      </Typography>
      <CardContent>
        <FormContext {...data}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="row" spacing={1}>
              <Grid item md={12} sm={12} xs={12}>
                <Card square={true} className={classes.cardWrapper}>
                  <CustomCardTitle title={"From"}></CustomCardTitle>
                  <CardContent>
                    <Grid container direction="row" spacing={2}>
                      <Grid item md={6} sm={6} xs={12}>
                        <CampusForCurrentUserAutoComplete
                          params={{
                            fullWidth: true,
                          }}
                          id="fromCampusId"
                          name="fromCampusId"
                          label="Campus *"
                          filterHandle={(v: any) => {
                            handleFieldOnChange(
                              "fromCampusId",
                              v ? v.value : undefined
                            );
                          }}
                          valueFilter={
                            model.fromCampusId
                              ? {
                                  key: "value",
                                  values: [model.fromCampusId],
                                }
                              : undefined
                          }
                          error={!!data.errors.fromCampusId}
                          inputRef={data.register({ required: true })}
                          helperText={
                            data.errors.fromCampusId
                              ? "Campus is required."
                              : undefined
                          }
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}></Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <ProgramsAutoComplete
                          params={{
                            fullWidth: true,
                          }}
                          id="programId"
                          name="programId"
                          label="Program *"
                          activePrograms={true}
                          campusId={model.fromCampusId}
                          valueFilter={
                            model.programId
                              ? {
                                  key: "value",
                                  values: [model.programId],
                                }
                              : undefined
                          }
                          inputRef={data.register({
                            required: {
                              value: true,
                              message: "Program is required.",
                            },
                          })}
                          helperText="Program is required."
                          error={!!data.errors.programId}
                          filterHandle={(v: any) => {
                            handleFieldOnChange(
                              "programId",
                              v ? v.value : undefined
                            );
                          }}
                        ></ProgramsAutoComplete>
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}></Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <ProgramVersionAutoComplete
                          params={{
                            fullWidth: true,
                          }}
                          id="programVerId"
                          name="programVerId"
                          label="Program Version *"
                          valueFilter={
                            model.programVerId
                              ? {
                                  key: "value",
                                  values: [model.programVerId],
                                }
                              : undefined
                          }
                          programId={model.programId ?? null}
                          campusId={model.fromCampusId}
                          error={!!data.errors.programVerId}
                          inputRef={data.register({ required: true })}
                          helperText={
                            data.errors.programVerId
                              ? "Program version is required."
                              : undefined
                          }
                          filterHandle={(v: any) => {
                            handleFieldOnChange(
                              "programVerId",
                              v ? v.value : undefined
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card square={true} className={classes.cardWrapper}>
                  <CustomCardTitle title={"To"}></CustomCardTitle>
                  <CardContent>
                    <Grid container direction="row" spacing={2}>
                      <Grid item md={6} sm={6} xs={12}>
                        <CampusForCurrentUserAutoComplete
                          params={{
                            fullWidth: true,
                          }}
                          id="toCampusId"
                          name="toCampusId"
                          label="New Campus *"
                          filterHandle={(v: any) => {
                            handleFieldOnChange(
                              "toCampusId",
                              v ? v.value : undefined
                            );
                          }}
                          valueFilter={
                            model.toCampusId
                              ? {
                                  key: "value",
                                  values: [model.toCampusId],
                                }
                              : undefined
                          }
                          error={!!data.errors.toCampusId}
                          inputRef={data.register({ required: true })}
                          helperText={
                            data.errors.toCampusId
                              ? "Campus is required."
                              : undefined
                          }
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Grid
                item
                alignContent="flex-start"
                alignItems="flex-start"
                justify="flex-start"
              >
                <ProgressSaveButton
                  text="Create"
                  onClick={(e: any) => {
                    onSubmit(e);
                  }}
                  loading={updating}
                  buttonClassName={classes.copyButton}
                ></ProgressSaveButton>
              </Grid>
            </Grid>
          </form>
        </FormContext>
      </CardContent>
      <CustomSnackbar
        variant={snackBarProps.variant}
        message={snackBarProps.messageInfo}
        open={snackBarProps.showSnackBar}
        onClose={(event?: React.SyntheticEvent, reason?: string) => {
          setSnackBarProps((props: any) => {
            return { ...props, showSnackBar: false };
          });
        }}
      ></CustomSnackbar>
    </Card>
  );
}
export default CopyProgramVersionToCampus;
