import API from "../apiWrapper";
import { ReportOutput } from "../../enums/ReportOutput";
import { IAttendanceExceptions } from "../../interfaces/reports/attendance/IAttendanceExceptions";

export const getAttendanceExceptionsReport = (
  params: IAttendanceExceptions,
  isPreview: boolean = false
) => {
  return API()
    .post("/AcademicRecords/Attendance/GetAttendanceExceptionsReport", params, {
      responseType: "blob",
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          if (isPreview && ReportOutput.Pdf === params.exportType) {
            return res.data;
          } else {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            let exportType: number = Number(params.exportType);
            let extension = "";
            switch (exportType) {
              case Number(ReportOutput.Pdf):
                extension = "pdf";
                break;
              case Number(ReportOutput.Excel):
                extension = "xls";
                break;
              case Number(ReportOutput.CSV):
                extension = "csv";
                break;
              default:
                extension = "pdf";
                break;
            }
            var fileName = "AttendanceExceptionsReport" + "." + extension;

            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          }
        }
      },
      (error: any) => {
        return null;
      }
    );
};
