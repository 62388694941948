import API from "../../apiWrapper";
import { CustomSnackBarProps } from "../../../interfaces/common/CustomSnackBarProps";
export const getSavedReportParameterDetail = (
  resourceId: number | undefined,
  campusId: string | undefined, 
  reportParameterId: number | undefined
): Promise<any> => {
  let route: string =
    `Report/GetSavedReportParameterDetail?reportParameterId=${reportParameterId}&resourceId=${resourceId}&campusId=${campusId}`;
  return API()
    .get(route)
    .then(
      (res: any) => {
        return res.data;
      },
      (error: any) => {
        if (error.response) {
          throw new Error(error.response.data); // => the response payload
        }
        throw new Error("Unknown error");
      }
    );
};

export const saveReportParameter = (params: any): Promise<CustomSnackBarProps> => {
  let route: string = "Report/CreateReportParameter";
  return API()
    .post(route, params)
    .then(
      (res: any) => {
          if (res && res.data) {
              return {
                  variant: res.data.result ? 'success' : 'error',
                  showSnackBar: true,
                  messageInfo: res.data.resultStatusMessage
              }
          }
          throw (new Error('Unknown error'));
      },
      (error: any) => {
          if (error.response) {
              throw new Error(error.response.data); // => the response payload 
          }
          throw (new Error('Unknown error'));
      }
  );
};
