import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Dialog, TextField, DialogTitle, DialogContent, Portal } from "@material-ui/core";
import { useForm, FormContext } from "react-hook-form";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, Paper, CardContent, Grid, Button } from "@material-ui/core";
import ProgressSaveButton from "../../../components/_Layout/Buttons/ProgressSaveButton";
import { IStatusChangeOption } from "../../../interfaces/student/status/IStatusChangeOption";
import { useSelector } from "react-redux";
import AccessManager from '../../../components/security/AccessManager';
import { security } from '../../../constants/Security/securityConstants';

type LOADisbursementConfirmationProps = {
    open: boolean;
    setOpen: any;
    meta: any;
};

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            "& .MuiPaper-rounded": {
                borderRadius: theme.spacing.borderradius,
            },
            "& .MuiDialogContent-root": {
                minHeight: 270,
            },
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            width: 'fit-content',
            minWidth: 100,
        },
        dialogHeader: {
            backgroundColor: theme.palette.site.secondary,
            "& .MuiTypography-root": {
                fontSize: theme.spacing(2),
            },
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
        },
        detailWrapper: {
            padding: theme.spacing(.5),
        },
        reasonTb: {
            width: '100%'
        },
        cancelBtn: {
            marginLeft: theme.spacing(2)
        },
        errorMsg: {
            color: "red",
        },
    })
);

const LOADisbursementConfirmation = (props: LOADisbursementConfirmationProps) => {
    const classes = useStyles({});
    const [reasonValue, setReasonValue] = React.useState<string>("");
    const validation = useForm<any>({ mode: "onBlur" });
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleClose = () => {
        props.setOpen(false);
        setReasonValue("")
    };


    const submitNewStatus = () => {
        if (props.meta.meta)
            props.meta.handleSubmit(props.meta.meta, props.meta.runValidation, true, reasonValue)
        else
            props.meta.handleSubmit(true, reasonValue)

        handleClose()
    }


    return (
        <FormContext {...validation}>
            <form className={classes.form}>
                <div>

                    <Dialog
                        open={props.open}
                        onClose={handleClose}
                        className={classes.root}
                        aria-labelledby="form-dialog-title"
                        fullWidth={true}
                        maxWidth='xs'
                        disableBackdropClick
                    >
                        <DialogTitle
                            id="form-dialog-title"
                            className={classes.dialogHeader}
                        >
                            Disbursement Found - LOA
                            <IconButton
                                aria-label="close"
                                className={classes.closeButton}
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography>
                                        {`A disbursement is found "${props.meta ? props.meta.response.data.resultStatusMessage : ''}" during LOA date(s).`}
                                        Are you sure you want to continue?
                                    </Typography>
                                    <br />
                                    <Typography>
                                        If <strong>YES</strong>, please add reason.
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField label="Reason *"
                                        className={classes.reasonTb}
                                        value={reasonValue}
                                        onChange={(e) => { setReasonValue(e.target.value) }}
                                        name="reason"
                                        error={!!validation.errors.reason}
                                        inputRef={validation.register({ required: true })}
                                        helperText={
                                            validation.errors.reason
                                                ? "Reason is required."
                                                : undefined
                                        }
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <AccessManager
                                        allowedPermissions={[
                                            security.permissions.student.postStudentOnLoa,
                                        ]}
                                        renderNoAccess={() => (
                                            <div>
                                                <ProgressSaveButton
                                                    text={'Submit'}
                                                    disabled={true}
                                                    loading={loading}
                                                    onClick={async () => {
                                                        const response = await validation.triggerValidation();
                                                        if (response)
                                                            submitNewStatus()
                                                    }}
                                                ></ProgressSaveButton>
                                                <Button
                                                    className={classes.cancelBtn}
                                                    onClick={handleClose}
                                                    color="secondary"
                                                    variant="contained"
                                                >Cancel</Button>
                                                <Typography className={classes.errorMsg}>You don't have permission to post student on LOA with listed disbursement.</Typography>
                                            </div>


                                        )}
                                    >
                                        <ProgressSaveButton
                                            text={'Submit'}                                            
                                            loading={loading}
                                            onClick={async () => {
                                                const response = await validation.triggerValidation();
                                                if (response)
                                                    submitNewStatus()
                                            }}
                                        ></ProgressSaveButton>
                                        <Button
                                            className={classes.cancelBtn}
                                            onClick={handleClose}
                                            color="secondary"
                                            variant="contained"
                                        >Cancel</Button>
                                        
                                    </AccessManager>


                                </Grid>
                            </Grid>

                        </DialogContent>
                    </Dialog>
                </div>
            </form>
        </FormContext>
    )
};

export default LOADisbursementConfirmation;

