import * as React from "react";
import clsx from "clsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import { Typography, Card, CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import AdminCriteriaAutoComplete from "../../../../../src/components/AutoComplete/AdminCriteriaAutoComplete";
import AdmissionsRepsAutoCompleteNoSearchIcon from "../../../../../src/components/AutoComplete/AdmissionsRepsAutoCompleteNoSearchIcon";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import AdmissionsRepsAutoComplete from "../../../../../src/components/AutoComplete/AdmissionsRepsAutoComplete";
import InstitutionsAutoComplete from "../../../../../src/components/AutoComplete/InstitutionsAutoComplete";
import { IStudentInfoAdmissions } from "../../../../interfaces/student/profile/studentInfo/IStudentInfo";
import EducationCompletedAutoComplete from "../../../../../src/components/AutoComplete/EducationCompletedAutoComplete";
import { FieldError, useFormContext } from "react-hook-form";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		drawer: {
			width: 280
		},
		root: {
			backgroundColor: theme.palette.paper.background,
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2)
			}
		},
		"& .makeStyles-content": {
			backgroundColor: theme.palette.paper.background
		},
		cardTitle: {
			fontSize: 17,
			color: theme.palette.black,
			textDecoration: "bold",
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1),
			marginBottom: theme.spacing(1)
		},
		textField: {
			marginRight: theme.spacing(1),
			width: "100%"
		},
		headerText: {
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(0)
		},
		cardContent: {
			padding: theme.spacing(1, 3)
		},
		switch: {
			marginLeft: "0px",
			paddingTop: theme.spacing(2)
		}
	})
);

const ThemeTextField = withStyles((theme: any) => createStyles({}))(TextField);

type AdmissionsProps = {
	model: IStudentInfoAdmissions;
	setModel: any;
	isNewEnrollment: boolean;
	isHighSchoolMandatory?: any;
};

const Admissions = (props: AdmissionsProps) => {
	const classes = useStyles({});
	const { model, setModel } = props;
	const data = useFormContext();

	const handleFieldOnChange = (fieldId: string, value: any) => {
		let updatedModel = model;
		(updatedModel as any)[fieldId] = value;
		setModel(updatedModel);
	};

	return (
		<div>
			<Card square={true}>
				<Typography className={clsx(classes.cardTitle)}>
					<div className={classes.headerText}>Admissions</div>
				</Typography>
				<CardContent className={classes.cardContent}>
					<Grid container direction="row" spacing={2}>
						<Grid item md={4} sm={6} xs={12}>
							<InstitutionsAutoComplete
								id="highSchoolId"
								filterHandle={(v: any) => {
									handleFieldOnChange("highSchoolId", v ? v.value : null);
								}}
								label={(props.isHighSchoolMandatory == true) ? 'High School *' : "High School"}
								name="highSchoolId"
								valueFilter={
									model.highSchoolId
										? {
											key: "value",
											values: [model.highSchoolId]
										}
										: undefined
								}
								helperText={
									!!data.errors.highSchoolId ? (data.errors.highSchoolId as FieldError).message : undefined
								}
								error={!!data.errors.highSchoolId}
							/>
						</Grid>
						<Grid item md={4} sm={6} xs={12}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="MM/dd/yyyy"
									id="HighSchoolGraduationDate"
									label={(props.isHighSchoolMandatory == true) ? 'High School Graduation Date *' : "High School Graduation Date" }
									TextFieldComponent={ThemeTextField}
									fullWidth
									autoOk={true}
									KeyboardButtonProps={{
										"aria-label": "change date"
									}}
									value={
										model.highSchoolGraduationDate
											? new Date(
												model.highSchoolGraduationDate
											).toLocaleDateString()
											: null
									}
									onChange={val => {
										handleFieldOnChange("highSchoolGraduationDate", val);
									}}
									helperText={
										!!data.errors.highSchoolGraduationDate ? (data.errors.highSchoolGraduationDate as FieldError).message : undefined
									}
									error={!!data.errors.highSchoolGraduationDate}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item md={4} sm={6} xs={12}>
							<FormControlLabel
								id="currentlyAttendingHighSchool"
								className={classes.switch}
								control={
									<Switch
										checked={model.currentlyAttendingHighSchool}
										value={model.currentlyAttendingHighSchool}
										onChange={(e: any) => {
											handleFieldOnChange(
												"currentlyAttendingHighSchool",
												e.target.checked
											);
										}}
										color="primary"
									/>
								}
								labelPlacement="start"
								label="Currently Attending High School"
							/>
						</Grid>
						<Grid item md={4} sm={6} xs={12}>
							<AdmissionsRepsAutoCompleteNoSearchIcon
								id="admissionsRepId"
								name="admissionsRepId"
								filterHandle={(v: any) => {
									handleFieldOnChange("admissionsRepId", v ? v.value : null);
								}}
								valueFilter={
									model.admissionsRepId
										? {
											key: "value",
											values: [model.admissionsRepId]
										}
										: undefined
								}
							/>
						</Grid>
						<Grid item md={4} sm={6} xs={12}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="MM/dd/yyyy"
									id="AdmissionsRepAssigned"
									label="Admissions Rep Assigned"
									TextFieldComponent={ThemeTextField}
									fullWidth
									autoOk={true}
									KeyboardButtonProps={{
										"aria-label": "change date"
									}}
									value={
										model.admissionsRepAssigned
											? new Date(
												model.admissionsRepAssigned
											).toLocaleDateString()
											: null
									}
									onChange={val => {
										handleFieldOnChange("admissionsRepAssigned", val);
									}}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</div>
	);
};

export default Admissions;
