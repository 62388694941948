import * as React from "react";
import {
  Menu,
  MenuItem,
} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import { system } from "../../../constants/system/systemConstants";
import { useSelector } from "react-redux";

const NewEnrollmentSelectionModal = (props: any) => {
  const [open, setOpen] = React.useState<any>();
  const appSettings = useSelector(
    (state: any) => {  return state.settings.settings }
);

const getImportLeadPopupFlag = () => {

    const importLeadPopupSetting = appSettings[system.settings.enableImportLeadPopup] as string
    let importLeadIntegrationEnabled = false;

    if (importLeadPopupSetting) {
        importLeadIntegrationEnabled = importLeadPopupSetting.toLowerCase() === 'yes'
    }
    return importLeadIntegrationEnabled;

};
  const history = props.browserLocationHistory;//useHistory();
  React.useEffect(() => {
    setOpen(props.modelProps)
  }, [props.modelProps]);

  const handleClose = () => {
    setOpen({isOpen : false})
  };

  return (
    <Menu
      id="breadcrumb-menu"
      anchorEl={props.anchorEl}
      open={open && open.isOpen}
      onClose={handleClose}
      TransitionComponent={Fade}
    >
      <MenuItem
        onClick={function (e: any) {
          handleClose();
          history.push("/Student/NewEnrollment?isNew=true&isImport=" + getImportLeadPopupFlag())
        }}
      >
        New Student
      </MenuItem>
      <MenuItem
        onClick={function (e: any) {
          handleClose();
          history.push("/Student/NewEnrollment?isNew=false")
        }}
      >
        New Enrollment
      </MenuItem>
   
    </Menu>
  );
};
export default NewEnrollmentSelectionModal;
