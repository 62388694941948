export interface IIncrementDetails {
  actualHours: number;
  scheduledHours: number;
  chargeAmount: number;
  transactionCode: string;
  usesActualHours: boolean;
  hours: number;
  options:any;
  isEditModeOn: boolean;
  transactionCodeId: string;
  id:string;
}

export class IncrementDetails implements IIncrementDetails {
  actualHours: number = 0;
  scheduledHours: number = 0;
  chargeAmount: number = 0;
  transactionCode: string = "";
  usesActualHours: boolean = false;
  hours: number = 0;
  options:any;
  isEditModeOn: boolean = false;
  transactionCodeId: string = "";
  id: string = "";
}
