import React, { useEffect, useState, Fragment } from "react";
import {
  makeStyles,
  createStyles,
  Card,
  Grid,
  Button,
  CardContent,
  FormControlLabel,
  Switch,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import ProgressSaveButton from "../../../../../../components/_Layout/Buttons/ProgressSaveButton";
import { IScheduleGridRow } from "../../../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/Schedule/IScheduleGridRow";
import CustomCardTitle from "../../../../../../interfaces/common/card/CustomCardTitle";
import { ThemeTextField } from "../../../../../../components/_Layout/Inputs/ThemeTextField";
import InputField from "../../../../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../../../../constants/uiConstants/inputConstants";
import { FormContext, useForm } from "react-hook-form";
import ScheduleDetailsGrid from "./SchedulesDetailsGrid";
import * as ProgramScheduleApi from "../../../../../../api/setup/academics/programScheduleApi";
import { EmptyGuid } from "../../../../../../utils/constants";
import ConfirmationModal from "../../../../../../components/_Layout/Modal/ConfirmationModal";
const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%",
    },
    cardWrapper: {
      height: "100%",
      padding: theme.spacing(2),

      marginTop: theme.spacing(2),
    },
    saveButton: {
      width: 150,
    },
    switch: {
      marginLeft: "0px",
      paddingTop: theme.spacing(2),
    },
    fullWidthField: {
      width: "100%",
    },
    preLoader: {
      textAlign: "center",
    },
  })
);

type ScheduleDetailsProps = {
  model: IScheduleGridRow;
  setModel: any;
  setSnackBar: any;
  refreshSchedules: any;
  setSchedules: any;
  clearFields: any;
  resetChanges: any;
};
const ScheduleDetails = (props: ScheduleDetailsProps) => {
  const classes = useStyles({});
  const { model, setModel } = props;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [confirmationPopupState, setConfirmationPopupState] =
    React.useState<any>({
      id: "simple-popup",
      message: "",
    });

  const handleCancel = () => {
    setConfirmationPopupState((state: any) => {
      return {
        ...state,
        open: true,
        confirmationText: "Are you sure you want to discard your changes?",
        confirmationButton: "Yes",
        title: "Discard Changes?",
        onConfirmEvent: () => {
          if (model.scheduleId === EmptyGuid) {
            props.clearFields();
          } else {
            props.resetChanges();
          }
          setConfirmationPopupState((updatedState: any) => {
            return { ...updatedState, open: false };
          });
        },
        onCancelEvent: () => {
          setConfirmationPopupState((updatedState: any) => {
            return { ...updatedState, open: false };
          });
        },
      };
    });
  };
  const data = useForm<any>({ mode: "onBlur" });
  const { handleSubmit } = data;
  useEffect(() => {
    handleFieldChange("totalHours", model.totalHours);
  }, []);

  const handleFieldChange = (fieldId: string, value: any) => {
    let updatedModel = model;
    (updatedModel as any)[fieldId] = value;
    setModel({ ...updatedModel });
    data.setValue(fieldId, value);
  };

  const setScheduleDetails = (newValue: any) => {
    handleFieldChange("programScheduleDetails", newValue);
  };

  const validateMaximumTimeAllowed = (model: IScheduleGridRow) => {
    let isValid = true;
    let scheduleDetail = model.programScheduleDetails;
    scheduleDetail.map(function (v) {

      if ( v.maximumHoursPerDay && (v.maximumHoursPerDay > 24 || (v.total && Math.round(v.maximumHoursPerDay * 100) < Math.round(v.total * 100))))
        { 
          isValid = false;
        }
    });
    return isValid;
  };

  const onSubmit = (d: any) => {
    if (model) {
      let modelToSend = model;
      data.triggerValidation().then((validation: any) => {
        let isValid = validateMaximumTimeAllowed(model);
        if (validation && isValid) {
          setLoading(true);
          ProgramScheduleApi.upsertProgramVersionSchedule(modelToSend).then(
            (response: any) => {
              if (response.resultStatus === 0) {
                props.refreshSchedules();
                props.setSnackBar((props: any) => {
                  return {
                    variant: "success",
                    showSnackBar: true,
                    messageInfo: response.resultStatusMessage,
                  };
                });
              } else {
                props.setSnackBar((props: any) => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: response.data,
                  };
                });
              }
              setLoading(false);
            },
            (exception: any) => {
              props.setSnackBar((snackBarProps: any) => {
                return {
                  variant: "error",
                  showSnackBar: true,
                  messageInfo: exception,
                };
              });
              setLoading(false);
            }
          );
        } else {
          props.setSnackBar((snackBarProps: any) => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo:
                "Maximum time allowed must be less then 24 hours and greater then schedule hours.",
            };
          });
        }
      });
    }
  };

  return (
    <Fragment>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card square={true} className={classes.cardWrapper}>
            <CustomCardTitle title={"Schedule Details"} />
            <CardContent>
              {(loading && (
                <div className={classes.preLoader}>
                  <CircularProgress />
                </div>
              )) || (
                <React.Fragment>
                  <Grid container direction="row" spacing={1}>
                    <Grid
                      item
                      container
                      xs={4}
                      alignContent="flex-start"
                      alignItems="flex-start"
                      justify="flex-start"
                    >
                      <ThemeTextField
                        id="description"
                        label="Schedule *"
                        name="description"
                        className={classes.fullWidthField}
                        value={model.description}
                        onChange={(e: any) => {
                          handleFieldChange("description", e.target.value);
                        }}
                        error={!!data.errors.description}
                        inputRef={data.register({ required: true })}
                        helperText={
                          data.errors.description
                            ? "Schedule is required."
                            : undefined
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={2}
                      alignContent="flex-start"
                      alignItems="flex-start"
                      justify="flex-start"
                    >
                      <InputField
                        type={InputType.NUMBER}
                        key="totalHours"
                        id="totalHours"
                        label="Total Hours *"
                        name="totalHours"
                        value={model.totalHours}
                        disabled
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={2}
                      alignContent="flex-start"
                      alignItems="flex-start"
                      justify="flex-start"
                    >
                      <Tooltip
                        title="This information is typically published in your school catalog"
                        arrow
                      >
                        <InputField
                          id="schPrgWeeks"
                          key="schPrgWeeks"
                          label="Scheduled Program Weeks *"
                          name="schPrgWeeks"
                          type={InputType.NUMBER}
                          className={classes.fullWidthField}
                          defaultValue={model.schPrgWeeks ?? null}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e: any) => {
                            handleFieldChange("schPrgWeeks", e.target.value);
                          }}
                          error={!!data.errors.schPrgWeeks}
                          inputRef={data.register({ required: true })}
                          helperText={
                            data.errors.schPrgWeeks
                              ? "Schedule program weeks are required."
                              : undefined
                          }
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1}>
                    <Grid
                      item
                      container
                      xs={2}
                      alignContent="flex-start"
                      alignItems="flex-start"
                      justify="flex-start"
                    >
                      <FormControlLabel
                        id="active"
                        className={classes.switch}
                        labelPlacement="start"
                        label="Active"
                        control={
                          <Switch
                            checked={model.active}
                            name="active"
                            onChange={(e: any) => {
                              handleFieldChange(
                                "active",
                                e ? e.target.checked : false
                              );
                            }}
                            color="primary"
                          />
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={2}
                      alignContent="flex-start"
                      alignItems="flex-start"
                      justify="flex-start"
                    >
                      <FormControlLabel
                        id="useFlexTime"
                        className={classes.switch}
                        labelPlacement="start"
                        label="Flexible Schedule"
                        control={
                          <Switch
                            checked={model.useFlexTime}
                            name="useFlexTime"
                            onChange={(e: any) => {
                              handleFieldChange(
                                "useFlexTime",
                                e ? e.target.checked : false
                              );
                            }}
                            color="primary"
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                  <ScheduleDetailsGrid
                    model={props.model.programScheduleDetails}
                    setModel={setScheduleDetails}
                  />
                  <Grid container direction="row" spacing={4}>
                    <Grid
                      item
                      container
                      xs={1}
                      alignContent="flex-start"
                      alignItems="flex-start"
                      justify="flex-start"
                    >
                      <ProgressSaveButton
                        text="Save"
                        onClick={(e: any) => {}}
                        loading={false}
                        disabled={false}
                        buttonClassName={classes.saveButton}
                      ></ProgressSaveButton>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={1}
                      alignContent="flex-start"
                      alignItems="flex-start"
                      justify="flex-start"
                    >
                      <Button
                        onClick={handleCancel}
                        color="secondary"
                        variant="contained"
                        type="button"
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </CardContent>
          </Card>
        </form>
      </FormContext>
      <ConfirmationModal {...confirmationPopupState} />
    </Fragment>
  );
};

export default ScheduleDetails;
