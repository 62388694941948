import React from "react";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import {
		Typography,
		Paper,
		CardContent,
		Grid,
		Button,
		TextField
	} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
		MuiPickersUtilsProvider,
		KeyboardDatePicker
	} from "@material-ui/pickers";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ProgressSaveButton from "../../../components/_Layout/Buttons/ProgressSaveButton";
import { ILeaveOfAbsence, NewILeaveOfAbsenceDetail } from "../../../interfaces/student/status/ILeaveOfAbsence";
import { ILeaveOfAbsenceReason } from "../../../interfaces/student/status/ILeaveOfAbsenceReason";
import * as LeaveOfAbsenceApi from "../../../api/student/status/LeaveOfAbsenceApi";
import { ApiResultStatus } from "../../../enums/ApiResultStatus"; 
import { useSelector } from "react-redux";
import { useFormContext, Controller } from "react-hook-form";
import { format} from "date-fns";

type LeaveOfAbsenceDetailProps = {
	setSnackBarProps: any;
	updateEvent: any;
	newStatusCode: any;
	newStatus: any;
	loaConfirmation: any;
};

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& label.Mui-focused": {
				color: theme.palette.primary.white,
			},
		},
	})
)(TextField);

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.background.paper,
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2),
			},
		},
		paperRoot: {
			width: "100%",			
		},
		cardTitle: {
			fontSize: theme.spacing(2),
			color: theme.palette.black,
			textDecoration: "bold",
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1)
		},
		cardContent: {
			padding: theme.spacing(1, 1)
		},
		datePicker: {
			"& .MuiIconButton-root": {
				padding: theme.spacing(0.8)
			}
		},    
		textField: {
			marginRight: theme.spacing(1),
			width: "100%"
		  },
		actionContainer: {
			marginTop: theme.spacing(3)
		  },
		  button: {
			marginRight: theme.spacing(1),
			marginLeft: theme.spacing(1),
        },
	})
);

	const LeaveOfAbsenceDetail = (props: 
		LeaveOfAbsenceDetailProps) => {

    const classes = useStyles({});

	const data = useFormContext();

	const selectedEnrollment = useSelector(
		(state: any) => state.student.selectedEnrollment
	);
	
	const [reasonData, setReasonData] = React.useState<Array<ILeaveOfAbsenceReason>>([]);
	const [selectedReason, setSelectedReason] = React.useState(null);
	const [loaderState, setLoaderState] = React.useState<boolean>(false);

	const refreshReason=()=>{
		if (selectedEnrollment && selectedEnrollment.stuEnrollId) {
			let studentEnrollmentId = selectedEnrollment.stuEnrollId;
			LeaveOfAbsenceApi.getLOAReasons(studentEnrollmentId).then(
				(response: Array<ILeaveOfAbsenceReason>) => {
					if (response && response.length > 0) {
						setReasonData(response);
					}
				},
				(exception: any) => {}
			);
		}
	}
	React.useEffect(() => {
		refreshReason();
	}, [selectedEnrollment]);

	const [leaveOfAbsenceData, setLeaveOfAbsenceData] = React.useState<ILeaveOfAbsence>(
		NewILeaveOfAbsenceDetail((selectedEnrollment && selectedEnrollment.stuEnrollId)
			? selectedEnrollment.stuEnrollId
			: "", props.newStatusCode)
	);

	const handleFieldOnChange = (fieldId: string, value: any) => {
		let updatedData = leaveOfAbsenceData;
		(updatedData as any)[fieldId] = value;
		setLeaveOfAbsenceData({ ...updatedData });
	  };

	const handleReasonOnChange = (fieldId: string, value: any) => {
		let updatedData = leaveOfAbsenceData;
		setSelectedReason(value);
		(updatedData as any)[fieldId] = (value != null)? value.leaveOfAbsenceReasonId : null;
		setLeaveOfAbsenceData({ ...updatedData });
	};

	const handleSubmit = (disbursementConfirmation?: boolean,  existingDisbursementReason?: string) => {

		data.triggerValidation().then((validation: any) => {
			if (validation) {
				setLoaderState(true);
				leaveOfAbsenceData.startDate = new Date(leaveOfAbsenceData.startDate).toLocaleDateString()
				leaveOfAbsenceData.endDate = new Date(leaveOfAbsenceData.endDate).toLocaleDateString()
				leaveOfAbsenceData.existingDisbursementReason = existingDisbursementReason;
			  	LeaveOfAbsenceApi.createLOA(leaveOfAbsenceData, disbursementConfirmation).then(
				(response: any) => {
					setLoaderState(false);	
					if (response.data != undefined && response.data.resultStatus != undefined)
					{
						switch (response.data.resultStatus) {
							case ApiResultStatus.Success:
								props.setSnackBarProps((props: any) => {
									return {
									variant: "success",
									showSnackBar: true,
									messageInfo: response.data.resultStatusMessage
									};
								});	
								props.updateEvent((leaveOfAbsenceData.startDate != null && new Date(leaveOfAbsenceData.startDate) <= new Date(Date.now()))? props.newStatus : null);				
								break;							
							case ApiResultStatus.Warning:
								props.setSnackBarProps((props: any) => {
									return {
									variant: "warning",
									showSnackBar: true,
									messageInfo: response.data.resultStatusMessage
									};
								});
								break;
							case ApiResultStatus.Error:
								props.setSnackBarProps((props: any) => {
									return {
									variant: "error",
									showSnackBar: true,
									messageInfo: response.data.resultStatusMessage
									};
								});
								break;
							case ApiResultStatus.ConfirmationNeeded:
							{
								props.loaConfirmation({response, handleSubmit, meta : null});
								break;
							}
							default:
						}						
					}
					else {
						props.setSnackBarProps((props: any) => {
							return {
							variant: "error",
							showSnackBar: true,
							messageInfo: "Error creating leave of absence."
							};
						});
					}
				},
				(exception: any) => {
					setLoaderState(false);	
					props.setSnackBarProps((snackBarProps: any) => {
					  return {
						variant: "error",
						showSnackBar: true,
						messageInfo: exception,
					  };
					});
				}
				)
			}
		});			
	};

	const handleCancel = () => {
		setLeaveOfAbsenceData(NewILeaveOfAbsenceDetail((selectedEnrollment && selectedEnrollment.stuEnrollId)
			? selectedEnrollment.stuEnrollId
			: "", props.newStatusCode));
		setSelectedReason(null);
	};


	return (

	<Paper className={classes.paperRoot}>
		<Typography className={classes.cardTitle} >
				Add LOA Details
		</Typography>
		<CardContent className={classes.cardContent}>
		<Grid container direction="row" spacing={2}>
		<Grid item md={4} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="startDate"
                  label="Start Date"
                  fullWidth
				  autoOk={true}
				  required
                  KeyboardButtonProps={{
                    "aria-label": "start date"
                  }}
                  value={
                    leaveOfAbsenceData.startDate
                      ? new Date(leaveOfAbsenceData.startDate).toLocaleDateString()
                      : null
				  }
				  error={!!data.errors["startDate"]}
				  innerRef={(ref: any) => {
					data.register("startDate", { required: true });
					data.setValue("startDate", leaveOfAbsenceData.startDate);
				  }}			  
                  onChange={(val: any) => {
                    handleFieldOnChange("startDate", val);
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
			<Grid item md={4} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
				  disableToolbar
				  autoOk={true}
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="endDate"
				  label="End Date"				  
				  className={classes.datePicker}
				  fullWidth
				  required
                  KeyboardButtonProps={{
                    "aria-label": "end date"
                  }}
                  value={
                    leaveOfAbsenceData.endDate
                      ? new Date(leaveOfAbsenceData.endDate).toLocaleDateString()
                      : null
				  }
				  error={!!data.errors["endDate"]}
				  innerRef={(ref: any) => {
					data.register("endDate", { required: true });
					data.setValue("endDate", leaveOfAbsenceData.endDate);
				  }}				  
                  onChange={(val: any) => {
                    handleFieldOnChange("endDate", val);
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
			<Grid item md={4} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
				  disableToolbar
				  autoOk={true}
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="requestDate"
				  label="Request Date"				  
				  className={classes.datePicker}
				  fullWidth
				  required
                  KeyboardButtonProps={{
                    "aria-label": "request date"
                  }}
                  value={
                    leaveOfAbsenceData.requestDate
                      ? new Date(leaveOfAbsenceData.requestDate).toLocaleDateString()
                      : null
				  }
				  error={!!data.errors["requestDate"]}
				  innerRef={(ref: any) => {
					data.register("requestDate", { required: true });
					data.setValue("requestDate", leaveOfAbsenceData.requestDate);
				  }}				  
                  onChange={(val: any) => {
                    handleFieldOnChange("requestDate", val);
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
			<Grid item md={8} sm={6} xs={12}>
			<Autocomplete
					id="loa-reasons"
					options={reasonData}
					className={classes.textField}
					value={selectedReason}
					getOptionLabel={(option) => (option != null)? option.description: ""}
					renderInput={(params) => (
						<TextField {...params} label="LOA Reason" required error={!!data.errors["selectedReason"]}/>
					)}
					innerRef={(ref: any) => {
						data.register("selectedReason", 
						{ validate: (value: any) => {
							if (selectedReason) return true; else return false;
							}} 
						);
					  }}			
					onChange={(e: any, v: any) => {
						handleReasonOnChange("reasonId", v);
					}}
				/>
			</Grid>
			<Grid item md={4} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
				  disableToolbar
				  autoOk={true}
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="returnDate"
				  label="Return from LOA"	
				  disabled={(leaveOfAbsenceData.studentStatusChangeId == null? false : true )}		  
				  className={classes.datePicker}
				  fullWidth
                  KeyboardButtonProps={{
                    "aria-label": "return date"
                  }}
                  value={
                    leaveOfAbsenceData.returnDate
                      ? new Date(leaveOfAbsenceData.returnDate).toLocaleDateString()
                      : null
				  }
				  error={!!data.errors["returnDate"]}
				  innerRef={(ref: any) => {
					data.setValue("returnDate", leaveOfAbsenceData.returnDate);
				  }}				  
                  onChange={(val: any) => {
                    handleFieldOnChange("returnDate", val);
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

			</Grid>
			
			<div className={classes.actionContainer}>
				<ProgressSaveButton
				text="Save"
				onClick={() => {
					handleSubmit();
				}}
				loading={loaderState}
				></ProgressSaveButton>
				<Button
					onClick={handleCancel}
					color="secondary"
					variant="contained"
					className={classes.button}
				>Cancel</Button>
          </div>
		</CardContent>
	</Paper>

	);
};
	
export default LeaveOfAbsenceDetail;
