import API from "../../apiWrapper";
import { MonthlyAccrualsAccountReceivableParams } from "../../../interfaces/reports/studentAccounts/MonthlyAccrualsAccountReceivableParams";
import { ReportOutput } from "../../../enums/ReportOutput";
export const generateReport = (
  params: MonthlyAccrualsAccountReceivableParams,
  isPreview: boolean = false
) => {
  return API()
    .post(
      "/Reports/FinancialAid/MonthlyAccrualsAccountsReceivable/GetMonthlyAccrualsAccountsReceivable",
      params,
      { responseType: "blob" }
    )
    .then(
      (res: any) => {
        if (res && res.data) {
          if (isPreview && ReportOutput.Pdf == params.exportType) {
            return res.data;
          } else {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            let exportType: number = Number(params.exportType);
            let extension = exportType === ReportOutput.Pdf ? "pdf" : "xlsx";
            const fileName =
              "MonthlyAccrualsAccountsReceivableReport" +
              new Date()
                .toISOString()
                .replace("T", " ")
                .replace(/\..*$/, "")
                .split(" ")[0]
                .split("-")
                .join("") +
              "." +
              extension;

            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          }
        }
      },
      (error: any) => {
        return null;
      }
    );
};
