import * as React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
	Grid,
	FormControl,
	FormLabel,
	FormHelperText,
} from "@material-ui/core";
import { FormContext, useForm } from "react-hook-form";
import { IStudentPaidByTypeReportParams } from "../../../interfaces/reports/studentAccounts/IStudentPaidByTypeReport";
import * as studentPaidByTypeApi from "../../../api/reports/studentAccounts/studentPaidByTypeReportApi";
import { ReportOutput } from "../../../enums/ReportOutput";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import StartEndDateFilter from "../../attendance/StartEndDateFilter";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import { useSelector } from "react-redux";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ProgramVersionAutoComplete from "../../../components/AutoComplete/ProgramVersionAutoComplete";
import EnrollmentStatusAutoComplete from "../../../components/AutoComplete/EnrollmentStatusAutoComplete";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			minHeight: "100%",
			height: "100%",
			padding: theme.spacing(2),
		},
		"& .makeStyles-content": {
			backgroundColor: theme.palette.background.paper,
		},
		paperRoot: {
			padding: theme.spacing(3, 2),
			width: "100%",
			minHeight: "100%",
			height: "100%",
			overflowX: "hidden",
			overflowY: "auto",
			flexDirection: "column",
			backgroundColor: theme.palette.background.paper,
		},
		searchActions: {
			marginTop: theme.spacing(3),
			textAlign: "left",
		},
		startDateField: {
			width: "100%",
		},
		fieldSet: {
			marginTop: theme.spacing(2),
		},
	})
);

const getFormattedDate = (date: Date) => {
	var year = date.getFullYear();

	var month = (1 + date.getMonth()).toString();
	month = month.length > 1 ? month : "0" + month;

	var day = date.getDate().toString();
	day = day.length > 1 ? day : "0" + day;

	return month + "/" + day + "/" + year;
};

const StudentPaidByTypeReport = (props: any) => {
	const { report } = props;
	const classes = useStyles({});
	const [preview, setPreview] = React.useState<any>();
	const [previewLoader, setPreviewLoader] = React.useState<boolean>();
	const [value, setValue] = React.useState<any>({
		startDate: new Date(),
		endDate: new Date(),
	});

	let reportOverview = { report: report } as IReportOverview;

	const data = useForm<any>({ mode: "onBlur" });

	const campusId = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const [model, setModel] = React.useState({
		campusId: campusId as string,
		startDateRange: value.startDate as Date,
		endDateRange: value.endDate as Date,
		exportType: ReportOutput.Pdf as any,
		programVersionIds: [],
		enrollmentStatusIds: []
	});
	const setEnrollmentStatus = (enrollmentStatusIds: any) => {
		let newStatuses: string[] = [];
		if (enrollmentStatusIds !== null) {
			if (Array.isArray(enrollmentStatusIds)) {
				newStatuses = enrollmentStatusIds.map((gr: any) => gr.value);
			} else {
				newStatuses.push(enrollmentStatusIds.value);
			}
		}

		return newStatuses;
	};

	const setProgramVersion = (PrgVersions: any) => {
		let newProgramVersion: string[] = [];
		if (PrgVersions !== null) {
			if (Array.isArray(PrgVersions)) {
				newProgramVersion = PrgVersions.map((gr: any) => gr.value);
			} else {
				newProgramVersion.push(PrgVersions.value);
			}
		}
		return newProgramVersion;
	};

	const exportTypes: Array<DropDownListItem> = [
		{ text: "PDF", id: "1" },
		{ text: "Excel", id: "2" },
		{ text: "Word", id: "3" },
	];

	const exportTypeAutoComplete = {
		options: exportTypes,
		getOptionLabel: (option: DropDownListItem) => option.text,
	};

	const handleFieldOnChange = (
		fieldId: string,
		value: any,
		mapFunction?: Function
	) => {
		let updatedModel = model;
		let newValue = mapFunction ? mapFunction(value) : value;
		(updatedModel as any)[fieldId] = newValue;
		setModel({ ...updatedModel });
	};

	const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
		const item = items.find((opt) => {
			if (opt.id == value) return opt;
		});
		return item || ({} as DropDownListItem);
	};

	const { handleSubmit } = data;

	const onSubmit = async (isPreview: any): Promise<void> => {
		let params = await getReportParameters();
		const validation = await data.triggerValidation();
		if (validation) {
			if (isPreview) {
				studentPaidByTypeApi
					.generateReport(params, isPreview)
					.then((res: any) => {
						if (res) {
							res.arrayBuffer().then((buffer: any) => {
								setPreview(buffer);
								setPreviewLoader(false);
							});
						} else setPreviewLoader(false);
					});
			} else await studentPaidByTypeApi.generateReport(params);
		}
	};

	const filterHandler = (data: any) => {
		if (data) {
			setValue({ startDate: data.startDate, endDate: data.endDate });
			setModel({ ...data });
		} else {
			setValue({ startDate: new Date(), endDate: new Date() });
			setModel({
				campusId: model.campusId,
				startDateRange: value.startDate as Date,
				endDateRange: value.endDate as Date,
				exportType: model.exportType,
				programVersionIds: model.programVersionIds,
				enrollmentStatusIds: model.enrollmentStatusIds,
			});
		}
	};

	const getReportParameters = async () => {
		const success = await data.triggerValidation();
		if (model && success) {
			let startDate = model.startDateRange
				? model.startDateRange
				: getFormattedDate(new Date());
			let endDate = model.endDateRange ? model.endDateRange : undefined;
			let params: IStudentPaidByTypeReportParams = {
				campusId: model.campusId,
				exportType: model.exportType,
				enrollmentStatusIds: model.enrollmentStatusIds,
			};
			return params;
		}
		return {} as IStudentPaidByTypeReportParams;
	};

	let parameters = (
		<div className={classes.root}>
			<FormContext {...data}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container direction="row" spacing={1}>
					<Grid item md={6} sm={6} xs={12}>
							<EnrollmentStatusAutoComplete
								filterHandle={(statuses: any) => {
									handleFieldOnChange(
										"enrollmentStatusIds",
										statuses,
										setEnrollmentStatus
									);
								}}
								id="enrollmentStatusIds"
								name="enrollmentStatusIds"
								//label="Enrollment Status"
								label="Enrollment Status *"
								multiple={true}
								error={!!data.errors.enrollmentStatusIds}
								inputRef={data.register({
									validate: {
										atLeastOneRequired: (value) =>
											(model.enrollmentStatusIds && model.enrollmentStatusIds.length > 0) ||
											"Status(es) are required.",
									},
								})}
								helperText={
									data.errors.enrollmentStatusIds
										? "At least one status is required."
										: undefined
								}
								valueFilter={
									model?.enrollmentStatusIds
										? {
												key: "value",
												values: model?.enrollmentStatusIds,
										  }
										: undefined
								}
							/>
						</Grid>
						<Grid item md={3} sm={3} xs={3}>
							<Autocomplete
								{...exportTypeAutoComplete}
								autoComplete
								includeInputInList
								onChange={(e: any, value: any) => {
									handleFieldOnChange(
										"exportType",
										value ? value : undefined,
										(v: any) => v?.id
									);
								}}
								value={getSelectedItem(exportTypes, model.exportType)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Export Type *"
										name="exportType"
										error={!!data.errors.exportType}
										inputRef={data.register({ required: true })}
										helperText={
											data.errors.exportType
												? "Export Type is required."
												: undefined
										}
										fullWidth
									/>
								)}
							/>
						</Grid>
					</Grid>
				</form>
			</FormContext>
		</div>
	);

	reportOverview.parameters = parameters;

	return (
		<ReportOverview
			reportOverview={reportOverview}
			filterHandler={filterHandler}
			getReportParameters={getReportParameters}
			exportHandler={onSubmit}
			preview={preview}
			previewLoader={previewLoader}
		/>
	);
};

export default StudentPaidByTypeReport;
