import * as React from "react";
import { useEffect, useRef } from "react";
import { apiAutoCompleteConfig } from "../../constants/apiAutoComplete.config";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../constants/actions.constants";
import { FilterParams } from "../../interfaces/common/FilterParams";

const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& label.Mui-focused": {
				color: theme.palette.primary.white
			},
			"& input": {
				color: theme.palette.black,
				opacity: 1,
				fontWeight: theme.typography.fontWeightMedium,
				fontSize: 14,
				padding: "10px"
			},
			"& input::placeholder": {
				fontWeight: theme.typography.fontWeightMedium,
				fontSize: 13
			},
			"& .MuiInputBase-root": {
				backgroundColor: theme.palette.site.secondary,
				borderRadius: 5
			}
		}
	})
)(TextField);

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {
			width: "100%"
		},
		searchIcon: {
			fontSize: 30,
			paddingLeft: 10,
			color: theme.palette.icon.primary,
			cursor: "pointer"
		}
	})
);

const ConseqTypIdAutoComplete = (props: FilterParams) => {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const inputRef: any = useRef(null);

	const filterHandle = props.filterHandle;

	const statusOnChange = (e: any) => {
		if (filterHandle) 
		filterHandle(e);
	};
	return (
		<div className={clsx(classes.divWidth)}>
			<ApiAutoComplete
				loading={true}
				config={apiAutoCompleteConfig.ConseqTyp}
				classes={{
					option: "text-black"
				}}
				label={props.label ? props.label : undefined}
				onChange={(e: any) => {
					statusOnChange(e);
				}}
				multiple={false}
				includeAllValue={props.includeAllValue ? props.includeAllValue : false}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
				inputRef={props.inputRef ? props.inputRef : inputRef}
				error={props.error}
				helperText={props.helperText}
				inputName={props.name}
			></ApiAutoComplete>
		</div>
	);
};
export default ConseqTypIdAutoComplete;
