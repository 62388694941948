import * as React from 'react';
import { Button, Checkbox, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, makeStyles, Switch, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import { selectors as adHocDetailsSelectors, actions as adHocDetailsActions } from '../../../store/reports/adhocReportDetails';
import { useSelector } from 'react-redux';
import { IAdhocReportField } from '../../../interfaces/reports/adhoc/IAdhocReportField';
import InputField from '../../../components/_Layout/Inputs/InputField';
import CancelIcon from '@material-ui/icons/Cancel';
import { useActions } from '../../../store/utils';
import { IAdhocRerportFilterOption } from '../../../interfaces/reports/adhoc/IAdHocReportOptions';
import { AdHocReportFilterOperation, CombineFilter } from '../../../enums/AdHocReportFilterOperation';
import { InputType } from '../../../constants/uiConstants/inputConstants';
import FilterLookUp from './FilterLookUp';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DateFnsUtils from '@date-io/date-fns';
import { format, isValid } from "date-fns";

const useStyles = makeStyles((theme: any) =>
    createStyles({
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        sortColumn: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end'
        },
        filterCombineSwitch: {
            marginRight: theme.spacing(1)
        },
        dialogue: {
            minWidth: 960
        },
        actions:{
            borderTop: '1px solid',
            borderTopColor: theme.palette.grey[500]
        }
    }));

interface IAdHocReportFilterConfiguration {
    open: boolean;
    close: () => void;
}

const operationOptions = [{
    value: AdHocReportFilterOperation.equal,
    label: '='
},
{
    value: AdHocReportFilterOperation.greaterEqual,
    label: '>='
},
{
    value: AdHocReportFilterOperation.lessEqual,
    label: '<='
},
{
    value: AdHocReportFilterOperation.notEqual,
    label: '<>'
}, {
    value: AdHocReportFilterOperation.between,
    label: 'Between'
}
    , {
    value: AdHocReportFilterOperation.any,
    label: 'Any of'
}
    , {
    value: AdHocReportFilterOperation.lessThan,
    label: '<'
}
    , {
    value: AdHocReportFilterOperation.greaterThan,
    label: '>'
}
    , {
    value: AdHocReportFilterOperation.notBetween,
    label: 'Not Between'
}
    , {
    value: AdHocReportFilterOperation.notAny,
    label: 'Not Any of'
}
    , {
    value: AdHocReportFilterOperation.contains,
    label: 'Contains'
}
    , {
    value: AdHocReportFilterOperation.notContains,
    label: 'Not Contains'
}
    , {
    value: AdHocReportFilterOperation.startsWith,
    label: 'Starts With'
}
    , {
    value: AdHocReportFilterOperation.endsWith,
    label: 'Ends With'
}
    , {
    value: AdHocReportFilterOperation.notStartsWith,
    label: 'Not Starts With'
}
    , {
    value: AdHocReportFilterOperation.notEndsWith,
    label: 'Not Ends With'
}];

const AdHocReportFilterConfiguration = (props: IAdHocReportFilterConfiguration) => {
    const classes = useStyles({});
    const selectedColumns: Array<IAdhocReportField> = useSelector(adHocDetailsSelectors.selectedColumns);
    const editFilters: Array<{ filterItem: IAdhocRerportFilterOption, allowed: Array<AdHocReportFilterOperation> }> = useSelector(adHocDetailsSelectors.filtersToEdit);
    const actions = useActions({
        addFilter: adHocDetailsActions.addFilterOption,
        setFiltersForEdit: adHocDetailsActions.setFiltersForEdit,
        apply: adHocDetailsActions.applyFilters,
        updateFilter: adHocDetailsActions.updateFilter,
        removeFilter: adHocDetailsActions.deleteFilterOption,
        deleteAll: adHocDetailsActions.deleteAllFilters
    }, []);

    React.useEffect(() => {
        if (props.open) {
            actions.setFiltersForEdit();
        }
    }, [props.open]);

    const applyFilters = () => {
        actions.apply();
        props.close();
    }

    React.useEffect(() => {
        if (!editFilters || editFilters.length === 0) {
            actions.addFilter(undefined, undefined, undefined, undefined)
        }
    }, [editFilters]);

    const renderInputField = (field: IAdhocReportField | undefined, filter: AdHocReportFilterOperation, value: any, setValue: (newValue: any) => void, fieldLabel: string) => {
        if (!field) {
            return <></>;
        }
        switch (field.fieldType) {
            case 'Boolean':
                return <Checkbox color='primary' checked={value === 1} onChange={() => setValue(value === 1 ? 0 : 1)} />;
            case 'Number':
                return <InputField
                    type={InputType.NUMBER}
                    decimal
                    decimalPlaces={2}
                    InputProps={
                        {
                            startAdornment: (field && field.prefix && <InputAdornment position="start"> {field?.prefix}</InputAdornment>) || undefined,
                            endAdornment: (field && field.postfix && <InputAdornment position="end"> {field?.prefix}</InputAdornment>) || undefined
                        }
                    }
                    InputLabelProps={{ shrink: true }} label={fieldLabel}
                    defaultValue={value} onBlur={(e) => e && e.target && setValue(e.target.value)} />
            case 'Lookup':
                return <FilterLookUp fieldName={field.name} values={value} onChnage={(value) => setValue(value)} />
            case 'Date':
                return <MuiPickersUtilsProvider utils={DateFnsUtils}><KeyboardDatePicker value={(value || null)} label={fieldLabel} variant="inline" format="MM/dd/yyyy" onChange={(date: MaterialUiPickersDate): void => {
                    if (date && isValid(date)) {
                        setValue(format(date, 'MM-dd-yyyy'));
                    }
                }} />
                </MuiPickersUtilsProvider>
            default:
                return <InputField
                    InputLabelProps={{ shrink: true }} label={fieldLabel}
                    defaultValue={value} onBlur={(e) => e && e.target && setValue(e.target.value)}
                    InputProps={
                        {
                            startAdornment: (field && field.prefix && <InputAdornment position="start"> {field?.prefix}</InputAdornment>) || undefined,
                            endAdornment: (field && field.postfix && <InputAdornment position="end"> {field?.prefix}</InputAdornment>) || undefined
                        }
                    } />;
        }
    }

    return (<Dialog open={props.open} onClose={props.close} maxWidth="md" classes={{ paper: classes.dialogue }}>
        <DialogTitle id="customized-dialog-title">
            <Typography variant="h6">Filter</Typography>

            <IconButton aria-label="close" className={classes.closeButton} onClick={props.close}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <Grid container justifyContent="flex-end">
                <Grid item>
                    <Button variant="text" color="primary" size="small" onClick={actions.deleteAll}>
                        Clear All Filter(s)
                    </Button>
                </Grid>
            </Grid>
            <DialogContentText>
                Select the columns by which data should be filtered
            </DialogContentText>
            <Grid container spacing={2}>
                {
                    editFilters.map((f, index) => <Grid item xs={12}>
                        <Grid container spacing={1}>
                            {
                                index > 0 && <Grid item xs={12}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <FormControlLabel
                                                    control={<Switch className={classes.filterCombineSwitch} color="primary" checked={f.filterItem.combineAs === CombineFilter.or} onChange={() => actions.updateFilter(index, { ...f, combineAs: f.filterItem.combineAs === CombineFilter.or ? CombineFilter.and : CombineFilter.or })} />}
                                                    label="And"
                                                    labelPlacement="start"
                                                />}
                                            label="Or"
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </Grid>
                            }
                            <Grid item xs={3}>
                                <Autocomplete
                                    options={selectedColumns}
                                    renderInput={(params) => <InputField {...params} label="Column" />}
                                    getOptionLabel={(opt) => opt.name}
                                    getOptionSelected={(option, value) => (value && (option.id === value.id))}
                                    onChange={(_e: any, value: any) => actions.updateFilter(index, { ...f.filterItem, fieldId: value?.id, valueEnd: '', valueStart: undefined, type: undefined })}
                                    value={selectedColumns.find(m => m.id === f.filterItem.fieldId)} />
                            </Grid>
                            {!!f.filterItem.fieldId && selectedColumns.find(m => m.id === f.filterItem.fieldId)?.fieldType !== 'Boolean' && <Grid item xs={2}>
                                <Autocomplete
                                    options={operationOptions.filter((op) => {
                                        return f.allowed.includes(op.value);
                                    })}
                                    renderInput={(params) => <InputField {...params} label="Operation" />}
                                    getOptionLabel={(opt) => (operationOptions.find(m => m.value === opt.value) || { label: '' }).label}
                                    getOptionSelected={(option, value) => (!!value && (option.value === value.value))}
                                    onChange={(_e: any, value: any) => actions.updateFilter(index, { ...f.filterItem, type: value.value })}
                                    value={{ value: f.filterItem.type, label: '' }}
                                    disableClearable />
                            </Grid>}
                            {f.filterItem.type !== undefined && <React.Fragment>
                                <Grid item xs={selectedColumns.find(m => m.id === f.filterItem.fieldId)?.fieldType === 'Lookup' ? 6 : 3} >
                                    {
                                        renderInputField(selectedColumns.find(m => m.id === f.filterItem.fieldId), f.filterItem.type, f.filterItem.valueStart, (val: any) => actions.updateFilter(index, { ...f.filterItem, valueStart: val }), (f.filterItem.type === AdHocReportFilterOperation.between || f.filterItem.type === AdHocReportFilterOperation.notBetween) ? "From" : "Value")
                                    }
                                </Grid>
                                {(f.filterItem.type === AdHocReportFilterOperation.between || f.filterItem.type === AdHocReportFilterOperation.notBetween) && <Grid item xs={3}>
                                    {
                                        renderInputField(selectedColumns.find(m => m.id === f.filterItem.fieldId), f.filterItem.type, f.filterItem.valueEnd, (val: any) => actions.updateFilter(index, { ...f.filterItem, valueEnd: val }), "To")
                                    }
                                </Grid>}

                            </React.Fragment>}
                            {!(editFilters.length === 1 && (!f.filterItem.combineAs)) &&
                                <Grid item xs={1} className={classes.sortColumn}>
                                    <IconButton key={`sorting-item-actions-delete${index}`} size="small" onClick={() => actions.removeFilter(index)}>
                                        <CancelIcon key={`sorting-item-actions-delete-icon${index}`} />
                                    </IconButton>
                                </Grid>
                            }
                        </Grid>
                    </Grid>)
                }
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        {
                            editFilters.length > 0 && <Grid item xs={12}>
                                <Button variant="text" color="primary" size="small" onClick={() => actions.addFilter(undefined, undefined, undefined, undefined, CombineFilter.and)}>
                                    Add Filter
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Divider />

            </Grid>
        </DialogContent>
        <DialogActions className={classes.actions}>
            <Grid container>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={applyFilters} disabled={!!editFilters && (editFilters.length > 0) && (editFilters.findIndex(f => (!f.filterItem || !f.filterItem.fieldId || f.filterItem.type === undefined || f.filterItem.type === null) && !!f.filterItem.combineAs) >= 0)}>
                        Apply
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog >)
}

export default AdHocReportFilterConfiguration;