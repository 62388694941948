import * as React from "react";
import { useEffect, useRef } from "react";
import { apiAutoCompleteConfig } from "../../constants/apiAutoComplete.config";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../constants/actions.constants";
import { FilterParams } from "../../interfaces/common/FilterParams";

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& label.Mui-focused": {
        color: theme.palette.primary.white
      }
    }
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {}
  })
);

const DependencyAutoComplete = (props: FilterParams) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const [DependencySelected, setDependencySelected] = React.useState(false);
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const inputRef: any = useRef(null);

  const filterHandle = props.filterHandle;

  const magnifyOnClick = (e: any) => {
    if (inputRef.current !== undefined) {
      inputRef.current.children[1].focus();
    }
  };

  const dependencyOnChange = (e: any) => {
    if (filterHandle) {
      filterHandle(e);
    }

    setDependencySelected(e);
  };

  return (
    <div>
      <ApiAutoComplete
        config={apiAutoCompleteConfig.dependency}
        loading={userSelectedCampus ? false : true}
        reqParams={{ campusId: userSelectedCampus }}
        classes={{
          option: "text-black"
        }}
        label={props.hideLabel ? undefined : props.label ? props.label : "Dependency"}
        onChange={(e: any) => {
          dependencyOnChange(e);
        }}
        multiple={props.multiple ?? false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        maxTags={props.maxTags}
        includeAllValue={props.includeAllValue}
        showIncludeAllValue={props.showIncludeAllValue}
        chipSize={props.chipSize}
        inputName={props.name}
        inputRef={props.inputRef ? props.inputRef : inputRef}
        error={props.error}
        helperText={props.helperText}
      ></ApiAutoComplete>
    </div>
  );
};
export default DependencyAutoComplete;
