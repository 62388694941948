import * as React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";
import {
  FormControlLabel,
  Switch
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CampusForCurrentUserAutoComplete from "../../../components/AutoComplete/CampusForCurrentUserAutoComplete";
import { Grid } from "@material-ui/core";
import { FormContext, useForm } from "react-hook-form";
import ProgramVersionAutoComplete from "../../../components/AutoComplete/ProgramVersionAutoComplete";
import { MonthlyAccrualsAccountReceivableParams } from "../../../interfaces/reports/studentAccounts/MonthlyAccrualsAccountReceivableParams";
import * as monthlyAccrualsApi from "../../../api/reports/studentAccounts/monthlyAccrualsApi";
import { ReportOutput } from "../../../enums/ReportOutput";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import EnrollmentStatusAutoComplete from "../../../components/AutoComplete/EnrollmentStatusAutoComplete";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { system } from "../../../constants/system/systemConstants";
import { debug } from "console";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      minHeight: "100%",
      height: "100%",
      maxWidth: "1050px",
      padding: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    searchActions: {
      marginTop: theme.spacing(3),
      textAlign: "left",
    },
    startDateField: {
      width: "100%",
    },
  })
);

const getFormattedDate = (date: Date) => {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return month + "/" + day + "/" + year;
};

const AccrualsARExportReport = (props: any) => {

  const { report } = props;
  console.log('PropsAccrualsARExportReport:')
  console.log(props)
  console.log('AccrualsARExportReport:')
  console.log(report)
  let reportOverview = { report: report } as IReportOverview;
  const classes = useStyles({});
  const [preview, setPreview] = React.useState<any>();
  const [previewLoader, setPreviewLoader] = React.useState<boolean>();

  const data = useForm<any>({ mode: "onBlur" });
  const appSettings = useSelector(
    (state: any) => {  return state.settings.settings }
  ); 

  const [model, setModel] = React.useState({
    campusId: "",
    programId: [],
    enrollmentStatusIds: ['All'],
    refDate: undefined as Date | undefined,
    customEndDate: undefined as Date | undefined,
    reportType: "1",
    exportType: 1 as ReportOutput,
    balanceOwedIsGreaterThenTotalCost: true as boolean,
  });

  const balanceOwedSetting = appSettings[system.settings.balanceOwedIsGreaterThenTotalCost] as string
  let balanceOwedEnabled = false;

  if (balanceOwedSetting) {
    balanceOwedEnabled = balanceOwedSetting.toLowerCase() === 'true'
  }
  useEffect(() => {
    let updatedModel = model;
    (updatedModel as any)["balanceOwedIsGreaterThenTotalCost"] = balanceOwedEnabled;
    setModel({ ...updatedModel });
  }, [balanceOwedEnabled])

  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );
  useEffect(() => {
    let updatedModel = model;
    (updatedModel as any)["campusId"] = userSelectedCampus;
    (updatedModel as any)["programId"] = undefined;
    setModel({ ...updatedModel });
  }, [userSelectedCampus])

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    if (fieldId == "campusId") {
      (updatedModel as any)["programId"] = undefined;
    }
    setModel({ ...updatedModel });
  };

  const { handleSubmit } = data;

  const reportTypes: Array<DropDownListItem> = [
    { text: "Monthly Accruals and Accounts Receivable", id: "0" },
    { text: "Monthly Accruals", id: "1" },
    { text: "Accounts Receivable", id: "2" },
  ];

  const exportTypes: Array<DropDownListItem> = [
    { text: "Excel", id: "2" },
    { text: "PDF", id: "1" },
  ];

  const onSubmit = async (isPreview: any) => {
    let params = (await getReportParameters()) as MonthlyAccrualsAccountReceivableParams;
    const validation = await data.triggerValidation();
    if (validation) {
      if (isPreview) {
        setPreviewLoader(true);
        monthlyAccrualsApi
          .generateReport(params, isPreview)
          .then((res: any) => {
            if (res) {
              res.arrayBuffer().then((buffer: any) => {
                setPreview(buffer);
                setPreviewLoader(false);
              });
            } else setPreviewLoader(false);
          });
      } else await monthlyAccrualsApi.generateReport(params);
    }
  };

  const reportTypeAutoComplete = {
    options: reportTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const exportTypeAutoComplete = {
    options: exportTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const filterHandler = (data: any) => {
    if (data) {
      setModel(data);
    } else {
      setModel({
        campusId: "",
        programId: [],
        enrollmentStatusIds: ['All'],
        refDate: undefined as Date | undefined,
        customEndDate: undefined as Date | undefined,
        reportType: "1",
        exportType: 1 as ReportOutput,
        balanceOwedIsGreaterThenTotalCost: true as boolean
      });
    }
  };

  const setProgramVersion = (PrgVersions: any) => {
    let newProgramVersion: string[] = [];
    if (PrgVersions !== null) {
      if (Array.isArray(PrgVersions)) {
        newProgramVersion = PrgVersions.map((gr: any) => gr.value);
      } else {
        newProgramVersion.push(PrgVersions.value);
      }
    }
    return newProgramVersion;
  };

  const setEnrollmentStatus = (enrollmentStatus: any) => {
    let newStatuses: string[] = [];
    if (enrollmentStatus !== null) {
      if (Array.isArray(enrollmentStatus)) {
        if (enrollmentStatus.length > 1) {
          enrollmentStatus = enrollmentStatus.filter((item: any) => item.value !== 'All');
        }
        else if (enrollmentStatus.length == 0) {
          enrollmentStatus.push({ text: 'Include All', value: 'All' })
        }
        newStatuses = enrollmentStatus.map((gr: any) => gr.value);
      } else {
        newStatuses.push(enrollmentStatus.value);
      }
    }
    return newStatuses;
  };

  const getReportParameters = async () => {
    const success = await data.triggerValidation();
    if (model && success) {
      let refDate = model.refDate
        ? model.refDate
        : getFormattedDate(new Date());
      let customEndDate = model.customEndDate ? model.customEndDate : undefined;
      let enrollmentStatus = model.enrollmentStatusIds.filter((item: any) => item !== 'All');

      let params: MonthlyAccrualsAccountReceivableParams = {
        campusId: model.campusId,
        programId: model.programId,
        enrollmentStatusIds: enrollmentStatus,
        refDate: refDate,
        customEndDate: customEndDate,
        exportType: model.exportType,
        reportType: model.reportType,
        sortOrder: 1,
        balanceOwedIsGreaterThenTotalCost: model.balanceOwedIsGreaterThenTotalCost
      };
      return params;
    }
    return {} as MonthlyAccrualsAccountReceivableParams;
  };

  const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
    const item = items.find((opt) => {
      if (opt.id == value) return opt;
    });
    return item || ({} as DropDownListItem);
  };

  let parameters = (
    <div className={classes.root}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} sm={12} xs={12}>
              <CampusForCurrentUserAutoComplete
                id="campusId"
                name="campusId"
                label="Campus *"
                filterHandle={(v: any) => {
                  handleFieldOnChange("campusId", v ? v.value : undefined);
                }}
                valueFilter={
                  model.campusId
                    ? {
                      key: "value",
                      values: [model.campusId],
                    }
                    : undefined
                }
                error={!!data.errors.campusId}
                inputRef={data.register({ required: true })}
                helperText={
                  data.errors.campusId ? "Campus is required." : undefined
                }
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <EnrollmentStatusAutoComplete
                filterHandle={(statuses: any) => {
                  handleFieldOnChange(
                    "enrollmentStatusIds",
                    statuses,
                    setEnrollmentStatus
                  );
                }}
                includeAllValue={true}
                defaultOptionText={"Include All"}
                id="enrollmentStatusIds"
                name="enrollmentStatusIds"
                label="Enrollment Status"
                valueFilter={
                  model?.enrollmentStatusIds
                    ? {
                      key: "value",
                      values: model?.enrollmentStatusIds,
                    }
                    : undefined
                }
                multiple={true}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <ProgramVersionAutoComplete
                selectAll={true}
                campusId={model.campusId}
                name="programId"
                filterHandle={(value: any) =>
                  handleFieldOnChange("programId", value, setProgramVersion)
                }
                valueFilter={
                  model.programId
                    ? {
                      key: "value",
                      values: model.programId,
                    }
                    : undefined
                }
                multiple={true}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="refDate"
                  name="refDate"
                  label="Report Date"
                  value={model.refDate}
                  onChange={(e: any, value: any) =>
                    handleFieldOnChange("refDate", value ? value : undefined)
                  }
                  className={classes.startDateField}
                  KeyboardButtonProps={{
                    "aria-label": "report date",
                  }}
                  error={!!data.errors.refDate}
                  inputRef={data.register({ required: true })}
                  helperText={
                    data.errors.refDate ? "Report Date is required." : undefined
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="customEndDatePicker"
                  label="Custom End Date"
                  value={model.customEndDate ? model.customEndDate : null}
                  onChange={(e: any, value: any) =>
                    handleFieldOnChange(
                      "customEndDate",
                      value ? value : undefined
                    )
                  }
                  className={classes.startDateField}
                  KeyboardButtonProps={{
                    "aria-label": "custom end date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Autocomplete
                {...reportTypeAutoComplete}
                autoComplete
                includeInputInList
                value={getSelectedItem(reportTypes, model.reportType)}
                onChange={(e: any, value: any) =>
                  handleFieldOnChange(
                    "reportType",
                    value ? value : undefined,
                    (v: any) => v.id
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Report Type"
                    name="reportType"
                    error={!!data.errors.reportType}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.reportType
                        ? "Report Type is required."
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item md={6} sm={6} xs={12}>
              <Autocomplete
                {...exportTypeAutoComplete}
                autoComplete
                includeInputInList
                value={getSelectedItem(
                  exportTypes,
                  model.exportType.toString()
                )}
                onChange={(e: any, value: any) =>
                  handleFieldOnChange(
                    "exportType",
                    value ? value : undefined,
                    (v: any) => v.id
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Export Type"
                    name="exportType"
                    error={!!data.errors.exportType}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.exportType
                        ? "Export Type is required."
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <FormControlLabel
                label="Balance due can't be greater than Total Cost"
                control={
                  <Switch
                    checked={model.balanceOwedIsGreaterThenTotalCost}
                    value="balanceOwedIsGreaterThenTotalCost"
                    onChange={(e: any) => {
                      handleFieldOnChange(
                        "balanceOwedIsGreaterThenTotalCost",
                        e ? e.target.checked : undefined
                      );
                    }}
                    color="primary"
                  />
                }
              />
            </Grid>
          </Grid>
        </form>
      </FormContext>
    </div>
  );
  reportOverview.parameters = parameters;

  return (
    <ReportOverview
      reportOverview={reportOverview}
      filterHandler={filterHandler}
      getReportParameters={getReportParameters}
      exportHandler={onSubmit}
      preview={preview}
      previewLoader={previewLoader}
    />
  );
};

export default AccrualsARExportReport;
