import React from "react";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import { TextField, Grid, Button, DialogActions } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PeriodTypeAutoComplete from "../../../../../components/AutoComplete/PeriodTypeAutoComplete";
import PaymentPeriodAutoComplete from "../../../../../components/AutoComplete/PaymentPeriodAutoComplete";
import { PeriodTypes } from "../../../../../enums/PeriodTypes";
import * as ledgerApi from "../../../../../api/student/studentAccounts/ledgerApi";
import { ICharge } from "../../../../../interfaces/student/financials/ICharge";
import { useSelector } from "react-redux";
import { useForm, FieldError, FormContext } from "react-hook-form";
import { Enrollment } from "../../../../../interfaces/student/academics/Enrollment";
import { EmptyGuid } from "../../../../../utils/constants";
import ProgressSaveButton from "../../../../../components/_Layout/Buttons/ProgressSaveButton";
import CurrencyInput from "../../../../../components/_Layout/Inputs/CurrencyInput";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      width: "100%",
    },
    fullWidthField: {
      width: "100%",
    },
    buttonSection: {
      marginTop: theme.spacing(3),
    },
    saveButton: {
      width: 90,
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
  })
);

const ThemeTextField = withStyles((theme: any) => createStyles({}))(TextField);

type SnackBarStateProps = {
  showSnackBar: boolean;
  messageInfo: string | undefined;
  variant: "success" | "error" | "warning" | "info";
};

export interface ChargeProps {
  transactionType: any;
  setPopupState: any;
  setTransactionTypeValue: any;
  setSnackBar: any;
  refreshLedgerItems: any;
  enrollmentId: string;
  creditDebit: any;
}

const Charge = (props: ChargeProps) => {
  const classes = useStyles({});
  const data = useForm<any>({
    mode: "onBlur",
  });
  const { handleSubmit } = data;
  const [loaderState, setLoaderState] = React.useState<boolean>(false);
  const [model, setModel] = React.useState<ICharge>({
    transactionDate: new Date().toLocaleDateString(),
    amount: undefined,
    transactionCodeId: props.transactionType.value,
    transactionId: EmptyGuid,
    studentEnrollmentId: props.enrollmentId,
    periodType: null,
    paymentPeriod: null,
    creditDebit: props.creditDebit,
  });

  const handleFieldOnChange = (fieldId: string, value: any) => {
    let updatedModel = model;
    if (fieldId === 'transactionDate'){
      (updatedModel as any)[fieldId] = new Date(value).toLocaleDateString();
      setModel({ ...updatedModel });
    }else { 
      (updatedModel as any)[fieldId] = value;
      setModel({ ...updatedModel });}

  };

  const unFormattedNumber = (formattedNumber: any) => {
    const cleanedInput = (formattedNumber + "").replace(/[^\d.-]/g, "");
    const result = Number(cleanedInput);
    return result;
  };
  const onAmountChanged = (chargeAmount: any) => {
    return (
      unFormattedNumber(chargeAmount) > 0 || "Amount must be greater than 0"
    );
  };
  const onSubmit = (d: any) => {
    if (model) {
      setLoaderState(true);
      ledgerApi.postCharge(model).then(
        (response: any) => {
          if (response.resultStatus === 0) {
            setLoaderState(false);
            props.setSnackBar((props: any) => {
              return {
                variant: "success",
                showSnackBar: true,
                messageInfo: "Charge posted.",
              };
            });
            handleClose();
          } else {
            setLoaderState(false);
            props.setSnackBar((props: any) => {
              return {
                variant: "error",
                showSnackBar: true,
                messageInfo: "Charge failed to post. Please try again.",
              };
            });
          }
        },
        (exception: any) => {
          setLoaderState(false);
          props.setSnackBar((snackBarProps: any) => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo: exception,
            };
          });
        }
      );
    }
  };

  const handleClose = () => {
    props.setPopupState(false);
    props.setTransactionTypeValue(null);
    props.refreshLedgerItems();
  };

  return (
    <FormContext {...data}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id="transactionDate"
                  name="transactionDate"
                  label="Transaction Date *"
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  TextFieldComponent={ThemeTextField}
                  className={classes.fullWidthField}
                  value={model.transactionDate}
                  autoOk={true}
                  onChange={(e: any) => {
                    handleFieldOnChange("transactionDate", e);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <PeriodTypeAutoComplete
                id="periodType"
                name="periodType"
                label="Period Type *"
                valueFilter={
                  model.periodType != undefined
                    ? {
                        key: "value",
                        values: [model.periodType],
                      }
                    : undefined
                }
                error={!!data.errors.periodType}
                inputRef={data.register({ required: true })}
                helperText={
                  data.errors.periodType
                    ? "Period type is required."
                    : undefined
                }
                filterHandle={(v: any) => {
                  handleFieldOnChange("periodType", v ? v.value : undefined);
                  handleFieldOnChange("paymentPeriod", undefined);
                }}
              />
            </Grid>
            {model.periodType !== PeriodTypes.ProgramVersion && (
              <Grid item xs={12}>
                <PaymentPeriodAutoComplete
                  id="paymentPeriod"
                  name="paymentPeriod"
                  label="Period *"
                  periodType={model.periodType}
                  valueFilter={
                    model.paymentPeriod
                      ? {
                          key: "value",
                          values: [model.paymentPeriod],
                        }
                      : undefined
                  }
                  error={!!data.errors.paymentPeriod}
                  inputRef={data.register({ required: true })}
                  helperText={
                    data.errors.paymentPeriod
                      ? "Payment period is required."
                      : undefined
                  }
                  filterHandle={(v: any) => {
                    handleFieldOnChange(
                      "paymentPeriod",
                      v ? v.value : undefined
                    );
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <CurrencyInput
                control={data.control}
                className={classes.textField}
                label="Amount *"
                name="amount"
                key="amount"
                onChange={handleFieldOnChange}
                value={model.amount}
                error={!!data.errors.amount}
                creditDebit={props.creditDebit}
                inputRef={data.register({
                  required: "Amount is required.",
                  validate: {
                    valueGreaterThanZero: onAmountChanged,
                  },
                })}
                helperText={
                  data.errors.amount
                    ? (data.errors.amount as FieldError).message
                    : undefined
                }
              />
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid item xs={12}>
            <DialogActions className={classes.buttonSection}>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={2}></Grid>
                <Grid
                  item
                  container
                  xs={4}
                  alignContent="center"
                  alignItems="center"
                  justify="center"
                >
                  <ProgressSaveButton
                    text="Save"
                    onClick={() => {
                      handleSubmit(onSubmit);
                    }}
                    loading={loaderState}
                    buttonClassName={classes.saveButton}
                  ></ProgressSaveButton>
                </Grid>
                <Grid
                  item
                  container
                  xs={4}
                  alignContent="center"
                  alignItems="center"
                  justify="center"
                >
                  <Button
                    onClick={handleClose}
                    color="secondary"
                    variant="contained"
                    type="button"
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
            </DialogActions>
          </Grid>
        </div>
      </form>
    </FormContext>
  );
};

export default Charge;
