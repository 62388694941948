import * as React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Campus } from "../../interfaces/systemCatalog/ICampus";
import EnrollmentGroupAutoComplete from "../../components/AutoComplete/EnrollmentGroupAutoComplete";
const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      "& .MuiCardContent-root": {
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(2),
      },
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    filter: {
      width: "10%",
      padding: theme.spacing(1),
      display: "inline-block",
    },
  })
);

interface IModel {
  enrollmentGroup?: string[];
}

interface IEnrollmentGroupFilterProps {
  model: IModel;
  show?: boolean;
  setModel: (values: IModel) => void;
}

const EnrollmentGroupFilter = (props: IEnrollmentGroupFilterProps) => {
  const classes = useStyles({});
  const { model, setModel } = props;
  if (props.show) {
    return (
      <div className={classes.filter}>
        <EnrollmentGroupAutoComplete multiple
        filterHandle={
          (values: any[]) =>
            setModel({ enrollmentGroup: values.map((v: { value: string }) => v.value) })
        }
        valueFilter={
          model && model.enrollmentGroup
            ? {
              key: "value",
              values: model.enrollmentGroup,
            }
            : undefined
        } />
      </div>
    )

  } else {
    return <div className={classes.filter}></div>;
  }
};

export default EnrollmentGroupFilter;
