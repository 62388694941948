import React from "react";
import clsx from "clsx";
import {
    createStyles,
    Theme,
    makeStyles,
    withStyles,
} from "@material-ui/core/styles";
import { Link as RouterLink } from 'react-router-dom';
import ApiVirtualizedTable from "../../../components/_Layout/ApiTables/ApiVirtualizedTable";
import { apiVirtualizedTableConfig } from "../../../constants/apiVirtualizedTable.config";
import { TableCellRenderer, TableRowRenderer } from "react-virtualized";
import { LeadSearchInputPaged } from "../../../interfaces/admissions/LeadSearchInputPaged";
import { useSelector } from "react-redux";
import { Tooltip, TableCell, Checkbox, Avatar } from "@material-ui/core";
import { ILead } from "../../../interfaces/admissions/ILead";
import { v5 as uuid } from "uuid";
import { ILeadEmail } from "../../../interfaces/admissions/ILeadEmail";
import { ILeadAddress } from "../../../interfaces/admissions/ILeadAddress";
import { ILeadPhone } from "../../../interfaces/admissions/ILeadPhone";

const useStyles = makeStyles((theme: any) => ({
    root: {},
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    content: {
        padding: 0,
        height: '100%'
    },
    inner: {
        height: '100%',
        width: '100%',
        minWidth: 700
    },
    nameCell: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        height: 42,
        width: 42,
        marginRight: theme.spacing(1)
    },
    actions: {
        padding: theme.spacing(1),
        justifyContent: 'flex-end'
    },
    tableCell: {
        flex: 1,
        whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'
    },
    noClick: {
        cursor: 'initial',
    },
    marginRight: {
        marginRight: theme.spacing(1)
    },
    redText: {
        color: theme.palette.error.main
    },
    typography: {
        padding: theme.spacing(2),
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    popOverButtons: {
        justifyContent: 'space-evenly',
        padding: 5
    }

}));

export interface LeadImportGridProps {
    params: LeadSearchInputPaged | null;
    setSelectedLead?: (val: ILead | undefined) => any;
    meta? : ILead ;
}

const LeadImportGrid = (props: LeadImportGridProps) => {
    const classes = useStyles({});
    const userTableRef = React.useRef<any>();
    const [selectedLead, setSelectedLead] = React.useState<ILead | undefined>(props.meta);
    const parentSetSelectedLead = props.setSelectedLead;
    const config: any = { ...apiVirtualizedTableConfig.leadSearchPaged };
    const userSelectedCampus: string = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );

    const session = useSelector((state: any) => state.session);
    const appConfig: any = session.appConfig.appConfig;
   
    const handleSelectOne = (event: any, lead: ILead) => {
        const isSelected = selectedLead?.id == lead.id

        if (isSelected) {
            setSelectedLead(undefined);

            if (parentSetSelectedLead)
                parentSetSelectedLead(undefined);
        }

        else {
            setSelectedLead(lead);

            if (parentSetSelectedLead)
                parentSetSelectedLead(lead);
        }
    };

    if (
        userSelectedCampus !== undefined &&
        userSelectedCampus !== null &&
        userSelectedCampus !== ""
    ) {
        config.route = config.route + "?campusId=" + userSelectedCampus;
    }

    config.params = props.params;


    const refreshData = (hardReload?: boolean) => {
        if (userTableRef && userTableRef.current) {
            if (hardReload) {
                userTableRef.current.hardRefresh();
            } else {
                userTableRef.current.refresh();
            }
        }
    };

    return (
        <ApiVirtualizedTable
            ref={userTableRef}
            config={config}
            rowHeight={80}
            cellDataGetter={({ rowData, dataKey }) => {
                if (rowData) return rowData[dataKey];
            }}
            columns={[
                {
                    width: 30,
                    label: '',
                    dataKey: 'fullName',
                    cellRenderer: ({ cellData, rowData, columnIndex }) => {
                        if (rowData) {
                            var first_name = rowData.name.split(' ')[0];
                            var last_name = rowData.name.substring(first_name.length).trim();
                            console.log(rowData);
                            const rowLead: ILead = {
                                id: uuid(rowData.id, 'd9eed29e-83ad-4d84-b1d2-82748a6a8c06'),
                                externalEntityId: rowData.externalEntityId,
                                campusId: userSelectedCampus,
                                highSchool: rowData.highschool,
                                birthDate: rowData.birthday,
                                gender: rowData.gender,
                                maritalStatus: rowData.maritalStatus,
                                fullName: rowData.name,
                                firstName: first_name,
                                lastName: last_name,
                                status: rowData.leadStatus,
                                program: rowData.studentProgram,
                                email: rowData.email,
                                phone: rowData.phone,
                                address1: rowData.address,
                                address2: rowData.address2,
                                city: rowData.city,
                                zip: rowData.zip,
                                state: rowData.state,
                                photoUrl: appConfig.CrmImageUrl + rowData.photo,
                                emails: [{ email: rowData.email } as ILeadEmail],
                                phones: [{ phone: rowData.phone } as ILeadPhone],
                                addresses: [{ address1: rowData.address, address2: rowData.address2, city: rowData.city, zip: rowData.zip, state: rowData.state } as ILeadAddress],

                            }
                            const isSelected = selectedLead?.id == rowLead.id
                            return (
                                <TableCell
                                    component="div"
                                    className={clsx(classes.tableCell, classes.flexContainer)}
                                    variant="body"
                                >
                                    <Checkbox
                                        checked={isSelected}
                                        onChange={(event: any) => handleSelectOne(event, rowLead)}
                                        value="primary"
                                        color="primary"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </TableCell>
                            )
                        }
                    }
                },
                {
                    width: 30,
                    label: '',
                    dataKey: 'fullName',
                    cellRenderer: ({ cellData, rowData, columnIndex }) => {
                        if (rowData) {
                            var first_name = rowData.name.split(' ')[0];
                            var last_name = rowData.name.substring(first_name.length).trim();
                            return (
                                <TableCell
                                    component="div"
                                    className={clsx(classes.tableCell, classes.flexContainer)}
                                    variant="body"
                                >
                                    <Avatar
                                        alt={first_name + last_name}
                                        className={classes.avatar}
                                        src={appConfig.CrmImageUrl + rowData.photo}
                                    />
                                </TableCell>
                            )
                        }
                    }
                },
                {
                    width: 70,
                    label: "Full Name",
                    dataKey: "name",
                },

                {
                    width: 70,
                    label: "Email",
                    dataKey: "email",
                },
                {
                    width: 40,
                    label: "Phone",
                    dataKey: "phone",
                },
                {
                    width: 60,
                    label: "Address",
                    dataKey: "fullAddress",
                },
                {
                    width: 80,
                    label: "Program",
                    dataKey: "studentProgram",
                },
                {
                    width: 80,
                    label: "Status",
                    dataKey: "status",
                },
            ]}
        />
    );
};
export default LeadImportGrid;
