import palette from "../mainPalette";

export default {
  root: {
    "&$selected": {
      backgroundColor: palette.secondary.main
    },
    "&$hover": {
      backgroundColor: palette.secondary.main,
      "&:hover": {
        backgroundColor: palette.secondary.main
      }
    },
    "&:hover": {
      backgroundColor: palette.secondary.main
    }
  },
  hover: {
    "&:hover": { backgroundColor: palette.secondary.mainImportant }
  }
};
