import API from "../../apiWrapper";
import { IProgramCatalogDetails } from "../../../interfaces/setup/academics/ProgramProgramVersion/IProgramCatalogDetails";
export const GetProgramCatalogDetails = (ProgCatYearId: string) => {
  return API()
    .get("/AcademicRecords/ProgramCatalog/GetProgramCatalogDetails", {
      params: {
        ProgCatYearId
      }
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const upsertProgramCatalog = (model: IProgramCatalogDetails) => {
  return API()
    .post("/AcademicRecords/ProgramCatalog/UpsertProgramCatalog", model, {
			responseType: "json",
		})
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const GetSchedules = () => {
  return API()
    .get("/AcademicRecords/ProgramCatalog/GetScheduleDetails")
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};

export const GetProgramVersionChargingMethodInformation = (programVersionId: string) => {
  return API()
    .get("/AcademicRecords/ProgramCatalog/GetProgramVersionCharginMethodInformation", {
      params: {
        programVersionId
      }
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};