import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Dialog,TextField,	DialogTitle,DialogContent,Portal} from "@material-ui/core";
import { useForm, FormContext } from "react-hook-form";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {Typography,Grid,Button} from "@material-ui/core";
import ProgressSaveButton from "../../../components/_Layout/Buttons/ProgressSaveButton";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IStatusHistoryDeletionOption } from "../../../interfaces/student/status/IStatusHistoryDeletionOption";
import { useSelector } from "react-redux";
import * as CurrentlyAttendingApi from "../../../api/student/status/CurrentlyAttendingApi";
import { format} from "date-fns";

type AddNewStatusProps = {
    open: boolean;
    setOpen : any;
    deletionReasonCallback : any;
    confirmationMessage : string,
    isLoading : boolean
};

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			"& .MuiPaper-rounded": {
				borderRadius: theme.spacing.borderradius,
			},
			"& .MuiDialogContent-root": {
				minHeight: 145,
			},
		},
		form: {
			display: 'flex',
			flexDirection: 'column',
			width: 'fit-content',
			minWidth: 100,
		  },
		dialogHeader: {
			backgroundColor: theme.palette.site.secondary,
			"& .MuiTypography-root": {
				fontSize: theme.spacing(2),
			},
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
		},
		detailWrapper: {
			padding: theme.spacing(.5),
        },
        reasonTb : {
            width : '100%'
        },
        cancelBtn : {
            marginLeft : theme.spacing(2)
        }
	})
);

const RemoveReasonModal = (props: AddNewStatusProps) => {
	const classes = useStyles({});
    const [changeOptionData, setChangeOptionData] = React.useState<Array<IStatusHistoryDeletionOption>>([]);
    const [optionValue, setOptionValue] = React.useState<string>( "");
    const validation = useForm<any>({ mode: "onBlur" });

	const handleClose = () => {
        props.setOpen(false);
	};

    const refreshOptions=()=>{
		CurrentlyAttendingApi.getStatusChangeDeletionReasons().then(
            (response: Array<IStatusHistoryDeletionOption>) => {
                if (response && response.length > 0) {
                    setChangeOptionData(response);
                }
            },
            (exception: any) => {}
        );
    }

    React.useEffect(() => {
		refreshOptions();
    }, []);

    const onOptionChange = (_event: any, value: any) => {
		setOptionValue((value != null)? value.id : null)
    }
    
    
return (
    <FormContext {...validation}>
        <form className={classes.form}>
            <div>
							
                <Dialog
                    open={props.open}
                    onClose={handleClose}
                    className={classes.root}
                    aria-labelledby="form-dialog-title"
					fullWidth={true}
					maxWidth='xs'
					disableBackdropClick
                >
                    <DialogTitle
                        id="form-dialog-title"
						className={classes.dialogHeader}
                    >
						Confirmation - Select Deletion Reason
						<IconButton 
							aria-label="close" 
							className={classes.closeButton} 
							onClick={handleClose}
						>
							<CloseIcon />
						</IconButton>
                    </DialogTitle>
                    <DialogContent>						
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="change-status-options"
                                options={changeOptionData}
                                getOptionLabel={(option) => (option != null)? option.description: ""}
                                onChange={onOptionChange}
                                renderInput={(params) => (
                                    <TextField {...params} 
                                        label="Deletion Reason *" 
                                        name="addNewStatus"
                                        error={!!validation.errors.addNewStatus}
                                        inputRef={validation.register({ required: true })}
                                        helperText={
                                            validation.errors.addNewStatus
                                            ? "Reason is required."
                                            : undefined
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                            {props.confirmationMessage}
                            </Typography>
                        </Grid>
				        <Grid item xs={12}>
                            <ProgressSaveButton
                                text= { props.confirmationMessage ? "Delete & Update" :  'Delete'}
                                loading = {props.isLoading}
                                onClick={async() => {
                                    const response = await validation.triggerValidation();
                                   if(response)
                                   props.deletionReasonCallback(optionValue)
                                }}
                                ></ProgressSaveButton>
                            <Button
                               className={classes.cancelBtn}
                                onClick={handleClose}
                                color="secondary"
                                variant="contained"
                            >Cancel</Button>
                        </Grid>
			        </Grid>

                    </DialogContent>
                </Dialog>
            </div>
        </form>
    </FormContext>
)    
};

export default RemoveReasonModal;

