import API from "../../apiWrapper";
import { ITimeClockPunches } from "../../../interfaces/reports/attendance/ITimeClockPunches";
import { ReportOutput } from "../../../enums/ReportOutput";
export const generateReport = (params: ITimeClockPunches,
  isPreview: boolean = false) => {
  return API()
    .post("/AcademicRecords/Attendance/GetTimeClockPunchesReport", params, {
      responseType: "blob",
    })
    .then(
			(res: any) => {
				if (res && res.data) {
          if (isPreview && ReportOutput.Pdf == params.exportType) {
            return res.data;
          } else {
					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement("a");
					let exportType: number = Number(params.exportType);
          let extension ='';
          switch(exportType){
            case Number(ReportOutput.Pdf):
            extension = "pdf";
            break;
            case Number(ReportOutput.Excel):
            extension = "xls";
            break;
            case Number(ReportOutput.Word):
            extension = "doc";
            break;
            default:
            extension = "pdf";
            break;
          }
					const fileName = "TimeClockPunchesReport" + new Date().toISOString().replace('T', ' ').replace(/\..*$/, '').split(' ')[0].split('-').join('') + "." + extension;

					link.href = url;
					link.setAttribute("download", fileName);
					document.body.appendChild(link);
					link.click();
        }
      }
			},
			(error: any) => {
				return null;
			}
		);
};
