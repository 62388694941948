import { IDataExport9010 } from "../../../interfaces/academicRecords/IDataExport9010";
import API from "../../apiWrapper";

export const exportData9010Report = (
    params: IDataExport9010
) => {
    return API()
        .post(
            "/Reports/Operations9010/GetDataExport9010",
            params,
            {
                responseType: "blob",
            }
        )
        .then(
            (res: any) => {
                if (res && res.data) {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                        const link = document.createElement("a");
                        var fileName ="DataExport9010.xlsx";

                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                }
            },
            (error: any) => {
                return null;
            }
        );
};

export const PrintCampus9010Mappings = (campusId:any) => {
    return API().post(
        "/Reports/Operations9010/PrintCampus9010Mappings?campusId=" + campusId,
        {}, 
        { responseType: 'blob' } 
    )
    .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "DataExport9010.xlsx");
        document.body.appendChild(link);
        link.click();
 
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        }, 100);
    })
    .catch(error => {
        console.error('Download error:', error);
    });
};