import DateFnsUtils from "@date-io/date-fns";
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Switch } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import { FormContext, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as attendanceSheetReportApi from "../../../api/reports/attendance/attendanceSheetReportApi";
import ProgramVersionAutoComplete from "../../../components/AutoComplete/ProgramVersionAutoComplete";
import StudentGroupsAutoComplete from "../../../components/AutoComplete/StudentGroupsAutoComplete";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import { ReportOutput } from "../../../enums/ReportOutput";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import { IAttendanceSheet } from "../../../interfaces/reports/attendance/IAttendanceSheet";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      minHeight: "100%",
      height: "100%",
      maxWidth: theme.spacing(131.25),
      margin: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    paperRoot: {
      padding: theme.spacing(3, 2),
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
    },
    width100: {
      width: "100%",
    },
    divWidthAutoComp: {
      width: "90%",
    },
    cardTitle: {
      fontSize: theme.spacing(2.15),
      color: theme.palette.primary.headerText,
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
    switch: {
      display: "flex",
      alignItems: "flex-end",
      padding: theme.spacing(0, 0.5),
      height: theme.spacing(6.6),
    },
    fieldSet: {
      marginTop: theme.spacing(2),
    },
  })
);



const AttendanceSheetReport = (props: any) => {
  const classes = useStyles({});
  const { report } = props;
  let reportOverview = { report: report } as IReportOverview;

  const [preview, setPreview] = React.useState<any>();
  const [previewLoader, setPreviewLoader] = React.useState<boolean>();
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );
  const [allActiveStudents, setAllActiveStudents] = React.useState<boolean>(
    true
  );
  const [groupOnSeparatePages, setGroupOnSeparatePages] = React.useState<boolean>(
    false
  );
  const [inActiveGroup, setInActiveGroup] = React.useState<
  boolean
>(false);
  const [inActiveProgramVersion, setInActiveProgramVersion] = React.useState<
    boolean
  >(false);


  const defaultFilters = {
    leadGroupIds: [],
    programVersionIds: [],
    month: undefined as Date | undefined,
    startDate: new Date() as Date | undefined,
    studentStartDateStart: undefined as Date| undefined,
    studentStartDateEnd: undefined as Date| undefined,
    exportType: 1 as ReportOutput,
    sortTypeName: "last" as string | undefined,
    allActiveStudents: true,
    groupBy : "none" as string | undefined
  };
  const [model, setModel] = React.useState<any>(defaultFilters);

  const handleChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    switch (name) {
      case "inActiveGroup":
        setInActiveGroup(event.target.checked);
        break;
      case "inActiveProgramVersion":
        setInActiveProgramVersion(event.target.checked);
        break;
        case "allActiveStudents":
          setAllActiveStudents(event.target.checked);
          model.programVersionIds = null;
          model.leadGroupIds = null;
          model.studentStartDateStart = null;
          model.studentStartDateEnd = null;
        break;
        case "groupOnSeparatePages":
          setGroupOnSeparatePages(event.target.checked);
          break;
    }
  };

 
  const setProgramVersion = (PrgVersions: any) => {
    let newProgramVersion: string[] = [];
    if (PrgVersions !== null) {
      if (Array.isArray(PrgVersions)) {
        newProgramVersion = PrgVersions.map((gr: any) => gr.value);
      } else {
        newProgramVersion.push(PrgVersions.value);
      }
    }
    return newProgramVersion;
  };
  const setStudentGroup = (studentGroups: any) => {
    let newGroups: string[] = [];
    if (studentGroups !== null) {
      if (Array.isArray(studentGroups)) {
        newGroups = studentGroups.map((gr: any) => gr.value);
      } else {
        newGroups.push(studentGroups.value);
      }
    }
    return newGroups;
  };
  const exportTypes: Array<DropDownListItem> = [
    { text: "PDF", id: "1" },
    { text: "Excel", id: "2" },
    { text: "Word", id: "3" },
  ];

  const exportTypeAutoComplete = {
    options: exportTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
    const item = items.find((opt) => {
      if (opt.id == value) return opt;
    });
    return item || ({} as DropDownListItem);
  };
  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    setModel({ ...updatedModel });
  };
  const data = useForm<any>({ mode: "onBlur" });
  const { handleSubmit } = data;
  const onSubmit = async (isPreview: any): Promise<void> => {
    let params = (await getReportParameters()) as IAttendanceSheet;
    const validation = await data.triggerValidation();
    params.startDate = new Date(params.startDate);
    if (validation && params.startDate) {
      if (isPreview) {
        setPreviewLoader(true);
        attendanceSheetReportApi
          .generateReport(params, isPreview)
          .then((res: any) => {
            if (res) {
              res.arrayBuffer().then((buffer: any) => {
                setPreview(buffer);
                setPreviewLoader(false);
              });
            } else setPreviewLoader(false);
          });
      } else await attendanceSheetReportApi.generateReport(params);
    }
  };

  const filterHandler = (data: any) => {
    if (data) {
      setInActiveGroup(data.inActiveGroup);
      setInActiveProgramVersion(data.inActiveProgramVersion);
      setModel({ ...data });
    } else {
      setInActiveGroup(false);
      setInActiveProgramVersion(false);
      setModel(defaultFilters);
    }
  };

  const handleSortOrderChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let updatedModel = model;
    (updatedModel as any)[
      "sortTypeName"
    ] = (event.target as HTMLInputElement).value;
    setModel({ ...updatedModel });
  };

  const handleGroupOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let updatedModel = model;
    (updatedModel as any)[
      "groupBy"
    ] = (event.target as HTMLInputElement).value;
    setModel({ ...updatedModel });
  };


  const getReportParameters = async () => {
    const success = await data.triggerValidation();
    if (model && success) {
      let params: IAttendanceSheet = {
        leadGroupIds: model?.leadGroupIds,
        programVersionIds: model?.programVersionIds,
        startDate: model?.startDate,
        studentStartDateStart: model?.studentStartDateStart,
        studentStartDateEnd: model?.studentStartDateEnd,
        exportType: model?.exportType,
        campusId: userSelectedCampus,
        sortTypeName: model.sortTypeName,
        allActiveStudents: allActiveStudents, 
        groupBy:model?.groupBy,
        groupOnSeparatePages: groupOnSeparatePages
      };
      return params;
    }
    return {} as IAttendanceSheet;
  };
  const handleDateFieldOnChange = (fieldId: string, value: any) => {
      const updatedModel = model;
      if (fieldId === 'studentStartDateStart') {
          updatedModel.studentStartDateStart = value;
          let addDays = new Date(value);
          let newDate = new Date(addDays.setDate(addDays.getDate() + 7));
          updatedModel.studentStartDateEnd =  newDate;
      }
      setModel({ ...updatedModel });
  };
  let parameters = (
    <div className={[classes.root, classes.width100].join(" ")}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
           <React.Fragment>
              <Grid item md={4} sm={4} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  views={["year", "month", "date"]}
                  variant="inline"
                  id="startDate"
                  name="startDate"
                  error={!!data.errors.startDate}

                  innerRef={(ref: any) => {
                    data.register("startDate", {
                      required: true,
                    });
                    data.setValue("startDate", model.startDate);
                  }}
                  helperText={
                    data.errors["startDate"]
                     ? "The week is required"
                      : undefined
                  }
                  label="Week *"
                  minDate={new Date().setFullYear(
                    new Date().getFullYear() - 10
                  )}
                  value={model.startDate ? model.startDate : null}
                  onChange={(value: any) =>
                  handleFieldOnChange("startDate", value ? value : undefined)

                  }
                  className={classes.width100}
                />
              </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={4} sm={4} >
                <Autocomplete className={classes.divWidthAutoComp}
                  {...exportTypeAutoComplete}
                  autoComplete
                  includeInputInList
                  onChange={(e: any, value: any) => {
                    handleFieldOnChange(
                      "exportType",
                      value ? value : undefined,
                      (v: any) => v?.id
                    );
                  }}
                  value={getSelectedItem(exportTypes, model.exportType)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Export Type *"
                      name="exportType"
                      error={!!data.errors.exportType}
                      inputRef={data.register({ required: true })}
                      helperText={
                        data.errors.exportType
                          ? "Export Type is required."
                          : undefined
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={3} sm={3} xs={12} className={classes.switch}>
                <FormControlLabel
                  label="All active students"
                  control={
                    <Switch
                      checked={allActiveStudents}
                      value="allActiveStudents"
                      onChange={handleChange("allActiveStudents")}
                      color="primary"
                    />
                  }
                />
              </Grid>
            </React.Fragment>
            <React.Fragment>
              <Grid item md={8} sm={8} xs={12}>
                <ProgramVersionAutoComplete
                  disabled={allActiveStudents}
                  id="programVersionIds"
                  name="programVersionIds"
                  label="Program Versions"
                  multiple={true}
                  params={{
                    inActiveProgramVersion: inActiveProgramVersion,
                  }}
                  selectAll={true}
                  valueFilter={
                    model?.programVersionIds
                      ? {
                          key: "value",
                          values: model?.programVersionIds,
                        }
                      : undefined
                  }
                  filterHandle={(data: any) => {
                    handleFieldOnChange(
                      "programVersionIds",
                      data,
                      setProgramVersion
                    );
                  }}
                />
              </Grid>
              <Grid item md={3} sm={3} xs={12} className={classes.switch}>
                <FormControlLabel
                  label="Include Inactive Values"
                  control={
                    <Switch
                      checked={inActiveProgramVersion}
                      value="inActiveProgramVersion"
                      onChange={handleChange("inActiveProgramVersion")}
                      color="primary"
                    />
                  }
                />
              </Grid>
            </React.Fragment>
            <React.Fragment>
              <Grid item sm={8} md={8}>
                <StudentGroupsAutoComplete
                 disabled={allActiveStudents}
                    id="leadGroupIds"
                    name="leadGroupIds"
                    filterHandle={(groups: any) => {
                      handleFieldOnChange(
                        "leadGroupIds",
                        groups,
                        setStudentGroup
                      );
                    }}
                    label="Student Group"
                  params={{
                    inActiveStudentGroup: inActiveGroup,
                  }}
                  multiple={true}
                  valueFilter={
                    model?.leadGroupIds
                      ? {
                          key: "value",
                          values: model?.leadGroupIds,
                        }
                      : undefined
                  }
                 
                />
              </Grid>
              <Grid item md={3} sm={3} xs={12} className={classes.switch}>
                <FormControlLabel
                  label="Include Inactive Values"
                  control={
                    <Switch
                      checked={inActiveGroup}
                      value="inActiveGroup"
                      onChange={handleChange("inActiveGroup")}
                      color="primary"
                    />
                  }
                />
              </Grid>
            </React.Fragment>
            <React.Fragment>
              <Grid item md={4} sm={4} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disabled={allActiveStudents}
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="studentStartDateStart"
                  name="studentStartDateStart"
                  error={!!data.errors.date}
                  innerRef={(ref: any) => {
                    data.register("studentStartDateStart", {
                      required: false,
                    });
                    data.setValue("studentStartDateStart", model.studentStartDateStart);
                  }}
                  label="Student Start Date From"
                  maxDate={new Date()}
                  value={model.studentStartDateStart ? model.studentStartDateStart : null}
                  onChange={(value: any) =>
                  handleDateFieldOnChange("studentStartDateStart", value ? value : undefined)

                  }
                  className={classes.width100}
                />
              </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disabled={allActiveStudents}
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="studentStartDateEnd"
                  name="studentStartDateEnd"
                  error={!!data.errors.date}
                  innerRef={(ref: any) => {
                    data.register("studentStartDateEnd", {
                      required: false,
                    });
                    data.setValue("studentStartDateEnd", model.studentStartDateEnd);
                  }}
                  label="Student Start Date To"
                  defaultValue = {model.studentStartDateStart ? (model.studentStartDateStart + 7 ): null}
                  value={model.studentStartDateEnd ? model.studentStartDateEnd : null}
                  onChange={(value: any) =>
                    handleFieldOnChange("studentStartDateEnd", value ? value : undefined)
                  }
                  className={classes.width100}
                />
              </MuiPickersUtilsProvider>
              </Grid>
            </React.Fragment>
            <React.Fragment>
              <Grid item md={12} sm={12} xs={12}>
                <FormControl component="fieldset" className={classes.fieldSet}>
                  <FormLabel component="legend">Sort Order</FormLabel>
                  <RadioGroup
                    row
                    aria-label="sortTypeName"
                    name="sortTypeName"
                    value={model.sortTypeName}
                    onChange={handleSortOrderChange}
                    
                  >
                    <FormControlLabel 
                      labelPlacement="start"
                      value="last"
                      control={<Radio color="default" />}
                      label="Last Name"
                    />
                    <FormControlLabel 
                      labelPlacement="start"
                      value="first"
                      control={<Radio color="default" />}
                      label="First Name"
                    />
                    <FormControlLabel 
                      labelPlacement="start"
                      value="studentId"
                      control={<Radio color="default"  />}
                      label="Student ID"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </React.Fragment>
            <React.Fragment>

              <Grid item md={12} sm={12} xs={12}>
                <FormControl component="fieldset" className={classes.fieldSet}>
                  <FormLabel component="legend">Grouping Options</FormLabel>
                  <RadioGroup
                    row
                    aria-label="groupBy"
                    name="groupBy"
                    value={model.groupBy}
                    onChange={handleGroupOptionChange}
                    
                  >
                    <FormControlLabel 
                      labelPlacement="start"
                      value="none"
                      control={<Radio color="default" />}
                      label="None"
                    />
                    <FormControlLabel 
                      labelPlacement="start"
                      value="programVersion"
                      control={<Radio color="default" />}
                      label="Program Version"
                    />
                    <FormControlLabel 
                      labelPlacement="start"
                      value="studentGroup"
                      control={<Radio color="default"  />}
                      label="Student Group"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </React.Fragment>
          <React.Fragment>
          <Grid item md={12} sm={12} xs={12} className={classes.switch}>
                <FormControlLabel
                  label="Group on separate pages"
                  control={
                    <Switch
                      checked={groupOnSeparatePages}
                      value="groupOnSeparatePages"
                      onChange={handleChange("groupOnSeparatePages")}
                      color="primary"
                    />
                  }
                />
              </Grid>
          </React.Fragment>
          </Grid>
        </form>
      </FormContext>
    </div>
  );
  reportOverview.parameters = parameters;

  return (
    <ReportOverview
      reportOverview={reportOverview}
      filterHandler={filterHandler}
      getReportParameters={getReportParameters}
      exportHandler={onSubmit}
      preview={preview}
      previewLoader={previewLoader}
    />
  );
};

export default AttendanceSheetReport;
