import * as React from 'react';
import { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
    Checkbox,
    Chip,
    Divider,
    FormControlLabel,
    Input,
    Card,
    colors,

    Grid,
    TextField,
    CardContent
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';


import AdmissionStatusAutoComplete from "../../../components/AutoComplete/AdmissionStatusAutoComplete";
import LeadImportGrid from "./LeadImportGrid";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import { LeadSearchInputPaged } from "../../../interfaces/admissions/LeadSearchInputPaged";
import ProgressSaveButton from "../../../components/_Layout/Buttons/ProgressSaveButton";
import CRMStudentProgramsAutoComplete from "../../../components/AutoComplete/CRMStudentProgramsAutoComplete";
import { ThemeTextField } from "../../../components/_Layout/Inputs/ThemeTextField";
import SsnMaskInput from "../../../components/_Layout/Inputs/SsnMaskInput";
import { useFormContext } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import { ILead } from '../../../interfaces/admissions/ILead';

const useStyles = makeStyles((theme: any) => ({
    root: {},
    keywords: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center'
    },
    searchIcon: {
        color: theme.palette.icon,
        marginRight: theme.spacing(2)
    },
    cardGrid: {
        marginTop: theme.spacing(2),
        height: 500
    },
    selects: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        backgroundColor: colors.grey[50],
        padding: theme.spacing(1)
    },
    inputField: {
        width: "100%",
    },
    cardContent: {
        padding: theme.spacing(1, 3)
        ,
        height: 450
    },
    fullWidthField: {
        width: "100%",
    },
    gridSpacing: {
        marginTop: theme.spacing(1)
    }
}));


export interface LeadSearchFilterProps {
    handleFieldChange: (id: string, val: any) => void;
    model?: LeadSearchInputPaged
}

const LeadSearchFilter = (props:LeadSearchFilterProps) => {
    const { handleFieldChange, model, ...rest } = props;

    const classes = useStyles();
    return (
        <React.Fragment>
            <Grid container direction="row" spacing={1}>
                <Grid item xs={4}>
                    <AdmissionStatusAutoComplete
                        filterHandle={(v: any) => {
                            handleFieldChange("status", v ? v.value : undefined);
                        }}
                        valueFilter={ model?.status
                            ? {
                                key: 'value',
                                values: [model?.status],
                            }
                            : undefined}
                    ></AdmissionStatusAutoComplete>
                </Grid>
                <Grid item xs={4}>
                    <CRMStudentProgramsAutoComplete
                        filterHandle={(v: any) => {
                            handleFieldChange("studentProgram", v ? v.value : undefined);
                        }}
                        valueFilter={ model?.studentProgram
                            ? {
                                key: 'value',
                                values: [model?.studentProgram],
                            }
                            : undefined}
                    ></CRMStudentProgramsAutoComplete>
                </Grid>
                <Grid item xs={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format={"MM/dd/yyyy"}
                            id="startDate"
                            label="Start Date"
                            TextFieldComponent={ThemeTextField}
                            autoOk={true}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                            className={classes.fullWidthField}
                            value={
                               model && model.startDate
                                    ? new Date(model.startDate)?.toLocaleDateString() ?? null
                                    : null
                            }
                            onChange={(val: any) => {
                                handleFieldChange("startDate", val?.toLocaleDateString() ?? null);
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={4}></Grid>
            </Grid>
            <Grid container direction="row" spacing={1}>
                <Grid item xs={3}>
                    <TextField
                        id="firstName"
                        label="First Name"
                        className={classes.inputField}
                        onChange={(v: any) => {
                            handleFieldChange(
                                "firstName",
                                v ? v.target.value : undefined
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="lastName"
                        label="Last Name"
                        className={classes.inputField}
                        onChange={(v: any) => {
                            handleFieldChange("lastName", v ? v.target.value : undefined);
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="phoneNumber"
                        label="Phone Number"
                        className={classes.inputField}
                        onChange={(v: any) => {
                            handleFieldChange(
                                "phoneNumber",
                                v ? v.target.value : undefined
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="emailAddress"
                        label="Email Address"
                        className={classes.inputField}
                        onChange={(v: any) => {
                            handleFieldChange("email", v ? v.target.value : undefined);
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className={classes.gridSpacing}>
                <Grid item xs={3}>
                    <TextField
                        id="address"
                        label="Address"
                        className={classes.inputField}
                        onChange={(v: any) => {
                            handleFieldChange("address", v ? v.target.value : undefined);
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="address2"
                        label="Address 2"
                        className={classes.inputField}
                        onChange={(v: any) => {
                            handleFieldChange("address2", v ? v.target.value : undefined);
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="city"
                        label="City"
                        className={classes.inputField}
                        onChange={(v: any) => {
                            handleFieldChange("city", v ? v.target.value : undefined);
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        id="state"
                        label="State"
                        className={classes.inputField}
                        onChange={(v: any) => {
                            handleFieldChange("state", v ? v.target.value : undefined);
                        }}
                    />
                </Grid>
                <Grid item xs={1}>
                    <TextField
                        id="zip"
                        label="Zip Code"
                        className={classes.inputField}
                        onChange={(v: any) => {
                            handleFieldChange("zipCode", v ? v.target.value : undefined);
                        }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default LeadSearchFilter;
