import * as React from "react";
import {
	Card,
	CardContent,
	Grid,
	CardActions,
	Paper,
	Table,
	withStyles,
	TableCell,
	TableRow,
	TableContainer,
	TableHead,
	TableBody,
	CircularProgress,
	Popover,
	Typography,
	Button,
} from '@material-ui/core';
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import * as postGradesApi from "../../api/student/academics/postGradesApi";
import { PostGradesFilter, PostGradeStudentsResult, PostGradesModel } from "../../interfaces/student/academics/PostGradesFilter";
import ProgressSaveButton from "../../components/_Layout/Buttons/ProgressSaveButton";
import CustomSnackbar from "../../components/notifications/CustomSnackbar";
import { CustomSnackBarProps } from "../../interfaces/common/CustomSnackBarProps";
import PostGradesSearchFilter from "./PostGradesSearchFilter";
import InputField from "../../components/_Layout/Inputs/InputField";
import { InputType } from "../../constants/uiConstants/inputConstants";
import * as StudentGradesApi from "../../api/student/academics/StudentGradesApi";
import { StudentAttempts } from "../../interfaces/student/academics/StudentAttempts";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const StyledTableCell = withStyles((theme: any) =>
	createStyles({
		head: {
			backgroundColor: theme.palette.site.secondary,
			fontSize: 17,
			color: theme.palette.black,
			fontWeight: 'bold',
			textAlign: 'center',
			boxShadow: '1px 1px 0px 0px rgba(0,0,0,0.2)',
			'&:first-child': {
				width: '42px',
				padding: theme.spacing(0, 1)
			}
		},
		body: {
			fontSize: 16,
			color: theme.palette.black,
			fontWeight: 500,
			cursor: 'pointer',
			textAlign: 'left',
			'&:first-child': {
				width: '42px',
				padding: theme.spacing(0, 1)
			}
		},
	}),
)(TableCell);

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			paddingTop: theme.spacing(2),
			minHeight: "100%",
			height: "100%",
			width: "100%",
			margin: "auto"
		},
		paperRoot: {
			width: "100%",
			minHeight: "100%",
			height: "100%",
			overflowX: "hidden",
			overflowY: "auto",
			flexDirection: "column",
		},
		cardMain: {
			marginTop: theme.spacing(2)
		},
		tableContainer: {
			height: '400px',
		},
		tableEmptyRow: {
			height: '350px',
			textAlign: 'center',
			fontSize: 20,
			opacity: 0.3,
		},
		table: {
			width: '100%',
		},
		searchField: {
			'&::placeholder': {
				color: '#000000'
			}
		},
		selectionCheckBox: {
			padding: theme.spacing(0)
		},
		preLoaderCell: {
			textAlign: 'center',
			padding: theme.spacing(1)
		},
		popOverButtons: {
			display: "flex",
			justifyContent: "space-evenly",
			flexDirection: "row",
			padding: 5
		},
		typography: {
			padding: theme.spacing(2)
		},
		lineThru: {
			textDecoration: "line-through",
		},
		saveButton: {
			width: 90,
		},
		gridHeadTypeXsmall: {
			width: 30,
			paddingLeft: theme.spacing(0),
			paddingRight: theme.spacing(0)
		},
		gridCellTypeXsmall: {
			width: 30,
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0)
		},
		textField: {
			marginRight: theme.spacing(1),
			width: "100%",
			"& ::-webkit-inner-spin-button, ::-webkit-outer-spin-button": {
				WebkitAppearance: "none",
				margin: 0,
				MozAppearance: "textfield"
			}
		},
		gridHeadTypeMedium: {
			width: 160,
			paddingLeft: theme.spacing(0),
			paddingRight: theme.spacing(0)
		},
		gridCellTypeMedium: {
			width: 160,
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0)
		},
		datePicker: {
			"& .MuiIconButton-root": {
				padding: theme.spacing(0.8)
			}
		}
		, tableRowSelected: {
			backgroundColor: "rgb(255, 201, 201)"
		}
		, cardContentTop: {
			paddingTop: 0,
			marginTop: 0,
		}
		, tableWrapper: {
			height: "calc(100% - 40px)",
			maxWidth: "400px",
			width: "100%"
		}
		, gridHeader: {
			backgroundColor: theme.palette.secondary.dark,
			height: "50px",
			display: "block",
			width: "100%",
			padding: "10px",
			tableLayout: "fixed"
		},
		tableRowWrapper: {
			display: "table",
			width: "100%",
			tableLayout: "fixed"
		},
		tableBodyWrapper: {
			maxHeight: "530px",
			overflowY: "auto",
			display: "block",
			width: "100%",
			padding: "10px"
		},
		header: {
			height: "40px",
			padding: "10px",
			fontWeight: 600,
			width: "100%",
			backgroundColor: theme.palette.secondary.dark
		},
		popoverStyle: {
			maxHeight: "800px",
			"& .MuiPopover-paper": {
				maxWidth: "400px !important",
				overflowY: "hidden"
			}
		},
	})
);

const PostGradesByGroup = (props: any) => {
	const classes = useStyles({});
	let currentDate = new Date();
	const [loading, setLoading] = React.useState<boolean>(false);
	const [allDate, setAllDate] = React.useState<Date | null>(null);
	const [updating, setUpdating] = React.useState<boolean>(false);
	const [gradeComponents, setGradeComponents] = React.useState<Array<PostGradeStudentsResult>>([]);
	const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
		{
			showSnackBar: false,
			messageInfo: undefined,
			variant: "info",
		}
	);

	const campusId = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const [filters, setFilters] = React.useState<PostGradesFilter>({
		startDate: null,
		programVersionIds: [],
		courseIds: [],
		componentIds: [],
		studentGroupIds: [],
		statusIds: [],
		campusId: campusId,
	});

	const handleSearch = (filter: PostGradesFilter) => {
		setFilters(filter);
		getStudentsForPostGrades(filter);
	};

	const getStudentsForPostGrades = (filters: PostGradesFilter) => {
		filters.campusId = campusId;
		postGradesApi.getStudentsForPostGrades(filters).then(
			(response: any) => {
				if (response && response.data) {
					setGradeComponents(response.data);
				}
			},
			(exception: any) => { }
		);
	}
	const handleAllDateChange = (value: any) => {
		var selectedDate = value ? value?.toLocaleDateString() : undefined;
		setAllDate(selectedDate);
	}

	const handleFieldOnChange = (row: any, fieldName: string, value: any, index: number) => {
		let updatedModel = gradeComponents;
		if (fieldName === "score") {
			if (value.target.value == "" || (!isNaN(value.target.value) == true && Number(value.target.value) >= 0 && Number(value.target.value) <= 100)) {
				updatedModel[index].score = value.target.value == "" ? null : Number(value.target.value);
			}
			else {
				if (Number(value.target.value) >= 100) {
					updatedModel[index].score = 100;
				} else if (Number(value.target.value) < 0) {
					updatedModel[index].score = 0;
				}
			}
			if (updatedModel[index].dateCompleted == null) {
				let newDate = new Date();
				updatedModel[index].dateCompleted = allDate ? allDate : newDate;
			}

		}
		else {
			updatedModel[index].dateCompleted = value?.toLocaleDateString();
		}
		setGradeComponents([...updatedModel]);
	}

	const handleCancel = () => {
		setAllDate(null);
		let updatedModel = gradeComponents;
		updatedModel.forEach((element, index) => {
			updatedModel[index].score = null;
			updatedModel[index].dateCompleted = null;
		});
		setGradeComponents([...updatedModel]);
	};

	const handleSave = () => {
		try {
			setUpdating(true);
			let grades: Array<PostGradesModel> = [];
			var invalidCount = 0;
			gradeComponents.forEach((element, index) => {
				if ((element.score != null && element.dateCompleted == null) || (element.score == null && element.dateCompleted != null)) {
					invalidCount++;
					return;
				}
				if (element.score != null && element.dateCompleted != null) {
					grades.push({
						stuEnrollId: element.stuEnrollId
						, clsSectionId: element.clsSectionId
						, instrGrdBkWgtDetailId: element.instrGrdBkWgtDetailId
						, score: element.score, dateCompleted: element.dateCompleted
					});
				}
			});
			if (grades.length > 0 && invalidCount == 0) {
				postGradesApi.postStudentGrades(grades).then(
					(response: any) => {
						if (response && response.data) {
							getStudentsForPostGrades(filters);
							setSnackBarProps({
								variant: 'success',
								showSnackBar: true,
								messageInfo: 'Data saved successfully.'
							});
						}
						setUpdating(false)
					},
					(exception: any) => {
						setUpdating(false)
						setSnackBarProps({
							variant: 'error',
							showSnackBar: true,
							messageInfo: "Error on posting grades."
						});
					}
				);
			}
			else {
				setUpdating(false)
				setSnackBarProps({
					variant: 'error',
					showSnackBar: true,
					messageInfo: "Please enter valid score and Date."
				});
			}

		} catch (error) {
			setSnackBarProps({
				variant: 'error',
				showSnackBar: true,
				messageInfo: "Error"
			});
		}
	};

	const [anchorEl, setAnchorEl] = React.useState<any>(null);
	const open = Boolean(anchorEl);
	const popOverId = open ? 'attempts-popover' : undefined;
	const [studentAttempts, setStudentAttempts] = React.useState<Array<StudentAttempts>>([]);
	const openPopover = (event: React.MouseEvent<HTMLButtonElement>, row: PostGradeStudentsResult) => {
		StudentGradesApi.GetStudentAttempts(
			row.stuEnrollId,
			row.instrGrdBkWgtDetailId,
			false
		).then(
			(response: any) => {
				if (response && response.data) {
					setStudentAttempts(response.data.result);
					setAnchorEl(event);
				}
			},
			(exception: any) => { }
		);
	};
	const closePopover = () => {
		setAnchorEl(null);
	};

	return (
		<div className={classes.root}>
			<div className={classes.paperRoot}>
				<div>
					<CustomSnackbar
						variant={snackBarProps.variant}
						message={snackBarProps.messageInfo}
						open={snackBarProps.showSnackBar}
						onClose={(event?: React.SyntheticEvent, reason?: string) => {
							setSnackBarProps((props: any) => {
								return { ...props, showSnackBar: false };
							});
						}}
					></CustomSnackbar>
					<Card square={true} elevation={2} className={classes.cardMain}>
						<CardContent>
							<PostGradesSearchFilter handleSearch={handleSearch} components={gradeComponents}></PostGradesSearchFilter>
						</CardContent>
						<CardContent className={classes.cardContentTop}>
							<Grid container direction="column" spacing={2}>
								<Grid
									item
									alignContent="flex-start"
									alignItems="flex-start"
									justify="flex-start"
								>
									<TableContainer component={Paper} square className={classes.tableContainer}>
										<Table stickyHeader size='small' className={classes.table} aria-label='customized table'>
											<TableHead>
												<TableRow>
													<TableCell>Student</TableCell>
													<TableCell>Program Version</TableCell>
													<TableCell>Course</TableCell>
													<TableCell>Component</TableCell>
													<TableCell>Score</TableCell>
													<TableCell>
														{
															gradeComponents.length == 0 ? 'Date' : <MuiPickersUtilsProvider utils={DateFnsUtils}>
																<KeyboardDatePicker
																	className={classes.datePicker}
																	disableToolbar
																	autoOk={true}
																	variant="inline"
																	format="MM/dd/yyyy"
																	placeholder="Default Date"
																	maxDate={currentDate}
																	id={"selectAllDate"}
																	key={"key_selectAllDate"}
																	name={"selectAllDate"}
																	value={
																		allDate ? new Date(allDate) : null
																	}
																	onChange={(v: any) => {
																		handleAllDateChange(v ? v : undefined);
																	}}
																	KeyboardButtonProps={{
																		"aria-label": "change date",
																	}}
																/>
															</MuiPickersUtilsProvider>
														}


													</TableCell>
													<TableCell>Attempts</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>

												{loading ?

													<TableRow>
														<StyledTableCell colSpan={2} className={classes.preLoaderCell}>
															<CircularProgress />
														</StyledTableCell>
													</TableRow>
													: gradeComponents?.map((row: PostGradeStudentsResult, index: any) => (
														<TableRow
															className={((row.score != null && row.dateCompleted == null) || (row.score == null && row.dateCompleted != null)) ? classes.tableRowSelected : ""}
														>
															<TableCell>{row.student}</TableCell>
															<TableCell>{row.programVersion}</TableCell>
															<TableCell>{row.course}</TableCell>
															<TableCell>{row.component}</TableCell>
															<TableCell
																classes={{
																	head: classes.gridHeadTypeXsmall,
																	body: classes.gridCellTypeXsmall
																}}
																align="left"
															>
																<InputField
																	type={InputType.NUMBER}
																	decimal
																	className={classes.textField}
																	id={"score_" + row.instrGrdBkWgtDetailId + row.studentNumber}
																	key={"key_score_" + row.instrGrdBkWgtDetailId + row.studentNumber}
																	name={"score_" + row.instrGrdBkWgtDetailId + row.studentNumber}
																	defaultValue={row.score != null ? row.score : null}
																	onBlur={(v: any) => {
																		handleFieldOnChange(row, "score", v ? v : undefined, index);
																	}}
																/>
															</TableCell>
															<TableCell
																classes={{
																	head: classes.gridHeadTypeMedium,
																	body: classes.gridCellTypeMedium
																}}
																align="left"
															>
																<MuiPickersUtilsProvider utils={DateFnsUtils}>
																	<KeyboardDatePicker
																		className={classes.datePicker}
																		disableToolbar
																		autoOk={true}
																		variant="inline"
																		format="MM/dd/yyyy"
																		maxDate={currentDate}
																		id={"dateCompleted_" + row.instrGrdBkWgtDetailId + row.studentNumber}
																		key={"key_dateCompleted_" + row.instrGrdBkWgtDetailId + row.studentNumber}
																		name={"dateCompleted_" + row.instrGrdBkWgtDetailId + row.studentNumber}
																		value={
																			row.dateCompleted ? new Date(row.dateCompleted) : null
																		}
																		onChange={(v: any) => {
																			handleFieldOnChange(row, "dateCompleted", v ? v : undefined, index);
																		}}
																		KeyboardButtonProps={{
																			"aria-label": "change date",
																		}}
																	/>
																</MuiPickersUtilsProvider>
															</TableCell>
															<TableCell>
																{Number(row.attempts)}

																{
																	Number(row.attempts) > 0 &&
																	<Button aria-describedby={popOverId} onClick={(v: any) => openPopover(v.currentTarget, row)}>
																		<ListAltOutlinedIcon />
																	</Button>
																}

															</TableCell>
														</TableRow>
													))}

												{
													gradeComponents?.length == 0 ?
														<TableRow>
															<TableCell className={classes.tableEmptyRow} colSpan={7}>No Data</TableCell>
														</TableRow>
														: null
												}
											</TableBody>
										</Table>
										<Popover
											id={popOverId}
											open={open}
											anchorEl={anchorEl}
											onClose={closePopover}
											className={classes.popoverStyle}
											anchorOrigin={{
												vertical: "bottom",
												horizontal: "center"
											}}
											transformOrigin={{
												vertical: "top",
												horizontal: "center"
											}}
										>
											<div>
												<Typography className={classes.header}>Previous Attempts</Typography>
												<div className={classes.tableWrapper}>
													<Table
														className={classes.table}
														aria-labelledby="tableTitle"
														size="small"
														aria-label="enhanced table"
													>
														<TableHead>
															<TableRow>
																<TableCell padding="normal" align="left">Date</TableCell>
																<TableCell padding="normal" align="left">Score</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{
																studentAttempts?.map((subRow: StudentAttempts, indx: any) => (
																	<TableRow>
																		<TableCell padding="normal">{subRow.dateCompleted ? new Date(subRow.dateCompleted).toLocaleDateString() : null}</TableCell>
																		<TableCell padding="normal">{subRow.score}</TableCell>
																	</TableRow>
																))
															}
														</TableBody>
													</Table>

												</div>
											</div>
										</Popover>
									</TableContainer>
								</Grid>
							</Grid>
						</CardContent>
						<CardActions className={classes.cardContentTop}>
							<Grid container direction="row" spacing={1}>
								<Grid
									item
									alignContent="flex-start"
									alignItems="flex-start"
									justify="flex-start"
								>
									<ProgressSaveButton
										text="Save"
										onClick={handleSave}
										loading={updating}
										buttonClassName={classes.saveButton}
									></ProgressSaveButton>
								</Grid>
								<Grid
									item
									alignContent="flex-start"
									alignItems="flex-start"
									justify="flex-start"
								>
									<Button
										onClick={handleCancel}
										color="secondary"
										variant="contained"
										type="button"
									>
										Cancel
									</Button>
								</Grid>
							</Grid>
						</CardActions>
					</Card>
				</div>
			</div>

		</div>
	);
};

export default PostGradesByGroup;
