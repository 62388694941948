import React, { useState } from "react";
import { TableRow, TableCell, FormControlLabel, Checkbox, makeStyles, createStyles } from "@material-ui/core";
import { IStatusInfoRow } from "../../../interfaces/student/profile/status/IStatusInfoRow";

type InfoStatusGridRowProps = {
row: IStatusInfoRow
gridData:IStatusInfoRow[];
setGridData:any;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    gridCellTypeSmall: {
      width: "15%",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(0),
      whiteSpace: "nowrap",
    },
    gridCellTypeMedium: {
      width: "25%",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(0),
      whiteSpace: "nowrap",
    },
    gridCellTypeLarge: {
      width: "35%",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(0),
      whiteSpace: "nowrap",
    },
    gridCellTypeXLarge: {
      width: "45%",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(0),
      whiteSpace: "nowrap",
    },
    gridCellTypeRemainder: {
      width: "100%",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(0),
    },
    gridCellTypeOptions: {
      width: "50px",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(0),
      whiteSpace: "nowrap",
    },
  }))
const InfoStatusGridRow = (props: InfoStatusGridRowProps) => {
const classes = useStyles({});
    const [model, setModel] = useState<IStatusInfoRow>(props.row);
    const handleSelected = (badgeNumber: string, newValue: any, property: string) => {
      let modelToUpdate = model
      model.selected = !modelToUpdate.selected
      setModel({...modelToUpdate});

      let arrayToUpdate: IStatusInfoRow[] = [...props.gridData];
      let modelToUpdateArray: any;
      modelToUpdateArray = arrayToUpdate.find((prop) => prop.badgeNumber === badgeNumber);
      if (modelToUpdateArray !== undefined) {
        modelToUpdateArray[property as keyof IStatusInfoRow] = newValue;
      }
      props.setGridData([...arrayToUpdate]);
    }
    return (
        <React.Fragment>
          <TableRow
            key={"status__" + model.studentEnrollmentId }
          >
            <TableCell className={classes.gridCellTypeOptions}><React.Fragment><FormControlLabel
                                control={
                                    <Checkbox color="primary" checked={model.selected} onChange={(e:any) => {
                                      handleSelected(model.badgeNumber , e ? e.target.checked : false, "selected")}} />
                                }
                                label=""
                            /></React.Fragment></TableCell>
            <TableCell className={classes.gridCellTypeSmall}>  <React.Fragment>{model.badgeNumber}</React.Fragment></TableCell>
            <TableCell className={classes.gridCellTypeMedium}>  <React.Fragment>{model.lastName}</React.Fragment></TableCell>
            <TableCell className={classes.gridCellTypeMedium}>  <React.Fragment>{model.firstName}</React.Fragment></TableCell>
            <TableCell className={classes.gridCellTypeRemainder}>  <React.Fragment>{model.currentStatus}</React.Fragment></TableCell>
          </TableRow>
        </React.Fragment>
      );
}
export default InfoStatusGridRow;