import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Typography,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Grid,
} from "@material-ui/core";

import { useSelector } from "react-redux";
import { useForm, FormContext } from "react-hook-form";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { ThemeTextField } from "../../../components/_Layout/Inputs/ThemeTextField";
import ProgressSaveButton from "../../../components/_Layout/Buttons/ProgressSaveButton";
import { StudentBadgeNumberAndNotes } from "../../../interfaces/student/academics/EnrollmentProgram";
import * as enrollmentApi from "../../../api/student/academics/enrollmentApi";
import { EmptyGuid } from "../../../utils/constants";
import { useState } from "react";
import { number } from "prop-types";
import CachedIcon from "@material-ui/icons/Cached";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardSeparator: {
      marginBottom: theme.spacing(1),
      padding: theme.spacing(2),
      "& div.MuiOutlinedInput-root": {
        "& textarea": {
          minHeight: "100px",
        },
      },
    },
    button: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      whiteSpace: "nowrap",
      marginRight: theme.spacing(1),
    },
    noteBox: {
      width: "80%",
      borderColor: "",
    },
    noteTextArea: {
      width: "100%",
      height: theme.spacing(1) * 5,
      border: "none",
    },
    fullWidthField: {
      width: "100%",
    },
    notFullWidthField: {
      width: "25%",
    },
    buttonLeft: {
      whiteSpace: "nowrap",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);
type FinalizeEnrollmentProps = {
  isNewEnrollmentTransfer: boolean;
  activeStep: number;
  steps: number;
  isTransferredStudent?: boolean;
  isNewEnrollment?: boolean;
  handleBack?: () => void;
  finalizeEnrollment?: () => void;
  setActionAfterFinalize: (value: string) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
  setBadgeAndNotes: (badgeAndNotes: StudentBadgeNumberAndNotes) => void;
};
const FinalizeEnrollment = (props: FinalizeEnrollmentProps) => {
  const classes = useStyles({});
  const methods = useForm<any>({
    mode: "onBlur",
  });

  const userSelectedCampus: string = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );
  const selectedStudent = useSelector((state: any) => state.student);

  const [model, setModel] = React.useState<StudentBadgeNumberAndNotes>({
    badgeNumber: "",
    notes: "",
  });

  const [badgeDisabled, setBadgeDisabled] = useState(true);
  const { handleSubmit } = methods;
  const [whatToDoAfter, setWhatToDoAfter] = React.useState<string>("quit");
  const [enrollmentNumberWay, setEnrollmentNumberWay] = React.useState<string>("auto");
  const { loading, setLoading } = props;
  const onSubmit = () => {
    if (props.finalizeEnrollment) props.finalizeEnrollment();
  };

  React.useEffect(() => {
    setLoading(false);
    props.setActionAfterFinalize(whatToDoAfter);
    getBadgeNumber("auto");
  }, []);

  const handleChange = (e: any) => {
    setWhatToDoAfter(e.target.value);
    props.setActionAfterFinalize(e.target.value);
  };

  const params = new URLSearchParams(window.location.search);
  let importParam = params.get("isImport");
  let importedParam = params.get("isImported");
  let isNew = props.isNewEnrollment ||
    (importedParam && (importedParam === "true" || importedParam === "True")) || (importParam && (importParam === "true" || importParam === "True"))
    ? true
    : false;
  const [isNewStudent, setIsNewStudent] = React.useState<boolean>(isNew);

  const handleBadgeNumberSelectiom = (e: any) => {
    setEnrollmentNumberWay(e.target.value);

    setBadgeDisabled(e.target.value === "auto");

    getBadgeNumber(e.target.value);
  };

  const handleModelChange = (fieldId: string, value: any) => {
    let updateModel = model;
    (updateModel as any)[fieldId] = value;
    setModel({ ...updateModel });
    props.setBadgeAndNotes({ ...model });
  };

  const getBadgeNumber = (selection: string) => {    
    if (userSelectedCampus !== "" && userSelectedCampus !== EmptyGuid) {
      var studentId = null;
      if (selection == "auto" && selectedStudent && selectedStudent.studentId && !isNew) {
        studentId = selectedStudent.studentId;        
      }
      enrollmentApi.getNextBadgeNumber(userSelectedCampus, studentId).then(
        (response: any) => {
          if (response.status) {
            let newBadgeNumber = response.data;
            handleModelChange("badgeNumber", newBadgeNumber);
          }
        },
        (exception: any) => {
          handleModelChange("badgeNumber", "");
        }
      );
    }
  };

  return (
    <FormContext {...methods}>
      <form>
        <div className={classes.cardSeparator}>
          <ThemeTextField
            label={
              "Enter any notes you would like to add regarding the enrollment"
            }
            multiline={true}
            value={model.notes}
            className={classes.noteBox}
            variant={"outlined"}
            onChange={(e: any) => {
              handleModelChange("notes", e ? e.target.value : null);
            }}
          />
        </div>
        <div className={classes.cardSeparator}>
          <FormLabel component="legend">
            How to chose Badge # for this enrollment? Field contains next
            available Badge number
          </FormLabel>
          <RadioGroup
            aria-label="enrollmentNumberWay"
            name="enrollmentNumberWay"
            value={enrollmentNumberWay}
            onChange={handleBadgeNumberSelectiom}
          >
            <FormControlLabel value="auto" control={<Radio />} label="System" />
            <FormControlLabel
              disabled={!isNewStudent}
              value="manual"
              control={<Radio />}
              label="Manual"
            />
          </RadioGroup>
          <React.Fragment>
            <Grid item md={3} sm={3} xs={3}>
              <ThemeTextField
                label={"Badge #"}
                value={model.badgeNumber}
                className={classes.notFullWidthField}
                disabled={badgeDisabled}
                type="number"
                onChange={(e: any) => {
                  if (e && e.target.value > 0 && e.target.value.toString().length < 10) {
                    handleModelChange("badgeNumber", e ? e.target.value : null);
                  }
                }}
              />

              <IconButton
                aria-label="New BadgeNumber"
                onClick={() => {
                  getBadgeNumber(enrollmentNumberWay);
                }}
              >
                <CachedIcon className={classes.buttonLeft} />
              </IconButton>
            </Grid>
          </React.Fragment>
        </div>
        <div className={classes.cardSeparator}>
          <FormLabel component="legend">
            After Completing this Enrollment
          </FormLabel>
          <RadioGroup
            aria-label="whatToDoAfter"
            name="whatToDoAfter"
            value={whatToDoAfter}
            onChange={handleChange}
          >
            <FormControlLabel
              value="quit"
              control={<Radio />}
              label="Quit the New Student Workflow"
            />
            <FormControlLabel
              value="new"
              control={<Radio />}
              label="Enter another enrollment"
            />
            <FormControlLabel
              value="reuse"
              control={<Radio />}
              label="Enter another enrollment -use same program and schedule"
            />
          </RadioGroup>
        </div>
        <div className={classes.cardSeparator}>
          <Button
            disabled={props.activeStep === 0}
            onClick={props.handleBack}
            className={classes.button}
            color="secondary"
            variant="contained"
          >
            Back
          </Button>
          <ProgressSaveButton
            disabled={false}
            type={"button"}
            onClick={() => {
              setLoading(true);
              onSubmit();
            }}
            loading={loading}
            text={"Finish"}
            buttonClassName={classes.button}
          />
        </div>
      </form>
    </FormContext>
  );
};
export default FinalizeEnrollment;
