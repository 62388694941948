import * as React from 'react';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import * as userApi from "../../../../api/user/userApi";
import {
    Avatar,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    Button,
    TableCell,
    Typography,
    IconButton,
    Tooltip,
    Menu,
    Fade,
    MenuItem,
    Popover
} from '@material-ui/core';
import ApiVirtualizedTable from '../../../../components/_Layout/ApiTables/ApiVirtualizedTable';
import { apiVirtualizedTableConfig } from '../../../../constants/apiVirtualizedTable.config';
import { TableCellRenderer, TableRowRenderer } from 'react-virtualized';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { User } from '../../../../interfaces/user/User';
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MailIcon from '@material-ui/icons/Mail';
import { Email } from '../../../../interfaces/common/Email';
const useStyles = makeStyles((theme: any) => ({
    root: {},
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    content: {
        padding: 0,
        height: '100%'
    },
    inner: {
        height: '100%',
        width: '100%',
        minWidth: 700
    },
    nameCell: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        height: 42,
        width: 42,
        marginRight: theme.spacing(1)
    },
    actions: {
        padding: theme.spacing(1),
        justifyContent: 'flex-end'
    },
    tableCell: {
        flex: 1,
        whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'
    },
    noClick: {
        cursor: 'initial',
    },
    marginRight: {
        marginRight: theme.spacing(1)
    },
    redText: {
        color: theme.palette.error.main
    },
    typography: {
        padding: theme.spacing(2),
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    popOverButtons: {
        justifyContent: 'space-evenly',
        padding: 5
    }

}));

type UserResultsProps = {
    className: string,
    params?: any,
    setSelectedUser: any
}



const Results = (props: UserResultsProps) => {
    const { className, params, setSelectedUser, ...rest } = props;

    const classes = useStyles({});

    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const [selectAllUsers, setSelectAllUsers] = useState<boolean>(false);
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [messageInfo, setMessageInfo] = React.useState<string | undefined>(undefined);
    const [snackBarVariant, setSnackBarVariant] = React.useState<'success' | 'error' | 'warning' | 'info'>('info');
    const userTableRef = React.useRef<any>();
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [currentAction, setCurrentAction] = useState<'delete' | 'activate' | 'inactivate' | 'reset' | null>(null);
    const handleSelectAllClick = () => {
        if (selectAllUsers) {
            // un-select all users
            setSelectedUsers([]);
        }
        else {
            // select users
            setSelectedUsers(allUsers);
        }
        setSelectAllUsers((prevState: boolean) => !prevState);
    };
    var allUsers: User[] = new Array();
    const addNewUser = (user: User) => {
        allUsers.push(user);
    }
    const handleClickMenu = (event: any) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };

    const [popOverAnchorEl, setPopOverAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClickPopOver = (event: any, user: User, action: 'delete' | 'activate' | 'inactivate' | 'reset' | null) => {
        setCurrentUser(user);
        setCurrentAction(action);
        setPopOverAnchorEl(event.currentTarget);
    };

    const handleClosePopOver = () => {
        setPopOverAnchorEl(null);

        setCurrentUser(null);
    };

    const handleBulkInactivate = () => {
        if (selectedUsers.length === 0) {
            setSnackBarVariant('info')
            setMessageInfo('Please select at least one user')
            setShowSnackBar(true)

        }
        else {
            userApi.inactivateAccount(selectedUsers.map(u => u.userId)).then(
                (response: boolean) => {
                    if (response) {
                        setSnackBarVariant('success')
                        setMessageInfo('User(s) successfully inactivated.')
                        setShowSnackBar(true)

                        refreshData(true);
                    }

                    else {
                        setSnackBarVariant('error')
                        setMessageInfo('User inactivation could not be completed. Please try again.')
                        setShowSnackBar(true)
                    }
                },
                (exception: any) => { }
            );
        }
    }
    const handleBulkResetPassword = () => {
        if (selectedUsers.length === 0) {
            setSnackBarVariant('info')
            setMessageInfo('Please select at least one user.')
            setShowSnackBar(true)
        } else {
            var userEmails: Email[] = new Array()
            selectedUsers.forEach(currentUser => {
                let userEmail: Email = { to: [currentUser.email] }
                userEmails.push(userEmail);
            });
            resetUserPasswordUtil(userEmails)
        }
    }
    const handleBulkActivate = () => {
        if (selectedUsers.length === 0) {
            setSnackBarVariant('info')
            setMessageInfo('Please select at least one user.')
            setShowSnackBar(true)
        }
        else {
            userApi.activateAccount(selectedUsers.map(u => u.userId)).then(
                (response: boolean) => {
                    if (response) {
                        setSnackBarVariant('success')
                        setMessageInfo('User(s) successfully activated.')
                        setShowSnackBar(true)

                        refreshData(true);
                    }

                    else {
                        setSnackBarVariant('error')
                        setMessageInfo('User activation could not be completed. Please try again.')
                        setShowSnackBar(true)
                    }
                },
                (exception: any) => { }
            );
        }
    }

    const refreshData = (hardReload?: boolean) => {
        if (userTableRef && userTableRef.current) {
            if (hardReload) {
                userTableRef.current.hardRefresh();

            }
            else {
                userTableRef.current.refresh();
            }
        }
    }
    const options = [
        { label: 'Lock user account', action: handleBulkInactivate },
        { label: 'Activate user account', action: handleBulkActivate },
        { label: 'Reset password', action: handleBulkResetPassword },
    ];

    const popOverOpen = Boolean(popOverAnchorEl);
    const popOverId = popOverOpen ? 'simple-popover' : undefined;

    const handleSelectOne = (event: any, user: User) => {
        var rab = userTableRef.current;
        const selectedIndex = selectedUsers.findIndex((u: User) => u.userId === user.userId);
        let newSelectedUsers: User[] | ((prevState: User[]) => User[]) = [];

        if (selectedIndex === -1) {
            newSelectedUsers = newSelectedUsers.concat(selectedUsers, user);
        } else if (selectedIndex === 0) {
            newSelectedUsers = newSelectedUsers.concat(
                selectedUsers.slice(1)
            );
        } else if (selectedIndex === selectedUsers.length - 1) {
            newSelectedUsers = newSelectedUsers.concat(
                selectedUsers.slice(0, -1)
            );
        } else if (selectedIndex > 0) {
            newSelectedUsers = newSelectedUsers.concat(
                selectedUsers.slice(0, selectedIndex),
                selectedUsers.slice(selectedIndex + 1)
            );
        }
        setSelectedUsers(newSelectedUsers);
        if (selectedUsers.length == allUsers.length) {
            setSelectAllUsers(true);
        } else {
            setSelectAllUsers(false);
        }
    };
    const config = { ...apiVirtualizedTableConfig.usersPaged }
    config.params = params;
    const onEditClicked = (id: string | undefined) => {
        if (id) {
            userApi.getUserDetails(id).then(
                (response: User) => {
                    if (response) {
                        setSelectedUser(response);
                    }
                },
                (exception: any) => { }
            );
        }

    }
    const onPopOverConfirmed = () => {
        if (currentUser) {
            if (currentAction === 'inactivate') {
                userApi.inactivateAccount([currentUser.userId]).then(
                    (response: boolean) => {
                        if (response) {
                            setSnackBarVariant('success')
                            setMessageInfo('User successfully inactivated.')
                            setShowSnackBar(true)

                            refreshData();
                        }

                        else {
                            setSnackBarVariant('error')
                            setMessageInfo('User inactivation could not be completed. Please try again.')
                            setShowSnackBar(true)
                        }
                    },
                    (exception: any) => { }
                );
            }

            else if (currentAction === 'activate') {
                userApi.activateAccount([currentUser.userId]).then(
                    (response: boolean) => {
                        if (response) {
                            setSnackBarVariant('success')
                            setMessageInfo('User successfully activated.')
                            setShowSnackBar(true)

                            refreshData();
                        }

                        else {
                            setSnackBarVariant('error')
                            setMessageInfo('User activation could not be completed. Please try again.')
                            setShowSnackBar(true)
                        }
                    },
                    (exception: any) => { }
                );
            }
            else if (currentAction === 'reset') {
                let email: Email = { to: [currentUser.email] }
                resetUserPasswordUtil([email]);
            }
        }
    }
    const rowRenderer: TableRowRenderer = ({ columns, index, key, style, rowData }) => {
        let content;
        const isActive = rowData ? rowData.value.status.toLowerCase() === 'active' : false;
        return (
            <div
                className={clsx({ [classes.redText]: !isActive })}
                key={key}
                style={style}>
                {columns}
            </div>
        );

    }

    const cellRenderer: TableCellRenderer = ({ cellData, rowData, columnIndex }) => {

        if (rowData) {
            const rowUser: User = {
                userTypeCode: rowData.value.userType,
                email: rowData.value.email,
                fullName: rowData.value.fullName,
                displayName: rowData.value.displayName,
                userStatus: rowData.value.status,
                userId: rowData.text,
                userTypeId: 1,
                password: "",
                confirmPassword: "",
                userStatusId: "",
                accountActive: true,
                defaultModuleId: 0,
                userRoles: [],
                userPermissions: [],
                campuses: [],
                resultStatus: "",
                hasPassed: true,
                sendEmail: true,
                isInstructor: false,
            }
            const isActive = rowData ? rowData.value.status.toLowerCase() === 'active' : false;
            return (
                <TableCell className={classes.flexRow}
                    component="div"
                    variant="body"
                >
                    <Tooltip title="Edit user">
                        <IconButton onClick={() => onEditClicked(rowUser.userId)} >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    {isActive ?
                        <Tooltip title="Lock user">
                            <IconButton onClick={(e: any) => handleClickPopOver(e, rowUser, 'inactivate')} >
                                <LockIcon />
                            </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title="Activate user">
                            <IconButton onClick={(e: any) => handleClickPopOver(e, rowUser, 'activate')} >
                            <LockOpenIcon />
                            </IconButton>
                        </Tooltip>}
                    <Tooltip title="Reset password">
                        <IconButton onClick={(e: any) => handleClickPopOver(e, rowUser, 'reset')} >
                            <MailIcon />
                        </IconButton>
                    </Tooltip>


                </TableCell>
            );
        }
        return (<TableCell
            component="div"
            variant="body"
        >
        </TableCell>)
    };

    const resetUserPasswordUtil = (EmailList: Array<Email>) => {
        userApi.sendResetPasswordEmail(EmailList).then(
            (response: any) => {
                if (response && response.length > 0) {
                    var isFailure = false;
                    for (var i = 0; i < response.length; i++) {
                        if (!response[i].hasPassed) {
                            setSnackBarVariant('error')
                            setMessageInfo(response[i].resultStatus)
                            setShowSnackBar(true)
                            isFailure = true
                        }
                    }
                    if (!isFailure) {
                        setSnackBarVariant('success')
                        setMessageInfo('Email has been sent to reset password')
                        setShowSnackBar(true)
                        refreshData();
                    }
                }
                else {
                    setSnackBarVariant('error')
                    setMessageInfo('User reset password failed. Please try again.')
                    setShowSnackBar(true)
                }
            },
            (exception: any) => { }
        );
    }
    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
            >
            </Typography>
            <Card>
                <CardHeader
                    title="Users"
                    action={
                        <Tooltip title="Bulk Actions" >
                            <IconButton  >
                                <MoreVertIcon onClick={handleClickMenu} />
                            </IconButton>
                        </Tooltip>

                    }
                />
                <Divider />
                <CardContent className={classes.content}>
                    <div className={classes.inner} style={{ height: 500 }}>
                        <Menu
                            id='fade-menu'
                            anchorEl={menuAnchorEl}
                            keepMounted
                            open={menuOpen}
                            onClose={handleCloseMenu}
                            TransitionComponent={Fade}>
                            {options.map((actionItem: any) => {
                                return <MenuItem onClick={function () { handleCloseMenu(); actionItem.action() }}>{actionItem.label}</MenuItem>;
                            })}
                        </Menu>
                        <Popover
                            id={popOverId}
                            open={popOverOpen}
                            anchorEl={popOverAnchorEl}
                            onClose={handleClosePopOver}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Typography color="textSecondary" className={classes.typography}
                                variant="body1">{currentAction === 'inactivate' ? 'Are you sure you want to inactivate this user?'
                                    : currentAction === 'activate' ? 'Are you sure you want to activate this user?'
                                        : currentAction === 'delete' ? 'Are you sure you want to delete this user?' : 'Are you sure you want to reset this user\'s password?'}</Typography>
                            <div className={clsx(classes.flexRow, classes.popOverButtons)}>
                                <Button variant="contained" size="small" onClick={() => { onPopOverConfirmed(); handleClosePopOver(); }} color="primary">
                                    Yes
                                </Button>
                                <Button variant="contained" size="small" onClick={handleClosePopOver} color="secondary">
                                    Cancel
                                </Button>
                            </div>
                        </Popover>
                        <CustomSnackbar
                            variant={snackBarVariant}
                            message={messageInfo}
                            open={showSnackBar}
                            onClose={(event?: React.SyntheticEvent, reason?: string) => {
                                setShowSnackBar(false);
                            }}
                        ></CustomSnackbar>
                        <ApiVirtualizedTable
                            ref={userTableRef}
                            config={config}
                            cellDataGetter={({ rowData, dataKey }) => { if (rowData) return rowData.value[dataKey] }}
                            columns={[
                                {
                                    width: 30,
                                    label: '',
                                    dataKey: 'fullName',
                                    cellRenderer: ({ cellData, rowData, columnIndex }) => {
                                        if (rowData) {
                                            const rowUser: User = {
                                                userTypeCode: rowData.value.userType,
                                                email: rowData.value.email,
                                                fullName: rowData.value.fullName,
                                                displayName: rowData.value.displayName,
                                                userStatus: rowData.value.status,
                                                userId: rowData.text,
                                                userTypeId: 1,
                                                password: "",
                                                confirmPassword: "",
                                                userStatusId: "",
                                                accountActive: true,
                                                defaultModuleId: 0,
                                                userRoles: [],
                                                userPermissions: [],
                                                campuses: [],
                                                resultStatus: "",
                                                hasPassed: true,
                                                sendEmail: true,
                                                isInstructor: false,
                                            }
                                            addNewUser(rowUser);
                                            const isSelected = selectedUsers.findIndex((user: User) => user.userId === rowData.text) !== -1
                                            return (
                                                <TableCell
                                                    component="div"
                                                    className={clsx(classes.tableCell, classes.flexContainer)}
                                                    variant="body"
                                                >
                                                    <Checkbox
                                                        checked={isSelected}
                                                        onChange={(event: any) => handleSelectOne(event, rowUser)}
                                                        value="primary"
                                                        color="primary"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </TableCell>
                                            )
                                        }
                                    }
                                },
                                {
                                    width: 130,
                                    label: 'Full Name',
                                    dataKey: 'fullName',
                                },
                                {
                                    width: 80,
                                    label: 'Display Name',
                                    dataKey: 'displayName',
                                    cellRenderer: ({ cellData, rowData, columnIndex }) => {
                                        return (
                                            <Tooltip title={cellData} placement="top">
                                                <TableCell
                                                    component="div"
                                                    className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
                                                    variant="body"
                                                >
                                                    {cellData}
                                                </TableCell>
                                            </Tooltip>)
                                    }
                                },
                                {
                                    width: 200,
                                    label: 'Email',
                                    dataKey: 'email',
                                    cellRenderer: ({ cellData, rowData, columnIndex }) => {
                                        return (
                                            <Tooltip title={cellData} placement="top">
                                                <TableCell
                                                    component="div"
                                                    className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
                                                    variant="body"
                                                >
                                                    {cellData}
                                                </TableCell>
                                            </Tooltip>

                                        )
                                    }
                                },
                                {
                                    width: 60,
                                    label: 'Type',
                                    dataKey: 'userType',

                                },
                                {
                                    width: 60,
                                    label: 'Status',
                                    dataKey: 'status',
                                    cellRenderer: ({ cellData, rowData, columnIndex }) => {
                                        const isActive = rowData ? rowData.value.status.toLowerCase() === 'active' : false;
                                        return (<TableCell
                                            component="div"
                                            className={clsx(classes.tableCell, classes.flexContainer, {
                                                [classes.redText]: !isActive
                                            }, classes.noClick)}
                                            variant="body"
                                        >
                                            {cellData == 'Active' ?  cellData : 'Locked'}
                                        </TableCell>)
                                    }

                                },
                                {
                                    width: 180,
                                    label: 'Options',
                                    dataKey: 'options',
                                    cellRenderer: cellRenderer
                                }
                            ]}
                        />
                    </div>
                </CardContent>
                <CardActions className={classes.actions}>

                </CardActions>
            </Card>

        </div>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    params: PropTypes.object
};

Results.defaultProps = {
    params: {}
};

export default Results;
