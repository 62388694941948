import React from "react";
import ChargeDefinitionSetup from "../chargeDefinition/ChargeDefinitionSetup";

const ComponentViewer = (props: any) => {
	return (
		<React.Fragment>
			{props.component &&
				props.history.location.pathname.indexOf("chargeDefinitionSetup") >
					-1 && <ChargeDefinitionSetup></ChargeDefinitionSetup>}
		</React.Fragment>
	);
};

export default ComponentViewer;
