import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import { Button, Card, Grid, Typography, CardContent } from "@material-ui/core";
import AwardYearAutoComplete from "../../../../components/AutoComplete/AwardYearAutoComplete";
import { useSelector } from "react-redux";
import InputField from "../../../../components/_Layout/Inputs/InputField";
import { ICostOfAttend } from "../../../../interfaces/student/academics/EnrollmentProgram";
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";
import TextField from "@material-ui/core/TextField";
import { CustomSnackBarProps } from "../../../../interfaces/common/CustomSnackBarProps";
import clsx from "clsx";
import * as enrollmentApi from "../../../../api/student/academics/enrollmentApi";
import { system } from "../../../../constants/system/systemConstants";
import { InputType } from "../../../../constants/uiConstants/inputConstants";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.paper.background,
      "& .MuiCardContent-root": {
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(2),
      },
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.paper.background,
    },
    cardTitle: {
      fontSize: 17,
      color: "Black",
      textDecoration: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(1, 1),
    },
    cardTitle2: {
      fontSize: 15,
      color: "Black",
      height: "20px",
      paddingTop: "5px",
    },
    cardTitle3: {
      //height: "50%",
      minimumHeight: "50%",
    },
    cardTitle4: {
      //height: "50%",
      minimumHeight: "20px",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    fullWidthField: {
      width: "100%",
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      height: "100%",
    },
    details: {
      padding: theme.spacing(2, 0.5),
      flex: "1 1",
    },
    rowContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
      marginBottom: "10px",
    },
    row: {
      width: "48%",
      border: "1px solid #ccc",
      padding: "10px",
    },
    inputUnderline: {
      border: "none",
      borderBottom: "2px solid black",
      outline: "none",
      "&:focus": {
        borderBottom: "2px solid blue",
      },
    },
    gridContainer: {
      paddingTop: "20px",
      paddingLeft: "6px",
    },
  })
);

type CostOfAttendProps = {
  stateWiseLicenseDetails: ICostOfAttend;
  setStateWise: any;
  setSaveAllMethod: any;
  stateId?: string;
  campusId?: string;
  licenseId?: string;
  license?: string;
  totalCOAAwardYear?: string;
  academicYearId?: string;
  studentCOAId?: string;
  stuEnrollId?: string;
};

const CostOfAttendDetails = (props: CostOfAttendProps) => {
  const classes = useStyles({});
  const [campusId, setCampusId] = React.useState<string>();
  const [showGrid, setShowGrid] = useState(false);
  const [showGridMain, setShowGridMain] = useState(false);

  const ThemeTextField = withStyles((theme: any) => createStyles({}))(
    TextField
  );

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );

  const appSettings = useSelector((state: any) => {
    return state.settings.settings;
  });

  const studentEnrollId = useSelector(
    (state: any) => state.student?.selectedEnrollment?.stuEnrollId
  );

  const [showRow, setShowRow] = React.useState(false);

  const handleToggleRow = () => {
    setShowRow((prevState) => !prevState);
  };

  const [data, setData] = useState([
    {
      academicYearId: "",
      totalCOAAwardYear: "",
      studentCOAId: "",
      stuEnrollId: "",
    },
  ]);

  useEffect(() => {
    if (studentEnrollId) {
      const fetchData = async () => {
        try {
          const result = await enrollmentApi.GetCostOfAttend(studentEnrollId);
          setData(result);

          if (result.length == 0) {
            setShowGrid(false);
          } else {
            setShowGrid(true);
          }

          if (result.length == 1) {
            setShowGridMain(true);
          } else {
            setShowGridMain(false);
          }

          if (result.length >= 2) {
            setShowGrid(true);
            setShowGridMain(true);
          } else {
            setShowGrid(false);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [studentEnrollId]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement> | { value: any },
    index: number,
    field: "totalCOAAwardYear" | "academicYearId"
  ) => {
    const value = "value" in e ? e.value : e.target.value;
    const newData = [...data];
    newData[index][field] = value;
    setData(newData);
  };

  const newRows = [
    {
      academicYearId: "",
      totalCOAAwardYear: "",
      studentCOAId: "",
      stuEnrollId: "",
    }, // New row 1
    {
      academicYearId: "",
      totalCOAAwardYear: "",
      studentCOAId: "",
      stuEnrollId: "",
    }, // New row 2
  ];

  const handleAddRows = () => {
    const newRows = [
      {
        academicYearId: "",
        totalCOAAwardYear: "",
        studentCOAId: "",
        stuEnrollId: "",
      }, // New row 1
      {
        academicYearId: "",
        totalCOAAwardYear: "",
        studentCOAId: "",
        stuEnrollId: "",
      }, // New row 2
    ];

    setData((prevData) => {
      const updatedData = [...prevData, ...newRows];
      return updatedData;
    });
  };

  const handleRowChange = (index: number, field: string, value: string) => {
    setData((prevData) =>
      prevData.map((row, i) => (i === index ? { ...row, [field]: value } : row))
    );
  };

  useEffect(() => {
    const handleSaveAll = async () => {
      try {
        const existingAcademicYearIds = data
          .map((row) => row.academicYearId)
          .filter((id) => id && id.trim() !== "");

        const newAcademicYearIds = newRows
          .map((row) => row.academicYearId)
          .filter((id) => id && id.trim() !== "");

        const allAcademicYearIds = [
          ...existingAcademicYearIds,
          ...newAcademicYearIds,
        ];

        const uniqueIds = new Set(allAcademicYearIds);

        if (uniqueIds.size !== allAcademicYearIds.length) {
          setSnackBarProps((props: any) => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo:
                "Error: Duplicate Academic Year entered in COA. Please ensure these values are unique.",
            };
          });

          setSnackBarProps((props: any) => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo:
                "Error: Duplicate Academic Year entered in COA. Please ensure these values are unique.",
            };
          });
          return;
        }

        const validData = data.filter(
          (row) => row.academicYearId && row.academicYearId.trim() !== ""
        );

        const updatePromises = data.map(async (row) => {
          if (row.studentCOAId) {
            if (!row.academicYearId && !row.totalCOAAwardYear) {
              // If both academicYearId and totalCOAAwardYear are empty, delete the row
              return enrollmentApi.DeleteCostOfAttendance(row.studentCOAId);
            } else if (!row.academicYearId || !row.totalCOAAwardYear) {
              // If one is empty, return error
              setSnackBarProps((props: any) => {
                return {
                  variant: "error",
                  showSnackBar: true,
                  messageInfo:
                    "Error: Both Academic Year and Total Amount must be included.",
                };
              });
            }
          }
        });
        const updatePromises2 = validData.map(async (row) => {
          if (row.studentCOAId) {
            // Update existing row
            return enrollmentApi.UpdateCostOfAttendance(
              row.studentCOAId,
              row.academicYearId,
              row.stuEnrollId,
              row.totalCOAAwardYear
            );
          } else {
            return enrollmentApi.AddCostOfAttendance(
              String(row.academicYearId ?? ""),
              String(studentEnrollId),
              String(row.totalCOAAwardYear ?? "")
            );
          }
        });

        await Promise.all(updatePromises);

        await Promise.all(updatePromises2);

        const fetchData = async () => {
          try {
            const result = await enrollmentApi.GetCostOfAttend(studentEnrollId);
            setData(result);

            if (result.length == 0) {
              setShowGrid(false);
            } else {
              setShowGrid(true);
            }

            if (result.length == 1) {
              setShowGridMain(true);
            } else {
              setShowGridMain(false);
            }

            if (result.length >= 2) {
              setShowGrid(true);
              setShowGridMain(true);
            } else {
              setShowGrid(false);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };

        fetchData();

        setSnackBarProps((props: any) => {
          return {
            variant: "success",
            showSnackBar: true,
            messageInfo: "All rows saved successfully.",
          };
        });
      } catch (error) {
        console.error("Error saving data", error);
        setSnackBarProps((props: any) => {
          return {
            variant: "error",
            showSnackBar: true,
            messageInfo: "Error during save operation. Please try again.",
          };
        });
      }
    };
    props.setSaveAllMethod(handleSaveAll);
  }, [props, data, newRows, studentEnrollId]);

  const getAfaIntegratedFlag = () => {
    const afaIntegrationSetting = appSettings[
      system.settings.enableAFAIntegration
    ] as string;
    let afaIntegrationEnabled = false;

    const allowAFAOverrideSetting = appSettings[
      system.settings.allowAFAOverride
    ] as string;
    let allowAFAOverrideEnabled = false;

    if (afaIntegrationSetting) {
      afaIntegrationEnabled = afaIntegrationSetting.toLowerCase() === "yes";
      if (afaIntegrationEnabled)
        if (allowAFAOverrideSetting) {
          allowAFAOverrideEnabled =
            allowAFAOverrideSetting.toLowerCase() === "true";

          if (allowAFAOverrideEnabled) {
            afaIntegrationEnabled = false;
          }
        }
    }

    return afaIntegrationEnabled;
  };

  const isAfaIntegrationEnabled = getAfaIntegratedFlag();

  const handleAddClick = () => {
    setShowGrid(true); // Show columns when Add button is clicked
    setShowGridMain(true);
  };

  const handleAddButton = () => {
    handleAddRows();
    handleAddClick();
  };

  return (
    <Card square={true}>
      <CustomSnackbar
        variant={snackBarProps.variant}
        message={snackBarProps.messageInfo}
        open={snackBarProps.showSnackBar}
        onClose={() => {
          setSnackBarProps((props: any) => {
            return { ...props, showSnackBar: false };
          });
        }}
      ></CustomSnackbar>
      <Typography className={clsx(classes.cardTitle)}>
        &nbsp; Cost of Attendance
      </Typography>
      <CardContent className={classes.cardContent}>
        <Grid container direction="row" spacing={2}></Grid>

        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}></Grid>
        </Grid>

        {/* //------------- */}

        {showGridMain && (
          <Grid
            container
            spacing={1}
            sm={8}
            className={clsx(classes.cardTitle2)}
          >
            <Grid item xs={6} sm={3}>
              <Typography>Award Year</Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography>Total Cost</Typography>
            </Grid>
            {showGrid && (
              <>
                <Grid item xs={6} sm={3}>
                  <Typography>Award Year</Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography>Total Cost</Typography>
                </Grid>
              </>
            )}
          </Grid>
        )}

        <Grid container spacing={1} sm={8} className={classes.cardTitle3}>
          {data.map((row, index) => (
            <React.Fragment key={index}>
              <Grid item xs={6} sm={3} className={classes.cardTitle4}>
                <AwardYearAutoComplete
                  name="academicYearId"
                  label=" "
                  disabled={isAfaIntegrationEnabled}
                  valueFilter={
                    row.academicYearId
                      ? {
                          key: "value",
                          // values: ['0e091441-2091-4e39-be1a-8f6fab8b2da8'],
                          values: [row.academicYearId],
                        }
                      : undefined
                  }
                  filterHandle={(v: any) => {
                    handleInputChange(
                      { value: v ? v.value : undefined },
                      index,
                      "academicYearId"
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={3} className={classes.cardTitle4}>
                <InputField
                  type={InputType.NUMBER}
                  id="totalCOAAwardYear"
                  label=" "
                  name="totalCOAAwardYear"
                  key="totalCOAAwardYear"
                  decimal
                  thousandSeparator
                  defaultValue={row?.totalCOAAwardYear}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleRowChange(index, "totalCOAAwardYear", e.target.value);
                  }}
                  // error={!!data.errors.grossAmount}
                  // inputRef={data.register({
                  // 	required: "Gross amount is required."
                  // })}
                  // helperText={
                  // 	data.errors.grossAmount
                  // 		? (data.errors.grossAmount as FieldError).message
                  // 		: undefined
                  // }
                />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>

        <Grid item xs={12}>
          <h5></h5>
        </Grid>
        <Grid item xs={12}>
          <h5></h5>
        </Grid>
        <Grid container spacing={2} sm={8} className={classes.gridContainer}>
          <Button
            variant="contained"
            disabled={isAfaIntegrationEnabled}
            color="primary"
            onClick={handleAddButton}
          >
            ADD
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CostOfAttendDetails;
