import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Grid, FormControlLabel, Switch } from "@material-ui/core";
import StudentNameBadgeIDFilter from "../../attendance/StudentNameBadgeIDFilter";
import { FormContext, useForm, FieldError } from "react-hook-form";
import StudentGroupsAutoComplete from "../../../components/AutoComplete/StudentGroupsAutoComplete";
import ProgramVersionAutoComplete from "../../../components/AutoComplete/ProgramVersionAutoComplete";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ReportOutput } from "../../../enums/ReportOutput";
import { ReportSort } from "../../../enums/ReportSort";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";
import { IAttendanceRecordsChange } from "../../../interfaces/reports/attendance/IAttendanceRecordsChange";
import { IStudentFilters } from "../../../interfaces/student/enrollment/IStudentFilters";
import * as attendanceRecordsChangeApi from "../../../api/reports/attendance/attendanceRecordsChangeApi";
import { useSelector } from "react-redux";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      minHeight: "100%",
      height: "100%",
      maxWidth: theme.spacing(131.25),
      margin: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    paperRoot: {
      padding: theme.spacing(3, 2),
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
    },
    width100: {
      width: "100%",
    },
    cardTitle: {
      fontSize: theme.spacing(2.15),
      color: theme.palette.primary.headerText,
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
    switch: {
      display: "flex",
      alignItems: "flex-end",
      padding: theme.spacing(0, 0.5),
      height: theme.spacing(6.6),
    },
  })
);

const AttendanceRecordsChanged = (props: any) => {
  const classes = useStyles({});
  const { report } = props;
  let reportOverview = { report: report } as IReportOverview;
  //#endregion Global Const

  //#region State Objects
  const [preview, setPreview] = React.useState<any>();
  const [previewLoader, setPreviewLoader] = React.useState<boolean>();
  const [singleStudent, setSingleStudent] = React.useState<boolean>(true);
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );
  const [inActiveStudentGroup, setInActiveStudentGroup] = React.useState<
    boolean
  >(false);
  const [inActiveProgramVersion, setInActiveProgramVersion] = React.useState<
    boolean
  >(false);
  const defaultFilters = {
    singleStudent: true,
    inActiveStudentGroup: false,
    inActiveProgramVersion: false,
    student: undefined as IStudentFilters | undefined,
    leadGroupIds: [],
    programVersionIds: [],
    month: undefined as Date | undefined,
    startDate: undefined as Date | undefined,
    endDate: undefined as Date | undefined,
    sortType: 1 as ReportSort,
    exportType: 1 as ReportOutput,
  };
  const [model, setModel] = React.useState<any>(defaultFilters);
  //#endregion State Objects End

  //#region Input Handlers
  const handleChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    switch (name) {
      case "showSingleStudent":
        setSingleStudent(event.target.checked);
        handleFieldOnChange("singleStudent", event.target.checked);
        break;
      case "inActiveStudentGroup":
        setInActiveStudentGroup(event.target.checked);
        break;
      case "inActiveProgramVersion":
        setInActiveProgramVersion(event.target.checked);
        break;
    }
  };

  const setStudentGroup = (studentGroups: any) => {
    let newGroups: string[] = [];
    if (studentGroups !== null) {
      if (Array.isArray(studentGroups)) {
        newGroups = studentGroups.map((gr: any) => gr.value);
      } else {
        newGroups.push(studentGroups.value);
      }
    }
    return newGroups;
  };
  const setProgramVersion = (PrgVersions: any) => {
    let newProgramVersion: string[] = [];
    if (PrgVersions !== null) {
      if (Array.isArray(PrgVersions)) {
        newProgramVersion = PrgVersions.map((gr: any) => gr.value);
      } else {
        newProgramVersion.push(PrgVersions.value);
      }
    }
    return newProgramVersion;
  };

  const exportTypes: Array<DropDownListItem> = [
    { text: "PDF", id: "1" },
    { text: "Excel", id: "2" },
    { text: "Word", id: "3" },
  ];

  const exportTypeAutoComplete = {
    options: exportTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const sortTypes: Array<DropDownListItem> = [
    { text: "Name", id: "1" },
    { text: "Enrollment ID", id: "2" },
  ];

  const sortTypeAutoComplete = {
    options: sortTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
    const item = items.find((opt) => {
      if (opt.id == value) return opt;
    });
    return item || ({} as DropDownListItem);
  };
  //#endregion Input Handlers

  //#region Form Handler

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    setModel({ ...updatedModel });
  };
  const data = useForm<any>({ mode: "onBlur" });
  const { handleSubmit } = data;
  const onSubmit = async (isPreview: any): Promise<void> => {
      let params = (await getReportParameters()) as IAttendanceRecordsChange;
      const validation = await data.triggerValidation();
      if (validation) {
        if (isPreview) {
          setPreviewLoader(true);
          attendanceRecordsChangeApi
            .generateReport(params, isPreview)
            .then((res: any) => {
              if (res) {
                res.arrayBuffer().then((buffer: any) => {
                  setPreview(buffer);
                  setPreviewLoader(false);
                });
              } else setPreviewLoader(false);
            });
        } else await attendanceRecordsChangeApi.generateReport(params);
      }
  };
  const filterHandler = (data: any) => {
    if (data) {
      setInActiveStudentGroup(data.inActiveStudentGroup);
      setInActiveProgramVersion(data.inActiveProgramVersion);
      setSingleStudent(data.singleStudent);
      setModel({ ...data });
    } else {
      setSingleStudent(true);
      setInActiveStudentGroup(false);
      setInActiveProgramVersion(false);
      setModel(defaultFilters);
    }
  };

  const getReportParameters = async () => {
    const success = await data.triggerValidation();
    if (model && success) {
      let params: IAttendanceRecordsChange = {
        singleStudent: model.singleStudent,
        studentEnrollmentId: model?.student?.studentNameBadgeId,
        student: model?.student,
        leadGroupIds: model?.leadGroupIds,
        programVersionIds: model?.programVersionIds,
        startDate: model?.startDate,
        endDate: model?.endDate,
        exportType: model?.exportType,
        sortType: model?.sortType,
        campusId: userSelectedCampus,
        inActiveStudentGroup: inActiveStudentGroup,
        inActiveProgramVersion: inActiveProgramVersion,
      };
      return params;
    }
    return {} as IAttendanceRecordsChange;
  };
  //#endRegion Form Handler

  React.useEffect(() => {
    if (singleStudent) {
      setModel({ ...model, leadGroupIds: [], programVersionIds: [] });
    } else {
      setModel({ ...model, student: undefined as IStudentFilters | undefined });
    }
  }, [singleStudent]);
  let parameters = (
    <div className={[classes.root, classes.width100].join(" ")}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item md={12} sm={12} xs={12}>
              <FormControlLabel
                label="Single Student"
                control={
                  <Switch
                    checked={singleStudent}
                    value="showSingleStudent"
                    onChange={handleChange("showSingleStudent")}
                    color="primary"
                  />
                }
              />
            </Grid>
            {singleStudent ? (
              <Grid item md={11} sm={11} xs={12}>
                <StudentNameBadgeIDFilter
                  report={true}
                  show={true}
                  model={{
                    studentNameBadgeId: model?.student?.studentNameBadgeId,
                    studentName: model?.student?.studentName,
                  }}
                  setModel={(values) => {
                    handleFieldOnChange("student", values);
                  }}
                />
              </Grid>
            ) : (
              <React.Fragment>
                <Grid item md={9} sm={9} xs={12}>
                  <StudentGroupsAutoComplete
                    id="leadGroupIds"
                    name="leadGroupIds"
                    error={!!data.errors.leadGroupIds}
                    helperText={
                      data.errors.leadGroupIds
                        ? (data.errors.leadGroupIds as FieldError).message
                        : undefined
                    }
                    inputRef={data.register({
                      validate: {
                        atLeastOneRequired: (value) =>
                          (model.leadGroupIds &&
                            model.leadGroupIds.length > 0) ||
                          "Group(s) are required.",
                      },
                    })}
                    filterHandle={(groups: any) => {
                      handleFieldOnChange(
                        "leadGroupIds",
                        groups,
                        setStudentGroup
                      );
                    }}
                    params={{
                      inActiveStudentGroup: inActiveStudentGroup,
                      disableCache: true,
                    }}
                    label="Student Group *"
                    multiple={true}
                    valueFilter={
                      model?.leadGroupIds
                        ? {
                            key: "value",
                            values: model?.leadGroupIds,
                          }
                        : undefined
                    }
                  />
                </Grid>
                <Grid item md={3} sm={3} xs={12} className={classes.switch}>
                  <FormControlLabel
                    label="InActive Student Groups"
                    control={
                      <Switch
                        checked={inActiveStudentGroup}
                        value="inActiveStudentGroup"
                        onChange={handleChange("inActiveStudentGroup")}
                        color="primary"
                      />
                    }
                  />
                </Grid>
                <Grid item md={9} sm={9} xs={12}>
                  <ProgramVersionAutoComplete
                    id="programVersionIds"
                    name="programVersionIds"
                    label="Program Version *"
                    multiple={true}
                    error={!!data.errors.programVersionIds}
                    helperText={
                      data.errors.programVersionIds
                        ? (data.errors.programVersionIds as FieldError).message
                        : undefined
                    }
                    inputRef={data.register({
                      validate: {
                        atLeastOneRequired: (value) =>
                          (model.programVersionIds &&
                            model.programVersionIds.length > 0) ||
                          "Program Version(s) are required.",
                      },
                    })}
                    params={{
                      inActiveProgramVersion: inActiveProgramVersion,
                    }}
                    selectAll={true}
                    valueFilter={
                      model?.programVersionIds
                        ? {
                            key: "value",
                            values: model?.programVersionIds,
                          }
                        : undefined
                    }
                    filterHandle={(data: any) => {
                      handleFieldOnChange(
                        "programVersionIds",
                        data,
                        setProgramVersion
                      );
                    }}
                  />
                </Grid>
                <Grid item md={3} sm={3} xs={12} className={classes.switch}>
                  <FormControlLabel
                    label="InActive Program Versions"
                    control={
                      <Switch
                        checked={inActiveProgramVersion}
                        value="inActiveProgramVersion"
                        onChange={handleChange("inActiveProgramVersion")}
                        color="primary"
                      />
                    }
                  />
                </Grid>
              </React.Fragment>
            )}
            <Grid item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="startDate"
                  name="startDate"
                  label="Start Date *"
                  value={model.startDate ? model.startDate : null}
                  onChange={(e: any, value: any) =>
                    handleFieldOnChange("startDate", value ? value : undefined)
                  }
                  className={classes.width100}
                  KeyboardButtonProps={{
                    "aria-label": "report date",
                  }}
                  error={!!data.errors["startDate"]}
                  innerRef={(ref: any) => {
                    data.register("startDate", {
                      required: true,
                      validate: {
                        beforeEndDate: (value) =>
                          new Date(value) <
                            new Date(data.getValues()["endDate"]) ||
                          "Start Date must be before end date",
                      },
                    });
                    data.setValue("startDate", model.startDate);
                  }}
                  helperText={
                    data.errors["startDate"]
                      ? (data.errors["startDate"] as FieldError).message
                      : undefined
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  id="endDate"
                  name="endDate"
                  label="End Date *"
                  value={model.endDate ? model.endDate : null}
                  onChange={(e: any, value: any) =>
                    handleFieldOnChange("endDate", value ? value : null)
                  }
                  className={classes.width100}
                  KeyboardButtonProps={{
                    "aria-label": "end date",
                  }}
                  error={!!data.errors["endDate"]}
                  innerRef={(ref: any) => {
                    data.register("endDate", {
                      required: true,
                      validate: {
                        beforeEndDate: (value) =>
                          new Date(value) >
                            new Date(data.getValues()["startDate"]) ||
                          "End Date must be after Start date",
                      },
                    });
                    data.setValue("endDate", model.endDate);
                  }}
                  helperText={
                    data.errors["endDate"]
                      ? (data.errors["endDate"] as FieldError).message
                      : undefined
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Autocomplete
                {...sortTypeAutoComplete}
                autoComplete
                includeInputInList
                onChange={(e: any, value: any) => {
                  handleFieldOnChange(
                    "sortType",
                    value ? value : undefined,
                    (v: any) => v?.id
                  );
                }}
                value={getSelectedItem(sortTypes, model.sortType)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sort Type *"
                    name="sortType"
                    error={!!data.errors.sortType}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.sortType
                        ? "Sort Type is required."
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Autocomplete
                {...exportTypeAutoComplete}
                autoComplete
                includeInputInList
                onChange={(e: any, value: any) => {
                  handleFieldOnChange(
                    "exportType",
                    value ? value : undefined,
                    (v: any) => v?.id
                  );
                }}
                value={getSelectedItem(exportTypes, model.exportType)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Export Type *"
                    name="exportType"
                    error={!!data.errors.exportType}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.exportType
                        ? "Export Type is required."
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </FormContext>
    </div>
  );
  reportOverview.parameters = parameters;

  return (
    <ReportOverview
      reportOverview={reportOverview}
      filterHandler={filterHandler}
      getReportParameters={getReportParameters}
      exportHandler={onSubmit}
      preview={preview}
      previewLoader={previewLoader}
    />
  );
};

export default AttendanceRecordsChanged;
