import React, { Suspense, useState, useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(() =>
    createStyles({
        centered: {
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto"
        }
    })
);

const CenterLoader = () => {
    const classes = useStyles({});

    return (<Grid container justify="center" style={{ height: '100%' }} alignItems="center">
                <Grid item >
                    <CircularProgress ></CircularProgress>
                </ Grid>
            </Grid>);
};

export default CenterLoader;
