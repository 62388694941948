import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { connect, useSelector } from "react-redux";
import { Grid, TextField, Box, Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import "../../index.css";
import { accountActions as actions } from "../../actions/accountActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router-dom";
import { Typography } from '@material-ui/core';
import SchoolLogo from './SchoolLogo';
import SelectedSchoolBackground from './SchoolBackground';
import themeTenantPicker from "../Common/ThemeTenantPicker";


const ForgotPasswordStep1 = (props: any) => {
  const history = useHistory();
  const isTabletMode = useMediaQuery("(max-width:1280px)");
  const themeName = themeTenantPicker();
  const themeBackground = SelectedSchoolBackground(themeName);
  const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      background:  (themeName ==="default") ? theme.palette.login.background : "url(" + themeBackground + ") no-repeat center center fixed",
      backgroundSize: "cover",
      WebkitBackgroundSize: "cover",
      MozBackgroundSize: "cover",
      OBackgroundSize: "cover",
      height: "100% !important",
      minHeight: "100% !important",
      overflow: "hidden !important"
    },
    gridContainer: {
      zIndex: 999999,
      height: "100%"
    },
    gridContainerTabletMode: {
      zIndex: 999999,
      height: "100%"
    },
    loginFormContainer: {
      minWidth: "600px !important",
      maxWidth: "600px !important"
    },
    loginContainer: {
      background: theme.palette.login.background,
      border: "1px solid",
      borderColor: theme.palette.border
    },
    logoContainer: {
      textAlign: "center",
      paddingTop: theme.spacing(4)
    },
    mainLogoContainer: {
      textAlign: "center",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    column: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.primary
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "80%",
      background: "white",
      borderRadius: theme.spacing(0.5)
    },
    button: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.site.primary,
      color: theme.palette.white,
      "&:hover": {
        backgroundColor: theme.palette.primary.main
      }
    },
    errorText: {
      margin: 0,
      padding: 0,
      color: theme.palette.error.dark
    }
  })
);
  const classes = useStyles({});
  const [emailAddress, setEmailAddress] = useState("");
  const account = useSelector((state: any) => state.account);
  const verifyErrors: boolean = account.verifyErrors;
  const [callMessage, setCallMessage] = useState("");

  const historyParm = props.location.search.split("?");
  if(historyParm && historyParm.length == 2 && historyParm[1])
  {
    const historySpliced = historyParm[1].split("=");
    if(historySpliced && historySpliced.length == 2 && historySpliced[1] && emailAddress!=historySpliced[1])
    {
      setEmailAddress(historySpliced[1])
    }
  }
  const isVerifyingEmail: boolean = account.isVerifyingEmail;
  if(account.emailVerified && account.securityQuestion)
  {
    history.push('/ForgotPassword/SecurityQuestion');
  }
  const handleClickEvent = () => {
    props.runVerifyEmail(emailAddress);
  };
	React.useEffect(() => {
    setCallMessage(account.callMessage);
	  }, [account.callMessage]); 

  return (
    <div className={clsx(classes.root)}>
      <Grid
        container
        className={clsx(
          { [classes.gridContainer]: !isTabletMode },
          { [classes.gridContainerTabletMode]: isTabletMode }
        )}
        direction="row"
        alignItems="center"
        justify="center"
      >
        <Grid item xs></Grid>
        <Grid item xs={6} className={classes.loginFormContainer}>
          <div className={classes.loginContainer}>
          <div className={classes.mainLogoContainer}>
              <SchoolLogo schoolName={themeName} logoType="Normal"></SchoolLogo>
            </div>
            <div className={classes.column}>
              <Typography>
              Enter your associated email address to verify its you.
              </Typography>
              <TextField
                required
                className={classes.textField}
                label="Enter email address"
                margin="normal"
                variant="filled"
                value={emailAddress}
                type="email"
                onChange={(e) => {setEmailAddress(e.target.value);}}
                onKeyDown = {
                  (e) => {
                    if (e.key === 'Enter') 
                    handleClickEvent()
                     }
                  }
                  autoFocus={true}
              />
               {verifyErrors && (
                <h5 className={classes.errorText}>
                  {callMessage}
                </h5>
              )}
            </div>
          <div className={classes.column}>
              {!isVerifyingEmail && (
                <Button className={classes.button}  onClick={handleClickEvent}>
                  Verify Email
                </Button>
              )}
              {!isVerifyingEmail && (
                 <Button className={classes.button} variant="outlined"
                 onClick={() => history.push('/login')  
                }>
                   Cancel
                </Button>
              )}
              {isVerifyingEmail && <CircularProgress></CircularProgress>}
            </div>
          </div>
        </Grid>
        <Grid item xs></Grid>
      </Grid>
    </div>
  );
};

function mapState(state: any) {
  return state;
}

const actionCreators = {
  runVerifyEmail: actions.runVerifyEmail
};

const connectedLoginPage = connect(mapState, actionCreators)(ForgotPasswordStep1);
export { connectedLoginPage as ForgotPasswordEmail };
