import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import MainLayout from './components/_Layout/MainLayout';

import { useSelector, useDispatch } from 'react-redux';
import { Login } from './components/security/Login';
import { withRouter } from 'react-router-dom';
import CacheBuster from './utils/CacheBuster';
import CenterLoader from './components/_Layout/Loaders/CenterLoader';
import * as actionTypes from './constants/actions.constants';
import './App.css';
import { ForgotPasswordEmail } from './components/security/ForgotPasswordEmail';
import { ForgotPasswordQuestion } from './components/security/ForgotPasswordQuestion';
import { ForgotPasswordReset } from './components/security/ForgotPasswordReset';
import ResetPasswordForNewUser from './components/security/ResetPasswordForNewUser';
import UserPilotProvider from '../src/UserPilotProvider';



const App = (props: any) => {
	const session = useSelector((state: any) => state.session);
	const dispatch = useDispatch();
	const isAuthenticatedUser: boolean = session.loggedIn() ? true : false;
	useEffect(() => { }, [isAuthenticatedUser]);
	const loginPath = '/login';
	const ForgotPasswordEmailPath = '/ForgotPassword/EnterEmail';
	const ForgotPasswordQuestionPath = '/ForgotPassword/SecurityQuestion';
	const ForgotPasswordResetPath = '/ForgotPassword/Reset';
	const ResetPasswordForNewUserPath = '/ResetPassword';
	const path = props.location.pathname;

	return (
		<CacheBuster>
			{(cacheProps: any) => {
				if (cacheProps.loading && !cacheProps.isLatestVersion) {
					return <CenterLoader></CenterLoader>;
				}
				if (!cacheProps.isLatestVersion && !cacheProps.loading) {
					dispatch({
						type: actionTypes.appConfig.APP_CONFIG_LOADER,
						appConfig: undefined
					});
					cacheProps.refreshCacheAndReload();
				}

				if (path === ForgotPasswordEmailPath || path === ForgotPasswordQuestionPath || path.includes(ForgotPasswordResetPath) ||
					path.includes(ResetPasswordForNewUserPath)) {
					var destinationComponent = ForgotPasswordEmail;
					if (path === ForgotPasswordQuestionPath)
						destinationComponent = ForgotPasswordQuestion;
					else if (path.includes(ForgotPasswordResetPath))
						destinationComponent = ForgotPasswordReset;
					else if (path.includes(ResetPasswordForNewUserPath))
						destinationComponent = ResetPasswordForNewUser;

					return (
						<React.Fragment>
							<Route path={path} component={destinationComponent}></Route>
						</React.Fragment>
					);
				}

				if (!isAuthenticatedUser) {
					if (
						props &&
						props.location &&
						props.location.pathname !== loginPath
					) return <Redirect to={loginPath} />;

					let destinationComponent: any = Login;
					return (
						<React.Fragment>
							<Route path={props.location.pathname} component={destinationComponent}></Route>
						</React.Fragment>
					);
				}
				return (
					<React.Fragment>
						<UserPilotProvider path={props.location.pathname} />
						{isAuthenticatedUser && <MainLayout></MainLayout>}
					</React.Fragment>
				);
			}}
		</CacheBuster>
	);
};


export default withRouter(App);
