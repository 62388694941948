
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";
import { FilterParams } from "../../interfaces/common/FilterParams";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {},
  })
);
const config: ApiAutoCompleteConfigItem = {
  acId: "academicYears",
  cacheTime: 5,
  route: "/SystemCatalog/AcademicCalendar/GetAllAcademicCalendars",
  requestType: "GET",
  body: null,
  hasUrlParams: false,
  useCache: true,
  storeInCache: true,
  isCampusSpecific: true,
  isUserSpecific: false,
  loadOnRender: true,
  defaultSelectedFirstItem: true,
  optionsMappedFx: (data: Array<any>) => {
    return data;
  },
};

const AcademicCalendarAutoComplete = (props: FilterParams &{includeAllValue? : boolean}) => {
  const classes = useStyles({});
  const inputRef: any = useRef(null);
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const handleOnChange = (e: any) => {
    if (props.filterHandle) {
      props.filterHandle(e);
    }
  };
  return (
    <div>
      <ApiAutoComplete
        config={{...config, defaultSelectedFirstItem: props.defaultSelectedFirstItem !== undefined ? props.defaultSelectedFirstItem : config.defaultSelectedFirstItem}}
        loading={userSelectedCampus ? false : true}
        classes={{
          option: "text-black",
        }}
        label={props.label ? props.label : "Academic Calendar"}
        onChange={(e: any) => {
          handleOnChange(e);
        }}
        multiple={false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        inputRef={props.inputRef ? props.inputRef : inputRef}
        error={props.error}
        helperText={props.helperText}
        inputName={props.name}
        includeAllValue={props.includeAllValue !== undefined ? props.includeAllValue : false}
      ></ApiAutoComplete>
    </div>
  );
};

export default AcademicCalendarAutoComplete;
