import API from "../../apiWrapper";
import { IStatusChangeEnrollment, IAddNewStatus } from "../../../interfaces/student/status/IStatusChangeEnrollment";		
import { IStatusHistoryDeletionOption } from "../../../interfaces/student/status/IStatusHistoryDeletionOption";		

  export const ReEnrollFromDrop = (
    changeEnrollment: IStatusChangeEnrollment,
    newStatusCode: string
  ) => {

      return API()
          .post("/AcademicRecords/StatusChanges/CurrentlyAttending/ReEnrollFromDrop", changeEnrollment,{
            params: {
              newStatusCode
            }
            })
          .then(
            (res: any) => {
                if (res && res.data) {
                    return res;
                }
                },
                    (error: any) => {
                    return error;
                }
            );;          
  };

  export const reEnrollFromNoStart = (
    changeEnrollment: IStatusChangeEnrollment,
    newStatusCode: string
  ) => {

      return API()
          .post("/AcademicRecords/StatusChanges/CurrentlyAttending/ReEnrollFromNoStart", changeEnrollment,{
            params: {
                newStatusCode
            }
            })
          .then(
            (res: any) => {
                if (res && res.data) {
                    return res;
                }
                },
                    (error: any) => {
                    return error;
                }
            );;          
  };

  export const ReturnFromLeaveOfAbsence = (
    changeEnrollment: IStatusChangeEnrollment,
    newStatusCode: string
  ) => {

      return API()
          .post("/AcademicRecords/StatusChanges/CurrentlyAttending/ReturnFromLeaveOfAbsence", changeEnrollment,{
            params: {
              newStatusCode
            }
            })
          .then(
            (res: any) => {
                if (res && res.data) {
                    return res;
                }
                },
                    (error: any) => {
                    return error;
                }
            );;          
  };

  export const ReturnFromSuspension = (
    changeEnrollment: IStatusChangeEnrollment,
    newStatusCode: string
  ) => {

      return API()
          .post("/AcademicRecords/StatusChanges/CurrentlyAttending/ReturnFromSuspension", changeEnrollment,{
            params: {
              newStatusCode
            }
            })
          .then(
            (res: any) => {
                if (res && res.data) {
                    return res;
                }
                },
                    (error: any) => {
                    return error;
                }
            );;          
  };

  export const ReturnFromProbation = (
    changeEnrollment: IStatusChangeEnrollment,
    newStatusCode: string
  ) => {

      return API()
          .post("/AcademicRecords/StatusChanges/CurrentlyAttending/ReturnFromProbation", changeEnrollment,{
            params: {
              newStatusCode
            }
            })
          .then(
            (res: any) => {
                if (res && res.data) {
                    return res;
                }
                },
                    (error: any) => {
                    return error;
                }
            );;          
  };

  export const ChangeToCurrentlyAttending = (
    changeEnrollment: IStatusChangeEnrollment,
    newStatusCode: string
  ) => {

      return API()
          .post("/AcademicRecords/StatusChanges/StatusChange/AddStatusChange", changeEnrollment,{
            params: {
              newStatusCode
            }
            })
          .then(
            (res: any) => {
                if (res && res.data) {
                    return res;
                }
                },
                    (error: any) => {
                    return error;
                }
            );;          
  };

  export const AddNewStatus = async (
    newStatusInfo: IAddNewStatus,
    newStatusCode: string,
    isForce: boolean
  ) => {

      return await API()
          .post("/AcademicRecords/StatusChanges/StatusChange/AddNewStatusHistory", newStatusInfo,{
            params: {
              newStatusCode, isForce
            }
            })
          .then(
            (res: any) => {
                if (res && res.data) {
                    return res;
                }
                },
                    (error: any) => {
                    return error;
                }
            );;          
  };


  export const DeleteStatusHistory = async (
    enrollmentId: string | undefined,
    studentStatusChangeId: string | undefined,
    deleteReasonId: string | undefined,
    isForce : boolean
  ) => {

      return await API()
          .post("/AcademicRecords/StatusChanges/StatusChange/DelteStatusHistory", null ,  {
            params: {
              enrollmentId,
              studentStatusChangeId,
              deleteReasonId,
              isForce
            }
        })
          .then(
            (res: any) => {
                if (res && res.data) {
                    return res;
                }
                },
                    (error: any) => {
                    return error;
                }
            );;          
  };


  export const getStatusChangeDeletionReasons = (
  ) => {

      return API()
          .get("/AcademicRecords/StatusChanges/StatusChange/GetDeletionReasonsForStatusHistory")
          .then(
              (res: any) => {
                  if (res && res.data) {
                    return res.data as Array<IStatusHistoryDeletionOption>;
                   }
              },
              (error: any) => {
                  return error;
              }
          );          
  };