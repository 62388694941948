import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import TagManager from "../TagManager/TagManager";
import * as React from "react";
import BreadCrumbPrintMenu from "../../../pages/student/profile/breadCrumbActions/BreadCrumbPrintMenu";
import { Redirect } from "react-router-dom";
import { security } from "../../../constants/Security/securityConstants";
import NewEnrollmentSelectionModal from "../../../pages/student/newEnrollment/NewEnrollmentSelectionModal";
import ImportTimeClockModal from "../../../pages/attendance/ImportTimeClockModal";
import SecondaryMenuModel from "./SecondaryMenuModel";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ImportGradesModal from "../../../pages/academics/ImportGradesModal";

export default [
	{
		pages: [
			{
				title: "Dashboard",
				href: "/dashboard",
				route: "/dashboard",
				actions: [
					{
						icon: PersonAddOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<NewEnrollmentSelectionModal
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									modelProps={{ isOpen: true }}
								/>
							);
						},
						permissions: [security.permissions.student.addNewEnrollment],
					},
				],
			},
			{
				title: "New Enrollment",
				href: "/student/NewEnrollment",
				route: "/student/NewEnrollment",
				actions: [
					{
						icon: PersonAddOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<NewEnrollmentSelectionModal
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									modelProps={{ isOpen: true }}
								/>
							);
						},
						permissions: [security.permissions.student.addNewEnrollment],
					},
				],
			},
			{
				title: "Summary",
				href: "/student/1/summary",
				route: "/student/:id/:tab",
				actions: [
					{
						icon: PersonAddOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<NewEnrollmentSelectionModal
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									modelProps={{ isOpen: true }}
								/>
							);
						},
						permissions: [security.permissions.student.addNewEnrollment],
					},
					{
						icon: MoreVertOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<SecondaryMenuModel
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									menuProps={{ isOpen: true }}
								/>
							);
						},
						permissions: [security.permissions.student.addNewEnrollment],
					},
				],
			},
			{
				title: "Profile",
				href: "/student/1/profile/info",
				route: "/student/:id/profile/:tab",
				actions: [
					{
						icon: PersonAddOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<NewEnrollmentSelectionModal
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									modelProps={{ isOpen: true }}
								/>
							);
						},
						permissions: [security.permissions.student.addNewEnrollment],
					},
					{
						icon: PrintOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<BreadCrumbPrintMenu
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									menuProps={{ isOpen: true }}
								/>
							);
						},
					},
					{
						icon: MoreVertOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<SecondaryMenuModel
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									menuProps={{ isOpen: true }}
								/>
							);
						},
					},
				],
			},
			{
				title: "Grades",
				href: "/student/1/Grades",
				route: "/student/:id/grades/:tab",
				actions: [
					{
						icon: PersonAddOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<NewEnrollmentSelectionModal
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									modelProps={{ isOpen: true }}
								/>
							);
						},
						permissions: [security.permissions.student.addNewEnrollment],
					},
					{
						icon: PrintOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<BreadCrumbPrintMenu
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									menuProps={{ isOpen: true }}
								/>
							);
						},
					},
					{
						icon: MoreVertOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<SecondaryMenuModel
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									menuProps={{ isOpen: true }}
								/>
							);
						},
					},
				],
			},
			{
				title: "Financials",
				href: "/student/1/financials",
				route: "/student/:id/financials/:tab",
				actions: [
					{
						icon: PersonAddOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<NewEnrollmentSelectionModal
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									modelProps={{ isOpen: true }}
								/>
							);
						},
						permissions: [security.permissions.student.addNewEnrollment],
					},
					{
						icon: PrintOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<BreadCrumbPrintMenu
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									menuProps={{ isOpen: true }}
								/>
							);
						},
					},
					{
						icon: MoreVertOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<SecondaryMenuModel
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									menuProps={{ isOpen: true }}
								/>
							);
						},
					},
				],
			},
			{
				title: "SAP Results",
				href: "/student/1/sap",
				route: "/student/:id/sap/:tab",
				actions: [
					{
						icon: PersonAddOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<NewEnrollmentSelectionModal
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									modelProps={{ isOpen: true }}
								/>
							);
						},
						permissions: [security.permissions.student.addNewEnrollment],
					},
					{
						icon: PrintOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<BreadCrumbPrintMenu
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									menuProps={{ isOpen: true }}
								/>
							);
						},
					},
					{
						icon: MoreVertOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<SecondaryMenuModel
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									menuProps={{ isOpen: true }}
								/>
							);
						},
					},
				],
			},
			{
				title: "Placement",
				href: "/student/1/placement",
				route: "/student/:id/placement",
				actions: [
					{
						icon: PersonAddOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<NewEnrollmentSelectionModal
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									modelProps={{ isOpen: true }}
								/>
							);
						},
						permissions: [security.permissions.student.addNewEnrollment],
					},
					{
						icon: PrintOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<BreadCrumbPrintMenu
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									menuProps={{ isOpen: true }}
								/>
							);
						},
					},
					{
						icon: MoreVertOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<SecondaryMenuModel
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									menuProps={{ isOpen: true }}
								/>
							);
						},
					},
				],
			},
			{
				title: "Documents",
				href: "/student/1/documents",
				route: "/student/:id/documents/:tab",
				actions: [
					{
						icon: PersonAddOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<NewEnrollmentSelectionModal
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									modelProps={{ isOpen: true }}
								/>
							);
						},
						permissions: [security.permissions.student.addNewEnrollment],
					},
					{
						icon: PrintOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<BreadCrumbPrintMenu
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									menuProps={{ isOpen: true }}
								/>
							);
						},
					},
					{
						icon: MoreVertOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<SecondaryMenuModel
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									menuProps={{ isOpen: true }}
								/>
							);
						},
					},
				],
			},
			{
				title: "Notes",
				href: "/student/1/notes",
				route: "/student/:id/notes/:tab",
				actions: [
					{
						icon: PersonAddOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<NewEnrollmentSelectionModal
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									modelProps={{ isOpen: true }}
								/>
							);
						},
						permissions: [security.permissions.student.addNewEnrollment],
					},
					{
						icon: PrintOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<BreadCrumbPrintMenu
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									menuProps={{ isOpen: true }}
								/>
							);
						},
					},
					{
						icon: MoreVertOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<SecondaryMenuModel
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									menuProps={{ isOpen: true }}
								/>
							);
						},
					},
				],
			},
			{
				title: "Termination",
				href: "/student/termination",
				route: "/student/termination/:tab",
				actions: [
					{
						icon: PersonAddOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<NewEnrollmentSelectionModal
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									modelProps={{ isOpen: true }}
								/>
							);
						},
						permissions: [security.permissions.student.addNewEnrollment],
					},
					{
						icon: PrintOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<BreadCrumbPrintMenu
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									menuProps={{ isOpen: true }}
								/>
							);
						},
					},
					{
						icon: MoreVertOutlinedIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<SecondaryMenuModel
									anchorEl={e.target.parentElement.parentElement}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
									browserLocationHistory={browserLocationHistory}
									menuProps={{ isOpen: true }}
								/>
							);
						},
					},
				],
			},
			{
				title: "Reports",
				href: "/reports",
				route: "/reports",
				searchable: false,
				actions: [
					//{
					//    text: "Tag Manager",
					//    isSecondary: true, action: () => {
					//      return <TagManager />
					//    }
					//},
				],
			},
			{
				title: "Post Attendance",
				href: "/attendance/1/postAttendance",
				route: "/attendance/1/postAttendance",
				actions: [
					{
						icon: CloudUploadIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<ImportTimeClockModal
									modalProps={{ isOpen: true }}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
								/>
							);
						},
						permissions: [security.permissions.attendance.postAttendance],
					},
				],
			},
			{
				title: "Post Grades",
				href: "/academics/1/postGradesByGroup",
				route: "/academics/1/postGradesByGroup",
				actions: [
					{
						icon: CloudUploadIcon,
						isSecondary: false,
						actionUrl: undefined,
						action: (
							e: any,
							setBreadCrumbBarLoader: any,
							browserLocationHistory?: any
						) => {
							return (
								<ImportGradesModal
									modalProps={{ isOpen: true }}
									setBreadCrumbBarLoader={setBreadCrumbBarLoader}
								/>
							);
						},
						permissions: [security.permissions.academics.postGradesByGroup],
					},
				],
			},
		],
	},
];
