import * as React from "react";
import {
  makeStyles,
  createStyles,
  Grid,
  Paper,
  Table,
  withStyles,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  CircularProgress,
  IconButton,
  Button,
  Card,
  CardContent,
  TextField,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Divider,
} from "@material-ui/core";
import InputField from "../../../../components/_Layout/Inputs/InputField";
import { EmptyGuid } from "../../../../utils/constants";
import { CustomSnackBarProps } from "../../../../interfaces/common/CustomSnackBarProps";
import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";
import IClassDetails from "../../../../interfaces/setup/creditHour/IClassDetails";
import IClassSearchParam from "../../../../interfaces/setup/creditHour/IClassSearchParam";
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";
import CustomCardTitle from "../../../../interfaces/common/card/CustomCardTitle";
import * as ProgramVersionApi from "../../../../api/setup/academics/programCatalogApi";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import ScheduleDetails from "./ScheduleDetails";
import {
  IClassScheduleGridRow,
  ClassScheduleGridRow,
} from "../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/Schedule/IScheduleGridRow";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      fontWeight: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%",
    },
    saveButton: {
      width: 90,
    },
    tableContainer: {
      maxHeight: "400px",
    },
    table: {
      width: "100%",
    },
    iconColor: {
      color: theme.palette.black,
    },
    searchField: {
      "&::placeholder": {
        color: theme.palette.black,
      },
    },
    preLoaderCell: {
      textAlign: "center",
      padding: theme.spacing(1),
    },
    ScheduleSelectionCell: {
      color: theme.palette.paperSummarySchedulerTitle,
      cursor: "pointer",
      fontWeight: 500,
    },
    cardMain: {
      margin: "auto",
      marginTop: theme.spacing(2),
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
    },
  })
);

const ScheduleSetup = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const classes = useStyles({});
  const [searchText, setSearchText] = React.useState<string>("");
  const [isDirty, setDirty] = React.useState<boolean>(false);
  const [confirmationPopupState, setConfirmationPopupState] =
    React.useState<any>({
      id: "simple-popup",
      message: "",
    });

  var baseSearch: IClassSearchParam = {};
  const [searchModel, setSearchModel] =
    React.useState<IClassSearchParam>(baseSearch);

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );
  const [selectedScheduleId, setSelectedScheduleId] = React.useState<string>();
  const [selectedSchedule, setSelectedSchedule] = React.useState<
    IClassScheduleGridRow | undefined
  >(undefined);
  const [trackChangesModel, setTrackChangesModel] =
    React.useState<IClassScheduleGridRow>(new ClassScheduleGridRow());
  const [schedules, setSchedules] = React.useState<
    Array<IClassScheduleGridRow>
  >(new Array<IClassScheduleGridRow>());

  const addNewRecord = () => {
    let newSchedule = new ClassScheduleGridRow();
    setSelectedSchedule({ ...newSchedule });
  };

  React.useEffect(() => {
    getSchedules();
    addNewRecord();
  }, []);

  const refreshSchedules = () => {
    getSchedules();
    addNewRecord();
  };

  const getSchedules = () => {
    ProgramVersionApi.GetSchedules().then(
      (response: Array<IClassScheduleGridRow>) => {
        if (response && response.length > 0) {
          let newData = [...response];
          setSchedules(newData);
        } else {
          setSchedules(new Array<IClassScheduleGridRow>());
        }
      },
      (exception: any) => {}
    );
  };
  const resetChanges = () => {
    let resetModel: ClassScheduleGridRow = trackChangesModel;
    setSelectedSchedule({ ...resetModel });
  };

  const editSchedule = (schedule: IClassScheduleGridRow) => {
    if (isDirty == true) {
      setConfirmationPopupState((state: any) => {
        return {
          ...state,
          open: true,
          confirmationText: "Are you sure you want to discard your changes?",
          confirmationButton: "Yes",
          title: "Discard Changes?",
          onConfirmEvent: () => {
            setDirty(false);
            setSelectedSchedule({ ...schedule });
            setConfirmationPopupState((updatedState: any) => {
              return { ...updatedState, open: false };
            });
          },
          onCancelEvent: () => {
            setConfirmationPopupState((updatedState: any) => {
              return { ...updatedState, open: false };
            });
          },
        };
      });
    } else {
      setSelectedSchedule({ ...schedule });
    }
  };

  const toDisplay =
    searchText && searchText.length > 2
      ? schedules.filter(
          (Schedule) =>
            Schedule.classScheduleId === searchText ||
            (Schedule.name ?? "")
              .toLocaleLowerCase()
              .includes(searchText.toLocaleLowerCase())
        )
      : schedules;
  const handleAdd = () => {};

  return (
    <React.Fragment>
      <CustomSnackbar
        variant={snackBarProps.variant}
        message={snackBarProps.messageInfo}
        open={snackBarProps.showSnackBar}
        onClose={() => {
          setSnackBarProps((props: any) => {
            return { ...props, showSnackBar: false };
          });
        }}
      ></CustomSnackbar>
      <Card square={true} elevation={12} className={classes.cardMain}>
        <CardContent>
          <Grid container direction="column" spacing={2} wrap="nowrap">
            <Grid
              item
              alignContent="flex-start"
              alignItems="flex-start"
              justify="flex-start"
            >
              <Grid container direction="column" spacing={8} wrap="nowrap">
                <Grid
                  item
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={3} md={3}>
                      <Card square={true} elevation={2}>
                        <CustomCardTitle title="Schedule Search"></CustomCardTitle>
                        <CardContent>
                          <InputField
                            value={searchText ?? ""}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => setSearchText(e.target.value)}
                            inputProps={{ className: classes.searchField }}
                            InputProps={{
                              startAdornment: (
                                <SearchIcon className={classes.iconColor} />
                              ),
                              endAdornment: searchText && (
                                <IconButton onClick={() => setSearchText("")}>
                                  <CloseIcon className={classes.iconColor} />
                                </IconButton>
                              ),
                            }}
                            placeholder={"Search Schedule"}
                          />
                          <TableContainer
                            component={Paper}
                            square
                            className={classes.tableContainer}
                          >
                            <Table
                              stickyHeader
                              size="small"
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Schedules</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {loading ? (
                                  <TableRow>
                                    <TableCell
                                      className={classes.preLoaderCell}
                                    >
                                      <CircularProgress />
                                    </TableCell>
                                  </TableRow>
                                ) : (
                                  toDisplay.map((schedule, index) => (
                                    <TableRow key={`row-${index}`}>
                                      <TableCell
                                        key={`ScheduleName-${index}`}
                                        className={
                                          classes.ScheduleSelectionCell
                                        }
                                        onClick={
                                          () => editSchedule(JSON.parse(JSON.stringify(schedule)))
                                        }
                                      >
                                        {schedule.name}
                                      </TableCell>
                                    </TableRow>
                                  ))
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={9} md={9}>
                      <Card square={true} elevation={2}>
                        <CustomCardTitle title="Schedule Detail"></CustomCardTitle>
                        <CardContent>
                          {selectedSchedule !== undefined && (
                            <ScheduleDetails
                              resetChanges={resetChanges}
                              model={selectedSchedule}
                              setModel={setSelectedSchedule}
                              isDirty={isDirty}
                              confirmationPopupState={confirmationPopupState}
                              setConfirmationPopupState={
                                setConfirmationPopupState
                              }
                              setDirty={setDirty}
                              clearFields={addNewRecord}
                              setSnackBar={setSnackBarProps}
                              refreshSchedules={refreshSchedules}
                              setSchedules={setSchedules}
                            />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default ScheduleSetup;
