import axios, { AxiosResponse } from "axios";
import API from "../apiWrapper";
import { LoginUser } from "../../interfaces/security/LoginUser";

export const login = (user: LoginUser) => {
	const dta = { ...user, tenantName: user.tenantName };
	return API()
		.post("/token/login", dta)
		.then(
			(res: any) => {
				//setting localstorage for the api calls
				res && res.data && res.data.campusTimeZoneEnvironment && res.data.campusTimeZoneEnvironment.uxUrl && localStorage.setItem('UXUrl', (res.data.campusTimeZoneEnvironment.uxUrl));
				res && res.data && res.data.campusTimeZoneEnvironment && res.data.campusTimeZoneEnvironment.hostUrl && localStorage.setItem('HostUrl', (res.data.campusTimeZoneEnvironment.hostUrl));
				res && res.data && res.data.campusTimeZoneEnvironment && res.data.campusTimeZoneEnvironment.siteUrl && localStorage.setItem('SiteUrl', (res.data.campusTimeZoneEnvironment.siteUrl));
				res && res.data && res.data.campusTimeZoneEnvironment && res.data.campusTimeZoneEnvironment.apiUrl && localStorage.setItem('ApiUrl', (res.data.campusTimeZoneEnvironment.apiUrl));
				//setting localstorage for the api calls
				return res;
			},
			(error: any) => {
				return error;
			}
		);
};

export const loginWithToken = (token: string, tenantName: string) => {
	return API()
		.post("/token/login-token", { token: token, tenantName: tenantName })
		.then(
			(res: any) => {
				//setting localstorage for the api calls
				res && res.data && res.data.campusTimeZoneEnvironment && res.data.campusTimeZoneEnvironment.uxUrl && localStorage.setItem('UXUrl', (res.data.campusTimeZoneEnvironment.uxUrl));
				res && res.data && res.data.campusTimeZoneEnvironment && res.data.campusTimeZoneEnvironment.hostUrl && localStorage.setItem('HostUrl', (res.data.campusTimeZoneEnvironment.hostUrl));
				res && res.data && res.data.campusTimeZoneEnvironment && res.data.campusTimeZoneEnvironment.siteUrl && localStorage.setItem('SiteUrl', (res.data.campusTimeZoneEnvironment.siteUrl));
				res && res.data && res.data.campusTimeZoneEnvironment && res.data.campusTimeZoneEnvironment.apiUrl && localStorage.setItem('ApiUrl', (res.data.campusTimeZoneEnvironment.apiUrl));
				//setting localstorage for the api calls
				return res;
			},
			(error: any) => {
				return error;
			}
		);
};

export const logout = () => {
	// localStorage.set("user");
	localStorage.removeItem('TrustedDomain');
	localStorage.removeItem('HostUrl');
	localStorage.removeItem('SiteUrl');
	localStorage.removeItem('ApiUrl');
};

export const handleResponse = (response: any) => {
	return response.text().then((text: any) => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				// auto logout if 401 response returned from api
				logout();
				window.location.reload();
			}

			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}

		return data;
	});
};

export const verifyEmail = (email: string) => {
	const request = { Email: email };
	return API()
		.post("/ForgotPassword/VerifyEmail", request)
		.then(
			(res: any) => {
				return res;
			},
			(error: any) => {
				return error;
			}
		);
};


export const verifyAnswer = (email: string, answer: string) => {
	const request = { Email: email, SecurityAnswer: answer, HostName: document.location.protocol + "//" + window.location.host };
	return API()
		.post("/ForgotPassword/VerifySecurityAnswer", request)
		.then(
			(res: any) => {
				return res;
			},
			(error: any) => {
				return error;
			}
		);
};

export const newPassword = (password: string, token: string, identity: string) => {
	const request = { Password: password, Token: token, Identity: identity };
	return API()
		.post("/ForgotPassword/AddNewPassword", request)
		.then(
			(res: any) => {
				return res;
			},
			(error: any) => {
				return error;
			}
		);
};

export const changeUserTerms = (email: string, version: any) => {
	const data = { UserEmail: email, Version: version };
	return API()
		.post("/SystemCatalog/User/UpdateTermsOfUseByEmail", data)
		.then(
			(res: any) => {
				return res;
			},
			(error: any) => {
				return error;
			}
		);
};

export const setPasswordAndQuestion = (password: string, token: string, identity: string, securityQuestion: string, securityAnswer: string) => {
	const request = { Password: password, Token: token, Identity: identity, SecurityQuestion: securityQuestion, SecurityAnswer: securityAnswer };
	return API()
		.post("/SystemCatalog/User/SetSecurityQuestionAnswer", request)
		.then(
			(res: any) => {
				return res;
			},
			(error: any) => {
				return error;
			}
		);
};
