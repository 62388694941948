import { Button, Card, CardActions, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Popover, Table, TableBody, TableCell, TableHead, TableRow, Typography, createStyles, makeStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import CampusForCurrentUserAutoComplete from '../../../../../components/AutoComplete/CampusForCurrentUserAutoComplete';
import ProgramVersionAutoComplete from '../../../../../components/AutoComplete/ProgramVersionAutoComplete';
import { FieldError, useForm } from 'react-hook-form';
import { duplicateConfiguration, saveConfigurations } from '../../../../../api/setup/reports/illinoisTranscript';
import IIllinoisTranscriptReportSettings from '../../../../../interfaces/setup/reports/IIllinoisTranscriptReportSettings';
import CustomCardTitle from '../../../../../interfaces/common/card/CustomCardTitle';
import { toastr } from 'react-redux-toastr';
const useStyles = makeStyles((theme: any) =>
	createStyles({
		closeButton: {
			position: 'absolute',
			right: 0,
			top: 0,
			padding: theme.spacing(1),
		},
		cardTitle: {
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1),
			marginBottom: theme.spacing(1),
		},
		titleTypo: {
			fontSize: 17,
			color: theme.palette.black,
			textDecoration: 'bold',
		},
		headerText: {
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(0),
		},
		popover: {
			maxWidth: 300,
		}
	})
);

const Duplicate: React.FC<{ open: boolean, onClose: () => void, fromConfig?: string, refresh: () => void }> = ({ refresh, open, onClose, fromConfig }) => {
	const classes = useStyles({});
	const [campusId, setCampusId] = React.useState<string>();
	const [campusName, setCampusName] = React.useState<string>();
	const [prgVerId, setPrgVerId] = React.useState<string>();
	const { reset, register, errors, handleSubmit } = useForm({ mode: 'onBlur' });
	const [step, setStep] = React.useState(1);
	const [loading, setLoading] = React.useState(false);
	const [duplicatedConfig, setDuplicatedConfig] = React.useState<IIllinoisTranscriptReportSettings>();
	const [popperAnchorEl, setPopperAnchorEl] = React.useState<null | HTMLElement>(null);
	React.useEffect(() => {
		setCampusId(undefined);
		setPrgVerId(undefined);
		setStep(1);
		setLoading(false);
		setDuplicatedConfig(undefined);
		setPopperAnchorEl(null);
		reset();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);
	register('campusId', { validate: () => campusId ? undefined : 'Campus is required' });
	register('prgVerId', { validate: () => prgVerId ? undefined : 'Program Version is required' });
	const duplicateConfig = async () => {
		setLoading(true);
		try {
			const data = await duplicateConfiguration({ fromConfiguration: fromConfig ?? '', programVersion: prgVerId ?? '' });
			setDuplicatedConfig(data);
			setStep(2);
		} catch (e) {
			toastr.error('', (e as Error)?.message ?? 'An unknown error has occurred.');
		}
		setLoading(false);
	};

	const confirm = async () => {
		if (duplicatedConfig) {
			setLoading(true);
			try {
				await saveConfigurations(duplicatedConfig);
				onClose();
				toastr.success('', 'Configuration saved successfully.');
				refresh();
			} catch (e) {
				toastr.error('', (e as Error)?.message ?? 'An unknown error has occurred.');
			}
			setLoading(false);
			onClose();
		}
		setPopperAnchorEl(null);
	};

	return (
		<Fragment>
			<Dialog open={open} maxWidth="md" fullWidth onClose={onClose}>
				<DialogTitle className={classes.cardTitle}><Typography className={classes.titleTypo}><div className={classes.headerText}>Duplicate</div></Typography> <IconButton className={classes.closeButton} onClick={onClose}><CloseIcon /></IconButton></DialogTitle>
				<DialogContent>
					{loading && <Grid container justifyContent="center" alignContent="center">
						<CircularProgress size={50} color="primary" />
					</Grid>}
					{!loading && step === 1 && <form onSubmit={handleSubmit((v) => console.log(v))}>
						<Grid container spacing={2}>
							<Grid xs={3} item><CampusForCurrentUserAutoComplete
								name="campusId"
								error={!!errors.campusId}
								helperText={
									errors.campusId
										? (errors.campusId as FieldError).message
										: undefined
								}
								valueFilter={campusId ? {
									key: 'value',
									values: [campusId]
								} : undefined} filterHandle={(v: any) => {
									if (v && v.value) {
										setCampusId(v.value);
									}
									else {
										setCampusId(undefined);
									}
									if (v && v.text) {
										setCampusName(v.text);
									}
									else {
										setCampusName(undefined);
									}
								}} />
							</Grid>
							<Grid xs={3} item><ProgramVersionAutoComplete campusId={campusId}
								filterHandle={(v: any) =>
									setPrgVerId(v?.value ?? undefined)
								}
								valueFilter={
									prgVerId
										? {
											key: 'value',
											values: [prgVerId],
										}
										: undefined
								}
								error={!!errors.prgVerId}
								name="prgVerId"
								helperText={
									errors.prgVerId
										? (errors.prgVerId as FieldError).message
										: undefined
								} /></Grid>
						</Grid>
					</form>}
					{
						!loading && step === 2 && <Grid container spacing={2}>
							<Grid item xs={12}>
								<Card>
									<CustomCardTitle title={`${duplicatedConfig?.programVersionName}(${campusName})-[Effective ${duplicatedConfig?.effectiveDate && new Date(duplicatedConfig?.effectiveDate).toLocaleDateString()}]`} />
									<CardContent>
										<Grid container spacing={2}>

											{(duplicatedConfig?.reportConfigurationSubjectAreas || []).sort((a, b) => {
												const sa = a.sortOrder || 1; const sb = b.sortOrder || 1;
												if (sa - sb) {
													return sa - sb;
												}
												const na = (a.subjectAreaTitle || '').toLowerCase(); const nb = (b.subjectAreaTitle || '').toLowerCase();
												if (na > nb)
													return 1;
												if (nb > na)
													return -1;
												return 0;
											}).map((sa, index) => <Grid item xs={12} key={index}>
												<Card >
													<CustomCardTitle title={sa.subjectAreaTitle || ''} />
													<CardContent>
														<Grid container spacing={2}>
															<Grid item xs={6}><Typography variant="body1">Subject Area Description</Typography></Grid>
															<Grid item xs={6}><Typography variant="body1" component="p">{sa.subjectAreaDescription}</Typography></Grid>
														</Grid>
														<Grid container spacing={2}>
															<Grid item xs={6}><Typography variant="body1">Subject Area Minimum Required</Typography></Grid>
															<Grid item xs={6}><Typography variant="body1">{sa.subjectAreaMinRequired}</Typography></Grid>
														</Grid>
														<Grid container spacing={2}>
															<Grid item xs={12}>
																<Table size="small">
																	<TableHead>
																		<TableRow>
																			<TableCell>Components</TableCell>
																		</TableRow>
																	</TableHead>
																	<TableBody>
																		{sa.subjectAreaComponents.map((c, index) => <TableRow key={index}>
																			<TableCell>{c.componentDescription}</TableCell>
																		</TableRow>)}
																	</TableBody>
																</Table>
															</Grid>
														</Grid>
													</CardContent>
												</Card>
											</Grid>)}
										</Grid>
									</CardContent>
								</Card>
							</Grid>
						</Grid>}
				</DialogContent>
				<DialogActions>
					{!loading && step === 1 && <Button variant="contained" color="primary" onClick={handleSubmit(duplicateConfig)}>
						Next
					</Button>}
					{!loading && step === 2 && <Button variant="contained" color="primary"
						onClick={(e) => {
							setPopperAnchorEl(e.currentTarget);
						}}
					>
						Confirm
					</Button>
					}
					{' '}
					<Button onClick={onClose}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
			<Popover
				classes={{ paper: classes.popover }}
				open={Boolean(popperAnchorEl)}
				anchorEl={popperAnchorEl}
				onClose={() => setPopperAnchorEl(null)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Card>
					<CardContent>
						<Typography variant="body1">
							There may be some components that need to be mapped manually. Do you wish to continue?
						</Typography>
					</CardContent>
					<CardActions>
						<Button variant='contained' color='primary' onClick={confirm}>Yes</Button>
						<Button variant='contained' color='secondary' onClick={() => setPopperAnchorEl(null)}>No</Button>
					</CardActions>
				</Card>
			</Popover>
		</Fragment>
	);
};

export default Duplicate;