import { createStyles, makeStyles, Paper } from "@material-ui/core";
import React, { useState } from "react";
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";
import { CustomSnackBarProps } from "../../../../interfaces/common/CustomSnackBarProps";
import { IProgramSetup } from "../../../../interfaces/setup/academics/ProgramProgramVersion/IProgramSetup";
import ProgramDetailsCard from "./Program/ProgramDetailsCard";
import ProgramDetailsCardCreditHr from "./Program/ProgramDetailsCardCreditHr";
import ProgramVersionDetailsCard from "./ProgramVersion/ProgramVersionDetailsCard";
import ProgramCatalogDetailsCard from "./ProgramVersion/ProgramCatalogDetailsCard";
import SearchAndAddProgramCard from "./SearchAndAddProgramsCard";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      width: "100%",
      minHeight: "100%",
      height: "95%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      marginTop: theme.spacing(3),
      backgroundColor: theme.palette.paper.background,
    },
  })
);
const ProgramsSetup = (props: any) => {
  const classes = useStyles({});
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [model, setModel] = useState<IProgramSetup>({
    isProgramVersion: undefined,
    newProgramProgramVersionName: "",
    newProgramVersionProgramId: "",
    selectedProgramProgramVersionId: "",
    isCreditHour: false,
  });

  const handleFieldChange = (fieldId: keyof IProgramSetup, value: any) => {
    let updatedModel = model;
    (updatedModel as any)[fieldId] = value;
    setModel({ ...updatedModel });
  };

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );

  const clearNewProgramProgramVersion = () => {
    let updatedModel = model;
    updatedModel.selectedProgramProgramVersionId = "";
    updatedModel.newProgramProgramVersionName = "";
    updatedModel.isProgramVersion = undefined;
    setModel({ ...updatedModel });
  };

  return (
    <Paper className={classes.root} square={true}>
      <CustomSnackbar
        variant={snackBarProps.variant}
        message={snackBarProps.messageInfo}
        open={snackBarProps.showSnackBar}
        onClose={(event?: React.SyntheticEvent, reason?: string) => {
          setSnackBarProps((props: any) => {
            return { ...props, showSnackBar: false };
          });
        }}
      ></CustomSnackbar>
      <SearchAndAddProgramCard
        refreshData={refreshData}
        setProgramProgramVersionId={(e: any) => {
          handleFieldChange(
            "selectedProgramProgramVersionId",
            e ? e.value : undefined
          );
        }}
        setNewProgramProgramVersionName={(e: any) => {
          handleFieldChange(
            "newProgramProgramVersionName",
            e ? e.text : undefined
          );
        }}
        setNewProgramVersionProgramId={(e: any) => {
          handleFieldChange(
            "newProgramVersionProgramId",
            e ? e.value : undefined
          );
        }}
        setIsProgramVersion={(e?: boolean) => {
          handleFieldChange("isProgramVersion", e);
        }}
        setIsCreditHour={(e?: boolean) => {
          handleFieldChange("isCreditHour", e);
        }}
        addNew={() => {
          //TODO function to clear fields needed
        }}
      />
      {model.isProgramVersion !== undefined &&
        model.isProgramVersion === true &&
        (model.selectedProgramProgramVersionId !== undefined ||
          model.newProgramProgramVersionName) && (
          <div>
            {model.isCreditHour !== undefined && model.isCreditHour == true ? (
              <ProgramCatalogDetailsCard
                programVersionId={model.selectedProgramProgramVersionId}
                setSnackBar={setSnackBarProps}
                newProgramVersionName={model.newProgramProgramVersionName}
                programId={model.newProgramVersionProgramId}
                clearNewProgramProgramVersion={clearNewProgramProgramVersion}
                setRefreshData={setRefreshData}
                refreshDetailData={(value: any) => {
                  handleFieldChange("selectedProgramProgramVersionId", value);
                }}
              />
            ) : (
              <ProgramVersionDetailsCard
                programVersionId={model.selectedProgramProgramVersionId}
                setSnackBar={setSnackBarProps}
                newProgramVersionName={model.newProgramProgramVersionName}
                programId={model.newProgramVersionProgramId}
                clearNewProgramProgramVersion={clearNewProgramProgramVersion}
                setRefreshData={setRefreshData}
                refreshDetailData={(value: any) => {
                  handleFieldChange("selectedProgramProgramVersionId", value);
                }}
              />
            )}
          </div>
        )}
      {model.isProgramVersion !== undefined &&
        model.isProgramVersion === false &&
        (model.selectedProgramProgramVersionId !== undefined ||
          model.newProgramProgramVersionName) && (
          <div>
            {model.isCreditHour !== undefined && model.isCreditHour == true ? (
              <ProgramDetailsCardCreditHr
                programId={model.selectedProgramProgramVersionId}
                setSnackBar={setSnackBarProps}
                newProgramName={model.newProgramProgramVersionName}
                clearNewProgramProgramVersion={clearNewProgramProgramVersion}
                setRefreshData={setRefreshData}
                refreshDetailData={(value: any) => {
                  handleFieldChange("selectedProgramProgramVersionId", value);
                }}
              />
            ) : (
              <ProgramDetailsCard
                programId={model.selectedProgramProgramVersionId}
                setSnackBar={setSnackBarProps}
                newProgramName={model.newProgramProgramVersionName}
                clearNewProgramProgramVersion={clearNewProgramProgramVersion}
                setRefreshData={setRefreshData}
                refreshDetailData={(value: any) => {
                  handleFieldChange("selectedProgramProgramVersionId", value);
                }}
              />
            )}
          </div>
        )}
    </Paper>
  );
};

export default ProgramsSetup;
