import { Action } from "redux";
import { settingConstants } from "../../constants/actions.constants";

const initialState: any = {
    settings: {}
};

const settingsReducer = (state = initialState, action: Action<any>) => {
    switch (action.type) {
        case settingConstants.SET_SETTINGS: {
            return {
                ...state,
                settings: (action as any).settings
            };
        }
       
        default: {
            return state;
        }
    }
};

export default settingsReducer;
