import { Button, Card, CardActions, createStyles, Grid, IconButton, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme } from '@material-ui/core';
import * as React from 'react';
import { CardBody } from 'reactstrap';
import InputField from '../../../../../../components/_Layout/Inputs/InputField';
import { InputType } from '../../../../../../constants/uiConstants/inputConstants';
import CustomCardTitle from '../../../../../../interfaces/common/card/CustomCardTitle';
import AddComponents from './AddComponents';
import { actions as IllinoisTranscriptSettingsActions } from '../../../../../../store/setup/reports/illinoisTranscriptSettings';
import { useForm } from 'react-hook-form';
import IIllinoisTranscriptReportSettingsSubjectArea from '../../../../../../interfaces/setup/reports/IIllinoisTranscriptReportSettingsSubjectArea';
import { EmptyGuid } from '../../../../../../utils/constants';
import IIllinoisTranscriptReportSettingsSubjectAreaComponents from '../../../../../../interfaces/setup/reports/IIllinoisTranscriptReportSettingsSubjectAreaComponents';
import { useActions } from '../../../../../../store/utils';
import { Delete } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		cardBody: {
			minHeight: theme.spacing(50),
			padding: theme.spacing(2)
		},
		components: {
			margin: theme.spacing(0, 2)
		}
	})
);

const SubjectAreaDetails: React.FC<{ selctedIndex?: number; selectedProgramVersion?: string, details: IIllinoisTranscriptReportSettingsSubjectArea, isDirty: boolean, setIsDirty: (value: boolean) => void }> = ({ details, selctedIndex, selectedProgramVersion, setIsDirty }) => {
	const classes = useStyles({});
	const { register, handleSubmit, errors, setValue } = useForm<IIllinoisTranscriptReportSettingsSubjectArea>({ mode: 'onBlur', defaultValues: details });
	const [components, setComponents] = React.useState<Array<{ value: string; text: string; checked?: boolean; }>>([]);
	const [unmappedComponents, setUnmappedComponents] = React.useState<Array<{ value: string; text: string; checked?: boolean; disabled?: boolean }>>([]);
	const actions = useActions({
		addSubjectArea: IllinoisTranscriptSettingsActions.addSubjectArea,
		updateSubjectArea: IllinoisTranscriptSettingsActions.updateSubjectArea,
		removeComponent: IllinoisTranscriptSettingsActions.removeFromDetails,
		selectSubjectArea: IllinoisTranscriptSettingsActions.selectSubjectArea,
	}, []);
	React.useEffect(() => {
		if (details) {
			setValue('subjectAreaTitle', details.subjectAreaTitle);
			setValue('subjectAreaMinRequired', details.subjectAreaMinRequired);
			setValue('subjectAreaDescription', details.subjectAreaDescription);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [details]);
	return (
		<React.Fragment>
			<Card>
				<CustomCardTitle title="Subject Area Details" />
				<CardBody className={classes.cardBody}>
					<Grid container spacing={2}>
						<Grid xs={3} item>
							<InputField type={InputType.TEXT} label="Subject Area Title"
								inputRef={register({ required: 'Title is required.' })}
								name="subjectAreaTitle"
								error={!!errors.subjectAreaTitle}
								helperText={errors.subjectAreaTitle?.message ?? ''}
								onChange={() => setIsDirty(true)}/>
						</Grid>
						<Grid xs={4} item>
							<InputField inputRef={register({ required: 'Minimum Hours is required.', validate: (value) => isNaN(Number(value)) ? 'Minimum Hours must be a number' : true  })}
								name="subjectAreaMinRequired"
								type={InputType.NUMBER}
								decimal
								decimalPlaces={2}
								label="Minimum Hours Required"
								error={!!errors.subjectAreaMinRequired}
								helperText={errors.subjectAreaMinRequired?.message ?? ''}
								onBlur={() => setIsDirty(true)}/>
						</Grid>
						<Grid xs={2} item>
							<InputField
								inputRef={register({ required: 'Sort Order is required', min: { value: 1, message: 'Sort order should 1 or greater.' } })}
								type={InputType.NUMBER}
								label="Sort Order"
								name="sortOrder"
								error={!!errors.sortOrder}
								helperText={errors.sortOrder?.message ?? ''}
								onChange={() => setIsDirty(true)}/>

						</Grid>
						<Grid xs={12} md={8} item>
							<InputField inputRef={register({})} type={InputType.TEXT} variant="outlined" multiline minRows={4} label="Subject Area Description"
								name="subjectAreaDescription" onChange={() => setIsDirty(true)} />
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<TableContainer>
								<Table >
									<TableHead>
										<TableRow>
											<TableCell variant='head'>Subject Area Components</TableCell>
											<TableCell variant='head'></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{
											details && details.subjectAreaComponents?.map((c, i) => <TableRow key={i}>
												<TableCell>{c.componentDescription}</TableCell>
												<TableCell><IconButton onClick={() => { setIsDirty(true); actions.removeComponent(i); }}><Delete /></IconButton></TableCell>
											</TableRow>)
										}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</Grid>
				</CardBody>
				<div className={classes.components}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<AddComponents setIsDirty={setIsDirty} components={components} setMappedComponents={setComponents} setUnmappedComponents={setUnmappedComponents} unmappedComponents={unmappedComponents} selectedSubjectAreaId={details?.subjectAreaId} selectedProgramVersionId={selectedProgramVersion} mappedComponents={details?.subjectAreaComponents} setComponents={(value: Array<IIllinoisTranscriptReportSettingsSubjectAreaComponents>) => setValue('subjectAreaComponents', value, true)} />
						</Grid>
					</Grid>
				</div>
				<CardActions>
					<Button color="primary" variant="contained" onClick={handleSubmit((v) => {
						if (details && (selctedIndex)) {
							if (selctedIndex < 0) {
								actions.addSubjectArea({ ...v, subjectAreaId: EmptyGuid, subjectAreaComponents: components.map(m => ({ subjectAreaComponentId: EmptyGuid, reportConfigurationSubjectAreaId: details.subjectAreaId ?? EmptyGuid, componentDescription: m.text, componentId: m.value })) });
							}
							else {
								actions.updateSubjectArea({ ...v, subjectAreaId: details.subjectAreaId, subjectAreaComponents: [...details.subjectAreaComponents, ...components.map(m => ({ subjectAreaComponentId: EmptyGuid, reportConfigurationSubjectAreaId: details.subjectAreaId ?? EmptyGuid, componentDescription: m.text, componentId: m.value }))] }, selctedIndex);
							}
						}
						setComponents([]);
						setUnmappedComponents([]);
						setIsDirty(true);
					})}>Save Subject Area</Button>
					<Button color="secondary" variant="contained" onClick={() => actions.selectSubjectArea(undefined)}>Cancel</Button>
				</CardActions>
			</Card>
		</React.Fragment>
	);
};

export default SubjectAreaDetails;