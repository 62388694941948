import * as React from 'react';
import { Grow, Paper, ClickAwayListener, MenuList, MenuItem, ButtonGroup, Button, makeStyles, createStyles, Popper } from '@material-ui/core';
import ProgressSaveButton from '../../../components/_Layout/Buttons/ProgressSaveButton';
import { ReportExportType } from '../../../constants/reports/NewAdHocReport';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles((theme: any) =>
    createStyles({
        exportButtonProgress: {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
        },
        exportTypeSelection: {
            minWidth: 104
        }
    })
);
export interface IExportButtonProps {
    progress?: boolean;
    exportFunction: (exportType: number) => void;
}

const ExportButton = (props: IExportButtonProps) => {
    const exportTypeRef = React.useRef(null);
    const [exportType, setExpType] = React.useState(ReportExportType);
    const [exportTypeSelectOpen, setExportTypeSelectOpen] = React.useState(false);
    const classes = useStyles({});
    const { progress, exportFunction } = props;
    const setExportType = (type: number) => { setExpType(type); setExportTypeSelectOpen(false) }
    return (<React.Fragment><ButtonGroup variant="contained" color="primary" aria-label="split button" ref={exportTypeRef}>
        <ProgressSaveButton
            buttonClassName={classes.exportButtonProgress}
            type="button"
            loading={progress || false}
            text="Export"
            onClick={() => exportFunction(exportType)} />
        <Button
            className={classes.exportTypeSelection}
            variant="outlined"
            color="primary"
            aria-controls={exportTypeSelectOpen ? 'split-button-menu' : undefined}
            aria-expanded={exportTypeSelectOpen ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={() => setExportTypeSelectOpen((prevOpen) => !prevOpen)}
        >
            {exportType === 2 ? 'PDF' : 'Excel'}
            <ArrowDropDownIcon />
        </Button>
    </ButtonGroup>
        <Popper open={exportTypeSelectOpen} placement="top-end" anchorEl={exportTypeRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={() => setExportTypeSelectOpen(false)}>
                            <MenuList id="split-button-menu">
                                <MenuItem
                                    selected={exportType === 2}
                                    onClick={() => setExportType(2)}
                                >
                                    PDF
                                </MenuItem>
                                <MenuItem
                                    selected={exportType === 1}
                                    onClick={() => setExportType(1)}
                                >
                                    Excel
                                </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </React.Fragment>);
}

export default ExportButton;