import React from "react";
import themes from "./theme/index";
import { ThemeProvider } from "@material-ui/styles";
import themeTenantPicker from "../src/components/Common/ThemeTenantPicker";
import { cookiesConstants } from "../src/constants/actions.constants";
import Favicon from "react-favicon";
import AvedaFavicon from "./resources/images/AVEDA/favicon-16x16.png";
import PMTSFavicon from "./resources/images/PMTS/favicon-16x16.png";
import TUBCFavicon from "./resources/images/TUBC/favicon-16x16.png";
import ARCLABSFavicon from "./resources/images/ARCLABS/favicon-16x16.png";
import MPIFavicon from "./resources/images/MPI/favicon-16x16.png";
import ANGELESFavicon from "./resources/images/ANGELES/favicon-16x16.png";
import PHAGANSFavicon from "./resources/images/PHAGANS/favicon-16x16.png";
import FABSCHOOLFavicon from "./resources/images/FABSCHOOL/favicon-16x16.png";
import DEFAULTFavicon from "./resources/images/DEFAULT/favicon-32x32.png";
import CELEBRITYFavicon from "./resources/images/CELEBRITY/favicon-32x32.png";
import DPRESSLEYFavicon from "./resources/images/DPRESSLEY/favicon-32x32.png";
import RITZFavicon from "./resources/images/RITZ/favicon-32x32.png";
import ChromeInstituteFavIcon from './resources/images/CHROMEINSTITUTE/schoolLogo32.png';

const getTheme = (school: string) => {
	switch (school) {
		case "PMTS":
			return themes.paulMitchellTheme;
		case "TUBC":
			return themes.tricociTheme;
		case "AVEDA":
			return themes.avedaTheme;
		case "ARCLABS":
			return themes.arcLabsTheme;
		case "MPI":
			return themes.mpiTheme;
		case "ANGELES":
			return themes.angelesTheme;
		case "COLLEGE":
			return themes.mainTheme;
		case "TRAINING":
			return themes.trainingTheme;
		case "FABSCHOOL":
			return themes.fabTheme;
		case "PHAGANS":
			return themes.phagansTheme;
		case "CELEBRITY":
			return themes.celebrityTheme;
		case "DPRESSLEY":
			return themes.davidPressleyTheme;
		case "RITZ":
			return themes.ritzTheme;
		case "CHROMEINSTITUTE":
			return themes.chromeInstituteTheme;
		default:
			return themes.mainTheme;
	}
};
let faviconHref = "";
const AppTheme = (props: any) => {
	const themeName = themeTenantPicker();
	const theme =
		typeof themeName !== "undefined" && themeName !== null
			? getTheme(themeName)
			: getTheme("default");

	if (themeName) {
		switch (themeName) {
			case cookiesConstants.PMTS_THEME:
				faviconHref = PMTSFavicon;
			case cookiesConstants.TUBC_THEME:
				faviconHref = TUBCFavicon;
			case cookiesConstants.AVEDA_THEME:
				faviconHref = AvedaFavicon;
			case cookiesConstants.ARCLABS_THEME:
				faviconHref = ARCLABSFavicon;
			case cookiesConstants.MPI_THEME:
				faviconHref = MPIFavicon;
			case cookiesConstants.ANGELES_THEME:
				faviconHref = ANGELESFavicon;
			case cookiesConstants.PHAGANS_THEME:
				faviconHref = PHAGANSFavicon;
			case cookiesConstants.CELEBRITY_THEME:
				faviconHref = CELEBRITYFavicon;
			case cookiesConstants.FABSCHOOL_THEME:
				faviconHref = FABSCHOOLFavicon;
			case cookiesConstants.DPRESSLEY_THEME:
				faviconHref = DPRESSLEYFavicon;
			case cookiesConstants.RITZ_THEME:
				faviconHref = RITZFavicon;
			case cookiesConstants.TRAINING_THEME:
				faviconHref = DEFAULTFavicon;
			case cookiesConstants.COLLEGE_THEME:
				faviconHref = DEFAULTFavicon;
			case cookiesConstants.CHROME_INSTITUTE_THEME:
				faviconHref = ChromeInstituteFavIcon;
			default:
				faviconHref = DEFAULTFavicon;
		}
	}

	return (
		<ThemeProvider theme={theme}>
			{props.children}
			<Favicon url={faviconHref} />
		</ThemeProvider>
	);
};

export default AppTheme;
