import { useSelector } from "react-redux";
export default (pathname: string) => {
	const userMenu = useSelector((state: any) => state.session.user.menu);
	const nthIndex = (str: string, pat: string, n: number) => {
		var L = str.length,
			i = -1;
		while (n-- && i++ < L) {
			i = str.indexOf(pat, i);
			if (i < 0) break;
		}
		return i;
	};
	let urlTokens: Array<string> = [];
	let breadCrumbs: Array<any> = [];

	if (userMenu) {
		urlTokens.push(...pathname.split('/').map((m, index, arr) => !!m ? `${arr[index - 1] ? `${arr[index - 1]}` : ''}/${m}` : ''));

		let dataParent: any = undefined;
		for (var m = 0; m < urlTokens.length; m++) {
			let token = urlTokens[m];
			dataParent = userMenu.pages.filter((navItem: any) => {
				return navItem.href === token;
			})[0];

			if (dataParent) break;
		}

		if (dataParent) {
			breadCrumbs.push({
				href: dataParent.href,
				title: dataParent.title,
				current: false,
				isParent:true
			});
			if (dataParent.children) {
				let added = false;
				for (let l = m; l <= urlTokens.length - m; l++) {
					let pathPart = urlTokens[l];
					const child = dataParent.children.filter((navItem: any) => {
						return navItem.href.endsWith(pathPart);
					})[0];

					if (child) {
						added = true;
						const isParent = l < urlTokens.length;
						const subChild = child.children && child.children.find((m: any) => m.href.endsWith(urlTokens[l + 1]));
						breadCrumbs.push({
							href: child.href,
							title: child.title,
							current: false,
							isParent:isParent
						});
						if (subChild) {
							breadCrumbs.push({
								href: subChild.href,
								title: subChild.title,
								current: true,
								isParent:false
							});
						}
					}
				}
				if (!added) {
					for (let c = 0; c < dataParent.children.length; c++) {
						for (let l = m; l <= urlTokens.length - m; l++) {
							if (added) break;
							let pathPart = urlTokens[l];
							if (dataParent.children[c].tabs != null) {
								const tab = dataParent.children[c].tabs.tabs.filter(
									(tabItem: any) => {
										return tabItem.href === pathPart;
									}
								)[0];

								if (tab) {
									added = true;
									breadCrumbs.push({
										href: tab.href,
										title: tab.title,
										current: false
									});
								}
							}
						}
					}
				}
			}
		}
	}

	return breadCrumbs;
};
