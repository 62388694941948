import { Notification } from "../interfaces/common/notifications/Notification";
import { ProcessType } from "../enums/ProcessType";
import { NotificationEvent } from "../interfaces/common/notifications/NotificationEvent";
import { notificationTypes } from "../constants/notifications/notificationContants";
import moment from "moment";
import { v1 as uuid } from "uuid";
import { stringify } from "qs";

export const getNotifications = (notificationEvent: NotificationEvent) => {
	switch (notificationEvent.eventType) {
		case notificationTypes.integration.exception:
			return getIntegrationExceptionNotifications(notificationEvent.data);
		case notificationTypes.timeclock.fileImport:
			return {
				id: uuid(),
				title: notificationEvent.data,
				type: "document",
				timestamp: moment().toDate(),
			} as Notification;
		case notificationTypes.reports.generated:
			let obj = JSON.parse(notificationEvent.data)
			let qs = stringify(obj)
	
			return {
				id: uuid(),
				title: "Your weekly/monthly reports generation has completed, click to download the reports.",
				type: "report",
				timestamp: moment().toDate(),
				href: "/reports/downloadReports" + '?'+ qs   
			} as Notification;
		default:
			return null;
	}
};

const getIntegrationExceptionNotifications = (processType: string) => {
	let pt = parseInt(processType);
	if (pt) {
		switch (pt) {
			case ProcessType.Lead:
				return {
					id: uuid(),
					title: "New lead exception has been reported",
					type: "lead",
					timestamp: moment().toDate(),
				} as Notification;
			case ProcessType.Award:
				return {
					id: uuid(),
					title: "New award exception has been reported",
					type: "award",
					timestamp: moment().toDate(),
				} as Notification;
			case ProcessType.Disbursement:
				return {
					id: uuid(),
					title: "New disbursement exception has been reported",
					type: "disbursement",
					timestamp: moment().toDate(),
				} as Notification;
			case ProcessType.Document:
				return {
					id: uuid(),
					title: "New document exception has been reported",
					type: "document",
					timestamp: moment().toDate(),
				} as Notification;
			default:
				return null;
		}
	}
	return null;
};

export default getNotifications;
