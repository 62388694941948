import { Action } from "redux";
import { notificationConstants } from "../../constants/actions.constants";

const initialState: any = {
    newNotification: false
};

const notificationReducer = (state = initialState, action: Action<any>) => {
    switch (action.type) {
        case notificationConstants.NEW_NOTIFICATION: {
            return {
                ...state,
                newNotification: true
            };
        }
        case notificationConstants.NOTIFICATION_VIEWED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default notificationReducer;
