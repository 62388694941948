import { Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import React from 'react';
import CustomCardTitle from '../../../../../interfaces/common/card/CustomCardTitle';

const UnsaveConfirmPopUp: React.FC<{ open?: boolean, cancel: () => void, confirm: () => void }> = ({ open, cancel, confirm }) => {
	return (
		<Dialog
			open={open || false}
			onClose={cancel}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<CustomCardTitle title='Unsaved Changes' />
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					You have unsaved changes. Are you sure you want to leave? All unsaved changes will be lost.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => {
					confirm();
					cancel();
				}} color="primary">
					Yes
				</Button>
				<Button onClick={cancel} color="primary" autoFocus>
					No
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default UnsaveConfirmPopUp;