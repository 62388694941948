import React from "react";
import { useRef } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";
import { RefundTypes } from "../../constants/RefundConstants/refunds.constants";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {},
	})
);

const config: ApiAutoCompleteConfigItem = {
	acId: "emergencyFundsTypeAutoComplete",
	cacheTime: 5,
	route: "/FinancialAid/EmergencyFund/GetEmergencyFundType",
	requestType: "GET",
	body: null,
	hasUrlParams: false,
	useCache: false,
	storeInCache: false,
	isCampusSpecific: false,
	isUserSpecific: false,
	loadOnRender: true,
	defaultSelectedFirstItem: false,
	optionsMappedFx: (data: Array<any>) => {
		return data;
	},
};

export interface RefundTypeProps extends FilterParams {
	className?: any;
}

const EmergencyFundsTypesAutoComplete = (props: RefundTypeProps) => {
	const classes = useStyles({});
	const inputRef: any = useRef(null);

	const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );

	const OnChange = (e: any) => {
        if (props.filterHandle) {
            props.filterHandle(e);
        }
    };

	return (
		<div>
			<ApiAutoComplete
				config={config}
				classes={{
                    option: "text-black"
                }}
                label={props.label ? props.label : "Funds Type"}
                onChange={(e: any) => {
                    OnChange(e);
                }}
                reqParams={{ campusId: userSelectedCampus }}
                multiple={false}
                valueFilter={props.valueFilter ? props.valueFilter : undefined}
                inputRef={props.inputRef ? props.inputRef : inputRef}
                error={props.error}
                helperText={props.helperText}
                inputName={props.name}
			></ApiAutoComplete>
		</div>
	);
};

export default EmergencyFundsTypesAutoComplete;
