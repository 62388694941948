import React from "react";
import { IReportOverview } from "../../../../interfaces/reports/IReportOverview";
import { useSelector } from "react-redux";
import { FormContext, useForm } from "react-hook-form";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import ReportOverview from "../../../../components/_Layout/Reports/ReportOverview";
import CampusForCurrentUserAutoComplete from "../../../../components/AutoComplete/CampusForCurrentUserAutoComplete";
import ProgramAutoComplete from "../../../../components/AutoComplete/ProgramAutoComplete";
import { Autocomplete } from "@material-ui/lab";
import { IFall12MonthEnrollmentPartBDetailAndSummary } from "../../../../interfaces/reports/IFall12MonthEnrollmentPartBDetailAndSummary";
import { getFall12MonthEnrollmentPartBDetailAndSummary } from "../../../../api/reports/ipeds/IPEDSFall";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      minHeight: "100%",
      height: "100%",
      padding: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    paperRoot: {
      padding: theme.spacing(3, 2),
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
    },
    switch: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 0.5),
      height: theme.spacing(8.6),
    },
    errorText: {
      color: "red",
    },
    fieldSet: {
      marginTop: theme.spacing(2),
    },
  })
);

interface IGEReportType {
  reportType: "ProgramSubmittal" | "EnrollmentSubmittal";
  reportTypeDescription: string;
}

interface IAwardYearDataSource {
  startDate: string;
  endDate: string;
  reportYear: number;
  reportYearDescription: string;
}

const Fall12MonthEnrollmentPartBDetailAndSummary = (props: any) => {
  const { report } = props;
  const reportOverview = { report: report } as IReportOverview;
  const classes = useStyles({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [previewData, setPreviewData] = React.useState<any>(undefined);
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const currentYear = new Date().getFullYear();
  const awardYearDataSource = React.useMemo(() => {
    let toReturn: IAwardYearDataSource[] = [];
    let startYear: number = 2015;
    let endYear: number = new Date().getFullYear() + 1;

    for (let i = endYear; i > startYear; i--) {
      toReturn.push({
        endDate: `06/30/${i}`,
        reportYear: i,
        reportYearDescription: `${i - 1}-${i}`,
        startDate: `07/01/${i - 1}`,
      });
    }

    return toReturn;
  }, []);

  const data = useForm<any>({ mode: "onBlur" });
  const initialModel: IFall12MonthEnrollmentPartBDetailAndSummary = {
    campusId: userSelectedCampus,
    startDate: awardYearDataSource.find((m) => m.reportYear === currentYear)
      ?.startDate,
    endDate: awardYearDataSource.find((m) => m.reportYear === currentYear)
      ?.endDate,
    progId: null,
    orderBy: "student number",
    dateRangeText: "text",
    selectedReportYear: currentYear,
  };

  const [model, setModel] =
    React.useState<IFall12MonthEnrollmentPartBDetailAndSummary>(initialModel);

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    if (fieldId == "campusId") {
      (updatedModel as any)["programId"] = [];
      (updatedModel as any)["leadGroupIds"] = [];
    }
    setModel({ ...updatedModel });
  };

  const { handleSubmit } = data;

  const onSubmit = async (isPreview: boolean) => {
    let params =
      (await getReportParameters()) as IFall12MonthEnrollmentPartBDetailAndSummary;
    const validation = await data.triggerValidation();
    if (validation) {
      if (isPreview) {
        setLoading(true);
      }
      const reportData = await getFall12MonthEnrollmentPartBDetailAndSummary(
        params,
        isPreview
      );
      setLoading(false);
      if (isPreview) {
        if (reportData) {
          setPreviewData("data:application/pdf;base64," + reportData);
        }
        setLoading(false);
      }
    }
  };

  const awardYearAutoComplete = {
    options: awardYearDataSource,
    getOptionLabel: (option: IAwardYearDataSource) =>
      option.reportYearDescription,
  };

  const awardYearOnChange = (e: IAwardYearDataSource) => {
    debugger
    if (!!e && !!e.reportYear && e.reportYear > 0) {
      let updatedModel = { ...model };
      updatedModel.startDate = awardYearDataSource.find((m) => m.reportYear === e.reportYear - 1)?.startDate;;
      updatedModel.endDate = awardYearDataSource.find((m) => m.reportYear === e.reportYear - 1)?.endDate;;
      updatedModel.selectedReportYear = e.reportYear;
      setModel({ ...updatedModel });
    }
  };

  const filterHandler = (data: any) => {
    if (data) {
      setModel(data);
    } else {
      setModel(initialModel);
    }
  };

  const getReportParameters = async () => {
    const success = await data.triggerValidation();
    if (model && success) {
      let startDate = model?.startDate ? model.startDate : undefined;
      let endDate = model?.endDate ? model.endDate : undefined;
      let params: IFall12MonthEnrollmentPartBDetailAndSummary = {
        campusId: model?.campusId ?? userSelectedCampus,
        startDate: startDate,
        endDate: endDate,
        progId: model?.progId ?? null,
        selectedReportYear: model.selectedReportYear - 1,
        orderBy: model.orderBy,
        dateRangeText: model.dateRangeText,
      };
      return params;
    }
    return {} as IFall12MonthEnrollmentPartBDetailAndSummary;
  };

  data.register(
    { name: "campusId" },
    {
      validate: () => {
        return model.campusId ? true : "Campus is required.";
      },
    }
  );

  data.register(
    { name: "startDate" },
    {
      validate: () => {
        return model.startDate ? true : "Start Date is required.";
      },
    }
  );

  data.register(
    { name: "endDate" },
    {
      validate: () => {
        return model.endDate ? true : "End Date is required.";
      },
    }
  );

  data.register(
    { name: "orderBy" },
    {
      validate: () => {
        return model.orderBy ? true : "Sort By is required.";
      },
    }
  );

  const parameters = (
    <div className={classes.root}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(() => {})}>
          <Grid container spacing={1}>
            <Grid item md={4} sm={4} xs={12}>
              <CampusForCurrentUserAutoComplete
                name="campusId"
                valueFilter={
                  model.campusId
                    ? {
                        key: "value",
                        values: [model.campusId],
                      }
                    : undefined
                }
                filterHandle={(v: any) => {
                  handleFieldOnChange("campusId", v ? v.value : null);
                }}
                error={!!data.errors.campusId}
                helperText={
                  data.errors.campusId && data.errors.campusId.message
                }
                inputRef={data.register({ required: "Campus is required." })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item md={8} sm={8} xs={12}>
              <ProgramAutoComplete
                chipSize="small"
                maxTags={1}
                multiple
                name="progId"
                valueFilter={
                  model.progId
                    ? {
                        key: "value",
                        values: model.progId.split(","),
                      }
                    : undefined
                }
                filterHandle={(v: any) =>
                  handleFieldOnChange("progId", v || [], (v: any[]) =>
                    v.reduce((a, b) => ((a && a + ",") || "") + b.value, "")
                  )
                }
                label="Programs"
                campusIds={model.campusId ? [model.campusId] : []}
              />
            </Grid>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <Typography>{`Report Date: ${
              awardYearDataSource.findIndex(
                (m) => m.reportYear === model.selectedReportYear
              ) >= 0
                ? `${
                    awardYearDataSource.find(
                      (m) => m.reportYear === model.selectedReportYear - 1
                    )?.startDate
                  } thru ${
                    awardYearDataSource.find(
                      (m) => m.reportYear === model.selectedReportYear - 1
                    )?.endDate
                  }`
                : ""
            }`}</Typography>
            <Autocomplete
              {...awardYearAutoComplete}
              autoComplete
              includeInputInList
              value={
                !!model.selectedReportYear
                  ? awardYearDataSource.find(
                      (m) => m.reportYear == model.selectedReportYear
                    )
                  : null
              }
              onChange={(e: any, v: any) => {
                awardYearOnChange(v);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`Reporting Year *`}
                  name="selectedReportYear"
                  error={!!data.errors.selectedReportYear}
                  inputRef={data.register({ required: true })}
                  helperText={
                    data.errors.exportType
                      ? "Report Year is required."
                      : undefined
                  }
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Sort By</FormLabel>
                <RadioGroup
                  name="orderBy"
                  row
                  value={model.orderBy}
                  onChange={(_, v) => {
                    handleFieldOnChange("orderBy", v);
                  }}
                >
                  <FormControlLabel
                    value="student number"
                    control={<Radio color="primary" />}
                    label="Student Number"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="LastName"
                    control={<Radio color="primary" />}
                    label="Last Name"
                    labelPlacement="end"
                  />
                </RadioGroup>
                <div className={classes.errorText}>
                  {/* {data ? data : ""} */}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </FormContext>
    </div>
  );

  reportOverview.parameters = parameters;

  return (
    <ReportOverview
      reportOverview={reportOverview}
      filterHandler={filterHandler}
      getReportParameters={getReportParameters}
      exportHandler={onSubmit}
      previewLoader={loading}
      preview={previewData}
      isUrlPreview
    />
  );
};

export default Fall12MonthEnrollmentPartBDetailAndSummary;
