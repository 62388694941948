import { Fragment, useState, useEffect } from "react";
import {
  makeStyles,
  createStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
} from "@material-ui/core";
import React from "react";
import { ThemeTextField } from "../../../../components/_Layout/Inputs/ThemeTextField";
import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";
import {
  IChargingMethodIncrement,
  CharginMethodIncrement,
} from "../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/CharginMethod/IChargingMethodIncrement";

import * as IncrementApi from "../../../../api/setup/academics/incrementApi";
import IncrementDetailsCard from "../../../setup/academics/ProgramProgramVersion/ProgramVersion/ChargingMethod/IncrementDetailsCard";
import { EmptyGuid } from "../../../../utils/constants";
import { IIncrementDetails } from "../../../../interfaces/setup/academics/ProgramProgramVersion/ProgramVersion/CharginMethod/IIncrementDetails";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .MuiPaper-rounded": {
        borderRadius: 0,
      },
      "& .MuiDialogContent-root": {
        minHeight: 250,
      },
    },
    dialogHeader: {
      backgroundColor: theme.palette.site.secondary,
      "& .MuiTypography-root": {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: "12pt",
      },
    },
    buttonSection: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    saveButton: {
      width: 360,
    },
    fullWidthField: {
      width: "100%",
    }
  })
);

type CustomIncrementModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  setSnackBar: (snackBarProps: any) => void;
  model: IChargingMethodIncrement;
  setModel: (model: IChargingMethodIncrement) => void;
  incrementId: string;
  isSaveNewCustomIncrement: boolean;
  setIncrement: (incrementId: string) => void;
  setRefreshData: (doRefresh: boolean) => void;
  usesActualHours: boolean;
  billingMethodId?: string;
};
const CustomIncrementModal = (props: CustomIncrementModalProps) => {
  const classes = useStyles({});

  const [areRowsValid, setAreRowsValid] = useState<boolean>(false);

  const checkAllRowsAreValid = () => {
    let invalidRowsArray: IIncrementDetails[];
    invalidRowsArray = props.model.details.filter(
      (row) =>
        row.isEditModeOn === true ||
        (row.usesActualHours &&
          row.actualHours <= 0 &&
          row.chargeAmount <= 0 &&
          (row.transactionCodeId === "" ||
            row.transactionCodeId === undefined)) ||
        (!row.usesActualHours &&
          row.scheduledHours <= 0 &&
          row.chargeAmount <= 0 &&
          (row.transactionCodeId === "" || row.transactionCodeId === undefined))
    );
    return invalidRowsArray.length === 0 && props.model.details.length > 0;
  };

  useEffect(() => {
    let areValid = checkAllRowsAreValid();
    setAreRowsValid(areValid);
  }, [props.model.details]);

  const onSubmit = (e: any) => {
    let modelToSend = props.model;
    if (
      props.incrementId === "" ||
      props.incrementId === undefined ||
      props.incrementId === EmptyGuid
    ) {
      modelToSend.billingMethodId = props.billingMethodId ?? "";
    }
    if (props.isSaveNewCustomIncrement) {
      modelToSend.id = EmptyGuid;

      modelToSend.isCustom = true;
    } else {
      modelToSend.id = props.incrementId;
    }
    IncrementApi.UpsertIncrement(modelToSend).then(
      (response: any) => {
        if (response.resultStatus === 0) {
          props.setRefreshData(true);
          props.setIncrement(response.result.id);

          props.setSnackBar((props: any) => {
            return {
              variant: "success",
              showSnackBar: true,
              messageInfo: response.resultStatusMessage,
            };
          });
        } else {
          props.setSnackBar((props: any) => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo: response.resultStatusMessage,
            };
          });
        }
        props.setOpen(false);
        //props.refreshIncrementDropdown();
        //props.setIncrement();
      },
      (exception: any) => {
        props.setSnackBar((snackBarProps: any) => {
          return {
            variant: "error",
            showSnackBar: true,
            messageInfo: exception,
          };
        });
      }
    );
  };
  const handleClose = () => {
    props.setOpen(false);
  };

  const getIncrementDetails = () => {
    IncrementApi.GetIncrementDetails(props.incrementId).then(
      (response: any) => {
        if (response.resultStatus === 0) {
          if (
            response.result &&
            response.result.id !== EmptyGuid &&
            response.result.id !== ""
          ) {
            props.setModel(response.result);
          } else {
            props.setModel({ ...new CharginMethodIncrement() });
          }
          props.setSnackBar((props: any) => {
            return {
              variant: "success",
              showSnackBar: false,
              messageInfo: response.resultStatusMessage,
            };
          });
        } else {
          props.setSnackBar((props: any) => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo: response.resultStatusMessage,
            };
          });
        }
      },
      (exception: any) => {
        props.setSnackBar((snackBarProps: any) => {
          return {
            variant: "error",
            showSnackBar: true,
            messageInfo: exception,
          };
        });
      }
    );
  };
  const selectedEnrollment = useSelector(
    (state: any) => state.student.selectedEnrollment?.stuEnrollId
  );
  useEffect(() => {
    if (props.incrementId !== EmptyGuid && props.open === true) {
      getIncrementDetails();
    } else {
      let modelToCreate = new CharginMethodIncrement();
      modelToCreate.isCustom = true;
      modelToCreate.studentEnrollmentId = selectedEnrollment;
      props.setModel({ ...modelToCreate });
    }
  }, [props.incrementId, props.open]);
  return (
    <Fragment>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
          {"Customize Automatic Charge Definition"}
        </DialogTitle>
        <DialogContent>
          {props.model !== undefined && (
            <IncrementDetailsCard
              model={props.model}
              setModel={props.setModel}
              isEditable={true}
              usesActualHours={props.usesActualHours}
            />
          )}
        </DialogContent>
        <DialogActions className={classes.buttonSection}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={2}></Grid>
            <Grid
              item
              container
              xs={4}
              alignContent="center"
              alignItems="center"
              justify="center"
            >
              <ProgressSaveButton
                text="Save Definition for Enrollment"
                onClick={(e: any) => {
                  onSubmit(e);
                }}
                loading={false}
                disabled={!areRowsValid}
                buttonClassName={classes.saveButton}
              ></ProgressSaveButton>
            </Grid>
            <Grid
              item
              container
              xs={4}
              alignContent="center"
              alignItems="center"
              justify="center"
            >
              <Button
                onClick={handleClose}
                color="secondary"
                variant="contained"
                type="button"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default CustomIncrementModal;
