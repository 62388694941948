import API from "../../apiWrapper";

export const syncDemographics = (campusId: string) => {
	return API()
		.post("/AFA/Afa/InitialSyncDemographics", campusId)
		.then(
			(res: any) => {
				return res;
			},
			(error: any) => {
				return error;
			}
		);
};

export const syncPrograms = (campusId: string) => {
	return API()
		.post("/AFA/Afa/InitialSyncPrograms", campusId)
		.then(
			(res: any) => {
				return res;
			},
			(error: any) => {
				return error;
			}
		);
};

export const syncEnrollments = (campusId: string) => {
	return API()
		.post("/AFA/Afa/InitialSyncEnrollments", campusId)
		.then(
			(res: any) => {
				return res;
			},
			(error: any) => {
				return error;
			}
		);
};

export const syncAttendance = (campusId: string) => {
	return API()
		.post("/AFA/Afa/InitialSyncAttendance", {
			campusId: campusId,
		})
		.then(
			(res: any) => {
				return res;
			},
			(error: any) => {
				return error;
			}
		);
};

export const syncAwards = (campusId: string) => {
	return API()
		.post("/AFA/Afa/InitialSyncAwards", campusId)
		.then(
			(res: any) => {
				return res;
			},
			(error: any) => {
				return error;
			}
		);
};

export const syncDisbursements = (campusId: string) => {
	return API()
		.post("/AFA/Afa/InitialSyncDisbursements", campusId)
		.then(
			(res: any) => {
				return res;
			},
			(error: any) => {
				return error;
			}
		);
};
