import * as React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		divWidth: {},
	})
);

const config: ApiAutoCompleteConfigItem = {
	acId: "InstitutionsAutoComplete",
	cacheTime: 5,
	route: "/SystemCatalog/Institutions/GetByCampus",
	requestType: "GET",
	body: null,
	hasUrlParams: true,
	useCache: false,
	storeInCache: false,
	isCampusSpecific: true,
	isUserSpecific: false,
	loadOnRender: true,
	defaultSelectedFirstItem: false,
	optionsMappedFx: (data: Array<any>) => {
		return data;
	},
};

const InstitutionsAutoComplete = (props: FilterParams) => {
	const classes = useStyles({});
	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const [nameFilter, setNameFilter] = React.useState<any>({ searchText: null });
	const [requestParameters, setRequestParameters] = React.useState<any>(null);

	React.useEffect(() => {
		if (
			(requestParameters === null && userSelectedCampus) ||
			(userSelectedCampus &&
				requestParameters &&
				(userSelectedCampus !== requestParameters.campusId ||
					nameFilter.searchText !== requestParameters.nameFilter ||
					props.valueFilter?.values[0] !== requestParameters.highSchoolId))
		) {
			setRequestParameters({
				campusId: userSelectedCampus,
				nameFilter: nameFilter.searchText,
				highSchoolId: props.valueFilter?.values[0],
				count: 100,
			});
		}
	}, [userSelectedCampus, nameFilter, props.valueFilter]);

	const handleOnChange = (e: any) => {
		if (props.filterHandle) {
			props.filterHandle(e);
		}
	};

	return (
		<div>
			<ApiAutoComplete
				config={config}
				loading={userSelectedCampus ? false : true}
				reqParams={requestParameters}
				classes={{
					option: "text-black",
				}}
				label={props.label ?? "High School"}
				onKeyUp={(e: any) => {
					if (e.target.value && e.target.value.length >= 3) {
						setNameFilter({ searchText: e.target.value });
					}
				}}
				onChange={(e: any) => {
					handleOnChange(e);
				}}
				disabled={props.disabled ? props.disabled : false}
				multiple={props.multiple ?? false}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
				helperText={props.helperText}
				error={props.error}
				maxTags={props.maxTags}
				includeAllValue={props.includeAllValue}
				showIncludeAllValue={props.showIncludeAllValue}
				chipSize={props.chipSize}
			></ApiAutoComplete>
		</div>
	);
};
export default InstitutionsAutoComplete;
