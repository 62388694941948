import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { connect, useSelector } from "react-redux";
import { Grid, TextField, Box, Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import "../../index.css";
import { accountActions as actions } from "../../actions/accountActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router-dom";
import { Typography } from '@material-ui/core';
import SchoolLogo from './SchoolLogo';
import SelectedSchoolBackground from './SchoolBackground';
import themeTenantPicker from "../Common/ThemeTenantPicker";
import Link from '@material-ui/core/Link';


const ForgotPasswordFinal = (props: any) => {
  const history = useHistory();
  const isTabletMode = useMediaQuery("(max-width:1280px)");
  const themeName = themeTenantPicker();
  const themeBackground = SelectedSchoolBackground(themeName);
  const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      background:  (themeName ==="default") ? theme.palette.login.background : "url(" + themeBackground + ") no-repeat center center fixed",
      backgroundSize: "cover",
      WebkitBackgroundSize: "cover",
      MozBackgroundSize: "cover",
      OBackgroundSize: "cover",
      height: "100% !important",
      minHeight: "100% !important",
      overflow: "hidden !important"
    },
    gridContainer: {
      zIndex: 999999,
      height: "100%"
    },
    gridContainerTabletMode: {
      zIndex: 999999,
      height: "100%"
    },
    loginFormContainer: {
      minWidth: "600px !important",
      maxWidth: "600px !important"
    },
    loginContainer: {
      background: theme.palette.login.background,
      border: "1px solid",
      borderColor: theme.palette.border
    },
    mainLogoContainer: {
      textAlign: "center",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    logoContainer: {
      textAlign: "center",
      paddingTop: theme.spacing(4)
    },
    column: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.primary
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "80%",
      background: "white",
      borderRadius: theme.spacing(0.5)
    },
    button: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.site.primary,
      color: theme.palette.white,
      "&:hover": {
        backgroundColor: theme.palette.primary.main
      }
    },
    errorText: {
      margin: 0,
      padding: 0,
      color: theme.palette.error.dark
    }
  })
);
  const classes = useStyles({});
  const [credentials, setCredentials] = useState(
  {
        NewPassword: "",
        ConfirmPassword: ""  
  })
  const [error_Message, set_error_Message] = useState("");
  const account = useSelector((state: any) => state.account);
  let verifyErrors: boolean = account.verifyErrors;
  const isVerifyingEmail: boolean = account.isVerifyingEmail;
  const callMessage: boolean = account.callMessage;
  
  React.useEffect(() => {
    if(account.callMessage && !isVerifyingEmail)
      {
        alert(account.callMessage);
        if(account.callMessage.includes('changed'))
        window.location.href = "/login";
      }
    }, [account.callMessage]); 
    
  const handleClickEvent = () => {
    set_error_Message("");
    if( credentials.NewPassword.length < 7){
         verifyErrors = true;
         set_error_Message("Error : Password length should be greater than 7");
    }
    else if(credentials.NewPassword!= credentials.ConfirmPassword){
      verifyErrors = true;
      set_error_Message("Error : Password & confirm password do not match");
    }
    else{
          const urlParams = new URLSearchParams(window.location.search);
        if(urlParams && urlParams.get('token') && urlParams.get('identity')){
          const securityToken = urlParams.get('token');
          const identity = urlParams.get('identity');
            props.runUpdatePassword(credentials.NewPassword, securityToken, identity)
        }
      else{
        verifyErrors = true;
        set_error_Message("Invalid security stamp");
      }
    }
  };
  return (
    <div className={clsx(classes.root)}>
      <Grid
        container
        className={clsx(
          { [classes.gridContainer]: !isTabletMode },
          { [classes.gridContainerTabletMode]: isTabletMode }
        )}
        direction="row"
        alignItems="center"
        justify="center"
      >
        <Grid item xs></Grid>
        <Grid item xs={6} className={classes.loginFormContainer}>
          <div className={classes.loginContainer}>
          <div className={classes.mainLogoContainer}>
              <SchoolLogo schoolName={themeName} logoType="Normal"></SchoolLogo>
            </div>
            <div className={classes.column}>
              <Typography>
              Thank you for verifying the account. Please add new credentials.
              </Typography>
              <TextField
                required
                className={classes.textField}
                label="New password"
                margin="normal"
                variant="filled"
                value={credentials.NewPassword}
                type="password"
                onChange={(e) => {
                  setCredentials({...credentials, NewPassword: e.target.value})
                }}
              />

               <TextField
                required
                className={classes.textField}
                label="Confirm new password"
                margin="normal"
                variant="filled"
                value={credentials.ConfirmPassword}
                type="password"
                onChange={(e) => {
                  setCredentials({...credentials, ConfirmPassword: e.target.value})
                }}             
               />
               
               <h5>
                 {error_Message}
               </h5>
              </div>
          <div className={classes.column}>
              {!isVerifyingEmail && (
                <Button className={classes.button}  onClick={handleClickEvent}>
                  Update
                </Button>
              )}

              {!isVerifyingEmail && (
                //  <Button className={classes.button} variant="outlined"
                //  onClick={() => history.push('/login')
                // }
                //  >
                //    Go To Login
                // </Button>
                <Link title="Go to login page" style={{cursor:'pointer'}}
              onClick={() => {
                  window.location.href = window.location.protocol+"//"+window.location.host+"/login";
              }}
              >Go to login</Link>
              )}
              {isVerifyingEmail && <CircularProgress></CircularProgress>}
            </div>
          </div>
        </Grid>
        <Grid item xs></Grid>
      </Grid>
    </div>
  );
};

function mapState(state: any) {
  return state;
}

const actionCreators = {
  runUpdatePassword: actions.runUpdatePassword
};

const connectedPage = connect(mapState, actionCreators)(ForgotPasswordFinal);
export { connectedPage as ForgotPasswordReset };
