import React, { useState, useEffect } from "react";
import packageJson from "../../package.json";

// version from response - first param, local version second param
const semverGreaterThan = (versionA: string, versionB: string) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

const refreshCacheAndReload = () => {
  console.log("Clearing cache and hard reloading...");
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(function(names) {
      for (let name of names) caches.delete(name);
    });
  }

  // delete browser cache and hard reload
  window.location.reload();
};

const CacheBuster = (props: any) => {
  const [loading, setLoading] = React.useState(true);
  const [isLatestVersion, setIsLatestVersion] = React.useState(false);

  useEffect(() => {
    fetch("/meta.json")
      .then(response => response.json())
      .then(meta => {
        const latestVersion = meta.version;
        const currentVersionCachedInTheBrowser = packageJson.version;
        const shouldForceRefresh = semverGreaterThan(
          latestVersion,
          currentVersionCachedInTheBrowser
        );
        if (shouldForceRefresh) {
          console.log(
            "New Version -" + latestVersion + ". Should force refresh"
          );
          setLoading(false);
          setIsLatestVersion(false);
        } else {
          console.log(
            "You already have the latest version - " +
              latestVersion +
              ". No cache refresh needed."
          );
          setIsLatestVersion(true);
        }
      });
  });

  return props.children({
    loading,
    isLatestVersion,
    refreshCacheAndReload
  });
};
export default CacheBuster;
