import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Typography, Paper, Button } from "@material-ui/core";
import AdvantageViewer from "../../../components/Common/AdvantageViewer";
import getAdvantageLink from "../../../utils/getAdvantageLink";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Enrollment } from "../../../interfaces/student/academics/Enrollment";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			height: "100%",
			minHeight: "100%",
			paddingTop: theme.spacing(3),
			overflowX: "hidden",
			overflowY: "auto",
		},
	})
);

const UndoTermination = (props: any) => {
	const history = useHistory();
	const classes = useStyles({});
	const [redirectState, setRedirectState] = React.useState<string | null>(null);
	const advantageLink = getAdvantageLink(props.location.pathname);
	console.log(advantageLink);
	const studentId = useSelector<any, any>(
		(state: any) => state.student.studentId
	);
	const [loaderState, setLoaderState] = React.useState<boolean>(false);
	const [isTransferStudent, setIsTransferStudent] = React.useState<boolean>(
		false
	);
	const selectedEnrollment: Enrollment = useSelector(
		(state: any) => state.student.selectedEnrollment
	);

	const params = new URLSearchParams(window.location.search);
	const programId = params.get("toProgram");
	const programVersionId = params.get("toProgramVersion");
	const transferHours = Number(params.get("transferHours"));
	const transferDate = params.get("transferDate");
	const oldEnrollmentId = params.get("enrollmentId");
	if (programId && programVersionId && transferDate && oldEnrollmentId) {
		if (!isTransferStudent) {
			setIsTransferStudent(true);
		}
	}

	const handleCallBack = () => {
		console.log("Student termination has been undone!")
		const params = new URLSearchParams(window.location.search);
		const toProgram = params.get("toProgram");
		const toProgramVersion = params.get("toProgramVersion");
		const transferHours = params.get("transferHours");
		const transferDate = params.get("transferDate");
		const enrollmentId = params.get("enrollmentId");

		if (
			toProgram &&
			toProgramVersion &&
			transferHours &&
			transferDate &&
			enrollmentId
		) {
			const newQueryString =
				"?toProgram=" +
				toProgram +
				"&toProgramVersion=" +
				toProgramVersion +
				"&transferHours=" +
				transferHours +
				"&transferDate=" +
				transferDate +
				"&enrollmentId=" +
				enrollmentId +
				"&isTransferred=true";
			console.log("Redirect state calling!")
			setRedirectState("/student/newEnrollment" + newQueryString);
		}
		else
		{
			console.log("Redirecting to dashboard!")
			history.replace("/dashboard"+window.location.search+"&undoTermination=true&reloadEnrollmentSelector=true")
		}
	};
	if (redirectState) {
		return <Redirect to={redirectState} />;
	}
	return (
		<div className={classes.root}>
			<AdvantageViewer
				url={advantageLink}
				onCallBack={handleCallBack}
				params={{
					cmpId: selectedEnrollment?.campusId,
					studentId: studentId,
					studentEnrollmentId: selectedEnrollment?.stuEnrollId,
					IsTransferStudent: isTransferStudent,
					desc: "UndoTermination",
					mod: "AR",
				}}
			/>
		</div>
	);
};

export default UndoTermination;
