import * as React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Button, Grid } from "@material-ui/core";
import UserProfile from "./UserProfile";
import UserPermissions from "./UserPermissions";
import { User } from "../../../../../interfaces/user/User";
import { UserRole } from "../../../../../interfaces/systemCatalog/UserRole";
import { UserDetails } from "../../../../../interfaces/user/UserDetails";
import { Permission } from "../../../../../interfaces/security/Permission";
import * as userApi from "../../../../../api/user/userApi";
import CustomSnackbar from "../../../../../components/notifications/CustomSnackbar";
import { EmptyGuid } from "../../../../../utils/constants";
import { useForm, FormContext } from "react-hook-form";
import UserCampuses from "../userMain/UserCampuses";
import { PermissionTemplateDetail } from "../../../../../interfaces/security/PermissionTemplateDetail";
import { Campus } from "../../../../../interfaces/systemCatalog/ICampus";


const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.paper.background,
            "& .MuiCardContent-root": {
                paddingTop: theme.spacing(0),
                paddingLeft: theme.spacing(2)
            }
        },
        "& .makeStyles-content": {
            backgroundColor: theme.palette.paper.background
        },
        paperRoot: {
            padding: theme.spacing(2),
            width: "100%",
            minHeight: "100%",
            height: "100%",
            overflowX: "hidden",
            overflowY: "auto",
            flexDirection: "column",
            backgroundColor: theme.palette.paper.background
        },
        buttonColorPrimary: {
            backgroundColor: theme.palette.buttons.primary,
            color: theme.palette.primary.contrastText,
            "&:hover": {
                backgroundColor: theme.palette.primary.main
            },
            textDecoration: "bold",
            padding: theme.spacing(1),
            marginTop: theme.spacing(3),
            whiteSpace: "nowrap"
        },
        cardSeparator: {
            marginBottom: theme.spacing(1)
        }
    })
);

type FormData = {
    displayName: string;
    email: string;
    userStatusId: string;
    userTypeId: number;
    campuses: Array<string>;
};
type IUserCreateProps = {
    user?: User | null,
    isEditingUser?: boolean,
    onComplete?: any
}

const convertToTemplateDetails = (permissions: Array<Permission> | undefined) => {

    if (permissions)
        return permissions.map((p: Permission) => { return { permissionId: p.permissionId, isEnabled: true, permission: p } as PermissionTemplateDetail })

    return [];
}


const UserMain = (props: IUserCreateProps) => {
    const classes = useStyles({});
    const { isEditingUser, onComplete } = props;
    const user: User = props.user ? props.user :
        {
            userId: EmptyGuid,
            userTypeId: 0,
            userTypeCode: "",
            password: "",
            confirmPassword: "",
            fullName: "",
            displayName: "",
            email: "",
            userStatusId: "",
            userStatus: "",
            accountActive: true,
            defaultModuleId: 0,
            userRoles: [],
            userPermissions: [],
            campuses: [],
            resultStatus: "",
            hasPassed: true,
            sendEmail: true,
            isInstructor: false,
        };
    const [userState, setUserState] = React.useState(user)
    const methods = useForm<any>({
        mode: 'onBlur',
    });
    const { handleSubmit } = methods;

    const userDetails = { ...userState } as UserDetails
    const profileRef = React.useRef<any>();
    const [showSnackBar, setShowSnackBar] = React.useState<boolean>(false);
    const [messageInfo, setMessageInfo] = React.useState<string | undefined>(undefined);
    const [snackBarVariant, setSnackBarVariant] = React.useState<'success' | 'error' | 'warning' | 'info'>('info');
    const [templateDetails, setTemplateDetails] = React.useState<Array<PermissionTemplateDetail>>(convertToTemplateDetails(userState.userPermissions))

    const setUserDetails = (userDetails: UserDetails) => {
        setUserState((state: User) => { return { ...state, userRoles: state.userRoles, userPermissions: state.userPermissions, ...userDetails } })
    }

    const setUserRoles = (newUserRoles: UserRole[]) => {
        setUserState((state: User) => { return { ...state, userPermissions: state.userPermissions, userRoles: newUserRoles } })
    }

    const setCampusChange = (e: any) => {
        setUserState((state: User) => { return { ...state, campuses: e.map((x: any) => { return { id: x.value } as Campus }) } })
    }

    const setUserPermissions = (templateDetails: Array<PermissionTemplateDetail>) => {
        const permissions = templateDetails.filter(td => td.isEnabled).map(td => td.permission);
        setUserState((state: User) => { return { ...state, userPermissions: permissions} })

    }

    const onSubmit = ((data: any, e: any) => {
        if (isEditingUser) {
            userApi.updateUser(userState).then(
                (response: User | null) => {
                    if (response) {

                        onComplete('success', 'User successfully updated');
                    }

                    else {
                        setSnackBarVariant('error')
                        setMessageInfo('User update could not be completed. Please try again.')
                        setShowSnackBar(true)
                    }
                },
                (exception: any) => { }
            );
        }
        else {
            userApi.createUser(userState).then(
                (response: any) => {
                    if (response.data.hasPassed) {
                        onComplete('success', response.data.resultStatus);
                    }
                    else {
                        setSnackBarVariant('error')
                        setMessageInfo(response.data.resultStatus)
                        setShowSnackBar(true)
                    }
                },
                (exception: any) => { }
            );
        }

    });
    return (
        <Paper className={classes.paperRoot} square={true}>
            <CustomSnackbar
                variant={snackBarVariant}
                message={messageInfo}
                open={showSnackBar}
                onClose={(event?: React.SyntheticEvent, reason?: string) => {
                    // if (reason === "clickaway") {
                    // 	return;
                    // }
                    setShowSnackBar(false);
                }}
            ></CustomSnackbar>
            <FormContext {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={classes.cardSeparator}>
                        <UserProfile ref={profileRef} user={userDetails} setUserDetails={setUserDetails} />
                    </div>

                    <div className={classes.cardSeparator}>
                        <UserCampuses campuses={userState.campuses} setUserCampuses={setCampusChange} />
                    </div>

                    <div>

                        <Grid
                            container
                            direction="row"
                            style={{ marginTop: 10 }}
                        >
                            <Grid item xs={12}>
                                <UserPermissions userPermissions={templateDetails} setTemplateDetails={setUserPermissions} />
                            </Grid>
                        </Grid>
                    </div>
                    <Button className={classes.buttonColorPrimary} type='submit' size='small'>
                        Save
			        </Button>
                </form>
            </FormContext>
        </Paper>
    )

};

export default UserMain;
