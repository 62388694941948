import React from 'react';
import clsx from 'clsx';
import {
	createStyles,
	makeStyles,
	withStyles,
} from '@material-ui/core/styles';
import {
	Typography,
	Paper,
	Grid,
	Card,
	CardContent,
	FormControlLabel,
	Switch,
} from '@material-ui/core';
import ProgressSaveButton from '../../../components/_Layout/Buttons/ProgressSaveButton';
import TextField from '@material-ui/core/TextField';
import ConfirmationModal from '../../../components/_Layout/Modal/ConfirmationModal';
import ProgramsAutoComplete from '../../../components/AutoComplete/ProgramsAutoComplete';
import ProgramVersionAutoComplete from '../../../components/AutoComplete/ProgramVersionAutoComplete';
import { useForm, FormContext } from 'react-hook-form';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ITransferStudentToProgramDetail } from '../../../interfaces/student/transfer/IStudentTransferInfo';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as studentTransferApi from "../../../api/student/transfer/studentTransferApi";
import NotificationModal from "../../../components/_Layout/Modal/NotificationModal";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			padding: theme.spacing(0),
			width: '100%',
			minHeight: '100%',
			height: '95%',
			overflowX: 'hidden',
			overflowY: 'auto',
			flexDirection: 'column',
			marginTop: theme.spacing(3),
			backgroundColor: theme.palette.paper.background,
		},
		loader: {
			margin: 'auto',
		},
		cardWrapper: {
			height: '100%',
		},
		textField: {
			marginRight: theme.spacing(1),
			width: '100%',
		},
		cardTitle: {
			fontSize: 17,
			color: theme.palette.black,
			textDecoration: 'bold',
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1),
			marginBottom: theme.spacing(1),
		},
		headerText: {
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(0),
		},
		cardContent: {
			padding: theme.spacing(1, 3),
			height: '100%',
		},
	})
);
const ThemeTextField = withStyles((_theme: any) =>
	createStyles({
		root: {
			'& .MuiFormLabel-root': {
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				width: '80%',
			},
		},
	})
)(TextField);

const ToProgram = () => {
	const classes = useStyles({});
	const data = useForm<any>({ mode: 'onBlur' });
	const [redirectState, setRedirectState] = React.useState<string | null>(null);

	const [model, setModel] = React.useState({
		toProgram: '',
		toProgramVersion: null,
		transferHours: null,
		transferDate: null,
		SwitchProgramVersion: false
	});
	const isSupportUser = useSelector(
		(state: any) => state.session.user.isSupportUser
	  );
	const handleFieldOnChange = (fieldId: string, value: any) => {
		let updatedModel = model;
		(updatedModel as any)[fieldId] = value;
		setModel({ ...updatedModel });
	};

const [notificationPopupState, setNotificationPopupState] = React.useState<
		any
	  >({
		  id: 'simple-popup',
		  message: '',
	  });

	const { handleSubmit } = data;
	const loaderState = false;
	const student = useSelector((state: any) => state.student);
	const [confirmationPopupState, setConfirmationPopupState] = React.useState<
    any
  >({
  	id: 'confirmation-popup',
  	message: '',
  });
  const handleEnrollSwitch = (text: string) => {
	setNotificationPopupState((state: any) => {
		return {
			...state,
			open: true,
			notificationText: text,
			notificationButton: "Ok",
			title: "Switch to Different Program",
			onConfirmEvent: () => {
				setNotificationPopupState((updatedState: any) => {
					window.location.reload();
					return { ...updatedState, open: false };
				});
			},
			onCloseEvent: () => {
				setNotificationPopupState((updatedState: any) => {
					return { ...updatedState, open: false };
				});
			},

		};
	});

}

	const onSubmit = (_d: any) => {
		if (model) {
			let enrollmentId = '';
			let studentId = '';
			if (
				student.selectedEnrollment &&
        student.selectedEnrollment.stuEnrollId
			) {
				enrollmentId = student.selectedEnrollment.stuEnrollId;
				studentId = student.studentId;
			}
      
      

			let modelTransfer: ITransferStudentToProgramDetail = {
				EnrollmentId: enrollmentId,
				NewEnrollmentId: null,
				NewProgramId: model.toProgram,
				NewProgramVersionId: model.toProgramVersion,
				TransferHours: model.transferHours,
				TransferDate: model.transferDate,
				SwitchProgramVersion: model.SwitchProgramVersion,
			};

			data.triggerValidation().then((validation: any) => {
				if (validation && modelTransfer.TransferDate !== null) {

					if (model.SwitchProgramVersion == false) {

					const queryStringParams =
            '?toProgram=' +
            model.toProgram +
            '&toProgramVersion=' +
            model.toProgramVersion +
            '&transferHours=' +
            model.transferHours +
            '&transferDate=' +
            new Date(modelTransfer.TransferDate).toLocaleDateString() +
            '&enrollmentId=' +
            enrollmentId +
            '&studentId=' +
            studentId;



					setConfirmationPopupState((state: any) => {
						return {
							...state,
							open: true,
							confirmationText:
                'Please drop the student before transferring to a new program.',
							confirmationButton: 'Ok',
							title: 'Transfer to Different Program',
							onConfirmEvent: () => {
								setConfirmationPopupState((updatedState: any) => {
									return { ...updatedState, open: false };
								});
								const redirectURL = '/student/termination' + queryStringParams;
								setRedirectState(redirectURL);
							},
							onCancelEvent: () => {
								setConfirmationPopupState((updatedState: any) => {
									return { ...updatedState, open: false };
								});
							},
						};
					});

				}else {
					setConfirmationPopupState((state: any) => {
						return {
							...state,
							open: true,
							confirmationText:
                'Students will be switched to new program, please check new schedule is correct.',
							confirmationButton: 'Ok',
							title: 'Switch to Different Program',
							onConfirmEvent: () => {
								setConfirmationPopupState((updatedState: any) => {
									return { ...updatedState, open: false };
								});
					
								let modelSwitch: ITransferStudentToProgramDetail = {
									EnrollmentId: enrollmentId,
									NewEnrollmentId: null,
									NewProgramId: model.toProgram,
									NewProgramVersionId: model.toProgramVersion,
									TransferHours: model.transferHours,
									TransferDate: model.transferDate,
									SwitchProgramVersion: model.SwitchProgramVersion,
								};
								studentTransferApi.TransferStudentToProgramSupport(modelSwitch).then(
									(response: any) => {
										if (response.resultStatus === 1) {
											handleEnrollSwitch("The student was not switched to the new program.");
										} else {
										
											handleEnrollSwitch("The student was switched to the new program.");
										}
									},
									(exception: any) => {
										handleEnrollSwitch("The student was not switch to the new program.");
									}
								);
						
								
								
							},
							onCancelEvent: () => {
								setConfirmationPopupState((updatedState: any) => {
									return { ...updatedState, open: false };
								});
							},
						};
					});

				}
				}
			});
		}
	};
	if (redirectState) {
		return <Redirect to={redirectState} />;
	}
	return (
		<FormContext {...data}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Paper className={classes.root} square={true}>
					<Card square={true} className={classes.cardWrapper}>
						<Typography className={clsx(classes.cardTitle)}>
							<div className={classes.headerText}>
                Transfer or Switch Student Enrollment to a different program
							</div>
						</Typography>
						<CardContent className={classes.cardContent}>
							<Typography color="textPrimary" variant="h5">
                New Enrollment Detail
							</Typography>
							<Grid container direction="row" spacing={2}>
								<Grid item md={4} sm={6} xs={12}>
									<ProgramsAutoComplete
										id="toProgram"
										name="toProgram"
										label="To Program *"
										filterHandle={(v: any) => {
											handleFieldOnChange('toProgram', v ? v.value : undefined);
										}}
										valueFilter={
											model.toProgram
												? {
													key: 'value',
													values: [model.toProgram],
												}
												: undefined
										}
										activePrograms={true}
										error={!!data.errors.toProgram}
										inputRef={data.register({ required: true })}
										helperText={
											data.errors.toProgram ? 'Program is required.' : undefined
										}
									/>
								</Grid>
								<Grid item md={4} sm={6} xs={12}>
									<ProgramVersionAutoComplete
										id="toProgramVersion"
										name="toProgramVersion"
										label="To Program Version *"
										valueFilter={
											model.toProgramVersion
												? {
													key: 'value',
													values: [model.toProgramVersion],
												}
												: undefined
										}
										programId={
											model.toProgram !== '' ? model.toProgram : undefined
										}
										error={!!data.errors.toProgramVersion}
										inputRef={data.register({ required: true })}
										helperText={
											data.errors.toProgramVersion
												? 'Program version is required.'
												: undefined
										}
										filterHandle={(v: any) => {
											handleFieldOnChange(
												'toProgramVersion',
												v ? v.value : undefined
											);
										}}
									/>
								</Grid>
								<Grid item md={4} sm={6} xs={12}></Grid>
								<Grid item md={4} sm={6} xs={12}>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<KeyboardDatePicker
											disableToolbar
											variant="inline"
											clearable
											format="MM/dd/yyyy"
											placeholder="mm/dd/yyyy"
											id="transferDate"
											name="transferDate"
											label="Transfer Date"
											TextFieldComponent={ThemeTextField}
											fullWidth
											autoOk={true}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											value={model?.transferDate ?? null}
											onChange={(val) => {
												handleFieldOnChange('transferDate', val ? val : null);
											}}
											required
											error={!!data.errors.transferDate}
											
											inputRef={data.register({
												required: true,
											})}
											helperText={
												data.errors.transferDate
													? 'Transfer date required'
													: null
											}
										/>
									</MuiPickersUtilsProvider>
								</Grid>
								<Grid item md={4} sm={6} xs={12}>
									<ThemeTextField
									   disabled = {model.SwitchProgramVersion}
										id="transferHours"
										name="transferHours"
										className={classes.textField}
										label="Transfer hours"
										type="number"
										InputProps={{ inputProps: { min: 0 } }}
										onChange={(e: any) => {
											if (e.target.value > -1) {
												handleFieldOnChange('transferHours', e.target.value);
											}
										}}
									/>
								</Grid>
								<Grid item md={2} sm={3} xs={12}>
									<Grid item md={4} sm={6} xs={12}></Grid>
									{isSupportUser && (<FormControlLabel
										id="SwitchProgramVersion"
										labelPlacement="start"
										label="Switch Program Version"
										control={
											<Switch
												checked={model.SwitchProgramVersion}
												value={model.SwitchProgramVersion}
												onChange={(e: any) => {
													handleFieldOnChange("SwitchProgramVersion", e.target.checked);
												}}
												color="primary"
											/>
										}
									/>    )}
								</Grid>
								<Grid item md={4} sm={6} xs={12}>
									<ProgressSaveButton
										text="Save"
										onClick={(e: any) => {
											onSubmit(e);
										}}
										loading={loaderState}
									></ProgressSaveButton>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
					<ConfirmationModal {...confirmationPopupState} />
					<NotificationModal {...notificationPopupState} />
				</Paper>
			</form>
		</FormContext>
	);
};

export default ToProgram;
