import API from "../../apiWrapper";
import { IFinancialAidDisbursement } from "../../../interfaces/student/financials/IFinancialAidDisbursement";
import { ICharge } from "../../../interfaces/student/financials/ICharge";
import { IStudentPayment } from "../../../interfaces/student/financials/IStudentPayment";
import { ITitleIVStudentStipend } from "../../../interfaces/student/financials/ITitleIVStudentStipend";
import { IRefundToStudent } from "../../../interfaces/student/financials/IRefundToStudent";
import { IReverseTransaction } from "../../../interfaces/student/financials/ITransactionInfo";

export const getTransactionById = (transactionId: string) => {
	return API()
		.get("/StudentAccounts/Transaction/GetTransactionById", {
			params: {
				transactionId
			}
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return null;
			}
		);
};

export const reverseTransaction = (transactionInfo: IReverseTransaction) => {
	return API()
		.post(
			"/StudentAccounts/Transaction/ReverseTransaction",
			transactionInfo,
			{ responseType: "json" }
		)
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return null;
			}
		);
};

export const getStudentLedger = (enrollmentId: string) => {
	return API()
		.get("/StudentAccounts/Ledger/GetStudentLedger", {
			params: {
				enrollmentId
			}
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return null;
			}
		);
};

export const voidTransaction = (transactionId: string) => {
	return API()
		.patch("/StudentAccounts/Ledger/VoidTransaction", transactionId)
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return null;
			}
		);
};
export const deleteTransaction = (transactionId: string, reason: string) => {
	return API()
		.delete("/StudentAccounts/Ledger/DeleteTransaction", {
			data: { transactionId, reason }
		})
		.then(
			(res: any) => {
				if (res && res.data) {
					return res;
				}
			},
			(error: any) => {
				return null;
			}
		);
};

export const printReceipt = (transactionId: string, campusId: string) => {
	return API()
		.post(
			"/Reports/Receipt/GetReceiptReport",
			{
				transactionId,
				campusId
			},
			{ responseType: "blob" }
		)
		.then(
			(res: any) => {
				if (res && res.data) {
					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", "Receipt.pdf");
					document.body.appendChild(link);
					link.click();
				}

				return res;
			},
			(error: any) => {
				return error.response;
			}
		);
};

export const printLedger = (stuEnrollId: string, isVoided: boolean) => {
	return API()
		.post(
			"/StudentAccounts/Ledger/GetLedgerReport",
			{
				stuEnrollId,
				isVoided
			},
			{ responseType: "blob" }
		)
		.then(
			(res: any) => {
				if (res && res.data) {
					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", "LedgerReport.pdf");
					document.body.appendChild(link);
					link.click();
				}
			},
			(error: any) => {
				return null;
			}
		);
};

export const processFinancialAidDisbursement = (
	model: IFinancialAidDisbursement
) => {
	return API()
		.post(
			"/StudentAccounts/Transaction/ProcessFinancialAidDisbursement",
			model,
			{ responseType: "json" }
		)
		.then(
			(res: any) => {
				if (res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};

export const processFinancialAidDisbursementList = (
	model: Array<IFinancialAidDisbursement>
) => {
	return API()
		.post("/StudentAccounts/Transaction/ProcessFinancialAidDisbursementList", model)
		.then(
			(res: any) => {
				if (res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};

export const postStudentPayment = (model: IStudentPayment) => {
	return API()
		.post("/StudentAccounts/Transaction/PostStudentPayment", model, {
			responseType: "json"
		})
		.then(
			(res: any) => {
				if (res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};

export const postCharge = (model: ICharge) => {
	return API()
		.post("/StudentAccounts/Transaction/PostCharge", model, {
			responseType: "json"
		})
		.then(
			(res: any) => {
				if (res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};

export const postTitleIVStudentStipend = (model: ITitleIVStudentStipend) => {
	return API()
		.post("/StudentAccounts/Transaction/PostTitleIVStudentStipend", model, {
			responseType: "json"
		})
		.then(
			(res: any) => {
				if (res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};

export const postPostRefundToStudent = (model: IRefundToStudent) => {
	return API()
		.post("/StudentAccounts/Transaction/PostRefundToStudent", model, {
			responseType: "json"
		})
		.then(
			(res: any) => {
				if (res.data) {
					return res.data;
				}
			},
			(error: any) => {
				return error.response;
			}
		);
};
