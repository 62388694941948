import { IGradeComponentTypeOptionItem } from "../../../interfaces/setup/academics/IGradeComponentTypeOptionItem";
import API from "../../apiWrapper";
export const GetGradeBookComponentTypes = (campusId: string):Promise<IGradeComponentTypeOptionItem[]> => {
  return API()
    .get("/AcademicRecords/GradeBookComponentTypes/GetByCampus", {
      params: {
        campusId
      }
    })
    .then(
      (res: any) => {
        if (res && res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error.response;
      }
    );
};
