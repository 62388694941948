import * as React from "react";
import { useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";

export const TermCalenderApiAutoComplete = {
  config: {
    acId: "termCalenderTypes",
    cacheTime: 5,
    route: "/AcademicRecords/Class/GetTermCalenders",
    requestType: "GET",
    body: null,
    hasUrlParams: true,
    useCache: false,
    storeInCache: false,
    isCampusSpecific: false,
    isUserSpecific: false,
    loadOnRender: true,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
      return data;
    },
  },
};

const ThemeTextField = withStyles((theme: any) =>
  createStyles({
    root: {
      "& label.Mui-focused": {
        color: theme.palette.primary.white,
      },
    },
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {
      width: "100%",
    },
  })
);

export interface ProgramTermCalender extends FilterParams {
  shouldCheckForEffectiveEndDate?: boolean;
  daysFilter?: number | undefined;
  classId?: string | null;
}

const TermCalenderAutoComplete = (props: ProgramTermCalender) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const [TermCalenderTypesSelected, setTermCalenderTypesSelected] =
    React.useState(false);
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );
  const [shouldCheckForEffectiveEndDate, setShouldCheckForEffectiveEndDate] =
    React.useState<boolean>(false);

  const inputRef: any = useRef(null);

  const filterHandle = props.filterHandle;

  const termCalenderTypesOnChange = (e: any) => {
    if (filterHandle) filterHandle(e);
    setTermCalenderTypesSelected(e);
  };

  React.useEffect(() => {
    if (props?.shouldCheckForEffectiveEndDate === true) {
      setShouldCheckForEffectiveEndDate(true);
    }
  }, [props.shouldCheckForEffectiveEndDate]);

  return (
    <div className={clsx(classes.divWidth)}>
      <ApiAutoComplete
        config={{...TermCalenderApiAutoComplete.config, defaultSelectedFirstItem: props.defaultSelectedFirstItem !== undefined ? props.defaultSelectedFirstItem : TermCalenderApiAutoComplete.config.defaultSelectedFirstItem}}
        loading={userSelectedCampus ? false : true}
        reqParams={{
          campusId: userSelectedCampus,
          shouldCheckForEffectiveEndDate: shouldCheckForEffectiveEndDate,
          daysFilter: props.daysFilter,
          classId: props.classId,
        }}
        classes={{
          option: "text-black",
        }}
        label={props.label ? props.label : "Term Calender"}
        onChange={(e: any) => {
          termCalenderTypesOnChange(e);
        }}
        multiple={false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        inputRef={props.inputRef ? props.inputRef : inputRef}
        error={props.error}
        helperText={props.helperText}
        inputName={props.name}
      ></ApiAutoComplete>
    </div>
  );
};
export default TermCalenderAutoComplete;
