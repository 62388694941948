import React from 'react';
import {
	makeStyles,
	Card,
	createStyles,
	CardContent,
	Grid,
	CardActions,
	Paper,
	Table,
	withStyles,
	TableCell,
	TableRow,
	TableContainer,
	TableHead,
	TableBody,
	IconButton,
	Popover,
	Typography,
	Button,
	TablePagination,
	TextField,
} from '@material-ui/core';
import CustomCardTitle from '../../interfaces/common/card/CustomCardTitle';
import { useSelector } from 'react-redux';
import * as EmergencyFundsApi from '../../api/student/financials/emergencyFunds/EmergencyFundsApi';
import { IEmergencyFundsDetail } from '../../interfaces/student/financials/IEmergencyFundsInfo';
import AddEmergencyFunds from './AddEmergencyFunds';
import CustomSnackbar from '../../components/notifications/CustomSnackbar';
import { CustomSnackBarProps } from '../../interfaces/common/CustomSnackBarProps';
import PrintIcon from '@material-ui/icons/Print';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AccessManager from '../../components/security/AccessManager';
import { security } from '../../constants/Security/securityConstants';
import AwardYearAutoComplete from '../../components/AutoComplete/AwardYearAutoComplete';
import EmergencyFundsTypesAutoComplete from '../../components/AutoComplete/EmergencyFundsTypesAutoComplete';
import InputField from '../../components/_Layout/Inputs/InputField';

const ThemeTextField = withStyles(() => createStyles({}))(TextField);


const useStyles = makeStyles((theme: any) =>
	createStyles({
		cardTitle: {
			fontSize: 17,
			color: theme.palette.black,
			textDecoration: 'bold',
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(1, 1),
			marginBottom: theme.spacing(1),
		},
		headerText: {
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(0),
		},
		cardContent: {
			padding: theme.spacing(1, 3),
			height: '100%',
		},
		saveButton: {
			width: 90,
		},
		tableContainer: {
			height: '400px',
		},
		tableEmptyRow: {
			height: '350px',
			textAlign: 'center',
			fontSize: 20,
			opacity: 0.3,
		},
		table: {
			width: '100%',
		},
		iconColor: {
			color: theme.palette.black
		},
		searchField: {
			'&::placeholder': {
				color: '#000000'
			}
		},
		selectionCheckBox: {
			padding: theme.spacing(0)
		},
		preLoaderCell: {
			textAlign: 'center',
			padding: theme.spacing(1)
		},
		cardMain: {
			marginTop: theme.spacing(2)
		},
		popOverButtons: {
			display: 'flex',
			justifyContent: 'space-evenly',
			flexDirection: 'row',
			padding: 5
		},
		typography: {
			padding: theme.spacing(2)
		},
		textField: {
			marginRight: theme.spacing(1),
			width: '100%',
		},
	})
);

const EmergencyFundAllocation = () => {
	const classes = useStyles({});
	const [totalFunds, setTotalFunds] = React.useState<Array<IEmergencyFundsDetail>>([]);
	const [funds, setFunds] = React.useState<Array<IEmergencyFundsDetail>>([]);
	const [model, setModel] = React.useState({ emergencyFundTypeId: '', academicYearId: '', totalAmount: 0 });
	const [loading, setLoading] = React.useState<boolean>(false);
	const [editedFundId, seteditedFundId] = React.useState<number | null>(null);
	const [fundsPopUpOpen, setFundsPopUpOpen] = React.useState<boolean>(false);
	const [searchText, setSearchText] = React.useState<string>('');

	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const [fundIdDelete, setFundIdToDelete] = React.useState<any | null>(null);
	const [
		popOverAnchorEl,
		setPopOverAnchorEl
	] = React.useState<HTMLButtonElement | null>(null);
	const popOverOpen = Boolean(popOverAnchorEl);
	const popOverId = popOverOpen ? 'simple-popover' : undefined;
	const handleDelete = () => {
		if (fundIdDelete !== null) {
			EmergencyFundsApi.removeEmergencyFunds(fundIdDelete).then(
				(response: any) => {
					if (response && response.data) {
						setSnackBarProps(() => {
							return {
								variant: 'success',
								showSnackBar: true,
								messageInfo: 'Funds deleted successfully.',
							};
						});
						getEmergencyFunds(userSelectedCampus);
					}
				},
				() => { }
			);
		}
		setPage(0);
		setFundIdToDelete(null);
		setPopOverAnchorEl(null);
	};
	const handleClosePopOver = () => {
		setFundIdToDelete(null);
		setPopOverAnchorEl(null);
	};
	const handleClickPopOver = (event: any, fundId: any) => {
		setFundIdToDelete(fundId);
		setPopOverAnchorEl(event.currentTarget);
	};

	const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
		{
			showSnackBar: false,
			messageInfo: undefined,
			variant: 'info',
		}
	);

	const openFundsPopUp = React.useCallback(() => {
		seteditedFundId(null);
		setFundsPopUpOpen(true);
	}, []);

	const closeFundsPopUp = () => {
		setFundsPopUpOpen(false);
		seteditedFundId(null);
		getEmergencyFunds(userSelectedCampus);
		setPage(0);
	};


	const printEmergencyFunds = () => {
		EmergencyFundsApi.PrintEmergencyFunds(userSelectedCampus, model.academicYearId, model.emergencyFundTypeId).then(
			() => { },
			() => { }
		);
	};

	React.useEffect(() => {
		if (userSelectedCampus) {
			getEmergencyFunds(userSelectedCampus);
		}
	}, [userSelectedCampus]);

	const getEmergencyFunds = (campusId: any) => {
		if (campusId) {
			EmergencyFundsApi.getEmergencyFundsByCampus(campusId).then(
				(response: any) => {
					if (response && response.data) {
						setTotalFunds(response.data);
						setFunds(response.data);
					}
				},
				() => { }
			);
		}
	};

	const setRowToEdit = (fundsId: any) => {
		seteditedFundId(fundsId);
		setFundsPopUpOpen(true);
	};

	const handleFieldOnChange = (
		fieldId: string,
		value: any	) => {
		let updatedModel = model;
		(updatedModel as any)[fieldId] = value;
		if (model.academicYearId !== '' && model.emergencyFundTypeId !== '') {
			(updatedModel as any)['totalAmount'] = 0;
			var items = funds.filter(p => p.academicYearId === model.academicYearId
				&& p.emergencyFundTypeId === model.emergencyFundTypeId);
			if (items !== null && items.length > 0) {
				var tAmount = items.reduce(function (prev, current) {
					var amo = current.amount ?? 0;
					return prev + +amo;
				}, 0);
				(updatedModel as any)['totalAmount'] = tAmount.toFixed(2);
			}
		}
		setModel({ ...updatedModel });
	};

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const handleChangePage = (event: any, newPage: any) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event: any) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleFieldOnSearch = (event: any, text: any) => {
		setSearchText(text);
		if (text !== '') {
			const filteredRows = totalFunds.filter(s => s.studentName.toLowerCase().indexOf(text.toLowerCase()) >= 0
				|| s.programVersion.toLowerCase().indexOf(text.toLowerCase()) >= 0
				|| s.emergencyFundType.toLowerCase().indexOf(text.toLowerCase()) >= 0
				|| s.academicYearDescription.toLowerCase().indexOf(text.toLowerCase()) >= 0
				|| (new Date(s.disbursementDate as any).toLocaleDateString() ?? '').toString().toLowerCase().indexOf(text.toLowerCase()) >= 0

			);
			if (filteredRows.length > 0)
				setFunds(filteredRows);
			else
				setFunds([]);
		}
		else {
			setFunds(totalFunds);
		}
	};
	return (
		<React.Fragment>
			<CustomSnackbar
				variant={snackBarProps.variant}
				message={snackBarProps.messageInfo}
				open={snackBarProps.showSnackBar}
				onClose={() => {
					setSnackBarProps((props: any) => {
						return { ...props, showSnackBar: false };
					});
				}}
			></CustomSnackbar>
			<Card square={true} elevation={2} className={classes.cardMain}>
				<CustomCardTitle title='Emergency Fund Allocation'></CustomCardTitle>
				<CardActions>
					<Grid container direction="row" spacing={2}>
						<Grid item md={4} sm={4} xs={12}>
							<EmergencyFundsTypesAutoComplete
								id="emergencyFundTypeId"
								name="emergencyFundTypeId"
								label="Funds Type"
								valueFilter={
									model.emergencyFundTypeId
										? {
											key: 'value',
											values: [model.emergencyFundTypeId]
										}
										: undefined
								}
								filterHandle={(v: any) => {
									handleFieldOnChange('emergencyFundTypeId', v ? v.value : undefined);
								}}
							/>
						</Grid>
						<Grid item md={4} sm={4} xs={12}>
							<AwardYearAutoComplete
								name="academicYearId"
								id="academicYearId"
								label="Award Year"
								valueFilter={
									model.academicYearId
										? {
											key: 'value',
											values: [model.academicYearId]
										}
										: undefined
								}
								filterHandle={(v: any) => {
									handleFieldOnChange('academicYearId', v ? v.value : null);
								}}
							/>
						</Grid>
						<Grid item md={4} sm={4} xs={12}>
							<InputField
								key={'totalAmount'}
								id={'totalAmount'}
								label="Total Amount Disbursed"
								name={'totalAmount'}
								defaultValue={model.totalAmount}
							/>
						</Grid>
					</Grid>
					<Grid container direction='row' spacing={1} dir="rtl">
						<Grid
							item
							alignContent='flex-start'
							alignItems='flex-start'
							justify='flex-start'
						>

							<AccessManager
								allowedPermissions={[security.permissions.studentNotes.updateNotes]}
								renderNoAccess={() =>
									<IconButton
										aria-label="add funds"
										disabled={true}
										onClick={openFundsPopUp}
									>
										<AddIcon />
									</IconButton>
								}
							>
								<IconButton
									aria-label="add funds"
									onClick={openFundsPopUp}
								>
									<AddIcon />
								</IconButton>
							</AccessManager>
						</Grid>
						<Grid
							item
							alignContent='flex-start'
							alignItems='flex-start'
							justify='flex-start'
						>
							<IconButton
								aria-label="print funds"
								onClick={printEmergencyFunds}
							>
								<PrintIcon />
							</IconButton>
						</Grid>
					</Grid>
				</CardActions>
				<CardContent>
					<Grid container direction="column" spacing={2}>
						<Grid
							item
							alignContent="flex-start"
							alignItems="flex-start"
							justify="flex-start"
						>
							<TableContainer component={Paper} square className={classes.tableContainer}>
								<Table stickyHeader size='small' className={classes.table} aria-label='customized table'>
									<TableHead>
										<TableRow>
											<TableCell align="center" colSpan={8}>
												<ThemeTextField
													id="searchText"
													name="searchText"
													className={classes.textField}
													label="Search Text"
													value={searchText}
													onChange={(e: any) => {
														handleFieldOnSearch('searchText', e.target.value);
													}}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>Student</TableCell>
											<TableCell>Program Version</TableCell>
											<TableCell>Emergency Fund Type</TableCell>
											<TableCell>Award Year</TableCell>
											<TableCell>Disbursment Date</TableCell>
											<TableCell>Amount</TableCell>
											<TableCell>Edit</TableCell>
											<TableCell>Delete</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{funds !== null && funds.length > 0 ? (
											funds.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
											).map((row: IEmergencyFundsDetail, index: number) => {
												return (
													<TableRow key={`row-${index}`}>
														<TableCell>{row.studentName}</TableCell>
														<TableCell>{row.programVersion}</TableCell>
														<TableCell>{row.emergencyFundType}</TableCell>
														<TableCell>{row.academicYearDescription}</TableCell>
														<TableCell>{row.disbursementDate === null ? null : new Date(row.disbursementDate as any).toLocaleDateString()}</TableCell>
														<TableCell>{row.amount}</TableCell>
														<TableCell>
															<AccessManager
																allowedPermissions={[security.permissions.studentNotes.updateNotes]}
																renderNoAccess={() => <IconButton disabled={true} key={`saveButton-${row.emergencyFundId}`}>
																	<EditIcon key={`editIcon-${row.emergencyFundId}`} />
																</IconButton>}
															>
																<IconButton key={`saveButton-${row.emergencyFundId}`} onClick={() => setRowToEdit(row.emergencyFundId)}>
																	<EditIcon key={`editIcon-${row.emergencyFundId}`} />
																</IconButton>
															</AccessManager>
														</TableCell>
														<TableCell>
															<AccessManager
																allowedPermissions={[security.permissions.studentNotes.updateNotes]}
																renderNoAccess={() =>
																	<IconButton disabled={true} key={`deleteButton-${row.emergencyFundId}`}>
																		<DeleteIcon key={`deleteIcon-${row.emergencyFundId}`} />
																	</IconButton>}
															>
																<IconButton key={`deleteButton-${row.emergencyFundId}`} onClick={(e: any) => handleClickPopOver(e, row.emergencyFundId)}>
																	<DeleteIcon key={`deleteIcon-${row.emergencyFundId}`} />
																</IconButton>
															</AccessManager>

														</TableCell>
													</TableRow>
												);
											})
										) : (
											<TableRow>
												<TableCell align="center" colSpan={8}>
													No Data
												</TableCell>
											</TableRow>
										)}

									</TableBody>
								</Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[5, 10, 25]}
								component="div"
								count={funds.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</Grid>
					</Grid>
				</CardContent>
				<Popover
					id={popOverId}
					open={popOverOpen}
					anchorEl={popOverAnchorEl}
					onClose={handleClosePopOver}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
				>
					<Typography
						color="textSecondary"
						className={classes.typography}
						variant="body1"
					>
						Are you sure you want to delete this funds?
					</Typography>
					<div className={classes.popOverButtons}>
						<Button
							variant="contained"
							size="small"
							onClick={() => handleDelete()}
							color="primary"
						>
							Yes
						</Button>
						<Button
							variant="contained"
							size="small"
							onClick={handleClosePopOver}
							color="secondary"
						>
							Cancel
						</Button>
					</div>
				</Popover>
			</Card>
			<AddEmergencyFunds editedFundId={editedFundId} open={fundsPopUpOpen} handleClose={closeFundsPopUp} isLoading={loading} setLoading={setLoading} setSnackBar={setSnackBarProps} />
		</React.Fragment>
	);
};

export default EmergencyFundAllocation;
