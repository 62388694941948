import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: any) =>
	createStyles({
		root: {
			width: '100%',
			height: '100%',
			overflowX: 'hidden',
			backgroundColor: theme.palette.background.paper,
		},
		innerRoot: {
			width: '100%',
			height: '90%',
			//maxHeight: '360px',
			overflowY: 'scroll',
			overflowX: 'hidden',
			marginBottom: theme.spacing(2),
			backgroundColor: theme.palette.background.paper,
		},
		innerRootTabs: {
			width: '100%',
			height: '90%',
			//maxHeight: '320px',
			overflowY: 'scroll',
			overflowX: 'hidden',
			marginBottom: theme.spacing(2),
			backgroundColor: theme.palette.background.paper,
		},
		textAlignLeft: {
			textAlign: 'left!important' as any,
		},
		textAlignRight: {
			textAlign: 'right!important' as any,
		},
		headerPaddingRight: {
			paddingRight: theme.spacing(2),
		},
		tabStyle: {
			backgroundColor: theme.palette.primary.constrastText,
			color: theme.palette.primary.headerText,
		},
		headCell: {
			padding: theme.spacing(1, 2),
			textDecoration: 'bold',
			color: theme.palette.primary.headerText,
		},
		bodyCell: {
			padding: theme.spacing(1, 2),
		},
		table: {
			width: '100%',
		},
		header: {
			height: '40px',
			padding: '10px',
			fontWeight: 600,
			width: '100%',
			backgroundColor: theme.palette.primary.constrastText,
		},
		cardTitle: {
			fontSize: 17,
			color: theme.palette.primary.headerText,
			textDecoration: 'bold',
			backgroundColor: theme.palette.secondary.dark,
			padding: theme.spacing(1, 1),
		},
		cardContent: {
			padding: theme.spacing(1, 1),
			margin: theme.spacing(0),
			height: '100%',
		},
		iconBtn: {
			padding: theme.spacing(0, 2),
			display: 'inline-block',
		},
		linkDesign: {
			color: theme.palette.primary.light,
		},
		tableHeading: {
			fontSize: 17,
			color: theme.palette.primary.headerText,
			textDecoration: 'bold',
			padding: theme.spacing(2, 0),
		},
		headerFilter: {
			width: '100%',
		},
		headerText: {
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(0),
			textAlign: 'left',
		},
		headerTextRight: {
			padding: theme.spacing(0, 2),
			fontSize: 17,
			color: theme.palette.primary.headerText,
			textDecoration: 'bold',
			textAlign: 'right',
			display: 'inline-block',
		},
		widgetMenu: {
			textAlign: 'right',
		},
		checkBoxStyle: {
			padding: theme.spacing(0),
			margin: theme.spacing(0),
		},
		cardSeparator: {
			marginBottom: theme.spacing(1),
		},
		button: {
			padding: theme.spacing(1),
			margin: theme.spacing(2, 0),
		},
		buttonColorPrimary: {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.primary.contrastText,
			'&:hover': {
				backgroundColor: theme.palette.primary.main,
			},
			textDecoration: 'bold',
			padding: theme.spacing(1),
			marginTop: theme.spacing(1),
			whiteSpace: 'nowrap',
		},
		tableBottomMargin: {
			marginBottom: theme.spacing(2),
		},
		backdrop: {
			// zIndex: theme.zIndex.drawer + 1,
			// color: '#fff',
		},
		typographyMessage: {
			backgroundColor: theme.palette.error.light,
			padding: 5,
			color: theme.palette.primary.contrastText,
		},
		footerOutline: {
			outlineColor: theme.palette.primary.dark,
			outline: 'solid',
			outlineWidth: 1,
		},
		footerCell: {
			fontSize: 16,
		},
		warningRow: {
			backgroundColor: theme.palette.warning.light,
		},
		seriousWarningRow: {
			backgroundColor: theme.palette.error.light,
		},
		baseRow: {},
	})
);
