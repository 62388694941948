import * as React from "react";
import { useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Grid,
	Button,
	Typography
} from "@material-ui/core";
import ProgressSaveButton from "../Buttons/ProgressSaveButton";
const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& label.Mui-focused": {
				color: theme.palette.primary.white
			}
		}
	})
)(TextField);

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		dialogHeader: {
			backgroundColor: theme.palette.site.secondary,
			"& .MuiTypography-root": {
				fontWeight: theme.typography.fontWeightBold,
				fontSize: "12pt"
			}
		},
		buttonSection: {
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(3)
		},
		saveButton: {
			width: 90
		},
		typography: {
			padding: theme.spacing(1)
		}
	})
);

type NotificationModalProps = {
	onConfirmEvent: any;
	onCloseEvent: any;
	setOpen: any;
	open: boolean;
	title: string;
	notificationText: string;
	notificationButton: string;
	id?: string;
	maxWidth?: string;
};

const NotificationModal = (props: NotificationModalProps) => {
	const classes = useStyles({});

	const handleConfirm = () => {
		if (props.onConfirmEvent) {
			props.onConfirmEvent();
		}
	};

	const handleClose = () => {
		if (props.onCloseEvent) {
			props.onCloseEvent();
		}
	};
	return (
		<div>
			
			<Dialog
				open={props.open}
				onClose={handleClose}
				className={classes.root}
				aria-labelledby="form-dialog-title"
				maxWidth = "xs"
				fullWidth={true}
				disableBackdropClick
			>
				<DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
					{props.title}
				</DialogTitle>
				<DialogContent>
					<Grid container direction="row" spacing={2}>
						<Grid item xs={12}>
							{}
						</Grid>
					</Grid>
					<Grid container direction="row" spacing={2}>
						<Grid item xs={12}>
							<Typography
								color="textSecondary"
								className={classes.typography}
								variant="body1"
							>
								{props.notificationText}
							</Typography>
						</Grid>
					</Grid>
				</DialogContent>

				<Grid item xs={12}>
					<DialogActions className={classes.buttonSection}>
						<Grid container direction="column" spacing={0} alignItems="center" justify="center">
							<Grid
								item
								container
								xs={4}
								alignContent="center"
								alignItems="center"
								justify="center"
							>
								<ProgressSaveButton
									text={props.notificationButton}
									onClick={() => {
										handleConfirm();
									}}
									loading={false}
									buttonClassName={classes.saveButton}
								></ProgressSaveButton>
							</Grid>
							<Grid item xs={2}></Grid>
						</Grid>
					</DialogActions>
				</Grid>
			</Dialog>
		</div>
	);
};

export default NotificationModal;
