import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Grid,
} from "@material-ui/core";
import { FormContext, useForm } from "react-hook-form";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ReportOutput } from "../../../enums/ReportOutput";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropDownListItem } from "../../../interfaces/DropDownListItem";
import TextField from "@material-ui/core/TextField";
import { IBackOnTrackReportParam } from "../../../interfaces/reports/attendance/IBackOnTrackReportParam";
import * as BackOnTrackReportApi from "../../../api/reports/attendance/BackOnTrackReportApi"
import { useSelector } from "react-redux";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      minHeight: "100%",
      height: "100%",
      maxWidth: theme.spacing(131.25),
      margin: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    paperRoot: {
      padding: theme.spacing(3, 2),
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
    },
    width100: {
      width: "100%",
    },
    cardTitle: {
      fontSize: theme.spacing(2.15),
      color: theme.palette.primary.headerText,
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
    switch: {
      display: "flex",
      alignItems: "flex-end",
      padding: theme.spacing(0, 0.5),
      height: theme.spacing(6.6),
    },
    fieldSet: {
      marginTop: theme.spacing(2),
    },
  })
);

const BackOnTrackListReport = (props: any) => {
  const classes = useStyles({});
  const { report } = props;
  let reportOverview = { report: report } as IReportOverview;
  const [preview, setPreview] = React.useState<any>();
  const [previewLoader, setPreviewLoader] = React.useState<boolean>();
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  var date = new Date();
  const [model, setModel] = React.useState<IBackOnTrackReportParam>({ 
    startDate : new Date(date.getFullYear(), date.getMonth(), 1), 
    endDate : new Date(date.getFullYear(), date.getMonth() + 1, 0) ,
    campusId: null,
    exportType: 1 as ReportOutput
  });

  const exportTypes: Array<DropDownListItem> = [
    { text: "PDF", id: "1" },
    { text: "Excel", id: "2" },
    { text: "Word", id: "3" },
  ];

  const exportTypeAutoComplete = {
    options: exportTypes,
    getOptionLabel: (option: DropDownListItem) => option.text,
  };

  const getSelectedItem = (items: Array<DropDownListItem>, value: string) => {
    const item = items.find((opt) => {
      if (opt.id == value) return opt;
    });
    return item || ({} as DropDownListItem);
  };

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    setModel({ ...updatedModel });
  };

  const data = useForm<any>({ mode: "onBlur" });
  const { handleSubmit } = data;
  const onSubmit = async (isPreview: any): Promise<void> => {
    let params = (await getReportParameters()) as IBackOnTrackReportParam;
    const validation = await data.triggerValidation();

    if (validation && params.startDate) {
      if (isPreview) {
        setPreviewLoader(true);
        BackOnTrackReportApi
          .generateReport(params, isPreview)
          .then((res: any) => {
            if (res) {
              res.arrayBuffer().then((buffer: any) => {
                setPreview(buffer);
                setPreviewLoader(false);
              });
            } else setPreviewLoader(false);
          });
      } else await BackOnTrackReportApi.generateReport(params);
    }
  };

  const filterHandler = (data: any) => {
    if (data) {
      setModel({ ...data });
    } else {
      setModel(model);
    }
  };

  const handleSortOrderChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let updatedModel = model;
    (updatedModel as any)[
      "sortOrder"
    ] = (event.target as HTMLInputElement).value;
    setModel({ ...updatedModel });
  };

  const getReportParameters = async () => {
    const success = await data.triggerValidation();
    if (model && success) {
      let params: IBackOnTrackReportParam = {
        startDate: model?.startDate,
        endDate: model?.endDate,
        exportType: model?.exportType,
        campusId: userSelectedCampus,
      };
      return params;
    }
    return {} as IBackOnTrackReportParam;
  };

  let parameters = (
    <div className={[classes.root, classes.width100].join(" ")}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <React.Fragment>
              <Grid item md={4} sm={4} xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    id="startDate"
                    name="startDate"
                    key="startDateKey"
                    label="Start Date"
                    value={model.startDate ? model.startDate : new Date()}
                    required
                    onChange={(e: any, value: any) =>
                      handleFieldOnChange(
                        "startDate",
                        value ? value : undefined
                      )
                    }
                    className={classes.width100}
                    KeyboardButtonProps={{
                      "aria-label": "report date",
                    }}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.startDate ? "Start date is required." : undefined
                    }
                    error={!!data.errors["startDate"]}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={4} sm={4} xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    id="endDate"
                    name="endDate"
                    key="endDateKey"
                    label="End Date"
                    value={model.endDate ? model.endDate : new Date()}
                    required
                    onChange={(e: any, value: any) =>
                      handleFieldOnChange(
                        "endDate",
                        value ? value : undefined
                      )
                    }
                    className={classes.width100}
                    KeyboardButtonProps={{
                      "aria-label": "report date",
                    }}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.endDate ? "End date is required." : undefined
                    }
                    error={!!data.errors["endDate"]}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={4} sm={4} xs={6}>
                <Autocomplete
                  {...exportTypeAutoComplete}
                  autoComplete
                  includeInputInList
                  onChange={(e: any, value: any) => {
                    handleFieldOnChange(
                      "exportType",
                      value ? value : undefined,
                      (v: any) => v?.id
                    );
                  }}
                  value={getSelectedItem(exportTypes, model.exportType?.toString())}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Export Type *"
                      name="exportType"
                      error={!!data.errors.exportType}
                      inputRef={data.register({ required: true })}
                      helperText={
                        data.errors.exportType
                          ? "Export Type is required."
                          : undefined
                      }
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </React.Fragment>
          </Grid>
        </form>
      </FormContext>
    </div>
  );
  reportOverview.parameters = parameters;

  return (
    <ReportOverview
      reportOverview={reportOverview}
      filterHandler={filterHandler}
      getReportParameters={getReportParameters}
      exportHandler={onSubmit}
      preview={preview}
      previewLoader={previewLoader}
    />
  );
};

export default BackOnTrackListReport;
