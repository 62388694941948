import API from "../apiWrapper";
import { User } from "../../interfaces/user/User";
import { ResetPassword } from "../../interfaces/user/ResetPassword";

import { Email } from "../../interfaces/common/Email";
import { UserRole } from "../../interfaces/systemCatalog/UserRole";
import { Campus } from "../../interfaces/systemCatalog/ICampus";
import { EmptyGuid } from "../../utils/constants";
export const getUserDetails = (userId: string) => {
    return API()
        .get("/SystemCatalog/User/GetWithCampusById", {
            params: {
                userId
            }
        })
        .then(
            (res: any) => {
                if (res && res.data) {
                    return {
                        ...res.data,
                        campuses: res.data.campuses.map((c: any) => { return { id: c.campusId } as Campus }),
                        userRoles: res.data.userRoles
                            .map((ur: any) => {
                                return {
                                    id: ur.id,
                                    role: { id: ur.role.roleId, name: ur.role.roleDescription },
                                    campuses: ur.campuses.map((c: any) => { return { id: c.campusDescription === 'All' ? 'All' : c.campusId, name: c.campusDescription } as Campus }),
                                    isAllCampuses: ur.isAllCampuses
                                } as UserRole
                            })
                    } as User;
                }
            },
            (error: any) => {
                return error;
            }
        );
};

export const inactivateAccount = (userIds: Array<string>) => {
    return API()
        .post("/SystemCatalog/User/InactivateAccount", userIds
        )
        .then(
            (res: any) => {
                if (res) {
                    return res.data as boolean;
                }
                return false
            },
            (error: any) => {
                return false;
            }
        );
};

export const sendResetPasswordEmail = (email: Array<Email>) => {
    return API()
        .post("/SystemCatalog/User/SendResetPasswordEmail", {EmailList : email, HostName: document.location.protocol+"//"+window.location.host})
        .then(
            (res: any) => {
                if (res && res.data) {
                    return res.data as Array<ResetPassword>;
                }
            },
            (error: any) => {
                return error;
            }
        );
};

export const activateAccount = (userIds: Array<string>) => {
    return API()
        .post("/SystemCatalog/User/ActivateAccount", userIds)
        .then(
            (res: any) => {
                if (res && res.data) {
                    return res.data as boolean;
                }
            },
            (error: any) => {
                return error;
            }
        );
};

const transformUser = (user: User) => {
    return {
        ...user, campuses: user.campuses.map((c: Campus) => { return { campusId: c.id } }), 
        userRoles: user.userRoles
            .map((ur: UserRole) => {
                return {
                    id: ur.id,
                    role: { roleId: ur.role.id, roleDescription: ur.role.name },
                    campuses: ur.campuses.map((c: Campus) => { return { campusId: c.id === 'All' ? EmptyGuid : c.id, campusDescription: c.name } as any }),
                    isAllCampuses: ur.isAllCampuses
                } as any
            }),
    }
}

export const updateUser = (user: User) => {

    let userToUpdate = transformUser(user);

    return API()
        .put("/SystemCatalog/User/PutByCampus", userToUpdate)
        .then(
            (res: any) => {
                if (res && res.data && res.data.hasPassed) {
                    return res.data as User;
                }
                return null;
            },
            (error: any) => {
                return null;
            }
        );
};


export const createUser = (user: User) => {
    let userToCreate = transformUser(user);
    return API()
        .post("/SystemCatalog/User/PostByCampus", { NewUser : userToCreate, HostName :  document.location.protocol+"//"+window.location.host })
        .then(
            (res: any) => {
                return res;
            },
            (error: any) => {
                return null;
            }
        );
};

