import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Popover,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { Fragment } from "react";
import InputField from "../../../../components/_Layout/Inputs/InputField";
import {
  deleteTerm,
  getDetails,
  getTermsList,
  saveTerm,
} from "../../../../api/setup/terms/termslist";
import { useForm } from "react-hook-form";
import ITermDetails from "../../../../interfaces/setup/terms/ITermDetails";
import StatusAutoComplete from "../../../../components/AutoComplete/StatusAutoComplete";
import AcademicCalendarAutoComplete from "../../../../components/AutoComplete/AcademicCalendarAutoComplete";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { EmptyGuid } from "../../../../utils/constants";
import APIHook from "../../../../api/hook";
import ITerm from "../../../../interfaces/setup/terms/ITerm";
import { Pagination } from "@material-ui/lab";
import { toastr } from "react-redux-toastr";
import SortIcon from "@material-ui/icons/Sort";
import { InputType } from "../../../../constants/uiConstants/inputConstants";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardMainContent: {
      height: "100%",
      maxWidth: "100%",
      width: "100%",
    },
    selectAbleRow: {
      cursor: "pointer",
    },
    pagination: {
      display: "inline-flex",
    },
    loaderWrapper: {
      textAlign: "center",
    },
    sortDesc: {
      transform: "rotate(180deg)",
    },
    iconButton: {
      fontSize: "xx-large",
      overflow: "hidden",
      maxWidth: "100%",
      maxHeight: "100%",
      relative: "center",
    },
    button1: {
      marginTop: "5px",
    },
    PopoverButton: {
      marginRight: "100px",
    },
  })
);

const AddTermModal: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const classes = useStyles({});
  const [page, setPage] = React.useState<number>(1);
  const [showAll, setShowAll] = React.useState<boolean>(false);
  const [sort, setSort] = React.useState<"asc" | "desc">("desc");
  const pageSize = 20;
  const [search, setSearch] = React.useState<string>("");
  const [dirty, setDirty] = React.useState<boolean>(false);
  const [popAnchor, setPopAnchor] = React.useState<null | HTMLElement>(null);
  const [popMessage, setPopMessage] = React.useState<string>("");
  const [popConfirmAction, setPopConfirmAction] = React.useState<() => void>();
  const [, message, code, saving, callSave, resetState] = APIHook<
    ITermDetails,
    typeof saveTerm
  >(saveTerm);
  const [, messageDelete, codeDelete, deleting, callDelete, resetStateDelete] =
    APIHook<string, typeof deleteTerm>(deleteTerm);
  const [
    fetchedDetails,
    ,
    detailsCode,
    loadingDetails,
    getTermDetails,
    resetDetails,
  ] = APIHook<ITermDetails, typeof getDetails>(getDetails);
  const [data, , , loading, getList] = APIHook<
    {
      data: Array<ITerm>;
      hasMoreData: boolean;
      total: number;
    },
    typeof getTermsList
  >(getTermsList);
  React.useEffect(() => {
    getList({
      take: pageSize,
      sort,
      showAll,
      skip: (page - 1) * pageSize,
      search: search || undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [details, setDetails] = React.useState<ITermDetails>({ id: EmptyGuid }); // eslint-disable-line
  const { register, reset, errors, handleSubmit, triggerValidation } =
    useForm<ITermDetails>({
      mode: "onBlur",
    });

  const onSave = () => {
    callSave(details);
  };

  React.useEffect(() => {
    if (detailsCode === 200) {
      reset({
        ...fetchedDetails,
      });
      setDetails({ ...fetchedDetails });
      setDirty(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsCode, loadingDetails]);

  React.useEffect(() => {
    if (code === 200 && !saving) {
      toastr.success("", "Term saved successfully.");
      setTimeout(() => {
        toastr.clean();
      }, 3000);
      resetDetails();
      setDetails({
        id: EmptyGuid,
      });
      reset({});
      setDirty(false);
      getList({
        take: pageSize,
        skip: (page - 1) * pageSize,
        search: search || undefined,
        showAll,
        sort,
      });
    } else if (code) {
      toastr.error("", message || "Something went wrong.");
    }
    resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, saving]);

  React.useEffect(() => {
    if (codeDelete === 200 && !deleting) {
      toastr.success("", "Term deleted successfully.");
      resetDetails();
      setDetails({
        id: EmptyGuid,
      });
      reset({});
      setDirty(false);
      getList({
        take: pageSize,
        skip: (page - 1) * pageSize,
        search: search || undefined,
        showAll,
        sort,
      });
    } else if (codeDelete) {
      toastr.error("", "Class is registered with this term.");
    }
    resetStateDelete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeDelete, deleting]);

  return (
    <div>
      <Button
        aria-describedby={id}
        className={classes.button1}
        //variant="contained"
        onClick={handleClick}
      >
        <AddIcon className={classes.iconButton} />

        {/* Open Popover 2 */}
      </Button>
      <Popover
        // className={classes.PopoverButton}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <div>
          <React.Fragment>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={0} className={classes.cardMainContent}>
                <Grid item xs={12} sm={12} md={12}>
                  <Card elevation={2}>
                    <CardContent>
                      <form onSubmit={handleSubmit(onSave)}>
                        {((loadingDetails || deleting || saving) && (
                          <div className={classes.loaderWrapper}>
                            <CircularProgress size={50} color="primary" />
                          </div>
                        )) || (
                          <Fragment>
                            <InputField
                              required
                              name="code"
                              label="Term Code"
                              inputRef={register({
                                required: "Term Code is required.",
                              })}
                              error={errors.code ? true : false}
                              helperText={
                                errors.code ? errors.code.message : ""
                              }
                              onBlur={(
                                e: React.FocusEvent<HTMLInputElement>
                              ) => {
                                if (
                                  (details.code || "") !==
                                  (e.target.value || "")
                                ) {
                                  setDetails({
                                    ...details,
                                    code: e.target.value,
                                  });
                                  setDirty(true);
                                }
                              }}
                            />
                            <InputField
                              required
                              name="name"
                              label="Term Description"
                              inputRef={register({
                                required: "Term Description is required.",
                              })}
                              error={errors.name ? true : false}
                              helperText={
                                errors.name ? errors.name.message : ""
                              }
                              onBlur={(
                                e: React.FocusEvent<HTMLInputElement>
                              ) => {
                                if (details.name !== e.target.value) {
                                  setDetails({
                                    ...details,
                                    name: e.target.value,
                                  });

                                  setDirty(true);
                                }
                              }}
                            />
                            <StatusAutoComplete
                              error={errors.status ? true : false}
                              helperText={
                                errors.status ? errors.status.message : ""
                              }
                              label="Status *"
                              name="status"
                              inputRef={register({
                                required: "Status is required.",
                              })}
                              valueFilter={
                                details?.status
                                  ? {
                                      key: "value",
                                      values: [details?.status],
                                    }
                                  : undefined
                              }
                              filterHandle={(value: any) => {
                                setDetails({
                                  ...details,
                                  status: value.value || undefined,
                                });
                                if (details.status !== value?.value) {
                                  setDirty(true);
                                }
                              }}
                            />
                            <AcademicCalendarAutoComplete
                              error={errors.termType ? true : false}
                              helperText={
                                errors.termType ? errors.termType.message : ""
                              }
                              label="Term Type *"
                              name="termType"
                              inputRef={register({
                                required: "Term Type is required.",
                              })}
                              valueFilter={
                                details?.termType
                                  ? {
                                      key: "value",
                                      values: [details?.termType],
                                    }
                                  : undefined
                              }
                              filterHandle={(value: any) => {
                                console.log("value", value);
                                setDetails({
                                  ...details,
                                  termType:
                                    (value?.value && Number(value.value)) ||
                                    undefined,
                                });
                                if (details.termType !== value?.value) {
                                  setDirty(true);
                                }
                              }}
                              defaultSelectedFirstItem={false}
                            />
                            <InputField
                              required
                              type={InputType.NUMBER}
                              label="Instructional Weeks"
                              name="instructionalWeeks"
                              inputRef={register({
                                required: "Instructional Weeks is required.",
                                maxLength: {
                                  value: 7,
                                  message:
                                    "Max value for instructional week is 9999999",
                                },
                                validate: () =>
                                  parseInt(
                                    (details.instructionalWeeks || 0).toString()
                                  ) !== details.instructionalWeeks
                                    ? "Instructional Weeks must be a number."
                                    : true,
                              })}
                              error={errors.instructionalWeeks ? true : false}
                              helperText={
                                errors.instructionalWeeks
                                  ? errors.instructionalWeeks.message
                                  : ""
                              }
                              onBlur={(
                                e: React.FocusEvent<HTMLInputElement>
                              ) => {
                                if (
                                  parseInt(e.target.value) !==
                                  details.instructionalWeeks
                                ) {
                                  setDetails({
                                    ...details,
                                    instructionalWeeks: parseInt(
                                      e.target.value
                                    ),
                                  });
                                  setDirty(true);
                                }
                              }}
                            />
                            <KeyboardDatePicker
                              required
                              name="startDate"
                              fullWidth
                              format="MM/dd/yyyy"
                              label="Term Start"
                              value={details?.startDate || null}
                              onChange={(date: any) => {
                                if (details.startDate !== date) {
                                  setDetails({
                                    ...details,
                                    startDate: date,
                                  });
                                  setDirty(true);
                                }
                                setTimeout(() => {
                                  triggerValidation(["startDate"]);
                                }, 50);
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              inputRef={register({
                                required: "Term Start is required.",
                              })}
                              error={errors.startDate ? true : false}
                              helperText={
                                errors.startDate ? errors.startDate.message : ""
                              }
                            />
                            <KeyboardDatePicker
                              fullWidth
                              required
                              name="endDate"
                              format="MM/dd/yyyy"
                              label="Term End"
                              value={details?.endDate || null}
                              onChange={(date: any) => {
                                if (details.endDate !== date) {
                                  setDetails({
                                    ...details,
                                    endDate: date,
                                  });
                                  setDirty(true);
                                }
                                setTimeout(() => {
                                  triggerValidation(["endDate"]);
                                }, 50);
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              inputRef={register({
                                required: "Term End is required.",
                                validate: () =>
                                  details?.startDate &&
                                  details?.endDate &&
                                  details?.startDate > details?.endDate
                                    ? "Term End must be greater than Term Start."
                                    : true,
                              })}
                              error={errors.endDate ? true : false}
                              helperText={
                                errors.endDate ? errors.endDate.message : ""
                              }
                            />
                          </Fragment>
                        )}
                      </form>
                    </CardContent>
                    <CardActions>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit(onSave)}
                        disabled={saving || deleting || loadingDetails}
                      >
                        SAVE
                      </Button>{" "}
                      {details?.id && details?.id !== EmptyGuid && (
                        <Button
                          disabled={saving || deleting || loadingDetails}
                          color="secondary"
                          variant="contained"
                          onClick={(e) => {
                            setPopMessage(
                              "Are you sure you want to delete this term?"
                            );
                            setPopAnchor(e.currentTarget);
                            setPopConfirmAction(() => () => {
                              callDelete(details?.id || EmptyGuid);
                            });
                          }}
                        >
                          DELETE
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
            <Popover
              anchorEl={popAnchor}
              open={Boolean(popAnchor)}
              onClose={() => {
                setPopAnchor(null);
                setPopMessage("");
                setPopConfirmAction(undefined);
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Card>
                <CardContent>
                  <Typography variant="h6">{popMessage}</Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      if (popConfirmAction) {
                        popConfirmAction();
                      }
                      setPopAnchor(null);
                      setPopMessage("");
                      setPopConfirmAction(undefined);
                    }}
                  >
                    Confirm
                  </Button>{" "}
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      setPopAnchor(null);
                      setPopMessage("");
                      setPopConfirmAction(undefined);
                    }}
                  >
                    CANCEL
                  </Button>
                </CardActions>
              </Card>
            </Popover>
          </React.Fragment>
        </div>
      </Popover>
    </div>
  );
};

export default AddTermModal;
