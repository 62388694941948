export const TenantSelectorApiAutoComplete = {
    config: {
        acId: "tenantSelector",
        cacheTime: 5,
        route: "/Security/TenantPicker/GetTenants",
        requestType: "GET",
        body: null,
        hasUrlParams: false,
        useCache: true,
        storeInCache: true,
        isCampusSpecific: false,
        isUserSpecific: false,
        loadOnRender: true,
        defaultSelectedFirstItem: true,
        optionsMappedFx: (data: Array<any>) => {
          return data;
        }
      }
};