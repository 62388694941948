import * as React from 'react';
import Dashboard from '../../pages/system/Dashboard';
import { Login } from '../security/Login';
import { ForgotPasswordEmail } from '../security/ForgotPasswordEmail';
import PlacementTab from '../../pages/student/placement/PlacementTab';
import TabItem from '../Tabs/TabItem';
import StudentInfo from '../../pages/student/profile/studentInfo/StudentInfo';
import ContactInfo from '../../pages/student/profile/contactInfo/ContactInfo';
import SapPolicyPage from "../../pages/student/profile/studentInfo/SapPolicyPage";
import Enrollments from '../../pages/student/profile/enrollments/Enrollments';
import NavTabs from '../_Layout/Tabs/NavTabs';
import Exams from '../../pages/student/grades/Exams';
import ClinicHours from '../../pages/student/grades/ClinicHours';
import ClinicServices from '../../pages/student/grades/ClinicServices';
import Ledger from '../../pages/student/financials/ledger/Ledger';
import Awards from '../../pages/student/financials/awards/Awards';
import PaymentPlans from '../../pages/student/financials/paymentPlans/PaymentPlans';
import AdvantageViewer from '../Common/AdvantageViewer';
import Documents from '../../pages/student/documents/Documents';
import Notes from '../../pages/student/notes/Notes';
import Termination from '../../pages/student/termination/Termination';
import UndoTermination from '../../pages/student/termination/UndoTermination';
import PostGradesByGroup from '../../pages/academics/PostGradesByGroup';
import SAP from '../../pages/academics/SAP';
import Transcripts from '../../pages/academics/Transcripts';
import ProgressReports from '../../pages/academics/ProgressReports';
import IPEDSFall12MonthPartADetail from '../../pages/reports/IPEDS/IPEDSFall12MonthPartADetail';
import IPEDSFall12MonthPartASummary from '../../pages/reports/IPEDS/IPEDSFall12MonthPartASummary';
import PostAttendance from '../../pages/attendance/PostAttendance';
import PostCreditHourAttendance from '../../pages/attendance/PostCreditHourAttendance';
import EditPunches from '../../pages/attendance/EditPunches';
import AdjustScheduledHours from '../../pages/attendance/AdjustScheduledHours';
import FundSources from '../../pages/studentAccounts/FundSources';
import TransactionCodes from '../../pages/studentAccounts/TransactionCodes';
import AccrualsARExport from '../../pages/studentAccounts/AccrualsARExport';
import NonTitleIVPostPage from '../../pages/studentAccounts/NonTitleIVPostPage';
import AccrualsARExportReport from '../../pages/reports/AccrualsARReport/AccrualsARExportReport';
import C_1098T from '../../pages/financialAid/1098T';
import RecalculateTitleIV from '../../pages/financialAid/RecalculateTitleIV';
import EmergencyFundAllocation from '../../pages/financialAid/EmergencyFundAllocation';
import GradDateCalculator from '../../pages/tools/GradDateCalculator';
import CustomFieldsPagesSetup from '../../pages/setup/customFields/CustomFieldsPagesSetup';
import GradeSystem from '../../pages/setup/grades/Systems/GradeSystem';
import GradeScale from '../../pages/setup/grades/Systems/GradeScale';
import CustomFieldsSetup from '../../pages/setup/customFields/CustomFieldsSetup';
import Reports from '../../pages/reports/Reports';
import ViewAllContacts from '../../pages/student/profile/contactInfo/ViewAllContacts';
import UserList from '../../pages/setup/users/userlist/UserList';
import ChargeDefinitionSetup from "../../pages/setup/chargeDefinition/ChargeDefinitionSetup";
import PipelineTab from '../../pages/admissions/pipeline/Pipeline';
import AppointmentsTab from '../../pages/admissions/appointments/Appointments';
import LeadAssignmentTab from '../../pages/admissions/leadAssignment/LeadAssignment';
import LeadImportTab from '../../pages/admissions/leadImport/LeadImport';
import ReportsTab from '../../pages/admissions/reports/Reports';
import LeadActivity from '../../pages/admissions/lead/activity/Activity';
import LeadAppointments from '../../pages/admissions/lead/appointments/Appointments';
import LeadMessages from '../../pages/admissions/lead/messages/Messages';
import LeadEmail from '../../pages/admissions/lead/email/Emails';
import LeadNotes from '../../pages/admissions/lead/notes/Notes';
import LeadRequirements from '../../pages/admissions/lead/requirements/Requirements';
import PermissionsPage from '../../pages/setup/users/userlist/Security/PermissionsPage';
import AfaIntegrator from '../../pages/setup/afa/AfaIntegrator';
import CoursesSetup from '../../pages/setup/creditHour/courses/CoursesSetup';
import Terms from '../../pages/setup/creditHour/Terms';
import ClassesSetup from '../../pages/setup/creditHour/classes/ClassesSetup';
import ScheduleSetup from '../../pages/setup/creditHour/schedule/ScheduleSetup'
import ClassRegistration from '../../pages/setup/academics/classRegistration/ClassRegistration';
import ClassDrop from '../../pages/setup/academics/classDrop/ClassDrop';
import InstructorSummary from '../../pages/setup/academics/Instructor/InstructorSummary';
import SystemConfigurationViewer from '../../pages/setup/systemConfiguration/SystemConfigurationViewer';
import SetupSchedule from '../../pages/attendance/SetupSchedule';
import ResultSAP from '../../pages/student/sap/ResultSAP';
import ResultTitleIVSAP from '../../pages/student/sap/ResultTitleIVSAP';
import R9010Mappings from '../../pages/setup/Reports/9010Mappings';
import Exceptions from '../../pages/integration/exceptions/Exception';
import StudentAppConfiguration from '../../pages/integration/StudentAppConfiguration';
import ProgramsSetup from '../../pages/setup/academics/ProgramProgramVersion/ProgramsSetup';
import ComponentTypeSetup from '../../pages/setup/grades/ComponentTypes/ComponentTypeSetup';
import ToProgram from '../../pages/student/transfer/ToProgram';
import ToCampus from '../../pages/student/transfer/ToCampus';
import RefundSetup from '../../pages/setup/refunds/RefundSetup';
import CopyProgramVersionToCampus from '../../pages/setup/academics/copyProgramVersion/CopyProgramVersionToCampus';
import GradeDescriptionDetail from '../../pages/setup/grades/Systems/GradeDescriptionDetail';
import NewEnrollment from '../../pages/student/newEnrollment/NewEnrollment';
import { security } from '../../constants/Security/securityConstants';
import AttendanceHistoryMultiple from '../../pages/reports/AttendanceHistory/AttendanceHistoryMultiple';
import MonthlyTimeClockAttendance from '../../pages/reports/MonthlyTimeClockAttendance/MonthlyTimeClockAttendance';
import AttendanceRecordsChanged from '../../pages/reports/AttendanceRecordsChanged/AttendanceRecordsChanged';
import AbsentTodayReport from '../../pages/reports/AbsentTodayReport/AbsentTodayReport';
import InSchoolTodayReport from '../../pages/reports/InSchoolTodayReport/InSchoolTodayReport';
import BackOnTrackListReport from '../../pages/reports/BackOnTrack/BackOnTrackListReport';
import PrintTimeClockPunches from '../../pages/reports/TimeClockPunches/TimeClockPunches';
import AidReceivedAllStudentsByDate from '../../pages/reports/AidReceivedAllStudentsByDate/AidReceivedAllStudentsByDate';
import Holidays from '../../pages/setup/other/Holidays';
import AttendanceSheetReport from '../../pages/reports/AttendanceSheetReport/AttendanceSheetReport';
import ACAATTReport from '../../pages/reports/ACAATTReport/ACAATTReport';
import AttendancePercentageConcernsReport from '../../pages/reports/AttendancePercentageConcernsGraduateStatus/AttendancePercentageConcerns';
import GraduateStatusReport from '../../pages/reports/AttendancePercentageConcernsGraduateStatus/GraduateStatus';
import NetPriceCalculator from '../../pages/reports/NetPriceCalculatorReport/NetPriceCalculatorReport';
import NetPriceCalculatorDetails from '../../pages/reports/NetPriceCalculatorReport/NetPriceCalculatorDetailsReport';
import TuitionProjectedByDateRangeReport from '../../pages/reports/TuitionProjectedByDateRangeReport/TuitionProjectedByDateRangeReport';
import StudentPaidByTypeReport from '../../pages/reports/StudentPaidByType/StudentPaidByTypeReport';
import SAPCheckReport from '../../pages/reports/SAPCheck/SAPCheckReport';
import SAPCheckListingByDateReport from '../../pages/reports/SAPCheck/SAPCheckListingByDateReport';
import StudentListingReport from '../../pages/reports/StudentListing/StudentListingReport';
import PaymentPlanTrackerReport from '../../pages/reports/PaymentPlanTrackerReport/PaymentPlanTrackerReport';
import WeeklyAttendanceReport from '../../pages/reports/WeeklyAttendanceReport/WeeklyAttendanceReport';
import StudentsScheduleChange from '../../pages/reports/ScheduleChange/StudentsScheduleChange';
import TardyReport from '../../pages/reports/TardyReport/TardyReport';
import GradPlacementReport from '../../pages/reports/GradPlacement/GradPlacementReport';
import COECPLReport from '../../pages/reports/COEBPLReport/COECPLReport';
import BPPEReport from '../../pages/reports/BPPE/BPPEReport';
import STRFReport from '../../pages/reports/BPPE/STRFReport';
import CampusAttendanceReport from '../../pages/reports/CampusAttendance/CampusAttendanceReport';
import StudentFinancialAid from '../../pages/reports/StudentFinancialAid/StudentFinancialAid';
import ReportsSetup from '../../pages/setup/Reports/ReportsSetup';
import Map9010Page from '../../pages/setup/Reports/Map9010Page';
import IllinoisTranscriptReport from '../../pages/reports/IllinoisTranscriptReport';
import LedgerBalanceReport from '../../pages/reports/StudentFinancialAid/LedgerBalanceReport';
import RemainingBalToSchReport from '../../pages/reports/StudentFinancialAid/RemainingBalToSchReport';
import ExpectedDisbursementsReport from '../../pages/reports/StudentFinancialAid/ExpectedDisbursementsReport';
import PaidDisbursementsReport from '../../pages/reports/StudentFinancialAid/PaidDisbursementsReport';
import AutomatedDisbursementReport from '../../pages/reports/StudentFinancialAid/AutomatedDisbursementReport';
import StateWiseLicense from '../../pages/setup/campus/StateWiseLicense';
import StudentLedgers from '../../pages/reports/StudentFinancialAid/StudentLedgers';
import StateBoardReport from '../../pages/reports/StateBoardReport/StateBoardReport';
import TitleIVAuditReport from '../../pages/reports/TitleIVAuditReport/TitleIVAuditReport';
import AttendanceSummary from '../../pages/reports/AttendanceSummary/AttendanceSummary';
import Report9010 from '../../pages/reports/9010/Report9010';
import GradeComponents from '../../pages/integration/lms/GradeComponents';
import InstructorReport from '../../pages/reports/InstructorReport/InstructorReport';
import FallAllEnrollmentsPartC from '../../pages/reports/IPedsReports/FallAllEnrollmentsPartC';
import AttendanceExceptions from '../../pages/reports/AttendanceHistory/AttendanceExceptionsReport';
import TranscriptReport from '../../pages/reports/Transcript';
import ProgressReport from '../../pages/reports/ProgressReport/ProgressReport';
import StudentLoanDebt from '../../pages/reports/LoanDebt/StudentLoanDebt';
import PostGradesByClass from '../../pages/setup/academics/postGradesByClass/PostGradesByClass';
import ExitCounselingExport from '../../pages/reports/ExitCounselingExport/ExitCounselingExport';
import ClassAttendanceSummary from '../../pages/reports/AttendanceSummary/ClassAttendanceSummary';
import GainfulEmployment from '../../pages/reports/GainfulEmployment/GainfulEmployment';
import StudentStatusChangesReport from '../../pages/reports/StudentListing/StudentStatusChangesReport';
import CIPDataReport from '../../pages/reports/IPedsReports/CIPDataReport';
import IPEDSMissingDataReport from '../../pages/reports/IPedsReports/IPEDSMissingDataReport';
import IPEDSFallPartASummaryReport from '../../pages/reports/IPedsReports/FallAllEnrollmentPartASummary';
import FallCompetionsByLevelReport from '../../pages/reports/IPedsReports/FallCompetionsByLevelReport';
import IPEDSDisabilityService from '../../pages/reports/IPEDSDisabilityService/IPEDSDisabilityService';
import CostIIPartCDetailSummary from '../../pages/reports/IPEDS/CostIIPartCDetailSummary';
import FallAllCompletersDetailAndSummary from '../../pages/reports/IPedsReports/FallAllCompletersDetailAndSummary';
import Fall12MonthEnrollmentPartBDetailAndSummary from '../../pages/reports/IPedsReports/Fall12MonthEnrollmentPartBDetailAndSummary';
import ProgramHoursReport from '../../pages/reports/AttendanceHistory/ProgramHoursReport';
import CostIIFinancialAidPartA from '../../pages/reports/StudentFinancialAid/CostIIFinancialAidPartA';
import CostIIPartBDetailSummary from '../../pages/reports/IPEDS/CostIIPartBDetailSummary';



export default class ComponentList {
	static components: any = {
		Dashboard: Dashboard,
		Login: Login,
		ForgotPasswordEmail: ForgotPasswordEmail,
		LeadTabs: (props: any) => {
			return ComponentList.GetNavTabs('LeadTabs', props);
		},
		PipelineTab: PipelineTab,
		AppointmentsTab: AppointmentsTab,
		LeadAssignmentTab: LeadAssignmentTab,
		LeadImportTab: LeadImportTab,
		ReportsTab: ReportsTab,
		StudentTabs: (props: any) => {
			return ComponentList.GetNavTabs('StudentTabs', props);
		},
       PoliciesTab: (props: any) => {
      return ComponentList.GetNavTabs("PoliciesTab", props);
    },
		GradesNewSetupTabs: (props: any) => {
			return ComponentList.GetNavTabs('GradesNewSetupTabs', props);
		},
		GradeTabs: (props: any) => {
			return ComponentList.GetNavTabs('GradeTabs', props);
		},
		FinancialTabs: (props: any) => {
			return ComponentList.GetNavTabs('FinancialTabs', props);
		},
		Users: (props: any) => {
			return ComponentList.GetNavTabs('Users', props);
		},
		SAPTabs: (props: any) => {
			return ComponentList.GetNavTabs('SAPTabs', {...props, maxWidth:1350});
		},
		StudentStatusChangesReport: StudentStatusChangesReport,
		StudentFinancialAid: StudentFinancialAid,
		LedgerBalanceReport:LedgerBalanceReport,
		RemainingBalToSchReport:RemainingBalToSchReport,
		ExpectedDisbursementsReport:ExpectedDisbursementsReport,
		PaidDisbursementsReport: PaidDisbursementsReport,
		AutomatedDisbursementReport: AutomatedDisbursementReport,
		PlacementTab: PlacementTab,
		Notes: Notes,
		CoursesSetup: CoursesSetup,
		TermsSetup: Terms,
		ClassesSetup: ClassesSetup,
		ScheduleSetup: ScheduleSetup,
		ClassRegistration: ClassRegistration,
		ClassDrop: ClassDrop,
		PostGradesByClass:PostGradesByClass,
		InstructorSummary: InstructorSummary,
		Exception: Exceptions,
		StudentAppConfiguration: StudentAppConfiguration,
		Termination: Termination,
		Documents: Documents,
		PostGradesByGroup: PostGradesByGroup,
		SAP: SAP,
		Transcripts: Transcripts,
		ProgressReports: ProgressReports,
		IPEDSFall12MonthPartADetail: IPEDSFall12MonthPartADetail,
		IPEDSFall12MonthPartASummary: IPEDSFall12MonthPartASummary,
		PostAttendance: PostAttendance,
		PostCreditHourAttendance: PostCreditHourAttendance,
		EditPunches: EditPunches,
		AdjustScheduledHours: AdjustScheduledHours,
		FundSources: FundSources,
		TransactionCodes: TransactionCodes,
		AccrualsARExport: AccrualsARExport,
		NonTitleIVPostPage: NonTitleIVPostPage,
		AccrualsARExportReport: AccrualsARExportReport,
		'1098T': C_1098T,
		//'9010Export': C_9010Export,
		'9010Export': Report9010,
		GradDateCalculator: GradDateCalculator,
		RecalculateTitleIV: RecalculateTitleIV,
		EmergencyFundAllocation: EmergencyFundAllocation,
		Reports: Reports,
		ToProgram: ToProgram,
		ToCampus: ToCampus,
		ViewAllContacts: ViewAllContacts,
		SetupSchedule: SetupSchedule,
		ProgramsSetup: ProgramsSetup,
		ComponentTypeSetup : ComponentTypeSetup,
		RefundSetup: RefundSetup,
		ChargeDefinitionSetup1: ChargeDefinitionSetup,
		ProgramSetupTabs: (props: any) => {
			return ComponentList.GetNavTabs('ProgramSetupTabs', {...props, maxWidth:1350});
		},
		AttendanceHistoryMultiple: AttendanceHistoryMultiple,
		AttendanceSummary:AttendanceSummary,
		ClassAttendanceSummary:ClassAttendanceSummary,
		GainfulEmployment:GainfulEmployment,
		IPEDSDisabilityService:IPEDSDisabilityService,
		CostIIPartCDetailSummary: CostIIPartCDetailSummary,
		CostIIPartBDetailSummary: CostIIPartBDetailSummary,
		MonthlyTimeClockAttendance: MonthlyTimeClockAttendance,
		AttendanceRecordsChanged: AttendanceRecordsChanged,
		AbsentTodayReport: AbsentTodayReport,
		StateBoardReport:StateBoardReport,
		InSchoolTodayReport: InSchoolTodayReport,
		BackOnTrackListReport: BackOnTrackListReport,
		PrintTimeClockPunches: PrintTimeClockPunches,
		Holidays: Holidays,
		AidReceivedAllStudentsByDate: AidReceivedAllStudentsByDate,
		ACAATTReport: ACAATTReport,
		AttendancePercentageConcernsReport: AttendancePercentageConcernsReport,
		GraduateStatusReport: GraduateStatusReport,
		NetPriceCalculator: NetPriceCalculator,
		NetPriceCalculatorDetails: NetPriceCalculatorDetails,
		TuitionProjectedByDateRangeReport: TuitionProjectedByDateRangeReport,
		StudentPaidByTypeReport: StudentPaidByTypeReport,
		SAPCheckReport: SAPCheckReport,
		SAPCheckListingByDateReport: SAPCheckListingByDateReport,
		StudentListingReport: StudentListingReport,
		CustomFieldsTabs: (props: any) => {
			return ComponentList.GetNavTabs('CustomFieldsTabs', props);
		},
		GradesSetupTabs: (props: any) => {
			return ComponentList.GetNavTabs('GradesSetupTabs', {...props, maxWidth:1800});
		},
		CoursesTabs: (props: any) => {
			return ComponentList.GetNavTabs('CoursesTabs', props);
		},
		PoliciesTabs: (props: any) => {
			return ComponentList.GetNavTabs('PoliciesTabs', props);
		},
		ReportsSetupTabs: (props: any) => {
			return ComponentList.GetNavTabs('ReportsSetupTabs', props);
		},
		CampusManagementTabs: (props: any) => {
			return ComponentList.GetNavTabs('CampusManagementTabs', props);
		},
		AfaIntegrator: AfaIntegrator,
		SystemConfigurationViewer: (props: any) => {
			return ComponentList.GetSearchableAdvantageViewer(
				'SystemConfigurationViewer',
				'Manage System Configuration Settings',
				props
			);
		},

		FinancialConfigurationViewer: (props: any) => {
			return ComponentList.GetSearchableAdvantageViewer(
				'FinancialConfigurationViewer',
				'Manage Financial Configuration Settings',
				props
			);
		},
		NewEnrollment: NewEnrollment,
		UndoTermination: UndoTermination,
		AttendanceSheetReport: AttendanceSheetReport,
		PaymentPlanTrackerReport: PaymentPlanTrackerReport,
		WeeklyAttendanceReport: WeeklyAttendanceReport,
		StudentsScheduleChange: StudentsScheduleChange,
		TardyReport:TardyReport,
		GradPlacementReport:GradPlacementReport,
		COECPLReport:COECPLReport,
		BPPEReport:BPPEReport,
		CampusAttendanceReport:CampusAttendanceReport,
		STRFReport:STRFReport,
		ExitCounselingExport:ExitCounselingExport,
		StudentLoanDebt: StudentLoanDebt,
		IllinoisTranscriptReport: IllinoisTranscriptReport,
		StudentTranscriptReport: TranscriptReport,
		StudentProgressReport: ProgressReport,
		FallAllEnrollmentsPartC: FallAllEnrollmentsPartC,
		FallAllCompletersDetailAndSummary:FallAllCompletersDetailAndSummary,
		Fall12MonthEnrollmentPartBDetailAndSummary:Fall12MonthEnrollmentPartBDetailAndSummary,
		StudentLedgers: StudentLedgers,
		TitleIVAuditReport: TitleIVAuditReport,
		InstructorReport: InstructorReport,
		AttendanceExceptions: AttendanceExceptions,
		ProgramHoursReport: ProgramHoursReport,
		CostIIFinancialAidPartA:CostIIFinancialAidPartA,
		CIPDataReport:CIPDataReport,
		IPEDSMissingDataReport:IPEDSMissingDataReport,
		IPEDSFallPartASummaryReport:IPEDSFallPartASummaryReport,
		FallCompetionsByLevelReport:FallCompetionsByLevelReport,
		LMS: (props: any) => {
			return ComponentList.GetNavTabs('LMS', props);
		}
	};

	static tabs: Array<any> = [
		{
			parent: 'LMS',
			tabs: [
				{
					label: 'Grade components',
					value: 'gradeComponents',
					component: GradeComponents,
					title: 'Grade components',
					href: '/setup/lms/gradeComponents',
				}
			] as Array<TabItem>,
		},

		{
			parent: 'LeadTabs',
			tabs: [
				{
					value: 'activity',
					label: 'Activity',
					component: LeadActivity,
					title: 'Activity',
					href: '/admissions/1/lead/activity',
				},
				{
					value: 'appointments',
					label: 'Appointments',
					component: LeadAppointments,
					title: 'Appointments',
					href: '/admissions/1/lead/appointments',
				},
				{
					value: 'messages',
					label: 'Messages',
					component: LeadMessages,
					title: 'Messages',
					href: '/admissions/1/lead/messages',
				},
				{
					value: 'email',
					label: 'Email',
					component: LeadEmail,
					title: 'Email',
					href: '/admissions/1/lead/email',
				},
				{
					value: 'notes',
					label: 'Notes',
					component: LeadNotes,
					title: 'Notes',
					href: '/admissions/1/lead/notes',
				},
				{
					value: 'requirements',
					label: 'Requirements',
					component: LeadRequirements,
					title: 'Requirements',
					href: '/admissions/1/lead/requirements',
				},
			] as Array<TabItem>,
		},
		{
			parent: 'StudentTabs',
			tabs: [
				{
					value: 'info',
					label: 'Student Profile',
					component: StudentInfo,
					title: 'Profile',
					href: '/student/1/profile/info',
					permissions: [
						security.permissions.student.viewStudentInfo,
						security.permissions.student.updateStudentInfo,
					],
				},
				{
					value: 'contactInfo',
					label: 'Contact Info',
					component: ContactInfo,
					title: 'Profile',
					href: '/student/1/profile/contactInfo',
					permissions: [
						security.permissions.student.viewContactInfo,
						security.permissions.student.updateContactInfo,
					],
				},
				{
					value: 'enrollments',
					label: 'Enrollments',
					component: Enrollments,
					title: 'Profile',
					href: '/student/1/profile/enrollments',
					permissions: [
						security.permissions.student.viewEnrollmentInfo,
						security.permissions.student.updateEnrollmentInfo,
					],
				},
			] as Array<TabItem>,
		},
    {
      parent: "PoliciesTab",
      tabs: [
        {
          value: "info",
          label: "Policies",
          component: SapPolicyPage,
          title: "Profile2",
          href:"/student/1/policies/info",
          permissions: [
            security.permissions.student.viewStudentInfo,
            security.permissions.student.updateStudentInfo,
          ],
        },
      ] as Array<TabItem>,
    },
		{
			parent: 'GradeTabs',
			tabs: [
				{
					value: 'exams',
					label: 'Grades',
					component: Exams,
					title: 'Academics',
					href: '/student/1/grades/exams',
					permissions: [
						security.permissions.student.viewExamResults,
						security.permissions.student.updateExamResults,
						security.permissions.student.deleteExamResults,
					],
				},
				{
					value: 'clinicServices',
					label: 'Clinic Services',
					component: ClinicServices,
					title: 'Academics',
					href: '/student/1/grades/clinicServices',
					permissions: [
						security.permissions.student.viewClinicServices,
						security.permissions.student.updateClinicServices,
						security.permissions.student.deleteClinicServices,
					],
				},
				{
					value: 'clinicHours',
					label: 'Clinic Hours',
					component: ClinicHours,
					title: 'Academics',
					href: '/student/1/grades/clinicHours',
					permissions: [
						security.permissions.student.viewClinicHoursDetails,
						security.permissions.student.updateClinicHoursDetails,
						security.permissions.student.deleteClinicHoursAttempts,
					],
				},
			] as Array<TabItem>,
		},
		{
			parent: 'FinancialTabs',
			tabs: [
				{
					value: 'ledger',
					label: 'Ledger',
					component: Ledger,
					title: 'Financials',
					href: '/student/1/financials/ledger',
					permissions: [
						security.permissions.student.viewLedger,
						security.permissions.student.updateLedger,
						security.permissions.student.postTransactions,
					],
				},
				{
					value: 'awards',
					label: 'Awards',
					component: Awards,
					title: 'Financials',
					href: '/student/1/financials/awards',
					permissions: [
						security.permissions.student.viewAwards,
						security.permissions.student.updateAwards,
						security.permissions.student.deleteAwards,
					],
				},
				{
					value: 'paymentPlans',
					label: 'Payment Plans',
					component: PaymentPlans,
					title: 'Financials',
					href: '/student/1/financials/paymentPlans',
					permissions: [
						security.permissions.student.viewPaymentPlans,
						security.permissions.student.updatePaymentPlans,
						security.permissions.student.deletePaymentPlans,
					],
				},
			] as Array<TabItem>,
		},
		{
			parent: 'SAPTabs',
			tabs: [
				{
					value: 'titleIVSAP',
					label: 'Title IV SAP',
					component: ResultTitleIVSAP,
					title: 'SAP Results',
					href: '/student/1/sap/titleIVSAP',
					permissions: [security.permissions.student.viewTitleIVSAPResults],
				},
				{
					value: 'results',
					label: 'SAP',
					component: ResultSAP,
					title: 'Results',
					href: '/student/1/sap/results',
					permissions: [security.permissions.student.viewSAPResults],
				},
			] as Array<TabItem>,
		},
		{
			parent: 'ProgramSetupTabs',
			tabs: [
				{
					value: 'copyProgramVersionToCampus',
					label: 'Copy Program Version',
					component: CopyProgramVersionToCampus,
					title: 'New Campus',
					href: '/setup/programs/copyProgramVersionToCampus',
					permissions: [security.permissions.student.viewTitleIVSAPResults],
				},
			
			] as Array<TabItem>,
		},
		{
			parent: 'GradesSetupTabs',
			tabs: [
				
				{
					value: 'system',
					label: 'System',
					component: GradeSystem,
					title: 'GradeSystem',
					href: '/setup/grades/system',
					permissions: [
						security.permissions.maintenance.viewGradingSystem,
						security.permissions.maintenance.updateGradingSystem,
						security.permissions.maintenance.deleteGradingSystem,
					],
				},
				{
					value: 'scales',
					label: 'Scales',
					component: GradeScale,
 					title: 'GradesScale',
					href: '/setup/grades/scales',
					permissions: [
						security.permissions.maintenance.viewGradingScales,
						security.permissions.maintenance.updateGradingScales,
						security.permissions.maintenance.deleteGradingScales,
					],
				},
				{
					value: 'descriptionDetails',
					label: 'Description Details',
					component: GradeDescriptionDetail,
					title: 'Grades',
					href: '/setup/grades/descriptionDetails',
					permissions: [
						security.permissions.maintenance.viewGradeDescriptionDetails,
						security.permissions.maintenance.updateGradeDescriptionDetails,
						security.permissions.maintenance.deleteGradeDescriptionDetails,
					],
				},
			] as Array<TabItem>,
		},
		{
			parent: 'CoursesTabs',
			tabs: [
				{
					value: 'courses',
					label: 'Courses',
					component: AdvantageViewer,
					isLegacy: true,
					params: { desc: 'Courses', mod: 'AR' },
					title: 'Courses',
					href: '/setup/courses/courses',
					advantageLink: '/AR/Courses.aspx',
					resId: 38,
					permissions: [
						security.permissions.maintenance.viewCourses,
						security.permissions.maintenance.updateCourses,
						security.permissions.maintenance.deleteCourses,
					],
				},
				{
					value: 'categories',
					label: 'Categories',
					component: AdvantageViewer,
					isLegacy: true,
					params: { desc: 'Course Categories', mod: '' },
					title: 'Courses',
					href: '/setup/courses/categories',
					advantageLink: '/SY/MaintenanceBase.aspx',
					resId: 350,
					permissions: [
						security.permissions.maintenance.viewCourseCategories,
						security.permissions.maintenance.updateCourseCategories,
						security.permissions.maintenance.deleteCourseCategories,
					],
				},
				{
					value: 'groups',
					label: 'Groups',
					component: AdvantageViewer,
					isLegacy: true,
					params: { desc: 'Course Groups', mod: '' },
					title: 'Courses',
					href: '/setup/courses/groups',
					advantageLink: '/AR/CourseGroups.aspx',
					resId: 44,
					permissions: [
						security.permissions.maintenance.viewCourseGroups,
						security.permissions.maintenance.updateCourseGroups,
						security.permissions.maintenance.deleteCourseGroups,
					],
				},
			] as Array<TabItem>,
		},
		{
			parent: 'Users',
			tabs: [
				{
					value: 'users',
					label: 'Users',
					component: UserList,
				},
				{
					value: 'permissions',
					label: 'Permissions',
					component: PermissionsPage,
				},
			] as Array<TabItem>,
		},
		{
			parent: 'CustomFieldsTabs',
			tabs: [
				{
					value: 'fields',
					label: 'Custom Fields',
					component: CustomFieldsSetup,
					title: 'Custom Fields',
					href: '/setup/customFields/fields',
				},
				{
					value: 'pageSetup',
					label: 'Pages and Custom Fields',
					component: CustomFieldsPagesSetup,
					title: 'Pages and Custom Fields',
					href: '/setup/customFields/pageSetup',
				},
			] as Array<TabItem>,
		},
		{
			parent: 'PoliciesTabs',
			tabs: [
				{
					value: 'SAP',
					label: 'Policies',
					component: AdvantageViewer,
					isLegacy: true,
					params: { desc: 'SAP Policy Setup', mod: '' },
					title: 'SAP',
					href: '/setup/policies/SAP',
					advantageLink: '/AR/SAPCodeSetup.aspx',
					resId: 43,
					permissions: [
						security.permissions.maintenance.viewSAPPolicies,
						security.permissions.maintenance.updateSAPPolicies,
						security.permissions.maintenance.deleteSAPPolicies,
					],
				},
			] as Array<TabItem>,
		},
		{
			parent: 'ReportsSetupTabs',
			tabs: [
				{
					value: '9010Mappings',
					label: '90/10 Mapping',
					//component: R9010Mappings,
					component:Map9010Page,
					isLegacy: true,
					title: 'Reports',
					href: '/setup/reportsSetup/9010Mappings',
					// permissions: [
					// 	security.permissions.reports.view9010Mappings,
					// 	security.permissions.reports.update9010Mappings,
					// 	security.permissions.reports.delete9010Mappings,
					// ],
				},
				{
					value: 'stateBoardAccreditingAgencySettings',
					label: 'State Board/Accrediting Agency',
					component: AdvantageViewer,
					isLegacy: true,
					params: { desc: '', mod: '' },
					title: 'Reports',
					href: '/setup/reportsSetup/stateBoardAccreditingAgencySettings',
					advantageLink: '/SY/StateBoardSettings.aspx',
					resId: 873,
					permissions: [
						security.permissions.reports
							.viewStateBoardAccreditingAgencySettings,
						security.permissions.reports
							.updateStateBoardAccreditingAgencySettings,
						security.permissions.reports
							.deleteStateBoardAccreditingAgencySettings,
					],
				},
				{
					value: 'configuration',
					label: 'REPORTS SET UP',
					component: ReportsSetup,
					//component:ExamsTable,
					isLegacy: true,
					params: { desc: '', mod: '' },
					title: 'Reports',
					href: '/setup/reportsSetup/configuration',
					advantageLink: '/SY/StateBoardSettings.aspx',
					resId: 873,
				},
				// {
				// 	value: 'configuration',
				// 	label: 'REPORTS SET UP',
				// 	component: ReportsSetup,
				// 	isLegacy: true,
				// 	params: { desc: '', mod: '' },
				// 	title: 'Reports',
				// 	href: '/setup/reportsSetup/configuration',
				// 	advantageLink: '/SY/StateBoardSettings.aspx',
				// 	resId: 873,
				// },
			] as Array<TabItem>,
		},
		{
			parent: 'CampusManagementTabs',
			tabs: [
				{
					value: 'campuses',
					label: 'Campuses',
					component: AdvantageViewer,
					isLegacy: true,
					params: { desc: 'Campus', mod: 'SY' },
					title: 'Campus Management',
					href: '/setup/campusManagement/campuses',
					advantageLink: '/SY/CampusAddress.aspx',
					resId: 1,
					permissions: [
						security.permissions.maintenance.viewCampus,
						security.permissions.maintenance.updateCampus,
						security.permissions.maintenance.deleteCampus,
					],
				},
				{
					value: 'collegeDivisions',
					label: 'College Divisons',
					component: AdvantageViewer,
					isLegacy: true,
					params: { desc: 'College Divisions', mod: '' },
					title: 'Campus Management',
					href: '/setup/campusManagement/collegeDivisions',
					advantageLink: '/SY/MaintenanceBase.aspx',
					resId: 100,
					permissions: [
						security.permissions.maintenance.viewCollegeDivisions,
						security.permissions.maintenance.updateCollegeDivisions,
						security.permissions.maintenance.deleteCollegeDivisions,
					],
				},
				{
					value: 'departments',
					label: 'Departments',
					component: AdvantageViewer,
					isLegacy: true,
					params: { desc: '', mod: '' },
					title: 'Campus Management',
					href: '/setup/campusManagement/departments',
					advantageLink: '/AR/Departments.aspx',
					resId: 101,
					permissions: [
						security.permissions.maintenance.viewDepartments,
						security.permissions.maintenance.updateDepartments,
						security.permissions.maintenance.deleteDepartments,
					],
				},
				{
					value: 'stateLicenses',
					label: 'State Wise Licenses',
					component: StateWiseLicense,
					isLegacy: false,
					title: 'Campus Management',
					href: '/setup/campusManagement/stateLicenses',
					permissions: [
						security.permissions.maintenance.viewCampus,
						security.permissions.maintenance.updateCampus,
						security.permissions.maintenance.deleteCampus,
					],
				}
			] as Array<TabItem>,
		},
	];

	public static GetComponentByName(componentName: string) {
		const Component = ComponentList.components[componentName];
		return Component;
	}

	public static GetNavTabs(componentName: string, props: any) {
		let t = ComponentList.tabs.find((tabs) => tabs.parent === componentName);

		if (t) {
			const componentElement = (
				<NavTabs {...props} pKey={componentName} tabs={t.tabs} />
			);
			return componentElement;
		}
		return undefined;
	}

	public static GetSearchableAdvantageViewer(
		parentComponentName: string,
		title: string,
		props: any
	) {
		return (
			<SystemConfigurationViewer
				{...props}
				parentComponentName={parentComponentName}
				title={title}
			/>
		);
	}
	public static GetComponentTabByName(parentName: string, tabName: string) {
		let t = ComponentList.tabs.find((tabs) => tabs.parent === parentName);
		if (t && t.tabs) {
			const childTab = t.tabs.find(
				(tab: TabItem) => tab.value === tabName
			) as TabItem;

			if (childTab) return childTab;
		}
		return undefined;
	}

	public static GetComponentElementByName(componentName: string) {
		const Component = ComponentList.components[componentName];
		return <Component />;
	}

	public static GetComponentElementByNameWithProps(
		componentName: string,
		props: any
	) {
		const Component = ComponentList.components[componentName];
		return <Component {...props} />;
	}
}
