import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import { Paper, Card, IconButton } from "@material-ui/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as attendanceApi from "../../api/student/attendance/attendanceApi";
import CustomTableHeader from "../../components/Tables/CustomTableHeader";
import {
	CustomHeadCell,
	Order,
} from "../../interfaces/common/Table/CustomHeadCell";
import * as TableFunctions from "../../utils/tableFunctions";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { getLmsImportGradesLog } from "../../api/student/academics/postGradesApi";

const headCells: CustomHeadCell<any>[] = [
	{
		id: "fileName",
		numeric: false,
		disablePadding: false,
		label: "File name",
		widthType: "M",
	},
	{
		id: "modDate",
		numeric: false,
		disablePadding: false,
		label: "Date",
		widthType: "M",
	},
	{
		id: "modUser",
		numeric: true,
		disablePadding: false,
		label: "Mod User",
		widthType: "M",
	},
	{
		id: "status",
		numeric: true,
		disablePadding: false,
		label: "Status",
		widthType: "M",
	},
    {
		id: "action",
		numeric: true,
		disablePadding: false,
		label: "",
		widthType: "M",
	},
];

const useToolbarStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(1),
			height: "50px",
		},
		highlight:
			theme.palette.type === "light"
				? {
						color: theme.palette.secondary.main,
						backgroundColor: theme.palette.secondary.light,
				  }
				: {
						color: theme.palette.text.primary,
						backgroundColor: theme.palette.secondary.dark,
				  },
		title: {
			flex: "1 1 100%",
		},
		header: {
			width: "100%",
			height: "90%",
			display: "inline-block",
			border: "5px",
			float: "left",
			color: "#a8a8a8",
		},
		
		date: {
			width: "100%",
		},
		dateDiv: {
			paddingRight: theme.spacing(2),
			width: "20%",
		},
		searchDiv: {
			paddingRight: theme.spacing(2),
			float: "right",
			width: "100%",
		},
	})
);

// enhance table styling
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			height: "300px",
			width: "100%",
		},
		cardWrapper: {
			height: "100%",
			width: "100%",
		},
		paper: {
			width: "100%",
			marginBottom: theme.spacing(2),
		},
		tableWrapper: {
			width: "100%",
		},
		actionCell: {
			whiteSpace: "nowrap",
		},
		bodyWrapper:{
			height:250,
			overflowY:"auto",
		},gridCellTypeMedium: {
			width: "25%",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(0),
			whiteSpace: "nowrap",
		  },
	})
);

type LmsLogGridProps = {
	onShowClick: Function;
	setLogsData: any;
	logsData: any;
	setSnackBar: any;
	refreshGrid?: any;
};
export default function LmsLogGrid(props: LmsLogGridProps) {
	const classes = useStyles({});
	const [order, setOrder] = React.useState<Order>("desc");
	const [orderBy, setOrderBy] = React.useState<keyof any>("modDate");

	const [selected, setSelected] = React.useState<string[]>([]);
	const [searchFilter, setsearchFilter] = useState<string | null>("");
	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const filterDataBySearch = (data: any[]): any[] => {
		if (searchFilter === "" || searchFilter === undefined) return data;

		let searchVal: string = searchFilter
			? (searchFilter as string).toLowerCase()
			: "";
		return data.filter((row: any) => {
			return row && row.fileName;
		});
	};
	const refreshGridData = () => {
		getLmsImportGradesLog().then(
            (response: any) => {
                if (response) {
                    let data = response.data;
                    props.setLogsData(data);
                }
            },
            (exception: any) => {}
        );
	};

	React.useEffect(() => {
		refreshGridData();
	}, [props.refreshGrid]);

	const handleSearchOnChange = (v: any) => {
		setsearchFilter(v.target.value);
	};

	return (
		<Paper className={classes.root}>
			<Card className={classes.cardWrapper}>
				<div className={classes.tableWrapper}>
					<CustomTableHeader<any>
						cells={headCells}
						defaultOrder={order}
						defaultOrderBy={orderBy}
						setOrder={setOrder}
						setOrderBy={setOrderBy}
						key={"tex"}
					/>
					<div className={classes.bodyWrapper}>
					<TableBody>
						{TableFunctions.stableSort(
							filterDataBySearch(props.logsData as any) as any,
							TableFunctions.getSorting(order, orderBy)
						).map((row: any, index: any) => {
							let rowData = row as any;

							return (
								<React.Fragment>
									<TableRow>
										<TableCell className={classes.gridCellTypeMedium}>{rowData.fileName}</TableCell>
										<TableCell className={classes.gridCellTypeMedium}>
											{new Date(rowData.modDate as any).toLocaleDateString()}
										</TableCell>
										<TableCell className={classes.gridCellTypeMedium}>{rowData.modUser}</TableCell>

										<TableCell className={classes.gridCellTypeMedium}>
											{rowData.status === 2
												? "Processing"
												: rowData.status === 1
												? "Completed"
												: "Error"}
										</TableCell>
										<TableCell className={classes.gridCellTypeMedium}>
											<IconButton
												color="primary"
												aria-label="Show log"
												component="span"
												onClick={(e: any) => {
													props.onShowClick(rowData);
												}}
											>
												<VisibilityOutlinedIcon></VisibilityOutlinedIcon>
											</IconButton>
										</TableCell>
									</TableRow>
								</React.Fragment>
							);
						})}
					</TableBody>
					</div>
				</div>
			</Card>
		</Paper>
	);
}
