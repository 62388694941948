import * as React from 'react';
import {
    makeStyles,
    createStyles,
    Grid,
    withStyles,
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
    TextField,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import ModuleAutoComplete from "../../../components/AutoComplete/ModuleAutoComplete";
import NoteTypeAutoComplete from "../../../components/AutoComplete/NoteTypeAutoComplete";
import { StudentProfile } from "../../../interfaces/student/profile/StudentProfile";
import * as StudentNotesApi from "../../../api/student/notes/StudentNotesApi";
import { INewNotes } from "../../../interfaces/student/notes/INotesInfo";
import { FormContext, useForm } from "react-hook-form";

const ThemeTextField = withStyles((theme: any) =>
    createStyles({
        root: {},
    })
)(TextField);

const useStyles = makeStyles((theme: any) =>
    createStyles({
        cardTitle: {
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            backgroundColor: theme.palette.site.secondary,
            padding: theme.spacing(0)
        },
        headerText: {
            float: 'left',
            padding: theme.spacing(1.5),
        },
        dialogCloseButton: {
            float: "right",
        },
        textField: {
            marginRight: theme.spacing(1),
            width: "100%",
        },
    })
);
type AddNotesPopUpProps = {
    open?: boolean;
    editedNotesId: number | null;
    module: number | null;
    isLoading: boolean;
    setLoading: (loading: boolean) => void;
    handleClose: () => void;
    setSnackBar: any;
};

const AddNotesPopUp = (
    props: Readonly<AddNotesPopUpProps>
) => {
    const { open, handleClose, isLoading } = props;
    const classes = useStyles({});
    const data = useForm<any>({ mode: "onBlur" });
    const { handleSubmit } = data;

    const selectedStudent: StudentProfile = useSelector(
        (state: any) => state.student
    ) as StudentProfile;

    const [model, setModel] = React.useState<INewNotes>({
        notesId: props.editedNotesId,
        leadId: selectedStudent.leadId,
        moduleId: props.module,
        noteText: "",
        noteType: "",
        modDate: new Date(),
        modDateString: "",
    });

    React.useEffect(() => {
        let updatedModel = model;
        (updatedModel as any)["notesId"] = null;
        (updatedModel as any)["moduleId"] = null;
        (updatedModel as any)["noteType"] = null;
        (updatedModel as any)["noteText"] = null;
        setModel({ ...updatedModel });
    }, [selectedStudent.selectedEnrollment]);

    const updateNotes = () => {
        data.triggerValidation().then((validation: any) => {
            if (validation) {
                const leadId = selectedStudent.leadId;
                model.leadId = leadId;
                model.modDate = new Date();
                model.modDateString = (new Date()).toLocaleString();
                if (model.noteText != null && leadId != null && model.moduleId != null && model.notesId == null) {
                    StudentNotesApi.CreateNotes(model).then(
                        (response: any) => {
                            if (response) {
                                props.setSnackBar((props: any) => {
                                    return {
                                        variant: "success",
                                        showSnackBar: true,
                                        messageInfo: response.resultStatusMessage,
                                    };
                                });
                                handleClose();
                            }
                        },
                        (exception: any) => { }
                    );
                }
                else if (model.noteText && leadId != null && model.moduleId != null && model.notesId != null) {
                    StudentNotesApi.UpdateNotes(model).then(
                        (response: any) => {
                            if (response) {
                                props.setSnackBar((props: any) => {
                                    return {
                                        variant: "success",
                                        showSnackBar: true,
                                        messageInfo: response.resultStatusMessage,
                                    };
                                });
                                handleClose();
                            }
                        },
                        (exception: any) => {
                            props.setSnackBar((snackBarProps: any) => {
                                return {
                                    variant: "error",
                                    showSnackBar: true,
                                    messageInfo: exception,
                                };
                            });
                        }
                    );
                }
            }


        });
    }

    React.useEffect(() => {
        if (props.editedNotesId != null && props.editedNotesId != 0) {
            StudentNotesApi.GetNotesById(props.editedNotesId).then(
                (response: any) => {
                    if (response && response.data) {
                        let notes = response.data;
                        let updatedModel = model;
                        (updatedModel as any)["notesId"] = notes.notesId;
                        (updatedModel as any)["leadId"] = notes.leadId;
                        (updatedModel as any)["moduleId"] = notes.moduleId;
                        (updatedModel as any)["noteType"] = notes.noteType;
                        (updatedModel as any)["noteText"] = notes.noteText;
                        setModel({ ...updatedModel });
                    }
                },
                (exception: any) => { }
            );
        }
        else {
            let updatedModel = model;
            (updatedModel as any)["notesId"] = null;
            (updatedModel as any)["moduleId"] = null;
            (updatedModel as any)["noteType"] = null;
            (updatedModel as any)["noteText"] = null;
            setModel({ ...updatedModel });
        }
    }, [props.editedNotesId]);

    const handleFieldOnChange = (
        fieldId: string,
        value: any,
        mapFunction?: Function
    ) => {
        let updatedModel = model;
        let newValue = mapFunction ? mapFunction(value) : value;
        (updatedModel as any)[fieldId] = newValue;
        setModel({ ...updatedModel });
    };

    const beforeClose = () => {
        let updatedModel = model;
        (updatedModel as any)["notesId"] = null;
        (updatedModel as any)["moduleId"] = null;
        (updatedModel as any)["noteType"] = null;
        (updatedModel as any)["noteText"] = null;
        setModel({ ...updatedModel });
        handleClose();
    }

    return (
        <React.Fragment>
            <FormContext {...data}>
                <form onSubmit={handleSubmit(updateNotes)}>
                    <Dialog
                        PaperProps={{ square: true }}
                        fullWidth={true}
                        maxWidth="sm"
                        open={open ?? false}
                        onClose={beforeClose}
                        aria-labelledby="notes-add-dialog-title"
                    >
                        <DialogTitle
                            className={classes.cardTitle}
                            disableTypography
                            id="notes-add-dialog-title"
                        >
                            <Typography className={classes.cardTitle}>
                                <div className={classes.headerText}>Enter a New Note</div>
                                <IconButton
                                    aria-label="close"
                                    onClick={beforeClose}
                                    className={classes.dialogCloseButton}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            {open &&
                                <Grid container direction="column" spacing={2} wrap="nowrap">
                                    <Grid
                                        item
                                        alignContent="flex-start"
                                        alignItems="flex-start"
                                        justify="flex-start"
                                    >
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12}>
                                                <ModuleAutoComplete
                                                    id="moduleId"
                                                    label="Module *"
                                                    name="moduleId"
                                                    disabled={(props.editedNotesId != null && props.editedNotesId != 0) ? true : false}
                                                    useOnlyDocumentsModule={true}
                                                    valueFilter={
                                                        model.moduleId
                                                            ? {
                                                                key: "value",
                                                                values: [model.moduleId],
                                                            }
                                                            : undefined
                                                    }
                                                    filterHandle={(v: any) => {
                                                        handleFieldOnChange("moduleId", v ? v.value : null);
                                                    }}
                                                    error={!!data.errors.moduleId}
                                                    inputRef={data.register({ required: true })}
                                                    helperText={
                                                        data.errors.awardId ? "Module is required." : undefined
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12}>
                                                <NoteTypeAutoComplete
                                                    id="noteType"
                                                    label="Type *"
                                                    name="noteType"
                                                    valueFilter={
                                                        model.noteType
                                                            ? {
                                                                key: "value",
                                                                values: [model.noteType],
                                                            }
                                                            : undefined
                                                    }
                                                    filterHandle={(v: any) => {
                                                        handleFieldOnChange("noteType", v ? v.value : null);
                                                    }}
                                                    error={!!data.errors.noteType}
                                                    inputRef={data.register({ required: true })}
                                                    helperText={
                                                        data.errors.awardId ? "Note type is required." : undefined
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ThemeTextField
                                                id="noteText"
                                                name="noteText"
                                                className={classes.textField}
                                                label="Notes *"
                                                multiline={true}
                                                margin="normal"
                                                value={model.noteText ?? ""}
                                                onChange={(v: any) => {
                                                    handleFieldOnChange(
                                                        "noteText",
                                                        v ? v.target.value : undefined
                                                    );
                                                }}
                                                error={!!data.errors.noteText}
                                                inputRef={data.register({ required: true })}
                                                helperText={
                                                    data.errors.awardId ? "Notes are required." : undefined
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row">
                                        <Grid
                                            item
                                            container
                                            xs={2}
                                            alignContent="flex-start"
                                            alignItems="flex-start"
                                            justify="flex-start"
                                        >
                                            <Button
                                                size='small'
                                                color='primary'
                                                variant='contained'
                                                type='button' onClick={updateNotes}>Save</Button>

                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            xs={2}
                                            alignContent="flex-start"
                                            alignItems="flex-start"
                                            justify="flex-start"
                                        >
                                            <Button
                                                onClick={beforeClose}
                                                size='small'
                                                color="secondary"
                                                variant="contained"
                                                type="button"
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        alignContent='flex-start'
                                        alignItems='flex-start'
                                        justify='flex-start'
                                    >
                                    </Grid>

                                </Grid>
                            }
                        </DialogContent>
                    </Dialog>
                </form>
            </FormContext>
        </React.Fragment>
    );
};

export default AddNotesPopUp;
