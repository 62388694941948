import * as React from "react";
import { useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Grid,
	Button,
	Typography,
} from "@material-ui/core";
import ProgressSaveButton from "../Buttons/ProgressSaveButton";
import { useFormContext } from "react-hook-form";
const ThemeTextField = withStyles((theme: any) =>
	createStyles({
		root: {
			"& label.Mui-focused": {
				color: theme.palette.primary.white,
			},
		},
	})
)(TextField);

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {},
		dialogHeader: {
			backgroundColor: theme.palette.site.secondary,
			"& .MuiTypography-root": {
				fontWeight: theme.typography.fontWeightBold,
				fontSize: "12pt",
			},
		},
		buttonSection: {
			marginBottom: theme.spacing(2)
		},
		saveButton: {
			width: 'auto',
			marginRight : theme.spacing(2)
		},
		typography: {
			whiteSpace: "pre-line"
		},
	})
);

export type ConfirmationModalProps = {
	onConfirmEvent?: any;
	onCancelEvent?: any;
	open: boolean;
	title: string;
	confirmationText: string;
	cancelText?: string;
	confirmationButton: string;
	id?: string;
	maxWidth?: string;
	object?: any;
};

const ConfirmationModal = (props: ConfirmationModalProps) => {
	const classes = useStyles({});
const data = useFormContext();
	const handleConfirm = () => {
		if (props.onConfirmEvent) {
			props.onConfirmEvent();
		}
	};

	const handleClose = () => {
		if (props.onCancelEvent) {
			props.onCancelEvent();
		}
	};
	return (
		<div>
			<Dialog
				open={props.open}
				onClose={handleClose}
				className={classes.root}
				aria-labelledby="form-dialog-title"
				maxWidth="sm"
				fullWidth={true}
				disableBackdropClick
			>
				<DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
					{props.title}
				</DialogTitle>
				<DialogContent>
					<Grid container direction="row" spacing={2}>
						<Grid item xs={12}>
							{}
						</Grid>
					</Grid>
					<Grid container direction="row" spacing={2}>
						<Grid item xs={12}>
							<Typography
								color="textSecondary"
								className={classes.typography}
								variant="body1"
							>
								{props.confirmationText}
							</Typography>
						</Grid>
					</Grid>
				</DialogContent>

				<Grid item xs={12}>
					<DialogActions className={classes.buttonSection}>
						<Grid container direction="row" spacing={1}>
							<Grid item xs={3}></Grid>
							<Grid
								item
								container
								xs={6}
								alignContent="center"
								alignItems="center"
								justify="center"
							>
								<ProgressSaveButton
									text={props.confirmationButton}
									onClick={() => {
										handleConfirm();
									}}
									loading={false}
									buttonClassName={classes.saveButton}
								></ProgressSaveButton>

								<Button
									onClick={handleClose}
									color="secondary"
									variant="contained"
									type="button"
								>
									{props.cancelText ? props.cancelText : "Cancel"}
								</Button>
							</Grid>							
							<Grid item xs={3}></Grid>
						</Grid>
					</DialogActions>
				</Grid>
			</Dialog>
		</div>
	);
};

export default ConfirmationModal;
