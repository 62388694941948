import * as React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Paper, StepButton } from "@material-ui/core";
import * as afaApi from "../../../api/setup/afaIntegration/afaIntegrationApi";
import { Alert } from "@material-ui/lab";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		root: {
			width: "100%",
		},
		backButton: {
			marginRight: theme.spacing(1),
		},
		button: {
			marginRight: theme.spacing(1),
		},
		instructions: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
		paperRoot: {
			padding: theme.spacing(2),
			width: "100%",
			minHeight: "100%",
			height: "100%",
			overflowX: "hidden",
			overflowY: "auto",
			flexDirection: "column",
			backgroundColor: theme.palette.paper.background,
			marginTop: theme.spacing(1),
		},
		completed: {
			display: "inline-block",
		},
	})
);

function getStepContent(stepIndex: number) {
	switch (stepIndex) {
		case 0:
			return (
				"This step will retrieve all demographics from AFA and synchronize the afa student ID on the corresponding lead records." +
				"New lead records may be created as a result of this process, and any exceptions will be inserted into the afa sync exception table."
			);
		case 1:
			return "This step will send the program version data to AFA for those Title IV programs that are marked as active in Advantage.";
		case 2:
			return "This step will send the enrollments to AFA for those students who have been successfully linked with AFA.";
		case 3:
			return "Sync Attendance/Credits for students. Attendance or credits will be sent depending on whether the program is clock hour or not.";
		case 4:
			return "This step will sync awards with AFA by assigning all corresponding FA Identifiers to the currently existing awards in Advantage.";
		case 5:
			return (
				"This step will attempt to assign a sequence number to existing disbursements in advantage based on the data received from AFA." +
				" Note: In most cases, this step can be skipped as the appropriate sequence number has been set during the conversion process."
			);
		default:
			return "Unknown stepIndex";
	}
}

export default function HorizontalNonLinearAlternativeLabelStepper() {
	const classes = useStyles();
	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);
	const [activeStep, setActiveStep] = React.useState(0);
	const [completed, setCompleted] = React.useState(new Set<number>());
	const [skipped, setSkipped] = React.useState(new Set<number>());
	const [steps, setSteps] = React.useState<any>([
		{
			title: "Sync Demographics",
			optional: false,
			action: afaApi.syncDemographics,
			status: undefined,
		},
		{
			title: "Sync Program Versions",
			optional: false,
			action: afaApi.syncPrograms,
			status: undefined,
		},
		{
			title: "Sync Enrollments",
			optional: false,
			action: afaApi.syncEnrollments,
			status: undefined,
		},
		{
			title: "Sync Attendance",
			optional: false,
			action: afaApi.syncAttendance,
			status: undefined,
		},
		{
			title: "Sync Awards",
			optional: false,
			action: afaApi.syncAwards,
			status: undefined,
		},
		{
			title: "Sync Disbursements",
			optional: true,
			action: afaApi.syncDisbursements,
			status: undefined,
		},
	]);

	const totalSteps = () => {
		return steps.length;
	};

	const isStepOptional = (step: number) => {
		return steps[step].optional;
	};

	const handleSkip = () => {
		if (!isStepOptional(activeStep)) {
			// You probably want to guard against something like this
			// it should never occur unless someone's actively trying to break something.
			throw new Error("You can't skip a step that isn't optional.");
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped((prevSkipped) => {
			const newSkipped = new Set(prevSkipped.values());
			newSkipped.add(activeStep);
			return newSkipped;
		});
	};

	const skippedSteps = () => {
		return skipped.size;
	};

	const completedSteps = () => {
		return completed.size;
	};

	const allStepsCompleted = () => {
		return completedSteps() === totalSteps() - skippedSteps();
	};

	const isLastStep = () => {
		return activeStep === totalSteps() - 1;
	};

	const handleNext = () => {
		const newActiveStep =
			isLastStep() && !allStepsCompleted()
				? // It's the last step, but not all steps have been completed
				  // find the first step that has been completed
				  steps.findIndex((step: any, i: number) => !completed.has(i))
				: activeStep + 1;

		setActiveStep(newActiveStep);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStep = (step: number) => () => {
		setActiveStep(step);
	};

	const handleComplete = () => {
		const newCompleted = new Set(completed);
		newCompleted.add(activeStep);
		setCompleted(newCompleted);

		/**
		 * Sigh... it would be much nicer to replace the following if conditional with
		 * `if (!this.allStepsComplete())` however state is not set when we do this,
		 * thus we have to resort to not being very DRY.
		 */
		if (completed.size !== totalSteps() - skippedSteps()) {
			handleNext();
		}
	};

	const handleReset = () => {
		setActiveStep(0);
		setCompleted(new Set<number>());
		setSkipped(new Set<number>());
	};

	const isStepSkipped = (step: number) => {
		return skipped.has(step);
	};

	function isStepComplete(step: number) {
		return completed.has(step);
	}
	const stepSynced = (res: any) => {
		let status: string;
		if (!!res.data) {
			status = "success";
		} else status = "error";

		setSteps((steps: any) =>
			steps.map((step: any, i: number) =>
				i === activeStep ? { ...step, status: status } : step
			)
		);
	};
	const stepHasStatus = () => {
		return !!steps[activeStep].status;
	};
	const getSeverity = () => {
		return steps[activeStep].status;
	};
	const getMessageContent = () => {
		switch (steps[activeStep].status) {
			case "success":
				return "The sync has completed successfully.";
			case "error":
				return "An error occured during the sync.";
			default:
				return "The sync has completed successfully.";
		}
	};

	return (
		<Paper className={classes.paperRoot} square={true}>
			<Stepper alternativeLabel nonLinear activeStep={activeStep}>
				{steps.map((step: any, index: number) => {
					const stepProps: { completed?: boolean } = {};
					const buttonProps: { optional?: React.ReactNode } = {};
					if (isStepOptional(index)) {
						buttonProps.optional = (
							<Typography variant="caption">Optional</Typography>
						);
					}
					if (isStepSkipped(index)) {
						stepProps.completed = false;
					}
					return (
						<Step key={step.title} {...stepProps}>
							<StepButton
								onClick={handleStep(index)}
								completed={isStepComplete(index)}
								{...buttonProps}
							>
								{step.title}
							</StepButton>
						</Step>
					);
				})}
			</Stepper>
			<div>
				{allStepsCompleted() ? (
					<div>
						<Typography className={classes.instructions}>
							All steps completed - you&apos;re finished
						</Typography>
						<Button onClick={handleReset}>Reset</Button>
					</div>
				) : (
					<div>
						<Typography className={classes.instructions}>
							{getStepContent(activeStep)}
						</Typography>

						{stepHasStatus() && (
							<div>
								<Alert
									className={classes.instructions}
									severity={getSeverity()}
								>
									{getMessageContent()}
								</Alert>
							</div>
						)}

						<div>
							<Button
								disabled={activeStep === 0}
								onClick={handleBack}
								className={classes.button}
								variant="contained"
								color="secondary"
							>
								Back
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={handleNext}
								className={classes.button}
							>
								Next
							</Button>

							{activeStep !== steps.length &&
								(completed.has(activeStep) ? (
									<Typography variant="caption" className={classes.completed}>
										Step {activeStep + 1} already completed
									</Typography>
								) : (
									<Button
										variant="contained"
										color="primary"
										onClick={() =>
											steps[activeStep]
												.action(userSelectedCampus)
												.then(stepSynced)
										}
									>
										Sync
									</Button>
								))}
						</div>
					</div>
				)}
			</div>
		</Paper>
	);
}
