import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Typography, Grid } from "@material-ui/core";
import Warning from "@material-ui/icons/ReportProblemOutlined";
import CheckCircle from "@material-ui/icons/CheckCircleOutlined";
import Cancel from "@material-ui/icons/CancelOutlined";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import yellow from "@material-ui/core/colors/yellow";
import { ISAP } from "../../../interfaces/student/quickInfo/IProgramPaymentPeriodCard";

const useStyles = makeStyles((theme: any) =>
	createStyles({
		fieldTextStyle: {
			fontSize: 12,
			color: theme.palette.text.primary
		},
		selectedRow: {
			backgroundColor: theme.palette.primary.main
		},
		selectedTextStyle: {
			color: theme.palette.white
		},
		arrowIcon: {
			color: theme.palette.primary,
			width: 15
		},
		warning: {
			color: yellow[500],
			width: 15
		},
		passed: {
			color: green[500],
			width: 15
		},
		fail: {
			color: red[500],
			width: 15
		},
		italicize: {
			fontStyle: "italic"
		},
		iconStatus: {
			marginRight: "-3px"
		},
		checkPointColumn: {
			marginRight: "-7px"
		},
		AttendanceColumn: {
			marginRight: "10px"
		}
	})
);

type IAcademicSAPChartProps = {
	checkpointsData: Array<ISAP> | undefined;
	className?: "";
	isCreditHour: boolean;
};

const AcademicSAPChart = (props: IAcademicSAPChartProps) => {
	const { className, checkpointsData} = props;

	const classes = useStyles({});

	useEffect(() => {
		let mounted = true;

		const fetchData = () => {};

		fetchData();

		return () => {
			mounted = false;
		};
	}, []);

	function RenderText(text: any, italicize?: boolean) {
		return (
			<Typography
				className={clsx(classes.fieldTextStyle, {
					[classes.italicize]: italicize
				})}
			>
				{text}
			</Typography>
		);
	}

	function RenderStatusIcon(status?: number) {
		return (
			<React.Fragment>
				{status === 1 && <Warning className={classes.warning}></Warning>}
				{status === 0 && <CheckCircle className={classes.passed}></CheckCircle>}
				{status === 2 && <Cancel className={classes.fail}></Cancel>}
			</React.Fragment>
		);
	}

	return (
		<Grid container direction="column" justify="center" alignItems="center">
			<Grid container direction="row" justify="flex-start" alignItems="center">
				<Grid item xs={1}></Grid>
				<Grid item xs={4}>
					{RenderText("Checkpoint", true)}
				</Grid>
				<Grid item xs={1}></Grid>
				<Grid item xs={2}>
					{RenderText(props.isCreditHour ? "Pace" : "Attend", true)}
				</Grid>
				<Grid item xs={1}></Grid>
				<Grid item xs={3}>
					{RenderText(props.isCreditHour ? "GPA" :"Grade", true)}
				</Grid>
			</Grid>
			{props.checkpointsData?.map(function(rowItem: ISAP, index: any) {
				return (
					<Grid
						container
						key={index}
						direction="row"
						justify="flex-start"
						alignItems="center"
					>
						<Grid item xs={1} className={classes.iconStatus}>
							{RenderStatusIcon(rowItem?.checkPointStatus)}
						</Grid>
						<Grid item xs={5} className={classes.checkPointColumn}>
							{RenderText(rowItem.checkPointAsString)}
						</Grid>
						<Grid item xs={1} className={classes.iconStatus}>
							{RenderStatusIcon(rowItem.attendanceStatus)}
						</Grid>
						<Grid item xs={2} className={classes.AttendanceColumn}>
							{RenderText(rowItem.attendancePercentage)}
						</Grid>
						<Grid item xs={1} className={classes.iconStatus}>
							{RenderStatusIcon(rowItem.gradeStatus)}
						</Grid>
						<Grid item xs={2}>
							{RenderText(props.isCreditHour ? (rowItem?.grade ?? 0).toFixed(2) : rowItem.gradePercentage)}
						</Grid>
					</Grid>
				);
			})}
		</Grid>
	);
};

export default AcademicSAPChart;
