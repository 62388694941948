import React from "react";
import {ToBlob} from '../../../utils/base64Helper';
import {FileUtil} from '../../../utils/file';
import {toasterActions} from '../../../actions/toasterActions';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import * as api from "../../../api/reports/progressReport/reportApi";
import {pdfPreviewActions} from '../../../actions/pdfPreviewActions';
import ReportMethod from '../../../constants/reportMethod';

const GenerateReport = (props: any) => {

  const handleOnClick  = () => {
    if (props.reportConfig.RequestUrl && props.reportConfig.RequestBody) {
      api.getReport(props.reportConfig.RequestUrl,props.reportConfig.RequestBody)
        .then(
          (response: any) => {
            
            if(response.base64Data){
              if(props.reportMethod){
                let url = FileUtil.GetObjectUrl(ToBlob(response.base64Data,'application/pdf'));

                if(props.reportMethod === ReportMethod.Download){
                  FileUtil.DownloadFromObjectUrl(url,props.reportConfig.FileName);
                }
                else if(props.reportMethod === ReportMethod.Preview){
                  props.showPreview(url,props.reportConfig.FileName);
                }
                else if(props.reportMethod === ReportMethod.Print){
                  FileUtil.Print(url,props.reportConfig.FileName,()=>{
                    props.showPreview(url,props.reportConfig.FileName);
                  });
                }
                else{
                  props.showToaster("Invalid Report Method");
                }
              }
              else{
                props.showToaster("Please specify report method");
              }
            }
            else{
              props.showToaster(response.resultStatus);
            }

            props.success();
          },
          (exception: any) => {
            props.error();
          }
        );
    }
    else{
        props.showToaster("Invalid request");
        props.invalidCriteria();
    }

    props.clicked();
  };

  return (
    <div onClick = { handleOnClick  } >
      {props.children}
    </div>
  );
};

GenerateReport.prototype = {
  showToaster: PropTypes.func,
  showPreview: PropTypes.func
}

let actionCreators = {
  showToaster: toasterActions.showToaster,
  showPreview: pdfPreviewActions.showPreview
};

export default connect(null,actionCreators)(GenerateReport);
