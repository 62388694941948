import {
  Dialog,
  makeStyles,
  createStyles,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import React from "react";
import AccessManager from "../../components/security/AccessManager";
import { security } from "../../constants/Security/securityConstants";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { Report } from "../../interfaces/reports/Report";
import CampusAttendanceReport from "../../pages/reports/CampusAttendance/CampusAttendanceReport";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    dialogHeader: {
      backgroundColor: theme.palette.site.secondary,
      "& .MuiTypography-root": {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: "12pt",
      },
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(0),
      top: theme.spacing(0),
    },
    root: {
      paddingTop: theme.spacing(3),
      minHeight: "100%",
      height: "100%",
      maxWidth: "100%",
      margin: "auto",
    },
    paperRoot: {
      padding: theme.spacing(3, 2),
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
    },
  })
);

const CampusAttendanceReportPopup = (props: any) => {
  const classes = useStyles({});

  const handleClose = () => {
    props.setOpen(false);
  };

  const [reportData, setReportData] = React.useState<Report>({
    creationMethod: "",
    description: "Campus Attendance Report.",
    favorited: false,
    id: 884,
    isUnLock: true,
    modules: [],
    name: "Campus Attendance Report",
    recentlyUsed: true,
    reportClass: "",
    resourceURL: "",
    tags: [],
    typeId: 0,
  });

  return (
    <AccessManager
      allowedPermissions={[
        security.permissions.dashboard.viewCampusAttendanceWidget,
      ]}
      renderNoAccess={() => null}
    >
      <div>
        <Dialog
          open={props.open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth={"lg"}
          fullWidth={true}
          disableBackdropClick
        >
          <DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
            <Typography>Campus Attendance Report</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div className={classes.root}>
              <Paper className={classes.paperRoot}>
                <CampusAttendanceReport
                  report={reportData}
                ></CampusAttendanceReport>
              </Paper>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </AccessManager>
  );
};

export default CampusAttendanceReportPopup;
