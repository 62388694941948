import * as React from "react";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Paper, Checkbox, InputAdornment, Button, CircularProgress, IconButton } from "@material-ui/core";
import { TreeItem, TreeView } from "@material-ui/lab";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SearchIcon from '@material-ui/icons/Search';
import { IAdhocRerportColumsObject } from "../../../interfaces/reports/adhoc/IAdHocReportOptions";
import { IAhHocReportFieldGroup } from "../../../interfaces/reports/adhoc/IAhHocReportFieldGroup";
import { IAdhocReportField } from "../../../interfaces/reports/adhoc/IAdhocReportField";
import { actions as adHocDetailsActions, selectors as adHocDetailsSelectors } from '../../../store/reports/adhocReportDetails';
import { useActions } from "../../../store/utils";
import { useSelector } from "react-redux";
import InputField from "../../../components/_Layout/Inputs/InputField";

const useStyles = makeStyles((theme: any) =>
    createStyles({
        keywords: {
            padding: 0,
            display: 'flex',
            alignItems: 'center'
        },
        searchIcon: {
            color: theme.palette.icon,
            marginRight: theme.spacing(2)
        },
        root: {
            height: "100%",
            overflowY: "auto",
            position: "relative",
            padding: theme.spacing(1)
        },
        mainText: {
            color: theme.palette.text.primary
        },
        secondaryText: {
            color: theme.palette.text.secondary
        },
        content: {
            padding: theme.spacing(1),
            overflow: "auto",
            height: "100%",
        },
        navigation: {
            overflowX: "hidden",
            marginTop: theme.spacing(1),
            height: "100%",
            maxHeight: "78%"
        },
        hidden: {
            visibility: "hidden"
        },
        hiddenHeight: {
            height: 75
        },
        loadingPapar: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        loadingContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
        },
        alignCenter: {
            textAlign: 'center'
        }

    })
);


const useTreeViewStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            padding: theme.spacing(1),
            color: theme.palette.text.secondary,
            '& .MuiTreeItem-label': {
                color: theme.palette.text.secondary,
                padding: theme.spacing(0.25),
                fontSize: theme.spacing(1.5),
            },
            '& .MuiSvgIcon-root': {
                width: '1em',
                height: '1em'
            }
        },
        nodeCheckBox: {
            padding: 0
        },
        rootNode: {
            '& > .MuiTreeItem-content > .MuiTreeItem-label': {
                fontWeight: theme.typography.fontWeightBold,
                color: theme.palette.text.primary,
                fontSize: theme.spacing(1.5)
            },
        }
    })
);

type IAdhocFieldViewerProps = {
};



const AdhocFieldViewer = (props: IAdhocFieldViewerProps) => {
    const actions = useActions({
        toggleField: adHocDetailsActions.toggleField,
        filterColumns: adHocDetailsActions.filterColumns,
        toggleAllFields: adHocDetailsActions.toggleAllFields
    }, []);
    const treeData: IAdhocRerportColumsObject = useSelector(adHocDetailsSelectors.reportColumns);
    const classes = useStyles({});
    const treeClasses = useTreeViewStyles({});
    const loading = useSelector(adHocDetailsSelectors.loadingDetails);
    const appliedFilter = useSelector(adHocDetailsSelectors.appliedFilter);
    const renderTree = (nodes: IAhHocReportFieldGroup | IAdhocReportField, parent: string = "") => {
        if (nodes.hasOwnProperty('fields') && Array.isArray((nodes as IAhHocReportFieldGroup).fields)) {
            const includedCount = (nodes as IAhHocReportFieldGroup).fields.filter(m => m.included).length;
            return (
                <TreeItem key={nodes.id} nodeId={nodes.id} label={`${nodes.name}${includedCount > 0 && ` (${includedCount})` || ''}`} className={clsx({ [treeClasses.rootNode]: true })}>
                    {(nodes as IAhHocReportFieldGroup).fields.map((node) => renderTree(node, nodes.id))}
                </TreeItem>
            )
        }
        return (
            <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name} onClick={() => actions.toggleField(parent, nodes.id)} icon={(<Checkbox className={treeClasses.nodeCheckBox} color="primary" size="small" checked={!!(nodes as IAdhocReportField).included} />)}>
            </TreeItem>);
    };


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        actions.filterColumns(event.target.value);
    };

    React.useEffect(() => {
        actions.filterColumns('');
    }, []);

    return (
        <React.Fragment>
            <Paper
                className={clsx(
                    classes.root,
                    loading ? classes.loadingPapar : undefined
                )}
                elevation={2}
                square>
                {(!loading && <div className={clsx({ [classes.content]: true })}>
                    <div className={classes.keywords}>
                        <InputField
                            value={appliedFilter || ''}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                endAdornment: (!!appliedFilter && (<InputAdornment position="end"> <IconButton onClick={() => actions.filterColumns('')}> <ClearIcon /></IconButton></InputAdornment>)) || undefined
                            }}
                            onChange={handleInputChange}
                            placeholder="Search Fields"
                        />
                    </div>
                    <div className={classes.keywords}>
                        <Button color="primary" size="small" onClick={() => actions.toggleAllFields(false)}>
                            Clear
                        </Button>
                        <Button color="primary" size="small" onClick={() => actions.toggleAllFields(true)}>
                            Select All
                        </Button>
                    </div>
                    <div>
                        <TreeView
                            className={treeClasses.root}
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpanded={Object.values(treeData).map((t) => t.id)}
                            defaultExpandIcon={<ChevronRightIcon />}
                            disableSelection
                        >
                            {Object.values(treeData).map((t) => renderTree(t))}
                        </TreeView>
                    </div>
                </div>)
                    || <div className={classes.loadingPapar}>
                        <div className={classes.alignCenter}><CircularProgress color="primary" /></div></div>}
            </Paper>
        </React.Fragment>
    );
};

export default AdhocFieldViewer;
