import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect, useSelector } from "react-redux";
import { sessionActions as actions } from "../../actions/sessionActions";
import CircularProgress from "@material-ui/core/CircularProgress";


function UserTermsAndConditions(props:any) {
    
  const [open, setOpen] = React.useState(false);
  const [runProgress, setRunProgress] = React.useState(false);


  const session = useSelector((state: any) => state.session);
  const account = useSelector((state: any) => state.account);
  React.useEffect(() => {
       setOpen(account.isPendingUserTerms)
  }, [account.isPendingUserTerms]); 

  React.useEffect(() => {
    setRunProgress(account.approvalRunning);
  
    if(!account.approvalRunning)
    setOpen(false);

}, [account.approvalRunning]); 
    
const handleCancel= () => {
    handleClose();
    props.cancelUserTerms();
}

  const handleClickOpen = () => {
    var source = session.user_backup;
    props.changeUserTerms(source.email, source.userTermsOfUse.version);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='md'
      >
        <DialogTitle id="alert-dialog-title"><span style={{marginLeft:'38%', fontSize:'21px'}}>Terms and Conditions Updated</span></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{textAlign:'center'}}>
          <span>The Terms and Conditions for using Advantage have been updated.</span>
          <span style={{textAlign:'center'}}>&nbsp;Click on the <a href="https://support.fameinc.com/hc/en-us/articles/115001146071" rel="noopener noreferrer" target="_blank">Advantage Software and Services Agreement</a> to read the full details of the Terms and Conditions, and then click "<strong>I AGREE</strong>" button if you agree with the terms of use and wish to proceed to use Advantage. Otherwise, click on the "<strong>CANCEL</strong>" button to go back to the login page.</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        {
           runProgress && <CircularProgress style={{marginRight:'4%'}}></CircularProgress>
        }
        {
            !runProgress && <div>
          <Button onClick={handleCancel} color="primary" style={{color:'#0000008a'}}>
            Cancel
          </Button>
          <Button onClick={handleClickOpen} color="primary" autoFocus>
            I Agree
          </Button>
            </div>
        }
        </DialogActions>
      </Dialog>
    </div>
  );
}

function mapState(state: any) {
    return state;
  }
  
  const actionCreators = {
    changeUserTerms: actions.changeUserTerms,
    cancelUserTerms: actions.cancelUserTerms
  };

  const connectedLoginPage = connect(mapState, actionCreators)(UserTermsAndConditions);
 export { connectedLoginPage as UserTermsAndConditions };