export const ProgramVersionApiAutoComplete = {
  config: {
    acId: "PrgVersion",
    cacheTime: 5,
    route: "/AcademicRecords/ProgramVersions/GetProgramVersionsByCampus",
    requestType: "GET",
    body: null,
    hasUrlParams: true,
    useCache: false,
    storeInCache: false,
    isCampusSpecific: true,
    isUserSpecific: false,
    loadOnRender: true,
    defaultSelectedFirstItem: false,
    optionsMappedFx: (data: Array<any>) => {
      return data;
    },
  },
};
