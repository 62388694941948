import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Popover,
    CardHeader,
    CardActions,
    Divider,
    Button,
    colors
} from '@material-ui/core';

import EmptyList from './components/EmptyList/EmptyList'
import NotificationList from './components/NotificationList/NotificationList';
import { useDispatch, useSelector } from 'react-redux';
import { userConstants } from '../../../constants/actions.constants';

import { useState, useEffect } from 'react';
const useStyles = makeStyles(() => ({
    root: {
        width: 350,
        maxWidth: '100%'
    },
    actions: {
        backgroundColor: colors.grey[50],
        justifyContent: 'center'
    }
}));

const NotificationsPopover = (props: any) => {
    const { notifications, anchorEl, persistentNotifications, ...rest } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    
   
    const dismissAllNotifications = () => {
        if (notifications.length > 0)
            dispatch({ type: userConstants.SESSION_NOTIFICATION_REMOVED, notifications: notifications })

    }

    return (
        <Popover
            {...rest}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
        >
            <div className={classes.root}>
                <CardHeader title="Notifications" />
                <Divider />
                {notifications.length > 0 || persistentNotifications?.length > 0? (
                    <NotificationList notifications={notifications} persistentNotifications={persistentNotifications} />
                ) : (
                        <EmptyList />
                    )}
                <Divider />
                <CardActions className={classes.actions}>
                    <Button
                        size="small"
                        onClick={() => { dismissAllNotifications() }}
                    >
                        Dismiss all
          </Button>
                </CardActions>
            </div>
        </Popover>
    );
};

NotificationsPopover.propTypes = {
    anchorEl: PropTypes.any,
    className: PropTypes.string,
    notifications: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    persistentNotifications: PropTypes.array.isRequired
};

export default NotificationsPopover;
