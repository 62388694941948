import React, { useState } from "react";
import clsx from "clsx";
import { connect, useSelector } from "react-redux";
import { Grid, TextField, Box, Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import "../../index.css";
import { accountActions as actions } from "../../actions/accountActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Typography } from '@material-ui/core';
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import SchoolLogo from './SchoolLogo';
import SelectedSchoolBackground from './SchoolBackground';
import themeTenantPicker from "../Common/ThemeTenantPicker";


const ForgotPasswordStep2 = (props: any) => {
  const history = useHistory();
  const isTabletMode = useMediaQuery("(max-width:1280px)");
  const themeName = themeTenantPicker();
  const themeBackground = SelectedSchoolBackground(themeName);
  const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
     background:  (themeName ==="default") ? theme.palette.login.background : "url(" + themeBackground + ") no-repeat center center fixed",
      backgroundSize: "cover",
      WebkitBackgroundSize: "cover",
      MozBackgroundSize: "cover",
      OBackgroundSize: "cover",
      height: "100% !important",
      minHeight: "100% !important",
      overflow: "hidden !important"
    },
    gridContainer: {
      zIndex: 999999,
      height: "100%"
    },
    gridContainerTabletMode: {
      zIndex: 999999,
      height: "100%"
    },
    loginFormContainer: {
      minWidth: "600px !important",
      maxWidth: "600px !important"
    },
    loginContainer: {
      background: theme.palette.login.background,
      border: "1px solid",
      borderColor: theme.palette.border
    },
    logoContainer: {
      textAlign: "center",
      paddingTop: theme.spacing(4)
    },
    mainLogoContainer: {
      textAlign: "center",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    column: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.primary,
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1)
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      background: "#f4f0f0",
      borderRadius: theme.spacing(0.5),
      float: "left",
      width: "50%",
      marginTop: theme.spacing(0.5),
      padding: theme.spacing(0.5)
    },
    button: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.site.primary,
      color: theme.palette.white,
      "&:hover": {
        backgroundColor: theme.palette.primary.main
      }
    },
    errorText: {
      margin: 0,
      padding: 0,
      color: theme.palette.error.dark,
    },
    successText: {
      margin: 0,
      padding: 0,
      color: theme.palette.error.dark
    }
    ,
    TypoLeftLabel:{
       fontWeight:"bold",
       marginTop: theme.spacing(2)
    },
    TypoRightLabel:{
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(2),
        float:"left",
        display:"inline",
    },
    AnswerBox:{
        float: "left",
        width: "50%",
        marginTop: theme.spacing(-1)
    },
    initialFloat : {
          float : 'initial'
    },
    centerText : {
      textAlign : 'center'
    },
    marginBottom : {
      marginBottom : theme.spacing(2)
    }
  })
);
  const classes = useStyles({});
  const [answer, setAnswer] = useState("");
  const account = useSelector((state: any) => state.account);
  const isVerifying: boolean = account.isVerifyingEmail;
  const [callMessage, setCallMessage] = useState(null);

  React.useEffect(() => {
    setCallMessage(account.callMessage);
	  }, [account.callMessage]); 


  if(!account.emailVerified)
  return <Redirect to="/login" />
  const handleClickEvent = () => {
    props.runVerifyAnswer(account.emailAddress,answer);
  };
 
  return (
    <div className={clsx(classes.root)}>
      <Grid
        container
        className={clsx(
          { [classes.gridContainer]: !isTabletMode },
          { [classes.gridContainerTabletMode]: isTabletMode }
        )}
        direction="row"
        alignItems="center"
        justify="center"
      >
        <Grid item xs></Grid>
        <Grid item xs={6} className={classes.loginFormContainer}>
          <div className={classes.loginContainer}>
          <div className={classes.mainLogoContainer}>
              <SchoolLogo schoolName={themeName} logoType="Normal"></SchoolLogo>
            </div>
            <div className={classes.column}>
            <Typography className={`${classes.centerText} ${classes.marginBottom}`}>
              Answer the following security question and a password reset link will be sent to your associated email address.
              </Typography>
              
              <Typography className={`${classes.TypoLeftLabel} ${classes.initialFloat}`}>Email Address</Typography>
              
              <Typography className={`${classes.TypoRightLabel} ${classes.initialFloat} ${classes.marginBottom}`}>{account.emailAddress}</Typography>
              
              <Typography className={`${classes.TypoLeftLabel} ${classes.initialFloat}`}>Security Question</Typography>
              
              <Typography className={`${classes.TypoRightLabel} ${classes.initialFloat}`}>{account.securityQuestion}</Typography>
              
              <Typography className={`${classes.TypoLeftLabel} ${classes.initialFloat}`}>Answer</Typography>
              
              <TextField
                required
                id="username"
                className={`${classes.textField} ${classes.initialFloat}`}
                placeholder="Type answer here"
                margin="normal"
                variant="standard"
                value={answer}
                onChange={(e) => {
                  setCallMessage(null);
                  setAnswer(e.target.value);
                }}
                onKeyDown = {
                  (e) => {
                    if (e.key === 'Enter') 
                    handleClickEvent()
                     }
                }
                autoFocus={true}
              />
              
               { true && (
                 <p className={`${classes.errorText} ${classes.initialFloat}`}>
                 {callMessage}
               </p>
              )}
            </div>
          <div className={classes.column}>
              {!isVerifying && (
                <Button className={classes.button}  onClick={handleClickEvent}>
                  SUBMIT
                </Button>
              )}
              {!isVerifying && (
                 <Button className={classes.button} variant="outlined"
                 onClick={() => history.push('/login')
                }>
                   Cancel
                </Button>
              )}
                {isVerifying && <CircularProgress></CircularProgress>}
            </div>
          </div>
        </Grid>
        <Grid item xs></Grid>
      </Grid>
    </div>
  );
};

function mapState(state: any) {
  return state;
}

const actionCreators = {
  runVerifyAnswer: actions.runVerifyAnswer,
};

const connectedLoginPage = connect(mapState, actionCreators)(ForgotPasswordStep2);
export { connectedLoginPage as ForgotPasswordQuestion };
