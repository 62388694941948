import * as React from 'react';
import { useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ApiAutoComplete from '../../components/_Layout/ApiAutoComplete/ApiAutoComplete';
import config from '../../constants/setup/reports/CourseGradeBook';



const useStyles = makeStyles((_theme: any) =>
	createStyles({
		root: {},
		divWidth: {
			width: '95%',
		},
	})
);

const CourseGradeBookAutoComplete = (props: any) => {
	const classes = useStyles({});
	const inputRef: any = useRef(null);

	const { error, helperText } = props;

	const statusOnChange = (e: any) => {
		if (props.filterHandle) {
			props.filterHandle(e);
		}
	};

	return (
		<div className={clsx(classes.divWidth)}>
			<ApiAutoComplete
				config={config}
				reqParams={{
					courseId: props.courseId ? props.courseId.toString() : null,
				}}
				includeAllValue={props.includeAllValue ? props.includeAllValue : false}
				defaultOptionText={props.defaultOptionText ? props.defaultOptionText : undefined}
				classes={{
					option: 'text-black',
				}}
				label={props.label ? props.label : 'Gradebook'}
				onChange={(e: any) => {
					statusOnChange(e);
				}}
				disabled={props.disabled}
				multiple={props.multiple ? props.multiple : false}
				valueFilter={props.valueFilter ? props.valueFilter : undefined}
				error={error}
				helperText={helperText}
				inputRef={props.inputRef ? props.inputRef : inputRef}
				inputName={props.name}
				chipSize={props.chipSize}
				maxTags={props.maxTags}
			></ApiAutoComplete>
		</div>
	);
};
export default CourseGradeBookAutoComplete;
