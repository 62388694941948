import { createTheme } from "@material-ui/core";

import typography from "./typography";
import overrides from "./overrides";

import palette from "./mainPalette";
import mainPalette from "./mainPalette";
import paulMitchellPalette from "./paulMitchellPalette";
import tricociPalette from "./tricociPalette";
import avedaPalette from "./avedaPalette";
import arcLabsPalette from "./arcLabsPalette";
import mpiPalette from "./mpiPalette";
import angelesPalette from "./angelesPalette";
import trainingPalette from "./trainingPalette";
import phagansPalette from "./phagansPalette";
import celebrityPalette from "./celebrityPalette";
import fabSchoolPalette from "./fabSchoolPalette";
import davidPressleyPalette from "./davidPressleyPalette";
import ritzPalette from "./ritzPalette";
import chromeInstitutePalette from './chromeInstitutePalette';

const themes = {
	mainTheme: createTheme({
		palette,
		typography,
		overrides,
	}),
	paulMitchellTheme: createTheme({
		palette: paulMitchellPalette,
		typography,
		overrides,
	}),

	tricociTheme: createTheme({
		palette: tricociPalette,
		typography,
		overrides,
	}),

	avedaTheme: createTheme({
		palette: avedaPalette,
		typography,
		overrides,
	}),

	arcLabsTheme: createTheme({
		palette: arcLabsPalette,
		typography,
		overrides,
	}),

	mpiTheme: createTheme({
		palette: mpiPalette,
		typography,
		overrides,
	}),

	angelesTheme: createTheme({
		palette: angelesPalette,
		typography,
		overrides,
	}),

	collegeTheme: createTheme({
		palette: mainPalette,
		typography,
		overrides,
	}),

	trainingTheme: createTheme({
		palette: trainingPalette,
		typography,
		overrides,
	}),

	fabTheme: createTheme({
		palette: fabSchoolPalette,
		typography,
		overrides,
	}),

	phagansTheme: createTheme({
		palette: phagansPalette,
		typography,
		overrides,
	}),

	celebrityTheme: createTheme({
		palette: celebrityPalette,
		typography,
		overrides,
	}),

	davidPressleyTheme: createTheme({
		palette: davidPressleyPalette,
		typography,
		overrides,
	}),

	ritzTheme: createTheme({
		palette: ritzPalette,
		typography,
		overrides,
	}),
	chromeInstituteTheme: createTheme({
		palette: chromeInstitutePalette,
		typography,
		overrides,
	}),
};

export default themes;
