import React, { useEffect, useRef, SyntheticEvent } from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Input, Card, CircularProgress } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
//import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
//import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import StudentItemResult from "./SearchResults/StudentItemResult/StudentItemResult";
import { StudentProfile } from "../../../interfaces/student/profile/StudentProfile";
import { AdvanceSearchModel } from "../../../interfaces/student/search/AdvanceSearchModel";
import * as studentSearchApi from "../../../api/search/studentSearchApi";
import { StudentSearchModel } from "../../../interfaces/student/search/SearchModel";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import useOutsideClick from "../../../utils/useOutsideClick";
import { withRouter } from "react-router-dom";
import CustomSnackbar from "../../notifications/CustomSnackbar";
import { Enrollment } from "../../../interfaces/student/academics/Enrollment";

const useStyles = makeStyles((theme: any) => ({
	searchBox: {
		alignItems: "center",
		borderRadius: "0.4em",
		width: "100%",
		marginRight: theme.spacing(2),
		//marginLeft: theme.spacing(2),
	},

	searchBoxActive: {
		display: "flex",
		alignItems: "center",
		marginRight: theme.spacing(2),
		width: "50%",
		"& input::placeholder": {},
		"& $searchIcon": {
			color: theme.palette.black,
		},
		position: "absolute",
		//position: "relative",
		overflowY: "visible",
		overflowX: "visible",
	},

	searchIcon: {
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
		color: theme.palette.black,
	},
	searchInput: {
		color: theme.palette.black,
		opacity: 1,
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: 13,
		paddingRight: "0px !important",
		paddingLeft: "1px !important",
		minWidth: '100px !important',
		flexGrow: 1,
		width: "88%",
		// color: theme.palette.black,
		// fontSize: 14,
		// fontWeight: theme.typography.fontWeightMedium,
		// padding: 4,
		"& input::placeholder": {
			color: theme.palette.black,
			fontWeight: theme.typography.fontWeightLight,
		},
		"&$searchInputFocused": {
			color: theme.palette.text.primary,
			width: "88%",
			"& input::placeholder": {
				color: theme.palette.text.primary,
				fontWeight: theme.typography.fontWeightMedium,
			},
		},
	},
	advanceSearchInput: {
		color: theme.palette.primary.main,
		"& input::placeholder": {
			color: theme.palette.primary.main,
		},
	},
	searchInputFocused: {},
	searchButton: {
		marginLeft: theme.spacing(3),
	},
	searchPaper: {
		backgroundColor: 'transparent',
		boxShadow: "none",
		transition: "width 4s",
		zIndex: 11
		//position: "absolute",
	},
	searchPaperShown: {
		width: "100%",
		position: "absolute",
		backgroundColor: theme.palette.white,
		border: "1px solid #ccc",
		minHeight: "300px",
		top: 0,
		zIndex: 12,
	},
	searchContentWrapper: {
		display: "none",
		color: theme.palette.primary.main,
	},
	searchContentWrapperShown: {
		top: "0px",
		width: "100%",
		display: "block",
	},
	searchResultsContent: {
		paddingLeft: "1em",
		paddingRight: "1em",
		paddingBottom: "1em",
		color: "rgba(0, 0, 0, 0.8)",
	},
	searchResultList: {
		padding: "0.4em",
		marginTop: "10pt",
		maxHeight: "500px",
		overflowY: "auto",
		overflowX: "hidden",
		display: "block",
		visibility: "visible",
	},
	searchResultListHidden: {
		display: "none",
		visibility: "hidden",
	},
	advanceSearchResult: {
		padding: "0.4em",
		display: "block",
		visibility: "visible",
	},
	advanceSearchResultHidden: {
		display: "none",
		visibility: "hidden",
	},
	recentStudents: {
		color: "rgba(0, 0, 0, 0.8)",
		fontSize: "10pt",
	},
	advanceSearchExpand: {
		float: "right",
		color: "rgba(255,255,255, 0.8)",
	},
	advanceSearchExpanded: {
		float: "right",
		color: theme.palette.black,
	},
	loader: {
		marginTop: theme.spacing(10),
	},
	searchTextbox: {
		borderBottom: "1px solid #000",
	},
}));

const SearchBar = (props: any) => {
	const { onSearch, className, staticContext, ...rest } = props;
	const dispatch = useDispatch();
	const containerRef = useRef();
	const classes = useStyles({});
	const [isSearchBarActive, setSearchBarState] = useState<boolean>(false);
	const [mruHasLoaded, setMruHasLoaded] = useState<boolean>(false);
	const [showSnackBar, setShowSnackBar] = useState<boolean>(false);

	const [isAdvanceSearchActive, setIsAdvanceSearchState] = useState<boolean>(
		false
	);
	const [isSearchBoxContentActive, setIsSearchBoxContentActiveState] = useState<
    boolean
  >(false);
	const [studentProfiles, setStudentProfiles] = React.useState<
    Array<StudentProfile>
  >();
	const [mruProfiles, setMruProfiles] = React.useState<Array<StudentProfile>>();
	const [mruIsShowing, setMruIsShowing] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [searchText, setSearchText] = React.useState<string>("");
	const defaultInputSearchPlaceHolder: string =
    props.placeholder ?? "Student Search";
	const loggedInUser = useSelector((state: any) => state.session.user);

	const [inputSearchPlaceHolder, setInputSearchPlaceHolderState] = useState<
    string
  >(defaultInputSearchPlaceHolder);

	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);

	const onChangeSearchText = (e: any) => {
		setSearchText(e);
	};

	const populateFromResult = (data?: StudentProfile[]): StudentProfile[] => {
		const newData: StudentProfile[] = [];
		if (data) {
			data.map((profile: StudentProfile) => profile.enrollments && (profile.enrollments.length > 1 ? profile.enrollments.map((enroll: Enrollment) => newData.push({ ...profile, enrollments: [enroll], currentStatus: enroll.status, startDate: enroll.startDate, lastDateOfAttendance: enroll.lastDateOfAttendance, studentNumber: enroll.studentNumber, currentProgramVersion: enroll.programVersionDescription })) : newData.push(profile)));
		}
		return newData;
	}

	const loadMRU = () => {
		setLoading(true);
		if (mruHasLoaded) {
			setStudentProfiles(mruProfiles);
			setMruIsShowing(true);
			setMruHasLoaded(true);
			setLoading(false);
		} else {
			studentSearchApi.getMRU().then(
				(rv: any) => {
					setStudentProfiles(populateFromResult(rv.result));
					setMruProfiles(populateFromResult(rv.result));
					setMruIsShowing(true);
					setMruHasLoaded(true);
					setLoading(false);
				},
				(e: any) => {
					setLoading(false);
				}
			);
		}
	};

	const onSearchFocus = (event: any) => {
		if (!isSearchBarActive) {
			setSearchBarState((isSearchBarActive) => !isSearchBarActive);
			setInputSearchPlaceHolderState(defaultInputSearchPlaceHolder);
			setIsSearchBoxContentActiveState(true);
			setIsAdvanceSearchState(false);
		}
	};

	const hideStudentSearch = () => {
		if (isSearchBarActive) {
			setSearchBarState((isSearchBarActive) => !isSearchBarActive);
			setIsSearchBoxContentActiveState(false);
		}
	};

	const onSelectStudentProfile = (data: StudentProfile) => {
		if (data) {
			hideStudentSearch();
			let studentName = "";
			if (data.studentName != undefined) {
				studentName = data.studentName;
			}
			//update MRU
			setMruProfiles((mruProfiles: any) => {
				if (!mruProfiles) {
					mruProfiles = [];
				}
				mruProfiles = mruProfiles.filter((e: any) => {
					return e.studentId !== data.studentId;
				});
				mruProfiles.unshift(data);
				if (mruProfiles.length > 5) {
					mruProfiles.pop();
				}
				return mruProfiles;
			});
			setSearchText("");
			//onSelect Call Back Option
			if (props.onSelect && data.enrollments && data.enrollments.length > 0) {
				props.onSelect(data.enrollments[0], studentName);
			}
		}
	};

	const onAdvanceSearchClick = (event: any) => {
		if (isSearchBarActive) {
			hideStudentSearch();
		}

		if (isAdvanceSearchActive) {
			setSearchBarState(false);
			setIsSearchBoxContentActiveState(false);
			setInputSearchPlaceHolderState(defaultInputSearchPlaceHolder);
		} else {
			setIsSearchBoxContentActiveState(true);
			setInputSearchPlaceHolderState("Advanced Search");
		}
		setIsAdvanceSearchState((isAdvanceSearchActive) => !isAdvanceSearchActive);
	};

	const onSearchClicked = (
		event: any,
		advanceSearchModel: AdvanceSearchModel
	) => {
		setSearchBarState(false);
		setIsSearchBoxContentActiveState(false);
		setIsAdvanceSearchState(false);
		setInputSearchPlaceHolderState(defaultInputSearchPlaceHolder);
	};

	useOutsideClick(containerRef, () => {
		if (isSearchBarActive) {
			hideStudentSearch();
		}
	});

	useEffect(() => {
		const timeOutId = setTimeout(() => fetchSearchResults(), 500);
		return () => clearTimeout(timeOutId);
	}, [searchText]);

	const fetchSearchResults = () => {
		if (searchText && searchText.length > 2 && userSelectedCampus) {
			setLoading(true);
			studentSearchApi
				.studentProfileSearch({
					Filter: searchText,
					ShowAll: true,
					CampusId: userSelectedCampus,
				} as StudentSearchModel)
				.then(
					(rv) => {
						setStudentProfiles(populateFromResult(rv.result));
						setMruIsShowing(false);
						setLoading(false);
					},
					(e) => {
						console.log(e);
						setLoading(false);
					}
				);
		} else if (!mruIsShowing) {
			loadMRU();
		}
	}

	useEffect(() => {
		if (mruIsShowing && mruHasLoaded) {
			loadMRU();
		}
	}, [mruProfiles]);

	return (
		<div
			ref={containerRef}
			id="searchBox"
			name="searchBox"
			{...rest}
			className={clsx(
				{ [classes.searchBox]: !isSearchBarActive },
				{
					[classes.searchBoxActive]: isSearchBarActive,
				}
			)}
		>
			<Card
				className={clsx(
					{ [classes.searchPaper]: !isSearchBarActive },
					{
						[classes.searchPaperShown]: isSearchBarActive,
					}
				)}
				elevation={2}
			>
				<Input
					startAdornment={
						<SearchIcon fontSize="small" className={classes.searchIcon} />
					}
					className={props.report ? props.searchTextbox : ""}
					classes={{
						root: clsx(
							{ [classes.searchInput]: !isAdvanceSearchActive },
							{ [classes.advanceSearchInput]: isAdvanceSearchActive }
						),
						focused: classes.searchInputFocused,
					}}
					onChange={(e: any) => {
						onChangeSearchText(e.target.value);
					}}
					value={searchText}
					disableUnderline
					placeholder={defaultInputSearchPlaceHolder}
					onFocus={onSearchFocus}
					required= {props.required}
				/>

				{/* <IconButton
          className={clsx(
            { [classes.advanceSearchExpand]: !isSearchBarActive },
            {
              [classes.advanceSearchExpanded]:
                isSearchBarActive || isAdvanceSearchActive,
            }
          )}
          size="small"
          onClick={onAdvanceSearchClick}
        >
          {!isAdvanceSearchActive && <ArrowDropDownIcon />}
          {isAdvanceSearchActive && <ArrowDropUpIcon />}
        </IconButton> */}

				<div
					id="searchContentWrapper"
					className={clsx(
						{ [classes.searchContentWrapper]: !isSearchBoxContentActive },
						{
							[classes.searchContentWrapperShown]: isSearchBoxContentActive,
						}
					)}
				>
					<div className={classes.searchResultsContent}>
						{loading && (
							<Grid container justify="center" alignItems="center">
								<CircularProgress className={classes.loader}></CircularProgress>
							</Grid>
						)}

						{!isAdvanceSearchActive && studentProfiles && !loading && (
							<div className={classes.searchResultList}>
								{studentProfiles.map(function (item: any, index: any) {
									let disabled = false;
									if (props.creditHoursOnly == true && item.enrollments.length > 0 && item.enrollments[0].isCreditHour == false)
									{
										disabled = true;
										return false;
									}
									return (
										<StudentItemResult
											key={"student-item-result-" + index}
											studentProfile={item}
											disabled = {disabled}
											onSelect={(e: any, data: any) => {
												if(!disabled)
												{
													onSelectStudentProfile(data);
												}												
											}}
										/>
									);
								})}
							</div>
						)}
						{/* 
            {isAdvanceSearchActive && (
              <div className={classes.advanceSearchResult}>
                <AdvanceSearch
                  onSearchClicked={onSearchClicked}
                ></AdvanceSearch>
              </div>
            )} */}
					</div>
				</div>
			</Card>
			<CustomSnackbar
				variant="warning"
				message="Campus changed after selecting student from different campus."
				open={showSnackBar}
				onClose={(event?: SyntheticEvent, reason?: string) => {
					setShowSnackBar(false);
				}}
			></CustomSnackbar>
		</div>
	);
};

SearchBar.propTypes = {
	className: PropTypes.string,
	onSearch: PropTypes.func,
};

export default withRouter(SearchBar);
