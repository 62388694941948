import * as actionTypes from "../../constants/actions.constants";
import { Action } from "redux";
const initialState: any = {
  // FOR FORGOT PASSWORD
  isVerifyingEmail: null,
  emailVerified: null,

  securityQuestion: null,
  answerVerified: null,

  verifyErrors: null,
  emailAddress: null,
  callMessage: null,
  isPendingUserTerms: false,
  isSupportUser: false,
  approvalRunning: false,
  moveToLogin: false,
  refreshUserPilot: false,
  isMultiTenantUser: false,
  tenantPickerOptions: null,
};

const accountReducer = (state = initialState, action: Action<any>) => {
  switch (action.type) {
    case actionTypes.forgotPasswordConstants.ACCOUNT_FORGOT_PASSWORD_REQUEST: {
      return {
        ...state,
        isVerifyingEmail: true,
        verifyErrors: false,
        emailAddress: (action as any).userEmail,
      };
    }

    case actionTypes.forgotPasswordConstants.ACCOUNT_VERIFY_EMAIL_SUCCESS: {
      return {
        ...state,
        isVerifyingEmail: false,
        emailVerified: true,
        securityQuestion: (action as any).serverResponse.securityQuestion,
        verifyErrors: false,
        callMessage: null,
      };
    }
    case actionTypes.forgotPasswordConstants.ACCOUNT_VERIFY_EMAIL_ERROR: {
      return {
        ...state,
        isVerifyingEmail: false,
        emailVerified: false,
        verifyErrors: true,
        securityQuestion: null,
        callMessage: (action as any).serverResponse.message,
      };
    }
    case actionTypes.forgotPasswordConstants
      .ACCOUNT_FORGOT_PASSWORD_REQUEST_QUESTION: {
      return {
        ...state,
        isVerifyingEmail: true,
        verifyErrors: false,
      };
    }
    case actionTypes.forgotPasswordConstants.ACCOUNT_VERIFY_QUESTION_SUCCESS: {
      return {
        ...state,
        isVerifyingEmail: false,
        answerVerified: true,
        verifyErrors: false,
        callMessage: (action as any).serverResponse.message,
      };
    }
    case actionTypes.forgotPasswordConstants.ACCOUNT_VERIFY_QUESTION_ERROR: {
      return {
        ...state,
        isVerifyingEmail: false,
        answerVerified: false,
        verifyErrors: true,
        callMessage: (action as any).serverResponse.message,
      };
    }
    case actionTypes.forgotPasswordConstants.ACCOUNT_NEW_PASSWORD_REQUEST: {
      return {
        ...state,
        isVerifyingEmail: true,
        verifyErrors: false,
        callMessage: "",
      };
    }
    case actionTypes.forgotPasswordConstants.ACCOUNT_NEW_PASSWORD_SUCCESS: {
      var message = {
        ...state,
        isVerifyingEmail: false,
        answerVerified: true,
        verifyErrors: false,
        callMessage: (action as any).serverResponse.message,
      };
      return message;
    }
    case actionTypes.forgotPasswordConstants.ACCOUNT_NEW_PASSWORD_ERROR: {
      return {
        ...state,
        isVerifyingEmail: false,
        answerVerified: false,
        verifyErrors: true,
        callMessage: (action as any).serverResponse.message,
      };
    }
    case actionTypes.userTermsConstants.NEED_APPROVAL: {
      return {
        ...state,
        isPendingUserTerms: true,
        approvalRunning: false,
        moveToLogin: false,
      };
    }
    case actionTypes.userTermsConstants.APPROVAL_DONE: {
      return {
        ...state,
        isPendingUserTerms: false,
        approvalRunning: false,
        moveToLogin: true,
      };
    }
    case actionTypes.userTermsConstants.APPROVAL_REQUEST: {
      return {
        ...state,
        approvalRunning: true,
      };
    }
    case actionTypes.userTermsConstants.APPROVAL_CANCEL: {
      return {
        ...state,
        isPendingUserTerms: false,
      };
    }
    case actionTypes.forgotPasswordConstants.ACCOUNT_SESSION_RESET: {
      return {
        ...state,
        isVerifyingEmail: false,
        emailVerified: false,
        securityQuestion: null,
        answerVerified: false,
        verifyErrors: null,
        emailAddress: null,
        callMessage: null,
        isPendingUserTerms: false,
        approvalRunning: false,
        isSupportUser: false,
        isMultiTenantUser: false,
        tenantPickerOptions: null,
      };
    }
    case actionTypes.supportUserConstants.SUPPORT_USER_FOUND: {
      return {
        ...state,
        isSupportUser: true,
        moveToLogin: false,
      };
    }
    case actionTypes.supportUserConstants.SUPPORT_USER_CANCEL: {
      return {
        ...state,
        isSupportUser: false,
      };
    }
    case actionTypes.multitenantUserConstants.MULTI_TENANT_USER_FOUND: {
      return {
        ...state,
        isMultiTenantUser: true,
        tenantPickerOptions: (action as any).eventBody,
      };
    }
    case actionTypes.multitenantUserConstants.MULTI_TENANT_USER_CANCEL: {
      return {
        ...state,
        isMultiTenantUser: false,
        tenantPickerOptions: null,
      };
    }
    case actionTypes.userPilotConstants.USER_PILOT_REFRESH_REQUIRED: {
      return {
        ...state,
        refreshUserPilot: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default accountReducer;
