/* eslint-disable eqeqeq */
import * as React from "react";
import {
  makeStyles,
  createStyles,
  Grid,
  Paper,
  Table,
  withStyles,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  IconButton,
  CircularProgress,
  Button,
  Card,
  CardContent,
  TextField,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Tab,
  Tabs,
  Box,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import AddCircle from "@material-ui/icons/AddCircle";
import SortIcon from "@material-ui/icons/Sort";
import InputField from "../../../../components/_Layout/Inputs/InputField";
import { EmptyGuid } from "../../../../utils/constants";
import { CustomSnackBarProps } from "../../../../interfaces/common/CustomSnackBarProps";
import { useSelector } from "react-redux";
import ICreditCourseDetails from "../../../../interfaces/setup/academics/ProgramDefinition/ICreditCourseDetails";
import CreditCourseGradeBook from "./CreditCourseGradeBook";
import { getAllCreditCourses } from "../../../../api/setup/academics/creditCoursesApi";
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";
import CustomCardTitle from "../../../../interfaces/common/card/CustomCardTitle";
import { FieldError, useForm, FormContext } from "react-hook-form";
import { InputType } from "../../../../constants/uiConstants/inputConstants";
import StatusAutoComplete from "../../../../components/AutoComplete/StatusAutoComplete";
import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  UpsertCourse,
  GetCourseDetailByCourseId,
  GetCourseDetailByDetailId,
} from "../../../../api/setup/academics/creditCoursesApi";
import * as creditCoursesApi from "../../../../api/setup/academics/creditCoursesApi";
import * as PropTypes from "prop-types";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: "0px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ThemeTextField = withStyles(() =>
  createStyles({
    root: {
      "& .MuiFormLabel-root": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "100%",
      },
    },
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      fontWeight: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(0),
    },
    headerText: {
      float: "left",
      padding: theme.spacing(1.5),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%",
    },
    saveButton: {
      width: 90,
    },
    tableContainer: {
      maxHeight: "200px",
    },
    gridMargin: {
      marginTop: theme.spacing(3),
    },
    table: {
      width: "100%",
    },
    iconColor: {
      color: theme.palette.black,
    },
    searchField: {
      "&::placeholder": {
        color: theme.palette.black,
      },
    },
    selectionCheckBox: {
      padding: theme.spacing(0),
    },
    preLoaderCell: {
      textAlign: "center",
      padding: theme.spacing(1),
    },
    dialogCloseButton: {
      float: "right",
    },
    courseSelectionCell: {
      color: theme.palette.paperSummarySchedulerTitle,
      cursor: "pointer",
      fontWeight: 500,
    },
    courseSelectedCell: {
      backgroundColor: theme.palette.site.secondary,
      cursor: "pointer",
      fontWeight: 500,
    },
    fileInput: {
      display: "none",
    },
    switch: {
      marginLeft: "0px",
      paddingTop: theme.spacing(2),
    },
    alignCenter: {
      textAlign: "center",
    },
    datePickerField: {
      width: "100%",
      background: "white",
      borderRadius: theme.spacing(0.5),
      "& .MuiInputBase-root": {
        "& input": {
          width: "100%",
        },
      },
    },
    cardMain: {
      /* maxWidth: "1050px", */
      margin: "auto",
      marginTop: theme.spacing(2),
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
    },
    tabStyle: {
      backgroundColor: theme.palette.primary.constrastText,
      color: theme.palette.primary.headerText,
    },
    sortDesc: {
      transform: "rotate(180deg)",
    },
  })
);

const CoursesSetup = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [currentCourseId, setCurrentCourseId] = React.useState<string | null>(
    null
  );
  const [courses, setCourses] = React.useState<ICreditCourseDetails[]>([]);
  const [courseDetailList, setCourseDetailList] = React.useState<
    ICreditCourseDetails[]
  >([]);
  const classes = useStyles({});
  const [searchText, setSearchText] = React.useState<string>("");
  const [showAll, setShowAll] = React.useState<boolean>(false);
  const [sort, setSort] = React.useState<"asc" | "desc">("desc");
  const [lastDetailDate, setLastDetailDate] = React.useState<Date | null>(null);

  var startDate = new Date();
  var endDate = new Date();
  endDate.setFullYear(endDate.getFullYear() + 100);

  var baseModel: ICreditCourseDetails = {
    courseId: EmptyGuid,
    name: "",
    descrip: "",
    code: "",
    courseCost: null,
    faCredit: null,
    credit: null,
    activeStartDate: startDate,
    activeEndDate: endDate,
  };
  const [model, setModel] = React.useState<ICreditCourseDetails>(baseModel);
  const [modelEdit, setEditModel] = React.useState<ICreditCourseDetails | null>(
    null
  );
  const [repeatedExamPolicySelected, selectRepeatedExamPolicy] =
    React.useState<string>();
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );
  const [confirmationDialogue, setConfirmationDialogue] = React.useState<{
    onOk?: () => void;
    open: boolean;
    message?: string;
    onCancel?: () => void;
  }>({
    open: false,
    message: "Are you sure, do you want to save these changes? ",
  });

  const [value, setValue] = React.useState(0);
  const handleChange = (_index: any, newValue: any) => {
    setValue(newValue);
  };
  React.useEffect(() => {}, [value]);

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );

  const toDisplay =
    searchText && searchText.length > 0
      ? courses.filter(
          (course) =>
            course.courseId === searchText ||
            (course.name ?? "")
              .toLocaleLowerCase()
              .includes(searchText.toLocaleLowerCase())
        )
      : courses;

  const loadCourses = async (showAll: boolean): Promise<void> => {
    try {
      setLoading(true);
      creditCoursesApi.getAllCreditCourses(showAll).then(
        (courses: any) => {
          setCourses(courses);
        },
        (exception: any) => {
          setSnackBarProps(() => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo: exception,
            };
          });
        }
      );
    } catch (_e) {
      setCourses([]);
    } finally {
      setTimeout(() => setLoading(false), 250);
    }
  };

  React.useEffect(() => {
    loadCourses(showAll);
  }, [showAll]);

  const data = useForm<ICreditCourseDetails>({
    mode: "onBlur",
    defaultValues: model,
  });

  const handleFieldChange = (
    fieldId: keyof ICreditCourseDetails,
    value: any
  ) => {
    let updatedModel = model;
    (updatedModel as any)[fieldId] = value;
    setModel({ ...updatedModel });
    data.setValue(fieldId, value);
    data.triggerValidation(fieldId);
  };

  const { handleSubmit } = data;

  const saveCourseDetails = async (confirm?: boolean) => {
    if (!loading) {
      setLoading(true);
      if (
        !confirm &&
        model.courseId !== EmptyGuid &&
        modelEdit &&
        !compareDates(model.activeStartDate, modelEdit?.activeStartDate)
      ) {
        setConfirmationDialogue({
          open: true,
          message:
            "Since you updated effective date, New version of the course will be created.",
          onOk: () => {
            courseDetailId = null;
            saveCourseDetails(true);
          },
          onCancel: () => setLoading(false),
        });
        return;
      }

      let courseDetailId = null;
      if (
        modelEdit == null ||
        compareDates(model.activeStartDate, modelEdit?.activeStartDate)
      ) {
        courseDetailId = model.courseDetailId ?? null;
      }

      try {
        var endDate = new Date(model.activeStartDate);
        endDate.setFullYear(endDate.getFullYear() + 100);
        model.activeEndDate = endDate;

        creditCoursesApi.UpsertCourse(model, courseDetailId).then(
          (newDetails: any) => {
            var detailList: ICreditCourseDetails[] = newDetails.courseDetails;
            setCourseDetailList(detailList);
            if (detailList.length > 0) {
              setCurrentCourseId(detailList[0].courseId);
            }

            setModel(baseModel);
            setLastDetailDate(null);
            setEditModel(null);

            loadCourses(showAll);

            setSnackBarProps({
              variant: "success",
              showSnackBar: true,
              messageInfo: newDetails.message,
            });
          },
          (exception: any) => {
            setSnackBarProps(() => {
              return {
                variant: "error",
                showSnackBar: true,
                messageInfo: exception,
              };
            });
          }
        );
      } catch (err) {
        setSnackBarProps({
          variant: "error",
          showSnackBar: true,
          messageInfo: (err as Error).message,
        });
      }
      setLoading(false);
    }
  };

  const getCourseDetails = async (courseId: string) => {
    if (!loading && courseId) {
      setLoading(true);
      if (courseId !== EmptyGuid) {
        try {
          creditCoursesApi.GetCourseDetailByCourseId(courseId).then(
            (newDetails: any) => {
              setCourseDetailList(newDetails);
              setCurrentCourseId(courseId);

              setLastDetailDate(newDetails[0].activeStartDate);
              setModel({ ...newDetails[0] });
              setEditModel({ ...newDetails[0] });
            },
            (exception: any) => {
              setSnackBarProps(() => {
                return {
                  variant: "error",
                  showSnackBar: true,
                  messageInfo: exception,
                };
              });
            }
          );
        } catch (err) {
          setSnackBarProps({
            variant: "error",
            showSnackBar: true,
            messageInfo: (err as Error).message,
          });
        }
      }
      setLoading(false);
    }
  };

  const getDetails = async (courseDetailId: string) => {
    if (!loading && courseDetailId) {
      setLoading(true);
      if (courseDetailId !== EmptyGuid) {
        try {
          creditCoursesApi.GetCourseDetailByDetailId(courseDetailId).then(
            (newDetails: any) => {
              setLastDetailDate(newDetails.activeStartDate);
              setModel(newDetails);
              setEditModel(newDetails);
            },
            (exception: any) => {
              setSnackBarProps(() => {
                return {
                  variant: "error",
                  showSnackBar: true,
                  messageInfo: exception,
                };
              });
            }
          );
        } catch (err) {
          setSnackBarProps({
            variant: "error",
            showSnackBar: true,
            messageInfo: (err as Error).message,
          });
        }
      }
      setLoading(false);
    }
  };

  const compareDates = (date1: Date, date2: Date) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    // Extract date components
    const day1 = d1.getDate();
    const month1 = d1.getMonth();
    const year1 = d1.getFullYear();
    const day2 = d2.getDate();
    const month2 = d2.getMonth();
    const year2 = d2.getFullYear();
    // Compare date components
    return day1 === day2 && month1 === month2 && year1 === year2;
  };

  const cancel = () => {
    setModel(baseModel);
    setEditModel(null);
    setCurrentCourseId(null);
    setLastDetailDate(null);
    data.reset(baseModel);
    setCourseDetailList([]);
  };

  const handleAdd = () => {
    setModel({ ...model, name: searchText });
    setEditModel(null);
    setCurrentCourseId(null);
    data.setValue("name", searchText);
    data.triggerValidation("name");
    setSearchText("");
    setCourseDetailList([]);
  };

  return (
    <React.Fragment>
      <CustomSnackbar
        variant={snackBarProps.variant}
        message={snackBarProps.messageInfo}
        open={snackBarProps.showSnackBar}
        onClose={() => {
          setSnackBarProps((props: any) => {
            return { ...props, showSnackBar: false };
          });
        }}
      ></CustomSnackbar>
      <Card square={true} elevation={12} className={classes.cardMain}>
        <CardContent>
          <Grid container direction="column" spacing={0} wrap="nowrap">
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={3} md={3}>
                <Card square={true} elevation={2}>
                  <CustomCardTitle title="Course Search"></CustomCardTitle>
                  <CardContent>
                    <InputField
                      value={searchText ?? ""}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSearchText(e.target.value)
                      }
                      inputProps={{ className: classes.searchField }}
                      InputProps={{
                        startAdornment: (
                          <SearchIcon className={classes.iconColor} />
                        ),
                        endAdornment:
                          searchText &&
                          (toDisplay.length > 0 ? (
                            <IconButton onClick={() => setSearchText("")}>
                              <CloseIcon className={classes.iconColor} />
                            </IconButton>
                          ) : (
                            <IconButton onClick={() => handleAdd()}>
                              <AddCircle className={classes.iconColor} />
                            </IconButton>
                          )),
                      }}
                      placeholder={"Search Courses"}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showAll}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setShowAll(e.target.checked);
                          }}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Show All"
                    />
                    <IconButton
                      onClick={() => {
                        const newSort = sort === "asc" ? "desc" : "asc";
                        setSort(newSort);
                      }}
                    >
                      <SortIcon
                        className={(sort === "asc" && classes.sortDesc) || ""}
                      />
                    </IconButton>
                    <TableContainer
                      component={Paper}
                      square
                      className={classes.tableContainer}
                    >
                      <Table
                        stickyHeader
                        size="small"
                        className={classes.table}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Course</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loading ? (
                            <TableRow>
                              <TableCell className={classes.preLoaderCell}>
                                <CircularProgress />
                              </TableCell>
                            </TableRow>
                          ) : (
                            toDisplay
                              .slice()
                              .sort((a, b) => {
                                const aValue =
                                  a["name" as keyof ICreditCourseDetails];
                                const bValue =
                                  b["name" as keyof ICreditCourseDetails];

                                if (
                                  typeof aValue === "string" &&
                                  typeof bValue === "string"
                                ) {
                                  if (sort === "asc") {
                                    return aValue.localeCompare(bValue);
                                  } else {
                                    return bValue.localeCompare(aValue);
                                  }
                                } else if (
                                  typeof aValue === "boolean" &&
                                  typeof bValue === "boolean"
                                ) {
                                  return aValue === bValue
                                    ? 0
                                    : aValue
                                    ? -1
                                    : 1;
                                }

                                return 0;
                              })
                              .map((program, index) => (
                                <TableRow key={`row-${index}`}>
                                  <TableCell
                                    key={`courseName-${index}`}
                                    className={
                                      program.courseId == currentCourseId
                                        ? classes.courseSelectedCell
                                        : classes.courseSelectionCell
                                    }
                                    onClick={(e: any) =>
                                      getCourseDetails(program.courseId)
                                    }
                                  >
                                    {program.name}
                                  </TableCell>
                                </TableRow>
                              ))
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={9} md={9}>
                <Grid
                  item
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <Card square={true} elevation={2}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      className={classes.tabStyle}
                      indicatorColor="primary"
                    >
                      <Tab label={"Course Details"} {...a11yProps(0)} />
                      <Tab
                        label={"Grade Books"}
                        {...a11yProps(1)}
                        disabled={currentCourseId == null}
                      />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                      <CardContent>
                        <Grid
                          container
                          direction="column"
                          spacing={8}
                          wrap="nowrap"
                        >
                          <Grid
                            item
                            alignContent="flex-start"
                            alignItems="flex-start"
                            justify="flex-start"
                          >
                            <FormContext {...data}>
                              <form
                                onSubmit={handleSubmit(() =>
                                  saveCourseDetails(false)
                                )}
                              >
                                {(!loading && (
                                  <React.Fragment>
                                    <Grid container direction="row" spacing={2}>
                                      <Grid item xs={12} sm={6} md={6}>
                                        <InputField
                                          type={InputType.TEXT}
                                          disabled={model.courseId != EmptyGuid}
                                          key="name"
                                          id="name"
                                          label="Course *"
                                          name="name"
                                          value={model.name ? model.name : ""}
                                          onChange={(
                                            e: React.FocusEvent<HTMLInputElement>
                                          ) => {
                                            handleFieldChange(
                                              "name",
                                              e.target.value
                                            );
                                          }}
                                          error={!!data.errors.name}
                                          inputRef={data.register({
                                            required: "Name is required.",
                                          })}
                                          helperText={
                                            data.errors.name
                                              ? (data.errors.name as FieldError)
                                                  .message
                                              : undefined
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={6}>
                                        <InputField
                                          type={InputType.TEXT}
                                          key="descrip"
                                          id="descrip"
                                          label="Description *"
                                          name="descrip"
                                          value={model.descrip ?? ""}
                                          onChange={(
                                            e: React.FocusEvent<HTMLInputElement>
                                          ) => {
                                            handleFieldChange(
                                              "descrip",
                                              e.target.value
                                            );
                                          }}
                                          error={!!data.errors.descrip}
                                          inputRef={data.register({
                                            required:
                                              "Description is required.",
                                          })}
                                          helperText={
                                            data.errors.descrip
                                              ? (
                                                  data.errors
                                                    .descrip as FieldError
                                                ).message
                                              : undefined
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={2}>
                                      <Grid item xs={12} sm={6} md={6}>
                                        <StatusAutoComplete
                                          id="statusId"
                                          name="statusId"
                                          label="Status *"
                                          valueFilter={
                                            model.statusId
                                              ? {
                                                  key: "value",
                                                  values: [model.statusId],
                                                }
                                              : undefined
                                          }
                                          filterHandle={(v: any) => {
                                            handleFieldChange(
                                              "statusId",
                                              v ? v.value : null
                                            );
                                          }}
                                          error={!!data.errors.statusId}
                                          inputRef={data.register({
                                            required: "Status is required.",
                                          })}
                                          helperText={
                                            data.errors.statusId
                                              ? (
                                                  data.errors
                                                    .statusId as FieldError
                                                ).message
                                              : undefined
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={6}>
                                        <InputField
                                          type={InputType.TEXT}
                                          disabled={
                                            model.courseId != EmptyGuid &&
                                            model.code != ""
                                          }
                                          key="code"
                                          id="code"
                                          label="Code *"
                                          name="code"
                                          value={model.code ?? ""}
                                          onChange={(
                                            e: React.FocusEvent<HTMLInputElement>
                                          ) => {
                                            handleFieldChange(
                                              "code",
                                              e.target.value
                                            );
                                          }}
                                          error={!!data.errors.code}
                                          inputRef={data.register({
                                            required: "Code is required.",
                                          })}
                                          helperText={
                                            data.errors.code
                                              ? (data.errors.code as FieldError)
                                                  .message
                                              : undefined
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={2}>
                                      <Grid item xs={12} sm={6} md={6}>
                                        <InputField
                                          type={InputType.NUMBER}
                                          key="credit"
                                          id="credit"
                                          label="Credit(s) *"
                                          name="credit"
                                          value={model.credit ?? ""}
                                          onChange={(
                                            e: React.FocusEvent<HTMLInputElement>
                                          ) => {
                                            handleFieldChange(
                                              "credit",
                                              e.target.value
                                            );
                                          }}
                                          error={!!data.errors.credit}
                                          inputRef={data.register({
                                            required: "Credit(s) are required.",
                                            validate: {
                                              max: () =>
                                                !model.credit ||
                                                model.credit < 100
                                                  ? true
                                                  : "Enter two digit number.",
                                              min: () =>
                                                !model.credit ||
                                                model.credit >= 1
                                                  ? true
                                                  : "The credit(s) should be greater then 1.",
                                            },
                                          })}
                                          helperText={
                                            data.errors.credit
                                              ? (
                                                  data.errors
                                                    .credit as FieldError
                                                ).message
                                              : undefined
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={6}>
                                        <InputField
                                          type={InputType.NUMBER}
                                          key="faCredit"
                                          id="faCredit"
                                          label="FA Credit(s)"
                                          name="faCredit"
                                          value={model.faCredit ?? ""}
                                          decimal
                                          inputRef={data.register({
                                            required: false,
                                            validate: {
                                              max: () =>
                                                !model.faCredit ||
                                                model.faCredit < 100
                                                  ? true
                                                  : "Enter two digit number.",
                                              min: () =>
                                                !model.faCredit ||
                                                model.faCredit >= 1
                                                  ? true
                                                  : "The FaCredit(s) should be greater then 1.",
                                            },
                                          })}
                                          error={!!data.errors.faCredit}
                                          helperText={
                                            data.errors.faCredit
                                              ? (
                                                  data.errors
                                                    .faCredit as FieldError
                                                ).message
                                              : undefined
                                          }
                                          onChange={(
                                            e: React.FocusEvent<HTMLInputElement>
                                          ) => {
                                            const numValue = parseInt(e.target.value,10);
                                            if(!e.target.value||numValue===0){
                                              e.target.value="";
                                            }
                                            handleFieldChange(
                                              "faCredit",
                                              e.target.value
                                            );
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={2}>
                                      <Grid item xs={12} sm={6} md={6}>
                                        <InputField
                                          type={InputType.NUMBER}
                                          key="courseCost"
                                          id="courseCost"
                                          label="Course Cost"
                                          name="courseCost"
                                          value={model.courseCost ?? ""}
                                          decimal
                                          inputRef={data.register({
                                            required: false,
                                            validate: {
                                              max: () =>
                                                !model.courseCost ||
                                                model.courseCost < 100000
                                                  ? true
                                                  : "The course cost should be less then 100,000.",
                                              min: () =>
                                                !model.courseCost ||
                                                model.courseCost >= 1
                                                  ? true
                                                  : "The course cost should be greater then 1.",
                                            },
                                          })}
                                          error={!!data.errors.courseCost}
                                          helperText={
                                            data.errors.courseCost
                                              ? (
                                                  data.errors
                                                    .courseCost as FieldError
                                                ).message
                                              : undefined
                                          }
                                          onChange={(
                                            e: React.FocusEvent<HTMLInputElement>
                                          ) => {
                                            handleFieldChange(
                                              "courseCost",
                                              e.target.value == "0"
                                                ? null
                                                : e.target.value
                                            );
                                          }}
                                        />
                                      </Grid>
                                      {/* </Grid> */}
                                      {/* <Grid container direction="row" spacing={2}> */}
                                      <Grid item xs={12} sm={6} md={6}>
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="activeStartDate"
                                            name="activeStartDate"
                                            label="Effective Start Date *"
                                            value={
                                              model.activeStartDate
                                                ? model.activeStartDate
                                                : null
                                            }
                                            onChange={(e: any, value: any) =>
                                              handleFieldChange(
                                                "activeStartDate",
                                                value ? value : undefined
                                              )
                                            }
                                            className={classes.datePickerField}
                                            KeyboardButtonProps={{
                                              "aria-label": "change date",
                                            }}
                                            error={
                                              !!data.errors["activeStartDate"]
                                            }
                                            innerRef={(ref: any) => {
                                              data.register("activeStartDate", {
                                                required:
                                                  "The Effective Start Date is required",
                                                validate: {
                                                  afterLastDetail: (value) => {
                                                    var sValue = new Date(
                                                      new Date(value).setHours(
                                                        0,
                                                        0,
                                                        0,
                                                        0
                                                      )
                                                    );
                                                    if (
                                                      lastDetailDate == null &&
                                                      modelEdit == null
                                                    )
                                                      return true;
                                                    if (
                                                      lastDetailDate != null &&
                                                      modelEdit != null &&
                                                      new Date(
                                                        modelEdit?.activeStartDate
                                                      ) < sValue
                                                    )
                                                      return true;
                                                    if (
                                                      lastDetailDate != null &&
                                                      modelEdit != null &&
                                                      compareDates(
                                                        new Date(
                                                          modelEdit?.activeStartDate
                                                        ),
                                                        sValue
                                                      )
                                                    )
                                                      return true;
                                                    if (
                                                      lastDetailDate != null &&
                                                      modelEdit != null &&
                                                      new Date(
                                                        modelEdit?.activeStartDate
                                                      ) != sValue &&
                                                      new Date(
                                                        modelEdit?.activeStartDate
                                                      ) < sValue
                                                    )
                                                      return true;
                                                    if (
                                                      lastDetailDate != null &&
                                                      modelEdit == null &&
                                                      new Date(lastDetailDate) <
                                                        sValue
                                                    )
                                                      return true;
                                                    return (
                                                      "The Effective Start Date must be greater then " +
                                                      new Date(
                                                        lastDetailDate ??
                                                          new Date()
                                                      )
                                                        .toLocaleDateString()
                                                        .toString()
                                                    );
                                                  },
                                                },
                                              });
                                              data.setValue(
                                                "activeStartDate",
                                                model.activeStartDate
                                              );
                                            }}
                                            helperText={
                                              data.errors["activeStartDate"]
                                                ? (
                                                    data.errors[
                                                      "activeStartDate"
                                                    ] as FieldError
                                                  ).message
                                                : undefined
                                            }
                                          />
                                        </MuiPickersUtilsProvider>
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={6}></Grid>
                                    </Grid>
                                    {/* <Grid container direction="row" spacing={2}>
                                <Grid item xs={4} sm={4} md={4}>
                                  <FormControlLabel
                                    id="isModuleCourse"
                                    className={classes.switch}
                                    labelPlacement="start"
                                    label="Is Module Course"
                                    control={
                                      <Switch
                                        checked={
                                          model.isModuleCourse ? true : false
                                        }
                                        name="isContinuingEducation"
                                        onChange={(e: any) => {
                                          handleFieldChange(
                                            "isModuleCourse",
                                            e ? e.target.checked : false
                                          );
                                        }}
                                        color="primary"
                                      />
                                    }
                                  />
                                </Grid>
                              </Grid> */}
                                    <Grid
                                      container
                                      direction="row"
                                      spacing={2}
                                      className={classes.gridMargin}
                                    >
                                      <TableContainer
                                        component={Paper}
                                        square
                                        className={classes.tableContainer}
                                      >
                                        <Table
                                          stickyHeader
                                          size="small"
                                          className={classes.table}
                                          aria-label="customized table"
                                        >
                                          <TableHead>
                                            <TableRow>
                                              <TableCell>
                                                Course History
                                              </TableCell>
                                              <TableCell>
                                                Effective Start Date
                                              </TableCell>
                                              <TableCell>
                                                Effective End Date
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {loading ? (
                                              <TableRow>
                                                <TableCell
                                                  className={
                                                    classes.preLoaderCell
                                                  }
                                                >
                                                  <CircularProgress />
                                                </TableCell>
                                              </TableRow>
                                            ) : (
                                              courseDetailList.map(
                                                (program, index) => (
                                                  <TableRow
                                                    key={`row-${index}`}
                                                  >
                                                    <TableCell
                                                      key={`courseName-${index}`}
                                                      className={
                                                        0 == index
                                                          ? classes.courseSelectionCell
                                                          : ""
                                                      }
                                                      onClick={() =>
                                                        0 == index
                                                          ? getDetails(
                                                              program.courseDetailId ??
                                                                ""
                                                            )
                                                          : undefined
                                                      }
                                                    >
                                                      {program.name}
                                                    </TableCell>
                                                    <TableCell>
                                                      {new Date(
                                                        program.activeStartDate
                                                      ).toLocaleDateString()}
                                                    </TableCell>
                                                    <TableCell>
                                                      {new Date(
                                                        program.activeEndDate
                                                      ).toLocaleDateString()}
                                                    </TableCell>
                                                  </TableRow>
                                                )
                                              )
                                            )}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </Grid>
                                  </React.Fragment>
                                )) || (
                                  <div className={classes.alignCenter}>
                                    <CircularProgress />
                                  </div>
                                )}
                              </form>
                            </FormContext>
                          </Grid>
                          <Grid
                            item
                            alignContent="flex-start"
                            alignItems="flex-start"
                            justify="flex-start"
                          >
                            <Grid
                              container
                              direction="row"
                              spacing={1}
                              wrap="nowrap"
                            >
                              <Grid
                                item
                                alignContent="flex-start"
                                alignItems="flex-start"
                                justify="flex-start"
                              >
                                <ProgressSaveButton
                                  text="Save"
                                  onClick={handleSubmit(() =>
                                    saveCourseDetails(false)
                                  )}
                                  loading={loading}
                                  disabled={false}
                                  buttonClassName={classes.saveButton}
                                  size="small"
                                ></ProgressSaveButton>
                              </Grid>
                              <Grid
                                item
                                alignContent="flex-start"
                                alignItems="flex-start"
                                justify="flex-start"
                              >
                                <Button
                                  disabled={loading}
                                  onClick={cancel}
                                  size="small"
                                  color="primary"
                                  variant="contained"
                                  type="button"
                                >
                                  Cancel
                                </Button>
                              </Grid>
                              <Grid
                                item
                                alignContent="flex-start"
                                alignItems="flex-start"
                                justify="flex-start"
                              >
                                <Button
                                  size="small"
                                  color="primary"
                                  variant="contained"
                                  type="button"
                                  onClick={cancel}
                                >
                                  ADD COURSE
                                </Button>{" "}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      {currentCourseId != null && (
                        <Grid
                          item
                          alignContent="flex-start"
                          alignItems="flex-start"
                          justify="flex-start"
                        >
                          <Grid container direction="row" spacing={2}>
                            <Grid item xs={12}>
                              <CreditCourseGradeBook
                                setConfirmationDialog={setConfirmationDialogue}
                                showSnackBar={setSnackBarProps}
                                course={model}
                                isInstructorGradeBook={false}
                                campusId={userSelectedCampus}
                                courseId={currentCourseId}
                                repeatedExamPolicy={repeatedExamPolicySelected}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </TabPanel>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog"
        open={confirmationDialogue.open}
        onClose={() => {
          setConfirmationDialogue({ open: false });
          confirmationDialogue.onCancel && confirmationDialogue.onCancel();
        }}
      >
        <DialogContent dividers>
          <Typography>{confirmationDialogue.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              confirmationDialogue.onOk && confirmationDialogue.onOk();
              setConfirmationDialogue({ open: false });
            }}
            color="primary"
          >
            Confirm
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setConfirmationDialogue({ open: false });
              confirmationDialogue.onCancel && confirmationDialogue.onCancel();
            }}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default CoursesSetup;
