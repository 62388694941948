/* eslint-disable eqeqeq */
//import * as React from "react";
import React, { Fragment, useEffect, useState } from "react";
import {
  makeStyles,
  createStyles,
  Grid,
  Paper,
  Popover,
  Table,
  withStyles,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  IconButton,
  CircularProgress,
  Button,
  Card,
  CardContent,
  TextField,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Tab,
  Tabs,
  Box,
  FormControlLabel,
  Switch,
  FormControl,
  CardActions,
  TableFooter,
  InputAdornment,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import AddCircle from "@material-ui/icons/AddCircle";
import SortIcon from "@material-ui/icons/Sort";
import InputField from "../../../../components/_Layout/Inputs/InputField";
import { EmptyGuid } from "../../../../utils/constants";
import { CustomSnackBarProps } from "../../../../interfaces/common/CustomSnackBarProps";
import { useSelector } from "react-redux";
import ICreditCourseDetails from "../../../../interfaces/setup/academics/ProgramDefinition/ICreditCourseDetails";
import CreditCourseGradeBook from "../../../setup/creditHour/courses/CreditCourseGradeBook";
import { getAllCreditCourses } from "../../../../api/setup/academics/creditCoursesApi";
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";
import CustomCardTitle from "../../../../interfaces/common/card/CustomCardTitle";
import { FieldError, useForm, FormContext } from "react-hook-form";
import { InputType } from "../../../../constants/uiConstants/inputConstants";
import StatusAutoComplete from "../../../../components/AutoComplete/StatusAutoComplete";
import TriggerUnitTypeIdAutoComplete from "../../../../components/AutoComplete/triggerUnitTypeIdAutoComplete";
import TriggerOffsetTypeIdAutoComplete from "../../../../components/AutoComplete/TriggerOffsetTypeIdAutoComplete";
import QualitativeMinimumTypeIdAutoComplete from "../../../../components/AutoComplete/QualitativeMinimumTypeIdAutoComplete";
import QuantitativeMinimumUnitTypeIdAutoComplete from "../../../../components/AutoComplete/QuantitativeMinimumUnitTypeIdAutoComplete";
import QuantitativeMinimumUnitTypeIdCredAttAutoComplete from "../../../../components/AutoComplete/QuantitativeMinimumUnitTypeIdCredAttAutoComplete";
import ConseqTypIdAutoComplete from "../../../../components/AutoComplete/ConseqTypIdAutoComplete";
import CampusGroupAutoComplete from "../../../../components/AutoComplete/CampusGroupAutoComplete";
import CampusGroupsAutoComplete from "../../../../components/AutoComplete/CampusGroupsAutoComplete";
import RefreshIcon from "@material-ui/icons/Refresh";

import ProgressSaveButton from "../../../../components/_Layout/Buttons/ProgressSaveButton";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  UpsertCourse,
  GetCourseDetailByCourseId,
  GetCourseDetailByDetailId,
} from "../../../../api/setup/academics/creditCoursesApi";
import * as creditCoursesApi from "../../../../api/setup/academics/creditCoursesApi";
import * as PropTypes from "prop-types";
import CampusForCurrentUserAutoComplete from "../../../../components/AutoComplete/CampusForCurrentUserAutoComplete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { IPoliciesDetails } from "../../../../interfaces/setup/policies/IPoliciesDetails";
import {
  deletePolicy,
  getDetails,
  getPolicyList,
  savePolicy,
  savePolicyExist,
  deletePolicy2,
  getDetails2,
  getPolicyList2,
  savePolicy2,
  savePolicyExist2,
} from "../../../../api/setup/terms/PoliciesList";
import { DropDownListItem } from "../../../../interfaces/DropDownListItem";
import APIHook from "../../../../api/hook";
import IPolicies from "../../../../interfaces/setup/policies/IPolicies";
import { toastr } from "react-redux-toastr";
import ComponentList from "../../../../components/routing/ComponentList";
import { Pagination } from "@material-ui/lab";
import { AnyAaaaRecord } from "dns";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: "0px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ThemeTextField = withStyles(() =>
  createStyles({
    root: {
      "& .MuiFormLabel-root": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "100%",
      },
    },
  })
)(TextField);

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardMain: {
      margin: "auto",
      marginTop: theme.spacing(2),
      minHeight: "750px",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
    },
    tabStyle: {
      backgroundColor: theme.palette.primary.constrastText,
      color: theme.palette.primary.headerText,
    },
    sortDesc: {
      transform: "rotate(180deg)",
    },
    TextFieldResize: {
      resize: "both",
      overflow: "auto",
    },
    loaderWrapper: {
      textAlign: "center",
    },
    cardMainContent: {
      height: "750px",
    },
    cardMainContent2: {
      height: "750px",
    },
    selectAbleRow: {
      cursor: "pointer",
    },
    pagination: {
      display: "inline-flex",
    },
    gridName: {
      marginTop: "20px",
    },
    gridName2: {
      marginTop: "10px",
    },
    gridName3: {
      marginTop: "40px",
    },
    gridName4: {
      marginLeft: "3px",
    },
    gridName6: {
      marginLeft: "5px",
    },
    buttonColorTertiary: {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.error.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.error.main,
      },
      textDecoration: "bold",
      padding: theme.spacing(1),
      whiteSpace: "nowrap",
      margin: theme.spacing(1.5),
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
  })
);

const StudentInfo2 = () => {
  //-------------------------BELOW

  const [searchText, setSearchText] = React.useState<string>("");

  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  const campus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const classes = useStyles({});
  const [page, setPage] = React.useState<number>(1);

  //const [sapIdValue, setSapIdValue] = React.useState<string>('');

  const [showAll, setShowAll] = React.useState<boolean>(false);
  const [showAllTab2, setShowAllTab2] = React.useState<boolean>(true);

  const [sort, setSort] = React.useState<"asc" | "desc">("asc");
  const pageSize = 20;
  //const pageSize = 200;

  const [search, setSearch] = React.useState<string>("");
  const [dirty, setDirty] = React.useState<boolean>(false);
  const [popAnchor, setPopAnchor] = React.useState<null | HTMLElement>(null);
  const [popMessage, setPopMessage] = React.useState<string>("");
  const [popConfirmAction, setPopConfirmAction] = React.useState<() => void>();
  const [response, message, code, saving, callSave, resetState] = APIHook<
    IPoliciesDetails,
    typeof savePolicy
  >(savePolicy);
  const [
    responseTab2,
    messageTab2,
    codeTab2,
    savingTab2,
    callSaveTab2,
    resetStateTab2,
  ] = APIHook<IPoliciesDetails, typeof savePolicy2>(savePolicy2);
  const [, , codeExist, savingExist, callSaveExist, resetStateExist] = APIHook<
    IPoliciesDetails,
    typeof savePolicyExist
  >(savePolicyExist);
  const [
    responseExistTab2,
    messageExistTab2,
    codeExistTab2,
    savingExistTab2,
    callSaveExistTab2,
    resetStateExistTab2,
  ] = APIHook<IPoliciesDetails, typeof savePolicyExist2>(savePolicyExist2);
  const [, messageDelete, codeDelete, deleting, callDelete, resetStateDelete] =
    APIHook<string, typeof deletePolicy>(deletePolicy);
  const [
    ,
    messageDeleteTab2,
    codeDeleteTab2,
    deletingTab2,
    callDeleteTab2,
    resetStateDeleteTab2,
  ] = APIHook<string, typeof deletePolicy2>(deletePolicy2);
  const [
    fetchedDetails,
    ,
    detailsCode,
    loadingDetails,
    getTermDetails,
    resetDetails,
  ] = APIHook<IPoliciesDetails, typeof getDetails>(getDetails);

  const [
    fetchedDetailsTab2,
    ,
    detailsCodeTab2,
    loadingDetailsTab2,
    getTermDetailsTab2,
    resetDetailTab2,
  ] = APIHook<IPoliciesDetails, typeof getDetails2>(getDetails2);

  const [data, , , loading, getList] = APIHook<
    {
      data: Array<IPolicies>;
      hasMoreData: boolean;
      total: number;
    },
    typeof getPolicyList
  >(getPolicyList);

  const [dataTab2, , , loadingTab2, getListTab2] = APIHook<
    {
      data: Array<IPolicies>;
      hasMoreData: boolean;
      total: number;
    },
    typeof getPolicyList2
  >(getPolicyList2);

  const [details, setDetails] = React.useState<IPoliciesDetails>({
    id: EmptyGuid,
    code: "",
    name: "",
    triggerValue: 0,
    quantitativeMinimumValue: 0,
    qualitativeMinimumValue: 0,
    minimumAttendanceValue: 0,
    faSapPolicy: false,
    triggerUnitTypeId: 0,
    includeTransferHours: false,
    performQuantativeTitleIVSAPCheck: false,
    includeExternshipAttendance: false,
    payOnProbation: false,
  }); // eslint-disable-line

  const sapIdValue = details.id;

  //-------------------Tab 1

  React.useEffect(() => {
    getList({
      take: pageSize,
      sort,
      showAll,
      skip: (page - 1) * pageSize,
      search: search || undefined,
      campus: campus,
      sapIdValue: sapIdValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campus]);

  //------------------Tab 2

  // React.useEffect(() => {
  //   getListTab2({
  //     take: pageSize,
  //     sort,
  //     showAll,
  //     skip: (page - 1) * pageSize,
  //     search: search || undefined,
  //     campus: campus,
  //     sapIdValue: sapIdValue,
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [campus]);

  //---------------------Tab2

  React.useEffect(() => {
    getListTab2({
      take: pageSize,
      skip: 0,
      search,
      showAllTab2,
      sort,
      campus,
      sapIdValue,
    });
  }, [campus, search, showAllTab2, sort, sapIdValue]);

  const { register, reset, errors, handleSubmit, triggerValidation } =
    useForm<IPoliciesDetails>({
      mode: "onBlur",
    });

  const onSave = () => {
    callSave(details);
  };

  const onSave3 = () => {
    callSaveTab2(details);
  };

  const onSaveExist = () => {
    callSaveExist(details);
  };

  const onSaveExist3 = () => {
    callSaveExistTab2(details);
  };

  const data2 = useForm<IPoliciesDetails>({
    mode: "onBlur",
    defaultValues: details,
  });

  React.useEffect(() => {
    if (detailsCode === 200) {
      // reset({
      //   ...fetchedDetails,
      // });
      setDetails({
        ...fetchedDetails,
        triggerValue: fetchedDetailsTab2?.triggerValue,
        triggerOffsetSequence: fetchedDetailsTab2?.triggerOffsetSequence,
        qualitativeMinimumValue: fetchedDetailsTab2?.qualitativeMinimumValue,
        quantitativeMinimumValue: fetchedDetailsTab2?.quantitativeMinimumValue,
        quantitativeMinimumUnitTypeId:
          fetchedDetailsTab2?.quantitativeMinimumUnitTypeId,
        qualitativeMinimumTypeId: fetchedDetailsTab2?.qualitativeMinimumTypeId,
        //minimumCreditsCompleted: fetchedDetailsTab2?.minimumCreditsCompleted,
        minimumAttendanceValue: fetchedDetailsTab2?.minimumAttendanceValue,
        conseqTypId: fetchedDetailsTab2?.conseqTypId,
        sapDetailId: fetchedDetailsTab2?.sapDetailId,
        sapdetailId: fetchedDetailsTab2?.sapdetailId,
      });
      setDirty(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsCode, loadingDetails, fetchedDetails, fetchedDetailsTab2]);

  React.useEffect(() => {
    if (
      (code === 200 && !saving) ||
      (codeExist === 200 && !savingExist) ||
      (codeTab2 === 200 && !saving) ||
      (codeExistTab2 === 200 && !savingExist)
    ) {
      toastr.success("", "Policy saved successfully.");
      setTimeout(() => {
        toastr.clean();
      }, 3000);
      resetDetails();
      // setDetails({
      //   // id: EmptyGuid,
      //   ...details,
      //   id: currentId
      // });
      reset({});
      setDirty(false);
      getList({
        take: pageSize,
        skip: (page - 1) * pageSize,
        search: search || undefined,
        showAll,
        sort,
        campus: campus,
        sapIdValue: sapIdValue,
      });
    } else if (code) {
      toastr.error("", message || "Something went wrong.");
    }
    resetState(); //This resets the code
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    code,
    saving,
    codeExist,
    savingExist,
    codeTab2,
    savingTab2,
    codeExistTab2,
    savingExistTab2,
  ]);

  React.useEffect(() => {
    if (
      codeDelete === 200 &&
      !deleting //||
      //(codeDeleteTab2 === 200 && !deleting)
    ) {
      toastr.success("", "Policy deleted successfully.");
      //resetDetails();
      setDetails({
        id: EmptyGuid,
        //...fetchedDetails,
      });
      reset({});
      setDirty(false);
      getList({
        take: pageSize,
        skip: (page - 1) * pageSize,
        search: search || undefined,
        showAll,
        sort,
        campus: campus,
        sapIdValue: sapIdValue,
      });
    } else if (codeDelete) {
      toastr.error("", "Something went wrong.");
    }
    resetStateDelete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeDelete, deleting]);

  React.useEffect(() => {
    if (
      (code === 200 && !saving) ||
      (codeExist === 200 && !savingExist) ||
      (codeTab2 === 200 && !saving) ||
      (codeExistTab2 === 200 && !savingExist)
    ) {
      toastr.success("", "Policy saved successfully.");
      setTimeout(() => {
        toastr.clean();
      }, 3000);
      resetDetails();
      // setDetails({
      //   id: EmptyGuid,
      // });
      reset({});
      setDirty(false);
      getListTab2({
        take: pageSize,
        //skip: (page - 1) * pageSize,
        skip: 0,
        search: search || undefined,
        showAllTab2,
        sort,
        campus: campus,
        sapIdValue: sapIdValue,
      });
    } else if (code) {
      toastr.error("", message || "Something went wrong.");
    }
    resetState(); //This resets the code
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    code,
    saving,
    codeExist,
    savingExist,
    codeTab2,
    savingTab2,
    codeExistTab2,
    savingExistTab2,
  ]);

  React.useEffect(() => {
    if (
      //(codeDelete === 200 && !deleting) ||
      codeDeleteTab2 === 200 &&
      !deleting
    ) {
      toastr.success("", "Policy deleted successfully.");
      //resetDetails();
      setDetails({
        //id: EmptyGuid,
        ...fetchedDetails,
      });
      reset({});
      setDirty(false);
      getListTab2({
        take: pageSize,
        //skip: (page - 1) * pageSize,
        skip: 0,
        search: search || undefined,
        showAllTab2,
        sort,
        campus: campus,
        sapIdValue: sapIdValue,
      });
    } else if (codeDelete) {
      toastr.error("", "Something went wrong.");
    }
    resetStateDelete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeDeleteTab2, deletingTab2]);

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: (values: any, key: string) => Array<string>
  ) => {
    if (
      (fieldId == "TriggerUnit" || fieldId == "TriggerOffsetType") &&
      value == undefined
    )
      return;

    let updatedModel = details;
    let newValue = mapFunction ? mapFunction(value, "value") : value;
    (updatedModel as any)[fieldId] = newValue;

    if (fieldId == "campusIds") {
      (updatedModel as any)["programVersions"] = [];
      (updatedModel as any)["fundSources"] = [];
    }

    setDetails({ ...updatedModel });
  };

  const handleFieldChange = (fieldId: string, value: any) => {
    let updatedModel = details;
    (updatedModel as any)[fieldId] = value;
    setDetails({ ...updatedModel });
  };

  const initialState = {
    increment: "",
    triggerValue: "",
  };

  const [formData, setFormData] = React.useState(initialState);
  var startDate = new Date();
  var endDate = new Date();
  endDate.setFullYear(endDate.getFullYear() + 100);

  var baseModel: ICreditCourseDetails = {
    courseId: EmptyGuid,
    name: "",
    descrip: "",
    code: "",
    courseCost: null,
    faCredit: null,
    credit: null,
    activeStartDate: startDate,
    activeEndDate: endDate,
  };

  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );
  const [confirmationDialogue, setConfirmationDialogue] = React.useState<{
    onOk?: () => void;
    open: boolean;
    message?: string;
    onCancel?: () => void;
  }>({
    open: false,
    message: "Are you sure, do you want to save these changes? ",
  });

  const [value, setValue] = React.useState(0);

  const handleChange = (_index: any, newValue: any) => {
    setValue(newValue);
  };

  React.useEffect(() => {}, [value]);

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );

  var passCreditHourDefault: any;
  var warningCreditHourDefault: any;
  var ineligibleCreditHourDefault: any;
  var probationCreditHourDefault: any;

  var passDefault: any;
  var warningDefault: any;
  var ineligibleDefault: any;
  var probationDefault: any;

  passCreditHourDefault =
    "You have successfully met our school's financial aid satisfactory progress standards of {{Pace of Completion}} and a {{MinimumGPA}} GPA.";
  warningCreditHourDefault =
    "You have been placed on Title IV Warning for failure to complete the minimum required portion of your program and/or a low GPA.  At the end of the next payment period your progress will be re-evaluated. Failure to achieve our schools financial aid satisfactory progress standards of {{Pace of Completion}} and/or a {{MinimumGPA}} GPA will result in your Title IV financial funding being terminated.";
  ineligibleCreditHourDefault =
    "Failure to complete the minimum required portion of your program and/or a low GPA, and having a previous status of Title IV Warning, has made you ineligible to receive any further Title IV funding. At the end of the next payment period your progress will be re-evaluated. You must achieve our schools financial aid satisfactory progress standards of {{Pace of Completion}} and/or a {{MinimumGPA}} GPA to resume eligibility to receive any Title IV funding.";
  probationCreditHourDefault =
    "You have failed to complete the minimum required portion of your program and/or a low GPA, and having a previous status of Title IV Warning, you are ineligible to receive any further Title IV funding. Your appeal has been approved and you are now placed on Title IV probation which will temporarily resume your Title IV eligibility. At the end of the next payment period your progress will be re-evaluated. You must achieve our schools financial aid satisfactory progress standards of {{Pace of Completion}} and/or a {{MinimumGPA}} GPA to continue to receive Title IV aid.";

  passDefault =
    "You have successfully met our school's financial aid satisfactory progress standards of {{Minimum quantity}} and a {{MinimumGPA}} cumulative grade.";
  warningDefault =
    "You have been placed on Title IV Warning for failure to complete the minimum required portion of your program and/or a low cumulative grade.  At the end of the next payment period your progress will be re-evaluated. Failure to achieve our school's financial aid satisfactory progress standards of {{Minimum quantity}} and/or a {{MinimumGPA}} cumulative grade will result in your Title IV financial funding being terminated.";
  ineligibleDefault =
    "Failure to complete the minimum required portion of your program and/or a low cumulative grade, and having a previous status of Title IV Warning, has made you ineligible to receive any further Title IV funding.  At the end of the next payment period your progress will be re-evaluated. You must achieve our school's financial aid satisfactory progress standards of {{Minimum quantity}} and/or a {{MinimumGPA}} cumulative grade to resume eligibility to receive any Title IV funding.";
  probationDefault =
    "You have failed to complete the minimum required portion of your program and/or a low cumulative grade, and having a previous status of Title IV Warning, you are ineligible to receive any further Title IV funding.  Your appeal has been approved and you are now placed on Title IV probation which will temporarily resume your Title IV eligibility.  At the end of the next payment period your progress will be re-evaluated. You must achieve our school's financial aid satisfactory progress standards of {{Minimum quantity}} and/or a {{MinimumGPA}} cumulative grade to continue to receive Title IV aid.";

  const handleSetWarning = () => {
    setDetails({
      ...details,
      warning:
        details.triggerUnitTypeId === 7
          ? warningCreditHourDefault
          : warningDefault,
    });
  };

  const handleSetIneligible = () => {
    setDetails({
      ...details,
      ineligible:
        details.triggerUnitTypeId === 7
          ? ineligibleCreditHourDefault
          : ineligibleDefault,
    });
  };

  const handleSetProbation = () => {
    setDetails({
      ...details,
      probation:
        details.triggerUnitTypeId === 7
          ? probationCreditHourDefault
          : probationDefault,
    });
  };

  const handleSearch = (searchText: string) => {
    setSearchText(searchText);

    getList({
      take: pageSize,
      sort,
      showAll,
      skip: 0,
      search: searchText,
      campus: campus,
      sapIdValue: sapIdValue,
    });

    if (data && data.data) {
      const lowercasedSearchText = searchText.toLowerCase();
      data.data = data.data.filter((en: any) =>
        en.name.toLowerCase().includes(lowercasedSearchText)
      );
    }
  };

  React.useEffect(() => {
    if (details !== null && details.faSapPolicy) {
      const newDetails = {
        ...details,
        passed:
          details.passed ||
          (details.triggerUnitTypeId === 7
            ? passCreditHourDefault
            : passDefault),
        warning:
          details.warning ||
          (details.triggerUnitTypeId === 7
            ? warningCreditHourDefault
            : warningDefault),
        ineligible:
          details.ineligible ||
          (details.triggerUnitTypeId === 7
            ? ineligibleCreditHourDefault
            : ineligibleDefault),
        probation:
          details.probation ||
          (details.triggerUnitTypeId === 7
            ? probationCreditHourDefault
            : probationDefault),
      };
      setDetails(newDetails);
    }
  }, [
    details.triggerUnitTypeId,
    setDetails,
    passCreditHourDefault,
    passDefault,
    warningCreditHourDefault,
    warningDefault,
    ineligibleCreditHourDefault,
    ineligibleDefault,
    probationCreditHourDefault,
    probationDefault,
  ]);

  React.useEffect(() => {
    if (details.triggerUnitTypeId == 3) {
      const newDetails = {
        ...details,
        quantitativeMinimumUnitTypeId: 1,
      };
      setDetails(newDetails);
    }
  }, [details.triggerUnitTypeId]);

  const handleSetField = (field: any, value: any) => {
    setDetails({
      ...details,
      [field]: value,
    });
  };

  const [increment, setIncrement] = React.useState(details.increment);

  React.useEffect(() => {
    setIncrement(details.increment);
  }, [details.increment]);
  const handleIncrementChange = (newValue: any) => {
    setIncrement(newValue);
  };
  const handleRowClick = (itemIndex: any, item: any, e: any) => {
    if (!dirty) {
      getTermDetailsTab2(item.code);
      setIncrement(itemIndex + 1);
    } else {
      setPopMessage(
        "You have unsaved changes. Are you sure you want to proceed?"
      );
      setPopConfirmAction(() => () => {
        getTermDetailsTab2(item.code);
        setIncrement(itemIndex + 1);
      });
      setPopAnchor(e.currentTarget);
    }
  };

  const [currentId, setCurrentId] = React.useState<string>();
  const [currentDetailId, setCurrentDetailId] = React.useState<string>();

  React.useEffect(() => {
    if (response?.id != null) {
      var currentId = response?.id;
      setCurrentId(currentId);
      setDetails({
        ...details,
        // id: currentIdTemp,
        id: currentId,
      });
    } else {
      console.log("response?.id is null or undefined; currentId not set.");
    }
  }, [response?.id]);

  React.useEffect(() => {
    if (responseTab2 != null) {
      var currentIdTemp = responseTab2?.id;
      //var currentDetailIdTemp = responseExistTab2?.sapdetailId;
      var currentDetailIdTemp = responseTab2?.sapdetailId;

      setCurrentId(currentIdTemp);
      setCurrentDetailId(currentDetailIdTemp);

      setDetails({
        ...details,
        id: currentIdTemp,
        sapdetailId: currentDetailIdTemp,
      });
    } else {
      console.log("response?.id is null or undefined; currentId not set.");
    }
  }, [responseTab2]);

  React.useEffect(() => {
    if (responseExistTab2?.sapdetailId != null) {
      var currentDetailIdTemp = responseExistTab2?.sapdetailId;
      setCurrentDetailId(currentDetailIdTemp);
      setDetails({
        ...details,
        sapdetailId: currentDetailIdTemp,
      });
    } else {
      console.log("response?.id is null or undefined; currentId not set.");
    }
  }, [responseExistTab2?.sapdetailId]);

  const [autoToggle, setAutoToggle] = React.useState(true);

  var tempIncludeTransferHours = fetchedDetails?.includeTransferHours;

  React.useEffect(() => {
    if (autoToggle) {
      if (
        tempIncludeTransferHours === undefined ||
        tempIncludeTransferHours == null
      ) {
        if (details.triggerUnitTypeId === 4 && details.faSapPolicy) {
          handleFieldOnChange("includeTransferHours", true);
          setDetails((prevDetails) => ({
            ...prevDetails,
            includeTransferHours: true,
          }));
        } else if (!(details.triggerUnitTypeId === 4) && details.faSapPolicy) {
          handleFieldOnChange("includeTransferHours", false);
          setDetails((prevDetails) => ({
            ...prevDetails,
            includeTransferHours: false,
          }));
        }
      } else if (tempIncludeTransferHours !== null) {
        if (
          details.triggerUnitTypeId === 4
          //&& tempIncludeTransferHours === true
        ) {
          handleFieldOnChange("includeTransferHours", true);
          setDetails((prevDetails) => ({
            ...prevDetails,
            includeTransferHours: true,
          }));
        } else if (
          !(details.triggerUnitTypeId === 4) &&
          tempIncludeTransferHours === true
        ) {
          handleFieldOnChange("includeTransferHours", true);
          setDetails((prevDetails) => ({
            ...prevDetails,
            includeTransferHours: true,
          }));
        } else if (
          !(details.triggerUnitTypeId === 4) &&
          tempIncludeTransferHours !== true
        ) {
          handleFieldOnChange("includeTransferHours", false);
          setDetails((prevDetails) => ({
            ...prevDetails,
            includeTransferHours: false,
          }));
        } else {
          handleFieldOnChange("includeTransferHours", false);
          setDetails((prevDetails) => ({
            ...prevDetails,
            includeTransferHours: false,
          }));
        }
      } else {
        // handle false backend value
      }
    }
  }, [
    autoToggle,
    details.triggerUnitTypeId,
    details.faSapPolicy,
    details.performQuantativeTitleIVSAPCheck,
  ]);

  const handleToggleChange = (newValue: any) => {
    setAutoToggle(false);
    handleFieldOnChange("includeTransferHours", newValue);
    setDetails((prevDetails) => ({
      ...prevDetails,
      includeTransferHours: newValue,
    }));
  };

  const [autoTogglePerformQuant, setAutoTogglePerformQuant] =
    React.useState(true);

  var tempPerformQuantativeTitleIVSAPCheck =
    fetchedDetails?.performQuantativeTitleIVSAPCheck;

  React.useEffect(() => {
    if (autoTogglePerformQuant) {
      if (
        tempPerformQuantativeTitleIVSAPCheck === undefined ||
        tempPerformQuantativeTitleIVSAPCheck == null
      ) {
        if (
          (details.triggerUnitTypeId === 4 ||
            details.triggerUnitTypeId === 5) &&
          details.faSapPolicy
        ) {
          handleFieldOnChange("performQuantativeTitleIVSAPCheck", true);
          setDetails((prevDetails) => ({
            ...prevDetails,
            performQuantativeTitleIVSAPCheck: true,
          }));
        } else if (
          !(
            details.triggerUnitTypeId === 4 || details.triggerUnitTypeId === 5
          ) &&
          details.faSapPolicy
        ) {
          handleFieldOnChange("performQuantativeTitleIVSAPCheck", false);
          setDetails((prevDetails) => ({
            ...prevDetails,
            performQuantativeTitleIVSAPCheck: false,
          }));
        }
      } else if (
        tempPerformQuantativeTitleIVSAPCheck !== null &&
        details.faSapPolicy
      ) {
        if (
          details.triggerUnitTypeId === 4 ||
          details.triggerUnitTypeId === 5
          //&& tempPerformQuantativeTitleIVSAPCheck === true
        ) {
          handleFieldOnChange("performQuantativeTitleIVSAPCheck", true);
          setDetails((prevDetails) => ({
            ...prevDetails,
            performQuantativeTitleIVSAPCheck: true,
          }));
        } else if (
          !(
            details.triggerUnitTypeId === 4 || details.triggerUnitTypeId === 5
          ) &&
          tempPerformQuantativeTitleIVSAPCheck === true
        ) {
          handleFieldOnChange("performQuantativeTitleIVSAPCheck", true);
          setDetails((prevDetails) => ({
            ...prevDetails,
            performQuantativeTitleIVSAPCheck: true,
          }));
        } else if (
          !(
            details.triggerUnitTypeId === 4 || details.triggerUnitTypeId === 5
          ) &&
          tempPerformQuantativeTitleIVSAPCheck !== true
        ) {
          handleFieldOnChange("performQuantativeTitleIVSAPCheck", false);
          setDetails((prevDetails) => ({
            ...prevDetails,
            performQuantativeTitleIVSAPCheck: false,
          }));
        } else {
          handleFieldOnChange("performQuantativeTitleIVSAPCheck", false);
          setDetails((prevDetails) => ({
            ...prevDetails,
            performQuantativeTitleIVSAPCheck: false,
          }));
        }
      } else {
        // // Handle false backend value
      }
    }
  }, [
    autoTogglePerformQuant,
    details.triggerUnitTypeId,
    details.faSapPolicy,
    details.performQuantativeTitleIVSAPCheck,
  ]);

  React.useEffect(() => {
    setAutoTogglePerformQuant(true);
    setAutoToggle(true);
  }, [details.triggerUnitTypeId]);

  const handlePerformQuantToggleChange = (newValue: any) => {
    setAutoTogglePerformQuant(false); // Disable automatic toggling when user interacts
    handleFieldOnChange("performQuantativeTitleIVSAPCheck", newValue);
    setDetails((prevDetails) => ({
      ...prevDetails,
      performQuantativeTitleIVSAPCheck: newValue,
    }));
  };

  return (
    <React.Fragment>
      <CustomSnackbar
        variant={snackBarProps.variant}
        message={snackBarProps.messageInfo}
        open={snackBarProps.showSnackBar}
        onClose={() => {
          setSnackBarProps((props: any) => {
            return { ...props, showSnackBar: false };
          });
        }}
      ></CustomSnackbar>
      <Card square={true} elevation={12} className={classes.cardMain}>
        <CardContent>
          <Grid>
            <Grid container direction="row" spacing={2}>
              {/* //-------------Tab1-------------*/}

              {selectedTab == 0 && (
                <Grid item xs={12} sm={3} md={3}>
                  <Card elevation={2} className={classes.cardMainContent}>
                    <CardContent className={classes.cardMainContent}>
                      <TableContainer className={classes.cardMainContent}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <div>
                                  <TextField
                                    placeholder={"Search Policy"}
                                    value={searchText}
                                    onChange={(v: any) => {
                                      handleSearch(
                                        v ? v.target.value : undefined
                                      );
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton>
                                            <SearchIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell variant="head">
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={showAll}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        setShowAll(e.target.checked);
                                        setPage(1);
                                        getList({
                                          take: pageSize,
                                          skip: 0,
                                          search,
                                          showAll: e.target.checked,
                                          sort,
                                          campus,
                                          sapIdValue,
                                        });
                                      }}
                                      name="checkedB"
                                      color="primary"
                                    />
                                  }
                                  label="Show All"
                                />
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          {
                            //showAll &&
                            <TableBody>
                              {loading && (
                                <TableRow>
                                  <TableCell align="center">
                                    <CircularProgress
                                      size={50}
                                      color="primary"
                                    />
                                  </TableCell>
                                </TableRow>
                              )}
                              {!loading &&
                                data?.data?.map((item: IPolicies) => {
                                  return (
                                    <TableRow
                                      className={classes.selectAbleRow}
                                      key={item.id}
                                      onClick={(e) => {
                                        if (!dirty) {
                                          resetDetailTab2();
                                          setIncrement(0);
                                          getTermDetails(item.id);
                                          setShowAllTab2(false);
                                          setFormData(initialState);
                                        } else {
                                          setPopMessage(
                                            "You have unsaved changes. Are you sure you want to proceed?"
                                          );
                                          setPopConfirmAction(() => () => {
                                            getTermDetails(item.id);
                                          });
                                          setPopAnchor(e.currentTarget);
                                        }
                                      }}
                                    >
                                      <TableCell>
                                        {item.code}-{item.name}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              {(data?.data?.length || 0) < pageSize &&
                                !loading &&
                                [
                                  ...Array(
                                    pageSize - (data?.data?.length || 0)
                                  ),
                                ].map((_, index) => {
                                  return (
                                    <TableRow key={index}>
                                      <TableCell>&nbsp;</TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          }

                          <TableFooter>
                            <TableRow>
                              <TableCell align="center">
                                <Pagination
                                  className={classes.pagination}
                                  disabled={
                                    (!data?.hasMoreData ?? true) &&
                                    (page || 1) === 1
                                  }
                                  onChange={(_e, page) => {
                                    resetDetailTab2();
                                    setPage(page);
                                    getList({
                                      take: pageSize,
                                      skip: (page - 1) * pageSize,
                                      search: search || undefined,
                                      showAll,
                                      sort,
                                      campus,
                                      sapIdValue,
                                    });
                                  }}
                                  count={
                                    Math.ceil((data?.total || 0) / pageSize) ||
                                    0
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
              )}

              {/* //-------------Tab2-------------*/}

              {selectedTab == 1 && (
                <Grid //item xs={6}
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  className={classes.cardMainContent2}
                >
                  <Card elevation={2} className={classes.cardMainContent2}>
                    <CardContent className={classes.cardMainContent2}>
                      <TableContainer className={classes.cardMainContent2}>
                        <Table stickyHeader>
                          <TableBody>
                            {loadingTab2 && (
                              <TableRow>
                                <TableCell align="center">
                                  <CircularProgress size={50} color="primary" />
                                </TableCell>
                              </TableRow>
                            )}
                            {!loadingTab2 &&
                              dataTab2?.data?.map((item, index) => {
                                return (
                                  <TableRow
                                    className={classes.selectAbleRow}
                                    key={item.id}
                                    onClick={(e) => {
                                      handleRowClick(index, item, e);

                                      if (!dirty) {
                                        getTermDetails(item.id);
                                        setShowAllTab2(true);
                                        setFormData(initialState);
                                      } else {
                                        setPopMessage(
                                          "You have unsaved changes. Are you sure you want to proceed?"
                                        );
                                        setPopConfirmAction(() => () => {
                                          getTermDetails(item.id);
                                        });
                                        setPopAnchor(e.currentTarget);
                                      }
                                    }}
                                  >
                                    <TableCell>
                                      {item.triggerValue}{" "}
                                      {item.trigUnitTypIdDescrip}
                                      {" after the "}
                                      {item.trigOffsetTypIdDescrip}{" "}
                                      {item.triggerOffsetSequence}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            {(dataTab2?.data?.length || 0) < pageSize &&
                              !loadingTab2 &&
                              [
                                ...Array(
                                  pageSize - (dataTab2?.data?.length || 0)
                                ),
                              ].map((_, index) => (
                                <TableRow key={index}>
                                  <TableCell>&nbsp;</TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                          {/* //)} */}

                          <TableFooter>
                            <TableRow>
                              <TableCell align="center">
                                <Pagination
                                  className={classes.pagination}
                                  disabled={
                                    (!dataTab2?.hasMoreData ?? true) &&
                                    (page || 1) === 1
                                  }
                                  onChange={(_e, page) => {
                                    setPage(page);
                                    getListTab2({
                                      take: pageSize,
                                      skip: (page - 1) * pageSize,
                                      search: search || undefined,
                                      showAllTab2,
                                      sort,
                                      campus,
                                      sapIdValue,
                                    });
                                  }}
                                  count={
                                    Math.ceil(
                                      (dataTab2?.total || 0) / pageSize
                                    ) || 0
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
              )}

              <Grid item xs={12} sm={9} md={9}>
                <Grid
                  item
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <Card square={true} elevation={2}>
                    <Tabs
                      value={value}
                      onChange={(event, newValue) => {
                        handleChange(event, newValue);
                        handleTabChange(event, newValue);
                      }}
                      className={classes.tabStyle}
                      indicatorColor="primary"
                    >
                      <Tab label={"SAP Policy Setup"} {...a11yProps(0)} />
                      <Tab
                        label={"SAP Policy Details"}
                        {...a11yProps(1)}
                        disabled={
                          !details.id ||
                          details.id === "00000000-0000-0000-0000-000000000000"
                        }
                      />
                    </Tabs>

                    <TabPanel value={value} index={0}>
                      <CardContent
                      //className={classes.cardMainContent}
                      >
                        <form
                          onSubmit={handleSubmit(
                            details.id == EmptyGuid ? onSave : onSaveExist
                          )}
                        >
                          {((loadingDetails ||
                            deleting ||
                            saving ||
                            savingExist) && (
                            <div className={classes.loaderWrapper}>
                              <CircularProgress size={50} color="primary" />
                            </div>
                          )) || (
                            <Fragment>
                              <Grid container spacing={2}>
                                <Grid item md={3} sm={6} xs={12}>
                                  <div className={classes.gridName2}>
                                    Title IV SAP Policy?
                                  </div>
                                </Grid>
                                <Grid item md={9} sm={6} xs={12}>
                                  <FormControl>
                                    <FormControlLabel
                                      label=""
                                      control={
                                        <Switch
                                          checked={details.faSapPolicy}
                                          //value="titleIVSapPolicy"
                                          onChange={(e: any) => {
                                            handleFieldOnChange(
                                              "faSapPolicy",
                                              e.target.checked
                                                ? e.target.checked
                                                : false
                                            );
                                            setDetails({
                                              ...details,
                                              faSapPolicy: e.target.checked
                                                ? e.target.checked
                                                : false,
                                            });
                                          }}
                                          color="primary"
                                        />
                                      }
                                    />
                                  </FormControl>
                                </Grid>

                                <Grid item md={9} sm={6} xs={12}>
                                  <ThemeTextField
                                    type={InputType.TEXT}
                                    key="code"
                                    id="code"
                                    label="Code"
                                    name="code"
                                    className={classes.textField}
                                    value={details.code ?? ""}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      handleFieldChange("code", e.target.value);
                                    }}
                                    required
                                    inputRef={register({
                                      required: "Code is required.",
                                      maxLength: {
                                        value: 12,
                                        message:
                                          "Code cannot exceed 12 characters.",
                                      },
                                    })}
                                    error={errors.code ? true : false}
                                    helperText={
                                      errors.code ? errors.code.message : ""
                                    }
                                  />
                                </Grid>
                                <Grid item md={9} sm={6} xs={12}>
                                  <StatusAutoComplete
                                    id="status"
                                    label="Status*"
                                    name="status"
                                    valueFilter={
                                      details?.status
                                        ? {
                                            key: "value",
                                            values: [details?.status],
                                          }
                                        : undefined
                                    }
                                    filterHandle={(v: any) => {
                                      handleFieldOnChange(
                                        "status",
                                        v ? v.value : null
                                      );
                                    }}
                                    error={errors.status ? true : false}
                                    helperText={
                                      errors.status ? errors.status.message : ""
                                    }
                                    inputRef={register({
                                      required: "Status is required.",
                                    })}
                                  />
                                </Grid>

                                <Grid item md={9} sm={6} xs={12}>
                                  <CampusGroupsAutoComplete
                                    id="campusIds"
                                    label="Campus(es)"
                                    name="campusIds"
                                    valueFilter={
                                      details?.campusIds
                                        ? {
                                            key: "value",
                                            values: [details?.campusIds],
                                          }
                                        : undefined
                                    }
                                    filterHandle={(v: any) => {
                                      handleFieldOnChange(
                                        "campusIds",
                                        v ? v.value : null
                                      );
                                    }}
                                    error={errors.campusIds ? true : false}
                                    helperText={
                                      errors.campusIds
                                        ? errors.campusIds.message
                                        : ""
                                    }
                                    inputRef={register({
                                      required: "CampusIds is required.",
                                    })}
                                  />
                                </Grid>

                                <Grid item md={9} sm={6} xs={12}>
                                  <ThemeTextField
                                    type={InputType.TEXT}
                                    key="name"
                                    id="name"
                                    label="Description"
                                    name="name"
                                    className={classes.textField}
                                    value={details.name ?? ""}
                                    onChange={(
                                      e: React.FocusEvent<HTMLInputElement>
                                    ) => {
                                      handleFieldChange("name", e.target.value);
                                    }}
                                    required
                                    // inputRef={register({
                                    //   required: "Description is required.",
                                    // })}

                                    inputRef={register({
                                      required: "Description is required.",
                                      maxLength: {
                                        value: 50,
                                        message:
                                          "Description cannot exceed 50 characters.",
                                      },
                                    })}
                                    error={errors.name ? true : false}
                                    helperText={
                                      errors.name ? errors.name.message : ""
                                    }
                                  />
                                </Grid>

                                <Grid item md={9} sm={6} xs={12}>
                                  <TriggerUnitTypeIdAutoComplete
                                    id="triggerUnitTypeId"
                                    label="Trigger Unit*"
                                    name="triggerUnitTypeId"
                                    valueFilter={
                                      details?.triggerUnitTypeId
                                        ? {
                                            //key: "triggerUnitTypeId",
                                            key: "value",
                                            values: [
                                              details?.triggerUnitTypeId,
                                            ],
                                          }
                                        : undefined
                                    }
                                    filterHandle={(v: any) => {
                                      handleFieldOnChange(
                                        "triggerUnitTypeId",
                                        v ? v.value : null
                                      );
                                    }}
                                    error={
                                      errors.triggerUnitTypeId ? true : false
                                    }
                                    helperText={
                                      errors.triggerUnitTypeId
                                        ? errors.triggerUnitTypeId.message
                                        : ""
                                    }
                                    inputRef={register({
                                      required: "Trigger Unit is required.",
                                    })}
                                  />
                                </Grid>

                                <Grid item md={9} sm={6} xs={12}>
                                  <TriggerOffsetTypeIdAutoComplete
                                    id="triggerOffsetTypeId"
                                    label="Trigger Offset Type*"
                                    name="triggerOffsetTypeId"
                                    unitTypeId={details?.triggerUnitTypeId}
                                    valueFilter={
                                      details?.triggerOffsetTypeId
                                        ? {
                                            key: "value",
                                            values: [
                                              details?.triggerOffsetTypeId,
                                            ],
                                          }
                                        : undefined
                                    }
                                    filterHandle={(v: any) => {
                                      handleFieldOnChange(
                                        "triggerOffsetTypeId",
                                        v ? v.value : null
                                      );
                                    }}
                                    error={
                                      errors.triggerOffsetTypeId ? true : false
                                    }
                                    helperText={
                                      errors.triggerOffsetTypeId
                                        ? errors.triggerOffsetTypeId.message
                                        : ""
                                    }
                                    inputRef={register({
                                      required:
                                        "Trigger Offset Type is required.",
                                    })}
                                  />
                                </Grid>

                                {(details.faSapPolicy === false ||
                                  details.faSapPolicy === null ||
                                  details.faSapPolicy === undefined) && (
                                  <Grid
                                    container //direction="row"
                                    spacing={2}
                                    className={classes.gridName4}
                                  >
                                    <Grid item md={9} sm={6} xs={12}>
                                      <ThemeTextField
                                        type={InputType.NUMBER}
                                        key="terminationProbationCnt"
                                        id="terminationProbationCnt"
                                        label="Number of Probation(s) for Termination"
                                        name="terminationProbationCnt"
                                        className={classes.textField}
                                        value={
                                          details.terminationProbationCnt ?? ""
                                        }
                                        onChange={(
                                          e: React.FocusEvent<HTMLInputElement>
                                        ) => {
                                          handleFieldChange(
                                            "terminationProbationCnt",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={12}>
                                      <div className={classes.gridName2}>
                                        Include Externship Attendance?
                                      </div>
                                    </Grid>

                                    <Grid item md={8} sm={6} xs={12}>
                                      <FormControl>
                                        <FormControlLabel
                                          label=""
                                          control={
                                            <Switch
                                              checked={
                                                details.includeExternshipAttendance
                                              }
                                              //value="titleIVSapPolicy"
                                              onChange={(e: any) => {
                                                handleFieldOnChange(
                                                  "includeExternshipAttendance",
                                                  e.target.checked
                                                    ? e.target.checked
                                                    : false
                                                );
                                                setDetails({
                                                  ...details,
                                                  includeExternshipAttendance: e
                                                    .target.checked
                                                    ? e.target.checked
                                                    : false,
                                                  //reportType: value.value || undefined,
                                                });
                                              }}
                                              color="primary"
                                            />
                                          }
                                        />
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                )}
                                {/* </Grid> */}

                                {/* {details.triggerUnitTypeId === 4 ? (
                                    <div className={classes.gridName}>Code</div>
                                  ) : (
                                    <div className={classes.gridName}>
                                      Code*
                                    </div>
                                  )} */}

                                {(details.triggerUnitTypeId === 4 ||
                                  (details.triggerUnitTypeId === 4 &&
                                    details.faSapPolicy)) && (
                                  <Grid item md={4} sm={6} xs={12}>
                                    <div className={classes.gridName2}>
                                      Trigger Includes Transfer Hours?
                                    </div>
                                  </Grid>
                                )}

                                {(details.triggerUnitTypeId === 4 ||
                                  (details.triggerUnitTypeId === 4 &&
                                    details.faSapPolicy)) && (
                                  <Grid item md={8} sm={6} xs={12}>
                                    <FormControl className={classes.gridName6}>
                                      <FormControlLabel
                                        label=""
                                        control={
                                          <Switch
                                            checked={
                                              details.includeTransferHours
                                              // ||
                                              // (details.triggerUnitTypeId ===
                                              //   4 &&
                                              //   details.faSapPolicy
                                              // )
                                            }
                                            onChange={(e) => {
                                              console.log("Switch toggled");
                                              const newValue = e.target.checked;
                                              console.log(
                                                "New Value:",
                                                newValue
                                              );
                                              handleToggleChange(newValue);
                                            }}
                                            color="primary"
                                          />
                                        }
                                      />
                                    </FormControl>
                                  </Grid>
                                )}

                                {details.faSapPolicy ? (
                                  <Grid
                                    container //direction="row"
                                    spacing={2}
                                    className={classes.gridName4}
                                  >
                                    <Grid item md={4} sm={6} xs={12}>
                                      <div className={classes.gridName2}>
                                        Pay on Probation?
                                      </div>
                                    </Grid>
                                    <Grid item md={8} sm={6} xs={12}>
                                      <FormControl>
                                        <FormControlLabel
                                          label=""
                                          control={
                                            <Switch
                                              checked={details.payOnProbation}
                                              //value="titleIVSapPolicy"
                                              onChange={(e: any) => {
                                                handleFieldOnChange(
                                                  "payOnProbation",
                                                  e.target.checked
                                                    ? e.target.checked
                                                    : false
                                                );
                                                setDetails({
                                                  ...details,
                                                  payOnProbation: e.target
                                                    .checked
                                                    ? e.target.checked
                                                    : false,
                                                  //reportType: value.value || undefined,
                                                });
                                              }}
                                              color="primary"
                                            />
                                          }
                                        />
                                      </FormControl>
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={12}>
                                      <div className={classes.gridName2}>
                                        Perform Quantitative Title IV SAP Check?
                                      </div>
                                    </Grid>
                                    <Grid item md={8} sm={6} xs={12}>
                                      <FormControl>
                                        <FormControlLabel
                                          label=""
                                          control={
                                            <Switch
                                              checked={
                                                details.performQuantativeTitleIVSAPCheck
                                                // || // Existing check
                                                // (autoTogglePerformQuant && // Added condition for autoTogglePerformQuant
                                                //   ((details.triggerUnitTypeId ===
                                                //     4 &&
                                                //     details.faSapPolicy) ||
                                                //     (details.triggerUnitTypeId ===
                                                //       5 &&
                                                //       details.faSapPolicy)))
                                              }
                                              onChange={(e) => {
                                                console.log(
                                                  "Switch toggled for performQuantativeTitleIVSAPCheck"
                                                );
                                                const newValue =
                                                  e.target.checked;
                                                console.log(
                                                  "New Value:",
                                                  newValue
                                                );
                                                handlePerformQuantToggleChange(
                                                  newValue
                                                ); // Ensure state update and disable auto-toggling
                                              }}
                                              color="primary"
                                            />
                                          }
                                        />
                                      </FormControl>
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={12}>
                                      <div
                                      //className={classes.gridName3}
                                      >
                                        Passed
                                      </div>
                                    </Grid>

                                    <Grid item md={9} sm={6} xs={12}>
                                      <InputField
                                        type={InputType.TEXT}
                                        multiline
                                        rows={6}
                                        className={classes.TextFieldResize}
                                        key="passed"
                                        id="passed"
                                        label=""
                                        name="passed"
                                        onChange={(e) =>
                                          setDetails({
                                            ...details,
                                            passed: e.target.value,
                                          })
                                        }
                                        value={
                                          details.passed
                                            ? details.passed
                                            : details.triggerUnitTypeId === 7
                                            ? passCreditHourDefault
                                            : passDefault
                                        }
                                        error={!!data2.errors.code}
                                        inputRef={data2.register({
                                          required: "Warning is required.",
                                        })}
                                        helperText={
                                          data2.errors.passed
                                            ? (
                                                data2.errors
                                                  .passed as FieldError
                                              ).message
                                            : undefined
                                        }
                                        // onChange={(e) =>
                                        //   setDetails({
                                        //     ...details,
                                        //     passed: e.target.value,
                                        //   })
                                        // }
                                      />
                                    </Grid>
                                    <Grid item md={1} sm={6} xs={12}>
                                      <RefreshIcon
                                        onClick={() =>
                                          handleSetField(
                                            "passed",
                                            details.triggerUnitTypeId === 7
                                              ? passCreditHourDefault
                                              : passDefault
                                          )
                                        }
                                        className={classes.gridName3}
                                      />
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={12}>
                                      <div
                                      //className={classes.gridName3}
                                      >
                                        Warning
                                      </div>
                                    </Grid>

                                    <Grid item md={9} sm={6} xs={12}>
                                      <InputField
                                        type={InputType.TEXT}
                                        multiline
                                        rows={6}
                                        className={classes.TextFieldResize}
                                        key="warning"
                                        id="warning"
                                        label=""
                                        name="warning"
                                        // value={details.warning ?? ""}
                                        value={
                                          details.warning
                                            ? details.warning
                                            : details.triggerUnitTypeId === 7
                                            ? warningCreditHourDefault
                                            : warningDefault
                                        }
                                        onChange={(
                                          e: React.FocusEvent<HTMLInputElement>
                                        ) => {
                                          handleFieldChange(
                                            "warning",
                                            e.target.value
                                          );
                                        }}
                                        error={!!data2.errors.code}
                                        inputRef={data2.register({
                                          required: "Warning is required.",
                                        })}
                                        helperText={
                                          data2.errors.warning
                                            ? (
                                                data2.errors
                                                  .warning as FieldError
                                              ).message
                                            : undefined
                                        }
                                      />
                                    </Grid>
                                    <Grid item md={1} sm={6} xs={12}>
                                      <RefreshIcon
                                        className={classes.gridName3}
                                        onClick={handleSetWarning}
                                        type="button"
                                      />
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={12}>
                                      <div
                                      //className={classes.gridName3}
                                      >
                                        Ineligible
                                      </div>
                                    </Grid>

                                    <Grid item md={9} sm={6} xs={12}>
                                      <InputField
                                        type={InputType.TEXT}
                                        multiline
                                        rows={6}
                                        className={classes.TextFieldResize}
                                        key="ineligible"
                                        id="ineligible"
                                        label=""
                                        name="ineligible"
                                        // value={details.ineligible ?? ""}
                                        value={
                                          details.ineligible
                                            ? details.ineligible
                                            : details.triggerUnitTypeId === 7
                                            ? ineligibleCreditHourDefault
                                            : ineligibleDefault
                                        }
                                        onChange={(
                                          e: React.FocusEvent<HTMLInputElement>
                                        ) => {
                                          handleFieldChange(
                                            "ineligible",
                                            e.target.value
                                          );
                                        }}
                                        error={!!data2.errors.code}
                                        inputRef={data2.register({
                                          required: "Ineligible is required.",
                                        })}
                                        helperText={
                                          data2.errors.ineligible
                                            ? (
                                                data2.errors
                                                  .ineligible as FieldError
                                              ).message
                                            : undefined
                                        }
                                      />
                                    </Grid>
                                    <Grid item md={1} sm={6} xs={12}>
                                      <RefreshIcon
                                        className={classes.gridName3}
                                        onClick={handleSetIneligible}
                                        type="button"
                                      />
                                    </Grid>

                                    <Grid item md={4} sm={6} xs={12}>
                                      <div
                                      //className={classes.gridName3}
                                      >
                                        Probation
                                      </div>
                                    </Grid>

                                    <Grid item md={9} sm={6} xs={12}>
                                      <InputField
                                        type={InputType.TEXT}
                                        multiline
                                        rows={6}
                                        className={classes.TextFieldResize}
                                        key="probation"
                                        id="probation"
                                        label=""
                                        name="probation"
                                        // value={details.probation ?? ""}
                                        value={
                                          details.probation
                                            ? details.probation
                                            : details.triggerUnitTypeId === 7
                                            ? probationCreditHourDefault
                                            : probationDefault
                                        }
                                        onChange={(
                                          e: React.FocusEvent<HTMLInputElement>
                                        ) => {
                                          handleFieldChange(
                                            "probation",
                                            e.target.value
                                          );
                                        }}
                                        error={!!data2.errors.code}
                                        inputRef={data2.register({
                                          required: "Probation is required.",
                                        })}
                                        helperText={
                                          data2.errors.probation
                                            ? (
                                                data2.errors
                                                  .probation as FieldError
                                              ).message
                                            : undefined
                                        }
                                      />
                                    </Grid>
                                    <Grid item md={1} sm={6} xs={12}>
                                      <RefreshIcon
                                        className={classes.gridName3}
                                        onClick={handleSetProbation}
                                        type="button"
                                      />
                                    </Grid>
                                  </Grid>
                                ) : null}
                              </Grid>
                            </Fragment>
                          )}
                        </form>
                      </CardContent>

                      <CardActions>
                        {/* <Button
                          color="primary"
                          variant="contained"
                          //   onClick={handleSubmit(onSave)}
                          onClick={handleSubmit(
                            details.id == EmptyGuid ? onSave : onSaveExist
                          )}
                          disabled={
                            saving || deleting || loadingDetails || savingExist
                          }
                        >
                          SAVE
                        </Button> */}
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={handleSubmit(
                            details.id === EmptyGuid ? onSave : onSaveExist
                          )}
                          disabled={
                            saving || deleting || loadingDetails || savingExist
                          }
                        >
                          {saving ||
                          deleting ||
                          loadingDetails ||
                          savingExist ? (
                            <CircularProgress size={24} color="primary" />
                          ) : (
                            "SAVE"
                          )}
                        </Button>{" "}
                        <Button
                          disabled={
                            saving || deleting || loadingDetails || savingExist
                          }
                          color="secondary"
                          variant="contained"
                          onClick={(e) => {
                            if (!dirty) {
                              resetDetails();
                              setDetails({
                                id: EmptyGuid,
                                faSapPolicy: false,
                                triggerUnitTypeId: 0,
                                includeTransferHours: false,
                                performQuantativeTitleIVSAPCheck: false,
                              });
                              reset({});
                              setDirty(false);
                              setAutoTogglePerformQuant(true);
                              setAutoToggle(true);
                            } else {
                              setPopMessage(
                                "You have unsaved changes. Are you sure you want to proceed?"
                              );
                              setPopAnchor(e.currentTarget);
                              setPopConfirmAction(() => () => {
                                resetDetails();
                                setDetails({
                                  id: EmptyGuid,
                                  faSapPolicy: false,
                                  triggerUnitTypeId: 0,
                                  includeTransferHours: false,
                                  performQuantativeTitleIVSAPCheck: false,
                                });
                                reset({});
                                setDirty(false);
                                setAutoTogglePerformQuant(true);
                                setAutoToggle(true);
                              });
                            }
                          }}
                        >
                          NEW
                        </Button>
                        {details?.id && details?.id !== EmptyGuid && (
                          <Button
                            className={classes.buttonColorTertiary}
                            disabled={
                              saving ||
                              deleting ||
                              loadingDetails ||
                              savingExist
                            }
                            color="secondary"
                            variant="contained"
                            onClick={(e) => {
                              console.log("clickclick");
                              //callDelete(details?.id || EmptyGuid);
                              setPopMessage(
                                "Are you sure you want to delete this policy?"
                              );
                              setPopAnchor(e.currentTarget);
                              setPopConfirmAction(() => () => {
                                callDelete(details?.id || EmptyGuid);
                              });
                            }}
                          >
                            DELETE
                          </Button>
                        )}
                      </CardActions>
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                      <CardContent>
                        {/* <form onSubmit={handleSubmit(onSave)}> */}
                        <form
                          onSubmit={handleSubmit(
                            details.id == EmptyGuid ? onSave3 : onSaveExist3
                          )}
                        >
                          {((loadingDetailsTab2 ||
                            deletingTab2 ||
                            savingTab2 ||
                            savingExistTab2) && (
                            <div className={classes.loaderWrapper}>
                              <CircularProgress size={50} color="primary" />
                            </div>
                          )) || (
                            <Fragment>
                              <Grid container spacing={2}>
                                <Grid item md={8} sm={6} xs={12}>
                                  <ThemeTextField
                                    type={InputType.TEXT}
                                    key="name"
                                    id="name"
                                    label="Description"
                                    name="name"
                                    className={classes.textField}
                                    value={details.name ?? ""}
                                    onChange={(
                                      e: React.FocusEvent<HTMLInputElement>
                                    ) => {
                                      handleFieldChange("name", e.target.value);
                                    }}
                                    inputRef={register({
                                      required: "Description is required.",
                                      maxLength: {
                                        value: 50,
                                        message:
                                          "Description cannot exceed 50 characters.",
                                      },
                                    })}
                                    error={errors.name ? true : false}
                                    helperText={
                                      errors.name ? errors.name.message : ""
                                    }
                                  />
                                </Grid>

                                <Grid item md={8} sm={6} xs={12}>
                                  <ThemeTextField
                                    name="increment"
                                    label=" "
                                    value={increment}
                                    disabled={true}
                                  />
                                </Grid>

                                <Grid item md={8} sm={6} xs={12}>
                                  <ThemeTextField
                                    type={InputType.TEXT}
                                    key="triggerValue"
                                    id="triggerValue"
                                    label= {details?.triggerUnitTypeId == 7 ? "Trigger Unit Value" : "Trigger Unit Value *"}
                                    name="triggerValue"
                                    className={classes.textField}
                                    value={details.triggerValue ?? ""}
                                    onChange={(
                                      e: React.FocusEvent<HTMLInputElement>
                                    ) => {
                                      handleFieldChange(
                                        "triggerValue",
                                        e.target.value
                                      );
                                    }}
                                    inputRef={register({
                                      validate: {
                                        conditionalRequired: (value) =>
                                          (details?.triggerUnitTypeId &&
                                            details?.triggerUnitTypeId == 7) || !!value ||
                                          "Trigger Unit Value is required.",
                                      },
                                    })}
                                    error={errors.triggerValue ? true : false}
                                    helperText={
                                      errors.triggerValue
                                        ? errors.triggerValue.message
                                        : ""
                                    }
                                  />
                                </Grid>

                                <Grid item md={8} sm={6} xs={12}>
                                  <TriggerUnitTypeIdAutoComplete
                                    disabled={true}
                                    id="triggerUnitTypeId"
                                    label="Trigger Unit Type*"
                                    name="triggerUnitTypeId"
                                    valueFilter={
                                      details?.triggerUnitTypeId
                                        ? {
                                            //key: "triggerUnitTypeId",
                                            key: "value",
                                            values: [
                                              details?.triggerUnitTypeId,
                                            ],
                                          }
                                        : undefined
                                    }
                                    filterHandle={(v: any) => {
                                      handleFieldOnChange(
                                        "triggerUnitTypeId",
                                        v ? v.value : null
                                      );
                                    }}
                                    error={
                                      errors.triggerUnitTypeId ? true : false
                                    }
                                    helperText={
                                      errors.triggerUnitTypeId
                                        ? errors.triggerUnitTypeId.message
                                        : ""
                                    }
                                    inputRef={register({
                                      required:
                                        "Trigger Unit Type is required.",
                                    })}
                                  />
                                </Grid>

                                <Grid item md={8} sm={6} xs={12}>
                                  <TriggerOffsetTypeIdAutoComplete
                                    disabled={true}
                                    id="triggerOffsetTypeId"
                                    label="Trigger Offset Type*"
                                    name="triggerOffsetTypeId"
                                    unitTypeId={details.triggerUnitTypeId}
                                    valueFilter={
                                      details?.triggerOffsetTypeId
                                        ? {
                                            key: "value",
                                            values: [
                                              details?.triggerOffsetTypeId,
                                            ],
                                          }
                                        : undefined
                                    }
                                    filterHandle={(v: any) => {
                                      handleFieldOnChange(
                                        "triggerOffsetTypeId",
                                        v ? v.value : null
                                      );
                                    }}
                                    error={
                                      errors.triggerOffsetTypeId ? true : false
                                    }
                                    helperText={
                                      errors.triggerOffsetTypeId
                                        ? errors.triggerOffsetTypeId.message
                                        : ""
                                    }
                                    inputRef={register({
                                      required:
                                        "Trigger Offset Type is required.",
                                    })}
                                  />
                                </Grid>
                                <Grid item md={8} sm={6} xs={12}>
                                  <QualitativeMinimumTypeIdAutoComplete
                                    id="qualitativeMinimumTypeId"
                                    label="Grade Type*"
                                    name="qualitativeMinimumTypeId"
                                    valueFilter={
                                      details?.qualitativeMinimumTypeId
                                        ? {
                                            key: "value",
                                            values: [
                                              details?.qualitativeMinimumTypeId,
                                            ],
                                          }
                                        : undefined
                                    }
                                    filterHandle={(v: any) => {
                                      handleFieldOnChange(
                                        "qualitativeMinimumTypeId",
                                        v ? v.value : null
                                      );
                                    }}
                                    error={
                                      errors.qualitativeMinimumTypeId
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      errors.qualitativeMinimumTypeId
                                        ? errors.qualitativeMinimumTypeId
                                            .message
                                        : ""
                                    }
                                    inputRef={register({
                                      required: "Grade Type is required.",
                                    })}
                                  />
                                </Grid>

                                <Grid item md={4} sm={6} xs={12}>
                                  <ThemeTextField
                                    type={InputType.TEXT}
                                    key="qualitativeMinimumValue"
                                    id="qualitativeMinimumValue"
                                    label="Required Average/GPA"
                                    name="qualitativeMinimumValue"
                                    className={classes.textField}
                                    value={
                                      details.qualitativeMinimumValue ?? ""
                                    }
                                    onChange={(
                                      e: React.FocusEvent<HTMLInputElement>
                                    ) => {
                                      handleFieldChange(
                                        "qualitativeMinimumValue",
                                        e.target.value
                                      );
                                    }}
                                    required
                                    inputRef={register({
                                      required:
                                        "Required Average/GPA is required.",
                                    })}
                                    error={
                                      errors.qualitativeMinimumValue
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      errors.qualitativeMinimumValue
                                        ? errors.qualitativeMinimumValue.message
                                        : ""
                                    }
                                  />
                                </Grid>
                                <Grid item md={8} sm={6} xs={12}>
                                  <ThemeTextField
                                    type={InputType.TEXT}
                                    key="quantitativeMinimumValue"
                                    id="quantitativeMinimumValue"
                                    className={classes.textField}
                                    label={
                                      details.triggerUnitTypeId === 3
                                        ? "Pace of Completion"
                                        : "Required Attendance %"
                                    }
                                    //label="Required %"
                                    name="quantitativeMinimumValue"
                                    value={
                                      details.quantitativeMinimumValue ?? ""
                                    }
                                    onChange={(
                                      e: React.FocusEvent<HTMLInputElement>
                                    ) => {
                                      handleFieldChange(
                                        "quantitativeMinimumValue",
                                        e.target.value
                                      );
                                    }}
                                    required
                                    inputRef={register({
                                      required: "Required % is required.",
                                    })}
                                    error={
                                      errors.quantitativeMinimumValue
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      errors.quantitativeMinimumValue
                                        ? errors.quantitativeMinimumValue
                                            .message
                                        : ""
                                    }
                                  />
                                </Grid>

                                <Grid item md={4} sm={6} xs={12}>
                                  {details.triggerUnitTypeId === 3 ? (
                                    <QuantitativeMinimumUnitTypeIdCredAttAutoComplete
                                      id="quantitativeMinimumUnitTypeId"
                                      //label="Attendance Unit Type*"
                                      label={
                                        " "
                                        // details.triggerUnitTypeId === 3
                                        //   ? " "
                                        //   : "Attendance Unit Type*"
                                      }
                                      //options={1}
                                      name="quantitativeMinimumUnitTypeId"
                                      valueFilter={
                                        details?.quantitativeMinimumUnitTypeId
                                          ? {
                                              key: "value",
                                              values: [
                                                details?.quantitativeMinimumUnitTypeId,
                                              ],
                                            }
                                          : undefined
                                      }
                                      filterHandle={(v: any) => {
                                        handleFieldOnChange(
                                          "quantitativeMinimumUnitTypeId",
                                          v ? v.value : null
                                        );
                                      }}
                                      error={
                                        errors.quantitativeMinimumUnitTypeId
                                          ? true
                                          : false
                                      }
                                      helperText={
                                        errors.quantitativeMinimumUnitTypeId
                                          ? errors.quantitativeMinimumUnitTypeId
                                              .message
                                          : ""
                                      }
                                      inputRef={register({
                                        required:
                                          "Attendance Unit Type is required.",
                                      })}
                                    />
                                  ) : (
                                    <QuantitativeMinimumUnitTypeIdAutoComplete
                                      id="quantitativeMinimumUnitTypeId"
                                      //label="Attendance Unit Type*"
                                      label={
                                        "Attendance Unit Type*"
                                        // details.triggerUnitTypeId === 3
                                        //   ? " "
                                        //   : "Attendance Unit Type*"
                                      }
                                      //options={1}
                                      name="quantitativeMinimumUnitTypeId"
                                      valueFilter={
                                        details?.quantitativeMinimumUnitTypeId
                                          ? {
                                              key: "value",
                                              values: [
                                                details?.quantitativeMinimumUnitTypeId,
                                              ],
                                            }
                                          : undefined
                                      }
                                      filterHandle={(v: any) => {
                                        handleFieldOnChange(
                                          "quantitativeMinimumUnitTypeId",
                                          v ? v.value : null
                                        );
                                      }}
                                      error={
                                        errors.quantitativeMinimumUnitTypeId
                                          ? true
                                          : false
                                      }
                                      helperText={
                                        errors.quantitativeMinimumUnitTypeId
                                          ? errors.quantitativeMinimumUnitTypeId
                                              .message
                                          : ""
                                      }
                                      inputRef={register({
                                        required:
                                          "Attendance Unit Type is required.",
                                      })}
                                    />
                                  )}
                                </Grid>

                                <Grid item md={8} sm={6} xs={12}>
                                  <ThemeTextField
                                    type={InputType.TEXT}
                                    key="minimumAttendanceValue"
                                    id="minimumAttendanceValue"
                                    label="Minimum Attendance Value"
                                    name="minimumAttendanceValue"
                                    className={classes.textField}
                                    value={details.minimumAttendanceValue ?? ""}
                                    onChange={(
                                      e: React.FocusEvent<HTMLInputElement>
                                    ) => {
                                      handleFieldChange(
                                        "minimumAttendanceValue",
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item md={8} sm={6} xs={12}>
                                  <ConseqTypIdAutoComplete
                                    id="conseqTypId"
                                    label="Consequence of not meeting Requirements*"
                                    name="conseqTypId"
                                    valueFilter={
                                      details?.conseqTypId
                                        ? {
                                            key: "value",
                                            values: [details?.conseqTypId],
                                          }
                                        : undefined
                                    }
                                    filterHandle={(v: any) => {
                                      handleFieldOnChange(
                                        "conseqTypId",
                                        v ? v.value : null
                                      );
                                    }}
                                    error={errors.conseqTypId ? true : false}
                                    helperText={
                                      errors.conseqTypId
                                        ? errors.conseqTypId.message
                                        : ""
                                    }
                                    inputRef={register({
                                      required:
                                        "Consequence of not meeting Requirements is required.",
                                    })}
                                  />
                                </Grid>
                              </Grid>
                            </Fragment>
                          )}
                        </form>
                      </CardContent>
                      <CardActions>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={handleSubmit(
                            details.id === EmptyGuid ? onSave3 : onSaveExist3
                          )}
                          disabled={
                            savingTab2 ||
                            deletingTab2 ||
                            loadingDetailsTab2 ||
                            savingExistTab2
                          }
                        >
                          {savingTab2 ||
                          deletingTab2 ||
                          loadingDetailsTab2 ||
                          savingExistTab2 ? (
                            <CircularProgress size={24} color="primary" />
                          ) : (
                            "SAVE"
                          )}
                        </Button>
                        <Button
                          disabled={
                            savingTab2 ||
                            deletingTab2 ||
                            loadingDetailsTab2 ||
                            savingExistTab2
                          }
                          color="secondary"
                          variant="contained"
                          onClick={(e) => {
                            if (!dirty) {
                              resetDetails();
                              setDetails({
                                ...details,
                                triggerValue: 0,
                                triggerOffsetSequence: 0,
                                qualitativeMinimumValue: 0,
                                quantitativeMinimumValue: 0,
                                quantitativeMinimumUnitTypeId: undefined,
                                qualitativeMinimumTypeId: undefined,
                                minimumAttendanceValue: undefined,
                                conseqTypId: undefined,
                                sapDetailId: undefined,
                                sapdetailId: undefined,
                              });
                              reset({});
                              setDirty(false);
                            } else {
                              setPopMessage(
                                "You have unsaved changes. Are you sure you want to proceed?"
                              );
                              setPopAnchor(e.currentTarget);
                              setPopConfirmAction(() => () => {
                                resetDetails();
                                setDetails({
                                  id: EmptyGuid,
                                });
                                reset({});
                                setDirty(false);
                              });
                            }
                          }}
                        >
                          NEW
                        </Button>
                        {details?.id && details?.id !== EmptyGuid && (
                          <Button
                            className={classes.buttonColorTertiary}
                            disabled={
                              savingTab2 ||
                              deletingTab2 ||
                              loadingDetailsTab2 ||
                              savingExist
                            }
                            color="secondary"
                            variant="contained"
                            onClick={(e) => {
                              setPopMessage(
                                "Are you sure you want to delete this policy?"
                              );
                              setPopAnchor(e.currentTarget);
                              setPopConfirmAction(() => () => {
                                callDeleteTab2(
                                  fetchedDetailsTab2?.sapdetailId?.toString() ||
                                    EmptyGuid
                                );
                              });
                            }}
                          >
                            DELETE
                          </Button>
                        )}
                      </CardActions>
                    </TabPanel>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog"
        open={confirmationDialogue.open}
        onClose={() => {
          setConfirmationDialogue({ open: false });
          confirmationDialogue.onCancel && confirmationDialogue.onCancel();
        }}
      >
        <DialogContent dividers>
          <Typography>{confirmationDialogue.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              confirmationDialogue.onOk && confirmationDialogue.onOk();
              setConfirmationDialogue({ open: false });
            }}
            color="primary"
          >
            Confirm
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setConfirmationDialogue({ open: false });
              confirmationDialogue.onCancel && confirmationDialogue.onCancel();
            }}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Popover
        anchorEl={popAnchor}
        open={Boolean(popAnchor)}
        onClose={() => {
          setPopAnchor(null);
          setPopMessage("");
          setPopConfirmAction(undefined);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card>
          <CardContent>
            <Typography variant="h6">{popMessage}</Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={() => {
                if (popConfirmAction) {
                  popConfirmAction();
                }
                setPopAnchor(null);
                setPopMessage("");
                setPopConfirmAction(undefined);
              }}
            >
              Confirm
            </Button>{" "}
            <Button
              size="small"
              color="secondary"
              variant="contained"
              onClick={() => {
                setPopAnchor(null);
                setPopMessage("");
                setPopConfirmAction(undefined);
              }}
            >
              CANCEL
            </Button>
          </CardActions>
        </Card>
      </Popover>
    </React.Fragment>
  );
};

export default StudentInfo2;
