import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Tab,
  Tabs,
  Box,
  Divider,
  Grid,
  Card,
  CardContent,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  IconButton,
  TextField,
  FormControlLabel,
  Switch,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import * as PropTypes from "prop-types";
import ClassSummary from "./ClassSummary";
import GradeBookSummary from "./ClassSummary";
import { Block } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import SortIcon from "@material-ui/icons/Sort";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import * as classApi from "../../../../api/setup/creditHour/classApi";
import { useSelector } from "react-redux";
import IInstructorClassDetails from "../../../../interfaces/setup/creditHour/IInstructorClassDetails";
import CreditCourseGradeBook from "../../creditHour/courses/CreditCourseGradeBook";
import { CustomSnackBarProps } from "../../../../interfaces/common/CustomSnackBarProps";
import CustomSnackbar from "../../../../components/notifications/CustomSnackbar";
import ICreditCourseDetails from "../../../../interfaces/setup/academics/ProgramDefinition/ICreditCourseDetails";
import { EmptyGuid } from "../../../../utils/constants";
import { system } from '../../../../constants/system/systemConstants';
import PostGradesByInstructorClass from "./PostGradesByInstructorClass";
import PostCreditHourAttendance from "../../../attendance/PostCreditHourAttendance";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: "0px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      minHeight: "100%",
      height: "100%",
      width: "100%",
      margin: "auto",
    },
    paperRoot: {
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
    },
    inner: {
      height: "100%",
      width: "100%",
      margin: "0 auto",
      minHeight: "100%",
      maxWidth: 1150,
    },
    fixed: {
      position: "sticky",
      top: "0",
      width: "100%",
      marginBottom: "0.5em",
    },
    divider: {
      backgroundColor: theme.palette.site.primary,
      marginBottom: theme.spacing(1),
    },
    tabController: {
      backgroundColor: "transparent",
      textTransform: "uppercase",
    },
    tabIndicator: {
      backgroundColor: theme.palette.primary.contrastNavTabColor,
    },
    tabSelected: { fontWeight: "bold" },
    right: {
      float: "right",
    },
    courseSelectionCell: {
      color: theme.palette.paperSummarySchedulerTitle,
      cursor: "pointer",
      fontWeight: 500,
    },
    courseSelectedCell: {
      backgroundColor: theme.palette.site.secondary,
      cursor: "pointer",
      fontWeight: 500,
    },
    sortIcon: {
      fontSize: 15,
      marginLeft: 10,
    },
    hiddenElement: {
      visibility: "hidden",
    },
    classLabel: {
      fontWeight: "bold",
    },
    sortDesc: {
      transform: "rotate(180deg)",
    },
  })
);

function InstructorSummary() {
  const classes = useStyles();

  const [classesList, setClassesList] = React.useState<
    IInstructorClassDetails[]
  >([]);

  const [selectedClass, setSelectedClasses] = React.useState<
    IInstructorClassDetails | undefined
  >(undefined);
  const [searchText, setSearchText] = React.useState<string>("");
  const [sortOn, setSortOn] = React.useState<string>("startDate");
  const [sortOrder, setSortOrder] = React.useState<string>("DESC");
  const [showAll, setShowAll] = React.useState<boolean>(false);

  const [value, setValue] = React.useState(0);
  const handleChange = (_index: any, newValue: any) => {
    setValue(newValue);
  };

  const userId = useSelector((state: any) => state.session.user.userId);

  const appSettings = useSelector((state: any) => {
		return state.settings.settings;
	});
  const isGradeBookEnabled = (appSettings[system.settings.allowInstructorGradebook] as string || 'false').toLowerCase() === 'true';

  var startDate = new Date();
  var endDate = new Date();
  endDate.setFullYear(endDate.getFullYear() + 100);
  var baseModel: ICreditCourseDetails = {
    courseId: EmptyGuid,
    name: "",
    activeStartDate: startDate,
    activeEndDate: endDate,
  };
  const [model, setModel] = React.useState<ICreditCourseDetails>(baseModel);

  const [confirmationDialogue, setConfirmationDialogue] = React.useState<{
    onOk?: () => void;
    open: boolean;
    message?: string;
    onCancel?: () => void;
  }>({ open: false, message: "Do you want to save changes?" });

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );
  const [repeatedExamPolicySelected, selectRepeatedExamPolicy] =
    React.useState<string>();

  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const loadClasses = (instructorId: string) => {
    classApi
      .getClassesByInstructor(userSelectedCampus, instructorId, !showAll)
      .then((response: any) => {
        if (response) {
          let res: IInstructorClassDetails[] = response;
          res.map((c) => (c.visibility = true));
          setClassesList(res);
        }
      });
  };

  React.useEffect(() => {
    if (userId && userSelectedCampus) {
      loadClasses(userId);
    }
  }, [showAll, userId, userSelectedCampus]);

  const handleSearch = (searchText: string) => {
    setSearchText(searchText);
    if (classesList) {
      let filteredClasses = classesList?.map((en) => {
        if (en.className.toLowerCase().includes(searchText.toLowerCase())) {
          return { ...en, visibility: true };
        } else return { ...en, visibility: false };
      });
      setClassesList(filteredClasses);
    }
  };
  const handleSort = (field: string) => {
    setSortOn(field);
    sortOrder == "ASC" ? setSortOrder("DESC") : setSortOrder("ASC");
  };

  const handelSetSelectedClasses = (cls: IInstructorClassDetails) => {
    setSelectedClasses(cls);
    setModel({ ...model, name: cls?.className, courseId: cls.courseId });
  };

  return (
    <div className={classes.root}>
      <div className={classes.paperRoot}>
        <CustomSnackbar
          variant={snackBarProps.variant}
          message={snackBarProps.messageInfo}
          open={snackBarProps.showSnackBar}
          onClose={() => {
            setSnackBarProps((props: any) => {
              return { ...props, showSnackBar: false };
            });
          }}
        ></CustomSnackbar>
        <Card>
          <CardContent>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12} sm={2} md={2}>
                <TableContainer style={{ maxHeight: 400 }}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <div className={classes.right}>
                            <TextField
                              placeholder={"Search class"}
                              value={searchText}
                              onChange={(v: any) => {
                                handleSearch(v ? v.target.value : undefined);
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton>
                                      <SearchIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={showAll}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setShowAll(e.target.checked);
                                }}
                                name="checkedB"
                                color="primary"
                              />
                            }
                            label="Show All"
                          />
                          <IconButton
                            onClick={() => {
                              const newSort =
                                sortOrder === "ASC" ? "DESC" : "ASC";
                              handleSort("startDate");
                            }}
                          >
                            <SortIcon
                              className={
                                (sortOrder === "ASC" && classes.sortDesc) || ""
                              }
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {classesList
                        ?.filter((item) => item.visibility === true)
                        .slice()
                        .sort((a, b) => {
                          const aValue =
                            a[sortOn as keyof IInstructorClassDetails];
                          const bValue =
                            b[sortOn as keyof IInstructorClassDetails];

                          if (
                            typeof aValue === "string" &&
                            typeof bValue === "string"
                          ) {
                            if (sortOrder === "ASC") {
                              return aValue.localeCompare(bValue);
                            } else {
                              return bValue.localeCompare(aValue);
                            }
                          } else if (
                            typeof aValue === "boolean" &&
                            typeof bValue === "boolean"
                          ) {
                            return aValue === bValue ? 0 : aValue ? -1 : 1;
                          }

                          return 0;
                        })
                        .map((cls: IInstructorClassDetails, ix: any) => (
                          <TableRow>
                            <TableCell
                              key={`courseName-${ix}`}
                              className={
                                cls.instrClassTermId == selectedClass?.instrClassTermId
                                  ? classes.courseSelectedCell
                                  : classes.courseSelectionCell
                              }
                              onClick={(e: any) => {
                                handelSetSelectedClasses(cls);
                              }}
                            >
                              {cls.className}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={10} md={10}>
                <div style={{ width: 1050, margin: "auto" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    scrollButtons="auto"
                    variant="fullWidth"
                    classes={{
                      indicator: classes.tabIndicator,
                    }}
                    className={classes.tabController}
                  >
                    <Tab
                      classes={{ selected: classes.tabSelected }}
                      label={"CLASS"}
                      {...a11yProps(0)}
                    />
                    <Tab
                      disabled={!isGradeBookEnabled}
                      classes={{ selected: classes.tabSelected }}
                      label={"GRADE BOOK"}
                      {...a11yProps(1)}
                    />
                    <Tab
                      classes={{ selected: classes.tabSelected }}
                      label={"CLASS GRADES"}
                      {...a11yProps(2)}
                    />
                    <Tab
                      classes={{ selected: classes.tabSelected }}
                      label={"CLASS ATTENDANCE"}
                      {...a11yProps(3)}
                    />
                  </Tabs>
                  <Divider className={classes.divider} />
                  <TabPanel value={value} index={0}>
                    <ClassSummary selectedClass={selectedClass} />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    {selectedClass?.courseId != null && (
                      <Grid
                        item
                        alignContent="flex-start"
                        alignItems="flex-start"
                        justify="flex-start"
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item xs={12}>
                            <CreditCourseGradeBook
                              setConfirmationDialog={setConfirmationDialogue}
                              showSnackBar={setSnackBarProps}
                              course={model}
                              userId={userId}
                              isInstructorGradeBook={true}
                              instrClassTermId={selectedClass.instrClassTermId}
                              campusId={userSelectedCampus}
                              courseId={selectedClass?.courseId}
                              repeatedExamPolicy={repeatedExamPolicySelected}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <PostGradesByInstructorClass classId={selectedClass?.instrClassTermId ?? ''} className={selectedClass?.className ?? ''} />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <div>
                      <PostCreditHourAttendance classId={selectedClass?.instrClassTermId} />
                    </div>
                  </TabPanel>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          aria-labelledby="confirmation-dialog"
          open={confirmationDialogue.open}
          onClose={() => {
            setConfirmationDialogue({ open: false });
            confirmationDialogue.onCancel && confirmationDialogue.onCancel();
          }}
        >
          <DialogContent dividers>
            <Typography>{confirmationDialogue.message}</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                confirmationDialogue.onOk && confirmationDialogue.onOk();
                setConfirmationDialogue({ open: false });
              }}
              color="primary"
            >
              Confirm
            </Button>
            <Button
              autoFocus
              onClick={() => {
                setConfirmationDialogue({ open: false });
                confirmationDialogue.onCancel &&
                  confirmationDialogue.onCancel();
              }}
              color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
export default InstructorSummary;
