import palette from "../mainPalette";

export default {
	root: {
		color: palette.text.primary
	},
	input: {
		"&::placeholder": {
			opacity: 1,
			color: palette.text.secondary
		}
	}
};
