import API from '../apiWrapper';

export const PushStudentsUpdatesToKlassApp = (studentEnrollmentId: string) => {
    return API()
        .get('/AcademicRecords/KlassApp/PushStudentsUpdatesToKlassApp', {
            params: {
                studentEnrollmentId

            }
        })
        .then(
            (res: any) => {
                if (res) {
                    return res.data;
                }
                return false
            },
            (error: any) => {
                return false;
            }
        );
};

export const GetKlassAppConfigSettings = (campusId: string) => {
    return API()
        .get('/AcademicRecords/KlassApp/GetKlassAppConfigSettings', {
            params: {
                campusId

            }
        })
        .then(
            (res: any) => {
                if (res) {
                    return res.data;
                }
                return false
            },
            (error: any) => {
                return false;
            }
        );
};