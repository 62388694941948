/* eslint-disable react/display-name */
import * as React from "react";
import { useState, forwardRef, CSSProperties, useRef } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { ListItem, Button, Icon, Collapse, colors, List, Popover } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { mapIcons } from "../../../_Layout/NavBar/navigationConfig";
import { isThisHour } from "date-fns";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { hr } from "date-fns/locale";

type INavigationListItemProps = {
	children?: React.ReactNode;
	className?: string;
	depth: number;
	href?: string;
	svgIcon?: JSX.Element;
	icon?: any;
	label?: any;
	open?: boolean;
	title?: string;
	minimized?: boolean;
	pages?: Array<any>;
};

type INavigationListItemState = {
	open: boolean;
};
const CustomRouterLink = forwardRef((props: any, ref: React.Ref<HTMLDivElement>) => (
	<div ref={ref} style={{ flexGrow: 1 }}>
		<RouterLink to={props.href ? props.href : "/"} {...props} />
	</div>
));

const useStyles = makeStyles((theme: any) =>
	createStyles({
		item: {
			display: "block",
			paddingTop: 0,
			paddingBottom: 0
		},
		itemLeaf: {
			display: "flex",
			paddingTop: 0,
			paddingBottom: 0
		},
		itemLeafMinimized: {
			display: "flex",
			paddingTop: 10,
			paddingBottom: 0
		},
		button: {
			color: colors.blueGrey[800],
			padding: "10px 8px",
			justifyContent: "flex-start",
			textTransform: "none",
			letterSpacing: 0,
			width: "100%"
		},
		buttonLeaf: {
			color: colors.blueGrey[800],
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingRight: theme.spacing(1),
			wordWrap: "break-word",
			justifyContent: "flex-start",
			textTransform: "none",
			letterSpacing: 0,
			width: "100%",
			fontWeight: theme.typography.fontWeightRegular,
			"&.depth-0": {
				fontWeight: theme.typography.fontWeightMedium
			}
		},
		icon: {
			color: theme.palette.icon.primary,
			display: "flex",
			alignItems: "center",
			marginRight: theme.spacing(1)
		},
		expandIcon: {
			marginLeft: "auto",
			height: 16,
			width: 16
		},
		label: {
			display: "flex",
			alignItems: "center",
			marginLeft: "auto"
		},
		active: {
			color: theme.palette.primary.contrastNavTabColor,
			fontWeight: theme.typography.fontWeightMedium,
			"& $icon": {
				color: theme.palette.primary.main
			}
		},
		buttonMinimized: {
			paddingLeft: "0px",
			paddingRight: "0px",
			minWidth: "56px"
		},
		listItemWithChildren: {
			"& $a": {
				paddingLeft: theme.spacing(5)
			}
		},
		popoverButton: {
			width: "100%",
			textTransform: "none",
			justifyContent: "left",
			paddingLeft: theme.spacing(3)
		},
		minimizedButton: {
			marginLeft: theme.spacing(0.6)
		},
		minimizedButtonWithLinks: {
			marginLeft: theme.spacing(0.5)
		},
		subMenuItem:{
			paddingLeft:theme.spacing(2)
		}
	})
);

const NestedList = (props: INavigationListItemProps) => {
	const classes = useStyles();

	const { title, depth, children, icon: Icon, open: openProp, className, ...rest } = props;

	const [open, setOpen] = useState(openProp);
	const handleToggle = () => {
		setOpen(open => !open);
	};

	return (
		<List>
			{children &&
				Array.isArray(children) &&
				children.reduce(
					(items, page) =>
						reduceChildMenu({
							items,
							page,
							classes,
							depth,
							className,
							handleToggle,
							open,
							...rest
						}),
					[]
				)}
		</List>
	);
};

const reduceChildMenu = (props: any) => {
	const { router, items, page, depth, classes, className, handleToggle, open } = props;

	if (page.menuTypeId <= 6) {
		if (!page.children) {
			items.push(
				<Button
					activeClassName={classes.active}
					className={clsx(classes.buttonMinimized, `depth-${depth}`, classes.popoverButton)}
					component={CustomRouterLink}
					exact
					to={page.href}>
					{page.title}
				</Button>
			);
		} else {
			items.push(
				<ListItem
					className={clsx(classes.item, className, classes.listItemWithChildren)}
					disableGutters>
					<Button
						className={clsx(classes.buttonMinimized, `depth-${depth}`, classes.popoverButton)}
						onClick={handleToggle}>
						{page.title}
						{open ? (
							<ExpandLessIcon className={classes.expandIcon} color='inherit' />
						) : (
							<ExpandMoreIcon className={classes.expandIcon} color='inherit' />
						)}
					</Button>
					<Collapse in={open}>
						<NestedList
							children={page.children}
							title={page.title}
							depth={depth}
							className={className}
							open={open}
						/>
					</Collapse>
				</ListItem>
			);
		}
	}
	return items;
};

const NavigationListItem = (props: INavigationListItemProps) => {
	const {
		title,
		href,
		depth,
		children,
		icon: Icon,
		className,
		open: openProp,
		label: Label,
		minimized,
		...rest
	} = props;

	const IconElement = mapIcons(Icon);
	const classes = useStyles();
	const [open, setOpen] = useState(openProp);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const popOverRef: any = useRef(null);

	const openPop = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleToggle = () => {
		setOpen(open => !open);
	};

	let paddingLeft = 8;

	if (depth > 0) {
		paddingLeft = 32 + 8 * depth;
	}

	const style = {
		paddingLeft
	};
	if (minimized && !children) {
		return (
			<ListItem {...rest} className={clsx(classes.item, className)} disableGutters>
				<Button
					activeClassName={classes.active}
					className={clsx(classes.button, classes.minimizedButton)}
					component={CustomRouterLink}
					exact
					to={href}>
					{IconElement && <IconElement className={classes.icon} />}
					{Label && (
						<span className={classes.label}>
							<Label />
						</span>
					)}
				</Button>
			</ListItem>
		);
	} else if (minimized && children) {
		return (
			<ListItem {...rest} className={clsx(classes.item, className)} disableGutters>
				<Button
					className={clsx(classes.button, classes.minimizedButtonWithLinks)}
					onClick={handleClick}
					style={style}>
					{IconElement && <IconElement className={classes.icon} />}
				</Button>
				<Popover
					open={openPop}
					onClick={handleClose}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center"
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "center"
					}}
					PaperProps={{
						style: {
							width: "220px"
						}
					}}>
					<NestedList
						children={children}
						title={title}
						depth={depth}
						className={className}
						open={open}></NestedList>
				</Popover>
			</ListItem>
		);
	} else if (children) {
		return (
			<ListItem
				{...rest}
				className={clsx(classes.item, className, classes.listItemWithChildren)}
				disableGutters>
				<Button className={classes.button} onClick={handleToggle} style={style}>
					{IconElement && <IconElement className={classes.icon} />}
					{title}
					{open ? (
						<ExpandLessIcon className={classes.expandIcon} color='inherit' />
					) : (
						<ExpandMoreIcon className={classes.expandIcon} color='inherit' />
					)}
				</Button>
				<Collapse in={open}>{children}</Collapse>
			</ListItem>
		);
	} else {
		return (
			<ListItem
				{...rest}
				className={clsx(
					{ [classes.itemLeaf]: !minimized },
					{ [classes.itemLeafMinimized]: minimized },
					(depth == 2 ? classes.subMenuItem : ""),
					className
				)}
				disableGutters>
				<Button
					activeClassName={classes.active}
					className={clsx(
						{ [classes.buttonLeaf]: !minimized, [classes.buttonMinimized]: minimized },
						`depth-${depth}`
					)}
					component={CustomRouterLink}
					exact
					to={href}>
					{IconElement && <IconElement className={classes.icon} />}
					{!minimized && title}
					{Label && (
						<span className={classes.label}>
							<Label />
						</span>
					)}
				</Button>
			</ListItem>
		);
	}
};
NavigationListItem.defaultProps = {
	depth: 0,
	open: false
};

export default NavigationListItem;
