import * as React from "react";
import {
  makeStyles,
  createStyles,
  Grid,
  Paper,
  Table,
  withStyles,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  CircularProgress,
  IconButton,
  Button,
  Card,
  CardContent,
  TextField,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Divider,
  Checkbox,
  FormControlLabel,
  DialogTitle,
} from "@material-ui/core";
import moment from "moment";
import InputField from "../../components/_Layout/Inputs/InputField";
import { EmptyGuid } from "../../utils/constants";
import { CustomSnackBarProps } from "../../interfaces/common/CustomSnackBarProps";
import ProgressSaveButton from "../../components/_Layout/Buttons/ProgressSaveButton";
import IClassDetails from "../../interfaces/setup/creditHour/IClassDetails";
import IClassSearchParam from "../../interfaces/setup/creditHour/IClassSearchParam";
import CustomSnackbar from "../../components/notifications/CustomSnackbar";
import CustomCardTitle from "../../interfaces/common/card/CustomCardTitle";
import * as ProgramVersionApi from "../../api/setup/academics/programCatalogApi";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { FormContext, useForm, FieldError } from "react-hook-form";
import CampusForCurrentUserAutoComplete from "../../components/AutoComplete/CampusForCurrentUserAutoComplete";
import ProgramsAutoComplete from "../../components/AutoComplete/ProgramsAutoComplete";
import ProgramVersionAutoComplete from "../../components/AutoComplete/ProgramVersionAutoComplete";
import EnrollmentStatusAutoComplete from "../../components/AutoComplete/EnrollmentStatusAutoComplete";
import FundSourcesAutoComplete from "../../components/AutoComplete/FundSourcesAutoComplete";
import TransactionCodeAutoComplete from "../../components/AutoComplete/TransactionCodeAutoComplete";
import { InputType } from "../../constants/uiConstants/inputConstants";
import { useSelector } from 'react-redux';
import { system } from '../../constants/system/systemConstants';
import {
  ISearchTitleIVAwardsParams,
  IPostTitleIVAwardsResults,
} from "../../interfaces/student/financials/IDisbursement";
import { IFinancialAidDisbursement } from "../../interfaces/student/financials/IFinancialAidDisbursement";
import * as disbursementDetailsApi from "../../api/student/studentAccounts/disbursementDetailsApi";
import * as ledgerApi from "../../api/student/studentAccounts/ledgerApi";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Laptop } from "@material-ui/icons";

export const ConvertCurrencyValueToInput = (value: any) => {
  return Number((value + "").replace("$ ", "").replace(/,/g, ""));
};

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      fontWeight: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
      height: "100%",
    },
    saveButton: {
      width: 90,
      marginRight: 10,
    },
    tableContainer: {
      maxHeight: "400px",
    },
    table: {
      width: "100%",
    },
    iconColor: {
      color: theme.palette.black,
    },
    searchField: {
      "&::placeholder": {
        color: theme.palette.black,
      },
    },
    preLoaderCell: {
      textAlign: "center",
      padding: theme.spacing(1),
    },
    ScheduleSelectionCell: {
      color: theme.palette.paperSummarySchedulerTitle,
      cursor: "pointer",
      fontWeight: 500,
    },
    cardMain: {
      margin: "auto",
      marginTop: theme.spacing(2),
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    copyButton: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      width: 170,
    },
    tableEmptyRow: {
      height: "350px",
      textAlign: "center",
      fontSize: 20,
      opacity: 0.3,
    },
    datePickerField: {
      width: "100%",
      background: "white",
      borderRadius: theme.spacing(0.5),
      "& .MuiInputBase-root": {
        "& input": {
          width: "100%",
        },
      },
    },
    sortIcon: {
      fontSize: 15,
      marginLeft: 10,
    },
    hiddenElement: {
      visibility: "hidden",
    },
    right: {
      float: "right",
    },
    buttonSection: {
      marginBottom: theme.spacing(2),
    },
    dialogHeader: {
      backgroundColor: theme.palette.site.secondary,
      "& .MuiTypography-root": {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: "12pt",
      },
    },
    typography: {
      whiteSpace: "pre-line",
    },
    customTable: {
      "& .MuiTableCell-sizeSmall": {
        padding: "0px 0px 6px 10px",
      },
    },
  })
);

const NonTitleIVPostPage = () => {
  const [updating, setUpdating] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState<string>("");
  const [defaultDate, setDefaultDate] = React.useState<Date | null>(null);
  const data = useForm<any>({ mode: "onBlur" });
  const [model, setModel] = React.useState<ISearchTitleIVAwardsParams>({
    campusId: "",
    programId: "",
    progVerId: null,
    fundSourceId: "",
    statusId: "",
    disbursementNumber: "",
    hoursToGraduation: null,
  });
  const { handleSubmit } = data;

  const classes = useStyles({});
  const [confirmationPopupState, setConfirmationPopupState] =
    React.useState<any>({
      id: "simple-popup",
      message: "",
    });

  var baseSearch: ISearchTitleIVAwardsParams = {};
  const [searchModel, setSearchModel] =
    React.useState<ISearchTitleIVAwardsParams>(baseSearch);
  const [awards, setAwards] = React.useState<IPostTitleIVAwardsResults[]>([]);
  const [awardsCopy, setAwardsCopy] = React.useState<
    IPostTitleIVAwardsResults[]
  >([]);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);

  const [sortOn, setSortOn] = React.useState<string>("studentNumber");
  const [sortOrder, setSortOrder] = React.useState<string>("ASC");
  const [transCodeId, setTransCodeId] = React.useState<string>("");

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );

  function filterAwardsResults(results: IPostTitleIVAwardsResults[]) {
    const filteredResults = results.filter((f) => {
      return (
        f.selected === true &&
        f.disbursementDate !== null &&
        f.amount > 0 &&
        moment(f.disbursementDate).isValid() === true &&
        new Date(f.disbursementDate).setHours(0, 0, 0, 0) >=
          new Date("01/01/1900").setHours(0, 0, 0, 0) &&
        new Date(f.disbursementDate).setHours(0, 0, 0, 0) <=
          new Date("12/12/2099").setHours(0, 0, 0, 0)
      );
    });

    return filteredResults;
  }

  const onPost = async (isPreview: any) => {
    if (awards && filterAwardsResults(awards).length > 0) setDialogOpen(true);
  };

  const handleConfirm = () => {
    let disList: IFinancialAidDisbursement[] = [];
    filterAwardsResults(awards)?.map(function (
      dis: IPostTitleIVAwardsResults,
      ix: any
    ) {
      let disbursement: IFinancialAidDisbursement = {
        transactionCodeId: transCodeId,
        transactionDate: new Date(dis.disbursementDate).toLocaleDateString(),
        fundSourceId: dis.fundSourceId,
        awardId: dis.awardId,
        disbursementId: dis.awardScheduleId,
        documentId: "",
        amount: dis.amount,
        isRefund: false,
        transactionId: EmptyGuid,
        studentEnrollmentId: dis.studentEnrollmentId,
        creditDebit: 2,
      };
      disList.push(disbursement);
    });

    if (disList.length > 0) {
      setUpdating(true);
      ledgerApi.processFinancialAidDisbursementList(disList).then(
        (response: any) => {
          if (response.result === true) {
            onSearch();
            setSnackBarProps(() => {
              return {
                variant: "success",
                showSnackBar: true,
                messageInfo: "Disbursement posted successfully.",
              };
            });
            setDialogOpen(false);
          } else {
            setSnackBarProps(() => {
              return {
                variant: "error",
                showSnackBar: true,
                messageInfo: response.data.resultStatusMessage,
              };
            });
          }
          setUpdating(false);
        },
        (exception: any) => {
          setSnackBarProps(() => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo: exception,
            };
          });
          setUpdating(false);
        }
      );
    }
  };

  const onSearch = async () => {
    const validation = await data.triggerValidation();
    if (validation) {
      setUpdating(true);
      await disbursementDetailsApi.GetNonTitleIVAwardsForPost(model).then(
        (response: any) => {
          if (response) {
            setSortOn("studentNumber");
            setSortOrder("ASC");
            setSearchText("");
            selectAllClick(false);
            setAwards(response);
            setAwardsCopy(response);
            setUpdating(false);
          }          
          else
          {
            setUpdating(false);
          }
        },
        (exception: any) => {
          setSnackBarProps(() => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo: exception,
            };
          });
          setUpdating(false);
        }
      );
    }
  };

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    if (fieldId == "campusId") {
      (updatedModel as any)["fundSourceId"] = "";
      (updatedModel as any)["programId"] = "";
      (updatedModel as any)["progVerId"] = null;
    } else if (fieldId == "programId") {
      (updatedModel as any)["progVerId"] = null;
    }
    (updatedModel as any)[fieldId] = newValue;
    setModel({ ...updatedModel });
  };

  const setNonTitleIV = (Funds: any) => {
    let newFunds: string[] = [];
    if (Funds !== null) {
      if (Array.isArray(Funds)) {
        newFunds = Funds.map((gr: any) => gr.value);
      } else {
        newFunds.push(Funds.value);
      }
    }
    return newFunds;
  };

  const ThemeTextField = withStyles((theme: any) =>
    createStyles({
      root: {},
    })
  )(TextField);

  const handelDefaultDate = (dDate: Date) => {
    var date = moment(dDate);
    if (date.isValid()) {
      const updatedAwards = awards.map((cls) => {
        cls.disbursementDate = dDate;
        return cls;
      });
      setAwards(updatedAwards);
      setDefaultDate(dDate);
    }
  };

  const selectAllClick = (checked: boolean) => {
    const updatedAwards = awards.map((cls) => {
      cls.selected = checked;
      return cls;
    });
    setAwards(updatedAwards);
  };

  const selectClass = (checked: boolean, awardScheduleId: string, ix: number) => {
    let aw = [...awards];
    aw[ix].selected = checked;    
    setAwards([...aw]);
  };

  const changeDisbursementDate = (dDate: Date, awardScheduleId: string, ix: number) => {

    
    var date = moment(dDate);
    let aw = [...awards];
    aw[ix].disbursementDate = date.isValid() ? dDate : dDate;    
    setAwards([...aw]);
  };

  const isNumber = (value: string) => {
    return /^-?\d+$/.test(value);
  };

  const handelAmount = (dAmount: string, awardScheduleId: string, ix: number) => {
    let aw = [...awards];
    aw[ix].amount = Number(dAmount);
    setAwards([...aw]);
  };

  const handleSort = (field: string) => {
    setSortOn(field);
    sortOrder == "ASC" ? setSortOrder("DESC") : setSortOrder("ASC");
    setAwards(sortList(awards));
  };

  const handleTextFieldClick = (event: any) => {
    event.stopPropagation();
  };

  const handleSearch = (searchText: string) => {
    const filteredAwards = awardsCopy.filter(
      (cls) =>
        cls.studentNumber.toLowerCase().includes(searchText.toLowerCase()) ||
        cls.studentName.toLowerCase().includes(searchText.toLowerCase()) ||
        cls.programVersion.toLowerCase().includes(searchText.toLowerCase()) ||
        cls.award.toLowerCase().includes(searchText.toLowerCase()) ||
        (cls.disbursementDate &&
          new Date(cls.disbursementDate)
            .toLocaleDateString()
            .toLowerCase()
            .includes(searchText.toLowerCase())) ||
        cls.disbursementNumber
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase())
    );
    setAwards(filteredAwards);
    setSearchText(searchText);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const useDecimalFormat = true;
  const getDecimalValue = (value: any) => {
    let valstring = (value + "").replace("$ ", "").replace(/,/g, "");
    let valstr = valstring.split(".", 2);
    let ret = 0;
    if (valstr.length > 1) {
      ret = Number(valstr[1]);
    }
    if (!useDecimalFormat) {
      if (ret > 0) {
        return 0;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  };

  const sortList = (list : Array<IPostTitleIVAwardsResults>) => 
  {
    return list.sort((a, b) => {
      const aValue =
        a[
          sortOn as keyof IPostTitleIVAwardsResults
        ];
      const bValue =
        b[
          sortOn as keyof IPostTitleIVAwardsResults
        ];

      if (
        typeof aValue === "string" &&
        typeof bValue === "string"
      ) {
        if (sortOrder === "ASC") {
          return aValue.localeCompare(bValue);
        } else {
          return bValue.localeCompare(aValue);
        }
      } else if (
        typeof aValue === "boolean" &&
        typeof bValue === "boolean"
      ) {
        return aValue === bValue ? 0 : aValue ? -1 : 1;
      }

      return 0;
    })
  }

  return (
    <React.Fragment>
      <CustomSnackbar
        variant={snackBarProps.variant}
        message={snackBarProps.messageInfo}
        open={snackBarProps.showSnackBar}
        onClose={() => {
          setSnackBarProps((props: any) => {
            return { ...props, showSnackBar: false };
          });
        }}
      ></CustomSnackbar>

      <div className={classes.cardMain}>
        <Grid container direction="column" spacing={8} wrap="nowrap">
          <Grid
            item
            alignContent="flex-start"
            alignItems="flex-start"
            justify="flex-start"
          >
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Card square={true} elevation={2}>
                  <CustomCardTitle title="Awards Bulk Post"></CustomCardTitle>
                  <CardContent>
                    <FormContext {...data}>
                      <form onSubmit={handleSubmit(onSearch)}>
                        <Grid container direction="row" spacing={2}>
                          <Grid item xs={12} sm={4} md={4}>
                            <CampusForCurrentUserAutoComplete
                              id="campusId"
                              name="campusId"
                              label="Campus *"
                              params={{
                                fullWidth: true,
                              }}
                              filterHandle={(v: any) => {
                                handleFieldOnChange(
                                  "campusId",
                                  v ? v.value : undefined
                                );
                              }}
                              valueFilter={
                                model.campusId
                                  ? {
                                      key: "value",
                                      values: [model.campusId],
                                    }
                                  : undefined
                              }
                              error={!!data.errors.campusId}
                              inputRef={data.register({
                                required: true,
                              })}
                              helperText={
                                data.errors.campusId
                                  ? "Campus is required."
                                  : undefined
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <FundSourcesAutoComplete
                              campusId={model.campusId}
                              params={{
                                nonTitleIvLoanOnly: null,
                                afaFilter: true,
                                fullWidth: true,
                              }}
                              id="fundSourceId"
                              name="fundSourceId"
                              label="Fund Source *"
                              filterHandle={(v: any) => {
                                handleFieldOnChange(
                                  "fundSourceId",
                                  v ? v.value : undefined
                                );
                              }}
                              valueFilter={
                                model.fundSourceId
                                  ? {
                                      key: "value",
                                      values: [model.fundSourceId],
                                    }
                                  : undefined
                              }
                              error={!!data.errors.fundSourceId}
                              inputRef={data.register({
                                required: true,
                              })}
                              helperText={
                                data.errors.fundSourceId
                                  ? "Fund source is required."
                                  : undefined
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <EnrollmentStatusAutoComplete
                              id="statusId"
                              name="statusId"
                              label="Student Status"
                              params={{
                                fullWidth: true,
                              }}
                              filterHandle={(v: any) => {
                                handleFieldOnChange(
                                  "statusId",
                                  v ? v.value : undefined
                                );
                              }}
                              valueFilter={
                                model.statusId
                                  ? {
                                      key: "value",
                                      values: [model.statusId],
                                    }
                                  : undefined
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <ProgramsAutoComplete
                              label="Program"
                              campusId={model.campusId || EmptyGuid}
                              params={{
                                fullWidth: true,
                              }}
                              filterHandle={(v: any) => {
                                handleFieldOnChange(
                                  "programId",
                                  v ? v.value : undefined
                                );
                              }}
                              valueFilter={
                                model.programId
                                  ? {
                                      key: "value",
                                      values: [model.programId],
                                    }
                                  : undefined
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <ProgramVersionAutoComplete
                              id="progVerId"
                              name="progVerId"
                              label="Program Version"
                              showInactivePrograms={true}
                              params={{
                                fullWidth: true,
                              }}
                              valueFilter={
                                model.progVerId
                                  ? {
                                      key: "value",
                                      values: [model.progVerId],
                                    }
                                  : undefined
                              }
                              campusId={model.campusId}
                              programId={model.programId}
                              filterHandle={(v: any) => {
                                handleFieldOnChange(
                                  "progVerId",
                                  v ? v.value : undefined
                                );
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={4} md={4}>
                            <InputField
                              type={InputType.NUMBER}
                              id="disbursementNumber"
                              label="Disbursement Number"
                              name="disbursementNumber"
                              key="disbursementNumber"
                              defaultValue={model.disbursementNumber}
                              onBlur={(
                                v: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleFieldOnChange(
                                  "disbursementNumber",
                                  v.target.value
                                );
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={4} md={4}>
                            <InputField
                              type={InputType.NUMBER}
                              id="hoursToGraduation"
                              label="Hours To Graduation"
                              name="hoursToGraduation"
                              key="hoursToGraduation"   
                              decimal        
                              decimalPlaces={2}                   
                              defaultValue={model.hoursToGraduation}
                              inputRef={data.register({
                                required: false,
                                validate: {
                                  cannotBeGreaterThan: (value) =>
                                    Number(value) <= 9999999.00 ||
                                    "Hours cannot be greater than 9999999.00",
                                  cannotBeLess: (value) =>
                                    Number(value) >= 0 ||
                                    "Hours cannot less than 0.00",
                                },
                              })}
                              onBlur={(
                                v: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleFieldOnChange(
                                  "hoursToGraduation",
                                  v.target.value
                                );
                              }}
                              error={
                                !!data.errors.hoursToGraduation
                              }
                              helperText={
                                data.errors.hoursToGraduation
                                  ? (data.errors
                                    .hoursToGraduation as FieldError)
                                    .message
                                  : undefined
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={2}>
                          <Grid
                            item
                            alignContent="flex-start"
                            alignItems="flex-start"
                            justify="flex-start"
                          >
                            <ProgressSaveButton
                              text="Student Search"
                              onClick={async () => {
                                await onSearch();
                              }}
                              loading={updating}
                              buttonClassName={classes.copyButton}
                            ></ProgressSaveButton>
                          </Grid>
                        </Grid>
                        <Grid>
                          <TableContainer
                            component={Paper}
                            square
                            className={classes.tableContainer}
                          >
                            <Table
                              size="small"
                              aria-label="a dense table"
                              classes={{ root: classes.customTable }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell colSpan={5}>
                                    <b>
                                      Refunded or partially refunded awards
                                      won’t be displayed on the grid. Please use
                                      ledger page to post it.
                                    </b>
                                  </TableCell>
                                  <TableCell colSpan={3}>
                                    <div className={classes.right}>
                                      <TextField
                                        placeholder={"Search ..."}
                                        onClick={handleTextFieldClick}
                                        value={searchText}
                                        onChange={(v: any) => {
                                          handleSearch(
                                            v ? v.target.value : undefined
                                          );
                                        }}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconButton>
                                                <SearchIcon />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <FormControlLabel
                                      label="Student ID"
                                      onClick={() => {
                                        handleSort("studentNumber");
                                      }}
                                      control={
                                        sortOn == "studentNumber" ? (
                                          sortOrder == "ASC" ? (
                                            <ArrowUpward
                                              className={classes.sortIcon}
                                            />
                                          ) : (
                                            <ArrowDownward
                                              className={classes.sortIcon}
                                            />
                                          )
                                        ) : (
                                          <ArrowDownward
                                            className={classes.hiddenElement}
                                          />
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <FormControlLabel
                                      label="Student Name"
                                      onClick={() => {
                                        handleSort("studentName");
                                      }}
                                      control={
                                        sortOn == "studentName" ? (
                                          sortOrder == "ASC" ? (
                                            <ArrowUpward
                                              className={classes.sortIcon}
                                            />
                                          ) : (
                                            <ArrowDownward
                                              className={classes.sortIcon}
                                            />
                                          )
                                        ) : (
                                          <ArrowDownward
                                            className={classes.hiddenElement}
                                          />
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <FormControlLabel
                                      label="Program Version"
                                      onClick={() => {
                                        handleSort("programVersion");
                                      }}
                                      control={
                                        sortOn == "programVersion" ? (
                                          sortOrder == "ASC" ? (
                                            <ArrowUpward
                                              className={classes.sortIcon}
                                            />
                                          ) : (
                                            <ArrowDownward
                                              className={classes.sortIcon}
                                            />
                                          )
                                        ) : (
                                          <ArrowDownward
                                            className={classes.hiddenElement}
                                          />
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <FormControlLabel
                                      label="Award"
                                      onClick={() => {
                                        handleSort("award");
                                      }}
                                      control={
                                        sortOn == "award" ? (
                                          sortOrder == "ASC" ? (
                                            <ArrowUpward
                                              className={classes.sortIcon}
                                            />
                                          ) : (
                                            <ArrowDownward
                                              className={classes.sortIcon}
                                            />
                                          )
                                        ) : (
                                          <ArrowDownward
                                            className={classes.hiddenElement}
                                          />
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <FormControlLabel
                                      label="Disbursement Number"
                                      onClick={() => {
                                        handleSort("disbursementNumber");
                                      }}
                                      control={
                                        sortOn == "disbursementNumber" ? (
                                          sortOrder == "ASC" ? (
                                            <ArrowUpward
                                              className={classes.sortIcon}
                                            />
                                          ) : (
                                            <ArrowDownward
                                              className={classes.sortIcon}
                                            />
                                          )
                                        ) : (
                                          <ArrowDownward
                                            className={classes.hiddenElement}
                                          />
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <FormControlLabel
                                      label="Amount"
                                      onClick={() => {
                                        handleSort("amount");
                                      }}
                                      control={
                                        sortOn == "amount" ? (
                                          sortOrder == "ASC" ? (
                                            <ArrowUpward
                                              className={classes.sortIcon}
                                            />
                                          ) : (
                                            <ArrowDownward
                                              className={classes.sortIcon}
                                            />
                                          )
                                        ) : (
                                          <ArrowDownward
                                            className={classes.hiddenElement}
                                          />
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <label
                                      style={{
                                        width: 160,
                                        display: "inline-block",
                                      }}
                                    >
                                      <FormControlLabel
                                        label="Disbursement Date"
                                        onClick={() => {
                                          handleSort("disbursementDate");
                                        }}
                                        control={
                                          sortOn == "disbursementDate" ? (
                                            sortOrder == "ASC" ? (
                                              <ArrowUpward
                                                className={classes.sortIcon}
                                              />
                                            ) : (
                                              <ArrowDownward
                                                className={classes.sortIcon}
                                              />
                                            )
                                          ) : (
                                            <ArrowDownward
                                              className={classes.hiddenElement}
                                            />
                                          )
                                        }
                                      />
                                    </label>
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        clearable
                                        style={{ width: 130 }}
                                        format={"MM/dd/yyyy"}
                                        placeholder="mm/dd/yyyy"
                                        autoOk={true}
                                        id="defaultDD"
                                        key="defaultDD"
                                        name="defaultDD"
                                        TextFieldComponent={ThemeTextField}
                                        className={classes.datePickerField}
                                        value={defaultDate ? defaultDate : null}
                                        onChange={(value: any) => {
                                          handelDefaultDate(
                                            value ? value : null
                                          );
                                        }}
                                        KeyboardButtonProps={{
                                          "aria-label": "change date",
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </TableCell>
                                  <TableCell align="right">
                                    Select All
                                    <Checkbox
                                      value="uncontrolled"
                                      color="primary"
                                      /* checked={row.Selected} */
                                      onClick={(e: any) => {
                                        selectAllClick(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "uncontrolled-checkbox",
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {awards
                                  .map(
                                    (
                                      cls: IPostTitleIVAwardsResults,
                                      ix: any
                                    ) => (
                                      <TableRow>
                                        <TableCell>
                                          {cls.studentNumber}
                                        </TableCell>
                                        <TableCell>{cls.studentName}</TableCell>
                                        <TableCell>
                                          {cls.programVersion}
                                        </TableCell>
                                        <TableCell>{cls.award}</TableCell>
                                        <TableCell>
                                          {cls.disbursementNumber}
                                        </TableCell>
                                        <TableCell>
                                          <InputField
                                            type={InputType.CURRENCY}
                                            label="Amount *"
                                            name="amount"
                                            key="amount"
                                            readOnly={true}
                                            decimal
                                            thousandSeparator
                                            defaultValue={cls.amount}
                                            onBlur={(
                                              v: React.FocusEvent<HTMLInputElement>
                                            ) => {
                                              handelAmount(
                                                v.target.value,
                                                cls.awardScheduleId,
                                                ix
                                              );
                                            }}
                                            error={!!data.errors.amount}
                                            inputRef={data.register({
                                              required: "Amount is required.",
                                              validate: {
                                                numberOnly: (value) =>
                                                  getDecimalValue(value) !==
                                                    0 ||
                                                  "Amount cannot have cents.",
                                                valueGreaterThanZero: (value) =>
                                                  ConvertCurrencyValueToInput(
                                                    value
                                                  ) > 0 ||
                                                  "Amount must be greater than 0",
                                              },
                                            })}
                                            helperText={
                                              data.errors.amount
                                                ? (
                                                    data.errors
                                                      .amount as FieldError
                                                  ).message
                                                : undefined
                                            }
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                          >
                                            <KeyboardDatePicker
                                              disableToolbar
                                              variant="inline"
                                              clearable
                                              format={"MM/dd/yyyy"}
                                              placeholder="mm/dd/yyyy"
                                              id="disbursementDate"
                                              key={cls.awardScheduleId + "disbursementDate"}
                                              name="disbursementDate"
                                              label="Disbursement Date"
                                              TextFieldComponent={
                                                ThemeTextField
                                              }
                                              className={
                                                classes.datePickerField
                                              }
                                              value={
                                                cls.disbursementDate
                                                  ? cls.disbursementDate
                                                  : null
                                              }
                                              onChange={(value: any) => {
                                                changeDisbursementDate(
                                                  value ? value : null,
                                                  cls.awardScheduleId,
                                                  ix
                                                );
                                              }}
                                              KeyboardButtonProps={{
                                                "aria-label": "change date",
                                              }}
                                              autoOk={true}
                                            />
                                          </MuiPickersUtilsProvider>
                                        </TableCell>
                                        <TableCell align="right">
                                          <Checkbox
                                            color="primary"
                                            key={`selectionCheck-${ix}`}
                                            onClick={(e: any) => {
                                              selectClass(
                                                e.target.checked,
                                                cls.awardScheduleId,
                                                ix
                                              );
                                            }}
                                            checked={cls.selected}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                {awards?.length == 0 ? (
                                  <TableRow>
                                    <TableCell
                                      className={classes.tableEmptyRow}
                                      colSpan={7}
                                    >
                                      No Data
                                    </TableCell>
                                  </TableRow>
                                ) : null}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </form>
                    </FormContext>
                    <Grid container direction="row" spacing={2}>
                      <Grid
                        item
                        alignContent="flex-start"
                        alignItems="flex-start"
                        justify="flex-start"
                      >
                        <ProgressSaveButton
                          text="Post Now"
                          onClick={(e: any) => {
                            onPost(e);
                          }}
                          loading={updating}
                          buttonClassName={classes.copyButton}
                        ></ProgressSaveButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={dialogOpen}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth={true}
          disableBackdropClick
        >
          <DialogTitle id="form-dialog-title" className={classes.dialogHeader}>
            {"Post Disbursement Confirmation"}
          </DialogTitle>
          <DialogContent>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                {}
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                <Typography
                  color="textSecondary"
                  className={classes.typography}
                  variant="body1"
                >
                  {"Please review and confirm."}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TransactionCodeAutoComplete
                  id="transCodeId"
                  name="transCodeId"
                  label="Transaction Code *"
                  campusId={model.campusId}
                  params={{
                    fullWidth: true,
                    sysTransCodesDesc: "Financial Aid Payment",
                    creditDebitId: 2,
                  }}
                  filterHandle={(v: any) => {
                    setTransCodeId(v ? v.value : undefined);
                  }}
                  valueFilter={
                    transCodeId
                      ? {
                          key: "value",
                          values: [transCodeId],
                        }
                      : undefined
                  }
                  error={!!data.errors.transCodeId}
                  inputRef={data.register({
                    required: true,
                  })}
                  helperText={
                    data.errors.transCodeId
                      ? "Transaction code is required."
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Student ID</TableCell>
                        <TableCell>Student Name</TableCell>
                        <TableCell>Program Version</TableCell>
                        <TableCell>Awards</TableCell>
                        <TableCell>Disbursement Number</TableCell>
                        <TableCell>Disbursement Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {awards &&
                        filterAwardsResults(awards)
                          ?.map((cls: IPostTitleIVAwardsResults, ix: any) => (
                            <TableRow key={ix}>
                              <TableCell>{cls.studentNumber}</TableCell>
                              <TableCell>{cls.studentName}</TableCell>
                              <TableCell>{cls.programVersion}</TableCell>
                              <TableCell>{cls.award}</TableCell>
                              <TableCell>{cls.disbursementNumber}</TableCell>
                              <TableCell>
                                {new Date(
                                  cls.disbursementDate ?? new Date()
                                ).toLocaleDateString()}
                              </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </DialogContent>
          <Grid item xs={12}>
            <DialogActions className={classes.buttonSection}>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={3}></Grid>
                <Grid
                  item
                  container
                  xs={6}
                  alignContent="center"
                  alignItems="center"
                  justify="center"
                >
                  <ProgressSaveButton
                    disabled={transCodeId == ""}
                    text={"Yes"}
                    onClick={() => {
                      handleConfirm();
                    }}
                    loading={updating}
                    buttonClassName={classes.saveButton}
                  ></ProgressSaveButton>

                  <Button
                    onClick={handleClose}
                    color="secondary"
                    variant="contained"
                    type="button"
                  >
                    {"Cancel"}
                  </Button>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
            </DialogActions>
          </Grid>
        </Dialog>
      </div>
    </React.Fragment>
  );
};

export default NonTitleIVPostPage;
