import React, { Fragment, useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      minHeight: "100%",
      height: "100%",
      margin: "auto",
			backgroundColor: theme.palette.paper.background,
			"& .MuiCardContent-root": {
				paddingTop: theme.spacing(0),
				paddingLeft: theme.spacing(2)
			}
		},
		"& .makeStyles-content": {
			backgroundColor: theme.palette.paper.background
		},
		paperRoot: {
			padding: theme.spacing(2),
			width: "100%",
			minHeight: "100%",
			height: "95%",
			overflowX: "hidden",
			overflowY: "auto",
			flexDirection: "column",
			backgroundColor: theme.palette.paper.background
		},
  })
);

const LeadActivity = (props: any) => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <Paper className={classes.paperRoot}>
          <Typography > Activity </Typography>
      </Paper>
    </div>
  );
};

export default LeadActivity;
